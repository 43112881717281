angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesObjectModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {

      var LsnApplicationAllowedChangesObjectModelV1 = function() {
        this.objectName = 'ApplicationAllowedChangesObject';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
      
        this.fieldsProperties = {
          isNew: {
            type: 'bool',
            defaultValue: false
          },
          risksName: {
            type: 'array',
            elementsType: 'string'
          },
          metaData: {
            type: 'MetaData'
          },
          attributes: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesAttribute'
          },
          objectProperties: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesAttribute'
          },
          additionalData: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesAttribute'
          },
          objectId: {
            type: 'string'
          },
          objectClientId: {
            type: 'string'
          },
          def: {
            type: 'object'
          }
        };

        this.isNew = null; // czy obiekt na wnioksu jest nowy (nie pochodzi z historycznej polisy)
        this.risksName = null; // opcja - nazwa(y) ryzyk(a), jeśli obiekt opisuje ryzyko
        this.metaData = null; // opcja - metaData, o ile wskazujemy na konkretny obiekt
        this.attributes = []; // dozwolone do zmiany atrybuty/dynamicValues obiektu, podmiotu lub ryzyka
        this.objectProperties = []; // dozwolone do zmiany pola z obiektu
        this.additionalData = []; // dozwolone do zmiany additionalData z obiektu - UWAGA, to uzupełniamy sami po stronie JSa
        this.objectId = null; // metaData.id powiązanego obiektu ubezpieczenia (np dla ryzyk komunikacyjnych to id pojazdu)
        this.objectClientId = null; // metaData.clientId powiązanego obiektu ubezpieczenia (np dla ryzyk komunikacyjnych to clientId pojazdu)
        this.def = null; // definicja obiektu z SPD, np. w przypadku ryzyk
      };

      LsnApplicationAllowedChangesObjectModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesObjectModelV1;
    }
  ]);