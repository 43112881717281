angular.module('ihestiaRestServicesBase')

/**
 * pobieranie wydruków polis
 */
.service('policiesPrintoutsV2Svc', ['IHestiaRestServiceAbstractDef',
  function (IHestiaRestServiceAbstractDef)
{
  var PoliciesPrintoutsV2Svc = function () {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    var self = this;
    this.resource = 'policies';
    this.businessArea = 'printouts';
    this.port = '8208';
    this.path = 'RestPrintouts';
    this.apiVersion = 'v2';

    this.printNow = function(id, data, subResource, callback, errorCallback, httpParams){
        self.id = id;
        var parseCallback = function(result)
        {
          if(angular.isFunction(callback))
          {
            callback(result);
          }
        };

        if(typeof httpParams === 'undefined' || httpParams === null)
        {
          httpParams = {};
        }

        httpParams.allowedStatuses = [404, 409];

        return self.get(id, data, subResource, parseCallback, errorCallback, httpParams);
      };

    this.getAvailablePrintoutTypes = function(policyNumber, callback) {
      self.get(policyNumber, null, null, callback);
    };

  };
  return new PoliciesPrintoutsV2Svc();
}]);