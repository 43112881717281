angular.module('salesPath2')
  .factory('TariffFactorModel', [
    function ()
    {

      var TariffFactorModel = function (data)
      {
        this.lp = data.lp || null;
        this.name = data.name || null;
        this.value = data.value || null;
        this.code = data.code || null; //kod czynnika - opcjonalny
        this.formatType = data.formatType || null;
      };

      return TariffFactorModel;
    }
  ]);