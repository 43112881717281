//model opisujący dostępność atrybutu (dynamicValue z obiektu np. lokalizacji) w formularzu/szablonie
angular.module('salesPath2')
  .factory('AllowedFormAttributeModel', [
    function ()
    {

      var AllowedFormAttributeModel = function ()
      {
        this.disabled = false; //czy nieaktywny
        this.allowedValues = []; //dozwolone wartości
      };

      return AllowedFormAttributeModel;
    }
  ]);