angular.module('salesPath2')
  .controller('vehicleBmModalCtrl', ['$scope', '$rootScope', 'BonusMalusDataObjectModel', 'BonusMalusNewModel', 'bonusMalusHelper', 'RESOURCES', '$timeout', 'bonusMalusModalHelper', 'sp2CommonHelper', 'actionHelper', 'CONSTANTS', '$filter', 'dataContainerHelper',
    function($scope, $rootScope, BonusMalusDataObjectModel, BonusMalusNewModel, bonusMalusHelper, RESOURCES, $timeout, bonusMalusModalHelper, commonHelper, actionHelper, CONSTANTS, $filter, dataContainerHelper) { // eslint-disable-line angular/di

      $scope.numericSettings = {
        years: {
          allowLeadingZeros: false,
          parseToString: false,
          formatting: false,
          groupSeparator: '',
          decimals: 0,
          min: 0
        },
        claims: {
          allowLeadingZeros: false,
          parseToString: false,
          formatting: false,
          groupSeparator: '',
          decimals: 0,
          min: 0
        }
      };

      $scope.loadSpinnerOptions = {
        label: 'Pobieranie danych'
      };

      $scope.modalData = bonusMalusModalHelper.bmModalData[bonusMalusModalHelper.currentPersonId][bonusMalusModalHelper.currentVehicleId];
      $scope.vehicleId = bonusMalusModalHelper.currentVehicleId;
      $scope.personId = bonusMalusModalHelper.currentPersonId;
      $scope.todayDate = RESOURCES.TODAY;
      $scope.bonusMalusHelper = bonusMalusHelper;
      /**
       * active mode for each risk
       * @type {{AC: 'new' | 'continuation', OC: 'new' | 'continuation'}}
       */
      $scope.activeMode = {
        'AC': 'new',
        'OC': 'new'
      };

      /**
       * Init
       */
      $scope.init = function() {
        $scope.fireListeners();
        if ($scope.modalData.dataCleared) {
          $scope.loadBmData();
        }
      };

      /**
       * Wczytywanie danych bm
       */
      $scope.loadBmData = function() {
        // reset active mode
        $scope.activeMode.AC = 'new';
        $scope.activeMode.OC = 'new';

        var bmData = angular.copy($scope.dataContainer.bonusMalus[$scope.personId][$scope.vehicleId]);

        $scope.modalData.dataCleared = false;

        // dane bonus malus
        angular.forEach(bonusMalusModalHelper.risks, function(risk) {
          if (bmData.risks[risk] !== null) {
            var containerData = bmData.risks[risk].data;
            $scope.modalData.risks[risk] = containerData;
            $scope._setActiveMode(containerData, risk);
            if (containerData.newModeData === null && containerData.continuationModeData === null) {
              // if no data received we assume newModeData
              $scope.modalData.risks[risk].newModeData = new BonusMalusNewModel();
              $scope.modalData.risks[risk].continuationModeData = null;
            }
          } else {
            // brak danych do ściągania z ufg
            $scope.modalData.risks[risk] = (new BonusMalusDataObjectModel()).data;
            $scope.modalData.risks[risk].newModeData = new BonusMalusNewModel();
            if (bonusMalusHelper.givenDataAvailable($scope.personId, $scope.vehicleId)) {
              $scope.modalData.risks[risk].useGivenData = true;
            }
          }
        });
        $scope.modalData.givenDataAvailable = bonusMalusHelper.givenDataAvailable($scope.personId, $scope.vehicleId);

        $scope.modalData.acSelected = dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_ACKOM, $scope.vehicleId);
        $scope.modalData.ocSelected = dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_OCKOM, $scope.vehicleId);
      };

      /**
       * set active mode for risk
       * @param {Object} containerData BM risk data form svc and dataContainer
       * @param {'AC'|'OC'} riskName
       * @return {undefined}
       */
      $scope._setActiveMode = function(containerData, riskName) {
        $scope.activeMode[riskName] = 'new';
        if (containerData.newModeData !== null && containerData.continuationModeData !== null) {
          // based on IHESTIAWZ-2322
          var daysDiff = Math.floor(new XDate(containerData.continuationModeData.policyStartDate)
            .diffDays(new XDate(containerData.continuationModeData.policyEndDate))
          );
          if (daysDiff > 360) {
            $scope.activeMode[riskName] = 'continuation';
          }
        } else if (containerData.continuationModeData !== null) { // backward compatibility, from 2019-12-16 continuation mode will always come with newMode (mix mode)
          $scope.activeMode[riskName] = 'continuation';
        } else if (containerData.newModeData === null && containerData.continuationModeData === null) {
          // if no data received we assume newModeData
          $scope.modalData.risks[riskName].newModeData = new BonusMalusNewModel();
          $scope.modalData.risks[riskName].continuationModeData = null;
        } // else containerData.newModeData !== null, so we leave isNewMode = true
      };

      /**
       * [fireListeners description]
       */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleBmModal', function($event, modal) {
          if (modal.validForm) {
            $scope.setBmData();
            $timeout(function() {
              commonHelper.showTemporaryHiddenPopups();
              actionHelper.runAction('vehicleBmModalClosed');
              actionHelper.runAction('saveApplication');
            }, 0);

          } else {
            $event.preventDefault();
          }
        });

        $scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleBmModal', function() {
          commonHelper.showTemporaryHiddenPopups();
          actionHelper.runAction('vehicleBmModalClosed');
        });

        // czysczenie daty uzyskania prawa jazdy, jeśli brak prawa jazdy
        $scope.$watch('bonusMalusHelper.tarifficationInProgress', function(tarifficationInProgress) {
          var bmModalData = bonusMalusModalHelper.bmModalData[bonusMalusModalHelper.currentPersonId][bonusMalusModalHelper.currentVehicleId];
          $scope.showSpinner = false;
          if (tarifficationInProgress) {
            $scope.showSpinner = true;
          } else {
            var risks = bmModalData.risks,
              iRisks = ['AC', 'OC'];
            delete $scope.problemWithUfgMessage;
            angular.forEach(iRisks, function(risk) {
              if (risks[risk] && risks[risk].newModeData && risks[risk].newModeData.ufgConnectionProblem) {
                $scope.problemWithUfgMessage = $filter('translate')('ufgConnectionFailed', {componentCode: 'sp2'});
              }
            });
          }
        }, true);
      };

      /**
       * Ustawiamy dane bonus malus
       */
      $scope.setBmData = function() {
        var contModeData = null;
        angular.forEach($scope.modalData.risks, function(riskData, riskCode) {
          if (riskData) {
            var risksContainer = $scope.dataContainer.bonusMalus[$scope.personId][$scope.vehicleId].risks;
            var newModeData = $scope.modalData.risks[riskCode].newModeData || null;
            contModeData = $scope.modalData.risks[riskCode].continuationModeData || null;

            // sprawdzamy czy można ręcznie wpisać dane
            if (bonusMalusHelper.givenDataAvailable($scope.personId, $scope.vehicleId)) {
              risksContainer[riskCode] = new BonusMalusDataObjectModel();
              risksContainer[riskCode].data = {
                useGivenData: true,
                newModeData: newModeData,
                continuationModeData: contModeData
              };
            }
          }
        });

        bonusMalusHelper.distributeGivenData($scope.personId, $scope.vehicleId);
        bonusMalusModalHelper.clearBmModalData();

        // taryfikacja
        actionHelper.runAction('tarifficate');
      };


      $scope.damagesCountValid = function(type) {
        return !(($scope.bmData[type].last2YearsDamagesCount > $scope.bmData[type].last5YearsDamagesCount) || ($scope.bmData[type].last2YearsDamagesCount && !$scope.bmData[type].last5YearsDamagesCount) || (!$scope.bmData[type].last2YearsDamagesCount && $scope.bmData[type].last5YearsDamagesCount));
      };

      // sprawdzenie czy liczba szkód jest wpiana prawidłowo inaczej nie pozwalamy zapisać formularza
      // return valid

      $scope.checkFormValid = function() {
        var valid = true;
        if ($scope.modalData.acAvailable && $scope.modalData.risks.AC.newModeData && $scope.modalData.risks.AC.newModeData.last2YearsDamagesCount > $scope.modalData.risks.AC.newModeData.last5YearsDamagesCount) {
          valid = false;
        }
        if ($scope.modalData.ocAvailable && $scope.modalData.risks.OC.newModeData && $scope.modalData.risks.OC.newModeData.last2YearsDamagesCount > $scope.modalData.risks.OC.newModeData.last5YearsDamagesCount) {
          valid = false;
        }
        return valid;
      };

      $scope.$watch('modalData.risks.AC.newModeData.last2YearsDamagesCount', function() {
        bonusMalusModalHelper.modals.vehicleBmModal.settings.okBtnDisabled = !$scope.checkFormValid();
      });
      $scope.$watch('modalData.risks.AC.newModeData.last5YearsDamagesCount', function() {
        bonusMalusModalHelper.modals.vehicleBmModal.settings.okBtnDisabled = !$scope.checkFormValid();
      });

      $scope.$watch('modalData.risks.OC.newModeData.last2YearsDamagesCount', function() {
        bonusMalusModalHelper.modals.vehicleBmModal.settings.okBtnDisabled = !$scope.checkFormValid();
      });
      $scope.$watch('modalData.risks.OC.newModeData.last5YearsDamagesCount', function() {
        bonusMalusModalHelper.modals.vehicleBmModal.settings.okBtnDisabled = !$scope.checkFormValid();
      });

      $scope.init();
    }
  ]);