angular.module('spaceChat.common')
  .controller('spaceChatUploadCtrl', ['$scope', 'spaceChatUploadSvc',
    function($scope, spaceChatUploadSvc) {

      $scope.uploadOptions = {
        url: spaceChatUploadSvc._getServiceUrl() + '/' + $scope.chatData.chat.thread_id + '/upload/file',
        autoUpload: true,
        multiple: false,
        name: 'chat_' + $scope.chatData.chat.thread_id,
        onCompleteItem: function()
        {
          $scope.setUploadHeaders();
        }
      };

      $scope.init = function()
      {
        $scope.setUploadHeaders();
      };

      $scope.setUploadHeaders = function(){
        $scope.uploadOptions.headers = spaceChatUploadSvc.provider.getNewRequestHeaders();
        $scope.uploadOptions.headers['Ap-Contract-Req-Type'] = 'ACTION';
        $scope.uploadOptions.headers['Ap-Contract-Process-Type'] = 'SYNC';
        $scope.uploadOptions.headers['AP-Unique-Request-Id'] = spaceChatUploadSvc.provider.getUniqueRequestId();
      };

      $scope.init();

    }
  ]);