angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorNavigationHelper', ['ihestiaConfigHelper', '$window', '$state', 'ihestiaIkomunikatorCommonHelper',
    function(ihestiaConfigHelper, $window, $state, ihestiaIkomunikatorCommonHelper) {
      var NavigationHelper = function() {
        var self = this;
        
        /**
         * [init pobiera adres ikomunikatora]
         * @return {[type]} [description]
         */
        this.init = function() {
          self.ikomunikatorHost = ihestiaConfigHelper.getUrl('IKOMUNIKATOR_URL');

          self.fullListPanelState = ihestiaIkomunikatorCommonHelper.defaultConfig.stateNames.fullListPanel;
          self.detailsState = ihestiaIkomunikatorCommonHelper.defaultConfig.stateNames.details;
        };

        /**
         * [stateInApp sprawdza czy zdefiniowano nazwy stanow poprzez provider - ustawine w aplikacji w ktorej ma byc osadzony ikomunikator]
         * @return {[type]} [description]
         */
        this.stateInApp = function(stateName) {
          return !!self[stateName + 'State'];
        };

        /**
         * [goTo description]
         * @param  {[type]} page     [nazwa state na ktory chcemy przejsc]
         * @param  {[type]} threadId [id wątku]
         * @param  {[type]} filters  [filtry zaawansowanego wyszukiwania]
         */
        this.goTo = function(page, threadId, filters) {
          if (self.stateInApp(page)) {
            if (page === 'fullListPanel') {
              if(angular.isObject(filters)){
                $state.go(self.fullListPanelState, filters);
              } else {
                $state.go(self.fullListPanelState, null, {inherit:false});
              }
              
            } else if (page === 'details') {
              $state.go(self.detailsState, {
                threadId: threadId
              });
            }
          } else {
            if (page === 'fullListPanel') {
              $window.open(self.ikomunikatorHost + self.getQuery(filters));
            } else if (page === 'details') {
              $window.open(self.ikomunikatorHost + threadId);
            }
          }
        };

        /**
         * [getQuery zamienia plain obiektu do adresu z parametrami GET]
         * @param  {object} filters [plain obiekt filtru]
         * @return {string}         [parametry GET do adresu]
         */
        this.getQuery = function(filters) {
          if (filters === null) {
            return '';
          }

          var serializedFilters = [];
          angular.forEach(filters, function(value, key) {
            if (value !== '' && value !== null && !angular.isFunction(value)) {
              serializedFilters.push(key + '=' + encodeURIComponent(value));
            }
          });

          if (serializedFilters.length === 0) {
            return '';
          }

          return '?' + serializedFilters.join('&');
        };

        self.init();
      };

      return new NavigationHelper();
    }
  ]);