/**
 * Zamienia znaki nowej linii \n\r na <br />
 */
angular.module('ihestiaCommonDirectives')
  .directive('ihestiaMoveablePopup', ['$document', '$parse', function($document, $parse) {
    'use strict';
    return {
      restrict: 'E',
      replace: true,
      templateUrl: function(elem, attrs) {
        return attrs.drvTemplate || 'ihestia-commondirectives-template-app/directives/moveablePopup/moveablePopup.tpl.html';
      },
      compile: function() {
        return function(scope, element, attr) {

          var
            container = angular.element(element[0].querySelector('[data-popup-container]')),
            header = angular.element(element[0].querySelector('[data-popup-head]')),
            startX = 0,
            startY = 0,
            x = 0,
            y = 0;

          var defaultSettings = {
            title: '',
            show: true,
            bodyTemplate: 'ihestia-commondirectives-template-app/directives/moveablePopup/defaultMoveablePopupContent.tpl.html'
          };

          var getPositionRightValue = function(pageX) {
            // @todo zamienić na $window
            var result = $(window).width() - pageX - container.width(); //eslint-disable-line no-undef

            return result;
          };

          scope.hide = function() {
            var settingsName = attr.drvSettings;

            scope[settingsName].show = false;
          };

          scope.init = function() {
            scope.setObservers();
            scope.rebuildSettings();

            y = scope.settings.top;
            x = scope.settings.right;

            container.css({
              top: y + 'px',
              right: x + 'px'
            });
          };

          scope.setObservers = function() {
            scope.$watch(attr.drvSettings, function(settings, oldSettings) {
              if (settings) {
                scope.rebuildSettings();
                if(settings.show === true && oldSettings.show === false){
                  scope.init();
                }
              }
            }, true);
          };

          /**
           * Funkcja do przebudowy ustawień
           * @param  {[type]} $attrs [description]
           * @return {[type]}        [description]
           */
          scope.rebuildSettings = function() {
            // zmiany ustawień
            if (attr.drvSettings) {
              scope.settings = angular.extend({}, defaultSettings, $parse(attr.drvSettings)(scope));
            } else {
              scope.settings = angular.extend({}, defaultSettings);
            }
          };

          header.on('mousedown', function(event) {
            // Prevent default dragging of selected content
            event.preventDefault();

            startY = event.pageY - y;
            startX = getPositionRightValue(event.pageX) - x;

            $document.on('mousemove', scope.onMouseMove);
            $document.on('mouseup', scope.onMouseUp);
          });

          scope.$on('$destroy',function() {
            header.off('mousedown');
            $document.off('mousemove', scope.onMouseMove);
            $document.off('mouseup', scope.onMouseUp);
          });

          scope.onMouseMove = function(event) {
            y = event.pageY - startY;
            x = getPositionRightValue(event.pageX) - startX;

            container.css({
              top: y + 'px',
              right: x + 'px'
            });
          };

          scope.onMouseUp = function() {
            $document.off('mousemove', scope.onMouseMove);
            $document.off('mouseup', scope.onMouseUp);
          };

          scope.edit = function(){
            scope.settings.onEdit();
          };

          scope.init();
        };
      }
    };
  }]);