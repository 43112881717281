angular.module('ihestiaMenuBase')
  .factory('chatBotSessionSvc', ['IHestiaRestServiceLsnDef',
    function(IHestiaRestServiceLsnDef) {

      var ChatbotSessionSvc = function() {
        IHestiaRestServiceLsnDef.apply(this, arguments);
        
        this.apiString = 'api';
        this.resource = 'current';
        this.businessArea = 'session';

      };
      return new ChatbotSessionSvc();
    }
  ]);
