angular.module('salesPath2')
  .controller('vehicleSpecialPurposeModalCtrl', ['$scope', 'RESOURCES', 'vehicleModalHelper',
    function($scope, RESOURCES, vehicleModalHelper) {
      $scope.purposeOptions = RESOURCES.SPECIAL_PURPOSE_OPTIONS;
      $scope.selectedPurposes = {};

      /**
       * Init
       */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.loadSelectedPurposes();
      };

      /**
       * [fireListeners description]
       */
      $scope.fireListeners = function(){
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleSpecialPurposeModal', function() {
            vehicleModalHelper.purposesChosen($scope.getSelectedPurposes());
        });

        // anuluj
        $scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleSpecialPurposeModal', function() {
            vehicleModalHelper.purposesEditCanceled();
        });
      };

      /**
       * Ładujemy szczególne przeznaczenie
       */
      $scope.loadSelectedPurposes = function() {
        var purposes = vehicleModalHelper.modals.vehicleSpecialPurposeModal.selectedPurposes;
        if(angular.isArray(purposes)) {
          angular.forEach(purposes, function(purposeCode){
            $scope.selectedPurposes[purposeCode] = true;
          });
        }
      };

      /**
       * Zwraca zaznaczone wyposażenie
       * @return array
       */
      $scope.getSelectedPurposes = function() {
        var purposes = [];
        angular.forEach($scope.purposeOptions, function(purpose){
          if($scope.selectedPurposes[purpose.CODE]) {
            purposes.push(purpose);
          }
        });

        return purposes;
      };

      $scope.init();
    }
  ]);