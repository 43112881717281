angular.module('salesPath2')
  .service('requirementMap', ['REQUIREMENTS', '$log',
    function(REQUIREMENTS, $log) {
      var Req = function() {
        var self = this;
        /**
         * [map funkcja mapuje style do obiektu RESOURCES]
         * @param  {[type]} resource [RESOURCES]
         * @return {[type]}          [description]
         */
        this.map = function(resources) {
          self.mapAddReq(resources);
        };

        /**
         * mapuje wymagania dla dodatków i ich wariantów
         * @param  {Object} resources zasoby aplikacji
         */
        this.mapAddReq = function(resources) {
          angular.forEach(resources.PRODADD, function(elem) {
            self._mapAddElement(elem.REQUIREMENTS);
            if (angular.isArray(elem.VARIANTS)) {
              angular.forEach(elem.VARIANTS, function(variant) {
                self._mapAddElement(variant.REQUIREMENTS);
              });
            }
            if (angular.isObject(elem.PRODUCTS_REQUIREMENTS)) {
              angular.forEach(elem.PRODUCTS_REQUIREMENTS, function(reqs) {
                self._mapAddElement(reqs);
              });
            }
          });
        };

        /**
         * mapuje dodatek lub jego wariant
         * @param  {Array} reqs tablica z kodami wymagań/requirements
         */
        this._mapAddElement = function(reqs) {
          if (angular.isArray(reqs)) {
            var errors = [],
              idx = reqs.length - 1;
            for (idx; idx >= 0; idx -= 1) {
              var name = reqs[idx];
              if (angular.isString(name)) {
                if (angular.isDefined(REQUIREMENTS[name])) {
                  reqs.splice(idx, 1);
                  reqs.push(REQUIREMENTS[name]);
                } else {
                  errors.push(name);
                }
              }
            }
            if (errors.length) {
              $log.error('Brak mapowania dla wymagań dodatku o kodach: ' + errors.join(', '));
            }
          }
        };

      };
      return new Req();
    }
  ]);