angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonComponentRegisterSvc', ['ihestiaCommonAbstractComponentRegisterSvc',
    function(ihestiaCommonAbstractComponentRegisterSvc) {

      var ComponentRegisterSvc = function() {
        ihestiaCommonAbstractComponentRegisterSvc.apply(this, arguments);

        this.resource = 'component';
      };
      return new ComponentRegisterSvc();
    }
  ]);