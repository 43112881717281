angular.module('lsnBase.filters')
  .filter('ucFirst', function(){
    return function(inputValue, multiple) {
      if(angular.isUndefined(multiple))
      {
        multiple = false;
      }

      if(!angular.isString(inputValue) || inputValue === ''){
        return inputValue;
      }
      if(inputValue.length === 1)
      {
        return inputValue.toUpperCase();
      }

      var ucFirstWord = function(string)
      {
        return string.charAt(0).toUpperCase() + string.substring(1);
      };

      if(multiple)
      {
        return inputValue.replace(/([a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ])+/g, function myFunction(match){
          return ucFirstWord(match);
        });
      }
      else
      {
        return ucFirstWord(inputValue);
      }
    };
});