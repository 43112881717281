angular.module('salesPath2')
  .provider('appVariablesHelper', [
    function() {
      var self = this;

      this.vars = {};

      this.$get = ['CONFIG', 'CONSTANTS',
        function(CONFIG, CONSTANTS) {
          var AVHelper = function() {
            var helper = this;
            /**
             * zwraca aktualne appVariables
             * @return {Object}
             */
            this.getAppVars = function() {
              if (angular.equals(self.vars, {})) {
                helper.initAppVars();
              }
              return self.vars;
            };

            /**
             * inicjalizuje self.vars
             */
            this.initAppVars = function() {
              angular.extend(self.vars, helper.getInitialVars());
            };

            /**
             * zwraca inicjalne wartości dla appVariables
             * @return {Object}
             */
            this.getInitialVars = function() {
              return {
                contentPulledLeft: false, // czy glowny kontent przesuniety w lewo
                messageContainerVisible: false, // czy widoczny sidebar z komunikatami
                activeMenu: false, // jesli true, to klikniecie w logo w headerze powinno przekierowywac do matrycy
                appMode: CONSTANTS.APP_MODE_CALCULATION, // tryb dzialania aplikacji
                basketContainerVisible: false, // czy jest rozwinięty koszyk
                basketContainerSecondVisibleNonCom: false, // czy widoczny przycisk rozwijania 2giego koszyka (dodatkowe zniżki i odstępstwa) - Pozakomunikacyjne
                basketContainerSecondVisibleCom: false, // czy widoczny przycisk rozwijania 2giego koszyka (dodatkowe zniżki i odstępstwa) - Komunikacha
                disableLeftMenu: false, // lewe menu wylaczone
                uiBlocked: false, // czy wlaczony block-ui
                // Przykład openPolicyModal = {type: CONSTANTS.POLICY_EXTRA_DISCOUNT, policyId: 4365646}
                openPolicyModal: null, //zmienna wykorzystywana przy otwieraniu ekranu podsumowania, ustawiana na otwarcie bledu, służy do otwarcia konkretnego modala
                isInsurerChosen: false, // czy wybrano glownego ubezpieczonego z ekranow startowych
                mainState: CONFIG.NAVIGATION.mainState, // glowny state dla aplikacji
                isOcBuyer: false, // czy w trybie wniosku OC nabywcy
                isSupplement: false, // czy w trybie dokupienia/doubezpieczenia
                isExternallyCreated: false, // czy wniosek jest tworzony w innej aplikacji - ustawiana na true tylko podczas wczytania wniosku
                policyType: null, // (CONSTANTS.POLICY_TYPE_COMMUNICATION lub CONSTANTS.POLICY_TYPE_PROPERTY) jesli !== null (np. w dokupieniach), tzn. że wniosek zawężamy tylko do ryzyk z konkretnego pakietu (komunikacja lub mienie)
                readOnly: false, // czy wniosek w trybie readonly
                canManage: true, // czy można zarządzać wnioskiem/ofertą - kopia wartości z atrybutu canManage na wnioksu odczytanym bezpośresnio po wczytaniu wnoisku/oferty
                policyViewMode: false, // czy aplikacja została otwarta w trybie odczytu polisy ?action=viewPolicy - przydatna gdy flaga appMode zostaje w trakcie jakichś operacji nadpisana - np. w momencie gdy oferta/kalkulacja zostanie zatwierdzona
                allowedGeneralAgreements: [] // {string[]} allowed general agreements (e.g. for ergo 4, jupiter)
              };
            };
          };
          return new AVHelper();
        }
      ];

    }
  ]);