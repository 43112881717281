angular.module('ihestiaCommonDirectives')
.directive('autoSubmitForm', ['$timeout', '$sce', function($timeout, $sce) {
    return {
        replace: true,
        scope: {},
        require: 'form',
        template: '<form action="{{formData.url}}" method="POST" target="_blank">' +
                     '<input type="hidden" name="data" value="{{formData.data}}" />' +
                  '</form>',
        link: function($scope, $element, $attrs) {
            $scope.$on($attrs.event, function(event, data) {
                $scope.formData = data;
                $scope.formData.url = $sce.trustAsResourceUrl($scope.formData.url);
                $timeout(function() {
                    // usunięcie błędu pod FF/platform-independent
                    try {
                        angular.element($element).submit();
                    } catch(e) {
                        return false;
                    }
                    return null;
                });
             });
        }
    };
}]);