angular.module('ihestiaWidgets.updateContactData')
  .controller('myCustomerContactDataModalCtrl', ['$scope', 'myCustomerContactDataModalHelper', 'ihestiaRestDictionaryAddressesSvc', 'myCustomerContactDataHelper', 'MyCustomerContactDataSaveHelper',
    function($scope, myCustomerContactDataModalHelper, ihestiaRestDictionaryAddressesSvc, myCustomerContactDataHelper, MyCustomerContactDataSaveHelper) {

      /**
       * Dane agencji
       * @type {Object}
       */
      $scope.agencyData = myCustomerContactDataModalHelper.modalData.agencyData;

      /**
       * Dane pracownika
       * @type {Object}
       */
      $scope.workerData = myCustomerContactDataModalHelper.modalData.workerData;

      /**
       * Podpowiadajka adresów
       * @type {Object} service
       */
      $scope.addressSvc = ihestiaRestDictionaryAddressesSvc;

      /**
       * Mapa atrybutów w adresach
       * @type {Object}
       */
      $scope.addressAttributeMap = myCustomerContactDataHelper.addressAttributeMap;

      /**
       * Teksty błędów
       * @type {Object}
       */
      $scope.invalidTexts = myCustomerContactDataHelper.invalidTexts;

      /**
       * Mapa atrybutów do walidatorów z usług
       * @type {Object}
       */
      $scope.svcValidatorMap = myCustomerContactDataHelper.svcValidatorMap;

      /**
       * Klinięto zapisz chociaż raz
       * @type {Boolean}
       */
      $scope.formSubmitted = false;

      /**
       * Otwarcie regulaminu.
       */
      $scope.openStatute = myCustomerContactDataHelper.openStatute;

      /**
       * Helper
       * @type {Object}
       */
      $scope.helper = myCustomerContactDataHelper;

      $scope.clauseListHelper = null;

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        if ($scope.workerData) {
          $scope.clauseListHelper = myCustomerContactDataHelper.getClauseListHelper($scope.workerData.clauses);
        }
      };


      /**
       * Ok
       * @return {myCustomerContactDataModalHelper}
       */
      myCustomerContactDataModalHelper.confirm = function() {
        $scope.formSubmitted = true;

        $scope.$emit('showBlockUi');
        if ($scope.workerData) {
          $scope.workerData.clauses = $scope.clauseListHelper.getClauses();
        }
        var saveHelper = new MyCustomerContactDataSaveHelper({
          agencyData: $scope.agencyData,
          workerData: $scope.workerData
        });
        saveHelper.save().then(function(res) {
          myCustomerContactDataModalHelper.setPromiseAsResolved(res);
          myCustomerContactDataModalHelper.hideModal();
        }, function(validationResponse) {

          $scope.validationResponse = validationResponse;

          if ($scope.workerData) {
            saveHelper.validateClauses($scope.clauseListHelper, $scope.validationResponse);
          }

        }).finally(function() {
          $scope.$emit('hideBlockUi');
        });

      };

    }]);
