angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaRestoreAnniversaryModalHelper', ['LsnModalHelper', 'ihestiaLifeAnniversaryHelper', 'IhestiaNotesAndUploader', '$q',
    function(LsnModalHelper, ihestiaLifeAnniversaryHelper, IhestiaNotesAndUploader, $q) {
      var RestoreAnniversaryModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;
        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'sm',
          title: ['anniversary.restoreOperation', {componentCode: 'iKonto'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/restoreAnniversary/restoreAnniversaryModal.tpl.html',
          okBtnName: ['Public.save', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };

        this.modalData = {
          policyNumber: null
        };

        this.tplData = {
          notesAndUploaderHelper: null,
          saveInProgress: null,
          errors: null,
          warnings: null
        };

        this.cancelPromise = null;

        this.setOptions(self.modalOptions);

        this.init = function() {
          self.tplData.errors = null;
          self.tplData.warnings = null;
          /**
           * @type {IhestiaNotesAndUploaderOptions}
           */
          var notesAndUploaderOptions = {
            policyNumber: self.modalData.policyNumber
          };
          self.tplData.notesAndUploaderHelper = new IhestiaNotesAndUploader(notesAndUploaderOptions);
        };

        this.confirm = function() {
          self.tplData.saveInProgress = true;
          self.tplData.errors = [];
          self.tplData.warnings = [];
          if (!self.cancelPromise) {
            self.cancelPromise = $q.defer();
          }

          var operationData = {
            comment: self.tplData.notesAndUploaderHelper.getNotes(),
            uploadedDocumentsIds: self.tplData.notesAndUploaderHelper.getFileServerIds()
          };
          var httpParams = {
            timeout: self.cancelPromise.promise.then(lsnNg.noop, lsnNg.noop)
          };
          ihestiaLifeAnniversaryHelper.restoreAnniversary(self.modalData.policyNumber, operationData, httpParams)
            .then(function() {
              self.setPromiseAsResolved();
              self.hideModal();
            }, function(err) {
              _.forEach(_.get(err, 'data.messages'), function(message) {
                if (message.type === 'WARN') {
                  self.tplData.warnings.push(message);
                } else if (message.type !== 'INFO') {
                  self.tplData.errors.push(message);
                }
              });
            })
            .finally(function() {
              self.tplData.saveInProgress = false;
              self.cancelPromise = null;
            });
        };

        this.cancel = function() {
          if (self.cancelPromise) {
            self.cancelPromise.reject('cancel');
          }
          self.setPromiseAsRejected();
          return self.hideModal();
        };
      };
      return new RestoreAnniversaryModalHelper();
    }]);
