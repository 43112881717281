angular.module('salesPath2')
  /**
   * zestawy predefinniowanych wymagań/warunków dla produktów/ryzyk/dodatków
   * @return {Object}
   */
  .factory('REQUIREMENTS', ['CONSTANTS', 'EstateModelConstants',
    function(CONSTANTS, EstateModelConstants) {

      var REQUIREMENTS = {
        //wymagania/warunki jakie muszą być spełnione, aby dodatek był aktywny - do wyboru
        REQ_ADD_LEASED: { //wymagalność na zaznaczenie "nieruchomość jest przedmiotem najmu"
          type: 'attributeValue',
          attribute: ['dynamicValues', 'leased'],
          value: true
        },
        REQ_ADD_FIRE_SUM_1: { //wymaganie na uzupełnienie sumy ubezpieczenia na określonych ryzykach ogniowych
          type: 'riskSumInsured',
          product: CONSTANTS.PRODUCT_OGI,
          risks: ['estate', 'equipment', 'personalBelongings', 'currentAssets']
        },
        REQ_ADD_FLAT_OR_HOUSE: { //wymagalność na typ nieruchomości "dom" lub "mieszkanie"
          type: 'objectFieldValue',
          field: 'type', //pole na obiekcie ubezpieczenia, dla którego sprawdzamy wartość
          values: [EstateModelConstants.ESTATE_TYPE_FLAT, EstateModelConstants.ESTATE_TYPE_HOUSE] //dopuszczalne wartości
        },
        REQ_ADD_LEASED_PERIOD: { // wymagalność na zaznaczenie "nieruchomość jest przedmiotem najmu"
          type: 'attributeValue',
          attribute: ['dynamicValues', 'leasedLongOrShortTerm'],
          values: ['Tak, długoterminowego', 'Tak, krótkoterminowego']
        }
      };

      return REQUIREMENTS;
    }
  ]);
