angular.module('ihestiaCommonDirectives.uploaderV2')
  .factory('LsnCommonUploadV2Api', ['lsnCommonUploadV2FrpHelper', '$alert', '$filter', '$q',
    function(lsnCommonUploadV2FrpHelper, $alert, $filter, $q) {

      var LsnCommonUploadV2Api = function(options) {
        var self = this;

        this.options = options;

        this.callbacks = {};

        this.files = [];

        /**
         *
         */
        this._registerBasicCallbacks = function() {
          self.prependCallback('fileuploadadd', self._addFile);
          self.prependCallback('fileuploadsend', lsnCommonUploadV2FrpHelper.appendRequestData);
          self.prependCallback('fileuploaddone', lsnCommonUploadV2FrpHelper.appendResponseData);
          self.prependCallback('fileuploadprocessfail', self._handleFailedUpload);
          self.prependCallback('fileuploadprocessalways', self._handleFileLoad);
        };

        /**
         * Adds given callback as first in queue
         * @param eventCode
         * @param callbackFn
         */
        this.prependCallback = function(eventCode, callbackFn) {
          self._checkCallbackStructure(eventCode);
          self.callbacks[eventCode].unshift(callbackFn);
        };

        /**
         * Adds given callback as last in queue
         * @param eventCode
         * @param callbackFn
         */
        this.appendCallback = function(eventCode, callbackFn) {
          self._checkCallbackStructure(eventCode);
          self.callbacks[eventCode].push(callbackFn);
        };

        this.clearCallbacks = function(eventCode) {
          if (angular.isArray(self.callbacks[eventCode])) {
            self.callbacks[eventCode] = [];
          }
        };

        /**
         * Handles events emitted by ng-uploader
         *
         * @param eventCode
         * @param event
         * @param data
         */
        this.uploaderEventCatched = function(eventCode, event, data) {
          if (angular.isArray(self.callbacks[eventCode])) {
            angular.forEach(self.callbacks[eventCode], function(callbackFn) {
              data = callbackFn(data, event);
            });
          }
        };

        /**
         * - removing failed files
         * - auto send to frp after upload
         * @private
         */
        this._handleFileLoad = function(data) {
          if(data.files[0].failedUpload) {
            self.deleteFile(data.files[0]);
          } else if (self.options.frp.autoSend) {
            self.sendFiles(self.options.frp.attributes);
          }
        };

        /**
         *
         * @param eventCode
         * @private
         */
        this._checkCallbackStructure = function(eventCode) {
          if (!self.callbacks) {
            self.callbacks = {};
          }
          if (!angular.isArray(self.callbacks[eventCode])) {
            self.callbacks[eventCode] = [];
          }
        };

        /**
         *
         * @param data
         * @returns {*}
         */
        this._addFile = function(data) {
          var allFilesSizes = 0;
          angular.forEach(self.files, function(file){
            allFilesSizes = allFilesSizes + file.size;
          });

          if(self.options.ngUploadFormOptions.maxAllFilesSize && (allFilesSizes + data.files[0].size) > self.options.ngUploadFormOptions.maxAllFilesSize) {
            self.options.showMessage('Przekroczono maksymalną wielkość plików w ramach jednej transakcji');
          } else {
            lsnCommonUploadV2FrpHelper.addFrpData(data.files);
            if (angular.isArray(data.files)) {
              self.files.push.apply(self.files, data.files);
            } else {
              self.files.push(data.files);
            }
          }
          return data;
        };

        /**
         *
         * @param dynamicValues
         * @returns {*}
         */
        this.sendFiles = function(dynamicValues) {
          var productFilesParams = self.options.useProductFilesSvc ? self.options.productFilesParams : null;
          if(angular.isFunction(options.callback.frpFileUploadStart)) {
            options.callback.frpFileUploadStart(self.getFileProgress());
          }
          return $q(function(resolve, reject) {
            if (!self.files || self.files.length === 0) {
              $alert({
                content: $filter('translate')('noFileSelected', {
                  componentCode: 'Public'
                }),
                type: 'warning'
              });
              reject('noFileSelected');
            } else {
              lsnCommonUploadV2FrpHelper.sendFiles(self.files, dynamicValues, productFilesParams).then(function(res){
                if(angular.isFunction(options.callback.frpFileUploadDone)) {
                  options.callback.frpFileUploadDone(res, self.getFileProgress(), self.options);
                }
                resolve(res);
              }, reject);
            }
          });
        };

        /**
         * Returns current file uploads status
         * makes sense in multiple: true mode
         */
        this.getFileProgress = function() {
          var progress = {
            filesCount: self.files.length,
            filesError: 0,
            filesDone: 0
          };
          angular.forEach(self.files, function(file) {
            if(file.uploadDone) {
              progress.filesDone++;
            }
            if(file.error) {
              progress.filesError++;
            }
          });
          progress.allDone = (progress.filesDone + progress.filesError) >= progress.filesCount;

          return progress;
        };

        /**
         *
         * @param removeFileAfter
         * @returns {Promise}
         */
        this.getQuality = function(removeFileAfter) {
          var promises = [];

          angular.forEach(self.files, function(file) {
            if (file.fileServerId) {
              promises.push(lsnCommonUploadV2FrpHelper.getQuality(file).then(function() {
                if (removeFileAfter) {
                  self.deleteFile(file);
                }
                return file;
              }, lsnNg.noop));
            }
          });

          return $q.all(promises).then(lsnNg.noop, lsnNg.noop);
        };

        /**
         *
         * @param file
         */
        this.deleteFile = function(file) {
          var fileIndex = self.files.indexOf(file);
          if (fileIndex > -1) {
            self.files.splice(fileIndex, 1);
          }

          if (angular.isFunction(self.options.callback.onFileDelete)) {
            self.options.callback.onFileDelete(file);
          }
        };

        /**
         * handles fileuploadprocessfail
         * (wrong file size/format/etc)
         * @private
         */
        this._handleFailedUpload = function(data) {
          var file = data.files[0];
          file.failedUpload = true;
          var msg = file.error;

          msg = msg.replace('FILENAME', file.name);
          msg = msg.replace('MAXSIZE', $filter('formatFileSize')(data.maxFileSize));
          msg = msg.replace('SIZE', $filter('formatFileSize')(file.size));

          self.options.showMessage(msg);
        };

        /**
         *
         */
        this._init = function() {
          self._registerBasicCallbacks();
        };

        self._init();

      };

      return LsnCommonUploadV2Api;
    }]);
