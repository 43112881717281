angular.module('salesPath2')
  .controller('basketContainerCtrl', ['$scope', 'applicationHelper', 'messagesHelper', 'CONSTANTS', 'appVariables', 'basketHelper', 'navigationHelper', 'discountsHelper', 'basketSecondDetailsModalHelper', 'basketSecondHelper', '$filter', 'mainDataContainer', 'sp2CommonHelper', 'sp2UiHelper',
    function($scope, applicationHelper, messagesHelper, CONSTANTS, appVariables, basketHelper, navigationHelper, discountsHelper, basketSecondDetailsModalHelper, basketSecondHelper, $filter, mainDataContainer, sp2CommonHelper, sp2UiHelper) {

      $scope.basketContentData = basketHelper.tplData.basketContent;
      $scope.basketSecondContentData = basketHelper.tplData.basketSecondContent;
      $scope.basketMenuData = basketHelper.tplData.menuBar;
      $scope.basketSecondVisible = basketHelper.tplData.basketSecondVisible;
      $scope.tplSecondBasket = basketSecondHelper.tplData;
      $scope.menuBar = basketHelper.tplData.menuBar;
      $scope.isSalesCharacter = sp2CommonHelper.isSalesCharacter();


      $scope.tplData = {
        discountValue: null,
        discountType: null
      };

      $scope.basketSpinnerOptions = {
        label: basketHelper.tplData.loadingSpinnerLabel
      };

      /**
       * Init
       */
      $scope.init = function() {
        $scope.tplData.discountValue = mainDataContainer.individualDiscountInputValue;
        $scope.checkDiscountType($scope.tplData.discountValue, false);
        basketHelper.refreshBasketContentData();
      };

      /**
       * Funkcja sprawdza jaki typ zniżki został wpisany (procent, kwota lub kod promocyjny)
       * - jeżeli kwota to przelicza skadkę z uwzględnieniem tej kwoty
       * - jeżeli procent to zamienia % na kwotę i potem przelicza składkę
       * - jeżeli kod promocyjny to woła usługę która zwróci odpowiednią kwotę (lub procent)
       * @param {String} value wartosc wpisana w polu input
       * @param {Boolean} [tarifficate=true] czy uruchomić taryfikację/zapis wniosku
       */
      $scope.checkDiscountType = function(value, tarifficate) {
        tarifficate = (tarifficate === false) ? false : true;
        if (angular.isString(value) && sp2CommonHelper.isSalesCharacter()) {
          mainDataContainer.individualDiscountInputValue = value;
        }
        
        var patt = /[^0-9]/g;
        var valueTmp = value;
        if (value.substring(0, 1) === '+') {
          valueTmp = value.substring(1, value.length);
        }

        $scope.tplData.discountType = null;

        if (valueTmp.length >= 1) {
          if (valueTmp.length >= 2) {

            if (valueTmp.substring(valueTmp.length - 1, valueTmp.length) === '%' && (patt.test(valueTmp.substring(0, valueTmp.length - 1)) === false || (patt.test(valueTmp.substring(1, valueTmp.length - 1)) === false && valueTmp.substring(0, 1) === '-') ) ) {
              //zniżka okreslona w procentach
              $scope.tplData.discountType = 'PERC';
            } else if ((patt.test(valueTmp.substring(0, valueTmp.length)) === false) || (patt.test(valueTmp.substring(1, valueTmp.length)) === false && valueTmp.substring(0, 1) === '-')) {
              //znikaka okreslona w kwocie
              $scope.tplData.discountType = 'AMOUNT';
            } else {
              //kod promocyjny
              $scope.tplData.discountType = 'PROMOCODE';

            }
          } else if (!patt.test(valueTmp)) {
            //jedna cyfra - to jest kwota zniżki
            $scope.tplData.discountType = 'AMOUNT';
          } else {
            $scope.tplData.discountType = null;
          }
          $scope.doscountValue = valueTmp;
        } else {
          $scope.doscountValue = 0;
          $scope.tplData.discountType = null;
        }

        if ((valueTmp.length > 6 && valueTmp.substring(0, 1) !== '-') || (valueTmp.substring(0, 1) === '-' && valueTmp.length > 7)) {
          $scope.tplData.discountType = 'PROMOCODE';
        }

        if ($scope.tplData.discountType === null) {
          $scope.tplData.discountType = 'AMOUNT';
        }

        basketHelper.checkPzaDiscount($scope.doscountValue , $scope.tplData.discountType, tarifficate);

      };

      /**
       * Funkcja drukująca kalkulację
       */
      $scope.printOffer = function() {
        $scope.hideBasketContainer();
        if ($scope.dataContainer.application.metaData.id === null) {
          sp2UiHelper.showAlert({
            content: $filter('translate')('noApplicationNumber', {componentCode: 'sp2'}),
            type: 'danger'
          });
        } else if (messagesHelper.isanyTarifficationError()) {
          sp2UiHelper.showAlert({
            content: $filter('translate')('inOrderToPrintCalculationFixTarifficationErrorsFirst', {componentCode: 'sp2'}),
            type: 'danger'
          });
        } else {
          applicationHelper.registerPrintApplicationOffer();
        }
      };

      /**
       * przejscie do podsumowania
       */
      $scope.goToSummary = function() {
        $scope.hideBasketContainer();
        navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
      };

      /**
       * Szczegóły zniżek
       * @return {[type]} [description]
       */
      $scope.showDetails = function() {
        discountsHelper.showDiscountsDetailsModal();
      };

      /*
       * ukrywa kontener koszyka
       */
      $scope.hideBasketContainer = function() {
        $scope.appVariables.contentPulledLeft = false;
        appVariables.contentPulledLeftDoubled = false;
        $scope.appVariables.basketContainerVisible = false;
      };

      //otwiera koszyk2 (Promocje i odstępstwa)
      $scope.openSecondBasket = function() {
        if (appVariables.contentPulledLeftDoubled === null || ! appVariables.contentPulledLeftDoubled) {
          appVariables.contentPulledLeftDoubled = true;
          if (basketHelper.getIndividualDiscount() === null) {
            $scope.tplData.discountValue = null;
          }
        } else {
          appVariables.contentPulledLeftDoubled = false;
        }
      };

      $scope.openSecondBasketDetails = function() {
        basketSecondDetailsModalHelper.showBasketSecondDetailsModal();
      };

      $scope.init();
    }
  ]);