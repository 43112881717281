angular.module('salesPath2')
  .service('groupModalHelper', ['sp2CommonHelper', '$filter', '$timeout', 'personGroupHelper', 'GroupModel', 'mainDataContainer', 'appVariables',
    function(sp2CommonHelper, $filter, $timeout, personGroupHelper, GroupModel, mainDataContainer, appVariables) {
      var GroupModalHelper = function() {
        var self = this;
        /**
         * edycja/dodawanie grupy
         * @type {Object}
         */
        this.modals = {
          groupEditModal: {
            settings: {
              treatLabelAsI18nCode: true,
              cancelBtnName: 'sp2.modal.cancel',
              okBtnName: 'sp2.modal.confirm',
              title: 'sp2.modal.groupEditModal.title',
              size: 'md'
            }
          }
        };

        var DEFAULT_GROUP_MODAL_PARAMS = {
          errors: {},
          group: null,
          groupData: null,
          editedId: null,
          insuredAgeIntervals: [],
          personCount: 0
        };

        /**
         * dane aktulanie otwartego modala edycji grupy
         * @type {Object} obiekt formatu DEFAULT_GROUP_MODAL_PARAMS
         */
        this.currentlyEditedGroupData = angular.copy(DEFAULT_GROUP_MODAL_PARAMS);

        /**
         * Ustawiamy domyślne wartości parametrów edycji grupy
         */
        this.clearGroupEditModalData = function() {
          self.currentlyEditedGroupData = angular.copy(DEFAULT_GROUP_MODAL_PARAMS);
        };

        /**
         * event dodawania/edycji grupy
         * @param {Object} data dane
         * id: clientId grupy (opcja)
         * context: kontekst
         */
        this.openPopupEditGroup = function(data) {
          if (!angular.isString(data.id)) {
            self.currentlyEditedGroupData.group = new GroupModel();
          } else {
            self.currentlyEditedGroupData.editedId = data.id;
            self.currentlyEditedGroupData.group = mainDataContainer.groups[data.id];
          }

          if (angular.isDefined(data.context)) {
            self.currentlyEditedGroupData.context = data.context;
          }
          var title;
          if (angular.isDefined(data.id)) {
            title = self.currentlyEditedGroupData.group.get('name') + ' - ' + $filter('translate')('modal.matrix.editGroupModalTitle', {componentCode: 'sp2'});
          } else {
            title = $filter('translate')('modal.matrix.addGroupModalTitle', {componentCode: 'sp2'});
          }
          self.modals.groupEditModal.settings.title = title;
          self.modals.groupEditModal.settings.cancelBtnName = ['sp2.modal.cancel', {componentCode: 'sp2'}];
          self.modals.groupEditModal.settings.okBtnName = ['sp2.modal.confirm', {componentCode: 'sp2'}];
          if (appVariables.readOnly) { //tryb RO dla DiD, o ile zajdzie taka potrzeba
            self.modals.groupEditModal.settings.cancelBtnName = '';
            self.modals.groupEditModal.settings.okBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
          }
          $timeout(function() {
            sp2CommonHelper.showModal('groupEditModal');
          }, 0);
        };
      };

      return new GroupModalHelper();
    }
  ]);