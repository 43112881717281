angular.module('ihestiaMenuBase')
  .service('taskCounterHelper', ['taskCounterSvc', '$rootScope', 'ihestiaCrossTabHelper', '$window', 'ihestiaConfigHelper',
    function(taskCounterSvc, $rootScope, ihestiaCrossTabHelper, $window, ihestiaConfigHelper) {

      var TaskCounterHelper = function() {
        var self = this;

        this.reloadInterval = 60000;
        this.offset = 500;
        this.tasksCountUpdatedEventCode = 'ihestiaCrossTab:tasksCountUpdate';
        
        this.tplData = {
          tasksCount : 0,
          lastUpdateTimestamp : 0
        };

        this.init = function() {
          // odbieranie aktualizacji licznika od innych zakładek
          $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
            if (data.code === self.tasksCountUpdatedEventCode) {
              self.updateTemplateData(data.attrs.tasksCount);
            }
          });
          
          var reloadOnLocationChange = ihestiaConfigHelper.get('taskCounter', 'reloadOnLocationChange');
          if (reloadOnLocationChange == null) {
            reloadOnLocationChange = true;
          }
          
          if (reloadOnLocationChange) {
            $rootScope.$on('$locationChangeSuccess', function($event, next, current) { 
              self.getTasksCountFromService();
            });
          }

          var reloadIntervalFromConfig = ihestiaConfigHelper.get('taskCounter', 'reloadInterval');
          if (reloadIntervalFromConfig) {
            self.reloadInterval = reloadIntervalFromConfig;
          }

          self.reloadTasksCountIfNecessary();
          $window.setInterval(function() {
            self.reloadTasksCountIfNecessary();
          }, self.reloadInterval);
        };
        
        this.updateTemplateData = function(tasksCount) {
          self.tplData.tasksCount = tasksCount;
          self.tplData.lastUpdateTimestamp = Date.now();
        };
        
        this.sendTasksCountUpdateToOtherTabs = function() {
          ihestiaCrossTabHelper.sendMessage({
            code: self.tasksCountUpdatedEventCode,
            attrs: {
              tasksCount: self.tplData.tasksCount
            }
          });
        };

        this.getTasksCountFromService = function () {
          taskCounterSvc.getTasksCount(null, function(result) {
            self.updateTemplateData(result.data.tasksCount);
            self.sendTasksCountUpdateToOtherTabs();
          }, lsnNg.noop, {allowedStatuses: [401, 402, 403, 404, 500, 501, 502, 503, 504]});
        };

        this.reloadTasksCountIfNecessary = function () {
          if (Date.now() - self.tplData.lastUpdateTimestamp + self.offset >= self.reloadInterval) {
            self.getTasksCountFromService(true);
          }
        };

        self.init();
      };

      return new TaskCounterHelper();
    }
  ]);