angular.module('ihestiaWidgets.policyDetails')
  .factory('RiskTableHelperConfigConstants', [function() {
    var CONSTANTS = {};
    CONSTANTS.POLICY_MODE = 'POLICY_MODE';
    CONSTANTS.OFFER_MODE = 'OFFER_MODE';
    return CONSTANTS;
  }])
  .factory('RiskTableHelperConfig', ['RiskTableHelperConfigConstants', function(RiskTableHelperConfigConstants) {
    var RiskTableHelperConfig = function() {
      var self = this;
      /**
       * @param {LsnRiskModelV2[]} risks
       * @param {string} [mapMode]
       * @return {string[]}
       */
      this.getIndexedRiskSymbols = function(risks, mapMode) {
        return _.chain(risks)
          .filter(mapMode === RiskTableHelperConfigConstants.POLICY_MODE ? self._isIndexedPolicyRisk : self._isIndexedIOfferRisk)
          .map('symbol')
          .value();
      };

      /**
       *
       * @param {LsnRiskModelV2[]} risks
       * @param {string} activeThresholdDate
       * @param {string} [mapMode]
       * @return {string[]}
       */
      this.getActiveRiskSymbols = function(risks, activeThresholdDate, mapMode) {
        return _.chain(risks)
          .filter(activeThresholdDate ? self._isActiveRisk(activeThresholdDate) : lsnNg.noop)
          .map(function(risk) {
            return mapMode === RiskTableHelperConfigConstants.POLICY_MODE && _.isString(risk.symbol)
              ? risk.symbol.substr(0, 3) : risk.symbol;
          })
          .value();
      };

      /**
       * @param risk
       * @return {*}
       * @private
       */
      this._isIndexedIOfferRisk = function(risk) {
        return _.get(risk, 'product.dynamicValues.toBeIndexed', false)
          && _.get(risk, 'product.dynamicValues.toBeIndexedInThisYear', false);
      };

      // IHESTLIFE-5141 check if risk was originally indexed
      this._isIndexedPolicyRisk = function(risk) {
        return _.get(risk, 'product.dynamicValues.sumInsuredIndexation', false);
      };

      this._isActiveRisk = function(activeThresholdDate) {
        return function(risk) {
          // IHESTLIFE-5056
          var endDate = _.get(risk, 'product.dynamicValues.agreementEndDate');
          return endDate > activeThresholdDate;
        };
      };
    };
    return RiskTableHelperConfig;
  }]);
