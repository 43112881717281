angular.module('salesPath2.loader', [])
  /**
   * provider loadera ścieżki
   * Tu można sprawdzić np. z jaką wersję configa uruchomić ścieżkę.
   * Pola typu this.currentAction zawierają inicjalne informacje o ścieżce (pierwsze wejście do aplikacji z parametrami w url)
   */
  .provider('sp2Loader', [
    function() {
      var LoadProvider = function() {
        var self = this;
        this.appLoaded = false; //czy aplikcja wczytana

        /**
         * @type (string|null) inicjalna akcja
         */
        this.currentAction = null;

        /**
         * @type object inicjalne parametry akcji i ch wartości
         */
        this.currentActionParams = {};

        /**
         * przetwarza url i uzupelnia akcje i jej parametry
         */
        this.initialProcessUrl = function() {
          var queryArray = window.location.hash.split('?'),
            params = (queryArray.length > 1) ? queryArray[1].split('&') : [];
          angular.forEach(params, function(param) {
            var pair = param.split('=');
            if (pair[0] === '' || typeof pair[1] === 'undefined') {
              return;
            }
            var name = pair[0].trim().toLowerCase(),
              value = decodeURIComponent(pair[1].replace(/\+/g, ' '));
            if (name === 'action') { //akcja
              self.currentAction = value.toLowerCase();
            } else { //parametr
              self.currentActionParams[name] = value;
            }
          });
        };

        /**
         * to co zwróci sp2Loader
         * @param  {[type]} sp2LoaderHelper [description]
         * @return {[type]}               [description]
         */
        this.$get = ['sp2LoaderHelper', function(sp2LoaderHelper) {
          return sp2LoaderHelper;
        }];

        this.initialProcessUrl();
      };

      return new LoadProvider();
    }
  ]);