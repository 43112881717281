/**
 * sprawdza, i ustawia flagę w scope, czy element schował się poza ekranem w wyniku scrollowania strony
 */
angular.module('ihestiaCommonDirectives')
  .directive('elementScrolledOut', ['$document', '$parse', '$timeout',
    function($document, $parse, $timeout){
      return {
        restrict: 'A',
        link: function($scope, $element, $attrs){


          var scopeDataContainer = $attrs.elementScrolledOut;
          var topHiddenOffset = 0;

          $attrs.$observe('elementScrolledOutOffset', function(elementScrolledOutOffset){
            if(angular.isDefined(elementScrolledOutOffset))
            {
              topHiddenOffset = parseInt(elementScrolledOutOffset, 10);
            }
          });

          var checkPosition = function()
          {
            if($element.hasClass('ng-hide') || $element.parents().hasClass('ng-hide'))
            {
              // element jest ukryty i nie chcemy badać przesunięcia
              return false;
            }

            var topScrollOffset = $document.scrollTop();
            var elementPosition = $element.offset().top;

            if(elementPosition - topScrollOffset - topHiddenOffset <= 0)
            {
              $scope[scopeDataContainer].elementReachedTop = true;
            }
            else
            {
              $scope[scopeDataContainer].elementReachedTop = false;
            }

            return true;
          };

          var checkPositionAfterTimeout = function () {
            $timeout(checkPosition);
          };

          checkPosition();

          $document.on('scroll', checkPositionAfterTimeout);

          $scope.$on('$destroy', function() {
            $document.off('scroll', checkPositionAfterTimeout);
          });

        }
      };
    }]);