angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardOperatorChooserConstants', ['$filter', function($filter) {
    var CONSTANTS = {};
    CONSTANTS.RECEIVER_TYPE_WORKER = 'Worker';
    CONSTANTS.RECEIVER_TYPE_CENTRAL = 'Central';
    CONSTANTS.RECEIVER_TYPE_PRELOADED_OPERATOR = 'PreLoadedOperator';
    CONSTANTS.RECEIVER_TYPE_NO_CHANGE = 'NoChange';
    CONSTANTS.DEFAULT_RECEIVER_TYPES = [{
      name: $filter('translate')('defaultOption', {
        componentCode: 'Dashboard'
      }),
      code: ''
    }, {
      name: $filter('translate')('receiverTypesAgency', {
        componentCode: 'Dashboard'
      }),
      code: CONSTANTS.RECEIVER_TYPE_WORKER
    }, {
      name: $filter('translate')('receiverTypesCentral', {
        componentCode: 'Dashboard'
      }),
      code: CONSTANTS.RECEIVER_TYPE_CENTRAL
    }];
    return CONSTANTS;
  }])
  .controller('ihestiaDashboardOperatorChooserCtrl', ['$scope', '$filter', 'IhestiaDashboardWorkerSubjectModel', 'IhestiaDashboardSaleSubjectModel', 'IhestiaDashboardPoolSubjectModel', 'ihestiaOssSalesprofileSvc', 'ihestiaOssWorkerSvc', '$q', 'ihestiaDashboardPoolsSvc', 'ihestiaUsersSvc', 'IhestiaDashboardSubjectModelConstants', 'ihestiaAgHelper', 'ihestiaConfigHelper', 'IhestiaDashboardOperatorChooserConstants',
    function($scope, $filter, IhestiaDashboardWorkerSubjectModel, IhestiaDashboardSaleSubjectModel, IhestiaDashboardPoolSubjectModel, ihestiaOssSalesprofileSvc, ihestiaOssWorkerSvc, $q, ihestiaDashboardPoolsSvc, ihestiaUsersSvc, IhestiaDashboardSubjectModelConstants, ihestiaAgHelper, ihestiaConfigHelper, IhestiaDashboardOperatorChooserConstants) {

      $scope.spinnerOptions = {
        label: '',
        size: ''
      };

      $scope.elementsVisibility = {
        receiverType: true,
        pool: true,
        worker: true
      };

      $scope.isAgencyOwner = false;

      $scope.contextId = 'dashboardNewTask';

      $scope.multiselectLocalLang = {
        selectAll: $filter('translate')('Public.multiselectSelectAll'),
        selectNone: $filter('translate')('Public.multiselectSelectNone'),
        reset: $filter('translate')('Public.multiselectReset'),
        search: $filter('translate')('Public.multiselectSearch'),
        nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
        noOption: $filter('translate')('Public.multiselectNoOption')
      };

      $scope.pools = [];
      $scope.modalData = {
        receiverType: '',
        poolId: null,
        login: null, //login pracownika centrali
        saleNet: {
          agencyKey: null,
          workerKey: null
        }
      };
      $scope.receiverTypes = null;

      //agencja i pracownik agencji
      $scope.loaded = { //czy mamy załadowaną listę/zasób
        salesProfiles: false, //agencja
        workers: false, //pracownik agencji
        poolWorkers: false //pracownicy z uprawnieniem do wybranej puli
      };

      $scope.guids = {
        salesProfiles: null
      };

      $scope.open = {
        salesProfiles: false,
        workers: false,
        pools: false,
        poolWorkers: false
      };

      $scope.operatorChooserData.loaded = $scope.loaded; //wystawiamy dla rodzica
      $scope.operatorChooserData.modalData = $scope.modalData;


      $scope.previous = { //musimy znać poprzednie wartości jak chcemy odznaczać
        worker: null
      };

      $scope.lastTextFilter = {
        salesProfiles: null
      };

      $scope.salesProfileDictionary = [];
      $scope.workerDictionary = [];

      $scope.poolWorkerDictionary = [];

      $scope.labels = {
        receiverTypes: null
      };
      /**
       * @name GetOperatorData
       * @function
       * @return {IhestiaDashboardWorkerSubject}
       *
       * @typedef ChooseOperatorApi
       * @property {GetOperatorData} getOperatorData
       * @property {{operator: IhestiaDashboardWorkerSubject, operatorName: string}} preLoadedData
       * @property {Array<{name: string, code: string}>}receiverTypes
       * @property {string} receiverTypesLabel
       */
      /**
       * $scope.chooseOperatorApi - an object of type ChooseOperatorApi set by parent controller, which customizes operator chooser.
       * Implemented customization options ($scope.chooseOperatorApi.*):
       * 1. getOperatorData = (function) invoking this function returns current chosen operator data
       * 2. preLoadedData = {operator: IhestiaDashboardWorkerSubject, operatorName: string} - if the operator is known beforehand,
       *    it can be set for example from custom receiver types list (using 'PreLoadedOperator' code)
       * 3. receiverTypes = (Array<receiverType>)custom receiver types
       * 4. receiverTypesLabel = (string) custom template label for receiver types
       */

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.labels.receiverTypes = $filter('translate')('typeRecipient', {componentCode: 'Dashboard'});
        $scope.loadDictionaries();
        // $scope.setDefaultOperatorData();
        // sets custom data if present
        if ($scope.chooseOperatorApi) {
          $scope.chooseOperatorApi.getOperatorData = $scope.getOperatorData;
          if ($scope.chooseOperatorApi.receiverTypes) {
            $scope.receiverTypes = $scope.chooseOperatorApi.receiverTypes;
          }
          if ($scope.chooseOperatorApi.receiverTypesLabel) {
            $scope.labels.receiverTypes = $scope.chooseOperatorApi.receiverTypesLabel;
          }
        }
        if (!$scope.receiverTypes) {
          $scope.receiverTypes = IhestiaDashboardOperatorChooserConstants.DEFAULT_RECEIVER_TYPES;
        }
        // settings default receiver type here, for deatiled users setting it also in setDefaultOperatorData
        $scope.modalData.receiverType = _.get(_.find($scope.receiverTypes, ['default', true]), 'code', undefined) || _.get(_.first($scope.receiverTypes), 'code');
        $scope.setDefaultOperatorData();
      };

      $scope.getOperatorData = function() {
        // @todo wyswietlanie walidacji
        var operator = {}; // NOSONAR

        if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_WORKER) {
          // agencja
          var saleNet = $scope.modalData.saleNet;
          operator = new IhestiaDashboardWorkerSubjectModel();

          //jeli wybrano agencje i wskazano pracownika
          if (saleNet.agencyKey && saleNet.workerKey) {
            operator.saleProfileId = saleNet.agencyKey;
            operator.workerId = saleNet.workerKey;
            operator.type = IhestiaDashboardSubjectModelConstants.TYPE_WORKER;
          }

          //jeli wybrano agencje bez wskazania pracownika
          if (saleNet.agencyKey && !saleNet.workerKey) {
            operator.saleProfileId = saleNet.agencyKey;
            operator.type = IhestiaDashboardSubjectModelConstants.TYPE_SALE_PROFILE;
          }

        } else if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_NO_CHANGE) {
          operator = null;
        } else if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_PRELOADED_OPERATOR) {
          operator = $scope.chooseOperatorApi.preLoadedData.operator;
        } else {
          operator = new IhestiaDashboardWorkerSubjectModel();
          operator.poolId = $scope.modalData.poolId;

          //jesli wskazano pracownika - typ "pool_worker" i bierzemy login wybranego pracownika
          //jesli nie typ "pool"
          if ($scope.modalData.login) {
            operator.type = IhestiaDashboardSubjectModelConstants.TYPE_POOL_WORKER;
            operator.login = $scope.modalData.login;
          } else {
            operator.type = IhestiaDashboardSubjectModelConstants.TYPE_POOL;
          }

        }

        return operator ? operator.getData() : null;
      };

      /**
       * Domyślne ustawienia w zależności od uprawnień
       */
      $scope.setDefaultOperatorData = function() {
        // sets custom data
        if (_.get($scope.chooseOperatorApi, 'preLoadedData.operator.saleProfileId')) {
          $scope.setDefaultOperatorDataFromPreloaded();
        } else if (($scope.currentCharacter.type === $scope.UserCharacterTypeConstants.CHARACTER_TYPE_WORKER && ihestiaAgHelper.hasAgencyOwnerRights()) || //pracownik agencji na prawach właściciela
          $scope.currentCharacter.type === $scope.UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE) //agencja
        {
          $scope.elementsVisibility.worker = false;
          $scope.elementsVisibility.pool = false;
          $scope.isAgencyOwner = true;
        } else if ($scope.currentCharacter.type === $scope.UserCharacterTypeConstants.CHARACTER_TYPE_WORKER && _.some($scope.receiverTypes, ['code', IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_NO_CHANGE])){
          // when receiver types contain NoChange option, we display the receiver types select
          $scope.elementsVisibility.receiverType = true;
          $scope.elementsVisibility.worker = false;
          $scope.elementsVisibility.pool = false;
        } else if ($scope.currentCharacter.type === $scope.UserCharacterTypeConstants.CHARACTER_TYPE_WORKER) {
          $scope.modalData.receiverType = IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_CENTRAL;
          $scope.elementsVisibility.receiverType = false;
          $scope.elementsVisibility.worker = false;
          $scope.elementsVisibility.pool = false;
        }
      };


      /**
       * zmienił się typ odbiorcy
       */
      $scope.receiverTypeChanged = function() {
        if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_WORKER) {
          //czyścimy śmieci
          $scope.modalData.pool = null;
          $scope.modalData.poolId = null;
          $scope.modalData.login = null;

          if (!$scope.loaded.salesProfiles) {
            if ($scope.isAgencyOwner) {
              $scope.setDefaultSalesProfile();
            } else {
              $scope.prepareSalesProfiles();
            }
          }
        } else if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_PRELOADED_OPERATOR) {
          // clear central data
          $scope.modalData.pool = null;
          $scope.modalData.poolId = null;
          $scope.modalData.login = null;
          $scope.setOperatorFromPreLoadedData();
        } else if ($scope.modalData.receiverType === IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_NO_CHANGE) {
          // clear central and agency data
          $scope.modalData.pool = null;
          $scope.modalData.poolId = null;
          $scope.modalData.login = null;
          $scope.modalData.saleNet = {
            agencyKey: null,
            workerKey: null
          };
        } else {
          //czyścimy śmieci
          $scope.modalData.saleNet = {
            agencyKey: null,
            workerKey: null
          };
        }
      };

      /**
       * ustawiamy domyślną agencje na podstawie danych użytkownika
       */
      $scope.setDefaultSalesProfile = function() {
        $scope.salesProfileDictionary = [];

        var icContext = ihestiaConfigHelper.get('ic', 'activeContext');
        var agencyNumber;
        if (icContext === 'EHL') {
          // agencja życiowa
          agencyNumber = $scope.currentCharacter.ossContextInfo.SalesProfileLifeSymbol;
        } else {
          // agencja mieniowa
          agencyNumber = $scope.currentCharacter.ossContextInfo.SalesProfilePropertySymbol;
        }

        $scope.salesProfileDictionary.push({
          subjectKeyString: 'SalesProfile_' + agencyNumber,
          symbol: agencyNumber,
          selected: true
        });
        $scope.modalData.saleNet.agencyKey = 'SalesProfile_' + agencyNumber;
        $scope.loaded.salesProfiles = true;
        $scope.salesProfileClick().then(lsnNg.noop, lsnNg.noop);
      };

      /**
       * Sets default operator from passed data, can set only Agency or specific Worker
       */
      $scope.setDefaultOperatorDataFromPreloaded = function() {
        $scope.salesProfileDictionary = [];
        $scope.salesProfileDictionary.push({
          subjectKeyString: $scope.chooseOperatorApi.preLoadedData.operator.saleProfileId,
          symbol: $scope.chooseOperatorApi.preLoadedData.operator.saleProfileNumber || $scope.chooseOperatorApi.preLoadedData.operator.saleProfileId,
          selected: true
        });
        $scope.modalData.saleNet.agencyKey = $scope.chooseOperatorApi.preLoadedData.operator.saleProfileId;
        var hasWorkerId = false;
        if($scope.chooseOperatorApi.preLoadedData.operator.workerId) {
          hasWorkerId = true;
          $scope.modalData.saleNet.workerKey = $scope.chooseOperatorApi.preLoadedData.operator.workerId;
        }
        $scope.modalData.receiverType = IhestiaDashboardOperatorChooserConstants.RECEIVER_TYPE_WORKER;
        $scope.prepareSalesProfiles()
          .then($scope.salesProfileClick, lsnNg.noop)
          .then(function() {
            if(hasWorkerId) {
              var worker = _.find($scope.workerDictionary, ['subjectKeyString', $scope.chooseOperatorApi.preLoadedData.operator.workerId]);
              if (worker) {
                worker.selected = true;
              }
            }
          }, lsnNg.noop);
      };

      /**
       * tworzymy zasób wyszukwiania
       * @return {Promise}
       */
      $scope.prepareSalesProfiles = function() {
        return ihestiaOssSalesprofileSvc.findByParams({
          id: $scope.contextId,
          count: 0,
          skip: 0,
          dynamicFilters: {}
        }).then(function(response) {
          $scope.guids.salesProfiles = response.data.id;
          $scope.loaded.salesProfiles = true;
        }, function() {
          $scope.loaded.salesProfiles = true;
        });
      };

      /**
       * Autocompleter do puli
       * @param  {String} searchValue
       * @return {Promise}
       */
      $scope.getPools = function(searchValue) {
        return $q(function(resolve) {
          var pools = [];
          angular.forEach($scope.pools, function(pool) {
            if (pool.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
              pools.push(pool);
            }
          });
          resolve(pools);
        });
      };

      /**
       * zwraca obiekt wybranej puli
       * @return {Object}
       */
      $scope.getSelectedPool = function() {
        var selectedPool = null;
        if ($scope.modalData.poolId) {
          angular.forEach($scope.pools, function(pool) {
            if (pool.id === $scope.modalData.poolId) {
              selectedPool = pool;
            }
          });
        }
        return selectedPool;
      };

      $scope.poolClick = function() {
        $scope.poolWorkerDictionary = [];
        $scope.loaded.poolWorkers = false;
        $scope.loadPoolWorkers();
      };

      $scope.loadPoolWorkers = function() //eslint-disable-line consistent-return
      {
        if (!$scope.modalData.poolId) {
          return false; //nie mamy wybranej puli
        }

        ihestiaUsersSvc.pageStyle = false;

        var selectedPool = $scope.getSelectedPool();
        $scope.loaded.poolWorkers = false;
        ihestiaUsersSvc.findByParams({
          count: 5000,
          top: 5000,
          skip: 0,
          sortBy: 'personName',
          dynamicFilters: {
            atoms: ['DashboardTaskPools/' + selectedPool.userPermissionName],
            columns: 'personName,login'
          },
          callback: function(response) {
            $scope.poolWorkerDictionary = response.data.items;
            angular.forEach($scope.poolWorkerDictionary, function(poolWorker) {
              poolWorker.personNameFull = poolWorker.personName + ' ' + poolWorker.login;
            });
            $scope.loaded.poolWorkers = true;
          },
          errorCallback: function() {
            $scope.loaded.poolWorkers = true;
          }
        });
      };

      /**
       * Ładujemy słowniki
       * @return {undefined}
       */
      $scope.loadDictionaries = function() {
        $scope.$emit('showBlockUi');
        $scope.pools = [];
        ihestiaDashboardPoolsSvc.get().then(function(res) {
          if (res && res.data) {
            $scope.pools = res.data;
          }
        }, angular.noop).finally(function() {
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * zmieniła się fraza wyszukiwania na agencji
       * @param  {[type]} data [description]
       */
      $scope.salesProfileSearchChanged = function(data) {
        if (data.keyword.length > 0) {
          $scope.searchSalesNetwork('salesProfiles', data.keyword);
        } else {
          $scope.parseSalesNetworkSearch('salesProfiles', []); //tylko czyścimy niezaznaczone elementy
        }
      };

      $scope.searchSalesNetwork = function(type, textFilter) {
        if ($scope.guids[type] === null || textFilter === $scope.lastTextFilter[type]) {
          return;
        }
        $scope.lastTextFilter[type] = textFilter;

        var filters = {
          filter: textFilter,
          skip: 0,
          count: 50
        };

        var svcObject;
        if (type === 'salesProfiles') {
          svcObject = ihestiaOssSalesprofileSvc;
        } else if (type === 'workers') {
          svcObject = ihestiaOssWorkerSvc;
        }

        if (svcObject) {
          svcObject.get($scope.contextId, filters, 'searches/' + $scope.guids[type], function(response) {
            $scope.parseSalesNetworkSearch(type, response.data.items);
          }, lsnNg.noop);
        }
      };


      /**
       * parsujemy odpowiedź z usługi
       * @param  {array} networks
       */
      $scope.parseSalesNetworkSearch = function(type, networks) {
        var newList = [];
        var dictionaryCode;
        var characterNode;
        if (type === 'salesProfiles') {
          dictionaryCode = 'salesProfileDictionary';
          characterNode = 'agencyKey';
        } else if (type === 'workers') {
          dictionaryCode = 'workerDictionary';
          characterNode = 'workerKey';
        }

        angular.forEach(networks, function(searchedNetwork) {
          if (searchedNetwork.subjectKeyString !== $scope.modalData.saleNet[characterNode]) {
            newList.push(searchedNetwork); //element nie był zaznaczony, więc go dorzucamy do listy
          }
        });

        //zaznaczony element wpychamy na koniec, jeśli trzeba będzie go przesunąć na przód to trzeba też będzie przebudować indeksy multiselecta
        //jeśli mamy wybrany element z listy to zachowujemy go
        if ($scope.modalData.saleNet[characterNode]) {
          angular.forEach($scope[dictionaryCode], function(item) {
            if (item.subjectKeyString === $scope.modalData.saleNet[characterNode]) {
              newList.push(item);
            }
          });
        }

        $scope[dictionaryCode] = newList;
      };

      /**
       * jeśli mamy select agencji i pracownika to chcemy załadować pracowników po wyborze agencji
       * @return {Promise}
       */
      $scope.salesProfileClick = function() {
        $scope.workerDictionary = [];
        $scope.loaded.workers = false;
        return $scope.loadWorkers();
      };

      /**
       * wybranie pracownika z listy
       */
      $scope.workerClick = function() {
        if ($scope.modalData.saleNet.workerKey === $scope.previous.worker) //kliknięcie we wcześniej wybrany element
        {
          //odznaczamy element
          angular.forEach($scope.workerDictionary, function(worker) {
            if (worker.selected) {
              worker.selected = false;
            }
          });
          $scope.previous.worker = null;
        } else {
          $scope.previous.worker = $scope.modalData.saleNet.workerKey;
        }
      };

      /**
       * ładujemy listę pracowników
       * @return {Promise}
       */
      $scope.loadWorkers = function()
      {
        if (!$scope.modalData.saleNet.agencyKey) {
          return false; //liste ładujemy dla wybranej agencji
        }
        return ihestiaOssWorkerSvc.findByParams({
          id: $scope.contextId,
          count: 10000,
          top: 10000,
          skip: 0,
          dynamicFilters: {
            salesProfilesKeysStrings: [$scope.modalData.saleNet.agencyKey]
          }
        }).then(function(response) {
          $scope.workerDictionary = response.data.items;
          $scope.loaded.workers = true;
          return response;
        }, function() {
          $scope.loaded.workers = true;
        });
      };

      $scope.init();
    }
  ]);
