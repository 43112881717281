angular.module('ihestiaCommonDirectives').config(['$alertProvider', function($alertProvider) {
  angular.extend($alertProvider.defaults, {
    title: '',
    animation: 'am-fade-and-slide-top',
    placement: 'top-right',
    template: 'ihestia-commondirectives-template-app/directives/alert/defaultTemplate.tpl.html',
    show: true,
    duration: 3
  });
}]);

