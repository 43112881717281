angular.module('lsnBase.models')
  .service('lsnModelFactory', ['lsnCommonErrorHandler', '$injector', 'lsnModelFactoryParams',
    function(lsnCommonErrorHandler, $injector, lsnModelFactoryParams) {
      var self = this;

      /**
       * prefiksy dla modeli danych ułożone od najwyższego priorytetu
       * @type {String[]}
       */
      this.modelPrefixes = lsnModelFactoryParams.modelPrefixes;

      /**
       * czy pozwolic na niewersjonowane modele danych (bez przyrostka "V..."")
       * @type {Boolean}
       */
      this.allowNotVersioned = lsnModelFactoryParams.allowNotVersioned;

      /**
       * domyślne parametry konstruktora obiektu
       * @type {Object}
       */
      this.defaultParams = {
        data: null, // dane obiektu
        version: lsnModelFactoryParams.defaultVersion // wersja używanego modelu
      };

      /**
       * zwraca parametry dla konstruktora modelu, biorąc pod uwagę opcje domyślne i te przekazane
       * @param  {Object|String} givenParams przekazane parametry. Jeśli string, to traktujemy go jak wersję. W innym wypadku, próbujemy ustalić wersję na podstawie data.metaData.version
       * @return {Object}
       */
      this.getParams = function(givenParams) {
        var params = angular.copy(self.defaultParams);
        if (angular.isString(givenParams)) { // jesli jako opcje przekazano stringa, to jest on traktowany jak wersja modelu
          params.version = givenParams;
        } else if (angular.isObject(givenParams)) {
          angular.forEach(params, function(val, name) {
            if (angular.isDefined(givenParams[name])) {
              params[name] = givenParams[name];
            }
          });
          // określenie wersje na podstawie danych z obiektu
          if (angular.isObject(givenParams.data) && angular.isObject(givenParams.data.metaData) && givenParams.data.metaData.version) {
            params.version = self.getMajorVersion(givenParams.data.metaData.version);
          }
        }

        return params;
      };

      /**
       * zwraca obiekt dla danego modelu i przekazanych parametrów
       * @param  {String} model nazwa modelu, którego instancję chcemy dostać
       * @param  {Object|String|null} params przekazane parametry (takie jak w defaultParams). Jeśli string, to traktujemy go jak wersję. W innym wypadku, próbujemy ustalić wersję na podstawie data.metaData.version
       * @param  {Boolean} allowNull czy pozwolić na nieodnalezienie żądanego modelu - zwracamy nulla
       * @return {Object|null} null gdy allowNull===true i niezaleziono modelu
       */
      this.getObject = function(model, params, allowNull) {
        var parsedParams = self.getParams(params),
          modelClassName = self.getModelClassName(model, parsedParams.version);
        if (modelClassName !== null) {
          var modelClass = $injector.get(modelClassName),
            obj = new modelClass(parsedParams.data); // eslint-disable-line new-cap
          obj.dataVersion = parsedParams.version;
          if (parsedParams.data !== null) {
            obj.setData(parsedParams.data);
          }
          return obj;
        }
        if (allowNull) {
          return null;
        }
        lsnCommonErrorHandler.throwException('No model class found for model {0}, params: {1}.'.format(model, JSON.stringify(params)));
      };

      /**
       * zwraca główną wersję z podanej (np. 'v2' z 'v2.1.4')
       * @param  {String} ver wersja
       * @return {String}
       */
      this.getMajorVersion = function(ver) {
        var dotIdx = ver.indexOf('.');
        if (dotIdx === -1) {
          return ver;
        }
        return ver.substr(0, dotIdx);
      };

      /**
       * szuka w injectorze i zwraca nazwę klasy modelu lub null gdy nie znaleziono
       * Zasada szukania:
       * Zaczynamy od podanej wersji np. 'v3' i szukamy aż do 'v1'. Zwracana jestsza znaleziona wersja.
       * @param  {String} model nazwa modelu, dla którego szukamy klasy
       * @param  {String} [ver] wersja modelu. Jeśli nie podana to odczytujemy domyślną dla projektu
       * @return {String|null} 
       */
      this.getModelClassName = function(model, ver) {
        ver = ver || self.defaultParams.version;
        var className = null;
        lsnNg.forEach(self.modelPrefixes, function(prefix) {
          className = self._getModelClassNameByPrefix(model, ver, prefix);
          if (className !== null) {
            return false;
          }
          return true;
        });
        return className;
      };

      /**
       * szuka w injectorze i zwraca nazwę klasy modelu lub null gdy nie znaleziono
       * Zasada szukania:
       * Zaczynamy od podanej wersji np. 'v3' i szukamy aż do 'v1'. Zwracana jestsza znaleziona wersja.
       * @param  {String} model nazwa modelu, dla którego szukamy klasy
       * @param  {String} ver wersja modelu
       * @param  {String} prefix prefiks modelu
       * @return {String|null} 
       */
      this._getModelClassNameByPrefix = function(model, ver, prefix) {
        var modelClassNamePart = prefix + model + 'ModelV',
          modelClassName = '',
          verInt = parseInt(ver.substr(1), 10);

        while (verInt > 0) {
          modelClassName = modelClassNamePart + verInt;
          if ($injector.has(modelClassName)) {
            return modelClassName;
          }
          verInt -= 1;
        }
        if (self.allowNotVersioned) {
          modelClassName = prefix + model + 'Model';
          if ($injector.has(modelClassName)) {
            return modelClassName;
          }
        }
        return null;
      };

    }
  ]);