//przy opisie pamietac by opisac ze wymagane jest person bezposrednio w scope
angular.module('salesPath2')
  .directive('clientSearch', ['availabilityHelper', 'personHelper', 'CONFIG', '$q',
    function(availabilityHelper, personHelper, CONFIG, $q) {
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else {
            return 'salesPath2Template/autocompleters/clientSearch/clientSearch.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope) {
          $scope.clientSearchViewData = {
            loadingElements: false
          };
          $scope.dictAvailable = availabilityHelper.isAvailable('addressSearch');
          if ($scope.dictAvailable) {

            $scope.findPersons = function(val) {
              return $q(function(resolve){
                $scope.clientSearchViewData.loadingElements = true;
                var persons = personHelper.findPersons(val);
                var obj = [];
                if (angular.isFunction(persons.then)) {
                  persons.then(function(items) {
                    personHelper.filterPersonsAdded(items, $scope.dataContainer.persons);

                    var showNip = false;
                    if (CONFIG.BEHAVIOR.mainInsuredAdditionalFields.indexOf('NIP') !== -1) {
                      showNip = true;
                    }

                    angular.forEach(items, function(person) {
                      person.addressInOneString = personHelper.personAddressToOneString(person);
                      obj.push({
                        person: person,
                        showNip: showNip
                      });
                    });
                    resolve(obj);
                  }, function(){
                    resolve();
                  }).finally(function(){
                    $scope.clientSearchViewData.loadingElements = false;
                  });
                } else {
                  $scope.clientSearchViewData.loadingElements = false;
                  resolve();
                }
              });


            };

          }
        }]
      };
    }
  ]);