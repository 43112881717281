angular.module('lsnBase.models')
  .factory('LsnApplicationOperationDataModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationOperationDataModelV2 = function() {
        this.objectName = 'ApplicationOperationData';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          policyNumber: {
            type: 'string'
          },
          policyVer: {
            type: 'string'
          },
          changeDate: {
            type: 'dateTime'
          },
          allowedChanges: {
            type: 'ApplicationAllowedChanges',
            version: 'v2'
          }
        };

        this.policyNumber = null; // numer polisy dla operacji
        this.policyVer = null; // id polisy dla operacji
        this.changeDate = null; // data dokonania zmian
        this.allowedChanges = null; // dozwolone zmiany w ramach operacji
      };

      LsnApplicationOperationDataModelV2.prototype = LsnAbstractModel.prototype;

      return LsnApplicationOperationDataModelV2;
    }
  ]);