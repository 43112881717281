angular.module('lsnBase.constants')
  /**
   * stałe
   * @todo  parę rzeczy trzeba będzie wynieść stąd do podrzędnych modułów, które będą rozszerzać te CONSTANTSy.
   * @return {Object}
   */
  .provider('LSNCONSTANTS', [function() {

    var CONSTANTS = {};
      CONSTANTS.PRODUCT_OCZP = 'OCZP';
      CONSTANTS.PRODUCT_OGI = 'OGIEN';
      CONSTANTS.PRODUCT_KRA = 'KRA';
      CONSTANTS.PRODUCT_OGI_KRA = 'OGIEN_KRA'; // np. wspolny wariant ognia z kradzieza
      CONSTANTS.PRODUCT_NNW = 'NNW';
      CONSTANTS.PRODUCT_NNW_INCOGNITO = 'NNW_INCOGNITO';
      CONSTANTS.PRODUCT_ACKOM = 'ACKOM';
      CONSTANTS.PRODUCT_NNWKIP = 'NNWKIP';
      CONSTANTS.PRODUCT_OCKOM = 'OCKOM';
      CONSTANTS.PRODUCT_SPORT_ASSISTANCE = 'SPORT_ASSISTANCE';
      CONSTANTS.PRODUCT_REHABILITATION = 'REHABILITATION';
      CONSTANTS.PRODUCT_BAGGAGE = 'PROD_BAGGAGE';
      CONSTANTS.PRODUCT_MEDICAL_EXPENSES = 'MEDICAL_EXPENSES';

      CONSTANTS.RISK_ESTATE = 'estate';
      CONSTANTS.RISK_EQUIPMENT = 'equipment';
      CONSTANTS.RISK_PERSONAL_BELONGINGS = 'personalBelongings';
      CONSTANTS.RISK_FLOOD = 'flood';
      CONSTANTS.RISK_COLLECTIONS = 'collections';
      CONSTANTS.RISK_CURRENT_ASSETS = 'currentAssets';
      CONSTANTS.RISK_THIRD_PARTIES_PROPERTY = 'thirdPartiesProperty';
      CONSTANTS.RISK_CASH = 'cash';
      CONSTANTS.RISK_PROPERTY_ARCHITECTURE = 'propertyArchitecture';
      CONSTANTS.RISK_GARDEN = 'garden';
      CONSTANTS.RISK_GARAGE = 'garage';
      CONSTANTS.RISK_AC = 'ACKOM';
      CONSTANTS.RISK_ACKR = 'ACKOMKR';
      CONSTANTS.RISK_OC = 'OCKOM';
      CONSTANTS.RISK_NNWKIP = 'NNWKIP';
      CONSTANTS.RISK_WD = 'ADDEQ';
      CONSTANTS.RISK_OUTBUILDINGS = 'outBuildings';
      CONSTANTS.RISK_PHONE_LEGAL_ASSISTANT = 'phoneLegalAssistant';
      CONSTANTS.RISK_GARDEN_ARCHITECTURE_FENCE = 'gardenArchitectureFence';
      CONSTANTS.RISK_GARDEN_ARCHITECTURE = 'gardenArchitecture';
      CONSTANTS.RISK_FENCE = 'fence';
      CONSTANTS.RISK_MOVEABLE_PROPERTY = 'moveableProperty';
      CONSTANTS.RISK_PROPERTY_GLAZING = 'propertyGlazing';
      CONSTANTS.RISK_ELEMENTS = 'elements';
      CONSTANTS.RISK_SATELLITE = 'satelite';
      CONSTANTS.RISK_SPORT_ASSISTANCE = 'SPORT_ASSISTANCE';
      CONSTANTS.RISK_REHABILITATION = 'REHABILITATION';
      CONSTANTS.RISK_BAGGAGE = 'PROD_BAGGAGE';
      CONSTANTS.RISK_MEDICAL_EXPENSES = 'MEDICAL_EXPENSES';

      CONSTANTS.ADD_CARGO = 'CARGO';
      CONSTANTS.ADD_ASSDOM = 'ASSDOM';
      CONSTANTS.ADD_ASSPOJ = 'ASSPOJ';
      CONSTANTS.ADD_ASSMEDIC = 'ASSMEDIC';
      CONSTANTS.ADD_OCNAJ = 'OCNAJ';
      CONSTANTS.ADD_TRAVEL = 'TRAVEL';
      CONSTANTS.ADD_OUZ = 'OUZ';
      CONSTANTS.ADD_OCHPRAW = 'OCHPRAW';
      CONSTANTS.ADD_WD = 'EXTRA';
      CONSTANTS.ADD_GREENCARD = 'GREENCARD';
      CONSTANTS.ADD_SSU = 'SSU';
      CONSTANTS.ADD_BAGGAGE = 'BAGGAGE';
      CONSTANTS.ADD_GLAZING = 'GLAZING';
      CONSTANTS.ADD_HCA_TOWING_VEHICLE = 'HCA_TOWING_VEHICLE';
      CONSTANTS.ADD_HCA_THEFT = 'HCA_THEFT';
      CONSTANTS.ADD_HCA_REPLACEMENT_VEHICLE = 'HCA_REPLACEMENT_VEHICLE';
      CONSTANTS.ADD_OUZ_OC = 'ADD_OUZ_OC';
      CONSTANTS.ADD_COST_OF_CAUSE_SEARCHING = 'ADD_COST_OF_CAUSE_SEARCHING';
      CONSTANTS.ADD_TICKETS = 'TICKETS';
      CONSTANTS.ADD_SPORT_EQUIPMENT = 'SPORT_EQUIPMENT';
      CONSTANTS.ADD_TRAVEL_ACCIDENT = 'TRAVEL_ACCIDENT';

      CONSTANTS.VARIANT_I = 'I';
      CONSTANTS.VARIANT_II = 'II';
      CONSTANTS.VARIANT_III = 'III';
      CONSTANTS.VARIANT_IV = 'IV';

    /**
     * Zwracamy stałe
     * @return {object} [description]
     */
    this.getConstants = function() {
      return angular.copy(CONSTANTS);
    };

    /**
     * get providera
     * @return {object} [description]
     */
    this.$get = function() {
      return CONSTANTS;
    };
  }]);