angular.module('ihestiaRestServicesBase')
  .service('clientVerificationSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var ClientVerification = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      var self = this;

      /**
       * resource
       * NITE
       * proxyPass for QA env:
       *   ProxyPass /api/clients/v1/VerifyIdExisting/ http://10.111.229.205:8202/api/clients/v1/VerifyIdExisting/
       *   ProxyPassReverse /api/clients/v1/VerifyIdExisting/ http://10.111.229.205:8202/api/clients/v1/VerifyIdExisting/
       * @type {String}
       */
      this.resource = 'VerifyIdExisting';
      this.businessArea = 'clients';

      /**
       *
       * @param {{
       * [Id]: string // pesel
       * }} data
       * @param {object} httpParams
       * @return Promise<any[]>
       */
      this.verifyByPesel = function(data, httpParams) {
        return self.post('VerifyId', data, undefined, undefined, undefined, httpParams);
      };
    };
    return new ClientVerification();
  }]);
