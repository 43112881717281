angular.module('ihestiaCommonDirectives.application')
  .factory('IhestiaPolicyHelper', ['simplePolicyHelper', 'AbstractPolicyAndOfferHelper', 'ihestiaRolesOnPolicyConstants',
    function(simplePolicyHelper, AbstractPolicyAndOfferHelper, ihestiaRolesOnPolicyConstants) {

      var PolicyHelper = function(policy, policyId, policyNumber) {
        var abstractHelper = new AbstractPolicyAndOfferHelper(policy);
        angular.extend(this, abstractHelper);

        this.policyId = policyId;
        this.policyNumber = policyNumber;

        /**
         * polisy
         * @type {PolisyModel}
         */
        this.policy = policy;

        /**
         * Zwracamy składkę dla polisy
         * @return {object} {currency: 'PLN', value: 100}
         */
        this.getPolicyPremium = function() {
          return simplePolicyHelper.getPolicyPremium(this.policy);
        };

        /**
         * Pobieranie cesjonariuszy
         * @return {array}          obekty cesjonariuszy
         */
        this.getCessions = function() {
          var cesssions = [];
          var self = this;
          angular.forEach(self.policy.suretyRef, function(suretyRef) {
            cesssions.push(self.getInsured(suretyRef));
          });
          return cesssions;
        };

        /**
         * Czy polisa jest polisą vintage
         * Przydatne głównie dlatego, że w vintage "wszystko jest inaczej" :P
         * 
         * @return {Boolean} [description]
         */
        this.isVintagePolicy = function() {
          if (!this.policy || !this.policy.product || !this.policy.product.compId) {
            return false;
          }
          return this.policy.product.compId.indexOf('H1V') > -1 || this.policy.product.compId.indexOf('H7V') > -1;
        };

        /**
         * Pobieranie lisingodawcy
         * @return {array}          obekty lisingodawcy
         */
        this.getLeaser = function() {
          var leaser = null;
          var self = this;
          angular.forEach(self.policy.risks, function(risk) {
            angular.forEach(risk.insuredSubjectsRef, function(riskRef) {
              var lessorRoles = [
                ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_LESSOR,
                ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_BANK_OR_LESSOR
              ];
              if (lessorRoles.indexOf(riskRef.groupSymbol) > -1) {
                leaser = self.getInsured(riskRef.ref);
              }
            });
          });
          return leaser;
        };

        /**
         * Zwracamy obiekt lokalizacji po metaData.id
         * @param  {string} estateId id lokalizacji
         * @return {object}          obiekt lokalizacji
         */
        this.getEstateById = function(estateId) {
          var estate = {};
          angular.forEach(this.policy.estates, function(currentEstate) {
            if (currentEstate.metaData.id === estateId) {
              estate = currentEstate;
            }
          });
          return estate;
        };

      };

      return PolicyHelper;
    }]);