angular.module('ihestiaWidgets.policyOperations')
  .controller('duplicateDataCtrl', ['$scope', '$state', '$filter', 'vehiclePurchaseHelper', 'applicationsSvc', 'ihestiaConfigHelper', '$window', 'ihestiaAgHelper', 'ihestiaSsoBaseInfoHelper',
    function($scope, $state, $filter, vehiclePurchaseHelper, applicationsSvc, ihestiaConfigHelper, $window, ihestiaAgHelper, ihestiaSsoBaseInfoHelper) {

      $scope.sellers = [];
      $scope.buyers = [];

      $scope.sellersString = null;
      $scope.buyersString = null;

      $scope.operationDate = null;

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();

      $scope.init = function(){
        //jednak dostajemy gotowy obiekt duplikatu
      };

      $scope.parsePersonsData = function()
      {
        var firstRecordParsed = false;
        angular.forEach($scope.duplicateData.vehicleOwnerChangeRecords, function(changeRecord){
          if(firstRecordParsed) //później pewnie jakoś trzeba będzie wyświetlać wiele rekordów, ale nie wiadomo jak
          {
            return;
          }
          $scope.operationDate = changeRecord.operationDate;
          angular.forEach(changeRecord.partnerRoles, function(roleCode, personId){
            //szukamy osoby o takim id
            angular.forEach($scope.duplicateData.persons, function(person){
              if(person.metaData.id == personId) //eslint-disable-line
              {
                var personString = $scope.getPersonString(person);
                $scope.addClientByRole(personString, roleCode);
              }
            });
            angular.forEach($scope.duplicateData.organizations, function(organization){
              if(organization.metaData.id == personId) //eslint-disable-line
              {
                $scope.addClientByRole(organization.name, roleCode);
              }
            });
          });

          firstRecordParsed = true;
        });

        $scope.sellersString = $scope.sellers.join(', ');
        $scope.buyersString = $scope.buyers.join(', ');
      };

      /**
       * rozkłada osoby/organizacje po roli
       * @param {string} clientString
       * @param {string} roleCode
       */
      $scope.addClientByRole = function(clientString, roleCode)
      {
        if(roleCode === $scope.roles.SELLER_OWNER)
        {
          $scope.sellers.unshift(clientString);
        }
        else if(roleCode === $scope.roles.SELLER_COOWNER)
        {
          $scope.sellers.push(clientString);
        }
        else if(roleCode === $scope.roles.BUYER_OWNER)
        {
          $scope.buyers.unshift(clientString);
        }
        else if(roleCode === $scope.roles.BUYER_COOWNER)
        {
          $scope.buyers.push(clientString);
        }
      };

      /**
       * zwraca nazwę osoby
       * @param  {Person} person
       * @return {string}
       */
      $scope.getPersonString = function(person)
      {
        return person.firstName + ' ' + person.lastName;
      };

      /**
       * przycisk anuluj
       */
      $scope.cancel = function()
      {
        $scope.goToParent();
      };

      /**
       * przycisk Uzupełnij, ładujemy wniosek
       */
      $scope.modifyOwnerChange = function()
      {
        //zgodnie z IHESTIAOA-2058 przenosimy do iagenta
        $scope.$emit('showBlockUi');
        if($scope.currentUser.character.type === 'Client')
        {
          // klient ma dostęp tylko do ikonta, więc zostajemy w aplikacji w której jesteśmy
          $state.go('main.' + $filter('lcFirst')($scope.duplicateObject.operationType), {loadApplication: $scope.duplicateObject.metaData.id}, {reload: true});
        }
        else
        {
          var applicationTarget;
          if(ihestiaAgHelper.hasPermissionFor('Public', 'AgentService'))
          {
            applicationTarget = 'AGENT_URL'; //jeśli ktoś ma uprawnienia do iagenta
          }
          else
          {
            applicationTarget = 'DASHBOARD_URL'; //w przeciwnym przypadku lecimy do dash
          }
          $window.location = ihestiaConfigHelper.getUrl(applicationTarget, 'external/policyOperations/' + $filter('lcFirst')($scope.duplicateObject.operationType) + '?loadApplication=' + $scope.duplicateObject.metaData.id);
        }
      };

      /**
       * nowy wniosek dla tej polisy
       */
      $scope.newOwnerChange = function()
      {
        $scope.duplicateId = null;
        vehiclePurchaseHelper.flags.prependOperation = true; //musimy ustawić dodatkową flagę w weryfikacji żeby obejść duplikat
        $scope.goToNewApplication();
      };

      /**
       * idziemy do strony początkowej z załadowanymi danymi
       */
      $scope.goToNewApplication = function()
      {
        var params = {};
        params.sellDate = $scope.verificationData.operationDate;
        params.policyNo = $scope.verificationData.policyNumber;
        params.regNumber = $scope.verificationData.vehicleRegistrationNumber;
        params.forward = '1';
        $state.go('main.' + $filter('lcFirst')($scope.verificationData.operationType), params, {reload: true});
      };

      /**
       * robimy wniosek polisowy i przechodzimy do Oc nabywcy
       * @return {[type]} [description]
       */
      $scope.makePolicy = function()
      {
        $scope.$emit('showBlockUi');
        applicationsSvc.post('vehicleBuyer/' + $scope.duplicateObject.metaData.id, null, null, function(response){
          $window.location = ihestiaConfigHelper.getUrl('OCK_URL', 'external/init?action=viewApplication&applicationId=' + response.data);
        }, function(){
          $scope.$emit('hideBlockUi');
        });
      };

    }
  ]);