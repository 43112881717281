angular.module('lsnBase.models')
  .factory('LsnSalesProductModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {
      var LsnSalesProductModelV1 = function()
      {
        this.objectName = 'SalesProduct';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          compId:
          {
            type: 'string'
          },
          tariffId:
          {
            type: 'string'
          },
          tariffAttributes:
          {
            type: 'object'
          },
          dynamicValues:
          {
            type: 'dynamicValues'
          }
        };

        this.compId = null; // string, Identyfikator wariantu pakietu ubezpieczeń w przypadku polisy. Dla ryzyka identyfikator w BOS.PTY
        this.tariffId = null; // string, Identyfikator taryfy polisy lub ryzyka zdefiniowany w BOS.PTY
        this.tariffAttributes = {}; // dynamicValues, Lista atrybutów do taryfy
        this.dynamicValues = null; // dynamicValues, Lista atrybutów do wariantu
      };

      LsnSalesProductModelV1.prototype = LsnAbstractModel.prototype;

      return LsnSalesProductModelV1;
    }
  ]);