angular.module('lsnBase.models')
  .factory('LsnPolicyPropositionModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnPolicyModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnPolicyModelConstants)
    {

      var LsnPolicyPropositionModel = function()
      {
        this.objectName = 'PolicyProposition';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          startDate: {
            type: 'dateTime'
          },
          endDate: {
            type: 'dateTime'
          },
          firstPaymentMethod:
          {
            type: 'string',
            dictionary: LsnPolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD
          },
          mainInsuredRef: {
            type: 'string'
          },
          discountsRefs: {
            type: 'array',
            elementsType: 'string'
          },
          risksRefs: {
            type: 'array',
            elementsType: 'string'
          },
          suretyRefs: {
            type: 'array',
            elementsType: 'string'
          },
          premiumFrequency: {
            type: 'int'
          },
          installments:
          {
            type: 'array',
            elementsType: 'Installment'
          },
          product:
          {
            type: 'SalesProduct',
            version: 'v2'
          },
          symbol:
          {
            type: 'string'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});
        this.startDate = null; // dateTime Data i godz. rozpoczęcia okresu ubezpieczenia
        this.endDate = null; // dateTime Data i godz. zakończenia okresu ubezpieczenia
        this.firstPaymentMethod = null; // Forma płatności pierwszej raty.
        this.mainInsuredRef = null; // string Identyfikator głównego ubezpieczonego
        this.discountsRefs = null; // Referencje do listy zniżek.
        this.risksRefs = null; // Referencje do ryzyk.
        this.suretyRefs = null; // Identyfikatory podmiotów udzielających cesji.
        this.premiumFrequency = null; // Częstotliwość opłacania składki.
        this.installments = null; // Kalendarz rat, zawiera kwoty poszczególnych rat wraz z terminem płatności.
        this.product = lsnModelFactory.getObject('SalesProduct'); // Dane produktu.
        this.symbol = null; // policy symboll

      };

      LsnPolicyPropositionModel.prototype = LsnAbstractModel.prototype;

      return LsnPolicyPropositionModel;
    }
  ]);
