angular.module('ihestiaCommonDirectives')
  .factory('TruncateHtml', function() {
    var TruncateHtml = function() {
      var Stack = function() {
        this.items = [];
        this.size = function() {
          return this.items.length;
        };
        this.push = function(key) {
          this.items.push(key);
        };
        this.pop = function() {
          return this.items.pop();
        };
        this.dumpCloseTag = function() {
          var html = '',
            i, len, tag;
          for (i = 0, len = this.size(); i < len; ++i) {
            tag = this.pop();
            html += '</' + tag.tag + '>';
          }

          return html;
        };
      };

      var stack = new Stack();

      this.trancate = function(string, maxLength) {
        var content = '', // traced text
          total = 0, // record how many characters we traced so far
          matches = true,
          result, index, tail;
        while (matches) { // NOSONAR
          matches = /<\/?\w+(\s+\w+="[^"]*")*>/g.exec(string);
          if (!matches) {
            break;
          }
          result = matches[0];
          index = matches.index;
          // overceed, dump everything to clear stack
          if (total + index > maxLength) {
            content += string.substring(0, maxLength - total);
            content += stack.dumpCloseTag();
            break;
          } else {
            total += index;
            content += string.substring(0, index);
          }

          if (result.indexOf('</') === -1) {
            tail = result.indexOf(' ');
            tail = (tail === -1) ? result.indexOf('>') : tail;
            stack.push({
              tag: result.substring(1, tail),
              html: result
            });
          } else {
            stack.pop();
          }

          content += result;
          string = string.substring(index + result.length);
        }

        //w przypadku gdy nie było żadnych znaczników html
        if (content === '') {
          content = string.substr(0, maxLength);
        }

        return content;
      };
    };
    return new TruncateHtml();
  });