angular.module('lsnBase.autocompleters')
  .directive('validPkd', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        var numberPkds = null;
        var numberInvalidPkds = null;
        var setValidity = function(value) {
          if (parseInt(numberPkds, 10) !== 0) {
            ngModel.$setValidity('emptyList', true);
          } else {
            ngModel.$setValidity('emptyList', false);
          }

          if (parseInt(numberInvalidPkds, 10) === 0) {
            ngModel.$setValidity('invalidPkds', true);
          } else {
            ngModel.$setValidity('invalidPkds', false);
          }

          return value;
        };

        attrs.$observe('numberPkds', function(value) {
          if (angular.isString(value) && value !== '') {
            numberPkds = value;
          } else {
            numberPkds = null;
          }
          setValidity(ngModel.$viewValue);
        });

        attrs.$observe('numberInvalidPkds', function(value) {
          if (angular.isString(value) && value !== '') {
            numberInvalidPkds = parseInt(value, 10);
          } else {
            numberInvalidPkds = null;
          }
          setValidity(ngModel.$viewValue);
        });

        setValidity(element.val());

        // For DOM -> model validation
        ngModel.$parsers.unshift(setValidity);

        // For model -> DOM validation
        ngModel.$formatters.unshift(setValidity);
      }
    };
  });