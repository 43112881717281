angular.module('ihestiaRestServicesBase')
  .service('clientSearchSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var ClientSearch = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      var self = this;

      this.resource = 'search';
      this.businessArea = 'clients';

      /**
       *
       * @param {{
       * [regon]: string,
       * [nip]: string,
       * [companyId]: string,
       * [name]: string
       * }} data
       * @param {object} httpParams
       * @return Promise<any[]>
       */
      this.searchOrganization = function(data, httpParams) {
        return self.post('organization', data, undefined, undefined, undefined, httpParams);
      };
    };
    return new ClientSearch();
  }]);
