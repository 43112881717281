angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorDownloadFileHelper', ['iKomunikatorfileSvc', 'ihestiaCommonConfirmModalHelper', '$filter', 'ihestiaIkomunikatorCommonHelper',
    function(iKomunikatorfileSvc, ihestiaCommonConfirmModalHelper, $filter, ihestiaIkomunikatorCommonHelper) {
      var DownloadFileHelper = function() {
        var self = this;

        /**
         * [download pobiera załacznik z FRP]
         * @param  {[type]} messageId   [id wiadomości]
         * @param  {[type]} fileId      [id pliku na FRP]
         * @param  {[type]} fileName    [nazwa pliku]
         * @param  {[type]} attachIndex [id załącznika]
         */
        this.download = function(messageId, fileId, fileName, attachIndex) {
          var data = {
            messageId: messageId
          };

          if (angular.isDefined(fileId)) {
            data.fileId = fileId;
          }

          if (angular.isDefined(attachIndex) && attachIndex !== null) {
            data.attachIndex = attachIndex;
          }

          var params = {
            responseType: 'blob',
            allowedStatuses: [404, 403, 410]
          };

          iKomunikatorfileSvc.downloadAttachment(data, function(result) {
            var headers = result.headers();

            var blob = new Blob([result.data], {
              type: headers['content-type']
            });
            saveAs(blob, fileName);
          }, function(rejection) {
            self.showErrorAlertModal(null, rejection.status);
          }, params);

        };

        /**
         * [showErrorAlertModal zwraca infomracje o błędzie pobierania]
         * @param  {[type]} text   [opis błędu]
         * @param  {[type]} status [status kodu błędu usługi]
         */
        this.showErrorAlertModal = function(text, status) {
          if (status === 410) {
            text = 'Minął termin przechowywania pliku w FRP.';
          } else if (status === 403) {
            text = $filter('translate')('Public.noPermissionsForFile');
          } else if (status === 404) {
            text = $filter('translate')('Public.fileNotFound');
          } else if(text === null){
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi pobierania pliku');
          }

          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('Public.errorOccurred'),
            okBtnName: $filter('translate')('Public.close'),
            cancelBtnName: '',
            text: text,
            okBtnCallback: lsnNg.noop
          });
        };
      };

      return new DownloadFileHelper();
    }
  ]);