angular.module('ihestiaIkomunikator')
  .service('iKomunikatorMessageContextSvc', ['IHestiaRestServiceAbstractDef', 'ihestiaIkomunikatorContextHelper', 'iKomunikatorConstants', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaPlatformHelper',
    function(IHestiaRestServiceAbstractDef, ihestiaIkomunikatorContextHelper, iKomunikatorConstants, ihestiaIkomunikatorUserTypeHelper, ihestiaPlatformHelper) {

      var IKomunikatorMessageContextSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        var self = this;

        this.resource = 'resources/messagecontext';
        this.businessArea = 'ikomunikator';

        this.getFields = function(data, callback, errorCalback) {
          data.formName = ihestiaIkomunikatorContextHelper.get('formName');
          data.callSource = ihestiaIkomunikatorContextHelper.get('systemContext');

          if(ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && ihestiaPlatformHelper.isJupiterOrDirect()) {
            data.isListOfCategory = 'true';
          }

          var category = ihestiaIkomunikatorContextHelper.get('category'),
            categoryId = ihestiaIkomunikatorContextHelper.get('categoryId'), // podtemat
            subjectId = ihestiaIkomunikatorContextHelper.get('subjectId'); // temat

          if (subjectId !== null) {
            data.selectedSubjectCode = subjectId;
          }

          if(ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext() &&
            (!data.selectedSubjectCode || data.selectedSubjectCode === iKomunikatorConstants.SUBJECT_ID_POLICY)) {
            data.isListOfCategory = 'true';
          }

          if(!category && categoryId) {
            category = categoryId;
          }

          if (category !== null) {
            data.category = category;
          } else if ((data.formName === iKomunikatorConstants.FORM_NAME_NEW_MESSAGE || data.formName === iKomunikatorConstants.FORM_NAME_OFFERS_LIST) &&
            data.selectedSubjectCode === iKomunikatorConstants.SUBJECT_ID_INSURANCE && angular.isObject(data.selectedContextObject) && data.selectedContextObject.userNumberValue) {
            data.category = iKomunikatorConstants.CATEGORY_NEW_OFFER;
          }

          if (ihestiaIkomunikatorContextHelper.contextContainSubjectContextItem()) {
            data.selectedContextObject = ihestiaIkomunikatorContextHelper.getSubjectContextItem();
          }

          return self.post(null, data, null, callback, errorCalback);
        };

        this.getDefaultFields = function(data, callback, errorCalback) {
          data.formName = ihestiaIkomunikatorContextHelper.getDefaultFormName();

          return self.post(null, data, null, callback, errorCalback);
        };

        this.getCategories = function(data, callback, errorCalback) {
          data.formName = ihestiaIkomunikatorContextHelper.getDefaultFormName();

          if(ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && ihestiaPlatformHelper.isJupiterOrDirect()) {
            data.isListOfCategory = 'true';
          }
          if(ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext() &&
            (!data.selectedSubjectCode || data.selectedSubjectCode === iKomunikatorConstants.SUBJECT_ID_POLICY)) {
            data.isListOfCategory = 'true';
          }

          data.callSource = ihestiaIkomunikatorContextHelper.get('systemContext');
          return self.post(null, data, null, callback, errorCalback);
        };


      };

      return new IKomunikatorMessageContextSvc();
    }
  ]);