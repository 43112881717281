angular.module('ihestiaRestServicesBase')
/**
 * płatności
 */
.factory('paymentsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var Payments = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'payments';
      this.businessArea = 'payments';
    };

    /**
     * Konstruktor v2
     */
    var PaymentsV2 = function() {
      Payments.apply(this, arguments);
      this.apiVersion = 'v2';
    };

    /**
     * Konstruktor v1
     */
    var PaymentsV1 = function() {
      Payments.apply(this, arguments);
    };

    return IHestiaRestServiceAbstractDef.setApiConstructors({
      v1: PaymentsV1,
      v2: PaymentsV2
    });

}]);