angular.module('salesPath2')
  .controller('chooseLeasingModalCtrl', ['$scope', 'leasingModalHelper', 'leasingHelper', 'CONSTANTS', 'mainDataContainer', 'organizationHelper',
    function($scope, leasingModalHelper, leasingHelper, CONSTANTS, mainDataContainer, organizationHelper) {
      $scope.organizationsData = [];

      /**
       * Init
       */
      $scope.init = function() {
        $scope.loadOrganizations();
      };

      /**
       * Budujemy listę organizacji
       */
      $scope.loadOrganizations = function() {
        angular.forEach($scope.dataContainer.organizations, function(organization) {
          var orgData = organization.getData();
          orgData.orgInfo = organizationHelper.getOrganizationInfo(organization);
          $scope.organizationsData.push(orgData);
        });
      };

      /**
       * Wybieramy konkretną organizację
       * @param  {int} organizationId [description]
       */
      $scope.chooseOrganization = function(organizationId) {
        leasingHelper.selectLeaser(organizationId, {
          objectType: leasingModalHelper.editLeasingModalData.objectType,
          objectId: leasingModalHelper.editLeasingModalData.objectId
        }, true);
        $scope.hideModal();
      };

      /**
       * Dodajemy nowy leasing
       */
      $scope.addNewLeasing = function() {
        $scope.hideModal();
        leasingModalHelper.editLeasingModalData.leaserId = null;
          if (leasingModalHelper.editLeasingModalData.objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            leasingModalHelper.editLeasingModalData.objectId = mainDataContainer.selectedVehicle;
          } else if (leasingModalHelper.editLeasingModalData.objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            leasingModalHelper.editLeasingModalData.objectId = mainDataContainer.selectedLocalization;
          }
        leasingModalHelper.addNewLeasing();
      };

      /**
       * Ukrywamy ten modal
       * @return {[type]} [description]
       */
      $scope.hideModal = function() {
        leasingModalHelper.hideChooseLeasingModal();
      };


      $scope.init();
    }
  ]);