angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaChooseReportModalCtrl', ['$scope', 'ihestiaPzaReportsSvc', 'ihestiaPzaChooseReportModalHelper', 'ihestiaPzaPoicyReportModalHelper', '$timeout',
    function($scope, ihestiaPzaReportsSvc, ihestiaPzaChooseReportModalHelper, ihestiaPzaPoicyReportModalHelper, $timeout) {

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.$on('$destroy', function(){
          ihestiaPzaChooseReportModalHelper.clearData();
        });
      };

      /**
       * Pobieranie rapportów
       * @param  {String} context agent/worker
       * @return {[type]}         [description]
       */
      $scope.getReport = function(context){
        $scope.$emit('showBlockUi');
        ihestiaPzaReportsSvc.get(ihestiaPzaChooseReportModalHelper.programId, null, context).then(function(res){
          if(res && (res.status === 200 || res.status === 204)) {
            ihestiaPzaChooseReportModalHelper.downloadReport(res.data.fileId);
          }
        }, angular.noop).finally(function(){
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * Jeżeli wybieramy raport na poziomie polisy, to potrzebny dodatkowy modal,
       * w którym można sterować parametrami
       * @return {[type]} [description]
       */
      $scope.getPolicyReport = function() {
        var programId = ihestiaPzaChooseReportModalHelper.programId;
        ihestiaPzaChooseReportModalHelper.hideChooseReportModal();
        $timeout(function(){
          ihestiaPzaPoicyReportModalHelper.showPolicyReportModal(programId);
        },0);
      };

    }
  ]);