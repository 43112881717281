angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseWithdrawalCtrl', ['$scope', '$state', 'VerificationDataModelConstants', 'policiesPrintoutsSvc', 'applicationsSvc', '$window', 'ihestiaConfigHelper', 'resolvedApplication', 'policyOperationsSvc', '$filter', 'fileServerSvc', '$alert', 'resolvedFileAttributes', 'ihestiaAutoUploadOneListUploadHelper', '$timeout', 'policyOperationsHelper', '$stateParams', 'ihestiaPlatformHelper',
    function($scope, $state, VerificationDataModelConstants, policiesPrintoutsSvc, applicationsSvc, $window, ihestiaConfigHelper, resolvedApplication, policyOperationsSvc, $filter, fileServerSvc, $alert, resolvedFileAttributes, ihestiaAutoUploadOneListUploadHelper, $timeout, policyOperationsHelper, $stateParams, ihestiaPlatformHelper) {

      $scope.VerificationDataModelConstants = VerificationDataModelConstants;

      $scope.applicationConfirmed = false;

      $scope.applicationDataForm = null;
      $scope.tarrificationDelay = 5000; //5s, ile czekamy przed następnym strzałem sprawdzającym czy mamy składki
      $scope.simulationTimeout = null;

      $scope.hasUserContext = false; //czy mamy kontekst klienta (jesteśmy w iKoncie)
      if($scope.appContextData && $scope.appContextData.customerId)
      {
        $scope.hasUserContext = true;
      }

      $scope.formSubmitted = false;

      $scope.saveErrors = [];

      $scope.applicationData = resolvedApplication.data;

      $scope.settlement = null;
      $scope.recipientsBankAccountNumber = '';


      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.formData = {
        withdrawalDate: null
      };

      $scope.minDate = null;

      $scope.withdrawalDateMessage = 'Data wypowiedzenia nie może być wcześniejsza niż data ';
      if($scope.applicationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
      {
        $scope.withdrawalDateMessage = $scope.withdrawalDateMessage + 'darowizny';
      }
      else if($scope.applicationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_SALE)
      {
        $scope.withdrawalDateMessage = $scope.withdrawalDateMessage + 'sprzedaży';
      }
      else
      {
        $scope.withdrawalDateMessage = $scope.withdrawalDateMessage + 'zakupu';
      }


      $scope.uploaderName = 'policyWithdrawal';

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderName,
        hideQueueList: true
      };

      //upload plików
      $scope.documentType = resolvedFileAttributes.data;
      $scope.fileModels = [];


      $scope.init = function(){
        $scope.$on('$destroy', function() {
          $timeout.cancel($scope.simulationTimeout);
        });

        $scope.minDate = $scope.today;
        if($scope.applicationData && $scope.applicationData.vehicleOwnerChangeRecords)
        {
          $scope.recipientsBankAccountNumber = $scope.applicationData.vehicleOwnerChangeRecords[$scope.applicationData.vehicleOwnerChangeRecords.length - 1].recipientsBankAccountNumber;
        }

        $scope.initUploader();
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.documentType);
      };

      $scope.refreshSettlement = function()
      {
        if($scope.applicationData && $scope.applicationData.vehicleOwnerChangeRecords)
        {
          $scope.settlement = $scope.applicationData.vehicleOwnerChangeRecords[$scope.applicationData.vehicleOwnerChangeRecords.length - 1].settlement;
          $scope.recipientsBankAccountNumber = $scope.applicationData.vehicleOwnerChangeRecords[$scope.applicationData.vehicleOwnerChangeRecords.length - 1].recipientsBankAccountNumber;
        }
        else
        {
          $scope.settlement = null;
        }
      };

      /**
       * przygotowujemy treść komunikatu
       */
      $scope.prepareTitle = function()
      {
        if(VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[$scope.applicationData.operationType])
        {
          $scope.$emit('setPageTitle', {
            pageTitleLabel: VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[$scope.applicationData.operationType]
          });
        }
      };

      $scope.withdrawalDateChanged = function()
      {
        var dateIsValid = true;
        if($scope.formData.withdrawalDate === '' || $scope.formData.withdrawalDate === null || angular.isUndefined($scope.formData.withdrawalDate))
        {
          dateIsValid = false;
        }
        else
        {
          var date = new XDate($scope.formData.withdrawalDate);
          if (isNaN(date.getTime())) //niepoprawna data
          {
            dateIsValid = false;
          }
        }

        if($scope.applicationDataForm.$valid && dateIsValid)
        {
          $scope.updateApplication();
        }
        else
        {
          $scope.formSubmitted = true;
        }
      };

      $scope.updateApplication = function(confirm, back)
      {
        $scope.saveErrors = [];
        $scope.$emit('showBlockUi');

        if(confirm)
        {
          $scope.saveConfirm();
        }
        else
        {
          //data wypowiedzenia
          var lastRecord = $scope.applicationData.vehicleOwnerChangeRecords[$scope.applicationData.vehicleOwnerChangeRecords.length - 1];
          var simulationData = {};
          if(back)
          {
            lastRecord.buyerWithdrawal = false;
            lastRecord.withdrawalDate = null;
          }
          else
          {
            lastRecord.buyerWithdrawal = true;
            lastRecord.withdrawalDate = $filter('date')($scope.formData.withdrawalDate,'yyyy-MM-ddTHH:mm:ss');
            simulationData.buyerWithdrawalDate = lastRecord.withdrawalDate;
          }

          policyOperationsSvc.get('vehicleOwnerChange/' + $scope.applicationData.metaData.id, simulationData, 'simulation', function(res){
            if(res && angular.isDefined(res.data))
            {
              if(res.data === null)
              {
                //nie mamy settlement, więc czekay dalej
                $scope.simulationTimeout = $timeout(function(){
                  $scope.updateApplication(confirm, back);
                }, $scope.tarrificationDelay);
              }
              else
              {
                $scope.applicationData.vehicleOwnerChangeRecords[$scope.applicationData.vehicleOwnerChangeRecords.length - 1].settlement = res.data;
                $scope.refreshSettlement();
                $scope.$emit('hideBlockUi');
              }
            }
          }, function(rej){
            if(rej.status === 409 && rej.data && rej.data.messages)
            {
              $scope.saveErrors = rej.data.messages;
            }
            $scope.$emit('hideBlockUi');
          },{
            allowedStatuses: [409]
          });


        }
      };

      $scope.initUploader = function() {
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          null, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.end = function(){
        policyOperationsHelper.end($scope.applicationData.policyNumber);
      };

      $scope.deleteFile = function(id){
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      $scope.confirm = function()
      {
        $scope.formSubmitted = true;
        if($scope.applicationDataForm.$valid)
        {
          $scope.saveErrors = [];
          if($scope.fileModels.length === 0)
          {
            //walidacja na załączone pliki
            $scope.saveErrors.push({
              text: 'Załącz dokument potwierdzający wypowiedzenie'
            });
          }
          else
          {
            $scope.updateApplication(true);
          }
        }
      };

      /**
       * zatwierdzamy wniosek
       */
      $scope.saveConfirm = function()
      {
        //pliki
        var files = [];
        angular.forEach($scope.applicationData.files, function(file){
          if(file.fileType !== 'PolicyTermination') //nie przepisujemy plików z wypowiedzenia
          {
            files.push(file);
          }
        });
        //dokładamy pliki wypowiedzenia z interfejsu
        angular.forEach($scope.fileModels, function(fileObject){
          files.push({
            name: fileObject.name,
            size: fileObject.size,
            fileServerId: fileObject.fileServerId,
            fileType: 'PolicyTermination'
          });
        });
        var confirmData = {
          files: files,
          buyerWithdrawalDate: $filter('date')($scope.formData.withdrawalDate,'yyyy-MM-ddTHH:mm:ss')
        };

        policyOperationsSvc.post('vehicleOwnerChange/' + $scope.applicationData.metaData.id, confirmData, 'confirmation', function(response){
          $scope.confirmResponse = response.data;
          $scope.applicationConfirmed = true;
          $scope.$emit('hideBlockUi');
        }, function(rej){
          if(rej.status === 409 && rej.data && rej.data.messages)
          {
            $scope.saveErrors = rej.data.messages;
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409]
        });
      };

      $scope.goBack = function()
      {
        if($stateParams.salesApplicationId)
        {
          if(ihestiaPlatformHelper.isJupiterOrDirect())
          {
            if($scope.applicationData.policyPlatform === 'Jupiter')
            {
              $window.location = ihestiaConfigHelper.getUrl('JUPITER_URL', 'external/salesPath/init?action=viewApplication&applicationId=' + $stateParams.salesApplicationId);
            }
            else
            {
              //dla Jupiter+ przekierowanie do Mtu24
              $window.location = ihestiaConfigHelper.getUrl('MTU24_URL', 'motor/ikdirocn/vehicle?applicationVer=' + $stateParams.salesApplicationId);
            }
          }
          else
          {
            $window.location = ihestiaConfigHelper.getUrl('OCK_URL', 'external/init?action=viewApplication&applicationId=' + $stateParams.salesApplicationId);
          }
        }
        else
        {
          $state.go('main.vehicleOperationConfirmation', {applicationId: $scope.applicationData.metaData.id});
        }

        
      };

    }
  ]);