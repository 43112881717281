angular.module('salesPath2')
  .service('dcDateHelper', ['CONFIG', 'mainDataContainer', 'RESOURCES', 'resourceHelper', 'CONSTANTS', '$log',
    function(CONFIG, mainDataContainer, RESOURCES, resourceHelper, CONSTANTS, $log) {
      var DcDateHelper = function() {
        var self = this;

        //inicjalizacja okresów ubezpieczeńi niektórych dat
        this.initProtectionDates = function() {
          if (CONFIG.BEHAVIOR.globalProtectionPeriod !== null) {
            if (!angular.isArray(RESOURCES.PROTECTION_PERIOD_OPTIONS) || RESOURCES.PROTECTION_PERIOD_OPTIONS.length === 0) {
              $log.error('Brak konfiguracji dla elementu RESOURCES.PROTECTION_PERIOD_OPTIONS');
            }
            mainDataContainer.protectionPeriodCode = RESOURCES.PROTECTION_PERIOD_OPTIONS[0].CODE;
            switch (CONFIG.BEHAVIOR.globalProtectionPeriod) {
              case 'days': //okres ochorny w dniach, np. ergo sport
                if (mainDataContainer.propertyProtectionDates.start) {
                  if (angular.isDefined(RESOURCES.PROTECTION_PERIOD_OPTIONS[0].DAYS_TO_ADD)) {
                    mainDataContainer.propertyProtectionDates.end = (new XDate(mainDataContainer.propertyProtectionDates.start).addDays(RESOURCES.PROTECTION_PERIOD_OPTIONS[0].DAYS_TO_ADD)).toString('yyyy-MM-dd');
                  } else if (angular.isDefined(RESOURCES.PROTECTION_PERIOD_OPTIONS[0].YEARS_TO_ADD)) {
                    mainDataContainer.propertyProtectionDates.end = (new XDate(mainDataContainer.propertyProtectionDates.start).addYears(RESOURCES.PROTECTION_PERIOD_OPTIONS[0].YEARS_TO_ADD).addDays(-1)).toString('yyyy-MM-dd');
                  }
                }
                break;
              case 'travel':
                mainDataContainer.propertyProtectionDates.end = null;
                break;
              default:
                $log.error('Nieobsługiwany typ okresu ubezpieczenia: {0}.'.format(CONFIG.BEHAVIOR.globalProtectionPeriod));
            }
          }
          var oczpDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP);
          if (!angular.equals(oczpDef, {}) && CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1) {
            mainDataContainer.oczpProtectionDates = {};
            angular.forEach(oczpDef.VARIANTLIST, function(variant) {
              mainDataContainer.oczpProtectionDates[variant.CODE] = {
                'start': null,
                'end': null
              };
            });
          }
        };

        /**
         * inicjalizuje czasy ochrony
         */
        this.initProtectionTime = function() {
          mainDataContainer.protectionStartTime = self.getTodayProtectionStartTime();
        };

        /**
         * zmienia wszystkie daty ochrony danego typu w datacontainerze
         * @param {String} type  start|end
         * @param {String} value wartość - data
         */
        this.setAllProtectionDates = function(type, value) {
          var typeProcessed = [],
            personTypeDates = {};
          personTypeDates[CONSTANTS.PRODUCT_OCZP] = mainDataContainer.oczpProtectionDates;
          personTypeDates[CONSTANTS.PRODUCT_NNW] = mainDataContainer.nnwProtectionDates;
          angular.forEach(resourceHelper.products, function(prodDef) {
            switch (prodDef.TYPE) {
              case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
                angular.forEach(mainDataContainer.localizations, function(obj) {
                  var dates = obj.getAdditionalData(['protectionDates', prodDef.CODE]);
                  if (angular.isObject(dates)) {
                    dates[type] = value;
                  }
                });
                if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                  mainDataContainer.propertyProtectionDates[type] = value;
                }
                break;
              case CONSTANTS.PRODUCT_TYPE_VEHICLE:
                if (typeProcessed.indexOf(prodDef.TYPE) !== -1) {
                  break;
                }
                angular.forEach(mainDataContainer.vehicles, function(obj) {
                  var dates = obj.getAdditionalData('protectionDates');
                  if (angular.isObject(dates)) {
                    dates[type] = value;
                  }
                });
                break;
              case CONSTANTS.PRODUCT_TYPE_PERSON:
                if (CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(prodDef.CODE) !== -1) {
                  angular.forEach(personTypeDates[prodDef.CODE], function(dates) {
                    dates[type] = value;
                  });
                } else {
                  personTypeDates[prodDef.CODE][type] = value;
                }
                if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                  mainDataContainer.propertyProtectionDates[type] = value;
                }
                break;
              default: //osobowo-grupowe
                if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                  mainDataContainer.propertyProtectionDates[type] = value;
                }
            }
            mainDataContainer.propertyProtectionDates[type] = value;
            mainDataContainer.communicationProtectionDates[type] = value;
            typeProcessed.push(prodDef.TYPE);
          });
        };

        /**
         * zwraca czas rozpoczęcia okresu ochrony dla dnia dzisiejszego na podstawie aktualnej godziny serwerowej
         * Zasada
         * - jeśli aktualna minuta w przedziale <0 .. 29> to zwracamy następną pełną godzinę np. 12:23 -> 13:00
         * - jeśli aktualna minuta w przedziale <30 .. 59> to dodajemy 2 godziny (minuty zerujemy) np. 12:50 -> 14:00
         * @return {String} HH:MM
         */
        self.getTodayProtectionStartTime = function() {
          var time = RESOURCES.CURRENT_TIME.split(':'),
            hour = parseInt(time[0], 10),
            minute = parseInt(time[1], 10),
            hoursToAdd = 1, //ile godzin dodac do aktalnej godziny
            //daysToAdd = 0, //ile dni dodac do aktualnej daty
            //startTime = '00:00', //czas poczatku ochrony na podstawie aktualnego czasu serwerowego
            startDate = (new XDate(RESOURCES.TODAY)).setMinutes(0); //najwczesniejsza mozliwa data rozpoczecia ochrony - uzywana w komunikacji
          if (minute > 29) {
            hoursToAdd = 2;
          }
          hour += hoursToAdd;
          if (hour > 23) { //zmieniamy dzien ochrony o jeden w przod. W takim razie ustawiamy godzinę 23:59, czyli najpozniejsza w dniu dzisiejszym
            return '23:59';
          }
          startDate.setHours(hour);
          return startDate.toString('HH:mm');
        };

      };

      return new DcDateHelper();
    }
  ]);