angular.module('ihestiaCommonDirectives')
  .directive('ihestiaHistoryBack', ['$window', function($window) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.on('click', function() {
            $window.history.back();
        });

        scope.$on('$destroy',function() {
          element.off('click');
        });
      }
    };
  }]);