angular.module('lsnBase.deprecated.models')
  .factory('GenderConstants', ['LsnPersonModelConstants', function(LsnPersonModelConstants) {
    return LsnPersonModelConstants;
  }])
  .factory('PersonModelConstants', ['LsnPersonModelConstants', function(LsnPersonModelConstants) {
    return LsnPersonModelConstants;
  }])
  .factory('PersonModel', ['LsnPersonModelV1',
    function(LsnPersonModelV1) {
      return LsnPersonModelV1;
    }
  ]);