/**
 * Messages' modals helper
 */
angular.module('salesPath2').service('messageModalsHelper', ['CONFIG', 'sp2CommonHelper', '$filter', '$timeout', 'mainDataContainer', 'renewalHelper','CONSTANTS', 'dataContainerHelper',
  function(CONFIG, sp2CommonHelper, $filter, $timeout, mainDataContainer, renewalHelper, CONSTANTS, dataContainerHelper) { // eslint-disable-line angular/di
    var Helper = function() {
      var self = this;

      this.modals = {
        propertySumInsuredModal: {
              settings: {
                treatLabelAsI18nCode: true,
                title: 'sp2.modal.propertySumInsured.title.house',// + selectedLocalizationType,
                size: 'md',
                okBtnName: 'sp2.modal.propertySumInsured.okBtnName',
                cancelBtnName: 'sp2.modal.propertySumInsured.cancelBtnName',
                keyboard: false
              }
          },
          propertySumRenewalModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.info.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              keyboard: false
            }
          }
      };

      /**
       * reaction on dispatched action
       * @param  {String} actionName name of dispatched action
       */
      this._afterAction = function(actionName) {
        if (actionName === 'messagesUpdated' && _.isArray(CONFIG.MESSAGES.suggestedPropertySICodes)) {
          var msg = _.find(arguments[1], function(message) {
            return CONFIG.MESSAGES.suggestedPropertySICodes.indexOf(message.code) !== -1;
          });
          if (msg) {
            dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, msg.riskParams.estateId);
            self.showPropertySumInsuredMsg(msg);
          }
        }
      };
      
      /**
       * show modal with "property sum insured change suggestion"
       * Based on https://ebok.atena.pl/browse/IHESTIASS-737
       * @param {Object} message
       */
      this.showPropertySumInsuredMsg = function() {
        if (renewalHelper.isRenewal() && mainDataContainer.application.estates.length > 1) {
          sp2CommonHelper.showModal('propertySumRenewalModal');
        } else {
          self.modals.propertySumInsuredModal.settings.title = this.prepareModalTitle();
          $timeout(function() {
            sp2CommonHelper.showModal('propertySumInsuredModal');
          }, 0);
        }
      };

      this.prepareModalTitle = function() {
        var title = $filter('translate')('sp2.modal.propertySumInsured.title.prefix', {componentCode: 'sp2'});
        if (mainDataContainer.selectedLocalization && mainDataContainer.localizations[mainDataContainer.selectedLocalization].name) {
          title = title + ' ' + mainDataContainer.localizations[mainDataContainer.selectedLocalization].name;
        } else {
          var selectedLocalizationType = mainDataContainer.selectedLocalization
            ? $filter('translate')('sp2.modal.propertySumInsured.title.' + mainDataContainer.localizations[mainDataContainer.selectedLocalization].type, {componentCode: 'sp2'})
            : $filter('translate')('sp2.modal.propertySumInsured.title.flat', {componentCode: 'sp2'});
          title = title + ' ' + selectedLocalizationType;
        }
        return title;
      };

    };

    return new Helper();
  }
])
  .run(['messageModalsHelper', 'actionHelper',
    function(messageModalsHelper, actionHelper) {
      actionHelper.registerHelper('messageModalsHelper', messageModalsHelper, ['messagesUpdated']);
    }
  ]);
