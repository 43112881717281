/**
 * Serwis do obsługi AG (Action Guard)
 * @return {ihestiaAgHelper}
 */
angular.module('ihestiaAg')
  .provider('ihestiaAgConstants', [function() {
    var ihestiaAgConstants = {
      USER_TYPE_PD: 'PD',
      USER_TYPE_CENTRAL: 'CENTRAL',
      USER_TYPE_MS: 'MS',
      USER_TYPE_AGENT: 'AGENT', //to nie jest agent tylko agencja
      USER_TYPE_WORKER: 'WORKER',
      USER_TYPE_PARTNER: 'PARTNER',
      USER_TYPE_CLIENT: 'CLIENT'
    };

    /**
     * Zwraca stałe dla sekcji .config itd.
     * @return {Object}
     */
    this.getConstants = function() {
      return ihestiaAgConstants;
    };

    /**
     * Magic $get zwraca stałe Ag
     * @return {Function}
     */
    this.$get = [function() {
      return ihestiaAgConstants;
    }];
  }])
  .provider('ihestiaAgHelper', ['ihestiaSsoBaseInfoHelperProvider', 'ihestiaConfigHelperProvider', 'UserCharacterTypeConstantsProvider', 'ihestiaAgConstantsProvider', 'ihestiaPlatformHelperProvider', '$windowProvider',
    function(ihestiaSsoBaseInfoHelperProvider, ihestiaConfigHelperProvider, UserCharacterTypeConstantsProvider, ihestiaAgConstantsProvider, ihestiaPlatformHelperProvider, $windowProvider) {
      var ihestiaAgHelperProvider = this;
      var agData = ihestiaConfigHelperProvider.getConfigHelper().get('ag');
      var ssoReminder = ihestiaConfigHelperProvider.getConfigHelper().get('appModuleName') === 'ihestiaSsoReminder';

      /**
       * Sprawdzamy uprawnienia do atomu
       * @param  string  context aplikacja, np. Public, Portal
       * @param  string  atom
       * @return {Boolean}      [description]
       */
      this.hasPermissionFor = function(context, atom) {
        if (ssoReminder) {
          return true;
        }
        // jesli sprawdzanie AG wylaczone, to zwracamy true
        if (agData.enabled !== true) {
          return true;
        }

        // jesli nie, to sprawdzamy kontekst
        if (typeof agData.permissions[context] === 'undefined') {
          return false;
        }

        // jesli mamy uprawnienia do czegokolwiek w kontekscie, to sprawdzamy atom
        if (agData.permissions[context].indexOf(atom) >= 0) {
          return true;
        }

        return false;
      };


      /**
       * Czy użytkownik ma uprawnienia właściciela agencji
       * @return {Boolean} [description]
       */
      this.hasAgencyOwnerRights = function(icContext) {
        if(!icContext)
        {
          icContext = ihestiaConfigHelperProvider.getConfigHelper().get('ic', 'activeContext');
        }
        var userCharacter = ihestiaSsoBaseInfoHelperProvider.getUserData().character;
        if(icContext === 'EHL') {
          return userCharacter.ossContextInfo.WorkerIsOwnerLife || ihestiaAgHelperProvider.hasPermissionFor('Public', 'EmployeeAsOwnerLife');
        } else {
          return userCharacter.ossContextInfo.WorkerIsOwnerProperty || ihestiaAgHelperProvider.hasPermissionFor('Public', 'EmployeeAsOwner');
        }
      };

      /**
       * Zwraca rolę aktualnie zalogowanego użytkownika
       * @return {String} PD|CENTRAL|MS|.. ihestiaAgConstants
       */
      this.getUserRole = function() {
        var userTypeConstants = UserCharacterTypeConstantsProvider.getConstants();
        var agConstants = ihestiaAgConstantsProvider.getConstants();
        var currentCharacterType = ihestiaSsoBaseInfoHelperProvider.getUserData().character.type;
        var userRole = null;
        switch (currentCharacterType) {
          case userTypeConstants.CHARACTER_TYPE_WORKER:
          case userTypeConstants.CHARACTER_TYPE_DIRECT_SALE:
            if (ihestiaAgHelperProvider.hasAgencyOwnerRights()) {
              userRole = agConstants.USER_TYPE_AGENT;
            } else {
              userRole = agConstants.USER_TYPE_WORKER;
            }
            break;
          case userTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER:
          case userTypeConstants.CHARACTER_TYPE_CENTRAL:
            userRole = agConstants.USER_TYPE_CENTRAL;
            break;
          case userTypeConstants.CHARACTER_TYPE_BRANCH:
            userRole = agConstants.USER_TYPE_PD;
            break;
          case userTypeConstants.CHARACTER_TYPE_MANAGER:
            userRole = agConstants.USER_TYPE_MS;
            break;
          case userTypeConstants.CHARACTER_TYPE_SALES_PROFILE:
            userRole = agConstants.USER_TYPE_AGENT;
            break;
          case userTypeConstants.CHARACTER_TYPE_PARTNER:
            userRole = agConstants.USER_TYPE_PARTNER;
            break;
          case userTypeConstants.CHARACTER_TYPE_CLIENT:
            userRole = agConstants.USER_TYPE_CLIENT;
            break;
          default:
            break;
        }
        return userRole;
      };

      /**
       * Czy użytkownik jest w jakiejkolwiek z podanych ról
       * @param  {array|string} roles rola/role z ihestiaAgConstants (np. 'USER_TYPE_PD')
       * @return {Boolean}
       */
      this.userIsInOneOfRoles = function(roles) {
        var userRoleIsInRoles = false;
        var agConstants = ihestiaAgConstantsProvider.getConstants();
        var userRole = ihestiaAgHelperProvider.getUserRole();
        if(!angular.isArray(roles)) {
          roles = [roles];
        }
        angular.forEach(roles, function(role){
          var roleValue = agConstants[role];
          if(roleValue === userRole) {
            userRoleIsInRoles = true;
          }
        });

        return userRoleIsInRoles;
      };

      /**
       * Czy użytkownik nie jest w żadnej z podanych ról
       * @param  {array|string} roles rola/role z ihestiaAgConstants (np. 'USER_TYPE_PD')
       * @return {Boolean}
       */
      this.userIsNotInAnyOfRoles = function(roles) {
        var userIsNotInAnyOfRoles = true;
        var agConstants = ihestiaAgConstantsProvider.getConstants();
        var userRole = ihestiaAgHelperProvider.getUserRole();
        if(!angular.isArray(roles)) {
          roles = [roles];
        }
        angular.forEach(roles, function(role){
          var roleValue = agConstants[role];
          if(roleValue === userRole) {
            userIsNotInAnyOfRoles = false;
          }
        });

        return userIsNotInAnyOfRoles;
      };


      /**
       * Sprawdzamy updawnienia do polisy (atom + typ tożsamości)
       * @param  {string}  atom
       * @return {Boolean}
       */
      this.hasFinalPolicyPermissionFor = function(atom) {
        if (agData.enabled !== true) {
          return true;
        }
        return agData.finalPolicyPermissions[atom];
      };

      /**
       * Sprawdzamy czy możemy rozpocząć nową ścieżkę sprzedaży
       * @return {bool}
       */
      this.canStartSalePath = function() {
        if (agData.enabled !== true) {
          return true;
        }
        //Sprawdzamy updawnienia do polisy (atom)
        return (agData.finalPolicyPermissions[ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_SYMBOL_POLICY_SALE')] && agData.finalPolicyPermissions[ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_SYMBOL_POLICY_VIEW')]) ? true : false;
      };

      this.canAccessSyriusz = function() {
        return ihestiaAgHelperProvider.hasPermissionFor(ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), 'AppSyriusz');
      };

      /**
       * dostęp do e4
       * @param  {bool} ignoreSalePathCheck czy pominąć sprawdzenie uprawnienienia do utworzenia kalkulacji
       *                                    przykładowo jeśli ktoś chciałby wejść tylko do odczytu na istniejącą kalkulację
       * @return {bool}
       */
      this.canAccessLifeSalePathApp = function(ignoreSalePathCheck) {
        // dla dostępu do e4 potrzebujemy też mieć uprawnienia do nowej kalkulacji
        var canAccess = ihestiaAgHelperProvider.hasPermissionFor(ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), 'AppErgo4');
        if(!ignoreSalePathCheck && !ihestiaAgHelperProvider.canStartSalePath()) {
          canAccess = false;
        }
        return canAccess;
      };

      /**
       * can user access any life app (Syriusz, Life Sale Path itp.)
       * @return {[type]} [description]
       */
      this.canStartAnyLifeSalePath = function() {
        return ihestiaAgHelperProvider.canAccessSyriusz() || ihestiaAgHelperProvider.canAccessLifeSalePathApp();
      };

      /**
       * Przejście do portalu, dla agenta jupiterowego to będzie jupiter
       * @return {undefined}
       */
      this.goToPortal = function()
      {
        var jupiterCharacter = ihestiaPlatformHelperProvider.isJupiterAgent();
        var canAccessPortal = ihestiaAgHelperProvider.hasPermissionFor(ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_SYMBOL_PORTAL'));
        var canAccessJupiter = ihestiaAgHelperProvider.hasPermissionFor(ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), 'AppPortalJupiter');

        if(jupiterCharacter && canAccessJupiter) {
          //w fazie config jeszcze nie możemy sobie wstrzyknąć window
          $windowProvider.$get().open(ihestiaConfigHelperProvider.getConfigHelper().getUrl('JUPITER_URL'), '_self');
        } else if(canAccessPortal) {
          $windowProvider.$get().open(ihestiaConfigHelperProvider.getConfigHelper().getUrl('PORTAL_URL'), '_self');
        }
      };

      /**
       * Magic get
       * @type {Array}
       */
      this.$get = [
        function() {
          return ihestiaAgHelperProvider;
        }
      ];
    }]);