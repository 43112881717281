angular.module('ihestiaMenuBase')
  .service('chatBotHelper', ['ihestiaConfigHelper', 'chatBotSessionSvc', '$document', '$interval', '$window', '$timeout', '$http',
    function (ihestiaConfigHelper, chatBotSessionSvc, $document, $interval, $window, $timeout, $http) {

      var ChatBotHelper = function () {
        var self = this;

        this.statusData = {
          anyUnreadMessages: false
        };

        this.config = ihestiaConfigHelper.get('chatbot');
        this.ssoConfig = ihestiaConfigHelper.get('sso');
        this.leadingBranchProperty = this.ssoConfig.userInfo.character.ossContextInfo.LeadingBranchProperty;
        this.widget = null; // chatBot widget

        this.registeredAudioCallback = null; // tu ląduje metoda do przekazania zmian do kontrolera
        this.registeredStatusCallback = null; // tu ląduje metoda do odpalenia dzwięku

        // try to load chat bot sdk
        this.loadChatBotSdk = function () {
          var interval = $interval(function () {
            if (angular.isUndefined($window.ADV)) {
              self.loadScript(self.config.sdkUrl);
            } else {
              $interval.cancel(interval);
              self.setConfig();
            }
          }, 100);
        };

        // set chatbot config and session data
        this.setConfig = function () {
          // because 'Central' has no leading branch
          var leadingBranch = this.leadingBranchProperty ? this.leadingBranchProperty : 'GD20';
          $http.get(self.config.uwrUrl + '/' + leadingBranch)
            .then(function (uwrData) {
              ADV.initWidget({
                cid: self.config.cid,
                baseUrl: self.config.baseUrl,
                layout: self.config.layout,
                theme: self.config.theme,
                translations: self.config.translations,
                getCustomerData: function (done) {
                  $timeout(function () {
                    chatBotSessionSvc.get().then(function (sessionData) {
                      if (sessionData.status === 200) {
                        var customerData = sessionData.data;
                        if (uwrData && uwrData.data) {
                          customerData.underwriterCode = uwrData.data.code;
                          customerData.underwriterName = uwrData.data.name;
                          customerData.underwriterEmail = uwrData.data.email;
                          customerData.underwriterPhoneNumber = uwrData.data.phoneNumber;
                        }
                        return done(null, customerData);
                      }
                      return done(null, null);
                    }, function (err) {
                      return done(err, null);
                    });
                  }, 0);
                },
                newMessageEvent: function (isWidgetOpen) {
                  $timeout(function () {
                    if (!isWidgetOpen) {
                      self.setStatusData('anyUnreadMessages', true);
                    }
                    if (angular.isFunction(self.registeredAudioCallback)) {
                      self.registeredAudioCallback();
                    }
                  });
                }
              }).then(function (sdk) {
                self.widget = sdk;
                self.widget.showWidget();
              });
            }, angular.noop);
        };

        this.setStatusData = function (attributeName, value) {
          self.statusData[attributeName] = value;
          if (angular.isFunction(self.registeredStatusCallback)) {
            self.registeredStatusCallback(attributeName, value);
          }
        };

        // load chatbot api script into page head
        this.loadScript = function (apiUrl) {
          var isFound = false;
          var scripts = $document[0].getElementsByTagName('script');
          for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') !== null && scripts[i].getAttribute('src').includes(apiUrl)) {
              isFound = true;
            }
          }

          if (!isFound) {
            var dynamicScripts = [apiUrl];
            for (var j = 0; j < dynamicScripts.length; j++) {
              var node = $document[0].createElement('script');
              node.src = dynamicScripts [j];
              node.type = 'text/javascript';
              node.async = false;
              node.charset = 'utf-8';
              $document[0].getElementsByTagName('head')[0].appendChild(node);
            }
          }
        };

        // toggle chatbot visibility
        this.toggleVisibility = function () {
          self.setStatusData('anyUnreadMessages', false);
          if (angular.isDefined($window.ADV)) {
            self.widget.toggleWidget();
          } else {
            self.loadChatBotSdk();
          }
        };

        this.registerStatusCallback = function (statusCallback) {
          self.registeredStatusCallback = statusCallback;
        };

        this.registerAudioCallback = function (audioCallback) {
          self.registeredAudioCallback = audioCallback;
        };

      };

      return new ChatBotHelper();
    }
  ]);
