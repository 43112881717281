angular.module('ihestiaWidgets.ikonfigurator')
  .service('ihestiaIkonfiguratorUserSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PzaTagsSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
        this.resource = 'user';
        this.businessArea = 'ikonfigurator';
      };

      return new PzaTagsSvc();
    }]);