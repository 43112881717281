angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaPolicyOperationsList', [function() {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/operations/policyOperationsList.tpl.html',
      restrict: 'E',
      transclude: true,
      scope: {
        operations: '=', // availableOperationsList from ihestiaLifePolicyDetailsCtrl
        onClickOperation: '&' // handleOperation from ihestiaLifePolicyDetailsCtrl
      },
      link: function(scope) {
        /**
         *
         * @param {string} code
         */
        scope.handleOperation = function(code) {
          scope.onClickOperation({
            code: code
          });
        };
      }
    };
  }]);
