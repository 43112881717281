angular.module('ihestiaCommonDirectives')
  .directive('ihestiaCompanyAddress', ['$filter', 'ihestiaTrimInfo', 'LsnAddressModelConstants',
    function($filter, ihestiaTrimInfo, LsnAddressModelConstants) {
      return {
        scope: {
          organization: '='
        },
        restrict: 'E',
        templateUrl: 'ihestia-commondirectives-template-app/directives/companyAddress/companyAddressDescription.tpl.html',
        link: function(scope) {
          scope.tplData = {
            addressLiteral: ''
          };
          scope.defaultSettings = {
            elements: [{
              field: 'postalCode'
            }, {
              field: 'city'
            }, {
              field: 'streetPrefix'
            }, {
              field: 'street'
            }, {
              field: 'house',
              fieldSeparator: ''
            }, {
              field: 'apartment',
              prefix: '/',
              lastInLine: true
            }],
            maxLength: 300,
            emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
          };

          scope.init = function() {
            scope.tplData.addressLiteral = scope.prepareLiteral(scope.organization.addresses);
          };

          /**
           *
           * @param {object[]} addresses
           * @return {string}
           */
          scope.prepareLiteral = function(addresses) {
            var mainAddress = _.find(addresses, ['code', LsnAddressModelConstants.ADDRESS_TYPE_REJ])
              || _.find(addresses, ['code', LsnAddressModelConstants.ADDRESS_TYPE_STAL])
              || {};
            return scope.getAddressInfo(mainAddress);
          };

          /**
           *
           * @param {object} address
           * @param {object} [params]
           * @return {string}
           */
          scope.getAddressInfo = function(address, params) {
            var settings = _.merge({}, scope.defaultSettings, params);
            _.forEach(settings.elements, function(element) {
              element.text = address[element.field] || '';
            });
            return ihestiaTrimInfo(settings);
          };

          scope.init();
        }
      };
    }
  ]);
