angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaConditionDefinitionAdditionalPremiumModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaConditionDefinitionAdditionalPremiumModel = function() {
      this.objectName = 'IhestiaPzaConditionDefinitionAdditionalPremiumModel';

      this.fieldsProperties = {
        discount: {
          type: 'IhestiaPzaDictionary'
        },
        selected: {
          type: 'bool'
        }
      };

      this.discount = null;
      this.selected = null;

    };


    IhestiaPzaConditionDefinitionAdditionalPremiumModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaConditionDefinitionAdditionalPremiumModel;
  }]);