angular.module('lsnBase.validators')
        .directive('validAccount', function() {
          return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {

              var setValidity = function(value) {
                if(value){
                  if(value.isValidAccountNumber())
                  {
                    ngModel.$setValidity('invalidFormat', true);
                  }else{
                    ngModel.$setValidity('invalidFormat', false);
                  }
                }
                return value;
              };

              setValidity(element.val());

              // For DOM -> model validation
              ngModel.$parsers.unshift(setValidity);

              // For model -> DOM validation
              ngModel.$formatters.unshift(setValidity);
            }
          };
        });