angular.module('ihestiaRestServicesBase')

/**
 * Ustawienia
 * @return {[type]} [description]
 */
.factory('IHestiaRestServiceProviderAtenaDef', ['$rootScope', 'IHestiaRestServiceProviderAbstractDef', '$http', 'ihestiaConfigHelper', 'IHestiaRestPerformanceContractsDef', 'ihestiaCommonErrorHandler', 'ihestiaSsoBaseInfoHelper', '$q', 'simpleOAuthHelper', 'detectorUserActiveHelper',
  function($rootScope, IHestiaRestServiceProviderAbstractDef, $http, ihestiaConfigHelper, IHestiaRestPerformanceContractsDef, ihestiaCommonErrorHandler, ihestiaSsoBaseInfoHelper, $q, simpleOAuthHelper, detectorUserActiveHelper) {

    var sessionHasExpired = false;

    $rootScope.$on('iHestia.Session.Expired', function() { // eslint-disable-line angular/on-watch
      sessionHasExpired = true;
    });

    var RestServiceProviderAtenaDef = function() {

      IHestiaRestServiceProviderAbstractDef.apply(this, arguments);

      var self = this,
        additionalHeaders = {},
        performanceContracts = new IHestiaRestPerformanceContractsDef(self);

      this.conflictedRequests = [];
      this.sessionPossibleExpiredRequests = [];

      /**
       * @param {Object} headers
       */
      this.setAdditionalHeaders = function(headers) {
        additionalHeaders = headers;
      };

      /**
       * @returns {string}
       */
      this.getName = function() {
        return 'Atena';
      };

      /**
       * wykonuje call do webservice'u Ateny
       *
       * @param parameters
       * @returns {*}
       */
      this.call = function(parameters, callback, errorCallback, httpParams) {
        // jeśli sesja wygaśnie, to zwracamy "wiszący" promise
        // nie chcemy bowiem wysyłać niepotrzebnych requestów
        if (sessionHasExpired) {
          return $q(angular.noop);
        }

          var settings = angular.extend({
            url: '',
            data: '',
            method: 'GET',
            async: true
          }, parameters);
          settings.data = self._parseData(settings.data, settings.dataType);

          var uniqueRequestId = self.getUniqueRequestId();

          var ajaxCallParams = {
            url: settings.url,
            method: settings.method,
            businessArea: settings.businessArea,
            resource: settings.resource,
            async: settings.async,
            dataType: settings.dataType,
            data: settings.data,
            processData: false,
            withCredentials: false
          };

          if(ihestiaConfigHelper.get('serverData', 'AP-PROXY') === 'OpenIdProxy')
          {
            ajaxCallParams.withCredentials = true;
          }

          if (settings.dataType === 'json') {
            ajaxCallParams.contentType = 'application/json;charset=UTF-8';
          } else if (settings.dataType === 'form') {
            ajaxCallParams.contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
          }

          var additionalParam = {
            processType: parameters.processType,
            requestType: 'ACTION',
            uniqueRequestId: uniqueRequestId,
            mockMode: parameters.mockMode
          };

        if (detectorUserActiveHelper.userWasActive(!parameters.serviceOption || !parameters.serviceOption.sessionKeeper)) {
          return self.callAjaxWithParams(ajaxCallParams, callback, errorCallback, httpParams, additionalParam);
        } else {
          detectorUserActiveHelper.registerCallbacks('onUserServiceReactive', 'restServiceProvider', self.onUserServiceReactive);

          var servicePromise = $q.defer();

          self.sessionPossibleExpiredRequests.push({
            servicePromise: servicePromise,
            ajaxCallParams: ajaxCallParams,
            callback: callback,
            errorCallback: errorCallback,
            httpParams: httpParams,
            additionalParam: additionalParam
          });

          return servicePromise.promise;
        }
      };

      /**
       * Zwraca jedna sztuke skonfliktowanego przez tozsamosci requestu
       * i wyrzuca go z kolejki
       * @return {Object} [description]
       */
      this.getSessionPossibleExpiredRequests = function() {
        // jak nic nie ma to false
        if (self.sessionPossibleExpiredRequests.length === 0) {
          return false;
        }
        // i bierzemy pierwszy z brzegu
        var requests = self.sessionPossibleExpiredRequests,
          request = requests[0];
        self.sessionPossibleExpiredRequests = requests.slice(1, requests.length);

        return request;
      };

      this.onUserServiceReactive = function() {
        for (var request = self.getSessionPossibleExpiredRequests(); request !== false; request = self.getSessionPossibleExpiredRequests()) {
          self.callAjaxWithParams(request.ajaxCallParams, request.callback, request.errorCallback, request.httpParams, request.additionalParam, request.servicePromise.resolve, request.servicePromise.reject);
        }
      };

      /**
       * Zwraca jedna sztuke skonfliktowanego przez tozsamosci requestu
       * i wyrzuca go z kolejki
       * @return {Object} [description]
       */
      this.getConflictedRequest = function() {
        // jak nic nie ma to false
        if (self.conflictedRequests.length === 0) {
          return false;
        }
        // i bierzemy pierwszy z brzegu
        var requests = self.conflictedRequests,
          request = requests[0];
        self.conflictedRequests = requests.slice(1, requests.length);

        return request;
      };

      /**
       * Wysyla wszystkie ajaxy z kolejki
       * @return {[type]} [description]
       */
      this.resendConflictedRequests = function() {
        for (var request = self.getConflictedRequest(); request !== false; request = self.getConflictedRequest()) {
          self.callAjaxWithParams(request.params, request.callback, request.errorCallback, request.httpParams, request.additionalParam);
        }
      };

      $rootScope.$on('characterConflictResolved', function() {
        self.resendConflictedRequests();
      });

      /**
       * Zwraca obiekt window.location dla zadanego url
       * @param  {string} locationString "http://google.com"
       * @return {object}                [description]
       */
      this.getLocationFromString = function(locationString) {
        var l = document.createElement('a'); //eslint-disable-line
        l.href = locationString;
        return l;
      };

      /**
       * Wola ajaxa z odpowiednimi parametrami
       * @param  {Object} params [description]
       * @param  {Function} callback [description]
       * @param  {Function} errorCallback [description]
       * @param  {Object} httpParams [description]
       * @param  {Object} additionalParam [description]
       * @return
       */
      this.callAjaxWithParams = function(params, callback, errorCallback, httpParams, additionalParam, previousMainResolve, previousMainReject) {
        //jesli po nie aktywnosci dostalismy ze sesja nie aktywna to dalej juz nie strzelamy
        if (sessionHasExpired) {
          return $q(angular.noop);
        }

        // zwracane, gdy wysylamy zadanie z inna tozsamoscia niz jest ustawiona w platformie
        var apCaller = ihestiaConfigHelper.get('rest', 'AP_CALLER');
        if (!angular.isString(apCaller)) {
          ihestiaCommonErrorHandler.throwException('BRAK AP_CALLER w konfiguracji restow');
        }

        params.headers = self.getNewRequestHeaders();

        params.headers['AP-Unique-Request-Id'] = additionalParam.uniqueRequestId;
        params.headers['Content-Type'] = params.contentType;

        if (additionalParam.mockMode === 'service') {
          params.headers['AP-Mock-Response'] = true;
        } else if (additionalParam.mockMode === 'lsn') {
          var mockData = {
            svcData: params.data,
            svcUrl: params.url.replace(self.getLocationFromString(params.url).origin, '')
          };
          // strzaly do naszych mockow
          if (params.method === 'GET' || params.method === 'DELETE') {
            params.params = mockData;
          } else {
            params.data = mockData;
          }

          params.url = ihestiaConfigHelper.get('rest', 'MOCK').LOCAL_URL;
        }


        if (ihestiaConfigHelper.get('rest', 'USE_PERFORMANCE_CONTRACTS')) {
          params.headers['Ap-Contract-Req-Type'] = additionalParam.requestType;
          params.headers['Ap-Contract-Process-Type'] = additionalParam.processType;

          if (additionalParam.requestType === 'DEFERRED') {
            params.headers['Ap-Contract-Deferred-Req-Id'] = additionalParam.deferredRequestId;
          }
        }

        if (ihestiaConfigHelper.get('ic', 'activeContext')) {
          params.headers['AP-Ic'] = ihestiaConfigHelper.get('ic', 'activeContext');
        }

        angular.extend(params.headers, additionalHeaders);

        if (typeof params.url === 'undefined' || params.url === null) {
          ihestiaCommonErrorHandler.throwException({
            code: 'http_url',
            message: 'Brak parametru url w requeście $http',
            data: {
              url: params.url
            }
          });
        }

        // wolanie ajaxa
        if (angular.isObject(httpParams) && httpParams !== null) {
          if (typeof httpParams.headers !== 'undefined') {
            angular.extend(params.headers, httpParams.headers);
            delete httpParams.headers;
          }
          if (typeof httpParams.url !== 'undefined') {
            params.url = httpParams.url;
            delete httpParams.url;
          }
          angular.extend(params, httpParams);
        }

        if (angular.isDefined(previousMainResolve)) {
          //tu nie przekazujemy nowego promisa, bo kontynuujemy stary
          return self.sendHttpRequest(params, callback, errorCallback, httpParams, additionalParam, previousMainResolve, previousMainReject);
        } else {
          var mainPromise = $q(function(mainResolve, mainReject) {
            self.sendHttpRequest(params, callback, errorCallback, httpParams, additionalParam, mainResolve, mainReject);
          });
          //zwracamy informacje o requeście
          mainPromise.uniqueRequestId = additionalParam.uniqueRequestId;
          return mainPromise;
        }
      };

      // params, callback, errorCallback, httpParams, additionalParam, previousMainResolve, previousMainReject

      this.sendHttpRequest = function(params, callback, errorCallback, httpParams, additionalParam, mainResolve, mainReject) {
        var conflictParams = JSON.parse(JSON.stringify(params)),
          conflictAdditionalParam = JSON.parse(JSON.stringify(additionalParam)),
          accessTokenId;

        if (ihestiaConfigHelper.get('rest', 'AUTH_METHOD') === 'oauth') {
          accessTokenId = simpleOAuthHelper.getAccessTokenId(); //potrzebujemy tego idka żeby rozpoznać czy między requestem a responsem usługi nie następiła już regeneracja sesji
        }

        var callArguments = arguments;

        $http(params).then(function(result) {
          var resType = result.headers('Ap-Contract-Res-Type');

          if (ihestiaConfigHelper.get('rest', 'AUTH_METHOD') === 'oauth' &&
            result.status === 401) {
            simpleOAuthHelper.handleTokenExpired(accessTokenId, self, callArguments);
          } else if (!ihestiaConfigHelper.get('rest', 'USE_PERFORMANCE_CONTRACTS')) {
            if (angular.isFunction(callback)) {
              callback(result); //ręcznie przekazany callback
            }
            mainResolve(result);
          } else if (resType === 'ACTION') {
            if (angular.isFunction(callback)) {
              callback(result);
            }
            mainResolve(result);

            var deferredId = result.headers('Ap-Contract-Src-Deferred-Id');
            if (typeof deferredId !== 'undefined') {
              performanceContracts.clearRequest();
            }
          } else if (resType === 'DEFERRED') {
            performanceContracts.onCallAgainAjaxRequest(result, params, callback, errorCallback, httpParams, additionalParam, mainResolve, mainReject);
          } else if (resType === 'REFUSED') {
            mainReject(result);
            performanceContracts.onRequestRefused(result);
          } else {
            if (angular.isFunction(callback)) {
              callback(result);
            }
            mainResolve(result);
          }
        }, function(reject) {
          if (ihestiaConfigHelper.get('rest', 'AUTH_METHOD') === 'oauth' &&
            reject.status === 401) {
            simpleOAuthHelper.handleTokenExpired(accessTokenId, self, callArguments);
          } else {
            if (reject.status === 409 && reject.headers('AP-User-Status') === 'CHARACTER_CHANGED' && (httpParams && !httpParams.doNotAskAgainOnConflict)) {
              self.conflictedRequests.push({
                params: conflictParams,
                callback: callback,
                errorCallback: errorCallback,
                httpParams: httpParams,
                additionalParam: conflictAdditionalParam
              });
            }

            if (angular.isFunction(errorCallback)) {
              errorCallback(reject);
            }
            mainReject(reject);
          }
        });
      };

      this._parseData = function(data, dataType) {
        if (data === null) {
          return null;
        }
        if (angular.isObject(data)) {
          if (dataType === 'json') {
            return angular.toJson(data);
          } else if (dataType === 'form') {
            var parsedData = [];
            angular.forEach(data, function(value, key) {
              if (angular.isObject(value)) {
                value = angular.toJson(value);
              }
              parsedData.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            });
            return parsedData.join('&');
          }
        }
        return data;
      };

    };

    return RestServiceProviderAtenaDef;
  }
]);