//przy opisie pamietac by opisac ze wymagane jest person bezposrednio w scope
angular.module('salesPath.outer')
  .directive('ihestiaMaidenName', ['ihestiaAvailabilityHelper', 'ihestiaRestDictionaryLastNamesSvc', 'ihestiaTryEscapeHtml',
    function(ihestiaAvailabilityHelper, ihestiaRestDictionaryLastNamesSvc, ihestiaTryEscapeHtml) {
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else if (ihestiaAvailabilityHelper.isAvailable('lastNameSearch')) {
            return 'ihestiaSalesPathTemplate/autocompliters/maidenName/maidenName.tpl.html';
          } else {
            return 'ihestiaSalesPathTemplate/autocompliters/lmaidenName/maidenNameWithoutDict.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

          if($attrs.valueAttribute)
          {
            $scope.valueAttribute = $attrs.valueAttribute;
          }
          else
          {
            $scope.valueAttribute = 'maidenName';
          }

          $scope.$watch($attrs.errors, function(val) {

            if (!val || !val[$scope.valueAttribute]) {
              $scope.hasError = false;
            } else {
              $scope.hasError = !!val[$scope.valueAttribute];
              $scope.invalidText = val[$scope.valueAttribute].message;
            }

          });

          $scope.$watch($attrs.person, function(val) {
            if (!val[$scope.valueAttribute]) {
              val[$scope.valueAttribute] = '';
            }

            $scope.person = val;
          });

          if ($attrs.ngDisabled) {
            $scope.ngDisabled = $attrs.ngDisabled;
          }
          else {
            $scope.ngDisabled = false;
          }
          if (angular.isDefined($attrs.required)) {
            $scope.required = true;
          }
          else {
            $scope.required = false;
          }

          if (ihestiaAvailabilityHelper.isAvailable('lastNameSearch')) {

            $scope.getLastNames = function(query) {
              $scope.loadingElements = true;
              return ihestiaRestDictionaryLastNamesSvc.findByParams({
                query: query,
                top: 10,
                pageSize: 10
              }).then(function(res) {
                $scope.loadingElements = false;
                var data;
                if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                  data = {
                    items: []
                  };
                } else {
                  data = res.data;
                }
                var names = [];
                angular.forEach(data.items, function(item) {
                  names.push(ihestiaTryEscapeHtml(item.name));
                });
                return names;
              }, angular.noop);
            };
          }
        }]
      };
    }
  ]);