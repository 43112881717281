angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorDashboardListCtrl', ['$scope', 'ihestiaIkomunikatorDashboardListHelper', 'ihestiaIkomunikatorActionHelper', 'ihestiaDashboardTaskDetailsHelper', 'ihestiaIkomunikatorDownloadFileHelper', 'iKomunikatorConstants', 'ihestiaIkomunikatorPermissionHelper',
    function($scope, ihestiaIkomunikatorDashboardListHelper, ihestiaIkomunikatorActionHelper, ihestiaDashboardTaskDetailsHelper, ihestiaIkomunikatorDownloadFileHelper, iKomunikatorConstants, ihestiaIkomunikatorPermissionHelper) {

      $scope.init = function() {
        $scope.inDashboard = true;
        $scope.tplData = ihestiaIkomunikatorDashboardListHelper.tplData;
        //pobieramy nazwę z zewnętrznego scopa, rozwiązanie po to by się uniezależnić od nazwy
        var nameInScopeForTaskId = ihestiaDashboardTaskDetailsHelper.getNameInScopeForTaskId();
        $scope.tplData.dashboardTaskId = $scope[nameInScopeForTaskId];
        $scope.shouldShowAction = ihestiaIkomunikatorPermissionHelper.shouldShowAction();

        $scope.$on('messageSended', function() {
          var taskId = $scope.tplData.dashboardTaskId;
          ihestiaIkomunikatorDashboardListHelper.setClearData();
          $scope.tplData.dashboardTaskId = taskId;
          ihestiaIkomunikatorDashboardListHelper.init();
        });

        ihestiaIkomunikatorDashboardListHelper.init();
      };

      $scope.showAddingMessage = function(threadId) {
        $scope.tplData.showsAddingMessage[threadId] = true;
      };

      $scope.hideAddingMessage = function(threadId) {
        $scope.tplData.showsAddingMessage[threadId] = false;
      };

      $scope.triggerDetailsMessageThread = function(threadId) {
        if (!$scope.tplData.showDetailsTread[threadId]) {
          $scope.tplData.showDetailsTread[threadId] = true;
          ihestiaIkomunikatorDashboardListHelper.reloadThread(threadId);

        } else {
          $scope.tplData.showDetailsTread[threadId] = false;
        }
      };

      /**
       * Wyszukiwanie kolejnzch elementow
       * @param  {string} phrase [description]
       */
      $scope.searchNext = function() {
        if (!$scope.tplData.loading) {
          ihestiaIkomunikatorDashboardListHelper.loadNextResult();
        }
      };

      $scope.onSendedMessage = function(threadId) {
        $scope.hideAddingMessage(threadId);
        ihestiaIkomunikatorDashboardListHelper.reloadSearch();
      };

      /**
       * Tworzy nową wiadomość na nowym wątku
       * @param  {string} phrase [description]
       */
      $scope.createNewMessage = function() {

        var context = {
          dashboardTaskId: $scope.tplData.dashboardTaskId
        };

        var nameInScopeForCustomer = ihestiaDashboardTaskDetailsHelper.getNameInScopeForCustomer(),
          customer = $scope[nameInScopeForCustomer];
        if (angular.isObject(customer)) {
          context.customer = customer;

          if (angular.isDefined($scope.taskData.objectClientCustomerId)) {
            context.customer.customerId = parseInt($scope.taskData.objectClientCustomerId, 10);
          }
        } else {
          var nameInScopeForTask = ihestiaDashboardTaskDetailsHelper.getNameInScopeForTask(),
            task = $scope[nameInScopeForTask];
          //imie, nazwisko, pesel musza byc wartosciami nie pustymi
          if (angular.isObject(task) && task.objectClientName && task.objectClientSurname && task.objectClientPESEL) {
            context.otherCustomer = {
              firstName: task.objectClientName ? task.objectClientName : '',
              lastName: task.objectClientSurname ? task.objectClientSurname : '',
              pesel: task.objectClientPESEL ? task.objectClientPESEL : ''
            };
            context.channels = [iKomunikatorConstants.CHANNEL_NAME_EMAIL];
          }
        }

        ihestiaIkomunikatorActionHelper.startNewMessage(context);
      };

      $scope.downloadFile = function(attachment, messageId) {
        ihestiaIkomunikatorDownloadFileHelper.download(messageId, attachment.path, attachment.fileName, attachment.msgFileIndex);
      };

      /**
       * [shouldShowButtonAddMessage sprawdza czy kanal komunikatu watku pozwala na dodanie wiadomosci]
       * @param  {[type]} thread [watek]
       * @return {[boalen]}        [description]
       */
      $scope.shouldShowButtonAddMessage = function(thread) {
        var originator = thread.lastMessage.originator;
        return ['KOMUNIKAT', 'EMAIL', 'SMS'].indexOf(thread.getLastMessageChannel()) !== -1 ||
          (thread.getLastMessageChannel() === 'WWW' && (angular.isString(originator.email) && originator.email !== ''));
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorDashboardListHelper.setClearData();
      });

      $scope.init();
    }
  ]);