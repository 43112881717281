angular.module('lsnBase.modal')
  .directive('lsnModal', ['lsnModalBaseConfig', 
    function(lsnModalBaseConfig){
    return {
      scope: {
      },
      controller: lsnModalBaseConfig.mainCtrlName,
      restrict: 'E',
      templateUrl: lsnModalBaseConfig.mainTemplateUrl,
      link: function($scope, iElm, iAttrs) {
        $scope.modalDrvKey = iAttrs.modalDrvKey; // nie można tego odpalić w scope, ze względu na body-injector
      }
    };
  }]);