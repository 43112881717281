angular.module('ihestiaIkomunikator', [
  'ihestiaIkomunikatorTemplatesApp',
  'ihestiaCommonDirectives.detectorUserActvie',
  'ihestiaCommonDirectives.upperCase',
  'ihestiaCommonDirectives',
  'ihestiaSsoBase',
  'ihestiaGrid',
  'ihestiaGridSettings',
  'salesPath.outer',
  'ihestiaIkomunikator.notificationBar',
  'ihestiaIkomunikator.common'
]);
