angular.module('ihestiaRestServicesBase')
  .service('ihestiaEuaDocumentsSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var EuaDocumentsSvc = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}/{apiVer}{id}{subResource}';
      this.resource = 'documents';
      this.businessArea = 'eua';

    };
    return new EuaDocumentsSvc();
  }]);