angular.module('salesPath2')
  .controller('summaryPaymentModalCtrl', ['$scope', 'RESOURCES', 'summaryPaymentHelper', 'CONSTANTS', 'CONFIG', 'mainDataContainer',
    function($scope, RESOURCES, summaryPaymentHelper, CONSTANTS, CONFIG, mainDataContainer) {

      $scope.paymentDetails = {};
      $scope.policyId = '';
      $scope.readonly = $scope.appVariables.readOnly && !$scope.appVariables.isOcBuyer;

      /**
       * init
       */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.paymentDetails = summaryPaymentHelper.getPaymentDetails();
        $scope.policyId = summaryPaymentHelper.getPolicyId();
      };

      /**
       * Odpalenie watchy
       */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.summaryPaymentModal', function($event, modal) {
          if (modal.validForm) {
            summaryPaymentHelper.savePaymentDetails($scope.paymentDetails, $scope.policyId);
          } else {
            $event.preventDefault();
          }
        });

        $scope.$watch('paymentDetails.method', function(method) {
          if(CONFIG.METADATA.applicationSymbol === 'EP' && method === CONSTANTS.PAYMENT_METHOD_TRANSFER) {
            var policy = mainDataContainer.policies.find(function(policy) {
              return policy.number === $scope.policyId;
            });
            var policyStartMinusOne = (new XDate(policy.start)).addDays(-1).toString('yyyy-MM-dd');
            if (policyStartMinusOne === RESOURCES.TODAY) {              
              $scope.paymentDetails.delayOptions = [{
                value: 0,
                name: '0 dni'
              }];
              $scope.paymentDetails.delay = 0;
            } else if($scope.paymentDetails.delayOptions.length > 0 && !$scope.paymentDetails.delay) {
              $scope.paymentDetails.delay = $scope.paymentDetails.delayOptions[$scope.paymentDetails.delayOptions.length - 1].value;
            }
          }
        }, true);
      };
      
      $scope.init();

    }
  ]);
