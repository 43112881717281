angular.module('ihestiaWidgets.dashboard.euaChecker')
  .controller('ihestiaEuaCheckerCtrl', ['$scope', 'ihestiaEuaCheckerModalHelper', 'ihestiaSessionSvc', 'ihestiaConfigHelper', '$window', 'ihestiaPlatformHelper',
    function($scope, ihestiaEuaCheckerModalHelper, ihestiaSessionSvc, ihestiaConfigHelper, $window, ihestiaPlatformHelper) {

      $scope.tplData = ihestiaEuaCheckerModalHelper.modalData;

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.$on('$destroy', function(){
          ihestiaEuaCheckerModalHelper.clearData();
        });

        // kliknięcie ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaDashboardEuaChecker', function($event) {
          $event.preventDefault();
          $scope.postPoneInfo(true);
        });

        // kliknięcie anuluj
        $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaDashboardEuaChecker', function($event) {
          $event.preventDefault();
          $scope.postPoneInfo();
        });
      };

      /**
       * Przypomnij później
       * @return {undefined}
       */
      $scope.postPoneInfo = function(goToDetails) {
        $scope.$emit('showBlockUi');
        return ihestiaSessionSvc.put('hideEuaInfo').finally(function(){
          $scope.$emit('hideBlockUi');
          ihestiaEuaCheckerModalHelper.hideEuaModal();
          if(goToDetails) {
            if(ihestiaPlatformHelper.isJupiterAgent()) {
              $window.open(ihestiaConfigHelper.getUrl('JUPITER_URL', 'external/profile?tab=eua'), '_self');
            } else {
              $window.open(ihestiaConfigHelper.getUrl('AGENT_URL', 'external/eua'));
            }
          }
        });
      };

    }
  ]);