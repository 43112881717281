angular.module('ihestiaCommonDirectives')
  .service('ihestiaDocumentTypesHelper', ['ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaCommonErrorHandler', '$filter',
    function(ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaCommonErrorHandler, $filter) {
      var DocumentTypes = function() {
        var self = this;
        this.documentTypes = {};

        this.init = function(context) {
          if ($.isEmptyObject(self.documentTypes) || angular.isUndefined(self.documentTypes[context])) {
            self.setDocumentTypes(context);
          }
        };

        this.setDocumentTypes = function(context) {
          ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(null, {
            context: context
          }, null, function(result) {
            if (angular.isArray(result.data) && result.data.length > 0) {
              self.documentTypes[context] = {};
              angular.forEach(result.data, function(value) {
                self.documentTypes[context][value.code] = value;
              });
            } else {
              ihestiaCommonErrorHandler.throwException($filter('translate')('serviceDidNotReturnListOfAvailableDocumentTypes', {
                componentCode: 'Public'
              }));
            }
          });
        };

        this.getDocumentType = function(context, documentTypeCode) { // eslint-disable-line consistent-return
          if (angular.isDefined(self.documentTypes[context][documentTypeCode])) {
            return self.documentTypes[context][documentTypeCode];
          } else {
            ihestiaCommonErrorHandler.throwException($filter('translate')('sendFileWith', {
                componentCode: 'Public',
                params: {
                  documentTypeCode: documentTypeCode,
                  context: context
                }
              }));
          }
        };

        this.getGeneralDocumentType = function() {
          return self.documentTypes.Reclamation;
        };
      };

      return new DocumentTypes();
    }
  ]);