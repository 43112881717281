angular.module('lsnBase.models')
/**
 * Opis z doca od Ateny:
 * Na relacji może być ustawione jedynie jedno z pól personRef, organizationRef w zależności czy relacja tyczy się osoby fizycznej, czy firmy. Analogiczna sytuacja ma miejsce przy polach vehicleRef, estateRef. Ustawiamy jedno pola w zależności czy relacja tyczy się pojazdu, czy mienia.
 * W przypadku wysłania węzła relations do symulacji składek, jest on ważniejszy niż węzeł generalAgreementsNumbers na wniosku. Wysłane umowy generalne są pomijane i brane z węzła relations. W przypadku przesłania konkretnych ryzyk nie ma potrzeby przesyłania węzła relations, ponieważ wszystkie informacje są zawarte na ryzykach. W tym przypadku ten węzeł będzie pomijany.
 */
  .factory('LsnApplicationRelationModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {

      var ApplicationRelationModel = function() {
        this.objectName = 'ApplicationRelation';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          personRef:
          {
            type: 'string'
          },
          organizationRef:
          {
            type: 'string'
          },
          vehicleRef:
          {
            type: 'string'
          },
          estateRef:
          {
            type: 'string'
          },
          role:
          {
            type: 'string'
          },
          generalAgreementNumber: {
            type: 'string'
          }
        };

        this.personRef = null; // string, metaData.id osoby
        this.organizationRef = null; // string, metaData.id organizacji
        this.vehicleRef = null; // string, metaData.id pojazdu
        this.estateRef = null; // string, metaData.id nieruchomości
        this.role = null; // string, rola podmiotu ubezpieczonego np. OWNER
        this.generalAgreementNumber = null; // nr umowy generalnej

      };

      ApplicationRelationModel.prototype = LsnAbstractModel.prototype;

      return ApplicationRelationModel;
    }
  ]);