angular.module('lsnBase.deprecated.models')
  .factory('EstateModelConstants', ['LsnEstateModelConstants',
    function(LsnEstateModelConstants) {
      return LsnEstateModelConstants;
    }
  ])
  .factory('EstateModel', ['LsnEstateModelV1',
    function(LsnEstateModelV1) {
      return LsnEstateModelV1;
    }
  ]);