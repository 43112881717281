angular.module('salesPath2')
  /**
   * konfiguracja dla actionHelpera:
   * metody, któ®e zostaną zarejestrowane do actionHelpera przy odpowiednio spełnionych warunkach
   * @param  {Object} CONSTANTS stałe
   */
  .factory('ActionHelperConfig', ['CONSTANTS',
    function(CONSTANTS) {

      /**
       * konfiguracja dla rejestracji metod helperów/serwisów w actionHelperze
       * @type {Object} format:
       * {
       *   'nazwa_helpera': {
       *     methods: String[], //tablica nazw metod do rejestracji
       *     configCondition: Array //opcjonalnie warunek jaki musi spełniać CONFIG, aby metody zostały zarejestrowane
       *       ['scieżka_do_ustawienia', wartosc_ustawienia]
       *     states: String[] //tablica nazw stanów, na których mają być w/w metody helpera
       *   }
       * }
       */
      var ahConfig = {
        addHelper: {
          methods: [
            'objectSelected',
            'matrixProductSelected',
            'personVariantChanged',
            'personEdited',
            'personAdded',
            'personDeleted',
            'groupEdited',
            'groupAdded',
            'fireBurglarySumInsuredChanged',
            'localizationDeleted',
            'coownersChanged'
          ],
          configCondition: ['MODULES.additions', true]
        },
        addsTplHelper: {
          methods: [
            'tarifficationEnded',
            'addDeleted',
            'addAdded',
            'objectSelected',
            'matrixProductSelected',
            'personVariantChanged',
            'personEdited',
            'personAdded',
            'groupEdited',
            'groupAdded',
            'fireBurglarySumInsuredChanged',
            'localizationDeleted',
            'personGroupSelected',
            'coownersChanged'
          ],
          configCondition: ['MODULES.additions', true]
        },
        applicationHelper: {
          methods: [
            'tarifficate',
            'saveApplication'
          ]
        },
        basketHelper: {
          methods: [
            'tarifficationStarted',
            'tarifficationEnded',
            'languageChanged.afterStateReload'
          ]
        },
        communicationPersonsModalHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'vehicleBmModalClosed'
          ]
        },
        coownerModalHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'vehicleBmModalClosed',
            'readOnlyChanged',
            'tarifficationEnded'
          ]
        },
        discountsHelper: {
          methods: [
            'readOnlyChanged'
          ]
        },
        extensionHelper: {
          methods: [
            'objectSelected',
            'matrixProductSelected',
            'personVariantChanged',
            'personEdited',
            'personAdded',
            'personDeleted',
            'groupEdited',
            'groupAdded',
            'groupDeleted',
            'fireBurglarySumInsuredChanged',
            'localizationDeleted'
          ],
          configCondition: ['MODULES.extensions', true]
        },
        extensionsTplHelper: {
          methods: [
            'tarifficationEnded',
            'extDeleted',
            'extAdded',
            'objectSelected',
            'matrixProductSelected',
            'personVariantChanged',
            'personEdited',
            'personAdded',
            'groupEdited',
            'groupAdded',
            'fireBurglarySumInsuredChanged',
            'localizationDeleted',
            'personGroupSelected'
          ],
          configCondition: ['MODULES.extensions', true]
        },
        messagesHelper: {
          methods: [
            'updateMessages'
          ]
        },
        personModalHelper: {
          methods: [
            'readOnlyChanged'
          ]
        },
        personGroupHelper: {
          methods: [
            'personAdded',
            'groupAdded'
          ],
          configCondition: ['BEHAVIOR.personGroups', true]
        },
        summaryPaymentHelper: {
          methods: [
            'readOnlyChanged'
          ]
        },
        bonusMalusHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'tarifficationEnded',
            'vehicleSelected',
            'vehicleEdited',
            'vehicleAdded',
            'tarifficationStarted',
            'tarifficationEnded'
          ]
        },
        newBonusMalusModalHelper: {
          methods: [
            'tarifficationEnded'
          ]
        },
        bonusMalusModalHelper: {
          methods: [
            'verifyBonusMalusInfo'
          ]
        },
        sp2HeaderHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'personGroupSelected',
            'tarifficationEnded',
            'vehicleSelected',
            'vehicleEdited',
            'vehicleAdded',
            'localizationSelected',
            'localizationEdited',
            'leaserSaved',
            'leaserDeleted',
            'coownersChanged',
            'matrixProductSelected',
            'languageChanged.beforeStateReload'
          ]
        },
        communicationHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'vehicleSelected',
            'vehicleDeleted',
            'vehicleEdited',
            'vehicleAdded',
            'personGroupSelected',
            'tarifficationEnded'
          ],
          configCondition: ['MODULES.communication', true]
        },
        fireBurglaryHelper: {
          methods: [
            'tarifficationEnded',
            'localizationSelected'
          ]
        },
        matrixLocaliationBoxHelper: {
          methods: [
            'localizationEdited',
            'localizationAdded',
            'localizationSelected',
            'localizationDeleted'
          ],
          configCondition: ['BEHAVIOR.multipleLocalizations', true]
        },
        matrixHelper: {
          methods: [
            'personEdited',
            'personAdded',
            'groupEdited',
            'groupAdded',
            'personGroupSelected',
            'personGroupDeleted',
            'tarifficationEnded',
            'localizationSelected',
            'vehicleSelected',
            'vehicleDeleted',
            'vehicleAdded',
            'vehicleEdited',
            'languageChanged.beforeStateReload'
          ],
          configCondition: ['MODULES.matrix', true]
        },
        matrixPersonBoxHelper: {
          methods: [
            'personEdited',
            'groupEdited',
            'personGroupSelected',
            'personGroupDeleted'
          ],
          configCondition: ['BEHAVIOR.personGroups', true]
        },
        matrixVehicleBoxHelper: {
          methods: [
            'vehicleEdited',
            'vehicleAdded'
          ],
          configCondition: ['BEHAVIOR.multipleVehicles', true]
        },
        nnwHelper: {
          methods: [
            'tarifficationEnded',
            'personAdded',
            'personEdited',
            'addIncognito',
            'refreshAllInsured',
            'languageChanged.afterStateReload'
          ],
          states: [CONSTANTS.PAGE_PRODUCT_NNW]
        },
        oczpHelper: {
          methods: [
            'tarifficationEnded',
            'personAdded',
            'personEdited',
            'refreshAllInsured',
            'languageChanged.afterStateReload'
          ],
          states: [CONSTANTS.PAGE_PRODUCT_OCZP]
        },
        personsHelper: {
          methods: [
            'personAdded',
            'personEdited',
            'groupAdded',
            'groupEdited',
            'leaserSaved',
            'tarifficationEnded',
            'personEntirelyDeleted',
            'cessionDeleted',
            'matrixProductSelected',
            'personGroupSelected',
            'personGroupDeleted'

          ]
        },
        summaryPageHelper: {
          methods: [
            'loadingPolicies', //wczytywanie polis dla wniosku
            'applicationRequestFailed', //bledy we wnioskach
            'policiesAccepted', //zatwierdzono polisy
            'policiesReceived',
            'tarifficationEnded',
            'languageChanged.afterStateReload',
            'availableOperationsLoaded',
            'readOnlyChanged'
          ]
        }
      };
      return ahConfig;
    }
  ]);