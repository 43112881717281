//trzeba pomyslec jak czyscic te listy
angular.module('ihestiaCommonDirectives')
  .service('IhestiaOneListUploadHelper', ['IhestiaAbstractListUploadHelper', '$rootScope', 'IHestiaOneListFileModel', '$alert', 'filesSvc', '$timeout', 'OneListUploadDataContainer',
    function(IhestiaAbstractListUploadHelper, $rootScope, IHestiaOneListFileModel, $alert, filesSvc, $timeout, OneListUploadDataContainer) {
      var OneListUploadHelper = function() {
        IhestiaAbstractListUploadHelper.apply(this, arguments);
        var self = this;

        this.init = function(scopeName, callbacks, uplodedFiles, config) {

          self.data[scopeName] = new OneListUploadDataContainer();
          if (angular.isObject(config)) {
            self.data[scopeName].config = angular.extend(self.data[scopeName].configDefault, config);
          }

          self.data[scopeName].status = 'WAITING';
          //kopiujemy w razie gdyby juz takie pliki były na liście
          if (angular.isArray(uplodedFiles)) {
            angular.forEach(uplodedFiles, function(uploadedFile) {
              var fileModel = new IHestiaOneListFileModel();
              fileModel.setEarlierUploadedFile(uploadedFile);
              self.data[scopeName].files.all.push(fileModel);
            });
          }

          angular.forEach(callbacks, function(value, key) {
            self.data[scopeName].callbacks[key] = value;
          });
        };

        this.onAddFile = function(e, data) {
          if (data.state() !== 'rejected') {
            var scopeName = data.scope.name;
            if (!self.isActive(scopeName)) {
              return;
            }

            angular.forEach(data.files, function(file) {
              var newFileModel = new IHestiaOneListFileModel();
              newFileModel.setFileData(file);
              if (self.data[scopeName].selectedDocumentType) {
                newFileModel.setDocumentType(self.data[scopeName].selectedDocumentType);
              } else if (self.selectedDocumentType) {
                newFileModel.setDocumentType(self.selectedDocumentType);
              }
              self.data[scopeName].files.all.push(newFileModel);
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileUploadAdd)) {
              self.data[scopeName].callbacks.onFileUploadAdd(e, data);
            }

            //pierwsza funkcja sprawdza czy aby nie usunieto plikow ktore mialy byc dodane
            if (self.areFileToSend(scopeName) && self.data[scopeName].config.autoUpload && self.data[scopeName].status === 'WAITING') {
              $timeout(function(){
                self.startSendingFiles(scopeName);
              }, 100);

            }
          }
        };

        this.startSendingFiles = function(scopeName) {
          self.data[scopeName].processedFileIndex = self.data[scopeName].getFirstIndexFileToSend();

          if (self.data[scopeName].config.checkFilesBeforeSend) {
            self.sendInfoAboutFile(scopeName);
          } else {
            self.data[scopeName].getProcessedFile().file.$submit();
          }

          if (self.data[scopeName].config.firstCheckAllFiles && self.data[scopeName].config.checkFilesBeforeSend) {
            self.data[scopeName].status = 'INFO';
          } else {
            self.data[scopeName].status = 'SENDING';
          }
        };

        this.getDocumentCodeTypeFromProcessedFile = function(scopeName) {
          var documentTypeCode = null,
            processedFile = self.data[scopeName].files.all[self.data[scopeName].processedFileIndex];

          if (processedFile.documentTypeCode) {
            documentTypeCode = processedFile.documentTypeCode;
          } else if (angular.isObject(processedFile.file) && processedFile.file.documentTypeCode) {
            documentTypeCode = processedFile.file.documentTypeCode;
          }

          return documentTypeCode;
        };

        this.onFileDelete = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var i = self.data[scopeName].files.all.length;
            while (i) {
              i -= 1;
              if (self.data[scopeName].files.all[i].file === data.file) {
                self.data[scopeName].files.all.splice(i, 1);
                break;
              }
            }
            $rootScope.$broadcast('deleteFileInUploader', {
              file: data.file,
              scopeName: scopeName
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileDelete)) {
              self.data[scopeName].callbacks.onFileDelete(e, data);
            }
          }
        };

        this.copyFileToUploaded = function(scopeName, status, message, fileServerId){
          self.data[scopeName].getProcessedFile().convertToUploadedFile(status, message, fileServerId);
        };

        /**
         * Metoda służy do kontynuacji procesu przesłania plików
         * @param  {string} scopeName  nazwa scope
         */
        this.proceedUploadingFile = function(scopeName) {
          if (!self.data[scopeName].config.keepBrokenFileInLibraryUploader || self.data[scopeName].getProcessedFile().fileIsUploadedWithoutErrors()) {
            $rootScope.$broadcast('deleteFileInUploader', {
              scopeName: scopeName,
              file: self.data[scopeName].getProcessedFile().file
            });
          }

          if (self.allFilesSended(scopeName)) {
            self.onAllFilesSended(scopeName);
          } else if (self.data[scopeName].status === 'INFO') {
            if (self.data[scopeName].processedFileIndex + 1 === self.data[scopeName].files.all.length && self.checkAllFilesSendWithoutErrors(scopeName)) {
              self.data[scopeName].status = 'SENDING';
              var foundFileToSend = false;
              angular.forEach(self.data[scopeName].files.all, function(file, index) {
                if (!foundFileToSend) {
                  self.data[scopeName].processedFileIndex = index;
                  if (self.data[scopeName].getProcessedFile().fileToSend()) {
                    foundFileToSend = true;
                    self.data[scopeName].getProcessedFile().file.$submit();
                  }
                }
              });
              //wysyłamy informacje o wszystkich plikach
            } else if (self.data[scopeName].processedFileIndex + 1 < self.data[scopeName].files.all.length) {
              self.data[scopeName].processedFileIndex += 1;
              self.sendInfoAboutFile(scopeName);
            } else if (self.data[scopeName].processedFileIndex + 1 === self.data[scopeName].files.all.length && !self.checkAllFilesSendWithoutErrors(scopeName)) {
              self.onAllFilesSended(scopeName);
            }
          } else {

            if (self.data[scopeName].processedFileIndex + 1 === self.data[scopeName].files.all.length) {
              self.data[scopeName].status = 'WAITING';
              if (self.data[scopeName].windowUploadClose) {
                self.clearUploadData(scopeName);
              }
              $rootScope.$broadcast('allFilesUploaded.' + scopeName);
            } else {
              self.data[scopeName].processedFileIndex += 1;
              if (self.data[scopeName].config.firstCheckAllFiles || !self.data[scopeName].config.checkFilesBeforeSend) {
                self.data[scopeName].getProcessedFile().file.$submit();
              } else {
                self.sendInfoAboutFile(scopeName);
              }
            }
          }
        };

        /**
         * [allFilesSended wywolane w momencie wyslania wszystkich plikow, poprawnie badz nie]
         * @return {[type]} [description]
         */
        this.onAllFilesSended = function(scopeName) {
          self.afterAllFilesSended(scopeName, self.data[scopeName].files.all);
        };

        this.deleteFile = function(scopeName, idx) {
          self.doDeleteFile(scopeName, idx, self.data[scopeName].files.all);
        };

        this.getAllfiles = function(scopeName) {
          return self.data[scopeName].files.all;
        };
      };

      return OneListUploadHelper;
    }
  ]);