angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorNewMessageCtrl', ['$scope', '$rootScope', 'ihestiaIkomunikatorNewMessageHelper', 'iKomunikatorMessagesSvc', '$alert', 'ihestiaFirstInfoOneListUploaderHelper',
    'IKomunikatorAttachmmentModel', 'iHestiaCommonModalHelper', 'fileServerSvc', 'ihestiaIkomunikatorActionHelper', 'ihestiaIkomunikatorCommonHelper', '$timeout', 'iKomunikatorConstants', 'ihestiaDictionaryHelper', 'ihestiaIkomunikatorContextHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaPlatformHelper',
    function($scope, $rootScope, ihestiaIkomunikatorNewMessageHelper, iKomunikatorMessagesSvc, $alert, ihestiaFirstInfoOneListUploaderHelper, IKomunikatorAttachmmentModel, iHestiaCommonModalHelper, fileServerSvc,
      ihestiaIkomunikatorActionHelper, ihestiaIkomunikatorCommonHelper, $timeout, iKomunikatorConstants, ihestiaDictionaryHelper, ihestiaIkomunikatorContextHelper, ihestiaIkomunikatorUserTypeHelper, ihestiaPlatformHelper) {
      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        messages: {
          maxNumberOfFiles: 'Przekroczono maksymalną liczbę plików',
          acceptFileTypes: 'Typ pliku nie jest dozwolony',
          maxFileSize: 'Plik jest za duży',
          minFileSize: 'Plik jest za mały'
        },
        maxFileSize: 10000000,
        maxChunkSize: 4000000,
        maxNumberOfFiles: 15,
        name: 'newMessageClient',
        hideQueueList: true,
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        }
      };

      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };

      $scope.constants = iKomunikatorConstants;
      $scope.oneListUploaderOptions = {
        attachmentType: 'static'
      };

      $scope.showCategory = false;

      $scope.isLifeClient = ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext();

      $scope.init = function() {
        $scope.tplData = ihestiaIkomunikatorNewMessageHelper.tplData;
        $scope.showCategory = $scope.isCategoryVisible();

        $scope.initUploader();
        $scope.fireListeners();

        if ($scope.tplData.recipientMounted) {
          $scope.tplNewMessage = 'ihestiaIkomunikatorTemplatesApp/views/common/newMessage/newMessageWithRecipientMountedContent.tpl.html';
          $rootScope.newMessageModalSettings.replaceModalBody = true;
        } else {
          $scope.tplNewMessage = 'ihestiaIkomunikatorTemplatesApp/views/common/newMessage/newMessageContent.tpl.html';
          $rootScope.newMessageModalSettings.replaceModalBody = false;
        }

        if($scope.tplData.clientInfoTypePesel){
          $scope.loadDicts();
        }
      };

      $scope.isCategoryVisible = function() {
        if (((ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && ihestiaPlatformHelper.isJupiterOrDirect()) || (ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext())) &&
          $scope.tplData.message.customFields && $scope.tplData.message.customFields.subjectId === iKomunikatorConstants.SUBJECT_ID_POLICY) {
          return true;
        } else {
          return false;
        }
      };

      /**
       * Ładowanie słowników
       * @return {undefined}
       */
      $scope.loadDicts = function() {
        $scope.$emit('showBlockUi');
        var loadedDicts = {
            nationalities: false,
            identityDocumentTypes: false
          },
          tryToHideBlockUi = function() {
            var canHide = true;
            angular.forEach(loadedDicts, function(isLoaded) {
              if (!isLoaded) {
                canHide = false;
              }
            });
            if (canHide) {
              $scope.$emit('hideBlockUi');
            }
          };

        ihestiaDictionaryHelper.getDict('nationalities', {
          requestType: 'searches',
          callback: function(nationalities) {
            $scope.tplData.nationalities = nationalities;
            loadedDicts.nationalities = true;
            tryToHideBlockUi();
          }
        });
        ihestiaDictionaryHelper.getDict('identityDocumentTypes', {
          requestType: 'searches',
          callback: function(identityDocumentTypes) {
            $scope.tplData.identityDocumentTypes = identityDocumentTypes;
            loadedDicts.identityDocumentTypes = true;
            tryToHideBlockUi();
          }
        });
      };

      $scope.initUploader = function() {
        ihestiaFirstInfoOneListUploaderHelper.init($scope.uploaderOptions.name, {
          onFileUploadDone: $scope.onFileUploadDone,
          onErrorSendingFile: $scope.onErrorSendingFile,
          onFileUploadAdd: $scope.onFileUploadAdd,
          onErrorAddingFile: $scope.checkAndBlockUpload,
          onAllFileSend: $scope.onAllFileSend
        }, null, {
          keepBrokenFileInLibraryUploader: true
        });

        $scope.fileModels = ihestiaFirstInfoOneListUploaderHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIkomunikatorNewMessage', function(event) {
          if (ihestiaFirstInfoOneListUploaderHelper.areFileToSend($scope.uploaderOptions.name)) {
            ihestiaFirstInfoOneListUploaderHelper.startSendingFiles($scope.uploaderOptions.name);
            $scope.$emit('showBlockUi', {
              label: 'Wysyłam 1 plik z ' + $scope.fileModels.length
            });
          } else {
            if (ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
              $scope.sendMessage();
            }
          }

          event.preventDefault();
        });

        $scope.$watch('tplData.message.content', function(val) {
          if(angular.isDefined(val) && $scope.tplData.message.forcedNotificationChannelName === 'sms-single'){
            $scope.numberSms = Math.ceil(val.length / 160);
            if (val.length > 480) {
              $scope.tplData.message.content = val.substring(0, 480);
            }
          }

        });

        $scope.$watch('tplData.message.customFields', function() {
          // coś modyfikuje temat już po init i musimy odświeżyć widoczność podtematu
          $scope.showCategory = $scope.isCategoryVisible();
        }, true);

        $scope.$watch('tplData.document', function(newValue) {
          if(angular.isObject(newValue)){
            ihestiaFirstInfoOneListUploaderHelper.setSelectedDocumentType($scope.uploaderOptions.name, newValue.documentType);
          }
        }, true);
      };

      $scope.onSelectChannelName = function() {
        ihestiaIkomunikatorNewMessageHelper.clearRelatedSubresource('channelName');
      };

      $scope.setSubject = function() {
        ihestiaIkomunikatorNewMessageHelper.setSubject();
      };

      $scope.onSelectCategory = function() {
        if($scope.tplData.message.customFields.categoryObject && $scope.tplData.message.customFields.categoryObject.code !== -1) {
          $scope.tplData.message.customFields.subjectContextItemOperationCategory = $scope.tplData.message.customFields.categoryObject.code;
          $scope.tplData.message.customFields.categoryDisplayValue = $scope.tplData.message.customFields.categoryObject.label;

          ihestiaIkomunikatorNewMessageHelper.onSelectSubject(false, true);
        }
      };

      $scope.onSelectSubject = function() {
        $scope.deleteAllFile();
        $scope.showCategory = $scope.isCategoryVisible();
        ihestiaIkomunikatorNewMessageHelper.onSelectSubject();
      };

      $scope.onSelectSubjectContextItem = function() {
        $scope.deleteAllFile();
        ihestiaIkomunikatorNewMessageHelper.onSelectSubjectContextItem();
      };

      $scope.onSelectRecipient = function() {
        ihestiaIkomunikatorNewMessageHelper.onSelectRecipient();
      };

      $scope.sendMessage = function() {
        $scope.$emit('showBlockUi', {
          label: 'Wysyłam wiadomość'
        });
        ihestiaIkomunikatorNewMessageHelper.clearValidatonErrors();

        if(angular.isObject($scope.tplData.contextRecipient)){
          $scope.tplData.message.customFields.customerFirstName = $scope.tplData.contextRecipient.taggedValues.firstName;
          $scope.tplData.message.customFields.customerLastName = $scope.tplData.contextRecipient.taggedValues.lastName;
          $scope.tplData.message.customFields.customerDocumentNumber = $scope.tplData.contextRecipient.taggedValues.pesel;
          $scope.tplData.message.customFields.customerId = $scope.tplData.contextRecipient.taggedValues.ikontoId;
        }

        $scope.tplData.message.contextRedisUUID = $scope.tplData.contextRedisUUID;
        var message = angular.copy($scope.tplData.message);

        iKomunikatorMessagesSvc.post(null, message.getModelForRest(true), null, function() {
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorNewMessage');
          $scope.$emit('hideBlockUi');
          $timeout(function() {
            $rootScope.$broadcast('messageSended');
          }, 0);

          $alert({
            content: 'Twoja wiadomość została wysłana.',
            type: 'success'
          });
        }, function(res) {
          $scope.$emit('hideBlockUi');

          if (res.status === 400) {
            ihestiaIkomunikatorNewMessageHelper.setValidatonErrors(res.data);
          } else {
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi podczas wysyłania wiadomości, spróbuj ponownie.');
          }

        }, {
          allowedStatuses: [400]
        });
      };

      $scope.onAllFileSend = function(data) {
        if (data.withoutError) {
          $scope.tplData.fileMessageError = false;
          angular.forEach(data.files, function(fileModel) {
            var attachment = new IKomunikatorAttachmmentModel();
            attachment.setFromFile(fileModel.file, fileModel.fileServerId);
            delete attachment.msgFileIndex;
            $scope.tplData.message.attachments.push(attachment);
          });
          $scope.sendMessage();
        } else {
          $scope.tplData.fileMessageError = true;
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.onFileUploadDone = function() {
        var numberSendedFile = ihestiaFirstInfoOneListUploaderHelper.getProcessedFileIndex($scope.uploaderOptions.name) + 1;
        if (numberSendedFile < $scope.fileModels.length) {
          $scope.$emit('hideBlockUi');
          $scope.$emit('showBlockUi', {
            label: 'Wysyłam ' + numberSendedFile + ' plik z ' + $scope.fileModels.length
          });
        }
      };

      $scope.deleteFileFromAttachment = function(id) {
        var fileServerId = $scope.fileModels[id].fileServerId;
        if (fileServerId !== null) {
          angular.forEach($scope.tplData.message.attachments, function(attachment, key) {
            if (attachment.fileServerId === fileServerId) {
              $scope.tplData.message.attachments.splice(key, 1);
            }
          });
        }
      };

      $scope.deleteAllFile = function() {
        ihestiaFirstInfoOneListUploaderHelper.clearAllFiles($scope.uploaderOptions.name);
        $scope.tplData.message.attachments = [];

        $scope.tplData.fileMessageError = false;
      };

      $scope.deleteFile = function(id) {
        $scope.deleteFileFromAttachment(id);
        ihestiaFirstInfoOneListUploaderHelper.deleteFile($scope.uploaderOptions.name, id);
        if (ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
          $scope.tplData.fileMessageError = false;
        }
      };

      $scope.openSearchRecipient = function() {
        //jesli okno nowej wiadomosci otwarte z wyszukiwania, to wchodzac z powrotem przeladowujemy wyszukiwanie
        ihestiaIkomunikatorActionHelper.showSearchPerson({
          research: $scope.tplData.openFromSearch,
          contextForNewMessage: ihestiaIkomunikatorContextHelper.getContext()
        });
        ihestiaIkomunikatorNewMessageHelper.hideNewMessageModal();
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorNewMessageHelper.setClearData();
      });

      $scope.onIsForeignerChange = function(){
        $scope.tplData.message.customFields.customerDocumentNumber = null;
      };

      $scope.init();
    }
  ]);