angular.module('lsnBase.directives')
  .directive('textUppercase', [function() {
    return {
      restrict: 'C',
      require: '?ngModel',
      link: function($scope, $element, $attrs, ngModel) {
        var isInput = false;
        if(angular.isFunction($element.is)) {
          isInput = $element.is('input');
        } else if($element && $element[0]) {
          isInput = $element[0].localName === 'input';
        }

        if (ngModel && isInput) {
          ngModel.$parsers.push(function(value) {
            if (typeof value === 'string') {
              return value.toUpperCase();
            }
            return '';
          });
        }
      }
    };
  }]);