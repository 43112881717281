/**
 * Serwis do logowania aktywności w ikoncie
 * https://ebok.atena.pl/browse/IHESTIACL-1198
 */
angular.module('ihestiaRestServicesBase')
  .service('sessionReportSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var SessionReportSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'activitylog';
        this.businessArea = 'session';
      };

      return new SessionReportSvc();
    }]);