angular.module('lsnBase.validators')
  .directive('setDefaultDateIfInvalid', ['$parse', function($parse) { // dziala tylko onBlur. Ustawia poprawny adres url dla linka zewntrznego lub wewnetrznego
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        var defaultDay = attrs.defaultDay ? new XDate(attrs.defaultDay) : new XDate(),
          defaultDiffDay = attrs.defaultDiffDay || 0,
          valueSetOnStart = false;

        /**
         * czyści date gdy wartość nie jest poprawna
         * @param {String} inputValue
         * @returns {String}
         */
        var setOnDefaultIfInvalid = function(inputValue, defaultDayChanged) {
          // sprawdzamy czy wartość jest już ustawiona na starcie
          if(!modelCtrl.$dirty && inputValue){
            valueSetOnStart = true;
          }

          // ustawiamy gdy sie nie waliduje
          // ustawiamy gdy puste
          // ustawiamy gdy jeszcze nic nie wpisaliśmy
          // gdy jest nie dostępny
          if (modelCtrl.$invalid || !inputValue || (!modelCtrl.$touched && !valueSetOnStart && defaultDayChanged) || attrs.disabled) {
            if (defaultDiffDay === 0) {
              inputValue = defaultDay.toString('yyyy-MM-dd');
            } else {
              inputValue = defaultDay.addDays(defaultDiffDay).toString('yyyy-MM-dd');
              defaultDay.addDays(-1 * defaultDiffDay);
            }
          }

          modelCtrl.$dateValue = new Date(inputValue);
          element.val(inputValue);
          modelCtrl.$setViewValue(inputValue);
        };

        var setDefaultDay = function(defaultDayTmp) {
          defaultDayTmp = new XDate(defaultDayTmp);
          if (defaultDayTmp.valid()) {
            // odpalamy observer datepickera i wrzucamy mu zmodyfikowane dane
            defaultDay = defaultDayTmp;
          }

          setOnDefaultIfInvalid($parse(attrs.ngModel)(scope), true);
        };

        // wrzucamy własnego observera
        attrs.$observe('defaultDay', setDefaultDay);

        element.on('blur', function() {
          setOnDefaultIfInvalid($parse(attrs.ngModel)(scope));
        });

        scope.$on('$destroy', function(){
          element.off('blur');
        });

        setOnDefaultIfInvalid($parse(attrs.ngModel)(scope));
      }
    };
  }]);