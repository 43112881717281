angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaPackageModelConstants', [function(){
    var IhestiaPzaPackageModelConstants = {
      STATE_OFF: 0,
      STATE_POOL: 1,
      STATE_NOLIMIT: 2
    };

    return IhestiaPzaPackageModelConstants;
  }])
  .factory('IhestiaPzaPackageModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaConditionDefinitionModel = function() {
      this.objectName = 'IhestiaPzaPackageModel';

      this.fieldsProperties = {
        insurancePackage: {
          type: 'IhestiaPzaDictionary'
        },
        accessMode: {
          type: 'int'
        },
        discountMax: {
          type: 'int'
        },
        riseMax: {
          type: 'int'
        },
        claimCountMin: {
          type: 'int'
        },
        claimCountMax: {
          type: 'int'
        }
      };

      this.insurancePackage = null;
      this.accessMode = null;
      this.discountMax = null;
      this.riseMax = null;
      this.claimCountMin = null;
      this.claimCountMax = null;

    };


    IhestiaPzaConditionDefinitionModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaConditionDefinitionModel;
  }]);