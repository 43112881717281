angular.module('ihestiaCommonDirectives.istevenMultiSelect', [
    'isteven-multi-select'
  ])
  .config(['$provide', function($provide) {
    return $provide.decorator('istevenMultiSelectDirective', ['$delegate', '$sce', '$timeout', '$parse', '$log', '$filter',
      function($delegate, $sce, $timeout, $parse, $log, $filter) {
        var $originalDirective = $delegate[0],
          oldLinkFunc = $originalDirective.link;

        var mergedFunc = function($scope, $element, attrs) {
          oldLinkFunc.apply(this, arguments);

          $log.log('module ihestiaCommonDirectives.istevenMultiSelect is obsolete, use ihestiaCommonDirectives.istevenMultiSelectMerged intead. (also drop isteven-multi-select module if used)');

          var vMinSearchLength = 0;
          var preserveSelected = false;

          if ( typeof attrs.MinSearchLength !== 'undefined' && parseInt( attrs.MinSearchLength, 10 ) > 0 ) {
            vMinSearchLength = Math.floor( parseInt( attrs.MinSearchLength, 10 ) );
          }

          if ( typeof attrs.preserveSelected !== 'undefined' ) {
            preserveSelected = !!$parse(attrs.preserveSelected);
          }

          /**
           * Kliknięto element,
           * nadpisujemy domyślne zachowanie aby móc bezproblemowo zaznaczać/odznaczać wszystko
           * @param  {[type]} item   [description]
           * @param  {[type]} $event [description]
           * @param  {[type]} $index [description]
           * @return {[type]}        [description]
           */
          $scope.itemClicked = function(item, $event) {
            if(item.selectAll) {
              $scope.select( 'all', $event );
              $event.stopPropagation();
            } else if(item.selectNone) {
              $scope.select( 'none', $event );
              $event.stopPropagation();
            } else {
              $scope.syncItems.apply(this, arguments);
            }
            // zupełnie niepotrzebna klasa
            angular.element('.multiSelectFocus').removeClass('multiSelectFocus');
          };

          // refresh button label
          $scope.refreshButton = function() {
            var labelValue = ''; // NOSONAR
            if ($scope.outputModel.length === 0) {
              if ($scope.inputModel.length === 0 && typeof $scope.translation.noOption !== 'undefined') {
                labelValue = $scope.translation.noOption;
              } else {
                labelValue = $scope.lang.nothingSelected;
              }
            } else {
              labelValue = $filter('translate')('selected', {
                              componentCode: 'Public'
                            }) + ' ' + $scope.outputModel.length;
            }
            $scope.varButtonLabel = $sce.trustAsHtml(labelValue + '<span class="caret"></span>');
          };

          $scope.$watch('translation', function(newVal) {
            $scope.lang.nothingSelected = newVal.nothingSelected;
          });

          $scope.updateFilter = function()
          {
              // we check by looping from end of input-model
              $scope.filteredModel = [];

              if ( typeof $scope.inputModel === 'undefined' ) {
                  return false;
              }

              for( var i = $scope.inputModel.length - 1; i >= 0; i-- ) {

                  // if it's group end, we push it to filteredModel[];
                  if ( typeof $scope.inputModel[ i ][ attrs.groupProperty ] !== 'undefined' && $scope.inputModel[ i ][ attrs.groupProperty ] === false ) {
                      $scope.filteredModel.push( $scope.inputModel[ i ] );
                  }

                  // if it's data
                  var gotData = false;
                  if ( typeof $scope.inputModel[ i ][ attrs.groupProperty ] === 'undefined' ) {
                      if(preserveSelected) //
                      {
                        gotData = true;
                      }
                      // If we set the search-key attribute, we use this loop.
                      else if ( typeof attrs.searchProperty !== 'undefined' && attrs.searchProperty !== '' ) {

                          for (var key in $scope.inputModel[ i ] ) {
                              if (
                                  typeof $scope.inputModel[ i ][ key ] !== 'boolean' &&
                                   String( $scope.inputModel[ i ][ key ] ).toUpperCase().indexOf( $scope.inputLabel.labelFilter.toUpperCase() ) >= 0 &&
                                    attrs.searchProperty.indexOf( key ) > -1
                              ) {
                                  gotData = true;
                                  break;
                              }
                          }
                      }
                      // if there's no search-key attribute, we use this one. Much better on performance.
                      else {
                          for ( var key2 in $scope.inputModel[ i ] ) {
                              if (
                                  typeof $scope.inputModel[ i ][ key2 ] !== 'boolean' &&
                                  String( $scope.inputModel[ i ][ key2 ] ).toUpperCase().indexOf( $scope.inputLabel.labelFilter.toUpperCase() ) >= 0
                              ) {
                                  gotData = true;
                                  break;
                              }
                          }
                      }

                      if ( gotData === true ) {
                          // push
                          $scope.filteredModel.push( $scope.inputModel[ i ] );
                      }
                  }

                  // if it's group start
                  if ( typeof $scope.inputModel[ i ][ attrs.groupProperty ] !== 'undefined' && $scope.inputModel[ i ][ attrs.groupProperty ] === true ) {

                      if ( typeof $scope.filteredModel[ $scope.filteredModel.length - 1 ][ attrs.groupProperty ] !== 'undefined' &&
                              $scope.filteredModel[ $scope.filteredModel.length - 1 ][ attrs.groupProperty ] === false ) {
                          $scope.filteredModel.pop();
                      }
                      else {
                          $scope.filteredModel.push( $scope.inputModel[ i ] );
                      }
                  }
              }

              $scope.filteredModel.reverse();

              $timeout( function() {

                  $scope.getFormElements();

                  // Callback: on filter change
                  if ( $scope.inputLabel.labelFilter.length > vMinSearchLength ) {

                      var filterObj = [];

                      angular.forEach( $scope.filteredModel, function(value) {
                          if ( typeof value !== 'undefined' && typeof value[ attrs.groupProperty ] === 'undefined') {
                            var tempObj = angular.copy( value );
                            var index = filterObj.push( tempObj );
                            delete filterObj[ index - 1 ][ $scope.indexProperty ];
                            delete filterObj[ index - 1 ][ $scope.spacingProperty ];
                          }
                      });

                      $scope.onSearchChange({
                          data:
                          {
                              keyword: $scope.inputLabel.labelFilter,
                              result: filterObj
                          }
                      });
                  }
              }, 0);
              return null;
          };
        };

        $originalDirective.compile = function() {
          return mergedFunc;
        };

        return $delegate;
      }
    ]);
  }])
.run(['$templateCache',
  function($templateCache){
    // nadpisujemy szablon
    $templateCache.put('isteven-multi-select.htm', $templateCache.get('ihestia-commondirectives-template-app/directives/istevenMultiSelect/istevenMultiselect.tpl.html'));
  }]);