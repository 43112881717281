angular.module('ihestiaWidgets.life')
  .service('ihestiaFromRestClauseMapper', ['LsnClausesModelConstants', function(LsnClausesModelConstants) {
    var IhestiaFromRestClauseMapper = function() {
      var self = this;
      /**
       * Returns function mapping partnerClauses to format used in clause list widget
       * @param {string} language
       * @param {boolean} checkShouldCopyAttribute - flag indicating whether to check additional attribute to determine clause value,
       *  should be set to true when accepting IOffer
       * @returns {function(object): object}
       * @private
       */
      this.mapPartnerClause = function(language, checkShouldCopyAttribute) {
        return function(partnerClause) {
          var hasAgreement = self._partnerClauseHasAgreement(partnerClause, checkShouldCopyAttribute);
          // setting presentational clauses (presentationForm='Text') as selected and with 'Consent' value
          var noCheckbox = _.get(partnerClause, 'presentationForm', 'Option') === 'Text';
          var texts = _.find(partnerClause.texts, ['language', language]) || _.first(partnerClause.texts);
          return _.assign({},
            partnerClause,
            {
              texts: texts,
              value: (hasAgreement || noCheckbox) ? LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT : LsnClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT,
              selected: hasAgreement || noCheckbox,
              isDisabled: self._isPartnerClauseDisabled(partnerClause),
              noCheckbox: noCheckbox
            }
          );
        };
      };

      this.disableSelectedClauses = function(disableSelectedClauses) {
        return function(clause) {
          clause.isDisabled = disableSelectedClauses && clause.selected;
          return clause;
        };
      };

      /**
       * check is partner clause is disabled (several ways it can be passed)
       * @param partnerClause
       * @return {boolean|boolean|*}
       * @private
       */
      this._isPartnerClauseDisabled = function(partnerClause) {
        if (partnerClause.hasOwnProperty('isReadOnly')) {
          return partnerClause.isReadOnly;
        } else {
          return partnerClause.presentationForm === 'DisabledOption';
        }
      };

      /**
       * IHESTIACL-2131 - show consent for DisabledOption
       * @param {PartnerClauseModel} partnerClause
       * @param {boolean} checkShouldCopy
       * @return {boolean}
       * @private
       */
      this._partnerClauseHasAgreement = function(partnerClause, checkShouldCopy) {
        var hasConsent = partnerClause.value === 'Consent';
        if (checkShouldCopy && partnerClause.presentationForm !== 'DisabledOption') {
          return hasConsent && _.get(partnerClause, 'attributes.showCurrentConsentWhenAcceptingIOffer', false);
        } else {
          return hasConsent;
        }
      };
    };
    return new IhestiaFromRestClauseMapper();
  }]);
