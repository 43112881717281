angular.module('ihestiaRestServicesBase')
 .service('policyPaymentStatusSvc', ['IHestiaRestServiceAbstractDef',
    function (IHestiaRestServiceAbstractDef) {
      var PolicyPaymentStatusSvc = function () {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'policypaymentstatus';
        this.businessArea = 'compositeAccounting';

        this.getStatus = function(policyNumber, data, callback, errorCallback) {
          return self.get(policyNumber, data, null, callback, errorCallback, {allowedStatuses: [404]});
        };
      };
      return new PolicyPaymentStatusSvc();
    }
  ]);