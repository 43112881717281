angular.module('ihestiaCommonDirectives')
  .controller('commonModalCtrl', ['$scope',
    function($scope) {
      $scope.isTitleString = false;

      $scope.modalData = {
        visible: false,
        temporaryHidden: false,
        okSpinnerVisible: false,
        cancelSpinnerVisible: false
      };

      /**
       * Init
       */
      $scope.init = function() {
        // odpalamy listenery po załadowaniu ustawień
        var firelistenersDestroy = $scope.$watch('settings', function(settings){
          if(settings) {
            $scope.fireListeners();
            firelistenersDestroy();
          }
        }, true);
      };


      /**
       * Nasłuchujemy
       */
      $scope.fireListeners = function() {
        // pokaz modal
        $scope.$on('commonModal.show.' + $scope.settings.modalCode, function() {
          $scope.show();
        });

        // ukryj modal
        $scope.$on('commonModal.hide.' + $scope.settings.modalCode, function() {
          $scope.hide();
        });

        // pokaż spinner
        $scope.$on('commonModal.showOkSpinner.' + $scope.settings.modalCode, function() {
          $scope.modalData.okSpinnerVisible = true;
        });

        // ukryj spinner
        $scope.$on('commonModal.hideOkSpinner.' + $scope.settings.modalCode, function() {
          $scope.modalData.okSpinnerVisible = false;
        });

        // pokaż spinner anuluj
        $scope.$on('commonModal.showCancelSpinner.' + $scope.settings.modalCode, function() {
          $scope.modalData.cancelSpinnerVisible = true;
        });

        // ukryj spinner anuluj
        $scope.$on('commonModal.hideCancelSpinner.' + $scope.settings.modalCode, function() {
          $scope.modalData.cancelSpinnerVisible = false;
        });

        // ukrywamy, ale nie usuwamy modala
        $scope.$on('commonModal.temporaryHide.' + $scope.settings.modalCode, function() {
          $scope.modalData.temporaryHidden = true;
        });

        // ponownie pokazujemy wcześniej ukryty modal
        $scope.$on('commonModal.showTemporaryHidden.' + $scope.settings.modalCode, function() {
          $scope.modalData.temporaryHidden = false;
        });

      };

      /**
       * Kliknięto anuluj czy cos takiego
       * @return {[type]} [description]
       */
      $scope.cancelBtnClicked = function() {
        if (!$scope.settings.cancelBtnDisabled) {
          var defaultPrevented = false,
            preventCallback = false;
          angular.forEach(['$emit', '$broadcast'], function($func) {
            var $event = $scope[$func]('iHestiaCommonModalCancelBtnClicked.' + $scope.settings.modalCode);
            if ($event.stopCancelCallback) {
              preventCallback = true;
            }
            if ($event.defaultPrevented) {
              defaultPrevented = true;
            }
          });

          if (typeof $scope.settings.cancelBtnCallback === 'function' && !preventCallback) {
            defaultPrevented = $scope.settings.cancelBtnCallback() === false;
          }
          if (!defaultPrevented) {
            $scope.hide();
          }
        }
      };

      /**
       * Kliknieto ok czy cos w tym guscie
       * @return {[type]} [description]
       */
      $scope.okBtnClicked = function() {
        if (!$scope.settings.okBtnDisabled) {
          var validForm = $scope.checkExistsFormValid(),
            defaultPrevented = false,
            preventCallback = false;

          angular.forEach(['$emit', '$broadcast'], function($func) {
            var $event = $scope[$func]('iHestiaCommonModalOkBtnClicked.' + $scope.settings.modalCode, {
              validForm: validForm
            });
            if ($event.stopOkCallback) {
              preventCallback = true;
            }
            if ($event.defaultPrevented) {
              defaultPrevented = true;
            }
          });

          if (typeof $scope.settings.okBtnCallback === 'function' && !preventCallback) {
            defaultPrevented = $scope.settings.okBtnCallback() === false;
          }

          if (!defaultPrevented) {
            $scope.hide();
          }

        }
      };

      /**
       * Kliknieto trzeci przycisk
       * @return {[type]} [description]
       */
      $scope.thirdBtnClicked = function() {
        var defaultPrevented = false,
            preventCallback = false;

        if (!$scope.settings.thirdBtnDisabled) {
          angular.forEach(['$emit', '$broadcast'], function($func) {
            var $event = $scope[$func]('iHestiaCommonModalThirdBtnClicked.' + $scope.settings.modalCode);
            if ($event.stopThirdCallback) {
              preventCallback = true;
            }
            if ($event.defaultPrevented) {
              defaultPrevented = true;
            }
          });

          if (angular.isFunction($scope.settings.thirdBtnCallback) && !preventCallback) {
            $scope.settings.thirdBtnCallback();
          }

          if (!defaultPrevented) {
            $scope.hide();
          }
        }
      };

      /**
       * fourth button has been clicked
       */
      $scope.fourthBtnClicked = function() {
        var defaultPrevented = false,
            preventCallback = false;

        if (!$scope.settings.fourthBtnDisabled) {
          angular.forEach(['$emit', '$broadcast'], function($func) {
            var $event = $scope[$func]('iHestiaCommonModalFourthBtnClicked.' + $scope.settings.modalCode);
            if ($event.stopFourthCallback) {
              preventCallback = true;
            }
            if ($event.defaultPrevented) {
              defaultPrevented = true;
            }
          });

          if (angular.isFunction($scope.settings.fourthBtnCallback) && !preventCallback) {
            $scope.settings.fourthBtnCallback();
          }

          if (!defaultPrevented) {
            $scope.hide();
          }
        }
      };


      $scope.init();
    }
  ]);