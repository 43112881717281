angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaOperationModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var OperationModel = function() {
      this.objectName = 'OperationModel';

      this.fieldsProperties = {
        subjectKeyList: {
          type: 'array',
          elementsType: 'string'
        },
        budget: {
          type: 'bool'
        },
        centralLock: {
          type: 'bool'
        },
        centralLockValue: {
          type: 'bool'
        },
        budgetValue: {
          type: 'float'
        },
        operation: {
          type: 'IhestiaPzaDictionary'
        },
        condition: {
          type: 'IhestiaPzaDictionary'
        },
        groupChange: {
          type: 'bool'
        },
        conditionChange: {
          type: 'bool'
        },
        groupValue: {
          type: 'string'
        }
      };

      this.subjectKeyList = [];
      this.budget = false;
      this.budgetValue = null;
      this.operation = null;
      this.groupChange = null;
      this.groupValue = null;
      this.condition = null;
      this.conditionChange = null;
      this.centralLock = null;
      this.centralLockValue = null;

    };


    OperationModel.prototype = IHestiaAbstractModel.prototype;

    return OperationModel;
  }]);