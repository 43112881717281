angular.module('lsnBase')
  .service('bodyInjectorHelper', ['$rootScope', function($rootScope){
    var BodyInjectorHelper = function(){
        var self = this;

        this.directives = {};

        /**
         * wstrzyknięcie dyrektywy do html
         * @param  {string} directive nazwa dyrektywy w formacie dla html np. to-jest-dyrektywa
         * @param  {object} params paramtry do dyrektywy {my-data: 'ok', x: 'y'}
         * @param  {string} key       unikatowy klucz (opcjonalnie)
         *
         * @return {string} kod dyrektywy
         */
        this.injectDirectiveWithParams = function(directive, params, key) {
          if(!key) {
            key = self.getUniqueKey();
          }

          self.injectDirective(key, directive);
          return key;
        };

        /**
         * Zwraca unikatowy kod da dyrektyw
         * @return {[type]} [description]
         */
        this.getUniqueKey = function() {
          var uniqueKey = '';
          var randkey = function() {
            var key = '';
            for(var i = 0; i < 5; i++) {
              key += Math.round(Math.random() * 10000) + '-';
            }
            return key;
          };
          for(uniqueKey = randkey(); self.directives[uniqueKey]; uniqueKey = randkey()){
            lsnNg.noop();
          }

          return uniqueKey;
        };

        /**
         * wstrzyknięcie dyrektywy do html
         * @param  {string} key       unikatowy klucz
         * @param  {string} directive nazwa dyrektywy w formacie dla html np. to-jest-dyrektywa
         * @param  {object} params paramtry do dyrektywy {my-data: 'ok', x: 'y'}
         */
        this.injectDirective = function(key, directive, params)
        {
          if(!params) {
            params = {};
          }
          var addDirective = function(){
            self.directives[key] = {
              directive: directive,
              params: params
            };
          };

          if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') { // jak nie jesteśmy w cyklu przetwarzania to odpalamy taki
              $rootScope.$apply(addDirective);
          }
          else
          {
            addDirective();
          }
        };

        /**
         * usunięcię dyrektywy z html
         * @param  {string} key
         */
        this.removeDirective = function(key)
        {
          if(typeof self.directives[key] !== 'undefined')
          {
            var dropDirective = function(){
              delete self.directives[key];
            };

            if ($rootScope.$$phase !== '$apply' && $rootScope.$$phase !== '$digest') { // jak nie jesteśmy w cyklu przetwarzania to odpalamy taki
                $rootScope.$apply(dropDirective);
            }
            else
            {
              dropDirective();
            }
          }
        };
      };

      return new BodyInjectorHelper();
  }]);