angular.module('lsnBase.directives')
  .provider('lsnMaskCfg', [function(){

    var lsnMaskCfg = this;

    /**
     * true, jeśli używane w ionic
     * @type {Boolean}
     */
    this.mobileMode = false;

    /**
     * jeśli true, to próbujemy wywalać trochę dziwnych
     * metod, operacji itp.
     * Niestety może to spowodować złamania jakiejś kompatybilności wstecz
     * @type {Boolean}
     */
    this.disableQuirks = false;

    /**
     * [description]
     * @return {lsnMaskCfg}
     */
    this.$get = [function(){
      return lsnMaskCfg;
    }];

  }]);