/**
 * Poprawka dla bs-datepicker'a dla min-date,
 * bez tego date-min nie uwzględniał dokładnie tej daty (min 13-04-2015 pozwalał zaznaczyć dopiero 14-04-2015)
 */
angular.module('lsnBase')
  .directive('minDate', ['$timeout',
    function($timeout) {
      return {
        restrict: 'A',
        link: function($scope, $element, $attrs) {
          if (typeof $attrs.bsDatepicker !== 'undefined') {
            var $timeoutPromise = $timeout(function() {
              // zapamiętujemy i wywalamy $observe'a z bs-datepickera
              var bsDatepickerMinObserver = $attrs.$$observers.minDate.pop();
              var minDateSetFunc = function(minDate) {
                if(minDate){
                  var minDateObj = new XDate(minDate);
                  if (minDateObj.valid()) {
                    // odpalamy observer datepickera i wrzucamy mu zmodyfikowane dane
                    bsDatepickerMinObserver(minDateObj.addDays(-1).toString('yyyy-MM-dd'));
                  } else {
                    bsDatepickerMinObserver('');
                  }
                }
              };
              minDateSetFunc($attrs.minDate);
              // wrzucamy własnego observera
              $attrs.$observe('minDate', minDateSetFunc);
            }, 0);

            $scope.$on('$destroy', function(){
              $timeout.cancel($timeoutPromise);
            });
          }
        }
      };
    }]);