angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesObjectsModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesObjectsModelV1 = function() {
        this.objectName = 'ApplicationAllowedChangesObjects';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          estates: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          },
          vehicles: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          }
        };

        this.estates = null; // nieruchomości
        this.vehicles = null; // pojazdy
      };

      LsnApplicationAllowedChangesObjectsModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesObjectsModelV1;
    }
  ]);