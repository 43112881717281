angular.module('salesPath2')
  .controller('extraLifeModalCtrl', ['$scope', 'summaryPageHelper', 'clientVerificationHelper',
    function($scope, summaryPageHelper, clientVerificationHelper) {
      $scope.modalSettings = summaryPageHelper.tplData.modalSettings.extraLifeModal;

      $scope.tplData = {};

      $scope.init = function() {
        $scope.fireListeners();
      };

      $scope.fireListeners = function() {
        // "ergo 4" clicked
        $scope.$on('iHestiaCommonModalOkBtnClicked.extraLifeModal', function() {
          clientVerificationHelper.setUserNotification(true);
          summaryPageHelper.gotoErgo4();
        });

        // "Remind later" clicked
        $scope.$on('iHestiaCommonModalThirdBtnClicked.extraLifeModal', function() {
          clientVerificationHelper.setUserNotification(false);
        });

        // "Close" clicked
        $scope.$on('iHestiaCommonModalCancelBtnClicked.extraLifeModal', function() {
          clientVerificationHelper.setUserNotification(true);
        });

        $scope.$on('$destroy', function handler() {
        });
      };

      $scope.init();
    }
  ]);