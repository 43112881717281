angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardPoolSubjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardSubjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardSubjectModelConstants) {

      var PoolSubjectModel = function() {
        this.objectName = 'IhestiaDashboardPoolSubjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          poolId: {
            type: 'string'
          },
          login: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          type: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.poolId = null;
        this.login = null;
        this.name = null;
        this.type = IhestiaDashboardSubjectModelConstants.TYPE_POOL;

      };


      PoolSubjectModel.prototype = IHestiaAbstractModel.prototype;

      return PoolSubjectModel;
    }]);