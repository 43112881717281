angular.module('ihestiaRestServicesBase')
  /**
  * słowniki nbk
  */
  .service('nbkDictionarySvc',[ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var NbkDictionarySvc = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'dictionary';
      this.businessArea = 'nbk';
      this.apiString = 'nbk-helper-web/api';

  };

    return new NbkDictionarySvc();
}]);