angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaDictionarySvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaDictionarySvc = function() {
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'dictionary';
      };

      return new PzaDictionarySvc();
    }]);