angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorActionHelper', ['$rootScope', 'ihestiaIkomunikatorNewMessageHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorSearchPersonHelper', 'ihestiaIkomunikatorContextHelper', 'ihestiaIkomunikatorPermissionHelper', 'ihestiaIkomunikatorSearchPersonCentralHelper', 'ihestiaPlatformHelper', 'ihestiaIkomunikatorSelectSubjectHelper',
    function($rootScope, ihestiaIkomunikatorNewMessageHelper, ihestiaIkomunikatorUserTypeHelper, ihestiaIkomunikatorSearchPersonHelper, ihestiaIkomunikatorContextHelper, ihestiaIkomunikatorPermissionHelper, ihestiaIkomunikatorSearchPersonCentralHelper, ihestiaPlatformHelper, ihestiaIkomunikatorSelectSubjectHelper) {
      var ActionHelper = function() {
        var self = this;
        
        /**
         * funkcja odpalana w momencie 1 odwołania się do nowej wiadomości
         *  w zależności od typu klienta zostanie otwarte okno nowej wiadomości bądź wyszukiwarki osób
         */
        this.startNewMessage = function(context) {
          //jeśli nie ma uprawnien to nie uruchamiamy
          if (!ihestiaIkomunikatorPermissionHelper.shouldShowAction()) {
            return;
          }

          ihestiaIkomunikatorContextHelper.setContext(context);

          if (ihestiaIkomunikatorUserTypeHelper.isClientUserLogged()) {
            ihestiaIkomunikatorNewMessageHelper.showNewMessageModal({});
          } else if(ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && ihestiaPlatformHelper.isJupiterOrDirect()){
            ihestiaIkomunikatorSelectSubjectHelper.showModal();
          } else if (ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && angular.isDefined(context) && angular.isDefined(context.client)) {
            ihestiaIkomunikatorNewMessageHelper.showNewMessageModal({
              client: context.client
            });
          } else if ((ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() || ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()) && angular.isDefined(context) && angular.isDefined(context.customer)) {
            ihestiaIkomunikatorNewMessageHelper.showNewMessageModal({
              customer: context.customer
            });
          //wejscie z dashboadu
          } else if ((ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() || ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()) && angular.isDefined(context) && angular.isDefined(context.otherCustomer)) {
            ihestiaIkomunikatorNewMessageHelper.showNewMessageModal({
              otherCustomer: context.otherCustomer
            });
          } else if (ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged()) {
            ihestiaIkomunikatorSearchPersonHelper.showModal();
          }else if (ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()){
            ihestiaIkomunikatorSearchPersonCentralHelper.showModal();
          }
        };

        /**
         * funkcja odpalana w momencie koljnego wywołania np z listy wyszukiwania
         *  w zależności od typu klienta zostanie otwarte okno nowej wiadomości bądź wyszukiwarki osób
         */
        this.showNewMesageModal = function(data) {
          if (!angular.isObject(data)) {
            data = {};
          }
          if(angular.isDefined(data.context)){
            angular.forEach(data.context, function(value, paramName){
              ihestiaIkomunikatorContextHelper.setParamsContext(paramName, value);
            });

            if((data.context.ifNewRecipient || data.context.ifClientSearched) && !angular.isObject(data.context.settings)) {
              data.context.settings = {};
            }

            if(data.context.ifNewRecipient && data.newRecipient){
              angular.forEach(data.context.ifNewRecipient.settings, function(value, paramName){
                data.context.settings[paramName] = value;
              });
            }

            if(data.context.ifClientSearched && data.clientSearched){
              angular.forEach(data.context.ifClientSearched.settings, function(value, paramName){
                data.context.settings[paramName] = value;
              });
            }
          }
          
          ihestiaIkomunikatorNewMessageHelper.showNewMessageModal(data);
        };

        this.showSearchPerson = function(data){
          if (!angular.isObject(data)) {
            data = {};
          }
          if (ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()){
            ihestiaIkomunikatorSearchPersonCentralHelper.showModal(data);
          }else{
            ihestiaIkomunikatorSearchPersonHelper.showModal(data);
          }
        };

        $rootScope.$on('iKomunikator.startNewMessage', function($event, context) {
          self.startNewMessage(context);
        });

      };

      return new ActionHelper();
    }
  ]);