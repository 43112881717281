/**
 * Kontroler dla górnego menu
 * @param  {[type]} $scope [description]
 * @return {[type]}        [description]
 */
angular.module('salesPath2')

  .controller('HeaderMenuCtrl', ['$scope', '$state', '$filter', 'navigationHelper', 'sp2UiHelper', 'CONSTANTS', 'RESOURCES', 'applicationHelper', 'sp2CommonHelper', 'actionHelper',
    'appVariables', 'CONFIG', 'tariffFactorsModalHelper', 'ihestiaLabelHelper', 'lsnDeepExtend', 'mainDataContainer', 'ApplicationModelConstants',
    function($scope, $state, $filter, navigationHelper, sp2UiHelper, CONSTANTS, RESOURCES, applicationHelper, sp2CommonHelper, actionHelper,
      appVariables, CONFIG, tariffFactorsModalHelper, ihestiaLabelHelper, lsnDeepExtend, mainDataContainer, ApplicationModelConstants) {

      /**
       * schemat Ustawień menu
       * @type {Object}
       */
      $scope.iHestiaMenuOptionsSchema = {
        header: RESOURCES.APP_FULLNAME,
        visible: true,
        leftNavBar: {
          items: [{
            code: 'applicationNumber',
            name: {
              __: ['sp2.menu.calcNumber', 'sp2']
            },
            class: 'icon-solo icon-numkal',
            customTemplate: 'salesPath2Template/common/menu/navBarApplicationNumber.tpl.html',
            disabled: true
          }, {
            code: 'persons',
            name: {
              __: ['sp2.menu.persons', 'sp2']
            },
            class: 'icon-solo icon-osoby',
            disabled: false
          }, {
            code: 'owu',
            name: {
              __: ['sp2.menu.owu', 'sp2']
            },
            class: 'icon-solo icon-owu',
            disabled: false
          }, {
            code: 'iod',
            name: {
              __: ['sp2.menu.distributorInformation', 'sp2']
            },
            class: 'icon-sl-fill icon-user-1',
            disabled: false
          }, {
            code: 'newCustomer',
            name: {
              __: ['sp2.menu.newCustomer', 'sp2']
            },
            class: 'icon-solo icon-nowykl',
            disabled: false
          }]
        },
        userInfo: {
          active: true,
          stats: false
        }
      };

      $scope.hideDataTransferOnStatus = [
        ApplicationModelConstants.APPLICATION_STATUS_OFFER_ACCEPTED,
        ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_ACCEPTED,
        ApplicationModelConstants.APPLICATION_STATUS_IOFFER_ACCEPTED
      ];

      /**
       * nasluchuje na akcje actionHelpera
       * @type {Object}
       */
      $scope.menuListener = {
        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        _afterAction: function(actionName) {
          if (actionName === 'languageChanged.beforeStateReload') {
            $scope.translate();
          }
        }
      };

      /**
       * ustawienia menu (na podstawie schematu)
       * @type {Object}
       */
      $scope.iHestiaMenuOptions = {};

      /**
       * [getAllProducts ustawia w menu produkty z konfiguracj]
       * @param {Boolean} refresh czy odswiezamy juz istniejaca liste produktow
       * @return {[type]} [description]
       */
      $scope.setAllProducts = function(refresh) {
        refresh = (refresh === true) ? true : false;
        var productList = [];
        angular.forEach(RESOURCES.PRODUCTLIST, function(product) {
          if (Number.isInteger(product.MENU_POSITION) && product.MENU_POSITION > 0) {
            productList[product.MENU_POSITION - 1] = {
              code: product.CODE,
              name: product.MENU_NAME,
              class: '',
              disabled: false
            };
          }

        });

        var products = {
          code: 'products',
          name: {
            __: ['sp2.menu.products', 'sp2']
          },
          class: 'icon-solo icon-produkty',
          disabled: false
        };

        if (productList.length > 0) {
          products.items = productList;
        }
        var prodsIdx = $scope.getMenuItemIndex('products');
        if (refresh && prodsIdx !== -1) { //produkty sa w wersji przetlumaczonej dlatego nadpisujemy tu i tu uruchamiamy tylko wtedy gdy istnieje juz element products w menu)
          lsnDeepExtend($scope.iHestiaMenuOptionsSchema.leftNavBar.items[prodsIdx], products);
          lsnDeepExtend($scope.iHestiaMenuOptions.leftNavBar.items[prodsIdx], products);
        } else {
          var personsIdx = $scope.getMenuItemIndex('persons');
          $scope.iHestiaMenuOptionsSchema.leftNavBar.items.splice(personsIdx, 0, products); //produkcty przed pozycją "Osoby"
        }
      };

      /**
       * zwraca inde elemetu z menu o zadanym kodzie
       * @param  {String} code kod pozycji
       * @return {Number} -1 gdy nie znaleziono
       */
      $scope.getMenuItemIndex = function(code) {
        var idx = -1;
        lsnNg.forEach($scope.iHestiaMenuOptionsSchema.leftNavBar.items, function(item, index) {
          if (item.code === code) {
            idx = index;
            return false;
          }
          return true;
        });
        return idx;
      };

      $scope.menuCallbacks = {
        persons: function() {
          navigationHelper.go(CONSTANTS.PAGE_CLIENT);
        },
        iod: applicationHelper.printIOD,
        newCustomer: function() {
          if (appVariables.appMode === CONSTANTS.APP_MODE_CALCULATION) {
            sp2UiHelper.showBlockUi('Zapisywanie wniosku');

            applicationHelper.saveApplication(true, function() {
              navigationHelper.go(CONSTANTS.PAGE_INIT, {
                action: 'new'
              });
            });
          } else {
            navigationHelper.go(CONSTANTS.PAGE_INIT, {
              action: 'new'
            });
          }
        },
        products: function() {
          navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMPARE);
        },
        matrix: function() {
          navigationHelper.go(CONSTANTS.PAGE_MATRIX);
        },
        owu: function() {
          sp2CommonHelper.showModal('owuModal');
        },
        dataTransfer: function() {
          sp2CommonHelper.showModal('dataTransferModal');
        }
      };

      $scope.init = function() {
        if (CONFIG.MODULES.tariffFactors && sp2CommonHelper.canRenderTariffFactors()) {
          $scope.iHestiaMenuOptionsSchema.leftNavBar.items.splice(1, 0, {
            code: 'tariffFactors',
            name: {
              __: ['sp2.menu.tariffFactors', 'sp2']
            }, //'Czynniki taryfikacji'
            class: 'icon-solo icon-ockom',
            disabled: false
          });

          $scope.menuCallbacks.tariffFactors = function() {
            tariffFactorsModalHelper.show();
          };
        }

        //nasluchiwanie na akcje actionHelpera
        actionHelper.registerHelper('menuListener', $scope.menuListener, [
          'languageChanged.beforeStateReload'
        ]);

        $scope.setAllProducts();
        //matryca
        if (CONFIG.MENU.matrixInLeftMenu) {
          var prodsIdx = $scope.getMenuItemIndex('products'); //pozycja przed "Produkty"
          $scope.iHestiaMenuOptionsSchema.leftNavBar.items.splice(prodsIdx, 0, {
            code: 'matrix',
            name: {
              __: ['sp2.menu.matrix', 'sp2']
            },
            class: 'icon-solo icon-matryca',
            disabled: false
          });
        }
        //przenoszenie danych
        if (CONFIG.MENU.dataTransferInLeftMenu) {
          var ncIdx = $scope.getMenuItemIndex('iod'); //pozycja przed "nowy klient"
          $scope.iHestiaMenuOptionsSchema.leftNavBar.items.splice(ncIdx, 0, {
            code: 'dataTransfer',
            name: {
              __: ['sp2.menu.dataTransfer', 'sp2']
            },
            class: 'icon-sl-fill icon-contacts-1',
            disabled: false
          });
        }
        lsnDeepExtend($scope.iHestiaMenuOptions, $scope.iHestiaMenuOptionsSchema);
        $scope.translate();

        $scope.fireListeners();
      };

      $scope.fireListeners = function() {
        /**
         * Klikanie w lewe menu
         * @param  {[type]} event [description]
         * @param  {[type]} item  [description]
         * @return {[type]}       [description]
         */
        $scope.$on('iHestiaMenuLeftNavbarItemClicked', function(event, menuElement) {
          if (angular.isFunction($scope.menuCallbacks[menuElement.code])) {
            $scope.menuCallbacks[menuElement.code]();
          } else if (angular.isDefined(CONSTANTS.PRODUCT_TO_STATE[menuElement.code])) {
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[menuElement.code]);
          }
        });

        $scope.$on('iHestiaMenuMainLogoClicked', function() {
          navigationHelper.go(appVariables.mainState);
        });

        $scope.$watch('appVariables', function(appVars) {
          //lewe menu
          if (appVars.disableLeftMenu) {
            $scope.iHestiaMenuOptions.leftNavBar.visible = false;
            $scope.iHestiaMenuOptionsSchema.leftNavBar.visible = false;
          } else {
            $scope.iHestiaMenuOptions.leftNavBar.visible = true;
            $scope.iHestiaMenuOptionsSchema.leftNavBar.visible = true;
          }
          //opcja "Przenoszenie danych"
          var dtIdx = $scope.getMenuItemIndex('dataTransfer');
          if (dtIdx !== -1) {
            if ($scope.hideDataTransferOnStatus.indexOf(mainDataContainer.application.status) !== -1 || appVars.isSupplement) {
              $scope.iHestiaMenuOptions.leftNavBar.items.splice(dtIdx, 1);
              $scope.iHestiaMenuOptionsSchema.leftNavBar.items.splice(dtIdx, 1);
            } else {
              if (appVars.appMode === CONSTANTS.APP_MODE_CALCULATION || appVars.appMode === CONSTANTS.APP_MODE_OFFER) {
                $scope.iHestiaMenuOptions.leftNavBar.items[dtIdx].disabled = false;
                $scope.iHestiaMenuOptionsSchema.leftNavBar.items[dtIdx].disabled = false;
              } else {
                $scope.iHestiaMenuOptions.leftNavBar.items[dtIdx].disabled = true;
                $scope.iHestiaMenuOptionsSchema.leftNavBar.items[dtIdx].disabled = true;
              }
            }
          }
        }, true);

        $scope.$on('ihestiaInlineEditionEnded', function(event, context, oldValue, newValue) {
          if (context === 'applicationNumber' && oldValue !== newValue) {
            actionHelper.runAction('applicationNumberEdited');
          }
        });
      };

      /**
       * tlumaczy ustawienia menu
       */
      $scope.translate = function() {
        $scope.setAllProducts(true);
        lsnDeepExtend($scope.iHestiaMenuOptions, ihestiaLabelHelper.transObject(angular.copy($scope.iHestiaMenuOptionsSchema)));
      };


      $scope.init();
    }
  ]);