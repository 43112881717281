angular.module('ihestiaCommonDirectives')
  .directive('commonSpinner', ['$parse', '$templateCache', '$timeout', '$compile', 'labelActionHelper', '$filter',
    function($parse, $templateCache, $timeout, $compile, labelActionHelper, $filter){
      return {
        restrict: 'A',
        controller: 'commonSpinnerCtrl',
        scope: true,
        link: function($scope, $element, $attrs){
          var spinnerContent = $templateCache.get('ihestia-commondirectives-template-app/directives/spinner/commonSpinner.tpl.html');
          $element = $($element);
          $element.css('position', 'relative');
          // widocznosc spinnera
          $attrs.$observe('commonSpinner', function(isEnabled){
            isEnabled = $parse(isEnabled)($scope);
            if(isEnabled) {
              $element.append(spinnerContent);
              $compile($element.find('[common-spinner-wrapper]').contents())($scope);
            } else {
              $element.find('[common-spinner-wrapper]').remove();
            }
          });

          /**
           * Odświeżanie ustawień (głownie ze względu na zmianę języka)
           * @return {undefined}
           */
          var refreshSettings = function() {
            $scope.commonSpinnerOptions = angular.copy($scope.defaultSpinnerOptions);
            angular.extend($scope.commonSpinnerOptions, $parse($attrs.commonSpinnerOptions)($scope));
          };

          // opcje spinnera
          $scope.$watch($attrs.commonSpinnerOptions, function(){
            refreshSettings();
          }, true);

          /** Reakcja na przeładowanie języka */
          var reloadLangFunc = function(){
            $scope.defaultSpinnerOptions.label = $filter('translate')('Public.loading', {componentCode: 'Public'});
            refreshSettings();
          };
          labelActionHelper.registerAction('commonSpinner', reloadLangFunc, true);
          $scope.$on('$destroy', function(){
            labelActionHelper.clearRegisteredAction('commonSpinner', reloadLangFunc);
          });
        }
      };
    }]);