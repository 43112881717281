angular.module('lsnBase.models')
  .factory('LsnApplicationModelV2', ['LsnAbstractModel', 'LsnApplicationModelConstantsV2', 'LsnPolicyModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, LsnApplicationModelConstantsV2, LsnPolicyModelConstants, lsnModelFactory) {

      var ApplicationModel = function() {
        this.objectName = 'Application';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          number: {
            type: 'string'
          },
          calculationDate: {
            type: 'date'
          },
          calculationStatus: {
            type: 'string',
            defaultValue: LsnApplicationModelConstantsV2.CALCULATION_STATUS_CALCULATION,
            dictionary: LsnApplicationModelConstantsV2.DICTIONARY_CALCULATION_AND_OFFER_STATUS // slownik laczony, poniewaz dla jupitera dostajemy oferty wciśnięte w model wniosku i tutaj ląduje też status oferty
          },
          firstPaymentMethod:
          {
            type: 'string',
            dictionary: LsnPolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD
          }, 
          generalAgreementsNumbers: {
            type: 'array',
            elementsType: 'string'
          },
          signDate: {
            type: 'date'
          },
          place: {
            type: 'string'
          },
          pkdList: {
            type: 'array',
            elementsType: 'string'
          },
          insuredRef: {
            type: 'string'
          },
          persons: {
            type: 'array',
            elementsType: 'Person'
          },
          organizations: {
            type: 'array',
            elementsType: 'Organization'
          },
          groups: {
            type: 'array',
            elementsType: 'Group'
          },
          vehicles: {
            type: 'array',
            elementsType: 'Vehicle'
          },
          risks: {
            type: 'array',
            elementsType: 'Risk',
            version: 'v2'
          },
          estates: {
            type: 'array',
            elementsType: 'Estate'
          },
          holderRef: {
            type: 'string'
          },
          distributions: {
            type: 'array',
            elementsType: 'DistributionChannel',
            version: 'v2'
          },
          segment: {
            type: 'string'
          },
          messages: {
            type: 'array',
            elementsType: 'Message'
          },
          risksVariants: {
            type: 'array',
            elementsType: 'RiskVariant',
            version: 'v2'
          },
          // policies: {
          //   type: 'array',
          //   elementsType: 'SimplePolicy'
          // },
          policyPropositions: {
            type: 'array',
            elementsType: 'PolicyProposition',
            version: 'v2'
          },
          dynamicValues: {
            type: 'dynamicValues'
          },
          symbol: {
            type: 'string'
          },
          bonusMalusData: {
            type: 'array',
            elementsType: 'BonusMalusDataObject',
            version: 'v2'
          },
          operationType: {
            type: 'string',
            defaultValue: LsnApplicationModelConstantsV2.APPLICATION_OPERATION_TYPE_NEW,
            dictionary: LsnApplicationModelConstantsV2.DICTIONARY_APPLICATION_OPERATION_TYPE
          },
          offerValidDate: {
            type: 'dateTime'
          },
          individualDiscount: {
            type: 'string'
          },
          salesDate: {
            type: 'date'
          },
          operationData: {
            type: 'ApplicationOperationData',
            version: 'v2'
          },
          premiumFrequency: {
            type: 'int'
          },
          discounts: {
            type: 'array',
            elementsType: 'PolicyDiscount'
          },
          relations: {
            type: 'array',
            elementsType: 'ApplicationRelation',
            version: 'v2'
          },
          premiumFrequencies: {
            type: 'array',
            elementsType: 'ApplicationPremiumFrequency',
            version: 'v2'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});
        this.number = null; // string, numer polisy
        this.calculationDate = null; // date, Data z jaką została wykonana taryfikacja
        this.calculationStatus = null; // string, status wniosku
        this.firstPaymentMethod = null; // metoda pierwszej platnosci
        this.generalAgreementsNumbers = []; // numery umów generalnych
        this.signDate = null; // date, Data wystawienia polisy
        this.place = null; // string, Miejsce podpisania umowy
        this.pkdList = null; // array, lista kodów pkd
        this.insuredRef = null; // string, Identyfikator ubezpieczonego. MetaData.Id z listy person lub organization
        this.persons = null; // lista Person, Lista osób. MetaData.Id musi być unikalne w obrębie person i organization
        this.organizations = null; // lista Organization, Lista osób prawnych. MetaData.ID musi być unikalne w obrębie person i organization
        this.groups = null; // lista Group, Lista grup
        this.vehicles = null; // lista Vehicle, Ubezpieczany pojazd. Maksymalnie jeden na polisie
        this.risks = null; // lista Risk, Kolekcja ubezpieczeń
        this.estates = null; // lista Estate, Lista nieruchomości do ubezpieczenia
        this.holderRef = null; // string, Idenyfikator ubezpieczającego . MetaData.Id z listy persons lub organizations
        this.distributions = null; // lista DistributionChannel
        this.segment = null; // Segment klienta
        this.messages = null; // lista Message - Lista komunikatów biznesowych.
        this.risksVariants = null; // lista RiskVariant - Warianty ryzyk z symulacji.
        this.policyPropositions = null; // lista PolicyProposition - Lista propozycji polis
        this.status = null; // Status wniosku ubezpieczeniowego. Dopuszczalne wartości:
        // • CALCULATION – wniosek w trybie kalkulacji, możliwość edycji
        // • OFFER – wniosek w trybie oferty, możliwość edycji jedynie określonych w analizie pól (np. miejsce podpisania, data podpisania)
        this.dynamicValues = null; // dynamicValues
        this.symbol = null; // symbol
        this.bonusMalusData = null; // lista danych Bonus Malus
        this.operationType = null; // Tryb procedowania wniosku ubezpieczeniowego.
        this.offerValidDate = null; // data waznosci oferty wiazacej
        this.individualDiscount = null; // kwota zniżki PZA (suwak)
        this.salesDate = null; // date, Data wystawienia oferty
        this.operationData = null; // dane dotyczące operacji na wniosku
        this.premiumFrequency = null; // czestotliwosc skladek
        this.discounts = null; // zniżki dla wniosku
        this.relations = null; // relacje pomiotów do obiektów na wniosku (np. wspołwłaścicieli fo pojazdu) UWAGA wykorzystywane obecnie tylko w symulacji składek
        this.premiumFrequencies = null; // częstotliwości składek per umowa generalna
      };

      ApplicationModel.prototype = LsnAbstractModel.prototype;

      return ApplicationModel;
    }
  ]);