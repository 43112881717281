angular.module('ihestiaGrid')
  .controller('ihestiaGridCtrl', ['$scope', 'IHestiaGridSettingsDataModel', 'ihestiaGridSettingsHelper', 'ihestiaCommonErrorHandler', '$timeout', 'iHestiaCommonModalHelper', 'ihestiaGridModalDetailsHelper', '$filter', '$state', '$window', 'ihestiaCommonConfirmModalHelper', 'ihestiaGridHelper',
    function($scope, IHestiaGridSettingsDataModel, ihestiaGridSettingsHelper, ihestiaCommonErrorHandler, $timeout, iHestiaCommonModalHelper, ihestiaGridModalDetailsHelper, $filter, $state, $window, ihestiaCommonConfirmModalHelper, ihestiaGridHelper) {

      //przy zmianie strony zerujemy stan
      ihestiaGridHelper.sharedData.baseFilterfocused = false;

      $scope.orderReportModal = {
        settings: {
          treatLabelAsI18nCode: true,
          title: ['Public.orderReport', {componentCode: 'Public'}],
          okBtnName: ['Public.order', {componentCode: 'Public'}],
          cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
          size: 'md'
        }
      };

      $scope.exportModal = {
        settings: {
          treatLabelAsI18nCode: true,
          cancelBtnName: ['Public.close', {componentCode: 'Public'}],
          okBtnName: '',
          title: ['Public.export', {componentCode: 'Public'}],
          size: 'sm'
        }
      };
      $scope.preloadedSearchFilter = '';
      $scope.selectAll = {
        selected: false
      };

      $scope.modals.detailsModal = {
        settings: {
          treatLabelAsI18nCode: true,
          cancelBtnName: ['Public.close', {componentCode: 'Public'}],
          okBtnName: '',
          title: ['Public.details', {componentCode: 'Public'}]
        },
        item: null,
        detailsFields: null
      };

      $scope.defaultFieldOptions = {
        type: 'simple',
        sortable: true,
        customizable: true, //czy możliowsc konfiguracji z poziomu girdSettings
        extendable: true, //czy szerkość może zostać zwiększona jak jest miejsce
        defaultHidden: false, //czy domyślnie ukryta kolumna
        artificial: false //czy kolumna sztuczna której nie ma na wydrukach i nie można sterować jej widocznością
      };

      //domyślne opcje extendowane przez opcje przekazane z kontrolera konkretnego grida
      $scope.options = {
        stateId: null,
        serviceMode: 'cacheSearch', // zasób wyszukiwania cacheSearch / pobieranie całości danych loadAll
        allowEmptySaleProfile: false,  //pozwalaj na pustą listę agencji, jak false to jest walidacja
        showSalesNetworks: true, //czy pokazywać w filtrach sieci sprzedażowe
        sendSalesProfiles: false, //czy wysyłać dane o agencjach itp w dynamicFilters
        multipleSelection: true, //domyślnie można zaznaczyć wiele checkboxów
        preloadedData: false, //flaga czy dane są już załadowane do grida
        preloadedDataItems: null, //lista elementów zaczytanych z controlera
        preloadedSummaryKeys: [], //tablica kluczy do generowania summary
        showFilter: true, //czy jest widoczny kontener z filtrami
        showHeader: true, //czy jest widoczny kontener kwota łącznie
        showSummary: true, //czy jest widoczny kontener ilości wyników
        showOnlyFilterSettings: false,
        showOnlyColumnSettings: false,
        forcePreviousViewFilter: false, //jeśli są filtry z poprzedniego widoku to ignorujemy inne (domyślne / wybrane settingsy)
        ignorePreviousView: false, //ignorujemy nawet dane oss z poprzedniego widoku (domyślnie przepisują się oss)
        fields: [],
        tableWidth: 912, //673 dla filtrów fasetowych
        textUppercase: true,
        multipleSelectionRestriction: null, //jeśli różny od null to wielokrotne zaznaczenie możliwe tylko jeśli wartość tego atrybutu się zgadza
        selectionGroup: null, //jeśli różny od null to zaznaczenie jednego checkboxa powoduje zaznaczenie również innych których atrybut podany w selectionGroup jest taki sam
        actionsTemplate: null, //templatka z akcjami na lewo od filtra
        tabsTemplate: null, //templatka z sekcjami nad akcjami
        actionsOnlyOnFixed: false, //templatka akcji dokładana tylko w fixed header, bo standardowo jest poza ihestiaGrid

        facetedTemplate: null, //templatka filtrów fasetowych
        facetedFilters: false, //czy mamy filtry fasetowe, jeśli będzie temlatka (facetedTemplate) to automatycznie ustawimy true
        facetedFiltersCodes: [], //lista atrybutów z filtru na których lądują filtry fasetowe, używane przy czyszczeniu przez 'Wyczyść'

        headerTopOffset: 62,  //ile px od góry ma się zatrzymać header, domyślnie tyle ile zajmuje menu
        fixedHeaderTopOffset: 48,
        showWorkerFilter: true, //czy pokazywać select pracownika,
        hideFilterSettings: false, //showanie ikonek przy szukajce i wyłączenie settingsów
        hideGear: false, //schowanie samego trybika bez wyłączenia filtrów (trybik jest gdzie indziej podpięty)
        hideExportIcon: false, //showanie ikonki eksportu
        pushToCtrl: '', //przekazanie wyników do kontrolera
        disableReports: false, //czy wyłączyć raporty asynchroniczne
        asyncReportDateOption: false, // czy okno raportu asynchronicznego powinno mieć dodatkową opcję eksportu z datą uprawnień
        exports: {
          csv: true,
          pdf: true,
          xls: false
        },
        maxPdfColumns: 7, //powyżej 7 kolumn wydruk się rozjeżdża, więc blokujemy eksport
        addColumnsToRequest: false, //czy do requesta szukania dokładać wybrane kolumny
        metaDataContainer: 'gridMetaData',
        recordsPerRequest: 50,
        defaultOrderByField: null, //domyślne sortowanie po
        defaultReverseSort: true, //domyślny kierunek sortowania DESC
        addColumnsForSimpleFilter: true, //czy dodawać do requesta listę kolumn dla filtrowania po stringu
        showSettingsOnStart: false, //czy na początku przed wyszukaniem wysunąć filtry
        summaryParser: null, //parser dla pól z summary
        orderReportFromMessage: false, //czy z poziomu komuniaktu o przekroczeniu moreThanTop bedzie mozliwosc zamowienia raportu
        loadAllMaxItems: null,
        beforeSvcCall: null, //parser wołany tuż przed strzałem do usługi
        customMaxSearchResourceTip: null //możliwość ustawienia własnej treści dla drugiej częsci komunikata przy przekroczeniu zasobu wyszukiwania
      };

      $scope.visibleFields = []; //lista pól z options.fields które !hidden (dodane dla optymalizacji)

      $scope.moreThanTopOffsetValue = 77; //jak mamy moreThanTop to musimy wszystko w dół przesunąć

      $scope.headerPositionData = {}; //dane z dyrektywy elementScrolledOut

      $scope.items = [];
      $scope.summary = {};
      $scope.parsedSummary = {};
      $scope.summaryFields = [];
      $scope.orderByField = null;
      $scope.reverseSort = true;

      $scope.showSortArrow = false;

      $scope.guid = null;
      $scope.tableToLarge = false;
      $scope.finalTableWidth = $scope.options.tableWidth;

      //string filtra nad gridem
      $scope.filters = {
        search: '',
        lastSearch: ''
      };

      $scope.loadData = {
        recordsPerRequest: $scope.options.recordsPerRequest,
        loadedRecords: 0,
        totalRecords: null,
        topRecords: null,
        loadMore: false, //flaga dla dociągania przez scroll
        spinner: false,
        moreThanTop: false
      };

      $scope.spinnerOptions = {
        size: 'sm',
        label: ''
      };

      $scope.defaultWidths = { //domyślne szerokości dla typów pól
        simple: 150,
        checkbox: 46,
        custom: 200,
        amount: 150,
        icon: 50,
        details: 200
      };

      $scope.standardFields = {
        symbol:
        { // (typ)
          align: 'center',
          width: 55,
          extendable: false
        },
        status:
        {
          align: 'left',
          width: 135,
          extendable: true
        },
        policyNumber:
        {
          align: 'left',
          width: 100,
          extendable: true
        },
        pakiet:
        {
          align: 'left',
          width: 105,
          extendable: false
        },
        amount:
        {
          align: 'right',
          width: 100,
          extendable: false
        },
        person:
        {
          align: 'left',
          width: 150,
          extendable: true,
          cutLongWords: true
        },
        date:
        {
          align: 'center',
          width: 130,
          extendable: false
        },
        dateFull:
        {
          align: 'center',
          width: 140,
          extendable: false
        },
        period:
        {
          align: 'center',
          width: 160,
          extendable: false
        },
        periodFull:
        {
          align: 'center',
          width: 310,
          extendable: false
        },
        pd:
        {
          align: 'right',
          width: 60,
          extendable: false
        },
        agencyNumber:
        {
          align: 'right',
          width: 80,
          extendable: true
        },
        number:
        { //(również numer zestawienia)
          align: 'left',
          width: 130,
          extendable: true
        },
        invoiceNumber:
        {
          align: 'left',
          width: 130,
          extendable: true
        },
        icon:
        { //(korekty, uwagi)
          align: 'center',
          width: 80,
          extendable: false
        },
        checkbox:
        {
          align: 'center',
          width: 50,
          extendable: false
        },
        desc:
        { //(tytuł)
          align: 'left',
          width: 250,
          extendable: true,
          cutLongWords: true
        },
        lp:
        {
          align: 'right',
          width: 30,
          extendable: false
        },
        jm:
        {
          align: 'center',
          width: 50,
          extendable: false
        },
        documentType:
        { //(tam gdzie windykacja)
          align: 'left',
          width: 200,
          extendable: true
        },
        file:
        {
          align: 'left',
          width: 150,
          extendable: false
        },
        info:
        { //(korekty, uwagi)
          align: 'left',
          width: 150,
          extendable: true
        },
        installment:
        {
          align: 'right',
          width: 70,
          extendable: false
        }
      };

      $scope.lastLoad = {
        hash: null,
        string: null
      };

      $scope.renderEnded = false;
      $timeout(function() {
        $scope.renderEnded = true; //nie wyświetlamy przez chwilę filtrów fasetowych, bo inaczej pozycje będą miały złą
      }, 0);

      $scope.gridSettingsLoaded = false; //czy mamy gotowe settingsy
      $scope.gridDataLoaded = false; //czy mamy załadowane dane do grida

      $scope.showSettingsShowedOnStart = false;

      $scope.scrollSynchronizeApiContainer = []; //tu odkładają swoje api dyrektywy scrollSynchronize

      $scope.resetScrollsPosition = function()
      {
        angular.forEach($scope.scrollSynchronizeApiContainer, function(container){
          if(angular.isFunction(container.scrollTo))
          {
            container.scrollTo('left');
          }
        });
      };

      /**
       * options są ładowane już po załadowaniu kontrolera przez dyrektywę gridOptions
       */
      $scope.optionsLoaded = function()
      {
        $scope.parseOptions();
        //jeśli opcje mają inną inicjalną wartość rekordów do pobrania to przepisujemy
        $scope.loadData.recordsPerRequest = $scope.options.recordsPerRequest;
        $scope.reverseSort = $scope.options.defaultReverseSort;
        $scope.orderByField = $scope.options.defaultOrderByField;
        $scope.init();
      };

      $scope.filterByString = function(delay) //eslint-disable-line consistent-return
      {
        $scope.selectAll.selected = false;

        $scope.preloadedSearchFilter = '';

        if($scope.options.preloadedData || $scope.options.serviceMode === 'loadAll')
        {
          $scope.clearSelection();
          $scope.preloadedSearchFilter = $scope.filters.search;
          return false;
        }
        else
        {
          $timeout(function(){
            if($scope.guid === null) //brak zasobu, możliwe że brak danych dla danych filtrów
            {
              return;
            }
            if($scope.filters.search === $scope.filters.lastSearch)
            {
              return;
            }
            $scope.filters.lastSearch = $scope.filters.search;
            $scope.clearData(true);

            $scope.loadGridPage(true, null, true);
          }, delay);
        }

      };

      $scope.showExportModal = function(){
        if($scope.loadData.totalRecords === 0 || $scope.loadData.totalRecords === null)
        {
          return;
        }
        iHestiaCommonModalHelper.showModal('exportModal');
      };

      /**
       * zapisanie pliku na dysku
       * @param  {string} type csv/pdf
       */
      $scope.saveExport = function(type, force)
      {
        var filters = $scope.getFilters();
        filters.skip = 0;
        filters.count = $scope.options.service.grid.top;
        filters.columns = $scope.getVisibleColumnsForExport();
        filters.visibleColumns = $scope.getVisibleColumnsForExport(); // IHESTIAOA-2653

        var columnsTab = filters.columns.split(',');
        if(!force && type === 'pdf' && columnsTab.length > $scope.options.maxPdfColumns)
        {
          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('tooManyColumns', {componentCode: 'Public'}),
            okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
            cancelBtnName: '',
            text: $filter('translate')('tooManyColumnsGridInfo', {componentCode: 'Public'}),
            size: 'sm',
            okBtnCallback: function() {
              $scope.saveExport(type, true); //po kliknięciu eksportujemy
            }
          });
          return;
        }

        var mime;
        if(type === 'csv')
        {
          mime = 'text/csv';
        }
        else if(type === 'pdf')
        {
          mime = 'application/pdf';
        }
        else if(type === 'xls')
        {
          mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
        var params = {
          headers: {
            'Accept': mime
          },
          responseType: 'blob',
          allowedStatuses: [404]
        };

        $scope.$emit('showBlockUi');
        $scope.options.service.gridGet($scope.options.stateId, $scope.guid, filters, null, function(result)
        {
          var filename = $filter('translate')('Public.exportFileName', {componentCode: 'Public'}) + '.' + type; //jak nie dostaniemy nazwy z usługi
          var contentDisposition = result.headers('Content-Disposition');

          var fileNameString = 'filename=';
          if(angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1 )
          {
            var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
            if(preCut.indexOf(';') !== -1)
            {
              preCut = preCut.substr(0, preCut.indexOf(';'));
            }
            //wywalamy jeszcze '"' z obu stron
            if(preCut.substr(0,1) === '"')
            {
              preCut = preCut.substr(1);
            }
            if(preCut.substr(preCut.length - 1,1) === '"')
            {
              preCut = preCut.substr(0, preCut.length - 1);
            }

            filename = decodeURIComponent(preCut);
          }

          var blobMime = mime;
          if(blobMime === 'csv')
          {
            blobMime += ';charset=utf-8';
          }
          var blob = new Blob([result.data], {type: blobMime});
          saveAs(blob, filename);
          $scope.$emit('hideBlockUi');
        }, function(rejection){
          if(rejection.status === 404) //zasób wyszukiwania wygasł
          {
            //tworzymy nowy zasób wyszukiwania i od razu pobieramy tyle rekordów ile przed chwilą mieliśmy
            $scope.loadGridData(true, filters.skip + filters.count, function(){
              $scope.saveExport(type);
            });
          }
        }, params);
      };

      /**
       * ustawienie domyślnych wartości w opcjach
       * @return {[type]} [description]
       */
      $scope.parseOptions = function()
      {
        if($scope.options.facetedTemplate !== null)
        {
          $scope.options.facetedFilters = true; //jak mamy templatkę to włączamy filtry fasetowe
          $scope.options.tableWidth = 673;
        }

        $scope.parseFields('fields');
        $scope.parseFields('detailsFields');
        $scope.refreshVisibleFields();

        if($scope.options.preloadedData)
        {
          $scope.gridSettingsLoaded = true;
          $scope.gridDataLoaded = true;
        }
      };

      $scope.parseFields = function(fieldsKey)
      {
        if(typeof $scope.options[fieldsKey] !== 'undefined')
        {
          angular.forEach($scope.options[fieldsKey], function(fieldOptions, key){
            if($scope.options[fieldsKey][key].templateType !== 'undefined') //dane z typu templatkowego
            {
              var standardFieldProperties = $scope.standardFields[$scope.options[fieldsKey][key].templateType]; //dane domyślne dla typu templatkowego
              angular.forEach(standardFieldProperties, function(standardValue, standardFieldKey){
                if(typeof $scope.options[fieldsKey][key][standardFieldKey] === 'undefined')
                {
                  $scope.options[fieldsKey][key][standardFieldKey] = standardValue;
                }
              });
            }
            $scope.options[fieldsKey][key] = angular.extend({}, $scope.defaultFieldOptions, fieldOptions);
            if(angular.isUndefined($scope.options[fieldsKey][key].valueSource))
            {
              $scope.options[fieldsKey][key].valueSource = $scope.options[fieldsKey][key].code;
            }
            if(typeof $scope.options[fieldsKey][key].orderBy === 'undefined' && typeof $scope.options[fieldsKey][key].valueSource !== 'undefined')
            {
              $scope.options[fieldsKey][key].orderBy = $scope.options[fieldsKey][key].valueSource;
            }
            if(typeof $scope.options[fieldsKey][key].orderBy === 'undefined')
            {
              $scope.options[fieldsKey][key].sortable = false;
            }
            if(typeof $scope.options[fieldsKey][key].width === 'undefined')
            {
              $scope.options[fieldsKey][key].width = $scope.defaultWidths[$scope.options[fieldsKey][key].type];
            }
            if(angular.isUndefined($scope.options[fieldsKey][key].tabCode))
            {
              $scope.options[fieldsKey][key].tabCode = 'base';
            }
            if($scope.options[fieldsKey][key].defaultHidden) //przed załadowaniem
            {
              $scope.options[fieldsKey][key].hidden = true;
            }
          });
        }
      };

      /**
       * wyliczamy szerkości pól na podstawie dostępnej przestrzeni
       */
      $scope.computeFieldsWidths = function()
      {
        var fieldsWidthSum = 0;
        var extandableFieldsCount = 0;
         angular.forEach($scope.options.fields, function(field){
          if(!field.hidden)
          {
            fieldsWidthSum += field.width; //zliczamy łączną szerokość widocznych pól
            if(field.extendable)
            {
              extandableFieldsCount += 1; //zliczamy liczbę rozszerzanych pól
            }
          }
        });

        var extendFields = true;
        if($scope.options.tableWidth < fieldsWidthSum)
        {
          extendFields = false;
          $scope.finalTableWidth = fieldsWidthSum;
        }
        else
        {
          $scope.finalTableWidth = $scope.options.tableWidth;
        }
        var freeSpace = $scope.options.tableWidth - fieldsWidthSum;
        var widthToAdd = Math.floor(freeSpace / extandableFieldsCount); //ile musimy dodać szerokości do każdego pola
        var widthLeftAfterRound = freeSpace - (widthToAdd * extandableFieldsCount); //ile pixeli zostało po zaokrągleniu w dół
        var firstExtandable = true;
        angular.forEach($scope.options.fields, function(field){
          if(!field.hidden)
          {
            field.computedWidth = field.width;
            if(field.extendable && extendFields)
            {
              field.computedWidth += widthToAdd; //dokładamy szerokości
              if(firstExtandable)
              {
                field.computedWidth += widthLeftAfterRound;//do pierwszego pola dokładamy resztę pozostałą po zaokrągleniu
                firstExtandable = false;
              }
            }
            //computedWidth czyli szerokość pola
            field.maxWordLengthPx = Math.floor(field.computedWidth) - 12; //odejmujemy padding
          }
        });

        $scope.tableToLarge = !extendFields;
      };

      $scope.$on('gridSettingsLoaded', function(){
        if(($scope.options.showSettingsOnStart && !$scope.showSettingsShowedOnStart) || //jeśli showSettingsOnStart to raz wysuwamy filtry
        ($scope.options.showSalesNetworks && !$scope.options.allowEmptySaleProfile && ihestiaGridSettingsHelper.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length === 0)) //brak agencji, więc nie możemy nic pobrać
        {
          $scope.showSettingsShowedOnStart = true;
          ihestiaGridSettingsHelper.showGridSettings(); //otwieramy settingsy
          $scope.$emit('hideBlockUi');
        }
        else
        {
          $scope.loadData.recordsPerRequest = ihestiaGridSettingsHelper.currentFilter.settings.otherSettings.recordsPerPage;
          $scope.loadGridData();
        }
        $scope.gridSettingsLoaded = true;
      });

      $scope.$on('gridSettingsClosed', function(){
        $scope.loadData.recordsPerRequest = ihestiaGridSettingsHelper.currentFilter.settings.otherSettings.recordsPerPage;
        $scope.loadGridData();
      });

      $scope.$on('refreshGrid', function(){
        if(!$scope.options.preloadedData && !$scope.options.serviceMode === 'loadAll')
        {
          $scope.filters = {
            search: '',
            lastSearch: ''
          };
        }
        $scope.selectAll.selected = false;

        if($scope.options.preloadedData)
        {
          $scope.putPreloadedData();
        }
        else
        {
          $scope.loadGridData(true);
        }
      });

      /**
       * pokazanie grida
       * @return {[type]} [description]
       */
      $scope.init = function()
      {
        $scope.selectAll.selected = false;
        $scope.preloadedSearchFilter = '';

        if($scope.options.preloadedData)
        {
          $scope.putPreloadedData();
        }
        else if($scope.options.showFilter && !$scope.options.hideFilterSettings)
        {
          $scope.$emit('showBlockUi');
          ihestiaGridSettingsHelper.setOptions($scope.options);
        }
        else
        {
          $scope.loadGridData();
        }
      };

      /**
       * zwraca filtry używane zarówno przy tworzeniu zasobu jak i pobierania już utworzonego zasobu
       * @return {[type]} [description]
       */
      $scope.getBasicFilters = function(){
        var basicFilters = {};
        if($scope.orderByField !== null)
        {
          basicFilters.sortBy = $scope.orderByField;
        }
        if($scope.reverseSort)
        {
          basicFilters.sortDirection = 'DESC';
        }
        else
        {
          basicFilters.sortDirection = 'ASC';
        }
        if($scope.options.serviceMode !== 'loadAll')
        {
          basicFilters.skip = $scope.loadData.loadedRecords;
          basicFilters.count = $scope.loadData.recordsPerRequest;
        }
        if($scope.options.serviceMode === 'loadAll'
          && angular.isDefined(basicFilters.sortDirection)
          && angular.isUndefined(basicFilters.sortBy))
        {
          //w naszych usługach chcemy parami sort, ale dla wcześniejszych zostawiamy bez zmian
          delete basicFilters.sortDirection;
        }
        return basicFilters;
      };

      /**
       * filtry dla utworzonego już zasobu
       * @return {[type]} [description]
       */
      $scope.getFilters = function()
      {
        var filters = $scope.getBasicFilters();
        filters.filter = $scope.filters.search;
        return filters;
      };

      /**
       * czyścimy filtr tekstowy nad gridem
       * @return {[type]} [description]
       */
      $scope.clearStringFilter = function()
      {
        $scope.filters.search = '';
        $scope.filters.lastSearch = '';
        $scope.preloadedSearchFilter = '';
      };

      /**
       * pobranie danych do grida z wcześniej utworzonego zasobu
       */
      $scope.loadGridPage = function(showBlockUi, recordsToLoad, clearData){ //eslint-disable-line consistent-return
        if($scope.options.preloadedData)
        {
          return false;
        }
        $scope.loadData.loadMore = false;

        if($scope.guid === null)
        {
          ihestiaCommonErrorHandler.throwException({
            message: 'Trying to load grid cache data without guid'
          });
        }
        if(showBlockUi)
        {
          $scope.$emit('showBlockUi');
        }
        else
        {
          $scope.loadData.spinner = true;
        }

        var filters = $scope.getFilters();
        if(typeof recordsToLoad !== 'undefined' && recordsToLoad !== null) //doładowanie przy zmianie liczby wyników na stronie
        {
          filters.count = recordsToLoad;
        }

        if($scope.options.addColumnsForSimpleFilter && filters.filter && filters.filter !== '')
        {
          filters.visibleColumns = $scope.getVisibleColumnsForExport();
        }

        var httpParams = {};
        httpParams.allowedStatuses = [404];
        var searchString = $scope.filters.search;

        $scope.options.service.gridGet($scope.options.stateId, $scope.guid, filters, $scope.options.itemModelName, function(result){
          if(searchString === $scope.filters.search) //jeśli podczas pobierania danych zmieniła się fraza wyszukiwania to ignorujemy dane
          {
            if(clearData)
            {
              $scope.clearData(true);
            }
            $scope.loadResponseData(result, showBlockUi); //podczas filtrowania otrzymamy zaktualizowane summary nie aktualizujemy tylko przy doczytywaniu wyników
            if($scope.selectAll.selected)
            {
              $scope.selectAllChange();
            }
          }
          if(showBlockUi)
          {
            $scope.$emit('hideBlockUi');
          }
          else
          {
            $scope.loadData.spinner = false;
          }
        }, function(rejection){
          if(rejection.status === 404) //zasób wyszukiwania wygasł
          {
            //tworzymy nowy zasób wyszukiwania i od razu pobieramy tyle rekordów ile przed chwilą mieliśmy
            $scope.loadGridData(true, filters.skip + filters.count);
          }
        }, httpParams);
      };

      /**
       * przekazujemy dane już załadowane
       * @param  {object} result
       */
      $scope.putPreloadedData = function()
      {
        if(!$scope.options.preloadedDataItems)
        {
          $scope.items = null;
          $scope.loadData.loadedRecords = 0;
          $scope.loadData.totalRecords = 0;
          $scope.loadData.moreThanTop = false;
          $scope.summary = {};
          $scope.parsedSummary = {};
        }
        else
        {
          $scope.items = $scope.options.preloadedDataItems;
          $scope.loadData.loadedRecords = $scope.items.length;
          $scope.loadData.totalRecords = $scope.items.length;
          $scope.loadData.moreThanTop = false;
          $scope.summary = {};
          $scope.parsedSummary = {};
          angular.forEach($scope.items, function(item, key)
          {
            angular.forEach($scope.options.preloadedSummaryKeys, function(summaryKey)
            {
              if(key === 0)
              {
                $scope.summary[summaryKey] = [0, 'PLN'];
              }
              $scope.summary[summaryKey][0] = parseFloat($scope.summary[summaryKey][0], 10) + parseFloat(item[summaryKey], 10);
            });
          });
          $scope.parseSummary();
        }
        $scope.computeFieldsWidths();
        $scope.setInitialItemsSelection();
      };

      $scope.standardParser = function(itemData)
      {
        if(typeof itemData.statementTypeCode !== 'undefined')
        {
          itemData.type = itemData.statementTypeCode;
          angular.forEach($scope.statementTypeDictionary, function(dicItem){
            if(dicItem.code === itemData.type)
            {
              itemData.tooltip = dicItem.name;
            }
          });
        }

        if(typeof itemData.commissionTypeCode !== 'undefined')
        {
          itemData.type = itemData.commissionTypeCode;
          itemData.tooltip = $scope.commissionTypeDictionary[itemData.commissionTypeCode];
        }

        if(itemData.type === 'S')
        {
          itemData.additionalClass = 'bg-primary';
        }
        if(itemData.type === 'U')
        {
          itemData.additionalClass = 'bg-success';
        }
        if(itemData.type === 'P')
        {
          itemData.additionalClass = 'bg-violet';
        }
        if(itemData.type === 'W')
        {
          itemData.additionalClass = 'bg-warning';
        }
        if(itemData.type === 'N')
        {
          itemData.additionalClass = 'bg-danger';
        }
        if(itemData.type === 'K')
        {
          itemData.additionalClass = 'bg-navy';
        }

        return itemData;
      };

      /**
       * ładuje dane z response do scope
       * @param  {Object} result
       * @param  {bool} loadSummary
       * @param  {bool} newResource czy jest to nowy zasób wyszukiwania
       */
      $scope.loadResponseData = function(result, loadSummary)
      {
        if(result.data === '') //204 No Content
        {
          return;
        }

        var items;
        if($scope.options.serviceMode === 'loadAll')
        {
          items = result.data;
        }
        else
        {
          items = result.data.items;
        }

        var newItems = [];
        angular.forEach(items, function(item, key){
          var itemData = item.getData();
          itemData = $scope.standardParser(itemData);
          if(typeof $scope.options.itemParser !== 'undefined')
          {
            itemData = $scope.options.itemParser(itemData, key);
          }
          $scope.items.push(itemData);
          if($scope.options.pushToCtrl !== '')
          {
            $scope[$scope.options.pushToCtrl].items.push(itemData);
          }
          newItems.push(itemData);
        });
        if(typeof $scope.options.loadedRowsParser !== 'undefined')
        {
          $scope.options.loadedRowsParser(newItems);
        }

        if(typeof result.data.summary !== 'undefined' && loadSummary)
        {
          $scope.summary = result.data.summary;
          $scope.parseSummary();
        }

        if(angular.isDefined(result.data.facetedFilters) && result.data.facetedFilters !== null && loadSummary && angular.isDefined($scope.gridMetaData))
        {
          $scope.gridMetaData.facetedFilters = result.data.facetedFilters;
        }

        $scope.loadData.loadedRecords = $scope.items.length;
        if($scope.options.serviceMode === 'loadAll')
        {
          $scope.loadData.totalRecords = $scope.loadData.loadedRecords;
          $scope.loadData.topRecords = $scope.loadData.loadedRecords;
        }
        else
        {
          if(result.data.moreThanTop && result.data.total > result.data.top)
          {
            $scope.loadData.totalRecords = result.data.top;
          }
          else
          {
            $scope.loadData.totalRecords = result.data.total;
          }
          $scope.loadData.topRecords = result.data.top;
        }

        if($scope.loadData.loadedRecords < $scope.loadData.totalRecords)
        {
          $timeout(function() {
            $scope.loadData.loadMore = true;
          }, 0);
        }
        else
        {
          $scope.loadData.loadMore = false;
        }
      };

      /**
       * parsujemy summary i określamy jak ma wyglądać "kwoty łącznie"
       */
      $scope.parseSummary = function()
      {
        var firstValueFound = false;
        var firstFieldWidth = 0;
        $scope.summaryFields = [];
        angular.forEach($scope.options.fields, function(field){
          if(field.hidden)
          {
            return;
          }
          if($scope.summary && angular.isDefined($scope.summary[field.valueSource]) && !firstValueFound)
          {
            //znaleźliśmy pierwsze pole z summary, więc wszystkie poprzenie kolumny (puste pola) "łączymy" dla "Kwoty łącznie"
            firstValueFound = true;
            $scope.summaryFields.push({
              computedWidth: firstFieldWidth
            });
          }
          if(!firstValueFound)
          {
            firstFieldWidth += field.computedWidth; //sumujemy długość pustych pól
          }
          else
          {
            $scope.summaryFields.push(field);
          }
          if($scope.summary && angular.isDefined($scope.summary[field.valueSource]) && angular.isArray($scope.summary[field.valueSource]))
          {
            //pola typu Amount zrzucamy do inta
            $scope.summary[field.valueSource] = $scope.summary[field.valueSource][0];
          }
        });

        if($scope.summary)
        {
          $scope.parsedSummary = angular.copy($scope.summary);
          if($scope.options.summaryParser !== null && angular.isFunction($scope.options.summaryParser))
          {
            $scope.parsedSummary = $scope.options.summaryParser($scope.parsedSummary);
          }
          $scope.parsedSummary = $scope.standardSummaryParser($scope.parsedSummary);
        }
      };

      $scope.standardSummaryParser = function(summaryData)
      {
        angular.forEach(summaryData, function(value, key){
          if(angular.isNumber(value))
          {
            summaryData[key] = $filter('number')(value, 2);
          }
        });
        return summaryData;
      };

      $scope.loadColumnsVisibility = function()
      {
        if($scope.options.showFilter && !$scope.options.hideFilterSettings)
        {
          $scope.sortFieldsByUserSelection();
          angular.forEach($scope.options.fields, function(field){
            var valueSource = field.valueSource;
            if(field.orderBy)
            {
              valueSource = field.orderBy;
            }
            if(valueSource && typeof ihestiaGridSettingsHelper.currentFilter.settings.columnSettings.visibility[valueSource] !== 'undefined')
            {
              field.hidden = !ihestiaGridSettingsHelper.currentFilter.settings.columnSettings.visibility[valueSource].selected;
            }
          });
        }

        $scope.computeFieldsWidths();
        $scope.parseSummary();
        $scope.refreshVisibleFields();
      };

      /**
       * sortujemy kolumny zgodnie z porządkiem w filtrze
       * @return {[type]} [description]
       */
      $scope.sortFieldsByUserSelection = function()
      {
        $scope.options.fields.sort(function(aField, bField){
          var aFieldIndex = -1;
          var aFieldTab = -1;
          var bFieldIndex = -1;
          var bFieldTab = -1;
          var tabIndex;

          //mamy kolumny podzielone na taby
          angular.forEach(ihestiaGridSettingsHelper.globalConfig.currentData.columns, function(tabColumns, tabCode){
            tabIndex = -1;
            //sprawdzamy która w kolejności jest ta zakładka
            angular.forEach(ihestiaGridSettingsHelper.globalConfig.currentData.columnTabs, function(tabData, tabDataIndex){
              if(tabData.code === tabCode)
              {
                tabIndex = tabDataIndex;
              }
            });
            angular.forEach(tabColumns, function(column, index){
              var aValueSource = aField.valueSource;
              if(aField.orderBy)
              {
                aValueSource = aField.orderBy;
              }
              var bValueSource = bField.valueSource;
              if(bField.orderBy)
              {
                bValueSource = bField.orderBy;
              }

              if(column.code === aValueSource) //kody kolumn odpowiadają atrybutom z usługi
              {
                aFieldIndex = index;
                aFieldTab = tabIndex;
              }
              if(column.code === bValueSource) //kody kolumn odpowiadają atrybutom z usługi
              {
                bFieldIndex = index;
                bFieldTab = tabIndex;
              }
            });
          });

          if(aFieldTab !== bFieldTab)
          {
            return aFieldTab - bFieldTab; //kolumny na innych kartach
          }
          else
          {
            return aFieldIndex - bFieldIndex;
          }
        });
      };

      /**
       * pobranie danych do grida, utworzenie zasobu
       */
      $scope.loadGridData = function(forceReload, recordsToLoad, callback)
      {
        if(typeof forceReload === 'undefined')
        {
          forceReload = false;
        }
        if(!$scope.options.preloadedData)
        {
          var dynamicFilters = {};
          if($scope.options.showFilter && !$scope.options.hideFilterSettings)
          {
            dynamicFilters = ihestiaGridSettingsHelper.getCurrentFilterData();
            if($scope.options.addColumnsToRequest)
            {
              $scope.loadColumnsVisibility();
              dynamicFilters.columns = $scope.getVisibleColumnsForExport();
            }
          }

          var stringLoad = angular.toJson(dynamicFilters);
          var hashCode = '';
          if(stringLoad.hashCode)
          {
            hashCode = stringLoad.hashCode();
          }

          //nie zmieniły się filtry, więc nie odświeżamy
          if(!forceReload && $scope.lastLoad.hash !== null && hashCode === $scope.lastLoad.hash)
          {
            $scope.loadColumnsVisibility();
            //jak ktoś zmienił liczbę rekordów na stronie, a nie zmienił samych filtrów
            if($scope.guid !== null && $scope.loadData.recordsPerRequest > $scope.loadData.loadedRecords && $scope.loadData.totalRecords > $scope.loadData.loadedRecords) //zwiększyła się liczba wyników na stronie, więc doładowujemy
            {
              $scope.loadGridPage(true, $scope.loadData.recordsPerRequest - $scope.loadData.loadedRecords);
            }
            return;
          }
          $scope.lastLoad.hash = hashCode;
          $scope.lastLoad.string = stringLoad; //do debug

          $scope.$emit('showBlockUi');
          $scope.loadColumnsVisibility();
          $scope.clearData();

          var basicFilters = $scope.getBasicFilters();

          if(typeof recordsToLoad !== 'undefined') //doładowanie przy zmianie liczby wyników na stronie
          {
            basicFilters.count = recordsToLoad;
          }

          $scope.gridDataLoaded = false;
          $scope.clearStringFilter();


          if($scope.options.beforeSvcCall !== null)
          {
            var requestParams = {
              requestType: 'dataLoad',
              basicFilters: basicFilters,
              dynamicFilters: dynamicFilters
            };
            requestParams = $scope.options.beforeSvcCall(requestParams);
            basicFilters = requestParams.basicFilters;
            dynamicFilters = requestParams.dynamicFilters;
          }

          if($scope.options.serviceMode === 'loadAll')
          {
            var getFilters = angular.extend({}, basicFilters, dynamicFilters);
            $scope.options.service.get($scope.options.stateId, getFilters, null, function(result){
                $scope.loadGridDataSuccess(result, callback);
              }, $scope.loadGridDataError);
          }
          else
          {
            //wyszukiwanie z utworzeniem zasobu
            $scope.options.service.gridSearch(
              $scope.options.stateId,
              dynamicFilters,
              function(result){
                $scope.loadGridDataSuccess(result, callback);
              },
              basicFilters,
              $scope.loadGridDataError,
              $scope.options.itemModelName
            );
          }

        }
        else
        {
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.loadGridDataError = function()
      {
        //errorCallback
        $scope.gridDataLoaded = true;
        $scope.loadData.moreThanTop = false;
        $scope.loadData.totalRecords = 0;
        $scope.loadData.topRecords = 0;
        $scope.guid = null;
        $scope.$emit('hideBlockUi');
      };

      $scope.loadGridDataSuccess = function(result, callback)
      {
        $scope.gridDataLoaded = true;
        if($scope.options.pushToCtrl !== '')
        {
          $scope[$scope.options.pushToCtrl].items = [];
        }
        if(result.status === 204 || result.data === '' || (typeof result.data.id === 'undefined' && $scope.options.serviceMode !== 'loadAll')) //brak wyników
        {
          $scope.loadData.totalRecords = 0;
          $scope.loadData.topRecords = 0;
          $scope.loadData.moreThanTop = false;
          $scope.guid = null;
        }
        else
        {
          if($scope.options.serviceMode === 'loadAll')
          {
            var items = [];
            var moreThanTopFromSvc = result.data && angular.isObject(result.data) && result.data.moreThanTop;
            if(angular.isArray(result.data)) {
              items = result.data;
            } else if (result.data && angular.isArray(result.data.items)) {
              items = result.data.items;
            }

            result.data = $scope.options.service.parseItemsWithModel(items, $scope.options.itemModelName);
            if(moreThanTopFromSvc || ($scope.options.loadAllMaxItems && items.length === $scope.options.loadAllMaxItems))
            {
              $scope.loadData.moreThanTop = true;
            }
            else
            {
              $scope.loadData.moreThanTop = false;
            }
          }
          else
          {
            $scope.guid = result.data.id;
            if(result.data.moreThanTop)
            {
              $scope.loadData.moreThanTop = true;
            }
            else
            {
              $scope.loadData.moreThanTop = false;
            }
          }
          $scope.loadResponseData(result, true, true);
        }
        if($scope.selectAll.selected)
        {
          $scope.selectAllChange();
        }
        $scope.$emit('hideBlockUi');
        $scope.$emit('gridDataLoaded', $scope.items, $scope.summary, $scope.guid);
        if(callback)
        {
          callback();
        }
      };


      /**
       * czyszczenie zaznaczenia w nadrzędnym kontrolerze
       */

      $scope.clearSelection = function()
      {
        if(typeof $scope[$scope.options.metaDataContainer] !== 'undefined')
        {
          if(typeof $scope[$scope.options.metaDataContainer].selectedItem !== 'undefined')
          {
            $scope[$scope.options.metaDataContainer].selectedItem = null;
          }
          if(typeof $scope[$scope.options.metaDataContainer].selectedItems !== 'undefined')
          {
            $scope[$scope.options.metaDataContainer].selectedItems = [];
          }
        }
      };
      /**
       * czyści wyniki wyszukiwania
       */
      $scope.clearData = function(keepMetaData)
      {
        $scope.clearSelection();

        if(!$scope.options.preloadedData)
        {
          if(!keepMetaData)
          {
            $scope.summary = {};
            $scope.parsedSummary = {};
            $scope.loadData.totalRecords = null;
            $scope.loadData.topRecords = null;
            $scope.loadData.moreThanTop = false;
          }

          $scope.items = [];
          $scope.loadData.loadedRecords = 0;
          $scope.loadData.loadMore = false;
        }
      };

      /**
       * zmiana sortowania
       * @param  {[type]} orderBy [description]
       */
      $scope.changeOrderBy = function(orderBy)
      {
        $scope.showSortArrow = true;
        if(!$scope.options.preloadedData){
          $scope.clearData(true); //keep Summary
          $scope.resetScrollsPosition();
          if($scope.orderByField === orderBy)
          {
            $scope.reverseSort = !$scope.reverseSort;
          }
          else
          {
            $scope.orderByField = orderBy;
            $scope.reverseSort = $scope.options.defaultReverseSort;
          }
          $scope.$emit('showBlockUi');
          if($scope.options.serviceMode === 'loadAll')
          {
            $scope.loadGridData(true);
          }
          else
          {
            $scope.loadGridPage(true, null, true);
          }
        }
        else
        {
          if($scope.orderByField === orderBy)
          {
            $scope.reverseSort = !$scope.reverseSort;
          }
          else
          {
            $scope.reverseSort = $scope.options.defaultReverseSort;
          }
          $scope.orderByField = orderBy;

          $scope.items = $filter('orderBy')($scope.items, $scope.orderByField, $scope.reverseSort);
        }
      };

      /**
       * pobranie dodatkowego kontentu
       */
      $scope.loadNextPage = function(){
        $scope.loadGridPage(false);
      };

      /**
       * pokazujemy modal szczegółów
       * @param  {[type]} item [description]
       * @return {[type]}      [description]
       */
      $scope.modalDetailsShow = function(item)
      {
        ihestiaGridModalDetailsHelper.showModal(item, $scope.options.detailsFields);
      };

      /**
       * Pokazujemy menu trybika
       * @return {[type]} [description]
       */
      $scope.toggleGridSettings = function() {
        if (ihestiaGridSettingsHelper.globalConfig.visible) {
          ihestiaGridSettingsHelper.hideGridSettings();
        } else {
          $timeout(function() { //żeby kliknięcie poza setting od razu ich nie zamknęło
            ihestiaGridSettingsHelper.showGridSettings();
          }, 0);
        }
      };

      /**
       * kliknięcie w checkbox
       * @param  {Object} selectedItem
       */
      $scope.selectionChange = function(selectedItem) {
        $scope.selectAll.selected = false;
        if(!$scope.options.multipleSelection) //pojedynczy checkbox
        {
          if(selectedItem.selected) //zaznaczenie
          {
            //odznaczamy inne checkoxy
            angular.forEach($scope.items, function(item) {
              if (item.selected && selectedItem !== item) {
                item.selected = false;
              }
            });

            $scope[$scope.options.metaDataContainer].selectedItem = selectedItem;
          }
          else
          {
            $scope[$scope.options.metaDataContainer].selectedItem = null;
          }
        }
        else
        {
          if($scope.options.multipleSelectionRestriction !== null && selectedItem.selected) //zaznaczenie
          {
            //odznaczamy checkoxy elementów które mają inną wartkość multipleSelectionRestriction
            angular.forEach($scope.items, function(item) {
              if (item.selected && selectedItem[$scope.options.multipleSelectionRestriction] !== item[$scope.options.multipleSelectionRestriction]) {
                item.selected = false;
              }
            });
          }
          if($scope.options.selectionGroup !== null) //odwzorowujemy stan zaznaczenia na inne elementy grupy
          {
            angular.forEach($scope.items, function(item) {
              if (selectedItem !== item && selectedItem[$scope.options.selectionGroup] === item[$scope.options.selectionGroup]) {
                item.selected = selectedItem.selected;
              }
            });
          }
          $scope[$scope.options.metaDataContainer].selectedItems = $scope.getSelectedItems();
        }
      };

      /**
       * ustawiamy początkowe zaznaczenie w gridMetaData
       * istotne w przypadku preloadedData i wielu gridów
       */
      $scope.setInitialItemsSelection = function()
      {
        if($scope[$scope.options.metaDataContainer]) //nie zawsze mamy w ogóle opcję zaznaczania
        {
          if(!$scope.options.multipleSelection) //pojedynczy checkbox
          {
            $scope[$scope.options.metaDataContainer].selectedItem = null;
            angular.forEach($scope.items, function(item) {
              if (item.selected) {
                $scope[$scope.options.metaDataContainer].selectedItem = item;
              }
            });
          }
          else
          {
            $scope[$scope.options.metaDataContainer].selectedItems = $scope.getSelectedItems();
          }
        }
      };

      /**
       * zwraca tablice identyfikatorow zaznaczonych elementow
       * @return {number[]}
       */
      $scope.getSelectedItems = function() {
        var selectedItems = [];
        angular.forEach($scope.items, function(item) {
          if (item.selected) {
            selectedItems.push(item);
          }
        });
        return selectedItems;
      };

      $scope.showOrderReportModal = function()
      {
        iHestiaCommonModalHelper.hideModal('exportModal');
        iHestiaCommonModalHelper.showModal('ihestiaGridOrderReportModal');
      };

      /**
       * zamówienie raportu asynchronicznego
       */
      $scope.orderReport = function(reportTitle, exportWithPermissionDate)
      {
        var dynamicFilters = {};
        if($scope.options.showFilter && !$scope.options.hideFilterSettings)
        {
          dynamicFilters = ihestiaGridSettingsHelper.getCurrentFilterData();

          if($scope.options.addColumnsToRequest)
          {
            $scope.loadColumnsVisibility();
            dynamicFilters.columns = $scope.getVisibleColumnsForExport();
          }

          if ($scope.options.asyncReportDateOption) {
            dynamicFilters.usersListWithPermissionGrant = exportWithPermissionDate;
          }
        }
        var basicFilters = $scope.getBasicFilters();

        var queryFilters = angular.copy(angular.extend($scope.options.service.grid, basicFilters));
        queryFilters.dynamicFilters = dynamicFilters;
        queryFilters.reportTitle = reportTitle;

        //nazwa pliku
        if($state.current.data && $state.current.data.pageTitle)
        {
          queryFilters.fileName = $state.current.data.pageTitle;
        }

        delete queryFilters.skip;
        delete queryFilters.count;
        delete queryFilters.top;

        if($scope.options.beforeSvcCall !== null)
        {
          queryFilters = $scope.options.beforeSvcCall(queryFilters);
        }

        $scope.$emit('showBlockUi');

        $scope.options.service.post($scope.options.stateId, angular.toJson(queryFilters), $scope.options.service.searchesPostfix + '/reportorder', function(){
          $scope.$emit('hideBlockUi');
          iHestiaCommonModalHelper.hideModal('exportModal');
        }, function(){
          $scope.$emit('hideBlockUi');
          iHestiaCommonModalHelper.hideModal('exportModal');
        });
      };

      $scope.getVisibleColumnsForExport = function()
      {
        var columns = [];
        angular.forEach($scope.options.fields, function(field){
          if(typeof field.orderBy !== 'undefined' && !field.hidden && field.type !== 'checkbox' && !field.artificial)
          {
            columns.push(field.orderBy);
          }
        });
        return columns.join(',');
      };

      $scope.$on('clearSelectedItems', function()
      {
        $scope.clearSelectedItems();
      });

      /**
       * czyści zaznaczenie w gridzie i w nadrzędnym controlerze
       */
      $scope.clearSelectedItems = function()
      {
        angular.forEach($scope.items, function(item, key) {
          if (item.selected) {
            $scope.items[key].selected = false;
          }
        });
        $scope.clearSelection();
      };

      $scope.selectAllChange = function ()
      {
        if($scope.selectAll.selected){
          $scope[$scope.options.metaDataContainer].selectedItems = [];
          angular.forEach($scope.items, function(item, key) {
            $scope.items[key].selected = true;
            $scope[$scope.options.metaDataContainer].selectedItems.push(item);
          });
        }else
        {
          $scope[$scope.options.metaDataContainer].selectedItems = [];
          angular.forEach($scope.items, function(item, key) {
              $scope.items[key].selected = false;
          });
        }
      };

      /**
       * odświeżamy listę widocznych kolumn
       */
      $scope.refreshVisibleFields = function()
      {
        $scope.visibleFields = [];
        angular.forEach($scope.options.fields, function(field){
          if(!field.hidden)
          {
            $scope.visibleFields.push(field);
          }
        });
      };

      /**
       * czyścimy filtry fasetowe do zera, wywalamy nawet domyślne wartości
       */
      $scope.clearFacetedFilters = function()
      {
        if($scope.options.facetedFiltersCodes && ihestiaGridSettingsHelper.currentFilter && ihestiaGridSettingsHelper.currentFilter.settings && ihestiaGridSettingsHelper.currentFilter.settings.filterSettings)
        {
          angular.forEach($scope.options.facetedFiltersCodes, function(filterCode){
            if(angular.isDefined(ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode]))
            {
              if(ihestiaGridSettingsHelper.globalConfig.currentData.multipleValues &&
                ihestiaGridSettingsHelper.globalConfig.currentData.multipleValues[filterCode] &&
                ihestiaGridSettingsHelper.globalConfig.currentData.dictionaries &&
                ihestiaGridSettingsHelper.globalConfig.currentData.dictionaries[filterCode]
                )
              {
                //isteven
                angular.forEach(ihestiaGridSettingsHelper.globalConfig.currentData.dictionaries[filterCode], function(dictElement){
                  if(dictElement.selected)
                  {
                    dictElement.selected = false;
                  }
                });
               ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode] = [];
              }
              else
              {
                delete ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode];
              }
            }
          });
          $scope.loadGridData();
        }
      };

    }
  ]);