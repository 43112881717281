angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionCtrl', ['$scope', 'IhestiaPzaConditionDefinitionModel', 'IhestiaPzaPackageModel', 'IhestiaPzaPackageModelConstants', 'ihestiaPzaDictionaryHelper', 'definitionData', 'previewMode', '$state', 'ihestiaPzaConditionSvc', 'copyMode', '$anchorScroll', 'ihestiaPzaCommonHelper', 'ihestiaPzaAppConstants',
    function($scope, IhestiaPzaConditionDefinitionModel, IhestiaPzaPackageModel, IhestiaPzaPackageModelConstants, ihestiaPzaDictionaryHelper, definitionData, previewMode, $state, ihestiaPzaConditionSvc, copyMode, $anchorScroll, ihestiaPzaCommonHelper, ihestiaPzaAppConstants) {

      /**
       * Tryb podglądu - blokuje wszystkie inputy
       * @type {Boolean}
       */
      $scope.previewMode = previewMode;
      $scope.isCentral = ihestiaPzaCommonHelper.getUserRole() === ihestiaPzaAppConstants.USER_TYPE_CENTRAL;

      /**
       * Pierwsze wciśnięcie "zapisz" przestawia flagę na true
       * @type {Boolean}
       */
      $scope.formSubmitted = false;

      /**
       * Nazwy pól dla walidacji
       * @type {Object}
       */
      var fieldLabels = {
        'discountCodeList.code':'conditionDefinition.discountCodeList.code',
        'discountCodeList.valueAmount':'conditionDefinition.discountCodeList.valueAmount',
        'discountCodeList.valuePercentage':'conditionDefinition.discountCodeList.valuePercentage',
        'vehicleIncludeList.type':'conditionDefinition.vehicleIncludeList.type',
        'vehicleIncludeList.make':'conditionDefinition.vehicleIncludeList.make',
        'vehicleIncludeList.usageYearMin':'conditionDefinition.vehicleIncludeList.usageYearMin',
        'vehicleIncludeList.usageYearMax':'conditionDefinition.vehicleIncludeList.usageYearMax',
        'code':'conditionDefinition.code',
        'description':'conditionDefinition.description',
        'status':'conditionDefinition.status',
        'vehicleExcludeList.type':'conditionDefinition.vehicleExcludeList.type',
        'vehicleExcludeList.make':'conditionDefinition.vehicleExcludeList.make',
        'vehicleExcludeList.usageYearMin':'conditionDefinition.vehicleExcludeList.usageYearMin',
        'vehicleExcludeList.usageYearMax':'conditionDefinition.vehicleExcludeList.usageYearMax',
        'packageList.claimCountMin':'conditionDefinition.packageList.claimCountMin',
        'packageList.claimCountMax':'conditionDefinition.packageList.claimCountMax',
        'packageList.discountMax':'conditionDefinition.packageList.discountMax',
        'packageList.riseMax':'conditionDefinition.packageList.riseMax'
      };

      /**
       * Słowniki
       * @type {Object}
       */
      $scope.dicts = {
        status: [],
        packageList: [],
        registrationTypes: [],
        policyTypes: [],
        riskTypes: [],
        clientTypes: [],
        additionalPremiums: []
      };

      $scope.pzaNumericSettings = ihestiaPzaCommonHelper.pzaNumericSettings;

      /**
       * Ustawienia do common-numeric
       * @type {Object}
       */
      $scope.conditonNumericSettings = {
        claims: { // liczba szkód
          decimal: '',
          decimals: 0,
          max: 100,
          min: 0,
          separator: '',
          negative: false,
          absolute: true,
          parseToString: false,
          allowBeginZero: false
        },
        vehicleYear: {
          decimal: '',
          decimals: 0,
          max: 100,
          min: 0,
          separator: '',
          negative: false,
          absolute: true,
          parseToString: false,
          allowBeginZero: false
        },
        bmClass: {
          decimal: '',
          decimals: 0,
          max: 10,
          min: -10,
          separator: '',
          negative: true,
          absolute: true,
          parseToString: false,
          allowBeginZero: false
        }
      };

      /**
       * Pomocnicze dane do ekranu
       * @type {Object}
       */
      $scope.screenData = {
        vehicleConditions: false,
        postCodeConditions: false,
        bonusMalusConditions: false,
        policyInfoConditions: false,
        additionalPremiumsConditions: false,
        discountCodesConditions: false
      };

      /**
       * Mapa - sekcja na jakiekolwiek niepuste pole w modelu deficji
       * @type {Object}
       */
      $scope.sectionMap = {
        vehicleConditions: ['vehicleExcludeList', 'vehicleIncludeList'],
        postCodeConditions: ['postCodeIncludeList', 'postCodeExcludeList'],
        bonusMalusConditions: ['bmOCMin', 'bmOCMax', 'bmACMin', 'bmACMax'],
        policyInfoConditions: ['policyType', 'riskType', 'customerType'],
        additionalPremiumsConditions: ['discountList'],
        discountCodesConditions: ['discountCodeList']
      };

      /**
       * Stałe pakietu (wyłączony, pula itd.)
       * @type {[type]}
       */
      $scope.IhestiaPzaPackageModelConstants = IhestiaPzaPackageModelConstants;

      /**
       * Zaznaczenie pakietów
       * @type {Object}
       */
      $scope.selectedPackageList = {};

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.loadDefinition();
        $scope.loadDicts();

        $scope.$watch('screenData', $scope.validatorFieldsChanged, true);


      };

      /**
       * Ukrycie/odsłonięcie sekcji - przeładowanie czyszczenia walidacji z serwera na blur
       * @return {undefined}
       */
      $scope.validatorFieldsChanged = function (){
        $scope.$broadcast('validatorFieldsChanged');
      };

      /**
       * Ładujemy definicję warunku
       * @return {undefined}
       */
      $scope.loadDefinition = function(){
        // ładujemy dane modelu
        var model = new IhestiaPzaConditionDefinitionModel();
        if(definitionData && definitionData.data) {

          // setujemy dane na modelu
          model.setData(definitionData.data);

          /** jeśli mamy dane, to mapujemy dane pomocnicze **/

          // na początek odkrywanie sekcji
          angular.forEach($scope.sectionMap, function(modelProperties, sectionCode){
            angular.forEach(modelProperties, function(modelProperty){
              if(definitionData.data[modelProperty] !== null && (!angular.isArray(definitionData.data[modelProperty]) || definitionData.data[modelProperty].length > 0) ) {
                $scope.screenData[sectionCode] = true;
              }
            });
          });

          // jesli żadna zniżka nie jest zaznaczona, to ukrywamy sekcję
          $scope.screenData.additionalPremiumsConditions = false;
          angular.forEach(definitionData.data.discountList, function(discount){
            if(discount.selected !== null) {
              $scope.screenData.additionalPremiumsConditions = true;
            }
          });

        }
        $scope.conditionData = model.getData();

        if(copyMode) {
          delete $scope.conditionData.id;
        }
      };

      /**
       * Anuluj, wracamy do listy
       * @return {undefined}
       */
      $scope.cancel = function() {
        $state.go('main.mainPage.conditionCatalog');
      };

      /**
       * Zapis definicji
       * @return {undefined}
       */
      $scope.saveCondition = function() {
        $scope.formSubmitted = true;
        var data = angular.copy($scope.conditionData);
        /** doklejamy dane o zaznaczonych pakietach start */
        data.packageList = [];
        var packagesArray = [];
        angular.forEach($scope.selectedPackageList, function(sPackage){
          packagesArray.push(sPackage);
        });
        // sortujemy pakiety po indexie
        // (chcemy zachować kolejność wysłania do usługi względem listy na interfejsie)
        // (niestety w obiektach kolejność jest gubiona)
        packagesArray.sort(function(a,b){
          if(a.index < b.index) {
            return -1;
          } else if(a.index > b.index) {
            return 1;
          } else {
            return 0;
          }
        });
        // właściwe uzupełnienie tabeli
        angular.forEach(packagesArray, function(packageElem){
          var packageData = (new IhestiaPzaPackageModel()).setData(packageElem).getData();
          data.packageList.push(packageData);
        });
        /** doklejamy dane o zaznaczonych pakietach end */


        // czyścimy dane z ukrytych sekcji
        angular.forEach($scope.sectionMap, function(modelProperties, sectionCode){
          // czy sekcja jest ukryta
          if(!$scope.screenData[sectionCode]) {
            // jeśli tak, to czyścimy wszystkie dane
            angular.forEach(modelProperties, function(modelProperty){
              if(angular.isArray(data[modelProperty])) {
                data[modelProperty] = [];
              } else {
                data[modelProperty] = null;
              }
            });
          }
        });

        // nie wysyłamy zniżek, jeśli sekcja ukryta
        if(!$scope.screenData.additionalPremiumsConditions) {
          data.discountList = [];
        }

        // uspójniamy model
        data = (new IhestiaPzaConditionDefinitionModel()).setData(data).getData();

        $scope.$emit('showBlockUi');
        var savePromise = $scope.conditionData.id ? ihestiaPzaConditionSvc.put($scope.conditionData.id, data, null, null, null, {allowedStatuses: [400]}) : ihestiaPzaConditionSvc.post(null, data, null, null, null, {allowedStatuses: [400]});
        var responseHandler = function(res){
          if(res && (res.status === 200 || res.status === 204)) {
            $state.go('main.mainPage.conditionCatalog');
          } else {
            $scope.validationResponse = res.data;
            $scope.validationResponse[0].uuid = Date.now();
            $scope.errorList = [];
            angular.forEach($scope.validationResponse, function(validationErrror) {
              if (validationErrror.defaultMessage) {
                var message = {text: validationErrror.defaultMessage};
                var fieldCode = validationErrror.field.replace(/\[.*\]/g,'');
                if(fieldLabels[fieldCode]) {
                  message.label = [fieldLabels[fieldCode], {
                    componentCode: 'pza'
                  }];

                  message.text = ' - ' + message.text;
                }

                
                $scope.errorList.push(message);
              }

            });
            $scope.errorList = $scope.errorList.unique().sort();
            $anchorScroll();
          }
        };
        savePromise.then(responseHandler, responseHandler).finally(function(){
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * Ładowanie słowników
       * @return {undefined}
       */
      $scope.loadDicts = function() {

        // standardowe słowniki pza
        var dictMap = {
          'CONDITION_STATUS': 'status',
          'POLICY_TYPE': 'policyTypes',
          'RISK_TYPE': 'riskTypes',
          'PACKAGE': 'packageList',
          'DISCOUNT': 'additionalPremiums',
          'CUSTOMER_TYPE': 'clientTypes'
        };

        ihestiaPzaDictionaryHelper.getStandardDictItems(Object.keys(dictMap)).then(function(dictItems){
          angular.forEach(dictMap, function(mappedCode, svcCode){
            $scope.dicts[mappedCode] = dictItems[svcCode];
          });

          // mapujemy zaznaczenie pakietów
          angular.forEach($scope.dicts.packageList, function(packageElem){
            // sprawdzamy, czy mamy dany pakiet w edycji, jesli tak, to ładujemy jego dane...
            angular.forEach($scope.conditionData.packageList, function(conditionPackage){
              if(conditionPackage.insurancePackage.id === packageElem.id) {
                $scope.selectedPackageList[packageElem.id] = conditionPackage;
              }
            });
            // .. a jeśli nie, to wrzucamy domyślne dane
            if(!$scope.selectedPackageList[packageElem.id]) {
              $scope.selectedPackageList[packageElem.id] = {
                accessMode: IhestiaPzaPackageModelConstants.STATE_OFF,
                insurancePackage: packageElem
              };
            }
          });

          // określamy kolejność wysyłania pakietów do usługi
          // musi być zgodna ze słownikiem, aby nie było problemów z walidacją serwera
          angular.forEach($scope.dicts.packageList, function(packageElem, index){
            $scope.selectedPackageList[packageElem.id].index = index;
          });

          // zaznaczamy domyślne opcje dla selectów polisa/ryzyko/klient
          var policyRiskClientDictMap = {
            policyType: 'policyTypes',
            riskType: 'riskTypes',
            customerType: 'clientTypes'
          };
          angular.forEach(policyRiskClientDictMap, function(dictCode, selectModel){
            angular.forEach($scope.dicts[dictCode], function(dictItem){
              if((dictItem.code === 'W' && !$scope.conditionData[selectModel]) || ($scope.conditionData[selectModel] && dictItem.code === $scope.conditionData[selectModel].code)) {
                $scope.conditionData[selectModel] = dictItem;
              }
            });
          });

        }, angular.noop);


        // rodzaje pojazdu wg etx
        $scope.$emit('showBlockUi');
        ihestiaPzaDictionaryHelper.getVehicleRegistrationTypes().then(function(res){
          if(res && (res.status === 200 || res.status === 204)) {
            $scope.dicts.registrationTypes = res.data;
          }
        }, angular.noop).finally(function() {
          $scope.$emit('hideBlockUi');
        });

      };

      $scope.init();
    }]);