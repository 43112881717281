angular.module('ihestiaWidgets.extraLife')
  .service('ihestiaExtraLifeHelper', ['$q', 'lsnDeepExtend', 'ihestiaExtraLifeSvc',
    function($q, lsnDeepExtend, ihestiaExtraLifeSvc) {
      var ExtraLifeDataHelper = function() {
        var self = this;

        var responseCodes = {};
        responseCodes.NO_ACCESS = 'NO_ACCESS';
        responseCodes.ACCESS = 'ACCESS';
        responseCodes.ACCESS_DIFFERENT_CHARACTER = 'ACCESS_DIFFERENT_CHARACTER';

        /**
         * default popup params
         * @type {Object} as follows:
         */
        var defaultParams = {
          button: {
            visible: false
          },
          popup: {
            visible: false, // {bool} is popup visible
            callInProgress: false, // {bool} true if service processing
            refusalStep: false, // {bool} whether in refusal step
            data: {}, // {Object} response from service (https://gist.github.com/lsnpatryks/fdc2210976afab04e42d7ccc08054bb6#przyk%C5%82adowy-response)
            responseCodes: responseCodes // {Object} svc response code options
          }
        };

        this.params = angular.copy(defaultParams);

        /**
         * set extra life info for person by pesel
         * @param {string} pesel
         * @return {promise}
         */
        this.setInfoByPesel = function(pesel) {
          self.params.popup.callInProgress = true;
          ihestiaExtraLifeSvc.infoByPesel(pesel)
            .then(function(resp) {
              self.params.popup.data = resp.data;
              self.params.button.visible = true;
              self.params.popup.refusalStep = false;
            }, function() {
              self.hidePopup(true);
            })
            .finally(function() {
              self.params.popup.callInProgress = false;
            });
        };

        /**
         * postopone customer decision
         */
        this.postponeDecision = function() {
          self.params.popup.callInProgress = true;
          ihestiaExtraLifeSvc.deleteAnswer(self.params.popup.data.requestUuid)
            .then(lsnNg.noop, lsnNg.noop)
            .finally(function() {
              self.params.popup.callInProgress = false;
              self.hidePopup(true);
            });
        };

        /**
         * post refusal answer
         * @param {string} answerCode
         * @return {undefined}
         */
        this.postAnswer = function(answerCode) {
          self.params.popup.callInProgress = true;
          ihestiaExtraLifeSvc.postAnswer(self.params.popup.data.requestUuid, answerCode)
            .then(lsnNg.noop, lsnNg.noop)
            .finally(function() {
              self.params.popup.callInProgress = false;
              self.hidePopup(true);
            });
        };

        /**
         * show extraLife popup
         * @return {undefined}
         */
        this.showPopup = function() {
          self.params.popup.visible = true;
        };

        /**
         * hide extraLife popup
         * @param {boolean} clearData whether to clear loaded popup data
         * @return {undefined}
         */
        this.hidePopup = function(clearData) {
          lsnDeepExtend(self.params.popup, clearData ? {
            data: {}
          } : {}, {
            visible: false
          });
          if (clearData) {
            self.params.button.visible = false;
          }
        };

      };

      return new ExtraLifeDataHelper();
    }
  ]);