angular.module('salesPath2')
  .controller('iOfferSelectionModalCtrl', ['$scope', 'sp2CommonHelper', 'summaryPageHelper', 'summaryHelper', 'dataContainerHelper',
    function($scope, sp2CommonHelper, summaryPageHelper, summaryHelper, dataContainerHelper) {
      $scope.modalSettings = summaryPageHelper.tplData.modalSettings.iOfferSelectionModal;
      $scope.viaEmailNotAllowed = summaryHelper.isFraudulentClient(false) || !dataContainerHelper.getInsurer().get('pesel');

      /**
       * perform action
       * @param {boolean} sendViaEmail
       * @return {boolean} false if can't proceed
       */
      $scope.makeIOffer = function(sendViaEmail) {
        if (sendViaEmail && $scope.viaEmailNotAllowed) {
          return false;
        }
        sp2CommonHelper.hideModal('iOfferSelectionModal');
        if (angular.isFunction($scope.modalSettings.applyCallback)) {
          $scope.modalSettings.applyCallback(sendViaEmail);
        }
        return true;
      };

    }
  ]);