angular.module('ihestiaWidgets.dashboard')
.factory('IhestiaDashboardEhlTaskTopicDict', [function() {
  var Constants = {};

  Constants.CLIENT_POLICY_DASH = 'z000181';
  Constants.COMPLAINT_DASH = 'z000182';
  Constants.CLIENT_DATA_MODIFICATION_DASH = 'z000183';
  Constants.APPLICATION_DASH = 'z000185';
  Constants.APPLICATION_UWR = 'z000186';
  Constants.APPLICATION_COMPLETION_UWR = 'z000187';
  Constants.APPLICATION_VERIFICATION = 'z000188';
  Constants.RELAY_OFFER_TO_CLIENT = 'z000189';
  Constants.RELAY_APPLICATION_REJECTION = 'z000190';
  Constants.RELAY_APPLICATION_CANCELLATION = 'z000191';
  Constants.RELAY_APPLICATION_POSTPONEMENT = 'z000192';
  Constants.REFERRAL_FOR_MEDICAL_EXAMINATION_UWR = 'z000193';
  Constants.RELAY_TO_MEDICAL_HEAD_UWR = 'z000194';
  Constants.REFERRAL_TO_UWR_HEAD_UWR = 'z000195';
  Constants.RELAY_TO_LEGAL_UWR = 'z000196';
  Constants.RELAY_TO_REINSURER_UWR = 'z000197';
  Constants.POLICY_SCAN_DASH = 'z000199';
  Constants.CLAIMS_DASH = 'z000200';
  Constants.COMMISSIONS_DASH = 'z000201';
  Constants.CONTACT_CLIENT_URGENT_DASH = 'z000202';
  Constants.PAYMENTS_DASH = 'z000203';
  Constants.APPLICATION_REPEAL_DASH = 'z000206';
  Constants.POLICY_QUESTION = 'z000207';
  Constants.COMMISSION_EXPLANATION_PARTNER = 'z000209';
  Constants.COMMISSION_INVOICES_PARTNER = 'z000210';
  Constants.POLICY_OUTSIDE_IHESTIA = 'z000211';
  Constants.PERSONAL_POLICY_OUTSIDE_IHESTIA = 'z000231';

  return Constants;
}]);