angular.module('ihestiaWidgets.commonOperations')
  .controller('taskSearchListModalCtrl', ['$scope', 'taskSearchModalHelper', 'iHestiaCommonModalHelper', '$timeout',
    function($scope, taskSearchModalHelper, iHestiaCommonModalHelper, $timeout) {

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.$emit('hideBlockUi');

        $scope.tasksListResponse = taskSearchModalHelper.tasksListResponse;

        $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaOperationsTaskSearchList', function($event) {
          $event.preventDefault();
          iHestiaCommonModalHelper.hideModal('ihestiaOperationsTaskSearchList');
          $timeout(function() {
            iHestiaCommonModalHelper.showModal('ihestiaOperationsTaskSearch');
          }, 0);
        });

        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaOperationsTaskSearchList', function() {
          taskSearchModalHelper.clearData();
        });

      };

      /**
       * Otwarcie wybranego z listy wyników zadania
       */
      $scope.goToTask = function(task)
      {
        taskSearchModalHelper.goToTask(task);        
      };

    }
  ]);