angular.module('ihestiaWidgets.eua')
  .factory('ihestiaEuaHelper', ['$filter', 'ihestiaEuaDocumentsSvc', 'ihestiaDownloadHelper', '$q',
    function($filter, ihestiaEuaDocumentsSvc, ihestiaDownloadHelper, $q) {

      var EuaHelper = function() {
        /**
         * Zwraca etykietę dla statusu
         * @param  {String} status [description]
         * @return {String}        [description]
         */
        this.getStatusDesc = function(status) {
          return $filter('translate')('eua.documentStatus.' + status, {
            componentCode: 'Public'
          });
        };

        /**
         * Ściągnięcie dokumentu
         * @param  {String} uuid uuid dokumentu eua
         * @return {Promise}
         */
        this.downloadDocument = function(uuid) {
          return $q(function(resolve, reject){
            ihestiaEuaDocumentsSvc.get('agencyAgreements', null, uuid + '/tokens', null, null, {
              allowedStatuses: [404, 403, 410]
            }).then(function(response) {
              var data = response.data;
              if ($.isEmptyObject(data) || typeof data.token === 'undefined') {
                ihestiaDownloadHelper.showErrorAlertModal($filter('translate')('Public.tokenNotReturnedByService'));
                reject();
              } else {
                ihestiaDownloadHelper.downloadFileByTokenId(data.token, 'dokument.pdf').then(resolve,reject);
              }
            }, function(rejection) {
              ihestiaDownloadHelper.showErrorAlertModal(null, rejection.status);
              reject();
            });
          });
        };
      };

      return new EuaHelper();
    }]);