angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi OSS employee (opiekunowie)
   * @return {[type]} [description]
   */
  .factory('ihestiaOssEmployeeSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaEmployee = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/employee';
        this.businessArea = 'oss';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new IHestiaEmployee();
  }])
  ;
