angular.module('ihestiaRestServicesBase')
  .factory('pwdAdminPartnersSvc', [ 'IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {

    var PwdAdminPartnersSvc = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
      this.resource = 'admin/partner';
      this.businessArea = 'pwd';

    };

    return new PwdAdminPartnersSvc();
  }])
  ;