angular.module('lsnBase.autocompleters')
  .directive('lsnAutocompleterPkd', ['$sce',
    function($sce) {
      return {
        restrict: 'E',
        require: '^form',
        scope: {
          savedPkds: '=',
          pkdDataArray: '=',
          pkdHelper: '=',
          appName: '=',
          popoverPlacement: '=?',
          disabled: '=?'
        },
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else {
            return 'lsn-base-templates-app/autocompleters/pkd/pkd.tpl.html';
          }
        },
        link: function(scope, element, attrs, formCtrl) {
          scope.form = formCtrl;
        },
        controller: ['$scope', '$element', '$attrs', '$parse',
          function($scope) {
            $scope.temp = {
              PKD: ''
            };
            $scope.pkdObjects = [];
            $scope.loadingElements = false;

            $scope.init = function() {
              var numberOfInvalid = 0;
              angular.forEach($scope.savedPkds, function(code) {
                if ($scope.pkdDataArray[code].supported !== 1) {
                  numberOfInvalid += 1;
                }
                $scope.addObjectPKD($scope.pkdDataArray[code]);
              });
              $scope.numberInvalidPkd = numberOfInvalid;
            };

            $scope.findFilterPkd = function(val) {
              $scope.loadingElements = true;
              return $scope.pkdHelper.findPkds(val, $scope.getSelectedCodePkd()).then(function(res) {
                $scope.loadingElements = false;
                return res;
              }, angular.noop);
            };

            $scope.onSelectPkd = function($item) {
              $scope.addPKD($item);
              $scope.temp.PKD = '';
            };

            $scope.clearValues = function() {
              $scope.pkdObjects = [];
              $scope.temp = {
                PKD: ''
              };
            };

            $scope.addPKD = function(pkd) {
              if(angular.isDefined($scope.disabled) && $scope.disabled){
                return;
              }
              $scope.pkdDataArray[pkd.code] = pkd;

              if (pkd.supported === 1) {
                $scope.savedPkds.push(pkd.code);
              } else if (angular.isNumber($scope.numberInvalidPkd) && !isNaN($scope.numberInvalidPkd)) {
                $scope.numberInvalidPkd = $scope.numberInvalidPkd + 1;
              } else {
                $scope.numberInvalidPkd = 1;
              }
              $scope.addObjectPKD(pkd);
              $scope.numberInvalidPkd = $scope.pkdObjects.length - $scope.savedPkds.length;
            };

            $scope.addObjectPKD = function(pkd) {
              var copyPkd = angular.copy(pkd),
                nameToTrust = ''; // nazwa - szczytana z pola desc lub name
              if (angular.isString(copyPkd.name)) {
                nameToTrust = copyPkd.name;
              } else if (angular.isString(copyPkd.desc)) {
                nameToTrust = copyPkd.desc;
              }
              copyPkd.name = $sce.trustAsHtml(nameToTrust);
              $scope.pkdObjects.push(copyPkd);
            };

            $scope.removePkd = function(pkdCode) {
              if(angular.isDefined($scope.disabled) && $scope.disabled){
                return;
              }
              var numberOfInvalid = 0;
              for (var i = $scope.pkdObjects.length - 1; i >= 0; i -= 1) {
                if ($scope.pkdObjects[i].code === pkdCode) {
                  $scope.pkdObjects.splice(i, 1);
                } else if ($scope.pkdObjects[i].supported !== 1) {
                  numberOfInvalid += 1;
                }
              }
              for (var j = $scope.savedPkds.length - 1; j >= 0; j -= 1) {
                if ($scope.savedPkds[j] === pkdCode) {
                  $scope.savedPkds.splice(j, 1);
                }
              }
              $scope.numberInvalidPkd = numberOfInvalid;
            };

            $scope.getSelectedCodePkd = function() {
              var selectedPkd = [];
              angular.forEach($scope.pkdObjects, function(pkd) {
                selectedPkd.push(pkd.code);
              });
              return selectedPkd;
            };

            $scope.init();
          }
        ]
      };
    }
  ]);