angular.module('ihestiaRestServicesBase')
   .factory('customerSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var Customer = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      var self = this;

      this.resource = 'customers';
      this.businessArea = 'customers';

      /**
       * Rejestrujemy token dostępowy do klienta, którego nie jesteśmy opiekunem
       *
       * @param  {Object} customerData {firstname: '..', lastName: '..', identityNumber: '..'}
       * @return {Promise}              obietnica angularowas
       */
      this.registerAccessToken = function(customerData) {
        return self.post('accessTokens', customerData, null, null, null, {allowedStatuses: [404]});
      };


      this.getAccountInfo = function(customerId, callback, errorCallback) {
        return self.get(customerId, null, 'account', callback, errorCallback);
      };

    };

    return new Customer();
  }])
  ;