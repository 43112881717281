/**
 * Typy charakterów - kody i słownik.
 */
angular.module('ihestiaSsoBase.constants')
  .provider('UserCharacterTypeConstants', [function() {

    var UserCharacterTypeConstants = {};

    UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT = 'Client';
    UserCharacterTypeConstants.CHARACTER_TYPE_WORKER = 'Worker';
    UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER = 'ProContactWorker';
    UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE = 'SalesProfile';
    UserCharacterTypeConstants.CHARACTER_TYPE_MANAGER = 'Manager';
    UserCharacterTypeConstants.CHARACTER_TYPE_BRANCH = 'Branch';
    UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL = 'Central';
    UserCharacterTypeConstants.CHARACTER_TYPE_DIRECT_SALE = 'DirectSale';
    UserCharacterTypeConstants.CHARACTER_TYPE_PARTNER = 'Partner';

    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE = {};
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT] = 'Klient';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_WORKER] = 'Pracownik';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER] = 'Agencja centralna';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE] = 'Profil sprzedażowy';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_MANAGER] = 'Menedżer sprzedaży';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_BRANCH] = 'Przedstawicielstwo';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL] = 'Centrala';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_DIRECT_SALE] = 'Sprzedaż bezpośrednia';
    UserCharacterTypeConstants.DICTIONARY_CHARACTER_TYPE[UserCharacterTypeConstants.CHARACTER_TYPE_PARTNER] = 'Partner';

    /**
     * Zwraca stałe dla sekcji .config itd.
     * @return {Object} UserCharacterTypeConstants
     */
    this.getConstants = function() {
      return UserCharacterTypeConstants;
    };

    /**
     * Magic get, zwraca stałe
     * @return {Function}
     */
    this.$get = [function() {
      return UserCharacterTypeConstants;
    }];
  }]);
