angular.module('ihestiaIkomunikator.fullListPanel')
  .service('ihestiaIkomunikatorFullListPanelCentralHelper', ['iKomunikatorConstants', '$rootScope', 'iKomunikatorMessageThreadRepresentersSvc',
    'ihestiaIkomunikatorSearchMessagesCentralHelper', 'ihestiaIkomunikatorAdvancedFilterHelper', '$timeout', 'iKomunikatorMessagesThreadSvc', 'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorNavigationHelper', 'triggerWhenInactive', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function(iKomunikatorConstants, $rootScope, iKomunikatorMessageThreadRepresentersSvc, ihestiaIkomunikatorSearchMessagesCentralHelper,
      ihestiaIkomunikatorAdvancedFilterHelper, $timeout, iKomunikatorMessagesThreadSvc, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorNavigationHelper, triggerWhenInactive, ihestiaIkomunikatorLoaderMessagesHelper) {

      var FullListPanelCentralHelper = function() {
        var self = this;

        this.defaultData = {
          searchFilters: {
            status: iKomunikatorConstants.STATUS_ALL,
            criteria: {},
            orderSpec: {
              direction: 'DESC',
              sortBy: 'dateFrom'
            },
            pageSpec: {
              pageNo: 0,
              pageSize: 20
            }
          },
          subjects: [],
          threads: [],
          loading: false,
          total: 0,
          cacheId: null,
          searchFor: null,
          showModalFilterOnInit: true,
          reloadSearchAgain: false,
          promiseFiter: {}
        };

        this.tplData = angular.copy(self.defaultData);

        this.init = function() {
          //jeśli wysłano parametry w metodzie get, to znaczy, że nie chcemy kolejny raz pokazac okna wyszukiwania
          var filterParamSended = ihestiaIkomunikatorAdvancedFilterHelper.initFilterFromUrl();
          self.tplData.showModalFilterOnInit = self.tplData.showModalFilterOnInit && !filterParamSended;

          if (self.tplData.showModalFilterOnInit) {
            $timeout(function() {
              ihestiaIkomunikatorSearchMessagesCentralHelper.showModal();
            }, 0);
          } else {
            self.reloadSearch();
          }

          self.setShowModalFilterOnInit(true);
        };

        /**
         * [initSearch inicjalne wyszukiwanie]
         * @return {[type]} [description]
         */
        this.initSearch = function() {
          self.tplData.loading = true;
          self.tplData.threads = [];
          $rootScope.$broadcast('showBlockUi');

          self.tplData.searchFilters.criteria = ihestiaIkomunikatorAdvancedFilterHelper.getFilter().getModelForRest();
          var data = angular.copy(self.tplData.searchFilters);

          if (data.criteria.dateFrom) {
            data.dateFrom = angular.copy(data.criteria.dateFrom);
          }
          if (data.criteria.dateTo) {
            data.dateTo = angular.copy(data.criteria.dateTo);
          }

          delete data.criteria.dateFrom;
          delete data.criteria.dateTo;

          iKomunikatorMessagesThreadSvc.findLoggedUserMessageThreads(data, function(result) {
            if (angular.isObject(result.data)) {
              ihestiaIkomunikatorLoaderMessagesHelper.setAnswerFromSearch(result.data, self.tplData);
              self.tplData.cacheId = result.data.resourceId;
            }

            self.tplData.loading = false;
            $rootScope.$broadcast('hideBlockUi');
          }, function() {
            self.tplData.loading = false;
            $rootScope.$broadcast('hideBlockUi');
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania wiadomości');
          });
        };

        /**
         * [reloadSearch wyszukaj na ustawieniach domyślnych - zgodnie z wybranymi parametrami wyszukiwania zaawansowanego]
         * @return {[type]} [description]
         */
        this.reloadSearch = function() {
          if (!self.tplData.loading) {
            self.tplData.loading = true;
            self.tplData.searchFilters.pageSpec.pageNo = 0;
            self.tplData.searchFor = null;
            self.tplData.threads = [];

            self.initSearch();
          }
        };

        /**
         * [getThreadById pobiera wątek na podstawie id]
         * @param  {[type]} threadId [id wątku]
         * @return {[IKomunikatorMessageThreadModel]}          [description]
         */
        this.getThreadById = function(threadId) {
          var result = null;
          if (angular.isArray(self.tplData.threads)) {
            angular.forEach(self.tplData.threads, function(thread) {
              if (thread.threadId === threadId) {
                result = thread;
              }
            });
          }

          return result;
        };

        /**
         * [filterSearch odpalane na wpisanie tekstu w filtrze po wyżej listy wątków]
         * @return {[type]} [description]
         */
        this.filterSearch = function() {
          if (!self.tplData.loading && self.tplData.cacheId !== null) {
            self.tplData.searchFilters.pageSpec.pageNo = 0;
            self.tplData.threads = [];
            triggerWhenInactive(500, self.tplData.promiseFiter, self.loadFromCache);
          }
        };

        /**
         * [loadFromCache funkcja wyszukuje wiadomości z cahce zgodnie z ustawionymi paramtrami wyszukiwanie]
         */
        this.loadFromCache = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.loadFromCache(self.tplData);
        };


        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };

        /**
         * [setShowModalFilterOnInit ustawia flage czy odpalić modal po wjesciu na strone]
         * @param {[type]} value [description]
         */
        this.setShowModalFilterOnInit = function(value) {
          self.tplData.showModalFilterOnInit = value;
        };

        $rootScope.$on('iKomunikator.reloadCentralFullListPanel', function() {
          self.reloadSearch();
        });

        $rootScope.$on('iKomunikator.setShowModalFilterOnInit', function($event, value) {
          self.setShowModalFilterOnInit(value);
        });

        /**
         * na zmiane stanu na inna podstrone niz historia/podglad szczegolow czyścimy filtr wyszukiwania zaawansowanego
         */
        $rootScope.$on('$stateChangeStart', function($event, toState, params, fromState) {
          if (angular.isObject(toState) && toState.name === ihestiaIkomunikatorNavigationHelper.fullListPanelState && angular.isObject(fromState) && fromState.name === ihestiaIkomunikatorNavigationHelper.detailsState) {
            self.setShowModalFilterOnInit(false);
          }
        });

        // ponieważ $stateChangeSuccess nie jest odpalany przy odświeżeniu strony, to robimy $watcha na stanie
        /**
         * na zmiane stanu na inna podstrone niz historia/podglad szczegolow czyścimy filtr wyszukiwania zaawansowanego
         */
        $rootScope.$watch('$state.$current', function(toState, params, fromState) {
          if (angular.isObject(toState) && toState.name === ihestiaIkomunikatorNavigationHelper.fullListPanelState && angular.isObject(fromState) && fromState.name === ihestiaIkomunikatorNavigationHelper.detailsState) {
            self.setShowModalFilterOnInit(false);
          }
        });

        self.init();
      };

      return new FullListPanelCentralHelper();
    }
  ]);