// policyDocumentsModalHelper
angular.module('ihestiaWidgets.policyDetails')
  .controller('policyDocumentsInfoModalCtrl', ['$scope', 'policyDocumentsModalHelper', 'iHestiaCommonModalHelper', 'PolicyHelper', '$filter', '$alert',
    function($scope, policyDocumentsModalHelper, iHestiaCommonModalHelper, PolicyHelper, $filter, $alert) {

      $scope.documentCode = policyDocumentsModalHelper.documentsType;
      if ($scope.documentCode === 'Policy') {
        $scope.info = $filter('translate')('policyDocuments.policyInfo', {componentCode: 'Public'});
      }
      if ($scope.documentCode === 'GreenCard') {
        $scope.info = $filter('translate')('policyDocuments.greenCardInfo', {componentCode: 'Public'});
      }
      $scope.policyHelper = new PolicyHelper({
        policyNumber: policyDocumentsModalHelper.policyNumber
      }, policyDocumentsModalHelper.policyId, policyDocumentsModalHelper.policyNumber);

      $scope.init = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaPolicyDocumentsInfo', function($event) {
          $scope.printDocument($event);
        });
      };
      /**
       * Pobranie żądanego pdf'a
       * @return {undefined}
       */
      $scope.printDocument = function($event) {
        $event.preventDefault();
        $scope.errros = [];
        $scope.$emit('showBlockUi', {
          label: $filter('translate')('downloadingDocument', {componentCode: 'Public'})
        });

        $scope.policyHelper.printDocument($scope.documentCode).then(function(res) {
          if (res.status === 200) {
            iHestiaCommonModalHelper.hideModal('ihestiaPolicyDocumentsInfo');
          } else if (res.status === 409) {
            if ($scope.documentCode === 'GreenCard') {
              $alert({
                content: $filter('translate')('unavailableGreenCard', {componentCode: 'Public'}),
                type: 'danger',
                duration: 20
              });
            } else {
              $scope.errros.push($filter('translate')('printIsNotAvailable', {componentCode: 'Public'}));
            }
          } else if (angular.isDefined(res.data) && angular.isArray(res.data.messages)) {
            _.forEach(res.data.messages, function(message) {
              if (message && message.text) {
                $scope.errros.push(message.text);
              }
            });
          } else {
            $scope.errros.push($filter('translate')('unknownServiceErrorHasOccured', {componentCode: 'Public'}));
          }
        }, angular.noop).finally(function() {
          $scope.$emit('hideBlockUi');
          policyDocumentsModalHelper.clearData();
        });
      };

      $scope.init();
    }]);
