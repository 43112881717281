angular.module('ihestiaSsoBase')
/**
 * obiekt który dostajemy na liście użytkowników, przykładowo w zarządzaniu użytkownikami
 */
.factory('UserResponseModel', [ 'IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var UserResponseModel = function()
    {
      this.objectName = 'UserResponse';

      this.fieldsProperties = {
        canRelog: {
          type: 'bool'
        },
        characterName: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        },
        login: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        salesProfileLife: {
          type: 'string'
        },
        salesProfileProperty: {
          type: 'string'
        },
        userType: {
          type: 'string'
        },
        inproperPesel: {
          type: 'bool'
        },
        phone: {
          type: 'string'
        },
        accountRetrieveMode: {
          type: 'string'
        },
        alias: {
          type: 'string'
        },
        locked: {
          type: 'string'
        }
      };

      this.canRelog = null; // bool
      this.characterName = null; // string
      this.email = null; // string
      this.firstName = null; // string
      this.lastName = null; // string
      this.login = null; // string
      this.pesel = null; // string
      this.salesProfileLife = null; // string
      this.salesProfileProperty = null; // string
      this.userType = null; // string
      this.inproperPesel = null; // bool
      this.phone = null; // string
      this.accountRetrieveMode = null; // string
      this.alias = null; // string
      this.locked = null; // string

    };


    UserResponseModel.prototype = IHestiaAbstractModel.prototype;

    return UserResponseModel;
  }]);