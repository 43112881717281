angular.module('ihestiaWidgets.eua.documentStatusDetails')
  .factory('euaDocumentStatusDetailsModalHelper', ['LsnModalHelper', '$q', 'ihestiaEuaDocumentsSvc', 'ihestiaEuaHelper', '$filter', 'blockUiHelper',
    function(LsnModalHelper, $q, ihestiaEuaDocumentsSvc, ihestiaEuaHelper, $filter, blockUiHelper) {

      var EuaDocumentStatusDetailsModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;

        /**
         * Rozszerzamy opcje modala
         * @type {String}
         */
        this.setOptions({
          templateUrl: 'ihestia-widgets-templates-app/eua/documentStatusDetailsModal/euaDocumentStatusDetailsModal.tpl.html',
          size: 'lg',
          withDefaultMargin: false,
          title: ['eua.statusDetails', {
            componentCode: 'Public'
          }],
          okBtnName: '',
          cancelBtnName: ['Public.close', {
            componentCode: 'Public'
          }]
        });

        var oldShowModal = self.showModal;
        this.showModal = function(modalData) {
          var thisFunc = this;
          return $q(function(resolve, reject) {
            blockUiHelper.showBlockUi();
            ihestiaEuaDocumentsSvc.get('agencyAgreements', null, modalData.euaDocument.uuid)
              .then(function(response) {
                // większośc przeniesiona z ci (uspójniamy ci i jupiter)
                modalData.statusDetails = response.data;
                modalData.statusDetails.statusDescription = ihestiaEuaHelper.getStatusDesc(response.data.status);
                modalData.statusDetails.additionalDate = '';
                if (['NEW', 'PENDING'].indexOf(modalData.statusDetails.status) !== -1 && modalData.statusDetails.finishDate) {
                  modalData.statusDetails.additionalDate = $filter('translate')('eua.statusAdditionalFinishDate', {
                    componentCode: 'Public',
                    params: {
                      date: $filter('date')(modalData.statusDetails.finishDate, 'yyyy-MM-dd')
                    }
                  });
                }
                angular.forEach(modalData.statusDetails.documentDecisionList, function(o, i) {
                  if (modalData.statusDetails.autoAccepted && o.status === 'NEW') {
                    modalData.statusDetails.documentDecisionList[i].status = 'ACCEPTED_AUTOMATICALLY';
                  }
                  if (modalData.statusDetails.status === 'REJECTED' && o.status === 'NEW') {
                    modalData.statusDetails.documentDecisionList[i].status = 'NO_DECISION';
                  }
                  modalData.statusDetails.documentDecisionList[i].statusDescription = ihestiaEuaHelper.getStatusDesc(modalData.statusDetails.documentDecisionList[i].status);
                });

                // pokazujemy modal
                oldShowModal.apply(thisFunc, [modalData]).then(resolve, reject);
              }, reject).finally(function(){
                blockUiHelper.hideBlockUi();
              });
          });
        };

      };

      return new EuaDocumentStatusDetailsModalHelper();
    }]);