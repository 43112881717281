angular.module('ihestiaWidgets.policyOperations.common')
.factory('VerificationDataModelConstants', [function() {
    var VerificationDataModelConstants = {};

    VerificationDataModelConstants.VEHICLE_SALE = 'VehicleSale';
    VerificationDataModelConstants.VEHICLE_PURCHASE = 'VehiclePurchase';
    VerificationDataModelConstants.VEHICLE_DONATION = 'VehicleDonation';

    //słownik typów operacji
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE = {};
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE[VerificationDataModelConstants.VEHICLE_SALE] = 'Sprzedaż pojazdu';
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE[VerificationDataModelConstants.VEHICLE_PURCHASE] = 'Zakup pojazdu';
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE[VerificationDataModelConstants.VEHICLE_DONATION] = 'Darowizna pojazdu';

    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL = {};
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[VerificationDataModelConstants.VEHICLE_SALE] = ['vehicleSale', {componentCode: 'PolicyOperations'}];
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[VerificationDataModelConstants.VEHICLE_PURCHASE] = ['vehiclePurchase', {componentCode: 'PolicyOperations'}];
    VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[VerificationDataModelConstants.VEHICLE_DONATION] = ['vehicleDonation', {componentCode: 'PolicyOperations'}];

    return VerificationDataModelConstants;
  }])
.factory('VerificationDataModel', [ 'IHestiaAbstractModel', 'VerificationDataModelConstants',
 function(IHestiaAbstractModel, VerificationDataModelConstants) {

    var VerificationDataModel = function()
    {
      this.objectName = 'VerificationData';

      this.fieldsProperties = {
        operationDate: {
          type: 'dateTime'
        },
        operationType: {
          type: 'string',
          dictionary: VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE
        },
        policyNumber: {
          type: 'string'
        },
        vehicleRegistrationNumber: {
          type: 'string'
        }
      };

      this.operationDate = null; //Data operacji
      this.operationType = null; //Typ operacji   VehicleSale/VehiclePurchase/VehicleDonation
      this.policyNumber = null;  //numer polisy
      this.vehicleRegistrationNumber = null; //nr rejestracyjny pojazdu
    };

    VerificationDataModel.prototype = IHestiaAbstractModel.prototype;

    return VerificationDataModel;
  }]);