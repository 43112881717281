/**
* Dyrektywa do wrzucenia szablonu navbara
* @param  {[type]}  [description]
* @return {[type]}  [description]
*/
angular.module('ihestiaMenuBase')
	.directive('menuIcContext', [
		function() {
			return {
				restrict: 'E',
				templateUrl: 'ihestia-menu-template-app/icContext/menuIcContext.tpl.html'
			};
		}
	]);