angular.module('ihestiaWidgets.policyDetails')
  .factory('LsnCommDataModelConstants', [function() {
    var CONSTANTS = {};
    CONSTANTS.CONTACT_TYPE_INT = 'INT';
    CONSTANTS.CONTACT_TYPE_TEL = 'TEL';

    CONSTANTS.DICTIONARY_CONTACT_TYPE = {};
    CONSTANTS.DICTIONARY_CONTACT_TYPE[CONSTANTS.CONTACT_TYPE_INT] = 'E-MAIL';
    CONSTANTS.DICTIONARY_CONTACT_TYPE[CONSTANTS.CONTACT_TYPE_TEL] = 'TEL. KOMÓRKOWY';
    return CONSTANTS;
  }
  ])
  .factory('CommDataModel', ['LsnAbstractModel', 'LsnCommDataModelConstants',
    function(LsnAbstractModel, LsnCommDataModelConstants) {
      var CommDataModel = function() {
        this.objectName = 'CommData';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          contactType: {
            type: 'string',
            dictionary: LsnCommDataModelConstants.DICTIONARY_CONTACT_TYPE
          },
          contactValue: {
            type: 'string'
          },
          description: {
            type: 'string'
          },
          defaultContact: {
            type: 'bool'
          }
        };
      };
      CommDataModel.prototype = LsnAbstractModel.prototype;
      return CommDataModel;
    }
  ]);
