angular.module('lsnBase.models')
.factory('LsnAddressModelV1', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAddressModelConstants',
  function(LsnAbstractModel, lsnModelFactory, LsnAddressModelConstants) {

    var LsnAddressModelV1 = function()
    {
      this.objectName = 'Address';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;

      this.fieldsProperties = {
        metaData: {
          type: 'MetaData'
        },
        code: {
          type: 'string',
          defaultValue: LsnAddressModelConstants.ADDRESS_TYPE_STAL,
          dictionary: LsnAddressModelConstants.DICTIONARY_ADDRESS_TYPE
        },
        postalCode: {
          type: 'string'
        },
        post: {
          type: 'string'
        },
        postBox: {
          type: 'string'
        },
        city: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        streetPrefix: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        street: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        house: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        apartment: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        region: {
          type: 'string'
        },
        district: {
          type: 'string'
        },
        commune: {
          type: 'string'
        },
        countryCode: {
          type: 'string',
          defaultValue: 'PL'
        }
      };

      this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
      this.code = null; // string, stal/kore/tymc/dzia (stały (Dla tego typu nie trzeba uzupełniać pola)/korespondencyjny/tymczasowy/adr. działalności)
      this.postalCode = null; // string, kod pocztowy
      this.post = null; // string, Poczta. Pole ma być niewypełniane jeśli jest tożsame z city
      this.postBox = null; // string, Skrytka pocztowa. Jeśli klient nie posiada skrytki pocztowej nie należy prze-syłać żadnych danych
      this.city = null; // string, miejscowość
      this.streetPrefix = null; // string, przedrostek ulicy
      this.street = null; // string, ulica
      this.house = null; // string, numer domu
      this.apartment = null; // string, numer mieszkania
      this.region = null; // string, województwo
      this.district = null; // string, powiat
      this.commune = null; // string, gmina
      this.countryCode = null; // string, Kod kraju (2 znaki). Wartość domyśl-na: PL. Pole powinno być wypełniane tylko jeśli wartość jest inna.

    };

    LsnAddressModelV1.prototype = LsnAbstractModel.prototype;

    return LsnAddressModelV1;
  }]);