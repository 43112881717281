angular.module('salesPath2')
  .controller('personSearchModalCtrl', ['$scope', 'actionHelper', 'CONFIG', 'personModalHelper', 'sp2CommonHelper', '$timeout', 'personHelper',
    function($scope, actionHelper, CONFIG, personModalHelper, sp2CommonHelper, $timeout, personHelper) {

      $scope.temp = {
        search: ''
      };
      $scope.init = function() {
        $scope.params = personModalHelper.searchModalParams;
      };

      $scope.onSelect = function($item) {
        personHelper.loadPerson($item.person.metaData.id, $item.person,
          function(personData) {
            $scope.personLoaded(personData, $scope.params.context);
          });
        $scope.temp.search = $item.person.firstName + ' ' + $item.person.lastName + ' ' + $item.person.pesel;
      };

      $scope.personLoaded = function(personData, context) {
        if (personModalHelper.searchModalParams.context === context) {
          personHelper.save(personData, context, null, (($scope.params.personChange && angular.isString($scope.params.sourcePersonId)) ? $scope.params.sourcePersonId : null), $scope.params.personChangeRole);
          personModalHelper.clearSearchModalData();
          $timeout(function() {
            // be timeoutu są dziwne błędy z $$nextSibling
            sp2CommonHelper.hideModal('personSearchModal');
          }, 0);
        }
      };

      $scope.$on('iHestiaCommonModalCancelBtnClicked.personSearchModal', function() {
        $timeout(function() {
          actionHelper.runAction('cancelSearchPerson', {
            context: $scope.params.context
          });

          personModalHelper.clearSearchModalData();
        }, 0);
      });

      $scope.init();
    }
  ]);