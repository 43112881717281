angular.module('lsnBase.validators')
        .directive('clearInvalidTimeOnBlur', function() {
          return {
            restrict: 'A',
            require: 'ngModel',
            priority: 1000,
            link: function(scope, element, attrs, modelCtrl) {

              /**
               * czyści godzinę gdy wartość nie jest poprawna
               * @param {String} inputValue
               * @returns {String}
               */
              var clearIfInvalid = function(inputValue) {
                if(inputValue) {
                  var values = inputValue.split(':'),
                    hour = parseInt(values[0], 10),
                    minutes = parseInt(values[1], 10);

                  if(hour > 23 || hour < 0) {
                    inputValue = '';
                  }

                  if(minutes > 59 || minutes < 0) {
                    inputValue = '';
                  }

                  element.val(inputValue);
                  modelCtrl.$setViewValue(inputValue);
                }
              };

              element.on('blur', function() {
                clearIfInvalid(element.val());
              });

              scope.$on('$destroy', function(){
                element.off('blur');
              });

              clearIfInvalid(scope[attrs.ngModel]);
            }
          };
        });