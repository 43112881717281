angular.module('ihestiaCommonDirectives')
  /**
   * wywoluje callback
   * @param {int} czas nieaktywnosci
   * @param {obj} obj referencja - miejsce przetrzymywania promise
   * @param {function} callback - funkcja wywolywana po nie aktywnosci
   */
  .factory('triggerWhenInactive', ['$timeout', function($timeout) {
    var triggerWhenInactive = function(timeout, obj, callback) {
      if (angular.isObject(obj) && obj.promise) {
        $timeout.cancel(obj.promise);
      }

      obj.promise = $timeout(function() {
        callback();
      }, timeout);
    };
    return triggerWhenInactive;
  }]);