angular.module('ihestiaCommonDirectives')
  .directive('commonTagListInput', [
    function() {
      return {
        restrict: 'A',
        scope: {
          ngModel: '=',
          tagsModel: '=',
          tagsDelimiters: '=',
          tagsApi : '=',
          itemParser: '='
        },
        link: function($scope, $element) {

          $scope.delimiters = [',', '.'];
          if(angular.isArray($scope.tagsDelimiters))
          {
            $scope.delimiters = $scope.tagsDelimiters;
          }

          /**
           * Dodawanie taga do listy
           */
          $scope.addTag = function() {
            var tagsArray = $scope.tagsModel;
            var inputValue = $element.val();
            var splitRegex = new RegExp('[' + $scope.delimiters.join() + ']'); // domyślnie mamy regułę /[,.]/
            angular.forEach(inputValue.split(splitRegex), function(tag){
              tag = tag.trim();
              if(angular.isFunction($scope.itemParser)) {
                tag = $scope.itemParser(tag);
              }
              if(tag !== '' && tagsArray.indexOf(tag) === -1) {
                tagsArray.push(tag);
              }
            });

            if(angular.isDefined($scope.ngModel))
            {
              $scope.ngModel = '';
            }
            else
            {
              $element.val('');
            }
          };

          // Umożliwiamy użycie zewnętrznej metody do dodania tagu
          $scope.$on('addTag', $scope.addTag);

          // również bez oczekiwania na event
          if($scope.tagsApi && angular.isObject($scope.tagsApi))
          {
            $scope.tagsApi.addTag = $scope.addTag;
          }

          /**
           * Dzięki temu możemy dodawać tagi do listy
           */
          $element.on('keyup', function(e) {
            var modificatorKeys = ['ctrlKey', 'shiftKey', 'altKey'],
              delimiterKeyPressed = false;

            // czy naciśnięto delimiter (enter, przecinek, kropka)
            if (e.keyCode === 13) {
              // enter
              delimiterKeyPressed = true;
            } else {
              // inne klawisze (,.)
              if($scope.delimiters.indexOf(e.key) !== -1)
              {
                delimiterKeyPressed = true;
              }

              // jesli jednak byl modyfikator, to wcale nie wcisniety
              angular.forEach(modificatorKeys, function(modificator) {
                if (e[modificator]) {
                  delimiterKeyPressed = false;
                }
              });
            }

            // jesli wcisnieto delimiter
            if (delimiterKeyPressed) {
              $scope.addTag();
            }

            // refresh scope'a
            if (!$scope.$$phase) {
              $scope.$apply();
            }
          });

          $scope.$on('$destroy', function() {
            $element.off('keyup');
          });

        }
      };
    }]);