angular.module('salesPath2')
  .service('communicationHelper', ['RESOURCES', 'CONFIG', 'sp2SelectionHelper', 'mainDataContainer', 'CONSTANTS', 'addHelper', 'renewalHelper', 'actionHelper', 'dataContainerHelper', 'appVariables', 'errorHelper',
    function(RESOURCES, CONFIG, selectionHelper, mainDataContainer, CONSTANTS, addHelper, renewalHelper, actionHelper, dataContainerHelper, appVariables, errorHelper) { // eslint-disable-line angular/di
      var CommunicationHelper = function() {
        var self = this;

        //lista produktów komunikacyjnych
        this.communicationProductList = [];
        this.activeProductList = [];

        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          productList: [],
          productIconList: [],
          selectedVariants: {}, //zaznaczone warianty
          previouslySelectedVariants: {}, //historycznie (np. z poprzeniej polisy w dokupieniach) wybrane warianty proukctów
          historicalDisabledVariants: {}, //historycznie (np. z poprzeniej polisy w dokupieniach) wyłączone warianty ryzyk
          numbersToShow: {}, //przechowuje numery obiektów, dla których wybrano dany wariant
          prodPremiums: {},
          zkPremium: 0,
          isSingleProduct: CONFIG.BEHAVIOR.isSingleProduct,
          greenCardAsProd: CONFIG.BEHAVIOR.greenCardAsProd,
          greenCardSelected: false,
          greenCardNumber: '',
          renewalMarks: {}, //informacje o wznowieniach
          errors: [] //lista glownych bledow
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
            case 'personAdded':
            case 'personGroupSelected':
            case 'vehicleSelected':
            case 'vehicleDeleted':
            case 'vehicleEdited':
            case 'vehicleAdded':
              self.refreshAllData();
              break;
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.refreshRenewal();
              self.refreshErrors();
              break;
            case 'languageChanged.beforeStateReload':
              self.setCommunicationProductList();
              self.refreshAllData();
              break;
            default:
              break;
          }
        };

        /**
         * to co ma się zadziać przy instancjonowaniu tego helpera
         * @return {[type]} [description]
         */
        this.init = function() {
          self.setCommunicationProductList();
        };

        this.refreshErrors = function() {
          self.tplData.errors = errorHelper.getMainErrorForPage(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
        };

        /**
         * ustawia liste prodiktow komunikacyjnych
         */
        this.setCommunicationProductList = function() {
          self.communicationProductList = [];
          angular.forEach(RESOURCES.PRODUCTLIST, function(product) {
            if (product.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
              self.communicationProductList.push(product);
              self.activeProductList.push(product.CODE);
            }
          });
        };

        /**
         * aktualizuje wszystkie dane z self.tplData
         */
        this.refreshAllData = function() {
          self.tplData.productList = self.communicationProductList;
          self.tplData.productIconList = self.communicationProductList;
          self.tplData.selectedVariants = selectionHelper.getSelectedVariants();
          self.tplData.previouslySelectedVariants = selectionHelper.getPreviouslySelectedVariants();
          self.tplData.historicalDisabledVariants = selectionHelper.getHistoricalDisabledVariants();
          //numery wybranych obiektów
          self.tplData.numbersToShow = {};
          angular.forEach(self.tplData.selectedVariants, function(variants, product) {
            self.tplData.numbersToShow[product] = {};
            if (angular.isObject(variants)) {
              angular.forEach(variants, function(selected, variant) {
                if (angular.isArray(selected)) {
                  self.tplData.numbersToShow[product][variant] = selected;
                }
              });
            }
          });

          self.refreshGreenCardData();
          self.refreshPremiums();
          self.refreshRenewal();
        };

        /**
         * aktualizuje dane zielonej karty
         * @return {Boolean} false gdy niezaktualizowano
         */
        this.refreshGreenCardData = function() {
          if (!CONFIG.BEHAVIOR.greenCardAsProd) {
            return false;
          }
          self.tplData.greenCardNumber = mainDataContainer.greenCardNumber;
          self.tplData.greenCardSelected = addHelper.isAddSelected(CONSTANTS.ADD_GREENCARD);
          return true;
        };

        /**
         * odswieża znaczniki wznowień
         */
        this.refreshRenewal = function() {
          self.tplData.renewalMarks = {};
          angular.forEach(renewalHelper.getProductsMarks(), function(variants, productCode) {
            if (angular.isObject(variants)) {
              angular.forEach(variants, function(val, variant) {
                if (angular.isUndefined(self.tplData.renewalMarks[productCode])) {
                  self.tplData.renewalMarks[productCode] = {};
                }
                self.tplData.renewalMarks[productCode][variant] = true;
              });
            } else {
              if (angular.isUndefined(self.tplData.renewalMarks[productCode])) {
                self.tplData.renewalMarks[productCode] = {};
              }
              self.tplData.renewalMarks[productCode][variants] = true;
            }
          });
        };

        /**
         * aktualizuje składki
         */
        this.refreshPremiums = function() {
          var premiumContainer = 'premiumList';
          self.tplData.prodPremiums = dataContainerHelper.getPremiums(premiumContainer);

          //zielona karta
          if (CONFIG.BEHAVIOR.greenCardAsProd && addHelper.isAddSelected(CONSTANTS.ADD_GREENCARD)) {
            self.tplData.zkPremium = dataContainerHelper.getAddPremium(CONSTANTS.ADD_GREENCARD, null, mainDataContainer.selectedVehicle, premiumContainer);
          }
        };

        /*
        * zapis zielonej karty
        */
        this.saveZk = function(number) {
          mainDataContainer.greenCardNumber = number;
          addHelper.addAdd(CONSTANTS.ADD_GREENCARD);
          actionHelper.runAction('tarifficate');
          self.refreshGreenCardData();
        };

        this.init();
      };

      return new CommunicationHelper();
    }
  ])
  .run(['communicationHelper', 'actionHelper',
    function(communicationHelper, actionHelper) {
      actionHelper.registerHelper('communicationHelper', communicationHelper);
    }
  ]);