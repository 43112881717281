angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesRisksModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesRisksModelV1 = function() {
        this.objectName = 'ApplicationAllowedChangesRisks';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          newRisks: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          },
          existingRisks: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          }
        };

        this.newRisks = null; // ryzyka nowe
        this.existingRisks = null; // ryzyka już istniejące
      };

      LsnApplicationAllowedChangesRisksModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesRisksModelV1;
    }
  ]);