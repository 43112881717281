angular.module('salesPath2', [
  'lsnBase',
  'lsnBase.object',
  'lsnNg',
  'lsn-base-templates-app',
  'templates-app',
  'templates-common',
  'ui.router',
  'salesPath2.params',
  'salesPath2.loader', //wczytywanie akcji z URLa
  'salesPath2.spd', //do rejestrowania i pobierania SPD
  'salesPath2Template',
  'ab-base64',
  'ihestiaSsoBase',
  'ihestiaRestServicesBase',
  'ngAnimate',
  'mgcrea.ngStrap.popover',
  'ihestiaMenuBase',
  'ihestiaMenuLeftNavbar',
  'ihestiaMenuUserBox',
  'ihestiaAg',
  'ihestiaSsoBase.baseInfo',
  'ihestiaCrossTab',
  'ihestiaCommonDirectives',
  'ihestiaCommonDirectives.errorHandler',
  'ihestiaCommonDirectives.validators',
  'ihestiaCommonDirectives.bsScrollSpy',
  'ihestiaCommonDirectives.blockUi',
  'ihestiaCommonDirectives.upperCase',
  'ihestiaCommonDirectives.changeLanguage',
  'ihestiaIkomunikator',
  'ihestiaMenuHelp',
  'ihestiaBase',
  'ihestiaWidgets.extraLife'
])
  .provider('ConfigResource', ['sp2ParamsProvider', 'sp2LoaderProvider', '$stateProvider', '$urlRouterProvider', 'lsnDeepExtendProvider', 'ihestiaConfigHelperProvider', 'ihestiaLabelHelperProvider', 'lsnDateTimeHelperProvider',
    function(sp2ParamsProvider, sp2LoaderProvider, $stateProvider, $urlRouterProvider, lsnDeepExtendProvider, ihestiaConfigHelperProvider, ihestiaLabelHelperProvider, lsnDateTimeHelperProvider) {
      var self = this;

      //domyslna config data
      var DEFAULT_DATA = sp2ParamsProvider.getParams();

      /**
       * zbiór konfiguracji
       * @type {Object.<string, Object>} klucz to data obowiązywania wersji (versionDate), wartość to cały obiekt z konfiguracją
       */
      this.registeredConfigs = {};

      /**
       * aktywan surowa konfiguracja
       * @type {Object}
       */
      this.activeRawConfig = null;

      /**
       * przetworzona aktywna konfiguracja
       * @type {Object}
       */
      this.activeConfig = {};

      /**
       * rejestracja nowego configa
       * @param  {[type]} configData [description]
       */
      this.registerConfig = function(configData, activate) {
        var newCfgData = lsnDeepExtendProvider.deepExtend({}, DEFAULT_DATA, configData);
        self.registeredConfigs[newCfgData.METADATA.versionDate] = newCfgData;
        if (angular.isUndefined(activate)) {
          activate = true;
        }
        if (activate) {
          self.activateConfig();
        }
      };

      /**
       * Mamy już wszystkie konfigi i wczytujemy ten co trzeba
       */
      this.activateConfig = function() {
        self.activateRawConfig();
        self.refreshConfig();
      };

      /**
       * zwraca docelową wersję konfiguracji na podstawie przekazanej daty
       * @param  {String} type 'date' lub 'version', gdzie data to data wh której sprawdzać, version to wersja (czyli data w formacie yyyymmdd)
       * @param  {String} value wartość
       * @return {String} docelowa wersja
       */
      this.getTargetVersionBy = function(type, value) {
        var sortedVersions = Object.keys(self.registeredConfigs).sort(),
          targetVersion = null,
          dateVersion = (type === 'version') ? value : new XDate(value).toString('yyyyMMdd');
        for (var i = sortedVersions.length - 1; i >= 0; i -= 1) {
          if (dateVersion >= sortedVersions[i]) {
            targetVersion = sortedVersions[i];
            break;
          }
        }
        return targetVersion;
      };

      /**
       * przetwarza "surowy" config (m.in. tłumaczy labelki do aktualnego języka)
       * @param  {Object} rawCfg surowy config (zarejestrowany przez aplikację)
       * @return {Object} przetworzony config
       */
      this.processRawConfig = function(rawCfg) {
        var cfg = ihestiaLabelHelperProvider.getLabelHelper().transObject(JSON.parse(JSON.stringify(rawCfg)), {noSceSet: true}); //tlumaczymy konfiguracje na aktulany jezyk
        return cfg;
      };

      /**
       * aktywacja konfiguracji na podstawie aktualnego czasu lub parametrów uruchomienia ścieżki
       */
      this.activateRawConfig = function() {
        if (angular.equals(self.registeredConfigs, {})) {
          throw new Error('Brak konfiguracji dla modułu salesPath2.');
        }
        var todayVersion = new XDate(lsnDateTimeHelperProvider.getDateTimeHelper().getDate()).toString('yyyyMMdd'),
          version = sp2LoaderProvider.currentActionParams.version || todayVersion,
          targetVersion = self.getTargetVersionBy('version', version);
        self.activeRawConfig = self.registeredConfigs[targetVersion];
        //stany
        $urlRouterProvider.when('', '/init');
        $urlRouterProvider.otherwise(function($injector) {
          var loaderHelper = $injector.get('sp2LoaderHelper'),
            config = $injector.get('CONFIG');
          if (!loaderHelper.appLoaded) {
            return '/init';
          }
          return config.NAVIGATION.otherwiseUrl;
        });
        $stateProvider
          .state('init', {
            url: '/init?action&applicationId&policyId&policyNumber&vehicleId&personId&offerId&product&estateType&personData&risks&adds&clientId&policySymbol&insuranceOption&version',
            views: {
              main: {
                template: '<ui-view/>',
                controller: 'initCtrl'
              }
            }
          });
        $stateProvider
          .state('persons', {
            url: '/persons',
            views: {
              main: {
                templateUrl: 'salesPath2Template/pages/persons/persons.tpl.html',
                controller: 'personsCtrl'
              }
            }
          });
        if (self.activeRawConfig.MODULES.matrix) {
          $stateProvider
            .state('matrix', {
              url: '/matrix',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/matrix/matrix.tpl.html',
                  controller: 'matrixCtrl'
                }
              }
            });
        }
        if (self.activeRawConfig.MODULES.oczp) {
          $stateProvider
            .state('oczp', {
              url: '/oczp',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/oczp/oczp.tpl.html',
                  controller: 'oczpCtrl'
                }
              }
            });
        }
        if (self.activeRawConfig.MODULES.nnw) {
          $stateProvider
            .state('nnw', {
              url: '/nnw',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/nnw/nnw.tpl.html',
                  controller: 'nnwCtrl'
                }
              }
            });
        }
        if (self.activeRawConfig.MODULES.fire) {
          $stateProvider
            .state('fire', {
              url: '/fire',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/fireBurglary/fireBurglary.tpl.html',
                  controller: 'fireBurglaryCtrl'
                }
              }
            });
        }
        if (self.activeRawConfig.MODULES.burglary) {
          $stateProvider
            .state('burglary', {
              url: '/burglary',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/fireBurglary/fireBurglary.tpl.html',
                  controller: 'fireBurglaryCtrl'
                }
              }
            });
        }
        if (self.activeRawConfig.MODULES.communication) {
          $stateProvider
            .state('vehicleMatrix', {
              url: '/communication',
              views: {
                main: {
                  templateUrl: 'salesPath2Template/pages/communication/communication.tpl.html',
                  controller: 'communicationCtrl'
                }
              }
            });
        }
        $stateProvider
          .state('summary', {
            url: '/summary',
            views: {
              main: {
                templateUrl: 'salesPath2Template/pages/summary/summary.tpl.html',
                controller: 'summaryCtrl'
              }
            }
          });

        $stateProvider.state('productCompare', {
          url: '/productCompare',
          views: {
            main: {
              templateUrl: 'salesPath2Template/pages/productCompare/productCompare.tpl.html',
              controller: 'productCompareCtrl'
            }
          }
        });
      };

      /**
       * czy aktywna wersja configa
       * @param  {Boolean}
       */
      this.isActiveConfigVersion = function(version) {
        if (angular.isString(sp2LoaderProvider.currentActionParams.version) && version === sp2LoaderProvider.currentActionParams.version) {
          return true;
        }
        return false;
      };

      /**
       * zwraca konfigurację sprzedażówki
       * @return {Object}
       */
      this.getConfig = function() {
        if (angular.equals(self.activeConfig, {})) { //przy pierwszym pobraniu aktywujemy konfigurację na podstawie wersji aplikacji
          self.activateConfig();
        }
        return self.activeConfig;
      };

      /**
       * odświeża aktulaną konfigurację
       */
      this.refreshConfig = function() {
        lsnDeepExtendProvider.deepExtend(self.activeConfig, self.processRawConfig(self.activeRawConfig || DEFAULT_DATA));
      };


      this.$get = [function() {
        var CfgRes = function() {
          this.refreshConfig = function() {
            return self.refreshConfig();
          };
          this.getConfig = function() {
            return self.getConfig();
          };
          this.getResources = function() {
            return self.activeConfig.RESOURCES;
          };
          this.getTargetVersionBy = self.getTargetVersionBy;
        };
        return new CfgRes();
      }];

    }
  ])
  .provider('CONFIG', [
    function() {
      this.$get = ['ConfigResource', function(ConfigResource) {
        return ConfigResource.getConfig();
      }];
    }
  ])
  .config(['lsnDateTimeHelperProvider',
    function(lsnDateTimeHelperProvider) {
      lsnDateTimeHelperProvider.env = 'www';
    }])
  .run(['mainDataContainer', '$rootScope', 'CONSTANTS', 'CONFIG', 'PAGE_CONFIG', 'RESOURCES', 'appVariables', '$state', '$timeout', '$window', 'blockUiHelper', 'sp2Loader', 'ihestiaConfigHelper', 'availabilityHelper', 'labelActionHelper', 'actionHelper', 'ConfigResource', 'ResourcesBuilder', 'resourceHelper', 'dataContainerHelper', 'sp2CommonHelper',
    function(mainDataContainer, $rootScope, CONSTANTS, CONFIG, PAGE_CONFIG, RESOURCES, appVariables, $state, $timeout, $window, blockUiHelper, sp2Loader, ihestiaConfigHelper, availabilityHelper, labelActionHelper, actionHelper, ConfigResource, ResourcesBuilder, resourceHelper, dataContainerHelper, sp2CommonHelper) {
      //dostępnpść serwisów
      // rozwiązanie tymczasowe. Należy przejść na ihestiaAvailabilityHelper i przenieść go salesPathOuter do commonDirectives
      var servicesAvailabilityConfig = ihestiaConfigHelper.get('availability', 'SERVICES');

      if (angular.isArray(servicesAvailabilityConfig)) {
        availabilityHelper.loadServicesAvailabilities(servicesAvailabilityConfig);
      }

      $rootScope.dataContainer = mainDataContainer;
      $rootScope.dataContainerHelper = dataContainerHelper;
      $rootScope.CONSTANTS = CONSTANTS;
      $rootScope.CONFIG = CONFIG;
      $rootScope.PAGE_CONFIG = PAGE_CONFIG;
      $rootScope.RESOURCES = RESOURCES;
      $rootScope.appVariables = appVariables;
      $rootScope.modals = {};
      $rootScope.canRender = true; //czy mozna renderowac aplikacje
      sp2Loader.fireListeners();
      sp2CommonHelper.setAllowedGeneralAgreements();

      labelActionHelper.registerAction('sp2main', function() {
        ConfigResource.refreshConfig();
        ResourcesBuilder.refreshResources();
        resourceHelper.refreshProducts();
        actionHelper.runAction('languageChanged.beforeStateReload');
        $state.go($state.current, {}, {
          reload: true
        });
        actionHelper.runAction('languageChanged.afterStateReload');
        actionHelper.runAction('tarifficate'); //przeladowanie np. komunikatów
      });

      // wstecz
      $rootScope.goBack = function() {
        $window.history.back();
      };
    }
  ]);