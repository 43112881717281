angular.module('salesPath2')
  .controller('vehicleCompareModalCtrl', ['$scope', 'vehicleHelper', 'vehicleServiceHelper', 'vehicleModalHelper', 'vehicleCepHelper',
    function($scope, vehicleHelper, vehicleServiceHelper, vehicleModalHelper, vehicleCepHelper) {

      $scope.compareData = [];
      $scope.vehicleIds = vehicleModalHelper.modals.vehicleCompareModal.vehicleIds;
      $scope.searchModalParams = vehicleHelper.searchModalParams;
      $scope.compareInProgress = false;

      /**
       * Init
       */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.loadComparison();
      };

      /**
       * Ładujemy dane do porównania
       */
      $scope.loadComparison = function() {
        $scope.compareInProgress = true;
        vehicleServiceHelper.getEquipmentService().post(null, {
          pageSize: 100,
          parameter: {
            id: $scope.vehicleIds,
            onlyDifferences: false,
            registrationDate: null
          },
          sortBy: 'id',
          sortDirection: 'ASC',
          top: 0
        }, 'searches', function(res) {
          $scope.compareInProgress = false;
          $scope.compareData = res.data.item;
        });
      };

      $scope.fireListeners = function() {
        // 'wróć' na modalu edycji pojazdu
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleCompareModal', function() {
          vehicleModalHelper.compareVehicleCancel();
        });
      };

      /**
       * Wybieramy pojazd
       * @param  {int} etxId [description]
       */
      $scope.chooseVehicle = function(etxId) {
        vehicleHelper.loadVehicle(etxId, function(vehicleModel) {
          vehicleModel.set('productionYear', $scope.searchModalParams.searchParams.productionYear);
          vehicleCepHelper._setCepDataForVehicle(vehicleModel);
          vehicleModel.metaData.set('id', null);
          if (angular.isDefined($scope.dataContainer.vehicles[$scope.searchModalParams.replaceClientId])) {
            var oldVehicle = $scope.dataContainer.vehicles[$scope.searchModalParams.replaceClientId];
            //przepisujemy czesc additionalData z aktualnego pojazdu
            angular.forEach(vehicleHelper.additionalDataToRewrite, function(attr) {
              vehicleModel.setAdditionalData(oldVehicle.getAdditionalData(attr), attr);
            });
          }
          vehicleModalHelper.compareVehicleChoose(vehicleModel, $scope.searchModalParams.replaceClientId);
        });
      };


      $scope.init();

    }
  ]);
