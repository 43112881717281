angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskSubjectModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper', '$timeout', 'iHestiaCommonModalHelper', 'ihestiaDictionaryHelper', 'IhestiaDashboardObjectModelConstants',
    function($scope, ihestiaDashboardNewTaskHelper, $timeout, iHestiaCommonModalHelper, ihestiaDictionaryHelper, IhestiaDashboardObjectModelConstants) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;
      $scope.objectModelConstants = IhestiaDashboardObjectModelConstants;
      $scope.subjects = [];
      $scope.segments = [];
      $scope.modalData = {
        selectedTopic: null,
        selectedSubtopic: null,
        segment: null,
        formValidated: false
      };
      $scope.loaded = {
        subjects: false
      };
      $scope.spinnerOptions = {
        label: '',
        size: ''
      };
      $scope.isLife = ihestiaDashboardNewTaskHelper.isLife();

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.loadDictionaries();

        // zatwierdzenie danych
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaDashboardNewTaskSubject', function($event) {
          $event.preventDefault();
          $scope.formSubmitted = true;
          if ($scope.createTaskForm && $scope.createTaskForm.$valid) {
            var definition = $scope.modalData.selectedTopic;

            if (definition) {
              $scope.taskData.title = definition.title;
              if (!definition.subtitle || definition.subtitle.length === 0) {
                $scope.taskData.subtitle = null;
              }
              $scope.taskData.taskDefinitionId = definition.id;
              $scope.taskData.taskDefinitionCode = definition.code;
              ihestiaDashboardNewTaskHelper.setObjectByDefinitionType(definition.objectType);
              if (angular.isDefined($scope.taskData.object.segment)) {
                $scope.taskData.object.segment = $scope.modalData.segment;
              }
              iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskSubject');
              $timeout(function() {
                ihestiaDashboardNewTaskHelper.showNextNewTaskModal();
              }, 0);
            }
          }
        });
      };

      /**
       * Ładujemy słowniki
       * @return {undefined}
       */
      $scope.loadDictionaries = function() {
        $scope.loaded.subjects = false;
        ihestiaDictionaryHelper.getDict('policySegment', {
          callback: function(segments) {
            $scope.segments = segments;
          }
        });

        ihestiaDashboardNewTaskHelper.getManualSubjects(ihestiaDashboardNewTaskHelper.context)
          .then(function(subjects) {
            $scope.subjects = subjects;
          }, angular.noop)
          .finally(function() {
            $scope.loaded.subjects = true;
          });
      };

      $scope.init();
    }
  ]);