/**
 * Konteksty IC, Życie/Mienie
 */
angular.module('ihestiaRestServicesBase')
  .provider('ihestiaIcContextConstants', [function() {

    var IcContextConstants = {};

    IcContextConstants.IC_CONTEXT_LIFE = 'EHL';
    IcContextConstants.IC_CONTEXT_PROPERTY = 'EHN';

    IcContextConstants.DICTIONARY_IC_CONTEXT = {};
    IcContextConstants.DICTIONARY_IC_CONTEXT[IcContextConstants.IC_CONTEXT_LIFE] = 'Życie';
    IcContextConstants.DICTIONARY_IC_CONTEXT[IcContextConstants.IC_CONTEXT_PROPERTY] = 'Mienie';

    /**
     * Zwraca stałe dla sekcji .config itd.
     * @return {Object} IcContextConstants
     */
    this.getConstants = function() {
      return IcContextConstants;
    };

    /**
     * Magic get, zwraca stałe
     * @return {Function}
     */
    this.$get = [function() {
      return IcContextConstants;
    }];
  }]);