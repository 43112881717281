angular.module('lsnBase.validators')
.directive('valueNumericCompare', ['$parse', function($parse) {
  return {
    require: 'ngModel',
    link: function(scope, elem, attrs, ngModel) {

      var setValidity = function(value) {
        ngModel.$setValidity('valueNumericCompare', value);
        return value;
      };

      attrs.$observe('valueNumericCompare', function(value) {
        var isValid = $parse(value);
        setValidity(isValid);
      });

      setValidity(attrs.valueNumericCompare);

      // For DOM -> model validation
      ngModel.$parsers.unshift(setValidity);

      // For model -> DOM validation
      ngModel.$formatters.unshift(setValidity);

    }
  };
}]);