angular.module('ihestiaRestServicesBase')
  .factory('changePasswordSvc', [ 'IHestiaRestServiceLsnDef',
    function(IHestiaRestServiceLsnDef) {

      var ChangePassword = function()
      {
        IHestiaRestServiceLsnDef.apply(this, arguments);
        var self = this;
        this.resource = 'password';

        this.changePassword = function(data, callback) {
          return self.post('changePassword', data, '', callback);
        };

        /**
         * Zmiana hasła by sms
         * @param  {Object}   data     [description]
         * @param  {Function} callback [description]
         * @return {Promise}
         */
        this.changePasswordBySms = function(data, callback) {
          return self.post('changePasswordBySms', data, '', callback);
        };

      };

     return new ChangePassword();
  }])
  ;