angular.module('lsnBase.bsDatepickerPropperPlTitle', [
    'mgcrea.ngStrap.datepicker'
  ])
  .config(['$provide', function($provide) {
    $provide.decorator('datepickerViews', ['$delegate', function($delegate){
      var months = ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
      var $newDelegate = function(picker){
        var $delegateFunc = $delegate(picker);
        if(picker.$options.lang === 'pl'){
          var viewDate = $delegateFunc.viewDate;
          picker.$scope.$watch('title', function(title){
            if(picker.$scope.$mode === 0) {
              var newTitle = months[viewDate.month] + ' ' + viewDate.year;
              if(title !== newTitle) {
                picker.$scope.title = newTitle;
              }
            }
          });
        }
        return $delegateFunc;
      };
      return $newDelegate;
    }]);
  }]);