angular.module('ihestiaCommonDirectives')
  .service('iHestiaCommonModalHelper', ['$rootScope',
    function($rootScope) {
      var CommonModalHelper = function() {
        var self = this;

        this.modalEscKeyboardCallback = null;
        this.visibleModals = [];
        this.nextWindowWillBeOpen = false;
        //Tablica flag informująca o tym, że dane okno zostanie jeszcze otwarte
        this.inOpenWindowModalContext = [];

        /**
         * Init
         */
        this.init = lsnNg.noop;

        /**
         * Pokaż modal
         * @param  {string} modalCode [description]
         * @return {CommonModalHelper}           [description]
         */
        this.showModal = function(modalCode) {
          $rootScope.$broadcast('commonModal.show.' + modalCode);
          return self;
        };


        /**
         * Ukryj modal
         * @param  {string} modalCode [description]
         * @return {CommonModalHelper}           [description]
         */
        this.hideModal = function(modalCode, nextWindowWillBeOpen) {
          $rootScope.$broadcast('commonModal.hide.' + modalCode);
          self.setFlagNextWindowWillBeOpen(nextWindowWillBeOpen);

          return self;
        };

        this.setFlagNextWindowWillBeOpen = function(flagName, value) {
          self.nextWindowWillBeOpen = !!value;
        };

        this.setInOpenWindowModalContext = function(nameFlag) {
          self.inOpenWindowModalContext.push(nameFlag);
        };

        this.removeFlagFromOpenWindowModalContext = function(nameFlag) {
          var index = self.inOpenWindowModalContext.indexOf(nameFlag);
          if (index > -1) {
            self.inOpenWindowModalContext.splice(index, 1);
          }
        };

        this.canOpenNextWindow = function(){
          return !self.nextWindowWillBeOpen && self.inOpenWindowModalContext.length === 0 && self.visibleModals.length === 0;
        };

        /**
         * Ukryj modal
         * @param  {string} modalCode [description]
         * @return {CommonModalHelper}           [description]
         */
        this.refreshModal = function(modalCode) {
          $rootScope.$broadcast('commonModal.hide.' + modalCode);
          $rootScope.$broadcast('commonModal.show.' + modalCode);
          return self;
        };


        /**
         * Pokazuje spinner na przycisku OK
         * @return {[type]} [description]
         */
        this.showOkSpinner = function(modalCode) {
          $rootScope.$broadcast('commonModal.showOkSpinner.' + modalCode);
        };

        /**
         * Ukrywa spinner na przycisku OK
         * @return {[type]} [description]
         */
        this.hideOkSpinner = function(modalCode) {
          $rootScope.$broadcast('commonModal.hideOkSpinner.' + modalCode);
        };

        /**
         * Pokazuje spinner na przycisku anuluj
         * @return {[type]} [description]
         */
        this.showCancelSpinner = function(modalCode) {
          $rootScope.$broadcast('commonModal.showCancelSpinner.' + modalCode);
        };

        /**
         * Ukrywa spinner na przycisku anuluj
         * @return {[type]} [description]
         */
        this.hideCancelSpinner = function(modalCode) {
          $rootScope.$broadcast('commonModal.hideCancelSpinner.' + modalCode);
        };

        /**
         * Ukrywa modal, nie wywalając go
         * @param  {string} modalCode
         */
        this.temporaryHideModal = function(modalCode) {
          $rootScope.$broadcast('commonModal.temporaryHide.' + modalCode);
        };

        /**
         * Ponownie pokazuje tymczasowo ukryty modal
         * @param  {string} modalCode
         */
        this.showTemporaryHiddenModal = function(modalCode) {
          $rootScope.$broadcast('commonModal.showTemporaryHidden.' + modalCode);
        };

        /**
         * tymczasowo chowamy aktywny popup, bo przykładowo popup BM chce się pokazać
         */
        this.hideActivePopups = function()
        {
          angular.forEach(self.visibleModals, function(modalCode){
            self.temporaryHideModal(modalCode);
          });
        };

        /**
         * odkrywamy tymczasowo schowane popupy
         */
        this.showTemporaryHiddenPopups = function()
        {
          angular.forEach(self.visibleModals, function(modalCode){
            self.showTemporaryHiddenModal(modalCode);
          });
        };
      };

      var helper = new CommonModalHelper();
      helper.init();
      return helper;
    }
  ]);