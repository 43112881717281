angular.module('ihestiaCommonDirectives')
  .factory('TwoListUploadDataContainer', ['UploadDataContainer', function(UploadDataContainer) {

    var TwoListUploadDataContainer = function() {
      UploadDataContainer.apply(this, arguments);
      var self = this;
      this.files = {
        toSend: [],
        uploaded: []
      };
      //@type  {scopeName => String}
      //WAITING - oczekiwanie na akcje
      //INFO -wysyłanie informacji o plikach
      //(w przypadku gdy flaga konfiguracji ustawiona 'firstCheckAllFiles' jest na false, powyższa faza nie występuje)
      //SENDING - faktyczne wysyłanie plików
      //VERIFY - sprawdza jakość pliku, np kod na polsie
      //ERROR - błąd przy sprawdzaniu typów atrybutów
      this.status = 'WAITING';
      this.callbacks.onErrorAttributes = null;

      this.configDefault = {
        //w zależności od tej flagi,
        //albo pliki wysyłane są po kolei
        //a jeśli na true
        //to najpierw wysyłamy informacje o plikach
        //a nastepnie jesli wszystkie pliki są poprawne wysyłamy faktyczne pliki
        firstCheckAllFiles: false,
        autoUpload: false,
        verifyForDocumentsTypes : [],
        doNotAddTokenId: false,
        oneFile: false //czy upload umozliwia tylko jeden plik
      };
      this.processedFileIndex = 0;

      this.config = angular.copy(self.configDefault);

      //Uwaga funkcja powinna być wołana tylko na zamknięcie okna
      //ponieważ niszczy cały obiekt, więc też referencję
      this.clearUploadData = function() {
        delete self.files.toSend;
        delete self.files.uploaded;
      };

      this.clearListFile = function() {
        angular.forEach(self.files.toSend, function(value, key) {
          delete self.files.toSend[key];
        });

        angular.forEach(self.files.uploaded, function(value, key) {
          delete self.files.uploaded[key];
        });
      };

      this.areFileToSend = function() {
        return self.files.toSend && self.files.toSend.length > 0;
      };

      this.checkAllFilesSendWithoutErrors = function() {
        var result = true;
        angular.forEach(self.files.uploaded, function(fileModel) {
          if (fileModel.hasError()) {
            result = false;
          }

        });
        return result;
      };

      this.allFilesSended = function() {
        return !self.files.toSend || self.files.toSend.length === 0;
      };

      /**
       * [setProcessedFile description]
       */
      this.setProcessedFile = function() {
        if (angular.isDefined(self.files.toSend[self.processedFileIndex])) {
          self.processedFile = self.files.toSend[self.processedFileIndex];
        }
      };

      this.getFirstIndexFileToSend = function() {
        return 0;
      };

      this.getProcessedFile = function() {
        return self.files.toSend[self.processedFileIndex];
      };

      this.getFileModelContainFile = function(file) {
        var fileModel = null;
        angular.forEach(self.files.toSend, function(val, i) {
          if (file.externalUploadFileId === val.file.externalUploadFileId) {
            fileModel = self.files.all[i];
          }
        });

        if (fileModel !== null) {
          return fileModel;
        } else {
          angular.forEach(self.files.uploaded, function(val, i) {
            if (file.externalUploadFileId === val.file.externalUploadFileId) {
              fileModel = self.files.uploaded[i];
            }
          });

          return fileModel;
        }
      };

      /**
       * [getIndexFileModelContainFile wyszukuje index pliku na liście]
       * @param  {[File]} file     [plik którego szukamy]
       * @return {[int]}          index pliku
       */
      this.getIndexFileModelContainFile = function(file) {
        var index = null;
        angular.forEach(self.files.toSend, function(val, i) {
          if (file.externalUploadFileId === val.file.externalUploadFileId) {
            index = i;
          }
        });

        if (index !== null) {
          return index;
        } else {
          angular.forEach(self.files.uploaded, function(val, i) {
            if (file.externalUploadFileId === val.file.externalUploadFileId) {
              index = i;
            }
          });

          return index;
        }
      };

      this.clearAllFiles = function() {
        self.clearListFile();
      };

      this.areUploadedFilesWithoutErrors = function() {
        var result = false;
        angular.forEach(self.files.uploaded, function(fileModel) {
          if (!fileModel.fileIsUploadedWithoutErrors()) {
            result = true;
          }

        });
        return result;
      };

      this.areUploadedFiles = function() {
        return self.files.uploaded && self.files.uploaded.length > 0;
      };
    };

    return TwoListUploadDataContainer;
  }]);