angular.module('lsnBase')
.directive('commonMarkInvalid', ['$timeout', function($timeout) {
  return {
    /**
    * require: '^?form'
    *
    * Bardzo fajna metoda odwołania się z dyrektywy elementu podrzędnego formularza, do jego kontrolera, gdzie dostajemy
    * również dostęp do listy wszystkich pól wraz z ich modelami(!!!). Element podrzędny nie musi być inputem!
    *
    * Odwołanie do kontrolera formularza jest 4 parametrem w funkcji link:
    * link: function (scope, elem, attrs, formCtrl) {}
    */
    require: '^?form',
    scope: true,
    link: function(scope, elem, attrs, formCtrl) {
      scope.inputNames = attrs.commonMarkInvalid.replace(' ', '');
      scope.additionalConditions = attrs.commonMarkInvalidAdditionalConditions;
      scope.inputNamesArray = scope.inputNames.split(',');
      scope.isInvalid = false;
      scope.isDirty = false;
      scope.conditionsValid = true;
      scope.errorClassName = 'has-error';

      /**
       * Sprawdzenie czy input/inputy kontenera jest poprawny i czy zewnętrzne kondycje są również spełnione
       * @return {[type]} [description]
       */
      scope.checkValidity = function() {
        if((scope.isDirty || scope.conditionsValid) && scope.isInvalid) {
          scope.addErrorClass();
        } else {
          scope.removeErrorClass();
        }
      };

      /**
       * Dodaje klasę błędu z kontenera
       * @return {[type]} [description]
       */
      scope.addErrorClass = function() {
        angular.element(elem[0]).addClass(scope.errorClassName);
      };

      /**
       * Usuwa klasę błędu z kontenera
       * @return {[type]} [description]
       */
      scope.removeErrorClass = function() {
        angular.element(elem[0]).removeClass(scope.errorClassName);
      };

      /**
       * Odpalenie observe na atrybucie zewnętrznych kondycji
       * @return {[type]} [description]
       */
      scope.runAttrsObserve = function() {
        attrs.$observe('commonMarkInvalidAdditionalConditions', function(newValue, oldValue) {
          if(newValue !== oldValue) {

            if(newValue) {
              scope.checkConditions(newValue);
            }

            scope.checkValidity();
          }
        });
      };

      /**
       * Sprawdza czy wszystkie kondycje zewnętrzne są true
       * @return {[type]} [description]
       */
      scope.checkConditions = function(conditions) {
        var breakLoop = false;
        var conditionsObj = {};
        if(conditions !== '')
        {
          conditionsObj = angular.fromJson(conditions);
        }

        angular.forEach(conditionsObj, function(value) {
          if(breakLoop === false) {
            if(value === false) {
              scope.conditionsValid = false;
              breakLoop = true;
            } else {
              scope.conditionsValid = true;
            }
          }
        });
      };

      /**
       * Odpalenie watchy na modelach inputów kontenera
       * @return {[type]} [description]
       */
      scope.runModelsWatch = function() {
        angular.forEach(scope.inputNamesArray, function(name) {
          var elementChild = scope.$new();

          /**
          * formCtrl[name];
          *
          * Takie odwołanie po nazwie pola inputa (np. formCtrl['fk_topic']) pozwala nam uzyskać dostęp do kontrolera
          * modelu tego inputa
          */
          elementChild.model = formCtrl[name];

          /**
          * function(newValue, oldValue, childScope) {}
          *
          * Funkcja odpalana przy $watch, pozwala jako 3 parametr przekazać scope, którego właściwość śledzi.
          * Bardzo przydatna funkcja przy rozbudowanych modelach, gdzie śledząc jedną właściwość, można po jej zmianie
          * odczytać inne wartości scope'a - podrzędne, czy też nadrzędne(!). Eliminuje to konieczność użycia
          * objectEquality === true, która śledzi właściwości obiektu rekursywnie i pozwala odwoływać się "w górę" w
          * stosunku do śledzonej wartości.
          */
          elementChild.$watch('model.$modelValue', function(newValue, oldValue, childScope) {
            if(newValue !== oldValue) {
              scope.isInvalid = childScope.model.$invalid;
              scope.isDirty = childScope.model.$dirty;

              scope.checkValidity();
            }
          });

          if(typeof elementChild.model !== 'undefined')
          {
            scope.isInvalid = elementChild.model.$invalid;
            scope.isDirty = elementChild.model.$dirty;
          }
        });

      };

      /**
       * Inicjalizacja dyrektywy
       * @return {[type]} [description]
       */
      scope.init = function() {
        scope.runAttrsObserve();
        scope.runModelsWatch();

        scope.checkConditions(attrs.commonMarkInvalidAdditionalConditions);
        scope.checkValidity();
        /**
        * @ HACK
        *
        * Kiedy dyrektywa jest wywoływana w dołączanej do modala templatce, przy ukrywaniu modala pozostają
        * w pamięci scopy, które nie uległy $destroy. Wtedy przy ponownym pokazaniu modala następuje stworzenie nowego
        * scopa, obok utworzonego wcześniej. Scopy dyrektyw się multiplikują.
        *
        * Jest to bardzo niebezpieczne przy $scope.$watch, ponieważ są one zakładane wielokrotnie na ten sam event,
        * bez możliwości derejestracji.
        *
        * HACK: $destroy należy wywołać ręcznie z timeout 0 na evencie "$destroy".
        */

        scope.$on('$destroy', function() {
          if(scope) {
            $timeout(function() {
              scope.$destroy();
            }, 0);
          }
        });
      };

      $timeout(function() {
        scope.init();
      }, 0);

    }
  };
}]);