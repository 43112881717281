angular.module('ihestiaCommonDirectives') //eslint-disable-line angular/controller-name
	.directive('lsnCommonUpload', function() {
		return {
			restrict: 'E',
			templateUrl: function(elem, attrs) {
				return attrs.drvTemplate || 'ihestia-commondirectives-template-app/directives/uploader/common/commonUploader.tpl.html';
			},
			scope: {
				//funkcja dostepne w callbackach
				// deleteFile - wywolana w momencie recznego usuniecia pliku (np w momncie klikniecie w krzyzyk przy nazwie pliku)
				// deleteFileFromAttachment - wywolana gdy nalezy usunac plik z listy zalczmikow
				// onFileUploadDone - wywolywana po poprawnym wyslaniu pliku
				// onErrorSendingFile - wywolane w momencie bledu przy wysylce pliku
				// onFileUploadAdd - wywolane zaraz po dodaniu pliku
				// onErrorAddingFile - wywolane w momencie nie udanego dodania pliku
				// onAllFileSend - wywolane po uploadzie wszystkich plikow
				// afterAllFileSend - wywolana zaraz po onAllFileSend pochodzacym z dyrektywy, w atrybucie metody zostanie przekazana informacja czy upload powiodl sie w calosci czy nie
				// setDocumentTypeForUpload - funkcja ktory powinna ustawic odpowiedni typ dokumentu
				// setAttributeForDocumentType - funkcja ktora powinna ustawic atrybuty dla wybranego typu dokumentu
				callbacks: '=?', //obiekt z lista funkcja z wyzej wymienionej listy
				documentCode: '=?', //typ aktualnie wybranego dokumentu
				documentContext: '=?', //typ aktualnie wybranego dokumentu
				document: '=?', //obiekt typu dokumentu, wymagany jesli chcemy w locie zmieniac ten typ
				name: '=?', //mozna indywidualnie przekazac kontekst
				errorFileExist: '=?', //
				uploadHelperName: '=?', //nazwa uploadHelper ktory ma obslugiwac upload - domyslnie ustawiony na ihestiaFirstInfoOneListUploaderHelper
				attachmentModel: '=?', //model załącznika który chcemy obsłużyć
				attachmentList: '=?', //lista do ktorej chcemy doczepiac pliki
				tplOptions: '=?', //opcje dla tpl z lista plikow
				uploaderOptions: '=?' //opcje uploadera 
			},
			controller: 'lsnCommonUploadCtrl'
		};
	});