angular.module('salesPath2')
  .service('fireBurglaryHelper', ['localizationHelper', 'RESOURCES', 'CONSTANTS', 'CONFIG', 'mainDataContainer', 'resourceHelper', 'sp2SelectionHelper', 'supplementHelper', 'dataContainerHelper', 'errorHelper', 'appVariables', 'renewalHelper',
    function(localizationHelper, RESOURCES, CONSTANTS, CONFIG, mainDataContainer, resourceHelper, sp2SelectionHelper, supplementHelper, dataContainerHelper, errorHelper, appVariables, renewalHelper) {
      var FireBurglaryHelper = function() {
        var self = this;

        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        var DEFAULT_DATA = {
          errors: {},
          view: null, //tu ustawiac bedzie trzeba dopiero w controlerze, helper by nie widzial zmian state
          rewriteFrom: '',
          productCode: null,
          otherProductCode: null,
          matrixVisible: false,
          activeProduct: [],
          premiums: {}, //skałdki za ryzyka
          suValues: {},
          localization: null,
          localizations: [], //lista pojazdów
          disableVariants: [],
          showlocalizations: CONFIG.BEHAVIOR.multipleLocalization,
          fireBurglaryRisksArray: [],
          flood: {
            disabled: false,
            visible: true,
            selected: true,
            floodIntensity: [],
            floodIntensityOption: RESOURCES.FLOOD_INTENSITY[0].value
          },
          insurerName: null,
          coowners: [],
          disabledSU: {},
          pageActive: false,
          variantLocalizations: {}, //lista wybranych lokalizacji per variant {variantCode: [localizationId, ..]}
          selectedVariants: {}, //lista wybranych variantow wybranych dla wybranej lokalizacji
          previouslySelectedVariants: {}, //historycznie (np. z poprzeniej polisy w dokupieniach) wybrane warianty proukctów
          previouslySelectedRisks: {}, //poprzednio wybrane ryzyka w produkcie. {kod_ryzyka: true} - oznacza wybrany
          unchangeableVariants: {}, //wylaczone warianty na podstawie danych w poprzedniej polisy
          isSingleProduct: CONFIG.BEHAVIOR.isSingleProduct,
          suLimitations: {}, //ograniczenia dotyczące sum ubezpieczeń (np. w trybie DiD)
          renewedRisks: {}, //ryzyka wznawiane
          renewedVariant: null //wariant wznawiany
        };

        this.tplData = angular.copy(DEFAULT_DATA);

        /**
         * Ustawiamy domyślne wartości parametrów edycji lokalizacji
         * @return {fireBurglaryHelper}
         */
        this.clearData = function() {
          self.tplData = angular.copy(DEFAULT_DATA);
          return self;
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'localizationSelected':
              self.refreshAvailableVariants();
              self.refreshRisksArray();
              self.refreshLocalizationData();
              self.setAllErrorPageForCurrentlyLocalization();
              self.refreshRenewal();
              self.refreshPremiums();
              break;
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.setAllErrorPageForCurrentlyLocalization();
              break;
            default:
              break;
          }
        };

        self.refreshAvailableVariants = function() {
          self.tplData.disableVariants = sp2SelectionHelper.getDisabledVariant(self.tplData.productCode);

          var floodDisabled = supplementHelper.isRiskPreviouslySelected(CONSTANTS.PRODUCT_OGI, CONSTANTS.RISK_FLOOD, mainDataContainer.selectedLocalization);
          floodDisabled = floodDisabled || (appVariables.isSupplement && !dataContainerHelper.isAllowedRisk(CONSTANTS.PRODUCT_OGI, CONSTANTS.RISK_FLOOD, mainDataContainer.selectedLocalization, ['newRisks']));
          self.tplData.flood.disabled = floodDisabled;

        };

        /**
         * [refreshRisksArray odswieza lista ryzyk ogniowych w zaleznosci od wybranej lokalizacji]
         * @return {[type]} [description]
         */
        self.refreshRisksArray = function() {
          var selectedLocalization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
          if (angular.isObject(selectedLocalization) && (CONSTANTS.PRODUCT_OGI === self.tplData.productCode || CONSTANTS.PRODUCT_KRA === self.tplData.productCode)) {
            self.tplData.fireBurglaryRisksArray = resourceHelper.getFireBurglaryRisksListAsArray(selectedLocalization.type, self.tplData.productCode);
          } else if (mainDataContainer.selectedLocalization === CONSTANTS.ALL_OBJECTS && !angular.equals(mainDataContainer.localizations, {})) {
            //jesli wybrana opcja "wszusykie lokalizacje", to sczytujemy listę ryzyk z pierszej lokalizacji z brzegu, aby pojawiły się nazwy na GUI
            var tmpLocalization = mainDataContainer.localizations[Object.keys(mainDataContainer.localizations)[0]];
            self.tplData.fireBurglaryRisksArray = resourceHelper.getFireBurglaryRisksListAsArray(tmpLocalization.type, self.tplData.productCode);
          }
          //ograniczenia dla SU. Obecnie wsparcie tylko w trybie DiD
          self.tplData.suLimitations = {};
          if (appVariables.isSupplement) {
            if (mainDataContainer.selectedLocalization !== CONSTANTS.ALL_OBJECTS && mainDataContainer.selectedLocalization !== CONSTANTS.NO_OBJECT) {
              angular.forEach(self.tplData.fireBurglaryRisksArray, function(riskData) {
                var attrData = dataContainerHelper.getAllowedAttributeForRisk('sumInsured', mainDataContainer.selectedLocalization, self.tplData.productCode, riskData.code, selectedLocalization.type, 'existingRisks');
                if (attrData !== null) {
                  self.tplData.suLimitations[riskData.code] = {
                    min: angular.isNumber(attrData.minValue) ? attrData.minValue : null,
                    max: angular.isNumber(attrData.maxValue) ? attrData.maxValue : null
                  };
                }
              });
              self._refreshPreviousRiskSelection();
            } else {
              self.tplData.previouslySelectedRisks = {};
            }
          }
        };

        /**
         * aktualizuje informacje o wybranych ryzykach na poprzedniej polisie
         */
        this._refreshPreviousRiskSelection = function() {
          self.tplData.previouslySelectedRisks = {};
          angular.forEach(mainDataContainer.previouslySelectedRisksRefs, function(prevData) {
            if (prevData.product === self.tplData.productCode && mainDataContainer.selectedLocalization === prevData.objId) {
              self.tplData.previouslySelectedRisks[prevData.risk] = true;
            }
          });
        };

        /**
         * Odświerzenie widoku
         *
         */
        this.refreshLocalizationData = function() {
          angular.forEach(RESOURCES.PRODUCTLIST, function(product) {
            if (self.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE && product.CODE === CONSTANTS.PRODUCT_OGI) {
              self.tplData.activeProduct = product;
            }
            if (self.tplData.view === CONSTANTS.PAGE_PRODUCT_BURGLARY && product.CODE === CONSTANTS.PRODUCT_KRA) {
              self.tplData.activeProduct = product;
            }
          });
          /*
           * modele wypełnienia danych
           */
          self.tplData.suValues = {};
          angular.forEach(self.tplData.fireBurglaryRisksArray, function(risk) {
            var riskCode = CONSTANTS.PRODUCT_KRA;
            if (self.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
              riskCode = CONSTANTS.PRODUCT_OGI;
            }

            var su = dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, riskCode, risk.code);
            if (angular.equals(su, {})) {
              su = '';
            }
            self.tplData.suValues[risk.code] = su;
          });
          self.refreshDisabledElement();

          if (mainDataContainer.localizations[mainDataContainer.selectedLocalization]) {

            self.tplData.localization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
            if (self.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
              self.tplData.flood.visible = true;
              var flood = self.tplData.localization.getAdditionalData('flood');
              if (flood !== null) {
                self.tplData.flood.selected = true;
                self.tplData.flood.floodIntensityOption = flood + '';
              } else {
                self.tplData.flood.selected = false;
                self.tplData.flood.floodIntensityOption = self.tplData.flood.floodIntensity[0].value;
              }
            } else {
              self.tplData.flood.visible = false;
            }
          }

          self.tplData.matrixVisible = !angular.equals(mainDataContainer.localizations, {});
          self.refreshVariantLocalizations();
        };

        /**
         * [refreshVariantLocalizations odswieza liste wybranych wariantow]
         * @return {[type]} [description]
         */
        this.refreshVariantLocalizations = function() {
          self.tplData.selectedVariants = dataContainerHelper.getVariantsForProduct(self.tplData.productCode);
          self.tplData.previouslySelectedVariants = dataContainerHelper.getPreviousVariantsForProduct(self.tplData.productCode, mainDataContainer.selectedLocalization);
          self.tplData.unchangeableVariants = sp2SelectionHelper.getUnchangeableVariants(sp2SelectionHelper.getHistoricalDisabledVariants(self.tplData.productCode, mainDataContainer.selectedLocalization), self.tplData.previouslySelectedVariants, self.tplData.selectedVariants);
          self.setSelectedConnectedFireRisk();
        };

        /**
         * [setSelectedConnectedFireRisk sprawdza i oustawia flage informujaca o tym czy wybrano wariant z połączonymi ryzyka]
         */
        this.setSelectedConnectedFireRisk = function() {
          self.tplData.selectedConnectedFireRisk = false;
          angular.forEach(CONFIG.BEHAVIOR.localization.connectedFireRisk, function(text, variantCode) {
            if (self.tplData.selectedVariants[variantCode]) {
              self.tplData.selectedConnectedFireRisk = true;
            }
          });
        };

        this.clearPremiums = function() {
          //tutaj ewentualne czyszczenie składek i wrzcanie "-" w niedostępnych ryzykach
        };

        this.refreshPremiums = function() {
          self.clearPremiums();
          var premiums,
            premiumContainer = 'premiumList';
          if (mainDataContainer.selectedLocalization !== CONSTANTS.ALL_OBJECTS) {
            premiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, self.tplData.productCode, undefined, premiumContainer);
          } else {
            premiums = {};
            //wszystkie lokazliacje, sumujemy składki
            angular.forEach(mainDataContainer.localizations, function(localization, id) {
              var localizationPremiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, id, self.tplData.productCode, undefined, premiumContainer);
              angular.forEach(localizationPremiums, function(variants, riskCode) {
                angular.forEach(variants, function(premium, variantCode) {
                  if (angular.isUndefined(premiums[riskCode])) {
                    premiums[riskCode] = {};
                  }
                  if (angular.isUndefined(premiums[riskCode][variantCode])) {
                    premiums[riskCode][variantCode] = 0;
                  }
                  if (mainDataContainer.selectedVariants.localization[id][self.tplData.productCode] === variantCode) {
                    premiums[riskCode][variantCode] += premium;
                  }
                });
              });
            });
          }

          self.tplData.premiums = premiums;
          self.refreshRenewal();
        };

        /**
         * czy subRyzyko dostępne przy aktualnym zaznaczeniu i sumach dla innych ryzyk
         * @param  {String}  productCode nazwa producktu const PRODUCT_OGI lub PRODUCT_KRA
         * @param  {String}  subRiskName nazwa subryzyka, np. const RISK_ESTATE, RISK_GARDEN itd.
         * @return {Boolean}
         */
        this.isSubRiskAvailable = function(productCode, subRiskName) {
          return dataContainerHelper.isSubRiskAvailable(productCode, subRiskName);
        };

        /**
         * [refreshDisabledElement zaznaczona/odznacza inputy zalezne od innych inputow]
         * @param {Boolean} czy czysic zalezne sumy ubezpieczenia
         * @return {[type]} [description]
         */
        this.refreshDisabledElement = function(clearDependedSum) {
          var clientPolicy = dataContainerHelper.anyClientPolicyExist();
          self.tplData.disabledSU = {};
          if (appVariables.readOnly) { //w trybie RO blokujemy wszystkie inputy z SU
            angular.forEach(self.tplData.fireBurglaryRisksArray, function(riskDef) {
              self.tplData.disabledSU[riskDef.code] = true;
            });
          } else { //tryb edycji
            var allowedPropertyRisks = dataContainerHelper.getAllowedPropertyRisks(self.tplData.productCode, mainDataContainer.selectedLocalization);
            angular.forEach(self.tplData.fireBurglaryRisksArray, function(riskDef) {
              if (angular.isArray(riskDef.dependsOn)) {
                self.tplData.disabledSU[riskDef.code] = true;
                angular.forEach(riskDef.dependsOn, function(riskCode) {
                  //jesli wartosc zalezna nie zerowa badz polisa klienta (istnieje polisa cesyjna z ryzykiem CONSTANTS.RISK_ESTATE)
                  if (self.tplData.suValues[riskCode] > 0 || (riskCode === CONSTANTS.RISK_ESTATE && self.tplData.productCode === CONSTANTS.PRODUCT_OGI && appVariables.isSupplement && clientPolicy)) {
                    self.tplData.disabledSU[riskDef.code] = false;
                  }
                });
              }
              //blokowanie np. w dokupieniach - niezależne od powyższego
              if (!self.tplData.disabledSU[riskDef.code] && allowedPropertyRisks !== null && !allowedPropertyRisks[riskDef.code]) {
                self.tplData.disabledSU[riskDef.code] = true;
              }
            });
          }

          if (clearDependedSum) {
            angular.forEach(self.tplData.disabledSU, function(value, riskCode) {
              if (value) {
                self.tplData.suValues[riskCode] = '';
                dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, self.tplData.productCode, self.tplData.suValues[riskCode], riskCode);
              }
            });
          }

        };

        /**
         * ustawia błędy (o ile są) dla aktualnej strony bledow
         */
        this.setAllErrorPageForCurrentlyLocalization = function() {
          var params = {
            productType: CONSTANTS.PRODUCT_TYPE_LOCALIZATION,
            objectClientId: mainDataContainer.selectedLocalization,
            risks: []
          };
          angular.forEach(self.tplData.fireBurglaryRisksArray, function(riskDef) {
            var risk = {
              code: riskDef.code,
              productCode: self.tplData.productCode
            };
            params.risks.push(risk);
          });
          self.tplData.errors = errorHelper.getErrorsForRisks(params);
        };

        /**
         * [refreshRenewal odswieza informacje o odnowieniach]
         */
        this.refreshRenewal = function() {
          if (!renewalHelper.isRenewal()) {
            return false;
          }
          self.tplData.renewedRisks = renewalHelper.getSubRisksMarks(self.tplData.productCode);
          var renewedProdVnts = renewalHelper.getProductsMarks(self.tplData.productCode);
          self.tplData.renewedVariant = angular.isDefined(renewedProdVnts[self.tplData.productCode]) ? renewedProdVnts[self.tplData.productCode] : null;
          return true;
        };
      };

      return new FireBurglaryHelper();
    }
  ])
  .run(['fireBurglaryHelper', 'actionHelper',
    function(fireBurglaryHelper, actionHelper) {
      actionHelper.registerHelper('fireBurglaryHelper', fireBurglaryHelper);
    }
  ]);