angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardAttachmentConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardAttachmentModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var AttachmentModel = function() {
      this.objectName = 'IhestiaDashboardAttachmentModel';

      this.fieldsProperties = {
        fileName: {
          type: 'string'
        },
        fileSize: {
          type: 'int'
        },
        fileType: {
          type: 'string'
        },
        fileServerId: {
          type: 'string'
        }
      };

      this.fileName = null;
      this.fileSize = null;
      this.fileType = null;
      this.fileServerId = null;
    };


    AttachmentModel.prototype = IHestiaAbstractModel.prototype;

    return AttachmentModel;
  }]);