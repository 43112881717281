angular.module('salesPath2.spd')
.factory('spdAllSalesProducts', [function() {
    /*
     * allSalesProducts - atrybuty dodane w tym miejscu będą dozwolone na wszystkich pakietach
     */
    var allSalesProducts = {
      attributes: {
        'additionalClause': ['Simple', 'String'], //klauzule dodatkowe
        '_additionalData': ['Simple', 'String'], //dane dodatkowe ze sciezki sprzedazy
        '_premiumForDisplay': ['Simple', 'Decimal'], //skladka za polisę do wyswietlenia w GUI
        '_policyVer': ['Simple', 'String'], //metaData.id policy - w sumie nie wiem po co
        '_agreementMinimalPremiumReduced': ['Simple', 'Boolean'], //zgoda na obnizenie skladki minimalnej
        '_patronClauseVisible': ['Simple', 'Boolean'], //czy widoczna klauzula K5
        '_patronIKontoLinkVisible': ['Simple', 'Boolean'], //czy ma być prezentowqny link do zmiany OK w iKoncie
        'operationDate': ['Simple', 'Date'] //data operacji
      }
    };

    return allSalesProducts;
  }])
;