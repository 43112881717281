/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuHelp')
	.factory('screenHelpSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
		var HelpSvc = function() {
			IHestiaRestServiceAbstractDef.apply(this, arguments);
			this.apiVer = 'v3';
			this.resource = 'screenhelp';
			

			this.basePattern = '{host}{port}{path}/{apiString}/v3/{resource}{id}{subResource}';
		};

		return new HelpSvc();
	}]);