angular.module('ihestiaWidgets.dashboard.euaChecker')
  .factory('ihestiaEuaCheckerHelper', ['euaCheckerSvc', 'ihestiaEuaCheckerModalHelper', 'ihestiaSsoBaseInfoHelper', 'ihestiaAgHelper', 'ihestiaPlatformHelper', 'ihestiaEuaDocumentsSvc',
    function(euaCheckerSvc, ihestiaEuaCheckerModalHelper, ihestiaSsoBaseInfoHelper, ihestiaAgHelper, ihestiaPlatformHelper, ihestiaEuaDocumentsSvc) {
      var EuaCheckerHelper = function() {

        var self = this;

        /**
         * Sprawdzenie umów agensyjnych
         * @return {undefined}
         */
        this.checkForDocuments = function() {
          var currentUser = ihestiaSsoBaseInfoHelper.getUser();
          var accountInfo = currentUser.accountInfo;
          if (accountInfo.showEuaInfo !== false) {
            if (ihestiaPlatformHelper.isJupiterAgent()) {
              if(currentUser.character.ossContextInfo.WorkerIsOwnerProperty === true || currentUser.character.ossContextInfo.WorkerIsRepresentativeProperty === true || (currentUser.character.type === 'Worker' && ihestiaAgHelper.hasPermissionFor('Public', 'AgencyAgreementView'))){
                self._checInDocuments();
              }
            } else if (self.canCheckEua()) {
              self._checkInDashboard();
            }
          }
        };

        this._checInDocuments = function() {
          var ossContextInfo = ihestiaSsoBaseInfoHelper.getCurrentUser().getCurrentCharacter().ossContextInfo;
          ihestiaEuaDocumentsSvc.get('agencyAgreements', {
            intermediaryNumber: ossContextInfo ? ossContextInfo.IntermediaryNumber : ''
          }).then(function(res) {
            if (res && angular.isArray(res.data)) {
              var documentToAccept = null; // eslint-disable-line no-unused-vars
              var documentToAcceptCount = 0;
              // sortujemy po dacie dla EHNLJUPIT-4051
              res.data.sort(function(a, b) {
                if(!a.finishDate && !b.finishDate) {
                  return 0;
                } else if (!a.finishDate) {
                  return -1;
                } else if(!b.finishDate) {
                  return 1;
                } else {
                  var aDate = new XDate(a.finishDate);
                  var bDate = new XDate(b.finishDate);
                  return bDate.diffMilliseconds(aDate);
                }
              });
              angular.forEach(res.data, function(euaDocument) {
                angular.forEach(euaDocument.decisions, function(decision) {
                  if (euaDocument.status === 'PENDING' && decision.status !== 'ACCEPTED') {
                    if(documentToAccept === null) {
                      documentToAccept = euaDocument;
                    }
                    documentToAcceptCount++;
                  }
                });
              });
              if (documentToAcceptCount > 0) {
                ihestiaEuaCheckerModalHelper.showEuaModal({
                  documentToAcceptCount: documentToAcceptCount,
                  acceptToDate: documentToAccept.finishDate
                });
              }
            }
          }, lsnNg.noop);
        };

        /**
         * Sprawdzamy umowy w dashboardzie
         * @return {undefined}
         */
        this._checkInDashboard = function() {
          var currentUser = ihestiaSsoBaseInfoHelper.getUser();
          var ossContextInfo = currentUser.getCurrentCharacter().ossContextInfo;
          var data = {
            dynamicFilters: {
              category: ['AGENCY_AGREEMENT_CHANGE'],
              status: ['NEW', 'IN_PROGRESS', 'POSTPONED'],
              subjectKeySaleProfile: self.getSubjectKeySaleProfiles(),
              subjectKeyWorker: [ossContextInfo.SubjectKeyWorker]
            },
            sortBy: 'dueDate'
          };
          // wołamy usługę zadań (ta sama, co w dashboardzie)
          euaCheckerSvc.findByParams(data).then(function(res) {
            if (res && res.status === 200 && res.data && angular.isArray(res.data.items) && res.data.items.length > 0) {
              ihestiaEuaCheckerModalHelper.showEuaModal(res.data.items);
              ihestiaEuaCheckerModalHelper.showEuaModal({
                documentToAcceptCount: res.data.items.length,
                acceptToDate: res.data.items[0].dueDate //posortowane przez usługę
              });
            }
          }, angular.noop);
        };

        /**
         * Sprawdzamy, czy możemy sprawdzać zadania eua
         * @return {Boolean}
         */
        this.canCheckEua = function() {
          var currentUser = ihestiaSsoBaseInfoHelper.getUser();
          if(currentUser.userType === 'System' || currentUser.userType === 'Client')
          {
            return false;
          }
          var ossContextInfo = currentUser.getCurrentCharacter().ossContextInfo;
          if (self.getSubjectKeySaleProfiles().length === 0 || !ossContextInfo.SubjectKeyWorker) {
            return false;
          }
          if (!ihestiaAgHelper.hasPermissionFor('Public', 'AgentService') || !ihestiaAgHelper.hasPermissionFor('Public', 'DashboardServices')) {
            return false;
          }

          return true;
        };

        this.getSubjectKeySaleProfiles = function() {
          var ossContextInfo = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().ossContextInfo;
          var profiles = [];
          if(ossContextInfo.SubjectKeySalesProfileProperty) {
            profiles.push(ossContextInfo.SubjectKeySalesProfileProperty);
          }
          if(ossContextInfo.SubjectKeySalesProfileLife) {
            profiles.push(ossContextInfo.SubjectKeySalesProfileLife);
          }

          return profiles;
        };

      };

      return new EuaCheckerHelper();
    }]);
