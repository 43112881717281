angular.module('lsnBase.bootstrap')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('lsnRestCommonHelperDef', ['$q', '$timeout',
    function($q, $timeout){

    var RestCommonHelperDef = function() {

      var self = this;

      /**
       * generowanie unikalnego id requesta
       * gotowy algorytm został dostarczony
       * nie winić za niego osobę w "blame"
       *
       * @returns {string}
       */
      this.getUniqueRequestId = function()
      {
        var subhash = function()
        {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); // NOSONAR
        };

        return (subhash() + subhash() + '-' + subhash() + '-' + subhash() + '-' + subhash() + '-' + subhash() + subhash() + subhash());
      };

      this.objectToFormString = function(object)
      {
        var stringTab = [];
        angular.forEach(object, function(value, key){
          stringTab.push(key + '=' + encodeURIComponent(value));
        });
        return stringTab.join('&');
      };

      /**
       * repeat svcMethod calls with attributes given in attributesTab
       * until response match check or reloading takes to long
       * @param  {function} svcMethod for example policesSvc.get
       * @param  {array} attributesTab example: ['1234'] - policy number
       * @param  {function} checkMethod - must return boolean
       * @param  {object} params - additional params
       * @return {promise}
       */
      this.reloadUntil = function(svcMethod, attributesTab, checkMethod, params) {
        var defaultParams = {
          maxCheck: 20,
          checkInterval: 3000
        };

        if(typeof params === 'undefined') {
          params = {};
        }
        angular.forEach(defaultParams, function(value, key){
          if(typeof params[key] === 'undefined') {
            params[key] = value;
          }
        });
        params.currentCheck = 0;

        var deferred = $q.defer();
        self.reloadCall(svcMethod, attributesTab, checkMethod, params, deferred);
        return deferred.promise;
      };

      this.reloadCall = function(svcMethod, attributesTab, checkMethod, params, deferred){
        if(params.currentCheck < params.maxCheck) {
          svcMethod.apply(this, attributesTab).then(function(res){
            if(checkMethod(res)) {
              deferred.resolve(res);
            } else {
              $timeout(function() {
                params.currentCheck = params.currentCheck + 1;
                self.reloadCall(svcMethod, attributesTab, checkMethod, params, deferred);
                }, params.checkInterval);
            }
          }, function(res){
            if(checkMethod(res)) {
              deferred.resolve(res);
            } else {
              $timeout(function() {
                params.currentCheck = params.currentCheck + 1;
                self.reloadCall(svcMethod, attributesTab, checkMethod, params, deferred);
                }, params.checkInterval);
            }
          });
        } else {
          deferred.reject('reloadUntilTimeout');
        }
      };

    };

    return RestCommonHelperDef;

  }]).factory('lsnRestCommonHelper', ['lsnRestCommonHelperDef', function(lsnRestCommonHelperDef){
    return new lsnRestCommonHelperDef(); // eslint-disable-line new-cap
  }]);