angular.module('salesPath2')
  .controller('matrixVehicleBoxCtrl', ['$scope', 'matrixVehicleBoxHelper', 'CONSTANTS', 'vehicleHelper', 'actionHelper', 'applicationHelper', 'sp2CommonHelper', '$timeout', 'vehicleModalHelper', '$filter', 'dataContainerHelper',
    function($scope, matrixVehicleBoxHelper, CONSTANTS, vehicleHelper, actionHelper, applicationHelper, sp2CommonHelper, $timeout, vehicleModalHelper, $filter, dataContainerHelper) {
        $scope.tplData = matrixVehicleBoxHelper.tplData;

        $scope.init = function() {
            matrixVehicleBoxHelper.refreshVehicles();
        };

        $scope.selectVehicle = function(clientId) {
            if (parseInt(dataContainerHelper.getSelectedObjectId(CONSTANTS.PRODUCT_TYPE_VEHICLE), 10) === parseInt(clientId, 10)) {
              return; //ten podmiot jest juz wybrany
            }
            dataContainerHelper.selectVehicle(clientId);
            actionHelper.runAction('vehicleSelected');
        };

        //usuwanie osoby/grupy z listy ubezpieczonych
        $scope.deleteVehicle = function(clientId) {
            sp2CommonHelper.showConfirmModal({
                treatLabelAsI18nCode: true,
                title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
                text: $filter('translate')('sureWantToDeleteVehicle', {componentCode: 'sp2'}),
                okBtnCallback: function() {
                    vehicleHelper.deleteVehicle(clientId);
                    matrixVehicleBoxHelper.refreshVehicles();
                }
            });
        };

        $scope.showEtxSearchVehicleModal = function() {
          $timeout(function() {
            vehicleHelper.clearEditModalData();
            vehicleModalHelper.showEtxSearchVehicleModal();
          }, 0);
        };

        //otwarcie modala edycji podmiotu
        $scope.showEditModal = function(vehicle) {
            $timeout(function() {
                vehicleModalHelper.editVehicle(vehicle.clientId);
            }, 0);
        };


        $scope.init();
    }
  ]);