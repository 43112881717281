angular.module('lsnBase.filters')
  .filter('lsnmd', ['$sce',
    function($sce) {
      var self = this;
      showdown.setOption('openLinksInNewWindow', true);
      var converter = new showdown.Converter();

      this.filter = function(mdText, unsafe, customConverter) {
        if (typeof mdText !== 'string') {
          return mdText;
        } else {
          unsafe = unsafe !== false;
          var currConverter = customConverter || converter;
          var mdHtml = currConverter.makeHtml(mdText);
          var html = self._applyNewLine(mdHtml);
          return unsafe ? $sce.trustAsHtml(html) : html;
        }
      };

      this._applyNewLine = function(htmlString) {
        // negative lookahead for list elements - prevent redundant line breaks
        return htmlString.replace(/\n(?!(<li>|<\/ul>|<ul>))/g, function(e) {
          return e.trim() + '<br/>';
        });
      };

      return this.filter;
    }]);
