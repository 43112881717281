angular.module('ihestiaCommonDirectives')
  .service('ihestiaCommonStateHelper', [function(){
    var Helper = function(){
      this.stateParams = {
        willRedirect: false //flaga mówiąca o tym czy nastąpi przekierowanie z portalu
        //jeśli mamy ustawioną flagę willRedirect to np w niektórych sytuacjach na jej podstawie 
        //pomijamy/anulujemy ładowanie modułu wiadomości administracyjnych
        //występuje to np. dla przypadku opisanego w zgłoszeniu https://ebok.atena.pl/browse/IHESTIADA-341
      };
    };

    return new Helper();
  }]);