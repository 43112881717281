/**
 * Dyrektywa do wrzucenia szablonu clipboard
 * @param  {[type]}  [description]
 * @return {[type]}  [description]
 */
angular.module('ihestiaMenuClipboard')
	.directive('ihestiaMenuClipboard', ['$templateCache',
		function($templateCache) {
			return {
				restrict: 'E',
				replace: true,
				template: $templateCache.get('ihestia-menu-template-app/clipboard/clipboard.tpl.html'),
				link: lsnNg.noop
			};
		}
	]);