angular.module('salesPath2')
  .controller('ceidgSearchModalCtrl', ['$scope', '$rootScope', 'sp2CommonHelper', '$timeout', 'ceidgReaderSvc', 'ceidgModalHelper', 'sp2UiHelper',
    function($scope, $rootScope, sp2CommonHelper, $timeout, ceidgReaderSvc, ceidgModalHelper, sp2UiHelper) {

      $scope.regon = '';
      $scope.nip = '';

      $scope.numericSettings = {
        decimals: 0,
        groupSeparator: '',
        allowLeadingZeros: true,
        parseToString: false,
        formatting: true
      };

      // wyszukujemy podmiotu
      $scope.searchCeidg = function() {
        if ($scope.nip !== '') {
          sp2UiHelper.showBlockUi();
          ceidgReaderSvc.get($scope.nip, null, 'searchByNip', $scope.callbackOnSearch);
        } else if ($scope.regon !== '') {
          sp2UiHelper.showBlockUi();
          ceidgReaderSvc.get($scope.regon, null, 'searchByRegon', $scope.callbackOnSearch);
        }
      };

      $scope.callbackOnSearch = function(res) {
        sp2UiHelper.hideBlockUi();

        sp2CommonHelper.hideModal('ceidgSearchModal');

        if (!angular.isObject(res.data.entry)) {
          sp2CommonHelper.showConfirmModal({
            title: 'Wyszukiwanie Klienta nie powiodło się',
            text: 'Nie odnaleziono Klienta.',
            cancelBtnName: '',
            okBtnName: 'Ok'
          });
        } else {
          ceidgModalHelper.showCeidgClientFoundModal(res.data.entry);
        }
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.ceidgSearchModal', function(event) {
        $scope.searchCeidg();
        event.preventDefault();
      });
    }
  ]);