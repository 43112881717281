angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('ihestiaRestCommonHelper',['lsnRestCommonHelperDef',
    function(lsnRestCommonHelperDef){

    var RestCommonHelper = function() {
      lsnRestCommonHelperDef.apply(this, arguments);
    };

    return new RestCommonHelper();

  }]);