angular.module('spaceChat.client', [
  'ihestia-widgets-templates-app',
  'ihestiaSsoBase',
  'spaceChat.common'
])
  .run(['bodyInjectorHelper',
    function(bodyInjectorHelper) {
      bodyInjectorHelper.injectDirective('spaceChatClientModalsInjector', 'space-chat-client-modals-injector');
    }
  ]);
