angular.module('lsnBase.models')
  .factory('LsnInsuredSubjectModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {

      var LsnInsuredSubjectModel = function()
      {
        this.objectName = 'InsuredSubject';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          ref:
          {
            type: 'string'
          },
          role:
          {
            type: 'string'
          },
          groupSymbol:
          {
            type: 'string'
          }
        };

        this.ref = null; // string, Referencje do obiektów lub osób na polisie. Dla pierwszego rodzaju MetaData.Id z vehicle lub estates, dla drugiego natomiast MetaData.Id z listy persons lub organizations
        this.role = null; // string, rola podmiotu ubezpieczonego np. OWNER
        this.groupSymbol = null;
      };

      LsnInsuredSubjectModel.prototype = LsnAbstractModel.prototype;

      return LsnInsuredSubjectModel;
    }
  ]);