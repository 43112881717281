angular.module('ihestiaCommonDirectives')
  .directive('inputPendingIndicator', [
    function(){
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/inputPending/inputPending.tpl.html',
        link: function($scope, $element, $attrs){
          $scope.$watch($attrs.indicatorFlag, function(flag){
            $scope.indicateProgress = flag;
          }, true);
        }
      };
    }]);