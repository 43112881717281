angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorRecipientModel', ['IKomunikatorAbstractModel', 'iKomunikatorConstants', function(IKomunikatorAbstractModel, iKomunikatorConstants) {

    var IKomunikatorRecipientModel = function() {
      var self = this;
      IKomunikatorAbstractModel.apply(this, arguments);

      /**
       * email
       * @type {string} dowolne znaki brak walidacji
       */
      this.emailAddress = null;

      /**
       * [role rola w systemie]
       * @type {[string]}
       */
      this.role = null;

      /**
       * telephoneNumber
       * @type {[string]} dowolne znaki brak walidacji
       */
      this.telephoneNumber = null;

      /**
       * telephoneNumber
       * @type {[string]} prefix telefonu
       */
      this.phonePrefix = '+48';

      //dodatkowe wartości
      this.taggedValues = {};


      /**
       * [setRecipientFromData funkcja ustawia odbiorcę z osoby wyszukanej przez wyszukiwarkę]
       * @param {[type]} recipient [obiekt odbiorcy]
       */
      this.setRecipientData = function(person, role, subjectType) {
        if (angular.isUndefined(role)) {
          var isOrganization = subjectType === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY;
          self.role = isOrganization ? iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY : iKomunikatorConstants.CONTACT_ROLE_CLIENT;
          var fields = isOrganization ? ['name', 'regon', 'nip', 'companyId', 'countryCode'] : ['lastName', 'firstName', 'pesel'];
          _.assign(self.taggedValues, _.pick(person, fields));
          angular.forEach(person.contacts, function(contact) {
            if (contact.code === 'mail') {
              self.emailAddress = contact.value;
            } else if (contact.code === 'komo') {
              self.telephoneNumber = contact.value;
              self.phonePrefix = contact.countryPrefix;
            }
          });
          self.taggedValues.ikontoId = person.customerId;

          self.customerStatusLabel = (angular.isString(person.customerStatus) && person.customerStatus.toLocaleLowerCase() === 'active') ? 'Aktywne' : 'Nieaktywne';

        } else {
          self.role = role;
          if (role === iKomunikatorConstants.CONTACT_ROLE_WORKER) {
            self.taggedValues.lastName = person.surname;
            self.taggedValues.firstName = person.firstname;
            self.taggedValues.raNumber = person.ra_number;
            self.taggedValues.workerKey = person.subject_key;
            self.taggedValues.salesProfileKey = person.agency_key;
          } else if (role === iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE) {
            self.taggedValues.salesProfileKey = person.agency_key;
            self.taggedValues.name = person.name;
            self.taggedValues.intermediaryName = person.name;
            self.taggedValues.displayName = person.name;
          }
        }

        self.taggedValues.platform = person.platform ? person.platform : null;
      };

      /**
       * [setForNewRecipent ustawia model pod nowego odbiorce]
       */
      this.setForNewRecipent = function() {
        self.role = iKomunikatorConstants.CONTACT_ROLE_OTHER;
      };

      /**
       * [getModelForRest zwraca obiekt do wyslania w usludze rest]
       * @return {[type]} [description]
       */
      this.getModelForRest = function() {
        var data = angular.copy(self.toRest());
        delete data.customerStatusLabel;

        if (data.displayName) {
          data.taggedValues.displayName = data.displayName;
        }

        if (data.phonePrefix && data.telephoneNumber) {//jira IHESTIADA-2163 - tworzylo sie dziwactwo w stylu 48undefine

          if (data.phonePrefix.charAt(0) === '+') {
            data.phonePrefix = data.phonePrefix.substring(1);
          }
          data.telephoneNumber = data.phonePrefix + data.telephoneNumber;
        }

        delete data.phonePrefix;
        delete data.displayName;

        if (data.role === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
          data.role = iKomunikatorConstants.CONTACT_ROLE_CLIENT;
        }

        return data;
      };

      this.setFromList = function(data) {
        self.fromRest(data);
        if (self.taggedValues && self.taggedValues.display_name) {
          self.taggedValues.displayName = self.taggedValues.display_name;
          delete self.taggedValues.display_name;
        }
      };
    };

    return IKomunikatorRecipientModel;
  }]);
