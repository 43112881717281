angular.module('ihestiaCommonDirectives.binaryUpload')
	.factory('binaryUploadMessage', ['$filter', function($filter) {
		return {
			noFileSelected: $filter('translate')('noFileSelected', {
				componentCode: 'Public'
			}),
			exceptionInternalServices: $filter('translate')('exceptionInternalServices', {
				componentCode: 'Public'
			}),
			tokenNotReturnedByService: $filter('translate')('tokenNotReturnedByService', {
				componentCode: 'Public'
			}),
			noPermissionToSaveFile: $filter('translate')('noPermissionToSaveFile', {
				componentCode: 'Public'
			}),
			exceptionNoAuthentication: $filter('translate')('exceptionNoAuthentication', {
				componentCode: 'Public'
			}),
			toLargeFile: $filter('translate')('toLargeFile', {
				componentCode: 'Public'
			}),
			characterConflict: $filter('translate')('characterConflict', {
				componentCode: 'Public'
			}),
			exceptionFileNotSentProbablyMalware: $filter('translate')('exceptionFileNotSentProbablyMalware', {
				componentCode: 'Public'
			}),
			errorAttributesTypeDocument: $filter('translate')('errorAttributesTypeDocument', {
				componentCode: 'Public'
			})
		};

	}]);