angular.module('ihestiaCommonDirectives.application')
  .factory('IhestiaOfferHelper', ['AbstractPolicyAndOfferHelper', 'simplePolicyHelper', 'OFFER_CONSTANTS', 'applicationsSvc', 'ihestiaRolesOnPolicyConstants', 'LsnOfferModelConstants', 'insuranceOffersSvc', '$filter', 'applicationsPrintoutsSvc',
    function(AbstractPolicyAndOfferHelper, simplePolicyHelper, OFFER_CONSTANTS, applicationsSvc, ihestiaRolesOnPolicyConstants, LsnOfferModelConstants, insuranceOffersSvc, $filter, applicationsPrintoutsSvc) {
      var IhestiaOfferHelper = function(offer) {

        var abstractHelper = new AbstractPolicyAndOfferHelper(offer);
        angular.extend(this, abstractHelper);

        /**
         * Model wniosku
         * @type {ApplicationModel}
         */
        this.offer = offer;

        this.plainPolicies = null;
        this.fullPolicies = null;

        /**
         * Zwracamy polisy z modelu oferty
         * w węźle pojazdów i lokalizacji mamy już prawdziwe obiekty, a nie same id
         * @param {boolean} plainObjects
         * @param {boolean} forceRefresh jeśli true, to przeliczamy wszystko jeszcze raz, jesli nie, to lecimy z cache
         * @return {array}       policies
         */
        this.getPolicies = function(plainObjects, forceRefresh) {
          var self = this;
          // próbujemy ładować z cache
          if (!forceRefresh) {
            if (plainObjects && self.plainPolicies !== null) {
              return self.plainPolicies;
            }
            if (!plainObjects && self.fullPolicies !== null) {
              return self.fullPolicies;
            }
          }

          // nie było nic w cache, więc przeliczamy wszystko
          var policies = [];
          angular.forEach(self.offer.get('policies', true, true), function(offerPolicy) {
            var policy = angular.copy(offerPolicy);

            // zbieramy obiekty pojazdów
            policy.vehicles = [];
            angular.forEach(offerPolicy.vehicles, function(vehicleId) {
              angular.forEach(self.offer.vehicles, function(vehicle) {
                if (parseInt(vehicle.metaData.id, 10) === vehicleId) {
                  if (plainObjects) {
                    policy.vehicles.push(vehicle.getData());
                  } else {
                    policy.vehicles.push(vehicle);
                  }
                }
              });
            });

            // zbieramy lokalizacje
            policy.estates = [];
            angular.forEach(offerPolicy.estates, function(estateId) {
              angular.forEach(self.offer.estates, function(estate) {
                if (estate.metaData.id === estateId) {
                  if (plainObjects) {
                    policy.estates.push(estate.getData());
                  } else {
                    policy.estates.push(estate);
                  }
                }
              });
            });

            policy.objects = policy.estates.concat(policy.vehicles);

            // ustawiamy głównego ubezpieczonego
            policy.mainInsured = self.getPolicyMainInsured(policy.id);
            if (plainObjects) {
              policy.mainInsured = policy.mainInsured.getData();
            }

            // ustalamy obiekt nagłówka polisy
            if (policy.objects.length > 0) {
              policy.mainObject = policy.objects[0];
            } else {
              policy.mainObject = policy.mainInsured;
            }

            // uzupełniamy składkę
            policy.premium = simplePolicyHelper.getPolicyPremium(offerPolicy);

            policies.push(policy);
          });

          // uzupełniamy nasz cache
          if (plainObjects) {
            self.plainPolicies = policies;
          } else {
            self.fullPolicies = policies;
          }

          return policies;
        };


        /**
         * Zwracamy polisy z modelu oferty v2
         * @param {boolean} plainObjects
         * @param {boolean} forceRefresh jeśli true, to przeliczamy wszystko jeszcze raz, jesli nie, to lecimy z cache
         * @return {array}       policies
         */
        this.getJupiterPolicies = function() {
          var policies = [];
          var self = this;

          angular.forEach(self.offer.get('policyPropositions', true, true), function(offerPolicy) {
            var policy = angular.copy(offerPolicy);
            policy.vehicles = self.getJupiterInsuredObjects(policy.risksRefs, 'vehicle');
            policy.estates = self.getJupiterInsuredObjects(policy.risksRefs, 'estate');

            policy.objects = policy.estates.concat(policy.vehicles);

            // ustawiamy głównego ubezpieczonego
            policy.mainInsured = self.getPolicyMainInsured(policy.metaData.id, true);

            // ustalamy obiekt nagłówka polisy
            if (policy.objects.length > 0) {
              policy.mainObject = policy.objects[0];
            } else {
              policy.mainObject = policy.mainInsured;
            }

            policies.push(policy);
          });

          return policies;
        };


        /**
         * Zwracamy głównego ubezpieczonego
         * @param  {string} policyId [description]
         * @return {PersonModel}
         */
        this.getPolicyMainInsured = function(policyId, isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var policy = this.getPolicyById(policyId, true, jupiterMode);
          var insuredRef = policy.insuredRef;
          if(jupiterMode) {
            insuredRef = policy.mainInsuredRef;
          }

          return this.getInsured(insuredRef);
        };


        /**
         * Pobieranie cesjonariuszy
         * @param  {string} policyId id polisy (np. E7)
         * @return {array}          obekty cesjonariuszy
         */
        this.getCessions = function(policyId, isJupiter) {
          var cessions = [];
          var self = this;
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var policy = self.getPolicyById(policyId, true, jupiterMode);

          if(jupiterMode) {
            cessions = self.getJupiterSuretyObjects(policy.suretyRefs);
          } else {
            angular.forEach(policy.suretyRef, function(suretyRef) {
              cessions.push(self.getInsured(suretyRef));
            });
          }

          return cessions;
        };



        /**
         * Zwraca prostą polisę z oferty po id (np. E7_C)
         * @param  {string} policyId id polisy
         * @return {object}
         */
        this.getPolicyById = function(policyId, simpleMode, isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var policies = null; // NOSONAR
          var policy = {};

          if(jupiterMode) {
            policies = this.offer.get('policyPropositions', true, true);
            angular.forEach(policies, function(currentPolicy) {
              if (currentPolicy.metaData.id === policyId) {
                policy = currentPolicy;
              }
            });
          } else {
            policies = simpleMode ? this.offer.get('policies', true, true) : this.getPolicies();
            angular.forEach(policies, function(currentPolicy) {
              if (currentPolicy.id === policyId) {
                policy = currentPolicy;
              }
            });
          }

          return policy;
        };


        /**
         * Pobieramy wszystkich ubezpieczonych
         * @param {boolean} withInsurer jeśli false, to nie uwzględniamy ubezpieczającego
         * @param  {string} policyId jeśli podane, to zawężamy listę osób do tych występujących na zadanej polisie
         * @param {bool} isJupiter
         * @return {PersonModel[]}          ubezpieczeni
         */
        this.parentGetAllInsuredPersons = this.getAllInsuredPersons;
        this.getAllInsuredPersons = function(withInsurer, objectId, policyId, isJupiter) {
          var self = this;
          var insured = self.parentGetAllInsuredPersons(withInsurer, objectId);
          if (policyId) {

            var policy = self.getPolicyById(policyId, true, isJupiter);

            if(isJupiter) {
              insured = self.getJupiterInsuredObjects(policy.risksRefs, 'person', withInsurer);
            } else {
              // zbieramy referencje osób na polisie
              var policyInsuredRefs = {};

              angular.forEach(policy.risks, function(risk) {
                angular.forEach(risk.insuredSubjectsRef, function(insuredSubjectsRef) {
                  policyInsuredRefs[insuredSubjectsRef.ref] = true;
                });
              });

              // wyrzucanie z naszej tablicy wszystkich osób, które nie występują na polisie
              var filteredInsured = [];
              angular.forEach(insured, function(currentInsured) {
                if (policyInsuredRefs[currentInsured.metaData.id]) {
                  filteredInsured.push(currentInsured);
                }
              });

              insured = filteredInsured;
            }
          }
          return insured;
        };


        /**
         * Metoda zwracająca listę obiektów na polisie - we wskazanym typie
         * w Jupiterze musimy przejść z referencji do ryzyk na polisie
         * potem z ryzyk mamy referencje do osób, pojazdów, nieruchomości
         *
         * @param {array} risksRefs - lista referencji do ryzyk
         * @param {string} objectType - jaki typ obiektów mamy przejrzeć w poszukiwaniu referencji
         * @param {boolean} withInsurer - czy uwzględniamy ubezpieczającego
         * @return {Model[]} ubezpieczeni lub pojazdy lub nieruchomości
         */
        this.getJupiterInsuredObjects = function(risksRefs, objectType, withInsurer) {
          var self = this;
          var insuredObjects = [];
          var riskRefsElement = null;
          var offersDataElementToGet = null;

          switch(objectType) {
            case 'person':
              riskRefsElement = 'personsRefs';
              offersDataElementToGet = 'persons';
              break;

            case 'vehicle':
              riskRefsElement = 'vehiclesRefs';
              offersDataElementToGet = 'vehicles';
              break;

            case 'estate':
              riskRefsElement = 'estatesRefs';
              offersDataElementToGet = 'estates';
              break;

            default:
              break;
          }

          angular.forEach(risksRefs, function(ref) {
            angular.forEach(self.offer.risks, function(risk){
              if (risk.metaData.id === ref) {

                angular.forEach(risk[riskRefsElement], function(riskEl) {
                  angular.forEach(self.offer[offersDataElementToGet], function(dataEl) {
                    if (dataEl.metaData.id === riskEl.ref
                      && (withInsurer !== false || (objectType === 'person' && dataEl.metaData.id !== self.offer.holderRef))) {
                      // jeśli przetwarzamy listę osób i nie mamy uwzględniać ubezpieczającego
                      // to dodatkowo sprawdzamy czy nasz person.refId != holderRef
                      insuredObjects.push(dataEl);
                    }
                  });
                });

              }
            });
          });

          return insuredObjects.unique();
        };

        /**
         * Metoda zwracająca listę organizacji - cesjonariuszy
         * w Jupiterze musimy przejść z referencji na polisie do listy organizacji na ofercie
         *
         * @param {array} suretyRefs - lista referencji do ryzyk
         * @return {Model[]} cesjonariusze - organizacje
         */
        this.getJupiterSuretyObjects = function(suretyRefs) {
          var self = this;
          var suretyObjects = [];

          angular.forEach(suretyRefs, function(ref) {
            angular.forEach(self.offer.organizations, function(organization){
              if (organization.metaData.id === ref) {
                suretyObjects.push(organization);
              }
            });
          });

          return suretyObjects.unique();
        };

        /**
         * Odrzucenie oferty
         * @return {object} promise
         */
        this.rejectOffer = function(showLastOfferWarning, isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var data = {};
          if (showLastOfferWarning === true) {
            data.doNotRemoveIfLastClientOffer = true;
          } else if (showLastOfferWarning === false) {
            data.doNotRemoveIfLastClientOffer = false;
          }

          if(jupiterMode) {
            return insuranceOffersSvc.ver('v2').post(this.offer.metaData.id, data, 'reject');
          } else {
            return applicationsSvc.put(this.offer.metaData.id, data, 'iofferRejected');
          }
        };

        /**
         * Akceptacja oferty (zatwierdzenie polisy)
         * @param {array} clauses - zaznaczone klauzule ([{ "code": "K1", "version": "1", "checked": true }])
         * @return {Promise}
         */
        this.acceptOffer = function(clauses, isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var data = {};
          if (clauses) {
            data.clauses = clauses;
          }

          if(jupiterMode){
            return insuranceOffersSvc.ver('v2').post(this.offer.metaData.id, data, 'accept');
          } else {
            return applicationsSvc.postPolicy(this.offer.metaData.id, data);
          }
        };

        /**
         * Zwracamy całą składkę za ofertę iHestyjną
         * @return {int} składka
         */
        this.getOfferPremium = function() {
          var premium = 0;
          var self = this;
          angular.forEach(self.getPolicies(true), function(policy) {
            angular.forEach(policy.risks, function(risk) {
              if (!(self.isOfferRO() && risk.isHistorical === true)) {
                premium = premium + parseInt(angular.isArray(risk.premium) ? risk.premium[0] : risk.premium, 10);
              }
            });
          });

          return premium;
        };


        /**
         * Pobieranie lisingodawcy
         * @return {array}          obekty lisingodawcy
         */
        this.getLeaser = function(policyId, isJupiter) {
          var self = this;
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var policy = self.getPolicyById(policyId, true, jupiterMode);
          var leaser = null;
          angular.forEach(policy.risks, function(risk) {
            angular.forEach(risk.insuredSubjectsRef, function(riskRef) {
              if (riskRef.groupSymbol === ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_LESSOR) {
                leaser = self.getInsured(riskRef.ref);
              }
            });

          });
          return leaser;
        };

        /**
         * Zwracamy obiekt lokalizacji po metaData.id
         * @param  {string} estateId id lokalizacji
         * @return {object}          obiekt lokalizacji
         */
        this.getEstateById = function(estateId) {
          var estate = {};
          angular.forEach(this.offer.estates, function(currentEstate) {
            if (currentEstate.metaData.id === estateId) {
              estate = currentEstate;
            }
          });
          return estate;
        };

        /**
         * Zwracamy obiekt pojazdu po metaData.id
         * @param  {string} vehicleId id pojazdu
         * @return {object} obiekt pojazdu
         */
        this.getVehicleById = function(vehicleId) {
          var vehicle = {};
          angular.forEach(this.offer.vehicles, function(currentVehicle) {
            if (currentVehicle.metaData.id === vehicleId) {
              vehicle = currentVehicle;
            }
          });
          return vehicle;
        };

        /**
         * Zwracamy obiekt osoby po metaData.id
         * @param  {string} personId id osoby
         * @return {object} obiekt osoby
         */
        this.getPersonById = function(personId) {
          var person = {};
          angular.forEach(this.offer.persons, function(currentPerson) {
            if (currentPerson.metaData.id === personId) {
              person = currentPerson;
            }
          });
          return person;
        };


        /**
         * Zwracamy informację o tym czy oferta jest po RO
         * (rozszerzenie ochrony)
         * @return {bool}
         */
        this.isOfferRO = function() {
          var extendedInsurance = false;
          if (angular.isDefined(this.offer.operationType) && this.offer.operationType === 'ExtendInsurance') {
            extendedInsurance = true;
          }

          return extendedInsurance;
        };

        /**
         * Zwracamy informację o tym czy oferta jest RA
         * (wznowienie agencyjne)
         * @return {bool}
         */
        this.isOfferRA = function(isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          return this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_AGENCY, jupiterMode);
        };

        /**
         * Zwracamy informację o tym czy oferta jest RCS
         * (wznowienie centralne ustawowe)
         * @return {bool}
         */
        this.isOfferRCS = function(isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          return this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_CENTRAL_STATUTORY, jupiterMode);
        };

        /**
         * Zwracamy informację o tym czy oferta jest RCLY
         * (wznowienie centralne – zakres ubiegłoroczny )
         * @return {bool}
         */
        this.isOfferRCLY = function(isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          return this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_CENTRAL_LAST_YEAR, jupiterMode);
        };

        /**
         * Zwracamy informację o tym czy oferta jest RCEXP
         * (wznowienie centralne – zakres rozszerzony )
         * @return {bool}
         */
        this.isOfferRCEXP = function(isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          return this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_CENTRAL_EXPANDED, jupiterMode);
        };

        /**
         * Sprawdzamy czy oferta jest ofertą wznowieniową o wskazanym typie
         * @param {string} typ do sprawdzenia
         * @param {bool} flaga mówiąca o tym czy to oferta iHestyjna czy Jupiterowa
         * @return {bool}
         */
        this.checkOfferExpandedType = function(offerTypeToCheck, isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var offerOfType = false;
          if(jupiterMode) {
            // na liście ofert mamy model OfferV2
            if (angular.isDefined(this.offer.renewalVariant) && LsnOfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[this.offer.renewalVariant] === offerTypeToCheck) {
              offerOfType = true;
            }
            // na szczegółach oferty mamy model ApplicationV2
            if (angular.isDefined(this.offer.policyPropositions) && this.offer.policyPropositions[0].product.dynamicValues.renewalVariant === offerTypeToCheck){
              offerOfType = true;
            }
          } else {
            if (angular.isDefined(this.offer.dynamicValues.renewalVariant) && this.offer.dynamicValues.renewalVariant === offerTypeToCheck) {
              offerOfType = true;
            }
          }

          return offerOfType;
        };

        /**
         * Zwraca uproszczoną nazwę rodzaju wznowienia dla ofert wznowieniowych wariantowych
         * @param {bool} flaga mówiąca o tym czy to oferta iHestyjna czy Jupiterowa
         * @return {string}
         */
        this.getOfferRenewalVariant = function(isJupiter) {
          var variant = '';
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;

          if(this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_CENTRAL_LAST_YEAR, jupiterMode)){
            // ubiegłoroczny
            variant = $filter('translate')('renewalOfferVariantLastYear', {componentCode: 'iKonto'});
          }

          if(this.checkOfferExpandedType(OFFER_CONSTANTS.OFFER_RENEWAL_TYPE_CENTRAL_EXPANDED, jupiterMode)){

            // na szczegółach oferty mamy model ApplicationV2
            if(angular.isDefined(this.offer.policyPropositions)) {
              if(jupiterMode && angular.isDefined(this.offer.policyPropositions[0].product.dynamicValues._isRenewalVariantBasic)) {
                if(this.offer.policyPropositions[0].product.dynamicValues._isRenewalVariantBasic) {
                  // podstawowy
                  variant = $filter('translate')('renewalOfferVariantBasic', {componentCode: 'iKonto'});
                } else {
                  // rozszerzony
                  variant = $filter('translate')('renewalOfferVariantExtended', {componentCode: 'iKonto'});
                }
              }
            } else {
              // na liście iOfert Jupiterowych
              if(angular.isDefined(this.offer.isBasicRenewalVariant) && this.offer.isBasicRenewalVariant === true) {
                // podstawowy
                variant = $filter('translate')('renewalOfferVariantBasic', {componentCode: 'iKonto'});
              } else {
                // rozszerzony
                variant = $filter('translate')('renewalOfferVariantExtended', {componentCode: 'iKonto'});
              }
            }

            //@TODO dodać obsługę dla ofert nie Jupiterowych - na razie nie ma takich w iHestii ale być może będą
          }

          return variant;
        };

        /**
         * Zwracamy współwłaścicieli na pojazdach - dla Jupitera
         * wszystkie osoby które są na ryzykach w roli 'COOWNER'
         *
         * @return {Array}
         */
        this.getJupiterAllVehicleCoowners = function(){
          var self = this;
          var coowners = [];
          var offerPolicies = self.offer.get('policyPropositions', true, true);
          var offerRisks = self.offer.risks;
          var offerPersons = self.offer.persons;

          angular.forEach(offerPolicies, function(policy) {
            angular.forEach(policy.risksRefs, function(policyRiskRef) {
              angular.forEach(offerRisks, function(risk){
                if (risk.metaData.id === policyRiskRef) {
                  angular.forEach(risk.personsRefs, function(riskPerson) {
                    if(riskPerson.role === 'COOWNER') {
                      angular.forEach(offerPersons, function(person) {
                        if (person.metaData.id === riskPerson.ref) {
                          coowners.push(person);
                        }
                      });
                    }
                  });
                }
              });
            });
          });

          return coowners.unique();
        };

        /**
         * Get distributor about file for iOffer - IOD
         * returns frp token
         */
        this.getOfferIODFile = function() {
          return applicationsSvc.get(this.offer.metaData.id, null, 'distributorAboutFile', null, null, {
            allowedStatuses: [409]
          });
        };

        /**
         * Get Key Informations Documents for iOffer - KID
         * returns file
         */
        this.getOfferKIDFile = function(isJupiter) {
          var jupiterMode = angular.isDefined(isJupiter) ? isJupiter : false;
          var queryParams = 'kid';

          if(jupiterMode && this.offer.generalAgreementsNumbers.length===1){
            queryParams = 'kid/'+encodeURIComponent(this.offer.generalAgreementsNumbers[0]);
          }

          var params = {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'blob'
          };

          return applicationsPrintoutsSvc.get(this.offer.metaData.id, null, queryParams, null, null, params);
        };

        this.isOfferDemandPayment = function() {
          if(this.offer.isDemandPayment) {
            return true; // porównanie ofert w v2
          } else if(this.offer.offerSubStatus && this.offer.offerSubStatus === 'DemandPayment') {
            return true; // oferta v2
          } else if(this.offer.dynamicValues && this.offer.dynamicValues.insuranceApplicationSubStatus === 'DemandPayment') {
            return true; // oferta v1 (application)
          } else {
            return false;
          }
        };

      };

      return IhestiaOfferHelper;
    }]);
