angular.module('ihestiaWidgets.policyOperationsPublic', ['ihestiaWidgets.policyOperations'])  //ihestiaWidgets.policyOperations
  .config(['$stateProvider',
    function config($stateProvider) {
        $stateProvider
        .state('main.vehiclePurchasePublic', {
          url: 'zakuppojazdu',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/vehiclePurchasePublic.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehiclePurchase', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_PURCHASE;
            }],
            resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
              return ihestiaRestDictionaryNationalitiesSvc.find('');
            }],
            resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
              return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
            }]
          }
        })
        .state('main.vehicleSalePublic', {
          url: 'sprzedazpojazdu',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/vehiclePurchasePublic.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_SALE;
            }],
            resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
              return ihestiaRestDictionaryNationalitiesSvc.find('');
            }],
            resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
              return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
            }]
          }
        })
        .state('main.vehiclePurchasePublicStep2', {
          url: 'zakuppojazdu/krok2',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/step2/vehiclePurchasePublicStep2.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehiclePurchase', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_PURCHASE;
            }],
            resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
              return ihestiaRestDictionaryNationalitiesSvc.find('');
            }],
            resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
              return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
            }],
            resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
              function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleSaleAgreement');
            }],
            resolvedWithdrawalAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
              function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
            }],
            resolvedNbkCountries: ['nbkDictionarySvc', function(nbkDictionarySvc){
              return nbkDictionarySvc.get('countries');
            }],
            clausesDictionary: ['ihestiaDictionaryHelper',
              function(ihestiaDictionaryHelper){
                return ihestiaDictionaryHelper.getDict('clauses');
            }]
          }
        })
        .state('main.vehicleSalePublicStep2', {
          url: 'sprzedazpojazdu/krok2',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/step2/vehiclePurchasePublicStep2.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_SALE;
            }],
            resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
              return ihestiaRestDictionaryNationalitiesSvc.find('');
            }],
            resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
              return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
            }],
            resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
              function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleSaleAgreement');
            }],
            resolvedWithdrawalAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
              function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
            }],
            resolvedNbkCountries: ['nbkDictionarySvc', function(nbkDictionarySvc){
              return nbkDictionarySvc.get('countries');
            }],
            clausesDictionary: ['ihestiaDictionaryHelper',
              function(ihestiaDictionaryHelper){
                return ihestiaDictionaryHelper.getDict('clauses');
            }]
          }
        })
        .state('main.vehicleSalePublicSummary', {
          url: 'sprzedazpojazdu/podsumowanie',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicSummaryCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/summary/vehiclePurchasePublicSummary.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_SALE;
            }]
          }
        })
        .state('main.vehiclePurchasePublicSummary', {
          url: 'zakuppojazdu/podsumowanie',
          views: {
            'content': {
              controller: 'vehiclePurchasePublicSummaryCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchasePublic/summary/vehiclePurchasePublicSummary.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehiclePurchase', {componentCode: 'PolicyOperations'}]
          },
          resolve: {
            operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_PURCHASE;
            }]
          }
        })
      ;
    }
  ]);