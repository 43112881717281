angular.module('lsnBase.models')
  .factory('LsnPolicyDocumentModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {

      var LsnPolicyDocumentModelV1 = function()
      {
        this.objectName = 'PolicyDocument';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData:
          {
            type: 'MetaData'
          },
          code:
          {
            type: 'string'
          },
          name:
          {
            type: 'string'
          },
          attached:
          {
            type: 'bool'
          },
          number:
          {
            type: 'int'
          },
          required:
          {
            type: 'bool'
          },
          requiredAttributes:
          {
            type: 'array',
            elementsType: 'string'
          }
        };

        this.code = null; // string - Kod dokumentu dla polisy.
        this.name = null; // string, Nazwa dokumentu dla polisy.
        this.number = null; // string, Numer dokumentu. Np. numer aneksu.
        this.attached = null; // bool, Informacja czy dokument został dołączony do polisy.
        this.required = null; // bool, Informacja czy dokument jest dokumentem wymaganym do polisy.
        this.requiredAttributes = null; // bool, Lista wymaganych atrybutów do opisu przesyłanego pliku w ramach danego typu dokumentu.
      };

      LsnPolicyDocumentModelV1.prototype = LsnAbstractModel.prototype;

      return LsnPolicyDocumentModelV1;
    }
  ]);