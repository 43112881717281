angular.module('ihestiaSsoBase')
  .factory('sessionKeeperSvc', ['IHestiaRestServiceAbstractDef', '$timeout', '$q',
    function(IHestiaRestServiceAbstractDef, $timeout, $q) {

      var SessionKeeper = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'keep';
        this.businessArea = 'session';
        this.port = '8215';
        this.sessionKeeper = true;

        this.numberOfTries = 10;
        this.tryAfterMillisecunds = 1000;

        this.queryResults = null;
        this.counter = 0;

        this.touch = function(callback){
          return self.put('', {name: 'touch'}, '', callback, callback);
        };

        this.tryTouch = function(){
          self.queryResults = $q.defer();
          self.counter = 0;

          self.tryTouchProcess();

          return self.queryResults.promise;
        };

        this.tryTouchProcess = function(){
          return self.put('', {name: 'touch'}, '').then(function(body) {
            self.queryResults.resolve(body);
          }, function() {
            if (self.counter < self.numberOfTries) {
              $timeout(function(){
                self.counter++;
                self.tryTouchProcess();
              }, self.tryAfterMillisecunds);
            }
          });          
        };
      };
      return new SessionKeeper();
    }
  ]);