angular.module('ihestiaWidgets.policyOperations')
  .factory('vehiclePurchaseHelper', ['ihestiaCommonErrorHandler',
    function(ihestiaCommonErrorHandler) {


      var VehiclePurchaseHelper = function() {

        var self = this;
        this.roles = {
          SELLER_OWNER: 'SellerOwner',
          SELLER_COOWNER: 'SellerCoowner',
          BUYER_OWNER: 'BuyerOwner',
          BUYER_COOWNER: 'BuyerCoowner'
        };

        this.typeToRoles = {
          buyer: [this.roles.BUYER_OWNER, this.roles.BUYER_COOWNER],
          seller: [this.roles.SELLER_OWNER, this.roles.SELLER_COOWNER]
        };

        this.flags = {
          prependOperation: false //czy weryfikacja ma ignorować duplikaty
        };
        this.policyData = {}; //dane polisy z której wchodzimy dla darowizny częsciowej
         // Dane zapisywane w policyData:
         //    policyNumber
         //    policyId
         //    vehicleId
         //    vehicleRegistrationNumber

        this.getOwnersData = function(verificationData)
        {
          var ownersData = {
            ownerNames: [],
            ownerName: '',
            ownersIds: []
          };

          angular.forEach(self.getPersonsByRole(self.roles.SELLER_OWNER, 0, verificationData), function(person) {
            if(person.name)
            {
              ownersData.ownerNames.push(person.name);
            }
            else
            {
              ownersData.ownerNames.push(person.firstName + ' ' + person.lastName);
            }
            ownersData.ownersIds.push(person.metaData.id);
          });

          angular.forEach(self.getPersonsByRole(self.roles.SELLER_COOWNER, 0, verificationData), function(person) {
            if(person.name)
            {
              ownersData.ownerNames.push(person.name);
            }
            else
            {
              ownersData.ownerNames.push(person.firstName + ' ' + person.lastName);
            }
            ownersData.ownersIds.push(person.metaData.id);
          });

          //nazwa dla selecta
          ownersData.ownerName = ownersData.ownerNames.join(', ');

          return ownersData;
        };

        /**
         * zwraca osoby z obeznego rekordu o podanej roli
         * @param  {[type]} role [description]
         * @return {array}
         */
        this.getPersonsByRole = function(role, recordNumber, verificationData)
        {
          var record;
          record = verificationData.vehicleOwnerChangeRecords[recordNumber];

          var persons = [];
          angular.forEach(record.partnerRoles, function(personRole, personId){
            if(personRole === role)
            {
              persons.push(self.getPersonById(personId, verificationData));
            }
          });

          return persons;
        };

        /**
         * zwraca osobę z wniosku o podanym id
         * @param  {string"int} personId
         * @return {Object}
         */
        this.getPersonById = function(personId, verificationData)
        {
          var clientFound = null;
          angular.forEach(verificationData.persons, function(person){
            if(person.metaData.id == personId) //eslint-disable-line
            {
              clientFound = person;
            }
          });
          angular.forEach(verificationData.organizations, function(organization){
            if(organization.metaData.id == personId) //eslint-disable-line
            {
              clientFound = organization;
            }
          });
          if(clientFound === null)
          {
            ihestiaCommonErrorHandler.throwException('Nie znaleziono osoby/orgaznizacji o podanym id ' + personId);
          }
          return clientFound;
        };

        /**
         * Czyścimy osoby z wniosku z obecnej iteracji
         * @param  {string} type buyer/owner czyścimy tylko wybranego typu
         */
        this.clearPersons = function(type, recordNumber, verificationData)
        {
          var record = verificationData.vehicleOwnerChangeRecords[recordNumber];

          angular.forEach(record.partnerRoles, function(partnerRole, partnerId){
            if(self.typeToRoles[type].indexOf(partnerRole) !== -1)
            {
              delete record.partnerRoles[partnerId];
              //usuwamy z listy osób
              self.deletePersonById(partnerId, verificationData);
            }
          });
        };

        /**
         * usuwamy osobę z wniosku
         */
        this.deletePersonById = function(partnerId, verificationData)
        {
          angular.forEach(verificationData.persons, function(person, personKey){
            if(person.metaData.id === partnerId)
            {
              verificationData.persons.splice(personKey, 1);
            }
          });

          angular.forEach(verificationData.organizations, function(organization, organizationKey){
            if(organization.metaData.id === partnerId)
            {
              verificationData.organizations.splice(organizationKey, 1);
            }
          });
        };

        /**
         * zbieramy pliki dla których nie było błedów
         * @return {array}
         */
        this.getGoodFiles = function(fileModels)
        {
          var goodFiles = [];
          angular.forEach(fileModels, function(fileModel){
            if(fileModel.fileIsUploadedWithoutErrors())
            {
              goodFiles.push(fileModel);
            }
          });
          return goodFiles;
        };

        /**
         * sprawdzamy po plikach czy jakiś nie ma błedu
         * @return {bool}
         */
        this.anyUploadErrors = function(fileModels)
        {
          var anyFileError = false;
          angular.forEach(fileModels, function(fileModel){
            if(!fileModel.fileIsUploadedWithoutErrors())
            {
              anyFileError = true;
            }
          });
          return anyFileError;
        };

      };

      return new VehiclePurchaseHelper();
    }]);