/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuClipboard')
  .factory('iHestiaJsApiSrv', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
      var IHestiaJsApiSrv = function() {

        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.basePattern = '{host}{port}{path}/jsapi{subResource}';
      };

      return new IHestiaJsApiSrv();
    }
  ]);