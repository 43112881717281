angular.module('ihestiaRestServicesBase')
  .factory('ihestiaOssSubjectSvc', ['IHestiaRestServiceAbstractDef', '$q',
    function(IHestiaRestServiceAbstractDef, $q) {

      var OssSubjectSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/subject/dbReports';
        this.businessArea = 'oss';
        this.port = '8282';

        
        /**
         * Obiekty podmiotów z nazwami
         * @type {Object}
         */
        this.subjectObjects = {};

        
        /**
         * Zwraca promise z obiektami podmiotów na podstawie symboli
         * @param  {array|string} subjectKeyStrings symbol/symbole biznesowe
         * @return {promise}
         */
        this.getSubjects = function(subjectKeyStrings) {
          if(angular.isString(subjectKeyStrings)) {
            subjectKeyStrings = [subjectKeyStrings];
          }

          /** Sprawdzamy, czy mamy już wszystko w cache */
          var resolvedObjects = self._retreiveSujectObjects(subjectKeyStrings);
          if(resolvedObjects !== null) {
            /** Jeśli mamy wszystko w cache, to zwracamy promise z tym co w nim jest */
            return $q(function(resolve){
              resolve(resolvedObjects);
            });
          } else {
            /** Jeśli czegokolwiek nie znaleźliśmy w cache, to odpytujemy usługę */
            return self.gridSearch(null, {
              subjectKeysStrings: subjectKeyStrings
            }).then(function(res){
              if(res && res.data && angular.isArray(res.data.items)) {
                angular.forEach(res.data.items, function(svcSubject){
                  self.subjectObjects[svcSubject.subjectKeyString] = svcSubject;
                });
              }
              return self._retreiveSujectObjects(subjectKeyStrings);
            }, lsnNg.noop);
          }
        };

        /**
         * Pobieramy dane obiektów z cache
         * @param  {array|string} subjectKeyStrings symbol/symbole biznesowe
         * @return {object|null} tablica asocjacyjna obiektów jeśli znaleziono wszystkie, lub null w przeciwnym wypadku
         */
        this._retreiveSujectObjects = function(subjectKeyStrings) {
          var resolvedSymbols = {};
          var allSymbolsResolved = true;
          angular.forEach(subjectKeyStrings, function(subjectKeyString){
            if(self.subjectObjects[subjectKeyString]) {
              resolvedSymbols[subjectKeyString] = self.subjectObjects[subjectKeyString];
            } else {
              allSymbolsResolved = false;
            }
          });

          if(allSymbolsResolved) {
            return resolvedSymbols;
          } else {
            return null;
          }
        };


      };
      return new OssSubjectSvc();
    }
  ]);