/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuUserBox')
  .factory('IhestiaMenuUserBoxMyPointsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {
      var IhestiaMenuUserBoxMyPointsSvc = function() {

        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'mypoints';
        this.businessArea = 'usercharacters';
      };

      return new IhestiaMenuUserBoxMyPointsSvc();
    }
  ]);