/**
 * Kontroler licznika zadań
 */
angular.module('ihestiaMenuBase')
.controller('taskCounterCtrl', ['$scope', 'taskCounterHelper', '$window', 'ihestiaConfigHelper', 'ihestiaSsoBaseInfoHelper',
  function($scope, taskCounterHelper, $window, ihestiaConfigHelper, ihestiaSsoBaseInfoHelper) {

    $scope.init = function() {
      $scope.tplData = taskCounterHelper.tplData;
    };

    $scope.goToChangedTasks = function() {

      var applicationTarget;
      var context = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;

      if (context === 'Central' || context === 'ProContactWorker')
      {
        applicationTarget = 'DASHBOARD_URL';
      }
      else
      {
        applicationTarget = 'AGENT_URL';
      }

      var url = ihestiaConfigHelper.getUrl(applicationTarget, 'external/tasks/mytasks/?with-changes=true');
      if (url) {
        $window.open(url);
      }
    };

    $scope.init();

  }
]);
