angular.module('salesPath2')
  .controller('todayOfferConfirmModalCtrl', ['$scope', 'summaryPageHelper', 'applicationHelper', 'sp2UiHelper', '$filter', 'CONFIG', 'RESOURCES', 'currentTimeSvc', '$interval', 'sp2CommonHelper',
    function($scope, summaryPageHelper, applicationHelper, sp2UiHelper, $filter, CONFIG, RESOURCES, currentTimeSvc, $interval, sp2CommonHelper) {
      $scope.modalSettings = summaryPageHelper.tplData.modalSettings.todayOfferConfirm;
      /**
       * promise for counter, that's checking the correctness of given time
       * @type {Promise}
       */
      $scope.countingPromise = null;
      /**
       * current time counted by interval function
       * @type {XDate}
       */
      $scope.currentTime = null;
      /**
       * entry time set after service response
       * @type {XDate}
       */
      $scope.entryTime = null;

      $scope.tplData = {
        time: $scope.dataContainer.protectionStartTime,
        minTime: $scope.dataContainerHelper.getTodayProtectionStartTime(),
        loadingData: false
      };

      $scope.spinnerOptions = {
        size: 'sm',
        label: ''
      };

      $scope.init = function() {
        $scope.modalSettings.okBtnDisabled = true;
        $scope.tplData.loadingData = true;
        currentTimeSvc.get().then(function(resp) {
          var ct = new XDate(resp.data.content.time * 1000),
            time = '';
          // set data for counter
          $scope.entryTime = new XDate(resp.data.content.time * 1000);
          $scope.currentTime = new XDate(resp.data.content.time * 1000);

          RESOURCES.CURRENT_TIME = ct.toString('HH:mm'); // first we're updating global RESOURCES time data
          ct.addMinutes(CONFIG.BEHAVIOR.todaysInsuranceExtraMinutes);
          time = ct.toString('HH:mm');
          // if svc slucceed, we overwrite current settings
          $scope.tplData.minTime = time; // in this case min time equals to proposed time
          $scope.tplData.time = time;
          $scope.tplData.loadingData = false;
          $scope.modalSettings.okBtnDisabled = false;
          $scope.countingPromise = $interval($scope.updateCurrentTime, 1000 * 60);
        }, function() {
          $scope.tplData.loadingData = false;
          $scope.modalSettings.okBtnDisabled = false;
        });
        $scope.fireListeners();
      };

      /**
       * sprawdza i poprawia godzinę
       */
      $scope.correctTime = function() {
        if (!angular.isString($scope.tplData.time) || $scope.tplData.time < $scope.tplData.minTime || $scope.tplData.time > '23:59') {
          $scope.tplData.time = $scope.tplData.minTime;
        }
      };

      /**
       * updates $scope.currentTime
       * @return {undefined}
       */
      $scope.updateCurrentTime = function() {
        var timeExp = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        $scope.currentTime.addMinutes(1);
        if (angular.isString($scope.tplData.time) && timeExp.test($scope.tplData.time)) {
          var timeData = $scope.tplData.time.split(':'), // splits string such as '23:33' to array [hour, minutes]
            inputTime = new XDate($scope.entryTime);
          inputTime.setHours(parseInt(timeData[0], 10));
          inputTime.setMinutes(parseInt(timeData[1], 10));
          if ($scope.currentTime.diffMinutes(inputTime) <= 1) { // if less than 1 minute left to spedified (entered) time, we close current modal. To set new time, modal have to be opened again.
            sp2CommonHelper.hideModal('todayOfferConfirmModal');
          }
        }
      };

      $scope.fireListeners = function() {
        // ok na modalu
        $scope.$on('iHestiaCommonModalOkBtnClicked.todayOfferConfirmModal', function() {
          $scope.dataContainer.protectionStartTime = $scope.tplData.time;
          if (angular.isFunction($scope.modalSettings.applyCallback)) {
            $scope.modalSettings.applyCallback();
          }
        });

        $scope.$on('$destroy', function handler() {
          if ($scope.countingPromise !== null) {
            $interval.cancel($scope.countingPromise);
          }
        });
      };

      $scope.init();
    }
  ]);