angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaAppConstants', ['ihestiaAgConstants', function(ihestiaAgConstants) {
    // stałe z ihestiaAgConstants ze względu na kompatybilność wstecz
    // nie ma czasu przerabiać wszystkich odwołań
    var AppConstants = {
      USER_TYPE_PD: ihestiaAgConstants.USER_TYPE_PD,
      USER_TYPE_CENTRAL: ihestiaAgConstants.USER_TYPE_CENTRAL,
      USER_TYPE_MS: ihestiaAgConstants.USER_TYPE_MS,
      USER_TYPE_AGENT: ihestiaAgConstants.USER_TYPE_AGENT,
      USER_TYPE_WORKER: ihestiaAgConstants.USER_TYPE_WORKER,
      SVC_CONTEXT: {},
      TYPE_CODE_JUPITER: 'JUPITER',
      JUPITERPLUS: 'JUPITERPLUS',
      TYPE_CODE_IHESTIA: 'IHESTIA',
      PLATFORM_CONTEXT_JUPITER: 'JUPITER',
      PLATFORM_CONTEXT_IHESTIA: 'IHESTIA',
      PLATFORM_CONTEXT_ALL: 'ALL'
    };

    AppConstants.SVC_CONTEXT[AppConstants.USER_TYPE_CENTRAL] = 'central';
    AppConstants.SVC_CONTEXT[AppConstants.USER_TYPE_PD] = 'pd';
    AppConstants.SVC_CONTEXT[AppConstants.USER_TYPE_MS] = 'ms';
    AppConstants.SVC_CONTEXT[AppConstants.USER_TYPE_AGENT] = 'agent';
    AppConstants.SVC_CONTEXT[AppConstants.USER_TYPE_WORKER] = 'worker';

    return AppConstants;
  }])
  .factory('ihestiaPzaCommonHelper', ['ihestiaPzaAppConstants', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'ihestiaAgHelper', 'ihestiaPlatformHelper', 'ihestiaConfigHelper',
    function(ihestiaPzaAppConstants, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, ihestiaAgHelper, ihestiaPlatformHelper, ihestiaConfigHelper) {

      var CommonHelper = function() {
        var self = this;

        /**
         * Ustawienia numeryków
         * @type {Object}
         */
        this.pzaNumericSettings = {
          amount: {
            formatting: true,
            parseToString: false,
            decimals: 0,
            min: 0,
            max: 50000000,
            decimalSeparator: ' '
          },
          amountMin: {
            formatting: true,
            parseToString: false,
            decimals: 0,
            max: 50000000,
            min: -50000000,
            decimalSeparator: ' '
          },
          int: {
            decimal: '',
            separator: '',
            negative: false,
            decimals: 0,
            absolute: true,
            parseToString: false,
            allowBeginZero: false
          },
          percentageMin: { // <-100, 100>
            min: -100,
            decimal: '',
            decimals: 0,
            separator: '',
            negative: false,
            absolute: true,
            parseToString: false,
            allowBeginZero: false
          },
          number: {
            decimal: '',
            separator: '',
            negative: false,
            absolute: true,
            allowBeginZero: true
          },
          percentageAbs: { // <0,100>
            formatting: false,
            parseToString: false,
            decimals: 0,
            min: 0,
            max: 100,
            decimalSeparator: ''
          },
          percentageHigh: { // <0,>
            formatting: false,
            parseToString: false,
            decimals: 0,
            min: 0,
            max: 50000000,
            decimalSeparator: ''
          }
        };

        /**
         * Zwraca rolę aktualnie zalogowanego użytkownika
         * @return {String} PD|CENTRAL|MS|.. ihestiaPzaAppConstants
         */
        this.getUserRole = function() {
          // przeniosłem całość do security, ale zostawiam przelotkę dla kompatybilności
          return ihestiaAgHelper.getUserRole();
        };

        /**
         * Ustawiamy w zadanym obiekcie sortBy i sortDirection.
         * Głównie odpowiada za poprawne ustawienie kierunku sortowania
         * @param {String} sortBy     kolumna, po której sortujemy
         * @param {object} sortParams obiekt, w którym trzeba ustawić standardowe sortBy/sortDirection
         *
         * @return {CommonHelper} self
         */
        this.setSortParams = function(sortBy, sortParams) {
          if (sortBy === sortParams.sortBy && sortParams.sortDirection === 'asc') {
            // jeśli już sortujemy po tym polu i sortowanie po asc, to odwracamy sortowanie
            sortParams.sortDirection = 'desc';
          } else {
            // w innym wypadku po prostu sortujemy asc
            sortParams.sortDirection = 'asc';
          }
          sortParams.sortBy = sortBy;

          return self;
        };

        /**
         * Oczyszczamy dane do filtrów do geta z pustych wartości
         * @param  {object} listParams obiekt z oryginalnymi parametrami
         * @return {object} wyczyszczone parametry
         */
        this.getListParams = function(listParams) {
          var cleanParams = {};
          angular.forEach(listParams, function(paramValue, paramKey) {
            if (paramValue !== null && paramValue !== '') {
              cleanParams[paramKey] = paramValue;
            }
          });

          return cleanParams;
        };

        /**
         * Zwraca context platformy aplikacji
         * (potrzebne do różnicowania filtrów itp. jupiter/iagent/pza)
         * @return {String} context z ihestiaPzaAppConstants.PLATFORM_CONTEXT_*
         */
        this.getPlatformContext = function(){
          if(ihestiaPlatformHelper.isJupiter()) {
            return ihestiaPzaAppConstants.PLATFORM_CONTEXT_JUPITER;
          } else if (ihestiaConfigHelper.get('rest', 'AP_CALLER') === 'pza') {
            return ihestiaPzaAppConstants.PLATFORM_CONTEXT_ALL;
          } else {
            return ihestiaPzaAppConstants.PLATFORM_CONTEXT_IHESTIA;
          }
        };

      };

      return new CommonHelper();
    }
  ]);