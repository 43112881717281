angular.module('lsnBase.validators')
.directive('valueEqual', function() {
  return {
    require: 'ngModel',
    link: function(scope, elem, attrs, model) {
      var otherValue = attrs.valueEqual;

      attrs.$observe('valueEqual', function(value) {
        otherValue = value;
        // Only compare values if the second ctrl has a value.
        if (angular.isDefined(model.$viewValue) && model.$viewValue !== '') {
          model.$setValidity('valueEqual', otherValue === model.$viewValue);
        }
      });

      model.$parsers.push(function(value) {
        // Mute the nxEqual error if the second ctrl is empty.
        if (angular.isUndefined(value) || value === '') {
          model.$setValidity('valueEqual', true);
          return value;
        }
        var isValid = value === otherValue;
        model.$setValidity('valueEqual', isValid);
        return value;
      });
    }
  };
});