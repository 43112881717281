angular.module('ihestiaCommonAdminMessage')
  .service('ihestiaCommonAdminMessageHelper', ['ihestiaCommonAdminMessageUsrListSvc', '$interval', 'ihestiaConfigHelper', 'ihestiaCommonConfirmModalHelper', 'ihestiaCommonAdminMessageMarkReadSvc', 'detectorUserActiveHelper', 'blockUiHelper', 'lsnConfirmModalHelper',
    function(ihestiaCommonAdminMessageUsrListSvc, $interval, ihestiaConfigHelper, ihestiaCommonConfirmModalHelper, ihestiaCommonAdminMessageMarkReadSvc, detectorUserActiveHelper, blockUiHelper, lsnConfirmModalHelper) {
      var AdminMessageHelper = function() {
        var self = this;

        this.defaultTimeut = 1000 * 60 * 5; //5 minut
        this.messageCheckLock = false;
        this.intervalPromise = null;
        this.messages = [];

        /**
         * Init
         * @return {[type]} [description]
         */
        this.init = function() {
          self.checkMessages();
          self.setIntervalMsgCheck();
        };

        /**
         * Ustawiamy interwałowe sprawdzanie wiadomości
         */
        this.setIntervalMsgCheck = function() {
          var configTimeout = ihestiaConfigHelper.get('commonDirectives', 'adminInfoUsrMsgCheckDelay'),
            timeout = configTimeout ? configTimeout : self.defaultTimeut;

          self.intervalPromise = $interval(function() {
            // sprawdzamy tylko wtedy, kiedy jest sens
            if (!self.messageCheckLock && detectorUserActiveHelper.userWasActive()) {
              self.checkMessages();
            }
          }, timeout);
        };

        /**
         * Sprawdzamy, czy są dla nas nowe wiadomości
         */
        this.checkMessages = function() {
          //jeżeli jesteśmy poza SSO to nie sprawdzamy.
          if(!ihestiaConfigHelper.get('rest', 'SESSION_OFF'))
          {
            self.messageCheckLock = true;
            ihestiaCommonAdminMessageUsrListSvc.getList(function(messages) {
              if (messages.length > 0) {
                self.messages = messages;
                self.showMessages();
              } else {
                self.messageCheckLock = false;
              }
            });
          }
        };

        /**
         * Pokazujemy wszystkie wiadomosci z kolejki
         * @return {[type]} [description]
         */
        this.showMessages = function() {
          self.messageCheckLock = true;
          if (self.messages.length > 0) {
            self.showMessage(self.messages[0]);
            self.messages.splice(0, 1);
          } else {
            self.messageCheckLock = false;
          }
        };

        /**
         * Wyswietla komunikat
         * @param  {ihestiaCommonAdminSystemMessageModel} message  [description]
         * @param  {boolean} viewMode jesli true, to klikniecie w potwierdz tylko zamyka okno
         */
        this.showMessage = function(message, viewMode) {
          viewMode = viewMode ? true : false;

          lsnConfirmModalHelper.showConfirmModal({
            title: 'Komunikat: ' + message.title,
            okBtnName: 'Potwierdź',
            cancelBtnName: '',
            content: message.body
          }).then(function(){
            if (!viewMode) {
              blockUiHelper.showBlockUi();
              ihestiaCommonAdminMessageMarkReadSvc.post(message.id).then(function(){
                self.showMessages();
              }, lsnNg.noop).finally(function(){
                blockUiHelper.hideBlockUi();
              });
            }
          }, lsnNg.noop);
        };
      };

      return new AdminMessageHelper();
    }
  ]);