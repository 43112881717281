angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi OSS Icorganizations
   * @return {[type]} [description]
   */
  .factory('ihestiaOssIcorganizationunitSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaIcorganizationunit = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/icorganizationunit';
        this.businessArea = 'oss';
        this.sortBy = 'symbol';
        this.pageStyle = false;
      };

    return new IHestiaIcorganizationunit();
  }])
  ;
