angular.module('lsnBase.models')
.factory('LsnPersonModelV1', ['LsnAbstractModel', 'lsnModelFactory', 'LsnPersonModelConstants',
  function(LsnAbstractModel, lsnModelFactory, LsnPersonModelConstants) {

    var LsnPersonModelV1 = function()
    {
      this.objectName = 'Person';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        metaData: {
          type: 'MetaData'
        },
        firstName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        secondName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        lastName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        motherName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        maidenName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        fatherName: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        gender: {
          type: 'string',
          dictionary: LsnPersonModelConstants.DICTIONARY_PERSON_GENDER
        },
        birthDate: {
          type: 'date'
        },
        birthPlace: {
          type: 'string'
        },
        deathDate: {
          type: 'date'
        },
        citizenshipCode: {
          type: 'string',
          defaultValue: 'PL'
        },
        nip: {
          type: 'string',
          filters: [
            {
              name: 'strReplace',
              params: {
                searchValue: /-/g,
                newValue: ''
              }
            }
          ]
        },
        regon: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        documents: {
          type: 'array',
          elementsType: 'IdentityDocument'
        },
        addresses: {
          type: 'array',
          elementsType: 'Address',
          filters: [
            {
              name: 'addressFilter'
            }
          ]
        },
        contacts: {
          type: 'array',
          elementsType: 'Contact',
          filters: [
            {
              name: 'contactFilter'
            }
          ]
        },
        accounts: {
          type: 'array',
          elementsType: 'BankAccount'
        },
        ekonto: {
          type: 'bool'
        },
        clauses: {
          type: 'Clauses'
        },
        customerId: {
          type: 'string'
        },
        customerStatus: {
          type: 'string'
        },
        dynamicValues: {
          type: 'dynamicValues'
        },
        pkd: {
          type: 'array',
          elementsType: 'string'
        }
      };

      this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
      this.firstName = null; // string, imię
      this.secondName = null; // string, drugie imię
      this.lastName = null; // string, nazwisko
      this.motherName = null; // string, imię matki
      this.maidenName = null; // string, nazwisko rodowe
      this.fatherName = null; // string, imię ojca
      this.gender = null; // string, płeć M/F
      this.birthDate = null; // date, data urodzenia
      this.birthPlace = null; // string, miejsce urodzenia
      this.deathDate = null; // date, data zgonu
      this.citizenshipCode = null; // string, Kod obywatelstwa (2 znaki tak jak kraj). Wartość domyślna: PL. Pole powinno być wypełniane tylko jeśli wartość jest inna.
      this.nip = null; // string, NIP
      this.regon = null; // string, REGON
      this.pesel = null; // string, PESEL
      this.documents = null; // Lista IdentityDocument , Kolekcja identyfikatorów dokumen-tów tożsamości
      this.addresses = null; // Lista Address, Kolekcja adresów
      this.contacts = null; // Lista Contact, Kolekcja kontaktów
      this.accounts = null; // Lista BankAccount, Lista kont bankowych klienta
      this.ekonto = null; // bool, Flaga informująca czy klient posiada eKonto. Jeśli tak to przyjmujemy, że mail do eKonta jest taki sam jak podany w danych kontaktowych. Jeśli nie został podany będzie wygenerowany błąd.
      this.clauses = null; // Clauses, Pole przechowuje informacje na temat zgód elektronicznych
      this.dynamicValues = null; // Lista atrybutów dotyczących osoby
      this.customerId = null; // id klienta z ikonta
      this.customerStatus = null; // status klienta z ikonta
      this.pkd = []; // kody pkd

      this.setDefaultAdditionalData();

    };

    LsnPersonModelV1.prototype = LsnAbstractModel.prototype;

    return LsnPersonModelV1;
  }]);