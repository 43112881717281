angular.module('ihestiaWidgets.commonOperations')
  .directive('commonOperationsOrganizationForm', ['ihestiaDictionaryHelper',
    function(ihestiaDictionaryHelper) {
      return {
        restrict: 'E',
        scope: {
          data: '=',
          showElements: '=?',
          customBindAttrs: '=?bindAttrs'
        },
        templateUrl: 'ihestia-widgets-templates-app/operations/common/organizationForm.tpl.html',
        link: function(scope) {
          scope.tplData = {
            countries: null,
            numericSettings: {
              regon: {
                allowLeadingZeros: true,
                decimals: 0,
                formatting: false,
                groupSeparator: '',
                min: 0,
                parseToString: true
              },
              nip: {
                allowLeadingZeros: true,
                decimals: 0,
                formatting: false,
                groupSeparator: '',
                min: 0,
                parseToString: true
              }
            },
            bindAttrs: {
              registrationCountry: 'code'
            },
            polishItem: {
              code: 'PL'
            }
          };

          scope.init = function() {
            // IHESTIABAS-5177 bind registration country to name attr from dictionary
            if (scope.customBindAttrs) {
              _.assign(scope.tplData.bindAttrs, scope.customBindAttrs);
            }
            ihestiaDictionaryHelper.getDictWithPromise('countries', {
              requestType: 'searches'
            }).then(function(countries) {
              scope.tplData.countries = countries;
              scope.tplData.polishItem = _.find(scope.tplData.countries, {code: 'PL'});
              if (!scope.data.registrationCountry) {
                scope.data.registrationCountry = scope.tplData.polishItem[scope.tplData.bindAttrs.registrationCountry];
              }
            });
          };

          scope.onCountryCodeChange = function() {
            scope.data.taxId = null;
            scope.data.statisticalId = null;
            scope.data.organizationName = null;
            scope.data.companyId = null;
          };

          scope.init();
        }
      };
    }]);
