/**
 * Helper do informowania o różnych cudach jak wygaśnięcie hasła
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase')
  .service('sessionToucherHelper', ['$rootScope', 'ihestiaConfigHelper', 'idpSessionSvc', 'ihestiaCrossTabHelper', 'ihestiaCommonErrorHandler', 'sessionKeeperSvc', 'lsnCookie', '$interval', '$window',
    function($rootScope, ihestiaConfigHelper, idpSessionSvc, ihestiaCrossTabHelper, ihestiaCommonErrorHandler, sessionKeeperSvc, lsnCookie, $interval, $window) {

      var SessionToucherHelper = function() {
        var self = this;

        this.timeSessionRefreshed = new XDate();
        var cookieName = ihestiaConfigHelper.get('cookieNames', 'TOUCH_SESSION');
        cookieName = cookieName ? cookieName : 'touchSession';
        var refreshUrl = ihestiaConfigHelper.get('ihsgDefines', 'SSO_REFRESH_URL');
        var refreshUrlPerApp = ihestiaConfigHelper.get('ihsgDefines', 'SSO_REFRESH_URL_PER_APP');
        var symbol = $window.location.origin;
        var cookiesSettings = {
          'domain': ihestiaConfigHelper.get('crossTab').ROOT_DOMAIN
        };
        var sessionRefreshInterval = ihestiaConfigHelper.get('ihsgDefines', 'SESSION_REFRESH_INTERVAL');
        var sessionHasExpired = false;
        var waitingForResponse = false;//informacja czy odpowiedz z uslugi zostala zwrocona
        /**
         * Init
         * @return {[type]} [description]
         */
        this.init = function() {
          var cookie = lsnCookie.get(cookieName),
            appData = {
              symbol: symbol,
              refreshUrlPerApp: refreshUrlPerApp
            };

          if (angular.isUndefined(cookie) || !angular.isArray(cookie)) {
            lsnCookie.set(cookieName, [appData], cookiesSettings);
          } else {
            var appAdded = false;
            angular.forEach(cookie, function(obj) {
              if (obj.symbol === symbol) {
                appAdded = true;
              }
            });
            if (!appAdded) {
              cookie.push(appData);
            }

            lsnCookie.set(cookieName, cookie, cookiesSettings);
          }

          self.fireListeners();
          self.refreshSession();
          self.setIntervalSessionRefresh();
          return true;
        };

        /**
         * Ustawia interwał odświeżający sesje w SSO
         */
        this.setIntervalSessionRefresh = function() {
          if (!sessionRefreshInterval) {
            ihestiaCommonErrorHandler.throwException({
              code: 'sessionRefreshIntervalNotSet',
              message: 'Session refresh interval is not set -> check app configuration!'
            });
          } else {
            self.intervalPromise = $interval(function() {
              // sprawdzamy tylko wtedy, kiedy jest sens
              if (!sessionHasExpired && !waitingForResponse) {
                self.checkSessionRefresh();
              }
            }, sessionRefreshInterval);
          }
        };

        /**
         * Sprawdzamy czy trzeba odświeżyć sesję w SSO
         */
        this.checkSessionRefresh = function() {
          if (self.timeSessionRefreshed.diffMilliseconds(new XDate()) > Math.ceil(sessionRefreshInterval * 9 / 10)) {
            self.refreshSession();
          }
        };

        /**
         * strzelamy do SSO w celu odświeżenia sesji
         */
        this.refreshSession = function() {
          if (!sessionHasExpired) {
            self.timeSessionRefreshed = new XDate();
            var timeSessionRefreshed = new XDate();

            waitingForResponse = true;
            sessionKeeperSvc.tryTouch().then(function() {
              waitingForResponse = false;

              idpSessionSvc.tryTouch(refreshUrl);

              var cookie = lsnCookie.get(cookieName);
              angular.forEach(cookie, function(data) {
                idpSessionSvc.tryTouch(data.refreshUrlPerApp);
              });

              //odswiezamy ciasteczko
              lsnCookie.set(cookieName, cookie, cookiesSettings);

              //informujemy inne zakladki ze juz odswiezono sesje
              ihestiaCrossTabHelper.sendMessage({
                code: 'idpSessionTouched',
                attrs: {
                  timeSessionRefreshed: timeSessionRefreshed.addSeconds(5).toISOString()
                }
              });

            }, angular.noop);
          }
        };

        /**
         * Różne eventy
         * @return {ihestiaSsoBaseInfoHelper} [description]
         */
        this.fireListeners = function() {
          $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
            if (data.code === 'idpSessionTouched') {
              self.timeSessionRefreshed = new XDate(data.attrs.timeSessionRefreshed);
            }
          });

          $rootScope.$on('iHestia.Session.Expired', function() { // eslint-disable-line angular/on-watch
            sessionHasExpired = true;
            if(self.intervalPromise){
              $interval.cancel(self.intervalPromise);
            }
          });

          return self;
        };
      };

      var helper = new SessionToucherHelper();
      return helper;
    }
  ])
  .run(['$rootScope', '$timeout', 'sessionToucherHelper', 'ihestiaConfigHelper',
    function($rootScope, $timeout, sessionToucherHelper, ihestiaConfigHelper) {
      if (!ihestiaConfigHelper.get('frontFlags', 'DISABLE_KEEP_SEESION') && ihestiaConfigHelper.get('serverData', 'AP-PROXY') !== 'OpenIdProxy') {
        var initDestroy = $rootScope.$on('commonModal.settingsInitialized.ihestiaCommonConfirmModal', function() {
          $timeout(function() {
            sessionToucherHelper.init();
          }, 0);
          initDestroy();
        });
      }
    }
  ]);