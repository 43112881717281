angular.module('ihestiaCommonDirectives')
  .factory('IHestiaOneListFileModel', ['ihestiaUploadModelHelper', function(ihestiaUploadModelHelper) {

    var IHestiaOneListFileModel = function() {
      var self = this;
      /**
       * Fizyczny plik do wysłania
       * @type {FILE}
       */
      this.file = null;

      /**
       * Data utworzenia komentarza
       * @type {String}
       */
      this.errorText = '';

      /**
       * status przesłanego pliku
       * @type {String}
       */
      this.status = null;

      /**
       * name nazwa pliku
       * @type {String}
       */
      this.name = null;

      /**
       * size rozmiar pliku
       * @type {String}
       */
      this.size = null;

      /**
       * id pliku na serwerze
       * @type {String}
       */
      this.fileServerId = null;

      this.sendingInfo = false;

      this.earlierUploadedFile = false;

      /**
       * nazwa typu dokumentu
       */
      this.documentTypeName = null;

      this.fileIsUploadedWithoutErrors = function() {
        return self.fileServerId !== null;
      };

      /**
       * funkcja sprawdza czy ten plik nie został jeszcze wysłany
       */
      this.fileToSend = function() {
        return self.status === null && !self.earlierUploadedFile;
      };

      this.hasError = function(){
        return self.status !== 'SUCCESS' && self.status !== null;
      };

      this.setStatusFile = function(status, errorText){
        ihestiaUploadModelHelper.setStatusFile(self, status, errorText);
      };

      this.convertToUploadedFile = function(status, errorText, fileServerId) {
        ihestiaUploadModelHelper.convertToUploadedFile(self, status, errorText, fileServerId);
      };

      this.setFileData = function(fileData) {
        ihestiaUploadModelHelper.setFileData(self, fileData);
      };

      this.setDocumentType = function(documentType) {
        ihestiaUploadModelHelper.setDocumentType(self.file, documentType);
      };

      this.inSendingInfo = function(){
        return self.sendingInfo;
      };

      this.getInfoAboutFile = function() {
        self.sendingInfo = true;
        return ihestiaUploadModelHelper.getInfoAboutFile(self.file);
      };

      this.setEarlierUploadedFile = function(uploadedFile){
        if (angular.isObject(uploadedFile)) {
          self.name = uploadedFile.name;
          self.size = parseInt(uploadedFile.size, 10);
          self.fileServerId = uploadedFile.fileServerId;
          self.earlierUploadedFile = true;
          if(angular.isDefined(uploadedFile.status)){
            self.status = uploadedFile.status;
          }
        }
      };
    };
    return IHestiaOneListFileModel;
  }]);