angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorChooseCorrespondenceTypeCtrl', ['$scope', 'ihestiaIkomunikatorChooseCorrespondenceTypeHelper', 'ihestiaIkomunikatorSearchMessagesCentralHelper', 'ihestiaIkomunikatorImportHelper',
    function($scope, ihestiaIkomunikatorChooseCorrespondenceTypeHelper, ihestiaIkomunikatorSearchMessagesCentralHelper, ihestiaIkomunikatorImportHelper) {
      $scope.showSearchPersonModal = function(){
        ihestiaIkomunikatorSearchMessagesCentralHelper.showModal();
        ihestiaIkomunikatorChooseCorrespondenceTypeHelper.hideModal();
      };

      $scope.showImportModal = function(){
        ihestiaIkomunikatorImportHelper.showModal();
        ihestiaIkomunikatorChooseCorrespondenceTypeHelper.hideModal();
      };

      $scope.init();
    }
  ]);