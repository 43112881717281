angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseTarrificationWithdrawalCtrl', ['$scope', 'ihestiaAutoUploadOneListUploadHelper', 'fileServerSvc', '$filter', '$alert', 'labelActionHelper',
    function($scope, ihestiaAutoUploadOneListUploadHelper, fileServerSvc, $filter, $alert, labelActionHelper) {

      $scope.uploaderWithdrawalName = 'policyOperationWithdrawal';

      $scope.fileModels = []; //musimy mieć niezależną kopie, bo inaczej listy uplodera nie łykną

      $scope.uploaderWithdrawalOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderWithdrawalName,
        hideQueueList: true
      };

      //upload plików
      $scope.documentType = $scope.fileWithdrawalAttributes;


      $scope.init = function(){
        labelActionHelper.registerAction('vehiclePurchaseTarrificationWithdrawalCtrl', $scope.onChangeLang);
        $scope.initUploader();
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderWithdrawalOptions.name, $scope.documentType);
      };

      $scope.onChangeLang = function()
      {
        //odświeżamy labelki
        $scope.uploaderOptions.messages.acceptFileTypes = $filter('translate')('Public.incorrectFileType');
        $scope.uploaderOptions.messages.maxFileSize = $filter('translate')('Public.toLargeFile');
      };

      $scope.deleteFile = function(id){
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderWithdrawalOptions.name, id);
      };

      $scope.initUploader = function() {
        var files = [];
        if($scope.verificationData && $scope.verificationData.files)
        {
          angular.forEach($scope.verificationData.files, function(file){
            if(file.fileType === 'PolicyTermination')
            {
              files.push(file);
            }
          });
        }


        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderWithdrawalOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          files, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileData.fileWithdrawalModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderWithdrawalOptions.name);
        $scope.fileModels = $scope.fileData.fileWithdrawalModels;
        $scope.fileData.fileWithdrawalModelsReady = true;
      };



    }
  ]);