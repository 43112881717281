angular.module('ihestiaWidgets.policyOperations')
  .controller('additionalInformationCtrl', ['$scope', 'ihestiaCommonConfirmModalHelper', '$filter',
    function($scope, ihestiaCommonConfirmModalHelper, $filter) {

      //zakup - dane sprzedającego

      $scope.additionalData = {
        onlyOneOwner: false
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = lsnNg.noop;

      /**
       * kliknięcie w checkbox wypowiedzenia
       */
      $scope.buyerWithdrawalChange = function()
      {
        var hasNextSale = false;
        if($scope.centralSpecialMode)
        {
          if($scope.verificationData.vehicleOwnerChangeRecords && $scope.currentRecordNumber < $scope.verificationData.vehicleOwnerChangeRecords.length - 1) //mamy już zdefiniowany kolejny rekord
          {
            hasNextSale = true;
          }
        }
        else if($scope.shared.nextSale)
        {
          //mamy zaznaczony checkbox kolejnej sprzedaży
          hasNextSale = true;
        }

        //próba zaznaczenia wypowiedzenia gdy mamy kolejną sprzedaż jest niemożliwa
        if($scope.currentRecord.buyerWithdrawal && hasNextSale)
        {
          var isSale = true;
          if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
          {
            isSale = false;
          }
          var textLabel;
          if($scope.centralSpecialMode)
          {
            //zarejestrowano
            if(isSale)
            {
              textLabel = 'nextSaleRegisteredWithdrawalNotSelectable';
            }
            else
            {
              textLabel = 'nextDonationRegisteredWithdrawalNotSelectable';
            }
          }
          else
          {
            //zaznaczono
            if(isSale)
            {
              textLabel = 'nextSaleSelectedWithdrawalNotSelectable';
            }
            else
            {
              textLabel = 'nextDonationSelectedWithdrawalNotSelectable';
            }
          }

          $scope.currentRecord.buyerWithdrawal = false;
          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('policyTermination.vehicleBuyerNotice', {componentCode: 'PolicyOperations'}),
            okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
            cancelBtnName: '',
            text: $filter('translate')(textLabel, {componentCode: 'PolicyOperations'}),
            size: 'sm',
            okBtnCallback: lsnNg.noop
          });
        }
      };

      /**
       * kliknięcie checkboxa 'Zmiana nr rejestracyjnego'
       */
      $scope.registrationNumberChangedClicked = function()
      {
        $scope.currentRecord.currentRegistrationNumber = '';
      };

    }
  ]);