angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorAdvancedSearchMessageModel', ['IKomunikatorAbstractModel', 'iKomunikatorConstants', 'ihestiaIkomunikatorUserTypeHelper',
    function(IKomunikatorAbstractModel, iKomunikatorConstants, ihestiaIkomunikatorUserTypeHelper) {

      var AdvancedSearchMessageModel = function() {
        IKomunikatorAbstractModel.apply(this, arguments);
        var self = this;

        /*eslint-disable camelcase */
        this.name = '';
        this.pesel = '';
        this.phone_number = '';
        this.phone_prefix = '+48';
        this.policy_id = '';
        this.claim_id = '';
        this.offer_id = '';
        this.start_date = '';
        this.end_date = '';
        this.contain_attachment = '';
        this.attachment_name = '';
        this.surname = '';
        this.originator_recipient = 'or';
        this.smsstatus = '';
        this.ra_number = '';
        this.login = '';
        this.firstname = '';
        this.idNumber = '';
        this.subject = -1;
        this.task_id = '';
        this.emailAddress = '';
        this.messageType = -1;
        this.contactRole = -1;
        this.document_number = '';
        this.agency_id = '';
        this.intermediary_name = '';

        //mopowanie głownych atrybutów do zaawansowanych filtrów
        this.mapAttr = {
          attachmentName: 'attachment_name',
          messageType: 'messageType',
          originatorRecipient: 'originatorRecipient',
          taskId: 'task_id',
          subject: 'subject'
        };

        /**
         * [getModelForRest pobiera obiekt do zaawansowanych filtrów]
         * @return {[type]} [description]
         */
        this.getModelForRest = function() {
          if (!ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()) {
            return {};
          }

          var data = {};
          angular.forEach(self.mapAttr, function(value, key) {
            if (value !== 'attachment_name' || self.contain_attachment) {
              data[key] = self[value];
            }
          });

          self.setPerson(data, 'originator');
          self.setPerson(data, 'recipient');
          self.setSubjectContextItem(data);
          self.setDate(data);

          //czyścimy wszytkie puste wartości
          self.clearEmptyValueFromObject(data);

          if (self.contain_attachment) {
            data.attachmentExists = true;
          } else {
            data.attachmentExists = null;
          }

          return data;
        };

        /**
         * [getModelForRest ustawia osoby w obiekcie filtrów do  zaawansowanych wyszukiwania]
         * @return {[type]} [description]
         */
        this.setPerson = function(data, role) {
          data[role] = {};
          data[role].contactRole = self.contactRole;
          data[role].identities = [];

          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CLIENT) {
            data[role].firstName = self.firstname;
            data[role].surName = self.surname;
            if (self.pesel) {
              data[role].identities.push({
                idNumber: self.pesel,
                type: 'pesel'
              });
            } 
            if (self.document_number) {
              data[role].identities.push({
                idNumber: self.document_number
              });
            }
            if (self.phone_number) {
              data[role].telephoneNumber = self.phone_prefix + self.phone_number;
            }
            data[role].emailAddress = self.emailAddress;
          }
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_WORKER) {
            data[role].intermediaryName = self.intermediary_name;
            data[role].firstName = self.firstname;
            data[role].surName = self.surname;
            
            if(self.ra_number){
              data[role].identities.push({
                idNumber: self.ra_number
              });
            }
            if(self.agency_id){
              data[role].identities.push({
                type : 'salesProfleKey',
                idNumber: self.agency_id
              });
            }
            if (self.phone_number) {
              data[role].telephoneNumber = self.phone_prefix + self.phone_number;
            }
            data[role].emailAddress = self.emailAddress;
          }
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE) {
            data[role].intermediaryName = self.intermediary_name;
            data[role].identities.push({
              idNumber: self.agency_id
            });
            if (self.phone_number) {
              data[role].telephoneNumber = self.phone_prefix + self.phone_number;
            }
            data[role].emailAddress = self.emailAddress;
          }
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CLAIMS_ADJUSTER) {
            data[role].firstName = self.firstname;
            data[role].surName = self.surname;
            data[role].identities.push({
              idNumber: self.login
            });
            if (self.phone_number) {
              data[role].telephoneNumber = self.phone_prefix + self.phone_number;
            }
            data[role].emailAddress = self.emailAddress;
          }
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CENTRAL) {
            data[role].firstName = self.firstname;
            data[role].surName = self.surname;
            data[role].identities.push({
              idNumber: self.login
            });
          }
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_OTHER || self.contactRole === iKomunikatorConstants.CONTACT_ROLE_FUNCTIONAL_MAILBOX) {
            data[role].identities.push({
              idNumber: self.name
            });
            data[role].emailAddress = self.emailAddress;
          }
          if(self.contactRole=== iKomunikatorConstants.CONTACT_ROLE_TASK_POOL){
            data[role].identities.push({
              idNumber: self.name
            });
          }

          //czyścimy wszytkie puste wartości
          self.clearEmptyValueFromObject(data[role]);
        };

        /**
         * [clearEmptyValueFromObject czysci puste wartosci]
         * @param  {[type]} obj [description]
         * @return {[type]}     [description]
         */
        this.clearEmptyValueFromObject = function(obj) {
          angular.forEach(obj, function(value, key) {
            if (value === null || value === '' || value === -1 || angular.equals(value, {})) {
              delete obj[key];
            }
          });
        };

        /**
         * [mapAttrFts mapuje dane z obiektu wyszukiwanie prostego]
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        this.mapAttrFts = function(data) {
          //nadpisujemy więc zerujemy wszystkie ustawienia
          self.clearAllData();

          self.contactRole = data.type;
          if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CLIENT) {
            self.surname = data.surname;
            self.firstname = data.firstname;
            self.pesel = data.pesel;
            self.idNumber = data.document_number;
            self.emailAddress = data.email;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_WORKER) {
            self.surname = data.surname;
            self.firstname = data.firstname;
            self.ra_number = data.ra_number;
            self.intermediary_name = data.intermediary_name;
            self.agency_id = data.agency_id;
            self.emailAddress = data.email;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE) {
            self.intermediary_name = data.intermediary_name;
            self.agency_id = data.agency_id;
            self.emailAddress = data.email;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CENTRAL) {
            self.surname = data.surname;
            self.firstname = data.firstname;
            self.login = data.login;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_OTHER || self.contactRole === iKomunikatorConstants.CONTACT_ROLE_FUNCTIONAL_MAILBOX) {
            self.name = data.name;
            self.emailAddress = data.email;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_TASK_POOL) {  
            self.name = data.name;

          } else if (self.contactRole === iKomunikatorConstants.CONTACT_ROLE_CLAIMS_ADJUSTER) {
            self.surname = data.surname;
            self.firstname = data.firstname;
            self.login = data.login;
            self.emailAddress = data.email;
          }
        };
        /*eslint-enable camelcase */

        /**
         * [clearAllData zeruje wszystkie atrybuty]
         * @return {[type]} [description]
         */
        this.clearAllData = function() {
          angular.forEach(this, function(propertyValue, propertyCode) {
            if(propertyCode !== 'mapAttr'){
              if(propertyCode === 'phone_prefix'){
                self[propertyCode] = '+48';
              }else if(propertyCode === 'originator_recipient'){
                self[propertyCode] = 'or';
              }else if(['subject', 'messageType', 'contactRole'].indexOf(propertyCode) !== -1){
                self[propertyCode] = -1;
              }else if (propertyValue && !angular.isFunction(propertyValue) && propertyCode !== 'originator_recipient') {
                self[propertyCode] = '';
              }
            }
          });
        };

        /**
         * [setSubjectContextItem ustawia dotyczy w obiekcie filtrów do  zaawansowanych wyszukiwania]
         * @param {[type]} data [description]
         */
        this.setSubjectContextItem = function(data) {
          if (self.policy_id) {
            data.contextItem = {
              subjectContextItemNumber: self.policy_id,
              subjectContextItemType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_POLICY
            };
          } else if (self.offer_id) {
            data.contextItem = {
              subjectContextItemNumber: self.offer_id,
              subjectContextItemType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_OFFER
            };
          } else if (self.claim_id) {
            data.contextItem = {
              subjectContextItemNumber: self.claim_id,
              subjectContextItemType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INJURY
            };
          }
        };

        /**
         * [setSubjectContextItem ustawia daty w obiekcie filtrów do zaawansowanych wyszukiwania]
         * @param {[type]} data [description]
         */
        this.setDate = function(data) {
          var startDate = new XDate(self.start_date);
          if (startDate.valid()) {
            var timezoneOffsetS = startDate.getTimezoneOffset() * 60 * 1000;
            data.dateFrom = (new XDate(startDate.getTime() - timezoneOffsetS)).toISOString();
            data.dateFrom = startDate.toString('yyyy-MM-dd') + 'T' + startDate.toString('HH:mm:ss');
          }

          var endDate = new XDate(self.end_date);
          if (endDate.valid()) {
            var timezoneOffsetE = endDate.getTimezoneOffset() * 60 * 1000;
            data.dateTo = (new XDate(endDate.getTime() - timezoneOffsetE)).toISOString();
            data.dateTo = endDate.toString('yyyy-MM-dd') + 'T' + endDate.toString('HH:mm:ss');
          }
        };

        /**
         * [setData ustawia dane z tego co przyszło w parametrach get]
         * @param {[type]} data [description]
         */

        /**
         * [setData description]
         * @param {[type]} data [description]
         * @return {[type]}     [czy zapisano jakikolwiek element]
         */
        this.setData = function(data) {
          var self = this,
            anyValueFromModelSet = false;

          angular.forEach(data, function(propertyValue, propertyCode) {
            if (!angular.isFunction(propertyValue) && angular.isDefined(self[propertyCode])) {
              self[propertyCode] = propertyValue;
              anyValueFromModelSet = true;
            }
          });

          return anyValueFromModelSet;
        };

        /**
         * [getAttributeToGet pobiera obiekt z atrybutami do przesłania poprzez GET]
         * @return {[type]} [description]
         */
        this.getAttributeToGet = function() {
          var data = angular.copy(self.toRest());
          delete data.mapAttr;

          angular.forEach(['phone_prefix', 'subject', 'messageType'], function(name) {
            if (data[name] === -1) {
              delete data[name];
            }
          });

          return data;
        };
      };

      return AdvancedSearchMessageModel;
    }
  ]);