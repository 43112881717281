/**
 * Dyrektywa pozwala na uruchamianie metod na elementach dom
 */
angular.module('lsnBase.directives')
  .directive('domExecutor', ['$parse', function($parse) {
    return {
      restrict: 'A',
      link: function($scope, iElm, iAttrs) {
        var api = {
          blur: function() {
            if (iElm.length > 0) {
              iElm[0].blur();
            }
          },
          focus: function() {
            if (iElm.length > 0) {
              iElm[0].focus();
            }
          },
          focusNext: function() {
            var $nextElement = iElm.next();
            if ($nextElement.length > 0) {
              $nextElement[0].focus();
            }
          },
          onEnter: lsnNg.noop,
          custom: function() {
            if (arguments.length > 0 && iElm.length > 0) {
              var args = [];
              var func = arguments[0];
              for (var i = 1; i < arguments.length - 1; i++) {
                args.push(arguments[i]);
              }
              iElm[0][func].apply(iElm[0], args);
            }
          },
          getElement: function(){
            return iElm[0];
          },
          scrollBottom: function() {
            iElm[0].scrollTop = iElm[0].scrollHeight;
          }
        };

        /** enter */
        iElm.on('keydown', function(event) {
          if (event.which === 13) {
            api.onEnter();
          }
        });

        $scope.$on('$destroy', function(){
          iElm.off('keydown');
        });

        $parse(iAttrs.domExecutor).assign($scope, api);

        /** odpalenie metody po utworzeniu api */
        if(iAttrs.domExecutorOnCreate) {
          $parse(iAttrs.domExecutorOnCreate)($scope, {
            $api: api
          });
        }
      }
    };
  }]);