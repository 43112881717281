angular.module('lsnBase.models')
.factory('LsnClausesModelV1', ['LsnAbstractModel', 'LsnClausesModelConstants',
  function(LsnAbstractModel, LsnClausesModelConstants) {

    var LsnClausesModelV1 = function()
    {
      this.objectName = 'Clauses';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        mark: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        elec: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k3: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k28: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k5: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k31b: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k32b: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        },
        k33b: {
          type: 'string',
          dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
        }
      };

      this.mark = null; // string, Wartość określa czy klient wyraził zgodę na przetwarzanie danych marketingowych
      this.elec = null; // string, Wartość określa czy klient wyraził zgodę na kontakt drogą elektroniczna
      this.k3 = null;
      this.k28 = null;
      this.k5 = LsnClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT; // domyślnie jest NO_AGREEMENT
      this.k31b = null;
      this.k32b = null;
      this.k33b = null;

    };

    LsnClausesModelV1.prototype = LsnAbstractModel.prototype;

    return LsnClausesModelV1;
  }]);