angular.module('salesPath2')
  .controller('discountsModalCtrl', ['$scope', '$filter', 'discountsHelper', 'CONSTANTS', '$timeout', 'PolicyDiscountsModel', 'appVariables',
    function($scope, $filter, discountsHelper, CONSTANTS, $timeout, PolicyDiscountsModel, appVariables) {
      $scope.modalData = discountsHelper.modalData;

      $scope.modalData.promoCode = null;
      $scope.cleanPolicyDiscountModel = new PolicyDiscountsModel();
      $scope.risksEnabled = false;
      $scope.showDiscountEmployeePopover = false;
      $scope.risksIsValid = true;

      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: false,
        allowBeginZero: true
      };

      $scope.numericSettingsWithNegative = {
        decimal: '',
        separator: '',
        negative: true,
        absolute: false,
        allowBeginZero: true
      };

      /**
       * Init
       */
      $scope.init = function() {
        if ($scope.modalData.dataCleared) {
          discountsHelper.loadData();
          $scope.enableRisksList();

          $scope.CONSENT_PREMIUM = CONSTANTS.CONSENT_PREMIUM;
          $scope.CONSENT_EXCEED = CONSTANTS.CONSENT_EXCEED;
        }
        $scope.fireListeners();
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // zapis danych
        $scope.$on('iHestiaCommonModalOkBtnClicked.discountsModal', function($event) {
          //jesli jestesmy w trybie dokupien to albo mozemy zmieniac cos na authorizationExceeded albo nigdzie
          if ($scope.modalData.authorizationExceeded.disabled && $scope.modalData.authorizationExceeded.disabled) {
            discountsHelper.clearModalData();
            return;
          }
          if (!$scope.isPromoCodeValid() || !$scope.isEmployeeDiscountValid() || !$scope.isDeviatedPremiumValid()) {
            $event.preventDefault();
            return false; //eslint-disable-line consistent-return
          }
          discountsHelper.save();
          discountsHelper.clearModalData();
        });

        // anulowanie danych
        $scope.$on('iHestiaCommonModalCancelBtnClicked.discountsModal', function() {
          discountsHelper.clearModalData();
        });

        if (!$scope.modalData.readonly) {
          // anulowanie danych
          $scope.$on('iHestiaCommonModalCancelBtnClicked.discountsModal', function() {
            discountsHelper.clearModalData();
          });

          //zmiany w lewym menu - znizki pracownicze
          $scope.$watch('modalData.policyDiscounts.employee.selected', function(isSelected) {
            if (!isSelected) {
              $scope.modalData.employee.selectedCode = null;
              $scope.showDiscountEmployeePopover = false;
            } else {
              $scope.selectTab('employee');
            }
          }, true);

          //zmiany w lewym menu - kod promocyjny
          $scope.$watch('modalData.policyDiscounts.promoCodes.selected', function(isSelected) {
            if (!isSelected) {
              $scope.deletePromoCode();
            } else {
              $scope.selectTab('promoCodes');
            }
          }, true);


          //zmiany w lewym menu - zgodna na odstępstwo od składki
          $scope.$watch('modalData.policyDiscounts.agreement_DeviatedPremium.selected', function(isSelected) {
            if (!isSelected) {
              $scope.modalData.policyDiscounts.agreement_DeviatedPremium = angular.copy($scope.cleanPolicyDiscountModel.agreement_DeviatedPremium); //eslint-disable-line camelcase
            } else {
              $scope.selectTab('deviatedPremium');
            }
          }, true);


          $scope.$watch('modalData.deviatedPremium.selectedType', function() {
            $scope.enableRisksList();
          }, true);
        }

        //zmiany w lewym menu - zgodna na przekroczenie pełnomocnictw
        $scope.$watch('modalData.policyDiscounts.agreement_AuthorizationExceeded.selected', function(isSelected) {
          if (!isSelected) {
            $scope.modalData.policyDiscounts.agreement_AuthorizationExceeded = angular.copy($scope.cleanPolicyDiscountModel.agreement_AuthorizationExceeded); //eslint-disable-line camelcase
            $scope.modalData.authorizationExceeded.selectedType = null;
            $scope.removeDecisionMaker(CONSTANTS.CONSENT_EXCEED);
          } else {
            $scope.selectTab('authorizationExceeded');
          }
        }, true);

        // wybrano decydenta
        $scope.$on('decisionMakerChosen', function($event, item, consentType) {
          $event.stopPropagation();
          if (consentType === CONSTANTS.CONSENT_PREMIUM) {
            if (!appVariables.isSupplement) {
              $scope.modalData.policyDiscounts.agreement_DeviatedPremium.attributes.deviatedPremiumDecisionMakerName = item.firstName + ' ' + item.name;
            }
          } else {
            if (!$scope.modalData.authorizationExceeded.disabled) {
              $scope.modalData.policyDiscounts.agreement_AuthorizationExceeded.attributes.authorizationExceededDecisionMakerName = item.firstName + ' ' + item.name;
            }
          }
        });
      };

      /**
       * Zmiana checkboxa ze zgodą na odstępstwo od składki
       * @return {undefined}
       */
      $scope.deviatedPremiumAgreementChanged = function() {
        if (!appVariables.isSupplement && !$scope.modalData.policyDiscounts.agreement_DeviatedPremium.selected) {
          $scope.clearRisk();
        }
      };

      /*
       * Sprawdź czy została wybrana zniżka dla pracownika
       */
      $scope.isEmployeeDiscountValid = function() {
        if ($scope.modalData.policyDiscounts.employee.selected && !$scope.modalData.employee.selectedCode) {
          $scope.selectTab('employee');
          $scope.showDiscountEmployeePopover = true;
          return false;
        } else {
          return true;
        }
      };

      /*
       * Sprawdź czy odstępstwo jest poprawnie uzupełnione
       */
      $scope.isDeviatedPremiumValid = function() {
        var isValid = true;
        var riskValue = null;
        angular.forEach($scope.modalData.risksList, function(risk) {
          if (risk.value) {
            riskValue = risk.value;
          }
        });

        if ($scope.risksEnabled === true && riskValue === null) {
          isValid = false;
          $scope.selectTab('deviatedPremium');
        }

        // validacja dla procentów ryzyk
        $scope.risksIsValid = isValid;

        return isValid;
      };

      /*
       * Uaktywnia listę ryzyk jeśli są ku temu warunki
       */
      $scope.enableRisksList = function() {
        $scope.risksEnabled = !angular.equals($scope.modalData.deviatedPremium.selectedType, {});
        if (!$scope.risksEnabled) {
          $scope.clearRisk();
        }
      };

      /**
       * Ryzyko zostało ozdnaczone, trzeba wyczyścić pola
       * @return {[type]} [description]
       */
      $scope.clearRisk = function() {
        $scope.modalData.deviatedPremium.selectedType = {};
        $scope.risksEnabled = false;
        $scope.removeDecisionMaker(CONSTANTS.CONSENT_PREMIUM);
        angular.forEach($scope.modalData.risksList, function(risk) {
          risk.value = null;
        });
      };

      /**
       * Zmieniła się podstawa zgody na odstępstwo od składki
       * @param  {String} type PROMO/NR_ZG/ZG_TEL
       */
      $scope.deviatedPremiumGrantBasisChanged = function(type) {

        if ($scope.modalData.deviatedPremium.selectedType[type]) {
          // nastąpiła zmiana, więc czyścimy nazwę decydenta
          $scope.removeDecisionMaker(CONSTANTS.CONSENT_PREMIUM);
          // zerowanie innych zgód
          angular.forEach($scope.modalData.deviatedPremium.selectedType, function(isSelected, code) {
            if (code !== type) {
              $scope.modalData.deviatedPremium.selectedType[code] = false;
            }
          });
          // ustawienie treści
          $scope.modalData.policyDiscounts.agreement_DeviatedPremium.attributes.agreement_DeviatedPremium_GrantBasis[0] = $scope.modalData.deviatedPremium.dict[type].BOSVAL;
          $scope.toggleDiscountGroup('agreement_DeviatedPremium', true);
        } else {
          $scope.modalData.policyDiscounts.agreement_DeviatedPremium.attributes.agreement_DeviatedPremium_GrantBasis[0] = $scope.modalData.deviatedPremium.dict.BRAK.BOSVAL;
          $scope.toggleDiscountGroup('agreement_DeviatedPremium', false);
        }

        // procenty na ryzykach
        $scope.modalData.deviatedPremium.grantBasisSelected = false;


        angular.forEach($scope.modalData.deviatedPremium.selectedType, function(isSelected) {
          if (isSelected) {
            $scope.modalData.deviatedPremium.grantBasisSelected = true;
          }
        });

        if (!$scope.modalData.deviatedPremium.grantBasisSelected) {
          // wszystko odznaczone, zerujemy zniżki
          angular.forEach($scope.modalData.risksList, function(risk) {
            risk.value = null;
          });
        }
      };

      /**
       * Zmieniła się podstawa zgody na przekroczenie pełnomocnictw
       * @param  {String} type NR_ZG/ZG_TEL
       */
      $scope.authorizationExceededGrantBasisChanged = function(type) {

        if ($scope.modalData.authorizationExceeded.selectedType[type]) {
          // nastąpiła zmiana, więc czyścimy nazwę decydenta
          $scope.removeDecisionMaker(CONSTANTS.CONSENT_EXCEED);
          // zerowanie innych zgód
          angular.forEach($scope.modalData.authorizationExceeded.selectedType, function(isSelected, code) {
            if (code !== type) {
              $scope.modalData.authorizationExceeded.selectedType[code] = false;
            }
          });
          // ustawienie treści
          $scope.modalData.policyDiscounts.agreement_AuthorizationExceeded.attributes.agreement_AuthorizationExceeded_GrantBasis[0] = $scope.modalData.authorizationExceeded.dict[type].BOSVAL;
          $scope.toggleDiscountGroup('agreement_AuthorizationExceeded', true);

        } else {
          $scope.modalData.policyDiscounts.agreement_AuthorizationExceeded.attributes.agreement_AuthorizationExceeded_GrantBasis[0] = $scope.modalData.authorizationExceeded.dict.BRAK.BOSVAL;
          $scope.toggleDiscountGroup('agreement_AuthorizationExceeded', false);
        }
      };

      /**
       * Usuwamy decydenta
       * @param  {string} type typ zgody
       */
      $scope.removeDecisionMaker = function(type) {
        if (type === CONSTANTS.CONSENT_PREMIUM) {
          if (!appVariables.isSupplement) {
            $scope.modalData.policyDiscounts.agreement_DeviatedPremium.attributes.deviatedPremiumDecisionMakerName = '';
          }
        } else {
          if (!$scope.modalData.authorizationExceeded.disabled) {
            $scope.modalData.policyDiscounts.agreement_AuthorizationExceeded.attributes.authorizationExceededDecisionMakerName = '';
          }
        }

        $scope.$broadcast('clearDecisionMaker');
      };

      /**
       * włącza/wyłącza (zaznaczenie) grupe znizek/odstepstw
       * @param  {String} group  grupa zniżek/odstepstw
       * @param  {Boolean} turnOn true - zaznacza grupe / false odznacza
       */
      $scope.toggleDiscountGroup = function(group, turnOn) {
        if (!$scope.modalData.readonly || (group === 'agreement_AuthorizationExceeded' && !$scope.modalData.authorizationExceeded.disabled)) {
          turnOn = (typeof turnOn !== 'boolean') ? true : turnOn; //eslint-disable-line angular/ng_typecheck_boolean
          $scope.modalData.policyDiscounts[group].selected = turnOn;

          if (group === 'employee') {
            $scope.showDiscountEmployeePopover = false;
          }
        }
      };

      /**
       * Przełącza taba
       */
      $scope.selectTab = function(tab) {
        $scope.modalData.selectedTab = tab;
      };

      /**
       * akcja zatwierdzania wprowadzonego kodu promocyjnego
       */
      $scope.applyPromoCode = function() {
        if (!appVariables.isSupplement) {
          var validCode = $scope.processPromoCode();
          if (validCode) {
            $scope.modalData.promoCodes.applied = true;
            $scope.modalData.promoCodes.error = false;
          }
        }
      };

      /**
       * przetwarza wprowadzony kod promocyjny
       * @return {Boolean} true jeśli poprawny kod
       */
      $scope.processPromoCode = function() {
        if (!angular.isString($scope.modalData.promoCodes.code)) {
          $scope.deletePromoCode();
          return false;
        }
        $scope.modalData.promoCodes.code.trim();
        if ($scope.modalData.promoCodes.code === '') {
          $scope.deletePromoCode();
          return false;
        }
        $scope.toggleDiscountGroup('promoCodes', true);
        return true;
      };

      /**
       * sprawdza poprawnosc kodu promocyjnego i ewentualnie aktwuje tab z kodem jesli niepoprawny
       * @return {Boolean} true gdy ok
       */
      $scope.isPromoCodeValid = function() {
        if (!$scope.modalData.policyDiscounts.promoCodes.selected) {
          $scope.modalData.promoCodes.error = false;
          return true;
        } else if (!$scope.modalData.promoCodes.applied && (!angular.isString($scope.modalData.promoCodes.code) || $scope.modalData.promoCodes.code === '')) {
          $scope.modalData.promoCodes.error = true;
          $scope.selectTab('promoCodes');
          return false;
        }
        return true;
      };
      /*
       * Usunięcie kodu promocyjnego
       */
      $scope.deletePromoCode = function() {
        if (!appVariables.isSupplement) {
          $scope.modalData.promoCodes.code = null;
          $scope.modalData.promoCodes.applied = false;
          $scope.modalData.promoCodes.error = false;
        }
      };

      $scope.validRisks = function() {
        $scope.risksIsValid = false;

        angular.forEach($scope.modalData.risksList, function(risk) {
          if (risk.value) {
            $scope.risksIsValid = true;
          }
        });
      };

      $scope.init();
    }
  ]);