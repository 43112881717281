angular.module('ihestiaWidgets.policyDetails')
  .factory('policyDocumentsModalHelper', [function(){

    var PolicyDocumentsModalHelper = function(){
      var self = this;
      this.policyId = null;
      this.policyNumber = null;
      this.documentsType = null;

      /**
       * Czyszczenie danych
       * @return {PolicyDocumentsModalHelper} self
       */
      this.clearData = function(){
        self.policyId = null;
        self.policyNumber = null;
        self.documentsType = null;

        return self;
      };
    };

    return new PolicyDocumentsModalHelper();
  }]);
