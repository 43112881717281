angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesSubjectsModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesSubjectsModelV1 = function() {
        this.objectName = 'ApplicationAllowedChangesSubjects';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
      
        this.fieldsProperties = {
          persons: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          },
          organizations: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          }
        };

        this.persons = null; // osoby
        this.organizations = null; // firmy
      };

      LsnApplicationAllowedChangesSubjectsModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesSubjectsModelV1;
    }
  ]);