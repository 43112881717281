angular.module('salesPath2')
  .controller('localizationAddModalCtrl', ['$scope', '$rootScope', 'sp2CommonHelper', 'RESOURCES', 'localizationModalHelper', 'CONSTANTS', '$state', 'CONFIG', 'dataContainerHelper',
    function($scope, $rootScope, sp2CommonHelper, RESOURCES, localizationModalHelper, CONSTANTS, $state, CONFIG, dataContainerHelper) {

      /**
       * Wybieramy typ lokalizacji
       * @param  {int} etxId [description]
       */
      $scope.chooseType = function(localizationType) {
        sp2CommonHelper.hideModal('localizationAddModal');
        localizationModalHelper.onLocalizationTypeChoosen(localizationType);
      };

      $scope.init = function() {
        $scope.fireListeners();

        $scope.localizationTypes = [];
        var selectedVariant = dataContainerHelper.getVariantsForProduct(CONSTANTS.PRODUCT_OGI);

        angular.forEach(RESOURCES.LOCALIZATION_TYPES.LIST, function(typeDef) {
          if (!typeDef.restrictProductsVariants || angular.equals({}, selectedVariant) || typeDef.restrictProductsVariants.indexOf(Object.keys(selectedVariant)[0]) !== -1) {
            $scope.localizationTypes.push(typeDef);
          }
        });
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalCancelBtnClicked.localizationAddModal', function() {
          if (!angular.isDefined($scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization])) {
            if (CONFIG.BEHAVIOR.isSingleProduct) {
              $state.go(CONSTANTS.PAGE_START_CLIENT_EDIT);
            } else {
              $state.go(CONSTANTS.PAGE_MATRIX);
            }
          }
        });
      };

      $scope.init();
    }
  ]);