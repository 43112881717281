angular.module('salesPath2')
  .directive('nonEtxMark', ['availabilityHelper', 'dictionaryServiceHelper',
    function(availabilityHelper, dictionaryServiceHelper) {
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else if (availabilityHelper.isAvailable('nonEtxMark')) {
            return 'salesPath2Template/autocompleters/vehicle/nonEtxMark/nonEtxMark.tpl.html';
          } else {
            return 'salesPath2Template/autocompleters/vehicle/nonEtxMark/nonEtxMarkWithoutDict.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

          $scope.$watch($attrs.errors, function(val) {

            if (!val || !val.vehicleMark) {
              $scope.hasError = false;
            } else {
              $scope.hasError = !!val.vehicleMark;
              $scope.invalidText = val.vehicleMark.message;
            }

          });

          if (availabilityHelper.isAvailable('nonEtxMark')) {

            $scope.getVehicleMark = function(query) {
              $scope.loadingElements = true;
              var obj = [];
              return dictionaryServiceHelper.getVehicleBrandsService().find({
                ProductCode: 'H1H7V_OBJECT_VEHICLE',
                registrationTypeCode: $scope.modalData.formData.dependencySelects.category[1],
                Value: query
              }).then(function(res) {
                if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                  obj = [];
                } else {
                  $scope.loadingElements = false;
                  if (angular.isDefined(res)) {
                    angular.forEach(res.data.items, function(item) {
                      obj.push(item);
                    });
                  }
                }
                return obj;
              }, angular.noop);
            };
          }
        }]
      };
    }
  ]);