angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionDiscountCodesCtrl', ['$scope', 'IhestiaPzaDiscountCodeModel',
    function($scope, IhestiaPzaDiscountCodeModel) {

      /**
       * Usuwamy wiersz z kodem
       * @param  {int} index index wiersza z pojazdem
       * @return {undefined}
       */
      $scope.removeDiscountCode = function(index) {
        $scope.conditionData.discountCodeList.splice(index, 1);
        $scope.validatorFieldsChanged();
      };

      /**
       * Dodajemy wiersz z kodem
       * @return {undefined}
       */
      $scope.addDiscountCode = function() {
        $scope.conditionData.discountCodeList.push((new IhestiaPzaDiscountCodeModel()).getData());
        $scope.validatorFieldsChanged();
      };
    }
  ]);