angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do pobrania startowej struktury przedstawicielstw/agencji/pracowników
   */
  .factory('ihestiaOssCompositeSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaOssComposite = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/compositeconfig';
        this.businessArea = 'oss';
        this.sortBy = 'symbol';
        this.pageStyle = false;
      };

    return new IHestiaOssComposite();
  }])
  ;
