angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaBeneficiaryFromRestMapper', ['LsnBeneficiaryModelConstants',
    function(LsnBeneficiaryModelConstants) {
      var BeneficiaryFromRestMapper = function() {
        /**
         * @typedef BeneficiaryRiskModel
         * @property {boolean} cessionary
         * @property {string} degreeOfKinship
         * @property {string} partnerRef
         * @property {number} shareRatio
         * @property {string} type
         * @property {string} subjectType
         * @property {string} subjectName
         * @property {string} subjectDetails
         * @property {string} subjectDataNonEditable
         */

        /**
         *
         * @param {object[]} risks
         * @param {object[]} subjects
         * @param {string[]} nonEditableSubjectIds
         * @return {BeneficiaryRiskModel}
         */
        this.mapBeneficiariesFromRisks = function(risks, subjects, nonEditableSubjectIds) {
          return _.chain(risks)
            .filter('beneficiaries')
            .flatMap(function(risk) {
              return _.map(risk.beneficiaries, function(beneficiary) {
                return {
                  beneficiary: beneficiary
                };
              });
            })
            .map(function(beneficiaryComposite) {
              var beneficiary = beneficiaryComposite.beneficiary;
              var subject = _.find(subjects, ['metaData.id', beneficiary.partnerRef]) || {};
              var isPerson = _.get(subject, 'metaData.className') === 'Person';
              var beneficiaryModel = {
                cessionary: beneficiary.cessionary,
                degreeOfKinship: beneficiary.degreeOfKinship,
                subjectClientId: beneficiary.partnerRef,
                shareRatio: beneficiary.shareRatio,
                type: beneficiary.type,
                subjectType: isPerson ? LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON : LsnBeneficiaryModelConstants.SUBJECT_TYPE_ORGANIZATION,
                subjectName: isPerson ? '{0} {1}'.format(subject.firstName, subject.lastName) : subject.name,
                subjectDetails: isPerson ? subject.birthDate : subject.regon,
                subjectDataNonEditable: _.includes(nonEditableSubjectIds, beneficiary.partnerRef)
              };
              return beneficiaryModel;
            })
            .uniqWith(function(arrVal, othVal) {
              return arrVal.subjectClientId === othVal.subjectClientId && arrVal.type === othVal.type;
            })
            .value();
        };
      };
      return new BeneficiaryFromRestMapper();
    }]
  );
