angular.module('salesPath2')
/**
 * helper rejestrowania i wykonywania akcji na ścieżce sprzedaży
 * UWAGA:
 * Każda akcja, mająca wpływ na wiele części aplikacji, powinna być wywoływana przez ten serwis
 */
  .service('actionHelper', ['sp2CommonHelper', '$timeout', 'navigationHelper', 'ActionHelperConfig', 'CONFIG',
    function(sp2CommonHelper, $timeout, navigationHelper, ActionHelperConfig, CONFIG) {
      var ActionHelper = function() {
        var self = this;
        /**
         * wsystkie możliwe akcje, jakie mogą zostać zarejestrowane i wywołane przez serwis
         * konwencja:
         * 'nazwa_akcji', //opis | zwracana wartość | miejsce wywołania
         * @type {Array}
         */
        this._availableActions = [
          'personAdded', // dodano osobę | {id: client_id_osoby, context: 'kontekst'} | personHelpe.save(), gdy nowy id osoby luv personAddModalCtrl.onSelectPerson()
          'personEdited', // wyedytowano osobę | {id: client_id_osoby, context: 'kontekst'} | personHelpe.save(), gdy osoba istnieje; sp2SelectionPopups.__hightRiskJobSelected()
          'personChangeCheckBM', // zmiany w osobie - powiadom BM | {oldPerson: personPreviousData, newPerson: person} | personHelpe.savePerson(), przy edycji istnejącej osoby
          'fireBurglarySumInsuredChanged', // zmiana SU dla ognia | undefined | fireBurglaryCtrl.suChanged()
          'vehicleAdded', // dodano pojazd | clientId pojazdu
          'vehicleEdited', // wyedytowano pojazd | clientId pojazdu
          'vehicleSelected', // wybrano pojazd
          'vehicleDeleted', // wybrano pojazd | clientId pojazdu
          'cancelAddPerson', // anulowano dodawanie osoby | {context: 'kontekst'} | personAddModalCtrl.$scope.$on('iHestiaCommonModalCancelBtnClicked.personAddModal'...
          'cancelEditPerson', // anulowano edycję osoby | {personId: clientId osoby, context: 'kontekst'} | personEditModalXtrl.$scope.$on('iHestiaCommonModalCancelBtnClicked.personEditModal'...
          'cancelSearchPerson', // anulowano wyszukiwanie osoby | {context: 'kontekst'} | personSearchModalCtrl.$scope.$on('iHestiaCommonModalCancelBtnClicked.personSearchModal'...
          'cancelEditGroup', // anulowano edycję grupy | {context: 'kontekst', groupId: clientId_grupy} | groupEditModalCtrl.$scope.$on('iHestiaCommonModalCancelBtnClicked.groupEditModal'
          'objectSelected', // wybrano globalnie obiekt(lub podmiot) | type, clientId | dataContainerHelper.selectObject()
          'tarifficationStarted', // rozpoczęto taryfikację(z/bez zapisu wniosku) | brak | applicationHelper._checkAndCallService(), .getPoliciesForApplication()
          'tarifficationEnded', // ukończono taryfikację(z/bez zapisu wniosku) | opcjonalnie {withError: true} -> wystapily bledy przy taryfikacji | applicationHelper.applConvertionFailedCallback(), .getPoliciesForApplication()
          'readOnlyChanged', // zmieniono tryb readOnly dla aplikacji (wartość można sprawdzić rowniez w appVariables.readOnly) | bool - true gdy readOnly włączony | applicationHelper._applicationLoadedCallback()
          'applicationRequestFailed', // wywołanie usługi dotyczącej wniosku zakończyło się niepowodzeniem | opcjonalnie {emailIOffer: true} -> dotyczy usługi iofertowania z wysyłką emailem | np. applicationHelper.applConvertionFailedCallback()
          'loadingPolicies', // rozpoczęto wczytywanie polis | brak | applicationHelper.checkSummary()
          'policiesReceived', // wczytano/pobrano z usługi polisy | brak | applicationHelper.checkSummary()
          'policiesAcceptingStarted', // rozpoczęto akceptowanie polis | brak | applicationHelper.convertApplicationToPolicies()
          'policiesAccepted', // zaakceptowano polisy | brak | applicationHelper.convertApplicationToPolicies()
          'policyModeHasBeenSet', // ustawiono tryb polisy | brak | applicationHelper.setPolicyMode()
          'policiesNoAccepted', // polisy nie zostały zaakceptowane | brak | applicationHelper.convertApplicationToPolicies()
          'afterPoliciesAccepted', // zakonczono akceptowanie polis - z błdami lub bez (then()) | brak | applicationHelper.convertApplicationToPolicies()
          'updateMessages', // zaktualizuj komunikaty. Zwraca tablicę komunikatóe (dokładny ich format w komentarzy metody) | [] | fromRestMessageMapper.mapMessages()
          'messagesUpdated', // messages has been updated | messagesHelper.messagesToShow[] | messagesHelper.updateMessages()
          'matrixProductSelected', // zaznaczono/odznaczono produkt na glownej matrycy | productCode, variantCode, select(bool) | sp2SelectionHelper.selectProductMatrix()
          'addDeleted', // usunieto/odznaczono dodatek | code, objectId, variant | addHelper.deleteAdd()
          'addAdded', // dodano/zaznaczono dodatek | code, objectId, variant | addHelper.deleteAdd()
          'extAdded', // usunięcie/odznaczenie rozszerzenia | code, objectId | extensionHelper.addExt()
          'extDeleted', // dodanie/zaznaczenie rozszerzenia | code, objectId | extensionHelper.deleteExt()
          'personGroupSelected', // wybrano (zaznaczono na matrycy głównej) osobę lub grupę | opcja {context: 'new'} | matrixPersonBoxCtrl, jeśli {context: 'new'}, to oznacza to dodanie zaznacenie nowododanej grupy lub osoby
          'personGroupDeleted', // usunięto z ubezpieczenia osobę lub grupę | undefined | matrixPersonBoxCtrl
          'groupAdded', // dodano/zapisano nową grupę | {id: clint_id_grupy, context: kontekst} | personGroupHelper.save()
          'groupEdited', // zapisano/wyedytowano istniejącą grupę | {id: clint_id_grupy, context: kontekst} | personGroupHelper.save()
          'personOczpDataChanged', // zmieniono dane OCZP osoby | personId - clientId osoby | oczpHelper.saveSpecializations()
          'oczpProtectionDatesChanged', // zmieniono daty ochrony na OCZP | brak | oczpHelper.setVariantDate()
          'personDeleted', // usunięcie osoby z ubezpieczenia | clientId osoby | oczpHelper.deleteInsured(), nnwHelper.deleteInsured()
          'personEntirelyDeleted', // usunięcie osoby z dataContainera | clientId osoby | personsCtrl.$scope.removePerson()
          'addIncognito', // wybrano opcję dodawania pracowników w formie bezimiennej | {context: kontekst wywołania} | personAddModalCtrl.addIncognito()
          'leaserSaved', // zapisano leasingodawcę
          'leaserDeleted', // usunięcie leasingodawcy
          'refreshAllInsured', // nalezy zaktulaizowac dane wszystkich ubezpieczonych w produkcie | productCode - kod produktu | sp2SelectionHelper.selectPersonVariant()
          'personVariantChanged', // zmieniono wariant ubezpieczenia dla osoby | kod_produktu, clientId_osoby | sp2SelectionHelper.selectPersonVariant(), sp2SelectionHelper.unselectPersonVariant()
          'saveApplication', // żądanie zapisu wniosku | undefined | dowolne miejsce w aplikacji
          'coownersChanged', // dodano/zmieniono współwłaściciela dla wybranego obiektu | objectType: lokalizacja_lub_pojazd, objectId: clientId_osoby
          'basketOpened', // rozwiniecie/otwarcie koszyka | undefined | sp2MenuBasketCtrl.showBasketContainer()
          'vehicleBmModalClosed', // wyjście z ekrau edycji danych BM
          'verifyBonusMalusInfo',
          'protectionDatesChanged', // zmieniono dowolne daty ochrony poprazez nagłówek | undefined | sp2HeaderHelper.updateProtectionDates()
          'applicationNumberEdited', // wyedytowano numer wniosku | undefined | kontroler menu headera
          'availableOperationsLoaded' // pobrano informację na temat dostępnych operacji na wniosku | undefined | applicationHelper.setAvailableOperations
        ];

        /**
         * akcje po kktórych wolamy zapis wniosku
         * @type {Array}
         */
        this._applicationSaveActions = [
          'saveApplication',
          'basketOpened',
          'applicationNumberEdited',
          'protectionDatesChanged',
          'vehicleDeleted',
          'coownersChanged',
          'leaserDeleted',
          'personEntirelyDeleted',
          'personGroupDeleted'
        ];

        /**
         * czy należy wowołać zapis wniosku
         * @type {String} tarifficate lub saveApplication
         */
        this.requestApplicationCall = null;

        /**
         * zarejestrowane helpery i nasłuchiwane przez nich akcje
         * nazwa_helpera => {
         *   instance: instancja_helpera
         *   actions: [akcja_1[,...]]
         * }
         * @type {Object}
         */
        this._registeredHelpers = {};

        /**
         * rejestruje helper do odbioru akcji
         * Po każdej akcji, actionHelper uruchomi metodę _afterAction na zarejestrowanym helperze z argumentami
         * (nazwa_akcji, arguments), gdzie arguments to wszystkie parametry wywołanej akcji (opcja)
         * @param  {String} helperName [description]
         * @param  {Object} instance instancja helpera, implementująca metodę _afterAction
         * @param  {Object} [params] dodatkowe parametry dla actionHelpera
         * Dostępne opcje:
         * states: ['nazwa_stanu_1'[,...]] - tablica ze stanami, dla których ma być wołany _afterAction w helperze. Jeśli nieokreślona, to wołamy dla każdego stanu
         * @param  {String[]} actions akcje
         */
        this.registerHelper = function(helperName, instance, actions, params) {
          if (angular.isUndefined(self._registeredHelpers[helperName])) {
            if (!angular.isFunction(instance._afterAction)) {
              sp2CommonHelper.throwException('Brak metody _afterAction w rejestrowanej klasie/helperze ' + helperName);
            }
            self._registeredHelpers[helperName] = {
              instance: instance,
              actions: [],
              states: (params && params.states) ? params.states : null
            };
          }
          angular.forEach(actions, function(name) {
            self._registeredHelpers[helperName].actions.push(name);
          });
        };

        /**
         * wyrejestrowuje helpera
         * @param  {String} helperName nazwa, pod którą helper został zrejestrowany
         * @return {Boolean} true gdy wyrejestrowano
         */
        this.unregisterHelper = function(helperName) {
          if (angular.isDefined(self._registeredHelpers[helperName])) {
            delete self._registeredHelpers[helperName];
            return true;
          }
          return false;
        };

        /**
         * uruchamia rejestrację metod na podstawie konfiga ActionHelperConfig
         * UWAGA:
         * 1. zostaną zarejestrowane metody tylko dla zarejestrowanych już wcześniej indtancji helperów (registerHelper()) i spełniające warunki podane w konfiguracji (configConditions)
         * 2. metody są dopisywane do już istniejących "actions" zarejstrowaneog helpera (o ile nie isnitaly wczesniej) a states są nadpisywane
         * @return {undefined}
         */
        this.registerByConfig = function() {
          var regData = null;
          angular.forEach(self._registeredHelpers, function(helperRegData, helper) {
            regData = ActionHelperConfig[helper];
            if (angular.isObject(regData)) {
              if (!self._areConditionsFulfilled(regData)) {
                return true;
              }
              angular.forEach(regData.methods, function(name) {
                if (helperRegData.actions.indexOf(name) === -1) {
                  self._registeredHelpers[helper].actions.push(name);
                }
              });
              //jesli podano stany, to też je uzupelniamy w danych rejestracyjnych helpera
              if (angular.isArray(regData.states)) {
                self._registeredHelpers[helper].states = regData.states;
              }
            }
            return true;
          });
        };

        /**
         * wyrejestrowuje/usuwa z self._registeredHelpers wszustkie akcje(metody helperów) i związane z nimi states
         * @return {undefined}
         */
        this.unregisterAllActions = function() {
          angular.forEach(self._registeredHelpers, function(data) {
            data.actions = [];
            data.states = null;
          });
        };

        /**
         * czy spełniony jest warunek z konfiguracji sprzedażówki
         * @param  {String[]} regData ['scieżka_do_ustawienia', wartosc_ustawienia], gdzie scieżka_do_ustawienia to rozdzielone '.' (kropką) kolejne poziomy CONFIGa
         * @return {Boolean} true gdy warunek spełniony
         */
        this._areConditionsFulfilled = function(regData) {
          if (!angular.isArray(regData.configCondition) || regData.configCondition.length === 0) {
            return true; //gdy brak warunków, to uznajemy że są spełnione
          }
          var settingPath = regData.configCondition[0].split('.'),
            settingValue = regData.configCondition[1];
          var element = CONFIG,
            lastFieldName = null;
          lsnNg.forEach(settingPath, function(fieldNameElement, k) { //iterujemy wgłąb drzewa obiektu
            lastFieldName = fieldNameElement;
            if (k === settingPath.length - 1 || angular.isUndefined(element[fieldNameElement])) {
              return false;
            }
            element = element[fieldNameElement];
            return true;
          });
          if (angular.isUndefined(element[lastFieldName])) {
            return false;
          }
          return element[lastFieldName] === settingValue;
        };

        /**
         * uruchamia metodę _afterAction na zarejestrowanych helperach
         * @param {String} actionName nawa wykonanej akcji
         * @param {*} pozostałe argumenty metody
         */
        this.runAction = function(actionName) {
          var currState = navigationHelper.getCurrentState().name;
          if (self._applicationSaveActions.indexOf(actionName) !== -1) {
            self.requestApplicationCall = 'saveApplication';
          } else if (actionName === 'tarifficate') {
            self.requestApplicationCall = (self.requestApplicationCall !== 'saveApplication') ? 'tarifficate' : 'saveApplication'; //zapis ma wyzszy priorytet niz taryfikacja
          }
          if (['tarifficate', 'saveApplication'].indexOf(actionName) === -1) {
            var actionArguments = Array.prototype.slice.call(arguments, 0);
            angular.forEach(self._registeredHelpers, function(helperData) {
              angular.forEach(helperData.actions, function(helperAction) {
                if (actionName === helperAction && (!helperData.states || helperData.states.indexOf(currState) !== -1)) {
                  try {
                    helperData.instance._afterAction.apply(this, actionArguments);
                  } catch (exc) {
                    sp2CommonHelper.throwException(exc);
                  }
                }
              });
            });
          }
          self.checkApplicationCall();
        };

        /**
         * ewentualne wywolanie zapisu/taryfikacji wniosku
         */
        this.checkApplicationCall = function() {
          if (self.requestApplicationCall !== null) {
            $timeout(function() {
              if (self.requestApplicationCall === 'tarifficate') {
                self._registeredHelpers.applicationHelper.instance.tarifficate();
              } else if (self.requestApplicationCall === 'saveApplication') {
                self._registeredHelpers.applicationHelper.instance.saveApplication();
              }
              self.requestApplicationCall = null;
            }, 0);
          }
        };

      };
      return new ActionHelper();
    }
  ]);