angular.module('ihestiaSsoBase.constants')
  .provider('IhestiaEmployeeTypeConstants', [function() {

    this.ULD = 'Uld';
    this.DP = 'Dp';
    this.PCA = 'Pca';
    this.ADP = 'Adp';
    this.POMS = 'Poms';
    this.AUWR = 'Auwr';
    this.UWR = 'Uwr';
    this.MS = 'Ms';

    /**
     * Magic get, zwraca stałe
     * @return {Function}
     */
    this.$get = [function() {
      return this;
    }];
  }]);
