angular.module('salesPath2')
  .service('matrixLocaliationBoxHelper', ['localizationHelper',
    function(localizationHelper) {
      var MatrixLocaliationBoxHelper = function() {
        var self = this;
        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          localizations: [] //lista pojazdów
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'localizationEdited':
            case 'localizationAdded':
            case 'localizationDeleted':
            case 'localizationSelected':
              self.refreshLocalizations();
              break;
            default:
              break;
          }
        };

        /**
         * aktualizuje listę podmiotów
         */
        this.refreshLocalizations = function() {
          var settings = {  
            showName: true,
            elements: [
              {field: 'city'},
              {field: 'street'},
              {field: 'house', fieldSeparator: ''},
              {field: 'apartment', prefix: '/', lastInLine: true}
            ],
            maxLength: 20
          };

          self.tplData.localizations = localizationHelper.getLocaliations(settings);
        };
      };

      return new MatrixLocaliationBoxHelper();
    }
  ])
  .run(['matrixLocaliationBoxHelper', 'actionHelper',
    function(matrixLocaliationBoxHelper, actionHelper) {
      actionHelper.registerHelper('matrixLocaliationBoxHelper', matrixLocaliationBoxHelper);
    }
  ]);