angular.module('salesPath2.spd')
.factory('spdAllRisks', [ function() {
    var allRisks = {
      attributes: {
        '_premiumForDisplay': ['Simple', 'Decimal'], //skladka do wyswietlenia w GUI
        '_tariffPremiumForDisplay': ['Simple', 'Decimal'], //skladka taryfowa do wyswietlenia w GUI
        '_premiumAfterLoyaltyBonusForDisplay': ['Simple', 'Decimal'], //skladka po znizkach klienckich do wyswietlenia w GUI
        '_groupId': ['Simple', 'String'] //id powiązanej z ryzykiem grupy
      }
    };

    return allRisks;
  }])
;