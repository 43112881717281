angular.module('spaceChat.common')
  .factory('spaceChatHelper', ['spaceChatSvc', 'spaceChatConsultantSvc', 'spaceChatAgentSvc', '$q', 'spaceChatManagerSvc', 'spaceChatUploadSvc', '$timeout', '$rootScope', 'ihestiaConfigHelper', 'lsnCookie', 'ihestiaSsoBaseInfoHelper',
    function(spaceChatSvc, spaceChatConsultantSvc, spaceChatAgentSvc, $q, spaceChatManagerSvc, spaceChatUploadSvc, $timeout, $rootScope, ihestiaConfigHelper, lsnCookie, ihestiaSsoBaseInfoHelper) {

      var SpaceChatHelper = function() {
        var self = this;

        this.longPollTimeoutAfter = 30 * 1000; // po 30s timeout, bo usługa nie powinna, więcej jak 15s czekać

        this.cookieRoomName = ihestiaConfigHelper.get('cookieNames', 'CHAT_WAS_OPENED');
        this.cookieRoomName = this.cookieRoomName ? this.cookieRoomName : 'chat_was_opened';

        this.cookieTextName = 'chat_temp_text'; //ciasteczko w których zapisujemy niewysłaną treść z textarea
        this.cookieConfig = {
          'domain': ihestiaConfigHelper.get('crossTab').ROOT_DOMAIN
        };

        this.eventsMap = {
          'MESSAGE_CHAT_OPEN': {text: 'Rozpoczęcie rozmowy.'},
          'MESSAGE_CONSULTANT_JOIN': {text: 'Konsultant dołączył do rozmowy.'},
          'MESSAGE_CHAT_CLOSE': {text: 'Rozmowa została zakończona.'}
        };

        this.longPollAbortPromise = null;
        this.longPollTimeout = null;

        this.consultantLongPollAbortPromise = null;
        this.consultantLongPollTimeout = null;

        this.messagesForAgent = [''];
        this.longPollCallback = null;

        //po inicjalizacji controlera chatu tu ląduje metoda która go odpala
        this.startAgentChatMethod = null;
        this.additionalEventParserMethod = null;

        this.onlineTimeout = 35;

        this.retryPollTime = 1000; // jak mamy problem z połączeniem to czekamy tyle przed kolejnym strzałem

        this.abortedByUser = false;

        /**
         * ustawiamy ciastko z informacją o otwartym chacie
         */
        this.setCookieRoom = function(text)
        {
          lsnCookie.set(self.cookieRoomName, text, self.cookieConfig);
        };

        this.getCookieRoom = function()
        {
          return lsnCookie.get(self.cookieRoomName);
        };

        /**
         * ustawiamy ciastko z treścią z textarea
         */
        this.setCookieText = function(text)
        {
          var cookieObject = {
            text: text,
            loginHash: SparkMD5.hash(ihestiaSsoBaseInfoHelper.getCurrentUser().login)
          };
          lsnCookie.set(self.cookieTextName, angular.toJson(cookieObject), self.cookieConfig);
        };

        this.getCookieText = function()
        {
          var cookieJsonString = lsnCookie.get(self.cookieTextName);
          if(cookieJsonString)
          {
            var cookieObject = angular.fromJson(cookieJsonString);
            if(cookieObject.loginHash && cookieObject.loginHash === SparkMD5.hash(ihestiaSsoBaseInfoHelper.getCurrentUser().login))
            {
              return cookieObject.text;
            }
            else
            {
              return '';
            }
          }
          else
          {
            return '';
          }
        };

        this.startAgentChat = function()
        {
          if(angular.isFunction(self.startAgentChatMethod))
          {
            self.startAgentChatMethod();
          }
        };

        this.isAgent = function()
        {
          return true;
        };

        /**
         * dołączenie przez agenta
         * @return {[type]} [description]
         */
        this.agentJoinRoom = function (agentUserInfo) {
          return spaceChatSvc.get('join').then(function(response){
            var chatData = response.data;
            self.prepareChat(chatData, agentUserInfo);
            return chatData;
          }, angular.noop);
        };

        /**
         * zapujemy dane z usera SSO na taki format jaki przychodzi z usługi
         * @return {[type]} [description]
         */
        this.prepareUserFromSso = function(userInfo)
        {
          var userData = {
            first_name: userInfo.firstName, //eslint-disable-line
            last_name: userInfo.name //eslint-disable-line
          };

          return userData;
        };

        /**
         * przygotowujemy dane usera itp
         */
        this.prepareChat = function(chatData, agentUserInfo, params)
        {
          if(!params)
          {
            params = {};
          }

          self.prepareMessages(chatData.messages, chatData, params);
          //dane usera z pierwszej wiadomości to dane agenta
          if(chatData.messages && chatData.chat.opened_by)
          {
            chatData.agent = chatData.chat.opened_by;
          }
          else if(chatData.messages && chatData.chat.opened_by_id)
          {
            angular.forEach(chatData.messages, function(message){
              if(message && message.user && message.user.id && message.user.id === chatData.chat.opened_by_id)
              {
                chatData.agent = message.user;
              }
            });
          }
          if(chatData.agent)
          {
            chatData.agentInitials = self.getUserInitials(chatData.agent);
          }

          if(!chatData.consultant && chatData.chat && chatData.chat.consultant)
          {
            //do tej pory consultant brany był z messages, ale nie zawsze w najnowszych będzie, więc jeśli nie to szukamy w danych chata z join'a
            chatData.consultant = chatData.chat.consultant;
          }

          if(!params.isHistory)
          {
            //zliczamy liczbę screenshotów żeby wiedzieć na załadowanie ilu musimy zaczekać
           var initialImagesCount = 0;
           angular.forEach(chatData.messages, function(event){
            if(event.type === 'SCREENSHOT')
            {
              initialImagesCount = initialImagesCount + 1;
            }
           });
           chatData.initialImagesCount = initialImagesCount;
           chatData.initialImagesLoaded = 0;
          }
        };

        this.consultantJoinRoom = function(threadId)
        {
          return spaceChatSvc.post(threadId + '/join').then(function(response){
            var chatData = response.data;
            self.prepareMessages(chatData.messages, chatData);
            return chatData;
          }, angular.noop);
        };

        /**
         * @param  {array} messages
         * @param  {object} chatData przekazujemy żeby dołożyć do chatu dodatkowe info
         */
        this.prepareMessages = function(messages, chatData, params)
        {
          angular.forEach(messages, function(message){
            self.prepareMessage(message, chatData, params);
          });
        };

        /**
         * chyba zwraca aktywne chaty
         */
        this.consultantInit = function()
        {
          return spaceChatConsultantSvc.get('init', null, null, null, self.rejectCallback, {allowedStatuses: [403]}).then(function(response){
            var chatsData;
            if(self.isResponseSuccess(response))
            {
              chatsData = response.data;
              angular.forEach(chatsData.chats, function(chatData){
                self.prepareChat(chatData);
              });
            }
            else
            {
              chatsData = {
                chats: []
              };
            }
            return chatsData;
          }, angular.noop);
        };

        this.agentStartLongPoll = function(chatData, firstPoll, isRetry)
        {
          if(firstPoll)
          {
            self.abortedByUser = false;
            self.longPollAbortPromise = $q.defer();
          }

          // nie zawsze przeglądarka przerwie strzał w rozsądnym czasie (czasem trwa to dużo więcej niż minutę)
          // więc z poziomu js przerywamy request
          self.longPollTimeout = $timeout(function(){
            if(self.longPollAbortPromise)
            {
              // console.log('jsTimeout'); //eslint-disable-line
              self.longPollAbortPromise.resolve('jsTimeout');
              self.longPollAbortPromise = $q.defer();
            }
          }, self.longPollTimeoutAfter);


          return spaceChatSvc.get(chatData.chat.thread_id + '/' + chatData.current_index, null , null,
            function (response) {
              if(self.longPollTimeout) {
                // console.log('resolve jsTimeout cancel'); //eslint-disable-line
                $timeout.cancel(self.longPollTimeout); // anulujemy timeout
                self.longPollTimeout = null;
              }
              if(response && response.data && angular.isArray(response.data))
              {
                var messages = response.data;
                self.prepareMessages(messages, chatData, {source: 'agentLongPoll'});
                self.clearTempMessages(chatData, messages);
                if(messages && messages.length > 0)
                {
                  chatData.messages = chatData.messages.concat(messages);
                  self.inform({code: 'newEvent', chatId: chatData.chat.id});
                }
                if(chatData.messages && chatData.messages.length > 0 && chatData.messages[chatData.messages.length - 1].id)
                {
                  chatData.current_index = chatData.messages[chatData.messages.length - 1].id; //eslint-disable-line
                }

                self.agentStartLongPoll(chatData);
              }
              else if(!self.abortedByUser)
              {
                if(!isRetry)
                {
                  self.agentStartLongPoll(chatData, firstPoll, true);
                }
                else
                {
                  $timeout(function(){
                    self.agentStartLongPoll(chatData, firstPoll, true);
                  }, self.retryPollTime);
                }
              }
            }, function(){
              if(self.longPollTimeout) {
                // console.log('reject jsTimeout cancel'); //eslint-disable-line
                $timeout.cancel(self.longPollTimeout); // anulujemy timeout
                self.longPollTimeout = null;
              }
              // console.log('reject'); //eslint-disable-line
              // console.log(reject); //eslint-disable-line
              if(!self.abortedByUser)
              {
                if(!isRetry)
                {
                  self.agentStartLongPoll(chatData, firstPoll, true);
                }
                else
                {
                  $timeout(function(){
                    self.agentStartLongPoll(chatData, firstPoll, true);
                  }, self.retryPollTime);
                }
              }
            },
            {
              timeout: self.longPollAbortPromise.promise.then(angular.noop, angular.noop) //abort na promise
            });
        };

        this.consultantStartLongPoll = function(currentIndex, activeChatList, isRetry)
        {
          self.consultantLongPollAbortPromise = $q.defer();
          self.consultantLongPollTimeout = $timeout(function(){
            if(self.consultantLongPollAbortPromise)
            {
              // console.log('jsTimeout'); //eslint-disable-line
              self.consultantLongPollAbortPromise.resolve('jsTimeout');
              self.consultantLongPollAbortPromise = $q.defer();
            }
          }, self.longPollTimeoutAfter);
          return spaceChatConsultantSvc.get(currentIndex, null, null, function (response) {
              if(self.consultantLongPollTimeout) {
                // console.log('resolve jsTimeout cancel'); //eslint-disable-line
                $timeout.cancel(self.consultantLongPollTimeout); // anulujemy timeout
                self.consultantLongPollTimeout = null;
              }
              if(response && response.data && angular.isArray(response.data))
              {
                var events = response.data;
                //mamy listę eventów i każdy traktujemy indywidualnie
                angular.forEach(events, function(event){
                  self.parseEvent(event, activeChatList);
                  currentIndex = event.id; //ostatni index
                });
                self.consultantStartLongPoll(currentIndex, activeChatList);
              }
              else if(!self.abortedByUser)
              {
                if(!isRetry)
                {
                  self.consultantStartLongPoll(currentIndex, activeChatList, true);
                }
                else
                {
                  $timeout(function(){
                    self.consultantStartLongPoll(currentIndex, activeChatList, true);
                  }, self.retryPollTime);
                }
              }
            }, function(){
              if(self.consultantLongPollTimeout) {
                // console.log('resolve jsTimeout cancel'); //eslint-disable-line
                $timeout.cancel(self.consultantLongPollTimeout); // anulujemy timeout
                self.consultantLongPollTimeout = null;
              }
              if(!self.abortedByUser)
              {
                if(!isRetry)
                {
                  self.consultantStartLongPoll(currentIndex, activeChatList, true);
                }
                else
                {
                  $timeout(function(){
                    self.consultantStartLongPoll(currentIndex, activeChatList, true);
                  }, self.retryPollTime);
                }
              }
            },
            {
              timeout: self.consultantLongPollAbortPromise.promise.then(angular.noop, angular.noop) //abort na promise
            });
        };

        this.inform = function(whatHappened)
        {
          if(angular.isFunction(self.longPollCallback))
          {
            self.longPollCallback(whatHappened);
          }
        };

        /**
         * sprawdzenie czy nie mamy tymczasowej wiadomości której id dostaliśmy najpierw z longPoll, a dopiero później z zapisu
         * @return {[type]} [description]
         */
        this.clearLateTempMessages = function(chatData)
        {
          var lastEventId = null;
          //najpierw szukamy id najpóźniejszego eventu z longPolla
          for (var i = chatData.messages.length - 1; i >= 0; i--) {
            if(chatData.messages[i].id && !chatData.messages[i].temp && chatData.messages[i].type !== 'ONLINE') {
              lastEventId = chatData.messages[i].id;
              i = -1; //break loop
            }
          }
          if(lastEventId !== null)
          {
            var messagesToDeleteKeys = [];
            angular.forEach(chatData.messages, function(storedMessage, storedMessageKey){
              if(storedMessage.temp
                && storedMessage.id <= lastEventId
                && messagesToDeleteKeys.indexOf(storedMessageKey) === -1
                )
              {
                messagesToDeleteKeys.unshift(storedMessageKey);
              }
            });

            angular.forEach(messagesToDeleteKeys, function(messageToDeleteKey){
              chatData.messages.splice(messageToDeleteKey, 1);
            });
          }
        };

        /**
         * czyścimy tymczasowe message z chatData które przyszły w pollu (messages)
         */
        this.clearTempMessages = function(chatData, newMessages)
        {
          var messagesToDeleteKeys = [];
          angular.forEach(newMessages, function(newMessage){
            angular.forEach(chatData.messages, function(storedMessage, storedMessageKey){
              if(storedMessage.temp
                && storedMessage.id === newMessage.id
                && newMessage.type !== 'ONLINE'
                && messagesToDeleteKeys.indexOf(storedMessageKey) === -1
                )
              {
                messagesToDeleteKeys.unshift(storedMessageKey);
              }
            });
          });

          angular.forEach(messagesToDeleteKeys, function(messageToDeleteKey){
            chatData.messages.splice(messageToDeleteKey, 1);
          });
        };

        this.parseEvent = function(event, activeChatList)
        {
          //tu trafia tylko konsultant z longPoola
          if(angular.isFunction(self.additionalEventParserMethod))
          {
            self.additionalEventParserMethod(event);
          }

          var chatForEvent = null,
           chatForEventKey = null;
          //szukamy chata dla tego eventa
          //jeśli to nie jest join event i nie znajdziemy takie chatu to ignorujemy
          angular.forEach(activeChatList, function(activeChat, activeChatKey){
            if(activeChat.chat.id === event.chat_id)
            {
              chatForEvent = activeChat;
              chatForEventKey = activeChatKey;
            }
          });

          if(event.type === 'CONSULTANT_JOIN')
          {
            var newChat = event.chat_init_message;
            self.prepareChat(newChat);
            if(chatForEvent === null)
            {
              //jest to nowy chat dla consultanta
              activeChatList.push(newChat);
            }
            else
            {
              //chat był już na liście ale dołączyliśmy
              activeChatList[chatForEventKey] = newChat;
            }

            self.inform({code: 'joinedChat', chatId: newChat.chat.id});
          }
          else if(chatForEvent !== null)
          {
            self.prepareMessage(event, chatForEvent, {checkAgentOnline: true}); //event i message się miesza
            self.clearTempMessages(chatForEvent, [event]); //czyścimy tymczasowe
            chatForEvent.messages.push(event); //dokładamy nowe info/event do chatu który już mamy

            self.inform({code: 'newEvent', chatId: chatForEvent.chat.id, event: event});
          }
          else
          {
            //event for chat outside of list but not a consultant join
            //something went wrong so we want to load this chat manually
            self.inform({code: 'unknownChatAppeared', event: event});
          }
        };

        this.prepareMessage = function(message, chatData, params)
        {
          if(angular.isFunction(self.additionalEventParserMethod))
          {
            self.additionalEventParserMethod(message);
          }

          if(!params)
          {
            params = {
              //checkAgentOnline
            };
          }

          if(message.type !== 'SCREENSHOT_REQUEST' && message.type !== 'CHAT_QUEUE')
          {
            message.visibleForAgent = true;
          }
          else if(message.type === 'SCREENSHOT_REQUEST' && !params.isHistory)
          {
            $timeout(function() {
              self.makeScreenshot(chatData.chat.thread_id);
            }, 0);
          }

          if(message.type === 'SCREENSHOT')
          {
            message.thumbnailUrl = '/space-chat/resource/chat/' + message.chat_id + '/thumbnail/' + message.attachment.file_uid;
            message.screenshotUrl = '/space-chat/resource/chat/' + message.chat_id + '/screenshot/' + message.attachment.file_uid;
          }
          if(message.type === 'ATTACHMENT')
          {
            message.fileUrl = '/space-chat/resource/chat/' + message.chat_id + '/file/' + message.attachment.file_uid;
          }

          if(message.message && message.message.message_type && self.eventsMap[message.message.message_type])
          {
            message.message.eventInfo = self.eventsMap[message.message.message_type];
          }

          // chat jest zawsze rozpoczynany przez agenta, więc po tym rozpoznajemy jaką użytkownik ma rolę w chacie
          if(chatData.chat.opened_by_id === message.user_id)
          {
            message.userInitials = self.getUserInitials(message.user);
          }
          else
          {
            message.fromConsultant = true;
          }

          // wcześniej mieliśmy rozpoznawanie na podstawie roli
          // if(message.user && message.user.roles && message.user.roles.indexOf('ROLE_CONSULTANT') !== -1)
          // {
          //   message.fromConsultant = true;
          // }
          // else
          // {
          //   message.userInitials = self.getUserInitials(message.user);
          // }

          if(message.message && message.message.message_type === 'MESSAGE_CONSULTANT_JOIN' && !chatData.consultant)
          {
            self.inform({code: 'MESSAGE_CONSULTANT_JOIN', params: params});
            chatData.consultant = message.user;
          }
          if(message.message && message.message.message_type === 'MESSAGE_CHAT_CLOSE' && !params.isHistory)
          {
            chatData.closed = true;
            self.inform({code: 'chatClosed', chatId: chatData.chat.id});
          }

          if(message.type !== 'ONLINE')
          {
            message.toShow = true;
            //aktualizujemy status dla czerwonej ikonki
            chatData.lastActivityFromAgent = !message.fromConsultant;
            chatData.lastActivityDate = message.event_date;
          }

          if(params.checkAgentOnline && !params.isHistory && (message.type === 'ONLINE' || !message.fromConsultant ))
          {
            chatData.agentOnline = true;
            if(chatData.agentOnlineTimeout)
            {
              $timeout.cancel(chatData.agentOnlineTimeout);
            }
            chatData.agentOnlineTimeout = $timeout(function(){
              chatData.agentOnline = false;
            }, self.onlineTimeout * 1000);
          }
        };

        this.getUserInitials = function(user, params){
          if(angular.isUndefined(params))
          {
            params = {
              firstNameParam: 'first_name',
              lastNameParam: 'last_name'
            };
          }

          var userInitials = '';
          if(user && user[params.firstNameParam])
          {
            userInitials = userInitials + user[params.firstNameParam].substring(0, 1).toUpperCase();
          }
          if(user && user[params.lastNameParam])
          {
            userInitials = userInitials + user[params.lastNameParam].substring(0, 1).toUpperCase();
          }
          return userInitials;
        };

        this.sendMessage = function(messageString, chatData, fromAgent)
        {
          var tempEvent = self.addTempMessage(messageString, chatData, fromAgent);
          self.inform({code: 'newEvent', chatId: chatData.chat.id});
          return spaceChatSvc.post(chatData.chat.thread_id, messageString, null, function(response){
            tempEvent.id = response.data.id;
            // zdaża się, że response zapisu wróci później niż ta sama wiadomość z longPoll
            self.clearLateTempMessages(chatData);
          });
        };

        /**
         * chcemy już wyświetlać wpisany message ze spinnerem, więc dodajemy go do listy
         */
        this.addTempMessage = function(messageString, chatData, fromAgent)
        {
          var tempEvent = {
            message: {
              message: messageString
            },
            fromConsultant: !fromAgent,
            userInitials: chatData.agentInitials,
            temp: true,
            toShow: true
          };

          chatData.messages.push(tempEvent);

          return tempEvent; //zwracamy go żeby dodać do niego id po zapisaniu
        };

        this.loadList = function()
        {
          return spaceChatSvc.get(null, null, null, null, self.rejectCallback, {
            allowedStatuses: [403]
          });
        };

        this.rejectCallback = function(rej)
        {
          if(rej.status === 403)
          {
            $rootScope.canRender = false;
            $rootScope.serviceForbidden = true;
          }
        };

        this.loadAgentHistory = function(agentId)
        {
          return spaceChatAgentSvc.get(agentId + '/chats');
        };

        this.closeChat = function(threadId, stopPoll)
        {
          if(stopPoll)
          {
            self.stopPoll();
          }
          return spaceChatSvc.post(threadId + '/close', null, null, null, null, {
            allowedStatuses: [403]
          });
        };

        this.stopPoll = function()
        {
          self.abortedByUser = true;
          if(self.longPollAbortPromise)
          {
            self.longPollAbortPromise.resolve('roomClosed');
          }
        };

        this.stopConsultantPoll = function()
        {
          self.abortedByUser = true;
          if(self.consultantLongPollAbortPromise)
          {
            self.consultantLongPollAbortPromise.resolve('roomClosed');
          }
        };

        this.loadArchiveChatMessages = function(threadId, index, alsoActive)
        {
          var indexString = '';
          if(index)
          {
            indexString = '/' + index;
          }
          return spaceChatSvc.get(threadId + '/history' + indexString).then(function(response){
            var chatData = response.data;
            var prepareParams = {};
            if(!alsoActive)
            {
              prepareParams.isHistory = true;
            }
            self.prepareChat(chatData, null, prepareParams);
            return chatData;
          }, angular.noop);
        };

        /**
         * weryfikacja czy response był ok
         * @param  {object}  response
         * @return {Boolean}
         */
        this.isResponseSuccess = function(response)
        {
          var succesStatuses = [200, 201, 202];
          return (response.status && succesStatuses.indexOf(response.status) !== -1) ? true : false;
        };

        /**
         * konsultant prosi o nowy chat z agentem
         */
        this.joinNewRoom = function()
        {
          return spaceChatConsultantSvc.post('join', null, null, null, null, {
            allowedStatuses: [404]
          });
        };

        /**
         * ustawia konsultanta online
         */
        this.setOnline = function()
        {
          return spaceChatConsultantSvc.post('online', null, null, null, self.rejectCallback, {
            allowedStatuses: [403]
          });
        };

        /**
         * ustawia konsultanta online
         */
        this.setOffline = function()
        {
          return spaceChatConsultantSvc.post('offline');
        };

        /**
         * zapis oceny
         */
        this.saveRating = function(threadId, rateData)
        {
          return spaceChatSvc.post(threadId + '/rate', rateData);
        };

        /**
         * lista aktywnych chatów dla managera
         */
        this.getPendingChatList = function(filterData)
        {
          if(!filterData)
          {
            filterData = {};
          }
          if(!filterData.sortBy)
          {
            filterData.sortBy = 'id';
          }
          if(!filterData.sortDirection)
          {
            filterData.sortDirection = 'ASC';
          }

          return spaceChatManagerSvc.get('pending', filterData, null, null, self.rejectCallback, {
            allowedStatuses: [403]
          });
        };

        /**
         * lista konsultantów dla managera
         */
        this.getConsultantList = function(filterData)
        {
          if(!filterData)
          {
            filterData = {};
          }
          if(!filterData.sortBy)
          {
            filterData.sortBy = 'id';
          }
          if(!filterData.sortDirection)
          {
            filterData.sortDirection = 'ASC';
          }

          return spaceChatManagerSvc.get('activity', filterData, null, null, self.rejectCallback, {
            allowedStatuses: [403]
          });
        };

        this.makeScreenshot = function(threadId) {
          html2canvas(document.body, {
            type: 'view', //przycinamy żeby pozbyć się problemów z overflow
            letterRendering: true,
            background: undefined,
            onrendered: function (canvas) {
              var blob = self.dataURItoBlob(canvas.toDataURL('image/png'));
              var form = new FormData();
              form.append('filename', 'screenshot.png');
              form.append('file', blob, 'screenshot.png');

              spaceChatUploadSvc.post(threadId, form, 'upload/screenshot', null, null, {
                  transformRequest: angular.identity,
                  headers         : {
                    'Content-Type': undefined
                  },
                  contentType: undefined
                });
            }
          });
        };

        this.dataURItoBlob = function(dataURI) {
          var byteString;
          if(dataURI.split(',')[0].indexOf('base64') >= 0)
          {
            //dekodujemy base64
            byteString = atob(dataURI.split(',')[1]);
          }
          else
          {
            byteString = unescape(dataURI.split(',')[1]);
          }

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var typedArray = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              typedArray[i] = byteString.charCodeAt(i);
          }

          return new Blob([typedArray], {type: mimeString});
        };

        this.demandScreenshot = function(chatData)
        {
          return spaceChatSvc.post(chatData.chat.thread_id, null, 'screenshot');
        };

        this.returnReject = function()
        {
          var deferred = $q.defer();
          deferred.reject();
          return deferred.promise;
        };

        this.loadPrevious = function(chatData)
        {
          if(chatData.scrolledToBottomOnce && !chatData.loadingPrevious && chatData.previous && chatData.initialImagesCount <= chatData.initialImagesLoaded)
          {
            chatData.initialLoadEnded = true;
            chatData.loadingPrevious = true;
            var firstAlreadyLoadedMessageId = chatData.messages[0].id;
            return self.loadArchiveChatMessages(chatData.chat.thread_id, firstAlreadyLoadedMessageId).then(function(loadedChatData){
              chatData.messages = loadedChatData.messages.concat(chatData.messages);
              chatData.previous = loadedChatData.previous;
              chatData.loadingPrevious = false;
              return {
                threadId: chatData.chat.thread_id,
                messageId: firstAlreadyLoadedMessageId
              };
            }, angular.noop);
          }
          else
          {
            return self.returnReject();
          }
        };
      };

      return new SpaceChatHelper();
    }]);
