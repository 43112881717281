angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonAbstractComponentManagementSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var AbstractComponentManagement = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.port = '8282';
        this.path = 'component-management-web';

      };
      return AbstractComponentManagement;
    }
  ]);