angular.module('lsnBase.models')
  .factory('LsnMessageModelV1', ['LsnAbstractModel', 'LsnMessageModelConstants',
    function(LsnAbstractModel, LsnMessageModelConstants)
    {

      var LsnMessageModelV1 = function()
      {
        this.objectName = 'Message';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          code:
          {
            type: 'string'
          },
          type:
          {
            type: 'string',
            dictionary: LsnMessageModelConstants.DICTIONARY_MESSAGE_TYPE
          },
          text:
          {
            type: 'string'
          },
          object:
          {
            type: 'string'
          }
        };
        this.type = null; // string, Typ komunikatu. Możliwe wartości:
        // • INFO - informacja
        // • WARN - ostrzeżenie
        // • ERROR – błąd
        this.text = null; // string, Treść komunikatu.
        this.object = null; // string, Obiekt związany z komunikatem.
        this.code = null; // string, kod bledu
      };

      LsnMessageModelV1.prototype = LsnAbstractModel.prototype;

      return LsnMessageModelV1;
    }
  ]);