angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorMessageReceivedModel', ['IKomunikatorMessageModel', 'IKomunikatorAttachmmentModel', 'ikomunikatorCommonHelper',
    function(IKomunikatorMessageModel, IKomunikatorAttachmmentModel, ikomunikatorCommonHelper) {

    var IKomunikatorMessageReceivedModel = function() {
      IKomunikatorMessageModel.apply(this, arguments);
      var self = this;

      /**
       * Obiekt z imieniem i nazwiskiem twórcy wiadomości
       * @type {Object}
       */
      this.originator = null;

      /**
       * inicjały twórcy wiadomości
       * @type {String}
       */
      this.originatorInitials = null;

      /**
       * Imiona i nazwiska odbiorców z książki adresowej
       * @type {Array}
       */
      this.recipients = [];

      /**
       * Temat
       * @type {String}
       */
      this.subject = null;

      /**
       * Dotyczy
       * @type {String}
       */
      this.subjectContextItem = null;

      /**
       * Kanał zwiazany z wiadomością (może być kilka po przecinku)
       * @type {String}
       */
      this.messageChannel = '';

      this.lastMessage = null;

      /**
       * [setDataFromRest description]
       * @param {[type]} data [description]
       */
      this.setDataFromRest = function(data) {
        self.fromRest(data);
        self.attachments = [];
        angular.forEach(data.attachments, function(attachmentData) {
          var attachment = new IKomunikatorAttachmmentModel();
          attachment.fromRest(attachmentData);
          self.attachments.push(attachment);
        });
      };

      /**
       * [getMessageChannel description]
       * @return {[type]} [description]
       */
      this.getMessageChannel = function(){
        return self.messageChannel === null ? null : self.messageChannel.split(',')[0].toUpperCase();
      };

      this.getOriginatorInfo = function(){
        return ikomunikatorCommonHelper.getUserInfoForFullPanelPage(self.originator);
      };

      this.getRecipientInfo = function(){
        return ikomunikatorCommonHelper.getUserInfoForFullPanelPage(self.recipients[0]);
      };

    };

    return IKomunikatorMessageReceivedModel;
  }]);