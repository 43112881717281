angular.module('ihestiaCommonDirectives')
  .directive('commonModal', ['$parse', '$rootScope', '$templateCache', '$timeout', 'iHestiaCommonModalHelper', '$filter',
    function($parse, $rootScope, $templateCache, $timeout, iHestiaCommonModalHelper, $filter) {
      return {
        restrict: 'E',
        templateUrl: 'ihestia-commondirectives-template-app/directives/modal/defaultModalTemplate.tpl.html',
        scope: true,
        controller: 'commonModalCtrl',
        link: function($scope, $element, $attrs) {
          var self = this;

          // ustawienia podstawowe
          var defaultSettings = {
            show: false,
            modalTemplate: 'ihestia-commondirectives-template-app/directives/modal/defaultModalContent.tpl.html',
            content: '',
            // okBtnName: 'Ok',
            // cancelBtnName: 'Anuluj',
            thirdBtnName: '',
            fourthBtnName: '',
            okBtnDisabled: false,
            cancelBtnDisabled: false,
            thirdBtnDisabled: false,
            fourthBtnDisabled: false,
            modalCode: null,
            replaceModalBody: false,
            keyboard: true,
            animation: 'am-fade-and-scale',
            backdropAnimation: 'am-fade',
            size: 'md', // dostępne warianty sm: 460px, md: 780px, lg: 940px
            showOkSpinner: false,
            showCancelSpinner: false,
            headerList: [], //np. [{name:'lokal', value:'flat'},{name:'budynek', value:'house'}]
            headerListModel: null, //gdzie zapisywana ma być wartość w scope, podpięte przez ng-model, np. 'modals.localizationEditModal.type'
            treatLabelAsI18nCode: false
          };

          /**
           * Init
           * @return {[type]} [description]
           */
          this.init = function() {
            self.setScopeVars($scope);
            self.setObservers($scope);
            $scope.forms = {};
          };


          /**
           * Zmiany ustawień
           */
          this.setObservers = function(drvScope) {
            // zmiany ustawień
            var headerValueWatchDestroy = null;
            drvScope.$watch($attrs.modalSettings, function(settings) {
              if (settings) {
                self.rebuildSettings($attrs, drvScope);
                // headerValue
                if (settings.headerListModel) {
                  if (angular.isFunction(headerValueWatchDestroy)) {
                    headerValueWatchDestroy();
                  }
                  headerValueWatchDestroy = drvScope.$watch(settings.headerListModel, function(headerValue) {
                    drvScope.headervalue = headerValue;
                  }, true);
                }
                $rootScope.$broadcast('commonModal.settingsInitialized.' + drvScope.settings.modalCode);
              }
            }, true);

            // jesli tytul w atrybucie, to wrzucamy go do settingsow
            $attrs.$observe('modalTitle', function() {
              self.rebuildSettings($attrs, drvScope);
            });

            // dodatkowy szablon headera
            $attrs.$observe('headerTemplate', function() {
              self.rebuildSettings($attrs, drvScope);
            });

            // Pozbywamy się nadrzędnego kontenera .modal-body
            $attrs.$observe('replaceModalBody', function() {
              self.rebuildSettings($attrs, drvScope);
            });
          };

          /**
           * Funkcja do przebudowy
           * @param  {[type]} $attrs [description]
           * @return {[type]}        [description]
           */
          this.rebuildSettings = function(attrs, drvScope) {
            // zmiany ustawień
            if (attrs.modalSettings) {
              var drvESettings = $parse(attrs.modalSettings)(drvScope);
              if(!angular.isObject(drvESettings)){
                throw new Error('commonModalDrv: ustawienia dyrektywy powinny być obiektem, dla ' + attrs.modalSettings + ' nie są');
              }

              if(angular.isObject(drvESettings) && drvESettings.treatLabelAsI18nCode){
                if(angular.isUndefined(drvESettings.okBtnName)){
                  drvESettings.okBtnName = ['okUcFirst', {componentCode: 'Public'}];
                }
                if(angular.isUndefined(drvESettings.cancelBtnName)){
                  drvESettings.cancelBtnName = ['cancelUcFirst', {componentCode: 'Public'}];
                }
              }
              drvScope.settings = angular.extend({}, defaultSettings, drvESettings);
            } else {
              drvScope.settings = angular.extend({}, defaultSettings);
            }

            // content modala
            if (attrs.modalTemplate) {
              drvScope.settings.modalTemplate = attrs.modalTemplate;
            }

            // unikatowy kod modala
            drvScope.settings.modalCode = attrs.modalCode;

            // jesli tytul w atrybucie, to wrzucamy go do settingsow
            if (attrs.modalTitle) {
              drvScope.settings.title = attrs.modalTitle;
            }

            // dodatkowy szablon headera
            if (attrs.headerTemplate) {
              drvScope.settings.headerTemplate = attrs.headerTemplate;
            }

            // Pozbywamy się nadrzędnego kontenera .modal-body
            if (attrs.replaceModalBody) {
              drvScope.settings.replaceModalBody = $parse(attrs.replaceModalBody)(drvScope);
            }

            drvScope.setTranslateLabel();
          };

          /**
           * Ustawiamy klawisze
           * @param {Boolean} isOn [description]
           */
          this.setKeyboard = function(isOn, drvScope) {
            var onKeyUp = function(event) {
              if (event.which === 27 && drvScope.settings.keyboard === true) {
                drvScope.cancelBtnClicked();
              }
            };
            angular.element('body').off('keyup', onKeyUp);
            if (!drvScope.settings.errorMessage) {
              iHestiaCommonModalHelper.modalEscKeyboardCallback = null;
            }
            if (isOn && drvScope.settings.keyboard === true) {
              if (!drvScope.settings.errorMessage) {
                // przechowujemy cancel dla modali "pod błędami"
                iHestiaCommonModalHelper.modalEscKeyboardCallback = drvScope.cancelBtnClicked;
              }
              angular.element('body').on('keyup', onKeyUp);
              $scope.$on('$destroy',function() {
                angular.element('body').off('keyup', onKeyUp);
              });
            }
          };

          /**
           * Ustawiamy zmienne/funkcje scope'a
           */
          this.setScopeVars = function(drvScope) {
            drvScope.$element = $element;

            /**
             * ustawia wartość elementu spiętego z listą w nagłówku
             * @param {mixed} value
             */
            drvScope.setHeaderValue = function(value) {
              var headerValueSelector = drvScope.settings.headerListModel;
              if (headerValueSelector !== null) {
                $parse(headerValueSelector).assign(drvScope, value);
              }
              drvScope.headervalue = value;
            };

            /**
             * funckja sprawdza czy wystepuje w module formularz
             * i jesli wystepuje chociaz jeden to sprawdza jego/ich walidacje
             * @return {boolean}
             */
            drvScope.checkExistsFormValid = function() {
              var formValid = true;
              angular.forEach(drvScope.forms, function(form) {
                formValid = formValid && form.$valid;
              });

              return formValid;
            };

            /**
             * Ukryj modal
             * @return {[type]} [description]
             */
            drvScope.hide = function() {
              var onKeyUp = function(event) {
                if (event.which === 27 && drvScope.settings.keyboard === true) {
                  iHestiaCommonModalHelper.modalEscKeyboardCallback();
                }
              };

              self.setKeyboard(false, drvScope);
              drvScope.modalData.visible = false;
              if (drvScope.settings.errorMessage) {
                // przywracamy ustawienia klawisza esc
                angular.element('body').off('keyup', onKeyUp);
                if (angular.isFunction(iHestiaCommonModalHelper.modalEscKeyboardCallback)) {
                  angular.element('body').on('keyup', onKeyUp);
                  $scope.$on('$destroy',function() {
                    angular.element('body').off('keyup', onKeyUp);
                  });
                }
              }
              var index = iHestiaCommonModalHelper.visibleModals.indexOf($scope.settings.modalCode);
              if (index > -1) {
                iHestiaCommonModalHelper.visibleModals.splice(index, 1);
              }
              // ensuring that this method will fire $digest
              $timeout(angular.noop, 0);

              if (iHestiaCommonModalHelper.canOpenNextWindow()) {
                drvScope.$emit('canOpenNextWindow');
              }
            };

            /**
             * Pokaz modal
             * @return {[type]} [description]
             */
            drvScope.show = function() {
              drvScope.setTranslateLabel();
              $timeout(function() {
                // klawisz esc
                self.setKeyboard(drvScope.settings.keyboard, drvScope);
                drvScope.modalData.visible = true;
                drvScope.modalData.temporaryHidden = false;
                if (iHestiaCommonModalHelper.visibleModals.indexOf($scope.settings.modalCode) === -1) {
                  iHestiaCommonModalHelper.visibleModals.push($scope.settings.modalCode);
                }
              }, 0);
            };

            /**
             * [setTransLabel funkcja sluzy ustawieniu tlumaczonych tekstow]
             */
            drvScope.setTranslateLabel = function() {
              /** domyślne nazwy przycisków */
              var drvESettings = $parse($attrs.modalSettings)(drvScope);
              if(angular.isUndefined(drvESettings.okBtnName)) {
                $scope.settings.okBtnName = $filter('translate')('okUcFirst', {componentCode: 'Public'});
              }
              if(angular.isUndefined(drvESettings.cancelBtnName)) {
                $scope.settings.cancelBtnName = $filter('translate')('cancelUcFirst', {componentCode: 'Public'});
              }
              angular.forEach(['title', 'cancelBtnName', 'thirdBtnName', 'fourthBtnName', 'okBtnName'], function(name) {
                if (drvScope.settings.treatLabelAsI18nCode) {
                  if (angular.isArray(drvScope.settings[name])) {
                    //pierwszy argument to kod labelki, a drugi to zestaw atrybutów, np.: {componentCode: 'Public'}
                    if (angular.isUndefined(drvScope.settings[name][0]) || angular.isUndefined(drvScope.settings[name][1])) {
                      throw new Error('commonModalDrv - illegal translate array - setting name: ' + name + ' - modalCode: ' + drvScope.settings.modalCode);
                    } else {
                      drvScope.settings[name + 'Trans'] = $filter('translate')(drvScope.settings[name][0], drvScope.settings[name][1]);
                    }
                  } else if ((angular.isString(drvScope.settings[name]) && drvScope.settings[name] !== '') || angular.isObject(drvScope.settings[name])) {
                    drvScope.settings[name + 'Trans'] = $filter('translate')(drvScope.settings[name]);
                  }
                } else {
                  drvScope.settings[name + 'Trans'] = drvScope.settings[name];
                }
              });

              drvScope.settings.headerListTrans = [];
              angular.forEach(drvScope.settings.headerList, function(item) {
                 if (drvScope.settings.treatLabelAsI18nCode) {
                  var translation = {
                    name: item.name,
                    disabled: item.disabled,
                    value: item.value
                  };
                  if (angular.isString(item.name)) {
                    translation.name = $filter('translate')(item.name);
                  } else if (angular.isArray(item.name)) {
                    //pierwszy argument to kod labelki, a drugi to zestaw atrybutów, np.: {componentCode: 'Public'}
                    if (angular.isUndefined(item.name[0]) || angular.isUndefined(item.name[1])) {
                      throw new Error('commonModalDrv - illegal headerList translate array - modalCode: ' + drvScope.settings.modalCode);
                    } else {
                      translation.name = $filter('translate')(item.name[0], item.name[1]);
                    }
                  }
                  drvScope.settings.headerListTrans.push(translation);
                } else {
                  drvScope.settings.headerListTrans.push(item);
                }
              });
            };
          };


          self.init();
        }
      };
    }
  ])
  .run(['iHestiaCommonModalHelper', '$rootScope',
    function(iHestiaCommonModalHelper, $rootScope) {
      $rootScope.commonModalHelper = iHestiaCommonModalHelper;

      $rootScope.$watch('commonModalHelper.visibleModals', function(visibleModals) {
        if (visibleModals.length > 0) {
          angular.element('body').addClass('modal-open modal-with-am-fade-and-scale');
        } else {
          angular.element('body').removeClass('modal-open modal-with-am-fade-and-scale');
        }
      }, true);
    }
  ]);