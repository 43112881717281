/**
 * Filtr do nowych linii
 */
angular.module('lsnBase.filters')
  .filter('lsnNl2br', ['$sce', 'lsnTryEscapeHtml', function($sce, lsnTryEscapeHtml) {
    return function(text, withStringNewLines, alreadyTrustedAsHtml) {
        text = text ? text : '';
        text = lsnTryEscapeHtml(text);
        text = text.replace(/\n/g, '<br/>');
        if(withStringNewLines){
          text = text.replace(/\\n/g, '<br/>');
        }


        text = text ? text : '';
        if(!alreadyTrustedAsHtml){
          text = $sce.trustAsHtml(text);
        }
        return text;
    };
  }]).filter('nl2br', ['lsnNl2brFilter', function(lsnNl2brFilter) {
    return lsnNl2brFilter;
  }]);