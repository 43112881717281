/**
 * helper dla ustawień grida
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaCommonDirectives.blockUi')
  .service('blockUiHelper', ['$rootScope', '$filter', '$document',
   function ($rootScope, $filter, $document) {

      /**
       * Zwracany serwis
       */
      var BlockUiHelper = function () {
        var self = this;
        this.sessionHasExpired = false;

        this.blockUiState = {
          visible: false,
          appSettings: {},//ustawienia przekazane bezpośrenio do helpera
          passedSettings: {}, //ustawienia przekazane eventem
          activeSettings: {}, //ustawienia wykorzystywane do prezentacji
          defaultSettings: {}
        };

        this.setSettings = function(settings)
        {
          self.blockUiState.appSettings = settings;
        };

        /**
         * Obsługa tłumaczeń (zmiana języka)
         */
        this.setLangData = function() {
          self.blockUiState.defaultSettings.label = $filter('translate')('Public.loading', {componentCode: 'Public'});
        };

        /**
         * [showBlockUi description]
         * @param  {[type]} settings [description]
         * @return {[type]}          [description]
         */
        this.showBlockUi = function(settings) {
          self.setLangData();
          // jeśli sesja wygasła, to nie puszczamy juz żadnych requestów, więc
          // nie wyświetlamy spinnerów (moglibyśmy dojść do sytuacji, w której mamy wiszący spinner)
          if(!self.sessionHasExpired) {
            if(settings){
              self.blockUiState.passedSettings = settings;
            }else{
              self.blockUiState.passedSettings = {};
            }
            self.blockUiState.visible = true;
          }


        };

        this.fireListeners = function(){
          $document.on('click', function($event) {
            if(self.blockUiState.visible){
              $event.stopPropagation();
              $event.preventDefault();  
            }

          });

          $document.on('keypress', function($event) {
            if(self.blockUiState.visible){
              $event.stopPropagation();
              $event.preventDefault();
            }
          });
        };

        /**
         * [hideBlockUi description]
         * @return {[type]} [description]
         */
        this.hideBlockUi = function() {
          self.blockUiState.visible = false;
        };

        /**
         * [init description]
         */
        this.init = function(){
          $rootScope.$on('iHestia.Session.Expired', function(){ // eslint-disable-line angular/on-watch
            self.sessionHasExpired = true;
            self.blockUiState.visible = false;
          });

          $rootScope.$on('showBlockUi', function(event, settings){ // eslint-disable-line angular/on-watch
            self.showBlockUi(settings);
          });

          $rootScope.$on('hideBlockUi', function(){ // eslint-disable-line angular/on-watch
            self.hideBlockUi();
          });

          self.fireListeners();
        };

      };

      return new BlockUiHelper();
    }
  ]).run(['blockUiHelper',
    function(blockUiHelper) {
      blockUiHelper.init();
    }
  ]);
