angular.module('ihestiaRestServicesBase')
  .factory('customerPoliciesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var CustomerPoliciesSvc = function() {
        var self = this;

        this.resource = 'customers';
        this.businessArea = 'insurance';
        this.searchesPostfix = 'policies/searches';
        this.pageStyle = false;
        this.sortBy = 'startDate';
        /**
         * Rejestruje accesToken, aby mieć dostęp do obcej polisy klienta
         *
         * @param  {int} customerId   id klienta
         * @param  {string} policyNumber numer polisy
         * @return {promise}              obietnica angularowa
         */
        this.registerAccessToken = function(customerId, policyNumber){
          return self.post(customerId, {
            policyNumber: policyNumber
          }, 'policies/accesstokens', null, null, {allowedStatuses: [404]});
        };

      };

      var CustomerPoliciesSvcV1 = function() {
        CustomerPoliciesSvc.apply(this, arguments);
      };

      var CustomerPoliciesSvcV3 = function() {
        CustomerPoliciesSvc.apply(this, arguments);
        this.apiVersion = 'v3';
      };

      return IHestiaRestServiceAbstractDef.setApiConstructors({
        v1: CustomerPoliciesSvcV1,
        v3: CustomerPoliciesSvcV3
      });

    }]);