angular.module('salesPath2')
  .service('tariffFactorsModalHelper', ['$rootScope', 'mainDataContainer', 'SPD', 'CONSTANTS', 'appVariables', 'vehicleHelper', 'fromRestTariffFactorsMapper', 'MapperUtils', 'messagesHelper', 'sp2CommonHelper', 'dataContainerHelper',
    function($rootScope, mainDataContainer, SPD, CONSTANTS, appVariables, vehicleHelper, fromRestTariffFactorsMapper, MapperUtils, messagesHelper, sp2CommonHelper, dataContainerHelper) {
      var TariffFactorsModalHelper = function() {
        var self = this;
        var DEFAULT_MODAL_DATA = {
          errors: false,
          vehicleId: null,
          tariffFactors: {}
        };

        //ustawienia modali
        this.modals = {};

        /**
         * Ustawienia modala
         * @type {Object}
         */
        this.modals.tariffFactorsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: ['sp2.menu.tariffFactors', {
              componentCode: 'sp2'
            }],
            okBtnName: ['sp2.modal.close', {
              componentCode: 'sp2'
            }],
            cancelBtnName: '',
            size: 'md'
          }
        };

        this.modalData = angular.copy(DEFAULT_MODAL_DATA);

        /**
         * Czyscimy dane modala
         * @return {CessionModalHelper} [description]
         */
        this.clearModalData = function() {
          self.modalData = angular.copy(DEFAULT_MODAL_DATA);
          return self;
        };

        /**
         * Odpalenie modala weryfikacji danych
         * @param  {[type]} $event       [description]
         * @param  {[type]} personId     [description]
         * @param  {[type]} vehicleId
         */
        this.show = function() {
          self.loadData();
          if (self.modalData.errors) {
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: ['sp2.modal.info.title', {
                componentCode: 'sp2'
              }],
              okBtnName: ['sp2.modal.close', {
                componentCode: 'sp2'
              }],
              cancelBtnName: '',
              text: ['modal.noCalculationForMotorInsurance', {
                componentCode: 'sp2'
              }]
            });
          } else if (self.modalData.vehicleId !== null) {
            sp2CommonHelper.showModal('tariffFactorsModal');
          }
        };

        /**
         * wczytuje dane do self.modalData na podstawie inicjalnie przekazanych danych/parametrow
         * (otwarcie modala)
         */
        this.loadData = function() {
          self.clearModalData();
          var vehiclesRisks = self._getRisksForVehicles();
          self._setFactorsWithVehicleData(vehiclesRisks);
          var count = Object.keys(self.modalData.tariffFactors).length;
          if (messagesHelper.isanyTarifficationError() || count === 0) { //bledy taryfikacji lub brak pojazdow
            self.modalData.errors = true;
          }
          if (count === 1) {
            self.modalData.vehicleId = Object.keys(self.modalData.tariffFactors)[0];
          }
        };

        /**
         * @return {Object} zwraca pary metadata_id_pojazdu: tablica_ryzyk
         */
        this._getRisksForVehicles = function() {
          var resp = {};
          angular.forEach(mainDataContainer.vehicles, function(vehicle) {
            //wazne aby w tablicy ryzyk znalazy sie AC,OC,ACKR i NNWKIP. Pozostałe ryzyka nie będą przeszkadzać w mapowaniu, wiec nie musimy ich usuwac
            var vehicleId = vehicle.metaData.get('id');
            if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY) { //tryb polisy - zczytujemy ryzyka z polis
              var policy = dataContainerHelper.getPolicyByObjectId(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleId);
              if (policy !== null) {
                resp[vehicleId] = policy.risks;
              }
            } else { //tryb wniosku/oferty  - sczytujemy ryzyka z wniosku
              var tfRisks = self._getTariffFactorRisksForVehicle(vehicleId);
              if (tfRisks.length > 0) {
                resp[vehicleId] = tfRisks;
              }
            }
          });
          return resp;
        };

        /**
         * uzupełnia self.modalData.tariffFactors danymi pojazdu i zmapowanymi czynnikami
         */
        this._setFactorsWithVehicleData = function(vehiclesRisks) {
          self.modalData.tariffFactors = {};
          var infoSettings = {
            elements: [{
              field: 'mark',
              fieldSeparator: ', '
            }, {
              field: 'registration'
            }],
            maxLength: 35
          };
          angular.forEach(vehiclesRisks, function(risks, vehicleId) {
            var vehicleFactorsData = {
              name: vehicleHelper.getVehicleInfo(dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleId, 'id'), infoSettings),
              factors: fromRestTariffFactorsMapper.getTariffFactorsForSinglePolicy(risks)
            };
            self.modalData.tariffFactors[vehicleId] = vehicleFactorsData;
          });
        };

        /**
         * zwraca tablicę ryzyk (z czynnikami taryfowymi) z wnoisku (dataManager.application) dla podanego pojazdu
         * @param {*} vehicleId metaData.id pojazdu
         * @return {Risk[]} tablica ryzyk
         */
        this._getTariffFactorRisksForVehicle = function(vehicleId) {
          var risks = [];
          vehicleId = parseInt(vehicleId, 10);
          if (mainDataContainer.application.get('risks').length === 0) {
            return [];
          }
          angular.forEach(mainDataContainer.application.risks, function(risk) {
            var riskDef = MapperUtils.findRiskDefByIdpm(risk.product.get('compId'));
            if (fromRestTariffFactorsMapper.riskCodes.indexOf(riskDef.riskCode) !== -1) { //ryzyko do czynnikow taryfowych
              lsnNg.forEach(risk.get('insuredObjectsRef'), function(insuredObject) {
                if (parseInt(insuredObject.ref, 10) === vehicleId) {
                  risks.push(risk);
                  return true;
                }

                return true; //continue
              });
            }
          });
          return risks;
        };

      };

      return new TariffFactorsModalHelper();
    }
  ]);