/* eslint-disable require-jsdoc */

/**
 * Numeric directive (modified copy of https://github.com/epeschier/angular-numeric-directive)
 *
 * @author of changes Michał Wierzba <michalw@lsnova.pl>
 *
 * Changes:
 *  - new attributes:
 *   - decimal-separator
 *   - group-separator
 *   - allow-leading-zeros
 *   - parse-to-string - set model value type to String or leave Number
 *     - if allow-leading-zeros is set to true the model value will always be String
 *   - select-on-focus
 * - allow inputting comma (,) and dot (.) as decimal separator
 * - disallow inputting more than `decimals` digits after decimal separator
 * - format initial value
 * - regenerate input regex on dependent values change
 * - allow passing settings as object (not as separate attributes)
 */
angular.module('lsnBase')
    .directive('commonNumeric', ['$locale', '$timeout', '$parse', function($locale, $timeout, $parse) {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, el, attrs, ngModelCtrl) {
                var self = this;
                var allowedDecimalsSeparatorsRegex = /[.,]/;

                // default values
                var decimalSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
                var groupSeparator = $locale.NUMBER_FORMATS.GROUP_SEP;
                var formatting = false; // Whether format value or not.
                var maxInputLength = 16; // Maximum input length. Default max ECMA script.
                var max; // Maximum value. Default undefined.
                var min; // Minimum value. Default undefined.
                var decimals = 2; // Number of decimals. Default 2.
                var lastValidValue; // Last valid value.
                var parseToString = true; // Set model value type to String (or leave Number).
                var allowLeadingZeros = false; // Allow leading zeros in input.
                var selectOnFocus = false; // Select input value on focus.
                var roundOnBlur = false;
                this.roundViewValue = false;

                var regex = generateRegex(); // Validation regex (filters user input and accepts numbers and decimal separator).

                if (typeof attrs.roundViewValue !== 'undefined'){
                    this.roundViewValue = true;
                    ngModelCtrl.$formatters.push(this.removeFloatingPart.bind(this));
                }

                // Create parsers and formatters.
                ngModelCtrl.$parsers.push(parseViewValue);
                ngModelCtrl.$parsers.push(minValidator);
                ngModelCtrl.$parsers.push(maxValidator);
                ngModelCtrl.$formatters.push(formatViewValue);

                el.on('blur', onBlur); // Event handler for the leave event.
                el.on('focus', onFocus); // Event handler for the focus event.

                scope.$on('$destroy', function(){
                  el.off('blur focus');
                });

                if (attrs.roundOnBlur) {
                    roundOnBlur = attrs.roundOnBlur;
                }
                // Put a watch on the min, max and decimal value changes in the attribute.
                scope.$watch(attrs.min, onMinChanged);
                scope.$watch(attrs.max, onMaxChanged);
                scope.$watch(attrs.decimals, onDecimalsChanged);
                scope.$watch(attrs.formatting, onFormattingChanged);
                scope.$watch(attrs.decimalSeparator, onDecimalSeparatorChanged);
                scope.$watch(attrs.groupSeparator, onGroupSeparatorChanged);
                scope.$watch(attrs.parseToString, onParseToStringChanged);
                scope.$watch(attrs.allowLeadingZeros, onAllowLeadingZerosChanged);
                scope.$watch(attrs.selectOnFocus, onSelectOnFocusChanged);
                scope.$watch(attrs.maxInputLength, onMaxInputLengthChanged);

                scope.$watch(attrs.commonNumeric, onSettingsChange, true);

                // brzydkie obejście, ale musimy załadować inicjalnie wartości
                var initSettings = $parse(attrs.commonNumeric)(scope);
                if (angular.isDefined(initSettings) && angular.isObject(initSettings)) {
                    if (angular.isDefined(initSettings.allowLeadingZeros)) {
                        allowLeadingZeros = initSettings.allowLeadingZeros;
                    }
                    if (angular.isDefined(initSettings.min)) {
                        min = initSettings.min;
                    }
                    if (angular.isDefined(initSettings.max)) {
                        max = initSettings.max;
                    }
                    if (angular.isDefined(initSettings.decimals)) {
                        decimals = initSettings.decimals;
                    }
                    if (angular.isDefined(initSettings.formatting)) {
                        formatting = initSettings.formatting;
                    }
                    if (angular.isDefined(initSettings.decimalSeparator)) {
                        decimalSeparator = initSettings.decimalSeparator;
                    }
                    if (angular.isDefined(initSettings.groupSeparator)) {
                        groupSeparator = initSettings.groupSeparator;
                    }
                    if (angular.isDefined(initSettings.parseToString)) {
                        parseToString = initSettings.parseToString;
                    }
                    if (angular.isDefined(initSettings.selectOnFocus)) {
                        selectOnFocus = initSettings.selectOnFocus;
                    }
                    if (angular.isDefined(initSettings.maxInputLength)) {
                        maxInputLength = initSettings.maxInputLength;
                    }
                }

                // Setup decimal formatting.
                if (decimals > -1) {
                    ngModelCtrl.$parsers.push(function(value) {
                        var returnValue = value;

                        if (value) {
                            if (allowLeadingZeros === false) {
                                returnValue = round(value);
                            }
                            if (parseToString === true) {
                                returnValue = returnValue.toString();
                            }
                        }

                        return returnValue;
                    });
                    ngModelCtrl.$formatters.push(formatPrecision);
                }

                formatInitialValue();

                function generateRegex() {
                    var decimalsRegexPart = '';
                    if (decimals > 0) {
                        decimalsRegexPart = "((\\.|\\,)\\d{0," + decimals + "})"; // eslint-disable-line quotes
                    }

                    var NUMBER_REGEXP = "^\\s*(\\-)?(\\d+|(\\d*" + decimalsRegexPart + "))\\s*$"; // eslint-disable-line quotes
                    if (roundOnBlur) {
                        NUMBER_REGEXP = "^\\s*(\\-)?(\\d+|(\\d*((\\.|\\,)\\d{0,10})))\\s*$"; // eslint-disable-line quotes
                    }
                    return new RegExp(NUMBER_REGEXP);
                }

                function onMinChanged(value) {
                    if (!angular.isUndefined(value)) {
                        min = parseFloat(value);
                        lastValidValue = minValidator(ngModelCtrl.$modelValue);
                        ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                        ngModelCtrl.$render();
                    }
                }

                function onMaxChanged(value) {
                    if (!angular.isUndefined(value)) {
                        max = parseFloat(value);
                        maxInputLength = calculateMaxLength(max);
                        lastValidValue = maxValidator(ngModelCtrl.$modelValue);
                        ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                        ngModelCtrl.$render();
                    }
                }

                function onDecimalsChanged(value) {
                    if (!angular.isUndefined(value)) {
                        decimals = parseFloat(value);
                        maxInputLength = calculateMaxLength(max);
                        regex = generateRegex();
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onFormattingChanged(value) {
                    if (!angular.isUndefined(value)) {
                        formatting = (value !== false);
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onDecimalSeparatorChanged(value) {
                    if (!angular.isUndefined(value)) {
                        decimalSeparator = value;
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onGroupSeparatorChanged(value) {
                    if (!angular.isUndefined(value)) {
                        groupSeparator = value;
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onParseToStringChanged(value) {
                    if (!angular.isUndefined(value)) {
                        parseToString = (value !== false);
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onAllowLeadingZerosChanged(value) {
                    if (!angular.isUndefined(value)) {
                        allowLeadingZeros = (value !== false);
                        if (lastValidValue !== undefined) {
                            ngModelCtrl.$setViewValue(formatPrecision(lastValidValue));
                            ngModelCtrl.$render();
                        }
                    }
                }

                function onSelectOnFocusChanged(value) {
                    if (!angular.isUndefined(value)) {
                        selectOnFocus = (value !== false);
                    }
                }

                function onMaxInputLengthChanged(value) {
                    if (angular.isDefined(value)) {
                        maxInputLength = value;
                    }
                }

                function onSettingsChange(value) {
                    if (angular.isDefined(value)) {

                        if (angular.isDefined(value.min)) {
                            onMinChanged(value.min);
                        }

                        if (angular.isDefined(value.maxInputLength)) {
                            onMaxInputLengthChanged(value.maxInputLength);
                        }

                        if (angular.isDefined(value.max)) {
                            onMaxChanged(value.max);
                        }

                        if (angular.isDefined(value.decimals)) {
                            onDecimalsChanged(value.decimals);
                        }

                        if (angular.isDefined(value.formatting)) {
                            onFormattingChanged(value.formatting);
                        }

                        if (angular.isDefined(value.decimalSeparator)) {
                            onDecimalSeparatorChanged(value.decimalSeparator);
                        }

                        if (angular.isDefined(value.groupSeparator)) {
                            onGroupSeparatorChanged(value.groupSeparator);
                        }

                        if (angular.isDefined(value.parseToString)) {
                            onParseToStringChanged(value.parseToString);
                        }

                        if (angular.isDefined(value.allowLeadingZeros)) {
                            onAllowLeadingZerosChanged(value.allowLeadingZeros);
                        }

                        if (angular.isDefined(value.selectOnFocus)) {
                            onSelectOnFocusChanged(value.selectOnFocus);
                        }

                        formatInitialValue();
                    }
                }

                /**
                 * Round the value to the closest decimal.
                 */
                function round(value) {
                    var d = Math.pow(10, decimals);
                    return Math.round(value * d) / d;
                }

                /**
                 * Format a number with the thousand group separator.
                 */
                function numberWithCommas(value) {
                    if (formatting) {
                        var parts = value.toString().split(decimalSeparator);
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
                        return parts.join(decimalSeparator);
                    } else {
                        // No formatting applies.
                        return value;
                    }
                }

                /**
                 * Format a value with thousand group separator and correct decimal char.
                 */
                function formatPrecision(value) {
                    if (!(value || value === 0)) {
                        return '';
                    }

                    var formattedValue = value;
                    var stringValue = value + '';

                    if (stringValue.length <= 16 && decimals > 0) {
                        formattedValue = formatToNumberOfDigits(value, decimals);
                        if (allowLeadingZeros) { // jeśli zformatowana wartość ma już ucięte zera (powyższa linijka), a chcemy je mieć, to przepisujemy z początkowej wartości
                            var numberOfLeadingZeros = getNumberOfLeadingZeros(value);
                            formattedValue = padWithZeros(formattedValue, numberOfLeadingZeros);
                        }
                    }

                    formattedValue = formattedValue.toString().replace('.', decimalSeparator);
                    return numberWithCommas(formattedValue);
                }

                function formatToNumberOfDigits(value, decimals) {
                    var dotPosition = (value + '').indexOf('.');
                    var decimalDigits = '';
                    if (dotPosition === -1) {
                        while (decimalDigits.length < decimals) {
                            decimalDigits += '0';
                        }
                        return value + '.' + decimalDigits;
                    }
                    decimalDigits = (value + '').substring(dotPosition + 1);
                    if (decimalDigits.length > decimals) {
                        return parseFloat(value).toFixed(decimals);
                    }
                    while (decimalDigits.length < decimals) {
                        decimalDigits += '0';
                    }
                    return (value + '').substring(0, dotPosition) + '.' + decimalDigits;
                }

                function getNumberOfLeadingZeros(value) {
                    var stringValue = value.toString();
                    var numberOfLeadingZeros = stringValue.search(/[^0]/); // find position of first non-zero value
                    if (stringValue.charAt(numberOfLeadingZeros) == decimalSeparator) { // eslint-disable-line eqeqeq
                        numberOfLeadingZeros--;
                    }
                    return numberOfLeadingZeros;
                }

                function padWithZeros(value, numberOfZeros) {
                    var paddedValue = value.toString();
                    while (numberOfZeros-- > 0) {
                        paddedValue = '0' + paddedValue;
                    }
                    return paddedValue;
                }

                function formatViewValue(value) {
                    return ngModelCtrl.$isEmpty(value) ? '' : '' + value;
                }

                /**
                 * Parse the view value.
                 */
                function parseViewValue(value) {
                    if (angular.isUndefined(value)) {
                        value = '';
                    }
                    value = value.toString().replace(allowedDecimalsSeparatorsRegex, '.');

                    // Handle leading decimal point, like ".5"
                    if (value.indexOf('.') === 0) {
                        value = '0' + value;
                    }

                    // Allow "-" inputs only when min < 0
                    if (value.indexOf('-') === 0) {
                        if (min >= 0) {
                            value = null;
                            ngModelCtrl.$setViewValue(formatViewValue(formatPrecision(lastValidValue)));
                            ngModelCtrl.$render();
                        } else if (value === '-') {
                            value = '';
                        }
                    }

                    var empty = ngModelCtrl.$isEmpty(value);
                    if (empty) {
                        lastValidValue = '';
                    } else {
                        if (regex.test(value) && (value.length <= maxInputLength)) {
                            if (value > max) {
                                lastValidValue = max;
                            } else if (value < min) {
                                lastValidValue = min;
                            } else {
                                lastValidValue = (value === '') ? null : ((allowLeadingZeros || value.length > 16) ? value : parseFloat(value));
                            }
                        } else {
                            // Render the last valid input in the field
                            ngModelCtrl.$setViewValue(formatViewValue(formatPrecision(lastValidValue)));
                            ngModelCtrl.$render();
                        }
                    }

                    if (angular.isDefined(parseToString) && parseToString === true) {
                        lastValidValue = lastValidValue.toString();
                    }

                    return lastValidValue;
                }

                /**
                 * Calculate the maximum input length in characters.
                 * If no maximum the input will be limited to 16; the maximum ECMA script int.
                 */
                function calculateMaxLength(value) {
                    var length = maxInputLength;
                    if (!angular.isUndefined(value)) {
                        length = Math.floor(value).toString().length;
                    }
                    if (decimals > 0) {
                        // Add extra length for the decimals plus one for the decimal separator.
                        length += decimals + 1;
                    }
                    if (min < 0) {
                        // Add extra length for the - sign.
                        length++;
                    }
                    return length;
                }

                /**
                 * Minimum value validator.
                 */
                function minValidator(value) {
                    if (!angular.isUndefined(min)) {
                        if (!ngModelCtrl.$isEmpty(value) && (value < min)) {
                            return min;
                        } else {
                            return value;
                        }
                    } else {
                        return value;
                    }
                }

                /**
                 * Maximum value validator.
                 */
                function maxValidator(value) {
                    if (!angular.isUndefined(max)) {
                        if (!ngModelCtrl.$isEmpty(value) && (value > max)) {
                            return max;
                        } else {
                            return value;
                        }
                    } else {
                        return value;
                    }
                }


                /**
                 * Function for handeling the blur (leave) event on the control.
                 */
                function onBlur() {
                    var value = ngModelCtrl.$modelValue;
                    if (!angular.isUndefined(value)) {
                        // Format the model value.
                        ngModelCtrl.$viewValue = formatPrecision(value);
                        if (self.roundViewValue){
                            ngModelCtrl.$viewValue = self.removeFloatingPart(ngModelCtrl.$viewValue);
                        }
                        ngModelCtrl.$render();
                    }
                }


                /**
                 * Function for handeling the focus (enter) event on the control.
                 * On focus show the value without the group separators.
                 */
                function onFocus() {
                    var value = ngModelCtrl.$modelValue;
                    if (!angular.isUndefined(value) && value !== null) {
                        ngModelCtrl.$viewValue = value.toString().replace(allowedDecimalsSeparatorsRegex, decimalSeparator);
                      if (self.roundViewValue){
                        ngModelCtrl.$viewValue = self.removeFloatingPart(ngModelCtrl.$viewValue);
                      }
                        ngModelCtrl.$render();
                    }
                    if (selectOnFocus === true) {
                        el.select();
                    }
                }

                function formatInitialValue() {
                    $timeout(function() {
                        onBlur();
                    }, 0);
                }

                this.removeFloatingPart = function(value) {
                    if (typeof value !== 'string'){
                        return value;
                    } else {
                      var parts = value.split(/[,.]/g);
                      return parts.length > 1 ? parts[0] : value;
                    }
                };
            }
        };
    }]);
