angular.module('lsnBase.validators')
  .directive('dateFromBeLaterThanDateTo', function() { // dziala tylko onBlur. Ustawia poprawny adres url dla linka zewntrznego lub wewnetrznego
   return {
     restrict: 'A',
     require: 'ngModel',
     link: function(scope, elem, attr, ngModel) {
          var hourTo = null;
          var hourFrom = null;
          var dateFrom = null;

          var getValid = function(value)
          {
            if(typeof hourTo !== 'undefined' && typeof hourFrom !== 'undefined' && typeof dateFrom !== 'undefined' &&
               hourTo !== null && hourFrom !== null && dateFrom !== null && !/^(?:[01]\d|2[0-3]):([0-5]\d)$/.test(hourFrom) &&
                    !/^(?:[01]\d|2[0-3]):([0-5]\d)$/.test(hourTo)){

                return true;
            }

            var dFrom = new XDate(dateFrom), dTo = new XDate(value);
            if(!dateFrom || new Date(dateFrom).toString() === 'Invalid Date' || !dFrom.valid() || !value || new Date(value).toString() === 'Invalid Date' || !dTo.valid() ) // XDate uznaje daty typu 9999-99-99 za prawidłowe
            {
              return true;
            }

            if(dTo.diffDays(dFrom) === 0 && hourTo < hourFrom){
              return false;
            }
            return true;
          };

          var setValidity = function(value) {
            var valid = getValid(value);
            ngModel.$setValidity('minOnHour', valid);

            return value;
          };

          // obserwujemy daty polisy, bo mogą zostać załadowane, przeładowane
          attr.$observe('dateFrom', function(value) {
            if(typeof value === 'string' && value !== '')
            {
              dateFrom = value;
            }
            else
            {
              dateFrom = null;
            }
            setValidity(ngModel.$viewValue);
          });

          attr.$observe('hourFrom', function(value) {
            if(typeof value === 'string' && value !== '')
            {
              hourFrom = value;
            }
            else
            {
              hourFrom = null;
            }
            setValidity(ngModel.$viewValue);
          });

          attr.$observe('hourTo', function(value) {
            if(typeof value === 'string' && value !== '')
            {
              hourTo = value;
            }
            else
            {
              hourTo = null;
            }
            setValidity(ngModel.$viewValue);
          });

          // For DOM -> model validation
          ngModel.$parsers.unshift(setValidity);

          // For model -> DOM validation
          ngModel.$formatters.unshift(setValidity);
     }
   };
 });