angular.module('salesPath2')
  .service('vehicleCepHelper', ['CONFIG', 'appVariables', 'CONSTANTS', 'renewalHelper',
    function(CONFIG, appVariables, CONSTANTS, renewalHelper) { // eslint-disable-line angular/di
      var VehicleCepHelper = function() {
        var self = this;

        var DEFAULT_DAATA = {
          enabled: false, // is CEP module enabled
          form: {
            active: true,
            vin: null,
            registration: null,
            searchByVin: false
          }
          // add more below....
        };

        this.data = _.cloneDeep(DEFAULT_DAATA);
        this.responseData = null;

        /**
         * init helper data and return this.data
         * @return {Object}
         */
        this.initData = function() {
          // self.data = _.cloneDeep(DEFAULT_DAATA);
          self.data.enabled = self.isCepEnabled();
          return self.data;
        };

        this.isCepEnabled = function() {
          return CONFIG.BEHAVIOR.vehicle.cep && appVariables.appMode === CONSTANTS.APP_MODE_CALCULATION && !appVariables.readOnly
            && !appVariables.isSupplement && !appVariables.isOcBuyer && !renewalHelper.isRenewal();
        };

        this.getSearchPhrase = function(respData) {
          var props = ['mark', 'model', 'type', 'bodyDesc', 'capacity', 'doorCount', 'fuelDesc', 'power'];
          var parts = [];
          if (_.isArray(respData.itsEtxData)) {
            angular.forEach(props, function(propName) {
              var cepProperty = _.find(respData.itsEtxData, function(propData) {
                return propData.property === propName && propData.items.length === 1;
              });
              if (!cepProperty) {
                switch (propName) {
                  case 'mark':
                  case 'model':
                    if (respData.input[propName]) {
                      parts.push(respData.input[propName]);
                    }
                    break;
                  case 'bodyDesc':
                    if (respData.input.body) {
                      parts.push(respData.input.body);
                    }
                    break;
                  case 'capacity':
                    if (respData.input.engine) {
                      parts.push(respData.input.engine);
                    }
                    break;
                  case 'power':
                    if (respData.input.powerKw) {
                      parts.push(respData.input.powerKw);
                    }
                    break;
                  case 'fuelDesc':
                    if (respData.responseVehicleExtendedData.fuelDesc) {
                      parts.push(respData.responseVehicleExtendedData.fuelDesc);
                    }
                    break;
                  default:
                }
              }
              if (cepProperty) {
                parts.push(_.first(cepProperty.items).name);
              }
            });
          }
          return _.trim(parts.join(' '));
        };

        this.clearSearchModalData = function() {
          self.data = _.cloneDeep(DEFAULT_DAATA);
          self.data.enabled = self.isCepEnabled();
          self.responseData = null;
          return self;
        };

        this._setCepDataForVehicle = function(vehicleModel) {
          if (!self.data.enabled || !self.data.form.active) {
            return false;
          }
          if (self.data.form.searchByVin) {
            vehicleModel.set('vin', self.data.form.vin);
          } else {
            vehicleModel.set('registration', [self.data.form.registration]);
          }
          vehicleModel.set('firstRegistration', _.get(self.responseData, 'responseVehicleExtendedData.firstRegistrationDate', null));
          vehicleModel.set('mileage', _.get(self.responseData, 'responseVehicleExtendedData.mileage', null));
          var rightSideSteeringWheel = _.get(self.responseData, 'responseVehicleExtendedData.rightSideSteeringWheel', null);
          vehicleModel.setAdditionalData(self._cepRightSideSteeringWheelValue(rightSideSteeringWheel), 'rightHandDrive');
          vehicleModel.set('vin', _.get(self.responseData, 'responseVehicleExtendedData.vin', null));
          var registration = _.get(self.responseData, 'responseVehicleExtendedData.registrationNo', null);
          if (registration) {
            vehicleModel.set('registration', [registration]);
          }
          return true;
        };

        self._cepRightSideSteeringWheelValue = function(cepValue) {
          switch (cepValue) {
            case 'T':
              return true;
            case 'N':
              return false;
            default:
              return null;
          }
        };

      };

      return new VehicleCepHelper();
    }
  ]);
