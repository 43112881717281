angular.module('lsnBase.autocompleters')
  .directive('lsnPkdPopoverUnsupported', ['$popover', function($popover) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        var options = {
          template: 'lsn-base-templates-app/directives/popover/popover.tpl.html',
          trigger: 'manual',
          placement: 'left',
          content: 'Wybrany kod PKD jest niedostępny w produkcie ' + attrs.appName
        };
        if (parseInt(attrs.supported, 10) === 0) {
          var ts = $popover(element, options);
          ts.$promise.then(function() {
            ts.show();
          }, angular.noop);
        }
      }
    };
  }]);