angular.module('ihestiaWidgets.extraLife')
  .directive('ihestiaExtraLifeButtonDrv', ['ihestiaExtraLifeConfig', '$log', 'ihestiaExtraLifeHelper',
    function(ihestiaExtraLifeConfig, $log, ihestiaExtraLifeHelper) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: function() {
          return ihestiaExtraLifeConfig.buttonTemplate;
        },
        controller: ['$scope',
          function($scope) {
            $scope.params = ihestiaExtraLifeHelper.params;

            $scope.showPopup = function() {
              ihestiaExtraLifeHelper.showPopup();
            };

          }],
        link: function($scope, elem) {
          var domRect = elem[0].getBoundingClientRect();
          // set popup position
          ihestiaExtraLifeHelper.params.popup.posBottom = domRect.top;
          ihestiaExtraLifeHelper.params.popup.posRight = domRect.left;
        }
      };
    }
  ]);