angular.module('ihestiaRestServicesBase')
  .service('lsnDictionarySvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var LsnDictionarySvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'dictionary';
        this.businessArea = null; //zmienne businessArea iszkody/pza
      };

      return new LsnDictionarySvc();
    }]);