angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorDashboardListHelper', ['iKomunikatorConstants', 'iKomunikatorMessagesThreadSvc', 'IKomunikatorMessageThreadModel', 'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorNotificationBarHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function(iKomunikatorConstants, iKomunikatorMessagesThreadSvc, IKomunikatorMessageThreadModel, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorNotificationBarHelper, ihestiaIkomunikatorUserTypeHelper, ihestiaIkomunikatorLoaderMessagesHelper) {

      var FullListPanelCentralHelper = function() {
        var self = this;

        this.defaultData = {
          searchFilters: {
            status: iKomunikatorConstants.STATUS_ALL,
            criteria: {},
            orderSpec: {
              direction: 'DESC',
              sortBy: 'dateFrom'
            },
            pageSpec:{
              pageNo: 0,
              pageSize: 20
            }
          },
          subjects: [],
          threads: [],
          loading: false,
          total: 0,
          showDetailsTread: {},
          showsAddingMessage: {},
          additionalHeaderVisibile: {},
          dashboardTaskId: null
        };

        this.tplData = angular.copy(self.defaultData);

        this.init = function() {
          this.centralUserLogged = ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged();
          self.tplData.searchFilters.criteria.taskId = self.tplData.dashboardTaskId;
          self.reloadSearch();
        };

        this.initSearch = function() {
          self.tplData.loading = true;
          
          iKomunikatorMessagesThreadSvc.findDashboardMessageThreads(self.tplData.searchFilters, function(result) {
            // messageThreadRepresentersSvc.findReceivedMessages(null, function(result) {
            if (angular.isObject(result.data)) {
              self.setAnswerFromSearch(result.data);
              self.tplData.cacheId = result.data.resourceId;
            }

            self.tplData.loading = false;
          }, function() {
            self.tplData.loading = false;
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania wiadomości');
          });
        };

        this.setAnswerFromSearch = function(data) {
          self.tplData.total = data.total;
          if (angular.isArray(data.threads)) {
            angular.forEach(data.threads, function(threadData) {
              var thread = new IKomunikatorMessageThreadModel();
              thread.fromRest(threadData);
              self.tplData.threads.push(thread);
              self.setAdditionalHeaderVisibility(thread);
            });
          }
        };

        this.reloadSearch = function() {
          if (!self.tplData.loading) {
            self.tplData.searchFilters.pageSpec.pageNo = 0;
            self.tplData.loading = true;
            self.tplData.threads = [];

            self.initSearch();
          }
        };

        this.reloadThread = function(threadId){
          var position = self.getPositionThreadById(threadId);

          iKomunikatorMessagesThreadSvc.findDashboardMessageThread(threadId, function(result) {
            if (angular.isObject(result.data)) {
              var thread = new IKomunikatorMessageThreadModel();
              thread.fromRest(result.data);
              self.tplData.threads[position] = thread;

              self.setAdditionalHeaderVisibility(thread);
            } else {
              ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła wątku wiadomości');
            }

            ihestiaIkomunikatorNotificationBarHelper.reloadNumberMessage();
          }, function(){
            ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła wątku wiadomości');
          });
        };

        this.setAdditionalHeaderVisibility = function(thread){
          self.tplData.additionalHeaderVisibile[thread.threadId] = self.centralUserLogged && ['ALERT', 'KOMUNIKAT', 'WWW'].indexOf(thread.getLastMessageChannel()) === -1;
        };

        this.getThreadById = function(threadId) {
          var result = null;
          if (angular.isArray(self.tplData.threads)) {
            angular.forEach(self.tplData.threads, function(thread) {
              if (thread.threadId === threadId) {
                result = thread;
              }
            });
          }

          return result;
        };

        this.getPositionThreadById = function(threadId) {
          var position = null;
          if (angular.isArray(self.tplData.threads)) {
            angular.forEach(self.tplData.threads, function(thread, pos) {
              if (thread.threadId === threadId) {
                position = pos;
              }
            });
          }

          return position;
        };

        /**
         * [loadFromCache funkcja wyszukuje wiadomości z cahce zgodnie z ustawionymi paramtrami wyszukiwanie]
         */
        this.loadFromCache = function(){
          self.tplData.loading = true;
          
          iKomunikatorMessagesThreadSvc.findThreadsFromCache(self.tplData, function(result) {
            // messageThreadRepresentersSvc.findReceivedMessages(null, function(result) {
            if (angular.isObject(result.data)) {
              self.setAnswerFromSearch(result.data);
            }

            self.tplData.loading = false;
          }, function(){
            self.tplData.loading = false;
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania wiadomości');
          });
        };

        this.loadNextResult = function() {
          if (!self.tplData.loading && !ihestiaIkomunikatorLoaderMessagesHelper.loadedAllThreads(self.tplData)) {
            self.tplData.searchFilters.pageSpec.pageNo += 1;
            self.loadFromCache();
          }
        };

        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };
      };

      return new FullListPanelCentralHelper();
    }
  ]);