angular.module('ihestiaRestServicesBase')
    .service('offersManualUnderwritingSvc', ['IHestiaRestServiceAbstractDef',
        function(IHestiaRestServiceAbstractDef) {

            var OffersManualUnderwriting = function() {
                IHestiaRestServiceAbstractDef.apply(this, arguments);
                var self = this;

                this.businessArea = 'insurance';
                this.apiVersion = 'v2';
                this.basePattern = '{host}{port}{path}/{apiString}/{businessArea}/{apiVer}{id}{subResource}';

                this.getOffers = function(applicationNumber) {
                    return self.get(applicationNumber, null, 'offersManualUnderwriting');
                };
            };
            return new OffersManualUnderwriting();
        }
    ]);