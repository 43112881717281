angular.module('ihestiaMenuTopMenu')
  .controller('ihestiaMenuSaleLifeCtrl', ['$scope', 'ihestiaConfigHelper', '$window', 'ihestiaAgHelper',
    function($scope, ihestiaConfigHelper, $window, ihestiaAgHelper){

      $scope.lifePermissions = {
        sirius: ihestiaAgHelper.canAccessSyriusz(),
        lifeSalePath: ihestiaAgHelper.canAccessLifeSalePathApp()
      };

      $scope.init = function() {
      };

      /**
       * Idziemy do zewnętrznego urla
       * @param  {string} urlCode [description]
       */
      $scope.gotoExternal = function(urlCode, available) {
        if(angular.isUndefined(available) || available){
          $window.open( ihestiaConfigHelper.getUrl(urlCode));
        }
      };

      $scope.gotoSirius = function() {
        if($scope.lifePermissions.sirius)
        {
          $scope.gotoExternal('SYRIUSZ_URL');
        }
      };

      $scope.gotoLifeSalePath = function() {
        if($scope.lifePermissions.lifeSalePath)
        {
          $scope.gotoExternal('E4_URL');
        }
      };

    }
  ]);
