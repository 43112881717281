angular.module('lsnBase.validators')
        .directive('validEmail', function() {
          return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {

              var setValidity = function(email) {
                var isValid = false;
                if (!angular.isString(email)) {
                  email = '';
                }
                if (email === '') {
                  isValid = true;
                } else {
                  var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
                  isValid = exp.test(email);
                }
                ngModel.$setValidity('email', isValid);
                return email;
              };

              setValidity(element.val());

              // For DOM -> model validation
              ngModel.$parsers.unshift(setValidity);

              // For model -> DOM validation
              ngModel.$formatters.unshift(setValidity);
            }
          };
        });