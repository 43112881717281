angular.module('ihestiaSsoBase')
  .service('sessionExpiredEventHelper', ['$rootScope', 'ihestiaCommonConfirmModalHelper', '$window', '$filter',
    function($rootScope, ihestiaCommonConfirmModalHelper, $window, $filter) {

      var SessionExpiredEventHelper = function() {
        var self = this;

        /**
         * Init
         * @return {SessionExpiredEventHelper} [description]
         */
        this.init = function() {
          self.fireListeners();
          return self;
        };

        /**
         * Słuchamy wydarzeń
         * @return {SessionExpiredEventHelper} [description]
         */
        this.fireListeners = function() {
          $rootScope.$on('iHestia.Session.Expired', function() {
            self.showSessionExpiredModal();
          });

          $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
            if (data.code === 'sessionExpired') {
              self.showSessionExpiredModal();
            }
          });

          return self;
        };

        /**
         * Pokazuje modal "sesja wygasła"
         * @return {SessionExpiredEventHelper} [description]
         */
        this.showSessionExpiredModal = function() {
          $rootScope.displayingErrors = true;
          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('Security.sessionExpired'),
            text: $filter('translate')('Security.sessionExpiredText'),
            okBtnName: $filter('translate')('Security.sessionExpiredOkBtn'),
            cancelBtnName: '',
            keyboard: false,
            errorMessage: true,
            okBtnCallback: function() {
              $window.location.reload();
            }
          });
          return self;
        };

      };

      return new SessionExpiredEventHelper();
    }
  ]).run(['sessionExpiredEventHelper',
    function(sessionExpiredEventHelper) {
      sessionExpiredEventHelper.init();
    }
  ]);