angular.module('ihestiaSsoBase')
  .controller('passwordExpiredCtrl', ['$scope', 'iHestiaCommonModalHelper',
    function($scope, iHestiaCommonModalHelper) {

      $scope.applicationErrorBodyTemplate = 'ihestia-sso-template-app/sso/passwordExpired/passwordExpired.tpl.html';

      /**
       * Zmiana hasła
       */
      $scope.changePassword = function() {
        iHestiaCommonModalHelper.showModal('ihestiaPasswordChange');
      };
    }
  ]);