/**
 * Ukrywa element przez visibility:hidden
 */
angular.module('lsnBase')
  .directive('bodyInjector', ['$compile', 'bodyInjectorHelper', function($compile, bodyInjectorHelper){
      return {
        restrict: 'E',
        link: function($scope, $element){
          var self = this;

          $scope.directives = bodyInjectorHelper.directives;
          $scope.domElements = {};

          /**
           * głowne $scope'y dyrektyw
           * @type {Object}
           */
          $scope.scopes = {};

          /**
           * Dyrektywa się zmieniłą
           * @param  {object} newDirectives [description]
           * @param  {object} oldDirectives [description]
           */
          this.directivesChanged = function(newDirectives, oldDirectives)
          {
            if(typeof oldDirectives === 'undefined')
            {
              oldDirectives = [];
            }
            angular.forEach(oldDirectives, function(directive, key){
              if(typeof newDirectives[key] === 'undefined')
              {
                self.removeDirective(key);
              }
            });
            angular.forEach(newDirectives, function(directive, key){
              if(typeof oldDirectives[key] === 'undefined')
              {
                self.addDirective(key);
              }
            });
          };

          /**
           * Dodawanie dyrektywy
           * @param {string} key [description]
           */
          this.addDirective = function(key)
          {
            var directiveData = $scope.directives[key];
            var paramsString = ' modal-drv-key="'+ key + '" ';
            angular.forEach(directiveData.params, function(paramValue, paramKey){
              paramsString += paramKey + '="' + paramValue + '" ';
            });
            var childScope = $scope.$new();
            $element.append($compile('<'+directiveData.directive + paramsString + '/>')(childScope));
            var children = $element.children();
            $scope.domElements[key] = children[children.length - 1];
            $scope.scopes[key] = childScope;
          };

          /**
           * usuwa element dyrektywy z html'a
           * @param  {[type]} key [description]
           * @return {[type]}     [description]
           */
          this.removeDirective = function(key)
          {
            $scope.scopes[key].$destroy();
            // w IE nie ma remove()
            $scope.domElements[key].parentNode.removeChild($scope.domElements[key]);

            delete $scope.scopes[key];
            delete $scope.domElements[key];
          };

          self.directivesChanged($scope.directives, {});
          $scope.$watch('directives', self.directivesChanged, true);
        }
      };
    }]);