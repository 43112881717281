/**
 * Mapper ryzyk
 */
angular.module('salesPath2').service('toRestRiskMapper', ['MapperUtils', 'ToRestTempRiskModel', 'mainDataContainer', 'sp2CommonHelper', 'CONSTANTS', 'CONFIG', 'RiskModel', 'SPD', 'InsuredObjectModel', 'toRestAttributeMapper', 'RESOURCES', 'extensionHelper', 'resourceHelper', 'arrayUpperCaseModelFilter', 'appVariables', 'dataContainerHelper',
  function(MapperUtils, ToRestTempRiskModel, mainDataContainer, sp2CommonHelper, CONSTANTS, CONFIG, RiskModel, SPD, InsuredObjectModel, toRestAttributeMapper, RESOURCES, extensionHelper, resourceHelper, arrayUpperCaseModelFilter, appVariables, dataContainerHelper) {
    var ToRestRiskMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.mapRisks = function(dataConteiner) {
        var selectedVariants = dataConteiner.selectedVariants,
          sums = dataConteiner.suList,
          estates = dataConteiner.localizations,
          persons = dataConteiner.persons,
          vehicles = dataConteiner.vehicles,
          groups = dataConteiner.groups,
          mainInsuredId = self.utils.getSubjectMetadataId(dataConteiner.mainInsuredId);
        var risks = [];
        risks = risks.concat(self._mapPropertyRisks(estates, selectedVariants, sums, mainInsuredId, (CONFIG.BEHAVIOR.propertyOneProtectionPeriod ? dataConteiner.propertyProtectionDates : null)));
        risks = risks.concat(self._mapPersonalRisks(dataConteiner));
        risks = risks.concat(self._mapCommunicationRisks(vehicles, selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE], mainInsuredId));
        if (CONFIG.BEHAVIOR.personGroups) {
          risks = risks.concat(self._mapPersonGroupRisks(dataConteiner.persons, dataConteiner.groups, selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP], dataConteiner.propertyProtectionDates));
        }
        if (CONFIG.MODULES.additions && (CONFIG.BEHAVIOR.additionsWithoutRisks || risks.length > 0)) { //nie wysyłamy dodatków jeśli żaden produkt nie jest wysyłany
          risks = risks.concat(self._mapAdditionRisks(dataConteiner.selectedAdditions, mainInsuredId, estates, persons, vehicles, groups));
        }
        risks = risks.concat(self._mapExtraRisks(dataConteiner));
        return risks;
      };
      //mapuje dodatkowe ryzyka, spoza ogólnych reguł
      this._mapExtraRisks = function(dataConteiner) {
        var risks = [];
        //NNW forma bezimienna
        if (mainDataContainer.nnwIncognito !== null) {
          var selectedVariant = null;
          lsnNg.forEach(dataConteiner.nnwIncognito.variants, function(selected, variant) {
            if (selected) {
              selectedVariant = variant;
              return false;
            }
            return true; //continue
          });
          if (selectedVariant !== null) {
            var attrs = {
              insuredNo: dataConteiner.nnwIncognito.insuredNo
            };
            risks.push(self._getPersonalRiskObj(CONSTANTS.PRODUCT_NNW_INCOGNITO, dataConteiner.persons[dataConteiner.mainInsuredId], selectedVariant, dataConteiner.nnwIncognito.su[selectedVariant], self._getPersonalRiskDates(dataConteiner, CONSTANTS.PRODUCT_NNW), attrs));
          }
        }
        return risks;
      };
      //#pragma mark additionRisks
      //mapuje ryzyka dodatkow
      //poniższe dodatki do ogarniecia po stronie konkretncyh aplikacji (e7/h7 itd) - metoda _getAdditionAttributes
      // ASSPOJ hca AssistanceOption
      // ASSMEDIC wariant  ? 'Podstawowy' : 'Rozszerzony';
      this._mapAdditionRisks = function(selectedAdditions, mainInsuredId, estates, persons, vehicles, groups) {
        var additionRisks = [],
          conf = null,
          productObjects = null;
        lsnNg.forEach(selectedAdditions, function(selection, addCode) {
          if (self.omitAddition(addCode)) {
            return;
          }
          conf = self.utils.getAdditionDefinition(addCode);
          if (selection) {
            switch (conf.TYPE) {
              case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
                productObjects = estates;
                break;
              case CONSTANTS.PRODUCT_TYPE_VEHICLE:
                productObjects = vehicles;
                break;
              case CONSTANTS.PRODUCT_TYPE_PERSON:
                productObjects = persons;
                break;
              case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
                productObjects = angular.extend({}, persons, groups);
                break;
              case CONSTANTS.PRODUCT_TYPE_PACKAGE:
                productObjects = {};
                break;
              default:
                sp2CommonHelper.throwException('No addition mapping available for product type {0}'.format(conf.TYPE));
            }
            if (angular.isDefined(conf.VARIANTS)) { //tablica wariantów dodatku
              lsnNg.forEach(selection, function(subSelection, variant) {
                if (self.omitAddition(addCode, variant)) {
                  return;
                }
                if (typeof subSelection === 'boolean' && subSelection) { //dodatki pakietowe
                  additionRisks.push(self._getAdditionRiskObj(conf, null, mainInsuredId, variant, productObjects));
                } else if (angular.isObject(subSelection)) {
                  lsnNg.forEach(subSelection, function(objId) {
                    if (parseInt(objId, 10) !== 0) {
                      additionRisks.push(self._getAdditionRiskObj(conf, objId, mainInsuredId, variant, productObjects));
                    }
                  });
                }
              });
            } else if (typeof selection === 'boolean') { //dodatek pakietowy - true/false
              additionRisks.push(self._getAdditionRiskObj(conf, null, mainInsuredId, null, productObjects));
            } else if (angular.isArray(selection) && selection.length > 0) { //tablica powiazancyh obiektow/podmiotow/aut
              lsnNg.forEach(selection, function(objId) {
                if (parseInt(objId, 10) !== 0) {
                  additionRisks.push(self._getAdditionRiskObj(conf, objId, mainInsuredId, null, productObjects));
                }
              });
            }
          }
        });
        return additionRisks;
      };
      /**
       * czy ominąć ryzyko dodatku w mapowaniu
       * @param {string} addCode kod ryzyka
       * @param {string|null|undefined} variant wariant dodatku
       * @return {boolean}
       */
      this.omitAddition = function(addCode, variant) {
        variant = (typeof variant === 'undefined') ? null : variant;
        var omit = false;
        lsnNg.forEach(CONFIG.BEHAVIOR.additionsToOmit, function(addData) {
          if (addData[0] === addCode && ((angular.isDefined(addData[1]) && addData[1] === variant) || angular.isUndefined(addData[1]))) {
            omit = true;
            return false;
          }
          return true; //continue
        });
        return omit;
      };
      /**
       * zwraca obiekt ryzyka dla dodatku
       * @param  {Object} addDef definicja dodatku
       * @param  {[type]} objId         [description]
       * @param  {[type]} mainInsuredId [description]
       * @param  {[type]} variant       [description]
       * @param  {[type]} objects       [description]
       * @return {[type]}               [description]
       */
      this._getAdditionRiskObj = function(addDef, objId, mainInsuredId, variant, objects) {
        var dates = self._getAdditionInsuranceDates(addDef.CODE, addDef.TYPE, objId, objects),
          attrs = self._getAdditionAttributes(addDef, objId, mainInsuredId, variant, objects);
        variant = self._getAdditionVariant(addDef.CODE, addDef.TYPE, objId, mainInsuredId, variant, objects);
        var riskData = {
          'attributes': attrs,
          'code': addDef.CODE,
          'prodCode': addDef.CODE,
          'sumInsured': null,
          'variant': variant,
          'start': dates.start,
          'end': dates.end,
          'insuredId': mainInsuredId,
          'subjectRefs': [],
          'objectRefs': []
        };
        switch (addDef.TYPE) {
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION: //objects to estates
            riskData.objectRefs.push(self.utils.getEstateMetadataId(objId));
            riskData.subjectRefs.push([CONSTANTS.SUBJECT_INSURED, riskData.insuredId]);
            break;
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            riskData.subjectRefs.push([CONSTANTS.SUBJECT_OWNER, riskData.insuredId]);
            var vehicleAddData = objects[objId].getAdditionalData();
            if (angular.isObject(vehicleAddData.coowners)) {
              lsnNg.forEach(vehicleAddData.coowners, function(personId) {
                riskData.subjectRefs.push([CONSTANTS.SUBJECT_COOWNER, self.utils.getSubjectMetadataId(personId)]);
              });
            }
            //leasing
            if (typeof vehicleAddData.isLeased !== 'undefined' && vehicleAddData.isLeased === true && typeof vehicleAddData.leaserId !== 'undefined' && vehicleAddData.leaserId !== null) {
              riskData.subjectRefs.push([CONSTANTS.SUBJECT_LEASING, self.utils.getSubjectMetadataId(vehicleAddData.leaserId)]);
            }
            riskData.objectRefs.push(self.utils.getVehicleMetadataId(objId));
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
            var isPerson = angular.isDefined(mainDataContainer.persons[objId]);
            if (isPerson) {
              riskData.insuredId = self.utils.getSubjectMetadataId(objId);
              riskData.subjectRefs.push([CONSTANTS.SUBJECT_INSURED, riskData.insuredId]);
            } else {
              riskData.attributes._groupId = self.utils.getSubjectMetadataId(objId);
              riskData.insuredId = null;
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON:
            var isMainInsured = self.utils.getSubjectMetadataId(objId) === mainInsuredId;
            riskData.subjectRefs.push([(isMainInsured ? CONSTANTS.SUBJECT_INSURED : CONSTANTS.SUBJECT_COINSURED), self.utils.getSubjectMetadataId(objId)]);
            riskData.insuredId = self.utils.getSubjectMetadataId(objId);
            break;
          case CONSTANTS.PRODUCT_TYPE_PACKAGE: //w e7 - ouz oraza bagaz
            riskData.subjectRefs.push([null, mainInsuredId]); //rola jedyna jaka wynika z SPD
            break;
          default:
            sp2CommonHelper.throwException('Unexpected product type \'{0}\' for addition risk mapper.'.format(addDef.TYPE));
        }
        var tmpRisk = new ToRestTempRiskModel(riskData);
        return self._buildRiskObject(tmpRisk);
      };
      /**
       * zwraca wariant dla mapowanego dodatku - do nadpisania w maperze konkretnego produktu
       */
      this._getAdditionVariant = function(addCode, prodType, objId, mainInsuredId, variant) {
        if (addCode === CONSTANTS.ADD_ASSMEDIC) {
          return (variant === CONSTANTS.VARIANT_I) ? CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_BASIC : CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_EXTENDED;
        } else {
          return variant;
        }
      };
      /**
       * zwraca atrybuty dla mapowanego dodatku - do nadpisania w maperze konkretnego produktu
       * @param  {Object} addDef definicja dodatku
       * @param  {[type]} objId         [description]
       * @param  {[type]} mainInsuredId [description]
       * @param  {[type]} variant       [description]
       * @param  {[type]} objects       [description]
       * @return {Object} atrybuty dodatku
       */
      this._getAdditionAttributes = function(addDef, objId, mainInsuredId, variant, objects) {
        var attrs = {},
          variantsDef = {};
        if (angular.isArray(addDef.VARIANTS)) {
          angular.forEach(addDef.VARIANTS, function(variantDef) {
            variantsDef[variantDef.CODE] = variantDef;
          });
        }
        if (addDef.CODE === CONSTANTS.ADD_GREENCARD) {
          attrs.greenCardNumber = self.utils.getGreenCaredNumber();
        }
        if (variant !== null && angular.isDefined(variantsDef[variant].ASSISTANCE_OPTION)) {
          attrs.assistanceOption = angular.isDefined(variantsDef[variant].ASSISTANCE_OPTION);
        }
        //przypadek dla wariantów dodatków, które są w usługach tylko atrybutami na innych wariantach dodatków
        if (variant !== null && RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE] && RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant]) {
          if (self.utils.isAdditionSelected(RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].addCode, objId, RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].addVariant)) {
            attrs[RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].attrName] = true;
          } else {
            attrs[RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].attrName] = false;
          }
        }
        //dla dodatków komunikacyjnych próbujemy dodać takie atrybuty jak do ryzyk
        if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
          self._mapCommunicationDynamicValues(attrs, objects[objId], addDef.CODE, null);
        }
        return attrs;
      };
      /**
       * zwraca daty ochrony dla dodatku
       * @param  {[type]} addCode  [description]
       * @param  {[type]} prodType [description]
       * @param  {[type]} objId    [description]
       * @param  {[type]} objects  [description]
       * @return {[type]}          [description]
       */
      this._getAdditionInsuranceDates = function(addCode, prodType, objId, objects) {
        var dates = {
          'start': mainDataContainer.defaultStartDate,
          'end': mainDataContainer.defaultEndDate
        };
        if (CONFIG.BEHAVIOR.isSingleProduct) { //jezeli pakiet typu solo
          switch (prodType) {
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
              dates = angular.copy(mainDataContainer.vehicles[dataContainerHelper.getSelectedObjectId(prodType)].getAdditionalData('protectionDates'));
              break;
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              if (!CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                dates = angular.copy(mainDataContainer.localizations[dataContainerHelper.getSelectedObjectId(prodType)].getAdditionalData('protectionDates'));
              } else {
                dates = mainDataContainer.propertyProtectionDates;
              }
              break;
            default:
              sp2CommonHelper.throwException('Nieobsługiwany rodzaj produktu dla aplikacji typu singleProduct.');
          }
        } else {
          switch (prodType) {
            case CONSTANTS.PRODUCT_TYPE_PERSON:
            case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
              if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                dates = mainDataContainer.propertyProtectionDates;
              }
              break;
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              if (!CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
                dates = angular.copy(objects[objId].getAdditionalData('protectionDates'));
              } else {
                dates = mainDataContainer.propertyProtectionDates;
              }
              break;
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
              dates = angular.copy(objects[objId].getAdditionalData('protectionDates'));
              break;
            default:
          }
        }
        return dates;
      };
      //#pragma mark propertyRisks
      /**
       * mapuje ryzyka majątkowe z DM do REST
       * @param {object} estates id: Estate
       * @param {object} selectedVariants warianty zaznaczone
       * @param {object} sums sumy ubezpieczen
       * @param {string} mainInsuredId metaData.id glownego ubezpieczonego
       * @param {null|object} propertyProtectionDates daty ochrony dla ryzyk lub null jeśli trzeba je pobrac z additionalData na nieruchomosci
       */
      this._mapPropertyRisks = function(estates, selectedVariants, sums, mainInsuredId, propertyProtectionDates) {
        var risks = [],
          propertyRisks = {};
        lsnNg.forEach(estates, function(estate, id) {
          if (parseInt(id, 10) < 1) {
            return;
          }
          propertyRisks[id] = {};
          propertyRisks[id][CONSTANTS.PRODUCT_OGI] = self._getPropertyRisksObjs(CONSTANTS.PRODUCT_OGI, estate.type);
          propertyRisks[id][CONSTANTS.PRODUCT_KRA] = self._getPropertyRisksObjs(CONSTANTS.PRODUCT_KRA, estate.type);
          self._addVariantToPropertyRisks(id, propertyRisks[id], selectedVariants);
          self._addSumInsuredToPropertyRisks(id, propertyRisks[id], sums);
          self._addDynamicValuesToPropertyRisks(propertyRisks[id], estate);
        });
        lsnNg.forEach(propertyRisks, function(products, estateId) {
          var estateProtectionDates = (propertyProtectionDates !== null) ? propertyProtectionDates : estates[estateId].getAdditionalData('protectionDates'),
            subjects = [],
            addData = estates[estateId].getAdditionalData(),
            floodingsNr = estates[estateId].getAdditionalData('flood'),
            anyFireRisk = false;
          subjects.push([CONSTANTS.SUBJECT_INSURED, mainInsuredId]);
          lsnNg.forEach(estates[estateId].getAdditionalData('coowners'), function(personId) {
            subjects.push([CONSTANTS.SUBJECT_COINSURED, self.utils.getSubjectMetadataId(personId)]);
          });
          //leasing
          if (typeof addData.leaserId !== 'undefined' && addData.leaserId !== null) {
            subjects.push([CONSTANTS.SUBJECT_BANK, self.utils.getSubjectMetadataId(addData.leaserId)]);
          }
          lsnNg.forEach(products, function(prodRisks, prodCode) {
            lsnNg.forEach(prodRisks, function(tmpRisk, riskCode) {
              var productProtectionDates = (propertyProtectionDates !== null) ? propertyProtectionDates : estateProtectionDates[prodCode],
                isConnectedFireVariant = (prodCode === CONSTANTS.PRODUCT_OGI && tmpRisk.variant && self.utils.isConnectedFireVariant(tmpRisk.variant));
              //jesli wariant ryczaltowy(polaczony) to przepuszczamy tylko ryzyko od murów
              if (isConnectedFireVariant) {
                if (tmpRisk.code === CONSTANTS.RISK_ESTATE) {
                  //mapujemy i dodajemy ryzyko
                  tmpRisk.sumInsured = null;
                  self._mapPropertyRisk(tmpRisk, productProtectionDates, estates[estateId].get('type'), prodCode, mainInsuredId, subjects, estates[estateId].metaData.get('id'));
                  risks.push(self._buildRiskObject(tmpRisk));
                  anyFireRisk = true;
                }
                return true;
              }
              //pozostałe przypadki
              if (tmpRisk.sumInsured > 0 && tmpRisk.variant && self.utils.isPropertyRiskAvailableInVariant(riskCode, tmpRisk.variant)) {
                if (prodCode === CONSTANTS.PRODUCT_OGI) {
                  anyFireRisk = true;
                }
                if (tmpRisk.code === CONSTANTS.RISK_OUTBUILDINGS) { //budynki gospodarcze
                  self._mapPropertyRisk(tmpRisk, productProtectionDates, estates[estateId].get('type'), prodCode, mainInsuredId, subjects);
                  self._mapOutBuildingsRisks(risks, prodCode, tmpRisk, estates[estateId]);
                } else {
                  //mapujemy i dodajemy ryzyko
                  self._mapPropertyRisk(tmpRisk, productProtectionDates, estates[estateId].get('type'), prodCode, mainInsuredId, subjects, estates[estateId].metaData.get('id'));
                  risks.push(self._buildRiskObject(tmpRisk));
                }
              }

              return true; //continue
            });
          });
          if (anyFireRisk && floodingsNr !== null) { //jak nie ma żadnego ryzyka ogniowego to nie dokładamy powodzi
            risks.push(self._mapFloodRisk(floodingsNr, estates[estateId], subjects, mainInsuredId, (propertyProtectionDates !== null ? propertyProtectionDates : estateProtectionDates[CONSTANTS.PRODUCT_OGI])));
          }
        });
        return risks;
      };
      /**
       * mapuje ryzyko majątkowe
       * @param {ToRestTempRiskModel} tmpRisk tymczasowe ryzyko
       * @return {ToRestTempRiskModel}
       */
      this._mapPropertyRisk = function(tmpRisk, protectionDates, estateType, prodCode, mainInsuredId, subjects, objectId) {
        tmpRisk.estateType = estateType;
        tmpRisk.start = protectionDates.start;
        tmpRisk.end = protectionDates.end;
        tmpRisk.prodCode = prodCode;
        tmpRisk.insuredId = mainInsuredId;
        tmpRisk.subjectRefs = subjects;
        tmpRisk.objectRefs = objectId ? [objectId] : [];
        return tmpRisk;
      };
      /**
       * mapuje ryzyka budynków ospodarczych
       */
      this._mapOutBuildingsRisks = function(risks, prodCode, tmpRisk, estate) {
        var buildingsContainer = estate.getAdditionalData('outBuildings');
        if (buildingsContainer !== null && angular.isDefined(buildingsContainer[prodCode])) { //przypadek aktualnie nie obslugiwany - w nowych produktach
          lsnNg.forEach(buildingsContainer[prodCode], function(buildingData) {
            tmpRisk.sumInsured = buildingData.su;
            tmpRisk.attributes = angular.extend(tmpRisk.attributes, {
              insuredObjectDesc: buildingData.desc
            });
            tmpRisk.objectRefs = [estate.metaData.get('id')];
            risks.push(self._buildRiskObject(tmpRisk));
          });
        } else { //ryzyko bez podpietego obiektu - podpinamy obiekt lokalizacji
          tmpRisk.objectRefs = [estate.metaData.get('id')];
          risks.push(self._buildRiskObject(tmpRisk));
        }
      };
      //mapuje ryzyko powodzi
      this._mapFloodRisk = function(floodingsNr, estate, subjectRefs, mainInsuredId, protectionDates) {
        floodingsNr = parseInt(floodingsNr, 10);

        var attributes = {};
        if (floodingsNr !== -2) {
          attributes.nrOfFloodings = (floodingsNr === -1) ? 3 : floodingsNr;
        }

        var tmpRisk = new ToRestTempRiskModel({
          start: protectionDates.start,
          end: protectionDates.end,
          prodCode: CONSTANTS.PRODUCT_OGI,
          code: CONSTANTS.RISK_FLOOD,
          insuredId: mainInsuredId,
          subjectRefs: subjectRefs,
          objectRefs: [estate.metaData.get('id')],
          attributes: attributes,
          estateType: estate.get('type')
        });

        return self._buildRiskObject(tmpRisk);
      };
      //dodaje warianty do ryzyk mieniowych
      this._addVariantToPropertyRisks = function(estatesId, propertyRisks, selectedVariants) {
        if (selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] && selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId]) {
          lsnNg.forEach(propertyRisks, function(data, prodType) {
            lsnNg.forEach(data, function(riskData, riskCode) {
              propertyRisks[prodType][riskCode].variant = selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId][prodType];
            });
          });
        }
        return propertyRisks;
      };
      //dodaje sumy ubezpieczen do ryzyk mieniowych
      this._addSumInsuredToPropertyRisks = function(estatesId, propertyRisks, sums) {
        if (sums[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] && sums[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId]) {
          lsnNg.forEach(propertyRisks, function(data, prodType) {
            lsnNg.forEach(data, function(riskData, riskCode) {
              if (sums[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId][prodType] && typeof sums[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId][prodType][riskCode] !== 'undefined') {
                riskData.sumInsured = sums[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][estatesId][prodType][riskCode];
              }
            });
          });
        }
        return propertyRisks;
      };
      //dodaje inicjalne dynamicValues do ryzyk majątkowych. Ostatecznie będą one weryfikowane i ewentualnie usuwane w AttributeMapperze
      this._addDynamicValuesToPropertyRisks = function(propertyRisks, estate) {
        var dynValsToCopy = self._getEstateDynamicValuesForPropertyRisk(estate);
        lsnNg.forEach(propertyRisks, function(data) {
          lsnNg.forEach(data, function(riskData) {
            angular.extend(riskData.attributes, dynValsToCopy);
          });
        });
        return propertyRisks;
      };

      this._getEstateDynamicValuesForPropertyRisk = function(estate) {
        var dynVals = estate.get('dynamicValues');
        lsnNg.forEach(dynVals, function(val, name) {
          if (name.substring(0, 1) === '_') {
            delete dynVals[name];
          }
        });
        return dynVals;
      };
      //zwraca obiekt z kodami ryzyk i podstawową strukturą potrzebna do zmapowania ognia/kradziezy
      //obsluzyc przypadki e7, e1
      this._getPropertyRisksObjs = function(productCode, estateType) {
        var risks = {};
        lsnNg.forEach(resourceHelper.getFireBurglaryRisksListAsArray(estateType, productCode), function(data) {
          risks[data.code] = new ToRestTempRiskModel({
            'code': data.code
          });
        });
        return risks;
      };
      //#pragma mark personalRisks
      //mapuje ryzyka osobowe
      this._mapPersonalRisks = function(dataConteiner) {
        var personalRisks = [],
          selectedVariants = dataConteiner.selectedVariants;
        lsnNg.forEach([CONSTANTS.PRODUCT_OCZP, CONSTANTS.PRODUCT_NNW], function(product) {
          if (!resourceHelper.isProductAvailable(product)) {
            return true;
          }
          if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(product) !== -1 && angular.isObject(selectedVariants[product])) {
            lsnNg.forEach(selectedVariants[product], function(selected, variant) {
              if (selected) {
                self._mapPersonalRiskForPersons(dataConteiner, product, personalRisks, variant);
              }
            });
          } else if (selectedVariants[product] !== null) {
            self._mapPersonalRiskForPersons(dataConteiner, product, personalRisks, selectedVariants[product]);
          }

          return true; //continue
        });
        return personalRisks;
      };
      /**
       * mapuje ryzyka osobowe dla produktu i osob w wariancie
       */
      this._mapPersonalRiskForPersons = function(dataConteiner, product, personalRisks, variant) {
        var persons = dataConteiner.persons,
          personSums = dataConteiner.suList[CONSTANTS.PRODUCT_TYPE_PERSON];
        lsnNg.forEach(persons, function(person, personId) {
          if (personSums[personId] && personSums[personId][product]) {
            var personData = person.getAdditionalData(product);
            var risk = null;
            if ((CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(product) === -1 && personData.variants === variant) ||
              (personData.variants !== null && personData.variants[variant])) {
              risk = self._getPersonalRiskObj(product, person, variant, personSums[personId][product][variant], self._getPersonalRiskDates(dataConteiner, product, variant));
            }
            if (risk !== null) {
              personalRisks.push(risk);
            }
          }
        });
      };

      //zwraca daty ochrony dla ryzyka osobowego
      this._getPersonalRiskDates = function(dataConteiner, product, variant) { //eslint-disable-line consistent-return
        if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
          return dataConteiner.propertyProtectionDates;
        }

        var multiVariant = (CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(product) !== -1);
        switch (product) {
          case CONSTANTS.PRODUCT_OCZP:
            return multiVariant ? dataConteiner.oczpProtectionDates[variant] : dataConteiner.oczpProtectionDates;
          case CONSTANTS.PRODUCT_NNW:
            return dataConteiner.nnwProtectionDates;
          default:
            break;
        }
      };
      /**
       * zwraca obiekt ryzyka osobowego (NNW, OCZP)
       * @param {string} product CONSTANTS.PRODUCT_OCZP, itp.
       * @param {PersonModel} person
       * @param {string} variant
       * @param {null|int} sumInsured
       * @param {Object} dates
       * @returns {ToRestTempRiskModel|null} obiekt ryzyka lub null, gdy brak ryzyka - nie wysyłamy go do usługi
       */
      this._getPersonalRiskObj = function(product, person, variant, sumInsured, dates, additionalAttributes) {
        var attrs = {},
          isMainInsured = (self.utils.getMainInsuredClientId() === person.metaData.get('clientId'));
        var isAllowed = self._setPersonalRiskAttributes(product, attrs, person, variant, sumInsured, dates, additionalAttributes);
        if (!isAllowed) {
          return null;
        }
        var tmpRisk = new ToRestTempRiskModel({
          'code': product,
          'prodCode': product,
          'sumInsured': self._getPersonalRiskSumInsured(product, variant, sumInsured),
          'variant': variant,
          'start': dates.start,
          'end': dates.end,
          'insuredId': person.get('metaData').get('id'),
          'subjectRefs': [
            [(isMainInsured ? CONSTANTS.SUBJECT_INSURED : CONSTANTS.SUBJECT_COINSURED), person.metaData.get('id')]
          ],
          'attributes': attrs,
          'profession': self.utils.getProfessionCode(person.getAdditionalData([CONSTANTS.PRODUCT_OCZP, 'job']))
        });
        return self._buildRiskObject(tmpRisk);
      };
      //#pragma mark personalRisks
      /**
       * ustawia atrybuty dla ryzyka osobowego i w zwroce zwraca flagę informującą o tym czy ryzyko powinno być wysłane w usłudze
       * @param {String} product              [description]
       * @param {Object} attrs obiekt z atrybutami(klucz: wartosc) do uzupełnienia
       * @param {PersonModel} person               [description]
       * @param {[type]} variant              [description]
       * @param {[type]} sumInsured           [description]
       * @param {[type]} dates                [description]
       * @param {[type]} additionalAttributes [description]
       */
      this._setPersonalRiskAttributes = function(product, attrs, person, variant, sumInsured, dates, additionalAttributes) {
        var personData = person.getAdditionalData(product),
          variantDef = resourceHelper.getProdVariantDef(product, variant),
          allowed = true;
        switch (product) {
          case CONSTANTS.PRODUCT_OCZP:
            if (variantDef.PROFESSION) {
              if (angular.isDefined(personData.job)) {
                attrs.profession = self.utils.getProfessionCode(personData.job);
              }
              if (angular.isArray(personData.specializations) && personData.specializations.length) {
                attrs.speciality = arrayUpperCaseModelFilter(personData.specializations);
              }
              if (angular.isUndefined(attrs.profession) || attrs.profession === -1) {
                allowed = false; //nie wysyłamy takiego ryzyka, jeśli brak wybranej profesji
              }
            }
            if (variantDef.HOUSEHOLDPEOPLE && angular.isDefined(personData.householdPeople)) {
              attrs.householdNumberOfAdultPeople = personData.householdPeople;
            }
            break;
          case CONSTANTS.PRODUCT_NNW:
            if (angular.isDefined(variantDef.INCOME_LOSS) && angular.isDefined(personData.monthlyIncome)) {
              attrs.monthlyIncome = personData.monthlyIncome;
            }
            break;
          case CONSTANTS.PRODUCT_NNW_INCOGNITO:
            if (angular.isObject(additionalAttributes) && angular.isDefined(additionalAttributes.insuredNo)) {
              attrs.insuredNo = additionalAttributes.insuredNo;
            }
            break;
          default:
            break;
        }
        return allowed;
      };
      //zwraca sume ubezpieczenia dla ryzyka osobowego - do nadpisania w konkretnym produkcie
      this._getPersonalRiskSumInsured = function(product, variant, sumInsured) {
        var variantDef = resourceHelper.getProdVariantDef(product, variant);
        if (product === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
          return sumInsured;
        }
        return (variantDef.TYPEINSUM) ? sumInsured : null;
      };

      //#pragma mark communicationRisks
      //mapuje ryzyka komunikacyjne
      this._mapCommunicationRisks = function(vehicles, selectedVariants, mainInsuredId) {
        var communiactionRisks = [];
        lsnNg.forEach(selectedVariants, function(risks, vehicleId) {
          if (parseInt(vehicleId, 10) === 0) {
            return;
          }
          var subjects = [],
            addData = vehicles[vehicleId].getAdditionalData();
          subjects.push([CONSTANTS.SUBJECT_OWNER, mainInsuredId]);
          if (angular.isObject(addData.coowners)) {
            lsnNg.forEach(addData.coowners, function(personId) {
              subjects.push([CONSTANTS.SUBJECT_COOWNER, self.utils.getSubjectMetadataId(personId)]);
            });
          }
          //leasing
          if (typeof addData.isLeased !== 'undefined' && addData.isLeased === true && typeof addData.leaserId !== 'undefined' && addData.leaserId !== null) {
            subjects.push([CONSTANTS.SUBJECT_LEASING, self.utils.getSubjectMetadataId(addData.leaserId)]);
          }
          lsnNg.forEach(risks, function(variant, riskCode) {
            if (variant === null) {
              return true;
            }
            var attrs = {};
            self._mapCommunicationDynamicValues(attrs, vehicles[vehicleId], riskCode, variant);
            self.mapExtensions(attrs, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleId);
            var su = dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleId, riskCode);
            var tmpRisk = new ToRestTempRiskModel({
              start: addData.protectionDates.start,
              end: addData.protectionDates.end,
              prodCode: riskCode,
              code: riskCode,
              variant: variant,
              insuredId: mainInsuredId,
              subjectRefs: subjects,
              objectRefs: [vehicles[vehicleId].metaData.get('id')],
              gross: self.getSumInsuredBrutto(addData.vehicleValueType, riskCode),
              attributes: attrs,
              sumInsured: angular.equals(su, {}) ? null : su
            });
            communiactionRisks.push(self._buildRiskObject(tmpRisk));

            return true; //continue
          });
        });

        return communiactionRisks;
      };

      /**
       * uzupełnia dynamicValues na ryzykach komunikacyjnych
       * @param {Object} dynVals
       * @param {Vehicle} vehicle
       * @param {string} riskCode
       * @param {string} variant
       */
      this._mapCommunicationDynamicValues = function(dynVals, vehicle, riskCode, variant) {
        var addData = vehicle.getAdditionalData();
        if (angular.isDefined(addData.isYoungDriver) && addData.isYoungDriver !== null) {
          dynVals.userUnderAge = addData.isYoungDriver;
        }
        if (angular.isDefined(addData.rightHandDrive) && addData.rightHandDrive !== null) {
          dynVals.rightHandDrive = addData.rightHandDrive;
        }
        if (CONFIG.BEHAVIOR.liquidationVariant.indexOf(variant) !== -1 && riskCode === CONSTANTS.RISK_AC) {
          dynVals.amortization = (angular.isDefined(addData.liquidation) && addData.liquidation !== '') ? CONSTANTS.AMORTIZATION_TYPE[addData.liquidation] : null;
        }
        //szczegolne przeznaczenie pojazdu
        if (angular.isArray(addData.specialPurposes) && angular.isArray(RESOURCES.SPECIAL_PURPOSE_OPTIONS)) {
          angular.forEach(RESOURCES.SPECIAL_PURPOSE_OPTIONS, function(item) {
            dynVals[item.CODE] = (addData.specialPurposes.indexOf(item.CODE) !== -1);
          });
        }
        if (appVariables.isOcBuyer && riskCode === CONSTANTS.RISK_OC) { //atrybuty dla oc Nabywcy
          self.addOcBuyerRiskDynamicValues(dynVals);
        }
        if (CONFIG.BEHAVIOR.vehicle.extendedValueTypes && self.canSetSumInsuredValueType(riskCode)) {
          // eslint-disable-next-line camelcase
          dynVals.issuminsuredgross_new = self.getSumInsuredGrossNew(addData.vehicleValueType, riskCode);
        }
      };

      /**
       * dodaje do przekazanego obiektu z dynamicValues atrybuty dla ryzyka OC w trybie OC nabywcy
       * @param {Object} dynVals
       */
      this.addOcBuyerRiskDynamicValues = function(dynVals) {
        var ocBuyerAttrs = [ //atrybuty specyficzne dla ryzyka OC nabywcy (wg IHESTIAPRO-687)
          'vehicleSellerPremiumPart',
          'vehicleBuyerPremiumPart',
          'policySellerCalculationType',
          'ocBuyer',
          'vehicleSellerInsuranceStartDate',
          'vehicleSellerInsuranceEndDate',
          'dateVehicleBought',
          'sellerOCPremium'
        ];
        var risk = self.utils.findRisk(CONSTANTS.RISK_OC);
        if (risk === null) {
          sp2CommonHelper.throwException('Nie znaleziono ryzyka OC wymaganego przy wniosku OC Nabywcy.');
        }
        angular.forEach(ocBuyerAttrs, function(name) {
          if (angular.isDefined(risk.product.dynamicValues[name])) {
            dynVals[name] = risk.product.dynamicValues[name];
          }
        });
      };

      /**
       * zwraca informacje sumie brutto ubezpieczenia komunikacyjnego
       * @param {*} vehicleValueType typ wartosci pojazdu
       * @param {string} riskCode kod ryzyka
       * @return {boolean|null} true/false gdy ryzyko AC/ACKR, null w pozostalych przypadkach
       */
      this.getSumInsuredBrutto = function(vehicleValueType, riskCode) {
        if (self.canSetSumInsuredValueType(riskCode)) {
          return (typeof vehicleValueType !== 'undefined') ? (vehicleValueType === CONSTANTS.VALUE_TYPE_BRUTTO) : true;
        }
        return null;
      };

      this.getSumInsuredGrossNew = function(vehicleValueType, riskCode) {
        if (self.canSetSumInsuredValueType(riskCode)) {
          return vehicleValueType;
        }
        return null;
      };

      this.canSetSumInsuredValueType = function(riskCode) {
        return riskCode === CONSTANTS.RISK_AC || riskCode === CONSTANTS.RISK_ACKR;
      };

      //#pragma mark personGroupRisks
      //mapuje ryzyka typu personGroup
      this._mapPersonGroupRisks = function(persons, groups, selectedVariants, protectionDates) {
        var personGroupRisks = [];
        lsnNg.forEach(selectedVariants, function(risks, subjectClientId) {
          if (parseInt(subjectClientId, 10) < 1) {
            return;
          }
          var subjectId = self.utils.getSubjectMetadataId(subjectClientId),
            isPerson = (angular.isDefined(persons[subjectClientId]));
          if (!isPerson && angular.isUndefined(groups[subjectClientId])) { //ani osoba ani grupa
            return; //brak podmiotu wsrod osob i grup
          }
          var subjects = [];
          if (isPerson) {
            subjects.push([CONSTANTS.SUBJECT_INSURED, subjectId]);
          }
          angular.forEach(risks, function(variant, riskCode) {
            if (variant === null) {
              return;
            }
            var attrs = {};
            if (!isPerson) {
              attrs._groupId = subjectId;
            }
            self.mapExtensions(attrs, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subjectClientId);
            var tmpRisk = new ToRestTempRiskModel({
              start: protectionDates.start,
              end: protectionDates.end,
              prodCode: riskCode,
              code: riskCode,
              variant: variant,
              insuredId: isPerson ? subjectId : null,
              subjectRefs: subjects,
              attributes: attrs
            });
            personGroupRisks.push(self._buildRiskObject(tmpRisk));
          });
        });

        return personGroupRisks;
      };
      //#pragma mark extensions
      /**
       *  mapuje rozszerzenia do atrybutow ryzyka
       * @param  {[type]} riskAttrs [description]
       * @param  {[type]} prodType  [description]
       * @param  {[type]} objId     [description]
       * @return {Boolean} jasli rozszerzenia wylaczone to false
       */
      this.mapExtensions = function(riskAttrs, prodType, objId) {
        if (!CONFIG.MODULES.extensions) {
          return false; //brak obslugi rozszerzeń
        }
        angular.forEach(extensionHelper.getSelectedExtensionsForObject(prodType, objId), function(extDef) {
          if (extDef.CODE !== CONSTANTS.NO_EXTENSION) {
            riskAttrs[extDef.BOS_CODE] = true;
          }
        });
        return true;
      };
      //#pragma mark restRisks
      /**
       * buduje ryzyko modelu REST
       * @param {MapperTempRisk} tmpRisk tymczasowe ryzyko
       * @return {Risk} ryzyko modelu REST
       */
      this._buildRiskObject = function(tmpRisk) {
        // var conditions = self._getRiskDefinitinConditions(tmpRisk),
        var riskDef = self.utils.findRiskDef(tmpRisk.prodCode, tmpRisk.code, tmpRisk.estateType, tmpRisk.variant, tmpRisk.profession);

        var data = {
          'sum': tmpRisk.sumInsured,
          'start': tmpRisk.start + ' 00:00:00',
          'end': tmpRisk.end + ' 23:59:59',
          'gross': tmpRisk.gross,
          'product': {
            'compId': riskDef.idpm,
            'dynamicValues': toRestAttributeMapper.mapAttributesForRisk(riskDef, tmpRisk)
          },
          'insuredSubjectsRef': self._mapSubjectRef(riskDef, tmpRisk),
          'insuredObjectsRef': self._mapObjectRef(riskDef, tmpRisk),
          'insuredRef': tmpRisk.insuredId
        };
        //data ochrony "od" + czas w przypadku daty dzisiejszej
        if (data.start.substring(0, 10) === RESOURCES.TODAY) {
          data.start = data.start.substring(0, 10) + ' ' + mainDataContainer.protectionStartTime;
          data.product.dynamicValues.insuranceStartDate = data.start;
        }
        var risk = new RiskModel();
        risk.setData(data);
        if (appVariables.isSupplement) { //tryb dokupienia
          var refRisk = self.utils.findRefRisk(riskDef.idpm, tmpRisk.subjectRefs, tmpRisk.objectRefs);
          if (refRisk !== null) {
            risk.metaData.set('id', refRisk.metaData.get('id'));
            risk.set('parentRef', refRisk.get('parentRef'));
          }
        }
        return risk;
      };

      /**
       * Zwraca obiekt z warunkami, które zostaną użyte do wyszukania definicji ryzyka w SPD
       * @param {MapperTempRisk} tmpRisk tymczasowe ryzyko
       * @return {object}
       * {
       *  estateType: ... //typ lokalizacji
       *  variant: ... //wariant ryzyka
       * }
       */
      this._getRiskDefinitinConditions = function(tmpRisk) {
        var conditions = {
          variant: tmpRisk.variant,
          estateType: tmpRisk.estateType,
          profession: tmpRisk.profession
        };
        switch (tmpRisk.prodCode) {
          case CONSTANTS.PRODUCT_OCZP:
          case CONSTANTS.PRODUCT_NNW:
          case CONSTANTS.PRODUCT_REHABILITATION:
          case CONSTANTS.PRODUCT_SPORT_ASSISTANCE:
          case CONSTANTS.PRODUCT_BAGGAGE:
          case CONSTANTS.PRODUCT_MEDICAL_EXPENSES:
          case CONSTANTS.PRODUCT_NNW_INCOGNITO:
          case CONSTANTS.RISK_AC:
          case CONSTANTS.RISK_OC:
          case CONSTANTS.RISK_NNWKIP:
          case CONSTANTS.ADD_ASSMEDIC:
          case CONSTANTS.ADD_SPORT_EQUIPMENT:
            conditions.variant = null;
            conditions.estateType = null;
            break;
          case CONSTANTS.PRODUCT_OGI:
            if (!self.utils.isFireRisksConnection()) {
              conditions.variant = null;
            }
            break;
          case CONSTANTS.PRODUCT_KRA:
            conditions.variant = null;
            break;
          default:
            break;
        }
        switch (tmpRisk.code) {
          case CONSTANTS.RISK_GARDEN:
          case CONSTANTS.RISK_OUTBUILDINGS:
          case CONSTANTS.RISK_PROPERTY_ARCHITECTURE:
            conditions.estateType = null;
            break;
          case CONSTANTS.RISK_FLOOD:
            conditions.variant = null;
            break;
          default:
            break;
        }
        return conditions;
      };
      //mapuje powiazania ryzyka z podmiotami
      this._mapSubjectRef = function(riskDef, tmpRisk) {
        var sunjs = null;
        if (!riskDef.subjects) {
          return null;
        }
        lsnNg.forEach(tmpRisk.subjectRefs, function(subData) {
          if (!sunjs) {
            sunjs = [];
          }
          if (subData[0] === null) { //jesli nie podano roli, to pobieramy role z definicji SPD, pod warunkiem ze jest tylko jedna rola w definicji
            if (riskDef.subjects.length > 1) {
              sp2CommonHelper.throwException('Subject definition for risk {0} is ambigious.'.format(riskDef.idpm));
            }
            subData[0] = riskDef.subjects[0];
          }
          var givenRoleExists = riskDef.subjects.indexOf(subData[0]) !== -1;
          if (!givenRoleExists && riskDef.subjects.indexOf(CONSTANTS.SUBJECT_ALIASES[subData[0]]) === -1) { //sprawdzamy też aliasy/zamienniki dla roli
            return; //nie alertujemy braku roli na ryzyku, poniewaz uniemozliwiloby to prace automatu (np. coinsured na ogródku, bud.gosp. w kradzieży)
          }
          var io = new InsuredObjectModel();
          io.setData({
            'ref': subData[1],
            'typeId': SPD.subjects[givenRoleExists ? subData[0] : CONSTANTS.SUBJECT_ALIASES[subData[0]]]
          });
          sunjs.push(io);
        });
        return sunjs;
      };
      //mapuje powiazania ryzyka z obiektami
      this._mapObjectRef = function(riskDef, tmpRisk) {
        var objs = null;
        if (!riskDef.objectIdpm) {
          return null;
        }
        if (tmpRisk.objectRefs.length > 0) {
          objs = [];
          lsnNg.forEach(tmpRisk.objectRefs, function(objId) {
            var io = new InsuredObjectModel();
            io.setData({
              'ref': objId,
              'typeId': riskDef.objectIdpm
            });
            objs.push(io);
          });
        }
        return objs;
      };
    };

    return new ToRestRiskMapper();
  }
]);
