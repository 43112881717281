angular.module('ihestiaRestServicesBase')

/**
 * pobieranie obrazka captcha
 */
.service('clientPersonsSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var ClientPersons = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'persons';
    this.businessArea = 'clients';
    this.top = 5;
    this.port = '8202';
    this.path = 'RestClients';
    this.sortDirection = 'DESC';
    this.sortBy = 'rank';

  };
  return new ClientPersons();
}]);