/**
 * Serwis do pobierania podstawowych danych
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase.baseInfo')
  .provider('ihestiaSsoBaseInfoHelper', ['ihestiaConfigHelperProvider', 'IHestiaUserModelProvider',
    function(ihestiaConfigHelperProvider, IHestiaUserModelProvider) {
      var ihestiaSsoBaseInfoHelperProvider = this;
      var ssoData = ihestiaConfigHelperProvider.getConfigHelper().get('sso');
      var isUserRelogged = !!ssoData.isUserRelogged;

      /**
       * Czy użytkownik jest przelogowany
       * @param {Boolean} ignoreSupportFlag czy ignorować flagę wspierania przelogowania
       * @return {Boolean} [description]
       */

      this.isUserRelogged = function(ignoreSupportFlag) {
        var reloggingSupported = ihestiaConfigHelperProvider.getConfigHelper().get('frontFlags', 'SUPPORT_RELOGGED_USER');
        return ignoreSupportFlag ? isUserRelogged : isUserRelogged && reloggingSupported;
      };

      /**
       * Zwraca dane zalogowanego (lub przelogowanego) użytkownika
       * @param  {Boolean} supportRellogedUser czy wspieramy przelogowanie
       * @return {Object} dane użytkownika
       */
      this.getUserData = function(supportRellogedUser){
        var UserModel = IHestiaUserModelProvider.getModel();
        var user = new UserModel;
        if (supportRellogedUser && ihestiaSsoBaseInfoHelperProvider.isUserRelogged())
        {
          user.setData(ssoData.reloggedUser);
          return user;
        }
        else
        {
          user.setData(ssoData.userInfo);
          return user;
        }
      };

      /**
       * Magic get
       * zwraca to, co wcześniej service
       * @type {Array}
       */
      this.$get = ['ihestiaConfigHelper', 'IHestiaUserModel',
        function(ihestiaConfigHelper, IHestiaUserModel) {

          var IHestiaSsoBaseInfoHelper = function() {
            var self = this;

            this.currentUser = null;
            this.reloggedUser = null;

            /**
             * Pobieramy userInfo
             * @param {Boolean} supportRellogedUser czy zwrócić przelogowanego usera jeśli jest przelogowanie i wsparcie dla przelogowania
             * @return {IHestiaUserModel} [description]
             */
            this.getUser = function(supportRellogedUser) {
              if(supportRellogedUser && self.isUserRelogged()) {
                return self.getReloggedUser();
              } else {
                return self.getCurrentUser();
              }
            };

            /**
             * Nakładka na isUserRelogged z providera
             * @return {Boolean} czy użytkownik jest przelogowany
             */
            this.isUserRelogged = function( ignoreSupportFlag ) {
              return ihestiaSsoBaseInfoHelperProvider.isUserRelogged( ignoreSupportFlag );
            };

            /**
             * Zwracamy przelogowanego użytkownika
             * @return {IHestiaUserModel} [description]
             */
            this.getReloggedUser = function() {
              if(!self.reloggedUser) {
                self.reloggedUser = new IHestiaUserModel();
                self.reloggedUser.setData(ssoData.reloggedUser);
              }
              return self.reloggedUser;
            };

            /**
             * Zwracamy zalogowanego użytkownika
             * @return {IHestiaUserModel} [description]
             */
            this.getCurrentUser = function() {
              if(!self.currentUser) {
                self.currentUser = new IHestiaUserModel();
                self.currentUser.setData(ssoData.userInfo);
                self.currentUser.setAccountInfo(ssoData.accountInfo);
              }
              return self.currentUser;
            };

            /**
             * Zwracamy tożsamość po kodzie
             * @param  {string} code [description]
             * @param  {IHestiaUserModel} user uzytkownik którego tożsamosci przeglądamy
             * @return {[type]}      [description]
             */
            this.getCharacterByCode = function(code, user) {
              var returnCharacter = {};
              user = user ? user : self.getCurrentUser();
              angular.forEach(user.characters, function(character) {
                if (character.code + '' === code + '') {
                  returnCharacter = character;
                  return true;
                }
                return false;
              });

              return returnCharacter;
            };

          };

          return new IHestiaSsoBaseInfoHelper();
        }
      ];

    }]);