/**
 * Mapper atrybutów
 */
angular.module('salesPath2').service('toRestAttributeMapper', ['appVariables', 'lsnModelHelper', 'spdAllRisks',
  function (appVariables, lsnModelHelper, spdAllRisks)
  {
    var ToRestAttributeMapper = function ()
    {
      var self = this;
      this.commonAttributes = { //mapowanie wspolnych atrybutow - nazwa atr. : pozycja,
        // risk.x - pozycja x w mapperTempRisk
        'insuranceStartDate': 'risk.start',
        'insuranceEndDate': 'risk.end',
        'isSumInsuredGross': 'risk.gross',
        'sumInsured': 'risk.sumInsured',
        'coverageOption': 'risk.variant'
      };
      /**
       * mapuje i zwraca zestaw atrybutów dla podanego ryzyka
       * @param {Object} riskDef definicja ryzyka z SPD/risks.js
       * @param {XPegaz.Mapper.ToRest.TempRisk} tmpRisk tymczasowe ryzyko
       * @return {Array|null} null gdy brak atrybutów
       */
      this.mapAttributesForRisk = function (riskDef, tmpRisk)
      {
        var attributes = angular.extend(self._parseCommonAttributes(tmpRisk), tmpRisk.attributes);
        var mappedAttributes = {},
          empty = true;
        angular.forEach(attributes, function (value, name)
        {
          if (( angular.isDefined(spdAllRisks.attributes[name]) || angular.isDefined(riskDef.attributes[name]) ) && angular.isDefined(value) && value !== null)
          {
            var attr = lsnModelHelper.parseDynamicValueByDef(angular.isDefined(spdAllRisks.attributes[name]) ? spdAllRisks.attributes[name] : riskDef.attributes[name], name, value);
            mappedAttributes[name] = attr;
            empty = false;
          }
        });

        return !empty ? mappedAttributes : null;
      };
      /**
       * zwraca zestaw wspolnych atrybutow wraz z ich wartosciami dla podanego tmp ryzyka
       * @param {XPegaz.Mapper.ToRest.TempRisk} tmpRisk ryzyko
       * @return {Object} atrybuty
       */
      this._parseCommonAttributes = function (tmpRisk)
      {
        var attrs = {};
        angular.forEach(self.commonAttributes, function (position, key)
        {
          var path = position.split('.');
          if (path[0] === 'risk' && typeof tmpRisk[path[1]] !== 'undefined' && tmpRisk[path[1]] !== null)
          {
            attrs[key] = tmpRisk[path[1]];
          }
        });
        return attrs;
      };
    };

    return new ToRestAttributeMapper();
  }
]);
