angular.module('ihestiaWidgets.ikonfigurator')
  .service('IKONFIGURATOR_RESOURCES', ['$filter', 'labelActionHelper',
    function($filter, labelActionHelper) {
      var IkonfiguratorResourcesHelper = function() {
        var self = this;
        this.init = function() {
          self.transLabel();

          labelActionHelper.registerAction('ikonfiguratorResources', self.transLabel);
        };

        this.transLabel = function() {
          angular.forEach(self.currentResources.risks, function(riskData, riskCode) {
            self.currentResources.risks[riskCode].shortName = $filter('translate')(riskData.shortNameLabelCode, {
              componentCode: 'iKonfigurator'
            });
            self.currentResources.risks[riskCode].desc = $filter('translate')(riskData.descLabelCode, {
              componentCode: 'iKonfigurator'
            });
          });

          angular.forEach(self.currentResources.additions, function(additionData, additionCode) {
            self.currentResources.additions[additionCode].shortName = $filter('translate')(additionData.shortNameLabelCode, {
              componentCode: 'iKonfigurator'
            });
            self.currentResources.additions[additionCode].desc = $filter('translate')(additionData.descLabelCode, {
              componentCode: 'iKonfigurator'
            });
          });

          angular.forEach(self.currentResources.productsDescription, function(appData, appCode) {
            angular.forEach(appData, function(typeData, typeCode) {
              angular.forEach(typeData, function(riskData, riskCode) {
                if (angular.isDefined(riskData.titleLabelCode)) {
                  self.currentResources.productsDescription[appCode][typeCode][riskCode].title = $filter('translate')(riskData.titleLabelCode, {
                    componentCode: 'iKonfigurator'
                  });
                }
                if (angular.isDefined(riskData.descLabelCode)) {
                  self.currentResources.productsDescription[appCode][typeCode][riskCode].desc = $filter('translate')(riskData.descLabelCode, {
                    componentCode: 'iKonfigurator'
                  });
                }
              });
            });
          });
        };

        this.currentResources = {
          // opisy dla ekranu podsumowania
          productsDescription: {
            ock: {
              risks: {
                OC: {
                  titleLabelCode: 'app.ock.risk.oc.title',
                  descLabelCode: ''
                }
              }
            },
            md: {
              risks: {
                OGIEN: {
                  titleLabelCode: 'app.md.risk.fire.title',
                  descLabelCode: ''
                }
              }
            },
            ocz: {
              risks: {
                OCZP: {
                  titleLabelCode: 'app.ocz.risk.oczp.title',
                  descLabelCode: ''
                }
              }
            },
            e1: {
              risks: {
                OC_1: {
                  titleLabelCode: 'app.e1.risk.oc.title',
                  descLabelCode: 'app.e1.risk.oc1.desc'
                },
                OC_2: {
                  titleLabelCode: 'app.e1.risk.oc.title',
                  descLabelCode: 'app.e1.risk.oc2.desc'
                },
                OC_3: {
                  titleLabelCode: 'app.e1.risk.oc.title',
                  descLabelCode: 'app.e1.risk.oc3.desc'
                },
                NNWKIP_1: {
                  titleLabelCode: 'app.e1.risk.nnwkip.title',
                  descLabelCode: 'app.e1.risk.nnwkip1.desc'
                },
                NNWKIP_2: {
                  titleLabelCode: 'app.e1.risk.nnwkip.title',
                  descLabelCode: 'app.e1.risk.nnwkip2.desc'
                },
                NNWKIP_3: {
                  titleLabelCode: 'app.e1.risk.nnwkip.title',
                  descLabelCode: 'app.e1.risk.nnwkip3.desc'
                },
                OGIEN_1: {
                  titleLabelCode: 'app.e1.risk.fire.title',
                  descLabelCode: 'app.e1.risk.fire1.desc'
                },
                OGIEN_2: {
                  titleLabelCode: 'app.e1.risk.fire.title',
                  descLabelCode: 'app.e1.risk.fire2.desc'
                },
                OGIEN_3: {
                  titleLabelCode: 'app.e1.risk.fire.title',
                  descLabelCode: 'app.e1.risk.fire3.desc'
                },
                OCZP_1: {
                  titleLabelCode: 'app.e1.risk.oczp.title',
                  descLabelCode: 'app.e1.risk.oczp1.desc'
                },
                OCZP_2: {
                  titleLabelCode: 'app.e1.risk.oczp.title',
                  descLabelCode: 'app.e1.risk.oczp2.desc'
                },
                OCZP_3: {
                  titleLabelCode: 'app.e1.risk.oczp3.title',
                  descLabelCode: 'app.e1.risk.oczp3.desc'
                }
              }
            },
            e7: {
              risks: {
                OC_1: {
                  titleLabelCode: 'app.e7.risk.oc.title',
                  descLabelCode: 'app.e7.risk.oc.desc'
                },
                OC_2: {
                  titleLabelCode: 'app.e7.risk.oc.title',
                  descLabelCode: 'app.e7.risk.oc.desc'
                },
                OC_3: {
                  titleLabelCode: 'app.e7.risk.oc.title',
                  descLabelCode: 'app.e7.risk.oc.desc'
                },
                AC_1: {
                  titleLabelCode: 'app.e7.risk.ac.title',
                  descLabelCode: 'app.e7.risk.ac1.desc'
                },
                AC_2: {
                  titleLabelCode: 'app.e7.risk.ac.title',
                  descLabelCode: 'app.e7.risk.ac2.desc'
                },
                AC_3: {
                  titleLabelCode: 'app.e7.risk.ac.title',
                  descLabelCode: 'app.e7.risk.ac3.desc'
                },
                NNWKIP_1: {
                  titleLabelCode: 'app.e7.risk.nnwkip.title',
                  descLabelCode: 'app.e7.risk.nnwkip1.desc'
                },
                NNWKIP_2: {
                  titleLabelCode: 'app.e7.risk.nnwkip.title',
                  descLabelCode: 'app.e7.risk.nnwkip2.desc'
                },
                NNWKIP_3: {
                  titleLabelCode: 'app.e7.risk.nnwkip.title',
                  descLabelCode: 'app.e7.risk.nnwkip3.desc'
                },
                NNW_1: {
                  titleLabelCode: 'app.e7.risk.nnw.title',
                  descLabelCode: 'app.e7.risk.nnw1.desc'
                },
                NNW_2: {
                  titleLabelCode: 'app.e7.risk.nnw.title',
                  descLabelCode: 'app.e7.risk.nnw2.desc'
                },
                NNW_3: {
                  titleLabelCode: 'app.e7.risk.nnw.title',
                  descLabelCode: 'app.e7.risk.nnw3.desc'
                },
                OGIEN_1: {
                  titleLabelCode: 'app.e7.risk.fire.title',
                  descLabelCode: 'app.e7.risk.fire1.desc'
                },
                OGIEN_2: {
                  titleLabelCode: 'app.e7.risk.fire.title',
                  descLabelCode: 'app.e7.risk.fire2.desc'
                },
                OGIEN_3: {
                  titleLabelCode: 'app.e7.risk.fire.title',
                  descLabelCode: 'app.e7.risk.fire3.desc'
                },
                KRADZIEZ_1: {
                  titleLabelCode: 'app.e7.risk.burglary.title',
                  descLabelCode: 'app.e7.risk.burglary1.desc'
                },
                KRADZIEZ_2: {
                  titleLabelCode: 'app.e7.risk.burglary.title',
                  descLabelCode: 'app.e7.risk.burglary2.desc'
                },
                KRADZIEZ_3: {
                  titleLabelCode: 'app.e7.risk.burglary.title',
                  descLabelCode: 'app.e7.risk.burglary3.desc'
                },
                OCZP_1: {
                  titleLabelCode: 'app.e7.risk.oczp.title',
                  descLabelCode: 'app.e7.risk.oczp1.desc'
                },
                OCZP_2: {
                  titleLabelCode: 'app.e7.risk.oc.title',
                  descLabelCode: 'app.e7.risk.oczp2.desc'
                },
                OCZP_3: {
                  titleLabelCode: 'app.e7.risk.oczp.title',
                  descLabelCode: 'app.e7.risk.oczp3.desc'
                }

              },
              additions: {
                CAR_ASSISTANCE_STANDARD: {
                  titleLabelCode: 'app.e7.additions.carAssistanceStandard.title',
                  descLabelCode: ''
                },
                CAR_ASSISTANCE_PREMIUM: {
                  titleLabelCode: 'app.e7.additions.carAssistancePremium.title',
                  descLabelCode: ''
                },
                CAR_ASSISTANCE_PRESTIGE: {
                  titleLabelCode: 'app.e7.additions.carAssistancePrestige.title',
                  descLabelCode: ''
                },
                CAR_ASSISTANCE_AWARIA: {
                  titleLabelCode: 'app.e7.addition.carAssistanceAwaria.title',
                  descLabelCode: ''
                },
                WINDOWS: {
                  titleLabelCode: 'app.e7.additions.windows.title',
                  descLabelCode: ''
                },
                OUZ: {
                  titleLabelCode: 'app.e7.additions.ouz.title',
                  descLabelCode: ''
                },
                OUZ_OC: {
                  titleLabelCode: 'app.e7.additions.ouzOc.title',
                  descLabelCode: ''
                },
                OUZ_AC: {
                  titleLabelCode: 'app.e7.additions.ouzAc.title',
                  descLabelCode: ''
                },
                HOME_ASSISTANCE: {
                  titleLabelCode: 'app.e7.additions.homeAssistance.title',
                  descLabelCode: ''
                },
                SOS_ASSISTANCE: {
                  titleLabelCode: 'app.e7.additions.sosAssistance.title',
                  descLabelCode: ''
                }
              }
            },
            mf: {
              risks: {
                OC_1: {
                  titleLabelCode: 'app.mf.risk.oc.title',
                  descLabelCode: 'app.mf.risk.oc1.desc'
                },
                OC_2: {
                  titleLabelCode: 'app.mf.risk.oc.title',
                  descLabelCode: 'app.mf.risk.oc2.desc'
                },
                OC_3: {
                  titleLabelCode: 'app.mf.risk.oc.title',
                  descLabelCode: 'app.mf.risk.oc3.desc'
                },
                AC_1: {
                  titleLabelCode: 'app.mf.risk.ac.title',
                  descLabelCode: 'app.mf.risk.ac1.desc'
                },
                AC_2: {
                  titleLabelCode: 'app.mf.risk.ac.title',
                  descLabelCode: 'app.mf.risk.ac2.desc'
                },
                AC_3: {
                  titleLabelCode: 'app.mf.risk.ac3.title',
                  descLabelCode: ''
                },
                NNWKIP_1: {
                  titleLabelCode: 'app.mf.risk.nnwkip.title',
                  descLabelCode: 'app.mf.risk.nnwkip1.desc'
                },
                NNWKIP_2: {
                  titleLabelCode: 'app.mf.risk.nnwkip.title',
                  descLabelCode: 'app.mf.risk.nnwkip2.desc'
                },
                NNWKIP_3: {
                  titleLabelCode: 'app.mf.risk.nnwkip.title',
                  descLabelCode: 'app.mf.risk.nnwkip3.desc'
                },
                NNW_1: {
                  titleLabelCode: 'app.mf.risk.nnw.title',
                  descLabelCode: 'app.mf.risk.nnw1.desc'
                },
                NNW_2: {
                  titleLabelCode: 'app.mf.risk.nnw.title',
                  descLabelCode: 'app.mf.risk.nnw2.desc'
                },
                NNW_3: {
                  titleLabelCode: 'app.mf.risk.nnw.title',
                  descLabelCode: 'app.mf.risk.nnw3.desc'
                },
                OGIEN_1: {
                  titleLabelCode: 'app.mf.risk.fire.title',
                  descLabelCode: 'app.mf.risk.fire1.desc'
                },
                OGIEN_2: {
                  titleLabelCode: 'app.mf.risk.fire.title',
                  descLabelCode: 'app.mf.risk.fire2.desc'
                },
                OGIEN_3: {
                  titleLabelCode: 'app.mf.risk.fire3.title',
                  descLabelCode: ''
                },
                KRADZIEZ_1: {
                  titleLabelCode: 'app.mf.risk.burglary.title',
                  descLabelCode: 'app.mf.risk.burglary1.desc'
                },
                KRADZIEZ_2: {
                  titleLabelCode: 'app.mf.risk.burglary.title',
                  descLabelCode: 'app.mf.risk.burglary2.desc'
                },
                KRADZIEZ_3: {
                  titleLabelCode: 'app.mf.risk.burglary3.title',
                  descLabelCode: ''
                },
                OCZP_1: {
                  titleLabelCode: 'app.mf.risk.oczp1.title',
                  descLabelCode: ''
                },
                OCZP_2: {
                  titleLabelCode: 'app.mf.risk.oczp2.title',
                  descLabelCode: ''
                },
                OCZP_3: {
                  titleLabelCode: 'app.mf.risk.oczp3.title',
                  descLabelCode: ''
                }
              },
              additions: {
                OUZ: {
                  titleLabelCode: 'app.ock.addition.ouz.title',
                  descLabelCode: 'app.ock.risk.nnwkip1.desc'
                },
                CAR_ASSISTANCE_AWARIA: {
                  titleLabelCode: 'app.ock.addition.carAssistanceAwaria.title',
                  descLabelCode: ''
                },
                HOME_ASSISTANCE: {
                  titleLabelCode: 'app.ock.addition.homeAssistance.title',
                  descLabelCode: ''
                },
                SOS_ASSISTANCE: {
                  titleLabelCode: 'app.ock.addition.sosAssistance.title',
                  descLabelCode: ''
                }
              }
            },
            es: {
              risks: {},
              additions: {
                ERGO_SPORT: {
                  titleLabelCode: 'app.es.risk.ergoSport.title',
                  descLabelCode: ''
                }
              }
            }
          },
          // ogolne dane ryzyk
          // 
          risks: {
            OC: {
              shortNameLabelCode: 'risk.oc.shortName',
              descLabelCode: 'risk.oc.desc',
              classes: 'icon-ock bg-oc'
            },
            AC: {
              shortNameLabelCode: 'risk.ac.shortName',
              descLabelCode: 'risk.ac.desc',
              classes: 'icon-ac bg-ac'
            },
            NNWKIP: {
              shortNameLabelCode: 'risk.nnwkip.shortName',
              descLabelCode: 'risk.nnwkip.desc',
              classes: 'icon-nnwk bg-nnwkip'
            },
            OGIEN: {
              shortNameLabelCode: 'risks.ogien.shortName',
              descLabelCode: 'risks.ogien.desc',
              classes: 'icon-mury bg-ogien'
            },
            KRADZIEZ: {
              shortNameLabelCode: 'risks.kradziez.shortName',
              descLabelCode: 'risks.kradziez.desc',
              classes: 'icon-kradziez bg-kradziez'
            },
            OCZP: {
              shortNameLabelCode: 'risks.oczp.shortName',
              descLabelCode: 'risks.oczp.desc',
              classes: 'icon-oczaw bg-oczp'
            },
            NNW: {
              shortNameLabelCode: 'risks.nnw.shortName',
              descLabelCode: 'risks.nnw.desc',
              classes: 'icon-nnw bg-nnw'
            }
          },
          // ogolne dane dodatkow
          additions: {
            CAR_ASSISTANCE: {
              shortNameLabelCode: 'additions.asspoj.shortName',
              descLabelCode: 'additions.asspoj.desc',
              classes: 'icon-assistance_pojazd bg-primary'
            },
            OUZ: {
              shortNameLabelCode: 'risk.oc.shortName',
              descLabelCode: 'additions.ouz.desc',
              classes: 'icon-ouz bg-primary'
            },
            SOS_ASSISTANCE: {
              shortNameLabelCode: 'additions.sosAssistance.shortName',
              descLabelCode: 'additions.sosAssistance.desc',
              classes: 'icon-assistance_dom bg-primary'
            },
            HOME_ASSISTANCE: {
              shortNameLabelCode: 'additions.homeAssistance.shortName',
              descLabelCode: 'additions.homeAssistance.desc',
              classes: 'icon-assistance_dom bg-primary'
            },
            ES: {
              shortNameLabelCode: 'productName.es',
              descLabelCode: '',
              classes: 'icon-pakiet_sport bg-primary'
            }
          }
        };
      };

      var ikonfiguratorResourcesHelperInstance = new IkonfiguratorResourcesHelper();
      ikonfiguratorResourcesHelperInstance.init();

      return ikonfiguratorResourcesHelperInstance.currentResources;

    }
  ]);