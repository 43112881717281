angular.module('ihestiaCommonDirectives.landingPage', ['ui.router'])
  .config(['$stateProvider',
    function config($stateProvider) {

      /**
       * Definicja stanów
       * @type {String}
       */
      $stateProvider
        .state('main.ihestiaLandingPage', {
          url: 'ihestia/{path:.*}',
          views: {
            'content': {
              controller: 'LandingPageCtrl',
              templateUrl: 'ihestia-commondirectives-template-app/other/landingpage/landingpage.tpl.html'
            }
          },
          data: {
            pageTitle: 'Landing page',
            disableHideBlockUi: false
          }
        });
    }
  ]);