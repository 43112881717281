angular.module('lsnBase.models')
.factory('LsnBankAccountModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnBankAccountModelV1 = function()
    {
      this.objectName = 'BankAccount';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        name: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        swift: {
          type: 'string'
        },
        iban: {
          type: 'string'
        },
        defaultAccount: {
          type: 'bool'
        }
      };

      this.name = null; // string, nazwa banku
      this.swift = null; // string, Kod SWIFT banku
      this.iban = null; // string, Numer IBAN
      this.defaultAccount = null; // bool, Konto główne. Wartością domyślną jest true. Należy przesłać wartość w polu tylko w przeciwnym wypadku

    };

    LsnBankAccountModelV1.prototype = LsnAbstractModel.prototype;

    return LsnBankAccountModelV1;
  }]);