angular.module('salesPath2')
  .controller('summaryCtrl', ['$scope', 'summaryPageHelper', 'summaryHelper', 'applicationHelper', '$timeout', 'sp2CommonHelper', 'messagesHelper', 'policiesPrintoutsSvc', 'summaryPrintoutsHelper',
    'RequestApplicationsPolicyModelConstants', 'appVariables', 'attachmentHelper', '$state', 'blockUiHelper', 'ApplicationModelConstants', 'sp2UiHelper', '$window', '$filter', 'lsnUtils', 'RESOURCES', 'sp2BlikPaymentModalHelper', 'dataContainerHelper', 'mainDataContainer', 'actionHelper', 'CONFIG', 'productsClausesCleanupHelper',
    function($scope, summaryPageHelper, summaryHelper, applicationHelper, $timeout, sp2CommonHelper, messagesHelper, policiesPrintoutsSvc, summaryPrintoutsHelper,
             RequestApplicationsPolicyModelConstants, appVariables, attachmentHelper, $state, blockUiHelper, ApplicationModelConstants, sp2UiHelper, $window, $filter, lsnUtils, RESOURCES, sp2BlikPaymentModalHelper, dataContainerHelper, mainDataContainer, actionHelper, CONFIG, productsClausesCleanupHelper) {

      $scope.blockUiState = blockUiHelper.blockUiState;
      $scope.tplData = summaryPageHelper.tplData;

      /**
       * reakcja na opuszczenie pola z miejscem podpisania umowy
       */
      $scope.checkPlace = function() {
        if (!angular.equals($scope.tplData.data.place, $scope.dataContainer.application.get('place'))) {
          if (applicationHelper.callInProgress) {
            $scope.tplData.options.requestApplicationSave = true;
          } else {
            summaryPageHelper.updateApplication();
          }
        }
      };

      //check product (only EP, nMF, E7)
      $scope.validProduct = function() {
        if ((CONFIG.METADATA.applicationSymbol === 'nMF' || CONFIG.METADATA.applicationSymbol === 'E7' || CONFIG.METADATA.applicationSymbol === 'EP')
          && summaryHelper.isPunuNumber) {
          $scope.appVariables.validProduct = true;
        } else {
          $scope.appVariables.validProduct = false;
        }
      };

      $scope.init = function() {
        $scope.tplData.data.place = $scope.dataContainer.application.get('place') || '';
        summaryPageHelper.refreshPlaceEditability();
        summaryPageHelper.setPolicyGrouping();
        summaryPageHelper.refreshButtonSettings();
        $scope.validProduct();
        $timeout(function() {
          if ($scope.appVariables.appMode === $scope.CONSTANTS.APP_MODE_CALCULATION) {
            applicationHelper.saveApplication();
          } else if ($scope.appVariables.appMode === $scope.CONSTANTS.APP_MODE_OFFER) {
            $scope.tplData.options.loadInProgress = true;
            applicationHelper.checkSummary();
          } else {
            summaryPageHelper.showPolicies();
          }
          var appDynVals = $scope.dataContainer.application.get('dynamicValues');
          if (angular.isDefined(appDynVals._calculateEstatesSeparately)) {
            $scope.tplData.data.policyGrouping = !appDynVals._calculateEstatesSeparately || appVariables.isSupplement;
          }
        }, 0);

      };
      /**
       * If insurance date discrepancy errors exist show confirmation modal and shows validate and accept policies
       */
      $scope.validateAndAcceptPolicies = function() {
        messagesHelper.showAcknowledgeDateDiscrepancyErrorsModal($scope._showValidateAndAcceptPolicies);
      };

      $scope._showValidateAndAcceptPolicies = function() {
        if ($scope.appVariables.appMode === $scope.CONSTANTS.APP_MODE_OFFER || $scope.appVariables.isOcBuyer) { // OcBuyer - https://ebok.atena.pl/browse/IHESTIASIA-1298
          return $scope._validateAndAcceptPolicies();
        } else {
          return summaryPageHelper.showAgentClausesModal(function() {
            $scope._validateAndAcceptPolicies();
          });
        }
      };

      /**
       * akcja - zatwierdz polisy - komunikat
       */
      $scope._validateAndAcceptPolicies = function() {
        var verificationStatus = summaryPageHelper.canMakeOffer(true),
          isCalc = ($scope.appVariables.appMode === $scope.CONSTANTS.APP_MODE_CALCULATION);
        if (!isCalc || verificationStatus.can) { // can accept (in !isCalc===offer mode we always allow to accept policies if "accept button" is available)
          sp2CommonHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            title: ['sp2.modal.info.title', {
              componentCode: 'sp2'
            }],
            okBtnName: ['next', {
              componentCode: 'Public'
            }],
            cancelBtnName: ['sp2.modal.cancel', {
              componentCode: 'sp2'
            }],
            text: ['modal.verifyDataBeforePolicyAccept', { // IHESTIASS-28
              componentCode: 'sp2'
            }],
            okBtnCallback: $scope.applyPoliciesAcceptance
          });
        } else if (verificationStatus.errors) { // has errors
          $scope.showAcceptErrorPopup();
        } else if (verificationStatus.dateToday) { // show todays policy acceptance modal
          summaryPageHelper.showTodaysOfferConfirmModal('policy', function() {
            applicationHelper.saveApplication(); // we have to save application before converting it to policies (insurance start time must be updated)
            $scope.applyPoliciesAcceptance();
          }, $filter('translate')('modal.verifyDataBeforePolicyAccept', {
            componentCode: 'sp2'
          }), ['summary.todayPolicyModal.title', {
            componentCode: 'sp2'
          }]);
        }
        // other case not supported
      };

      /**
       * applies policies acceptance - run services and block UI
       * @return {undefined}
       */
      $scope.applyPoliciesAcceptance = function() {
        $scope.tplData.options.acceptInProgress = true;
        applicationHelper.convertApplicationToPolicies(RequestApplicationsPolicyModelConstants.REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS_SCAN);
      };

      /**
       * shows confirm modal about errors unabling to accept policies
       * @return {undefined}
       */
      $scope.showAcceptErrorPopup = function() {
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['sp2.modal.info.title', {
            componentCode: 'sp2'
          }],
          okBtnName: ['sp2.modal.close', {
            componentCode: 'sp2'
          }],
          cancelBtnName: '',
          text: ['modal.fixErrorsBeforePoliciesVerification', {
            componentCode: 'sp2'
          }]
        });
      };

      /*
       * Show modal for upload files
       */
      $scope.showUploadPopup = function(policyNumber) {
        attachmentHelper.showAttachmentModal(policyNumber);
      };

      /*
       * Wyświetlenie modala z rodzajami wydruków
       */
      $scope.showPrintoutsPopup = function(policyNumber) {
        if ($scope.appVariables.appMode !== $scope.CONSTANTS.APP_MODE_POLICY) {
          $scope.renderPrintoutsPopup(null, policyNumber);
        } else {
          policiesPrintoutsSvc.getAvailablePrintoutTypes(policyNumber, function(types) {
            $scope.renderPrintoutsPopup(types, policyNumber);
          });
        }
      };

      /**
       * shows modal with available printouts for policy
       * @param  {String[]} types list of printout types (from service)
       * @param  {String} policyNumber
       * @return {undefined}
       */
      $scope.renderPrintoutsPopup = function(types, policyNumber) {
        var printouts = null,
          printoutsNames = summaryHelper.getPrintoutsNames(),
          alternativePrintout = null; // second group of printouts, currently only one item supported

        if (types) {
          printouts = [];
          angular.forEach(types.data, function(index, type) { //eslint-disable-line consistent-return
            if (type === 'POLISA-WERYFIKACJA' || angular.isUndefined(printoutsNames[index])) //tego tutaj ma nie być
            {
              return true;
            }
            printouts.push({
              code: index,
              checked: false,
              description: printoutsNames[index]
            });
          });
        }

        if (applicationHelper.isKIDPrintoutAllowed(policyNumber)) {
          alternativePrintout = {
            code: 'kid', // currently useless
            description: $filter('translate')('kidPrintoutDesc', {
              componentCode: 'sp2'
            }),
            callback: function() {
              applicationHelper.printKID(policyNumber);
            }
          };
        }

        summaryPrintoutsHelper.showPrintoutsModal(printouts, policyNumber, alternativePrintout);
      };

      /**
       * If insurance date discrepancy errors exist show confirmation modal and show make offer modal
       */
      $scope.makeOffer = function() {
        var makeOfferCallback = summaryHelper.isFraudulentClient(false)
          ? $scope._handleReservePolicyNumberModal : $scope._handleShowOfferModal;
        messagesHelper.showAcknowledgeDateDiscrepancyErrorsModal(makeOfferCallback);
      };

      /**
       * If insurance date discrepancy errors exist show confirmation modal and show "Reserve policy numer / pay and accept" modal
       */
      $scope.reservePolicyNumber = function() {
        messagesHelper.showAcknowledgeDateDiscrepancyErrorsModal($scope._handleReservePolicyNumberModal);
      };

      /**
       * Shows make offer modal
       * @private
       */
      $scope._handleShowOfferModal = function() {
        var canMakeOffer = summaryPageHelper.canMakeOffer(true, true);
        if (canMakeOffer.can) {
          summaryPageHelper.showAgentClausesModal(function() {
            summaryPageHelper.convertApplicationToOffer();
          });
        } else if (canMakeOffer.errors) {
          sp2UiHelper.showAlert({
            content: $filter('translate')('summary.inOrderToGenerateOfferCompleteNecessaryData', {
              componentCode: 'sp2'
            }),
            type: 'danger'
          });
        } else if (canMakeOffer.dateToday) {
          summaryPageHelper.showAgentClausesModal(function() {
            summaryPageHelper.showTodaysOfferConfirmModal('offer', function() {
              $scope.applyOfferCreation();
            });
          });
        }
      };

      /**
       * Shows "reserve policy number / pay and accept" modal
       * @private
       */
      $scope._handleReservePolicyNumberModal = function() {
        var canMakeOffer = summaryPageHelper.canMakeOffer(true, true);
        if (canMakeOffer.can) {
          summaryPageHelper.showAgentClausesModal(function() {
            summaryPageHelper.reservePolicyNumber();
          });
        } else if (canMakeOffer.errors) {
          sp2UiHelper.showAlert({
            content: $filter('translate')('summary.inOrderToGenerateOfferCompleteNecessaryData', {
              componentCode: 'sp2'
            }),
            type: 'danger'
          });
        } else if (canMakeOffer.dateToday) {
          summaryPageHelper.showAgentClausesModal(function() {
            summaryPageHelper.showTodaysOfferConfirmModal('offer', function() {
              $scope.applyPolicyNumberReservation();
            });
          });
        }
      };

      $scope.showIofferSelectionModal = function() {
        summaryPageHelper.showIOfferSelectionModal($scope.sendIOffer.bind(this));
      };

      /**
       * dedide whether to show "policy holder contact data modal" or directly call service and send offer to account
       * @param {boolean} sendViaEmail if true ioffer/sendbyemail will be called
       */
      $scope.sendIOffer = function(sendViaEmail) {
        if (!summaryPageHelper.canEmailOffer()) { // if false, it means holder hasn't agreed required clause or hasn't provided email address
          summaryPageHelper.showinsurerContactDataModal(function() {
            $scope.makeIOffer(sendViaEmail);
          }, sendViaEmail);
        } else {
          $scope.makeIOffer(sendViaEmail);
        }
      };

      /**
       * trigger ioffer service
       * @param {boolean} sendViaEmail whether to additionally send offer via email (other service call)
       */
      $scope.makeIOffer = function(sendViaEmail) {
        var canMakeOffer = summaryPageHelper.canMakeOffer();
        if (canMakeOffer.can) {
          sp2UiHelper.showBlockUi($filter('translate')('Public.sending', {
            componentCode: 'Public'
          }));
          applicationHelper.convertApplicationToIOffer(function() {
            sp2UiHelper.hideBlockUi();
            if ($scope.dataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_IOFFER) {
              sp2CommonHelper.showConfirmModal({
                treatLabelAsI18nCode: true,
                title: ['sp2.modal.info.title', {
                  componentCode: 'sp2'
                }],
                okBtnName: ['sp2.modal.close', {
                  componentCode: 'sp2'
                }],
                cancelBtnName: '',
                text: [sendViaEmail ? 'summary.offerHasBeenSentToClientsEmail' : 'summary.offerWasSentToClientPersonalAccount', {
                  componentCode: 'sp2'
                }],
                okBtnCallback: function() {
                  summaryPageHelper.checkAndShowExtraLifeModal();
                }
              });
            } else {
              var text = $filter('translate')('summary.errorOccurredTransferringOfferToClientAccount', {
                componentCode: 'sp2'
              });
              if (messagesHelper.isAnyError(true) && messagesHelper.isAnyWarn(true)) {
                text += $filter('translate')('summary.detailsInCenterOfAlerts.', {
                  componentCode: 'sp2'
                });
              }

              sp2UiHelper.showAlert({
                content: text,
                type: 'danger'
              });
            }
          }, sendViaEmail);

        } else {
          sp2UiHelper.showAlert({
            content: $filter('translate')('summary.inOrderToSendOfferCompleteNecessaryDataInEditMode', {
              componentCode: 'sp2'
            }),
            type: 'danger'
          });
        }
      };

      /**
       * apply offer/ioffer creation - run service
       * @return {undefined}
       */
      $scope.applyOfferCreation = function() {
        sp2UiHelper.showBlockUi($filter('translate')('makingOffer', {
          componentCode: 'sp2'
        }));
        applicationHelper.saveApplication();
        summaryPageHelper.convertApplicationToOffer();
      };

      /**
       * apply policy number reservation
       * @return {undefined}
       */
      $scope.applyPolicyNumberReservation = function() {
        sp2UiHelper.showBlockUi($filter('translate')('spinner.reservingPolicyNumber', {
          componentCode: 'sp2'
        }));
        applicationHelper.saveApplication();
        summaryPageHelper.reservePolicyNumber();
      };

      $scope.showPolicyModal = function(policyId, type, tab) {
        summaryPageHelper.showPolicyModal(policyId, type, tab);
      };

      /**
       * powrot do edycji
       */
      $scope.backToEdit = function() {
        sp2UiHelper.showBlockUi($filter('translate')('summary.returningToEditing', {
          componentCode: 'sp2'
        }));
        applicationHelper.convertApplicationToCalculation(function() {
          $state.go($scope.CONSTANTS.PAGE_INIT, {
            'action': 'viewApplication',
            'applicationId': $scope.dataContainer.application.metaData.get('id')
          });
        });
      };

      $scope.setCalculateEstatesSeparately = function(value) {
        if (!appVariables.isSupplement) {
          var appDynVals = $scope.dataContainer.application.get('dynamicValues');
          appDynVals._calculateEstatesSeparately = !value;
          $scope.dataContainer.application.set('dynamicValues', appDynVals);

          var nonCommunicationPolicies = $scope.dataContainer.policies.filter(function(policy) {
            return policy.estates.length > 0 || policy.persons.length > 0;
          });
          // https://ebok.atena.pl/browse/IHESTIAGEN-881
          if (!appDynVals._calculateEstatesSeparately && nonCommunicationPolicies.length > 1) {
            // usuniecie klauzul dla polis mieniowych, tylko jesli jest wiecej niz 1 taka polisa i mozliwy jest podzial/zlaczenie
            var productClausesSelected = false;
            angular.forEach($scope.dataContainer.policies, function(policy) {
              if (policy.vehicles.length <= 0) {
                if ($scope.dataContainer.productClauses[policy.number] && $scope.dataContainer.productClauses[policy.number].clauseCode.length > 0) {
                  productClausesSelected = true;
                }
                delete $scope.dataContainer.productClauses[policy.number];
              }
            });
            $scope.removeClausesForNonExistingPolicies();
            if (productClausesSelected) {
              productsClausesCleanupHelper.showModal('_calculateEstatesTogether');
            }
          }
          applicationHelper.saveApplication();
        }
      };

      // usuniecie klauzul dla polis, ktorych juz nie ma na wniosku
      $scope.removeClausesForNonExistingPolicies = function() {
        angular.forEach($scope.dataContainer.productClauses, function(value, key) {
          var currentPolicies = $scope.dataContainer.policies.map(function(policy) {
            return policy.number;
          });
          if(currentPolicies.indexOf(key) === -1) {
            delete $scope.dataContainer.productClauses[key];
          }
        });
      };

      //wypowiedzenie polisy zbywcy
      $scope.terminatePolicy = function() {
        if (angular.isObject($scope.dataContainer.application.dynamicValues) && angular.isDefined($scope.dataContainer.application.dynamicValues._operationId)) {
          $window.location = sp2CommonHelper.getServerConfig().get('external', 'AGENT_URL') + $scope.CONFIG.BEHAVIOR.ocBuyerPolicyWithdrawalUrl.format($scope.dataContainer.application.dynamicValues._operationId, encodeURIComponent($scope.dataContainer.application.metaData.id));
        } else {
          sp2CommonHelper.throwException($filter('translate')('summary.canNotFindIDRequestToPurchaseVehicle', {
            componentCode: 'sp2'
          }));
        }
      };

      // redirect to Sirius
      $scope.gotoSirius = function() {
        lsnUtils.openWindow(sp2CommonHelper.getServerConfig().get('external', 'SYRIUSZ_URL'));
      };

      /**
       * redirect to Ergo 4
       * @return {boolean} false if redirected straightly
       */
      $scope.gotoErgo4 = function() {
        return summaryPageHelper.gotoErgo4();
      };

      $scope.payWithBlik = function() {
        sp2BlikPaymentModalHelper.showModal().then(angular.noop, function(event) {
          if (event && event.blikPayed) {
            $scope.tplData.options.showBlikPayment = false;
            if (event.fromOffer) {
              applicationHelper.setPolicyMode();
            }
          }
        });
      };

      $scope.init();
    }
  ]);
