angular.module('ihestiaIkomunikator')
  .service('iKomunikatorSubjectDictionariesSvc', ['IHestiaRestServiceAbstractDef', 'ihestiaIkomunikatorContextHelper',
    function(IHestiaRestServiceAbstractDef, ihestiaIkomunikatorContextHelper) {

      var IKomunikatorSubjectDictionariesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'resources/subjectDictionaries';
        this.businessArea = 'ikomunikator';

        this.getDocumentTypes = function(data, callback, errorCalback) {
          var params = {
            headers: angular.extend(self.additionalHeaders, ihestiaIkomunikatorContextHelper.getContextForHeaders())
          };
          return self.get(null, data, 'documentTypes', callback, errorCalback, params);
        };
      };

      return new IKomunikatorSubjectDictionariesSvc();
    }
  ]);

