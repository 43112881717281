/**
 * Model etykiety
 */
angular.module('ihestiaCommonDirectives')
  .factory('IhestiaLabelModel', [function() {
    var LabelModel = function() {
      var self = this;

      self.code = null;
      self.component = null;
      self.description = null;
      self.name = null;
      self.status = null;
      self.variant = null;
      self.translations = {};
      self.draftTranslations = {};
    };

    return LabelModel;
  }])
  .factory('ihestiaLabelConstants', [function(){
      var constants = {
        LABEL_STATUS_DRAFT: 'Draft',
        LABEL_STATUS_ACCEPTED: 'Accepted'
      };

      return constants;
    }]);