angular.module('ihestiaRestServicesBase')

  /**
   * pobieranie obrazka captcha
   */
  .service('applicationsPrintoutsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {
      var ApplicationsPrintouts = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'applications';
        this.businessArea = 'printouts';
        this.port = '8208';
        this.path = 'RestPrintouts';

        this._parentMethods = {
          post: self.post,
          put: self.put,
          get: self.get,
          remove: self.remove
        };

        this.printNow = function(id, data, subResource, callback, errorCallback, httpParams, symbol) {
          self.id = id;
          var parseCallback = function(result) {
            if (angular.isFunction(callback)) {
              callback(result);
            }
          };

          if (typeof httpParams === 'undefined' || httpParams === null) {
            httpParams = {};
          }

          httpParams.allowedStatuses = [404];
          httpParams.url = self._getServiceUrl(id, 'symbols') + '/' + symbol;

          return self._parentMethods.get(id, data, subResource, parseCallback, errorCallback, httpParams);
        };

        this.getFileStream = function(id, data, subResource, lastQueryParam, callback, errorCallback, httpParams) {
          self.id = id;
          var parseCallback = function(result) {
            if (angular.isFunction(callback)) {
              callback(result);
            }
          };

          if (typeof httpParams === 'undefined' || httpParams === null) {
            httpParams = {};
          }

          httpParams.allowedStatuses = [404];
          httpParams.url = self._getServiceUrl(id, subResource) + (lastQueryParam ? ('/' + lastQueryParam) : '');

          return self._parentMethods.get(id, data, null, parseCallback, errorCallback, httpParams);
        };

        this.getAvailablePrintoutTypes = function(appNumber, callback) {
          self.get(appNumber, null, null, callback);
        };

      };


      /**
       * Konstruktor v2
       */
      var ApplicationsPrintoutsV2 = function() {
        ApplicationsPrintouts.apply(this, arguments);
        this.apiVersion = 'v2';
      };

      /**
       * Konstruktor v1
       */
      var ApplicationsPrintoutsV1 = function() {
        ApplicationsPrintouts.apply(this, arguments);
      };

      return IHestiaRestServiceAbstractDef.setApiConstructors({
        v1: ApplicationsPrintoutsV1,
        v2: ApplicationsPrintoutsV2
      });

    }
  ]);