angular.module('salesPath2')
/**
 * helper do usług dla wniosku ubezpieczeniowego
 */
  .service('applicationServiceHelper', ['applicationsSvc',
    function(applicationsSvc) {
      var ApplicationServiceHelper = applicationsSvc;

      var originalMethods = {
        sendApplication: ApplicationServiceHelper.sendApplication
      };

      ApplicationServiceHelper.sendApplication = function(application, simulation, save, response, errorCallback, httpParams) { //eslint-disable-line consistent-return
        // EHNLEARN-873
        return originalMethods.sendApplication(application, false, true, response, errorCallback, httpParams);
      };

      return ApplicationServiceHelper;
    }]);