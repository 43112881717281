angular.module('ihestiaCommonDirectives.binaryUpload')
  .factory('BinaryUploadOptions', function() {

    var BinaryUploadOptions = function() {
      var self = this;

      /**
       * Adres serwera na kory chcemy przeslac pliki
       * @type {string}
       */
      this.url = null;

      /**
       * Jesli rozne od null znaczy to ze chunkujemy pliki
       * @type {string}
       */
      this.chunkSize = null;

      /**
       * [checkFilesBeforeSend czy wysylamy informacje przed wyslaniem pliku, do frp zawsze tak]
       * @type {[type]}
       */
      this.checkFilesBeforeSend = null;

      /**
       * setter
       */
      this.set = function(options) {
        angular.forEach(options, function(option, name) {
          self[name] = option;
        });
      };

    };
    return BinaryUploadOptions;
  });