angular.module('ihestiaCommonDirectives.landingPage')
  .controller('LandingPageCtrl', ['$scope', '$state', '$interval', '$window',
    function($scope, $state, $interval, $window) {

      $scope.state = $state;

      $scope.countdown = 5;
      $scope.intervalPromise = null;

      $scope.init = function() {
        $scope.startCountdown();
      };

      $scope.startCountdown = function()
      {
        $scope.intervalPromise = $interval(function() {
            $scope.countdown = $scope.countdown - 1;
            if($scope.countdown === 0)
            {
              $interval.cancel($scope.intervalPromise);
              var url = '/#/' + $state.params.path;
              if($window.location.hash.indexOf('?') !== -1)
              {
                url = url + $window.location.hash.substr($window.location.hash.indexOf('?'));
              }
              $window.location = url;
            }
          }, 1000);
      };
    }
  ]);