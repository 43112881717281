//przy opisie pamietac by opisac ze wymagane jest person bezposrednio w scope
angular.module('salesPath2')
        .directive('greenCard', ['greenCardsSvc', 'availabilityHelper',
          function(greenCardsSvc, availabilityHelper) {
            return {
              restrict: 'E',
              replace: true,
              scope: true,
              templateUrl: function(elem, attrs) {
                if (attrs.drvTemplate) {
                  return attrs.drvTemplate;
                } else {
                  return 'salesPath2Template/autocompleters/vehicle/greenCard/greenCard.tpl.html';
                }
              },
              controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
                  $scope.greenCards = [];

                  $scope.$watch($attrs.greenCard, function(val) {
                    $scope.greenCard = val;
                  });

                  if (availabilityHelper.isAvailable('greenCards')) {
                    greenCardsSvc.get(null, {}, null).then(function(res) {
                      angular.forEach(res.data, function(item)
                      {
                        $scope.greenCards.push(item);
                      });
                    }, angular.noop);
                  }
                }]
            };
          }]);