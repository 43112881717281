/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaCommonDirectives.changeLanguage')
  .provider('changeLanguageConfig',function() {

      var self = this;
      self.defaultConfig = {
        template: 'ihestia-commondirectives-template-app/labels/changeLanguage.tpl.html'
      };

     /**
     * Magic get
     * @return {Object}
     */
    this.$get = [
      function() {
        return self;
      }];
  });