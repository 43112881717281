angular.module('ihestiaGridSettings')
  .controller('ihestiaGridSettingsSaveModalCtrl', ['$scope',
    function ($scope) {

      $scope.nameChanged = function()
      {
        $scope.modals.saveSettings.nameAlreadyExist = false;
      };
    }
  ]);
