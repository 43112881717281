angular.module('ihestiaCommonDirectives.labelActionHelper')
  .provider('labelActionHelper', [function() {

    var LabelActionHelper = function() {
      var self = this;

      //lista callbackow wywolanych po zmianie jezyka
      //contextName => callback
      this.afterChangeLangCallbacks = {};

      /**
       * [registerAction description]
       * @param  {[type]}   contextName [description]
       * @param  {Function} callback    [description]
       * @return {[type]}               [description]
       */
      this.registerAction = function(contextName, callback, multipleMode){
        if(multipleMode) {
          if(!angular.isArray(self.afterChangeLangCallbacks[contextName])) {
            self.afterChangeLangCallbacks[contextName] = [];
          }
          self.afterChangeLangCallbacks[contextName].push(callback);
        } else {
          self.afterChangeLangCallbacks[contextName] = callback;
        }
      };

      /**
       * Wołanie wszystkich zarejestrowanych akcji
       * @return {undefined}
       */
      this.callRegisteredAction = function(){
        angular.forEach(self.afterChangeLangCallbacks, function(callback){
          if(angular.isFunction(callback)){
            callback();
          } else if(angular.isArray(callback)) {
            // wsparcie dla wielu callbacków pod jednym kontekstem
            angular.forEach(callback, function(callbackItem){
              callbackItem();
            });
          }
        });
      };

      /**
       * Czyścimy callbacki dla zadanego kontekstu
       * @param  {String} contextName nazwa kontekstu
       * @param  {Function|null} callbackRef referencja funkcji do usunięcia (jeśli nie chcemy usuwać wszytkiego)
       * @return {LabelActionHelper} self
       */
      this.clearRegisteredAction = function(contextName, callbackRef) {
        if(self.afterChangeLangCallbacks[contextName]) {
          if(callbackRef && angular.isArray(self.afterChangeLangCallbacks[contextName])) {
            // jeśli chcemy usunąć konkretną funkcję, to iterujemy się po tablicy i usuwamy tylko odopowiednie referencje
            // robimy to forem od końca, aby móc usuwać elementy bez dodatkowych zmiennych (nie psujemy pętli)
            for(var i = self.afterChangeLangCallbacks[contextName].length - 1; i >= 0; i--) {
              if(self.afterChangeLangCallbacks[contextName][i] === callbackRef) {
                self.afterChangeLangCallbacks[contextName].splice(i,1);
              }
            }
          } else {
            delete self.afterChangeLangCallbacks[contextName];
          }
        }

        return self;
      };
    };

    var labelActionHelperInstance = new LabelActionHelper();

    /**
     * Metoda dostępna w sekcji .config
     * @return {ConfigService} [description]
     */
    this.getLabelHelper = function() {
      return labelActionHelperInstance;
    };

    /**
     * return injectora
     * @return {ConfigService} [description]
     */
    this.$get = [function() {
      return labelActionHelperInstance;
    }];
  }]);