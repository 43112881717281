/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuHelp')
  .provider('ihestiaMenuHelpConfig',function() {

      var self = this;
      self.defaultConfig = {
        justVideoAndChat: false,//czy tylko wyswietlac video i chat - np w Jupiterze
        template: 'ihestia-menu-template-app/help/help.tpl.html',
        moveablePopupBody: 'ihestia-menu-template-app/help/helpModalContent.tpl.html'
      };

     /**
     * Magic get
     * @return {Object}
     */
    this.$get = [
      function() {
        return self;
      }];
  });