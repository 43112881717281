angular.module('salesPath2')
  .service('summaryPrintoutsHelper', ['$rootScope', 'sp2CommonHelper', 'summaryHelper', 'policiesPrintoutsSvc', '$filter', 'sp2UiHelper', 'CONSTANTS',
    function($rootScope, sp2CommonHelper, summaryHelper, policiesPrintoutsSvc, $filter, sp2UiHelper, CONSTANTS) {
      var SummaryPrintoutsHelper = function() {
        var self = this;
        /**
         * Wydruki podsumowania
         * @type {Object}
         */

        this.policyNumber = '';
        this.printouts = [];
        /**
         * alternative printout config
         * @type {object} list of objects: 
         * {
         *   code: unique code
         *   description: description shown in tpl
         *   callback: function to call if printoout is selected
         * }
         */
        this.alternativePrintout = null;

        //ustawienia modali
        this.modals = {};

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.summaryPrintoutsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.summaryPrintoutsModal.title',
            size: 'sm',
            okBtnName: 'sp2.modal.print',
            okBtnDisabled: false
          }
        };

        /**
         * Pobranie danych wydruków i wyświetlenie modala
         */
        this.showPrintoutsModal = function(printouts, policyNumber, alternativePrintout) {
          self.policyNumber = policyNumber;
          self.printouts = printouts;
          self.alternativePrintout = alternativePrintout || null;

          sp2CommonHelper.showModal('summaryPrintoutsModal');
        };


        /**
         * Pobranie rodzajów wydruków
         */
        this.getPrintouts = function() {
          return self.printouts;
        };

        /**
         * returns alternative printout config
         */
        this.getAlternativePrintout = function() {
          return self.alternativePrintout;
        };

        /**
         * Pobranie id polisy
         */
        this.getPolicyNumber = function() {
          return self.policyNumber;
        };


        /**
         * Wydruk zaznacznych dokumentów polisy
         */
        this.printSelected = function(printouts, policyNumber) {
          var selectedPrintouts = [];

          angular.forEach(printouts, function(val) {
            if (val.checked === true) {
              selectedPrintouts.push(val.code);
            }
          });

          if (selectedPrintouts.length > 0) {
            var mime = 'application/pdf';
            var params = {
              headers: {
                'Accept': mime
              },
              responseType: 'blob'
            };

            sp2UiHelper.showBlockUi($filter('translate')('downloadingFile', {componentCode: 'Public'}));
            policiesPrintoutsSvc.printNow(policyNumber, null, null, function(result) {
              sp2CommonHelper.saveFile(result, mime);
              sp2UiHelper.hideBlockUi();
            }, function(rej) {
              sp2UiHelper.hideBlockUi();
              if (rej.status === 409 && selectedPrintouts.includes(CONSTANTS.PRINT_GREEN_CARD)) {
                sp2UiHelper.showAlert({
                  content: $filter('translate')('unavailableGreenCard', {componentCode: 'Public'}),
                  type: 'danger',
                  duration: 20
                });
              }
            }, params, selectedPrintouts);

          }
        };

      };

      return new SummaryPrintoutsHelper();
    }
  ])
  .run(['summaryPrintoutsHelper', function(summaryPrintoutsHelper) {
    lsnNg.noop(summaryPrintoutsHelper);
  }]);
