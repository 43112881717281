angular.module('iSkanerLsn')
  .factory('iSkanerConstants', [function() {
    return {
      //Events
      START_PROCESSING_EVENT: 'STARTPROCESSINGEVENT',
      FINISH_PROCESSING_EVENT: 'FINISHPROCESSINGEVENT',
      NEW_DEVICE_EVENT: 'NEWDEVICEEVENT',
      NEW_FILE_EVENT: 'newFile',//@todo jesli to mozliwe zmienic nazwy na takie jednego typu
      //dostepne stany
      STATE_INITIALIZATION: 'initialization',
      STATE_START: 'start',
      STATE_SHOW_QR: 'showQr',
      STATE_PAIRING_DEVICE: 'pairingDevice',
      STATE_UNPAIRING_DEVICE: 'unpairingDevice',
      STATE_DEVICE_PAIRED: 'devicesPaired',
      STATE_PROCESSING: 'processing',
      STATE_READY_TO_SEND_FRP: 'readyToSendFRP',
      STATE_SENDING_TO_FRP: 'sendingToFRP'
    };
  }]);