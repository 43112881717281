angular.module('ihestiaIkomunikator')
  .service('ikomunikatorCommonHelper', ['ihestiaIkomunikatorUserTypeHelper', 'ihestiaTrimInfo', 'iKomunikatorConstants',
    function(ihestiaIkomunikatorUserTypeHelper, ihestiaTrimInfo, iKomunikatorConstants) {
      var CommonHelper = function() {
        var self = this;
        /**
         * [getUserInfoForFullPanelPage wystawia odpowiednie informacje na podstawie typu zalogowanego uzytkownika oraz typu uzytkownika do wyswietlenia]
         * @return {[type]} [description]
         */
        this.getUserInfoForFullPanelPage = function(user) {
          //na podstawie jiry IHESTIAIKO-378
          var viewFor = 'Client';
          if(ihestiaIkomunikatorUserTypeHelper.isCentralUserLoggedForViewPanel()){
            viewFor = 'Central';
          }else if(ihestiaIkomunikatorUserTypeHelper.isAgentUserLoggedForViewPanel()){
            viewFor = 'Agent';
          }

          //klient
          if(user.type === iKomunikatorConstants.CONTACT_ROLE_CLIENT){
            if(viewFor === 'Client'){
              return self.getUserInfo([{text: user.surname}, {text: user.name}]);
            }else{
              return self.getUserInfo([{text: user.surname}, {text: user.name, fieldSeparator: ' / '}, {text: user.pesel}]);
            }
          //pracownik
          }else if(user.type === iKomunikatorConstants.CONTACT_ROLE_WORKER){
            if(viewFor === 'Central'){
              return self.getUserInfo([{text: user.surname}, {text: user.name, fieldSeparator: ' / '}, {text: user.salesProfileId, fieldSeparator: ' / '}, {text: user.ra}]);
            }else{
              return self.getUserInfo([{text: user.surname}, {text: user.name}]);
            }
          //profil sprzedazowy
          }else if(user.type === iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE){
            if(viewFor === 'Central'){
              return self.getUserInfo([{text: user.name, fieldSeparator: ' / '}, {text: user.salesProfileId}]);
            }else{
              return $.trim(user.name);
            }
          //centrala, likwidator
          }else if([iKomunikatorConstants.CONTACT_ROLE_CENTRAL, iKomunikatorConstants.CONTACT_ROLE_CLAIMS_ADJUSTER].indexOf(user.type) !== -1){
            return self.getUserInfo([{text: user.surname}, {text: user.name, fieldSeparator: ' / '}, {text: user.login}]);
          } else {
            //inny, pula, skrzynka funkcyjna
            //brak typu - nie powinno wystepowac gdy naprawia uslugi
            return $.trim(user.name);
          }

        };

        this.getUserInfo = function(elements){
          var settings = {
            elements: elements,
            maxLength: 1000,//nie ma byc ograniczen a domyslnie sa na 22
            emptyValue: 'Brak danych',
            html: false
          };

          return ihestiaTrimInfo(settings);
        };
      };

      return new CommonHelper();
    }
]);
