angular.module('ihestiaWidgets.policyOperations')
  .factory('clientDataHelper', ['ihestiaDictionaryHelper', 'ihestiaRestDictionaryNationalitiesSvc', 'ihestiaRestDictionaryIdentityDocumentTypesSvc', '$q', 'IdentityDocumentModelConstants', 'PersonModel', 'AddressModel', 'AddressModelConstants', 'ContactModel', 'ContactModelConstants', '$filter',
    function(ihestiaDictionaryHelper, ihestiaRestDictionaryNationalitiesSvc, ihestiaRestDictionaryIdentityDocumentTypesSvc, $q, IdentityDocumentModelConstants, PersonModel, AddressModel, AddressModelConstants, ContactModel, ContactModelConstants, $filter) {


      var ClientDataHelper = function() {

        var self = this;

        this.nationalities = {
          'PL': {
            code: 'PL',
            description: 'POLSKIE - POLSKA',
            name: 'POLSKIE'
          }
        }; //w razie gdyby usługa się wysypała będziemy mieć chociaż domyślną wartość
        this.identityDocumentTypes = IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE; //wartość przed zaciągnięciem z usługi, w razie gdyby się wysypała

        /**
         * Init
         * @return {Promise} ok, gdy załadowane wszystkie słowniki
         */
        this.init = function() {
          var promises = [];
          promises.push(self._setNationalities);
          promises.push(self._setIdentityDocumentTypes);
          return $q.all(promises);
        };

        /**
         * [setNationalities description]
         */
        this._setNationalities = function() {
          return ihestiaRestDictionaryNationalitiesSvc.find('', function(response) {
            angular.forEach(response.data.items, function(v) {
              self.nationalities[v.code] = v;
            });
          });
        };

        /**
         * [setIdentityDocumentTypes description]
         */
        this._setIdentityDocumentTypes = function() {
          return ihestiaRestDictionaryIdentityDocumentTypesSvc.get(null, {}, null, function(response) {
            angular.forEach(response.data, function(v) {
              self.identityDocumentTypes[v.code] = v;
            });
          });
        };

        /**
         * zwraca nową pustą osobę
         * @return {[type]} [description]
         */
        this.prepareNewPerson = function(nokore)
        {
          var person = new PersonModel();
          var mainAddress = new AddressModel();
          mainAddress.set('code', AddressModelConstants.ADDRESS_TYPE_STAL);
          if(!nokore)
          {
            var corespAddress = new AddressModel();
            corespAddress.set('code', AddressModelConstants.ADDRESS_TYPE_KORE);
            person.set('addresses', [mainAddress, corespAddress]);
          }
          else
          {
             person.set('addresses', [mainAddress]);
          }

          var emailContact = new ContactModel();
          emailContact.set('code', ContactModelConstants.CONTACT_TYPE_MAIL);
          var komoContact = new ContactModel();
          komoContact.set('code', ContactModelConstants.CONTACT_TYPE_KOMO);
          person.set('contacts', [emailContact, komoContact]);

          var personData = person.getData();
          personData.clientType = 'person';
          personData.noSecondAddr = true;

          return personData;
        };

        this.getClientMainAddress = function(personData)
        {
          var mainAddress = null;
          angular.forEach(personData.addresses, function(address){
            if(address.code === AddressModelConstants.ADDRESS_TYPE_STAL)
            {
              mainAddress = address;
            }
          });
          return mainAddress;
        };

        /**
         * przygotowujemy osobę/organizację z wniosku
         * @param  {Object} client [description]
         */
        this.prepareApplicationPerson = function(client)
        {
          if(angular.isDefined(client.firstName))
          {
            //zakładamy, że jak ma imię to to osoba
            client.clientType = 'person';
          }
          else
          {
            client.clientType = 'organization';
          }
          if(client.birthDate)
          {
            client.birthDate = $filter('date')(client.birthDate,'yyyy-MM-dd');
          }

          //pierwszy ma być adres email a drugi telefon
          //ustawiamy kontakty i przepisujemy wartości
          var emailContact = new ContactModel();
          emailContact.set('code', ContactModelConstants.CONTACT_TYPE_MAIL);
          var komoContact = new ContactModel();
          komoContact.set('code', ContactModelConstants.CONTACT_TYPE_KOMO);

          if(client.contacts)
          {
            angular.forEach(client.contacts, function(contact){
              if(contact.code === ContactModelConstants.CONTACT_TYPE_MAIL)
              {
                emailContact.value = contact.value;
              }
              else if(contact.code === ContactModelConstants.CONTACT_TYPE_KOMO)
              {
                komoContact.value = contact.value;
                if(contact.countryPrefix)
                {
                  komoContact.countryPrefix = contact.countryPrefix;
                }
              }
            });
          }
          client.contacts = [emailContact.getData(), komoContact.getData()];

          return client;
        };

        this.getPersonName = function(client)
        {
          var name;
          if(angular.isDefined(client.firstName))
          {
            name = client.firstName + ' ' + client.lastName;
          }
          else
          {
            name = client.name;
          }
          return name;
        };

        this.getPersonAccount = function(client)
        {
          var account;
          if(client.accounts && client.accounts.length > 0)
          {
            account = client.accounts[0].iban; //numer postaci PL50109013620000000036017904
            if(account)
            {
              account = account.substr(2); //ucinamy prefiks
            }
          }
          return account;
        };

        this.prepareApplicationPersons = function(clients)
        {
          angular.forEach(clients, function(client){
            self.prepareApplicationPerson(client);
          });
          return clients;
        };

        /**
         * jak adres korespondencyjny jest pusty, bo nie ma być możliwości go wypełnić to wycinamy go
         */
        this.clearSecondAddressIfEmpty = function(personData)
        {
          var secondAddressKey = null;
          angular.forEach(personData.addresses, function(address, addressKey){
            if(address.code === AddressModelConstants.ADDRESS_TYPE_KORE && (angular.isUndefined(address.postalCode) || address.postalCode === null))
            {
              secondAddressKey = addressKey;
            }
          });
          if(secondAddressKey !== null)
          {
            personData.addresses.splice(secondAddressKey, 1);
          }
        };

        /**
         * czyścimy adres korespondecyjny
         * @param  {[type]} personData [description]
         */
        this.clearSecondAddress = function(personData)
        {
          var secondAddressKey = null;
          angular.forEach(personData.addresses, function(address, addressKey){
            if(address.code === AddressModelConstants.ADDRESS_TYPE_KORE)
            {
              secondAddressKey = addressKey;
            }
          });
          if(secondAddressKey !== null)
          {
            personData.addresses.splice(secondAddressKey, 1);
          }
        };

        this.getPersonsByRole = function(applicationData, recordNumer, role)
        {
          if(!applicationData.vehicleOwnerChangeRecords)
          {
            return [];
          }

          if(recordNumer === 'last')
          {
            recordNumer = applicationData.vehicleOwnerChangeRecords.length - 1;
          }
          var persons = [];
          var personsIds = [];
          var record = applicationData.vehicleOwnerChangeRecords[recordNumer];
          angular.forEach(record.partnerRoles, function(personRole, personId){
            if(personRole === role)
            {
              personsIds.push(personId);
            }
          });
          angular.forEach(applicationData.persons, function(person){
            if(personsIds.indexOf(person.metaData.id) !== -1)
            {
              persons.push(person);
            }
          });
          return persons;
        };

        // sortujemy adresy żeby stały był pierwszy a korespondencyjny drugi, bo tak jest w templatce clientData
        this.sortAddresses = function(person) {
          var mainAddress = null;
          var secondAddress = null;
          angular.forEach(person.addresses, function(address){
            if(address.code === AddressModelConstants.ADDRESS_TYPE_STAL) {
              mainAddress = address;
            } else if(address.code === AddressModelConstants.ADDRESS_TYPE_KORE) {
              secondAddress = address;
            }
          });

          person.addresses = [];
          if(mainAddress) {
            person.addresses.push(mainAddress);
          } else if(secondAddress) {
            // mamy adres korespondencyjny ale nie mamy stałego, więc dorzucamy pusty stały
            mainAddress = new AddressModel();
            mainAddress.set('code', AddressModelConstants.ADDRESS_TYPE_STAL);
            person.addresses.push(mainAddress);
          }
          if(secondAddress) {
            person.addresses.push(secondAddress);
          }
        };


      };



      return new ClientDataHelper();
    }]);