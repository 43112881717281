angular.module('ihestiaIkomunikator.common')
  .service('ihestiaIkomunikatorPermissionHelper', ['ihestiaIkomunikatorUserTypeHelper', 'ihestiaConfigHelper', 'ihestiaAgHelper', 'ihestiaCommonComponentStatusHelper', 'ihestiaIkomunikatorCommonHelper',
    function(ihestiaIkomunikatorUserTypeHelper, ihestiaConfigHelper, ihestiaAgHelper, ihestiaCommonComponentStatusHelper, ihestiaIkomunikatorCommonHelper) {
      var PermissionHelper = function() {
        var symbolPublic = ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'),
            agSymbol = ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_IKOMUNIKATOR'),
            self = this;

        /**
         * [shouldShowApp sprawdza czy użytkownik ma dostęp do komponentu ikomuniktaora oraz czy ikomunikator jest wogóle dostępny]
         * @return {[type]} [description]
         */
        this.shouldShowApp = function() {
          return ihestiaAgHelper.hasPermissionFor(symbolPublic, agSymbol) && ihestiaCommonComponentStatusHelper.isComponentEnabledByAgSymbol(agSymbol) &&
            !ihestiaIkomunikatorUserTypeHelper.isOtherUserLogged() && ihestiaIkomunikatorCommonHelper.defaultConfig.available;
        };

        this.shouldShowAction = function(){
          return self.shouldShowApp();
        };
      };

      return new PermissionHelper();
    }
  ]);