angular.module('salesPath2')
  .controller('communicationCtrl', ['$scope', '$timeout', 'communicationHelper', 'navigationHelper', 'PAGE_CONFIG', 'vehicleModalHelper', 'sp2SelectionHelper', 'addHelper', 'CONSTANTS', 'matrixHelper', 'actionHelper', 'mainDataContainer',
    function($scope, $timeout, communicationHelper, navigationHelper, PAGE_CONFIG, vehicleModalHelper, selectionHelper, addHelper, CONSTANTS, matrixHelper, actionHelper, mainDataContainer) { // eslint-disable-line angular/di

      $scope.tplData = communicationHelper.tplData;
      $scope.communicationHeaderSettings = null;
      $scope.activeProductList = communicationHelper.activeProductList;

      $scope.navigationHelper = navigationHelper;

      $scope.localizationBoxVisable = false;
      $scope.personBoxVisable = false;
      $scope.vehicleBoxVisable = true;

      /**
       * inicjacja modułu
       * @return {[type]} [description]
       */
      $scope.init = function() {
        $scope.initHeaderSettings();
        if (angular.equals($scope.dataContainer.vehicles, {})) {
          $timeout(function() {
            $scope.showEtxSearchVehicleModal();
          }, 0);
        }
        communicationHelper.refreshAllData();
        if ($scope.dataContainer.unsupportedVehicle) {
          vehicleModalHelper.chooseOtherVehicle();
        }
      };

      /**
       * pokazuję modal z wyborem pojazdu ETX (wymagane, jeżeli nie został wczęsniej wybrany na matrixie)
       */
      $scope.showEtxSearchVehicleModal = function() {
        vehicleModalHelper.showEtxSearchVehicleModal();
      };

      $scope.selectVariant = function(code, variant) {
        selectionHelper.toggleProductVariant(code, variant);
        communicationHelper.refreshAllData();
        return true;
      };

      $scope.toggleZk = function() {
        if(addHelper.isAddSelected(CONSTANTS.ADD_GREENCARD)) {
          addHelper.deleteAdd(CONSTANTS.ADD_GREENCARD);
          matrixHelper.clearGreenCard();
          communicationHelper.tplData.greenCardSelected = false;
          actionHelper.runAction('tarifficate');
        } else {
          // IHESTIAGEN-133
          mainDataContainer.greenCardNumber = null;
          addHelper.addAdd(CONSTANTS.ADD_GREENCARD);
          actionHelper.runAction('tarifficate');
          communicationHelper.refreshGreenCardData();
        }
        matrixHelper.refreshAllData();
      };

      /**
       * konfiguracja nagłówka
       */
      $scope.initHeaderSettings = function() {
        if (!PAGE_CONFIG.COMMUNICATION.header.enabled) {
          return;
        }
        $scope.pageHeaderSettings = angular.copy(PAGE_CONFIG.COMMUNICATION.header);
        delete $scope.pageHeaderSettings.enabled;
      };

      $scope.init();
    }
  ]);
