/**
 * Kontroler navbara ("hamburger")
 */
angular.module('ihestiaMenuLeftNavbar')
  .controller('ihestiaMenuLeftNavbarV2Ctrl', ['$scope', 'iHestiaMenuLeftNavbarHelperV2', '$timeout', 'ihestiaConfigHelper', '$window', 'iHestiaMenuLeftNavbarItems',
    function($scope, iHestiaMenuLeftNavbarHelperV2, $timeout, ihestiaConfigHelper, $window, iHestiaMenuLeftNavbarItems) {

      /**
       * Ustawienia menu
       * @type {Object}
       */
      $scope.navBarSettings = iHestiaMenuLeftNavbarHelperV2.settings;

      /**
       * Elementy menu
       * @type {Array}
       */
      $scope.menuItems = iHestiaMenuLeftNavbarItems.items;

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = lsnNg.noop;

      /**
       * Kliknięto w element menu
       * @param  {Object} item   element menu
       * @param  {Event} $event angular $event
       * @return {undefined}
       */
      $scope.itemClicked = function(item, $event) {
        if(item.urlCode) {
          var url = ihestiaConfigHelper.getUrl(item.urlCode, item.urlPostfix);
          $window.open(url, item.target ? item.target : '_self');
          $event.stopPropagation();
          iHestiaMenuLeftNavbarHelperV2.hide();
        } else if(angular.isFunction(item.onClick)) {
          var hide = item.onClick(item, $event);
          if(hide) {
            iHestiaMenuLeftNavbarHelperV2.hide();
          }
        }
      };

      /**
       * Najechanie na głowny element menu
       * @return {[type]} [description]
       */
      $scope.mouseenterMain = function() {
        if ($scope.navBarSettings.openLvl === 'none') {
          $scope.navBarSettings.openLvl = 'part';
        }
      };

      /**
       * Opuszczenie głównego elementu menu
       * @return {[type]} [description]
       */
      $scope.mouseleaveMain = function() {
        // timeout do poczekania na drugi event mouseenter (mouseenterLeftBar)
        $timeout(function() {
          if ($scope.navBarSettings.openLvl !== 'all') {
            $scope.navBarSettings.openLvl = 'none';
          }
        }, 0);
      };

      /**
       * Przelotka na showFullLeftBar ze względu na click na document ukrywający menu
       * @return {[type]} [description]
       */
      $scope.mainMenuClicked = function() {
        $scope.navBarSettings.preventClose = true;
        $scope.showFullLeftBar();
      };

      /**
       * Najechanie na lewe menu
       * @return {[type]} [description]
       */
      $scope.showFullLeftBar = function() {
        $scope.navBarSettings.openLvl = 'all';
      };
    }
  ]);