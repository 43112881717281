angular.module('salesPath2')
  .service('discountsHelper', ['DiscountsHelperClass',
    function(DiscountsHelperClass) {
      var discountsHelper = new DiscountsHelperClass();
      discountsHelper.init();

      return discountsHelper;
    }
  ])
  .run(['discountsHelper', 'actionHelper',
    function(discountsHelper, actionHelper) {
      actionHelper.registerHelper('discountsHelper', discountsHelper);
    }
  ]);