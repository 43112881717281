angular.module('ihestiaIkomunikator.fullListPanel')
  .service('ihestiaIkomunikatorFullListPanelHelper', ['$rootScope', 'iKomunikatorConstants', 'iKomunikatorMessageThreadRepresentersSvc', 'IKomunikatorMessageThreadModel', 'iKomunikatorMessagesThreadSvc', 'ihestiaIkomunikatorCommonHelper', 'triggerWhenInactive', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function($rootScope, iKomunikatorConstants, iKomunikatorMessageThreadRepresentersSvc, IKomunikatorMessageThreadModel, iKomunikatorMessagesThreadSvc, ihestiaIkomunikatorCommonHelper, triggerWhenInactive, ihestiaIkomunikatorLoaderMessagesHelper) {

      var FullListPanelHelper = function() {
        var self = this;

        this.defaultData = {
          searchFilters: {
            status: iKomunikatorConstants.STATUS_ALL,
            criteria: {},
            orderSpec: {
              direction: 'DESC',
              sortBy: 'dateFrom'
            },
            pageSpec: {
              pageNo: 0,
              pageSize: 20
            }
          },
          searchFor: null,
          cacheId: null,
          subjects: [],
          threads: [],
          loading: false,
          total: 0,
          showModalFilterOnInit: true,
          reloadSearchAgain: false,
          promiseFiter: {}
        };

        this.tplData = angular.copy(self.defaultData);

        this.init = function() {
          self.initSearch();
        };

        this.initSearch = function() {
          self.tplData.loading = true;
          self.tplData.threads = [];
          $rootScope.$broadcast('showBlockUi');

          iKomunikatorMessagesThreadSvc.findLoggedUserMessageThreads(self.defaultData.searchFilters, function(result) {
            // messageThreadRepresentersSvc.findReceivedMessages(null, function(result) {
            if (angular.isObject(result.data)) {
              ihestiaIkomunikatorLoaderMessagesHelper.setAnswerFromSearch(result.data, self.tplData);
              self.tplData.cacheId = result.data.resourceId;
            }

            self.tplData.loading = false;
            $rootScope.$broadcast('hideBlockUi');
          }, function() {
            self.tplData.loading = false;
            $rootScope.$broadcast('hideBlockUi');
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania wiadomości');
          });
        };

        this.filterSearch = function() {
          if (!self.tplData.loading && self.tplData.cacheId !== null) {
            self.tplData.searchFilters.pageSpec.pageNo = 0;
            self.tplData.threads = [];
            triggerWhenInactive(500, self.tplData.promiseFiter, self.loadFromCache);
          }
        };

        this.getThreadById = function(threadId) {
          var result = null;
          if (angular.isArray(self.tplData.threads)) {
            angular.forEach(self.tplData.threads, function(thread) {
              if (thread.threadId === threadId) {
                result = thread;
              }
            });
          }

          return result;
        };

        /**
         * [loadFromCache funkcja wyszukuje wiadomości z cahce zgodnie z ustawionymi paramtrami wyszukiwanie]
         */
        this.loadFromCache = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.loadFromCache(self.tplData);
        };
        
        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };
      };

      return new FullListPanelHelper();
    }
  ]);