angular.module('ihestiaWidgets.dashboard')
  .factory('newTaskIcrDataModalHelper', ['LsnModalHelper', '$q', 'newTaskIcrDataHelper',
    function(LsnModalHelper, $q, newTaskIcrDataHelper) {

      var NewTaskIcrDataModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;

        this.dicts = {};

        /**
         * Rozszerzamy opcje modala
         * @type {String}
         */
        this.setOptions({
          templateUrl: 'ihestia-widgets-templates-app/dashboard/newTask/icr/data/newTaskIcrDataModal.tpl.html',
          size: 'lg',
          withDefaultMargin: false,
          title: ['newTask', {
            componentCode: 'Dashboard'
          }],
          okBtnName: ['Public.confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        });

        var oldShowModal = self.showModal;
        this.showModal = function(modalData) {
          var thisFunc = this;
          return $q(function(resolve, reject) {
            newTaskIcrDataHelper.getIcrDicts().then(function(dicts){
              self.dicts = dicts;
              oldShowModal.apply(thisFunc, [modalData]).then(resolve, reject);
            }, reject);
          });
        };

        this.closeAndPreserveData = function() {
          this.setPromiseAsRejected();
          return this.hideModal();
        };

      };

      return new NewTaskIcrDataModalHelper();
    }]);
