angular.module('lsnBase.models')
  .service('strReplaceModelFilter', [function(){
    return function(val, params) {
      /**
       * dostepne parametry:
       * searchValue - szukany string
       * newValue - nowy string
       */
      var availableParams = {
        searchValue: null,
        newValue: null
      };
      var currentParams = availableParams;
      if (typeof val !== 'string') {
        return '';
      }
      if (typeof params === 'object') {
        currentParams = $.extend(currentParams, params);
      }
      return val.replace(currentParams.searchValue, currentParams.newValue);
    };
  }]);