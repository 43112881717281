angular.module('ihestiaWidgets.policyDetails', [
  'ihestia-widgets-templates-app',
  'lsnBase',
  'ihestiaCommonDirectives',
  'ihestiaRestServicesBase',
  'ihestiaWidgets.life' // using beneficiary edit modal
]).run(['bodyInjectorHelper',
  function(bodyInjectorHelper) {
    bodyInjectorHelper.injectDirective('ihestiaPolicyDetailsModalsInjector', 'ihestia-policy-details-modals-injector');
  }
]);
