angular.module('salesPath2')
  .service('bonusMalusHelper', ['mainDataContainer', 'BonusMalusObjectModel', 'BonusMalusDataObjectModel', 'BonusMalusNewModel', 'CONFIG', 'RESOURCES', 'CONSTANTS', 'sp2CommonHelper', 'actionHelper', 'personHelper', '$filter', 'dataContainerHelper', 'sp2UiHelper',
    function(mainDataContainer, BonusMalusObjectModel, BonusMalusDataObjectModel, BonusMalusNewModel, CONFIG, RESOURCES, CONSTANTS, sp2CommonHelper, actionHelper, personHelper, $filter, dataContainerHelper, sp2UiHelper) {
      var BonusMalusHelper = function() {
        var self = this;

        this.riskToBm = {
          ACKOM: 'AC',
          OCKOM: 'OC'
        };

        this.tarifficationInProgress = false;

        if (CONFIG.BEHAVIOR.bonusMalusRisks) {
          this.risks = CONFIG.BEHAVIOR.bonusMalusRisks;
        }

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
            case 'personAdded':
            case 'vehicleSelected':
            case 'vehicleEdited':
            case 'vehicleAdded':
              self.refreshBonusMalusObjects(mainDataContainer);
              break;
            case 'tarifficationStarted':
              self.tarifficationInProgress = true;
              break;
            case 'tarifficationEnded':
              self.tarifficationInProgress = false;
              break;
            default:
              break;
          }
        };

        this.refreshBonusMalusObjects = function(dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          //zbieramy info gdzie powinno być BM
          var expectedBM = {};
          $.each(dataContainer.vehicles, function(vehicleId, vehicle) {
            if (typeof expectedBM[dataContainer.mainInsuredId] === 'undefined') {
              expectedBM[dataContainer.mainInsuredId] = {};
            }
            expectedBM[dataContainer.mainInsuredId][vehicleId] = true;
            $.each(vehicle.getAdditionalData('coowners'), function(k, personId) {
              if (typeof expectedBM[personId] === 'undefined') {
                expectedBM[personId] = {};
              }
              expectedBM[personId][vehicleId] = true;
            });
          });

          //usuwamy nieaktualne wpisy BM
          $.each(dataContainer.bonusMalus, function(personId, vehicles) { //eslint-disable-line consistent-return
            if (typeof expectedBM[personId] === 'undefined') //osoba nie jest już właścicielem żadnego pojazdu
            {
              delete dataContainer.bonusMalus[personId];
              return true;
            }
            $.each(vehicles, function(vehicleId) {
              if (typeof expectedBM[personId][vehicleId] === 'undefined') //osoba nie jest już właścicielem tego pojazdu
              {
                delete dataContainer.bonusMalus[personId][vehicleId];
              }
            });
          });

          //dodajemy nowe wspisy i czyścimy niaktualne dane BM jeśli nie da się ich pobrać
          $.each(expectedBM, function(personId, vehicles) {
            if (typeof dataContainer.bonusMalus[personId] === 'undefined') {
              dataContainer.bonusMalus[personId] = {};
            }
            $.each(vehicles, function(vehicleId) {
              if (typeof dataContainer.bonusMalus[personId][vehicleId] === 'undefined') {
                //wstawiamy pusty obiekt BM
                dataContainer.bonusMalus[personId][vehicleId] = self._getPlainBonusMalusObject(personId, vehicleId);
                if (self.givenDataAvailable(personId, vehicleId, dataContainer)) {
                  //sprawdzamy czy nie ma już ręcznie wpisanych danych dla tej osoby żeby je przepisać
                  self.tryFillGivenData(personId, vehicleId, dataContainer);
                }
              } else if (!self.requiredDataFilled(personId, vehicleId, dataContainer)) //obiekt BM już był i dane osoby i/lub pojazdu nie wystarczają do pobrania danych BM
              {
                //jak są dane i nie są one wpisane ręcznie to je wywalamy
                if (dataContainer.bonusMalus[personId][vehicleId].risks.AC !== null && dataContainer.bonusMalus[personId][vehicleId].risks.AC.data.useGivenData !== true) {
                  dataContainer.bonusMalus[personId][vehicleId].risks.AC = null;
                }
                if (dataContainer.bonusMalus[personId][vehicleId].risks.OC !== null && dataContainer.bonusMalus[personId][vehicleId].risks.OC.data.useGivenData !== true) {
                  dataContainer.bonusMalus[personId][vehicleId].risks.OC = null;
                }
              }
            });
          });
        };

        /**
         * zwraca pusty obiekt BM tworzony pod powiązaniu właściciela/współwłaściciela z pojazdem
         * @param  {int|string} personId
         * @param  {int|string} vehicleId
         * @return {BonusMalusObjectModel}
         */
        this._getPlainBonusMalusObject = function(personId, vehicleId) {
          var bonusMalusObject = new BonusMalusObjectModel();
          bonusMalusObject.personId = personId;
          bonusMalusObject.vehicleId = vehicleId;
          return bonusMalusObject;
        };

        /**
         * czy mozna ręcznie wpisywać dane
         * @param  {string|int} personId
         * @param  {string|int} vehicleId
         * @return {bool}
         */
        this.givenDataAvailable = function(personId, vehicleId, dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          //jeśli błąd połączenia z ufg
          return self.ufgConnectionProblem(personId, vehicleId, dataContainer);
        };

        /**
         * czy są dane potrzebne do pobrania BM dla podanych osoby i pojazdu
         * @param  {int|string} personId
         * @param  {int|string} vehicleId
         * @return {bool}
         */
        this.requiredDataFilled = function(personId, vehicleId, dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          return !(dataContainer.persons[personId].get('firstName') === '' || dataContainer.persons[personId].get('firstName') === null ||
            dataContainer.persons[personId].get('lastName') === '' || dataContainer.persons[personId].get('lastName') === null ||
            dataContainer.persons[personId].get('pesel') === '' || dataContainer.persons[personId].get('pesel') === null ||
            dataContainer.vehicles[vehicleId].get('registration') === '' || dataContainer.vehicles[vehicleId].get('registration') === null);
        };

        /**
         * sprawdzanie flagi ufgConnectionProblem
         * @param  {string|int} personId
         * @param  {string|int} vehicleId
         * @return {bool}
         */
        this.ufgConnectionProblem = function(personId, vehicleId, dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          var bonusMalus = dataContainer.bonusMalus[personId][vehicleId];
          var ufgConnectionProblem = false;
          $.each(self.risks, function(index, riskName) { //eslint-disable-line consistent-return
            if (bonusMalus.risks[riskName] !== null && bonusMalus.risks[riskName].data.newModeData !== null && bonusMalus.risks[riskName].data.newModeData.ufgConnectionProblem) {
              ufgConnectionProblem = true;
              return false;
            }
          });
          return ufgConnectionProblem;
        };

        /**
         * szuka dla danej osoby ręcznie wypełnionych danych które można by przepisać do tego obiektu
         * @param  {string|int} personId
         * @param  {string|int} vehicleId
         */
        this.tryFillGivenData = function(personId, vehicleId, dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          $.each(dataContainer.bonusMalus[personId], function(otherVehicleId) { //eslint-disable-line consistent-return
            if (vehicleId !== otherVehicleId && self.givenDataUsed(personId, otherVehicleId, dataContainer)) {
              self.copyNewBonusMalusData(personId, otherVehicleId, vehicleId, dataContainer);
              return false;
            }
          });
        };

        /**
         * sprawdza na wszystkich ryzykach czy jest ustawiona flaga useGivenData na true
         * @param  {string|int} personId
         * @param  {string|int} vehicleId
         * @return {bool}
         */
        this.givenDataUsed = function(personId, vehicleId, dataContainer) {
          dataContainer = dataContainer || mainDataContainer;
          var givenDataUsed = false;
          $.each(dataContainer.bonusMalus[personId][vehicleId].risks, function(riskName, bmDataObject) { //eslint-disable-line consistent-return
            if (bmDataObject !== null && bmDataObject.data.useGivenData) {
              givenDataUsed = true;
              return false;
            }
          });
          return givenDataUsed;
        };

        /**
         * kopiuje dane BM z jednego pojazdu na drugi
         * @param  {string|int} personId
         * @param  {string|int} vehicleId       pojazd z którego przepisujemy dane
         * @param  {string|int} otherVehicleId  pojazd do którego przepisujemy dane
         */
        this.copyNewBonusMalusData = function(personId, vehicleId, otherVehicleId, dataContainer) {
          if (typeof dataContainer === 'undefined') {
            dataContainer = mainDataContainer;
          }
          $.each(dataContainer.bonusMalus[personId][vehicleId].risks, function(riskName, bmDataObject) {
            if (bmDataObject !== null && bmDataObject.data.newModeData !== null) {
              if (dataContainer.bonusMalus[personId][otherVehicleId].risks[riskName] === null) {
                dataContainer.bonusMalus[personId][otherVehicleId].risks[riskName] = new BonusMalusDataObjectModel();
                dataContainer.bonusMalus[personId][otherVehicleId].risks[riskName].data.newModeData = new BonusMalusNewModel();
              }
              dataContainer.bonusMalus[personId][otherVehicleId].risks[riskName].data.newModeData = angular.fromJson(angular.toJson(bmDataObject.data.newModeData));
              dataContainer.bonusMalus[personId][otherVehicleId].risks[riskName].data.useGivenData = true;
            }
          });
          dataContainer.bonusMalus[personId][otherVehicleId].popupShowed = true;
        };

        /**
         * rozprowadza ręcznie wprowadzone dane na inne obiekty tej osoby
         */
        this.distributeGivenData = function(personId, vehicleId) {
          $.each(mainDataContainer.bonusMalus[personId], function(otherVehicleId) {
            //inny pojazd i możliwe wspianie danych ręcznie
            if (otherVehicleId !== vehicleId && self.givenDataAvailable(personId, vehicleId)) {
              self.copyNewBonusMalusData(personId, vehicleId, otherVehicleId);
            }
          });
        };

        this.getAvailableRisks = function() {
          var availableRisks = {};
          $.each(self.riskToBm, function(prodCode, bmRiskCode) {
            availableRisks[bmRiskCode] = false;
          });
          $.each(RESOURCES.PRODUCTLIST, function(index, riskDef) {
            if (typeof self.riskToBm[riskDef.CODE] !== 'undefined') {
              availableRisks[self.riskToBm[riskDef.CODE]] = true;
            }
          });
          return availableRisks;
        };

        /**
         * czy spodziewamy się danych BM z usługi
         * @param  {int|string} personId
         * @param  {int|string} vehicleId
         * @return {bool}
         */
        this.dataExpected = function(personId, vehicleId) {
          if (!self.requiredDataFilled(personId, vehicleId)) //jak brak danych osoby lub pojazdu to nie
          {
            return false;
          }
          //wymagane jest też jedno z ryzyk OC, AC
          return (dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_OCKOM, vehicleId) || dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_ACKOM, vehicleId));
        };

        /**
         * sprawdzamy czy po taryfikacji dane BM się zmieniły i jeśli tak to wykonujemy odpowiednie akcje
         */
        this.checkChanges = function() {
          var showGrowl = false;
          var popupShowed = false;
          $.each(mainDataContainer.bonusMalus, function(personId, vehicles) {
            $.each(vehicles, function(vehicleId, bonusMalus) {
              var dataDownloaded = false;
              if ((bonusMalus.risks.AC !== null && bonusMalus.risks.AC.data.useGivenData === false) ||
                (bonusMalus.risks.OC !== null && bonusMalus.risks.OC.data.useGivenData === false)) {
                dataDownloaded = true;
              }
              if (self._checkNoUfgData(bonusMalus)) //brak historii UFG
              {
                sp2CommonHelper.hideActivePopups();
                self.showVerifyInfoPopup(personId, vehicleId);
                popupShowed = true;
              }

              //spodziewaliśmy się nowych danych BM i są dane BM
              if (bonusMalus.dataWillChange === true && dataDownloaded) {
                //nowe dane BM pobrane, sprawdzamy czy wyświetlić popup
                if (!popupShowed) {
                  if (bonusMalus.popupShowed === false && personHelper.drivingLicenseFilled(personId)) {
                    sp2CommonHelper.hideActivePopups();
                    actionHelper.runAction('verifyBonusMalusInfo', {
                      personId: personId,
                      vehicleId: vehicleId
                    });
                    popupShowed = true;
                  } else {
                    showGrowl = true;
                  }
                }
                bonusMalus.dataWillChange = false;
              }
            });
          });
          if (!popupShowed) //sprawdzamy czy nie było zapisu pojazdu i nie trzeba wtedy wyświetlić popupu
          {
            $.each(mainDataContainer.bonusMalus, function(personId, vehicles) { //eslint-disable-line consistent-return
              $.each(vehicles, function(vehicleId, bonusMalus) { //eslint-disable-line consistent-return
                if (bonusMalus.vehicleSaved && ((bonusMalus.risks.AC !== null && bonusMalus.risks.AC.data.useGivenData === false) ||
                  (bonusMalus.risks.OC !== null && bonusMalus.risks.OC.data.useGivenData === false))) {
                  sp2CommonHelper.hideActivePopups();
                  actionHelper.runAction('verifyBonusMalusInfo', {
                    personId: personId,
                    vehicleId: vehicleId
                  });
                  popupShowed = true;
                  return false;
                }
              });
              if (popupShowed) {
                return false;
              }
            });
          }

          self.setToAll('vehicleSaved', false);

          if (!popupShowed && showGrowl) {
            sp2UiHelper.showAlert({
              content: $filter('translate')('bonusMalusDataDownloaded', {componentCode: 'sp2'}),
              type: 'info'
            });
          }
        };

        /**
         * Ustawiamy zmienną dla wszystkich obiektów bonusmalus
         * @param {string} name  [description]
         * @param {mixed} value [description]
         */
        this.setToAll = function(name, value) {
          $.each(mainDataContainer.bonusMalus, function(personId, vehicles) {
            $.each(vehicles, function(vehicleId, bonusMalus) {
              bonusMalus[name] = value;
            });
          });
        };

        /**
         * Okno pytania o weryfikację danych do ufg
         * @param  {int} personId  [description]
         * @param  {int} vehicleId [description]
         * @return {int}           [description]
         */
        this.showVerifyInfoPopup = function(personId, vehicleId) {
          sp2CommonHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            title: ['modal.noUfgData.title', {componentCode: 'sp2'}],
            text: ['modal.noUfgData.text', {componentCode: 'sp2'}],
            okBtnName: ['modal.noUfgData.verifyData', {componentCode: 'sp2'}], //weryfikuj dane
            cancelBtnName: ['Public.yes', {componentCode: 'Public'}],
            keyboard: false,
            okBtnCallback: function() {
              actionHelper.runAction('verifyBonusMalusInfo', {
                personId: personId,
                vehicleId: vehicleId
              });

            },
            cancelBtnCallback: function() {
              mainDataContainer.bonusMalus[personId][vehicleId].clientDataVerified = true;
              self.showPopupForPerson(personId);
            }
          });
        };

        /**
         * Pokazuje popup dla osoby i dowolnego pojazdu
         * @param  {string|int} personId
         */
        this.showPopupForPerson = function(personId) {
          var personVehicleId = null;
          $.each(mainDataContainer.vehicles, function(vehicleId, vehicle) { //eslint-disable-line consistent-return

            var coownerIndex = vehicle.getAdditionalData('coowners').indexOf(personId);
            if (personId === mainDataContainer.mainInsuredId || coownerIndex !== -1) {
              personVehicleId = vehicleId;
              return false;
            }
          });
          if (personVehicleId === null) {
            sp2CommonHelper.throwException('Tried to show bonus malus popup for person with no vehicles. personId: '.personId);
          }
          actionHelper.runAction('verifyBonusMalusInfo', {
            personId: personId,
            vehicleId: personVehicleId
          });

        };

        /**
         * sprawdzamy czy nie trzeba otworzyć popupa z weryfikacją danych
         * @param  {XPegaz.BonusMalus} bonusMalus
         * @return {bool}
         */
        this._checkNoUfgData = function(bonusMalus) {
          if (!bonusMalus.clientDataVerified && (self._noUfgFlag(bonusMalus, 'AC') || self._noUfgFlag(bonusMalus, 'OC'))) {
            return true;
          }
          return false;
        };

        /**
         * sprawdzana flaga noUfgData dla danego ryzyka
         * @param  {XPegaz.BonusMalus} bonusMalus
         * @param  {string} riskName
         * @return {bool}
         */
        this._noUfgFlag = function(bonusMalus, riskName) {
          return (bonusMalus.risks[riskName] !== null && bonusMalus.risks[riskName].data.newModeData !== null && bonusMalus.risks[riskName].data.newModeData.noUfgData);
        };

      };

      return new BonusMalusHelper();
    }
  ]).run(['bonusMalusHelper', 'actionHelper',
  function(bonusMalusHelper, actionHelper) {
    actionHelper.registerHelper('bonusMalusHelper', bonusMalusHelper);
  }
]);