angular.module('salesPath2')
  .config(['$stateProvider',
    function myAppConfig($stateProvider) {
      $stateProvider
        .state('clientAdd', {
          url: '/clientAdd',
          views: {
            main: {
              templateUrl: 'salesPath2Template/pages/clientAdd/clientAdd.tpl.html',
              controller: 'clientAddCtrl'
            }
          }

        });
    }
  ]);