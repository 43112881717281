/**
 * helper dla uprawnień
 */
angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaPermissionHelper', ['ihestiaAgHelper', 'ihestiaSsoBaseInfoHelper', 'ihestiaPzaCommonHelper', 'ihestiaPzaAppConstants',
   function (ihestiaAgHelper, ihestiaSsoBaseInfoHelper, ihestiaPzaCommonHelper, ihestiaPzaAppConstants) {

      /**
       * Zwracany serwis
       */
      var PermissionHelper = function () {
        var isWorker = ihestiaPzaCommonHelper.getUserRole() === ihestiaPzaAppConstants.USER_TYPE_WORKER;
        var isCentral = ihestiaPzaCommonHelper.getUserRole() === ihestiaPzaAppConstants.USER_TYPE_CENTRAL;
        this.permission = {
          programManagement: !isWorker,
          conditionCatalog: isCentral,
          conditionEdit: isCentral,
          programPreview: isCentral,
          programEdit: isCentral
        };


        this.permission.agencyOwner = ihestiaAgHelper.hasAgencyOwnerRights();

      };

      return new PermissionHelper();
    }
  ]);
