angular.module('ihestiaWidgets.dashboard')
  .provider('ihestiaDashboardModalsInjectorConfig', [function() {

    var modalsInjectorConfig = this;

    /**
     * [dashboardNumericSettings description]
     * @type {Object}
     */
    this.dashboardNumericSettings = {
      stringCodes: {
        allowLeadingZeros: true,
        parseToString: true,
        formatting: false,
        groupSeparator: '',
        decimals: 0,
        min: 0,
        maxInputLength: 20
      },
      policyNumber: {
        decimals: 0,
        min: 0,
        allowLeadingZeros: true
      }
    };


    /**
     * EUA - sprawdzenie, czy są jakieś umowy agencyjne
     * @type {Object}
     */
    this.ihestiaDashboardEuaCheckerModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['taskOfAgencyAgreement', {
        componentCode: 'Dashboard'
      }],
      size: 'md',
      okBtnName: ['details', {
        componentCode: 'Dashboard'
      }],
      cancelBtnName: ['remindMeLater', {
        componentCode: 'Dashboard'
      }]
    };


    /**
     * Wybór tematu/podtematu/segmentu
     * @type {Object}
     */
    this.newTaskSubjectModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['newTask', {
        componentCode: 'Dashboard'
      }],
      size: 'sm',
      okBtnName: ['confirm', {
        componentCode: 'Public'
      }],
      keyboard: false
    };

    /**
     * Wybór operatora
     * @type {Object}
     */
    this.ihestiaDashboardNewTaskReceiverModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['newTask', {
        componentCode: 'Dashboard'
      }],
      size: 'md',
      okBtnName: ['confirm', {
        componentCode: 'Public'
      }],
      keyboard: false
    };

    /**
     * Treść zadania
     * @type {Object}
     */
    this.newTaskContentModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['newTask', {
        componentCode: 'Dashboard'
      }],
      size: 'lg',
      okBtnName: ['confirm', {
        componentCode: 'Public'
      }],
      replaceModalBody: true,
      keyboard: false
    };

    /**
     * Wyszukiwanie klienta
     * @type {Object}
     */
    this.newTaskClientSearchModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['newTask', {
        componentCode: 'Dashboard'
      }],
      size: 'sm',
      okBtnName: ['otherClient', {
        componentCode: 'Dashboard'
      }],
      replaceModalBody: true,
      okBtnDisabled: true,
      keyboard: false
    };

    /**
     * Edycja klienta
     * @type {Object}
     */
    this.ihestiaDashboardNewTaskClientEditModalSettings = {
      treatLabelAsI18nCode: true,
      title: ['newTask', {
        componentCode: 'Dashboard'
      }],
      size: 'lg',
      okBtnName: ['save', {
        componentCode: 'Public'
      }],
      replaceModalBody: true,
      keyboard: false
    };

    /**
     * [description]
     * @return {[type]} [description]
     */
    this.$get = [function(){
      return modalsInjectorConfig;
    }];

  }]);