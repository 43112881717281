angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesAttributeModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesAttributeModelV1 = function() {
        this.objectName = 'ApplicationAllowedChangesAttribute';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          name: {
            type: 'string'
          },
          allowedValues: {
            type: 'array',
            elementsType: 'mixed'
          },
          minValue: {
            type: 'int'
          },
          maxValue: {
            type: 'int'
          }
        };

        this.name = null; // nazwa atrybutu
        this.allowedValues = []; // tablica dopuszczalnych wartości dla atrybutu
        this.minValue = null; // wartosc minimalna dla atrybutu
        this.maxValue = null; // wartosc minimalna dla atrybutu
      };

      LsnApplicationAllowedChangesAttributeModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesAttributeModelV1;
    }
  ]);