angular.module('salesPath2')
  .factory('AgentClausesModalHelperClass', ['sp2CommonHelper', '$filter', function(sp2CommonHelper, $filter) {
    var AgentClausesModalHelperClass = function() {
      var self = this;

      this.tplData = null;

      this.modalSettings = null;

      this.tarrificationInProgress = false;

      //config for agent clauses
      this.agentClauses = [{
        code: 'k31b',
        text: $filter('translate')('sp2.modal.agentClausesModal.k31b', {componentCode: 'sp2'}),
        isSelected: false
      }, {
        code: 'k32b',
        text: $filter('translate')('sp2.modal.agentClausesModal.k32b', {componentCode: 'sp2'}),
        isSelected: false
      }, {
        code: 'k33b',
        text: $filter('translate')('sp2.modal.agentClausesModal.k33b', {componentCode: 'sp2'}),
        isSelected: false
      }];

      this.init = function() {
        self.tplData = {
          clauses: self.agentClauses
        };
      };


      /**
       * reakcja na akcje w aplikacji
       * @param  {String} actionName nazwa wykonanej akcji
       */
      this._afterAction = function(actionName) {
        switch (actionName) {
          case 'policiesReceived':
          if (angular.isFunction(self.modalSettings.applyCallback)) {
            self.modalSettings.applyCallback();
          }
          self.tarrificationInProgress = false;
          sp2CommonHelper.hideModal('agentClausesModal');
            break;
          default:
            break;
        }
      };

    };

    return AgentClausesModalHelperClass;
  }]);