angular.module('lsnBase.models')
  .factory('LsnInsuredObjectModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {

      var LsnInsuredObjectModelV1 = function()
      {
        this.objectName = 'InsuredObject';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          ref:
          {
            type: 'string'
          },
          typeId:
          {
            type: 'string'
          },
          groupSymbol:
          {
            type: 'string'
          }
        };

        this.ref = null; // string, Referencje do obiektów lub osób na polisie. Dla pierwszego rodzaju MetaData.Id z vehicle lub estates, dla drugiego natomiast MetaData.Id z listy persons lub organizations
        this.typeId = null; // string, Identyfikator podmiotu typu obiektu w  BOS.PTY
        this.groupSymbol = null;
      };

      LsnInsuredObjectModelV1.prototype = LsnAbstractModel.prototype;

      return LsnInsuredObjectModelV1;
    }
  ]);