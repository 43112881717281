angular.module('ihestiaCommonDirectives')
  .service('ihestiaModuleHelper',
    function() {
      var ModuleHelper = function() {
        var self = this;

        this.checkedModules = {}; //sprawdzone moduły odpowiednio :
                                // {szukany moduł : {
                                //   sprawdzony moduł rodzica: czy wymagany
                                // }
                                // przykład:
                                // {communication: {App: false, salesPath: true}}

        /**
         * Metoda do sprawdzania obecności modułu
         */
        this.isModule = function(baseModuleName, requiredModuleName) {
          return self.isModuleRequiringModule(baseModuleName, requiredModuleName);
        };

        /**
         * Rekurencyjna funkcja do sprawdzania, czy w module jest zależność od innego modułu
         * @param  {object}  module             sprawdzany moduł
         * @param  {strinig}  requiredModuleName nazwa poszukiwanego modułu
         * @return {Boolean}                    czy moduł jest w którymkolwiek z modułow jako dependency
         */
        this.isModuleRequiringModule = function(baseModuleName, requiredModuleName) {
          if(typeof self.checkedModules[requiredModuleName] === 'undefined')
          {
            self.checkedModules[requiredModuleName] = {};
          }

          if(baseModuleName === requiredModuleName) //szukany moduł to modul od którego zaczynamy sprawdzanie
          {
            self.checkedModules[requiredModuleName][baseModuleName] = true;
            return true; //lepiej się zabezpieczyć ;)
          }

          var modulesToCheck = [];

          var requires = self._checkAddSubmodules(requiredModuleName, baseModuleName, modulesToCheck);

          var iteration = 0;
          var maxIterations = 1000;

          while (!requires && modulesToCheck.length > 0 && iteration < maxIterations)
          {
            var checkedModuleName = modulesToCheck.shift();
            if(checkedModuleName === requiredModuleName)
            {
              requires = true;
              self.checkedModules[requiredModuleName][checkedModuleName] = true;
            }
            else
            {
              requires = self._checkAddSubmodules(requiredModuleName, checkedModuleName, modulesToCheck);
            }
          }
          if(iteration >= maxIterations)
          {
            throw new Error('isModuleRequiringModule check reached maxIterations.');
          }

          return requires;
        };

        /**
         * odkładamy w modulesToCheck do sprawdzania wszystkie podmoduly checkedModuleName
         * pomijamy moduły już sprawdzone i te już odłożone do sprawdzenia
         */
        this._checkAddSubmodules = function(requiredModuleName, checkedModuleName, modulesToCheck)
        {
          var requires = false;
          angular.forEach(angular.module(checkedModuleName).requires, function(subModuleName){
            if(subModuleName === requiredModuleName)
            {
              self.checkedModules[requiredModuleName][checkedModuleName] = true;
              requires = true;
            }
            else if(self.checkedModules[requiredModuleName][subModuleName] === true)
            {
              //jeśli moduł już wcześniej był sprawdzany (wcześniejsze wywołanie isModuleRequiringModule)
              requires = true;
            }
            else if(typeof self.checkedModules[requiredModuleName][subModuleName] === 'undefined' &&
              modulesToCheck.indexOf(subModuleName) === -1)
            {
              modulesToCheck.push(subModuleName); //odkładamy modul do sprawdzenia
            }
          });
          if(!requires)
          {
            self.checkedModules[requiredModuleName][checkedModuleName] = false;
          }

          return requires;
        };

      };

      return new ModuleHelper();
    }
  );