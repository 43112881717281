angular.module('ihestiaSsoBase')
.factory('UserRequestModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var UserRequestModel = function()
    {
      this.objectName = 'UserRequest';

      this.fieldsProperties = {
        login: {
          type: 'string'
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        roleCode: {
          type: 'string'
        },
        privilegeCode: {
          type: 'string'
        },
        salesProfile: {
          type: 'string'
        },
        characterCode: {
          type: 'string'
        },
        columns: {
          type: 'string'
        }
      };

      this.login = null; // String  Filtr loginu użytkownika.   Nie
      this.firstName = null; // String  Filtr imienia użytkownika Nie
      this.lastName = null; //  String  Filtr nazwiska użytkownika. Nie
      this.email = null; // String  Filtr emailu użytkownika  Nie
      this.pesel = null; // String  Filtr peselu użytkownika  Nie
      this.roleCode = null; //  String  Filtr  roli użytkownika Nie
      this.privilegeCode = null; // String  Filtr uprawnienia użytkownika.  Nie
      this.salesProfile = null; //  String  Filtr agencji użytkownika.  Nie
      this.characterCode = null; // String  Filtr kodu tożsamości Nie
      this.columns = null; // String  Filtr mówiący o zwracanych danych.
          // Mamy 3 zbiory kolumn:
          // 1.Login,FirstName,LastName,Email,Pesel,IsBlocked,
          // 2.CharacterName,SalesProfile
          // 3.RoleCode,PrivilageCode

          // Po podaniu jednej z kolumn zaczytają się wszystkie kolumny z jej grupy.
          // UWAGA!!! Nie można wstawiać spacji między kolumnami! Separatorem musi być przecinek!
          // Przykład: „Login,FirstName,CharacterName”.

          // UWAGA!!! Podanie samego filtru dla loginu bez podania kolumny „Login” też zwróci dane z pierwszej grupy.  Nie

    };

    UserRequestModel.prototype = IHestiaAbstractModel.prototype;

    return UserRequestModel;
  }]);