/**
 * Mapper podmiotów (osoby, firmy)
 */
angular.module('salesPath2').service('toRestVehicleMapper', ['MapperUtils', 'VehicleModel', 'lsnDeepExtend', 'CONFIG',
  function(MapperUtils, VehicleModel, lsnDeepExtend, CONFIG) {
    var ToRestVehicleMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.mapVehicles = function(vehicles) {
        var vehiclesArray = [];
        angular.forEach(vehicles, function(vehicle) {
          vehiclesArray.push(self._mapVehicle(vehicle));
        });
        return vehiclesArray;
      };

      /**
       * mapuje pojazd
       * @param {Vehicle} dcVehicle pojazd z dataContainera
       * @return {Vehicle} pojazd do wniosku/polisy
       */
      this._mapVehicle = function(dcVehicle) {
        var vehicle = new VehicleModel(),
          addData = dcVehicle.getAdditionalData(),
          vehData = dcVehicle.getData(false, true),
          isLeased = (typeof addData.isLeased === 'boolean') ? addData.isLeased : null;

        var data = {
          dynamicValues:
            { //nowe dynamicValues - nie ujete w vehicle
              '_leased': isLeased,
              '_vehicleInEtx': dcVehicle.getAdditionalData('source') === 'nonEtx' ? false : true,
              '_vehicleCoownersIds': MapperUtils.getEstateOrVehicleCoownersIds(addData)
            }
        };
        if (addData !== null) {
          data.dynamicValues._additionalData = self.utils.serializeValue(addData);
        }
        if (CONFIG.BEHAVIOR.vehicle.rightHandDrive) {
          data.dynamicValues.rightSideSteeringWheel = addData.rightHandDrive;
        }
        data = lsnDeepExtend(vehData, data);
        //myk na obejście walidacji przebiegu (IHESTIAMF-1973)
        if (CONFIG.BEHAVIOR.vehicle.mileage === false && !parseInt(data.mileage, 10)) {
          data.mileage = '1';
        }
        vehicle.setData(data);

        return vehicle;
      };


    };

    return new ToRestVehicleMapper();
  }
]);