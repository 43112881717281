angular.module('lsnBase')
  .directive('lsnHistoryBack', ['$window', function($window) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        var onClick = function(){
          $window.history.back();
        };

        element.on('click', onClick);

        scope.$on('$destroy', function(){
          element.off('click', onClick);
        });

      }
    };
  }]);