angular.module('lsnBase.models')
  .factory('LsnBonusMalusModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {

      var LsnBonusMalusModelV1 = function()
      {
        this.objectName = 'BonusMalus';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          data: {
            type: 'object'
          },
          personRef: {
            type: 'string'
          },
          riskName: {
            type: 'string'
          },
          riskRef: {
            type: 'string'
          },
          vehicleRef: {
            type: 'string'
          }
        };

        this.data = null; //
        this.personRef = null; // string, id osoby
        this.riskName = null; // string, AC / OC
        this.riskRef = null; // referencja do ryzyka
        this.vehicleRef = null; // string, id pojazdu

        this.setDefaultAdditionalData();
      };

      LsnBonusMalusModelV1.prototype = LsnAbstractModel.prototype;

      return LsnBonusMalusModelV1;
    }
  ]);