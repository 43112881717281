angular.module('salesPath2')
  .controller('editLeasingModalCtrl', ['$scope', 'leasingModalHelper', 'AddressModel', 'CONSTANTS', 'OrganizationModel', 'dictionaryServiceHelper', 'actionHelper', 'addressHelper', 'appVariables', 'dataContainerHelper',
    function($scope, leasingModalHelper, AddressModel, CONSTANTS, OrganizationModel, dictionaryServiceHelper, actionHelper, addressHelper, appVariables, dataContainerHelper) {

      $scope.modalData = leasingModalHelper.editLeasingModalData;
      $scope.observers = [];

      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };

      $scope.services = {
        addressSvc: dictionaryServiceHelper.getDictionaryAddressService()
      };

      /**
       * Init
       */
      $scope.init = function() {
        if ($scope.modalData.dataCleared) {
          // obiekt, do którego jest/ma być przypisany leasing
          var leaserId = $scope.modalData.leaserId;
          if (leaserId) {
            $scope.modalData.organization = $scope.dataContainer.organizations[leaserId];
            if (angular.isUndefined($scope.modalData.organization.addresses[0])) {
              $scope.modalData.organization.set('addresses', addressHelper.getAdrStal());
            }
          } else {
            var address = addressHelper.getAdrStal();

            $scope.modalData.organization = new OrganizationModel();
            $scope.modalData.organization.set('countryCode', $scope.CONFIG.BEHAVIOR.defaultLeaserCountry);
            $scope.modalData.organization.set('addresses', [address]);
            $scope.modalData.organization.set('dynamicValues', {
              type: CONSTANTS.ORGANIZATION_TYPE_LEASING
            });
          }

          $scope.modalData.organizationData = $scope.modalData.organization.getData();
          $scope.loadDictionaries();
        }
        $scope.fireListeners();
      };

      /**
       * Wciągamy niezbędne słowniki
       */
      $scope.loadDictionaries = function() {
        // branże
        dictionaryServiceHelper.getDictionary('industries', {
          requestType: 'searches',
          callback: function(industries) {
            $scope.modalData.industries = industries;
          }
        });
        // formy prawny
        dictionaryServiceHelper.getDictionary('legalForms', {
          requestType: 'searches',
          callback: function(legalForms) {
            $scope.modalData.legalForms = legalForms;
          }
        });
        // kraje
        dictionaryServiceHelper.getDictionary('countries', {
          requestType: 'searches',
          callback: function(countries) {
            $scope.modalData.countries = countries;
          }
        });
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // zapisz leasingu
        $scope.observers.push($scope.$on('iHestiaCommonModalOkBtnClicked.editLeasingModal', function() {
          $scope.saveLeasing();
        }));
        // anuluj na leasingu
        $scope.observers.push($scope.$on('iHestiaCommonModalCancelBtnClicked.editLeasingModal', function() {
          leasingModalHelper.clearEditLeasingModalData();
        }));
      };

      /**
       * Zapisujemy leasing
       */
      $scope.saveLeasing = function() {
        //wedlug analizy nie ma mozliwosci edycji w dokupieniach
        if(appVariables.isSupplement){
          return;
        }

        // ustalanie clientId
        var organizationId = $scope.modalData.organizationData.metaData.clientId,
          isNewLeasing = false;
        if (!organizationId) {
          isNewLeasing = true;
          organizationId = dataContainerHelper.getNextOrganizationId();
        }
        $scope.modalData.organizationData.metaData.clientId = organizationId;

        // zapisujemy organizację
        $scope.modalData.organization.setData($scope.modalData.organizationData);
        $scope.dataContainer.organizations[organizationId] = $scope.modalData.organization;

        if (isNewLeasing && $scope.modalData.objectType && $scope.modalData.objectId) {
          leasingModalHelper.selectLeaser(organizationId, {
            objectType: $scope.modalData.objectType,
            objectId: $scope.modalData.objectId
          });
        } else {
          actionHelper.runAction('leaserSaved');
        }
        actionHelper.runAction('saveApplication');
        leasingModalHelper.clearEditLeasingModalData();
      };

      $scope.init();

    }
  ]);