angular.module('lsnBase.object')
  .service('lsnObjectHelper', ['lsnDeepExtend', function(lsnDeepExtend) {
    var LsnObjectHelper = function() {
      var self = this;

      /**
       * czyści obiekt
       * UWAGA można rozwinąć o dodatkowe parametry czyszczenia
       * @param  {Object} obj
       * @return {Boolean} true gdy powodzenie
       */
      this.clearObject = function(obj) {
        if (!angular.isObject(obj)) {
          return false;
        }
        angular.forEach(obj, function(val, key) {
          delete obj[key];
        });
        return true;
      };

      /**
       * zastępuje obiekt toObj obiektem fromObj zachowując referencję do toObj
       * @param  {Object} toObj obiekt rozszerzany
       * @param  {Object} fromObj obiekt, który będzie rozszerzał toObj
       * @return {Boolean} false gdy się nie powiodło
       */
      this.replaceObject = function(toObj, fromObj) {
        if (self.clearObject(toObj)) {
          lsnDeepExtend(toObj, fromObj);
          return true;
        }
        return false;
      };

    };
    return new LsnObjectHelper();
  }]);