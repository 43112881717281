angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonAbstractComponentStatusSvc', ['ihestiaCommonAbstractComponentManagementSvc',
    function(ihestiaCommonAbstractComponentManagementSvc) {

      var AbstractComponentStatus = function() {
        ihestiaCommonAbstractComponentManagementSvc.apply(this, arguments);

        this.businessArea = 'component-status';

      };
      return AbstractComponentStatus;
    }
  ]);