
angular.module('ihestiaIkomunikator')
  .directive('ihestiaIkomunikatorDetailsMessageThread', ['$templateCache',
    function($templateCache) {
      var tpl = $templateCache.get('ihestiaIkomunikatorTemplatesApp/views/common/detailsMessageThread/detailsMessageThread.tpl.html');
      return {
        template: tpl,
        controller: 'ihestiaIkomunikatorDetailsMessageThreadCtrl'
      };
    }
  ]);