angular.module('salesPath2')
  .service('productsClausesAutomaticUpdateHelper', ['sp2CommonHelper',
    function(sp2CommonHelper) {
      var ProductsClausesAutomaticUpdateHelper = function() {

        //ustawienia modali
        this.modals = {};

        this.modals.productsClausesAutomaticUpdateModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.summary.clausesChangesTitle',
            size: 'md',
            okBtnName: 'sp2.modal.okBtnName.ok',
            cancelBtnName: false
          }
        };

        /**
         * Wyświetlenie modala
         */
        this.showModal = function() {
          sp2CommonHelper.showModal('productsClausesAutomaticUpdateModal');
        };
      };

      return new ProductsClausesAutomaticUpdateHelper();
    }
  ]);
