var LsnNgFunctions = function() {
  /**
   * forEach z obługą przerwań
   * return false - przerywa pętlę
   * @param  {Object|Array}   obj obiekt lub tablica
   * @param  {Function} iterator function(val, key)
   * @param  {Object} context obiekt, ktory bedzie kontekstem, domyslnie this
   * @return {Object|Array} obj
   */
  this.forEach = function(obj, iterator, context) {
    var iterate = true;
    angular.forEach(obj, function(val, key) {
      if (iterate && iterator(val, key) === false) {
        iterate = false;
      }
    }, context);
    return obj;
  };

  /**
   * angular.noop nic nie zwraca, co rodzi czasem
   * problemy, np. w promise'ach
   * @param  {mixed} input 
   * @return {mixed} input bez żadnych modyfikacji
   */
  this.noop = function(input){
    return input;
  };

  /**
   * @deprecated
   * @param url
   * @param target
   */
  this.openWindow = function(url, target) {
    // deprecated
  };

};

/* eslint-disable no-undef */
document.addEventListener('DOMContentLoaded', function() {
  window.lsnNg = new LsnNgFunctions();
});

