angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaConditionSvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaConditionSvc = function() {
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'condition';
      };

      return new PzaConditionSvc();
    }]);