angular.module('salesPath2')
  .controller('vehicleEtxEditModalCtrl', ['$scope', 'vehicleHelper', 'vehicleServiceHelper', 'vehicleModalHelper', 'actionHelper', 'sp2CommonHelper', 'CONFIG',
    function($scope, vehicleHelper, vehicleServiceHelper, vehicleModalHelper, actionHelper, sp2CommonHelper, CONFIG) {
      $scope.modalData = vehicleHelper.currentlyEditedVehicleData;
      $scope.vehicleFieldsBlackList = {};
      $scope.context = 'etx';
      $scope.runValuation = false;

      $scope.init = function() {
        $scope.setInitialData();
        $scope.fireListeners();
      };

      /**
       * Ustawiamy inicjalne wartości na pojedźie
       */
      $scope.setInitialData = function() {
        if ($scope.modalData.dataCleared) {
          $scope.modalData.dataCleared = false;
          $scope.modalData.humanVehicleData = $scope.modalData.vehicle.getData();
          $scope.modalData.vehicleData = $scope.modalData.vehicle.getData(false, true);
          if ($scope.modalData.vehicleData.registration === null) {
            $scope.modalData.vehicleData.registration = [''];
          }
          $scope.modalData.vehicleAdditionalData = angular.copy($scope.modalData.vehicle.getAdditionalData());
          $scope.modalData.vehicleDynamicValues = $scope.modalData.vehicle.get('dynamicValues');
          $scope.setEquipmentCount($scope.modalData.vehicleData.equipments);
          $scope.runValuation = true;
        } else if ($scope.modalData.equipmentchanged) {
          $scope.modalData.equipmentchanged = false;
          $scope.runValuation = true;
          $scope.modalData.vehicleData.equipments = $scope.modalData.vehicle.equipments;
        }
      };

      /**
       * zliczenie wyposażenia
       * @return Boolean true, gdy pobrano i zliczono wyposazenie, false gdy brak danych o wyposazeniu pobranych ze slownikow ETX
       */
      $scope.setEquipmentCount = function(equipments) {
        if (!CONFIG.BEHAVIOR.vehicle.vehicleEqipment) {
          return;
        }
        $scope.modalData.equipmentCountStd = 0;
        $scope.modalData.equipmentCountDealers = 0;
        $scope.modalData.equipmentCountAdditional = 0;
        if (!angular.isArray($scope.modalData.equipment)) { //nie pobrano wyposażenia
          return false; //eslint-disable-line consistent-return
        }
        lsnNg.forEach($scope.modalData.equipment, function(etxEq) {
          lsnNg.forEach(equipments, function(eq) {
            if ((eq[1] + '') === (etxEq.id + '')) {
              if (etxEq.category === 'Standard') {
                $scope.modalData.equipmentCountStd++;
              } else if (etxEq.category === 'Dealers') {
                $scope.modalData.equipmentCountDealers++;
              } else {
                $scope.modalData.equipmentCountAdditional++;
              }
              return false; //przerywamy iteracje, bo znalezlismy element
            }
            return true;
          });
        });
        return true; //eslint-disable-line consistent-return
      };

      /**
       * Ustawiamy wybrane wyposazenie
       */
      $scope.setEquipment = function(equipment) {
        if (!CONFIG.BEHAVIOR.vehicle.vehicleEqipment) {
          return;
        }
        var equipments = [];
        $scope.modalData.vehicleData.equipments = [];
        angular.forEach(equipment, function(eq) {
          equipments.push([eq.name, eq.id, eq.category]);
        });
        $scope.modalData.vehicleData.equipments = equipments;
        $scope.setEquipmentCount(equipments);
      };

      /**
       * Odpalamy nasłuchiwanie eventow, watchy itp.
       */
      $scope.fireListeners = function() {
        $scope.$watch('modalData.vehicleData', function(newVehicleData, oldVehicleData) {
          var equipment = [];
          var specialPurpose = null;
          // wycena pojazdu
          if (!CONFIG.BEHAVIOR.vehicle.etxValuation || !angular.isArray($scope.modalData.vehicle.type) || $scope.modalData.vehicle.type.length < 2) { //przypadek pojazdu spoza etx przy wznowieniu i braku obslugi pojazdow spoza etx
            return;
          }
          var newValuationData = $scope.getEurotaxValuationData(newVehicleData),
            oldValuationData = $scope.getEurotaxValuationData(oldVehicleData),
            etxId = $scope.modalData.vehicle.type[1];
          if (newValuationData !== false && (!angular.equals(newValuationData, oldValuationData) || $scope.runValuation)) {
            $scope.runValuation = false;
            //jeżeli jest wyposażenie dodatkowe w konfiguracji to dodajemy do wyceny
            if (CONFIG.BEHAVIOR.vehicle.vehicleEqipment) {
              angular.forEach(newVehicleData.equipments, function(eq) {
                var eqTmp = [eq[0], eq[1]];
                equipment.push(eqTmp);
              });
            }


            vehicleServiceHelper.getEurotaxValuation(etxId, newValuationData, equipment, specialPurpose, function(response) {
              $scope.setVehicleValue(response.data.value, response.data.kind);
            });

          }
        }, true);

        // anuluj
        $scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleEtxEditModal', function() {
          vehicleModalHelper.cancelEditVehicle($scope.modalData.vehicle.metaData.get('clientId'));
        });

        // ładujemy wyposażenie
        $scope.$watch('modalData.vehicleData.firstRegistration', function(firstRegistration) {
          if (firstRegistration) {
            if (!CONFIG.BEHAVIOR.vehicle.vehicleEqipment) {
              return;
            }
            $scope.loadEquipment();
          }
        });
      };

      /**
       * Ładowanie wyposażenia
       */
      $scope.loadEquipment = function() { //eslint-disable-line consistent-return
        if (!angular.isArray($scope.modalData.vehicle.type) || angular.isUndefined($scope.modalData.vehicle.type[1])) {
          return false;
        }
        vehicleHelper.getVehicleEquipment($scope.modalData.vehicleData.firstRegistration, $scope.modalData.vehicle.type[1], function(equipment) {
          if ($scope.modalData.vehicle.equipments === null) {
            // dla nowego pojazdu zaznaczamy tylko standardowe
            var stdEquipment = [];
            angular.forEach(equipment, function(eq) {
              if (eq.category === 'Standard') {
                stdEquipment.push(eq);
              }
            });
            $scope.setEquipment(stdEquipment);
          }
          $scope.modalData.equipment = equipment;
          $scope.setEquipmentCount($scope.modalData.vehicleData.equipments);
        });
      };

      /**
       * Wybieramy wyposazenie dodatkowe
       */
      $scope.chooseVehicleEquipment = function() {
        vehicleModalHelper.chooseVehicleEquipment($scope.modalData.vehicle, $scope.modalData.vehicleData.equipments, $scope.modalData.equipment);
      };


      /**
       * Zwraca dane do pobrania wartosci pojazdu
       * @param {object} vehicleData dane z vehicle.getData()
       * @return {object|false} [description]
       */
      $scope.getEurotaxValuationData = function(vehicleData) {
        var valuationData = {
            productionYear: vehicleData.productionYear,
            run: vehicleData.mileage,
            registrationDate: vehicleData.firstRegistration
          },
          canGetValuation = true;

        angular.forEach(valuationData, function(value) {
          if (typeof value === 'undefined' || value === null) {
            canGetValuation = false;
          }
        });
        return canGetValuation ? valuationData : false;
      };

      /**
       * Wybierz inny pojazd
       */
      $scope.chooseOtherVehicle = function() {
        vehicleModalHelper.chooseOtherVehicle();
      };

      /**
       * Odpalamy nasłuchiwanie eventow, watchy itp.
       */
      $scope.setVehicleValue = function(value, kind) {
        $scope.modalData.etxData.vehicleValue = value;
        $scope.modalData.etxData.vehicleValueType = kind;
      };

      $scope.init();

    }
  ]);