angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskClientSearchModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper', 'clientPersonsSvc', 'IhestiaDashboardClientModel', '$timeout', 'iHestiaCommonModalHelper', 'IhestiaDashboardClientConstants', 'clientPersonsNbkSvc', '$rootScope', '$q', '$filter', 'ihestiaPlatformHelper', 'ihestiaDictionaryHelper', 'clientSearchSvc',
    function($scope, ihestiaDashboardNewTaskHelper, clientPersonsSvc, IhestiaDashboardClientModel, $timeout, iHestiaCommonModalHelper, IhestiaDashboardClientConstants, clientPersonsNbkSvc, $rootScope, $q, $filter, ihestiaPlatformHelper, ihestiaDictionaryHelper, clientSearchSvc) {

      $scope.SUBJECT_TYPE_PERSON = 'person';
      $scope.SUBJECT_TYPE_ORGANIZATION = 'organization';

      $scope.loaderVisible = false;
      $scope.searchResultEmpty = false;
      $scope.scrollVisible = false;

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;
      $scope.searchQuery = {
        value: ''
      };
      $scope.clients = [];

      $scope.sortParameters = {
        sortBy: 'rank',
        reverseSort: false
      };

      $scope.promiseToCancel = $q.defer();
      $scope.isLife = ihestiaDashboardNewTaskHelper.isLife();

      $scope.tplData = {
        subjectType: $scope.SUBJECT_TYPE_PERSON,
        isSubjectSelectAvailable: ihestiaDashboardNewTaskHelper.isSubjectSelectAvailable(),
        countries: null,
        organizationData: {
          nip: null,
          name: null,
          regon: null,
          countryCode: 'PL',
          companyId: null
        }
      };

      $scope.numericSettings = {
        regon: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        },
        nip: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        }
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        // IHESTLIFE-3125 - EHL has different label on confirm button
        $scope.updateOkBtn({
          name: $filter('translate')(!$scope.isLife ? 'otherClient' : 'otherInsurer', {componentCode: 'Dashboard'}),
          disabled: true
        });
        var confirmClickEvent = $scope.tplData.isSubjectSelectAvailable
          ? 'iHestiaCommonModalThirdBtnClicked.ihestiaDashboardNewTaskClientSearch'
          : 'iHestiaCommonModalOkBtnClicked.ihestiaDashboardNewTaskClientSearch';
        $scope.$on(confirmClickEvent, function($event) {
          $event.preventDefault();
          // odpalenie okna wyboru innego klienta
          iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskClientSearch');
          $timeout(function() {
            iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskClientEdit');
          }, 0);
        });

        $scope.$on('checkTopReached', function(event, data) {
          if (data.reachedTop) {
            $timeout(function() {
              $scope.updateOkBtn({
                disabled: false
              });
            }, 0);
          }
        });

        $scope.$on('onScrollShowHideOnTask', function(event, data) {
          $scope.scrollVisible = data.scrollShowed;
        });

        ihestiaDictionaryHelper.getDictWithPromise('countries', {
          requestType: 'searches'
        }).then(function(countries) {
          $scope.tplData.countries = countries;
        });

        $scope.modalData.subjectType = $scope.tplData.subjectType;
        $scope.$watch('modalData.subjectType', function(type) {
          $scope.tplData.subjectType = type;
          $scope.onSubjectTypeChange();
        });
      };

      /**
       * Wyszukiwanie klientów
       * @return {undefined}
       */
      $scope.clientSearch = function() {
        $scope.clients = [];
        if ($scope.canSearch()) {
          $scope.loaderVisible = true;
          $scope.promiseToCancel.resolve('newRequest');
          $scope.promiseToCancel = $q.defer();

          $scope.updateOkBtn({
            disabled: true
          });
          $scope.getClientSearchRequest()
            .then(function(res) {
              $scope.searchResultEmpty = false;
              if (angular.isObject(res) && res.data) {
                $scope.loaderVisible = false;
                $scope.clients = res.data.items || (Array.isArray(res.data) ? res.data : null);
                $scope.searchResultEmpty = _.isEmpty($scope.clients);

                if ($scope.clients.length === 0) { // błąd: Cannot read property 'length' of undefined
                  $scope.updateOkBtn({
                    disabled: false
                  });
                  $scope.searchResultEmpty = true;
                }

                $timeout(function() {
                  if (!$scope.scrollVisible) {
                    $scope.updateOkBtn({
                      disabled: false
                    });
                  }
                }, 0);
              }
            }, angular.noop);
        }
      };


      /**
       * Wybór klienta
       * @param  {object} client obiekt klienta
       * @return {undefined}
       */
      $scope.chooseClient = function(client) {
        var isCompany = $scope.tplData.subjectType === $scope.SUBJECT_TYPE_ORGANIZATION;
        var clientModel = new IhestiaDashboardClientModel();
        var clientParams;
        if (isCompany) {
          clientParams = {
            companyName: client.name,
            regon: client.regon,
            // IHESTIABAS-5163
            nip: client.countryCode !== 'PL' ? (client.companyId || client.nip) : client.nip,
            ikontoId: client.customerId
          };
        } else {
          clientParams = {
            pesel: client.pesel,
            name: client.firstName,
            surname: client.lastName
          };
        }
        var clientData = _.merge({
          type: isCompany ? IhestiaDashboardClientConstants.TYPE_COMPANY : IhestiaDashboardClientConstants.TYPE_PERSON
        }, clientParams);

        // telefon i email
        angular.forEach(client.contacts, function(contact) {
          if (contact.code === 'komo') {
            clientData.phoneNumber = contact.value;
          } else if (contact.code === 'mail') {
            clientData.email = contact.value;
          }
        });
        clientModel.setData(clientData);

        $scope.taskData.object.client = clientModel.getData();

        // odpalenie kolejnego okna
        iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskClientSearch');

        // sprawdzamy, czy klient posiada ikonto
        $scope.$emit('showBlockUi');
        var nbkRequest = !isCompany ? $scope.personNbkRequest(client) : $scope.companyNbkRequest(client);
        nbkRequest.finally(function() {
          $rootScope.$emit('hideBlockUi');
          ihestiaDashboardNewTaskHelper.selectedClientFromList = true;
          ihestiaDashboardNewTaskHelper.selectedClientCountryCode = isCompany ? client.countryCode : null;
          ihestiaDashboardNewTaskHelper.showNextNewTaskModal();
        });
      };

      /**
       * sprawdzamy, czy klient posiada ikonto
       * @param {object} client
       * @return {Promise<any>}
       */
      $scope.personNbkRequest = function(client) {

        return clientPersonsNbkSvc.post(client.metaData.id, {
          FirstName: client.firstName,
          LastName: client.lastName,
          Pesel: client.pesel,
          metadata: {
            masterid: client.metaData.masterId
          }
        }).then(function(res) {
          if (res && res.data) {

            if (res.data.customerId) {
              $scope.taskData.object.client.ikontoId = res.data.customerId;
            }

            //Pobieramy z najnowszych danych nbk imię, nazwisko i pesel klienta
            //zgodnie z https://ebok.atena.pl/browse/IHESTIADA-1299
            $scope.taskData.object.client.name = res.data.firstName;
            $scope.taskData.object.client.surname = res.data.lastName;
            $scope.taskData.object.client.pesel = res.data.pesel;

          }
        }, angular.noop);
      };

      /**
       *
       * @return {Promise<any>}
       */
      $scope.companyNbkRequest = function(client) {
        if ($scope.tplData.organizationData.countryCode === 'PL'
          && _.size($scope.clients) > 1
          && _.size(client.regon) === 14) {
          var params = {
            nip: $scope.tplData.organizationData.nip,
            regon: client.regon
          };
          return clientSearchSvc.searchOrganization(params)
            .then(function(res) {
              if (!_.isEmpty(res.data)) {
                var detailedClient = _.first(res.data);
                // todo assign which properties?
                $scope.taskData.object.client.nip = detailedClient.nip;
                $scope.taskData.object.client.ikontoId = detailedClient.customerId;
              }
            }, lsnNg.noop);
        } else {
          return $q.when();
        }
      };

      $scope.sortBy = function(columnCode) {
        $scope.sortParameters.sortBy = columnCode;
        $scope.sortParameters.reverseSort = !$scope.sortParameters.reverseSort;
        $scope.clientSearch();
      };

      $scope.onCountryCodeChange = function() {
        $scope.tplData.organizationData.nip = null;
        $scope.tplData.organizationData.regon = null;
        $scope.tplData.organizationData.name = null;
        $scope.tplData.organizationData.companyId = null;
      };

      $scope.onSubjectTypeChange = function() {
        if ($scope.tplData.subjectType === $scope.SUBJECT_TYPE_PERSON) {
          $scope.tplData.organizationData = {
            countryCode: 'PL'
          };
        } else {
          $scope.searchQuery.value = '';
        }
        $scope.clients = [];
      };

      /**
       *
       * @return {Promise}
       */
      $scope.getClientSearchRequest = function() {
        var sortBy = $scope.sortParameters.sortBy;
        var sortDirection = $scope.sortParameters.reverseSort ? 'DESC' : 'ASC';
        var httpParams = {
          timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
        };
        if ($scope.tplData.isSubjectSelectAvailable && $scope.tplData.subjectType === $scope.SUBJECT_TYPE_ORGANIZATION) {
          var body = _.chain($scope.tplData.organizationData)
            // .omit(['countryCode'])
            .omitBy(_.isNil)
            .value();
          return clientSearchSvc.searchOrganization(body, httpParams);
        } else {
          return clientPersonsSvc.findByParams({
            count: 10,
            skip: 0,
            top: 10,
            sortBy: sortBy,
            sortDirection: sortDirection,
            query: $scope.searchQuery,
            httpParams: httpParams
          });
        }
      };

      /**
       *
       * @return {boolean}
       */
      $scope.canSearch = function() {
        if ($scope.tplData.isSubjectSelectAvailable && $scope.tplData.subjectType === $scope.SUBJECT_TYPE_ORGANIZATION) {
          return true;
        } else {
          return $scope.searchQuery.value.length >= 3;
        }
      };


      /**
       * IHESTIABAS-5163 - subject select available - confirm btn is thirdBtn
       * @param {{disabled: boolean, name: string}} [params]
       */
      $scope.updateOkBtn = function(params) {
        var disabled = _.get(params, 'disabled');
        var name = _.get(params, 'name');
        if ($scope.tplData.isSubjectSelectAvailable) {
          if (typeof name !== 'undefined') {
            $scope.newTaskClientSearchModalSettings.thirdBtnName = name;
          }
          if (typeof disabled !== 'undefined') {
            $scope.newTaskClientSearchModalSettings.thirdBtnDisabled = disabled;
          }
          if ($scope.newTaskClientSearchModalSettings.okBtnName) {
            $scope.newTaskClientSearchModalSettings.okBtnName = null;
          }
        } else {
          if (typeof name !== 'undefined') {
            $scope.newTaskClientSearchModalSettings.okBtnName = name;
          }
          if (typeof disabled !== 'undefined') {
            $scope.newTaskClientSearchModalSettings.okBtnDisabled = disabled;
          }

          if ($scope.newTaskClientSearchModalSettings.thirdBtnName) {
            $scope.newTaskClientSearchModalSettings.thirdBtnName = null;
          }
        }
      };

      $scope.init();
    }
  ]);
