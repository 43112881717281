angular.module('ihestiaRestServicesBase')
.factory('ceidgReaderSvc', [ 'IHestiaRestServiceAbstractDef',
  function(IHestiaRestServiceAbstractDef) {

  var CeidgReader = function()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{subResource}{id}';

    this.resource = 'bisnode';
    this.businessArea = 'ceidg-reader-web';
  };

  return new CeidgReader();
}]);