//UWAGA nie uzywac helper abstrakcyjny, do uzycie sa jego dzieci
angular.module('ihestiaCommonDirectives')
  .service('iskanerCommonHelper', ['ihestiaCommonErrorHandler',
    function(ihestiaCommonErrorHandler) {
      var IskanerCommonHelper = function() {

        // var self = this;
        this.validConfig = function(input) {
          var dataTypes = {
            'FILE': 1,
            'AZTEC': 1,
            'POLICY': 1,
            'BARCODE': 1,
            'DOCUMENT': 1
          };

          if (angular.isUndefined(input) || Object.keys(input).length === 0) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner is missing config'
            });
            return false;
          }

          if (!angular.isString(input.componentId) || input.componentId.length === 0) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: component ID must be a string'
            });

            return false;
          }

          if (!angular.isString(input.userId) || input.userId.length === 0) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: user userId must be a string'
            });

            return false;
          }

          if (!angular.isString(input.system) || input.system.length === 0) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: system name must be a string'
            });

            return false;
          }

          if (angular.isUndefined(input.dataType) || !dataTypes[input.dataType]) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: dataType must be a string. Allowed values:' + Object.keys(dataTypes).join(', ')
            });
            return false;
          }

          if (input.dataDesc &&
            (!angular.isString(
              input.dataDesc) || input.dataDesc.length === 0 || input.dataDesc.length > 256)) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: dataDesc must be a string and is limited to 256 characters'
            });
            return false;
          }

          if (input.dataType === 'POLICY' && (angular.isUndefined(input.policyNumber) || !angular.isString(
                input.policyNumber) ||
              input.policyNumber.length === 0)) {
            ihestiaCommonErrorHandler.throwException({
              message: 'iSkaner config: for \'POLICY\' dataType, policyNumber must be specified as a string'
            });
            return false;
          }

          return true;
        };
      };

      return new IskanerCommonHelper();
    }
  ]);