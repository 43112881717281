angular.module('ihestiaSsoBase.baseInfo')

  .factory('ihestiaSsoCharactersCurrentSvc', [ 'abstractPlatformSessionSvc',
    function(abstractPlatformSessionSvc) {

    var CharactersCurrent = function()
    {
      abstractPlatformSessionSvc.apply(this, arguments);

      var self = this;

      this.resource = 'user/characters/current';

      /**
       * zmiana tożsamości
       * @param  {[type]} character       na jaką zmieniamy
       * @param  {[type]} successCallback
       */
      this.changeCharacter = function(character, successCallback, httpParams)
      {
        return self.put('', {
          Name: character.name,
          Code: character.code
        }, null, successCallback, null, httpParams);
      };
    };

    return new CharactersCurrent();
  }])
  ;