angular.module('ihestiaWidgets.policyDetails')
  .factory('EditInsuredRequestModel', ['LsnAbstractModel',
    function(LsnAbstractModel) {
      var EhlEditInsuredRequestModel = function() {
        this.objectName = 'EditInsuredRequest';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          operationData: {
            type: 'object'
          },
          logicalId: {
            type: 'string'
          },
          businessPartner: {
            type: 'BusinessPartner'
          }
        };

        /**
         * @typedef operationData
         * @param {string} validFrom - operation should be valid from this date
         * @param {string} operCauseCode - operation code
         * @param {{system: string = 'iHestia', policyNumber: string}} policyRef
         * @param {string} comment
         */
        this.operationData = null;
        this.logicalId = null;
        this.businessPartner = null;
      };

      EhlEditInsuredRequestModel.prototype = LsnAbstractModel.prototype;
      return EhlEditInsuredRequestModel;
    }]);
