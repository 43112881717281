angular.module('ihestiaSsoBase.baseInfo')
  .factory('ihestiaUserReloggedSvc', [ 'abstractPlatformSessionSvc',
    function(abstractPlatformSessionSvc) {

    var UserReloggedSvc = function()
    {
      abstractPlatformSessionSvc.apply(this, arguments);

      var self = this;

      this.resource = 'user/relogged';

      /**
       * przelogowanie
       * @param  {string} login login na ktory chcemy sie przelogowac
       * @param  {function} successCallback
       */
      this.relogUserTo = function(login, successCallback){
        return self.post('', {
          Login: login
        }, null, successCallback);
      };

      /**
       * odprzelogowanie
       * @param  {function} successCallback
       */
      this.revertRelogging = function(successCallback){
        return self.remove('', '', null, successCallback);
      };
    };

    return new UserReloggedSvc();
  }])
  ;