angular.module('ihestiaWidgets.life')
  .controller('ihestiaClauseListCtrl', ['$scope', 'IhestiaClauseListHelper',
    function($scope, IhestiaClauseListHelper) {
      $scope.mdConverter = null;
      $scope.init = function() {
        if (!$scope.helper) {
          $scope.helper = new IhestiaClauseListHelper($scope.settings);
        }

        $scope.tplData = $scope.helper.tplData;
        $scope.settings = $scope.helper.settings;
        $scope.mdConverter = $scope._initMdConverter();
      };

      /**
       * custom showdown converted, which is passed to lsnmd filter
       * @return {showdown.Converter}
       * @private
       */
      $scope._initMdConverter = function() {
        return new showdown.Converter({
          extensions: [$scope._styledLink],
          openLinksInNewWindow: true
        });
      };

      /**
       * showdown extension, which adds css classes to links
       * @return {[{regex: string, replace: string, type: string}]}
       * @private
       */
      $scope._styledLink = function() {
        var primaryLink = {
          type: 'output',
          regex: '<a (.*)>',
          replace: '<a class="link link-primary" $1>'
        };
        return [primaryLink];
      };

      $scope.init();
    }
  ]);
