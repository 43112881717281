angular.module('salesPath2')
  /**
   * helper dla usług dotyczących osób
   */
  .service('personServiceHelper', ['ihestiaRestDictionaryNationalitiesSvc', 'ihestiaRestDictionaryIdentityDocumentTypesSvc', 'clientPersonsSvc', 'clientPersonsNbkSvc', 'customerSvc', 'ihestiaRestDictionaryLastNamesSvc', 'ihestiaRestDictionaryFirstNamesSvc', 'ihestiaRestDictionaryDecisionMakersSvc', '$q',
    function(ihestiaRestDictionaryNationalitiesSvc, ihestiaRestDictionaryIdentityDocumentTypesSvc, clientPersonsSvc, clientPersonsNbkSvc, customerSvc, ihestiaRestDictionaryLastNamesSvc, ihestiaRestDictionaryFirstNamesSvc, ihestiaRestDictionaryDecisionMakersSvc, $q) {
      var CommonServiceHelper = function() {
        var self = this;

        /**
         * promise używany do anulowania requesta
         * Do użycia w dowolnym serwisie poniżej.
         * UWAGA
         * jeśli możliwa jest sytuacja, w której co najmniej 2 serwisy będą jednocześnie używać tego promise-a, to należy utworzyć osobny
         * @type {[type]}
         */
        this.promiseToCancel = $q.defer();

        /**
         * czy trwa zapytanie do usługi
         * Wykorzystywane w połączneiu z promiseToCancel
         * @type {Boolean}
         */
        this.processingRequest = false;

        /**
         * zwraca w callbacku listę wszystkich narodowości
         * @param  {Function} callback
         * @return {HttpPromise}
         */
        this.getAllNationalities = function(callback) {
          return ihestiaRestDictionaryNationalitiesSvc.find('', callback);
        };

        /**
         * zwraca w callbacku listę wszystkich typów dokumentów tożsamości
         * @param  {Function} callback
         * @return {HttpPromise}
         */
        this.getAllIdentityDocumentTypes = function(callback) {
          return ihestiaRestDictionaryIdentityDocumentTypesSvc.get(null, {}, null, callback);
        };

        /**
         * wyszukuje osobę po podanej frazie
         * @param  {String} query fraza
         * @return {HttpPromise}
         */
        this.findPerson = function(query) {
          var deferred = $q.defer();
          if (self.processingRequest) {
            self.promiseToCancel.reject('newRequest');
            self.promiseToCancel = $q.defer();
          }
          self.processingRequest = true;
          clientPersonsSvc.findByParams({
            query: query,
            top: 0, //bez 0 tutaj, nie wyszukuja sie osoby
            pageSize: 10,
            httpParams: {
              timeout: self.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
            }
          }).then(function(resp) {
            self.processingRequest = false;
            deferred.resolve(resp);
          }, function(resp) {
            self.processingRequest = false;
            deferred.reject(resp);
          });
          return deferred.promise;
        };

        /**
         * wczytuje osobę (stara kartoteka) po id
         * @param  {String} id
         * @param  {Function} callback reakcja na odpowiedź
         * @param  {Function} errorCallback reakcja na odpowiedź błędną
         * @return {HttpPromise}
         */
        this.getPersonById = function(id, callback, errorCallback) {
          return clientPersonsSvc.get(id, null, null, callback, errorCallback);
        };

        /**
         * wczytywanie osoby
         * @param  {String}   id metaData.id osoby
         * @param  {Object}   personData w wersji dla NBK powinien zawierać pola firstName, lastName, pesel, documents
         * @param  {Function} callback reakcja na odpowiedź
         * @return {HttpPromise}
         */
        this.getPerson = function(id, personData, callback, errorCallback, httpParams) {
          return clientPersonsNbkSvc.post(id, personData, null, callback, errorCallback, httpParams);
        };
        /**
         * wczytywanie klienta z NBK
         * @param  {String}   id metaData.id osoby
         * @param  {Function} callback reakcja na odpowiedź
         * @param  {Function} errorCallback reakcja na odpowiedź błędną
         * @return {HttpPromise}
         */
        this.getNbkCustomer = function(id, callback, errorCallback) {
          return customerSvc.get(id, null, null, callback, errorCallback);
        };

        this.findDecisionMaker = function(query, callback, consentType) {
          return ihestiaRestDictionaryDecisionMakersSvc.find(query, callback, consentType, 10, 10);
        };

        /**
         * zwraca klasę usłgi obsługującą wyszukiwanie nazwisk
         * @return {ihestiaRestAbstractDictionariesSvc}
         */
        this.getLastNameService = function() {
          return ihestiaRestDictionaryLastNamesSvc;
        };

        /**
         * zwraca klasę usłgi obsługującą wyszukiwanie imion
         * @return {ihestiaRestAbstractDictionariesSvc}
         */
        this.getFirstNameService = function() {
          return ihestiaRestDictionaryFirstNamesSvc;
        };
      };
      return new CommonServiceHelper();
    }
  ]);