angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaNotesAndUploader', ['$filter', function($filter) {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/notesAndUploader/notesAndUploader.tpl.html',
      restrict: 'E',
      transclude: true,
      scope: {
        helper: '=',
        showElements: '=?',
        disabled: '=?',
        labels: '=?',
        /** by default 5/7 col ratio
         * {[number. number]}
         */
        cols: '=?'
      },
      link: function(scope) {
        scope.tplData = scope.helper.tplData;
        scope.uploaderOptions = scope.helper.uploaderOptions;
        scope.showElements = scope.helper.mergeDefaultOptions(scope.showElements, {
          notes: true,
          uploader: true
        });
        scope.labels = scope.helper.mergeDefaultOptions(scope.labels, {
          notes: $filter('translate')('notes', {componentCode: 'Public'}),
          uploader: $filter('translate')('Public.documents', {componentCode: 'Public'})
        });
        /**
         * Assigning 'fileModels' property to scope for {@link commonDirectives#ihestiaUploadForm}
         */
        scope.fileModels = scope.helper.tplData.fileModels;
        scope.deleteFile = scope.helper.deleteFile;
        if (!scope.cols) {
          scope.cols = [5, 7];
        }
      }
    };
  }]);
