/**
 * This directive dynamically sets mask on ihestiaSalesPathAddresses directive -> postalCode,
 * if address countryCode is equal to 'PL' applied to postalCode is '?99-999' mask
 * else no mask is set and ['-', '_'] characters are removed from postal code
 * !! if directive should be applied conditionally,
 * then the condition should be pass in polish-postal-code-mask-if attribute
 */
angular.module('ihestiaCommonDirectives')
  .directive('polishPostalCodeMask', ['$parse', '$timeout', function($parse, $timeout) {
    return {
      restrict: 'A',
      require: 'ihestiaSalesPathAddresses',
      scope: false,
      link: function(scope, elem, attrs) {
        scope.POLISH_POSTAL_CODE_MASK = '?99-999';
        scope.init = function() {
          var condition = attrs.polishPostalCodeMaskIf ? $parse(attrs.polishPostalCodeMaskIf) : null;
          if (condition === null || condition) {
            scope.countryCode = $parse(scope, attrs.countryCode);
            scope.$watch('address.countryCode', function(val) {
              if (scope.masks && scope.address && scope.address.postalCode !== undefined) {
                scope.masks.postalCode = val === 'PL' ? scope.POLISH_POSTAL_CODE_MASK : null;
                if (!scope.masks.postalCode) {
                  scope.address.postalCode = _.replace(scope.address.postalCode, /[-,_]/g, '');
                }
              }
            });
          }
        };
        $timeout(scope.init.bind(this), 0);
      }
    };
  }]);
