angular.module('ihestiaWidgets.claims')
  .controller('abstractClaimDetailsCtrl', ['$scope', '$stateParams', 'claimsHelper', 'claimsSvc', '$timeout', '$state', 'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaAutoUploadOneListUploadHelper', 'ihestiaCommonErrorHandler', 'fileServerSvc', '$alert', '$location', '$filter',
    function($scope, $stateParams, claimsHelper, claimsSvc, $timeout, $state, ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaAutoUploadOneListUploadHelper, ihestiaCommonErrorHandler, fileServerSvc, $alert, $location, $filter) {

      $scope.claim = {};
      $scope.claimId = $stateParams.claimId;
      $scope.claimObject = null;

      $scope.adjusterPerson = null;
      $scope.survey = null;
      $scope.surveyDate = null; //określana na podstawie statusu oględzin

      $scope.hasPaymentConfirmation = false;
      $scope.hasPaymentOrder = false; //czy jest jakaś informacja o płatnościach (wykluczamy gotówkę), możemy nie mieć kwoty z powodu braku uprawnień
      $scope.hasPaymentOrderAmount = false; //czy prezentujemy kwoty na decyzjach
      $scope.shortRequiredDocumentList = true;

      $scope.progressOrder = ['notification', 'notificationVerification', 'inspection', 'documentationVerification', 'decision'];
      $scope.showProgress = true;

      $scope.decisions = [];
      $scope.payments = [];
      $scope.elementLoaded = {documentTypes: false, claim: false};

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        maxChunkSize: 4000000,
        messages: {
          maxNumberOfFiles: $filter('translate')('maxFileCountExceeded', {componentCode: 'Public'}),
          acceptFileTypes: $filter('translate')('incorrectFileType', {componentCode: 'Public'}),
          maxFileSize: $filter('translate')('toLargeFile', {componentCode: 'Public'}),
          minFileSize: $filter('translate')('toSmallFile', {componentCode: 'Public'})
        },
        name: 'claimDetails',
        hideQueueList: true,
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning',
            duration: 10
          });
        }
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function(){
        $scope.loadClaim();
        $scope.getDocumentTypeForUpload();
      };

      $scope.getDocumentTypeForUpload = function() {
        ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(null, {
          context: 'Damages'
        }, null, function(result) {
          if (angular.isArray(result.data) && result.data.length > 0) {
            var documentType = result.data[0];
            ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name ,documentType);
            $scope.elementLoaded.documentTypes = true;
            $scope.setDocumentTypeForUpload();
          } else {
            ihestiaCommonErrorHandler.throwException($filter('translate')('emptyDocumentTypesList', {componentCode: 'Public'}));
          }
        });
      };

      $scope.setDocumentTypeForUpload = function(){
        if($scope.elementLoaded.documentTypes && $scope.elementLoaded.claim){
          var documentType = ihestiaAutoUploadOneListUploadHelper.data[$scope.uploaderOptions.name].selectedDocumentType;
          if(documentType.attributes.length === 0){
            var attr = {code: 'DamageNumbers', type: 'stringCollection'};
            documentType.attributes.push(attr);
          }
          documentType.attributes[0].val = [$scope.claim.claimNumber];
          ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, documentType);
        }
      };

      $scope.refreshProgress = function()
      {
        $scope.progress = {
          notification: {
            visible: true,
            highlight: $scope.claim.adjustmentCall,
            icon: 'icon-note-paper-add',
            name: $filter('translate')('notification', {componentCode: 'Public'})
          },
          notificationVerification: {
            visible: true,
            highlight: $scope.claim.adjustmentCallVerification,
            icon: 'icon-note-paper-checked',
            name: $filter('translate')('notificationVerification', {componentCode: 'Public'})
          },
          inspection: {
            visible: false,
            highlight: $scope.claim.adjustmentSurvey,
            icon: 'icon-preview-1',
            name: $filter('translate')('survey', {componentCode: 'Public'})
          },
          documentationVerification: {
            visible: false,
            highlight: $scope.claim.adjustmentDocumentVerification,
            icon: 'icon-file-checked',
            name: $filter('translate')('documentationVerification', {componentCode: 'Public'})
          },
          decision: {
            visible: true,
            highlight: $scope.claim.adjustmentDecision,
            icon: 'icon-hand-file-1',
            name: $filter('translate')('decision', {componentCode: 'Public'})
          }
        };

        if($scope.survey !== null) //jak są oględzieny to pokazujemy je na progress
        {
          $scope.progress.inspection.visible = true;
        }
        if($scope.survey !== null && ($scope.survey.hslType === 'A' || $scope.survey.hslType === 'B' || $scope.survey.hslType === 'C' || $scope.survey.hslType === 'G'))
        {
          $scope.progress.documentationVerification.visible = true;
        }
        if($scope.claim.isDocumentInEgida)
        {
          $scope.progress.documentationVerification.visible = true;
        }

        //na ostatnim widocznym ustawiamy flagę 'last'
        var lastName = null;
        angular.forEach($scope.progressOrder, function(progressName){
          if($scope.progress[progressName].visible)
          {
            lastName = progressName;
          }
        });
        $scope.progress[lastName].last = true;

      };

      $scope.parseClaim = function()
      {
        //zbieramy obiekty o ile mamy do nich uprawnienia (różne od null)
        $scope.claimObject = claimsHelper.getObject($scope.claim);

        $scope.adjusterPerson = $scope.claim.adjusterPerson;
        if($scope.adjusterPerson && $scope.adjusterPerson.phone)
        {
          $scope.adjusterPerson.phone = $scope.adjusterPerson.phone.trim();
        }

        if($scope.claim.surveyList !== null && $scope.claim.surveyList.length > 0)
        {
          $scope.survey = $scope.claim.surveyList[$scope.claim.surveyList.length - 1];
          $scope.prepareSurveyDate();
        }

        if($scope.claim.requiredDocumentList && $scope.claim.requiredDocumentList.length !== 0)
        {
          $scope.shortRequiredDocumentList = false;
        }

        if($scope.claim.decisionList)
        {
          angular.forEach($scope.claim.decisionList, function(decision){

            //decyzje
            if(decision.paymentOrderList)
            {
              angular.forEach(decision.paymentOrderList ,function(paymentOrder){
                //szukamy uposażonego
                if(paymentOrder.beneficiary !== null && paymentOrder.beneficiary.beneficiaryId !== null)
                {
                  var beneficiaryFound = false;
                  angular.forEach($scope.claim.insuredPersonList, function(insuredPerson){
                    if(beneficiaryFound)
                    {
                      //mamy już go
                      return;
                    }
                    if(insuredPerson.businessId === paymentOrder.beneficiary.beneficiaryId)
                    {
                      beneficiaryFound = true;
                      paymentOrder.beneficiary.name = insuredPerson.name;
                      paymentOrder.beneficiary.firstName = insuredPerson.firstName;
                      paymentOrder.beneficiary.lastName = insuredPerson.lastName;
                    }
                  });
                }

                if(paymentOrder.type !== 'CASH')
                {
                  if(paymentOrder.amount !== null)
                  {
                    $scope.hasPaymentOrderAmount = true;
                  }

                  $scope.anyPaymentOrder = true;
                  $scope.hasPaymentOrder = true;
                  $scope.decisions.push(paymentOrder);
                }
              });
            }

            //wypłaty
            if(decision.paymentConfirmationList)
            {
              angular.forEach(decision.paymentConfirmationList ,function(paymentConfirmation){
                if(paymentConfirmation.amount !== null && paymentConfirmation.amount.inCurrency > 0)
                {
                  $scope.hasPaymentConfirmation = true;
                  $scope.payments.push(paymentConfirmation);
                }
              });
            }
          });
        }

        //postęp
        $scope.refreshProgress();
      };

      /**
       * przygotowujemy datę oględzin
       */
      $scope.prepareSurveyDate = function()
      {
        var possibleDates;
        if($scope.survey.status === 'Zakończone')
        {
          //Data zakończenia przez koordynatora lub Data zakończenia transferem
          possibleDates = ['endCoordinatorDate', 'endWithTransferDate'];
        }
        else
        {
          possibleDates = ['createDate', 'proposedDate', 'surveyDate'];
        }
        var lastPossibleDate = null;
        //bierzemy późniejszą z możliwych dat
        angular.forEach(possibleDates, function(dateAttribute){
          if($scope.survey[dateAttribute])
          {
            if(lastPossibleDate === null)
            {
              lastPossibleDate = $scope.survey[dateAttribute];
            }
            else
            {
              //badamy która jest późniejsza
              var xlastPossibleDate = new XDate(lastPossibleDate);
              var xnewPossibleDate = new XDate($scope.survey[dateAttribute]);
              if(xlastPossibleDate.diffDays(xnewPossibleDate) > 0)
              {
                //nowa data jest późniejsza
                lastPossibleDate = $scope.survey[dateAttribute];
              }
            }
          }
        });
        $scope.surveyDate = lastPossibleDate;
      };

      /**
       * Ładujemy szkodę
       */
      $scope.loadClaim = function(){
        $scope.claim = {};

        var params = {};
        if($scope.appContextData && $scope.appContextData.customerId)
        {
          params.clientAccountNumber = $scope.appContextData.customerId;
        }

        // /api/secured/claim/pwd/GD20-5114-26

        claimsSvc.get('pwd/' + $stateParams.claimId, params, null, function(response){
          $scope.claim = response.data;

          if($scope.shareData)
          {
            $scope.shareData.claimData = $scope.claim;
          }

          $scope.parseClaim();

          $scope.$emit('setPageTitle', {
            pageTitleLabel: $state.current.data.pageTitleLabel,
            pageTitle: $state.current.data.pageTitle,
            stateNumber: $scope.claim.claimNumber
          });

          $scope.elementLoaded.claim = true;
          if(!$scope.isPwd)
          {
            $scope.setDocumentTypeForUpload();
          }

          $scope.$emit('hideBlockUi');
        }, function(reject){
          $scope.$emit('hideBlockUi');
          if(reject.status === 404)
          {
            $location.url('/');
          }
        }, {allowedStatuses: [404]});

        if(!$scope.isPwd)
        {
          $location.url('/');
        }

      };

      $scope.init();
    }]);