angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifePersonHeaderHelper', ['AddressModelConstants', 'ContactModelConstants', 'IhestiaLifePersonHeaderConstants', '$filter',
    function(AddressModelConstants, ContactModelConstants, IhestiaLifePersonHeaderConstants, $filter) {
      var IhestiaLifePersonHeaderHelper = function() {

        this.getAddress = function(subject) {
          //todo ADDRESS_TYPE_STAL vanishes
          return _.find(subject.addresses, ['code', AddressModelConstants.ADDRESS_TYPE_STAL]) || _.first(subject.addresses);
        };

        /**
         * Ustawia dane kontaktowe
         */
        this.getContactData = function(subject) {
          var contactData = {};
          _.forEach(subject.contacts, function(contact) {
            if (contact.code === ContactModelConstants.CONTACT_TYPE_MAIL) {
              contactData.mail = contact.value;
            }
            if (contact.code === ContactModelConstants.CONTACT_TYPE_KOMO) {
              contactData.mobile = contact.value;
            }
          });
          return contactData;
        };

        this.getRoleNameLabelLife = function(role) {
          switch (role) {
            case IhestiaLifePersonHeaderConstants.roleInsurer:
              return $filter('translate')('Public.insurer', {componentCode: 'Public'});
            case IhestiaLifePersonHeaderConstants.roleInsurerAndMainInsured:
              return $filter('translate')('insuredOrInsurer', {componentCode: 'Public'});
            default:
              return $filter('translate')('insured', {componentCode: 'Public'});
          }
        };
      };
      return new IhestiaLifePersonHeaderHelper();
    }
  ])
;
