angular.module('salesPath2')
  .service('owuModalHelper', ['$rootScope', 'iHestiaCommonModalHelper',
    function($rootScope, iHestiaCommonModalHelper) {
      var OwuModalHelper = function() {

        /**
         * wyświetlanie okienka OWU
         * @type {Object}
         */
        $rootScope.modals.owuModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.owuModal.title',
            cancelBtnName: 'sp2.modal.close',
            okBtnName: false,
            size: 'sm'
          }
        };

        //wyswietla modal owu
        this.showOwuModal = function() {
          iHestiaCommonModalHelper.showModal('owuModal');
        };

      };

      return new OwuModalHelper();
    }
  ])
  .run(['owuModalHelper', function(owuModalHelper) {
    lsnNg.noop(owuModalHelper);
  }]);