angular.module('lsnBase.models')
  .factory('LsnRiskGroupModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAmountModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnAmountModelConstants)
    {
      var LsnRiskGroupModel = function()
      {
        this.objectName = 'RiskGroup';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          name: {
            type: 'string'
          },          
          premium: {
            type: 'float',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          }
        };
        
        this.name = null; // string, nazwa grupy ryzyk        
        this.premium = null; // Amount, Składka na ryzyku        
      };

      LsnRiskGroupModel.prototype = LsnAbstractModel.prototype;

      return LsnRiskGroupModel;
    }
  ]);