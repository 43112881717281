angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionAdditionalPremiumsCtrl', ['$scope', 'IhestiaPzaConditionDefinitionAdditionalPremiumModel',
    function($scope, IhestiaPzaConditionDefinitionAdditionalPremiumModel){

      $scope.selectedPremiums = {};

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.setSelelectedPremiums();
        $scope.$watch('selectedPremiums', function(selectedPremiums){
          $scope.conditionData.discountList = [];
          angular.forEach(selectedPremiums, function(selectedPremium, premiumId){
            if(!selectedPremium.discount) {
              selectedPremium.discount = {
                id: premiumId
              };
            }
            var model = new IhestiaPzaConditionDefinitionAdditionalPremiumModel();
            model.setData(selectedPremium);
            $scope.conditionData.discountList.push(model.getData());
          });
        }, true);
      };

      /**
       * Inicjalne ustawienie zaznaczonych zniżek
       */
      $scope.setSelelectedPremiums = function() {
        angular.forEach($scope.conditionData.discountList, function(additionalPremium){
          $scope.selectedPremiums[additionalPremium.discount.id] = additionalPremium;
          $scope.selectedPremiums[additionalPremium.discount.id].selected = $scope.selectedPremiums[additionalPremium.discount.id].selected ? true : false;
        });
      };
    }
  ]);