angular.module('lsnBase.models')
.factory('LsnEstateModelV1', ['LsnAbstractModel', 'lsnModelFactory', 'LsnEstateModelConstants',
  function(LsnAbstractModel, lsnModelFactory, LsnEstateModelConstants) {

    var LsnEstateModelV1 = function()
    {
      this.objectName = 'Estate';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion; 
      this.fieldsProperties = {
        metaData: {
          type: 'MetaData'
        },
        type: {
          type: 'string',
          dictionary: LsnEstateModelConstants.DICTIONARY_ESTATE_TYPE
        },
        name: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        address: {
          type: 'Address'
        },
        dynamicValues: {
          type: 'dynamicValues'
        }
      };

      this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
      this.type = null; // string, typ nieruchomości lokal/budynek
      this.name = null; // string, skrócona nazwa lokalizacji
      this.address = null; // Address, adres nieruchomości
      this.dynamicValues = null; // Lista atrybutów dotyczących nieruchomości

      this.setDefaultAdditionalData();

    };

    LsnEstateModelV1.prototype = LsnAbstractModel.prototype;

    return LsnEstateModelV1;
  }]);