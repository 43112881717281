angular.module('spaceChat.client')
  .controller('chatWelcomePopupCtrl', ['$scope', 'ihestiaSsoBaseInfoHelper', 'spaceChatHelper', 'ihestiaPlatformHelper', 'ihestiaConfigHelper', '$window',
    function($scope, ihestiaSsoBaseInfoHelper, spaceChatHelper, ihestiaPlatformHelper, ihestiaConfigHelper, $window) {

      $scope.userInfo = ihestiaSsoBaseInfoHelper.getUser();
      $scope.isJupiterOrDirect = ihestiaPlatformHelper.isJupiterOrDirect();

      $scope.openChatWindow = function()
      {
        $scope.chatWelcomePopupSettings.show = false;
        $scope.chatTalkPopupSettings.show = true;

        //oznaczamy chat jako otwarty żeby móc go odtwarzać na innych zakładkach
        spaceChatHelper.setCookieRoom(SparkMD5.hash(ihestiaSsoBaseInfoHelper.getCurrentUser().login));
      };

      $scope.goToArchive = function()
      {
        var appUrl;
        if($scope.isJupiterOrDirect)
        {
          appUrl = 'JUPITER_URL';
        }
        else
        {
          appUrl = 'PORTAL_URL';
        }
        $window.open(ihestiaConfigHelper.getUrl(appUrl, 'external/chatArchive'), '_blank');
        $scope.chatWelcomePopupSettings.show = false;
      };

      $scope.close = function()
      {
        $scope.chatWelcomePopupSettings.show = false;
      };

    }
  ]);