angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('IHestiaRestPerformanceContractsDef', ['ihestiaCommonErrorHandler', '$timeout',
    function(ihestiaCommonErrorHandler, $timeout) {

    var RestServicePerformanceContractsDef = function(provider){
      var self = this;
      this.config = {
        numberOfTrials: 10,
        additionalTimeToWaitToRetry: 0
      };
      this.messagesError = {
        '001': 'Bufor żądań odłożonych jest pełen',
        '002': 'Brak możliwości wykonania zadania natychmiast',
        '003': 'Ważność żądania upłynęła',
        '004': 'Wyczerpano limit ponowień',
        '005': 'Brak dostępu do żądania odroczonego',
        '006': 'Nieprawidłowe żądanie'
      };
      this.provider = provider;
      this.requests = {};
      this.trialsNumber = {};

      this.onCallAgainAjaxRequest = function(result, ajaxCallParams, callback, errorCallback, httpParams, additionalParam, mainResolve, mainReject) {
        var deferredReqId = result.headers('Ap-Contract-Deferred-Req-Id');
        var retryAfter = parseInt(result.headers('Ap-Contract-Retry-After'), 10) + self.config.additionalTimeToWaitToRetry;

        ajaxCallParams.data = '';
        additionalParam.requestType = 'DEFERRED';
        additionalParam.deferredRequestId = deferredReqId;
        self.requests[deferredReqId] = {
          result: result,
          ajaxCallParams: ajaxCallParams,
          callback: callback,
          errorCallback: errorCallback,
          httpParams: httpParams,
          additionalParam: additionalParam,
          mainResolve: mainResolve,
          mainReject: mainReject
        };

        if (typeof self.trialsNumber[deferredReqId] === 'undefined') {
          self.trialsNumber[deferredReqId] = self.config.numberOfTrials;
        } else {
          self.trialsNumber[deferredReqId] -= 1;
        }

        if (self.trialsNumber[deferredReqId] > 0) {
          $timeout(function() {
            self.callAgainAjaxRequest(deferredReqId);
          }, retryAfter);
        }
      };

      this.callAgainAjaxRequest = function(deferredReqId) {
        var requestData = self.requests[deferredReqId];

        self.provider.callAjaxWithParams(requestData.ajaxCallParams, requestData.callback, requestData.errorCallback, requestData.httpParams, requestData.additionalParam);
      };

      this.onRequestRefused = function(result, ajaxCallParams, uniqueRequestId) {
        var srcDeferredId = result.headers('Ap-Contract-Src-Deferred-Id'),
                msgCode = result.headers('Ap-Contract-Msg-Code'),
                msg = self.messagesError[msgCode],
                code = 'PerformanceContracts';

        self.clearRequest(srcDeferredId);

        ihestiaCommonErrorHandler.throwException({
          code: code,
          message: msg,
          data: {
            srcDeferredId: srcDeferredId,
            msgCode: msgCode,
            uniqueRequestId: uniqueRequestId
          }
        }, true);
      };

      this.clearRequest = function(deferredId) {
        delete self.requests[deferredId];
      };
    };

    return RestServicePerformanceContractsDef;
  }]);
