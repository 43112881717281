angular.module('salesPath2')
    /**
     * helper dla usług związanych z programem zniżkowym (promocje/odstępstwa)
     */
    .service('basketServiceSvc', ['IHestiaRestServiceAbstractDef', '$http', 'appVariables',
        function(IHestiaRestServiceAbstractDef, $http, appVariables) {
            var basketServiceSvc = function() {

                IHestiaRestServiceAbstractDef.apply(this, arguments);

                this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
                this.businessArea = 'pza';
                this.apiString = 'api';

                var self = this;

                this._parentMethods = {
                    get: self.get,
                    post: self.post
                };

                /*
                 * Czy presentujemy koszyk2
                 * @param  {String} segment
                 * @param  {Array} packageCodeList
                 * @return {boolean}
                 */
                this.isSecondBasketVisible = function(segment, packageCodeList) {
                    this.resource = 'program';
                    var param = {
                        'packageList': packageCodeList,
                        'segment': segment
                    };
                    var packageArray = [];

                    //sprawdzamy oddzielnie dla komunikacji i mienia

                    lsnNg.forEach(packageCodeList, function(packageCode) {
                        packageArray = [];
                        packageArray.push(packageCode);
                        param = {
                            'packageList': packageArray,
                            'segment': segment
                        };

                        self.post(null, param, 'check', function() {
                            if (packageCode.substring(packageCode.length - 1, packageCode.length) === 'T') {
                                appVariables.basketContainerSecondVisibleCom = true;
                            } else {
                                appVariables.basketContainerSecondVisibleNonCom = true;
                            }
                            //200 - jest program
                        }, function() {
                            if (packageCode.substring(packageCode.length - 1, packageCode.length) === 'T') {
                                appVariables.basketContainerSecondVisibleCom = false;
                            } else {
                                appVariables.basketContainerSecondVisibleNonCom = false;
                            }
                            //każdy inny, ale w szczególności 404 - nie m programu
                        });
                    });

                    return true;
                };

                /*
                 * Pobranie definicji kodu zniżkowego
                 */
                this.checkDiscountCode = function(segment, discountCode, callback, errorCallback, httpParams) {

                    this.resource = 'discount';

                    var param = {
                        'segment': segment,
                        'discountCode': discountCode
                    };

                    return self.get(null, param, 'check', callback, errorCallback, httpParams);
                };

                this.get = function(id, data, subResource, callback, errorCallback, httpParams) {
                    self.id = id;
                    var parseCallback = function(result) {
                        if (angular.isFunction(callback)) {
                            callback(result);
                        }
                    };

                    if (typeof httpParams === 'undefined' || httpParams === null) {
                        httpParams = {};
                    }

                    httpParams.allowedStatuses = [404, 403];

                    return self._parentMethods.get(id, data, subResource, parseCallback, errorCallback, httpParams);
                };

                this.post = function(id, data, subResource, callback, errorCallback, httpParams) {
                    self.id = id;
                    var parseCallback = function(result) {
                        if (angular.isFunction(callback)) {
                            callback(result);
                        }
                    };

                    if (typeof httpParams === 'undefined' || httpParams === null) {
                        httpParams = {};
                    }

                    httpParams.allowedStatuses = [404, 403];

                    return self._parentMethods.post(id, data, subResource, parseCallback, errorCallback, httpParams);
                };

            };

            return new basketServiceSvc();
        }
    ]);