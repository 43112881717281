angular.module('salesPath2')
  .service('personGroupHelper', ['actionHelper', 'mainDataContainer', 'CONSTANTS', 'personHelper', '$timeout', 'GroupModel', 'ihestiaTrimInfo', 'RESOURCES', '$filter', 'dataContainerHelper', 'allowedChangesHelper',
    function(actionHelper, dataContainer, CONSTANTS, personHelper, $timeout, GroupModel, ihestiaTrimInfo, RESOURCES, $filter, dataContainerHelper, allowedChangesHelper) { // eslint-disable-line angular/di
      var PersonGroupHelper = function() {
        var self = this;

        /**
         * parametry dla personInfo w boksie na matrycy
         * @type {Object}
         */
        this.boxPersonInfoParams = {
          elements: [{
            field: 'lastName'
          }, {
            field: 'firstName'
          }],
          maxLength: 999
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'groupAdded':
            case 'personAdded':
              if (angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
                self.addSubjectToInsured(arguments[1].id);
              }
              break;
            default:
              break;
          }
        };

        /**
         * zwraca opis grupy zdatny do wyświetlena
         * @param  {GroupModel} group
         * @param  {object} params
         * @return {string}
         */
        this.getGroupInfo = function(group, params) {
          // domyslne ustawienia
          var settings = angular.extend({
            elements: [{
              field: 'name'
            }, {
              field: 'personCount'
            }],
            maxLength: 999,
            emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
          }, params);

          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            if (element.field === 'personCount') {
              element.text = '(' + self.getGroupPersonCount(group) + ')';
            } else {
              element.text = group.get(element.field);
            }
          });

          var text = ihestiaTrimInfo(settings);

          return text;
        };

        /**
         * zwraca liczbę osób dla grupy
         * @param  {GroupModel} group grupa
         * @return {Number} liczba osób
         */
        this.getGroupPersonCount = function(group) {
          var personCount = 0,
            dynVals = group.get('dynamicValues');
          angular.forEach(RESOURCES.INSURED_AGE_INTERVALS, function(item) {
            if (angular.isNumber(dynVals[item.BOS_CODE]) && !isNaN(dynVals[item.BOS_CODE])) { // because angular.isNumber(NaN) returns true...
              personCount += dynVals[item.BOS_CODE];
            }
          });

          return personCount;
        };

        /**
         * zwraca podmioty (grupy i osoby) do produktów personGroup
         * @param  {String} container persons lub groups
         * @return {Object} tablica
         * obiekt:
         *   name - nazwa do wyswietlenia
         *   clientId - id elementu
         *   objectName - nazwa obiektu (Group lub Person)
         */
        this.getSubjects = function() {
          var subjects = [];
          angular.forEach(dataContainerHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(obj) {
            subjects.push(self.getSubjectData(null, obj));
          });
          return subjects;
        };

        /**
         * zwraca dane podmiotu na potrzeby szablonu/templatki
         * Podajemy albo clientId albo podmiot w 2 argumencie.
         * @param  {String} [clientId] clientId grupy lub osoby
         * @param  {PersonModel|GroupModel} [subject]  grupa lub osoba
         * @return {Object} dane podmiotu
         */
        this.getSubjectData = function(clientId, subject) {
          if (angular.isString(clientId)) {
            subject = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
          }
          var isGroup = (subject.objectName === 'Group'),
            data = {
              name: isGroup ? self.getGroupInfo(subject) : personHelper.getPersonInfo(subject, self.boxPersonInfoParams),
              clientId: subject.metaData.get('clientId'),
              objectName: subject.objectName,
              allowedChanges: allowedChangesHelper.getAllowedChangesFor(subject),
              canRemove: allowedChangesHelper.canRemoveSubjectFromInsured(subject)
            };
          return data;
        };

        /**
         * zwraca clientId wszystkich podmiotow zaznaczonych do ubezpieczen typu osoba/grupa
         * @return {Object} tablica id-kow
         */
        this.getSubjectsClientIds = function() {
          var ids = [];
          angular.forEach(self.getSubjects(), function(subject) {
            ids.push(subject.clientId);
          });
          return ids;
        };
        /**
         * dodaje podmiot do ubezpieczonych na ryzykach typu personGroup
         * @param {String} clientId
         */
        this.addSubjectToInsured = function(clientId) {
          var currentSubjectClientId = dataContainerHelper.getSelectedObjectId(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP);
          dataContainerHelper.selectSubject(clientId);
          if (parseInt(currentSubjectClientId, 10) > 0) { //jesli aktualnie wtbrany jakis podmiot, to kopiujemy z niego zaznaczenia produktów/dodatków
            dataContainerHelper.copyObjectSelection(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, currentSubjectClientId, clientId);
          }
          actionHelper.runAction('personGroupSelected', {context: 'new'});
          actionHelper.runAction('saveApplication');
        };

        /**
         * zapis grupy z wszelkimi eventami
         * @param  {Object} groupData dane do obiektu grupy
         * @param  {String} context
         * @param  {[type]} [clientId] opcjonalny metaData.clientId
         */
        this.save = function(groupData, context, clientId) {
          var groupId = self.saveGroup(groupData, clientId);

          if (angular.isUndefined(clientId) || clientId === null) {
            $timeout(function() {
              actionHelper.runAction('groupAdded', {
                id: groupId,
                context: context
              });
            }, 0);
          } else {
            $timeout(function() {
              actionHelper.runAction('groupEdited', {
                id: groupId,
                context: context
              });

            }, 0);
          }
        };

        /**
         * zapis grupy (bez eventow)
         * @param {Object} groupData dane grupy
         * @param {(string|int)=} groupId id grupy
         * @param {boolean=} saveApplication czy zapisać wniosek (domyslnie = true)
         * @param {string=} filterForRole nazwa roli, dla której należy zastosować filtr zapisu osoby. Gdy brak filtrowanie nie odbywa się.
         * @return {String} clientId zapisanej grupy
         */
        this.saveGroup = function(groupData, groupId, saveApplication) {
          if (angular.isUndefined(saveApplication)) //false przy wyborze osoby z ekranu startowego i przejściu do formularza dodawania osoby
          {
            saveApplication = true;
          }
          var newGroup = false;
          var group;
          if (angular.isUndefined(groupId) || groupId === null || angular.isUndefined(dataContainer.groups[groupId])) {
            newGroup = true;
            if (angular.isUndefined(groupId) || groupId === null) {
              groupId = dataContainerHelper.getNextPersonId();
            }
            group = new GroupModel();
            group.metaData.set('clientId', groupId);
            dataContainer.groups[groupId] = group;
          } else {
            group = dataContainer.groups[groupId];
          }
          group.setData(groupData);
          group.metaData.set('clientId', groupId); //na wypadek gdyby byl null w groupData.metaData.clientId

          if (newGroup) {
            //nazwa grupy
            var groupCount = Object.keys(dataContainer.groups).length;
            group.set('name', self.generateGroupName(groupCount));
          }

          if (saveApplication && !newGroup) //obsluga zapisu wniosku dla nowej grupy powinna byc obsluzona w delegacie (na callbacku groupAdded). Niniejszy przpadek ma oblsugiwac np. edycje grup po kliknieciu na blad z listy (automatyczny zapis wniosku)
          {
            actionHelper.runAction('saveApplication');
          }
          return group.metaData.get('clientId');
        };

        //usuwa grupę z datcy w DC (o powiązania z ryzykami należy zadbać osobno)
        this.deleteGroup = function(clientId) {
          delete dataContainer.groups[clientId];
        };

        /**
         * generuje nazwe grupy
         * @param  {Number} groupNo
         * @return {String} nazwa
         */
        this.generateGroupName = function(groupNo) {
          return 'Grupa ' + groupNo;
        };

      };

      return new PersonGroupHelper();
    }
  ])
  .run(['actionHelper', 'personGroupHelper',
    function(actionHelper, personGroupHelper) {
      actionHelper.registerHelper('personGroupHelper', personGroupHelper);
    }
  ]);