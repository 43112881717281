angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorSelectSubjectHelper', ['iHestiaCommonModalHelper', 'iKomunikatorMessageContextSvc', 'ihestiaIkomunikatorCommonHelper', '$rootScope',
    function(iHestiaCommonModalHelper, iKomunikatorMessageContextSvc, ihestiaIkomunikatorCommonHelper, $rootScope) {
      var SelectSubjectHelper = function() {
        var self = this;

        this.defaultData = {
          subjectList: [],
          categoryList: null,
          selectedSubjectId: null,
          selectedCategoryId: null,
          errorNotSelectedSubjectId: false,
          errorNotSelectedCategoryId: false
        };

        this.init = function(){
          self.tplData = angular.copy(self.defaultData);
          self.setSubjects();
        };

        $rootScope.selectSubjectModalSettings = {
          title: 'Nowa wiadomość',
          okBtnName: 'Zatwierdź',
          replaceModalBody: true,
          cancelBtnName: 'Anuluj'
        };

        /**
         * funkcja odpalana w momencie 1 odwołania się do nowej wiadomości
         *  w zależności od typu klienta zostanie otwarte okno nowej wiadomości bądź wyszukiwarki osób
         */
        this.showModal = function() {
          iHestiaCommonModalHelper.showModal('ihestiaIkomunikatorSelectSubject');
        };

        this.hideModal = function(){
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorSelectSubject');
        };

        this.getCategories = function(selectedSubjectCode) {
          $rootScope.$broadcast('showBlockUi', {
            label: 'Wczytywanie podtematów'
          });
          return iKomunikatorMessageContextSvc.getCategories({selectedSubjectCode: selectedSubjectCode}, function(result) {
              $rootScope.$broadcast('hideBlockUi');
              if (angular.isObject(result.data)) {
                self.tplData.categoryList = result.data.categoryList;
                self.tplData.categoryList.unshift({
                  label: '-wybierz-',
                  code: -1
                });
                self.tplData.selectedCategoryId = self.tplData.categoryList[0].code;
              } else {
                ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie podtematów');
              }
            },
            function() {
              $rootScope.$broadcast('hideBlockUi');
              ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie podtematów');
            });
        };

        this.setSubjects = function() {
          $rootScope.$broadcast('showBlockUi', {
            label: 'Wczytywanie tematów'
          });
          iKomunikatorMessageContextSvc.getFields({}, function(result) {
            $rootScope.$broadcast('hideBlockUi');
              if (angular.isObject(result.data)) {
                self.tplData.subjectList = result.data.subjectList;
                self.tplData.subjectList.unshift({
                  label: '-wybierz-',
                  code: -1
                });
                self.tplData.selectedSubjectId = self.tplData.subjectList[0].code;
              } else {
                ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie tematów');
              }
            },
            function() {
              $rootScope.$broadcast('hideBlockUi');
              ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie tematów');
            });
        };
      };

      return new SelectSubjectHelper();
    }
  ]);