//UWAGA nie uzywac helper abstrakcyjny, do uzycie sa jego dzieci
angular.module('ihestiaCommonDirectives')
  .service('IhestiaAbstractListUploadHelper', ['AbstractUploadOnCallbacksHelper', '$rootScope', 'IHestiaOneListFileModel', '$alert', 'filesSvc', '$timeout', 'OneListUploadDataContainer', 'ihestiaDownloadHelper', '$filter',
    function(AbstractUploadOnCallbacksHelper, $rootScope, IHestiaOneListFileModel, $alert, filesSvc, $timeout, OneListUploadDataContainer, ihestiaDownloadHelper, $filter) {
      var AbstractListUploadHelper = function() {
        AbstractUploadOnCallbacksHelper.apply(this, arguments);
        var self = this,
          parentMethod = {
            addHeaderToServiceSendingFile: this.addHeaderToServiceSendingFile,
            onErrorWhenSending: this.onErrorWhenSending
          };

        /**
         * Metoda wysyła informacje o pliku po kolei po wszystkich plikach do wysłania
         * @param  {string} scopeName  nazwa scope
         */
        this.sendInfoAboutFile = function(scopeName) {
          if (self.areFileToSend(scopeName)) {
            var processedFile = self.data[scopeName].getProcessedFile(),
              fileData = processedFile.getInfoAboutFile();

            filesSvc.post('', fileData, '', function(response) {
              var data = response.data;
              if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
                if ($.isEmptyObject(data)) {
                  var text = $filter('translate')('exceptionInternalServices', {
                    componentCode: 'Public'
                  });
                  self.onErrorWhenSending(scopeName, null, data, text);
                } else {
                  $timeout(function() {
                    var allAttrValid = true;
                    angular.forEach(processedFile.attributes, function(attr) {
                      angular.forEach(data.messages, function(msg) {
                        if (attr.code === msg.object) {
                          allAttrValid = false;
                        }
                      });
                    });
                    if (allAttrValid) {
                      self.onErrorWhenSending(scopeName, null, data, data.messages);
                    } else {
                      self.data[scopeName].status = 'ERROR';
                      if (angular.isFunction(self.data[scopeName].callbacks.onErrorAttributes)) {
                        self.data[scopeName].callbacks.onErrorAttributes(data);
                      }
                    }
                  }, 0);
                }

              } else {
                processedFile.tokenId = data.tokenId;
                if (self.data[scopeName].config.firstCheckAllFiles) {
                  self.proceedUploadingFile(scopeName);
                } else {
                  processedFile.file.$submit();
                }
              }
            }, function(res) {
              var text;
              if (res.status === 401) {
                text = $filter('translate')('exceptionNoAuthentication', {
                  componentCode: 'Public'
                });
              } else if (res.status === 409) {
                text = $filter('translate')('characterConflict', {
                  componentCode: 'Public'
                });
              } else {
                text = $filter('translate')('exceptionInternalServices', {
                  componentCode: 'Public'
                });
              }

              self.onErrorWhenSending(scopeName, null, res.data, text);

            }, {
              doNotAskAgainOnConflict: true
            });
          } else {
            $alert({
              content: $filter('translate')('noFileSelected', {
                componentCode: 'Public'
              }),
              type: 'warning'
            });
          }
        };

        this.resetStatus = function(scopeName) {
          self.data[scopeName].status = 'WAITING';
        };

        this.onFailOnAddingFile = function(e, data) {
          var scopeName = data.scope.name;

          if (angular.isObject(self.data[scopeName])) {

            angular.forEach(data.files, function(file) {
              var index = self.data[scopeName].getIndexFileModelContainFile(file);
              if (index !== null) {
                self.data[scopeName].files.all.splice(index, 1);

                $rootScope.$broadcast('deleteFileInUploader', {
                  scopeName: scopeName,
                  file: file
                });
              }

            });

            if (angular.isFunction(self.data[scopeName].callbacks.onErrorAddingFile)) {
              self.data[scopeName].callbacks.onErrorAddingFile(e, data);
            }
          }
        };

        this.clearListFile = function(scopeName) {
          self.data[scopeName].clearListFile();
        };

        this.checkAllFilesSendWithoutErrors = function(scopeName) {
          return self.data[scopeName].checkAllFilesSendWithoutErrors();
        };

        this.allFilesSended = function(scopeName) {
          return self.data[scopeName].allFilesSended();
        };

        this.addHeaderToServiceSendingFile = function(data) {
          parentMethod.addHeaderToServiceSendingFile(data);

          data.headers['Content-MD5'] = self.data[data.scope.name].getProcessedFile().file.md5;
        };

        this.areFileToSend = function(scopeName) {
          return self.data[scopeName].areFileToSend();
        };

        this.getProcessedFileIndex = function(scopeName) {
          return self.data[scopeName].processedFileIndex;
        };

        this.downloadFile = function(scopeName, file) {
          var fileModel = self.data[scopeName].getFileModelContainFile(file);
          ihestiaDownloadHelper.download(fileModel.name, fileModel.fileServerId);
        };

        this.setSelectedDocumentType = function(scopeName, selectedDocumentType) {
          self.data[scopeName].selectedDocumentType = selectedDocumentType;
        };

        this.getSelectedDocumentType = function(scopeName) {
          return self.data[scopeName].selectedDocumentType;
        };

        this.areUploadedFilesWithoutErrors = function(scopeName) {
          return self.data[scopeName].areUploadedFilesWithoutErrors();
        };

        this.onFileUploadDone = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            self.copyFileToUploaded(scopeName, 'SUCCESS', null, data.result.fileServerId);

            if (angular.isFunction(self.data[scopeName].callbacks.onFileUploadDone)) {
              self.data[scopeName].callbacks.onFileUploadDone(e, data);
            }

            if (!self.data[scopeName].config.oneFile) {
              self.proceedUploadingFile(scopeName);
            } else {
              self.onAllFilesSended(scopeName);
            }
          }
        };

        this.onErrorWhenSending = function(scopeName, e, data, messages, status) {
          var message = self.parseMessages(messages);

          if (angular.isFunction(self.data[scopeName].callbacks.onErrorSendingFile)) {
            self.data[scopeName].callbacks.onErrorSendingFile(e, data, message);
          }

          self.copyFileToUploaded(scopeName, status ? status : 'ERROR', message);
          self.proceedUploadingFile(scopeName);
        };

        /**
         * [afterAllFilesSended wywolane po wyslaniu wszystkich plikow, poprawnie badz nie]
         * @return {[type]} [description]
         */
        this.afterAllFilesSended = function(scopeName, uploadedFiles) {
          $rootScope.$broadcast('hideBlockUi');
          if (angular.isFunction(self.data[scopeName].callbacks.onAllFileSend)) {
            var datas = {
              withoutError: self.checkAllFilesSendWithoutErrors(scopeName),
              files: uploadedFiles
            };
            self.data[scopeName].callbacks.onAllFileSend(datas);
          }
          if (self.data[scopeName].status !== 'ERROR') {
            self.resetStatus(scopeName);
          }

          if (self.data[scopeName].windowUploadClose) {
            self.clearUploadData(scopeName);
          }
        };

         /**
         * [deleteFile usuwa tylko z plikow do wyslania]
         * @param  {[type]} scopeName [description]
         * @param  {[type]} idx       [description]
         * @return {[type]}           [description]
         */
        this.doDeleteFile = function(scopeName, idx, files) {
          if (self.isActive(scopeName) && self.data[scopeName].status !== 'pending') {
            $rootScope.$broadcast('deleteFileInUploader', {
              file: files[idx].file,
              scopeName: scopeName
            });

            files.splice(idx, 1);

            //tutaj jeszcze sprawdzenie czy plik wysłany i jak coś wywołać usługę do usuwania plików, przed tym należy dodąć fileServerId
          }
        };
      };

      return AbstractListUploadHelper;
    }
  ]);