/**
 * Serwis do logowania aktywności w ikoncie
 * https://ebok.atena.pl/browse/IHESTIACL-1198
 */
angular.module('ihestiaRestServicesBase')
  .service('ihestiaSessionCurrenticSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IhestiaSessionCurrenticSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'currentic';
        this.businessArea = 'session';
      };

      return new IhestiaSessionCurrenticSvc();
    }]);