angular.module('salesPath2')
  .directive('nonEtxType', ['availabilityHelper', 'dictionaryServiceHelper',
    function(availabilityHelper, dictionaryServiceHelper) {
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else if (availabilityHelper.isAvailable('nonEtxType')) {
            return 'salesPath2Template/autocompleters/vehicle/nonEtxType/nonEtxType.tpl.html';
          } else {
            return 'salesPath2Template/autocompleters/vehicle/nonEtxType/nonEtxTypeWithoutDict.tpl.html';
          }
        },
        controller: ['$scope', function($scope) { //eslint-disable-line no-unused-vars

          if (availabilityHelper.isAvailable('nonEtxType')) {
            $scope.getVehicleType = function(query) {
              $scope.loadingElements = true;
              var obj = [];

              return dictionaryServiceHelper.getVehicleTypesService().find({
                ProductCode: 'H1H7V_OBJECT_VEHICLE',
                registrationTypeCode: $scope.modalData.formData.dependencySelects.category[1],
                modelCode: $scope.modalData.formData.dependencySelects.model[1],
                value: query
              }).then(function(res) {
                if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                  obj = [];
                } else {
                  $scope.loadingElements = false;
                  if (angular.isDefined(res)) {
                    angular.forEach(res.data.items, function(item) {
                      obj.push(item);
                    });
                  }
                }
                return obj;
              }, angular.noop);
            };
          }
        }]
      };
    }
  ]);