angular.module('salesPath2')
  .controller('communicationPersonsModalCtrl', ['$scope', 'CommunicationPersonsHelperClass', 'ihestiaConfigHelper', 'mainDataContainer', 'communicationPersonsModalHelper', 'actionHelper',
    function($scope, CommunicationPersonsHelperClass, ihestiaConfigHelper, mainDataContainer, communicationPersonsModalHelper, actionHelper) { // eslint-disable-line angular/di

      $scope.dataContainer = mainDataContainer;

      $scope.modalHelper = communicationPersonsModalHelper;

      $scope.communicationPersonsHelper = null;

      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.init = function() {
        $scope.communicationPersonsHelper = new CommunicationPersonsHelperClass();
        $scope.setModalTitle();
        $scope.fireListeners();

        actionHelper.registerHelper('communicationPersonsHelper', $scope.communicationPersonsHelper, [
          'tarifficationEnded'
        ]);

        $scope.$on('$destroy', function() {
          actionHelper.unregisterHelper('communicationPersonsHelper');
        });
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // Dodaj wspólwłaściciela
        $scope.$on('iHestiaCommonModalOkBtnClicked.communicationPersonsModal', function() {
          $scope.modalHelper.showedPersonBMFromList = false;
          actionHelper.runAction('saveApplication');
        });
      };


      $scope.setModalTitle = function() {
        $scope.modalHelper.setModalTitle();
      };

      $scope.showBonusMalusPopup = function(personId) {
        $scope.modalHelper.showedPersonBMFromList = true;
        $scope.communicationPersonsHelper.showBonusMalusPopup(personId, false);
      };

      $scope.removePerson = function(personId) {
        $scope.communicationPersonsHelper.removePerson(personId);
        $scope.modalHelper.showedPersonBMFromList = false;
      };

      $scope.editPerson = function(personId) {
        $scope.communicationPersonsHelper.editPerson(personId);
      };

      $scope.addCoowner = function() {
        $scope.modalHelper.addCoowner();
      };

      /**
       * callback on "no driving licence" checkbox change
       * @param {Object} person plain data from LsnPersonModelV1
       */
      $scope.noDrivingLicenceChanged = function(person) {
        if (person.dynamicValues.noDrivingLicence) {
          person.dynamicValues.drivingLicenceIssueDate = null;
        }
      };

      $scope.init();

    }
  ]);