//trzeba pomyslec jak czyscic te listy
angular.module('ihestiaCommonDirectives')
  .service('ihestiaFirstInfoOneListUploaderHelper', ['IhestiaOneListUploadHelper',
    function(IhestiaOneListUploadHelper) {
      var FirstInfoOneListUploaderHelper = function() {
        IhestiaOneListUploadHelper.apply(this, arguments);
        var parentMethod = {
          init: this.init
        };
        this.init = function(scopeName, callbacks, uplodedFiles, config) {
          if (!angular.isObject(config)) {
            config = {};
          }
          config.firstCheckAllFiles = true;
          parentMethod.init(scopeName, callbacks, uplodedFiles, config);
        };
      };

      return new FirstInfoOneListUploaderHelper();
    }
  ]);