angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionPostCodesCtrl', ['$scope', 'IhestiaPzaPostCodeConditionDefinitionModel',
    function($scope, IhestiaPzaPostCodeConditionDefinitionModel) {
      $scope.excludeOptions = {
        type: 'exclude',
        postCodes: []
      };

      $scope.includeOptions = {
        type: 'include',
        postCodes: []
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        // mapowanie z edycji (czyli to, co w bazie na interfejs)
        angular.forEach($scope.conditionData.postCodeExcludeList, function(excludedPostCodeObj){
          $scope.excludeOptions.postCodes.push(excludedPostCodeObj.postCode);
        });
        angular.forEach($scope.conditionData.postCodeIncludeList, function(includedPostCodeObj){
          $scope.includeOptions.postCodes.push(includedPostCodeObj.postCode);
        });

        // mapowanie z interfejsu na to, co do bazy
        $scope.$watch('includeOptions.postCodes', function(postCodes){
          $scope.conditionData.postCodeIncludeList = $scope.getPostCodeObjects(postCodes);
        }, true);
        $scope.$watch('excludeOptions.postCodes', function(postCodes){
          $scope.conditionData.postCodeExcludeList = $scope.getPostCodeObjects(postCodes);
        }, true);
      };

      /**
       * Zamiana kodów pocztowych na obiekty
       * @param  {array} postCodes ['77-300', '33-']
       * @return {array}           [description]
       */
      $scope.getPostCodeObjects = function(postCodes) {
        var objects = [];
        angular.forEach(postCodes, function(code){
          var model = new IhestiaPzaPostCodeConditionDefinitionModel();
          model.set('postCode', code);
          objects.push(model.getData());
        });

        return objects;
      };

    }
  ]);