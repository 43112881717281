angular.module('ihestiaCommonDirectives.uploaderV2')
  .directive('lsnCommonUploadV2', function() {
    return {
      restrict: 'E',
      templateUrl: function(elem, attrs) {
        return attrs.drvTemplate || 'ihestia-commondirectives-template-app/directives/uploaderV2/templates/uploaderV2.tpl.html';
      },
      scope: {
        options: '=?',
        getApi: '=?'
      },
      controller: 'lsnCommonUploadV2Ctrl'
    };
  });
