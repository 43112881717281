angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorImportHelper', ['$rootScope', '$filter', 'ihestiaSsoBaseInfoHelper', 'iHestiaCommonModalHelper',
    function($rootScope, $filter, ihestiaSsoBaseInfoHelper, iHestiaCommonModalHelper) {

      var ImportHelper = function() {
        $rootScope.ihestiaIkomunikatorImportModalSettings = {
          title: 'Importuj',
          okBtnName: 'Importuj',
          okBtnDisabled: ihestiaSsoBaseInfoHelper.isUserRelogged(),
          cancelBtnName: $filter('translate')('Public.cancel'),
          size: 'sm'
        };

        /**
         * Pokazujemy modal wyboru typu rodzaju korespondencji
         * @param {object|undefined} options - dodatkowe ustawienia
         * @return {[type]} [description]
         */
        this.showModal = function() {
          iHestiaCommonModalHelper.showModal('ihestiaIkomunikatorImport');
        };

        /**
         * Ukrywamy modal wyboru typu rodzaju korespondencji
         * @return {[type]} [description]
         */
        this.hideModal = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorImport');
        };

      };

      return new ImportHelper();
    }
  ]);