angular.module('ihestiaWidgets.ikonfigurator')
  .factory('ikonfiguratorOfferModalHelper', ['ihestiaCommonErrorHandler', 'ikonfiguratorHelper', 'ikonfiguratorConstants', 'base64',
    function(ihestiaCommonErrorHandler, ikonfiguratorHelper, ikonfiguratorConstants, base64) {
      var IkonfiguratorOfferModalHelper = function() {
        var self = this;
        this.proposedProducts = [];
        this.dataSetNumber = 0;


        /**
         * Zwracamy pełne adresy url wraz z zaszytymi w nich ustawieniami
         * @return {string[]}
         */
        this.getProductsUrls = function() {
          var urls = [];
          angular.forEach(self.proposedProducts, function(product) {
            var url = ikonfiguratorHelper.productUrls[product.productCode],
              variants = [];

            // musimy mieć ustawiony główny adres aplikacji
            if (!url) {
              ihestiaCommonErrorHandler.throwException('url for product ' + product.productCode + ' is not set!');
            }
            url = url + '?action=new';

            // zbieramy parametry do urla
            angular.forEach(product.risks, function(risk) {
              variants.push(risk.code + (risk.variant ? '_' + risk.variant : ''));
            });
            if(variants.length > 0){
              url = url + '&risks=' + variants.join(',');
            }
            variants = [];
            angular.forEach(product.additions, function(addition) {
              var additionString = addition.code;
              if(addition.variant || addition.variantCode) {
                additionString = additionString + '_' + (addition.variantCode ? addition.variantCode : addition.variant);
              }

              if (addition.riskVariants) {
                // np. OUZ_AC OUZ_OC
                angular.forEach(addition.riskVariants, function(riskCode){
                  variants.push(additionString + '_' + riskCode);
                });
              } else {
                variants.push(additionString);
              }
            });
            if(variants.length > 0){
              url = url + '&adds=' + variants.join(',');
            }

            // @ugly hardkod dla es
            if(product.productCode === 'es') {
              url = url + '&risks=REHABILITATION_2';
            }

            // info odnośnie klienta
            if (ikonfiguratorHelper.nbkId || ikonfiguratorHelper.personData.pesel) {
              var personData = base64.encode(angular.toJson(ikonfiguratorHelper.personData)).base64UrlEncode();
              url = url + '&nbkId=' + ikonfiguratorHelper.nbkId + '&personData=' + personData;
            }

            urls.push(url);
          });

          return urls;
        };

      };

      return new IkonfiguratorOfferModalHelper();
    }]);