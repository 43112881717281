angular.module('lsnBase.filters')
  .filter('lcFirst', function(){
    return function(inputValue) {
      if(typeof inputValue !== 'string' || inputValue === ''){
        return inputValue;
      }
      if(inputValue.length === 1)
      {
        return inputValue.toLowerCase();
      }
      return inputValue.charAt(0).toLowerCase() + inputValue.substring(1);
    };
});