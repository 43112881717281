angular.module('lsnBase.models')
  .factory('LsnPolicyDiscountModelConstants', ['LSNCONSTANTS', function(LSNCONSTANTS)
  {
    var PolicyDiscountModelConstants = {};

    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY = {};
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.FIRE = LSNCONSTANTS.PRODUCT_OGI;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.BURGLARY = LSNCONSTANTS.PRODUCT_KRA;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.PRIVATE_LIABILITY = LSNCONSTANTS.PRODUCT_OCZP;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.INDIVIDUAL_NW = LSNCONSTANTS.PRODUCT_NNW;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.AC = LSNCONSTANTS.RISK_AC;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.DRIVER_PASSENGER_NW = LSNCONSTANTS.RISK_NNWKIP;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.OC = LSNCONSTANTS.RISK_OC;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.GREEN_CARD = LSNCONSTANTS.ADD_GREENCARD;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.REHABILITATION = LSNCONSTANTS.PRODUCT_REHABILITATION;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.SPORT_ASSISTANCE = LSNCONSTANTS.PRODUCT_SPORT_ASSISTANCE;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.BAGGAGE = LSNCONSTANTS.PRODUCT_BAGGAGE;
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY.MEDICAL_EXPENSES = LSNCONSTANTS.PRODUCT_MEDICAL_EXPENSES;

    PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_AMOUNT = 'amount';
    PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_PERCENT = 'percent';
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_UNIT = {};
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_UNIT[PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_AMOUNT] = 'amount';
    PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_UNIT[PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_PERCENT] = 'percent';

    return PolicyDiscountModelConstants;
  }]);