angular.module('salesPath2')
  /**
   * resourcy dla aplikacji
   * @return {Object}
   */
  .service('RESOURCES', ['ResourcesBuilder',
    function(ResourcesBuilder) {

      return ResourcesBuilder.currentResources;
    }
  ]);