angular.module('salesPath2')
  .controller('vehicleEtxSearchModalCtrl', ['$scope', '$state', 'CONSTANTS', '$timeout', 'vehicleHelper', 'vehicleModalHelper', 'CONFIG', 'vehicleCepHelper', 'cepSvc',
    function($scope, $state, CONSTANTS, $timeout, vehicleHelper, vehicleModalHelper, CONFIG, vehicleCepHelper, cepSvc) {
      $scope.searchModalParams = vehicleHelper.searchModalParams;
      $scope.searchStock = 0;
      $scope.freshModal = true;
      $scope.loadInProgress = false;
      $scope.cepData = {}; // -> vehicleCepHelper.data, initialized in $scope.init()
      $scope.cepHelper = vehicleCepHelper;
      $scope.noCepResults = false;
      $scope.cepSearching = false; // true during CEP api call

      $scope.yearSettings = {
        decimals: 0,
        allowLeadingZeros: false,
        numericFormat: false
      };

      $scope.activeSorter = '';
      $scope.reverseSort = false;
      $scope.selectedVehiclesCount = 0;
      $scope.searchStock = 0;
      $scope.nextPageAvailable = false;
      $scope.scrolledModal = false;

      $scope.init = function() {
        $scope.freshModal = true;
        $scope.cepData = vehicleCepHelper.initData();
        vehicleModalHelper.switchCepModule(vehicleCepHelper.data.enabled);
        $scope.fireListeners();
        $scope.searchModalParams = vehicleHelper.searchModalParams;
        $scope.compareButtonVisibility();
        $scope.refreshCepBtnState();
      };

      $scope.fireListeners = function() {

        // porównanie pojazdów
        $scope.$on('iHestiaCommonModalFourthBtnClicked.vehicleEtxSearchModal', function() {
          $scope.compareVehicles();
        });

        // CEP search
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleEtxSearchModal', function($event) {
          $event.preventDefault();
          $scope.noCepResults = false;
          $scope.freshModal = true;
          $scope.cepSearching = true;
          $scope.resetCepRelatedData();
          vehicleModalHelper.disableCepBtn();
          var body = {
            vin: $scope.cepData.form.searchByVin ? $scope.cepData.form.vin : null,
            licensePlate: !$scope.cepData.form.searchByVin ? $scope.cepData.form.registration : null
          };
          cepSvc.search(body).then(function(resp) {
            $scope.cepSearching = false;
            vehicleModalHelper.enableCepBtn();
            if (resp.data) {
              var phrase = vehicleCepHelper.getSearchPhrase(resp.data);
              $scope.searchModalParams.searchParams.productionYear = resp.data.input.productionYear;
              $scope.searchModalParams.searchParams.value = phrase;
              vehicleCepHelper.responseData = resp.data;
              $scope.newSearch();
            }
          }, function() {
            // displays "no results" info in search results area
            $scope.freshModal = false;
            $scope.noCepResults = true;
            $scope.cepSearching = false;
          });
        });

        $scope.$watch('searchModalParams.searchParams', function() {
          $scope.searchModalParams.searchId = null;
        });

        $scope.$watch('searchModalParams.selectedVehicles', function() {
          $scope.compareButtonVisibility();
        }, true);

        // pojazd NonETX
        $scope.$on('iHestiaCommonModalThirdBtnClicked.vehicleEtxSearchModal', function() {
          vehicleModalHelper.chooseNonEurotaxVehicle();
        });

        //anulowanie - jeżeli jesteśmy na stronie komunikacji to wracamy do MATRIXa
        $scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleEtxSearchModal', function() {
          vehicleHelper.clearSearchModalData();
          vehicleCepHelper.clearSearchModalData();

          var selectedVehivleId = vehicleHelper.getSelectedVehicleId();

          if (CONFIG.BEHAVIOR.isSingleProduct && selectedVehivleId === '0') {
            //musi być jakiś wybrany
            vehicleModalHelper.showEtxSearchVehicleModal();
          } else if (selectedVehivleId === '0') {
            $state.go(CONSTANTS.PAGE_MATRIX);
          }
        });

        $scope.$on('loadNextVehicles', function(event, data) {
          $scope.scrolledModal = true;
          $scope.dataReachedTop = data.reachedTop;

          if (!$scope.loadInProgress) {
            if ($scope.dataReachedTop && !$scope.nextPageAvailable) {
              vehicleModalHelper.enableNonETXBtn();
            } else {
              vehicleModalHelper.disableNonETXBtn();
            }

            if ($scope.nextPageAvailable && data.reachedTop) {
              $scope.getNextSearchPage();
            }
          }
        });
      };

      $scope.compareButtonVisibility = function() {
        $scope.selectedVehiclesCount = $scope.getSelectedVehicles().length;
        if ($scope.selectedVehiclesCount >= 8 || $scope.selectedVehiclesCount <= 1) {
          vehicleModalHelper.disableCompareButton();
        } else {
          vehicleModalHelper.enableCompareButton();
        }
      };

      /**
       * Sortowanie wyników
       */
      $scope.sortBy = function(sorter) {
        $scope.activeSorter = sorter;
        $scope.reverseSort = !$scope.reverseSort;
        var sortDirection = 'ASC';
        if ($scope.reverseSort === true) {
          sortDirection = 'DESC';
        }

        vehicleHelper.searchParams.sortBy = sorter;
        vehicleHelper.searchParams.sortDirection = sortDirection;
        $scope.newSearch();
      };

      $scope.compareVehicles = function() {
        vehicleModalHelper.compareVehicles($scope.getSelectedVehicles());
      };

      $scope.getSelectedVehicles = function() {
        var ids = [];
        angular.forEach($scope.searchModalParams.selectedVehicles, function(isSelected, vehicleId) {
          if (isSelected) {
            ids.push(vehicleId);
          }
        });
        return ids;
      };

      $scope.newSearch = function() {
        $scope.searchStock += 1;
        var thisSearch = angular.copy($scope.searchStock);
        $timeout(function() {
          if ($scope.searchModalParams.searchParams.value.length >= 3 && thisSearch === $scope.searchStock) {
            $scope.searchModalParams.selectedVehicles = [];
            $scope.loadInProgress = true;
            $scope.dataReachedTop = true;
            vehicleHelper.searchVehicles($scope.searchModalParams.searchParams, function(response) {
              $scope.searchModalParams.searchId = response.id;
              $scope.nextPageAvailable = response.nextPageAvailable;
              $scope.currentPage = response.pageNo;
              $scope.searchModalParams.vehicles = [];
              $scope.addVehiclesData(response.vehicles);
              $scope.freshModal = false;
              $scope.scrolledModal = false;
              $scope.loadInProgress = false;

              if ($scope.searchModalParams.vehicles.length === 0) {
                vehicleModalHelper.enableNonETXBtn();

              }
              if (angular.element('#scrollableBody')[0]) {
                angular.element('#scrollableBody')[0].scrollTop = 0;
              }
            });
          }
        }, 500);
      };

      /**
       * Pobranie kolejnej strony wyszukiwania
       */
      $scope.getNextSearchPage = function() {
        $scope.loadInProgress = true;

        vehicleHelper.getSearchVehiclesPage($scope.searchModalParams.searchId, $scope.currentPage + 1, function(response) {
          $scope.nextPageAvailable = response.nextPageAvailable;
          $scope.currentPage = response.pageNo;
          $scope.addVehiclesData(response.vehicles);
          $scope.loadInProgress = false;
        });
      };

      /**
       * Wybieramy pojazd
       * @param  {int} etxId [description]
       */
      $scope.chooseVehicle = function(etxId) {
        if (!$scope.searchModalParams.searchParams.productionYear) {
          return false;
        }
        vehicleHelper.loadVehicle(etxId, function(vehicleModel) {
          vehicleModel.set('productionYear', $scope.searchModalParams.searchParams.productionYear);
          vehicleCepHelper._setCepDataForVehicle(vehicleModel);
          vehicleModel.metaData.set('id', null);
          if (angular.isDefined($scope.dataContainer.vehicles[$scope.searchModalParams.replaceClientId])) {
            var oldVehicle = $scope.dataContainer.vehicles[$scope.searchModalParams.replaceClientId];
            //przepisujemy czesc additionalData z aktualnego pojazdu
            angular.forEach(vehicleHelper.additionalDataToRewrite, function(attr) {
              vehicleModel.setAdditionalData(oldVehicle.getAdditionalData(attr), attr);
            });
          }
          vehicleModalHelper.vehicleChosen(vehicleModel, $scope.searchModalParams.replaceClientId);
        });
        return true;
      };

      // $scope._setCepDataForVehicle = function(vehicleModel) {
      //   if (!vehicleCepHelper.data.enabled || !vehicleCepHelper.data.form.active) {
      //     return false;
      //   }
      //   if ($scope.cepData.form.searchByVin) {
      //     vehicleModel.set('vin', $scope.cepData.form.vin);
      //   } else {
      //     vehicleModel.set('registration', [$scope.cepData.form.registration]);
      //   }
      //   vehicleModel.set('firstRegistration', _.get(vehicleCepHelper.responseData, 'responseVehicleExtendedData.firstRegistrationDate', null));
      //   vehicleModel.set('mileage', _.get(vehicleCepHelper.responseData, 'responseVehicleExtendedData.mileage', null));
      //   var rightSideSteeringWheel = _.get(vehicleCepHelper.responseData, 'responseVehicleExtendedData.rightSideSteeringWheel', null);
      //   vehicleModel.setAdditionalData($scope._cepRightSideSteeringWheelValue(rightSideSteeringWheel), 'rightHandDrive');
      //   vehicleModel.set('vin', _.get(vehicleCepHelper.responseData, 'responseVehicleExtendedData.vin', null));
      //   var registration = _.get(vehicleCepHelper.responseData, 'responseVehicleExtendedData.registrationNo', null);
      //   if (registration) {
      //     vehicleModel.set('registration', [registration]);
      //   }
      //   return true;
      // };

      $scope.refreshCepBtnState = function() {
        if (!(vehicleCepHelper.data.enabled && vehicleCepHelper.data.form.active)
          || ($scope.cepData.form.searchByVin && !_.trim($scope.cepData.form.vin))
          || (!$scope.cepData.form.searchByVin && !_.trim($scope.cepData.form.registration))
        ) {
          vehicleModalHelper.disableCepBtn();
        } else {
          vehicleModalHelper.enableCepBtn();
        }
      };

      // $scope._cepRightSideSteeringWheelValue = function(cepValue) {
      //   switch (cepValue) {
      //     case 'T':
      //       return true;
      //     case 'N':
      //       return false;
      //     default:
      //       return null;
      //   }
      // };

      /**
       * Dodajemy do naszej tablicy "czyste dane" na potrzeby szablonu
       * @param {vehicleModel[]} vehicleModels [description]
       */
      $scope.addVehiclesData = function(vehicleModels) {
        angular.forEach(vehicleModels, function(vehicleModel) {
          $scope.searchModalParams.vehicles.push(vehicleModel.getData(true, true));
        });
      };

      $scope.resetCepRelatedData = function() {
        $scope.searchModalParams.searchParams.productionYear = '';
        $scope.searchModalParams.searchParams.value = '';
        $scope.searchModalParams.vehicles = [];
        $scope.noCepResults = false;
        $scope.freshModal = true;
        vehicleCepHelper.responseData = null;
        $scope.refreshCepBtnState();
      };

      $scope.init();

    }
  ]);
