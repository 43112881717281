angular.module('salesPath2')
  .controller('nnwCtrl', ['$scope', 'nnwHelper', 'personModalHelper', 'sp2CommonHelper', 'resourceTooltipModalHelper', '$filter', 'dataContainerHelper',
    function($scope, nnwHelper, personModalHelper, sp2CommonHelper, resourceTooltipModalHelper, $filter, dataContainerHelper) {
      $scope.tplData = nnwHelper.tplData;
      $scope.headerSettings = {}; //ustawienia nagłówka
      $scope.nnwHelper = nnwHelper;
      $scope.activeProductList = [$scope.CONSTANTS.PRODUCT_NNW];
      //ustawienia dyrektywy common-numeric
      $scope.numericSettings = {
        decimals: 0,
        groupSeparator: ' ',
        min: 0,
        max: 5000000,
        allowLeadingZeros: false,
        parseToString: false,
        formatting: true,
        selectOnFocus: true
      };

      $scope.init = function() {
        nnwHelper.initData();
        $scope.initHeaderSettings();
        if ($scope.tplData.nnwInsured.length === 0) {
          $scope.showAddPersonModal();
        }
      };

      /**
       * inicjalizacja ustawien dla naglowka
       */
      $scope.initHeaderSettings = function() {
        if (!$scope.PAGE_CONFIG.NNW.header.enabled) {
          return;
        }
        $scope.headerSettings = angular.copy($scope.PAGE_CONFIG.NNW.header);
        if ($scope.CONFIG.BEHAVIOR.propertyOneProtectionPeriod) { //wspólne daty dal mienia i osobowych
          $scope.headerSettings.protectionDates.ref = 'propertyProtectionDates';
        } else {
          $scope.headerSettings.protectionDates.ref = 'nnwProtectionDates';
        }
        //wszystkie ustawienia oprócz "enabled" są zgodne z sp2HeaderHelper.DEFAULT_HEADER_SETTINGS
        delete $scope.headerSettings.enabled;
      };

      /**
       * modal dodawania osoby
       */
      $scope.showAddPersonModal = function() {
        personModalHelper.openPopupAddPerson({
          context: $scope.CONSTANTS.PRODUCT_NNW,
          title: $filter('translate')('sp2.modal.personAddModal.editHeader', {componentCode: 'sp2'}),
          titleSide: $filter('translate')('modal.matrix.addPersonSide', {componentCode: 'sp2'}),
          excludeIds: Object.keys(dataContainerHelper.getPersonsForPersonalProduct($scope.CONSTANTS.PRODUCT_NNW)),
          showNnwIncognito: $scope.dataContainer.nnwIncognito === null && $scope.CONFIG.BEHAVIOR.nnwIncognito
        });
      };

      //otwarcie modala edycji osoby
      $scope.showEditPersonModal = function(clientId) {
        personModalHelper.openPopupEditPerson({
          id: clientId,
          editHeader: $filter('translate')('insured', {componentCode: 'Public'})
        });
      };

      //usuwanie osoby z listy ubezpieczonych
      $scope.deletePerson = function(clientId) {
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
          text: [(clientId === $scope.CONSTANTS.NNW_INCOGNITO_PERSON_ID ? 'insuredPersonsDeletionConfirm' : 'insuredPersonDeletionConfirm'), {componentCode: 'sp2'}],
          okBtnCallback: function() {
            nnwHelper.deleteInsured(clientId);
          }
        });
      };

      /**
       * wyswietla modal z tooltipem
       * @param  {String} resourceName pozycja z RESOURCES
       */
      $scope.showTooltipModal = function(resourceName) {
        resourceTooltipModalHelper.showModal(resourceName);
      };

      $scope.init();
    }
  ]);