angular.module('salesPath2')
  .service('toRestRenewMapper', [ 'MapperUtils', 'CONSTANTS', 'resourceHelper',
    function(MapperUtils, CONSTANTS, resourceHelper) {
      var RenewMapper = function() {
        var self = this;
        /**
         * dla podanego nowego ryzyka szuka we wznawianym wniosku odpowiadającego ryzyka wznawianego
         * @param  {Application} renewedApplication
         * @param  {Risk} risk
         * @return {Risk|null}
         */
        this._matchRenewalRisk = function(renewedApplication, risk) {
          var riskDef = MapperUtils.findRiskDefByIdpm(risk.product.compId);
          return self.findRenewalRisk(renewedApplication, riskDef.productCode, riskDef.riskCode, risk.product.dynamicValues.coverageOption);
        };

        this.findRenewalRisk = function(renewedApplication, productCode, riskCode, variant) {
          var matchedRenewalRisk = null;
          lsnNg.forEach(renewedApplication.risks, function(renewalRisk) {
            var renewalRiskDef = MapperUtils.findRiskDefByIdpm(renewalRisk.product.compId);
            if (renewalRiskDef.productCode === productCode) {
              if (productCode === CONSTANTS.PRODUCT_OGI || productCode === CONSTANTS.PRODUCT_KRA) //badamy też zgodność riskCode
              {
                if (renewalRiskDef.riskCode === riskCode) {
                  matchedRenewalRisk = renewalRisk;
                  return false;
                }
              } else if (productCode === CONSTANTS.CONSTANTS.PRODUCT_NNW) //sprawdzamy wariant
              {
                if (renewalRisk.product.dynamicValues.coverageOption === variant) {
                  matchedRenewalRisk = renewalRisk;
                  return false;
                }
              } else {
                matchedRenewalRisk = renewalRisk;
                return false;
              }
            }
            
            return true; //continue
          });

          return matchedRenewalRisk;
        };

        /**
         * Sprawdzamy po pesel czy którakolwiek z osób ubezpieczonych na ryzyku jest osobą ze wznawianej polisy
         * @param  {Risk}  risk  z polisy
         * @return {Boolean}
         */
        this._isInsuredOld = function(dataManager, matchedRenewalRisk, newRisk) {
          var riskDef = MapperUtils.findRiskDefByIdpm(matchedRenewalRisk.product.compId);
          var peselsRenewed = [];
          if (riskDef.productCode === CONSTANTS.PRODUCT_NNW) //zbieramy pesele z wszystkich wznawianych ryzyk NNW
          {
            lsnNg.forEach(dataManager.renewedApplication.risks, function(renewalRisk) {
              var renewalRiskDef = MapperUtils.findRiskDefByIdpm(renewalRisk.product.compId);
              if (renewalRiskDef.productCode === CONSTANTS.PRODUCT_NNW) {
                peselsRenewed = peselsRenewed.concat(self.getSubjectPesels(dataManager.renewedApplication.persons, renewalRisk));
              }
            });
          } else {
            peselsRenewed = self.getSubjectPesels(dataManager.renewedApplication.persons, matchedRenewalRisk);
          }

          var newPesels = self.getSubjectPesels(dataManager.persons, newRisk);

          var matchFound = false;
          lsnNg.forEach(peselsRenewed, function(peselRenewed) {
            lsnNg.forEach(newPesels, function(newPesel) {
              if (peselRenewed === newPesel) {
                matchFound = true;
                return false;
              }
              return true; //continue
            });
            if (matchFound) {
              return false;
            }
            
            return true; //continue
          });

          return matchFound;
        };

        this._isObjectOld = function(dataManager, matchedRenewalRisk, newRisk) {
          var riskDef = MapperUtils.findRiskDefByIdpm(newRisk.product.compId);

          return self.isObjectOld(dataManager, matchedRenewalRisk, riskDef.productCode);
        };

        this.isObjectOld = function(dataManager, matchedRenewalRisk, productCode) {
          var isObjectOld = null,
            dynVals = matchedRenewalRisk.product.dynamicValues;
          //sprawdzamy typ (dla interfejsu) ryzyka
          var appRiskType;
          if (MapperUtils.isAddition(productCode)) {
            appRiskType = resourceHelper.getAddDef(productCode).TYPE;
          } else {
            appRiskType = resourceHelper.productType(productCode);
          }
          if (appRiskType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            isObjectOld = false;
            if (typeof dynVals.objectOld === 'boolean') {
              isObjectOld = dynVals.objectOld;
            }
          }
          return isObjectOld;
        };


        //pobranie peseli osób z wznawianego ryzyka
        /**
         * Zwraca listę peseli osób ubezpieczonych na wznawianym ryzyku
         * @param  {Array} persons
         * @param  {Risk} risk
         * @return {Array}
         */
        this.getSubjectPesels = function(persons, renewalRisk) {
          var pesels = [];
          if (typeof renewalRisk.insuredSubjectsRef === 'undefined' || renewalRisk.insuredSubjectsRef.length === 0) {
            return []; //nie było osób na ryzyku, więc uznajemy, że nie zmieniło się
          }

          lsnNg.forEach(renewalRisk.insuredSubjectsRef, function(subject) {
            var person = self._getPersonFromId(persons, subject.ref);
            if (person === null) //nie znaleziono osoby, prawdopodobnie subjectem jest organizacja
            {
              return true;
            }
            if (typeof person.pesel === 'undefined' || person.pesel === null || person.pesel === '') //osoba bez pesel, prawdopodobnie obcokrajowiec
            {
              return true;
            }
            pesels.push(person.pesel);
            
            return true; //continue
          });
          return pesels;
        };


      };
      return new RenewMapper();
    }
  ]);