/**
 * Abstrakcyjny helper, ktorego można dziedziczyć 
 * Najlepiej używać z kontrolerem modala dziedziczącym po IHestiaAbstractModalPromiseCtrl
 */
angular.module('ihestiaCommonDirectives')
  .factory('IHestiaAbstractModalPromiseHelper', ['$q', 'iHestiaCommonModalHelper', 'ihestiaCommonErrorHandler',
    function($q, iHestiaCommonModalHelper, ihestiaCommonErrorHandler) {
      var IHestiaAbstractModalPromiseHelper = function() {

        /**
         * Kod modala
         * @type {String}
         */
        this.modalCode = null;

        /**
         * Promise
         * @type {Promise}
         */
        this.promise = null;

        /**
         * Sposób zakończenia promise'a po zamknięciu modala
         * @type {String} reject|resolve
         */
        this.endPromiseAs = 'reject';

        /**
         * Dane zwracane przez zakończony promise
         * @type {mixed}
         */
        this.promiseData = null;

        /**
         * Dane dostarczone do modala
         * @type {mixed}
         */
        this.modalData = null;

        /**
         * Callback do resolve'a
         * @type {Function}
         */
        this.resolvePromise = null;

        /**
         * Callback do reject'a
         * @type {Function}
         */
        this.rejectPromise = null;

        /**
         * Czyszczenie danych po ukryciu modala
         * @return {Object} this;
         */
        this.clearData = function() {
          this.promise = null;
          this.endPromiseAs = 'reject';
          this.promiseData = null;
          this.modalData = null;
          this.rejectPromise = null;
          this.resolvePromise = null;

          return this;
        };

        /**
         * Pokazujemy modal
         * @param  {mixed} modalData dane przekazane do modala
         * @return {Object}           Promise
         */
        this.showModal = function(modalData) {
          var vm = this;
          if(this.modalCode === null) {
            ihestiaCommonErrorHandler.throwException('modalCode not set!');
          }
          this.modalData = modalData;
          iHestiaCommonModalHelper.showModal(this.modalCode);

          // zwracamy promise
          return $q(function(resolve, reject){
            vm.resolvePromise = resolve;
            vm.rejectPromise = reject;
          });
        };

        /**
         * Ukrywamy modal
         * @return {Object} this;
         */
        this.hideModal = function() {
          iHestiaCommonModalHelper.hideModal(this.modalCode);
          return this;
        };

        /**
         * Ustawiamy promise modala na rejected
         * Promise zostanie zakończony dopiero po zniszczeniu modala
         * @param {mixed} data dane do reject() na promise
         */
        this.setPromiseAsRejected = function(data) {
          this.promiseData = data;
          this.endPromiseAs = 'reject';
        };

        /**
         * Ustawiamy promise modala na resolve
         * Promise zostanie zakończony dopiero po zniszczeniu modala
         * @param {mixed} data dane do resolve() na promise
         */
        this.setPromiseAsResolved = function(data) {
          this.promiseData = data;
          this.endPromiseAs = 'resolve';
        };

        /**
         * Modal został zniszczony, należy spełnić obietnicę (promise)
         * i po sobie posprzątać
         * @return {Object} this;
         */
        this.modalDestroyed = function() {
          if(this.endPromiseAs === 'resolve') {
            this.resolvePromise(this.promiseData);
          } else {
            this.rejectPromise(this.promiseData);
          }
          this.clearData();
          return this;
        };
      };


      return IHestiaAbstractModalPromiseHelper;
    }
  ]);