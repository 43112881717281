angular.module('ihestiaWidgets.policyTermination')
  .factory('customerEstatesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var CustomerEstatesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'customers';
        this.businessArea = 'insurance';
        this.searchesPostfix = 'policies/property/searches';
        this.pageStyle = true;
        this.sortBy = '';
      };

      return new CustomerEstatesSvc();
    }]);