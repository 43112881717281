angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskReceiverModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper', 'iHestiaCommonModalHelper', '$timeout', 'ihestiaDashboardOperatorChooserHelper',
    function($scope, ihestiaDashboardNewTaskHelper, iHestiaCommonModalHelper, $timeout, ihestiaDashboardOperatorChooserHelper) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;

      $scope.formData = {
        submitted: false
      };
      $scope.operatorChooserData = {};
      $scope.chooseOperatorApi = {};

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        // zatwierdzenie danych
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaDashboardNewTaskReceiver', function($event) {
          $event.preventDefault();
          $scope.formData.submitted = true;
          if ($scope.createTaskForm && $scope.createTaskForm.$valid && ihestiaDashboardOperatorChooserHelper.elementsLoaded($scope.operatorChooserData)) {
            $scope.taskData.operator = $scope.chooseOperatorApi.getOperatorData();

            // ukrycie modala
            iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskReceiver');
            $timeout(function() {
              ihestiaDashboardNewTaskHelper.showNextNewTaskModal();
            }, 0);
          }

        });
      };

      $scope.init();
    }
  ]);