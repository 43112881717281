angular.module('ihestiaCommonDirectives')
  .directive('inputPopover', ['$popover', '$timeout', '$parse', function($popover, $timeout, $parse) {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) {
        //submitted - ustawiana na true w momencie wyslania formularza, na false przy odpalaniu metody hide
        var focused = false;
        var invalid = false;
        var popover;
        var showOn = attrs.popoverShowOn;
        var submitted;
        var dirty;
        var shouldShow;

        var initPopover = function() {
          var options = {
            template: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            trigger: 'manual',
            placement: 'left'
          };

          angular.forEach(['template', 'contentTemplate', 'placement', 'container', 'target', 'delay', 'trigger', 'keyboard', 'html', 'animation', 'customClass', 'autoClose', 'id', 'content'], function(key) {
            if (angular.isDefined(attrs[key])) {
              options[key] = attrs[key];
            }
          });

          if (angular.isDefined(attrs.popoverPlacement))
          {
            //popoverPlacement jest mocniejszy od placement
            //użyteczne gdy potrzebujemy przykładowo input z datą gdzie zarówno kalendarz jak i popover zapisan się na placement
            options.placement = attrs.popoverPlacement;
          }

          if (angular.isDefined(attrs.popoverContainer))
          {
            //popoverContainer jest mocniejszy od container
            //użyteczne gdy potrzebujemy przykładowo input z datą gdzie zarówno kalendarz jak i popover zapisan się na placement
            options.container = attrs.popoverContainer;
          }

          if(angular.isUndefined(options.container))
          {
            if (angular.element(element).parent('.modal').length !== 0) {
              options.container = '.modal';
            } else {
              options.container = false;
            }
          }

          popover = $popover(element, options);
        };

        var checkShouldShow = function() {
          if (showOn === 'focused') {
            return (submitted || focused) && invalid;
          } else if (showOn === 'trigger') {
            return (attrs.popoverShowTrigger === 'true');
          } else {
            return (submitted || dirty) && invalid;
          }
        };

        var hideShowElement = function() {
          if (popover) {
            shouldShow = checkShouldShow();
            if (shouldShow) {
              popover.$promise.then(function() {
                $timeout(function() {
                  //w przypadku gdy mamy blur i trigger jednocześnie to flaga może się zmienić i nie wiemy co pierwsze wróci
                  // popover.$scope.content - nie ma sensu wyświetlać popovera, który jest pusty
                  if(shouldShow && popover.$scope.content)
                  {
                    popover.hide();
                    popover.$applyPlacement();
                    popover.show();
                  }
                }, 0);

              }, angular.noop);
            } else {
              popover.$promise.then(function() {
                if(!shouldShow)
                {
                  popover.hide();
                }
              }, angular.noop);
            }
          }
        };

        var listener = scope.$on('formSubmitted', function() {
          submitted = true;
          hideShowElement();
        });

        if (showOn !== 'focused') {
          element.on('blur', function() {
            dirty = true;
            hideShowElement();
          });
        } else {
          element.on('blur', function() {
            focused = false;
            submitted = false;
            hideShowElement();
          });

          element.on('focus', function() {
            focused = true;
            hideShowElement();
          });
        }

        attrs.$observe('popoverInvalid', function(value) {
          invalid = (value !== '') && !!$parse(attrs.popoverInvalid)(scope);
          if (showOn !== 'focused') {
            hideShowElement();
          }
        });

        attrs.$observe('popoverShowTrigger', function(newVal, oldVal) {
          if (newVal !== oldVal && showOn === 'trigger') {
            hideShowElement();
          }
        });


        angular.forEach(['title', 'content'], function(key) {
          attrs.$observe(key, function(newValue) {
            if (attrs[key]) {
              $timeout(function() {
                popover.$scope[key] = newValue;
                hideShowElement();
              }, 0);
            } else if (popover) {
                popover.$promise.then(function() {
                  popover.hide();
                }, angular.noop);
            }
          });
        });

        initPopover();

        scope.$on('$destroy', function() {
          listener();
          element.off('focus blur');
          if (scope) {
            $timeout(function() {
              scope.$destroy();
            }, 0);
          }
        });
      }
    };
  }]);