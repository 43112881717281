angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryEurotaxEquipmentSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryEurotaxEquipmentSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'eurotaxEquipment';
        this.searchesPostfix = 'searchesEquipment';

      };
      return new RestDictionaryEurotaxEquipmentSvc();
    }
  ]);