angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardObjectModelConstants', [function() {
    return {
      TYPE_CLAIM: 'CLAIM',
      TYPE_CLIENT: 'CLIENT',
      TYPE_OFFER: 'OFFER',
      TYPE_POLICY: 'POLICY',
      TYPE_WORKER: 'WORKER',
      TYPE_NONE: 'NONE',
      TYPE_APPLICATION: 'APPLICATION',
      TYPE_AGENT: 'AGENT'
    };
  }]);