angular.module('lsnBase.directives')
 .directive('lsnClickNoProp', ['$timeout', function($timeout){
   return {
     restrict: 'A', // E = Element, A = Attribute, C = Class, M = Comment
     scope: {
       'lsnClickNoProp': '&'
     },
     link: function($scope, iElm) {
       iElm[0].addEventListener('click', function($event){
        $timeout(function(){
          $scope.lsnClickNoProp();
        }, 0);

        $event.stopPropagation();
        $event.preventDefault();
       });
     }
   };
 }]);