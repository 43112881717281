angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaLifePersonHeader', [function() {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/personHeader/lifePersonHeader.tpl.html',
      restrict: 'E',
      scope: {
        subject: '=',
        canEdit: '=',
        role: '=',
        onEditSubject: '&',
        showElements: '='
      }
    };
  }]);
