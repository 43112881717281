angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryLastNamesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryLastNamesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'lastnames';
        this.sortBy = 'rank';

      };
      return new RestDictionaryLastNamesSvc();
    }
  ]);