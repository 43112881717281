angular.module('ihestiaCommonDirectives')
  .controller('ihestiaCommonHtmlHeadCtrl', ['$scope', '$timeout', function($scope, $timeout) {

    $scope.showFavicon = false;


    $scope.init = function() {
      //FIX na znikającą faviconę w FF (występuje gdy otwieramy nowy URL zawierający znaczek #)
      //domyślnie ikonę usuwamy z DOM'a i wstawiamy dopiero po załadowaniu strony      
      $timeout(function(){
        $scope.showFavicon = true;
      }, 0);
    };


    $scope.init();

  }]);