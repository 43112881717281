angular.module('ihestiaRestServicesBase')
  .factory('iodsLifeSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IodsLifeSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        this.resource = 'distributorLifeFiles';
        this.businessArea = 'iods';
      };

      return new IodsLifeSvc();
    }]);