angular.module('ihestiaCommonDirectives')
  .directive('ihestiaAttachmentElement', ['$timeout',
    function($timeout) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: function(elem,attrs){
          if(attrs.drvTemplate){
            return attrs.drvTemplate;
          }else{
            return 'ihestia-commondirectives-template-app/directives/elements/attachment/attachmentLinkWrap.tpl.html';
          }
        },
        scope: true,
        link: function($scope) {
            $timeout(function() {
              var fileNamePart = []; // NOSONAR
              var fileNameString;
              if(angular.isDefined($scope.fileModel)){
                fileNameString = $scope.fileModel.name;
              } else if($scope.file) {
                fileNameString = $scope.file.name;
              }

              if(fileNameString) {
                fileNamePart = fileNameString.split('.');

                var ext = fileNamePart.splice(fileNamePart.length - 1, 1);
                $scope.fileExtension = ext[0];
                $scope.fileName = fileNamePart.join('.');
              }
            });
        }
      };
    }]
  );