/**
 * Mapper atrybutów
 */
angular.module('salesPath2').service('fromRestAttributeMapper', ['lsnModelHelper', 'SPD', 'CONSTANTS',
  function(lsnModelHelper, SPD, CONSTANTS) {
    var FromRestAttributeMapper = function() {
      /**
       * rzutuje wartość atrybutu dla podanej klasy obiektu na podstawie SPD
       * @param  {String} objectName objectName klasy obiektu
       * @param  {String} attrName   nazwa atrybutu
       * @param  {*} attrValue  wartość atrybutu
       * @param  {String} riskCode kod ryzyka, jeśli objectName === 'Risk'
       * @return {*} sparsowana wartość atrybutu
       */
      this.castAttributeFor = function(objectName, attrName, attrValue, riskCode) {
        //przekodowanie nietypowych nazw wariantów
        //obecnie dotyczy to nazw wariantów dla dodatku Medical Assistance
        if (attrName === 'coverageOption') {
          switch(attrValue) {
            case CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_BASIC:
              attrValue = CONSTANTS.VARIANT_I;
              break;

            case CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_EXTENDED:
              attrValue = CONSTANTS.VARIANT_II;
              break;

            default:
          }
          return attrValue;
        }        
        if (angular.isString(riskCode)) { //korzystamy z SPD.risks
          if (angular.isUndefined(SPD.risks[riskCode]) || angular.isUndefined(SPD.risks[riskCode].attributes[attrName])) {
            return attrValue;
          }
          return lsnModelHelper.parseDynamicValueByDef(SPD.risks[riskCode].attributes[attrName], attrName, attrValue);
        } else { //korzystamy z SPD.objectsDynamicValues
          if (angular.isUndefined(SPD.objectsDynamicValues[objectName]) || angular.isUndefined(SPD.objectsDynamicValues[objectName][attrName])) {
            return attrValue;
          }
          return lsnModelHelper.parseDynamicValueByDef(SPD.objectsDynamicValues[objectName][attrName], attrName, attrValue);
        }
      };

      /**
       * rzutuje wartość atrybutu dla polisy na podstawie SPD
       * @param  {String} attrName   nazwa atrybutu
       * @param  {*} attrValue  wartość atrybutu
       * @param  {String} policyIdpm id pakietu wg spd
       * @return {*} sparsowana wartość atrybutu
       */
      this.castAttributeForPolicy = function(attrName, attrValue, policyIdpm) {
        var container = (SPD.salesProducts.Communication.idpm === policyIdpm) ? SPD.salesProducts.Communication.attributes : SPD.salesProducts.Property.attributes;
        if (angular.isDefined(SPD.allSalesProducts.attributes[attrName])) { //spd wspólne dla wszystkich pakietów
          container = SPD.allSalesProducts.attributes;
        }
        if (angular.isUndefined(container[attrName])) {
          return attrValue;
        }
        return lsnModelHelper.parseDynamicValueByDef(container[attrName], attrName, attrValue);
      };

    };

    return new FromRestAttributeMapper();
  }
]);