/**
 * tymczasowe tłumaczenia resposnów z usług
 * tylko na potrzeby prezentacji
 */

angular.module('ihestiaCommonDirectives.labelServiceHelper')
  .factory('labelServiceHelper', ['ihestiaConfigHelper', '$filter',
    function(ihestiaConfigHelper, $filter) {

    var LabelServiceHelper = function() {
      var self = this;

      this.isServiceTranslationActive = function()
      {
        return ihestiaConfigHelper.get('frontFlags', 'SERVICE_TRANSLATION') ? true : false;
      };

      /**
       * tłumaczenie alertów w ikoncie
       * @param  {array} alerts
       * @return {[type]}        [description]
       */
      this.translateAlerts = function(alerts)
      {
        if(self.isServiceTranslationActive())
        {
          angular.forEach(alerts, function(alert){
            if(angular.isUndefined(alert.baseHeader))
            {
              alert.baseHeader = alert.header; //odkładamy oryginalną wersję
            }
            if(alert.baseHeader.indexOf('Witamy w Ergo Hestii') !== -1)
            {
              alert.header = $filter('translate')('tmp.alertWelcome', {componentCode: 'Temp'});
            }
            if(alert.baseHeader.indexOf('Zaktualizowaliśmy dane na') !== -1 && alert.baseHeader.indexOf('Pobierz aktualną wersję polisy') !== -1)
            {
              alert.header = $filter('translate')('tmp.alertPolicyUpdate', {componentCode: 'Temp', params: {policyNumber: alert.relatedObject}});
            }
            if(alert.baseHeader.indexOf('Zarejestrowaliśmy nowa umowę ubezpieczenia') !== -1)
            {
              alert.header = $filter('translate')('tmp.alertNewPolicy', {componentCode: 'Temp', params: {policyNumber: alert.relatedObject}});
            }
          });
        }
      };

    };

    return new LabelServiceHelper();

  }]);