angular.module('salesPath2')
  .controller('summaryProductsClausesModalCtrl', ['$scope', 'summaryProductsClausesHelper',
    function($scope, summaryProductsClausesHelper) {
      $scope.modalData = summaryProductsClausesHelper.modalData;

      /**
      * init
      */
      $scope.init = function() {
        $scope.modalData.query = '';
        $scope.fireListeners();
      };

      $scope.expandClause = function(clause) {
        summaryProductsClausesHelper.expandClause(clause);
      };

      $scope.saveProductClauses = function(clause, policyNumber) {
        summaryProductsClausesHelper.saveProductClauses(clause, policyNumber);
      };

      /**
       * Odpalenie watchy
       */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.summaryProductsClausesModal', function() {
          summaryProductsClausesHelper.saveProductClauses();
        });
      };
      
      $scope.filterClause = function(clause) {
        if(!clause || !clause.code || !clause.data) {
          return false;
        }

        return clause.code.toLowerCase().indexOf($scope.modalData.query.toLowerCase() || '') !== -1
            || clause.data.name.toLowerCase().indexOf($scope.modalData.query.toLowerCase() || '') !== -1;
      };

      $scope.init();

    }
  ]);
