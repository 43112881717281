angular.module('ihestiaCommonDirectives')
  .provider('ihestiaLabelHelper', ['ihestiaConfigHelperProvider', 'lsnCookieProvider', 'labelActionHelperProvider',
    function(ihestiaConfigHelperProvider, lsnCookieProvider, labelActionHelperProvider) {
      var ihestiaConfigHelper = ihestiaConfigHelperProvider.getConfigHelper();
      var lsnCookie = lsnCookieProvider.getCookieHelper();

      var LabelHelper = function() {
        var self = this;

        this.labels = {};
        this.flatLabels = {};
        this.selectedLanguage = 'pl-PL';

        /**
         * Zostanie przypisane na runie
         * w .config nie mamy do tego dostępu
         * @type {[type]}
         */
        this.$sce = null;

        /**
         * Dostępne języki
         * @type {Array} ?
         */
        this.availableLanguages = ihestiaConfigHelper.get('AVAILABLE_LANGUAGE');

        var cookieName = ihestiaConfigHelper.get('cookieNames', 'SELECTED_LANGUAGE'),
          cookiesSettings = {
            'domain': ihestiaConfigHelper.get('crossTab').ROOT_DOMAIN
          };

        cookieName = cookieName ? cookieName : 'SELECTED_LANGUAGE';

        /**
         * Init
         */
        this.init = function() {
          if (ihestiaConfigHelper.get('availableComponents', 'changeLnguage')) {
            self.initSelectedLanguage();
          }

          self.initLabels();
        };

        /**
         * Ladujemy etykiety do pamieci
         * @return {LabelHelper} [description]
         */
        this.initLabels = function() {
          self.labels = APP_CONFIG.labels ? APP_CONFIG.labels : {}; // eslint-disable-line
          angular.forEach(self.labels, function(labels) {
            angular.extend(self.flatLabels, labels);
          });
          return self;
        };

        /**
         * Zwracamy etykiete
         * @param {string} labelCode kod etykiety
         * @param {string} componentCode kod komponentu
         * @return {IhestiaLabelModel} etykieta
         */
        this.getLabel = function(labelCode, componentCode) {
          if (componentCode && self.labels[componentCode]) {
            return self.labels[componentCode][labelCode];
          } else {
            // wsteczna kompatybilność
            return self.flatLabels[labelCode];
          }
        };

        /**
         * Pobieramy tlumaczenie
         * @param  {string} labelCode kod etykiety
         * @param {array} parameters parametry
         * @param  {string|undefined} langCode  kod jezyka
         * @param  {string|undefined} componentCode  kod komponentu
         * @param  {Object} markdowns obiekt md (np. linki)
         * @param  {Object} flags obiekt z flagami (np. noSceSet - nie ustawiaj sce)
         * @return {string}           tlumaczenie, kod etykiety jesli brak
         */
        this.getTranslation = function(labelCode, parameters, langCode, componentCode, markdowns, flags) {
          if (typeof labelCode === 'undefined') {
            return 'Error-noLabelCode';
          }
          var isHtml = false;

          if(angular.isObject(flags) && flags.forceSce) //chcemy sce nawet jak nie mamy w środku html'a, bo wrzucamy treść w bind-html
          {
            isHtml = true;
          }

          var label = self.getLabel(labelCode, componentCode),
            translation = labelCode;

          // zdobywamy tumaczenie
          if (label) {
            langCode = langCode ? langCode : self.selectedLanguage;
            if (label.translations[langCode]) {
              translation = label.translations[langCode];
            }
          }

          // podmieniamy parametry
          if (parameters) {
            angular.forEach(parameters, function(paramValue, paramKey) {
              translation = translation.replace('{' + paramKey + '}', paramValue);
            });
          }
          translation = translation.replace(/<<newline>>/g, '{newline}').replace(/<<hardspace>>/g, '{hardspace}');


          // obsługa markdown
          if (angular.isObject(markdowns) && angular.isArray(markdowns.link)) {
            isHtml = true;
            translation = self._getLabelWithLinkMarkdown(translation, markdowns.link);
          }

          var mdTags = ['{newline}', '{hardspace}'];
          angular.forEach(mdTags, function(mdTag) {
            if (translation.indexOf(mdTag) > -1) {
              isHtml = true;
            }
          });
          translation = translation.replace(/{newline}/g, '</br>').replace(/{hardspace}/g, '&nbsp;');

          var doubleMdTags = ['strong']; //{strong} {/strong}
          angular.forEach(doubleMdTags, function(doubleMdTag) {
            var startCode = '{' + doubleMdTag + '}';
            var startTag = '<' + doubleMdTag + '>';
            var endCode = '{/' + doubleMdTag + '}';
            var endTag = '</' + doubleMdTag + '>';
            var endReached = false;
            var i = 100;
            while (!endReached && i > 0) {
              if (translation.indexOf(startCode) !== -1 && translation.indexOf(endCode) !== -1) { //jeśli jest start i end
                translation = translation.replace(startCode, startTag).replace(endCode, endTag); //zamieniamy parami
                isHtml = true;
              } else {
                endReached = true;
              }
              i = i - 1; //na wszelki wypadek
            }
          });

          // jeśli mamy html, to musimy go potraktować sce, chyba ze flaga mowi inaczej
          if (self.$sce && isHtml && (!angular.isObject(flags) || !flags.noSceSet)) {
            translation = self.$sce.trustAsHtml(translation);
          }

          return translation;
        };

        /**
         * Zwraca etykietę wzgogaconą o linki z markdowna
         * np. dla etykiet "kliknij [link]tutaj[link]" dostaniemy  "kliknij <a href>tutaj</a>"
         * @param  {String} translation tłumaczenie
         * @param  {Array} links tablica obiektów linków
         * @return {String}
         */
        this._getLabelWithLinkMarkdown = function(translation, links) {
          var labelParts = translation.split('[link]');
          var labelPartsWithLink = [];
          if (labelParts.length > 2) {
            for (var i = 0; i < labelParts.length; i++) {
              labelPartsWithLink.push(labelParts[i].escapeHtml());
              if (i % 2 === 0 && i !== labelParts.length - 1) {
                // niezbyt ładne budowanie linku, ale przez szablon byłoby jeszcze gorzej
                var linkAttributes = [];
                angular.forEach(links[0], function(linkValue, linkAttribute) { //eslint-disable-line no-loop-func
                  linkAttributes.push(linkAttribute + '="' + linkValue + '"');
                });
                // wciągamy element po rozpoczynającym "[link]" aby mieć "nazwę" hrefa
                var link = '<a ' + linkAttributes.join(' ') + '>' + labelParts[i + 1].escapeHtml() + '</a>';
                i++; // NOSONAR
                labelPartsWithLink.push(link);
                links.splice(0, 1);
              }
            }
          }

          return labelPartsWithLink.join('');
        };

        /**
         * Pobieramy tlumaczenie
         * @param  {string} labelCode kod etykiety
         * @param  {string|undefined} componentCode  kod komponentu
         * @param {array|undefined} parameters parametry
         * @param  {string|undefined} langCode  kod jezyka
         * @return {string}           tlumaczenie, kod etykiety jesli brak
         */
        this.getTrans = function(labelCode, componentCode, parameters, langCode, markdowns, flags) {
          if (angular.isUndefined(componentCode)) {
            componentCode = 'Public';
          }
          return self.getTranslation(labelCode, parameters, langCode, componentCode, markdowns, flags);
        };

        /**
         * transObject przeszukuje obiekt/podobiekty za wartosciami '__' oczekiwania wartosc [labelCode, componentCode, parameters, langCode, markdowns]
         * @param  {object} obj [obiekt do tlumaczenia]
         * @param  {Object} flags obiekt z flagami (np. noSceSet - nie ustawiaj sce)
         * @return {object}     [przetlumaczony obiekt]
         */
        this.transObject = function(obj, flags) {
          var newObj = angular.copy(obj);
          if (angular.isObject(newObj)) {
            angular.forEach(newObj, function(val, key) {
              if (angular.isObject(val)) {
                if (angular.isDefined(val.__)) {
                  if(angular.isUndefined(val.__[4])){
                    val.__[4] = null;
                  }
                  if(angular.isUndefined(val.__[5])){
                    val.__[5] = flags;
                  }
                  newObj[key] = self.getTrans(val.__[0], val.__[1], val.__[2], val.__[3], val.__[4], val.__[5]);
                } else {
                  newObj[key] = self.transObject(val, flags);
                }
              }
            });
          }
          return newObj;
        };

        /**
         * [getAvailableLanguages zwraca dostepne jezyki]
         * @return {[type]} [dostepne jezyki]
         */
        this.getAvailableLanguages = function() {
          return self.availableLanguages;
        };

        /**
         * [initSelectedLanguage ustawia jezyk domyslny na podstawie zapisanego w ciasteczku, jesli nie zostawia ten z ustawien przegladarki]
         * @return {[type]} [description]
         */
        this.initSelectedLanguage = function() {
          var cookie = lsnCookie.get(cookieName);
          if (angular.isDefined(cookie)) {
            self.selectedLanguage = cookie.code;
          } else {
            var langFound = false;
            if(navigator && navigator.language)
            {
              angular.forEach(self.availableLanguages, function(lang) {
                if (lang.code === navigator.language) {
                  self.selectedLanguage = lang.code;
                  langFound = true;
                }
              });
            }
            if (!langFound && navigator && angular.isArray(navigator.languages) && angular.isString(navigator.languages[0])) {
              var navShortName = navigator.languages[0].substr(0, 2).toLowerCase();
              angular.forEach(self.availableLanguages, function(lang) {
                if (lang.code.substr(0, 2).toLowerCase() === navShortName) {
                  self.selectedLanguage = lang.code;
                }
              });
            }
          }
        };

        /**
         * [selectLanguage funkcja wywolana na zmiane jezyka]
         * @param  {[type]} langCode [na jaki jezyk zmieniono]
         */
        this.selectLanguage = function(langCode) {
          self.selectedLanguage = langCode;

          var cookieData = {
            code: langCode
          };
          lsnCookie.set(cookieName, cookieData, cookiesSettings);

          self.initLabels();
          labelActionHelperProvider.getLabelHelper().callRegisteredAction();
        };
      };

      var labelHelperInstance = new LabelHelper();
      labelHelperInstance.init();

      /**
       * Metoda dostępna w sekcji .config
       * @return {ConfigService} [description]
       */
      this.getLabelHelper = function() {
        return labelHelperInstance;
      };

      /**
       * return injectora
       * @return {ConfigService} [description]
       */
      this.$get = ['$sce', 'ihestiaCommonErrorHandler',
        function($sce, ihestiaCommonErrorHandler) {
          labelHelperInstance.$sce = $sce;
          if(angular.equals(labelHelperInstance.labels, {})) {
            ihestiaCommonErrorHandler.throwException('Błąd wczytywania etykiet!', true);
          }
          return labelHelperInstance;
        }
      ];
    }
  ]);