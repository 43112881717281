/**
 * pobieranie danych bonus malus
 */
angular.module('ihestiaRestServicesBase')
  .service('ufgSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var UfgSvc = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.businessArea = 'insurance';
      this.resource = 'ufg';

    };
    return new UfgSvc();
  }]);