angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaBeneficiariesContants', function() {
    var BeneficiaryConstants = {};
    BeneficiaryConstants.ACTION_EDIT_BENEFICIARY = 'ediBeneficiary';
    BeneficiaryConstants.ACTION_DELETE_BENEFICIARY = 'deleteBeneficiary';
    BeneficiaryConstants.ACTION_CLOSE_BENEFICIARIES = 'closeBeneficiaries';
    BeneficiaryConstants.ACTION_ADD_BENEFICIARY = 'addBeneficiary';
    BeneficiaryConstants.ACTION_ADDITIONAL_DATA = 'additionalData';
    BeneficiaryConstants.ACTION_BACK = 'back';
    BeneficiaryConstants.ACTION_SAVE_BENEFICIARIES = 'saveBeneficiaries';
    BeneficiaryConstants.TYPE_MAIN = 'MAIN';
    BeneficiaryConstants.TYPE_ADDITIONAL = 'ADDITIONAL';

    return BeneficiaryConstants;
  });
