angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaOperationsModalCtrl', ['$scope', 'IhestiaPzaOperationModel', 'ihestiaPzaOperationModalHelper', 'ihestiaPzaOperationConstants', 'ihestiaPzaDictionaryHelper', 'ihestiaPzaProgramSvc', 'lsnModelHelper', 'IhestiaPzaWorkerOperationModel', 'ihestiaPzaConditionConstants', 'ihestiaPzaConditionSvc', 'ihestiaPzaCommonHelper', 'ihestiaPzaAppConstants', '$alert', '$filter',
      function($scope, IhestiaPzaOperationModel, ihestiaPzaOperationModalHelper, ihestiaPzaOperationConstants, ihestiaPzaDictionaryHelper, ihestiaPzaProgramSvc, lsnModelHelper, IhestiaPzaWorkerOperationModel, ihestiaPzaConditionConstants, ihestiaPzaConditionSvc, ihestiaPzaCommonHelper, ihestiaPzaAppConstants, $alert, $filter) {

        /**
         * Pierwsze wciśnięcie "zapisz" przestawia flagę na true
         * @type {Boolean}
         */
        $scope.formSubmitted = false;

        /**
         * Lista warunków dla zmiany warunków
         * @type {Array}
         */
        $scope.conditions = null;

        /**
         * Kontekst operacji - pd|ms|agency|worker
         * @type {[type]}
         */
        $scope.context = ihestiaPzaOperationModalHelper.context;

        $scope.ihestiaPzaOperationConstants = ihestiaPzaOperationConstants;

        /**
         * Lista zaznaczonych elementów do operacji (pd, ms, agencje, pracownicy - w zależności od kontekstu)
         * @type {array}
         */
        $scope.itemList = ihestiaPzaOperationModalHelper.itemList;

        /**
         * Stałe - np. konteksty
         * @type {object}
         */
        $scope.operationConstants = ihestiaPzaOperationConstants;

        /**
         * Dane operacji (wysyłane do usługi)
         * @type {[type]}
         */
        $scope.operationData = ihestiaPzaOperationModalHelper.operationData;

        /**
         * Ładowanie słownik itp. w toku
         * @type {Boolean}
         */
        $scope.loadInProgress = false;

        /**
         * Całkowita wartość operacji
         * @type {Number}
         */
        $scope.totalOperationValue = 0;

        /**
         * Dostępne operacje
         * @type {Array}
         */
        $scope.actions = [];

        /**
         * Rodzaje budżetu
         * @type {Array}
         */
        $scope.workerAccessModes = [];

        /**
         * Czy już zamknęliśmy pozytywnie resolve'a
         * @type {Boolean}
         */
        $scope.promiseResolved = false;

        /**
         * Lista błędów z usługi
         * @type {Array}
         */
        $scope.errorList = [];

        /**
         * Usunięcie z programu
         * @type {Boolean}
         */
        $scope.modalData = {
          removeFromProgram: false
        };

        /**
         * Matryca uprawnień do operacji
         * @type {Object}
         */
        $scope.operationPermissions = ihestiaPzaOperationModalHelper.permissions;

        /**
         * Init
         * @return {undefined}
         */
        $scope.init = function() {
          /** ustalenie tytułu modala */
          if ($scope.itemList.length > 1) {
            $scope.ihestiaPzaOperationsModalSettings.title = ['modals.batchOperations', {componentCode:'Pza'}];
          } else {
            $scope.ihestiaPzaOperationsModalSettings.title = ['modals.operation', {componentCode:'Pza'}];
          }

          $scope.loadDicts();

          /** save */
          $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaPzaOperations', function($event) {
            $event.preventDefault();
            $scope.save();
          });

          /** koniec żywota jego amen - czyścimy dane */
          $scope.$on('$destroy', function() {
            if (!$scope.promiseResolved) {
              // jeśli nie było happy endu, to dajemy rejecta, bo najwyraźniej ktoś anulował operację
              ihestiaPzaOperationModalHelper.rejectPromise({
                reason: 'destroy'
              });
            }
            ihestiaPzaOperationModalHelper.clearData();
          });
        };

        /**
         * Załadowanie listy warunków
         * @return {undefined}
         */
        $scope.loadConditions = function() {
          if (!$scope.conditions) {
            $scope.conditions = [];
            $scope.$emit('showBlockUi');
            ihestiaPzaConditionSvc.get(null, {
              statusCode: ihestiaPzaConditionConstants.STATUS_ACTIVE,
              programId: ihestiaPzaOperationModalHelper.programData.id
            }).then(function(res) {
              if (res && (res.status === 200 || res.status === 204) && angular.isArray(res.data)) {
                $scope.conditions = res.data;
              }
            }, angular.noop).finally(function() {
              $scope.$emit('hideBlockUi');
            });
          }
        };

        /**
         * Przelicza wartość do pola całkowita wartość operacji
         * @return {undefined}
         */
        $scope.recalculateTotalValue = function() {
          $scope.totalOperationValue = 0;
          var budgetValue = $scope.operationData.budgetValue ? $scope.operationData.budgetValue : 0;
          if ($scope.operationData.operation) {
            switch ($scope.operationData.operation.code) {
              case ihestiaPzaOperationConstants.OPERATION_SUBSTRACT_VALUE:
              case ihestiaPzaOperationConstants.OPERATION_ADD:
                // proste dodanie lub odjęcie kwoty
                // (przemnażamy wartość przez liczbę pd/ms/agencji/...)
                $scope.totalOperationValue = budgetValue * $scope.itemList.length;
                break;
              case ihestiaPzaOperationConstants.OPERATION_SUBSTRACT_PERCENT:
                // zabranie %
                var userRole = ihestiaPzaCommonHelper.getUserRole();
                var budgetAttribute = '';
                if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_PD) {
                  budgetAttribute = 'available';
                } else if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_WORKER) {
                  budgetAttribute = 'individualAvailable';
                } else {
                  var budgetMap = {};
                  budgetMap[ihestiaPzaAppConstants.USER_TYPE_CENTRAL] = 'availableCen';
                  budgetMap[ihestiaPzaAppConstants.USER_TYPE_PD] = 'availablePd';
                  budgetMap[ihestiaPzaAppConstants.USER_TYPE_MS] = 'availableMs';
                  budgetAttribute = budgetMap[userRole];
                }
                // https://ebok.atena.pl/browse/IHESTIASU-277
                // najpierw zaokrąglamy, a potem sumujemy
                angular.forEach($scope.itemList, function(budget) {
                  $scope.totalOperationValue = $scope.totalOperationValue + Math.round(budget[budgetAttribute] * (budgetValue / 100));
                });
                break;
              default:
                $scope.totalOperationValue = 0;
                break;
            }
          }
        };

        /**
         * Ładowanie słowników
         * @return {[type]} [description]
         */
        $scope.loadDicts = function() {
          $scope.loadInProgress = true;
          $scope.actions = [];
          ihestiaPzaDictionaryHelper.getStandardDictItems([
            'OPERATIONS',
            'WORKER_OPERATIONS',
            'WORKER_ACCESS_MODE'
          ]).then(function(dicts) {
            var actions = [];
            if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_WORKER) {
              actions = dicts.WORKER_OPERATIONS;
              $scope.workerAccessModes = dicts.WORKER_ACCESS_MODE;
              /** Pozbywamy się pustej opcji w selekcie zmień wykorzystywany budżet 
               */
              var noneBudgetAccessMode = null;
              angular.forEach($scope.workerAccessModes, function(workerAccessMode) {
                if ($scope.operationData.budgetAccess && workerAccessMode.description === $scope.operationData.budgetAccess.description) {
                  $scope.operationData.budgetAccess = workerAccessMode;
                }
                if (workerAccessMode.code === '0') {
                  noneBudgetAccessMode = workerAccessMode;
                }
              });
              if (!$scope.operationData.budgetAccess || !$scope.operationData.budgetAccess.id) {
                $scope.operationData.budgetAccess = noneBudgetAccessMode;
              }
            } else {
              angular.forEach(dicts.OPERATIONS, function(action) {
                var budgetCodes = ['ADD', 'SUBSTRACT_VALUE', 'SUBSTRACT_PERCENT'];
                if (action.code !== 'REMOVE' && budgetCodes.indexOf(action.code) > -1 && $scope.operationPermissions.changeBudget) {
                  actions.push(action);
                } else if (action.code === 'REMOVE') {
                  $scope.removeOperation = action;
                }
              });
            }
            angular.forEach(actions, function(action) {
              // domyślnie akcja dodawania
              if (action.code === 'ADD') {
                $scope.operationData.operation = action;
              }
            });
            $scope.actions = actions;
            if ($scope.operationPermissions.changeCondition) {
              $scope.loadConditions();
            }
          }, angular.noop).finally(function() {
            $scope.loadInProgress = false;
          });
        };

        /**
         * Zmieniły się selektory operacji
         * @return {undefined}
         */
        $scope.validate = function() {
          $scope.errorList = [];
          /** required  */
          $scope.errorList = [];
          // brzydka pętla dla zaznaczenia radio, ale nie ma czasu rozkminiać co jest nie tak w angularze
          angular.forEach($scope.operationsForm.$error.required, function(requiredError) {
            switch (requiredError.$name) {
              case 'centralLockValue':
                $scope.operationsForm.centralLockValue.$setValidity('required', false);
                $scope.errorList.push({
                  label: ['modals.selectBlock', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              case 'blockValue':
                $scope.operationsForm.blockValue.$setValidity('required', false);
                $scope.errorList.push({
                  label: ['modals.selectBlock', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              case 'budgetAccess':
                $scope.errorList.push({
                  label: ['modals.selectBudget', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              case 'operation':
                $scope.errorList.push({
                  label: ['modals.selectOperations', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              case 'budgetValue':
                $scope.errorList.push({
                  label: ['modals.enterValue', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              case 'groupValue':
                $scope.errorList.push({
                  label: ['modals.enterNameGroup', {
                    componentCode: 'Pza'
                  }]
                });
                break;
              default:
                $scope.errorList.push({
                  label: ['modals.errorOccurred', {
                    componentCode: 'Pza'
                  }]
                });
            }
            requiredError.$setDirty();
          });
          /** Przynajmniej jedna operacja */
          var chooseOperationErrorText = {
            label: ['modals.selectAtLeastOneOperation', {
              componentCode: 'Pza'
            }]
          };
          /** dodatkowa walidacja, czy cokolwiek zaznaczyliśmy */
          var operationData = $scope.getOperationData();
          var atLeaastOneOperationSelected = false;
          var availableOperations = [
            'changeBudgetAccess', 'budget', 'conditionChange', 'groupChange', 'centralLock', 'operation', 'blockValue', 'blockChange'
          ];
          angular.forEach(availableOperations, function(operation) {
            if (operationData[operation]) {
              atLeaastOneOperationSelected = true;
            }
          });
          if (!atLeaastOneOperationSelected) {
            $scope.errorList.push(chooseOperationErrorText);
          }
          if (operationData.budget && operationData.operation && operationData.operation.code === 'ADD') {
            // nie można dodawać hajsu, jeśli ktokolwiek jest zablokowany lub będzie zablokowany
            if ($scope._someOneIsLocked(operationData)) {
              var labelCode = 'modals.canNotAddBudgetToLockedAgency';
              if($scope.context === ihestiaPzaOperationConstants.CONTEXT_MS) {
                labelCode = 'modals.canNotAddBudgetToLockedMs';
              } else if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_PD) {
                labelCode = 'modals.canNotAddBudgetToLockedPd';
              }
              $scope.errorList.push({
                label: [labelCode, {
                  componentCode: 'Pza'
                }]
              });
            }

            // nie można dodawać hajsu, jeśli ktokolwiek jest lub będzie bez warunku
            if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_AGENCY && $scope._someoneWithoutCondition(operationData)) {
              $scope.errorList.push({
                label: ['modals.canNotAddBudgetToAgencyWithoutCondition', {
                  componentCode: 'Pza'
                }]
              });
            }
          }

          $scope.errorList = $scope.errorList.unique();
        };

        /**
         * Czy można dodawać budżet
         * @return {Boolean}
         */
        $scope._canAddBudget = function() {
          var operationData = $scope.getOperationData();
          // nie można dodawać hajsu, jeśli ktokolwiek jest zablokowany lub będzie zablokowany
          var canAddBudget = $scope._someOneIsLocked(operationData) ? false : true;

          if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_AGENCY && $scope._someoneWithoutCondition(operationData)) {
            // nie można dodawać hajsu, jeśli ktokolwiek jest lub będzie bez warunku
            canAddBudget = false;
          }

          return canAddBudget;
        };

        /**
         * Czy ktoś jest lub będzie zablokowany
         * @param  {Object} operationData $scope.getOperationData();
         * @return {Boolean}
         */
        $scope._someOneIsLocked = function(operationData) {
          var someoneIsLocked = ihestiaPzaOperationModalHelper.getLockedCount() > 0;
          if (operationData.centralLock && operationData.centralLockValue) {
            someoneIsLocked = true;
          } else if (operationData.centralLock && !operationData.centralLockValue) {
            // jeśli odblokowujemy, to wszystko spoko
            someoneIsLocked = false;
          }
          return someoneIsLocked;
        };

        /**
         * Czy ktoś jest lub będzie bez warunku
         * @param  {Object} operationData $scope.getOperationData();
         * @return {Boolean}
         */
        $scope._someoneWithoutCondition = function(operationData) {
          var someoneWithoutCondition = ihestiaPzaOperationModalHelper.getWithoutConditionCount() > 0;
          if (operationData.conditionChange && !operationData.condition) {
            someoneWithoutCondition = true;
          } else if (operationData.conditionChange && operationData.condition) {
            someoneWithoutCondition = false;
          }

          return someoneWithoutCondition;
        };

        /**
         * Zwraca dane operacji do usługi
         * @return {array}
         */
        $scope.getOperationData = function() {
          /** przygotywanie danych */
          var operation = null; // NOSONAR
          if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_WORKER) {
            operation = new IhestiaPzaWorkerOperationModel();
          } else {
            operation = new IhestiaPzaOperationModel();
          }
          var operationData = angular.copy($scope.operationData);
          // jeśli nie ma zmian budżetu, to wycinami dane z nim związane
          if (operationData.budget === false || operationData.changeBudget === false || $scope.modalData.removeFromProgram) {
            delete operationData.budgetValue;
            delete operationData.operation;
            operationData.budget = false;
            operationData.changeBudget = false;
          }
          // usuń z programu nie powinno mieć pola z wpisaniem wartości
          if (operationData.operation && operationData.operation.code === 'REMOVE') {
            delete operationData.budgetValue;
            operationData.operation = null;
          }
          // jeśli nie ma zmiany grupy, to usuwamy nazwę grupy
          if (!operationData.groupChange) {
            delete operationData.groupValue;
          }
          // blokowanie
          if (!operationData.centralLock || $scope.modalData.removeFromProgram) {
            operationData.centralLockValue = null;
            operationData.centralLock = false;
          }
          if (!operationData.blockChange || $scope.modalData.removeFromProgram) {
            operationData.blockValue = null;
            operationData.blockChange = false;
          }
          // jeśli nie zmian w dostępie do budżetu, to nie wysyłamy go
          if (!operationData.changeBudgetAccess || $scope.modalData.removeFromProgram) {
            delete operationData.budgetAccess;
            operationData.changeBudgetAccess = false;
          }

          // jeśli podgląd, to wywalamy zmianę warunku
          if ($scope.modalData.removeFromProgram) {
            delete operationData.condition;
            operationData.conditionChange = false;
          }

          // sztucza opcja usuwania z programu (wcześnie była w selekcie operacji)
          if ($scope.modalData.removeFromProgram) {
            operationData.operation = $scope.removeOperation;
          }
          // przepuszczanie danych przez model
          operation.setData(operationData);
          lsnModelHelper.minifyRestObject(operation);
          operationData = operation.getData();

          return operationData;
        };

        /**
         * Zatwierdzenie operacji
         * @return {undefined}
         */
        $scope.save = function() {
          $scope.formSubmitted = true;
          $scope.validate();
          if ($scope.operationsForm.$valid && $scope.errorList.length === 0) {
            var operationResource = null; // NOSONAR
            var operationData = $scope.getOperationData();
            if ($scope.context === ihestiaPzaOperationConstants.CONTEXT_WORKER) {
              operationResource = 'agent/operation';
            } else {
              operationResource = 'operation';
            }

            // zapisujemy
            $scope.$emit('showBlockUi');
            ihestiaPzaProgramSvc.put(ihestiaPzaOperationModalHelper.programData.id, operationData, operationResource).then(function(res) {
                if (res && (res.status === 200 || res.status === 204)) {
                  if (res.data && res.data.result && res.data.result === 'NO_BUDGET') {
                    if (res.data.message) {
                      $scope.errorList.push({
                        text: res.data.message
                      });
                    } else {
                      $scope.errorList.push({
                          label: ['modals.noBudget', {
                        componentCode: 'Pza'
                      }]});
                    }
                  } else if (res.data && res.data.result !== 'OK') {
                    if (res.data.message) {
                      $scope.errorList.push({
                        text: res.data.message
                      });
                    } else {
                      $scope.errorList.push({
                          label: ['modals.unknownServicesErrorOccurred', {
                            componentCode: 'Pza'
                          }]});
                      }
                    } else {
                      ihestiaPzaOperationModalHelper.resolvePromise(res.data);
                      $scope.promiseResolved = true;
                      ihestiaPzaOperationModalHelper.hideOperationsModal();
                      $alert({
                        content: $filter('translate')('modals.operationCompletedSuccessfully', {
                          componentCode: 'Pza'
                        }),
                        type: 'success'
                      });
                    }
                  } else {
                    $scope.errorList.push({
                        label: ['modals.unknownServicesErrorOccurred', {
                          componentCode: 'Pza'
                        }]});
                    }
                  }, angular.noop).finally(function() {
                  $scope.$emit('hideBlockUi');
                });
              }
            };
          }
        ]);