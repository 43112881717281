angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorNextMessageCtrl', ['$scope', 'ihestiaIkomunikatorNextMessageHelper', '$alert',
    'iKomunikatorMessagesSvc', 'ihestiaFirstInfoOneListUploaderHelper', 'fileServerSvc', 'IKomunikatorAttachmmentModel', 'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorContextHelper',
    function($scope, ihestiaIkomunikatorNextMessageHelper, $alert, iKomunikatorMessagesSvc, ihestiaFirstInfoOneListUploaderHelper, fileServerSvc, IKomunikatorAttachmmentModel, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorContextHelper) {

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        messages: {
          maxNumberOfFiles: 'Przekroczono maksymalną liczbę plików',
          acceptFileTypes: 'Typ pliku nie jest dozwolony',
          maxFileSize: 'Plik jest za duży',
          minFileSize: 'Plik jest za mały'
        },
        name: 'detailsNewMessage',
        hideQueueList: true,
        maxFileSize: 10000000,
        maxChunkSize: 4000000,
        maxNumberOfFiles: 15,
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        }
      };

      $scope.oneListUploaderOptions = {
        attachmentType: 'static'
      };

      $scope.init = function() {

        if (angular.isDefined($scope.tplData.dashboardTaskId)) {
          ihestiaIkomunikatorNextMessageHelper.init($scope.thread, $scope.tplData.dashboardTaskId);
        } else {
          ihestiaIkomunikatorNextMessageHelper.init($scope.tplData.thread);
        }

        $scope.tplSubdata = ihestiaIkomunikatorNextMessageHelper.tplSubdata;

        $scope.fireListeners();
        $scope.initUploader();
      };

      $scope.fireListeners = function() {
        $scope.$watch('tplSubdata.document', function(newValue) {
          ihestiaFirstInfoOneListUploaderHelper.setSelectedDocumentType($scope.uploaderOptions.name, newValue.documentType);
        }, true);

        $scope.$watch('tplSubdata.newMessage.content', function(val) {
          if($scope.tplSubdata.thread.getLastMessageChannel() === 'SMS'){
            $scope.numberSms = Math.ceil(val.length / 160);
            if (val.length > 480) { 
              $scope.tplSubdata.newMessage.content = val.substring(0, 480);
            }
          }
        });
      };

      $scope.initUploader = function() {
        ihestiaFirstInfoOneListUploaderHelper.init($scope.uploaderOptions.name, {
          onFileUploadDone: $scope.onFileUploadDone,
          onErrorSendingFile: $scope.onErrorSendingFile,
          onFileUploadAdd: $scope.onFileUploadAdd,
          onErrorAddingFile: $scope.checkAndBlockUpload,
          onAllFileSend: $scope.onAllFileSend
        });

        $scope.fileModels = ihestiaFirstInfoOneListUploaderHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.deleteFile = function(id) {
        $scope.deleteFileFromAttachment(id);
        ihestiaFirstInfoOneListUploaderHelper.deleteFile($scope.uploaderOptions.name, id);
        if (ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
          $scope.tplSubdata.fileMessageError = false;
        }
      };

      $scope.startSendingMessage = function() {
        var context = {
          dashboardTaskId: $scope.tplData.dashboardTaskId
        };
        //odświeżenie kontekstu
        ihestiaIkomunikatorContextHelper.setContext(context);
        if (ihestiaFirstInfoOneListUploaderHelper.areFileToSend($scope.uploaderOptions.name)) {
          $scope.$emit('showBlockUi', {
            label: 'Wysyłam 1 plik z ' + $scope.fileModels.length
          });
          ihestiaFirstInfoOneListUploaderHelper.startSendingFiles($scope.uploaderOptions.name);
        } else {
          if (ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
            $scope.sendMessage();
          }
        }
      };

      $scope.onAllFileSend = function(data) {
        if (data.withoutError) {
          $scope.tplSubdata.fileMessageError = false;
          angular.forEach(data.files, function(fileModel) {
            var attachment = new IKomunikatorAttachmmentModel();
            attachment.setFromFile(fileModel.file, fileModel.fileServerId);
            $scope.tplSubdata.newMessage.attachments.push(attachment);
          });
          $scope.sendMessage();
        } else {
          $scope.$emit('hideBlockUi');
          $scope.tplSubdata.fileMessageError = true;
        }
      };

      $scope.sendMessage = function() {
        $scope.$emit('showBlockUi', {
          label: 'Wysyłam wiadomość'
        });

        ihestiaIkomunikatorNextMessageHelper.saveMessage();
        ihestiaIkomunikatorNextMessageHelper.clearValidatonErrors();
        iKomunikatorMessagesSvc.post(null, $scope.tplSubdata.newMessage.getModelForRest(), null, function() {
          $scope.$emit('hideBlockUi');
          $alert({
            content: 'Twoja wiadomość została wysłana.',
            type: 'success'
          });

          $scope.onSendedMessage($scope.tplSubdata.thread.threadId);
        }, function(res) {
          $scope.$emit('hideBlockUi');
                    
          if (res.status === 400) {
            ihestiaIkomunikatorNextMessageHelper.setValidatonErrors(res.data);
          } else {
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi podczas wysyłania wiadomości, spróbuj ponownie.');
          }
        }, {
          allowedStatuses: [400]
        });
      };

      $scope.deleteFileFromAttachment = function(id) {
        var fileServerId = $scope.fileModels[id].fileServerId;
        if (fileServerId !== null) {
          angular.forEach($scope.tplData.message.attachments, function(attachment, key) {
            if (attachment.fileServerId === fileServerId) {
              $scope.tplData.message.attachments.splice(key, 1);
            }
          });
        }
      };

      $scope.onFileUploadDone = function() {
        var numberSendedFile = ihestiaFirstInfoOneListUploaderHelper.getProcessedFileIndex($scope.uploaderOptions.name) + 1;
        if (numberSendedFile < $scope.fileModels.length) {
          $scope.$emit('hideBlockUi');
          $scope.$emit('showBlockUi', {
            label: 'Wysyłam ' + numberSendedFile + ' plik z ' + $scope.fileModels.length
          });
        }
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorNextMessageHelper.setClearData();
      });

      $scope.init();
    }
  ]);