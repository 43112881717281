//trzeba pomyslec jak czyscic te listy
angular.module('ihestiaCommonDirectives')
  .service('TwoListUploadHelper', ['IhestiaAbstractListUploadHelper', '$rootScope', 'UploadedFileModel', 'ToSendFileModel', '$alert', 'filesSvc', '$timeout', 'TwoListUploadDataContainer',
    function(IhestiaAbstractListUploadHelper, $rootScope, UploadedFileModel, ToSendFileModel, $alert, filesSvc, $timeout, TwoListUploadDataContainer) {
      var TwoListUploadHelper = function() {
        IhestiaAbstractListUploadHelper.apply(this, arguments);
        var self = this;

        this.init = function(scopeName, callbacks, uplodedFiles, config) {

          self.data[scopeName] = new TwoListUploadDataContainer();
          if (angular.isObject(config)) {
            self.data[scopeName].config = angular.extend(self.data[scopeName].configDefault, config);
          }

          self.data[scopeName].status = 'WAITING';
          //kopiujemy w razie gdyby juz takie pliki były na liście
          if (angular.isArray(uplodedFiles)) {
            angular.forEach(uplodedFiles, function(uploadedFile) {
              var fileModel = new UploadedFileModel();
              fileModel.setEarlierUploadedFile(uploadedFile);
              self.data[scopeName].files.uploaded.push(fileModel);
            });
          }

          angular.forEach(callbacks, function(value, key) {
            self.data[scopeName].callbacks[key] = value;
          });
        };

        this.onAddFile = function(e, data) {
          if (data.state() !== 'rejected') {
            var scopeName = data.scope.name;
            if (!self.isActive(scopeName)) {
              return;
            }

            angular.forEach(data.files, function(file) {
              var newFileModel = new ToSendFileModel();
              newFileModel.setFileData(file);
              if (self.data[scopeName].selectedDocumentType !== null) {
                newFileModel.setDocumentType(self.data[scopeName].selectedDocumentType);
              } else if (self.selectedDocumentType !== null) {
                newFileModel.setDocumentType(self.selectedDocumentType);
              }
              self.data[scopeName].files.toSend.push(newFileModel);
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileUploadAdd)) {
              self.data[scopeName].callbacks.onFileUploadAdd(e, data);
            }

            if (self.data[scopeName].config.autoUpload && self.data[scopeName].status === 'WAITING') {
              self.startSendingFiles(scopeName);
            }
          }
        };

        this.startSendingFiles = function(scopeName) {
          self.data[scopeName].processedFileIndex = self.data[scopeName].getFirstIndexFileToSend();

          if (self.data[scopeName].config.firstCheckAllFiles) {
            self.data[scopeName].status = 'INFO';
          } else {
            self.data[scopeName].status = 'SENDING';
          }
          self.sendInfoAboutFile(scopeName);
        };

        /**
         * [onFileDelete usuwa tylko z plikow do wyslania, wywolana z tpl do uploadu]
         * @param  {[type]} e    [description]
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        this.onFileDelete = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var i = self.data[scopeName].files.toSend.length;
            while (i) {
              i -= 1;
              if (self.data[scopeName].files.toSend[i].file === data.file) {
                self.data[scopeName].files.toSend.splice(i, 1);
                break;
              }
            }

            $rootScope.$broadcast('deleteFileInUploader', {
              file: data.file,
              scopeName: scopeName
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileDelete)) {
              self.data[scopeName].callbacks.onFileDelete(e, data);
            }
          }
        };

        this.copyFileToUploaded = function(scopeName, status, message, fileServerId) {
          var sendedFile = self.data[scopeName].getProcessedFile(),
            newUplodedFileModel = new UploadedFileModel();

          newUplodedFileModel.initFromSendedFile(sendedFile, status, message, fileServerId);
          self.data[scopeName].files.uploaded.push(newUplodedFileModel);
        };

        /**
         * Metoda służy do kontynuacji procesu przesłania plików
         * @param  {string} scopeName  nazwa scope
         */
        this.proceedUploadingFile = function(scopeName) {
          //jesli wysylamy najpierw informacje to dopiero przy kolejnej iteracji bedziemy usuwac pliki
          var fileDeleted = false;
          if (self.data[scopeName].status !== 'INFO') {
            //usuwamy plik z plikow do wyslania
            self.deleteFile(scopeName, self.data[scopeName].processedFileIndex);
            fileDeleted = true;
          }

          if (self.allFilesSended(scopeName)) {
            self.onAllFilesSended(scopeName);
          } else if (self.data[scopeName].status === 'INFO') {
            if (self.data[scopeName].processedFileIndex + 1 === self.data[scopeName].files.toSend.length && self.checkAllFilesSendWithoutErrors(scopeName)) {
              self.data[scopeName].status = 'SENDING';
              self.data[scopeName].processedFileIndex = 0;
              self.data[scopeName].getProcessedFile().file.$submit();
            } else if (self.data[scopeName].processedFileIndex + 1 < self.data[scopeName].files.toSend.length) {
              self.data[scopeName].processedFileIndex += 1;
              self.sendInfoAboutFile(scopeName);
            } else if (self.data[scopeName].processedFileIndex + 1 === self.data[scopeName].files.toSend.length && !self.checkAllFilesSendWithoutErrors(scopeName)) {
              self.onAllFilesSended(scopeName);
            }
            //jesli wysylamy plik to zawsze po 0 nie ma wiec potrzeby zwiekszania licznika
          } else {
            if (self.data[scopeName].files.toSend.length === 0) {
              self.onAllFilesSended(scopeName);
            } else {
              //jesli usunieto plik no to nie podnosimy indexu, bo aktualny index jest juz ustawiony na nowy
              if(!fileDeleted){
                self.data[scopeName].processedFileIndex += 1;
              }
              
              if (self.data[scopeName].config.firstCheckAllFiles) {
                self.data[scopeName].getProcessedFile().file.$submit();
              } else {
                self.sendInfoAboutFile(scopeName);
              }
            }
          }
        };

        /**
         * [allFilesSended wywolane w momencie wyslania wszystkich plikow, poprawnie badz nie]
         * @return {[type]} [description]
         */
        this.onAllFilesSended = function(scopeName) {
          self.afterAllFilesSended(scopeName, self.data[scopeName].files.uploaded);
        };

        /**
         * [deleteFile usuwa tylko z plikow do wyslania]
         * @param  {[type]} scopeName [description]
         * @param  {[type]} idx       [description]
         * @return {[type]}           [description]
         */
        this.deleteFile = function(scopeName, idx) {
          self.doDeleteFile(scopeName, idx, self.data[scopeName].files.toSend);
        };

        this.getAllUploadedfiles = function(scopeName) {
          return self.data[scopeName].files.uploaded;
        };

        this.getAllToSendFiles = function(scopeName) {
          return self.data[scopeName].files.toSend;
        };
      };

      return new TwoListUploadHelper();
    }
  ]);