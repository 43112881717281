angular.module('ihestiaWidgets.commonOperations')
  .factory('clientSearchModalHelper', ['ihestiaConfigHelper', '$window', 'customerSvc',
    function(ihestiaConfigHelper, $window, customerSvc) {

    var ClientSearchModalHelper = function() {
      var self = this;

      this.searchData = null;
      this.customersListResponse = null;
      this.ikontoTypeUrl = 'IKONTO_URL'; //url do przekierowania na odpowiednia wersję iKonta iHestia/Direct

      this.clearData = function()
      {
        self.searchData = null;
        self.customersListResponse = null;
      };

      this.goToClient = function(client)
      {
        //sprawdzamy jakiego typu/brandu obiekty posiada klient i przekierowujemy na odpowiedni brand
        customerSvc.get(client.customerAccountId, null, 'account', function(response) {
          if(angular.isObject(response) && response.data){
            var accountBrands = response.data.brands;
            // jeżeli wśród brandów pojawi się wartość IHESTIA przekierowujemy do iKonta iHestia            
            if (angular.isDefined(accountBrands)) {
              if (accountBrands.indexOf('IHESTIA') !== -1) {                
                self.ikontoTypeUrl = 'IKONTO_URL';
              } else {
                // jeśli jest tylko DIRECT to idziemy do iKonta Direct
                if (accountBrands.indexOf('DIRECT') !== -1) {       
                  self.ikontoTypeUrl = 'IKONTO_DIRECT_URL';
                }
              }
            } 
          }
          
          var clientUrl = ihestiaConfigHelper.getUrl(self.ikontoTypeUrl, 'external/client?customerContextId=' + client.customerAccountId);
          $window.open(clientUrl);

        });
        
      };
    };

    return new ClientSearchModalHelper();
  }]);