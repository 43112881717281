angular.module('ihestiaIkomunikator.common', [
    'ihestiaCommonDirectives',
    'ihestiaAg',
    'ihestiaSsoBase',
    'ihestiaCommonComponents',
    'ihestiaRestServicesBase'
  ])
  .filter('changeMarkupToHtml', ['$sce', function($sce) {
    return function(text) {
      var newText = text.replace(/{{b}}(.*){{\/b}}/g, '<b>$1</b>');
      newText = newText.replace(/{{i}}(.*){{\/i}}/g, '<i>$1</i>');
      newText = newText.replace(/{{n}}{{\/n}}/g, '<br/>');

      return $sce.trustAsHtml(newText);
    };
  }]);