angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifePersonSearchModalHelper', ['LsnModalHelper', 'clientPersonsSvc', '$q', 'IhestiaLifeInsurerChangeFlowConstants',
    function(LsnModalHelper, clientPersonsSvc, $q, IhestiaLifeInsurerChangeFlowConstants) {
      var IhestiaLifePersonSearchModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;
        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'sm',
          title: ['policyPersonSearchModal.title', {componentCode: 'Public'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/insurerChange/personSearch/personSearchModal.tpl.html',
          okBtnName: ['policyPersonSearchModal.newClient', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          withDefaultMargin: false
        };

        this.modalData = {
          modalOptions: undefined,
          queryInfo: null
        };

        this.tplData = {
          searchQuery: null,
          searchInProgress: false,
          clientResults: null,
          sortParameters: null,
          queryInfo: null,
          searchFinished: false
        };

        this.cancelPromise = null;

        this.setOptions(self.modalOptions);

        this.init = function() {
          // set custom modal options
          if (_.get(self.modalData, 'modalOptions')) {
            self.setOptions(_.merge({}, self.modalOptions, self.modalData.modalOptions));
          }
          self.tplData.searchQuery = {
            value: ''
          };

          self.tplData.sortParameters = {
            sortBy: 'firstName',
            reverseSort: false
          };
          self.tplData.clientResults = [];
          self.tplData.queryInfo = self.modalData.queryInfo;
          self.tplData.searchFinished = false;
        };

        this.clientSearch = function() {
          self.tplData.clientResults = [];
          if (self.tplData.searchQuery.value.length >= 3) {
            self.tplData.searchInProgress = true;
            self.tplData.searchFinished = false;

            var sortBy = self.tplData.sortParameters.sortBy;
            var sortDirection = self.tplData.sortParameters.reverseSort ? 'DESC' : 'ASC';

            if (self.cancelPromise) {
              self.cancelPromise.resolve('newRequest');
            }
            self.cancelPromise = $q.defer();

            var httpParams = {
              timeout: self.cancelPromise.promise.then(lsnNg.noop, lsnNg.noop) //abort na promise
            };

            self.changeOkBtnDisabled(true);
            clientPersonsSvc.findByParams({
              count: 10,
              skip: 0,
              top: 10,
              sortBy: sortBy,
              sortDirection: sortDirection,
              query: self.tplData.searchQuery,
              httpParams: httpParams
            }).then(function(res) {
              if (!_.isEmpty(_.get(res, 'data.items'))) {
                self.tplData.clientResults = res.data.items;
              } else {
                self.tplData.clientResults = [];
              }
            }, lsnNg.noop)
              .finally(function() {
                self.tplData.searchInProgress = false;
                self.tplData.searchFinished = true;
                self.changeOkBtnDisabled(false);
              });
          } else {
            self.tplData.searchFinished = false;
          }

        };

        /**
         *
         * @param {boolean} disabled
         */
        this.changeOkBtnDisabled = function(disabled) {
          self.setOptions(_.merge({}, self.modalOptions, {
            okBtnDisabled: disabled
          }));
        };

        this.sortBy = function(columnCode) {
          self.tplData.sortParameters.sortBy = columnCode;
          self.tplData.sortParameters.reverseSort = !self.tplData.sortParameters.reverseSort;
          self.clientSearch();
        };

        this.chooseClient = function(client) {
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.CLIENT_EDIT,
            value: {
              subject: client
            }
          });
          return self.hideModal();
        };

        this.confirm = function() {
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.CLIENT_EDIT,
            value: {
              subject: null
            }
          });
          return self.hideModal();
        };

      };
      return new IhestiaLifePersonSearchModalHelper();
    }]);
