angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryPolicyStatusSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryPolicyStatusSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'policyStatus';
      };
      return new RestDictionaryPolicyStatusSvc();
    }
  ]);