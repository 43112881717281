/**
 * Mapper nieruchomości
 */
angular.module('salesPath2').service('toRestEstateMapper', ['MapperUtils', 'EstateModel', 'CONSTANTS', 'CONFIG', 'EstateModelConstants', 'lsnDeepExtend', 'resourceHelper', 'RESOURCES',
  function(MapperUtils, EstateModel, CONSTANTS, CONFIG, EstateModelConstants, lsnDeepExtend, resourceHelper, RESOURCES) {
    var ToRestEstateMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.mapEstates = function(estates) {
        var estatesArray = [];
        angular.forEach(estates, function(estate) {
          estatesArray.push(self._mapEstate(estate));
        });
        return estatesArray;
      };

      /**
       * mapuje nieruchomosc
       * @param {Estate} dmEstate nieruchomosc z dataManagera
       * @return {LsnVehicleModelV1} nieruchomosc do wniosku/polisy
       */
      this._mapEstate = function(dmEstate) {
        var estate = new EstateModel(),
          addData = dmEstate.getAdditionalData(),
          dynVals = dmEstate.get('dynamicValues');
        var data = {
          dynamicValues:
            {
              businessObjectType: CONSTANTS.BUSINESS_OBJECT_TYPE_NIE,
              flatWithoutWindowBars: (typeof dynVals.flatWithoutWindowBars !== 'undefined') ? dynVals.flatWithoutWindowBars : true,
              propertyType: RESOURCES.ESTATE_TO_PROPERTY_TYPE[dmEstate.get('type')] || null, // https://ebok.atena.pl/browse/EHNLEARN-2207
              _estateCoownersIds: MapperUtils.getEstateOrVehicleCoownersIds(addData)
            }
        };
        if (addData !== null) {
          data.dynamicValues._additionalData = self.utils.serializeValue(addData);
        }
        data = lsnDeepExtend(dmEstate.getData(false, true), data);
        //uzupelnienie nazwy nieruchomosci poza MF
        if (CONFIG.BEHAVIOR.localization.sendDefaultEstateName && (data.name === null || data.name === '')) {
          var locDef = resourceHelper.getLocalizationTypeDef(data.type);
          data.name = (locDef !== false) ? locDef.NAME.capitalizeFirstLetter() : '';
        }
        estate.setData(data);
        self.utils.setTemporaryDynamicValuesForObject(estate);
        return estate;
      };

    };

    return new ToRestEstateMapper();
  }
]);