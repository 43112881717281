angular.module('ihestiaWidgets.commonOperations')
  .factory('ihestiaCustomerSearchSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IhestiaCustomerSearchSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'customersearch';
        this.businessArea = 'customers';
        this.pageStyle = false;
        this.sortBy = 'firstname';
      };
      return new IhestiaCustomerSearchSvc();
    }
  ]);