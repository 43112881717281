angular.module('ihestiaWidgets.dashboard')
  .factory('newTaskIcrClientEditModalHelper', ['LsnModalHelper',
    function(LsnModalHelper) {

      var NewTaskIcrClientEditModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;

        /**
         * Rozszerzamy opcje modala
         * @type {String}
         */
        this.setOptions({
          templateUrl: 'ihestia-widgets-templates-app/dashboard/newTask/icr/clientEdit/newTaskIcrClientEditModal.tpl.html',
          size: 'lg',
          withDefaultMargin: false,
          title: ['newTask', {
            componentCode: 'Dashboard'
          }],
          okBtnName: ['Public.confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        });

        this.addressDataMap = {
          postalCode: 'db_client_postcode',
          streetPrefix: 'db_client_street_prefix',
          street: 'db_client_street',
          city: 'db_client_city',
          house: 'db_client_house_no',
          apartment: 'db_client_flat_no',
          post: 'db_client_post_office'
        };

        this.icrAttributMap = {
          postcode: 'db_client_postcode',
          street_prefix: 'db_client_street_prefix', // eslint-disable-line
          street: 'db_client_street',
          city: 'db_client_city',
          house_no: 'db_client_house_no', // eslint-disable-line
          flat_no: 'db_client_flat_no', // eslint-disable-line
          post_office: 'db_client_post_office' // eslint-disable-line
        };

        var oldShowModal = self.showModal;
        this.showModal = function(taskData) {
          var thisFunc = this;
          if(angular.isUndefined(taskData.attributes) || taskData.attributes === null) {
            taskData.attributes = {};
          }

          if(taskData.attributes.additionalInsuredData) {
            taskData.attributes.db_client_birthdate = taskData.attributes.additionalInsuredData.birthdate; // eslint-disable-line
              angular.forEach(self.icrAttributMap, function(icrAttr, ihAttr){
                if(angular.isUndefined(taskData.attributes[icrAttr]) && taskData.attributes.additionalInsuredData[ihAttr]) {
                  taskData.attributes[icrAttr] = taskData.attributes.additionalInsuredData[ihAttr];
                }
              });
          }

          return oldShowModal.apply(thisFunc, [taskData]);
        };

      };

      return new NewTaskIcrClientEditModalHelper();
    }]);
