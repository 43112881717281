angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardWorkerConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardWorkerModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var WorkerModel = function() {
      this.objectName = 'IhestiaDashboardWorkerModel';

      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        version: {
          type: 'int'
        },
        type: {
          type: 'string'
        },
        workerId: {
          type: 'string'
        },
        agentId: {
          type: 'string'
        },
        saleProfileId: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        surname: {
          type: 'string'
        },
        poolId: {
          type: 'int'
        },
        login: {
          type: 'string'
        }
      };

      this.id = null;
      this.version = null;
      this.name = null;
      this.surname = null;
      this.agentId = null;
      this.workerId = null;
      this.saleProfileId = null;
      this.type = null;
      this.poolId = null;
      this.login = null;

    };


    WorkerModel.prototype = IHestiaAbstractModel.prototype;

    return WorkerModel;
  }]);