angular.module('salesPath2')
  /**
   * stałe dla sprzedażówek
   * @return {Object}
   */
  .provider('CONSTANTS', ['LSNCONSTANTSProvider', 'lsnDeepExtendProvider',
    function(LSNCONSTANTSProvider, lsnDeepExtendProvider) {
      var LSNCONSTANTS = LSNCONSTANTSProvider.getConstants();
      //specyficzne stałe dla SP2
      var CONSTANTS = {};
      CONSTANTS.MAIN_INSURED_OBJECT_VEHICLE = 'vehicle'; //typ głównego obiektu ubezpieczenia - pojazd
      CONSTANTS.MAIN_INSURED_OBJECT_ESTATE = 'estate'; //typ głównego obiektu ubezpieczenia - nieruchomość

      //nazwy state-ów dla poszczególnych stron produktów
      CONSTANTS.PAGE_MATRIX = 'matrix';
      CONSTANTS.PAGE_CLIENT = 'persons';
      CONSTANTS.PAGE_PRODUCT_FIRE = 'fire';
      CONSTANTS.PAGE_PRODUCT_BURGLARY = 'burglary';
      CONSTANTS.PAGE_PRODUCT_OCZP = 'oczp';
      CONSTANTS.PAGE_PRODUCT_NNW = 'nnw';
      CONSTANTS.PAGE_PRODUCT_COMMUNICATION = 'vehicleMatrix';
      CONSTANTS.PAGE_INFO = 'info';
      CONSTANTS.PAGE_SUMMARY = 'summary';
      CONSTANTS.PAGE_START_CLIENT_SEARCH = 'clientSearch';
      CONSTANTS.PAGE_START_CLIENT_EDIT = 'clientAdd';
      CONSTANTS.PAGE_INIT = 'init';
      CONSTANTS.PAGE_PRODUCT_COMPARE = 'productCompare';

      CONSTANTS.START_PAGES = [CONSTANTS.PAGE_START_CLIENT_SEARCH, CONSTANTS.PAGE_START_CLIENT_EDIT];

      CONSTANTS.POLICY_TYPE_PROPERTY = 'property';
      CONSTANTS.POLICY_TYPE_PROPERTY_CESSION = 'propertyCession';
      CONSTANTS.POLICY_TYPE_COMMUNICATION = 'communication';

      //lista elementów dodawanych do polisy
      CONSTANTS.POLICY_EXTRA_CESSION = 'CESSION';
      CONSTANTS.POLICY_EXTRA_PAYMENT = 'PAYMENT';
      CONSTANTS.POLICY_EXTRA_CLAUSES = 'CLAUSES';
      CONSTANTS.POLICY_PRODUCTS_CLAUSES = 'PRODUCTS_CLAUSES';
      CONSTANTS.POLICY_EXTRA_ATTACH = 'ATTACH';
      CONSTANTS.POLICY_EXTRA_DISCOUNT = 'DISCOUNT';
      CONSTANTS.POLICY_EXTRA_PRINT = 'PRINT';

      CONSTANTS.CONSENT_PREMIUM = 'CONSENT_PREMIUM';
      CONSTANTS.CONSENT_EXCEED = 'CONSENT_EXCEED';

      CONSTANTS.POLICY_EXTRAS = [CONSTANTS.POLICY_EXTRA_CESSION, CONSTANTS.POLICY_PRODUCTS_CLAUSES, CONSTANTS.POLICY_EXTRA_PAYMENT, CONSTANTS.POLICY_EXTRA_CLAUSES, CONSTANTS.POLICY_EXTRA_ATTACH, CONSTANTS.POLICY_EXTRA_DISCOUNT];

      CONSTANTS.PRODUCT_TYPE_LOCALIZATION = 'localization';
      CONSTANTS.PRODUCT_TYPE_VEHICLE = 'vehicle';
      CONSTANTS.PRODUCT_TYPE_PERSON = 'person';
      CONSTANTS.PRODUCT_TYPE_PERSON_GROUP = 'personGroup'; //osoby i grupy - np. ubezpieczenia w pakietach tematycznych
      CONSTANTS.PRODUCT_TYPE_PACKAGE = 'packagetype'; //package jest zarezerwowany w js
      CONSTANTS.OBJECT_TYPE_ORGANIZATION = 'organization';
      CONSTANTS.OBJECT_TYPE_GROUP = 'group'; //obejmuje tylko grupy
      CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION = 'personOrganization'; //firmy i osoby

      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE = {}; //nazwa obiektu (objectName z definicji modelu) do typu obiektu/produktu
      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE.Person = CONSTANTS.PRODUCT_TYPE_PERSON;
      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE.Organization = CONSTANTS.OBJECT_TYPE_ORGANIZATION;
      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE.Estate = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE.Vehicle = CONSTANTS.PRODUCT_TYPE_VEHICLE;
      CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE.Group = CONSTANTS.OBJECT_TYPE_GROUP;

      CONSTANTS.FORM_OF_POLICY_PERSON = 'imienna';
      CONSTANTS.FORM_OF_POLICY_GROUP = 'grupowa';
      /**
       * @type Object mapowanie typów obiektów do nazw kontenerów (przetrzymujacych je) w dataManagerze
       */
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME = {};
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = 'localizations';
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_VEHICLE] = 'vehicles';
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON] = 'persons';
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = ['persons', 'groups'];
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_ORGANIZATION] = 'organizations';
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_GROUP] = 'groups';
      CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION] = ['persons', 'organizations'];

      /**
       * @type Object mapowanie typów obiektów do nazw kontenerów (przetrzymujacych je) na polisie
       */
      CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME = {};
      CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = 'estates';
      CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_VEHICLE] = 'vehicles';
      CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON] = 'persons';
      CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_ORGANIZATION] = 'organizations';

      CONSTANTS.VARIANTS_ORDER = [LSNCONSTANTS.VARIANT_I, LSNCONSTANTS.VARIANT_II, LSNCONSTANTS.VARIANT_III, LSNCONSTANTS.VARIANT_IV];

      CONSTANTS.SUBJECT_ANY = 'any'; //dowolna osoba. Używane np. przy filtrowaniu osob z FTSa
      CONSTANTS.SUBJECT_OWNER = 'owner';
      CONSTANTS.SUBJECT_COOWNER = 'coowner';
      CONSTANTS.SUBJECT_INSURED = 'insured';
      CONSTANTS.SUBJECT_COINSURED = 'coinsured';
      CONSTANTS.SUBJECT_LEASING = 'leasing';
      CONSTANTS.SUBJECT_BANK = 'bank';
      CONSTANTS.SUBJECT_INSURED_NNW = 'insuredNnw';
      CONSTANTS.SUBJECT_COINSURED_NNW = 'coinsuredNnw';
      CONSTANTS.SUBJECT_ALIASES = {}; //aliasy dla ról - oznaczają to samo, ale BOS czasami wrzuca na ryzyko istniejącą już rolę, tylko z innym IDPM i musimy robić takie cuda
      CONSTANTS.SUBJECT_ALIASES[CONSTANTS.SUBJECT_INSURED] = CONSTANTS.SUBJECT_INSURED_NNW;
      CONSTANTS.SUBJECT_ALIASES[CONSTANTS.SUBJECT_COINSURED] = CONSTANTS.SUBJECT_COINSURED_NNW;

      //ogólne role osób na wniosku (np. dla personsCtrl)
      CONSTANTS.PERSON_ROLE_NONE = 'none'; //brak roli
      CONSTANTS.PERSON_ROLE_INSURED = 'insured'; //ubezpieczony
      CONSTANTS.PERSON_ROLE_COOWNER = 'coowner'; //współwłaściciel
      CONSTANTS.PERSON_ROLE_INSURER = 'insurer'; //ubezpieczający

      CONSTANTS.ETX_CATEGORY_PASSENGER = 10;
      CONSTANTS.ETX_CATEGORY_OFFROAD = 20;

      CONSTANTS.ALL_OBJECTS = '-1'; //wszystkie lokalizacje/pojazdy w selectedLocalization/selectedVehicle
      CONSTANTS.NO_OBJECT = '0'; //nie wybrano obiektu
      CONSTANTS.SUM_NAME = '_sum';

      //tryby dzialania aplikacji
      CONSTANTS.APP_MODE_CALCULATION = 'calculation'; //tworzenia wniosku
      CONSTANTS.APP_MODE_OFFER = 'offer'; //oferta
      CONSTANTS.APP_MODE_POLICY = 'policy'; // podgląd polis(y)
      CONSTANTS.APP_MODE_RENEW_CHOICE = 'renew_choice'; // wybór polis do wznowienia

      CONSTANTS.LIQUIDATION_ASO = 'SERVICE';
      CONSTANTS.LIQUIDATION_PRICE = 'PRICE';
      CONSTANTS.LIQUIDATION_NET = 'NET';

      CONSTANTS.POLICY_TYPE_STANDARD = 'Standardowa';
      CONSTANTS.POLICY_TYPE_CLIENT = 'Klienta';
      CONSTANTS.POLICY_TYPE_CESSION = 'Cesyjna';

      CONSTANTS.PRINT_CODE_CALCULATION = 'KALKULACJA';
      CONSTANTS.PRINT_CODE_VIEW_CALCULATION = 'PODGLAD_KALKULACJI';
      CONSTANTS.PRINT_CODE_OFFER = 'OFERTA';
      CONSTANTS.PRINT_CODE_SUMMARY = 'PODSUMOWANIE';
      CONSTANTS.PRINT_CODE_RENOUNCEMENT = 'ODSTAPIENIE';
      CONSTANTS.PRINT_CODE_POLICY = 'POLISA';
      CONSTANTS.PRINT_GREEN_CARD = 'ZK';

      CONSTANTS.BRAK_DANYCH = 'noData'; //nazwa labela z komponentu Public
      CONSTANTS.BRAK = 'brak';

      CONSTANTS.NO_EXTENSION = 'NO_EXTENSION';

      CONSTANTS.OFFER_STATE_EXPIRED = 'EXPIRED';


      CONSTANTS.BUSINESS_PARTNER_TYPE_PERSON = 'P';
      CONSTANTS.BUSINESS_PARTNER_TYPE_ORGANIZATION = 'O';
      CONSTANTS.BUSINESS_OBJECT_TYPE_NIE = 'NIE';
      CONSTANTS.BUSINESS_OBJECT_TYPE_POJ = 'POJ';
      CONSTANTS.ORGANIZATION_TYPE_LEASING = 'leasing';
      CONSTANTS.ORGANIZATION_TYPE_BANK = 'bank';
      CONSTANTS.AMORTIZATION_TYPE = {
        PRICE: 1,
        NET: 2,
        SERVICE: 3
      };
      CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_BASIC = 'Podstawowy';
      CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_EXTENDED = 'Rozszerzony';
      CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP = {};
      CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP[LSNCONSTANTS.VARIANT_II] = 1;
      CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP[LSNCONSTANTS.VARIANT_III] = 2;
      CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP[LSNCONSTANTS.VARIANT_IV] = 3;
      CONSTANTS.ESTATE_NAME_FLAT = 'Mieszkanie';
      CONSTANTS.ESTATE_NAME_HOUSE = 'Dom';
      CONSTANTS.LOCALIZATION_TYPE_FLAT = 'flat';
      CONSTANTS.LOCALIZATION_TYPE_HOUSE = 'house';

      CONSTANTS.CONTROL_TYPE_CHECKBOX = 'checkbox';
      CONSTANTS.CONTROL_TYPE_SELECT = 'select';
      CONSTANTS.CONTROL_TYPE_INPUT = 'input';
      CONSTANTS.CONTROL_TYPE_INPUT_NUMERIC = 'inputNumeric';

      CONSTANTS.JOIN_METHOD_OR = 'or';
      CONSTANTS.JOIN_METHOD_AND = 'and';

      CONSTANTS.EQUAL_TYPE_EQUAL = 'equal';
      CONSTANTS.EQUAL_TYPE_NOT_EQUAL = 'notEqual';
      CONSTANTS.EQUAL_TYPE_EMPTY = 'empty';
      CONSTANTS.EQUAL_TYPE_NOT_EMPTY = 'notEmpty';
      CONSTANTS.EQUAL_TYPE_ONE_OF = 'oneOf';
      CONSTANTS.EQUAL_TYPE_EXCLUDES = 'excludes';

      CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_SCAN = 'Elektroniczny';
      CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_PAPER = 'Papierowy';
      CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_IOFFER = 'Zatwierdzona z iOferty';

      // produkty do stanu - ekranu
      CONSTANTS.PRODUCT_TO_STATE = {};
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_ACKOM] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_NNWKIP] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_OCKOM] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_OGI] = CONSTANTS.PAGE_PRODUCT_FIRE;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_KRA] = CONSTANTS.PAGE_PRODUCT_BURGLARY;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_OCZP] = CONSTANTS.PAGE_PRODUCT_OCZP;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_NNW] = CONSTANTS.PAGE_PRODUCT_NNW;
      CONSTANTS.PRODUCT_TO_STATE[LSNCONSTANTS.PRODUCT_NNW_INCOGNITO] = CONSTANTS.PAGE_PRODUCT_NNW;

      //mapowanie produktów specjalnych na produkty "zwykłe" (chodzi o pobieranie ich definicji z CONFIG)
      CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP = {};
      CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[LSNCONSTANTS.PRODUCT_NNW_INCOGNITO] = LSNCONSTANTS.PRODUCT_NNW;
      CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[LSNCONSTANTS.PRODUCT_OGI_KRA] = LSNCONSTANTS.PRODUCT_OGI;

      //dodatki do strony produktu - tylko te jednoznaczne
      CONSTANTS.ADD_TO_STATE = {};
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_ASSPOJ] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_GLAZING] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_HCA_TOWING_VEHICLE] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_HCA_THEFT] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_HCA_REPLACEMENT_VEHICLE] = CONSTANTS.PAGE_PRODUCT_COMMUNICATION;
      CONSTANTS.ADD_TO_STATE[LSNCONSTANTS.ADD_OCNAJ] = CONSTANTS.PAGE_PRODUCT_FIRE;


      CONSTANTS.OCZP_JOB_CHOOSE = '-1';
      CONSTANTS.OCZP_JOB_DOCTOR = '1';

      CONSTANTS.NNW_INCOGNITO_PERSON_ID = '0'; //id osoby dla przypadku NNW forma bezimienna

      CONSTANTS.COMMUNICATION_PRODUCTS = [
        LSNCONSTANTS.PRODUCT_ACKOM,
        LSNCONSTANTS.PRODUCT_NNWKIP,
        LSNCONSTANTS.PRODUCT_OCKOM
      ];
      CONSTANTS.LOCALIZATION_PRODUCTS = [
        LSNCONSTANTS.PRODUCT_OGI,
        LSNCONSTANTS.PRODUCT_KRA
      ];

      CONSTANTS.PERSON_CONTEXT_CLIENT = 'client';
      CONSTANTS.PERSON_CONTEXT_COOWNER = 'coowner';

      //errors
      CONSTANTS.ERROR_TYPE = {
        error: 'error',
        warning: 'warning',
        warn: 'warn',
        info: 'info',
        notice: 'notice',
        unsupported: 'bos'
      };

      /**
       * mapowanie kodów ryzyk z urla do kodów produktów w aplikacji
       * @type {Object}
       */
      CONSTANTS.URL_RISK_TO_PRODUCT = {
        AC: LSNCONSTANTS.PRODUCT_ACKOM,
        OC: LSNCONSTANTS.PRODUCT_OCKOM,
        NNWKIP: LSNCONSTANTS.PRODUCT_NNWKIP,
        NNW: LSNCONSTANTS.PRODUCT_NNW,
        OCZP: LSNCONSTANTS.PRODUCT_OCZP,
        OGIEN: LSNCONSTANTS.PRODUCT_OGI,
        KRADZIEZ: LSNCONSTANTS.PRODUCT_KRA,
        REHABILITATION: LSNCONSTANTS.PRODUCT_REHABILITATION
      };

      /**
       * mapowanie kodów dodatków z urla do kodów dodatków w aplikacji
       * @type {Object}
       */
      CONSTANTS.URL_ADD_TO_ADD = {
        CAR_ASSISTANCE_AWARIA: {
          code: LSNCONSTANTS.ADD_ASSPOJ,
          variant: LSNCONSTANTS.VARIANT_II
        },
        CAR_ASSISTANCE_PREMIUM: {
          code: LSNCONSTANTS.ADD_ASSPOJ,
          variant: LSNCONSTANTS.VARIANT_III
        },
        CAR_ASSISTANCE_PRESTIGE: {
          code: LSNCONSTANTS.ADD_ASSPOJ,
          variant: LSNCONSTANTS.VARIANT_IV
        },
        OUZ_OC: {
          code: LSNCONSTANTS.ADD_OUZ,
          variant: LSNCONSTANTS.VARIANT_I
        },
        OUZ_AC: {
          code: LSNCONSTANTS.ADD_OUZ,
          variant: LSNCONSTANTS.VARIANT_II
        },
        SOS_ASSISTANCE: {
          code: LSNCONSTANTS.ADD_ASSDOM,
          variant: LSNCONSTANTS.VARIANT_II
        },
        HOME_ASSISTANCE: {
          code: LSNCONSTANTS.ADD_ASSDOM,
          variant: LSNCONSTANTS.VARIANT_I
        }
      };
      /**
       * mapowanie kodów wariantów z urla do wariantów w aplikacji
       * @type {Object}
       */
      CONSTANTS.URL_VARIANT_TO_VARIANT = {
        1: LSNCONSTANTS.VARIANT_I,
        2: LSNCONSTANTS.VARIANT_II,
        3: LSNCONSTANTS.VARIANT_III,
        4: LSNCONSTANTS.VARIANT_IV
      };

      CONSTANTS.PROROGATION_ATTR_DAYS = 'PaymentProrogation'; //atrybut przechowujący odroczenie płatności w dniach
      CONSTANTS.PROROGATION_ATTR_DATE = 'RenewalCalendarDates'; //atrybut przechowujący odroczenie płatności jako datę

      CONSTANTS.PAYMENT_METHOD_TRANSFER = 'TRANSFER';
      CONSTANTS.PAYMENT_METHOD_CASH = 'CASH';
      CONSTANTS.PAYMENT_METHOD_CARD = 'CARD';
      CONSTANTS.PAYMENT_METHOD_BLIK = 'BLIK';

      CONSTANTS.VALUE_TYPE_BRUTTO = 'brutto';
      CONSTANTS.VALUE_TYPE_NETTO = 'netto';
      CONSTANTS.VALUE_TYPE_NETTO_PLUS_50 = 'VAT 50%';

      //rozszerzamy definicje z lsnBase
      CONSTANTS = lsnDeepExtendProvider.deepExtend(LSNCONSTANTS, CONSTANTS);

      /**
       * Zwracamy stałe
       * @return {object} [description]
       */
      this.getConstants = function() {
        return angular.copy(CONSTANTS);
      };

      /**
       * get providera
       * @return {object} [description]
       */
      this.$get = function() {
        return CONSTANTS;
      };
    }
  ]);
