angular.module('ihestiaIkomunikator.common')
  .provider('ihestiaIkomunikatorCommonHelper', [function() {
    var self = this;

    this.defaultConfig = {
      notificationBarTemplate: 'ihestiaIkomunikatorTemplatesApp/views/common/notificationBar/notificationBar.tpl.html',
      stateNames: {
        fullListPanel: null, //nazwa stanu dla historii nalezy go ustawic jesli state znajduje sie w aplikacji
        details: null //nazwa stanu dla podgladu watku nalezy go ustawic jesli state znajduje sie w aplikacji
      },
      available: true // czy komunikator jest dostepny, mozna wylaczyc np dla uzytkownika typu klient w jupiterze
    };

    /**
     * Magic get
     * zwraca to, co wcześniej service
     * @type {Array}
     */
    this.$get = ['ihestiaCommonErrorHandler', '$timeout',
      function(ihestiaCommonErrorHandler, $timeout) {

        self.throwException = function(msg) {
          $timeout(function() {
            ihestiaCommonErrorHandler.throwException(msg);
          }, 0);
        };

        return self;
      }
    ];
  }]);