/**
 * Dyrektywa do wrzucenia szablonu navbara
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaMenuHelp')
  .directive('ihestiaMenuHelp', ['$templateCache', 'ihestiaMenuHelpHelper', '$document', 'ihestiaConfigHelper', 'ihestiaMenuHelpConfig',
    function($templateCache, ihestiaMenuHelpHelper, $document, ihestiaConfigHelper, ihestiaMenuHelpConfig) {
      var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: function(elem, attrs) {
          return attrs.drvTemplate || ihestiaMenuHelpConfig.defaultConfig.template;
        },
        link: function($scope) {
          $scope.elementsAvailability.help = $scope.elementsAvailability.help && !ihestiaConfigHelper.get('frontFlags', 'IS_PUBLIC');
        }
      };

      $document.on('click', function() {
        ihestiaMenuHelpHelper.hideWindowWithHelp();
      });

      return directive;
    }
  ]);