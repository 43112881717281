angular.module('lsnBase.stringPremium')
  .filter('stringPremium', ['$filter',
    function($filter) {
      return function(premium, emptyValue, currency) {
        premium = parseFloat(premium, 10);
        if (angular.isUndefined(emptyValue)) {
          emptyValue = '-';
        }
        if (angular.isUndefined(currency)) {
          currency = 'zł';
        }
        if (premium === 0 || isNaN(premium)) {
          return emptyValue;
        }
        return $filter('number')(premium, (premium % 1 !== 0) ? 2 : 0) + '\u00A0' + currency; // jeśli jakaś część dziesiętna, to zaokrąglamy do 2 miejsc po przecinku
      };
    }
  ]);