angular.module('salesPath2')
  /**
   * ogólny helper dla SP2
   * zaweira metody dotyczące:
   * - uprawnień
   * - obsługi wyjątkow
   */
  .service('sp2CommonHelper', ['sp2UiHelper', 'ihestiaCommonErrorHandler', 'ihestiaConfigHelper', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'ApplicationModelConstants', 'ihestiaAgHelper', 'ihestiaCommonConfirmModalHelper', 'iHestiaCommonModalHelper', 'ihestiaTryEscapeHtml', 'SPD', 'CONSTANTS', 'appVariables', '$timeout', '$filter', 'CONFIG', 'gaAccessUserSvc',
    function(sp2UiHelper, ihestiaCommonErrorHandler, ihestiaConfigHelper, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, ApplicationModelConstants, ihestiaAgHelper, ihestiaCommonConfirmModalHelper, iHestiaCommonModalHelper, ihestiaTryEscapeHtml, SPD, CONSTANTS, appVariables, $timeout, $filter, CONFIG, gaAccessUserSvc) {
      var CommonHelper = function() {
        var self = this;
        //typy charakterow dla pierwszej grupy uprawnionych - tzw. charaktery sprzedażowe
        this.charTypes1 = [
          UserCharacterTypeConstants.CHARACTER_TYPE_WORKER,
          UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER,
          UserCharacterTypeConstants.CHARACTER_TYPE_DIRECT_SALE
        ];
        //typy charakterów dla drugirj grupy uprawnionych - tzw. charaktery niesprzedażowe
        this.charTypes2 = [
          UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE,
          UserCharacterTypeConstants.CHARACTER_TYPE_BRANCH,
          UserCharacterTypeConstants.CHARACTER_TYPE_MANAGER,
          UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL
        ];
        //statusy wniosku dostępne na charakterach sprzedażowych
        this.allowedSalesCharacterStatuses = [
          ApplicationModelConstants.APPLICATION_STATUS_CALCULATION,
          ApplicationModelConstants.APPLICATION_STATUS_OFFER,
          ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED,
          ApplicationModelConstants.APPLICATION_STATUS_IOFFER,
          ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED,
          ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED
        ];

        /**
         * whether GA has already been checked (received from service)
         * @type {boolean}
         */
        this.generalAgreementsChecked = false;

        /**
         * ukrywa wszystkie aktywne popupy
         */
        this.hideActivePopups = function() {
          iHestiaCommonModalHelper.hideActivePopups();
        };

        /**
         * zgłoszenie wyjątku
         * @param  {String} txt treść błędu
         * @return {?}
         */
        this.throwException = function(txt) {
          sp2UiHelper.hideBlockUi();
          $timeout(function() {
            return ihestiaCommonErrorHandler.throwException(txt);
          }, 0);
        };

        /**
         * czy aktualnie zalogowany user jest charakterem sprzedazowym
         * @return {Boolean}
         */
        this.isSalesCharacter = function() {
          return self.charTypes1.indexOf(ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type) !== -1;
        };

        /**
         * czy wniosek w trybie wznowienia ustawowego
         * @param  {Object}  application obiekt zgodny z ApplicationMoel
         * @return {Boolean} true, gdy tryb wznowienia ustawowego
         */
        this.isStatutoryRenewal = function(application) {
          var centralSt = false;
          if (application.operationType === ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW && angular.isArray(application.policies)) {
            lsnNg.forEach(application.policies, function(pol) {
              if (pol.product.dynamicValues && pol.product.dynamicValues.renewalVariant === 1) {
                centralSt = true;
                return false;
              }
              return true;
            });
          }
          return centralSt;
        };

        /**
         * czy dla aktualnie zalogowanego usera renderować okno/link w menu do czynnikó∑ taryfikacyjnych
         * IHESTIASS-53
         * @return {Boolean}
         */
        this.canRenderTariffFactors = function() {
          var deniedCharTypes = [
            UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE,
            UserCharacterTypeConstants.CHARACTER_TYPE_WORKER
          ];
          return deniedCharTypes.indexOf(ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type) === -1;
        };

        /**
         * czy użytkownik ma dostep do widoku i edycji wniosku
         * Jeśli brak uprawnień do widoku (specyficzne sytuacje, normalnie blokada z backendu) to przekierowanie na strone "brak uprawnień"
         * @param {String} type typ sprawdzanego uprawnienia
         * dostępne opcje:
         * 'view' - ogolny podglad wniosku/oferty/polisy
         * 'edit' - edycja wniosku
         * 'backToEdit' - powrót do edycji
         * 'policyAccept' - zatwierdzanie wnioksu do polisy
         * 'offer' - oferta wiazaca
         * 'iOffer' - iOferta - wyślij do ikonta
         * 'viewEdit' widocznosc przycisku "zapisz wniosek"
         * 'viewBackToEdit' widocznosc przycisku "powrot do edycji"
         * 'viewPolicyAccept' widocznosc przycisku "zatwierdź polisy"
         * 'viewOffer' widocznosc przycisku "oferta wiążąca"
         * 'viewIOffer' widocznosc przycisku "wyslij do ikonta"
         * @param {ApplicationModel} application wniosek
         * @param {Object} availableOperations dostępne operacje - flagi otrzymane z usługi REST application/.../availableOperations
         * @return {Boolean} [description]
         */
        this.hasFinalApplicationPermission = function(type, application, availableOperations) {
          var isGroup1 = self.isSalesCharacter(),
            isGroup2 = self.charTypes2.indexOf(ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type) !== -1,
            charGroup = isGroup1 ? 'group1' : (isGroup2 ? 'group2' : null),
            defines = ihestiaConfigHelper.get('ihsgDefines'),
            agPermission = (type === 'edit') ? ihestiaAgHelper.hasFinalPolicyPermissionFor(defines.AG_SYMBOL_POLICY_SALE) : ihestiaAgHelper.hasFinalPolicyPermissionFor(defines.AG_SYMBOL_POLICY_VIEW),
            canManage = appVariables.canManage,
            applicationStatus = application.status || ApplicationModelConstants.APPLICATION_STATUS_CALCULATION,
            //czy tryb oferty wznowieniowej ustawowej
            isStatutoryRenewal = self.isStatutoryRenewal(application),
            srAcceptPermission = (isStatutoryRenewal ? availableOperations.canAcceptOffer === true : true),
            srBackToEditPermission = (isStatutoryRenewal ? availableOperations.canEditOffer === true : true);

          if (charGroup === null) {
            return false; //nieobslugiwany typ characteru
          }
          var allowed = false;
          switch (charGroup) {
            case 'group1': //charaktery sprzedażowe
              switch (type) {
                case 'view':
                  allowed = agPermission; //reszta po stronie uslugi
                  break;
                case 'viewOffer':
                  if (agPermission && applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) {
                    allowed = true;
                  }
                  break;
                case 'offer':
                  if (agPermission && canManage && applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) {
                    allowed = true;
                  }
                  break;
                case 'viewIOffer':
                  if (agPermission && applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_OFFER) {
                    allowed = true;
                  }
                  break;
                case 'iOffer':
                  if (agPermission && canManage && applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_OFFER) {
                    allowed = true;
                  }
                  break;
                case 'viewEdit':
                  if (agPermission && [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION].indexOf(applicationStatus) !== -1) {
                    allowed = true;
                  }
                  break;
                case 'edit':
                  if (agPermission && canManage && [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION].indexOf(applicationStatus) !== -1) {
                    allowed = true;
                  }
                  break;
                case 'viewBackToEdit':
                  if (agPermission && [ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED].indexOf(applicationStatus) !== -1 && srBackToEditPermission) {
                    allowed = true;
                  }
                  break;
                case 'backToEdit':
                  if (agPermission && canManage && [ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED].indexOf(applicationStatus) !== -1 && srBackToEditPermission) {
                    allowed = true;
                  }
                  break;
                case 'viewPolicyAccept':
                  if (agPermission && [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION, ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER].indexOf(applicationStatus) !== -1 && srAcceptPermission) {
                    allowed = true;
                  }
                  break;
                case 'policyAccept':
                  if (agPermission && canManage && [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION, ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER].indexOf(applicationStatus) !== -1 && srAcceptPermission) {
                    allowed = true;
                  }
                  break;
                default:
                  break;
              }
              break;
            case 'group2': //charaktery niesprzedażowe
              switch (type) {
                case 'view':
                  allowed = agPermission; //reszta po stronie uslugi
                  break;
                case 'viewEdit':
                  if ([ApplicationModelConstants.APPLICATION_STATUS_CALCULATION].indexOf(applicationStatus) !== -1) {
                    allowed = true;
                  }
                  break;
                case 'viewOffer':
                  if (applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) {
                    allowed = true;
                  }
                  break;
                case 'viewIOffer':
                  if (applicationStatus === ApplicationModelConstants.APPLICATION_STATUS_OFFER) {
                    allowed = true;
                  }
                  break;
                case 'viewBackToEdit':
                  if ([ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED].indexOf(applicationStatus) !== -1 && srBackToEditPermission) {
                    allowed = true;
                  }
                  break;
                case 'viewPolicyAccept':
                  if ([ApplicationModelConstants.APPLICATION_STATUS_CALCULATION, ApplicationModelConstants.APPLICATION_STATUS_OFFER, ApplicationModelConstants.APPLICATION_STATUS_IOFFER].indexOf(applicationStatus) !== -1 && srAcceptPermission) {
                    allowed = true;
                  }
                  break;
                default:
                  break;
              }
              break;

            default:
              break;
          }
          return allowed;
        };

        /**
         * czy użytkownik ma uprawnienia do podglądu/edycji polis
         * @param  {String}  action nazwa uprawnienia: 'AG_SYMBOL_POLICY_VIEW' lub 'AG_SYMBOL_POLICY_SALE'
         * @return {Boolean} true gdy ma uprawnienia
         */
        this.hasFinalPolicyPermissionFor = function(action) {
          return ihestiaAgHelper.hasFinalPolicyPermissionFor(ihestiaConfigHelper.get('ihsgDefines')[action]);
        };
        /**
         * wyświetla modala typu confirm
         * @param  {Object} params paramtry (obecnie takie jakie obsługuje ihestiaCommonConfirmModalHelper)
         */
        this.showConfirmModal = function(params) {
          ihestiaCommonConfirmModalHelper.showConfirmModal(params);
        };

        /**
         * otwiera okno modalne
         * @param  {String} modalCode kod modala
         */
        this.showModal = function(modalCode) {
          iHestiaCommonModalHelper.showModal(modalCode);
        };

        /**
         * ukrywa/zamyka okno modalne
         * @param  {String} modalCode kod modala
         */
        this.hideModal = function(modalCode) {
          iHestiaCommonModalHelper.hideModal(modalCode);
        };

        /**
         * zwraca config ze strony serwera
         * @return {ihestiaConfigHelper}
         */
        this.getServerConfig = function() {
          return ihestiaConfigHelper;
        };

        this.showTemporaryHiddenPopups = function() {
          iHestiaCommonModalHelper.showTemporaryHiddenPopups();
        };

        this.tryEscapeHtml = function(data) {
          return ihestiaTryEscapeHtml(data);
        };

        this.getAgHelper = function() {
          return ihestiaAgHelper;
        };

        /**
         * zwraca typ polisy z przekazanej polisy
         */
        this.getPolicyType = function(policy) {
          var policyType = CONSTANTS.POLICY_TYPE_PROPERTY;
          if (policy.product.compId === SPD.salesProducts.Communication.idpm) {
            policyType = CONSTANTS.POLICY_TYPE_COMMUNICATION;
          }

          return policyType;
        };

        /**
         * Sprawdzenie czy produkt z polisy jest tożsamy z aplikacją w której go otwieramy
         * @return {Boolean} true gdy wspierany
         */
        this.isPolicySupported = function(compId) {
          var productArray = [];

          angular.forEach(SPD.salesProducts, function(group) {
            if (angular.isArray(group.idpm)) {
              productArray.concat(group.idpm);
            } else {
              productArray.push(group.idpm);
            }
          });
          return productArray.indexOf(compId) !== -1;
        };

        /**
         * zapis pliku pdf na podstawie danych z uslugi
         * @param  {Object} result dane pliku pdf
         * @param {String} [mime='application/pdf'] mimeType dla pliku
         * @return {undefined}
         */
        this.saveFile = function(result, mime) {
          mime = mime || 'application/pdf';
          var filename = $filter('translate')('Public.exportFileName') + '.pdf'; // jak nie dostaniemy nazwy z usługi
          var contentDisposition = result.headers('Content-Disposition');

          var fileNameString = 'filename=';
          if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
            var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
            if (preCut.indexOf(';') !== -1) {
              preCut.substr(0, preCut.indexOf(';'));
            }
            filename = decodeURIComponent(preCut);
          }

          var blob = new Blob([result.data], {
            type: mime
          });
          saveAs(blob, filename);
        };

        /**
         * set allowed GA for current user
         * @return {undefined}
         */
        this.setAllowedGeneralAgreements = function() {
          if (self.generalAgreementsChecked) {
            return;
          } else {
            if (!self.isSalesCharacter()) {
              self.generalAgreementsChecked = true;
              return;
            }
            gaAccessUserSvc.get().then(function(respObj) {
              appVariables.allowedGeneralAgreements = respObj.data;
              return true;
            }, function() {
              appVariables.allowedGeneralAgreements = [];
              return false;
            }).finally(function() {
              self.generalAgreementsChecked = true;
            });
          }
        };

        /**
         * get first general agreement number starting with given prefix
         * @param {string} gaPrefix general agreement prefix
         * @return {string|null} null if no suitable general agreement found
         */
        this.getAllowedGeneralAgreement = function(gaPrefix) {
          return _.find(appVariables.allowedGeneralAgreements, function(el) {
            return el.indexOf(gaPrefix) === 0;
          }) || null;
        };

        /**
         * get property insurance agency number for logged user
         * @return {null|string} null if can't retrieve
         */
        this.getUserAgencyNumber = function() {
          var character = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter();
          if (character.ossContextInfo && character.ossContextInfo.AgencyNumberProperty) {
            return character.ossContextInfo.AgencyNumberProperty;
          } else {
            return null;
          }
        };

      };
      return new CommonHelper();
    }
  ]);