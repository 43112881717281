/**
 * Dyrektywa - przekazywanie options do grida
 * @param  {[type]} $templateCache      [description]
 * @param  {[type]} $parse
 * @return {[type]}                     [description]
 */
angular.module('ihestiaGrid')
  .directive('gridOptions', ['$parse', 'ihestiaGridConfig',
    function($parse, ihestiaGridConfig) {
      var directive = {
        link: function($scope, $element, $attrs) {
          $scope.options = angular.extend($scope.options, ihestiaGridConfig.localOptions ,$parse($attrs.gridOptions)($scope));
          $scope.optionsLoaded();
        }
      };

      return directive;
    }
  ]);