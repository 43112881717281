angular.module('lsnBase.models')
  .service('arrayUpperCaseModelFilter', ['strUpperCaseModelFilter', function(strUpperCaseModelFilter) {
    /**
     * zamienia elementy 1 poziomu w tablicy na UpperCase
     * @param {*} val
     * @returns {Array}
     */
    return function(val) {
      if (!$.isArray(val)) {
        return val;
      }

      var resultArray = [];
      angular.forEach(val, function(item) {
        resultArray.push((!angular.isString(item)) ? item : strUpperCaseModelFilter(item));
      });

      return resultArray;
    };
  }]);