/**
 * Model danych charakteru
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase.characterModel', [
  'ihestiaSsoBase.constants'
])
  .provider('IHestiaCharacterModel', ['IhestiaEmployeeTypeConstantsProvider',
    function(IhestiaEmployeeTypeConstantsProvider) {
      var IHestiaCharacterModelProvider = this;

      this.getModel = function()
      {
        var IHestiaCharacterModel = function() {
          var self = this;

          this.active = false;
          this.name = '';
          this.code = '';
          this.type = '';
          this.isBlocked = false;
          this.ossContextInfo = {};

          /**
           * Ustawiamy dane na podstawie danych z usługi
           * @param {[type]} data [description]
           */
          this.setData = function(data) {
            // przypisanie wartości
            angular.forEach(data, function(value, fieldCode){
              if(typeof self[fieldCode] !== 'undefined') {
                self[fieldCode] = value;
              }
            });
            // specyficzne przeparsowanie
            angular.forEach(self.ossContextInfo, function(ossValue, ossCode){
              if(ossValue === 'false'){
                self.ossContextInfo[ossCode] = false;
              } else if(ossValue === 'true') {
                self.ossContextInfo[ossCode] = true;
              }
            });
            return self;
          };

          /**
           *
           * @returns {boolean}
           */
          this.isUwr = function() {
              return self.ossContextInfo.EmployeeType === IhestiaEmployeeTypeConstantsProvider.UWR;
          };

          /**
           * Zwraca customerId (ikontoId), jeśli tożsamość ma typ client
           * @return {string|null} customerId
           */
          this.getCustomerId = function() {
            return self.ossContextInfo.iKontoId ? self.ossContextInfo.iKontoId : null;
          };
        };
        return IHestiaCharacterModel;
      };

      /**
       * Magic get
       * zwraca to, co wcześniej service
       * @type {Array}
       */
      this.$get = [
        function() {
          var IHestiaCharacterModel = IHestiaCharacterModelProvider.getModel();
          return IHestiaCharacterModel;
        }
      ];

    }]);
