angular.module('ihestiaGrid')
  .service('ihestiaGridModalDetailsHelper', [ '$rootScope', 'iHestiaCommonModalHelper',
    function($rootScope, iHestiaCommonModalHelper){
    var IhestiaGridModalDetailsHelper = function(){

      /**
       * [init description]
       * @return {[type]} [description]
       */
      this.init = function(){
        if(typeof $rootScope.modals === 'undefined')
        {
          $rootScope.modals = {};
        }

        $rootScope.modals.gridDetailsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            cancelBtnName: ['Public.close', {componentCode: 'Public'}],
            okBtnName: '',
            title: ['Public.details', {componentCode: 'Public'}]
          },
          item: null,
          detailsFields: null
        };
      };

      /**
       * [showModal description]
       * @param  {[type]} item          [description]
       * @param  {[type]} detailsFields [description]
       * @return {[type]}               [description]
       */
      this.showModal = function(item, detailsFields)
      {
        $rootScope.modals.gridDetailsModal.item = item;
        $rootScope.modals.gridDetailsModal.detailsFields = detailsFields;
        iHestiaCommonModalHelper.showModal('ihestiaGridModalDetails');
      };

    };

    return new IhestiaGridModalDetailsHelper();
  }])
  .run(['ihestiaGridModalDetailsHelper', function(ihestiaGridModalDetailsHelper) {
    ihestiaGridModalDetailsHelper.init();
  }]);