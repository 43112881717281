angular.module('ihestiaWidgets.policyOperations.common')
  .factory('policyTerminationHelper', ['$q', 'policyOperationsSvc', 'ihestiaSsoBaseInfoHelper', '$timeout',
    function($q, policyOperationsSvc, ihestiaSsoBaseInfoHelper, $timeout) {

      var settlementLoadGap = 2000; //co ile ms odpytywać usługę czekając na składki

      var PolicyTerminationHelper = function() {

        var self = this;

        this.promiseCallbacks = {
          reject: null,
          resolve: null
        };
        this.applicationId = null;

        this.summary = null; //dane z zapisu rozwiązania/anulowania
        this.policyNumber = null; //numer polisy potrzebny do przekierowania na 'zakończ' w polisaonline, ustawiamy go zawsze równolegle z summary

        this.title = null; //tytuł do breadcrumbs
        this.titleLabel = null; //labelka tytułu do breadcrumbs

        this.storedApplicationData = null; //dane aplikacji dla roszerzeń zapisane po 1 kroku

        this.causeCode = null; // przyczyna rozwiązania

        this.endOfInsurancePeriodNoticeCauses = null;

        this.getApplicationResolve = function(response, allowEmptySettlement){
          var responseValid = false;
          //nie mamy jeszcze settlement, a usługa mówi, że trzeba czekać
          if(!response.data.settlement && response.data.isSimulationRequired)
          {
            //jeśli wypowiedzenie z końcem okresu ubezpieczenia to niepotrzebne nam są składki
            if(response.data.terminationCause && response.data.terminationCause === 'EndOfInsurancePeriodNotice')
            {
              responseValid = true;
            }

            else if(
              // (ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === 'Central' && !response.data.operationDate) ||  //Centrala jak nie poda daty na pierwszym kroku to też składek nie dostanie
              allowEmptySettlement) // jak wczytujemy wniosek, ale nie na ekranie ze składkami
            {
              responseValid = true;
            }
          }
          else
          {
            //mamy settlement
            responseValid = true;
          }

          if(responseValid)
          {
            self.promiseCallbacks.resolve(response);
            return self;
          }
          else
          {
            $timeout(function() {
              policyOperationsSvc.get(self.applicationId).then(self.getApplicationResolve, self.getApplicationReject);
            }, settlementLoadGap);
            return self;
          }
        };

        /**
         * Pobieramy wniosek i jeśli stwiedzamy, że potrzebujemy settlement a jeszcze nie ma to odpytujemy ponownie
         * @param  {int} applicationId
         * @return {promise}
         */
        this.getApplicationWithSettlement = function(applicationId, allowEmptySettlement)
        {
          return $q(function(resolve, reject) {
            self.promiseCallbacks = {
              reject: reject,
              resolve: resolve
            };
            self.applicationId = applicationId;
            policyOperationsSvc.get(applicationId).then(
              function(response){
                return self.getApplicationResolve(response, allowEmptySettlement);
              }, self.getApplicationReject);
          });
        };

        /**
         * jak usługa sypnie czymś nieprawidłowym
         * @param  {object} rej
         * @return {PolicyTerminationHelper}
         */
        this.getApplicationReject = function(rej)
        {
          self.promiseCallbacks.reject(rej);
          return self;
        };
      };



      return new PolicyTerminationHelper();
    }]);