/**
 * helper dokupień i doubezpieczeń
 */
angular.module('salesPath2').service('supplementHelper', ['appVariables', 'RESOURCES', 'resourceHelper', 'CONSTANTS', 'CONFIG', 'MapperUtils', 'lsnArrayUnique', 'AllowedFormAttributeModel', 'sp2CommonHelper', 'dataContainerHelper', 'mainDataContainer',
  function(appVariables, RESOURCES, resourceHelper, CONSTANTS, CONFIG, MapperUtils, lsnArrayUnique, AllowedFormAttributeModel, sp2CommonHelper, dataContainerHelper, mainDataContainer) {
    var SupplementHelper = function() {
      var self = this;

      /**
       * zwraca listę ryzyk (pojedynczych) obowiązujących w poprzedniej wersji polisy
       * @param  {RiskModel[]} risks lista ryzyk z simplePolicy na wniosku
       * @return {RiskModel[]} lista ryzyk
       */
      this.getPreviousPolicyRisks = function(risks) {
        var previousRisks = [],
          parentIds = []; //id ryzyk, które są rodzicami innych ryzyk
        //zbieramy rodziców dla historycznych ryzyk
        angular.forEach(risks, function(risk) {
          if (risk.get('isHistorical')) {
            var parentId = risk.get('parentRef');
            if (parentId && parentIds.indexOf(parentId) === -1) {
              parentIds.push(parentId);
            }
          }
        });
        angular.forEach(risks, function(risk) {
          if (risk.get('isHistorical') && parentIds.indexOf(risk.metaData.get('id')) === -1) {
            previousRisks.push(risk);
          }
        });
        return previousRisks;
      };

      /**
       * ustawia domyśne poziomy/dane dostępości dla atrybutów obiektu w DiD
       * czyli wszedzie disabled: true
       * @param {Object} acData getData() z obiektu z wartościami zamienionymi na informacje o dozwolonych zmianach (AllowedFormAttributeModel)
       * @param {*} obj Estate, Person itp.
       * @return {Object} przetworzony acData
       */
      this.setDefaultAllowedChanges = function(acData, obj) {
        angular.forEach(acData, function(val, name) {
          if (name === 'dynamicValues' || name === '_additionalData') {
            angular.forEach(val, function(dvVal, dvName) {
              acData[name][dvName].disabled = true;
            });
          } else {
            acData[name].disabled = true;
          }
        });
        acData.allDisabled = true;
        var methodName = '_setDefaultAllowedChangesFor' + obj.objectName;
        if (angular.isFunction(self[methodName])) {
          self[methodName](obj, acData);
        }
        return acData;
      };

      /**
       * ustawia domyślne dozwolone atrybuty dla lokazlizacji
       * @param  {EstateModel} obj loklaizacja
       * @param  {Object} acData dane o dostępności pól, które zostaną uzupełnione
       */
      this._setDefaultAllowedChangesForEstate = function(obj, acData) {
        if (angular.isUndefined(acData.dynamicValues)) {
          acData.dynamicValues = {};
        }
        //dodajemy informacje o niedostepnosci atrybutów lokalizacji
        angular.forEach(resourceHelper.getLocalizationAttributesByType(obj.type), function(adName) {
          if (angular.isUndefined(acData.dynamicValues[adName])) {
            acData.dynamicValues[adName] = new AllowedFormAttributeModel();
            acData.dynamicValues[adName].disabled = true;
          }
        });

        acData.name = new AllowedFormAttributeModel();
        acData.name.disabled = true;
      };

       /**
       * ustawia domyślne dozwolone atrybuty dla osob
       * @param  {PersonModel} obj osoba
       * @param  {Object} acData dane o dostępności pól, które zostaną uzupełnione
       */
      this._setDefaultAllowedChangesForPerson = function(obj, acData) {
        if (acData.isNew) {
          angular.forEach(RESOURCES.NNW_PERSON_ATTRIBUTES, function(obj, name){
            acData.dynamicValues[name] = new AllowedFormAttributeModel();
            acData.dynamicValues[name].disabled = false;
          }); 
        }
      };

      /**
       * ustawia domyślne dozwolone atrybuty dla pojazdu
       * @param  {VehicleModel} obj pojazd
       * @param  {Object} acData dane o dostępności pól, które zostaną uzupełnione
       */
      this._setDefaultAllowedChangesForVehicle = function(obj, acData) {
        if (angular.isUndefined(acData._additionalData)) {
          acData._additionalData = {};
        }
        //dodajemy informacje o niedostepnosci atrybutów z additionalData pojazdu, pochodzących z ryzyk
        angular.forEach(mainDataContainer.riskDynamicValuesToAdditionalData[CONSTANTS.PRODUCT_TYPE_VEHICLE], function(adName) {
          if (angular.isUndefined(acData._additionalData[adName])) {
            acData._additionalData[adName] = new AllowedFormAttributeModel();
            acData._additionalData[adName].disabled = true;
          }
        });
        //atrybut dotyczacy pola "kopiuj wartość" pojazdu
        acData._additionalData.copyVehicleValue = new AllowedFormAttributeModel();
        acData._additionalData.copyVehicleValue.disabled = self.isRiskPreviouslySelected(CONSTANTS.PRODUCT_ACKOM, CONSTANTS.RISK_AC, obj.metaData.clientId);
        //typ wartości (brutto/netto) zawsze blokujemy w didach
        acData._additionalData.valueType.disabled = true;
      };

      /**
       * [getLastInsuranceStartDate wylicza daty dostępnego okresu ochrony]
       * @param  {String} productCode [kod produktu]
       * @param  {String} objectId    [id wybranego obiektu]
       * @param  {String} variant     [kod wariantu - opcjonalny] 
       * @return {Object}             [daty dostępnego okresu ochrony {minDate: XDate, maxDate: XDate} ]
       */
      this.getAvailablePeriodInsurance = function(productCode, objectId){
        var minDate = (new XDate(RESOURCES.TODAY)).addDays(1), //dajemy TODAY, ponieważ domyślnie brana jest pod uwagę data z przeglądarki z uwzględnieniem godziny
          maxDate = (new XDate(minDate)).addDays(6),
          prevRisk = dataContainerHelper.getPreviouslySelectedRiskObj(productCode, null, objectId),
          insuranceStartDate; // data rozpoczecia okresu ochrony bądz dokupienia

        if (prevRisk !== null) { // jesli znaleziono to data z ryzyka poprzedniego
          insuranceStartDate = new XDate(prevRisk.get('start'));
        } else { // jeśli nie znaleziono, to najwczesniejsza data poczatku ochrony z ryzyk z poprzedniej polisy
          var earliest = '';
          angular.forEach(mainDataContainer.previousRisks, function(risk) {
            var insStart = risk.get('start');
            if (earliest === '' || earliest > insStart) {
              earliest = insStart;
            }
          });
          insuranceStartDate = new XDate(earliest);
        }


        //jesli data jest pozniejsze to mamy do wyboru 7 dni (dzien rozpoczecia nie jest wliczany)
        if(minDate.diffDays(insuranceStartDate) >= 0 && minDate.diffDays(insuranceStartDate) <= 6){
          //dokupienie moze zaczynac sie od dnia nastepnego od dnia rozpoczecia ochrony
          minDate = insuranceStartDate.addDays(1);
        }else if(minDate.diffDays(insuranceStartDate) > 7){
          minDate = insuranceStartDate;
          maxDate = insuranceStartDate;
        }

        return {minDate: minDate, maxDate: maxDate};
      };

      /**
       * [isRiskPreviouslySelected czy ryzyko było wybrane na poprzedniej polisie]
       * @param  {String}  productCode kod produkktu
       * @param  {String}  riskCode    kod ryzyka
       * @param  {String}  [objectId]    id obiektu/podmiotu ubezpieczonego
       * @return {Boolean} true gdy było wybrane
       */
      this.isRiskPreviouslySelected = function(productCode, riskCode, objectId){
        if(!appVariables.isSupplement){
          return false;
        }
        var prevSel = dataContainerHelper.getPreviousVariantsForProduct(productCode, objectId);
        if (riskCode === CONSTANTS.RISK_FLOOD) { //aktualnie obsługa tylko ryzyka powodzi. W razie potrzeby należy rozbudować tę implementację (bez tego brzydkiego ifa :P).
          return (prevSel[riskCode] === true);
        }
        //sprawdzamy czy wybrano jakikolwiek wariant
        var selected = false;
        lsnNg.forEach(prevSel, function(vSelected) {
          if (vSelected) {
            selected = true;
            return false;
          }
          return true;
        });
        return selected;
      };

      /**
       * zwraca dozwolone wartości dla dynamicValues polisy
       * @param  {String} name     nazwa dynamicValue
       * @return {mix} null jeśli nie znaleziono
       */
      this.getAllowedValuesForPolicyDynamicValues = function(name){
        var acPolicy = dataContainerHelper.getAllowedChangesFor('policy');
        if (acPolicy === null) {
          return null;
        }
        if (angular.isDefined(acPolicy.attributes[name])) {
          return acPolicy.attributes[name].allowedValues;
        }
        return null; //nie znaleziono dozwolonych wartości dla atrybutu
      };

      /**
       * zwraca dozwolone wartości dla pól polisy
       * @param  {String} name     nazwa pola
       * @return {mix} null jeśli nie znaleziono
       */
      this.getAllowedValuesForPolicyProperty = function(name){
        var acPolicy = dataContainerHelper.getAllowedChangesFor('policy');
        if (acPolicy === null) {
          return null;
        }
        if (angular.isDefined(acPolicy.objectProperties[name])) {
          return acPolicy.objectProperties[name].allowedValues;
        }
        return null; //nie znaleziono dozwolonych wartości dla atrybutu
      };

    };

    return new SupplementHelper();
  }
]);