angular.module('ihestiaMenuUserBox')
  .provider('ihestiaMenuUserBoxHelper', ['$windowProvider', 'ihestiaConfigHelperProvider', 'ihestiaPlatformHelperProvider', 'UserCharacterTypeConstantsProvider',
    function($windowProvider, ihestiaConfigHelperProvider, ihestiaPlatformHelperProvider, UserCharacterTypeConstantsProvider) {
      var menuUserBoxHelper = this;

      /**
       * Ustawienia boxu
       * @type {Object}
       */
      this.settings = {
        boxVisible: false
      };

      this.configHelper = ihestiaConfigHelperProvider.getConfigHelper();

      /**
       * Przejście do profilu
       * @return {undefined}
       */
      this.goToProfile = function() {
        menuUserBoxHelper.hideUserBox();
        var profileUrl = menuUserBoxHelper.configHelper.getUrl('PORTAL_URL', 'external/profile');
        $windowProvider.$get().location = profileUrl;
      };

      /**
       * Przekierowanie na odpowiednią stronę po zmienie charakteru
       * https://ebok.atena.pl/browse/EHNLJUPIT-1693
       * https://ebok.atena.pl/browse/IHESTLIFE-2824
       *
       * @param  {Object} newCharacter charakter, na który właśnie się przepięliśmy
       * @return {undefined}
       */
      this.redirectAfterCharacterChange = function(newCharacter) {
        var isJupiter = ihestiaPlatformHelperProvider.isJupiter();
        var isPortal = menuUserBoxHelper.configHelper.get('ihsgDefines', 'AG_SYMBOL_APP') === 'AppPortal';

        var characterConstants = UserCharacterTypeConstantsProvider.getConstants();
        var isRedirectCharacter = [
          characterConstants.CHARACTER_TYPE_WORKER,
          characterConstants.CHARACTER_TYPE_SALES_PROFILE,
          characterConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER
        ].indexOf(newCharacter.type) > -1;
        var isPortalRedirectCharacter = [
          characterConstants.CHARACTER_TYPE_BRANCH,
          characterConstants.CHARACTER_TYPE_CENTRAL,
          characterConstants.CHARACTER_TYPE_MANAGER
        ].indexOf(newCharacter.type) > -1;

        if(isRedirectCharacter) {
          var memberOf = {
            jupiter: menuUserBoxHelper._isOssMember(newCharacter, ['CharacterMemberJupiter', 'CharacterMemberJupiterPlus']),
            iHestia: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberiHestia'),
            iPegaz: menuUserBoxHelper._isOssMember(newCharacter, ['CharacterMemberiPegaz', 'CharacterMemberiPegazLife']),
            sirius: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberSyriusz'),
            life: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberLife'),
            ipegazLife: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberiPegazLife'),
            corpo: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberCorpo'),
            diler: menuUserBoxHelper._isOssMember(newCharacter, 'CharacterMemberCumulusDiler')
          };

          if(isJupiter) {
            menuUserBoxHelper._redirectFromJupiter(newCharacter, memberOf);
          } else if (isPortal) {
            menuUserBoxHelper._redirectFromPortal(newCharacter, memberOf);
          } else {
            menuUserBoxHelper._redirectFromOther(newCharacter, memberOf);
          }
        } else if (isPortalRedirectCharacter){
          menuUserBoxHelper._reditectTo('PORTAL_URL');
        } else {
          menuUserBoxHelper._reload();
        }
      };

      /**
       * [_redirectFromJupiter description]
       * @param  {[type]} newCharacter [description]
       * @param  {[type]} memberOf     [description]
       * @return {[type]}              [description]
       */
      this._redirectFromJupiter = function(newCharacter, memberOf) {
        if(memberOf.jupiter) {
          menuUserBoxHelper._reload();
        } else if((memberOf.iHestia || memberOf.corpo || memberOf.life) && !memberOf.jupiter) {
          menuUserBoxHelper._reditectTo('PORTAL_URL');
        } else if (memberOf.diler && !memberOf.corpo && !memberOf.life) {
          menuUserBoxHelper._reditectTo('DILER_URL');
        } else if (memberOf.iPegaz) {
          menuUserBoxHelper._reditectTo('IPEGAZ_URL');
        } else if (memberOf.sirius && !memberOf.life && !memberOf.ipegazLife) {
          menuUserBoxHelper._reditectTo('SYRIUSZ_URL');
        } else {
          menuUserBoxHelper._reload();
        }
      };

      /**
       * [_redirectFromPortal description]
       * @param  {[type]} newCharacter [description]
       * @param  {[type]} memberOf     [description]
       * @return {[type]}              [description]
       */
      this._redirectFromPortal = function(newCharacter, memberOf) {
        if(memberOf.iHestia || memberOf.corpo || memberOf.life) {
          menuUserBoxHelper._reload();
        } else if (memberOf.jupiter) {
          menuUserBoxHelper._reditectTo('JUPITER_URL');
        } else if (memberOf.diler && !memberOf.corpo && !memberOf.life) {
          menuUserBoxHelper._reditectTo('DILER_URL');
        } else if (memberOf.iPegaz) {
          menuUserBoxHelper._reditectTo('IPEGAZ_URL');
        } else if (memberOf.sirius && !memberOf.life && !memberOf.ipegazLife) {
          menuUserBoxHelper._reditectTo('SYRIUSZ_URL');
        } else {
          menuUserBoxHelper._reload();
        }
      };

      /**
       * [_redirectFromOther description]
       * @param  {[type]} newCharacter [description]
       * @param  {[type]} memberOf     [description]
       * @return {[type]}              [description]
       */
      this._redirectFromOther = function(newCharacter, memberOf) {
        if(memberOf.iHestia || memberOf.corpo || memberOf.life) {
          menuUserBoxHelper._reload();
        } else if (memberOf.jupiter) {
          menuUserBoxHelper._reditectTo('JUPITER_URL');
        } else if (memberOf.diler && !memberOf.corpo && !memberOf.life) {
          menuUserBoxHelper._reditectTo('DILER_URL');
        } else if (memberOf.iPegaz) {
          menuUserBoxHelper._reditectTo('IPEGAZ_URL');
        } else if (memberOf.sirius && !memberOf.life && !memberOf.ipegazLife) {
          menuUserBoxHelper._reditectTo('SYRIUSZ_URL');
        } else {
          menuUserBoxHelper._reload();
        }
      };

      /**
       * redirect to another app
       * @param  {[type]} urlCode [description]
       * @return {[type]}         [description]
       */
      this._reditectTo = function(urlCode) {
        $windowProvider.$get().open(menuUserBoxHelper.configHelper.getUrl(urlCode), '_self');
      };

      /**
       * Reload page
       * @return {[type]} [description]
       */
      this._reload = function() {
        $windowProvider.$get().location = '/';
      };

      /**
       * Sprawdzamy, czy dany charakter jest członkiem (member) danej platformy
       * (którejkolwiek z podanych)
       *
       * @param  {Object}  character badany charakter
       * @param  {array|string}  platforms Jupiter|JupiterPlus|iHestia|iPegaz
       * @return {Boolean}
       */
      this._isOssMember = function(character, platforms) {
        var ossContextInfo = character.ossContextInfo;
        platforms = angular.isArray(platforms) ? platforms : [platforms];
        var isOssMember = false;

        angular.forEach(platforms, function(platform){
          var ossMemberValue = ossContextInfo[platform];
          if(angular.isString(ossMemberValue) && ossMemberValue.toLowerCase() === 'true' || ossMemberValue === true) {
            isOssMember = true;
          }
        });

        return isOssMember;
      };

      /**
       * Zwija box z profilem
       * @return {Object} self
       */
      this.hideUserBox = function() {
        menuUserBoxHelper.settings.boxVisible = false;
        return menuUserBoxHelper;
      };

      /**
       * provider $get
       * @return {Object}
       */
      this.$get = [function(){
        return menuUserBoxHelper;
      }];

    }]);
