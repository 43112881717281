angular.module('ihestiaRestServicesBase')

   .factory('directEmailSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var DirectEmail = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'resources/directEmail';
      this.businessArea = 'ikomunikator';
    };

    return new DirectEmail();
  }]);