angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaChooseConditionsModalCtrl', ['$scope', 'ihestiaPzaConditionSvc', 'ihestiaPzachooseConditionsModalHelper', '$timeout',
    function($scope, ihestiaPzaConditionSvc, ihestiaPzachooseConditionsModalHelper, $timeout) {

      $scope.conditions = [];
      $scope.selectedConditions = ihestiaPzachooseConditionsModalHelper.selectedConditions;
      $scope.loadInProgress = false;
      $scope.freshModal = true;
      $scope.listParams = ihestiaPzachooseConditionsModalHelper.listParams;
      $scope.timeoutPromise = null;

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {

        /** inicjalne załadowanie warunków */
        $scope.loadConditions();

        /** przekazanie danych na zatwierdź */
        $scope.$on('iHestiaCommonModalOkBtnClicked.chooseConditionsModal', function() {
          ihestiaPzachooseConditionsModalHelper.passConditions();
        });

        /** modal zniknął, więc czyścimy po sobie dane */
        $scope.$on('$destroy', function(){
          ihestiaPzachooseConditionsModalHelper.clearData();
        });

        /** Ustawiamy na przycisku wybierz liczbę zaznaczonych elementów */
        $scope.$watch('selectedConditions', function(selectedConditions){
          var selectedConditionsCount = 0;
          if(angular.isObject(selectedConditions) && selectedConditions !== null){
            angular.forEach(selectedConditions, function(conditionIsSelected){
              if(conditionIsSelected) {
                selectedConditionsCount = selectedConditionsCount + 1;
              }
            });
          }
          if(angular.isDefined($scope.chooseConditionsModalSettings.okBtnLabel[1].params) && angular.isDefined($scope.chooseConditionsModalSettings.okBtnLabel[1].params.count)){
            var newOkBtnLabel = $scope.chooseConditionsModalSettings.okBtnLabel;
            newOkBtnLabel[1].params.count = selectedConditionsCount;

            $scope.chooseConditionsModalSettings.okBtnLabel = newOkBtnLabel;
          }
          
        }, true);
      };

      /**
       * Ładujemy listę warunków
       * @param {int|undefined} delay czas w ms jaki poczekać przed ładowaniem
       * @return {undefined}
       */
      $scope.loadConditions = function(delay) {
        if($scope.timeoutPromise) {
          $timeout.cancel($scope.timeoutPromise);
        }
        $scope.timeoutPromise = $timeout(function(){
          $scope.timeoutPromise = null;
          $scope.conditions = [];
          $scope.loadInProgress = true;
          ihestiaPzachooseConditionsModalHelper.loadConditions(null, $scope.listParams).then(function(conditions) {
            $scope.conditions = conditions;
          }, angular.noop).finally(function() {
            $scope.loadInProgress = false;
            $scope.freshModal = false;
          });
        }, delay);
      };
    }]);