angular.module('salesPath2')
  .controller('sp2BlikPaymentModalCtrl', ['$scope', 'sp2BlikPaymentModalHelper',
    function($scope, sp2BlikPaymentModalHelper) {
      $scope.tplData = sp2BlikPaymentModalHelper.tplData;

      $scope.init = function() {
        sp2BlikPaymentModalHelper.init();
      };

      $scope.init();
    }]);