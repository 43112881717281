angular.module('lsnBase.directives')
 .directive('lsnFocusOnInit', [function(){
   return {
     restrict: 'A',
     scope: {
       'lsnFocusOnInit': '='
     },
     link: function($scope, $element) {
      if($scope.lsnFocusOnInit)
      {
        $element.focus();
      }
     }
   };
 }]);