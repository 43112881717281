angular.module('salesPath2')
  .controller('vehicleEditCommonDataCtrl', ['$scope', 'CONSTANTS', 'vehicleHelper', 'RESOURCES', 'CONFIG', 'vehicleModalHelper', 'actionHelper', 'sp2CommonHelper', 'coownerModalHelper', 'VehicleValueCalculatorModel',
    function($scope, CONSTANTS, vehicleHelper, RESOURCES, CONFIG, vehicleModalHelper, actionHelper, sp2CommonHelper, coownerModalHelper, VehicleValueCalculatorModel) {

      $scope.mileageOptions = RESOURCES.MILEAGE_OPTIONS;
      $scope.purposeOptions = RESOURCES.SPECIAL_PURPOSE_OPTIONS;
      $scope.originalKeyOptions = RESOURCES.VEHICLE_ORIGINAL_KEY;
      $scope.purposeOptionsV2 = RESOURCES.SPECIAL_PURPOSE_OPTIONS_V2;
      $scope.vehicleValueTypes = null;

      $scope.selectedPurpose = [];

      $scope.mileageNumericSettings = {
        decimals: 0,
        negative: false,
        absolute: true,
        allowLeadingZeros: true,
        formatting: true
      };

      $scope.valuationNumericSettings = {
        decimals: 0,
        negative: false,
        absolute: true,
        allowLeadingZeros: true,
        formatting: true
      };

      $scope.yearNumericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: false
      };

      $scope.yearSettings = {
        decimals: 0,
        allowLeadingZeros: false,
        numericFormat: false
      };

      $scope.todayDate = RESOURCES.TODAY;

      $scope.valueCalculator = null;

      $scope.init = function() {
        $scope.setMileageSelect();
        $scope.preselectOriginalKey();

        if (CONFIG.BEHAVIOR.vehicle.etxValuation && angular.isUndefined($scope.vehicleAdditionalData.vehicleValueType)) {
          $scope.vehicleAdditionalData.vehicleValueType = 'brutto';
        }

        // aplikujemy specjalne przeznaczenie, jeśli jest
        if (vehicleHelper.purposesModalData.purposes) {
          $scope.savePurposes(vehicleHelper.purposesModalData.purposes);
        }

        $scope.fireListener();

        $scope.setSelectedPropose();

        $scope.setSelectedPurposeV2();

        $scope.setStartDateFirstRegistration();

        $scope.valueCalculator = new VehicleValueCalculatorModel();
        $scope.valueCalculator.initialize($scope.vehicleAdditionalData.vehicleValue, $scope.vehicleAdditionalData.vehicleValueType);
        if (CONFIG.BEHAVIOR.vehicle.extendedValueTypes) {
          $scope.vehicleValueTypes = RESOURCES.VEHICLE_VALUE_TYPES;
        } else {
          $scope.vehicleValueTypes = _.reject(RESOURCES.VEHICLE_VALUE_TYPES, {
            code: CONSTANTS.VALUE_TYPE_NETTO_PLUS_50
          });
        }
      };

      /**
       * Ustawienie domyślnej wartości kompletu kluczy
       */
      $scope.preselectOriginalKey = function() {
        if (!CONFIG.BEHAVIOR.vehicle.vehicleOriginalKey) {
          return;
        }

        angular.forEach($scope.originalKeyOptions, function(option) {
          if (!option.value) {
            option.selected = true;
          }
        });
      };


      /**
       * Przeszukanie elementóe jednej listy w celu odnalezienia zaznaczonych elemntów innej
       */
      $scope.setSelectedPropose = function() {
        if (!CONFIG.BEHAVIOR.vehicle.vehicleSpecialPurpose) {
          return;
        }

        angular.forEach($scope.vehicleAdditionalData.specialPurposes, function(k1) {
          angular.forEach($scope.purposeOptions, function(k2) {
            if (k2.CODE === k1) {
              $scope.selectedPurpose.push(k2.NAME);
            }
          });
        });
      };

      $scope.fireListener = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleEtxEditModal', function($event, obj) {
          if (obj.validForm) {
            $scope.modalData.vehicleAdditionalData.source = 'etx';
            $scope.saveVehicle();
          } else {
            $event.preventDefault();
          }
        });
      };

      $scope.setMileageSelect = function() {
        if (!CONFIG.BEHAVIOR.vehicle.mileage) {
          return;
        }

        var mileageOptionSet = false;
        // najpierw próbujemy ustalić przedział
        angular.forEach($scope.mileageOptions, function(option) {
          if (parseInt(option.VALUE, 10) === parseInt($scope.vehicleData.mileage, 10)) {
            $scope.modalData.mileageOption = option.VALUE;
            mileageOptionSet = true;
          }
        });
        // skoro nie ma przedziału, to może jest wpisana wartość
        if (!mileageOptionSet && parseInt($scope.vehicleData.mileage, 10) > -1) {
          $scope.modalData.mileageOption = '-1';
          mileageOptionSet = true;
        }

        if (!mileageOptionSet) {
          $scope.modalData.mileageOption = '0';
        }
      };

      /**
       * Wpisujemy do additionalData dane o szczególnym przeznaczeniu pojazdu
       * @param  {array} purposes [description]
       */
      $scope.savePurposes = function(purposes) {
        if (!CONFIG.BEHAVIOR.vehicle.vehicleSpecialPurpose) {
          return;
        }

        $scope.vehicleAdditionalData.specialPurposes = [];
        angular.forEach(purposes, function(purpose) {
          $scope.vehicleAdditionalData.specialPurposes.push(purpose.CODE);
        });
      };

      /**
       * Kliknięcie w select przebiegu
       */
      $scope.checkMileageSelect = function() {
        if (parseInt($scope.modalData.mileageOption, 10) !== -1) {
          $scope.vehicleData.mileage = $scope.modalData.mileageOption;
        } else {
          $scope.vehicleData.mileage = '';
        }
      };

      /**
       * Szczególne przeznaczenie
       */
      $scope.chooseSpecialPurpose = function() {
        vehicleModalHelper.chooseSpecialPurpose($scope.vehicleData, $scope.vehicleAdditionalData.specialPurposes);
      };

      $scope.saveVehicle = function() {
        if ($scope.modalData.replaceClientId) {
          $scope.vehicleData.metaData.clientId = $scope.modalData.replaceClientId;
        }
        if ($scope.modalData.replaceEtxVehicle && $scope.modalData.clientId) {
          $scope.vehicleData.metaData.clientId = $scope.modalData.clientId;
        }

        // dane modelu
        $scope.modalData.vehicle.setData($scope.vehicleData);
        // dodatkowe dane
        if (!$scope.vehicleAdditionalData.isLeased) {
          $scope.vehicleAdditionalData.leaserId = null;
        }
        $scope.modalData.vehicle.setAdditionalData($scope.vehicleAdditionalData);
        if (CONFIG.BEHAVIOR.vehicle.etxValuation) {
          $scope.vehicleDynamicValues.vehicleValue = $scope.vehicleAdditionalData.vehicleValue;
          $scope.vehicleDynamicValues._vehicleValueEtxSelect = $scope.modalData.etxData.vehicleValue;
        }

        $scope.modalData.vehicle.set('dynamicValues', $scope.vehicleDynamicValues);
        // dodajemy do dataContainer

        vehicleHelper.applyVehicle($scope.modalData.vehicle, false);

        // zapisano pojazd
        actionHelper.runAction('saveApplication');

        // IHESTIASS-418 showing communication person modal doesnt depend on hasCoowners
        // if ($scope.vehicleDynamicValues._hasCoowners && (!angular.isArray($scope.vehicleAdditionalData.coowners) || (angular.isArray($scope.vehicleAdditionalData.coowners) && $scope.vehicleAdditionalData.coowners.length === 0))) {
        coownerModalHelper.showCoownerList(CONSTANTS.PRODUCT_TYPE_VEHICLE);
        // }

        // usuwamy przypisanie aktualnie modyfikowanego pojazdu
        vehicleHelper.clearEditModalData();
      };


      /**
       * zmiana komunikatu błędu
       */
      $scope.changeErrorText = function(formError, formErrorText, errorTree) {
        var error; // (object) przypisywany poniżej
        if (angular.isDefined($scope.modalData.errors)) {
          if (!$scope.modalData.errors[errorTree[0]]) {
            $scope.modalData.errors[errorTree[0]] = {};
          }
          if (!$scope.modalData.errors[errorTree[0]][errorTree[1]]) {
            $scope.modalData.errors[errorTree[0]][errorTree[1]] = {};
          }

          if (errorTree.length > 2) {
            if (!$scope.modalData.errors[errorTree[2]]) {
              $scope.modalData.errors[errorTree[0]][errorTree[1]][errorTree[2]] = {};
            }
            error = $scope.modalData.errors[errorTree[0]][errorTree[1]][errorTree[2]];
          } else {
            error = $scope.modalData.errors[errorTree[0]][errorTree[1]];
          }

          if (!!error.message && !!formError) {
            error.commonMessage = formErrorText + ' ' + error.message;
          } else if (!!formError && !error.message) {
            error.commonMessage = formErrorText;
          } else if (!formError && !!error.message) {
            error.commonMessage = error.message;
          } else {
            delete $scope.modalData.errors[errorTree[0]];
          }
        } else if (formError) {
          $scope.modalData.errors = {};
        } else {
          delete $scope.modalData.errors[errorTree[0]];
        }
      };

      $scope.setStartDateFirstRegistration = function() {
        //jesli puste lub nie poprawna data
        if ($scope.vehicleData.firstRegistration === '' || $scope.vehicleData.firstRegistration === null) {
          var startDateFirstRegistration = new XDate();
          if ($scope.vehicleData.productionYear !== '' && (!$scope.modalData.errors.vehicle || !$scope.modalData.errors.vehicle.productionYear || !$scope.modalData.errors.vehicle.productionYear.message)) {
            startDateFirstRegistration.setYear($scope.vehicleData.productionYear);
          }

          $scope.startDateFirstRegistration = startDateFirstRegistration.toString('yyyy-MM-dd');
        }
      };

      /**
       * Przepisz wartosc z eurotax
       */
      $scope.setVehicleValueFromEtx = function() {
        if (!$scope.modalData.allowedChanges.dynamicValues.vehicleValue.disabled) {
          $scope.vehicleAdditionalData.vehicleValueType = $scope.modalData.etxData.vehicleValueType;
          $scope.vehicleAdditionalData.vehicleValue = $scope.modalData.etxData.vehicleValue;
          //na dynamicvalues również ustawiamy wartość pojazdu:
          $scope.vehicleDynamicValues.vehicleValue = $scope.modalData.etxData.vehicleValue;
          // update calculator properties
          $scope.valueCalculator.onValueChange($scope.vehicleAdditionalData.vehicleValue);
          $scope.valueCalculator.onValueTypeChange($scope.vehicleAdditionalData.vehicleValueType, true);
        }
      };

      /*
       * przeliczenie netto na brutto
       */
      $scope.valueTypeChanged = function(valueType) {
        $scope.vehicleAdditionalData.vehicleValue = $scope.valueCalculator.onValueTypeChange(valueType);
      };

      $scope.setSelectedPurposeV2 = function() {
        if (!$scope.CONFIG.BEHAVIOR.vehicle.vehicleSpecialPurposeV2) {
          return;
        }
        $scope.vehicleDynamicValues.vehicleSpecialUsage = _.get($scope.modalData.vehicle, 'dynamicValues.vehicleSpecialUsage', null);

      };

      $scope.init();

    }
  ]);
