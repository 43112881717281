/**
 * Mapper czynnikow taryfikacyjnych (osoby, firmy)
 */
angular.module('salesPath2').service('fromRestTariffFactorsMapper', ['MapperUtils', 'ihestiaCommonErrorHandler', 'CONSTANTS', 'TariffFactorModel', 'FactorRowModel', 'TariffFactorsModel', 'CONFIG', '$filter', 'RESOURCES', 'appVariables', 'lsnUtils',
  function(MapperUtils, ihestiaCommonErrorHandler, CONSTANTS, TariffFactorModel, FactorRowModel, TariffFactorsModel, CONFIG, $filter, RESOURCES, appVariables, lsnUtils) {
    var FromRestTariffFactorsMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      /**
       * lista obslugiwanych ryzyk
       * @type Array
       */
      this.riskCodes = [CONSTANTS.RISK_AC, CONSTANTS.RISK_ACKR, CONSTANTS.RISK_OC, CONSTANTS.RISK_NNWKIP];

      /**
       * porządek ryzyk w tabeli z danymi
       * @type Array
       */
      this.riskOrder = [CONSTANTS.RISK_OC, CONSTANTS.RISK_AC, CONSTANTS.RISK_ACKR, CONSTANTS.RISK_NNWKIP];

      /**
       * zmapowane ryzyka {kod_ryzyka: Risk}
       * @type Object.<string, Risk>
       */
      this.mappedRisks = {};

      /**
       * nazwy ryzyk do wyświetlenia w GUI
       * @type object
       */
      this.riskNames = {};
      this.riskNames[CONSTANTS.RISK_AC] = 'AC';
      this.riskNames[CONSTANTS.RISK_ACKR] = 'ACKR';
      this.riskNames[CONSTANTS.RISK_OC] = 'OC';
      this.riskNames[CONSTANTS.RISK_NNWKIP] = 'NNWKIP';


      /**
       * konfiguracja wierszy bloku 1 (nagłówki) zaczynając od lp=2
       * UWAGA - wazna jest kolejnosc
       * [nazwa, [atrybut_do_sczytania[, opcjonalny_kolejny_atrybut_do_sczytania]], funkcja_filtrująca_wartosc, opcja_formatowania, opcjonalne_ograniczenie_do_ryzyk]
       * opcjonalny_kolejny_atrybut_do_sczytania jest wykorzystywany gdy nie znaleziono wartosci na poprzednim atrybucie
       * opcja_formatowania - opcjonalny parametr type dla metody formatującej -
       * funkcja_filtrująca_wartosc musi przyjac 1 argument i zwrocic przefiltrowana wartosc
       * opcjonalne_ograniczenie_do_ryzyk - jeśli podana, to tylko dla tych ryzyk atrybut będzie sczytywany (dla pozostałych przyjmie wartość null)
       */
      this.headerAttributes = [
        ['strefa regionalna', ['outRegionalZone', 'regionalZone'], null, 'normal'],
        ['klasa pojazdu', ['vehicleClass'], null, 'normal'],
        ['stawka', ['outRate', 'premiumRate'], function(val) {
            return Number(val.toFixed(4));
          },
          [CONSTANTS.RISK_AC, CONSTANTS.RISK_ACKR]
        ],
        ['składka podstawowa', ['printoutPremiumBasis', 'premiumBasis']]
      ];

      /**
       * nagłowki do zsumowania - dla AC i ACKR
       * nazwa: typ (float lub int)
       * UWAGA - aktualnie ac i kr są preznetowane rozdzielnie, więc metoda ta nie jest uzywana
       * @type object
       */
      this.headersToSum = {
        'stawka': 'float',
        'składka podstawowa': 'int'
      };

      this.i18nMapForLabel = {
        'strefa regionalna': ['tariffFactors.regionalZone', {
          componentCode: 'sp2'
        }],
        'klasa pojazdu': ['tariffFactors.classOfVehicle', {
          componentCode: 'sp2'
        }],
        'stawka': ['tariffFactors.rate', 'sp2'],
        'składka podstawowa': ['tariffFactors.basicRate', {
          componentCode: 'sp2'
        }],
        'suma ubezpieczenia': ['tariffFactors.sumInsured', {
          componentCode: 'sp2'
        }],
        'składka taryfowa': ['tariffFactors.premiumTariff', {
          componentCode: 'sp2'
        }],
        'zniżka kliencka': ['tariffFactors.discountClient', {
          componentCode: 'sp2'
        }],
        'odstępstwa i promocje': ['tariffFactors.deviationsAndPromotions', {
          componentCode: 'sp2'
        }],
        'składka do zapłaty': ['tariffFactors.rateToPay', {
          componentCode: 'sp2'
        }],
        'Składka przed Rozszerzeniem ochrony': ['tariffFactors.premiumBeforeExtension', {
          componentCode: 'sp2'
        }],
        'Składka po Rozszerzeniu ochrony': ['tariffFactors.premiumBeAfterExtension', {
          componentCode: 'sp2'
        }],
        'Dopłata': ['tariffFactors.supplement', {
          componentCode: 'sp2'
        }]
      };

      /**
       * spis nazw pozycji bloku 3 (podsumowania)
       * UWAGA - wazna jest kolejnosc
       * {kod_wewnetrzny_czynnika: [nazwa_wyswietlania]}
       */
      this.summariesAttributes = {
        'tariffPremium': ['składka taryfowa'],
        'clientDiscount': ['zniżka kliencka'],
        'discounts': ['odstępstwa i promocje'],
        'premium': ['składka do zapłaty']
      };

      /**
       * spis nazw pozycji bloku 4 (szczegóły)
       * UWAGA - wazna jest kolejnosc
       * {kod_wewnetrzny_czynnika: [nazwa_wyswietlana]}, kod_wewnetrzny_czynnika oznacza również dynamicValue, z którego nalezy odczytac wartosc
       */
      this.detailsAttributes = {
        '_extendPremiumBeforeChangeForDisplay': ['Składka przed Rozszerzeniem ochrony'],
        '_extendPremiumAfterChangeForDisplay': ['Składka po Rozszerzeniu ochrony'],
        '_extendPremium': ['Dopłata']
      };

      /**
       * domyślny operator dla operacji kalkulacyjnych (czynniki kalkulacji)
       * @type string
       */
      this.defaultCalculationOperator = 'x';

      /**
       * przypisanie innnych niż domyślny operatorów działań dla poszczególych pozycji w kalkulacjach
       * nazwa: operator
       * @type object
       */
      this.calculationOperators = {
        'składka ryczałtowa': '+'
      };

      /**
       * zwraca czynniki taryfikacyjne dla grupy ryzyk z jednej polisy (ryzyka powinny dotyczyc tej samej polisy)
       * @param {Risk[]} risks ryzyka
       * @return {object} czynniki taryfikacyjne lub pusty obiekt gdy nie sczytano z zadnego ryzyka
       */
      this.getTariffFactorsForSinglePolicy = function(risks) {
        if (!angular.isArray(risks)) {
          return {};
        }
        /**
         * czynniki w podziale na 3 grupy
         * names - nazwy ryzyk
         * headers - nag7ówki sczytywane z konkretnych atrybutów oraz własności ryzyk
         * calculations - kalkulacje - zniżki/odstepstwa
         * summaries - podsumowania
         * każda grupa to tablica z kluczem=kod ryzyka i tablicą czynników - obiekty TariffFactorModel
         */
        var factors = {
            names: {},
            headers: {},
            calculations: {},
            summaries: {},
            details: {}
          },
          calculationForAnyRisk = false, //czy udalo sie sczytac czynniki kalkulacji co najmniej dla jednego ryzyka
          toTranslate = ['headers', 'summaries', 'details']; //sekcje do przetlumaczenia
        lsnNg.forEach(risks, function(risk) {
          var riskDef = self.utils.findRiskDefByIdpm(risk.product.get('compId'));
          if (self.riskCodes.indexOf(riskDef.riskCode) === -1) {
            return;
          }
          calculationForAnyRisk = self._addRiskData(risk, riskDef, factors) || calculationForAnyRisk;
        });
        if (!calculationForAnyRisk) {
          return {};
        }
        if (CONFIG.BEHAVIOR.addRiskVariantToCalculationFactors) {
          self._addVariantFactorToCalculations(factors);
        }
        self._recalculateFactors(factors);
        self._formatFactors(factors);
        factors = self._remapFactors(factors);


        angular.forEach(toTranslate, function(section) {
          angular.forEach(factors[section], function(tariffObj, tariffKey) {
            var i18nLabel = self.i18nMapForLabel[tariffObj.name];
            factors[section][tariffKey].name = $filter('translate')(i18nLabel[0], i18nLabel[1]);
          });
        });

        return factors;
      };

      /**
       * przekalkulowanie ostatecne kwot
       * np. ustalenie kwot dla AC nieuwzględniających ACKR
       * @param  {Object} factors grupy czynników taryfikacyjnych
       */
      this._recalculateFactors = function(factors) {
        self._adjustFactors(factors);
        var processAcKr = angular.isDefined(factors.summaries[CONSTANTS.RISK_AC]) && angular.isDefined(factors.summaries[CONSTANTS.RISK_ACKR]);
        if (!processAcKr) {
          return false;
        }
        angular.forEach(factors.summaries[CONSTANTS.RISK_AC], function(factor, fIdx) {
          if (factors.summaries[CONSTANTS.RISK_ACKR][fIdx].value !== null && factor.value !== null) {
            factor.value -= factors.summaries[CONSTANTS.RISK_ACKR][fIdx].value;
          }
        });
        angular.forEach(factors.details[CONSTANTS.RISK_AC], function(factor, fIdx) {
          if (factors.details[CONSTANTS.RISK_ACKR][fIdx].value !== null && factor.value !== null) {
            factor.value -= factors.details[CONSTANTS.RISK_ACKR][fIdx].value;
          }
        });

        return true;
      };

      /**
       * dostosowuje czynniki
       * Aktualnie: uwzględnia pełną listę czynników z sekcji calculations dla wszystkich ryzyk.
       * @param  {Object} factors czynniki tar.
       */
      this._adjustFactors = function(factors) {
        var longest = null, //ryzyko z najdłuższa lista czynników
          finalCalcFactors = [], //lista nazw wsytskich mozliwych czynników
          lastLng = 0;
        lsnNg.forEach(factors.calculations, function(arr, riskName) {
          if (arr.length > lastLng) {
            longest = riskName;
            lastLng = arr.length;
          }
        });

        angular.forEach(factors.calculations[longest], function(factor) {
          finalCalcFactors.push(factor.name);
        });
        var mergeFactorsGroup = function(group) {
          var prevIndex = null;
          angular.forEach(group, function(factor) {
            if (finalCalcFactors.indexOf(factor.name) !== -1) {
              prevIndex = finalCalcFactors.indexOf(factor.name);
            } else {
              if (prevIndex === null) {
                finalCalcFactors.splice(0, 0, factor.name);
              } else {
                finalCalcFactors.splice(prevIndex + 1, 0, factor.name);
              }
            }
          });
        };
        //przeglądamy wsyztskie ryzyka i ich czynniki taryfowe (calculations) i dorzucamy do pełnej listy brakujące elementy
        lsnNg.forEach(factors.calculations, function(arr, riskName) {
          if (riskName === longest) {
            return true; //nie badamy wyjsciowej tablicy
          }
          mergeFactorsGroup(arr);
          return true;
        });
        //mając pełną listę czynników (sekdcja calculations), dorzucamy je w odpowiednie miejsca w tablicy czynników dla każdego ryzyka, aby można było zaprezentować wszystkie ryzyka na jednej tabelce na GUI
        var factorsCopy = factors.calculations;
        factors.calculations = {};
        angular.forEach(factorsCopy, function(arr, riskName) {
          factors.calculations[riskName] = []; //uzupełniamy od początku listę czynników dla ryzyka - uwzględnimy poniżej brakujące elementy
          angular.forEach(finalCalcFactors, function(fName, fIdx) {
            var riskFactor = lsnUtils.findObjInArray(arr, {
              name: fName
            });
            if (riskFactor === null) {
              riskFactor = new TariffFactorModel({
                name: fName,
                value: [self.defaultCalculationOperator, null, '=', null]
              });
            }
            riskFactor.lp = (fIdx + 1) + '';
            factors.calculations[riskName].push(riskFactor);
          });
        });
      };


      /**
       * dodaje wiersz "wariant ubezpieczenia" do czynników taryfikacji (blok 2 - calculations)
       * @param {object} factors czynniki
       */
      this._addVariantFactorToCalculations = function(factors) {
        lsnNg.forEach(factors.calculations, function(calculations, riskCode) {
          if (factors.calculations[riskCode].length === 0) {
            return true;
          }
          var risk = self.mappedRisks[riskCode],
            value = 0,
            nextLp = (parseInt(factors.calculations[riskCode][factors.calculations[riskCode].length - 1].lp, 10) + 1) + '',
            sum = factors.calculations[riskCode][factors.calculations[riskCode].length - 1].value[3],
            tariffAttrs = risk.product.get('tariffAttributes'),
            dynVals = risk.product.get('dynamicValues');

          if (angular.isDefined(dynVals._tariffPremiumForDisplay) && angular.isDefined(tariffAttrs.tariffPremium)) {
            if (riskCode === CONSTANTS.RISK_AC && angular.isDefined(self.mappedRisks[CONSTANTS.RISK_ACKR])) { //przypadek ac + ackr
              var krTariffAttrs = self.mappedRisks[CONSTANTS.RISK_ACKR].product.get('tariffAttributes');
              //wartosc za wariant ubezpieczenia to wartosc dodatkow bez uwzglednienia ACKR (ono jest sumowane z AC w każdym czynniku kalkulacji)
              value = parseFloat(dynVals._tariffPremiumForDisplay - tariffAttrs.tariffPremium - krTariffAttrs.tariffPremium);
            } else {
              value = parseFloat(dynVals._tariffPremiumForDisplay - tariffAttrs.tariffPremium);
            }
            sum += value;
          }
          if (value !== null) {
            value = self.formatTariffValue(value, 'normal');
          }
          var factor = new TariffFactorModel({
            lp: nextLp,
            name: 'wariant ubezpieczenia',
            value: ['+', value, '=', sum] //np. x 0,5 = 2100
          });
          factors.calculations[riskCode].push(factor);

          return true; //continue
        });
      };

      /**
       * @param {Risk} risk ryzyko
       * @param {Object} riskDef definicja ryzyka z spd
       * @param {Object} factors czynniki taryfikacyjne
       * @return {boolean} czy udalo sie sczytac czynniki kalkulacji
       */
      this._addRiskData = function(risk, riskDef, factors) {
        if (angular.isDefined(factors.headers[riskDef.riskCode])) {
          return false;
        }

        self._addName(factors, riskDef, risk);
        self._addHeaders(factors, riskDef, risk);
        self._addSummaries(factors, riskDef, risk);
        if (appVariables.isSupplement) { //przy dokupieniach dochodzi sekcja "szczegóły"
          self._addDetails(factors, riskDef, risk);
        }
        var calculationsRead = self._addCalculations(factors, riskDef, risk);
        self.mappedRisks[riskDef.riskCode] = risk;

        return calculationsRead;
      };

      /**
       * generuje nazwy ryzyk
       * @param {object} factors czynniki
       * @param {object} riskDef definicja ryzyka
       * @param {Risk} risk ryzyko
       */
      this._addName = function(factors, riskDef, risk) {
        var name = self.riskNames[riskDef.riskCode],
          dynVals = risk.product.get('dynamicValues'),
          variant = null,
          liquidation = self._getLiquidationOption(dynVals);
        if (angular.isDefined(dynVals.coverageOption) && dynVals.coverageOption !== null) {
          //wariant
          variant = dynVals.coverageOption;
        }
        if (liquidation !== null && variant !== null) {
          name += ' ({0} - {1})'.format(variant, liquidation);
        } else if (variant !== null) {
          name += ' ({0})'.format(dynVals.coverageOption);
        }
        factors.names[riskDef.riskCode] = name;
      };

      /**
       * generuje sekcję 1 - nagłówki
       * @param {object} factors czynniki
       * @param {object} riskDef definicja ryzyka
       * @param {Risk} risk ryzyko
       */
      this._addHeaders = function(factors, riskDef, risk) {
        factors.headers[riskDef.riskCode] = [];
        var tariffAttrs = risk.product.get('tariffAttributes');
        if (!angular.isObject(tariffAttrs)) {
          return;
        }
        //su
        var suFactor = new TariffFactorModel({
          lp: '1',
          name: 'suma ubezpieczenia',
          value: risk.get('sum'),
          formatType: 'normal'
        });
        factors.headers[riskDef.riskCode].push(suFactor);
        //nagówki z atrybutów taryfowych ryzyka
        var lp = 2;
        lsnNg.forEach(self.headerAttributes, function(attrsToRead) {
          var value = null;
          if (!angular.isObject(attrsToRead[3]) || (angular.isArray(attrsToRead[3]) && attrsToRead[3].indexOf(riskDef.riskCode) !== -1)) {
            lsnNg.forEach(attrsToRead[1], function(attrName) {
              if (angular.isDefined(tariffAttrs[attrName]) && tariffAttrs[attrName] !== null) {
                value = (angular.isFunction(attrsToRead[2])) ? attrsToRead[2](tariffAttrs[attrName]) : tariffAttrs[attrName];
                return false;
              }

              return true; //continue
            });
          }
          var factor = new TariffFactorModel({
            lp: lp + '',
            name: attrsToRead[0],
            value: value,
            formatType: angular.isString(attrsToRead[3]) ? attrsToRead[3] : null
          });
          factors.headers[riskDef.riskCode].push(factor);
          lp += 1;
        });
      };

      /**
       * generuje sekcję 2 - zniżki/odstępstwa
       * @param {object} factors czynniki
       * @param {object} riskDef definicja ryzyka
       * @param {Risk} risk ryzyko
       * @return {boolean} czy udalo sie sczytac czynniki taryfikacji
       */
      this._addCalculations = function(factors, riskDef, risk) {
        factors.calculations[riskDef.riskCode] = [];
        var tariffAttrs = risk.product.get('tariffAttributes');
        if (!angular.isObject(tariffAttrs.printoutProducts)) {
          return false;
        }
        var calculations = self.utils.convertTableAttributeToArray(tariffAttrs.printoutProducts);
        if (calculations === null || calculations.length === 0) {
          return false;
        }
        lsnNg.forEach(calculations, function(calcData) {
          var operator = null,
            calculator = null;

          if (calcData.przelicznik !== null) {
            operator = (angular.isDefined(self.calculationOperators[calcData.nazwa])) ? self.calculationOperators[calcData.nazwa] : self.defaultCalculationOperator;
            calculator = self.formatTariffValue(calcData.przelicznik, 'normal');
          }

          var factor = new TariffFactorModel({
            lp: calcData.pozycja,
            name: calcData.nazwa,
            value: [operator, calculator, '=', (calcData.wartosc !== null ? parseFloat(calcData.wartosc) : null)] //np. x 0,5 = 2100
          });
          factors.calculations[riskDef.riskCode].push(factor);
        });
        return true;
      };

      /**
       * generuje sekcję 3 - podsumowania
       * @param {object} factors czynniki
       * @param {object} riskDef definicja ryzyka
       * @param {Risk} risk ryzyko
       */
      this._addSummaries = function(factors, riskDef, risk) {
        factors.summaries[riskDef.riskCode] = [];
        var tariffAttrs = risk.product.get('tariffAttributes'),
          dynVals = risk.product.get('dynamicValues'),
          lp = 1,
          clientDiscountIndex = null, //indeks czynnika (prezentujacego znizke kliencka) w tablicy
          discountIndex = null, //indeks czynnika (prezentujacego zniżki/zwyżki/promocje) w tablicy
          tariffPremiumIndex = null, //indeks czynnika (prezentujacego skadkę taryfową) w tablicy
          finalPremium = 0; //skladka do zaplaty

        lsnNg.forEach(self.summariesAttributes, function(data, code) {
          var factor = new TariffFactorModel({
            lp: lp + '',
            name: data[0],
            code: code
          });
          switch (code) {
            case 'tariffPremium':
              tariffPremiumIndex = factors.summaries[riskDef.riskCode].length;
              if (angular.isDefined(dynVals._tariffPremiumForDisplay)) {
                factor.value = dynVals._tariffPremiumForDisplay;
              } else if (angular.isDefined(tariffAttrs.tariffPremium)) {
                factor.value = tariffAttrs.tariffPremium;
              } else {
                factor.value = 0;
              }
              break;
            case 'premium':
              if (angular.isDefined(dynVals._premiumForDisplay)) {
                finalPremium = dynVals._premiumForDisplay;
              } else {
                finalPremium = risk.get('premium');
              }
              factor.value = finalPremium;
              break;
            case 'discounts':
              factor.value = 0;
              discountIndex = factors.summaries[riskDef.riskCode].length;
              break;
            case 'clientDiscount':
              clientDiscountIndex = factors.summaries[riskDef.riskCode].length;
              break;
            default:
              break;
          }
          factors.summaries[riskDef.riskCode].push(factor);
          lp += 1;
        });
        //zniżka kliencka - kwota
        if (angular.isDefined(tariffAttrs.premiumAfterLoyaltyBonus)) {
          var premiumAfterClientDiscounts = angular.isDefined(dynVals._premiumAfterLoyaltyBonusForDisplay) ? dynVals._premiumAfterLoyaltyBonusForDisplay : tariffAttrs.premiumAfterLoyaltyBonus;
          factors.summaries[riskDef.riskCode][clientDiscountIndex].value = (premiumAfterClientDiscounts - factors.summaries[riskDef.riskCode][tariffPremiumIndex].value);
        } else {
          factors.summaries[riskDef.riskCode][clientDiscountIndex].value = 0;
        }
        //odstpestwa/promocje - kwota
        factors.summaries[riskDef.riskCode][discountIndex].value = finalPremium - factors.summaries[riskDef.riskCode][clientDiscountIndex].value - factors.summaries[riskDef.riskCode][tariffPremiumIndex].value;
      };

      /**
       * generuje sekcję 4 - szczegóły
       * @param {object} factors czynniki
       * @param {object} riskDef definicja ryzyka
       * @param {Risk} risk ryzyko
       */
      this._addDetails = function(factors, riskDef, risk) {
        factors.details[riskDef.riskCode] = [];
        var dynVals = risk.product.get('dynamicValues'),
          lp = 1;

        lsnNg.forEach(self.detailsAttributes, function(data, code) {
          var factor = new TariffFactorModel({
            lp: lp + '',
            name: data[0],
            code: code
          });
          if (code === '_extendPremium') {
            if (angular.isDefined(dynVals._extendPremiumForDisplay)) {
              factor.value = dynVals._extendPremiumForDisplay;
            } else if (angular.isDefined(dynVals._extendPremium)) {
              factor.value = dynVals._extendPremium;
            } else {
              factor.value = 0;
            }
          } else if (angular.isDefined(dynVals[code])) {
            factor.value = dynVals[code];
          } else {
            factor.value = 0;
          }
          factors.details[riskDef.riskCode].push(factor);
          lp += 1;
        });
      };

      /**
       * sumuje czynniki dla ryzyk AC i ACKR
       * sumowanie bloku 2 - kalkulacje i bloku 1 - skladka i stawka
       * @param {TariffFactorModel[]} factors czynniki
       */
      this._sumUpFactors = function(factors) {
        if (angular.isUndefined(factors.calculations[CONSTANTS.RISK_AC]) || angular.isUndefined(factors.calculations[CONSTANTS.RISK_ACKR])) {
          return;
        }
        //naglowki
        lsnNg.forEach(factors.headers[CONSTANTS.RISK_AC], function(headerFactor, idx) {
          if (angular.isDefined(self.headersToSum[headerFactor.name])) {
            if (factors.headers[CONSTANTS.RISK_ACKR][idx].value === null && factors.headers[CONSTANTS.RISK_AC][idx].value === null) { //jeśli nulle pomijamy
              return;
            }
            if (factors.headers[CONSTANTS.RISK_AC][idx].value === null) {
              factors.headers[CONSTANTS.RISK_AC][idx].value = factors.headers[CONSTANTS.RISK_ACKR][idx].value;
            } else if (factors.headers[CONSTANTS.RISK_AC][idx].value !== null && factors.headers[CONSTANTS.RISK_ACKR][idx].value !== null) {
              factors.headers[CONSTANTS.RISK_AC][idx].value += factors.headers[CONSTANTS.RISK_ACKR][idx].value;
            }
          }
        });
        //czynniki kalkulacji
        lsnNg.forEach(factors.calculations[CONSTANTS.RISK_AC], function(obj, idx) {
          if (factors.calculations[CONSTANTS.RISK_ACKR][idx].value[3] === null && factors.calculations[CONSTANTS.RISK_AC][idx].value[3] === null) { //jeśli nulle pomijamy
            return;
          }
          if (factors.calculations[CONSTANTS.RISK_AC][idx].value[3] === null) {
            factors.calculations[CONSTANTS.RISK_AC][idx].value[3] = factors.calculations[CONSTANTS.RISK_ACKR][idx].value[3];
          } else if (factors.calculations[CONSTANTS.RISK_AC][idx].value[3] !== null && factors.calculations[CONSTANTS.RISK_ACKR][idx].value[3] !== null) {
            factors.calculations[CONSTANTS.RISK_AC][idx].value[3] += factors.calculations[CONSTANTS.RISK_ACKR][idx].value[3];
          }
        });
      };

      /**
       * formatuje wartosci czynnikow pod widok
       * @param {TariffFactorModel[]} factors czynniki
       */
      this._formatFactors = function(factors) {
        //naglowki i podsumowanie
        lsnNg.forEach(['headers', 'summaries', 'details'], function(container) {
          lsnNg.forEach(factors[container], function(riskFactors) {
            lsnNg.forEach(riskFactors, function(containerFactor) {
              containerFactor.value = self.formatTariffValue(containerFactor.value, containerFactor.formatType);
            });
          });
        });

        //czynniki kalkulacji
        lsnNg.forEach(factors.calculations, function(riskFactors) {
          lsnNg.forEach(riskFactors, function(factor) {
            if (factor.value[3] !== null) {
              factor.value[3] = self.formatTariffValue(factor.value[3], factor.formatType);
            }
          });
        });
      };

      /**
       * przemapowuje dane pod templatke
       * @param {object} factors czynniki taryfikacji
       */
      this._remapFactors = function(factors) {
        var remapped = new TariffFactorsModel();
        //kolejnosc
        var riskOrder = [];
        var containers = ['headers', 'calculations', 'summaries'];
        if (appVariables.isSupplement) {
          containers.push('details');
        }
        lsnNg.forEach(self.riskOrder, function(riskCode) {
          if (angular.isDefined(factors.names[riskCode])) {
            riskOrder.push(riskCode);
          }
        });

        //przemapowywanie - naglowki, kalkulacje, podsumowania
        lsnNg.forEach(containers, function(container) {
          var containerRows = self._getFactorsPlainRows(factors, container);
          for (var i = 0; i < containerRows.length; i += 1) {
            var riskCode;
            for (var j = 0; j < riskOrder.length; j += 1) {
              riskCode = riskOrder[j];
              if (angular.isUndefined(factors[container][riskCode][i])) {
                containerRows[i].risks.push(null);
              } else {
                containerRows[i].risks.push(factors[container][riskCode][i].value);
                //sumujemy skladke laczna
                if (container === 'summaries' && factors[container][riskCode][i].code === 'premium') {
                  var totalSum = factors[container][riskCode][i].value.replace(' ', '');
                  remapped.totalPremium += parseFloat(totalSum.replace(/[\s]/g, '').replace(',', '.'));
                }
                //sumujemy skladke do dopłaty
                if (container === 'details' && factors[container][riskCode][i].code === '_extendPremium') {
                  var totalExtSum = factors[container][riskCode][i].value.replace(' ', '');
                  remapped.totalExtendPremium += parseFloat(totalExtSum.replace(/[\s]/g, '').replace(',', '.'));
                }
              }
            }
            remapped[container].push(containerRows[i]);
          }
        });
        //nazwy ryzyk
        lsnNg.forEach(riskOrder, function(riskCode) {
          remapped.names.push(factors.names[riskCode]);
        });
        //formatowanie totalPremium
        remapped.totalPremium = self.formatTariffValue(remapped.totalPremium);
        //formatowanie totalExtendPremium
        remapped.totalExtendPremium = self.formatTariffValue(remapped.totalExtendPremium);
        return remapped;
      };

      /**
       * zwraca wiersze dla nagłówków na podstawie pierwszego ryzyka z brzegu
       * @param {object} factors
       * @returns {FactorRowModel[]}
       */
      this._getFactorsPlainRows = function(factors, container) {
        var rows = [];
        lsnNg.forEach(factors[container], function(riskHeaders) {
          if (riskHeaders.length !== 0) {
            lsnNg.forEach(riskHeaders, function(tariffFactor) {
              var row = new FactorRowModel({
                lp: tariffFactor.lp,
                name: tariffFactor.name
              });
              rows.push(row);
            });
            return false;
          }
          return true; //continue
        });
        if (rows.length === 0) {
          ihestiaCommonErrorHandler.throwException('No {0} found in tariff factors for any risk.'.format(container));
        }
        return rows;
      };

      /**
       * zwraca opis sposobu likwidacji szkód
       * @param {object} dynVals atrybuty ryzyka
       * @return {null|string} opis sposobu likwidacji szkód lub null gdy nie znaleziono
       */
      this._getLiquidationOption = function(dynVals) {
        if (!angular.isObject(dynVals) || angular.isUndefined(dynVals.amortization)) {
          return null;
        }
        var liqName = null;
        lsnNg.forEach(CONSTANTS.AMORTIZATION_TYPE, function(restType, jsType) {
          if (parseInt(dynVals.amortization, 10) === restType) {
            lsnNg.forEach(RESOURCES.LIQUIDATION_OPTIONS, function(option) {
              if (option.CODE === jsType) {
                liqName = option.NAME;
                return false;
              }
              return true; //continue
            });
            return false;
          }
          return true; //continue
        });
        return liqName;
      };

      /**
       * formatuje wartości dla widoku
       * @param {type} number description
       * @param {undefined|string} type opcja (normal - formatowanie różnych wartości poza kwotami)
       * @return {*}
       */
      this.formatTariffValue = function(number, type) {
        var num = parseFloat(number, 10);
        if (isNaN(num)) {
          num = number;
        } else if (type === 'normal') {
          var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
          if (!match) {
            num = $filter('number')(num, 0);
          } else {
            var numberOfMatch = Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
            if (numberOfMatch > 2) {
              numberOfMatch = 2;
            }
            num = $filter('number')(num, numberOfMatch);
          }
        } else {
          num = $filter('number')(num, 2);
        }
        return num;
      };
    };

    return new FromRestTariffFactorsMapper();
  }
]);