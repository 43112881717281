angular.module('salesPath.outer')
        .service('addressHelper', ['ihestiaTrimInfo', 'AddressModel',
          function(ihestiaTrimInfo, AddressModel)
          {
            var AddressHelper = function()
            {

              this.getAdrStal = function(adresses)
              {
                if (adresses && adresses[0]) {
                  var adressStalData = adresses[0];
                  $.each(adresses, function(i, v) {
                    if (v.code === 'ADR_STALY')
                    {
                      adressStalData = v;
                    }
                  });

                  return adressStalData;
                } else {
                  return new AddressModel();
                }
              };

              /**
               * zwraca opis adresu zdatny do wyświetlena
               * @param  {AddressModel} address
               * @param  {object} params
               * @return {string}
               */
              this.getAddressInfo = function(address, params)
              {
                // domyslne ustawienia
                var defaultSettings =
                        {
                          elements: [
                            {field: 'streetPrefix'},
                            {field: 'street'},
                            {field: 'house', fieldSeparator: ''},
                            {field: 'apartment', prefix: '/', lastInLine: true},
                            {field: 'postalCode', prefix: ' '},
                            {field: 'city'}
                          ],
                          maxLength: 18
                        };
                var settings = $.extend(defaultSettings, params);
                var addressData = address.getData();
                // wstawiamy teksty
                $.each(settings.elements, function(key, element) {
                  element.text = addressData[element.field];
                });

                // ladnie przycinamy
                return ihestiaTrimInfo(settings);
              };


              this.REST_ADDRESSES_RESPONSE_RELATIONS = {//powiązanie wyszukiwanego parametru do elementu odpowiedzi w requeście
                postalCode: 'postalCodes',
                streetPrefix: 'streetPrefixes',
                street: 'streets',
                city: 'cities'
              };
            };

            return new AddressHelper();
          }]);