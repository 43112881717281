angular.module('iSkanerPolicies').factory('iSkanerPoliciesFilesSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
	var iSkanerPoliciesFilesSvc = function() {
		IHestiaRestServiceAbstractDef.apply(this, arguments);
		var self = this;

    this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{apiVer}/{resource}{id}{subResource}';
    this.businessArea = 'ehscanner';
    this.resource = 'admin/files';

		this.getFileList = function(token, data) {
			return self.get(null, {token: token, dataType: data.dataType});
		};

		this.deleteFile = function(fileId, token) {
			return self.remove(fileId + '?token=' + token);
		};
	};

	return new iSkanerPoliciesFilesSvc();
}]);