angular.module('ihestiaCommonDirectives.changeLanguage')
	.controller('changeLanguageCtrl', ['$scope', 'ihestiaLabelHelper',
		function($scope, ihestiaLabelHelper) {
			$scope.popoverVisible = false;

			/**
			 * [init description]
			 * @return {[type]} [description]
			 */
			$scope.init = function() {
				$scope.langSelected = ihestiaLabelHelper.selectedLanguage;
				$scope.availableLangs = ihestiaLabelHelper.getAvailableLanguages();
			};

			/**
			 * [hidePopover description]
			 * @return {[type]} [description]
			 */
			$scope.togglePopoverVisibility = function() {
				if ($scope.popoverVisible) {
					$scope.hidePopover();
				} else {
					$scope.showPopover();
				}
			};

			/**
			 * [hidePopover description]
			 * @return {[type]} [description]
			 */
			$scope.hidePopover = function() {
				$scope.popoverVisible = false;
			};

			/**
			 * [showPopover description]
			 * @return {[type]} [description]
			 */
			$scope.showPopover = function() {
				$scope.popoverVisible = true;
			};

			$scope.selectLanguage = function(langCode) {
				ihestiaLabelHelper.selectLanguage(langCode);
				$scope.langSelected = ihestiaLabelHelper.selectedLanguage;
				$scope.hidePopover();
			};

			$scope.init();
		}
	]);