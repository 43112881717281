/**
 * Domyślne tłumaczenia
 */
angular.module('lsnBase.istevenMultiSelectTranslationsHelper', [])
  .provider('istevenMultiSelectTranslationsHelper', [function() {
    var vm = this;

    this.translations = {
      noOption: 'brak',
      nothingSelected: '-wybierz-',
      reset: 'domyślne',
      search: 'wyszukaj',
      selectAll: 'wszystkie',
      selectNone: 'wyczyść',
      selected: 'wybrano',
      multiselectGroupNone: 'Odznacz wszystkie',
      multiselectGroupAll: 'Zaznacz wszystkie'
    };

    this.$get = [function(){
      return vm;
    }];
  }]);