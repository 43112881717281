angular.module('lsnBase.autocompleters')
  /**
   * pole input z autocompleterem (lub bez jeśli serwis niedostępny)
   * wymagane atrybuty:
   *   dictinarySvc: serwis/klasa usługi obsługująca słownik (póki co wspiera implementacje ihestyjnych firstName, lastName)
   *   valueObject: ref. do obiektu przetrzymującego interesującą nas daną (np. person)
   *   valueAttribute: nazwa pola w obiekcie valueObject, które przetrzymuje wartość danej (np. firstName na obiekcie person)
   *   availabilityCode: nazwa, pod którą availabilityHelper odczyta dostępność usługi wyszukującej w dictinarySvc
   */
  .directive('lsnSimpleAutocompleter', ['availabilityHelper', 'lsnTryEscapeHtml', '$parse', '$q',
    function(availabilityHelper, lsnTryEscapeHtml, $parse, $q) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          dictinarySvc: '=', // serwis
          valueObject: '=',
          valueAttribute: '=',
          errors: '=',
          popoverPlacement: '=?',
          formObject: '=?',
          formSubmitted: '=?',
          getDomExecutor: '&',
          ngDisabled: '=?',
          maxLength: '=?'
        },
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else if (availabilityHelper.isAvailable(attrs.availabilityCode)) {
            return 'lsn-base-templates-app/autocompleters/simple/simpleAutocompleter.tpl.html';
          } else {
            return 'lsn-base-templates-app/autocompleters/simple/simpleAutocompleterWithoutDict.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $scope.$watch('domExecutorApi', function(domExecutorApi){
            if(domExecutorApi) {
              $scope.getDomExecutor({
                $domExecutorApi: domExecutorApi
              });
            }
          }, true);
          $scope.promiseToCancel = $q.defer();
          $scope.loadingElements = false;
          if(!$scope.maxLength) {
            $scope.maxLength = 40;
          }
          $scope.$watch('valueObject', function(val) {
            if (angular.isUndefined(val)) {
              return;
            }
            if (!val[$scope.valueAttribute]) {
              val[$scope.valueAttribute] = '';
            }
          });

          $scope.$watch('errors', function(val) {
            if (!val || !val[$scope.valueAttribute]) {
              $scope.hasError = false;
            } else {
              $scope.hasError = !!val[$scope.valueAttribute];
              $scope.invalidText = val[$scope.valueAttribute].message;
            }
          });

          if ($attrs.invalidText) {
            $scope.invalidText = $attrs.invalidText;
          }

          // uzycie: invalid-text-label="['fillName', {componentCode: 'Public'}]"
          if($attrs.invalidTextLabel){
            $scope.invalidTextLabel = $parse($attrs.invalidTextLabel)($scope);
          }

          // if ($attrs.ngDisabled) {
          //   $scope.ngDisabled = $attrs.ngDisabled;
          // } else {
          //   $scope.ngDisabled = false;
          // }

          if (angular.isDefined($attrs.required)) {
            $scope.required = true;
          } else {
            $scope.required = false;
          }

          if (angular.isDefined($attrs.ngRequired)) {
            $scope.requiredFlag = $attrs.ngRequired;
          } else {
            $scope.requiredFlag = 'required';
          }

          if (angular.isDefined($attrs.placeholder)) {
            $scope.placeholder = $attrs.placeholder;
          }

          if (angular.isDefined($attrs.placeholderLabel)) {
            $scope.placeholderLabel = $parse($attrs.placeholderLabel)($scope);
          }

          if ($attrs.typeaheadMinLength) {
            $scope.typeaheadMinLength = $attrs.typeaheadMinLength;
          } else {
            $scope.typeaheadMinLength = 1;
          }

          if (angular.isDefined($attrs.useRegex)) // blokujemy wpisywanie wszystkiego poza literami spacją i '-'
          {
            $scope.regex = '\'^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -]*$\'';
          } else {
            $scope.regex = '';
          }

          if (availabilityHelper.isAvailable($attrs.availabilityCode)) {
            $scope.getItems = function(query) {
              if ($scope.loadingElements) {
                $scope.promiseToCancel.reject('newRequest');
                $scope.promiseToCancel = $q.defer();
              }
              $scope.loadingElements = true;
              return $scope.dictinarySvc.findByParams({
                query: query,
                top: 10,
                pageSize: 10,
                httpParams: {
                  timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) // abort na promise
                }
              }).then(function(res) {
                $scope.loadingElements = false;
                var data;
                if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                  data = {
                    items: []
                  };
                } else {
                  data = res.data;
                }
                var names = [];
                angular.forEach(data.items, function(item) {
                  names.push(lsnTryEscapeHtml(item.name));
                });

                return names;
              }, angular.noop);
            };
          }
        }]
      };
    }
  ]);
