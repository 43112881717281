angular.module('salesPath2')
  .service('addressHelper', ['ihestiaTrimInfo', 'AddressModel', 'lsnDeepExtend', 'CONSTANTS', '$filter', 'AddressModelConstants',
    function(ihestiaTrimInfo, AddressModel, lsnDeepExtend, CONSTANTS, $filter, AddressModelConstants) {
      var AddressHelper = function() {

        /**
         * zwraca adres określonego typu/kodu z kolekcji/tablicy adresów lub pusty adres, jeśli nie znaleziono
         * @param  {String} code
         * @return {AddressModel}
         */
        this.getAddressFromCollection = function(addresses, code) {
          var addressCodeData = null;
          if (angular.isArray(addresses) && addresses.length) {
            lsnNg.forEach(addresses, function(addr) {
              if (addr.get('code') === code) {
                addressCodeData = addr;
                return false;
              }
              return true; //continue
            });
          }
          return addressCodeData === null ? (new AddressModel()) : addressCodeData;
        };

        this.getAdrStal = function(adresses)
        {
          if (adresses && adresses[0]) {
            var adressStalData = adresses[0];

            angular.forEach(adresses, function(i, v) {
              if (v.code === 'ADR_STALY')
              {
                adressStalData = v;
              }
            });
            return adressStalData;
          } else {
            var addr = new AddressModel();
            addr.set('code', CONSTANTS.ADDRESS_TYPE_STAL);
            return addr;
          }
        };

        /**
         * zwraca opis adresu zdatny do wyświetlena
         * @param  {AddressModel} address
         * @param  {object} params
         * @return {string}
         */
        this.getAddressInfo = function(address, params) {
          // domyslne ustawienia
          var defaultSettings = {
            elements: [{
              field: 'postalCode'
            }, {
              field: 'city'
            }, {
              field: 'streetPrefix'
            }, {
              field: 'street'
            }, {
              field: 'house',
              fieldSeparator: ''
            }, {
              field: 'apartment',
              prefix: '/',
              lastInLine: true
            }],
            maxLength: 18,
            emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
          };
          var settings = lsnDeepExtend(defaultSettings, params);
          var addressData = address.getData();
          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            element.text = addressData[element.field] || '';
          });

          // ladnie przycinamy
          return ihestiaTrimInfo(settings);
        };

        /**
         * dodaje do przekazanego obiektu dane:
         * mainAddress: plain object adresu głównego pobieranego z data.addresses
         * hasMainAddress: bool czy usało się pobrać adres
         * @param {Object} data dane uzupełione o powyższe informacje
         */
        this.addMainAddressData = function(data) {
          data.mainAddress = {};
          data.hasMainAddress = false;
          angular.forEach(data.addresses, function(address) { //eslint-disable-line consistent-return
            if (address.code === AddressModelConstants.ADDRESS_TYPE_STAL) {
              data.mainAddress = address;
              angular.forEach(data.mainAddress, function(v, k) {
                if (v === null) //nie chcemy nulli wyświetlać
                {
                  data.mainAddress[k] = '';
                }
              });
              data.hasMainAddress = true;
              return false;
            }
          });
          angular.forEach(data, function(v, k) {
            if (v === null) {
              data[k] = '';
            }
          });
          return data;
        };

        this.REST_ADDRESSES_RESPONSE_RELATIONS = { //powiązanie wyszukiwanego parametru do elementu odpowiedzi w requeście
          postalCode: 'postalCodes',
          streetPrefix: 'streetPrefixes',
          street: 'streets',
          city: 'cities'
        };
      };

      return new AddressHelper();
    }
  ]);