angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifePolicyTerminationModalHelper', ['LsnModalHelper', 'LifePolicyOperationsConstants', 'policiesSvc', '$filter',
    function(LsnModalHelper, LifePolicyOperationsConstants, policiesSvc, $filter) {
      /**
       * @typedef LifePolicyTerminationCause
       * @property {string} policyOperationCauseCode
       * @property {string} policyOperationCauseName
       * @property {string} policyType
       */
      /**
       * @typedef IhestiaLifePolicyTerminationModalHelper
       * @extends LsnModalHelper
       */
      var IhestiaLifePolicyTerminationModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        /**
         *
         * @type {IhestiaLifePolicyTerminationModalHelper}
         */
        var self = this;

        this.LifeTerminationCauseCodes = [LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION_ON_DATE, LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION_ON_FIRST_PAYMENT];

        this.tplData = {
          loading: true,
          causesToDisplay: null,
          causes: null
        };
        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'sm',
          title: ['choosePolicyCancelType', {componentCode: 'PolicyOperations'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/policyTermination/policyTerminationModal.tpl.html',
          okBtnName: null,
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };

        this.modalData = {
          policyNumber: null
        };

        this.setOptions(this.modalOptions);

        this.init = function() {
          self.tplData.loading = true;
          self.tplData.causes = null;
          self.tplData.causesToDisplay = null;
          policiesSvc.get(self.modalData.policyNumber, null, 'availableTerminationCauses/')
            .then(function(res) {
              self.tplData.causes = res.data;
              self.tplData.causesToDisplay = self.filterCauseCodes(res.data);
              self.setCauseDescriptions();
            }, lsnNg.noop)
            .finally(function() {
              self.tplData.loading = false;
            });
        };

        this.onChooseTerminationType = function(cause) {
          var terminationType = cause.policyOperationCauseCode;
          var result = {
            terminationType: terminationType
          };
          if (terminationType === LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION) {
            result.availableTerminationTypes = _.filter(self.tplData.causes, function(cause) {
              return self.LifeTerminationCauseCodes.indexOf(cause.policyOperationCauseCode) >= 0;
            });
          }
          self.setPromiseAsResolved(result);
          return self.hideModal();
        };

        /**
         * @param {LifePolicyTerminationCause[]} causes
         */
        this.filterCauseCodes = function(causes) {

          var isTerminationTypePresent = !_.isEmpty(_.intersectionWith(causes, self.LifeTerminationCauseCodes, function(cause, terminationType) {
            return cause.policyOperationCauseCode === terminationType;
          }));
          var causesToDisplay = _.filter(causes, function(cause) {
            return self.LifeTerminationCauseCodes.indexOf(cause.policyOperationCauseCode) < 0;
          });
          if (isTerminationTypePresent) {
            causesToDisplay.push({
              policyOperationCauseCode: LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION,
              policyOperationCauseName: $filter('translate')('policyTerminationEhl.terminationPageTitle', {componentCode: 'PolicyOperations'}),
              policyType: 'Life'
            });
          }
          return causesToDisplay;
        };

        this.setCauseDescriptions = function() {
          _.forEach(self.tplData.causesToDisplay, function(cause) {
            switch (cause.policyOperationCauseCode) {
              case LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION:
                cause.optionIcon = 'icon-file-block';
                cause.optionTitle = cause.policyOperationCauseName;
                cause.optionDescription = $filter('translate')('policyTerminationEhl.terminationTypeDescription', {componentCode: 'PolicyOperations'});
                break;
              case LifePolicyOperationsConstants.OPERATION_CAUSE.NOTICE:
                cause.optionIcon = 'icon-file-block';
                cause.optionTitle = cause.policyOperationCauseName;
                cause.optionDescription = $filter('translate')('policyTerminationEhl.noticeTypeDescription', {componentCode: 'PolicyOperations'});
                break;
              case LifePolicyOperationsConstants.OPERATION_CAUSE.CANCELLATION:
                cause.optionIcon = 'icon-file-attention';
                cause.optionTitle = cause.policyOperationCauseName;
                cause.optionDescription = $filter('translate')('policyTerminationEhl.cancellationTypeDescription', {componentCode: 'PolicyOperations'});
                break;
              case LifePolicyOperationsConstants.OPERATION_CAUSE.WITHDRAWAL:
                cause.optionIcon = 'icon-file-block';
                cause.optionTitle = cause.policyOperationCauseName;
                cause.optionDescription = $filter('translate')('policyTerminationEhl.withdrawalTypeDescription', {componentCode: 'PolicyOperations'});
                break;
              default:
                break;
            }
          });
        };
      };
      return new IhestiaLifePolicyTerminationModalHelper();
    }]);
