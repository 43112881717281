angular.module('lsnBase.models')
.factory('LsnIcOrganizationUnitModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnIcOrganizationUnitModelV1 = function()
    {
      this.objectName = 'IcOrganizationUnit';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        subjectKeyString: {
          type: 'string'
        },
        symbol: {
          type: 'string'
        },
        name: {
          type: 'string'
        }
      };

      this.subjectKeyString = null; //  string  Identyfikator podmiotu sieci sprzedaży.
      this.symbol = null; //  string  Symbol jednostki organizacyjnej.
      this.name = null; //  string  Nazwa przedstawicielstwa.

    };

    LsnIcOrganizationUnitModelV1.prototype = LsnAbstractModel.prototype;

    return LsnIcOrganizationUnitModelV1;
  }]);