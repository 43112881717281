angular.module('lsnBase.models')
  .factory('LsnDistributionChannelModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel)
    {
      var LsnDistributionChannelModel = function()
      {
        this.objectName = 'DistributionChannel';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion; 
        this.fieldsProperties = {          
          branchSymbol:
          {
            type: 'string'
          },
          branchName:
          {
            type: 'string'
          },
          profileNumber:
          {
            type: 'string'
          },
          intermediaryNumber:
          {
            type: 'string'
          },
          intermediaryName:
          {
            type: 'string'
          },
          personName:
          {
            type: 'string'
          },
          personSurname:
          {
            type: 'string'
          },
          punu:
          {
            type: 'string'
          },
          share:
          {
            type: 'int'
          },
          leading:
          {
            type: 'bool'
          }
        };

        this.branchSymbol = null; // string, symbol przedstawicielstwa
        this.branchName = null; // string, nazwa przedstawicielstwa
        this.profileNumber = null; // string, numer profilu sprzedazowego
        this.intermediaryNumber = null; // string, numer posrednika grupy
        this.intermediaryName = null; // string, nazwa posrednika grupy
        this.personName = null; // string, imie posrednika
        this.personSurname = null; // string, nazwisko posrednika
        this.punu = null; // string, numer PUNU posrednika
        this.share = null; // string, procentowy udzial w prowizji
        this.leading = null; // string, czy kanal wiodacy
      };

      LsnDistributionChannelModel.prototype = LsnAbstractModel.prototype;

      return LsnDistributionChannelModel;
    }
  ]);