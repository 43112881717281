angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryLegalSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryLegalSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'legalforms';
        this.sortBy = 'name';

      };
      return new RestDictionaryLegalSvc();
    }
  ]);