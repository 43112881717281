angular.module('lsnBase.validators')
  .directive('clearInvalidChars', function() { // dziala tylko onBlur. Ustawia poprawny adres url dla linka zewntrznego lub wewnetrznego
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        var dict = attrs.dict,
          smsDict = attrs.smsDict;

        /**
         * usuwa nie potrzebne znaki ze stringak
         * @param {String} inputValue
         * @returns {String}
         */
        var clear = function(string) {
          var newVal = [];

          if (smsDict) {
            angular.forEach(string.split(''), function(letter) {
              var numberLetter = letter.charCodeAt(0);
              if (numberLetter === 10 || (numberLetter > 31 && numberLetter < 127) || numberLetter === 711 || numberLetter === 8364) {
                newVal.push(letter);
              }
            });
          } else {
            angular.forEach(string.split(''), function(letter) {
              if (dict.indexOf(letter) !== -1) {
                newVal.push(letter);
              }
            });
          }

          var newString = newVal.join('');

          element.val(newString);
          ngModel.$setViewValue(newString);

          return newString;
        };

        ngModel.$parsers.unshift(clear);

        // For model -> DOM validation
        ngModel.$formatters.unshift(clear);

      }
    };
  });