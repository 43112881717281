angular.module('salesPath2')
  .service('matrixVehicleBoxHelper', ['vehicleHelper',
    function(vehicleHelper) {
        var self = this;

        this.tplData = {
          vehicles: [] //lista pojazdów
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'vehicleEdited':
            case 'vehicleAdded':
              self.refreshVehicles();
              break;
            default:
              break;
          }
        };

        /**
         * aktualizuje listę pojazdów
         */
        this.refreshVehicles = function() {
          self.tplData.vehicles = vehicleHelper.getVehicles(); 
        };
    }
  ])
  .run(['matrixVehicleBoxHelper', 'actionHelper',
    function(matrixVehicleBoxHelper, actionHelper) {
      actionHelper.registerHelper('matrixVehicleBoxHelper', matrixVehicleBoxHelper);
    }
  ]);