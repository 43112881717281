angular.module('lsnBase.models')
.factory('LsnIdentityDocumentModelConstants', [function() {
    var IdentityDocumentModelConstants = {};

    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_DOOS = 'DOWOSO';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_PASZ = 'FS0002';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_DOTY = 'DOWTYM';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_KAST = 'KARSTA';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_KATY = 'KARCZA';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_INNY = 'INNY';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_PRAW = 'PRAWOJ';
    // IHESTIAWZ-3260
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_NBKID = 'NBKID';
    IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_NBKVER = 'NBKVER';

    // słownik typów tożsamości
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE = {};
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_DOOS] = 'dowód osobisty';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_PASZ] = 'paszport';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_DOTY] = 'dowód tymczasowy';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_KAST] = 'karta stałego pobytu';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_KATY] = 'karta czasowego pobytu';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_INNY] = 'inny';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_PRAW] = 'prawo jazdy';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_NBKID] = 'nbkid';
    IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[IdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_NBKVER] = 'nbkver';

    return IdentityDocumentModelConstants;
  }]);
