angular.module('salesPath2')
  .service('productsClausesErrorsHelper', ['sp2CommonHelper',
    function(sp2CommonHelper) {
      var ProductsClausesErrorsHelper = function() {

        //ustawienia modali
        this.modals = {};

        this.modals.productsClausesErrorsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.summary.clausesNotAvailableTitle',
            size: 'sm',
            okBtnName: 'sp2.modal.close',
            cancelBtnName: false
          }
        };

        /**
         * Wyświetlenie modala
         */
        this.showModal = function() {
          sp2CommonHelper.showModal('productsClausesErrorsModal');
        };
      };

      return new ProductsClausesErrorsHelper();
    }
  ]);
