angular.module('salesPath2')
  .service('cessionModalHelper', ['mainDataContainer', 'OrganizationModel', 'CONSTANTS', '$rootScope', 'dictionaryServiceHelper', 'summaryHelper', 'sp2CommonHelper', 'actionHelper', 'applicationHelper', '$filter', 'addressHelper', 'organizationHelper', 'dataContainerHelper', 'appVariables', 'productsClausesCleanupHelper',
    function(mainDataContainer, OrganizationModel, CONSTANTS, $rootScope, dictionaryServiceHelper, summaryHelper, sp2CommonHelper, actionHelper, applicationHelper, $filter, addressHelper, organizationHelper, dataContainerHelper, appVariables, productsClausesCleanupHelper) {
      var CessionModalHelper = function() {
        var self = this;


        //ustawienia modali
        this.modals = {};

        this.headerListOptions = {
          BANK: {
            name: 'sp2.modal.cessionModal.header.bank',
            value: 'BANK'
          }, 
          PERSON: {
            name: 'sp2.modal.cessionModal.header.person',
            value: 'PERSON'
          }
        };

        /**
         * Ustawienia modala cesji
         * @type {Object}
         */
        this.modals.cessionModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.cessionModal.title',
            cancelBtnName: 'sp2.modal.cancel',
            okBtnName: 'sp2.modal.confirm',
            thirdBtnName: '',
            headerList: [],
            headerListModel: 'modals.cessionModal.type',
            size: 'md'
          },
          type: 'BANK'
        };

        var DEFAULT_MODAL_DATA = {
          bankData: null,
          organizationData: {addresses: [{}], countryCode: 'PL'},
          selectedOrganization: '-1', //clientId wybranej z selecta organizacja lub '-1', gdy nowa organizacja
          orgSelectOptions: [], //opcje dla selecta organizacji
          orgSelectVisible: false, //czy select z wyborem organizacji widoczny
          dataCleared: true,
          organizationId: null,
          bankId: null,
          isBank: true
        };

        this.modalData = angular.copy(DEFAULT_MODAL_DATA);
        this.modalData.countries = {};
        this.policyId = null;
        this.policyCession = {};

        /*
         * Ładowanie danych
         */
        this.loadData = function() {
          var policyData;
          lsnNg.forEach(mainDataContainer.policies, function(policy) {
            if (policy.get('number') === self.policyId) {
              policyData = policy.getData();
              return false;
            }

            return true; //continue
          });

          var policyCession = summaryHelper.getPolicyExtraData(policyData, CONSTANTS.POLICY_EXTRA_CESSION);
          self.loadOrganization(policyCession);
        };

        /**
         * ładuje dane dotyczące selecta organizacji
         * @param  {LsnOrganizationModelV1} [currentOrg] aktualnie wybrana organizacja
         */
        this.loadOrgSelectData = function(currentOrg) {
          //obsługa tylko organizacji - cesji (nie banków)
          currentOrg = (currentOrg && currentOrg.dynamicValues.type === CONSTANTS.ORGANIZATION_TYPE_LEASING) ? currentOrg : null;
          var orgs = organizationHelper.getOrganizationsByType(CONSTANTS.ORGANIZATION_TYPE_LEASING);

          //domyslne ustawienia dla selecta organizacji
          self.modalData.selectedOrganization = (currentOrg !== null && currentOrg.dynamicValues.type === CONSTANTS.ORGANIZATION_TYPE_LEASING) ? currentOrg.metaData.clientId : '-1';
          self.modalData.orgSelectOptions = [];
          self.modalData.orgSelectVisible = false;

          if (orgs.length > 0 ) { //jesli zdefiniowana jakaś organizacja jako cesja-firma lub leasing
            if (self.modalData.selectedOrganization === '-1') { //jeśli brak cesji-firma, to zaznaczamy domyślnie pierwszą pozycję ze słownika
              self.modalData.selectedOrganization = orgs[0].metaData.get('clientId');
            }
            self.modalData.orgSelectVisible = true;
            self.modalData.organizationData = mainDataContainer.organizations[self.modalData.selectedOrganization].getData();
            self.modalData.organizationId = self.modalData.organizationData.metaData.id;
            // we neede at least one address address object in addressess array
            if (!angular.isArray(self.modalData.organizationData.addresses) || self.modalData.organizationData.addresses.length === 0) {
              self.modalData.organizationData.addresses = [{}];
            }
          }

          if (self.modalData.orgSelectVisible) { //jesli select ma byc widoczny, to generujemy jego zawartość
            angular.forEach(orgs, function(org) {
              self.modalData.orgSelectOptions.push({
                clientId: org.metaData.get('clientId'),
                name: organizationHelper.getOrganizationInfo(org)
              });
            });
            //dodajemy opcję - "inna"
            self.modalData.orgSelectOptions.push({
              clientId: '-1',
              name: $filter('translate')('cessionOrgSelect.other', {componentCode: 'sp2'})
            });
          }
        };

        /**
         * ustawia listę opcji do naglowka modala cesji
         * @param {string} [type] opcjonalny typ (value) dla opcji, która ma pozostac w naglowku
         */
        this.setHeaderList = function(type) {
          self.modals.cessionModal.settings.headerList = [];
          lsnNg.forEach(self.headerListOptions,function(opt) {
            if (type && type !== opt.value) {
              return true;
            }
            self.modals.cessionModal.settings.headerList.push(opt);
            return true;
          });
        };

        /*
         * Ładowanie cesjonariusza - danych do zakładek bank i osoba prawna
         */
        this.loadOrganization = function(organization) {
          var readonly = appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer);
          //domyslne dane
          self.modalData.organizationData = {addresses: [{}], countryCode: 'PL'};
          self.modalData.organizationId = null;
          //ładujemy aktualne dane
          self.loadOrgSelectData(organization);
          if (!readonly) {
            self.setHeaderList();
          } else { //w trybie readonly
            self.modalData.orgSelectVisible = true;
          }
          if(organization) {
            if(organization.dynamicValues.type === CONSTANTS.ORGANIZATION_TYPE_BANK) {
              self.modalData.bankData = organization;
              self.modalData.bankId = organization.metaData.id;
              self.modalData.isBank = true;
              if (readonly) {
                self.setHeaderList('BANK');
              }
            } else {
              self.modalData.bankData = null;
              self.modalData.bankId = null;
              self.modalData.isBank = false;
              if (readonly) {
                self.setHeaderList('PERSON');
              }
            }
          }

          if(organization && !readonly) {
            $rootScope.modals.cessionModal.settings.thirdBtnName = $filter('translate')('btn.removeCessionary', {componentCode: 'sp2'});
          } else {
            $rootScope.modals.cessionModal.settings.thirdBtnName = '';
          }
        };

        /**
         * Ładowanie słownika krajów
         */
        this.loadCountriesDict = function() {
          dictionaryServiceHelper.getDictionary('countries', {
            requestType: 'searches',
            callback: function(countries) {
              self.modalData.countries = countries;
            }
          });
        };

        /**
         * Zapis danych banku w datacontenairze
         */
        this.saveOrganization = function(organizationData) {
          var organizationAlreadyAdded = false;
          lsnNg.forEach(mainDataContainer.organizations, function(organization) {
            if (organization.metaData.get('id') !== null && organization.metaData.get('id') === organizationData.metaData.id) {
              organizationAlreadyAdded = true;
              return false;
            }

            return true; //continue
          });

          if (!organizationAlreadyAdded) {
            var organization = new OrganizationModel();
            organization.fillFromData(organizationData);

            var organizationId = dataContainerHelper.getNextOrganizationId();
            organization.metaData.set('clientId', organizationId);

            organization.set('dynamicValues', {type: CONSTANTS.ORGANIZATION_TYPE_BANK});
            mainDataContainer.organizations[organizationId] = organization;
          }
        };

        this.saveExtras = function(extraLabel, data) {
          var id = self.policyId;
          var policy;
          var i;
          var cessionOrganization;    
          var previousCessionOrganization = null;
          var orgClientId = null; //clientId aktualnie wybranej organizacji - cedjonalriusza

          for (i = 0; i < mainDataContainer.policies.length; i += 1) {
            policy = mainDataContainer.policies[i];
            if (policy.get('number') === id) {
              break;
            }
            policy = null;
          }

          if (policy === null) {
            throw new Error('Tried to save extras on police that no longer exists. Police numer: ' + id);
          }
         //jeśli to polisa klienta to zapisujemy na powiązanej cesyjnej            
          if(angular.isObject(policy.product) && angular.isObject(policy.product.dynamicValues) && policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT) {
            var cessionPolicy = summaryHelper.findRelatedClientCessionPolicy(policy);
            if(cessionPolicy === null) {
              throw new Error('No cession policy found for client policy.');
            }
            policy = cessionPolicy;
          }
          //szukamy wcześniej wybranego cesjonariusza
          if (policy.get('suretyRef').length !== 0) {
            previousCessionOrganization = dataContainerHelper.getObject(CONSTANTS.OBJECT_TYPE_ORGANIZATION, policy.get('suretyRef')[0], 'id');
          }


          switch (extraLabel) {
            case 'CESSION':
              if (self.modalData.selectedOrganization === '-1') {
                 //nowa organizacja
                 cessionOrganization = new OrganizationModel();
              } else {
                cessionOrganization = mainDataContainer.organizations[self.modalData.selectedOrganization];
                orgClientId = self.modalData.selectedOrganization;
              }

              cessionOrganization.fillFromData(data);
              cessionOrganization.set('dynamicValues', {type: CONSTANTS.ORGANIZATION_TYPE_LEASING});

              if (orgClientId === null) {
                orgClientId = dataContainerHelper.getNextOrganizationId();
              }

              cessionOrganization.metaData.set('clientId', orgClientId);
              mainDataContainer.organizations[orgClientId] = cessionOrganization;
              policy.set('suretyRef', [cessionOrganization.metaData.get('id')]);
              break;
            case 'CESSION_BANK':
              //sprawdzamy czy poprzednia organizacja nie jest tą samą którą właśnie zatwierdzamy - czyli nic sie nie zmieniło
              if(previousCessionOrganization === null || previousCessionOrganization.metaData.get('id') !== data){
                cessionOrganization = null;
                lsnNg.forEach(mainDataContainer.organizations, function(organization) {
                  if (organization.metaData.get('id') !== null && organization.metaData.get('id') === data) {
                    cessionOrganization = organization;
                    return false;
                  }
                  return true; //continue
                });
                policy.set('suretyRef', [cessionOrganization.metaData.get('id')]);
              }
              break;

            default:
              break;
          }


          for (i = 0; i < mainDataContainer.policies.length; i += 1) {
            if (mainDataContainer.policies[i].getData().metaData.id === id) {
              mainDataContainer.policies[i] = policy;
            }
          }

          //usuwanie nieubezpieczonych organizacji
          organizationHelper.deleteUnusedOrganizations();
          actionHelper.runAction('cessionEdited'); 
          actionHelper.runAction('saveApplication');         
        };

        /**
         * Czyscimy dane modala
         * @return {CessionModalHelper} [description]
         */
        this.clearModalData = function() {
          this.modalData = angular.copy(DEFAULT_MODAL_DATA);
          return self;
        };

        /**
         * Usuwanie cesjonariusza z dataContainera
         */
        this.deleteOrganization = function() {
          var policy,
            orgToDelete = null; //obiekt organizacji, którą bedziemy probowali usunac, jesli ine jest nigdzie uzyta
          lsnNg.forEach(mainDataContainer.policies, function(onePolicy){
            if (onePolicy.get('number') === self.policyId)
            {
              policy = onePolicy;
              return false;
            }

            return true; //continue
          });

          //jeśli to polisa klienta to zapisujemy na powiązanej cesyjnej
          if (policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT) {
            var cessionPolicy = summaryHelper.findRelatedClientCessionPolicy(policy);
            if (cessionPolicy === null) {
              throw new Error('No cession policy found for client policy.');
            }
            policy = cessionPolicy;
          }


          //uwuwamy organizację z datacontainera
          if (angular.isArray(policy.suretyRef) && policy.suretyRef[0]) {
            var org = dataContainerHelper.getObject(CONSTANTS.OBJECT_TYPE_ORGANIZATION, policy.suretyRef[0], 'id');
            if (org !== null) {
              orgToDelete = org;
            }
          }
          policy.set('suretyRef', []);
          if (angular.isObject(orgToDelete) && !organizationHelper.isOrganizationInsured(orgToDelete)) {
            organizationHelper.deleteOrganization(orgToDelete.metaData.get('clientId'));
          }
          actionHelper.runAction('cessionDeleted'); 
          actionHelper.runAction('saveApplication');        
        };

        this.showEditCessionModal = function(policyId){
            self.policyId = policyId;
            self.loadData();
            var policyType = sp2CommonHelper.getPolicyType(dataContainerHelper.getPolicyByNumber(policyId));
            if(policyType === 'property'){
              self.modals.cessionModal.settings.title = ['cessionModal.property.header', {componentCode: 'sp2'}];
            }else if(policyType === 'communication'){
              self.modals.cessionModal.settings.title = ['cessionModal.communication.header', {componentCode: 'sp2'}];
            }else{
              self.modals.cessionModal.settings.title = ['cessionModal.other.header', {componentCode: 'sp2', 'params': {policyType: policyType}}];
            }

            self.modals.cessionModal.settings.cancelBtnName = 'sp2.modal.cancel';
            self.modals.cessionModal.settings.okBtnName = 'sp2.modal.confirm';
            if(appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer)) {
              self.modals.cessionModal.settings.cancelBtnName = '';
              self.modals.cessionModal.settings.okBtnName = 'sp2.modal.close';
            }

            sp2CommonHelper.showModal('cessionModal');
        };

        /**
         * Usuwanie klauzul produktowych przy usuwaniu cesjonariusza
         * https://ebok.atena.pl/browse/IHESTIAGEN-881
         */
        this.deleteProductClauses = function() {
          if(self.policyId) {
            // https://ebok.atena.pl/browse/IHESTIAGEN-3591
            var policy = mainDataContainer.policies.find(function(pol) {
              return pol.number === self.policyId;
            });
            var cessionPolicy = summaryHelper.findRelatedClientCessionPolicy(policy);
            if (!cessionPolicy) {
              return;
            }

            if (mainDataContainer.productClauses[self.policyId] && mainDataContainer.productClauses[self.policyId].clauseCode.length > 0) {
              productsClausesCleanupHelper.showModal('_calculateEstatesTogether');
            }
            delete mainDataContainer.productClauses[self.policyId];
          }
        };
      };

      return new CessionModalHelper();
    }
  ]);