angular.module('ihestiaGridSettings')
.factory('IHestiaGridSettingsSettingsModel', [ 'IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IHestiaGridSettingsSettingsModel = function()
    {
      this.objectName = 'ihestiaGridSettingsSettingsModel';

      this.fieldsProperties = {
        filterSettings: {
          type: 'object'
        },
        columnSettings: {
          type: 'IHestiaGridSettingsColumnSettingsModel'
        }
      };

      this.filterSettings = null; //ustawienia filtrów
      this.columnSettings = null; //ustawienia kolumn
    };

    IHestiaGridSettingsSettingsModel.prototype = IHestiaAbstractModel.prototype;

    return IHestiaGridSettingsSettingsModel;
  }]);