/**
 * filtr zamieniający pustego stringa w słowniku na stały kod, żeby uniknąć problemów z selectem i filtrowaniem pustych wartości
 */
angular.module('ihestiaCommonDirectives')
  .filter('replaceEmptyStringCode', ['ihestiaDictionaryEmptyStringCodeReplace',
    function(ihestiaDictionaryEmptyStringCodeReplace){
    return function(dictionary, codeAttribute) {
      if(typeof codeAttribute === 'undefined')
      {
        codeAttribute = 'code';
      }

      if(angular.isArray(dictionary))
      {
        angular.forEach(dictionary, function(item){
          if(item[codeAttribute] === '')
          {
            item[codeAttribute] = ihestiaDictionaryEmptyStringCodeReplace;
          }
        });
      }

      return dictionary;
    };
}]);
