angular.module('iSkanerAztecs')
    .directive('iskanerAztecs', ['ihestiaCommonErrorHandler', 'iskanerCommonHelper',
        function(ihestiaCommonErrorHandler, iskanerCommonHelper) {
            return {
                restrict: 'A',
                replace: true,
                templateUrl: function(elem, attrs) {
                    return attrs.drvTemplate || 'ihestia-commondirectives-template-app/iskaner/iSkanerAztecs/iSkanerAztecs.tpl.html';
                },
                scope: {
                    config: '=iskanerAztecs'
                },
                controller: 'iSkanerAztecsCtrl',
                link: function(scope, element) {
                    activate();


                    function activate() {
                        if (!isIskanerAztecsConfigValid(scope.config)) {
                            element.addClass('iSkanerAztecs-disabled');
                        }
                    }

                    function isIskanerAztecsConfigValid(input) {
                        if (!iskanerCommonHelper.validConfig(input)) {
                            return false;
                        }

                        if (!angular.isFunction(input.callback)) {
                            ihestiaCommonErrorHandler.throwException({
                                message: 'iSkaner config: callback must be a function'
                            });
                            return false;
                        }

                        return true;
                    }
                }
            };
        }
    ]);