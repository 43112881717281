angular.module('salesPath2')
  .controller('rightSidebarCtrl', ['$scope',
    function($scope) {
      // -- start --
      $scope.clickOutsideSidebar = function() {
        $scope.appVariables.contentPulledLeft = false;
        $scope.appVariables.contentPulledLeftDoubled = false;
        $scope.appVariables.messageContainerVisible = false;
        $scope.appVariables.basketContainerVisible = false;
      };
    }
  ]);