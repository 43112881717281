angular.module('ihestiaCommonDirectives.binaryUpload')
  .factory('FileModelForBinary', ['addMd5ToFile', 'ihestiaUploadModelHelper', 'ihestiaCommonErrorHandler', 'BlobService', function(addMd5ToFile, ihestiaUploadModelHelper, ihestiaCommonErrorHandler, BlobService) {

    var FileModelForBinary = function() {
      var self = this;
      /**
       * Fizyczny plik do wysłania
       * @type {FILE}
       */
      this.file = null;

      /**
       * [blob alternatywa dla andorida 4.4 gdy brak obiektu FILE]
       * @type {[type]}
       */
      this.blob = null;

      /**
       * Data utworzenia komentarza
       * @type {String}
       */
      this.errorText = '';

      /**
       * status przesłanego pliku
       * @type {String}
       */
      this.status = null;

      /**
       * name nazwa pliku
       * @type {String}
       */
      this.name = null;

      /**
       * size rozmiar pliku
       * @type {String}
       */
      this.size = null;

      /**
       * id pliku na serwerze
       * @type {String}
       */
      this.fileServerId = null;

      this.sendingInfo = false;

      this.earlierUploadedFile = false;

      /**
       * nazwa typu dokumentu
       */
      this.documentTypeName = null;
      this.documentTypeCode = null;

      /**
       * jesli plik chunkowany
       */
      this.parts = null;

      /**
       * [attributes atrybuty typu dokumentu]
       * @type {Object}
       */
      this.attributes = {};

      /**
       * nazwa którą dajemy w przypadku gdy nie mamy żadnej fizycznej, np. zdjęcie z kamery telefonu
       * @type {String}
       */
      this.defaultFileName = 'picture.JPG';

      /**
       * funkcja sprawdza czy ten plik nie został jeszcze wysłany
       */
      this.fileToSend = function() {
        return self.status === null;
      };

      this.hasError = function() {
        return self.status !== 'SUCCESS' && self.status !== null;
      };

      this.setStatusFile = function(status, errorText) {
        ihestiaUploadModelHelper.setStatusFile(self, status, errorText);
      };

      this.convertToUploadedFile = function(status, errorText, fileServerId) {
        ihestiaUploadModelHelper.convertToUploadedFile(self, status, errorText, fileServerId);
      };

      this.setFileData = function(fileData) {

        if (fileData.binary) {
          self.createFromBinary(fileData);
        } else if (fileData.base64) {
          if (fileData.alternative) {
            self.createFromBase64Alternative(fileData);
          } else {
            self.createFromBase64(fileData);
          }

        } else {
          ihestiaCommonErrorHandler.throwException({
            message: 'Nie podano body dla pliku ani w postaci base64 ani w postaci binarnej'
          });
        }
      };

      /**
       * [createFromBinary tworzy plik z postaci binarnej]
       * @param  {[type]} fileData [description]
       * @return {[type]}          [description]
       */
      this.createFromBinary = function(fileData) {
        var FileConstructor = self._getFileConstructor();
        var fileName = fileData.name;
        if (!fileName) {
          fileName = self.defaultFileName;
        }
        var file = new FileConstructor([fileData.binary], fileName, {
          type: fileData.type,
          lastModified: new XDate()
        });
        self.setPreparedFile(file);
      };

      /**
       * [createFromBase64 tworzy plik z postaci base64]
       * @param  {[type]} fileData [description]
       * @return {[type]}          [description]
       */
      this.createFromBase64 = function(fileData) {
        var u8arr = self.base64ToBinary(fileData);
        var FileConstructor = self._getFileConstructor();
        var fileName = fileData.name;
        if (!fileName) {
          fileName = self.defaultFileName;
        }
        var file = new FileConstructor([u8arr], fileName, {
          type: fileData.type,
          lastModified: new XDate()
        });
        self.setPreparedFile(file);
      };

      this.base64ToBinary = function(fileData) {
        var bstr = atob(fileData.base64),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return u8arr;
      };

      this.createFromBase64Alternative = function(fileData) {
        self.name = fileData.name;
        if (!self.name) {
          self.name = self.defaultFileName;
        }
        self.type = fileData.type;
        self.blob = BlobService.dataURItoBlob('data:' + fileData.type + ';base64,' + fileData.base64);
        addMd5ToFile(self.blob);
        self.size = self.blob.size;
      };

      /**
       * Zwraca konstruktor plików (dla aplikacji ionic będzie NativeFile)
       * @return {File}
       */
      this._getFileConstructor = function() {
        return typeof NativeFile !== 'undefined' ? NativeFile : File;
      };

      /**
       * [setPreparedFile funkcja wywolywana po utworzeniu pliku]
       */
      this.setPreparedFile = function(file) {
        addMd5ToFile(file);
        self.file = file;

        if (angular.isObject(file)) {
          self.name = file.name;
          self.size = parseInt(file.size, 10);
          self.type = file.type;
        }
      };

      this.setDocumentType = function(documentType) {
        ihestiaUploadModelHelper.setDocumentType(self, documentType);
      };

      /**
       * funkcja zwraca informacje o pliku do wysłania do usługi
       * która sprawdza poprawność atrybutów wysyłąnego pliku
       * tuż przed jego wysłaniem
       */
      this.getInfoAboutFile = function() {
        self.sendingInfo = true;
        return ihestiaUploadModelHelper.getInfoAboutFile(self);
      };

      this.getMd5 = function() {
        return (self.blob ? self.blob.md5 : self.file.md5);
      };

      this.getDataFileForSendingRequest = function() {
        var form = new FormData();
        form.append('files', self.blob ? self.blob: self.file, self.name);
        return form;
      };

      this.getPartDataFileForSendingRequest = function(start, end) {
        var form = new FormData(),
        file = self.blob ? self.blob: self.file;

        form.append('files', file.slice(start, end), self.name);
        return form;
      };
    };
    return FileModelForBinary;
  }]);