angular.module('lsnBase.filters')
    .filter('polishGrammar', [function() {
        return function(text) {
            if (typeof text === 'string') {
                var repl = ['i', 'a', 'o', 'z', 'w', 'lub', 'oraz', 'na', 'pod', 'za', 'nad', 'bo', 'gdy', 'do', 'od', 'dla'];
                angular.forEach(repl, function(s) {
                    var reg = new RegExp(' ' + s + ' ', 'gi');
                    var rep = ' ' + s + '&nbsp;';
                    text = text.replace(reg, rep);

                    reg = new RegExp('&nbsp;' + s + ' ', 'gi');
                    rep = '&nbsp;' + s + '&nbsp;';
                    text = text.replace(reg, rep);

                    reg = new RegExp('\\(' + s + ' ', 'gi');
                    rep = '(' + s + '&nbsp;';
                    text = text.replace(reg, rep);
                });
            }
            return text;
        };
    }]);