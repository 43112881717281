angular.module('ihestiaCommonDirectives')
        .directive('focusOnFirstInput', ['$timeout', function($timeout) {
          return {
            restrict: 'A',
            link: function(_scope, elem) {
              $timeout(function() {
                var firstInput = angular.element(
                        elem[0].querySelector('input'))[0];

                if (firstInput) {
                  firstInput.focus();
                }
              }, 0);
            }
          };
        }]);