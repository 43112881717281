angular.module('salesPath2')
  .service('productsClausesCleanupHelper', ['sp2CommonHelper', 'dataContainerHelper', 'mainDataContainer', 'appVariables', 'CONSTANTS', 'actionHelper', 
    function(sp2CommonHelper, dataContainerHelper, mainDataContainer, appVariables, CONSTANTS, actionHelper) {
      var ProductsClausesCleanupHelper = function() {
        var self = this;

        //ustawienia modali
        this.modals = {};

        this.modals.productsClausesCleanupModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.clausesCleanupModal.title',
            size: 'sm',
            okBtnName: 'sp2.modal.close',
            cancelBtnName: false
          }
        };

        this.modalData = {
          content: 'sp2.clausesCleanupModal.content'
        };

        this.labels = {
          title: {
            '_productClausesRemoved': 'sp2.clausesCleanupModal.title',
            '_lastYearProductClausesRemoved': 'sp2.clausesCleanupModal.lastYear.title',
            '_calculateEstatesTogether': 'sp2.clausesCleanupModal.calculateEstatesTogether.title'
          },
          content: {
            '_productClausesRemoved': 'sp2.clausesCleanupModal.content',
            '_lastYearProductClausesRemoved': 'sp2.clausesCleanupModal.lastYear.content',
            '_calculateEstatesTogether': 'sp2.clausesCleanupModal.calculateEstatesTogether.content'
          }
        };

        /**
         * Wyświetlenie modala
         */
        this.showModal = function(type) {
          var labelType = type || '_productClausesRemoved';          
          self.modals.productsClausesCleanupModal.settings.title = self.labels.title[labelType];
          self.modalData.content = self.labels.content[labelType];
          sp2CommonHelper.showModal('productsClausesCleanupModal');
        };

        this.removeClauses = function(toState) {
          var matrixStates = ['matrix', 'fire', 'burglary', 'oczp', 'nnw', 'communication', 'vehicleMatrix'];
          var clausesSelected = false;
          var productClauses = dataContainerHelper.getProductClauses();
          var removeProductClauses = localStorage.getItem('removeProductClauses');
          
          if (productClauses && Object.keys(productClauses).length > 0) {
            angular.forEach(productClauses, function(policy) {
              if (policy.clauseCode && policy.clauseCode.length > 0) {
                clausesSelected = true;
              }
            });
          }
          if(appVariables.appMode === CONSTANTS.APP_MODE_CALCULATION
            && !appVariables.readOnly
            && !appVariables.isSupplement
            && sp2CommonHelper.isSalesCharacter()
            && (clausesSelected || removeProductClauses)
            && matrixStates.indexOf(toState) !== -1
          ) {
            dataContainerHelper.setProductClauses({});
            actionHelper.runAction('saveApplication');
            self.showModal(removeProductClauses);
            localStorage.removeItem('removeProductClauses');
          }
        };
      };

      return new ProductsClausesCleanupHelper();
    }
  ]);
