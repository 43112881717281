angular.module('ihestiaGridSettings')
.factory('IHestiaGridSettingsColumnSettingsModel',[ 'IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var ColumnSettingsModel = function()
    {
      this.objectName = 'IHestiaGridSettingsColumnSettingsModel';

      this.fieldsProperties = {
        visibility: {
          type: 'string'
        }
      };

      this.visibility = null; //lista widocznych kolumn np. 'Premium,Number,Date'
    };

    ColumnSettingsModel.prototype = IHestiaAbstractModel.prototype;

    return ColumnSettingsModel;
  }]);