angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseSummaryCtrl', ['$scope', '$state', 'VerificationDataModelConstants', 'policiesPrintoutsSvc', '$filter', 'labelServiceHelper',
    function($scope, $state, VerificationDataModelConstants, policiesPrintoutsSvc, $filter, labelServiceHelper) {

      $scope.showCoownerAddMessage = false;

      $scope.init = function(){
        $scope.prepareMessage();
        $scope.setUglyTranslations();
      };

      /**
       * pobranie wydruku polisy dla darowizny częściowej
       */
      $scope.downloadPolicy = function()
      {
        $scope.$emit('showBlockUi');
        policiesPrintoutsSvc.get($scope.otherData.newPolicyId, null, 'symbols/POLISA', function(result) {
          $scope.$emit('hideBlockUi');
          var filename = $filter('translate')('fileNameDefaults.policyPrint', {componentCode: 'Public', params: {policyNumber: $scope.verificationData.policyNumber}}) + '.pdf'; //jak nie dostaniemy nazwy z usługi
          var contentDisposition = result.headers('Content-Disposition');

          var fileNameString = 'filename=';
          if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
            var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
            if (preCut.indexOf(';') !== -1) {
              preCut.substr(0, preCut.indexOf(';'));
            }
            filename = decodeURIComponent(preCut);
          }

          var blob = new Blob([result.data], {
            type: 'application/pdf'
          });
          saveAs(blob, filename);
        }, function() {
          $scope.$emit('hideBlockUi');
        }, {
          headers: {
            'Accept': 'application/pdf'
          },
          responseType: 'blob'
        });
      };

      /**
       * przygotowujemy treść komunikatu
       */
      $scope.prepareMessage = function()
      {
        var operationType = $scope.verificationData.operationType;
        if(operationType === VerificationDataModelConstants.VEHICLE_DONATION && $scope.otherData.donationType === 'Partial')
        {
          $scope.showCoownerAddMessage = true;
        }
        //domyślnie mamy message z usługi


        /**
         * TYMCZASOWE TŁUMACZENIA START
         */
        $scope.setUglyTranslations = function() {
          if(labelServiceHelper.isServiceTranslationActive() &&  //tłumaczenia usług włączone
            ($scope.currentUser.character.type === 'Client' || $scope.currentUser.character.type !== 'Central') && //dla klientów i agentów
             $scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_SALE) //i tylko sprzedaż
          {
            $scope.uglyTranslate = true;
          }
        };
        /**
         * TYMCZASOWE TŁUMACZENIA END
         */
      };

      $scope.end = function(){
        $scope.goToParent();
      };

    }
  ]);