/**
 * helper klikniecia na komunikat
 */
angular.module('salesPath2').service('messagesClickHelper', ['$rootScope', '$state', 'CONSTANTS', 'mainDataContainer', 'appVariables', 'localizationModalHelper', 'personModalHelper', 'vehicleModalHelper', 'bonusMalusModalHelper',
  'discountsHelper', 'summaryPaymentHelper', 'leasingModalHelper', 'cessionModalHelper', 'sp2CommonHelper', 'dataContainerHelper', '$timeout',
  function($rootScope, $state, CONSTANTS, mainDataContainer, appVariables, localizationModalHelper, personModalHelper, vehicleModalHelper, bonusMalusModalHelper,
           discountsHelper, summaryPaymentHelper, leasingModalHelper, cessionModalHelper, sp2CommonHelper, dataContainerHelper, $timeout) {
    var Helper = function() {
      var self = this;

      this.handleError = function(params) {
        var method = 'handle' + params.objectType;
        if (angular.isFunction(self[method])) {
          return self[method](params);
        }
        return false;
      };

      this.handleVehicles = function(params) {
        vehicleModalHelper.editVehicle(params.vehicleId);
        return true;
      };

      this.handlePersons = function(params) {
        personModalHelper.openPopupEditPerson({id: params.personId});
        return true;
      };

      this.handleLocalization = function(params) {
        localizationModalHelper.showEditLocalizationModal(params.localizationId);
        return true;
      };

      this.handleBonusMalus = function(params) {
        bonusMalusModalHelper.showPopup(params.personId);
        return true;
      };

      /**
       * go to communication state and open insured modal
       * @param {Object} params
       * @return {boolean}
       */
      this.handleCommunicationInsured = function(params) {
        var vehicleClientId = null;
        if(_.has(params, 'policyNumber')){
          var policy = dataContainerHelper.getPolicyByNumber(params.policyNumber),
            vehicle = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, _.first(policy.vehicles).metaData.id, 'id');
          vehicleClientId = vehicle.metaData.clientId;
        } else if (_.has(params, 'vehicleId')){
          vehicleClientId = params.vehicleId;
        } else {
          vehicleClientId = params.objectId;
        }

        dataContainerHelper.selectVehicle(vehicleClientId);
        $state.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
        $timeout(function() {
          sp2CommonHelper.showModal('communicationPersonsModal');
        }, 0);
        return true;
      };

      this.handlePolicy = function() {
        $state.go(CONSTANTS.PAGE_SUMMARY);
        return true;
      };

      /**
       * obsługa nieznanego/niewyłapanego po "object" błędu. Dzięki temu unikamy exceptiona o braku obsłużenia.
       * Po kliku, nie robimy niczego :)
       * @return {Boolean} true
       */
      this.handleUnknown = function() {
        return true;
      };

      /**
       * błędy związane ze znizkami i odstępstwami
       * @param  {Object} params
       */
      this.handleDiscount = function(params) {
        if (angular.isDefined(params.decisionType)) {
          if (angular.isString(params.decisionType) && params.decisionType.toLowerCase() === 'exceed') {
            return self.handleAuthorizationExceeded(params);
          } else {
            return self.handleDiscountDeviatedPremium(params);
          }
        } else {
          return self._handleDiscount(params, null);
        }
      };

      this.handleDiscountDeviatedPremium = function(params) {
        return self._handleDiscount(params, 'deviatedPremium');
      };

      this.handleAuthorizationExceeded = function(params) {
        return self._handleDiscount(params, 'authorizationExceeded');
      };

      this.handlePromoCodes = function(params) {
        return self._handleDiscount(params, 'promoCodes');
      };

      /**
       * otwarcie modala znizek/odstepstw
       * @param  {Object} params [description]
       * @param  {String} tab nazwa zakładki do aktywacji
       */
      this._handleDiscount = function(params, tab) {
        if ($state.$current.name === CONSTANTS.PAGE_SUMMARY) {
          discountsHelper.showEditDiscountsModal(params.policyNumber, (angular.isString(tab) ? tab : null));
        } else {
          appVariables.openPolicyModal = {
            type: CONSTANTS.POLICY_EXTRA_DISCOUNT,
            policyId: params.policyNumber,
            tab: (angular.isString(tab) ? tab : null)
          };
          $state.go(CONSTANTS.PAGE_SUMMARY);
        }
        return true;
      };

      this.handlePolicy = function(params) {
        var policy = dataContainerHelper.getPolicyByNumber(params.policyNumber);
        if (sp2CommonHelper.getPolicyType(policy) === CONSTANTS.POLICY_TYPE_COMMUNICATION) {
          $state.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
        } else {
          $state.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OGI]);
        }

        return true;
      };

      //obsluga platnosci
      this.handlePayment = function(params) {
        if ($state.$current.name === CONSTANTS.PAGE_SUMMARY) {
          var policy = dataContainerHelper.getPolicyByNumber(params.policyNumber);
          summaryPaymentHelper.showPaymentModal(policy);
        } else {
          appVariables.openPolicyModal = {type: CONSTANTS.POLICY_EXTRA_PAYMENT, policyId: params.policyNumber};
          $state.go(CONSTANTS.PAGE_SUMMARY);
        }
        return true;
      };

      this.handleCession = function(policyId) {
        cessionModalHelper.showEditCessionModal(policyId); //typ polisy do czego byl uzywany
      };

      this.handleLeasing = function(objectType, objectId, organizationId) {
        leasingModalHelper.editExplicitLeaser(organizationId, objectType, objectId);
      };

      this.handleInsurer = function() {
        personModalHelper.openPopupEditPerson({id: mainDataContainer.insurerId});
      };
    };

    return new Helper();
  }]);
