angular.module('lsnBase.availability')
  .service('availabilityHelper', ['LsnAvailabilityModel',
    function(LsnAvailabilityModel) {
    var AvailabilityHelper = function() {
      var self = this;

      this.REST_SERVICES_AVAILABILITY = [];

      this.init = function() {
        self.loadServicesAvailabilities();
      };

      /**
       * wczytuje informacje o dostepnosci uslug z podanego zasobu
       * @param {Object[]} resource tablica obiektów w strukturze:
       * {
       *   serviceClassName: 'nazwa_klasy_serwisu' //nazwa klasy/serwisu dla usługi
       *   method: 'metoda' //post|get|put...
       *   subResource: 'podZasób' //opcja, nazwa podzasobu (wymieniana po "/" w urlu do usługi)
       *   available: false //bool, czy dostępny czy nie
       * }
       * np. dostępność uslugi wyszukujacej adresy
       * {
       *  'serviceClassName': 'IHSG.RESTService.DictionaryAddresses',
       *  'method': 'post',
       *  'subResource': 'searches',
       *  'available': false
       * }
       */
      this.loadServicesAvailabilities = function(resource) {
        self.servicesAvailabilities = [];
        if (!angular.isArray(resource)) {
          return;
        }
        angular.forEach(resource, function(srvAvData) {
          var serviceAvailability = new LsnAvailabilityModel(srvAvData);
          self.servicesAvailabilities.push(serviceAvailability);
        });
      };

      /**
       * zwraca informację o dostępności usługi REST
       * @param {string} serviceClassName nazwa klasy usługi
       * @param {string} method nazwa metody (post, get, etc.)
       * @param {string=} subResource nazwa podzasobu
       * @return {boolean} true jesli dostepna, false jesli nie
       */
      this.isServiceAvailable = function(serviceClassName, method, subResource) {
        var available = true;
        lsnNg.forEach(self.servicesAvailabilities, function(serviceAvailability) {
          if (serviceAvailability.serviceClassName === serviceClassName &&
            (serviceAvailability.method !== null ? serviceAvailability.method === method : true) &&
            (serviceAvailability.subResource !== null ? serviceAvailability.subResource === subResource : true)
          ) {
            available = serviceAvailability.available;
            return false;
          }
        });
        return available;
      };

      /**
       * mapowanie prostych nazw usług do parametrów określających niedostępną usługę
       * Na użytek metody isAvailable('np. addressSearch')
       * Poniżej predefiniowana lista prostych nazw dla niektórych serwisów. Do ewentualnego udekorowania po stronie aplikacji używającej.
       * @type Object tablica parametrów - 1 element to nazwa metody z availabilityHelper, kolejne do argumenty przekazywane do tej metody
       */
      this.servicesSimpleNames = {
        'addressSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryAddresses', 'post', 'searches'],
        'personSearch': ['isServiceAvailable', 'IHSG.RESTService.ClientsPersons', 'post', 'searches'],
        'firstNameSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryFirstNames', 'post', 'searches'],
        'lastNameSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryLastNames', 'post', 'searches'],
        'greenCards': ['isServiceAvailable', 'IHSG.RESTService.GreenCards', 'get'],
        'banks': ['isServiceAvailable', 'IHSG.RESTService.DictionaryBanks', 'post', 'searches'],
        'nonEtxMark': ['isServiceAvailable', 'IHSG.RESTService.DictionaryMarks', 'post', 'searches'],
        'nonEtxModel': ['isServiceAvailable', 'IHSG.RESTService.DictionaryModels', 'post', 'searches'],
        'nonEtxType': ['isServiceAvailable', 'IHSG.RESTService.DictionaryTypes', 'post', 'searches']
      };

      /**
       * czy dany serwis zdefiniowany w swlf.servicesSimpleNames jest dostępny
       */
      this.isAvailable = function(name) {
        if (angular.isUndefined(self.servicesSimpleNames[name])) {
          return true;
        }
        return self[self.servicesSimpleNames[name][0]].apply(this, self.servicesSimpleNames[name].slice(1));
      };

    };

    return new AvailabilityHelper();
  }]);