angular.module('ihestiaWidgets.policyTermination', ['ihestiaWidgets.policyOperations', 'ihestiaCommonDirectives.platformHelper'])
  .config(['$stateProvider',
    function config($stateProvider) {
      var terminationStep1OptionalParams = 'applicationId&operationDate&ehNumber&ownerChangeType&terminationCause';

      //resolvy wpięte w kilka stanów
      var commonResolves = {
        resolvedApplication: ['policyTerminationHelper', '$stateParams', function(policyTerminationHelper, $stateParams) {
          if ($stateParams.applicationId) {
            return policyTerminationHelper.getApplicationWithSettlement($stateParams.applicationId, true);
          } else {
            return null;
          }
        }],
        resolvedPolicy: ['policiesV2Svc', '$stateParams', function(policiesV2Svc, $stateParams) {
          if ($stateParams.policyNumber) {
            return policiesV2Svc.get($stateParams.policyNumber);
          } else {
            return null;
          }
        }],
        resolvedBlockings: ['ihestiaSsoBaseInfoHelper', 'ihestiaDictionaryHelper',
          function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper) {
            var currentUser = ihestiaSsoBaseInfoHelper.getUser();
            if (currentUser.character.type !== 'Central') {
              return false;
            } else {
              return ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {
                businessArea: 'dictionaries',
                resource: 'policyOperationSuspensions'
              });
            }
          }],
        resolvedShortages: ['ihestiaSsoBaseInfoHelper', 'ihestiaDictionaryHelper',
          function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper) {
            var currentUser = ihestiaSsoBaseInfoHelper.getUser();
            if (currentUser.character.type !== 'Central') {
              return false;
            } else {
              return ihestiaDictionaryHelper.getDict('policyOperationShortages', {
                businessArea: 'dictionaries',
                resource: 'policyOperationShortages'
              });
            }
          }]
      };

      var availableTerminationCausesParser = function(res) {
        if (res && res.code === 404) {
          res.status = true;
          return res;
        } else {
          return res;
        }
      };


      //definiujemy stany
      $stateProvider
        .state('main.estateSale', {
          url: 'policyTermination/estateSale?policyNumber&' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.ESTATE;
                  }],
                resolvedTerminationCauses: [function() {
                  return null;
                }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }],
                resolvedFileAttributes: [function() {
                  return null;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['propertySale', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationVehicleSale', {
          url: 'policyTermination/vehicleSale?policyNumber&' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.NOTICE;
                  }],
                resolvedTerminationCauses: [function() {
                  return null;
                }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false,
                    vehicleSale: true,
                    ownerChangeType: 'VehicleSale'
                  };
                }],
                resolvedFileAttributes: [function() {
                  return null;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationVehicleDonation', {
          url: 'policyTermination/vehicleDonation?policyNumber&' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.NOTICE;
                  }],
                resolvedTerminationCauses: [function() {
                  return null;
                }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false,
                    vehicleSale: true,
                    ownerChangeType: 'VehicleDonation'
                  };
                }],
                resolvedFileAttributes: [function() {
                  return null;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleDonation', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationDeregistrationAndDismantling', {
          url: 'policyTermination/{policyNumber:string}/deregistrationAndDismantling?' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.DAD;
                  }],
                resolvedTerminationCauses: ['policiesSvc', '$stateParams', 'PolicyTerminationConstants', 'ihestiaDictionaryHelper',
                  function(policiesSvc, $stateParams, PolicyTerminationConstants, ihestiaDictionaryHelper) {
                    if ($stateParams.applicationId) {
                      //pobieramy prosty słownik, bo mamy już wybrany typ na wniosku
                      return ihestiaDictionaryHelper.getDict('terminationCauses' + PolicyTerminationConstants.TYPES.DAD, {
                        businessArea: 'dictionaries',
                        resource: PolicyTerminationConstants.TYPES.DAD
                      });
                    } else {
                      return policiesSvc.get($stateParams.policyNumber, null, 'availableTerminationCauses/' + PolicyTerminationConstants.TYPES.DAD, null, null, {allowedStatuses: [404]}).then(availableTerminationCausesParser, availableTerminationCausesParser);
                    }
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }],
                resolvedFileAttributes: [function() {
                  return null;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.dismantlingOrDeregistration', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationStep2', {
          url: 'policyTermination/step2/{applicationId:string}',
          abstract: true,
          views: {
            'content': {
              controller: 'policyTerminationStep2AbstractCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2Abstract.tpl.html',
              resolve: {
                resolvedBlockings: commonResolves.resolvedBlockings,
                resolvedApplication: ['policyTerminationHelper', '$stateParams', function(policyTerminationHelper, $stateParams) {
                  // todo verify empty settlement for Corpo
                  return policyTerminationHelper.getApplicationWithSettlement($stateParams.applicationId);
                }],
                resolvedShortages: commonResolves.resolvedShortages,
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }]
              }
            }
          },
          data: {
            pageTitle: ''
          }
        })
        .state('main.policyTerminationStep2.deregistration', {
          url: '/deregistration',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleDismantlingOrDeregistrationCertificate');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.vehicleDeregistration', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.dismantling', {
          url: '/dismantling',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleDismantlingOrDeregistrationCertificate');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.vehicleDismantling', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationNotice', {
          url: 'policyTermination/{policyNumber:string}/notice?' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.NOTICE;
                  }],
                resolvedTerminationCauses: ['policiesSvc', '$stateParams', 'PolicyTerminationConstants', 'ihestiaDictionaryHelper', 'ihestiaPlatformHelper',
                  function(policiesSvc, $stateParams, PolicyTerminationConstants, ihestiaDictionaryHelper, ihestiaPlatformHelper) {
                    if (ihestiaPlatformHelper.isLifeContext()) {
                      return null;
                    }
                    if ($stateParams.applicationId) {
                      //pobieramy prosty słownik, bo mamy już wybrany typ na wniosku
                      return ihestiaDictionaryHelper.getDict('terminationCauses' + PolicyTerminationConstants.TYPES.NOTICE, {
                        businessArea: 'dictionaries',
                        resource: PolicyTerminationConstants.TYPES.NOTICE
                      });
                    } else {
                      return policiesSvc.get($stateParams.policyNumber, null, 'availableTerminationCauses/' + PolicyTerminationConstants.TYPES.NOTICE, null, null, {allowedStatuses: [404]}).then(availableTerminationCausesParser, availableTerminationCausesParser);
                    }
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }],
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc', 'ihestiaPlatformHelper', function(ihestiaRestDictionaryElectronicDocumentTypesSvc, ihestiaPlatformHelper) {
                  if (ihestiaPlatformHelper.isLifeContext()) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'CustomerApplication');
                  } else {
                    return null;
                  }
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.notice', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          },
          params: {
            successState: null,
            cancelState: null
          }
        })
        .state('main.policyTerminationStep2.endOfInsurancePeriodNotice', {
          url: '/endOfInsurancePeriodNotice',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'EndOfInsurancePeriodNotice');
                  }],
                //
                resolvedEndOfInsurancePeriodNoticeCauses: ['ihestiaDictionaryHelper', 'ihestiaSsoBaseInfoHelper', 'policyTerminationHelper', 'ihestiaPlatformHelper',
                  function(ihestiaDictionaryHelper, ihestiaSsoBaseInfoHelper, policyTerminationHelper, ihestiaPlatformHelper) {
                    var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                    // tylko kliencie w ihestii nie określają przyczyny
                    if (currentUser.character.type === 'Client' && !ihestiaPlatformHelper.isJupiterOrDirect()) {
                      return false;
                    } else {
                      return ihestiaDictionaryHelper.getDict('endOfInsurancePeriodNoticeCauses',
                        {
                          businessArea: 'dictionaries',
                          resource: 'endOfInsurancePeriodNoticeCauses',
                          callback: function(dictionary) {
                            policyTerminationHelper.endOfInsurancePeriodNoticeCauses = dictionary;
                          }
                        });
                    }
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.endOfInsurancePeriodNotice', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.vehicleBuyerNotice', {
          url: '/vehicleBuyerNotice',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.buyerNotice', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.prolongationPolicyNotice', {
          url: '/prolongationPolicyNotice',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.prolongationPolicyNotice', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationWithdrawal', {
          url: 'policyTermination/{policyNumber:string}/withdrawal?' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.WITHDRAWAL;
                  }],
                resolvedTerminationCauses: ['policiesSvc', '$stateParams', 'PolicyTerminationConstants', 'ihestiaDictionaryHelper', 'ihestiaPlatformHelper',
                  function(policiesSvc, $stateParams, PolicyTerminationConstants, ihestiaDictionaryHelper, ihestiaPlatformHelper) {
                    if (ihestiaPlatformHelper.isLifeContext()) {
                      return null;
                    } else if ($stateParams.applicationId) {
                      //pobieramy prosty słownik, bo mamy już wybrany typ na wniosku
                      return ihestiaDictionaryHelper.getDict('terminationCauses' + PolicyTerminationConstants.TYPES.WITHDRAWAL, {
                        businessArea: 'dictionaries',
                        resource: PolicyTerminationConstants.TYPES.WITHDRAWAL
                      });
                    } else {
                      return policiesSvc.get($stateParams.policyNumber, null, 'availableTerminationCauses/' + PolicyTerminationConstants.TYPES.WITHDRAWAL, null, null, {allowedStatuses: [404]}).then(availableTerminationCausesParser, availableTerminationCausesParser);
                    }
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }],
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc', 'ihestiaPlatformHelper', function(ihestiaRestDictionaryElectronicDocumentTypesSvc, ihestiaPlatformHelper) {
                  if (ihestiaPlatformHelper.isLifeContext()) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'CustomerApplication');
                  } else {
                    return null;
                  }
                }]
              }
            }
          },
          params: {
            successState: null,
            cancelState: null
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.withdrawal', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTermination', { // this state is only for EHL
          url: 'policyTermination/{policyNumber:string}/termination?' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedTerminationCauses: [function() {
                  return null;
                }],
                resolvedApplication: [function() {
                  return null;
                }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }],
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.TERMINATION;
                  }],
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc', function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'CustomerApplication');
                }]
              }
            }
          },
          params: {
            availableTerminationTypes: null,
            successState: null,
            cancelState: null
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTerminationEhl.terminationPageTitle', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyExtensionTerminationWithdrawal', {
          url: 'policyExtensionTermination/{policyNumber:string}/withdrawal?' + terminationStep1OptionalParams,
          views: {
            'content': {
              controller: 'policyTerminationStep1Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationStep1.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedApplication: commonResolves.resolvedApplication,
                terminationType: ['PolicyTerminationConstants',
                  function(PolicyTerminationConstants) {
                    return PolicyTerminationConstants.TYPES.WITHDRAWAL;
                  }],
                resolvedTerminationCauses: ['policiesSvc', '$stateParams',
                  function(policiesSvc, $stateParams) {
                    if ($stateParams.applicationId) {
                      return null;
                    } else {
                      return policiesSvc.get($stateParams.policyNumber, null, 'availableTerminationCauses/extensionNoticeTypes', null, null, {allowedStatuses: [404]}).then(availableTerminationCausesParser, availableTerminationCausesParser);
                    }
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: true
                  };
                }],
                resolvedFileAttributes: [function() {
                  return null;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyExtensionTermination.withdrawal', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationStep2.withdrawalAccordingToGtc', {
          url: '/withdrawalAccordingToGtc',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'AgreementWithdrawal');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.withdrawalAccordingToGtc', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyExtensionTerminationStep2', {
          url: 'policyExtensionTermination/step2/{applicationId:string}',
          abstract: true,
          views: {
            'content': {
              controller: 'policyTerminationStep2AbstractCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2Abstract.tpl.html',
              resolve: {
                resolvedBlockings: commonResolves.resolvedBlockings,
                resolvedApplication: ['policyTerminationHelper', function(policyTerminationHelper) {
                  return policyTerminationHelper.storedApplicationData;
                }],
                resolvedShortages: commonResolves.resolvedShortages,
                resolvedParams: [function() {
                  return {
                    policyExtension: true
                  };
                }]
              }
            }
          },
          data: {
            pageTitle: ''
          }
        })
        .state('main.policyExtensionTerminationStep2.withdrawal', {
          url: '/withdrawal',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'AgreementWithdrawal');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyExtensionTermination.distanceAgreementWithdrawal', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.distanceAgreementWithdrawal', {
          url: '/distanceAgreementWithdrawal',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'AgreementWithdrawal');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.distanceAgreementWithdrawal', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.extensionDistanceAgreementWithdrawal', {
          url: '/extension/distanceAgreementWithdrawal',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'AgreementWithdrawal');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyTermination.distanceAgreementWithdrawal', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.estateSale', {
          url: '/estateSale?policyNumber',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'RealEstateSaleAgreement');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['propertySale', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.vehicleSale', {
          url: '/vehicleSale?policyNumber',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyTerminationStep2.vehicleDonation', {
          url: '/vehicleDonation?policyNumber',
          views: {
            'content': {
              controller: 'policyTerminationStep2Ctrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/step2/policyTerminationStep2.tpl.html',
              resolve: {
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
                  }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['vehicleDonation', {componentCode: 'PolicyOperations'}]
          }
        })
        .state('main.policyCancellation', {
          url: 'policyTermination/{policyNumber:string}/policyCancellation',
          views: {
            'content': {
              controller: 'policyCancellationCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/cancellation/policyCancellation.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedCancellationCauses: ['policiesSvc', '$stateParams', 'ihestiaPlatformHelper',
                  function(policiesSvc, $stateParams, ihestiaPlatformHelper) {
                    if (ihestiaPlatformHelper.isLifeContext()) {
                      return null;
                    } else {
                      return policiesSvc.get($stateParams.policyNumber, null, 'availableCancellationCauses');
                    }
                  }],
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc', 'ihestiaPlatformHelper',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc, ihestiaPlatformHelper) {
                    if (ihestiaPlatformHelper.isLifeContext()) {
                      return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'CustomerApplication');
                    } else {
                      return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyCancellation');
                    }
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: false
                  };
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyCancellation.breadCrumbTitle', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          },
          params: {
            successState: null,
            cancelState: null
          }
        })
        .state('main.policyExtensionCancellation', {
          url: 'policyExtensionTermination/{policyNumber:string}/policyCancellation',
          views: {
            'content': {
              controller: 'policyCancellationCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/cancellation/policyCancellation.tpl.html',
              resolve: {
                resolvedPolicy: commonResolves.resolvedPolicy,
                resolvedCancellationCauses: ['policiesSvc', '$stateParams',
                  function(policiesSvc, $stateParams) {
                    return policiesSvc.get($stateParams.policyNumber, null, 'availableTerminationCauses/extensionCancellationTypes');
                  }],
                resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                  function(ihestiaRestDictionaryElectronicDocumentTypesSvc) {
                    return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyCancellation');
                  }],
                resolvedParams: [function() {
                  return {
                    policyExtension: true
                  };
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['policyExtensionCancellation.breadCrumbTitle', {componentCode: 'PolicyOperations'}],
            disableHideBlockUi: true
          }
        })
        .state('main.policyTerminationSummary', {
          url: 'policyTerminationSummary',
          views: {
            'content': {
              controller: 'policyTerminationSummaryCtrl',
              templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/summary/policyTerminationSummary.tpl.html'
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['summary', {componentCode: 'Public'}],
            disableHideBlockUi: true
          },
          params: {
            successState: null
          }
        })
      ;
    }
  ]);
