angular.module('iSkanerPolicies')
  .controller('iskanerPoliciesCtrl', ['$scope', 'iSkanerPairedDevicesSvc', '$q', 'iSkanerConstants', '$filter', 'iSkanerEvents', 'iSkanerTokenSvc', 'iSkanerPoliciesFilesSvc', 'ihestiaCommonErrorHandler', '$alert',
    function($scope, iSkanerPairedDevicesSvc, $q, iSkanerConstants, $filter, iSkanerEvents, iSkanerTokenSvc, iSkanerPoliciesFilesSvc, ihestiaCommonErrorHandler, $alert) {

      $scope.constants = iSkanerConstants;
      $scope.currentState = $scope.constants.STATE_INITIALIZATION;

      /**
       * Init
       */
      $scope.init = function() {
        iSkanerTokenSvc.fetch($scope.config) //If the user has a pairing token...
          .then(function(currentToken) {
            iSkanerTokenSvc.reset();
            $scope.currentToken = currentToken;
            $scope.openSseForUserWithToken();
          }, function() {
            $scope.currentState = $scope.constants.STATE_START;
          });

          $scope.config.revertCallbackAllFilesUploaded = function(allSuccess){
            //usuwamy pliki, ktorych nie udalo sie przeslac, bo sa nie poprawne
            if(!allSuccess){
              $scope.removeAllUploadedItems(); 
            }

            $scope.currentState = $scope.constants.STATE_DEVICE_PAIRED;
          };

          $scope.config.revertCallbackUploadedErrorOnAttributes = function(){
            $scope.currentState = $scope.constants.STATE_READY_TO_SEND_FRP;
            $scope.refreshUploadedFileList();
          };
      };

      $scope.openSseForUserWithToken = function() {
        iSkanerPairedDevicesSvc.fetch($scope.config)
          .then(function(pairedDevices) {
            $scope.showPairedDevices(pairedDevices);
         }, function() {
            $scope.currentState = $scope.constants.STATE_START;
          });
      };

      $scope.getQrCodeAndOpenSse = function() {
        var newDeviceConfig = JSON.parse(JSON.stringify($scope.config));
        newDeviceConfig.dataType = 'NEWDEVICE';

        $scope.getQrCode()
          .then(function() {
            iSkanerTokenSvc.fetch(newDeviceConfig).then(function(token) {
              $scope.currentToken = token;
              $scope.openSse('NEWDEVICE');
            }, lsnNg.noop);
          }, angular.noop);
      };

      /**
       * [openSse otwiera nasluch]
       * @return {[type]} [description]
       */
      $scope.openSse = function() {
        var isNewDeviceEvent = arguments[0];

        iSkanerEvents.open($scope.currentToken, $scope.config, $scope.handleEvents, isNewDeviceEvent)
          .then(function() {
            if (!isNewDeviceEvent) {
              //juz nie jest potrzebny kod qr
              $scope.iSkanerPoliciesQrCodeData = '';
              emitTokenReceivedEvent();
            }
          }, angular.noop);

        function emitTokenReceivedEvent() {
          $scope.$emit('iSkanerPoliciesTokenReceived', $scope.currentToken,
            $scope.config.componentId);
        }
      };

      $scope.getQrCode = function() {
        $scope.iSkanerPoliciesQrCodeData = '';

        return iSkanerPairedDevicesSvc
          .getQrCode($scope.config)
          .then(function(response) {
            $scope.iSkanerPoliciesQrCodeData = response.data;
            $scope.currentState = $scope.constants.STATE_SHOW_QR;
          }, function() {
            $scope.iSkanerPoliciesQrCodeData = '';
            ihestiaCommonErrorHandler.throwException({
              message: 'Błąd usługi do pobierania kodu qr'
            });
          });
      };

      $scope.handleEvents = function(typeOfEvent) {
        if (typeOfEvent === $scope.constants.FINISH_PROCESSING_EVENT) {
          $scope.currentState = $scope.constants.STATE_READY_TO_SEND_FRP;
          $scope.refreshUploadedFileList();
        } else if (typeOfEvent === $scope.constants.NEW_DEVICE_EVENT) {
          setPairedDevice();
          $alert({
            content: $filter('translate')('iskanerPolicies.pairDevicesSuccessfully', {componentCode: 'Public'}),
            type: 'info'
          });
        } else if (typeOfEvent === $scope.constants.NEW_FILE_EVENT) {
          $scope.refreshUploadedFileList();
        }

        function setPairedDevice() {
          iSkanerTokenSvc.reset($scope.config.componentId);

          //If user pairs up, check if their device is listed as paired...
          iSkanerPairedDevicesSvc.fetch($scope.config)
            .then(function(pairedDevices) {
              $scope.showPairedDevices(pairedDevices);
            }, function() {
              $scope.getQrCodeAndOpenSse();
              ihestiaCommonErrorHandler.throwException({
                message: 'Błąd usługi do pobierania sparowanego urzadzenie, sprobuj ponownie'
              });
            });
        }
      };

      /**
       * [prepareToReceiveData otwiera polaczenie na przesylanie plikow]
       * @return {[type]} [description]
       */
      $scope.prepareToReceiveData = function() {
        iSkanerTokenSvc.fetch($scope.config)
          .then(function(token) {
            $scope.currentToken = token;
            iSkanerEvents.close($scope.config.dataType)
              .then(angular.noop, angular.noop)
              .finally(function() {
                $scope.openSse();
                $scope.currentState = $scope.constants.STATE_PROCESSING;
              });
          }, angular.noop); //eslint-disable-line
      };

      $scope.reinitializeIskanerPolicies = function() {
        iSkanerEvents.close($scope.config.dataType)
          .finally(function() {
            $scope.resetIskaner();
          });
      };

      $scope.showPairedDevices = function(devices) {
        $scope.currentState = $scope.constants.STATE_DEVICE_PAIRED;
        $scope.iSkanerPoliciesPairedDevicesList = devices;
      };

      $scope.refreshUploadedFileList = function(callback) {
        iSkanerPoliciesFilesSvc.getFileList($scope.currentToken, $scope.config)
          .then(function(response) {
            //tutaj bedzie trzeba porownywac czy plik nowy, mamy informacja jaki byl przetwarzany
            $scope.iSkanerPoliciesUploadedItems = response.data;
            if(angular.isFunction(callback)){
              callback();
            }
          }, angular.noop); 
      };

      $scope.resetIskaner = function(resetUploadedItems) {
        $scope.currentState = $scope.constants.STATE_START;
        $scope.iSkanerPoliciesPairedDevicesList = [];
        $scope.iSkanerPoliciesQrCodeData = '';

        if (resetUploadedItems) {
          $scope.iSkanerPoliciesUploadedItems = [];
        }
      };

      $scope.iSkanerPoliciesUnpairDevice = function(deviceId) {
        iSkanerPairedDevicesSvc.unpair(deviceId, $scope.config)
          .then(function() {

            iSkanerPairedDevicesSvc.fetch($scope.config)
              .then(function(response) {
                $scope.iSkanerPoliciesPairedDevicesList = response;
              }, function() {

                iSkanerEvents.close($scope.config.dataType)
                  .then(function() {
                    $scope.currentToken = null;
                    iSkanerTokenSvc.reset($scope.config.componentId);
                  }, angular.noop)
                  .finally(function() {
                    $scope.resetIskaner(true);
                  });
              });
          }, angular.noop); //eslint-disable-line
      };

      $scope.turnOffISkanerPolicies = function() {
        iSkanerEvents.close($scope.config.dataType)
          .then(function() {
            iSkanerTokenSvc.reset($scope.config.componentId);
          }, angular.noop)
          .finally(function() {
            $scope.currentState = $scope.constants.STATE_DEVICE_PAIRED;
          });
      };

      $scope.startSendingFilesToFrp = function(){
        $scope.currentState = $scope.constants.STATE_SENDING_TO_FRP;
        var files = [];
        angular.forEach($scope.iSkanerPoliciesUploadedItems, function(file){
          var newFile = {};
          newFile.iSkanerTokenId = $scope.currentToken;
          newFile.name = file.filename;
          newFile.size = file.size;
          newFile.id = file.id;
          files.push(newFile);
        });
        $scope.config.callbackSendFiles(files);
        $scope.iSkanerPoliciesUploadedItems = [];
      };

      $scope.iSkanerPoliciesRemoveUploadedItem = function(id){
        iSkanerPoliciesFilesSvc.deleteFile(id, $scope.currentToken, $scope.config)
          .then(function() {
            $scope.refreshUploadedFileList(function(){
              //jesli usunelo ostatnie
              if(!$scope.iSkanerPoliciesUploadedItems.length){
                $scope.currentState = $scope.constants.STATE_DEVICE_PAIRED;
              }
            });
          }, angular.noop);
      };

      $scope.removeAllUploadedItems = function(){
        iSkanerPoliciesFilesSvc.getFileList($scope.currentToken, $scope.config)
          .then(function(response) {
            angular.forEach(response.data, function(file){
              $scope.iSkanerPoliciesRemoveUploadedItem(file.id);
            });
          }, angular.noop);
      };
      

      $scope.$on('$destroy', function() {
        iSkanerEvents.close($scope.config.dataType)
          .catch(function(error) {
            lsnNg.noop(error); // @todo do we need this catch?
          });
      });

      $scope.init();
    }
  ]);
