angular.module('lsnBase.models')
.factory('LsnEstateModelConstantsV2', [function() {
    var EstateModelConstants = {};

    // słownik stworzony przez nas
    EstateModelConstants.ESTATE_TYPE_FLAT = 'FLAT';
    EstateModelConstants.ESTATE_TYPE_HOUSE = 'HOUSE';
    EstateModelConstants.ESTATE_TYPE_GARAGE = 'GARAGE';
    EstateModelConstants.ESTATE_TYPE_OUTBUILDING = 'OUTBUILDING';
    EstateModelConstants.ESTATE_TYPE_HOUSE_UNDER_CONSTRUCTION = 'CONSTRUCTION';
    EstateModelConstants.ESTATE_TYPE_SUMMER_HOUSE = 'SUMMERHOUSE';
    // słownik typów lokalizacji
    EstateModelConstants.DICTIONARY_ESTATE_TYPE = {};
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_FLAT] = 'lokal';
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_HOUSE] = 'budynek';
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_HOUSE_UNDER_CONSTRUCTION] = 'dom w budowie';
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_SUMMER_HOUSE] = 'domek letniskowy';
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_GARAGE] = 'garaż';
    EstateModelConstants.DICTIONARY_ESTATE_TYPE[EstateModelConstants.ESTATE_TYPE_OUTBUILDING] = 'budynek gospodarczy';

    return EstateModelConstants;
  }]);