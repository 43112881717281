angular.module('ihestiaCommonDirectives.breadCrumbs', ['lsnBase.breadCrumbs', 'ihestiaCommonDirectives'])
.config(['breadCrumbsConfigProvider', 'ihestiaConfigHelperProvider',
    function(breadCrumbsConfigProvider, ihestiaConfigHelperProvider) {

      breadCrumbsConfigProvider.mainLinkVisible = true;

      breadCrumbsConfigProvider.mainState.data.pageTitleLabel = ['Public.iHestia', {componentCode: 'Public'}];
      breadCrumbsConfigProvider.mainState.url = ihestiaConfigHelperProvider.getConfigHelper().getUrl('PORTAL_URL');
      breadCrumbsConfigProvider.mainState.stateName = 'iHestia';

    }
  ]);
