angular.module('salesPath2')
  .controller('summaryPrintoutsModalCtrl', ['$scope', 'RESOURCES', 'summaryPrintoutsHelper', 'lsnUtils',
    function($scope, RESOURCES, summaryPrintoutsHelper, lsnUtils) {

      $scope.printouts = [];
      /**
       * alternative printout data
       * @type {Object}
       * {
       *   code: code
       *   description: description to show
       *   checked: is checked
       *   callback: callback function
       * }
       */
      $scope.alternativePrintout = null;
      $scope.policyNumber = '';
      $scope.printoutsDisabled = false;
      $scope.alternativePrintoutsDisabled = false;

      /**
      * init
      */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.printouts = summaryPrintoutsHelper.getPrintouts();
        $scope.alternativePrintout = summaryPrintoutsHelper.getAlternativePrintout();
        $scope.policyNumber = summaryPrintoutsHelper.getPolicyNumber();
        $scope.refreshOKButtonActivity();
      };

      /**
      * Odpalenie watchy
      */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.summaryPrintoutsModal', function($event, modal) {
          if (modal.validForm) {
            if ($scope.alternativePrintout && $scope.alternativePrintout.checked) {
              $scope.alternativePrintout.callback();
            } else {
              summaryPrintoutsHelper.printSelected($scope.printouts, $scope.policyNumber);
            }
          } else {
            $event.preventDefault();
          }
        });
      };

      $scope.alternativePrintoutToggled = function() {
        if ($scope.alternativePrintout.checked) {
          $scope.uncheckPrintouts();
          $scope.printoutsDisabled = true;
        } else {
          $scope.printoutsDisabled = false;
        }
        $scope.refreshOKButtonActivity();
      };

      $scope.printoutToggled = function() {
        var anyPrintoutChecked = lsnUtils.findObjInArray($scope.printouts, {checked: true}) !== null;
        if (anyPrintoutChecked) {
          $scope.alternativePrintoutsDisabled = true;
        } else {
          $scope.alternativePrintoutsDisabled = false;
        }
        $scope.refreshOKButtonActivity();
      };

      $scope.uncheckPrintouts = function() {
        if (angular.isArray($scope.printouts)) {
          angular.forEach($scope.printouts, function(item) {
            item.checked = false;
          });
        }
      };

      $scope.isAnyPrintoutSelected = function() {
        return (
          (angular.isArray($scope.printouts) && lsnUtils.findObjInArray($scope.printouts, {checked: true}) !== null) ||
          ($scope.alternativePrintout !== null && $scope.alternativePrintout.checked)
          );
      };

      /**
       * changes visibility of "ok" button
       * @return {undefined}
       */
      $scope.refreshOKButtonActivity = function() {
        summaryPrintoutsHelper.modals.summaryPrintoutsModal.settings.okBtnDisabled = !$scope.isAnyPrintoutSelected();
      };

      $scope.init();

    }
  ]);