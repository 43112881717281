angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardPoolTasksSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PoolTasksSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks/pools';
        this.businessArea = 'dashboard';
        this.port = '8282';

        this.grid.sortBy = 'dueDate';

      };
      return new PoolTasksSvc();
    }
  ]);