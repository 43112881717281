/**
 * Role osób na polisie.
 */
angular.module('ihestiaCommonDirectives.application')
  .factory('ihestiaRolesOnPolicyConstants', [function() {
    var RolesOnPolicyConstants = {};

    RolesOnPolicyConstants.ROLE_ON_POLICY_INSURED = 'INSURED';
    RolesOnPolicyConstants.ROLE_ON_POLICY_VEHICLE_OWNER = 'VEHICLE_OWNER';
    RolesOnPolicyConstants.ROLE_ON_POLICY_VEHICLE_COOWNER = 'VEHICLE_COOWNER';
    RolesOnPolicyConstants.ROLE_ON_POLICY_BANK_OR_LESSOR = 'BANK_OR_LESSOR';
    RolesOnPolicyConstants.ROLE_ON_POLICY_COINSURED_OR_COOWNER = 'COINSURED_OR_COOWNER';
    RolesOnPolicyConstants.ROLE_ON_POLICY_COINSURED = 'COINSURED';
    RolesOnPolicyConstants.ROLE_ON_POLICY_BANK = 'BANK';
    RolesOnPolicyConstants.ROLE_ON_POLICY_LESSOR = 'LESSOR';
    RolesOnPolicyConstants.ROLE_ON_POLICY_ADDITIONAL_INSURED = 'ADDITIONAL_INSURED';

    return RolesOnPolicyConstants;
  }]);