angular.module('lsnBase.models')
.factory('LsnIdentityDocumentModelV1', ['LsnAddressModelV1', 'IdentityDocumentModelConstants',
  function(LsnAddressModelV1, IdentityDocumentModelConstants) {

    var LsnIdentityDocumentModelV1 = function()
    {
      this.objectName = 'IdentityDocument';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        code: {
          type: 'string',
          dictionary: IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE
        },
        number: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        },
        issueDate: {
          type: 'dateTime'
        },
        validDate: {
          type: 'date'
        }
      };

      this.code = null; // string,
      this.number = null; // string, Numer dokumentu
      this.issueDate = null; // dateTime, Data wydania dokumentu
      this.validDate = null; // date, Data ważności dokumentu

    };

    LsnIdentityDocumentModelV1.prototype = LsnAddressModelV1.prototype;

    return LsnIdentityDocumentModelV1;
  }]);
