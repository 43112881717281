angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesModelV2 = function() {
        this.objectName = 'ApplicationAllowedChanges';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          risks: {
            type: 'ApplicationAllowedChangesRisks',
            version: 'v2'
          },
          objects: {
            type: 'ApplicationAllowedChangesObjects'
          },
          subjects: {
            type: 'ApplicationAllowedChangesSubjects'
          },
          salesProduct: {
            type: 'ApplicationAllowedChangesObject'
          }
        };

        this.risks = null; // ryzyka
        this.objects = null; // obiekty ubezpieczane
        this.subjects = null; // podmioty ubezpieczane
        this.salesProduct = null; // pakiety - polisy
      };

      LsnApplicationAllowedChangesModelV2.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesModelV2;
    }
  ]);