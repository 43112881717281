/**
 * dokłada nbsp po krótkich wyrazach które nie powinny się znaleźć na końcu linii
 */
angular.module('lsnBase.filters')
  .filter('prettyText', [function(){
    return function(inputValue) {
      if(!angular.isString(inputValue) || inputValue === ''){
        return inputValue;
      }
      // TODO uzależnić od języka
      var shortWords = ['z', 'w', 'i', 'że', 'nie'];

      // czyli puszczamy wyrażenie typu / i | w /gi
      var regexp = new RegExp('\\s' + shortWords.join('\\s|\\s') + '\\s', 'gi');
      inputValue = inputValue.replace(regexp, function(matched){
        return matched.substr(matched, matched.length - 1) + '\u00A0';// &nbsp;
      });
      return inputValue;
    };
}]);