angular.module('ihestiaWidgets.policyOperations')
  .config(['$stateProvider',
    function config($stateProvider) {
        $stateProvider.state('main.vehiclePurchase', {
        url: 'policyOperations/vehiclePurchase?policyNo&sellDate&regNumber&forward&loadApplication&isGreenCardReturned&ehNumber&step&record',
        views: {
          'content': {
            controller: 'vehiclePurchaseCtrl',
            templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchase/vehiclePurchase.tpl.html',
            resolve: {
              operationType: ['VerificationDataModelConstants',
                function(VerificationDataModelConstants){
                  return VerificationDataModelConstants.VEHICLE_PURCHASE;
              }],
              resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
                return ihestiaRestDictionaryNationalitiesSvc.find('');
              }],
              resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
                return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
              }],
              resolvedBlockings: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {businessArea: 'dictionaries', resource: 'policyOperationSuspensions'});
                  }
              }],
              resolvedShortages: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationShortages', {businessArea: 'dictionaries', resource: 'policyOperationShortages'});
                  }
              }],
              resolvedNbkCountries: ['nbkDictionarySvc', function(nbkDictionarySvc){
                return nbkDictionarySvc.get('countries');
              }],
              resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleSaleAgreement');
              }],
              resolvedWithdrawalAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
              }]
            }
          }
        },
        data: {
          pageTitle: null,
          pageTitleLabel: ['vehiclePurchase', {componentCode: 'PolicyOperations'}]
        }
      }).state('main.vehicleDonation', {
        url: 'policyOperations/vehicleDonation?policyNo&sellDate&regNumber&forward&loadApplication&isGreenCardReturned&ehNumber&step&record',
        views: {
          'content': {
            controller: 'vehiclePurchaseCtrl',
            templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchase/vehiclePurchase.tpl.html',
            resolve: {
              operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_DONATION;
              }],
              resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
                return ihestiaRestDictionaryNationalitiesSvc.find('');
              }],
              resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
                return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
              }],
              resolvedBlockings: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {businessArea: 'dictionaries', resource: 'policyOperationSuspensions'});
                  }
              }],
              resolvedShortages: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationShortages', {businessArea: 'dictionaries', resource: 'policyOperationShortages'});
                  }
              }],
              resolvedNbkCountries: ['nbkDictionarySvc', function(nbkDictionarySvc){
                return nbkDictionarySvc.get('countries');
              }],
              resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleDonationAgreement');
              }],
              resolvedWithdrawalAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
              }]
            }
          }
        },
        data: {
          pageTitle: null,
          pageTitleLabel: ['vehicleDonation', {componentCode: 'PolicyOperations'}]
        }
      }).state('main.vehicleSale', {
        url: 'policyOperations/vehicleSale?policyNo&sellDate&regNumber&forward&loadApplication&isGreenCardReturned&ehNumber&step&record',
        views: {
          'content': {
            controller: 'vehiclePurchaseCtrl',
            templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchase/vehiclePurchase.tpl.html',
            resolve: {
              operationType: ['VerificationDataModelConstants',
              function(VerificationDataModelConstants){
                return VerificationDataModelConstants.VEHICLE_SALE;
              }],
              resolvedNationalities: ['ihestiaRestDictionaryNationalitiesSvc', function(ihestiaRestDictionaryNationalitiesSvc){
                return ihestiaRestDictionaryNationalitiesSvc.find('');
              }],
              resolvedIdentityDocumentTypes: ['ihestiaRestDictionaryIdentityDocumentTypesSvc', function(ihestiaRestDictionaryIdentityDocumentTypesSvc){
                return ihestiaRestDictionaryIdentityDocumentTypesSvc.get();
              }],
              resolvedBlockings: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {businessArea: 'dictionaries', resource: 'policyOperationSuspensions'});
                  }
              }],
              resolvedShortages: ['ihestiaSsoBaseInfoHelper' , 'ihestiaDictionaryHelper',
                function(ihestiaSsoBaseInfoHelper, ihestiaDictionaryHelper){
                  var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                  if(currentUser.character.type !== 'Central')
                  {
                    return false;
                  }
                  else
                  {
                    return ihestiaDictionaryHelper.getDict('policyOperationShortages', {businessArea: 'dictionaries', resource: 'policyOperationShortages'});
                  }
              }],
              resolvedNbkCountries: ['nbkDictionarySvc', function(nbkDictionarySvc){
                return nbkDictionarySvc.get('countries');
              }],
              resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'VehicleSaleAgreement');
              }],
              resolvedWithdrawalAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
              }]
            }
          }
        },
        data: {
          pageTitle: null,
          pageTitleLabel: ['vehicleSale', {componentCode: 'PolicyOperations'}]
        }
      }).state('main.vehicleOperationConfirmation', {
        url: 'policyOperations/{applicationId:string}/confirmation',
        views: {
          'content': {
            controller: 'vehiclePurchaseConfirmationCtrl',
            templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchase/confirmation/vehiclePurchaseConfirmation.tpl.html',
            resolve: {
              resolvedApplication: ['policyOperationsSvc', '$stateParams', function(policyOperationsSvc, $stateParams){
                return policyOperationsSvc.get($stateParams.applicationId);
              }]
            }
          }
        },
        data: {
          pageTitle: ''
        }
      }).state('main.vehicleOperationWithdrawal', {
        url: 'policyOperations/{applicationId:string}/withdrawal?salesApplicationId',
        views: {
          'content': {
            controller: 'vehiclePurchaseWithdrawalCtrl',
            templateUrl: 'ihestia-widgets-templates-app/policyOperations/vehiclePurchase/withdrawal/vehiclePurchaseWithdrawal.tpl.html',
            resolve: {
              resolvedApplication: ['policyOperationsSvc', '$stateParams', function(policyOperationsSvc, $stateParams){
                return policyOperationsSvc.get($stateParams.applicationId);
              }],
              resolvedFileAttributes: ['ihestiaRestDictionaryElectronicDocumentTypesSvc',
                function(ihestiaRestDictionaryElectronicDocumentTypesSvc){
                  return ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'PolicyTermination');
              }]
            }
          }
        },
        data: {
          pageTitle: null,
          pageTitleLabel: ['policyTermination', {componentCode: 'PolicyOperations'}]
          // disableHideBlockUi: true
        }
      })
      ;
    }
  ]);