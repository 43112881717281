angular.module('ihestiaCommonDirectives')
  .factory('ihestiaReportsOfferConstants', [function() {
    var OfferConstants = {};

    OfferConstants.OFFER_STATUS_CALCULATION = 'Calculation';
    OfferConstants.OFFER_STATUS_CALCULATION_ACCEPTED = 'CalculationAccepted';
    OfferConstants.OFFER_STATUS_CALCULATION_DELETED = 'CalculationDeleted';
    OfferConstants.OFFER_STATUS_CALCULATION_EXPIRED = 'CalculationExpired';
    OfferConstants.OFFER_STATUS_OFFER = 'Offer';
    OfferConstants.OFFER_STATUS_OFFER_ACCEPTED = 'OfferAccepted';
    OfferConstants.OFFER_STATUS_OFFER_CANCELLED = 'OfferCancelled';
    OfferConstants.OFFER_STATUS_OFFER_EXPIRED = 'OfferExpired';
    OfferConstants.OFFER_STATUS_OFFER_DELETED = 'OfferDeleted';
    OfferConstants.OFFER_STATUS_IOFFER = 'IOffer';
    OfferConstants.OFFER_STATUS_IOFFER_ACCEPTED = 'IOfferAccepted';
    OfferConstants.OFFER_STATUS_IOFFER_CANCELLED = 'IOfferCancelled';
    OfferConstants.OFFER_STATUS_IOFFER_EXPIRED = 'IOfferExpired';
    OfferConstants.OFFER_STATUS_IOFFER_REJECTED = 'IOfferRejected';
    OfferConstants.OFFER_STATUS_OFFER_REJECTED = 'OfferRejected';
    OfferConstants.OFFER_STATUS_OFFER_CENTRAL = 'CentralOffer';

    OfferConstants.OFFER_SUB_TYPE_CODE_RENEWAL_CENTRAL_STATUTORY = 'RenewalCentralStatutory';
    OfferConstants.OFFER_SUB_TYPE_CODE_RENEWAL_AGENCY = 'RenewalAgency';
    OfferConstants.OFFER_SUB_TYPE_CODE_ANNIVERSARY = 'Anniversary';

    OfferConstants.DICTIONARY_OFFER_STATUS = {};
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_CALCULATION] = 'kalkulacja';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_CALCULATION_ACCEPTED] = 'kalkulacja zaakceptowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_CALCULATION_DELETED] = 'kalkulacja usunięta';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_CALCULATION_EXPIRED] = 'kalkulacja przeterminowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER] = 'oferta';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_ACCEPTED] = 'oferta zaakceptowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_CANCELLED] = 'oferta anulowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_EXPIRED] = 'oferta przeterminowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_DELETED] = 'oferta usunięta';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_IOFFER] = 'ioferta';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_CENTRAL] = 'oferta centralna';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_IOFFER_ACCEPTED] = 'ioferta zaakceptowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_IOFFER_CANCELLED] = 'ioferta anulowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_IOFFER_EXPIRED] = 'ioferta przeterminowana';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_IOFFER_REJECTED] = 'ioferta odrzucona';
    OfferConstants.DICTIONARY_OFFER_STATUS[OfferConstants.OFFER_STATUS_OFFER_REJECTED] = 'oferta odrzucona';

    return OfferConstants;
  }]);
