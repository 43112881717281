angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('ihestiaRestRequestStorageHelper', [ function() {
    var RestRequestStorageHelper = function(){
      var self = this;

      this.requests = [];

      /**
       * pierwszym argumentem musi być prowider, reszta to kolejne argumenty wywowłania callAjaxWithParams
       */
      this.add = function(provider, callArguments) {
        self.requests.push({
          provider: provider,
          callArguments: callArguments
        });
      };

      this.callAllAgain = function() {
        angular.forEach(self.requests, function(request){
          self.call(request.provider, request.callArguments);
        });
        self.requests = [];
      };

      this.call = function(provider, callArguments)
      {
        provider.callAjaxWithParams.apply(this, callArguments);
      };
    };

    return new RestRequestStorageHelper();
  }]);
