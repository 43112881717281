/**
 * Dyrektywa wycina spacje z lewej i prawej strony
 * oraz redukuje zmultipliowane spacje w środku frazy do jednej
 * np. "   123   445   " => "123 445"
 *
 * w modelu zawsze będziemy mieć poprawną wersję (o ile zostanie wpisana z inputa, a nie "bokiem")
 * zmiana w widoku będzie widoczna po blurze
 */
angular.module('lsnBase.directives')
  .directive('cutMultiSpaces', ['$filter',
    function($filter) {
    return {
      restrict: 'A',
      require: '?ngModel',
      link: function($scope, $element, $attrs, ngModel) {
        if (ngModel) {

          var onBlur = function(){
            ngModel.$setViewValue(ngModel.$modelValue);
            ngModel.$render();
          };

          // nastąpił blur, musimy ładnie zmienić zawartośc inputa
          var angularElement = angular.element($element);
          angularElement.on('blur', onBlur);

          $scope.$on('$destroy', function(){
            angularElement.off('blur', onBlur);
          });

          // dbamy o poprawną wartość w modelu
          ngModel.$parsers.push(function(value) {
            return $filter('cutMultiSpaces')(value);
          });
        }
      }
    };
  }]);