angular.module('ihestiaWidgets.policyDetails')
  .filter('variantStarCount', function() {
    return function(variantString) {
      switch (variantString) {
        case 'I':
          return 1;
        case 'II':
          return 2;
        case 'III':
          return 3;
        default:
          return 0;
      }
    };
  });
