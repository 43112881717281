angular.module('ihestiaCommonDirectives' )
.filter('countForm', [ 'labelActionHelper', '$filter', function( labelActionHelper, $filter ) {

  var getTemplates = function() {
    var templates = {
      days: {
        0: $filter('translate')('days', {componentCode: 'Public'}), //dni
        1: $filter('translate')('day', {componentCode: 'Public'}),  //dzień
        2: $filter('translate')('days', {componentCode: 'Public'})  //dni
      },
      pln: {
        0: $filter('translate')('Public.goldenYCH', {componentCode: 'Public'}), //złotych
        1: $filter('translate')('Public.goldenY', {componentCode: 'Public'}),   //złoty
        2: $filter('translate')('Public.goldenE', {componentCode: 'Public'})    //złote
      },
      results: {
        0: $filter('translate')('Public.results', {componentCode: 'Public'}), //wyników
        1: $filter('translate')('Public.resultsK', {componentCode: 'Public'}),  //wynik
        2: $filter('translate')('Public.resultsKI', {componentCode: 'Public'})  //wyniki
      }
    };
    return templates;
  };

  /**
   * Zwraca nam  kod odmiany
   * @param  {int} count    liczba przed tekstem (np. 33 grosze)
   * @return {int}
   */
  var getFormNoForCount = function(count) {
    // jeden
    if (count === 1) {
      return 1;
    }

    // podzielne przez 10
    if (count % 10 === 0) {
      return 0;
    }

    // specjalne (11-19)
    var tmp = count % 100;
    if (tmp >= 11 && tmp <= 19) {
      return 0;
    }

    tmp = count % 10;
    if (tmp === 1 || tmp > 4) {
      return 0;
    } else {
      return 2;
    }
  };

  var CountForm = function(count, template, plain) {
    var templates = getTemplates( );
    var text = '';
    count = parseInt(count, 10);
    if (!plain) {
      text += count + ' ';
    }
    text += templates[template][getFormNoForCount(count)];

    return text;
  };

  var translateCountForm = function(count, template, plain) {
    return CountForm(count, template, plain);
  };

  translateCountForm.$stateful = true; // @todo obczaić, czy to nie powoduje problemów wydajnościowych

  return translateCountForm;

}]);