angular.module('lsnBase.models')
.factory('LsnDocumentAttributeModelV1', ['LsnAbstractModel', function(LsnAbstractModel) {

    var LsnDocumentAttributeModelV1 = function()
    {
      this.objectName = 'DocumentAttribute';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        code: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        type: {
          type: 'string'
        },
        required: {
          type: 'bool'
        },
        value: {
          type: 'mixed'
        }
      };

      this.code = null;//  string  Kod atrybutu
      this.name = null;//  string  Nazwa atrybutu
      this.type = null;//  string  Możliwe typy atrybutu:
                      // • string
                      // • date
      this.required = null;//  boolean Wymagalność uzupełnienia atrybutu przy przesyłaniu dokumentu
      this.value = null; //  mixed (usługowy object) wartość dowolnego typu
    };

    LsnDocumentAttributeModelV1.prototype = LsnAbstractModel.prototype;

    return LsnDocumentAttributeModelV1;
  }]);