angular.module('salesPath2')
  /**
   * helper dla usług dotyczących pojazdów
   */
  .service('vehicleServiceHelper', ['ihestiaRestDictionaryEurotaxSvc', 'ihestiaRestDictionaryEurotaxEquipmentsSvc', '$q',
    function(ihestiaRestDictionaryEurotaxSvc, ihestiaRestDictionaryEurotaxEquipmentsSvc, $q) {
      var VehicleServiceHelper = function() {
        var self = this;
        this.valuationCanceller = $q.defer(); //promise do anulowania requestów do oszacowania wartosci pojazdu

        /*
        * wyszukiwanie pojazdów
        */
        this.searchVehicles = function(id, adjustmentsParams, httpParams, callback) {
          return ihestiaRestDictionaryEurotaxSvc.post(id, adjustmentsParams, httpParams, callback);
        };

        /*
        * pobranie kolejnej strony wyszukiwania
        */
        this.getSearchVehiclesNextPage = function(id, adjustmentsParams, httpParams, callback) {
          return ihestiaRestDictionaryEurotaxSvc.get(id, adjustmentsParams, httpParams, callback);
        };

        /*
        * pobieranie pojazdu po etxId
        */
        this.loadVehicle = function(id, callback, errorCallback) {
          return ihestiaRestDictionaryEurotaxSvc.get(id, {}, '', callback, errorCallback);
        };

        /*
        * wycen pojazdu (get lub post w zależności od tego czy podano wyposażenie)
        */
        this.getEurotaxValuation = function(id, valuationData, equipment, specialPuropse, callback) {
          self.valuationCanceller.resolve('new valuation');
          self.valuationCanceller = $q.defer();
          if (equipment === null || (angular.isArray(equipment) && equipment.length === 0)) {
            return ihestiaRestDictionaryEurotaxSvc.get(id, valuationData, 'valuation', callback, null, {
              timeout: self.valuationCanceller.promise.then(angular.noop, angular.noop)
            });
          } else {
            var adjustmentsParams = {
              Id: id,
              RegistrationDate: valuationData.registrationDate,
              Run: valuationData.run,
              Equipment: equipment
            };
            return ihestiaRestDictionaryEurotaxSvc.post(id, adjustmentsParams, 'valuation', callback, null, {
              timeout: self.valuationCanceller.promise.then(angular.noop, angular.noop)
            });
          }
        };

        /*
        * zwrraca serwis do pobierania wyposażenia pojazdu
        */
        this.getEquipmentService = function() {
          return ihestiaRestDictionaryEurotaxEquipmentsSvc;
        };

        /*
        * zwraca wyposażenie pojazdu
        */
        this.getVehicleEquipment = function(id, param, httpParams, callback) {
          return ihestiaRestDictionaryEurotaxEquipmentsSvc.post(id, param, httpParams, callback);
        };

      };
      
      return new VehicleServiceHelper();
    }
  ]);