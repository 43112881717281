angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestVehicleDictionaryTarifficationMakesSvc', ['ihestiaRestAbstractVehicleDictionariesSvc',
    function(ihestiaRestAbstractVehicleDictionariesSvc) {

      var RestVehicleDictionaryTarifficationMakesSvc = function() {
        ihestiaRestAbstractVehicleDictionariesSvc.apply(this, arguments);

        this.id = 'TarifficationVehicle/makes';

      };
      return new RestVehicleDictionaryTarifficationMakesSvc();
    }
  ]);