angular.module('ihestiaIkomunikator')
  .service('iKomunikatorMessagesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IKomunikatorMessagesSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/messages';
        this.businessArea = 'ikomunikator';

        /**
         * [findReceivedMessages pobiera listę wątków]
         * @param  {[type]}   data         [description]
         */
        this.findReceivedMessages = function(data, callback, errorCallback){
          return self.get(null, data, 'findReceivedMessages', callback, errorCallback);
        };

        /**
         * [countMessageThreadWithUnreadedMessages usługa sprawdza liczbe nie przeczytanych watkow]
         * @param  {[type]}   data         [description]
         * @param  {Function} callback     [description]
         * @param  {[type]}   errorCalback [description]
         * @return {[type]}                [description]
         */
        this.countMessageThreadWithUnreadedMessages = function(data, callback, errorCallback, params){
          return self.get(null, data, 'countMessageThreadWithUnreadedMessages', callback, errorCallback, params);
        };

      };

      return new IKomunikatorMessagesSvc();
    }]);