angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryApplicationStatusSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryApplicationStatusSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'applicationStatus';
      };
      return new RestDictionaryApplicationStatusSvc();
    }
  ]);