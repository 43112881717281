angular.module('lsnBase.utils')
  .service('lsnUtils', ['lsnArrayUnique', '$timeout',
    function(lsnArrayUnique, $timeout) {
      var Utils = function() {
        var self = this;
        /**
         * zwraca tablicę z unikatowymi wartościami z przekazanej tablicy
         * @param {(string|number)[]} array tablica ze stringami lub liczbami
         * @returns {(string|number)[]} nowa tablica unikatowych wartości
         */
        this.arrayUnique = lsnArrayUnique;

        /**
         * przeszukuje tablicę i zwraca obiekt/obiekty lub indeksy pasujące do przekazanych params
         * @param  {Object[]} array      tablica do przeszukania
         * @param  {Object} params       parametry do porównania
         * @param  {Object} [givenOptions] opbje działania - opisane poniżej
         * @return {null|String|[]} 
         */
        this.findObjInArray = function(array, params, givenOptions) {
          var options = {
            returnValue: 'obj' // obj - zwróci 1-szy znaleziony obiekt, objs - zwróci tablicę wszytskich znalezionych obiektów, idx - zwróci indeks znalezionego obiektu, idxs - zwróci tablicę z indeksami znalezionych obiektów
          };
          if (angular.isObject(givenOptions)) { // stosujemy przekazane ustawienia
            angular.extend(options, givenOptions);
          }
          var found = null; // zwracana wartość
          lsnNg.forEach(array, function(obj, idx) {
            var match = self._objMatch(obj, params);
            if (match && (options.returnValue === 'obj' || options.returnValue === 'idx')) { // znaleziono obiekt i od razu go zwracamy
              if (options.returnValue === 'obj') {
                found = obj;
              } else {
                found = idx;
              }
              return false;
            } else if (match && (options.returnValue === 'objs' || options.returnValue === 'idxs')) { // znaleziono i dodajemy go do tablicy zwracanych
              found = found || [];
              if (options.returnValue === 'objs') {
                found.push(obj);
              } else {
                found.push(idx);
              }
            }
            return true;
          });
          return found;
        };

        /**
         * copy value to clipboard
         * @param  {string} value
         * @return {boolean} true if copied successfully
         */
        this.copyToClipboard = function(value) {
          var copyElement = document.createElement('textarea');
          copyElement.style.position = 'fixed';
          copyElement.style.opacity = '0';
          copyElement.textContent = value;
          var body = document.getElementsByTagName('body')[0];
          body.appendChild(copyElement);
          copyElement.select();
          var result = document.execCommand('copy');
          body.removeChild(copyElement);
          return result;
        };

        /**
         * czy obiekt posiada przekazane parametry
         * @param  {Object} obj obiekt, który sprawdzamy
         * @param  {Object} params parametry/warunki jakie musi sprłniać obiekt
         * @return {Boolean} true, gdy pasuje/posiada
         */
        this._objMatch = function(obj, params) {
          if (!angular.isObject(obj)) {
            return false;
          }
          var match = true;
          lsnNg.forEach(params, function(pVal, pKey) {
            if (angular.isObject(pVal)) { // zagniezdzony obiekt w parametrach
              match = match && self._objMatch(obj[pKey], pVal);
            } else {
              match = match && (pVal === obj[pKey]);
            }
            if (!match) { // nie pasuje, wiec przerywamy dalsze sprawdzanie
              return false;
            }
            return true;
          });
          return match;
        };

        /**
         * [parseToInt]
         * @return {[type]} [zawsze zwraca liczbe]
         */
        this.parseToInt = function(value) {
          return value ? parseInt(value, 10) : 0;
        };

        /**
         *
         * @param url
         * @param target
         */
        this.openWindow = function(url, target) {
          if(!target) {
            target = '_blank';
          }
          if(url) {
            $timeout(function(){
              // fix from https://stackoverflow.com/a/50580515/2993450
              var link = document.createElement('a');
              link.target = target;
              link.href = url;
              link.rel = 'noopener noreferrer';
              document.body.appendChild(link); // you need to add it to the DOM to get FF working
              link.click();
              link.parentNode.removeChild(link); // link.remove(); doesn't work on IE11
            }, 0);
          }
        };

      };
      return new Utils();
    }
  ]);
