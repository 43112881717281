angular.module('salesPath2')
  .controller('salesPath2Ctrl', ['$scope', 'personModalHelper', 'groupModalHelper', 'vehicleModalHelper', 'localizationModalHelper', 'leasingModalHelper', 'coownerModalHelper', 'ceidgModalHelper', 'basketSecondDetailsModalHelper',
    'attachmentHelper', 'summaryClausesHelper', 'summaryProductsClausesHelper', 'summaryPaymentHelper', 'summaryPrintoutsHelper', 'sp2SelectionPopups', 'cessionModalHelper', 'discountsHelper', 'bonusMalusModalHelper', 'tariffFactorsModalHelper', 'resourceTooltipModalHelper', 'actionHelper', 'communicationPersonsModalHelper', 'newBonusMalusModalHelper', 'messageModalsHelper', 'productsClausesAutomaticUpdateHelper', 'productsClausesCleanupHelper', 'mainDataContainer', 'productsClausesErrorsHelper', 'travelDestHelper',
    function($scope, personModalHelper, groupModalHelper, vehicleModalHelper, localizationModalHelper, leasingModalHelper, coownerModalHelper, ceidgModalHelper, basketSecondDetailsModalHelper, attachmentHelper, summaryClausesHelper, summaryProductsClausesHelper, summaryPaymentHelper, summaryPrintoutsHelper, selectionPopups, cessionModalHelper, discountsHelper, bonusMalusModalHelper, tariffFactorsModalHelper, resourceTooltipModalHelper, actionHelper, communicationPersonsModalHelper, newBonusMalusModalHelper, messageModalsHelper, productsClausesAutomaticUpdateHelper, productsClausesCleanupHelper, mainDataContainer, productsClausesErrorsHelper, travelDestHelper) { // eslint-disable-line angular/di
      //konfiguracje dla globalnie dostępnych modali
      $scope.modals.personAddModal = personModalHelper.modals.personAddModal;
      $scope.modals.personEditModal = personModalHelper.modals.personEditModal;
      $scope.modals.groupEditModal = groupModalHelper.modals.groupEditModal;
      $scope.modals.personSearchModal = personModalHelper.modals.personSearchModal;
      $scope.modals.localizationAddModal = localizationModalHelper.modals.localizationAddModal;
      $scope.modals.localizationEditModal = localizationModalHelper.modals.localizationEditModal;
      $scope.modals.vehicleEtxSearchModal = vehicleModalHelper.modals.vehicleEtxSearchModal;
      $scope.modals.vehicleEtxEditModal = vehicleModalHelper.modals.vehicleEtxEditModal;
      $scope.modals.vehicleNonEtxEditModal = vehicleModalHelper.modals.vehicleNonEtxEditModal;
      $scope.modals.vehicleEquipmentModal = vehicleModalHelper.modals.vehicleEquipmentModal;
      $scope.modals.vehicleSpecialPurposeModal = vehicleModalHelper.modals.vehicleSpecialPurposeModal;
      $scope.modals.vehicleBmModal = bonusMalusModalHelper.modals.vehicleBmModal;
      $scope.modals.newBonusMalusModal = newBonusMalusModalHelper.modalSettings;
      $scope.modals.verifyInfoModal = bonusMalusModalHelper.modals.verifyInfoModal;
      $scope.modals.vehicleCompareModal = vehicleModalHelper.modals.vehicleCompareModal;
      $scope.modals.vehicleZkModal = vehicleModalHelper.modals.vehicleZkModal;
      $scope.modals.chooseLeasingModal = leasingModalHelper.modals.chooseLeasingModal;
      $scope.modals.editLeasingModal = leasingModalHelper.modals.editLeasingModal;
      $scope.modals.leasingInfoModal = leasingModalHelper.modals.leasingInfoModal;
      $scope.modals.coownerListModal = coownerModalHelper.modals.coownerListModal;
      $scope.modals.communicationPersonsModal = communicationPersonsModalHelper.modals.communicationPersonsModal;
      $scope.modals.attachmentModal = attachmentHelper.modals.attachmentModal;
      $scope.modals.summaryClausesModal = summaryClausesHelper.modals.summaryClausesModal;
      $scope.modals.summaryProductsClausesModal = summaryProductsClausesHelper.modals.summaryProductsClausesModal;
      //$scope.modals.productsClausesAutomaticUpdateModal = productsClausesAutomaticUpdateHelper.modals.productsClausesAutomaticUpdateModal;
      $scope.modals.productsClausesCleanupModal = productsClausesCleanupHelper.modals.productsClausesCleanupModal;
      $scope.modals.productsClausesErrorsModal = productsClausesErrorsHelper.modals.productsClausesErrorsModal;
      $scope.modals.summaryPaymentModal = summaryPaymentHelper.modals.summaryPaymentModal;
      $scope.modals.summaryPrintoutsModal = summaryPrintoutsHelper.modals.summaryPrintoutsModal;
      $scope.modals.liquidationModal = selectionPopups.modals.liquidationModal;
      $scope.modals.cessionModal = cessionModalHelper.modals.cessionModal;
      $scope.modals.discountsModal = discountsHelper.modals.discountsModal;
      $scope.modals.discountsDetailsModal = discountsHelper.modals.discountsDetailsModal;
      $scope.modals.tariffFactorsModal = tariffFactorsModalHelper.modals.tariffFactorsModal;
      $scope.modals.ceidgClientFoundModal = ceidgModalHelper.modals.ceidgClientFoundModal;
      $scope.modals.ceidgSearchModal = ceidgModalHelper.modals.ceidgSearchModal;
      $scope.modals.resourceTooltipModal = resourceTooltipModalHelper.tooltipModal;
      $scope.modals.basketSecondDetailsModal = basketSecondDetailsModalHelper.modals.basketSecondDetailsModal;
      $scope.modals.propertySumInsuredModal = messageModalsHelper.modals.propertySumInsuredModal;
      $scope.modals.propertySumRenewalModal = messageModalsHelper.modals.propertySumRenewalModal;
      $scope.modals.travelDestModal = travelDestHelper.modals.travelDestModal;
      actionHelper.registerByConfig();

      $scope.$on('$stateChangeStart', function(event, toState) {
        productsClausesCleanupHelper.removeClauses(toState.name);
      });
    }
  ]);
