angular.module('ihestiaCommonDirectives.uploaderV2')
  .service('lsnCommonUploadV2FrpHelper', ['addMd5ToFile', 'filesSvc', 'productFilesSvc', '$q', '$filter', 'IHestiaRestServiceProviderAtenaDef', '$interval', 'iSkanerPoliciesFrpSvc', '$alert',
    function(addMd5ToFile, filesSvc, productFilesSvc, $q, $filter, IHestiaRestServiceProviderAtenaDef, $interval, iSkanerPoliciesFrpSvc, $alert) {

      var LsnCommonUploadV2FrpHelper = function() {

        var self = this;

        /**
         * Sends files to frp
         * @param files
         * @param attributes
         * @param [productFilesParams=null]
         * @returns {Promise<any | never>}
         */
        this.sendFiles = function(files, attributes, productFilesParams) {
          return $q(function(resolve, reject) {
            self._getPromisesToSend(files, attributes, productFilesParams).then(function(promises) {
              $q.all(promises).then(resolve, reject);
            }, reject);
          });
        };

        /**
         * Promise land....
         *
         * @param files
         * @param attributes
         * @param {Object} [productFilesParams=null]
         * @return {Promise<T | never>}
         * @private
         */
        this._getPromisesToSend = function(files, attributes, productFilesParams) {
          var promisesToSend = [];
          var waitPromises = [];
          angular.forEach(files, function(file) {
            var waitPromise = self._waitForState(file, 'pending', true).then(function() {
              if (!file.processed && !file.error) {
                file.processed = true;
                var promise = self._sendFile(file, attributes, productFilesParams);
                promise.then(lsnNg.noop, lsnNg.noop);
                promisesToSend.push(promise);
              }
            }, lsnNg.noop);
            waitPromises.push(waitPromise);
          });

          return $q(function(resolve, reject) {
            $q.all(waitPromises).then(function() {
              resolve(promisesToSend);
            }, reject);
          });
        };

        /**
         * Sends info about file and then its data to FRP
         *
         * @param file
         * @param attributes
         * @param [productFilesParams=null]
         * @returns {*}
         * @private
         */
        this._sendFile = function(file, attributes, productFilesParams) {
          return $q(function(resolve, reject) {
            if (file.uploaded) {
              resolve(file);
            } else {
              self._sendInfoAboutFile(file, attributes, productFilesParams).then(function(file) {
                self._waitForState(file, 'pending', true).then(function(file) {
                  if (file.$state() === 'resolved') {
                    file.$submit();
                    self.waitForUploadDone(file).then(resolve, reject);
                  } else {
                    reject(file);
                  }
                }, lsnNg.noop);
              }, function(data) {
                $alert({
                  content: data.message,
                  type: 'danger'
                });
                file.processed = false;
                reject(data);
              });
            }
          });
        };

        /**
         * For some weird reason we need to wait for something to happen
         * in external lib so we can upload it to frp
         * @param {Object} file
         * @param {String} state
         * @param {Boolean} invert if true we're waiting for state different than given
         * @return {*}
         */
        this._waitForState = function(file, state, invert) {
          return $q(function(resolve) {
            var interval = $interval(function() {
              if ((!invert && file.$state() === state) || (invert && file.$state() !== state)) {
                $interval.cancel(interval);
                resolve(file);
              }
            }, 100);
          });
        };

        /**
         * Ugly way for checking if upload of the file is done
         * by external library (ng-uploader)
         * @param file
         * @returns {*}
         */
        this.waitForUploadDone = function(file) {
          return $q(function(resolve) {
            var interval = $interval(function() {
              if (file.uploadDone) {
                $interval.cancel(interval);
                file.uploaded = true;
                file.processed = false;
                resolve(file);
              } else if (file.error) {
                $interval.cancel(interval);
                file.uploaded = false;
                file.processed = false;
                resolve(file);
              }
            }, 100);
          });
        };

        /**
         *
         * @param file
         * @param attributes
         * @param [productFilesParams=null]
         * @returns {*}
         */
        this._sendInfoAboutFile = function(file, attributes, productFilesParams) {
          var svc = productFilesParams ? productFilesSvc.upload : filesSvc.post;
          var infoMethod = productFilesParams ? self.getProductFileInfo : self.getInfoAboutFile;
          return $q(function(resolve, reject) {
            svc('', infoMethod(file, attributes, productFilesParams), '', function(response) {
              var data = response.data;
              if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
                if ($.isEmptyObject(data)) {
                  reject({
                    message: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    }),
                    code: 'exceptionInternalServices'
                  });
                } else {
                  var message = '';
                  if (angular.isArray(data.messages) && data.messages.length > 0) {
                    if (angular.isString(message[0])) {
                      message = data.messages.join('\n');
                    } else {
                      angular.forEach(data.messages, function(svcMessage) {
                        message += svcMessage.text;
                      });
                    }
                  }
                  reject({
                    message: message,
                    code: 'serviceError'
                  });
                }
              } else {
                file.tokenId = data.tokenId;
                resolve(file, response);
              }
            }, function(res) {
              var message;
              var code;
              if (res.status === 401) {
                message = $filter('translate')('exceptionNoAuthentication', {
                  componentCode: 'Public'
                });
                code = 'unauthorized';
              } else if (res.status === 409) {
                message = $filter('translate')('characterConflict', {
                  componentCode: 'Public'
                });
                code = 'characterConflict';
              } else {
                message = $filter('translate')('exceptionInternalServices', {
                  componentCode: 'Public'
                });
                code = 'exceptionInternalServices';
              }

              reject({
                message: message,
                code: code
              });

            }, {
              doNotAskAgainOnConflict: true
            }).then(lsnNg.noop, lsnNg.noop);
          });
        };


        /**
         *
         * @param fileForFrp
         * @param attributes
         * @returns {{dynamicValues: {}}}
         */
        this.getInfoAboutFile = function(fileForFrp, attributes) {
          var infoAboutFile = {
            dynamicValues: {}
          };
          infoAboutFile.documentCode = attributes.documentCode;
          infoAboutFile.length = fileForFrp.size;
          infoAboutFile.name = fileForFrp.name;

          angular.forEach(fileForFrp.attributes, function(attr) {
            if (attr.type === 'datetime') {
              var date = new XDate(attr.val);
              if (date.valid()) {
                var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
                infoAboutFile.dynamicValues[attr.code] = (new XDate(date.getTime() - timezoneOffset)).toISOString();
              } else {
                infoAboutFile.dynamicValues[attr.code] = '';
              }
            } else {
              infoAboutFile.dynamicValues[attr.code] = attr.val;
            }
          });

          if (attributes.dynamicValues && angular.isObject(attributes.dynamicValues)) {
            angular.extend(infoAboutFile.dynamicValues, attributes.dynamicValues);
          }

          return infoAboutFile;
        };

        /**
         * get product file's info
         * @param {Object} fileForFrp
         * @param {Object} attributes
         * @return {{dynamicValues: {}}}
         */
        this.getProductFileInfo = function(fileForFrp, attributes, productFilesParams) {
          var infoAboutFile = {};
          infoAboutFile.fileLength = fileForFrp.size;
          infoAboutFile.fileName = fileForFrp.name;
          infoAboutFile.fileType = fileForFrp.name.substr(fileForFrp.name.lastIndexOf('.') + 1);
          infoAboutFile.productId = productFilesParams.productId;

          if (attributes.dynamicValues && angular.isObject(attributes.dynamicValues)) {
            infoAboutFile.dynamicValues = angular.extend({}, attributes.dynamicValues);
          }

          return infoAboutFile;
        };


        /**
         *
         * @param uploaderFile
         */
        this.addFrpData = function(uploaderFile) {
          if (angular.isArray(uploaderFile)) {
            angular.forEach(uploaderFile, function(file) {
              self._addFrpData(file);
            });
          } else {
            self._addFrpData(uploaderFile);
          }
        };

        /**
         *
         * @param file
         * @private
         */
        this._addFrpData = function(file) {
          addMd5ToFile(file);
          file.attributes = [];
        };

        /**
         * This is called right before sending file data to frp
         * It appends frp token and some headers to request url
         * /api/fileServer/v1/{token}
         * @param data
         */
        this.appendRequestData = function(data) {
          if (angular.isArray(data.files) && data.files[0].tokenId) {
            data.url += '/' + data.files[0].tokenId;
          }

          var provider = new IHestiaRestServiceProviderAtenaDef();

          data.headers = provider.getNewRequestHeaders();
          data.headers['AP-Unique-Request-Id'] = provider.getUniqueRequestId();
          data.headers['Ap-Contract-Req-Type'] = 'ACTION';
          data.headers['Ap-Contract-Process-Type'] = 'SYNC';

          data.headers['Content-MD5'] = data.files[0].md5;

        };

        /**
         * Appends frp data (eg fileServerId) from response
         * of file content upload
         * @param data
         */
        this.appendResponseData = function(data) {
          if (data.result.status === 200) {
            data.files[0].fileServerId = data.result.fileServerId;
          }
          data.files[0].uploadDone = true;
          return data;
        };

        /**
         *
         * @param file
         * @returns {*}
         */
        this.getQuality = function(file) {
          return filesSvc.getQuality(file.fileServerId).then(function(res) {
            var qualityCode = res.data.code;
            if (qualityCode === 'Ok' || qualityCode === null) {
              res.data.status = 'SUCCESS';
            } else if (qualityCode === 'DocumentNumberNotFound' || qualityCode === 'WrongPolicyNumber' || qualityCode === 'WrongAnnexNumber') {
              res.data.status = 'WARNING';
            } else {
              res.data.status = 'ERROR';
            }
            file.frpQuality = res.data;
            return res;
          }, lsnNg.noop);
        };

        /**
         * call productFile's metaData service
         * @param  {Object} data details below
         * @return {Promise}
         */
        this.productFileMetaData = function(data) {
          var metaData = {
            applicationVer: null, // metaData.id of application/offer
            applicationNumber: null, // number of application/offer
            policyNumber: null, // optional
            transactionGroupId: null, // optional
            fileName: null, // e.g. 'blank_file.pdf'
            frpFileId: null, // e.g. 'e8df0c16-dad0-4cad-ad8c-c3659d3ae758'
            productId: null, // e.g. 'ebiznes'
            uploadPlaceCode: null // e.g. 'sendToUWR___'
          };
          _.merge(metaData, data);
          return productFilesSvc.postMetaData(metaData);
        };

        /**
         * delete product file
         * @param  {String} fileId file server id
         * @param  {[type]} productId product id (e.g. application metaData.id)
         * @return {Promise}
         */
        this.deleteProductFile = function(fileId, productId) {
          return productFilesSvc.deleteFile(fileId, productId);
        };

        /**
         * Sends files from iskaner to frp
         * (2 phases - send info about file and then transfer file)
         * @param files
         * @param attributes
         * @returns {Promise<any | never>}
         */
        this.sendIskanerFilesToFrp = function(files, attributes) {
          var promises = [];
          if (!angular.isArray(files)) {
            files = [files];
          }
          angular.forEach(files, function(file) {
            var promise = $q(function(resolve, reject) {
              self._sendInfoAboutFile(file, attributes).then(function(fileWithFrpToken) {
                iSkanerPoliciesFrpSvc.sendFiles(fileWithFrpToken.tokenId, fileWithFrpToken.id, fileWithFrpToken.iSkanerTokenId).then(function(res) {
                  fileWithFrpToken.fileServerId = res.data.fileServerId;
                  resolve(fileWithFrpToken);
                }, reject);
              }, reject);

            });

            promises.push(promise);
          });

          return $q.all(promises).then(lsnNg.noop, lsnNg.noop);
        };

      };

      return new LsnCommonUploadV2FrpHelper();
    }
  ]);