angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionVehiclesCtrl', ['$scope',
    function($scope) {
      $scope.excludeOptions = {
        type: 'exclude',
        vehicles: $scope.conditionData.vehicleExcludeList
      };

      $scope.includeOptions = {
        type: 'include',
        vehicles: $scope.conditionData.vehicleIncludeList
      };
    }
  ]);