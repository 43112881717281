angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorSearchPersonCtrl', ['$rootScope', '$scope', 'ihestiaIkomunikatorSearchPersonHelper', '$timeout', 'ihestiaIkomunikatorActionHelper', 'triggerWhenInactive', '$filter', 'iKomunikatorConstants',
    function($rootScope, $scope, ihestiaIkomunikatorSearchPersonHelper, $timeout, ihestiaIkomunikatorActionHelper, triggerWhenInactive, $filter, iKomunikatorConstants) {
      $scope.subjects = [];

      $scope.CONTACT_ROLE_CLIENT = iKomunikatorConstants.CONTACT_ROLE_CLIENT;
      $scope.CONTACT_ROLE_CLIENT_COMPANY = iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY;

      $scope.numericSettings = {
        regon: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        },
        nip: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        }
      };

      $scope.init = function() {
        $scope.tplData = ihestiaIkomunikatorSearchPersonHelper.tplData;
        $scope.modalData.subjectType = $scope.tplData.subjectType;

        $scope.fireListeners();
        ihestiaIkomunikatorSearchPersonHelper.loadDicts();
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIkomunikatorSearchPerson', function() {
          $scope.showNewMesageModal();
        });
        $scope.$on('checkTopReached', function(event, data) {
          if (!$scope.tplData.loading && data.reachedTop) {
            $timeout(function() {
              $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = false;
            }, 0);
          }
        });
        $scope.$on('onScrollShowHide', function(event, data) {
          $scope.tplData.scrollVisible = data.scrollShowed;
          if (data.scrollShowed) {
            $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = true;
          }

        });
        // modal header - client type change
        $scope.$watch('modalData.subjectType', function(type) {
          $scope.tplData.subjectType = type;
          $scope.onSubjectTypeChange();
        });
      };

      $scope.showNewMesageModal = function(clientSearched) {
        ihestiaIkomunikatorSearchPersonHelper.lastFilterText = $scope.tplData.searchFilters.value;
        ihestiaIkomunikatorSearchPersonHelper.lastOrganizationData = $scope.tplData.organizationData;
        ihestiaIkomunikatorSearchPersonHelper.lastSubjectType = $scope.tplData.subjectType;
        var data = {};
        if (clientSearched) {
          data.clientSearched = clientSearched;
          data.subjectType = $scope.tplData.subjectType;
        } else {
          data.newRecipient = true;
        }

        if (ihestiaIkomunikatorSearchPersonHelper.contextForNewMessage) {
          data.context = ihestiaIkomunikatorSearchPersonHelper.contextForNewMessage;
        }

        ihestiaIkomunikatorActionHelper.showNewMesageModal(data);
        ihestiaIkomunikatorSearchPersonHelper.hideNewMessageModal();
      };

      $scope.searchRecipients = function() {
        triggerWhenInactive(500, $scope.tplData.promiseFiter, function() {
          if ($scope.tplData.loading) {
            $scope.tplData.reloadSearchAgain = true;
          } else {
            ihestiaIkomunikatorSearchPersonHelper.reloadRecipients();
          }
        });
      };

      $scope.sortBy = function(columnName) {
        ihestiaIkomunikatorSearchPersonHelper.sortBy(columnName);
      };

      $scope.personTypeChanged = function() {
        ihestiaIkomunikatorSearchPersonHelper.setPlaceolder();
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorSearchPersonHelper.setClearData();
      });

      $scope.onSubjectTypeChange = function() {
        ihestiaIkomunikatorSearchPersonHelper.onSubjectTypeChange();
      };

      $scope.onCountryCodeChange = function() {
        ihestiaIkomunikatorSearchPersonHelper.onCountryCodeChange();
      };

      $scope.init();
    }
  ]);
