/**
 * helper dla słowników
 */
angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaDictionaryHelper', ['ihestiaRestVehicleDictionaryRegistrationTypesSvc', 'ihestiaPzaDictionarySvc', '$q',
    function(ihestiaRestVehicleDictionaryRegistrationTypesSvc, ihestiaPzaDictionarySvc, $q) {

      var promises = {};
      var standardDictItems = {};

      var DictionaryHelper = function() {
        var self = this;

        /**
         * Zwraca promise z rodzajami pojazdy wg eurotax
         * @return {Promise}
         */
        this.getVehicleRegistrationTypes = function() {
          if (!promises.registrationTypes) {
            promises.registrationTypes = ihestiaRestVehicleDictionaryRegistrationTypesSvc.get();
          }
          return promises.registrationTypes;
        };

        /**
         * Zwraca promise z żądanymi słownikami
         * @param  {array} codes tablica kodów słowników
         * @return {promise} słowniki (np. then(res.data) -> {POLICY_TYPES: [{code: 'x', name: 'xyz'}]})
         */
        this.getStandardDictItems = function(codes) {
          if(!angular.isArray(codes)) {
            codes = [codes];
          }
          return $q(function(resolve, reject){
            // sprawdzamy, czy w ogóle musimy wołać usługę
            var dictsToFetch = [];
            angular.forEach(codes, function(dictCode){
              if(!standardDictItems[dictCode]) {
                dictsToFetch.push(dictCode);
              }
            });

            // jeśli trzeba, to ładujemy je
            if(dictsToFetch.length > 0) {
              ihestiaPzaDictionarySvc.get(null, {
                codes: dictsToFetch.join(','),
                details: true
              }).then(function(res){
                if(res && (res.status === 200 || res.status === 204) && angular.isArray(res.data)) {
                  angular.forEach(res.data, function(dict){
                    standardDictItems[dict.code] = dict.items;
                  });
                  resolve(self._getStandardDictItemsFromCache(codes));
                } else {
                  reject();
                }
              }, reject);
            } else {
              // mamy już wszysko zaciągnięte
              resolve(self._getStandardDictItemsFromCache(codes));
            }
          });
        };

        /**
         * Zwraca obiekt z żądanymi słownikami z wewnętrznego cache (js)
         * funkcja wewnętrzna - nie używać poza tym helperem
         * @param  {array} codes tablica kodów słowników
         * @return {object} słowniki (np. {POLICY_TYPES: [{code: 'x', name: 'xyz'}]})
         */
        this._getStandardDictItemsFromCache = function(codes) {
          var dicts = {};
          angular.forEach(codes, function(dictCode){
            dicts[dictCode] = standardDictItems[dictCode];
          });

          return dicts;
        };

      };

      return new DictionaryHelper();
    }
  ]);