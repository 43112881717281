angular.module('lsnBase.models')
  .factory('LsnApplicationOperationDataModelV1', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationOperationDataModelV1 = function() {
        this.objectName = 'ApplicationOperationData';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          policyNumber: {
            type: 'string'
          },
          policyVer: {
            type: 'string'
          },
          changeDate: {
            type: 'dateTime'
          },
          allowedChanges: {
            type: 'ApplicationAllowedChanges'
          }
        };

        this.policyNumber = null; // numer polisy dla operacji
        this.policyVer = null; // id polisy dla operacji
        this.changeDate = null; // data dokonania zmian
        this.allowedChanges = null; // dozwolone zmiany w ramach operacji
      };

      LsnApplicationOperationDataModelV1.prototype = LsnAbstractModel.prototype;

      return LsnApplicationOperationDataModelV1;
    }
  ]);