angular.module('ihestiaWidgets.policyDetails')
  .factory('InsuredPersonModel', ['LsnAbstractModel', 'LsnPersonModelConstants',
    function(LsnAbstractModel, LsnPersonModelConstants) {
      var InsuredPersonModel = function() {

        this.objectName = 'InsuredPerson';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          firstName: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          lastName: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          gender: {
            type: 'string',
            dictionary: LsnPersonModelConstants.DICTIONARY_PERSON_GENDER
          },
          birthDate: {
            type: 'string'
          },
          personId: {
            type: 'string'
          },
          citizenshipCode: {
            type: 'string'
          },
          identityDocuments: {
            type: 'array',
            elementsType: 'object'
          }
        };

        this.firstName = null;
        this.lastName = null;
        this.gender = null;
        this.birthDate = null;
        this.personId = null; //PESEL
        this.citizenshipCode = null;
        this.identityDocuments = null;
      };

      InsuredPersonModel.prototype = LsnAbstractModel.prototype;
      return InsuredPersonModel;
    }
  ]);
