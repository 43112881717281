angular.module('salesPath2')
  .service('sp2SelectionPopups', ['resourceHelper', 'sp2CommonHelper', 'appVariables', 'CONSTANTS', 'mainDataContainer', 'CONFIG', 'actionHelper', 'dataContainerHelper',
    function(resourceHelper, sp2CommonHelper, appVariables, CONSTANTS, mainDataContainer, CONFIG, actionHelper, dataContainerHelper) {
      var SelectionPopups = function() {
        var self = this;
        /**
         * sprawdza okolicznosci i ewentualnie wyswietla popup dla wairantu NNW z utratą dochodu
         * @param  {[type]} variantCode     [description]
         * @param  {[type]} personId        [description]
         * @param  {[type]} selectionHelper [description]
         */

        this.modals = {
          liquidationModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: ['sp2.modal.liquidation', {componentCode: 'sp2'}],
              size: 'sm',
              okBtnName: ['sp2.modal.okBtnName', {componentCode: 'sp2'}],
              cancelBtnName: ['sp2.modal.cancel', {componentCode: 'sp2'}],
              keyboard: false
            },
            variant: null,
            readonly: false //czy tryb tylko do odczytu
          }
        };

        this.checkAndShowIncomeLossPopup = function(variantCode, personId, selectionHelper) {
          var variantDef = resourceHelper.getProdVariantDef(CONSTANTS.PRODUCT_NNW, variantCode);
          //obslugijemy popup tylko dla przypadku dostepnosci wariantu wylacznie dla glownego ubezpieczonego
          if (angular.equals(variantDef, {}) ||
            !angular.isDefined(CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_NNW]) ||
            CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_NNW].indexOf(variantCode) === -1 ||
            angular.isUndefined(variantDef.INCOME_LOSS) ||
            !angular.isObject(variantDef.INCOME_LOSS.professionCheck) ||
            appVariables.appMode === CONSTANTS.APP_MODE_POLICY
          ) {
            return;
          }
          sp2CommonHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            title: variantDef.INCOME_LOSS.professionCheck.questionTitle,
            text: variantDef.INCOME_LOSS.professionCheck.questionContent,
            okBtnName: 'sp2.modal.yes',
            cancelBtnName: 'sp2.modal.no',
            okBtnCallback: function() {
              sp2CommonHelper.showConfirmModal({
                treatLabelAsI18nCode: true,
                title: 'sp2.modal.info.title',
                okBtnName: 'sp2.modal.close',
                cancelBtnName: '',
                text: variantDef.INCOME_LOSS.professionCheck.alertContent
              });
              self._hightRiskJobSelected(true, variantCode, selectionHelper);
            },
            cancelBtnCallback: function() {
              self._hightRiskJobSelected(false, variantCode, selectionHelper);
            }
          });
        };

        /**
         * wybór sposobu likwidacji szkody
         * @param {string} [variant] wariant ubezpieczenia
         * @param {boolwan} [okOnly=false] czy otworzyć popupa tylko z opcją "zatwierdź" (bez anuluj)
         * @return {boolean} true if amortization modal has been shown
         */
        this.showLiquidationPopup = function(variant, okOnly) {
          okOnly = (okOnly === true) ? true : false;
          var popupShowed = false;
          if (!angular.isString(variant)) { //jesli nie podano wariantu. to odczytujemy aktualnie zaznaczony
            var acVariantSelected = dataContainerHelper.getVariantsForProduct(CONSTANTS.PRODUCT_ACKOM);
            lsnNg.forEach(acVariantSelected, function(selected, variantCode) {
              if (selected) {
                variant = variantCode;
                return false;
              }
              return true;
            });
          }
          //może nie ma potrzeby otweirania popup'a, bo jest tylko jedna opcja
          if (CONFIG.BEHAVIOR.liquidationVariantOption[variant].length > 1) {
            //potrzebny modal
            self.modals.liquidationModal.variant = variant;
            self.justShowLiquidationPopup(variant, okOnly);
            popupShowed = true;
          } else {
            //ustawiamy od razu ten jeden
            if (mainDataContainer.selectedVehicle > 0) {
              var vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
              vehicle.setAdditionalData(CONFIG.BEHAVIOR.liquidationVariantOption[variant][0], 'liquidation');
            } else {
              mainDataContainer.liquidation = CONFIG.BEHAVIOR.liquidationVariantOption[variant][0];
            }
          }
          return popupShowed;
        };

        /**
         * samo pokazanie modala likwidacji szkód
         * @param  {String} acVariant wybrany wariant AC
         * @param  {boolean} [okOnly=false] czy tylko przycisk "ok/zatwierdź"
         */
        this.justShowLiquidationPopup = function(acVariant, okOnly) {
          self.modals.liquidationModal.settings.okBtnName = ['sp2.modal.okBtnName', {componentCode: 'sp2'}];
          self.modals.liquidationModal.settings.cancelBtnName = (okOnly ? '' : ['sp2.modal.cancel', {componentCode: 'sp2'}]);
          self.modals.liquidationModal.readonly = false;
          if (appVariables.isSupplement) {
            var prevSelected = dataContainerHelper.getPreviousVariantsForProduct(CONSTANTS.PRODUCT_ACKOM);
            if (prevSelected[acVariant]) { //jeśli wariant zgodny z tym z poprzedniej polisy, to blokujemy zmianę
              self.modals.liquidationModal.settings.okBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
              self.modals.liquidationModal.settings.cancelBtnName = '';
              self.modals.liquidationModal.readonly = true;
            }
          }
          if (appVariables.readOnly) {
            self.modals.liquidationModal.readonly = true;
          }
          if (self.modals.liquidationModal.readonly) {
            self.modals.liquidationModal.settings.okBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
            self.modals.liquidationModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('liquidationModal');
        };


        /**
         * Wybrano czy osoba wykonuje zawód podwyższonego ryzyka
         * @param  {Boolean} isHighRisk
         * @param  {String} variantCode
         * @param  {sp2SelectionHelper} selectionHelper
         */
        this._hightRiskJobSelected = function(isHighRiskJob, variantCode, selectionHelper) {
          var isMainInsured = mainDataContainer.mainInsuredId !== null;
          if (isMainInsured) {
            mainDataContainer.persons[mainDataContainer.mainInsuredId].setAdditionalData(isHighRiskJob, 'highRiskJob');
          }
          if (isHighRiskJob) //nie może być NNW III
          {
            //główny ubezpieczony jest dodany do nnw
            if (isMainInsured && mainDataContainer.persons[mainDataContainer.mainInsuredId].getAdditionalData(CONSTANTS.PRODUCT_NNW) !== null) {
              selectionHelper.unselectPersonVariant(mainDataContainer.mainInsuredId, CONSTANTS.PRODUCT_NNW, variantCode);
            } else //nie ma nikogo w NNW
            {
              selectionHelper.setVariant(CONSTANTS.PRODUCT_NNW, variantCode, false);
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(CONSTANTS.PRODUCT_NNW) !== -1) //wiele variantów
              {
                selectionHelper.setVariant(CONSTANTS.PRODUCT_NNW, variantCode, false);
              } else {
                selectionHelper.setVariant(CONSTANTS.PRODUCT_NNW, null);
              }
            }
          }
          if (isMainInsured) {
            actionHelper.runAction('personEdited', {id: mainDataContainer.mainInsuredId});
          }
          actionHelper.runAction('tarifficate');
        };

      };
      return new SelectionPopups();
    }
  ]);