angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaEditChildForm', [function() {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/childHeader/forms/editChildForm.tpl.html',
      restrict: 'E',
      scope: {
        subjectData: '=',
        api: '=?'
      }
    };
  }]);
