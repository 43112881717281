angular.module('ihestiaWidgets.router', [])
  .config(['$stateProvider',
    function config($stateProvider) {
      $stateProvider
      .state('main.router', {
        url: 'router?action&policyNo&sellDate&regNumber&ehNumber&isGreenCardReturned&step&record&loadApplication&forward&operationDate',
        views: {
          'content': {
            controller: 'widgetsRouterCtrl',
            template: ''
          }
        },
        // controller: 'widgetsRouterCtrl',
        // template: '',
        data: {
          pageTitle: null,
          pageTitleLabel: null,
          disableHideBlockUi: true
        }
      })
      ;
    }
  ]);