/**
 * Helper do sprawdzania i uzupełniania danych kontaktowych agenta i agencji.
 */
angular.module('ihestiaWidgets.updateContactData')
  .service('myCustomerContactDataHelper', ['ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'myCustomerContactDataSvc', 'ihestiaCommonErrorHandler', '$rootScope', 'ihestiaConfigHelper', '$filter', 'myCustomerContactDataModalHelper', 'ihestiaDictionaryHelper', 'labelActionHelper', '$window', '$cacheFactory', 'IhestiaClauseListHelper', 'ihestiaFromRestClauseMapper', 'ihestiaLabelHelper',
    function(ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, myCustomerContactDataSvc, ihestiaCommonErrorHandler, $rootScope, ihestiaConfigHelper, $filter, myCustomerContactDataModalHelper, ihestiaDictionaryHelper, labelActionHelper, $window, $cacheFactory, IhestiaClauseListHelper, ihestiaFromRestClauseMapper, ihestiaLabelHelper) {

      var ContactDataHelper = function() {
        var self = this;

        /**
         * Mapa atrybutów w adresach
         * @type {Object}
         */
        this.addressAttributeMap = {
          postalCode: 'zipCode',
          streetPrefix: 'streetPrefix',
          street: 'street',
          city: 'city',
          house: 'buildingNumber',
          apartment: 'apartmentNumber'
        };

        /**
         * Mapa atrybutów do walidatorów z usług
         * @type {Object}
         */
        this.svcValidatorMap = {
          agency: {
            postalCode: 'IntermediaryAddress.PostalCode',
            city: 'IntermediaryAddress.City',
            streetPrefix: 'IntermediaryAddress.StreetPrefix',
            street: 'IntermediaryAddress.Street',
            house: 'IntermediaryAddress.House'
          },
          worker: {
            postalCode: 'WorkerAddress.PostalCode',
            city: 'WorkerAddress.City',
            streetPrefix: 'WorkerAddress.StreetPrefix',
            street: 'WorkerAddress.Street',
            house: 'WorkerAddress.House'
          }
        };

        /**
         * Teksty błędów do dyrektywy adresu
         * @type {Object}
         */
        this.invalidTexts = {};

        /**
         * Ustawiamy zmienne językowe
         */
        this.setLangTexts = function() {
          angular.extend(self.invalidTexts, {
            insurerZipCode: $filter('translate')('validationMessageFillZipCode', {componentCode: 'Public'}),
            insurerStreet: $filter('translate')('validationMessageFillStreet', {componentCode: 'Public'}),
            insurerStreetPrefix: $filter('translate')('validationMessageFillStreetPrefix', {componentCode: 'Public'}),
            insurerCity: $filter('translate')('validationMessageFillCity', {componentCode: 'Public'}),
            insurerHouseNumber: $filter('translate')('validationMessageFillHouseNumber', {componentCode: 'Public'})
          });
        };

        /**
         * Pobranie danych agencyjnych
         * @return {Object} Promise
         */
        this.getContactData = function(forceReload) {
          if (forceReload) {
            // inwalidujemy cache w taki sposób, ponieważ ustawienie cache:false nie robi nic ze starym cache
            // i przy kolejnym odpytaniu lecą stare dane
            $cacheFactory.get('$http').remove(myCustomerContactDataSvc._getServiceUrl());
          }
          return myCustomerContactDataSvc.ver('v2')
            .get(null, null, null, null, null, {
              cache: true
            }).then(function(res) {
              var myCustomerContactData = {
                workerData: null,
                agencyData: null,
                updateIsRequired: false
              };
              if (res && res.status === 200 && angular.isArray(res.data)) {
                // ustawiamy dane
                angular.forEach(res.data, function(contactData) {
                  if (contactData.typeCode === 'Intermediary') {
                    delete contactData.clauses;
                    myCustomerContactData.agencyData = contactData;
                  } else {
                    myCustomerContactData.workerData = contactData;
                  }
                  if (contactData.updateIsRequired && !contactData.isReadOnly) { //jeśli jest readOnly to nie ma możliwości zapisu wymaganych danych, więc nie chcemy pokazywać modala
                    myCustomerContactData.updateIsRequired = true;
                  }
                });
              } else {
                self.throwLoadContactDataException(res);
              }

              return myCustomerContactData;
            }, function(res) {
              self.throwLoadContactDataException(res);
            });
        };

        /**
         * Prepares clause list helper with clauses from response.
         * IHESTIABAS-1774 We do not have to check if user has iHestia platform available,
         * clause will be filtered when appropriate by service
         * @param clauses
         * @return {IhestiaClauseListHelper}
         */
        this.getClauseListHelper = function(clauses) {
          var language = ihestiaLabelHelper.selectedLanguage ? ihestiaLabelHelper.selectedLanguage : 'pl-PL';
          var mappedClauseList = _.chain(clauses)
            .map(ihestiaFromRestClauseMapper.mapPartnerClause(language, false))
            .value();
          return new IhestiaClauseListHelper({
            clauseList: mappedClauseList,
            showElements: {
              selectAll: false,
              expandAll: false
            },
            initiallyExpanded: true
          });
        };

        /**
         * Rzucenie wyjątkiem dla wczytywania danych kontaktowych.
         */
        this.throwLoadContactDataException = function(response) {
          ihestiaCommonErrorHandler.throwException({
            code: response.status,
            data: {
              data: response.data,
              headers: response.headers()
            },
            message: $filter('translate')('ucd.errorLoadingContactInformation', {
              componentCode: 'Dashboard'
            })
          }, true);
        };

        /**
         * Sprawdzamy, czy trzeba zaktualizować dane agencyjne
         * @return {undefined}
         */
        this.checkContactData = function() {
          var userType = ihestiaSsoBaseInfoHelper.getUser().userType;
          var isPublic = !!ihestiaConfigHelper.get('frontFlags', 'IS_PUBLIC');

          if (userType === 'System' || isPublic) {
            //nie ma sprawdzenia wymogu aktualizacji danych w przypadku aplikacji publicznych
          } else {
            var charaterType = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;
            if ([UserCharacterTypeConstants.CHARACTER_TYPE_WORKER, UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE].indexOf(charaterType) !== -1) {
              // pobranie danych kontaktowych i informacji czy trzeba ja zaktualizować
              $rootScope.hideContent = false;
              self.getContactData().then(function(contactData) {
                if (contactData.updateIsRequired) {
                  myCustomerContactDataModalHelper.showModal(contactData).then(lsnNg.noop, lsnNg.noop);
                }
              }, lsnNg.noop).finally(function() {
                $rootScope.hideContent = false;
              });
            }
          }
        };

      };

      var contactDataHelper = new ContactDataHelper();

      /**
       * Etykiety
       */
      labelActionHelper.registerAction('myCustomerContactData', contactDataHelper.setLangTexts);

      return contactDataHelper;
    }
  ]);
