/**
 * Odpala funkcję zadeklarowaną w dyrektywie po wciśnięciu klawisza Enter na elemencie
 */
angular.module('lsnBase.directives')
    .directive('ngEnter', function() {
        return function(scope, element, attrs) {
            element.on('keydown keypress', function(event) {
                if(event.which === 13) {
                    scope.$apply(function(){
                        scope.$eval(attrs.ngEnter);
                    });
                    event.preventDefault();
                }
            });

            scope.$on( '$destroy', function() {
                element.off('keydown keypress');
            });

        };
    });