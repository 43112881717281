angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaBeneficiaryListModalHelper', ['LsnModalHelper', 'IhestiaBeneficiariesContants', 'policyDetailsContextHelper', 'ihestiaBeneficiaryDataHelper',
    function(LsnModalHelper, IhestiaBeneficiariesContants, policyDetailsContextHelper, ihestiaBeneficiaryDataHelper) {
      var BeneficiaryListModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        /**
         * @typedef BeneficiaryViewModel
         * @property {string} subjectClientId
         * @property {string} subjectType
         * @property {boolean} cessionary
         * @property {int} shareRatio
         * @property {string} subjectName
         * @property {string} subjectDetails
         */

        this.modalOptions = {
          size: 'md',
          title: ['beneficiaryListModal.title', {
            componentCode: 'Public'
          }],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/beneficiaries/beneficiaryList/beneficiaryListModal.tpl.html',
          okBtnName: ['save', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          thirdBtnName: [
            'beneficiaryListModal.addBeneficiaryBtn', {
              componentCode: 'Public'
            }
          ]
        };

        this.tplData = {
          /**
           * BeneficiaryViewModel[]
           */
          mainBeneficiaries: [],
          mainBeneficiariesSettings: {
            readOnly: false,
            type: 'MAIN',
            isFormValid: null
          },
          additionalBeneficiaries: [],
          additionalBeneficiariesSettings: {
            readOnly: false,
            type: 'ADDITIONAL',
            isFormValid: null
          },
          onEditBeneficiary: function(subjectClientId, type) {
            return self.dispatchEditBeneficiary(subjectClientId, type);
          },
          onDeleteBeneficiary: function(subjectClientId, type) {
            return self.dispatchDeleteBeneficiary(subjectClientId, type);
          },
          isCentral: policyDetailsContextHelper.isCentral(),
          showElements: {
            mainBeneficiariesError: false,
            additionalBeneficiariesError: false
          },
          errors: null
        };

        this.setOptions(self.modalOptions);

        this.modalData = {
          readOnly: false,
          errors: null
        };

        this.initData = function() {
          self.tplData.mainBeneficiaries = ihestiaBeneficiaryDataHelper.getEditableBeneficiariesByType('MAIN');
          self.tplData.additionalBeneficiaries = ihestiaBeneficiaryDataHelper.getEditableBeneficiariesByType('ADDITIONAL');

          self.tplData.errors = self.modalData.errors ? self._mapErrorMessages(self.modalData.errors) : null;

          self.tplData.mainBeneficiariesSettings.readOnly = self.modalData.readOnly;
          self.tplData.additionalBeneficiariesSettings.readOnly = self.modalData.readOnly;
          self.setOptions({
            thirdBtnName: self.modalData.readOnly ? '' : [
              'beneficiaryListModal.addBeneficiaryBtn', {
                componentCode: 'Public'
              }
            ],
            okBtnName: self.modalData.readOnly ? '' : ['save', {
              componentCode: 'Public'
            }]
          });
        };

        this.dispatchEditBeneficiary = function(subjectClientId) {
          var beneficiary = ihestiaBeneficiaryDataHelper.getBeneficiaryByClientId(subjectClientId);
          self.setPromiseAsResolved({
            action: IhestiaBeneficiariesContants.ACTION_EDIT_BENEFICIARY,
            editMode: true,
            beneficiary: beneficiary,
            subject: ihestiaBeneficiaryDataHelper.getSubjectByClientId(subjectClientId),
            showCessionaryCheckbox: !ihestiaBeneficiaryDataHelper.isCessionarySelected(),
            subjectDataNonEditable: beneficiary.subjectDataNonEditable
          });
          return self.hideModal();
        };

        this.dispatchDeleteBeneficiary = function(subjectClientId, subjectType) {
          self.setPromiseAsResolved({
            action: IhestiaBeneficiariesContants.ACTION_DELETE_BENEFICIARY,
            subjectClientId: subjectClientId,
            type: subjectType
          });
          return self.hideModal();
        };

        this.thirdBtnClicked = function() {
          self.setPromiseAsResolved({
            action: IhestiaBeneficiariesContants.ACTION_ADD_BENEFICIARY,
            editMode: false,
            showCessionaryCheckbox: !ihestiaBeneficiaryDataHelper.isCessionarySelected()
          });
          return self.hideModal();
        };

        this.confirm = function() {
          if (!self._checkValidity()) {
            return null;
          }
          if (self.tplData.isCentral) {
            self.setPromiseAsResolved({
              action: IhestiaBeneficiariesContants.ACTION_ADDITIONAL_DATA
            });
          } else {
            self.setPromiseAsResolved({
              action: IhestiaBeneficiariesContants.ACTION_SAVE_BENEFICIARIES
            });
          }
          return self.hideModal();
        };

        this.cancel = function() {
          ihestiaBeneficiaryDataHelper.resetBeneficiaries();
          ihestiaBeneficiaryDataHelper.resetSubjects();
          self.setPromiseAsRejected(IhestiaBeneficiariesContants.ACTION_CLOSE_BENEFICIARIES);
          return self.hideModal();
        };

        /**
         * Checks validity of beneficiary lists and displays error messages if it occurs
         * @returns {boolean}
         * @private
         */
        this._checkValidity = function() {
          var tableValid = true;

          if (!_.isEmpty(self.tplData.mainBeneficiaries) && _.isFunction(self.tplData.mainBeneficiariesSettings.isFormValid) && !self.tplData.mainBeneficiariesSettings.isFormValid()) {
            tableValid = false;
            self.tplData.showElements.mainBeneficiariesError = true;
          } else {
            self.tplData.showElements.mainBeneficiariesError = false;
          }

          if (!_.isEmpty(self.tplData.additionalBeneficiaries) && _.isFunction(self.tplData.additionalBeneficiariesSettings.isFormValid)
            && !self.tplData.additionalBeneficiariesSettings.isFormValid()) {
            tableValid = false;
            self.tplData.showElements.additionalBeneficiariesError = true;
          } else {
            self.tplData.showElements.additionalBeneficiariesError = false;
          }
          return tableValid;
        };

        this._mapErrorMessages = function(messages) {
          return _.map(messages, 'text');
        };

      };
      return new BeneficiaryListModalHelper();
    }
  ]);
