angular.module('lsnBase.modal')
  .factory('lsnModalGlobalHelper', [function(){

    /**
     * Helper singletonowy (tutaj wyłączne minimum)
     */
    var LsnModalGlobalHelper = function() {

      var self = this;

      /**
       * referencje do helperów
       * @type {Object}
       */
      this.helpers = {};

      this.modalsData = { // dane udostępniane na zewnątrz
        isAnyModalOpen: false // jesli modal otwarty to musimy zmieniać klasę body
      };

      /**
       * Id właśnie utworzonego modala
       * (utrzymywane tylko do momentu inicjalizacji kontrolera nowego modala)
       * @type {String}
       */
      this.currentDirectiveId = null;

      /**
       * Dodajemy helper do listy aktywnych
       * @param {String} modalDirectiveId id dyrektywy
       * @param {LsnModalHelper} helperInstance   instancja helpera modala
       *
       * @return lsnModalGlobalHelper self
       */
      this.addHelper = function(modalDirectiveId, helperInstance){
        self.helpers[modalDirectiveId] = helperInstance;
        self.currentDirectiveId = modalDirectiveId;
        self.modalsData.isAnyModalOpen = true;

        angular.element('body').addClass('modal-open modal-with-am-fade-and-scale');

        return self;
      };

      this.getCurrentHelper = function() {
        var helper = self.helpers[self.currentDirectiveId];
        self.currentDirectiveId = null;
        return helper;
      };

      /**
       * Usuwamy helper z listy na skutek zamknięcia modala
       * @param {String} modalDirectiveId id dyrektywy
       *
       * @return lsnModalGlobalHelper self
       */
      this.removeHelper = function(modalDirectiveId){
        delete self.helpers[modalDirectiveId];
        self.currentDirectiveId = null;

        var anyHelperLeft = false;
        angular.forEach(self.helpers, function(){
          anyHelperLeft = true;
        });
        if(!anyHelperLeft)
        {
          self.modalsData.isAnyModalOpen = false;
          angular.element('body').removeClass('modal-open modal-with-am-fade-and-scale');
        }

        return self;
      };

      /**
       * [hideCurrentModal description]
       * @return {[type]} [description]
       */
      this.hideAllModals = function(){
        var directiveIds = [];
        angular.forEach(self.helpers, function(helper, directiveId){
          directiveIds.push(directiveId);
        });

        for(var i = directiveIds.length - 1; i >= 0; i--){
          self.helpers[directiveIds[i]].hideModal();
        }
      };

    };


    return new LsnModalGlobalHelper();
  }]);