angular.module('ihestiaCommonDirectives')
  .directive('commonCheckbox', ['$compile', '$parse',
    function($compile, $parse) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/inputs/checkboxWrap.tpl.html',
        require: ['^ngModel'],
        scope: true,
        compile: function(tElement, tAttrs) {
          var checkbox = $(tElement).find('input');
          checkbox.attr('ng-model', tAttrs.ngModel);
          checkbox.attr('ng-checked', tAttrs.ngChecked);
          checkbox.attr('ng-value', tAttrs.ngValue);
          checkbox.attr('ng-true-value', tAttrs.ngTrueValue);
          checkbox.attr('ng-false-value', tAttrs.ngFalseValue);
          if (angular.isDefined(tAttrs.ngRequired)) {
            checkbox.attr('ng-required', tAttrs.ngRequired);
          }
          if (angular.isDefined(tAttrs.required)) {
            checkbox.attr('required', tAttrs.required);
          }
          checkbox.attr('name', tAttrs.name);
          if (tAttrs.svcValidatorName) {
            checkbox.attr('svc-validator-name', tAttrs.svcValidatorName);
          }

          // popovery
          if (tAttrs.dataContent) {
            tAttrs.$observe('dataContent', function(dataContent) {
              checkbox.attr('data-content', dataContent);
            });
          }
          if (tAttrs.dataPlacement) {
            checkbox.attr('data-placement', tAttrs.dataPlacement);
          }
          if (angular.isDefined(tAttrs.inputPopover)) {
            checkbox.attr('input-popover', tAttrs.inputPopover);
          }
          if (tAttrs.popoverInvalid) {
            checkbox.attr('popover-invalid', tAttrs.popoverInvalid);
          }
          if (tAttrs.popoverShowTrigger) {
            checkbox.attr('popover-show-trigger', tAttrs.popoverShowTrigger);
          }
          if (tAttrs.popoverShowOn) {
            checkbox.attr('popover-show-on', tAttrs.popoverShowOn);
          }


          checkbox.attr('id', tAttrs.inputId);
          if (tAttrs.ngDisabled) {
            checkbox.attr('ng-disabled', tAttrs.ngDisabled);
            $(tElement).find('[common-checkbox-wrap]').attr('ng-class', '{\'disabled\': ' + tAttrs.ngDisabled + '}');
          }

          if (tAttrs.ngChange) {
            checkbox.attr('ng-change', tAttrs.ngChange);
          }


          angular.forEach(tAttrs.$attr, function(v) {
            tElement.removeAttr(v);
          });

          return {
            pre: angular.noop,
            post: function($scope, iElement, iAttrs) {
              $compile(checkbox)($scope);
              $scope.text = iAttrs.commonCheckboxText;
              if (iAttrs.commonCheckboxTextLabel) {
                $scope.textLabel = $parse(iAttrs.commonCheckboxTextLabel)($scope);
              }
              $scope.textExtended = iAttrs.commonCheckboxTextExtended;
              $scope.textExtendedHtml = iAttrs.commonCheckboxTextExtendedHtml;
              if (iAttrs.commonCheckboxTextExtendedLabel) {
                $scope.textExtendedLabel = $parse(iAttrs.commonCheckboxTextExtendedLabel)($scope);
              }
            }
          };
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $scope.isDisabled = false;

          // jeśli w wyniku ng-if'a input zniknie, to wstawiamy null do modelu
          if (angular.isDefined($attrs.clearModel)) {
            $scope.$on('$destroy', function() {
              $parse($attrs.ngModel).assign($scope, null);
            });
          }

          $attrs.$observe('ngDisabled', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.isDisabled = $scope.$eval(newVal);
            }
          });

          $attrs.$observe('commonCheckboxText', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.text = newVal;
            }
          });

          $attrs.$observe('commonCheckboxTextExtended', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.textExtended = newVal;
            }
          });

          $attrs.$observe('commonCheckboxTextExtendedHtml', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.textExtendedHtml = newVal;
            }
          });

        }]
      };
    }
  ]);