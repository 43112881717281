/**
 * Helper do informowania o różnych cudach jak wygaśnięcie hasła
 * @return {[type]} [description]
 */
angular.module('ihestiaAg.security')
  .service('securityInitHelper', ['ihestiaSsoBaseInfoHelper', '$filter', 'ihestiaCommonConfirmModalHelper', '$rootScope', '$state', 'iHestiaCommonModalHelper', '$timeout', 'ihestiaSessionSvc', 'ihestiaConfigHelper', 'ihestiaAgHelper', '$window', 'blockUiHelper', 'lsnConfirmModalHelper',
    function(ihestiaSsoBaseInfoHelper, $filter, ihestiaCommonConfirmModalHelper, $rootScope, $state, iHestiaCommonModalHelper, $timeout, ihestiaSessionSvc, ihestiaConfigHelper, ihestiaAgHelper, $window, blockUiHelper, lsnConfirmModalHelper) {

      var SecurityInitHelper = function() {
        var self = this;
        this.currentUser = null;
        this.appSymbol = ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_APP');
        // zalogowany użytkownik
        this.currentUser = ihestiaSsoBaseInfoHelper.getUser();
        this.reloggingSupported = ihestiaConfigHelper.get('frontFlags', 'SUPPORT_RELOGGED_USER');

        /**
         * Teksty dla formatki forbidden.tpl.html
         * @type {Object}
         */
        this.forbiddenMessages = {
          accessViolation: $filter('translate')('Security.accessViolation'),
          accessViolationInfo: $filter('translate')('Security.accessViolationInfo')
        };

        /**
         * Init
         * @return {[type]} [description]
         */
        this.init = function() {
          $rootScope.disableIHestiaMenu = true;
          if (self.hasAppPermission() && !self.hasPasswordExpired()) {
            $rootScope.disableIHestiaMenu = false;
            self.checkPasswordSoonExpire(self.checkRelogSupport);
          } else {
            self.checkRelogSupport();
          }
          self.fireListeners();
          return true;
        };



        /**
         * Różne eventy
         * @return {ihestiaSsoBaseInfoHelper} [description]
         */
        this.fireListeners = function() {
          $rootScope.$on('$stateChangeStart', function(event, toState) {
            if (toState.name !== 'appForbidden' && toState.name !== 'passwordExpired' && (!self.hasAppPermission() || self.hasPasswordExpired())) {
              event.preventDefault();
            }
          });

          /**
           * przechwycienie przelogowania na innej zakładce
           * @param  {[type]} event [description]
           * @param  {IHestiaCrossTabMessageModel} data
           */
          $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
            if (data.code === 'userReloged') {
              self.checkRelogSupport(true, data.attrs.login, data.attrs.name);
            }
            if (data.code === 'userDereloged' && ihestiaSsoBaseInfoHelper.isUserRelogged(false)) {
              self.showUserDerelog();
            }
          });

          return self;
        };

        /**
         * Sprawdzamy, czy nie trzeba wyświetlić komunikatu o przelogowaniu
         * @return {SecurityInitHelper} [description]
         */
        this.checkRelogSupport = function(reloged, login, name) {

          if (ihestiaSsoBaseInfoHelper.isUserRelogged(true) || (angular.isDefined(reloged) && reloged)) {
            self.showUserRelogSupport(name);
          }

          return self;
        };

        /**
         * Pokazujemy modal informacyjny o przelogowaniu
         * @return {SecurityInitHelper} [description]
         */
        this.showUserRelogSupport = function(name) {
          var title = $filter('translate')('Security.relogModalInfoTitle'),
            text;
          if (self.reloggingSupported) {
            text = $filter('translate')('Security.relogSupportedMsg', name ? name : ihestiaSsoBaseInfoHelper.getReloggedUser().getShortName());
          } else {
            text = $filter('translate')('Security.relogNotSupportedMsg', self.currentUser.getShortName());
          }

          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: title,
            text: text,
            cancelBtnName: '',
            okBtnName: 'Ok',
            okBtnCallback: function() {
              if (name && self.reloggingSupported) {
                $window.location = '/';
              }
            },
            keyboard: false
          });

          return self;
        };

        this.showUserDerelog = function() {
          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('Security.relogModalInfoTitle'),
            text: 'Wyszedłeś z przelogowania w innej części platformy. Kontynujesz jako ' + self.currentUser.getShortName(),
            cancelBtnName: '',
            okBtnName: 'Ok',
            okBtnCallback: function() {
              $window.location = '/';
            },
            keyboard: false
          });
        };

        /**
         * Sprawdzamy uprawnienia do całej aplikacji
         * @return {Boolean} [description]
         */
        this.hasAppPermission = function() {
          if (!ihestiaAgHelper.hasPermissionFor('Public', self.appSymbol)) {
            return false;
          }
          // jeśli użytkownik może potencjalnie sprzedawać, to trzeba zweryfikować dodatkowe atrybuty oss
          // związane jest to z pdpisywaniem umów przez agencje
          // uzależnione od flagi, ponieważ nie wszędzie mamy to sprawdzać
          if (ihestiaConfigHelper.get('frontFlags', 'CHECK_SALES_PROFILE_IHESTIA_PERMISSION')) {
            var hasAppPermission = true;
            var characterTypes = ['DirectSale', 'Worker', 'SalesProfile'];
            var character = self.currentUser.getCurrentCharacter();
            if (characterTypes.indexOf(character.type) > -1 && !character.ossContextInfo.SalesProfileiHestia) {
              hasAppPermission = false;
            }
            return hasAppPermission;
          }
          return true;
        };

        /**
         * Sprawdzamy, czy hasło nie wygasło (lub wkrótce wygaśnie)
         * @return {Boolean} [description]
         */
        this.hasPasswordExpired = function() {
          return !!self.currentUser.accountInfo.passwordExpired;
        };

        /**
         * Sprawdzamy, czy hasło wkrótce nie wygaśnie
         * @param {Function} callback
         * @return {Boolean} [description]
         */
        this.checkPasswordSoonExpire = function(callback) {
          var accountInfo = self.currentUser.accountInfo;
          if (accountInfo.showPwdExpiryInfo) {
            self.showPasswordSoonExpireInfo(accountInfo.daysToExpire, callback);
            return false;
          }
          callback();
          return true;
        };

        /**
         * Pokazujemy info o tym, żę hasło wkrótce wygaśnie
         * @param  {int} daysToExpire liczba dni do wygaśnięcia hasła
         * @param {Function} callback
         * @return {SecurityInitHelper}              [description]
         */
        this.showPasswordSoonExpireInfo = function(daysToExpire, callback) {

          lsnConfirmModalHelper.showConfirmModal({
            title: ['Security.passwordSoonExpireInfoTitle', {componentCode: 'Security'}],
            okBtnName: ['Security.passwordSoonExpireInfoOkBtn', {componentCode: 'Security'}],
            cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
            content: $filter('translate')('Security.passwordSoonExpireInfoText', $filter('countForm')(daysToExpire, 'days'))
          }).then(function(){
            iHestiaCommonModalHelper.showModal('ihestiaPasswordChange');
          }, function(){
            self.cancelPasswordSoonExpireInfo();
          }).finally(function(){
            if (angular.isFunction(callback)) {
              callback();
            }
          });

          return self;
        };

        /**
         * Anulujemy info o tym, że hasło wkrótce wygaśnie
         * @return {SecurityInitHelper} false
         */
        this.cancelPasswordSoonExpireInfo = function() {
          blockUiHelper.showBlockUi();
          ihestiaSessionSvc.hidePwdExpiryInfo(function() {
            blockUiHelper.hideBlockUi();
          });

          return self;
        };

        /**
         * Pokazujemy info o tym, żę hasło wygasło
         * @return {SecurityInitHelper}              [description]
         */
        this.showPasswordExpiredInfo = function() {
          $state.go('passwordExpired');
          return self;
        };

        /**
         * Pokazdujemy stan brak uprawnień do aplikacji
         * @return {SecurityInitHelper} [description]
         */
        this.showAppForbidden = function() {
          $state.go('appForbidden');
          return self;
        };

      };

      var helper = new SecurityInitHelper();
      return helper;
    }
  ]).config(['$stateProvider', function($stateProvider) {
  $stateProvider.decorator('views', function(state, parent) {
    var result = {},
      views = parent(state);

    angular.forEach(views, function(config, name) {
      if (!config.resolve) {
        config.resolve = {};
      }
      config.resolve.hasAppPermission = ['securityInitHelper', '$q', '$rootScope',
        function(securityInitHelper, $q, $rootScope) {
          var d = $q.defer(),
            createListener = false;
          // handler ogarniający uprawnienia
          var promiseHandler = function(hasAppPermission) {
            if (!hasAppPermission) {
              // brak uprawnien do aplikacji
              d.reject('appForbidden');
              createListener = true;
            } else if (securityInitHelper.hasPasswordExpired()) {
              // haslo wygaslo
              d.reject('passwordExpired');
              createListener = true;
            } else {
              d.resolve(true);
            }
            // nie ma sensu tworzyć tymczasowego listenera jeśli wszystko było ok
            if (createListener) {
              // sluchamy odrzucen ui-routera
              var rejectListener = $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
                if (error === 'appForbidden') {
                  securityInitHelper.showAppForbidden();
                  rejectListener();
                } else if (error === 'passwordExpired') {
                  securityInitHelper.showPasswordExpiredInfo();
                  rejectListener();
                }
              });
            }
          };

          // dzięki temu securityInitHelper może też zwracać promise'a
          $q.when(securityInitHelper.hasAppPermission(), promiseHandler, promiseHandler);

          return d.promise;
        }
      ];
      result[name] = config;
    });
    return result;
  });
}])
  .run(['$rootScope', '$timeout', '$injector',
    function($rootScope, $timeout, $injector) {
      var initDestroy = $rootScope.$on('commonModal.settingsInitialized.ihestiaCommonConfirmModal', function() {
        // @comment magic - do not touch
        // z jakiegoś mega dziwnego, niezrozumiałego przeze mnie powodu jeśli securityInitHelper
        // jest wrzucony przed tym eventem, to $stateChangeStart nie jest wywoływany na inicjalnym stanie
        var securityInitHelper = $injector.get('securityInitHelper');
        $timeout(function() {
          securityInitHelper.init();
        }, 0);
        initDestroy();
      });
    }
  ]);