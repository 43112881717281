angular.module('iSkanerLsn').factory('iSkanerPairedDevicesSvc', ['IHestiaRestServiceAbstractDef', '$q', function(IHestiaRestServiceAbstractDef, $q) {
	var iSkanerPairedDevicesSvc = function() {
		IHestiaRestServiceAbstractDef.apply(this, arguments);
		var self = this;

    this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{apiVer}/{resource}{id}{subResource}';
    this.businessArea = 'ehscanner';
    this.resource = 'admin/pairing';

		/**
		 * [getQrCode description]
		 * @param  {[type]} data [description]
		 * @return {[type]}      [description]
		 */
		this.getQrCode = function(data) {
			return self.get(null, {login: data.userId, system: data.system}, null, null, null, {headers: {'Accept': 'application/json, text/plain', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache'}});
		};

		this.fetch = function(data) {
			var deferred = $q.defer();

			self.get('devices', {login: data.userId, system: data.system}, null, null, null, {headers: {'Accept': 'application/json, text/plain', 'Cache-Control': 'no-cache', 'Pragma': 'no-cache'}})
				.then(function(response) {
					if (response.status === 200 && response.data) {
						deferred.resolve(response.data);
					} else if (response.status === 204 && !response.data) {
						deferred.reject('No paired devices');
					}
				}, function(error) {
					return error;
				});

			return deferred.promise;
		};

		this.unpair = function(deviceId, data) {
			return self.remove('/devices/' + deviceId + '?login=' + data.userId + '&system=' + data.system);
		};
	};

	return new iSkanerPairedDevicesSvc();
}]);