angular.module('lsnBase.models')
  .factory('LsnRiskModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAmountModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnAmountModelConstants)
    {
      var LsnRiskModelV2 = function()
      {
        this.objectName = 'Risk';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          product:
          {
            type: 'SalesProduct',
            version: 'v2'
          },
          owu:
          {
            type: 'string'
          },
          owuName:
          {
            type: 'string'
          },
          name:
          {
            type: 'string'
          },
          startDate:
          {
            type: 'dateTime'
          },
          endDate:
          {
            type: 'dateTime'
          },
          generalAgreementNumber: {
            type: 'string'
          },
          insuredRef: {
            type: 'string'
          },
          premium:
          {
            type: 'float',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          parentRef: {
            type: 'string'
          },
          premiumList:
          {
            type: 'object'
          },
          sum:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          gross:
          {
            type: 'bool'
          },
          isHistorical:
          {
            type: 'bool'
          },
          personsRefs:
          {
            type: 'array',
            elementsType: 'InsuredSubject'
          },
          organizationsRefs:
          {
            type: 'array',
            elementsType: 'InsuredSubject'
          },
          estatesRefs:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          vehiclesRefs:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          symbol:
          {
            type: 'string'
          },
          group:
          {
            type: 'string'
          },
          riskVariantRef: {
            type: 'string'
          },
          suretyRef: {
            type: 'string'
          },
          installmentList: {
            type: 'object'
          },
          beneficiaries: {
            type: 'array',
            elementsType: 'mixed'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.product = lsnModelFactory.getObject('SalesProduct');
        this.parentRef = null;
        this.owu = null; // string, Symbol OWU
        this.owuName = null; // string, nazwa OWU
        this.name = null; // string, nazwa ryzyka
        this.startDate = null; // date, Data rozpoczęcia okresu ochrony na polisie
        this.endDate = null; // date, Data zakończenia okresu ochrony na polisie
        this.generalAgreementNumber = null; // nr umowy generalnej
        this.insuredRef = null; // id glł ubezpieczonego na ryzyku
        this.premium = null; // Amount, Składka na ryzyku
        this.premiumList = null; // lista składek w zależności od rat
        this.installmentList = null; // lista składek w podziale na raty wraz z datami rat
        this.sum = null; // Amount, Suma ubezpieczenia
        this.gross = null; // bool, True oznacza, że jest to kwota brutto. Wartością domyślną jest true. Uzupełniać tylko jeśli false.
        this.isHistorical = null; // bool, czy ryzyko jest historyczne
        this.personsRefs = null; // powiązania z osobami
        this.organizationsRefs = null; // powiązania z organizacjami
        this.estatesRefs = null; // powiązania z nieruchomościami
        this.vehiclesRefs = null; // powiązania z pojazdami
        this.symbol = null; // string, Symbol np. „J07-01”
        this.group = null; // string, Grupa ministerialna
        this.riskVariantRef = null; // referencja do RiskVariant
        this.suretyRef = null; // referencja do cesjonariusza
        this.beneficiaries = null;
      };

      LsnRiskModelV2.prototype = LsnAbstractModel.prototype;

      return LsnRiskModelV2;
    }
  ]);