angular.module('salesPath2')
  .service('addsTplHelper', ['actionHelper', 'RESOURCES', 'mainDataContainer', 'sp2CommonHelper', '$injector', 'CONSTANTS', 'CONFIG', 'resourceHelper', 'addHelper', 'sp2SelectionHelper', 'personHelper', 'renewalHelper', 'dataContainerHelper',
    function(actionHelper, RESOURCES, mainDataContainer, sp2CommonHelper, $injector, CONSTANTS, CONFIG, resourceHelper, addHelper, selectionHelper, personHelper, renewalHelper, dataContainerHelper) { // eslint-disable-line angular/di
      var AddsTplHelper = function() {
        var self = this;

        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          showAdds: false,
          addDefs: [], //definicje dodatków
          addPremiums: {}, //skladki
          activeProductList: [], //lista aktywnych produktów, do których należy zawężyć prezentację dodatków
          renewalMarks: {} //informacje o wznowianiach
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.refreshRenewalMarks();
              break;
            case 'addDeleted':
            case 'addAdded':
            case 'matrixProductSelected':
            case 'personEdited':
            case 'personAdded':
            case 'personDeleted':
            case 'groupEdited':
            case 'groupAdded':
            case 'groupDeleted':
            case 'personVariantChanged':
            case 'fireBurglarySumInsuredChanged':
            case 'coownersChanged':
              self.refreshAddData();
              break;
            case 'objectSelected':
            case 'localizationDeleted':
            case 'personGroupSelected':
              self.refreshAddData(true);
              break;
            default:
          }
        };

        /**
         * odświeżenie listy dodatkow
         * jesli przekazano np. w ng-init activeProductList to zawezamy dodatki do tej listy produktow
         * @param {boolean} refreshDefs czy odswiezyc definicje dodatkow
         */
        this.refreshAddData = function(refreshDefs) {
          var addDefs = [];
          if (refreshDefs) {
            addDefs = self.refreshAddDefs();
          }
          if (self.tplData.addDefs.length === 0) {
            return; //jeśli próba aktualizacji dodatków, gdy jeszcze nie zostały wyświetlone
          }
          if (!refreshDefs) {
            addDefs = self.getActiveProductDefinitions(self.tplData.activeProductList);
          }
          angular.forEach(addDefs, function(add, idx) {
            angular.forEach(add, function(v, k) {
              self.tplData.addDefs[idx][k] = v; //getActiveProductDefinitions zawsze zwróci te same dodatki więc można oprzeć się na idx
            });
          });
          self.refreshPremiums();
          self.refreshRenewalMarks();
        };

        /**
         * odsiweza definicje dodatkow
         * @return {Object[]} aktualna definicje dodatków
         */
        this.refreshAddDefs = function() {
          self.tplData.addDefs = self.getActiveProductDefinitions(self.tplData.activeProductList);
          self.tplData.showAdds = self.tplData.addDefs.length > 0;
          return self.tplData.addDefs;
        };

        /**
         * aktulaizuje skladki
         * @return {[type]} [description]
         */
        this.refreshPremiums = function() {
          self.tplData.addPremiums = self.getPremiums();
        };

        /**
         * Parsowanie produktów potrzebnych w szablonie
         * Skopiowane z funkcji show z managera z ery soi
         * @return {[type]} [description]
         */
        this.getActiveProductDefinitions = function(activeProductList) {
          var addsDefs = [],
            activeAdds = {}, //kod dodatku => czy atywny na GUI
            numbersToShow = {}, //numery wybranych obiektów dla dodatków
            selectedAdds = selectionHelper.getSelectedAdds(),
            previouslySelectedAdds = selectionHelper.getPreviouslySelectedAdds(),
            restrictToProducts = angular.isArray(activeProductList) && activeProductList.length > 0, //true|false
            insuredObjectId = restrictToProducts ? dataContainerHelper.getSelectedObjectId(resourceHelper.productType[activeProductList[0]]) : null; //sczytujemy głowny obiekt ubepiecznia na podstawie pierwszego z brzegu produktu. Zakladamy ze wszytskie produkty sa tego samego typu.
          if (restrictToProducts && insuredObjectId !== CONSTANTS.ALL_OBJECTS) //wybrana lista produktów
          {
            activeAdds = addHelper.getVisibleAddsForProducts(activeProductList, insuredObjectId);
            lsnNg.forEach(activeAdds, function(active, addCode) {
              var addDef = angular.copy(resourceHelper.getAddDef(addCode));
              addsDefs.push(addDef);
              numbersToShow[addCode] = null;
            });
          } else {
            addsDefs = angular.copy(RESOURCES.PRODADD); //wszystkie dodatki, matryca
            var availableAdds = addHelper.getGlobalAvailableAdds();
            angular.forEach(addsDefs, function(addDef) {
              activeAdds[addDef.CODE] = (availableAdds[addDef.CODE] !== false);
              numbersToShow[addDef.CODE] = angular.isObject(availableAdds[addDef.CODE]) ? Object.keys(availableAdds[addDef.CODE]) : null;
            });
          }
          angular.forEach(addsDefs, function(addDef) {
            addDef.tplData = self.getToolSheetData(addDef.CODE, previouslySelectedAdds);
            addDef.tplData.isSelected = (selectedAdds[addDef.CODE] === true);
            addDef.tplData.previouslySelected = (previouslySelectedAdds[addDef.CODE] ? true : false);
            addDef.tplData.isAvailable = activeAdds[addDef.CODE] || addDef.tplData.previouslySelected || addDef.tplData.isSelected; //dostepy do zaznaczenia lub wybrany na poprzedniej polisie
            addDef.tplData.numbersToShow = numbersToShow[addDef.CODE];
          });

          return addsDefs;
        };

        /**
         * odśwież ainformacje o wznowieniach (obiekt z dodatkami, dla których należy zaznaczyć wznowienie)
         * {Object<string, Object>} gdzie string to kod dodatku, Object to obiekt formatu {isRenew: true[, variants: {variantCode: true[, ...]}]}. Jeśli pojawi się w tym obiekcie dodatek, to zawsze oznacza ze jest wznawiany dlatego isRenew jest zawsze na true. variants jest opcjonalne i występuje tylko dla dodatków, które mają w definicji warianty i jeśli wariant pojawi się w tym obiekcie to też zawsze oznacza, zę jest wznawiany (zawsze ma wartość true).
         */
        this.refreshRenewalMarks = function() {
          var marks = {};
          angular.forEach(renewalHelper.getAddsMarks(), function(val, addCode) {
            marks[addCode] = {
              isRenew: true
            };
            var addDef = resourceHelper.getAddDef(addCode);
            if (addDef.TYPE !== CONSTANTS.PRODUCT_TYPE_PERSON && angular.isDefined(addDef.VARIANTS)) //zachowana logika z pokazywania płachty
            {
              //dla dodatkow z wariantami sprawdzamy wznowienie dla konkretnego wariantu
              var variantMarks = renewalHelper.getAddSheetMarks(addCode);
              marks[addCode].variants = {};
              angular.forEach(variantMarks, function(variantVal, variantCode) {
                marks[addCode].variants[variantCode] = true;
              });
            }
          });
          self.tplData.renewalMarks = marks;
        };

        /**
         * pobranie sum składek
         * @return {object} lista {code:premium,...}
         */
        this.getPremiums = function() {
          var premiums = {};

          lsnNg.forEach(RESOURCES.PRODADD, function(addDef) {
            var addPremium = 0;
            var selectedObject = CONSTANTS.ALL_OBJECTS;
            //określamy jak szeroko liczymy sumę
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
              selectedObject = mainDataContainer.selectedLocalization;
            }
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
              selectedObject = mainDataContainer.selectedVehicle;
            }
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
              selectedObject = mainDataContainer.selectedSubject;
            }

            if (selectedObject === CONSTANTS.NO_OBJECT) //brak lokalizacji/pojazdu więc pomijamy
            {
              return true;
            }

            if (typeof addDef.VARIANTS !== 'undefined') //są warianty na dodatku
            {
              lsnNg.forEach(mainDataContainer.selectedAdditions[addDef.CODE], function(ids, variant) //zbieramy kwotę z zaznaczonych dodatków
              {
                if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
                  if (ids) //boolean
                  {
                    addPremium += self.getPremium(addDef.CODE, variant);
                  }
                } else {
                  lsnNg.forEach(ids, function(id) {
                    if (selectedObject === CONSTANTS.ALL_OBJECTS || id === selectedObject) {
                      addPremium += self.getPremium(addDef.CODE, variant, id);
                    }
                  });
                }
              });
              if (addPremium === 0) //nie ma zaznaczonych, więc chcemy pokazać symulację
              {
                //jeśli tylko część wariantów jest dostępna to bierzemy z pierwszego dostępnego, w przeciwnym wypadku z pierwszego
                var availableVariant = CONSTANTS.VARIANT_I;


                lsnNg.forEach(CONSTANTS.VARIANTS_ORDER, function(variantCode) {
                  var variantDef = resourceHelper.getAddVariantDef(addDef.CODE, variantCode);
                  if (variantDef === null) {
                    return false; //nie ma takiego wariantu dla tego ryzyka
                  }

                  if (addHelper.addVariantIsAvailable(addDef.CODE, variantDef.CODE, selectedObject) && (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE || self.getPremiumSimulation(addDef.CODE, variantDef.CODE, selectedObject) !== 0)) {
                    availableVariant = variantDef.CODE;
                    return false;
                  }

                  return true; //continue
                });
                if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
                  addPremium += self.getPremium(addDef.CODE, availableVariant);
                } else {
                  addPremium = self.getPremiumSimulation(addDef.CODE, availableVariant, selectedObject);
                }
              }
            } else {
              if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
                addPremium += self.getPremium(addDef.CODE);
              } else {
                angular.forEach(mainDataContainer.selectedAdditions[addDef.CODE], function(id) {
                  if (selectedObject === CONSTANTS.ALL_OBJECTS || id === selectedObject) {
                    addPremium += self.getPremium(addDef.CODE, null, id);
                  }
                });
                if (addPremium === 0) //zaznaczonych nie ma, więć bierzemy z symulacji
                {
                  addPremium = self.getPremiumSimulation(addDef.CODE, null, selectedObject);
                }
              }
            }
            premiums[addDef.CODE] = addPremium;

            return true; //continue
          });

          return premiums;
        };

        /**
         * wyciągnięcie pojedynczej składki
         * @param  {string} addCode  kod dodatku
         * @param  {string} variant  wariant
         * @param  {string|int} objectId id obiektu
         * @return {int}          składka
         */
        this.getPremium = function(addCode, variant, objectId) {
          return dataContainerHelper.getAddPremium(addCode, variant, objectId, 'premiumList');
        };

        /**
         * szukanie składki na dowolnym obiekcie
         * @param  {string} addCode
         * @param  {string} variant
         * @return {int}            składka symulacji
         */
        this.getPremiumSimulation = function(addCode, variant, objectId) {
          if (objectId === CONSTANTS.NO_OBJECT) //brak lokalizacji/pojazdu więc pomijamy
          {
            return 0;
          }

          var addDef = resourceHelper.getAddDef(addCode);
          var premium = 0;
          if (objectId === CONSTANTS.ALL_OBJECTS) {
            lsnNg.forEach(dataContainerHelper.getObjects(addDef.TYPE), function(obj, id) {
              premium = self.getPremium(addCode, variant, id);
              if (premium !== 0) {
                return false;
              }
              return true; //continue
            });
          } else {
            premium = self.getPremium(addCode, variant, objectId);
          }
          return premium;
        };

        /**
         * pobiera dane dla prezentacji dodatku na interfejsie
         * @param  {String} code [description]
         * @param  {Object} previouslySelectedAdds poprzednio wybrane dodatki i ich warianty
         * @return {[type]}      [description]
         */
        this.getToolSheetData = function(code, previouslySelectedAdds) {
          var sheetData = {};
          var addData = resourceHelper.getAddDef(code);
          var allObjects = false; //wszystkie pojazdy/lokalizacje/osoby-grupy
          var mainSelectedObject = CONSTANTS.ALL_OBJECTS;
          var unchangeableAdds = selectionHelper.getUnchangeabledAdds(previouslySelectedAdds);
          if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            mainSelectedObject = mainDataContainer.selectedLocalization;
          } else if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            mainSelectedObject = mainDataContainer.selectedVehicle;
          } else if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
            mainSelectedObject = mainDataContainer.selectedSubject;
          }
          if (mainSelectedObject === CONSTANTS.ALL_OBJECTS && [CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP].indexOf(addData.TYPE) !== -1) {
            allObjects = true;
          }

          var elements = [],
            selectedObjectsIds = [],
            selectedObjectsNumbers = [];
          if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) //wyświetlamy listę osób
          {
            sheetData.personAdd = true;
            sheetData.TITLE = addData.NAME;
            sheetData.DESC = addData.DESC;
            sheetData.TAGLINE = addData.TAGLINE;
            sheetData.TAGLINE_2 = angular.isDefined(addData.TAGLINE_2) ? addData.TAGLINE_2 : null;

            angular.forEach(addHelper.getAvailablePersons(), function(person, id) {
              elements.push({
                TITLE: personHelper.getPersonInfo(person),
                SUBJECT_ID: id,
                ADD_CODE: addData.CODE,
                DESC: '',
                UNCHANGEABLE: unchangeableAdds[addData.CODE] && unchangeableAdds[addData.CODE][id]
              });
            });
          } else if (angular.isDefined(addData.VARIANTS)) //dodatek z wariantami
          {
            var renewalVariants = {};
            angular.forEach(addData.VARIANTS, function(variant) {
              selectedObjectsIds = mainDataContainer.selectedAdditions[addData.CODE][variant.CODE];
              selectedObjectsNumbers = [];
              angular.forEach(selectedObjectsIds, function(objectId) {
                selectedObjectsNumbers.push(dataContainerHelper.getObjectNumber(addData.TYPE, objectId));
              });

              var variantRenewed = false;
              if (typeof renewalVariants[variant.CODE] !== 'undefined') {
                variantRenewed = true;
              }

              elements.push({
                TITLE: variant.NAME,
                VARIANT_CODE: variant.CODE,
                VARIANT_AVAIABLE: addHelper.addVariantIsAvailable(addData.CODE, variant.CODE, mainSelectedObject),
                DESC: variant.DESC,
                selectedObjects: selectedObjectsNumbers,
                renewal: variantRenewed,
                TAGLINE: angular.isDefined(variant.TAGLINE) ? variant.TAGLINE : null,
                TAGLINE_2: angular.isDefined(variant.TAGLINE_2) ? variant.TAGLINE_2 : null,
                UNCHANGEABLE: unchangeableAdds[addData.CODE] && unchangeableAdds[addData.CODE][variant.CODE]
              });
            });
          } else //dodatek bez wariantów
          {
            selectedObjectsIds = mainDataContainer.selectedAdditions[addData.CODE];
            selectedObjectsNumbers = [];
            angular.forEach(selectedObjectsIds, function(objectId) {
              selectedObjectsNumbers.push(dataContainerHelper.getObjectNumber(addData.TYPE, objectId));
            });
            elements.push({
              TITLE: angular.isDefined(addData.SUBNAME) ? addData.SUBNAME : addData.NAME,
              DESC: addData.DESC,
              selectedObjects: selectedObjectsNumbers,
              TAGLINE: angular.isDefined(addData.TAGLINE) ? addData.TAGLINE : null,
              TAGLINE_2: angular.isDefined(addData.TAGLINE_2) ? addData.TAGLINE_2 : null,
              UNCHANGEABLE: unchangeableAdds[addData.CODE]
            });
          }

          //dokładamy infomację czy został już dany element wybrany
          angular.forEach(elements, function(element) {
            element.isSelected = selectionHelper.isAddSelected(addData.CODE, element.SUBJECT_ID, element.VARIANT_CODE);
          });

          var additionalData = {
            allObjects: allObjects
          };

          return {
            sheetData: sheetData,
            elements: elements,
            additionalData: additionalData
          };
        };

      };

      return new AddsTplHelper();
    }
  ])
  .run(['addsTplHelper', 'actionHelper',
    function(addsTplHelper, actionHelper) {
      actionHelper.registerHelper('addsTplHelper', addsTplHelper);
    }
  ]);
