angular.module('spaceChat.clientArchive').directive('chatArchiveResize', ['$window', function ($window) {
        'ngInject'; // NOSONAR

        return {
            priority: 0,
            link    : function (scope, element, attrs) {
                var changeHeight = function () {
                    element.css('max-height', ($window.innerHeight - parseInt(attrs.chatArchiveResize, 10)) + 'px'); // 120px = wysokość navbara + subnavbara + footera
                };
                $window.addEventListener('resize', changeHeight);
                changeHeight();
                scope.$on('$destroy', function() {
                    $window.removeEventListener('resize', changeHeight);
                });
            }
        };
    }]);