angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaBeneficiaresModalFlowHelper', ['$q', 'ihestiaBeneficiaryListModalHelper', 'IhestiaBeneficiariesContants', '$filter', 'lsnConfirmModalHelper', 'ihestiaBeneficiaryAdditionalDataModalHelper', 'ihestiaBeneficiaryDataHelper', 'ihestiaBeneficiaryEditModalHelper', 'policyOperationsV2Svc', 'lifePolicyOperationsHelper', 'policyDetailsContextHelper', 'blockUiHelper', '$alert',
    function($q, ihestiaBeneficiaryListModalHelper, IhestiaBeneficiariesContants, $filter, lsnConfirmModalHelper, ihestiaBeneficiaryAdditionalDataModalHelper, ihestiaBeneficiaryDataHelper, ihestiaBeneficiaryEditModalHelper, policyOperationsV2Svc, lifePolicyOperationsHelper, policyDetailsContextHelper, blockUiHelper, $alert) {
      var BeneficiariesModalFlowHelper = function() {
        var self = this;
        this.translate = $filter('translate');
        this.policyNumber = null;
        this.policyStartDate = null;
        this.readOnly = undefined;
        this.DEFAULT_BENEFICIARY_REQUIRED_FIELDS = {
          name: true,
          address: {
            house: true,
            city: true,
            postalCode: true
          },
          firstName: true,
          lastName: true,
          birthDate: true,
          regon: true,
          kinship: true,
          countryCode: true
        };

        /**
         *
         * @param {string} policyNumber
         * @param {boolean} skipBeneficiaryCheck - force not checking is any beneficiaries are present -> empty list available
         * @param {string} policyStartDate
         * @param {string} readOnly
         * @returns {Promise}
         */
        this.open = function(policyNumber, policyStartDate, skipBeneficiaryCheck, readOnly) {
          self.policyNumber = policyNumber;
          self.policyStartDate = policyStartDate;
          if (_.isBoolean(readOnly)) {
            self.readOnly = readOnly;
          }
          return self._startModalFlow(skipBeneficiaryCheck)
            .then(function() {
              return self._openBeneficiaryListModal();
            }, lsnNg.noop);
        };

        /**
         * Starts beneficiary modal flow - if there are no beneficiaries opens add beneficiary modal first else beneficiary list modal
         * @param {boolean} skipBeneficiaryCheck
         * @returns {Promise}
         * @private
         */
        this._startModalFlow = function(skipBeneficiaryCheck) {
          if (ihestiaBeneficiaryDataHelper.isBeneficiaryListEmpty() && !skipBeneficiaryCheck) {
            return ihestiaBeneficiaryEditModalHelper.showModal({
              action: IhestiaBeneficiariesContants.ACTION_ADD_BENEFICIARY,
              editMode: false,
              showCessionaryCheckbox: !ihestiaBeneficiaryDataHelper.isCessionarySelected() && ihestiaBeneficiaryDataHelper.isLongTermPolicy,
              required: self.DEFAULT_BENEFICIARY_REQUIRED_FIELDS
            }).then(function(data) {
              ihestiaBeneficiaryDataHelper.addNewBeneficiary(data.beneficiary, data.subject);
              return IhestiaBeneficiariesContants.ACTION_EDIT_BENEFICIARY;
            }, function() {
              throw IhestiaBeneficiariesContants.ACTION_CLOSE_BENEFICIARIES;
            });
          } else {
            return $q.when();
          }
        };

        /**
         * Opens beneficiary list modal and handles navigation to related modals
         * @params {any[]} errors
         * @returns {Promise}
         * @private
         */
        this._openBeneficiaryListModal = function(errors) {
          return ihestiaBeneficiaryListModalHelper.showModal({
            readOnly: self._readOnly(),
            errors: errors
          }).then(function(response) {
            switch (response.action) {
              case IhestiaBeneficiariesContants.ACTION_EDIT_BENEFICIARY:
              case IhestiaBeneficiariesContants.ACTION_ADD_BENEFICIARY:
                return self._handleOpenEditBeneficiary(response);
              case IhestiaBeneficiariesContants.ACTION_DELETE_BENEFICIARY:
                return self._handleDeleteBeneficiary(response);
              case IhestiaBeneficiariesContants.ACTION_ADDITIONAL_DATA:
                return ihestiaBeneficiaryAdditionalDataModalHelper.showModal({
                  policyNumber: self.policyNumber,
                  policyStartDate: self.policyStartDate
                });
              default:
                return response.action;
            }
          }, function() {
            throw IhestiaBeneficiariesContants.ACTION_CLOSE_BENEFICIARIES;
          }).then(self._saveChanges,
            function(action) { // invoked after additional data cancel or beneficiary list cancel
              if (action !== IhestiaBeneficiariesContants.ACTION_CLOSE_BENEFICIARIES) {
                return self.open(self.policyNumber, self.policyStartDate, true, self.readOnly); // opens beneficiary list modal again
              } else {
                ihestiaBeneficiaryDataHelper.resetBeneficiaries();
                ihestiaBeneficiaryDataHelper.resetSubjects();
                return false; // finishes modal flow without saving
              }
            });
        };

        /**
         * Attempts saving edited beneficiary data
         * @private
         * @returns {Promise}
         */
        this._saveChanges = function(additionalData) {
          var editBeneficiariesRequest = lifePolicyOperationsHelper.mapEditBeneficiariesRequest(
            ihestiaBeneficiaryDataHelper.editableBeneficiaries,
            ihestiaBeneficiaryDataHelper.editableSubjects,
            additionalData,
            self.policyStartDate
          );
          blockUiHelper.showBlockUi();
          return policyOperationsV2Svc.post(self.policyNumber, editBeneficiariesRequest, 'beneficiaries')
            .then(function() {
              ihestiaBeneficiaryDataHelper.updateSavedBeneficiaries();
              blockUiHelper.hideBlockUi();
              $alert({
                content: $filter('translate')('operationSuccesfull', {componentCode: 'Public'}),
                type: 'success',
                duration: 10
              });
              return true;
            }, function(reject) {
              blockUiHelper.hideBlockUi();
              if (_.has(reject.data, 'messages')) {
                return self._openBeneficiaryListModal(reject.data.messages);
              } else {
                ihestiaBeneficiaryDataHelper.resetBeneficiaries();
                ihestiaBeneficiaryDataHelper.resetSubjects();
                throw reject;
              }
            });
        };

        this._handleDeleteBeneficiary = function(response) {
          return lsnConfirmModalHelper.showConfirmModal({
            title: self.translate('Public.delete', {componentCode: 'Public'}),
            content: self.translate('beneficiaryListModal.confirmDelete', {
              componentCode: 'spLife'
            }),
            okBtnName: self.translate('confirm', {componentCode: 'Public'}),
            cancelBtnName: self.translate('Public.cancel', {componentCode: 'Public'})
          }).then(function() {
            ihestiaBeneficiaryDataHelper.deleteBeneficiary(response.subjectClientId);
            throw IhestiaBeneficiariesContants.ACTION_BACK;
          }, function() {
            throw IhestiaBeneficiariesContants.ACTION_BACK;
          });
        };

        this._handleOpenEditBeneficiary = function(response) {
          var modalData = _.merge(response, {
            readOnly: self._readOnly(),
            required: self.DEFAULT_BENEFICIARY_REQUIRED_FIELDS,
            invalidPartialStreetData: true, // streetPrefix and street must both be undefined or defined (no partial)
            showCessionaryCheckbox: !ihestiaBeneficiaryDataHelper.isCessionarySelected() && ihestiaBeneficiaryDataHelper.isLongTermPolicy
          });

          return ihestiaBeneficiaryEditModalHelper.showModal(modalData)
            .then(function(data) {
              if (data.editMode) {
                ihestiaBeneficiaryDataHelper.updateEditableBeneficiaryByClientId(data.beneficiary, data.subject);
              } else {
                ihestiaBeneficiaryDataHelper.addNewBeneficiary(data.beneficiary, data.subject);
              }
              throw IhestiaBeneficiariesContants.ACTION_BACK;
            }, function() {
              throw IhestiaBeneficiariesContants.ACTION_BACK;
            });
        };

        this._readOnly = function() {
          return self.readOnly;
        };

      };
      return new BeneficiariesModalFlowHelper();
    }]);
