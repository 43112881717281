angular.module('ihestiaGrid')
  .service('ihestiaGridHelper', [
   function () {

      /**
       * Zwracany serwis
       */
      var IHestiaGridHelper = function () {

        this.sharedData = {
          baseFilterfocused: false
        };

      };

      return new IHestiaGridHelper();
    }
  ]);
