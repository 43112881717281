angular.module('lsnBase.models')

/**
   * Abstrakcyjny model danych, którego prototyp dziedziczą wszystkie klasy modeli
   * @return {LsnAbstractModel}
   */
  .factory('LsnAbstractModel', ['lsnModelHelper', function(lsnModelHelper) {

    /**
     * [LsnAbstractModel description]
     */
    function LsnAbstractModel() {
    }

    LsnAbstractModel.prototype.setData = function(data)
    {
      return lsnModelHelper.setData(this, data);
    };
    LsnAbstractModel.prototype.fillFromData = function(data) // tymczasowo zosawtione dla wstecznej kompatybilności
    {
      return lsnModelHelper.setData(this, data);
    };
    LsnAbstractModel.prototype.getData = function(additionalData, withAdditionalParams, params)
    {
      return lsnModelHelper.getData(this, additionalData, withAdditionalParams, params);
    };
    LsnAbstractModel.prototype.set = function(fieldName, value)
    {
      return lsnModelHelper.set(this, fieldName, value);
    };
    LsnAbstractModel.prototype.get = function(fieldName, modelToData, withAdditionalParams, params)
    {
      return lsnModelHelper.get(this, fieldName, modelToData, withAdditionalParams, params);
    };
    LsnAbstractModel.prototype.setAdditionalData = function(value, fieldName)
    {
      return lsnModelHelper.setAdditionalData(this, value, fieldName);
    };
    LsnAbstractModel.prototype.getAdditionalData = function(fieldName)
    {
      return lsnModelHelper.getAdditionalData(this, fieldName);
    };
    LsnAbstractModel.prototype.deleteAdditionalData = function(fieldName)
    {
      return lsnModelHelper.deleteAdditionalData(this, fieldName);
    };
    LsnAbstractModel.prototype.setDefaultAdditionalData = function()
    {
      return lsnModelHelper.setDefaultAdditionalData(this);
    };

    return LsnAbstractModel;
  }]);