angular.module('lsnBase.models')
.factory('LsnSalesProfileModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnSalesProfileModelV1 = function()
    {
      this.objectName = 'SalesProfile';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        subjectKeyString: {
          type: 'string'
        },
        symbol: {
          type: 'string'
        }
      };

      this.subjectKeyString = null; //  string  Identyfikator podmiotu sieci sprzedaży.
      this.symbol = null; //  string  Symbol profilu sprzedażowego.

    };

    LsnSalesProfileModelV1.prototype = LsnAbstractModel.prototype;

    return LsnSalesProfileModelV1;
  }]);