angular.module('lsnBase.availability')
/**
 *
 * obiekt opisujący dostępność usługi
 * @param {Object=} data dane wejściowe (klucze jak własności klasy)
 */
.factory('LsnAvailabilityModel', [function() {

    var AvailabilityModel = function(data)
    {
      data = data || {};
      /**
       * nazwa klasy serwisu (js/services/...)
       * @type string
       */
      this.serviceClassName = data.serviceClassName || null;
      /**
       * podZasób
       * @type string
       */
      this.subResource = data.subResource || null;
      /**
       * nazwa usługi (get, post etc.)
       * @type string
       */
      this.method = data.method || null;
      /**
       * czy aktywna
       * @type boolean
       */
      this.available = (typeof data.available === 'boolean') ? data.available : true;
    };

    return AvailabilityModel;
  }]);