angular.module('ihestiaCommonDirectives.uploaderV2')
  .controller('lsnCommonUploadV2Ctrl', ['$scope', 'lsnCommonUploadV2Helper', 'LsnCommonUploadV2Api', 'fileUpload',
    function($scope, lsnCommonUploadV2Helper, LsnCommonUploadV2Api, fileUpload) {
      var self = this;

      /**
       *
       */
      $scope.init = function() {
        self.loadOptions();
        self.addListeners();
        self.registerCallbacks();
      };

      /**
       *
       */
      this.loadOptions = function() {
        if (typeof $scope.options === 'undefined' || $scope.options === null) {
          $scope.options = {};
        }

        lsnCommonUploadV2Helper.mergeOptions($scope.options);

        $scope.internalApi = new LsnCommonUploadV2Api($scope.options);
        if(angular.isFunction($scope.getApi)) {
          $scope.getApi($scope.internalApi);
        }
      };

      /**
       *
       */
      this.registerCallbacks = function() {
        angular.forEach($scope.options.callback, function(callbackFn, eventCode) {
          $scope.internalApi.appendCallback(eventCode, callbackFn);
        });
      };

      /**
       * listens for all external uploader events and passes it further
       */
      this.addListeners = function() {
        angular.forEach(lsnCommonUploadV2Helper.uploaderEvents, function(eventCode) {
          $scope.$on(eventCode, function(e, data) {
            $scope.internalApi.uploaderEventCatched(eventCode, e, data);
          });
        });
      };

      /**
       *
       * @param file
       */
      $scope.deleteFile = function(file) {
        $scope.internalApi.deleteFile(file);
      };

      /**
       * For proper ng-uploader queue handling
       * @param queue
       */
      $scope.setQueue = function(queue) {
        $scope.internalApi.setQueue(queue);
      };

      /**
       *
       * @param file
       */
      $scope.fileClicked = function(file) {
        if (angular.isFunction($scope.options.callback.onFileDelete)) {
          $scope.options.callback.onFileDelete(file);
        }
      };

      var generateFileObject = function generateFileObjects(objects) {

        angular.forEach(objects, function(value, key) {
          if (typeof value !== 'undefined') {
            if (typeof $scope.queue[key] === 'undefined') {
              $scope.queue[key] = {};
            }
            $scope.queue[key].fileObject = value;
          }
        });

      };

      $scope.filequeue = fileUpload.fieldData.claimDetails;

      $scope.$watchCollection('filequeue', function(newval) {
        generateFileObject(newval);
      });
}]);
