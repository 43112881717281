angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorSearchMessagesCentralListCtrl', ['$scope', 'ikomunikatorSearchMessagesCentralListHelper', '$timeout', '$rootScope', '$state', 'ihestiaIkomunikatorNavigationHelper', 'ihestiaIkomunikatorAdvancedFilterHelper', 'ihestiaIkomunikatorSearchMessagesCentralHelper', 'iKomunikatorConstants',
    function($scope, ikomunikatorSearchMessagesCentralListHelper, $timeout, $rootScope, $state, ihestiaIkomunikatorNavigationHelper, ihestiaIkomunikatorAdvancedFilterHelper, ihestiaIkomunikatorSearchMessagesCentralHelper, iKomunikatorConstants) {
      $scope.constants = iKomunikatorConstants;

      $scope.init = function() {
        $scope.tplData = ikomunikatorSearchMessagesCentralListHelper.tplData;
        ikomunikatorSearchMessagesCentralListHelper.setPlaceholder();
        $scope.fireListeners();
      };

      $scope.fireListeners = function() {
        $scope.$on('checkTopReached', function(event, data) {
          if (!$scope.tplData.loading && data.reachedTop) {
            $timeout(function() {
              ikomunikatorSearchMessagesCentralListHelper.searchNext();
            }, 0);
          }
        });
      };

      $scope.searchRecipients = function() {
        if($scope.tplData.loading){
          $scope.tplData.reloadSearchAgain = true;
        }else{
          ikomunikatorSearchMessagesCentralListHelper.reloadRecipients();
        }
      };

      $scope.loadMessageForRecipient = function(recipient) {
        ihestiaIkomunikatorAdvancedFilterHelper.setFilterFromFtsSearch(recipient);
        ihestiaIkomunikatorSearchMessagesCentralHelper.hideModal();
        if ($state.current.name !== null && $state.current.name === ihestiaIkomunikatorNavigationHelper.fullListPanelState) {
          $rootScope.$broadcast('iKomunikator.reloadCentralFullListPanel');
        } else {
          $rootScope.$broadcast('iKomunikator.setShowModalFilterOnInit', false);
          $timeout(function() {
            ihestiaIkomunikatorNavigationHelper.goTo('fullListPanel', null, ihestiaIkomunikatorAdvancedFilterHelper.getFilter().getAttributeToGet());
          }, 0);        
        }
      };

      $scope.personTypeChanged = function(){
         $scope.tplData.searchFilters.phrase = '';
         /*eslint-disable camelcase */
         $scope.tplData.searchFilters.sort_by = null;
         $scope.tplData.searchFilters.sort_order = 'none';
         /*eslint-enable camelcase */
         $scope.tplData.ftsRecipients = [];
         ikomunikatorSearchMessagesCentralListHelper.setPlaceholder();
      };

      $scope.sortBy = function(columnName){
        ikomunikatorSearchMessagesCentralListHelper.sortBy(columnName);
      };

      $scope.$on('$destroy', function() {
        ikomunikatorSearchMessagesCentralListHelper.setClearData();
      });

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIkomunikatorSearchMessagesCentral', function() {
        if ($state.current.name !== null && $state.current.name === ihestiaIkomunikatorNavigationHelper.fullListPanelState) {
          $rootScope.$broadcast('iKomunikator.reloadCentralFullListPanel');
        }
      });

      $scope.init();
    }
  ]);