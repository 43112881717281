/**
*  Moduł do zebrania podstawowych zależności dla aplikacji bez logowania
*
*/
angular.module('ihestiaBasePublic', [
  'ui.router',
  'ihestiaSsoBase',
  'ihestiaAg',
  'ihestiaRestServicesBase',
  'ihestiaCrossTab',
  'ihestiaCommonDirectives',
  'lsnBase.istevenMultiSelectMerged',
  'ihestiaCommonDirectives.istevenMultiSelectTranslationsHelper',
  'ihestiaCommonDirectives.errorHandler',
  'ihestiaCommonDirectives.blockUi',
  'ihestiaCommonDirectives.filters',
  'ngAnimate',
  'lsnNg',
  'lsnBase',
  'lsn-base-templates-app',
  'uiRouterFreeze',
  'typeaheadDependencies',
  'lsnBase.modal'
]).config(['lsnModalBaseConfigProvider',
function(lsnModalBaseConfigProvider) {
  lsnModalBaseConfigProvider.defaultConfig.mainTemplateUrl = 'ihestia-commondirectives-template-app/lsnModal/lsnModalMainTemplate.tpl.html';
  lsnModalBaseConfigProvider.defaultConfig.okBtnName = ['okUcFirst', {componentCode: 'Public'}];
  lsnModalBaseConfigProvider.defaultConfig.cancelBtnName = ['cancelUcFirst', {componentCode: 'Public'}];
}]);
