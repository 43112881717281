angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminMessageMarkReadSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var AdminMessageMarkReadSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        var self = this;

        this.businessArea = 'ui-channel-messages';
        this.resource = 'system-message';
        this.subResource = 'mark-read';

        this.post = function(id, data, callback) {
          var url = self._getServiceUrl(id, self.subResource);
          return self.call(url, 'POST', data, callback);
        };

      };
      return new AdminMessageMarkReadSvc();
    }
  ]);