angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardCaseModelConstants', [
    function(){
    var constants = {
      STATUS_NEW: 'NEW',
      STATUS_CLOSED: 'CLOSED'
    };

    return constants;
  }])
  .factory('IhestiaDashboardCaseModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var CaseModel = function() {
      this.objectName = 'IhestiaDashboardCase';

      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        version: {
          type: 'int'
        },
        status: {
          type: 'string'
        },
        updateTime: {
          type: 'date'
        },
        attributes: {
          type: 'mixed'
        },
        object: {
          type: 'mixed'
        },
        operator: {
          type: 'mixed'
        },
        originator: {
          type: 'mixed'
        },
        tasks: {
          type: 'array',
          elementsType: 'IhestiaDashboardTask'
        },
        caseDefinitionId: {
          type: 'int'
        },
        creationTime: {
          type: 'date'
        },
        title: {
          type: 'string'
        },
        subtitle: {
          type: 'string'
        },
        description: {
          type: 'string'
        }
      };

      this.id = null;
      this.version = null;
      this.status = null;
      this.updateTime = null;
      this.attributes = {};
      this.object = null;
      this.operator = null;
      this.originator = null;
      this.tasks = [];
      this.caseDefinitionId = null;
      this.creationTime = null;
      this.title = null;
      this.subtitle = null;
      this.description = null;


    };


    CaseModel.prototype = IHestiaAbstractModel.prototype;

    return CaseModel;
  }]);