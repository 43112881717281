angular.module('lsnBase.models')
.factory('LsnMetaDataModelConstants', [function() {
    var MetaDataModelConstants = {};

    MetaDataModelConstants.DATA_SOURCE_MASTER = 'MASTER';
    MetaDataModelConstants.DATA_SOURCE_LOCAL = 'LOCAL';
    
    MetaDataModelConstants.DICTIONARY_DATA_SOURCE = {};
    MetaDataModelConstants.DICTIONARY_DATA_SOURCE[MetaDataModelConstants.DATA_SOURCE_MASTER] = 'MASTER';
    MetaDataModelConstants.DICTIONARY_DATA_SOURCE[MetaDataModelConstants.DATA_SOURCE_LOCAL] = 'LOCAL';

    return MetaDataModelConstants;
  }]);