/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .provider('iHestiaMenuLeftNavbarHelperV2', function() {
    var self = this;

    /**
     * Tryb działania, jeśli v1, to wszystko po staremu
     * dla wstecznej kompatybilności ustawione v1, najnowszy tryb to v2
     * @type {String}
     */
    this.mode = 'v1';

    /**
     * Ustawienia dyrektywy
     * @type {Object}
     */
    this.directiveSettings = {
      restrict: 'E',
      replace: true,
      controller: 'ihestiaMenuLeftNavbarV2Ctrl',
      templateUrl: 'ihestia-menu-template-app/leftNavBar/v2/menuLeftNavbar.tpl.html'
    };

    /**
     * opcje
     * @type {Object}
     */
    this.settings = {
      openLvl: 'none', // none|part|all  
      preventClose: false,
      hideCallback: lsnNg.noop
    };

    /**
     * Ukrywamy menu
     * @return {[type]} [description]
     */
    this.hide = function() {
      if (!self.settings.preventClose) {
        self.settings.openLvl = 'none';
      }
      self.settings.preventClose = false;
    };

    /**
     * Magic get
     * @return {Object}
     */
    this.$get = ['$document',
      function($document) {
        $document.on('click touchstart', function() {
          self.hide();
        });
        return self;
      }];
  });