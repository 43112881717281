angular.module('salesPath.outer')
  .service('ihestiaAvailabilityHelper', ['IHestiaAvailabilityModel', 'ihestiaConfigHelper', function(IHestiaAvailabilityModel, ihestiaConfigHelper){
    var AvailabilityHelper = function(){
        var self = this;

        this.servicesAvailabilities = [];

        this.init = function () {
          self.loadServicesAvailabilities();
        };

        /**
         * wczytuje informacje o dostepnosci uslug z configa
         */
        this.loadServicesAvailabilities = function () {
          self.servicesAvailabilities = [];

          var servicesAvailabilityConfig = ihestiaConfigHelper.get('availability', 'SERVICES');

          if (servicesAvailabilityConfig === null || !angular.isArray(servicesAvailabilityConfig)) {
            return;
          }
          $.each(servicesAvailabilityConfig, function (idx, srvAvData) {
            var serviceAvailability = new IHestiaAvailabilityModel(srvAvData);
            self.servicesAvailabilities.push(serviceAvailability);
          });

        };

        /**
         * zwraca informację o dostępności usługi REST
         * @param {string} serviceClassName nazwa klasy usługi
         * @param {string} method nazwa metody (post, get, etc.)
         * @param {string=} subResource nazwa podzasobu
         * @return {boolean} true jesli dostepna, false jesli nie
         */
        this.isServiceAvailable = function (serviceClassName, method, subResource) {
          var available = true;
          $.each(self.servicesAvailabilities, function (idx, serviceAvailability) {
            if (serviceAvailability.serviceClassName === serviceClassName &&
              (serviceAvailability.method !== null ? serviceAvailability.method === method : true) &&
              (serviceAvailability.subResource !== null ? serviceAvailability.subResource === subResource : true)
              ) {
              available = serviceAvailability.available;
              return false;
            }
          });
          return available;
        };

        /**
        * mapowanie prostych nazw usług do parametrów określających niedostępną usługę
        * @type Object tablica parametrów - 1 element to nazwa metody z IHSG.availabilityHelper, kolejne do argumenty przekazywane do tej metody
        */
       this.servicesSimpleNames = {
         'addressSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryAddresses', 'post', 'searches'],
         'personSearch': ['isServiceAvailable', 'IHSG.RESTService.ClientsPersons', 'post', 'searches'],
         'firstNameSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryFirstNames', 'post', 'searches'],
         'lastNameSearch': ['isServiceAvailable', 'IHSG.RESTService.DictionaryLastNames', 'post', 'searches'],
         'greenCards': ['isServiceAvailable', 'IHSG.RESTService.GreenCards', 'get'],
         'ceidgCaptcha': ['isServiceAvailable', 'IHSG.RESTService.CeidgCaptcha', 'post'],
         'ceidgSearch': ['isServiceAvailable', 'IHSG.RESTService.CeidgSearch', 'post'],
         'ceidgEntry': ['isServiceAvailable', 'IHSG.RESTService.CeidgEntry', 'get'],
         'banks': ['isServiceAvailable', 'IHSG.RESTService.DictionaryBanks', 'post', 'searches'],
         'nonEtxMark': ['isServiceAvailable', 'IHSG.RESTService.DictionaryMarks', 'post', 'searches'],
         'nonEtxModel': ['isServiceAvailable', 'IHSG.RESTService.DictionaryModels', 'post', 'searches'],
         'nonEtxType': ['isServiceAvailable', 'IHSG.RESTService.DictionaryTypes', 'post', 'searches']
       };

       /**
        * czy dany serwis zdefiniowany w swlf.servicesSimpleNames jest dostępny
        */
       this.isAvailable = function (name) {
         if (typeof self.servicesSimpleNames[name] === 'undefined') {
           return true;
         }
         return self[self.servicesSimpleNames[name][0]].apply(this, Array.prototype.slice.call(self.servicesSimpleNames[name].slice(1), 0));
       };

      };

      return new AvailabilityHelper();
  }]);