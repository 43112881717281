angular.module('salesPath2')
  .service('summaryClausesHelper', ['$rootScope', 'summaryHelper', 'CONSTANTS', 'mainDataContainer', 'actionHelper', 'sp2CommonHelper', 'appVariables',
    function($rootScope, summaryHelper, CONSTANTS, mainDataContainer, actionHelper, sp2CommonHelper, appVariables) {
      var SummaryClausesHelper = function() {
        var self = this;
        /**
         * podsumowanie klauzule
         * @type {Object}
         */

        this.policyId = '';
        this.clauses = '';

        //ustawienia modali
        this.modals = {};

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.summaryClausesModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.summaryClausesModal.title',
            size: 'md',
            okBtnName: 'sp2.modal.okBtnName',
            okBtnDisabled: appVariables.readOnly
          }
        };

        /**
         * aktualizuje domyślne ustawienia dla modali na podstawie informacji o trybie readOnly
         */
        this.updateSettingsByReadOnly = function() {
          self.modals.summaryClausesModal.settings.okBtnDisabled = appVariables.readOnly;
        };

        /**
         * Pobranie danych klauzul i wyświetlenie modala
         */
        this.showClausesModal = function(policy) {
          policy = policy.getData();
          var policyClauses = summaryHelper.getPolicyExtraData(policy, CONSTANTS.POLICY_EXTRA_CLAUSES);

          self.clauses = policyClauses;
          self.policyId = policy.number;

          self.justShowClausesModal();
        };

        //wlasciwe wyswietlenie modala klauzul
        this.justShowClausesModal = function(){
          self.modals.summaryClausesModal.settings.okBtnName = 'sp2.modal.okBtnName';
          self.modals.summaryClausesModal.settings.cancelBtnName = 'sp2.modal.cancel';

          if(appVariables.readOnly || appVariables.isSupplement){
            self.modals.summaryClausesModal.settings.okBtnName = 'sp2.modal.close';
            self.modals.summaryClausesModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('summaryClausesModal');
        };

        /**
         * Pobranie szczegółów płatności
         */
        this.getClauses = function() {
          return self.clauses;
        };


        /**
         * Pobranie id polisy
         */
        this.getPolicyId = function() {
          return self.policyId;
        };


        /**
         * Zapisanie szczegółów płatności polisy
         */
        this.saveClauses = function(clauses, policyId) {
          var policy = {};
          angular.forEach(mainDataContainer.policies, function(val) {
            if (val.number === policyId) {
              policy = val;
            }
          });

          policy.product.set('dynamicValues', {
            additionalClause: clauses
          });

          actionHelper.runAction('clausesEdited');
          actionHelper.runAction('saveApplication');
        };

      };

      return new SummaryClausesHelper();
    }
  ]);