angular.module('ihestiaGrid')
  .controller('ihestiaGridModalDetailsCtrl', ['$scope',
    function($scope) {
      $scope.detailsOrderByField = null;
      $scope.detailsReverseSort = false;

      $scope.changeDetailsOrderBy = function(orderBy)
      {
        if($scope.detailsOrderByField === orderBy)
        {
          $scope.detailsReverseSort = !$scope.detailsReverseSort;
        }
        else
        {
          $scope.detailsReverseSort = true;
        }
        $scope.detailsOrderByField = orderBy;
      };
    }
  ]);