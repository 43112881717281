//trzeba pomyslec jak czyscic te listy
angular.module('ihestiaCommonDirectives')
  .service('ihestiaAutoUploadOneListUploadHelper', ['IhestiaOneListUploadHelper', '$rootScope', '$filter', '$timeout',
    function(IhestiaOneListUploadHelper, $rootScope, $filter, $timeout) {
      var AutoUploadOneListUploadHelper = function() {
        IhestiaOneListUploadHelper.apply(this, arguments);
        var self = this;

        var parentMethod = {
          init: this.init,
          onAddFile: this.onAddFile,
          proceedUploadingFile: this.proceedUploadingFile
        };
        this.init = function(scopeName, callbacks, uplodedFiles, config) {
          if (!angular.isObject(config)) {
            config = {};
          }
          if (angular.isUndefined(config.showBlockUi)) {
            config.showBlockUi = true;
          }
          config.autoUpload = true;


          parentMethod.init(scopeName, callbacks, uplodedFiles, config);
        };

        this.onAddFile = function(e, data) {
          //czekamy az wszystkie walidacje biblioteki sie odbeda
          $timeout(function() {
            if (angular.isObject(data.files)) {
              var areErrors = false;
              angular.forEach(data.files, function(file) {
                if (angular.isArray(file.error) && file.error.length > 0) {
                  areErrors = true;
                }
              });

              if (self.data[data.scope.name].config.showBlockUi && !areErrors) {
                $rootScope.$emit('showBlockUi', {
                  label: $filter('translate')('sending', {
                    componentCode: 'Public'
                  })
                });
              }

              parentMethod.onAddFile(e, data);
            }
          }, 100);

        };

        this.proceedUploadingFile = function(scopeName) {
          parentMethod.proceedUploadingFile(scopeName);
          if (self.allFilesSended(scopeName) && self.data[scopeName].config.showBlockUi) {
            $rootScope.$emit('hideBlockUi');
          }
        };

      };

      return new AutoUploadOneListUploadHelper();
    }
  ]);