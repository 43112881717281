angular.module('lsnBase.models')
  .service('strUpperCaseModelFilter', [function(){
    return function(val)
    {
      if (!angular.isString(val))
      {
        return val;
      }
      return val.toUpperCase();
    };
  }]);