angular.module('ihestiaCommonDirectives')
  .factory('ToSendFileModel', ['ihestiaUploadModelHelper', 'addMd5ToFile', function(ihestiaUploadModelHelper, addMd5ToFile) {

    var FileModel = function() {
      var self = this;
      /**
       * Fizyczny plik do wysłania
       * @type {FILE}
       */
      this.file = null;

      /**
       * Data utworzenia komentarza
       * @type {Number} @todo raczej powinna być data
       */
      this.errorText = '';

      /**
       * status przesłanego pliku
       * @type {String}
       */
      this.status = null;

      /**
       * name nazwa pliku
       * @type {String}
       */
      this.name = null;

      /**
       * size rozmiar pliku
       * @type {String}
       */
      this.size = null;

      /**
       * id pliku na serwerze
       * @type {String}
       */
      this.fileServerId = null;

      this.sendingInfo = false;

      this.earlierUploadedFile = false;

      /**
       * nazwa typu dokumentu
       */
      this.documentTypeName = null;

      this.setFileData = function(fileData) {
        addMd5ToFile(fileData);
        self.file = fileData;
        if (angular.isObject(fileData)) {
          self.name = fileData.name;
          self.size = parseInt(fileData.size, 10);
          self.type = fileData.type;
        }
      };

      this.setDocumentType = function(documentType) {
        ihestiaUploadModelHelper.setDocumentType(self.file, documentType);

        if (angular.isObject(documentType)) {
          this.documentTypeName = documentType.name;
        }
      };

      this.inSendingInfo = function() {
        return self.sendingInfo;
      };

      /**
       * funkcja zwraca informacje o pliku do wysłania do usługi
       * która sprawdza poprawność atrybutów wysyłąnego pliku
       * tuż przed jego wysłaniem
       */
      this.getInfoAboutFile = function() {
        return ihestiaUploadModelHelper.getInfoAboutFile(self.file);
      };
    };
    return FileModel;
  }]);