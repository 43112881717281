angular.module('ihestiaRestServicesBase')
  .factory('ihestiaOssAssociationsAttributesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {
      var IhestiaOssAssociationsAttributesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'associations/attributes';
        this.businessArea = 'oss';
        this.sortBy = 'symbol';
        this.pageStyle = false;
      };

      return new IhestiaOssAssociationsAttributesSvc();
    }]);