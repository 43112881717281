/**
 * validacja czy długość jest poprawna
 */
angular.module('lsnBase.validators')
        .directive('ihestiaMinLength', function() {
          return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {

              var minLength = parseInt(attrs.ihestiaMinLength, 10);

              var setValidity = function(value) {
                if(typeof value === 'undefined' || value === null){
                  ngModel.$setValidity('minLength', true);
                }else{
                  var valid = value.length >= minLength;
                  ngModel.$setValidity('minLength', valid);
                }

                return value;
              };

              setValidity(element.val());

              // For DOM -> model validation
              ngModel.$parsers.unshift(setValidity);

              // For model -> DOM validation
              ngModel.$formatters.unshift(setValidity);
            }
          };
        });