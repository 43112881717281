angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryEurotaxSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryEurotaxSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        var self = this;

        this.resource = 'eurotax';

        /**
         * Parametry korekty wartości pojazdu
         * @type {Object}
         */
        var ADJUSTMENTS_PARAMS = {
          VehicleInitialValue: null, // bazowa wartość pojazdu
          IsGazCorrection: false, //czy instalacja gazowa
          IsConditionCorrection: false, //czy korekta na stan pojazdu
          IsPrivateImportCorrection: false, //czy import prywatny
          IsVehicleAfterAccident: false, //czy powypadkowy
          IsSteeringWheelChangedLocation: false, //czy przełożona kierownica
          IsBodyTypeCorrection: false, //pickup itd.
          IsOwnCorrection: false, //czy własna korekta
          IsSpecialBodyTypeCorrection: false, //czy korekta na zabudowę (chłodnia/..)
          VehicleConditionCorrectionOption: 0, //stan pojazdu 1|Stan bardzo dobry;2|Stan dobry;3|Stan przeciętny;4|Stan zły;5|Test warsztatowy;
          LPGYear: null, //jeśli IsGazCorrection, to rok montażu lpg
          CarBodyTypeCorrectionOption: null, //IsBodyTypeCorrection
          SpecialCarBodyTypeCorrectionOption: null, //IsSpecialBodyTypeCorrection BUS|Bus;CAB|Kabriolet;CHL|Podwozie, zab. chłodnia;CPE|Coupe;FDA|Furgon, wysokidach;FPO|Furgon, max. wysoki dach;FUR|Furgon;H.T|Hardtop;HTB|Hatchback;IZO|Podwozie, zab. izoterma;KDA|Kombi, wysoki dach;KOM|Kombi;KON|Podwozie, zab. kontener;KPO|Kombi, max. wysoki dach;LIM|Limuzyna;PDD|Podwozie, kabina podwójna;PIC|Pickup;POD|Podwozie, kabina krótka;POK|Podwozie, kabina sypialna;POW|Podwozie, kabina krótka;ROA|Roadster;S.T|Soft Top;SED|Sedan;SIK|C. siodłowy, kab.sypialna;SIO|C. siodłowy, kab. dzienna;SIW|C. siodłowy, kab. dzienna;SKD|Skrzyniowy, kab. sypialna;SKR|Skrzyniowy, kabina krótka;SPK|Skrzyniowy, kab. podwójna;TAR|Targa;TOS|Towos;VAN|Van;WPK|Wywrotka, kab. podwójna;WWW|Wywrotka;WYD|Podwozie z zabierakiem;WYW|Wywrotka;
          OwnCorrectionValue: null, //jeśli IsOwnCorrection, to wartość korekty (decimal)
          Id: null, //etxId
          RegistrationDate: null, // data rejestracji pojazdu
          Run: null //przebieg
        };

        /**
         * Zwracamy dostępne parametry do korekty wartości pojazdu
         * @return {Object}
         */
        this.getDefaultAdjustmentsParams = function() {
          return angular.extend({}, ADJUSTMENTS_PARAMS);
        };

        this.initSearch = function(returnProperty, searchParams, httpParams) {
          var defaultSearchParams = {
            pageSize: 5000,
            sortBy: 'typeCode',
            sortDirection: 'ASC',
            top: 0,
            parameter: {
              product: 'kdir'
            }
          };

          var searchExtended = angular.extend({}, defaultSearchParams, searchParams);

          return self.post('searches', searchExtended, returnProperty, null, null, httpParams);
        };

        this.searchNextPage = function(returnProperty, id, pageNo) {
          return self.get('searches/' + returnProperty + '/' + id + '/' + pageNo);
        };

        /**
         * zwraca wycene pojazdu wg. klasyfikacji eurotax
         *
         * @param {Integer} id
         * @param {undefined|Object} adjustmentsParams parametry korekty
         * @param {undefined|Object} httpParams
         * @returns {*}
         */
        this.getValuation = function(id, adjustmentsParams, httpParams) {
          if (adjustmentsParams) {
            return self.post(id, adjustmentsParams, 'valuation', null, null, httpParams);
          } else {
            return self.get(id, null, 'valuation', null, null, httpParams);
          }
        };

      };
      return new RestDictionaryEurotaxSvc();
    }
  ]);