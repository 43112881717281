angular.module('ihestiaWidgets.policyTermination')
  .controller('policyTerminationStep1Ctrl', ['$scope', '$state', '$stateParams', 'ihestiaConfigHelper', 'resolvedPolicy', 'policiesSvc', 'policyOperationsSvc', 'terminationType', 'PolicyTerminationConstants', 'resolvedTerminationCauses', 'customerPoliciesSvc', 'ihestiaSsoBaseInfoHelper', '$filter', 'customerEstatesSvc', 'iHestiaCommonModalHelper', 'resolvedApplication', 'resolvedParams', 'policyTerminationHelper', 'policyOperationsHelper', 'resolvedFileAttributes',
    function($scope, $state, $stateParams, ihestiaConfigHelper, resolvedPolicy, policiesSvc, policyOperationsSvc, terminationType, PolicyTerminationConstants, resolvedTerminationCauses, customerPoliciesSvc, ihestiaSsoBaseInfoHelper, $filter, customerEstatesSvc, iHestiaCommonModalHelper, resolvedApplication, resolvedParams, policyTerminationHelper, policyOperationsHelper, resolvedFileAttributes) {

      $scope.tplData = {
        isLife: policyOperationsHelper.isLife()
      };
      $scope.otherData = {
        saveErrors: [],
        saveErrorsCodes: {}, //tu odkładamy kody błędów, później na ich podstawie jest specjalna obsługa
        onlyWarnings: false
      };

      $scope.invalidOperation = !$scope.tplData.isLife && _.get(resolvedTerminationCauses, 'status') === 404;
      if ($scope.invalidOperation || $scope.tplData.isLife) {
        $scope.$emit('hideBlockUi');
      }

      if (resolvedPolicy && resolvedPolicy.data) {
        $scope.policy = resolvedPolicy.data;
      } else {
        $scope.policy = null;
      }

      $scope.terminationCauses = [];
      $scope.estates = [];
      $scope.estateListDisabled = false;
      $scope.formData = {};
      $scope.calendarDateRange = {};

      $scope.applicationLoaded = false;

      $scope.viewParams = resolvedParams;

      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      if (resolvedApplication) {
        $scope.estateListDisabled = true;
        $scope.applicationLoaded = true;
        $scope.terminationData = resolvedApplication.data;
        $scope.formData.terminationCause = $scope.terminationData.terminationCause;
        if ($scope.terminationData.operationDate) {
          $scope.formData.operationDate = $filter('date')($scope.terminationData.operationDate, 'yyyy-MM-dd');
        }

        //dla wczytanego wniosku zakres dat mamy na wniosku, a nie z przyczyny
        if ($scope.terminationData.minAvailableOperationDate) {
          $scope.calendarDateRange.min = $scope.terminationData.minAvailableOperationDate;
        }
        if ($scope.terminationData.maxAvailableOperationDate) {
          $scope.calendarDateRange.max = $scope.terminationData.maxAvailableOperationDate;
        }
      } else {
        $scope.terminationData = null;
      }

      if ($scope.viewParams.vehicleSale) {
        //sprzedaż/darowizna pojazdu
        $scope.formData.terminationCause = $scope.viewParams.ownerChangeType;
        $scope.otherData.ownerChangeType = $scope.viewParams.ownerChangeType;

        if ($scope.policy) {
          $scope.calendarDateRange.min = new XDate($scope.policy.start).toString('yyyy-MM-dd');

          var policyEndDateMinus = new XDate($scope.policy.end).addDays(-1);
          var todayDate = new XDate($scope.today);
          if (policyEndDateMinus.diffDays(todayDate) > 0) {
            //dzisiejsza data jest późniejsza
            $scope.calendarDateRange.max = policyEndDateMinus.toString('yyyy-MM-dd');
          } else {
            $scope.calendarDateRange.max = todayDate.toString('yyyy-MM-dd');
          }
        }
      }


      $scope.confirmModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['Public.confirmation', {componentCode: 'Public'}],
        okBtnName: ['Public.submit', {componentCode: 'Public'}],
        cancelBtnName: ['Public.close', {componentCode: 'Public'}],
        size: 'sm'
      };

      $scope.form = {
        terminationStep1Form: null
      };

      $scope.TYPES = PolicyTerminationConstants.TYPES;
      $scope.CAUSE_CODES = PolicyTerminationConstants.CAUSE_CODES;

      $scope.formSubmitted = false;

      $scope.policyDescription = $stateParams.policyNumber;
      $scope.policyNumber = $stateParams.policyNumber;

      if (!$stateParams.policyNumber && $scope.terminationData && $scope.terminationData.policyNumber) {
        $scope.policyDescription = $scope.terminationData.policyNumber;
        $scope.policyNumber = $scope.terminationData.policyNumber;
      }

      $scope.objectDescription = '';

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();

      $scope.dataRequired = true;
      if ($scope.currentUser.character.type === 'Central') {
        $scope.dataRequired = false;
      }

      //upload plików
      if (resolvedFileAttributes) {
        $scope.documentType = resolvedFileAttributes.data;
      }

      /**
       * @typedef PolicyTerminationEhlData
       * @property {boolean | null} isFormValid?
       * @property {object[] | null} fileModels?
       * @property {string | null} comment?
       * @property {string | null} operationDate?
       * @property {string | null} terminationCause?
       */
      /**
       * Data passed from policyTerminationEhl Directive to forward() method
       * @type PolicyTerminationEhlData
       */
      $scope.ehlFormData = {
        isFormValid: null,
        fileModels: null,
        comment: null,
        operationDate: null
      };
      $scope.stateParams = null;

      $scope.init = function() {
        $scope.terminationResource = $scope.getTerminationResource($scope.policyNumber);
        if (!$scope.tplData.isLife) {
          if ($stateParams.operationDate && !$scope.formData.operationDate) {
            $scope.formData.operationDate = $stateParams.operationDate;
          }
          if ($stateParams.terminationCause && !$scope.formData.terminationCause) {
            $scope.formData.terminationCause = $stateParams.terminationCause;
          }
          if ($stateParams.ehNumber) {
            $scope.formData.ehNumber = $stateParams.ehNumber;
          }

          $scope.loadTerminationCauses();
          if (terminationType === $scope.TYPES.ESTATE) {
            $scope.loadEstatesList($scope.policyNumber);
          } else {
            $scope.tryLoadShortPolicy();
          }
        }
        $scope.stateParams = $stateParams;
      };

      $scope.terminationType = terminationType;

      $scope.tryLoadShortPolicy = function() {
        if ($scope.appContextData && $scope.appContextData.customerId) {
          //jakbyśmy kiedyś mieli coś odpalać poza ikontem
          var data = {
            id: $scope.appContextData.customerId,
            dynamicFilters: {
              policyNumberFrom: $scope.policyNumber,
              policyNumberTo: $scope.policyNumber
            }
          };
          customerPoliciesSvc.findByParams(data).then(function(response) {
            if (response.data.items.length === 1 && response.data.items[0].objectName) {
              $scope.policyDescription = $scope.policyNumber + ', ' + response.data.items[0].objectName;
              $scope.objectDescription = response.data.items[0].objectName;
            }
            $scope.$emit('hideBlockUi');
          }, function() {
            $scope.$emit('hideBlockUi');
          });
        } else {
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.loadTerminationCauses = function() {
        if (resolvedTerminationCauses && resolvedTerminationCauses.data) {
          angular.forEach(resolvedTerminationCauses.data, function(cause) {
            if ($stateParams.applicationId) {
              //tu mamy dane ze słownika
              $scope.terminationCauses.push({
                code: cause.code,
                description: cause.name
              });
            } else {
              //a tu z dedykowanej usługi w kontekście polisy
              $scope.terminationCauses.push({
                code: cause.policyOperationCauseCode,
                description: cause.policyOperationCauseName
              });
            }
          });
        }
      };


      /**
       * Zakres dat możliwych do wyboru w kalendarzu ustawiamy w zależności
       * od wybranej przyczyny rozwiązania polisy
       */
      $scope.reloadTerminationCauseList = function() {
        if (angular.isDefined($scope.formData.terminationCause) && resolvedTerminationCauses.data) {
          angular.forEach(resolvedTerminationCauses.data, function(cause) {
            if (cause.policyOperationCauseCode === $scope.formData.terminationCause) {
              $scope.calendarDateRange.min = cause.availableDateFrom;
              $scope.calendarDateRange.max = cause.availableDateTo;
            }
          });
        } else {
          $scope.calendarDateRange = {};
        }
      };

      $scope.estateChanged = function() {
        $scope.calendarDateRange = {};
        if ($scope.formData.selectedEstate) {
          if ($scope.formData.selectedEstate.availableDateFrom) {
            $scope.calendarDateRange.min = $scope.formData.selectedEstate.availableDateFrom;
          }
          if ($scope.formData.selectedEstate.availableDateTo) {
            $scope.calendarDateRange.max = $scope.formData.selectedEstate.availableDateTo;
          }
        }
      };


      $scope.prepareEstatesList = function() {
        $scope.estates = [];
        if (resolvedPolicy) {
          angular.forEach(resolvedPolicy.data.estates, function(policyEstate) {
            $scope.estates.push({
              objectId: policyEstate.metaData.id,
              policyNumber: $scope.policyNumber,
              description: $scope.getEstateDescription(policyEstate),
              availableDateFrom: policyEstate.availableDateFrom,
              availableDateTo: policyEstate.availableDateTo
            });
          });

          // jeśli tylko jedna nieruchomość, to od razu ją zaznaczamy
          // https://ebok.atena.pl/browse/IHESTIAPRO-1153
          if ($scope.estates.length === 1) {
            $scope.estateListDisabled = true;
            $scope.formData.selectedEstate = $scope.estates[0];
            $scope.estateChanged();
          }
        }
      };

      $scope.getEstateDescription = function(estate) {
        var description = estate.address.streetPrefix + ' ' + estate.address.street + ' ' + $filter('addressStreetNumber')(estate.address);
        description = description + ', ' + estate.address.postalCode + ' ' + estate.address.city;
        return description;
      };

      $scope.back = function() {
        policyOperationsHelper.end($scope.policyNumber, $scope.stateParams.cancelState || null);
      };

      $scope.parseErrors = function() {
        $scope.otherData.saveErrorsCodes = {};
        angular.forEach($scope.otherData.saveErrors, function(error) {
          if (error.code) {
            $scope.otherData.saveErrorsCodes[error.code] = true;
          }
        });
      };


      /**
       * klikniecię przycisku "dalej"
       * @param {boolean} force
       * @param {PolicyTerminationEhlData | null} ehlData
       */
      $scope.forward = function(force, ehlData) {
        $scope.formSubmitted = true;
        // cache directive form data
        $scope.ehlFormData = ehlData || $scope.ehlFormData;

        // jeśli brak błędów, to odpytujemy usługę
        if (($scope.form.terminationStep1Form && $scope.form.terminationStep1Form.$valid) || $scope.ehlFormData.isFormValid) {
          $scope.$emit('showBlockUi');
          $scope.otherData.saveErrors = [];
          $scope.otherData.saveErrorsCodes = {};
          if ($scope.terminationData === null) {
            var data = $scope.tplData.isLife ? $scope.mapTerminationRequestEhl(force) : $scope.mapNewApplicationRequest(force, terminationType);
            var svcObject = policyOperationsHelper.getPolicyOperationsSvc();
            //zapis nowego wniosku
            svcObject.post($scope.terminationResource, data, null, function(response) {
                return $scope.saveSuccess(response, data);
              }, $scope.saveError,
              {
                allowedStatuses: [409, 404]
              });
          } else {
            // update istniejącego wniosku
            $scope.updateExistingApplication(force);
          }
        }
      };

      $scope.ineffectiveWithdrawal = function(response) {
          var ineffectiveWithdrawal = false;
          if(response.data && response.data.messages) {
            angular.forEach(response.data.messages, function(message) {
              if(message.code === 'IneffectiveWithdrawal') {
                ineffectiveWithdrawal = true;
              }
            });
          }
          return ineffectiveWithdrawal;
      };

      $scope.saveSuccess = function(response, requestData) {
        if ($scope.tplData.isLife || ($scope.ineffectiveWithdrawal(response) && !$scope.viewParams.policyExtension)) //w rozszerzeniach jest drugi krok bez symulacji
        {
          //zakończenie przez Centralę
          policyTerminationHelper.summary = response.data.messages;
          policyTerminationHelper.policyNumber = $scope.policyNumber;

          var terminationCauseDesc;
          if ($scope.formData.terminationCause) //bierzemy tytuł Przyczyny
          {
            angular.forEach($scope.terminationCauses, function(terminationCause) {
              if (terminationCause.code === $scope.formData.terminationCause) {
                terminationCauseDesc = terminationCause.description;
              }
            });
          }
          // for Life, page title is set in policyTerminationEhlCtrl
          if ($scope.tplData.isLife) {
            policyTerminationHelper.causeCode = requestData.operCauseCode;
          } else {
            if (terminationCauseDesc) {
              policyTerminationHelper.titleLabel = null;
              policyTerminationHelper.title = terminationCauseDesc;
            } else {
              policyTerminationHelper.titleLabel = $state.$current.data.pageTitleLabel;
              policyTerminationHelper.title = $state.$current.data.pageTitle;
            }
          }
          // if custom success state was defined, we pass it to summary
          $state.go('main.policyTerminationSummary', $scope.stateParams.successState
            ? {
              successState: $scope.stateParams.successState
            } : null);
        } else {
          var terminationType = $filter('lcFirst')(response.data.terminationType);
          var terminationCause = $filter('lcFirst')(response.data.terminationCause);
          var params = {applicationId: response.data.metaData.id};
          if ($scope.viewParams.policyExtension) {
            //w rozszerzeniach mamy tylko Odstąpienie
            policyTerminationHelper.storedApplicationData = {data: requestData};
            params.applicationId = 1;
            $state.go('main.policyExtensionTerminationStep2.withdrawal', params);
          } else {
            if (terminationType === $scope.TYPES.ESTATE) {
              if ($scope.policyNumber) {
                params.policyNumber = $scope.policyNumber;
              }
              $state.go('main.policyTerminationStep2.estateSale', params);
            } else if (terminationType === $scope.TYPES.DAD) {
              if (terminationCause === 'vehicleDismantling') {
                $state.go('main.policyTerminationStep2.dismantling', params);
              } else {
                $state.go('main.policyTerminationStep2.deregistration', params);
              }
            } else if (terminationType === $scope.TYPES.WITHDRAWAL || terminationType === $scope.TYPES.NOTICE) {
              $state.go('main.policyTerminationStep2.' + terminationCause, params);
            }
          }
        }
      };

      $scope.parseErrors = function() {
        $scope.otherData.saveErrorsCodes = {};
        angular.forEach($scope.otherData.saveErrors, function(error) {
          if (error.code) {
            $scope.otherData.saveErrorsCodes[error.code] = true;
          }
        });
      };

      $scope.saveError = function(reject) {
        if (reject.data && angular.isArray(reject.data)) {
          $scope.otherData.saveErrors = reject.data;
          $scope.parseErrors();
        }
        if (reject.data && reject.data.messages) {
          $scope.otherData.saveErrors = reject.data.messages;
          $scope.parseErrors();
        }
        if ($scope.currentUser.character.type === 'Central' && $scope.onlyWarnings()) {
          $scope.showConfirmModal();
        }
        $scope.$emit('hideBlockUi');
      };

      $scope.onlyWarnings = function() {
        var onlyWarnings;
        if ($scope.otherData.saveErrors.length > 0) {
          onlyWarnings = true;
        } else {
          onlyWarnings = false; //nie ma nic
        }
        angular.forEach($scope.otherData.saveErrors, function(error) {
          if (error.type !== 'WARN') {
            onlyWarnings = false;
          }
        });
        $scope.otherData.onlyWarnings = onlyWarnings;
        return onlyWarnings;
      };

      $scope.operationDateChanged = function() {
        if (terminationType === $scope.TYPES.ESTATE && !$scope.policyNumber) //dla sprzedaży nieruchomości i konkretnej polisy nie ruszamy listy
        {
          $scope.loadEstatesList();
        }
      };

      $scope.loadEstatesList = function(policyNumber) {
        if ($scope.appContextData && $scope.appContextData.customerId) {
          //jakbyśmy kiedyś mieli coś odpalać poza ikontem
          var data = {
            id: $scope.appContextData.customerId,
            top: 200,
            pageSize: 200,
            query: {}
          };
          if ($scope.formData.operationDate) {
            var date = new XDate($scope.formData.operationDate);
            if (!isNaN(date.getTime())) {
              data.query.operationDate = date.toString('i');
            }
          }
          $scope.$emit('showBlockUi');
          $scope.estates = [];
          $scope.loadingEstatesInProgress = true;
          customerEstatesSvc.findByParams(data).then(function(response) {
            angular.forEach(response.data.items, function(policyEstate) {
              if (!policyNumber || policyNumber === policyEstate.policyNumber) //sami filtrujemy po numerze polisy
              {
                var estate = {
                  objectId: policyEstate.objectId,
                  policyNumber: policyEstate.policyNumber,
                  availableDateFrom: policyEstate.availableDateFrom,
                  availableDateTo: policyEstate.availableDateTo
                };
                if (policyNumber) {
                  estate.description = policyEstate.propertyDescription; //jak weszliśmy z konkretnej polisy to prezentujemy bez numeru polisy
                } else {
                  estate.description = policyEstate.policyNumber + ', ' + policyEstate.propertyDescription;
                }
                $scope.estates.push(estate);
              }
            });

            if ($scope.estates.length === 1) {
              $scope.formData.selectedEstate = $scope.estates[0];
              $scope.estateChanged();
            }
            $scope.loadingEstatesInProgress = false;
            $scope.$emit('hideBlockUi');
          }, function() {
            $scope.loadingEstatesInProgress = false;
            $scope.$emit('hideBlockUi');
          });
        } else {
          if ($scope.policyNumber) {
            //poza ikontem bierzemy nieruchomości z polisy
            $scope.prepareEstatesList();
          }
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.showConfirmModal = function() {
        iHestiaCommonModalHelper.showModal('policyTerminationConfirmModal');
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.policyTerminationConfirmModal', function() {
        $scope.forward(true, null);
      });

      /**
       *
       * @param {boolean} force
       * @param {string} terminationTypeData
       * @returns {*}
       */
      $scope.mapNewApplicationRequest = function(force, terminationTypeData) {
        var data = angular.copy($scope.formData);
        if (terminationTypeData === $scope.TYPES.ESTATE) {
          data.policyNumber = data.selectedEstate.policyNumber;
          data.objectId = data.selectedEstate.objectId;
        } else {
          data.policyNumber = $scope.policyNumber;
        }
        data.terminationType = terminationTypeData;
        if (force) {
          data.canTerminateWithWarnings = true;
        }
        return data;
      };

      /**
       *
       * @param {boolean} force
       * @returns {{comment: string, ignoreValidation: boolean, operCauseCode: string, uploadedDocumentsIds: string[]}}
       */
      $scope.mapTerminationRequestEhl = function(force) {
        var operCauseCode = policyOperationsHelper.getOperCauseCodeFromTerminationType(terminationType, $scope.ehlFormData.terminationCause);
        return policyOperationsHelper.mapTerminationRequestEhl(operCauseCode, force, _.pick($scope.ehlFormData, ['comment', 'fileModels', 'operationDate']));
      };

      $scope.getTerminationResource = function(policyNumber) {
        if ($scope.tplData.isLife) {
          return policyNumber + '/terminations';
        } else {
          return $scope.viewParams.policyExtension ? 'extensionTermination' : 'termination';
        }
      };

      /**
       * Runs in forward() method if existing application data is defined
       * @param {boolean} force
       */
      $scope.updateExistingApplication = function(force) {
        var applicationData = angular.copy($scope.terminationData);
        if (force) {
          applicationData.canTerminateWithWarnings = true;
        }

        if ($scope.formData.operationDate) {
          var date = new XDate($scope.formData.operationDate);
          if (!isNaN(date.getTime())) {
            applicationData.operationDate = date.toString('i');
          }
        } else {
          applicationData.operationDate = null;
        }

        applicationData.isConfirmation = false;
        policyOperationsSvc.put($scope.terminationResource + '/' + $scope.terminationData.metaData.id, applicationData, null, function(response) {
            return $scope.saveSuccess(response, applicationData);
          }, $scope.saveError,
          {
            allowedStatuses: [409, 404]
          });
      };

      if (!$scope.invalidOperation) {
        $scope.init();
      }

    }
  ]);
