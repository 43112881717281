/**
 * Mapper BOS messages
 */
angular.module('salesPath2').service('salesPath2BosErrorMapper', ['CONSTANTS', 'MapperUtils', 'ihestiaStrpos', 'SPD',
  function(CONSTANTS, MapperUtils, ihestiaStrpos, SPD) {
    var Mapper = function() {
      var self = this;

      this.codeMap = {
        100159: 'Policy',
        100313: 'Payment',
        100316: 'BonusMalus',
        100353: 'BonusMalus',
        100368: 'CommunicationInsured',
        100439: 'DiscountDeviatedPremium',
        100531: 'Payment', //raty
        100532: 'Payment', //raty
        100707: 'Discount',
        100705: 'Discount',
        200014: 'DiscountDeviatedPremium',
        200063: 'PromoCodes',
        200256: 'PromoCodes',
        200074: 'Policy',
        200095: 'Payment',
        200096: 'Payment',
        200097: 'Payment',
        200098: 'Payment',
        200099: 'Payment',
        200130: 'Payment',
        200169: 'Insurer',
        200156: 'Payment',
        200147: 'AuthorizationExceeded' //Wznowienie z wyprzedzeniem powyżej 90 dni wymaga Zgody Centrali.
      };

      /**
       * Zwraca parametry do errorClickManagera
       * @param  {[type]} error [description]
       * @return {[type]}       [description]
       */
      this.getParams = function(error) {
        var params = self.getRiskParams(error),
          mapObjectType = self.codeMap[error.code];

        // objectType z mapy jest wazniejszy
        if (angular.isDefined(mapObjectType)) {
          params.objectType = mapObjectType;
        }

        // jesli nie udalo nam sie ustalic typu, to zwracamy false
        if (angular.isUndefined(params.objectType)) {
          return false;
        }

        // sprawdzamy, czy mamy dodatkowa metode dla tego typu obiektu
        var handleFunc = 'handle' + self.codeMap[error.code];
        if (angular.isFunction(self[handleFunc])) {
          params = angular.extend(params, self[handleFunc](error));
        }

        return params;
      };

      /**
       * Wyciaga obiekt z parametrami z opisu ryzyka BOS
       * np. z 'Product.Risks[E7_OC].Vehicle[28746431]'
       * @param  {Object} error [description]
       * @return {Object}       [description]
       */
      this.getRiskParams = function(error) {
        // sprawdzamy, czy mamy fajne parametry
        if (angular.isUndefined(error.attributes.objects) || !angular.isArray(error.attributes.objects) || error.attributes.objects.length === 0) {
          return false;
        }
        // wyciagamy parametry z BOSa
        var params = error.attributes.objects[0].split('.'),
          riskParams = {};
        // wlasciwe przeszukiwanie
        angular.forEach(params, function(riskParam, idx) {
          var objId, // (string) potencjalne id obiektu
            parts; // (array) to co na wyjściu ''.match() - wynik matchowania
          if (ihestiaStrpos(riskParam, 'Vehicle') === 0) {
            objId = self.getIdFromRiskParam(CONSTANTS.PRODUCT_TYPE_VEHICLE, riskParam);
            if (objId !== null) {
              riskParams.objectType = 'Vehicles';
              // wyciagamy id z bracketow
              riskParams.objectId = riskParams.vehicleId = objId;
            }
          } else if (ihestiaStrpos(riskParam, 'Estates') === 0) {
            objId = self.getIdFromRiskParam(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, riskParam);
            if (objId !== null) {
              riskParams.objectType = 'Estates';
              // wyciagamy id z bracketow
              riskParams.objectId = riskParams.estateId = objId;
            }
          } else if (ihestiaStrpos(riskParam, 'Person') === 0 && ihestiaStrpos(riskParam, 'PersonData') !== 0) { //czyli albo nie ma PersonData, albo jest e jakimś dalszym miejscu w ciagu znakow
            objId = self.getIdFromRiskParam(CONSTANTS.PRODUCT_TYPE_PERSON, riskParam);
            if (objId !== null) {
              riskParams.objectType = 'Persons';
              // wyciagamy id z bracketow
              riskParams.objectId = riskParams.personId = objId;
            }
          } else if (ihestiaStrpos(riskParam, 'Group') === 0) {
            objId = self.getIdFromRiskParam(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, riskParam);
            if (objId !== null) {
              riskParams.objectType = 'Groups';
              // wyciagamy id z bracketow
              riskParams.objectId = riskParams.groupId = objId;
            }
          } else if (ihestiaStrpos(riskParam, 'Policies') === 0) {
            parts = riskParam.match(/\[(.*)\]/);
            if (angular.isArray(parts) && angular.isDefined(parts[1])) {
              riskParams.policyNumber = parts[1];
            }
          } else if (ihestiaStrpos(riskParam, 'decision') === 0) {
            parts = riskParam.match(/\[(.*)\]/);
            if (angular.isArray(parts) && angular.isDefined(parts[1])) {
              riskParams.decisionType = parts[1];
            }
          } else if (ihestiaStrpos(riskParam, 'Risks') === 0) {
            parts = riskParam.match(/\[(.*)\]/);
            if (angular.isArray(parts) && angular.isDefined(parts[1])) {
              riskParams.objectType = 'Risks';
              var riskRestCode = parts[1];
              if (angular.isDefined(SPD.risks[riskRestCode])) {
                riskParams.productCode = SPD.risks[riskRestCode].productCode;
                riskParams.riskCode = SPD.risks[riskRestCode].riskCode;
                riskParams.variant = SPD.risks[riskRestCode].variant || [];
              }
            }
            // ostatni wezel
            // jeśli beda inne przypadki, to do obslugi poniżej
          } else if (idx === params.length - 1 && ihestiaStrpos(riskParam.toLowerCase(), 'vehicle') !== -1) {
            riskParams.containSubjectAttribute = true;
          }
        });

        return riskParams;
      };

      /**
       * Zwracamy natywny id z id metadata z bos error
       * @param  {String} type [description]
       * @param  {String} riskParam [description]
       * @return {String|null} null gdy nie odczytano id z przekazanego ciągu parametrów
       */
      this.getIdFromRiskParam = function(type, riskParam) {
        var parts = riskParam.match(/\[(.*)\]/),
          mapperId = (angular.isArray(parts) && angular.isDefined(parts[1])) ? parts[1] : null;
        return MapperUtils.getClientIdForObject(type, mapperId);
      };

      this.handlePolicy = function() {
        return {
          objectType: 'Policy'
        };
      };

    };

    return new Mapper();
  }
]);