angular.module('ihestiaIkomunikator.common')
  .factory('iKomunikatorConstants', [function() {

    return {
      // priorytety
      PRIORITY_HIGH: 'HIGH',
      PRIORITY_NORMAL: 'NORMAL',
      PRIORITY_LOW: 'LOW',
      // statusy
      STATUS_UNREAD: 'UNREAD',
      STATUS_READ: 'READ',
      STATUS_ALL: 'ALL',
      // statusy wątku
      THREAD_STATUS_UNREAD: 'UNREAD',
      THREAD_STATUS_CLOSED: 'CLOSED',
      THREAD_STATUS_ALL: 'ALL',
      THREAD_STATUS_OPEN: 'OPEN',
      // id tematów
      SUBJECT_ID_CONTACT: 'CONTACT_SUBJECT',
      SUBJECT_ID_INJURY: 'CLAIM_SUBJECT',
      SUBJECT_ID_INJURY_DIRECT: 'SIMPLE_CLAIM_SUBJECT',
      SUBJECT_ID_INSURANCE: 'OFFER_SUBJECT',
      SUBJECT_ID_POLICY: 'POLICY_SUBJECT',
      SUBJECT_ID_RECLAMATION: 'RECLAMATION_SUBJECT',
      SUBJECT_ID_VERFICATION_CLIENT: 'CLIENT_DATA_VERFICATION_SUBJECT',
      SUBJECT_ID_COMMISSION: 'COMMISSION_SUBJECT',
      SUBJECT_ID_CONTACT_CLIENT: 'CONTACT_CLIENT_SUBJECT',
      SUBJECT_ID_PRINT_ORDER: 'PRINT_ORDER_SUBJECT',
      
      //typ dotyczy
      SUBJECT_CONTEXT_ITEM_TYPE_POLICY: 'POLICY',
      SUBJECT_CONTEXT_ITEM_TYPE_INJURY: 'CLAIM',
      SUBJECT_CONTEXT_ITEM_TYPE_RECLAMATION: 'RECLAMATION',
      SUBJECT_CONTEXT_ITEM_TYPE_CONTACT: 'CONTACT',
      SUBJECT_CONTEXT_ITEM_TYPE_OFFER: 'OFFER',
      SUBJECT_CONTEXT_ITEM_TYPE_INSURANCE: 'INSURANCE',

      //inne-nowa opcja na dotyczy
      OTHER_SUBJECT_CONTEXT_ITEM_ID_INSURANCE: 'new_insurance',
      OTHER_SUBJECT_CONTEXT_ITEM_ID_INJURY: 'other_injury',
      OTHER_SUBJECT_CONTEXT_ITEM_ID_POLICY: 'other_policy',
      // nazwa formularza
      FORM_NAME_NEW_MESSAGE: 'NEW_MESSAGE',
      FORM_NAME_NEW_POLICY: 'NEW_POLICY',
      FORM_NAME_ACCOUNT_MANAGER_CONTACT: 'ACCOUNT_MANAGER_CONTACT',
      FORM_NAME_OFFERS_LIST: 'OFFERS_LIST',
      FORM_NAME_OFFER_DETAILS: 'OFFER_DETAILS',
      FORM_NAME_OWNER_CHANGE: 'OWNERSHIP_CHANGE',
      FORM_NAME_TASK_LIST: 'TASK_LIST',
      FORM_NAME_INJURY_DETAILS: 'INJURY_DETAILS',
      FORM_NAME_POLICY_DETAILS: 'POLICY_DETAILS',
      FORM_NAME_POLICY_CONFIGURATION: 'POLICY_CONFIGURATION',
      FORM_NAME_PAYMENT_CALENDAR: 'PAYMENT_CALENDAR_EDITION',
      //platform
      PLATFORM_IHESTIA: 'iHestia',
      PLATFORM_JUPITER: 'Jupiter',
      PLATFORM_JUPITER_PLUS: 'JupiterPlus',
      // brand
      BRAND_IHESTIA: 'IHESTIA',
      BRAND_DIRECT: 'DIRECT',
      //system
      SYSTEM_CONTEXT_IKONTO: 'IKONTO',
      SYSTEM_CONTEXT_PORTAL: 'PORTAL',
      //kategoria
      CATEGORY_NEW_OFFER: 'NEW_OFFER',
      CATEGORY_BUYOUT_OF_LEASE: 'LEASING_BUYOUT',
      CATEGORY_EXTENSION_OF_COVERAGE: 'PROTECTION_EXPANSION',
      CATEGORY_RENEWAL_POLICY: 'POLICY_RENEWAL',
      CATEGORY_CHANGING_DATA_SUBJECT_OF_INSURANCE: 'INSURANCE_SUBJECT_INFO_CHANGE',
      CATEGORY_CHANGING_PAYMENT: 'PAYMENT_CHANGE',
      CATEGORY_CESSION_SUPPORT: 'CONVEYANCE_MANAGEMENT',
      CATEGORY_ADD_CESSIONARY: 'ADD_ASIGNEE',
      CATEGORY_ADD_COOWNER: 'ADD_COOWNER',
      CATEGORY_SALE_ESTATE: 'REAL_ESTATE_SALE',
      CATEGORY_ADD_RENEW_POLICY: 'POLICY_RENEWAL',
      
      //rola nadawcy/odbiorcy
      CONTACT_ROLE_WORKER: 'Worker',
      CONTACT_ROLE_POKWORKER: 'POKWorker',
      CONTACT_ROLE_SALES_PROFILE: 'SalesProfile',
      CONTACT_ROLE_CLIENT: 'Client',
      CONTACT_ROLE_CLAIMS_ADJUSTER: 'ClaimsAdjuster',
      CONTACT_ROLE_TASK_POOL: 'TaskPool',
      CONTACT_ROLE_FUNCTIONAL_MAILBOX: 'FunctionalMailbox',
      CONTACT_ROLE_CENTRAL: 'Employee',
      CONTACT_ROLE_OTHER: 'Other',
      // gui only
      CONTACT_ROLE_CLIENT_COMPANY: 'ClientCompany',
      //nazwy kanałów komunikacji, nazwy przy wysyłce wiadomości
      CHANNEL_NAME_SMS: 'sms-single',
      CHANNEL_NAME_EMAIL: 'email',
      CHANNEL_NAME_KOMUNIKAT: 'komunikat'
    };

  }])
.service('iKomunikatorConstantsMapper', ['iKomunikatorConstants',
    function(iKomunikatorConstants) {

      var IKomunikatorConstantsMapper = function() {

      /**
       * [getSubjectContextItemTypeDefaultBySubjectId pobiera domyślny typ obiektu dotyczy na podstawie id tematu]
       * @param  {[type]} subjectId [id tematu]
       * @return {[type]}           [typ dotyczy]
       */
        this.getSubjectContextItemTypeDefaultBySubjectId = function(subjectId){
            if(subjectId === iKomunikatorConstants.SUBJECT_ID_CONTACT){
                  return iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_CONTACT;
            }else if(subjectId === iKomunikatorConstants.SUBJECT_ID_INJURY){
                  return iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INJURY;
            }else if(subjectId === iKomunikatorConstants.SUBJECT_ID_INSURANCE){
                  return iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_OFFER;
            }else if(subjectId === iKomunikatorConstants.SUBJECT_ID_POLICY){
                  return iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_POLICY;
            }else if(subjectId === iKomunikatorConstants.SUBJECT_ID_RECLAMATION){
                  return iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_RECLAMATION;
            }else{
              return null;
            }
        };

        /**
         * [getOtherSubjectContextItemIdByType zwraca nazwe nowe/inne dotyczy]
         * @param  {[type]} type [typ dotyczy]
         * @return {[type]}      [nazwa nowe/inne dotyczy]
         */
        this.getOtherSubjectContextItemIdByType = function(type){
          if(type === iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_POLICY){
            return iKomunikatorConstants.OTHER_SUBJECT_CONTEXT_ITEM_ID_POLICY;
          }else if(type === iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INJURY){
            return iKomunikatorConstants.OTHER_SUBJECT_CONTEXT_ITEM_ID_INJURY;
          }else if(type === iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INSURANCE){
            return iKomunikatorConstants.OTHER_SUBJECT_CONTEXT_ITEM_ID_INSURANCE;
          }else{
            return null;
          }
        };
      };

      return new IKomunikatorConstantsMapper();
    }]);
