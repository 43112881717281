angular.module('ihestiaCommonDirectives.detectorUserActvie', ['lsnBase.detectorUserActvie'])
  .config(['$provide', function($provide) {
    return $provide.decorator('detectorUserActiveHelper', ['$delegate', 'ihestiaConfigHelper',
      function($delegate, ihestiaConfigHelper) {
        var sessionRefreshInterval = ihestiaConfigHelper.get('ihsgDefines', 'SESSION_REFRESH_INTERVAL') / 1000;

        $delegate.callbacks.onUserServiceReactive = {}; //jesli uzytkownik ponownie wznowil swoja aktywnosc wzgledem serwisow, troche skrocony czas

        /**
         * Funkcja do sprawdzania, czy uzytkownik był aktywny w ostatnim czasie
         * @param  {boolean} checkForService czy weryfikacja dla servisu
         */
        $delegate.userWasActive = function(checkForService) {
          if (checkForService) {
            //dla serwisu nie odswiezamy o jedna petle szybciej
            if ($delegate.lastActive.diffSeconds(new XDate()) > ihestiaConfigHelper.get('userInactiveDelay') - sessionRefreshInterval) {
              return false;
            }
          } else if ($delegate.lastActive.diffSeconds(new XDate()) > ihestiaConfigHelper.get('userInactiveDelay')) {
            return false;
          }

          return true;
        };

        $delegate.touch = function() {
          if (!$delegate.sessionHasExpired) {
            //weryfikujemy wczesniejszy stan
            if (!$delegate.userWasActive(true)) {
              $delegate.callCallbacksOnUserServiceReactive();
              $delegate.clearRegisteredAction('onUserServiceReactive');
            }

            if (!$delegate.userWasActive()) {
              $delegate.callCallbacksOnUserReactive();
              $delegate.clearRegisteredAction('onUserReactive');
            }

            $delegate.lastActive = new XDate();
          }
        };

        /**
         * [callCallbacksOnTabActivate wywolanie callbackow na aktywacje tab przegladarki]
         * @return {[type]} [description]
         */
        $delegate.callCallbacksOnUserServiceReactive = function() {
          angular.forEach($delegate.callbacks.onUserServiceReactive, function(callback) {
            if (angular.isFunction(callback)) {
              callback();
            } else if (angular.isArray(callback)) {
              // wsparcie dla wielu callbacków pod jednym kontekstem
              angular.forEach(callback, function(callbackItem) {
                callbackItem();
              });
            }
          });
        };

        return $delegate;
      }
    ]);
  }]);