angular.module('salesPath2')
  //helper ekranu osób
  .service('personsHelper', ['mainDataContainer', 'addressHelper', 'personHelper', 'CONSTANTS', 'CONFIG', 'AddressModelConstants', 'resourceHelper', 'organizationHelper', 'sp2SelectionHelper', 'navigationHelper', 'allowedChangesHelper', 'dataContainerHelper', '$filter', 'lsnUtils', 'RESOURCES',
    function(mainDataContainer, addressHelper, personHelper, CONSTANTS, CONFIG, AddressModelConstants, resourceHelper, organizationHelper, selectionHelper, navigationHelper, allowedChangesHelper, dataContainerHelper, $filter, lsnUtils, RESOURCES) { // eslint-disable-line angular/di
      var PersonsHelper = function() {
        var self = this;
        this.tplData = {
          indured: [], //ubezpieczeni
          coowners: [], //współubezpieczeni
          leasers: [], //leasingodawcy
          uninsuredOrganizations: [], //nieubezpieczone firmy
          clientHasPatronInIKonto: false, //czy ubezpieczajacy ma opiekuna klienta
          insurer: {} //dane ubezpieczającego
        };

        /**
         * ustawienia dla addressInfo
         * @type {Object}
         */
        this.adressDataTrim = {
          elements: [{
            field: 'postalCode'
          }, {
            field: 'city',
            fieldSeparator: ', ',
            optionalLineBreak: true, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
            forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
          }, {
            field: 'streetPrefix'
          }, {
            field: 'street'
          }, {
            field: 'house',
            fieldSeparator: '',
            firstInRow: false
          }, {
            field: 'apartment',
            prefix: '/',
            lastInLine: true
          }],
          maxLength: 31,
          doTruncate: false
        };
        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          // wykonaj tylko dla Ergo Podróż lub Ergo Sport
          if (CONFIG.BEHAVIOR.personGroups === true) {
            switch (actionName) {
              case 'matrixProductSelected':
              case 'personGroupSelected':
              case 'personGroupDeleted':
                self.verifyMainInsured(self.getInsured());
                break;
              default:
                break;
            }
          }
          if (navigationHelper.getCurrentState().name !== 'persons') {
            return false;
          }
          switch (actionName) {
            case 'groupAdded':
            case 'groupEdited':
            case 'personAdded':
            case 'personEdited':
            case 'leaserSaved':
            case 'tarifficationEnded':
            case 'personEntirelyDeleted':
            case 'cessionDeleted':
              self.refreshTplData();
              break;
            default:
              break;
          }
          return true;
        };

        /**
         * odswieza dane self.tplData
         * @param  {Boolean} includeGroups wysyłane do getUnassignedPersons (dzięki temu dostajemy też grupy w unassigned)
         * @return {undefined}
         */
        this.refreshTplData = function(includeGroups) {
          self.tplData.insured = self.getInsured();
          self.tplData.coowners = self.getCoowners();
          self.tplData.leasers = self.getLeasers();
          self.tplData.uninsuredOrganizations = self.getUninsuredOrganizations();
          self.tplData.unassigned = self.getUnassignedPersons(includeGroups);
          self.refreshInsurerInfo();
          //info o opiekunie
          var appDynVals = mainDataContainer.application.get('dynamicValues');
          if (angular.isDefined(appDynVals._patronIKontoLinkVisible) && appDynVals._patronIKontoLinkVisible) {
            self.tplData.clientHasPatronInIKonto = true;
          } else {
            self.tplData.clientHasPatronInIKonto = false;
          }
        };

        /**
         * Zwraca osoby niepowiązane z żadnym ryzykiem
         * @param  {Boolean} includeGroups dzięki temu dostajemy też grupy w unassigned
         * @return {array} lista osób
         */
        this.getUnassignedPersons = function(includeGroups) {
          var persons = [];
          var excludeRefs = [mainDataContainer.insurerId, mainDataContainer.mainInsuredId]; // wykluczamy głównego ubezpieczonego i ubezpieczającego

          // wykluczamy osoby z lokalizacji i pojazdów
          var objectsGroups = [mainDataContainer.vehicles, mainDataContainer.localizations];
          angular.forEach(objectsGroups, function(objectGroup) {
            angular.forEach(objectGroup, function(object) {
              excludeRefs.push(object.getAdditionalData('leaserId'));
              excludeRefs = excludeRefs.concat(object.getAdditionalData('coowners'));
            });
          });

          // wykluczamy ubezpieczonych
          angular.forEach(self.getInsured(), function(insured) {
            if (insured.isGroup === null || insured.isGroup === false) {
              excludeRefs.push(insured.person.metaData.clientId);
            } else if (insured.isGroup) {
              excludeRefs.push(insured.group.metaData.clientId);
            }
          });

          // wywalamy duplikaty
          excludeRefs = excludeRefs.unique();

          // zbieramy dane osób
          angular.forEach(mainDataContainer.persons, function(person) {
            if (excludeRefs.indexOf(person.metaData.clientId) === -1) {
              persons.push({
                isGroup: false,
                group: {},
                person: personHelper.getPersonWithAddress(person),
                allowedChanges: allowedChangesHelper.anyChangePossible(person),
                insurer: false,
                mainInsured: false,
                icons: [],
                addressInfo: addressHelper.getAddressInfo(person.addresses[0], self.adressDataTrim)
              });
            }
          });

          // Grupy
          if (includeGroups && mainDataContainer.groups) {
            angular.forEach(mainDataContainer.groups, function(group) {
              if (excludeRefs.indexOf(group.metaData.clientId) === -1) {


                persons.push({
                  isGroup: true,
                  personsCount: self.getPersonCountInGroup(group),
                  group: group.getData(),
                  person: {},
                  allowedChanges: allowedChangesHelper.anyChangePossible(group),
                  insurer: false,
                  mainInsured: false,
                  icons: [],
                  addressInfo: ''
                });
              }
            });
          }

          return persons;
        };

        /**
         * Zliczanie osób w grupie
         * @param  {Object} group mainDataContainer.groups element
         * @return {Int}
         */
        this.getPersonCountInGroup = function(group) {
          var personsCount = 0;
          if (group.dynamicValues) {
            angular.forEach(RESOURCES.INSURED_AGE_INTERVALS, function(interval) {
              var intervalValue = group.dynamicValues[interval.BOS_CODE];
              if (intervalValue) {
                personsCount += parseInt(intervalValue, 10);
              }
            });
          }

          return personsCount;
        };

        //odswieza dane o ubezpieczajacym
        this.refreshInsurerInfo = function() {
          var insurerModel = dataContainerHelper.getObjects(CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION)[mainDataContainer.insurerId],
            insurerAddress = addressHelper.getAddressFromCollection(insurerModel.addresses, AddressModelConstants.ADDRESS_TYPE_STAL),
            isPerson = dataContainerHelper.isPerson(insurerModel);

          self.tplData.insurer = isPerson ? personHelper.getPersonWithAddress(insurerModel) : organizationHelper.getOrganizationWithAddress(insurerModel);
          self.tplData.insurer.addressInfo = addressHelper.getAddressInfo(insurerAddress, self.adressDataTrim);
          self.tplData.insurer.isPerson = isPerson;
          self.tplData.insurer.allowedChanges = allowedChangesHelper.anyChangePossible(insurerModel);
        };
        /**
         * pobiera ubezpieczonych (osoby/grupy)
         * @return {Object[]}
         */
        this.getInsured = function() {
          var insured = {},
            subjects = angular.extend({}, mainDataContainer.persons, mainDataContainer.groups);
          angular.forEach(subjects, function(subject, clientId) {
            var isGroup = (subject.objectName === 'Group'),
              address = addressHelper.getAddressFromCollection(isGroup ? null : subject.addresses, AddressModelConstants.ADDRESS_TYPE_STAL);
            insured[clientId] = {
              isGroup: isGroup,
              personsCount: isGroup ? self.getPersonCountInGroup(subject) : null,
              group: isGroup ? subject.getData() : {},
              person: !isGroup ? personHelper.getPersonWithAddress(subject) : {},
              allowedChanges: allowedChangesHelper.anyChangePossible(subject),
              insurer: parseInt(clientId, 10) === parseInt(mainDataContainer.insurerId, 10),
              mainInsured: parseInt(clientId, 10) === parseInt(mainDataContainer.mainInsuredId, 10),
              icons: [],
              addressInfo: addressHelper.getAddressInfo(address, self.adressDataTrim)
            };
          });

          angular.forEach(resourceHelper.products, function(productData) {
            var variants = dataContainerHelper.getVariantsForProduct(productData.CODE, CONSTANTS.ALL_OBJECTS); //może być pusty obiekt
            if (!angular.equals(variants, {})) { //wybrany produkt
              if ([CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE].indexOf(productData.TYPE) !== -1) { //jeśli produkt mieniowy lub komunikacyjny, to ddoajemy głównego ubezpieczonego w tym produkcie
                self.addIconToSubject(insured, productData, mainDataContainer.mainInsuredId);
              }
              //współwłaściciele lokalizacji i pojazdów
              if ([CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE].indexOf(productData.TYPE) !== -1) {
                self.addCoownerProductsData(productData, insured);
              }
              //ryzyka osobowe
              if (productData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) {
                self.addPersonProductsData(productData, insured);
              }
              //ryzyka typu personGroup
              if (CONFIG.BEHAVIOR.personGroups && productData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
                self.addPersonGroupProductsData(productData, insured);
              }
            }
          });

          self.addExtraProductsForInsured(insured);

          var insurerRisk = [];
          angular.forEach(insured, function(v, i) {
            if (v.icons.length > 0 || (v.mainInsured && !v.insurer)) {
              insurerRisk.push(insured[i]);
            }
          });
          return insurerRisk;
        };

        /**
         * zwraca dane do prezentacji leasingodawców
         * @return {Object[]}
         */
        this.getLeasers = function() {
          var leasers = {};
          var leasersIcons = {};
          var leaserObjType = {}; //typy obiektów, do których przypisani są leasingodawncy
          //leasingodawcy na lokalizacji
          angular.forEach(mainDataContainer.localizations, function(localization, localizationId) {
            var leaserId = localization.getAdditionalData('leaserId');
            if (leaserId !== null && mainDataContainer.organizations[leaserId]) {
              leasers[leaserId] = mainDataContainer.organizations[leaserId];
              leaserObjType[leaserId] = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
            }
            angular.forEach(mainDataContainer.selectedVariants.localization, function(v, i) {
              if (i === localizationId) {
                leasersIcons[leaserId] = [];
                angular.forEach(resourceHelper.products, function(productData) {
                  if (productData.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION && v[productData.CODE]) {
                    leasersIcons[leaserId].push(productData.ICON);
                  }
                });
              }
            });
          });

          angular.forEach(mainDataContainer.vehicles, function(vehicle, vehicleId) {
            var leaserId = vehicle.getAdditionalData('leaserId');
            if (leaserId !== null && mainDataContainer.organizations[leaserId]) {
              leasers[leaserId] = mainDataContainer.organizations[leaserId];
              leaserObjType[leaserId] = CONSTANTS.PRODUCT_TYPE_VEHICLE;
            }
            angular.forEach(mainDataContainer.selectedVariants.vehicle, function(v, i) {
              if (i === vehicleId) {
                if (angular.isUndefined(leasersIcons[leaserId])) {
                  leasersIcons[leaserId] = [];
                }
                angular.forEach(resourceHelper.products, function(productData) {
                  if (productData.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE && v[productData.CODE]) {
                    leasersIcons[leaserId].push(productData.ICON);
                  }
                });
              }
            });
          });

          //zrzucamy dane do tablicy
          var leasersData = [];
          self.addExtraProductsForLeasers(leasers, leasersIcons);
          angular.forEach(leasers, function(leaser, leaserId) {
            var leaserData = organizationHelper.getOrganizationWithAddress(leaser);
            leaserData.productType = leaserObjType[leaserId];
            var address = addressHelper.getAddressFromCollection(leaser.addresses, AddressModelConstants.ADDRESS_TYPE_STAL);
            leaserData.addressInfo = addressHelper.getAddressInfo(address, self.adressDataTrim);

            leaserData.id = leaserId;
            leaserData.icons = leasersIcons[leaserId];

            if (leasersIcons[leaserId].length > 0) {
              leasersData.push(leaserData);
            }
            leaserData.allowedChanges = allowedChangesHelper.anyChangePossible(leaser);
          });
          return leasersData;
        };

        /**
         * zwraca listę organizacji niepowiązanych z ubezpieczeniami
         * @return {Object[]} lista z obiektami jak poniżej
         */
        this.getUninsuredOrganizations = function() {
          var orgs = [],
            orgData = null;
          angular.forEach(mainDataContainer.organizations, function(org, orgId) {
            if (!organizationHelper.isOrganizationInsured(org)) {
              orgData = organizationHelper.getOrganizationWithAddress(org);
              if (!orgData.name) {
                orgData.name = $filter('translate')('noData', {
                  componentCode: 'Public'
                });
              }
              orgData.addressInfo = addressHelper.getAddressInfo(addressHelper.getAddressFromCollection(org.addresses, AddressModelConstants.ADDRESS_TYPE_STAL), self.adressDataTrim);
              orgData.id = orgId;
              orgData.allowedChanges = allowedChangesHelper.anyChangePossible(org);
              orgs.push(orgData);
            }
          });
          return orgs;
        };

        /**
         * zwraca dane współwłaścicieli do prezentacji
         * @return {Object[]}
         */
        this.getCoowners = function() {
          var insured = {},
            coowners = null;

          angular.forEach(mainDataContainer.persons, function(person, personId) {
            var address = addressHelper.getAddressFromCollection(mainDataContainer.persons[personId].addresses, AddressModelConstants.ADDRESS_TYPE_STAL);
            insured[personId] = {
              person: personHelper.getPersonWithAddress(mainDataContainer.persons[personId]),
              allowedChanges: allowedChangesHelper.anyChangePossible(mainDataContainer.persons[personId]),
              icons: [],
              addressInfo: addressHelper.getAddressInfo(address, self.adressDataTrim)
            };
          });

          var selectedProductIcons = {};
          angular.forEach(resourceHelper.products, function(productData) {
            var variants = dataContainerHelper.getVariantsForProduct(productData.CODE); //może być pusty obiekt
            if (!angular.equals(variants, {})) {
              selectedProductIcons[productData.CODE] = productData.ICON;

              //współwłaściciele lokalizacji i pojazdów
              if ([CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE].indexOf(productData.TYPE) !== -1) {
                self.addCoownerProductsData(productData, insured);
              }

              //ryzyka osobowe
              if (productData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) {
                self.addPersonProductsData(productData, insured, true);
              }

            }
          });

          self.addExtraProductsForCoowners(insured);
          coowners = [];
          angular.forEach(insured, function(v, i) {
            if (v.icons.length > 0) {
              coowners.push(insured[i]);
            }
          });
          return coowners;
        };

        /**
         * dodaje dane/ikonki dotyczące produktów osobowych do przekazanych osób
         * @param {Object} productData definicja produktu
         * @param {Object} persons obiekt z osobami
         * @param {Object} onlyPreviouslyAdded czy dodawać dane tylko dla osób, które zostały już dodane do jakiegoś ubezpieczenia (mają co najmniej jedną ikonkę)
         */
        this.addPersonProductsData = function(prodDef, persons, onlyPreviouslyAdded) {
          onlyPreviouslyAdded = (onlyPreviouslyAdded === true) ? true : false;
          var personProdData = null;
          angular.forEach(mainDataContainer.persons, function(person, personId) {
            if (onlyPreviouslyAdded && persons[personId].icons.length === 0) {
              return true; //pomijamy osobę bez ikonki
            }
            personProdData = person.getAdditionalData(prodDef.CODE);
            if (angular.isObject(personProdData) && angular.isDefined(personProdData.variants)) {
              var variantSelected = false;
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(prodDef.CODE) !== -1) {
                lsnNg.forEach(personProdData.variants, function(selected) {
                  if (selected) {
                    variantSelected = true;
                    return false;
                  }
                  return true; //continue
                });
              } else if (personProdData.variants !== null) {
                variantSelected = true;
              }
              if (variantSelected) {
                self.addIconToSubject(persons, prodDef, personId);
              }
            }
            return true;
          });
        };

        /**
         * dodaje dane/ikonki dotyczące produktów typu personGroup do przekazanych podmiotów
         * @param {Object} productData definicja produktu
         * @param {Object} subjects podmioty ubezpieczone
         */
        this.addPersonGroupProductsData = function(prodDef, subjects) {
          angular.forEach(subjects, function(subjData, subjId) {
            if (dataContainerHelper.isProductSelected(prodDef.CODE, subjId)) {
              self.addIconToSubject(subjects, prodDef, subjId);
            }
          });
        };

        /**
         * dodaje dane/ikonki dotyczące produktów ze wspołwłaścicielami (lokalizacje i pojazdy) do przekazanych osób
         * @param {Object} productData definicja produktu
         * @param {Object} subjects obiekt z osobami/firmami
         */
        this.addCoownerProductsData = function(prodDef, subjects) {
          angular.forEach(dataContainerHelper.getObjects(prodDef.TYPE), function(obj, objId) {
            var selectedVariantForObj = mainDataContainer.selectedVariants[prodDef.TYPE][objId];
            if (selectedVariantForObj && selectedVariantForObj[prodDef.CODE] !== null) //obiekt ma wybrany wariant na produkcie
            {
              var coowners = obj.getAdditionalData('coowners');
              if (coowners !== null) {
                angular.forEach(coowners, function(coownerId) {
                  self.addIconToSubject(subjects, prodDef, coownerId);
                });
              }
            }
          });
        };
        /**
         * dodaje produkt/ikone do ubezpieczonego
         * @param {Object} insured obiekt z osobami
         * @param {Object} prodDef definicja produktu
         * @param {String} clientId clientId osoby/obiektu
         */
        this.addIconToSubject = function(insured, prodDef, clientId) {
          if (insured[clientId].icons.indexOf(prodDef.ICON) === -1) {
            insured[clientId].icons.push(prodDef.ICON);
          }
          //dodawanie ikonek dla sztucznych produktów
          var fakes = resourceHelper.getFakeProdDef(prodDef.CODE);
          angular.forEach(fakes, function(def) {
            if (insured[clientId].icons.indexOf(def.ICON) === -1) {
              insured[clientId].icons.push(def.ICON);
            }
          });
        };

        /**
         * mozliwosc dodania dodatkowych produktow/ikon do osob ubezpieczonych
         * @param {Object} insured
         */
        this.addExtraProductsForInsured = function(insured) {
          if (CONFIG.BEHAVIOR.greenCardAsProd) {
            var zkSelected = selectionHelper.isAddSelected(CONSTANTS.ADD_GREENCARD),
              zkDef = resourceHelper.getAddDef(CONSTANTS.ADD_GREENCARD);
            if (zkSelected) {
              angular.forEach(insured, function(person) {
                if (person.mainInsured) {
                  person.icons.push(zkDef.ICON);
                }
              });
            }
          }
          return insured;
        };
        /**
         * mozliwosc dodania dodatkowych produktow/ikon do wspolwlascicieli
         * @param {Object} coowners
         */
        this.addExtraProductsForCoowners = function(insured) {
          if (CONFIG.BEHAVIOR.greenCardAsProd) {
            var zkSelected = selectionHelper.isAddSelected(CONSTANTS.ADD_GREENCARD),
              zkDef = resourceHelper.getAddDef(CONSTANTS.ADD_GREENCARD),
              vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
            if (angular.isUndefined(vehicle)) {
              return insured;
            }
            var coowners = vehicle.getAdditionalData('coowners');
            if (zkSelected && coowners !== null) {
              angular.forEach(coowners, function(coownerId) {
                insured[coownerId].icons.push(zkDef.ICON);
              });
            }
          }
          return insured;
        };
        /**
         * mozliwosc dodania dodatkowych produktow/ikon do leasingodawcow
         * @param {Object} leasers obiekty leaserow
         * @param {Object} leasersIcons ikony produktow dla leaserow {id_leasera: [ikony]}
         */
        this.addExtraProductsForLeasers = function(leasers, leasersIcons) {
          if (CONFIG.BEHAVIOR.greenCardAsProd) {
            var zkSelected = selectionHelper.isAddSelected(CONSTANTS.ADD_GREENCARD),
              zkDef = resourceHelper.getAddDef(CONSTANTS.ADD_GREENCARD);
            if (zkSelected) {
              angular.forEach(leasers, function(leaser, leaserId) {
                if (!angular.isArray(leasersIcons[leaserId])) {
                  leasersIcons[leaserId] = [];
                }
                leasersIcons[leaserId].push(zkDef.ICON);
              });
            }
          }
          return [leasers, leasersIcons];
        };

        /**
         * ustal glownego ubezpieczonego
         * @param {array} insuredList tablica ubezpieczonych
         * @return {object}
         */

        this.verifyMainInsured = function(insuredList) {
          var isMainInsuredOnList = _.some(insuredList, 'mainInsured'),
            isInsurerOnList = _.some(insuredList, 'insurer'),
            firstInsuredPerson = _.find(insuredList, {isGroup: false}),
            matrixPersons = self._getMatrixPersons(),
            isMainInsuredOnMatrix = _.some(matrixPersons, ['metaData.clientId', mainDataContainer.mainInsuredId]),
            isInsurerOnMatrix = _.some(matrixPersons, ['metaData.clientId', mainDataContainer.insurerId]);

          if (!firstInsuredPerson) { // no insured persons, let's check matrixPersons
            if (matrixPersons.length) { // some persons without risk selection on matrix

              if (!isMainInsuredOnMatrix) {
                if (isInsurerOnMatrix) { // set insurer as mainInsured
                  mainDataContainer.mainInsuredId = mainDataContainer.insurerId;
                } else { // set first person from array as mainInsured
                  mainDataContainer.mainInsuredId = matrixPersons[0].metaData.clientId;
                }
              }
              // nothing to do -> main insured is already on matrix (but with no risk selected)
            } else { // no persons on matrix, set insurer as mainInsured
              mainDataContainer.mainInsuredId = mainDataContainer.insurerId;
            }

          } else if (!isMainInsuredOnList && !isMainInsuredOnMatrix) {
            if (isInsurerOnList) {
              mainDataContainer.mainInsuredId = mainDataContainer.insurerId;
            } else {
              mainDataContainer.mainInsuredId = firstInsuredPerson.person.metaData.clientId;
            }
          } // nothing to do -> main insured is already on insured list (has selected risk(s)) or matrix without risks
          return mainDataContainer.mainInsuredId;
        };

        /**
         * get persons from matrixPersonbox (chosen on matrix)
         * @return {LsnPersonModelV1[]}
         */
        this._getMatrixPersons = function() {
          var persons = [];
          var subject = null;
          angular.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP], function(varaints, clientId) {
            subject = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
            if (subject && subject.objectName === 'Person') {
              persons.push(subject);
            }
          });
          return persons;
        };

      };
      return new PersonsHelper();
    }
  ])
  .run(['actionHelper', 'personsHelper',
    function(actionHelper, personsHelper) {
      actionHelper.registerHelper('personsHelper', personsHelper);
    }
  ]);