/**
 * Filtr do tłumaczeń
 */
angular.module('ihestiaCommonDirectives')
  .filter('translate', ['ihestiaLabelHelper', function(ihestiaLabelHelper) {
    var translateFunction = function(value) {
      var parameters = [];
      var firstParam = arguments.length > 1 ? arguments[1] : null;
      var componentCode = null;
      var langCode = null;
      var markdowns = {};
      var flags = {};
      if(angular.isObject(firstParam) && firstParam !== null && !angular.isArray(firstParam)) {
        // parametry przekazywane via obiekt ("tablica asocjacyjna")
        parameters = firstParam.params;
        componentCode = firstParam.componentCode;
        langCode = firstParam.langCode;
        if(angular.isObject(firstParam.md)) {
          markdowns = firstParam.md;
        }
        if(angular.isObject(firstParam.flags)) {
          flags = firstParam.flags;
        }
      } else {
        // parametry przekazywane jako prosta tablica
        angular.forEach(arguments, function(argValue, argKey){
          if(argKey > 0) {
            parameters.push(argValue);
          }
        });
      }
      return ihestiaLabelHelper.getTranslation.apply(this, [value, parameters, langCode, componentCode, markdowns, flags]);
    };

    translateFunction.$stateful = true; // @todo obczaić, czy to nie powoduje problemów wydajnościowych

    return translateFunction;
  }]);