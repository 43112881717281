angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminSystemMessageSvc', ['ihestiaCommonAdminMessageAbstract',
    function(ihestiaCommonAdminMessageAbstract) {

      var AdminSystemMessageSvc = function() {
        ihestiaCommonAdminMessageAbstract.apply(this, arguments);

        var self = this;
        this.businessArea = 'message-manager';
        this.resource = 'system-message';


        /**
         * Zapis wiadomosci
         * @param  {object}   data                 dane obiektu
         * @param  {Function} callback             [description]
         * @return {[type]}                        [description]
         */
        this.save = function(data, callback) {
          return self.post('', data, null, function(res) {
            if (typeof callback === 'function') {
              callback(res);
            }
          });
        };


      };
      return new AdminSystemMessageSvc();
    }
  ]);