angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaSumInsuredVectorModalCtrl', ['$scope', 'ihestiaSumInsuredVectorModalHelper',
    function($scope, ihestiaSumInsuredVectorModalHelper) {
      $scope.tplData = ihestiaSumInsuredVectorModalHelper.tplData;

      $scope.initData = function() {
        ihestiaSumInsuredVectorModalHelper.init();
      };

      $scope.initData();
    }]);
