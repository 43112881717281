angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorMessageModel', ['IKomunikatorAbstractModel', 'iKomunikatorConstants', 'ihestiaIkomunikatorContextHelper',
    function(IKomunikatorAbstractModel, iKomunikatorConstants, ihestiaIkomunikatorContextHelper) {

      var IKomunikatorMessageModel = function() {
        IKomunikatorAbstractModel.apply(this, arguments);
        var self = this;

        // "path": {"type": "string"},
        // "storagePlace": {"type": "string"}
        this.attachments = [];

        /**
         * Identyfikatory komentarzy
         * @type {Array}
         */
        this.commentsIds = [];

        /**
         * Treść
         * @type {String}
         */
        this.content = '';

        /**
         * Temat/Dotyczy/...
         * @type {Object}
         */
        this.customFields = {};

        /**
         * Czy wiadomośc spoza IHestii (sms, mail, etc)
         * @type {Boolean}
         */
        this.external = false;

        /**
         * Jeśli external na true, to tutaj będzie id zewnętrznego systemu
         * @type {String}
         */
        this.externalMessageId = null;

        /**
         * Wymuszenie drogi komunikacji, np. std na mail, a my wymuszamy sms
         * Słownik w usłudze
         * @type {String}
         */
        this.forcedNotificationChannelName = null;

        /**
         * Identyfikator wiadomości
         * @type {String}
         */
        this.id = null;

        /**
         * Identyfikatory notatek
         * @type {Array}
         */
        this.notesIds = [];

        /**
         * Twórca wiadomości
         * Id w ramach książku adresowej (tylko w db ikom)
         * @type {String}
         */
        this.originatorId = null;

        /**
         * Obiekt z imieniem i nazwiskiem twórcy wiadomości
         * @type {Object}
         */
        this.originator = null;

        /**
         * inicjały twórcy wiadomości
         * @type {String}
         */
        this.initials = null;

        /**
         * Data utworzenia
         * @type {Number}
         */
        this.postDate = null;

        //póki co dwa atrybuty bo usługi zwracają różnies
        this.creationDate = null;

        /**
         * Priorytet
         * @type {Enum} PRIORITY_* z iKomunikatorConstants
         */
        this.priority = null;

        /**
         * Imiona i nazwiska odbiorców z książki adresowej
         * @type {Array}
         */
        this.recipients = [];

        /**
         * Powiązane z external
         * @type {String}
         */
        this.receivedThroughChannel = null;

        /**
         * id wątku wiadomości
         * @type {String}
         */
        this.messageThreadId = null;
        //przychodzi na tym albo na tym w zależności od usługi
        this.threadId = null;

        /**
         * Status wiadomości
         * @type {Enum} STATUS_* z iKomunikatorConstants
         */
        this.status = null;

        /**
         * Tytuł
         * @type {String}
         */
        this.title = '';

        /**
         * Obserwatorzy
         * @type {Array}
         */
        this.viewerIds = [];

        //kontekst przekazywny tylko i wylacznie dla uslug
        this.contextRedisUUID = null;

        /**
         * [getDate pobiera date wiadomosci]
         * @return {[string]} [description]
         */
        this.getDate = function() {
          var postDate = new XDate(self.postDate);
          var creationDate = new XDate(self.creationDate);

          return postDate.valid() ? postDate.toString('yyyy-MM-dd') : (creationDate.valid() ? creationDate.toString('yyyy-MM-dd') : '');
        };

        /**
         * [getTime pobiera czas wiadomosci]
         * @return {[string]} [description]
         */
        this.getTime = function() {
          var postDate = new XDate(self.postDate);
          var creationDate = new XDate(self.creationDate);

          return postDate.valid() ? postDate.toString('HH:mm') : (creationDate.valid() ? creationDate.toString('HH:mm') : '');
        };

        /**
         * [isUnread sprawdza czy wiadomość jest odczytana]
         * @return {Boolean} [description]
         */
        this.isUnread = function() {
          return self.status === iKomunikatorConstants.STATUS_UNREAD;
        };

        /**
         * [getModelForRest zwraca obiekt przygotowany do wysłania]
         * @param  {[type]} newMessage [czy nowa wiadomość, jeśli nie znaczy to, ze odpowiedź]
         * @return {[type]}            [description]
         */
        this.getModelForRest = function(newMessage) {
          self.id = null;
          self.status = iKomunikatorConstants.STATUS_UNREAD;
          self.priority = iKomunikatorConstants.PRIORITY_NORMAL;

          var context = ihestiaIkomunikatorContextHelper.getContext();
          var dashboardTaskId = context.dashboardTaskId;
          if (dashboardTaskId !== null) {
            self.customFields.dashboardTaskId = dashboardTaskId;
          }

          var data = angular.copy(self.toRest());

          if (newMessage) {
            data.customFields.formName = ihestiaIkomunikatorContextHelper.get('formName');
            data.customFields.callSource = ihestiaIkomunikatorContextHelper.get('systemContext');
            var contextCategory = ihestiaIkomunikatorContextHelper.get('category');
            if(contextCategory !== null || !data.customFields.subjectContextItemOperationCategory) {
              // dla podtematów mamy już ustawione subjectContextItemOperationCategory i nie może nam pusty context napisać
              data.customFields.subjectContextItemOperationCategory = ihestiaIkomunikatorContextHelper.get('category');
            }
          }

          self.setDateForRest(data);

          angular.forEach(data.recipients, function(recipient, key) {
            if(angular.isFunction(recipient.getModelForRest)){
              data.recipients[key] = recipient.getModelForRest();
            }
          });

          if (data.forcedNotificationChannelName === 'komunikat' || angular.isUndefined(data.forcedNotificationChannelName)) {
            data.forcedNotificationChannelName = null;
          }

          data.customFields.otherSubjectContextItemId = null;
          if (data.customFields.subjectContextItemNumber === null){
            data.customFields.subjectContextItemUserNumber = true;
            if(data.customFields.subjectContextItemNumberFill) {
              data.customFields.subjectContextItemNumber = data.customFields.subjectContextItemNumberFill;
            }
          }else{
            data.customFields.subjectContextItemUserNumber = false;
          }

          self.clearToSelectElement(data);
          self.deleteExtraAttributes(data);

          if(typeof data.customFields.categoryObject !== 'undefined') {
            delete data.customFields.categoryObject;
          }

          return data;
        };

        /**
         * [setDateForRest ustawia czas wysłania]
         * @param {[object]} data [obiekt wysyłany w usłudze]
         */
        this.setDateForRest = function(data) {
          var now = new XDate();
          var timezoneOffset = now.getTimezoneOffset() * 60 * 1000;

          data.postDate = (new XDate(now.getTime() - timezoneOffset)).toISOString();
          data.postDate = now.toString('yyyy-MM-dd') + 'T' + now.toString('HH:mm:ss');
        };

        /**
         * [clearToSelectElement funkcja czyści pozyje wybierz]
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        this.clearToSelectElement = function(data) {
          if (data.forcedNotificationChannelName === -1) {
            data.forcedNotificationChannelName = null;
          }
          if (data.customFields.subjectId === -1) {
            delete data.customFields.subjectId;
          }
          if (data.customFields.subjectContextItemNumber === -1) {
            data.customFields.subjectContextItemNumber = null;
          }
          if (data.recipients.length > 0 && data.recipients[0].id === -1) {
            data.recipients.splice(0, 1);
          }
        };

        /**
         * [deleteExtraAttributes czysci dodatkowe atrybuty uzywane na tpl]
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        this.deleteExtraAttributes = function(data) {
          angular.forEach(['subjectContextItemNumberFill', 'id'], function(value) {
            if (angular.isDefined(data.customFields[value])) {
              delete data.customFields[value];
            }
          });
        };

      };

      return IKomunikatorMessageModel;
    }
  ]);