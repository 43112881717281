/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuClipboard')
	.factory('IHestiaMenuClipboardListSrv', ['IHestiaRestServiceAbstractDef', 
		function(IHestiaRestServiceAbstractDef) {
			var IHestiaMenuClipboardListSrv = function() {

				IHestiaRestServiceAbstractDef.apply(this, arguments);				

				this.basePattern = '{host}{port}{path}/jsapi/clipboard{id}';
			};

			return new IHestiaMenuClipboardListSrv();
	}
]);