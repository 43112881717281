angular.module('lsnBase.models')
.factory('LsnAddressModelConstants', [function() {
    var AddressModelConstants = {};

    AddressModelConstants.ADDRESS_TYPE_STAL = 'ADR_STALY';
    AddressModelConstants.ADDRESS_TYPE_KORE = 'ADR_KORESP';
    AddressModelConstants.ADDRESS_TYPE_TYMC = 'ADR_TYMCZ';
    AddressModelConstants.ADDRESS_TYPE_DZIA = 'ADR_DZIAL';
    AddressModelConstants.ADDRESS_TYPE_REJ = 'ADR_REJ';
    AddressModelConstants.ADDRESS_TYPE_INNY = 'ADR_INNY';
    // słownik typów adresu
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE = {};
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_STAL] = 'stały';
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_KORE] = 'korespondencyjny';
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_TYMC] = 'tymczasowy';
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_DZIA] = 'adr. działalności';
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_REJ] = 'adr. rejonowy';
    AddressModelConstants.DICTIONARY_ADDRESS_TYPE[AddressModelConstants.ADDRESS_TYPE_INNY] = 'adr. inny';

    return AddressModelConstants;
  }]);