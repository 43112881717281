angular.module('salesPath2')
  /**
   * zmienne dla calej aplikacji (glownie flagi)
   * Wartości mogą zmieniać się w trakcie działania aplikacji
   * @return {Object}
   */
  .provider('appVariables', [
    function() {

      this.$get = ['appVariablesHelper', function(appVariablesHelper) {
        return appVariablesHelper.getAppVars();
      }];

    }
  ]);