angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryPolicySegmentSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryPolicySegmentSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'policySegment';

      };
      return new RestDictionaryPolicySegmentSvc();
    }
  ]);