angular.module('salesPath2')
	.controller('productCompareCtrl', ['$scope', 'RESOURCES', 'CONSTANTS',
		function($scope, RESOURCES, CONSTANTS) {
			$scope.init = function() {
				$scope.setDescGroups();
			};

			/**
			 * [setDescGroups ustawia descGroups na poddstawie definicji z RESOURCES]
			 */
			$scope.setDescGroups = function() {
				$scope.descGroups = [];
				angular.forEach(RESOURCES.PRODUCTLIST.concat(RESOURCES.EXTRA_DESCRIPTION_LIST) , function(productDef) {
					if (Number.isInteger(productDef.DESCRIPTION_POSITION) && productDef.DESCRIPTION_POSITION > 0) {
						var descGroup = {
							id: productDef.CODE + productDef.DESCRIPTION_POSITION,
							pos: productDef.DESCRIPTION_POSITION,
							icon: productDef.ICON,
							names: $scope.getNames(productDef),
							iconNavigation: productDef.ICON_NAVIGATION,
							numberVariants: angular.isArray(productDef.VARIANTLIST) ? productDef.VARIANTLIST.length : 1,
							descList: [],
							descGlobal: []
						};

						angular.forEach(productDef.DESCRIPTION, function(descDef) {
							var desc = null;
							if (descDef.VARIANTS) {
								desc = $scope.getDescSimple(productDef, descDef);
							} else if (descDef.COMPLEX) {
								desc = $scope.getDescComplex(productDef, descDef);
							} else if (descDef.COMPLEX_NAME) {
								desc = $scope.getDescComplexName(productDef, descDef);
							}

							if (desc !== null) {
								descGroup.descList.push(desc);
							}
						});

						if (angular.isDefined(productDef.DESCRIPTION_EXTRA) && angular.isDefined(productDef.DESCRIPTION_EXTRA.GLOBAL)) {
							descGroup.descGlobal = productDef.DESCRIPTION_EXTRA.GLOBAL;
						}

						$scope.descGroups.push(descGroup);
					}
				});
			};

			/**
			 * [getNames pobiera nazwe wariantow, wariantu jesli jeden]
			 * @param  {[type]} productDef [definicja produktu, obiektu z productList z RESOURCES]
			 * @return {[type]}            [description]
			 */
			$scope.getNames = function(productDef) {
				var names = [],
					name = null;

				if (!angular.isArray(productDef.VARIANTLIST)) {
					name = {
						name: productDef.DESCRIPTION_NAME ? productDef.DESCRIPTION_NAME : productDef.NAME,
						code: CONSTANTS.VARIANT_I
					};
					if (angular.isDefined(productDef.DESCRIPTION_TAGLINE)) {
						name.tagline = productDef.DESCRIPTION_TAGLINE;
					}

					names.push(name);
				} else {
					angular.forEach(productDef.VARIANTLIST, function(variantDef) {
						name = {
							name: variantDef.DESCRIPTION_NAME ? variantDef.DESCRIPTION_NAME : variantDef.NAME,
							code: variantDef.CODE
						};

						if (angular.isDefined(variantDef.DESCRIPTION_TAGLINE)) {
							name.tagline = variantDef.DESCRIPTION_TAGLINE;
						}
						names.push(name);
					});
				}

				return names;
			};

			/**
			 * [getDescSimple pobiera wiersz opisu: opis i warianty w ktorych wystepje - wystapienie jako checkbox]
			 * @param  {[type]} productDef [definicja produktu, obiektu z productList z RESOURCES]
			 * @param  {[type]} descDef    [definicja opisu, atrybutu obiektu z productList z RESOURCES]
			 * @return {[type]}            [opis]
			 */
			$scope.getDescSimple = function(productDef, descDef) {
				var desc = {
						variants: []
					},
					variant = null;

				if (descDef.NAME) {
					desc.name = descDef.NAME;
				} else if (descDef.COMPLEX_NAME) {
					desc.nameLabel = descDef.COMPLEX_NAME.NAME_LABEL;
					desc.nameList = descDef.COMPLEX_NAME.NAME_LIST;
				}

				if (angular.isArray(productDef.VARIANTLIST) && angular.isArray(descDef.VARIANTS)) {
					angular.forEach(productDef.VARIANTLIST, function(variantDef) {
						variant = {
							code: variantDef.CODE
						};
						if (descDef.VARIANTS.indexOf(variantDef.CODE) !== -1) {
							variant.value = true;
						} else {
							variant.value = false;
						}
						desc.variants.push(variant);
					});
				} else if (!angular.isArray(productDef.VARIANTLIST)) {
					variant = {
						code: CONSTANTS.VARIANT_I,
						value: true
					};
					desc.variants.push(variant);
				}

				return desc;
			};

			/**
			 * [getDescComplex pobiera wiersz opisu: opis i warianty w ktorych wystepje - wystapienie jako lista/opis - rozne per wariant]
			 * @param  {[type]} productDef [definicja produktu, obiektu z productList z RESOURCES]
			 * @param  {[type]} descDef    [definicja opisu, atrybutu obiektu z productList z RESOURCES]
			 * @return {[type]}            [opis]
			 */
			$scope.getDescComplex = function(productDef, descComplexDef) {
				var desc = {
						variants: []
					},
					variant = null;

				if (descComplexDef.NAME) {
					desc.name = descComplexDef.NAME;
				} else if (descComplexDef.COMPLEX_NAME) {
					desc.nameLabel = descComplexDef.COMPLEX_NAME.NAME_LABEL;
					desc.nameList = descComplexDef.COMPLEX_NAME.NAME_LIST;
				}

				if (angular.isArray(productDef.VARIANTLIST)) {
					angular.forEach(productDef.VARIANTLIST, function(variantDef) {
						variant = {
							code: variantDef.CODE
						};
						angular.forEach(descComplexDef.COMPLEX, function(descDef) {
							if (descDef.VARIANTS.indexOf(variantDef.CODE) !== -1) {
								if (descDef.TEXT) {
									variant.text = descDef.TEXT;
								} else if (descDef.TEXT_LIST) {
									variant.textList = descDef.TEXT_LIST;
								}
							}
						});
						desc.variants.push(variant);
					});
				} else if (!angular.isArray(productDef.VARIANTLIST)) {
					variant = {
						code: CONSTANTS.VARIANT_I
					};

					if (descComplexDef[0].TEXT) {
						variant.text = descComplexDef[0].TEXT;
					} else if (descComplexDef[0].TEXT_LIST) {
						variant.textList = descComplexDef[0].TEXT_LIST;
					}

					desc.variants.push(variant);
				}

				return desc;
			};

			$scope.init();
		}
	]);