angular.module('lsnBase.models')
  .factory('LsnUnderwriterModelV1', ['LsnAbstractModel', 'lsnModelFactory',
    function(LsnAbstractModel, lsnModelFactory)
    {

      var LsnUnderwriterModelV1 = function()
      {
        this.objectName = 'Underwriter';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData:
          {
            type: 'MetaData'
          },
          name:
          {
            type: 'string'
          },
          surname:
          {
            type: 'string'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.name = null; // string, imię
        this.surname = null; // string, nazwisko
      };

      LsnUnderwriterModelV1.prototype = LsnAbstractModel.prototype;

      return LsnUnderwriterModelV1;
    }
  ]);