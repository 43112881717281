angular.module('ihestiaRestServicesBase')
  .factory('iSkanerPoliciesFrpSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var ISkanerPoliciesFrpSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{apiVer}/{resource}{id}{subResource}';

        this.businessArea = 'ehscanner/frpconnector';
        this.resource = 'admin';

        /**
         * Przesylanie pliku z serwera iSkanera do frp
         *
         * @param {*} data
         * @returns {*}
         */
        this.sendFiles = function(tokenFrp, iSkanerFileId, iSkanerTokenId) {
          return self.post('save/' + tokenFrp + '/iskaner/' + iSkanerFileId + '/' + iSkanerTokenId, {});
        };

        this.checkStatus = function() {
          return self.get('status');
        };
      };
      return new ISkanerPoliciesFrpSvc();
    }
  ]);