angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminMessageAbstract', ['IHestiaRestServiceAbstractDef', 'ihestiaCommonErrorHandler', 'ihestiaCommonAdminSystemMessageModel',
    function(IHestiaRestServiceAbstractDef, ihestiaCommonErrorHandler, ihestiaCommonAdminSystemMessageModel) {

      var AdminMessageAbstract = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.port = '8282';
        this.path = 'system-messages-manager-web';

        /**
         * Parsujemy wiadomosci z uslugi na nasz model
         * @param  {object} response [description]
         * @return {object}          [description]
         */
        this.getParsedMessages = function(response) {
          if (response.data.Status !== 'Ok') {
            // cos poszlo nie tak
            ihestiaCommonErrorHandler.throwException({
              code: 500,
              message: 'Błąd ładowania listy'
            });
          }
          var messages = [];
          // parsujemy wiadomosci
          angular.forEach(response.data.systemMessages, function(systemMessage) {
            // tak naprawde, to nigdy nie powinnismy dostac takiego obiektu
            if (systemMessage.status !== 'DELETED') {
              // mapujemy wynik z resta na nasz obiekty
              var message = new ihestiaCommonAdminSystemMessageModel();
              message.setData(systemMessage);
              // dodajemy wiadomosc do kolejki
              messages.push(message);
            }
          });

          // zwracamy dane
          return messages;
        };
      };
      return AdminMessageAbstract;
    }
  ]);