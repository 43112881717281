/**
 * Odpala funkcję zadeklarowaną w dyrektywie po wciśnięciu klawisza ESC na elemencie
 */
angular.module('lsnBase.directives')
    .directive('ngEsc', function() {
        return function(scope, element, attrs) {
            element.on('keydown keypress', function(event) {
                if(event.which === 27) {
                    scope.$apply(function(){
                        scope.$eval(attrs.ngEsc);
                    });
                    event.preventDefault();
                }
            });

            scope.$on( '$destroy', function() {
                element.off('keydown keypress');
            });
        };
    });