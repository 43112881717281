angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorImportCtrl', ['$scope', 'ihestiaIkomunikatorChooseCorrespondenceTypeHelper', 'ihestiaIkomunikatorSearchPersonHelper', 'fileServerSvc',
    function($scope, ihestiaIkomunikatorChooseCorrespondenceTypeHelper, ihestiaIkomunikatorSearchPersonHelper, fileServerSvc) {
      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        maxChunkSize: 4000000,
        messages: {
          maxNumberOfFiles: 'Przekroczono maksymalną liczbę plików',
          acceptFileTypes: 'Typ pliku nie jest dozwolony',
          maxFileSize: 'Plik jest za duży',
          minFileSize: 'Plik jest za mały'
        },
        name: 'iKomunikatorImport',
        hideQueueList: true
      };

      $scope.showSearchPersonModal = function() {
        ihestiaIkomunikatorSearchPersonHelper.showModal();
        ihestiaIkomunikatorChooseCorrespondenceTypeHelper.hideModal();
      };

      $scope.init();
    }
  ]);