/**
 * Abstrakcyjny kontroler, ktorego można dziedziczyć 
 * Należy używać z helperem dziedziczącym po IHestiaAbstractModalPromiseHelper
 */
angular.module('ihestiaCommonDirectives')
  .controller('IHestiaAbstractModalPromiseCtrl', ['$scope', 'iHestiaCommonModalHelper',
    function($scope, iHestiaCommonModalHelper) {

      /**
       * Init
       * @return {unefined}
       */
      $scope._init = function(){
        if(!$scope.modalHelper) {
          iHestiaCommonModalHelper.throwException('No modalHelper set!');
        }

        /** Modal zamknięty, wykonujemy odpowiednią akcję */
        $scope.$on('$destroy', function(){
          $scope.modalHelper.modalDestroyed();
        });
      };
    }
  ]);