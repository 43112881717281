angular.module('salesPath2')
  .controller('ceidgClientFoundCtrl', ['$scope', '$rootScope', 'ceidgModalHelper', 'personHelper', 'navigationHelper', 'CONSTANTS', 'AddressModelConstants', 'clientAddHelper', 'PersonModel', 'applicationDictionaryHelper',
    function($scope, $rootScope, ceidgModalHelper, personHelper, navigationHelper, CONSTANTS, AddressModelConstants, clientAddHelper, PersonModel, applicationDictionaryHelper) {
      $scope.init = function() {
        $scope.clientFound = ceidgModalHelper.modals.ceidgClientFoundModal.data;
      };

      $rootScope.$on('iHestiaCommonModalOkBtnClicked.ceidgClientFoundModal', function() {
        $scope.loadPersonDetails();
      });

      $scope.loadPersonDetails = function() {
        var ceidgData = $scope.clientFound;
        var address = {
          code: AddressModelConstants.ADDRESS_TYPE_STAL
        };

        //sprawdzamy czy w adresie znajduje sie kod pocztowy
        var postalCode = /[0-9]{2}-[0-9]{3}/g;
        if (postalCode.test(ceidgData.businessAddress)) {
          address.postalCode = ceidgData.businessAddress.match(/[0-9]{2}-[0-9]{3}/g).shift();
        }

        var person = new PersonModel(),
          personData = person.getData();

        personData.addresses.push(address);
        personData.firstName = ceidgData.firstName;
        personData.lastName = ceidgData.lastName;
        personData.nip = ceidgData.nip;
        var pkds = [];
        angular.forEach(ceidgData.pkdList, function(pks) {
          pkds.push(pks.split(':')[0]);
        });
        personData.pkd = pkds;

        if (!angular.isObject(personData._additionalData)) {
          personData._additionalData = {};
        }
        personData._additionalData.dirtyStreet = ceidgData.businessAddress;
        personData.regon = ceidgData.regon;

        person.setData(personData);
        applicationDictionaryHelper.loadDictionariesForPerson(person, function() {
          clientAddHelper.tplData.personData = personData; //zasilamy kolejną stronę danymi osoby
          navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_EDIT);
        });


      };
    }
  ]);