angular.module('salesPath2')
  .controller('dataTransferModalCtrl', ['$scope', 'dataTransferModalHelper',
    function($scope, dataTransferModalHelper) {

      $scope.tplData = dataTransferModalHelper.tplData;

      $scope.init = function() {
        dataTransferModalHelper.init();
      };

      /**
       * transfer data from current calculation to new one
       * @param  {Object} item item form RESOURCES.DATA_TRANFER
       */
      $scope.transferTo = function(item) {
        dataTransferModalHelper.transferTo(item);
      };

      $scope.init();

    }
  ]);