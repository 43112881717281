angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaDashboardHelper', [function(){

    var DashboardHelper = function() {

      var self = this;

      /**
       * Zwraca domyślny program na dashboard
       * @param  {array} programList lista programów
       * @return {Object|null} domyslny program
       */
      this.getDefaultProgram = function(programList) {
        var statusesOrder = ['ACTIVE', 'SHARED', 'CLOSED'];
        var program = null;
        angular.forEach(statusesOrder, function(statusCode){
          if(program === null) {
            program = self.getFistItemWithstatus(programList, statusCode);
          }
        });

        return program;
      };

      /**
       * Zwracamy pierwszy program o zadanym statusie
       * 
       * @param  {array} programList lista programów
       * @param  {String} statusCode status
       * @return {Object|null} program
       */
      this.getFistItemWithstatus = function(programList, statusCode) {
        var searchedProgram = null;
        angular.forEach(programList, function(program){
          if(searchedProgram === null && program.status.code === statusCode) {
            searchedProgram = program;
          }
        });

        return searchedProgram;
      };
    };

    return new DashboardHelper();
  }]);