angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskContentModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper', 'iHestiaCommonModalHelper', '$timeout', 'IhestiaDashboardObjectModelConstants',
    'policiesSvc', 'fileServerSvc', 'ihestiaFirstInfoOneListUploaderHelper', 'IhestiaDashboardAttachmentModel', 'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaCommonErrorHandler', '$alert', '$rootScope', 'claimsSvc', '$filter', 'ihestiaConfigHelper', 'IhestiaDashboardEhlTaskTopicDict', 'applicationsSvc', 'ihestiaDictionaryHelper', 'ihestiaSsoBaseInfoHelper', '$q',
    function($scope, ihestiaDashboardNewTaskHelper, iHestiaCommonModalHelper, $timeout, IhestiaDashboardObjectModelConstants, policiesSvc, fileServerSvc, ihestiaFirstInfoOneListUploaderHelper,
             IhestiaDashboardAttachmentModel, ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaCommonErrorHandler, $alert, $rootScope, claimsSvc, $filter, ihestiaConfigHelper, IhestiaDashboardEhlTaskTopicDict, applicationsSvc, ihestiaDictionaryHelper, ihestiaSsoBaseInfoHelper, $q) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;
      $scope.screensToSkip = ihestiaDashboardNewTaskHelper.screensToSkip;
      $scope.fieldsToSkip = ihestiaDashboardNewTaskHelper.fieldsToSkip;
      $scope.policies = [];
      $scope.claims = [];
      $scope.applications = [];
      $scope.modalData = {
        selectedPolicy: null,
        selectedClaim: null,
        selectedApplication: null,
        selectedRequiredSupplement: []
      };
      $scope.objectModelConstants = IhestiaDashboardObjectModelConstants;
      $scope.isLife = ihestiaDashboardNewTaskHelper.isLife();
      $scope.isCentral = ihestiaDashboardNewTaskHelper.isCentral;
      $scope.isUwr = ihestiaSsoBaseInfoHelper.getUser().character.isUwr();
      $scope.ehlTaskTopicDict = IhestiaDashboardEhlTaskTopicDict;
      $scope.selctedClientFromList = null;
      $scope.requiredSupplementDict = [];

      $scope.multiselectLocalLang = {
        selectAll: $filter('translate')('Public.multiselectSelectAll'),
        selectNone: $filter('translate')('Public.multiselectSelectNone'),
        reset: $filter('translate')('Public.multiselectReset'),
        search: $filter('translate')('Public.multiselectSearch'),
        nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
        noOption: $filter('translate')('Public.multiselectNoOption')
      };

      $scope.showElements = {
        clientInfoBox: null,
        concernsGroup: null,
        concernsPolicyNumberSelect: null,
        concernsApplicationNumberSelect: null,
        policyNumber: null,
        applicationNumber: null,
        applicationSlashPolicyNumber: null,
        requiredSupplement: null
      };
      $scope.requiredElements = {
        policyNumber: null
      };

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        maxChunkSize: 4000000,
        maxNumberOfFiles: 10,
        treatMesageAsI18nCode: true,
        messages: {
          maxNumberOfFiles: ['maxFileCountExceeded', {
            componentCode: 'Public'
          }],
          acceptFileTypes: ['incorrectFileType', {
            componentCode: 'Public'
          }],
          maxFileSize: ['toLargeFile', {
            componentCode: 'Public'
          }],
          minFileSize: ['toSmallFile', {
            componentCode: 'Public'
          }]
        },
        name: 'dashboard',
        hideQueueList: true,
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        }
      };

      $scope.oneListUploaderOptions = {
        attachmentType: 'static'
      };

      $scope.selectedClientCountryCode = null;

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.selectedClientFromList = ihestiaDashboardNewTaskHelper.selectedClientFromList;
        $scope.selectedClientCountryCode = ihestiaDashboardNewTaskHelper.selectedClientCountryCode;
        $scope.fireListeners();
        $scope.initUploader();
        $scope.loadConcerns();
        $scope.refreshShowElements();
        if ($scope.showElements.requiredSupplement) {
          $scope.initRequiredSupplementDict();
        }
      };

      /**
       * Resolves visibility for policy number input for both contexts (EHL and EHN)
       * @param {string} category - task category
       * @returns {boolean}
       */
      $scope.resolvePolicyNumberVisibility = function() {
        var taskTopicVisibilityCondition = true;
        if ($scope.isLife) {
          if ($scope.isCentral) {
            var centralPolicyNumberRelatedTopics = [
              $scope.ehlTaskTopicDict.POLICY_QUESTION,
              $scope.ehlTaskTopicDict.POLICY_SCAN_DASH,
              $scope.ehlTaskTopicDict.CLAIMS_DASH,
              $scope.ehlTaskTopicDict.POLICY_OUTSIDE_IHESTIA,
              $scope.ehlTaskTopicDict.PERSONAL_POLICY_OUTSIDE_IHESTIA
            ];
            taskTopicVisibilityCondition = centralPolicyNumberRelatedTopics.indexOf($scope.taskData.taskDefinitionCode) > -1;
          } else {
            taskTopicVisibilityCondition = $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.CLIENT_POLICY_DASH || $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.PAYMENTS_DASH || $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.PERSONAL_POLICY_OUTSIDE_IHESTIA;
          }
        }
        return taskTopicVisibilityCondition && !$scope.loadingConcerns && ($scope.policies.length === 0 || $scope.fieldsToSkip.policyNumber || ($scope.modalData.selectedPolicy && $scope.modalData.selectedPolicy.code === 'other')) && $scope.taskData.object.type === $scope.objectModelConstants.TYPE_POLICY;
      };

      /**
       * resolves application number input visibility
       * @return {boolean}
       */
      $scope.resolveApplicationNumberVisibility = function() {
        return $scope.isLife && $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.APPLICATION_REPEAL_DASH && $scope.modalData.selectedApplication && $scope.modalData.selectedApplication.code === 'other'
          && !$scope.loadingConcerns && ($scope.applications.length === 0 || $scope.fieldsToSkip.applicationNumber || ($scope.modalData.selectedApplication && $scope.modalData.selectedApplication.code === 'other')) && $scope.taskData.object.type === $scope.objectModelConstants.TYPE_APPLICATION;
      };

      /**
       * Triggers on concerns policy change
       */
      $scope.onConcernsPolicyChange = function() {
        $scope.showElements.policyNumber = $scope.resolvePolicyNumberVisibility();
      };

      $scope.onConcernsApplicationChange = function() {
        $scope.showElements.applicationNumber = $scope.resolveApplicationNumberVisibility();
      };

      /**
       * Resolves requirement of policy number input for both contexts (EHL and EHL)
       * @param {string} category - task category
       * @returns {*}
       */
      $scope.resolvePolicyNumberRequirement = function(category) {
        var centralPolicyNumberRequiredTopics = [
          $scope.ehlTaskTopicDict.POLICY_QUESTION,
          $scope.ehlTaskTopicDict.POLICY_SCAN_DASH,
          $scope.ehlTaskTopicDict.CLAIMS_DASH,
          $scope.ehlTaskTopicDict.PERSONAL_POLICY_OUTSIDE_IHESTIA
        ];
        // required only for EHL Central and given task topics
        return !$scope.isLife || !$scope.isCentral ? true : centralPolicyNumberRequiredTopics.indexOf(category) > -1;
      };

      /**
       * Resolves whether concerns group (select element with concerns) should be shown
       * @return {boolean}
       */
      $scope.resolveConcernsGroupVisibility = function() {
        var ehlVisibilityCondition = true;

        if ($scope.isLife) {
          ehlVisibilityCondition = !$scope.isCentral && $scope.selectedClientFromList && _.includes([$scope.ehlTaskTopicDict.CLIENT_POLICY_DASH, $scope.ehlTaskTopicDict.PAYMENTS_DASH, $scope.ehlTaskTopicDict.PERSONAL_POLICY_OUTSIDE_IHESTIA /*TODO switch visibility on when service is ready*//*, $scope.ehlTaskTopicDict.APPLICATION_REPEAL_DASH*/], $scope.taskData.taskDefinitionCode);
        }
        return ehlVisibilityCondition && ($scope._resolveConcernsArrayLoaded($scope.policies, $scope.objectModelConstants.TYPE_POLICY) || $scope._resolveConcernsArrayLoaded($scope.claims, $scope.objectModelConstants.TYPE_CLAIM) || $scope._resolveConcernsArrayLoaded($scope.applications, $scope.objectModelConstants.TYPE_APPLICATION));
      };

      /**
       * Resolves whether concerns collection was loaded with items
       * @param {{code: string, name: string}[]} collection
       * @param {string} objectType
       * @return {boolean}
       * @private
       */
      $scope._resolveConcernsArrayLoaded = function(collection, objectType) {
        return collection.length > 0 && $scope.taskData.object.type === objectType;
      };

      /**
       * initializes showElements object
       */
      $scope.refreshShowElements = function() {
        $scope.showElements.clientInfoBox = !($scope.isLife && $scope.isCentral && $scope.taskData.taskDefinitionCode !== $scope.ehlTaskTopicDict.COMPLAINT_DASH && $scope.taskData.taskDefinitionCode !== $scope.ehlTaskTopicDict.CONTACT_CLIENT_URGENT_DASH);
        $scope.showElements.concernsGroup = $scope.resolveConcernsGroupVisibility();
        $scope.showElements.concernsPolicyNumberSelect = !$scope.isLife ?
          $scope.taskData.object.type === $scope.objectModelConstants.TYPE_POLICY
          : !$scope.isCentral && ($scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.CLIENT_POLICY_DASH || $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.PAYMENTS_DASH || $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.PERSONAL_POLICY_OUTSIDE_IHESTIA);
        // TODO switch visibility on when service is ready
        $scope.showElements.concernsApplicationNumberSelect = false && $scope.isLife && !$scope.isCentral && $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.APPLICATION_REPEAL_DASH;
        $scope.showElements.policyNumber = $scope.resolvePolicyNumberVisibility();
        // TODO switch visibility on when service is ready
        $scope.showElements.applicationNumber = false && $scope.resolveApplicationNumberVisibility();
        $scope.showElements.applicationSlashPolicyNumber = $scope.isLife && $scope.resolveApplicationSlashPolicyNumberVisibility();
        $scope.showElements.requiredSupplement = $scope.isLife && $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.APPLICATION_COMPLETION_UWR && $scope.isUwr;
        $scope.requiredElements.policyNumber = $scope.resolvePolicyNumberRequirement($scope.taskData.taskDefinitionCode);
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaDashboardNewTaskContent', function($event) {
          $event.preventDefault();
          // odpalenie okna wyboru innego klienta

          if (ihestiaFirstInfoOneListUploaderHelper.areFileToSend($scope.uploaderOptions.name)) {
            $scope.$emit('showBlockUi', {
              label: $filter('translate')('sendFileWith', {
                componentCode: 'Public',
                params: {
                  numberSendedFile: 1,
                  allNumberFiles: $scope.fileModels.length
                }
              })
            });
            ihestiaFirstInfoOneListUploaderHelper.startSendingFiles($scope.uploaderOptions.name);
          } else {
            if (ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
              $scope.saveTask();
            }
          }

        });
      };

      $scope.initUploader = function() {
        ihestiaFirstInfoOneListUploaderHelper.init($scope.uploaderOptions.name, {
          onFileUploadDone: $scope.onFileUploadDone,
          onErrorSendingFile: $scope.onErrorSendingFile,
          onFileUploadAdd: $scope.onFileUploadAdd,
          onErrorAddingFile: $scope.checkAndBlockUpload,
          onAllFileSend: $scope.onAllFileSend
        }, null, {
          keepBrokenFileInLibraryUploader: true
        });

        ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(null, {
          context: 'Dashboard'
        }, null, function(result) {
          if (angular.isDefined(result.data)) {
            ihestiaFirstInfoOneListUploaderHelper.setSelectedDocumentType($scope.uploaderOptions.name, result.data[0]);
          } else {
            ihestiaCommonErrorHandler.throwException($filter('translate')('serviceDidNotReturnDocumentType', {
              componentCode: 'Public'
            }));
          }
        }, function() {
          ihestiaCommonErrorHandler.throwException($filter('translate')('serviceDidNotReturnDocumentType', {
            componentCode: 'Public'
          }));
        });

        $scope.fileModels = ihestiaFirstInfoOneListUploaderHelper.getAllfiles($scope.uploaderOptions.name);
      };

      /**
       * Ładujemy odpowiednie dotyczy
       * @return {undefined}
       */
      $scope.loadConcerns = function() {
        if ($scope.taskData.object.client && $scope.taskData.object.client.ikontoId) {
          // polisy
          if ($scope.taskData.object.type === IhestiaDashboardObjectModelConstants.TYPE_POLICY) {
            $scope.loadingConcerns = true;
            $scope.policies = [];
            policiesSvc.findByParams({
              additionalParams: {
                dynamicFilters: {
                  customerAccountId: $scope.taskData.object.client.ikontoId + ''
                },
                sortBy: null,
                httpParams: {
                  allowedStatuses: [403] // 403 w przypadku agenta obcego, próbującego się dostać do listy polis klienta,
                }
              }
            }).then(function(res) {
              if (res && res.data && res.data.items && res.data.items.length > 0) {
                angular.forEach(res.data.items, function(item) {
                  var policyLabel = !$scope.isLife ? item.policyNumber + ' (' + item.insuredObjectName + ')'
                    : '{0} / {1} / {2} '.format(item.policyNumber, $filter('date')(item.acceptedDate), $filter('stringPremium')(item.premium));
                  $scope.policies.push({
                    code: item.policyNumber,
                    name: policyLabel
                  });
                });
                $scope.policies.push({
                  code: 'other',
                  name: $filter('translate')('other', {
                    componentCode: 'Public'
                  })
                });
              }
            }, angular.noop)
              .finally(function() {
                $scope.loadingConcerns = false;
                $scope.refreshShowElements();
              });
          }

          // szkody
          if ($scope.taskData.object.type === IhestiaDashboardObjectModelConstants.TYPE_CLAIM) {
            $scope.loadingConcerns = true;
            $scope.claims = [];
            claimsSvc.get(null, {
              clientAccountNumber: $scope.taskData.object.client.ikontoId
            }, null, function(res) {
              if (res && res.data && angular.isArray(res.data) && res.data.length > 0) {
                angular.forEach(res.data, function(item) {
                  $scope.claims.push({
                    code: item.claimNumber,
                    policyNumber: item.policyNumber,
                    name: item.claimNumber + ' (' + item.policyNumber + ')'
                  });
                });
                $scope.claims.push({
                  code: 'other',
                  name: $filter('translate')('other', {
                    componentCode: 'Public'
                  })
                });
              }
            }).finally(function() {
              $scope.loadingConcerns = false;
              $scope.refreshShowElements();
            });
          }

          // wnioski
          if ($scope.taskData.object.type === IhestiaDashboardObjectModelConstants.TYPE_APPLICATION) {
            $scope.loadingConcerns = true;
            $scope.applications = [];
            //TODO czekamy na odpowiednią usługę do pobrania wniosków od Ateny
            $q.when()
              .then(function(res) {
                if (res && res.data && angular.isArray(res.data) && res.data.length > 0) {
                  angular.forEach(res.data, function(item) {
                    $scope.applications.push({
                      applicationNumber: item.applicationNumber,
                      name: ''
                    });
                  });
                  // $scope.applications.push({
                  //   code: 'other',
                  //   name: $filter('translate')('otherMale', {
                  //     componentCode: 'Public'
                  //   })
                  // });
                }
                $scope.applications.push({
                  code: 'other',
                  name: $filter('translate')('otherMale', {
                    componentCode: 'Public'
                  })
                });
              })
              .finally(function() {
                $scope.loadingConcerns = false;
                $scope.refreshShowElements();
              });
          }
        }

      };

      $scope.saveTask = function() {
        $scope.formSubmitted = true;
        if ($scope.createTaskForm && $scope.createTaskForm.$valid) {
          $scope.$emit('hideBlockUi');
          $scope.$emit('showBlockUi', {
            label: $filter('translate')('savesTask', {
              componentCode: 'Dashboard'
            })
          });

          // select polisy
          if ($scope.modalData.selectedPolicy && $scope.modalData.selectedPolicy.code !== 'other') {
            $scope.taskData.object.policyNumber = $scope.modalData.selectedPolicy.code;
          }

          // select szkody
          if ($scope.modalData.selectedClaim && $scope.modalData.selectedClaim.code !== 'other') {
            $scope.taskData.object.claimNumber = $scope.modalData.selectedClaim.code;
            $scope.taskData.object.policyNumber = $scope.modalData.selectedClaim.policyNumber;
          }

          // select wnioski
          if ($scope.modalData.selectedApplication && $scope.modalData.selectedApplication.code !== 'other') {
            $scope.taskData.object.applicationNumber = $scope.modalData.selectedApplication.code;
          }

          if ($scope.showElements.requiredSupplement) {
            if (!$scope.taskData.attributes) {
              $scope.taskData.attributes = {};
            }
            $scope.taskData.attributes.requiredSupplement = $scope._reduceRequiredSupplementToPayloadArray($scope.modalData.selectedRequiredSupplement);
          }

          ihestiaDashboardNewTaskHelper.saveTask().then(function(res) {
            iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskContent');
            if (ihestiaDashboardNewTaskHelper.taskData.parentId) {
              $alert({
                content: $filter('translate')('taskLinkedHasBeenCreated', {
                  componentCode: 'Dashboard'
                }),
                type: 'success'
              });
              // IHESTLIFE-2944
              if ($scope.isLife) {
                $rootScope.$broadcast('ihestiaDashboardTaskAdded');
              }
            } else if (ihestiaDashboardNewTaskHelper.isCentral) {
              if (angular.isObject(res) && angular.isFunction(res.headers) && angular.isString(res.headers().entityid)) {
                var taskNumber = res.headers().entityid;

                $alert({
                  content: $filter('translate')('taskNumberHasBeenCreated', {
                    componentCode: 'Dashboard',
                    params: {taskNumber: taskNumber},
                    md: {
                      link: [{
                        class: 'link link-white link-underline',
                        href: ihestiaConfigHelper.getUrl('DASHBOARD_URL', 'external/task/' + taskNumber)
                      }]
                    },
                    flags: {
                      noSceSet: true
                    }
                  }),
                  type: 'success'
                });
              }
              $rootScope.$broadcast('ihestiaDashboardTaskAdded');

            } else {
              $alert({
                content: $filter('translate')('taskHasBeenCreated', {
                  componentCode: 'Dashboard'
                }),
                type: 'success'
              });
            }

          }, angular.noop).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        }
      };

      /**
       * Inny klient
       * @return {undefined}
       */
      $scope.chooseAnotherClient = function() {
        iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskContent');
        $timeout(function() {
          iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskClientSearch');
        }, 0);
      };

      $scope.deleteFile = function(id) {
        $scope.deleteFileFromAttachment(id);
        ihestiaFirstInfoOneListUploaderHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      $scope.deleteFileFromAttachment = function(id) {
        var fileServerId = $scope.fileModels[id].fileServerId;
        angular.forEach($scope.taskData.attachments, function(attachment, key) {
          if (attachment.fileServerId === fileServerId) {
            $scope.taskData.attachments.splice(key, 1);
          }
        });
      };

      $scope.onFileUploadDone = function() {
        var numberSendedFile = ihestiaFirstInfoOneListUploaderHelper.getProcessedFileIndex($scope.uploaderOptions.name) + 1;
        if (numberSendedFile < $scope.fileModels.length) {
          $scope.$emit('hideBlockUi');
          $scope.$emit('showBlockUi', {
            label: $filter('translate')('sendFileWith', {
              componentCode: 'Public',
              params: {
                numberSendedFile: numberSendedFile,
                allNumberFiles: $scope.fileModels.length
              }
            })
          });
        }
      };

      $scope.onErrorSendingFile = function() {
        $scope.$emit('hideBlockUi');
      };

      $scope.onAllFileSend = function(data) {
        if (data.withoutError) {
          $scope.fileMessageError = false;
          angular.forEach(data.files, function(fileModel) {
            //prosze nie usuwac , badz zapewnic istnienie
            if (!angular.isArray($scope.taskData.attachments)) {
              $scope.taskData.attachments = [];
            }
            var attachment = new IhestiaDashboardAttachmentModel();
            attachment.fileName = fileModel.name;
            attachment.fileSize = fileModel.size;
            attachment.fileType = fileModel.type;
            attachment.fileServerId = fileModel.fileServerId;
            $scope.taskData.attachments.push(attachment);
          });
          $scope.$emit('hideBlockUi');
          $scope.saveTask();
        } else {
          $scope.fileMessageError = true;
          $scope.$emit('hideBlockUi');
        }
      };

      /**
       * Initializes RequiredSupplement dictionary
       */
      $scope.initRequiredSupplementDict = function() {
        var params = {
          pageSize: 1000,
          businessArea: 'dashboard',
          resource: 'resources/dictionaries/content/RequiredSupplementDict'
        };
        ihestiaDictionaryHelper.getDictWithPromise('RequiredSupplementDict', params)
          .then(function(res) {
            if (res) {
              $scope.requiredSupplementDict = $scope._mapRequiredSupplementToViewArray(res);
            }
          }, lsnNg.noop);
      };


      /**
       * Maps requiredSupplement dictionary objects to array for use in multiselect
       * @param {Object} requiredSupplementObject
       * @returns {{code: string, value: string, selected: boolean}[]}
       * @private
       */
      $scope._mapRequiredSupplementToViewArray = function(requiredSupplementObject) {
        var keysArray = Object.keys(requiredSupplementObject);
        var requiredSupplemetDict = [];
        for (var i = 0; i < keysArray.length; ++i) {
          requiredSupplemetDict.push({
            code: keysArray[i],
            value: requiredSupplementObject[keysArray[i]],
            selected: false
          });
        }
        return requiredSupplemetDict;
      };

      /**
       * Reduces array of selected requiredSupplement objects to array of requiredSupplement codes
       * @param {{code: string, selected: boolean}[]} selectedRequiredSupplement
       * @returns {string[]}
       * @private
       */
      $scope._reduceRequiredSupplementToPayloadArray = function(selectedRequiredSupplement) {
        var reducedRequiredSupplement = [];
        angular.forEach(selectedRequiredSupplement, function(requiredSupplementViewObj) {
          reducedRequiredSupplement.push(requiredSupplementViewObj.code);
        });
        return reducedRequiredSupplement;
      };

      $scope.resolveApplicationSlashPolicyNumberVisibility = function() {
        if ($scope.isCentral) {
          return [$scope.ehlTaskTopicDict.COMPLAINT_DASH, $scope.ehlTaskTopicDict.CONTACT_CLIENT_URGENT_DASH, $scope.ehlTaskTopicDict.COMMISSIONS_DASH].indexOf($scope.taskData.taskDefinitionCode) > -1;
        } else {
          return $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.COMPLAINT_DASH;
        }

      };

      $scope.init();
    }
  ]);
