/**
 * The instance of this class can be passed to IhestiaClauseList directive to programmatically have control over and access to
 * created clause list:
 * - get current clause list
 * - add listener when seleced all property of clause list changes
 * - select all / expand all clauses
 * - connect other clause list helpers and their callbacks together
 */
angular.module('ihestiaWidgets.life')
  .factory('IhestiaClauseListHelper', ['$filter', 'LsnClausesModelConstants',
    function($filter, LsnClausesModelConstants) {
      return function(settings) {
        /**
         * @typedef PartnerClauseModel
         * @property {object} attributes
         * @property {string} code
         * @property {boolean} isDisabled
         * @property {boolean} isReadOnly
         * @property {any} metaData
         * @property {boolean} noCheckbox
         * @property {boolean} selected
         * @property {{shortText: string, fullText: string}} texts
         * @property {string} type
         * @property {string} value
         * @property {number} version
         * @property {boolean} hidden
         */
        var self = this;

        this.tplData = {
          selectedAll: false,
          expandedAll: false
        };

        this.defaultSettings = {
          showElements: {
            selectAll: true,
            expandAll: true
          },
          disabledElements: {
            selectAll: false
          },
          labels: {
            selectAll: $filter('translate')('clauseList.selectAll', {componentCode: 'Public'}),
            expandAll: $filter('translate')('clauseList.expandAll', {componentCode: 'Public'}),
            collapseAll: $filter('translate')('clauseList.collapseAll', {componentCode: 'Public'}),
            groupHeader: null
          },
          shortTextProperty: 'shortText',
          readOnly: false,
          clauseList: [],
          type: null,
          errors: null,
          initiallyExpanded: false // show fullText at start
        };

        this.callbacks = {
          selectedAllChanged: []
        };

        /**
         *
         * @type {IhestiaClauseListHelper[]}
         */
        this.connectedHelpers = null;

        this.initData = function(settings) {
          self.settings = _.merge({}, self.defaultSettings, settings);
          self.tplData.selectedAll = self.allClausesSelected();
          self.settings.disabledElements.selectAll = self.allClausesDisabled();
          self.tplData.expandedAll = settings.initiallyExpanded;
        };

        /**
         * switches select all to given value or value opposite to present one
         * @param selectAll
         * @returns {*|boolean}
         */
        this.toggleSelectAll = function(selectAll) {
          var selectedAllBeforeChange = self.allClausesSelected();
          self.tplData.selectedAll = selectAll !== undefined ? selectAll : !self.tplData.selectedAll;
          self.settings.clauseList = self._changeGroupClauseSelection(self.tplData.selectedAll);
          self.forEachConnectedHelper(function(helper) {
            selectedAllBeforeChange = selectedAllBeforeChange && helper.allClausesSelected();
            helper.tplData.selectedAll = self.tplData.selectedAll;
            helper.settings.clauseList = helper._changeGroupClauseSelection(self.tplData.selectedAll);
          });
          if (selectedAllBeforeChange !== self.tplData.selectedAll) {
            self._triggerSelectedAllListeners();
          }
          return self.tplData.selectedAll;
        };

        /**
         * switches expand all to given value or value opposite to present one
         * @param expandAll
         * @returns {*|boolean}
         */
        this.toggleExpandAll = function(expandAll) {
          self.tplData.expandedAll = expandAll !== undefined ? expandAll : !self.tplData.expandedAll;
          self.forEachConnectedHelper(function(helper) {
            if (helper.settings.showElements.expandAll) {
              helper.toggleExpandAll(expandAll);
            }
          });
          return self.tplData.expandedAll;
        };

        /**
         * Checks if all clauses in clauseList have value set as spLifeConstants.CLAUSE_VALUE_AGREEMENT,
         * if clause list is empty returns false
         * @return {boolean}
         * @private
         */
        this.allClausesSelected = function() {
          return _.isEmpty(self.settings.clauseList) ? false
            : _.chain(self.settings.clauseList)
              .filter(self._filterClausesByType(self.settings.type))
              .filter(self._filterOutDisabledClauses)
              .filter(self._filterOutPresentationalClauses)
              .every(function(clause) {
                return clause.value === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
              })
              .value();
        };

        /**
         * Maps clause's value based on selected parameter
         * @param {boolean} selected
         * @return {object} clauseViewObject
         * @private
         */
        this._changeGroupClauseSelection = function(selected) {
          return _.map(self.settings.clauseList, function(clause) {
            if (clause.isDisabled || clause.hidden) {
              return clause;
            }
            if (self.settings.type) {
              if (clause.type === self.settings.type) {
                clause.selected = selected;
                if (selected) {
                  clause.value = LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
                } else {
                  clause.value = LsnClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT;
                }
              }
            } else {
              clause.selected = selected;
              if (selected) {
                clause.value = LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
              } else {
                clause.value = LsnClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT;
              }
            }
            return clause;
          });
        };

        /**
         * handles change on clause's checkbox (changes its value param), checks if all checkboxes are selected to set the selected all checkbox if necessary
         * @param {Number} clauseIdx
         */
        this.onChangeClauseValue = function(clauseIdx) {
          var clause = self.settings.clauseList[clauseIdx];
          if (!clause.selected) {
            clause.value = LsnClausesModelConstants.CLAUSE_VALUE_REFUSAL;
          } else {
            clause.value = LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          }
          self.settings.clauseList.splice(clauseIdx, 1, clause);

          var updatedSelectedAll = self.allClausesSelected();
          self.forEachConnectedHelper(function(helper) {
            updatedSelectedAll = updatedSelectedAll && helper.allClausesSelected();
          });
          if (updatedSelectedAll !== self.tplData.selectedAll) {
            self.tplData.selectedAll = updatedSelectedAll;
            self._triggerSelectedAllListeners();
          }
        };

        /**
         * return clause list
         * @returns {*|Array}
         */
        this.getClauses = function() {
          return self.settings.clauseList;
        };

        /**
         * Registers listener function on select all change event,
         * returns unique listener id by which it can be removed from listeners,
         * if id already exists, replaces it
         * @param callback
         * @param id
         * @returns {string}
         */
        this.addOnSelectedAllChangedListener = function(callback, id) {
          var callbackId = id;
          if (!callbackId) {
            callbackId = Math.random().toString();
          }
          var callbackIndex = _.findIndex(self.callbacks.selectedAllChanged, ['id', callbackId]);
          if (callbackIndex > -1) {
            self.callbacks.selectedAllChanged[callbackIndex] = {
              id: callbackId,
              callback: callback
            };
          } else {
            self.callbacks.selectedAllChanged.push({
              id: callbackId,
              callback: callback
            });
          }
          return callbackId;
        };

        /**
         * Removes listener by given id
         * @param id
         */
        this.removeOnSelectedAllListener = function(id) {
          _.remove(self.callbacks.selectedAllChanged, ['id', id]);
        };

        /**
         * sets clause list on this helper
         * @param clauses
         */
        this.setClauses = function(clauses) {
          self.settings.clauseList = clauses;
          self.tplData.selectedAll = this.allClausesSelected();
        };

        /**
         * invokes all registered listeners for on select all changed event
         * @private
         */
        this._triggerSelectedAllListeners = function() {
          _.forEach(self.callbacks.selectedAllChanged, function(item) {
            item.callback(self.tplData.selectedAll);
          });
        };

        this._filterClausesByType = function(type) {
          return function(clause) {
            if (type) {
              return type === clause.type;
            } else {
              return true;
            }
          };
        };

        this._filterOutDisabledClauses = function(clause) {
          return _.has(clause, 'isDisabled') ? !clause.isDisabled : true;
        };

        this._filterOutHiddenClauses = function(clause) {
          return !clause.hidden;
        };

        this._filterOutPresentationalClauses = function(clause) {
          return !clause.noCheckbox;
        };

        /**
         * Checks whether required clauses are not selected and if so maps them to errors object.
         * If there are no unchecked required (invalid) clauses  returns true
         * @returns {boolean}
         */
        this.validateRequiredClauses = function() {
          self.settings.errors = _.chain(self.settings.clauseList)
            .filter(self._filterClausesByType(self.settings.type))
            .filter(self._filterOutDisabledClauses)
            .filter(self._filterOutPresentationalClauses)
            .filter(self._filterOutHiddenClauses)
            .filter(function(clause) {
              return clause.isRequired && clause.value !== LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
            })
            .map(function(clause) {
              return [clause.code, true];
            })
            .fromPairs()
            .value();

          var isValid = _.isEmpty(self.settings.errors);
          self.forEachConnectedHelper(function(helper) {
            isValid = isValid && helper.validateRequiredClauses();
          });
          return isValid;
        };

        /**
         * Checks whether all clauses are disabled
         * @returns {boolean}
         */
        this.allClausesDisabled = function() {
          return _.isEmpty(self.settings.clauseList) ? false
            : _.chain(self.settings.clauseList)
              .filter(self._filterClausesByType(self.settings.type))
              .filter(self._filterOutHiddenClauses)
              .every(function(clause) {
                return _.has(clause, 'isDisabled') && clause.isDisabled;
              })
              .value();
        };

        /**
         * Attach different clause list helper (helpers) to this helper
         * @param {IhestiaClauseListHelper[] || IhestiaClauseListHelper} clauseListHelpers
         */
        this.connectHelpers = function(clauseListHelpers) {
          if (!self.connectedHelpers) {
            self.connectedHelpers = [];
          }
          if (_.isArray(clauseListHelpers)) {
            self.connectedHelpers = _.concat(self.connectedHelpers, clauseListHelpers);
            _.forEach(clauseListHelpers, function(helper) {
              helper.addOnSelectedAllChangedListener(self._checkSelectedAll);
            });
          } else {
            self.connectedHelpers.push(clauseListHelpers);
            clauseListHelpers.addOnSelectedAllChangedListener(self._checkSelectedAll);
          }
        };

        /**
         * Invokes passed callback for each connected helper
         * @param {function} callback
         */
        this.forEachConnectedHelper = function(callback) {
          if (!_.isEmpty(self.connectedHelpers)) {
            _.forEach(self.connectedHelpers, callback);
          }
        };

        /**
         * Verifies if all clauses and connected clauses are selected and handles selectAll change
         * @param triggerSelectedAll
         * @private
         */
        this._checkSelectedAll = function(triggerSelectedAll) {
          var selectedAllBefore = self.allClausesSelected();
          if (triggerSelectedAll) {
            self.forEachConnectedHelper(function(helper) {
              selectedAllBefore = selectedAllBefore && helper.tplData.selectedAll;
            });
          } else {
            selectedAllBefore = false;
          }
          if (selectedAllBefore !== self.tplData.selectedAll) {
            self.tplData.selectedAll = selectedAllBefore;
            self._triggerSelectedAllListeners();
          }
        };

        /**
         * @param {{[code: string]: boolean | string}} selectionData
         */
        this.setSelection = function(selectionData) {
          _.forOwn(selectionData, function(value, key) {
            var clause = _.find(self.settings.clauseList, ['code', key]);
            if (clause){
              if (typeof value === 'string'){
                clause.selected = value === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
                clause.value = value;
              } else {
                clause.selected = value;
                clause.value = clause.selected ? LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT : LsnClausesModelConstants.CLAUSE_VALUE_REFUSAL;
              }
            }
          });
          self.tplData.selectedAll = this.allClausesSelected();
        };

        this.initData(settings);
      };
    }]
  );
