angular.module('ihestiaRestServicesBase')
   .factory('policyPartnerOperationsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var PolicyOperations = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'policyOperations';
      this.businessArea = 'insurance';
    };

    return new PolicyOperations();
  }])
;