angular.module('ihestiaGridSettings')
  /**
   * model wiadomosci przesylanych miedzy zakladkami
   */
  .factory('IHestiaGridSettingsDataModel', [function () {
      var model = function (data) {
        this.defaultFilterId = null; //identyfikator wymuszonego filtru (jeśli nie podany, to wykorzystany będzie domyślny)
        this.defaultFilterConditions = null; //wyszukuje i wymusza filtr z wartościami takimi jak podane
        this.contextId = ''; //identyfikator kontekstu uzywany w serwisach REST
        this.gridName = null; //grid, do którego nalezy niniejsza konfiguracja
        this.filterTplPath = null; //sciezka do templatki z filtrami
        this.multipleFilterTabs = false; //czy wiele zakładek filtrów
        this.saleProfileTab = null; //w przypadku wielu zakładek filtrów i wymaganiu agencji potrzbujemy wiedzieć jaką zakładkę otworzyć
        this.hideOtherSettings = false; //czy ukryć zakładkę 'Ustawienia'
        this.filterTabs = []; //lista zakładek jeśli multipleFilterTabs, obiekty typu{name: '', code: '', template: '', nameLabelCode: ['taskList.category', {componentCode: 'Dashboard'}]}

        this.columnTabs = []; //lista zakładek, obiekty typu{name: '', code: '', nameLabelCode: ['taskList.baseColumns', {componentCode: 'Dashboard'}]}, fields muszą mieć przypisany tabCode

        this.validators = []; // lista walidacji, obecnie obsługiwane typy:


        this.defaultFilterExtension = {}; //można tu ustawić wartości którymi chcemy nadpisać wartości z domyślnego filtra serwowanego przez usługę
        this.initialFilterExtension = null; //inicjalny filtr, który jest ponad wszystkim

        this.salesNetworksConfig = {}; //konfiguracja sieci sprzedażowych
        this.requestParser = null; //opcjonalna funkcja parsująca filtr przed wrzuceniem go w model
        this.filters = {}; //konfiguracja filtrów
        /** konfiguracja kolumn - na bazie commonGridCtrl.$scope.options.fields
         kod_kolumny: {visible: true|false}
         @type object
         */
        this.columns = {}; //widoczność kolumn, z podziałem na sekcje

        this.salesNetworksLabels = {}; //organizationUnits, salesProfiles, workers
        this.disableAgencyAutoSelect = false; //czy wyłączyć automatyczny wybór agencji jeśli jest jedna na liście

        this.otherFilterData = {}; //dodatkowe dane które chcemy przekazać do templatki filtrów

        var self = this;

        if (angular.isObject(data)) {
          angular.forEach(data, function (val, key) {
            if (typeof self[key] !== 'undefined') {
              self[key] = val;
            }
          });
        }

      };

      return model;
    }]);