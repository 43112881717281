/**
 * Serwis do pobierania podstawowych danych
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase')
  .service('ihestiaIcConflictedHelper', ['$rootScope', 'bodyInjectorHelper', 'ihestiaSsoBaseInfoHelper', 'ihestiaConfigHelper',
    function($rootScope, bodyInjectorHelper, ihestiaSsoBaseInfoHelper, ihestiaConfigHelper) {

      var IHestiaIcConflictedHelper = function() {
        var self = this;

        this.icConflictedModalActive = false;
        this.serviceIc = null;

        /**
         * przechwycienie zmiany kontekstu ic na innej zakładce
         * @param  {[type]} event [description]
         * @param  {IHestiaCrossTabMessageModel} data
         */
        $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
          if (data.code === 'icChanged') {
            self.icConflicted(data.attrs.icCode);
          }
        });

        /**
         * Obsługa konfliktu kontekstu ic
         *
         */
        this.icConflicted = function() {
          // konflikt interesuje nas tylko w przypadku gdy mamy wiele dostępnych kontekstów
          if(!ihestiaConfigHelper.get('frontFlags', 'IS_PUBLIC') || ihestiaConfigHelper.get('ic', 'mode') !== 'MIXED') {

            if (!self.icConflictedModalActive) {
              self.icConflictedModalActive = true;
              bodyInjectorHelper.injectDirective('icConflicted', 'ihestia-ic-conflicted-modal');
            }
          }
        };

        this.getConflictedIc = function() {
          return self.serviceIc;
        };

        this.clearConflictedIc = function() {
          self.serviceIc = null;
        };
      };

      return new IHestiaIcConflictedHelper();
    }
  ]);