angular.module('ihestiaCommonDirectives')
  .factory('ihestiaBasicPaymentHelper', ['paymentsSvc', '$q', '$timeout',
    function(paymentsSvc, $q, $timeout) {

      var IhestiaBasicPaymentHelper = function() {

        var self = this;

        // 3s * 20 = minuta
        this.maxCheck = 20;
        this.checkInterval = 3000;

        /**
         * wyciągamy z osoby dane potrzebne do płatności
         * @param  {[type]} person [description]
         * @return {[type]}        [description]
         */
        this.getCustomerDataFromSpPerson = function(person)
        {
          return {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email
          };
        };

        /**
         * na podstawie polis ze ścieżki tworzymy rekordy płatności
         * @return {array}
         */
        this.getPaymentsFromSpPolicies = function(policies, params)
        {
          if(!params)
          {
            params = {
              allInstallments: false //domyślnie z każdej polisy bierzemy tylko pierwszą ratę
            };
          }
          var paymentsInstallments = [];
          var gotFirstInstallment;
          angular.forEach(policies, function(policy){
            var policyNumber;
            if(policy.number) {
              policyNumber = policy.number;
            } else if(policy.metaData && policy.metaData.id) { // policyProposition from OfferV2 has policy numner in metaData.id
              policyNumber = policy.metaData.id;
            }
            var firstPaymentMethod;
            if(policy.paymentMethod) {
              firstPaymentMethod = policy.paymentMethod;
            } else if(policy.firstPaymentMethod) {
              firstPaymentMethod = policy.firstPaymentMethod;
            }

            var policyRecord = {
              policy: policyNumber,
              firstPaymentMethod: firstPaymentMethod,
              installments: []
            };
            gotFirstInstallment = false;
            angular.forEach(policy.installments, function(installment, key){
              if(params.allInstallments || !gotFirstInstallment)
              {
                policyRecord.installments.push({
                  number: key + 1,
                  paymentDate: installment.date,
                  premium: installment.amount
                });
                gotFirstInstallment = true;
              }
            });
            paymentsInstallments.push(policyRecord);
          });

          return paymentsInstallments;
        };


        /**
         * @return {Boolean}
         */
        this.savePayment = function(paymentsInstallments, customerData, params) {
          var paymentData = {
            policies: paymentsInstallments,
            customerFirstName: customerData.firstName,
            customerLastName: customerData.lastName,
            customerEmail: customerData.email
          };
          if(customerData.id)
          {
            paymentData.customerId = customerData.id;
          }

          if(params.authorizationCode)
          {
            paymentData.authorizationCode = params.authorizationCode;
          }

          if(params && params.isOffer)
          {
            paymentData.paymentType = 'CustomerOfferPayment'; // opłacenie oferty
          }

          var paymentNode = 'epayments';
          if(params && params.recurring)
          {
            paymentNode = 'epayments/recurring'; //płatność cykliczna
          }
          else if(params && params.blik)
          {
            paymentNode = 'epayments/blik'; //płatność BLIK
          }

          return paymentsSvc.ver('v2').post(null, paymentData, paymentNode);
        };

        /**
         * [waitForPayment description]
         * @param  {[type]} paymentId [description]
         * @return {Promise} payment result
         */
        this.waitForPayment = function(paymentId, maxCheck){
          var deferred = $q.defer();
          self.getPaymentStatus(paymentId, deferred, 0, maxCheck);
          return deferred.promise;
        };

        this.getPaymentStatus = function(paymentId, deferred, checkCount, maxCheck) {
          if(!maxCheck) {
            maxCheck = self.maxCheck;
          }

          if(checkCount < maxCheck) {
            paymentsSvc.ver('v2').get('epayment/' + paymentId).then(function(resp){
              if(resp.data && resp.data.paymentStatus !== 'PENDING') {
                deferred.resolve(resp);
              } else {
                $timeout(function() {
                  self.getPaymentStatus(paymentId, deferred, checkCount + 1, maxCheck);
                }, self.checkInterval);
              }
            }, function(rej){
              deferred.reject(rej);
            });
          } else {
            deferred.reject('paymentCheckTimeout');
          }
        };
      };

      return new IhestiaBasicPaymentHelper();
    }
  ]);
