angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskIcrClientEditModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper',
    'ihestiaRestDictionaryAddressesSvc', 'ihestiaRestDictionaryFirstNamesSvc', 'ihestiaRestDictionaryLastNamesSvc',
    function($scope, ihestiaDashboardNewTaskHelper, ihestiaRestDictionaryAddressesSvc,
             ihestiaRestDictionaryFirstNamesSvc, ihestiaRestDictionaryLastNamesSvc) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;

      $scope.addressSvc = ihestiaRestDictionaryAddressesSvc;

      $scope.ihestiaRestDictionaryFirstNamesSvc = ihestiaRestDictionaryFirstNamesSvc;

      $scope.ihestiaRestDictionaryLastNamesSvc = ihestiaRestDictionaryLastNamesSvc;

      $scope.addressAttributeMap = $scope.helper.addressDataMap;

      $scope.maxLength = {
        postalCode: 10,
        post: 100,
        city: 50,
        street: 50,
        house: 10,
        apartment: 10
      };

      $scope.phoneNumberLimitations = {
        minLength: 8,
        maxLength: 16
      };

      $scope.svcValidatorMap = {
        postalCode: 'db_client_postcode',
        city: 'db_client_city',
        streetPrefix: 'db_client_street_prefix',
        street: 'db_client_street',
        house: 'db_client_house_no',
        post: 'db_client_post_office'
      };

      $scope.dashboardNumericSettings = {
        stringCodes: {
          allowLeadingZeros: true,
          parseToString: true,
          formatting: false,
          groupSeparator: '',
          decimals: 0,
          min: 0,
          maxInputLength: 20
        }
      };

      $scope.overridedRequiredFields = {
        postalCode: true,
        post: false,
        city: true,
        street: true,
        house: true,
        apartment: false
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.calculateMinMaxLength();
      };

      $scope.helper.cancel = function() {
        ihestiaDashboardNewTaskHelper.clearTaskData();
        $scope.helper.setPromiseAsRejected();
        return $scope.helper.hideModal();
      };

      $scope.helper.confirm = function() {
        $scope.formSubmitted = true;
        if ($scope.createTaskForm.$valid) {
          $scope.helper.setPromiseAsResolved();
          $scope.helper.hideModal();
        }
      };

      $scope.calculateMinMaxLength = function() {
        $scope.phoneNumberLimitations.minLength = angular.isString($scope.taskData.object.client.phoneNumber)
        && $scope.taskData.object.client.phoneNumber[0] === '+' ? 8 : 7;

        $scope.phoneNumberLimitations.maxLength = angular.isString($scope.taskData.object.client.phoneNumber)
        && $scope.taskData.object.client.phoneNumber[0] === '+' ? 16 : 15;
      };
    }
  ]);
