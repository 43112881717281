angular.module('ihestiaGrid')
  .controller('ihestiaGridOrderReportModalCtrl', ['$scope',
    function($scope) {
      $scope.areaCharLimit = 35;

      $scope.orderReportForm = null;
      $scope.reportTitle = '';
      $scope.exportWithPermissionDate = false;

      $scope.fixedHeaderData = {
        onlyHeader: true
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaGridOrderReportModal', function(){
        $scope.orderReport($scope.reportTitle, $scope.exportWithPermissionDate);
      });
    }
  ]);