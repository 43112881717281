angular.module('ihestiaRestServicesBase')

/**
 * pobieranie obrazka captcha
 */
.service('greenCardsSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var GreenCards = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'greenCards';
    this.businessArea = 'insurance';
    this.port = '8206';
    this.path = 'RestPolicies';

  };
  return new GreenCards();
}]);