angular.module('ihestiaCommonDirectives')
  .run(['bodyInjectorHelper',
    function(bodyInjectorHelper) {
      bodyInjectorHelper.injectDirective('ihestiaCommonConfirmModal', 'ihestia-common-confirm-modal');
    }
  ])
  .service('ihestiaCommonConfirmModalHelper', ['iHestiaCommonModalHelper', '$rootScope', '$filter',
    function(iHestiaCommonModalHelper, $rootScope, $filter) {
      var ConfirmModalHelper = function() {
        var self = this;

        this.defaultConfirmSettings = {
          okBtnName: 'Tak',
          cancelBtnName: 'Anuluj',
          title: 'Potwierdzenie',
          text: '',
          size: 'md',
          template: 'ihestia-commondirectives-template-app/directives/confirm/confirmModalContent.tpl.html',
          otherData: {}, //dodatkowe dane do templatki dostępne w helper.confirmSettings.otherData
          treatLabelAsI18nCode: false,
          okBtnCallback: lsnNg.noop,
          cancelBtnCallback: lsnNg.noop
        };

        this.confirmSettings = angular.copy(self.defaultConfirmSettings);

        /**
         * Pokaz modal potwierdzenia
         * @param  {object} settings [description]
         * @return {ConfirmModalHelper}          [description]
         */
        this.showConfirmModal = function(settings) {
          if(settings.isMd) {
            settings.template = 'ihestia-commondirectives-template-app/directives/confirm/confirmModalContentMd.tpl.html';
          }
          angular.extend(self.confirmSettings, settings);
          if (settings.treatLabelAsI18nCode) {
            self.setLabelForLang(settings);
          }
          iHestiaCommonModalHelper.showModal('ihestiaCommonConfirmModal');
          if (!$rootScope.$$phase) {
            $rootScope.$apply();
          }
          return self;
        };

        this.setLabelForLang = function(settings) {
          var label = settings.text;
          if (angular.isString(label)) {
            self.confirmSettings.text = $filter('translate')(label);
          } else if (angular.isArray(label)) {
            //pierwszy argument to kod labelki, a drugi to zestaw atrybutów, np.: {componentCode: 'Public'}
            if (angular.isUndefined(label[0]) || angular.isUndefined(label[1])) {
              throw new Error('Podano nie poprawne ustawienia dla ');
            } else {
              self.confirmSettings.text = $filter('translate')(label[0], label[1]);
            }
          }

          if(angular.isUndefined(settings.okBtnName)){
            self.confirmSettings.okBtnName = ['yesUcFirst', {componentCode: 'Public'}];
          }

          if(angular.isUndefined(settings.cancelBtnName)){
            self.confirmSettings.cancelBtnName = ['cancelUcFirst', {componentCode: 'Public'}];
          }

          if(angular.isUndefined(settings.title)){
            self.confirmSettings.title = ['confirmationUcFirst', {componentCode: 'Public'}];
          }
        };

        /**
         * Pokazujemy spinner na ok
         * @return {ConfirmModalHelper}          [description]
         */
        this.showOkSpinner = function() {
          iHestiaCommonModalHelper.showOkSpinner('ihestiaCommonConfirmModal');
          return self;
        };

        /**
         * Ukrywamy spinner na ok
         * @return {ConfirmModalHelper}          [description]
         */
        this.hideOkSpinner = function() {
          iHestiaCommonModalHelper.hideOkSpinner('ihestiaCommonConfirmModal');
          return self;
        };

        /**
         * Pokazujemy spinner na anuluj
         * @return {ConfirmModalHelper}          [description]
         */
        this.showCancelSpinner = function() {
          iHestiaCommonModalHelper.showCancelSpinner('ihestiaCommonConfirmModal');
          return self;
        };

        /**
         * Ukrywamy spinner na anuuj
         * @return {ConfirmModalHelper}          [description]
         */
        this.hideCancelSpinner = function() {
          iHestiaCommonModalHelper.hideCancelSpinner('ihestiaCommonConfirmModal');
          return self;
        };

        /**
         * Ukrywa modal potwierdzenia
         * @return {ConfirmModalHelper}          [description]
         */
        this.hideConfirmModal = function() {
          self.clearData();
          iHestiaCommonModalHelper.hideModal('ihestiaCommonConfirmModal');
          return self;
        };

        /**
         * [clearData description]
         * @return {[type]} [description]
         */
        this.clearData = function() {
          self.confirmSettings = angular.copy(self.defaultConfirmSettings);
        };
      };
      return new ConfirmModalHelper();
    }

  ]);