angular.module('salesPath2')
  .service('basketHelper', ['mainDataContainer', 'applicationHelper', 'localizationHelper', '$sce', 'lsnTryEscapeHtml', 'CONSTANTS', 'personHelper', 'CONFIG', 'vehicleHelper', 'personGroupHelper', 'resourceHelper', 'appVariables', 'basketServiceSvc', 'RESOURCES', 'actionHelper', 'triggerWhenInactive', 'basketSecondHelper', '$filter', 'ihestiaLabelHelper', 'dataContainerHelper', 'sp2CommonHelper',
    function(mainDataContainer, applicationHelper, localizationHelper, $sce, lsnTryEscapeHtml, CONSTANTS, personHelper, CONFIG, vehicleHelper, personGroupHelper, resourceHelper, appVariables, basketServiceSvc, RESOURCES, actionHelper, triggerWhenInactive, basketSecondHelper, $filter, ihestiaLabelHelper, dataContainerHelper, sp2CommonHelper) {

      var BasketHelper = function() {
        var self = this;

        /**
         * domyślne ustawienia nazwy lokalizacji dla koszyka
         * @type {Object}
         */
        this.DEFAULT_LOCALIZATION_INFO_BASKET_SETTINGS = {
          showName: true,
          elements: [{
            field: 'city'
          }, {
            field: 'street'
          }, {
            field: 'house',
            fieldSeparator: ''
          }, {
            field: 'apartment',
            prefix: '/',
            lastInLine: true
          }],
          maxLength: 17
        };

        /**
         * domyślne ustawienia nazwy osoby dla koszyka
         * @type {Object}
         */
        this.DEFAULT_PERSON_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'lastName'
          }, {
            field: 'firstName'
          }],
          maxLength: 17
        };

        /**
         * domyślne ustawienia nazwy grupy dla koszyka
         * @type {Object}
         */
        this.DEFAULT_GROUP_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'name'
          }, {
            field: 'personCount'
          }],
          maxLength: 20
        };

        /**
         * domyślne ustawienia nazwy pojazdu dla koszyka
         * @type {Object}
         */
        this.DEFAULT_VEHICLE_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'mark',
            fieldSeparator: ', '
          }, {
            field: 'registration'
          }],
          maxLength: 20
        };

        /**
         * domyslne dane do zawartosci koszyka
         * @type {Object}
         */
        this.DEFAULT_BASKET_CONTENT_DATA = {
          objectsWithSums: null,
          printOfferDesc: {
            __: ['viewCalculation', 'sp2']
          },
          premiumHeader: {
            __: ['sp2.basket.tariffPremium', 'sp2']
          }
        };

        this.DEFAULT_BASKET_SECOND_CONTENT_DATA = {
          objectsWithSums: null,
          secondBasketPremiumVisible: false
        };

        this.variantsToNumeric = {};
        this.variantsToNumeric[CONSTANTS.VARIANT_I] = 1;
        this.variantsToNumeric[CONSTANTS.VARIANT_II] = 2;
        this.variantsToNumeric[CONSTANTS.VARIANT_III] = 3;

        /**
         * dane do szablonu i kontrolera
         * @type {Object}
         */
        this.tplData = {
          menuBar: { //dane koszykowe na pasku menu
            totalSum: 0,
            tarrificationInProgress: false
          },
          basketContent: {},
          basketSecondContent: angular.copy(this.DEFAULT_BASKET_SECOND_CONTENT_DATA),
          basketSecondVisible: {
            com: false,
            nonCom: false
          },
          promiseFiter: {},
          discountValue: null,
          discountType: null,
          discountTypePromo: null,
          diffCalcTypeSign: '%',
          tmpDiscValue: null,
          loadingSpinnerLabel: $filter('translate')('Public.loading', {
            componentCode: 'Public'
          })
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'tarifficationStarted':
              self.refreshProgressData();
              break;
            case 'tarifficationEnded':
              self.refreshProgressData();
              self.refreshMenuBarSum();
              self.refreshBasketContentData();
              break;
            case 'languageChanged.afterStateReload':
              self.translate();
              break;
            default:
              break;
          }
        };

        /**
         * tlumaczenie elementow z tplData po zmianie jezyka
         */
        this.translate = function() {
          self.tplData.loadingSpinnerLabel = $filter('translate')('Public.loading', {
            componentCode: 'Public'
          });
        };

        /**
         * tworzy dane dla koszyka na podstawie DEFAULT_BASKET_CONTENT_DATA
         * @return {Object} dane
         */
        this.createBasketContent = function() {
          var data = angular.copy(this.DEFAULT_BASKET_CONTENT_DATA);
          if (appVariables.isSupplement && appVariables.appMode !== CONSTANTS.APP_MODE_POLICY) {
            data.premiumHeader = {
              __: ['sp2.basket.insuranceExtension', 'sp2']
            };
          }
          return ihestiaLabelHelper.transObject(data);
        };

        this.init = function() {
          self.tplData.basketContent = self.createBasketContent();
          self.isSecondBasketVisible(CONFIG.BEHAVIOR.basketSecondPackages.segment, CONFIG.BEHAVIOR.basketSecondPackages.packages);
        };

        /**
         * KOSZYK2/SUWAK/PZA
         * ustaw zniżkę
         * @param {*} value wartość zniżki
         * @param {String} type typ zniżki
         * @param {Boolean} [tarifficate=true] czy staryfikować i zapisać wniosek
         */
        this.checkPzaDiscount = function(value, type, tarifficate) {
          tarifficate = (tarifficate === false) ? false : true;
          if (type !== null) {
            self.tplData.discountValue = value;
            self.tplData.discountType = type;
            if (tarifficate && sp2CommonHelper.isSalesCharacter()) {
              triggerWhenInactive(1500, self.tplData.promiseFiter, self.setPzaDiscount);
            } else if (!tarifficate) {
              self.setPzaDiscount(false); // initially set discount value/type on basket opening
            }
          }
        };

        /** KOSZYK2/SUWAK/PZA
         * ustaw zniżkę
         */
        this.setPzaDiscount = function(tarificate) {
          if (self.tplData.discountType === 'AMOUNT') {
            basketSecondHelper.tplData.diffCalcType = 'AMOUNT';
            basketSecondHelper.tplData.diffCalcTypeSign = 'zł';
            //ustawiamy kwotę i taryfikujemy
            mainDataContainer.application.individualDiscount = self.tplData.discountValue;
            self.tplData.tmpDiscValue = mainDataContainer.application.individualDiscount;
            if (!angular.isDefined(tarificate) || tarificate) {
              actionHelper.runAction('saveApplication');
            }
          } else if (self.tplData.discountType === 'PERC') {
            basketSecondHelper.tplData.diffCalcType = 'PERC';
            basketSecondHelper.tplData.diffCalcTypeSign = '%';
            mainDataContainer.application.individualDiscount = self.tplData.discountValue;
            self.tplData.tmpDiscValue = mainDataContainer.application.individualDiscount;
            if (!angular.isDefined(tarificate) || tarificate) {
              actionHelper.runAction('saveApplication');
            }
          } else if (self.tplData.discountType === 'PROMOCODE') {
            //kod promocyjny: wywołanie usługi która sprawdzi czy poprawny i zwróci jego wartość
            //uwaga: usługa może zwrócić kwotę lub procent
            basketServiceSvc.checkDiscountCode(CONFIG.BEHAVIOR.basketSecondPackages.segment, self.tplData.discountValue, function(res) {
              //odp OK 
              if (res.data.valueAmount !== null) {
                basketSecondHelper.tplData.diffCalcType = 'AMOUNT';
                basketSecondHelper.tplData.diffCalcTypeSign = 'zł';
                mainDataContainer.application.individualDiscount = res.data.valueAmount;
                self.tplData.tmpDiscValue = mainDataContainer.application.individualDiscount;
              } else if (res.data.valuePercentage !== null) {
                basketSecondHelper.tplData.diffCalcType = 'PERC';
                basketSecondHelper.tplData.diffCalcTypeSign = '%';
                mainDataContainer.application.individualDiscount = res.data.valuePercentage.toString() + '%';
                self.tplData.tmpDiscValue = mainDataContainer.application.individualDiscount;
              }
              if (!angular.isDefined(tarificate) || tarificate) {
                actionHelper.runAction('saveApplication');
              }
            }, function() {
              //nie ma rabatu...zerujemy zniżkę
              if (!angular.isDefined(tarificate) || tarificate) {
                self.tplData.tmpDiscValue = null;
                actionHelper.runAction('saveApplication');
              }
            });
          }
        };

        /*
         * przeliczenie procentu na konkretną kwotę (uwaga procent może być ujemny)
         */
        this.getAmountFromPerc = function(value) {
          var amount = 0; // NOSONAR
          //składka wyjściowa to składka z uwzględnieniem zniżki klienckiej bez uwzględnienia odstępstw
          var premium = self.tplData.basketContent.objectsWithSums.discounts.tariffPremium - self.tplData.basketContent.objectsWithSums.discounts.loyaltyBonusDiscounttAmount;

          var negative = false; // NOSONAR
          var perc = 0; // NOSONAR
          if (value.substring(0, 1) === '-') {
            negative = true;
            perc = value.substring(1, value.length - 1);
          } else {
            negative = false;
            perc = value.substring(0, value.length - 1);
          }

          amount = Math.round((perc / 100) * premium);

          if (negative) {
            amount = -1 * amount;
          }

          return amount;
        };


        /**
         * sprawdza, czy jest dostęp do programu promocji i odstępstw (suwak aka koszyk2)
         */
        this.isSecondBasketVisible = function(segment, packageCodes) {
          if (CONFIG.BEHAVIOR.basketSecondVisable && packageCodes.length > 0) {
            if (!sp2CommonHelper.isSalesCharacter()) {
              appVariables.basketContainerSecondVisibleNonCom = true;
              appVariables.basketContainerSecondVisibleCom = true;
            } else {
              basketServiceSvc.isSecondBasketVisible(segment, packageCodes);
            }
          } else {
            appVariables.basketContainerSecondVisibleNonCom = false;
            appVariables.basketContainerSecondVisibleCom = false;
          }
        };

        //odswieza dane w self.tplData
        this.refreshData = function() {
          self.refreshProgressData();
          self.refreshMenuBarSum();
        };

        //aktualizuje dane dotyczące wskaźnika zajętości
        this.refreshProgressData = function() {
          self.tplData.menuBar.tarrificationInProgress = applicationHelper.callInProgress;
        };

        //aktualizuje sumę ubezpieczenia w sekcji menu
        this.refreshMenuBarSum = function() {
          self.tplData.menuBar.totalSum = dataContainerHelper.getFinalPremiumSum();
        };

        /**
         * Czyścimy dane koszyka
         */
        this.clearBasketContentData = function() {
          angular.forEach(self.createBasketContent(), function(v, k) {
            self.tplData.basketContent[k] = angular.copy(v);
          });
        };

        this.getIndividualDiscount = function() {
          return mainDataContainer.application.individualDiscount;
        };

        /**
         * Przeladowujemy dane koszyka
         */
        this.refreshBasketContentData = function() {
          //jeżeli po taryfikacji nie ma ustawionej zniżki to zerujemy ustawienie koszykaj
          if (mainDataContainer.application.individualDiscount === null && !appVariables.basketContainerVisible) {
            appVariables.contentPulledLeftDoubled = false;
            self.tplData.basketSecondContent.secondBasketPremiumVisible = false;
            self.tplData.discountValue = null;
          }

          //jesli jestesmy w dokupieniach to wybrania koszyka 2 jest nie dostepne
          if (!appVariables.isSupplement) {
            //czy ukryć możliwość wybrania koszyka 2
            if (dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_VEHICLE)) {
              if (appVariables.basketContainerSecondVisibleCom) {
                self.tplData.basketSecondVisible.com = true;
              } else {
                self.tplData.basketSecondVisible.com = false;
              }
            } else {
              self.tplData.basketSecondVisible.com = false;
            }
            if (dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_LOCALIZATION) || dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_PERSON) || dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) || dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_PACKAGE)) {
              if (appVariables.basketContainerSecondVisibleNonCom) {
                self.tplData.basketSecondVisible.nonCom = true;
              } else {
                self.tplData.basketSecondVisible.nonCom = false;
              }

            } else {
              self.tplData.basketSecondVisible.nonCom = false;
            }
          } else {
            self.tplData.basketSecondVisible.com = false;
            self.tplData.basketSecondVisible.nonCom = false;
          }


          if (!appVariables.basketContainerVisible) { //jesli koszyk nie otwarty, to jego danych nie aktualizujemy
            //chyba, że zniżka procentowa, to musimy zaktualizować
            if (self.tplData.discountType === 'PERC') {
              if (mainDataContainer.application.individualDiscount === null || mainDataContainer.application.individualDiscount === 0) {
                return;
              }
            } else {
              return;
            }
          }


          var objects = self.getObjectsWithSums(),
            sumAll = 0,
            lastVariant = null;
          self.clearBasketContentData();
          angular.forEach(objects, function(object) {
            object.productsTable = [];
            angular.forEach(object.products, function(variants, productCode) {
              var prodDef = resourceHelper.getProdDef(productCode);
              var productPremium = 0;
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //każdy wariant osobno
              {
                angular.forEach(variants, function(premium, variant) {
                  productPremium += premium;
                  lastVariant = variant;
                  object.productsTable.push({
                    name: resourceHelper.getProductName(productCode, 'BASKET_NAME', variant),
                    value: premium,
                    code: prodDef.CODE,
                    variantNumeric: self.variantsToNumeric[variant]
                  });
                });
              } else {
                lastVariant = null;
                angular.forEach(variants, function(premium, variant) {
                  productPremium += premium;
                  lastVariant = variant;
                });
                object.productsTable.push({
                  name: resourceHelper.getProductName(productCode, 'BASKET_NAME', lastVariant),
                  value: productPremium,
                  code: prodDef.CODE,
                  variantNumeric: prodDef.VARIANTLIST.length === 1 ? 0 : self.variantsToNumeric[lastVariant]
                });
              }
            });

            object.addsTable = [];
            angular.forEach(object.adds, function(addValue, addCode) {
              var addDef = resourceHelper.getAddDef(addCode);
              if (angular.isDefined(addDef.VARIANTS)) {
                angular.forEach(addValue, function(premium, variant) {
                  object.addsTable.push({
                    name: resourceHelper.getAddName(object, addCode, 'BASKETNAME', variant) + self.getAddNameSuffix(addCode, variant, object.id),
                    value: premium
                  });
                });
              } else {
                object.addsTable.push({
                  name: resourceHelper.getAddName(object, addCode, 'BASKETNAME'),
                  value: addValue
                });
              }
            });
            sumAll += object.sum;
          });
          self.tplData.basketContent.objectsWithSums = {
            objects: objects,
            sum: sumAll,
            discounts: dataContainerHelper.getDiscounts()
          };

          if (appVariables.appMode === CONSTANTS.APP_MODE_OFFER) {
            self.tplData.basketContent.printOfferDesc = $filter('translate')('viewOffer', {
              componentCode: 'sp2'
            });
          }

          if ((mainDataContainer.application.individualDiscount !== null && mainDataContainer.application.individualDiscount !== 0)
            || appVariables.basketContainerSecondVisibleNonCom || appVariables.basketContainerSecondVisibleCom) {
            self.tplData.basketSecondContent.secondBasketPremiumVisible = true;
            basketSecondHelper.refreshBasketContentData();

            if (angular.isObject(basketSecondHelper.tplData.basketContent.objectsWithSums)) {
              self.tplData.basketSecondContent.objectsWithSums = {
                objects: basketSecondHelper.tplData.basketContent.objectsWithSums.objects,
                sumBefore: basketSecondHelper.tplData.basketContent.objectsWithSums.sumBefore,
                sumAfter: basketSecondHelper.tplData.basketContent.objectsWithSums.sumAfter,
                diff: basketSecondHelper.tplData.basketContent.objectsWithSums.diff
              };
            }
          } else {
            self.tplData.basketSecondContent.objectsWithSums = {
              sumBefore: self.tplData.basketContent.objectsWithSums.discounts.tariffPremium - self.tplData.basketContent.objectsWithSums.discounts.loyaltyBonusDiscounttAmount,
              sumAfter: self.tplData.basketContent.objectsWithSums.discounts.tariffPremium - self.tplData.basketContent.objectsWithSums.discounts.loyaltyBonusDiscounttAmount,
              diff: 0
            };
            self.tplData.basketSecondContent.secondBasketPremiumVisible = false;
          }
        };

        /**
         * zwraca opcjonalny suffix dla nazwy dodatku
         * @param  {String} addCode kod dodatku
         * @param  {String} variant wariant dodatku
         * @param  {String} objId   id obiektu ubezpieczonego
         * @return {String}
         */
        this.getAddNameSuffix = function(addCode, variant, objId) {
          if (angular.isUndefined(RESOURCES.ADDITIONS_ATTRIBUTES[addCode]) || angular.isUndefined(RESOURCES.ADDITIONS_ATTRIBUTES[addCode][variant])) {
            return '';
          }
          var suffix = '',
            attrData = RESOURCES.ADDITIONS_ATTRIBUTES[addCode][variant];
          //aktualnie obslugujemy tylko atrybut expandTurbo
          if (attrData.attrName === 'expandTurbo' && dataContainerHelper.getSelectedAddVariants(attrData.addCode, objId)[attrData.addVariant] === true) {
            suffix = ' - Turbo';
          }
          return suffix;
        };

        /**
         * zwraca ubezpieczone obiekty (osoby, pojazdy, lokalizacje) wraz ze składkami i sumami łącznymi na potrzeby koszyka
         * @return {Object}
         */
        this.getObjectsWithSums = function() {
          var objects = [];
          var object = null;
          // lokalizacje
          if (!angular.equals(mainDataContainer.localizations, {})) {
            lsnNg.forEach(mainDataContainer.localizations, function(localization, id) {
              object = {};
              object.name = self.getLocalizationNameForObjectWithSum(id);
              var showObject = false,
                anyAddition = false;
              object.products = {};
              object.adds = {};
              object.sum = 0;
              object.id = id;
              object.type = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;

              //produkty
              if (angular.isUndefined(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][id])) {
                return true;
              }
              //ryzyka OGI, KRA 
              lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][id], function(variant, productCode) {
                if (variant === null) //brak zaznaczonego wariantu
                {
                  return true;
                }

                var premiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, id, productCode, CONSTANTS.SUM_NAME);
                if (angular.isDefined(premiums[variant]) && premiums[variant] !== 0) {
                  showObject = true;
                  object.sum += premiums[variant];
                  object.products[productCode] = {};
                  object.products[productCode][variant] = premiums[variant];
                }

                return true; //continue
              });


              //dodatki
              anyAddition = self._addAdditionsToObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, object, id);

              if (showObject || anyAddition) {
                objects.push(object);
              }

              return true; //continue
            });
          }

          //osoby
          lsnNg.forEach(mainDataContainer.persons, function(person, id) {
            var showObject = false,
              anyAddition = false;
            object = {
              name: personHelper.getPersonInfo(person, self.DEFAULT_PERSON_INFO_BASKET_SETTINGS),
              products: {},
              adds: {},
              sum: 0,
              id: id,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };

            //produkty
            lsnNg.forEach(resourceHelper.productType, function(productType, productCode) {
              //osoby (poniżej są jeszcze grupy dla tematycznych)
              if (productType === CONSTANTS.PRODUCT_TYPE_PERSON) {
                var selectedVariants = {};
                var productPersons = dataContainerHelper.getPersonsForPersonalProduct(productCode);

                if (angular.isDefined(productPersons[id])) {
                  if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //możliwe wiele wariantów
                  {
                    lsnNg.forEach(productPersons[id].variants, function(selected, variant) {
                      if (selected) {
                        selectedVariants[variant] = true;
                      }
                    });
                  } else {
                    if (productPersons[id].variants === null) {
                      return true;
                    } else {
                      selectedVariants[productPersons[id].variants] = true;
                    }
                  }
                }

                var variantsCount = 0;
                var riskPremiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_PERSON, id, productCode);

                lsnNg.forEach(selectedVariants, function(isSelected, variant) {
                  if (angular.isUndefined(riskPremiums[variant]) || riskPremiums[variant] === 0) {
                    delete selectedVariants[variant];
                    return true;
                  }
                  object.sum += riskPremiums[variant];
                  selectedVariants[variant] = riskPremiums[variant];
                  variantsCount += 1;

                  return true; //continue
                });

                if (variantsCount) {
                  showObject = true;
                  object.products[productCode] = selectedVariants;
                }
              }

              return true; //continue
            });

            //dodatki
            anyAddition = self._addAdditionsToObject(CONSTANTS.PRODUCT_TYPE_PERSON, object, id);

            if (showObject || anyAddition) {
              objects.push(object);
            }
          });

          // NNW pracownicy bezimiennie
          // może być tylko jeden taki obiekt, tylko w I wariancie, więc sprawdzamy tylko ten
          if (mainDataContainer.nnwIncognito !== null && angular.isDefined(mainDataContainer.nnwIncognito.variants) && mainDataContainer.nnwIncognito.variants[CONSTANTS.VARIANT_I] && angular.isDefined(mainDataContainer.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID]) && mainDataContainer.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID][CONSTANTS.PRODUCT_NNW][CONSTANTS.VARIANT_I]) {
            var premium = {};
            premium[CONSTANTS.VARIANT_I] = mainDataContainer.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID][CONSTANTS.PRODUCT_NNW][CONSTANTS.VARIANT_I];
            var incognito = {
              name: $sce.trustAsHtml('Pracownicy bezimiennie'),
              products: {
                NNW: premium
              },
              adds: {},
              sum: premium[CONSTANTS.VARIANT_I],
              id: CONSTANTS.NNW_INCOGNITO_PERSON_ID,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };
            objects.push(incognito);
          }

          //pojazdy
          if (!angular.equals(mainDataContainer.vehicles, {})) {
            lsnNg.forEach(mainDataContainer.vehicles, function(vehicle, id) {
              object = {};
              object.name = vehicleHelper.getVehicleInfo(vehicle, self.DEFAULT_VEHICLE_INFO_BASKET_SETTINGS);

              var showObject = false,
                anyAddition = false;
              object.products = {};
              object.adds = {};
              object.sum = 0;
              object.id = id;
              object.type = CONSTANTS.PRODUCT_TYPE_VEHICLE;

              //produkty
              lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE][id], function(variant, productCode) {
                if (variant === null) //brak zaznaczonego wariantu
                {
                  return true;
                }

                var premiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_VEHICLE, id, productCode);
                if (angular.isDefined(premiums[variant]) && premiums[variant] !== 0) {
                  showObject = true;
                  object.sum += premiums[variant];
                  object.products[productCode] = {};
                  object.products[productCode][variant] = premiums[variant];
                }

                return true; //continue
              });

              //dodatki
              anyAddition = self._addAdditionsToObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, object, id);

              if (showObject || anyAddition) {
                objects.push(object);
              }
            });
          }

          //grupy-osoby
          lsnNg.forEach(dataContainerHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(groupPerson, id) {
            object = {};
            var showObject = false,
              anyAddition = false;
            var isPerson = dataContainerHelper.isPerson(groupPerson);
            object = {
              name: isPerson ? personHelper.getPersonInfo(groupPerson, self.DEFAULT_PERSON_INFO_BASKET_SETTINGS) : personGroupHelper.getGroupInfo(groupPerson, self.DEFAULT_GROUP_INFO_BASKET_SETTINGS),
              products: {},
              adds: {},
              sum: 0,
              id: id,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };

            //produkty
            lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][id], function(variant, productCode) {
              if (variant === null) //brak zaznaczonego wariantu
              {
                return true;
              }

              var premiums = dataContainerHelper.getPremium(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, id, productCode);
              if (angular.isDefined(premiums[variant]) && premiums[variant] !== 0) {
                showObject = true;
                object.sum += premiums[variant];
                object.products[productCode] = {};
                object.products[productCode][variant] = premiums[variant];
              }

              return true; //continue
            });

            //dodatki
            anyAddition = self._addAdditionsToObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, object, id);

            if (showObject || anyAddition) {
              objects.push(object);
            }
          });

          if (objects.length !== 0 || appVariables.isSupplement) {
            if (objects.length === 0) {
              objects.push(self.createDefaultObjectWithSum());
            }

            lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
              if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
                var packageAddPremium = null;
                if (angular.isDefined(addData.VARIANTS)) {
                  lsnNg.forEach(addData.VARIANTS, function(variantData) {
                    if (mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE]) {
                      if (angular.isUndefined(objects[0].adds[addData.CODE])) {
                        objects[0].adds[addData.CODE] = {};
                      }
                      packageAddPremium = dataContainerHelper.getAddPremium(addData.CODE, variantData.CODE);
                      if (packageAddPremium !== 0) {
                        objects[0].adds[addData.CODE][variantData.CODE] = packageAddPremium;
                        objects[0].sum += packageAddPremium;
                      }
                    }
                  });
                } else {
                  if (mainDataContainer.selectedAdditions[addData.CODE]) {
                    packageAddPremium = dataContainerHelper.getAddPremium(addData.CODE);
                    if (packageAddPremium !== 0) {
                      objects[0].adds[addData.CODE] = packageAddPremium;
                      objects[0].sum += packageAddPremium;
                    }
                  }
                }
              }
            });
          }

          return objects;
        };


        /**
         * dodaje węzeł z dodatkami w objectsWithSums z metody self.getObjectsWithSums()
         * @param {String} addObjType typ obiektu do ktorego podpinamy dodatki
         * @param {Object} object obiekt koszyka, do ktorego dopisujemy dodatki
         * @param {[type]} id clientId obiektu ubezpieczenia (pojazd, lokalizacja itp) do ktorego podpięte są dodatki
         * @return {Boolean} true gdy co dodano co najmniej jeden dodatek
         */
        this._addAdditionsToObject = function(addObjType, object, id) {
          var anyAdded = false;
          lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
            if (addData.TYPE === addObjType) {
              if (angular.isDefined(addData.VARIANTS)) {
                lsnNg.forEach(addData.VARIANTS, function(variantData) {
                  lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE], function(objectId) {
                    if (objectId === id) {
                      anyAdded = true;
                      if (angular.isUndefined(object.adds[addData.CODE])) {
                        object.adds[addData.CODE] = {};
                      }
                      var addPremium = dataContainerHelper.getAddPremium(addData.CODE, variantData.CODE, id);
                      if (addPremium !== 0) {
                        object.adds[addData.CODE][variantData.CODE] = addPremium;
                        object.sum += addPremium;
                      }
                    }
                  });
                });
              } else {
                lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
                  if (objectId === id) {
                    anyAdded = true;
                    var addPremium = dataContainerHelper.getAddPremium(addData.CODE, null, id);
                    if (addPremium !== 0) {
                      object.adds[addData.CODE] = addPremium;
                      object.sum += addPremium;
                    }
                  }
                });
              }
            }
          });

          return anyAdded;
        };


        /**
         * [createDefaultObjectWithSum funkcja wywolana na dokupieniu, gdy dokupino tylko dodatek]
         * @return {[type]} [description]
         */
        this.createDefaultObjectWithSum = function() {
          var selectedObject = {
            products: {},
            adds: {},
            sum: 0
          };

          //jesli wybrane lokalizacja
          if (mainDataContainer.selectedLocalization !== '0') {
            selectedObject.id = mainDataContainer.selectedLocalization;
            selectedObject.type = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
            selectedObject.name = self.getLocalizationNameForObjectWithSum(selectedObject.id);
            //jesli wybrany pojazd
          } else if (mainDataContainer.selectedVehicle !== '0') {
            selectedObject.id = mainDataContainer.selectedVehicle;
            selectedObject.type = CONSTANTS.PRODUCT_TYPE_VEHICLE;
            selectedObject.name = vehicleHelper.getVehicleInfo(mainDataContainer.vehicles[mainDataContainer.selectedVehicle], self.DEFAULT_VEHICLE_INFO_BASKET_SETTINGS);
            //w przeciwnym przypadku osoba
          } else {
            selectedObject.id = 1;
            selectedObject.type = CONSTANTS.PRODUCT_TYPE_PERSON;
            selectedObject.name = personHelper.getPersonInfo(mainDataContainer.persons[1], self.DEFAULT_PERSON_INFO_BASKET_SETTINGS);
          }

          return selectedObject;
        };

        this.getLocalizationNameForObjectWithSum = function(locId) {
          var localization = mainDataContainer.localizations[locId],
            name;

          if (localization.get('name') === null || localization.get('name') === '') {
            name = localizationHelper.getLocalizationInfo(localization, self.DEFAULT_LOCALIZATION_INFO_BASKET_SETTINGS);
          } else {
            var shortName = localization.get('name');
            if (shortName.length > 17) {
              shortName = shortName.substring(0, 17) + '...';
            }
            name = $sce.trustAsHtml(lsnTryEscapeHtml(shortName));
          }

          return name;
        };

        self.init();

      };

      return new BasketHelper();
    }
  ])
  .run(['basketHelper', 'actionHelper',
    function(basketHelper, actionHelper) {
      actionHelper.registerHelper('basketHelper', basketHelper);
    }
  ]);