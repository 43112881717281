angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('IHestiaRestServiceProviderMockDef',[ 'IHestiaRestServiceProviderAbstractDef', '$http', 'ihestiaConfigHelper', function(IHestiaRestServiceProviderAbstractDef, $http, ihestiaConfigHelper) {

    var RestServiceProviderMockDef = function() {

      IHestiaRestServiceProviderAbstractDef.apply(this, arguments);

      var self = this,
          additionalHeaders = {};

      this.conflictedRequests = [];

      /**
       * @param {Object} headers
       */
      this.setAdditionalHeaders = function(headers)
      {
        additionalHeaders = headers;
      };

      /**
       * @returns {string}
       */
      this.getName = function()
      {
        return 'Mock';
      };

      /**
       * wykonuje call do webservice'u Ateny
       *
       * @param parameters
       * @returns {*}
       */
      this.call = function(parameters, callback) {
        var settings = angular.extend({
          url: '',
          data: '',
          method: 'GET',
          async: true
        }, parameters);
        settings.data = self._parseData(settings.data, settings.dataType);

        var uniqueRequestId = self.getUniqueRequestId();

        var ajaxCallParams = {
          url: ihestiaConfigHelper.get('rest', 'HOST'),
          method: settings.method,
          businessArea: settings.businessArea,
          resource: settings.resource,
          async: settings.async,
          dataType: settings.dataType,
          processData: false,
          withCredentials: false
        };

        if(settings.method === 'GET' || settings.method === 'DELETE') {
          ajaxCallParams.params = {
            svcData: settings.data,
            svcUrl: settings.url
          };
        } else {
          ajaxCallParams.data = {
            svcData: settings.data,
            svcUrl: settings.url
          };
        }

        if(settings.dataType === 'json')
        {
          ajaxCallParams.contentType = 'application/json;charset=UTF-8';
        }
        else if(settings.dataType === 'form')
        {
          ajaxCallParams.contentType = 'application/x-www-form-urlencoded;charset=UTF-8';
        }

        var additionalParam = {
          processType: parameters.processType,
          requestType: 'ACTION',
          uniqueRequestId: uniqueRequestId
        };
        return self.callAjaxWithParams(ajaxCallParams, callback, additionalParam);
      };

      /**
       * Wola ajaxa z odpowiednimi parametrami
       * @param  {Object} params [description]
       * @param  {Object} callback [description]
       * @param  {Object} additionalParam [description]
       * @return
       */
      this.callAjaxWithParams = function(params, callback, additionalParam) {

        params.headers = {
          'AP-Caller': 'OA',
          'AP-Unique-Request-Id': additionalParam.uniqueRequestId,
          'Accept': 'application/json',
          'Content-Type': params.contentType
        };

        angular.extend(params.headers, additionalHeaders);

        // wolanie ajaxa
        $http(params).then(function(result){
          callback(result);
        }, angular.noop);

        return {
          uniqueRequestId: additionalParam.uniqueRequestId
        };
      };

      this._parseData = function(data, dataType) {
        if (data === null) {
          return null;
        }
        if (typeof data === 'object') {
          if(dataType === 'json')
          {
            return JSON.stringify(data);
          }
          else if(dataType === 'form')
          {
            var parsedData = [];
            angular.forEach(data, function(value, key){
              if (typeof value === 'object')
              {
                value = JSON.stringify(value);
              }
              parsedData.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            });
            return parsedData.join('&');
          }
        }
        return data;
      };
    };

    return RestServiceProviderMockDef;
  }]);