/**
 * Pokazuje modal wyszukiwania klienta
 * m.in. https://ebok.atena.pl/browse/EHNLJUPIT-2048
 *
 * Algorytm działania:
 * - wyszkujemy po wpisanych danych
 *   - jeden rekord
 *     - tworzymy token bezpieczeństwa dla danego klienta
 *     - jeśli jest numer polisy, to tworzymy dla niej token bezpieczeństwa
 *       - jeśli token ok, to przechodzimy do klienta do konkretnej polisy
 *       - jeśli token nie ok, to wyświetlamy info, że numer polisy jest nie ok i można przejść do jego ikonta
 *     - jeśli nie ma numeru polisy, to przechodzimy do ikonta/polisaonline (albo na sztywno, albo info z usługi)
 *   - wiele rekordów
 *     - wyświetlamy listę klientów
 *     - klikamy w klienta
 *       - tworzmy token bezpieczeństwa dla tego klienta
 *       - przechodzimy do ikonta/polisaonline (albo na sztywno, albo info z usługi)
 *
 * parametry w modalData
 * openClientPage (bool)     - czy przejść na stronę ikonta/polisaonline
 * clientPageTarget (string) - jeśli openClientPage: true, to wskazuje target dla $window.open
 * clientPageType (string)   - ihestiaSaleNavigationConstants.CLIENT_PAGE_* - bezpośrednie wskazanie na
 *                             aplikację do której przejdziemy (klient/polisaonline), domyślnie odpytujemy
 *                             o to usługę zgodnie (jeśli klient ma cokolwiek w IH, to klient, jeśli nie
 *                             to polisaonline)
 * requiredFields (Object)   - pola, które są wymagane ({policyNumber: true})
 *                             (na razie obsługujemy tylko policyNumber)
 * onlyExactHit              - jeśli true, to interesują nas tylko jednoznaczne wyniki (nie dostaniemy listy klientów)
 */
angular.module('ihestiaWidgets.commonOperations')
  .factory('findClientModalHelper', ['LsnModalHelper', '$q', 'ihestiaCustomerSearchSvc', 'customerSvc', 'ihestiaSaleNavigationHelper', 'ihestiaSaleNavigationConstants', 'customerPoliciesSvc', 'ihestiaPlatformHelper', 'policiesSvc',
    function(LsnModalHelper, $q, ihestiaCustomerSearchSvc, customerSvc, ihestiaSaleNavigationHelper, ihestiaSaleNavigationConstants, customerPoliciesSvc, ihestiaPlatformHelper, policiesSvc) {

      var FindClientModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;
        this.SUBJECT_TYPE_PERSON = 'person';
        this.SUBJECT_TYPE_ORGANIZATION = 'organization';

        /**
         * Rozszerzamy opcje modala
         * @type {String}
         */
        this.setOptions({
          templateUrl: 'ihestia-widgets-templates-app/operations/findClientModal/findClientModal.tpl.html',
          size: 'md'
        });

        /**
         * SearchParams
         * @type {Object}
         */
        this.searchParams = {
          count: 10,
          skip: 0,
          top: 10
        };

        /**
         * Ustawienia dla common-numeric
         * @type {Object}
         */
        this.numericSettings = {
          pesel: {
            allowLeadingZeros: true,
            decimals: 0,
            formatting: false,
            groupSeparator: '',
            min: 0,
            parseToString: true
          },
          policyNumber: {
            decimals: 0,
            min: 0,
            allowLeadingZeros: true
          }
        };

        /**
         * Wyszukiwanie klienta
         * @param  {Object} dynamicFilters parametry wyszukiwania
         * @param {'person' | 'organization'} [subjectType]
         * @return {Promise}
         */
        this.search = function(dynamicFilters, subjectType) {
          var searchParams = angular.copy(self.searchParams);
          searchParams.dynamicFilters = angular.copy(dynamicFilters);
          if (subjectType === self.SUBJECT_TYPE_ORGANIZATION) {
            // remove country code for polish searches - service requirement
            if (searchParams.dynamicFilters.registrationCountry === 'PL') {
              searchParams.dynamicFilters.registrationCountry = null;
            }
          }
          // IHESTIABAS-6426 client search supports identityNumber for foreigners and pesel for polish
          // wyszukiwarka obsługuje tylko pesel, a tworzenie tokenu tylko identityNumber

          return $q(function(resolve, reject) {
            ihestiaCustomerSearchSvc.findByParams(searchParams).then(function(res) {
              if (res.data.items.length === 0) {
                reject({
                  status: 'noResults',
                  errors: [{
                    label: ['operation.clientNotFoundTryAgain', {
                      componentCode: 'PolicyOperations'
                    }]
                  }]
                });
              } else if (res.data.items.length === 1) {
                resolve({
                  status: 'exactResult',
                  client: res.data.items[0]
                });
              } else {
                resolve({
                  status: 'manyResults',
                  customersListResponse: res.data
                });
              }
            }, function() {
              reject({
                status: 'error',
                errors: [{
                  label: ['operation.clientNotFoundTryAgain', {
                    componentCode: 'PolicyOperations'
                  }]
                }]
              });
            });
          });

        };

        /**
         * Przejście do klienta
         * @param  {Object} client klient
         * @param  {string} policyNumber numer polisy, jeśli chcemy przejść do konkretnej polisy
         *
         * @return {Promise}
         */
        this.goToClient = function(client, policyNumber) {
          if (self.modalData.clientPageType) {
            // podano gdzie mamy pokazać klienta (klient/polisaonline)
            // zamockowanie platformy w zależności od tego, gdzie chcemy trafić
            return self._gotoClientPage(client, self.modalData.clientPageType, policyNumber);
          } else {
            // nie podano gdzie mamy pokazać klienta (klient/polisaonline)
            // wiec sprawdzamy jakiego typu/brandu obiekty posiada klient
            return customerSvc.get(client.customerAccountId, null, 'account')
              .then(function(response) {
                if (angular.isObject(response) && response.data) {
                  var accountBrands = response.data.brands;
                  // jeżeli wśród brandów pojawi się wartość IHESTIA przekierowujemy do iKonta iHestia
                  if (!_.isEmpty(accountBrands)) {
                    if (accountBrands.indexOf('IHESTIA') !== -1) {
                      return self._gotoClientPage(client, ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO, policyNumber);
                    } else if (accountBrands.indexOf('DIRECT') !== -1) {
                      // jeśli jest tylko DIRECT to idziemy do iKonta Direct
                      return self._gotoClientPage(client, ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO_DIRECT, policyNumber);
                    } else {
                      return undefined;
                    }
                  } else if (response.data.type === 'Company') {
                    return self._gotoClientPage(client, ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO, policyNumber, self.SUBJECT_TYPE_ORGANIZATION);
                  } else {
                    return undefined;
                  }
                } else {
                  return undefined;
                }
              });
          }
        };

        /**
         * Rejestrowanie tokenu bezpieczeństwa dla klienta
         * @param  {Object} dynamicFilters filtry (dane klienta)
         * @param {'person' | 'organization'} [subjectType]
         * @return {Promise}
         */
        this.registerClientToken = function(dynamicFilters, subjectType) {
          // guard for incomplete request data
          if (self.insufficientRequestData(dynamicFilters, subjectType)) {
            return $q.reject({
              status: 'noRequest',
              errors: [{
                label: ['operation.clientNotFoundTryAgain', {
                  componentCode: 'PolicyOperations'
                }]
              }]
            });
          }

          return customerSvc.registerAccessToken(dynamicFilters)
            .then(function(res) {
              if (res && angular.isDefined(res.data) && angular.isDefined(res.status)) {
                switch (res.status) {
                  case 201:
                    var client = angular.copy(dynamicFilters);
                    client.customerAccountId = res.data.customerId;
                    return {
                      status: 'ok',
                      client: client
                    };
                  case 204:
                    return {
                      status: 'invalidCustomer'
                    };
                  default:
                    return self.stdTokenErrorFunc(res);
                }
              } else {
                return {
                  status: 'invalidCustomer'
                };
              }
            }, self.stdTokenErrorFunc);
        };

        /**
         * Rejestrowanie tokenu dla polisy
         * @param  {Object} data customerAccountId, policyNumber
         * @return {Promise}
         */
        this.registerPolicyToken = function(data) {
          return $q(function(resolve, reject) {
            customerPoliciesSvc.registerAccessToken(data.customerAccountId, data.policyNumber).then(function(policyRes) {
              if (policyRes && (policyRes.status === 200 || policyRes.status === 201)) {
                resolve({
                  status: 'ok',
                  policyData: policyRes.data
                });
              } else {
                reject({
                  status: 'invalidPolicyNumber',
                  customerAccountId: data.customerAccountId
                });
              }
            }, function() {
              reject({
                status: 'invalidPolicyNumber',
                customerAccountId: data.customerAccountId
              });
            });
          });
        };

        /**
         * Register token for centrally renewed policies
         * @param  {Object} data
         * @param {string} targetApp
         * @param {'person' | 'organization'} [subjectType]
         * @return {Promise}
         */
        this.registerCentrallyRenewedPoliciesToken = function(data, targetApp, subjectType) {
          if (self.insufficientRequestData(data, subjectType)) {
            // nie mamy czym wysłać requesta
            return $q.reject({
              status: 'noRequest'
            });
          }

          /** Wołanie usługi */
          return policiesSvc.registerCentrallyRenewedPoliciesToken(data, subjectType === self.SUBJECT_TYPE_ORGANIZATION)
            .then(function(res) {
              if (res && res.data && (res.status === 200 || res.status === 201)) {
                if (res.data.length === 0) {
                  return $q.reject({
                    status: 'noResults',
                    errors: [{
                      label: ['Nie znaleziono polis spełniających kryteriów wyszukiwania'] // bez labelki ze względu na problemy z etykietami @TODO
                    }],
                    targetApp: targetApp
                  });
                } else if (res.data.length === 1) {
                  return {
                    status: 'exactResult',
                    policy: res.data[0],
                    targetApp: targetApp
                  };
                } else {
                  return {
                    status: 'manyResults',
                    policiesListResponse: res.data,
                    targetApp: targetApp
                  };
                }
              } else {
                return $q.reject({
                  status: 'noResults',
                  targetApp: targetApp
                });
              }
            }, function(res) {
              var errorList = [];
              if (angular.isDefined(res.data.messages) && res.data.messages.length > 0) {
                angular.forEach(res.data.messages, function(msg) {
                  errorList.push({
                    text: msg
                  });
                });
              }
              return $q.reject({
                status: 'error',
                errors: errorList
              });
            });
        };

        /**
         * Otwiera odpowiednią stronę klienta
         * metoda wewnętrzna, nie używa poza tym helperem
         *
         * @param  {[type]} data      [description]
         * @param  {[type]} targetApp [description]
         * @param  {String} policyNumber
         * @param {'person' | 'organization'} [subjectType]
         * @return {Promise}           [description]
         */
        this._gotoClientPage = function(data, targetApp, policyNumber, subjectType) {
          return $q(function(resolve, reject) {
            if (policyNumber) {
              self.registerPolicyToken({
                customerAccountId: data.customerAccountId,
                policyNumber: policyNumber
              }).then(function(policyRes) {
                var simplePolicy = {
                  customerAccountId: data.customerAccountId,
                  platform: ihestiaPlatformHelper.isAnyJupiterPlatform(policyRes.policyData.platform) ? 'jupiter' : 'ihestia',
                  policyNumber: policyNumber
                };
                // w iHestii nawet kierując na podgląd konkretnej polisy nadajemy token do polis centralnie wznowionych bez żadnej obsługi tego co zwróci bądź nie usługa
                if (!ihestiaPlatformHelper.isJupiter()) {
                  policiesSvc.registerCentrallyRenewedPoliciesToken(data, targetApp, subjectType);
                }
                ihestiaSaleNavigationHelper.openPolicy(simplePolicy, self.modalData.clientPageTarget);
                resolve();
              }, reject);
            } else {
              self.registerCentrallyRenewedPoliciesToken(data, targetApp, subjectType).then(resolve, reject);
            }
          });
        };
        /** Błędy z usługi lub nie wiadomo co */
        this.stdTokenErrorFunc = function(res) {
          var errorList = [];
          if (angular.isDefined(res.data.messages) && res.data.messages.length > 0) {
            angular.forEach(res.data.messages, function(msg) {
              errorList.push({
                text: msg
              });
            });
          } else if (res.status === 404) {
            errorList.push({
              label: ['operation.clientNotFoundTryAgain', {
                componentCode: 'PolicyOperations'
              }]
            });
          } else {
            errorList.push({
              label: ['operation.errorOccurredDuringSearchClient', {
                componentCode: 'PolicyOperations'
              }]
            });
          }
          return $q.reject({
            status: 'error',
            errors: errorList
          });
        };

        this.insufficientRequestData = function(data, subjectType) {
          if (subjectType !== self.SUBJECT_TYPE_ORGANIZATION) {
            return !data.firstName || !data.lastName || (!data.pesel && !data.identityNumber);
          } else {
            // todo IHESTIABAS-6426 verify condition
            return _.chain(data)
              .pick([
                'taxId',
                'statisticalId',
                'organizationName',
                'companyId'
              ])
              .values()
              .filter(_.identity)
              .isEmpty()
              .value();
          }
        };
      };

      return new FindClientModalHelper();
    }
  ]);
