angular.module('salesPath2')
  .controller('insurerContactDataModalCtrl', ['$scope', 'sp2CommonHelper', 'InsurerContactDataModalHelperClass', 'summaryPageHelper',
    function($scope, sp2CommonHelper, InsurerContactDataModalHelperClass, summaryPageHelper) {
      $scope.modalSettings = summaryPageHelper.tplData.modalSettings.insurerContactDataModal;
      $scope.helper = new InsurerContactDataModalHelperClass();

      $scope.tplData = $scope.helper.tplData;

      $scope.spinnerOptions = {
        size: 'sm',
        label: ''
      };

      $scope.init = function() {
        $scope.modalSettings.okBtnDisabled = true;
        $scope.tplData.loadingData = false;
        $scope.helper.initData();
        $scope.fireListeners();
        $scope.refreshOkBtnState();
      };

      $scope.fireListeners = function() {
        // 'apply' has been clicked
        $scope.$on('iHestiaCommonModalOkBtnClicked.insurerContactDataModal', function($event) {
          $event.preventDefault();
          $scope.tplData.loadingData = true;
          $scope.modalSettings.okBtnDisabled = true;
          $scope.modalSettings.cancelBtnDisabled = true;
          $scope.helper.patchInsurerData()
            .then(function() {
              $scope.modalSettings.okBtnDisabled = false;
              $scope.modalSettings.cancelBtnDisabled = false;
              $scope.tplData.loadingData = false;
              $scope.helper.updateDCInsurerData();
              if (angular.isFunction($scope.modalSettings.applyCallback)) {
                $scope.modalSettings.applyCallback();
              }
              sp2CommonHelper.hideModal('insurerContactDataModal');
            }, function(rej) {
              $scope.modalSettings.okBtnDisabled = false;
              $scope.modalSettings.cancelBtnDisabled = false;
              $scope.tplData.loadingData = false;
              $scope.helper.parseErrorMessages(rej.data.messages || []);
            });
        });
      };


      /**
       * refresh "ok/apply" button state by data validity
       */
      $scope.refreshOkBtnState = function() {
        if ($scope.validateEmail() && $scope.tplData.clause) {
          $scope.modalSettings.okBtnDisabled = false;
        } else {
          $scope.modalSettings.okBtnDisabled = true;
        }
      };

      $scope.validateEmail = function() {
        var emailNotEmpty = !!$scope.tplData.mail.trim();
        return $scope.modalSettings.emailRequired ?
          emailNotEmpty : ($scope.helper.hasInsurerPhoneNumber() || emailNotEmpty);
      };

      $scope.init();
    }
  ]);