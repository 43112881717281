angular.module('lsnBase.modal')
  .factory('LsnModalHelper', ['$q', 'lsnModalBaseConfig', 'bodyInjectorHelper', 'lsnModalGlobalHelper',
    function($q, lsnModalBaseConfig, bodyInjectorHelper, lsnModalGlobalHelper) {
      var LsnModalHelper = function() {

        /**
         * Opcje modala
         * @type {Object}
         */
        this.options = angular.copy(lsnModalBaseConfig);

        /**
         * id dyrektywy z bodyInjector
         * @type {Strign}
         */
        this.modalDirectiveId = null;

        /**
         * Promise
         * @type {Promise}
         */
        this.promise = null;

        /**
         * Sposób zakończenia promise'a po zamknięciu modala
         * @type {String} reject|resolve
         */
        this.endPromiseAs = 'reject';

        /**
         * Dane zwracane przez zakończony promise
         * @type {mixed}
         */
        this.promiseData = null;

        /**
         * Dane dostarczone do modala
         * @type {mixed}
         */
        this.modalData = null;

        /**
         * Callback do resolve'a
         * @type {Function}
         */
        this.resolvePromise = null;

        /**
         * Callback do reject'a
         * @type {Function}
         */
        this.rejectPromise = null;

        /**
         * Rozszerzamy opcje modala
         * @param {Object} options opcje
         *
         * @return {LsnModalHelper} self
         */
        this.setOptions = function(options) {
          angular.extend(this.options, options);

          return this;
        };

        /**
         * Przywrócenie domyślnych ustawień
         *
         * @return {LsnModalHelper} self
         */
        this.restoreDefaultOptions = function() {
          this.options = angular.copy(lsnModalBaseConfig);

          return this;
        };

        /**
         * Czyszczenie danych po ukryciu modala
         * @return {Object} this;
         */
        this.clearData = function() {
          this.modalDirectiveId = null;
          this.promise = null;
          this.endPromiseAs = 'reject';
          this.promiseData = null;
          this.modalData = null;
          this.rejectPromise = null;
          this.resolvePromise = null;

          return this;
        };

        /**
         * Pokazujemy modal
         * @param  {mixed} modalData dane przekazane do modala
         * @return {Object}           Promise
         */
        this.showModal = function(modalData) {
          var vm = this;
          if(this.modalDirectiveId !== null && this.promise !== null) {
            return this.promise;
          }

          this.modalData = modalData;

          // dodajemy referencję do naszego helpera do dyrektywy
          this.modalDirectiveId = bodyInjectorHelper.getUniqueKey();
          lsnModalGlobalHelper.addHelper(this.modalDirectiveId, this);

          // wrzucamy cały html itd. do modala
          bodyInjectorHelper.injectDirective(this.modalDirectiveId, 'lsn-modal');


          // zwracamy promise
          this.promise = $q(function(resolve, reject) {
            vm.resolvePromise = resolve;
            vm.rejectPromise = reject;
          });

          return this.promise;
        };

        /**
         * Ukrywamy modal
         * @return {Object} this;
         */
        this.hideModal = function() {
          if(this.modalDirectiveId) {
            lsnModalGlobalHelper.removeHelper(this.modalDirectiveId);
            bodyInjectorHelper.removeDirective(this.modalDirectiveId);
            return this.modalDestroyed();
          }

          return this;
        };

        /**
         * Domyślny cancel
         * @return {Object} this;
         */
        this.cancel = function() {
          if(!this.options.cancelBtnSpinner) {
            this.setPromiseAsRejected();
            return this.hideModal();
          }
        };

        /**
         * Domyślne ok
         * @return {Object} this;
         */
        this.confirm = function() {
          if(!this.options.okBtnSpinner) {
            this.setPromiseAsResolved();
            return this.hideModal();
          }
        };

        /**
         * Kliknięcie w trzeci dodatkowy przycisk
         * @return {[type]} [description]
         */
        this.thirdBtnClicked = function(){
          if(!this.options.thirdBtnSpinner) {
            this.setPromiseAsResolved({thirdBtnClicked: true});
            return this.hideModal();
          }
        };

        /**
         * Ustawiamy promise modala na rejected
         * Promise zostanie zakończony dopiero po zniszczeniu modala
         * @param {mixed} data dane do reject() na promise
         */
        this.setPromiseAsRejected = function(data) {
          this.promiseData = data;
          this.endPromiseAs = 'reject';
        };

        /**
         * Kliknięcie w jeden z tabów w nagłówku
         * @param  {Object} headerTab obiekt nagłówka
         * @return {undefined}
         */
        this.headerTabClicked = function(headerTab) {
          if (!headerTab.disabled && angular.isArray(this.options.headerTabs)) {
            angular.forEach(this.options.headerTabs, function(iHeaderTab) {
              iHeaderTab.selected = false;
            });
            headerTab.selected = true;
          }
          if (angular.isFunction(this.options.onHeaderTabChange)) {
            this.options.onHeaderTabChange(headerTab);
          }
        };

        /**
         * Ustawiamy promise modala na resolve
         * Promise zostanie zakończony dopiero po zniszczeniu modala
         * @param {mixed} data dane do resolve() na promise
         */
        this.setPromiseAsResolved = function(data) {
          this.promiseData = data;
          this.endPromiseAs = 'resolve';
        };

        /**
         * Modal został zniszczony, należy spełnić obietnicę (promise)
         * i po sobie posprzątać
         * @return {Object} this;
         */
        this.modalDestroyed = function() {
          if(this.modalDirectiveId) {
            if (this.endPromiseAs === 'resolve') {
              this.resolvePromise(this.promiseData);
            } else {
              this.rejectPromise(this.promiseData);
            }
            this.clearData();
          }
          return this;
        };
      };

      return LsnModalHelper;
    }
  ]);
