/**
 * extraLife widget config
 */
angular.module('ihestiaWidgets.extraLife')
  .provider('ihestiaExtraLifeConfig', [
    function() {

      var self = this;

      this.config = {
        buttonTemplate: 'ihestia-widgets-templates-app/extraLife/button/extraLifeButton.tpl.html',
        popupTemplate: 'ihestia-widgets-templates-app/extraLife/popup/extraLifePopup.tpl.html'
      };

      /**
       * Magic get
       * @return {Object}
       */
      this.$get = [
        function() {
          return self.config;
        }];
    }]);