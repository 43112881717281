angular.module('ihestiaWidgets.policyDetails')
  .factory('BusinessPartnerModel', [ 'LsnAbstractModel',
    function(LsnAbstractModel) {
      var EhlBusinessPartnerModel = function() {
        this.objectName = 'BusinessPartner';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          partnerType: {
            type: 'string'
          },
          personData: {
            type: 'InsuredPerson'
          },
          organizationData: {
            type: 'object'
          },
          addresses: {
            type: 'array',
            elementsType: 'object'
          },
          clauses: {
            type: 'array',
            elementsType: 'object'
          },
          commData: {
            type: 'array',
            elementsType: 'CommData'
          },
          vipStatus: {
            type: 'bool'
          }
        };

        this.partnerType = null;
        this.personData = null;
        this.organizationData = null;
        this.addresses = null;
        this.clauses = null;
        this.commData = null;
        this.vipStatus = false;
      };

      EhlBusinessPartnerModel.prototype = LsnAbstractModel.prototype;

      return EhlBusinessPartnerModel;
    }
  ]);
