/**
 * Helper do zapisywania i walidacji danych kontaktowych
 */
angular.module('ihestiaWidgets.updateContactData')
  .service('MyCustomerContactDataSaveHelper', ['$q', 'myCustomerContactDataSvc', '$alert', '$filter', 'LsnClausesModelConstants',
    function($q, myCustomerContactDataSvc, $alert, $filter, LsnClausesModelConstants) {

      var ContactDataSaveHelper = function(contactData) {
        var self = this;

        /**
         * Dane kontaktowe
         * @type {Object}
         */
        this.contactData = contactData;

        /**
         * Zapis danych
         * @return {Promise} resolve tylko wtedy, kiedy zapis się powiedzie
         */
        this.save = function() {
          return $q(function(resolve, reject) {
            /**
             * Tu zbieramy dane do usługi
             */
            var postData = {
              contactDetails: []
            };

            /**
             * Zapisujemy tylko te dane, które nie są readOnly
             */
            if (self.contactData.workerData && !self.contactData.workerData.isReadOnly) {
              var workerData = _.cloneDeep(self.contactData.workerData);
              workerData.clauses = _.map(workerData.clauses, function(clause) {
                clause.value = clause.value === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT || clause.noCheckbox
                  ? 'Consent' : 'Rejection';
                return clause;
              });
              postData.contactDetails.push(workerData);
            }

            if (self.contactData.agencyData && !self.contactData.agencyData.isReadOnly) {
              postData.contactDetails.push(self.contactData.agencyData);
            }

            /**
             * Właściwy zapis
             */
            var errorList = [];
            myCustomerContactDataSvc.ver('v2').post('', postData, null, null, null, {
              allowedStatuses: [409]
            }).then(function(res) {
              if (res && res.status === 200) {
                var hasWarnings = false;
                if (angular.isArray(res.data)) {
                  angular.forEach(res.data, function(data) {
                    if (data.type === 'WARN') {
                      hasWarnings = true;
                    }
                  });
                }
                var message = null; // NOSONAR
                if (hasWarnings) {
                  message = $filter('translate')('ucd.dataSavedWithWarns', {componentCode: 'Dashboard'});
                } else {
                  message = $filter('translate')('ucd.dataSaved', {componentCode: 'Dashboard'});
                }
                $alert({
                  content: message,
                  type: 'success'
                });
                resolve(res.data);
              } else if (res.status === 409) {
                reject(self.getValidationResponse(res));
              } else {
                errorList.push({
                  label: ['ucd.errorOccurredWhileWritingContactData', {
                    componentCode: 'Dashboard'
                  }]
                });
                reject(errorList);
              }
            }, function(res) {
              if (res.status === 409) {
                reject(self.getValidationResponse(res));
              } else {
                errorList.push({
                  label: ['ucd.errorOccurredWhileWritingContactData', {
                    componentCode: 'Dashboard'
                  }]
                });
                reject(errorList);
              }
            });
          });
        };

        /**
         * Zwracamy obiekt do parse-svc-validators
         * @param  {Object} response dane z usługi
         * @return {Array} błędy
         */
        this.getValidationResponse = function(response) {
          var validationResponse = response.data || [];
          angular.forEach(validationResponse, function(validationObject) {
            validationObject.field = validationObject.object ? validationObject.object : '';
            validationObject.defaultMessage = validationObject.text;
            validationObject.message = validationObject.defaultMessage;

            if (validationObject.label) {
              validationObject.message = $filter('translate')(validationObject.label[0], validationObject.label[1]);
            } else if (validationObject.object) {
              var prefixLabel = validationObject.object.indexOf('Intermediary') === 0 ? 'ucd.agencyData' : 'ucd.workerData';
              validationObject.message = '[' + ($filter('translate')(prefixLabel, {componentCode: 'Dashboard'}) + '] ' + validationObject.defaultMessage);
            }

            validationObject.messageUniqueHash = validationObject.prefixLabel + validationObject.defaultMessage;
          });

          validationResponse[0].uuid = Date.now();

          var errors = [];
          var warnings = [];
          var fieldsWithError = {};
          angular.forEach(validationResponse, function(validationMessage) {
            if (validationMessage.type === 'WARN') {
              warnings.push(validationMessage);
            } else {
              errors.push(validationMessage);
              fieldsWithError[validationMessage.field] = validationMessage.text;
            }
          });

          return {
            validationResponse: validationResponse,
            errors: errors,
            warnings: warnings,
            fieldsWithError: fieldsWithError
          };
        };

        /**
         * based on validation response, set errors on clauses.
         * first clears errors object
         * @param clauseListHelper
         * @param validationResponse
         */
        this.validateClauses = function(clauseListHelper, validationResponse) {
          clauseListHelper.settings.errors = null;

          //ustawianie informacji o błędach dla klauzul
          if (angular.isDefined(validationResponse)) {
            _.forEach(validationResponse.errors, function(error) {
              clauseListHelper.settings.errors = _.reduce(clauseListHelper.getClauses(), function(acc, value) {
                if (value.code === error.field.replace(/^Worker+/, '')) {
                  _.set(acc, value.code, true);
                }
                return acc;
              }, {});
            });
          }
        };
      };


      return ContactDataSaveHelper;
    }
  ]);
