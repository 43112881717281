angular.module('ihestiaWidgets.updateContactData')
  .service('myCustomerContactDataSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var MyCustomerContactDataSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'myCustomerContactData';
        this.businessArea = 'oss';
        this.pageStyle = false;
        this.sortBy = null;
      };

      var MyCustomerContactDataSvcV2 = function() {
        MyCustomerContactDataSvc.apply(this, arguments);
        this.apiVersion = 'v2';
      };

      return IHestiaRestServiceAbstractDef.setApiConstructors({
        v1: MyCustomerContactDataSvc,
        v2: MyCustomerContactDataSvcV2
      });
    }
  ]);
