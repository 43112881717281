angular.module('ihestiaCommonDirectives.changeLanguage')
	.directive('changeLanguage', ['$templateCache', 'ihestiaConfigHelper', 'changeLanguageConfig', function($templateCache, ihestiaConfigHelper, changeLanguageConfig) {
		return {
			scope: true,
			templateUrl: function(elem, attrs) {
				return attrs.drvTemplate || changeLanguageConfig.defaultConfig.template;
			},
			link: function($scope) {
				$scope.elementsAvailability.changeLnguage = $scope.elementsAvailability.changeLnguage && ihestiaConfigHelper.get('availableComponents', 'changeLnguage');
			}
		};
	}]);