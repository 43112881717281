angular.module('ihestiaWidgets.extraLife')
  .directive('ihestiaExtraLifePopupDrv', ['ihestiaExtraLifeConfig',
    function(ihestiaExtraLifeConfig) {
      return {
        restrict: 'E',
        scope: {},
        templateUrl: function() {
          return ihestiaExtraLifeConfig.popupTemplate;
        },
        controller: ['$scope', 'ihestiaExtraLifeHelper', 'lsnUtils', '$alert', '$filter',
          function($scope, ihestiaExtraLifeHelper, lsnUtils, $alert, $filter) {
            $scope.params = ihestiaExtraLifeHelper.params.popup;
            $scope.spinnerOptions = {
              label: ''
            };

            $scope.init = function() {
              $scope.params.refusalStep = false;
            };

            $scope.hide = function() {
              ihestiaExtraLifeHelper.hidePopup();
            };

            /**
             * click on refusal button
             */
            $scope.refusal = function() {
              $scope.params.refusalStep = true;
            };

            /**
             * make refusal decision
             * @param {string} answerCode answer code
             */
            $scope.refusalDecision = function(answerCode) {
              ihestiaExtraLifeHelper.postAnswer(answerCode);
            };

            /**
             * postpone decision
             */
            $scope.postpone = function() {
              ihestiaExtraLifeHelper.postponeDecision();
            };

            /**
             * copy salesPath url to clipboard
             */
            $scope.copyUrl = function() {
              if (lsnUtils.copyToClipboard($scope.params.data.siriusLink)) {
                $alert({
                  content: $filter('translate')('extraLife.popup.growl.urlHasBeenCopied', {componentCode: 'ExtraLife'}),
                  type: 'success'
                });
              }
            };

            $scope.init();
          }]
      }
        ;
    }
  ]);