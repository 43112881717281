/**
 * helper wznowienia
 */
angular.module('salesPath2').service('renewalHelper', ['resourceHelper', 'mainDataContainer', 'toRestRenewMapper', 'sp2UiHelper', 'policiesServiceHelper', 'ApplicationModel', 'fromRestApplicationMapper', 'applicationHelper', 'addHelper', 'appVariables', 'CONFIG', 'CONSTANTS', 'applicationDictionaryHelper', 'actionHelper', 'navigationHelper', 'sp2CommonHelper', 'VehicleModelConstants', 'VehicleModel', 'personDataFilter', 'RESOURCES', 'MapperUtils', 'fromRestRiskMapper', 'sp2SelectionHelper', 'OrganizationModel', 'PersonModel', 'SPD', 'addressHelper', '$filter', 'dataContainerHelper', 'ApplicationModelConstants', 'lsnUtils',
  function(resourceHelper, mainDataContainer, toRestRenewMapper, sp2UiHelper, policiesServiceHelper, ApplicationModel, fromRestApplicationMapper, applicationHelper, addHelper, appVariables, CONFIG, CONSTANTS, applicationDictionaryHelper, actionHelper, navigationHelper, sp2CommonHelper, VehicleModelConstants, VehicleModel, personDataFilter, RESOURCES, MapperUtils, fromRestRiskMapper, sp2SelectionHelper, OrganizationModel, PersonModel, SPD, addressHelper, $filter, dataContainerHelper, ApplicationModelConstants, lsnUtils) {
    var RenewalHelper = function() {
      var self = this;

      this.policySymbol = null;
      this.vehicleOutsideProductMsg = null; //treść komunikatu o pojezdzie spoza pakietu
      this.cessionToVerifyMsg = null; //treść komunikatu o weryfikacji cesjonariusza

      this._pushPeselIfExist = function(peselArray, person) {
        if (angular.isDefined(person.pesel) && person.pesel !== null && person.pesel !== '') {
          peselArray.push(person.pesel);
        }
        return peselArray;
      };

      this.startRenewal = function(policyId, policySymbol) {
        sp2UiHelper.showBlockUi($filter('translate')('loadingRenewal', {
          componentCode: 'sp2'
        }));
        self.policySymbol = policySymbol;
        //pobieramy powiązane polisy żeby umożliwić wybranie polis do wznawiania
        policiesServiceHelper.getLinkedPolicies(policyId, {
          context: 'renew'
        }, function(resp) {
          if (!angular.isArray(resp.data) || resp.data.length === 0) {
            self._renewFailed();
          } else if (resp.data.length === 1) {
            self.renewPolicies([policyId]);
          } else {
            var numbers = [];
            lsnNg.forEach(resp.data, function(policyData) {
              numbers.push(policyData.metaData.id);
            });
            self.renewPolicies(numbers);
          }
        }, self._renewFailed, {
          allowedStatuses: [403]
        });
      };

      /**
       * wznawia polisy
       * @param {string} policyId id polisy
       * @param {string} policySymbol symbol polisy
       */
      this.renewPolicies = function(policiesIds) {
        policiesServiceHelper.getRenew(policiesIds, self.policySymbol, function(resp) {
          if (angular.isUndefined(resp.data.metaData)) {
            self._renewFailed();
          } else {
            self.loadRenewApplication(resp.data);
          }
        }, self._renewFailed, {
          allowedStatuses: [403]
        });
      };

      /**
       * gdy błędy przy wznowieniu to wołaj tę metodę
       * @param  {Object} reject response usługi
       */
      this._renewFailed = function(reject) {
        sp2UiHelper.hideBlockUi();
        if (angular.isObject(reject) && reject.status === 403) {
          sp2CommonHelper.showConfirmModal({
            title: 'Komunikat',
            okBtnName: 'Zamknij',
            cancelBtnName: '',
            text: 'Wystąpiły problemy podczas wznawiania polisy. Spróbuj ponownie przejść proces wznawiania polisy, począwszy od wyszukania jej w kartotece.',
            size: 'sm',
            okBtnCallback: function() {
              navigationHelper.go(CONSTANTS.PAGE_INIT, {
                'action': 'new'
              });
            }
          });
        }
      };

      /**
       * obrabiamy wznowieniowy wniosek
       * @param  {Object} resp
       */
      this.loadRenewApplication = function(resp) {
        mainDataContainer.renewedApplication = angular.copy(resp);

        var application = new ApplicationModel(),
          //callback do wywołania po wczytaniu wznowienia
          applLoadedCallback = null;

        applicationHelper.checkExtraLife(resp);
        
        application.fillFromData(resp);

        //obsluga przypadku z organizacją jako ubezpieczony
        self.mapOrganizationsToPersons(application);

        //uzupelnienie typu domyslnego cesjonariusza dla przypadku cesji dodanej przez aneks
        self.addDefaultCessionType(application);

        MapperUtils.generateClientIds(application);

        //sprawdzenie czy pojazd spoza ETX lub spoza pakietu
        var vehicleOutsideProduct = self._isOutsideProductVehicle(application);
        if ((self._isNonEtxVehicle(application) && !CONFIG.BEHAVIOR.vehicle.nonEtxVehicles) || vehicleOutsideProduct) { //jesli pojazd spoza pakietu lub (spoza etx i niewspierany pojazd poza etx w danej apce
          mainDataContainer.unsupportedVehicle = true;
          self._replaceNonEtxVehicle(application);
        }

        //czy cesja do weryfikacji
        if (self._isCessionToVerify(application)) {
          applLoadedCallback = function(callback) {
            sp2CommonHelper.showConfirmModal({
              title: 'Komunikat',
              okBtnName: 'Zamknij',
              cancelBtnName: '',
              text: self.cessionToVerifyMsg,
              size: 'sm',
              okBtnCallback: function() {
                if (angular.isFunction(callback)) {
                  callback();
                }
              }
            });
          };
        }

        var tmpDm = fromRestApplicationMapper.mapApplication(application, {
          premium: true, //mapowanie skladek
          sumInsured: true, //mapowanie sum ubezpieczen
          additionalData: true, //mapowanie additionalData osób/firm/lokalizacji/pojazdów
          tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
          dataContainerProperties: true, //wszystko pozostalw dotyczące wniosku i dataManagera
          initialLoad: true,
          renewalMode: true
        });

        self._filterApplication(tmpDm);

        applicationHelper.setDataContainer(tmpDm, mainDataContainer);
        if (CONFIG.MODULES.additions) {
          addHelper.deleteUnavailable();
        }
        dataContainerHelper.alignPersonalRisksSumInsured();
        appVariables.appMode = CONSTANTS.APP_MODE_CALCULATION;
        appVariables.isInsurerChosen = true;
        if (self._isAnyApplicationVehicle(application)) {
          self._processVehicle();
        }
        self._deleteUnavailableRisks();
        applicationDictionaryHelper.loadDictionariesForApplication(application, function() {
          sp2UiHelper.hideBlockUi();
          if (!mainDataContainer.unsupportedVehicle) {
            navigationHelper.go(appVariables.mainState);
            if (angular.isFunction(applLoadedCallback)) {
              applLoadedCallback();
            }
            actionHelper.runAction('tarifficationEnded');
            applicationHelper.saveApplication();
          } else {
            navigationHelper.go(appVariables.mainState);
            sp2CommonHelper.showConfirmModal({
              title: 'Komunikat',
              okBtnName: 'Zamknij',
              cancelBtnName: '',
              text: 'Pojazd ze wznawianej polisy jest niedostępny w wybranym produkcie. Zaproponuj nowe ubezpieczenie Klientowi.',
              size: 'sm',
              okBtnCallback: function() {
                if (angular.isFunction(applLoadedCallback)) {
                  applLoadedCallback(function() {
                    navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
                  });
                } else {
                  navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
                }
              }
            });
          }
        });
      };

      /**
       * dorzuca do pojazdu w dataContainerze niezbędne elementy do poprawnego dzialania wniosku
       */
      this._processVehicle = function() {
        if (angular.equals(mainDataContainer.vehicles, {})) {
          return false;
        }
        lsnNg.forEach(mainDataContainer.vehicles, function(vehicle) {
          //daty ochrony - jesli nie uzupelnione, to dodajemy domyslne
          if (vehicle.getAdditionalData('protectionDates') === null) {
            vehicle.setAdditionalData({
              start: mainDataContainer.defaultStartDate.toString('yyyy-MM-dd'),
              end: mainDataContainer.defaultEndDate.toString('yyyy-MM-dd')
            }, 'protectionDates');
          }
        });
        return true;
      };
      /**
       * czy na wznowieniu istnieje pojazd spoza ETX
       * @param  {Application} application [description]
       * @return {Boolean}
       */
      this._isNonEtxVehicle = function(application) {
        if (!self._isAnyApplicationVehicle(application)) {
          return false;
        }
        var vehicle = application.vehicles[0], //oblsuga tylko jednego pojazdu poki co
          typeData = vehicle.get('type', false, true);
        if (angular.isArray(typeData) && angular.isString(typeData[2]) && typeData[2] !== VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX) {
          return true;
        }
        return false;
      };

      /**
       * czy na wznowieniu istnieje pojazd niedostepny w danym pakiecie
       * @param  {Application} application [description]
       * @return {Boolean}
       */
      this._isOutsideProductVehicle = function(application) {
        var outside = self._findMessage('P000041', application);
        if (angular.isString(outside)) {
          self.vehicleOutsideProductMsg = outside;
          return true;
        } else {
          return false;
        }
      };

      /**
       * czy istnieje cesjonariusz, którego dane należy zweryfikować
       * @param  {Application} application [description]
       * @return {Boolean}
       */
      this._isCessionToVerify = function(application) {
        var verify = self._findMessage('U000088', application);
        if (angular.isString(verify)) {
          self.cessionToVerifyMsg = verify;
          return true;
        } else {
          return false;
        }
      };

      /**
       * szuka komunikatu o danym kodzie i zwraca jesgo tekst lub false gdy nie znaleziono
       * @param  {String} code kod komunikatu
       * @param  {Application} application
       * @return {Boolean|String} txt lub false gdy nie znaleziono
       */
      this._findMessage = function(code, application) {
        if (!self._isAnyApplicationVehicle(application) || (!angular.isArray(application.messages) || application.messages.length === 0)) {
          return false;
        }
        var result = false;
        lsnNg.forEach(application.messages, function(msg) {
          if (msg.code === code) {
            result = msg.text;
            return false;
          }
          return true; //continue
        });
        return result;
      };

      /**
       * czy na wniosku istnieje jakis pojazd
       * @param  {Application}  application
       * @return {Boolean}
       */
      this._isAnyApplicationVehicle = function(application) {
        if (application.get('vehicles').length === 0) {
          return false;
        }
        return true;
      };

      /**
       * zastępuje wznawiamy pojaad spoza ETX nowym obiektem dziedziczącym kilka atrybutów po starym pojeździe
       * Uwaga
       * po tej akcji powinno następić przekierowanie na szukajkę pojazdu w celu wybrania poprawnego pojazdu wg ETX
       * @param  {Application} application
       */
      this._replaceNonEtxVehicle = function(application) {
        var dataToRewrite = [], //tak bylo popraednio 'firstRegistration', 'registration', 'vin'
          metaDataToRewrite = ['id', 'clientId'],
          oldVehicle = application.vehicles[0], //tylko jeden pojazd i zakladamy ze sprawdzono jego istnienie przed wejsciem do tej metody
          newVehicle = new VehicleModel(),
          tempVehicleData = {
            metaData: {}
          };

        angular.forEach(metaDataToRewrite, function(fieldName) {
          newVehicle.metaData.set(fieldName, oldVehicle.metaData.get(fieldName));
          tempVehicleData.metaData[fieldName] = oldVehicle.metaData.get(fieldName);
        });
        angular.forEach(dataToRewrite, function(fieldName) {
          newVehicle.set(fieldName, oldVehicle.get(fieldName));
          tempVehicleData[fieldName] = oldVehicle.get(fieldName);
        });
        application.vehicles[0] = newVehicle;
        mainDataContainer.tempVehicleData = tempVehicleData; //dzieki temu dane zostana przeniesione do okna edycji pojazdu po wejsciu z szukajki ETX
      };
      /**
       * Wniosek stworzony po stronie usługi, więc musimy filtrować część rzeczy
       * @param  {XPegaz.Mapper.FromRest.TempDataManager} tmpDm
       */
      this._filterApplication = function(tmpDm) {
        angular.forEach(tmpDm.persons, function(person) { //filtrujemy osoby
          person = personDataFilter(person, CONSTANTS.SUBJECT_ANY);
        });
      };

      /**
       * ususwa/odznacza ryzyka, które nie mogą być zaznaczone przy aktualnej konfiguracji ryzyk
       */
      this._deleteUnavailableRisks = function() {
        //dla kradziezy i ognia
        var fbRiskList = RESOURCES.FIRE_BURGLARY_RISKS_LIST;
        angular.forEach(resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_LOCALIZATION), function(productCode) {
          for (var key in fbRiskList) {
            if (!dataContainerHelper.isLocalizationRiskAvailable(productCode, key)) {
              dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, productCode, 0, key); //odznaczamy ryzyko = suma 0
            }
          }
        });
      };

      /**
       * czy wniosek jest w trybie wznowienia
       * @return {Boolean}
       */
      this.isRenewal = function(dataManager) {
        if (angular.isUndefined(dataManager)) {
          dataManager = mainDataContainer;
        }
        if (mainDataContainer.application && mainDataContainer.application.operationType === ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW) {
          return true;
        }
        return false;
      };

      /**
       * Zwraca listę wznawianych ryzyk
       * @return {Object}
       */
      this.getRenewedRisks = function(dataContainer) {
        if (angular.isUndefined(dataContainer)) {
          dataContainer = mainDataContainer;
        }
        var renewedRisks = {},
          commProds = resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_VEHICLE);

        lsnNg.forEach(dataContainer.renewedApplication.risks, function(renewalRisk) {
          var variant,
            riskDef = MapperUtils.findRiskDefByIdpm(renewalRisk.product.compId),
            allow = self.isRiskRelatedWithSelectedObject(renewalRisk, riskDef, dataContainer.renewedApplication); ////czy pozwolić na dodanie informacji o ryzyku do znaczników
          if (!allow) {
            return true;
          }
          if (resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_LOCALIZATION).indexOf(riskDef.productCode) !== -1) //badamy też zgodność riskCode
          {
            if (angular.isUndefined(renewedRisks[riskDef.productCode])) {
              renewedRisks[riskDef.productCode] = {};
            }
            renewedRisks[riskDef.productCode][riskDef.riskCode] = renewalRisk.product.dynamicValues.coverageOption;
          } else if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(riskDef.productCode) !== -1 || (CONFIG.MODULES.additions && MapperUtils.isAddition(riskDef.productCode) && angular.isDefined(resourceHelper.getAddDef(riskDef.productCode).VARIANTS))) //sprawdzamy wariant
          {
            if (angular.isUndefined(renewedRisks[riskDef.productCode])) {
              renewedRisks[riskDef.productCode] = {};
            }
            variant = fromRestRiskMapper.getRiskVariant(riskDef, renewalRisk.product.dynamicValues);
            renewedRisks[riskDef.productCode][variant] = true;

          } else if (angular.isDefined(renewalRisk.product.dynamicValues.coverageOption)) { //standardowe ryzyka z wariantami
            variant = fromRestRiskMapper.getRiskVariant(riskDef, renewalRisk.product.dynamicValues);
            renewedRisks[riskDef.productCode] = variant;
          } else if (commProds.indexOf(riskDef.productCode) !== -1) {
            renewedRisks[riskDef.productCode] = CONSTANTS.VARIANT_I; //dla ryzyk komunikacyjnych zawsze mamy jakiś wariant. Nawet gdy wg BOSa go nie ma to my zaznzaczamy I
          } else {
            renewedRisks[riskDef.productCode] = true;
          }
          return true;
        });

        return renewedRisks;
      };

      /**
       * sprawdza czy ryzyko jest związane z aktualnie wybranym obiektem ubezpieczenia (np. domem, pojazdem)
       * Działanie:
       * - sprawdzamy tylko produkty typu mieniowe i pojazdowe
       * - sprawdzamy tylko w przypadku, gdy w sprzedażówce możliwy jest wybór wielu lokalizacji lub pojazdów. W przeciwynum wypadku metoda zawsze zwróci wartość true
       * - sprawdzamy obiekt powiązany z ryzykiem i przyrównujemy go (identyfikacja obiektu - patrz kod poniżej) do aktualnie wybranego na ścieżce
       * @param  {Object}  risk        zrzut z RiskModel
       * @param  {Object}  riskDef definicja ryzyka z spd
       * @param  {Object}  application zrzut z wniosku ubezpieczeniowego (ApplicationModel)
       * @return {Boolean}
       */
      this.isRiskRelatedWithSelectedObject = function(risk, riskDef, application) {
        var productType = resourceHelper.productType[riskDef.productCode];
        if (MapperUtils.isAddition(riskDef.productCode)) {
          var addDef = resourceHelper.getAddDef(riskDef.productCode, true);
          if (addDef === null) { //dodatki, których nie prezentujemy na GUI
            return false;
          }
          productType = addDef.TYPE;
        }
        if (productType !== CONSTANTS.PRODUCT_TYPE_LOCALIZATION && productType !== CONSTANTS.PRODUCT_TYPE_VEHICLE) {
          return true;
        }

        var selObjId = dataContainerHelper.getSelectedObjectId(productType),
          objIdData = null, //dane identyfikacyjne obiektu
          riskObjId = null, //id obiektu (metaData.id) powizandego z ryzykiem
          related = false;
        if (parseInt(selObjId, 10) < 1) { //brak wybranego obiektu lub wybrana opcja: 'wszystkie'
          return true; //jesli nie inteersuje nas konkretny obiekt, to dowolne ryzyko danego typu będzie spełniało warunki
        }
        if (!angular.isArray(risk.insuredObjectsRef) || risk.insuredObjectsRef.length === 0) {
          return false; //brak odniesienia do obiektów ubezpieczenia
        }
        riskObjId = risk.insuredObjectsRef[0].ref;
        switch (productType) {
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            if (!CONFIG.BEHAVIOR.multipleLocalizations) {
              return true;
            }
            objIdData = self._getEstateIdData(mainDataContainer.localizations[selObjId]);
            if (angular.isArray(application.estates)) {
              lsnNg.forEach(application.estates, function(estate) {
                if (estate.metaData.id === riskObjId && angular.equals(objIdData, self._getEstateIdData(estate))) {
                  related = true;
                  return false;
                }
                return true;
              });
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            if (!CONFIG.BEHAVIOR.multipleVehicles) {
              return true;
            }
            objIdData = mainDataContainer.vehicles[selObjId].vin;
            if (angular.isArray(application.vehicles)) {
              lsnNg.forEach(application.vehicles, function(vehicle) {
                if (vehicle.metaData.id === riskObjId && objIdData === vehicle.vin) {
                  related = true;
                  return false;
                }
                return true;
              });
            }
            break;
          default:
            sp2CommonHelper.throwException('Unsupported product type: {0} (renewalHelper.isRiskRelatedWithSelectedObject).'.format(productType));
        }
        return related;
      };

      /**
       * czy polisa zawiera pojazd o podanym numerze vin
       * @param  {PolicyModel}  policy polisa
       * @param  {String}  vin  vin
       * @return {Boolean}
       */
      this._hasVehicleWithVin = function(policy, vin) {
        if (!angular.isArray(policy.vehicles)) {
          return false;
        }
        return lsnUtils.findObjInArray(policy.vehicles, {
          vin: vin
        }) !== null;
      };

      //isAddition

      /**
       * Zwaraca oznaczenia wznowień dla ognia lub kradzieży
       * @param  {string} productCode
       * @return {Object}
       */
      this.getSubRisksMarks = function(productCode) {

        if (!self.isRenewal()) //nie jest to wznowienie
        {
          return {};
        }
        var renewedRisks = self.getRenewedRisks();
        if (angular.isUndefined(renewedRisks[productCode])) // ryzyka nie było na wznowieniu
        {
          return {};
        }
        var marks = {};
        var localizationId = mainDataContainer.selectedLocalization;
        if (angular.isDefined(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][localizationId]) && angular.isDefined(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][localizationId][productCode])) {
          lsnNg.forEach(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][localizationId][productCode], function(variants, subRiskCode) {
            if (subRiskCode === CONSTANTS.SUM_NAME) {
              return true; //suma z wszystkich
            }
            lsnNg.forEach(variants, function(count) {
              if (count > 0) {
                marks[subRiskCode] = true;
              }
            });

            return true; //continue
          });
        }
        //przypadek - nie jest oznaczony jako wznowiane, ale jest na ryzyku wznowionym i nie jest zaznaczony żaden wariant
        var productSelected = dataContainerHelper.isProductSelected(productCode, mainDataContainer.selectedLocalization); //UWAGA obsluga tylko pojedynczej lokalizacji
        if (angular.isDefined(renewedRisks[productCode])) {
          angular.forEach(renewedRisks[productCode], function(variant, subRiskCode) {
            if (angular.isUndefined(marks[subRiskCode]) && !productSelected) {
              marks[subRiskCode] = true;
            }
          });
        }
        return marks;
      };

      /**
       * Zwraca oznaczenia wznowień dla produktów
       * @param {String} productCode opcjonalny kod produktu, do którego zawężyć działanie metody
       * @return {Object}
       */
      this.getProductsMarks = function(productCode) {
        if (!self.isRenewal()) {
          return {};
        }
        var marks = {};
        var renewedRisks = self.getRenewedRisks();
        /**
         * zwraca wariant dla ryzyk mieniowych
         * @param  {Object} renewedRisks ryzyko => zaznaczony wariant ze starej polisy
         * @return {String|null}
         */
        var getPropertyRenewVariant = function(renewedRisks) {
          var variant = null;
          lsnNg.forEach(renewedRisks, function(riskVariant) {
            variant = riskVariant;
            return false;
          });
          return variant;
        };
        lsnNg.forEach(RESOURCES.PRODUCTLIST, function(prodDef) {
          if (productCode && productCode !== prodDef.CODE) { //jeśli sprawdzamy tylko dla jednego produktu
            return true;
          }
          if (angular.isUndefined(renewedRisks[prodDef.CODE])) //nie ma ryzyka na wznawianych
          {
            return true;
          }
          var productType = resourceHelper.productType[prodDef.CODE];
          var objectId; // (string) clientId danego obiektu

          //sprawdzamy czy jest jakiś wariant zaznaczony i jeśli tak to jaki
          var selectedVariants = dataContainerHelper.getVariantsForProduct(prodDef.CODE);
          var firstSelectedVariant = null;
          lsnNg.forEach(selectedVariants, function(selected, variant) {
            if (selected) {
              firstSelectedVariant = variant;
              return false;
            }
            return true; //continue
          });

          if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || productType === CONSTANTS.PRODUCT_TYPE_VEHICLE) //produkty powiązane z lokalizacjami lub z pojazdami
          {
            objectId = dataContainerHelper.getSelectedObjectId(productType);
            if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) //są podryzyka
            {
              //jest zaznaczenie na wybranym produkcie
              if (angular.isDefined(mainDataContainer.renewedList[productType][objectId]) && angular.isDefined(mainDataContainer.renewedList[productType][objectId][prodDef.CODE]) && angular.isString(firstSelectedVariant)) {
                self._setMarkForProduct(marks, mainDataContainer.renewedList[productType][objectId][prodDef.CODE]._sum, prodDef.CODE, getPropertyRenewVariant(renewedRisks[prodDef.CODE])); //zawsze zaznaczamy wariant jaki był na starej polisie
              }
              if (angular.isUndefined(marks[prodDef.CODE]) && firstSelectedVariant === null) {
                //brak zaznaczenia produktu, więc oznaczamy to co było na wznawianej polisie
                lsnNg.forEach(renewedRisks[prodDef.CODE], function(variant) {
                  //wyciągamy wariant z pierwszego z brzegu podryzyka
                  marks[prodDef.CODE] = variant;
                  return false;
                });
              }
            } else {
              //pojazdy
              if (angular.isDefined(mainDataContainer.renewedList[productType][objectId]) && angular.isDefined(mainDataContainer.renewedList[productType][objectId][prodDef.CODE])) {
                self._setMarkForProduct(marks, mainDataContainer.renewedList[productType][objectId][prodDef.CODE], prodDef.CODE, renewedRisks[prodDef.CODE]); //zawsze zaznaczamy wariant jaki był na starej polisie
              }
              if (angular.isUndefined(marks[prodDef.CODE]) && firstSelectedVariant === null) {
                //brak zaznaczenia produktu, więc oznaczamy to co było na wznawianej polisie
                if (angular.isUndefined(prodDef.VARIANTLIST) || prodDef.VARIANTLIST.length === 1) //ryzyko nie ma wariantów, ale na interfejsie jest niezbędne zaznaczenie
                {
                  marks[prodDef.CODE] = CONSTANTS.VARIANT_I;
                } else {
                  marks[prodDef.CODE] = renewedRisks[prodDef.CODE];
                }
              }
            }
          } else //produkty powiązane z osobami
          {
            lsnNg.forEach(mainDataContainer.renewedList[productType], function(personRisks) {
              if (angular.isUndefined(personRisks) || angular.isUndefined(personRisks[prodDef.CODE])) {
                //ta osoba nie ma tego ryzyka, lecimy dalej
                return true;
              }
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(prodDef.CODE) === -1) //możliwy do wyboru tylko jeden wariant na raz
              {
                self._setMarkForProduct(marks, personRisks[prodDef.CODE], prodDef.CODE, renewedRisks[prodDef.CODE]); //zaznaczamy oryginalny wariant ze starej polisy
                if (angular.isDefined(marks[prodDef.CODE])) {
                  return false;
                }
              } else //wielowariantowe ryzyka, np NNW indywidualne
              {
                angular.forEach(renewedRisks[prodDef.CODE], function(selected, riskVariant) {
                  //zaznaczamy pierwssy z brzegu wariant, który był na wznawianej polisie - (UWAGA - ewentualnie do ustalenia/zmiany)
                  self._setMarkForProduct(marks, personRisks[prodDef.CODE], prodDef.CODE, riskVariant);
                });
              }
              return true; //continue
            });
            if (angular.isUndefined(marks[prodDef.CODE]) && firstSelectedVariant === null) {
              //brak zaznaczenia produktu, więc oznaczamy to co było na wznawianej polisie
              marks[prodDef.CODE] = renewedRisks[prodDef.CODE];
            }
          }

          return true; //continue
        });
        //obsluga sharedvariant dla ognia i kradziezy
        if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && (marks[CONSTANTS.PRODUCT_OGI] === CONSTANTS.VARIANT_III || marks[CONSTANTS.PRODUCT_KRA] === CONSTANTS.VARIANT_III)) {
          marks[CONSTANTS.PRODUCT_OGI_KRA] = CONSTANTS.VARIANT_III;
        }
        //nnw forma bezimienna
        if (CONFIG.BEHAVIOR.nnwIncognito) {
          var markVariants = self.getPersonalRiskMarks(CONSTANTS.PRODUCT_NNW, CONSTANTS.NNW_INCOGNITO_PERSON_ID);
          if (markVariants.length > 0) {
            if (!angular.isObject(marks[CONSTANTS.PRODUCT_NNW])) {
              marks[CONSTANTS.PRODUCT_NNW] = {};
            }
            angular.forEach(markVariants, function(vnt) {
              marks[CONSTANTS.PRODUCT_NNW][vnt] = true;
            });
          }
        }
        return marks;
      };

      /**
       * uzupełnia w marks warianty do oznaczenia na podstawie danych z variantsContainer, który jest wycinkiem renewedList
       * @param {Object} marks
       * @param {Object} variantsContainer
       * @param {string} productCode
       * @param {string|null} firstSelectedVariant pierwszy z zaznaczonych na matrycy wariantów dla rozpatrywanego ryzyka lub po prostu przekazany na sztywno wariant do zaznaczenia
       */
      this._setMarkForProduct = function(marks, variantsContainer, productCode, firstSelectedVariant) {
        lsnNg.forEach(variantsContainer, function(count, variant) {
          if (count > 0) {
            if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) === -1) //możliwy do wyboru tylko jeden wariant na raz
            {
              if (angular.isDefined(firstSelectedVariant) && firstSelectedVariant !== null) {
                marks[productCode] = firstSelectedVariant; //jest taki wariant zaznaczony na Gui, więc ten chcemy oznaczyć
              } else {
                marks[productCode] = variant;
              }
              return false;
            } else {
              if (angular.isUndefined(marks[productCode])) {
                marks[productCode] = {};
              }
              if (angular.isDefined(firstSelectedVariant) && firstSelectedVariant !== null) {
                marks[productCode][firstSelectedVariant] = true;
              } else {
                marks[productCode][variant] = true;
              }
            }
          }

          return true; //continue
        });
      };

      /**
       * Zwraca oznaczenia wznowień dla dodatków
       * @return {Object}
       */
      this.getAddsMarks = function() {
        if (!self.isRenewal()) {
          return {};
        }
        var marks = {};
        var renewedRisks = self.getRenewedRisks();
        var selectedAdds = sp2SelectionHelper.getSelectedAdds();
        lsnNg.forEach(RESOURCES.PRODADD, function(addDef) {
          if (angular.isUndefined(renewedRisks[addDef.CODE])) //nie ma ryzyka na wznawianych
          {
            return true;
          }
          var objectId; // (string)

          //sprawdzamy czy jest jakiś wariant zaznaczony i jeśli tak to jaki
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isDefined(mainDataContainer.renewedList[addDef.TYPE]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][addDef.CODE])) {
              self._setMarkForAdd(marks, mainDataContainer.renewedList[addDef.TYPE][addDef.CODE], addDef);
            }
          } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) //produkty powiązane z lokalizacjami lub z pojazdami
          {
            objectId = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
            //pojazdy
            if (angular.isDefined(mainDataContainer.renewedList[addDef.TYPE]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][objectId]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][objectId][addDef.CODE])) {
              self._setMarkForAdd(marks, mainDataContainer.renewedList[addDef.TYPE][objectId][addDef.CODE], addDef);
            }
          } else //produkty powiązane z osobami
          {
            lsnNg.forEach(mainDataContainer.renewedList[addDef.TYPE], function(personRisks) {
              if (angular.isUndefined(personRisks)) {
                //ta osoba nie ma tego ryzyka, lecimy dalej
                return true;
              }
              self._setMarkForAdd(marks, personRisks[addDef.CODE], addDef);
              if (angular.isDefined(marks[addDef.CODE])) {
                return false;
              }

              return true; //continue
            });
          }
          if (angular.isUndefined(marks[addDef.CODE]) && angular.isUndefined(selectedAdds[addDef.CODE])) {
            marks[addDef.CODE] = true;
          }

          return true; //continue
        });
        return marks;
      };

      /**
       * uzupełnia w marks ryzyka do oznaczenia na podstawie danych z variantsContainer, który jest wycinkiem renewedList
       * @param {Object} marks
       * @param {Object} variantsContainer
       * @param {Object} addDef
       */
      this._setMarkForAdd = function(marks, variantsContainer, addDef) {
        if (angular.isUndefined(addDef.VARIANTS)) //bez wariantów
        {
          if (variantsContainer > 0) {
            marks[addDef.CODE] = true;
          }
        } else {
          angular.forEach(variantsContainer, function(count) {
            if (count > 0) {
              marks[addDef.CODE] = true;
            }
          });
        }
      };

      /**
       * Zwraca listę wariantów podanego ryzyka dla których na płachcie trzeba zaznaczyć wznowienia
       * @param  {string} addCode [description]
       * @return {Object}         [description]
       */
      this.getAddSheetMarks = function(addCode) {
        if (!self.isRenewal()) {
          return {};
        }
        var addDef = resourceHelper.getAddDef(addCode);
        var marks = {};
        var renewedRisks = self.getRenewedRisks();

        if (angular.isUndefined(renewedRisks[addCode])) //nie ma ryzyka na wznawianych
        {
          return marks;
        }

        //określamy aktywny obiekt
        var objectId = null;
        if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) //produkty powiązane z lokalizacjami lub z pojazdami
        {
          objectId = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
        }

        var selectedVariants = {};
        var anyVariantSelected = false;
        lsnNg.forEach(addDef.VARIANTS, function(variantData) {
          if (sp2SelectionHelper.isAddSelected(addCode, objectId, variantData.CODE)) {
            selectedVariants[variantData.CODE] = true;
            anyVariantSelected = true;
          }
        });

        if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
          if (angular.isDefined(mainDataContainer.renewedList[addDef.TYPE]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][addDef.CODE])) {
            self._setMarkForAddVariant(marks, mainDataContainer.renewedList[addDef.TYPE][addDef.CODE], addDef);
          }
        } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) //produkty powiązane z lokalizacjami lub z pojazdami
        {
          if (angular.isDefined(mainDataContainer.renewedList[addDef.TYPE]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][objectId]) && angular.isDefined(mainDataContainer.renewedList[addDef.TYPE][objectId][addDef.CODE])) {
            self._setMarkForAddVariant(marks, mainDataContainer.renewedList[addDef.TYPE][objectId][addDef.CODE], addDef);
          }
        } else //produkty powiązane z osobami
        {
          lsnNg.forEach(mainDataContainer.renewedList[addDef.TYPE], function(personRisks) {
            if (angular.isUndefined(personRisks)) {
              //ta osoba nie ma tego ryzyka, lecimy dalej
              return true;
            }
            self._setMarkForAddVariant(marks, personRisks[addDef.CODE], addDef);
            if (angular.isDefined(marks[addDef.CODE])) {
              return false;
            }

            return true; //continue
          });
        }
        var anyMark = false;
        lsnNg.forEach(marks, function() {
          anyMark = true;
          return false;
        });
        if (!anyMark && !anyVariantSelected) {
          marks = renewedRisks[addCode];
        }
        return marks;
      };

      /**
       * uzupełnia w marks warianty ryzyka do oznaczenia na podstawie danych z variantsContainer, który jest wycinkiem renewedList
       * @param {Object} marks
       * @param {Object} variantsContainer
       */
      this._setMarkForAddVariant = function(marks, variantsContainer) {
        lsnNg.forEach(variantsContainer, function(count, variant) {
          if (count > 0) {
            marks[variant] = true;
          }
        });
      };

      /**
       * przemapowuje organizacje na osoby dla przypadków, w których pakiet tego wymaga
       * @param {Application} application wniosek
       */
      this.mapOrganizationsToPersons = function(application) {
        if (application.get('organizations').length === 0) {
          return;
        }
        var remappedIds = []; //ids zmapowanych organizacji
        for (var k = application.organizations.length - 1; k >= 0; k -= 1) {
          var org = application.organizations[k],
            orgId = org.metaData.get('id');
          if (org.get('dynamicValues').type !== 'leasing' && org.get('dynamicValues').type !== 'bank' && !self.isCession(application, orgId) && remappedIds.indexOf(orgId) === -1) { //leasing i bank zostawiamy taki jaki byl
            self.remapOrganization(application, orgId);
            remappedIds.push(orgId);
          }
        }
      };

      /**
       * czy organizacja jest cesjonariuszem
       * @param  {[type]}  application    [description]
       * @param  {[type]}  organizationId [description]
       * @return {Boolean}                [description]
       */
      this.isCession = function(application, organizationId) {
        var isCession = false;
        lsnNg.forEach(application.policies, function(policy) {
          if (angular.isArray(policy.suretyRef) && policy.suretyRef.indexOf(organizationId) !== -1) {
            isCession = true;
            return false;
          }
          return true; //continue
        });
        return isCession;
      };

      /**
       * przemapowauje organizację przekazaną jako główny ube3zpieczony lub ubezpieczajacy do osoby
       * @param {Application} application wniosek
       * @param {String} orgId id organizacji
       */
      this.remapOrganization = function(application, orgId) {
        var personOrg = null,
          org; // (string) clientId organizacji
        for (var k = application.organizations.length - 1; k >= 0; k -= 1) {
          org = application.organizations[k];
          if (org.metaData.get('id') === orgId) {
            personOrg = new OrganizationModel();
            personOrg.set('addresses', [addressHelper.getAdrStal()]);
            personOrg.fillFromData(org.getData());
            application.organizations.splice(k, 1);
            break;
          }
        }
        if (personOrg === null) {
          return;
        }
        var insuredPerson = new PersonModel(); //osoba powstała z firmy
        //mapowanie organizacji do osoby
        var mapDynamicValues = function(val, person) {
          var valsToMap = {};
          lsnNg.forEach(val, function(value, name) {
            if (angular.isDefined(SPD.objectsDynamicValues.Person[name])) {
              //kilka szczegolnych przypadkow
              if (name === 'dateOfBirth') {
                person.set('birthDate', value);
              } else if (name === 'businessPartnerType') {
                return true;
              }
              valsToMap[name] = value;
            }
            return true; //continue
          });
          person.set('dynamicValues', valsToMap);
        };
        var mapNames = function(val, person) {
          if (!angular.isString(val)) {
            return;
          }
          var vals = val.trim().split(' ');
          if (angular.isDefined(vals[0])) {
            person.set('lastName', vals[0]);
          }
          if (angular.isDefined(vals[1])) {
            person.set('firstName', vals[1]);
          }
        };

        /**
         * pole z organizacji do pola z osoby
         * pole z osoby może być callbackiem przyjmującym 2 argumenty (1 - wartosc mapowanego elementy, 2- obiekt do którego mapujemy - Person)
         */
        var propertyMapping = {
          'metaData': 'metaData',
          'contacts': 'contacts',
          'countryCode': 'citizenshipCode',
          'addresses': 'addresses',
          'dynamicValues': mapDynamicValues,
          'name': mapNames
        };

        angular.forEach(propertyMapping, function(personProp, orgProp) {
          if (angular.isFunction(personProp)) {
            personProp(personOrg.get(orgProp), insuredPerson);
          } else {
            insuredPerson.set(personProp, personOrg.get(orgProp));
          }
        });
        personDataFilter(insuredPerson, (application.get('insuredRef') === orgId) ? CONSTANTS.SUBJECT_INSURED : CONSTANTS.SUBJECT_ANY);
        if (application.persons === null) {
          application.persons = [];
        }
        application.persons.push(insuredPerson);
        sp2CommonHelper.showConfirmModal({
          title: 'Komunikat',
          okBtnName: 'Zamknij',
          cancelBtnName: '',
          text: 'Dane {0} zostały zmienione w Kartotece Klientów z osoby fizycznej na firmę. Uzupełnij dane o kliencie na nowo.'.format(application.get('holderRef') === orgId ? 'ubezpieczającego' : 'ubezpieczonego'),
          size: 'sm',
          okBtnCallback: function() {
            lsnNg.noop();
          }
        });
      };

      /**
       * ustawia domyślną wartość typu cesjinariusza/organizacji
       * @param {Application} application wniosek
       */
      this.addDefaultCessionType = function(application) {
        if (!angular.isArray(application.policies) || !angular.isArray(application.organizations)) {
          return;
        }
        lsnNg.forEach(application.policies, function(policy) {
          if (!angular.isArray(policy.suretyRef)) {
            return true;
          }
          lsnNg.forEach(policy.suretyRef, function(orgId) {
            lsnNg.forEach(application.organizations, function(org) {
              if ((orgId + '') === org.metaData.get('id')) { //nasz cesjonariusz
                var dynVals = org.get('dynamicValues');
                if (angular.isUndefined(dynVals.type) || dynVals.type === null || dynVals.type === '') {
                  dynVals.type = CONSTANTS.ORGANIZATION_TYPE_LEASING;
                }
                org.set('dynamicValues', dynVals);
                return false;
              }
              return true; //continue
            });
          });

          return true; //continue
        });
      };

      /**
       * zwraca tablicę wariantów, które należy zaprezentować na GUI dla danej osoby w yzyku osobowym
       * Działanie:
       * - jeśli wybrano aktualnie wariant do wznowienia, to sprawdzamy na podstawie odpowiedzi z usługi zapisu/taryfikacji
       * - jeśli nie wybrano wariantu, to prezentujemy na podstawie informacji z poprzedniej/wznawianej polisy
       * @param  {String} productCode kod produktu
       * @param  {String} clientId clientId osoby
       * @return {String[]} tablica wariantów do oznaczenia
       */
      this.getPersonalRiskMarks = function(productCode, clientId) {
        var renewedVariants = self.getRenewedPersonalRiskVariants(productCode, clientId),
          marks = [],
          selectedVariants = dataContainerHelper.getVariantsForProduct(productCode, clientId),
          prevSelectedVariants = self.getPreviousPersonalRiskMarks(productCode, clientId),
          variantsGroups = resourceHelper.getVariantsGroupsForProduct(productCode);
        if (productCode === CONSTANTS.PRODUCT_NNW && clientId === CONSTANTS.NNW_INCOGNITO_PERSON_ID && angular.isObject(mainDataContainer.nnwIncognito) && mainDataContainer.nnwIncognito.variants[CONSTANTS.VARIANT_I]) { //przypadek nnw - forma bezimienna
          selectedVariants[CONSTANTS.VARIANT_I] = true;
        }
        angular.forEach(variantsGroups, function(group) {
          self._setMarksFroVariantGroup(marks, group, selectedVariants, prevSelectedVariants, renewedVariants);
        });
        return marks;
      };

      /**
       * dodaje do marks warainty, któ®e należy oznaczyć na GUI przy wznowieniach
       * @param {String[]} marks lista wariantów do oznaczenia (dla wszystkich grup)
       * @param {String[]} group lista wariantów do rozpatrzenia
       * @param {Object} selectedVariants aktualnie zaznaczone warianty np. {'I': true} (dla wszystkich grup)
       * @param {String[]} prevSelectedVariants lista wariantów wybranych na poprzedniej/wznawianej polisie (dla wszystkich grup)
       * @param {String[]} renewedVariants lista aktualnych wariantów pozytywnie wznowionych (które przeszły walidacje BOSową) (dla wszystkich grup)
       */
      this._setMarksFroVariantGroup = function(marks, group, selectedVariants, prevSelectedVariants, renewedVariants) {
        var selected = false, //wybrany wariant z grupy
          renewedGroupVariants = []; //pozytywnie wznowione warianty z danej grupy
        //sprawdzamy czy zaznaczony wariant z grupy
        lsnNg.forEach(group, function(variant) {
          if (selectedVariants[variant]) {
            selected = true;
          }
          if (renewedVariants.indexOf(variant) !== -1) {
            renewedGroupVariants.push(variant);
          }
          return true;
        });

        if (!selected || renewedGroupVariants.length > 0) { //nie wybrano wariantu z grupy lub wybrano i pozytywnie wznowiono -> prezentujemy warianty z poprzedniej polisy
          var anyAdded = false;
          angular.forEach(group, function(variant) {
            if (prevSelectedVariants.indexOf(variant) !== -1) {
              anyAdded = true;
              marks.push(variant);
            }
          });
          if (!anyAdded && renewedGroupVariants.length > 0) { //obsluga przypadku, gdy na poprzedniej polisy nie bylo danej osoby, a na wznowieniu została dodana i ryzyko jest poprawnie zwalidowane - wówczas oznaczamy aktualnie wybrany wariant (poprzedniego brak dla danej osobie)
            angular.forEach(renewedGroupVariants, function(variant) {
              marks.push(variant);
            });
          }
        }
      };

      /**
       * zwraca listę pomyślnie wznowionych wariantów ryzyka osobowego dla podanej osoby
       * @param  {String} productCode kod produktu
       * @param  {String} clientId    clientId osoby
       * @return {String[]} tablica kodów wariantów
       */
      this.getRenewedPersonalRiskVariants = function(productCode, clientId) {
        if (angular.isUndefined(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_PERSON]) || angular.isUndefined(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_PERSON][clientId]) || angular.isUndefined(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_PERSON][clientId][productCode])) {
          return [];
        }
        var vnts = [];
        angular.forEach(mainDataContainer.renewedList[CONSTANTS.PRODUCT_TYPE_PERSON][clientId][productCode], function(count, variant) {
          if (count > 0) {
            vnts.push(variant);
          }
        });
        return vnts;
      };

      /**
       * Zwraca warianty ryzyka, które były zaznaczone dla danej osoby na wznawianej polisie
       * @param  {String} productCode kod produktu
       * @param  {String} clientId clientId osoby
       * @return {String[]} tablica wariantów, pusta gdy nie były wybrane.
       */
      this.getPreviousPersonalRiskMarks = function(productCode, clientId) {
        if (clientId === CONSTANTS.NNW_INCOGNITO_PERSON_ID && productCode === CONSTANTS.PRODUCT_NNW) { //przypadek nnw forma bezimienna
          return self.getPreviousNnwIncognitoRiskMarks();
        }
        var personIdData = self._getPersonIdData(mainDataContainer.persons[clientId]),
          personProds = resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_PERSON);
        if (personIdData === null) { //brak danych osoby - przerywamy przeszukiwanie
          return [];
        }
        var prevVariants = [];
        lsnNg.forEach(mainDataContainer.renewedApplication.risks, function(prevRisk) {
          var riskDef = MapperUtils.findRiskDefByIdpm(prevRisk.product.compId);
          if (personProds.indexOf(riskDef.productCode) === -1 || productCode !== riskDef.productCode) //szukamy tylko produktow/ryzyk osobowych
          {
            return true;
          }
          if (angular.isUndefined(prevRisk.insuredSubjectsRef) || prevRisk.insuredSubjectsRef.length === 0) {
            return true; //brak podmiotów na ryzyku - błąd
          }
          lsnNg.forEach(prevRisk.insuredSubjectsRef, function(subject) {
            var person = self._getPersonById(mainDataContainer.renewedApplication.persons, subject.ref);
            if (person === null) //nie znaleziono osoby, prawdopodobnie subjectem jest organizacja
            {
              return true;
            }
            if (angular.equals(self._getPersonIdData(person), personIdData)) { //znalezlismy osobe na ryzyku
              prevVariants.push(prevRisk.product.dynamicValues.coverageOption || CONSTANTS.VARIANT_I);
              return false;
            }
            return true; //continue
          });
          return true;
        });
        return prevVariants;
      };

      /**
       * zwraca poprzednio zaznaczone warianty ryzyka NNW forma bezimienna
       * @return {String[]} warianty
       */
      this.getPreviousNnwIncognitoRiskMarks = function() {
        var prevVariants = [];
        lsnNg.forEach(mainDataContainer.renewedApplication.risks, function(prevRisk) {
          var riskDef = MapperUtils.findRiskDefByIdpm(prevRisk.product.compId);
          if (riskDef.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
            prevVariants.push(CONSTANTS.VARIANT_I);
            return false;
          }
          return true;
        });
        return prevVariants;
      };

      /**
       * zwraca osobę o podenym id ze zbioru osób
       * @param  {Array} persons
       * @param  {string|int} id
       * @return {Object|Person}
       */
      this._getPersonById = function(persons, id) {
        var matchPerson = null;
        lsnNg.forEach(persons, function(person) {
          if (parseInt(person.metaData.id, 10) === parseInt(id, 10)) {
            matchPerson = person;
            return false;
          }
          return true; //continue
        });
        return matchPerson;
      };

      /**
       * zwraca dane identyfikujące osobę
       * @param  {Object} person osoba
       * @return {null|Object} null gdy nie znaleziono żadnych danych identyfikacyjnych. Obiekt:
       * {pesel: nr_pesel} - gdy nie obcokrajowiec
       * {citizenshipCode: kod_obywatelstwa, typ_dokumnetu: numer_dokumentu} - gdy obcokrajowiec
       */
      this._getPersonIdData = function(person) {
        var res = {};
        if (angular.isString(person.pesel) && person.pesel !== '') { //osoba z wypełnionym peselem
          return {
            pesel: person.pesel
          };
        } else if (angular.isObject(person.dynamicValues) && angular.isString(person.dynamicValues._oldPesel)) { //osoba z wyczyszczonymi danymi posiadająca poprzednio (na wznawianej polisie) pesel
          return {
            pesel: person.dynamicValues._oldPesel
          };
        } else if (person.documents.length && angular.isString(person.documents[0].number) && angular.isString(person.documents[0].code)) { //obcokrajowiec z wypełnionymi danymi
          res[person.documents[0].code] = person.documents[0].number;
          res.citizenshipCode = person.citizenshipCode || (new PersonModel()).get('citizenshipCode');
          return res;
        } else if (angular.isObject(person.dynamicValues) && angular.isString(person.dynamicValues._oldDocumentType) && angular.isString(person.dynamicValues._oldDocumentNumber) && angular.isString(person.dynamicValues._oldCitizenshipCode)) { //obcokrajowiec  usunietymi danymi, ale posiadającymi je na wznawianej polisie
          res[person.dynamicValues._oldDocumentType] = person.dynamicValues._oldDocumentNumber;
          res.citizenshipCode = person.dynamicValues._oldCitizenshipCode;
          return res;
        }
        return null;
      };

      /**
       * zwraca dane identyfikujące nieruchomość
       * @param  {Object} estate nieruchomosc
       * @return {null|String} null gdy nie znaleziono żadnych danych identyfikacyjnych, string identyfikacyjny (zlepek danych), gdy znaleziono.
       */
      this._getEstateIdData = function(estate) {
        var parts = []; //części adresu
        if (!angular.isObject(estate.address)) {
          return null;
        }
        //typ nieruchomości (dom, mieszkanie itp.)
        parts.push(estate.type);
        var addrParts = ['postalCode', 'city', 'street', 'house', 'apartment'];
        angular.forEach(addrParts, function(part) {
          self.__addPartIfString(parts, estate.address, part);
        });
        return parts.join('_');
      };

      /**
       * dodaje do tablicy parts kolejny element, jeśli istnieje i jest stringiem w obj
       * @param  {String[]} parts tablica części/stringów
       * @param  {Object} obj   obiekt, a którego odczytujemy
       * @param  {String} part  nazwa elementu/części do dodania
       */
      this.__addPartIfString = function(parts, obj, part) {
        if (angular.isString(obj[part])) {
          parts.push(obj[part].trim());
        }
      };

    };
    return new RenewalHelper();
  }
]);