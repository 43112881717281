angular.module('lsnBase.breadCrumbs')
  .controller('breadCrumbsCtrl', ['$state', '$scope', '$timeout', '$location', '$rootScope', 'breadCrumbsHelper', 'breadCrumbsConfig', '$window',
    function($state, $scope, $timeout, $location, $rootScope, breadCrumbsHelper, breadCrumbsConfig, $window) {
      $scope.breadCrumbs = [];

      $scope.customTitle = false;
      $scope.customData = {
        pageTitle: '',
        pageTitleLabel: null,
        stateNumber: ''
      };

      $scope.state = $state;
      $scope.activePageData = {
        pageTitle: '',
        pageTitleLabel: null,
        stateNumber: ''
      };

      $scope.statesData = {

      };

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = function() {

        $timeout(function(){
          $scope.fillBreadCrumbs();
          // zmiana okruszków przy zmianie stanu
          $rootScope.$on('$stateChangeSuccess', function(){
            $scope.clearTemporaryData();
            $scope.fillBreadCrumbs();
          });

          $scope.$watch('headerData.stateNumber', function(){
            $scope.fillBreadCrumbs();
          });
        }, 0);

        $rootScope.$on('setPageTitle', function(event, dataObject){
          $scope.setCustomData(dataObject);
        });

        $rootScope.$on('setStatesData', function(event, statesData){
          $scope.setStatesData(statesData);
        });
      };

      $scope.setStatesData = function(statesData)
      {
        $scope.statesData = angular.copy(statesData);
        $scope.fillBreadCrumbs();
      };

      $scope.clearTemporaryData = function(){
        $scope.statesData = {};
        $scope.customTitle = false;
      };

      $scope.setCustomData = function(dataObject){
        $scope.customTitle = true;
        $scope.customData.pageTitle = '';
        $scope.customData.pageTitleLabel = null;
        $scope.customData.stateNumber = '';
        if(dataObject.pageTitleLabel)
        {
          $scope.customData.pageTitleLabel = dataObject.pageTitleLabel;
        }
        if(dataObject.pageTitle)
        {
          $scope.customData.pageTitle = dataObject.pageTitle;
        }
        if(dataObject.stateNumber)
        {
          $scope.customData.stateNumber = dataObject.stateNumber;
        }
        $scope.activePageData = angular.copy($scope.customData);
      };

      /**
       * Zbieramy okruchy ze stanu
       * @return {[type]} [description]
       */
      $scope.fillBreadCrumbs = function() {
        $scope.breadCrumbs = [];
        $scope.fillBreadCrumbsState($state.$current, true);

        if(breadCrumbsHelper.getMainLinkVisibility())
        {
          $scope.breadCrumbs.push(breadCrumbsConfig.mainState);
        }

        // jeśli są tymczasowe dane to nadpisujemy
        angular.forEach($scope.breadCrumbs, function(crumb){
          if(typeof $scope.statesData[crumb.stateName] !== 'undefined')
          {
            angular.forEach($scope.statesData[crumb.stateName], function(value, key){
              if(key === 'pageTitle' || key === 'pageTitleLabel')
              {
                crumb.data[key] = value;
              }
              else
              {
                crumb[key] = value;
              }
            });
          }
        });

        // wycinamy początkowe stany których pageTitle === '', bo tych nie ma być
        var trimedBreadCrumbs = [];
        var anyBreadCrumbSet = false;
        angular.forEach($scope.breadCrumbs, function(breadCrumb){
          if(breadCrumb.data.pageTitle || breadCrumb.data.pageTitleLabel || anyBreadCrumbSet)
          {
            if(breadCrumb.data.pageTitle === '') // w razie gdy mamy pageTitle === '', a nie mamy ustawionego pageTitleLabel to ui.router nam wepchnie pageTitleLabel z rodzica, więc nullujemy
            {
              breadCrumb.data.pageTitleLabel = null;
            }
            trimedBreadCrumbs.push(breadCrumb);
            anyBreadCrumbSet = true;
          }
        });
        $scope.breadCrumbs = trimedBreadCrumbs;

        // ustawiamy tytuł strony
        if(!$scope.customTitle)
        {
          var firstCrumb = $scope.breadCrumbs[0];
          if(typeof firstCrumb !== 'undefined')
          {
            $scope.activePageData.pageTitle = firstCrumb.data.pageTitle;
            $scope.activePageData.pageTitleLabel = firstCrumb.data.pageTitleLabel;
            $scope.activePageData.stateNumber = firstCrumb.data.stateNumber;
          }
          else
          {
            $scope.activePageData.pageTitle = '';
            $scope.activePageData.pageTitleLabel = null;
            $scope.activePageData.stateNumber = '';
          }
        }

        $scope.breadCrumbs = $scope.breadCrumbs.reverse();
      };

      /**
       * Zbieramy okruchy z konkretnego stanu, funkcja rekurencyjna
       * @return {[type]} [description]
       */
      $scope.fillBreadCrumbsState = function(oneState, current) {
        if(angular.isObject(oneState) && angular.isObject(oneState.data)) {
          var stateData = {
            data: oneState.data,
            stateName: oneState.name,
            abstract: oneState.abstract
          };

          if(typeof oneState.data.breadCrumbUrl !== 'undefined')
          {
            stateData.url = oneState.data.breadCrumbUrl; // url nadpisany przez data
          }
          else
          {
            stateData.url = oneState.url.source;
          }

          if(current && $scope.headerData && $scope.headerData.stateNumber !== '')
          {
            if(angular.isUndefined(stateData.data))
            {
              stateData.data = {};
            }
            stateData.data.stateNumber = $scope.headerData.stateNumber;
          }

          $scope.breadCrumbs.push(stateData);
          if(!oneState.data.breadCrumbHideParents) // umożliwiamy wyłączenie rodziców (dla strony głównej)
          {
            $scope.fillBreadCrumbsState(breadCrumbsHelper.getParentState(oneState));
          }
        }
      };

      /**
       * Kliknięto okruszek
       * @param  {object} Crumb [description]
       * @return {[type]}       [description]
       */
      $scope.breadCrumbClicked = function(crumb) {
        if(crumb.data.breadCrumbDisabled !== true) {
          if(crumb.absolute) {
            $window.location.href = crumb.url;
          }
          else if(crumb.abstract) {
            if(crumb.data.breadCrumbClickState)
            {
              $state.go(crumb.data.breadCrumbClickState);
              return;
            }
            var statePath = crumb.stateName.split('.');
            if(statePath.length > 1) {
              // abstrakcyjny stan w środku - omijamy go
              // mamy zalozenie, ze nie będzie 2 abstrakcyjnych pod rząd
              var parentStateName = statePath.splice(0, statePath.length - 1).join('.');
              $state.go(parentStateName);
            } else {
              // idziemy na sam początek
              $location.url('/');
            }
          } else {
            // normalna zmiana stanu
            $state.go(crumb.stateName, crumb.stateParams);
          }
        }
      };

      $scope.init();
    }
  ]);