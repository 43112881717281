angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaProgramDefinitionCtrl', ['$scope', 'IhestiaPzaProgramDefinitionModel', '$state', 'definitionData', 'ihestiaPzaDictionaryHelper', 'ihestiaPzaProgramSvc', 'ihestiaPzachooseConditionsModalHelper', '$window', 'ihestiaPzaProgramConstants', 'previewMode', 'ihestiaPzaCommonHelper',
    function($scope, IhestiaPzaProgramDefinitionModel, $state, definitionData, ihestiaPzaDictionaryHelper, ihestiaPzaProgramSvc, ihestiaPzachooseConditionsModalHelper, $window, ihestiaPzaProgramConstants, previewMode, ihestiaPzaCommonHelper) {

      $scope.conditions = [];
      $scope.statuses = [];
      $scope.formSubmitted = false;
      $scope.errorList = [];
      $scope.ihestiaPzaProgramConstants = ihestiaPzaProgramConstants;
      $scope.pzaNumericSettings = ihestiaPzaCommonHelper.pzaNumericSettings;

      /**
       * Nazwy pól dla walidacji
       * @type {Object}
       */
      var fieldLabels = {
        'startDate':   'programDefinition.startDate',
        'endDate': 'programDefinition.endDate',
        'code': 'programDefinition.code',
        'description': 'programDefinition.description',
        'segment': 'programDefinition.segment',
        'budget': 'programDefinition.budget',
        'typeCode': 'programDefinition.typeCode',
        'conditionList': 'programDefinition.conditionList'
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.loadProgram();
        $scope.loadDicts();

        // bez tego nie puscimy save'a
        $scope.$watch('programData.conditionList', function() {
          if ($scope.programDefinitionForm) {
            $scope.programDefinitionForm.conditionList.$setValidity('service', true);
          }
        }, true);
      };

      /**
       * Ładujemy program
       * @return {undefined}
       */
      $scope.loadProgram = function() {
        var model = new IhestiaPzaProgramDefinitionModel();
        if (definitionData && definitionData.data) {
          model.setData(definitionData.data);
        }
        $scope.programData = model.getData();
        $scope.previewMode = previewMode || ($scope.programData.status && $scope.programData.status.code === ihestiaPzaProgramConstants.STATUS_CLOSED);
      };

      /**
       * Ładujemy słowniki
       * @return {undefined}
       */
      $scope.loadDicts = function() {
        // statusy
        ihestiaPzaDictionaryHelper.getStandardDictItems([
          'PROGRAM_STATUS',
          'SEGMENT'
        ]).then(function(dicts) {
          $scope.statuses = dicts.PROGRAM_STATUS;
          $scope.segments = dicts.SEGMENT;

          // dla nowych programów zawsze draft
          // https://ebok.atena.pl/browse/IHESTIASU-228
          if (!$scope.programData.id) {
            angular.forEach($scope.statuses, function(status) {
              if (status.code === ihestiaPzaProgramConstants.STATUS_DRAFT) {
                $scope.programData.status = status;
              }
            });
          }
          // ustawiamy prawidłowo select z segmentem
          if (angular.isObject($scope.programData.segment) && $scope.programData.segment !== null) {
            angular.forEach($scope.segments, function(segment) {
              if (segment.code === $scope.programData.segment.code) {
                $scope.programData.segment = segment;
              }
            });
          } else {
            // dla nowych bierzemy pierwszy z brzegu
            $scope.programData.segment = $scope.segments[0];
          }

          // ustawiamy prawidłowo select ze statusem
          if (angular.isObject($scope.programData.status)) {
            angular.forEach($scope.statuses, function(status) {
              if (status.code === $scope.programData.status.code) {
                $scope.programData.status = status;
              }
            });
          }
        }, angular.noop);
      };

      /**
       * Zapis programu
       * @return {undefined}
       */
      $scope.saveProgram = function() {
        $scope.formSubmitted = true;
        $scope.errorList = [];
        $scope.$emit('showBlockUi');
        var data = angular.copy($scope.programData);

        /** Przerzucamy dane przez model, żeby było spoko */
        data = (new IhestiaPzaProgramDefinitionModel()).setData(data).getData();

        // zwrotka z puta/posta => obsługa walidacji itp.
        var thenFunc = function(res) {
          if (res && (res.status === 200 || res.status === 204)) {
            $state.go('main.mainPage.programCatalog');
          } else {
            $scope.validationResponse = res.data;
            $scope.validationResponse[0].uuid = Date.now();
            angular.forEach($scope.validationResponse, function(validationErrror) {
              if (validationErrror.defaultMessage) {
                var message = {text: validationErrror.defaultMessage};
                if (fieldLabels[validationErrror.field]) {
                  message.label = [fieldLabels[validationErrror.field], {
                    componentCode: 'pza'
                  }];

                  message.text = ' - ' + message.text;
                }

                
                $scope.errorList.push(message);
              }
            });
            if ($scope.errorList.length === 0) {
              $scope.errorList.push({label: ['program.unknownServiceErrorOccurred', {
                componentCode: 'Dashboard'
              }]});
            }
            $scope.errorList = $scope.errorList.unique().sort();
          }
        };
        if (data.id) {
          // edycja
          ihestiaPzaProgramSvc.put(data.id, data, null, null, null, {
            allowedStatuses: [400]
          }).then(thenFunc, thenFunc).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        } else {
          // nowy
          ihestiaPzaProgramSvc.post(null, data, null, null, null, {
            allowedStatuses: [400]
          }).then(thenFunc, thenFunc).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        }
      };

      /**
       * Dodawanie warunków
       */
      $scope.addConditions = function() {
        ihestiaPzachooseConditionsModalHelper.showChooseConditionModal($scope.programData.conditionList, function(conditions) {
          $scope.programData.conditionList = [];
          angular.forEach(conditions, function(condition) {
            $scope.programData.conditionList.push({
              condition: condition
            });
          });
        });
      };

      /**
       * Usuwamy warunek z listy
       * @param  {int} index index warunku w tablicy
       * @return {undefined}
       */
      $scope.removeCondition = function(index) {
        $scope.programData.conditionList.splice(index, 1);
      };


      /**
       * Edycja warunku
       * @param  {int} definitionId id warunku
       * @return {undefined}
       */
      $scope.editCondition = function(definitionId) {
        $window.open('#/conditionCatalog/definition/' + definitionId);
      };

      /**
       * Podgląd warunku
       * @param  {int} definitionId id warunku
       * @return {undefined}
       */
      $scope.viewCondition = function(definitionId) {
        $window.open('#/conditionCatalog/definitionPreview/' + definitionId);
      };

      /**
       * Anuluj, wracamy do listy
       * @return {undefined}
       */
      $scope.cancel = function() {
        $state.go('main.mainPage.programCatalog');
      };

      $scope.init();
    }
  ]);