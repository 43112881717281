angular.module('ihestiaWidgets.policyDetails')
  .controller('conditionsChangeModalCtrl', ['conditionsChangeModalHelper', '$scope',
    function(conditionsChangeModalHelper, $scope) {
      $scope.tplData = conditionsChangeModalHelper.tplData;
      conditionsChangeModalHelper.initData();
      $scope.fileModels = $scope.tplData.fileModels;

      /**
       * Required for uploader
       * @param id
       */
      $scope.deleteFile = function(id) {
        conditionsChangeModalHelper.onDeleteFile(id);
      };

      $scope.checkFileUploadAllowed = function(event) {
        if (conditionsChangeModalHelper.tplData.uploaderOptions.disabled) {
          event.preventDefault();
        }
      };
    }]);
