angular.module('ihestiaWidgets.dashboard')
  .factory('newTaskIcrDataHelper', ['ihestiaDashboardTaskHelper', '$q',
    function(ihestiaDashboardTaskHelper, $q) {

      var NewTaskIcrDataModalHelper = function() {
        var self = this;

        this.getIcrDicts = function() {
          return $q(function(resolve, reject) {
            var dicts = {};

            $q.all([
              ihestiaDashboardTaskHelper.getDict('MedicalTestGroup')
                .then(function(dict) {
                  dicts.medicalTestGroup = angular.copy(dict);
                }, reject),
              ihestiaDashboardTaskHelper.getDict('MedicalTestSingle')
                .then(function(dict) {
                  dicts.medicalTestSingle = angular.copy(dict);
                }, reject)
            ]).then(function() {
              resolve(dicts);
            }, reject);
          });
        };

        this.getIcrMultiSelectCodesMap = function(selectedCodes) {
          return $q(function(resolve, reject){
            self.getIcrDicts().then(function(dicts){
              var map = {};
              var dictCodes = ['medicalTestGroup', 'medicalTestSingle'];
              angular.forEach(dictCodes, function(dictCode) {
                map[dictCode] = {};
                angular.forEach(selectedCodes, function(selectedCode){
                  angular.forEach(dicts[dictCode], function(item){
                    if(item.code === selectedCode) {
                      map[dictCode][item.code] = item;
                    }
                  });
                });
              });
              resolve(map);
            }, reject);
          });
        };

      };

      return new NewTaskIcrDataModalHelper();
    }]);
