/**
 * Dyrektywa do wrzucenia szablonu modala konfliktu tożsamości
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaSsoBase')
  .directive('ihestiaCharacterConflictedModal', ['$templateCache', 'iHestiaCommonModalHelper', 'ihestiaSsoBaseInfoHelper', '$window', 'bodyInjectorHelper', 'ihestiaCharacterConflictedHelper', 'ihestiaSsoCharactersCurrentSvc', '$timeout', 'ihestiaCrossTabHelper',
    function($templateCache, iHestiaCommonModalHelper, ihestiaSsoBaseInfoHelper, $window, bodyInjectorHelper, ihestiaCharacterConflictedHelper, ihestiaSsoCharactersCurrentSvc, $timeout, ihestiaCrossTabHelper) {
      var directive = {
        restrict: 'E',
        template: $templateCache.get('ihestia-sso-template-app/sso/characterConflicted/characterConflictedModal.tpl.html'),
        link: function($scope) {
          $scope.modalSettings = {
            title: 'Konflikt charakterów',
            okBtnName: 'Przywróć',
            cancelBtnName: 'Potwierdź nowy charakter'
          };

          $scope.serviceCharacter = ihestiaCharacterConflictedHelper.getConflictedCharacter();
          $scope.currentCharacter = ihestiaSsoBaseInfoHelper.getUser().character;

          $timeout(function() { //musimy poczekać aż on dla kodu modala załączy się na scope
            iHestiaCommonModalHelper.showModal('ihestiaCharacterConflicted');
          }, 0);

          $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaCharacterConflicted', function(){
            //potiwerdzamy nową tożsamość przeładowując stronę
            $window.location = '/';
          });

          $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaCharacterConflicted', function(){
            //jesli nie zresetujemy to okna przy kolejnej zmianie tożasamości się nie pojawi
            ihestiaCharacterConflictedHelper.charactersConflictedModalActive = false;
            var headers = {};
            // informujemy resztę zakładek, że znowu zmieniono charakter
            ihestiaCrossTabHelper.sendMessage({
              code: 'characterChanged',
              attrs: {
                characterCode: $scope.currentCharacter.code,
                changeByRevert: true
              }
            });
            // zmieniamy kod charakteru do autoryzacji przywrócenia charakteru
            // https://bok.atena.pl/browse/IHESTIASSO-582
            headers['AP-User-Character'] = $scope.serviceCharacter.code;
            ihestiaSsoCharactersCurrentSvc.changeCharacter($scope.currentCharacter, function(){
              $scope.$emit('characterConflictResolved');
              bodyInjectorHelper.removeDirective('charactersConflicted');
            }, {
              headers: headers
            });
          });
        }
      };

      return directive;
    }
  ]);