angular.module('lsnBase.models')
  .factory('LsnOfferModelV2', ['LsnAbstractModel', 'LsnApplicationModelConstantsV2', 'LsnPolicyModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, LsnApplicationModelConstantsV2, LsnPolicyModelConstants, lsnModelFactory) {

      var OfferModel = function() {
        this.objectName = 'Offer';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          bonusMalusData: {
            type: 'array',
            elementsType: 'BonusMalusDataObject',
            version: 'v2'
          },
          generalAgreementsNumbers: {
            type: 'array',
            elementsType: 'string'
          },
          calculationDate: {
            type: 'date'
          },
          operationType: {
            type: 'string',
            defaultValue: LsnApplicationModelConstantsV2.APPLICATION_OPERATION_TYPE_NEW,
            dictionary: LsnApplicationModelConstantsV2.DICTIONARY_APPLICATION_OPERATION_TYPE
          },
          policyPropositions: {
            type: 'array',
            elementsType: 'PolicyProposition',
            version: 'v2'
          },
          offerStatus: {
            type: 'string',
            dictionary: LsnApplicationModelConstantsV2.DICTIONARY_OFFER_STATUS,
            version: 'v2'
          },
          offerSubStatus: {
            type: 'string'
          },
          refusalReason: {
            type: 'string'
          },
          offerValidDate: {
            type: 'dateTime'
          },
          discounts: {
            type: 'array',
            elementsType: 'PolicyDiscount'
          },
          distributions: {
            type: 'array',
            elementsType: 'DistributionChannel',
            version: 'v2'
          },
          estates: {
            type: 'array',
            elementsType: 'Estate'
          },
          groups: {
            type: 'array',
            elementsType: 'Group'
          },
          holderRef: {
            type: 'string'
          },
          messages: {
            type: 'array',
            elementsType: 'Message'
          },
          number: {
            type: 'string'
          },
          organizations: {
            type: 'array',
            elementsType: 'Organization'
          },
          persons: {
            type: 'array',
            elementsType: 'Person'
          },
          pkdList: {
            type: 'array',
            elementsType: 'string'
          },
          risks: {
            type: 'array',
            elementsType: 'Risk',
            version: 'v2'
          },
          salesDate: {
            type: 'date'
          },
          segment: {
            type: 'string'
          },
          signDate: {
            type: 'date'
          },
          place: {
            type: 'string'
          },
          vehicles: {
            type: 'array',
            elementsType: 'Vehicle'
          },
          metaData: {
            type: 'MetaData'
          }
        };

        this.bonusMalusData = null; // lista danych Bonus Malus
        this.generalAgreementsNumbers = []; // numery umów generalnych
        this.calculationDate = null; // date, Data z jaką została wykonana taryfikacja
        this.operationType = null; // Tryb procedowania wniosku ubezpieczeniowego.
        this.policyPropositions = null; // lista PolicyProposition - Lista propozycji polis
        this.offerStatus = null; // string, status oferty
        this.offerSubStatus = null; // string, podstatus oferty
        this.refusalReason = null; // string
        this.offerValidDate = null; // data waznosci oferty wiazacej
        this.discounts = null; // zniżki dla wniosku
        this.distributions = null; // lista DistributionChannel
        this.estates = null; // lista Estate, Lista nieruchomości do ubezpieczenia
        this.groups = null; // lista Group, Lista grup
        this.holderRef = null; // string, Idenyfikator ubezpieczającego . MetaData.Id z listy persons lub organizations
        this.messages = null; // lista Message - Lista komunikatów biznesowych.
        this.number = null; // string, numer polisy
        this.organizations = null; // lista Organization, Lista osób prawnych. MetaData.ID musi być unikalne w obrębie person i organization
        this.persons = null; // lista Person, Lista osób. MetaData.Id musi być unikalne w obrębie person i organization
        this.pkdList = null; // array, lista kodów pkd
        this.risks = null; // lista Risk, Kolekcja ubezpieczeń
        this.salesDate = null; // date, Data wystawienia oferty
        this.segment = null; // Segment klienta
        this.signDate = null; // date, Data wystawienia polisy
        this.place = null; // string, Miejsce podpisania umowy
        this.vehicles = null; // lista Vehicle, Ubezpieczany pojazd. Maksymalnie jeden na polisie
        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});

      };

      OfferModel.prototype = LsnAbstractModel.prototype;

      return OfferModel;
    }
  ]);