/**
 * Mapper komunikatów z wniosku
 */
angular.module('salesPath.outer').service('fromRestMessageMapper', ['$rootScope',
 function ($rootScope)
{
  var Mapper = function ()
  {

    /**
     * iteracja po message, wywolanie eventu dla rejestracji bledu
     *
     * @param {MessageModel[]} komunikaty
     */
    this.mapMessages = function (messagesToMap, callback)
    {
      var i = 0,
        messages = [],
        message = {};

      if (angular.isUndefined(messagesToMap) || messagesToMap === null)
      {
        messagesToMap = [];
      }

      for (i = 0; i < messagesToMap.length; i += 1)
      {
        message = messagesToMap[i].getData();
        message.message = message.text;
        message.object = (message.object === null ? '' : message.object);
        message.attributes = {
          objects: message.object.replace(/\s/g, '').split(',')
        };
        message.text = null;
        messages.push(message);
      }

      if(angular.isFunction(callback)){
        callback(messages);
      }
    };
  };

  return new Mapper();
}]);