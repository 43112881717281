angular.module('salesPath2')
  .controller('addsCtrl', ['$scope', 'addsTplHelper', 'sp2SelectionHelper', '$timeout', 'addHelper', 'CONFIG',
    function($scope, addsTplHelper, selectionHelper, $timeout, addHelper, CONFIG) { // eslint-disable-line angular/di
      $scope.addsTplData = addsTplHelper.tplData;
      $scope.clickedAdd = null; //kod kliknietego dodatku
      /**
       * init
       */
      $scope.init = function() {
        $scope.addsTplData.activeProductList = $scope.activeProductList; //sczytane z ng-init
        addsTplHelper.refreshAddDefs();
        addsTplHelper.refreshPremiums();
      };

      /**
       * zaznacza/odznacza dodatek
       * @param  {Object} add obiekt dodatku
       * @param  {String} [variant] opcja, wariant dodatku
       * @param  {String|Number} subjectId id obiektu, dla ktorego zaznaczamy dodatek
       */
      $scope.toggleAddition = function(add, element) {
        var variant = element.VARIANT_CODE;
        var subjectId = element.SUBJECT_ID;

        if ($scope.appVariables.readOnly || !addHelper.addIsAvailable(add.CODE, subjectId) || element.UNCHANGEABLE || (element.VARIANT_CODE && !element.VARIANT_AVAIABLE)) {
          return;
        }
        selectionHelper.toggleAdd(add.CODE, subjectId, variant, true);
        //czy chować modal z dodatkiem po wybraniu
        if (CONFIG.BEHAVIOR.hideModalAfterAddToggled) {
          $scope.clickedAdd = null;
        }
        add.tplData.isSelected = selectionHelper.isAddSelected(add.CODE, subjectId, variant);
        addsTplHelper.refreshAddData();
      };

      /**
       * klikniecie w dodatek
       * @param  {String} code kod dodatku
       */
      $scope.clickAdd = function(code) {
        var newClickedAdd = (code === $scope.clickedAdd) ? null : code;
        $timeout(function() {
          $scope.clickedAdd = newClickedAdd;
        }, 0);
      };

      /**
       * klikniecie poza panel dodatku
       * @param  {String} code kod dodatku
       */
      $scope.clickOutsideAddPanel = function(code) {
        if ($scope.clickedAdd !== code) {
          return; //jesli klik poza dodatek inny niz aktualnie rozwiniety, to nic nie robimy
        }
        if ($scope.clickedAdd !== null) {
          $scope.clickedAdd = null;
        }
      };

      $scope.init();

    }
  ]);