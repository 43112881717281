/**
 * @deprecated zamiast tego należy używać policiesSvc.ver('v2')
 */
angular.module('ihestiaRestServicesBase')

  /**
   * wnioski ubezp.
   */
  .service('policiesV2Svc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var Policies = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'policies';
        this.businessArea = 'insurance';
        this.port = '8206';
        this.path = 'RestPolicies';
        this.apiVersion = 'v2';

        /**
         * zwraca listę dokumentów dostępnych dla wskazanej polisy wraz z informacją jaki z dokumentów został załączony
         *
         * @param {String} numer polisy
         * @param {Object} data
         * @param {function} callback
         * @returns {*}
         */
        this.getDocuments = function (number, callback) {
          return self.get(number, null, 'documents', callback);
        };

        /**
         * Usługa do pobrania informacji o przesłanych elektronicznie plikach w ramach danej polisy.
         *
         * @param {String} numer polisy
         * @param {function} callback
         * @returns {*}
         */
        this.getFiles = function (number, callback) {
          return self.get(number, null, 'files', callback);
        };

      };

      return new Policies();
    }]);