angular.module('ihestiaWidgets.policyDetails')
  .factory('ihestiaPolicyMessageHelper', [function() {
    var PolicyMessageHelper = function() {
      var self = this;
      this.message = null;
      this.messages = [];


      /**
       * Ustawienie wiadomości
       * @param {String} message
       *
       * @return {PolicyMessageHelper} self
       */
      this.setMessage = function(message) {
        self.message = message;
        return self;
      };

      /**
       * Pobranie wiadomości i jednoczesne usunięcie jej
       * @return {String}
       */
      this.getMessage = function() {
        var message = self.message;
        self.setMessage(null);

        return message;
      };

      /**
       * Ustawianie błędów i innych komunikatów do wyświetlenia na podglądzie polisy
       * @param {[type]} message [description]
       */
      this.setMessages = function(messages) {
        self.messages.splice(0, self.messages.length);
        angular.forEach(messages, function(message) {
          if (!message.messageText && _.isString(message.text)) {
            message.messageText = message.text;
          }
          if (!message.messageType && _.isString(message.type)) {
            message.messageType = message.type;
          }
          self.messages.push(message);
        });
        return self;
      };

      /**
       * Pobranie błędów
       * @return {array}
       */
      this.getMessages = function() {
        var messages = self.messages;
        return messages;
      };

      /**
       * [clearErrors description]
       * @return {[type]} [description]
       */
      this.clearMessages = function() {
        self.messages.splice(0, self.messages.length);
      };


    };

    return new PolicyMessageHelper();
  }]);
