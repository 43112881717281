angular.module('ihestiaWidgets.policyDetails')
  .service('conditionsChangeModalHelper', ['LsnModalHelper', 'policyDetailsContextHelper', 'fileServerSvc', '$filter', '$alert', 'ihestiaAutoUploadOneListUploadHelper', 'IhestiaLifeAnniversaryConstants', 'ihestiaRestDictionaryElectronicDocumentTypesSvc', '$q',
    function(LsnModalHelper, policyDetailsContextHelper, fileServerSvc, $filter, $alert, ihestiaAutoUploadOneListUploadHelper, IhestiaLifeAnniversaryConstants, ihestiaRestDictionaryElectronicDocumentTypesSvc, $q) {
      var ConditionsChangeModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        this.modalOptions = {
          size: 'md',
          title: ['anniversaryDetails.changeConditionsModalTitle', {
            componentCode: 'iKonto'
          }],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/conditionsChange/conditionsChangeModal.tpl.html',
          okBtnName: ['confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };
        this.setOptions(self.modalOptions);

        this.modalData = {
          premiumFrequency: null,
          policyNumber: null,
          riskTable: null,
          spAllowedChanges: null,
          messages: null,
          noDataReset: null,
          anniversaryOfferEndDate: null
        };

        /**
         * @typedef ConditionsChangeFormDataItem
         * @property {string} id
         * @property {{disabled: boolean, value: any}} toBeRenewed
         * @property {{disabled: boolean, value: any}} toBeTerminated
         * @property {{disabled: boolean, value: any}} indexation
         */

        /**
         *
         * @typedef ConditionChangeModalTplData
         * @property isAgent: boolean
         * @property isClient: boolean
         * @property riskTable: any
         * @property formData: {ConditionsChangeFormDataItem[][]}
         * @property uploaderOptions: any
         * @property showElements: {terminationCol: boolean, indexationCol: boolean, renewalCol: boolean, document: boolean},
         * @property anniversaryOfferEndDate: string
         * @property fileModels: any,
         * @property premiumFrequency: number,
         * @property dicts: {
         *  indexation: any,
         *  allowedIndexation: {code: string, value: string}[]
         *  confirmation: any,
         *  premiumFrequency: any,
         *  allowedPremiumFrequency: any
         * }
         * @property messages: any[]
         */
        /**
         *
         * @type {ConditionChangeModalTplData}
         */
        this.tplData = {
          isClient: null,
          isAgent: null,
          anniversaryOfferEndDate: null,
          riskTable: null,
          formData: null,
          uploaderOptions: null,
          fileModels: null,
          premiumFrequency: null,
          dicts: {
            confirmation: null,
            indexation: null,
            premiumFrequency: null,
            allowedPremiumFrequency: null
          },
          showElements: {
            renewalCol: false,
            indexationCol: false,
            document: false,
            indexationCounterInfo: false
          }
        };

        this.documentType = null;

        this.initData = function() {
          self.tplData.isClient = policyDetailsContextHelper.isClient();
          if (self.tplData.isClient) {
            self.modalOptions.okBtnName = ['accept', { componentCode: 'Public' }];
            this.setOptions(self.modalOptions);
          }
          self.tplData.isAgent = !self.tplData.isClient && !policyDetailsContextHelper.isCentral();
          self.tplData.riskTable = self.modalData.riskTable;
          self._initDicts();
          if (!self.modalData.noDataReset) {
            // reset form visibility flags, will be set by _initFormData
            self.tplData.showElements.indexationCounterInfo = false;
            self.tplData.showElements.renewalCol = false;
            self.tplData.showElements.indexationCol = false;
            self._initFormData();
            self._initUploader();
            self.tplData.premiumFrequency = self.modalData.premiumFrequency;
            self.tplData.uploaderOptions.disabled = false;
          }
          self._initShowElements();
          self.tplData.anniversaryOfferEndDate = self.modalData.anniversaryOfferEndDate;
          self.tplData.messages = self.modalData.messages;
        };

        this._initFormData = function() {
          self.tplData.formData = [];
          _.forEach(self.tplData.riskTable.riskRows, function(riskRow, rowIndex) {
            self.tplData.formData[rowIndex] = [];
            _.forEach(riskRow.risks, function(risk, riskIndex) {
              var toBeRenewedAttr = _.find(risk.allowedAttributes, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_TO_BE_RENEWED]),
                toBeTerminatedAttr = _.find(risk.allowedAttributes, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_TO_BE_TERMINATED]),
                toBeIndexedAttr = _.find(risk.allowedAttributes, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_TO_BE_INDEXED]),
                toBeIndexedInThisYearAttr = _.find(risk.allowedAttributes, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_TO_BE_INDEXED_IN_THIS_YEAR]);
              var riskData = {
                id: _.get(risk, 'objectId', risk.symbol), // risk id or symbol
                toBeRenewed: {
                  disabled: null,
                  value: undefined
                }, // renewal
                indexation: {
                  disabled: null,
                  value: undefined,
                  allowedValues: null
                }, // toBeIndexed, toBeIndexedInThisYear
                indexationCounter: risk.indexationCounter
              };
              /**
               * renewal / termination mapping
               */
              // IHESTLIFE-5061
              // toBeRenewed should be used for Client
              // IHESTLIFE-5620
              // only active when allowedValues has both values
              if (self._arrayHasTrueValue(_.get(toBeRenewedAttr, 'allowedValues')) && _.get(toBeRenewedAttr, 'allowedValues').length > 1 && self.tplData.isClient) {
                riskData.toBeRenewed.disabled = false;
                riskData.toBeRenewed.value = risk.toBeRenewed;
              } else if (self._arrayHasTrueValue(_.get(toBeTerminatedAttr, 'allowedValues')) && _.get(toBeTerminatedAttr, 'allowedValues').length > 1 && !self.tplData.isClient) {
                // toBeTerminated should be used for Agent / Central
                riskData.toBeRenewed.disabled = false;
                // value negation for toBeTerminated!
                riskData.toBeRenewed.value = !risk.toBeTerminated;
              } else {
                riskData.toBeRenewed.disabled = true;
                riskData.toBeRenewed.value = undefined;
              }
              /**
               * indexation mapping
               */
              var toBeIndexedAvail = self._arrayHasTrueValue(_.get(toBeIndexedAttr, 'allowedValues'));
              var toBeIndexedInThisYearAvail = self._arrayHasTrueValue(_.get(toBeIndexedInThisYearAttr, 'allowedValues'));
              riskData.indexation.disabled = !toBeIndexedAvail && !toBeIndexedInThisYearAvail;
              riskData.indexation.value = !riskData.indexation.disabled ? self.mapIndexationToFormData(risk.toBeIndexed, risk.toBeIndexedInThisYear) : undefined;
              riskData.indexation.allowedValues = self.filterAvailableIndexationDict(toBeIndexedAvail, toBeIndexedInThisYearAvail);

              /**
               * assign risk data to row
               */
              self.tplData.formData[rowIndex][riskIndex] = riskData;
              self._checkColumns(self.tplData.formData[rowIndex][riskIndex], self.tplData.isClient);
              // if indexation was resigned previously for any risk we show indexation counter info
              // IHESTLIFE-5310 - indexation info should be shown when indexationCounter equals 1
              self.tplData.showElements.indexationCounterInfo = self.tplData.showElements.indexationCounterInfo || (_.isNumber(risk.indexationCounter) && risk.indexationCounter === 1);
            });
          });
          // IHESTLIFE-5310 - indexation info should be shown when indexation is available
          if (!self.tplData.showElements.indexationCol) {
            self.tplData.showElements.indexationCounterInfo = false;
          }
        };

        /**
         * Set column visibility based on changes availability
         * Every risk row is checked, if none have enabled given attribute, column is hidden
         * @param {ConditionsChangeFormDataItem} formRisk
         * @param {boolean} isClient - for Agent/Central renewal col is always visible
         * @private
         */
        this._checkColumns = function(formRisk, isClient) {
          self.tplData.showElements.renewalCol = isClient ? self.tplData.showElements.renewalCol || !formRisk.toBeRenewed.disabled : true;
          self.tplData.showElements.indexationCol = self.tplData.showElements.indexationCol || !formRisk.indexation.disabled;
        };

        /**
         *
         * @param {string} uploaderName
         * @returns any
         */
        this.getDefaultUploaderOptions = function(uploaderName) {
          return {
            url: fileServerSvc._getServiceUrl(),
            autoUpload: false,
            singleFileUploads: true,
            // acceptFileTypes: /(\.|\/)(csv)$/i,
            maxChunkSize: 4000000,
            // hideProgressBar: true,
            multiple: false,
            messages: {
              acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
              maxFileSize: $filter('translate')('Public.toLargeFile')
            },
            showMessage: function(msg) {
              $alert({
                content: msg,
                type: 'warning'
              });
            },
            name: uploaderName,
            hideQueueList: true
          };
        };

        /**
         * Initializes uploader. If no uploader options are passed, creates default options.
         */
        this._initUploader = function() {
          if (!self.tplData.uploaderOptions) {
            self.tplData.uploaderOptions = self.getDefaultUploaderOptions('conditionChange');
          }
          ihestiaAutoUploadOneListUploadHelper.init(self.tplData.uploaderOptions.name, {
              onFileUploadAdd: self.onFileAdd,
              onAllFileSend: lsnNg.noop,
              onErrorAddingFile: lsnNg.noop
            },
            null, {
              autoUpload: true,
              firstCheckAllFiles: false
            });

          self.tplData.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles(self.tplData.uploaderOptions.name);
          var fileAttributesCall = self.documentType ? $q.when(self.documentType) : ihestiaRestDictionaryElectronicDocumentTypesSvc.parentGet(null, null, 'CustomerApplication');
          fileAttributesCall
            .then(function(res) {
              self.documentType = res.data ? res.data : res;
              ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType(self.tplData.uploaderOptions.name, self.documentType);
            });
        };

        this._initShowElements = function() {
          self.tplData.showElements.document = _.some(self.modalData.spAllowedChanges, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_DOCUMENT]);
        };

        this.confirm = function() {
          self.modalData.messages = null;
          var prevModalData = _.clone(self.modalData);
          prevModalData.noDataReset = true;
          var resolve = {
            request: self.mapParametersRequest(),
            prevModalData: prevModalData
          };
          self.setPromiseAsResolved(resolve);
          return self.hideModal();
        };

        /**
         * initialize dictionaries for selects
         * @private
         */
        this._initDicts = function() {
          if (!self.tplData.dicts.confirmation) {
            self.tplData.dicts.confirmation = [{
              value: true,
              label: $filter('translate')('conditionsChange.confirmRenewal', {componentCode: 'iKonto'})
            }, {
              value: false,
              label: $filter('translate')('conditionsChange.confirmTermination', {componentCode: 'iKonto'})
            }];
            self.tplData.dicts.indexation = [{
              label: $filter('translate')('Public.upYes', {componentCode: 'Public'}),
              value: IhestiaLifeAnniversaryConstants.INDEXATION_CONFIRM
            }, {
              label: $filter('translate')('conditionsChange.indexationNoNextYear', {componentCode: 'iKonto'}),
              value: IhestiaLifeAnniversaryConstants.INDEXATION_NO_NEXT_YEAR
            }, {
              label: $filter('translate')('conditionsChange.indexationNoEndOfInsurance', {componentCode: 'iKonto'}),
              value: IhestiaLifeAnniversaryConstants.INDEXATION_NO_END_INSURNACE
            }];
            self.tplData.dicts.premiumFrequency = [
              {
                value: 12,
                label: $filter('translate')('conditionsChange.pfMonthly', {componentCode: 'iKonto'})
              },
              {
                value: 4,
                label: $filter('translate')('conditionsChange.pfQuarterly', {componentCode: 'iKonto'})
              },
              {
                value: 2,
                label: $filter('translate')('conditionsChange.pfSemiAnnualy', {componentCode: 'iKonto'})
              },
              {
                value: 1,
                label: $filter('translate')('conditionsChange.pfAnnually', {componentCode: 'iKonto'})
              }
            ];
          }
          var allowedPremiumFrequency = _.get(_.find(self.modalData.spAllowedChanges, ['name', IhestiaLifeAnniversaryConstants.ATTRIBUTE_PREMIUM_FREQUENCY]), 'allowedValues', []);
          self.tplData.dicts.allowedPremiumFrequency = _.filter(self.tplData.dicts.premiumFrequency, function(pf) {
            return _.includes(allowedPremiumFrequency, '' + pf.value);
          });
        };

        /**
         * @typedef AnniversaryParameterRiskModel
         * @property {string} id
         * @property {boolean} toBeIndexed
         * @property {boolean} toBeIndexedInThisYear
         * @property {boolean} toBeRenewed
         * @property {boolean} toBeTerminated
         */

        /**
         * @typedef AnniversaryParametersRequestModel
         * @property {number} installmentsCount
         * @property {AnniversaryParameterRiskModel[]} risks
         * @property {string} attachedDocumentGuid
         */

        /**
         *
         * @return {any}
         */
        this.mapParametersRequest = function() {
          var files = _.filter(_.map(self.tplData.fileModels, 'fileServerId'), _.isString);
          var request = {
            installmentsCount: self.tplData.premiumFrequency
          };
          var risksReq = self.mapFormDataToRequest();
          if (risksReq) {
            request.risks = risksReq;
          }
          if (!_.isEmpty(files)) {
            request.attachedDocumentGuid = _.first(files);
          }
          return request;
        };

        /**
         * @return {AnniversaryParameterRiskModel[] | null}
         */
        this.mapFormDataToRequest = function() {
          var riskReq = _.chain(self.tplData.formData)
            .flatten()
            .map(function(formData) {
              var req = {};
              if (!formData.indexation.disabled) {
                // IHESTLIFE-4955 - conditions
                switch (formData.indexation.value) {
                  // YES
                  case IhestiaLifeAnniversaryConstants.INDEXATION_CONFIRM:
                    req.toBeIndexed = req.ToBeIndexedInThisYear = true;
                    break;
                  // NO - until the end of insurance
                  case IhestiaLifeAnniversaryConstants.INDEXATION_NO_END_INSURNACE:
                    req.toBeIndexed = req.ToBeIndexedInThisYear = false;
                    break;
                  // NO - no indexation this year
                  case IhestiaLifeAnniversaryConstants.INDEXATION_NO_NEXT_YEAR:
                    // check indexation twice in a row
                    req.toBeIndexed = formData.indexationCounter < 2;
                    req.toBeIndexedInThisYear = false;
                    break;
                  default:
                    break;
                }
              }
              if (!formData.toBeRenewed.disabled) {
                if (!self.tplData.isClient) {
                  // negation!!
                  req.toBeTerminated = !formData.toBeRenewed.value;
                } else {
                  req.toBeRenewed = formData.toBeRenewed.value;
                }
              }
              if (_.isEmpty(req)) {
                return null;
              } else {
                req.id = formData.id;
                return req;
              }
            })
            .filter(_.identity)
            .value();
          return _.isEmpty(riskReq) ? null : riskReq;
        };

        this.mapIndexationToFormData = function(toBeIndexed, toBeIndexedInThisYear) {
          if (toBeIndexed && toBeIndexedInThisYear) {
            return IhestiaLifeAnniversaryConstants.INDEXATION_CONFIRM;
          } else if (!toBeIndexed && !toBeIndexedInThisYear) {
            return IhestiaLifeAnniversaryConstants.INDEXATION_NO_END_INSURNACE;
          } else if (toBeIndexed && !toBeIndexedInThisYear) {
            return IhestiaLifeAnniversaryConstants.INDEXATION_NO_NEXT_YEAR;
          } else {
            return undefined;
          }
        };

        this._arrayHasTrueValue = function(array) {
          return _.includes(array, 'true') || _.includes(array, true);
        };

        /**
         *
         * @param {boolean} toBeIndexed
         * @param {boolean} isBeIndexedInThisYear
         * @return {{code: string, value: string}[]}
         */
        this.filterAvailableIndexationDict = function(toBeIndexed, isBeIndexedInThisYear) {
          return _.filter(self.tplData.dicts.indexation, function(indexationDictItem) {
            switch (indexationDictItem.value) {
              case IhestiaLifeAnniversaryConstants.INDEXATION_CONFIRM:
                return true;
              case IhestiaLifeAnniversaryConstants.INDEXATION_NO_END_INSURNACE:
                return toBeIndexed;
              case IhestiaLifeAnniversaryConstants.INDEXATION_NO_NEXT_YEAR:
                return isBeIndexedInThisYear;
              default:
                return false;
            }
          });
        };

        /**
         *
         * @param {string} id - file id to delete
         */
        this.onDeleteFile = function(id) {
          ihestiaAutoUploadOneListUploadHelper.deleteFile(self.tplData.uploaderOptions.name, id);
          self.tplData.uploaderOptions.disabled = false;
        };

        this.onFileAdd = function() {
          self.tplData.uploaderOptions.disabled = true;
        };
      };
      return new ConditionsChangeModalHelper();
    }]);
