angular.module('spaceChat.clientArchive')
  .controller('spaceChatClientArchiveCtrl', ['$scope', 'spaceChatHelper', 'resolvedArchive', '$timeout', 'ihestiaPlatformHelper',
    function($scope, spaceChatHelper, resolvedArchive, $timeout, ihestiaPlatformHelper) {

      $scope.isJupiter = ihestiaPlatformHelper.isJupiterOrDirect();

      $scope.chatActive = true;

      $scope.scrollBottomObj = {
        scrollOnInit: true
      };

      $scope.mainChat = null;

      $scope.loading = {
        archive: false
      };

      $scope.spinnerOptions = {
        archive: {
          label: '',
          size: 'sm'
        },
        boardPrevious: {
          label: '',
          size: 'sm'
        }
      };

      $scope.init = function()
      {
        if(resolvedArchive && resolvedArchive.data && resolvedArchive.data.elements)
        {
          $scope.myChatList = $scope.prepareChats(resolvedArchive.data.elements);
        }
      };

      $scope.prepareChats = function(chatList)
      {
        angular.forEach(chatList, function(chat){
          chat.consultantInitials = spaceChatHelper.getUserInitials(chat, {
              firstNameParam: 'consultantFirstName',
              lastNameParam: 'consultantLastName'
            });
        });
        return chatList;
      };

      $scope.chatSelected = function(chatFromList)
      {
        $scope.mainChat = null;
        spaceChatHelper.loadArchiveChatMessages(chatFromList.threadId).then(function(resp){
          if(resp)
          {
            $scope.mainChat = resp;
            $scope.mainChat.chatFromList = angular.copy(chatFromList);
            $scope.scrollToBottom();
          }
        });
      };

      $scope.scrollToBottom = function(){
        $timeout(function(){
          if(angular.isFunction($scope.scrollBottomObj.scrollToBottom))
          {
            $scope.scrollBottomObj.scrollToBottom();
          }
        });
      };

      $scope.imageLoaded = function()
      {
        if($scope.mainChat.initialImagesCount > $scope.mainChat.initialImagesLoaded)
        {
          //przeglądarka wczytała obrazek z inicjalnej paczki, więć scroll w dół
          $scope.scrollToBottom();
        }
        $scope.mainChat.initialImagesLoaded = $scope.mainChat.initialImagesLoaded + 1;
      };


      $scope.boardScrolled = function(data){
        if($scope.mainChat && !data.reallyReachedTop)
        {
          $scope.mainChat.scrolledToBottomOnce = true;
        }
        if($scope.mainChat && data.reallyReachedTop && $scope.mainChat.previous)
        {
          spaceChatHelper.loadPrevious($scope.mainChat);
        }
      };

      $scope.init();

    }
  ]);