angular.module('ihestiaIkomunikator.common')
  .service('ihestiaIkomunikatorUserTypeHelper', ['ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'ihestiaPlatformHelper',
    function(ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, ihestiaPlatformHelper) {
      var UserTypeHelper = function() {
        var self = this;

        /**
         * [init inicjujemy aktualnego użytkownika oraz jego typ]
         * @return {[type]} [description]
         */
        this.init = function() {
          //dane z https://bok.atena.pl/browse/IHESTIAIKO-998
          this.currentUser = ihestiaSsoBaseInfoHelper.getUser();
          this.currentUserType = self.currentUser.userType;
          this.currentCharacter = self.currentUser.getCurrentCharacter().type;

          this.userTypeCurrentLogged = 'Other';
          if (self.currentUser.userType === UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT) {
            this.userTypeCurrentLogged = 'Client';
          } else if (self.currentCharacter === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL ||
            self.currentCharacter === UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER) {
            this.userTypeCurrentLogged = 'Central';
          } else if (self.currentCharacter === UserCharacterTypeConstants.CHARACTER_TYPE_WORKER ||
            self.currentCharacter === UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE) {
            this.userTypeCurrentLogged = 'Agent';
          }
        };

        /**
         * [isClientUserLogged sprawdza czy aktualnie zalogowany uzytkownik to klient]
         * @return {Boolean} [description]
         */
        this.isClientUserLogged = function() {
          return self.userTypeCurrentLogged === 'Client';
        };

        /**
         * [isCentralUserLogged sprawdza czy aktualnie zalogowany uzytkownik to uzytkownik z centrali]
         * @return {Boolean} [description]
         */
        this.isCentralUserLogged = function() {
          return self.userTypeCurrentLogged === 'Central';
        };

        /**
         * [isAgentUserLogged sprawdza czy aktualnie zalogowany uzytkownik to agent]
         * @return {Boolean} [description]
         */
        this.isAgentUserLogged = function() {
          return self.userTypeCurrentLogged === 'Agent';
        };

        /**
         * [isOtherWithViewUserLogged sprawdza czy aktualnie zalogowany uzytkownik typu Menedżer sprzedaży, Przedstawicielstwo
         * @return {Boolean} [description]
         */
        this.isOtherWithViewUserLogged = function() {
          return self.userTypeCurrentLogged === 'OtherWithView';
        };

        /**
         * [isOtherUserLogged sprawdza czy aktualnie zalogowany uzytkownik to nie przypisany do żadnego typu Client/Agent/Central/OtherWithView]
         * @return {Boolean} [description]
         */
        this.isOtherUserLogged = function() {
          return self.userTypeCurrentLogged === 'Other';
        };

        /**
         * [isCentralUserLoggedForViewPanel jaki typ widoku jest prezentowany per typ uzytkownika - widok typu centrala]
         * @return {Boolean} [description]
         */
        this.isCentralUserLoggedForViewPanel = function() {
          //dane z https://bok.atena.pl/browse/IHESTIAIKO-378
          return [UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL, UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER, UserCharacterTypeConstants.CHARACTER_TYPE_BRANCH, UserCharacterTypeConstants.CHARACTER_TYPE_MANAGER].indexOf(self.currentCharacter) !== -1;
        };

        /**
         * [isCentralUserLoggedForViewPanel jaki typ widoku jest prezentowany per typ uzytkownika - widok typu agent]
         * @return {Boolean} [description]
         */
        this.isAgentUserLoggedForViewPanel = function() {
          //dane z https://bok.atena.pl/browse/IHESTIAIKO-378
          return [UserCharacterTypeConstants.CHARACTER_TYPE_WORKER, UserCharacterTypeConstants.CHARACTER_TYPE_SALES_PROFILE, UserCharacterTypeConstants.CHARACTER_TYPE_DIRECT_SALE].indexOf(self.currentCharacter) !== -1;
        };

        /**
         * [isUserWithViewLogged sprawdza czy aktualnie zalogowany uzytkownik to]
         * @return {Boolean} [description]
         */
        this.isUserWithoutActionLogged = function() {
          return self.userTypeCurrentLogged === 'Other' || self.userTypeCurrentLogged === 'OtherWithView';
        };

        this.isCompanySearchAvailable = function() {
          return !ihestiaPlatformHelper.isJupiter() && !ihestiaPlatformHelper.isLifeContext();
        };

        self.init();
      };

      return new UserTypeHelper();
    }
  ]);
