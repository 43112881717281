angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorDocumentTypes', ['ihestiaRestDictionaryAllSettlementsDocumentTypesSvc', 'ihestiaIkomunikatorCommonHelper', 'iKomunikatorSubjectDictionariesSvc', 'ihestiaIkomunikatorContextHelper', 'iKomunikatorConstants', 'iKomunikatorConstantsMapper', '$timeout', 'ihestiaCommonStateHelper', 'ihestiaIkomunikatorPermissionHelper',
    function(ihestiaRestDictionaryAllSettlementsDocumentTypesSvc, ihestiaIkomunikatorCommonHelper, iKomunikatorSubjectDictionariesSvc, ihestiaIkomunikatorContextHelper, iKomunikatorConstants, iKomunikatorConstantsMapper, $timeout, ihestiaCommonStateHelper, ihestiaIkomunikatorPermissionHelper) {
      var DocumentTypes = function() {
        var self = this;
        this.documentTypes = {};

        /**
         * [init pobiera lista wszystkich typow dokumentow do ikomunikatora]
         * @return {[type]} [description]
         */
        this.init = function() {
          if (ihestiaIkomunikatorPermissionHelper.shouldShowAction()) {
            $timeout(function() {
              if ($.isEmptyObject(self.documentTypes) && !ihestiaCommonStateHelper.stateParams.willRedirect) {
                self.setDocumentTypes();
              }
            }, 0);
          }
        };

        /**
         * [setDocumentTypes pobiera lista wszystkich typow dokumentow do ikomunikatora]
         * @param {[type]} callbackFunction [description]
         */
        this.setDocumentTypes = function(callbackFunction) {
          ihestiaRestDictionaryAllSettlementsDocumentTypesSvc.get(null, {
            context: 'Messenger'
          }, null, function(result) {
            if (angular.isArray(result.data) && result.data.length > 0) {
              self.documentTypes = {};
              angular.forEach(result.data, function(value) {
                self.documentTypes[value.code] = value;
              });

              if (angular.isFunction(callbackFunction)) {
                callbackFunction();
              }
            } else {
              ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła listy dostępnych typów dokumentów');
            }
          });
        };

        /**
         * [setDocumentTypeByCode ustawiania typy dokumentu na podstawie kodu ktory przyszedl]
         * @param {[type]} doc              [obiekt dokumentu do ktorego przypisujemy wybrany typ]
         * @param {[type]} documentTypeCode [kod typu dokumentu]
         */
        this.setDocumentTypeByCode = function(doc, documentTypeCode, attrs){
            var documentType = self.documentTypes[documentTypeCode];
            if (angular.isObject(documentType)) {

              doc.documentType = documentType;

              if(angular.isObject(attrs)){
                self.setAttrForDocumentType(doc, attrs.subjectId, attrs.subjectContextItemNumber);
              }else{
                self.setAttrForDocumentTypeFromContext(documentType);
              }
            } else if(documentTypeCode !== 'Reclamation') {
              self.setDocumentTypeByCode(doc, 'Reclamation', attrs);
            }
        };

        /**
         * [setAttrForDocumentTypeFromContext ustawia atrybuty typu dokumentu na podstawie kontekstu]
         * @param {[type]} documentType [description]
         */
        this.setAttrForDocumentTypeFromContext = function(documentType) {
          var context = ihestiaIkomunikatorContextHelper.getContext();
          if (context.policyNumber !== null) {
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'PolicyNumber') {
                attr.val = context.policyNumber;
              }
            });
          } else if (context.offerId !== null) {
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'OfferNumber') {
                attr.val = context.offerId;
              }
            });
          }else if(context.claimNumber !== null){
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'DamageNumber') {
                attr.val = context.claimNumber;
              }
            });
          }
        };

         /**
         * [setAttrForDocumentType ustawia atrybuty typu dokumentu na podstawie wybranego dotyczy]
         * @param {[type]} documentType  [id dotyczy]
         * @param {[type]} subjectId    [id tematu] - temat ze slownika
         * @param {object} subjectContextItemNumber - numer polisy (znajdujace sie w polu inne)
         */
        this.setAttrForDocumentType = function(documentType, subjectId, subjectContextItemNumber) {
          if (subjectId === iKomunikatorConstants.SUBJECT_ID_POLICY) {
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'PolicyNumber') {
                attr.val = subjectContextItemNumber;
              }
            });
          } else if (subjectId === iKomunikatorConstants.SUBJECT_ID_INSURANCE) {
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'OfferNumber') {
                attr.val = subjectContextItemNumber;
              }
            });
          } else if (subjectId === iKomunikatorConstants.SUBJECT_ID_INJURY) {
            angular.forEach(documentType.attributes, function(attr) {
              if (attr.code === 'DamageNumber') {
                attr.val = subjectContextItemNumber;
              }
            });
          }
        };

        /**
         * [getGeneralDocumentType głowny typ dokumentu, narazie zaniechany]
         * @return {[type]} [description]
         */
        this.getGeneralDocumentType = function() {
          return self.documentTypes.Reclamation;
        };

        self.init();
      };

      return new DocumentTypes();
    }
  ]);