angular.module('salesPath2')
  .service('leasingModalHelper', ['$timeout', 'mainDataContainer', 'sp2CommonHelper', 'leasingHelper', 'CONSTANTS', 'actionHelper', '$filter', 'organizationHelper', 'appVariables', 'allowedChangesHelper', 'dataContainerHelper',
    function($timeout, mainDataContainer, sp2CommonHelper, leasingHelper, CONSTANTS, actionHelper, $filter, organizationHelper, appVariables, allowedChangesHelper, dataContainerHelper) {
        var LeasingModalHelper = function() {
            var self = this;

            var DEFAULT_EDIT_LEASING_MODAL_DATA = {
              leaserId: null,
              objectType: null,
              objectId: null,
              dataCleared: true,
              countries: [],
              organizationData: null,
              organization: null
            };

            this.editLeasingModalData = angular.copy(DEFAULT_EDIT_LEASING_MODAL_DATA);

            this.modals = {
                chooseLeasingModal: {
                    settings: {
                      treatLabelAsI18nCode: true,
                      title: 'sp2.modal.chooseLeasingModal.title',
                      size: 'sm',
                      okBtnName: ''
                    }
                },
                editLeasingModal: {
                    settings: {
                        treatLabelAsI18nCode: true,
                        title: 'sp2.modal.editLeasingModal.title',
                        size: 'md',
                        okBtnName: 'sp2.modal.okBtnName'
                    }
                },
                leasingInfoModal: {
                    settings: {
                      treatLabelAsI18nCode: true,
                      title: 'sp2.modal.leasingInfoModal.title',
                      size: 'md',
                      okBtnName: 'sp2.modal.close',
                      cancelBtnName: 'sp2.modal.leasingInfoModal.cancelBtnName'
                    }
                }
            };

            /**
            * wyświetlenie modala z informacja o lisingu
            */
            this.showLeaserInfo = function(leaserId, objectType) {
                self.editLeasingModalData.objectType = objectType;
                self.editLeasingModalData.leaserId = leaserId;
                self.setObjectId(objectType);
                self.justShowLeaserInfo();
            };

            this.justShowLeaserInfo = function(){
              self.modals.leasingInfoModal.settings.okBtnName = 'sp2.modal.close';
              self.modals.leasingInfoModal.settings.cancelBtnName = 'sp2.modal.leasingInfoModal.cancelBtnName';
              if (appVariables.isSupplement || appVariables.readOnly) {
                //nie mozemy dolozyc leasingodawcy na dokupieniach
                if(self.editLeasingModalData.leaserId === null){
                  return;
                }
                var leaser = mainDataContainer.organizations[self.editLeasingModalData.leaserId];
                var allowedChanges = allowedChangesHelper.getAllowedChangesFor(leaser);
                if(allowedChanges.allDisabled){
                  self.modals.leasingInfoModal.settings.okBtnName = 'sp2.modal.close';
                  self.modals.leasingInfoModal.settings.cancelBtnName = '';
                }
              }
              sp2CommonHelper.showModal('leasingInfoModal');
            };

            /**
             * Czyścimy dane modala
             */
            this.clearEditLeasingModalData = function() {
              self.editLeasingModalData = angular.copy(DEFAULT_EDIT_LEASING_MODAL_DATA);
            };

            /**
             * Usunięcie przypisania leasingu
             */
            this.deleteLeasingAssignment = function() {
              sp2CommonHelper.hideModal('leasingInfoModal');
              sp2CommonHelper.showConfirmModal({
                treatLabelAsI18nCode: true,
                title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
                text: $filter('translate')('sureWantToDeleteLeaser', {componentCode: 'sp2'}),
                okBtnCallback: function() {
                    var object = dataContainerHelper.getObject(self.editLeasingModalData.objectType, self.editLeasingModalData.objectId);
                    organizationHelper.deleteOrganization(object.getAdditionalData('leaserId'));
                    object.setAdditionalData(null, 'leaserId');
                    actionHelper.runAction('leaserDeleted');
                },
                cancelBtnCallback: function() {
                  self.justShowLeaserInfo();
                }
              });
            };


            this.justShowEditLeasingModal = function(){
              
              self.modals.editLeasingModal.settings.okBtnName = 'sp2.modal.okBtnName';
              self.modals.editLeasingModal.settings.cancelBtnName = 'sp2.modal.cancel';
              if (appVariables.isSupplement || appVariables.readOnly) {
                //nie mozemy dolozyc leasingodawcy na dokupieniach
                if(self.editLeasingModalData.leaserId === null){
                  return;
                }
                var leaser = mainDataContainer.organizations[self.editLeasingModalData.leaserId];
                self.editLeasingModalData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(leaser);
                if(self.editLeasingModalData.allowedChanges.allDisabled){
                  self.modals.editLeasingModal.settings.okBtnName = 'sp2.modal.close';
                  self.modals.editLeasingModal.settings.cancelBtnName = '';
                }
              }
              sp2CommonHelper.showModal('editLeasingModal');
            };

            /**
             * Zamknięcie modala wyboru leasingu
             */
            this.hideChooseLeasingModal = function() {
              sp2CommonHelper.hideModal('chooseLeasingModal');
            };

            /**
            * wyświetlenie modala z możliwością edycji lisingu (lub wybór jeżeli więcej niż jedna organizacja)
            */
            this.hideLeaserInfoAndEdit = function(leaserId, objectType) {
              sp2CommonHelper.hideModal('leasingInfoModal');
              self.editExplicitLeaser(leaserId, objectType);
            };    

            /**
             * otwiera modala z formualrzem edycji konkretnego leasera
             * @param  {[type]} leaserId   [description]
             * @param  {[type]} objectType [description]
             * @param  {String} [objectId] opcjonalnie id obiektu do ktorego przypisano leasera
             */
            this.editExplicitLeaser = function(leaserId, objectType, objectId) {
              self.editLeasingModalData.objectType = objectType;
              self.editLeasingModalData.leaserId = leaserId;
              if (!angular.isString(objectId) && self.editLeasingModalData.objectId === null) {
                self.setObjectId(objectType);
              } else if(angular.isString(objectId)) {
                self.editLeasingModalData.objectId = objectId;
              }
              self.justShowEditLeasingModal();
            };

            /**
            * wyświetlenie modala z możliwością edycji lisingu (lub wybór jeżeli więcej niż jedna organizacja)
            */
            this.addNewLeasing = function() {
              self.justShowEditLeasingModal();
            }; 




            /**
            * wyświetlenie modala z możliwością edycji lisingu (lub wybór jeżeli więcej niż jedna organizacja)
            */
            this.editLeasing = function(objectType, objectId, leaserId) {
                self.editLeasingModalData.objectType = objectType;
                if (objectId === null) {
                  self.setObjectId(objectType);
                } else {
                  self.editLeasingModalData.objectId = objectId;
                }

                if (leaserId === null) {
                  self.editLeasingModalData.leaserId = self.getSelectedObject(objectType).getAdditionalData('leaserId');
                } else {
                  self.editLeasingModalData.leaserId = leaserId;
                }
                if(Object.countKeys(mainDataContainer.organizations) > 0) {
                    sp2CommonHelper.showModal('chooseLeasingModal');
                } else {
                    self.justShowEditLeasingModal();
                }
            };

            /**
             * zwraca wybrany obiekt lokalizacji lub pojazdu w zależności od kontekstu headera
             */
            this.getSelectedObject = function(objectType) {
              var selectedObjectId;
               var object = null;
              if (objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
                selectedObjectId = mainDataContainer.selectedVehicle;
                object = dataContainerHelper.getObject(objectType, selectedObjectId, 'clientId');
              } else if (objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                selectedObjectId = mainDataContainer.selectedLocalization;
                object = dataContainerHelper.getObject(objectType, selectedObjectId, 'clientId');
              }

              return object;
            };

            /**
            * klinięto 'zmień leasingodawcę'
            */
            this.changeLeasing = function() {
                self.editLeasingModalData.leaserId = null;

                sp2CommonHelper.hideModal('leasingInfoModal');
                
                if(Object.countKeys(mainDataContainer.organizations) > 0) {
                    sp2CommonHelper.showModal('chooseLeasingModal');
                } else {
                    self.justShowEditLeasingModal();
                }
            };

            /**
            * ustawia ID aktualnie wybranego obiektu
            */
            this.setObjectId = function(objectType) {
                if (objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
                  self.editLeasingModalData.objectId = mainDataContainer.selectedVehicle;
                } else if(objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION){
                  self.editLeasingModalData.objectId = mainDataContainer.selectedLocalization;
                }
            };

            /**
            * zapisuje powiązanie do leasera na obiekcie
            * @param  {int|string} leaserId
            */
            this.selectLeaser = function(leaserId, objectParams, tarifficate) {
                return leasingHelper.selectLeaser(leaserId, objectParams, tarifficate);
            };
        };

        return new LeasingModalHelper();
    }
  ]);