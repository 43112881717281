angular.module('salesPath2')
  .controller('attachmentCtrl', ['$rootScope', '$scope', 'attachmentHelper', 'fileServerSvc', 'TwoListUploadHelper', 'sp2UiHelper', 'policiesV2Svc', '$filter',
    function($rootScope, $scope, attachmentHelper, fileServerSvc, TwoListUploadHelper, sp2UiHelper, policiesV2Svc, $filter) {
      $scope.listeners = {};

      $scope.showElements = {
        listDocumentTypes: true
      };

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        //              acceptFileTypes: /(\.|\/)(jpg|jpeg|gif|png|tif|tiff|eml|msg|pdf|rtf|odt|ods|doc|docs|xls|xlsx)$/i,
        maxChunkSize: 52000,
        //              maxFileSize: 2000000,
        hideProgressBar: true,
        messages: {
          acceptFileTypes: 'Typ pliku nie jest dozwolony',
          maxFileSize: 'Plik jest za duży'
        },
        showMessage: function(msg) {
          sp2UiHelper.showAlert({
            content: msg,
            type: 'warning'
          });
        },
        name: 'solo'
      };

      $scope.init = function() {
        $scope.modalData = attachmentHelper.modalData;

        $scope.initUploader();
        $scope.fireListeners();
      };

      $scope.initUploader = function() {
        /**
         * Uzupełniamy listę zuploadowanych plików przypisanych do polisy
         */
        policiesV2Svc.getFiles($scope.modalData.policy.number).then(function(res) {
          // policiesSvc.getFiles(31964812).then(function(res) {

          var alreadySendedFiles = [];
          angular.forEach(res.data, function(file) {
            var newFile = {
              name: file.name,
              documentTypeName: file.documentName,
              size: file.length,
              errorText: file.qualityDescription,
              fileServerId: file.fileServerId, // sprawdzic czy wogole cos takiego dostajemy
              qualityCode: file.qualityCode ? file.qualityCode :'Ok'
            };

            alreadySendedFiles.push(newFile);
          });

          TwoListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadDone: $scope.onFileUploadDone,
            onErrorSendingFile: $scope.onErrorSendingFile,
            onFileUploadAdd: $scope.onFileUploadAdd,
            onErrorAddingFile: $scope.checkAndBlockUpload,
            onAllFileSend: $scope.onAllFileSend,
            onErrorAttributes: $scope.onErrorAttributes
          }, alreadySendedFiles, {verifyForDocumentsTypes: ['Policy', 'Annex']});

          $scope.uploadedFiles = TwoListUploadHelper.getAllUploadedfiles($scope.uploaderOptions.name);
          $scope.fileToSend = TwoListUploadHelper.getAllToSendFiles($scope.uploaderOptions.name);
        }, angular.noop);
      };

      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };

      $scope.onAllFileSend = function(data) {
        if (data.withoutError) {
          //sprawdzic czy to powinno sie zadziac tylko w przypadku braku bledow
          attachmentHelper.setDocumentTypeForSelectList();
          attachmentHelper.getDocumentTypesAttachToPolicyAndShowPopup(false);
          $scope.showElements.listDocumentTypes = true;
        } else {
          sp2UiHelper.hideBlockUi();
        }
      };

      $scope.onErrorAttributes = function(data) {
        sp2UiHelper.hideBlockUi();
        angular.forEach($scope.modalData.documentType.attributes, function(attr) {
          angular.forEach(data.messages, function(msg) {
            if (attr.code === msg.object && attr.invalidText !== msg.text) {
              attr.invalidText = msg.text;
              attr.invalid = true;
            }
          });
        });
      };

      $scope.selectedDocumentType = function(){
        if($scope.modalData.documentType.code){
          attachmentHelper.setAttributesForSelectedDocumentType();
          TwoListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.modalData.documentType);
        }
      };

      $scope.fireListeners = function() {
        //nastepuje wysyłanie plików
        $scope.listeners.eventOnBtnClicked = $scope.$on('iHestiaCommonModalOkBtnClicked.attachmentModal', function($event, obj) {
          //zobaczyc czy to dalej dobrze dziala
          if (obj.validForm) {
            if (TwoListUploadHelper.areFileToSend($scope.uploaderOptions.name)) {
              sp2UiHelper.showBlockUi('Wysyłanie');
              attachmentHelper.setDefaultAttributes();
              TwoListUploadHelper.startSendingFiles($scope.uploaderOptions.name);
            } else {
              sp2UiHelper.showAlert({
                content: $filter('translate')('noFileSelected', {componentCode: 'Public'}),
                type: 'warning'
              });
            }
          }
          $event.preventDefault();
        });

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.listeners.cancelBtnClicked = $scope.$on('iHestiaCommonModalCancelBtnClicked.uploadDocumentModal', function() {
          attachmentHelper.clearModalParams();
          TwoListUploadHelper.clearUploadData($scope.uploaderOptions.name);
          $scope.clearListeners(); // remove listener.
        });
      };

      $scope.clearListeners = function() {
        angular.forEach($scope.listeners, function(val) {
          val();
        });
        $scope.listeners = {};
      };

      $scope.init();
    }
  ]);