/**
 * helper dla breadCrumbs
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('lsnBase.breadCrumbs')
  .provider('breadCrumbsHelper', [function(){

    /**
     * Helper
     * tutaj wszystko, co może być w providerze
     */
    var self = this;

    /**
     * hierarchia niezależna od stanów
     * dziecko: rodzic
     */
    this.stateParent = {};

    /**
     * stany rodzeństwa które pokazują się obok siebie na zakładkach
     * biznesowo to jeden stan
     * @type {Array}
     */
    this.siblingStates = []; // w środku ląduję tablice stanów
                            // całość wygląda np. [['main.taskList.myTasks', 'main.taskList.cases'], [...]]

    this.mainState = 'main';
    this.mainLinkVisible = true;

    this.setCustomStateParents = function(parents)
    {
      self.stateParent = parents;
    };

    this.setSiblingStates = function(siblingStates)
    {
      self.siblingStates = siblingStates;
    };

    this.setMainLinkVisibility = function(visible)
    {
      self.mainLinkVisible = visible;
    };

    this.getMainLinkVisibility = function()
    {
      return self.mainLinkVisible;
    };

    this.setMainStateName = function(stateName)
    {
      self.mainState = stateName;
    };


    /**
     * sprawdzamy czy stany nie są rodzeństwem
     * @param  {string}  stateName
     * @param  {string}  otherStateName
     * @return {Boolean}
     */
    this.isSiblingState = function(stateName, otherStateName)
    {
      var isSibling = false;
      angular.forEach(self.siblingStates, function(siblingsGroup){
        if(siblingsGroup.indexOf(stateName) !== -1 && siblingsGroup.indexOf(otherStateName) !== -1)
        {
          isSibling = true;
        }
      });
      return isSibling;
    };

    /**
     * Zwraca serwis, więc zawiera to, czego nie mogło być w providerze
     */
    this.$get = ['$state', function($state){

        /**
         * sprawdzamy czy parent jest rodzicem (dowolne zagłębienie) child
         * @param  {string}  parentName
         * @param  {string}  childName
         * @return {Boolean}
         */
        self.isParentState = function(parentName, childName)
        {
          var isParent = false;
          var childParent = self.getParentState($state.get(childName));
          var i = 100;
          while (childParent && i > 0) {
            if(childParent.name === parentName)
            {
              isParent = true;
              break;
            }
            i = i - 1;// na wszelki wypadek
            childParent = self.getParentState(childParent);
          }

          return isParent;
        };

        /**
         * zwracamy rodzica uwzględniając nasze własne drzewo zależności
         * @param  {[type]} state [description]
         * @return {[type]}       [description]
         */
        self.getParentState = function(state)
        {
          // zbieramy rodziców
          if(typeof self.stateParent[state.name] !== 'undefined') // rodzic zdefiniowany poza ui.routerem
          {
            return $state.get(self.stateParent[state.name]);
          }
          else if(angular.isObject(state.parent)) {
            return state.parent;
          }
          else if(typeof state.parent === 'undefined' && state.name.indexOf('.') !== -1)
          // nazwa sugeruje, że jest rodzic, ale mamy tylko podstawowe dane stanu, więc sami szukamy rodzica
          {
            var compositeName = /^(.+)\.[^.]+$/.exec(state.name); // reguła z stateBuilder'a ui.routera
            return $state.get(compositeName[1]);
          }

          return null;
        };

        return self;
    }];
  }]);