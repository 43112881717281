angular.module('lsnBase.models')
  .factory('LsnOfferModelConstants', [function() {
    var OfferModelConstants = {};
    
    // warianty wznowień i rozszerzeń
    OfferModelConstants.RENEWAL_CENTRAL_STATUTORY = 'RenewalCentralStatutory'; // Wznowienie odnowienie centralne – ustawowe
    OfferModelConstants.RENEWAL_CENTRAL_LAST_YEAR = 'RenewalCentralLastYear'; // Wznowienie odnowienie centralne – zakres ubiegłoroczny.
    OfferModelConstants.RENEWAL_CENTRAL_EXPANDED = 'RenewalCentralExpanded'; // Wznowienie odnowienie centralne – zakres rozszerzony.
    OfferModelConstants.RENEWAL_AGENCY = 'RenewalAgency'; // Wznowienie agencyjne(ustawiane podczas wznawiania)/kontynuacja(ustawiane podczas podpinania kontynuacji)
    OfferModelConstants.EXTEND_INSURANCE = 'ExtendInsurance'; // oferta razszerzenia ochrony
    OfferModelConstants.USUALY = 'Usualy'; // oferta nowej polisy

    // słownik typów wznowień/rozszerzeń
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT = {};
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.RENEWAL_CENTRAL_STATUTORY] = 1;
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.RENEWAL_CENTRAL_LAST_YEAR] = 2;
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.RENEWAL_CENTRAL_EXPANDED] = 3;
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.RENEWAL_AGENCY] = 4;
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.EXTEND_INSURANCE] = 5;
    OfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT[OfferModelConstants.USUALY] = 6;
    
    return OfferModelConstants;
  }]);