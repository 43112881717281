angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifePersonEditModalHelper', ['LsnModalHelper', 'ihestiaLabelHelper', 'clausesPoliciesSvc', 'IhestiaClauseListHelper', 'ihestiaFromRestClauseMapper', 'policyDetailsContextHelper', 'policyOperationsV2Svc', 'lifePolicyOperationsHelper', 'IhestiaNotesAndUploader', 'blockUiHelper', 'ihestiaAgHelper', '$timeout', '$q', 'ihestiaCustomerDataHelper',
    function(LsnModalHelper, ihestiaLabelHelper, clausesPoliciesSvc, IhestiaClauseListHelper, ihestiaFromRestClauseMapper, policyDetailsContextHelper, policyOperationsV2Svc, lifePolicyOperationsHelper, IhestiaNotesAndUploader, blockUiHelper, ihestiaAgHelper, $timeout, $q, ihestiaCustomerDataHelper) {
      var IhestiaLifePersonEditModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;
        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'md',
          title: ['policyDetails.personEdit.modalTitle', {componentCode: 'Public'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/personEditModal/personEditModal.tpl.html',
          okBtnName: ['save', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };
        this.setOptions(self.modalOptions);

        this.modalData = {
          subject: null,
          policyNumber: null,
          policyStartDate: null,
          // used as form data cache when alternation between modals
          formData: null,
          notes: null,
          fileIds: null,
          fileModels: null,
          clauses: null
        };

        this.tplData = {
          subject: null,
          clauseListHelper: null,
          formApi: null,
          errors: null,
          clausesLoaded: null,
          notesAndUploaderHelper: null,
          showNotesAndUploader: null
        };

        /**
         * Show element map for person edit form
         * @type {{
         *   showElements: PersonEditFormShowElements,
         *   disabledElements: any
         * }}
         */
        this.personEditForm = {
          showElements: {
            maidenName: false,
            nipAndRegon: false,
            drivingLicenceIssueDate: false,
            errors: false,
            documentValidDate: true
          },
          disabledElements: {
            document: false,
            pesel: true,
            birthDate: true,
            gender: true,
            citizenshipCode: true
          }
        };

        this.init = function() {
          // map modal params
          self.tplData.subject = _.cloneDeep(self.modalData.formData || self.modalData.subject);
          self.personEditForm.showElements.errors = false;
          // reinitialize template variables
          self.tplData.formApi = {};
          self.tplData.clauseListHelper = null;
          self.tplData.errors = [];
          self.tplData.warnings = [];
          // init citizenship code
          self.tplData.subject.citizenshipCode = self.tplData.subject.citizenshipCode || 'PL';
          self.personEditForm.disabledElements.document = self.tplData.subject.citizenshipCode !== 'PL';
          self.loadClauses();
          // init notes and uploader helper
          self.tplData.notesAndUploaderHelper = new IhestiaNotesAndUploader({
            policyNumber: self.modalData.policyNumber,
            fileModels: self.modalData.fileModels,
            notes: self.modalData.notes
          });
          self.tplData.showNotesAndUploader = policyDetailsContextHelper.isCentral();
          // form is disabled for Client or if user has no permission for policy partner change
          self.personEditForm.disabledElements.form = policyDetailsContextHelper.isClient()
            || !ihestiaAgHelper.hasPermissionFor('Public', 'PolicyPartnerChange');
          // set modal options
          self.setOptions(_.merge({}, self.modalOptions, {
            okBtnDisabled: self.personEditForm.disabledElements.form
          }));
        };

        this.confirm = function() {
          self.tplData.subject = self.tplData.formApi.getSubjectData();
          var notesAndFiles = self.getNotesAndFilesForContext();
          if (self.tplData.formApi.formRef.$invalid) {
            self.personEditForm.showElements.errors = true;
            self.tplData.formApi.validateAutoCompleters();
            return null;
          } else {
            self.tplData.formApi.validateAutoCompleters();
            self.tplData.errors = [];
            self.tplData.warnings = [];
          }
          var showDocumentsConfirmation = ihestiaCustomerDataHelper.documentDataChanged(_.first(self.tplData.subject.documents), _.first(self.modalData.subject.documents))
            && !(policyDetailsContextHelper.isCentral() || policyDetailsContextHelper.isClient());
          if (showDocumentsConfirmation) {
            // open documents confirmation
            var data = _.assign({}, self.modalData, {
              formData: self.tplData.subject,
              notes: notesAndFiles.notes,
              fileIds: notesAndFiles.fileIds,
              fileModels: self.tplData.notesAndUploaderHelper.getFileModels(),
              clauses: self._mapClausesToSelectionData(self.getClauses())
            });
            self.setPromiseAsResolved({type: 'documentsConfirmation', data: data});
            return self.hideModal();
          }
          blockUiHelper.showBlockUi();
          self.saveInsured(self.tplData.subject, notesAndFiles.notes, notesAndFiles.fileIds, self.modalData.policyNumber, self.modalData.policyStartDate, self.getClauses())
            .then(function() {
              self.setPromiseAsResolved();
              blockUiHelper.hideBlockUi();
              return self.hideModal();
            }, function(rej) {
              blockUiHelper.hideBlockUi();
              _.forEach(_.get(rej, 'data.messages'), function(message) {
                if (message.type === 'WARN') {
                  self.tplData.warnings.push(message);
                } else if (message.type !== 'INFO') {
                  self.tplData.errors.push(message);
                }
                self.goToFirstError();
              });
            });
          return null;
        };

        this.loadClauses = function() {
          self.tplData.clausesLoaded = false;
          self.getPolicyClauses(self.modalData.policyNumber, 'Insured', !policyDetailsContextHelper.isCentral())
            .then(function(clauseHelper) {
              clauseHelper.settings.showElements = _.merge(clauseHelper.settings.showElements, {selectAll: false});
              // showing only 'Personal' type clauses - also on save only Personal type clauses are passed to service
              clauseHelper.settings.type = 'Personal';
              self.tplData.clauseListHelper = clauseHelper;
              if (self.modalData.clauses) {
                self.tplData.clauseListHelper.setSelection(self.modalData.clauses);
              }
            }, lsnNg.noop)
            .finally(function() {
              self.tplData.clausesLoaded = true;
            });
        };

        /**
         * Returns clauses for given policy and optional partner role
         * @param {string} policyNumber
         * @param {string} partnerRole
         * @param {boolean} disableSelectedClauses
         * @returns {Promise<IhestiaClauseListHelper>}
         */
        this.getPolicyClauses = function(policyNumber, partnerRole, disableSelectedClauses) {
          return clausesPoliciesSvc.get(policyNumber, null, partnerRole ? '{0}/clauses'.format(partnerRole) : 'clauses')
            .then(function(res) {
              var language = ihestiaLabelHelper.selectedLanguage ? ihestiaLabelHelper.selectedLanguage : 'pl-PL';
              var clauseList = _.chain(_.get(res.data, 'partnerClauses[0].clauses', []))
                .map(ihestiaFromRestClauseMapper.mapPartnerClause(language, false))
                .map(ihestiaFromRestClauseMapper.disableSelectedClauses(disableSelectedClauses))
                .value();
              return new IhestiaClauseListHelper({clauseList: clauseList});
            }, function(err) {
              throw err;
            });
        };

        /**
         * Creates request model from form data and posts it to service
         * @param {object} subject
         * @param {string} notes
         * @param {string[]} files
         * @param {string} policyNumber
         * @param {string} policyStartDate
         * @param {[]} clauses
         * @private
         */
        this.saveInsured = function(subject, notes, files, policyNumber, policyStartDate, clauses) {
          var editInsuredRequest = lifePolicyOperationsHelper.mapEditInsuredRequest(subject, clauses, notes, files, policyNumber, policyStartDate);
          return policyOperationsV2Svc.post(policyNumber, editInsuredRequest, 'insured');
        };

        this.goToFirstError = function() {
          $timeout(function() {
            var errorBlockElement = _.first(angular.element('.alert-danger'));
            if (errorBlockElement && _.isFunction(errorBlockElement.scrollIntoView)) {
              errorBlockElement.scrollIntoView({behavior: 'smooth'});
            }
          }, 0);
        };

        /**
         *
         * @param {object} data
         * @returns {Promise}
         */
        this.handleDocumentChange = function(data) {
          return ihestiaCustomerDataHelper.documentDataChangeConfirmation()
            .then(function() {
              blockUiHelper.showBlockUi();
              return self.saveInsured(data.formData, data.notes, data.fileIds, data.policyNumber, data.policyStartDate, self.getClauses());
            }, function() {
              return self.chooseDestination('form', data);
            });
        };

        this.showFormModal = function(modalData) {
          return self.showModal(modalData)
            .then(function(res) {
              return self.chooseDestination(_.get(res, 'type'), _.get(res, 'data'));
            });
        };

        this.chooseDestination = function(type, data) {
          switch (type) {
            case 'form':
              return self.showFormModal(data);
            case 'documentsConfirmation':
              return self.handleDocumentChange(data);
            default:
              return $q.when(null);
          }
        };

        // add notes/files only for Central
        this.getNotesAndFilesForContext = function() {
          var isCentral = policyDetailsContextHelper.isCentral();
          return {
            notes: isCentral ? self.tplData.notesAndUploaderHelper.getNotes() : null,
            fileIds: isCentral ? self.tplData.notesAndUploaderHelper.getFileServerIds() : null
          };
        };

        /**
         * IHESTLIFE-4211 - exception from rule - we pass to service clauses that we show (type 'Personal')
         * @returns {[]}
         */
        this.getClauses = function() {
          return _.filter(self.tplData.clauseListHelper.getClauses(), function(clause) {
            return clause.type === 'Personal';
          });
        };

        this._mapClausesToSelectionData = function(clauses) {
          return _.reduce(clauses, function(acc, item) {
            acc[item.code] = item.value;
            return acc;
          }, {});
        };

      };
      return new IhestiaLifePersonEditModalHelper();
    }]);
