/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuClipboard')
  .controller('ihestiaMenuClipboardCtrl', ['$scope', '$rootScope', 'IHestiaMenuClipboardListSrv', 'ihestiaConfigHelper', '$window', '$state', '$stateParams', 'IhestiaMenuClipboardHelper', '$q', '$timeout',
    function($scope, $rootScope, IHestiaMenuClipboardListSrv, ihestiaConfigHelper, $window, $state, $stateParams, IhestiaMenuClipboardHelper, $q, $timeout) {
      $scope.limitListClipboard = 11;
      $scope.offsetListClipboard = 0;
      $scope.loadingListClipboard = false;
      $scope.deleteInProgress = false;
      $scope.elementInClipboardList = 11;
      $scope.clipboardElements = {
        documents: [],
        articles: []
      };

      $scope.CI_URL = ihestiaConfigHelper.getUrl('CI_URL');

      $scope.spinnerOptions = {
        size: 'sm'
      };

      $scope.promiseToCancel = $q.defer();

      /**
       * Event wywoływany na scroll
       * @return {[type]} [description]
       */
      $scope.$on('iHestiaLoadNextElementsFromClipboard', function(event, data) {
        if (!$scope.loadingListClipboard && data.reachedTop && $scope.elementInClipboardList > $scope.offsetListClipboard) {
          if ($scope.loadingListClipboard) {
            $scope.promiseToCancel.reject('newRequest');
            $scope.promiseToCancel = $q.defer();
          }

          var httpParams = {
            timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
          };

          $scope.loadingListClipboard = true;

          IHestiaMenuClipboardListSrv.get(
            '', 
            {
              // limit: $scope.limitListClipboard,
              // offset: $scope.offsetListClipboard
            }, 
            '', 
            null,
            null,
            httpParams
          ).then(function(response) {

            angular.forEach(response.data.clipboard, function(item) {
              $scope.clipboardList.push(item);
            });

            angular.forEach(response.data.clipboard, function(item) {
              if(item.is_document) {
                $scope.clipboardElements.documents.push(item);
              } else {
                $scope.clipboardElements.articles.push(item);
              }
            });

            $scope.emptyListClipbWoard = $scope.clipboardList.length === 0;
            $scope.elementInClipboardList = response.data.max_items;
            $scope.offsetListClipboard += $scope.limitListClipboard;

            $scope.loadingListClipboard = false;
          }, function() {
            $scope.loadingListClipboard = false;
          });
        }
      });

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = function() {
        $scope.clipboardListVisible = false;
      };

      /**
       * Pokazuje lub ukrywa schowek       
       */
      $scope.toggleClipboard = function() {
        $scope.clipboardListVisible = !$scope.clipboardListVisible;        

        // jeśli schowek ma zostać pokazany to ładujemy jego zawartość
        if($scope.clipboardListVisible) {
          $scope.renderClipboardList();
        } else {
          //jeśli schowek ma zostać ukryty to ubijamy wszystkie nieukończone requesty
          $scope.promiseToCancel.reject('newRequest');
          $scope.promiseToCancel = $q.defer();
        }
      };      

      /**
       * Pokazuje schowek
       * @return {[type]} [description]
       */
      $scope.renderClipboardList = function() {
        if ($scope.loadingListClipboard) {
          $scope.promiseToCancel.reject('newRequest');
          $scope.promiseToCancel = $q.defer();
        }

        var httpParams = {
          timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
        };

        $scope.offsetListClipboard = 0;
        $scope.loadingListClipboard = true;
        $scope.clipboardElements = {
          documents: [],
          articles: []
        };

        IHestiaMenuClipboardListSrv.get(
          '', 
          {
            // limit: $scope.limitListClipboard,
            // offset: $scope.offsetListClipboard
          }, 
          '', 
          null, 
          null, 
          httpParams)
        .then(function(response) {

            $scope.clipboardList = response.data.clipboard;

            angular.forEach(response.data.clipboard, function(item) {
              if(item.is_document) {
                $scope.clipboardElements.documents.push(item);
              } else {
                $scope.clipboardElements.articles.push(item);
              }
            });

            $scope.elementInClipboardList = response.data.max_items;
            $scope.emptyListClipboard = $scope.clipboardList.length === 0;
            $scope.offsetListClipboard = 11;
            $scope.loadingListClipboard = false;
          },
          function() {
            $scope.loadingListClipboard = false;
          });
      };

      $scope.deleteItemFromClipboard = function(deleteUrl) {
        $scope.deleteInProgress = true;
        var httpParams = {
          url: deleteUrl
        };

        IHestiaMenuClipboardListSrv.remove(null, null, function(){
          $scope.deleteInProgress = false;
          $scope.reloadArticleStatistics();
          $scope.renderClipboardList();
        }, function(){
          $scope.deleteInProgress = false;
        }, httpParams);
                
      };

      /**
       * Chowa schowek
       * @return {[type]} [description]
       */
      $scope.hideClipboard = function() {
        $scope.clipboardListVisible = false;
        $timeout(function(){});
      };

      $scope.openOrGoTo = function(item) {
        if (item.new_tab) {
          $window.open(item.url);
        } else {
          $window.location = item.url;
        }
      };

      //jeśli aktualny state to help lub news to przeładowujemy statystyki dla artykułu/pomocy
      $scope.reloadArticleStatistics = function() {        
        if($state.$current.name === 'news.article_view' || $state.$current.name === 'help.article_view') {
          IhestiaMenuClipboardHelper.setCIArticleStatistics($stateParams.id);          
        }        
      };

      $scope.set = function (object) {
        $rootScope.statistics = {};
        angular.forEach(['edit', 'views', 'likes', 'star', 'like', 'dislike'], function (item) {
            $rootScope.statistics[item] = angular.copy(object[item]);
        });
        $rootScope.statistics.comments = [];
        for (var i = 0; i < object.comments.length; i++) {
            $rootScope.statistics.comments.push(object.comments[i]);
        }
        $rootScope.statistics.files = angular.copy(object.files);
      };

    }
  ]);