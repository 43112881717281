angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('IHestiaRestServiceProviderAbstractDef', ['ihestiaConfigHelper', 'ihestiaCommonErrorHandler', 'ihestiaSsoBaseInfoHelper', 'ihestiaRestCommonHelper',
    function(ihestiaConfigHelper, ihestiaCommonErrorHandler, ihestiaSsoBaseInfoHelper, ihestiaRestCommonHelper) {
    var RestServiceAbstractDef = function() {

      this.getUniqueRequestId = function ()
      {
        return ihestiaRestCommonHelper.getUniqueRequestId();
      };

      this.getNewRequestHeaders = function()
      {
        var apCaller = ihestiaConfigHelper.get('rest', 'AP_CALLER');
        if (!angular.isString(apCaller)) {
          ihestiaCommonErrorHandler.throwException('BRAK AP_CALLER w konfiguracji restow');
        }

        var headers = {
          'AP-Caller': apCaller,
          'Accept': 'application/json'
        };

        var apBrand = ihestiaConfigHelper.get('rest', 'AP_BRAND');
        if(angular.isString(apBrand)){
          headers['AP-Brand'] = apBrand;
        }

        if (!ihestiaConfigHelper.get('rest', 'SKIP_USER_CHARACTER')) {
          headers['AP-User-Character'] = ihestiaConfigHelper.get('sso', 'userInfo').character.code;
          headers['AP-Character-Code'] = ihestiaConfigHelper.get('sso', 'userInfo').character.code;
          if(ihestiaSsoBaseInfoHelper.isUserRelogged() || (ihestiaConfigHelper.get('frontFlags', 'ALWAYS_SEND_RELOGGED_CHARACTER') && ihestiaSsoBaseInfoHelper.isUserRelogged(true)))
          {
            headers['AP-User-Character-Relogged'] = ihestiaSsoBaseInfoHelper.getReloggedUser().character.code;
          }
        }

        if (ihestiaConfigHelper.get('rest', 'SSO_HEADERS') === true) {
          headers['AP-User-Login'] = ihestiaConfigHelper.get('sso', 'userInfo').login;
          headers['AP-Session-Id'] = ihestiaConfigHelper.get('sso', 'sessionId');
          headers['AP-Application'] = apCaller;
        }

        if(ihestiaConfigHelper.get('rest', 'SSO_USER_TYPE_HEADER') === true)
        {
          headers['AP-User-Type'] = ihestiaConfigHelper.get('sso', 'userInfo').userType;
        }

        //dodatkowe nagłówki
        if(ihestiaConfigHelper.get('rest', 'AP_PLATFORM')) {
          headers['AP-Platform'] = ihestiaConfigHelper.get('rest', 'AP_PLATFORM');
        }
        if(ihestiaConfigHelper.get('rest', 'AP_ACCESS_CHANNEL')) {
          headers['AP-Access-Channel'] = ihestiaConfigHelper.get('rest', 'AP_ACCESS_CHANNEL');
        }

        if (ihestiaConfigHelper.get('ic', 'activeContext')) {
          headers['AP-Ic'] = ihestiaConfigHelper.get('ic', 'activeContext');
        }

        return headers;
      };
    };

    return RestServiceAbstractDef;
  }]);