angular.module('ihestiaWidgets.policyDetails')
  .controller('policyDocumentsModalCtrl',
    ['$scope', 'policiesPrintoutsSvc', '$filter', 'iHestiaCommonModalHelper', 'policyDocumentsModalHelper', 'PolicyHelper', 'policiesSvc', 'ihestiaPlatformHelper', 'policyDetailsContextHelper', 'ihestiaPolicyDetailsModalsInjectorCfg', '$alert',
      function($scope, policiesPrintoutsSvc, $filter, iHestiaCommonModalHelper, policyDocumentsModalHelper, PolicyHelper, policiesSvc, ihestiaPlatformHelper, policyDetailsContextHelper, ihestiaPolicyDetailsModalsInjectorCfg, $alert) {

        $scope.documentTypes = [];
        $scope.policyHelper = new PolicyHelper({}, policyDocumentsModalHelper.policyId, policyDocumentsModalHelper.policyNumber);
        $scope.errors = [];
        $scope.policyData = {
          number: policyDocumentsModalHelper.policyNumber,
          id: policyDocumentsModalHelper.policyId
        };

        /**
         * Init
         * @return {undefined}
         */
        $scope.init = function() {
          $scope.checkPolicyPrintouts();
        };

        /**
         * Sprawdzamy, czy to się da wydrukować (np. nie są anulowaniami)
         * @return {undefined}
         */
        $scope.checkPolicyPrintouts = function() {
          $scope.$emit('showBlockUi');
          policiesPrintoutsSvc.get(policyDocumentsModalHelper.policyNumber, null, null, null, null, {
            allowedStatuses: [404]
          }).then(function(res) {
            if (res && res.status === 200) {
              $scope.loadDocumentTypes();
            }
          }, function(res) {

            $scope.errors = [];

            if (res && res.data && angular.isArray(res.data.technicalMessages) && res.data.technicalMessages.length > 0) {
              angular.forEach(res.data.technicalMessages, function(messageObject) {
                if ($scope.errors.indexOf(messageObject.text) === -1) {
                  $scope.errors.push(messageObject.text);
                }
              });
            }

            if (res && angular.isArray(res.data)) {

              angular.forEach(res.data, function(errorObject) {
                if (errorObject.text) {
                  $scope.errors.push(errorObject.text);
                }
              });
              if ($scope.errors.length === 0) {
                $scope.errors.push($filter('translate')('unknownServiceErrorHasOccured', {componentCode: 'Public'}));
              }
            }

            $scope.errors = $scope.errors.unique();

            $scope.$emit('hideBlockUi');
          });
        };

        /**
         * Ładujemy dokumenty dostępne do pobrania
         * @return {undefined}
         */
        $scope.loadDocumentTypes = function() {
          $scope.$emit('showBlockUi');
          $scope.documentTypes = [];
          policiesSvc.get(policyDocumentsModalHelper.policyNumber, null, 'customerDocuments').then(function(res) {
            if (res && res.data) {
              $scope.documentTypes = res.data;
            }
          }, angular.noop).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        };

        /**
         * Pobranie żądanego pdf'a
         * @param  {string} documentCode (POLISA|POLISA-KOPIA|..)
         * @return {undefined}
         */
        $scope.printDocument = function(documentCode) {
          $scope.errros = [];
          // IHESTIANBK-1854 for Agent and Client we present info modal for Policy
          // https://ebok.atena.pl/browse/IHESTIAGEN-226 dla ZK
          if ((documentCode === 'Policy' || documentCode === 'GreenCard')
            && !policyDetailsContextHelper.isCentral()
            && !ihestiaPlatformHelper.isLifeContext()) {
            policyDocumentsModalHelper.documentsType = documentCode;
            if (documentCode === 'GreenCard') {
              ihestiaPolicyDetailsModalsInjectorCfg.policyDocumentsModalInfoSettings.title = $filter('translate')('offerResources.greencard', {
                componentCode: 'Public'});
            }
            iHestiaCommonModalHelper.hideModal('ihestiaPolicyDocuments');
            iHestiaCommonModalHelper.showModal('ihestiaPolicyDocumentsInfo');
            return;
          }

          $scope.$emit('showBlockUi', {
            label: $filter('translate')('downloadingDocument', {componentCode: 'Public'})
          });

          $scope.policyHelper.printDocument(documentCode).then(function(res) {
            if (res.status === 200) {
              iHestiaCommonModalHelper.hideModal('ihestiaPolicyDocuments');
            } else if (res.status === 409) {
              if (documentCode === 'GreenCard') {
                $alert({
                  content: $filter('translate')('unavailableGreenCard', {componentCode: 'Public'}),
                  type: 'danger',
                  duration: 20
                });
              } else {
                $scope.errros.push($filter('translate')('printIsNotAvailable', {componentCode: 'Public'}));
              }
            } else if (angular.isDefined(res.data)) {
              if (angular.isDefined(res.data.messages) && angular.isArray(res.data.messages)) {
                angular.forEach(res.data.messages, function(message) {
                  if (angular.isObject(message) && message.text) {
                    $scope.errros.push(message.text);
                  }
                });
              }
            } else {
              $scope.errros.push($filter('translate')('unknownServiceErrorHasOccured', {componentCode: 'Public'}));
            }
          }, angular.noop).finally(function() {
            $scope.$emit('hideBlockUi');
            policyDocumentsModalHelper.clearData();
          });
        };

      }
    ]);
