angular.module('ihestiaWidgets.policyDetails')
  .factory('ihestiaRiskTableHelper', ['labelServiceHelper', '$filter', 'labelActionHelper', 'ihestiaPlatformHelper', 'RiskTableHelperConfig',
    function(labelServiceHelper, $filter, labelActionHelper, ihestiaPlatformHelper, RiskTableHelperConfig) {

      var RiskTableHelper = function() {

        var self = this;
        /**
         * TYMCZASOWE TŁUMACZENIA START
         */
        this.translationRiskRows = []; //trzymamy tu dowiązania do tłumaczonych obiektów
        /**
         * TYMCZASOWE TŁUMACZENIA END
         */

        this.numericCoverageOptionList = ['I', 'II', 'III'];

        this.mapConfig = new RiskTableHelperConfig();

        /**
         * @typedef RiskTableMappingParams
         * @property {string | int | undefined} [objectId] - opcjonalny obiekt, z ktorego wyłuskujemy dane
         * @property {boolean} [skipObjectRisks]
         * @property {string} [mapMode] - RiskTableHelperConfigConstants
         * @property {boolean} [showIndexation] - show indexation small text under indexed risks
         * @property {number} [premiumFrequency] - show premium frequency in premium table header
         * @property {boolean} [dividedByDate] - divide risks between active and historical
         * @property {string} [activeThresholdDate] - date to divide active and historical
         * @property {boolean} [onlyActive] - filter only active risks
         * @property {boolean} [activeFromRiskTable]
         */
        /**
         * Parsujemy dane z insuranceTable
         * @param  {object} insuranceTableData dane z usługi
         * @param {RiskTableMappingParams} [additionalParams] - additional params for mapping
         * @param {LsnRiskModelV2[]} [risks]
         * @return {array}                    dane do riskTable.tpl.html
         */
        this.retrieveRiskTables = function(insuranceTableData, additionalParams, risks) {
          var params = additionalParams || {};
          if (ihestiaPlatformHelper.isLifeContext()) {
            if (params.divideByEndDate) {
              return self._retrieveRiskTablesEhlDivided(insuranceTableData, params, risks);
            } else {
              return self._retrieveRiskTablesEhl(insuranceTableData, params, risks);
            }
          } else {
            return self._retrieveRiskTablesDefault(insuranceTableData, params.objectId, params.skipObjectRisks);
          }
        };

        /**
         * TYMCZASOWE TŁUMACZENIA START
         */
        this.setUglyTranslations = function(riskRow) {
          if (labelServiceHelper.isServiceTranslationActive()) {
            self.translationRiskRows.push(riskRow);
          }
        };

        this.refreshTranslations = function() {
          var coverageMap = {
            // 'sport, praca fizyczna, choroby przewlekłe': '',
            'choroby przewlekłe': ['tmp.coverageSickness', 'Temp'],
            'ASO': ['liquidationOptions.serviceName', 'sp2resource'],
            'Stała suma ubezpieczenia': ['productlist.ackomDesc.fixedSumInsured', 'sp2resource'],
            'Wyposażenie dodatkowe': ['productlist.ackomVariantDesc4Name', 'sp2resource'],
            'Wymiana zabezpieczeń': ['productlist.ackomDesc.securityExchange', 'sp2resource'],
            'Parkowanie pojazdu po szkodzie': ['tmp.coverageParking', 'Temp'],
            'Pojazd zastępczy po kradzieży': ['tmp.coverageReplacementVehicle', 'Temp'],
            'OC posiadacza pojazdu': ['Public.productOckom', 'Public'],
            'Szyby': ['prodadd.glazingName', 'v2e7resource'],
            // 'Holowanie pojazdu po zderzeniu pojazdów': [],
            'Telefoniczny Asystent Prawny': ['tmp.coveragePhoneLegalAssistant', 'Temp'],
            'Holowanie pojazdu': ['tmp.coverageVehicleTracking', 'Temp']
          };
          var riskMap = {
            'Pomoc medyczna i koszty leczenia': ['productlist.medicalExpensesName', 'travelresource'],
            'NNW': ['productlist.nnwName', 'sp2resource'],
            'Odpowiedzialność Cywilna': ['productlist.oczpName', 'travelresource'],
            'Bagaż': ['productlist.prodBaggageName', 'travelresource'],
            'Sprzęt sportowy': ['prodadd.sportEqName', 'travelresource'],
            'Podróż Assistance': ['productlist.medicalExpansesFakeName', 'travelresource'],
            'Autocasco (w tym kradzież)': ['tmp.riskAcTheft', 'Temp'],
            'Odpowiedzialność cywilna posiadaczy pojazdów mechanicznych': ['tmp.riskOcKom', 'Temp']
          };

          var sumInsuredMap = {
            'brutto': ['tmp.sumBrutto', 'Temp'],
            'osoba': ['tmp.sumPerson', 'Temp'],
            'zgodnie z Ustawą': ['tmp.sumLaw', 'Temp']
          };

          angular.forEach(self.translationRiskRows, function(riskRow) {
            angular.forEach(riskRow.insuranceRiskRows, function(insuranceRiskRow) {
              //odkładamy bazowe teksty
              if (insuranceRiskRow.coverageOption && angular.isUndefined(insuranceRiskRow.baseCoverageOption)) {
                insuranceRiskRow.baseCoverageOption = insuranceRiskRow.coverageOption;
              }
              if (insuranceRiskRow.name && angular.isUndefined(insuranceRiskRow.baseName)) {
                insuranceRiskRow.baseName = insuranceRiskRow.name;
              }
              if (insuranceRiskRow.sumInsured && angular.isUndefined(insuranceRiskRow.baseSumInsured)) {
                insuranceRiskRow.baseSumInsured = insuranceRiskRow.sumInsured;
              }

              //szukamy czy mamy do tego †łumaczenia
              if (insuranceRiskRow.name && riskMap[insuranceRiskRow.baseName]) {
                insuranceRiskRow.name = $filter('translate')(riskMap[insuranceRiskRow.baseName][0], {componentCode: riskMap[insuranceRiskRow.baseName][1]});
              }
              if (insuranceRiskRow.name && insuranceRiskRow.baseName.indexOf('Następstwa nieszczęśliwych wypadków kierowcy i') !== -1) //ustatnia spacja z usługi to jakiś dziwny biały znak
              {
                insuranceRiskRow.name = $filter('translate')('tmp.riskNnw', {componentCode: 'Temp'});
              }
              //w coverage tłumaczony jest tylko kawałek stringa, więc szukamy dokładniej
              if (insuranceRiskRow.coverageOption) {
                angular.forEach(coverageMap, function(label, coverageMapString) {
                  if (insuranceRiskRow.baseCoverageOption.indexOf(coverageMapString) !== -1) {
                    insuranceRiskRow.coverageOption = insuranceRiskRow.baseCoverageOption.replace(coverageMapString, $filter('translate')(label[0], {componentCode: label[1]}));
                  }
                });
              }
              if (insuranceRiskRow.sumInsured) {
                angular.forEach(sumInsuredMap, function(label, sumInsuredMapString) {
                  if (insuranceRiskRow.baseSumInsured.indexOf(sumInsuredMapString) !== -1) {
                    insuranceRiskRow.sumInsured = insuranceRiskRow.baseSumInsured.replace(sumInsuredMapString, $filter('translate')(label[0], {componentCode: label[1]}));
                  }
                });
              }
            });
          });
        };
        /**
         * TYMCZASOWE TŁUMACZENIA END
         */
        this._retrieveRiskTablesDefault = function(insuranceTableData, objectId, skipObjectRisks) {
          var riskTables = [];
          if (insuranceTableData) {
            angular.forEach(insuranceTableData, function(riskSet) {
              var riskTable = {
                insuranceEndDate: riskSet.insuranceEndDate,
                insuranceStartDate: riskSet.insuranceStartDate,
                riskRows: []
              };
              angular.forEach(riskSet.insuranceRiskGroups, function(riskGroup) {
                if (skipObjectRisks && riskGroup.objectId !== 'others') {
                  return;
                }

                // sprawdzamy, czy chcemy wyłuskać tylko ryzyka dla konkretnego obiektu
                if (!objectId || riskGroup.objectId === objectId) {
                  angular.forEach(riskGroup.insuranceRiskGroupRows, function(riskRow) {
                    /**
                     * TYMCZASOWE TŁUMACZENIA START
                     */
                    if (labelServiceHelper.isServiceTranslationActive()) {
                      self.setUglyTranslations(riskRow);
                    }
                    /**
                     * TYMCZASOWE TŁUMACZENIA END
                     */
                    riskTable.riskRows.push(riskRow);
                  });

                  /**
                   * TYMCZASOWE TŁUMACZENIA START
                   */
                  if (labelServiceHelper.isServiceTranslationActive()) {
                    self.refreshTranslations();
                    labelActionHelper.registerAction('riskList', self.refreshTranslations);
                  }
                  /**
                   * TYMCZASOWE TŁUMACZENIA END
                   */

                }
              });

              // nie ma sensu dodawać tabeli ryzyk, jeśli nie ma w niej ryzyk
              if (riskTable.riskRows.length > 0) {
                riskTables.push(riskTable);
              }
            });
          }
          return riskTables;
        };

        /**
         * @typedef LifeRiskTableRiskItem
         * @property {boolean} cantMergePremium
         * @property {string} coverageOption
         * @property {string} name
         * @property {string} objectOrSubject
         * @property {number} premium
         * @property {string} sumInsured
         * @property {string} riskSymbol
         * @property {string} riskStartDate
         * @property {string} riskEndDate
         * @property {boolean} [hidden]
         * @property {boolean} [first]
         * @property {Array<any>} sumsForYear
         * @property {boolean} numericCoverageOption
         */
        /**
         * @typedef LifeRiskTableData
         * @property {string} insuranceEndDate - to display in table header
         * @property {string} insuranceEndDate - to display in table header
         * @property {{object: string, risks: LifeRiskTableRiskItem}[]} riskRows
         */
        /**
         *
         * @param {any} insuranceTableData
         * @param {RiskTableMappingParams} additionalParams
         * @param {LsnRiskModelV2[]} risks
         * @return {LifeRiskTableData[]}
         * @private
         */
        this._retrieveRiskTablesEhl = function(insuranceTableData, additionalParams, risks) {
          var params = additionalParams || {};
          var indexedRiskSymbols = params.showIndexation ? self.mapConfig.getIndexedRiskSymbols(risks, params.mapMode) : [];
          var activeRiskSymbols = params.onlyActive ? self.mapConfig.getActiveRiskSymbols(risks, params.activeThresholdDate, params.mapMode) : [];
          var riskTables = _.map(insuranceTableData, function(riskSet) {
            var tableData = {
              insuranceEndDate: riskSet.insuranceEndDate,
              insuranceStartDate: riskSet.insuranceStartDate,
              riskRows: null
            };
            if (params.premiumFrequency) {
              tableData.premiumFrequency = params.premiumFrequency;
            }
            tableData.riskRows = self._chainCommonRiskData(riskSet.insuranceRiskGroups || [], indexedRiskSymbols, activeRiskSymbols, params)
              .value();
            return tableData;
          });
          return riskTables;
        };

        /**
         *
         * @param {any} insuranceTableData
         * @param {any} [additionalParams]
         * @param {LsnRiskModelV2[]} risks
         * @return {Array}
         * @private
         */
        this._retrieveRiskTablesEhlDivided = function(insuranceTableData, additionalParams, risks) {
          var params = additionalParams || {};
          // IHESTLIFE-5056
          var activeRiskSymbols =
            self.mapConfig.getActiveRiskSymbols(risks, params.activeThresholdDate, params.mapMode);
          return _.map(insuranceTableData, function(riskSet) {
            return self._chainCommonRiskData(riskSet.insuranceRiskGroups || [])
              .map(function(riskData) {
                var dividedRisks = _.groupBy(riskData.risks, function(risk) {
                  return _.includes(activeRiskSymbols, risk.riskSymbol) ? 'active' : 'historical';
                });

                var finalRiskRows = [];
                if (_.size(dividedRisks.historical) > 0) {
                  dividedRisks.historical[0].first = true;
                  finalRiskRows.push({object: riskData.object, risks: dividedRisks.historical, status: 'historical'});
                }

                if (_.size(dividedRisks.active) > 0) {
                  dividedRisks.active[0].first = true;
                  finalRiskRows.push({object: riskData.object, risks: dividedRisks.active, status: 'active'});
                }
                return finalRiskRows;
              })
              .flatten()
              .groupBy('status')
              .mapValues(function(riskRows) {
                // if active risks table is available, mark it as visible, else mark historical as visible
                return {
                  insuranceEndDate: riskSet.insuranceEndDate,
                  insuranceStartDate: riskSet.insuranceStartDate,
                  riskRows: riskRows
                };
              })
              .value();
          });
        };

        /**
         * Chain common EHL risk group mapping
         * function returns non-terminal lodash chain - required value() call on return value
         * makes further customization of chain available
         * @param insuranceRiskGroups
         * @param indexedRiskSymbols
         * @param {string[]} activeRiskSymbols
         * @param {RiskTableMappingParams} [params]
         * @return {{risks: Array, object: *}[]}
         * @private
         */
        this._chainCommonRiskData = function(insuranceRiskGroups, indexedRiskSymbols, activeRiskSymbols, params) {
          if (!params) {
            params = {};
          }
          return _.chain(insuranceRiskGroups)
            .flatMap('insuranceRiskGroupRows')
            .flatMap('insuranceRiskRows')
            .groupBy('objectOrSubject')
            .toPairs()
            .map(function(pair) {
              // mark risk as first on the list to render object name - neccessary because of risk.hidden option
              var firstRiskAssigned = false;
              return {
                object: pair[0],
                risks: _.chain(pair[1])
                  .map(function(risk) {
                    risk.numericCoverageOption = self.numericCoverageOptionList.indexOf(risk.coverageOption) > -1;
                    if (!_.isEmpty(indexedRiskSymbols)) {
                      risk.indexed = _.includes(indexedRiskSymbols, risk.riskSymbol);
                    }
                    if (!_.isEmpty(activeRiskSymbols) && !params.activeFromRiskTable) {
                      risk.hidden = !_.includes(activeRiskSymbols, risk.riskSymbol);
                    } else if (params.activeFromRiskTable && params.activeThresholdDate) {
                      risk.hidden = risk.riskEndDate <= params.activeThresholdDate;
                    }
                    if (!firstRiskAssigned && !risk.hidden) {
                      risk.first = firstRiskAssigned = true;
                    }
                    return risk;
                  })
                  .value()
              };
            });
        };

        /**
         *
         * @param {Array<{active: LifeRiskTableData[], historical: LifeRiskTableData[]}>} riskTables
         * @return {boolean}
         * @private
         */
        this.activeRiskTablesPresent = function(riskTables) {
          return _.some(riskTables, function(riskTable) {
            return !_.isUndefined(riskTable.active);
          });
        };

      };

      return new RiskTableHelper();
    }]);
