angular.module('ihestiaCommonDirectives', [
  'ihestia-commondirectives-templates-app',
  'ihestiaCommonDirectives.configHelper',
  'ihestiaCommonDirectives.platformHelper',
  'ihestiaCommonDirectives.baseDownloadHelper',
  'ihestiaCommonDirectives.baseUpload',
  'ihestiaRestServicesBase',
  'lsnBase',
  'ihestiaCommonDirectives.detectorUserActvie',
  'ihestiaCommonDirectives.changeLanguage',
  'ihestiaCommonDirectives.labelActionHelper',
  'ihestiaCommonDirectives.starRating',
  'ihestiaCommonDirectives.application',
  'ihestia-commondirectives-templates-common',
  'ihestiaCommonDirectives.trimInfo',
  'ihestiaCommonDirectives.pkdHelper',
  'ihestiaCommonDirectives.polishGrama',
  'ihestiaCommonDirectives.strpos',
  'mgcrea.ngStrap.modal',
  'mgcrea.ngStrap.datepicker',
  'lsnBase.bsDatepickerPropperPlTitle',
  'mgcrea.ngStrap.alert',
  'mgcrea.ngStrap.timepicker',
  'mgcrea.ngStrap.popover',
  'mgcrea.ngStrap.tooltip',
  'blueimp.fileupload',
  'ngAnimate',
  'angular-ladda',
  'ui.router',
  'lsnBase.dateTime'
]).config(['$datepickerProvider', '$timepickerProvider', '$animateProvider', 'ihestiaConfigHelperProvider', '$compileProvider', 'lsnDateTimeHelperProvider',
  function($datepickerProvider, $timepickerProvider, $animateProvider, ihestiaConfigHelperProvider, $compileProvider, lsnDateTimeHelperProvider) {
    angular.extend($datepickerProvider.defaults, { //domyślne ustawienia datePickera
      dateFormat: 'yyyy-MM-dd',
      dateType: 'string', //w modelu zapisuje się jako string a nie jako Date
      autoclose: true, //zamyka się po wybraniu daty
      startWeek: 1, //tydzień zaczyna się od poniedziałku
      iconLeft: 'icon-sl-fill icon-arrow-67',
      iconRight: 'icon-sl-fill icon-arrow-68',
      dayFormat: 'd'
    });
    angular.extend($timepickerProvider.defaults, { //domyślne ustawienia datePickera
      timeFormat: 'HH:mm',
      timeType: 'string',
      length: 4,
      autoclose: true,
      minuteStep: 5,
      iconUp: 'icon-sl-fill icon-arrow-66',
      iconDown: 'icon-sl-fill icon-arrow-65'
    });
    // wywalenie animacji z inputów form-control
    // zrobione specjalnie dla bocznego menu i inline-edit
    $animateProvider.classNameFilter(/^((?!no-animate).)*$/);

    // przyspiesza kompilację, ale wywala debug
    if(!localStorage.ngDebugInfoEnabled && !ihestiaConfigHelperProvider.getConfigHelper().get('frontFlags', 'DEBUG_INFO_ENABLED')) {
      $compileProvider.debugInfoEnabled(false);
    }

    lsnDateTimeHelperProvider.fetchTimeFunc = function() {
      if (lsnDateTimeHelperProvider.env === 'www') {
        return new XDate(ihestiaConfigHelperProvider.getConfigHelper().get('serverData').TODAY + ' ' + ihestiaConfigHelperProvider.getConfigHelper().get('serverData').CURRENT_TIME);
      } else {
        return new XDate();
      }
    };
  }
]);
