angular.module('salesPath2')
  .service('newBonusMalusModalHelper', ['dataContainerHelper', 'mainDataContainer', 'CONSTANTS', 'sp2CommonHelper', 'lsnObjectHelper', 'applicationHelper', '$filter',
    function(dataContainerHelper, mainDataContainer, CONSTANTS, sp2CommonHelper, lsnObjectHelper, applicationHelper, $filter) {
      /**
       * new BM modal based on IHESTIASS-615
       * @constructor
       */
      var NewBonusMalusHelperClass = function() {
        var self = this;

        var SINGLE_COMPUTED_DATA = { // same codes as in DEFAULT_DATA.rows
          isSelected: false, // is product/risk selected
          damages1y: 0,
          damages10y: 0,
          prevPolicyOuz: 0 // max from 'premiumFromPreviousPolicyOCU' or 'premiumFromPreviousPolicyACU'
        };

        var DEFAULT_DATA = {
          computedData: {
            oc: _.cloneDeep(SINGLE_COMPUTED_DATA),
            ac: _.cloneDeep(SINGLE_COMPUTED_DATA)
          },
          processing: false,
          isUfgProblem: false, // is ufg connection problem based on https://ebok.atena.pl/browse/IHESTIASS-615 algorithm
          warnings: [], // string[] warnings to show on modal
          showWarnings: false,
          table: { // modified by this._prepareTable
            isVisible: false, // false if isUfgProblem===true or no data included
            headers: [ // same codes as in computedData keys
              {code: 'oc', name: 'OC'},
              {code: 'ac', name: 'AC'}
            ],
            rows: [
              {
                dataCode: 'damages1y',
                label: $filter('translate')('sp.insuredDamagesModal.1y.label', {componentCode: 'Public'}),
                riskCols: [ // same codes as in computedData keys
                  {code: 'oc', value: 0},
                  {code: 'ac', value: 0}
                ]
              },
              {
                dataCode: 'damages10y',
                label: $filter('translate')('sp.insuredDamagesModal.10y.label', {componentCode: 'Public'}),
                riskCols: [ // same codes as in computedData keys
                  {code: 'oc', value: 0},
                  {code: 'ac', value: 0}
                ]
              }
            ]
          }
        };

        /**
         * clientId of vehicle, we're collecting data for
         * @type {string|null}
         */
        this.vehicleId = null;

        /**
         * is modal currently active - shown on GUI
         * @type {boolean}
         */
        this.isActive = false;

        this.modalSettings = {
          treatLabelAsI18nCode: true,
          title: ['sp.insuredDamagesModal.title', {
            componentCode: 'Public'
          }],
          cancelBtnName: null,
          okBtnName: ['Public.close', {componentCode: 'Public'}],
          okBtnDisabled: false,
          keyboard: false,
          size: 'md'
        };

        this.tplData = _.cloneDeep(DEFAULT_DATA);

        /**
         * triggers when specified action was dispatched
         */
        this._afterAction = function() {
          if (arguments[0] === 'tarifficationEnded' && self.isActive) {
            self.initData();
          }
        };

        this.refreshProcessingFlag = function() {
          self.tplData.processing = applicationHelper.callInProgress;
        };

        /**
         * init/set all required data
         * NOTE
         * this method should be called when this.vehicleId is already set
         */
        this.initData = function() {
          lsnObjectHelper.replaceObject(self.tplData, _.cloneDeep(DEFAULT_DATA));
          var vehicle = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, self.vehicleId);
          var insured = [mainDataContainer.mainInsuredId].concat(vehicle.getAdditionalData('coowners'));
          var vehiclePolicy = _.find(mainDataContainer.policies, function(pol) {
            return _.isArray(pol.vehicles) && _.some(pol.vehicles, {metaData: {id: vehicle.metaData.id}});
          }) || null;

          self.tplData.computedData.oc.isSelected = dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_OCKOM, self.vehicleId);
          self.tplData.computedData.ac.isSelected = dataContainerHelper.isProductSelected(CONSTANTS.PRODUCT_ACKOM, self.vehicleId);

          // data from policy
          if (vehiclePolicy) {
            self._setComputedData(vehiclePolicy.product, 'premiumFromPreviousPolicyOCU', 'oc', 'prevPolicyOuz');
            self._setComputedData(vehiclePolicy.product, 'premiumFromPreviousPolicyACU', 'ac', 'prevPolicyOuz');
          }

          // check ufg problems based on vehicle
          self.tplData.isUfgProblem = self._isUfgProblem(vehicle);

          angular.forEach(insured, function(clientId) {
            var person = mainDataContainer.persons[clientId];
            self.tplData.isUfgProblem = self.tplData.isUfgProblem || self._isUfgProblem(person);
            // oc
            self._setComputedData(person, 'ufgNoClaimsOCPerpetr10Y', 'oc', 'damages10y');
            self._setComputedData(person, 'ufgNoClaimsOCPerpetr1Y', 'oc', 'damages1y');
            // ac
            self._setComputedData(person, 'ufgNoClaimsAC10Y', 'ac', 'damages10y');
            self._setComputedData(person, 'ufgNoClaimsAC1Y', 'ac', 'damages1y');
          });
          if (self.tplData.isUfgProblem) {
            self._setUfgProblemWarning();
          } else {
            if (self.tplData.computedData.oc.isSelected) {
              self._setOUZWarning('oc');
            }
            if (self.tplData.computedData.ac.isSelected) {
              self._setOUZWarning('ac');
            }
            self._prepareTable();
          }
          if (!self.tplData.table.isVisible && !self.tplData.warnings.length) {
            self._setNoDataWarning();
          }
          self.tplData.showWarnings = !!self.tplData.warnings.length;
          self.refreshProcessingFlag();
        };

        this._setComputedData = function(objWithDynVals, dvName, riskCode, dataCode) {
          var ufgValue = _.get(objWithDynVals, 'dynamicValues.' + dvName, 0);
          self.tplData.computedData[riskCode][dataCode] = Math.max(
            self.tplData.computedData[riskCode][dataCode],
            ufgValue);
        };

        this._prepareTable = function() {
          angular.forEach(['oc', 'ac'], function(riskCode) {
            if (self.tplData.computedData[riskCode].isSelected) {
              angular.forEach(self.tplData.table.rows, function(row) {
                _.find(row.riskCols, {code: riskCode}).value = self.tplData.computedData[riskCode][row.dataCode];
              });
            } else {
              _.remove(self.tplData.table.headers, {code: riskCode});
              angular.forEach(self.tplData.table.rows, function(row) {
                _.remove(row.riskCols, {code: riskCode});
              });
            }
          });
          if (self.tplData.isUfgProblem || !self.tplData.table.headers.length) {
            self.tplData.table.isVisible = false;
          } else {
            self.tplData.table.isVisible = true;
          }
        };

        this._setUfgProblemWarning = function() {
          self.tplData.warnings.push($filter('translate')('sp.insuredDamagesModal.warning.ufgProblem', {componentCode: 'Public'}));
        };

        this._setNoDataWarning = function() {
          self.tplData.warnings.push($filter('translate')('sp.insuredDamagesModal.warning.noRisks', {componentCode: 'Public'}));
        };

        /**
         * set OZU warning if conditions are met
         * @param {'oc'|'ac'} riskCode
         * @private
         */
        this._setOUZWarning = function(riskCode) {
          // Występuje szkoda na AC, która nie jest uwzględniona w taryfikacji składki, w związku z wykupieniem produktu OUZ AC na poprzedniej polisie.
          var riskName = (riskCode === 'oc') ? 'OC' : 'AC';
          var content = $filter('translate')('sp.insuredDamagesModal.warning.damage', {
            componentCode: 'Public', params: {
              risk1: riskName,
              risk2: 'OUZ ' + riskName
            }
          });
          if (self.tplData.computedData[riskCode].prevPolicyOuz > 0 && self.tplData.computedData[riskCode].damages1y === 1) {
            self.tplData.warnings.push(content);
          }
        };

        /**
         * is any ufg problem based on person or vehicle
         * NOTE
         * pass vehicle or person
         * @param {LsnPersonModelV1|LsnVehicleModelV1} obj
         * @return {boolean}
         * @private
         */
        this._isUfgProblem = function(obj) {
          var values = [-501, -502];
          var dvsToCheck = obj.metaData.className === 'Person' ? ['ufgNoClaimsOCPerpetr10Y', 'ufgNoVehiclesOC10Y']
            : ['ufgCarLenNonCovDaysOC10Y', 'ufgCarNoClaimsOCPerpetr10Y'];
          var anyProblem = false;
          lsnNg.forEach(dvsToCheck, function(dvName) {
            if (_.includes(values, _.get(obj, 'dynamicValues.' + dvName, 0))) {
              anyProblem = true;
              return false;
            }
            return true;
          });
          return anyProblem;
        };

        this.showModal = function(vehicleId) {
          self.vehicleId = vehicleId;
          self.initData();
          sp2CommonHelper.showModal('newBonusMalusModal');
        };

      };
      return new NewBonusMalusHelperClass();
    }
  ])
  .run(['newBonusMalusModalHelper', 'actionHelper',
    function(newBonusMalusModalHelper, actionHelper) {
      actionHelper.registerHelper('newBonusMalusModalHelper', newBonusMalusModalHelper);
    }
  ]);
