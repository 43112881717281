//@deprecated
angular.module('ihestiaCommonDirectives')
  .service('ihestiaUploadHelper', ['$rootScope', 'filesSvc', 'ihestiaConfigHelper', 'IHestiaRestServiceProviderAbstractDef', '$timeout', '$alert', 'ihestiaCharacterConflictedHelper', 'addMd5ToFile', '$filter', 'iSkanerPoliciesFrpSvc', 'deprectedHelper',
    function($rootScope, filesSvc, ihestiaConfigHelper, IHestiaRestServiceProviderAbstractDef, $timeout, $alert, ihestiaCharacterConflictedHelper, addMd5ToFile, $filter, iSkanerPoliciesFrpSvc, deprectedHelper) {
      var UploadHelper = function() {
        var self = this;

        deprectedHelper.setAsDeprecated('ihestiaUploadHelper', 'przepnij się na inny uploader');
        this.files = {
          toSend: {},
          uploaded: {}
        };
        this.status = {};
        this.windowUploadClose = {};
        this.processedFileIndex = 0;
        this.processingType = {}; //dostepny rowniez iskaner

        /**
         * Metoda służy do inicjowania listy wysłanych plików
         * @param  {array} files tablica wyslanych plikow
         * @param  {string} scopeName  nazwa scope
         */
        this.initUploadedFiles = function(files, scopeName) {
          self.files.uploaded[scopeName] = files;
        };

        /**
         * Metoda służy do dodawania plikow do wysłania
         * @param  {object} files tablica plikow do wysłania
         * @param  {string} scopeName  nazwa scope
         * @param  {string} documentType  nazwa scope
         */
        this.addFiles = function(files, scopeName, documentType) {
          self.initFileList(scopeName);

          angular.forEach(files, function(file) {
            file.documentTypeName = documentType.name;
            file.documentTypeCode = documentType.code;
            file.attributes = documentType.attributes;
            self.files.toSend[scopeName].push(file);
            addMd5ToFile(file);
          });
        };


        this.addISkanerFileAndSend = function(files, scopeName, documentType) {
          self.initFileList(scopeName);

          angular.forEach(files, function(file) {
            file.documentTypeName = documentType.name;
            file.documentTypeCode = documentType.code;
            file.attributes = documentType.attributes;
            self.files.toSend[scopeName].push(file);
          });

          self.sendInfoAboutFile(scopeName, 'iskaner');
        };

        this.initFileList = function(scopeName) {
          if (typeof self.files.toSend[scopeName] === 'undefined') {
            self.files.toSend[scopeName] = [];
          }
          if (typeof self.files.uploaded[scopeName] === 'undefined') {
            self.files.uploaded[scopeName] = [];
          }
        };

        this.sendFile = function(scopeName) {
          self.status[scopeName] = 'pending';
          self.files.toSend[scopeName][self.processedFileIndex].$submit();
        };

        /**
         * Metoda rozpoczona proces wysyłania plików, wysyłącjąc informacje o pierwszym pliku
         * @param  {string} scopeName  nazwa scope
         */
        this.sendInfoAboutFile = function(scopeName, processingType) {
          if (angular.isDefined(processingType)) {
            self.processingType[scopeName] = processingType;
          }

          //ustawiamy index na kolejnym pliku do wyslania
          self.setNextProcessedFileIndex(scopeName);
          //weryfikujemy czy istnieje jeszcze jakis plik do wyslania
          if (self.processedFileIndex !== null) {
            self.status[scopeName] = 'pending';

            var file = self.files.toSend[scopeName][self.processedFileIndex];
            var fileData = {
              dynamicValues: {}
            };
            fileData.documentCode = file.documentTypeCode;
            fileData.length = file.size;
            fileData.name = file.name;

            angular.forEach(file.attributes, function(attr) {
              if (attr.type === 'datetime') {
                var date = new XDate(attr.val);
                if (date.valid()) {
                  var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
                  fileData.dynamicValues[attr.code] = (new XDate(date.getTime() - timezoneOffset)).toISOString();
                } else {
                  fileData.dynamicValues[attr.code] = '';
                }
              } else {
                fileData.dynamicValues[attr.code] = attr.val;
              }
            });

            filesSvc.post('', fileData, '', function(response) {
              var data = response.data;
              if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
                if ($.isEmptyObject(data)) {
                  var msgs = [{
                    text: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    })
                  }];
                  self.onErrorWhenSending(msgs, scopeName);
                }
                $timeout(function() {
                  var allAttrValid = true;
                  angular.forEach(file.attributes, function(attr) {
                    angular.forEach(data.messages, function(msg) {
                      if (attr.code === msg.object) {
                        allAttrValid = false;
                      }
                    });
                  });
                  if (allAttrValid) {
                    self.onErrorWhenSending(response.data.messages, scopeName);
                  } else {
                    delete self.status[scopeName];
                    $rootScope.$broadcast('fileUploadedOnErrorAttributes.' + scopeName, {
                      messages: data.messages
                    });
                  }
                }, 0);

              } else {

                if (angular.isDefined(file.iSkanerTokenId)) {
                  iSkanerPoliciesFrpSvc.checkStatus();
                  self.sendIskanerFile(file, data.tokenId, scopeName);
                } else {
                  file.tokenId = data.tokenId;
                  file.$submit();
                }
              }
            }, function(res) {
              var msgs;
              if (res.status === 401) {
                msgs = [{
                  text: $filter('translate')('exceptionNoAuthentication', {
                    componentCode: 'Public'
                  })
                }];
              } else if (res.status === 409) {
                msgs = [{
                  text: $filter('translate')('characterConflict', {
                    componentCode: 'Public'
                  })
                }];
              } else {
                msgs = [{
                  text: $filter('translate')('exceptionInternalServices', {
                    componentCode: 'Public'
                  })
                }];
              }

              self.onErrorWhenSending(msgs, scopeName);

            }, {
              doNotAskAgainOnConflict: true
            });
          } else {
            $alert({
              content: $filter('translate')('noFileSelected', {
                componentCode: 'Public'
              }),
              type: 'warning'
            });
          }
        };

        this.sendIskanerFile = function(file, tokenId, scopeName) {
          iSkanerPoliciesFrpSvc.sendFiles(tokenId, file.id, file.iSkanerTokenId).then(function(res) {
            self.onFileUploadDone(scopeName, res.data.fileServerId);
          }, function(res) {
            self.onFileUploadFail(res, scopeName);
          });
        };

        /**
         * Metoda służy do kontynuacji przesłania kolejnych plików
         * @param  {string} scopeName  nazwa scope
         */
        this.proceedUploadingFile = function(scopeName) {
          //zawsze przetwarzamy pierwszy plik i po przetworzeniu go usuwamy
          $timeout(function() {
            $rootScope.$broadcast('deleteFileInUploader', {
              scopeName: scopeName,
              file: self.files.toSend[scopeName][self.processedFileIndex]
            });
            self.files.toSend[scopeName].splice(self.processedFileIndex, 1);
            if (self.files.toSend[scopeName].length > 0) {
              self.sendInfoAboutFile(scopeName);
            } else {
              delete self.status[scopeName];
              if (self.windowUploadClose[scopeName]) {
                self.clearUploadData(scopeName);
              }
              var data = {
                filesUpload: self.files.uploaded[scopeName],
                withoutError: self.checkAllFilesSendWithoutErrors(scopeName)
              };
              $rootScope.$broadcast('allFilesUploaded.' + scopeName, data);
            }
          });
        };

        this.setNextProcessedFileIndex = function(scopeName) {
          self.processedFileIndex = null;
          angular.forEach(self.files.toSend[scopeName], function(file, index) {
            if (self.processedFileIndex === null && self.checkFileIsActualProccessFile(scopeName, file)) {
              self.processedFileIndex = index;
            }
          });
        };

        /**
         * [getNumberFilesToSendByFrp zwraca liczbe plikow do wyslania przez FRP]
         * @param  {[type]} scopeName [description]
         * @return {[type]}           [description]
         */
        this.getNumberFilesToSendByFrp = function(scopeName){
          var number = 0;
          angular.forEach(self.files.toSend[scopeName], function(file) {
            if (!self.processedFileIndex && angular.isUndefined(file.iSkanerTokenId)) {
              number ++;
            }
          });

          return number;
        };

        /**
         * Kopiowanie pliku z tablicy toSend do uploaded.
         * @param {string} scopeName nazwa scope
         * @param {string} status SUCCESS lub ERROR
         * @param {string} message treść komunikatu
         */
        this.copyFileToUploaded = function(scopeName, status, message) {
          var fileToSend = {
            name: self.files.toSend[scopeName][self.processedFileIndex].name,
            size: self.files.toSend[scopeName][self.processedFileIndex].size,
            type: self.files.toSend[scopeName][self.processedFileIndex].type,
            documentTypeName: self.files.toSend[scopeName][self.processedFileIndex].documentTypeName,
            status: status
          };

          if (angular.isDefined(message)) {
            fileToSend.message = message;
          }

          self.files.uploaded[scopeName].push(fileToSend);
        };

        /**
         * Event na błąd do przechwycenia błędu przy wysyłąniu pliku
         */
        $rootScope.$on('ihestiaOnFileUploadFail', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var jqXHR = data.response().jqXHR;

            self.onFileUploadFail(jqXHR, scopeName);
          }
        });

        this.onFileUploadFail = function(jqXHR, scopeName) {
          if (self.isActive(scopeName)) {
            var text = $filter('translate')('exceptionInternalServices', {
              componentCode: 'Public'
            });
            if (jqXHR.status === 403) {
              text = $filter('translate')('noPermissionToSaveFile', {
                componentCode: 'Public'
              });
            } else if (jqXHR.status === 401) {
              text = $filter('translate')('exceptionNoAuthentication', {
                componentCode: 'Public'
              });
            } else if (jqXHR.status === 413) {
              text = 'Plik jest za duży.';
            } else if (jqXHR.status === 409) {
              text = $filter('translate')('characterConflict', {
                componentCode: 'Public'
              });
              $timeout(function() {
                ihestiaCharacterConflictedHelper.charactersConflicted(jqXHR.getResponseHeader('AP-User-Character'));
              });
            } else if (jqXHR.status === 410) {
              text = $filter('translate')('exceptionFileNotSentProbablyMalware', {
                componentCode: 'Public'
              });
            }

            var msgs = [{
              text: text
            }];
            $timeout(function() {
              self.onErrorWhenSending(msgs, scopeName);
            });
          }
        };

        /**
         * Metoda wywoływana po popraw nie przesłanym pliku
         * @param  {string} scopeName  nazwa scope
         */
        $rootScope.$on('ihestiaOnFileUploadDone', function(e, data) {
          var scopeName = data.scope.name;
          self.onFileUploadDone(scopeName, data.result.fileServerId);
        });

        this.onFileUploadDone = function(scopeName, fileServerId) {

          if (self.isActive(scopeName)) {
            //tutaj trzeba zczytać z pliku typ
            if (self.files.toSend[scopeName][self.processedFileIndex].documentTypeCode === 'Policy' || self.files.toSend[scopeName][self.processedFileIndex].documentTypeCode === 'Annex') {
              filesSvc.getQuality(fileServerId, function(res) {

                var msgs;
                if (!$.isEmptyObject(res.data)) {
                  if (res.data.code === 'Ok' || res.data.code === null) {
                    self.copyFileToUploaded(scopeName, 'SUCCESS');
                    self.proceedUploadingFile(scopeName);
                    return;
                  } else if (res.data.code === 'DocumentNumberNotFound' || res.data.code === 'WrongPolicyNumber' || res.data.code === 'WrongAnnexNumber') {
                    msgs = [{
                      text: res.data.description,
                      status: 'WARNING'
                    }];
                  } else {
                    msgs = [{
                      text: res.data.description
                    }];
                  }
                } else {
                  msgs = [{
                    text: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    })
                  }];
                }
                self.onErrorWhenSending(msgs, scopeName);
              }, function(res) {
                var msgs;
                if (res.status === 401) {
                  msgs = [{
                    text: $filter('translate')('exceptionNoAuthentication', {
                      componentCode: 'Public'
                    })
                  }];
                } else if (res.status === 409) {
                  msgs = [{
                    text: $filter('translate')('characterConflict', {
                      componentCode: 'Public'
                    })
                  }];
                } else {
                  msgs = [{
                    text: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    })
                  }];
                }

                self.onErrorWhenSending(msgs, scopeName);

              });
            } else {
              self.copyFileToUploaded(scopeName, 'SUCCESS');
              self.proceedUploadingFile(scopeName);
            }
          }
        };

        /**
         * funkcja wywoływana na jakikolwiek błąd przy wysyłaniu pliku (oprócz usług do weryfikacji jakości przesłanego pliku)
         * @param {object} messages lista z błędami
         * @param  {string} scopeName  nazwa scope
         */
        this.onErrorWhenSending = function(messages, scopeName) {
          var message = '';
          angular.forEach(messages, function(msg) {
            message += msg.text + ' ';
          });

          $timeout(function() {
            var status = messages[0].status ? messages[0].status : 'ERROR';
            self.copyFileToUploaded(scopeName, status, message);
            self.proceedUploadingFile(scopeName);
          }, 0);

        };

        /**
         * funkcja wywoływana na jakikolwiek błąd przy wysyłaniu pliku (oprócz usług do weryfikacji jakości przesłanego pliku)
         * @param {event} e
         * @param  {object} data
         */
        $rootScope.$on('fileuploadsend', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            //tutaj będziemy chcieli wczepić się z nagłówkami
            data.url += '/' + data.files[self.processedFileIndex].tokenId;

            var provider = new IHestiaRestServiceProviderAbstractDef();
            data.headers = provider.getNewRequestHeaders();
            data.headers['AP-Unique-Request-Id'] = provider.getUniqueRequestId();
            data.headers['Ap-Contract-Req-Type'] = 'ACTION';
            data.headers['Ap-Contract-Process-Type'] = 'SYNC';

            data.headers['Content-MD5'] = data.files[self.processedFileIndex].md5;
          }
        });

        /**
         * event wywyołany na usunięcie pliku
         * @param {event} e
         * @param  {object} data
         */
        $rootScope.$on('ihestiaOnFileDelete', function($event, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var i = self.files.toSend[scopeName].length;
            while (i) {
              i -= 1;
              if (self.files.toSend[scopeName][i] === data.file) {
                self.files.toSend[scopeName].splice(i, 1);
                break;
              }
            }
            $rootScope.$broadcast('deleteFileInUploader', {
              file: data.file,
              scopeName: scopeName
            });
          }
        });

        //nie powiodło się dodanie pliku
        $rootScope.$on('fileuploadprocessfail', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            angular.forEach(self.files.toSend[scopeName], function(val, i) {
              if (data.files[self.processedFileIndex].$$hashKey === val.$$hashKey) {
                self.files.toSend[scopeName].splice(i, 1);
              }
            });
          }
        });

        this.onUploadWindowClose = function(scopeName) {
          if (self.status[scopeName] === 'pending') {
            self.windowUploadClose[scopeName] = true;
          } else {
            self.clearUploadData(scopeName);
          }
        };

        this.clearUploadData = function(scopeName) {
          delete self.files.toSend[scopeName];
          delete self.files.uploaded[scopeName];
        };

        this.isActive = function(scopeName) {
          return typeof self.files.toSend[scopeName] !== 'undefined' ? true : false;
        };

        this.getLastProcessingType = function(scopeName){
          return self.processingType[scopeName];
        };

        this.checkAllFilesSendWithoutErrors = function(scopeName){
          var result = true;
          angular.forEach(self.files.uploaded, function(file) {
            if (self.checkFileIsActualProccessFile(scopeName, file) && file.status === 'SUCCESS') {
              result = false;
            }
          });
          return result;
        };

        /**
         * [checkFileIsActualProccessFile description]
         * @param  {[type]} scopeName [description]
         * @param  {[type]} file      [description]
         * @return {[type]}           [description]
         */
        this.checkFileIsActualProccessFile = function(scopeName, file){
          var result = false;
          if (self.processingType[scopeName] !== 'iskaner' && angular.isUndefined(file.iSkanerTokenId)) {
            result = true;
          } else if (self.processingType[scopeName] === 'iskaner' && angular.isDefined(file.iSkanerTokenId)) {
            result = true;
          }

          return result;
        };
      };

      return new UploadHelper();
    }
  ]);