angular.module('lsnBase.models')
  .factory('LsnDistributionChannelModelV1', ['LsnAbstractModel', 'lsnModelFactory',
    function(LsnAbstractModel, lsnModelFactory)
    {
      var LsnDistributionChannelModelV1 = function()
      {
        this.objectName = 'DistributionChannel';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion; 
        this.fieldsProperties = {
          metaData:
          {
            type: 'MetaData'
          },
          branchSymbol:
          {
            type: 'string'
          },
          branchName:
          {
            type: 'string'
          },
          profileNumber:
          {
            type: 'string'
          },
          intermNumber:
          {
            type: 'string'
          },
          intermName:
          {
            type: 'string'
          },
          personName:
          {
            type: 'string'
          },
          personSurname:
          {
            type: 'string'
          },
          punu:
          {
            type: 'string'
          },
          share:
          {
            type: 'int'
          },
          leading:
          {
            type: 'bool'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.branchSymbol = null; // string, symbol przedstawicielstwa
        this.branchName = null; // string, nazwa przedstawicielstwa
        this.profileNumber = null; // string, numer profilu sprzedazowego
        this.intermNumber = null; // string, numer posrednika grupy
        this.intermName = null; // string, nazwa posrednika grupy
        this.personName = null; // string, imie posrednika
        this.personSurname = null; // string, nazwisko posrednika
        this.punu = null; // string, numer PUNU posrednika
        this.share = null; // string, procentowy udzial w prowizji
        this.leading = null; // string, czy kanal wiodacy
      };

      LsnDistributionChannelModelV1.prototype = LsnAbstractModel.prototype;

      return LsnDistributionChannelModelV1;
    }
  ]);