angular.module('salesPath2')
  .controller('owuModalCtrl', ['$scope', 'CONSTANTS', 'RESOURCES', '$window',
    function($scope, CONSTANTS, RESOURCES, $window) {

      $scope.owu = RESOURCES.OWU || [];

			$scope.showOwu = function(link) {
				if(link){
					$window.open(link, '_blank');
				}
			};
    }
  ]);