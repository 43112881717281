angular.module('ihestiaSsoBase')
  .controller('forbiddenCtrl', ['$scope', 'ihestiaAgHelper', 'securityInitHelper', 'ihestiaConfigHelper', 'ihestiaCrossTabHelper', '$window',
    function($scope, ihestiaAgHelper, securityInitHelper, ihestiaConfigHelper, ihestiaCrossTabHelper, $window) {
      $scope.applicationErrorBodyTemplate = 'ihestia-sso-template-app/sso/forbidden/forbidden.tpl.html';

      $scope.canAccessPortal = ihestiaAgHelper.hasPermissionFor(ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_PORTAL'));
      $scope.canAccessJupiter = ihestiaAgHelper.hasPermissionFor(ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC'), 'AppPortalJupiter');

      $scope.messages = securityInitHelper.forbiddenMessages;

      /**
       * Przejście do portalu
       * @return {undefined}
       */
      $scope.gotoPortal = function() {
        ihestiaAgHelper.goToPortal();
      };

      $scope.logout = function() {
        ihestiaCrossTabHelper.sendMessage({
          code: 'sessionExpired'
        });
        $window.location = ihestiaConfigHelper.get('sso', 'LOGOUT_URL');
      };
    }
  ]);