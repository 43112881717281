angular.module('ihestiaGrid')
  .provider('ihestiaGridConfig', [
    function() {

      var provider = this;

      this.localOptions = {}; //możemy tu nadpisać lokalnie dla całej aplikacji domyślne options grida

      this.$get = [function(){
        return provider;
      }];
    }
  ]);