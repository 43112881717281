angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionPostCodeSectionCtrl', ['$scope', 'ihestiaRestDictionaryAddressesSvc', '$timeout',
    function($scope, ihestiaRestDictionaryAddressesSvc, $timeout) {

      /**
       * Dodajemy wiersz z kodem
       * @return {undefined}
       */
      $scope.addPostCode = function() {
        $timeout(function(){
          $scope.$broadcast('addTag');
        });
      };

      /**
       * Zamieniamy maskę na wildcard
       * @param  {String} postalCode wartość przed parsowaniem
       * @return {String}            postalCode przekazany do common-tag-list
       */
      $scope.postalCodeParser = function(postalCode) {
        if(postalCode === '__-___') {
          return '';
        } else {
          postalCode = postalCode.replace(/_/g, '');
          if(postalCode.indexOf('-') === 1) {
            postalCode = postalCode.replace(/-/g, '');
          }
          return postalCode;
        }
      };

      /**
       * Autocompleter kodów pocztowych
       * @param  {Srting} query częściowy kod
       * @return {Promise}
       */
      $scope.getPostalCodes = function(query) {
        return ihestiaRestDictionaryAddressesSvc.find({
          postalCode: query.replace(/_/g, '')
        }).then(function(res){
          if(res && res.data && angular.isObject(res.data.item) && angular.isObject(res.data.item.postalCodes) && angular.isArray(res.data.item.postalCodes.items)) {
            var items = [];
            angular.forEach(res.data.item.postalCodes.items, function(item){
              items.push({
                code: item,
                name: item
              });
            });
            return items;
          } else {
            return [];
          }
        }, angular.noop);
      };

    }
  ]);
