angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestVehicleDictionaryTarifficationModelsSvc', ['ihestiaRestAbstractVehicleDictionariesSvc',
    function(ihestiaRestAbstractVehicleDictionariesSvc) {

      var RestVehicleDictionaryTarifficationModelsSvc = function() {
        ihestiaRestAbstractVehicleDictionariesSvc.apply(this, arguments);

        this.id = 'TarifficationVehicle/models';

      };
      return new RestVehicleDictionaryTarifficationModelsSvc();
    }
  ]);