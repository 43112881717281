angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestVehicleDictionaryBrandsSvc', ['ihestiaRestAbstractVehicleDictionariesSvc',
    function(ihestiaRestAbstractVehicleDictionariesSvc) {

      var RestDictionaryVehicleSvc = function() {
        ihestiaRestAbstractVehicleDictionariesSvc.apply(this, arguments);

        this.id = 'brands';

      };
      return new RestDictionaryVehicleSvc();
    }
  ]);