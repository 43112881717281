angular.module('ihestiaCommonDirectives')
  .service('compressorJsConfigHelper', ['ihestiaConfigHelper',
    function(ihestiaConfigHelper) {
      var CompressorJsConfigHelper = function() {

            var config ={
                compressorjs: ihestiaConfigHelper.get('imageCompressor', 'enable') !== undefined ? ihestiaConfigHelper.get('imageCompressor', 'enable') : false
            };

            var compressorjsConfig = {};

            if(ihestiaConfigHelper.get('imageCompressor', 'enable') === true) {
                if(ihestiaConfigHelper.get('imageCompressor', 'quality') !== undefined) {
                    compressorjsConfig.quality = ihestiaConfigHelper.get('imageCompressor', 'quality');
                }

                if(ihestiaConfigHelper.get('imageCompressor', 'maxHeight') !== undefined) {
                    compressorjsConfig.maxHeight = ihestiaConfigHelper.get('imageCompressor', 'maxHeight');
                }

                if(ihestiaConfigHelper.get('imageCompressor', 'maxWidth') !== undefined) {
                    compressorjsConfig.maxWidth = ihestiaConfigHelper.get('imageCompressor', 'maxWidth');
                }

                if(ihestiaConfigHelper.get('imageCompressor', 'minFileSize') !== undefined) {
                    compressorjsConfig.minFileSize = ihestiaConfigHelper.get('imageCompressor', 'minFileSize');
                }
            }

            if(Object.keys(compressorjsConfig).length) {
                config.compressorjsConfig = compressorjsConfig;
            }

            return config;
        };

      return new CompressorJsConfigHelper();
    }
  ]);