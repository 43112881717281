angular.module('ihestiaRestServicesBase')

/**
 * pobieranie wydruków polis
 */
.service('policiesPrintoutsSvc', ['IHestiaRestServiceAbstractDef',
  function (IHestiaRestServiceAbstractDef)
{
  var PoliciesPrintouts = function () {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    var self = this;
    this.resource = 'policies';
    this.businessArea = 'printouts';
    this.port = '8208';
    this.path = 'RestPrintouts';

    this.printNow = function(id, data, subResource, callback, errorCallback, httpParams, symbols){
        self.id = id;
        var parseCallback = function(result)
        {
          if(angular.isFunction(callback))
          {
            callback(result);
          }
        };

        if(typeof httpParams === 'undefined' || httpParams === null)
        {
          httpParams = {};
        }

        httpParams.allowedStatuses = [404, 408];
        symbols = angular.isArray(symbols) ? symbols : [symbols];
        httpParams.url = self._getServiceUrl(id, 'symbols') + '/' + symbols;

        return self.get(id, data, subResource, parseCallback, errorCallback, httpParams);
      };

    this.getAvailablePrintoutTypes = function(policyNumber, callback) {
      self.get(policyNumber, null, null, callback);
    };

    this.getFileStream = function(id, data, subResource, lastQueryParam, callback, errorCallback, httpParams) {
          self.id = id;
          var parseCallback = function(result) {
            if (angular.isFunction(callback)) {
              callback(result);
            }
          };

          if (typeof httpParams === 'undefined' || httpParams === null) {
            httpParams = {};
          }

          httpParams.allowedStatuses = [404];
          httpParams.url = self._getServiceUrl(id, subResource) + (lastQueryParam ? ('/' + lastQueryParam) : '');

          return self.get(id, data, null, parseCallback, errorCallback, httpParams);
        };

  };
  return new PoliciesPrintouts();
}]);
