/**
 * Zapobiegacz zapętlaniu się ui-routera
 */
angular.module('uiRouterFreeze').factory('uiRouterFreezeHelper', ['$rootScope', '$state',
  function($rootScope, $state) {
    var UiRouterFreezeHelper = function() {

      /**
       * Init
       * @return {undefined}
       */
      this.init = function() {
        var corruptedStates = {}; //nie chcemy żeby się zapętlało

        $rootScope.$on('$stateChangeError', function(event, toState) {
          corruptedStates[toState.name] = true;
        });

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {

          if ((!fromState.name || fromState.name === toState.name) && corruptedStates[toState.name]) {
            event.preventDefault();
            if(!corruptedStates.uiRouterFreeze) {
              $rootScope.disableIHestiaMenu = true;
              $state.go('uiRouterFreeze');
            }
          }
        });

      };
    };

    return new UiRouterFreezeHelper();
  }]);