/**
 * Dyrektywa do wrzucenia szablonu navbara
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .directive('ihestiaMenuLeftNavbar', ['iHestiaMenuLeftNavbarHelper', '$document', 'iHestiaMenuLeftNavbarHelperV2', 'deprectedHelper',
    function(iHestiaMenuLeftNavbarHelper, $document, iHestiaMenuLeftNavbarHelperV2, deprectedHelper) {

      var directive = {
        restrict: 'E',
        replace: true,
        templateUrl: iHestiaMenuLeftNavbarHelper.settings.templateUrl
      };

      /**
       * komunikat o nowym menu
       */
      if(iHestiaMenuLeftNavbarHelperV2.mode === 'v1') {
        deprectedHelper.setAsDeprecated('ihestiaMenuLeftNavbar', 'ustaw iHestiaMenuLeftNavbarHelperV2Provider.mode = \'v2\', uwaga spory refaktor!');
        $document.on('click touchstart', function() {
          iHestiaMenuLeftNavbarHelper.hide();
        });
      } else {
        directive = iHestiaMenuLeftNavbarHelperV2.directiveSettings;
      }



      return directive;
    }
  ]);