angular.module('salesPath2')
        .directive('lsnPolicyGroup',
          function() {
            return {
              restrict: 'E',
              replace: true,
              scope: {
                policyGroup: '=',
                attachmentMode: '='
              },
              templateUrl: function(elem, attrs) {
                if (attrs.drvTemplate) {
                  return attrs.drvTemplate;
                } else {
                  return 'salesPath2Template/directives/policyGroup/policyGroup.tpl.html';
                }
              },
              controller: ['$scope', function($scope) {
                $scope.isProperty = false;

                if($scope.policiesType === 'property') {
                  $scope.isProperty = true;
                }
              }]
            };
          });