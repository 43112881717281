/**
 * orginalna biblioteka https://github.com/ivpusic/angular-cookie
 */
angular.module('lsnBase.bootstrap')
  .provider('lsnCookie', [
    function() {
      var LsnCookie = function() {
        var self = this;

        /**
         * [set ustawianie/dodawnie ciasteczka]
         * @param {string} key     [nazwa cookie]
         * @param {mix} value   [wartosc]
         * @param {object} options [opcje takie jak domena, sciezka, czas wygasniecia]
         */
        this.set = function(key, value, options) {
          options = angular.copy(options);
          var appConfig = window.APP_CONFIG; // eslint-disable-line no-undef
          options.secure = true;

          if(angular.isObject(appConfig) && angular.isObject(appConfig.frontFlags) && appConfig.frontFlags.ON_HTTP){
            options.secure = false;
          }

          var expiresFor;
          value = angular.isObject(value) ? JSON.stringify(value) : String(value);

          if (typeof options.expires === 'number') {
            expiresFor = options.expires;
            options.expires = new Date();
            // Trying to delete a cookie; set a date far in the past
            if (expiresFor === -1) {
              options.expires = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
              // A new
            } else if (angular.isDefined(options.expirationUnit)) {
              if (options.expirationUnit === 'hours') {
                options.expires.setHours(options.expires.getHours() + expiresFor);
              } else if (options.expirationUnit === 'minutes') {
                options.expires.setMinutes(options.expires.getMinutes() + expiresFor);
              } else if (options.expirationUnit === 'seconds') {
                options.expires.setSeconds(options.expires.getSeconds() + expiresFor);
              } else if (options.expirationUnit === 'milliseconds') {
                options.expires.setMilliseconds(options.expires.getMilliseconds() + expiresFor);
              } else {
                options.expires.setDate(options.expires.getDate() + expiresFor);
              }
            } else {
              options.expires.setDate(options.expires.getDate() + expiresFor);
            }
          }
          return (document.cookie = [
            encodeURIComponent(key),
            '=',
            encodeURIComponent(value),
            options.expires ? '; expires=' + options.expires.toUTCString() : '',
            options.path ? '; path=' + options.path : '',
            options.domain ? '; domain=' + options.domain : '',
            options.secure ? '; secure' : ''
          ].join(''));
        };

        /**
         * [get pobieranie ciasteczka]
         * @param  {[type]} key [nazwa ciastka -opcjonalny]
         * @return {mix}     [ciastko o podanej nazwie, badz wszystkie ciastka, gdy nazwy nie podano]
         */
        this.get = function(key) {
          var all = document.cookie,
            cookies = {},
            hasCookies = false,
            list = [];

          if (all) {
            list = all.split('; ');
          }

          for (var i = 0; i < list.length; ++i) {
            if (list[i]) {
              var cookie = list[i],
                pos = cookie.indexOf('='),
                name = cookie.substring(0, pos),
                value = decodeURIComponent(cookie.substring(pos + 1));

              if (angular.isUndefined(value)) {
                continue;
              }

              if (key === undefined || key === name) {
                try {
                  cookies[name] = JSON.parse(value);
                } catch (e) {
                  cookies[name] = value;
                }
                if (key === name) {
                  return cookies[name];
                }
                hasCookies = true;
              }
            }
          }
          if (hasCookies && angular.isUndefined(key)) {
            return cookies;
          }
        };

        /**
         * [remove usuwanie ciastka, badz zmiana opcja na ciastku]
         * @param  {string} key     [nazwa ciastka]
         * @param  {object} options [opcje takie jak domena, sciezka, czas wygasniecia]
         * @return {boolean}         [czy ciastko wogole zostalo znalezione]
         */
        this.remove = function(key, options) {
          var hasCookie = angular.isDefined(self.get(key));

          if (hasCookie) {
            if (!options) {
              options = {};
            }
            options.expires = -1;
            self.set(key, '', options);
          }
          return hasCookie;
        };
      };


      var lsnCookieInstance = new LsnCookie();

      /**
       * Metoda dostępna w sekcji .config
       * @return {ConfigService} [description]
       */
      this.getCookieHelper = function() {
        return lsnCookieInstance;
      };

      /**
       * get providera
       * @return {object} [description]
       */
      this.$get = [function() {
              return lsnCookieInstance;
            }];
    }
  ]);