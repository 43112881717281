angular.module('ihestiaGridSettings')
  .controller('ihestiaGridSettingsSalesNetworksCtrl', ['$scope', 'ihestiaGridSettingsHelper', '$timeout', 'ihestiaOssSalesprofileSvc', 'ihestiaOssWorkerSvc', '$filter', 'labelActionHelper',
    function ($scope, ihestiaGridSettingsHelper, $timeout, ihestiaOssSalesprofileSvc, ihestiaOssWorkerSvc, $filter, labelActionHelper) {
      $scope.defaultLocalLang = {};//ustawiany przez reloadLabels

      $scope.popover = {
        noAgencies: '', //ustawiany przez reloadLabels
        showNoAgencies: false
      };

      $scope.localLang = {
        organizationUnits: '',//ustawiany przez reloadLabels
        salesProfiles: '',//ustawiany przez reloadLabels
        workers: ''//ustawiany przez reloadLabels
      };

      $scope.guids = {
        organizationUnits: null,
        salesProfiles: null,
        workers: null
      };

      $scope.lastTextFilter = {
        organizationUnits: null,
        salesProfiles: null,
        workers: null
      };

      $scope.multiselectAttributes = {
        salesProfiles: {},
        workers: {}
      };

      $scope.init = function()
      {
        $scope.reloadLabels();
        labelActionHelper.registerAction('gridSettings', $scope.reloadLabels);
        $scope.$on('$destroy', function(){
          labelActionHelper.clearRegisteredAction('gridSettings', $scope.reloadLabels);
        });

        if($scope.salesNetworksConfig.organizationUnits.disabled && !$scope.salesNetworksConfig.salesProfiles.disabled)
        {
          $scope.loadSalesNetworkList('salesProfiles', {
            icOrganizationsUnitsKeysStrings: []
          });
        }
        $scope.prepareMultiselectAttributes();
      };

      $scope.reloadLabels = function()
      {
        $scope.popover.noAgencies = $filter('translate')('Public.chooseSalesProfile', {componentCode: 'Public'});

        $scope.defaultLocalLang.selectAll = $filter('translate')('Public.multiselectSelectAll', {componentCode: 'Public'});
        $scope.defaultLocalLang.selectNone = $filter('translate')('Public.multiselectSelectNone', {componentCode: 'Public'});
        $scope.defaultLocalLang.reset = $filter('translate')('Public.multiselectReset', {componentCode: 'Public'});
        $scope.defaultLocalLang.search = $filter('translate')('Public.multiselectSearch', {componentCode: 'Public'});
        $scope.defaultLocalLang.nothingSelected = $filter('translate')('Public.multiselectNothingSelected', {componentCode: 'Public'});
        $scope.defaultLocalLang.noOption = $filter('translate')('Public.multiselectNoOption', {componentCode: 'Public'});

        //nadpisywanie etykiet językowych jeśli zostaną podane w ustawieniach salesNetworksConfig.translation
        //@TODO - dodać nadpisywanie pozostałych etykiet
        if($scope.salesNetworksConfig.translation) {
          $scope.defaultLocalLang.nothingSelected = $scope.salesNetworksConfig.translation.nothingSelected;
        }

        $scope.localLang.organizationUnits = angular.copy($scope.defaultLocalLang);
        $scope.localLang.salesProfiles = angular.copy($scope.defaultLocalLang);
        $scope.localLang.workers = angular.copy($scope.defaultLocalLang);
      };


      $scope.prepareMultiselectAttributes = function()
      {
        if($scope.getSalesProfileRenderMode() === 'Search')
        {
          $scope.multiselectAttributes.salesProfiles.preserveSelected = 'true';
        }
        else
        {
          $scope.multiselectAttributes.salesProfiles.preserveSelected = 'false';
        }
        if($scope.getWorkersRenderMode() === 'Search')
        {
          $scope.multiselectAttributes.workers.preserveSelected = 'true';
        }
        else
        {
          $scope.multiselectAttributes.workers.preserveSelected = 'false';
        }
      };

      /**
       * wyszukiwanie sieci sprzedażowej po stronie usługi
       * @param  {string} type
       * @param  {string} textFilter
       */
      $scope.searchSalesNetwork = function(type, textFilter)
      {
        if($scope.guids[type] === null || textFilter === $scope.lastTextFilter[type])
        {
          return;
        }
        if(textFilter === null) //przerwaliśmy szukanie w celu odświeżenia zasobu a teraz wznawiamy
        {
           textFilter = $scope.lastTextFilter[type];
        }
        else
        {
          $scope.lastTextFilter[type] = textFilter;
        }

        var filters = {
          filter: textFilter,
          skip: 0,
          count: 50
        };

        if(type === 'salesProfiles')
        {
          if($scope.serviceRunning.SalesProfile)
          {
            return;
          }

          ihestiaOssSalesprofileSvc.get(ihestiaGridSettingsHelper.globalConfig.currentData.contextId, filters, 'searches/' + $scope.guids[type], function(response){
            $scope.parseSalesNetworkSearch(type, response.data.items);
          }, function(reject){
            if(reject.status === 404) //zasób wyszukiwania wygasł
            {
              //tworzymy nowy zasób wyszukiwania i ponawiamy wyszukiwanie
              $scope.loadSalesNetworkList('salesProfiles', {
                  icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter
                },
                function(){
                  $scope.searchSalesNetwork(type, null); //będziemy z $scope.lastTextFilter brać
                }
              );
            }
          },
          {allowedStatuses: [404]});
        }
        if(type === 'workers')
        {
          if($scope.serviceRunning.Worker)
          {
            return;
          }

          ihestiaOssWorkerSvc.get(ihestiaGridSettingsHelper.globalConfig.currentData.contextId, filters, 'searches/' + $scope.guids[type], function(response){
            $scope.parseSalesNetworkSearch(type, response.data.items);
          }, function(reject){
            if(reject.status === 404) //zasób wyszukiwania wygasł
            {
              //tworzymy nowy zasób wyszukiwania i ponawiamy wyszukiwanie
              $scope.loadSalesNetworkList('workers', {
                  icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter,
                  salesProfilesKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter
                },
                function(){
                  $scope.searchSalesNetwork(type, null); //będziemy z $scope.lastTextFilter brać
                }
              );
            }
          },
          {allowedStatuses: [404]});
        }
      };

      /**
       * parsujemy odpowiedź z usługi, zostawiamy zaznaczone wcześniej elementy
       * @param  {array} networks
       */
      $scope.parseSalesNetworkSearch = function(type, networks)
      {
        var newList = [];
        var selectedCodes = {};
        //czyścimy niezaznaczone dane
        angular.forEach(ihestiaGridSettingsHelper.globalConfig.salesNetworks[type], function(localNetwork){
          if(localNetwork.selected)
          {
            selectedCodes[localNetwork.subjectKeyString] = true; //oznaczamy jako już wybrany żeby wykluczyć go z listy wyszukiwania
            newList.push(localNetwork);
          }
        });

        angular.forEach(networks, function(searchedNetwork){
          if(typeof selectedCodes[searchedNetwork.subjectKeyString] === 'undefined')
          {
            newList.push(searchedNetwork); //element nie był zaznaczony, więc go dorzucamy do listy
          }
        });

        ihestiaGridSettingsHelper.globalConfig.salesNetworks[type] = newList;
        $scope.refreshGroups(type, false);
      };

      /**
       * walidacja usługą danych zachowanych dla list typu Search
       * @param  {string} type           typ sieci sprzedażowych
       * @param  {object} dynamicFilters zaznaczone nadrzędne elementy
       */
      $scope.validateSalesNetworks = function(type, dynamicFilters)
      {
        var filters = angular.copy(dynamicFilters);
        if(type === 'salesProfiles')
        {
          filters.salesProfilesKeysStrings = $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter;
          $scope.serviceRunning.SalesProfile = true;
          ihestiaOssSalesprofileSvc.post(ihestiaGridSettingsHelper.globalConfig.currentData.contextId, {
            dynamicFilters: filters
          }, 'validate', function(response){
            $scope.config.salesNetworks.salesProfiles = response.data.items;
            $scope.selectAgencies($scope.salesNetworksToSelect.subjectKeysSalesProfileFilter);

            $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
            $scope.serviceRunning.SalesProfile = false;
            $scope.refreshGroups('salesProfiles', false);
            $scope.checkIfFilterReady();
          }, function(){
            $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
            $scope.serviceRunning.SalesProfile = false;
            $scope.checkIfFilterReady();
          });
        }
        else if(type === 'workers' && !$scope.serviceRunning.Worker)
        {
          filters.workersSubjectKeysFilter = $scope.salesNetworksToSelect.subjectKeysWorkerFilter;
          $scope.serviceRunning.Worker = true;
          ihestiaOssWorkerSvc.post(ihestiaGridSettingsHelper.globalConfig.currentData.contextId, {
            dynamicFilters: filters
          }, 'validate', function(response){
            ihestiaGridSettingsHelper.globalConfig.salesNetworks.workers = response.data.items;
            $scope.selectWorkers($scope.salesNetworksToSelect.subjectKeysWorkerFilter);
            $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
            $scope.serviceRunning.Worker = false;
            $scope.refreshGroups('workers', false);
            $scope.checkIfFilterReady();
          }, function(){
            $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
            $scope.serviceRunning.Worker = false;
            $scope.checkIfFilterReady();
          });
        }
      };


      /**
       * pobiera listę danych do selecta
       * @return {[type]} [description]
       */
      $scope.loadSalesNetworkList = function(type, dynamicFilters, successCallback)
      {
        var count = 1000;
        $scope.guids[type] = null;

        if(type === 'salesProfiles')
        {
          var salesProfileRenderMode = $scope.getSalesProfileRenderMode();
          if(salesProfileRenderMode === 'Search')
          {
            count = 0;
          }

          $scope.serviceRunning.SalesProfile = true;
          ihestiaOssSalesprofileSvc.findByParams({
            id: ihestiaGridSettingsHelper.globalConfig.currentData.contextId,
            count: count,
            top: 1000,
            httpParams: {
              timeout: $scope.salesNetworksCancel.promise.then(angular.noop, angular.noop) //abort na promise
            },
            skip: 0,
            dynamicFilters: dynamicFilters,
            callback: function(response){
              $scope.serviceRunning.SalesProfile = false;
              if(salesProfileRenderMode === 'List' && response.data.moreThanTop)
              {
                salesProfileRenderMode = 'Search';
                $scope.config.salesNetworks.salesProfileToMany = true;
                $scope.prepareMultiselectAttributes();
              }

              if(salesProfileRenderMode === 'List')
              {
                ihestiaGridSettingsHelper.globalConfig.salesNetworks.salesProfiles = response.data.items;
                if($scope.salesNetworksToSelect.subjectKeysSalesProfileFilter !== null)
                {
                  $scope.selectAgencies($scope.salesNetworksToSelect.subjectKeysSalesProfileFilter);
                  $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
                }
                if( (!$scope.config.currentData || !$scope.config.currentData.disableAgencyAutoSelect) && !$scope.otherParams.loadingFilter )
                {
                  $scope.selectAgencyIfOne();
                }
                $scope.refreshGroups('salesProfiles', false);
                $scope.checkIfFilterReady();
              }
              else if(salesProfileRenderMode === 'Search')
              {
                $scope.guids[type] = response.data.id;
                if($scope.salesNetworksToSelect.subjectKeysSalesProfileFilter !== null)
                {
                  //walidujemy dane usługą
                  $scope.validateSalesNetworks('salesProfiles', dynamicFilters);
                }
                else
                {
                  $scope.checkIfFilterReady();
                }
              }
              else
              {
                $scope.checkIfFilterReady();
              }
              if(successCallback)
              {
                successCallback();
              }
            },
            errorCallback: function()
            {
              $scope.serviceRunning.SalesProfile = false;
              $scope.$emit('hideBlockUi');
            }
          });

        }
        else if(type === 'workers')
        {
          var workersRenderMode = $scope.getWorkersRenderMode();
          if(workersRenderMode === 'Search')
          {
            count = 0;
          }

          $scope.serviceRunning.Worker = true;
          ihestiaOssWorkerSvc.findByParams({
            id: ihestiaGridSettingsHelper.globalConfig.currentData.contextId,
            count: count,
            top: 1000,
            skip: 0,
            httpParams: {
              timeout: $scope.salesNetworksCancel.promise.then(angular.noop, angular.noop) //abort na promise
            },
            dynamicFilters: dynamicFilters,
            callback: function(response){
              $scope.serviceRunning.Worker = false;

              if(workersRenderMode === 'List' && response.data.moreThanTop) //jak mamy za dużo pracowników to przestawiamy się w tryb wyszukiwania
              {
                workersRenderMode = 'Search';
                $scope.config.salesNetworks.workersToMany = true;
                $scope.prepareMultiselectAttributes();
              }

              if(workersRenderMode === 'List')
              {
                ihestiaGridSettingsHelper.globalConfig.salesNetworks.workers = response.data.items;
                if($scope.salesNetworksToSelect.subjectKeysWorkerFilter !== null)
                {
                  $scope.selectWorkers($scope.salesNetworksToSelect.subjectKeysWorkerFilter);
                  $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
                }
                $scope.refreshGroups('workers', false);
                $scope.checkIfFilterReady();
              }
              else if(workersRenderMode === 'Search')
              {
                $scope.guids[type] = response.data.id;
                if($scope.salesNetworksToSelect.subjectKeysWorkerFilter !== null)
                {
                  $scope.validateSalesNetworks('workers', dynamicFilters);
                }
                else
                {
                  $scope.checkIfFilterReady();
                }
              }
              else
              {
                $scope.checkIfFilterReady();
              }
              if(successCallback)
              {
                successCallback();
              }
            },
            errorCallback: function()
            {
              $scope.serviceRunning.Worker = false;
              $scope.$emit('hideBlockUi');
            }
          });
        }
      };

      $scope.getSalesProfileRenderMode = function()
      {
        var salesProfileRenderMode = $scope.config.salesNetworks.salesProfileUiConfig.renderMode;
        if(salesProfileRenderMode === 'List' && $scope.config.salesNetworks.salesProfileToMany)
        {
          salesProfileRenderMode = 'Search';
        }
        return salesProfileRenderMode;
      };

      $scope.getWorkersRenderMode = function()
      {
        var workersRenderMode = $scope.config.salesNetworks.workerUiConfig.renderMode;
        if(workersRenderMode === 'List' && ($scope.config.salesNetworks.workersToMany || ($scope.salesNetworksConfig.salesProfiles.canEmpty && $scope.currentFilter.settings.filterSettings.salesProfiles.length === 0)))
        {
          // Jeśli pozwalamy na pobranie pracowników bez agencji i nie mamy żadnych agencji to przełączamy się w tryb wyszukiwania
          workersRenderMode = 'Search';
        }
        return workersRenderMode;
      };

      $scope.$on('selectOrganizations', function(){
        if($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter !== null) //przy zmianie filtra jeśli te same organizacje to czyścimy na watchu
        {
          $scope.selectOrganizations($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter);
          $scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter = null;
        }
        $scope.checkIfFilterReady();
      });

      /**
       * zaznaczamy organizacje w multiselect
       * @param  {array} codes
       */
      $scope.selectOrganizations = function(codes)
      {
        //odznaczamy wszystkie
        angular.forEach($scope.config.salesNetworks.organizationUnits, function(value){
          value.selected = false;
        });

        var valueFound = false;
        angular.forEach(codes, function(code){
          angular.forEach($scope.config.salesNetworks.organizationUnits, function(value){
            if(value.subjectKeyString === code)
            {
              value.selected = true;
              valueFound = true;
            }
          });
        });
        if(!valueFound)
        {
          //nie ma co zaznaczyć więc wywalamy zaznaczenia poniższe
          $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
          $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
        }
      };

      /**
       * jeśli nie ma zaznaczonej agencji a jest tylko jedna to ją zaznaczamy
       */
      $scope.selectAgencyIfOne = function()
      {
        if($scope.config.salesNetworks.salesProfiles.length === 1)
        {
          $scope.config.salesNetworks.salesProfiles[0].selected = true;
        }
      };

      /**
       * zaznaczamy agencje w multiselect
       * @param  {array} codes
       */
      $scope.selectAgencies = function(codes)
      {
        var valueFound = false;
        angular.forEach(codes, function(code){
          angular.forEach($scope.config.salesNetworks.salesProfiles, function(value){
            if(value.subjectKeyString === code)
            {
              value.selected = true;
              valueFound = true;
            }
          });
        });
        if(!valueFound)
        {
          //nie ma co zaznaczyć więc wywalamy zaznaczenia poniższe
          $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
        }
      };

      /**
       * zaznaczamy pracowników w multiselect
       * @param  {array} codes
       */
      $scope.selectWorkers = function(codes)
      {
        angular.forEach(codes, function(code){
          angular.forEach($scope.config.salesNetworks.workers, function(value){
            if(value.subjectKeyString === code)
            {
              value.selected = true;
            }
          });
        });
      };

      $scope.agencySelectOpened = function(){
        $scope.popover.showNoAgencies = false;
      };

      $scope.$on('gridSettingsInvalid', function(event, context, validData){
        if(validData.error.subjectKeysSalesProfileFilter)
        {
          $scope.popover.showNoAgencies = true;
        }
      });

      $scope.getKeysOfSelected = function(elements)
      {
        var selected = {};
        angular.forEach(elements, function(element){
          if(element.selected)
          {
            selected[element.subjectKeyString] = true;
          }
        });
        return selected;
      };

      $scope.salesNetworksSelectionEquals = function(firstItems, secondItems)
      {
        var selectionIsEqual = true;
        var firstSelectedKeys = [];
        var secondSelectedKeys = [];
        angular.forEach(firstItems, function(item){
          if(item.selected)
          {
            firstSelectedKeys.push(item.subjectKeyString);
          }
        });
        angular.forEach(secondItems, function(item){
          if(item.selected)
          {
            secondSelectedKeys.push(item.subjectKeyString);
          }
        });
        if(firstSelectedKeys.length !== secondSelectedKeys.length)
        {
          selectionIsEqual = false;
        }
        else
        {
          angular.forEach(firstSelectedKeys, function(firstKey){
            if(secondSelectedKeys.indexOf(firstKey) === -1)
            {
              selectionIsEqual = false;
            }
          });
        }
        return selectionIsEqual;
      };

      //zmiana zaznaczenia przedstawicielstwa
      $scope.$watch('currentFilter.settings.filterSettings.organizationUnits', function(newValue, oldValue){
        if(angular.isArray(newValue) && (!$scope.salesNetworksSelectionEquals(newValue, oldValue) || $scope.salesNetworksToReload.organizationUnits))
        {
          //sprawdzamy czy zmieniło się zaznaczenie
          var selectionChanged = !angular.equals($scope.getKeysOfSelected(newValue), $scope.getKeysOfSelected(oldValue));
          if($scope.salesNetworksToReload.organizationUnits)
          {
            selectionChanged = true; //jak pierwszy raz albo zmiana filtra to chcemy wszystko załadować
            $scope.salesNetworksToReload.organizationUnits = false;
          }

          if(selectionChanged)
          {
            //czyścimy wartości z niższych poziomów o ile są zmienne
            if(typeof $scope.config.salesNetworks.salesProfileUiConfig !== 'undefined' && $scope.config.salesNetworks.salesProfileUiConfig.renderMode !== 'Hide' && $scope.config.salesNetworks.salesProfileUiConfig.renderMode !== 'Locked')
            {
              $scope.config.salesNetworks.salesProfiles = [];
            }
            if(typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' && $scope.config.salesNetworks.workerUiConfig.renderMode !== 'Hide' && $scope.config.salesNetworks.workerUiConfig.renderMode !== 'Locked')
            {
              $scope.config.salesNetworks.workers = [];
              if($scope.config.salesNetworks.workersToMany)
              {
                $scope.config.salesNetworks.workersToMany = false;
                $scope.prepareMultiselectAttributes();
              }
            }
          }

          if($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter !== null) //czyścimy te które już mamy zaznaczone (zachodzi przy zmianie filtra)
          {
            var selectedOrganizations = $scope.getKeysOfSelected(newValue);
            var newList = [];
            angular.forEach(angular.copy($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter), function(subjectKeyString){
              if(typeof selectedOrganizations[subjectKeyString] === 'undefined')
              {
                newList.push(subjectKeyString);
              }
            });
            if(newList.length === 0)
            {
              $scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter = null;
            }

          }


          $scope.salesNetworksObjectsToSymbols();

          if($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter !== null)//jak są rzeczy do zaznaczenia to na razie nic nie robimy
          {
            return;
          }

          if(selectionChanged && newValue.length > 0 && typeof $scope.config.salesNetworks.salesProfileUiConfig !== 'undefined' &&
            ($scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'List' || $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Search'))
          {
            $scope.loadSalesNetworkList('salesProfiles', {
              icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter
            });
          }
          else if(selectionChanged && typeof $scope.config.salesNetworks.salesProfileUiConfig !== 'undefined' &&
            newValue.length === 0 &&
            ($scope.getSalesProfileRenderMode() === 'Search' || $scope.salesNetworksConfig.organizationUnits.canEmpty))
          {
            //przygotowujemy zasób wyszukiwania, być może mamy też zachowane agencje ale bez listy organizacji
            $scope.loadSalesNetworkList('salesProfiles', {});
          }

          //jak mamy wyszukiwanie pracowników bez agencji to przeładowujemy zasób wyszukiwania pracowników przy zmianach w przedstawicielwstiwe
          if(selectionChanged && typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' &&
              (($scope.salesNetworksConfig.salesProfiles.canEmpty &&
              $scope.currentFilter.settings.filterSettings.salesProfiles.length === 0) || //mogą być puste i są puste agencje
               $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Locked' || //mamy zablokowaną agencję
              $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Hide'  //mamy ukrytą agencję
              ) &&
            ($scope.config.salesNetworks.workerUiConfig.renderMode === 'Search' || $scope.config.salesNetworks.workerUiConfig.renderMode === 'List'))
          {
            var dynamicFilters = {
              icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter
            };

            if($scope.currentFilter.settings.filterSettings.salesProfiles.length > 0)
            {
              dynamicFilters.salesProfilesKeysStrings = $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter;
            }

            if($scope.serviceRunning.SalesProfile)
            {
              $scope.salesNetworksToReload.salesProfiles = true; //chcemy pobrania listy pracowników, więc oznaczamy agnecje do odświeżenia
            }
            else
            {
              //przygotowujemy zasób wyszukiwania, być może mamy też zachowane agencje ale bez listy organizacji
              $scope.loadSalesNetworkList('workers', dynamicFilters);
            }
          }

          //grupy
          if(typeof $scope.config.salesNetworks.organizationUnitUiConfig !== 'undefined' && $scope.config.salesNetworks.organizationUnitUiConfig.renderMode === 'List')
          {
            $scope.refreshGroups('organizationUnits');
          }
        }
      });

      $scope.$watch('currentFilter.settings.filterSettings.salesProfiles', function(newValue, oldValue){
        if(angular.isArray(newValue) && (!$scope.salesNetworksSelectionEquals(newValue, oldValue) || $scope.salesNetworksToReload.salesProfiles))
        {
          //sprawdzamy czy zmieniło się zaznaczenie
          var selectionChanged = !angular.equals($scope.getKeysOfSelected(newValue), $scope.getKeysOfSelected(oldValue));
          if($scope.salesNetworksToReload.salesProfiles)
          {
            selectionChanged = true; //jak pierwszy raz albo zmiana filtra to chcemy wszystko załadować
            $scope.salesNetworksToReload.salesProfiles = false;
          }

          if(selectionChanged)
          {
            $scope.prepareMultiselectAttributes(); //po zmianie zaznaczenia agencji może się zmienić tryb selecta dla workera
          }

          //czyścimy wartości z niższych poziomów o ile są zmienne
          if(selectionChanged && typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' && $scope.config.salesNetworks.workerUiConfig.renderMode !== 'Hide' && $scope.config.salesNetworks.workerUiConfig.renderMode !== 'Locked')
          {
            $scope.config.salesNetworks.workers = [];
            if($scope.config.salesNetworks.workersToMany)
            {
              $scope.config.salesNetworks.workersToMany = false;
              $scope.prepareMultiselectAttributes();
            }
          }
          $scope.salesNetworksObjectsToSymbols();
          if(newValue.length > 0)
          {
            $scope.popover.showNoAgencies = false;
            if(selectionChanged && typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' && !$scope.salesNetworksConfig.workers.disabled &&
             ($scope.config.salesNetworks.workerUiConfig.renderMode === 'List' || $scope.config.salesNetworks.workerUiConfig.renderMode === 'Search'))
            {
              $scope.loadSalesNetworkList('workers', {
                icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter,
                salesProfilesKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter
              });
            }
          }
          else if(selectionChanged && typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' &&
            newValue.length === 0 &&
            ($scope.config.salesNetworks.workerUiConfig.renderMode === 'Search' || $scope.salesNetworksConfig.salesProfiles.canEmpty))
          {
            if($scope.serviceRunning.SalesProfile)
            {
              $scope.salesNetworksToReload.salesProfiles = true; //chcemy pobrania listy pracowników, więc oznaczamy agnecje do odświeżenia
            }
            else
            {
              //przygotowujemy zasób wyszukiwania, być może mamy też zachowane agencje ale bez listy organizacji
              $scope.loadSalesNetworkList('workers', {
                icOrganizationsUnitsKeysStrings: $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter
              });
            }
          }

          //grupy
          if(typeof $scope.config.salesNetworks.salesProfileUiConfig !== 'undefined' &&
            ($scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'List' || $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Search'))
          {
            $scope.refreshGroups('salesProfiles');
          }
        }
      });

      $scope.$watch('currentFilter.settings.filterSettings.workers', function(newValue, oldValue){
        if(angular.isArray(newValue) && !$scope.salesNetworksSelectionEquals(newValue, oldValue))
        {
          $scope.salesNetworksObjectsToSymbols();
          //grupy
          if(typeof $scope.config.salesNetworks.workerUiConfig !== 'undefined' &&
            ($scope.config.salesNetworks.workerUiConfig.renderMode === 'List' || $scope.config.salesNetworks.workerUiConfig.renderMode === 'Search'))
          {
            $scope.refreshGroups('workers');
          }
        }
      });

      /**
       * ustawiamy w inputModel wartości selected
       * @param  {string} name
       * @param  {bool} userChange //czy przegenerowanie grup nastąpiło w wyniku interakcji użytkownika z selectem
       */
      $scope.refreshGroups = function(name, userChange) //eslint-disable-line consistent-return
      {
        if($scope.salesNetworksConfig[name].singleValue) //jak można tylko jedną wartość wybrać to nie chcemy grupowania
        {
          return false;
        }

        if(typeof userChange === 'undefined')
        {
          userChange = true;
        }
        var baseList = $scope.config.salesNetworks[name];
        if(!angular.isArray(baseList))
        {
          return false;
        }
        angular.forEach(baseList, function(item){ //eslint-disable-line consistent-return
          if(typeof item.group !== 'undefined')
          {
            return false;
          }

          //dyrektywa nie zapewnia atrybutu selected w danych źródłowych
          var itemIsSelected = false;
          if(userChange)
          {
            angular.forEach($scope.currentFilter.settings.filterSettings[name], function(selectedItem){
              if(selectedItem.subjectKeyString === item.subjectKeyString && selectedItem.selected)
              {
                itemIsSelected = true;
              }
            });
          }
          else if(item.selected) //zmiana z poziomu kodu, więc mamy zaznaczenie w liście źródłowej
          {
            itemIsSelected = true;
          }


          if(itemIsSelected)
          {
            item.selected = true; //oznaczamy elementy
          }
          else
          {
            if(typeof item.selected !== 'undefined')
            {
              item.selected = false;
            }
          }
        });
      };

      /**
       * zmieniła się fraza wyszukiwania na agencji
       * @param  {[type]} data [description]
       * @return {[type]}      [description]
       */
      $scope.agencySearchChanged = function(data)
      {
        if($scope.getSalesProfileRenderMode() === 'Search')
        {
          if(data.keyword.length > 2)
          {
            $scope.searchSalesNetwork('salesProfiles', data.keyword);
          }
          else
          {
            $scope.parseSalesNetworkSearch('salesProfiles', []); //tylko czyścimy niezaznaczone elementy
          }
        }
      };

      /**
       * zmieniła się fraza wyszukiwania na pracowniku
       * @param  {[type]} data [description]
       * @return {[type]}      [description]
       */
      $scope.workerSearchChanged = function(data)
      {
        if($scope.getWorkersRenderMode() === 'Search')
        {
          if(data.keyword.length > 2)
          {
            $scope.searchSalesNetwork('workers', data.keyword);
          }
          else
          {
            $scope.parseSalesNetworkSearch('workers', []); //tylko czyścimy niezaznaczone elementy
          }
        }
      };

      $scope.init();
    }
  ]);
