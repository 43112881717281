angular.module('lsnBase.models')
.factory('LsnCompositeSubjectSelectConfigModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnCompositeSubjectSelectConfigModelV1 = function()
    {
      this.objectName = 'CompositeSubjectSelectConfig';

      this.fieldsProperties = {
        organizationUnitUiConfig: {
          type: 'SubjectSelectUiConfig'
        },
        salesProfileUiConfig: {
          type: 'SubjectSelectUiConfig'
        },
        workerUiConfig: {
          type: 'SubjectSelectUiConfig'
        },
        defaultOrganizationUnits: {
          type: 'IcOrganizationUnit'
        },
        defaultSalesProfile: {
          type: 'SalesProfile'
        },
        defaultWorker: {
          type: 'Worker'
        },
        organizationUnits:
        {
          type: 'array',
          elementsType: 'IcOrganizationUnit'
        },
        salesProfiles:
        {
          type: 'array',
          elementsType: 'SalesProfile'
        },
        workers:
        {
          type: 'array',
          elementsType: 'Worker'
        }
      };

      this.organizationUnitUiConfig = null; // SubjectSelectUiConfig Obiekt konfiguracji dla kontrolki prezentującej jednostki organizacyjne.
      this.salesProfileUiConfig = null; // SubjectSelectUiConfig Obiekt konfiguracji dla kontrolki prezentującej profile sprzedażowe.
      this.workerUiConfig = null; // SubjectSelectUiConfig Obiekt konfiguracji dla kontrolki prezentującej pracowników.
      this.defaultOrganizationUnits = null; // IcOrganizationUnit Obiekt jednostki organizacyjnej do zaprezentowania jako wartość domyślna.
      this.defaultSalesProfile = null; // SalesProfile Obiekt profilu sprzedażowego do zaprezentowania jako wartość domyślna.
      this.defaultWorker = null; // Worker Obiekt pracownika do zaprezentowania jako wartość domyślna.
      this.organizationUnits = null; // Array[IcOrganizationUnit] Lista jednostek organizacyjnych jako możliwy wybór w kontrolce.
      this.salesProfiles = null; // Array[SalesProfile] Lista profili sprzedażowych możliwy wybór w kontrolce.
      this.workers = null; // Array[Worker] Lista pracowników jako możliwy wybór w kontrolce.
    };

    LsnCompositeSubjectSelectConfigModelV1.prototype = LsnAbstractModel.prototype;

    return LsnCompositeSubjectSelectConfigModelV1;
  }]);