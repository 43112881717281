angular.module('ihestiaRestServicesBase')
  .factory('accountActivationSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var AccountActivationSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        var self = this;

        this.resource = 'customer';
        this.businessArea = 'authentication';

        /**
         * Inicalizacja aktywacji
         * @param  {object} data wg dokumentu tech.
         * @return {Promise}
         */
        this.initActivation = function(data) {
          return self.post('AccountInitActivation', data, null, null, null, {allowedStatuses: [409]});
        };

        /**
         * Właściwa aktywacja konta
         * @param  {object} data wg dokumentu tech.
         * @return {Promise}
         */
        this.activateAccount = function(data) {
          return self.post('AccountActivation', data, null, null, null, {allowedStatuses: [409]});
        };
      };

      return new AccountActivationSvc();
    }]);
