angular.module('salesPath2')
  .controller('sp2MenuBasketCtrl', ['$scope', '$timeout', 'applicationHelper', 'basketHelper', 'actionHelper',
    function($scope, $timeout, applicationHelper, basketHelper, actionHelper) {

      $scope.basketData = basketHelper.tplData.menuBar;

      $scope.basketSpinnerOptions = {
        size: 'sm',
        label: ''
      };

      $scope.init = function() {
        basketHelper.refreshData();
      };

      /**
       * wyswietla kontener koszyka
       */
      $scope.showBasketContainer = function() {
        $timeout(function() {
          $scope.appVariables.contentPulledLeft = true;
          $scope.appVariables.basketContainerVisible = true;
          basketHelper.refreshBasketContentData();
          actionHelper.runAction('basketOpened');
        }, 0);
      };

      $scope.init();

    }
  ]);