/* eslint-disable no-extend-native */
if (!String.prototype.capitalize) {
  /**
   * Zamienia string na pisany wielką literą
   * @return {[type]} [description]
   */
  String.prototype.capitalize = function() {
    var inputValue = this,
      capitalized = inputValue.replace(/[\wżźćńółęąśŻŹĆĄŚĘŁÓŃ]*/g, function(txt) { // wcześniejszy regexp "/([^\W_]+[\s-]*) */g" nie łapał polskiich znaków
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });

    return capitalized;
  };
}

if (!String.prototype.capitalizeFirstLetter) {
  String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
}