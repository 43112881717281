angular.module('salesPath2')
  .factory('MapperUtils', ['mainDataContainer', 'SPD', 'sp2CommonHelper', 'CONSTANTS', 'RESOURCES', 'CONFIG', 'base64', 'resourceHelper', 'sp2SelectionHelper', 'dataContainerHelper',
    function(mainDataContainer, SPD, sp2CommonHelper, CONSTANTS, RESOURCES, CONFIG, base64, resourceHelper, selectionHelper, dataContainerHelper) { // eslint-disable-line angular/di

      var MapperUtils = {
        /**
         * mapping atrybutow dynamicznych wniosku na pola dataContainera
         * pole_dc: dyn_attr
         */
        applicationDynamicValuesMap: {
          selectedVariants: '_selectedVariants',
          selectedAdditions: '_selectedAdditions',
          selectedExtensions: '_selectedExtensions',
          suList: '_suList',
          oczpProtectionDates: '_oczpProtectionDates',
          nnwProtectionDates: '_nnwProtectionDates',
          propertyProtectionDates: '_propertyProtectionDates',
          nnwIncognito: '_nnwIncognito',
          defaultStartDate: '_defaultStartDate',
          defaultEndDate: '_defaultEndDate',
          greenCardNumber: '_greenCardNumber',
          renewedApplication: '_renewedApplication',
          protectionPeriodCode: '_protectionPeriodCode',
          protectionStartTime: '_protectionStartTime',
          individualDiscountInputValue: '_individualDiscountInputValue'
        },
        /**
         * produkty majątkowe
         * @type {Array}
         */
        propertyProducts: resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_LOCALIZATION),
        /**
         * produkty osobowe
         * @type {Array}
         */
        personalProducts: resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_PERSON).concat([CONSTANTS.PRODUCT_NNW_INCOGNITO]),
        /**
         * produkty komunikacyjne
         * @type {Array}
         */
        communicationProducts: resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_VEHICLE),
        /**
         * produkty typu osoba-grupa
         * @type {Array}
         */
        personGroupProducts: resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP),
        /**
         * nazwy atrybutów dataContainera bedace obiektami XDate
         * @type Array
         */
        xDateProperties: ['defaultStartDate', 'defaultEndDate'],
        /**
         * tymczasowe dynamicValues (te z prfiksem "_"), któeych nie czyscimy przy mapowaniu
         * @type {Object} klasa_obiektu: ['atr1'[, ...]] //tablica z nazwamy atrybutów
         */
        dynamicValuesToPreserve: {
          Organization: ['_cessionReenter'],
          Person: ['_OCBMmode', '_ACBMmode', '_OCPreviousPolicyNumber', '_ACPreviousPolicyNumber', '_OCNoClaimInsuranceContinuation', '_ACNoClaimInsuranceContinuation'],
          Vehicle: ['_hasNoVin', '_hasCoowners', '_vehicleValueEtxSelect'],
          Estate: ['_hasCoowners']
        },
        /**
         * wyszukuje definicje ryzyka w SPD
         * parametry estateType, variant są opcjonalne
         * UWAGA
         * bliźniaczy mechanizm jest dostępny w dcAllowedHelper.findAllowedRisk
         * @param {string} prodType
         * @param {string} riskCode
         * @param {string} estateType
         * @param {string} variant
         * @param {(int|null)} profession
         * @returns {object}
         */
        findRiskDef: function(prodType, riskCode, estateType, variant, profession) {
          if (CONFIG.BEHAVIOR.defualtRiskProfession !== null && parseInt(profession, 10) === -1) {
            profession = CONFIG.BEHAVIOR.defualtRiskProfession; //chcemy coś wysyłać (jakieś ryzyko) dla braku profesji
          }
          profession = profession ? parseInt(profession, 10) : null;
          var riskDef = null,
            match = false;
          lsnNg.forEach(SPD.risks, function(data) {
            if (data.productCode === prodType && data.riskCode === riskCode) {
              match = true;
              if (estateType && data.estateType && (angular.isArray(data.estateType) ? (data.estateType.indexOf(estateType) === -1) : (data.estateType !== estateType))) { //estateType moze byc stringiem lub tablica
                match = false;
              }
              if (variant && data.variant && data.variant.indexOf(variant) === -1) {
                match = false;
              }
              if (profession && data.profession && data.profession.indexOf(profession) === -1) {
                match = false;
              }
              if (match) {
                riskDef = data;
                return false;
              }
            }
            return true; //continue
          });
          if (!riskDef) {
            sp2CommonHelper.throwException('No risk definition found for risk with prodType: {0}, code: {1}, estateType: {2}, variant: {3}, profession: {4}'.format(prodType, riskCode, estateType ? estateType : 'n/a', variant ? variant : 'n/a', profession ? profession : 'n/a'));
          }
          return riskDef;
        },
        /**
         * zwraca obiekt definicji ryzyka z SPD
         * @param {string} idpm
         * @returns {Object}
         */
        findRiskDefByIdpm: function(idpm) {
          if (typeof SPD.risks[idpm] === 'undefined') {
            sp2CommonHelper.throwException('No risk definition found for risk with idpm: {0}.'.format(idpm));
          }
          return SPD.risks[idpm];
        },
        /**
         * zwraca definicje produktu (ryzyka) z RESOURCES
         * @param {string} prodCode
         * @returns {object}
         */
        getProductDef: function(prodCode) {
          var def = null;
          if (prodCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
            prodCode = CONSTANTS.PRODUCT_NNW;
          }
          lsnNg.forEach(RESOURCES.PRODUCTLIST, function(pDef) {
            if (pDef.CODE === prodCode) {
              def = pDef;
              return false;
            }
            return true; //continue
          });
          if (def === null) {
            sp2CommonHelper.throwException('No product definition found in RESOURCES for product code: {0}'.format(prodCode));
          }
          return def;
        },
        /**
         * zwraca wariant ryzyka dla HCA AC
         * @param {string} assisOpt assistanceOption
         * @returns {string}
         */
        getHCAVariantFromAssistanceOption: function(assisOpt) {
          assisOpt = parseInt(assisOpt, 10);
          var variant = null;
          lsnNg.forEach(CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP, function(opt, hcaVar) {
            if (opt === assisOpt) {
              variant = hcaVar;
              return false;
            }
            return true; //continue
          });
          if (variant === null) {
            sp2CommonHelper.throwException('No HCA variant mapping for assistanceOption {0}.'.format(assisOpt));
          }
          return variant;
        },
        /**
         * zwraca pierwszy element z obiektu
         * @param {object} container
         * @returns {*}
         */
        getFirstItemFromObject: function(container) {
          if (!angular.isObject(container) || container === null) {
            return null;
          }
          var obj = null;
          lsnNg.forEach(container, function(value) {
            obj = value;
            return false;
          });
          return obj;
        },
        /**
         * czy ryzyko o podanym kodzie jest dodatkiem
         * @param {string} riskCode
         * @returns {Boolean}
         */
        isAddition: function(riskCode) {
          return angular.isDefined(RESOURCES.SELECTED_ADDITIONS[riskCode]);
        },
        /**
         * zwraca definicję dodatku
         * @param {string} addCode
         * @returns {object}
         */
        getAdditionDefinition: function(addCode) {
          return resourceHelper.getAddDef(addCode);
        },
        /**
         * zwraca id (restowe) na podstawie clientId z podanego kontenera z mapowaniami
         * @param {object} container
         * @param {string} clientId
         * @returns {(string|null)} id lub null gdy nie znaleziono
         */
        getIdByClientId: function(container, clientId) {
          if (!angular.isObject(container)) {
            return null;
          }
          var id = null;
          lsnNg.forEach(container, function(cId, rId) {
            if (clientId === cId) {
              id = rId;
              return false;
            }
            return true; //continue
          });
          return id;
        },
        /**
         * zwraca clientId obiektu na podstawie id z kontenera z mapowaniami
         * @param {object} container
         * @param {string} id
         * @returns {(string|null)} clientId lub null gdy nie znaleziono
         */
        getClientIdById: function(container, id) {
          if (!angular.isObject(container) || angular.isUndefined(container[id])) {
            return null;
          }
          return container[id];
        },
        /**
         * zwraca sformatowaną datę ochrony dla dataContainera
         * @param {string} date
         * @returns {string}
         */
        getFormattedJSProtectionDate: function(date) {
          return MapperUtils.isProperProtectionDate(date) ? new XDate(date).toString('yyyy-MM-dd') : null;
        },
        /**
         * serializuje wartość
         * @param {*} val
         * @returns {string}
         */
        serializeValue: function(val) {
          return base64.encode(angular.toJson(val));
        },
        /**
         * odserializowuje wartość
         * @param {string} val
         * @returns {JSON.parse.e|JSON.parse.j|Array|Object}
         */
        unserializeValue: function(val) {
          return angular.fromJson(base64.decode(val));
        },
        /**
         * konwertuje wartość z wniosku ubezpieczeniowego na poprawny typ wartosci z dataContainera
         * @param {string} propName
         * @param {*} value
         * @returns {*|XDate}
         */
        convertToDataContainerProperty: function(propName, value) {
          var returnValue = value;
          if (MapperUtils.xDateProperties.indexOf(propName) !== -1) {
            returnValue = new XDate(value);
          }
          return returnValue;
        },
        /**
         * konwertuje wartość z dataContainera na wartosc gotową do serializowania
         * @param {string} propName
         * @param {*} value
         * @returns {*}
         */
        convertFromdataContainerProperty: function(propName, value) {
          var returnValue = value;
          if (MapperUtils.xDateProperties.indexOf(propName) !== -1 && value instanceof XDate) {
            returnValue = value.toString('yyyy-MM-dd');
          }
          return returnValue;
        },
        /**
         * zwraca RESTowy kod specjalizacji - zwodu
         * @param {string|int} guiCode
         * @returns {int}
         */
        getProfessionCode: function(guiCode) {
          if (guiCode === null) {
            return null;
          }
          return parseInt(guiCode, 10);
        },
        /**
         * czy ryzyko majątkowe jest dostępne w danym wariancie
         * do rozbudowy w ramach potrzeby
         * @param {string} riskCode
         * @param {string} variant
         * @returns {undefined}
         */
        isPropertyRiskAvailableInVariant: function() {
          return true;
        },
        /**
         * czy dodatek wybrany na ścieżce
         * @param {string} code
         * @param {*} objectId
         * @param {string} variant
         * @returns {boolean}
         */
        isAdditionSelected: function(code, objectId, variant) {
          return selectionHelper.isAddSelected(code, parseInt(objectId, 10), variant);
        },
        /**
         * ustawia clientId dla obiektów/podmiotów
         * @param {Object} globalContainer
         */
        setClientIdsForObjects: function(globalContainer) {
          var containers = {
              'estates': CONSTANTS.PRODUCT_TYPE_LOCALIZATION,
              'persons': CONSTANTS.PRODUCT_TYPE_PERSON,
              'organizations': CONSTANTS.PRODUCT_TYPE_PERSON,
              'vehicles': CONSTANTS.PRODUCT_TYPE_VEHICLE,
              'groups': CONSTANTS.PRODUCT_TYPE_PERSON_GROUP
            },
            dmContainers = {
              'estates': 'localizations',
              'persons': 'persons',
              'organizations': 'organizations',
              'vehicles': 'vehicles',
              'groups': 'groups'
            };
          lsnNg.forEach(containers, function(prodType, container) {
            if (angular.isUndefined(globalContainer[container]) || !angular.isArray(globalContainer[container])) {
              return true;
            }
            lsnNg.forEach(globalContainer[container], function(obj) {
              if (obj === null) { //jesli brak obiektu to pomijamy
                return true;
              }
              if (obj.metaData.get('clientId') !== null) { //jesli clientId juz istnieje to oznacza to ze zmienilo sie tylko metadata.id, wiec podmieniamy je w dataContainerze
                lsnNg.forEach(mainDataContainer[dmContainers[container]], function(dmObj) {
                  if (dmObj.metaData.get('clientId') === obj.metaData.get('clientId')) {
                    dmObj.metaData.set('id', obj.metaData.get('id'));
                    return false;
                  }
                  return true; //continue
                });
                return true;
              }
              var clientId = MapperUtils.getClientIdForObject(prodType, obj.metaData.get('id'));
              if (clientId === null) {
                sp2CommonHelper.throwException('No clientId found in dataContainer.{0} for object with id {1}.'.format(container, obj.metaData.get('id')));
              }
              obj.metaData.set('clientId', clientId);
              return true;
            });
            return true;
          });
        },
        /**
         * zwraca metaData.clientId dla podanego metaData.id obiektu
         * przeszukiwanie odbywa się w kontenerach dataContainera odpowiadających podanemu productType
         * @param {string} productType rodzaj produktu/obiektów do przeszukania
         * @param {string} id
         * @returns {string}
         */
        getClientIdForObject: function(productType, id) {
          var containerNames = [],
            clientId = null;
          switch (productType) {
            case CONSTANTS.PRODUCT_TYPE_PERSON:
              containerNames.push('persons');
              containerNames.push('organizations');
              break;
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
              containerNames.push('vehicles');
              break;
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              containerNames.push('localizations');
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
              containerNames.push('persons');
              containerNames.push('groups');
              break;
            default:
              break;
          }
          lsnNg.forEach(containerNames, function(containerName) {
            lsnNg.forEach(mainDataContainer[containerName], function(obj) {
              if (obj.metaData.get('id') === id) {
                clientId = obj.metaData.get('clientId');
                return false;
              }
              return true; //continue
            });
            if (clientId !== null) {
              return false;
            }
            return true; //continue
          });
          return clientId;
        },
        /**
         * łączy 2 abiory dynamicValues
         * @param {object} targetValues docelowy
         * @param {object} sourceValues zrodlowy
         * @returns {object}
         */
        mergeDynamicValues: function(targetValues, sourceValues) {
          angular.forEach(sourceValues, function(val, name) {
            if (val !== null && (typeof targetValues[name] === 'undefined' || targetValues[name] === null)) {
              targetValues[name] = val;
            }
          });
          return targetValues;
        },
        /**
         * czy w danym wairnacie ogniowym ryzyka są po·ączone - rycza›towa suma
         * @param {string} variant
         * @returns {Boolean}
         */
        isConnectedFireVariant: function(variant) {
          return (angular.isDefined(CONFIG.BEHAVIOR.localization.connectedFireRisk[variant]));
        },
        /**
         * czy istnieje połączony wariant w ryzykach ogniowych
         * @returns {Boolean}
         */
        isFireRisksConnection: function() {
          if (typeof CONFIG.BEHAVIOR.conectedFireRisk === 'undefined') {
            return false;
          }
          return !angular.equals(CONFIG.BEHAVIOR.conectedFireRisk, {});
        },
        /**
         * zwraca definicję polisy (salesProduct)
         * @param {string} idpm kod produktu
         * @returns {SPD.salesProducts.Communication|SPD.salesProducts.Property}
         */
        getSalesProductDef: function(idpm) { //eslint-disable-line consistent-return
          if (SPD.salesProducts.Communication.idpm === idpm) {
            return SPD.salesProducts.Communication;
          } else if (SPD.salesProducts.Property.idpm === idpm) {
            return SPD.salesProducts.Property;
          }
          sp2CommonHelper.throwException('No sales product definition found for product code: {0}'.format(idpm));
        },
        /**
         * konwertuje wartość atrybuu typu Table(kolumnowy) na tablicę (ułożoną wierszami)
         * @param {*} value
         * @returns {Array} tablica z obiektami, {kolumna1: wartosc1[, kolumna2: wartosc2[,...]]}
         */
        convertTableAttributeToArray: function(value) {
          if (!angular.isObject(value)) {
            return null;
          }
          var columns = [],
            arrayValue = [];
          angular.forEach(value, function(idx, columnName) {
            columns.push(columnName);
          });
          if (columns.length === 0) {
            return null;
          }
          var rowsCount = value[columns[0]].length;
          for (var i = 0; i < rowsCount; i += 1) {
            var row = {};
            for (var j = 0; j < columns.length; j += 1) {
              row[columns[j]] = (typeof (value[columns[j]][i] !== 'undefined')) ? value[columns[j]][i] : null; //eslint-disable-line no-constant-condition
            }
            arrayValue.push(row);
          }
          return arrayValue;
        },
        /**
         * pobiera numer zielonej karty
         * @returns {string}
         */
        getGreenCaredNumber: function() {
          return mainDataContainer.greenCardNumber;
        },
        /**
         * zwraca clientId głownego ubezpieczonego ze sciezki (z dataContainera)
         * @returns {int}
         */
        getMainInsuredClientId: function() {
          return mainDataContainer.mainInsuredId;
        },
        /**
         * zwraca wszystkie tymczasowe dynamicValues (definicje) dla obiektu (te z czy_pomijac_w_usţugach_rest = true w spd/tmpObjectsDynamicValues)
         * @param {string} objectName nazwa klasy obiektu np. Estate
         * @returns {Object.<string, Object>} tablica(obiekt) z definicjami atrybutow tymczasowych {symbol: definicja_z_spd[,...]}
         */
        getTemporaryDynamicValuesDefForObject: function(objectName) {
          var tmpDynVals = {};
          angular.forEach(SPD.objectsDynamicValues[objectName], function(def, symbol) {
            if (def[2] !== 'undefined' && def[2]) {
              tmpDynVals[symbol] = def;
            }
          });
          return tmpDynVals;
        },
        /**
         * ustawia na obiekcie dynamicValue _tmpDynamicValues, ktory przechowuje tymczasowe dynamicValues używane na sciezce a nie zawarte w SPD
         * @param {AbstractModel} obj
         */
        setTemporaryDynamicValuesForObject: function(obj) {
          var tmpDynVals = {},
            dynVals = obj.get('dynamicValues'),
            objTmpDynValsDef = MapperUtils.getTemporaryDynamicValuesDefForObject(obj.objectName);
          angular.forEach(objTmpDynValsDef, function(idx, symbol) {
            if (angular.isDefined(dynVals[symbol])) {
              tmpDynVals[symbol] = dynVals[symbol];
            }
          });
          obj.set('dynamicValues', {
            '_tmpDynamicValues': MapperUtils.serializeValue(tmpDynVals)
          });
        },
        /**
         * ustawia na obiekcie tymczasowe dynamicValues używane na sciezce a nie zawarte w SPD (z atrybutu _tmpDynamicValues)
         * @param {AbstractModel} obj
         */
        getTemporaryDynamicValuesForObject: function(obj) {
          var dynVals = obj.get('dynamicValues');
          if (typeof dynVals._tmpDynamicValues !== 'undefined') {
            var tmpDynVals = MapperUtils.unserializeValue(dynVals._tmpDynamicValues);
            if (!angular.equals(tmpDynVals, {})) {
              angular.extend(obj.dynamicValues, tmpDynVals);
            }
            delete obj.dynamicValues._tmpDynamicValues;
          }
        },
        /**
         * usuwa z dynamicValues na obiektach wniosku te atrybuty które nie powinny znaleźć się w modelu REST
         * @param {Application} application wniosek
         */
        removeTemporaryDynamicValuesFromObjects: function(application) {
          var objContainerToClassName = {
            'persons': 'Person',
            'organizations': 'Organization',
            'estates': 'Estate',
            'vehicles': 'Vehicle'
          };
          angular.forEach(objContainerToClassName, function(className, containerName) {
            if (angular.isArray(application[containerName])) {
              angular.forEach(application[containerName], function(obj) {
                var dynVals = obj.get('dynamicValues');
                angular.forEach(SPD.objectsDynamicValues[className], function(def, symbol) {
                  if (typeof dynVals[symbol] !== 'undefined' && typeof def[2] !== 'undefined' && def[2]) {
                    delete dynVals[symbol];
                  }
                });
                obj.dynamicValues = dynVals; //chcemy nadpisac a nie rozszerzyc
              });
            }
          });
        },
        /**
         * usuwa wszystkie nieuzywane na sciezce dynamic values (te z prefiksem '_')
         * @param {AbstractModel} obj
         */
        removeUnusedDynamicValuesForObject: function(obj) {
          var dynVals = obj.get('dynamicValues'),
            toDelete = [],
            toPreserve = MapperUtils.dynamicValuesToPreserve[obj.objectName] || [];

          angular.forEach(dynVals, function(idx, name) {
            if (toPreserve.indexOf(name) === -1 && name.substr(0, 1) === '_') {
              toDelete.push(name);
            }
          });
          angular.forEach(toDelete, function(name) {
            delete dynVals[name];
          });
          obj.dynamicValues = dynVals;
        },
        /**
         * zwraca id nieruchomosci z metaData
         * @param {string|int} dmId id z dataManagera.localizations
         * @return {string|null} id nieruchomosci lub null gdy niezdefiniowane
         */
        getEstateMetadataId: function(dmId) {
          dmId = dmId + '';
          if (angular.isDefined(mainDataContainer.localizations[dmId])) {
            return mainDataContainer.localizations[dmId].get('metaData').get('id');
          }
          return null;
        },
        /**
         * zwraca id osoby z metaData
         * @param {string|int} dmId id z dataManagera.persons
         * @return {string|null} id osoby lub null gdy niezdefiniowane
         */
        getSubjectMetadataId: function(dmId) {
          dmId = dmId + '';
          if (angular.isDefined(mainDataContainer.persons[dmId])) {
            return mainDataContainer.persons[dmId].get('metaData').get('id');
          } else if (angular.isDefined(mainDataContainer.organizations[dmId])) {
            return mainDataContainer.organizations[dmId].get('metaData').get('id');
          } else if (angular.isDefined(mainDataContainer.groups[dmId])) {
            return mainDataContainer.groups[dmId].get('metaData').get('id');
          }
          return null;
        },
        /**
         * get estate or vehicle co owners ids
         * @param {Object} addData estate/vehicle additionalData
         * @return {String} ';' separated coowners metaData.ids
         */
        getEstateOrVehicleCoownersIds: function(addData) {
          if (!angular.isArray(addData.coowners) || !addData.coowners.length) {
            return '';
          }
          return _.map(addData.coowners, function(clientId) {
            return MapperUtils.getSubjectMetadataId(clientId);
          }).join(';');
        },
        /**
         * zwraca id pojazdu z metaData
         * @param {string|int} dmId id z dataManagera.vehicles
         * @return {string|null} id pojazdu lub null gdy niezdefiniowane
         */
        getVehicleMetadataId: function(dmId) {
          dmId = dmId + '';
          if (angular.isDefined(mainDataContainer.vehicles[dmId])) {
            return mainDataContainer.vehicles[dmId].get('metaData').get('id');
          }
          return null;
        },

        /**
         * zwraca obiekty z dataContainera określonego typu
         * @param  {String} dataContainer dataContainer
         * @param  {String} objectType typ
         * @return {Object} []clientId: obiekt[description]
         */
        getObjects: function(dataContainer, objectType) {
          var container = {};
          if (angular.isArray(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType])) {
            angular.forEach(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType], function(name) {
              angular.extend(container, dataContainer[name]);
            });
          } else {
            container = dataContainer[CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType]];
          }
          return container;
        },
        /**
         * ustawia clientId dla wszystkich obiektów na wniosku i podbija iteratory clientId w dataContainerze
         * Do użycia np. ww wczytywaniu wniosku dla wznowienia lub wniosku wygenerowanego poza salesPath
         * @param  {ApplicationModel} application
         */
        generateClientIds: function(application) {
          mainDataContainer.nextSubjectId = 1; //ustawiamy id, bo domyślnie jest 2 (pominięcie startowej osoby)
          //osoby
          if (angular.isArray(application.persons)) {
            angular.forEach(application.persons, function(person) {
              var clientId = dataContainerHelper.getNextPersonId();
              person.metaData.set('clientId', clientId);
            });
          }
          //organizacje
          if (angular.isArray(application.organizations)) {
            angular.forEach(application.organizations, function(organization) {
              var clientId = dataContainerHelper.getNextOrganizationId();
              organization.metaData.set('clientId', clientId);
            });
          }
          mainDataContainer.nextObjectId = 1;
          var minId = _.chain(application.estates)
            .concat(application.vehicles)
            .compact()
            .flatMap(function(el) {
              return +el.metaData.id;
            })
            .min()
            .value();
          if (minId < 0) {
            // IHESTIAMF-2305
            mainDataContainer.nextObjectId = Math.abs(minId) + 1;
          }
          //lokalizacje
          if (angular.isArray(application.estates)) {
            angular.forEach(application.estates, function(estate) {
              var clientId = dataContainerHelper.getNextLocalizationId();
              estate.metaData.set('clientId', clientId);
            });
          }
          //pojazdy
          if (angular.isArray(application.vehicles)) {
            angular.forEach(application.vehicles, function(vehicle) {
              var clientId = dataContainerHelper.getNextVehicleId();
              vehicle.metaData.set('clientId', clientId);
            });
          }
          //grupy
          if (angular.isArray(application.groups)) {
            angular.forEach(application.groups, function(group) {
              var clientId = dataContainerHelper.getNextGroupId();
              group.metaData.set('clientId', clientId);
            });
          }
        },
        /**
         * zwraca pierwsze z brzegu ryzyko z dataContainer.policies[].risks (lub przekazanej polisy) na podstawie przekazanego kodu ryzyka (z CONSTANTSow)
         * @param  {String} riskCode
         * @param  {Object|PolicyModel} [policyToSearch] opcjonalnie obiekt polisy, z którego należy wyszukać ryzyko
         * @return {RiskModel|null} ryzyko lub null gdy nie znaleziono
         */
        findRisk: function(riskCode, policyToSearch) {
          var risk = null;
          var getRisk = function(policy, code) {
            if (!angular.isArray(policy.risks)) {
              return null;
            }
            var foundRisk = null;
            lsnNg.forEach(policy.risks, function(appRisk) {
              var riskDef = MapperUtils.findRiskDefByIdpm(appRisk.product.compId);
              if (riskDef.riskCode === code) {
                foundRisk = appRisk;
                return false;
              }
              return true; //continue
            });
            return foundRisk;
          };
          if (angular.isObject(policyToSearch)) {
            risk = getRisk(policyToSearch, riskCode);
          } else {
            lsnNg.forEach(mainDataContainer.policies, function(policy) {
              risk = getRisk(policy, riskCode);
              if (risk !== null) {
                return false;
              }
              return true; //continue
            });
          }

          return risk;
        },
        /**
         * szuka i zwraca poprzednie ryzyko (RiskModel) odpowiadające przekazanemu
         * @param  {String} riskIdpm kod ryzyka wg SPD
         * @param  {Array[]} [subjectRefs] tablica referencji do podmiotów ubezpieczonych (konwencja taka jak w ToRestTempRiskModel)
         * @param  {Array[]} [objectRefs]  tablica referencji do obiektów ubezpieczonych (konwencja taka jak w ToRestTempRiskModel)
         * @return {RiskModel|null} ryzyko gdy znaleziono
         */
        findRefRisk: function(riskIdpm, subjectRefs, objectRefs) {
          var foundId = null,
            foundRisk = null, //znalezione ryzyko
            spdDef = MapperUtils.findRiskDefByIdpm(riskIdpm),
            isAdd = MapperUtils.isAddition(spdDef.productCode),
            prodDef = isAdd ? resourceHelper.getAddDef(spdDef.productCode) : resourceHelper.getProdDef(CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[spdDef.productCode] ? CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[spdDef.productCode] : spdDef.productCode);
          lsnNg.forEach(mainDataContainer.selectedRisksRefs, function(data) {
            if (data.riskIdpm === riskIdpm) { //prawie mamy ryzyko
              //teraz nalezy sprawdzic przypisanie do podmiotu lub obiektu ubezpieczonego
              var objFound = false;
              switch (prodDef.TYPE) {
                case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
                case CONSTANTS.PRODUCT_TYPE_PERSON:
                  if (spdDef.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) { //szczegolny przypadek - nnw forma bezimienna
                    objFound = true;
                  } else { //pozostale formy osobowe
                    lsnNg.forEach(subjectRefs, function(refData) {
                      if (refData[1] === MapperUtils.getSubjectMetadataId(data.objId)) { //data.objId to metaData.clientId, a przyrownujemy do metaData.id
                        objFound = true;
                        return false;
                      }
                      return true;
                    });
                  }
                  break;
                case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
                  lsnNg.forEach(objectRefs, function(refId) {
                    if (refId === MapperUtils.getEstateMetadataId(data.objId)) { //data.objId to metaData.clientId, a przyrownujemy do metaData.id
                      objFound = true;
                      return false;
                    }
                    return true;
                  });
                  break;
                case CONSTANTS.PRODUCT_TYPE_VEHICLE:
                  lsnNg.forEach(objectRefs, function(refId) {
                    if (refId === MapperUtils.getVehicleMetadataId(data.objId)) { //data.objId to metaData.clientId, a przyrownujemy do metaData.id
                      objFound = true;
                      return false;
                    }
                    return true;
                  });
                  break;
                case CONSTANTS.PRODUCT_TYPE_PACKAGE:
                  //podpięcie do obiektu/podmiotu nie ma znaczenia dla pakietowych ryzyk
                  objFound = true;
                  break;
                default:
              }
              if (objFound) {
                foundId = data.riskId;
                return false; //przerywamy
              }
            }
            return true;
          });
          if (foundId !== null) {
            lsnNg.forEach(mainDataContainer.application.risks, function(riskObj) {
              if (riskObj.metaData.get('id') === foundId) {
                foundRisk = riskObj;
                return false;
              }
              return true;
            });
          }
          return foundRisk;
        },
        /**
         * zwraca kod produktu, który należy użyć do pobrania definicji z CONFIGa
         * (przydatne tylko dla ryzyk specjalnych typu nnw incognito)
         * @param  {String} productCode kod produktu z spdRisks
         * @return {String} kod produktu z definicji z CONFIGa
         */
        getConfigProductCode: function(productCode) {
          if (productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
            return CONSTANTS.PRODUCT_NNW;
          }
          return productCode;
        },
        /**
         * zwraca dozwolone warianty dla ryzyka na podstawie definicji z SPD oraz danych przysłanych przez usługę (application.allowedChanges.risks.attributes)
         * @param  {Object[]} allowedAttributes lista dozwolonych atrybutów do zmiany na ryzyku
         * @param  {Object} riskDef           def ryzyka z SPD
         * @return {String[]}            tablica dozwolonych wariantów
         */
        _getAllowedRiskVariants: function(allowedAttributes, riskDef) {
          var allowedValues = [],
            defVariants = true; //warinaty z definicji SPD
          if (angular.isDefined(riskDef.variant) && riskDef.variant.length === 1) { //ryzyka z konkretnym wariantem w SPD. Wówczas powinien być dostępny ten jedyny wariant ubezpieczenia, ale np. dla E1_LUMP_SUM_FLAT_FIRE (wariant ryczałtowy ognia w e1) zwracane będą dozwolone inne warianty przez usługę. Dlatego kontynuujemy działanie metody.
            allowedValues.push(riskDef.variant[0]);
          }
          var variantAttribute = 'coverageOption'; //atrybut przenoszacy informacje o wariancie ryzyka
          if (angular.isDefined(riskDef.attributes.assistanceOption)) { //np. dla hca atrybut ten to assistanceOption
            variantAttribute = 'assistanceOption';
          }
          lsnNg.forEach(allowedAttributes, function(attr) {
            if (attr.name === variantAttribute) {
              allowedValues = attr.allowedValues;
              defVariants = false;
              return false;
            }
            return true;
          });
          //jeśli assistanceOption to musimy skonwertować go na nasz CONSTANT.VARIANT...
          if (variantAttribute === 'assistanceOption' && !defVariants) {
            angular.forEach(allowedValues, function(val, idx) {
              allowedValues[idx] = MapperUtils.getHCAVariantFromAssistanceOption(val);
            });
          }
          return allowedValues;
        },
        /**
         * checks if value is proper protection date (by running Date.parse - date has to be later than 1970-01-01)
         * @param {*} value
         * @return {boolean}
         */
        isProperProtectionDate: function(value) {
          var parsed = Date.parse(value);
          return !isNaN(parsed) && parsed > 0;
        }

      };


      return MapperUtils;
    }
  ]);