angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchasePolicyVerificationCtrl', ['$scope', '$stateParams', 'ihestiaDashboardNewTaskHelper',
    function($scope, $stateParams, ihestiaDashboardNewTaskHelper) {

      $scope.autocomplete = false; //jednak bez autocompletera ma być, ale zostawiam dla developmentu

      $scope.data = {
        verificationDataForm: {}, //z jakiegoś powodu $scope.verificationDataForm nie był łapany przez forma
        operationDate: null
      };

      $scope.loadingElements = false; //czy w trakcie ładowania

      $scope.formSubmitted = false;
      $scope.formObjectReady = false;

      $scope.init = function(){
        $scope.formObjectReady = true; //musimy zaczekać z tplką aż verificationDataForm będzie w scope
        if($scope.verificationData.operationDate && (!$stateParams.forward || $stateParams.forward !== '1'))
        {
          $scope.data.operationDate = $scope.verificationData.operationDate;
        }
      };

      /**
       * klikniecię przycisku "dalej"
       * @return {[type]}        [description]
       */
      $scope.forward = function() {
        var corpoPrefixes = ['722', '922']; // zakładamy, że każdy suffix ma długość 3
        var isCorpoPolicy = false;
        if($scope.verificationData.policyNumber) {
          var policyNumber = $scope.verificationData.policyNumber + '';
          if(corpoPrefixes.indexOf(policyNumber.substr(0, 3)) !== -1) {
            isCorpoPolicy = true;
          }
        }

        if(isCorpoPolicy) {
          // IHESTIABAS-5927 - tymczasowo dla polis corpo tylko tworzymy zadanie
          ihestiaDashboardNewTaskHelper.showNewTaskModal();
        }
        else {
          // jeśli brak błędów, to odpytujemy usługę
          if ($scope.data.verificationDataForm.$valid || ($stateParams.forward && $stateParams.forward === '1')) {
            $scope.verificationData.operationDate = $scope.data.operationDate;
            $scope.verifyPolicy(false, null, false, $scope.otherData.isForward);
          }
          else
          {
            $scope.formSubmitted = true;
          }
        }
      };

    }
  ]);