//trzeba pomyslec jak czyscic te listy
angular.module('ihestiaCommonDirectives')
  .factory('AbstractUploadOnCallbacksHelper', ['$rootScope', 'filesSvc', 'ihestiaConfigHelper', 'IHestiaRestServiceProviderAbstractDef', '$timeout', '$alert',
    'ihestiaCharacterConflictedHelper', 'addMd5ToFile', 'UploadDataContainer', '$filter',
    function($rootScope, filesSvc, ihestiaConfigHelper, IHestiaRestServiceProviderAbstractDef, $timeout, $alert, ihestiaCharacterConflictedHelper, addMd5ToFile, UploadDataContainer, $filter) {
      var AbstractUploadOnCallbacksHelper = function() {
        var self = this;

        this.data = {};

        /**
         * Metoda służy do inicjowania listy wysłanych plików
         * @param  {string} scopeName  nazwa scope
         * @param  {object} callbacks obiekt z funkcjami callback
         * @param  {array} uplodedFiles  files tablica wyslanych plikow
         */
        this.init = function(scopeName, callbacks, uplodedFiles, config) {
          self.data[scopeName] = new UploadDataContainer();
          if (angular.isObject(config)) {
            self.data[scopeName].config = angular.extend(self.data[scopeName].configDefault, config);
          }

          if (angular.isArray(uplodedFiles)) {
            self.data[scopeName].files.uploaded = uplodedFiles;
          }

          angular.forEach(callbacks, function(value, key) {
            self.data[scopeName].callbacks[key] = value;
          });
        };

        /**
         * Event dodawania plikow do wysłania
         */
        $rootScope.$on('ihestiaOnFileUploadAdd', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            self.onAddFile(e, data);
          }
        });

        /**
         * funkcja wywoływana przed wysłaniem pliku
         * @param {event} e
         * @param  {object} data
         */
        $rootScope.$on('fileuploadsend', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            self.beforeSendingFile(e, data);
          }
        });

        /**
         * event wywyołany na usunięcie pliku
         * @param {event} e
         * @param  {object} data
         */
        $rootScope.$on('ihestiaOnFileDelete', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            self.onFileDelete(e, data);
          }
        });

        /**
         * Event na udane wysyłanie
         */
        $rootScope.$on('ihestiaOnFileUploadDone', function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var documentTypeCode = self.getDocumentCodeTypeFromProcessedFile(scopeName),
              verifyForDocumentsTypes = self.data[scopeName].config.verifyForDocumentsTypes;

            if (documentTypeCode !== null && angular.isArray(verifyForDocumentsTypes) && verifyForDocumentsTypes.indexOf(documentTypeCode) !== -1) {
              filesSvc.getQuality(data.result.fileServerId, function(res) {

                var msgs;
                if (!$.isEmptyObject(res.data)) {
                  if (res.data.code === 'Ok' || res.data.code === null) {
                    self.onFileUploadDone(e, data);
                    return;
                  } else if (res.data.code === 'DocumentNumberNotFound' || res.data.code === 'WrongPolicyNumber' || res.data.code === 'WrongAnnexNumber') {
                    msgs = [{
                      text: res.data.description,
                      status: 'WARNING'
                    }];
                  } else {
                    msgs = [{
                      text: res.data.description,
                      status: 'ERROR'
                    }];
                  }
                } else {
                  msgs = [{
                    text: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    }),
                    status: 'ERROR'
                  }];
                }
                self.onErrorWhenSending(scopeName, e, data, msgs, msgs[0].status);
              }, function(res) {
                var msgs;
                if (res.status === 401) {
                  msgs = [{
                    text: $filter('translate')('exceptionNoAuthentication', {
                      componentCode: 'Public'
                    })
                  }];
                } else if (res.status === 409) {
                  msgs = [{
                    text: $filter('translate')('characterConflict', {
                      componentCode: 'Public'
                    })
                  }];
                } else {
                  msgs = [{
                    text: $filter('translate')('exceptionInternalServices', {
                      componentCode: 'Public'
                    })
                  }];
                }

                self.onErrorWhenSending(scopeName, e, data, msgs);
              });
            } else {
              self.onFileUploadDone(e, data);
            }
          }
        });

        /**
         * Event na bład w wysyłaniu
         */
        $rootScope.$on('ihestiaOnFileUploadFail', function(e, data) {
          self.onFileUploadFail(e, data);
        });

        /**
         * Event na bład przy dodawaniu pliku
         */
        $rootScope.$on('ihestiaOnAddingFileFail', function(e, data) {
          self.onFailOnAddingFile(e, data);
        });

        this.getDocumentCodeTypeFromProcessedFile = function(scopeName) {
          var documentTypeCode = null,
            processedFile = self.data[scopeName].files.toSend[self.data[scopeName].processedFileIndex];

          if (processedFile.documentTypeCode) {
            documentTypeCode = processedFile.documentTypeCode;
          } else if (angular.isObject(processedFile.file) && processedFile.file.documentTypeCode) {
            documentTypeCode = processedFile.file.documentTypeCode;
          }

          return documentTypeCode;
        };

        this.onAddFile = function(e, data) {
          if (data.state() !== 'rejected') {
            var scopeName = data.scope.name;

            angular.forEach(data.files, function(file) {
              if (self.data[scopeName].selectedDocumentType !== null) {
                file.documentTypeName = self.data[scopeName].selectedDocumentType.name;
                file.documentTypeCode = self.data[scopeName].selectedDocumentType.code;
                file.attributes = self.data[scopeName].selectedDocumentType.attributes;
              }
              self.data[scopeName].files.toSend.push(file);
              addMd5ToFile(file);
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileUploadAdd)) {
              self.data[scopeName].callbacks.onFileUploadAdd(e, data);
            }
          }
        };

        this.beforeSendingFile = function(e, data) {
          var scopeName = data.scope.name;
          if (!self.data[scopeName].config.doNotAddTokenId && self.isActive(scopeName)) {
            var processedFile = self.data[scopeName].getProcessedFile('toSend');
            if (angular.isDefined(processedFile.tokenId)) {
              data.url += '/' + processedFile.tokenId;
            }
          }

          self.addHeaderToServiceSendingFile(data);
        };

        this.addHeaderToServiceSendingFile = function(data) {
          var provider = new IHestiaRestServiceProviderAbstractDef();
          data.headers = provider.getNewRequestHeaders();
          data.headers['AP-Unique-Request-Id'] = provider.getUniqueRequestId();
          data.headers['Ap-Contract-Req-Type'] = 'ACTION';
          data.headers['Ap-Contract-Process-Type'] = 'SYNC';

          var file = self.data[data.scope.name].getProcessedFile('toSend');
          //biblioteka wywoluje wczesniej te funkcje niz funkcje dodania pliku
          //w przypadku automatycznego uploadu, ale w tym przypadku nie musimy wyslac md5
          if (angular.isDefined(file)) {
            data.headers['Content-MD5'] = file.md5;
          }

        };

        this.onFileDelete = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var i = self.data[scopeName].files.toSend.length;
            while (i) {
              i -= 1;
              if (self.data[scopeName].files.toSend[i] === data.file) {
                self.data[scopeName].files.toSend.splice(i, 1);
                break;
              }
            }

            $rootScope.$broadcast('deleteFileInUploader', {
              file: data.file,
              scopeName: scopeName
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onFileDelete[scopeName])) {
              self.data[scopeName].callbacks.onFileDelete(e, data);
            }
          }
        };

        this.onFileUploadDone = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var processedFile = self.data[scopeName].getProcessedFile('toSend');

            //tutaj trzeba zczytać z pliku typ
            self.data[scopeName].files.uploaded.push({
              name: processedFile.name,
              size: processedFile.size,
              type: processedFile.type,
              documentTypeName: processedFile.documentTypeName,
              status: 'SUCCESS'
            });
            if (angular.isFunction(self.data[scopeName].callbacks.onFileUploadDone)) {
              self.data[scopeName].callbacks.onFileUploadDone(e, data);
            }
            if (!self.data[scopeName].config.oneFile) {
              self.proceedUploadingFile(scopeName);
            }
          }
        };

        this.onFileUploadFail = function(e, data) {
          var scopeName = data.scope.name;
          if (self.isActive(scopeName)) {
            var jqXHR = data.response().jqXHR;

            var text = $filter('translate')('exceptionInternalServices', {
              componentCode: 'Public'
            });
            if (jqXHR.status === 403) {
              text = $filter('translate')('noPermissionToSaveFile', {
                componentCode: 'Public'
              });
            } else if (jqXHR.status === 401) {
              text = $filter('translate')('exceptionNoAuthentication', {
                componentCode: 'Public'
              });
            } else if (jqXHR.status === 413) {
              text = $filter('translate')('toLargeFile', {
                componentCode: 'Public'
              });
            } else if (jqXHR.status === 409 && jqXHR.getResponseHeader('AP-User-Status') === 'CHARACTER_CHANGED') {
              text = $filter('translate')('characterConflict', {
                componentCode: 'Public'
              });
              $timeout(function() {
                ihestiaCharacterConflictedHelper.charactersConflicted(jqXHR.getResponseHeader('AP-User-Character'));
              });
            } else if (jqXHR.status === 409 && data.result && data.result.messageText) {
              text = data.result.messageText;
            } else if (jqXHR.status === 410) {
              text = $filter('translate')('exceptionFileNotSentProbablyMalware', {
                componentCode: 'Public'
              });
            } else if (angular.isDefined(data.result) && angular.isArray(data.result.messages) && data.result.messages.length > 0) {
              text = data.result.messages;
            }

            self.onErrorWhenSending(scopeName, e, data, text);
          }
        };

        this.onFailOnAddingFile = function(e, data) {
          var scopeName = data.scope.name;

          if (angular.isObject(self.data[scopeName])) {

            angular.forEach(data.files, function(file) {
              angular.forEach(self.data[scopeName].files.toSend, function(val, i) {
                //val moze byc modelem z atrybutem file, albo poprostu file
                var externalUploadFileId = angular.isDefined(val.file) ? val.file.externalUploadFileId : val.externalUploadFileId;
                if (file.externalUploadFileId === externalUploadFileId) {
                  self.data[scopeName].files.toSend.splice(i, 1);
                }
              });

              $rootScope.$broadcast('deleteFileInUploader', {
                scopeName: scopeName,
                file: file
              });
            });

            if (angular.isFunction(self.data[scopeName].callbacks.onErrorAddingFile)) {
              self.data[scopeName].callbacks.onErrorAddingFile(e, data);
            }
          }
        };

        this.onErrorWhenSending = function(scopeName, e, data, messages, status) {
          if (angular.isUndefined(status)) {
            status = 'ERROR';
          }

          if (angular.isFunction(self.data[scopeName].callbacks.onErrorSendingFile)) {
            self.data[scopeName].callbacks.onErrorSendingFile(e, data, self.parseMessages(messages));
          }

          if (!self.data[scopeName].config.oneFile) {
            self.proceedUploadingFile(scopeName);
          }
        };

        this.parseMessages = function(messages){
          var message = '';
          if (angular.isArray(messages)) {
            angular.forEach(messages, function(msg) {
              message += msg.text + ' ';
            });
          } else if (angular.isString(messages)) {
            message = messages;
          }

          return message;
        };

        /**
         * Metoda służy do kontynuacji przesłania kolejnych plików
         * @param  {string} scopeName  nazwa scope
         */
        this.proceedUploadingFile = function(scopeName) {
          //zawsze przetwarzamy pierwszy plik i po przetworzeniu go usuwamy
          $timeout(function() {
            $rootScope.$broadcast('deleteFileInUploader', {
              scopeName: scopeName,
              file: self.data[scopeName].getProcessedFile('toSend')
            });
            self.data[scopeName].files.toSend.splice(self.data[scopeName].processedFileIndex, 1);
            if (self.data[scopeName].files.toSend.length > 0) {
              if (self.data[scopeName].config.checkFilesBeforeSend) {
                self.sendInfoAboutFile(scopeName);
              } else {
                self.data[scopeName].processedFileIndex += 1;
                self.data[scopeName].getProcessedFile('toSend').$submit();
              }

            } else {
              delete self.data[scopeName].status;
              if (self.data[scopeName].windowUploadClose) {
                self.clearUploadData(scopeName);
              }

              if (angular.isFunction(self.data[scopeName].callbacks.onAllFileSend)) {
                self.data[scopeName].callbacks.onAllFileSend();
              }
            }
          });
        };

        this.sendInfoAboutFile = function(scopeName) {
          if (self.areFilesToSend(scopeName)) {
            var processedFile = self.data[scopeName].getProcessedFile('toSend'),
              fileData = {
                dynamicValues: {}
              };
            fileData.documentCode = processedFile.documentTypeCode;
            fileData.length = processedFile.size;
            fileData.name = processedFile.name;

            angular.forEach(processedFile.attributes, function(attr) {
              if (attr.type === 'datetime') {
                var date = new XDate(attr.val);
                if (date.valid()) {
                  var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
                  fileData.dynamicValues[attr.code] = (new XDate(date.getTime() - timezoneOffset)).toISOString();
                } else {
                  fileData.dynamicValues[attr.code] = '';
                }
              } else {
                fileData.dynamicValues[attr.code] = attr.val;
              }
            });

            filesSvc.post('', fileData, '', function(response) {
              var data = response.data;
              if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
                if ($.isEmptyObject(data)) {
                  var text = $filter('translate')('exceptionInternalServices', {
                    componentCode: 'Public'
                  });
                  self.onErrorWhenSending(scopeName, null, null, text);
                }
                $timeout(function() {
                  var allAttrValid = true;
                  angular.forEach(processedFile.attributes, function(attr) {
                    angular.forEach(data.messages, function(msg) {
                      if (attr.code === msg.object) {
                        allAttrValid = false;
                      }
                    });
                  });
                  if (allAttrValid) {
                    self.onErrorWhenSending(scopeName, null, null, data.messages);
                  } else {
                    self.status[scopeName] = 'ERROR';
                    if (angular.isFunction(self.data[scopeName].callbacks.onErrorAttributes)) {
                      self.data[scopeName].callbacks.onErrorAttributes(data);
                    }
                  }
                }, 0);

              } else {
                processedFile.tokenId = data.tokenId;
                if (self.config[scopeName].firstCheckAllFiles) {
                  self.proceedUploadingFile(scopeName);
                } else {
                  processedFile.$submit();
                }
              }
            }, function(res) {
              var text;
              if (res.status === 401) {
                text = $filter('translate')('exceptionNoAuthentication', {
                  componentCode: 'Public'
                });
              } else if (res.status === 409) {
                text = $filter('translate')('characterConflict', {
                  componentCode: 'Public'
                });
              } else {
                text = $filter('translate')('exceptionInternalServices', {
                  componentCode: 'Public'
                });
              }

              self.onErrorWhenSending(scopeName, null, null, text);

            }, {
              doNotAskAgainOnConflict: true
            });
          } else {
            $alert({
              content: $filter('translate')('noFileSelected', {
                componentCode: 'Public'
              }),
              type: 'warning'
            });
          }
        };

        //czy akcja jest z tego helpera
        this.isActive = function(scopeName) {
          return angular.isObject(self.data[scopeName]);
        };

        this.onUploadWindowClose = function(scopeName) {
          if (self.isActive(scopeName) === 'pending') {
            self.data[scopeName].windowUploadClose[scopeName] = true;
          } else {
            self.clearUploadData(scopeName);
          }
        };

        //Uwaga funkcja powinna być wołana tylko na zamknięcie okna
        //ponieważ niszczy cały obiekt, więc też referencję
        this.clearUploadData = function(scopeName) {
          delete self.data[scopeName];
        };

        this.areFilesToSend = function(scopeName) {
          return self.data[scopeName].areFileToSend();
        };

        this.areUploadedFiles = function(scopeName) {
          return self.data[scopeName].areUploadedFiles();
        };

        this.sendFile = function(scopeName) {
          self.data[scopeName].status = 'pending';
          self.data[scopeName].getProcessedFile('toSend').$submit();
        };

        this.clearAllFiles = function(scopeName) {
          self.data[scopeName].clearAllFiles();
        };
      };

      return AbstractUploadOnCallbacksHelper;
    }
  ]);