/**
 * Serwis do budowy lewego menu - przetrzymuje style opis i uprawnienia
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .service('menuLeftItemListHelper', ['$filter', 'ihestiaIkomunikatorPermissionHelper', 'ihestiaIcContextHelper', '$window',
    function($filter, ihestiaIkomunikatorPermissionHelper, ihestiaIcContextHelper, $window) {
      var ItemListHelper = function() {
        var self = this;
        this.list = [];

        this.init = function() {
          self.reloadList();
        };

        this.reloadList = function() {
          self.list = [];
          // IHESTLIFE-4354
          if(!ihestiaIcContextHelper.isLifeActiveContext()) {
            self.list.push({
              code: 'iMonitor',
              name: $filter('translate')('Public.imonitor'),
              class: 'icon-solo icon-numkal',
              permissionCode: 'IMONITOR',
              disabled: false
            });
          }
          self.list.push({
            code: 'integratedSystems',
            name: $filter('translate')('Public.integratedSystems'),
            class: 'icon-solo icon-matryca',
            disabled: true
          });
          self.list.push({
            code: 'iPegaz',
            name: $filter('translate')('Public.ipegaz'),
            permissionCode: 'IPEGAZ',
            class: '',
            disabled: false
          });
          self.list.push({
            code: 'myPoints',
            name: $filter('translate')('Public.myPoints'),
            class: '',
            disabled: false
          });
          self.list.push({
            code: 'trainingPlatform',
            name: $filter('translate')('Public.trainingPlatform'),
            class: '',
            disabled: false
          });
          self.list.push({
            code: 'solicitedReports',
            name: $filter('translate')('solicitedReports', {
              componentCode: 'Public'
            }),
            class: 'icon-sl-fill icon-file-table',
            disabled: false
          });
          self.list.push({
            code: 'EH24',
            name: 'EH24',
            class: '',
            disabled: false,
            target: '_blank',
            visible: true,
            onClick: function(item, $event) {
              $window.open('https://eh24.ergohestia.pl', item.target ? item.target : '_self');
              $event.stopPropagation();
              return true; // hide menu
            }
          });

          if (ihestiaIkomunikatorPermissionHelper.shouldShowApp()) {
            self.list.push({
              code: 'iKomunikator',
              name: $filter('translate')('Public.ikomunikator'),
              class: 'icon-solo icon-osoby',
              permissionCode: 'IKOMUNIKATOR',
              disabled: false
            });
          }
        };

        this.prepareByCodes = function(items){
          self.reloadList();

          var keysToRemove = [];
          angular.forEach(items, function(item, key) {
            if (angular.isString(item.code)) {
              var defItem = self.getItemByCode(item.code);
              if (defItem !== null) {
                angular.extend(item, defItem);
              } else {
                keysToRemove.push(key);
              }

              if (angular.isArray(item.items)) {
                self.prepareByCodes(item.items);
              }
            }
          });

          for (var i = keysToRemove.length - 1; i >= 0; i--) {
            var key = keysToRemove[i];
            items.splice(key, 1);
          }
        };

        this.getItemByCode = function(code) {
          var foundedItem = null;

          angular.forEach(self.list, function(defItem) {
            if (defItem.code === code) {
              foundedItem = defItem;
            }
          });

          return foundedItem;
        };

        self.init();
      };

      return new ItemListHelper();
    }
  ]);