angular.module('salesPath2')
  .directive('sp2Header',
    function() {
      return {
        restrict: 'E',
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.headerTemplate) {
            return attrs.headerTemplate;
          } else {
            return 'salesPath2Template/directives/header/header.tpl.html';
          }
        },
        controller: 'sp2HeaderCtrl'
      };
    });