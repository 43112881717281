/**
 * Serwis do pobierania podstawowych danych
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase')
  .service('ihestiaCharacterConflictedHelper', ['$rootScope', 'bodyInjectorHelper', 'ihestiaSsoBaseInfoHelper', 'ihestiaConfigHelper',
    function($rootScope, bodyInjectorHelper, ihestiaSsoBaseInfoHelper, ihestiaConfigHelper) {

      var IHestiaCharacterConflictedHelper = function() {
        var self = this;

        this.charactersConflictedModalActive = false;
        this.serviceCharacter = null;

        /**
         * przechwycienie zmiany tożsamości na innej zakładce
         * @param  {[type]} event [description]
         * @param  {IHestiaCrossTabMessageModel} data
         */
        $rootScope.$on('ihestiaCrossTab:message', function(event, data) {
          if (data.code === 'characterChanged') {
            self.charactersConflicted(data.attrs.characterCode);
          }
        });

        /**
         * Obsługa konfliktu tożsamości
         *
         */
        this.charactersConflicted = function(serviceCharacterCode) {
          if(!ihestiaConfigHelper.get('frontFlags', 'IS_PUBLIC')) {
            self.serviceCharacter = ihestiaSsoBaseInfoHelper.getCharacterByCode(serviceCharacterCode);

            if (!self.charactersConflictedModalActive) {
              self.charactersConflictedModalActive = true;
              bodyInjectorHelper.injectDirective('charactersConflicted', 'ihestia-character-conflicted-modal');
            }
          }
        };

        this.getConflictedCharacter = function() {
          return self.serviceCharacter;
        };

        this.clearConflictedCharacter = function() {
          self.serviceCharacter = null;
        };
      };

      return new IHestiaCharacterConflictedHelper();
    }
  ]);