angular.module('ihestiaCommonDirectives.trimInfo')
  .factory('ihestiaTrimInfo', ['$filter',
    function($filter) {
      var trimInfo = function(params) {
        // domyslne ustawienia
        var settings = $.extend({
            elements: [],
            fieldSeparator: ' ',
            forceShowFieldSeparator: false, //wymuszamy pokazanie separatora pola nawet gdy kolejny element jest pusty
            lineSeparator: '\n', // @do not use
            maxLength: 22,
            doTruncate: true, //domyślne obcinanie za długiego tekstu - jeśli wyłączymy to jednocześnie musimy określić na którymś z elementów "optionalLineBreak"
            truncateText: '…',
            html: true,
            emptyValue: 'BRAK DANYCH',
            optionalLineBreak: false, //jeśli element ma ustawiona taka flage to znaczy że dopuszczamy że po nim może wystąpić przełamanie linii gdy text nie zmieści się w zadanej długości
            lineBreakTempElement: '~~~', //tymczasowy znacznik określający w jakim miejscu można przeprowadzić dodatkowe przełamanie linii
            firstInRow: true //czy element może znaleźć się na początku linii gdy mamy do czynienia z blokiem kilku wierszy
          }, params),
          lines = [],
          actualLine = '',
          usedFildSeparators = [];

        // pobieramy niezbedne skladniki
        $.each(settings.elements, function(key, element) {
          // trimmujemy string
          var text = $.trim(element.text);
          if (text !== '' && element.prefix) {
            actualLine += element.prefix;
          }
          if (element.maxLength && text.length > element.maxLength && settings.doTruncate) {            
            text = text.truncate(element.maxLength, settings.truncateText);            
          }
          actualLine += text;

          if (element.lastInLine && settings.doTruncate) {
            // jesli to ostatni element w linii, to wypychamy go
            lines.push(actualLine.truncate(settings.maxLength, settings.truncateText));
            actualLine = '';
          } else if (key < settings.elements.length - 1) {
            var nextElem = settings.elements[key + 1];            
            
            if ($.trim(nextElem.text) !== '') {
              // jesli nastepny element niepusty, to dodajemy separator
              if(angular.isDefined(element.fieldSeparator)) {
                actualLine += element.fieldSeparator;
                usedFildSeparators.push(element.fieldSeparator);
              } else {
                actualLine += settings.fieldSeparator;
                usedFildSeparators.push(settings.fieldSeparator);
              }
            }

            // gdy nastepny element jest pusty ale mamy ustawioną flagę wymuszenia separatora to dodajemy separator pola
            if ($.trim(nextElem.text) === '' && element.forceShowFieldSeparator) {
              if(angular.isDefined(element.fieldSeparator)) {
                actualLine += element.fieldSeparator;
                usedFildSeparators.push(element.fieldSeparator);
              } else {
                actualLine += settings.fieldSeparator;
                usedFildSeparators.push(settings.fieldSeparator);
              }
            }

            // jeśli element ma flagę optionalLineBreak i występujący po nim element może się znaleźć na początku wiersza
            // to dodajemy tymczasowy znacznik przełamania linii            
            var nextElementFirstInRow = (angular.isDefined(nextElem.firstInRow) && $.trim(nextElem.text) !== '') ? nextElem.firstInRow : settings.firstInRow;
            if (text !== '' && $.trim(nextElem.text) !== '' && !settings.doTruncate && element.optionalLineBreak && nextElementFirstInRow) {
              actualLine += settings.lineBreakTempElement;
              settings.optionalLineBreak = true;
            }

          }
        });

        
        if ($.trim(actualLine) !== '') {
          if(settings.doTruncate) {
            lines.push(actualLine.truncate(settings.maxLength, settings.truncateText));
          } else {
            if ($.trim(actualLine.length) > settings.maxLength && settings.optionalLineBreak && actualLine.indexOf(settings.lineBreakTempElement) !== -1) {
              var newLine = actualLine.split(settings.lineBreakTempElement);
              angular.forEach(newLine, function(element) {
                lines.push($.trim(element));
              });                
            } else {
              var reg = new RegExp(settings.lineBreakTempElement, 'g');
              actualLine = actualLine.replace(reg , '');
              lines.push(actualLine);
            }            
          }
        }

        //usuwamy "wiszące" na końcach wierszy separatory pól które mogły pozostać po wymuszonym przełamaniu linii
        if(usedFildSeparators.length) {
          angular.forEach(lines, function(element, index, linesArray) {
            angular.forEach(usedFildSeparators, function(separator) {
              if($.trim(separator) !== '') {
                var reg = new RegExp($.trim(separator)+'$', 'g');
                linesArray[index] = element.replace(reg , '');
              }
            });          
          });
        }

        // sklejamy linie
        var returnValue = lines.join(settings.lineSeparator);
        if (returnValue === '') {
          returnValue = settings.emptyValue;
        }

        return settings.html ? $filter('nl2br')(returnValue) : returnValue;
      };
      return trimInfo;
    }
  ]);