angular.module('ihestiaWidgets.policyOperations')
  .factory('communicationPoliciesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var CommunicationPoliciesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'policies/communicationPolicies';
        this.businessArea = 'insurance';
      };

      return new CommunicationPoliciesSvc();
    }]);