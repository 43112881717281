angular.module('lsnBase.detectorUserActvie')
  .service('detectorUserActiveHelper', ['$rootScope',
    function($rootScope) {
      var UserActiveHelper = function() {
        var self = this;

        this.lastActive = new XDate();
        this.userInactiveDelay = 60000;
        this.activeTab = true; // czy jestesmy wlasnie na tej zakladce
        this.callbacks = {
          onTabActivate: {},
          onTabDeactivate: {},
          onUserServiceReactive: {}, // jesli uzytkownik ponownie wznowil swoja aktywnosc wzgledem serwisow, troche skrocony czas
          onUserReactive: {} // jesli uzytkownik ponownie wznowil swoja aktywnosc
        };

        this.sessionHasExpired = false;

        $rootScope.$on('iHestia.Session.Expired', function() { // eslint-disable-line angular/on-watch
          self.sessionHasExpired = true;
        });

        this.touch = function() {
          if (!self.sessionHasExpired) {
            if (!self.userWasActive()) {
              self.callCallbacksOnUserReactive();
              self.clearRegisteredAction('onUserReactive');
            }

            self.lastActive = new XDate();
          }
        };

        /**
         * Funkcja do sprawdzania, czy uzytkownik był aktywny w ostatnim czasie
         * @param  {boolean} checkForService czy weryfikacja dla servisu
         */
        this.userWasActive = function() {
          if (self.lastActive.diffSeconds(new XDate()) > self.userInactiveDelay) {
            return false;
          }

          return true;
        };

        this.setActiveTab = function(active) {
          if (self.activeTab !== active) {
            if (active) {
              // klikniecie w aktulana zakladke rowniez powinno odpalac touch
              self.touch();
              self.callCallbacksOnTabActivate();
            } else {
              self.callCallbacksOnTabDeactivate();
            }

            self.activeTab = active;
          }
        };

        /**
         * [callCallbacksOnTabActivate wywolanie callbackow na aktywacje tab przegladarki]
         * @return {[type]} [description]
         */
        this.callCallbacksOnUserReactive = function() {
          angular.forEach(self.callbacks.onUserReactive, function(callback) {
            if (angular.isFunction(callback)) {
              callback();
            } else if (angular.isArray(callback)) {
              // wsparcie dla wielu callbacków pod jednym kontekstem
              angular.forEach(callback, function(callbackItem) {
                callbackItem();
              });
            }
          });
        };

        /**
         * [callCallbacksOnTabActivate wywolanie callbackow na aktywacje tab przegladarki]
         * @return {[type]} [description]
         */
        this.callCallbacksOnTabActivate = function() {
          angular.forEach(self.callbacks.onTabActivate, function(callback) {
            if (angular.isFunction(callback)) {
              callback();
            } else if (angular.isArray(callback)) {
              // wsparcie dla wielu callbacków pod jednym kontekstem
              angular.forEach(callback, function(callbackItem) {
                callbackItem();
              });
            }
          });
        };

        /**
         * [callCallbacksOnTabDeactivate wywolanie callbackow na deaktywacje tab przegladarki]
         * @return {[type]} [description]
         */
        this.callCallbacksOnTabDeactivate = function() {
          angular.forEach(self.callbacks.onTabDeactivate, function(callback) {
            if (angular.isFunction(callback)) {
              callback();
            } else if (angular.isArray(callback)) {
              // wsparcie dla wielu callbacków pod jednym kontekstem
              angular.forEach(callback, function(callbackItem) {
                callbackItem();
              });
            }
          });
        };

        /**
         * [registerCallbacks rejestrujemy callbacki]
         * @param  {[type]}   callbackName [nazwa/typ callbacku]
         * @param  {[type]}   contextName  [description]
         * @param  {Function} callback     [description]
         * @param  {[type]}   multipleMode [description]
         * @return {[type]}                [description]
         */
        this.registerCallbacks = function(callbackName, contextName, callback, multipleMode) {
          if (multipleMode) {
            if (!angular.isArray(self.callbacks[callbackName][contextName])) {
              self.callbacks[callbackName][contextName] = [];
            }
            self.callbacks[callbackName][contextName].push(callback);
          } else {
            self.callbacks[callbackName][contextName] = callback;
          }
        };

        /**
         * Czyścimy callbacki dla zadanego kontekstu
         * @param  {String} contextName nazwa kontekstu
         * @param  {Function|null} callbackRef referencja funkcji do usunięcia (jeśli nie chcemy usuwać wszytkiego)
         * @return {LabelActionHelper} self
         */
        this.clearRegisteredAction = function(callbackName, contextName, callbackRef) {
          if (self.callbacks[callbackName] && self.callbacks[callbackName][contextName]) {
            if (callbackRef && angular.isArray(self.callbacks[callbackName][contextName])) {
              // jeśli chcemy usunąć konkretną funkcję, to iterujemy się po tablicy i usuwamy tylko odopowiednie referencje
              // robimy to forem od końca, aby móc usuwać elementy bez dodatkowych zmiennych (nie psujemy pętli)
              for (var i = self.callbacks[callbackName][contextName].length - 1; i >= 0; i--) {
                if (self.callbacks[callbackName][contextName][i] === callbackRef) {
                  self.callbacks[callbackName][contextName].splice(i, 1);
                }
              }
            } else {
              delete self.callbacks[callbackName][contextName];
            }
          } else if (self.callbacks[callbackName]) {
            self.callbacks[callbackName] = {};
          }

          return self;
        };
      };

      return new UserActiveHelper();
    }
  ]);