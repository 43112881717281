angular.module('ihestiaIkomunikator')
  .service('iKomunikatorMessagesThreadSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IKomunikatorMessagesThreadSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/messageThreads';
        this.businessArea = 'ikomunikator';

        this.findThreadsFromCache = function(tplData, callback, errorCalback) {
          var data = {
            cacheId : tplData.cacheId,
            pageNo : tplData.searchFilters.pageSpec.pageNo,
            searchFor : tplData.searchFor
          };


          if(tplData.searchFor){
            return self.get(null, {
              searchFor: data.searchFor
            }, 'findLoggedUserMessageThreads/' + data.cacheId + '/' + data.pageNo, callback, errorCalback);
          } else {
            return self.get(null, null, 'findLoggedUserMessageThreads/' + data.cacheId + '/' + data.pageNo, callback, errorCalback);
          }

        };

        this.findLoggedUserMessageThreads = function(data, callback, errorCalback) {
          return self.post(null, data, 'findLoggedUserMessageThreads', callback, errorCalback);
        };

        this.findLoggedUserMessageThread = function(id, callback, errorCalback) {
          return self.get('findLoggedUserMessageThread', null, id, callback, errorCalback);
        };

        this.findDashboardMessageThreads = function(data, callback, errorCalback) {
          return self.post(null, data, 'findDashboardMessageThreads', callback, errorCalback);
        };

        this.findDashboardMessageThread = function(id, callback, errorCalback) {
          return self.get(null, null, id, callback, errorCalback);
        };
      };

      return new IKomunikatorMessagesThreadSvc();
    }
  ]);