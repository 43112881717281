angular.module('ihestiaCommonDirectives')
.directive('buttonRipple',[ 
  function() {
    return {
      restrict: 'A',
      link: function (scope, element) {
        var x, y, size, offsets,
          func = function(arip){
            var ripple = this.querySelector('.button-ripple');
            var eventType = arip.type;
            // Ripple
            if (ripple === null) {
              // Create ripple
              ripple = document.createElement('span'); //eslint-disable-line no-undef
              ripple.className += ' button-ripple';

              // Prepend ripple to element
              this.insertBefore(ripple, this.firstChild);

              // Set ripple size
              if (!ripple.offsetHeight && !ripple.offsetWidth) {
                size = Math.max(element[0].offsetWidth, element[0].offsetHeight);
                ripple.style.width = size + 'px';
                ripple.style.height = size + 'px';
              }
            }

            // Remove animation effect
            ripple.className = ripple.className.replace(/ ?(animate)/g, '');

            // get click coordinates by event type
            if (eventType === 'mouseup') {
              x = arip.pageX;
              y = arip.pageY;
            } else if (eventType === 'touchend') {
              try {
                var origEvent;

                if (typeof arip.changedTouches !== 'undefined') {
                  origEvent = arip.changedTouches[0];
                } else {
                  origEvent = arip.originalEvent;
                }

                x = origEvent.pageX;
                y = origEvent.pageY;
              } catch (ev) {
                // fall back to center of el
                x = ripple.offsetWidth / 2;
                y = ripple.offsetHeight / 2;
              }
            }

            // set new ripple position by click or touch position
            function getPos(element) {
              var de = document.documentElement; //eslint-disable-line no-undef
              var box = element.getBoundingClientRect();
              var top = box.top + window.pageYOffset - de.clientTop; //eslint-disable-line no-undef
              var left = box.left + window.pageXOffset - de.clientLeft; //eslint-disable-line no-undef
              return { top: top, left: left };
            }

            offsets = getPos(element[0]);
            ripple.style.left = (x - offsets.left - size / 2) + 'px';
            ripple.style.top = (y - offsets.top - size / 2) + 'px';

            // Add animation effect
            ripple.className += ' animate';
          };

        element.on('touchend mouseup', func);

        //remove the event listener on scope destroy
        scope.$on('$destroy',function() {
          element.off('touchend mouseup', func);
        });
      }
    };
  }]);