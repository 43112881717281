angular.module('iSkanerAztecs.base').factory('iSkanerAztecsListSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
	var iSkanerAztecsListSvc = function() {
		IHestiaRestServiceAbstractDef.apply(this, arguments);
		var self = this;

		this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{apiVer}/{resource}{id}{subResource}';
		this.businessArea = 'ehscanner';
		this.resource = 'admin/aztec';

		this.getAztecList = function(token) {
			return self.get(null, {
				token: token
			});
		};

		this.deleteAztec = function(aztecId, token) {
			return self.remove( aztecId + '?token=' + token);
		};

		this.downloadAztec = function(aztecId, token) {
			return self.get(aztecId, {
				token: token
			});
		};
	};

	return new iSkanerAztecsListSvc();
}]);