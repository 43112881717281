angular.module('salesPath2')
  .factory('BonusMalusNewModel', [
    function ()
    {

      var BonusMalusNewModel = function ()
      {
        this.history5YearsCount = null; //"5"
        this.last2YearsDamagesCount = null; //"0"
        this.last5YearsDamagesCount = null; //"1"
        this.noUfgData = null; //"false" //brak historii w UFG
        this.ufgConnectionProblem = null; //"false" //problem z połączeniem z UFG
      };

      return BonusMalusNewModel;
    }
  ]);