angular.module('lsnBase.models')
.factory('LsnWorkerModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnWorkerModelV1 = function()
    {
      this.objectName = 'Worker';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        subjectKeyString: {
          type: 'string'
        },
        symbol: {
          type: 'string'
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        }
      };

      this.subjectKeyString = null; //  string  Identyfikator podmiotu sieci sprzedaży.
      this.symbol = null; //  string  Symbol pracownika.
      this.firstName = null; // string  Imię
      this.lastName = null; //  string  Nazwisko
    };

    LsnWorkerModelV1.prototype = LsnAbstractModel.prototype;

    return LsnWorkerModelV1;
  }]);