angular.module('salesPath2')
  .service('summaryProductsClausesHelper', ['mainDataContainer', 'actionHelper', 'sp2CommonHelper', 'appVariables', 'dataContainerHelper', 'productClausesSvc', 'sp2UiHelper', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'CONSTANTS',
    function(mainDataContainer, actionHelper, sp2CommonHelper, appVariables, dataContainerHelper, productClausesSvc, sp2UiHelper, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, CONSTANTS) {
      var SummaryProductsClausesHelper = function() {
        var self = this;
        /**
         * podsumowanie klauzule
         * @type {Object}
         */

        this.modalData = {
          clauses: [],
          loading: false,
          policyId: '',
          query: '',
          policy: null,
          disabled: appVariables.readOnly || !sp2CommonHelper.isSalesCharacter()
        };

        //ustawienia modali
        this.modals = {};

        this.modals.summaryProductsClausesModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.summary.policyAction.clausesProducts',
            size: 'md',
            okBtnName: 'sp2.modal.close',
            cancelBtnName: false
          }
        };
        
        /**
           * reakcja na akcje w aplikacji
           * @param  {String} actionName nazwa wykonanej akcji
           */
        this._afterAction = function(actionName) {        
          switch (actionName) {
            case 'policiesReceived':
              var dcClauses = dataContainerHelper.getProductClauses();
              if (!dcClauses || !dcClauses[self.modalData.policyId]) {
                break;
              }
              break;
            default:
              break;
          }
          return true;
        };

        /**
         * Pobranie danych klauzul i wyświetlenie modala
         */
        this.showProductsClausesModal = function() {
          sp2CommonHelper.showModal('summaryProductsClausesModal');
        };

        this.getClauses = function(policy) {
          self.modalData.loading = true;
          sp2UiHelper.showBlockUi();

          var policyData = policy.getData();
          policyData.objects = policy.objects;
          self.modalData.policy = policyData;
          self.modalData.policyId = policy.number;
          self.modalData.clauses = [];          
          self.modalData.disabled = appVariables.readOnly || !sp2CommonHelper.isSalesCharacter();
          var id = appVariables.appMode === CONSTANTS.APP_MODE_POLICY ? self.modalData.policy.number : mainDataContainer.application.metaData.id;
          var offerId = appVariables.appMode !== CONSTANTS.APP_MODE_POLICY ? self.modalData.policy.product.dynamicValues._hash : null;

          productClausesSvc.getClauses(id, appVariables.appMode, offerId, function(response) {
            self.modalData.loading = false;
            sp2UiHelper.hideBlockUi();
            self.setClauses(policy, response.data);
            self.setModalButtons();
            self.showProductsClausesModal();
          }, function(response) {
            if (response && response.data) {
              self.setClauses(policy, response.data);
              self.setModalButtons();
            }
            self.modalData.loading = false;
            sp2UiHelper.hideBlockUi();
            
            sp2CommonHelper.showModal('productsClausesErrorsModal');
          });
        };

        this.isEditable = function() {
          return !(this.modalData.disabled || self.modalData.clauses.length === 0);
        };

        this.setClauses = function(policy, clauses) {
          // group clauses by code and sum premium values
          var groupedClauses = {};
          angular.forEach(clauses, function(clause) {
            if (!groupedClauses[clause.code]) {
              clause.groups = [{
                idPmCategoryList: clause.data.idPmCategoryList,
                premium: clause.data.premium || 0
              }];
              clause.data.premium = clause.data.premium || 0;
              groupedClauses[clause.code] = clause;
            } else {
              groupedClauses[clause.code].data.premium += clause.data.premium || 0;
              groupedClauses[clause.code].groups.push({
                idPmCategoryList: clause.data.idPmCategoryList,
                premium: clause.data.premium || 0
              });
            }
          });

          self.modalData.clauses = Object.values(groupedClauses); 
        };

        this.setModalButtons = function() {
          self.modals.summaryProductsClausesModal.settings.okBtnName = this.isEditable() ? 'sp2.modal.okBtnName' : 'sp2.modal.close';
          self.modals.summaryProductsClausesModal.settings.cancelBtnName = this.isEditable() ? 'sp2.modal.cancel' : false;
        };

        this.expandClause = function(clause) {          
          if (clause.showExtended) {
            clause.showExtended = false;
          } else {
            self.modalData.clauses.forEach(function(clause) {
              clause.showExtended = false;
            });
            self.modalData.clauses[self.modalData.clauses.indexOf(clause)].showExtended = true;
          }
        };

        /**
         * Zapisanie klauzul
         */
        this.saveProductClauses = function() {
          if(!this.isEditable()) {
            return;
          }
  
          var selectedClauses = self.modalData.clauses.filter(function(clause) {
            return clause.data.selected;
          });
  
          var clauses = dataContainerHelper.getProductClauses();
  
          clauses[self.modalData.policyId] = {
            idpm: [],
            clauseCode: [],
            clauseVer: [],
            clauseName: [],
            premium: [],
            dateOfLastUpdate: [],
            orderIndex: [],
            coverageOption: []
          };

          angular.forEach(selectedClauses, function(clause) {
            angular.forEach(clause.groups, function(group) {
              angular.forEach(group.idPmCategoryList, function(category) {
                clauses[self.modalData.policyId].idpm.push(category.idPm);
                clauses[self.modalData.policyId].clauseCode.push(clause.code);
                clauses[self.modalData.policyId].clauseVer.push(clause.ver);
                clauses[self.modalData.policyId].clauseName.push(clause.data.name);
                clauses[self.modalData.policyId].premium.push(group.premium);
                clauses[self.modalData.policyId].dateOfLastUpdate.push(clause.lastUpdate);
                clauses[self.modalData.policyId].orderIndex.push(clause.data.orderIndex);
                clauses[self.modalData.policyId].coverageOption.push(category.category ? category.category : '');
              });
            });
          });
  
          dataContainerHelper.setProductClauses(clauses);
  
          actionHelper.runAction('productClausesEdited');
          actionHelper.runAction('saveApplication');
        };

      };

      return new SummaryProductsClausesHelper();
    }
  ])
  .run(['summaryProductsClausesHelper', 'actionHelper',
    function(summaryProductsClausesHelper, actionHelper) {
      actionHelper.registerHelper('summaryProductsClausesHelper', summaryProductsClausesHelper, [
        'policiesReceived'
      ]);
    }
  ]);
