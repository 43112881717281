angular.module('iSkanerLsn').factory('iSkanerTokenSvc', ['$window', '$q', 'IHestiaRestServiceAbstractDef',
 function($window, $q, IHestiaRestServiceAbstractDef) {
	var iSkanerTokenSvc = function() {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    var self = this;

    this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{apiVer}/{resource}{id}{subResource}';
    this.businessArea = 'ehscanner';
    this.resource = 'admin/token';

		var tokens = JSON.parse($window.sessionStorage.getItem('iSkanerPolicies_tokens')) || {};

		this.fetch = function(dataToPost) {
			var deferred = $q.defer();
			var dataType = dataToPost.componentId;

			if(dataToPost.dataType === 'NEWDEVICE' && dataToPost.componentId === 'aztecuploader') {
				dataToPost.componentId = dataToPost.componentId + '_NEWDEVICE';
			}

			if (tokens[dataType]) {
				deferred.resolve(tokens[dataType]);
			} else {
				self.post(self.url, dataToPost, null, null, null, {allowedStatuses: [403]})
					.then(function(response) {
						tokens[dataType] = response.data.token;
						self.saveTokenToSessionStorage();
						deferred.resolve(response.data.token);
					}, function(error) {
						delete tokens[dataType];
						self.saveTokenToSessionStorage();
						deferred.reject(error);
					});
			}

			return deferred.promise;
		};

		this.reset = function(key) {
			if (key) {
				delete tokens[key];
			} else {
				tokens = {};
			}
			self.saveTokenToSessionStorage();
		};

		this.saveTokenToSessionStorage = function() {
			if (Object.keys(tokens).length !== 0) {
				$window.sessionStorage.setItem('iSkanerPolicies_tokens', JSON.stringify(tokens));
			} else if (Object.keys(tokens).length === 0 && $window.sessionStorage.getItem('iSkanerPolicies_tokens')) {
				$window.sessionStorage.removeItem('iSkanerPolicies_tokens');
			}
		};
	};

	return new iSkanerTokenSvc();
}]);