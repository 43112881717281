/**
* Dyrektywa do wrzucenia szablonu sidebara z ustawieniami grida
* @param  {[type]}  [description]
* @return {[type]}                 [description]
*/
angular.module('ihestiaGridSettings')
  .directive('ihestiaGridSettingsSidebar', ['$templateCache',
    function($templateCache) {

      var directive = {
		restrict: 'E',
		replace: true,
        template: $templateCache.get('ihestiaGridTemplatesApp/gridSettings/ihestiaGridSettings.tpl.html')
      };

      return directive;
    }
  ]);