angular.module('ihestiaCommonDirectives.binaryUpload')
	.factory('BinaryUploadTransport', ['$http', 'IHestiaRestServiceProviderAbstractDef', 'ihestiaConfigHelper', 'filesSvc', '$q', '$timeout', 'binaryUploadMessage', 'BinaryChunkedUploadTransport',
		function($http, IHestiaRestServiceProviderAbstractDef, ihestiaConfigHelper, filesSvc, $q, $timeout, binaryUploadMessage, BinaryChunkedUploadTransport) {
			var BinaryUploadTransport = function() {
				var self = this;

				this.sendingDeffer = null;
				this.promiseToCancel = $q.defer();
				this.chunkedTrasport = new BinaryChunkedUploadTransport();

				this.getHeadersForSending = function(md5) {
					var provider = new IHestiaRestServiceProviderAbstractDef();
					var headers = provider.getNewRequestHeaders();
          headers['AP-Unique-Request-Id'] = provider.getUniqueRequestId();
          headers['Ap-Contract-Req-Type'] = 'ACTION';
          headers['Ap-Contract-Process-Type'] = 'SYNC';
          headers['Content-MD5'] = md5;
          headers['Content-Type'] = undefined;

					return headers;
				};

				/**
				 * [sendFile sluzy do fkatycznego wysylania pliku]
				 * @param  {[FileModelForBinary]} dataFile
				 * @return {[promise]}      [zwraca obietnice, jesli udalo sie plik wyslac to resolve, jesli nie to reject]
				 */
				this.sendFile = function(dataFile, url, chunkSize) {
					self.sendingDeffer = $q.defer();
					var headers = self.getHeadersForSending(dataFile.getMd5());

					if (chunkSize) { //eslit-diable-line - tutaj zrobic chunkowanie pliku, moze jakis helper czy cos
						self.chunkedTrasport.sendFile(dataFile, url, chunkSize, headers).then(function(fileServerId){
							self.sendingDeffer.resolve(fileServerId);
						}, function(res){
							if(self.uploadCanceled){
								self.sendingDeffer.reject('requestCancel');
							}else{
								self.onErrorSendingFile(res);
							}

						});
					} else {
						$http({
							method: 'POST',
							url: url,
							headers: headers,
							timeout: self.promiseToCancel.promise.then(angular.noop, angular.noop),
							data: dataFile.getDataFileForSendingRequest(),
							transformRequest: angular.identity
						}).then(function(res) {
							if (res.status === 200) {
								if (res.data.fileServerId) {
									self.sendingDeffer.resolve(res.data.fileServerId);
								} else {
									self.sendingDeffer.reject({
										data: res.data,
										text: binaryUploadMessage.tokenNotReturnedByService
									});
								}
							} else {
								self.onErrorSendingFile(res);
							}
						}, function(res) {
							self.onErrorSendingFile(res);
						});
					}

					return self.sendingDeffer.promise;
				};

				/**
				 * [onErrorSendingFile metoda wywolywana na bledzie z uslug podczas wysylki]
				 * @param  {[type]} res [description]
				 * @return {[type]}     [description]
				 */
				this.onErrorSendingFile = function(res) {
					var text = binaryUploadMessage.exceptionInternalServices;

					if (res.status === 403) {
						text = binaryUploadMessage.noPermissionToSaveFile;
					} else if (res.status === 401) {
						text = binaryUploadMessage.exceptionNoAuthentication;
					} else if (res.status === 413) {
						text = binaryUploadMessage.toLargeFile;
					} else if (res.status === 409 && res.getResponseHeader('AP-User-Status') === 'CHARACTER_CHANGED') {
						text = binaryUploadMessage.characterConflict;
						// @todo Piotrek - nie może być zależności od ihestiaCharacterConflictedHelper
						// ihestiaCharacterConflictedHelper.charactersConflicted(res.getResponseHeader('AP-User-Character')); //NOSONAR
					} else if (res.status === 409 && res.result && res.result.messageText) {
						text = res.result.messageText;
					} else if (res.status === 410) {
						text = binaryUploadMessage.exceptionFileNotSentProbablyMalware;
					} else if (angular.isDefined(res.result) && angular.isArray(res.result.messages) && res.result.messages.length > 0) {
						text = res.result.messages;
					}

					self.sendingDeffer.reject({
						data: res.data,
						text: text
					});
				};

				/**
				 * Metoda wysyła informacje o pliku po kolei po wszystkich plikach do wysłania
				 * @param  {string} scopeName  nazwa scope
				 */
				this.sendInfoAboutFile = function(fileDataInfo, processedFileAttributes) {
					return $q(function(resolve, reject) {
						filesSvc.post('', fileDataInfo, '', function(response) {
							var data = response.data;
							if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
								if ($.isEmptyObject(data)) {
									var text = binaryUploadMessage.exceptionInternalServices;
									reject({
										data: data,
										text: text
									});
								} else {
									$timeout(function() {
										var allAttrValid = true;
										angular.forEach(processedFileAttributes, function(attr) {
											angular.forEach(data.messages, function(msg) {
												if (attr.code === msg.object) {
													allAttrValid = false;
												}
											});
										});
										if (allAttrValid) {
											reject({
												data: data,
												text: data.messages
											});
										} else {
											reject({
												data: data,
												text: binaryUploadMessage.errorAttributesTypeDocument
											});
										}
									}, 0);
								}

							} else {
								resolve(data.tokenId);
							}
						}, function(res) {
							var text;
							if (res.status === 401) {
								text = binaryUploadMessage.exceptionNoAuthentication;
							} else if (res.status === 409) {
								text = binaryUploadMessage.characterConflict;
							} else {
								text = binaryUploadMessage.exceptionInternalServices;
							}

							reject({
								data: null,
								text: text
							});

						}, {
							doNotAskAgainOnConflict: true
						});
					});
				};

				/**
				 * [cancel metoda wywolywana na anulowanie wysylania]
				 * @return {[type]} [description]
				 */
				this.cancel = function(){
					if(angular.isObject(self.sendingDeffer)){
						self.uploadCanceled = true;
						self.chunkedTrasport.cancel();
						self.promiseToCancel.resolve('requestCancel');
						self.sendingDeffer.reject('requestCancel');
					}
				};

				this.clearCancel = function(){
					self.uploadCanceled = false;
					self.chunkedTrasport.clearCancel();
					self.promiseToCancel = $q.defer();
				};
			};

			return BinaryUploadTransport;
		}
	]);