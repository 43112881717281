angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskIcrDataModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper',
    '$alert', '$filter', '$timeout', 'newTaskIcrClientEditModalHelper', 'newTaskIcrDataModalHelper', '$rootScope',
    'newTaskIcrDataHelper',
    function($scope, ihestiaDashboardNewTaskHelper, $alert, $filter, $timeout, newTaskIcrClientEditModalHelper,
             newTaskIcrDataModalHelper, $rootScope, newTaskIcrDataHelper) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;

      $scope.multiselects = {
        medicalTestGroup: [],
        medicalTestSingle: []
      };

      $scope.multiselectLocalLang = {
        selectAll: $filter('translate')('Public.multiselectSelectAll'),
        selectNone: $filter('translate')('Public.multiselectSelectNone'),
        reset: $filter('translate')('Public.multiselectReset'),
        search: $filter('translate')('Public.multiselectSearch'),
        nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
        noOption: $filter('translate')('Public.multiselectNoOption')
      };

      $scope.setApi = function(api) {
        $scope.uploadApi = api;
      };

      $scope.uploaderOptions = {
        multiple: true,
        ngUploadFormOptions: {
          maxChunkSize: 4000000
        }
      };

      $scope.errors = [];

      $scope.changeClientData = function() {
        $scope.setMultiSelectsAttributes();
        $scope.helper.closeAndPreserveData();
        $timeout(function() {
          newTaskIcrClientEditModalHelper.showModal($scope.taskData).then(function() {
            $timeout(function() {
              newTaskIcrDataModalHelper.showModal($scope.taskData).then(lsnNg.noop, lsnNg.noop);
            });
          }, lsnNg.noop);
        }, 0).then(lsnNg.noop, lsnNg.noop);
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.setMultiSelects();
      };

      $scope.setMultiSelects = function() {
        if (angular.isArray($scope.taskData.attributes.db_medical_test_codes)) { // eslint-disable-line
          angular.forEach($scope.multiselects, function(items, multiSelectCode) {
            if ($scope.helper.dicts[multiSelectCode]) {
              angular.forEach($scope.helper.dicts[multiSelectCode], function(dictItem) {
                angular.forEach($scope.taskData.attributes.db_medical_test_codes, function(itemCode) {
                  if (itemCode === dictItem.code) {
                    dictItem.selected = true;
                    $scope.multiselects[multiSelectCode].push(dictItem);
                  }
                });
              });
            }
          });
        }
      };

      $scope.init();

      $scope.setMultiSelectsAttributes = function() {
        // is sent to icr
        $scope.taskData.attributes.db_medical_test_codes = []; // eslint-disable-line
        // values for display in dashboard
        $scope.taskData.attributes.db_medical_test_group = []; // eslint-disable-line
        $scope.taskData.attributes.db_medical_test_single = []; // eslint-disable-line
        var codes = [];

        // gathering selected codes
        angular.forEach($scope.multiselects, function(items) {
          angular.forEach(items, function(item) {
            if (item.selected) {
              codes.push(item.code);
            }
          });
        });

        // assigning code and label
        return newTaskIcrDataHelper.getIcrMultiSelectCodesMap(codes).then(function(dictMap) {
          angular.forEach(dictMap, function(items, dictCode) {
            angular.forEach(items, function(item) {
              $scope.taskData.attributes.db_medical_test_codes.push(item.code);
              if(dictCode === 'medicalTestGroup') {
                $scope.taskData.attributes.db_medical_test_group.push(item.label);
              } else {
                $scope.taskData.attributes.db_medical_test_single.push(item.label);
              }
            });
          });
        }, lsnNg.noop);
      };

      $scope.helper.confirm = function() {
        $scope.formSubmitted = true;
        if ($scope.createTaskForm.$valid) {
          $scope.$emit('showBlockUi');
          if ($scope.uploadApi.files.length > 0) {
            $scope.uploadApi.sendFiles({
              documentCode: 'AttachmentFromDashboardTask'
            }).then($scope.saveTask, function() {
              $scope.$emit('hideBlockUi');
            });
          } else {
            $scope.saveTask();
          }
        }
      };

      $scope.saveTask = function() {
        $scope.$emit('showBlockUi');
        $scope.setMultiSelectsAttributes().finally(function() {
          ihestiaDashboardNewTaskHelper.saveTask().then(function() {
            $alert({
              content: $filter('translate')('taskLinkedHasBeenCreated', {
                componentCode: 'Dashboard'
              }),
              type: 'success'
            });

            $rootScope.$broadcast('ihestiaDashboardTaskAdded');
            ihestiaDashboardNewTaskHelper.clearTaskData();
            $scope.helper.setPromiseAsResolved();
            $scope.helper.hideModal();
          }, $scope.displayIcrError).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        });
      };

      $scope.displayIcrError = function(response) {
        $scope.errors = [];
        var data = response.data ? response.data : response;
        if (data && angular.isArray(data.technicalMessages)) {
          angular.forEach(data.technicalMessages, function(messageObject) {
            if (messageObject.messageText) {
              $scope.errors.push(messageObject.messageText);
            }
          });
        }
        if ($scope.errors.length === 0) {
          $scope.errors.push($filter('translate')('Public.serviceError', {componentCode: 'Public'}));
        }

      };

      $scope.helper.cancel = function() {
        ihestiaDashboardNewTaskHelper.clearTaskData();
        $scope.helper.setPromiseAsRejected();
        return $scope.helper.hideModal();
      };
    }
  ])
;
