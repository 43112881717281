angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorCommentModel', ['IKomunikatorAbstractModel', function(IKomunikatorAbstractModel) {

    var IKomunikatorCommentModel = function() {
      var self = this;
      angular.extend(this, IKomunikatorAbstractModel);

      /**
       * Treść komentarza
       * @type {String}
       */
      this.body = '';

      /**
       * Data utworzenia komentarza
       * @type {Number}
       */
      this.creationDateTime = 0;

      /**
       * [creatorIdentifier description]
       * @type {String}
       */
      this.creatorIdentifier = '';

      /**
       * Id komentarza
       * @type {String}
       */
      this.id = null;

      /**
       * Id wiadomości
       * @type {String}
       */
      this.messageId = null;

      /**
       * Id wątku wiadomości
       * @type {String}
       */
      this.messageThreadId = null;

      this.initMock = function() {
        self.creatorIdentifier = '13';
        self.creationDateTime = (new XDate()).toString('yyyyMMdd');
      };
    };

    return IKomunikatorCommentModel;
  }]);