angular.module('iSkanerPolicies')
    .directive('iskanerPolicies', ['ihestiaCommonErrorHandler', 'iskanerCommonHelper', function(ihestiaCommonErrorHandler, iskanerCommonHelper) {
        return {
            restrict: 'A',
            replace: true,
            templateUrl: 'ihestia-commondirectives-template-app/iskaner/iSkanerPolicies/iSkanerPolicies.tpl.html',
            scope: {
                config: '=iskanerPolicies'
            },
            controller: 'iskanerPoliciesCtrl',
            link: function(scope, element) {
                activate();

                function activate() {
                    if (!isIskanerPoliciesConfigValid(scope.config)) {
                        element.addClass('iSkanerPolicies-disabled');
                    }
                }

                function isIskanerPoliciesConfigValid(input) {
                    if (!iskanerCommonHelper.validConfig(input)) {
                        return false;
                    }

                    if (!angular.isFunction(input.callbackSendFiles)) {
                        ihestiaCommonErrorHandler.throwException({
                            message: 'iSkaner config: callback must be a function'
                        });

                        return false;
                    }

                    return true;
                }
            }
        };
    }]);