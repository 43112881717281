angular.module('lsnBase.models')
  .service('strTrimModelFilter', [function(){
    return function(val)
    {
      if (!angular.isString(val))
      {
        return val;
      }
      return val.trim();
    };
  }]);