angular.module('ihestiaWidgets.router')
  .controller('widgetsRouterCtrl', ['$scope', '$state', '$stateParams', '$location',
    function($scope, $state, $stateParams, $location) {


      var vehicleOwnerChangeActions = ['vehiclePurchase', 'vehicleSell', 'vehicleSale', 'vehicleDonation']; //które akcje prowadzą do Oc nabywcy
      var vehicleOwnerChangeParams = ['policyNo', 'sellDate', 'regNumber', 'ehNumber', 'isGreenCardReturned', 'step', 'record', 'loadApplication', 'forward'];

      var policyTerminationMap = {  //które akcje prowadzą do rozwiązań
        estateSale: 'estateSale',
        withdrawal: 'policyTerminationWithdrawal',
        notice: 'policyTerminationNotice',
        extensionWithdrawal: 'policyExtensionTerminationWithdrawal',
        deregistrationAndDismantling: 'policyTerminationDeregistrationAndDismantling'
      };
      //reszta do rozwiązań

      // estateSale
      // withdrawal
      // notice
      // extensionWithdrawal
      // deregistrationAndDismantling


      // http://dash.localhost/#/router?action=withdrawal&policyNo=919000300904&operationDate=2016-12-09&ehNumber=123

      $scope.init = function(){
        var params = {};

        if(!$stateParams.action)
        {
          //brak akcji i nie wiemy dokąd skierować, więc przerzucamy na stronę główną
          $location.url('/');
        }
        else if(vehicleOwnerChangeActions.indexOf($stateParams.action) !== -1)
        {
          var stateName = $stateParams.action;
          if(stateName === 'vehicleSell')
          {
            stateName = 'vehicleSale';
          }
          if(stateName !== 'vehicleSale' && stateName !== 'vehicleDonation')
          {
            stateName = 'vehiclePurchase';
          }


          var ownerChaneParamsMap = {
            operationDate: 'sellDate'
          };

          //oc nabywcy
          angular.forEach($stateParams, function(value, key){
            if(vehicleOwnerChangeParams.indexOf(key) !== -1)
            {
              params[key] = value;
            }
            else if(ownerChaneParamsMap[key])
            {
              params[ownerChaneParamsMap[key]] = value;
            }
          });

          $state.go('main.' + stateName, params);
        }
        else if(angular.isDefined(policyTerminationMap[$stateParams.action]))
        {
          //rozwiązania
          var teminationParams = {
            loadApplication: 'applicationId',
            applicationId: 'applicationId',
            sellDate: 'operationDate',
            operationDate: 'operationDate',
            ehNumber: 'ehNumber',
            policyNo: 'policyNumber'
          };

          angular.forEach($stateParams, function(value, key){
            if(teminationParams[key])
            {
              params[teminationParams[key]] = value;
            }
          });

          $state.go('main.' + policyTerminationMap[$stateParams.action], params);
        }
        else
        {
          //nie znamy takiej akcji, więc żegnamy
          $location.url('/');
        }
      };

      $scope.init();

    }
  ]);
