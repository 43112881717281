angular.module('salesPath2')
  /**
   * helper do usług pobierających różne słowniki
   */
  .service('filesServiceHelper', ['ihestiaRestDictionaryAllElectronicDocumentTypesSvc',
    function(ihestiaRestDictionaryAllElectronicDocumentTypesSvc) {
      var DictionaryServiceHelper = function() {
        /**
         * pobiera dane dotyczące kodu pkd z usługi
         * @param  {String} code kod/symbol pkd
         * @return {HttpPromise}
         */
        this.getAllElectronicDocumentTypesSvc = function(id, filters, subResource, callback, errorCallback, httpParams) {
          return ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(id, filters, subResource, callback, errorCallback, httpParams);
        };


      };
      return new DictionaryServiceHelper();
    }
  ]);