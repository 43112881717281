angular.module('salesPath2')
  .controller('extensionsCtrl', ['$scope', 'extensionHelper', '$timeout', 'extensionsTplHelper', 'sp2SelectionHelper',
    function($scope, extensionHelper, $timeout, extensionsTplHelper, selectionHelper) { // eslint-disable-line angular/di
      $scope.extsTplData = extensionsTplHelper.tplData;
      $scope.clickedExt = null; //kod kliknietego rozszerzenia

      /**
       * init
       */
      $scope.init = function() {
        extensionsTplHelper.refreshExtDefs();
      };

      /**
       * zaznacza.odznacza rozszerzenie
       * @param  {Object} ext dane rozszerzenia
       * @return {Boolean} czy przelaczono rozszerzenie
       */
      $scope.toggleExtension = function(ext) { //eslint-disable-line consistent-return
        if ($scope.appVariables.readOnly || !extensionHelper.extIsAvailable(ext.CODE)) {
          return false;
        }

        selectionHelper.toggleExt(ext.CODE);

        //czy chować modal z dodatkiem po wybraniu
        if ($scope.CONFIG.BEHAVIOR.hideModalAfterExtToggled) {
          $scope.clickedExt = null;
        }
        ext.tplData.isSelected = extensionHelper.isExtSelected(ext.CODE);
        extensionsTplHelper.refreshExtsData();
      };

      /**
       * klikniecie w rozszerzenie
       * @param  {String} code kod dodatku
       */
      $scope.clickExt = function(code) {
        var newClickedExt = (code === $scope.clickedExt) ? null : code;
        $timeout(function() {
          $scope.clickedExt = newClickedExt;
        }, 0);
      };

      /**
       * klikniecie poza panel rozszerzenia
       * @param  {String} code kod rozszerzenia
       */
      $scope.clickOutsideExtPanel = function(code) {
        if ($scope.clickedExt !== code) {
          return; //jesli klik poza rozszerzenie inne niz aktualnie rozwiniete, to nic nie robimy
        }
        if ($scope.clickedExt !== null) {
          $scope.clickedExt = null;
        }
      };

      $scope.init();

    }
  ]);