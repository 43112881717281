//main helper for salesPath operations
angular.module('salesPath2')
  .service('applicationHelper', ['$timeout', 'navigationHelper', 'sp2UiHelper', 'actionHelper', 'sp2CommonHelper', 'applicationServiceHelper', 'appVariables', 'CONSTANTS', 'mainDataContainer', 'lsnModelHelper', 'ApplicationModelConstants', 'ApplicationModel', 'personHelper', 'personServiceHelper', 'MapperUtils', 'applicationDictionaryHelper', 'VehicleModel', 'RequestApplicationsPolicyModel', 'PolicyModel', 'applicationsPrintoutsSvc', '$injector', 'policiesServiceHelper', 'PolicyDiscountsModel', 'SPD', 'customerSvc', 'CONFIG', 'base64', 'ClausesModelConstants', 'fromRestMessageMapper', 'MessageModel', '$filter', 'toRestApplicationMapper', 'fromRestApplicationMapper', 'fromRestPolicyMapper', 'bonusMalusHelper', 'sp2SelectionHelper', 'lsnDeepExtend', 'clientAddHelper', 'AddressModel', 'dataContainerHelper', 'ConfigResource', 'policiesPrintoutsSvc', 'ihestiaDownloadHelper', 'policiesSvc', 'applicationsSvc', 'ihestiaExtraLifeHelper', 'lsnUtils', 'clientVerificationHelper',
    function($timeout, navigationHelper, sp2UiHelper, actionHelper, sp2CommonHelper, applicationServiceHelper, appVariables, CONSTANTS, mainDataContainer, lsnModelHelper, ApplicationModelConstants, ApplicationModel, personHelper, personServiceHelper, MapperUtils, applicationDictionaryHelper, VehicleModel, RequestApplicationsPolicyModel, PolicyModel, applicationsPrintoutsSvc, $injector, policiesServiceHelper, PolicyDiscountsModel, SPD, customerSvc, CONFIG, base64, ClausesModelConstants, fromRestMessageMapper, MessageModel, $filter, toRestApplicationMapper, fromRestApplicationMapper, fromRestPolicyMapper, bonusMalusHelper, selectionHelper, lsnDeepExtend, clientAddHelper, AddressModel, dataContainerHelper, ConfigResource, policiesPrintoutsSvc, ihestiaDownloadHelper, policiesSvc, applicationsSvc, ihestiaExtraLifeHelper, lsnUtils, clientVerificationHelper) { // eslint-disable-line angular/di
      var ApplicationHelper = function() {
        var self = this;
        this.SERVICE_TYPE_TARIFFICATION = 'tarifficate';
        this.SERVICE_TYPE_APPLICATION_SAVE = 'saveApplication';
        this.SERVICE_TYPE_CONVERT_TO_OFFER = 'convertToOffer';
        this.SERVICE_TYPE_CONVERT_TO_IOFFER = 'convertToIOffer';
        this.SERVICE_TYPE_CONVERT_TO_IOFFER_EMAIL = 'convertToIOfferEmail';
        this.SERVICE_TYPE_CONVERT_TO_CALCULATION = 'convertToCalculation';
        this.SERVICE_TYPE_RESERVE_POLICY_NUMBER = 'reservePolicyNumber';
        this.DEFAULT_TARIFFICATE_DELAY = 500;
        /**
         * @type Array.<string> pola datamanagera które będą aktualizowane (1 do 1 z tmpDataManagera) w metodzie _updateDataContainer
         */
        this.dataManagerFieldsToUpdate = ['premiumList', 'tariffPremiumList', 'finalPremiumList', 'renewedList', 'premBeforeDiscList', 'premPureBeforeDiscList', 'premAfterDiscList', 'premPureAfterDiscList', 'summedPremiumList', 'tariffFactors', 'bonusMalus', 'policiesDiscounts', 'policies'];
        /**
         * statusy wniosków nieaktywnych wymagających specjalnej obslugi
         * @type {String[]}
         */
        this.inactiveApplicationStatuses = [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_DELETED, ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_EXPIRED, ApplicationModelConstants.APPLICATION_STATUS_OFFER_CANCELLED, ApplicationModelConstants.APPLICATION_STATUS_IOFFER_CANCELLED];
        /**
         * statusy wniosku dla typu "wniosek" (nie oferta)
         * @type {String[]}
         */
        this.calculationStatuses = [ApplicationModelConstants.APPLICATION_STATUS_CALCULATION, ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_ACCEPTED, ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_DELETED, ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_EXPIRED];
        //#pragma mark serviceCall
        /**
         * czy nastąpiţo żądanie zapisu wniosku lub taryfikacji
         * obiekt jest czyszczony w momencie
         * @type (object)
         * type - null gdy brak żądania, 't' - gdy taryfikacja, 's' - gdy zapis wniosku
         * simulation - bool - czy tryb symulacji
         */
        this.callService = {
          type: null,
          simulation: false
        };
        /**
         * czas oczekiwania miedzy kolejnymi wywolaniami uslugi
         * @type int sekundy
         */
        this.serviceTimeout = 2;
        this.servicePromise = null; //ustawiony timeout
        this.callInProgress = false; //trwa przetwarzanie żądania w usłudeze REST
        this.getPolicies = false; //czy wysłano żądanie pobrania polis na podstawie zapisanego wniosku (ekran podsumowania)
        this.acceptPolicies = false; //czy wysłano żądanie zatwierdzenia polis na podstawie zapisanego wniosku (ekran podsumowania)
        this.printOffer = null; //czy wysłano żądanie wydruku na podstawie zapisanego wniosku (koszyk). Jesli tak to przyjmuje kod wydruku (stala PRINT_CODE...)
        this.additionalApplicationCallback = null; //dodatkowy callback, który powinien być uruchomiony po zapisie/taryfikacji wniosku
        this.additionalPoliciesCallback = null; //dodatkowy callback, który powinien być uruchomiony po pobraniu polis na podstawie wniosku
        //wrzucamy coś tutaj jeśli jakąś akcję (np. obsługę błędów dotyczących polisy) możemy wykonać dopiero na pełnych polisach
        this.delayPromise = null;
        this.lastUniqueRequestId = null; //uniqueRequestId ostatniego zapisanego wniosku/symulacji/taryfikacji
        this.lastInsurerPesel = null; // lastly checked insurer's pesel in ExtraLife functionality

        /**
         * obiekt przechowujący dane ostatniego requestu w celu porównania elementów które zostały zmienione aby nie nadpisywać tych które nie zmieniły się. zmienna prywatna.
         * @type {Object}
         */
        this.lastRequest = {
          data: {},
          getData: function(key) {
            return this.data[key];
          },
          setData: function(d) {
            this.data = d;
          },
          clear: function() {
            this.data = {};
          }
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'tarifficate':
              self.tarifficate.apply(this, Array.prototype.slice.call(arguments, 1));
              break;
            case 'saveApplication':
              self.saveApplication.apply(this, Array.prototype.slice.call(arguments, 1));
              break;
            default:
              break;
          }
        };

        /**
         * rejestruje nowe żądanie zpisu/taryfikacji wniosku
         * @param {string} serviceType typ usługi - wartość this.SERVICE_TYPE_...
         * @param {bool} simulation czy wołać w trybie symulacji
         * @param {number|null} delay opznienei przy wolaniu uslui
         */
        this._registerServiceCall = function(serviceType, simulation, delay) {
          simulation = typeof simulation === 'boolean' ? simulation : false;
          if (!(self.callService.type === self.SERVICE_TYPE_APPLICATION_SAVE && serviceType === self.SERVICE_TYPE_TARIFFICATION)) {
            self.callService.type = serviceType;
          }
          self.callService.simulation = simulation;
          if (angular.isDefined(delay) && delay !== null) {
            $timeout.cancel(self.delayPromise);
            self.delayPromise = $timeout(self._checkAndCallService, delay);
          } else {
            self._checkAndCallService();
          }
        };
        /**
         * sprawdza czy istnieje żądanie wywołania usługi i jeśli tak, woła ją
         * @param {bool} deleteTimeout czy usunąć timeout id ostatniego żądania
         * @param {bool} forceCall czy wymusić wywołanie usługi mimo braku responsa z ostatnio wołanej
         */
        this._checkAndCallService = function(deleteTimeout, forceCall) {
          $timeout.cancel(self.delayPromise);
          self.delayPromise = null;
          deleteTimeout = (typeof deleteTimeout === 'boolean') ? deleteTimeout : false;
          forceCall = (typeof forceCall === 'boolean') ? forceCall : false;
          if (deleteTimeout || forceCall) {
            self.servicePromise = null;
          }
          if (!self.servicePromise && !self.callInProgress && self.callService.type !== null) {
            var application = toRestApplicationMapper.mapApplication(mainDataContainer),
              save = (self.callService.type === self.SERVICE_TYPE_APPLICATION_SAVE) ? true : false,
              serviceType = self.callService.type,
              simulation = self.callService.simulation;
            self.callInProgress = true;
            self.callService = { //reset ustawien
              type: null,
              simulation: false
            };
            self.servicePromise = $timeout(function() {
              self._checkAndCallService(true);
            }, self.serviceTimeout * 1000);
            //dopiero tu, ponieaz mock by nie dzialal prawidlowo - brak asynchronicznosci
            lsnModelHelper.minifyRestObject(application);
            actionHelper.runAction('tarifficationStarted');
            var requestInfo;
            if (serviceType === self.SERVICE_TYPE_CONVERT_TO_OFFER) {
              requestInfo = applicationServiceHelper.putOffer(application.metaData.id, application, self._applicationCallback, function(rej) {
                self.applConvertionFailedCallback(rej, $filter('translate')('errorsOccuredDuringMakingOffer', {
                  componentCode: 'sp2'
                }));
              });
            } else if (serviceType === self.SERVICE_TYPE_RESERVE_POLICY_NUMBER) {
              requestInfo = applicationServiceHelper.putOfferReservationNumber(application.metaData.id, null, self._applicationCallback, function(rej) {
                self.applConvertionFailedCallback(rej, $filter('translate')('errorsOccuredDuringReservingPolicyNumber', {
                  componentCode: 'sp2'
                }));
              });
            } else if (serviceType === self.SERVICE_TYPE_CONVERT_TO_IOFFER || serviceType === self.SERVICE_TYPE_CONVERT_TO_IOFFER_EMAIL) {
              requestInfo = applicationServiceHelper.putIOffer(application.metaData.id, null, self._applicationCallback, function(rej) {
                self.applConvertionFailedCallback(rej, $filter('translate')('errorsOccuredDuringSendingToAccount', {
                  componentCode: 'sp2'
                }), serviceType === self.SERVICE_TYPE_CONVERT_TO_IOFFER_EMAIL);
              }, null, serviceType === self.SERVICE_TYPE_CONVERT_TO_IOFFER_EMAIL);
            } else if (serviceType === self.SERVICE_TYPE_CONVERT_TO_CALCULATION) {
              requestInfo = applicationServiceHelper.putCalculation(application.metaData.id, application, self._applicationCallback, self._applicationCallback);
            } else {
              self.lastRequest.setData(application);
              self.checkExtraLife(application);
              requestInfo = applicationServiceHelper.sendApplication(application, simulation, save, self._applicationCallback, self._applicationCallback);
            }
            if (angular.isObject(requestInfo) && angular.isDefined(requestInfo.uniqueRequestId)) {
              self.lastUniqueRequestId = requestInfo.uniqueRequestId;
            }
          }
        };

        /**
         * callback błędnego ofertowania wniosku (oferta lub ioferta)
         * @param  {Object} rej reject z usługi
         * @param  {[type]} msg wiadomość o błędzie do modala
         * @param  {boolean} [emailIOffer=false] if "send iOffer via email" failed
         */
        this.applConvertionFailedCallback = function(rej, msg, emailIOffer) {
          actionHelper.runAction('applicationRequestFailed', {
            emailIOffer: emailIOffer
          });
          actionHelper.runAction('tarifficationEnded', {
            withError: true
          });
          sp2UiHelper.hideBlockUi();
          self.callInProgress = false;
          if (rej.status === 409) {
            self._handleFailureMessages(rej, msg);
          }
        };

        /**
         * zapisuje/taryfikuje wniosek ubezp.
         * @param {Boolean} simulation czy taryfikować wszystkie warianty ubezpieczeń (tryb symulacji)
         * @param {null|function} callback opcjonlany callback do wywolania po zapisie wniosku
         * @param {null|int} delay[ms]
         * @return {Boolean} czy jestesmy w stanie zapisac wniosek
         */
        this.saveApplication = function(simulation, callback, delay) {
          if (!self.canUpdateOrTarificateApplication()) {
            return false;
          }
          if (!self.hasFinalApplicationPermission('edit')) //brak uprawnień do zapisu wniosku/polisy
          {
            if (angular.isFunction(callback)) {
              callback();
            }
            self.checkSummary(); //chcemy załadować polisy na podumowaniu
            return false;
          }
          //zapis wniosku zawsze odbywa sie, gdy ubezpieczajacy jest wybrany
          appVariables.isInsurerChosen = true;
          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(self.SERVICE_TYPE_APPLICATION_SAVE, self._getSimulationValue(simulation), delay);
          self.checkSummary();
          return true;
        };

        /**
         * taryfikuje wniosek
         * @param  {Boolean} simulation czy taryfikować wszystkie warianty ubezpieczeń (tryb symulacji)
         * @param  {null|function} callback opcjonlany callback do wywolania po taryfikacji
         * @param {null|int} delay[ms]
         */
        this.tarifficate = function(simulation, callback, delay) {
          if (!self.canUpdateOrTarificateApplication()) {
            return;
          }
          //na ekranie podsumowania zawsze musimy zapisać wniosek żeby otrzymać taryfikację w podziale na polisy
          if (!self.hasFinalApplicationPermission('edit')) //brak uprawnień do zapisu wniosku/polisy
          {
            if (angular.isFunction(callback)) {
              callback();
            }
            self.checkSummary(); //chcemy załadować polisy na podumowaniu
            return;
          }

          if (navigationHelper.getCurrentState().name === CONSTANTS.PAGE_SUMMARY) {
            self.saveApplication(simulation, callback, delay);
            return;
          }

          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(self.SERVICE_TYPE_TARIFFICATION, self._getSimulationValue(simulation), delay);
        };

        /**
         * jeśli jesteśmy na stronie podsumowania to po każdej akcji odświeżamy polisy
         */
        this.checkSummary = function() {
          if (navigationHelper.getCurrentState().name === CONSTANTS.PAGE_SUMMARY) {
            actionHelper.runAction('loadingPolicies');
            self.getPoliciesForApplication(function() {
              actionHelper.runAction('policiesReceived');
            });
          }
        };

        /**
         * czy mozna zapisywac/taryfikowac wniosek
         * @return {boolean}
         */
        this.canUpdateOrTarificateApplication = function() {
          if ([CONSTANTS.APP_MODE_CALCULATION].indexOf(appVariables.appMode) !== -1 && mainDataContainer.mainInsuredId !== null) { //warunkiem koniecznym jest istnienie glownego ubezpieczonego oraz status "CALCULATION"
            return true;
          }
          return false;
        };

        /**
         * zwraca wartosc parametru symulacji
         * @param {*} simulation przekazana wartosc symulacji
         */
        this._getSimulationValue = function(simulation) {
          if (typeof simulation !== 'boolean') {
            if (navigationHelper.getCurrentState().name === CONSTANTS.PAGE_SUMMARY) { //jeśli jesteśmy na stronie pdsumowania to nie puszczamy symulacji
              simulation = false;
            } else {
              simulation = true;
            }
          }
          return simulation;
        };

        /**
         * obsługa responsa z zapisu wniosku/taryfikacji
         */
        this._applicationCallback = function(responseObject) { //eslint-disable-line consistent-return
          var res = responseObject.data;
          self.callInProgress = false;
          if (!angular.isObject(res) || angular.equals(res, {}) || responseObject.status !== 200) {
            actionHelper.runAction('applicationRequestFailed');
            actionHelper.runAction('tarifficationEnded', {
              withError: true
            });
            sp2UiHelper.hideBlockUi();
            self.callInProgress = false;
            self._applicationErrorCallback();
            self._handleFailureMessages(responseObject);
            return false; //b;ąd wczytywania wniosku
          }

          var appl = new ApplicationModel();
          appl.setData(res);
          if (angular.equals(mainDataContainer.availableOperations, {})) {
            self.setAvailableOperations(appl);
          }
          self._updateDataContainer(appl, mainDataContainer, {
            tarifficationMode: true
          });
          //BM ignore - EHNLEARN-981
          //ustawianie trybu aplikacji
          if (self.calculationStatuses.indexOf(appl.get('status')) === -1) {
            appVariables.appMode = CONSTANTS.APP_MODE_OFFER;
            appVariables.readOnly = true;
            actionHelper.runAction('readOnlyChanged', true);
          } else {
            appVariables.appMode = CONSTANTS.APP_MODE_CALCULATION;
          }

          //wydruk jeśli był zarejestrowany
          if (self.printOffer !== null && self.callService.type !== self.SERVICE_TYPE_APPLICATION_SAVE) {
            self.printApplicationOffer();
          }

          actionHelper.runAction('tarifficationEnded');
          //sprawdzanie czy nalezy kolejny raz zawolac usluge
          if (self.callService.type !== null) {
            self._checkAndCallService();
          } else { //brak żądań kolejnego zapisu/taryfikacji
            //wywolanie opcjonalnego callbacka
            if (angular.isFunction(self.additionalApplicationCallback)) {
              self.additionalApplicationCallback(res);
            }
            self.additionalApplicationCallback = null;
            if (self.acceptPolicies) {
              self.convertApplicationToPolicies();
            } else if (self.getPolicies) {
              self.getPoliciesForApplication(function() {
                actionHelper.runAction('policiesReceived');
              });
            }
          }
        };

        /**
         * callback po nieudanym zapisie/taryfikacji wniosku
         */
        this._applicationErrorCallback = function() {
          //wydruk jeśli był zarejestrowany
          if (self.printOffer !== null && self.callService.type !== self.SERVICE_TYPE_APPLICATION_SAVE) {
            self.printApplicationOffer();
          }
          //sprawdzanie czy nalezy kolejny raz zawolac usluge
          if (self.callService.type !== null) {
            self._checkAndCallService();
          } else { //brak żądań kolejnego zapisu/taryfikacji
            //wywolanie opcjonalnego callbacka
            if (angular.isFunction(self.additionalApplicationCallback)) {
              self.additionalApplicationCallback();
            }
            self.additionalApplicationCallback = null;
            if (self.acceptPolicies) {
              self.convertApplicationToPolicies();
            } else if (self.getPolicies) {
              self.getPoliciesForApplication();
            }
          }
        };

        //#pragma mark dataManager
        /**
         * update skladek i sum ubezpieczen
         * @param {Application} application wniosek
         * @param {DataContainer} dataManager mainDataContainer
         */
        this._updateDataContainer = function(application, dataManager, mappingOptions) {
          var tmpDc = fromRestApplicationMapper.mapApplication(application, mappingOptions);
          angular.forEach(self.dataManagerFieldsToUpdate, function(field) {
            dataManager[field] = tmpDc[field];
          });
          var prevApplDynVals = dataManager.application.get('dynamicValues'),
            newApplDynVals = application.get('dynamicValues'),
            dynValsToDelete = {};
          //usuwanie nieistniejacych juz dynamicValues
          angular.forEach(prevApplDynVals, function(val, name) {
            if (angular.isUndefined(newApplDynVals[name])) {
              dynValsToDelete[name] = null;
            }
          });

          // przepisz zaznaczone klauzule
          angular.forEach(tmpDc.policies, function(policy) {
            if(policy.product.dynamicValues._productClausesRetail) {
              dataManager.productClauses[policy.number] = policy.product.dynamicValues._productClausesRetail;
            }
          });

          dataManager.application.setData(self.filterByLastRequestAppl(application.getData(false, true), self.lastRequest.data));
          if (!angular.equals(dynValsToDelete, {})) {
            dataManager.application.set('dynamicValues', dynValsToDelete);
          }
          //uzupelnianie REST id dla osob/pojazdow/firm/nieruchomosci
          angular.forEach(dataManager.persons, function(person) {
            var restId = MapperUtils.getIdByClientId(tmpDc._subjectIdMap, person.metaData.get('clientId'));
            if (restId !== null) {
              var fromRestPerson = tmpDc.persons[person.metaData.get('clientId')];
              person.setData(self.filterByLastRequest(fromRestPerson.getData(false, true), self.lastRequest.getData('persons'), person.metaData.get('clientId')));
            }
          });
          angular.forEach(dataManager.organizations, function(organization) {
            var restId = MapperUtils.getIdByClientId(tmpDc._subjectIdMap, organization.metaData.get('clientId'));
            if (restId !== null) {
              var fromRestOrganization = tmpDc.organizations[organization.metaData.get('clientId')];
              organization.setData(self.filterByLastRequest(fromRestOrganization.getData(false, true), self.lastRequest.getData('organizations'), organization.metaData.get('clientId')));
            }
          });
          angular.forEach(dataManager.groups, function(group) {
            var restId = MapperUtils.getIdByClientId(tmpDc._subjectIdMap, group.metaData.get('clientId'));
            if (restId !== null) {
              var fromRestGroup = tmpDc.groups[group.metaData.get('clientId')];
              group.setData(self.filterByLastRequest(fromRestGroup.getData(false, true), self.lastRequest.getData('groups'), group.metaData.get('clientId')));
            }
          });
          angular.forEach(dataManager.vehicles, function(vehicle) {
            var restId = MapperUtils.getIdByClientId(tmpDc._vehicleIdMap, vehicle.metaData.get('clientId'));
            if (restId !== null) {
              var fromRestVehicle = tmpDc.vehicles[vehicle.metaData.get('clientId')];
              vehicle.setData(self.filterByLastRequest(fromRestVehicle.getData(false, true), self.lastRequest.getData('vehicles'), vehicle.metaData.get('clientId')));
            }
          });
          angular.forEach(dataManager.localizations, function(localization) {
            var restId = MapperUtils.getIdByClientId(tmpDc._estateIdMap, localization.metaData.get('clientId'));
            if (restId !== null) {
              var formRestLocalization = tmpDc.localizations[localization.metaData.get('clientId')];
              localization.setData(self.filterByLastRequest(formRestLocalization.getData(false, true), self.lastRequest.getData('estates'), localization.metaData.get('clientId')));
            }
          });
        };
        /**
         * ustawia dane w dataManagerze ze zmapowanego tempDataContainera
         * @param {type} tempDataContainer tymczasowy dataContainer (zmapowany wniosek)
         * @param {DataContainer} dataContainer dataContainer aplikacji
         */
        this.setDataContainer = function(tempDataContainer, dataContainer) {
          lsnNg.forEach(tempDataContainer, function(value, name) {
            if (name.substring(0, 1) === '_') {
              return;
            }
            if (angular.isObject(dataContainer[name])) {
              lsnDeepExtend(dataContainer[name], value);
            } else {
              dataContainer[name] = value;
            }
          });
        };
        //#pragma mark application actions
        /**
         * Wczytuje wniosek ubezpieczeniowy i przkierowuje na stronę matrycy
         * @param {string} id identyfikator wniosku
         */
        this.loadApplication = function(id) {
          sp2UiHelper.showBlockUi($filter('translate')('loadingApplication', {
            componentCode: 'sp2'
          }));
          return applicationServiceHelper.get(id, null, null, self._applicationLoadedCallback, function(reject) {
            if (reject.status === 403) {
              //użytkownik nie ma uprawnień do wniosku/oferty
              sp2UiHelper.hideBlockUi();
              sp2UiHelper.disableRender();
            } else {
              sp2UiHelper.hideBlockUi();
              actionHelper.runAction('applicationRequestFailed');
            }
          }, {
            allowedStatuses: [403]
          });
        };

        /**
         * callback po wczytaniu wniosku
         * @param  {Object} responseObject obiekt odpowiedzi z usługi
         * @return {Boolean} true jesli wczytano poprawnie
         */
        this._applicationLoadedCallback = function(responseObject) {
          var resp = responseObject.data;
          var spdAppSymbol = SPD.Application.idpm.toLowerCase();

          if (!angular.isObject(resp) || angular.equals(resp, {})) {
            sp2UiHelper.hideBlockUi();
            actionHelper.runAction('applicationRequestFailed');
            return false; //b;ąd wczytywania wniosku
          }

          if (angular.isString(resp.symbol) && spdAppSymbol !== resp.symbol.toLowerCase()) {
            sp2UiHelper.hideBlockUi();
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: 'sp2.modal.loadedCallbackErrorMsg',
              size: 'sm',
              okBtnCallback: function() {
                navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
              }
            });
            return false;
          }

          //sczytanie uprawnien do edycji dla charakterow sprzedazowych
          appVariables.canManage = resp.canManage;

          if (!self.hasFinalApplicationPermission('view', resp)) { //sprawdzanie uprawnien do podgladu wniosku (dodatkowe)
            sp2UiHelper.hideBlockUi();
            sp2UiHelper.disableRender();
            return false;
          }

          if (!self._processApplicationVersion(resp)) {
            return false;
          }

          appVariables.isInsurerChosen = true; //wnioske musi miec ubezpieczajacego
          appVariables.isSupplement = (resp.operationType === ApplicationModelConstants.APPLICATION_OPERATION_TYPE_EXTEND_INSURANCE); //czy w trybie dokupień
          appVariables.isOcBuyer = self.isOcBuyerApplication(resp);
          if (appVariables.isOcBuyer) {
            appVariables.readOnly = true;
            actionHelper.runAction('readOnlyChanged', true);
          }
          var appl = new ApplicationModel(),
            isExternalCreated = self.isExternalCreated(resp); //czy wniosek utworzony w innym systemie/aplikacji
          appVariables.isExternallyCreated = isExternalCreated;
          appl.setData(resp);
          if (appl.get('status') !== ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) { //jesli wczytana zostanie oferta/ioferta to ustawiamy odpowiedni tryb aplikacji
            appVariables.appMode = CONSTANTS.APP_MODE_OFFER;
            appVariables.readOnly = true;
            actionHelper.runAction('readOnlyChanged', true);
          } else {
            self.checkExtraLife(resp);
          }
          self.setApplicationPolicyType(appl);
          if (isExternalCreated) {
            MapperUtils.generateClientIds(appl);
            //jesli wznowienie wygenerowane w usłudze, to zapisujemy wniosek jako renewedApplication
            if (appl.operationType === ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW) {
              mainDataContainer.renewedApplication = angular.copy(appl.getData(false, true));
            }
          }
          self.setAvailableOperations(appl);
          var applDynVals = appl.get('dynamicValues');
          if (angular.isString(applDynVals._externalData) && applDynVals._externalData !== '') {
            //brak obsługi historycznych kalkulacji
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: 'sp2.modal.appWrongFormatErrorMsg',
              size: 'sm',
              okBtnCallback: function() {
                navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
              }
            });
          } else {
            var tmpDc = fromRestApplicationMapper.mapApplication(appl, {
              premium: true, //mapowanie skladek
              sumInsured: isExternalCreated, //mapowanie sum ubezpieczen
              additionalData: true, //mapowanie additionalData osób/firm/lokalizacji/pojazdów
              tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
              dataContainerProperties: true, //wszystko pozostalw dotyczące wniosku i dataManagera
              initialLoad: true,
              policyMode: isExternalCreated, //jeśli wniosek utworzony posa SP, to traktujemy go jak polisę - musimy uzupełnić wsystkie additionalData i dane dla dataContainera
              initSupplementData: appVariables.isSupplement //czy inicjalizacja danych do dokupień
            });
            self.setDataContainer(tmpDc, mainDataContainer);

            // to chyba niepotrzebne? setDataContiner powinien to juz przepisac
            // mainDataContainer.productClauses = tmpDc.productClauses;

            self._initApplication(appl, function() {
              //zakonczenie wczytywania
              actionHelper.runAction('tarifficationEnded');
              self.tarifficate(); //taryfikacja po wczytaniu - odswizenie komunikatow
            });
          }
          appVariables.isExternallyCreated = false; // ustaw flage z powrotem na false w celu poprawnego proponowania (na ten moment tylko) ryzyka powodzi
          return true; //wszystko ok
        };

        /**
         * przetwarza wniosek pod kątem wersji konfiguracji i spd.
         * @param  {Object} resp odp w usługi odczytu wniosku/oferty
         * @return {Boolean} true gdy wszystko ok, false i przekierowanie do wczuyanie wniosku w odpowiedniej wersji gdy wersja niezgodna a aktualnie zaladowana
         */
        this._processApplicationVersion = function(resp) {
          if (resp.status !== ApplicationModelConstants.APPLICATION_STATUS_CALCULATION && angular.isString(resp.salesDate)) {
            var targetVersion = ConfigResource.getTargetVersionBy('date', resp.salesDate);
            if (targetVersion !== CONFIG.METADATA.versionDate) { //nalezy odtworzyc oferte w odpowiedniej dla niej wersji aplikacji
              navigationHelper.go(CONSTANTS.PAGE_INIT, {
                'action': 'viewOffer',
                'offerId': resp.metaData.id,
                'version': targetVersion
              }, {
                reload: true
              });
              return false;
            }
          }
          return true;
        };

        //init wczytanego wniosku
        this._initApplication = function(appl, callback) {
          applicationDictionaryHelper.loadDictionariesForApplication(appl, function() {
            sp2UiHelper.hideBlockUi();
            if (appVariables.appMode === CONSTANTS.APP_MODE_OFFER) {
              navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
              if (typeof appl.dynamicValues.offerState !== 'undefined' && appl.dynamicValues.offerState === CONSTANTS.OFFER_STATE_EXPIRED) {
                sp2CommonHelper.showConfirmModal({
                  treatLabelAsI18nCode: true,
                  title: 'sp2.modal.info.title',
                  okBtnName: 'sp2.modal.close',
                  cancelBtnName: '',
                  text: 'sp2.modal.offerExpiredInfoMsg'
                });
              }
            } else {
              navigationHelper.go(appVariables.mainState);
            }
            if (angular.isFunction(callback)) {
              callback();
            }
          });
        };
        /**
         * Wczytuje wniosek ubezpieczeniowy w statusie oferty i przkierowuje na stronę matrycy
         * @param {string} id identyfikator oferty
         */
        this.loadOffer = function(id) {
          appVariables.appMode = CONSTANTS.APP_MODE_OFFER;
          self.loadApplication(id);
        };
        /**
         * uruchamia nowy wniosek dla osoby i pojazdu
         * @param {string|null} personId id osoby (ubezpieczajacego)
         * @param {string|null} vehicleId id pojazdu
         * @param {string|null} personData zserializowane dane ubezpieczajacego
         * @param {string|null} nbkId id ubezpieczajacego z NBK
         * @param {string|null} risks inicjalne zaznaczenia ryzyk/produktow
         * @param {string|null} adds inicjalne zaznaczenia dodatków
         */
        this.newApplication = function(personId, vehicleId, personData, nbkId, risks, adds) {
          var extraParamsHandled = self._handleNewApplicationExtraParams(personData, risks, adds),
            personDataHandled = angular.isDefined(extraParamsHandled.personData);
          selectionHelper.setPreselection();
          /**
           * [insurerLoadedCallback description]
           * @param  {[type]} vehId             [description]
           * @param  {[type]} personLoaded      [description]
           * @param  {[type]} personInitialized [description]
           * @return {[type]}                   [description]
           */
          var insurerLoadedCallback = function(vehId, personLoaded, personInitialized) {
            if (vehId !== null) {
              self._loadVehicle(vehId, function() {
                if (personLoaded) {
                  navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
                  self.saveApplication(false);
                } else if (personInitialized) {
                  navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_EDIT);
                } else {
                  navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
                }
              });
            } else if (personLoaded) {
              navigationHelper.go(appVariables.mainState);
              self.saveApplication(false);
            } else if (personInitialized) {
              navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_EDIT);
            } else {
              navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
            }
          };
          if (personId !== null || nbkId !== null || personDataHandled) { //wczytanie osoby (opcjonalnie z pojazdem) lub dane osoby z urla
            if (personDataHandled) {
              self._searchAndLoadInsurer(extraParamsHandled.personData, function(personLoaded, personInitialized) {
                insurerLoadedCallback(vehicleId, personLoaded, personInitialized);
              });
            } else {
              self._loadInsurer(nbkId !== null ? nbkId : personId, nbkId !== null, function(personLoaded) {
                insurerLoadedCallback(vehicleId, personLoaded);
              });
            }
          } else if (vehicleId !== null) { //wczytanie tylko pojazdu
            self._loadVehicle(vehicleId, function() {
              navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
            });
          } else { //wejscie do strony wyszukiwania klienta
            navigationHelper.go(angular.isDefined(CONFIG.NAVIGATION.initialState) ? CONFIG.NAVIGATION.initialState : CONSTANTS.PAGE_START_CLIENT_SEARCH);
          }
        };

        /**
         * wczytanie inicjalne ubezpieczajacego
         * @param  {[type]}   personId [description]
         * @param  {Boolean}   isNbk czy jest klientem z NBK- uzycie innej uslugi do pobrania osoby
         * @param  {Function} callback zwraca zrgument mowiacy o tym, czy udalo sie pobrac ubezpieczajacego - usera
         */
        this._loadInsurer = function(id, isNbk, callback) {
          if (isNbk) {
            personServiceHelper.getNbkCustomer(id, function(resp) {
              self._personLoadedsuccessfully(resp, callback);
            }, function() {
              callback(false);
            });
          } else {
            personServiceHelper.getPersonById(id, function(resp) {
              self._personLoadedsuccessfully(resp, callback);
            }, function() {
              callback(false);
            });
          }
        };

        /**
         * reakcja na pomyślne wczytanie osoby ubezpieczającego (wejście na ścieżkę)
         * @param  {Object}   resp     odp. z usługi
         * @param  {Function} callback funkcja callbacka
         */
        this._personLoadedsuccessfully = function(resp, callback) {
          var personData = resp.data;
          personHelper.saveInsurer(personData, true);
          appVariables.isInsurerChosen = true;
          sp2UiHelper.hideBlockUi();
          callback(true);
        };

        /**
         * szuka osoby na podstawie przekazanych danych i wczytuje  ja, jeśli odnaleziono
         * @param  {Object} personData zawiera m.in. pesel
         * @param  {function} callback
         * @return {[type]}            [description]
         */
        this._searchAndLoadInsurer = function(personData, callback) {
          var id = (personData.metaData && personData.metaData.id ? personData.metaData.id : null),
            requiredData = (personData.lastName && personData.firstName && personData.pesel) ? true : false; //wszystkie muszą być wypełnione, wówczas warunek będzie spełniony

          var initializePerson = function() {
            var initData = {
              firstName: personData.firstName || '',
              lastName: personData.lastName || '',
              pesel: personData.pesel || '',
              addresses: personData.addresses || [(new AddressModel()).getData()],
              metaData: {}
            };
            clientAddHelper.tplData.personData = initData; //zasilamy kolejną stronę danymi osoby
            callback(false, true);
          };

          if (id === null && !requiredData) {
            initializePerson();
          } else {
            personServiceHelper.getPerson(id, requiredData ? personData : {}, function(resp) {
              self._personLoadedsuccessfully(resp, callback);
            }, function(reject) {
              if (reject.status === 404) {
                //uzupelnienie danych ub-cego z urla
                initializePerson();
              }
            }, {
              allowedStatuses: [404]
            });
          }
        };

        /**
         * inicjalne wczytanie pojazdu
         * @param  {String}   vehicleId [description]
         * @param  {Function} callback  [description]
         */
        this._loadVehicle = function(vehicleId, callback) {
          var svc = $injector.get('vehiclesSvc');
          sp2UiHelper.showBlockUi($filter('translate')('loadingVehicle', {
            componentCode: 'sp2'
          }));
          svc.get(vehicleId, null, null, function(vehicleResp) {
            sp2UiHelper.hideBlockUi();
            var vehicle = new VehicleModel();
            vehicle.setData(vehicleResp.data);
            $injector.get('vehicleHelper').applyVehicle(vehicle);
            callback();
          });
        };

        /**
         * zwraca obiekt z uchwyconymi dodatkowymi parametrami uruchomienia sciezki sprzedazowej
         * @param  {[type]} personDataString [description]
         * @param  {[type]} risks            [description]
         * @param  {[type]} adds             [description]
         * @return {Object} jesli w obiekcie pojawi się klucz parametru (np. adds), tzn. ze zostal poprawnie odczytany i uzupelniony. Wartości przekazywane dla kazdego klucza sa specyficzne.
         */
        this._handleNewApplicationExtraParams = function(personDataString, risks, adds) {
          var handled = [];
          if (angular.isString(risks)) {
            handled.push('risks');
            self._setPreselectedRisks(risks);
          }
          if (angular.isString(adds)) {
            handled.push('adds');
            self._setPreselectedAdditions(adds);
          }
          if (angular.isString(personDataString)) {
            var personData = angular.fromJson(base64.decode(personDataString.base64UrlDecode()));
            handled.personData = personData;
          }
          return handled;
        };
        /**
         * ustawia preselekcje dla produktow/ryzyk
         * @param {String} risksString
         */
        this._setPreselectedRisks = function(risksString) {
          var risks = risksString.split(',');
          angular.forEach(risks, function(item) {
            var risk = item.split('_');
            mainDataContainer.preselectedVariants.push({
              code: CONSTANTS.URL_RISK_TO_PRODUCT[risk[0]],
              variant: CONSTANTS.URL_VARIANT_TO_VARIANT[risk[1]]
            });
          });
        };

        /**
         * ustawia preselekcje dla dodatkow
         * @param {String} addsString tekst z wariantami dodatków
         */
        this._setPreselectedAdditions = function(addsString) {
          var adds = addsString.split(',');
          angular.forEach(adds, function(addCode) {
            var mappedAdd = CONSTANTS.URL_ADD_TO_ADD[addCode],
              withVariant = angular.isObject(mappedAdd);
            mainDataContainer.preselectedAdditions.push({
              code: withVariant ? mappedAdd.code : mappedAdd,
              variant: withVariant ? mappedAdd.variant : null
            });
          });
        };

        /**
         * ustawia ubezpieczajacego na podstawie przeslanych danych z url-a
         * @param {String} personString zserializowane dane ubezpieczajacego
         */
        this._setInitialInsurer = function(personString) {
          var urlPersonData = angular.fromJson(base64.decode(personString)),
            simpleDataMap = { //mapowanie atrybutow osoby z urla do modelu osoby
              firstName: 'firstName',
              lastName: 'lastName'
            },
            personData = {
              clauses: {}
            };

          angular.forEach(simpleDataMap, function(target, source) {
            if (angular.isDefined(urlPersonData[source])) {
              personData[target] = urlPersonData[source];
            }
          });
          if (angular.isObject(urlPersonData.clauses)) {
            angular.forEach(urlPersonData.clauses, function(selected, code) {
              personData.clauses[code] = selected ? ClausesModelConstants.CLAUSE_VALUE_AGREEMENT : ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
            });
          }
          personHelper.saveInsurer(personData);
        };

        /**
         * wczytuje polisę
         * @param {string} policyId id polisy
         */
        this.loadPolicy = function(policyId) {
          appVariables.policyViewMode = true;
          appVariables.appMode = CONSTANTS.APP_MODE_POLICY;
          sp2UiHelper.showBlockUi($filter('translate')('loadingPolicy', {
            componentCode: 'sp2'
          }));
          policiesServiceHelper.getLinkedPolicies(policyId, {
            context: 'salepath'
          }, self._policyLoadedCallback, function(reject) {
            if (reject.status === 403) {
              //użytkownik nie ma uprawnień do wniosku/oferty
              sp2UiHelper.hideBlockUi();
              sp2UiHelper.disableRender();
            } else {
              sp2UiHelper.hideBlockUi();
              actionHelper.runAction('applicationRequestFailed');
            }
          }, {
            allowedStatuses: [403]
          });
        };

        /**
         * wczytuje polisę
         * Podajemy id lub numer polisy
         * @param {string} policyId id polisy
         * @param {string} policyNumber nr polisy
         */
        this.extendPolicy = function(policyId, policyNumber) {
          appVariables.isSupplement = true;
          appVariables.appMode = CONSTANTS.APP_MODE_CALCULATION;
          sp2UiHelper.showBlockUi($filter('translate')('loadingApplication', {
            componentCode: 'sp2'
          })); //ewentulanie wczytywanie dokupienia/doubezpieczenia
          policiesServiceHelper.getExtension(policyId || policyNumber, self._extensionLoadedCallback, function(reject) {
            if (reject.status === 403) {
              //użytkownik nie ma uprawnień do wniosku/oferty
              sp2UiHelper.hideBlockUi();
              sp2UiHelper.disableRender();
            } else {
              sp2UiHelper.hideBlockUi();
              sp2CommonHelper.showConfirmModal({
                treatLabelAsI18nCode: true,
                title: 'sp2.modal.error.title',
                okBtnName: 'sp2.modal.close',
                cancelBtnName: '',
                text: 'modal.policyExtensionFailed',
                size: 'sm',
                okBtnCallback: function() {
                  navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
                }
              });
            }
          }, {
            allowedStatuses: [403]
          });
        };

        /**
         * callback wczytania dokupienia
         * @param  {Object} responseObject
         * @return {Boolean} true jak wszystko ok
         */
        this._extensionLoadedCallback = function(responseObject) {
          var resp = responseObject.data;
          if (!angular.isObject(resp) || angular.equals(resp, {})) {
            sp2UiHelper.hideBlockUi();
            actionHelper.runAction('applicationRequestFailed');
            return false; //b;ąd wczytywania wniosku
          }

          if (!angular.isString(resp.symbol) || resp.symbol === '') {
            sp2UiHelper.hideBlockUi();
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: 'modal.applicationLackOfData',
              size: 'sm',
              okBtnCallback: function() {
                navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
              }
            });
            return false;
          }

          if (SPD.Application.idpm.toLowerCase() !== resp.symbol.toLowerCase()) {
            sp2UiHelper.hideBlockUi();
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: 'sp2.modal.loadedCallbackErrorMsg',
              size: 'sm',
              okBtnCallback: function() {
                navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
              }
            });
            return false;
          }

          //sczytanie uprawnien do edycji dla charakterow sprzedazowych
          appVariables.canManage = resp.canManage;

          if (!self.hasFinalApplicationPermission('view', resp)) { //sprawdzanie uprawnien do podgladu wniosku (dodatkowe)
            sp2UiHelper.hideBlockUi();
            sp2UiHelper.disableRender();
            return false;
          }
          
          if (!self._processExtensionVersion(resp)) {
            return false;
          }

          appVariables.isInsurerChosen = true; //wnioske musi miec ubezpieczajacego
          var appl = new ApplicationModel();
          appl.setData(resp);
          self.setApplicationPolicyType(appl);
          MapperUtils.generateClientIds(appl);
          var tmpDc = fromRestApplicationMapper.mapApplication(appl, {
            premium: true, //mapowanie skladek
            sumInsured: true, //mapowanie sum ubezpieczen
            additionalData: true, //mapowanie additionalData osób/firm/lokalizacji/pojazdów
            tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
            dataContainerProperties: true, //wszystko pozostalw dotyczące wniosku i dataManagera
            initialLoad: true,
            policyMode: true, //jeśli wniosek utworzony posa SP, to traktujemy go jak polisę - musimy uzupełnić wsystkie additionalData i dane dla dataContainera
            initSupplementData: true //czy zainicjalizować dane trybu dokupień
          });
          self.setDataContainer(tmpDc, mainDataContainer);

          self._initApplication(appl, function() {
            dataContainerHelper.alignPersonalRisksSumInsured();
            //zakonczenie wczytywania
            actionHelper.runAction('tarifficationEnded');
            self.tarifficate(); //taryfikacja po wczytaniu - odswizenie komunikatow
          });
          return true;
        };

        /**
         * przetwarza rozszerzany wniosek pod kątem wersji konfiguracji i spd.
         * @param  {Object} resp odp z usługi odczytu wniosku/oferty
         * @return {Boolean} true gdy wszystko ok, false i przekierowanie do wczuyanie wniosku w odpowiedniej wersji gdy wersja niezgodna a aktualnie zaladowana
         */
        this._processExtensionVersion = function(resp) {
          if (resp.operationData && angular.isString(resp.operationData.policySalesDate)) {
            var targetVersion = ConfigResource.getTargetVersionBy('date', resp.operationData.policySalesDate);
            if (targetVersion !== CONFIG.METADATA.versionDate) { //nalezy odtworzyc oferte w odpowiedniej dla niej wersji aplikacji
              navigationHelper.go(CONSTANTS.PAGE_INIT, {
                'action': 'extend',
                'policynumber': resp.operationData.policyNumber,
                'version': targetVersion
              }, {
                reload: true
              });
              return false;
            }
          }
          return true;
        };

        /**
         * zapisuje w odrębnych miejscach w dataContainerze informacje o dokupieniach
         * @return {Boolean} false jesli nie jestesmy w trybie dokupienia
         */
        this._saveInsuranceExtensionData = function() {
          if (!appVariables.isSupplement) {
            return false;
          }
          if (angular.isObject(mainDataContainer.application.operationData) && angular.isObject(mainDataContainer.application.operationData.allowedChanges)) {
            mainDataContainer.allowedChanges = mainDataContainer.application.operationData.allowedChanges.getData();
          }
          return true;
        };

        /**
         * callback po wczytaniu polisy
         * @param  {Object} responseObject
         */
        this._policyLoadedCallback = function(responseObject) {
          sp2UiHelper.hideBlockUi();
          var resp = responseObject.data;
          if (!angular.isObject(resp) || angular.equals(resp, {})) {
            actionHelper.runAction('applicationRequestFailed');
            return; //bląd wczytywania powiqaanych polis
          }
          if (!angular.isArray(resp) || resp.length === 0) {
            return;
          }

          if (!sp2CommonHelper.isPolicySupported(resp[0].product.compId)) {
            sp2UiHelper.hideBlockUi();

            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: 'sp2.modal.wrongPackageErrorMsg',
              size: 'sm',
              okBtnCallback: function() {
                navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
              }
            });

            return;
          }

          appVariables.isInsurerChosen = true; //polisa zawsze ma ubezpieczajacego
          var policies = [];
          angular.forEach(resp, function(policyData) {
            var policy = new PolicyModel();
            policy.setData(policyData);
            policies.push(policy);
          });
          appVariables.readOnly = true;
          //mapowanie polis do wniosku
          var application = fromRestPolicyMapper.mapPoliciesToApplication(policies);
          var tmpDc = fromRestApplicationMapper.mapApplication(application, {
            premium: true, //mapowanie skladek
            sumInsured: true, //mapowanie sum ubezpieczen
            additionalData: true, //mapowanie additionalData osób/firm/lokalizacji/pojazdów
            tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
            dataContainerProperties: true, //wszystko pozostalw dotyczące wniosku i dataManagera
            policyMode: true, //wczytywanie polis
            initialLoad: true //wejście na ścieżkę
          });
          self.setDataContainer(tmpDc, mainDataContainer);
          actionHelper.runAction('readOnlyChanged', true);
          self._applyPolicies(policies, {
            tarifficationMode: false
          }, function() {
            sp2UiHelper.hideBlockUi();
            navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
            $timeout(function() {
              actionHelper.runAction('tarifficationEnded');
            }, 0);
          });
        };

        /**
         * set policy mode "manually" (e.g. after successful offer payment in fraudulent client case)
         */
        this.setPolicyMode = function() {
          appVariables.appMode = CONSTANTS.APP_MODE_POLICY;
          appVariables.readOnly = true;
          actionHelper.runAction('readOnlyChanged', true);
          actionHelper.runAction('policyModeHasBeenSet');
        };

        /**
         * zmienia status wniosku na ofertę
         * @param {function|null} callback opcjonalny callback po zmianie statusu wniosku
         */
        this.convertApplicationToOffer = function(callback) {
          if (mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_OFFER) {
            return false;
          }
          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(self.SERVICE_TYPE_CONVERT_TO_OFFER, false);
          self.checkSummary();
          return true;
        };

        /**
         * reserve policy number for current calculation - convert it to offer with "pending payment" substatus
         * @param {function|null} callback optional callback to run after operation
         */
        this.reservePolicyNumber = function(callback) {
          if (mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_OFFER) {
            return false;
          }
          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(self.SERVICE_TYPE_RESERVE_POLICY_NUMBER, false);
          self.checkSummary();
          return true;
        };

        /**
         * zmienia status wniosku na iofertę
         * @param {function|null} callback opcjonalny callback po zmianie statusu wniosku
         * @param {boolean} [sendViaEmail=false] whether to create ioffer and send it via email to client
         */
        this.convertApplicationToIOffer = function(callback, sendViaEmail) {
          if (mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_IOFFER) {
            return;
          }
          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(sendViaEmail ? self.SERVICE_TYPE_CONVERT_TO_IOFFER_EMAIL : self.SERVICE_TYPE_CONVERT_TO_IOFFER, false);
          self.checkSummary();
        };

        /**
         * zmienia status wniosku na ofertę
         * @param {function|null} callback opcjonalny callback po zmianie statusu wniosku
         */
        this.convertApplicationToCalculation = function(callback) {
          if (mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) {
            return;
          }
          if (angular.isFunction(callback)) {
            self.additionalApplicationCallback = callback;
          }
          self._registerServiceCall(self.SERVICE_TYPE_CONVERT_TO_CALCULATION, false);
        };

        /**
         * Przekształca wniosek na polisy
         * @param {string} settlementDocuments sposob przekazywania dokumentow do polisy
         * @param {function|null} callback opcjonalny callback
         */
        this.convertApplicationToPolicies = function(settlementDocuments, callback) {
          if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY || appVariables.appMode === CONSTANTS.APP_MODE_RENEW_CHOICE) {
            return;
          }
          self.getPolicies = false; //nie chcemy podziału na polisy jak już zatwierdzamy
          if (self.callInProgress) { //czekamy na zakończenie zapisu wniosku
            self.acceptPolicies = true;
            mainDataContainer.settlementsDocuments = settlementDocuments;
            return;
          }

          if (typeof settlementDocuments === 'undefined') {
            settlementDocuments = mainDataContainer.settlementsDocuments;
          }

          self.acceptPolicies = false;
          var srv = applicationServiceHelper,
            application = mainDataContainer.application,
            request = new RequestApplicationsPolicyModel();
          request.setData({
            'place': application.get('place'),
            'settlementDocuments': settlementDocuments,
            'signDate': application.get('signDate')
          });
          lsnModelHelper.minifyRestObject(request);
          actionHelper.runAction('policiesAcceptingStarted');
          srv.postPolicy(application.metaData.id, request, function(respObj) {
            var resp = respObj.data;
            if (!angular.equals(resp, {})) {
              appVariables.appMode = CONSTANTS.APP_MODE_POLICY;
              if (!appVariables.isSupplement) { //dla dokupień prezentujemy wniosek pred zatwierdzeniem
                self._applyPolicies(resp, {
                  tarifficationMode: false,
                  acceptMode: true //tryb akceptacji polis
                });
              }
              appVariables.readOnly = true;
              actionHelper.runAction('readOnlyChanged', true);
              if (angular.isFunction(callback)) {
                callback();
              } else {
                actionHelper.runAction('policiesAccepted');
              }
            } else {
              actionHelper.runAction('policiesNoAccepted');
            }
          }, function(rej) { //errorCallback
            sp2UiHelper.hideBlockUi();
            actionHelper.runAction('applicationRequestFailed');
            if (rej.status === 403 && rej.data.status === 'NoPermission') { // na podstawie jiry IHESTIASU-1169
              self._handleFailureMessages(rej, 'noPermissionForPoliciesAccepted', 'sp2.modal.info.title');
            } else if (rej.status === 409 || rej.status === 403) {
              self._handleFailureMessages(rej, 'errorsOccuredDuringPoliciesAcceptance');
            }
          }, {
            allowedStatuses: [403]
          }).then(function(respObj) {
            if (respObj.status !== 403) {
              actionHelper.runAction('afterPoliciesAccepted');
            }
          }, function() {
            actionHelper.runAction('afterPoliciesAccepted');
          });
        };

        /**
         * obsluga bledow przy konwersji wniosku
         * @param  {Object} rej obiekt responsa-rejectu
         * @param  {String} [alert] message text (if none, modal won't show up)
         * @param  {String} [title] modal's title
         */
        this._handleFailureMessages = function(rej, alert, title) {
          self.callInProgress = false;
          self.additionalApplicationCallback = null;
          var respMessages = [];
          if (angular.isArray(rej.data)) {
            respMessages = rej.data; //np. bledy przy zatwierdzaniu polis
          } else if (angular.isObject(rej.data) && angular.isArray(rej.data.messages)) {
            respMessages = rej.data.messages; //np. bledy przy ofertowaniu
          }
          if (respMessages.length > 0) {
            var messages = [];
            angular.forEach(respMessages, function(data) {
              var message = new MessageModel();
              message.setData(data);
              messages.push(message);
            });
            fromRestMessageMapper.mapMessages(messages);
          }
          if (rej.status !== 403) {
            self.checkSummary();
          }
          if (alert) {
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: title ? title : 'sp2.modal.error.title',
              okBtnName: 'sp2.modal.close',
              cancelBtnName: '',
              text: alert,
              size: 'sm',
              okBtnCallback: function() {
                if (rej.status === 403) {
                  navigationHelper.go(CONSTANTS.PAGE_INIT, {
                    action: 'new'
                  });
                } else {
                  actionHelper.runAction('tarifficationEnded');
                }
              }
            });
          }
        };
        /**
         * pobiera polisy dla wniosku
         * @param {function|null} callback opcjonalny callback po poborze polis
         * @param {function|null} errorCallback opcjonalny callback w przypadku bledow
         */
        this.getPoliciesForApplication = function(callback, errorCallback) {
          if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY || appVariables.appMode === CONSTANTS.APP_MODE_RENEW_CHOICE) { //jeśli polisa została zatwierdzona, to nie chcemy wywoływać podziału na polisy
            return;
          }
          if (angular.isFunction(callback)) {
            self.additionalPoliciesCallback = callback;
          }
          if (self.callInProgress || self.delayPromise !== null || self.callService.type !== null) { //jeśli leci jakiś request to czekamy aż się skończy
            //może jeszcze servicePromise?
            self.getPolicies = true;
            return;
          }
          self.getPolicies = false;
          var id = mainDataContainer.application.metaData.get('id');
          if (id === null) { //jeśli brak id wniosku to przerywamy
            return;
          }
          actionHelper.runAction('tarifficationStarted');
          if (mainDataContainer.policies.length === 0) //jak brak ztaryfikowanych ryzyk to nic nie odpalamy usługi, bo się wysypie
          {
            actionHelper.runAction('tarifficationEnded');
            self._applyPolicies([], {
              tarifficationMode: true
            });
            if (angular.isFunction(self.additionalPoliciesCallback)) {
              self.additionalPoliciesCallback();
            }
            self.additionalPoliciesCallback = null;
            return;
          }
          var srv = applicationServiceHelper;
          srv.getPolicies(id, function(respObj) {
            actionHelper.runAction('tarifficationEnded');
            if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY || appVariables.appMode === CONSTANTS.APP_MODE_RENEW_CHOICE) { //jeśli polisa została zatwierdzona, to nie chcemy wczytywać podziału na polisy
              return;
            }
            if (!angular.isArray(respObj.data)) {
              actionHelper.runAction('applicationRequestFailed');
              return;
            }
            self._applyPolicies(respObj.data, {
              tarifficationMode: true
            });
            if (angular.isFunction(self.additionalPoliciesCallback)) {
              self.additionalPoliciesCallback();
            }
            self.additionalPoliciesCallback = null;
          }, errorCallback);
        };

        /**
         * uruchamia usługę przenoszenia danych z aktualnego wniosku do wniosku o podanym symbolu
         * @param  {String} symbol sumbol nowego wniosku
         * @param {Function} callback callback poprawnego wykonania usługi. Przyjmuje jako argument id nowego wniosku
         * @param {string} [generalAgreementPrefix] if set transfer will be done based on available GA number
         * @return {Boolean} true, gdy uruchomiono usługę przenoszenia danych
         */
        this.transferApplicationData = function(symbol, callback, generalAgreementPrefix) { //eslint-disable-line consistent-return
          if (!mainDataContainer.application.metaData.id) {
            return false;
          }
          sp2UiHelper.showBlockUi($filter('translate')('loadingApplication', {
            componentCode: 'sp2'
          }));

          if (generalAgreementPrefix) {
            // transfer by general agreement
            var svc = applicationServiceHelper.ver('v2'),
              payload = {
                destinationPacket: sp2CommonHelper.getAllowedGeneralAgreement(generalAgreementPrefix)
              };
            return svc.post(mainDataContainer.application.metaData.id, payload, 'copy')
              .then(function(resp) {
                sp2UiHelper.hideBlockUi();
                if (resp.data.metaData && resp.data.metaData.id) {
                  callback(resp.data.metaData.id);
                }
              }, lsnNg.noop)
              .finally(function() {
                sp2UiHelper.hideBlockUi();
              });
          } else {
            // regular transfer
            applicationServiceHelper.getTransform(mainDataContainer.application.metaData.id, symbol, function(resp) {
              sp2UiHelper.hideBlockUi();
              if (resp.data.metaData && resp.data.metaData.id) {
                callback(resp.data.metaData.id);
              }
            }, function() {
              sp2UiHelper.hideBlockUi();
            });
          }
        };

        //mock taryfikacji wniosku
        this.mockTariffication = function(data) {
          var application = new ApplicationModel();
          application.setData(data);
          application.metaData.set('id', 1);
          var premium = 0;
          angular.forEach(application.risks, function(risk) {
            premium = 0;
            switch (risk.product.compId) {
              case 'DZ91000GEH': //oczwzp
              case 'PX91000GEH': //nnw
                premium = 200;
                break;
              default:
                premium = Math.floor(Math.random() * 1000);
            }
            risk.set('premium', premium);
          });
          return application;
        };

        /**
         * @param {*} resp response z usługi
         * @param {object|null} mappingOptions opcje mapowania
         * @param {function=} callback metoda do wywołania po zastosowaniu polis
         */
        this._applyPolicies = function(resp, mappingOptions, callback) {
          if (!angular.isArray(resp)) {
            return;
          }
          var policies = [],
            simpleRisks = {},
            tmpRisks,
            i;
          angular.forEach(resp, function(policyData) {
            var policy = new PolicyModel();
            policy.setData(policyData);
            policies.push(policy);
            fromRestPolicyMapper.setClientIdsForPolicies(policies);
            tmpRisks = self.getSimpleRisks(policy, mappingOptions);

            simpleRisks[policyData.number] = [];
            for (i = 0; i < tmpRisks.length; i += 1) {
              simpleRisks[policyData.number].push(tmpRisks[i]);
            }
          });
          mainDataContainer.policies = policies;
          mainDataContainer.simpleRisks = simpleRisks;
          if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY) {
            mainDataContainer.policiesDiscounts = {};

            angular.forEach(mainDataContainer.policies, function(policy) {
              mainDataContainer.policiesDiscounts[policy.number] = new PolicyDiscountsModel();
              fromRestPolicyMapper._mapPolicyDiscounts(policy.number, policy.product.get('dynamicValues'), mainDataContainer);
            });

            fromRestPolicyMapper.mapPolicyDiscountsFromSimpleRisk(mainDataContainer.policiesDiscounts, simpleRisks);
            fromRestPolicyMapper.setPolicyDiscountsMinimalPremiumReduceAgreement(mainDataContainer.policiesDiscounts, policies);
            applicationDictionaryHelper.loadDictionariesForPolicies(policies, callback);
          }
        };

        /**
         * zwraca tablicę SimpleRisks dla danej polisy
         * @param {Policy} policy polisa
         * @param {Object} options opcje mapowania
         * @return {XPegaz.Mapper.FromRest.SimpleRisk[]} proste ryzyka
         */
        this.getSimpleRisks = function(policy, options) {
          return fromRestApplicationMapper.getSimpleRisks(policy, mainDataContainer, options);
        };

        this.registerPrintApplicationOffer = function() {
          self.printOffer = mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION ? CONSTANTS.PRINT_CODE_CALCULATION : CONSTANTS.PRINT_CODE_OFFER;
          if (self.callService.type !== self.SERVICE_TYPE_APPLICATION_SAVE && !self.callInProgress) {
            self.printApplicationOffer();
          }
        };

        /**
         * wydruk oferty ubezpieczenia
         * @return {undefined}
         */
        this.printApplicationOffer = function() {
          var printOfferCode = self.printOffer;
          self.printOffer = null;

          applicationsPrintoutsSvc.getAvailablePrintoutTypes(mainDataContainer.application.metaData.id, function(codes) {
            //sprawdzamy najpierw czy można taki wydruk wywołać (możliwy brak uprawnień)
            var printCodeMatched = false;
            angular.forEach(codes.data, function(printoutCode) {
              if (printOfferCode === printoutCode) {
                printCodeMatched = true;
              }
            });
            if (printCodeMatched) {
              var mime = 'application/pdf';
              var params = {
                headers: {
                  'Accept': mime
                },
                responseType: 'blob'
              };
              sp2UiHelper.showBlockUi($filter('translate')('downloadingFile', {
                componentCode: 'Public'
              }));
              applicationsPrintoutsSvc.printNow(mainDataContainer.application.metaData.id, null, null, function(result) {
                sp2CommonHelper.saveFile(result, mime);
                sp2UiHelper.hideBlockUi();
              }, function() {
                sp2UiHelper.hideBlockUi();
              }, params, printOfferCode);
            } else {
              sp2UiHelper.showAlert({
                content: $filter('translate')('noPermissionToPrintouts', {
                  componentCode: 'sp2'
                }),
                type: 'danger'
              });
            }
          });
        };

        /**
         * prompt download and start downloading KID file
         * @param  {String} policyId offer id (simplePolicy)
         * @return {undefined}
         */
        this.printKID = function(policyNumber) {
          var mime = 'application/pdf',
            httpParams = {
              headers: {
                'Accept': mime
              },
              responseType: 'blob'
            };
          sp2UiHelper.showBlockUi($filter('translate')('downloadingFile', {
            componentCode: 'Public'
          }));
          if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY) {
            var policyId = dataContainerHelper.getPolicyByNumber(policyNumber).metaData.get('id');
            policiesPrintoutsSvc.getFileStream(policyId, null, null, 'kid', function(result) {
              sp2CommonHelper.saveFile(result, mime);
              sp2UiHelper.hideBlockUi();
            }, function() {
              sp2UiHelper.hideBlockUi();
            }, httpParams);
          } else {
            applicationsPrintoutsSvc.getFileStream(mainDataContainer.application.metaData.id, null, 'kid', policyNumber, function(result) {
              sp2CommonHelper.saveFile(result, mime);
              sp2UiHelper.hideBlockUi();
            }, function() {
              sp2UiHelper.hideBlockUi();
            }, httpParams);
          }

        };

        /**
         * begin downloading IOD file
         * @return {undefined}
         */
        this.printIOD = function() {
          if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY) {
            sp2UiHelper.showBlockUi($filter('translate')('downloadingFile', {
              componentCode: 'Public'
            }));
            var policyId = dataContainerHelper.getPolicyByNumber(mainDataContainer.policies[0].number).metaData.get('id');
            policiesSvc.get(policyId, null, 'distributorAboutFile', function(response) {
              ihestiaDownloadHelper.downloadFileByTokenId(response.data.token, 'iod.pdf', false).then(angular.noop, angular.noop)
                .finally(function() {
                  sp2UiHelper.hideBlockUi();
                });
            }, function(response) {
              sp2UiHelper.hideBlockUi();
              if (angular.isObject(response.data) && response.status === 409) {
                sp2CommonHelper.showConfirmModal({
                  treatLabelAsI18nCode: true,
                  title: ['sp2.modal.info.title', {
                    componentCode: 'sp2'
                  }],
                  okBtnName: ['sp2.modal.close', {
                    componentCode: 'sp2'
                  }],
                  cancelBtnName: '',
                  text: $filter('translate')('sp2.modal.IOD.messageAgent', {componentCode: 'sp2'})
                });
              }
            });
          } else {
            sp2UiHelper.showBlockUi($filter('translate')('downloadingFile', {
              componentCode: 'Public'
            }));
            applicationsSvc.get(mainDataContainer.application.metaData.id, null, 'distributorAboutFile', function(response) {
              ihestiaDownloadHelper.downloadFileByTokenId(response.data.token, 'iod.pdf', false).then(angular.noop, angular.noop)
                .finally(function() {
                  sp2UiHelper.hideBlockUi();
                });
            }, function(response) {
              sp2UiHelper.hideBlockUi();
              if (angular.isObject(response.data) && response.status === 409) {
                sp2CommonHelper.showConfirmModal({
                  treatLabelAsI18nCode: true,
                  title: ['sp2.modal.info.title', {
                    componentCode: 'sp2'
                  }],
                  okBtnName: ['sp2.modal.close', {
                    componentCode: 'sp2'
                  }],
                  cancelBtnName: '',
                  text: $filter('translate')('sp2.modal.IOD.messageAgent', {componentCode: 'sp2'})
                });
              }
            });
          }
        };

        /**
         * ususwa wniosek
         * @return {[type]} [description]
         */
        this.deleteApplication = function() {
          var appId = mainDataContainer.application.metaData.get('id');
          if (appId === null) {
            sp2UiHelper.showAlert({
              content: $filter('translate')('applicationHasntBeenSavedYet', {
                componentCode: 'sp2'
              }),
              type: 'danger'
            });
            return;
          }
          applicationServiceHelper.remove(appId, null, function() {
            navigationHelper.go(CONSTANTS.PAGE_INIT, {
              'action': 'new'
            });
          });
        };

        /**
         * czy użytkownik ma dostep do widoku i edycji wniosku
         * Jeśli brak uprawnień do widoku (specyficzne sytuacje, normalnie blokada z backendu) to przekierowanie na strone "brak uprawnień"
         * @param {String} type typ sprawdzanego uprawnienia
         * dostępne opcje:
         * 'view' - ogolny podglad wniosku/oferty/polisy
         * 'edit' - edycja wniosku
         * 'backToEdit' - powrót do edycji
         * 'policyAccept' - zatwierdzanie wnioksu do polisy
         * 'offer' - oferta wiazaca
         * 'iOffer' - iOferta - wyślij do ikonta
         * 'viewEdit' widocznosc przycisku "zapisz wniosek"
         * 'viewBackToEdit' widocznosc przycisku "powrot do edycji"
         * 'viewPolicyAccept' widocznosc przycisku "zatwierdź polisy"
         * 'viewOffer' widocznosc przycisku "oferta wiążąca"
         * 'viewIOffer' widocznosc przycisku "wyslij do ikonta"
         * @return {Boolean} [description]
         */
        this.hasFinalApplicationPermission = function(type, application) {
          return sp2CommonHelper.hasFinalApplicationPermission(type, angular.isObject(application) ? application : mainDataContainer.application, mainDataContainer.availableOperations);
        };

        /**
         * czy wniosek jest wnioskiem dla OC nabywcy
         * @param  {Object}  appData plain obiekt wniosku
         * @return {Boolean} true gdy wniosek OC nabywcy
         */
        this.isOcBuyerApplication = function(appData) {
          if (!angular.isArray(appData.risks)) {
            return false;
          }
          var isOcBuyer = false;
          lsnNg.forEach(appData.risks, function(riskData) {
            if (angular.isDefined(riskData.product) && angular.isObject(riskData.product.dynamicValues) && riskData.product.dynamicValues.ocBuyer === true) {
              isOcBuyer = true;
              return false;
            }
            return true;
          });
          return isOcBuyer;
        };

        /**
         * czy wniosek został utworzony przez zewnętrzny system (taki wniosek należy inaczej zmapować)
         * @param  {Object}  appData plain object wniosku
         * @return {Boolean} true gdy wniosek utworzony poza LSN salesPath
         */
        this.isExternalCreated = function(appData) {
          if (angular.isObject(appData.dynamicValues) && angular.isString(appData.dynamicValues._selectedVariants)) { //sprawdzamy jeden z obligatoryjnych atrybutów ustawiany przez nasze GUI na kazdym wniosku
            return false;
          }
          return true;
        };

        /**
         * odfiltrowanie elementów z odpowiedzi które nie uległy zmianie
         * @return {Object} plain object osoby, firmy, pojazdu itp.
         */
        this.filterByLastRequest = function(element, lastRequest, clientId) {
          //gdy lastRequest nie jest zdefiniowany nic nie sprawdzamy, wystepuje to np w przypadku ofertowania
          if (angular.isUndefined(lastRequest)) {
            return element;
          }
          angular.forEach(lastRequest, function(elem) {
            if (elem.metaData.clientId === clientId) {
              angular.forEach(elem.dynamicValues, function(dynamicValue, k) {
                if (angular.isDefined(element.dynamicValues[k]) && element.dynamicValues[k] === dynamicValue) {
                  delete element.dynamicValues[k];
                }
              });
              if (angular.equals(element.dynamicValues, {})) {
                delete element.dynamicValues;
              }
            }
          });
          return element;
        };

        /**
         * odfiltrowanie elementów z odpowiedzi które nie uległy zmianie dla obiektu wniosku
         * @return {Object} plain object wniosku
         */
        this.filterByLastRequestAppl = function(appl, lastRequestAppl) {
          //gdy lastRequest nie jest zdefiniowany nic nie sprawdzamy, wystepuje to np w przypadku ofertowania
          if (angular.isUndefined(lastRequestAppl) || angular.equals(lastRequestAppl.data, {})) {
            return appl;
          }
          lsnNg.forEach(lastRequestAppl, function(value, name) {
            if (angular.isObject(value)) {
              return true;
            }
            if (angular.isDefined(appl[name]) && appl[name] === value) {
              delete appl[name];
            }
            return true;
          });
          return appl;
        };

        /**
         * ustawia appVariables.policyType na podstawie danych z wniosku i aktualnego trybu działania aplikacji
         * @param {ApplicationModel} appl wniosek sprzedażowy/oferta
         */
        this.setApplicationPolicyType = function(appl) {
          if (appVariables.isSupplement && angular.isArray(appl.policies)) { //obecnie tylko w trybie dokupień wspieramy ustawianie typu obsługiwanego pakietu/polisy
            lsnNg.forEach(appl.policies, function(policy) {
              appVariables.policyType = sp2CommonHelper.getPolicyType(policy);
              return false;
            });
          }
        };

        /**
         * ustawia informacje odnośnie dostępnych operacji na wniosku na podstawie odpowiedzi z usługi REST
         * @param {Object} appl obiekt wniosku zgodny z ApplicationModel (co do danych/pól)
         * @return {Boolean} czy uruchomiono usługę
         */
        this.setAvailableOperations = function(appl) {
          if (!sp2CommonHelper.isStatutoryRenewal(appl)) { //obecnie wsparcie tylko w trybie dokupień ustawowych
            return false;
          }
          applicationServiceHelper.getAvailableOperations(appl.metaData.id, function(resp) {
            mainDataContainer.availableOperations = resp.data;
            actionHelper.runAction('availableOperationsLoaded');
          });
          return true;
        };

        /**
         * Are any policy/offer printouts allowed
         * @param  {String} policyNumber
         * @return {Boolean}
         */
        this.policyPrintoutsAllowed = function(policyNumber) {
          return appVariables.appMode === CONSTANTS.APP_MODE_POLICY || self.isKIDPrintoutAllowed(policyNumber);
        };

        /**
         * is KID printout allowed for offer/policy
         * @param {String} policyNumber
         * @return {Boolean}
         */
        this.isKIDPrintoutAllowed = function(policyNumber) {
          var policy = dataContainerHelper.getPolicyByNumber(policyNumber);
          if (angular.isObject(policy.product.dynamicValues) && policy.product.dynamicValues._IDDEnabled) {
            return true;
          }
          return false;
        };

        /**
         * check if Extra-Life is enabled and get info for current insurer
         * @param {Object} applData plain data of calculation
         * @return {boolean} true if extra life service called
         */
        this.checkExtraLife = function(applData) {
          if (!sp2CommonHelper.isSalesCharacter()) {
            return false;
          }
          var insurer = lsnUtils.findObjInArray(applData.persons, {
            metaData: {
              id: applData.holderRef
            }
          });
          if (insurer === null) {
            return false;
          }
          if (self.lastInsurerPesel === insurer.pesel || !angular.isString(insurer.pesel) || insurer.pesel.trim().length !== 11) {
            return false; // (we've already checked this pesel - no insurer change) or invalid pesel (just general check)
          }
          if (CONFIG.BEHAVIOR.extraLife) {
            ihestiaExtraLifeHelper.setInfoByPesel(insurer.pesel);
          }
          if (CONFIG.BEHAVIOR.extraLifeE4) {
            clientVerificationHelper.verifyPesel(insurer.pesel);
          }
          self.lastInsurerPesel = insurer.pesel;
          return true;
        };

        /**
         * patch insurer contact data (e.g. in offer mode)
         * @param {Object} getData() (plain data) of most recent insurer
         * @return {*|HttpPromise}
         */
        this.patchInsurerContactData = function(insurerData) {
          return applicationServiceHelper.patch(mainDataContainer.application.metaData.id,
            {
              clauses: insurerData.clauses,
              contacts: insurerData.contacts
            },
            'policyholder/contactandclauses', lsnNg.noop, lsnNg.noop, {
              allowedStatuses: [400]
            });
        };

      };

      return new ApplicationHelper();
    }
  ])
  .run(['CONFIG', 'applicationHelper', 'actionHelper',
    function(CONFIG, applicationHelper, actionHelper) {
      actionHelper.registerHelper('applicationHelper', applicationHelper);
    }
  ]);