angular.module('salesPath2')
  .service('travelDestHelper', ['sp2CommonHelper', 'appVariables', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants',
    function(sp2CommonHelper, appVariables, ihestiaSsoBaseInfoHelper,UserCharacterTypeConstants) {
      var TravelDestHelper = function() {
        //ustawienia modali
        this.modals = {};

        this.isReadOnly = false;

        this.modals.travelDestModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.travelDestModal.title',
            size: 'md',
            okBtnName: '',
            okBtnDisabled: true,
            cancelBtnName: 'sp2.modal.cancel'
          }
        };

        /**
         * Wyświetlenie modala
         */
        this.showModal = function() {
          var readOnly = appVariables.readOnly 
          || ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL;
          this.readOnly = readOnly;
          this.modals.travelDestModal.settings.okBtnName = readOnly ? '' : 'sp2.modal.confirm';
          this.modals.travelDestModal.settings.cancelBtnName = readOnly ? 'sp2.modal.close' : 'sp2.modal.cancel';
          sp2CommonHelper.showModal('travelDestModal');
        };
      };

      return new TravelDestHelper();
    }
  ]);
