angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseVerificationDataCtrl', ['$scope', 'PersonModel', 'clientDataHelper', 'customerVehiclesSvc', '$filter', 'labelActionHelper', '$stateParams',
    function($scope, PersonModel, clientDataHelper, customerVehiclesSvc, $filter, labelActionHelper, $stateParams) {

      //zakup - dane sprzedającego

      $scope.vehicles = [];
      $scope.formData = {
        selectedVehicle: null
      };

      $scope.loadingElements = false; //czy w trakcie ładowania

      $scope.donationTypes = [];

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.prepareDonationTypes();
        labelActionHelper.registerAction('vehiclePurchaseVerificationDataCtrl', $scope.prepareDonationTypes);
        if($scope.otherData.isForward)
        {
          $scope.otherData.isForward = false;
          if($scope.canChangeDate && !$stateParams.sellDate) //jak kogoś automatycznie przenosimy z pierwszego kroku to wstawiamy mu sami datę na chwilę żeby zapisać wniosek
          {
            //i tu ją zerujemy, żeby mógł rzeczywistą podać.
            $scope.currentRecord.operationDate = null;
          }
        }

        $scope.setOtherSeller();
        $scope.setOwnerSelect();
        if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION && $scope.currentRecordNumber === 0 && !$scope.otherData.donationType)
        {
          if($stateParams.loadApplication)
          {
            //jak wczytanie wniosku to na pewno darowizna całkowita, bo częściowa jest zawsze jednokrokowa i kończy się dodaniem współwłaściciela
            $scope.otherData.donationType = 'Full';
          }
          else
          {
            $scope.otherData.donationType = null;
          }
        }
      };

      $scope.prepareDonationTypes = function()
      {
        $scope.donationTypes = [
        {
          code: 'Full',
          name: $filter('translate')('ownerChange.donationTypeFull', {componentCode: 'PolicyOperations'})
        },
        {
          code: 'Partial',
          name: $filter('translate')('ownerChange.donationTypePartial', {componentCode: 'PolicyOperations'})
        }
      ];
      };

      /**
       * Ustawiamy model dla innego sprzedającego
       */
      $scope.setOtherSeller = function(){
        if(!$scope.shared.sellerData || !$scope.shared.sellerData.metaData || $scope.isPersonFromPolicy($scope.shared.sellerData.metaData.id))
        {
          $scope.shared.otherSellerData = clientDataHelper.prepareNewPerson();
        }
      };

      /**
       * zmienił się wybór pojazdu
       */
      $scope.vehicleChanged = function() {
        $scope.shared.vehicleDirty = true;
        if($scope.shared.selectedVehicle && $scope.shared.selectedVehicle.policyNumber)
        {
          $scope.verificationData.policyNumber = $scope.shared.selectedVehicle.policyNumber;
        }
        if($scope.shared.selectedVehicle && $scope.verificationData.operationType !== $scope.VerificationDataModelConstants.VEHICLE_PURCHASE &&
          $scope.showPolicyPerson)
        {
          //na sprzedaży wybór pojazdu mamy tylko dla centrali i wtedy od razu towrzymy wniosek
          $scope.loadPolicyData();
        }
      };

      $scope.operationDateChanged = function()
      {
        if($scope.currentRecordNumber === 0 && $scope.canChangeVehicle)
        {
          $scope.findVehicles();
        }
      };

      $scope.donationTypeChanged = function()
      {
        if($scope.otherData.donationType === 'Partial')
        {
          $scope.shared.clientData.clientType = 'person';
        }
      };

      /**
       * wyszukiwanie pojazdów po zmianie daty
       * @return {array}
       */
      $scope.findVehicles = function() { //eslint-disable-line consistent-return
        var date = new XDate($scope.currentRecord.operationDate);
        if($scope.currentRecord.operationDate === '' || $scope.currentRecord.operationDate === null || angular.isUndefined($scope.currentRecord.operationDate))
        {
          $scope.vehicles = [];
          return false;
        }
        if(isNaN(date.getTime()))
        {
          $scope.vehicles = [];
          return false;
        }

        $scope.loadingElements = true;
        $scope.shared.selectedVehicle = null;

        customerVehiclesSvc.findByParams({
          id: $scope.appContextData.customerId,
          top: 200,
          pageSize: 200,
          query:
          {
            operationDate: date.toString('i'),
            distinctVehicles: true //bez duplikatów pojazdów
          },
          callback: function(response){
            $scope.vehicles = response.data.items;
            angular.forEach($scope.vehicles, function(vehicle){
              vehicle.fullDesc = vehicle.vehicleDescription + ', '+ vehicle.vehicleRegistrationNumber;
            });
            if($scope.vehicles.length > 0
             // && $scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_PURCHASE
             )
            {
              if($scope.vehicles.length === 1)
              {
                $scope.shared.selectedVehicle = $scope.vehicles[0];
              }

              $scope.vehicleChanged();
            }
            $scope.loadingElements = false;
          },
          errorCallback: function(){
            $scope.vehicles = [];
            $scope.loadingElements = false;
          }
        });
      };
    }
  ]);