/**
 * helper do parsowania walidacji z usług
 */

angular.module('ihestiaCommonDirectives')
  .factory('ihestiaServiceValidationHelper', [
    function() {

    var ServiceValidationHelper = function() {
      // var self = this;

      this.splitByType = function(serviceValidations) {
        var validationsObject = {}; // WARN, ERROR, INFO?
        angular.forEach(serviceValidations, function(serviceValidation){
          if(typeof validationsObject[serviceValidation.type] === 'undefined') {
            validationsObject[serviceValidation.type] = [];
          }
          validationsObject[serviceValidation.type].push(serviceValidation);
        });
        return validationsObject;
      };
    };

    return new ServiceValidationHelper();

  }]);