angular.module('salesPath2')
  .service('basketSecondHelper', ['mainDataContainer', 'localizationHelper', 'lsnTryEscapeHtml', '$sce', 'CONSTANTS', 'personHelper', 'resourceHelper', 'CONFIG', 'RESOURCES', 'vehicleHelper', 'personGroupHelper', 'appVariables', 'ihestiaLabelHelper', 'dataContainerHelper',
    function(mainDataContainer, localizationHelper, lsnTryEscapeHtml, $sce, CONSTANTS, personHelper, resourceHelper, CONFIG, RESOURCES, vehicleHelper, personGroupHelper, appVariables, ihestiaLabelHelper, dataContainerHelper) {

      var BasketSecondHelper = function() {
        var self = this;

        this.variantsToNumeric = {};
        this.variantsToNumeric[CONSTANTS.VARIANT_I] = 1;
        this.variantsToNumeric[CONSTANTS.VARIANT_II] = 2;
        this.variantsToNumeric[CONSTANTS.VARIANT_III] = 3;

        /**
         * domyślne ustawienia nazwy lokalizacji dla koszyka
         * @type {Object}
         */
        this.DEFAULT_LOCALIZATION_INFO_BASKET_SETTINGS = {
          showName: true,
          elements: [{
            field: 'city'
          }, {
            field: 'street'
          }, {
            field: 'house',
            fieldSeparator: ''
          }, {
            field: 'apartment',
            prefix: '/',
            lastInLine: true
          }],
          maxLength: 150
        };

        /**
         * domyślne ustawienia nazwy osoby dla koszyka
         * @type {Object}
         */
        this.DEFAULT_PERSON_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'lastName'
          }, {
            field: 'firstName'
          }],
          maxLength: 150
        };

        /**
         * domyślne ustawienia nazwy grupy dla koszyka
         * @type {Object}
         */
        this.DEFAULT_GROUP_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'name'
          }, {
            field: 'personCount'
          }],
          maxLength: 150
        };

        /**
         * domyślne ustawienia nazwy pojazdu dla koszyka
         * @type {Object}
         */
        this.DEFAULT_VEHICLE_INFO_BASKET_SETTINGS = {
          elements: [{
            field: 'mark',
            fieldSeparator: ', '
          }, {
            field: 'registration'
          }],
          maxLength: 150
        };

        this.DEFAULT_BASKET_CONTENT_DATA = {
          objectsWithSums: null,
          printOfferDesc: {
            __: ['viewCalculation', 'sp2']
          }
        };

        this.tplData = {
          basketContent: {},
          diffCalcType: 'PERC',
          diffCalcTypeSign: '%'
        };

        /**
         * tworzy dane dla koszyka na podstawie DEFAULT_BASKET_CONTENT_DATA
         * @return {Object} dane
         */
        this.createBasketContent = function() {
          var data = angular.copy(this.DEFAULT_BASKET_CONTENT_DATA);
          return ihestiaLabelHelper.transObject(data);
        };

        //init danych dla tplData
        this.initTplData = function() {
          self.tplData.basketContent = self.createBasketContent();
        };

        /**
         * Czyścimy dane koszyka
         */
        this.clearBasketContentData = function() {
          angular.forEach(self.createBasketContent(), function(v, k) {
            self.tplData.basketContent[k] = angular.copy(v);
          });
        };

        /**
         * liczy zmianę w procentach lub kwocie w składce przed i po
         */
        this.calcDiff = function(before, after) {

          if (before > 0) {
            var calc = 0; // NOSONAR
            if (self.tplData.diffCalcType === 'PERC') {
              calc = (100 - ((100 * after) / before)) * -1;
              calc = Math.round(calc);
              //bez wartości bezwzględnej
              return calc;
            } else {
              calc = Math.round(after - before);
              return calc;
            }
          } else {
            return 0;
          }

        };

        /**
         * Przeladowujemy dane koszyka2 (zniżki indywidualne)
         */
        this.refreshBasketContentData = function() {
          if (!appVariables.basketContainerVisible) { //jesli koszyk nie otwarty, to jego danych nie aktualizujemy
            return;
          }
          self.clearBasketContentData();
          var bcData = self.getBasketContentData(false);
          self.tplData.basketContent.objectsWithSums = {
            objects: bcData.objects,
            sumBefore: bcData.sumAllBefore,
            sumAfter: bcData.sumAllAfter,
            diff: self.calcDiff(bcData.sumAllBefore, bcData.sumAllAfter),
            discounts: dataContainerHelper.getDiscounts()
          };
        };

        /**
         * build and return basket content data object
         * @param {boolean} [purePremium=false] if true, premiums will not contain attached additions
         * @return {{sumAllBefore: number, objects: {}, sumAllAfter: number}}
         */
        this.getBasketContentData = function(purePremium) {
          purePremium = !!purePremium;
          var objects = self.getObjectsWithSums(purePremium),
            sumAllBefore = 0,
            sumAllAfter = 0,
            lastVariant = null;
          angular.forEach(objects, function(object) {
            object.productsTable = [];
            angular.forEach(object.products, function(variants, productCode) {
              var prodDef = resourceHelper.getProdDef(productCode);
              var productPremiumBefore = 0;
              var productPremiumAfter = 0;

              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //każdy wariant osobno
              {
                angular.forEach(variants, function(premium, variant) {
                  productPremiumBefore += premium.before;
                  productPremiumAfter += premium.after;
                  lastVariant = variant;
                  object.productsTable.push({
                    name: resourceHelper.getProductName(productCode, 'BASKET_NAME', variant),
                    valueBefore: premium.before,
                    valueAfter: premium.after,
                    diff: premium.diff,
                    code: prodDef.CODE,
                    prodDef: prodDef,
                    variantNumeric: self.variantsToNumeric[variant]
                  });
                });
              } else {
                lastVariant = null;
                angular.forEach(variants, function(premium, variant) {
                  productPremiumBefore += premium.before;
                  productPremiumAfter += premium.after;
                  lastVariant = variant;
                });

                object.productsTable.push({
                  name: resourceHelper.getProductName(productCode, 'BASKET_NAME', lastVariant),
                  valueBefore: productPremiumBefore,
                  valueAfter: productPremiumAfter,
                  diff: self.calcDiff(productPremiumBefore, productPremiumAfter),
                  code: prodDef.CODE,
                  prodDef: prodDef,
                  variantNumeric: prodDef.VARIANTLIST.length === 1 ? 0 : self.variantsToNumeric[lastVariant]
                });
              }
            });

            object.addsTable = [];
            angular.forEach(object.adds, function(addValue, addCode) {
              var addDef = resourceHelper.getAddDef(addCode);
              if (angular.isDefined(addDef.VARIANTS)) {
                angular.forEach(addValue, function(premium, variant) {
                  object.addsTable.push({
                    name: resourceHelper.getAddName(object, addCode, 'BASKETNAME', variant),
                    valueBefore: premium.before,
                    valueAfter: premium.after,
                    diff: premium.diff
                  });
                });
              } else {
                object.addsTable.push({
                  name: resourceHelper.getAddName(object, addCode, 'BASKETNAME'),
                  valueBefore: addValue.before,
                  valueAfter: addValue.after,
                  diff: self.calcDiff(addValue.before, addValue.after)
                });
              }
            });
            sumAllBefore += object.sumBefore;
            sumAllAfter += object.sumAfter;
          });
          return {
            objects: objects,
            sumAllBefore: sumAllBefore,
            sumAllAfter: sumAllAfter
          };
        };

        /**
         * zwraca ubezpieczone obiekty (osoby, pojazdy, lokalizacje) wraz ze składkami przed i po zniżce indywidualnej
         * @param {boolean} [purePremium=false] if true, pure premium (without attached additions) will be counted
         * @return {Object}
         */
        this.getObjectsWithSums = function(purePremium) {
          purePremium = !!purePremium;
          var objects = [];
          var object = null;
          // lokalizacje
          if (!angular.equals(mainDataContainer.localizations, {})) {
            lsnNg.forEach(mainDataContainer.localizations, function(localization, id) {
              object = {};
              if (localization.get('name') === null || localization.get('name') === '') {
                object.name = localizationHelper.getLocalizationInfo(localization, self.DEFAULT_LOCALIZATION_INFO_BASKET_SETTINGS);
              } else {
                var shortName = localization.get('name');
                if (shortName.length > 150) {
                  shortName = shortName.substring(0, 150) + '...';
                }
                object.name = $sce.trustAsHtml(lsnTryEscapeHtml(shortName));
              }
              var showObject = false;
              object.products = {};
              object.adds = {};
              object.sumBefore = 0;
              object.sumAfter = 0;
              object.diff = 0;
              object.id = id;
              object.type = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;

              //produkty
              if (typeof mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][id] === 'undefined') {
                return true;
              }
              //ryzyka OGI, KRA
              lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][id], function(variant, productCode) {
                if (variant === null) //brak zaznaczonego wariantu
                {
                  return true;
                }

                var premiums = dataContainerHelper.getPremiumBeforeDiscout(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, id, productCode, CONSTANTS.SUM_NAME, purePremium);
                if (typeof premiums[variant] !== 'undefined' && premiums[variant] !== 0) {
                  showObject = true;
                  object.sumBefore += premiums[variant];
                  object.products[productCode] = {};
                  object.products[productCode][variant] = {
                    before: 0,
                    after: 0
                  };
                  object.products[productCode][variant].before = premiums[variant];
                }
                var premiumsAfter = dataContainerHelper.getPremiumAfterDiscout(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, id, productCode, CONSTANTS.SUM_NAME, purePremium);
                if (typeof premiumsAfter[variant] !== 'undefined' && premiumsAfter[variant] !== 0) {
                  object.sumAfter += premiumsAfter[variant];
                  object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                  object.products[productCode][variant].after = premiumsAfter[variant];
                }

                return true; //continue
              });

              lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
                if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                  if (angular.isDefined(addData.VARIANTS)) {
                    lsnNg.forEach(addData.VARIANTS, function(variantData) {
                      lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE], function(objectId) {
                        if (objectId === id) {
                          showObject = true;
                          if (angular.isUndefined(object.adds[addData.CODE])) {
                            object.adds[addData.CODE] = {};
                          }
                          var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, variantData.CODE, id, purePremium);
                          if (addPremium !== 0) {
                            object.adds[addData.CODE][variantData.CODE] = {
                              before: 0,
                              after: 0,
                              diff: 0
                            };
                            object.adds[addData.CODE][variantData.CODE].before = addPremium;
                            object.sumBefore += addPremium;
                          }
                          var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, variantData.CODE, id, purePremium);
                          if (addPremiumAfter !== 0) {
                            object.adds[addData.CODE][variantData.CODE].after = addPremiumAfter;
                            object.sumAfter += addPremiumAfter;
                            object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                          }
                        }
                      });
                    });
                  } else {
                    lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
                      if (objectId === id) {
                        showObject = true;
                        var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, null, id, purePremium);
                        if (addPremium !== 0) {
                          object.adds[addData.CODE] = {
                            before: 0,
                            after: 0,
                            diff: 0
                          };
                          object.adds[addData.CODE].before = addPremium;
                          object.sumBefore += addPremium;
                        }
                        var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, null, id, purePremium);
                        if (addPremiumAfter !== 0) {
                          object.adds[addData.CODE].after = addPremiumAfter;
                          object.sumAfter += addPremiumAfter;
                          object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                        }
                      }
                    });
                  }
                }
              });


              if (showObject) {
                objects.push(object);
              }

              return true; //continue
            });
          }

          //osoby
          lsnNg.forEach(mainDataContainer.persons, function(person, id) {
            var showObject = false;
            object = {
              name: personHelper.getPersonInfo(person, self.DEFAULT_PERSON_INFO_BASKET_SETTINGS),
              products: {},
              adds: {},
              sumBefore: 0,
              sumAfter: 0,
              diff: 0,
              id: id,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };

            //produkty
            lsnNg.forEach(resourceHelper.productType, function(productType, productCode) {
              //osoby (poniżej są jeszcze grupy dla tematycznych)
              if (productType === CONSTANTS.PRODUCT_TYPE_PERSON) {
                var selectedVariants = {};
                var productPersons = dataContainerHelper.getPersonsForPersonalProduct(productCode);

                if (angular.isDefined(productPersons[id])) {
                  if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //możliwe wiele wariantów
                  {
                    lsnNg.forEach(productPersons[id].variants, function(selected, variant) {
                      if (selected) {
                        selectedVariants[variant] = true;
                      }
                    });
                  } else {
                    if (productPersons[id].variants === null) {
                      return true;
                    } else {
                      selectedVariants[productPersons[id].variants] = true;
                    }
                  }
                }

                var variantsCount = 0;
                var riskPremiums = dataContainerHelper.getPremiumBeforeDiscout(CONSTANTS.PRODUCT_TYPE_PERSON, id, productCode, undefined, purePremium);

                lsnNg.forEach(selectedVariants, function(isSelected, variant) {
                  if (typeof riskPremiums[variant] === 'undefined' || riskPremiums[variant] === 0) {
                    delete selectedVariants[variant];
                    return true;
                  }
                  object.sumBefore += riskPremiums[variant];
                  selectedVariants[variant] = {
                    before: 0,
                    after: 0,
                    diff: 0
                  };
                  selectedVariants[variant].before = riskPremiums[variant];
                  variantsCount += 1;

                  return true; //continue
                });

                var riskPremiumsAfter = dataContainerHelper.getPremiumAfterDiscout(CONSTANTS.PRODUCT_TYPE_PERSON, id, productCode, undefined, purePremium);

                lsnNg.forEach(selectedVariants, function(isSelected, variant) {
                  if (typeof riskPremiumsAfter[variant] === 'undefined' || riskPremiumsAfter[variant] === 0) {
                    delete selectedVariants[variant];
                    return true;
                  }
                  object.sumAfter += riskPremiumsAfter[variant];
                  object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                  selectedVariants[variant].after = riskPremiumsAfter[variant];
                  selectedVariants[variant].diff = self.calcDiff(riskPremiums[variant], riskPremiumsAfter[variant]);

                  return true; //continue
                });

                if (variantsCount) {
                  showObject = true;
                  object.products[productCode] = selectedVariants;
                }
              }

              return true; //continue
            });

            //dodatki
            lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
              if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) {
                if (typeof addData.VARIANTS !== 'undefined') {
                  lsnNg.forEach(addData.VARIANTS, function(variantData) {
                    lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE], function(objectId) {
                      if (objectId === id) {
                        showObject = true;
                        if (typeof object.adds[addData.CODE] === 'undefined') {
                          object.adds[addData.CODE] = {};
                        }

                        var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, variantData.CODE, id, purePremium);
                        if (addPremium !== 0) {
                          object.adds[addData.CODE][variantData.CODE] = {
                            before: 0,
                            after: 0,
                            diff: 0
                          };
                          object.adds[addData.CODE][variantData.CODE].before = addPremium;
                          object.sumBefore += addPremium;
                        }
                        var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, variantData.CODE, id, purePremium);
                        if (addPremiumAfter !== 0) {
                          object.adds[addData.CODE][variantData.CODE].after = addPremiumAfter;
                          object.sumAfter += addPremiumAfter;
                          object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                          object.adds[addData.CODE][variantData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                        }

                      }
                    });
                  });
                } else {
                  lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
                    if (objectId === id) {
                      showObject = true;
                      var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, null, id, purePremium);
                      if (addPremium !== 0) {
                        object.adds[addData.CODE] = {
                          before: 0,
                          after: 0,
                          diff: 0
                        };
                        object.adds[addData.CODE].before = addPremium;
                        object.sumBefore += addPremium;
                      }
                      var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, null, id, purePremium);
                      if (addPremiumAfter !== 0) {
                        object.adds[addData.CODE].after = addPremiumAfter;
                        object.sumAfter += addPremiumAfter;
                        object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                        object.adds[addData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                      }
                    }
                  });
                }
              }
            });
            if (showObject) {
              objects.push(object);
            }
          });

          // NNW pracownicy bezimiennie
          // może być tylko jeden taki obiekt, tylko w I wariancie, więc sprawdzamy tylko ten
          if (mainDataContainer.nnwIncognito !== null && angular.isDefined(mainDataContainer.nnwIncognito.variants) && mainDataContainer.nnwIncognito.variants[CONSTANTS.VARIANT_I] && angular.isDefined(mainDataContainer.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID]) && mainDataContainer.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID][CONSTANTS.PRODUCT_NNW][CONSTANTS.VARIANT_I]) {
            var premiumBefore = {};
            var premiumAfter = {};
            var premium = {};
            premiumBefore[CONSTANTS.VARIANT_I] = mainDataContainer.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID][CONSTANTS.PRODUCT_NNW][CONSTANTS.VARIANT_I];
            premiumAfter[CONSTANTS.VARIANT_I] = mainDataContainer.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON][CONSTANTS.NNW_INCOGNITO_PERSON_ID][CONSTANTS.PRODUCT_NNW][CONSTANTS.VARIANT_I];
            premium[CONSTANTS.VARIANT_I] = {
              before: premiumBefore[CONSTANTS.VARIANT_I],
              after: premiumAfter[CONSTANTS.VARIANT_I],
              diff: self.calcDiff(premiumBefore[CONSTANTS.VARIANT_I], premiumAfter[CONSTANTS.VARIANT_I])
            };
            var incognito = {
              name: $sce.trustAsHtml('Pracownicy bezimiennie'),
              products: {
                NNW: premium
              },
              adds: {},
              sumBefore: premiumBefore[CONSTANTS.VARIANT_I],
              sumAfter: premiumAfter[CONSTANTS.VARIANT_I],
              diff: self.calcDiff(premiumBefore[CONSTANTS.VARIANT_I], premiumAfter[CONSTANTS.VARIANT_I]),
              id: CONSTANTS.NNW_INCOGNITO_PERSON_ID,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };
            objects.push(incognito);
          }


          //pojazdy
          if (!angular.equals(mainDataContainer.vehicles, {})) {
            lsnNg.forEach(mainDataContainer.vehicles, function(vehicle, id) {
              object = {};
              object.name = vehicleHelper.getVehicleInfo(vehicle, self.DEFAULT_VEHICLE_INFO_BASKET_SETTINGS);

              var showObject = false;
              object.products = {};
              object.adds = {};
              object.sumBefore = 0;
              object.sumAfter = 0;
              object.id = id;
              object.type = CONSTANTS.PRODUCT_TYPE_VEHICLE;

              //produkty
              lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE][id], function(variant, productCode) {
                if (variant === null) //brak zaznaczonego wariantu
                {
                  return true;
                }

                var premiums = dataContainerHelper.getPremiumBeforeDiscout(CONSTANTS.PRODUCT_TYPE_VEHICLE, id, productCode, undefined, purePremium);
                if (typeof premiums[variant] !== 'undefined' && premiums[variant] !== 0) {
                  showObject = true;
                  object.sumBefore += premiums[variant];
                  object.products[productCode] = {};
                  object.products[productCode][variant] = {
                    before: 0,
                    after: 0,
                    diff: 0
                  };
                  object.products[productCode][variant].before = premiums[variant];
                }
                var premiumsAfter = dataContainerHelper.getPremiumAfterDiscout(CONSTANTS.PRODUCT_TYPE_VEHICLE, id, productCode, undefined, purePremium);
                if (typeof premiumsAfter[variant] !== 'undefined' && premiumsAfter[variant] !== 0) {
                  object.sumAfter += premiumsAfter[variant];
                  object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                  object.products[productCode][variant].after = premiumsAfter[variant];
                  object.products[productCode][variant].diff = self.calcDiff(premiums[variant], premiumsAfter[variant]);
                }

                return true; //continue
              });

              //dodatki
              lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
                if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
                  if (typeof addData.VARIANTS !== 'undefined') {
                    lsnNg.forEach(addData.VARIANTS, function(variantData) {
                      lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE], function(objectId) {
                        if (objectId === id) {
                          showObject = true;
                          if (typeof object.adds[addData.CODE] === 'undefined') {
                            object.adds[addData.CODE] = {};
                          }
                          var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, variantData.CODE, id, purePremium);
                          if (addPremium !== 0) {
                            object.adds[addData.CODE][variantData.CODE] = {
                              before: 0,
                              after: 0,
                              diff: 0
                            };
                            object.adds[addData.CODE][variantData.CODE].before = addPremium;
                            object.sumBefore += addPremium;
                          }
                          var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, variantData.CODE, id, purePremium);
                          if (addPremiumAfter !== 0) {
                            object.adds[addData.CODE][variantData.CODE].after = addPremiumAfter;
                            object.adds[addData.CODE][variantData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                            object.sumAfter += addPremiumAfter;
                            object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                          }
                        }

                      });
                    });
                  } else {
                    lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
                      if (objectId === id) {
                        showObject = true;
                        var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, null, id, purePremium);
                        if (addPremium !== 0) {
                          object.adds[addData.CODE] = {
                            before: 0,
                            after: 0,
                            diff: 0
                          };
                          object.adds[addData.CODE].before = addPremium;
                          object.sumBefore += addPremium;
                        }
                        var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, null, id, purePremium);
                        if (addPremiumAfter !== 0) {
                          object.adds[addData.CODE].after = addPremiumAfter;
                          object.adds[addData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                          object.sumAfter += addPremiumAfter;
                          object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                        }
                      }
                    });
                  }
                }
              });
              if (showObject) {
                objects.push(object);
              }
            });
          }

          //grupy-osoby
          lsnNg.forEach(dataContainerHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(groupPerson, id) {
            object = {};
            var showObject = false;
            var isPerson = dataContainerHelper.isPerson(groupPerson);
            object = {
              name: isPerson ? personHelper.getPersonInfo(groupPerson, self.DEFAULT_PERSON_INFO_BASKET_SETTINGS) : personGroupHelper.getGroupInfo(groupPerson, self.DEFAULT_GROUP_INFO_BASKET_SETTINGS),
              products: {},
              adds: {},
              sumBefore: 0,
              sumAfter: 0,
              diff: 0,
              id: id,
              type: CONSTANTS.PRODUCT_TYPE_PERSON
            };

            //produkty
            lsnNg.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][id], function(variant, productCode) {
              if (variant === null) //brak zaznaczonego wariantu
              {
                return true;
              }

              var premiums = dataContainerHelper.getPremiumBeforeDiscout(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, id, productCode, undefined, purePremium);
              if (angular.isDefined(premiums[variant]) && premiums[variant] !== 0) {
                showObject = true;
                object.sumBefore += premiums[variant];
                object.products[productCode] = {};
                object.products[productCode][variant] = {
                  before: 0,
                  after: 0,
                  diff: 0
                };
                object.products[productCode][variant].before = premiums[variant];
              }
              var premiumsAfter = dataContainerHelper.getPremiumAfterDiscout(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, id, productCode, undefined, purePremium);
              if (angular.isDefined(premiumsAfter[variant]) && premiumsAfter[variant] !== 0) {
                object.sumAfter += premiumsAfter[variant];
                object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                object.products[productCode][variant].after = premiumsAfter[variant];
                object.products[productCode][variant].diff = self.calcDiff(premiums[variant], premiumsAfter[variant]);
              }

              return true; //continue
            });

            //dodatki
            lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
              if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
                if (angular.isDefined(addData.VARIANTS)) {
                  lsnNg.forEach(addData.VARIANTS, function(variantData) {
                    lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE], function(objectId) {
                      if (objectId === id) {
                        showObject = true;
                        if (angular.isUndefined(object.adds[addData.CODE])) {
                          object.adds[addData.CODE] = {};
                        }
                        var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, variantData.CODE, id, purePremium);
                        if (addPremium !== 0) {
                          object.adds[addData.CODE][variantData.CODE] = {
                            before: 0,
                            after: 0,
                            diff: 0
                          };
                          object.adds[addData.CODE][variantData.CODE].before = addPremium;
                          object.sumBefore += addPremium;
                        }
                        var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, variantData.CODE, id, purePremium);
                        if (addPremiumAfter !== 0) {
                          object.adds[addData.CODE][variantData.CODE].after = addPremiumAfter;
                          object.adds[addData.CODE][variantData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                          object.sumAfter += addPremiumAfter;
                          object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                        }
                      }

                    });
                  });
                } else {
                  lsnNg.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
                    if (objectId === id) {
                      showObject = true;
                      var addPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, null, id, purePremium);
                      if (addPremium !== 0) {
                        object.adds[addData.CODE] = {
                          before: 0,
                          after: 0,
                          diff: 0
                        };
                        object.adds[addData.CODE].before = addPremium;
                        object.sumBefore += addPremium;
                      }
                      var addPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, null, id, purePremium);
                      if (addPremiumAfter !== 0) {
                        object.adds[addData.CODE].after = addPremiumAfter;
                        object.adds[addData.CODE].diff = self.calcDiff(addPremium, addPremiumAfter);
                        object.sumAfter += addPremiumAfter;
                        object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                      }
                    }
                  });
                }
              }
            });

            if (showObject) {
              objects.push(object);
            }
          });


          if (objects.length !== 0) {
            //dodatki pakietowe dorzucamy do pierwszego obiektu
            object = objects[0];
            lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
              if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
                var packageAddPremium = null;
                var packageAddPremiumAfter = null;
                if (angular.isDefined(addData.VARIANTS)) {
                  lsnNg.forEach(addData.VARIANTS, function(variantData) {
                    if (mainDataContainer.selectedAdditions[addData.CODE][variantData.CODE]) {
                      if (angular.isUndefined(object.adds[addData.CODE])) {
                        object.adds[addData.CODE] = {};
                      }
                      packageAddPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, variantData.CODE, null, purePremium);
                      if (packageAddPremium !== 0) {
                        object.adds[addData.CODE][variantData.CODE] = {
                          before: 0,
                          after: 0,
                          diff: 0
                        };
                        object.adds[addData.CODE][variantData.CODE].before = packageAddPremium;
                        object.sumBefore += packageAddPremium;
                      }
                      packageAddPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, variantData.CODE, null, purePremium);
                      if (packageAddPremiumAfter !== 0) {
                        object.adds[addData.CODE][variantData.CODE].after = packageAddPremiumAfter;
                        object.adds[addData.CODE][variantData.CODE].diff = self.calcDiff(packageAddPremium, packageAddPremiumAfter);
                        object.sumAfter += packageAddPremiumAfter;
                        object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                      }
                    }
                  });
                } else {
                  if (mainDataContainer.selectedAdditions[addData.CODE]) {
                    packageAddPremium = dataContainerHelper.getAddPremiumBeforeDiscout(addData.CODE, null, null, purePremium);
                    if (packageAddPremium !== 0) {
                      object.adds[addData.CODE] = {
                        before: 0,
                        after: 0,
                        diff: 0
                      };
                      object.adds[addData.CODE].before = packageAddPremium;
                      object.sumBefore += packageAddPremium;
                    }
                    packageAddPremiumAfter = dataContainerHelper.getAddPremiumAfterDiscout(addData.CODE, null, null, purePremium);
                    if (packageAddPremiumAfter !== 0) {
                      object.adds[addData.CODE].after = packageAddPremiumAfter;
                      object.adds[addData.CODE].diff = self.calcDiff(packageAddPremium, packageAddPremiumAfter);
                      object.sumAfter += packageAddPremiumAfter;
                      object.diff = self.calcDiff(object.sumBefore, object.sumAfter);
                    }
                  }
                }
              }
            });

            self.initTplData();
          }

          return objects;
        };

      };

      return new BasketSecondHelper();
    }
  ]);