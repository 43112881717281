angular.module('salesPath2')
  .controller('sp2HeaderCtrl', ['$scope', '$attrs', '$parse', 'applicationHelper', 'sp2HeaderHelper', 'personModalHelper', 'localizationModalHelper', '$timeout', 'leasingModalHelper', 'coownerModalHelper', 'bonusMalusModalHelper', 'vehicleModalHelper', 'sp2SelectionPopups', '$filter', 'appVariables', 'newBonusMalusModalHelper', 'dataContainerHelper', 'CONSTANTS', 'travelDestHelper', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants',
    function($scope, $attrs, $parse, applicationHelper, sp2HeaderHelper, personModalHelper, localizationModalHelper, $timeout, leasingModalHelper, coownerModalHelper, bonusMalusModalHelper, vehicleModalHelper, sp2SelectionPopups, $filter, appVariables, newBonusMalusModalHelper, dataContainerHelper, CONSTANTS, travelDestHelper, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants) {
      $scope.tplHeaderData = sp2HeaderHelper.tplData;
      $scope.readOnly = appVariables.readOnly 
        || ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL;
      $scope.settings = {};

      $scope.init = function() {
        sp2HeaderHelper.initData($attrs.drvSettings ? $parse($attrs.drvSettings)($scope) : {});
        $scope.settings = sp2HeaderHelper.currentSettings;
      };

      /**
       * aktualizuje datę ochrony "do"
       * @param  {String} type 'start' lub 'end' - którą datę aktualizujemy
       */
      $scope.updateProtectionDate = function(type) {
        return sp2HeaderHelper.updateProtectionDate(type);
      };

      /**
       * edycja ubezpieczonego
       */
      $scope.editInsurer = function() {
        personModalHelper.openPopupEditPerson({
          id: $scope.dataContainer.insurerId,
          editHeader: $filter('translate')('Public.insurer', {componentCode: 'Public'})
        });
      };

      /**
       * edycja leasingodawcy
       */
      $scope.editLeasing = function() {
        var leaserId = sp2HeaderHelper.getSelectedObject().getAdditionalData('leaserId');

        if (leaserId) {
          leasingModalHelper.showLeaserInfo(leaserId, sp2HeaderHelper.currentSettings.object);
        } else if (!appVariables.isSupplement) {
          leasingModalHelper.editLeasing(sp2HeaderHelper.currentSettings.object, null, leaserId);
        }
      };

      /**
       * open new BM modal
       *edycja danych BonusMalus - ubezpieczony - disabled with earnix
       */
      $scope.editBm = function() {
        newBonusMalusModalHelper.showModal(dataContainerHelper.getSelectedObjectId(CONSTANTS.PRODUCT_TYPE_VEHICLE));
        // bonusMalusModalHelper.showPopup($scope.dataContainer.insurerId);
      };

      /**
       * edit coowners / communication insured (persons)
       * @param {boolean} [force=false] if true then no conditions are checked
       */
      $scope.editCoowner = function(force) {
        if (force || ($scope.tplHeaderData.coowners.length > 0 || !(appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer)))) {
          coownerModalHelper.showCoownerList(sp2HeaderHelper.currentSettings.object);
        }
      };

      $scope.editSelectedLocalization = function() {
        $timeout(function() {
          localizationModalHelper.showEditLocalizationModal($scope.dataContainer.selectedLocalization);
        }, 0);
      };

      $scope.editSelectedVehicle = function() {
        $timeout(function() {
          vehicleModalHelper.editVehicle($scope.dataContainer.selectedVehicle);
        }, 0);
      };

      /**
       * pokazuje popup wyboru sposobu likwidacji szkód
       */
      $scope.showLiquidationPopup = function() {
        sp2SelectionPopups.showLiquidationPopup();
      };

      /**
       * pokazuje popup wyboru kierunku podróży
       */
      $scope.showTravelDestModal = function() {
        travelDestHelper.showModal();
      };

      $scope.init();
    }
  ]);
