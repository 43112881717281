/**
 * Mapper wariantów ryzyk (z symulacji)
 */
angular.module('salesPath2').service('fromRestVehicleMapper', ['MapperUtils', 'CONSTANTS', 'ihestiaCommonErrorHandler', 'SPD', 'CONFIG', 'RESOURCES', 'fromRestCompatibilityMapper', 'vehicleHelper', 'VehicleModelConstants',
  function(MapperUtils, CONSTANTS, ihestiaCommonErrorHandler, SPD, CONFIG, RESOURCES, fromRestCompatibilityMapper, vehicleHelper, VehicleModelConstants) {
    var FromRestVehicleMapper = function() {
      var self = this;
      this.utils = MapperUtils;
      this.defaultOptions = { //opcje mapowania
        initialLoad: false //pierwsze wczytanie wniosku/polisy
      };

      this.options = angular.copy(this.defaultOptions);
      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };
      /**
       * mapuje nieruchomosci
       * @param  {ApplicationModel} application wniosek
       * @param  {Object} tmpDc dataContainer
       * @param  {Object} mapOptions opcje mapowania
       */
      this.mapVehicles = function(application, tmpDc, mapOptions) { //eslint-disable-line consistent-return
        self._setOptions(mapOptions);
        if (application.vehicles === null) {
          return {};
        }
        var vehiclesObj = {},
          nextId = tmpDc.nextObjectId;
        angular.forEach(application.vehicles, function(vehicle, idx) {
          nextId = Math.max(nextId, parseInt(vehicle.metaData.get('clientId'), 10) + 1);
          self._mapVehicle(vehicle);
          vehiclesObj[vehicle.metaData.get('clientId')] = vehicle;
          tmpDc._vehicleIdMap[vehicle.metaData.get('id')] = vehicle.metaData.get('clientId');
          if (idx === 0) { //pierwsza iteracja
            tmpDc.selectedVehicle = vehicle.metaData.get('clientId');
          }
        });
        tmpDc.vehicles = vehiclesObj;
        tmpDc.nextObjectId = nextId;
      };

      this._mapVehicle = function(vehicle) {
        var dynVals = vehicle.get('dynamicValues');
        if (angular.isObject(dynVals)) {
          if (angular.isDefined(dynVals._additionalData)) {
            var addData = self.utils.unserializeValue(dynVals._additionalData);
            if (self.options.initialLoad) {
              fromRestCompatibilityMapper.mapAdditionalData(addData);
            }
            vehicle.setAdditionalData(addData);
            delete dynVals._additionalData;
          } else if (self.options.initialLoad) {
            if (!vehicle.getAdditionalData('protectionDates')) {
              vehicle.setAdditionalData(vehicleHelper.getDefaultProtectionDates(), 'protectionDates');
            }
            if (vehicle.getAdditionalData('isLeased') === null && angular.isDefined(dynVals._leased)) { //jesli info o zaznczonym leasingu przyszlo tylko na dynamicValues, to przepisujemy je na additionalData
              vehicle.setAdditionalData(dynVals._leased, 'isLeased');
            }
            if (vehicle.getAdditionalData('vehicleValue') === null && angular.isDefined(dynVals.vehicleValue)) { //wartość pojazdu (podwojnie zapisywany z uwagi na non-etx)
              vehicle.setAdditionalData(dynVals.vehicleValue, 'vehicleValue');
            }
            if (vehicle.getAdditionalData('source') === null) { //jesli info o pojeździe spoza etx przyszlo tylko na dynamicValues, to przepisujemy je na additionalData
              if (angular.isDefined(dynVals._vehicleInEtx)) {
                vehicle.setAdditionalData(self._getVehicleSourceByDynVal(dynVals._vehicleInEtx), 'source');
              } else {
                var vehType = vehicle.get('type', false, true);
                vehicle.setAdditionalData(self._getVehicleSourceByDynVal(angular.isArray(vehType) && angular.isString(vehType[2]) && vehType[2] === VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX), 'source');
              }

            }
            if (vehicle.getAdditionalData('rightHandDrive') === null && angular.isDefined(dynVals._rightHandDrive)) { //jesli info o kierownicy po prawwj stronie
              vehicle.setAdditionalData(dynVals._rightHandDrive, 'rightHandDrive');
            }
          }
        }

        vehicle.dynVals = dynVals;
        self.utils.getTemporaryDynamicValuesForObject(vehicle);
        self.utils.removeUnusedDynamicValuesForObject(vehicle);
        // originalKey filed's value mapping
        vehicle.set('originalKey', self._mapKeyCount(vehicle.get('originalKey')));
      };
      /**
       * zwraca pojazd ze zmapowanego obiektu datamanagera
       * @param {string} vehicleId id pojazdu
       * @param {FromRestTempDataContainerModel} tmpDc
       * @return {Vehicle} pojazd
       */
      this.getVehicleByClientId = function(vehicleId, tmpDc) {
        var obj = null;
        if (angular.isDefined(tmpDc.vehicles[vehicleId])) {
          obj = tmpDc.vehicles[vehicleId];
        } else {
          ihestiaCommonErrorHandler.throwException('No vehicle mapped with clientId: {0}.'.format(vehicleId));
        }
        return obj;
      };
      /**
       * ustawia additionalData na podstawie danych z ryzyka
       * @param {Vehicle} vehicle pojazd
       * @param {Risk} risk ryzyko
       * @param {FromRestTempDataContainerModel} tymcz. dataContainer
       */
      this.setAdditionalDataFromRisk = function(vehicle, risk, tmpDc) {
        var addData = vehicle.getAdditionalData(),
          dynVals = risk.product.get('dynamicValues'),
          riskDef = self.utils.findRiskDefByIdpm(risk.product.get('compId'));
        //daty ochrony
        if (angular.isUndefined(addData.protectionDates)) {
          addData.protectionDates = {
            start: null,
            end: null
          };
        }
        if (risk.get('start') !== null && risk.get('end') !== null) {
          addData.protectionDates.start = self.utils.getFormattedJSProtectionDate(risk.get('start'));
          addData.protectionDates.end = self.utils.getFormattedJSProtectionDate(risk.get('end'));
        }
        //sposob likwidacji szkod
        if (angular.isDefined(dynVals.amortization)) {
          lsnNg.forEach(CONSTANTS.AMORTIZATION_TYPE, function(restType, jsType) {
            if (dynVals.amortization === restType) {
              addData.liquidation = jsType;
              return false;
            }
            return true; //continue
          });
        }
        //uzytkownik do 25 roku zucia
        if (angular.isDefined(dynVals.userUnderAge)) {
          addData.isYoungDriver = dynVals.userUnderAge;
        }
        //leasing, współwłaściciele
        if (!angular.isObject(addData.coowners) || !angular.isArray(addData.coowners)) {
          addData.coowners = [];
        }
        angular.forEach(risk.get('insuredSubjectsRef'), function(insuredSubject) {
          if (insuredSubject.typeId === SPD.subjects[CONSTANTS.SUBJECT_LEASING]) {
            addData.isLeased = true;
            addData.leaserId = tmpDc._subjectIdMap[insuredSubject.ref];
          }
          var subjId = tmpDc._subjectIdMap[insuredSubject.ref];
          if (insuredSubject.typeId === SPD.subjects[CONSTANTS.SUBJECT_COOWNER] && addData.coowners.indexOf(subjId) === -1) {
            addData.coowners.push(subjId);
          }
        });
        //vehicleValueType - brutto/netto, tylko AC moze przeniesc te inforamcje
        if (riskDef.riskCode === CONSTANTS.RISK_AC) {
          var vehValueType = CONSTANTS.VALUE_TYPE_BRUTTO;
          if (risk.get('gross') === false) {
            vehValueType = CONFIG.BEHAVIOR.vehicle.extendedValueTypes
              ? (dynVals.issuminsuredgross_new || CONSTANTS.VALUE_TYPE_NETTO)
              : CONSTANTS.VALUE_TYPE_NETTO;
          }
          addData.vehicleValueType = vehValueType;
        }
        //specjalne przeznaczenie pojazdu
        if (CONFIG.BEHAVIOR.vehicle.vehicleSpecialPurpose) {
          if (angular.isUndefined(addData.specialPurposes) || !angular.isArray(addData.specialPurposes)) {
            addData.specialPurposes = [];
          }
          angular.forEach(RESOURCES.SPECIAL_PURPOSE_OPTIONS, function(purpose) {
            if (addData.specialPurposes.indexOf(purpose.CODE) === -1 && angular.isDefined(dynVals[purpose.CODE]) && dynVals[purpose.CODE]) {
              addData.specialPurposes.push(purpose.CODE);
            }
          });
        }
        //kierownica po prawej stronie
        if (CONFIG.BEHAVIOR.vehicle.rightHandDrive && angular.isDefined(dynVals.rightHandDrive)) {
          addData.rightHandDrive = dynVals.rightHandDrive;
        }
      };

      //#pragma mark policiesMapping
      /**
       * mapuje pojazdy z polis do wniosku ubezpieczeniowego
       * @param {Policy[]} policies polisy
       * @param {Application} application wniosek
       * @param {number=} nextVehicleClientId kolejny client id dla pojazdu
       * @return {object.<string,string>} obiekt mapowan id: clientId
       */
      this.mapPolicyVehiclesToApplication = function(policies, application, nextVehicleClientId) {
        var idToClientId = {};
        nextVehicleClientId = angular.isNumber(nextVehicleClientId) ? nextVehicleClientId : 1;
        lsnNg.forEach(policies, function(policy) {
          if (policy.vehicles === null) {
            return;
          }
          if (!angular.isArray(application.vehicles)) {
            application.set('vehicles', []);
          }
          angular.forEach(policy.vehicles, function(vehicle) {
            vehicle.metaData.set('clientId', nextVehicleClientId);
            application.vehicles.push(vehicle);
            idToClientId[vehicle.metaData.get('id')] = nextVehicleClientId;
            nextVehicleClientId += 1;
          });
        });
        return idToClientId;
      };

      /**
       * zwraca wartość dla additionalData.source pojazdu na podstawie wartości atrybutu z dynamicValues wniosku/polisy
       * @param {Boolean} attrValue
       * @return {String}
       */
      this._getVehicleSourceByDynVal = function(attrValue) {
        return (attrValue === false) ? 'nonEtx' : 'etx';
      };

      /**
       * map given value to one of RESOURCES.VEHICLE_ORIGINAL_KEY values
       * @param {number|null} val
       * @private
       */
      this._mapKeyCount = function(val) {
        if (val !== null) {
          if (val >= 3) {
            return 3;
          }
          if (val === 0) {
            return null;
          }
          return val;
        }
        return null;
      };
    };

    return new FromRestVehicleMapper();
  }
]);
