angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestAbstractDictionariesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var RestAbstractDictionariesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.businessArea = 'dictionaries';
        this.port = '8204';
        this.path = 'RestDictionaries';

      };
      return RestAbstractDictionariesSvc;
    }
  ]);