angular.module('ihestiaRestServicesBase')

.constant('IHESTIA_AMOUNT_CURRENCY_PLN', 'PLN')
.constant('IHESTIA_AMOUNT_CURRENCY_EUR', 'EUR')
.factory('IHESTIA_DICTIONARY_AMOUNT_CURRENCY',[ 'IHESTIA_AMOUNT_CURRENCY_PLN', 'IHESTIA_AMOUNT_CURRENCY_EUR', function(IHESTIA_AMOUNT_CURRENCY_PLN, IHESTIA_AMOUNT_CURRENCY_EUR) {
    var IHESTIA_DICTIONARY_AMOUNT_CURRENCY = {};
    IHESTIA_DICTIONARY_AMOUNT_CURRENCY[IHESTIA_AMOUNT_CURRENCY_PLN] = 'zł';
    IHESTIA_DICTIONARY_AMOUNT_CURRENCY[IHESTIA_AMOUNT_CURRENCY_EUR] = 'euro';
    return IHESTIA_DICTIONARY_AMOUNT_CURRENCY;
  }])
;