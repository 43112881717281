angular.module('salesPath2')
  .factory('vehicleNonEtxModalHelper', ['RESOURCES', 'dictionaryServiceHelper',
    function(RESOURCES, dictionaryServiceHelper) {
      var VehicleNonEtxModalHelper = function() {
        var self = this;


        /**
         * Mapa kodów obiektów na słowniki
         * @type {Object}
         */
        var SELECT_CODE_TO_DICT_MAP = {
          category: 'registrationTypes',
          bodyType: 'bodyTypes',
          fuel: 'fuelTypes',
          gearboxType: 'gearTypes',
          power: 'powerUnits',
          mark: 'brands',
          model: 'models',
          type: 'types'
        };

        this.staticCodes = ['bodyType', 'fuel', 'gearboxType'];
        this.dependencySelectArray = ['category', 'bodyType', 'fuel', 'gearboxType'];

        /**
         * Mapa kodów obiektów na serwisy
         * @type {Object}
         */
        var SELECT_CODE_TO_SVC_MAP = {
          category: dictionaryServiceHelper.getVehicleCategoryService(),
          mark: dictionaryServiceHelper.getVehicleBrandsService(),
          model: dictionaryServiceHelper.getVehicleModelsService(),
          type: dictionaryServiceHelper.getVehicleTypesService(),
          bodyType: dictionaryServiceHelper.getVehicleBodyTypesService(),
          fuel: dictionaryServiceHelper.getVehicleFuelTypesService(),
          gearboxType: dictionaryServiceHelper.getVehicleGearsTypesService(),
          power: dictionaryServiceHelper.getVehiclePowerUnitsService()
        };

        /**
         * Domyślne dane modala edycji
         * @type {Object}
         */
        var DEFAULT_EDIT_MODAL_DATA = {
          dicts: {
            registrationTypes: [],
            brands: [],
            models: [],
            types: [],
            bodyTypes: [],
            fuelTypes: [],
            gearTypes: [],
            powerUnits: []
          },
          etxProperties: self.dependencySelectArray, //wlssności pochodzące ze slownikow etx
          formData: {
            dependencySelects: {
              category: null,
              bodyType: null,
              fuel: null,
              gearboxType: null,
              mark: null,
              model: null,
              type: null
            },
            simpleData: {

            },
            additionalData: {

            },
            tmp: {
              power: {},
              isCar: true //czy pojazd jest samochodem
            }
          },
          dataCleared: true,
          vehicle: null,
          clientId: null,
          replaceEtxVehicle: null,
          errors: {},
          allowedChanges: {}
        };

        this.modalData = angular.copy(DEFAULT_EDIT_MODAL_DATA);

        /**
         * Czyścimy dane modala
         * @return {NonEurotaxHelper} [description]
         */
        this.clearmodalData = function() {
          this.modalData = angular.copy(DEFAULT_EDIT_MODAL_DATA);
          return self;
        };

        /**
         * Odpalamy po zmianie kodu w selektach, w których istotna jest kolejność
         * @param  {string} changedSelectCode [description]
         * @return {NonEurotaxHelper}                   [description]
         */
        this.dependencySelectorsChanged = function(changedSelectCode, blockClearDown) {
          // czyścimy selekty "w dół" od ostatniej zmiany
          var clearDown = false;
          var getNextDict = false;
          if (changedSelectCode === 'category') {
            self.setIsCar(self.modalData.formData.dependencySelects.category);
            //czyszczenie marki, modelu i typu
            self.modalData.formData.dependencySelects.mark = null;
            self.modalData.formData.dependencySelects.model = null;
            self.modalData.formData.dependencySelects.type = null;
          }

          self.clearNextDependencyModel(changedSelectCode);
          angular.forEach(self.modalData.formData.dependencySelects, function(value, selectCode) {
            // czyszczenie wybranych wartości i słowników
            if (changedSelectCode !== 'type') {
              if (clearDown) {
                self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]] = [];
              }
              // pobranie "następnego" w zależnościach słownika
              if (changedSelectCode !== 'gearboxType') {
                if (getNextDict) {
                  self.fillDictionaryForCode(selectCode, blockClearDown);
                  getNextDict = false;
                }
                // określenie w którym miejscu zależności jesteśmy
                if (changedSelectCode === selectCode) {
                  clearDown = !blockClearDown;
                  getNextDict = true;
                }
              }
            }
          });

          return self;
        };

        /**
         * Pobranie danych słownikowych dla pól typ nadwozia, rodzaj paliwa, rodzaj skrzyni za jednym zamachem
         */
        this.fillLastThreeSelectors = function() {
          angular.forEach(self.staticCodes, function(code) {
            self.fillDictionaryForCode(code, true);
          });
        };

        this.clearNextDependencyModel = function(code) {
          var i = 0;
          if(code !== 'type' && code !== 'fuel') {  
            angular.forEach(self.modalData.formData.dependencySelects, function(value, selectCode) {
              if(selectCode === code) {
                var nextCode = self.dependencySelectArray[i + 1];
                self.modalData.formData.dependencySelects[nextCode] = null;
              }
              i++;
            });
          }
        };

        /**
         * Wypełniamy zadany słownik
         * @param  {string} selectCode [description]
         * @param {boolean} keepDicts jesli true to nie nadpisujemy słowników
         * @return {promise}            [description]
         */
        this.fillDictionaryForCode = function(selectCode, keepDicts) { //eslint-disable-line consistent-return
          
          var svc = SELECT_CODE_TO_SVC_MAP[selectCode];
          if (selectCode !== 'model' && selectCode !== 'mark' && selectCode !== 'type' && selectCode !== 'undefined') {
            if (keepDicts && self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]].length > 0) {
              return true;
            }

            if (selectCode === 'category') {
              return svc.findByParams({
                query: {
                  classification: 'EUROTAX',
                  value: ''
                }
              }).then(function(res) {
                angular.forEach(res.data.items, function(item) {
                  self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]].push({
                    name: item[0],
                    code: item[1],
                    itemObj: item
                  });
                });
                }, angular.noop);
            } else if (selectCode === 'power') {
              // ugly if dla usług
              return svc.get().then(function(res) {
                angular.forEach(res.data, function(item) {
                  self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]].push({
                    name: item[0],
                    code: item[1],
                    itemObj: item
                  });
                });
                // zawsze jakaś moc wybrana
                if (selectCode === 'power' && (!self.modalData.formData.simpleData.power || !self.modalData.formData.simpleData.power[1])) {
                  self.modalData.formData.tmp.power = self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]][0].code;
                }
              }, angular.noop);
            } else {
              var query = self.getQueryForCode(selectCode);
              if (query && selectCode !== 'undefined') {
                return svc.findByParams({
                  query: query
                }).then(function(res) {
                  var tmpDict = [];
                    angular.forEach(res.data.items, function(item) {
                      var itemSec = [item[0], item[1], 'EUROTAX'];
                      tmpDict.push({
                        name: item[0],
                        code: item[1],
                        itemObj: itemSec
                      });
                    });
                    //poprawka "po fakcie" problemu z podwójnym ładowaniem słowników (np. bodyType). Po refaktoryzacji sekcji "spoza ETX", neleży unikać takich poprawek!!
                    if (!angular.equals(tmpDict, self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]])) {
                      self.modalData.dicts[SELECT_CODE_TO_DICT_MAP[selectCode]] = tmpDict;
                    }
                    
                }, angular.noop);
              } else {
                return false;
              }
            }
          }
        };

        /**
         * Zwracamy query dla wyszukiwania w słownikach
         * @param  {string} selectCode kod zmienionego selecta
         * @return {object}            query
         */
        this.getQueryForCode = function(selectCode) {
          var query = {},
            dependencySelectsData = self.modalData.formData.dependencySelects;
          switch (selectCode) {
            case 'mark':
              if (!dependencySelectsData.category) {
                query = false;
              } else {
                query.registrationTypeCode = dependencySelectsData.category[1];
              }
              break;
            case 'model':
              if (!dependencySelectsData.category || !dependencySelectsData.mark) {
                query = false;
              } else {
                query.registrationTypeCode = dependencySelectsData.category[1];
                query.markCode = dependencySelectsData.mark[1];
              }
              break;
            case 'type':
              if (!dependencySelectsData.model) {
                query = false;
              } else {
                query.modelCode = dependencySelectsData.model[1];
              }
              break;
            case 'bodyType':
              if (!dependencySelectsData.category) {
                query = false;
              } else {
                query.typeCode = null; //IHESTIAMF-2178
                query.registrationTypeCode = dependencySelectsData.category[1];
              }
              break;
            default:
              if (!dependencySelectsData.category) {
                query = false;
              } else {
                query.typeCode = '0';
                query.registrationTypeCode = dependencySelectsData.category[1];
              }
              break;
          }

          return query;
        };

        //ustawia zmienna mowiaca o tym czy pojazd jest samochodem
        this.setIsCar = function(category) {
          var val = true;
          if (angular.isArray(category) && angular.isDefined(category[1]) && RESOURCES.NO_CAR_CATEGORIES.indexOf(category[1]) !== -1) {
            val = false;
            self.clearCarFormData();
          }
          self.modalData.formData.tmp.isCar = val;
        };

        //czysci dane formularza dotyczace wylacznie samochodu
        this.clearCarFormData = function() {
          var carSimpleData = ['capacity', 'power', 'mileage'],
            carDependencySelects = ['fuel', 'gearboxType'];
          self.modalData.mileageOption = undefined;
          angular.forEach(carSimpleData, function(prop) {
            self.modalData.formData.simpleData[prop] = null;
          });
          angular.forEach(carDependencySelects, function(prop) {
            self.modalData.formData.dependencySelects[prop] = null;
          });
        };

      };
      return new VehicleNonEtxModalHelper();
    }
  ]);