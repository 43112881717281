angular.module('lsnBase.models')
  .factory('LsnBonusMalusDataObjectModelV1', [
    function()
    {

      var LsnBonusMalusDataObjectModelV1 = function()
      {
        this.data = {
          useGivenData: null, // true/false
          newModeData: null, // XPegaz.BonusMalus.New
          continuationModeData: null // //XPegaz.BonusMalus.Continuation
        };
        this.personRef = null; // id osoby
        this.riskName = null; // OC/AC
        this.vehicleRef = null; // id pojazdu
        this.currentClass = null; // klasa ryzyka - nie używane po stronie GUI ale mamy odsyłać w usłudze
      };

      return LsnBonusMalusDataObjectModelV1;
    }
  ]);