angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardSubjectModelConstants', [function() {
    return {
      TYPE_POOL: 'POOL',
      TYPE_SYSTEM: 'SYSTEM',
      TYPE_POOL_WORKER: 'POOL_WORKER',
      TYPE_SALE_PROFILE: 'SALE_PROFILE',
      TYPE_WORKER_POK: 'WORKERPOK', //?obsolete?
      TYPE_POK: 'POK',
      TYPE_WHITE_LIST: 'WHITE_LIST',
      TYPE_WORKER: 'WORKER'
    };
  }]);