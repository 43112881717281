angular.module('ihestiaCommonDirectives.uploaderV2')
  .service('lsnCommonUploadV2Helper', ['fileServerSvc', '$filter', '$alert', 'compressorJsConfigHelper',
    function(fileServerSvc, $filter, $alert, compressorJsConfigHelper) {

      var LsnCommonUploadV2Helper = function() {
        var self = this;

        /**
         * List of events provided by external plugin
         *
         * https://github.com/DominicBoettger/jQuery-File-Upload
         * @type {string[]}
         */
        this.uploaderEvents = [
          'fileuploadadd',
          'fileuploadsubmit',
          'fileuploadsend',
          'fileuploaddone',
          'fileuploadfail',
          'fileuploadalways',
          'fileuploadprogress',
          'fileuploadprogressall',
          'fileuploadstart',
          'fileuploadstop',
          'fileuploadchange',
          'fileuploadpaste',
          'fileuploaddrop',
          'fileuploaddragover',
          'fileuploadchunkbeforesend',
          'fileuploadchunksend',
          'fileuploadchunkdone',
          'fileuploadchunkfail',
          'fileuploadchunkalways',
          'fileuploadprocessstart',
          'fileuploadprocess',
          'fileuploadprocessdone',
          'fileuploadprocessfail',
          'fileuploadprocessalways',
          'fileuploadprocessstop'
        ];

        /**
         *
         * @type {{}}
         */
        this.defaultDrvOptions = {
          input: {
            visible: true,
            disabled: false
          },
          multiple: false,
          hideQueueList: false,
          attachmentType: 'static',
          useProductFilesSvc: false, // whether to use productFilesSvc instead of filesSvc
          productFilesParams: { // used when useProductFilesSvc === true
            productId: '' // unique application id
          },
          callback: {
            frpFileUploadDone: lsnNg.noop
          },
          frp: {
            autoSend: false,
            attributes: {}
          },
          ngUploadFormOptions: {
            url: fileServerSvc._getServiceUrl(),
            disabled: false,
            autoUpload: false,
            singleFileUploads: true,
            // acceptFileTypes: /(\.|\/)(jpg|jpeg|gif|png|tif|tiff|eml|msg|pdf|rtf|odt|ods|doc|docs|xls|xlsx)$/i,
            maxChunkSize: 4000000,
            maxFileSize: 2000000,
            hideProgressBar: false,
            messages: {
              acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
              maxFileSize: $filter('translate')('Public.toLargeFile')
            },
            name: null,
            compressorjs: compressorJsConfigHelper.compressorjs,
            compressorjsConfig: compressorJsConfigHelper.compressorjsConfig
          },
          templates: {
            attachmentElement: 'ihestia-commondirectives-template-app/directives/uploaderV2/templates/attachmentElement.tpl.html'
          },
          showMessage: function(msg) {
            $alert({
              content: msg,
              type: 'warning'
            });
          }
        };

        /**
         * Merges options given to directive with defaults
         *
         * @param sourceOptions
         */
        this.mergeOptions = function(sourceOptions) {
          // copying defaults, then adding user options and finally merging data,
          // so the sourceOptions is ok and we didn't loose reference
          var defaults = _.cloneDeep(self.defaultDrvOptions);
          _.merge(defaults, sourceOptions);
          _.merge(sourceOptions, defaults);
          if (!sourceOptions.ngUploadFormOptions.name) {
            sourceOptions.ngUploadFormOptions.name = Math.random() + '_' + (new Date).getTime();
          }
        };

      };

      return new LsnCommonUploadV2Helper();
    }]);
