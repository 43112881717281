//przy opisie pamietac by opisac ze wymagane jest person bezposrednio w scope
angular.module('salesPath.outer')
  .directive('ihestiaLastName', ['ihestiaAvailabilityHelper', 'ihestiaRestDictionaryLastNamesSvc', 'ihestiaTryEscapeHtml', '$parse', '$q',
    function(ihestiaAvailabilityHelper, ihestiaRestDictionaryLastNamesSvc, ihestiaTryEscapeHtml, $parse, $q) {
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else if (ihestiaAvailabilityHelper.isAvailable('lastNameSearch')) {
            return 'ihestiaSalesPathTemplate/autocompliters/lastName/lastName.tpl.html';
          } else {
            return 'ihestiaSalesPathTemplate/autocompliters/lastName/lastNameWithoutDict.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $scope.promiseToCancel = $q.defer();

          $scope.onBlur = null;
          $scope.autocompleteMax = null;

          var input = $($element).find('input');

          if ($attrs.svcValidatorName) {
            input.attr('svc-validator-name', $attrs.svcValidatorName);
          }

          if ($attrs.valueAttribute) {
            $scope.valueAttribute = $attrs.valueAttribute;
          } else {
            $scope.valueAttribute = 'lastName';
          }

          if ($attrs.autocompleteMax) //ile maksymalnie wyników w autocompleterze
          {
            $scope.autocompleteMax = parseInt($attrs.autocompleteMax, 10);
          }

          $scope.$watch($attrs.errors, function(val) {

            if (!val || !val[$scope.valueAttribute]) {
              $scope.hasError = false;
            } else {
              $scope.hasError = !!val[$scope.valueAttribute];
              $scope.invalidText = val[$scope.valueAttribute].message;
            }

          });

          if ($attrs.invalidText) {
            $attrs.$observe('invalidText', function(invalidText) {
              $scope.invalidText = invalidText;
            });
            $scope.invalidText = $attrs.invalidText;
          }

          if ($attrs.invalidTextLabel) {
            $attrs.$observe('invalidTextLabel', function(invalidTextLabel) {
              $scope.invalidTextLabel = $parse($attrs.invalidTextLabel)($scope);
            });
            $scope.invalidTextLabel = $parse($attrs.invalidTextLabel)($scope);
          }

          if (angular.isDefined($attrs.formObject)) {
            $scope.formObject = $parse($attrs.formObject)($scope);
          }

          if (angular.isDefined($attrs.onBlur)) {
            $scope.onBlur = $parse($attrs.onBlur)($scope);
          }

          $scope.$watch($attrs.person, function(val) {
            if (!val[$scope.valueAttribute]) {
              val[$scope.valueAttribute] = '';
            }

            $scope.person = val;
          });

          if ($attrs.ngDisabled) {
            $scope.ngDisabled = $attrs.ngDisabled;
          } else {
            $scope.ngDisabled = false;
          }
          if (angular.isDefined($attrs.required)) {
            $scope.required = true;
          } else {
            $scope.required = false;
          }
          if (angular.isDefined($attrs.ngRequired)) {
            $scope.requiredFlag = $attrs.ngRequired;
          } else {
            $scope.requiredFlag = 'required';
          }

          if (angular.isDefined($attrs.placeholder)) {
            $scope.placeholder = $attrs.placeholder;
          }

          if (angular.isDefined($attrs.placeholderLabel)) {
            $scope.placeholderLabel = $parse($attrs.placeholderLabel)($scope);
          }

          if ($attrs.typeaheadMinLength) {
            $scope.typeaheadMinLength = $attrs.typeaheadMinLength;
          } else {
            $scope.typeaheadMinLength = 1;
          }

          if (angular.isDefined($attrs.useRegex)) //blokujemy wpisywanie wszystkiego poza literami spacją i '-'
          {
            $scope.regex = '\'^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -]*$\'';
          } else {
            $scope.regex = '';
          }

          // todo handle custom maxlength!!
          if (angular.isDefined($attrs.maxLength)) {
            $scope.maxLength = $parse($attrs.maxLength)($scope);
          } else {
            $scope.maxLength = 80;
          }

          $scope.blurOccured = function() {
            if ($scope.onBlur) {
              $scope.onBlur();
            }
          };

          if (ihestiaAvailabilityHelper.isAvailable('lastNameSearch')) {

            $scope.getLastNames = function(query) {
              if ($scope.loadingElements) {
                $scope.promiseToCancel.reject('newRequest');
                $scope.promiseToCancel = $q.defer();
              }
              $scope.loadingElements = true;
              var searchParams = {
                query: query,
                top: 10,
                pageSize: 10,
                httpParams: {
                  timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
                }
              };
              if ($scope.autocompleteMax) {
                searchParams.count = $scope.autocompleteMax;
              }

              return ihestiaRestDictionaryLastNamesSvc.findByParams(searchParams).then(function(res) {
                $scope.loadingElements = false;
                var data;
                if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                  data = {
                    items: []
                  };
                } else {
                  data = res.data;
                }
                var names = [];
                angular.forEach(data.items, function(item) {
                  names.push(ihestiaTryEscapeHtml(item.name));
                });
                return names;
              }, angular.noop);
            };
          }
        }]
      };
    }
  ]);
