angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaProgramSvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaProgramSvc = function() {
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'program';
      };

      return new PzaProgramSvc();
    }]);