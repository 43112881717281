angular.module('ihestiaMenuTopMenu')
  .controller('ihestiaMenuAgentReportsCtrl', ['$scope', 'ihestiaAgHelper', 'ihestiaIcContextHelper',
    function($scope, ihestiaAgHelper, ihestiaIcContextHelper){

      $scope.permissions = {
        policies: ihestiaAgHelper.hasPermissionFor('Public', 'PolicyReportView'),
        offers: ihestiaAgHelper.hasPermissionFor('Public', 'OfferReportView'),
        patrons: ihestiaAgHelper.hasPermissionFor('Public', 'ClientAttendantReportView') && !ihestiaIcContextHelper.isLifeActiveContext(),
        paymentReport: ihestiaAgHelper.hasPermissionFor('Payments', 'PaymentsView')
      };

      $scope.init = function() {
      };

    }
  ]);