angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaBeneficiaryDataHelper', ['ihestiaBeneficiaryFromRestMapper',
    function(ihestiaBeneficiaryFromRestMapper) {
      var BeneficiaryDataHelper = function() {
        var self = this;

        this.beneficiaries = [];

        this.subjects = [];

        this.editableBeneficiaries = [];

        this.editableSubjects = [];

        this.dicts = {
          kinshipDict: [],
          countriesDict: []
        };

        this.FIRST_SUBJECT_ID = -1;

        this.nextSubjectId = null;

        this.isLongTermPolicy = false;

        this.initData = function(policy, subjects, nonEditableSubjectIds) {
          self.beneficiaries = [];
          self.subjects = [];
          self.editableBeneficiaries = [];
          self.editableSubjects = [];
          self.isLongTermPolicy = _.get(policy, 'symbol') === 'Life5BC';
          if (self._beneficiariesPresent(policy)) {
            self.beneficiaries = ihestiaBeneficiaryFromRestMapper.mapBeneficiariesFromRisks(policy.risks, subjects, nonEditableSubjectIds);
            // keeping reference only to beneficiary subjects
            self.subjects = _.filter(subjects, function(subject) {
              return self.isSubjectBeneficiary(self.beneficiaries, subject);
            });
            self.editableSubjects = self._mapEditableBeneficiaries(self.subjects, self._getCessionaryId(self.beneficiaries)); // angular.copy(self.subjects);
            self.editableBeneficiaries = angular.copy(self.beneficiaries);
            self.nextSubjectId = self._getAvailableClientId(_.concat([], policy.persons || [], policy.organizations || []));
          } else {
            self.beneficiaries = [];
            self.subjects = [];
            self.nextSubjectId = self.FIRST_SUBJECT_ID;
          }
        };

        /**
         *
         * @param {number} subjectClientId
         * @return {RiskBeneficiaryModel}
         */
        this.getBeneficiaryByClientId = function(subjectClientId) {
          return angular.copy(_.find(self.editableBeneficiaries, ['subjectClientId', subjectClientId]));
        };

        this.getSavedBeneficiariesByType = function(type) {
          return _.filter(self.beneficiaries, ['type', type]);
        };

        this.getEditableBeneficiariesByType = function(type) {
          return _.filter(self.editableBeneficiaries, ['type', type]);
        };

        this.updateEditableBeneficiaryByClientId = function(updatedBeneficiary, updatedSubject) {
          var oldBeneficiaryIndex = _.findIndex(self.editableBeneficiaries, ['subjectClientId', updatedBeneficiary.subjectClientId]);
          var oldSubjectIndex = _.findIndex(self.editableSubjects, ['metaData.id', updatedBeneficiary.subjectClientId]);

          if (!updatedSubject.metaData.id) {
            _.set(updatedSubject, 'metaData.id', updatedBeneficiary.subjectClientId);
          }
          updatedBeneficiary.subjectName = self._getSubjectName(updatedSubject);
          updatedBeneficiary.subjectDetails = updatedSubject.birthDate || updatedSubject.regon;
          self.editableBeneficiaries[oldBeneficiaryIndex] = updatedBeneficiary;
          self.editableSubjects[oldSubjectIndex] = updatedSubject;
        };

        this.updateSavedBeneficiaries = function() {
          self.beneficiaries = angular.copy(self.editableBeneficiaries);
          self.subjects = angular.copy(self.editableSubjects);
        };

        this.getSubjectByClientId = function(subjectClientId) {
          return angular.copy(_.find(self.editableSubjects, ['metaData.id', subjectClientId]));
        };

        this._beneficiariesPresent = function(policy) {
          return _.some(policy.risks, function(risk) {
            return !_.isEmpty(risk.beneficiaries);
          });
        };

        this.isCessionarySelected = function() {
          return _.some(self.editableBeneficiaries, ['cessionary', true]);
        };

        this.resetBeneficiaries = function() {
          self.editableBeneficiaries = angular.copy(self.beneficiaries);
        };

        this.resetSubjects = function() {
          self.editableSubjects = angular.copy(self.subjects);
        };

        this.addNewBeneficiary = function(beneficiary, subject) {
          beneficiary.subjectName = self._getSubjectName(subject);
          beneficiary.subjectDetails = subject.birthDate || subject.regon;
          var newSubjectId = self.getNextClientId();
          beneficiary.subjectClientId = newSubjectId;
          _.set(subject, 'metaData.id', newSubjectId);
          self.editableBeneficiaries.push(beneficiary);
          self.editableSubjects.push(subject);
        };

        this.getNextClientId = function() {
          var id = self.nextSubjectId;
          self.nextSubjectId -= 1;
          return id + '';
        };

        this._getSubjectName = function(subject, cessionary, version) {
          if (version === 'main') {
            var name = null;
            if (subject.metaData.className === 'Person') {
              name = '{0} {1}'.format(subject.firstName, subject.lastName);
            } else if (subject.metaData.className === 'Organization') {
              name = subject.name;
            }
            return cessionary ? '{0} - {1}'.format(name, 'Cesjonariusz') : name;
          } else {
            if (subject.metaData.className === 'Person') {
              return '{0} {1}'.format(subject.firstName, subject.lastName);
            } else if (subject.metaData.className === 'Organization') {
              return subject.name;
            } else {
              return '';
            }
          }
        };

        this.deleteBeneficiary = function(subjectClientId) {
          self.editableBeneficiaries = _.filter(self.editableBeneficiaries, function(beneficiaries) {
            return beneficiaries.subjectClientId !== subjectClientId;
          });
          self.editableSubjects = _.filter(self.editableSubjects, function(subject) {
            return subject.metaData.id !== subjectClientId;
          });
        };

        this.isBeneficiaryListEmpty = function() {
          return _.isEmpty(self.editableBeneficiaries);
        };

        this.isSubjectBeneficiary = function(beneficiaries, subject) {
          return _.some(beneficiaries, function(beneficiary) {
            return beneficiary.subjectClientId === _.get(subject, 'metaData.id');
          });
        };

        /**
         * Iterates over beneficiaries to determine the lowest available id for assignment to new beneficiaries
         * @param {object[]} subjects
         * @returns {number}
         * @private
         */
        this._getAvailableClientId = function(subjects) {
          var lowestTakenId = _.chain(subjects)
            .map('metaData.id')
            .filter(function(id) { // filtering ids that match pattern of ids generated in sales path eg. ('-2' | '-3')
              return /^-\d+$/.test(id);
            })
            .map(function(id) { // parses strings to numbers
              return parseInt(id, 10);
            })
            .min() // takes minimum value
            .value();
          return lowestTakenId - 1 || self.FIRST_SUBJECT_ID;
        };

        /**
         *
         * @param {object[]} subjects
         * @param {string} cessionaryId - id of subject set as cessionary, only cessionary can have addresses defined
         * @returns {object[]}
         * @private
         */
        this._mapEditableBeneficiaries = function(subjects, cessionaryId) {
          return _.map(subjects, function(subject) {
            if (!cessionaryId || subject.metaData.id !== cessionaryId) {
              subject.addresses = null;
            }
            return angular.copy(subject);
          });
        };

        this._getCessionaryId = function(beneficiaries) {
          return _.get(_.find(beneficiaries, ['cessionary', true]), 'subjectClientId', null);
        };

      };
      return new BeneficiaryDataHelper();
    }
  ]);
