angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryDecisionMakersSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryDecisionMakersSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        var self = this;

        this.resource = 'decisionMakers';
        this.sortBy = 'name';

        /**
         * pelnotekstowe wyszukiwanie osob
         *
         * @param {mixed} query
         * @param {*} callback
         * @param {Integer} limit
         * @param {Integer} offset
         * @param {String} sortBy
         * @param {String} sortDicrection
         * @param {mixed} field
         */
        this.find = function(query, callback, consentType, limit, offset, sortBy, sortDirection, field) {
          var settings = $.extend({
              pageSize: self.pageSize,
              top: 0,
              sortBy: self.sortBy,
              sortDirection: 'ASC',
              field: 'value'
            }, {
              pageSize: limit,
              top: offset,
              sortBy: sortBy,
              sortDirection: sortDirection,
              field: field
            }),
            queryObject = {
              pageSize: settings.pageSize,
              sortBy: settings.sortBy,
              sortDirection: settings.sortDirection,
              top: settings.top,
              parameter: {}
            };
          if (typeof query === 'object') {
            $.each(query, function(fieldName, queryValue) {
              queryObject.parameter[fieldName] = queryValue;
            });
          } else {
            queryObject.parameter[settings.field] = query;
          }

          queryObject.parameter.consentType = consentType;
          queryObject.parameter.ouSymbol = '';
          queryObject.parameter.productSegment = 'C';
          queryObject.parameter.productSymbol = '';

          return self.post(null, JSON.stringify(queryObject), 'searches', callback);
        };

      };
      return new RestDictionaryDecisionMakersSvc();
    }
  ]);