angular.module('salesPath2')
  .factory('CommunicationPersonsHelperClass', ['dataContainerHelper', 'CONSTANTS', 'mainDataContainer', 'coownerModalHelper', 'vehicleHelper', 'coownerHelper', 'sp2CommonHelper', 'personModalHelper', '$timeout', 'bonusMalusHelper', 'appVariables', 'supplementHelper',
    function(dataContainerHelper, CONSTANTS, mainDataContainer, coownerModalHelper, vehicleHelper, coownerHelper, sp2CommonHelper, personModalHelper, $timeout, bonusMalusHelper, appVariables, supplementHelper
    ) {
      var CommunicationPersonsHelperClass = function() {
        var self = this;

        /**
         * dane dla modala ubezpieczonych z komunikacji
         * @type {Object}
         */
        this.tplData = {
          showElements: {
            emptyBmInfo: false
          },
          allDataUfgFilled: false,
          vehicle: null, // dane pojazdu
          persons: [], // osoby
          coowners: null,
          readonlyBMData: false,
          readonly: false
        };

        /**
         * init danych
         * @return {undefined}
         */
        this.initData = function() {
          self.tplData.vehicle = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle);
          self.tplData.coowners = self.tplData.vehicle.getAdditionalData('coowners');
          self.tplData.persons = self.loadPersons();
          var previouslySelectedRisk = false;

          if (appVariables.isSupplement) {
            previouslySelectedRisk = supplementHelper.isRiskPreviouslySelected(CONSTANTS.RISK_OC, CONSTANTS.RISK_OC, mainDataContainer.selectedVehicle) || supplementHelper.isRiskPreviouslySelected(CONSTANTS.RISK_AC, CONSTANTS.RISK_AC, mainDataContainer.selectedVehicle);
          }
          self.tplData.readonly = appVariables.readOnly && !appVariables.isOcBuyer;
          self.tplData.readonlyBMData = self.tplData.readonly || previouslySelectedRisk;

          //przez to ze mamy tutaj do czynienia z klasą to musimy odświeżyć dane BM manualnie (klasa jest za każdym razem instancjonowana przy otwarciu modala)
          if (self.tplData.persons.length > 0) {
            self._refreshBMData();
          }
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'tarifficationEnded':
              // self._refreshBMData();
              break;
            default:
          }
        };


        /**
         * załaduj ubezpieczonych
         */
        this.loadPersons = function() {
          var persons = [];
          var mainInsured = dataContainerHelper.getMainInsured();
          var coowners = [];
          //pierw do osób wrzucamy głównego ubezpieczonego
          persons.push(mainInsured);


          //następnie dorzucamy współwłascicieli

          coowners = coownerHelper.getPersonsInfo(self.tplData.coowners);
          angular.forEach(coowners, function(coowner) {
            persons.push(coowner.data);
          });

          return persons;
        };

        /**
         * pokaż popup bonus malus
         */
        this.showBonusMalusPopup = function(personId, openCoownerList) {
          sp2CommonHelper.hideModal('communicationPersonsModal');
          coownerModalHelper.showBonusMalusPopup(personId, openCoownerList);
        };

        /**
         * usunięcie osoby
         */
        this.removePerson = function(personId) {
          sp2CommonHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            size: 'sm',
            title: 'sp2.modal.info.title',
            text: 'sp2.modal.removeCoownerConfirmInfo',
            okBtnCallback: function() {
              coownerHelper.removeCoowner(personId, CONSTANTS.PRODUCT_TYPE_VEHICLE);
            }
          });

          sp2CommonHelper.hideModal('communicationPersonsModal');
        };

        /**
         * edycja osoby
         */
        this.editPerson = function(personId) {
          sp2CommonHelper.hideModal('communicationPersonsModal');
          $timeout(function() {
            personModalHelper.openPopupEditPerson({
              id: personId,
              editHeader: 'Współwłaściciel',
              context: CONSTANTS.PERSON_CONTEXT_COOWNER
            });
          }, 0);
        };

        /**
         * odświeża dane BM dla każdej osoby
         */
        this._refreshBMData = function() {
          angular.forEach(self.tplData.persons, function(person) {
            self.refreshBMData(person.metaData.clientId);
          });
        };

        /**
         * odswieza dane BM
         * @param  {[type]} personId [description]
         * @return {boolean} true gdy odswiezono
         */
        this.refreshBMData = function(personId) { //eslint-disable-line consistent-return
          var bonusMalusData = {};
          bonusMalusData.risks = mainDataContainer.bonusMalus[personId][mainDataContainer.selectedVehicle].risks;
          bonusMalusData.availableRisks = bonusMalusHelper.getAvailableRisks();

          var riskBonusMalus = self.prepareDataBonusMalusToShow(bonusMalusData.risks, bonusMalusData.availableRisks);

          self.tplData.showElements.emptyBmInfo = false;
          if (!riskBonusMalus.areDataNewRisks && !riskBonusMalus.areDataContinuationRisks) {
            self.tplData.showElements.emptyBmInfo = true;
            self.tplData.allDataUfgFilled = false;
            //wszystkie dane potrzebne do taryfikacji wypełnione powinna odbywać się taryfikacja
            if (bonusMalusHelper.dataExpected(personId, mainDataContainer.selectedVehicle)) {
              self.tplData.allDataUfgFilled = true;
            }
            return true;
          }
        };

        this.prepareDataBonusMalusToShow = function(risks, availableRisks) {
          var riskBonusMalus = {
            areDataNewRisks: false,
            areDataContinuationRisks: false
          };

          var areDataNewAC = risks.AC !== null && risks.AC.data.newModeData !== null && availableRisks.AC !== null &&
            (risks.AC.data.newModeData.history5YearsCount !== null || risks.AC.data.newModeData.last2YearsDamagesCount !== null ||
              risks.AC.data.newModeData.last5YearsDamagesCount !== null);
          var areDataNewOC = risks.OC !== null && risks.OC.data.newModeData !== null && availableRisks.OC !== null &&
            (risks.OC.data.newModeData.history5YearsCount !== null || risks.OC.data.newModeData.last2YearsDamagesCount !== null ||
              risks.OC.data.newModeData.last5YearsDamagesCount !== null);
          if (areDataNewAC || areDataNewOC) {
            riskBonusMalus = {
              areDataNewRisks: true,
              newRisksHeaders: [],
              newRisks: [{
                label: 'Ciągłość ubezpieczenia w latach',
                values: []
              }, {
                label: 'Szkody w ostatnich 2 latach',
                values: []
              }, {
                label: 'Szkody w ostatnich 5 latach',
                values: []
              }]
            };

            if (areDataNewOC) {
              self.preapareNewRisksToShow(riskBonusMalus, risks.OC.data.newModeData, 'OC');
            }
            if (areDataNewAC) {
              self.preapareNewRisksToShow(riskBonusMalus, risks.AC.data.newModeData, 'AC');
            }
          }

          var areDataContinuationAC = risks.AC !== null && risks.AC.data.continuationModeData !== null && availableRisks.AC !== null;
          var areDataContinuationOC = risks.OC !== null && risks.OC.data.continuationModeData !== null && availableRisks.OC !== null;
          if (areDataContinuationAC || areDataContinuationOC) {
            riskBonusMalus = {
              areDataContinuationRisks: true,
              continuationRisksHeaders: [],
              continuationRisks: [{
                label: 'Koniec ubezpieczenia',
                values: []
              }, {
                label: 'Dotychczasowa klasa BM',
                values: []
              }, {
                label: 'Szkody w ostatnim roku',
                values: []
              }, {
                label: 'Szkody chronione OUZ',
                values: []
              }, {
                label: 'Lata bezszkodowe w dniu zawarcia kontynuowanej polisy',
                values: []
              }, {
                label: 'Numer kontynuowanej polisy',
                values: []
              }]
            };

            if (areDataContinuationOC) {
              self.preapareContinuationRisksToShow(riskBonusMalus, risks.OC.data.continuationModeData, 'OC');
            }
            if (areDataContinuationAC) {
              self.preapareContinuationRisksToShow(riskBonusMalus, risks.AC.data.continuationModeData, 'AC');
            }
          }

          return riskBonusMalus;
        };

        this.preapareNewRisksToShow = function(riskBonusMalus, newRisks, header) {
          riskBonusMalus.newRisksHeaders.push(header);
          riskBonusMalus.newRisks[0].values.push(newRisks.history5YearsCount === null ? '' : newRisks.history5YearsCount);
          riskBonusMalus.newRisks[1].values.push(newRisks.last2YearsDamagesCount === null ? '' : newRisks.last2YearsDamagesCount);
          riskBonusMalus.newRisks[2].values.push(newRisks.last5YearsDamagesCount === null ? '' : newRisks.last5YearsDamagesCount);
        };

        this.preapareContinuationRisksToShow = function(riskBonusMalus, continuationRisks, header) {
          riskBonusMalus.continuationRisksHeaders.push(header);
          riskBonusMalus.continuationRisks[0].values.push(continuationRisks.policyEndDate === null ? '' : new XDate(continuationRisks.policyEndDate).toString('yyyy-MM-dd'));
          riskBonusMalus.continuationRisks[1].values.push(continuationRisks.bmClass === null ? '' : continuationRisks.bmClass);
          riskBonusMalus.continuationRisks[2].values.push(continuationRisks.last1YearDamagesCount === null ? '' : continuationRisks.last1YearDamagesCount);
          riskBonusMalus.continuationRisks[3].values.push(continuationRisks.last1YearOuzCoveredDamagesCount === null ? '' : continuationRisks.last1YearOuzCoveredDamagesCount);
          riskBonusMalus.continuationRisks[4].values.push(continuationRisks.noClaimContinuationYears === null ? '' : continuationRisks.noClaimContinuationYears);
          riskBonusMalus.continuationRisks[5].values.push(continuationRisks.policyNumber === null ? '' : continuationRisks.policyNumber);
        };

        self.initData();
      };

      return CommunicationPersonsHelperClass;
    }
  ]);