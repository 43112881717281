angular.module('ihestiaRestServicesBase')

  .factory('ihestiaAuthenticationMypasswordsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var AuthenticationMypasswords = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'mypasswords';
      this.businessArea = 'authentication';
    };

    return new AuthenticationMypasswords();
  }])
  ;