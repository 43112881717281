angular.module('lsnBase.models')
  .factory('LsnClausesModelV2', ['LsnAbstractModel', 'LsnClausesModelConstants',
    function(LsnAbstractModel, LsnClausesModelConstants) {

      var LsnClausesModelV2 = function() {
        this.objectName = 'Clauses';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          k1: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k2: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k3: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k4: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k6: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k7: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k8: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k8a: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k9: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k22: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k24: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k25: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k27: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k34: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k31b: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k32b: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k33b: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k31a: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k32a: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          },
          k33a: {
            type: 'string',
            dictionary: LsnClausesModelConstants.DICTIONARY_CLAUSE_VALUE
          }
        };

        this.k1 = null; // string, Wartość określa czy klient wyraził zgodę na przetwarzanie danych marketingowych
        this.k2 = null; // string, Wartość określa czy klient wyraził zgodę na kontakt drogą elektroniczna
        this.k3 = null;
        this.k4 = null;
        this.k6 = null;
        this.k7 = null;
        this.k8 = null;
        this.k8a = null;
        this.k9 = null;
        this.k22 = null;
        this.k24 = null;
        this.k25 = null;
        this.k27 = null;
        this.k34 = null;
        this.k31b = null;
        this.k32b = null;
        this.k33b = null;
        this.k31a = null;
        this.k32a = null;
        this.k33a = null;

      };

      LsnClausesModelV2.prototype = LsnAbstractModel.prototype;

      return LsnClausesModelV2;
    }
  ]);