angular.module('salesPath2')
  .controller('cessionModalCtrl', ['$scope', 'cessionModalHelper', '$rootScope', 'applicationDictionaryHelper', 'appVariables', 'OrganizationModel', 'addressHelper',
    function($scope, cessionModalHelper, $rootScope, applicationDictionaryHelper, appVariables, OrganizationModel, addressHelper) {
      
      $scope.modalData = cessionModalHelper.modalData;
      $scope.observers = [];
      $scope.showBankSearch = true;
      $scope.selectedBankName = '';
      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };
      $scope.readonly = $scope.appVariables.isSupplement || ($scope.appVariables.readOnly && !$scope.appVariables.isOcBuyer);
      
      /**
       * Init
       */
      $scope.init = function() {
        if ($scope.modalData.dataCleared) {
          $scope.loadData();
        }
        $scope.findBanksMethod = applicationDictionaryHelper.findBanks;
        $scope.fireListeners();
        if ($scope.readonly) {
          $scope.modals.cessionModal.settings.okBtnDisabled = false;
        }
      };

      /**
       * Ładujemy dane cesjonariusza, które edytujemy
       * @return {[type]} [description]
       */
      $scope.loadData = function() {
        cessionModalHelper.loadCountriesDict();

        if($scope.modalData.isBank === true) {
          $rootScope.modals.cessionModal.type = 'BANK';
        } else {
          $rootScope.modals.cessionModal.type = 'PERSON';
        }

        if($scope.modalData.bankData) {
          $scope.selectedBankName = $scope.modalData.bankData.name;
          $scope.showBankSearch = false;
        }
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // zapis danych
        $scope.observers.push($scope.$on('iHestiaCommonModalOkBtnClicked.cessionModal', function() {
          if(($scope.modalData.bankData || $scope.modalData.organizationData) && !$scope.readonly) {
            $scope.save();
          }
          cessionModalHelper.clearModalData();
        }));

        // anulowanie danych
        $scope.observers.push($scope.$on('iHestiaCommonModalCancelBtnClicked.cessionModal', function() {
          cessionModalHelper.clearModalData();
        }));

        // usuń cesjonariusza
        $scope.observers.push($scope.$on('iHestiaCommonModalThirdBtnClicked.cessionModal', function() {
          if(!appVariables.isSupplement) {
            cessionModalHelper.deleteProductClauses();
            cessionModalHelper.deleteOrganization();
            cessionModalHelper.clearModalData();
          }
        }));

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.observers.push($scope.$on('iHestiaCommonModalHideEvent.cessionModal', function() {
          $scope.deregisterObservers();
        }));
      };

      /**
       * Niszczymy watche itd.
       */
      $scope.deregisterObservers = function() {
        angular.forEach($scope.observers, function(observer) {
          observer();
        });
        $scope.observers = [];
      };

      /**
       * Zapis danych do obiektów
       * @return {[type]} [description]
       */
      $scope.save = function() {
        if($scope.modals.cessionModal.type === 'BANK') {
          cessionModalHelper.saveOrganization($scope.modalData.bankData);
          cessionModalHelper.saveExtras('CESSION_BANK', $scope.modalData.bankData.metaData.id);
        } else {
          cessionModalHelper.saveExtras('CESSION', $scope.modalData.organizationData);
        }
      };

      /*
       * Akcja po wyborze banku z listy.
       */
      $scope.onSelect = function($item) {
        $scope.showBankSearch = false;
        $scope.selectedBankName = $item.name;
        $scope.modalData.bankData = $item;
        $scope.modals.cessionModal.settings.okBtnDisabled = false;
      };

      /*
       * Usunięcie wybranego banku
       */
      $scope.deleteBank = function() {
        if(appVariables.isSupplement){
          return;
        }

        $scope.showBankSearch = true;
        $scope.selectedBankName = '';
        $scope.modalData.bankData = null;
        $scope.modals.cessionModal.settings.okBtnDisabled = true;
      };

      $scope.$watch('modals.cessionModal.type', function()
      {
        if($scope.modals.cessionModal.type !== 'BANK')
        {
           $scope.deleteBank();
           $scope.modals.cessionModal.settings.okBtnDisabled = false;
        }else if(!$scope.modalData.bankData && !$scope.readonly)
        {
         $scope.modals.cessionModal.settings.okBtnDisabled = true;
        }
      });

      /**
       * wybrano organizację z listy - selecta
       */
      $scope.organizationSelected = function() {
        if ($scope.modalData.selectedOrganization === '-1') {
          $scope.modalData.organizationData = (new OrganizationModel()).getData();
          $scope.modalData.organizationData.countryCode = 'PL';
        } else {
          $scope.modalData.organizationData = $scope.dataContainer.organizations[$scope.modalData.selectedOrganization].getData();
        }
        if (!angular.isArray($scope.modalData.organizationData.addresses) || $scope.modalData.organizationData.addresses.length === 0) {
          $scope.modalData.organizationData.addresses = [addressHelper.getAdrStal()];
        }
      };

      $scope.init();
    }
  ]);