/**
 * Dyrektywa do wrzucenia szablonu modala zmiany hasła
 * @param  {[type]}	[description]
 * @return {[type]}	[description]
 */
angular.module('ihestiaSsoBase')
  .directive('ihestiaPasswordChange', ['$templateCache',
    function($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        template: $templateCache.get('ihestia-sso-template-app/sso/passwordChange/passwordChangeModal.tpl.html')
      };
    }
  ]).run(['bodyInjectorHelper',
  function(bodyInjectorHelper) {
    bodyInjectorHelper.injectDirective('passwordChangeModal', 'ihestia-password-change');
  }
]);