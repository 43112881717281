angular.module('ihestiaRestServicesBase')

/**
 * Lista banków
 */
.service('dictionaryBanksSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
  var DictionaryBanks = function () {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'banks';
    this.businessArea = 'dictionaries';
    this.port = '8204';
    this.path = 'RestDictionaries';
  };

  return new DictionaryBanks();
}]);