angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardWorkerObjectConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardWorkerObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {

      var WorkerObjectModel = function() {
        this.objectName = 'IhestiaDashboardWorkerObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          surname: {
            type: 'string'
          },
          workerId: {
            type: 'string'
          },
          saleProfileId: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_WORKER;
        this.name = null;
        this.surname = null;
        this.workerId = null;
        this.saleProfileId = null;

      };


      WorkerObjectModel.prototype = IHestiaAbstractModel.prototype;

      return WorkerObjectModel;
    }]);