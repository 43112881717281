angular.module('lsnBase.models')
  .factory('LsnApplicationModelConstants', [function() {
    var ApplicationModelConstants = {};
    ApplicationModelConstants.APPLICATION_STATUS_CALCULATION = 'CALCULATION';
    ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_ACCEPTED = 'CALCULATION_ACCEPTED';
    ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_DELETED = 'CALCULATION_DELETED';
    ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_EXPIRED = 'CALCULATION_EXPIRED';
    ApplicationModelConstants.APPLICATION_STATUS_OFFER = 'OFFER';
    ApplicationModelConstants.APPLICATION_STATUS_OFFER_ACCEPTED = 'OFFER_ACCEPTED';
    ApplicationModelConstants.APPLICATION_STATUS_OFFER_CANCELLED = 'OFFER_CANCELLED';
    ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED = 'OFFER_EXPIRED';
    ApplicationModelConstants.APPLICATION_STATUS_IOFFER = 'IOFFER';
    ApplicationModelConstants.APPLICATION_STATUS_IOFFER_ACCEPTED = 'IOFFER_ACCEPTED';
    ApplicationModelConstants.APPLICATION_STATUS_IOFFER_CANCELLED = 'IOFFER_CANCELLED';
    ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED = 'IOFFER_EXPIRED';
    ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED = 'IOFFER_REJECTED';

    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS = {};
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_CALCULATION] = 'kalkulacja';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_CALCULATION] = 'kalkulacja odrzucona';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_ACCEPTED] = 'kalkulacja zaakceptowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_DELETED] = 'kalkulacja usunięta';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_CALCULATION_EXPIRED] = 'kalkulacja przeterminowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_OFFER] = 'oferta';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_OFFER_ACCEPTED] = 'oferta zaakceptowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_OFFER_CANCELLED] = 'oferta anulowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_OFFER_EXPIRED] = 'oferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_IOFFER] = 'ioferta';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_IOFFER_ACCEPTED] = 'ioferta zaakceptowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_IOFFER_CANCELLED] = 'ioferta anulowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_IOFFER_EXPIRED] = 'ioferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_APPLICATION_STATUS[ApplicationModelConstants.APPLICATION_STATUS_IOFFER_REJECTED] = 'ioferta odrzucona';

    // statusy ofert
    ApplicationModelConstants.OFFER_STATUS_IOFFER_ACCEPTED = 'IOfferAccepted';
    ApplicationModelConstants.OFFER_STATUS_IOFFER = 'IOffer';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_CANCELLED = 'IOfferCancelled';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_EXPIRED = 'IOfferExpired';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_REJECTED = 'IOfferRejected';
    ApplicationModelConstants.OFFER_STATUS_OFFER_ACCEPTED = 'OfferAccepted';
    ApplicationModelConstants.OFFER_STATUS_OFFER = 'Offer';
    ApplicationModelConstants.OFFER_STATUS_OFFER_CANCELLED = 'OfferCancelled';
    ApplicationModelConstants.OFFER_STATUS_OFFER_EXPIRED = 'OfferExpired';
    ApplicationModelConstants.OFFER_STATUS_OFFER_REJECTED = 'OfferRejected';
    ApplicationModelConstants.OFFER_STATUS_DEMAND_PAYMENT = 'DemandPayment';

    ApplicationModelConstants.DICTIONARY_OFFER_STATUS = {};
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_ACCEPTED] = 'iOferta spolisowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER] = 'iOferta aktualna';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_CANCELLED] = 'iOferta anulowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_EXPIRED] = 'iOferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_REJECTED] = 'iOferta odrzucona';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_ACCEPTED] = 'Oferta spolisowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER] = 'Oferta aktualna';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_CANCELLED] = 'Oferta anulowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_EXPIRED] = 'Oferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_REJECTED] = 'Oferta odrzucona przez system';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_DEMAND_PAYMENT] = 'Żądanie płatności';

    // typy operacji
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_NEW = 'New';
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW = 'Renew';
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_EXTEND_INSURANCE = 'ExtendInsurance';
    ApplicationModelConstants.DICTIONARY_APPLICATION_OPERATION_TYPE = {};
    ApplicationModelConstants.DICTIONARY_APPLICATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_NEW] = 'Tryb tworzenia';
    ApplicationModelConstants.DICTIONARY_APPLICATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW] = 'Tryb wznowienia';
    ApplicationModelConstants.DICTIONARY_APPLICATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_EXTEND_INSURANCE] = 'Tryb dokupienia';
    return ApplicationModelConstants;
  }]);
