angular.module('lsnBase.models')
  .factory('LsnVehicleModelV1', ['LsnAbstractModel', 'lsnModelFactory', 'LsnVehicleModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnVehicleModelConstants) {

      var LsnVehicleModelV1 = function() {
        this.objectName = 'Vehicle';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          // celowa literowka ze wzgledu na niepoprawiona usluge
          metaData: {
            type: 'MetaData'
          },
          category: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          mark: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          model: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          type: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          bodyType: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          bodyNumber: {
            type: 'string'
          },
          fuel: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          importStart: {
            type: 'date'
          },
          importEnd: {
            type: 'date'
          },
          gearboxType: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }, {
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER
            }]
          },
          gears: {
            type: 'int'
          },
          power: {
            type: 'string',
            additionalParams: [{
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_POWER_UNIT_KW,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_POWER_UNIT
            }]
          },
          registration: {
            type: 'string',
            additionalParams: [{
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_REGISTRATION_TYPE_ORD,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE
            }],
            filters: [{
              name: 'strUpperCase'
            }, {
              name: 'strTrim'
            }, {
              name: 'strReplace',
              params: {
                searchValue: / /g,
                newValue: ''
              }
            }]
          },
          vin: {
            type: 'string',
            filters: [{
              name: 'strUpperCase'
            }, {
              name: 'strTrim'
            }, {
              name: 'strReplace',
              params: {
                searchValue: / /g,
                newValue: ''
              }
            }]
          },
          engineNumber: {
            type: 'string'
          },
          capacity: {
            type: 'string',
            additionalParams: [{
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_CAPACITY_UNIT_CM,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_CAPACITY_UNIT
            }]
          },
          cylindersSystem: {
            type: 'string',
            additionalParams: [{
              type: 'string'
            }]
          },
          cylindersCount: {
            type: 'int'
          },
          loadWeight: {
            type: 'float',
            additionalParams: [{
              type: 'string',
              defaultValue: LsnVehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_KG,
              dictionary: LsnVehicleModelConstants.DICTIONARY_VEHICLE_LOAD_WEIGHT_UNIT
            }]
          },
          firstRegistration: {
            type: 'date'
          },
          productionYear: {
            type: 'int'
          },
          doorCount: {
            type: 'int'
          },
          seatCount: {
            type: 'int'
          },
          mileage: {
            type: 'string'
          },
          color: {
            type: 'string'
          },
          originalKey: {
            type: 'int'
          },
          noOriginalKey: {
            type: 'int'
          },
          dynamicValues: {
            type: 'dynamicValues'
          },
          price: {
            type: 'string'
          },
          equipments: {
            type: 'array',
            elementsType: 'array'
          },
          securityEquipments: {
            type: 'array',
            elementsType: 'array'
          },
          totalWeight: {
            type: 'int'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.category = null; // Category, typ pojazdu
        this.mark = null; // Mark, marka pojazdu
        this.model = null; // Model, model pojazdu
        this.type = null; // Type, wersja pojazdu
        this.bodyType = null; // BodyType, wersja nadwozia
        this.bodyNumber = null; // string, numer nadwozia
        this.fuel = null; // Fuel, rodzaj paliwa
        this.gearboxType = null; // GearboxType, typ skrzyni biegów
        this.gears = null; // number, liczba przełożeń skrzyni biegów
        this.power = null; // Power, moc silnika KW/KM
        this.registration = null; // Registration, Numer rejestracyjny
        this.vin = null; // string, Numer VIN
        this.engineNumber = null; // string, Numer silnika
        this.capacity = null; // EngineCapacity, Pojemność silnika
        this.cylindersSystem = null; // CylindersSystem, Układ cylindrów
        this.cylindersCount = null; // number, Liczba cylindrów
        this.loadWeight = null; // LoadWeight, Ładowność pojazdu
        this.firstRegistration = null; // date, Data pierwszej rejestracji
        this.importStart = null; // date,
        this.importEnd = null; // date,
        this.doorCount = null; // number, Liczba drzwi
        this.seatCount = null; // number, Liczba miejsc siedzących
        this.mileage = null; // string, Przebieg
        this.color = null; // string,  Kolor nadwozia
        this.originalKey = null; // number, Liczba oryginalnych kluczyków
        this.noOriginalKey = null; // number, Liczba nie oryginalnych kluczyków. Jeśli takich nie ma to można nie zwracać wartości.
        this.dynamicValues = {}; // Lista atrybutów dotyczących pojazdu
        this.price = null;
        this.productionYear = null; // number, rok produkcji
        this.equipments = null; // Equipments[]
        this.securityEquipments = null; // Equipments[] - zabezpieczenia pojazdu
        this.totalWeight = null; // DMC

        this.setDefaultAdditionalData();

      };

      LsnVehicleModelV1.prototype = LsnAbstractModel.prototype;

      return LsnVehicleModelV1;
    }
  ]);
