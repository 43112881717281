angular.module('lsnBase.modelHelper')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('lsnModelHelper', ['$injector', '$filter', '$log', 'lsnModelFactory',
   function($injector, $filter, $log, lsnModelFactory) {

    var ModelHelper = function() {
      var self = this;

      this.objectTypes = ['int', 'float', 'string', 'date', 'dateTime', 'bool', 'dynamicValues', 'array', 'mixed'];
      this.protectedPropertyNames = ['fieldsProperties', 'objectName', '_additionalData', 'modelVersion', 'dataVersion']; // zabronione nazwy dla atrybutow obiektow - zarezerwowane do przechowywania meta informacji


      /**
       * wypelnia obiekt REST-owy danymi z przekazanego obiektu {klucz: wartosc[, klucz2: wartosc2...]}
       * @param {object} object
       * @param {object} data
       * @returns {undefined}
       */
      this.setData = function(object, data)
      {
        angular.forEach(object.fieldsProperties, function(fieldData, fieldName)
        {
          if (angular.isDefined(data[fieldName]))
          {
            if(angular.isFunction(object.set)) {
              object.set(fieldName, data[fieldName]);
            } else {
              self.set(object, fieldName, data[fieldName]);
            }
          }
        });
        return object;
      };

      this.fillObjectFromData = this.setData; // zachowujemy alias
      /**
       * parsuje (rzutuje) wartość do danego typu
       * @param {string} type
       * @param {*} value
       * @param {Array|undefined} filters
       * @param {Sreing} version
       * @returns {Array|ModelHelper.parseValue.parsedValue|ModelHelper.parseValue.newObject|window|Window}
       */
      this._parseValue = function(type, value, filters, version)
      {
        if (value === null)
        {
          return null;
        }
        var parsedValue = null;
        var date;
        switch (type)
        {
          case 'int':
            if (isNaN(parseInt(value, 10)) && value !== '') {
              value = 0;
            }
            if (value === '') {
              parsedValue = null;
              break;
            }
            parsedValue = parseInt(value, 10);
            break;
          case 'float':
            if (value === '') {
              parsedValue = null;
              break;
            }
            if (isNaN(parseFloat(value, 10))) {
              value = 0;
            }
            parsedValue = parseFloat(value, 10);
            break;
          case 'string':
            parsedValue = value + '';
            break;
          case 'date':
            date = new XDate(value);
            if (isNaN(date.getTime())) // niepoprawna data
            {
              parsedValue = null;
            }
            else
            {
              parsedValue = new XDate(value).toString('yyyy-MM-dd');
            }
            break;
          case 'dateTime':
            date = new XDate(value);
            if (isNaN(date.getTime())) // niepoprawna data
            {
              parsedValue = null;
            }
            else
            {
              parsedValue = new XDate(value).toString('i'); // ISO8601 bez strefy czasowej
            }
            break;
          case 'bool':
            if (value)
            {
              parsedValue = true;
            }
            else
            {
              parsedValue = false;
            }
            break;
          case 'array':
            if (!angular.isArray(value))
            {
              parsedValue = [value];
            }
            else
            {
              parsedValue = value;
            }
            break;
          case 'mixed': // piękny typ (dla usług to 'object') w którym możemy dostać dowolny typ string/int/object itp
            parsedValue = value;
            break;
          case 'object':
            if (angular.isObject(value))
            {
              parsedValue = value;
            }
            else
            {
              throw new Error('Unexpected value (type {0}) for plain object.'.format(typeof value));
            }
            break;
          default:
            // obiekt o zdefiniowanym modelu
            var modelClassName = lsnModelFactory.getModelClassName(type, version);
            if (modelClassName !== null)
            {
              var newObject = new ($injector.get(modelClassName))();
              if(angular.isUndefined(newObject.fieldsProperties)) // przykładowo obiekty BM /jkz
              {
                parsedValue = value;
              }
              else
              {
                if(angular.isFunction(newObject.setData)) {
                  newObject.setData(value);
                } else {
                  self.setData(newObject, value);
                }
                parsedValue = newObject;
              }
            }
            else
            {
              throw new Error('No model definition found for field type: {0}.'.format(type));
            }
            break;
        }
        if (filters && angular.isDefined(filters[0])) {
          parsedValue = self._applyFilters(parsedValue, filters);
        }
        return parsedValue;
      };

      this.set = function(object, fieldName, value)
      {
        if (angular.isUndefined(object.fieldsProperties[fieldName]))
        {
          throw new Error('undefined field properties for field name: ' + fieldName);
        }
        var properties = object.fieldsProperties[fieldName];
        // nieustawiona wartość to null
        if (angular.isUndefined(value))
        {
          value = null;
        }
        // jeśli wartość to null to możemy ją po prostu wstawić
        if (value === null)
        {
          object[fieldName] = value;
          return object;
        }
        var parsedValue = null;
        // jeśli to złożona wartość (tablica 1, 2 elementowa)
        if (angular.isDefined(properties.additionalParams))
        {
          if (angular.isUndefined(value[0]))
          {
            value = [value];
          }
          return self.setWithAdditionalParams(object, fieldName, value);
        }
        if (properties.type === 'dynamicValues')
        {
          return self._setDynamicAttributeValue(object, fieldName, value);
        }
        else if (properties.type === 'array' && angular.isUndefined(properties.elementsType))
        {
          throw 'Field with type array ({0}) must have defined elementsType.'.format(fieldName);
        }
        parsedValue = self._parseValue(properties.type, value, (angular.isDefined(properties.filters) ? properties.filters : null), (angular.isDefined(object.dataVersion) ? object.dataVersion : null));

        if(angular.isDefined(properties.defaultValue) && properties.defaultValue === parsedValue)
        {
          parsedValue = null;
        }

        // zdefiniowany typ elementu listy
        if (angular.isDefined(properties.elementsType))
        {
          angular.forEach(parsedValue, function(element, key)
          {
            // tu nie może być filtrów z properties.filters, bo inaczej na tym samym filtrze raz dostawalibyśmy całą tablice (powyżej), a raz pojedynczy element (tutaj)
            // jeśli zajdzie taka potrzeba to trzeba stworzyć elementsFilters
            parsedValue[key] = self._parseValue(properties.elementsType, element, null, (angular.isDefined(object.dataVersion) ? object.dataVersion : null));
          });
        }

        if (angular.isDefined(properties.dictionary) && parsedValue === '') // jeśli jest słownik to pusty string zamieniamy na null
        {
          parsedValue = null;
        }
        // jeśli mamy zdefiniowany słownik to nie pozwalamy na wartości z poza niego
        self.checkForDictionary(properties, parsedValue, fieldName, object.objectName);
        object[fieldName] = parsedValue;
        // uzupelnienie sztucznego id dla MetaData
        if (object.objectName === 'MetaData' && fieldName === 'clientId' && (object.id === null || object.id === '')) {
          if(angular.isFunction(object.set)){
            object.set('id', self._getFakeMetaDataId(parsedValue));
          } else {
            self.set(object, 'id', self._getFakeMetaDataId(parsedValue));
          }
        }
        return object;
      };
      // generuje sztuczne id dla metadata
      this._getFakeMetaDataId = function(clientId) {
        clientId = clientId + '';
        if (clientId === '') {
          throw new Error('Cannot set fake id from empty clientId');
        }
        return '-' + clientId;
      };
      /**
       * ustawia wartosc pola obiektu dla pól z opcją additionalParams (np. Vehicle.capacity)
       * @param {*} object
       * @param {string} fieldName
       * @param {*} value
       * @returns {undefined}
       */
      this.setWithAdditionalParams = function(object, fieldName, value)
      {
        var properties = object.fieldsProperties[fieldName];
        var additionalParamsValues = [];
        var parsedValue;
        if (angular.isObject(value))
        {
          parsedValue = self._parseValue(properties.type, value[0], (angular.isDefined(properties.filters) ? properties.filters : null), (angular.isDefined(object.dataVersion) ? object.dataVersion : null));
          angular.forEach(properties.additionalParams, function(paramProperties, k)
          {
            var index = k + 1;
            if (angular.isDefined(value[index]))
            {
              additionalParamsValues[index] = self._parseValue(paramProperties.type, value[index], (angular.isDefined(paramProperties.filters) ? paramProperties.filters : null), (angular.isDefined(object.dataVersion) ? object.dataVersion : null));
            }
          });
        }
        else
        {
          parsedValue = self._parseValue(properties.type, value, (angular.isDefined(properties.filters) ? properties.filters : null), (angular.isDefined(object.dataVersion) ? object.dataVersion : null));
        }
        if (additionalParamsValues.length !== 0)
        {
          angular.forEach(properties.additionalParams, function(paramProperties, k)
          {
            var index = k + 1;
            if (angular.isDefined(additionalParamsValues[index]))
            {
              if (angular.isDefined(paramProperties.dictionary) && additionalParamsValues[index] === '') // jeśli jest słownik to pusty string zamieniamy na null
              {
                additionalParamsValues[index] = null;
              }
              self.checkForDictionary(paramProperties, additionalParamsValues[index], fieldName, object.objectName);
            }
          });
        }
        // jeśli to złożona wartość (tablica wieloelemntowa gdzie pierwszy element to wartość, a reszta to parametry)
        // UWAGA: obecnie obowiazuje zalozenia "na sztywno", ze mozliwe jest przekazanie tylko jednego additionalParam (w razie potrzeby rozbudować poniższy kod)
        object[fieldName] = [];
        object[fieldName][0] = parsedValue; // wartość
        if (additionalParamsValues.length !== 0)
        {
          angular.forEach(additionalParamsValues, function(paramValue, k)
          {
            if (k !== 0)
            {
              if (angular.isUndefined(paramValue))
              {
                paramValue = null;
              }
              object[fieldName][k] = paramValue;
            }
          });
        }
        return object;
      };

      this.checkForDictionary = function(properties, value, fieldName, objectName)
      {
        if (angular.isDefined(properties.dictionary) && value !== null)
        {
          var foundInDictionary = false;
          lsnNg.forEach(properties.dictionary, function(v, k)
          {
            if (k === value)
            {
              foundInDictionary = true;
              return false;
            }
          });
          if (!foundInDictionary)
          {
            var error = new Error('Value not found in field dictionary. fieldName: {0}, value: {1}, model: {2}'.format(fieldName, value, objectName));
            error.object = properties;
            throw error;
          }
        }
      };
      /**
       * @param {IHestiaAbstractModel} object obiekt
       * @param {bool} additionalData czy dolaczyc wezel _additionalData (wynik metody getAdditionalData() na obiekcie)
       * @param {bool} withAdditionalParams czy zwrócić wszystkie additionalParams
       * @param {object} params wszystkie kolejne parametry
       *     params.initializeModelFields - dla typów o zdefiniowanym modelu i nieustawionej wartości tworzymy nowe puste obiekty
       */
      this.getData = function(object, additionalData, withAdditionalParams, params)
      {
        additionalData = (additionalData === true) ? true : false;
        withAdditionalParams = (withAdditionalParams === true) ? true : false;
        var data = {};
        if(angular.isUndefined(object.fieldsProperties)) {
          // wszystkie dane są luzem, więc przenosimy wszystko pozbywając się modeli (dla BM) JZ
          data = angular.copy(object);
          return data;
        }
        angular.forEach(object.fieldsProperties, function(fieldData, fieldName)
        {
          if(angular.isFunction(object.get)) {
            data[fieldName] = object.get(fieldName, true, withAdditionalParams, params);
          } else {
            data[fieldName] = self.get(object, fieldName, true, withAdditionalParams, params);
          }
        });
        if (additionalData) {
          data._additionalData = angular.copy(object.getAdditionalData());
        }
        return data;
      };

      // pobiera wartość atrybutu fieldName z object, jeśli modelToData === true to modele konwertuje na obiekty danych
      this.get = function(object, fieldName, modelToData, withAdditionalParams, params)
      {
        var properties = object.fieldsProperties[fieldName];
        var fieldValue = angular.isDefined(object[fieldName]) ? object[fieldName] : null;

        if (angular.isUndefined(modelToData))
        {
          modelToData = false;
        }
        if (angular.isUndefined(withAdditionalParams))
        {
          withAdditionalParams = false;
        }

        if (fieldValue !== null && angular.isDefined(properties.additionalParams)) // todo może będzie potrzeba wyciągnąć też parametry?
        {
          if (withAdditionalParams === false) {
            fieldValue = fieldValue[0];
          } else {
            fieldValue = angular.copy(object[fieldName]);
            angular.forEach(properties.additionalParams, function(addParam, idx) {
              var idxAddParam = idx + 1;
              if (angular.isUndefined(fieldValue[idxAddParam]) || fieldValue[idxAddParam] === null) {
                if (angular.isDefined(addParam.defaultValue)) {
                  fieldValue.push(addParam.defaultValue);
                } else {
                  fieldValue.push(null);
                }
              }
            });
          }
        }
        // domyślna wartość
        if(fieldValue === null && angular.isDefined(properties.defaultValue))
        {
          fieldValue = properties.defaultValue;
        }

        // dla array zwracamy domyślnie pusty array
        if(fieldValue === null && properties.type === 'array')
        {
          fieldValue = [];
        }

        if(properties.type === 'array' || properties.type === 'dynamicValues')
        {
          fieldValue = angular.copy(fieldValue);
        }


        // dla dynamicValues zwracamy pusty obiekt
        if(fieldValue === null && properties.type === 'dynamicValues')
        {
          fieldValue = {};
        }

        if (fieldValue !== null && properties.type === 'date')
        {
          fieldValue = new XDate(fieldValue).toString('yyyy-MM-dd'); // konwertujemy datę na postać bez czasu
        }

        // dla typów o zdefiniowanym modelu i nieustawionej wartości tworzymy nowe puste obiekty
        if(params && params.initializeModelFields && fieldValue === null && modelToData && self.objectTypes.indexOf(properties.type) === -1)
        {
          var modelClassName = lsnModelFactory.getModelClassName(properties.type);
          if (modelClassName !== null)
          {
            fieldValue = new ($injector.get(modelClassName))();
          }
        }

        // obiekt o zdefiniowanym modelu
        if (fieldValue !== null && angular.isDefined(fieldValue.objectName) && modelToData)
        {
          if(angular.isFunction(fieldValue.getData)) {
            fieldValue = fieldValue.getData(false, withAdditionalParams, params);
          } else {
            fieldValue = self.getData(fieldValue, false, withAdditionalParams, params);
          }
        }

        // lista elementów o zdefiniowanym typie, gdzie ten tym jest modelem
        if (angular.isDefined(properties.elementsType) && fieldValue !== null)
        {
          var list = null;
          if (angular.isArray(fieldValue))
          {
            list = [];
          }
          else
          {
            list = {};
          }
          angular.forEach(fieldValue, function(value, key)
          {
            if(angular.isFunction(value.getData)) {
              list[key] = value.getData(false, withAdditionalParams, params);
            } else {
              list[key] = self.getData(value, false, withAdditionalParams, params);
            }
          });
          fieldValue = list;
        }

        return fieldValue;
      };
      /**
       * zwraca sparsowaną wartość dla atrybutu(ów) dynamicznego wg specyfikacji BOS
       * @param {type} name
       * @param {object} valueObj
       * @returns {undefined}
       */
      this._setDynamicAttributeValue = function(object, fieldName, valueObj)
      {
        if (valueObj === null)
        {
          object[fieldName] = valueObj;
          return true;
        }
        if (!angular.isObject(valueObj))
        {
          throw new Error('Dynamic values must be passed as an object ( {key: value} ).');
        }
        var erase = true;
        angular.forEach(valueObj, function(value, key)
        {
          erase = false;
          if (value !== null && angular.isDefined(value))
          {
            if (angular.isUndefined(object[fieldName]) || object[fieldName] === null)
            {
              object[fieldName] = {};
            }
            object[fieldName][key] = self._getParsedDynamicValue(object, key, value);
          }
          else if (angular.isDefined(object[fieldName]) && object[fieldName] !== null && angular.isDefined(object[fieldName][key]))
          {
            delete object[fieldName][key];
          }
        });
        if (erase)
        {
          object[fieldName] = {};
        }
      };
      /**
       * parsuje wartosc atrybutu dynamicznego dla konkretnego obiektu (atrybuty z BOS)
       * @param {string} objectName
       * @param {string} attributeName
       * @param {*} value
       * @returns {*}
       */
      this._getParsedDynamicValue = function(object, attributeName, value)
      {
        if(!$injector.has('SPD')){
          return value;
        }
        var SPD = $injector.get('SPD'); // jeśli ścieża sprzedażowa to wymagamy SPD
        var objectName = object.objectName,
          error = false,
          container;
        switch (objectName) {
          case 'SalesProduct': // tu zakładamy, że mamy SPD
            if (!angular.isString(object.compId) || object.compId === '') {
              throw new Error('Cannot set dynamicValues for SalesProduct with no compId specified');
            }
            if ([SPD.salesProducts.Communication.idpm, SPD.salesProducts.Property.idpm].indexOf(object.compId) !== -1) {
              // polisa
              container = (SPD.salesProducts.Communication.idpm === object.compId) ? SPD.salesProducts.Communication.attributes : SPD.salesProducts.Property.attributes;
              if (angular.isDefined(SPD.allSalesProducts.attributes[attributeName])) {
                container = SPD.allSalesProducts.attributes;
              }
              else if (angular.isDefined(SPD.objectsDynamicValues.Application[attributeName])) { // atrybuty umowne z wniosku moga pojawic sie na pakiecie polisy
                container = SPD.objectsDynamicValues.Application;
              }
              else if (angular.isUndefined(container[attributeName]))
              {
                error = true;
              }
              return error ? value : self.parseDynamicValueByDef(container[attributeName], attributeName, value);
            } else {
              // ryzyko
              container = SPD.risks[object.compId];
              if (angular.isDefined(SPD.allRisks.attributes[attributeName])) {
                container = SPD.allRisks.attributes;
              } else if (angular.isUndefined(container) || angular.isUndefined(container.attributes[attributeName]))
              {
                error = true;
              } else {
                container = container.attributes;
              }
              return error ? value : self.parseDynamicValueByDef(container[attributeName], attributeName, value);
            }
          default:
            if (angular.isUndefined(SPD.objectsDynamicValues[objectName]) || angular.isUndefined(SPD.objectsDynamicValues[objectName][attributeName]))
            {
              error = true;
            }
            return error ? value : self.parseDynamicValueByDef(SPD.objectsDynamicValues[objectName][attributeName], attributeName, value);
        }

      };
      /**
       * parsuje atrybut wg podanej specyfikacji
       * @param {object} attrDef definicja atrybutu z SPD
       * @param {string} attributeName nazwa atrybutu
       * @param {*} value wartość atrybutu
       */
      this.parseDynamicValueByDef = function(attrDef, attributeName, value) {
        var parsedValue;
        switch (attrDef[0])
        { // wymiar
          case 'Simple':
            parsedValue = self._parseDynamicValue(value, attrDef[1], attributeName);
            break;
          case 'Collection':
            if (!$.isArray(value))
            {
              value = [value];
            }
            parsedValue = [];
            angular.forEach(value, function(valueOne) {
              parsedValue.push(self._parseDynamicValue(valueOne, attrDef[1], attributeName));
            });
            break;
          case 'Table': // @todo do spr i ewentualnej implementacji
            parsedValue = value;
            break;
          default:
            throw 'Unexpected dimension {0} for attribute {1}.'.format(attrDef[0], attributeName);
        }
        return parsedValue;
      };

      this._parseDynamicValue = function(value, type, attributeName)
      {
        var parsedValue;
        switch (type)
        { // typ
          case 'Integer':
            if (value === '') {
              value = 0;
            }
            parsedValue = parseInt(value, 10);
            break;
          case 'Decimal':
            if (value === '') {
              value = 0;
            }
            parsedValue = parseFloat(value);
            break;
          case 'Boolean':
            if (angular.isString(value) && (value === 'false' || value === '0')) {
              value = false;
            }
            parsedValue = value ? true : false;
            break;
          case 'Date':
            parsedValue = new XDate(value).toString('yyyy-MM-dd');
            break;
          case 'String':
            parsedValue = value + '';
            break;
          default:
            throw 'Unexpected value type {0} for attribute {1}.'.format(type, attributeName);
        }
        return parsedValue;
      };

      // ustawianie worka z danymi dodatkowymi, jeśli podane fieldName to ustawia jedną wartość, w przeciwnym wypadku nadpisuje cały obiekt
      this.setAdditionalData = function(object, value, fieldName)
      {
        if (angular.isUndefined(object._additionalData))
        {
          object._additionalData = {};
        }
        if (angular.isUndefined(fieldName))
        {
          if (!$.isPlainObject(value))
          {
            throw new Error('Additional data musi być obiektem.');
          }
          object._additionalData = value;
        }
        else // mamy niepusty fieldname
        {
          if (angular.isString(fieldName)) // paczka danych pierwszego poziomu
          {
            object._additionalData[fieldName] = value;
          }
          else if (angular.isObject(fieldName))
          {
            var element = object._additionalData;
            var lastFieldName;
            lsnNg.forEach(fieldName, function(fieldNameElement, k) {
              lastFieldName = fieldNameElement;
              if (k === fieldName.length - 1)
              {
                return false;
              }
              if (angular.isUndefined(element[fieldNameElement]))
              {
                element[fieldNameElement] = {};
              }
              element = element[fieldNameElement];
            });

            element[lastFieldName] = value;
          }
        }
        return object;
      };

      this.deleteAdditionalData = function(object, fieldName)
      {
        switch (typeof fieldName)
        {
          case 'undefined':
            delete object._additionalData;
            break;
          case 'string':
            delete object._additionalData[fieldName];
            break;
          case 'object':
            var element = object._additionalData;
            var lastFieldName;
            lsnNg.forEach(fieldName, function(fieldNameElement, k)
            {
              lastFieldName = fieldNameElement;
              if (k === fieldName.length - 1)
              {
                return false;
              }
              element = element[fieldNameElement];
            });

            delete element[lastFieldName];
            break;
          default:
            break;
        }
      };

      // zwraca całe _additionalData (jak nie podano fieldName) lub jego konkretną wartość
      this.getAdditionalData = function(object, fieldName)
      {
        if (angular.isUndefined(fieldName))
        {
          if (angular.isUndefined(object._additionalData))
          {
            return {};
          }
          else
          {
            return object._additionalData;
          }
        }
        else
        {
          if (angular.isUndefined(object._additionalData))
          {
            return null;
          }
          else
          {
            if (angular.isString(fieldName)) // paczka danych pierwszego poziomu
            {
              if (angular.isUndefined(object._additionalData[fieldName]))
              {
                return null;
              }
              else
              {
                return object._additionalData[fieldName];
              }
            }
            else if (angular.isObject(fieldName))
            {
              var element = object._additionalData;
              var lastFieldName;
              lsnNg.forEach(fieldName, function(fieldNameElement, k) { // iterujemy wgłąb drzewa obiektu
                lastFieldName = fieldNameElement;
                if (k === fieldName.length - 1)
                {
                  return false;
                }
                if (angular.isUndefined(element[fieldNameElement]))
                {
                  return null;
                }
                element = element[fieldNameElement];
              });

              if (angular.isUndefined(element[lastFieldName]))
              {
                return null;
              }
              else
              {
                return element[lastFieldName];
              }
            }
          }
        }
      };
      // uruchamia filtry na przekazanej wartosci
      this._applyFilters = function(value, filters) {
        angular.forEach(filters, function(filter) {
          var filterName = filter.name + 'ModelFilter';
          var angularFilterName = filter.name + 'Filter';
          if($injector.has(filterName))
          {
            // filtrowanie old style
            var filterFunction = $injector.get(filterName);
            value = filterFunction(value, filter.params ? filter.params : undefined);
          }
          else if($injector.has(angularFilterName))
          {
            // użycie filtru angularowego
            var args = [value];
            if(filter.params)
            {
              args = args.concat(filter.params);
            }
            value = $filter(filter.name).apply(this, args);
          }
          else
          {
            $log.warn('Filter ' + filterName + '/' + angularFilterName + ' not defined.');
          }
        });
        return value;
      };

      // minimalizuje obiekt REST do elementow !== null
      // extendedMode wywala również puste string itp.
      this.minifyRestObject = function(obj, extendedMode, nonull) {
        if(angular.isUndefined(extendedMode))
        {
          extendedMode = false;
        }

        if (!angular.isObject(obj)) {
          return;
        }
        var restObj = false;
        if(obj !== null && angular.isDefined(obj.objectName))
        {
          restObj = true;
        }
        //
        if(extendedMode && restObj)
        {
          angular.forEach(obj.fieldsProperties, function(properties, field) {
            if(properties.type === 'int' && obj[field] === '') // niewypełniony iny
            {
              delete obj[field];
            }
            if(properties.type === 'string' && obj[field] === '') // puste stringi
            {
              delete obj[field];
            }
            else if(properties.type === 'array' && angular.isArray(obj[field]) && obj[field].length === 0) // puste tablice
            {
              delete obj[field];
            }
          });
        }
        if (restObj) { // usunięcie informacji o obiekcie itp.
          angular.forEach(self.protectedPropertyNames, function(field) {
            delete obj[field];
          });
        }
        angular.forEach(obj, function(val, prop) {
          if (val === null && (restObj || nonull)) {
            delete obj[prop];
          } else if (angular.isObject(val) && val !== null) {
            self.minifyRestObject(val, extendedMode, nonull);
          }
        });
      };
      /**
       * konwertuje oviekt modeli REST do "zwyklego" obiektu JS
       * @param {object} object obiekt do konwersji
       */
      this.toPlainObject = function(object) {
        return angular.copy(object);
      };

      this.setDefaultAdditionalData = function(object)
      {
        if(!$injector.has('SPD')){
          return object;
        }
        var SPD = $injector.get('SPD'); // jeśli ścieża sprzedażowa to wymagamy SPD
        if(angular.isDefined(SPD.additionalData[object.objectName]))
        {
          angular.forEach(SPD.additionalData[object.objectName], function(defaultValue, key){
            if(defaultValue !== null)
            {
              object.setAdditionalData(angular.copy(defaultValue), key);
            }
          });
        }
        return object;
      };
    };

    return new ModelHelper();
  }]);