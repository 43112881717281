angular.module('salesPath2')
  .factory('FactorRowModel', [
    function ()
    {

      var FactorRowModel = function (data)
      {
        this.lp = data.lp || null;
        this.name = data.name || null;
        this.risks = [];
      };

      return FactorRowModel;
    }
  ]);