angular.module('ihestiaGridSettings')
  .controller('ihestiaDatePeriodInputsCtrl', ['$scope',
    function ($scope) {

      // $scope.attributeCode set in parent scope
      // $scope.attributeLabel set in parent scope

      $scope.dateFromAttribute = $scope.attributeCode + 'From';
      $scope.dateToAttribute = $scope.attributeCode + 'To';

      $scope.init = function()
      {

      };

      $scope.dateFromChanged = function()
      {
        // clear corresponding calendar values
        // $scope.currentFilter.settings.filterSettings[$scope.attributeCode] = null;
      };

      $scope.dateToChanged = function()
      {
        // clear corresponding calendar values
        // $scope.currentFilter.settings.filterSettings[$scope.attributeCode] = null;
      };

      $scope.init();
    }
  ]);
