angular.module('ihestiaWidgets.commonOperations')
  .controller('clientSearchModalCtrl', ['$scope', 'customerSvc', 'iHestiaCommonModalHelper', '$window', 'ihestiaConfigHelper', 'customerPoliciesSvc', 'ihestiaSsoBaseInfoHelper', 'ihestiaAgHelper', 'ihestiaCustomerSearchSvc', 'clientSearchModalHelper', '$timeout', 'deprectedHelper',
    function($scope, customerSvc, iHestiaCommonModalHelper, $window, ihestiaConfigHelper, customerPoliciesSvc, ihestiaSsoBaseInfoHelper, ihestiaAgHelper, ihestiaCustomerSearchSvc, clientSearchModalHelper, $timeout, deprectedHelper) {

      $scope.customerQuery = {
        firstName: '',
        lastName: '',
        identityNumber: ''
      };

      $scope.policyQuery = {
        customerId: ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().getCustomerId(),
        policyNumber: ''
      };

      $scope.tplData = {
        personIsForeigner: false
      };

      $scope.specialErrors = {
        invalidPolicyNumber: false,
        invalidCustomer: false
      };

      $scope.customerData = {};

      $scope.errorList = [];


      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        deprectedHelper.setAsDeprecated('clientSearchModal', 'use findClientModalHelper.showModal(...) instead!');
        if (clientSearchModalHelper.searchData !== null) {
          $scope.customerQuery = angular.copy(clientSearchModalHelper.searchData);
          clientSearchModalHelper.clearData();
        }

        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaOperationsClientSearch', function($event) {
          $event.preventDefault();
          $scope.clearErrors();
          var tokenErrorList = $scope.validateForToken();
          if (tokenErrorList.length === 0) //mamy pełne dane usera, więc strzelamy o token
          {
            $scope.createToken();
          } else {
            $scope.findCustomers();
          }
        });
      };

      $scope.findCustomers = function() {
        var dynamicFilters = angular.copy($scope.customerQuery);
        if (!$scope.tplData.personIsForeigner) {
          dynamicFilters.pesel = dynamicFilters.identityNumber;
          delete dynamicFilters.identityNumber;
        }

        $scope.$emit('showBlockUi');
        ihestiaCustomerSearchSvc.findByParams({
          count: 10,
          skip: 0,
          top: 10,
          dynamicFilters: dynamicFilters,
          callback: function(response) {
            if (response.data.items.length === 0) {

              $scope.errorList.push({
                label: ['operation.clientNotFoundTryAgain', {
                  componentCode: 'PolicyOperations'
                }]
              });
              $scope.$emit('hideBlockUi');
            } else if (response.data.items.length === 1) {
              clientSearchModalHelper.goToClient(response.data.items[0]);
              clientSearchModalHelper.clearData();
              $scope.$emit('hideBlockUi');
              iHestiaCommonModalHelper.hideModal('ihestiaOperationsClientSearch');
            } else {
              clientSearchModalHelper.customersListResponse = response.data;
              clientSearchModalHelper.searchData = $scope.customerQuery;
              iHestiaCommonModalHelper.hideModal('ihestiaOperationsClientSearch');
              $timeout(function() {
                iHestiaCommonModalHelper.showModal('ihestiaOperationsClientSearchList');
              }, 0);
            }
          },
          errorCallback: function() {
            $scope.errorList.push({
              label: ['operation.clientNotFoundTryAgain', {
                componentCode: 'PolicyOperations'
              }]
            });
            $scope.$emit('hideBlockUi');
          }
        });
      };

      /**
       * Tworzymy token wyszukiwania klienta
       * dzięki temu klient będzie widoczny w ikoncie
       * @return {undefined}
       */
      $scope.createToken = function() {
        $scope.$emit('showBlockUi');
        $scope.customerData = null;
        customerSvc.registerAccessToken($scope.customerQuery).then(function(res) {
          var searchErrorMessage = {label: ['operation.errorOccurredDuringSearchClient', {
            componentCode: 'PolicyOperations'
          }]};
          if (res && angular.isDefined(res.data) && angular.isDefined(res.status)) {
            switch (res.status) {
              case 201:
                $scope.customerData = res.data;
                if ($scope.policyQuery.policyNumber) {
                  customerPoliciesSvc.registerAccessToken(res.data.customerId, $scope.policyQuery.policyNumber).then(function(policyRes) {
                    if (policyRes && (policyRes.status === 200 || policyRes.status === 201)) {

                      // oczekiwane wartości brand to IHESTIA lub DIRECT
                      // idziemy do iKonta zgodnego z podanym dla wyszukiwanej polisy brandem
                      if (policyRes.data.brand === 'DIRECT') {
                        $scope.openCustomerAccount('DIRECT');
                      } else {
                        $scope.openCustomerAccount('IHESTIA');
                      }
                    } else {
                      $scope.specialErrors.invalidPolicyNumber = true;
                    }
                  }, angular.noop).finally(function() {
                    $scope.$emit('hideBlockUi');
                  });
                } else {
                  $scope.$emit('hideBlockUi');
                  // jeżeli na koncie klienta wśród brandów pojawi się wartość IHESTIA to link powinien prowadzić do iKonta iHestia
                  // jeśli jest tylko DIRECT to idziemy do iKonta Direct
                  if (angular.isDefined($scope.customerData.brands)) {
                    if ($scope.customerData.brands.indexOf('IHESTIA') !== -1) {
                      $scope.openCustomerAccount('IHESTIA');
                    } else {
                      $scope.openCustomerAccount('DIRECT');
                    }
                  } else {
                    $scope.openCustomerAccount('IHESTIA');
                  }

                }
                break;

              case 204:
                $scope.iPegazSearch = true;
                $scope.specialErrors.invalidCustomer = true;
                $scope.$emit('hideBlockUi');
                break;

              case 404:
                //szukamy w klientach własnych w razie gdyby ktoś podał wszystkie dane, ale niepełne (np tylko część nazwiska)
                $scope.findCustomers();

                break;

              default:
                if (angular.isDefined(res.data.messages) && res.data.messages.length > 0) {
                  angular.forEach(res.data.messages, function(msg){
                    $scope.errorList.push({text: msg});
                  });
                } else {
                  $scope.errorList.push(searchErrorMessage);
                }
                $scope.$emit('hideBlockUi');
            }
          } else {
            $scope.errorList.push(searchErrorMessage);
            $scope.$emit('hideBlockUi');
          }
        }, function() {
          $scope.$emit('hideBlockUi');
        }, {
          allowedStatuses: [404]
        });
      };

      /**
       * Otwarcie konta klienta w iKoncie.
       */
      $scope.openCustomerAccount = function(brandType) {
        var ikontoType = '';
        var hasPermission = false; // NOSONAR

        if (brandType === 'DIRECT' && ihestiaAgHelper.hasPermissionFor('Public', 'iKontoDirect')) {
          hasPermission = true;
          ikontoType = 'IKONTO_DIRECT_URL';
        } else if (brandType === 'IHESTIA' && ihestiaAgHelper.hasPermissionFor('Public', 'iKontoiHestia')) {
          hasPermission = true;
          ikontoType = 'IKONTO_URL';
        } else {
          hasPermission = false;

          $scope.errorList.push({label: ['operation.youDoNotHavePermissionToUseThisClient', {
            componentCode: 'PolicyOperations'
          }]});
        }

        if (hasPermission) {
          var clientUrl = ihestiaConfigHelper.getUrl(ikontoType, 'external/client?customerContextId=' + $scope.customerData.customerId);
          $window.open(clientUrl);
          iHestiaCommonModalHelper.hideModal('ihestiaOperationsClientSearch');
        }
      };

      $scope.clearErrors = function() {
        $scope.errorList = [];
        angular.forEach($scope.specialErrors, function(errorValue, errorKey) {
          $scope.specialErrors[errorKey] = false;
        });
      };

      /**
       * Walidacja tworzenia tokenu
       * @return {Boolean}
       */
      $scope.validateForToken = function() {
        var errorList = [];
        if (!$scope.customerQuery.firstName) {
          errorList.push({
            label: ['fillName', {
              componentCode: 'Public'
            }]
          });
        }
        if (!$scope.customerQuery.lastName) {
          errorList.push({
            label: ['fillSurname', {
              componentCode: 'Public'
            }]
          });
        }
        if (!$scope.customerQuery.identityNumber) {
          if ($scope.tplData.personIsForeigner) {
            errorList.push({
              label: ['fillDocumentNumberShort', {
                componentCode: 'Public'
              }]
            });
          } else {
            errorList.push({
              label: ['fillPolicyNumber', {
                componentCode: 'Public'
              }]
            });
          }
        }

        return errorList;
      };

      /**
       * Otwarcie wyszukiwania polisy iPegaz w nowym oknie/karcie.
       */
      $scope.openIPegazSearchWindow = function() {
        var iPegazUrl = ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'PolicyInfo.aspx');
        $window.open(iPegazUrl);
        iHestiaCommonModalHelper.hideModal('clientSearch');
      };

    }
  ]);