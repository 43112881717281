angular.module('iSkanerAztecs.base')
	.service('iskanerAztecsMapHelper', ['ihestiaCommonErrorHandler',
		function(ihestiaCommonErrorHandler) {
			var IskanerAztecsMapHelper = function() {
				var self = this;

				//mapper z modelu do danych aztecowych
				this.modelToAztecMap = {
					address: {
						apartment: 'C_2_3_Lokal',
						city: 'C_2_3_Miejscowosc',
						house: 'C_2_3_Dom',
						postalCode: 'C_2_3_KodPocztowy',
						street: 'C_2_3_Ulica'
					},
					person: {
						firstName: 'C_2_1_Imiona',
						lastName: 'C_2_1_Nazwisko',
						pesel: 'C_2_2',
						regon: 'C_2_2'
					}
				};

				/**
				 * [setAddressByAztecData ustawia dane z azteca na adresie]
				 * @param {AddressModel} address   []
				 * @param {object} aztecData [description]
				 */
				this.setAddressByAztecData = function(address, aztecData) {
					angular.forEach(self.modelToAztecMap.address, function(attrAztecName, attrModelName) {
						address[attrModelName] = aztecData[attrAztecName];
					});
				};

				/**
				 * [setAddressByAztecData ustawia dane z azteca na osobie]
				 * @param {AddressModel} address   []
				 * @param {object} aztecData [description]
				 */
				this.setPersonByAztecData = function(personData, aztecData) {
					angular.forEach(self.modelToAztecMap.person, function(attrAztecName, attrModelName) {
						if (attrModelName === 'pesel') {
							if(aztecData[attrAztecName].isValidPESEL()){
								personData.pesel = aztecData[attrAztecName];
								personData.birthDate = personData.pesel.peselToBirthdate();
								personData.gender = personData.pesel.peselToSex();
							}
						} else if (attrModelName === 'regon'){
							if(aztecData[attrAztecName].isValidREGON()) {
								personData[attrModelName] = aztecData[attrAztecName];
							}
						} else if(attrModelName === 'firstName') {
							personData[attrModelName] = aztecData[attrAztecName].split(' ')[0];
						}else {
							personData[attrModelName] = aztecData[attrAztecName];
						}
					});

					if (angular.isArray(personData.addresses) && angular.isObject(personData.addresses[0])) {
						self.setAddressByAztecData(personData.addresses[0], aztecData);
					} else {
						ihestiaCommonErrorHandler.throwException({
							message: 'Aby zasetowac dane z azteca osoba powinna miec zainicjalizowany obiekt adresu'
						});
					}
				};
			};

			return new IskanerAztecsMapHelper();
		}
	]);