/**
 * Kontroler navbara
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .controller('ihestiaMenuLeftNavbarCtrl', ['$scope', 'iHestiaMenuLeftNavbarHelper', '$timeout', 'iHestiaMenuSettings', '$window', 'ihestiaConfigHelper', 'menuLeftItemListHelper', 'labelActionHelper',
    function($scope, iHestiaMenuLeftNavbarHelper, $timeout, iHestiaMenuSettings, $window, ihestiaConfigHelper, menuLeftItemListHelper, labelActionHelper) {

      $scope.navBarSettings = iHestiaMenuLeftNavbarHelper.settings;
      $scope.iHestiaMenuSettings = {};

      /**
       * Callback przy ukrywaniu navbara
       * @return {[type]} [description]
       */
      $scope.navBarSettings.hideCallback = function() {
        $timeout(function(){});
      };

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = function() {
        $timeout(function() {
          $scope.iHestiaMenuSettings = iHestiaMenuSettings.settings.leftNavBar ? iHestiaMenuSettings.settings.leftNavBar : {};
          if($scope.iHestiaMenuSettings.itemsByDefaultDefinition){
            $scope.prepareByCodes();
            //jesli zmiana jezyka tez odswiezamy
            labelActionHelper.registerAction('reloadLeftMenuItems', $scope.prepareByCodes);
          }

          $scope.iHestiaMenuSettings.active = $scope.iHestiaMenuSettings.active !== false;
          $scope.iHestiaMenuSettings.visible = $scope.iHestiaMenuSettings.visible === false ? false : true;
          if(!$scope.iHestiaMenuSettings.active) {
            $scope.iHestiaMenuSettings.visible = false;
          }
        }, 0);
      };

      $scope.prepareByCodes = function(){
        menuLeftItemListHelper.prepareByCodes($scope.iHestiaMenuSettings.items);
      };

      $scope.itemClicked = function(item, $event) {
        if (!item.disabled) {
          var standardCodes = {
            ipegaz: {
              urlCode: 'IPEGAZ_URL',
              target: '_blank'
            },
            ikomunikator: {
              urlCode: 'IKOMUNIKATOR_URL',
              target: '_self'
            },
            solicitedReports: {
              urlCode: 'SOLICITED_REPORTS',
              target: '_self'
            }
          };
          var itemCode = (item.code + '').toLowerCase();
          if(standardCodes[itemCode]) {
            var config = standardCodes[itemCode];
            $window.open(ihestiaConfigHelper.getUrl(config.urlCode), config.target);
          //czlony 2 wyrazowe
          }else if(standardCodes[item.code]){
            var config2 = standardCodes[item.code];
            $window.open(ihestiaConfigHelper.getUrl(config2.urlCode), config2.target);          
          } else {
            $scope.$emit('iHestiaMenuLeftNavbarItemClicked', item);
          }
          if(!$event.defaultPrevented) {
            iHestiaMenuLeftNavbarHelper.hide();
          }
        }
        $event.stopPropagation();
      };

      /**
       * Najechanie na głowny element menu
       * @return {[type]} [description]
       */
      $scope.mouseenterMain = function() {
        if ($scope.navBarSettings.openLvl === 'none') {
          $scope.navBarSettings.openLvl = 'part';
        }
      };

      /**
       * Opuszczenie głównego elementu menu
       * @return {[type]} [description]
       */
      $scope.mouseleaveMain = function() {
        // timeout do poczekania na drugi event mouseenter (mouseenterLeftBar)
        $timeout(function() {
          if ($scope.navBarSettings.openLvl !== 'all') {
            $scope.navBarSettings.openLvl = 'none';
          }
        }, 0);
      };

      /**
       * Przelotka na showFullLeftBar ze względu na click na document ukrywający menu
       * @return {[type]} [description]
       */
      $scope.mainMenuClicked = function() {
        $scope.navBarSettings.preventClose = true;
        $scope.showFullLeftBar();
      };

      /**
       * Najechanie na lewe menu
       * @return {[type]} [description]
       */
      $scope.showFullLeftBar = function() {
        $scope.navBarSettings.openLvl = 'all';
      };

      $scope.$on('toogleMenuVisible', function($event, visible){
        if($scope.iHestiaMenuSettings.active){
          $scope.iHestiaMenuSettings.visible = visible;
        }
      });

      $scope.init();
    }
  ]);