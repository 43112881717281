angular.module('ihestiaWidgets.claims')
  .service('claimsHelper', [
    function() {

      var ClaimsHelper = function() {

        this.getObject = function(claim) {
          var object = null;
          if(claim.objectIsPerson)
          {
            if(claim.insuredPerson)
            {
              object = angular.copy(claim.insuredPerson);
              object.type = 'PERSON';
            }
          }
          else if(claim.claimObject)
          {
            object = angular.copy(claim.claimObject);
          }
          return object;
        };

        this.isObjectEmpty = function(object)
        {
          return object === null || !object.type ? true : false;
        };

        this.getObjectByType = function(claimObjectList, objectType)
        {
          var object = null;
          var firstObject = null;
          angular.forEach(claimObjectList, function(claimObject){
            if(object !== null)
            {
              //znaleźliśmy już
              return;
            }
            //najpierw szukamy czy jest pojazd
            if(claimObject.type === objectType || angular.isUndefined(objectType))
            {
              if(firstObject === null)
              {
                firstObject = claimObject;
              }
              //sprawdzamy czy to poszkodowany
              angular.forEach(claimObject.roleList, function(role){
                if(role.value === 'SUFFERER')
                {
                  //jest pojazd który jest poszkodowany, więc to ten
                  object = claimObject;
                }
              });
            }
          });
          if(object === null && firstObject !== null)
          {
            //jak nie znaleźliśmy poszkodowanego to bierzemy pierwszy
          }
          return object;
        };

        /**
         * szukamy osoby ubezpieczonej
         * @param  {array} claimPersonList
         * @return {object}
         */
        this.getPersonObject = function(claimPersonList)
        {
          var object = null;
          var policyHolder = null;
          var anyPerson = null;
          angular.forEach(claimPersonList, function(claimPerson){
            if(object !== null)
            {
              //znaleźliśmy już
              return;
            }
            angular.forEach(claimPerson.roleList, function(role){
              if(object !== null)
              {
                //znaleźliśmy już
                return;
              }
              if(role.value === 'SUFFERER')
              {
                object = claimPerson;
              }
              if(role.value === 'POLICY_HOLDER')
              {
                policyHolder = claimPerson;
              }
              anyPerson = claimPerson;
            });
          });
          if(object === null)
          {
            object = policyHolder;
          }
          if(object === null)
          {
            object = anyPerson;
          }
          if(object !== null)
          {
            object.type = 'PERSON';
          }
          return object;
        };
      };

      return new ClaimsHelper();
    }]);