/**
 * Mapper BM
 */
angular.module('salesPath2').service('fromRestBonusMalusMapper', ['MapperUtils', 'sp2CommonHelper', 'CONSTANTS', 'bonusMalusHelper', 'BonusMalusDataObjectModel', 'BonusMalusContinuationModel', 'BonusMalusNewModel',
  function (MapperUtils, sp2CommonHelper, CONSTANTS, bonusMalusHelper, BonusMalusDataObjectModel, BonusMalusContinuationModel, BonusMalusNewModel)
  {
    var FromRestBonusMalusMapper = function ()
    {
      var self = this;
      this.utils = MapperUtils;

      /**
       * mapuje tablicę danych BM
       * @param  {Application} application
       * @param  {object} tempDataContainer
       */
      this.mapBonusMalusData = function (application, tempDataContainer, mapOptions) //eslint-disable-line consistent-return
      {
        angular.forEach(tempDataContainer.bonusMalus, function (bonusMalusDataSet)
        {
          angular.forEach(bonusMalusDataSet, function (bm)
          {
            bm.risks = {
              OC: null,
              AC: null
            };
          });
        });

        if (mapOptions.initialLoad)
        {
          bonusMalusHelper.refreshBonusMalusObjects(tempDataContainer); //przy wczytaniu ścieżki musimy stworzyć najpierw strukturę BM na podstawie osób i pojazdów
        }
        if (mapOptions.policyMode) //wczytanie polisy, więc musimy zczytać dane BM z osób i ryzyk
        {
          self._loadBonusMalusDataFromPersonsAndRisks(application, tempDataContainer);
        }
        if (application.bonusMalusData === null || angular.equals(tempDataContainer.vehicles, {}))
        {
          return false;
        }

        angular.forEach(application.bonusMalusData, function (bonusMalusDataSet)
        {
          self._mapBonusMalusDataSet(bonusMalusDataSet, tempDataContainer, mapOptions);
        });
      };

      /**
       * Wczytywanie danych BM z polisy
       * Mamy już pustą strukturę BM i mapujemy dane BM z osób
       * @param  {Application} application
       * @param  {DataManager} tempDataContainer
       */
      this._loadBonusMalusDataFromPersonsAndRisks = function (application, tempDataContainer)
      {
        lsnNg.forEach(application.persons, function (person)
        {
          if (typeof tempDataContainer.bonusMalus[person.metaData.get('clientId')] === 'undefined')
          {
            return true; //osoba nie jest właścicielem/współwłaścicielem pojazdu
          }
          var personDynamicValues = person.get('dynamicValues');

          lsnNg.forEach(tempDataContainer.bonusMalus[person.metaData.get('clientId')], function (vehicleBmData, vehicleId)
          {
            vehicleBmData.popupShowed = true;
            vehicleBmData.dataWillChange = false;
            vehicleBmData.clientDataVerified = true;

            var risk;
            var vehicle;
            lsnNg.forEach(application.vehicles, function (vehicleObject)
            {
              if (parseInt(vehicleObject.metaData.get('clientId'), 10) === parseInt(vehicleId, 10))
              {
                vehicle = vehicleObject;
                return false;
              }
              return true; //continue
            });

            if (typeof personDynamicValues.acCurrentBMClass !== 'undefined') //jest ryzyko AC
            {
              risk = self._findRiskForVehicle(CONSTANTS.RISK_AC, vehicle, application);

              vehicleBmData.risks.AC = new BonusMalusDataObjectModel();
              self._fillBmRiskData(risk, vehicleBmData.risks.AC, personDynamicValues, 'AC');
            }
            if (typeof personDynamicValues.ocCurrentBMClass !== 'undefined') //jest ryzyko OC
            {
              risk = self._findRiskForVehicle(CONSTANTS.RISK_OC, vehicle, application);

              vehicleBmData.risks.OC = new BonusMalusDataObjectModel();
              self._fillBmRiskData(risk, vehicleBmData.risks.OC, personDynamicValues, 'OC');
            }
          });
        
          return true; //continue
        });
      };

      /**
       * zwraca ryzyko o podanym kodzie i dla danego pojazdu
       * @param  {string} riskCode
       * @param  {Vehicle} vehicle
       * @param  {Application} application
       * @return {Risk}
       */
      this._findRiskForVehicle = function (riskCode, vehicle, application)
      {
        var matchedRisk = null;
        lsnNg.forEach(application.risks, function (risk)
        {
          var def = self.utils.findRiskDefByIdpm(risk.product.compId);
          if (def.riskCode === riskCode && vehicle.metaData.get('id') === risk.insuredObjectsRef[0].ref)
          {
            matchedRisk = risk;
            return false;
          }
          
          return true; //continue
        });
        return matchedRisk;
      };

      /**
       * wypełniamy dane BM dla konkretnego ryzyka, pojazdu i osoby
       * @param  {[type]} risk                [description]
       * @param  {[type]} BmDataObject        [description]
       * @param  {[type]} personDynamicValues [description]
       * @param  {[type]} riskName            [description]
       * @return {[type]}                     [description]
       */
      this._fillBmRiskData = function (risk, BmDataObject, personDynamicValues, riskName)
      {
        BmDataObject.riskName = riskName; //AC/OC

        var useGivenData = false;
        if (typeof personDynamicValues.claimVerificationResultDenial !== 'undefined' && personDynamicValues.claimVerificationResultDenial)
        {
          useGivenData = true;
        }
        BmDataObject.data.useGivenData = useGivenData;

        if (risk !== null && personDynamicValues['_' + riskName + 'BMmode'] === 'CONTINUATION') //kontynuacja (wg zgłoszenia IHESTIAMF-2098)
        {
          BmDataObject.data.continuationModeData = new BonusMalusContinuationModel();
          BmDataObject.data.continuationModeData.last1YearDamagesCount = personDynamicValues[riskName.toLowerCase() + 'LastYearClaimsNumber'];
          BmDataObject.data.continuationModeData.noClaimContinuationYears = personDynamicValues['_' + riskName + 'NoClaimInsuranceContinuation'];
          BmDataObject.data.continuationModeData.policyStartDate = risk.product.dynamicValues.insuranceStartDateOld;
          BmDataObject.data.continuationModeData.policyEndDate = risk.product.dynamicValues.insuranceEndDateOld;
          BmDataObject.data.continuationModeData.bmClass = personDynamicValues[riskName.toLowerCase() + 'PreviousBMClass'];
          BmDataObject.data.continuationModeData.last1YearOuzCoveredDamagesCount = personDynamicValues[riskName.toLowerCase() + 'ouzCoveredClaimsNumber'];
          BmDataObject.data.continuationModeData.policyNumber = personDynamicValues['_' + riskName + 'PreviousPolicyNumber'];
        }
        else //tryb nowy
        {
          BmDataObject.data.newModeData = new BonusMalusNewModel();
          BmDataObject.data.newModeData.history5YearsCount = personDynamicValues[riskName.toLowerCase() + 'CoverageHistoryWithinFiveYears'];
          BmDataObject.data.newModeData.last2YearsDamagesCount = personDynamicValues[riskName.toLowerCase() + 'TwoYearClaimsNumber'];
          BmDataObject.data.newModeData.last5YearsDamagesCount = personDynamicValues[riskName.toLowerCase() + 'FiveYearClaimsNumber'];
          BmDataObject.data.newModeData.noUfgData = false; //nie wiemy, a nie chcemy już komunikatu wyświetać
          BmDataObject.data.newModeData.ufgConnectionProblem = useGivenData;
        }
      };

      /**
       * mapuje jeden zestaw danych BM dla konkretnej osoby, pojazdu i ryzyka
       * @param  {object} bonusMalusDataSet
       * @param  {object} tempDataContainer
       */
      this._mapBonusMalusDataSet = function (bonusMalusDataSet, tempDataContainer, mapOptions) //eslint-disable-line consistent-return
      {
        if (bonusMalusDataSet.personRef === '' || bonusMalusDataSet.personRef === null)
        {
          sp2CommonHelper.throwException('Empty personRef in BonusMalusData');
        }
        if (bonusMalusDataSet.riskName === '' || bonusMalusDataSet.riskName === null)
        {
          sp2CommonHelper.throwException('Empty riskName in BonusMalusData');
        }
        if (bonusMalusDataSet.vehicleRef === '' || bonusMalusDataSet.vehicleRef === null)
        {
          sp2CommonHelper.throwException('Empty vehicleRef in BonusMalusData');
        }
        var personId = tempDataContainer._subjectIdMap[bonusMalusDataSet.personRef];
        var vehicleId = tempDataContainer._vehicleIdMap[bonusMalusDataSet.vehicleRef];

        if (typeof tempDataContainer.bonusMalus[personId] === 'undefined')
        {
          return false;
        }

        if (typeof tempDataContainer.bonusMalus[personId][vehicleId] === 'undefined')
        {
          return false;
        }

        tempDataContainer.bonusMalus[personId][vehicleId].risks[bonusMalusDataSet.riskName] = new BonusMalusDataObjectModel();
        var bmObject = tempDataContainer.bonusMalus[personId][vehicleId].risks[bonusMalusDataSet.riskName];
        if (mapOptions.initialLoad)
        {
          //przy ładowaniu wnisoku zakţadamy, że dane BM były wyświetlane
          tempDataContainer.bonusMalus[personId][vehicleId].popupShowed = true;
          tempDataContainer.bonusMalus[personId][vehicleId].dataWillChange = false;
        }

        lsnNg.forEach(bonusMalusDataSet, function (value, name)
        {
          if (name !== 'data')
          {
            bmObject[name] = value;
          }
          else
          {
            lsnNg.forEach(value, function (dataValue, dataName)
            { //bonusMalusDataSet.data
              var objectType = false;
              if (dataName === 'continuationModeData')
              {
                bmObject[name][dataName] = new BonusMalusContinuationModel();
                objectType = true;
              }
              if (dataName === 'newModeData')
              {
                bmObject[name][dataName] = new BonusMalusNewModel();
                objectType = true;
              }

              if (objectType)
              {
                angular.forEach(dataValue, function (bmDataValue, bmDataKey)
                {
                  bmObject[name][dataName][bmDataKey] = bmDataValue;
                });
              }
              else
              {
                bmObject[name][dataName] = dataValue;
              }
            });
          }
        });

        tempDataContainer.bonusMalus[personId][vehicleId].risks[bonusMalusDataSet.riskName] = bmObject;

        if (!bmObject.data.useGivenData && bmObject.data.newModeData !== null && bmObject.data.newModeData.ufgConnectionProblem)
        {
          //jeśli brak połączenia z UFG i nie są to ręcznie wpisane dane to szukamy czy nie mamy już danych tego usera
          bonusMalusHelper.tryFillGivenData(personId, vehicleId, tempDataContainer);
        }

      };
    };

    return new FromRestBonusMalusMapper();
  }
]);