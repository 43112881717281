/**
 * Kontroler searchboxa
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
  angular.module('ihestiaMenuSearchBox')
  .controller('ihestiaMenuSearchBoxCtrl', ['$scope', '$rootScope', 'ihestiaConfigHelper', '$window', '$urlMatcherFactory',
    function($scope, $rootScope, ihestiaConfigHelper, $window, $urlMatcherFactory) {
      /**
       * Event wyszukiwania
       * @return {[type]} [description]
       */
      $scope.search = function() {
        if (ihestiaConfigHelper.get('rest', 'AP_CALLER') === 'portal') {
          $rootScope.$broadcast('iHestiaMenuSearchBoxSearchInitialized', '');
        } else {
          var $urlMatcher = $urlMatcherFactory.compile('search/{searchPhrase:urlstring}');
          $window.location = ihestiaConfigHelper.getUrl('PORTAL_URL') + $urlMatcher.format({searchPhrase: ''});
        }
      };

    }
  ]);