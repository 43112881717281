/**
 * Model danych użytkownika
 * @return {[type]} [description]
 */
angular.module('ihestiaSsoBase.userModel', ['ihestiaSsoBase.characterModel'])
  .factory('IHestiaUserModelConstants', [function(){
    var CONSTANTS = {
      SALE_NET: 'SaleNet',
      EMPLOYEE: 'Employee',
      CLIENT: 'Client'
    };

    return CONSTANTS;
  }])
  .provider('IHestiaUserModel', ['IHestiaCharacterModelProvider',
    function(IHestiaCharacterModelProvider) {
      var IHestiaUserModelProvider = this;

      this.getModel = function(IHestiaCharacterModel)
      {
        if(!IHestiaCharacterModel)
        {
          IHestiaCharacterModel = IHestiaCharacterModelProvider.getModel();
        }

        var IHestiaUserModel = function() {
          var self = this,
            fieldMap = {
              login: 'login',
              name: 'name',
              firstName: 'firstName',
              userType: 'userType',
              pesel: 'pesel',
              accountStatus: 'accountStatus',
              lockReason: 'lockReason'
            };

          this.login = '';
          this.name = '';
          this.firstName = '';
          this.userType = '';
          this.pesel = '';
          this.accountStatus = '';
          this.lockReason = '';
          this.character = new IHestiaCharacterModel;
          this.characters = [];
          this.accountInfo = {};

          /**
           * Ustawiamy dane konta
           * @param {object} accountInfo [description]
           */
          this.setAccountInfo = function(accountInfo) {
            self.accountInfo = accountInfo ? accountInfo : {};
          };

          /**
           * Imię i nazwisko
           * @return {[type]} [description]
           */
          this.getShortName = function() {
            var shortNameParts = [];
            if(self.firstName) {
              shortNameParts.push(self.firstName);
            }
            if(self.name) {
              shortNameParts.push(self.name);
            }
            return shortNameParts.join(' ');
          };

          /**
           * Dodanie tożsamości do listy
           * @param {[type]} character [description]
           */
          this.addCharacter = function(character) {
            if (self.characters.indexOf(character) === -1) {
              self.characters.push(character);
            }
            return self;
          };

          /**
           * Zwracamy aktywna tożsamosc
           * @return {Character} [description]
           */
          this.getCurrentCharacter = function() {
            return self.character;
          };

          /**
           * Zwracamy wszystkie tozsamosci
           * @return {Character[]} [description]
           */
          this.getCharacters = function() {
            return self.characters;
          };

          /**
           * Zwracamy liczbe posiadanych tozsamosci
           * @return {int} [description]
           */
          this.getCharactersCount = function() {
            return self.characters.length;
          };

          /**
           * Ustawiamy dane na podstawie danych z usługi
           * @param {[type]} data [description]
           */
          this.setData = function(data) {
            // dane proste
            if(angular.isUndefined(data))
            {
              return false;
            }
            angular.forEach(fieldMap, function(attr, dataField) {
                self[attr] = data[dataField];
            });

            // charaktery
            self.character.setData(data.character);

            angular.forEach(data.characters, function(characterData) {
              var character;
              character = new IHestiaCharacterModel;
              character.setData(characterData);
              self.addCharacter(character);
            });

            return self;
          };
        };
        return IHestiaUserModel;
      };

      /**
       * Magic get
       * zwraca to, co wcześniej service
       * @type {Array}
       */
      this.$get = ['IHestiaCharacterModel',
        function(IHestiaCharacterModel) {
          var IHestiaUserModel = IHestiaUserModelProvider.getModel(IHestiaCharacterModel);
          return IHestiaUserModel;
        }
      ];

    }]);
