angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryDirectionOfTravelSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var ihestiaRestDictionaryDirectionOfTravelSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'directionoftravel';

      };
      return new ihestiaRestDictionaryDirectionOfTravelSvc();
    }
  ]);