angular.module('lsnBase.models')
.factory('LsnContactModelConstants', [function() {
    var ContactModelConstants = {};

    ContactModelConstants.CONTACT_TYPE_DOMO = 'domo';
    ContactModelConstants.CONTACT_TYPE_KOMO = 'komo';
    ContactModelConstants.CONTACT_TYPE_SLOZ = 'sloz';
    ContactModelConstants.CONTACT_TYPE_TFAX = 'tfax';
    ContactModelConstants.CONTACT_TYPE_TELX = 'telx';
    ContactModelConstants.CONTACT_TYPE_MAIL = 'mail';
    ContactModelConstants.CONTACT_TYPE_INNY = 'inny';
    ContactModelConstants.CONTACT_TYPE_MARK = 'mark';
    // słownik typów kontaktu
    ContactModelConstants.DICTIONARY_CONTACT_TYPE = {};
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_DOMO] = 'telefon stacjonarny';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_KOMO] = 'telefon komórkowy';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_SLOZ] = 'telefon służbowy';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_TFAX] = 'fax';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_TELX] = 'teleks';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_MAIL] = 'adres e-mail';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_INNY] = 'inny kontakt';
    ContactModelConstants.DICTIONARY_CONTACT_TYPE[ContactModelConstants.CONTACT_TYPE_MARK] = 'telefon kontaktowy';

    return ContactModelConstants;
  }]);