angular.module('salesPath2')
  .service('clientVerificationHelper', ['clientVerificationSvc',
    function(clientVerificationSvc) {
      var ClientVerificationHelper = function() {
        var self = this;

        /**
         * @type {string}
         */
        this.lastVerifiedPesel = null;

        /**
         * last response from clientVerificationSvc.verifyByPesel() for this.lastVerifiedPesel
         * @type {{
         *   markers?: ({
         *      id: string,
         *      marker: string
         *   })[] | null
         * }}
         */
        this.lastVerificationResult = null;

        /**
         * has app user been notified about "available life insurance"
         * @type {boolean}
         */
        this.userNotified = false;

        /**
         * call verification api and save result in this.lastVerifiedPesel and this.lastVerificationResult
         * @param {string} pesel
         * @return {boolean} true if service has been called
         */
        this.verifyPesel = function(pesel) {
          if (self.lastVerifiedPesel === pesel && self.lastVerificationResult !== null) {
            return false;
          }
          clientVerificationSvc.verifyByPesel({id: pesel})
            .then(function(resp) {
              self.lastVerifiedPesel = pesel;
              self.lastVerificationResult = resp.data;
              self.userNotified = false;
            }, angular.noop);
          return true;
        };

        /**
         * is ExtraLife allowed based on fetched markers
         * Based on https://ebok.atena.pl/browse/IHESTLIFE-5855?focusedCommentId=1690252&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-1690252
         * @return {boolean}
         */
        this.isExtraLifeAllowed = function() {
          return (self.userNotified || self.lastVerificationResult === null || !self.lastVerificationResult.markers) ? false
            : _.some(self.lastVerificationResult.markers, function(item) {
              return _.toLower(item.marker) === 'extralife' && item.id === self.lastVerifiedPesel;
            });
        };

        /**
         * set userNotified flag
         * @param {boolean} isNotified is user notified
         */
        this.setUserNotification = function(isNotified) {
          self.userNotified = isNotified;
        };

      };

      return new ClientVerificationHelper();
    }
  ]);