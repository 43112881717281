angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryPkdsSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryPkdsSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'pkds';
        this.sortBy = 'code';

        /**
         * Find pkd definition for a list of pkd codes
         * @param {string[]} pkdCodeList - pkd codes fo which definitions will be fetched
         */
        this.findDefinitionForCodes = function(pkdCodeList) {
          return this.post('pkdlist', pkdCodeList);
        };

        /**
         * Fetch subcodes for given pkd codes
         * @param {string[]} pkdCodeList - pkd codes for which sub pkd definitions will be fetched
         */
        this.findSubCodes = function(pkdCodeList) {
          return this.post('ehsubcodes', pkdCodeList);
        };

      };
      return new RestDictionaryPkdsSvc();
    }
  ]);
