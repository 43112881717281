angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaLifeInsurerChangeFlowConstants', [function() {
    var constants = {};
    constants.PERSON_SEARCH = 'PERSON_SEARCH';
    constants.CLIENT_EDIT = 'CLIENT_EDIT';
    constants.ADDITIONAL_DATA = 'ADDITIONAL_DATA';
    constants.CLOSE = 'CLOSE';
    constants.SUCCESS = 'SUCCESS';
    return constants;
  }])
  .service('ihestiaLifeInsurerChangeFlowHelper', ['ihestiaLifePersonSearchModalHelper', '$filter', 'ihestiaLifeClientEditModalHelper', 'IhestiaLifeInsurerChangeFlowConstants', 'ihestiaLifeInsurerChangeAdditionalDataModalHelper',
    function(ihestiaLifePersonSearchModalHelper, $filter, ihestiaLifeClientEditModalHelper, IhestiaLifeInsurerChangeFlowConstants, ihestiaLifeInsurerChangeAdditionalDataModalHelper) {
      var IhestiaLifeInsurerChangeFlowHelper = function() {

        /**
         * @typedef IhestiaLifeInsurerChangeFlowAction
         * @property {string} type
         * @property {any} value
         */

        var self = this;
        this.policyNumber = null;
        this.policyStartDate = null;
        /**
         * @param {string} [policyNumber]
         * @param {string} [policyStartDate]
         * @return {Promise<{subject: any, clauses: any[], operationData: {comment: string, uploadedDocumentsIds: string[]}} | null>}
         */
        this.open = function(policyNumber, policyStartDate) {
          this.policyNumber = policyNumber || null;
          this.policyStartDate = policyStartDate || null;
          return self._handleModalAction({type: IhestiaLifeInsurerChangeFlowConstants.PERSON_SEARCH});
        };

        /**
         * Open person search modal
         * @return {Promise<any>}
         */
        this.personSearch = function() {
          return ihestiaLifePersonSearchModalHelper.showModal({
            queryInfo: $filter('translate')('policyPersonSearchModal.queryInfo', {componentCode: 'Public'})
          }).then(self._handleModalAction);
        };

        /**
         *
         * @param {IhestiaLifeInsurerChangeFlowAction} action
         * @returns {Promise|Deferred.promise|PromiseLike<any>|Promise<any>}
         */
        this.clientEdit = function(action) {
          return ihestiaLifeClientEditModalHelper.showModal(action.value)
            .then(self._handleModalAction);
        };

        /**
         *
         * @param {IhestiaLifeInsurerChangeFlowAction} action
         * @returns {Promise|Deferred.promise|PromiseLike<any>|Promise<any>}
         */
        this.additionalData = function(action) {
          return ihestiaLifeInsurerChangeAdditionalDataModalHelper.showModal({
            subject: action.value,
            policyNumber: this.policyNumber,
            policyStartDate: this.policyStartDate
          })
            .then(self._handleModalAction);
        };

        /**
         *
         * @param {IhestiaLifeInsurerChangeFlowAction} action
         * @throws {IhestiaLifeInsurerChangeFlowAction} - when action with type CLOSE is passed, cancel modal flow
         * @returns {Promise}
         */
        this._handleModalAction = function(action) {
          var type = _.get(action, 'type', '');
          switch (type) {
            case IhestiaLifeInsurerChangeFlowConstants.PERSON_SEARCH:
              return self.personSearch();
            case IhestiaLifeInsurerChangeFlowConstants.CLIENT_EDIT:
              return self.clientEdit(action);
            case IhestiaLifeInsurerChangeFlowConstants.ADDITIONAL_DATA:
              return self.additionalData(action);
            case IhestiaLifeInsurerChangeFlowConstants.SUCCESS:
              return {
                type: IhestiaLifeInsurerChangeFlowConstants.SUCCESS,
                value: null
              };
            default:
              // eslint-disable-next-line no-throw-literal
              throw {
                type: IhestiaLifeInsurerChangeFlowConstants.CLOSE,
                value: null
              };
          }
        };
      };
      return new IhestiaLifeInsurerChangeFlowHelper();
    }]);
