angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorAdditionalHeaderMessageCtrl', ['$scope', '$window', 'ihestiaConfigHelper',
   function($scope, $window, ihestiaConfigHelper) {

      $scope.goToDashboardTask = function(caseId, taskId){
        $window.location = ihestiaConfigHelper.getUrl('DASHBOARD_CENTRAL_TASK_URL') + taskId;
      };

      $scope.init = function(){
        if(angular.isUndefined($scope.thread) && angular.isDefined($scope.tplData.thread)){
          $scope.thread = $scope.tplData.thread;
        }
      };

      $scope.init();
    }
  ]);