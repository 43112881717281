angular.module('ihestiaCommonDirectives.binaryUpload')
  .factory('BinaryUpload', ['BinaryUploadTransport', 'BinaryUploadOptions', 'FileModelForBinary', '$q', 'binaryUploadMessage', '$timeout',
    function(BinaryUploadTransport, BinaryUploadOptions, FileModelForBinary, $q, binaryUploadMessage, $timeout) {
      var BinaryUpload = function() {
        var self = this;

        this.files = [];

        this.options = new BinaryUploadOptions();
        this.currentIndex = 0;
        this.selectedDocumentType = null;
        this.sendingDeffer = null;
        this.uploadCanceled = false;
        this.transport = new BinaryUploadTransport();

        this.init = function(option) {
          self.files = [];
          self.options.set(option);

          self.clearCancelSendingFile();
        };

        this.removeAllFiles = function() {
          self.files.length = 0;
        };

        this.addFiles = function(data) {
          angular.forEach(data.filesData, function(dataF) {
            var file = new FileModelForBinary();
            if (self.selectedDocumentType !== null) {
              file.setDocumentType(self.selectedDocumentType);
            }
            file.setFileData(dataF);
            self.files.push(file);
          });
        };

        this.startSending = function() {
          self.sendingDeffer = $q.defer();

          if (self.files.length === 0) {
            self.sendingDeffer.reject(binaryUploadMessage.noFileSelected);
          }
          self.currentIndex = 0;
          $timeout(function(){
            self.processSending();
          }, 0);

          return self.sendingDeffer.promise;
        };

        this.processSending = function() {
          if (!self.uploadCanceled) {
            self.currentIndex = self.getNextIndexForUploadedFile();

            //jesli wszystkie pliki przeslano to dosaniemy null
            if (self.currentIndex !== null) {
              if (self.options.checkFilesBeforeSend) {
                self.sendInfoAboutFile();
              } else {
                self.sendFile();
              }
            } else {
              self.sendingDeffer.resolve(self.files);
            }
          }          
        };

        this.getNextIndexForUploadedFile = function(){
          var nextIndex = null;

          angular.forEach(self.files, function(file, idx){
            if(file.fileToSend()){
              nextIndex = idx;
            }
          });
          return nextIndex;
        };

        this.getProcessedFile = function() {
          return self.files[self.currentIndex];
        };

        this.sendInfoAboutFile = function() {
          var fileDataInfo = self.getProcessedFile().getInfoAboutFile(),
            processedFileAttributes = self.getProcessedFile().attributes;

          self.transport.sendInfoAboutFile(fileDataInfo, processedFileAttributes).then(function(tokenId) {
            if (!self.uploadCanceled) {
              self.sendFile(tokenId);
            }
          }, function(data) {
            self.onErrorWhenSending(data.data, data.text);
          });
        };

        this.setDocumentType = function(selectedDocumentType) {
          self.selectedDocumentType = selectedDocumentType;
        };

        this.onErrorWhenSending = function(data, messages, status) {
          status = status ? status : 'ERROR';

          var message = '';
          if (angular.isArray(messages)) {
            angular.forEach(messages, function(msg) {
              message += msg.text + ' ';
            });
          } else if (angular.isString(messages)) {
            message = messages;
          }

          self.getProcessedFile().convertToUploadedFile(status, message);
          self.processSending();
        };

        /**
         * [sendFile wysyla plik]
         * @return {[type]} [description]
         */
        this.sendFile = function(tokenId) {
          var url = angular.copy(self.options.url);
          if (angular.isString(tokenId)) {
            url += '/' + tokenId;
          }

          self.transport.sendFile(self.getProcessedFile(), url, self.options.chunkSize).then(function(fileServerId) {
            self.getProcessedFile().convertToUploadedFile('SUCCESS', null, fileServerId);
            self.processSending();
          }, function(data) {
            self.onErrorWhenSending(data.data, data.text);
          });
        };

        this.cancelSendingFile = function() {
          self.uploadCanceled = true;
          self.transport.cancel();
          self.sendingDeffer.reject('requestCancel');
        };

        this.clearCancelSendingFile = function() {
          self.uploadCanceled = false;
          self.transport.clearCancel();
        };
      };

      return BinaryUpload;
    }
  ]);