angular.module('ihestiaCommonDirectives.platformHelper')
  .provider('ihestiaPlatformHelper', ['ihestiaConfigHelperProvider',
    function(ihestiaConfigHelperProvider) {

      var self = this;

      /**
       * funkcja sprawdza czy aplikacja uruchomiona jest w trybie direct
       */
      this.isJupiter = function() {
        return ihestiaConfigHelperProvider.getConfigHelper().get('ihsgDefines', 'AG_SYMBOL_APP') === 'AppPortalJupiter';
      };

      /**
       * dla polisaonline
       * @return {Boolean}
       */
      this.isDirect = function() {
        return ihestiaConfigHelperProvider.getConfigHelper().get('BRAND') === 'DIRECT';
      };

      /**
       * biznesowo Jupiter i polisaonline jest łączne dla agentów i klientów jupiterowych
       * @return {Boolean} [description]
       */
      this.isJupiterOrDirect = function()
      {
        return self.isJupiter() || self.isDirect();
      };

      /**
       * Czy zadana platforma pochodzi z jakiegokolwiek jupitera (jupiter, jupiter plus, ...)
       * @param  {Sring}  platform platforma
       * @return {Boolean}
       */
      this.isAnyJupiterPlatform = function(platform) {
        if(!platform) {
          return false;
        } else {
          return ['jupiter', 'jupiter plus'].indexOf(platform.toLowerCase()) > -1;
        }
      };

      /**
       * Czy mamy do czynienia z platformą Jupiter Plus
       */
      this.isJupiterPlusPlatform = function(platform) {
        if(!platform) {
          return false;
        } else {
          return platform.toLowerCase() === 'jupiter plus';
        }
      };

      /**
       * Weryfikacja tego czy mamy do czynienia z agentem Jupiter
       */
      this.isJupiterAgent = function() {
        var ssoData = ihestiaConfigHelperProvider.getConfigHelper().get('sso');
        if(ssoData.userInfo.userType === 'System' || ssoData.userInfo.userType === 'Client')
        {
          return false;
        }
        else
        {
          return self.isAnyJupiterPlatform(ssoData.userInfo.character.ossContextInfo.Platform);
        }
      };

      this.isLifeContext = function() {
        return ihestiaConfigHelperProvider.getConfigHelper().get('ic', 'activeContext') === 'EHL';
      };


      /**
       * [description]
       * @return {[type]} [description]
       */
      this.$get = [function() {
        return self;
      }];
    }
  ]);
