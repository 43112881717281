angular.module('lsnBase.autocompleteOff')
// umieszczenie dyrektywy w elemencie <form> dodaje do formularza schowany element autocomplete, który gdy zaistnieje to chrome odczytuje parametry autocomplete on/off
// jeżeli w całym formularzu autocomplete ma być niedostępny to możemy zamiast dyrektywy wpisać w elemencie form autocomplete="false" i też powinno działać
// szczegóły w http://stackoverflow.com/a/30873633
.directive('autocompleteTurnOff', ['$timeout', function($timeout){
return {
    restrict: 'A',
    link: function(scope, elem){
        $timeout( function(){
            angular.forEach(elem.children().find('input'), function(input){
                var autocompleteVal = input.autocomplete.toLowerCase() === 'true' || input.autocomplete.toLowerCase() === 'on' ? 'On' : 'Off';
                input.autocomplete = autocompleteVal;
            });
            elem.append('<div style="display: none;"><input type="text" id="PreventChromeAutocomplete" name="PreventChromeAutocomplete" autocomplete="address-level4" /></div>');
        }, 0);
    }
};
}]);
