angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaReportsSvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaTagsSvc = function() {
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'report';
      };

      return new PzaTagsSvc();
    }]);