/**
 * All params for this controller are passed by ui-router state params.
 * Available params:
 * - message - text displayed in main info box
 * - endState - state array [state name, state params] or state name to which navigate after user presses end button
 * - pageTitleLabel - page title label for confirmation page
 */
angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaConfirmationPageCtrl', ['$stateParams', '$scope', '$timeout', '$state', '$location', '$filter', 'blockUiHelper',
    function($stateParams, $scope, $timeout, $state, $location, $filter, blockUiHelper) {
      $scope.tplData = {
        message: null
      };
      $scope.init = function() {
        blockUiHelper.hideBlockUi();
        $scope.tplData.message = _.isArray($stateParams.message) ? $filter('translate')($stateParams.message[0], $stateParams.message[1])
          : $stateParams.message || '';

        if ($stateParams.pageTitleLabel && _.isArray($stateParams.pageTitleLabel)) {
          $timeout(function() {
            $scope.$emit('setPageTitle', {
              pageTitleLabel: $stateParams.pageTitleLabel
            });
          }, 0);
        }
      };

      $scope.end = function() {
        var state = $stateParams.endState;
        if (_.isString(state)) {
          $state.go(state);
        } else if (_.isArray(state)) {
          $state.go(state[0], state[1], state[2]);
        } else {
          $location.url('/');
        }
      };

      $scope.init();
    }]);
