angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaAnniversaryDocumentsModalHelper', ['LsnModalHelper', '$filter', '$q', 'policiesPrintoutsV2Svc', 'fileServerSvc',
    function(LsnModalHelper, $filter, $q, policiesPrintoutsV2Svc, fileServerSvc) {
      var AnniversaryDocumentsModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        this.modalOptions = {
          size: 'sm',
          title: null,
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/anniversaryDocuments/anniversaryDocumentsModal.tpl.html',
          okBtnName: null,
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };

        this.cancelPromise = null;

        /**
         *
         * @type {{documents: {code: string, name: string}[]: null}}
         */
        this.modalData = {
          documents: null,
          policyNumber: null
        };

        this.setOptions(this.modalOptions);

        this.tplData = {
          loading: false
        };

        this.onChooseDocument = function(fileId) {
          if (self.cancelPromise) {
            self.cancelPromise.reject('newRequest');
          }
          self.cancelPromise = $q.defer();
          self.tplData.loading = true;
          return self.printAnniversaryDocumentFromFrp(fileId, self.modalData.policyNumber, self.cancelPromise.promise)
            .then(function() {
              self.tplData.loading = false;
              self.setPromiseAsResolved();
              return self.hideModal();
            }, function() {
              self.tplData.loading = false;
            }).finally(function() {
              self.cancelPromise = null;
            });
        };

        this.init = function() {
          this.setOptions(_.merge({}, self.modalOptions, {
            title: $filter('translate')('anniversaryDocuments.modalTitle', {
              componentCode: 'iKonto',
              params: [self.modalData.policyNumber]
            })
          }));
          self.tplData.documents = self.modalData.documents;
        };

        this.cancel = function() {
          if (self.cancelPromise) {
            self.cancelPromise.reject('cancel');
          }
          self.setPromiseAsRejected();
          return self.hideModal();
        };

        /**
         *
         * @param {string} fileId
         * @param {string} policyNumber
         * @param {Promise<any>} [cancelPromise]
         * @return {PromiseLike<any> | Promise<any>}
         */
        this.printAnniversaryDocumentFromFrp = function(fileId, policyNumber, cancelPromise) {
          var httpParams = null;
          if (cancelPromise) {
            httpParams = {
              timeout: cancelPromise.then(lsnNg.noop, lsnNg.noop)
            };
          }
          return self._getTokenForFileId(fileId, policyNumber, httpParams)
            .then(function(res) {
              // token returned as string
              return self._getFileForToken(res.data, httpParams);
            }, function(err) {
              throw err;
            })
            .then(function(res) {
              var defaultFilename = $filter('translate')('fileNameDefaults.policyPrint', {
                componentCode: 'Public',
                params: {
                  policyNumber: policyNumber
                }
              }) + '.pdf';
              self.saveFile(res, defaultFilename);
            }, lsnNg.noop);
        };

        this.saveFile = function(result, defaultFileName, mime) {

          var filename; //jak nie dostaniemy nazwy z usługi
          var contentDisposition = result.headers('Content-Disposition');

          if (_.isString(contentDisposition)) {
            filename = self._getFilenameFromContentDisposition(contentDisposition) || defaultFileName;
          } else {
            filename = defaultFileName;
          }

          var blob = new Blob([result.data], {
            type: mime || self.defaultMimeType
          });
          saveAs(blob, filename);
          return result;
        };

        /**
         *
         * Content disposition can contain several parameters delimited by ';'
         * for now using filename= over filename*=
         * @param contentDisposition
         * @returns {string}
         * @private
         */
        this._getFilenameFromContentDisposition = function(contentDisposition) {
          var encodedFilename = _.chain(_.split(contentDisposition, ';'))
            .map(_.trim)
            .find(function(part) {
              return _.startsWith(part, 'filename=');
            })
            .replace(/filename=|"/g, '')
            .value();
          return decodeURIComponent(encodedFilename);
        };

        /**
         * Fetch token for file id for FRP service
         * @param {string} fileId
         * @param {string} policyNumber
         * @param {any} httpParams
         * @returns {Promise<{data: string}>}
         * @private
         */
        this._getTokenForFileId = function(fileId, policyNumber, httpParams) {
          return policiesPrintoutsV2Svc.post('{0}/{1}/token'.format(policyNumber, fileId), null, null, null, httpParams);
        };

        /**
         * Fetch file as byte array for token
         * @param {string} token
         * @param {any} params
         * @returns {Promise<any>}
         * @private
         */
        this._getFileForToken = function(token, params) {
          var httpParams = {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'blob'
          };
          if (params && params.timeout) {
            httpParams.timeout = params.timeout;
          }
          return fileServerSvc.get(token, null, '', null, null, httpParams);
        };
      };
      return new AnniversaryDocumentsModalHelper();
    }]);
