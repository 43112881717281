angular.module('lsnBase.models')
  .factory('LsnBonusMalusObjectModelV1', [
    function()
    {

      var LsnBonusMalusObjectModelV1 = function()
      {
        this.risks = {
          AC: null,
          OC: null
        };

        this.personId = null; // int, clientId osoby
        this.vehicleId = null; // int, clientId pojazdu
        this.popupShowed = false; // czy poup BM był już wyświetlony, niezależnie od aktualizacji danych BM
        this.dataWillChange = true; // czy spodziewamy się aktualizacji danych BM
        this.clientDataVerified = false; // zweryfikowano dane klienta po informacji o braku historii w UFG
        this.vehicleSaved = false; // czy przed chwilą zapisano pojazd, ma być pokazanie popupu BM po zapisaniu pojazdu
      };

      return LsnBonusMalusObjectModelV1;
    }
  ]);