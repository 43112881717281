angular.module('ihestiaRestServicesBase')
  .factory('ihestiaExtraLifeSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {

    /**
     * API doc:
     * https://gist.github.com/lsnpatryks/fdc2210976afab04e42d7ccc08054bb6
     * @constructor
     */
    var ExtraLifeSvc = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      var self = this;

      this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{subResource}{id}';
      this.resource = 'secured';
      this.businessArea = 'extralife';

      /**
       * get extraLife info by pesel
       * @param {string} pesel
       * @param {Object} [httpParams]
       * @return {Promise}
       */
      this.infoByPesel = function(pesel, httpParams) {
        return self.post(pesel, null, 'pesel', null, null, angular.extend({allowedStatuses: [404, 503]}, httpParams || {}));
      };

      /**
       * post answer
       * @param {string} uuid
       * @param {string} answerCode
       * @param {Object} [httpParams]
       * @return {Promise}
       */
      this.postAnswer = function(uuid, answerCode, httpParams) {
        var body = {
          code: answerCode
        };
        return self.post(uuid, body, 'answer', null, null, httpParams);
      };

      /**
       * delete answer
       * @param {string} uuid
       * @param {Object} [httpParams]
       * @return {Promise}
       */
      this.deleteAnswer = function(uuid, httpParams) {
        return self.remove(uuid, 'answer', null, null, httpParams);
      };

    };

    return new ExtraLifeSvc();
  }])
;