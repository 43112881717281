angular.module('lsnBase.errorHandler')
  .factory('LsnCommonErrorModel', [
    function() {
      var LsnCommonError = function(excData, knownBug) {
        var self = this;

        this.code = null;
        this.message = null;
        this.id = null;
        this.guid = null;
        this.stack = null;
        this.data = {};
        this.lastSvcCalls = null;
        this.lastUiRouterStates = null;
        this.frontConfig = null;
        this.applicationCode = null;
        this.navigator = null;
        this.knownBug = knownBug === true;

        /**
         * Init
         */
        this.init = function() {
          self._setData(excData);
        };

        /**
         * Uzupelniamy model o dane ze zwyklego obiektu/stringa
         * @param {object|string} excData [description]
         */
        this._setData = function(excData) {
          if (typeof excData === 'string') {
            self.message = excData;
          } else if (typeof excData === 'object' && excData !== null) {
            if(excData instanceof Error) {
              // najwyrazniej po obiekcie typu Error nie da się iterowac
              self.message = excData.message;
              self.stack = excData.stack;
              if (typeof excData.guid !== 'undefined') {
                self.guid = excData.guid;
              }
              if (typeof excData.data !== 'undefined') {
                self.data = excData.data;
              }
            } else {
              // zwykle dane, ladujemy je do naszego obiektu
              angular.forEach(excData, function(paramData, paramKey) {
                if (typeof self[paramKey] !== 'undefined') {
                  self[paramKey] = angular.copy(paramData);
                }
              });
            }
          }
        };
        
        /**
         * zwraca "czysty" obiekt JS z danymi wyjatku
         * @returns {object}
         */
        this.toPlainObject = function() {
          var plainObject = {};
          angular.forEach(self, function(attributeValue, attribute){
            if(!angular.isFunction(attributeValue)) {
              plainObject[attribute] = angular.isObject(attributeValue) ? angular.toJson(attributeValue) : attributeValue;
            }
          });
          return plainObject;
        };

        this.init();
      };

      return LsnCommonError;
    }
  ]);