//modal dla jupitera
angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatoSelectSubjectCtrl', ['$scope', 'ihestiaIkomunikatorSelectSubjectHelper', 'ihestiaIkomunikatorActionHelper', 'iKomunikatorConstants', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaPlatformHelper',
    function($scope, ihestiaIkomunikatorSelectSubjectHelper, ihestiaIkomunikatorActionHelper, iKomunikatorConstants, ihestiaIkomunikatorUserTypeHelper, ihestiaPlatformHelper) {

      $scope.showCategory = false;
      $scope.categoryLoading = false;

      $scope.init = function() {
        ihestiaIkomunikatorSelectSubjectHelper.init();
        $scope.tplData = ihestiaIkomunikatorSelectSubjectHelper.tplData;

        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIkomunikatorSelectSubject', function(event) {
          $scope.goToNewMessageBySubject();
          event.preventDefault();
        });
      };

      $scope.subjectChanged = function() {
        $scope.showCategory = $scope.isCategoryVisible();
        if($scope.showCategory &&
          $scope.tplData.selectedSubjectId !== -1 && !$scope.categoryLoading && $scope.tplData.categoryList === null) {
          // listę podtematów ładujemy tylko raz, bo tylko dla obsługi polisy
          $scope.categoryLoading = true;
          ihestiaIkomunikatorSelectSubjectHelper.getCategories($scope.tplData.selectedSubjectId).then(function(){
            $scope.categoryLoading = false;
          }, function(){
            $scope.categoryLoading = false;
          });
        }
      };

      $scope.isCategoryVisible = function() {
        if (((ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() && ihestiaPlatformHelper.isJupiterOrDirect()) || (ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext())) &&
          $scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_POLICY) {
          return true;
        } else {
          return false;
        }
      };

      $scope.goToNewMessageBySubject = function() {
        var categoryVisible = $scope.isCategoryVisible();

        if ($scope.tplData.selectedSubjectId === -1) {
          $scope.tplData.errorNotSelectedSubjectId = true;
        } else if(categoryVisible && $scope.tplData.selectedCategoryId === -1) {
          $scope.tplData.errorNotSelectedSubjectId = false;
          $scope.tplData.errorNotSelectedCategoryId = true;
        } else {
          $scope.tplData.errorNotSelectedSubjectId = false;
          $scope.tplData.errorNotSelectedCategoryId = false;
          ihestiaIkomunikatorSelectSubjectHelper.hideModal();

          if ($scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_CONTACT_CLIENT) {
            ihestiaIkomunikatorActionHelper.showSearchPerson({
              contextForNewMessage: {
                subjectId: $scope.tplData.selectedSubjectId,
                settings: {
                  subjectIsFirst: true,
                  subjectContextItemsAvailable: false
                }
              }
            });

          } else if ($scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_VERFICATION_CLIENT) {
            ihestiaIkomunikatorActionHelper.showSearchPerson({
              context: {
                newRecipientOkButtonName: 'Inny klient'
              },
              contextForNewMessage: {
                subjectId: $scope.tplData.selectedSubjectId,
                channels: [iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT],
                settings: {
                  otherRecipientInContextClient: true,
                  subjectIsFirst: true
                },
                ifNewRecipient: {
                  settings: {
                    clearForcedNotificationChannelName: true,
                    subjectContextItemsAvailable: false,
                    clientInfoTypePesel: true,
                    showElements: {
                      recipients: false
                    }
                  }
                },
                ifClientSearched: {
                  settings: {
                    subjectContextItemsAvailable: false,
                    clientInfoTypePesel: false,
                    showElements: {
                      recipients: true
                    }
                  }
                }
              }
            });
          } else if ($scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_POLICY) {
            ihestiaIkomunikatorActionHelper.showSearchPerson({
              context: {
                newRecipientOkButtonName: 'Inny klient'
              },
              contextForNewMessage: {
                subjectId: $scope.tplData.selectedSubjectId,
                categoryId: $scope.tplData.selectedCategoryId,
                channels: [iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT],
                settings: {
                  subjectContextItemsAvailable: true,
                  otherRecipientInContextClient: true,
                  subjectIsFirst: true
                },
                ifNewRecipient: {
                  settings: {
                    clearForcedNotificationChannelName: true,
                    subjectContextItemsAvailable: false,
                    additionalInputAvailable: true,
                    clientInfoTypePesel: true,
                    showElements: {
                      recipients: false
                    }
                  }
                },
                ifClientSearched: {
                  settings: {
                    subjectContextItemsAvailable: true,
                    clientInfoTypePesel: false,
                    showElements: {
                      recipients: true
                    }
                  }
                }
              }
            });
          } else if ($scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_PRINT_ORDER || $scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_RECLAMATION || $scope.tplData.selectedSubjectId === iKomunikatorConstants.SUBJECT_ID_COMMISSION) {
            ihestiaIkomunikatorActionHelper.showNewMesageModal({
              context: {
                subjectId: $scope.tplData.selectedSubjectId,
                channels: [iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT],
                settings: {
                  showElements: {
                    channels: true
                  },
                  subjectIsFirst: true,
                  recipientMounted: false,
                  subjectContextItemsAvailable: false
                }
              }
            });
          }
        }

      };

      $scope.init();
    }
  ]);