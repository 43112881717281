angular.module('ihestiaCommonDirectives.filters')
  .filter('prettyDiffDates', ['$filter', function($filter){
    var prettyDiffDatesFunction = function(input, endDate) {

      if(!input)
      {
        return '';
      }

      if(!endDate)
      {
        endDate = new XDate(); //jak nie ma podanej daty od której liczymy to dajemy czas systemowy
      }
      else
      {
        endDate = new XDate(endDate);
      }


      var diffDate = new XDate(input);
      var diffDays = endDate.diffDays(diffDate);
      var diffHours = endDate.diffHours(diffDate);
      var diffMinutes = endDate.diffMinutes(diffDate);

      var prettyDesc = null; // NOSONAR
      var isNegative = true;
      var minutesLeft;

      if (diffDays >= 8) {
        prettyDesc = new XDate(input).toString('yyyy-MM-dd HH:mm'); //ponad 7 dni, więc data
      } else {
        if (diffDays < 0) {
          //oznaczamy sobie, że czas minął i bierzemy wartość bezwzględną do obliczeń
          isNegative = false;
          diffDays = -diffDays;
          diffHours = -diffHours;
          diffMinutes = -diffMinutes;
        }
        if (diffDays >= 1) {
          //między 1-7.9999 dni
          prettyDesc = Math.floor(diffDays);
          if (diffDays >= 2) {
            prettyDesc += ' ' + $filter('translate')('days', {componentCode: 'Public'});
          } else {
            prettyDesc += ' ' + $filter('translate')('day', {componentCode: 'Public'});
          }
        } else if (diffHours >= 1) { //poniżej jednego dnia
          prettyDesc = (Math.floor(diffHours) + ':');
          minutesLeft = (Math.floor(diffMinutes) % 60) + '';
          prettyDesc += minutesLeft.padLeft('0', 2);
        } else { //poniżej godziny
          if(diffMinutes >= 1)
          {
            prettyDesc = Math.floor(diffMinutes) + ' min';
          }
          else //poniżej minuty
          {
            prettyDesc = 'teraz';
          }
        }
        if (isNegative) {
          prettyDesc = 'teraz'; //ignorujemy ujemne
        }
      }
      return prettyDesc;
    };

    prettyDiffDatesFunction.$stateful = true; // chcemy odświeżać jak leci czas systemowy

    return prettyDiffDatesFunction;
}]);