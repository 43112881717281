angular.module('ihestiaWidgets.dashboard.euaChecker')
  .factory('ihestiaEuaCheckerModalHelper', ['iHestiaCommonModalHelper',
    function(iHestiaCommonModalHelper) {
      var EuaCheckerModalHelper = function() {
        var self = this;

        this.modalData = {};

        /**
         * Czyścimy dane pomocnicze powiązane z modalem
         * zazwyczaj odpalane na $destroy modala
         * @return {EuaCheckerModalHelper} self
         */
        this.clearData = function() {
          self.modalData = {};

          return self;
        };

        /**
         * Zwracamy liczbę zadań z umowamy agencyjnymi
         * @return {[type]} [description]
         */
        this.getEuaTasksCount = function() {
          return self.modalData.documentToAcceptCount ? self.modalData.documentToAcceptCount : 0;
        };


        /**
         * Pokazujemy modal wyboru raportu
         * @param  {Object} modalData
         * @return {EuaCheckerModalHelper} self
         */
        this.showEuaModal = function(modalData) {
          self.modalData = modalData;
          iHestiaCommonModalHelper.showModal('ihestiaDashboardEuaChecker');

          return self;
        };

        /**
         * Zamykamy modal
         * @return {EuaCheckerModalHelper} self
         */
        this.hideEuaModal = function() {
          self.clearData();
          iHestiaCommonModalHelper.hideModal('ihestiaDashboardEuaChecker');

          return self;
        };

      };

      return new EuaCheckerModalHelper();
    }]);