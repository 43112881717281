angular.module('ihestiaCommonDirectives.filters')
  .filter('addressStreetNumber', ['EstateModelConstants', '$filter', function(EstateModelConstants, $filter){
      var AddressStreetNumberFilter = function(address, type) {
        var streetNumber = '';
        if(typeof address === 'undefined' || !angular.isObject(address))
        {
          return '';
        }

        var showHouse = address.house || address.house === 0;
        if(showHouse)
        {
          streetNumber += address.house;
        }

        if(address.apartment || address.apartment === 0) {
          if(!showHouse && (type === EstateModelConstants.ESTATE_TYPE_HOUSE_UNDER_CONSTRUCTION || type === EstateModelConstants.ESTATE_TYPE_SUMMER_HOUSE)) {
            streetNumber += $filter('translate')('plotNumber', {componentCode: 'Public'}) + ': ' + address.apartment;
          } else if(type === EstateModelConstants.ESTATE_TYPE_FLAT || !type) {
            if(streetNumber !== '')
            {
              streetNumber += '/';
            }
            streetNumber += address.apartment;
          }
        }

        return streetNumber;
      };

      AddressStreetNumberFilter.$stateful = true;

      return AddressStreetNumberFilter;
  }]);