/**
 * Kontroler searchboxa
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
  angular.module('ihestiaMenuBase')
  .controller('menuIcContextCtrl', ['$scope', 'ihestiaConfigHelper', 'ihestiaSessionCurrenticSvc', 'ihestiaSsoBaseInfoHelper', '$window', '$rootScope', 'ihestiaCrossTabHelper', 'lsnConfirmModalHelper',
    function($scope, ihestiaConfigHelper, ihestiaSessionCurrenticSvc, ihestiaSsoBaseInfoHelper, $window, $rootScope, ihestiaCrossTabHelper, lsnConfirmModalHelper) {

      $scope.contextList = [
      {
        code: 'EHN',
        name: 'Mienie',
        active: false,
        icon: 'icon-house-2'
      },
      {
        code: 'EHL',
        name: 'Życie',
        active: false,
        icon: 'icon-heart-1'
      }
      ];

      $scope.availableContextList = [];

      $scope.activeContext = null;

      $scope.popoverVisible = false;

      $scope.user = ihestiaSsoBaseInfoHelper.getUser();
      $scope.character = $scope.user.getCurrentCharacter();

      $scope.init = function(){
        if($scope.character && $scope.character.ossContextInfo && $scope.character.ossContextInfo.AvailableInsuranceCompanies) {
          var availableInsuranceCompanies = JSON.parse($scope.character.ossContextInfo.AvailableInsuranceCompanies);
          angular.forEach($scope.contextList, function(context){
            angular.forEach(availableInsuranceCompanies, function(insuranceCompanyCode){
              if(context.code === insuranceCompanyCode) {
                $scope.availableContextList.push(context);
              }
            });
          });
        }

        angular.forEach($scope.availableContextList, function(context){
          if(context.code === ihestiaConfigHelper.get('ic', 'activeContext')) {
            context.active = true;
            $scope.activeContext = context;
          }
        });
      };

      /**
       * [hidePopover description]
       * @return {[type]} [description]
       */
      $scope.togglePopoverVisibility = function() {
        if ($scope.popoverVisible) {
          $scope.hidePopover();
        } else {
          $scope.showPopover();
        }
      };

      /**
       * [hidePopover description]
       * @return {[type]} [description]
       */
      $scope.hidePopover = function() {
        $scope.popoverVisible = false;
      };

      /**
       * [showPopover description]
       * @return {[type]} [description]
       */
      $scope.showPopover = function() {
        $scope.popoverVisible = true;
      };

      $scope.selectContext = function(context) {
        if(context.code !== $scope.activeContext.code) {
          lsnConfirmModalHelper.showConfirmModal({
              title: 'Zmiana kontekstu towarzystwa',
              size: 'sm',
              okBtnName: 'Zmień',
              cancelBtnName: 'Anuluj',
              content: 'Czy na pewno zmienić kontekst towarzystwa na "' + context.name + '"?'
            }).then(function(){
              $scope.hidePopover();
              $rootScope.$broadcast('showBlockUi');
              ihestiaSessionCurrenticSvc.put(null, JSON.stringify(context.code)).then(function(){
                ihestiaCrossTabHelper.sendMessage({
                  code: 'icChanged',
                  attrs: {
                    icCode: context.code
                  }
                });
                $window.location = '/';
              }, function(){
                $rootScope.$broadcast('hideBlockUi');
              });
          }, function(){
            $scope.hidePopover();
          });
        }
      };

      $scope.init();

    }
  ]);