angular.module('ihestiaRestServicesBase')

  /**
     * Ustawienia
     * @return {[type]} [description]
     */
  .factory('IHestiaRestServiceAbstractDefMethods', ['ihestiaConfigHelper', 'ihestiaRestServiceProviderAtena', '$injector', 'ihestiaRestMockHelper', 'ihestiaCommonErrorHandler',
    function(ihestiaConfigHelper, ihestiaRestServiceProviderAtena, $injector, ihestiaRestMockHelper, ihestiaCommonErrorHandler) {
      var IHestiaRestServiceAbstractDefMethods = function() {
        var self = this,
          providers = {
            defaultProvider: ihestiaConfigHelper.get('rest', 'PROVIDER'),
            atena: 'Atena',
            mock: 'Mock'
          };

        this.basePattern = '{host}{port}{path}/{apiString}/{businessArea}/{apiVer}/{resource}{id}{subResource}';
        this.businessArea = '';
        this.resource = '';
        this.apiVersion = ihestiaConfigHelper.get('rest', 'VERSION');
        this.host = ihestiaConfigHelper.get('rest', 'HOST');
        this.port = ihestiaConfigHelper.get('rest', 'PORT');
        this.path = '';
        this.apiString = 'api';
        this.additionalHeaders = {};
        this.providerProtected = false;
        this.sortBy = 'id';
        this.count = 100;
        this.pageSize = 100;
        this.processType = '';
        this.dataType = 'json'; //json/form
        this.errorCallback = [];

        this.pageStyle = true; //czy pobieranie jest stronicowane

        this.searchesPostfix = 'searches';

        this.mockMode = null; //określany na podstawie config (none/service/lsn)

        this.grid = {
          top: 500,
          skip: 0,
          sortBy: '',
          sortDirection: 'ASC',
          count: ihestiaConfigHelper.get('rest', 'GRID_COUNT')
        };

        this.setProvider = function(name) {
          if (typeof name === 'undefined') {
            name = providers.defaultProvider;
          }
          self.provider = ihestiaRestServiceProviderAtena;

          self.provider.setAdditionalHeaders(self.additionalHeaders);
        };
        self.setProvider();

        /**
         * Pobranie serwisu w konrketnej wersji API
         * @param  {String} apiVersion (np. 'v1')
         * @return {undefined}
         */
        this.ver = function(apiVersion, doNotThrowError) {
          apiVersion = apiVersion ? apiVersion : 'v1';
          if (self.apiVersions) {
            if (self.apiVersions[apiVersion]) {
              return self.apiVersions[apiVersion];
            } else {
              if(doNotThrowError){
                ihestiaCommonErrorHandler.throwException('No api version ' + apiVersion + ' for service' + self.businessArea + '/' + self.resource);
              }
              return null;
            }
          } else {
            return self;
          }
        };

        /**
         * Pobieranie rekordu
         *
         * @param {String|Integer} id
         * @param {*} filters
         * @param {String} subResource
         * @returns {*}
         */
        this.get = function(id, filters, subResource, callback, errorCallback, httpParams) {
          var query = self._getQuery(filters),
            url = self._getServiceUrl(id, subResource) + query;
          return self.call(url, 'GET', null, callback, errorCallback, httpParams);
        };

        /**
         * Tworzenie nowego rekordu
         *
         * @param {*} data
         * @returns {*}
         */
        this.post = function(id, data, subResource, callback, errorCallback, httpParams) {
          var url = self._getServiceUrl(id, subResource);
          return self.call(url, 'POST', data, callback, errorCallback, httpParams);
        };

        /**
         * Aktualizacja rekordu
         *
         * @param {*} data
         * @returns {*}
         */
        this.put = function(id, data, subResource, callback, errorCallback, httpParams) {
          var url = self._getServiceUrl(id, subResource);
          return self.call(url, 'PUT', data, callback, errorCallback, httpParams);
        };

        /**
         * Aktualizacja rekordu
         *
         * @param {*} data
         * @returns {*}
         */
        this.patch = function(id, data, subResource, callback, errorCallback, httpParams) {
          var url = self._getServiceUrl(id, subResource);
          return self.call(url, 'PATCH', data, callback, errorCallback, httpParams);
        };

        /**
         * Kasowanie rekordu
         *
         * @param {String|Integer} id
         * @returns {*}
         */
        this.remove = function(id, subResource, callback, errorCallback, httpParams) {
          return self.removeWithBody(id, null, subResource, callback, errorCallback, httpParams);
        };

        /**
         * Kasowanie części zawartości rekordu (wysyłamy parametry w body)
         *
         * @returns {*}
         */
        this.removeWithBody = function(id, data, subResource, callback, errorCallback, httpParams) {
          var url = self._getServiceUrl(id, subResource);
          return self.call(url, 'DELETE', data, callback, errorCallback, httpParams);
        };

        this.findByParams = function(params) {
          if (typeof params.limit !== 'undefined') {
            params.count = params.limit; //zapewnienie wstecznej kompatybilności
          }

          var defaultBase = {
            sortBy: self.sortBy,
            sortDirection: 'ASC',
            field: 'value'
          };

          //offset style
          var defaultOffsetStyle = {
            top: 500, //top jako wielkość zasobu
            count: self.count,
            skip: 0,
            dynamicFilters: {}
          };

          //page style
          var defaultPageStyle = {
            pageSize: self.pageSize,
            top: 0 //top równy 0 jest ignorowany
          };

          var settings = $.extend({}, defaultBase);

          if (self.pageStyle) {
            $.extend(settings, defaultPageStyle, {
              pageSize: params.count
            });
          } else {
            $.extend(settings, defaultOffsetStyle, {
              top: params.top ? params.top : defaultOffsetStyle.top,
              skip: (params.offset || params.offset === 0) ? params.offset : defaultOffsetStyle.offset,
              count: (params.count || params.count === 0) ? params.count : defaultOffsetStyle.count,
              dynamicFilters: params.dynamicFilters ? params.dynamicFilters : defaultOffsetStyle.dynamicFilters
            });
          }

          //wspólne dla obu trybów
          $.extend(settings, {
            sortBy: params.sortBy,
            sortDirection: params.sortDirection,
            field: params.field
          });

          // trochę się boję, ale dodaję uzupełnianie pageSize i top
          // (jeśli oczywiście w ogóle są)
          if (params.top) {
            settings.top = params.top;
          }
          if (params.pageSize) {
            settings.pageSize = params.pageSize;
          }

          //wszystkie niestandardowe atrybuty przepisujemy
          angular.forEach(params.additionalParams, function(value, key) {
            settings[key] = value;
          });

          //mamy settingsy
          var queryObject = angular.copy(settings);
          delete queryObject.field;

          if (self.pageStyle) {
            queryObject.parameter = {};
            if (angular.isObject(params.query)) {
              $.each(params.query, function(fieldName, queryValue) {
                queryObject.parameter[fieldName] = queryValue;
              });
            } else {
              queryObject.parameter[settings.field] = params.query;
            }
          }

          return self.post(params.id, angular.toJson(queryObject), self.searchesPostfix, params.callback, params.errorCallback, params.httpParams);
        };


        /**
         * pelnotekstowe wyszukiwanie
         *
         * @param {mixed} query
         * @param {*} callback
         * @param {Integer} limit
         * @param {Integer} offset
         * @param {String} sortBy
         * @param {String} sortDicrection
         * @param {mixed} field
         */
        this.find = function(query, callback, limit, offset, sortBy, sortDirection, field, errorCallback, httpParams) {
          return self.findByParams({
            query: query,
            callback: callback,
            count: limit,
            offset: offset,
            sortBy: sortBy,
            sortDirection: sortDirection,
            field: field,
            errorCallback: errorCallback,
            httpParams: httpParams
          });
        };

        /**
         * przepuszczamy elementy responsu z usługi przez model
         * @param  {array} items
         * @param  {string} itemModelName
         * @return {array}
         */
        this.parseItemsWithModel = function(items, itemModelName) {
          var parsedItems = [];
          angular.forEach(items, function(value, key) {
            var ItemModel = $injector.get(itemModelName);
            var parsedValue = new ItemModel();
            parsedValue.setData(value);
            parsedItems[key] = parsedValue;
          });
          return parsedItems;
        };


        this.gridGet = function(stateid, guid, filters, itemModelName, callback, errorCallback, httpParams) {
          var parseItemsCallback;
          if (typeof itemModelName === 'undefined' || itemModelName === null) {
            parseItemsCallback = callback;
          } else {
            //zamieniamy zawartość items w intancje modeli
            parseItemsCallback = function(result) {
              result.data.items = self.parseItemsWithModel(result.data.items, itemModelName);
              callback(result);
            };
          }

          var searchesPath = self.searchesPostfix;
          if (typeof stateid !== 'undefined' && stateid !== null) {
            searchesPath = stateid + '/' + searchesPath;
          }

          return self.get(searchesPath, filters, guid, parseItemsCallback, errorCallback, httpParams);
        };

        /**
         * wyszukiwanie dla grida
         * @param  {[type]}   dynamicFilters       [description]
         * @param  {Function} callback             [description]
         * @param  {[type]}   filters              [description]
         * @param  {[type]}   errorCallback [description]
         * @return {[type]}                        [description]
         */
        this.gridSearch = function(id, dynamicFilters, callback, filters, errorCallback, itemModelName, httpParams) {
          if (typeof id === 'undefined') {
            id = null;
          }
          if (typeof dynamicFilters === 'undefined') {
            dynamicFilters = {};
          }
          if (typeof filters === 'undefined') {
            filters = {};
          }
          var queryFilters = angular.extend({}, self.grid, filters);
          queryFilters.dynamicFilters = dynamicFilters;

          var parseItemsCallback;
          if (typeof itemModelName === 'undefined') {
            parseItemsCallback = callback;
          } else {
            //zamieniamy zawartość items w intancje modeli
            parseItemsCallback = function(result) {
              var parsedItems = [];
              angular.forEach(result.data.items, function(value, key) {
                var ItemModel = $injector.get(itemModelName);
                var parsedValue = new ItemModel();
                parsedValue.setData(value);
                parsedItems[key] = parsedValue;
              });

              result.data.items = parsedItems;
              callback(result);
            };
          }

          return self.post(id, angular.toJson(queryFilters), self.searchesPostfix, parseItemsCallback, errorCallback, httpParams);
        };

        /**
         * sprawdzanie, czy zasob istnieje (za pomoca HEAD)
         *
         * @param {String|Integer} id
         * @param {*} filters
         * @param {String} subResource
         *
         * @returns {*}
         */
        this.exist = function(id, filters, subResource, callback, errorCallback, httpParams) {
          var query = self._getQuery(filters),
            url = self._getServiceUrl(id, subResource) + query;
          return self.call(url, 'HEAD', null, callback, errorCallback, httpParams);
        };

        /**
         * call przez providera do usulgi (moze byc mock albo atena)
         *
         * @param {String} url
         * @param {String} method
         * @param {*} data
         */
        this.call = function(url, method, data, callback, errorCallback, httpParams) {
          self.checkMockSettings();

          // logujemy wołanie serwisu
          var serviceCallObject = ihestiaCommonErrorHandler.addSvcCall(self._getServiceUrl(), {
            url: url,
            method: method,
            data: data,
            httpParams: httpParams,
            processType: self.processType,
            dataType: self.dataType,
            serviceOption: {
              sessionKeeper: self.sessionKeeper
            }
          });

          // faktycznie zawołanie serwisu
          var promise = self.provider.call({
            url: url,
            method: method,
            data: data,
            resource: self.resource,
            businessArea: self.businessArea,
            processType: self.processType,
            dataType: self.dataType,
            mockMode: self.mockMode,
            serviceOption: {
              sessionKeeper: self.sessionKeeper
            }
          }, callback, errorCallback, httpParams);

          serviceCallObject.request.guid = promise.uniqueRequestId;

          // tak wszelki wypadek sprawdzamy, czy to na pewno jest promise
          if (angular.isFunction(promise.then)) {
            var saveSvcResponseFunc = function(res) {
              if (angular.isObject(res) && angular.isFunction(res.headers)) {
                serviceCallObject.response = {
                  data: res.data,
                  headers: res.headers(),
                  status: res.status
                };
              }
              return res;
            };
            promise.then(saveSvcResponseFunc, saveSvcResponseFunc);
          }

          return promise;
        };

        /**
         * robi z filters, query
         *
         * @return {String} GET query string
         */
        this._getQuery = function(filters) {
          if (filters === null) {
            return '';
          }

          var serializedFilters = [];
          for (var filter in filters) {
            if (filters.hasOwnProperty(filter)) {
              serializedFilters.push(encodeURIComponent(filter) + '=' + encodeURIComponent(filters[filter]));
            }
          }

          if (serializedFilters.length === 0) {
            return '';
          }

          return '?' + serializedFilters.join('&');
        };

        /**
         * zwraca sparsowany adres do uslugi
         *
         * @param {Integer|String} id
         * @param {String} subResource
         *
         * @returns {string}
         * @private
         */
        this._getServiceUrl = function(id, subResource) {
          self.checkMockSettings();
          var paramDefault = function(param, defaultValue, separator, decorateCallback) {
              if (param === null || typeof param === 'undefined') {
                return defaultValue;
              }
              if (decorateCallback === null || typeof decorateCallback === 'undefined') {
                decorateCallback = function(param) {
                  return param;
                };
              }
              return decorateCallback(param, separator);
            },
            decorateCallback = function(param, chr) {
              if (param === '') {
                return '';
              }
              if (param === '/') {
                return '/';
              }
              if (typeof chr === 'undefined') {
                chr = '/';
              }
              return chr + '' + param;
            };

          var url = self.basePattern;
          if (self.businessArea === '') {
            url = url.replace('{businessArea}/', '');
          } else {
            url = url.replace('{businessArea}', self.businessArea);
          }

          url = url.replace('{apiString}', self.apiString);
          if (self.resource === '') {
            url = url.replace('/{resource}', '');
          } else {
            url = url.replace('{resource}', self.resource);
          }
          url = url.replace('{apiVer}', self.apiVersion);
          url = url.replace('{host}', self.host);
          url = url.replace('{id}', paramDefault(id, '', '/', decorateCallback));
          url = url.replace('{subResource}', paramDefault(subResource, '', '/', decorateCallback));

          /**
           * na podstawie konfiguracji, mozemy(musimy[!sick]) definiowac trzy sposoby adresowania uslug
           */
          switch (ihestiaConfigHelper.get('rest', 'MODE')) { //eslint-disable-line default-case
            case 'port':
              url = url.replace('{port}', paramDefault(self.port, '', ':', decorateCallback));
              url = url.replace('{path}', '');
              break;
            case 'path':
              url = url.replace('{port}', '');
              url = url.replace('{path}', paramDefault(self.path, '', '/', decorateCallback));
              break;
            case 'none':
              url = url.replace('{port}', '');
              url = url.replace('{path}', '');
              break;
            case 'portAndPath':
              url = url.replace('{port}', paramDefault(self.port, '', ':', decorateCallback));
              url = url.replace('{path}', paramDefault(self.path, '', '/', decorateCallback));
              break;
          }

          return url;
        };

        /**
         * sprawdzamy czu nie ma mocka dla tej usługi i czy nie trzeba zmienić hosta
         * @return {[type]} [description]
         */
        this.checkMockSettings = function() {
          if (typeof self.mockMode === 'undefined' || self.mockMode === null) {
            self.mockMode = ihestiaRestMockHelper.getServiceMockMode(self);
          }
        };
      };

      return IHestiaRestServiceAbstractDefMethods;
    }]);