angular.module('salesPath2')
  /**
   * helper do usług dla polis
   */
  .service('policiesServiceHelper', ['policiesSvc',
    function(policiesSvc) {
      var PoliciesServiceHelper = function() {
        /**
         * zwraca listę polis, które powstały z tego samego wniosku ubezpieczeniowego
         *
         * @param {String} id
         * @param {Object} data dodatkowe dane (np. kontekst)
         * @param {function} callback
         * @returns {*}
         */
        this.getLinkedPolicies = function(id, data, callback, errorCallback, httpParams) {
          return policiesSvc.getLinkedPolicies(id, data, callback, errorCallback, httpParams);
        };

        /**
         * Usługa do pobrania wznawianych polis.
         *
         * @param {String|Array} ids idki lub id polisy
         * @param {function} callback
         * @returns {*}
         */
        this.getRenew = function(ids, policySymbol, callback, errorCallback, httpParams) {
          return policiesSvc.getRenew(ids, policySymbol, callback, errorCallback, httpParams);
        };

        /**
         * Usługa do pobrania dokupienia polisy.
         *
         * @param {String}id polisy
         * @param {function} callback
         * @returns {*}
         */
        this.getExtension = function(id, callback, errorCallback, httpParams) {
          return policiesSvc.getExtension(id, callback, errorCallback, httpParams);
        };

      };

      return new PoliciesServiceHelper();

    }
  ]);