angular.module('ihestiaRestServicesBase')
.service('ihestiaRestDictionaryProfessionsSvc', ['ihestiaRestAbstractDictionariesSvc',
  function(ihestiaRestAbstractDictionariesSvc){
  var RestDictionaryProfessionsSvc = function(){
    ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
    this.resource = 'standard/Professions';
    this.requestType = 'searches';
    this.count = 5000;
  };
  return new RestDictionaryProfessionsSvc();
}]);