angular.module('lsnBase.models')
  .factory('LsnMessageModelConstants', [function()
  {
    var MessageModelConstants = {};
    // słownik typ komunikatu
    MessageModelConstants.MESSAGE_TYPE_INFO = 'INFO';
    MessageModelConstants.MESSAGE_TYPE_WARN = 'WARN';
    MessageModelConstants.MESSAGE_TYPE_ERROR = 'ERROR';
    MessageModelConstants.DICTIONARY_MESSAGE_TYPE = {};
    MessageModelConstants.DICTIONARY_MESSAGE_TYPE[MessageModelConstants.MESSAGE_TYPE_INFO] = 'informacja';
    MessageModelConstants.DICTIONARY_MESSAGE_TYPE[MessageModelConstants.MESSAGE_TYPE_WARN] = 'ostrzeżenie';
    MessageModelConstants.DICTIONARY_MESSAGE_TYPE[MessageModelConstants.MESSAGE_TYPE_ERROR] = 'błąd';

    return MessageModelConstants;
  }]);