angular.module('ihestiaCommonDirectives')
  .factory('UploadedFileModel', ['ihestiaUploadModelHelper', function(ihestiaUploadModelHelper) {

    var UploadedFileModel = function() {
      var self = this;
      /**
       * Fizyczny plik do wysłania
       * @type {FILE}
       */
      this.file = null;

      /**
       * Data utworzenia komentarza
       * @type {Number} @todo raczej powinna być data
       */
      this.errorText = '';

      /**
       * status przesłanego pliku
       * @type {String}
       */
      this.status = null;

      /**
       * name nazwa pliku
       * @type {String}
       */
      this.name = null;

      /**
       * size rozmiar pliku
       * @type {String}
       */
      this.size = null;

      /**
       * id pliku na serwerze
       * @type {String}
       */
      this.fileServerId = null;

      this.sendingInfo = false;

      this.earlierUploadedFile = false;

      /**
       * nazwa typu dokumentu
       */
      this.documentTypeName = null;

      this.fileIsUploadedWithoutErrors = function() {
        return self.fileServerId !== null;
      };

      this.hasError = function() {
        return self.status !== 'SUCCESS' && self.status !== null;
      };

      this.setStatusFile = function(status, errorText) {
        ihestiaUploadModelHelper.setStatusFile(self, status, errorText);
      };

      this.initFromSendedFile = function(data, status, errorText, fileServerId) {
        angular.forEach(data, function(propertyValue, propertyCode){
          if(!angular.isFunction(propertyValue) && angular.isDefined(self[propertyCode])){
            self[propertyCode] = propertyValue;
          }
        });

        self.setStatusFile(status, errorText);

        if (angular.isDefined(fileServerId) && fileServerId !== null) {
          self.fileServerId = fileServerId;
        }
      };

      this.setEarlierUploadedFile = function(uploadedFile) {
        if (angular.isObject(uploadedFile)) {
          self.name = uploadedFile.name;
          self.size = parseInt(uploadedFile.size, 10);
          self.fileServerId = uploadedFile.fileServerId;
          self.earlierUploadedFile = true;

          if (angular.isDefined(uploadedFile.documentTypeName)) {
            self.documentTypeName = uploadedFile.documentTypeName;
          }

          if (angular.isDefined(uploadedFile.status)) {
            self.status = uploadedFile.status;
          }

          if (angular.isString(uploadedFile.errorText)) {
            self.errorText = uploadedFile.errorText;
          }

          if(angular.isString(uploadedFile.qualityCode) || uploadedFile.qualityCode === null){
            self.setStatusFromQualityCode(uploadedFile.qualityCode);
          }
        }
      };

      this.setStatusFromQualityCode = function(qualityCode){
        if (qualityCode === 'Ok' || qualityCode === null) {
          self.status = 'SUCCESS';
        } else if (qualityCode === 'DocumentNumberNotFound' || qualityCode === 'WrongPolicyNumber' || qualityCode === 'WrongAnnexNumber') {
          self.status = 'WARNING';
        } else {
          self.status = 'ERROR';
        }
      };
    };
    return UploadedFileModel;
  }]);