angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorAttachmmentModel', ['IKomunikatorAbstractModel', function(IKomunikatorAbstractModel) {

    var AttachmmentModel = function() {
      IKomunikatorAbstractModel.apply(this, arguments);
      var self = this;
      /**
       * Nazwa pliku
       * @type {String}
       */
      this.fileName = null;

      /**
       * Rozmiar pliku
       * @type {Number}
       */
      this.fileSize = null;

      /**
       * Typ pliku
       * @type {String}
       */
      this.fileType = null;

      /**
       * Id
       * @type {String}
       */
      this.id = null;

      /**
       * Id wiadomości
       * @type {String}
       */
      this.messageId = null;

      /**
       * ?
       * @type {String}
       */
      this.path = null;

       /**
       * ?
       * @type {String}
       */
      this.storagePlace = null;

       /**
       * ?
       * @type {String}
       */
      this.msgFileIndex = null;

      /**
       * [setFromFile ustawia dane z pliku]
       * @param {[FileModel]} file         [plik]
       * @param {[type]} fileServerId [id pliku w fileSerwerze]
       */
      this.setFromFile = function(file, fileServerId){
        self.fileName = file.name;
        self.fileSize = file.size;
        self.fileType = file.type;
        self.path = fileServerId;
      };

      this.getSize = function(){
        return parseInt(self.fileSize, 10);
      };
    };

    return AttachmmentModel;
  }]);