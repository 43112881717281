angular.module('ihestiaIkomunikator.fullListPanel')
  .controller('ihestiaIkomunikatorFullListPanelCentralCtrl', ['$state', '$scope', 'ihestiaIkomunikatorActionHelper', 'ihestiaIkomunikatorNavigationHelper', 'ihestiaIkomunikatorFullListPanelCentralHelper',
    'ihestiaIkomunikatorSearchMessagesCentralHelper', 'ihestiaGridSettingsHelper', '$timeout', 'ihestiaIkomunikatorAdvancedFilterHelper', 'ihestiaIkomunikatorDownloadFileHelper', '$rootScope', 'ihestiaIkomunikatorPermissionHelper', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function($state, $scope, ihestiaIkomunikatorActionHelper, ihestiaIkomunikatorNavigationHelper, ihestiaIkomunikatorFullListPanelCentralHelper, ihestiaIkomunikatorSearchMessagesCentralHelper,
      ihestiaGridSettingsHelper, $timeout, ihestiaIkomunikatorAdvancedFilterHelper, ihestiaIkomunikatorDownloadFileHelper, $rootScope, ihestiaIkomunikatorPermissionHelper, ihestiaIkomunikatorLoaderMessagesHelper) {
      $scope.spinnerOptions = {
        size: 'sm',
        label: ''
      };

      $scope.gridOptions = {
        gridSettings: {
          contextId: 'CommunicatorCentralHistory',
          filterTplPath: 'ihestiaIkomunikatorTemplatesApp/views/central/fullListPanel/gridSettingsFilters.tpl.html'
        },
        //   filterRequestModel: 'IKomunikatorAdvancedSearchMessageModel',
        showOnlyFilterSettings: true,
        showWorkerFilter: false,
        showSalesNetworks: false,
        dictionaries: ihestiaIkomunikatorAdvancedFilterHelper.getDictionaries(),
        loaded: ihestiaIkomunikatorAdvancedFilterHelper.loaded,
        requiredFilters:
        {
          types: true,
          subjects: true,
          phonePrefixes: true,
          channelNames: true
        }
      };

      /**
       * [init description]l
       * @return {[type]} [description
       *        */
      $scope.init = function() {
        if (!ihestiaIkomunikatorPermissionHelper.shouldShowApp()) {
          $rootScope.canRender = false;
          return;
        }

        $scope.tplData = ihestiaIkomunikatorFullListPanelCentralHelper.tplData;

        ihestiaIkomunikatorFullListPanelCentralHelper.init();
        $scope.fireListeners();

        $timeout(function() {
            $scope.$emit('setPageTitle', {pageTitle: 'Wiadomości'});
        }, 0);
        
      };

      $scope.fireListeners = function() {
        $scope.$on('gridSettingsLoaded', function() {
          $scope.setFilterSettings();
        });

        $scope.$on('gridSettingsClosed', function() {
          $scope.loadFromGridData();
        });

        $scope.$watch('gridOptions.loaded', function(val, oldVal) {
          if(val.dict && !oldVal.dict){
            ihestiaGridSettingsHelper.setOptions($scope.gridOptions);
          }
        }, true);
      };

      $scope.showDetailsMessageThread = function(threadId) {
        ihestiaIkomunikatorNavigationHelper.goTo('details', threadId);
      };

      $scope.showNewMessageModal = function() {
        ihestiaIkomunikatorActionHelper.startNewMessage();  
      };

      $scope.filterSearch = function() {
        if(!$scope.tplData.loading){
          ihestiaIkomunikatorFullListPanelCentralHelper.filterSearch();
        }else{
          $scope.tplData.reloadSearchAgain = true;
        }
      };

      /**
       * Wyszukiwanie kolejnzch elementow
       * @param  {string} phrase [description]
       */
      $scope.searchNext = function() {
        if (!$scope.tplData.loading) {
          ihestiaIkomunikatorLoaderMessagesHelper.loadNextResult($scope.tplData);
        }
      };

      $scope.showGridSettings = function() {
        $scope.setFilterSettings();
        if (!ihestiaGridSettingsHelper.globalConfig.visible) {
          $timeout(function() { //żeby kliknięcie poza setting od razu ich nie zamknęło
            ihestiaGridSettingsHelper.showGridSettings();
          }, 0);
        }
      };

      $scope.setFilterSettings = function() {
        angular.forEach(ihestiaIkomunikatorAdvancedFilterHelper.getFilter(), function(value, key) {
          ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[key] = value;
        });
      };

      $scope.loadFromGridData = function() {
        ihestiaIkomunikatorFullListPanelCentralHelper.tplData.searchFor = null;
        var dynamicFilters = ihestiaGridSettingsHelper.getCurrentFilterData();

        ihestiaIkomunikatorAdvancedFilterHelper.setFilterData(dynamicFilters);
        ihestiaIkomunikatorFullListPanelCentralHelper.reloadSearch();
      };

      $scope.downloadFile = function(attachment, messageId) {
        ihestiaIkomunikatorDownloadFileHelper.download(messageId, attachment.path, attachment.fileName, attachment.msgFileIndex);
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorFullListPanelCentralHelper.setClearData();
      });

      $scope.additionalHeaderExist = function(thread){
        return thread.getLastMessageChannel() !== 'ALERT' && (thread.getLastMessageChannel() !== 'KOMUNIKAT' || thread.customFields.dashboardTaskId);
      };

      $scope.init();
    }
  ]);