angular.module('ihestiaCommonDirectives')
  .service('ikontoCommonHelper', ['ihestiaCommonConfirmModalHelper', 'ihestiaConfigHelper',
    function(ihestiaCommonConfirmModalHelper, ihestiaConfigHelper) {
      var IkontoCommonHelper = function() {

        /**
         * Pokazanie modala z informajcą, że klient już istnieje
         * @param  {string} title         tytuł modala
         */
        this.showClientAlreadyExistModal = function(title, redirectUrl, toDirect, params) {
          if(!title)
          {
            title = 'Jesteś już naszym klientem';
          }
          if(!redirectUrl)
          {
            redirectUrl = ihestiaConfigHelper.getUrl('IKONTO_URL');
          }
          if(angular.isUndefined(params)) {
            params = {};
          }
          if(!params.okBtnName) {
            params.okBtnName = '';
          }
          if(!params.okBtnCallback) {
            params.okBtnCallback = lsnNg.noop;
          }

          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: title,
            text: '',
            template: 'ihestia-commondirectives-template-app/helpers/ikontoCommonClient.tpl.html',
            otherData: {
              redirectUrl: redirectUrl,
              toDirect: !!toDirect,
              canForceForward: !!params.canForceForward
            },
            okBtnName: params.okBtnName,
            size: 'sm',
            cancelBtnName: 'Zamknij',
            keyboard: false,
            okBtnCallback: params.okBtnCallback
          });
        };
      };

      return new IkontoCommonHelper();
    }
  ]);