angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardDictionariesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var DictionariesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/dictionaries';
        this.businessArea = 'dashboard';
        this.port = '8282';

      };
      return new DictionariesSvc();
    }
  ]);