angular.module('iSkanerLsn').factory('iSkanerEvents', ['$q', 'iSkanerTokenSvc', 'iSkanerConstants', '$window', 'ihestiaCommonErrorHandler', '$timeout', '$alert',
 function($q, iSkanerTokenSvc, iSkanerConstants, $window, ihestiaCommonErrorHandler, $timeout, $alert) {
	var ISkanerEvents = function() {
		var self = this;
		var source = {};
		
		this.openDeferred = $q.defer();

		this.open = function(currentToken, config, directiveCallback, isNewDeviceEvent) {
			self.openDeferred = $q.defer();

			if (angular.isDefined($window.EventSource)) {
				self.openEventSource(currentToken, config, directiveCallback, isNewDeviceEvent);
			} else {
				self.openDeferred.reject('This browser does not support Server Sent Events.');
			}

			return self.openDeferred.promise;
		};

		this.openEventSource = function(currentToken, config, directiveCallback, isNewDeviceEvent){
				source[config.dataType] = new EventSource(
					'/ehscanner/api/v1/admin/sse/events?token=' + currentToken
				);

				source[config.dataType].addEventListener('error', function(error) {
					$timeout(function(){
            $alert({
              content: 'Nasłuch nie został otwarty',
              type: 'warning'
            });
					}, 0);

					iSkanerTokenSvc.reset(config.componentId);
					self.openDeferred.reject(error);
				});

				if (isNewDeviceEvent) {
					source[config.dataType].addEventListener(iSkanerConstants.NEW_DEVICE_EVENT, function(data) {
						directiveCallback(iSkanerConstants.NEW_DEVICE_EVENT, data);
					}, false);
				} else {
					source[config.dataType].addEventListener(config.dataType + 'EVENT', function(event) {
						directiveCallback(iSkanerConstants.NEW_FILE_EVENT, event.data);
					}, false);
					source[config.dataType].addEventListener(iSkanerConstants.START_PROCESSING_EVENT, function(data) {
						directiveCallback(iSkanerConstants.START_PROCESSING_EVENT, data);
					}, false);
					source[config.dataType].addEventListener(iSkanerConstants.FINISH_PROCESSING_EVENT, function(data) {
						self.close(config.dataType);
						directiveCallback(iSkanerConstants.FINISH_PROCESSING_EVENT, data);
					}, false);
				}

				source[config.dataType].addEventListener('open', function() {
					self.openDeferred.resolve();
				});
		};

		this.close = function(dataType) {
			var deferred = $q.defer();

			if (source[dataType]) {
				source[dataType].close();
				delete source[dataType];
				deferred.resolve();
			} 
			// wyglada na to ze moze zdarzyc sie ze FINISH_PROCESSING_EVENT potem w reinitializeIskanerAztecs mamy znowu wywolany close
			else {
				deferred.resolve('No SSEs to close');
			}

			return deferred.promise;
		};
	};

	return new ISkanerEvents();
}]);