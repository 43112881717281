angular.module('ihestiaCrossTab')
  /**
   * model wiadomosci przesylanych miedzy zakladkami
   */
  .factory('IHestiaCrossTabMessageModel', [function () {
      var model = function () {
        /**
         * czas utworzenia wiadomosci (timestamp)
         * @type number
         */
        this.created = null;
        /**
         * kod wiadomości
         * @type string
         */
        this.code = null;
        /**
         * id zakladki, z ktorej wyslano wiadomosc
         * @type string
         */
        this.tabId = null;
        /**
         * dodatkowe atrybuty wiadomości (opcjonalne)
         * @type object
         */
        this.attrs = null;
        //... można dodać kolejne pola
      };

      return model;
    }]);