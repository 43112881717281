angular.module('ihestiaRestServicesBase')
  .service('filesSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var Files = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      var self = this;
      this.resource = 'files';
      this.businessArea = 'files';
      this.port = '8221';
      this.path = 'RestFiles';

      /**
       * Usługa do tworzenia tokenu w celu odczytu pliku z FileServer.
       *
       * @param {String} id
       * @param {function} callback
       * @returns {*}
       */
      this.postToken = function(id, callback, errorCallback, params) {
        return self.post(id, null, 'tokens', callback, errorCallback, params);
      };

      /**
       * Usługa do weryfikacji „jakości” przesłanego elektronicznie dokumentu polisy.
       *
       * @param {String} id Identyfikator pliku w FileServer.
       * @param {function} callback
       * @returns {*}
       */
      this.getQuality = function(id, callback) {
        return self.get(id, null, 'quality', callback, null, {
          doNotAskAgainOnConflict: true
        });
      };
    };
    return new Files();
  }]);