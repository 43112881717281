angular.module('salesPath2')
  .controller('productsClausesErrorsModalCtrl', ['$scope', 'summaryProductsClausesHelper',
    function($scope, summaryProductsClausesHelper) {

      /**
      * init
      */
      $scope.init = function() {        
        $scope.fireListeners();
      };

      /**
       * Odpalenie watchy
       */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.productsClausesErrorsModal', function() {
          if (summaryProductsClausesHelper.modalData.clauses.length > 0 && summaryProductsClausesHelper.modalData.clauses[0].code) {
            summaryProductsClausesHelper.showProductsClausesModal();
          }
        });
      };

      $scope.init();

    }
  ]);
