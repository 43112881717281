angular.module('ihestiaCommonDirectives')
	.controller('lsnCommonUploadCtrl', ['$scope', '$element', '$attrs', 'ihestiaFirstInfoOneListUploaderHelper', 'fileServerSvc', '$alert', 'ihestiaDeepExtend', 'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaCommonErrorHandler', '$injector', 'AttachmentFileModel', '$filter', 'ihestiaRestDictionaryAllSettlementsDocumentTypesSvc', 'compressorJsConfigHelper',
		function($scope, $element, $attrs, ihestiaFirstInfoOneListUploaderHelper, fileServerSvc, $alert, ihestiaDeepExtend, ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaCommonErrorHandler, $injector, AttachmentFileModel, $filter, ihestiaRestDictionaryAllSettlementsDocumentTypesSvc, compressorJsConfigHelper) {
			$scope.defaultOptions = {
				url: fileServerSvc._getServiceUrl(), //url do uslugi wysylania pliku
				autoUpload: false, //domyslnie na false, poniewaz frp wymaga, wyslania 2 requestow - 1 z informacja o checi wyslania pliku
				singleFileUploads: true, //czy pliki maja byc wysylane pojedynczo
				treatMesageAsI18nCode: true,
				hideInput: false,
				oneFile: false,
				oneFileDisabled: false, //czy jeden plik i potem nie dostepne
				messages: {
					maxNumberOfFiles: ['maxFileCountExceeded', {
						componentCode: 'Public'
					}],
					acceptFileTypes: ['incorrectFileType', {
						componentCode: 'Public'
					}],
					maxFileSize: ['toLargeFile', {
						componentCode: 'Public'
					}],
					minFileSize: ['toSmallFile', {
						componentCode: 'Public'
					}]
				},
				//name: 'context_name', - nazwa kontekstu - wymagane
				//maxFileSize: 10000000, - maksymalny rozmiar pliku
				//maxNumberOfFiles: 15, - maksymalna liczba plikow
				maxChunkSize: 4000000, //rozmiar chunkowanego pliku
				hideQueueList: true, //czy ma schowac domyslna liste plikow z biblioteki
				showMessage: function(msg) { //funkcja wywolana na blad w przesylaniu/dodawniu pliku
					$alert({
						content: msg,
						type: 'warning'
					});
				},
				compressorjs: compressorJsConfigHelper.compressorjs,
				compressorjsConfig: compressorJsConfigHelper.compressorjsConfig
			};

			$scope.defaultTplOptions = {
				tplForFileList: 'ihestia-commondirectives-template-app/directives/uploader/tpl/newOneList.tpl.html', //tpl dla listy plikow
				attachmentType: 'static', //w jaki sposob maja sie pokazywac zalczniki enum : {'static', 'link'}
				hideProgressBar: false, //czy progresBar powinnien byc schowany
				allowDeleteEarlierUploadedFile: true //czy pozwolic usunac wczesniej uplodowany plik (np przy otwieraniu zapisanego wniosku)
			};

			/**
			 * Init.
			 */
			$scope.init = function() {
				if (angular.isUndefined($scope.uploadHelperName)) {
					$scope.uploadHelper = ihestiaFirstInfoOneListUploaderHelper;
				} else {
					$scope.uploadHelper = $injector.get($scope.uploadHelperName);
				}

				$scope.setTplOptions();
				$scope.setUploaderOptions();
				$scope.setCallbacks();
				$scope.initUploader();
				$scope.fireListeners();
			};

			$scope.fireListeners = function() {
				$scope.$on('startSendigFiles.' + $scope.uploaderOptions.name, function() {
					if ($scope.uploadHelper.areFileToSend($scope.uploaderOptions.name)) {
						$scope.uploadHelper.startSendingFiles($scope.uploaderOptions.name);
						$scope.$emit('showBlockUi', {
							label: $filter('translate')('sendFileWith', {
								componentCode: 'Public',
								params: {
									numberSendedFile: 1,
									allNumberFiles: $scope.fileModels.length
								}
							})
						});
					} else if ($scope.uploadHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name) && angular.isObject($scope.callbacks) && angular.isFunction($scope.callbacks.afterAllFileSend)) {
						$scope.callbacks.afterAllFileSend(true);
					}
				});

				$scope.$on('deleteAllFile.' + $scope.uploaderOptions.name, function() {
					$scope.deleteAllFile();
				});

				$scope.$watch('document', function(newValue) {
					if (angular.isObject(newValue)) {
						$scope.uploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, newValue.documentType);
					}
				}, true);
			};

			/**
			 * [setTplOptions ustawia opcje tpl m.in. dla listy plikow]
			 */
			$scope.setTplOptions = function() {
				if (angular.isDefined($scope.tplOptions)) {
					$scope.tplOptions = ihestiaDeepExtend($scope.defaultTplOptions, $scope.tplOptions);
				} else {
					$scope.tplOptions = $scope.defaultTplOptions;
				}
			};

			/**
			 * [setUploaderOptions ustawia opcje uploadera oraz tpl listy plikow, rozdziela konfiguracje na odpowiednie]
			 */
			$scope.setUploaderOptions = function() {
				if (angular.isDefined($scope.name)) {
					if (angular.isUndefined($scope.uploaderOptions)) {
						$scope.uploaderOptions = {};
					}
					$scope.uploaderOptions.name = $scope.name;
				}

				if (angular.isDefined($scope.uploaderOptions)) {
					$scope.uploaderOptions = ihestiaDeepExtend($scope.defaultOptions, $scope.uploaderOptions);
					if ($scope.uploaderOptions.oneFileDisabled) {
						$scope.uploaderOptions.oneFile = true;
					}

					if ($scope.uploaderOptions.oneFile) {
						$scope.uploaderOptions.multiple = false;
						$scope.uploaderOptions.maxNumberOfFiles = 1;
					}
				} else {
					ihestiaCommonErrorHandler.throwException($filter('translate')('optionsUploaderWereNotDelivered', {
						componentCode: 'Public'
					}));
				}
			};

			$scope.setCallbacks = function() {
				if (angular.isObject($scope.callbacks)) {
					if (angular.isFunction($scope.callbacks.onAllFileSend)) {
						$scope.onAllFileSend = $scope.callbacks.onAllFileSend;
					} else {
						$scope.onAllFileSend = $scope.internalOnAllFileSend;
					}

					if (angular.isFunction($scope.callbacks.onFileUploadAdd)) {
						$scope.onFileUploadAdd = $scope.callbacks.onFileUploadAdd;
					} else {
						$scope.onFileUploadAdd = $scope.internalOnFileUploadAdd;
					}

					if (angular.isFunction($scope.callbacks.onErrorSendingFile)) {
						$scope.onErrorSendingFile = $scope.callbacks.onErrorSendingFile;
					}

					if (angular.isFunction($scope.callbacks.onFileUploadDone)) {
						$scope.onFileUploadDone = $scope.callbacks.onFileUploadDone;
					}

					if (angular.isFunction($scope.callbacks.onErrorAddingFile)) {
						$scope.onErrorAddingFile = $scope.callbacks.onErrorAddingFile;
					} else {
						$scope.onErrorAddingFile = $scope.internalOnErrorAddingFile;
					}

					if (angular.isFunction($scope.callbacks.deleteFile)) {
						$scope.deleteFile = $scope.callbacks.deleteFile;
					} else {
						$scope.deleteFile = $scope.internalDeleteFile;
					}

					if (angular.isFunction($scope.callbacks.setDocumentTypeForUpload)) {
						$scope.setDocumentTypeForUpload = $scope.callbacks.setDocumentTypeForUpload;
					} else {
						$scope.setDocumentTypeForUpload = $scope.internalSetDocumentTypeForUpload;
					}

					if (angular.isFunction($scope.callbacks.setDocumentTypeForUpload)) {
						$scope.deleteFileFromAttachment = $scope.callbacks.deleteFileFromAttachment;
					} else {
						$scope.deleteFileFromAttachment = $scope.internalDeleteFileFromAttachment;
					}
				} else {
					$scope.onAllFileSend = $scope.internalOnAllFileSend;
					$scope.onFileUploadAdd = $scope.internalOnFileUploadAdd;
					$scope.deleteFile = $scope.internalDeleteFile;
					$scope.setDocumentTypeForUpload = $scope.internalSetDocumentTypeForUpload;
					$scope.deleteFileFromAttachment = $scope.internalDeleteFileFromAttachment;
					$scope.onErrorAddingFile = $scope.internalOnErrorAddingFile;
				}
			};

			/**
			 * Inicjalizacja uploadera.
			 */
			$scope.initUploader = function() {
				$scope.uploadHelper.init(
					$scope.uploaderOptions.name, {
						onFileUploadDone: $scope.onFileUploadDone,
						onErrorSendingFile: $scope.onErrorSendingFile,
						onFileUploadAdd: $scope.onFileUploadAdd,
						onErrorAddingFile: $scope.onErrorAddingFile,
						onAllFileSend: $scope.onAllFileSend
					},
					$scope.attachmentList);

				if (angular.isFunction($scope.uploadHelper.getAllfiles)) {
					$scope.fileModels = $scope.uploadHelper.getAllfiles($scope.uploaderOptions.name);
				} else {
					$scope.fileModels = [];
				}

				if (angular.isDefined($scope.documentCode) || angular.isDefined($scope.newDocumentCode)) {
					$scope.setDocumentTypeForUpload();
				}
			};

			/**
			 * Ustawienie typu dokumentu do uploadu.
			 */
			$scope.internalSetDocumentTypeForUpload = function() {
				if (angular.isDefined($scope.documentContext)) {
					$scope.internalSetDocumentTypeForUploadNewWay();
				} else if (angular.isDefined($scope.documentCode)) {
					$scope.internalSetDocumentTypeForUploadOldWay();
				}
			};

			$scope.internalSetDocumentTypeForUploadOldWay = function() {
				var documentCode = $scope.documentCode ? $scope.documentCode : 'CustomerApplication';
				ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(
					null,
					null,
					documentCode,
					function(result) {
						if (angular.isObject(result.data) && angular.isDefined(result.data.code)) {
							var documentType = result.data;
							if (angular.isFunction($scope.setAttributeForDocumentType)) {
								$scope.setAttributeForDocumentType(documentType);
							}

							$scope.uploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, documentType);
						} else {

							ihestiaCommonErrorHandler.throwException($filter('translate')('exception.serviceDidNotReturnDocDef', {
								componentCode: 'Public',
								params: {
									documentCode: documentCode
								}
							}));
						}
					}
				);
			};

			$scope.internalSetDocumentTypeForUploadNewWay = function() {
				var documentContext = $scope.documentContext ? $scope.documentContext : 'Messenger';
				ihestiaRestDictionaryAllSettlementsDocumentTypesSvc.get(null, {
						context: documentContext
					}, null,
					function(result) {
						if (angular.isArray(result.data) && result.data.length > 0) {
							var documentType = null;

							angular.forEach(result.data, function(docType) {
								if(docType.code === $scope.documentCode){
									documentType = docType;
								}
							});

							if (angular.isFunction($scope.setAttributeForDocumentType)) {
								$scope.setAttributeForDocumentType(documentType);
							}

							$scope.uploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, documentType);
						} else {
							ihestiaCommonErrorHandler.throwException('Usługa nie zwróciła listy dostępnych typów dokumentów dla kontekstu ' + documentContext);
						}
					}
				);
			};



			$scope.internalOnAllFileSend = function(data) {
				var AttachmentModel = AttachmentFileModel;
				if (angular.isDefined($scope.attachmentModel)) {
					AttachmentModel = $injector.get($scope.attachmentModel);
				}

				$scope.$emit('hideBlockUi');
				if (data.withoutError) {
					$scope.errorFileExist = false;
					if (angular.isArray($scope.attachmentList)) {
						angular.forEach(data.files, function(fileModel) {

							var attachment = new AttachmentModel();
							attachment.setDataFromFileModel(fileModel);
							if (angular.isDefined(attachment.msgFileIndex)) {
								delete attachment.msgFileIndex;
							}
							$scope.attachmentList.push(attachment);
						});
					}

				} else {
					$scope.errorFileExist = true;
				}

				if (angular.isObject($scope.callbacks) && angular.isFunction($scope.callbacks.afterAllFileSend)) {
					$scope.callbacks.afterAllFileSend(data.withoutError, $scope.uploaderOptions.name);
				}
			};

			$scope.deleteAllFile = function() {
				$scope.uploadHelper.clearAllFiles($scope.uploaderOptions.name);
				if (angular.isArray($scope.attachmentList)) {
					$scope.attachmentList.length = 0;
				}
				$scope.errorFileExist = false;

				if ($scope.uploaderOptions.oneFileDisabled) {
					$scope.uploaderOptions.hideInput = false;
				}
			};

			$scope.internalOnFileUploadAdd = function() {
				if ($scope.uploaderOptions.oneFileDisabled) {
					$scope.uploaderOptions.hideInput = true;
				}

				if (angular.isUndefined($scope.uploaderOptions.maxNumberOfFiles)) {
					return;
				}
				var maxNFiles = $scope.uploaderOptions.maxNumberOfFiles,
					allFiles = $scope.fileModels.length;

				if (allFiles > maxNFiles) {
					var startRemoveFrom = allFiles - 1;
					for (var i = startRemoveFrom; i < $scope.fileModels.length; i++) {
						$scope.uploadHelper.deleteFile($scope.uploaderOptions.name, i);
					}

					var msg = $scope.uploaderOptions.messages.maxNumberOfFiles;
					if ($scope.uploaderOptions.treatMesageAsI18nCode) {
						if (angular.isString(msg)) {
							msg = $filter('translate')(msg);
						} else if (angular.isArray(msg)) {
							msg = $filter('translate')(msg[0], msg[1]);
						}
					}
					$scope.$emit('hideBlockUi');
					$scope.uploaderOptions.showMessage(msg);
				}
			};

			$scope.internalOnErrorAddingFile = function() {
				if ($scope.uploaderOptions.oneFileDisabled) {
					$scope.uploaderOptions.hideInput = false;
				}
			};

			$scope.internalOnFileUploadDone = function() {
				var numberSendedFile = $scope.uploadHelper.getProcessedFileIndex($scope.uploaderOptions.name) + 1;
				if (numberSendedFile < $scope.fileModels.length) {
					$scope.$emit('hideBlockUi');
					$scope.$emit('showBlockUi', {
						label: $filter('translate')('sendFileWith', {
							componentCode: 'Public',
							params: {
								numberSendedFile: numberSendedFile,
								allNumberFiles: $scope.fileModels.length
							}
						})
					});
				}
			};

			/**
			 * Usunięcie pliku z uploadera.
			 */
			$scope.internalDeleteFile = function(id) {
				$scope.deleteFileFromAttachment(id);
				$scope.uploadHelper.deleteFile($scope.uploaderOptions.name, id);
				if ($scope.uploadHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name)) {
					$scope.errorFileExist = false;
				}

				if ($scope.uploaderOptions.oneFileDisabled) {
					$scope.uploaderOptions.hideInput = false;
				}
			};

			$scope.internalDeleteFileFromAttachment = function(id) {
				var fileServerId = $scope.fileModels[id].fileServerId;
				if (fileServerId !== null && angular.isArray($scope.attachmentList)) {
					angular.forEach($scope.attachmentList, function(attachment, key) {
						if (attachment.fileServerId === fileServerId) {
							$scope.attachmentList.splice(key, 1);
						}
					});
				}
			};

			$scope.init();
		}
	]);