angular.module('ihestiaCommonDirectives.blockUi')
  .directive('ihestiaBlockUi', ['blockUiHelper',
    function (blockUiHelper) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/blockUi/blockUi.tpl.html',
        scope: true,
        link: function ($scope) {

          $scope.blockUiState = blockUiHelper.blockUiState;

          $scope.refreshSettings = function()
          {
            $scope.blockUiState.activeSettings = angular.extend({},
              $scope.blockUiState.defaultSettings,
              $scope.blockUiState.appSettings,
              $scope.blockUiState.passedSettings);
          };

          $scope.$watch('blockUiState.passedSettings', function(){
            $scope.refreshSettings();
          });

          $scope.$watch('blockUiState.appSettings', function(){
            $scope.refreshSettings();
          });

          $scope.refreshSettings();
        }
      };
    }
  ]);