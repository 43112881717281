angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardTaskModelConstants', ['IhestiaDashboardClaimObjectModel', 'IhestiaDashboardClientObjectModel', 'IhestiaDashboardOfferObjectModel', 'IhestiaDashboardPolicyObjectModel', 'IhestiaDashboardWorkerObjectModel', 'IhestiaDashboardObjectModelConstants', 'IhestiaDashboardNoneObjectModel', 'IhestiaDashboardApplicationObjectModel',
    function(IhestiaDashboardClaimObjectModel, IhestiaDashboardClientObjectModel, IhestiaDashboardOfferObjectModel, IhestiaDashboardPolicyObjectModel, IhestiaDashboardWorkerObjectModel, IhestiaDashboardObjectModelConstants, IhestiaDashboardNoneObjectModel, IhestiaDashboardApplicationObjectModel){
    var constants = {
      STATUS_NEW: 'NEW',
      STATUS_ASSIGNED: 'ASSIGNED',
      STATUS_IN_PROGRESS: 'IN_PROGRESS',
      STATUS_CLOSED: 'CLOSED',
      STATUS_POSTPONED: 'POSTPONED',
      STATUS_REJECTED: 'REJECTED', // status wirtualny (można go przekazywać, ale nigdy nie zostanie ustawiony na zadaniu - służy do odrzucenia zadania)
      TYPE_MANUAL: 'MANUAL',
      TYPE_MANUAL_INFO: 'MANUAL_INFO'
    };

    constants.OBJECT_CLASS_MAP = {};
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_CLAIM] = IhestiaDashboardClaimObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_CLIENT] = IhestiaDashboardClientObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_OFFER] = IhestiaDashboardOfferObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_POLICY] = IhestiaDashboardPolicyObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_WORKER] = IhestiaDashboardWorkerObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_NONE] = IhestiaDashboardNoneObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_APPLICATION] = IhestiaDashboardApplicationObjectModel;
    constants.OBJECT_CLASS_MAP[IhestiaDashboardObjectModelConstants.TYPE_AGENT] = IhestiaDashboardWorkerObjectModel;
    return constants;
  }])
  .factory('IhestiaDashboardTaskModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var TaskModel = function() {
      this.objectName = 'IhestiaDashboardTask';

      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        version: {
          type: 'int'
        },
        status: {
          type: 'string'
        },
        attributes: {
          type: 'mixed'
        },
        object: {
          type: 'mixed'
        },
        operator: {
          type: 'IhestiaDashboardWorker'
        },
        originator: {
          type: 'IhestiaDashboardWorker'
        },
        caseId: {
          type: 'string'
        },
        creationTime: {
          type: 'date'
        },
        taskDefinitionId: {
          type: 'int'
        },
        title: {
          type: 'string'
        },
        subtitle: {
          type: 'string'
        },
        description: {
          type: 'string'
        },
        attachments: {
          type: 'array',
          elementsType: 'IhestiaDashboardAttachment'
        },
        parentId: {
          type: 'string'
        },
        workerId: {
          type: 'string'
        },
        poolId: {
          type: 'int'
        }
      };

      this.id = null;
      this.version = null;
      this.status = null;
      this.attributes = null;
      this.object = null;
      this.operator = null;
      this.originator = null;
      this.caseId = null;
      this.creationTime = null;
      this.taskDefinitionId = null;
      this.title = null;
      this.subtitle = null;
      this.description = null;
      this.attachments = null;
      this.parentId = null;
      this.workerId = null;
      this.poolId = null;
    };


    TaskModel.prototype = IHestiaAbstractModel.prototype;

    return TaskModel;
  }]);