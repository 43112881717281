angular.module('salesPath2')
  //walidator wniosku
  .service('sp2ApplicationValidator', ['mainDataContainer', 'resourceHelper', 'CONSTANTS', 'CONFIG', '$filter', 'dataContainerHelper',
    function(mainDataContainer, resourceHelper, CONSTANTS, CONFIG, $filter, dataContainerHelper) {
      var ApplicationValidator = function() {
        var self = this;
        /**
         * waliduje wniosek i zwraca komunikaty na potrzeby messagesHelpera
         * @return {Object[]} obiekty w formacie:
         *   {
         *    code: 'kod',
         *     type: CONSTANTS.ERROR_TYPE.error,
         *     message: 'Brak uprawnień do zapisu wniosku i polisy.',
         *     attributes: {},
         *     viewReference: null
         *   };
         */
        this.validateApplication = function() {
          var messages = [];
          //walidacje wyboru produktów
          messages = messages.concat(self.validateProductSelection());
          //kolejne walidacje poniżej...
          return messages;
        };

        /**
         * waliduje wniosek pod kątem zaznaczenoch produktów, przedmiotów ubezpieczeń i SU
         * @return {Object[]} zwraca ewentualne komunikaty
         */
        this.validateProductSelection = function() {
          var messages = [];
          angular.forEach(resourceHelper.productType, function(prodType, prodCode) {
            if (angular.isDefined(CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[prodCode])) { //produkcty typu nnw_incognito czy ogi_kra obsługiwane sa w ramach "normalnych" produktów
              return true;
            }
            var selectedVariants; // (object) zaznaczone warianty
            switch (prodType) {
              case CONSTANTS.PRODUCT_TYPE_PERSON:
                selectedVariants = dataContainerHelper.getVariantsForProduct(prodCode);
                if (angular.equals(selectedVariants, {})) {
                  return true; //nie wybrano żadnego wariantu
                }
                messages = messages.concat(self._validatePerosnProductSelection(selectedVariants, prodCode));
                break;
              case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              case CONSTANTS.PRODUCT_TYPE_VEHICLE:
                selectedVariants = mainDataContainer.selectedVariants[prodType];
                if (!angular.isObject(selectedVariants) || angular.equals(selectedVariants, {})) {
                  return true; //nie wybrano żadnego wariantu
                }
                if (prodType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                  messages = messages.concat(self._validateLocalizationProductSelection(selectedVariants, prodCode));
                } else {
                  messages = messages.concat(self._validateCommunicationProductSelection(selectedVariants, prodCode));
                }
                break;
              default:
            }
            return true;
          });
          return messages;
        };

        /**
         * sprawdza czy dodano osobę przy wybranym wairancie ubezpieczenia osobowego
         * @param  {Object} selectedVariants
         * @param  {String} prodCode kod produktu, którego zaznaczenie walidujemy
         * @return {Object[]} komunikaty wynikające z waldacji
         */
        this._validatePerosnProductSelection = function(selectedVariants, prodCode) {
          if (prodCode === CONSTANTS.PRODUCT_NNW && angular.isObject(mainDataContainer.nnwIncognito)) {
            return []; //przypadek wybranego nnw forma bezimienna
          }
          var msgs = [],
            personsData = dataContainerHelper.getPersonsForPersonalProduct(prodCode),
            multiVariantProduct = CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(prodCode) !== -1;
          lsnNg.forEach(selectedVariants, function(selected, variant) {
            if (!selected) { //interesuja nas tylko zaznaczone warianty
              return true;
            }
            var anyPersonInVariant = false; //czy jakas osoba ma wybrany rozpatrywany wariant
            lsnNg.forEach(personsData, function(pData) {
              if ( (multiVariantProduct && pData.variants[variant]) || (!multiVariantProduct && pData.variants === variant) ) {
                anyPersonInVariant = true;
                return false;
              }
              return true;
            });
            if (!anyPersonInVariant) {
              msgs.push(self._buildProductSelectionMessage(CONSTANTS.PRODUCT_TYPE_PERSON, prodCode));
              return false;
            }
            return true;
          });
          return msgs;
        };

        /**
         * sprawdza czy dodano nieruchomość i czy określono co najmniej jedną sumę ubezpieczenia
         * @param  {Object} selectedVariants
         * @param  {String} prodCode kod produktu, którego zaznaczenie walidujemy
         * @return {Object[]} komunikaty wynikające z waldacji
         */
        this._validateLocalizationProductSelection = function(selectedVariants, prodCode) {
          var msgs = [];
          angular.forEach(selectedVariants, function(prods, locId) {
            var productVariants = dataContainerHelper.getVariantsForProduct(CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[prodCode] ? CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[prodCode] : prodCode, locId);
            if (angular.equals(productVariants, {})) { //nie wybrano produktu i wariantu dla obiektu
              return true;
            }
            var selectedVariant = self._gettSelectedVariant(productVariants);
            if (selectedVariant !== null) {
              if (locId === CONSTANTS.NO_OBJECT) {
                msgs.push(self._buildProductSelectionMessage(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, prodCode, locId));
              } else {
                var anySumGiven = self._anySumGiven(dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, prodCode)),
                  noSumVariant = (prodCode === CONSTANTS.PRODUCT_OGI && angular.isDefined(CONFIG.BEHAVIOR.localization.connectedFireRisk[selectedVariant]));
                if (!noSumVariant && !anySumGiven) {
                  msgs.push(self._buildProductSelectionMessage(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, prodCode, locId));
                }
              }
            }
            return true;
          });
          return msgs;
        };

        /**
         * sprawdza czy dodano pojazd przy wybranym wairancie ubezpieczenia
         * @param  {Object} selectedVariants
         * @param  {String} prodCode kod produktu, którego zaznaczenie walidujemy
         * @return {Object[]} komunikaty wynikające z waldacji
         */
        this._validateCommunicationProductSelection = function(selectedVariants, prodCode) {
          var msgs = [];
          angular.forEach(selectedVariants, function(prods, vehId) {
            var productVariants = dataContainerHelper.getVariantsForProduct(prodCode, vehId);
            if (angular.equals(productVariants, {})) { //nie wybrano produktu i wariantu dla obiektu
              return true;
            }
            var selectedVariant = self._gettSelectedVariant(productVariants);
            if (selectedVariant !== null && vehId === CONSTANTS.NO_OBJECT) {
              msgs.push(self._buildProductSelectionMessage(CONSTANTS.PRODUCT_TYPE_VEHICLE, prodCode, vehId));
            }
            return true;
          });
          return msgs;
        };


        /**
         * czy ktoras z przekazanych sum ubezpieczen jest dodatnia
         * @param  {Object} suContainer wycinek dataContainer.suList
         * @return {Boolean} true, gdy co najmniej jedna suma dodatnia
         */
        this._anySumGiven = function(suContainer) {
          var anyGiven = false;
          lsnNg.forEach(suContainer, function(sum) {
            if (parseInt(sum, 10) > 0) {
              anyGiven = true;
              return false;
            }
            return true; //continue
          });
          return anyGiven;
        };

        /**
         * zwraca pierwszy zaznaczony wariant z przekazanego obiektu
         * @param  {Object} variants wycinek mainDataContainer.selectedVariants
         * @return {String|null} kod wariantu zaznaczonego lub null gdy żaden niezaznaczony
         */
        this._gettSelectedVariant = function(variants) {
          var selected = null;
          lsnNg.forEach(variants, function(checked, vnt) {
            if (checked) {
              selected = vnt;
              return false;
            }
            return true;
          });
          return selected;
        };

        /**
         * buduje obiekt komunikatu związanego z walidacją wyboru produktu i sumy ubezpieczenia
         * @param  {String} objType [description]
         * @param  {String} prodCode   [description]
         * @param  {String} objId   [description]
         * @return {Object} komunikat
         */
        this._buildProductSelectionMessage = function(objType, prodCode, objId) {
          var msg = {
              code: 'PRODSELECTION',
              type: CONSTANTS.ERROR_TYPE.warn,
              message: '',
              attributes: {},
              viewReference: { //tutaj określimy parametry, dzieki którym przeniesiemy do odpowiedniego widoku
                productCode: prodCode,
                productType: objType,
                objectId: null
              }
            },
            prodName = resourceHelper.getProductName(prodCode, 'BASKET_NAME');
          switch (objType) {
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              if (objId === CONSTANTS.NO_OBJECT) {
                msg.message = $filter('translate')('validator.selectedProductWithoutObjectOfInsurance', {componentCode: 'sp2', params: {prodName: prodName}});
              } else {
                msg.viewReference.objectId = objId;
                msg.message = $filter('translate')('validator.selectedProductWithoutSumInsured', {componentCode: 'sp2', params: {prodName: prodName}});
              }
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON:
              msg.message = $filter('translate')('validator.selectedProductWithoutPerson', {componentCode: 'sp2', params: {prodName: prodName}});
              break;
            default:
          }
          return msg;
        };

      };
      return new ApplicationValidator();
    }
  ]);