angular.module('ihestiaRestServicesBase')

/**
 * klauzule produktowe
 */
  .service('productClausesSvc', ['IHestiaRestServiceAbstractDef', 'IHestiaRestServiceProviderAbstractDef', function(IHestiaRestServiceAbstractDef, IHestiaRestServiceProviderAbstractDef) {

    /**
     * Główny konstruktor
     */
    var ProductClausesSvc = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      var self = this;
      this.resource = 'applications';
      this.businessArea = 'insurance';
      this.port = '8201';
      this.path = 'RestApplications';

      /**
       * pobranie klauzul produktowych dla wniosku
       *
       * @param {String} id
       * @param {String} appMode
       * @param {String} offerId
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getClauses = function(id, appMode, offerId, callback, errorCallback, httpParams) {
        var provider = new IHestiaRestServiceProviderAbstractDef();
        var headers = provider.getNewRequestHeaders();
        headers['AP-User-Type'] = 'SaleNet';
        headers['AP-Mock-Response'] = false;
        headers['AP-Application'] = 'undefined';
        headers['AP-Brand'] = 'IHESTIA';
        headers['AP-Platform'] = 'iHestia';
        headers['AP-Access-Channel'] = 'Www';

        var endpoint = offerId ? 'productClauses/' + offerId : 'productClauses'; 
        if(appMode === 'policy') {
          self.resource = 'policies';
        }
        if(typeof httpParams === 'undefined' || httpParams === null)
        {
          httpParams = {};
        }

        httpParams.allowedStatuses = [404, 408, 500];
        httpParams.headers = headers;
        return self.get(id, null, endpoint, callback, errorCallback, httpParams);
      };

    };
    
    return new ProductClausesSvc();
  }]);