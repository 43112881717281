angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorMessageThreadModel', ['IKomunikatorMessageReceivedModel', 'iKomunikatorConstants', 'ikomunikatorCommonHelper',
    function(MessageReceivedModel, iKomunikatorConstants, ikomunikatorCommonHelper) { //eslint-disable-line angular/di

    var MessageThreadModel = function() {
      var self = this;

      /**
       * Wiadomości
       * @type {Array}
       */
      this.messages = [];

      /**
       * Identyfikator wątku
       * @type {String}
       */
      this.id = null;

      /**
       * Status wiadomości
       * @type {type}
       */
      this.status = null;

      /**
       * id wątku wiadomości
       * @type {String}
       */
      this.messageThreadId = null;
      //przychodzi na tym albo na tym w zależności od usługi
      this.threadId = null;
      
      this.recipients = [];
      this.subject = null;
      this.subjectContextItem = null;
      this.lastMessageDate = null;
      this.creationDate = null;
      this.sendDate = null;
      this.originatorInitials = null;
      this.originator = null;
      //kanał wysyłki/wpływu ostatniej wiadomości (może być kilka po przecinku)
      this.lastMessageChannel = null;
      this.customFields = null;
      this.lastMessage = null;
      /**
       * Wciąganie danych z restów do modelu
       * @param  {Object} data dane z rest
       * @return {IKomunikatorAbstractModel}      self
       */
      this.fromRest = function(data) {
        angular.forEach(data, function(propertyValue, propertyCode) {
          if (!angular.isFunction(propertyValue) && angular.isDefined(self[propertyCode])) {
            self[propertyCode] = propertyValue;
            if (propertyCode === 'messages') {
              var messages = [];
              angular.forEach(self.messages, function(msgData) {
                var message = new MessageReceivedModel();
                message.setDataFromRest(msgData);
                messages.push(message);
              });

              self.messages = messages;
            }
          }
        });

        return self;
      };

      /**
       * [getDate pobiera date wątku]
       * @return {[type]} [description]
       */
      this.getDate = function() {
        var lastMessageDate = new XDate(self.lastMessageDate);
        return lastMessageDate.valid() ? lastMessageDate.toString('yyyy-MM-dd') : '';
      };
 
      /**
       * [getTime pobiera czas wątku]
       * @return {[type]} [description]
       */
      this.getTime = function() {
        var lastMessageDate = new XDate(self.lastMessageDate);
        return lastMessageDate.valid() ? lastMessageDate.toString('HH:mm') : '';
      };

      /**
       * [getCreationDate pobiera date wątku]
       * @return {[type]} [description]
       */
      this.getCreationDate = function() {
        var creationDate = new XDate(self.creationDate);

        return creationDate.valid() ? creationDate.toString('yyyy-MM-dd') : '';
      };
      

      /**
       * [getSendDate pobiera date nadania dla komunikacji listowej]
       * @return {[type]} [description]
       */
      this.getSendDate = function() {
        var sendDate = '';
        if(angular.isDefined(self.customFields.sendDate) && self.customFields.sendDate !== null) {
          sendDate = new XDate(self.customFields.sendDate.value);
          sendDate = sendDate.valid() ? sendDate.toString('yyyy-MM-dd') : '';
        }        

        return sendDate;
      };

      /**
       * [getLastMessageChannel zwraca kanał przesłanej wiadomości]
       * @return {[type]} [description]
       */
      this.getLastMessageChannel = function() {
        return self.lastMessageChannel === null ? 'KOMUNIKAT' : self.lastMessageChannel.split(',')[0].toUpperCase();
      };

      /**
       * [isUnread sprawdza czy wątek jest odczytany]
       * @return {Boolean} [description]
       */
      this.isUnread = function(){
        return self.status === iKomunikatorConstants.THREAD_STATUS_UNREAD;
      };

      this.getOriginatorInfo = function(){
        return ikomunikatorCommonHelper.getUserInfoForFullPanelPage(self.originator);
      };

      this.getRecipientInfo = function(){
        return ikomunikatorCommonHelper.getUserInfoForFullPanelPage(self.recipients[0]);
      };
    };

    return MessageThreadModel;
  }]);