angular.module('lsnBase.dateTime')
  .provider('lsnDateTimeHelper', [function() {
    var self = this;
    /**
     * środowisko, dla którego sprawdzamy godzinę i datę
     * @type {String} dostępne opcje:
     * 'www' - aplikacja webowa
     * 'mobile' - aplikacja mobilna
     */
    this.env = 'www';

    /**
     * funkcja zwracająca aktualny czas i datę w obiekcie XDate (domyślnie czas pobierany z maszyny klienta)
     * UWAGA
     * w tej metodzie powinno być uzależnienie od env - środowiska, w któ®ym sprawdzamy czas
     * @return {XDate}
     */
    this.fetchTimeFunc = function() {
      return new XDate();
    };

    var DateTimeHelper = function() {
      /**
       * zwraca czas domyslnie w formacie HH:mm
       * @param  {String} [format] format czasu dla XDate
       * @return {String} czas
       */
      this.getTime = function(format) {
        format = format || 'HH:mm';
        return self.fetchTimeFunc().toString(format);
      };

      /**
       * zwraca datę domyslnie w formacie 'yyyy-MM-dd'
       * @param  {String} [format] format daty dla XDate
       * @return {String} data
       */
      this.getDate = function(format) {
        format = format || 'yyyy-MM-dd';
        return self.fetchTimeFunc().toString(format);
      };
    };

    var dateTimeHelperInstance = new DateTimeHelper();

    /**
     * zwraca DateTimeHelper
     * @return {DateTimeHelper}
     */
    this.getDateTimeHelper = function() {
      return dateTimeHelperInstance;
    };

    this.$get = function() {
      return dateTimeHelperInstance;
    };

  }]);