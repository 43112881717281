angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaDashboardSvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaDashboardSvc = function() {
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'dashboard';
      };

      return new PzaDashboardSvc();
    }]);