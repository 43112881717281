angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorSearchPersonCentralCtrl', ['$rootScope', '$scope', 'ihestiaIkomunikatorSearchPersonCentralHelper', '$timeout', 'ihestiaIkomunikatorActionHelper', 'iKomunikatorConstants',
    function($rootScope, $scope, ihestiaIkomunikatorSearchPersonCentralHelper, $timeout, ihestiaIkomunikatorActionHelper, iKomunikatorConstants) {
      $scope.subjects = [];

      $scope.numericSettings = {
        regon: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        },
        nip: {
          allowLeadingZeros: true,
          decimals: 0,
          min: 0,
          parseToString: true
        }
      };

      $scope.init = function() {
        $scope.tplData = ihestiaIkomunikatorSearchPersonCentralHelper.tplData;
        $scope.modalData.subjectType = $scope.tplData.searchFilters.filters.subject_class[0];
        ihestiaIkomunikatorSearchPersonCentralHelper.setPlaceolder();
        ihestiaIkomunikatorSearchPersonCentralHelper.loadDicts();
        $scope.fireListeners();
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ikomunikatorSearchPersonCentral', function() {
          $scope.showNewMesageModal();
        });
        $scope.$on('checkTopReached', function(event, data) {
          if (!$scope.tplData.loading && data.reachedTop) {
            $timeout(function() {
              ihestiaIkomunikatorSearchPersonCentralHelper.searchNext();
            }, 0);
          }
        });
        $scope.$on('onScrollShowHide', function(event, data) {
          $scope.tplData.scrollVisible = data.scrollShowed;
        });
        // modal header - client type change
        $scope.$watch('modalData.subjectType', function(type) {
          $scope.tplData.searchFilters.filters.subject_class[0] = type;
          $scope.personTypeChanged();
        });
      };


      $scope.showNewMesageModal = function(personSearched) {
        ihestiaIkomunikatorSearchPersonCentralHelper.lastFilterText = $scope.tplData.searchFilters.phrase;
        ihestiaIkomunikatorSearchPersonCentralHelper.lastSubjectClass = $scope.tplData.searchFilters.filters.subject_class[0];
        ihestiaIkomunikatorSearchPersonCentralHelper.lastOrganizationData = $scope.tplData.organizationData;
        var data = {};
        if (personSearched) {
          if ($scope.tplData.searchFilters.filters.subject_class[0] === iKomunikatorConstants.CONTACT_ROLE_CLIENT
            || $scope.tplData.searchFilters.filters.subject_class[0] === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            data.clientSearched = personSearched;
            data.subjectType = $scope.tplData.searchFilters.filters.subject_class[0];
          } else {
            data.context = {
              channelSelected: iKomunikatorConstants.CHANNEL_NAME_EMAIL,
              channels: [iKomunikatorConstants.CHANNEL_NAME_EMAIL, iKomunikatorConstants.CHANNEL_NAME_SMS]
            };
            if (iKomunikatorConstants.PLATFORM_JUPITER === personSearched.platform || iKomunikatorConstants.PLATFORM_JUPITER_PLUS === personSearched.platform) {
              data.context.channels.unshift(iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT);
            }
            data.personRole = $scope.tplData.searchFilters.filters.subject_class[0];
            data.personSearched = personSearched;
          }
        } else {
          data.newRecipient = true;
        }
        ihestiaIkomunikatorActionHelper.showNewMesageModal(data);
        ihestiaIkomunikatorSearchPersonCentralHelper.hideModal();
      };

      $scope.searchRecipients = function() {
        if ($scope.tplData.loading) {
          $scope.tplData.reloadSearchAgain = true;
        } else {
          ihestiaIkomunikatorSearchPersonCentralHelper.reloadRecipients();
        }
      };

      $scope.sortBy = function(columnName) {
        ihestiaIkomunikatorSearchPersonCentralHelper.sortBy(columnName);
      };

      $scope.personTypeChanged = function() {
        ihestiaIkomunikatorSearchPersonCentralHelper.onSubjectTypeChange();
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorSearchPersonCentralHelper.setClearData();
      });

      $scope.onCountryCodeChange = function() {
        ihestiaIkomunikatorSearchPersonCentralHelper.onCountryCodeChange();
      };

      $scope.init();
    }
  ]);
