angular.module('salesPath2')
  .service('communicationPersonsModalHelper', ['CONSTANTS', 'sp2CommonHelper', 'dataContainerHelper', 'mainDataContainer', 'actionHelper', '$timeout', 'coownerHelper', '$filter', 'personModalHelper', 'bonusMalusHelper', 'vehicleHelper', 'coownerModalHelper',
    function(CONSTANTS, sp2CommonHelper, dataContainerHelper, mainDataContainer, actionHelper, $timeout, coownerHelper, $filter, personModalHelper, bonusMalusHelper, vehicleHelper, coownerModalHelper) {
      var CommunicationPersonsModalHelper = function() {

        var self = this;

        /**
         * domyślne ustawienia nazwy pojazdu dla modala komunikacji
         * @type {Object}
         */
        this.DEFAULT_VEHICLE_INFO_SETTINGS = {
          elements: [{
            field: 'mark'
          }, {
            field: 'model',
            lastInLine: true
          }],
          maxLength: 150,
          lineSeparator: '\n'
        };

        this.showedPersonBMFromList = false;

        this.modals = {
          communicationPersonsModal: {
            settings: {
              treatLabelAsI18nCode: true,
              okBtnDisabled: false,
              title: 'Ubezpieczeni',
              size: 'lg',
              okBtnName: false, //przy nadpisaniu footera musimy chyba ustawić te flagi na false..
              cancelBtnName: false,
              additionalFooterTpl: 'salesPath2Template/common/communicationPersons/communicationPersonsModalFooter.tpl.html',
              addCoownerCallback: function() {
                self.addCoowner();
              }
            }
          }
        };

        /**
         * ustawianie tytułu modala
         */
        this.setModalTitle = function() {
          var vehicleInfo = vehicleHelper.getVehicleInfo(mainDataContainer.vehicles[mainDataContainer.selectedVehicle], self.DEFAULT_VEHICLE_INFO_SETTINGS);
          self.modals.communicationPersonsModal.settings.title = 'Ubezpieczeni - ' + vehicleInfo;
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
              if (coownerModalHelper.editCoownerModalData.objectType !== CONSTANTS.PRODUCT_TYPE_LOCALIZATION && angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PERSON_CONTEXT_COOWNER) {
                sp2CommonHelper.showModal('communicationPersonsModal');
              }
              break;
            case 'personAdded':
              if (coownerModalHelper.editCoownerModalData.objectType !== CONSTANTS.PRODUCT_TYPE_LOCALIZATION && angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PERSON_CONTEXT_COOWNER) {
                self.onPersonAdded(arguments[1].id);
              }
              break;
            case 'vehicleBmModalClosed':
              self.vehicleBmModalClosed();
              break;
            default:
          }
        };

        /*
         *akcja przy dodaniu nowego coownera
         */
        this.onPersonAdded = function(personId) {
          var object = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle);
          var coowners = object.getAdditionalData('coowners');

          coowners.push(personId);

          $timeout(function() {
            actionHelper.runAction('coownersChanged', {
              objectType: CONSTANTS.PRODUCT_TYPE_VEHICLE,
              objectId: mainDataContainer.selectedVehicle
            });
          }, 0);

          sp2CommonHelper.showModal('communicationPersonsModal');

          actionHelper.runAction('saveApplication');
        };

        /**
         * dodanie nowego współwłaściciela (z okna listy)
         */
        this.addCoowner = function() {
          sp2CommonHelper.hideModal('communicationPersonsModal');
          var objectName = coownerHelper.getObjectName(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle);

          var title = $filter('translate')('sp2.modal.addCoowner.title', {
            componentCode: 'sp2'
          }) + ' ' + objectName;
          var titleSide = $filter('translate')('sp2.modal.addCoowner.titleSide', {
            componentCode: 'sp2'
          });
          var searchTitle = $filter('translate')('sp2.modal.addCoowner.searchTitle', {
            componentCode: 'sp2'
          }) + ':';

          $timeout(function() {
            personModalHelper.openPopupAddPerson({
              context: CONSTANTS.PERSON_CONTEXT_COOWNER,
              title: title,
              titleSide: titleSide,
              searchTitle: searchTitle,
              searchHeader: title,
              editHeader: title,
              excludeIds: coownerHelper.getOwners(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle)
            });
          }, 0);
        };

        this.vehicleBmModalClosed = function() {
          if (self.showedPersonBMFromList) {
            self.showedPersonBMFromList = false;
            sp2CommonHelper.showModal('communicationPersonsModal');
          }
        };

      };

      return new CommunicationPersonsModalHelper();
    }
  ]).run(['communicationPersonsModalHelper', 'actionHelper',
  function(communicationPersonsModalHelper, actionHelper) {
    actionHelper.registerHelper('communicationPersonsModalHelper', communicationPersonsModalHelper);
  }
]);