angular.module('salesPath2')
  .controller('groupEditModalCtrl', ['$scope', 'personGroupHelper', '$timeout', 'actionHelper', 'groupModalHelper',
    function($scope, personGroupHelper, $timeout, actionHelper, groupModalHelper) {
      $scope.modalData = groupModalHelper.currentlyEditedGroupData;
      $scope.listeners = {};
      $scope.personCountNumericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: false
      };

      $scope.init = function() {
        $scope.setInitialData();
        $scope.fireListeners();
      };

      $scope.setInitialData = function() {
        if ($scope.modalData.groupData === null) {
          $scope.modalData.groupData = $scope.modalData.group.getData();
          $scope.fillViewData();
        }
      };

      $scope.fillViewData = function() {
        var groupDynVals = $scope.modalData.group.get('dynamicValues');
        angular.forEach($scope.RESOURCES.INSURED_AGE_INTERVALS, function(item) {
          var interval = angular.copy(item);
          interval.value = (angular.isDefined(groupDynVals[item.BOS_CODE]) && groupDynVals[item.BOS_CODE] !== null) ? groupDynVals[item.BOS_CODE] : '';
          $scope.modalData.insuredAgeIntervals.push(interval);
        });
        $scope.refreshPersonCount();
      };

      $scope.fireListeners = function() {
        $scope.listeners.eventOnBtnClicked = $scope.$on('iHestiaCommonModalOkBtnClicked.groupEditModal', function($event, obj) {
          if (obj.validForm) {
            $scope.save();
            groupModalHelper.clearGroupEditModalData();
            $scope.clearListeners(); // remove listener.
          } else {
            $event.preventDefault();
          }
        });

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.listeners.hideEvent = $scope.$on('iHestiaCommonModalHideEvent.groupEditModal', function() {
          $scope.clearListeners(); // remove listener.
        });

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.listeners.cancelEvent = $scope.$on('iHestiaCommonModalCancelBtnClicked.groupEditModal', function() {
          $timeout(function() {
            actionHelper.runAction('cancelEditGroup', {
              groupId: $scope.modalData.editedId,
              context: $scope.modalData.context
            });
            groupModalHelper.clearGroupEditModalData();
          }, 0);
          $scope.clearListeners(); // remove listener.
        });
      };

      //czysci wszystkie listenery
      $scope.clearListeners = function() {
        angular.forEach($scope.listeners, function(val) {
          val();
        });
        $scope.listeners = [];
      };
      /**
       * zapis grupy
       */
      $scope.save = function() {
        $scope.fillGroupModel();
        personGroupHelper.save($scope.modalData.groupData, $scope.modalData.context, $scope.modalData.editedId);
      };

      /**
       * wypelnienie danych grupy na podstawie informacji z szablonu
       * @return {[type]} [description]
       */
      $scope.fillGroupModel = function() {
        var intervalDynVals = {};
        angular.forEach($scope.modalData.insuredAgeIntervals, function(item) {
          intervalDynVals[item.BOS_CODE] = (item.value === '') ? null : parseInt(item.value, 10);
        });
        angular.extend($scope.modalData.groupData.dynamicValues, intervalDynVals);
      };

      /**
       * odswieza liczbe wprowadzonych osób
       */
      $scope.refreshPersonCount = function() {
        var count = 0;
        angular.forEach($scope.modalData.insuredAgeIntervals, function(item) {
          if (item.value !== '' && item.value !== null) {
            count += parseInt(item.value, 10);
          }
        });
        $scope.modalData.personCount = count;
      };

      $scope.init();
    }
  ]);