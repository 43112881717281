angular.module('spaceChat.client')
  .controller('spaceChatClientModalsInjectorCtrl', ['$scope', 'spaceChatHelper', 'ihestiaSsoBaseInfoHelper', 'ihestiaCrossTabHelper', 'detectorUserActiveHelper', 'spaceChatSvc', 'lsnConfirmModalHelper',
    function($scope, spaceChatHelper, ihestiaSsoBaseInfoHelper, ihestiaCrossTabHelper, detectorUserActiveHelper, spaceChatSvc, lsnConfirmModalHelper) {

      $scope.talkChatData = {
        threadId: null, //id wątku chata który użyjemy do jego zamknięcia
        chatActive: false //czy chat jest aktywny
      };

      $scope.chatWelcomePopupSettings = {
        top: 62,
        right: 14,
        show: false,
        confirmClose: false,
        title: 'Witamy w SpaceChat',
        bodyTemplate: 'ihestia-widgets-templates-app/spaceChat/client/welcomePopup/chatWelcomePopupBody.tpl.html',
        consultantAvailable: false
      };

      /**
       * agent zamyka okno chatu
       * @return {[type]} [description]
       */
      $scope.onTalkWindowClose = function() {
        if ($scope.talkChatData.chatActive) {
          spaceChatHelper.closeChat($scope.talkChatData.threadId, true);
          spaceChatHelper.stopPoll(); //zatrzymujemy nasłuchiwanie, ale nie zamykamy chata
          $scope.talkChatData.chatActive = false;
        }

        spaceChatHelper.setCookieText('');
        spaceChatHelper.setCookieRoom('');
      };

      /**
       * agent chce zakończyć chat
       */
      $scope.onChatClose = function()
      {
        //pokazujemy modal z potwierdzeniem
        lsnConfirmModalHelper.showConfirmModal({
          content: 'Czy na pewno chcesz zakończyć rozmowę?'
        }).then(function(){
          if ($scope.talkChatData.chatActive) {
            spaceChatHelper.closeChat($scope.talkChatData.threadId, true);
            $scope.talkChatData.chatActive = false;
          }

          $scope.chatTalkPopupSettings.show = false;

          spaceChatHelper.setCookieText('');
          spaceChatHelper.setCookieRoom('');
        }, lsnNg.noop);
      };

      $scope.chatTalkPopupSettings = {
        top: 62,
        right: 14,
        show: false,
        allowResize: true,
        confirmClose: true,
        dynamicData: {
          additionalTitleText: '',
          disableClose: false
        },
        onClose: $scope.onTalkWindowClose,
        closeText: 'Czy na pewno chcesz zakończyć rozmowę?',
        title: 'Witamy w SpaceChat',
        bodyTemplate: 'ihestia-widgets-templates-app/spaceChat/client/talkPopup/chatTalkPopupBody.tpl.html'
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        spaceChatHelper.startAgentChatMethod = $scope.startChat;

        $scope.checkShouldOpenChat();
        $scope.initEventActvieTab();
      };

      /**
       * Różne eventy
       * @return {ihestiaSsoBaseInfoHelper} [description]
       */
      $scope.initEventActvieTab = function() {
        $scope.$on('ihestiaCrossTab:message', function(event, data) {
          if (data.code === 'newChatWindowOpened') {
            $scope.chatTalkPopupSettings.show = false; //na innej zakładce otwarto chat, więc zamykamy na tej
            spaceChatHelper.stopPoll();
          }
        });

        detectorUserActiveHelper.registerCallbacks('onTabActivate', 'chatWindow', $scope.checkShouldOpenChat);
      };

      $scope.checkShouldOpenChat = function() {

        var chatWasOpenFor = spaceChatHelper.getCookieRoom();

        if (chatWasOpenFor !== '') {
          var hashActualUser = SparkMD5.hash(ihestiaSsoBaseInfoHelper.getCurrentUser().login);
          if (chatWasOpenFor === hashActualUser) { //na poprzedniej zakładce otwarty chat miał user z tym samym loginem
            if (!$scope.chatTalkPopupSettings.show) {
              $scope.chatTalkPopupSettings.show = true; //pokazujemy chat
              ihestiaCrossTabHelper.sendMessage({
                code: 'newChatWindowOpened'
              });
            }

          } else {
            //jesli nastapilo przelogowanie to czyscimy info i nie otwieramy okna
            spaceChatHelper.setCookieRoom('');
          }
        }
      };

      $scope.startChat = function() {
        $scope.$emit('showBlockUi');
        spaceChatSvc.get('available').then(function(res){
          if(res && res.data)
          {
            $scope.chatWelcomePopupSettings.consultantAvailable = true;
          }
          else
          {
            $scope.chatWelcomePopupSettings.consultantAvailable = false;
          }
          $scope.$emit('hideBlockUi');
          $scope.chatWelcomePopupSettings.show = true;
        }, function(){
          $scope.chatWelcomePopupSettings.consultantAvailable = false;
          $scope.$emit('hideBlockUi');
          $scope.chatWelcomePopupSettings.show = true;
        });
      };

      $scope.init();
    }
  ]);