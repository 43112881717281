angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaPersonEditForm', [function() {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/personEdit/personEditForm.tpl.html',
      restrict: 'E',
      transclude: {
        subjectFormEnd: '?personEditSubjectFormEnd',
        footer: '?personEditFooter',
        subjectFormStart: '?personEditSubjectFormStart'
      },
      scope: {
        subjectData: '=',
        customShowElements: '=?showElements',
        customDisabledElements: '=?disabledElements',
        fieldErrors: '=?',
        api: '=?',
        formWrapperClass: '=?',
        options: '=?',
        customLabels: '=?labels'
      }
    };
  }]);
