angular.module('salesPath2')
	.service('dcSelectionHelper', ['CONFIG', 'RESOURCES', 'CONSTANTS', 'actionHelper', 'resourceHelper', 'lsnArrayUnique', 'mainDataContainer', 'dcCommonHelper', 'lsnUtils',
		function(CONFIG, RESOURCES, CONSTANTS, actionHelper, resourceHelper, lsnArrayUnique, mainDataContainer, dcCommonHelper, lsnUtils) {
			var DcSelectionHelper = function() {
				var self = this;

				/**
				 * wybiera (ustawia globalny kontekst - mainDataContainer.selected...) obiekt(podmiot) określonego typu i o podanym clientId
				 * @param  {String} type typ obiektu
				 * @param  {String} clientId
				 */
				this.selectObject = function(type, clientId) { //eslint-disable-line consistent-return
					clientId = dcCommonHelper.idToString(clientId);

					var objectsCount = 0;
					angular.forEach(mainDataContainer.selectedVariants[type], function(data, dataId) {
						if (dataId !== CONSTANTS.NO_OBJECT) {
							objectsCount = objectsCount + 1;
						}
					});
					if (clientId === CONSTANTS.ALL_OBJECTS && objectsCount < 2) //zaznaczenie "wszystkie" jak żadnego nie ma lub jest tylko 1
					{
						return false;
					}

					var selectedObject = mainDataContainer.selectedLocalization;

					if (type === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
						selectedObject = mainDataContainer.selectedVehicle;
					} else if (type === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
						selectedObject = mainDataContainer.selectedSubject;
					}

					if (selectedObject === CONSTANTS.NO_OBJECT) {
						//przepisujemy wcześniej wybrane warianty
						mainDataContainer.selectedVariants[type][clientId] = mainDataContainer.selectedVariants[type][CONSTANTS.NO_OBJECT];
						delete mainDataContainer.selectedVariants[type][CONSTANTS.NO_OBJECT];

						//przepisujemy wcześniej wybrane dodatki
						if (CONFIG.MODULES.additions) {
							angular.forEach(RESOURCES.PRODADD, function(addData) {
								if (addData.TYPE === type) {
									if (angular.isDefined(addData.VARIANTS)) //dodatki z wariantami
									{
										angular.forEach(addData.VARIANTS, function(variant) {
											angular.forEach(mainDataContainer.selectedAdditions[addData.CODE][variant.CODE], function(objectId) {
												if (objectId === CONSTANTS.NO_OBJECT) {
													mainDataContainer.selectedAdditions[addData.CODE][variant.CODE].push(clientId);
												}
											});
										});
									} else //dodatki bez wariantów
									{
										angular.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
											if (objectId === CONSTANTS.NO_OBJECT) {
												mainDataContainer.selectedAdditions[addData.CODE].push(clientId);
											}
										});
									}
								}
							});
						}
						if (CONFIG.MODULES.extensions) {
							//przepisujemy wcześniej wybrane rozszerzenia
							angular.forEach(RESOURCES.EXTENSIONDEF, function(extData) {
								if (extData.TYPE === type) {
									angular.forEach(mainDataContainer.selectedExtensions[extData.CODE], function(objectId) {
										if (objectId === CONSTANTS.NO_OBJECT) {
											mainDataContainer.selectedExtensions[extData.CODE].push(clientId);
										}
									});
								}
							});
						}
					} else if (angular.isUndefined(mainDataContainer.selectedVariants[type][clientId]) && clientId !== CONSTANTS.ALL_OBJECTS) {
						mainDataContainer.selectedVariants[type][clientId] = resourceHelper.getDefaultVariantsForType(type);
					}

					if (type === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
						mainDataContainer.selectedLocalization = clientId;
					} else if (type === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
						mainDataContainer.selectedVehicle = clientId;
					} else if (type === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
						mainDataContainer.selectedSubject = clientId;
					}
					actionHelper.runAction('objectSelected', type, clientId);
				};

				/**
				 * kopiuje zaznaczone produkty i dodatki z jedego obiektu do drugiego
				 * @param  {String} productType typ produktu, ktorefo zaznaczenia maja byc skopiowane
				 * @param  {String|Number} sourceObjId źródłowy obiekt clientId
				 * @param  {String|Number} targetObjId docelowy obiekt clientId
				 */
				this.copyObjectSelection = function(productType, sourceObjId, targetObjId) {
					if (angular.isUndefined(mainDataContainer.selectedVariants[productType][sourceObjId])) {
						return;
					}
					sourceObjId = dcCommonHelper.idToString(sourceObjId);
					targetObjId = dcCommonHelper.idToString(targetObjId);
					// produkty
					mainDataContainer.selectedVariants[productType][targetObjId] = angular.copy(mainDataContainer.selectedVariants[productType][sourceObjId]);
					// dodatki
					if (CONFIG.MODULES.additions) {
						angular.forEach(RESOURCES.PRODADD, function(addData) {
							if (addData.TYPE === productType) {
								if (angular.isDefined(addData.VARIANTS)) //dodatki z wariantami
								{
									angular.forEach(addData.VARIANTS, function(variant) {
										angular.forEach(mainDataContainer.selectedAdditions[addData.CODE][variant.CODE], function(objectId) {
											if (objectId === sourceObjId) {
												mainDataContainer.selectedAdditions[addData.CODE][variant.CODE].push(targetObjId);
											}
										});
									});
								} else //dodatki bez wariantów
								{
									angular.forEach(mainDataContainer.selectedAdditions[addData.CODE], function(objectId) {
										if (objectId === sourceObjId) {
											mainDataContainer.selectedAdditions[addData.CODE].push(targetObjId);
										}
									});
								}
							}
						});
					}
					//rozszerzenia
					if (CONFIG.MODULES.extensions) {
						//przepisujemy wcześniej wybrane rozszerzenia
						angular.forEach(RESOURCES.EXTENSIONDEF, function(extData) {
							if (extData.TYPE === productType) {
								angular.forEach(mainDataContainer.selectedExtensions[extData.CODE], function(objectId) {
									if (objectId === sourceObjId) {
										mainDataContainer.selectedExtensions[extData.CODE].push(targetObjId);
									}
								});
							}
						});
					}
				};

				/**
				 * wybiera lokalizację
				 * @param  {String} clientId
				 */
				this.selectLocalization = function(clientId) {
					self.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, clientId);
				};

				/**
				 * wybiera lokalizację
				 * @param  {String} clientId
				 */
				this.selectVehicle = function(clientId) {
					self.selectObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, clientId);
				};

				/**
				 * wybiera lokalizację
				 * @param  {String} clientId
				 */
				this.selectSubject = function(clientId) {
					self.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
				};

				/**
				 * zwraca true jeśli osoba dodana do ryzyka (OCZP/NNW)) i ma zazaczony jakiś wariant
				 * @param  {Person} person
				 * @param  {string} riskCode
				 * @return {bool}   czy soba dodana do ryzyka i ma zaznaczony dowolny wariant
				 */
				this.personVariantSelected = function(person, riskCode) {
					var riskAdditionalData = person.getAdditionalData(riskCode);
					if (riskAdditionalData === null) //osoba nie została dodana do ryzyka
					{
						return false;
					}
					var variantSelected = false;
					if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(riskCode) !== -1) //możliwe wiele wariantów
					{
						lsnNg.forEach(riskAdditionalData.variants, function(selected) {
							if (selected) {
								variantSelected = true;
								return false;
							}
							return true; //continue
						});
					} else if (riskAdditionalData.variants !== null) {
						variantSelected = true;
					}
					return variantSelected;
				};

				/**
				 * czy produkt jest zaznaczony, jeśli nie podano objectId to sprawdza dla obecnego stanu matrycy
				 * @param  {string}  productCode kod produktu
				 * @param  {string|int}  objectId    id pojazdu/lokalizacji/osoby
				 * @return {Boolean}
				 */
				this.isProductSelected = function(productCode, objectId) {
					var variants = dcCommonHelper.getVariantsForProduct(productCode, objectId);
					var isProductSelected = false;
					lsnNg.forEach(variants, function(selected) {
						if (selected) {
							isProductSelected = true;
							return false;
						}
						return true; //continue
					});
					return isProductSelected;
				};

				/**
				 * czy wybrano jakikolwiek produkt z danego typu
				 * @param  {String}  productType
				 * @return {Boolean}
				 */
				this.isSelectedAnyProductOn = function(productType) {
					var isProductSelected = false;
					lsnNg.forEach(dcCommonHelper.getObjects(productType), function(obj, objectClientId) {
						lsnNg.forEach(resourceHelper.getProductsForType(productType), function(productCode) {
							if (self.isProductSelected(productCode, objectClientId)) //jest ryzyko dla obiektu
							{
								isProductSelected = true;
								return false;
							}
							return true; //continue
						});

						if (isProductSelected) {
							return false;
						}
						
						return true; //continue
					});
					return isProductSelected;
				};

				/**
				 * szuka i zwraca obiekt ryzyka z poprzedniej polisy na podstawie podanych parametrów
				 * UWAGA:
				 * niezdefiniowane parametry lub parametry z wartością null nie będą użyte przy wyszukiwaniu ryzyka
				 * @param  {String} [prodCode] kod produktu
				 * @param  {String} [riskCode] kod ryzyka
				 * @param  {String} [objId]    id obiektu/podmiotu ubezpieczonego
				 * @param  {String} [variant]  wariant ryzyka
				 * @return {null|RiskModel} ryzyko lub null gdy nieznaleziono
				 */
				this.getPreviouslySelectedRiskObj = function(prodCode, riskCode, objId, variant) {
					return self._findRiskObj('previouslySelectedRisksRefs', prodCode, riskCode, objId, variant);
				};

				/**
				 * szuka ryzyka z poprzedniej polisy lub aktualnie zaznaczonego
				 * UWAGA:
				 * niezdefiniowane parametry lub parametry z wartością null nie będą użyte przy wyszukiwaniu ryzyka
				 * @param  {String} container 'previouslySelectedRisksRefs' lub 'selectedRisksRefs'
				 * @param  {String} [prodCode] kod produktu
				 * @param  {String} [riskCode] kod ryzyka
				 * @param  {String} [objId]    id obiektu/podmiotu ubezpieczonego
				 * @param  {String} [variant]  wariant ryzyka
				 * @return {null|RiskModel} ryzyko lub null gdy nieznaleziono
				 */
				this._findRiskObj = function(container, prodCode, riskCode, objId, variant) {
					var dataArray = mainDataContainer[container],
						riskArray = (container === 'previouslySelectedRisksRefs' ? mainDataContainer.previousRisks : mainDataContainer.application.risks),
						searchParams = {};
					if (prodCode) {
						searchParams.product = prodCode;
					}
					if (riskCode) {
						searchParams.risk = riskCode;
					}
					if (objId) {
						searchParams.objId = objId;
					}
					if (variant) {
						searchParams.variant = variant;
					}
					var found = lsnUtils.findObjInArray(dataArray, searchParams);
					if (found === null) {
						return null;
					}
					var foundRisk = null;
					lsnNg.forEach(riskArray, function(risk) {
						if (risk.metaData.id === found.riskId) {
							foundRisk = risk;
							return false;
						}
						return true;
					});
					return foundRisk;
				};

				/**
				 * zwraca informacje o aktualnie zaznaczonych wariantach dla dodatku "wariantowego"
				 * @param  {String} addCode kod dodatku
				 * @param  {String} objId id obiektu powiązanego z dodatkiem
				 * @return {Object} obiekt
				 * {
				 * 	kod_wariantu: true|false //true gdy wybrany
				 * 	[,...]
				 * }
				 */
				this.getSelectedAddVariants = function(addCode, objId) {
					var selected = {},
						def = resourceHelper.getAddDef(addCode),
						isPackage = (def.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE);

					if (isPackage) {
						return mainDataContainer.selectedAdditions[addCode];
					}

					angular.forEach(mainDataContainer.selectedAdditions[addCode], function(objs, vCode) {
						selected[vCode] = objs.indexOf(objId) !== -1;
					});

					return selected;
				};

				/**
				 * usuwa wpisy dotyczące konkretnego obiektu w selectedVariants
				 * @param  {String} type wspierane typy: PRODUCT_TYPE_LOCALIZATION, PRODUCT_TYPE_VEHICLE, PRODUCT_TYPE_PERSON_GROUP
				 * @param  {String} clientId clientId obiektu
				 * @return {Boolean} true gdy usunieto
				 */
				this.deleteVariantSelection = function(type, clientId) {
					if (angular.isDefined(mainDataContainer.selectedVariants[type]) && angular.isDefined(mainDataContainer.selectedVariants[type][clientId])) {
						delete mainDataContainer.selectedVariants[type][clientId];
						return true;
					}
					return false;
				};

			};

			return new DcSelectionHelper();
		}
	]);