/**
 * przesunięcię scrolla powoduje również przesunięcie powiązanych scrolli
 */
angular.module('ihestiaCommonDirectives')
  .directive('scrollSynchronize', ['$document', '$timeout', function($document, $timeout){
      return {
        restrict: 'A',
        scope: {
          scrollSynchronizeApi: '='
        },
        link: function($scope, $element, $attrs){
          var scrollGroupName = $attrs.scrollSynchronize;

          //na wejściu pobieramy pozycję scroll'a z innych elementów
          //bo możliwe, że nasz scroll był wcześniej niewidoczny
          var setStartPosition = function(){
            var elementTop = $element.scrollTop();
            var elementLeft = $element.scrollLeft();
            var scrolled = false;
            $($document).find('[scroll-synchronize="' + scrollGroupName + '"]').each(function() {
              if(!$(this).is($($element)) && !scrolled)
              {
                if($(this).scrollTop() !== elementTop)
                {
                  $($element).scrollTop($(this).scrollTop());
                  scrolled = true;
                }
                if($(this).scrollLeft() !== elementLeft)
                {
                  $($element).scrollLeft($(this).scrollLeft());
                  scrolled = true;
                }
              }
            });
          };

          $scope.scrollTo = function(direction) //top/left
          {
            if($element)
            {
              if(direction === 'left')
              {
                $($element).scrollLeft(0);
              }
              else if(direction === 'top')
              {
                $($element).scrollTop(0);
              }
            }
          };

          if(angular.isArray($scope.scrollSynchronizeApi))
          {
            $scope.scrollSynchronizeApi.push({
              scrollTo: $scope.scrollTo
            });
          }

          //wybijamy się poza angularową fazę
          $timeout(setStartPosition, 0);

          var onScroll = function () {
            var elementTop = $element.scrollTop();
            var elementLeft = $element.scrollLeft();
            $($document).find('[scroll-synchronize="'+scrollGroupName+'"]').each(function() {
              if(!$(this).is($($element)))
              {
                if($(this).scrollTop() !== elementTop)
                {
                  $(this).scrollTop(elementTop);
                }
                if($(this).scrollLeft() !== elementLeft)
                {
                  $(this).scrollLeft(elementLeft);
                }
              }
            });
          };


          $element.on('scroll', onScroll);

          $scope.$on('$destroy', function() {
            $element.off('scroll', onScroll);
          });
        }
      };
    }]);