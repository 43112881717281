angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonComponentStatusSvc', ['ihestiaCommonAbstractComponentStatusSvc',
    function(ihestiaCommonAbstractComponentStatusSvc) {

      var ComponentStatusSvc = function() {
        ihestiaCommonAbstractComponentStatusSvc.apply(this, arguments);

        this.resource = 'component-status';
        this.businessArea = 'component-status';
      };
      return new ComponentStatusSvc();
    }
  ]);