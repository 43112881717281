angular.module('salesPath2')
  /**
   * model encji errora
   */
  .factory('ErrorEntityModel', [function ()
  {

    /**
     * encja bledu
     *
     * @constructor
     */
    var ErrorEntityModel = function()
    {
      
      /**
       * kod bledu
       * @type {string|int}
       */
      this.code = null;

      /**
       * typ bledu (error/warning/notice)
       *
       * @type {string}
       */
      this.type = null;

      /**
       * tresc komunikatu
       *
       * @type {string}
       */
      this.message = null;

      /**
       * obiekt dowolnych atrybutow dla errora
       *
       * @type {{}}
       */
      this.attributes = {};

      /**
       * obiekt z parametrami z opisu ryzyka
       *
       * @type {{}}
       */
      this.riskParams = {};

      /**
       * referencja do widoku, ktorego error dotyczy
       *
       * @type {*}
       */
      this.viewReference = null;

      /**
       * @param {string} key
       * @param {*} value
       */
      this.addAttribute = function (key, value)
      {
        this.attributes[key] = value;
      };
    };

    return ErrorEntityModel;
  }]);