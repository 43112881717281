/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuBase')
  .service('iHestiaMenuBaseHelper', ['ihestiaConfigHelper', 'ihestiaAgHelper', 'ihestiaCommonComponentStatusHelper', 'ihestiaSsoBaseInfoHelper', '$rootScope', 'iHestiaCommonModalHelper', 'ihestiaPlatformHelper',
    function(ihestiaConfigHelper, ihestiaAgHelper, ihestiaCommonComponentStatusHelper, ihestiaSsoBaseInfoHelper, $rootScope, iHestiaCommonModalHelper, ihestiaPlatformHelper) {

      var IHestiaMenuBaseHelper = function() {
        var self = this;

        this.logoData = {
          isSirius: false
        };

        /**
         * Kontekst w jakim występujemy (np. Client)
         * @type {String}
         */
        this.context = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;

        /**
         * Dostępność elementów menu w zależności od kontekstu
         * Domyślna wartość to true
         * @type {Object}
         */
        this.elementsAvailability = {
          clipboard: {
            Client: false
          },
          leftNavBar: {
            Client: false
          },
          searchBox: {
            Client: false
          },
          topMenu: {
            Client: false
          },
          userBox: {},
          help: {},
          ikomunikator: {},
          changeLnguage: {},
          taskCounter: {},
          basket: {
            Client: false
          },
          messageBox: {
            Client: false
          },
          basketLife: {
            Client: false
          },
          messageBoxLife: {
            Client: false
          },
          icContext: {},
          chatBot: {
            Client: false
          }
        };

        /**
         * Elementy nieaktywne dla charakteru jupiterowego
         * tablica asocjacyjna od aplikacji (rest.AP_CALLER)
         * @type {Object}
         */
        this._inactiveForJupiterChar = {
          ma: ['clipboard', 'leftNavBar', 'searchBox', 'topMenu', 'ikomunikator', 'basket', 'messageBox', 'userBoxStats', 'ihlogo', 'profile'],
          polisaonline: ['help', 'clipboard', 'leftNavBar', 'searchBox', 'topMenu', 'ikomunikator', 'basket', 'messageBox', 'userBoxStats', 'profile']
        };

        this.hiddenElements = {};

        this.isAppPublic = ihestiaConfigHelper.get('frontFlags', 'IS_PUBLIC') ? true : false;

        $rootScope.ihestiaMenuWindowWithHelpModalSettings = {
          title: 'Pomoc',
          okBtnName: 'Uruchom czat',
          thirdBtnName: 'Rozpocznij rozmowę wideo'
        };

        this.baseSetAvailability = null; //tu baseCtrl zarejestruję swoją funkcję do ustawienia dostępności elementów menu

        this.customUserData = null;
        this.userBoxSetCustomUser = null; //tu później userBoxCtrl zarejestruję swoją funkcję do ustawiania użytkownika dla naszego logowania w ocawaryjne
        this.customLogout = null; //tu później coś zarejestruje metodę wylogowanie bez SSO

        this.customBrand = null; //jeśli trzeba ręcznie brand ustawić to tu zachowujemy tą wartość
        this.userBoxBrandChanged = null;
        this.menuBaseBrandChanged = null;

        this.setCustomUser = function(userData)
        {
          self.customUserData = userData;
          if(self.userBoxSetCustomUser !== null)
          {
            self.userBoxSetCustomUser(self.customUserData);
          }
        };

        /**
         * Zwraca dostępne w menu elementy
         * @return {Object} dostępność menu ({clipboard: true, ...})
         */
        this.getElementsAvailability = function() {
          var elementsAvailability = {};
          angular.forEach(self.elementsAvailability, function(element, elementCode) {
            if (!self.isAppPublic && element[self.context] !== false && !self.hiddenElements[elementCode]) {
              elementsAvailability[elementCode] = true;
            }
          });

          // EHNLJUPIT-3571
          if(ihestiaPlatformHelper.isJupiterAgent()) {
            var appCaller = ihestiaConfigHelper.get('rest').AP_CALLER + '';
            appCaller = appCaller.toLowerCase();
            angular.forEach(self._inactiveForJupiterChar, function(elements, caller){
              if(caller === appCaller) {
                angular.forEach(elements, function(element){
                  elementsAvailability[element] = false;
                });
              }
            });
          }

          var character = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter();
          var availableContexts = [];
          if(character && character.ossContextInfo && character.ossContextInfo.AvailableInsuranceCompanies) {
            availableContexts = JSON.parse(character.ossContextInfo.AvailableInsuranceCompanies);
          }
          if(ihestiaConfigHelper.get('ic', 'mode') === 'MIXED' && self.context !== 'Client' && availableContexts.length > 1 && !ihestiaConfigHelper.get('ic', 'disabled')) {
            elementsAvailability.icContext = true;
          } else {
            elementsAvailability.icContext = false;
          }

          if(ihestiaConfigHelper.get('ic', 'activeContext') === 'EHL') {
            elementsAvailability.userBoxStats = false;
          }

          // IHESTLIFE-3545 - no patron for life
          if(ihestiaConfigHelper.get('ic', 'activeContext') === 'EHL' && self.context === 'Client') {
            elementsAvailability.help = false;
          }

          if(ihestiaConfigHelper.get('chatbot', 'chatbotEnabled') && self.context !== 'Client') {
            elementsAvailability.chatBot = true;
          } else {
            elementsAvailability.chatBot = false;
          }
          
          // IHESTIAGEN-3082
          var hasNecessaryAtoms = ihestiaAgHelper.hasPermissionFor('Public', 'DashboardServices') &&
                                  (ihestiaAgHelper.hasPermissionFor('Public', 'AgentService') || ihestiaAgHelper.hasPermissionFor('Public', 'AppDashboard'));
          var hasSuitableCharacter = self.context === 'Worker' || self.context === 'Central' || self.context === 'SalesProfile' || self.context === 'ProContactWorker';
          
          elementsAvailability.taskCounter = hasNecessaryAtoms && hasSuitableCharacter;

          return elementsAvailability;
        };

        /**
         * Ustawia elementy ktore nie maja sie pokazac w menu
            * UWAGA Jesli ktos chce wylaczyc dzialanie, nalezy metode te ustawiac w sekcji config
         * i nastepnie we wszystkich run w helperach odczytac czy element jest dostepny za pomoca metody getElementsAvailability
         */
        this.hideElement = function(elementCode) {
          self.hiddenElements[elementCode] = true;
        };

        /**
         * [refreshItemsPermissions description]
         * @param  {[type]} items [description]
         * @return {[type]}       [description]
         */
        this.refreshItemsPermissions = function(items) {
          var symbolPublic = ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC');
          var agSymbol;

          //menu
          angular.forEach(items, function(item) {
            if (typeof item.permissionCode !== 'undefined') {
              agSymbol = ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_' + item.permissionCode);
              item.disabled = !ihestiaAgHelper.hasPermissionFor(symbolPublic, agSymbol) || !ihestiaCommonComponentStatusHelper.isComponentEnabledByAgSymbol(agSymbol);
            }

            angular.forEach(item.items, function(subitem) {
              if (typeof subitem.permissionCode !== 'undefined') {

                agSymbol = ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_' + subitem.permissionCode);
                var isAppEnabled = ihestiaAgHelper.hasPermissionFor(symbolPublic, agSymbol) && ihestiaCommonComponentStatusHelper.isComponentEnabledByAgSymbol(agSymbol);

                var currentUser = ihestiaSsoBaseInfoHelper.getUser();
                if (isAppEnabled && subitem.permissionCode === 'CLIENT') {
                  subitem.disabled = currentUser.character.type !== 'Client';
                } else {
                  subitem.disabled = !isAppEnabled;
                }

                // sprawdzamy, czy ta opcja nie jest zablokowana dla tożsamości, na której jesteśmy zalogowani
                if (angular.isArray(subitem.disabledForCharacters)) {
                  angular.forEach(subitem.disabledForCharacters, function(characterType) {
                    if (self.context === characterType) {
                      subitem.disabled = true;
                    }
                  });
                }
              }
            });
          });
        };

        this.showWindowWithHelp = function() {
          iHestiaCommonModalHelper.showModal('ihestiaMenuWindowWithHelp');
        };

        this.hideWindowWithHelp = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaMenuWindowWithHelp');
        };

         /**
         * funkcja sprawdza czy aplikacja uruchomiona jest w trybie direct
         */
        this.isDirect = function(){
          var brand = ihestiaConfigHelper.get('BRAND');
          if(self.customBrand !== null)
          {
            brand = self.customBrand;
          }
          return brand === 'DIRECT';
        };

        /**
         * ustawiamy z opóźnieniem brand
         * @param {[type]} brand [description]
         */
        this.setCustomBrand = function(brand)
        {
          self.customBrand = brand;
          if(self.userBoxBrandChanged !== null)
          {
            self.userBoxBrandChanged();
          }
          if(self.menuBaseBrandChanged !== null)
          {
            self.menuBaseBrandChanged();
          }
        };

        this.setSiriusLogo = function() {
          self.logoData.isSirius = true;
        };

        this.getLogoData = function() {
          return self.logoData;
        };
      };

      return new IHestiaMenuBaseHelper();
    }
  ]);
