angular.module('ihestiaIkomunikator')
  .factory('iKomunikatorFtsSearchSvc', [ 'IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {

    var FtsSearchSvc = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      var self = this;
      this.businessArea = 'fts';
      this.resource = 'search';

      /**
       * Wyszukiwanie proste odbiorcow z fts 
       * @param  {object}   data                 [description]
       * @param  {Function} callback             [description]
       * @param  {[type]}   errorCallback        [description]
       * @return {[type]}                        [parametry wywołania]
       */
      this.postSearch = function(data, callback, errorCallback, httpParams)
      {
        return self.post(null, data, '', callback, errorCallback, httpParams);
      };
    };

    return new FtsSearchSvc();
  }])
  ;