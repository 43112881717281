angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaOperationConstants', [function(){
    var CONSTANTS = {
      CONTEXT_PD: 'pd',
      CONTEXT_MS: 'ms',
      CONTEXT_AGENCY: 'agent',
      CONTEXT_WORKER: 'worker',
      OPERATION_SUBSTRACT_VALUE: 'SUBSTRACT_VALUE',
      OPERATION_SUBSTRACT_PERCENT: 'SUBSTRACT_PERCENT',
      OPERATION_ADD: 'ADD',
      OPERATION_REMOVE: 'REMOVE'
    };

    return CONSTANTS;
  }])
  .factory('ihestiaPzaOperationModalHelper', ['iHestiaCommonModalHelper', 'IhestiaPzaOperationModel', 'ihestiaPzaOperationConstants', '$q', 'ihestiaCommonErrorHandler', 'IhestiaPzaWorkerOperationModel', 'ihestiaPzaAppConstants', 'ihestiaPzaCommonHelper', 'IhestiaPzaDictionaryModel',
    function(iHestiaCommonModalHelper, IhestiaPzaOperationModel, ihestiaPzaOperationConstants, $q, ihestiaCommonErrorHandler, IhestiaPzaWorkerOperationModel, ihestiaPzaAppConstants, ihestiaPzaCommonHelper, IhestiaPzaDictionaryModel) {
      var OperationModalHelper = function() {
        var self = this;

        this.context = null;
        this.programData = null;
        this.operationData = null;
        this.itemList = [];
        this.promiseCallbacks = null;

        var appConst = ihestiaPzaAppConstants;
        var conConst = ihestiaPzaOperationConstants;
        /**
         * Matryca dostępności operacji
         * pierwszy poziom - rola
         * drugi poziom - operacja => dostępne w kontekstach
         * @type {Object}
         */
        var operationsPermissions = {};
        operationsPermissions[appConst.USER_TYPE_CENTRAL] = {
          changeBudget: [conConst.CONTEXT_PD, conConst.CONTEXT_MS, conConst.CONTEXT_AGENCY],
          changeGroup: [conConst.CONTEXT_PD, conConst.CONTEXT_MS, conConst.CONTEXT_AGENCY],
          changeCondition: [conConst.CONTEXT_AGENCY],
          block: [conConst.CONTEXT_AGENCY],
          remove: [conConst.CONTEXT_AGENCY]
        };
        operationsPermissions[appConst.USER_TYPE_PD] = {
          changeBudget: [conConst.CONTEXT_MS, conConst.CONTEXT_AGENCY],
          changeGroup: [conConst.CONTEXT_MS, conConst.CONTEXT_AGENCY],
          changeCondition: [],
          block: [],
          remove: []
        };
        operationsPermissions[appConst.USER_TYPE_MS] = {
          changeBudget: [conConst.CONTEXT_AGENCY],
          changeGroup: [conConst.CONTEXT_AGENCY],
          changeCondition: [],
          block: [],
          remove: []
        };
        operationsPermissions[appConst.USER_TYPE_AGENT] = {
          changeBudget: [conConst.CONTEXT_WORKER],
          changeGroup: [conConst.CONTEXT_WORKER],
          changeCondition: [],
          block: [conConst.CONTEXT_WORKER],
          remove: []
        };

        /**
         * Dostępność operacji na podstawie matrycy operationsPermissions
         * @type {Object}
         */
        this.permissions = {};

        /**
         * Czyścimy dane pomocnicze powiązane z modalem
         * zazwyczaj odpalane na $destroy modala
         * @return {OperationModalHelper} self
         */
        this.clearData = function() {
          self.context = null;
          self.programData = null;
          self.operationData = null;
          self.itemList = [];
          self.promiseCallbacks = null;
          self.permissions = {};

          return self;
        };

        /**
         * Zwracamy liczbę zablokowanych agencji/..
         * @return {int}
         */
        this.getLockedCount = function() {
          var LockedCount = 0;
          angular.forEach(self.itemList, function(item){
            if(item.centralLock || item.globalLock) {
              LockedCount = LockedCount + 1;
            }
          });

          return LockedCount;
        };

        /**
         * Zwracamy liczbę agencji bez warunku
         * @return {int}
         */
        this.getWithoutConditionCount = function() {
          var count = 0;
          angular.forEach(self.itemList, function(item){
            if(!item.condition) {
              count = count + 1;
            }
          });

          return count;
        };

        /**
         * Ustawienie dostępności operacji na podstawie matrycy dostępności (operationsPermissions)
         * budowane w oparciu o rolę i kontekst
         * @return {OperationModalHelper} self
         */
        this.setOperationPermissions = function() {
          var userRole = ihestiaPzaCommonHelper.getUserRole();
          var permissions = {};
          if(operationsPermissions[userRole]) {
            angular.forEach(operationsPermissions[userRole], function(availableContexts, operationCode){
              if(availableContexts.indexOf(self.context) > -1) {
                permissions[operationCode] = true;
              }
            });
          }
          self.permissions = permissions;
          return self;
        };


        /**
         * Operacja się udała - resolve na promise zwracanym przez showOperationsModal
         * @param  {object} data dane z usługi zapisu operacji
         * @return {OperationModalHelper} self
         */
        this.resolvePromise = function(data) {
          self.promiseCallbacks.resolve(data);
          return self;
        };

        /**
         * Operacja się nie udała - rejecy na promise zwracanym przez showOperationsModal
         * @param  {object} data ewentualne dodatkowe dane z przyczyną odrzucenia
         * @return {OperationModalHelper} self
         */
        this.rejectPromise = function(data) {
          self.promiseCallbacks.reject(data);
          return self;
        };


        /**
         * Pokazujemy modal operacji dla zadanych programów
         * @param  {array} budgets ?
         * @return {Promise} dzięki temu będziemy wiedzieć czy operacja została wykonana
         */
        this.showOperationsModal = function(programData, context, itemList) {
          return $q(function(resolve, reject){
            self.promiseCallbacks = {
              resolve: resolve,
              reject: reject
            };
            self.context = context;
            self.itemList = itemList;
            self.programData = programData;
            var operation = null; // NOSONAR
            if(context === ihestiaPzaOperationConstants.CONTEXT_WORKER) {
              operation = new IhestiaPzaWorkerOperationModel();
            } else {
              operation = new IhestiaPzaOperationModel();
            }
            self.operationData = operation.getData();
            self.operationData.budgetAccess = (new IhestiaPzaDictionaryModel()).getData();

            angular.forEach(itemList, function(item, index){
              // uzupełniamy subjectKeyList w zależności od kontekstu
              switch(context) {
                case ihestiaPzaOperationConstants.CONTEXT_PD:
                  self.operationData.subjectKeyList.push(item.pdSubjectKey);
                  break;
                case ihestiaPzaOperationConstants.CONTEXT_MS:
                  self.operationData.subjectKeyList.push(item.msSubjectKey);
                  break;
                case ihestiaPzaOperationConstants.CONTEXT_AGENCY:
                  self.operationData.subjectKeyList.push(item.agSubjectKey);
                  break;
                case ihestiaPzaOperationConstants.CONTEXT_WORKER:
                  self.operationData.workerList.push(item.subjectKey);
                  break;
                default:
                  ihestiaCommonErrorHandler.throwException('unknown context');
                  break;
              }
              // uzupełniamy nazwę grupy, jeśli jest taka sama wszędzie
              if(index === 0 || self.operationData.groupValue === item.objectGroup) {
                self.operationData.groupValue = item.objectGroup;
              } else {
                self.operationData.groupValue = null;
              }
              // uzupełniamy wykorzystywany budżet, jeśli jest taki sam wszędzie
              if(index === 0 || self.operationData.budgetAccess.description === item.budgetMode) {
                self.operationData.budgetAccess.description = item.budgetMode;
              } else {
                self.operationData.budgetAccess.description = null;
              }
            });

            self.setOperationPermissions();
            iHestiaCommonModalHelper.showModal('ihestiaPzaOperations');
          });
        };

        /**
         * Ukrycie modala
         * @return {OperationModalHelper} self
         */
        this.hideOperationsModal = function() {
          self.clearData();
          iHestiaCommonModalHelper.hideModal('ihestiaPzaOperations');
          return self;
        };
      };

      return new OperationModalHelper();
    }]);