angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaCustomerDataHelper', ['lsnConfirmModalHelper', '$filter', '$q',
    function(lsnConfirmModalHelper, $filter, $q) {
      var CustomerDataHelper = function() {
        var self = this;
        this.INDEFINITE_DATE_LITERAL = '2200-01-01';
        this.indefiniteDate = new XDate(this.INDEFINITE_DATE_LITERAL);

        /**
         *
         * @param {IdentityDocumentModel} document1
         * @param {IdentityDocumentModel} document2
         * @returns {boolean}
         */
        this.documentDataChanged = function(document1, document2) {
          if (!document1 && !document2) {
            return false;
          }
          return (document1 && !document2)
            || (!document1 && document2)
            || document1.code !== document2.code
            || document1.number !== document2.number
            || !self.datesEqual(document1.validDate, document2.validDate);
        };

        this.datesEqual = function(strDate1, strDate2) {
          if (!strDate1 && !strDate2) {
            return true;
          } else if ((!strDate1 && strDate2) || (strDate1 && !strDate2)) {
            return false;
          } else {
            var date1 = new XDate(strDate1);
            var date2 = new XDate(strDate2);
            return date1.getFullYear() === date2.getFullYear()
              && date1.getMonth() === date2.getMonth()
              && date1.getDate() === date2.getDate();
          }
        };

        /**
         * show modal with confirmation when changing document data
         * @returns {Promise}
         */
        this.documentDataChangeConfirmation = function() {
          return lsnConfirmModalHelper.showConfirmModal({
            okBtnName: $filter('translate')('Public.yes', {componentCode: 'Public'}),
            cancelBtnName: $filter('translate')('Public.no', {componentCode: 'Public'}),
            content: 'Potwierdzam autentyczność powyższych danych oraz, że zidentyfikowałem i zweryfikowałem tożsamość Klienta zgodnie z wymogami ustawy z dnia 1 marca 2018r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu w oparciu o okazany mi dokument tożsamości.'
          });
        };

        /**
         *
         * @param {IdentityDocumentModel} document1
         * @param {IdentityDocumentModel} document2
         * @returns {Promise}
         */
        this.handleDocumentChange = function(document1, document2) {
          if (self.documentDataChanged(document1, document2)) {
            return self.documentDataChangeConfirmation();
          } else {
            return $q.when(true);
          }
        };

        /**
         * Invokes functions returning promise in sequence - promises are invoked in order and await completion of previous promise
         * if any promise rejects further sequence execution is stopped
         * @param {Array<() => Promise>} promises
         * @param {number} index
         * @returns {Promise}
         */
        this.promiseSequence = function(promises, index) {
          var currIndex = index || 0;
          if (_.isArray(promises) && promises[currIndex]) {
            return promises[currIndex]()
              .then(function() {
                return self.promiseSequence(promises, currIndex + 1);
              });
          } else {
            return $q.when(null);
          }
        };

        /**
         * Parse document date from service
         * @param {string | null} validDate
         * @returns {{validDate: (null|*), indefinite: boolean}}
         */
        this.parseValidDate = function(validDate) {
          var isIndefinite = self.isIndefinite(validDate);
          return {
            indefinite: isIndefinite,
            validDate: isIndefinite ? null : validDate
          };
        };

        /**
         * set proper valid date literal on document
         * @param {IdentityDocument} document
         * @param {string | null} validDate
         * @param {boolean} indefinite
         */
        this.setRequestValidDate = function(document, validDate, indefinite) {
          _.set(document, 'validDate', indefinite ? self.INDEFINITE_DATE_LITERAL : validDate);
        };

        /**
         * check if given valid date is indefinite
         * @param {string | null} validDate
         * @returns {boolean}
         */
        this.isIndefinite = function(validDate) {
          if (typeof validDate === 'string') {
            var date = new XDate(validDate);
            return self.datesEqual(self.indefiniteDate, date);
          } else {
            return false;
          }
        };
      };
      return new CustomerDataHelper();
    }]);
