angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaBeneficiaryListTable', [function() {
    return {
      restrict: 'E',
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/beneficiaries/beneficiaryListTable/beneficiaryListTable.tpl.html',
      scope: {
        list: '=',
        settings: '=',
        onEdit: '=',
        onDelete: '='
      },
      link: function(scope) {

        scope.commonNumericSettings = {
          decimals: 0,
          min: 0,
          max: 100,
          allowLeadingZeros: false,
          suffix: '%'
        };

        scope.tplData = {
          totalSum: null,
          tableValid: null,
          tableMap: null
        };

        scope.initData = function() {
          scope.updateTotalShares();
          scope.tplData.tableValid = true;
          scope.tplData.tableMap = null;
        };
        /**
         *
         * @returns {number}
         */
        scope.sumShares = function() {
          return _.sumBy(scope.list, function(beneficiary){
            if(_.isNumber(beneficiary.shareRatio)){
              return beneficiary.shareRatio;
            } else {
              var parsedNumber = _.toNumber(beneficiary.shareRatio);
              return _.isNumber(parsedNumber) ? parsedNumber : 0;
            }
          });
        };

        scope.updateTotalShares = function() {
          scope.tplData.totalSum = scope.sumShares();
        };

        /**
         * Determines whether table is valid, this function is invoked in modal helper on confirm button click
         * @return {boolean}
         */
        scope.settings.isFormValid = function() {
          _.forEach(scope.beneficiaryListTableForm.$$controls, function(control) {
            control.$setPristine();
          });
          scope.tplData.tableMap = _.chain(scope.list)
            .map(function(beneficiary) {
              return [
                beneficiary.subjectClientId,
                _.toNumber(beneficiary.shareRatio) > 0
              ];
            })
            .fromPairs()
            .value();
          var sharesSum = scope.sumShares();
          scope.tplData.tableValid = sharesSum === 100;
          var sharesAreAssigned = _.every(_.values(scope.tplData.tableMap), Boolean);
          return scope.tplData.tableValid && sharesAreAssigned;
        };

        scope.initData();
      }
    };
  }]);
