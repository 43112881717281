angular.module('lsnBase.errorHandler')
  .provider('lsnCommonErrorHelper', [
    function() {

      var provider = this;

      /**
       * Czy wyświetlać błędy
       * @type {Boolean}
       */
      this.displayErrorModals = true;

      /**
       * Url do usługi zapisującej błędy
       * @type {String}
       */
      this.serviceUrl = '/api/error';

      /**
       * Maksymalna liczba pamiętanych stanów ui-routera
       * @type {Number}
       */
      this.maxUiRouterStatesCount = 30;

      /**
       * Maksymalna liczba pamiętanych requestów per usługa
       * @type {Number}
       */
      this.maxLoggedSvcCount = 5;

      /**
       * Czy zapisywać błędy na serwer
       * @type {Boolean}
       */
      this.saveErrorsOnServer = true;

      /**
       * Kod aplikacji
       * @type {String}
       */
      this.applicationCode = '';

      /**
       * event wygaśnięcia sesji
       * @type {String}
       */
      this.sessionExpiredEvent = 'Session.Expired';

      /**
       * Konfiguracja
       * @type {Object}
       */
      this.frontConfig = {};

      /**
       * Czy logować pełne dane, jeśli mamy do czynienia ze znanym błędem
       * (pełne dane to ~0.3MB na wpis do bazy)
       * @type {Boolean}
       */
      this.logFullDataForKnownErrors = false;

       /**
       * Czy usluga zwraca guid bledu wprost na id
       * @type {Boolean}
       */
      this.guidForwardedInId = false;

       /**
       * Html do wyświetlania błędów
       * @type {String}
       */
      this.alertTpl = 'lsn-base-templates-app/errorHandler/errorTemplate.tpl.html';

      this.$get = [function(){
        return provider;
      }];
    }
  ]).run(['$window', 'lsnCommonErrorHelper',
    function($window, lsnCommonErrorHelper) {
      $window.addEventListener('beforeunload', function() {
        lsnCommonErrorHelper.displayErrorModals = false;
      });
    }
  ]);