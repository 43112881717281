angular.module('salesPath2')
  .controller('matrixPersonBoxCtrl', ['$scope', 'CONSTANTS', 'personGroupHelper', '$timeout', 'applicationHelper', 'sp2SelectionHelper', 'sp2CommonHelper', 'actionHelper', 'matrixPersonBoxHelper', 'personModalHelper', 'groupModalHelper', '$filter', 'dataContainerHelper',
    function($scope, CONSTANTS, personGroupHelper, $timeout, applicationHelper, selectionHelper, sp2CommonHelper, actionHelper, matrixPersonBoxHelper, personModalHelper, groupModalHelper, $filter, dataContainerHelper) { // eslint-disable-line angular/di
      $scope.tplData = matrixPersonBoxHelper.tplData;

      $scope.init = function() {
        matrixPersonBoxHelper.refreshSubjects();
      };

      $scope.selectSubject = function(clientId) {
        if (parseInt(dataContainerHelper.getSelectedObjectId(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), 10) === parseInt(clientId, 10)) {
          return; //ten podmiot jest juz wybrany
        }
        dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
        actionHelper.runAction('personGroupSelected');
      };

      //usuwanie osoby/grupy z listy ubezpieczonych
      $scope.deleteSubject = function(objectName, clientId) {

        var confirmText; // (string) jak ponizej
        if (objectName === 'Person') {
          confirmText = $filter('translate')('modal.matrix.confirmModalPersonRemoveText', {componentCode: 'sp2'});
        } else {
          confirmText = $filter('translate')('modal.matrix.confirmModalGroupRemoveText', {componentCode: 'sp2'});
        }

        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: $filter('translate')('modal.matrix.confirmModalPersonGroupRemoveTitle', {componentCode: 'sp2'}),
          text: confirmText,
          okBtnCallback: function() {
            selectionHelper.deleteObjectVariants(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
            if (objectName === 'Group') {//w przpadku grupy usuwamy caly obiekt
              personGroupHelper.deleteGroup(clientId);
            }
            actionHelper.runAction('personGroupDeleted');
          }
        });
      };

      $scope.showPopupAddInsured = function() {
        $timeout(function() {
          personModalHelper.openPopupAddPerson({
            context: CONSTANTS.PRODUCT_TYPE_PERSON_GROUP,
            title: $filter('translate')('modal.matrix.addPerson', {componentCode: 'sp2'}),
            titleSide: $filter('translate')('modal.matrix.addPersonSide', {componentCode: 'sp2'}),
            searchTitle: $filter('translate')('modal.matrix.addPersonSearchTitle', {componentCode: 'sp2'}),
            searchHeader: $filter('translate')('modal.matrix.addPersonSearchHeader', {componentCode: 'sp2'}),
            editHeader: $filter('translate')('modal.matrix.addPersonEditHeader', {componentCode: 'sp2'}),
            excludeIds: personGroupHelper.getSubjectsClientIds(),
            showGroup: true
          });
        }, 0);
      };

      //otwarcie modala edycji podmiotu
      $scope.showEditModal = function(objectName, clientId) {
        if (objectName === 'Person') {
          $timeout(function() {
            personModalHelper.openPopupEditPerson({
              id: clientId,
              editHeader: (parseInt(clientId, 10) === parseInt($scope.dataContainer.insurerId, 10)) ? $filter('translate')('sp2.matrix.persons.insurer', {componentCode: 'sp2'}) : $filter('translate')('insured', {componentCode: 'Public'})
            });
          }, 0);
        } else {
          $timeout(function() {
            groupModalHelper.openPopupEditGroup({
              id: clientId,
              context: CONSTANTS.PRODUCT_TYPE_PERSON_GROUP
            });
          }, 0);
        }
      };

      $scope.init();
    }
  ]);