angular.module('ihestiaIkomunikator.notificationBar')
	.controller('ihestiaIkomunikatorNotificationBarCtrl', ['$scope', 'ihestiaIkomunikatorNavigationHelper', 'ihestiaIkomunikatorActionHelper',
		'ihestiaIkomunikatorNotificationBarHelper', '$timeout', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorSearchMessagesCentralHelper',
		function($scope, ihestiaIkomunikatorNavigationHelper, ihestiaIkomunikatorActionHelper, ihestiaIkomunikatorNotificationBarHelper, $timeout, ihestiaIkomunikatorUserTypeHelper,
			ihestiaIkomunikatorSearchMessagesCentralHelper) {
			$scope.popoverVisible = false;
			$scope.messagesCount = 0;
			$scope.messages = [];

			/**
			 * [init description]
			 * @return {[type]} [description]
			 */
			$scope.init = function() {
				$scope.tplData = ihestiaIkomunikatorNotificationBarHelper.tplData;
				$scope.centralUserLogged = ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged();
				$scope.fireListeners();
			};

			$scope.fireListeners = function() {
				if (!$scope.centralUserLogged) {
					$scope.$on('ikomunikatorLoadNextMessage', function(event, data) {
						if (!$scope.tplData.loading && data.percentage > 95) {
							$timeout(function() {
								ihestiaIkomunikatorNotificationBarHelper.loadNextResult();
							}, 0);
						}
					});
				}
			};

			/**
			 * [hidePopover description]
			 * @return {[type]} [description]
			 */
			$scope.togglePopoverVisibility = function() {
				if ($scope.popoverVisible) {
					$scope.hidePopover();
				} else {
					$scope.showPopover();
				}
			};

			/**
			 * [hidePopover description]
			 * @return {[type]} [description]
			 */
			$scope.hidePopover = function() {
				$scope.popoverVisible = false;
				if (!$scope.centralUserLogged) {
					ihestiaIkomunikatorNotificationBarHelper.setClearData();
				}
			};

			/**
			 * [showPopover description]
			 * @return {[type]} [description]
			 */
			$scope.showPopover = function() {
				$scope.popoverVisible = true;
				if (!$scope.centralUserLogged) {
					ihestiaIkomunikatorNotificationBarHelper.reloadSearch();
				}
			};

			/**
			 * KLiknięto wiadomości
			 * @param  {object} message [description]
			 * @return {[type]}         [description]
			 */
			$scope.showDetailsMessageThread = function(threadId) {
				ihestiaIkomunikatorNavigationHelper.goTo('details', threadId);
				$scope.hidePopover();
			};

			$scope.showNewMessageModal = function() {
				if(!$scope.centralUserLogged){
					$scope.hidePopover();
					ihestiaIkomunikatorActionHelper.startNewMessage();
				}
			};

			$scope.showMessageHistory = function() {
				ihestiaIkomunikatorNavigationHelper.goTo('fullListPanel');
				$scope.hidePopover();
			};

			$scope.showChooseCorrespondenceType = function(){
				if($scope.centralUserLogged){
					ihestiaIkomunikatorSearchMessagesCentralHelper.showModal();
				}
			};

			$scope.init();
		}
	]);