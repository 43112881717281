angular.module('ihestiaCommonDirectives.strpos')
  /**
   * zwraca pozycję stringa w zadanym stringu lub false gdy nie znaleziono
   * @param {String} haystack gdzie szukamy
   * @param {String} needle co szukamy
   * @param {Number} [offset=0] offset
   * @returns {(Boolean|number)[]} pozycję stringa lub false gdy nie znaleziono
   */
  .factory('ihestiaStrpos', function () {
    var strpos = function (haystack, needle, offset) {
      var i = (haystack + '').indexOf(needle, (offset || 0));
      return i === -1 ? false : i;
    };
    return strpos;
  });