angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorAbstractModel', [function() {

    var IKomunikatorAbstractModel = function() {

      /**
       * Składa json do restów (zapis/update/etc)
       * @return {Object}
       */
      this.toRest = function() {
        var restObject = {};
        angular.forEach(this, function(propertyValue, propertyCode) {
          if(propertyValue !== null && !angular.isFunction(propertyValue)) {
            restObject[propertyCode] = propertyValue;
          }
        });

        return restObject;
      };

      /**
       * Wciąganie danych z restów do modelu
       * @param  {Object} data dane z rest
       * @return {IKomunikatorAbstractModel}      self
       */
      this.fromRest = function(data) {
        var self = this;
        angular.forEach(data, function(propertyValue, propertyCode){
          if(!angular.isFunction(propertyValue) && angular.isDefined(self[propertyCode])){
            self[propertyCode] = propertyValue;
          }
        });

        return self;
      };


    };

    return IKomunikatorAbstractModel;
  }]);