angular.module('ihestiaRestServicesBase')

   .factory('alertsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var Alerts = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'resources/alertNotification';
      this.businessArea = 'ikomunikator';
    };

    return new Alerts();
  }])
  ;