angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaFileSvc', ['ihestiaPzaAbstractSvc',
    function(ihestiaPzaAbstractSvc) {

      var PzaFileSvc = function() {
        var self = this;
        ihestiaPzaAbstractSvc.apply(this, arguments);

        this.resource = 'file';

        /**
         * Pobranie pliku (na razie obsługa xlsx)
         * @param  {string} fileId id pliku
         * @return {promise} zwraca promise, ale samo pobieranie pliku i tak rozgrywane jest w tej metodzie
         */
        this.downloadFile = function(fileId) {
          return self.get(null, {
            fileId: fileId
          }, null, null, null, {
            headers: {
              'Accept': 'application/octet-stream; charset=UTF-8'
            },
            responseType: 'blob'
          }).then(function(response) {
            if (response && response.status === 200) {
              var reportData = response.data;
              var fileName = fileId + '.xlsx'; //jak nie dostaniemy nazwy z usługi
              var contentDisposition = response.headers('Content-Disposition');

              var fileNameString = 'filename=';
              if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
                var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
                if (preCut.indexOf(';') !== -1) {
                  preCut.substr(0, preCut.indexOf(';'));
                }
                fileName = decodeURIComponent(preCut).replace(/\"/g, '');
              }

              var b = new Blob([reportData], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              });
              saveAs(b, fileName);
            }
            return response;
          }, angular.noop);
        };
      };

      return new PzaFileSvc();
    }]);