/**
 * Opcje:
 * - disabled-attributes {city: true, street = "1 === 2"} - atrybuty ng-disabled na poszczególne pola
 */
angular.module('lsnBase.addresses')
  .directive('lsnAddress', ['availabilityHelper', '$parse',
    function(availabilityHelper, $parse) {
      return {
        restrict: 'E',
        replace: true,
        link: function(scope, elem, attrs) {
          var address = $parse(attrs.address)(scope);
          if (attrs.drvTemplate) {
            scope._tpl = attrs.drvTemplate;
          } else if (!availabilityHelper.isAvailable(attrs.availabilityCode) || (address && address.countryCode && address.countryCode !== 'PL')) {
            // rozwiązanie niewystarczające, bo address.countryCode może się zmienić
            // todo sprawdzić czy nie wystarczy $scope.blockServices
            scope._tpl = 'lsn-base-templates-app/autocompleters/address/addressWithoutService.tpl.html';
          } else {
            scope._tpl = 'lsn-base-templates-app/autocompleters/address/address.tpl.html';
          }
        },
        templateUrl: 'lsn-base-templates-app/autocompleters/address/addressWrapper.tpl.html',
        scope: true,
        controller: 'lsnAddressCtrl'
      };
    }
  ]);