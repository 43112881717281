angular.module('lsnBase.numeralString')
  .filter('numeralString', ['numeralStringConfig', 
    function(numeralStringConfig) {
    return function(value, numerals, withNumber) {
      var template = numerals;
      var number = parseInt(value, 10);
      withNumber = withNumber === false ? false : true;
      if(isNaN(number)) {
        // nie liczba, olewamy
        return value;
      }

      // podano kod szablonu
      if(angular.isString(numerals)){
        if(angular.isArray(numeralStringConfig.templates[numerals])) {
          // jest szablon
          template = numeralStringConfig.templates[numerals];
        } else {
          // nie ma szablonu - nie ma co z tym zrobić
          return value;
        }
      }

      // mamy szablon
      if(angular.isArray(template) && template.length === 3) {
        return number.toCountableString(template, withNumber);
      }

      // jakaś lipa - zwraca input
      return value;
    };
  }]);