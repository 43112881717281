angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonComponenModel', ['$injector', 'ihestiaCommonErrorHandler',
    function($injector, ihestiaCommonErrorHandler) {

      var IhestiaCommonComponenModel = function() {
        var self = this;

        this.symbol = '';
        this.statusManageable = false;
        this.name = '';
        this.hasAuthDomain = false;
        this.status = {
          val: 'ENABLED'
        };
        this.attributes = [];

        /**
         * Ustawiamy dane na podstawie zwrotki z uslugi
         * @param {object} data [description]
         * @return {IhestiaCommonComponenModel} [description]
         */
        this.setData = function(data) {
          angular.forEach(data, function(value, key) {
            if (typeof self[key] !== 'undefined') {
              self[key] = value;
            }
          });

          return self;
        };

        /**
         * Ustawienie statusu
         * @param {string}   status   ENABLED/DISABLED
         * @param {Function} callback [description]
         */
        this.setStatus = function(status, callback) {
          var statusSvc = $injector.get('ihestiaCommonComponentStatusSvc');
          statusSvc.put(null, {
            nodeStatus: status,
            symbol: self.symbol
          }, null, function(res){
            if(res.data.Status === 'Ok') {
              self.status.val = status;
              callback();
            } else {
              ihestiaCommonErrorHandler.throwException({
                code: 500,
                message: 'Błąd zmiany statusu komponentu ' + self.symbol
              });
            }
          });
        };
      };

      return IhestiaCommonComponenModel;
    }
  ]);