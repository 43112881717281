angular.module('lsnBase.bootstrap')
  .factory('simpleOAuthDataHelper', ['$window', 'lsnRestCommonHelper',
    function($window, lsnRestCommonHelper){

    var SimpleOAuthDataHelper = function() {

      var self = this;

      /**
       * [logout description]
       * @param {String} logoutCode kod wylogowania (np. 401 - https://ebok.atena.pl/browse/EHNLJUPIT-1055)
       * @return {undefined}
       */
      this.logout = function(logoutCode) {
        delete localStorage.accessToken;
        delete sessionStorage.accessToken;
        if(logoutCode) {
          sessionStorage.logoutCode = logoutCode;
        }
        $window.location.reload(true);
      };

      /**
       * [isLogged description]
       * @return {Boolean} [description]
       */
      this.isLogged = function() {
        return angular.isDefined($window.localStorage.accessToken);
      };

      /**
       * [getAccessToken description]
       * @return {String}
       */
      this.getAccessToken = function() {
        return $window.localStorage.accessToken ? $window.localStorage.accessToken : $window.sessionStorage.accessToken;
      };

      /**
       * jak nie wiemy czy persistent to sprawdzamy gdzie ostatnio był zapisany
       * @return {bool}
       */
      this.checkPersistent = function(persistent)
      {
        if(angular.isUndefined(persistent) || persistent === null)
        {
          if(angular.isDefined($window.localStorage.accessToken))
          {
            persistent = true;
          }
          else
          {
            persistent = false;
          }
        }
        return persistent;
      };

      /**
       * Zapisujemy token logowania (oauth)
       * @param {String} token
       * @param {Boolean} persistentToken jeśli true, to token pozostanie nawet po zamknięciu aplikacji
       */
      this.setAccessToken = function(token, persistentToken, login) {
        persistentToken = self.checkPersistent(persistentToken);

        delete $window.localStorage.accessToken;
        delete $window.sessionStorage.accessToken;
        delete $window.localStorage.accessTokenId;
        delete $window.sessionStorage.accessTokenId;
        if(login)
        {
          delete $window.localStorage.login;
          delete $window.sessionStorage.login;
        }

        var accessTokenId = lsnRestCommonHelper.getUniqueRequestId();

        if(persistentToken) {
          $window.localStorage.accessToken = token;
          $window.localStorage.accessTokenId = accessTokenId;
          if(login)
          {
            $window.localStorage.login = login;
          }
        } else {
          $window.sessionStorage.accessToken = token;
          $window.sessionStorage.accessTokenId = accessTokenId;
          if(login)
          {
            $window.sessionStorage.login = login;
          }
        }

        return self;
      };

      this.getAccessTokenId = function()
      {
        if($window.sessionStorage.accessTokenId)
        {
          return $window.sessionStorage.accessTokenId;
        }
        else if($window.localStorage.accessTokenId)
        {
          return $window.localStorage.accessTokenId;
        }
        else
        {
          return null;
        }
      };

      this.getStoredLogin = function()
      {
        if($window.sessionStorage.login)
        {
          return $window.sessionStorage.login;
        }
        else if($window.localStorage.login)
        {
          return $window.localStorage.login;
        }
        else
        {
          return null;
        }
      };

      /**
       * Zapisujemy token odświeżenia sesji logowania (oauth)
       * @param {String} token
       * @param {Boolean} persistentToken jeśli true, to token pozostanie nawet po zamknięciu aplikacji
       */
      this.setRefreshToken = function(refreshToken, persistentToken) {
        persistentToken = self.checkPersistent(persistentToken);

        delete $window.localStorage.refreshToken;
        delete $window.sessionStorage.refreshToken;
        if(persistentToken) {
          $window.localStorage.refreshToken = refreshToken;
        } else {
          $window.sessionStorage.refreshToken = refreshToken;
        }

        return self;
      };

      this.getRefreshToken = function()
      {
        if($window.sessionStorage.refreshToken)
        {
          return $window.sessionStorage.refreshToken;
        }
        else if($window.localStorage.refreshToken)
        {
          return $window.localStorage.refreshToken;
        }
        else
        {
          return null;
        }
      };

    };

    return new SimpleOAuthDataHelper();
  }]);