angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaPostCodeConditionDefinitionModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaPostCodeConditionDefinitionModel = function() {
      this.objectName = 'IhestiaPzaPostCodeConditionDefinitionModel';

      this.fieldsProperties = {
        postCode: {
          type: 'string'
        }
      };

      this.postCode = null;

    };


    IhestiaPzaPostCodeConditionDefinitionModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaPostCodeConditionDefinitionModel;
  }]);