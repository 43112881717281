angular.module('ihestiaRestServicesBase')
  /**
   * QA proxyPass
   *   ProxyPass /api/productFiles/ http://10.111.229.205:8252/api/productFiles/
   *   ProxyPassReverse /api/productFiles/ http://10.111.229.205:8252/api/productFiles/  
   */
  .service('productFilesSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var ProductFiles = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      var self = this;
      this.resource = '';
      this.businessArea = 'productFiles';
      this.port = '8252';
      this.path = '';

      /**
       * register file upload
       *
       * @param {String} id
       * @param {function} callback
       * @returns {*}
       */
      this.upload = function(id, data, subResource, callback, errorCallback, httpParams) {
        return self.post(id, data, subResource ? subResource : 'upload', callback, errorCallback, httpParams);
      };


      /**
       * delete file's info/metaData
       * @param  {String} fileId FRP file id - same as frpFileId in self.postMetaData() or fileId in response of self.upload()
       * @param  {String} productId e.g. application metaData.id
       * @param  {Function} [callback]
       * @param  {Function} [errorCallback]
       * @param  {Object} [httpParams]
       * @return {Promise}
       */
      this.deleteFile = function(fileId, productId, callback, errorCallback, httpParams) {
        var body = {
          fileId: fileId,
          productId: productId
        };
        return self.removeWithBody(null, body, null, callback, errorCallback, httpParams);
      };

      /**
       * post file's metaData
       * @param  {Object} data see details in code below
       * @param  {Boolean} [overwriteData=true] whether to overwrite default request body object
       * @param  {Function} [callback]
       * @param  {Function} [errorCallback]
       * @param  {Object} [httpParams]
       * @return {Promise}
       */
      this.postMetaData = function(data, overwriteData, callback, errorCallback, httpParams) {
        overwriteData = _.isBoolean(overwriteData) ? overwriteData : true;
        var defaultBody = {
          applicationVer: null, // metaData.id of application/offer
          applicationNumber: null, // number of application/offer
          policyNumber: null, // optional
          transactionGroupId: null, // optional
          fileName: null, // e.g. 'blank_file.pdf'
          frpFileId: null, // e.g. 'e8df0c16-dad0-4cad-ad8c-c3659d3ae758'
          productId: null, // e.g. 'ebiznes'
          uploadPlaceCode: null // e.g. 'sendToUWR___'
        };
        var body = _.merge({}, overwriteData ? defaultBody : null, body);
        return self.post(null, body, null, callback, errorCallback, httpParams);
      };

    };
    return new ProductFiles();
  }]);