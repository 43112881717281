angular.module('ihestiaWidgets.pza')
  .service('ihestiaPzaAbstractSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PzaAbstractSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
        this.businessArea = 'pza';
      };

      return PzaAbstractSvc;
    }]);