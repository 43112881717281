angular.module('lsnBase.typeahaedConfig', [])
  .provider('typeahaedConfig', [function(){
    var self = this;

    /**
     * [popupTemplate description]
     * @type {String}
     */
    this.popupTemplate = 'template/typeahead/typeahead-popup.html';

    /**
     * [defaultMatchTemplate description]
     * @type {String}
     */
    this.defaultMatchTemplate = 'template/typeahead/typeahead-match.html';

    /**
     * Gdy true, to chowamy listę wyników na blur
     * (dla mobilek powinno być na true)
     * @type {Boolean}
     */
    this.resetMatchesOnBlur = false;

    /**
     * Czy po kliknięciu w wybrany element mamy przywracać focus na inpucie
     * @type {Boolean}
     */
    this.returnFocusOnSelect = true;

    /**
     * Jeśli ustawione, to przed wyczyszczeniem (i tym samym zamnkięciem)
     * listy robiony jest timeout.
     * Zrobione na potrzeby IHESTIALT-372
     * 
     * @type {int}
     */
    this.resetMatchesDelay = null;

    this.$get = [function(){
      return self;
    }];
  }]);

angular.module('lsnBase.typeahaed', [
  'typeaheadDependencies'
])
  .config(['$provide', 'typeahaedConfigProvider',
    function($provide, typeahaedConfigProvider) {
      $provide.decorator('typeaheadMatchDirective', ['$delegate', '$parse', '$templateCache', '$http', '$compile',
        function($delegate, $parse, $templateCache, $http, $compile) {
          var directive = $delegate[0];
          delete directive.link;

          directive.compile = function(){
            return function(scope, element, attrs) {
              var tplUrl = $parse(attrs.templateUrl)(scope.$parent) || typeahaedConfigProvider.defaultMatchTemplate;
              $http.get(tplUrl, {
                cache: $templateCache
              }).then(function(tplContent) {
                element.replaceWith($compile(tplContent.data.trim())(scope));
              }, angular.noop);
            };
          };

          return $delegate;
        }]);
    }
  ]);