angular.module('ihestiaWidgets.commonOperations')
  .controller('findClientModalCtrl', ['$scope', 'ihestiaSaleNavigationConstants', 'ihestiaConfigHelper', '$window', 'findClientModalConfig', 'ihestiaSaleNavigationHelper', 'ihestiaPlatformHelper', 'ihestiaAgHelper', 'ihestiaDictionaryHelper', '$filter',
    function($scope, ihestiaSaleNavigationConstants, ihestiaConfigHelper, $window, findClientModalConfig, ihestiaSaleNavigationHelper, ihestiaPlatformHelper, ihestiaAgHelper, ihestiaDictionaryHelper, $filter) {

      $scope.SUBJECT_TYPE_PERSON = 'person';
      $scope.SUBJECT_TYPE_ORGANIZATION = 'organization';

      $scope.defaultDynamicFilters = {
        firstName: '',
        lastName: '',
        identityNumber: null,
        policyNumber: '',
        registrationCountry: null,
        statisticalId: null,
        taxId: null,
        organizationName: null,
        companyId: null
      };
      /**
       * Dodatkowe dane widoku
       * @type {Object}
       */
      $scope.viewData = {
        template: 'Form', // Form|ResList
        specialErrors: {
          invalidPolicyNumber: false,
          invalidCustomer: false,
          noPermissionToViewPolicy: false
        },
        personIsForeigner: false,
        dynamicFilters: _.cloneDeep($scope.defaultDynamicFilters),
        currentCustomerId: null,
        customersListResponse: null, // odpowiedź z usługi
        policiesListResponse: [],
        errorList: [],
        countries: null,
        subjectType: $scope.SUBJECT_TYPE_PERSON,
        subjectTypes: [{
          code: $scope.SUBJECT_TYPE_PERSON,
          name: $filter('translate')('naturalPerson', {componentCode: 'Public'})
        }, {
          code: $scope.SUBJECT_TYPE_ORGANIZATION,
          name: $filter('translate')('company', {componentCode: 'Public'})
        }],
        isSubjectSelectAvailable: false
      };

      $scope.companyFields = [
        'registrationCountry',
        'taxId',
        'statisticalId',
        'organizationName',
        'companyId'
      ];
      $scope.personFields = [
        'firstName',
        'lastName',
        'identityNumber',
        'pesel'
      ];

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.setView('Form');
        ihestiaDictionaryHelper.getDictWithPromise('countries', {
          requestType: 'searches'
        }).then(function(countries) {
          $scope.viewData.countries = countries;
        });
        $scope.viewData.isSubjectSelectAvailable =
          !ihestiaPlatformHelper.isJupiter() && !ihestiaPlatformHelper.isLifeContext();
      };

      /**
       * Jak obcokrajowiec to nie wysyłamy peselu i na odwrót
       * @return {undefined}
       */
      $scope.foreignerChanged = function() {
        $scope.viewData.dynamicFilters.identityNumber = null;
      };

      /**
       * Ustawia odpowiedni widok
       * @param {String} viewCode Form|ResList
       */
      $scope.setView = function(viewCode) {
        $scope.viewData.template = viewCode;
        if (viewCode === 'Form') {
          $scope.helper.setOptions(findClientModalConfig.labels.formModal);
        } else if (viewCode === 'ResList') {
          $scope.helper.setOptions(findClientModalConfig.labels.listModal);
        } else if (viewCode === 'PoliciesList') {
          $scope.helper.setOptions(findClientModalConfig.labels.policiesListModal);
        }
      };

      /**
       * Wybrano klienta
       * @param  {Object} client klient
       * @param  {string} policyNumber numer polisy, jeśli chcemy przejść do konkretnej polisy
       * @return {undefined}
       */
      $scope.chooseClient = function(client, policyNumber) {
        $scope.viewData.currentCustomerId = null;
        if ($scope.helper.modalData.openClientPage !== false) {
          $scope.$emit('showBlockUi');
          $scope.helper.goToClient(client, policyNumber).then(function(res) {
            if (res && ihestiaPlatformHelper.isJupiter()) {
              // dla Jupitera ma zadziać sie cała magia z przechodzeniem na podglad polisy/prezentowanie listy polis itd.
              if (res && res.status === 'exactResult') {
                var simplePolicy = {
                  customerAccountId: client.customerAccountId,
                  platform: ihestiaPlatformHelper.isAnyJupiterPlatform(res.policy.platform) ? 'jupiter' : 'ihestia',
                  policyNumber: res.policy.policyNumber
                };
                var permissionAppCode = ihestiaPlatformHelper.isAnyJupiterPlatform(res.policy.platform) ? 'iKontoDirect' : 'iKontoiHestia';
                if (ihestiaAgHelper.hasPermissionFor('Public', permissionAppCode)) {
                  ihestiaSaleNavigationHelper.openPolicy(simplePolicy, $scope.helper.modalData.clientPageTarget);
                  $scope.helper.hideModal();
                } else {
                  $scope.viewData.errorList.push({
                    label: ['Nie znaleziono polis spełniających kryteriów wyszukiwania'] // bez labelki ze względu na problemy z etykietami @TODO
                  });
                }
              } else if (res && res.status === 'manyResults') {
                $scope.viewData.currentCustomerId = client.customerAccountId;
                //$scope.viewData.policiesListResponse = res.policiesListResponse;
                angular.forEach(res.policiesListResponse, function(policy) {
                  if (ihestiaPlatformHelper.isAnyJupiterPlatform(policy.platform)) {
                    $scope.viewData.policiesListResponse.push(policy);
                  }
                });

                if ($scope.viewData.policiesListResponse.length === 0) {
                  $scope.viewData.errorList.push({
                    label: ['Nie znaleziono polis spełniających kryteriów wyszukiwania'] // bez labelki ze względu na problemy z etykietami @TODO
                  });
                } else if ($scope.viewData.policiesListResponse.length === 1) {
                  var policyObj = {
                    customerAccountId: client.customerAccountId,
                    platform: 'jupiter', // skoro mamy tylko polisy jupiterowe to platforma zawsze będzie jupiter
                    policyNumber: $scope.viewData.policiesListResponse[0].policyNumber
                  };

                  ihestiaSaleNavigationHelper.openPolicy(policyObj, $scope.helper.modalData.clientPageTarget);
                } else {
                  $scope.setView('PoliciesList');
                }
              }
            } else if (res && !ihestiaPlatformHelper.isJupiter()) {
              // dla iHestii ma zawsze kierować na strone iKonta/PolisyOnline
              ihestiaSaleNavigationHelper.openCustomerPage(client.customerAccountId, res.targetApp, $scope.helper.modalData.clientPageTarget);
            }
            $scope.helper.setPromiseAsResolved(client);
          }, function(res) {
            if (res.customerAccountId) {
              // być może będziemy mieć już id klienta (np. wszystko ok, poza numerem polisy)
              $scope.viewData.currentCustomerId = res.customerAccountId;
            }
            if (res.status === 'invalidPolicyNumber') {
              if ($scope.helper.modalData.onlyExactHit) {
                $scope.viewData.errorList.push({
                  label: ['policyForClientNotFound', {
                    componentCode: 'Public'
                  }]
                });
              } else {
                $scope.viewData.specialErrors.invalidPolicyNumber = true;
              }
            } else if (res.status === 'noResults') {
              //dla Jupitera pokazujemy komunikat o braku polis do wyświetlenia - dla iHestii kierujemy do iKonta
              if (ihestiaPlatformHelper.isJupiter()) {
                $scope.viewData.errorList = res.errors;
              } else {
                ihestiaSaleNavigationHelper.openCustomerPage(client.customerAccountId, res.targetApp);
              }
            } else {
              $scope.viewData.errorList = res.errors;
            }
          }).finally(function() {
            $scope.$emit('hideBlockUi');
          });
        } else {
          $scope.helper.setPromiseAsResolved(client);
          $scope.helper.hideModal();
        }
      };

      $scope.choosePolicy = function(policy) {
        var simplePolicy = {
          customerAccountId: $scope.viewData.currentCustomerId,
          platform: ihestiaPlatformHelper.isAnyJupiterPlatform(policy.platform) ? 'jupiter' : 'ihestia',
          policyNumber: policy.policyNumber
        };
        var permissionAppCode = ihestiaPlatformHelper.isAnyJupiterPlatform(policy.platform) ? 'iKontoDirect' : 'iKontoiHestia';
        if (ihestiaAgHelper.hasPermissionFor('Public', permissionAppCode)) {
          ihestiaSaleNavigationHelper.openPolicy(simplePolicy, $scope.helper.modalData.clientPageTarget);
        } else {
          $scope.viewData.specialErrors.noPermissionToViewPolicy = true;
          $scope.viewData.selectedPolicyNumber = policy.policyNumber;
        }
      };

      /**
       * Anuluj
       * @return {undefined}
       */
      $scope.helper.cancel = function() {
        if ($scope.viewData.template === 'ResList' || $scope.viewData.template === 'PoliciesList') {
          // wstecz
          $scope.setView('Form');
        } else {
          // jak jesteśmy na formularzy, to ten przycisk tylko zamyka okno
          $scope.helper.setPromiseAsRejected();
          $scope.helper.hideModal();
        }
      };

      /**
       * Walidujemy formularz
       * @return {Boolean}
       */
      $scope.validate = function() {
        $scope.viewData.errorList = [];
        $scope.viewData.specialErrors.invalidCustomer = false;
        $scope.viewData.specialErrors.invalidPolicyNumber = false;
        $scope.viewData.specialErrors.noPermissionToViewPolicy = false;
        if ($scope.helper.modalData.requiredFields && $scope.helper.modalData.requiredFields.policyNumber && !$scope.viewData.dynamicFilters.policyNumber) {
          $scope.viewData.errorList.push({
            label: ['fieldPolicyNumberIsRequired', {
              componentCode: 'Public'
            }]
          });
        }

        return $scope.viewData.errorList.length === 0;
      };

      /**
       * Otwieramy stronkę klienta
       * (np. gdy token na numer polisy się nie zarejestrował)
       * @return {undefined}
       */
      $scope.openCustomerAccount = function() {
        if (!$scope.helper.modalData.clientPageType) {
          $scope.helper.modalData.clientPageType = ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO;
        }
        var client = angular.copy($scope.viewData.dynamicFilters);
        client.customerAccountId = $scope.viewData.currentCustomerId;
        $scope.helper.goToClient(client)
          .then(angular.noop, function(res) {
            if (res.status === 'noResults') {
              //dla Jupitera pokazujemy komunikat o braku polis do wyświetlenia - dla iHestii kierujemy do iKonta
              if (ihestiaPlatformHelper.isJupiter()) {
                $scope.viewData.errorList = res.errors;
              } else {
                ihestiaSaleNavigationHelper.openCustomerPage(client.customerAccountId, res.targetApp);
              }
            }
          })
          .finally(function() {
            $scope.helper.setPromiseAsRejected();
            $scope.helper.hideModal();
          });
      };

      /**
       * Otwarcie wyszukiwania polisy iPegaz w nowym oknie/karcie.
       */
      $scope.openIPegazSearchWindow = function() {
        var iPegazUrl = ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'PolicyInfo.aspx');
        $window.open(iPegazUrl);
        $scope.helper.setPromiseAsResolved();
        $scope.helper.hideModal();
      };

      $scope.chooseClientFromList = function(client) {
        $scope.$emit('showBlockUi');
        var tokenRequest = $scope.mapTokenRequest(client);
        $scope.helper.registerClientToken(tokenRequest, $scope.viewData.subjectType)
          .then(function(res) {
            if (res.status === 'invalidCustomer') {
              $scope.viewData.specialErrors.invalidCustomer = true;
            } else {
              $scope.chooseClient(res.client, $scope.viewData.dynamicFilters.policyNumber);
            }
          }, function(res) {
            $scope.viewData.errorList = res.errors;
            if (!$scope.viewData.errorList || $scope.viewData.errorList.length === 0) {
              $scope.viewData.errorList = res.errors;
            }
          }).finally(function() {
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * OK
       * @return {undefined}
       */
      $scope.helper.confirm = function() {
        $scope.viewData.currentCustomerId = null;
        if ($scope.viewData.template === 'Form' && $scope.validate()) {
          // szukaj
          $scope.$emit('showBlockUi');
          $scope.viewData.customersListResponse = null;
          $scope.viewData.policiesListResponse = [];
          $scope.viewData.errorList = [];
          var dynamicFilters = $scope.mapSearchRequest($scope.viewData.dynamicFilters);
          $scope.helper.search(dynamicFilters, $scope.viewData.subjectType)
            .then(function(res) {
              if (res.status === 'exactResult') {
                // jeden wynik - prosta sprawa
                if ($scope.viewData.dynamicFilters.policyNumber) {
                  $scope.chooseClient(res.client, $scope.viewData.dynamicFilters.policyNumber);
                } else {
                  var tokenRequest = $scope.mapTokenRequest(res.client);
                  $scope.helper.registerClientToken(tokenRequest, $scope.viewData.subjectType)
                    .then(function(res) {
                      if (res.status === 'invalidCustomer') {
                        $scope.viewData.specialErrors.invalidCustomer = true;
                      } else {
                        $scope.chooseClient(res.client, $scope.viewData.dynamicFilters.policyNumber);
                      }
                    }, function(res) {
                      $scope.viewData.errorList = res.errors;
                      if (!$scope.viewData.errorList || $scope.viewData.errorList.length === 0) {
                        $scope.viewData.errorList = res.errors;
                      }
                    }).finally(function() {
                    $scope.$emit('hideBlockUi');
                  });
                }
              } else if (res.status === 'manyResults') {
                // wiele wyników - będzie lista
                if ($scope.helper.modalData.onlyExactHit) {
                  $scope.viewData.errorList.push({
                    label: ['toManyClientResults', {
                      componentCode: 'Public'
                    }]
                  });
                } else {
                  $scope.viewData.customersListResponse = res.customersListResponse;
                  $scope.setView('ResList');
                }
                $scope.$emit('hideBlockUi');
              } else {
                // jakiś błąd
                $scope.viewData.errorList = res.errors;
                $scope.$emit('hideBlockUi');
              }
            }, function(searchRes) {
              // nie dostaliśmy klienta, sprawdzamy czy da się po tokenie
              $scope.helper.registerClientToken($scope.viewData.dynamicFilters, $scope.viewData.subjectType)
                .then(function(res) {
                  if (res.status === 'invalidCustomer') {
                    $scope.viewData.specialErrors.invalidCustomer = true;
                  } else {
                    $scope.chooseClient(res.client, $scope.viewData.dynamicFilters.policyNumber);
                  }
                }, function(res) {
                  $scope.viewData.errorList = res.errors;
                  if (!$scope.viewData.errorList || $scope.viewData.errorList.length === 0) {
                    $scope.viewData.errorList = searchRes.errors;
                  }
                }).finally(function() {
                $scope.$emit('hideBlockUi');
              });
            });
        } else if ($scope.viewData.template === 'ResList' || $scope.viewData.template === 'PoliciesList') {
          // jak jesteśmy na liście, to ten przycisk tylko zamyka okno
          $scope.helper.setPromiseAsRejected();
          $scope.helper.hideModal();
        }
      };

      /**
       * omit proper fields based on subject type
       * @param dynamicFilters
       * @return {*}
       */
      $scope.mapSearchRequest = function(dynamicFilters) {
        var filtersCopy = _.cloneDeep(dynamicFilters);
        if ($scope.viewData.subjectType === $scope.SUBJECT_TYPE_PERSON) {
          if (!$scope.viewData.personIsForeigner) {
            // IHESTIABAS-6426 pesel for polish, identityNumber for foreigners
            filtersCopy.pesel = filtersCopy.identityNumber;
            delete filtersCopy.identityNumber;
          }
          return _.omit(filtersCopy, $scope.companyFields);
        } else {
          return _.omit(filtersCopy, $scope.personFields);
        }
      };

      $scope.mapTokenRequest = function(client) {
        var clientCopy = _.cloneDeep(client);
        if ($scope.viewData.subjectType === $scope.SUBJECT_TYPE_PERSON) {
          if(clientCopy.pesel) {
            clientCopy.identityNumber = clientCopy.pesel;  
          }
          delete clientCopy.pesel;
          return _.pick(clientCopy, $scope.personFields);
        } else {
          return _.pick(clientCopy, $scope.companyFields);
        }
      };

      $scope.onSubjectTypeChange = function() {
        $scope.viewData.dynamicFilters = _.cloneDeep($scope.defaultDynamicFilters);
      };
    }
  ]);
