/**
 * helper do parsowania tekstu
 */

angular.module('ihestiaCommonDirectives')
  .factory('ihestiaTextHelper', [
    function() {

    var TextHelper = function() {
      // var self = this;

      this.prepareLink = function(rawText) {
        var parsedText;
        // wyciągamy link dla przykładowego wejścia:
        // Zapoznałem(am) się i akceptuję treść [regulaminu](https://link.do/regulaminu.pdf) dla ubezpieczeń majątkowych.
        if(rawText && rawText.indexOf('[') !== -1){
          var linkRegex = /\[(.+)\]\((.+)\)/;
          var matches = linkRegex.exec(rawText);
          if(matches && matches.length === 3) { // w matches mamy pełny string [regulaminu](https://link.do/regulaminu.pdf) oraz składowe [.+] i (.+)
            var linkString = '<a href="' + matches[2] + '" target="_blank" class="link link-primary">' + _.escape(matches[1]) + '</a>';
            var replaceStart = rawText.indexOf(matches[0]);
            parsedText = _.escape(rawText.substr(0, replaceStart)) + linkString + _.escape(rawText.substr(replaceStart + matches[0].length));
          } else {
            parsedText = _.escape(rawText);
          }
        } else if (rawText) {
          parsedText = _.escape(rawText);
        }
        return parsedText;
      };
    };

    return new TextHelper();

  }]);