angular.module('ihestiaWidgets.commonOperations')
  .factory('taskSearchModalHelper', ['ihestiaConfigHelper', '$window',
    function(ihestiaConfigHelper, $window) {

    var TaskSearchModalHelper = function() {
      var self = this;

      this.searchData = null;
      this.tasksListResponse = null;


      this.clearData = function()
      {
        self.searchData = null;
        self.tasksListResponse = null;
      };

      this.goToTask = function(task)
      {        
        $window.open('#/task/'+task.taskId, '_blank');  
      };
    };

    return new TaskSearchModalHelper();
  }]);