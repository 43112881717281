angular.module('salesPath2')
  .service('oczpHelper', ['resourceHelper', 'CONSTANTS', 'CONFIG', 'mainDataContainer', 'personHelper', 'applicationHelper', 'actionHelper', 'sp2SelectionHelper', 'RESOURCES', 'appVariables', 'dataContainerHelper', 'allowedChangesHelper', 'errorHelper', 'supplementHelper', 'renewalHelper',
    function(resourceHelper, CONSTANTS, CONFIG, mainDataContainer, personHelper, applicationHelper, actionHelper, selectionHelper, RESOURCES, appVariables, dataContainerHelper, allowedChangesHelper, errorHelper, supplementHelper, renewalHelper) { // eslint-disable-line angular/di
      var OczpHelper = function() {
        var self = this;
        /**
         * dane do szablonu i kontrolera
         * @type {Object}
         */
        this.tplData = {
          prodDef: resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP),
          showAddInsuredBtn: !appVariables.readOnly, //czy pokazywać przycisk dodawania ubezpieczonego
          datesPerVariant: false, //czy osobno daty ochrony dla każdego wariantu
          variantDates: {}, //daty per wariant (ich kopia z datacontainera), o ile taka konfiguracja produktu
          oczpInsured: [], //dane ubezpieczonych w OCZP
          premiums: {}, //składki
          activeInsured: null,
          startMinDate: {},
          startMaxDate: {}
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
              self.refreshSingleInsured(arguments[1].id);
              break;
            case 'personAdded':
              if (angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PRODUCT_OCZP) {
                self.addInsured(arguments[1].id);
              }
              break;
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.refreshRenewalData();
              self.refreshErrorPage();
              break;
            case 'refreshAllInsured':
              if (arguments[1] === CONSTANTS.PRODUCT_OCZP) {
                self.refreshInsured();
              }
              break;
            case 'languageChanged.afterStateReload':
              self.translate();
              break;
            default:
              break;
          }
        };

        this.initData = function() {
          self.tplData.datesPerVariant = CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1;
          self.initInsurer();
          self.refreshVariantProtectionDates();
          self.refreshInsured();
          self.refreshPremiums();
          self.setMinMaxProtectionDate();
        };

        this.setMinMaxProtectionDate = function() {
          if (appVariables.isSupplement) {
            angular.forEach(self.tplData.prodDef.VARIANTLIST, function(variant) {
              var dates = supplementHelper.getAvailablePeriodInsurance(CONSTANTS.PRODUCT_OCZP, null, variant.CODE);
              self.tplData.startMinDate[variant.CODE] = dates.minDate.toString('yyyy-MM-dd');
              self.tplData.startMaxDate[variant.CODE] = dates.maxDate.toString('yyyy-MM-dd');
            });

          } else {
            angular.forEach(self.tplData.prodDef.VARIANTLIST, function(variant) {
              self.tplData.startMinDate[variant.CODE] = mainDataContainer.minPropertyDate;
            });
          }
        };

        /**
         * tlumaczenie danych dla GUI po zmianie jezyka
         */
        this.translate = function() {
          self.tplData.prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP);
        };

        /**
         * inicjalizacja zwiazana z ubezpieczającym - tylko apka typu singleProduct
         * Dodaje ub-cego jako ubezpieczonego, o ile nie istnieje jeszcze ubezpieczony w produkcie OCZP
         */
        this.initInsurer = function() {
          if (CONFIG.BEHAVIOR.autoAddInsurerToInsured && CONFIG.BEHAVIOR.isSingleProduct) {
            var insurer = dataContainerHelper.getInsurer(),
              mainInsured = dataContainerHelper.getMainInsured();
            if (insurer !== null && insurer.getAdditionalData(CONSTANTS.PRODUCT_OCZP) === null && mainInsured !== null && mainInsured.getAdditionalData(CONSTANTS.PRODUCT_OCZP) === null) {
              self.addInsured(mainDataContainer.insurerId, false);
              self.togglePersonVariant(mainDataContainer.insurerId, CONSTANTS.VARIANT_I);
            }
          }
        };

        /**
         * aktualizacja danych o ubezpieczonych
         */
        this.refreshInsured = function() {
          var insured = [],
            productPersonData = self.getPersons();
          angular.forEach(productPersonData, function(productData, clientId) {
            insured.push(self.getInsuredData(clientId));
          });
          self.tplData.oczpInsured = insured;
          self.refreshButtonsData();
          self.refreshActiveInsured();
        };

        /**
         * odswieza aktywnego ubezpieczonego dla gui
         * @return {[type]} [description]
         */
        this.refreshActiveInsured = function() {
          if (self.tplData.oczpInsured.length === 0) {
            self.tplData.activeInsured = null;
          } else {
            var activePresent = false;
            lsnNg.forEach(self.tplData.oczpInsured, function(insured) {
              if (self.tplData.activeInsured === insured.clientId) {
                activePresent = true;
                return false;
              }
              return true;
            });
            if (!activePresent) { //zaznaczamy pierwszego z gory
              self.tplData.activeInsured = self.tplData.oczpInsured[0].clientId;
            }
          }
        };

        /**
         * aktualizuje dane konkretnego ubezpieczonego
         * @param  {String} clientId
         */
        this.refreshSingleInsured = function(clientId) {
          lsnNg.forEach(self.tplData.oczpInsured, function(insured, idx) {
            if (insured.clientId === clientId) {
              var insuredData = self.getInsuredData(clientId);
              if (insuredData === null) { //brak osoby - odswiezamy cala liste
                self.refreshInsured();
              } else {
                self.tplData.oczpInsured[idx] = insuredData;
              }
              return false;
            }
            return true;
          });
        };

        /**
         * aktualizuje dane dotyczące przycisków
         */
        this.refreshButtonsData = function() {
          if (appVariables.readOnly || CONFIG.BEHAVIOR.mainInsuredOnlyRisks.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1 && self.tplData.oczpInsured.length > 0) { //oczp tylko dla glownego ubezpieczonego
            self.tplData.showAddInsuredBtn = false;
          } else {
            self.tplData.showAddInsuredBtn = true;
          }
        };

        /**
         * zwraca zestaw danych o ubezpieczonym
         * @param  {String} clientId
         * @return {Object|null} null gdy brak osoby w oczp
         */
        this.getInsuredData = function(clientId) {
          var person = mainDataContainer.persons[clientId],
            personData = person.getData(),
            prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP),
            professionSum = {}, //sumy ubezp. dla zawodów/profesji
            oczpData = angular.copy(person.getAdditionalData(CONSTANTS.PRODUCT_OCZP)),
            allowedVariants = {},
            mainInsuredOnlyVariants = angular.isDefined(CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_OCZP]),
            isMutliVariant = self.isMultiVariantProduct(),
            selectedVariant = null, //wybrany wariant w przypadku gdy isMultiVariant = false
            selectedSpecializations = [], //wybrane specjalizacje
            prevSelVnts = selectionHelper.getPreviouslySelectedVariants(CONSTANTS.PRODUCT_OCZP, clientId);

          if (oczpData === null) { //przypadek gdy jakiś niezależny mechanizm usunął osobę z ubezpieczenia - należy odświeżyć całą listę ubezpieczonych
            return null;
          }

          if (!angular.isString(oczpData.job)) {
            oczpData.job = CONSTANTS.OCZP_JOB_CHOOSE;
          }

          if (angular.isArray(oczpData.specializations) && angular.isDefined(RESOURCES.OCZP_JOBS_SPEC_LIST) && angular.isArray(RESOURCES.OCZP_JOBS_SPEC_LIST[oczpData.job])) {
            angular.forEach(RESOURCES.OCZP_JOBS_SPEC_LIST[oczpData.job], function(spec) {
              if (oczpData.specializations.indexOf(spec.CODE) !== -1) {
                selectedSpecializations.push(spec);
              }
            });
          }

          if (!isMutliVariant) { //przygotowujemy obiekt z wariantami, jakiego spodziewa się templatka
            selectedVariant = oczpData.variants;
            oczpData.variants = {};
          }

          lsnNg.forEach(prodDef.VARIANTLIST, function(variant) {
            if (variant.PROFESSION && angular.equals(professionSum, {})) { //oc zawodu
              angular.forEach(RESOURCES.OCZP_PROFESSION_LIST, function(item) {
                professionSum[item.CODE] = item;
              });
            }
            allowedVariants[variant.CODE] = !(mainInsuredOnlyVariants && CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_OCZP].indexOf(variant.CODE) !== -1 && mainDataContainer.mainInsuredId !== clientId);
            if (!isMutliVariant) {
              oczpData.variants[variant.CODE] = (selectedVariant === variant.CODE);
            }
          });

          var data = {
            personData: personData,
            clientId: clientId,
            personInfo: personHelper.getPersonInfo(person, self.getPersonInfoSettings()),
            collapsedPersonInfo: personHelper.getPersonInfo(person, self.getCollapsedPersonInfoSettings()),
            sumInsured: dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_PERSON, clientId, CONSTANTS.PRODUCT_OCZP),
            OCZP: oczpData,
            professionSum: professionSum,
            isMainInsured: (mainDataContainer.mainInsuredId === clientId),
            allowedVariants: allowedVariants,
            allowedChanges: allowedChangesHelper.getAllowedChangesFor(person),
            previouslySelectedVariants: prevSelVnts,
            unchangeableVariants: selectionHelper.getUnchangeableVariants(selectionHelper.getHistoricalDisabledVariants(CONSTANTS.PRODUCT_OCZP, clientId), prevSelVnts, oczpData.variants),
            selectedSpecializations: selectedSpecializations,
            editable: allowedChangesHelper.canRemovePersonFromRisk(clientId, CONSTANTS.PRODUCT_OCZP),
            riskErrors: self.getAllErrorPageForPerson(clientId),
            renewedVariants: self.getRenewedVariantsForPerson(clientId)
          };
          return data;
        };

        /**
         * zwraca ustawienia dla informacji o ubezpieczonym
         * @return {Object}
         */
        this.getPersonInfoSettings = function() {
          return {
            elements: [{
              field: 'lastName'
            }, {
              field: 'firstName'
            }],
            maxLength: 999
          };
        };

        /**
         * zwraca ustawienia dla zwiniętej informacji o ubezpieczonym
         * @return {Object}
         */
        this.getCollapsedPersonInfoSettings = function() {
          return {
            elements: [{
              field: 'lastName'
            }, {
              field: 'firstName'
            }],
            maxLength: 999
          };
        };

        //zmiana stanowiska osoby householdPeopleChanged
        this.personJobHasBeenChanged = function(personId, jobCode, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          self.tplData.premiums = {}; // reset premiums on any change
          var person = mainDataContainer.persons[personId];
          person.setAdditionalData(jobCode, [CONSTANTS.PRODUCT_OCZP, 'job']);
          if (jobCode !== CONSTANTS.OCZP_JOB_DOCTOR) {
            person.deleteAdditionalData([CONSTANTS.PRODUCT_OCZP, 'specializations']);
          }
          self.refreshSingleInsured(personId);
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        //zmiana liczby osób w gospodarstwie domowym
        this.householdPeopleChanged = function(personId, householdPeople, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          var person = mainDataContainer.persons[personId];
          person.setAdditionalData(householdPeople, [CONSTANTS.PRODUCT_OCZP, 'householdPeople']);
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        /**
         * usunięcie specjalizacji z danych ubezpieczonego w OCZP
         * @param  {String} personId clientId osoby
         * @param  {String} specialization
         * @param  {Boolean} tarifficate
         */
        this.deleteSpecialization = function(personId, specialization, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          var person = mainDataContainer.persons[personId];
          var oczpData = person.getAdditionalData(CONSTANTS.PRODUCT_OCZP);
          var specializations = oczpData.specializations;
          if (specializations.indexOf(specialization) !== -1) {
            specializations.splice(specializations.indexOf(specialization), 1);
          }
          self.refreshSingleInsured(personId);
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        /**
         * zapis specjalizacji na osobie
         * @param  {String} personId        [description]
         * @param  {String[]} specializations tablica kodów specjalizacji
         * @param  {Boolean} tarifficate czy staryfikować wniosek po zapisie
         */
        this.saveSpecializations = function(personId, specializations, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          var person = mainDataContainer.persons[personId];
          person.setAdditionalData(specializations, [CONSTANTS.PRODUCT_OCZP, 'specializations']);
          actionHelper.runAction('personOczpDataChanged', personId);
          self.refreshSingleInsured(personId);
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        //dodano osobę do OCZP
        this.addInsured = function(personId, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          if (CONFIG.BEHAVIOR.mainInsuredOnlyRisks.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1) {
            //ryzyko tylko na głównym ubezpieczonym i dodanie osoby powoduje zmianę głównego ubezpieczonego
            personHelper.changePerson(mainDataContainer.mainInsuredId, personId, CONSTANTS.PERSON_ROLE_INSURED);
          }

          var person = mainDataContainer.persons[personId];
          self.setDefaultAdditionalData(person);

          dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_PERSON, personId, CONSTANTS.PRODUCT_OCZP, self._getPersonDefaultVarinatsSumInsured());

          if (dataContainerHelper.getOczpPersonsCount() === 1) //jeśli przed dodaniem pierwszej osoby na matrycy było coś zaznaczone to próbujemy to zaznaczyć
          {
            if (self.isMultiVariantProduct()) {
              angular.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_OCZP], function(selected, variant) {
                if (selected) {
                  selectionHelper.selectPersonVariant(personId, CONSTANTS.PRODUCT_OCZP, variant);
                }
              });
            } else //pojedynczy wariant
            {
              if (mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_OCZP] !== null) {
                selectionHelper.selectPersonVariant(personId, CONSTANTS.PRODUCT_OCZP, mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_OCZP]);
              }
            }
          }
          self.refreshInsured();
          self.tplData.activeInsured = personId;
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        //dodawanie domyslnych danych dotyczacych OCZP dla osoby
        this.setDefaultAdditionalData = function(person) {
          if (self.isMultiVariantProduct()) {
            person.setAdditionalData({
              variants: {}
            }, CONSTANTS.PRODUCT_OCZP);
          } else {
            person.setAdditionalData({
              variants: null
            }, CONSTANTS.PRODUCT_OCZP);
          }

          if (parseInt(person.metaData.get('clientId'), 10) === parseInt(mainDataContainer.mainInsuredId, 10)) {
            person.setAdditionalData('1', [CONSTANTS.PRODUCT_OCZP, 'householdPeople']);
          }
        };

        //zwraca domyslny zestaw wariantow i ich sum
        this._getPersonDefaultVarinatsSumInsured = function() {
          var prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP);
          var variants = {};
          angular.forEach(prodDef.VARIANTLIST, function(variant) {
            variants[variant.CODE] = variant.SUMINSUREDLIST ? variant.SUMINSUREDLIST[0].VALUE : 0;
          });
          return variants;
        };

        //ustawia date dla wariantu OCZP
        this.setVariantDate = function(type, date, variant) {
          if (date === '') {
            date = null;
          }
          mainDataContainer.oczpProtectionDates[variant][type] = date;
          if (type === 'start' && date !== null && !appVariables.isSupplement) {
            mainDataContainer.oczpProtectionDates[variant].end = new XDate(date).addYears(1).addDays(-1).toString('yyyy-MM-dd');
          }
          //jeśli ustawiamy datę początku na dzisiejszą, to resetujemy ustawinie godziny
          if (type === 'start' && date === RESOURCES.TODAY) {
            dataContainerHelper.initProtectionTime();
          }
          self.refreshVariantProtectionDates(type, variant);
          actionHelper.runAction('tarifficate');
        };

        /**
         * aktualizuje daty ochrony na wariantach
         */
        this.refreshVariantProtectionDates = function(type, variant) {
          if (self.tplData.datesPerVariant) {
            if (appVariables.isSupplement && angular.isDefined(type) && angular.isDefined(variant)) { // jesli zmieniono date przestawiamy wszystkie inne
              var value = mainDataContainer.oczpProtectionDates[variant][type];
              dataContainerHelper.setAllProtectionDates(type, value);
            }
            self.tplData.variantDates = angular.copy(mainDataContainer.oczpProtectionDates);
          }
        };

        /**
         * ustawia sume ubezpieczenia dla wybranego wariantu dla osoby
         * @param {String} personId
         * @param {String} variant
         * @param {String|Number} sumInsured
         */
        this.setPersonSumInsured = function(personId, variant, sumInsured) {
          dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_PERSON, personId, CONSTANTS.PRODUCT_OCZP, sumInsured, variant);
          actionHelper.runAction('tarifficate');
        };

        /**
         * usuniecie ubezpieczonej osoby
         * @param  {String} personId clientId osoby
         */
        this.deleteInsured = function(personId) {
          var person = mainDataContainer.persons[personId];

          delete mainDataContainer.suList[CONSTANTS.PRODUCT_TYPE_PERSON][personId][CONSTANTS.PRODUCT_OCZP];
          person.deleteAdditionalData(CONSTANTS.PRODUCT_OCZP);

          selectionHelper.tryUnselectPersonalProduct(CONSTANTS.PRODUCT_OCZP);
          actionHelper.runAction('personDeleted', personId);
          self.refreshInsured();
          actionHelper.runAction('tarifficate');
        };

        /**
         * przełączanie wariantu dla osoby
         * @param  {String} personId
         * @param  {String} variant
         */
        this.togglePersonVariant = function(personId, variant) {
          var res = selectionHelper.togglePersonVariant(personId, CONSTANTS.PRODUCT_OCZP, variant);
          self.refreshSingleInsured(personId);
          return res;
        };

        /**
         * zwraca obiekt z identyfikatorami osób oraz ich danymi dla OCZP
         * {id: {dane OCZP}}
         * @return {Object}
         */
        this.getPersons = function() {
          return dataContainerHelper.getPersonsForPersonalProduct(CONSTANTS.PRODUCT_OCZP);
        };

        /**
         * czy OCZP jest produkctem, dla którego można zaznaczyć wiele wariantów ubezpieczenia
         * @return {Boolean} true gdy tak
         */
        this.isMultiVariantProduct = function() {
          return CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1;
        };

        /**
         * aktualizuje składki
         */
        this.refreshPremiums = function() {
          var premiumContainer = 'premiumList';
          self.tplData.premiums = {};
          angular.forEach(mainDataContainer[premiumContainer][CONSTANTS.PRODUCT_TYPE_PERSON], function(risks, personId) {
            if (angular.isDefined(risks[CONSTANTS.PRODUCT_OCZP])) {
              self.tplData.premiums[personId] = angular.fromJson(angular.toJson(risks[CONSTANTS.PRODUCT_OCZP]));
            }
          });
        };

        /**
         * odświeza dane dotyczące wznawainych wariantów dla wszystkich osób
         * @return {[type]} [description]
         */
        this.refreshRenewalData = function() {
          angular.forEach(self.tplData.oczpInsured, function(data, clientId) {
            self.tplData.oczpInsured[clientId].renewedVariants = self.getRenewedVariantsForPerson(data.clientId);
          });
        };

        /**
         * zwraca dane dotyczące wznawianych wariantów dla podanej osoby
         * @param  {String} clientId clientId osoby
         * @return {Object} {kod_wariantu: true|false}, true gdy do zaznaczenia
         */
        this.getRenewedVariantsForPerson = function(clientId) {
          var prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP),
            renewedVariants = {},
            renewedVariantsArray = renewalHelper.isRenewal() ? renewalHelper.getPersonalRiskMarks(CONSTANTS.PRODUCT_OCZP, clientId) : []; //znaczniki wznowionych wariantów
          angular.forEach(prodDef.VARIANTLIST, function(variantDef) {
            renewedVariants[variantDef.CODE] = renewedVariantsArray.indexOf(variantDef.CODE) !== -1;
          });
          return renewedVariants;
        };

        /**
         * zwraca dane z tplData aktywnego ubezpieczonego
         * @param {String} [personId] jesli nie podano, to zwraca dla aktualnie wybranej na gui osoby
         * @return {Object|null} dane osoby z self.tplData.oczpInsured lub null gdy brak aktywnegoubezpieczonego
         */
        this.getInsuredTplData = function(personId) {
          personId = personId || self.tplData.activeInsured || null;
          var found = null;
          if (personId !== null) {
            lsnNg.forEach(self.tplData.oczpInsured, function(data) {
              if (data.clientId === personId) {
                found = data;
                return false;
              }
              return true;
            });
          }
          return found;
        };

        this.refreshErrorPage = function() {
          angular.forEach(self.tplData.oczpInsured, function(data, key) {
            self.tplData.oczpInsured[key].riskErrors = self.getAllErrorPageForPerson(data.clientId);
          });
        };

        /**
         * ustawia błędy (o ile są) dla aktualnej strony bledow
         */
        this.getAllErrorPageForPerson = function(clientId) {
          var params = {
            productType: CONSTANTS.PRODUCT_TYPE_PERSON,
            objectClientId: clientId,
            risk: {
              code: CONSTANTS.PRODUCT_OCZP,
              productCode: CONSTANTS.PRODUCT_OCZP
            }
          };

          return errorHelper.getErrorsFieldsForRisk(params);
        };

      };

      return new OczpHelper();
    }
  ])
  .run(['oczpHelper', 'actionHelper',
    function(oczpHelper, actionHelper) {
      actionHelper.registerHelper('oczpHelper', oczpHelper);
    }
  ]);