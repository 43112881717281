/**
 * Sortowanie uwzględniąjące polskie znaki
 * (problem z sortowaniem 'a', 'b', 'z', 'ś', zamiast 'a', 'b', 'ś', 'z')
 */
angular.module('lsnBase.filters')
  .filter('localeOrderBy', function() {
    return function(items, orderBy, reverse) {
      items.sort(function(a, b) {
        if(!angular.isObject(a) || !angular.isObject(b)) {
          // nie wiadomo co
          return 0;
        } else if(!angular.isString(a[orderBy]) || !angular.isString(b[orderBy])) {
          // liczby
          if(a === b) {
            return 0;
          } else if (reverse) {
            return a > b ? -1 : 1;
          } else {
            return a < b ? -1 : 1;
          }
        } else if (angular.isFunction(a[orderBy].localeCompare) && angular.isFunction(b[orderBy].localeCompare)) {
          // stringi
          return reverse ? b[orderBy].localeCompare(a[orderBy]) : a[orderBy].localeCompare(b[orderBy]);
        } else {
          // nie wiadomo co, raczej nic nie powinno tu wpaść
          return 0;
        }
      });
      return items;
    };
  });