angular.module('salesPath2')
  .controller('summaryClausesModalCtrl', ['$scope', 'summaryClausesHelper', 'appVariables',
    function($scope, summaryClausesHelper, appVariables) {

      $scope.policyId = '';
      $scope.clauses = '';

      /**
      * init
      */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.clauses = summaryClausesHelper.getClauses();
        $scope.policyId = summaryClausesHelper.getPolicyId();
      };

      /**
      * Odpalenie watchy
      */
      $scope.fireListeners = function() {
        // ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.summaryClausesModal', function($event, modal) {
          if(appVariables.readOnly || appVariables.isSupplement){
            return;
          }

          if (modal.validForm) {
            summaryClausesHelper.saveClauses($scope.clauses, $scope.policyId);
          } else {
            $event.preventDefault();
          }
        });
      };

      $scope.init();

    }
  ]);