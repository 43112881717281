/**
 * helper dotyczący dozwolonych zmian na wniosku
 */
angular.module('salesPath2').service('allowedChangesHelper', ['SPD', 'mainDataContainer', 'CONSTANTS', 'AllowedFormAttributeModel', 'sp2CommonHelper', 'supplementHelper', 'appVariables', 'ApplicationAllowedChangesObjectModel', 'dataContainerHelper', 'RESOURCES',
  function(SPD, mainDataContainer, CONSTANTS, AllowedFormAttributeModel, sp2CommonHelper, supplementHelper, appVariables, ApplicationAllowedChangesObjectModel, dataContainerHelper, RESOURCES) {
    var AllowedChangesHelper = function() {
      var self = this;

      /**
       * zwraca obiekt z informacjami o mozliwych zmianach dla poszczegolnych atrybutów obiektu
       * @param  {*} obj lokalizacja, pojazd itd.
       * @return {*} getData() z przekazanego obiektu z wartościami zamienionymi na informacje o dozwolonych zmianach (AllowedFormAttributeModel) z dopiskiem:
       * allDisabled: true|false ustawienie opisąjące ogólnie cały formularz - czy zmainy dozwolone czy nie
       * isNew: true|false ustawienie opisąjące czy obiekt (np osoba) jest nowa (uzywane np do sprawdzenia czy osobe w dokupieniu mozna usunac)
       */
      this.getAllowedChangesFor = function(obj) {
        var acData = self.getDefaultAllowedChangesForObject(obj),
          foundAcData = self.getAllowedFormChangesFor(obj);
        if (foundAcData !== null) {
          acData.allDisabled = !foundAcData.isNew; //dla nowego obiektu wszystkie zmiany dozwolone
          if (!angular.equals(foundAcData.attributes, {})) {
            angular.extend(acData.dynamicValues, foundAcData.attributes);
          }
          if (!angular.equals(foundAcData._additionalData, {})) {
            angular.extend(acData._additionalData, foundAcData._additionalData);
          }
        }
        self.prepareFinalAcData(acData, obj.objectName);
        return acData;
      };

      /**
       * sprawdza czy sa mozliwe zmiany na obiekcie
       * @param  {*} obj lokalizacja, pojazd itd.
       * @return {*} czy mozliwe zmiany
       */
      this.anyChangePossible = function(obj) {
        var acData = self.getAllowedChangesFor(obj);
        return !acData.allDisabled;
      };

      /**
       * zwraca obiekt zawierający informacje o mozliwych modyfikacjach dla poszczegolnych pol obiektu
       * @param  {*} obj EstateModel, VehicleModel itp.
       * @return {Object} "odbicie" modelu obiektu + flaga allDisabled
       * dodatkowow w o obiektach z additionalData, dołączony jest węzeł _additionalData
       */
      this.getDefaultAllowedChangesForObject = function(obj) {
        var acData = obj.getData(true, true),
          saved = obj.metaData.clientId !== null; //czy obiekt został już zapisany, jeśli nie to znaczy że jest nowym elementem
        //worzymy domyslny obiekt opisujący dostepnosci pol  wszystkie pola na przekazanym obiekcie są dozwolone
        angular.forEach(acData, function(val, name) {
          if (name === 'dynamicValues' || name === '_additionalData') {
            angular.forEach(val, function(attrVal, attrName) {
              var acAttr = new AllowedFormAttributeModel();
              acData[name][attrName] = acAttr;
            });
          } else {
            var acAttr = new AllowedFormAttributeModel();
            acData[name] = acAttr;
          }
        });
        acData.allDisabled = false;
        var methodName = '_getAllowedChangesFor' + obj.objectName;
        if (angular.isFunction(self[methodName])) {
          self[methodName](obj, acData);
        }
        //obsługa DiD
        if (saved && appVariables.isSupplement) {
          var acGiven = dataContainerHelper.getAllowedChangesFor(CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE[obj.objectName], obj.metaData.get('clientId'), true);
          acData.isNew = (acGiven && acGiven.isNew) ? true : false;
          supplementHelper.setDefaultAllowedChanges(acData, obj);
        } else {
          acData.isNew = true;
        }
        return acData;
      };

      /**
       * zwraca/przetwarza obiekt dozwolonych zmian dla nieruchomości
       * @param  {Object} obj obkeit danych, np. Estate, Person
       * @param  {Object} acData allowedChanges data, czyli obiekt opisujący dostępność atrybutów wgenerowany w self.getDefaultAllowedChangesForObject
       * @return {Object} zrzut z obj.getData() z wartościami zamienionymi na informacje o dozwolonych zmianach (AllowedFormAttributeModel)
       */
      this._getAllowedChangesForEstate = function(obj, acData) {
        angular.forEach(['_hasCoowners'], function(name) {
          if (angular.isUndefined(acData.dynamicValues[name])) {
            acData.dynamicValues[name] = new AllowedFormAttributeModel();
            acData.dynamicValues[name].disabled = false;
          }
        });
        return acData;
      };

      /**
       * zwraca/przetwarza obiekt dozwolonych zmian dla osoby
       * @param  {Object} obj Person
       * @param  {Object} acData allowedChanges data, czyli obiekt opisujący dostępność atrybutów wgenerowany w self.getDefaultAllowedChangesForObject
       * @return {Object} zrzut z obj.getData() z wartościami zamienionymi na informacje o dozwolonych zmianach (AllowedFormAttributeModel)
       */
      this._getAllowedChangesForPerson = function(obj, acData) {
        var disabled = appVariables.readOnly && !appVariables.isOcBuyer;
        angular.forEach(acData, function(val, name) {
          switch (name) {
            case 'pesel':
              acData[name].disabled = disabled ? true : dataContainerHelper.isNbkPerson(obj.metaData.get('clientId'));
              break;
            case 'dynamicValues':
              angular.forEach(val, function(dvVal, dvName) {
                acData.dynamicValues[dvName].disabled = disabled;
              });
              break;
            default:
              acData[name].disabled = disabled;
          }
        });
        return acData;
      };

      /**
       * zwraca/przetwarza obiekt dozwolonych zmian dla pojazdu
       * @param  {Object} obj Vehicle
       * @param  {Object} acData allowedChanges data, czyli obiekt opisujący dostępność atrybutów wgenerowany w self.getDefaultAllowedChangesForObject
       * @return {Object} zrzut z obj.getData() z wartościami zamienionymi na informacje o dozwolonych zmianach (AllowedFormAttributeModel)
       */
      this._getAllowedChangesForVehicle = function(obj, acData) {
        var disabled = appVariables.readOnly;
        angular.forEach(acData, function(val, name) {
          if (name === 'dynamicValues' || name === '_additionalData') {
            angular.forEach(val, function(attrVal, attrName) {
              switch (attrName) {
                case '_hasCoowners':
                case 'rightHandDrive':
                case 'isYoungDriver':
                case 'vehicleSpecialUsage':
                  acData[name][attrName].disabled = disabled && !appVariables.isOcBuyer;
                  break;
                default:
                  acData[name][attrName].disabled = disabled;
              }
            });
          } else {
            switch (name) {
              case 'mileage':
              case 'registration':
                acData[name].disabled = disabled && !appVariables.isOcBuyer;
                break;
              case 'productionYear' :
                acData[name].disabled = obj.metaData.className === 'Eurotax' || _.get(obj, '_additionalData.source', undefined) === 'etx';
                break;
              default:
                acData[name].disabled = disabled;
            }
          }
        });
        //dynamicValues oraz additionalData, które mogą inicjalnie nie być ustawione na obiekcie
        angular.forEach(['isLeased', 'copyVehicleValue', 'valueType'], function(name) {
          if (angular.isUndefined(acData._additionalData[name])) {
            acData._additionalData[name] = new AllowedFormAttributeModel();
            acData._additionalData[name].disabled = false;
          }
        });
        angular.forEach(['vehicleDamage', 'vehicleDamageDescription', 'vehicleValue', 'vehicleSpecialUsage', '_hasCoowners'], function(name) {
          if (angular.isUndefined(acData.dynamicValues[name])) {
            acData.dynamicValues[name] = new AllowedFormAttributeModel();
            acData.dynamicValues[name].disabled = false;
          }
        });
        return acData;
      };

      /**
       * zwraca obiekt opisujący dozwolone zmiany na obikcie w kontekście szablonu/formularza
       * @param  {Object} obj [description]
       * @return {Object}     [description]
       */
      this.getAllowedFormChangesFor = function(obj) {
        if (obj.metaData.clientId === null) {
          return null; //jesli nowy obiekt/niezapisany, to nawet nie próbujemy poszukiwać danych dla niego
        }
        var objType = CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE[obj.objectName],
          acData = dataContainerHelper.getAllowedChangesFor(objType, obj.metaData.get('clientId'));

        if (acData !== null) {
          //dynamicValues
          self._parseAllowedAttributes(acData.attributes, obj.get('dynamicValues'), SPD.objectsDynamicValues[obj.objectName]);
          //pola obiektu
          //additionalData
          self._parseAllowedAttributes(acData._additionalData, obj.getAdditionalData() || [], SPD.spdAdditionalDataAttributes);
        }
        return acData;
      };

      /**
       * [_getParsedAllowedAttributes description]
       * @param  {[type]} allowedContainer [description]
       * @param  {[type]} objContainer     [description]
       * @param  {[type]} attrsDef         [description]
       * @return {[type]}                  [description]
       */
      this._parseAllowedAttributes = function(allowedContainer, objContainer, attrsDef) {
        angular.forEach(allowedContainer, function(attrData, attrName) {
          if (!attrsDef[attrName]) { //brak definicji atrybutu w SPD, pomijamy go
            return true;
          }
          var methodName = '_parseAttr' + attrsDef[attrName][1];
          if (!angular.isFunction(self[methodName])) {
            allowedContainer[attrName] = self._parseAttrDefault(attrData, objContainer[attrName]);
          } else {
            allowedContainer[attrName] = self[methodName](attrData, objContainer[attrName]);
          }
          return true;
        });
      };

      /**
       * parsowanie atrybutu typu bool
       * @param  {Object} attrData zrzut z ApplicationAllowedChangesAttributeModel
       * @param  {*} value wartosc aktualna atrybutu z obiektu
       * @return {AllowedFormAttributeModel}
       */
      this._parseAttrBoolean = function(attrData, value) {
        var attr = new AllowedFormAttributeModel(),
          trueAllowed = attrData.allowedValues.indexOf(true) !== -1,
          falseAllowed = attrData.allowedValues.indexOf(false) !== -1;
        value = (typeof value === 'boolean' ? value : null);
        switch (value) {
          case null:
          case false:
            attr.disabled = !trueAllowed;
            break;
          case true:
            attr.disabled = !falseAllowed;
            break;
          default:
            break;
        }
        return attr;
      };

      /**
       * domyślne parsowanie atrybutu
       * @param  {Object} [attrData=undefined] zrzut z ApplicationAllowedChangesAttributeModel
       * @param  {*} [value=undefined] wartosc aktualna atrybutu z obiektu
       * @return {AllowedFormAttributeModel}
       */
      this._parseAttrDefault = function() {
        var attr = new AllowedFormAttributeModel();
        attr.disabled = false; //atrybut pojawił się w allowedChanges, więc odpowiadające mu pole formularza jest edytowalne
        return attr;
      };

      /**
       * dodaje informację o nowej osobie do allowedChanges
       * @param {String} clientId clientId osoby
       * @param {String} id metaData.id osoby
       */
      this.addNewPersonToAllowed = function(clientId, id, objectName) {
        var allowed = new ApplicationAllowedChangesObjectModel();
        allowed.setData({
          isNew: true,
          metaData: {
            clientId: clientId,
            id: clientId
          }
        });
        if (objectName === 'Person') {
          mainDataContainer.allowedChanges.subjects.persons.push(allowed.getData());
        } else {
          mainDataContainer.allowedChanges.subjects.organizations.push(allowed.getData());
        }

      };

      /**
       * czy można usunąć osobę z ryzyka osobowego
       * @param  {String} personId clientId osoby
       * @param  {String} productCode
       * @return {Boolean} true, gdy można usunąć
       */
      this.canRemovePersonFromRisk = function(personId, productCode) { //eslint-disable-line
        if (appVariables.isSupplement) {
          //aktualnie sprawdzamy usuwalnosc osob tylko w trybie dokupien
          return angular.equals(dataContainerHelper.getPreviousVariantsForProduct(productCode, personId), {});
        } else if (appVariables.readOnly) {
          //w readonly blokujemy zawsze
          return false;
        }
        return true;
      };

      /**
       * czy można usunąć osobę/grupę z ubezpieczonych
       * UWAGA
       * do zaimplementowania część obsługująca grupy, jeśli będzie potrzebna
       * @param  {Object} subject pomiot - osoba/grupa
       * @return {Boolean} true, gdy można usunąć
       */
      this.canRemoveSubjectFromInsured = function(subject) { //eslint-disable-line
        if (appVariables.isSupplement) {
          //aktualnie sprawdzamy usuwalnosc osob tylko w trybie dokupien
          var acData = dataContainerHelper.getAllowedChangesFor(CONSTANTS.OBJECTNAME_TO_OBJECT_TYPE[subject.objectName], subject.metaData.get('clientId'), true);
          return (angular.isObject(acData) && acData.isNew) ? true : false;
        } else if (appVariables.readOnly) {
          //w readonly blokujemy zawsze
          return false;
        }
        return true;
      };

      /**
       * przygotowuje ostateczną wersję acData (allowedChangesData)
       * @param  {object} acData
       * @param  {string} objectName
       * @return {object} acData
       */
      this.prepareFinalAcData = function(acData, objectName) {
        var attrsToOmit = self.getAttrsToOmitInAllDisabled(objectName),
          pureROMode = appVariables.readOnly && !appVariables.isOcBuyer; //tryb blokady edycji wszystkich podmiotów, przedmiotów ubezpieczeń oraz zaznaczeń ryzyk
        lsnNg.forEach(acData, function(obj, name) {
          if (name !== '_additionalData' || name !== 'dynamicValues') {
            if (pureROMode) {
              obj.disabled = true;
              lsnNg.forEach(obj, function(obj2) {
                if (angular.isObject(obj2)) {
                  obj2.disabled = true;
                }
              });
            } else {
              lsnNg.forEach(obj, function(obj2, subAttrName) {
                if (angular.isObject(obj2) && obj2.disabled === false && (angular.isUndefined(attrsToOmit[name]) || attrsToOmit[name].indexOf(subAttrName) === -1)) {
                  acData.allDisabled = false;
                  return false;
                }
                return true; //continue
              });
            }
          } else {
            if (pureROMode) {
              obj.disabled = true;
            } else if (obj.disabled === false || !acData.allDisabled) {
              acData.allDisabled = false;
              return false;
            }
          }
          return true; //continue
        });
        if (pureROMode) {
          acData.allDisabled = true;
        }
        return;
      };

      this.getAttrsToOmitInAllDisabled = function(objectName) {
        var attrsToOmit = {};
        if (objectName === 'Person') {
          attrsToOmit = {
            dynamicValues: []
          };
          angular.forEach(RESOURCES.NNW_PERSON_ATTRIBUTES, function(obj, name) {
            attrsToOmit.dynamicValues.push(name);
          });
        }
        return attrsToOmit;
      };

    };

    return new AllowedChangesHelper();
  }
]);
