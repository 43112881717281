angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestAbstractVehicleDictionariesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestAbstractVehicleDictionariesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        var self = this;

        this.resource = 'vehicles';
        this.parentGet = this.get;
        this.parentFindByParams = this.findByParams;
        this.pageSize = 99999;

        /**
         * Napdpisujemy geta
         * @param  {[type]}   id            [description]
         * @param  {[type]}   filters       [description]
         * @param  {[type]}   subResource   [description]
         * @param  {Function} callback      [description]
         * @param  {[type]}   errorCallback [description]
         * @param  {[type]}   httpParams    [description]
         * @return {[type]}                 [description]
         */
        this.get = function(id, filters, subResource, callback, errorCallback, httpParams) {
          return self.parentGet(self.id, filters, subResource, callback, errorCallback, httpParams);
        };

        /**
         * @param  {[type]} params [description]
         * @return {[type]}        [description]
         */
        this.findByParams = function(params){
            params.id = self.id;
            return self.parentFindByParams(params);
        };

      };
      return RestAbstractVehicleDictionariesSvc;
    }
  ]);
