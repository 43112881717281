angular.module('lsnBase.models')
  .provider('lsnModelFactoryParams', [
    function() {

      var provider = this;

      /**
       * prefiksy dla modeli danych ułożone od najwyższego priorytetu
       * @type {String[]}
       */
      this.modelPrefixes = ['Lsn', ''];

      /**
       * czy zezwolić na modele niewersjonowanie (kompatybilność wstecz np. dla ihestiaBase)
       * @type {Boolean}
       */
      this.allowNotVersioned = true;

      /**
       * domyślna wersja modelu jaką lsnModelFactory będzie domyślnie używał (jeśli nie przekazano przy getObject wersji, to z korzystamy z tego ustawienia)
       * @type {String}
       */
      this.defaultVersion = 'v1';

      this.$get = [function(){
        return provider;
      }];
    }
  ]);