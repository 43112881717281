angular.module('ihestiaCommonDirectives')
  .service('ihestiaDownloadHelper', ['filesSvc', 'fileServerSvc', '$filter', 'ihestiaCommonConfirmModalHelper', 'ihestiaBaseDownloadHelper',
    function(filesSvc, fileServerSvc, $filter, ihestiaCommonConfirmModalHelper, ihestiaBaseDownloadHelper) {
      var DownloadHelper = function() {
        var self = this;
        this.download = function(fileName, fileServerId) {
          return self.downloadFile(fileServerId, fileName, true);
        };

        /**
         * Ściągnięcie pliku po id
         * @param  {String} fileServerId     [description]
         * @param  {String} defaultName      domyślna nazwa pliku (jesli nie będzie jej w usłudze)
         * @param  {Boolean} forceDefaultName czy wykorzystać zawsze defaultName
         * @return {Promise} promise z filesSvc
         */
        this.downloadFile = function(fileServerId, defaultName, forceDefaultName) {
          return filesSvc.postToken(fileServerId, function(response) {

            var data = response.data;
            if ($.isEmptyObject(data) || typeof data.tokenId === 'undefined') {
              self.showErrorAlertModal($filter('translate')('Public.tokenNotReturnedByService'));
            } else {
              self.downloadFileByTokenId(data.tokenId, defaultName, forceDefaultName);
            }
          }, function(rejection) {
            self.showErrorAlertModal(null, rejection.status);
          }, {
            allowedStatuses: [404, 403, 410]
          });
        };

        /**
         * Ściągnięcie pliku po id z usługi postToken 
         * @param  {String} tokenId          (np. 'd29639a0-60e2-44f4-9867-939e05037bd3')
         * @param  {String} defaultName      domyślna nazwa pliku (jesli nie będzie jej w usłudze)
         * @param  {Boolean} forceDefaultName czy wykorzystać zawsze defaultName
         * @return {Promise} promise z fileServerSvc
         */
        this.downloadFileByTokenId = function(tokenId, defaultName, forceDefaultName) {
          return ihestiaBaseDownloadHelper.downloadFileByTokenId(tokenId).then(function(result) {
              var headers = result.headers();

              var blob = new Blob([result.data], {
                type: headers['content-type']
              });

              var fileName = '';
              if (!forceDefaultName) {
                var contentDisposition = headers['content-disposition'];
                var fileNameString = 'filename=';
                if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
                  var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
                  if (preCut.indexOf(';') !== -1) {
                    preCut = preCut.substr(0, preCut.indexOf(';'));
                  }
                  //wywalamy jeszcze '"' z obu stron
                  if (preCut.substr(0, 1) === '"') {
                    preCut = preCut.substr(1);
                  }
                  if (preCut.substr(preCut.length - 1, 1) === '"') {
                    preCut = preCut.substr(0, preCut.length - 1);
                  }

                  fileName = decodeURIComponent(preCut);
                  if (!fileName) {
                    fileName = defaultName;
                  }
                }
              } else {
                fileName = defaultName;
              }

              saveAs(blob, fileName);
            },
            function(rejection) {
              self.showErrorAlertModal(null, rejection.status);
            }
          );
        };

        this.showErrorAlertModal = function(text, status) {
          var title = $filter('translate')('Public.errorOccurred');

          if (status === 410) {
            title = $filter('translate')('titleWindowFileExpire', {
              componentCode: 'Public'
            });
            text = $filter('translate')('Public.fileExpired');
          } else if (status === 403) {
            text = $filter('translate')('Public.noPermissionsForFile');
          } else if (status === 404) {
            text = $filter('translate')('Public.fileNotFound');
          } else if (typeof status !== 'undefined') {
            return;
          }

          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: title,
            okBtnName: $filter('translate')('Public.close'),
            cancelBtnName: '',
            text: text,
            okBtnCallback: angular.noop
          });
        };
      };

      return new DownloadHelper();
    }
  ]);