angular.module('ihestiaWidgets.policyDetails')
  .factory('EditBeneficiariesRequestModel', ['LsnAbstractModel',
    function(LsnAbstractModel) {
      var EditBeneficiariesRequestModel = function() {
        this.objectName = 'EditBeneficiariesRequest';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          validFrom: {
            type: 'string'
          },
          operCauseCode: {
            type: 'string'
          },
          beneficiaries: {
            type: 'array',
            elementsType: 'object'
          },
          partners: {
            type: 'array',
            elementsType: 'object'
          },
          comment: {
            type: 'string'
          },
          uploadedDocumentsIds: {
            type: 'array',
            elementsType: 'string'
          }
        };

        this.validFrom = null;
        this.beneficiaries = null;
        this.partners = null; // lista obiektów z zagnieżdżonym businessPartnerem + jakieś atrybuty
        this.comment = null;
        this.uploadedDocumentsIds = null;
      };

      EditBeneficiariesRequestModel.prototype = LsnAbstractModel.prototype;
      return EditBeneficiariesRequestModel;
    }
  ]);
