angular.module('ihestiaWidgets.commonOperations')
  .directive('ihestiaOperationsModalsInjector', [function() {
    return {
      controller: 'ihestiaOperationsModalsInjectorCtrl',
      restrict: 'E',
      scope: true,
      templateUrl: 'ihestia-widgets-templates-app/operations/modalsInjector.tpl.html'
    };
  }])
  .run(['bodyInjectorHelper',
    function(bodyInjectorHelper) {
      bodyInjectorHelper.injectDirective('ihestiaOperationsModalsInjector', 'ihestia-operations-modals-injector');
    }
  ]);