angular.module('salesPath2')
  .factory('DataContainer', ['CONFIG', 'RESOURCES', 'CONSTANTS', 'ApplicationModel',
    function(CONFIG, RESOURCES, CONSTANTS, ApplicationModel) {
      var DataContainer = function() {
        //DANE

        /**
         * wybrane dodatki
         * @type {Object}
         */
        this.selectedAdditions = {};
        /**
         * wybrane rozszerzenia
         * @type {Object}
         */
        this.selectedExtensions = {};
        /**
         * aktualnie wybrana lokalizacja/nieruchomosć
         * CONSTANTS.NO_OBJECT - brak wyboru, CONSTANTS.ALL_OBJECTS - wszystkie
         * @type {String}
         */
        this.selectedLocalization = CONSTANTS.NO_OBJECT;
        /**
         * aktualnie wybrany pojazd
         * CONSTANTS.NO_OBJECT - brak wyboru, CONSTANTS.ALL_OBJECTS - wszystkie
         * @type {String}
         */
        this.selectedVehicle = CONSTANTS.NO_OBJECT;
        /**
         * aktualnie wybrana osoba lub grupa
         * CONSTANTS.NO_OBJECT - brak wyboru, CONSTANTS.ALL_OBJECTS - wszystkie
         * @type {String}
         */
        this.selectedSubject = CONSTANTS.NO_OBJECT;
        /**
         * lista nieruchomości
         * @type {Object.<String, EstateModel>} {clientId: lokalizacja}
         */
        this.localizations = {};
        /**
         * lista pojazdów
         * @type {Object.<String, VehicleModel>} {clientId: pojazd}
         */
        this.vehicles = {};
        /**
         * czy przypadek wczytania wniosku z niewspieranum pojazdem - wówcza w tempVehicle są inicjalne dane i należy wybrac nowy pojazd
         * @type {Boolean}
         */
        this.unsupportedVehicle = false;
        /**
         * tymczasowe dane pojazdu. Wykorzystywae np. przy wznowieniach, aby przekazać pewien inicjalny zakres danych dla pojazdu.
         * @type {Object|null}
         */
        this.tempVehicleData = null;
        /**
         * lista osób
         * @type {Object.<String, PersonModel>} {clientId: osoba}
         */
        this.persons = {};
        /**
         * lista organizacji
         * @type {Object.<String, OrganizationModel>} {clientId: organizacja}
         */
        this.organizations = {};
        /**
         * lista grup
         * @type {Object.<String, GroupModel>} {clientId: grupa}
         */
        this.groups = {};
        /**
         * wspólna pula idków dla lokalizacji i pojazdów
         * @type {Number}
         */
        this.nextObjectId = 1;
        /**
         * wspólna pula idków dla osób, organizacji i grup
         * @type {Number}
         */
        this.nextSubjectId = 1;
        /**
         * clientId ubezpieczającego
         * @type {Number}
         */
        this.insurerId = null;
        /**
         * clientId głównego ubezpieczonego
         * @type {Number}
         */
        this.mainInsuredId = null;
        /**
         * domyślna data rozpoczęcia ochrony
         * @type {XDate}
         */
        this.defaultStartDate = new XDate(RESOURCES.TODAY).addDays(1);
        /**
         * domyślna data zakończenia ochrony
         * @type {XDate}
         */
        this.defaultEndDate = new XDate(RESOURCES.TODAY).addYears(1);
        /**
         * najwczesniejsze dopuszczalna data ochrony dla komunikacji
         * @type {String}
         */
        this.minCommunicationDate = new XDate(RESOURCES.TODAY).addDays(CONFIG.BEHAVIOR.minCommunicationStartDateDays).toString('yyyy-MM-dd');
        /**
         * najwczesniejsze dopuszczalna data ochrony dla majątku
         * @type {String}
         */
        this.minPropertyDate = new XDate(RESOURCES.TODAY).addDays(CONFIG.BEHAVIOR.minPropertyStartDateDays).toString('yyyy-MM-dd');
        this.protectionPeriodCode = null; //okres ubezpieczenia - 30/90/180 - domyslnie pierwszy z brzegu

        /**
         *  obiekt z listą zniżek na polisę
         *  @type {PolicyDiscountsModel[]}
         */
        this.policiesDiscounts = {};
        /**
         * Sumy ubezpieczeń na ryzykach i dodatkach
         * z wariantem
         * suList[typ_obiektu][id_obiektu][kod_ryzyka][wariant] = SU
         * bez wariantu
         * suList[typ_obiektu][id_obiektu][kod_ryzyka] = SU
         * albo dla ognia i kradzieży, bo są grupowane ryzyka:
         * suList[typ_obiektu][id_obiektu][kod_produktu][kod_ryzyka] = SU
         * @type {Object}
         */
        this.suList = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        /**
         * składki "na matrycy". Składki do prezentacji na ekranie matrycy i minimatryc (OGIEN/oczp itd.)
         * @type {Object}
         */
        this.premiumList = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};

        /**
         * składki przed i po zniżce indywidualnej (koszyk2)
         *
         */
        //koszyk2 składka przed zniżką
        this.premBeforeDiscList = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        //koszyk2 składka po zniżce
        this.premAfterDiscList = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        // koszyk surowa składka przed zniżką
        this.premPureBeforeDiscList = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        // koszyk2 surowa składka po zniżce
        this.premPureAfterDiscList = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};

        /**
         * składki taryfowe
         * @type {Object}
         */
        this.tariffPremiumList = angular.copy(this.premiumList);

        /**
         * składki po zniżkach klienckich i zniżkach/odstępstwach
         * @type {Object}
         */
        this.finalPremiumList = angular.copy(this.premiumList);

        /**
         * składki zsumowane za cały okreś trwania ubezpieczenia (np. w dokupieniach)
         * @type {Object}
         */
        this.summedPremiumList = angular.copy(this.premiumList);

        /**
         * czas rozpoczęcia ochrony używamy dla daty ochrony przypadającej na dzień dzisiejszy
         * @type {String} HH:MM
         */
        this.protectionStartTime = RESOURCES.CURRENT_TIME;

        /**
         * daty ochrony dla wariantów OCZP
         * @type {Object}
         */
        this.oczpProtectionDates = {
          'start': null,
          'end': null
        };

        /**
         * daty ochrony dla NNW
         * @type {Object}
         */
        this.nnwProtectionDates = {
          'start': null,
          'end': null
        };

        /**
         * daty ochrony dla mienia - wykorzystywane do inicjalnej prezentacji data oraz w przypadku wspólnych dat na każdej lokalizacji oraz ogniu i kradzieży
         * @type {Object}
         */
        this.propertyProtectionDates = {
          'start': null,
          'end': null
        };

        /**
         * daty ochrony dla komunikacji
         * @type {Object}
         */
        this.communicationProtectionDates = {
          'start': null,
          'end': null
        };

        /**
         * NNW forma bezimienna
         * @type {Object} dane jak z metody dcProductHelper.getDefaultNnwIncognitoData()
         */
        this.nnwIncognito = null;
        /**
         * numer zielonej karty
         * @type {String}
         */
        this.greenCardNumber = null;
        /**
         * lista polis, na które podzielono wniosek
         * @type {PolicyModel[]}
         */
        this.policies = [];
        /**
         * lista dokumentów/załączników do polis
         * @type {Object}
         */
        this.policyDocuments = {};
        /**
         * obiekt wniosku ubezpieczeniowego
         * Wykorzystywany w mapperach i usługach
         * @type {ApplicationModel}
         */
        this.application = new ApplicationModel();
        /**
         * wybrany sposób likwidacji dla ACKOM jeśli jeszcze nie ma pojazdu (self.selectedVehicle = 0)
         * @type {String}
         */
        this.liquidation = null;
        /**
         * dane bonus malus właścicieli i współwłaścicieli pojazdów
         * @type {Object}
         */
        this.bonusMalus = {};
        /**
         * wczytany wniosek wznawianych polis
         * Używany tylko w trybie wznowień
         * @type {ApplicationModel}
         */
        this.renewedApplication = null;
        /**
         * lista ryzyk wznowionych, aktualizowana po każdym wywołaniu usługi taryfikacji
         * Używana tylko w trybie wznowień
         * @type {Object}
         */
        this.renewedList = angular.copy(this.premiumList);

        /**
         * zaznaczenia wariantów ryzyk - ryzyka, które będą wysyłane do usług zapisu wniosku
         * @type {Object}
         */
        this.selectedVariants = {};
        this.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};

        /**
         * wstępne zaznczenie produktow/ryzyk dla nowego wniosku
         * Na użytk np. iKonfiguratora
         * @type {Array}
         */
        this.preselectedVariants = [];
        /**
         * wstępne zaznczenie dodatków dla nowego wniosku
         * Na użytk np. iKonfiguratora
         * @type {Array}
         */
        this.preselectedAdditions = [];

        /**
         * lista ryzyk z poprzednich polis - np. dla dokupień
         * @type {RiskModel[]}
         */
        this.previousRisks = [];

        /**
         * wybrane warianty ryzyk z poprzednich polis (użycie np. w dokupieniach)
         * @type {Object} na pierwszym poziomie obiektu znajduje sie element additions (da dodatków) i elementy reprezentujące poszczególne typy produktów
         * {
         *   additions: {
         *     BAGGAGE: true,
         *     ASSPOJ: {
         *       'I': ['3']
         *     }
         *   },
         *   localization: {
         *     OGIEN: {
         *       'I': ['1'],
         *       'II': ['2'],
         *       'flood': ['1'] //wyjątkowo umieszczamy tu informację o zazanczeniu powodzi - CONSTANTS.RISK_FLOOD
         *     }
         *   }
         *   ...
         * }
         */
        this.previousRisksSelection = {
          additions: {}
        };

        /**
         * przechowuje informacje na temat dozwolonych zmian dotyczących operacji na wniosku (np. dokupien i doubezpieczen)
         * @type {Object|null} getData z ApplicationAllowedChangesModel lub null - brak danych
         */
        this.allowedChanges = null;

        /**
         * dozwolone do wyboru ryzyka i ich warianty
         * Domyślnie wszystko dozwolone. Ustawiane np. w DiD.
         * @type {Object[]} tablica obiektów opisujących dostępność danego produktu/dodatku i jego wariantu.
         * format pojedynczego obiektu:
         * {
         *   product: 'KRA', //kod produkctu (daldodatku = risk)
         *   risk: 'estate', //kod ryzyka (dla dodatku = product)
         *   allowedVariants: ['II'], //wariant (w zależności od ryzyka, może być opcjonalny)
         *   objId: '3', //metaData.clientId obiektu lub podmiotu ubezpieczonego (też może być opcjonalny np dla pakietowego ryzyka bagażu)
         */
        this.allowedRisksVariants = null;

        /**
         * wskazanie na metaData.id ryzyka dla wybranych ryzyk i dodatków (selectedVariants, selectedAdditions)
         * @type {Object[]} tablice obiektów postaci:
         * {
         *     product: 'KRA', //kod produkctu (daldodatku = risk)
         *     risk: 'estate', //kod ryzyka (dla dodatku = prod)
         *     variant: 'II', //wariant (w zależności od ryzyka, może być opcjonalny)
         *     objId: '3', //metaData.clientId obiektu lub podmiotu ubezpieczonego (też może być opcjonalny np dla pakietowego ryzyka bagażu)
         *     riskId: '3', //metaData.id ryzyka z self.application.risks
         *     riskIdpm: 'GEH3434324_2', //comId ryzyka generowany przez BOS - risk.product.compId
         * }
         * Nieokreślony parametr przyjmuje wartość null
         */
        this.selectedRisksRefs = [];

        /**
         * wskazania na ryzyka wybrane na poprzedniej wersji polisy
         * @type {Object[]} tablica obiektów w analogicznym formacie co self.selectedRisksRefs
         */
        this.previouslySelectedRisksRefs = [];

        /**
         * dostępność produktów
         * ustawiane np. przy dokupieniach
         * @type {Object} {kod_produktu: true|false} true, gdy dostępny
         */
        this.allowedProducts = {};

        /**
         * declared on clientAdd page client's roles
         * NOTE by default it's empty, so no action will be triggered on matrix entry
         * @type {string[]} where string is one of CONSTANTS.PERSON_ROLE_...
         */
        this.declaredClientRoles = [];

        /**
         * typ obiektu/produktu do miejsca z informacjami (na jego temat) w self.allowedChanges
         * @type {Object}
         */
        this.objTypeToAllowedContainer = {};
        this.objTypeToAllowedContainer[CONSTANTS.OBJECT_TYPE_ORGANIZATION] = ['subjects', 'organizations'];
        this.objTypeToAllowedContainer[CONSTANTS.PRODUCT_TYPE_PERSON] = ['subjects', 'persons'];
        this.objTypeToAllowedContainer[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = ['objects', 'estates'];
        this.objTypeToAllowedContainer[CONSTANTS.PRODUCT_TYPE_VEHICLE] = ['objects', 'vehicles'];
        this.objTypeToAllowedContainer.policy = ['salesProduct'];

        /**
         * mapa podGrup z allowedChanges do nazw klas (objectName z modelu) obiektów
         * @type {Object}
         */
        this.allowedSubGroupToObjectName = {};
        this.allowedSubGroupToObjectName.persons = 'Person';
        this.allowedSubGroupToObjectName.organizations = 'Organization';
        this.allowedSubGroupToObjectName.vehicles = 'Vehicle';
        this.allowedSubGroupToObjectName.estates = 'Estate';
        this.allowedSubGroupToObjectName.newRisks = 'Risk';
        this.allowedSubGroupToObjectName.existingRisks = 'Risk';

        /**
         * dynamicValues z ryzyk, które mapowane będą na additionalData obiektów
         * @type {Object}
         */
        this.riskDynamicValuesToAdditionalData = {};
        this.riskDynamicValuesToAdditionalData[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {
          userUnderAge: 'isYoungDriver'
        };

        /**
         * dostępne operacje na wniosku/ofercie wg usług
         * UWAGA
         * Aktualnie wspierany tryb wyłacznie wznowień 2.0
         * @type {Object}
         */
        this.availableOperations = {};

        /**
         * wartość wpisana w polu typu input zniżki agencyjnej (koszyk 2)
         * @type {String}
         */
        this.individualDiscountInputValue = '';

        /**
         * klauzule produktowe
         * @type {Object}
         */
         this.productClauses = {};

         this.destinations = {};

      };

      return DataContainer;
    }
  ])
  .provider('dataContainerResource', function() {
    var dcInstance = null;

    this.$get = ['DataContainer', 'lsnDeepExtend',
      function(DataContainer, lsnDeepExtend) {
        var DCBuilder = function() {
          var self = this;
          /**
           * zwraca aktualną instancję dataContainera
           * @return {DataContainer}
           */
          this.getDataContainer = function() {
            if (dcInstance === null) {
              self.initDataContainer();
            }
            return dcInstance;
          };

          /**
           * Usunięcie dataContainera
           * @return {undefined}
           */
          this.clearDataContainer = function() {
            dcInstance = null;
          };

          /**
           * inicjalizuje na nowo dataContainer
           */
          this.initDataContainer = function() {
            if (dcInstance === null) { //jesli datacontainer jeszcze niezainicjalizowany to go tworzymy
              dcInstance = new DataContainer();
            } else { //inicjalizujemy datacontainer na nowo, np. przy zmianie pakietu/apki na inną
              angular.forEach(dcInstance, function(val, key) {
                if (!angular.isFunction(val)) { //czyscimy wszystkie "niefunkcyjne" pola dataContainera
                  delete dcInstance[key];
                }
              });
              lsnDeepExtend(dcInstance, new DataContainer());
            }
          };
        };
        return new DCBuilder();
      }
    ];

  })
  .provider('mainDataContainer', [
    function() {
      this.$get = ['dataContainerResource', function(dataContainerResource) {
        return dataContainerResource.getDataContainer();
      }];
    }
  ]);