angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi generalAgreementsAccess user (uprawnienia do umów generalnych)
   * @return {[type]} [description]
   */
  .factory('gaAccessUserSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var GAAccessUserSvc = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'user';
        this.businessArea = 'generalAgreementsAccess';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new GAAccessUserSvc();
  }])
  ;
