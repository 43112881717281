angular.module('salesPath2')
  .service('addHelper', ['actionHelper', 'RESOURCES', 'mainDataContainer', 'sp2CommonHelper', 'CONSTANTS', 'CONFIG', 'resourceHelper', 'dataContainerHelper',
    function(actionHelper, RESOURCES, mainDataContainer, sp2CommonHelper, CONSTANTS, CONFIG, resourceHelper, dataContainerHelper) {
      var AddHelper = function() {
        var self = this;

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'objectSelected':
            case 'matrixProductSelected':
            case 'personVariantChanged':
            case 'personEdited':
            case 'personAdded':
            case 'personDeleted':
            case 'groupEdited':
            case 'groupAdded':
            case 'fireBurglarySumInsuredChanged':
            case 'localizationDeleted':
            case 'coownersChanged':
              self.deleteUnavailable();
              break;
            default:
          }
        };

        /**
         * metoda zwraca globalną (tzn uzywaną do preznetacji na matrycy głównej) listę dodatków możliwych do zaznaczenia i ewentualne lokalizacje/pojazdy/osoby-grupy
         * wybrane dla danego dodatku jeśli zaznaczone są wszystkie lokalizacje/pojazdy/osoby-grupy
         * @return {object} [description]
         */
        this.getGlobalAvailableAdds = function() {
          var availableAdds = {};
          angular.forEach(mainDataContainer.selectedAdditions, function(selected, addCode) {
            if (self.addIsAvailable(addCode)) {
              availableAdds[addCode] = true;
              var addDef = resourceHelper.getAddDef(addCode);
              if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE || addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
                var selectedObject = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
                if (selectedObject === CONSTANTS.ALL_OBJECTS) //zaznaczone wszystkie lokalizacje/pojazdy
                {
                  var numbers = {};
                  var numersCount = 0;
                  //zbieramy numery pojazdów
                  if (angular.isDefined(addDef.VARIANTS)) {
                    angular.forEach(selected, function(ids) {
                      angular.forEach(ids, function(id) {
                        var numer = dataContainerHelper.getObjectNumber(addDef.TYPE, id); //zamiast idków chcemy numery
                        numbers[numer] = true;
                        numersCount += 1;
                      });
                    });
                  } else {
                    angular.forEach(selected, function(id) {
                      numbers[dataContainerHelper.getObjectNumber(addDef.TYPE, id)] = true;
                      numersCount += 1;
                    });
                  }

                  //teraz mamy numerki w kolejności rosnącej
                  if (numersCount) {
                    availableAdds[addCode] = numbers;
                  }
                }
              }
            } else {
              availableAdds[addCode] = false;
            }
          });
          self._setAllowedChangesToAvailableAdds(availableAdds);
          return availableAdds;
        };

        /**
         * zwraca listę dodatków widocznych dla danego obiektu
         * @param  {[type]} products [description]
         * @param  {[type]} objectId [description]
         * @return {Object.<string, boolen>} kod_dodatku: czy_aktywny(mozliwy do zaznaczenia)
         */
        this.getVisibleAddsForProducts = function(products, objectId) {
          var visibleAdds = {}; //klucz dodatku => true|false (czy aktywny)
          lsnNg.forEach(products, function(prodCode) {
            lsnNg.forEach(RESOURCES.PRODADD, function(addDef) {
              if (visibleAdds[addDef.CODE]) { //jeśli już sprawdzony dodatek i aktywny to pomijamy
                return;
              }
              var visible = self.addisVisibleForObject(addDef, prodCode, resourceHelper.productType[prodCode], objectId);
              if (visible) {
                visibleAdds[addDef.CODE] = self.addIsAvailable(addDef.CODE, null, prodCode, false);
              }
            });
          });
          self._setAllowedChangesToAvailableAdds(visibleAdds);
          return visibleAdds;
        };

        /**
         * weryfikuje i zmienia dostępność dodatków na podstawie informacji o dozwolonych zmianach z dataContainera
         * @param {Object} availableAdds {kod_dodatku: true|false [,...]} wstępnie ustawiony obiekt z dostępnością dodatków
         */
        this._setAllowedChangesToAvailableAdds = function(availableAdds) {
          //np. dla dokupień
          var allowedAdds = dataContainerHelper.getAllowedAdds();
          if (allowedAdds !== null) {
            angular.forEach(allowedAdds, function(addAllowed, addCode) {
              if (angular.isDefined(availableAdds[addCode])) { //weryfikujemy tylko dodatki ktorę przyszły w argumencie metody
                availableAdds[addCode] = !!availableAdds[addCode] && addAllowed;
              }
            });
          }
        };

        /**
         * czy dodatek widoczny dla podanego obiektu
         * @param  {[type]} addDef   [description]
         * @param  {[type]} prodCode [description]
         * @param  {[type]} prodType [description]
         * @param  {[type]} objId    [description]
         * @return {Boolen} true gdy widoczny
         */
        this.addisVisibleForObject = function(addDef, prodCode, prodType, objId) {
          if (addDef.PRODUCTS.indexOf(prodCode) === -1) {
            return false;
          }
          return self._checkAddProductRequirements(addDef, prodCode, objId);
        };

        /**
         * czy dodatek jest aktywny, możliwy do wybrania,
         * jeśli nie podano objectId jest sprawdzane pod kątem widczności na matrycy/aktualnie wybranego obiektu ubezpieczenia
         * @param {string} addCode
         * @param {string|int} objectId opcjonalne id obiektu, jak nie podano to sprawdzane dla stanu matrycy (Uwaga jeśli np. przy sprawdzaniu dostępności dodatku osobowego podanmy tu id lokalizacji, to metoda zadziala niepoprawnie, lepiej wówczas przekazać undefined lub null)
         * @param {string} prodCodeOnly opcjonalny kod produktu. Jeśli ustawiony to sprawdzamy dostępność tylko pod kątem tego produktu
         */
        this.addIsAvailable = function(addCode, objectId, prodCodeOnly, omitAddRequirements) {
          //obsluga singleProduct i zk (troche nieladnie ale jak bedie wiecej przypadkow solok to mozna zrobic automat)
          if (CONFIG.BEHAVIOR.greenCardAsProd && addCode === CONSTANTS.ADD_GREENCARD) {
            return true;
          }
          var addDef = resourceHelper.getAddDef(addCode),
            addProducts = addDef.PRODUCTS,
            isAvailable = false,
            isAllowedProductSelected = null; //czy wybrano prodkut spełniający wanunki dostępności dodaktu
          omitAddRequirements = (angular.isDefined(omitAddRequirements) ? omitAddRequirements : false);
          prodCodeOnly = angular.isString(prodCodeOnly) ? prodCodeOnly : null;
          if ((angular.isUndefined(objectId) || objectId === null) && [CONSTANTS.PRODUCT_TYPE_PACKAGE, CONSTANTS.PRODUCT_TYPE_PERSON].indexOf(addDef.TYPE) === -1) { //odczytujemy aktulane objectId po typie dodatku
            objectId = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
          } else if (!angular.isString(objectId)) {
            objectId = undefined; //dla kompatybilnosci z pozostalymi metodami
          }
          lsnNg.forEach(addProducts, function(productCode) //lista produktów dla których możliwy jest wybór dodatku
          {
            if (prodCodeOnly !== null && prodCodeOnly !== productCode) { //sprawdzamy dla konkretnego typu produktu
              return true;
            }
            //jeśli dodatek sprawdzamy dla konkretnego obiektu i produkt może być powiązany z tą osobą to sprawdzamy czy jest dla niej podpięty
            //w przeciwnym przypadku sprawdzamy bez względu na obiekt
            if (addDef.TYPE === resourceHelper.productType[productCode]) {
              var isProductSelected = dataContainerHelper.isProductSelected(productCode, objectId);
              isAllowedProductSelected = isProductSelected && self._checkAddProductRequirements(addDef, productCode, objectId);
            } else {
              //jeśli to produkt dla lokalizacji lub pojazdu lub osoby-grupy, to sprawdzamy wszystkie po kolei
              var objects = dataContainerHelper.getObjects(resourceHelper.productType[productCode]);
              if (!angular.equals(objects, {})) {
                lsnNg.forEach(objects, function(obj, objectClientId) {
                  var isProductSelected = dataContainerHelper.isProductSelected(productCode, objectClientId);
                  isAllowedProductSelected = isProductSelected && self._checkAddProductRequirements(addDef, productCode, objectClientId);
                  if (isAllowedProductSelected) {
                    return false;
                  }
                  return true; //continue
                });
              } else {
                isAllowedProductSelected = dataContainerHelper.isProductSelected(productCode) && self._checkAddProductRequirements(addDef, productCode, dataContainerHelper.getSelectedObjectId(addDef.TYPE));
              }
            }
            if (isAllowedProductSelected) {
              if (CONFIG.BEHAVIOR.checkShortTermNnw && productCode === CONSTANTS.PRODUCT_NNW && dataContainerHelper.isShortTermNnw()) //dla nnw krótkoterminiowego nie ma dodatków
              {
                return true;
              }
              isAvailable = true;
              return false;
            }
            return true;
          });
          if (isAvailable && !omitAddRequirements && angular.isArray(addDef.REQUIREMENTS)) {
            //sprawdzamy czy dodatkowe wymagania są spełnione
            if (angular.isUndefined(objectId)) {
              objectId = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
            }
            if (objectId === CONSTANTS.NO_OBJECT) //jeszcze nie ma obiektu, wiec nie spełnia wymagań
            {
              isAvailable = false;
            } else if (objectId !== CONSTANTS.ALL_OBJECTS) //dla wszystkich obiektów pokazujemy bez względu na dodatkowe wymagania
            {
              lsnNg.forEach(addDef.REQUIREMENTS, function(requirement) {
                if (!angular.isObject(requirement)) {
                  return;
                }
                if (!self.checkRequirement(addDef.TYPE, requirement, objectId)) {
                  isAvailable = false;
                  return false; //eslint-disable-line consistent-return
                }
              });
            }
          }
          //sprawdzanie dostepnosci dodatku osobowego (np travel w mf)
          if (isAvailable && addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON && angular.isString(objectId) && prodCodeOnly !== 'string') { //to sprawdzanie ma być uchwycone tylko przy deleteUnavailable dla osób stąd ten warunek (slabe :())
            var availablePersons = self.getAvailablePersons();
            if (angular.isUndefined(availablePersons[objectId])) {
              isAvailable = false;
            }
          }
          return isAvailable;
        };

        /**
         * sprawdza spełnienie dodatkowych warunków produktowych na dostępność dodatku
         * @param  {[type]} addDef   [description]
         * @param  {[type]} prodCode [description]
         * @return {Boolean} true gdy dostępny
         */
        this._checkAddProductRequirements = function(addDef, prodCode, objectId) {
          var prodReqs = angular.isObject(addDef.PRODUCTS_REQUIREMENTS) ? addDef.PRODUCTS_REQUIREMENTS : {};
          if (!angular.isArray(prodReqs[prodCode])) {
            return true;
          }
          if (!angular.isString(objectId) || [CONSTANTS.ALL_OBJECTS, CONSTANTS.NO_OBJECT].indexOf(objectId) !== -1) { //jeśli nie wybrano zadnego obiektu to nawet nie sprawdzamy dodatkowych wymagań - mają one zastosowanie tylko do konretnego obiektu
            return false;
          }
          var allowed = true;
          lsnNg.forEach(prodReqs[prodCode], function(req) {
            if (!self.checkRequirement(resourceHelper.productType[prodCode], req, objectId)) {
              allowed = false;
              return false;
            }
            return true; //continue
          });
          return allowed;
        };

        /**
         * zwraca listę osób do których możliwe jest podpięcie dodatku osobowego
         * @return {[type]} [description]
         */
        this.getAvailablePersons = function() {
          var availablePersons = {};
          angular.forEach(mainDataContainer.persons, function(person, id) {
            if (self._isPersonAvailable(person)) {
              availablePersons[id] = person;
            }
          });

          return availablePersons;
        };

        /**
         * czy osoba może mieć podpięty dodatek osobowy
         * @param  {int|string}  personId
         * @return {boolean}
         */
        this._isPersonAvailable = function(person) {
          var personId = person.metaData.get('clientId');
          if (personId === mainDataContainer.mainInsuredId && self.isSelectedAnyProductOnVehicleOrLocalization()) {
            return true;
          }
          //sprawdzamy czy osoba jest zaznaczona w wariancie ns jakims produkcie osobowym
          var personalProductChosen = false;
          lsnNg.forEach(resourceHelper.getProductsForType(CONSTANTS.PRODUCT_TYPE_PERSON), function(prodCode) {
            if (dataContainerHelper.personVariantSelected(person, prodCode)) {
              personalProductChosen = true;
              return false;
            }
            return true; //continue
          });
          if (personalProductChosen) {
            return true;
          }
          //współwłaściciele
          var isAvailable = false;
          lsnNg.forEach([CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE], function(prodType) {
            lsnNg.forEach(dataContainerHelper.getObjects(prodType), function(obj, objId) {
              if (obj.getAdditionalData('coowners').indexOf(personId) !== -1) //jest właścicielem
              {
                lsnNg.forEach(resourceHelper.getProductsForType(prodType), function(productCode) {
                  if (dataContainerHelper.isProductSelected(productCode, objId)) //jest ryzyko dla obiektu
                  {
                    isAvailable = true;
                    return false;
                  }
                  return true; //continue
                });
                if (isAvailable) {
                  return false;
                }
              }
              return true;
            });
            if (isAvailable) {
              return false;
            }
            return true;
          });
          return isAvailable;
        };

        /**
         * zwraca dane ze względu na rodzaj dodatku i aktywne obiekty na matrycy
         * dla powiąznych z osobami wymagany jest objectId
         * UWAGA:
         * addObjectsTab jest tablicą tylko dla dodatków o typie !== package. Dla tego typu jest wartością logiczną.
         * @param  {[type]} code     [description]
         * @param  {[type]} objectId [description]
         * @param  {[type]} variant  [description]
         * @return {[type]}          [description]
         */
        this.getAddData = function(code, objectId, variant) {
          var addDef = resourceHelper.getAddDef(code);
          var addObjectsTab = null; // NOSONAR lista idkow obiektów przypisanych do dodatku
          if (angular.isDefined(addDef.VARIANTS)) {
            addObjectsTab = mainDataContainer.selectedAdditions[code][variant];
          } else {
            addObjectsTab = mainDataContainer.selectedAdditions[code];
          }

          if (angular.isUndefined(objectId)) //jeśli nie podany id obiektu to bierzemy wybrany pojazd/lokalizację
          {
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
              objectId = mainDataContainer.selectedLocalization;
            } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
              objectId = mainDataContainer.selectedVehicle;
            } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
              objectId = mainDataContainer.selectedSubject;
            } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) {
              sp2CommonHelper.throwException('undefined objectId'); //dla dodatków powiązanych z osobami trzeba przekazać id osoby, bo nie ma czegoś takiego jak aktualnie wybrana osoba
            }
          }

          return {
            addDef: addDef,
            addObjectsTab: addObjectsTab,
            objectId: objectId
          };
        };

        /**
         * sprawdza czy dodatek jest możliwy do wybrania
         * @param {string} addCode
         * @param {string} variantCode
         * @param {string|int} objectId jeśli nie podany to sprawdzane dla wybranego elementu
         */
        this.addVariantIsAvailable = function(addCode, variantCode, objectId) {
          var variantAvailable = false;
          var variantDef = resourceHelper.getAddVariantDef(addCode, variantCode);
          if (angular.isDefined(variantDef.PRODUCTS)) //wariant może być tylko na niektórych z poduktów dostępnych dla dodatku
          {
            lsnNg.forEach(variantDef.PRODUCTS, function(productCode) {
              if (dataContainerHelper.isProductSelected(productCode, objectId)) {
                variantAvailable = true;
                return false;
              }
              return true;
            });
          } else {
            variantAvailable = true;
          }
          if (variantAvailable && angular.isArray(variantDef.REQUIREMENTS)) {
            lsnNg.forEach(variantDef.REQUIREMENTS, function(requirement) {
              if (!self.checkRequirement(resourceHelper.getAddDef(addCode).TYPE, requirement, objectId)) {
                variantAvailable = false;
                return false;
              }
              return true;
            });
          }
          return variantAvailable;
        };

        /**
         * sprawdza spełnienie wymagania dostępności dla dodatku lub wariantu dodatku
         * @param  {String} productType typ produktu/obiektu dla dodatku ubezpieczenia
         * @param  {Object} requirement definicja wymagania
         * @param  {String} objectId id obiektu powiązanego np. lokalizacja, pojazd
         * @return {Boolean}
         */
        this.checkRequirement = function(productType, requirement, objectId) {
          if (productType === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            return false;
          }
          var res = false;
          switch (requirement.type) {
            case 'attributeValue':
              res = self._checkReqAttributeValue(requirement, dataContainerHelper.getObject(productType, objectId));
              break;
            case 'riskSumInsured':
              res = self._checkReqRiskSumInsured(productType, requirement, objectId);
              break;
            case 'objectFieldValue':
              res = self._checkReqObjectFieldValue(requirement, dataContainerHelper.getObject(productType, objectId));
              break;
            default:
              sp2CommonHelper.throwException('Nieobsługiwany typ wymagania dla dodatku: {0}'.format(requirement.type));
          }
          return res;
        };
        /**
         * sprawdza dodatkowy warunek na dostepnosc wariantu dodatku
         * @param  {String} productType typ obiektu ubezpieczenia
         * @param  {Object} requirement warunek (jego definicja)
         * @return {Boolean} true gdy spelniony
         */
        this._checkReqRiskSumInsured = function(productType, requirement, objectId) {
          var available = true;
          if (angular.isDefined(objectId) && objectId !== null && objectId !== CONSTANTS.ALL_OBJECTS) {
            if (!dataContainerHelper.isProductSelected(requirement.product, objectId)) {
              available = false;
            } else {
              available = false;
              var checkRisks = requirement.risks;
              lsnNg.forEach(checkRisks, function(riskCode) {
                var sumInsured = dataContainerHelper.getSu(productType, objectId, requirement.product, riskCode);
                if (sumInsured !== null && sumInsured > 0) {
                  available = true;
                  return false;
                }
                return true;
              });
            }
          }
          return available;
        };

        /**
         * Odznaczenie dodatku
         * @param  {string} code     [description]
         * @param  {int} objectId    [description]
         * @param  {string} variant [description]
         * @return {boolean} true jesli usunieto
         */
        this.deleteAdd = function(code, objectId, variant) {
          var addDef = resourceHelper.getAddDef(code),
            deleted = false;
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isDefined(addDef.VARIANTS)) {
              if (mainDataContainer.selectedAdditions[code][variant] !== false) {
                mainDataContainer.selectedAdditions[code][variant] = false;
                deleted = true;
                actionHelper.runAction('addDeleted', code, objectId, variant);
              }
            } else {
              if (mainDataContainer.selectedAdditions[code] !== false) {
                mainDataContainer.selectedAdditions[code] = false;
                deleted = true;
                actionHelper.runAction('addDeleted', code, objectId, variant);
              }
            }
          } else {
            var currentData = self.getAddData(code, objectId, variant);
            angular.forEach(currentData.addObjectsTab, function(id, k) { //eslint-disable-line consistent-return
              if (parseInt(id, 10) === parseInt(currentData.objectId, 10)) //usuwamy jeśli znaleziono
              {
                deleted = true;
                actionHelper.runAction('addDeleted', code, objectId, variant);
                currentData.addObjectsTab.splice(k, 1);
                return false;
              }
            });
          }
          if (deleted) {
            //usuwamy zalezne dodatki
            angular.forEach(RESOURCES.PRODADD, function(prod) {
              if (angular.isDefined(prod.VARIANTS)) {
                angular.forEach(prod.VARIANTS, function(prodVariant) {
                  if (angular.isDefined(prodVariant.DEPENDENCY)) {
                    angular.forEach(prodVariant.DEPENDENCY, function(dependentAdd) {
                      if (dependentAdd[0] === code && dependentAdd[1] === variant && self.isAddSelected(prod.CODE, objectId, prodVariant.CODE)) {
                        self.deleteAdd(prod.CODE, objectId, prodVariant.CODE);
                      }
                    });
                  }
                });
              }
            });
          }
          return deleted;
        };

        /**
         * dodaje dodatek do zaznaczonych
         * @param {string} code
         * @param {string|int} objectId
         * @param {string} variant
         * @param {boolean} noExtraActions (domyslnie false) czy blokować wykonywanie dodatkowych akcji przy dodawaniu dodatku (np. otwarcie popupa zielonej karty, do wykorzystanie w klasach potomnych)
         */
        this.addAdd = function(code, objectId, variant, noExtraActions) {
          noExtraActions = (typeof noExtraActions === 'boolean') ? noExtraActions : false;
          var addDef = resourceHelper.getAddDef(code);
          if (CONFIG.BEHAVIOR.addsWithOneVariant.indexOf(code) !== -1) //there can be only one
          {
            angular.forEach(addDef.VARIANTS, function(addVariant) {
              self.deleteAdd(code, objectId, addVariant.CODE);
            });
          } else if (angular.isDefined(CONFIG.BEHAVIOR.addsVariantsConflict) && angular.isDefined(CONFIG.BEHAVIOR.addsVariantsConflict[code]) && CONFIG.BEHAVIOR.addsVariantsConflict[code].indexOf(variant) !== -1) {
            //warianty które nie mogą wystąpić jednocześnie
            angular.forEach(CONFIG.BEHAVIOR.addsVariantsConflict[code], function(conflictedVariant) {
              if (conflictedVariant !== variant) {
                //próbujemy go usunąć, w razie gdyby byl zaznaczony
                self.deleteAdd(code, objectId, conflictedVariant);
              }
            });
          }

          var objectFound = false;
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isDefined(addDef.VARIANTS)) {
              if (mainDataContainer.selectedAdditions[code][variant] === true) {
                objectFound = true;
              } else {
                mainDataContainer.selectedAdditions[code][variant] = true;
                actionHelper.runAction('addAdded', code, objectId, variant);
              }
            } else {
              if (mainDataContainer.selectedAdditions[code] === true) {
                objectFound = true;
              } else {
                mainDataContainer.selectedAdditions[code] = true;
                actionHelper.runAction('addAdded', code, objectId, variant);
              }
            }
          } else {
            var currentData = self.getAddData(code, objectId, variant);
            angular.forEach(currentData.addObjectsTab, function(id) { //eslint-disable-line consistent-return
              if (id === currentData.objectId) {
                objectFound = true;
                return false;
              }
            });
            if (!objectFound) //dodajemy tylko jak jeszcze go nie ma
            {
              currentData.addObjectsTab.push(currentData.objectId);
              actionHelper.runAction('addAdded', code, objectId, variant);
            }
          }
          //zaznaczamy zalezne dodatki
          if (angular.isDefined(addDef.VARIANTS)) {
            angular.forEach(addDef.VARIANTS, function(resVariant) {
              if (resVariant.CODE === variant && angular.isDefined(resVariant.DEPENDENCY)) {
                angular.forEach(resVariant.DEPENDENCY, function(dependentAdd) {
                  if (!self.isAddSelected(dependentAdd[0], objectId, dependentAdd[1])) {
                    self.addAdd(dependentAdd[0], objectId, dependentAdd[1]);
                  }
                });
              }
            });
          }
        };

        /**
         * zwraca nazwe dodatku
         * @param  {String} code  kod dodatku
         * @param  {String} field pole z nazwa dodatku (z definicji)
         * @return {String}
         */
        this.getAdditionName = function(code, field) {
          if (!angular.isString(field)) {
            field = 'NAME';
          }
          var addDef = resourceHelper.getAddDef(code),
            name = addDef.NAME;
          if (angular.isDefined(addDef[field])) {
            name = addDef[field];
          }
          return name;
        };

        /**
         * usun niedostepne dodatki
         * @return {[type]} [description]
         */
        this.deleteUnavailable = function() {
          var anyDeleted = false;
          //kopiujemy bo w czasie iteracji usuwwamy elementy z listy
          var selectedAdditions = angular.copy(mainDataContainer.selectedAdditions);
          lsnNg.forEach(selectedAdditions, function(selected, addCode) {
            var addDef = resourceHelper.getAddDef(addCode);
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
              if (angular.isUndefined(addDef.VARIANTS)) //dodatek bez wariantów
              {
                if (selected === true && !self.addIsAvailable(addCode)) {
                  anyDeleted = self.deleteAdd(addCode) || anyDeleted;
                }
              } else {
                lsnNg.forEach(selected, function(isSelected, variantCode) {
                  if (isSelected && (!self.addIsAvailable(addCode) || !self.addVariantIsAvailable(addCode, variantCode))) {
                    anyDeleted = self.deleteAdd(addCode, null, variantCode) || anyDeleted;
                  }
                });
              }
            } else if (angular.isUndefined(addDef.VARIANTS)) //dodatek bez wariantów
            {
              lsnNg.forEach(selected, function(objectId) {
                if (!self.addIsAvailable(addCode, objectId)) {
                  anyDeleted = self.deleteAdd(addCode, objectId) || anyDeleted;
                }
              });
            } else //dodatek z wariantami
            {
              lsnNg.forEach(selected, function(objectsIds, variantCode) {
                lsnNg.forEach(objectsIds, function(objectId) {
                  if (!self.addIsAvailable(addCode, objectId) || !self.addVariantIsAvailable(addCode, variantCode, objectId)) {
                    anyDeleted = self.deleteAdd(addCode, objectId, variantCode) || anyDeleted;
                  }
                });
              });
            }
          });
          if (anyDeleted) {
            actionHelper.runAction('saveApplication');
          }
        };

        /**
         * sprawdzanie czy wymaganie dla wartości atrybutu jest spełnione
         * @param  {object}  requirement
         * @param  {object}  object obiekt związany z dodatkiem, np. lokalizacja, pojazd
         * @return {Boolean} true gdy warunek spełniony
         */
        this._checkReqAttributeValue = function(requirement, object) {
          if (!angular.isObject(object) || object === null) {
            return false;
          }
          var checkedAttribute = _.get(object, requirement.attribute);
          var requirementFulfilled = false;
          if (_.isArray(requirement.values)) {
            requirementFulfilled = _.includes(requirement.values, checkedAttribute);
          } else if (checkedAttribute === requirement.value) {
            requirementFulfilled = true;
          }
          return requirementFulfilled;
        };

        /**
         * sprawdzanie czy wymaganie dla wartości pola na obiekcie ubezpieczenia jest spełnione
         * @param  {object}  requirement
         * @param  {object}  object obiekt związany z dodatkiem, np. lokalizacja, pojazd
         * @return {Boolean} true gdy warunek spełniony
         */
        this._checkReqObjectFieldValue = function(requirement, object) {
          if (!angular.isObject(object)) {
            return false;
          }
          if (angular.isUndefined(object[requirement.field])) {
            return false;
          }
          var objValue = object.get(requirement.field);
          return requirement.values.indexOf(objValue) !== -1;
        };

        /**
         * czy wybrany jakikolwiek produkt komunikacyjny lub mieniowy
         * @return {Boolean} [description]
         */
        this.isSelectedAnyProductOnVehicleOrLocalization = function() {
          var isProductSelected = dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_LOCALIZATION);

          if (!isProductSelected) {
            isProductSelected = dataContainerHelper.isSelectedAnyProductOn(CONSTANTS.PRODUCT_TYPE_VEHICLE);
          }

          return isProductSelected;
        };

        /**
         * czy dodaetk jest wybrany
         * @param  {String}  code kod dodatku
         * @param  {[type]}  objectId clientId obiektu
         * @param  {[type]}  variant wairnt (opcja)
         * @return {Boolean} true gdy zaznaczony
         */
        this.isAddSelected = function(code, objectId, variant) {
          var addDef = resourceHelper.getAddDef(code);
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (typeof addDef.VARIANTS !== 'undefined') {
              return mainDataContainer.selectedAdditions[code][variant];
            } else {
              return mainDataContainer.selectedAdditions[code];
            }
          }

          var currentData = self.getAddData(code, objectId, variant);
          if (!angular.isArray(currentData.addObjectsTab) || currentData.addObjectsTab.length === 0) //nie ma niczego/nikogo z danym dodatkiem
          {
            return false;
          }

          if (currentData.objectId === CONSTANTS.ALL_OBJECTS) //wszystkie pojazdy/lokalizacje i wiemy że co najmniej jeden element przypisany do dodatku
          {
            return true;
          } else {
            var objectFound = false;
            lsnNg.forEach(currentData.addObjectsTab, function(id) {
              if (parseInt(id, 10) === parseInt(currentData.objectId, 10)) {
                objectFound = true;
                return false;
              }
              return true;
            });
            return objectFound;
          }
        };
      };

      return new AddHelper();
    }
  ])
  .run(['CONFIG', 'addHelper', 'actionHelper',
    function(CONFIG, addHelper, actionHelper) {
      actionHelper.registerHelper('addHelper', addHelper);
    }
  ]);
