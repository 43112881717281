angular.module('ihestiaCommonDirectives')
  .controller('tagListCtrl', ['$scope', function($scope){

      /**
       * Usuwamy keyword
       * @param  {string} keyword [description]
       */
      $scope.removeKeyword = function(keyword) {
        $scope.model.splice($scope.model.indexOf(keyword), 1);
      };

  }]);