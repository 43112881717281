angular.module('ihestiaWidgets.eua.documentStatusDetails')
  .controller('euaDocumentStatusDetailsModalCtrl', ['$scope',
    function($scope) {

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = lsnNg.noop;

    }]);