/**
 * Walidator - w modelu musi być obiekt
 * jeśli pole nie jest obiektem, ale jest puste, to też jest ok
 */
angular.module('lsnBase.validators')
  .directive('valueIsObject', function() {
    return {
      require: 'ngModel',
      link: function(scope, elem, attrs, modelCtrl) {
        scope.$watch(attrs.ngModel, function(model) { // obserwujemy model, a nie wartość z inputa (dla autocompleterów itp.)
          var isValid = angular.isObject(model) || !model; // obiekt lub pusta wartość => ok
          modelCtrl.$setValidity('valueIsObject', isValid);
        }, true);
      }
    };
  });