angular.module('salesPath2')
  .factory('FromRestSimpleRiskModel', [
    function ()
    {

      //#pragma mark SimpleRisk
      /**
       * proste ryzyko na potrzeby ekranu podsumowania
       * @param {(object|null)} data
       * @returns {undefined}
       */
      var FromRestSimpleRiskModel = function (data)
      {
        data = data ||
        {};
        this.code = data.code || '';
        this.variant = data.variant || '';
        this.premium = data.premium || 0;
        this.salesPremium = data.salesPremium || 0;
        this.objectId = data.objectId || null;
        this.objectType = data.objectType || null;
        this.discountRisePercentage = data.discountRisePercentage || null;
        this.attributes = {};
      };

      return FromRestSimpleRiskModel;
    }
  ]);