/**
 * Mapper komunikatów z wniosku
 */
angular.module('salesPath2').service('fromRestMessageMapper', ['actionHelper',
  function(actionHelper) {
    var Mapper = function() {

      /**
       * iteracja po message, wywolanie akcji dla rejestracji komunikatów
       * format przekazywanych komunikatów do plainObject z polami takimi jak w MessageModel +
       * attributes: {
       *   objects: tablica stringów, opisujące jakich obiektów tyczy się komunikat
       * }
       * message: to co MeaageModel.text (text jest nullowany)
       * @param {MessageModel[]} komunikaty
       */
      this.mapMessages = function(messagesToMap) {
        var messages = [],
          message; // object

        if (angular.isUndefined(messagesToMap) || messagesToMap === null) {
          messagesToMap = [];
        }

        for (var i = 0; i < messagesToMap.length; i += 1) {
          message = messagesToMap[i].getData();
          message.message = message.text;
          message.object = (message.object === null ? '' : message.object);
          // little correction for Price-Point messages
          message.object = message.object.replace('Estate[', 'Estates[');
          message.code = message.code || '';
          message.attributes = {
            objects: message.object.replace(/\s/g, '').split(',')
          };
          message.text = null;
          // (deea58ec Piotr Szpręga) do usuniecie po wdrozeniu zmienione tymczasowo na potrzeby wdrozenia
          if (message.code === '200144') {
            message.message = 'Dla wybranej konfiguracji ryzyk zastosuj zgodę telefoniczną na odstępstwo od pełnomocnictw. Jako podstawę zastosowania odstępstwa wpisz: iHestia.';
          }
          messages.push(message);
        }
        actionHelper.runAction('updateMessages', messages);
      };
    };

    return new Mapper();
  }]);
