angular.module('salesPath2')
  .controller('menuMessageBoxCtrl', ['$scope', 'messagesHelper',
    function ($scope, messagesHelper)
    {
      // -- start --
      /**
       * komunikaty do wyświetlenia
       * @type {Array}
       */
      $scope.messagesHelper = messagesHelper;

      $scope.toggleMessageContainer = function ()
      {
        if (!$scope.appVariables.messageContainerVisible)
        {
          messagesHelper.showMessageContainer();
        }
      };

      // --end --
    }
  ]);