/**
 * Provider stanów dla programu
 */
angular.module('ihestiaWidgets.pza')
  .provider('ihestiaPzaStateConfig', function() {

    var ihestiaPzaStateConfigProvider = this;

    /**
     * Resolve dla edycji/podglądu warunku
     * @type {Object}
     */
    var conditionDefinitionResolve = {
      registrationTypes: ['ihestiaPzaDictionaryHelper', function(ihestiaPzaDictionaryHelper) {
        return ihestiaPzaDictionaryHelper.getVehicleRegistrationTypes();
      }],
      standardDicts: ['ihestiaPzaDictionaryHelper', function(ihestiaPzaDictionaryHelper) {
        return ihestiaPzaDictionaryHelper.getStandardDictItems([
          'CONDITION_STATUS',
          'POLICY_TYPE',
          'RISK_TYPE',
          'PACKAGE',
          'DISCOUNT',
          'CUSTOMER_TYPE'
        ]);
      }],
      definitionData: ['ihestiaPzaConditionSvc', '$stateParams', function(ihestiaPzaConditionSvc, $stateParams) {
        if ($stateParams.definitionId) {
          return ihestiaPzaConditionSvc.get($stateParams.definitionId);
        } else {
          return null;
        }
      }]
    };

    /**
     * Stany z aplikacji, które nie występują w widgetach
     * np. zarządzanie programem
     * @type {Object}
     */
    this.virtualStates = {};

    /**
     * Common resolves
     * @type {Object}
     */
    this.resolves = {
      standardDicts: ['ihestiaPzaDictionaryHelper', function(ihestiaPzaDictionaryHelper) {
        return ihestiaPzaDictionaryHelper.getStandardDictItems([
          'PROGRAM_STATUS',
          'SEGMENT'
        ]);
      }],
      definitionData: ['ihestiaPzaProgramSvc', '$stateParams', function(ihestiaPzaProgramSvc, $stateParams) {
        if ($stateParams.definitionId) {
          return ihestiaPzaProgramSvc.get($stateParams.definitionId);
        } else {
          return null;
        }
      }]
    };


    /**
     * Stany programu
     * @type {Object}
     */
    this.states = {
      programCatalog: {
        name: 'programCatalog',
        config: {
          url: 'programCatalog/',
          views: {
            content: {
              controller: 'ihestiaPzaProgramCatalogCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/program/catalog/programCatalog.tpl.html',
              resolve: {
                standardDicts: ['ihestiaPzaDictionaryHelper', function(ihestiaPzaDictionaryHelper) {
                  return ihestiaPzaDictionaryHelper.getStandardDictItems([
                    'PROGRAM_STATUS'
                  ]);
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.programCatalog', {componentCode:'Pza'}],
            disableHideBlockUi: true
          }
        }
      },
      programDefinition: {
        name: 'main.programDefinition',
        config: {
          url: 'programCatalog/definition/{definitionId}',
          views: {
            content: {
              controller: 'ihestiaPzaProgramDefinitionCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/program/definition/programDefinition.tpl.html',
              resolve: {
                standardDicts: ihestiaPzaStateConfigProvider.resolves.standardDicts,
                definitionData: ihestiaPzaStateConfigProvider.resolves.definitionData,
                previewMode: [function() {
                  return false;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.programDefinition', {componentCode:'Pza'}]
          }
        }
      },
      programDefinitionPreview: {
        name: 'main.programDefinitionPreview',
        config: {
          url: 'programCatalog/definition/preview/{definitionId}',
          views: {
            content: {
              controller: 'ihestiaPzaProgramDefinitionCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/program/definition/programDefinition.tpl.html',
              resolve: {
                standardDicts: ihestiaPzaStateConfigProvider.resolves.standardDicts,
                definitionData: ihestiaPzaStateConfigProvider.resolves.definitionData,
                previewMode: [function() {
                  return true;
                }]
              }
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.programDefinition', {componentCode:'Pza'}],
            permission: 'programPreview'
          }
        }
      },
      conditionDefinition: {
        name: 'main.conditionDefinition',
        config: {
          url: 'conditionCatalog/definition/{definitionId}',
          views: {
            content: {
              controller: 'ihestiaPzaConditionDefinitionCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/condition/definition/conditionDefinition.tpl.html',
              resolve: angular.extend({
                previewMode: [function() {
                  return false;
                }],
                copyMode: [function() {
                  return false;
                }]
              }, conditionDefinitionResolve)
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.conditionDefinition', {componentCode:'Pza'}],
            disableHideBlockUi: true,
            permission: 'conditionEdit'
          }
        }
      },
      conditionDefinitionPreview: {
        name: 'main.conditionDefinitionPreview',
        config: {
          url: 'conditionCatalog/definitionPreview/{definitionId}',
          views: {
            content: {
              controller: 'ihestiaPzaConditionDefinitionCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/condition/definition/conditionDefinition.tpl.html',
              resolve: angular.extend({
                previewMode: [function() {
                  return true;
                }],
                copyMode: [function() {
                  return false;
                }]
              }, conditionDefinitionResolve)
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.conditionDefinition', {componentCode:'Pza'}],
            disableHideBlockUi: true
          }
        }
      },
      conditionDefinitionCopy: {
        name: 'main.conditionDefinitionCopy',
        config: {
          url: 'conditionCatalog/definitionCopy/{definitionId}',
          views: {
            content: {
              controller: 'ihestiaPzaConditionDefinitionCtrl',
              templateUrl: 'ihestia-widgets-templates-app/pza/condition/definition/conditionDefinition.tpl.html',
              resolve: angular.extend({
                previewMode: [function() {
                  return false;
                }],
                copyMode: [function() {
                  return true;
                }]
              }, conditionDefinitionResolve)
            }
          },
          data: {
            pageTitle: null,
            pageTitleLabel: ['pageTitle.conditionCopy', {componentCode:'Pza'}],
            disableHideBlockUi: true,
            permission: 'conditionEdit'
          }
        }
      }
    };

    /**
     * Ustawiamy stan ui-routera
     * @param {object} $stateProvider z ui-routera
     * @param {Sring} stateCode      kod stanu (np. programCatalog), nie mylić z nazwą stanu w $stateProvider
     * @param {String} stateName      nazwa stanu dla $stateProvider
     * @param {object|udefined} config         rozszerzenie konfiguracji, jesli potrzeba
     *
     * @return {ProgramConfigProvider} self
     */
    this.setState = function($stateProvider, stateCode, stateName, config) {
      if (!angular.isObject(config) || config === null) {
        config = {};
      }
      // zmieniamy definicję u siebie, dzięki czemu potem będziemy mogli prawidłowo wykrywać stany
      ihestiaPzaStateConfigProvider.states[stateCode].name = stateName;
      angular.extend(ihestiaPzaStateConfigProvider.states[stateCode].config, config);

      // ustawiamy stan w ui-routerze
      $stateProvider.state(stateName, ihestiaPzaStateConfigProvider.states[stateCode].config);

      return ihestiaPzaStateConfigProvider;
    };

    /**
     * Ustawiamy nazwy obcych stanów
     * @param {String} code      nazwa stanu dla widgetów
     * @param {String} stateName nazwa stanu z ui-routera
     */
    this.setVirtualStateName = function(code, stateName) {
      ihestiaPzaStateConfigProvider.virtualStates[code] = stateName;
    };


    /**
     * injector -> programConfig
     * @return {ProgramConfig}
     */
    this.$get = [function() {
      var StateConfig = function() {

        /**
         * Pobieramy nazwę stanu dla zadanego kodu
         * @param  {String} stateCode kod stanu (nie ui-router)
         * @return {String}           nazwa stanu z ui-routera
         */
        this.getStateNameForCode = function(stateCode) {
          return ihestiaPzaStateConfigProvider.states[stateCode].name;
        };

        /**
         * Pobranie nazwy obcego stanu
         * @param  {String} stateCode kod "widgetowy"
         * @return {String}           kod ui-routera
         */
        this.getVirtualStateName = function(stateCode) {
          return ihestiaPzaStateConfigProvider.virtualStates[stateCode];
        };
      };

      return new StateConfig();
    }];

  });