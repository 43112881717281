angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('IHestiaRestServiceAbstractDef', ['IHestiaRestServiceAbstractDefMethods',
   function(IHestiaRestServiceAbstractDefMethods) {

    /**
     * Ustawiamy instance dla poszczególnych wersji serwisu
     * @param {Object} apiContructors {v1: Constructor}
     */
    IHestiaRestServiceAbstractDefMethods.setApiConstructors = function(apiContructors) {
      var apiVersions = {};
      angular.forEach(apiContructors, function(apiContructor, apiVersion){
        var Construct = function(){
          IHestiaRestServiceAbstractDefMethods.apply(this);
          apiContructor.apply(this);
        };
        apiVersions[apiVersion] = new Construct();
      });
      apiVersions.v1.apiVersions = apiVersions;
      return apiVersions.v1;
    };

    return IHestiaRestServiceAbstractDefMethods;
  }]);