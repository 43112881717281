angular.module('salesPath2')
  .controller('agentClausesModalCtrl', ['$scope', 'actionHelper', 'summaryPageHelper', 'dataContainerHelper', 'AgentClausesModalHelperClass', 'ClausesModelConstants', 'applicationHelper',
    function($scope, actionHelper, summaryPageHelper, dataContainerHelper, AgentClausesModalHelperClass, ClausesModelConstants, applicationHelper) {
      $scope.agentClausesModalHelper = new AgentClausesModalHelperClass();

      $scope.modalSettings = summaryPageHelper.tplData.modalSettings.agentClauses;

      $scope.insurer = null;

      $scope.tplData = null;

      $scope.tarrificationInProgress = false;

      $scope.actionMethods = [
        'policiesReceived'
      ];

      $scope.init = function() {
        actionHelper.registerHelper('agentClausesModalHelper', $scope.agentClausesModalHelper, $scope.actionMethods);
        $scope.insurer = dataContainerHelper.getInsurer();
        $scope.agentClausesModalHelper.init();
        $scope.tplData = $scope.agentClausesModalHelper.tplData;
        $scope.agentClausesModalHelper.modalSettings = $scope.modalSettings;
        $scope.agentClausesModalHelper.tarrificationInProgress = $scope.tarrificationInProgress;
        $scope.clauseChange();
        $scope.fireListeners();
      };

      $scope.clauseChange = function() {
        angular.forEach($scope.tplData.clauses, function(clause) {
          if ($scope.insurer.clauses[clause.code] !== ClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT && $scope.insurer.clauses[clause.code] !== null) {
            $scope.insurer.clauses[clause.code] = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          } else {
            $scope.insurer.clauses[clause.code] = ClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT;
          }
        });
        $scope.checkAllClausesSelected();
      };

      $scope.checkAllClausesSelected = function() {

        lsnNg.forEach($scope.tplData.clauses, function(clause) {
          if($scope.insurer.clauses[clause.code] === ClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT) {
            $scope.modalSettings.okBtnDisabled = true;
            return false;
          }
          $scope.modalSettings.okBtnDisabled = false;
          return true;
        });
      };

      $scope.fireListeners = function() {
        // ok na modalu
        $scope.$on('iHestiaCommonModalOkBtnClicked.agentClausesModal', function($event) {
          $event.preventDefault();
          $scope.tarrificationInProgress = true;
          $scope.modalSettings.okBtnDisabled = true;
          $scope.modalSettings.cancelBtnDisabled = true;
          applicationHelper.saveApplication();
        });

        $scope.$on('$destroy', function() {
          actionHelper.unregisterHelper('agentClausesModalHelper');
        });
      };

      $scope.init();
    }
  ]);