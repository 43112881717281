angular.module('salesPath2.params', [])
  .provider('sp2Params', [
    function() {
      /**
       * domyslne parametry konfiguracji ścieżki sprzedaży
       * @type {Object}
       */
      var params = {
        //meta informacje
        METADATA: {
          versionDate: '19000101', //data obowiązywania danej wersji konfiguracji (YYYYMMDD)
          applicationSymbol: null //symbol dla wniosku ubezpieczeniowego
        },
        //aktywne moduły ścieżki sprzedaży
        MODULES: {
          matrix: true, //matryca główna
          fireBurglary: false, //ubezpieczenia mieniowe, ekran ognia i ekran kradzieży
          oczp: false, //ekran OC w życiu prywatnym
          nnw: false, //ekran NNW (osobowe)
          communication: true, //ubezpieczenia komunikacyjne i ich ekran
          tariffFactors: true, //sekcja czynników taryfikacyjnych
          extensions: false, //obsługa rozszerzeń
          additions: false //obsługa dodatków
        },
        MATRIX: { //ustawienia dla matrycy głównej (gdy MODULES.matrix === true)
          header: { //nagłówek
            enabled: false //czy włączony
          }
        },
        PAGE_CONFIG: { //te ustawienia lądują do factory PAGE_CONFIG !!
          MATRIX: { //ustawienia dla matrycy głównej (gdy MODULES.matrix === true)
            clickableProductIcon: true, //czy ikona produktu na matrycy ma być klikalna - prowadzić do strony produktu
            header: { //nagłówek
              enabled: false, //czy włączony
              backButton: false, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_MATRIX_TRAVEL',
              protectionDates: { //daty ochrony
                enabled: false,
                ref: 'propertyProtectionDates', //nazwa pola z dataContainera, pod którym zapisane są daty ochrony dla matrycy głównej
                periodType: 'days' //wybór okresu ubezpieczenia: null: brak, days: opcje z dniami, travel: opcje dla podróży
              }
            }
          },
          FIRE: {
            header: { //nagłówek
              enabled: true, //czy włączony
              backButton: true, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_FIRE',
              protectionDates: { //daty ochrony
                enabled: true,
                ref: 'propertyProtectionDates' //nazwa pola z dataContainera, pod którym zapisane są daty ochrony dla matrycy głównej
              }
            }
          },
          BURGLARY: {
            header: { //nagłówek
              enabled: true, //czy włączony
              backButton: true, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_BURGLARY',
              protectionDates: { //daty ochrony
                enabled: true,
                ref: 'propertyProtectionDates' //nazwa pola z dataContainera, pod którym zapisane są daty ochrony dla matrycy głównej
              }
            }
          },
          COMMUNICATION: {
            header: { //nagłówek
              enabled: true, //czy włączony
              backButton: true, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_COMMUNICATION',
              protectionDates: { //daty ochrony
                enabled: true,
                ref: 'communicationProtectionDates' //nazwa pola z dataContainera, pod którym zapisane są daty ochrony dla matrycy głównej
              }
            }
          },
          OCZP: {
            header: { //nagłówek
              enabled: true, //czy włączony
              backButton: true, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_OCZP',
              protectionDates: { //daty ochrony
                enabled: true
                // ref: 'propertyProtectionDates' //ustawiane w samej aplikacji - zalezne od innych ustawien
              }
            }
          },
          NNW: {
            header: { //nagłówek
              enabled: true, //czy włączony
              backButton: true, //czy wyswietlic przycisk "wstecz"
              STYLE: 'PAGE_HEADER_NNW',
              protectionDates: { //daty ochrony
                enabled: true
                // ref: 'nnwProtectionDates' //ustawiane w samej aplikacji - zalezne od innych ustawien
              }
            }
          }
        },
        BEHAVIOR: {
          //zachowanie sprzedażówki
          isSingleProduct: false, //obsługa sprzedazowek typu solo, jeden produkt
          multipleLocalizations: false, //możliwość dodania wielu lokalizacji
          multipleVehicles: false, //możliwość dodania wielu pojazdów
          personGroups: false, //możliwość dodania wielu osob i grup osób z odrębną matrycą dla każdej z osób/grup
          oneVariantForSelectedProducts: false, //czy mozliwe jest zanzaczenie tylko jednego wspolnego wariantu dla wszystkich produktow zwiazanych z konkretnym obiektem-domem/pojazdem/osobą-grupą (tyczy sie tylko produktow typu localization, vehicle lub personGroup)
          allProductsAtOnce: false, //czy po wybraniu jednego produktu zaznaczać też pozostałe możliwe w tym samym wariancie dla danego obiektu-domem/pojazdem/osobą-grupą (tyczy sie tylko produktow typu localization, vehicle lub personGroup)
          multiVariantsProducts: [], //produkty dla których można wybrać wiele wariantów dla jednego podmiotu
          productVariantsConflict: {}, //warianty które nie mogą razem występować
          addsVariantsConflict: {}, //warianty dodatków, które nie mogą razem występować (kod_dodatku: [tablica wariantów konfliktowych])
          checkShortTermNnw: false, //czy sprawdzac dostepnosc produktow/dodatkow pod katem krotkoterminowego NNW
          addsWithOneVariant: [], //dodatki które mają warianty, ale można wybrać tylko jeden na raz
          addClientPKD: false, //dodawanie kodów PKD dla klienta
          mainInsuredAdditionalFields: [], //lista niestandardowych pól głównego ubezpieczonego
          mainInsuredOnlyRisks: [], //lista ryzyk osobowych które są możliwe tylko dla głównego ubezpieczonego
          mainInsuredOnlyRisksVariants: {}, //lista wariantów na ryzykach osobowych które są możliwe tylko dla głównego ubezpieczonego
          defaultPersonDynamicValues: {}, //domyślne wartości dynamicValues dla osoby z podziałem na ryzyka
          liquidationVariant: ['II', 'III'], //dla jakich wariantów ACKOM popup z wyborem sposobu likwidacji
          nnwIncognito: false, //możliwe bezosobowe ubezpieczenie w NNW
          protectionDatesOnVariants: [], //lista produktów w których daty ochrony są oddzielnie określane dla każdego wariantu
          rewriteSuFire: true, //czy można przepisać sumy z ognia do kradizeży i na odwrót (np. w e1 nie ma kradzieży)
          propertyOneProtectionPeriod: true, //czy wspólne daty ochrony dla ryzyk mieniowych i osobowych
          policiesGrouping: false, //czy obsługiwane grupowanie polis (np w MF w przypadku wielu lokalizacji i osób)
          risksToOmit: [], //ryzyka do pominiecia przy sczytywaniu z usług REST (podczas taryfikacji/zapisów wniosku)
          discountDetailsPurePremiumProducts: ['OCKOM'], //produkty(z PRODUCTLIST), dla których prezentujemy elementarne (bez dodatków) składki na modalu "szczegóły zniżki" (PZA)
          additionsToOmit: [], //dodatki, których nie mapujemy na ryzyka REST. Konwencja: [kod_dodatku[, wariant_dodatku]] (wariant jest opcjonalny)
          defualtRiskProfession: null, //domyslny kod profesji używany przy szukaniu definicji ryzyka, gdy jeszcze go nie podano a definicja ryzyko go wymaga
          liquidationLink: 'http://siecpartnerska.ergohestia.pl', //link do strony z serwisami likwidacji szkód
          hideModalAfterAddToggled: false, //czy chować automatycznie modal po wybraniu dodatku
          hideModalAfterExtToggled: false, //czy chować automatycznie modal po wybraniu rozszerzenia
          ceidgSearch: true, //czy wyświetlić wyszukiwanie firm z CEIDG na ekranie wyszukiwania ubezpieczającego
          readPersonalRiskPremiumFromRisk: true, //czy zczytywać skladki dla osob w ryzykach osobowych z ryzyka (jesli false to proba sczytywania z atrybutu premiumForNNW i premiumForOC z osoby)
          separatePersonalPolices: false, //czy polisy osobowe prezentować w osobnej kolumnie
          addRiskVariantToCalculationFactors: true, //czy dodać czynnik taryfikacyjny 'wariant ubezpieczenia' w podglądzie
          defaultCalculateEstatesSeparately: false, //domyślna wartość podziału polis - lokalizacje osobno
          tarifficationLockProducts: {}, ////klikniecie w produkt z wariantem nie bedzie powodowac taryfikacji
          personWarningEditFields: ['pesel', 'regon', 'nip'], //pola z modelu osoby, dla których formularz edycji osoby pozwoli na zapis nawet przy błędnej wartości pola
          minPropertyStartDateDays: 0, //liczba dni (+/-), które po dodaniu do aktualnej daty, dadzą minimalną datę rozpoczęcia ochrony dla mienia
          minCommunicationStartDateDays: -4, //liczba dni (+/-), które po dodaniu do aktualnej daty, dadzą minimalną datę rozpoczęcia ochrony dla komunikacji
          mainInsuredObject: null, //główny obiekt ubezpieczenia, o ile istnieje (np. pojazd w solo-ockom, nieruchomość w solo-md). Jeśli nie istnieje to null.
          globalProtectionPeriod: null, //czy włączone ustawianie okresu ochrony (chodzi o typ) dla całego wniosku (np. pakiety sport, travel). DOSTEPNE OPCJE: days: opcje z dniami, travel: opcje dla podróży
          additionsWithoutRisks: false, //czy można wysyłać do usług dodatki bez wybrania jakiegokolwiek ryzyka (np. przypadek zielonej karty w SOLO OC KOM),
          basketSecondVisable: true, //czy dostpny koszyk 2 (dodatkoweli i odstępstwa)
          basketProductsHidden: false, //czy ukryte produkty w koszyku
          ocBuyerSupported: false, //czy obsługiwany wniosek OC nabywcy
          ocBuyerSellerPremiumPartLabel: 'Przeksięgowano z polisy poprzedniego właściciela', //label dla Składki przeksięgowanej z polisy Zbywcy
          sendVehicleEtxInfo: false, //czy sczytywać atrybut z informacją o pojeździe w eurotax z wniosku (dynamicValues w response) - stary sposóļ informowania o pojezdzie spoza etx
          defaultLeaserCountry: 'PL', //domyślna wartość dla kraju leasingodawcy
          autoAddInsurerToInsured: false, //czy automatycznie dodać ubezpieczającego do grupy ubezpieczonych (UWAGA dotyczy tylko produktów typu personGroup lub aplikacji z isSingleProduct:true osobowych)
          autoAddMainInsuredToInsured: false, //czy automatycznie dodać głównego ubezpieczonego do grupy ubezpieczonych (UWAGA dotyczy tylko produktów typu personGroup lub aplikacji z isSingleProduct:true osobowych)
          dayDependedProrogation: false, //czy opcje odroczenia płatności mają być dynamicznie określane na podstawie różnicy w dniach między datą podpisania a datą początku ochrony
          greenCardAsProd: false, //czy traktować zieloną kartę jak ryzyko/produkt
          allowMainInsuredChange: false, //czy pozwalac na zmiane glownego ubezpieczonego
          insurerSameAsMainInsured: false, //czy ubezpieczający zawsze = główny ubezpieczony
          hideProductPremiums: false, //czy ukrywać składki produktów/ryzyk (m.in. na matrycy i paragonach)
          ocBuyerPolicyWithdrawalUrl: 'external/policyOperations/{0}/withdrawal?salesApplicationId={1}', //url wypowiedzenia polisy dla wnioksu OC nabywcy (kieruje do iAgenta, {0} będzie zastąpiony odpowiednim identyfikatorem)
          messageTranslation: true, //czy uruchomiony mechanizm "ręcznego" tłumaczenia komunikatów z usług
          todaysInsuranceExtraMinutes: 3, // minutes added to current time, when insurance is starting today
          basketSecondPackages: {
            packages: [], //dla jakich pakietów sprawdzać dostępność zniżek
            segment: 'C', //dla jakiego segmentu sprawdzać dostępność zniżek
            defaultCurrency: 'zł' //domyślnie wyświetlana waluta (np. w komunikatach, modalach itp.)
          },
          vehicle: {
            //konfiguracja edycji pojazdów
            nonEtxVehicles: true, //czy można wybrać pojazd, który jest poza Eurotax
            cep: false, // is CEP module (search) enabled
            mileage: true, //czy rejestrujemy przebieg pojazdu
            vehicleOriginalKey: true, //wudoczność "liczba kompletów kluczy" na ekranie edycji pojazdu
            vehicleYoungDriver: true, //czy moożliwość ustawienia, że kierowca młodociany (do 25 roku życia)
            vehicleBrandNew: true, //ustawienie, że pojazd jest nówka funkiel nie śmigany
            vehicleLeasing: true, //czy można ustaqić, że pojazd jest przedmiotem leasingu
            vehicleSpecialPurpose: true, //czy można wybrać szczegolne przeznaczenie pojazdu (MODAL)
            vehicleSpecialPurposeV2: false, //czy można wybrać szczegolne przeznaczenie pojazdu (SELECT)
            vehicleEqipment: true, //czy można wybrać na pojeżdzie wyposażenie (które ma wpływ na wycenę)
            vehicleDamage: true, //czy pojazd posiada uszkodzenia
            etxValuation: true, //czy wyceniać pojazd na podstawie ETX (uwaga: aby wycena działał, musi być zaznaczone opjca mileage: true)
            rightHandDrive: true, //pojazd z kierownicą po prawej stronie
            vehicleGearTypes: true, // czy można wybrać rodzaj skrzyni biegow (NonETX)
            permissibleWeight: false, //dopuszczalna masa całkowita (NonETX)
            extendedValueTypes: false, //is VAT+50% value type available
            vehicleUsedInBusiness: false // checkbox/select "is vehicle used in business"
          },
          localization: {
            connectedFireRisk: {}, // czy ryzyka ogniowe są połączony, jesli tak to w jakim wariancie {variant: opis}
            fireBurglarySharedVariant: false, //łączony wariant III dla ryzyk ognia i kradzieży
            rewriteSuFire: true, //czy można przepisać sumy z ognia do kradizeży i na odwrót (np. w e1 nie ma kradzieży)
            sendDefaultEstateName: false, //czy wysyłać nazwę na Estate jeśli nazwa nie uzupełniona
            localizationSetGroundFloor: false //tymczasowe ostawianie groundFloor, aż BOS wprowadzi zmianę
          },
          optionalClauses: [],
          extraLife: true, // is ExtraLife component supported
          extraLifeE4: false // is new ExtraLife for e4 enabled (IHESTLIFE-5855)
        },
        FILTERS: {
          //filtry danych
          personExtraFieldsToDelete: //dodatkowe pola/dane do usuwania przy zapisie ubezpieczającego
            {
              owner: [], //traktowany jest jako ubezpieczający oraz jako właściciel pojazdu
              coowner: [], //współwłaściciel np. mieszkania/pojazdu
              insured: [], //ubezpieczony i współubezpieczony np. w NNW/OCZP
              any: [] //pozostałe osoby (nie określona rola)
            }
        },
        NAVIGATION: {
          //nawigacja po ścieżce
          initialState: 'clientSearch', //inicjalny stan (jego nazwa) ścieżki, czyli to gdzie przekierowujemy przy akcji "new"
          otherwiseUrl: '/matrix', //strona (url), do ktorej będziemy przekierowywać gdy żądanego stanu nie ma (traktowany rowniez jako domyslna strona wyklikiwanego wniosku, np. /matrix)
          mainState: 'matrix' //kod głównego state'a aplikacji - główna strona. Musi być zgodny z nazwą/kodem state'a w konfiguracji $stateProvider'a
        },
        RESOURCES: { //te ustawienia lądują do factory RESOURCES !!
          PRODUCTLIST: [], // lista productów występujące w aplikacji
          SPECIAL_PURPOSE_OPTIONS: [{ //UWAGA ponizsze kody musza zgadzac sie z kodami z spdRisks.js
            'CODE': 'drivingSchool',
            'NAME': {
              __: ['vehicleSpecialPurpose.drivingSchool', 'sp2resource']
            }
          }, {
            'CODE': 'carRental',
            'NAME': {
              __: ['vehicleSpecialPurpose.carRental', 'sp2resource']
            }
          }, {
            'CODE': 'testDrive',
            'NAME': {
              __: ['vehicleSpecialPurpose.testDrive', 'sp2resource']
            }
          }, {
            'CODE': 'vehicleRaces',
            'NAME': {
              __: ['vehicleSpecialPurpose.vehicleRaces', 'sp2resource']
            }
          }, {
            'CODE': 'vehicleReplacement',
            'NAME': {
              __: ['vehicleSpecialPurpose.vehicleReplacement', 'sp2resource']
            }
          }, {
            'CODE': 'taxi',
            'NAME': {
              __: ['vehicleSpecialPurpose.taxi', 'sp2resource']
            }
          }],
          DATA_TRANSFER: [ //pozycje dla modala "Przenoszenie danych".
            {
              label: {
                __: ['Public.e1', 'Public']
              }, //label pozycji w modalu
              iconText: 'E1', //tekst na ikonce w modalu
              symbol: 'E1', //symbol wniosku
              url: 'E1_URL' //kod z CONFIG.external do adresu sprzedażówki
            }, {
              label: {
                __: ['Public.e7', 'Public']
              },
              iconText: 'E7',
              symbol: 'E7',
              url: 'E7_URL'
            },
            /** obecnie brak obsługi MF
             {
                         label: {__: ['Public.mf', 'Public']},
                         iconText: 'MF',
                         symbol: 'nMF',
                         url: 'MF_URL'
                       }, **/
            {
              label: {
                __: ['Public.productActivity', 'Public']
              },
              iconText: 'ES',
              symbol: 'ES',
              url: 'ES_URL'
            }, {
              label: {
                __: ['Public.productTravel', 'Public']
              },
              iconText: 'EP',
              symbol: 'EP',
              url: 'EP_URL'
            }, {
              label: {
                __: ['Public.productOckom', 'Public']
              },
              iconText: 'OCT',
              symbol: 'SOCT',
              url: 'OCK_URL'
            }, {
              label: {
                __: ['Public.productWalls', 'Public']
              },
              iconText: 'MD',
              symbol: 'SMD',
              url: 'MD_URL'
            }, {
              label: {
                __: ['Public.productOcActivity', 'Public']
              },
              iconText: 'OCZ',
              symbol: 'SOCZ',
              url: 'OCZ_URL'
            }, {
              label: 'Ergo 4',
              iconText: 'E4',
              url: 'E4_URL',
              generalAgreementPrefix: 'IH/LIFE' // option available only if user has access to certain GA (with given prefix)
            }
          ],
          SPECIAL_PURPOSE_OPTIONS_V2: [{
            'CODE': 'BRAK',
            'NAME': {
              __: ['lackUpperCase', 'Public']
            }
          }, { //UWAGA ponizsze kody musza zgadzac sie z kodami z spdRisks.js
            'CODE': '00',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions', 'Jupiter']
            }
          }, {
            'CODE': '01',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions1', 'Jupiter']
            }
          }, {
            'CODE': '02',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions2', 'Jupiter']
            }
          }, {
            'CODE': '03',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions3', 'Jupiter']
            }
          }, {
            'CODE': '04',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions4', 'Jupiter']
            }
          }, {
            'CODE': '05',
            'NAME': {
              __: ['spConfigProviderSpecialPurposeOptions5', 'Jupiter']
            }
          }],
          ESTATE_TO_PROPERTY_TYPE: { // !! keys must much LsnEstateModelConstants.ESTATE_TYPE_...
            flat: 'Mieszkanie',
            house: 'Dom',
            construction: 'Dom w budowie',
            summerHouse: 'Domek letniskowy',
            outbuilding: 'Budynek'
          }
        },
        MENU: {
          matrixInLeftMenu: true, //czy link do matrycy wystepuje w lewym menu
          dataTransferInLeftMenu: true //czy link do "przenoszenie danych" w lewym menu
        },
        GENERAL_AGREEMENTS: {
          lifePrefix: 'IH/LIFE' // {string} ergo 4 (life) GA prefix used in general agreements codes/numbers
        },
        MESSAGES: {
          fraudulentMsgCode: 'O000111', // fraudulent client message code
          suggestedPropertySICodes: ['P200321', 'P200322'] // suggested "property sum insured" message codes
        },
        OFFER_VIA_EMAIL: { // "send offer via email" functionality
          clause: { // required clause
            code: 'elec',
            nameLabel: 'sp2.clauses.email.short', // name next to checkbox control
            descLabel: 'sp2.clauses.email.ext' // extended description
          }
        }
      };

      this.getParams = function() {
        return params;
      };

      this.$get = function() {
        return params;
      };
    }
  ]);
