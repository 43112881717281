/**
 * Dyrektywa regex-mask.
 * Ogranicza znaki wpisywane przez użytkownika do tych spełniających wyrażenie regularne podane w parametrze.
 *
 * Przykłady wyrażeń regularnych:
 * /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ -]*$/ - imię i nazwisko (litery + spacja + myślnik)
 * /^[0-9]{0,11}$/                   - pesel (tylko cyfry; od zera do jedenastu znaków)
 *
 * Przykład użycia:
 * $scope.regex = '^[a-z ]*$';
 * <input type="text" name="test" ng-model="test" regex-mask="regex" /> <!-- wyrażenie regularne w zmiennej -->
 *
 * <input type="text" name="test" ng-model="test" regex-mask="'^[a-z ]*$'" /> <!-- lub bezpośrednio w atrybucie -->
 *
 * @author Michał Wierzba <michalw@lsnova.pl
 */
angular.module('ihestiaCommonDirectives')
  .directive('regexMask', ['$log', function($log) {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function postLink(scope, el, attrs, ngModelCtrl) {

        var lastValidValue; // ostatnia poprawna wartość (wpisana przez użytkownika)
        var regexp = new RegExp(); // wyrażenie regularne, którym będą sprawdzane wartości wpisywane przez użytkownika
        var listeners = []; // pojemnik na listenery (watch, observe, ...)

        /**
         * Renderuj ostatnią poprawną wartość.
         */
        function renderLastValidValue() {
          ngModelCtrl.$setViewValue(lastValidValue);
          ngModelCtrl.$render();
        }

        /**
         * Sprawdzenie poprawności wyrażenia regularnego w momencie jego zmiany.
         */
        function onRegexMaskChange(value) {
          try {
            regexp = new RegExp(value);
            if (angular.isDefined(lastValidValue)) {
              if (!regexp.test(lastValidValue)) {
                lastValidValue = '';
              }
              renderLastValidValue();
            }
          } catch (e) {
            $log.error('Invalid regexp string /%s/ passed to regexMask directive %o', value, e);
          }
        }

        /**
         * Parser wartości wpisanej przez użytkownika.
         */
        function parseViewValue(value) {

          // zapamiętanie pozycji kursora
          var element = el[0];
          var cursorPosition = element.selectionStart - 1;

          if (angular.isUndefined(value)) {
            value = '';
          }

          var empty = ngModelCtrl.$isEmpty(value);
          if (empty) {
            lastValidValue = '';
          } else {
            if (regexp.test(value)) {
              lastValidValue = (value === '') ? null : value;
            } else {
              renderLastValidValue();
              element.setSelectionRange(cursorPosition, cursorPosition); // przywrócenie pozycji kursora
            }
          }

          return lastValidValue;
        }

        // Ustawienie parsera.
        ngModelCtrl.$parsers.push(parseViewValue);

        // Watch wartości wyrażenia regularnego.
        listeners.watchRegexMask = scope.$watch(attrs.regexMask, onRegexMaskChange);
      }
    };
  }]);