angular.module('ihestiaMenuUserBox', [
  'ihestia-menu-templates-app',
  'ihestiaSsoBase',
  'ihestiaCommonDirectives',
  'ihestiaCommonComponents',
  'ihestiaAg'
]).provider('ihestiaMenuUserBoxConfig',function() {

      var self = this;
      self.defaultConfig = {
        template: 'ihestia-menu-template-app/userBox/userBox.tpl.html'//glowna tpl dla dyrektywy
      };

     /**
     * Magic get
     * @return {Object}
     */
    this.$get = [
      function() {
        return self;
      }];
  });