angular.module('salesPath2')
  .service('organizationHelper', ['AddressModelConstants', 'ihestiaTrimInfo', 'CONSTANTS', 'lsnDeepExtend', '$filter', 'mainDataContainer', 'addressHelper',
    function(AddressModelConstants, ihestiaTrimInfo, CONSTANTS, lsnDeepExtend, $filter, mainDataContainer, addressHelper) {
      var OrganizationHelper = function() {
        var self = this;

        /**
         * zwraca dane firmy i głównego adresu
         * @param  {OrganizationModel} organization
         * @return {String}
         */
        this.getOrganizationWithAddress = function(organization) {
          var data = organization.getData();
          addressHelper.addMainAddressData(data);
          angular.forEach(data, function(v, k) {
            if (v === null) {
              data[k] = '';
            }
          });
          return data;
        };

        /**
         * zwraca opis firmy zdatny do wyświetlena
         * @param  {OrganizationModel} org
         * @param  {object} params
         * @return {string}
         */
        this.getOrganizationInfo = function(org, params) {
          // domyslne ustawienia
          var settings = lsnDeepExtend({
            elements: [{
              field: 'name'
            }, {
              field: 'nip',
              fieldSeparator: ', '
            }],
            maxLength: 40,
            emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
          }, params);

          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            element.text = org.get(element.field);
          });

          var text = ihestiaTrimInfo(settings);

          return text;
        };

        /**
         * usuwa organizacje z datacontainera
         * Można w przyszłości rozszerzyć o sprawdzanie np. czy org. nie jest użyta w jakims ryzyku itd. i od tego uzależnić usuwanie
         * @param  {String} clientId clientId organizacji
         * @return {Boolean} true gdy usunieto
         */
        this.deleteOrganization = function(clientId) {
          var deleted = false;
          if (angular.isObject(mainDataContainer.organizations[clientId])) {
            delete mainDataContainer.organizations[clientId];
            deleted = true;
          }
          return deleted;
        };

        /**
         * zwraca tablicę organizacji określonego typu
         * @param  {String} type CONSTANTS.ORGANIZATION_TYPE_...
         * @return {LsnOrganizationModelV1[]} organizacje
         */
        this.getOrganizationsByType = function(type) {
          var orgs = [];
          angular.forEach(mainDataContainer.organizations, function(org) {
            if (angular.isObject(org.dynamicValues) && org.dynamicValues.type === type) {
              orgs.push(org);
            }
          });
          return orgs;
        };

        /**
         * usuwa organizacje nieuczestniczace w ubezpieczeniach
         */
        this.deleteUnusedOrganizations = function() {
          var cIdsToDelete = []; //clientId organizacji, ktore bedziemy usuwac
          angular.forEach(mainDataContainer.organizations, function(org, clientId) {
            if (!self.isOrganizationInsured(org)) {
              cIdsToDelete.push(clientId);
            }
          });
          angular.forEach(cIdsToDelete, function(cId) {
            self.deleteOrganization(cId);
          });
        };

        /**
         * czy organizacja jest w jakiś sposób ubezpieczona lub powiązana z ubezpieczeniem na wniosku
         * @param  {LsnOrganizationModelV1}  org firma
         * @return {Boolean} false gdy nie powiązana
         */
        this.isOrganizationInsured = function(org) {
          var insured = false,
            containers = ['localizations', 'vehicles'],
            clientId = org.metaData.get('clientId'),
            id = org.metaData.get('id');
          
          //sprawdzamy powiązania w 
          lsnNg.forEach(containers, function(container) {
            lsnNg.forEach(mainDataContainer[container], function(obj) {
              if (obj.getAdditionalData('leaserId') === clientId) {
                insured = true;
                return false;
              }
              return true;
            });
            if (insured) {
              return false;
            }
            return true;
          });

          if (!insured) { //jesli nie jest leaserem, to sprawdzamy jeszcze powiązania jako cesjonariusz na polisach
            lsnNg.forEach(mainDataContainer.policies, function(policy) {
              if (angular.isArray(policy.suretyRef) && policy.suretyRef.indexOf(id) !== -1) {
                insured = true;
                return false;
              }
              return true;
            });
          }

          return insured;
        };

      };

      return new OrganizationHelper();
    }
  ]);