angular.module('salesPath2')
  .controller('leasingInfoModalCtrl', ['$scope', 'leasingModalHelper', 'addressHelper', 'AddressModel',
    function($scope, leasingModalHelper, addressHelper, AddressModel) {
      $scope.leasing = $scope.dataContainer.organizations[leasingModalHelper.editLeasingModalData.leaserId];
      $scope.readonly = false; //czy w trybie RO

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = function() {
        $scope.fireListeners();
        $scope.loadData();
        $scope.readonly = $scope.appVariables.isSupplement || ($scope.appVariables.readOnly && !$scope.appVariables.isOcBuyer);
      };

      /**
       * ustawiamy dane
       * @return {[type]} [description]
       */
      $scope.loadData = function() {
        var addressTrimSetting = {
          elements: [
            {field: 'postalCode'}, 
            {
              field: 'city',
              fieldSeparator: ', ',
              optionalLineBreak: true, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
              forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
            },
            {field: 'streetPrefix'},
            {field: 'street'},
            {field: 'house', fieldSeparator: '', firstInRow: false},
            {field: 'apartment', prefix: '/', lastInLine: true}            
          ],          
          maxLength: 35,
          doTruncate: false
        };
        $scope.leasingData = $scope.leasing.getData();
        $scope.leasingData.leaserAddress = addressHelper.getAddressInfo((angular.isDefined($scope.leasing.addresses[0]) ? $scope.leasing.addresses[0] : new AddressModel()), addressTrimSetting);
      };

      $scope.fireListeners = function() {
        //kliknięto 'zmień leasingodawcę'
        $scope.$on('iHestiaCommonModalCancelBtnClicked.leasingInfoModal', function() {
          leasingModalHelper.changeLeasing();
        });
      };

      /**
       * Edycja leasingu
       * @return {[type]} [description]
       */
      $scope.editLeasing = function() {
        leasingModalHelper.hideLeaserInfoAndEdit(leasingModalHelper.editLeasingModalData.leaserId, leasingModalHelper.editLeasingModalData.leaserId);
      };


      /**
       * Usuwamy przypisanie leasingodawcy
       */
      $scope.deleteLeasingAssignment = function() {
        leasingModalHelper.deleteLeasingAssignment();
      };

      $scope.init();
    }
  ]);