angular.module('lsnBase.models')
  .factory('LsnRiskModelV1', ['LsnAbstractModel', 'SalesProductModel', 'LsnAmountModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, SalesProductModel, LsnAmountModelConstants, lsnModelFactory)
    {
      var LsnRiskModelV1 = function()
      {
        this.objectName = 'Risk';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          product:
          {
            type: 'SalesProduct'
          },
          owu:
          {
            type: 'string'
          },
          start:
          {
            type: 'dateTime'
          },
          end:
          {
            type: 'dateTime'
          },
          premium:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          sum:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          gross:
          {
            type: 'bool'
          },
          insuredRef:
          {
            type: 'string'
          },
          insuredObjectsRef:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          insuredSubjectsRef:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          symbol:
          {
            type: 'string'
          },
          group:
          {
            type: 'string'
          },
          // ponizsze do ustalenia - DiD
          parentRef:
          {
            type: 'string'
          },
          isHistorical:
          {
            type: 'bool',
            defaultValue: false
          },
          beneficiaries: {
            type: 'array',
            elementsType: 'mixed'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.product = new SalesProductModel(); // SalesProduct, Referencja na definicję ryzyka w PTY
        this.owu = null; // string, Symbol OWU
        this.start = null; // date, Data rozpoczęcia okresu ochrony na polisie
        this.end = null; // date, Data zakończenia okresu ochrony na polisie
        this.premium = null; // Amount, Składka na ryzyku
        this.sum = null; // Amount, Suma ubezpieczenia
        this.gross = null; // bool, True oznacza, że jest to kwota brutto. Wartością domyślną jest true. Uzupełniać tylko jeśli false.
        this.insuredRef = null; // string, Idenyfikator głównego ubezpieczonego z polisy dla danego ryzyka. MetaData.Id z listy persons lub organizations
        this.insuredObjectsRef = null; // lista InsuredObject, Lista obiektów ubezpieczonych na ryzyku
        this.insuredSubjectsRef = null; // Lista InsuredObject, Lista osób ubezpieczonych na ryzyku
        this.symbol = null; // string, Symbol np. „J07-01”
        this.group = null; // string, Grupa ministerialna
        this.parentRef = null; // metaData.id rodzica
        this.isHistorical = null; // czy ryzyko historyczne - nie uczestniczace np. w taryfikacji
        this.beneficiaries = null;
      };

      LsnRiskModelV1.prototype = LsnAbstractModel.prototype;

      return LsnRiskModelV1;
    }
  ]);