angular.module('salesPath2')
  .service('resourceHelper', ['RESOURCES', 'CONFIG', 'sp2CommonHelper', 'CONSTANTS',
    function(RESOURCES, CONFIG, sp2CommonHelper, CONSTANTS) {
      var ResourceHelper = function() {
        var self = this;

        /**
         * zbiór definicji obsługiwanych produktów
         * @type {Array}
         */
        this.products = [];
        /**
         * kody produktow do typu produktu
         * @type {Object}
         */
        this.productType = {};
        /**
         * dostępności dodatków dla produktó∑
         * @type {Object}
         */
        this.addToProduct = {};

        /**
         * konfiguracja zaokrąglania sum ubezpieczeń dla produktów
         * UWAGA aktualnie jest jeden sposób zaokrąglania opierający się tylko na podaniu min i max SU
         * @type {Object} klucz to kod produktu, wartość to
         * a) obiekt {min: 1000, max: 40000}, gdzie min i max to graniczne wartości SU.
         * lub
         * b) obiekt {I: ..., II: ...[,...]}, gdzie 'I' to wariant a wartością obiekt z punktu a).
         */
        this.sumInsuredRoundings = {};

        /**
         * INICJALIZACJA HELPERA
         * @return {[type]} [description]
         */
        this.init = function() {
          self.refreshProducts();
          angular.forEach(RESOURCES.PRODADD, function(prodDef) {
            self.addToProduct[prodDef.CODE] = angular.copy(prodDef.PRODUCTS);
          });
        };

        //aktualizuje self.products
        self.refreshProducts = function() {
          self.products = [];
          angular.forEach(RESOURCES.PRODUCTLIST, function(product) {
            if (product.FAKE) { //pomijamy sztuczne produkty
              return;
            }
            self.products.push(product);
            self.productType[product.CODE] = product.TYPE;
            if (product.CODE === CONSTANTS.PRODUCT_OGI && CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant) {
              self.productType[CONSTANTS.PRODUCT_OGI_KRA] = product.TYPE;
            }
            if (CONFIG.BEHAVIOR.nnwIncognito) {
              self.productType[CONSTANTS.PRODUCT_NNW_INCOGNITO] = CONSTANTS.PRODUCT_TYPE_PERSON;
            }
            if (angular.isArray(product.VARIANTLIST)) {
              angular.forEach(product.VARIANTLIST, function(variantDef) {
                self._setSumInsuredRounding(product.CODE, variantDef.CODE, variantDef);
              });
            } else {
              self._setSumInsuredRounding(product.CODE, null, product);
            }
          });
        };

        /**
         * ustawia parametry zaokrąglania SU, jeśli podane w definicji produktu/wariantu
         * @param {String} prodCode
         * @param {String} variantCode
         * @param {Object} def definicja produktu/wariantu
         * @return {Boolean} true gdy ustawiono parametry
         */
        this._setSumInsuredRounding = function(prodCode, variantCode, def) {
          if (angular.isUndefined(def.SUMINSURED_MIN) || angular.isUndefined(def.SUMINSURED_MAX) || def.TYPEINSUM !== true) {
            return false;
          }
          if (angular.isUndefined(self.sumInsuredRoundings[prodCode])) {
            self.sumInsuredRoundings[prodCode] = {};
          }
          var roundingParams = {
            min: def.SUMINSURED_MIN,
            max: def.SUMINSURED_MAX
          };
          if (angular.isString(variantCode)) {
            self.sumInsuredRoundings[prodCode][variantCode] = roundingParams;
          } else {
            self.sumInsuredRoundings[prodCode] = roundingParams;
          }
          return true;
        };

        /**
         * sprawdza dostępnosc produktu w danej aplikacji
         * @return {boolean} czy produkt dostępny w aktualnej aplikacji/pakiecie
         */
        this.isProductAvailable = function(code) {
          var available = false;
          lsnNg.forEach(RESOURCES.PRODUCTLIST, function(product) {
            if (product.CODE === code) {
              available = true;
              return false;
            }

            return true; //continue
          });
          return available;
        };

        /**
         * zwraca definicje produktu z resources
         * @param  {[type]} productCode [description]
         * @return {Object}
         */
        this.getProdDef = function(productCode) {
          var prod = {};
          lsnNg.forEach(self.products, function(prodData) {
            if (prodData.CODE === productCode) {
              prod = prodData;
              return false;
            }

            return true; //continue
          });
          return prod;
        };

        /**
         * zwraca tablicę z grupami wariantów dla danego produktu na podstaiw danych z konfiguracji ścieżki (BEHAVIOR.multiVariantsProducts i .productVariantsConflict)
         * Jeśli produkt nie jest multiwariantowy to zwrócona będzie jedna grupa ze wszusykimi wariantami produktu
         * @param  {String} productCode
         * @return {String[][]} np. [['I'], ['II', 'III']]
         */
        this.getVariantsGroupsForProduct = function(productCode) {
          var pDef = self.getProdDef(productCode);
          if (!angular.isArray(pDef.VARIANTLIST)) {
            return [];
          }
          var isMultiVariant = CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1,
            conflictVariants = angular.isDefined(CONFIG.BEHAVIOR.productVariantsConflict[productCode]) ? CONFIG.BEHAVIOR.productVariantsConflict[productCode] : false,
            variantsGroups = [],
            vGroup = []; //pojedyncza grupa wariantow
          if (!isMultiVariant) { //przypadek - możliwy jeden wariant do wyboru w produkcie
            angular.forEach(pDef.VARIANTLIST, function(variantDef) {
              vGroup.push(variantDef.CODE);
            });
            variantsGroups.push(vGroup);
          } else { //wiele wariantów do wyboru jednocześnie, z czego mogą być one podzielone na grupy - jako grupę rozumiemy zbiór konfiktowych wariantów lub pojedynczy wariant
            //przy 3 wariantach mogą być maksymalnie 2 grupy wariantów (jedna z grup powinna zawierac min 2 elementy, stąd ta kalkulacja)
            var g1 = [], g2 = [];
            angular.forEach(pDef.VARIANTLIST, function(variantDef) {
              if (!conflictVariants) {
                g1.push(variantDef.CODE);
              } else {
                if (conflictVariants.indexOf(variantDef.CODE) === -1) {
                  g1.push(variantDef.CODE);
                } else {
                  g2.push(variantDef.CODE);
                }
              }

            });
            if (g1.length > 0) {
              variantsGroups.push(g1);
            }
            if (g2.length > 0) {
              variantsGroups.push(g2);
            }
          }
          return variantsGroups;
        };

        /**
         * zwraca domyslne zaznaczenia wariantow dla typu produktu
         * @param  {String} type
         * @return {Object}
         */
        this.getDefaultVariantsForType = function(type) {
          var products = {};
          angular.forEach(self.productType, function(productType, productCode) {
            if (type === productType) {
              var variants = null;
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) {
                var prodDef = self.getProdDef(productCode);
                variants = {};
                angular.forEach(prodDef.VARIANTLIST, function(variant) {
                  variants[variant.CODE] = false;
                });
              }
              products[productCode] = variants;
            }
          });
          return products;
        };

        /**
         * zwraca listę produktów dla typu
         * @param  {string} type
         * @return {array}
         */
        this.getProductsForType = function(type) {
          var products = [];
          angular.forEach(self.products, function(product) {
            if (self.productType[product.CODE] === type) {
              products.push(product.CODE);
            }
          });
          return products;
        };

        /**
         * wraca definicje wariantu produktu z resources
         * @param  {[type]} productCode [description]
         * @param  {[type]} variantCode [description]
         * @return {Object}
         */
        this.getProdVariantDef = function(productCode, variantCode) {
          var prodDef = self.getProdDef(productCode);
          var variant = {};
          lsnNg.forEach(prodDef.VARIANTLIST, function(variantData) {
            if (variantData.CODE === variantCode) {
              variant = variantData;
              return false;
            }

            return true; //continue
          });
          return variant;
        };

        /**
         * zwraca listę sztucznych produktów dla podanego kodu produktu
         * @param  {String} productCode
         * @return {Array} tablica definicji z RESOURCES.PRODUCTLIST
         */
        this.getFakeProdDef = function(productCode) {
          var defs = [];
          angular.forEach(RESOURCES.PRODUCTLIST, function(def) {
            if (def.CODE === productCode && def.FAKE) {
              defs.push(def);
            }
          });
          return defs;
        };

        /**
         * zwraca nazwe produktu
         * jesli nie podano field lub nie znaleniono field na poziomie wariantu lub ryzyka, sczytywane jest pole NAME
         * @param {string} productCode kod produktu
         * @param {string} field NAME|MENU_NAME|SUMMARY_NAME|BASKET_NAME|... - opcjonalne
         * @param {string|null|undefined} variant wariant - opcjonalny
         */
        this.getProductName = function(productCode, field, variant, productDef) {
          if (!angular.isString(variant)) {
            variant = null;
          }
          if (!angular.isString(field)) {
            field = 'NAME';
          }
          var prodDef = productDef || self.getProdDef(productCode),
            name = prodDef.NAME;
          //domyslnie nazwa z poziomu ryzyka (nie wariantu)
          if (angular.isDefined(prodDef[field])) {
            name = prodDef[field];
          }
          if (angular.isDefined(prodDef.NAMEFROMVARIANT) && prodDef.NAMEFROMVARIANT && variant !== null) {
            var prodVariantDef = self.getProdVariantDef(productCode, variant);
            if (angular.isDefined(prodVariantDef[field])) {
              name = prodVariantDef[field];
            } else if (angular.isDefined(prodVariantDef.NAME)) { //jesli nie znaleziono pola na wariancie to proba sczytwania NAME z wariantu
              name = prodVariantDef.NAME;
            } else { //jesli nie ma NAME na wariancie to bierzemy NAME z ryzyka
              name = prodDef.NAME;
            }
          }
          return name;
        };

        /**
         * zwraca nazwę dla dodatku
         * @param  {Object} object obiekt ubezpieczenia
         * @param  {String} addCode kod dodatku
         * @param  {String} variant nazwa pola, z którego pobrać nazwę w definicji dodatku/wariantu
         * @param  {String} [variant] opcja - wariant
         * @return {String}
         */
        this.getAddName = function(object, addCode, field, variant) {
          var addDef = self.getAddDef(addCode);
          if (!angular.isString(field)) {
            field = 'NAME';
          }
          var name; // (string) nazwa dodtaku przypisywana poniżej
          if (angular.isDefined(variant)) {
            var variantDef = self.getAddVariantDef(addCode, variant);
            name = angular.isDefined(variantDef[field]) ? variantDef[field] : variantDef.NAME;
          } else {
            name = angular.isDefined(addDef[field]) ? addDef[field] : addDef.NAME;
          }
          return name;
        };

        /**
         * zwraca def. dodatku
         * @param {String} addCode kod dodatku
         * @param {Boolean} omitException czy nie rzucac wujatkiem jesli nie znaleziono definicji - zwraca null
         * @return {Object} definicja dodatku z RESOURCES
         */
        this.getAddDef = function(addCode, omitException) {
          omitException = (omitException === true) ? true : false;
          var addition = null;
          angular.forEach(RESOURCES.PRODADD, function(addData) { //eslint-disable-line consistent-return
            if (addData.CODE === addCode) {
              addition = addData;
              return false;
            }
          });
          if (addition !== null) {
            return addition;
          }
          if (!omitException) {
            sp2CommonHelper.throwException('No addition resource found for code ' + addCode);
          }
          return null;
        };

        /**
         * zwraca nazwe dodatku
         * @param  {String} code  kod dodatku
         * @param  {String} field pole z nazwa dodatku (z definicji)
         * @return {String}
         */
        this.getAdditionName = function(code, field) {
          if (!angular.isString(field)) {
            field = 'NAME';
          }
          var addDef = self.getAddDef(code),
            name = addDef.NAME;
          if (angular.isDefined(addDef[field])) {
            name = addDef[field];
          }
          return name;
        };

        /**
         * [getAddVariantDef description]
         * @param  {[type]} addCode     [description]
         * @param  {[type]} variantCode [description]
         * @return {[type]}             [description]
         */
        this.getAddVariantDef = function(addCode, variantCode) {
          var addDef = self.getAddDef(addCode);
          var variant = null;
          lsnNg.forEach(addDef.VARIANTS, function(variantData) {
            if (variantData.CODE === variantCode) {
              variant = variantData;
              return false;
            }

            return true; //continue
          });
          return variant;
        };

        /**
         * [getLocalizationAttributesByType pobiera liste kodow atrybutow dla konkretnego typu lokalizacji]
         * @param  {[type]} type [typ lokalizacji np flat, house]
         * @return {[type]}      [tablica kodow atrybutow]
         */
        this.getLocalizationAttributesByType = function(type) {
          return _.chain(RESOURCES.LOCALIZATION_ATTRIBUTES)
            .toPairs() // key value pair
            .filter(function(keyValuePair) { // filter out attributes not for type
              return _.includes(keyValuePair[1].localizations, type);
            })
            .sortBy(function(keyValuePair) { // sort by order
              var attributeDef = keyValuePair[1];
              return _.get(attributeDef.order, type, attributeDef.order);
            })
            .map(_.first) // get attribute key
            .value();
        };

        /**
         * [getAllLocalizationAttributes pobiera liste WSZYSTKICH kodow atrybutow
         * @return {string[]}      [tablica kodow atrybutow]
         */
        this.getAllLocalizationAttributes = function() {
          return _.keys(RESOURCES.LOCALIZATION_ATTRIBUTES);
        };

        /**
         * get localization attribute control type
         * @param {string} attrCode
         * @return {*|string|null} CONSTANTS.CONTROL_TYPE_... or null
         */
        this.getLocalizationAttributeType = function(attrCode) {
          return RESOURCES.LOCALIZATION_ATTRIBUTES[attrCode] ?
            (RESOURCES.LOCALIZATION_ATTRIBUTES[attrCode].type || CONSTANTS.CONTROL_TYPE_CHECKBOX) : null;
        };

        /**
         * update associated object (visibilityAssoc) with attribute visibility info based on config conditions
         * visibilityAssoc map should have correct values for attributes without conditions,
         * for such attributes, visibility values will be unchanged
         * @param dynamicValues regular estate.dynamicValues with attributes' codes as keys
         * @param visibilityAssoc associated object with attribute code as key and bool as value (true = visible, false = hidden)
         * @param {string} estateType
         */
        this.updateAttributeVisibilityWithConditions = function(dynamicValues, visibilityAssoc, estateType) {
          angular.forEach(visibilityAssoc, function(attrIsVisible, attrCode) {
            if (!RESOURCES.ESTATE_ATTR_CONDITIONS[attrCode]) {
              visibilityAssoc[attrCode] = attrIsVisible;
            } else {
              var conditionsDef = RESOURCES.ESTATE_ATTR_CONDITIONS[attrCode];
              if (conditionsDef && conditionsDef.estateTypes && !_.includes(conditionsDef.estateTypes, estateType)) {
                visibilityAssoc[attrCode] = attrIsVisible;
              } else {
                visibilityAssoc[attrCode] = self.areAttributeConditionsMet(conditionsDef, dynamicValues, visibilityAssoc);
              }
            }
          });
        };

        /**
         * resolve disabled state for visible attributes
         * conditions will be checked only for visible attribute (must be resolve first!!)
         * @param dynamicValues - object holding value flags for attributes
         * @param visibilityAssoc - dictionary object holding visibility flags for attributes
         * @param estateType
         * @param disableAssoc - dictionary object holding disabled flags for attributes
         */
        this.updateDisabilityWithConditions = function(dynamicValues, visibilityAssoc, estateType, disableAssoc) {
          _.forOwn(visibilityAssoc, function(visible, attrCode) {
            var conditionsDef = RESOURCES.ESTATE_ATTR_CONDITIONS[attrCode];
            if (visible && conditionsDef && conditionsDef.disableConditions) {
              var conditionMet = self.getAttributeConditionMet(conditionsDef, dynamicValues, visibilityAssoc, conditionsDef.disableConditions);
              disableAssoc[attrCode] = !!conditionMet;
              // if condition passed, set its value to attribute
              if (disableAssoc[attrCode]) {
                dynamicValues[attrCode] = !_.isUndefined(conditionMet.value) ? conditionMet.value :
                  !_.isUndefined(conditionMet.valueFromAttr) ? dynamicValues[conditionMet.valueFromAttr] : null;
              }
            }
          });
        };

        /**
         * check if conditionsDef (see def in RESOURCES.ESTATE_ATTR_CONDITIONS) conditions are met for given dynamicValues
         */
        this.areAttributeConditionsMet = function(conditionsDef, dynamicValues, visibilityAssoc) {
          if (conditionsDef.conditionJoinMethod === CONSTANTS.JOIN_METHOD_AND) {
            return _.every(conditionsDef.conditions, function(cond) {
              return self.isConditionMet(cond, self._getConditionValue(cond, dynamicValues, visibilityAssoc));
            });
          } else { // 'or'
            return _.some(conditionsDef.conditions, function(cond) {
              return self.isConditionMet(cond, self._getConditionValue(cond, dynamicValues, visibilityAssoc));
            });
          }
        };

        this.getAttributeConditionMet = function(conditionsDef, dynamicValues, visibilityAssoc, conditions) {
          var conditionCollection = conditions || conditionsDef.conditions;
          return _.find(conditionCollection, function(cond) {
            return self.isConditionMet(cond, self._getConditionValue(cond, dynamicValues, visibilityAssoc));
          });
        };

        /**
         * check if dependentValue matches condition
         */
        this.isConditionMet = function(condition, dependentValue) {
          switch (condition.equalType) {
            case CONSTANTS.EQUAL_TYPE_EMPTY:
              return _.isObjectLike(dependentValue) ? _.isEmpty(dependentValue) : !dependentValue;
            case CONSTANTS.EQUAL_TYPE_NOT_EMPTY:
              return !(_.isObjectLike(dependentValue) ? _.isEmpty(dependentValue) : !dependentValue);
            case CONSTANTS.EQUAL_TYPE_ONE_OF:
              if (_.isArray(dependentValue)) {
                return _.some(dependentValue, function(item) {
                  return _.isEqual(item, condition.attrValue);
                });
              } else {
                // IHESTIABAS-4829 one of dependency values matches current value
                return _.includes(condition.attrValue, dependentValue);
              }
            case CONSTANTS.EQUAL_TYPE_EXCLUDES:
              return !_.some(dependentValue, function(item) {
                return _.isEqual(item, condition.attrValue);
              });
            case CONSTANTS.EQUAL_TYPE_NOT_EQUAL:
              return !_.isEqual(dependentValue, condition.attrValue);
            default: // CONSTANTS.EQUAL_TYPE_EQUAL
              return _.isEqual(dependentValue, condition.attrValue);
          }
        };

        this._getConditionValue = function(condition, dynamicValues, visibilityAssoc) {
          var hasVisibility = visibilityAssoc ? visibilityAssoc[condition.attrCode] : true;
          return _.isUndefined(dynamicValues[condition.attrCode]) || !hasVisibility ? null : dynamicValues[condition.attrCode];
        };

        /**
         * [getLocalizationAttributesByProduct pobiera liste kodow atrybutow dla konkretnego produktu]
         * @param  {[type]} type [od produktu np OGIEN, KRA]
         * @return {[type]}      [tablica kodow atrybutow]
         */
        this.getLocalizationAttributesByProduct = function(productCode) {
          var attributes = [];
          angular.forEach(RESOURCES.LOCALIZATION_ATTRIBUTES, function(attributeDef, attrCode) {
            if (attributeDef.products.indexOf(productCode) !== -1) {
              attributes.push(attrCode);
            }
          });

          return attributes;
        };

        /**
         * [getFireBurglaryRisksListAsArray poiera liste ryzyk w postaci tablicy]
         * @param  {[type]} localizationType [typ wybranej lokalizacji]
         * @param  {[type]} productCode [kod produktu]
         * @return {[type]}                  [description]
         */
        this.getFireBurglaryRisksListAsArray = function(localizationType, productCode) {
          var risks = [];
          angular.forEach(RESOURCES.FIRE_BURGLARY_RISKS_LIST, function(risk, riskCode) { //eslint-disable-line consistent-return
            //jesli definiowane per product i nie ma definicji
            if (risk.products && risk.products.indexOf(productCode) === -1) {
              return true;
            }
            //jesli definiowane per product per lokalizacja i nie ma definicji
            if (risk.productsLocType && (angular.isUndefined(risk.productsLocType[productCode]) || risk.productsLocType[productCode].indexOf(localizationType) === -1)) {
              return true;
            }

            risk = angular.copy(risk);
            risk.code = riskCode;

            if (angular.isArray(risk.namesByLocType)) {
              angular.forEach(risk.namesByLocType, function(nameDef) {
                if (nameDef.localizationType === localizationType) {
                  risk.name = nameDef.name;
                }
              });
            }

            risks.push(risk);
          });

          return risks;
        };

        /**
         * czy ryzyko o danym kodzie jest dodatkiem
         * @param  {string}  riskCode
         * @return {Boolean}
         */
        this.isRiskAdd = function(riskCode) {
          var isAdd = false;
          lsnNg.forEach(RESOURCES.PRODADD, function(riskDef) {
            if (riskDef.CODE === riskCode) {
              isAdd = true;
              return false;
            }

            return true; //continue
          });
          return isAdd;
        };

        this.getFirstVariantForProduct = function(riskCode) {
          var variant = null;
          lsnNg.forEach(RESOURCES.PRODUCTLIST, function(riskDef) {
            if (riskDef.CODE === riskCode) {
              variant = riskDef.VARIANTLIST[0];
              return false;
            }

            return true; //continue
          });

          return variant;
        };

        /**
         * zwraca definicję dla typu nieruchomości (z RESOURCES.LOCALIZATION_TYPES.LIST)
         * @param  {string} type kod typu nieruchomości
         * @return {boolean|Object} obiekt z definicją lub false gdy nieznaleziono
         */
        this.getLocalizationTypeDef = function(type) {
          if (!angular.isObject(RESOURCES.LOCALIZATION_TYPES) || !angular.isArray(RESOURCES.LOCALIZATION_TYPES.LIST)) {
            return false;
          }
          var locDef = false;
          lsnNg.forEach(RESOURCES.LOCALIZATION_TYPES.LIST, function(def) {
            if (def.TYPE === type) {
              locDef = def;
              return false;
            }

            return true; //continue
          });
          return locDef;
        };

        /**
         * zwraca definicję profesji/zawodu z resources
         * @param  {string} code kod profesji
         * @return {Object|null} null gdy nie znaleziono
         */
        this.getOczpJobDef = function(code) {
          if (angular.isUndefined(RESOURCES.OCZP_PROFESSION_LIST) || RESOURCES.OCZP_PROFESSION_LIST.length === 0) {
            return null;
          }
          var job = null;
          lsnNg.forEach(RESOURCES.OCZP_PROFESSION_LIST, function(jobDef) {
            if (jobDef.CODE === code) {
              job = jobDef;
              return false;
            }
            return true;
          });
          return job;
        };

        /**
         * zwraca obiekt opisujący częstotliwość płatności z RESOURCES
         * @param  {String|Number} value wartość, dla której poszukujemy elementu
         * @return {Boolen|Object} wyszukany obiekt z RESOURCES lub false gdy nie znaleziono
         */
        this.getPaymentFrequency = function(value) {
          var found = false;
          lsnNg.forEach(RESOURCES.FREQUENCY_CONTRIBUTIONS, function(obj) {
            if ((obj.value + '') === (value + '')) {
              found = obj;
              return false;
            }
            return true;
          });
          return found;
        };

        /**
         * czy nowy wariant jest wyższy niż poprzedni
         * @param  {String}  newVariant  nowy wariant
         * @param  {String}  prevVariant poprzedni wariant
         * @return {Boolean} true, gdy wyższy
         */
        this.isHigherVariantThan = function(newVariant, prevVariant) {
          prevVariant = prevVariant || null;
          var isHigher = false;
          switch (newVariant) {
            case CONSTANTS.VARIANT_I:
              if (prevVariant === null) {
                isHigher = true;
              }
              break;
            case CONSTANTS.VARIANT_II:
              if (prevVariant === null || prevVariant === CONSTANTS.VARIANT_I) {
                isHigher = true;
              }
              break;
            case CONSTANTS.VARIANT_III:
              if (prevVariant !== CONSTANTS.VARIANT_III) {
                isHigher = true;
              }
              break;
            case null: //nigdy nie jest wyzszy niz jakikolwiek wariant
              break;
            default:
              sp2CommonHelper.throwException('isLowerVariantThan: unsupported variant ' + newVariant);
          }
          return isHigher;
        };

        self.init();
      };

      return new ResourceHelper();
    }
  ]);
