angular.module('salesPath2')
  .factory('VehicleValueCalculatorModel', ['CONSTANTS',
    function(CONSTANTS) {
      var VehicleValueCalculatorModel = function() {
        var self = this;

        this.precisionValue = null;
        this._calculationMap = null;
        this.prevType = null;
        this.VAT_RATE = 0.23;
        this.roundPrecision = 7;

        /**
         *
         * @param {number} vehicleValue
         * @param {'brutto' | 'netto' | 'VAT 50%'} vehicleValueType
         */
        this.initialize = function(vehicleValue, vehicleValueType) {
          this.precisionValue = vehicleValue;
          this.prevType = vehicleValueType;
        };

        this.onValueChange = function(vehicleValue) {
          self.precisionValue = vehicleValue;
        };

        /**
         *
         * @param {string} vehicleValueType
         * @param {boolean} [noCalculate] - set appropriate properies , but do not calculate precisionValue
         * @returns {number}
         */
        this.onValueTypeChange = function(vehicleValueType, noCalculate) {
          var currType = vehicleValueType;
          var prevType = self.prevType;
          var vehicleValue = self.precisionValue;
          if (!noCalculate) {
            self.precisionValue = this.calcValueForTypeRelative(vehicleValue, prevType, currType);
          }
          self.prevType = currType;
          return self.precisionValue;
        };

        /**
         * Create value type conversion map
         */
        this.calculationMap = function() {
          if (!self._calculationMap) {
            self._calculationMap = {};
            // BRUTTO
            var brutto = {};
            brutto[CONSTANTS.VALUE_TYPE_NETTO] = function(bruttoValue) {
              return bruttoValue / (1 + self.VAT_RATE);
            };
            brutto[CONSTANTS.VALUE_TYPE_NETTO_PLUS_50] = function(bruttoValue) {
              return bruttoValue / (1 + self.VAT_RATE) * (1 + 0.5 * self.VAT_RATE);
            };
            self._calculationMap[CONSTANTS.VALUE_TYPE_BRUTTO] = brutto;
            // NETTO
            var netto = {};
            netto[CONSTANTS.VALUE_TYPE_BRUTTO] = function(nettoValue) {
              return nettoValue * (1 + self.VAT_RATE);
            };
            netto[CONSTANTS.VALUE_TYPE_NETTO_PLUS_50] = function(nettoValue) {
              return nettoValue * (1 + 0.5 * self.VAT_RATE);
            };
            self._calculationMap[CONSTANTS.VALUE_TYPE_NETTO] = netto;
            // NETTO PLUS 50
            var nettoPlus50 = {};
            nettoPlus50[CONSTANTS.VALUE_TYPE_BRUTTO] = function(netto50) {
              return netto50 / (1 + 0.5 * self.VAT_RATE) * (1 + self.VAT_RATE);
            };
            nettoPlus50[CONSTANTS.VALUE_TYPE_NETTO] = function(netto50) {
              return netto50 / (1 + 0.5 * self.VAT_RATE);
            };
            self._calculationMap[CONSTANTS.VALUE_TYPE_NETTO_PLUS_50] = nettoPlus50;
          }
          return self._calculationMap;
        };

        /**
         * Recalculate value as new value type (brutto/netto) based on previous value
         */
        this.calcValueForTypeRelative = function(value, prevType, newType) {
          var calculationMap = self.calculationMap();
          if (prevType !== newType && prevType && newType &&
            _.has(calculationMap, prevType) && _.isFunction(calculationMap[prevType][newType])) {
            return calculationMap[prevType][newType](value);
          } else {
            return value;
          }
        };

        /**
         *
         * @param {number} value
         * @returns {number}
         */
        this.roundValue = function(value) {
          return parseFloat(value.toFixed(self.roundPrecision));
        };
      };
      return VehicleValueCalculatorModel;
    }
  ]);
