angular.module('ihestiaWidgets.policyOperations')
   .controller('vehiclePurchaseConfirmationCtrl', ['$scope', '$state', 'VerificationDataModelConstants', 'policiesPrintoutsSvc', 'applicationsSvc', '$window', 'ihestiaConfigHelper', 'resolvedApplication', 'policyOperationsSvc', 'clientDataHelper', 'ihestiaPlatformHelper', 'policyOperationsHelper',
    function($scope, $state, VerificationDataModelConstants, policiesPrintoutsSvc, applicationsSvc, $window, ihestiaConfigHelper, resolvedApplication, policyOperationsSvc, clientDataHelper, ihestiaPlatformHelper, policyOperationsHelper) {

      $scope.VerificationDataModelConstants = VerificationDataModelConstants;

      $scope.hasUserContext = false; //czy mamy kontekst klienta (jesteśmy w iKoncie)
      if($scope.appContextData && $scope.appContextData.customerId)
      {
        $scope.hasUserContext = true;
      }

      $scope.messages = {};
      $scope.message = '';

      $scope.saveErrors = [];

      $scope.applicationData = resolvedApplication.data;
      $scope.buyerHasPatron = false;

      if($scope.applicationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_PURCHASE ||
        ($scope.applicationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION && !$scope.hasUserContext))
      {
        $scope.saleLike = false;
      }
      else
      {
        $scope.saleLike = true;
      }

      $scope.init = function(){
        $scope.prepareTitle();
        $scope.checkBuyerPatron();
        $scope.$emit('hideBlockUi');
      };

      $scope.checkBuyerPatron = function(){
        if($scope.applicationData.vehicleOwnerChangeRecords)
        {
          var persons = clientDataHelper.getPersonsByRole($scope.applicationData, 'last', 'BuyerOwner');
          if(persons[0] && persons[0].dynamicValues && persons[0].dynamicValues.hasPatron)
          {
            $scope.buyerHasPatron = true;
          }
        }
      };

      /**
       * przygotowujemy treść komunikatu
       */
      $scope.prepareTitle = function()
      {
        if(VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[$scope.applicationData.operationType])
        {
          $scope.$emit('setPageTitle', {
            pageTitleLabel: VerificationDataModelConstants.DICTIONARY_OPERATION_TYPE_LABEL[$scope.applicationData.operationType]
          });
        }
      };

      $scope.end = function(){
        policyOperationsHelper.end($scope.applicationData.policyNumber);
      };

      /**
       * zatwierdzamy operację
       * @return {[type]} [description]
       */
      $scope.confirm = function()
      {
        $scope.$emit('showBlockUi');
        $scope.saveErrors = [];
        policyOperationsSvc.patch($scope.applicationData.metaData.id, {status: 'confirmed'}, null, function(response) {
          $scope.confirmResponse = response.data;

          $scope.end();
        }, function(rej) {
          if(rej.status === 409 && rej.data && rej.data.messages)
          {
            $scope.saveErrors = rej.data.messages;
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409]
        });
      };

      /**
       * robimy wniosek polisowy i przechodzimy do Oc nabywcy
       * @return {[type]} [description]
       */
      $scope.makePolicy = function()
      {
        $scope.$emit('showBlockUi');
        applicationsSvc.post('vehicleBuyer/' + $scope.applicationData.metaData.id, null, null, function(response){
          if(ihestiaPlatformHelper.isJupiterOrDirect())
          {
            if($scope.applicationData.policyPlatform === 'Jupiter')
            {
              $window.location = ihestiaConfigHelper.getUrl('JUPITER_URL', 'external/salesPath/init?action=viewApplication&applicationId=' + response.data);
            }
            else
            {
              //dla Jupiter+ przekierowanie do Mtu24
              $window.location = ihestiaConfigHelper.getUrl('MTU24_URL', 'motor/ikdirocn/vehicle?applicationVer=' + response.data);
            }
          }
          else
          {
            $window.location = ihestiaConfigHelper.getUrl('OCK_URL', 'external/init?action=viewApplication&applicationId=' + response.data);
          }
        }, function(){
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * zatwierdzamy wniosek z informacją, że agent chce podjąć zadanie
       * @return {[type]} [description]
       */
      $scope.getTask = function()
      {
        $scope.$emit('showBlockUi');
        var data = {
          op: 'Replace',
          path: '/CurrentUserAsBuyerPatron',
          value: true
        };
        policyOperationsSvc.patch($scope.applicationData.metaData.id, data, null, function(response) {
          $scope.confirmResponse = response.data;
          $scope.applicationConfirmed = true;
        }, function(rej) {
          if(rej.status === 409 && rej.data && rej.data.messages)
          {
            $scope.saveErrors = rej.data.messages;
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409]
        });
      };

      $scope.makeWithdrawal = function()
      {
        $state.go('main.vehicleOperationWithdrawal', {applicationId: $scope.applicationData.metaData.id});
      };

    }
  ]);