angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaBeneficiaryAdditionalDataModalHelper', ['LsnModalHelper', 'IhestiaBeneficiariesContants', '$filter', 'ihestiaConfigHelper', 'IhestiaNotesAndUploader', 'policyOperationsV2Svc',
    function(LsnModalHelper, IhestiaBeneficiariesContants, $filter, ihestiaConfigHelper, IhestiaNotesAndUploader, policyOperationsV2Svc) {
      var BeneficiaryAdditionalDataModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        this.modalOptions = {
          size: 'md',
          title: ['beneficiaryModal.title', {
            componentCode: 'spLife'
          }],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/beneficiaries/beneficiaryAdditionalData/beneficiaryAdditionalDataModal.tpl.html',
          okBtnName: ['save', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };

        this.tplData = {
          startDate: null,
          startDateDisabled: true,
          minDate: null,
          today: ihestiaConfigHelper.get('serverData').TODAY,
          policyNumber: null,
          notesAndUploaderHelper: null
        };

        this.modalData = {
          policyNumber: null,
          policyStartDate: null
        };

        /**
         * sets modal options
         */
        this.setOptions(self.modalOptions);

        /**
         * initializes data on controller init
         */
        this.initData = function() {
          self.tplData.policyNumber = self.modalData.policyNumber;
          self.tplData.minDate = new XDate(self.modalData.policyStartDate);
          self.tplData.notesAndUploaderHelper = new IhestiaNotesAndUploader({
            policyNumber: self.modalData.policyNumber
          });
          self.fetchValidFrom();
        };


        this.confirm = function() {
          var files = self.tplData.notesAndUploaderHelper.getFileServerIds();
          self.setPromiseAsResolved({
            validFrom: self.tplData.startDate,
            notes: self.tplData.notesAndUploaderHelper.getNotes(),
            files: files
          });
          return self.hideModal();
        };

        this.cancel = function() {
          self.setPromiseAsRejected(IhestiaBeneficiariesContants.ACTION_CANCEL);
          return self.hideModal();
        };

        this.fetchValidFrom = function() {
          policyOperationsV2Svc.get('{0}/beneficiaries/calculateValidFrom'.format(self.modalData.policyNumber))
            .then(function(res) {
              var data = _.get(res, 'data', {});
              self.tplData.startDateDisabled = !data.editable;
              self.tplData.startDate = new Date(data.validFrom);
            }, lsnNg.noop);
        };

        /**
         * @deprecated start date and its editability should be fetch from server
         * @returns {string}
         */
        this.getManualStartDate = function() {
          var date = self.modalData.policyStartDate && self.modalData.policyStartDate > self.tplData.today ? self.modalData.policyStartDate : self.tplData.today;
          return new XDate(date).toString('yyyy-MM-dd');
        };
      };
      return new BeneficiaryAdditionalDataModalHelper();
    }
  ]);
