angular.module('ihestiaCommonDirectives')
	.service('lsnChatPopupHelper',
		function() {
			var LsnChatPopupHelper = function() {
				var self = this;

				this.numberWindowMimimized = 0;
				this.activeChatList = [];

				this.getNumberWindowMimimized = function() {
					return self.numberWindowMimimized;
				};

				this.onWindowMinimized = function() {
					self.numberWindowMimimized = self.numberWindowMimimized + 1;
				};

				this.onWindowMaximized = function() {
					self.numberWindowMimimized = self.numberWindowMimimized - 1;
				};

				this.onCloseWindow = function() {
					self.numberWindowMimimized = self.numberWindowMimimized - 1;
				};
			};

			return new LsnChatPopupHelper();
		}
	);