angular.module('salesPath2')
  /**
   * helper do wyboru produktów, ryzyk, dodatków i ich wariantów
   * (to, co kiedyś było w matrixManager lub matrixHelper)
   */
  .service('sp2SelectionHelper', ['mainDataContainer', 'actionHelper', 'sp2CommonHelper', 'CONFIG', 'CONSTANTS', '$injector', 'addHelper', 'extensionHelper', 'resourceHelper', 'RESOURCES', 'sp2SelectionPopups', 'appVariables', 'dataContainerHelper',
    function(mainDataContainer, actionHelper, sp2CommonHelper, CONFIG, CONSTANTS, $injector, addHelper, extensionHelper, resourceHelper, RESOURCES, selectionPopups, appVariables, dataContainerHelper) { // eslint-disable-line angular/di
      var SelectionHelper = function() {
        var self = this;
        /**
         * ustawienie/zmiana wariantu - odznacza lub zaznacza wariant dla produktu
         * @param  {string} code [cod wariantu]
         * @param  {string} variant [nr rzymski wariantu]
         * @param  {Boolean} [tarifficate=true] czy staryfikowac (domyslnie true)
         * @return fn getSelectedVariants();
         */

        this.selectVariant = function(code, variant, tarifficate) {
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          var productType = resourceHelper.productType[code],
            isMultiVariant = CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(code) !== -1;
          if (code === CONSTANTS.PRODUCT_OGI_KRA) {
            self.selectVariant(CONSTANTS.PRODUCT_KRA, variant);
            return self.selectVariant(CONSTANTS.PRODUCT_OGI, variant);
          }
          //jeżeli połączone warianty ognia i kradzieży i zaznaczymy inny wariant niż III w ogniu lub kradzieży to należy odzanczyć wariant III z ognia lub kradzieży jeżeli był zaznaczony
          if (code === CONSTANTS.PRODUCT_KRA && variant !== CONSTANTS.VARIANT_III && CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][CONSTANTS.PRODUCT_OGI] === CONSTANTS.VARIANT_III) {
            mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][CONSTANTS.PRODUCT_OGI] = null;
          }
          if (code === CONSTANTS.PRODUCT_OGI && variant !== CONSTANTS.VARIANT_III && CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][CONSTANTS.PRODUCT_KRA] === CONSTANTS.VARIANT_III) {
            mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][CONSTANTS.PRODUCT_KRA] = null;
          }

          if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            if (mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][code] !== variant) {
              mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][code] = variant;
            } else {
              mainDataContainer.selectedVariants[productType][mainDataContainer.selectedLocalization][code] = null;
            }
          } else if (productType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            if (mainDataContainer.selectedVariants[productType][mainDataContainer.selectedVehicle][code] !== variant) {
              mainDataContainer.selectedVariants[productType][mainDataContainer.selectedVehicle][code] = variant;
            } else {
              mainDataContainer.selectedVariants[productType][mainDataContainer.selectedVehicle][code] = null;
            }
          } else if (productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
            mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON][code][mainDataContainer.selectedSubject][variant] = !mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON][code][mainDataContainer.selectedSubject][variant];
          } else if (isMultiVariant) {
            mainDataContainer.selectedVariants[code][variant] = !mainDataContainer.selectedVariants[code][variant];
          } else {
            mainDataContainer.selectedVariants[code] = (mainDataContainer.selectedVariants[code] !== null) ? null : variant;
          }

          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
          return self.getSelectedVariants();
        };

        /**
         * zwraca zaznaczone  warianty dla wszystkich produktów w kontekście aktualnie wybranego obiektu (dataContainer.selected...)
         * dla każdego produktu jest to obiekt typu {warianat: true|false[,...]} lub {wariant: [numery_obiektów]} jeśli dataContainer.selected.... to CONSTANTS.ALL_OBJECTS
         * @return {Object} [description]
         */
        this.getSelectedVariants = function() {
          var selectedVariants = {};
          angular.forEach(resourceHelper.productType, function(type, productCode) {
            selectedVariants[productCode] = dataContainerHelper.getVariantsForProduct(productCode);
          });
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && angular.isDefined(selectedVariants[CONSTANTS.PRODUCT_OGI])) {
            selectedVariants[CONSTANTS.PRODUCT_OGI_KRA] = selectedVariants[CONSTANTS.PRODUCT_OGI]; //należy uwzględnić tylko jeden wariant - III
          }
          return selectedVariants;
        };

        /**
         * czy wariant produktu jest niedostępny z uwagi na wybór z poprzedniej polisy
         * np. w dokupieniach
         * @param  {String}  productCode kod produktu
         * @param  {String}  variantCode kod wariantu
         * @param  {String}  [objId] id obiektu/podmiotu ubezpieczonego w produkcie
         * @return {Boolean} true, gdy wyłączony
         */
        this.isHistoricallyDisabled = function(productCode, variantCode, objId) {
          var disabled = self.getHistoricalDisabledVariants(productCode, objId);
          return (disabled[variantCode] === true);
        };

        /**
         * zwraca warianty z poprzednich ryzyk (polisy) dla wszystkich produktów w kontekście aktualnie wybranego obiektu (dataContainer.selected...)
         * Wykorzystywane np. przy dokupieniach
         * @param {String} [productCode] kod produktu, jesli nie podano, to zwracamy dla każdego produktu
         * @param {String} [objectId] id obiektu/podmiotu ubezpieczonego, jesli nie podano, to zwracamy dla każdego obiektu/podmiotu
         * @return {Object} obiekt postaci:
         * {
         *   kod_produktu: {
         *     kod_wariantu: true|false //true gdy wybrany
         *     [...]
         *   } [,...]
         * }
         *
         * lub, gdy podano productCode:
         *
         * {
         *   kod_wariantu: true|false //true gdy wybrany
         *   [...]
         * }
         * lub {} gdy brak informacji
         */
        this.getPreviouslySelectedVariants = function(productCode, objectId) {
          if (!appVariables.isSupplement) { //poki co obslugujemy poprzednio wybrane warianty tylko dla dokupien/doubezpieczen
            return {};
          }
          productCode = productCode || null;
          objectId = objectId || null;
          var selectedVariants = {};
          angular.forEach(resourceHelper.productType, function(type, prodCode) {
            if (productCode !== null && productCode !== prodCode) {
              return true; //iterujemy dalej
            }
            selectedVariants[prodCode] = dataContainerHelper.getPreviousVariantsForProduct(prodCode, objectId);
            return true;
          });
          return (productCode !== null) ? selectedVariants[productCode] : selectedVariants;
        };

        /**
         * zwraca informacje o zaznaczonych historycznie dodatkach
         * @return {Object} format obiektu:
         * {
         *   kod_dodatku: {
         *     I: true //wariant
         *   }
         *   kod_dodatku2: true //dla pakietowych lub bezwariantowych
         * }
         * UWAGA:
         * metoda zwraca stan dla aktulanie zaznaczonego obiektu ubezpieczenia, gdy dodatek zależy od niego (np. lokalizacja, pojazd) i nie zwraca żadnych informacji o dodatkach, które nie wystąpiły na poprzedniej polisie.
         */
        this.getPreviouslySelectedAdds = function() {
          if (!appVariables.isSupplement) { //poki co obslugujemy poprzednio wybrane warianty tylko dla dokupien/doubezpieczen
            return {};
          }
          var prevAdds = mainDataContainer.previousRisksSelection.additions,
            finalSelection = {}; //ostateczne zaznaczenia dodatków

          lsnNg.forEach(RESOURCES.PRODADD, function(addDef) {
            if (angular.isUndefined(prevAdds[addDef.CODE])) //nie ma ryzyka na wznawianych
            {
              return true;
            }
            finalSelection[addDef.CODE] = {};
            var objectId = null;
            if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) { //dal typu pakietowego zwracamy to co przygotowane we fromRestRiskMaperze
              finalSelection[addDef.CODE] = angular.copy(prevAdds[addDef.CODE]);
            } else if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) { //dla osobowych traktujemy kazdy ubezpieczony podmiot jak wariant dodatku
              finalSelection[addDef.CODE] = {};
              angular.forEach(prevAdds[addDef.CODE], function(subId) {
                finalSelection[addDef.CODE][subId] = true;
              });
            } else { //pozostałe produkty
              objectId = dataContainerHelper.getSelectedObjectId(addDef.TYPE);
              if (angular.isArray(addDef.VARIANTS)) { //dodatki z wariantami
                angular.forEach(prevAdds[addDef.CODE], function(objsSelected, vCode) {
                  if (prevAdds[addDef.CODE][vCode].indexOf(objectId) !== -1) { //czy wybrany był nasz obiekt w wariancie
                    finalSelection[addDef.CODE][vCode] = true;
                  }
                });
              } else if (prevAdds[addDef.CODE].indexOf(objectId) !== -1) { //czy wybrany był nasz obiekt
                finalSelection[addDef.CODE] = true;
              }
            }
            if (angular.equals(finalSelection[addDef.CODE], {})) { //jeśli brak poprzednio zaznaczonego dodatku, to usuwamy jego wpis z obiektu zwracanego
              delete finalSelection[addDef.CODE];
            }

            return true; //continue
          });
          return finalSelection;
        };

        /**
         * zwraca obiekt z informacjami o niezmiennych dodatkach i ich wariantach na podstawie informacji z poprzedniej wersji polisy i aktualnego stau zaznaczenia
         * np. dokupienia
         * UWAGA:
         * Metoda zwraca informacje potrzebne do prezentacji boksu dodatku (tego, ktory rozwija się po kliknieciu na dodatek) i bierze pod uwagę
         * mainDataContainer.allowedChanges, mainDataContainer.previousRisksSelection.additions oraz CONFIG.BEHAVIOR.addsVariantsConflict (wykluczające się warianty dodatku)
         * @param {Object} [prevSelectedAdds] to co zwraca metoda self.getPreviouslySelectedAdds(). Jak nie podano, to wywolamy metodę.
         * @return {Object} obiekt taki jak w getPreviouslySelectedAdds, z tym że true oznacza wyłączenie wariantu
         */
        this.getUnchangeabledAdds = function(prevSelectedAdds) {
          if (!appVariables.isSupplement) {
            return {};
          }
          prevSelectedAdds = prevSelectedAdds || self.getPreviouslySelectedAdds();
          var unchangeable = {};
          angular.forEach(prevSelectedAdds, function(data, code) {
            var def = resourceHelper.getAddDef(code),
              variants = angular.isArray(def.VARIANTS),
              conflictVariants = CONFIG.BEHAVIOR.addsVariantsConflict[def.CODE]; //czy dodatek z wykluczajacymi sie wariantami

            if (variants && conflictVariants) { //jesli dodatek z wariantami i wykluczajace sie warianty, to musimy sprawdzic wszystkie warianty dodatku
              var currSelVariants = dataContainerHelper.getSelectedAddVariants(code, dataContainerHelper.getSelectedObjectId(def.TYPE)),
                prevVariant = self._getFirstSelectedKey(data), //wariant wybrany w poprzedniej wersji polisy
                historicalDisabledVariants = {}; //warianty zablokowane na podstawie danych z poprzedniej polisy

              unchangeable[code] = {};
              historicalDisabledVariants[prevVariant] = true; //wariant wybrany w poprzedniej wersji polisy jest domyślnie zablokowany

              //pobieramy dozwolone warianty dla zadanego dodatku na podstawie danych z mainDataContainer.allowedRisksVariants
              var allowedVariants = dataContainerHelper.getAllowedProductVariants(code, dataContainerHelper.getSelectedObjectId(def.TYPE));
              angular.forEach(def.VARIANTS, function(vDef) {
                if (allowedVariants.indexOf(vDef.CODE) === -1) {
                  historicalDisabledVariants[vDef.CODE] = true; //jeśli któryś z wariantów nie znajduje się w allowedVariants to jest blokowany
                }
              });

              unchangeable[code] = self.getUnchangeableVariants(historicalDisabledVariants, data, currSelVariants);
            } else {
              unchangeable[code] = angular.copy(data);
            }
          });
          return unchangeable;
        };

        /**
         * zwraca pierwszy klucz z obiektu, dla którego wartość === true
         * @param  {Objext} obj
         * @return {String|null} klucz lub null gdy nieznaleziono
         */
        this._getFirstSelectedKey = function(obj) {
          var firstSelected = null;
          lsnNg.forEach(obj, function(sel, k) {
            if (sel) {
              firstSelected = k;
              return false;
            }
            return true;
          });
          return firstSelected;
        };

        /**
         * [getAnyCommunicationPreviouslySelectedProductCode zwraca jeden z kodow produktu (ryzyko znajdujacy sie na wczesniejszej polisie)]
         * @return {PRODUCT_CODE|null} code jednego z produktow komunikacyjnych
         */
        this.getAnyCommunicationPreviouslySelectedProductCode = function() {
          var previouslySelectedRisks = self.getPreviouslySelectedVariants();
          //wybierany product - dowolny bo ta sama data
          //w dokupieniach nie powinno byc mozliwosci wejscia w naglowek komunikacji jesli nie bylo wybrane wczesniej ryzyko komunikacyjne 
          var product = null;
          lsnNg.forEach(previouslySelectedRisks, function(previouslySelectedRisk, riskName) {
            if (product !== null) {
              return false;
            }
            if ([CONSTANTS.PRODUCT_OCKOM, CONSTANTS.PRODUCT_ACKOM, CONSTANTS.PRODUCT_NNWKIP].indexOf(riskName) !== -1) {
              lsnNg.forEach(previouslySelectedRisk, function(val) { //iteracja po wariantach
                if (val) {
                  product = riskName;
                  return false;
                }

                return true; //continue
              });
            }

            return true; //continue
          });

          return product;
        };

        /**
         * zwraca obiekt z informacjami o wylaczonych/zablokowanych wariantach ryzyk, na podstawie informacji z poprzedniej wersji polisy oraz dataContainer.allowedRisks
         * np. dokupienia
         * UWAGA:
         * Obowiązują następujące reguły:
         * - jeśli brak informacji o wyborze danego produktu dla przekazanego objId na poprzedniej polisie, to nie zwracamy informacji na temat jego blokad (czyli wg niniejszej metody - taki produkt nie jest blokowany na żadnym z wariantów)
         * - jeśli istnieje informacja o wyborze ryzyka dla danego produktu na poprzedniej polisie, to sprawdzamy w dataContainer.allowedRisks, które warianty NIE są dla niego dostępne
         * Inaczej mówiąć - nie blokujemy ryzyk/warinatów dla nowych obiektów/podmiotów ubezpieczenia
         * @param  {[type]} productCode [description]
         * @param  {[type]} objectId    [description]
         * @return {Object} obiekt taki jak w getPreviouslySelectedVariants, z tym że true oznacza wyłączenie wariantu
         */
        this.getHistoricalDisabledVariants = function(productCode, objectId) {
          if (!appVariables.isSupplement) {
            return {};
          }
          var previouslySelected = self.getPreviouslySelectedVariants(productCode, objectId);
          productCode = productCode || null;
          objectId = objectId || null;
          /**
           * zwraca obiekt z informacjami o wylaczonych wariantach
           * @param  {String} prodCode kod produktu
           * @param  {String} objId id obiektu/podmiotu ubezpieczonego
           * @return {Object} {kod_wariantu: true|false[,...]} wszystkie warianty dla danego produktu z informacją czy zablokowany (true) czy nie (false)
           */
          var getDisabled = function(prodCode, objId) {
            var def = resourceHelper.getProdDef(CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[prodCode] ? CONSTANTS.SPECIAL_PRODUCTS_DEF_MAP[prodCode] : prodCode), //dla produktow specjalnych (nnw incognito, ogi_kra...) pobieramy definicje z ich "zwykłych" odpowiedników
              disabled = {},
              allowedVariants = dataContainerHelper.getAllowedProductVariants(prodCode, objId);
            angular.forEach(def.VARIANTLIST, function(variantDef) {
              disabled[variantDef.CODE] = (allowedVariants === null) ? true : (allowedVariants.indexOf(variantDef.CODE) === -1); //jesli brak danych na temat dozwolonych wariantów, to nie blokujemy żadnego
            });
            return disabled;
          };

          if (productCode === null) { //dla wsystkich produktów
            var disabledVnts = {};
            angular.forEach(previouslySelected, function(data, prod) {
              //dla każdego typu produktu pobieramy aktualnie wybrany obiekt/podmiot ubezpieczenia
              disabledVnts[prod] = getDisabled(prod, dataContainerHelper.getSelectedObjectId(resourceHelper.productType[prod]));
            });
            return disabledVnts;
          } else { //dla konkretnego produktu
            return getDisabled(productCode, objectId);
          }
        };

        /**
         * zwraca informacje o zaznaczonych wariantach dla danego produktu
         * @param  {String} productCode kod produktu
         * @param  {Object} productContainer dataContainer.selectedVariants[kod_produktu]
         * @param  {Number} selectedId aktualnie wybrany obiekt (np. dla pojazdu dataContainer.selectedVehicle)
         * @return {Object}                  [description]
         */
        this._getVariantSelectionForProduct = function(productCode, productContainer, selectedId) {
          var selectedVariants = {},
            prodDef = self.getProdDef(productCode);
          angular.forEach(prodDef.VARIANTLIST, function(variant) {
            selectedVariants[variant.CODE] = (variant.CODE === productContainer[selectedId][prodDef.CODE]);
          });
          return selectedVariants;
        };

        /**
         * zwraca definicje produktu z RESOURCES
         * @param  {String} productCode [description]
         * @return {Object}             [description]
         */
        this.getProdDef = function(productCode) {
          return resourceHelper.getProdDef(productCode);
        };

        /**
         * zwraca definicje wariantu dla produktu z RESOURCES
         * @param  {String} productCode [description]
         * @param  {String} variantCode [description]
         * @return {Object}             [description]
         */
        this.getProdVariantDef = function(productCode, variantCode) {
          return resourceHelper.getProdVariantDef(productCode, variantCode);
        };

        /**
         * ustawia wariant w selectedVariants
         * @param {String} productCode kod produktu
         * @param {String} variantCode kod wariantu
         * @param {*} value wartość, zależna od konfiguracji produktu (jeden czy wiele wariantów itd.)
         * @param {String} objectId
         * @param {Boolean} [omitPopups=false] czy pomijac wyskakujace popupy (np pytanie o zawod przy zazanczaniu nnw w mf)
         */
        this.setVariant = function(productCode, variantCode, value, objectId, omitPopups) { //eslint-disable-line consistent-return
          if (!self.isProductAvailableForObject(productCode, variantCode, objectId) &&
            (!dataContainerHelper.isProductSelected(productCode, objectId) || (angular.isDefined(value) && value !== null && value !== false))) //brak możliwości zaznaczenia produtu, ale chcemy pozwolić na odznaczenie (automatyczne odznaczenie też korzysta z tej metody)
          {
            return false;
          }
          var wasProductVariantSelected = self.isProductVariantSelected(productCode, variantCode),
            productType = resourceHelper.productType[productCode];
          if (angular.isUndefined(objectId)) {
            objectId = null;
          }

          if (objectId === null && (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || productType === CONSTANTS.PRODUCT_TYPE_VEHICLE || productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP)) {
            objectId = dataContainerHelper.getSelectedObjectId(productType);
          }
          //ustawiamy wartość
          if (productType === CONSTANTS.PRODUCT_TYPE_PERSON) {
            if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) {
              mainDataContainer.selectedVariants[productCode][variantCode] = value;
            } else {
              if (mainDataContainer.selectedVariants[productCode] !== variantCode) //jeśli wariant był już zaznaczony to nic nie robimy, bo przy wczytywaniu moglibyśmy za dużo akcji zarejestrować
              {
                mainDataContainer.selectedVariants[productCode] = variantCode;
              }
            }
          } else {
            if (mainDataContainer.selectedVariants[productType][objectId][productCode] !== variantCode) //jeśli wariant był już zaznaczony to nic nie robimy, bo przy wczytywaniu moglibyśmy za dużo akcji zarejestrować
            {
              mainDataContainer.selectedVariants[productType][objectId][productCode] = variantCode;
              if (productCode === CONSTANTS.PRODUCT_ACKOM && variantCode === null) //czyścimy informację o sposobie likwidacji szkody
              {
                var vehicle = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, objectId);
                if (angular.isObject(vehicle)) {
                  vehicle.deleteAdditionalData('liquidation');
                }

              }
              if (variantCode !== null && CONFIG.BEHAVIOR.oneVariantForSelectedProducts) { //sprowadzenie innych zaznaczonych produktow tego samego typu do takiego samego wariantu
                angular.forEach(resourceHelper.getProductsForType(productType), function(prdCode) {
                  if (prdCode !== productCode && mainDataContainer.selectedVariants[productType][objectId][prdCode] !== null) {
                    self.setVariant(prdCode, variantCode, value, objectId);
                  }
                });
              }
            }
          }
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && (productCode === CONSTANTS.PRODUCT_OGI || productCode === CONSTANTS.PRODUCT_KRA)) //jeśli zmieniamy wariant na ogniu lub kradzieży na III lub z III
          {
            if (variantCode === CONSTANTS.VARIANT_III) {
              mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_OGI] = variantCode;
              mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_KRA] = variantCode;
            } else if (variantCode !== null) {
              if (mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_OGI] === CONSTANTS.VARIANT_III) {
                mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_OGI] = variantCode;
              }
              if (mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_KRA] === CONSTANTS.VARIANT_III) {
                mainDataContainer.selectedVariants[productType][objectId][CONSTANTS.PRODUCT_KRA] = variantCode;
              }
            }
          }
          if (CONFIG.MODULES.additions) {
            addHelper.deleteUnavailable();
          }
          if (CONFIG.MODULES.extensions) {
            extensionHelper.deleteUnavailable();
          }
          if (!wasProductVariantSelected && !omitPopups) { //po zaznaczeniu wariantu odpalam ewentulane popupy
            self._showVariantSetPopups(productCode, variantCode, value, objectId);
          }
        };

        /**
         * Zwraca sposób likwidacji dla wybranego pojazdu (jeżeli pojazd nie jest wybrany to ta informacja jest bezpośrednio w dataContainerze)
         */
        this.getLiquidation = function(variant) {
          var liquidation = mainDataContainer.liquidation;
          var optionAvilable = true;
          if (parseInt(mainDataContainer.selectedVehicle, 10) > 0) {
            var vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
            liquidation = vehicle.getAdditionalData('liquidation');
          }
          //jeżeli dla wariantu nie ma już takiej opcji to wybieramy domyślny
          if (CONFIG.BEHAVIOR.liquidationVariantOption[variant].indexOf(liquidation) === -1) {
            optionAvilable = false;
          }
          if (!optionAvilable) {
            liquidation = CONFIG.BEHAVIOR.liquidationVariantOption[variant][0];
          }
          if (liquidation === null || angular.isUndefined(liquidation)) {
            //pierwszy z configa
            liquidation = CONFIG.BEHAVIOR.liquidationVariantOption[variant][0];
          }
          return liquidation;
        };

        /**
         * ustawia sposóļ likwidacji na wybranym pojeździe (jeżeli żaden nie został wybrany to ustawia bezpośrednio w DC)
         * @param  {[type]} liquidationType
         */
        this.setLiquidation = function(liquidationType) {
          if (parseInt(mainDataContainer.selectedVehicle, 10) > 0) {
            var vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
            vehicle.setAdditionalData(liquidationType, 'liquidation');
          } else {
            mainDataContainer.liquidation = liquidationType;
          }
        };

        /**
         * wyswietlenie popupa z informacją/pytaniem już po zaznaczeniu wariantu produktu
         * @param  {[type]} productCode [description]
         * @param  {[type]} variantCode [description]
         * @param  {[type]} value       [description]
         * @param  {[type]} objectId    [description]
         */
        this._showVariantSetPopups = function(productCode, variantCode, value, objectId) {
          if (productCode === CONSTANTS.PRODUCT_NNW && (value === true || angular.isString(value))) { //sprawdzamy moment zaznaczenia wariantu
            selectionPopups.checkAndShowIncomeLossPopup(variantCode, objectId, self);
          }
        };

        /**
         * ustawia wariant dla wszystkich produktow tego samego typu co produkt o kodzie productCode
         * @param {[type]} productCode [description]
         * @param {[type]} variantCode [description]
         * @param {[type]} value       [description]
         * @param {[type]} objectId    [description]
         */
        this.setVariantForAllProducts = function(productCode, variantCode, value, objectId) {
          var productType = resourceHelper.productType[productCode];
          if (productType !== CONSTANTS.PRODUCT_TYPE_PERSON) {
            var allSameTypeProducts = resourceHelper.getProductsForType(productType);
            angular.forEach(allSameTypeProducts, function(prdCode) {
              self.setVariant(prdCode, variantCode, value, objectId);
            });
          }
        };
        /**
         * czy produkt dostepny dla wybraego obiektu ubezpieczenia
         * @param  {[type]}  productCode [description]
         * @param  {[type]}  variantCode [description]
         * @param  {[type]}  objectId    [description]
         * @return {Boolean}             [description]
         */
        this.isProductAvailableForObject = function(productCode, variantCode, objectId) {
          var productType = resourceHelper.productType[productCode];
          if (angular.isUndefined(objectId)) {
            objectId = null;
          }

          if (objectId === null && (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || productType === CONSTANTS.PRODUCT_TYPE_VEHICLE)) {
            objectId = dataContainerHelper.getSelectedObjectId(productType);
          }

          return true;
        };

        /**
         * sprawdza mozliwosc i jesli mozliwe to zaznacza wariant produktu lub odznacza jesli byl zaznaczony
         * @param  {[type]} productCode [description]
         * @param  {[type]} variantCode [description]
         * @return {[type]}             [description]
         */
        this.toggleProductVariant = function(productCode, variantCode) {
          var productType = resourceHelper.productType[productCode];
          if (appVariables.readOnly || !mainDataContainer.allowedProducts[productCode]) {
            return false;
          }
          //jak zaznaczone wszystkie pojazdy/lokalizacje to blokujemy zmianę produktów powiązanych
          if (
            (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION && mainDataContainer.selectedLocalization === CONSTANTS.ALL_OBJECTS) ||
            (productType === CONSTANTS.PRODUCT_TYPE_VEHICLE && mainDataContainer.selectedVehicle === CONSTANTS.ALL_OBJECTS) ||
            (productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP && mainDataContainer.selectedSubject === CONSTANTS.ALL_OBJECTS)) //nie można zaznaczać jak jest wiele lokalizacji/pojazdów/osob-grup
          {
            return false;
          }

          if (self.getDisabledVariant(productCode).indexOf(variantCode) !== -1) { //niedostępny aktulanie wariant produktu
            return false;
          }

          //jeśli wiele wariantów na OCZP/NNW i wiele osób to nie można zmieniać z matrycy
          if (productType === CONSTANTS.PRODUCT_TYPE_PERSON && CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1 && dataContainerHelper.getProductPeronsCount(productCode) > 1) {
            return false;
          }

          if (productType === CONSTANTS.PRODUCT_TYPE_PERSON && productCode === CONSTANTS.PRODUCT_NNW && (variantCode === CONSTANTS.VARIANT_II || variantCode === CONSTANTS.VARIANT_III) && mainDataContainer.nnwIncognito !== null) {
            return false;
          }

          var selectedObject = dataContainerHelper.getSelectedObjectId(productType);
          if (productType === CONSTANTS.PRODUCT_TYPE_PERSON) { //jesli ryzyko osobowe, gdy ubezpieczona jest 1 osoba, to sprawdamy w jej kontekscie
            var insuredIds = Object.keys(dataContainerHelper.getPersonsForPersonalProduct(productCode));
            if (insuredIds.length === 1) {
              selectedObject = insuredIds[0];
            }
          }
          if (self.isHistoricallyDisabled(productCode, variantCode, selectedObject)) {
            return false;
          }

          if (self.isProductVariantSelected(productCode, variantCode)) { //jesli odznaczamy istniejący wariant
            if (appVariables.isSupplement) { //przy dokupieniu, jesli odznaczamy wariant a na poprzedniej polisie był juz wybrany, to musimy go przywrócić
              //przy wspoldzielonym wariancie ognia/kradziezy - jesli na poprzedniej polisie byl wybrany wspolny wariant III, to nie mozemy go odznaczac.
              if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && [CONSTANTS.PRODUCT_OGI_KRA, CONSTANTS.PRODUCT_KRA, CONSTANTS.PRODUCT_OGI].indexOf(productCode) !== -1 && self.getPrevVariant(CONSTANTS.PRODUCT_OGI_KRA) !== CONSTANTS.VARIANT_III) {
                self._unselectProductWithPreviousSelection(CONSTANTS.PRODUCT_OGI, variantCode);
                self._unselectProductWithPreviousSelection(CONSTANTS.PRODUCT_KRA, variantCode);
              } else {
                self._unselectProductWithPreviousSelection(productCode, variantCode);
              }
            } else {
              self.selectProductMatrix(productCode, variantCode, false);
            }
          } else { //zaznaczamy nowy/zmieniamy wariant
            self.selectProductMatrix(productCode, variantCode, true);
            //przypadek DiD i wspoldzielonego wariantu
            if (appVariables.isSupplement && [CONSTANTS.PRODUCT_KRA, CONSTANTS.PRODUCT_OGI].indexOf(productCode) !== -1 && CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant) {
              var secondProd = (productCode === CONSTANTS.PRODUCT_KRA ? CONSTANTS.PRODUCT_OGI : CONSTANTS.PRODUCT_KRA),
                prevVariant = self.getPrevVariant(secondProd);
              if (prevVariant !== null && angular.equals(dataContainerHelper.getVariantsForProduct(secondProd), {})) {
                self.selectProductMatrix(secondProd, prevVariant, true);
              }
            }
          }
          return true;
        };

        this._unselectProductWithPreviousSelection = function(productCode, variantCode) {
          var prevSelected = self.getPreviouslySelectedVariants(productCode),
            anyPrevSelected = false;
          angular.forEach(prevSelected, function(selected, vCode) {
            if (vCode === CONSTANTS.RISK_FLOOD) { //wyjątkowo może pokazać się tu powódź przy dokupieniach. Nie interesuje nas ona w tej metodzie.
              return true;
            }
            var allowUnselect = (vCode !== variantCode);
            if (selected) {
              if (!anyPrevSelected && allowUnselect) { //aby wywolac ponizsze tylko raz
                self.selectProductMatrix(productCode, variantCode, false);
              }
              anyPrevSelected = true;
              if (allowUnselect) {
                self.selectProductMatrix(productCode, vCode, true);
              }
            }
            return true;
          });
          if (!anyPrevSelected) { //gdy poprzedni nie byl wybrany zaden wariant
            self.selectProductMatrix(productCode, variantCode, false);
          }
        };

        /**
         * zwraca pierwszy wyszukany wariant poprzednio zaznaczony na polisie
         * @param  {String} productCode kod produktu
         * @return {String|null} null gdy nieznaleziono
         */
        this.getPrevVariant = function(productCode) {
          var allPrevSelected = self.getPreviouslySelectedVariants(productCode),
            prevSelected = null;
          lsnNg.forEach(allPrevSelected, function(vSelected, vCode) {
            if (vSelected) {
              prevSelected = vCode;
              return false;
            }
            return true;
          });
          return prevSelected;
        };

        /**
         * wybiera produkt globalnie/na matrycy głównej
         * @param  {String} productCode [description]
         * @param  {String} variantCode [description]
         * @param  {Boolean|String} select określenie wyboru
         * @param  {Boolean} tarifficate czy staryfikować wniosek
         */
        this.selectProductMatrix = function(productCode, variantCode, select, tarifficate) {
          if (select && angular.isDefined(CONFIG.BEHAVIOR.tarifficationLockProducts[productCode]) && CONFIG.BEHAVIOR.tafficationLockProducts[productCode].indexOf(variantCode) !== -1) {
            tarifficate = false;
          }

          if (angular.isUndefined(tarifficate)) {
            tarifficate = true;
          }
          var productType = resourceHelper.productType[productCode];

          var value = null;
          if (select) {
            value = variantCode;
          }

          if (productType === CONSTANTS.PRODUCT_TYPE_PERSON) {
            //zmiana wariantu rozegrana po stonie helpera produktu, który zrobi rzutowanie sumy wariantów osób na matrycę
            var anyPerson = self.setAllPersonsVariant(productCode, variantCode, select);

            if (!anyPerson) //na produkcie nie ma żandej osoby, więc sami dbamy o poprawny wariant na matrycy
            {
              if (select && CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1 && angular.isDefined(CONFIG.BEHAVIOR.productVariantsConflict) && angular.isDefined(CONFIG.BEHAVIOR.productVariantsConflict[productCode]) &&
                CONFIG.BEHAVIOR.productVariantsConflict[productCode].indexOf(variantCode) !== -1) {
                var productVariants = dataContainerHelper.getVariantsForProduct(productCode);
                angular.forEach(CONFIG.BEHAVIOR.productVariantsConflict[productCode], function(conflictVariantCode) {
                  if (conflictVariantCode !== variantCode && productVariants[conflictVariantCode]) //na osobie jest już wariant które nie może wystąpić z właśnie dodawanym wariantem
                  {
                    self.setVariant(productCode, conflictVariantCode, false);
                  }
                });
              }

              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) {
                self.setVariant(productCode, variantCode, select);
              } else {
                self.setVariant(productCode, value);
              }
            }
          } else {
            if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && resourceHelper.productType[productCode] === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
              var fireVariants = dataContainerHelper.getVariantsForProduct(CONSTANTS.PRODUCT_OGI);
              if (productCode === CONSTANTS.PRODUCT_OGI_KRA || (!select && fireVariants[CONSTANTS.VARIANT_III])) {
                self.setVariant(CONSTANTS.PRODUCT_OGI, value);
                self.setVariant(CONSTANTS.PRODUCT_KRA, value);
              } else if (select && (productCode === CONSTANTS.PRODUCT_OGI || productCode === CONSTANTS.PRODUCT_KRA) && fireVariants[CONSTANTS.VARIANT_III]) {
                self.setVariant(productCode === CONSTANTS.PRODUCT_OGI ? CONSTANTS.PRODUCT_KRA : CONSTANTS.PRODUCT_OGI, null);
              }
            }
            if (productCode !== CONSTANTS.PRODUCT_OGI_KRA) {
              if (CONFIG.BEHAVIOR.allProductsAtOnce) {
                self.setVariantForAllProducts(productCode, value);
              } else {
                self.setVariant(productCode, value);
              }
            }
          }

          if (productCode === CONSTANTS.PRODUCT_ACKOM && select && CONFIG.BEHAVIOR.liquidationVariant.indexOf(variantCode) !== -1 && self.isProductVariantSelected(productCode, variantCode)) {
            if (selectionPopups.showLiquidationPopup(variantCode, true)) {
              tarifficate = false;
            }
          }

          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
          actionHelper.runAction('matrixProductSelected', productCode, variantCode, select);
        };

        /**
         * czy wybrano produkt we wskazanym wariancie
         * @param  {String}  productCode [description]
         * @param  {String}  variantCode [description]
         * @return {Boolean} true, gdy wybrano
         */
        this.isProductVariantSelected = function(productCode, variantCode) {
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && productCode === CONSTANTS.PRODUCT_OGI_KRA) {
            productCode = CONSTANTS.PRODUCT_OGI;
          }
          var variants = dataContainerHelper.getVariantsForProduct(productCode);
          if (variants[variantCode]) {
            return true;
          }
          return false;
        };

        //usuwa warianty dla pojazdu/lokalizacji
        this.deleteObjectVariants = function(type, id) {
          //usuwamy warianty produktów
          delete mainDataContainer.selectedVariants[type][id];

          //usuwamy dodatki i rozszerzenia
          if (CONFIG.MODULES.additions) {
            addHelper.deleteUnavailable();
          }
          if (CONFIG.MODULES.extensions) {
            extensionHelper.deleteUnavailable();
          }

          //zmieniamy zaznaczenie
          if (type !== CONSTANTS.PRODUCT_TYPE_PERSON && parseInt(id, 10) === parseInt(dataContainerHelper.getSelectedObjectId(type), 10)) {
            var firstObject = null;
            lsnNg.forEach(mainDataContainer.selectedVariants[type], function(obj, k) {
              firstObject = parseInt(k, 10);
              return false;
            });
            dataContainerHelper.selectObject(type, firstObject === null ? CONSTANTS.NO_OBJECT : firstObject);
          }
        };

        /**
         * ustawia inicjalne zaznaczenia ryzyk/dodatków na podstawie danych z dataManagera
         */
        this.setPreselection = function() {
          if (mainDataContainer.preselectedVariants.length > 0) {
            angular.forEach(mainDataContainer.preselectedVariants, function(preProd) {
              if (angular.equals(self.getProdVariantDef(preProd.code, preProd.variant), {})) {
                preProd.variant = CONSTANTS.VARIANT_I;
              }
              self.setVariant(preProd.code, preProd.variant, true);
            });
            mainDataContainer.preselectedVariants = [];
          }
          if (mainDataContainer.preselectedAdditions.length > 0) {
            angular.forEach(mainDataContainer.preselectedAdditions, function(preAdd) {
              if (angular.isString(preAdd.variant)) {
                addHelper.addAdd(preAdd.code, undefined, preAdd.variant);
              } else {
                addHelper.addAdd(preAdd.code);
              }
            });
            mainDataContainer.preselectedAdditions = [];
          }
        };

        /**
         * czy wybrano jakikolwiek produkt z danego typu
         * @param  {String}  productType
         * @return {Boolean}
         */
        this.isSelectedAnyProductOn = function(productType) {
          return dataContainerHelper.isSelectedAnyProductOn(productType);
        };

        /**
         * [getSelectedAdds description]
         * @return {[type]} [description]
         */
        this.getSelectedAdds = function() {
          var selectedAdds = {};
          lsnNg.forEach(RESOURCES.PRODADD, function(addData) {
            var addSelected = false;
            if (addData.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON) //jeśli jest choć jedna osoba to zaznaczony
            {
              if (mainDataContainer.selectedAdditions[addData.CODE].length > 0) {
                addSelected = true;
              }
            } else {
              if (typeof addData.VARIANTS !== 'undefined') //dodatek z wariantami, jeśli chodź jeden wariant dla wybranego obiektu to zaznaczony
              {
                lsnNg.forEach(addData.VARIANTS, function(variant) {
                  if (self.isAddSelected(addData.CODE, undefined, variant.CODE)) {
                    addSelected = true;
                    return false;
                  }
                  return true; //continue
                });
              } else {
                if (self.isAddSelected(addData.CODE)) {
                  addSelected = true;
                }
              }
            }
            if (addSelected) {
              selectedAdds[addData.CODE] = true;
            }
          });
          return selectedAdds;
        };

        /**
         * czy dodaetk jest wybrany
         * @param  {String}  code kod dodatku
         * @param  {[type]}  objectId clientId obiektu
         * @param  {[type]}  variant wairnt (opcja)
         * @return {Boolean} true gdy zaznaczony
         */
        this.isAddSelected = function(code, objectId, variant) {
          return addHelper.isAddSelected(code, objectId, variant);
        };

        /**
         * Zaznaczenie/odznaczenie dodatku
         * @param  {string} addCode     [description]
         * @param  {int} objectId    [description]
         * @param  {string} variantCode [description]
         * @return {AddHelper}             [description]
         */
        this.toggleAdd = function(addCode, objectId, variantCode, tarifficate) {
          if (typeof tarifficate !== 'boolean') {
            tarifficate = true;
          }
          if (self.isAddSelected(addCode, objectId, variantCode)) {
            if (appVariables.isSupplement) {
              //w trybie dokupień odznaczamy wariant dodatku z jednoczesnym zaznaczeniem wariantu z poprzedniej polisy
              self._unselectAdditionVariantWithPreviousSelection(addCode, objectId, variantCode);
            } else {
              addHelper.deleteAdd(addCode, objectId, variantCode);
            }
          } else {
            addHelper.addAdd(addCode, objectId, variantCode);
          }

          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
          return self;
        };

        /**
         * Zaznaczenie/odznaczenie rozszerzenia
         * @param  {string} extCode     [description]
         * @param  {int} objectId    [description]
         */
        this.toggleExt = function(extCode, objectId, tarifficate) {
          if (typeof tarifficate !== 'boolean') {
            tarifficate = true;
          }
          if (self.isExtSelected(extCode, objectId)) {
            extensionHelper.deleteExt(extCode, objectId);
          } else {
            extensionHelper.addExt(extCode, objectId);
          }
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        /**
         * czy rozszerzenie wybrane
         * @param  {String}  code     [description]
         * @param  {String}  objectId [description]
         * @param  {String}  variant  [description]
         * @return {Boolean}
         */
        this.isExtSelected = function(code, objectId) {
          return extensionHelper.isExtSelected(code, objectId);
        };

        /**
         * zwraca zaznaczone rozszerzenia
         * @return {Object} {kod_rozszerzenia: true[,...]}
         */
        this.getSelectedExts = function() {
          var selectedExts = {};
          lsnNg.forEach(RESOURCES.EXTENSIONDEF, function(extData) {
            var extSelected = self.isExtSelected(extData.CODE);
            if (extSelected) {
              selectedExts[extData.CODE] = true;
            }
          });
          return selectedExts;
        };

        /**
         * Sprawdzanie czy wybrane wcześniej produkty na obiekcie nadal są dostąpne. Jeśli nie, to odznaczamy je.
         * @param  {String} objectId
         * @param  {String} objectType
         */
        this.updateProductsAvailability = function(objectId, objectType) { //eslint-disable-line consistent-return
          if (objectId === CONSTANTS.ALL_OBJECTS || objectId === CONSTANTS.NO_OBJECT || (objectType !== CONSTANTS.PRODUCT_TYPE_VEHICLE && objectType !== CONSTANTS.PRODUCT_TYPE_LOCALIZATION)) {
            return false;
          }

          angular.forEach(mainDataContainer.selectedVariants[objectType][objectId], function(objs, productCode) {
            var variants = dataContainerHelper.getVariantsForProduct(productCode, objectId);
            angular.forEach(variants, function(selected, variantCode) {
              if (selected && !self.isProductAvailableForObject(productCode, variantCode, objectId)) {
                if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //odznaczamy
                {
                  self.setVariant(productCode, variantCode, false, objectId);
                } else {
                  self.setVariant(productCode, null, null, objectId);
                }
              }
            });
          });
        };


        /**
         * przełączanie wariantu ubezpieczenia dla osoby
         * @param  {String} personId
         * @param  {String} variant
         */
        this.togglePersonVariant = function(personId, productCode, variant) {
          if (appVariables.readOnly || self.isHistoricallyDisabled(productCode, variant, personId)) {
            return false;
          }
          var variants;
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID && productCode === CONSTANTS.PRODUCT_NNW) {
            variants = mainDataContainer.nnwIncognito.variants;
          } else {
            var persons = dataContainerHelper.getPersonsForPersonalProduct(productCode);
            variants = persons[personId].variants;
          }

          var unselectMethod = (appVariables.isSupplement ? self._unselectPersonVariantWithPreviousSelection : self.unselectPersonVariant);
          if (self.isMultiVariantProduct(productCode)) //wiele wariantów
          {
            if (variants[variant]) {
              unselectMethod(personId, productCode, variant);
            } else {
              self.selectPersonVariant(personId, productCode, variant);
            }
          } else //jeden wariant
          {
            if (variants === variant) {
              unselectMethod(personId, productCode, variant);
            } else {
              self.selectPersonVariant(personId, productCode, variant);
            }
          }
          actionHelper.runAction('saveApplication');
          return true;
        };

        /**
         * odznacza wariant produktu dla osoby z jednocesnym zaznaczeniem wariantu z poprzedniej polisy
         * @param  {[type]} personId    [description]
         * @param  {[type]} productCode [description]
         * @param  {[type]} variantCode [description]
         * @return {[type]}             [description]
         */
        this._unselectPersonVariantWithPreviousSelection = function(personId, productCode, variantCode) {
          var prevSelected = self.getPreviouslySelectedVariants(productCode, personId),
            anyPrevSelected = false;
          angular.forEach(prevSelected, function(selected, vCode) {
            var allowUnselect = (vCode !== variantCode);
            if (selected) {
              if (!anyPrevSelected && allowUnselect) { //aby wywolac ponizsze tylko raz
                self.unselectPersonVariant(personId, productCode, variantCode);
              }
              anyPrevSelected = true;
              if (allowUnselect) {
                self.selectPersonVariant(personId, productCode, vCode);
              }
            }
          });
          if (!anyPrevSelected) { //gdy poprzedni nie byl wybrany zaden wariant
            self.unselectPersonVariant(personId, productCode, variantCode);
          }
        };

        /**
         * Odznacza wariant dodatku z jednoczesnym zaznaczeniem wariantu z poprzedniej polisy
         * @param  {[type]} addCode     [description]
         * @param  {[type]} objectId    [description]
         * @param  {[type]} variantCode [description]
         */
        this._unselectAdditionVariantWithPreviousSelection = function(addCode, objectId, variantCode) {
          var addDef = resourceHelper.getAddDef(addCode);
          var prevSelected = self.getPreviouslySelectedAdds(),
            anyPrevSelected = false;

          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PERSON && prevSelected[addCode]) {
            anyPrevSelected = !!prevSelected[addCode][objectId];
          } else {
            angular.forEach(prevSelected[addCode], function(selected, vCode) {
              var allowUnselect = (vCode !== variantCode);
              if (selected) {
                if (!anyPrevSelected && allowUnselect) { //aby wywolac ponizsze tylko raz
                  addHelper.deleteAdd(addCode, objectId, variantCode);
                }
                anyPrevSelected = true;
                if (allowUnselect) {
                  addHelper.addAdd(addCode, objectId, vCode);
                }
              }
            });
          }

          if (!anyPrevSelected) { //gdy poprzednio nie byl wybrany zaden wariant
            addHelper.deleteAdd(addCode, objectId, variantCode);
          }
        };

        /**
         * czy produkt jest produkctem, dla którego można zaznaczyć wiele wariantów ubezpieczenia
         * @return {Boolean} true gdy tak
         */
        this.isMultiVariantProduct = function(productCode) {
          return CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1;
        };

        /**
         * czy wariant ubezpieczenia jest dostępny dla osoby
         * @param  {String}  variant
         * @param  {String}  personId
         * @return {Boolean} true gdy dostępny
         */
        this.isVariantAllowedForPerson = function(productCode, variant, personId) {
          //sprawdzamy czy wariant jest tylko dla głównego ubezpieczonego
          return !(angular.isDefined(CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[productCode]) && CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[productCode].indexOf(variant) !== -1 && mainDataContainer.mainInsuredId !== personId);
        };

        /**
         * zaznacza wariant ubezpieczenia dla osoby
         * @param  {String} personId
         * @param  {String} variant
         * @return {Boolean} true gdy zaznaczono, false gdy walidacja zablokowała zaznaczenie
         */
        this.selectPersonVariant = function(personId, productCode, variant) {
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID && productCode === CONSTANTS.PRODUCT_NNW) {
            if (variant !== CONSTANTS.VARIANT_I) //forma bezimienna może mieć tylko I wariant
            {
              return false;
            }
            mainDataContainer.nnwIncognito.variants[variant] = true;
          } else {
            var person = mainDataContainer.persons[personId];
            var prodPersonData = person.getAdditionalData(productCode);

            if (!self.isVariantAllowedForPerson(productCode, variant, personId)) //wariant III dostępny tylko dla głównego ubezpieczonego
            {
              return false;
            }

            if (self.isMultiVariantProduct(productCode)) { //mozliwosc zaznaczania wielu wariantów
              //wiele wariantów, ale nie wszystkie mogą wystąpić razem na jednej osobie
              if (angular.isDefined(CONFIG.BEHAVIOR.productVariantsConflict[productCode]) &&
                CONFIG.BEHAVIOR.productVariantsConflict[productCode].indexOf(variant) !== -1) {
                angular.forEach(CONFIG.BEHAVIOR.productVariantsConflict[productCode], function(conflictVariantCode) {
                  if (conflictVariantCode !== variant && prodPersonData.variants[conflictVariantCode]) //na osobie jest już wariant które nie może wystąpić z właśnie dodawanym wariantem
                  {
                    self.unselectPersonVariant(personId, productCode, conflictVariantCode);
                  }
                });
              }
              person.setAdditionalData(true, [productCode, 'variants', variant]);
            } else { //mozliwosc zaznaczenia tylko 1 wariantu
              person.setAdditionalData(variant, [productCode, 'variants']);
            }
          }
          //zaznaczamy na matrycy
          if (self.isMultiVariantProduct(productCode)) {
            self.setVariant(productCode, variant, true);
          } else {
            //jeśli inne osoby miały jakiś zaznaczony wariant to zmieniamy go na ten właśnie wybrany
            var anyOther = false;
            angular.forEach(dataContainerHelper.getPersonsForPersonalProduct(productCode), function(oczpData, otherPersonId) {
              if (parseInt(otherPersonId, 10) !== parseInt(personId, 10)) {
                var otherPerson = mainDataContainer.persons[otherPersonId];
                if (otherPerson.getAdditionalData([productCode, 'variants']) !== null) {
                  otherPerson.setAdditionalData(variant, [productCode, 'variants']);
                  anyOther = true;
                }
              }
            });
            if (anyOther) {
              actionHelper.runAction('refreshAllInsured', productCode);
            }
            self.setVariant(productCode, variant);
          }
          actionHelper.runAction('personVariantChanged', productCode, personId);
          actionHelper.runAction('tarifficate');
          return true;
        };

        /**
         * ustawia waraint dla wszystkich ubezpieczonych w produkcie
         * @param {String} productCode
         * @param {String} variant
         * @param {Boolean} select czy zaznaczyć-true czy odznaczyć-false
         */
        this.setAllPersonsVariant = function(productCode, variant, select) {
          var anyPerson = false;
          angular.forEach(dataContainerHelper.getPersonsForPersonalProduct(productCode), function(prodData, personId) {
            anyPerson = true;
            if (select) {
              self.selectPersonVariant(personId, productCode, variant);
            } else {
              self.unselectPersonVariant(personId, productCode, variant);
            }
          });
          if (productCode === CONSTANTS.PRODUCT_NNW && mainDataContainer.nnwIncognito !== null) {
            if (!mainDataContainer.nnwIncognito.variants[variant]) {
              self.selectPersonVariant(CONSTANTS.NNW_INCOGNITO_PERSON_ID, productCode, variant);
            } else {
              self.unselectPersonVariant(CONSTANTS.NNW_INCOGNITO_PERSON_ID, productCode, variant);
            }
          }
          return anyPerson;
        };


        /**
         * odznacza osobę z wariantu ubezpieczenia
         * @param  {String} personId
         * @param  {String} productCode
         * @param  {String} variant
         */
        this.unselectPersonVariant = function(personId, productCode, variant) {
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
            mainDataContainer.nnwIncognito.variants[variant] = false;
          } else {
            var person = mainDataContainer.persons[personId];
            if (self.isMultiVariantProduct(productCode)) {
              person.setAdditionalData(false, [productCode, 'variants', variant]);
            } else {
              person.setAdditionalData(null, [productCode, 'variants']);
            }
          }

          var matrixUnselected = self.tryUnselectPersonalProduct(productCode, variant);

          if (!matrixUnselected && CONFIG.MODULES.additions) {
            //usunięto ryzyko trzeba uaktualnić dodatki
            addHelper.deleteUnavailable();
          }

          actionHelper.runAction('personVariantChanged', productCode, personId);
          actionHelper.runAction('tarifficate');
        };

        /**
         * próba globalnego (na matrycy) odznaczenia wariantu produktu osobowego
         * @param  {String} productCode kod produktu osobowego
         * @param  {String} [variant] kod wariantu
         * @return {Boolean} true, gdy odznaczono chociaz 1 wariant
         */
        this.tryUnselectPersonalProduct = function(productCode, variant) {
          var variantsToUnselect = {}, //warainty które będziemy sprawdzać i ewentualnie odznaczać
            isMultiVariant = self.isMultiVariantProduct(productCode),
            anyUnselected = false;

          if (!variant) {
            angular.forEach(resourceHelper.getProdDef(productCode).VARIANTLIST, function(vDef) {
              variantsToUnselect[vDef.CODE] = true;
            });
          } else {
            variantsToUnselect[variant] = true;
          }

          lsnNg.forEach(variantsToUnselect, function(unselect, vCode) {
            if (productCode === CONSTANTS.PRODUCT_NNW && mainDataContainer.nnwIncognito !== null && mainDataContainer.nnwIncognito.variants[vCode]) {
              variantsToUnselect[vCode] = false;
              return true;
            }
            //jeśli jakas osoba ma zaznaczony wariantu to zostawiamy go na matrycy
            lsnNg.forEach(dataContainerHelper.getPersonsForPersonalProduct(productCode), function(data) {
              if ((isMultiVariant && data.variants[vCode]) || (!isMultiVariant && data.variants === vCode)) {
                variantsToUnselect[vCode] = false;
                return false;
              }
              return true;
            });
            return true;
          });

          angular.forEach(variantsToUnselect, function(unselect, vCode) {
            if (unselect) {
              if (isMultiVariant) {
                self.setVariant(productCode, vCode, false);
              } else {
                self.setVariant(productCode, null);
              }
            }
          });

          return anyUnselected;
        };

        /*
         * uruchamia modal to wprowadzenie numeru zielonej karty
         */
        this.enterVehicleZkNumber = function() {
          sp2CommonHelper.showModal('vehicleZkModal');
        };

        /**
         * [getDisabledVariant pobiera nie dostepne warianty]
         * @param  {[type]} productCode [kod produktu]
         * @return {String[]} tablica nieaktywnych wariantów
         */
        this.getDisabledVariant = function(productCode) {
          if ([CONSTANTS.PRODUCT_OGI, CONSTANTS.PRODUCT_KRA, CONSTANTS.PRODUCT_OGI_KRA].indexOf(productCode) !== -1 && [CONSTANTS.ALL_OBJECTS, CONSTANTS.NO_OBJECT].indexOf(mainDataContainer.selectedLocalization) === -1) {
            var disabledVariant = [CONSTANTS.VARIANT_I, CONSTANTS.VARIANT_II, CONSTANTS.VARIANT_III],
              selectedLocalizationType = mainDataContainer.localizations[mainDataContainer.selectedLocalization].type;

            var selectedTypeDef = resourceHelper.getLocalizationTypeDef(selectedLocalizationType);

            if (angular.isDefined(selectedTypeDef.restrictProductsVariants) && angular.isArray(selectedTypeDef.restrictProductsVariants)) {
              return disabledVariant.filter(function(item) {
                return selectedTypeDef.restrictProductsVariants.indexOf(item) === -1;
              });
            } else {
              return [];
            }
          } else {
            return [];
          }
        };

        /**
         * zwraca obiekt z informacją które warianty są niezmienne - nie można ich odznaczyć ani zaznaczyć (na podstawie historycznie blokowanych wariantów, poprzednio wybranych i aktualnie wybranych)
         * @param  {Object|null} disabledVariants wyłączone warianty (self.getHistoricalDisabledVariants(productCode, objectId))
         * @param  {Object|null} previouslySelectedVariants poprzednio wybrane warianty (dataContainerHelper.getPreviousVariantsForProduct(productCode, objectId))
         * @param  {Object|null} selectedVariants wybrane aktualnie warianty (dataContainerHelper.getVariantsForProduct(productCode, objectId))
         * @param {String} [productCode] kod produktu
         * @param {String} [objectId] id obiektu/podmiotu ubezpieczonego
         * @return {Object} {} gdy brak danych lub obiekt postaci {kod_variantu: true|false[,...]} gdzie true oznacza niezmienny wariant
         */
        this.getUnchangeableVariants = function(disabledVariants, previouslySelectedVariants, selectedVariants) {
          var unchangeable = {},
            enabledVariants = [];
          //jesli wariant zablokowany lub (aktualnie wybrany i zgodny z tym z poprzedniej wersji polisy) to blokujemy jego zmianę
          angular.forEach(disabledVariants, function(disabled, variant) {
            unchangeable[variant] = disabled || (selectedVariants[variant] && previouslySelectedVariants[variant]);
            if (!disabled) {
              enabledVariants.push(variant);
            }
          });
          if (enabledVariants.length === 1 && !angular.equals({}, previouslySelectedVariants)) {
            unchangeable[enabledVariants[0]] = true;
          }
          return unchangeable;
        };

      };

      return new SelectionHelper();
    }
  ]);
