angular.module('salesPath2')
  .service('localizationHelper', ['$rootScope', 'EstateModel', 'mainDataContainer', 'CONSTANTS', 'CONFIG', 'ihestiaTrimInfo', '$state', '$sce', 'AddressModelConstants', 'addHelper', 'extensionHelper',
    '$timeout', 'actionHelper', 'lsnDeepExtend', 'sp2SelectionHelper', 'resourceHelper', 'dataContainerHelper', 'appVariables', 'allowedChangesHelper',
    function($rootScope, EstateModel, mainDataContainer, CONSTANTS, CONFIG, ihestiaTrimInfo, $state, $sce, AddressModelConstants, addHelper, extensionHelper, $timeout, actionHelper, lsnDeepExtend, selectionHelper, resourceHelper, dataContainerHelper, appVariables, allowedChangesHelper) { // eslint-disable-line angular/di
      var LocalizationHelper = function() {
        var self = this;
        var DEFAULT_INFO_HEADER_SETTINGS = {
          elements: [{
            field: 'postalCode'
          }, {
            field: 'city',
            fieldSeparator: ', ',
            optionalLineBreak: true, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
            forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
          }, {
            field: 'streetPrefix'
          }, {
            field: 'street'
          }, {
            field: 'house',
            fieldSeparator: ''
          }, {
            field: 'apartment',
            prefix: '/',
            lastInLine: true
          }],
          maxLength: 19,
          html: true,
          doTruncate: false
        };

        var DEFAULT_INFO_HEADER_SETTINGS_NO_STREET = {
          elements: [{
            field: 'postalCode'
          }, {
            field: 'city',
            fieldSeparator: ', ',
            optionalLineBreak: false, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
            forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
          }, {
            field: 'house',
            fieldSeparator: ''
          }, {
            field: 'apartment',
            prefix: '/',
            lastInLine: true
          }],
          maxLength: 19,
          html: true,
          doTruncate: true
        };

        this.firstLocalizationCompletelyAdded = false;

        this.saveLocalization = function(id, localizationData) {

          if (CONFIG.BEHAVIOR.isSingleProduct && mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][mainDataContainer.selectedLocalization][CONSTANTS.PRODUCT_OGI] === null) {
            var availableVariant = resourceHelper.getFirstVariantForProduct(CONSTANTS.PRODUCT_OGI);
            selectionHelper.selectVariant(CONSTANTS.PRODUCT_OGI, availableVariant.CODE); //zawsze mamy wybrany wariant pierwszy dla ognia
          }

          if (angular.isUndefined(id)) {
            id = null;
          }
          var selectLocalization = false;
          if (dataContainerHelper.getLocalizationCount() === 0 || id === null) {
            selectLocalization = true;
          }
          var localization, type = 'add';
          if (id !== null) {
            type = 'edit';
            localization = mainDataContainer.localizations[id];
          } else {
            id = dataContainerHelper.getNextLocalizationId();
            localization = new EstateModel();
            localization.metaData.set('clientId', id);
            self.setDefaultLocalizationAdditionalData(localization);
            mainDataContainer.localizations[id] = localization;
          }
          if (angular.isDefined(localizationData.metaData)) {
            delete localizationData.metaData;
          }
          localization.setData(localizationData);
          if (selectLocalization) {
            self.selectLocalization(localization.metaData.get('clientId'));
          }

          actionHelper.runAction('saveApplication');

          if (type === 'add') {
            $timeout(function() {
              actionHelper.runAction('localizationAdded', {
                id: id
              });
            }, 0);
          } else if (type === 'edit') {
            $timeout(function() {
              actionHelper.runAction('localizationEdited', {
                id: id
              });
            }, 0);
          }
          self.selectLocalization(id);
          self.firstLocalizationCompletelyAdded = true;
        };

        this.selectLocalization = function(localizationId) {
          dataContainerHelper.selectLocalization(localizationId);

          $timeout(function() {
            actionHelper.runAction('localizationSelected', {
              id: localizationId
            });
          }, 0);
        };

        //ustawianie domyślnych wartości dodatkowych
        this.setDefaultLocalizationAdditionalData = function(localization) {

          var protectionDates = {
            'OGIEN': {
              start: null,
              end: null
            },
            'KRA': {
              start: null,
              end: null
            }
          };
          lsnNg.forEach(mainDataContainer.localizations, function(oneLocalization) {
            protectionDates = angular.copy(oneLocalization.getAdditionalData('protectionDates'));
            return false;
          });
          localization.setAdditionalData(protectionDates, 'protectionDates');
          if (!appVariables.isSupplement && !appVariables.readOnly && !appVariables.isExternallyCreated) {
            localization.setAdditionalData(-2, 'flood');
          }

          dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, localization.metaData.get('clientId'));
          if (CONFIG.BEHAVIOR.localization.localizationSetGroundFloor) {
            localization.set('dynamicValues', {
              groundFloor: false
            }); //tymczasowe ustawianie
          }
        };

        /**
         * [getLocalizationInfo description]
         * @param  {localization{ obiekt lokalizacji}
         * @param  {object} params  na bazie DEFAULT_INFO_HEADER_SETTINGS
         * @return {string}
         */
        this.getLocalizationInfo = function(localization, params) {
          // jeśli mamy miejscowość bez ulicy i nie wysyłamy do metody własnych ustawień - to pobieramy inną wersję domyślnych ustawień
          var defaultSettings = (angular.isDefined(localization.address.street) && angular.isDefined(localization.address.streetPrefix)) ? angular.copy(DEFAULT_INFO_HEADER_SETTINGS) : angular.copy(DEFAULT_INFO_HEADER_SETTINGS_NO_STREET);
          var settings = lsnDeepExtend(defaultSettings, params);

          var txt = CONSTANTS.BRAK;
          if (settings.html) {
            txt = $sce.trustAsHtml(txt);
          }

          if (localization) {

            var address = null;
            if (localization.get('address')) {
              address = localization.get('address').getData();
            }
            //sprawdzić czy dla produktu jest dostępny name
            if (settings.showName && localization.get('name') !== '' && localization.get('name') !== null) {
              settings = {
                elements: [{
                  text: localization.name
                }],
                maxLength: settings.maxLength
              };
            } else {
              // wstawiamy teksty
              if (address) {
                lsnNg.forEach(settings.elements, function(element) {
                  element.text = address[element.field];
                });
              }
            }
            // ladnie przycinamy
            txt = ihestiaTrimInfo(settings);
            if (angular.isUndefined(txt)) {

              txt = CONSTANTS.BRAK;
              if (settings.html) {
                txt = $sce.trustAsHtml(txt);
              }
            }
          }
          return txt;
        };


        /**
         * zwraca wszystkie lokalizacje
         * @param [settings] [ustawienia do wyswietlania]
         * @return {Object} tablica
         * obiekt:
         *   name - nazwa do wyswietlenia
         *   clientId - id elementu
         */
        this.getLocaliations = function(settings) {
          var localizations = [];

          angular.forEach(mainDataContainer.localizations, function(obj) {
            localizations.push({
              name: self.getLocalizationInfo(obj, settings),
              clientId: obj.metaData.get('clientId'),
              editable: allowedChangesHelper.anyChangePossible(obj)
            });
          });
          return localizations;
        };

        /**
         * [removeLocalization description]
         * @param  {int} localizationClientId nasz wewnętrzne id
         * @return {localizationHelper}
         */
        this.removeLocalization = function(localizationClientId) {
          delete mainDataContainer.localizations[localizationClientId];
          dataContainerHelper.deleteVariantSelection(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, localizationClientId);

          localizationClientId = parseInt(localizationClientId, 10);

          if (dataContainerHelper.getLocalizationCount() === 0) {
            // brak localizacji idziemy do głównej matrycy
            self.selectLocalization(0);

            if ($state.current.name === 'localizationMatrix') {
              $state.go(CONSTANTS.PAGE_MATRIX);
            }
          } else if (localizationClientId === parseInt(mainDataContainer.selectedLocalization, 10)) {
            lsnNg.forEach(mainDataContainer.localizations, function(localizaion, k) {
              self.selectLocalization(k);
              return false;
            });
          }

          $timeout(function() {
            actionHelper.runAction('localizationDeleted', {
              id: parseInt(localizationClientId, 10)
            });
          }, 0);
          return self;
        };

        this.getAdrStal = function(adresses) {
          var adressStalData = adresses[0];
          var e = new EstateModel();
          e.fillFromData({
            address: adressStalData
          });
          return e;
        };

        //pobiera adres lokalizacji z ubezpieczajacego
        this.loadDataFromMainInsuredOnce = function() {
          var localizationLoaded = false;
          var insurerAdressesData = dataContainerHelper.getMainInsured().get('addresses');
          var insurerAddressData;
          lsnNg.forEach(insurerAdressesData, function(addressData) {
            if (addressData.code === AddressModelConstants.ADDRESS_TYPE_STAL) {
              insurerAddressData = addressData;
              return false;
            }

            return true; //continue
          });
          //sprawdzamy czy już nie załadowano takiej lokalizacji
          var localizationData;
          lsnNg.forEach(mainDataContainer.localizations, function(localization) {
            var localizationMatch = true;
            localizationData = localization.address.getData();
            lsnNg.forEach(insurerAddressData, function(value, name) {
              //nie sprawdzamy lokalu bo budynek nie posiada lokalu
              if (!angular.isObject(value) && name !== 'apartment') {
                if (value === null) {
                  value = '';
                }
                if (localizationData[name] === null) {
                  localizationData[name] = '';
                }

                if (value !== localizationData[name]) {
                  localizationMatch = false;
                  return false;
                }
              }

              return true; //continue
            });
            if (localizationMatch) {
              localizationLoaded = true;
              return false;
            }

            return true; //continue
          });
          if (localizationLoaded) {
            return {};
          } else {
            return insurerAddressData;
          }
        };

      };
      return new LocalizationHelper();
    }
  ]);