angular.module('ihestiaWidgets.policyOperationsPublic')
  .controller('vehiclePurchasePublicCtrl', ['$scope', '$state', '$stateParams', 'ihestiaConfigHelper', 'vehiclePurchasePublicSvc', 'operationType', 'VerificationDataModelConstants', 'resolvedNationalities', 'resolvedIdentityDocumentTypes', 'PersonModel', 'OrganizationModel', 'vehiclePurchasePublicHelper', '$window', '$filter', 'iHestiaCommonModalHelper',
    function($scope, $state, $stateParams, ihestiaConfigHelper, vehiclePurchasePublicSvc, operationType, VerificationDataModelConstants, resolvedNationalities, resolvedIdentityDocumentTypes, PersonModel, OrganizationModel, vehiclePurchasePublicHelper, $window, $filter, iHestiaCommonModalHelper) {

      $scope.formData = {};

      $scope.otherData = {
        saveErrors: []
      };

      $scope.vehiclePurchasePublicForm = {};

      $scope.Constants = VerificationDataModelConstants;

      $scope.formSubmitted = false;
      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.policyOperationsNumericSettings = {
        policyNumber: {
          decimals: 0,
          min: 0,
          allowLeadingZeros: true
        }
      };

      $scope.clientTypeDictionary = [
      {
        name: 'Osoba fizyczna',
        code: 'person'
      },
      {
        name: 'Osoba prawna',
        code: 'organization'
      }
      ];

      $scope.roles = {
        SELLER_OWNER: 'SellerOwner',
        SELLER_COOWNER: 'SellerCoowner',
        BUYER_OWNER: 'BuyerOwner',
        BUYER_COOWNER: 'BuyerCoowner'
      };

      $scope.showElements = {
        foreigner: false
      };

      $scope.personData = {
        clientType: 'person',
        metaData: {
          id: -1
        }
      };
      $scope.foreignerDocument = {};

      //słowniki dla obcokrajowca
      $scope.nationalities = {
        'PL': {
          code: 'PL',
          description: 'POLSKIE - POLSKA',
          name: 'POLSKIE'
        }
      };
      angular.forEach(resolvedNationalities.data.items, function(v) {
        $scope.nationalities[v.code] = v;
      });

      $scope.identityDocumentTypes = {};
      angular.forEach(resolvedIdentityDocumentTypes.data, function(v) {
        $scope.identityDocumentTypes[v.code] = v;
      });

      $scope.operationType = operationType;


      $scope.vehiclePurchasePublicClientModal = {
        messages: [],
        settings: {
            title: 'Jesteś już naszym klientem',
            okBtnName: 'Dalej',
            size: 'sm',
            cancelBtnName: 'Zamknij',
            keyboard: false,
            okBtnCallback: function(){
              $scope.forward(true);
            }
          }
      };

      $scope.back = function() {
        $window.location = 'http://www.ergohestia.pl/';
      };

      /**
       * kliknięcie w checkbox obcokrajowca
       * @return {[type]} [description]
       */
      $scope.foreignerChanged = function()
      {
        if($scope.showElements.foreigner)
        {
          $scope.personData.pesel = null;
        }
        else
        {
          $scope.personData.citizenshipCode = null;
          $scope.foreignerDocument.number = null;
          $scope.foreignerDocument.code = null;
        }
      };

      /**
       * przygotowujemy dane do zapisu
       * @return {object}
       */
      $scope.prepareData = function()
      {
        var data = angular.copy($scope.formData);
        data.operationType = operationType;
        data.persons = [];
        data.organizations = [];

        if(data.vehicleRegistrationNumber)
        {
          data.vehicleRegistrationNumber = $filter('uppercase')(data.vehicleRegistrationNumber);
        }

        var personData;
        if($scope.personData.clientType === 'person')
        {
          personData = new PersonModel().setData($scope.personData).getData();
          if($scope.showElements.foreigner)
          {
            personData.documents = [];
            personData.documents.push($scope.foreignerDocument);
          }
          data.persons.push(personData);
        }
        else
        {
          personData = new OrganizationModel().setData($scope.personData).getData();
          data.organizations.push(personData);
        }

        data.vehicleOwnerChangeRecords = [];
        var record = {
          operationDate: data.operationDate
        };
        delete data.operationDate;
        var role;
        if(operationType === $scope.Constants.VEHICLE_PURCHASE)
        {
          role = $scope.roles.BUYER_OWNER;
        }
        else
        {
          role = $scope.roles.SELLER_OWNER;
        }
        record.partnerRoles = {
          '-1': role
        };
        data.vehicleOwnerChangeRecords.push(record);

        return data;
      };

      /**
       * klikniecię przycisku "dalej"
       * @return {[type]}        [description]
       */
      $scope.forward = function(force) {
        $scope.formSubmitted = true;
        // jeśli brak błędów, to odpytujemy usługę
        if ($scope.vehiclePurchasePublicForm.$valid) {
          var data = $scope.prepareData();
          $scope.$emit('showBlockUi');
          $scope.otherData.saveErrors = [];

          if(force) {
            data.AllowAnonymousConfirmationEvenForActiveCustomer = true;
          }

          vehiclePurchasePublicSvc.post('verification', data, null, function(response){
            vehiclePurchasePublicHelper.verificationData = response.data;
            if(operationType === $scope.Constants.VEHICLE_PURCHASE)
            {
              $state.go('main.vehiclePurchasePublicStep2');
            }
            else
            {
              $state.go('main.vehicleSalePublicStep2');
            }
          }, function(reject){
            var messages = null;
            if(reject.data && reject.data.length > 0)
            {
              messages = reject.data;
            }
            else if(reject.data && reject.data.messages)
            {
              messages = reject.data.messages;
            }
            if(messages)
            {
              var activeAccountExists = false;
              angular.forEach(messages, function(message){
                if(message.code === 'ActiveAccountExists')
                {
                  activeAccountExists = true;
                }
              });
              if(activeAccountExists)
              {
                $scope.parseErrors(messages);
                $scope.vehiclePurchasePublicClientModal.messages = messages;
                iHestiaCommonModalHelper.showModal('vehiclePurchasePublicClientModal');


                // var path;
                // if(operationType === $scope.Constants.VEHICLE_PURCHASE)
                // {
                //   path = 'external/policyOperations/vehiclePurchase';
                // }
                // else
                // {
                //   path = 'external/policyOperations/vehicleSale';
                // }
                // ikontoCommonHelper.showClientAlreadyExistModal($state.current.data.pageTitle, ihestiaConfigHelper.getUrl('IKONTO_URL', path));
              }
              else
              {
                $scope.otherData.saveErrors = messages;
                $scope.parseErrors();
              }
            }
            $scope.$emit('hideBlockUi');
          },
          {
            allowedStatuses: [409, 404]
          });
        }
      };

      $scope.parseErrors = function(errors)
      {
        if(angular.isUndefined(errors)) {
          errors = $scope.otherData.saveErrors;
        }

        angular.forEach(errors, function(error){
          if(error.text.indexOf('{') !== -1)
          {
            error.type = 'link';
            error.preText = error.text.substr(0, error.text.indexOf('{'));
            error.linkText = error.text.substr(error.text.indexOf('{') + 1, error.text.indexOf('}') - error.text.indexOf('{') - 1);
            error.postText = error.text.substr(error.text.indexOf('}') + 1);
          }
        });
      };

    }
  ]);
