angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryCountriesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryCountriesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'countries';
        this.sortBy = 'name';
        this.pageSize = 1000;

      };
      return new RestDictionaryCountriesSvc();
    }
  ]);