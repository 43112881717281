angular.module('lsnBase.models')
  .factory('LsnOrganizationModelV1', ['LsnAbstractModel', 'LsnOrganizationModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, LsnOrganizationModelConstants, lsnModelFactory)
    {

      var LsnOrganizationModelV1 = function()
      {
        this.objectName = 'Organization';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData:
          {
            type: 'MetaData'
          },
          name:
          {
            type: 'string',
            filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }]
          },
          shortName:
          {
            type: 'string',
            filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }]
          },
          nip:
          {
            type: 'string'
          },
          regon:
          {
            type: 'string'
          },
          companyId:
          {
            type: 'string'
          },
          foundationDate:
          {
            type: 'date'
          },
          countryCode:
          {
            type: 'string'
          },
          industryCode:
          {
            type: 'string',
            defaultValue: LsnOrganizationModelConstants.ORGANIZATION_INDUSTRY_CODE_DEFAULT // INNE
          },
          legalFormCode:
          {
            type: 'string',
            defaultValue: LsnOrganizationModelConstants.ORGANIZATION_LEGALFORM_CODE_DEFAULT // INNA
          },
          pkd:
          {
            type: 'array',
            elementsType: 'string'
          },
          defaultPkd:
          {
            type: 'string'
          },
          addresses:
          {
            type: 'array',
            elementsType: 'Address'
          },
          contacts:
          {
            type: 'array',
            elementsType: 'Contact',
            filters: [
            {
              name: 'contactFilter'
            }]
          },
          accounts:
          {
            type: 'array',
            elementsType: 'BankAccount'
          },
          clauses:
          {
            type: 'Clauses'
          },
          customerId: {
            type: 'string'
          },
          dynamicValues:
          {
            type: 'dynamicValues'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.name = null; // string, Nazwa organizacji
        this.shortName = null; // string, Nazwa skrócona organizacji
        this.nip = null; // string, NIP
        this.regon = null; // string, REGON
        this.companyId = null; // string, if firmy zagranicznej
        this.foundationDate = null; // date, Data powstania
        this.countryCode = null; // string, Kod kraju pochodzenia. Wartość domyślna: PL. Pole powinno być wypełniane tylko jeśli wartość jest inna.
        this.industryCode = null; // string, Kod branzy
        this.legalFormCode = null; // string, Kod formy prawnej
        this.pkd = []; // Lista string, Lista segmentów PKD
        this.defaultPkd = null; // string, Główny segment PKD
        this.addresses = []; // Lista Address, Kolekcja adresów
        this.contacts = []; // Lista Contact, Kolekcja kontaktów
        this.accounts = []; // Lista BankAccount, Lista kont bankowych klienta
        this.clauses = null; // Clauses, Pole przechowuje informacje na temat zgód elektronicznych
        this.customerId = null; // id klienta z ikonta
        this.dynamicValues = null; // Lista atrybutów dotyczących organizacji

        this.setDefaultAdditionalData();
      };

      LsnOrganizationModelV1.prototype = LsnAbstractModel.prototype;

      return LsnOrganizationModelV1;
    }
  ]);