angular.module('lsnBase.filters')
  .filter('range', function() {
    return function(limit, offset, step, asString) {
      offset = offset ? offset : 0;
      step = step ? step : 1;
      var range = [],
        maxValue = (limit * step) + offset;
      for (var i = offset; i < maxValue; i = i + step) {
        var pushValue = asString ? (i + '') : i;
        range.push(pushValue);
      }

      return range;
    };
  });