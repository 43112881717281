angular.module('ihestiaIkomunikator')
  .service('iKomunikatorfileSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var Files = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      var self = this;
      this.resource = 'resources/files';
      this.businessArea = 'ikomunikator';
      this.port = '8221';
      this.path = 'RestFiles';

      /**
       * Usługa do weryfikacji „jakości” przesłanego elektronicznie dokumentu polisy.
       *
       * @param {String} id Identyfikator pliku w FileServer.
       * @param {function} callback
       * @returns {*}
       */
       this.downloadAttachment = function(data, callback, errorCalback, params) {
          return self.get(null, data, 'file', callback, errorCalback, params);
        };
    };
    return new Files();
  }]);