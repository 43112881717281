angular.module('ihestiaWidgets.policyTermination')
  .factory('PolicyTerminationConstants', [function() {
    var PolicyTerminationConstants = {};

    PolicyTerminationConstants.TYPES = {};

    PolicyTerminationConstants.TYPES.NOTICE = 'noticeTypes'; //wypowiedzenie
    PolicyTerminationConstants.TYPES.WITHDRAWAL = 'withdrawalTypes'; //Odstąpienie
    PolicyTerminationConstants.TYPES.DAD = 'deregistrationAndDismantling'; //demontaż i wyrejestrowanie pojazdu
    PolicyTerminationConstants.TYPES.ESTATE = 'realEstateSale'; //sprzedaż nieruchomości
    PolicyTerminationConstants.TYPES.CANCELLATION = 'cancellationTypes';
    PolicyTerminationConstants.TYPES.TERMINATION = 'terminationTypes';

    PolicyTerminationConstants.CAUSE_CODES = {};

    PolicyTerminationConstants.CAUSE_CODES.CANCELLATION = 'PolicyCancellation';
    PolicyTerminationConstants.CAUSE_CODES.WITHDRAWAL = 'Withdrawal';
    PolicyTerminationConstants.CAUSE_CODES.NOTICE = 'PolicyNotice';
    PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_DATE = 'TerminationOnDate';
    PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_FIRST_PAYMENT = 'TerminationWithFirstPaymentDate';

    return PolicyTerminationConstants;
  }]);
