angular.module('lsnBase.breadCrumbs')
  .provider('breadCrumbsConfig', [
    function() {

      var provider = this;

      /**
       * Czy wyświetlać główny link absolutny (zazwyczaj inna główna aplikacja)
       * @type {Boolean}
       */
      this.mainLinkVisible = false;

      this.mainState = { // state który podpinamy pod mainLink, jeśli mainLinkVisible === true
        data: {
          pageTitle: null,
          pageTitleLabel: null
        },
        url: null,
        stateName: '',
        abstract: false,
        absolute: true // ścieżka bezwględna
      };

      this.directiveTpl = 'lsn-base-templates-app/directives/breadCrumbs/breadCrumbs.tpl.html';


      this.$get = [function(){
        return provider;
      }];
    }
  ]);