angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorNextMessageHelper', ['iKomunikatorMessagesThreadSvc', 'IKomunikatorMessageModel', 'IKomunikatorMessageThreadModel',
    'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaIkomunikatorDocumentTypes',
    function(iKomunikatorMessagesThreadSvc, IKomunikatorMessageModel, IKomunikatorMessageThreadModel, ihestiaRestDictionaryAllElectronicDocumentTypesSvc,
      ihestiaIkomunikatorDocumentTypes) {
      var DetailsMessageThreadHelper = function() {
        var self = this;

        this.defaultData = {
          thread: null,
          newMessage: null,
          errors: {},
          document: {
            documentType: null
          }
        };

        this.init = function(thread, taskId) {
          self.tplSubdata = angular.copy(self.defaultData);

          self.tplSubdata.newMessage = new IKomunikatorMessageModel();
          self.tplSubdata.thread = angular.copy(thread);
          self.tplSubdata.newMessage.forcedNotificationChannelName = thread.lastMessageChannel;
          self.tplSubdata.thread.threadId = thread.threadId || thread.messageThreadId;

          if (angular.isDefined(taskId)) {
            self.tplSubdata.newMessage.customFields.dashboardTaskId = taskId;
          }
          self.setDocumentType();
        };

        this.saveMessage = function() {
          self.tplSubdata.newMessage.messageThreadId = self.tplSubdata.thread.threadId;
          angular.forEach(['subjectContextItemMessagePreviewLabel', 'subjectContextItemLabel', 'subjectContextItemNumber', 'subjectContextItemId', 'subjectContextItemType', 'subjectContextItemOperationCategory', 'subjectId', 'subjectLabel', 'dashboardCaseId', 'dashboardTaskId', 'archiveDocumentClass'], function(name) {
            if (self.tplSubdata.thread.customFields[name] && angular.isDefined(self.tplSubdata.thread.customFields[name].value)) {
              self.tplSubdata.newMessage.customFields[name] = self.tplSubdata.thread.customFields[name].value;
            }
          });
        };

        this.setClearData = function() {
          angular.forEach(self.tplSubdata, function(value, key) {
            delete self.tplSubdata[key];
          });

          self.tplSubdata = angular.extend(self.tplSubdata, angular.copy(self.defaultData));
        };

        this.setDocumentType = function() {
          if (self.tplSubdata.thread.getLastMessageChannel() === 'SMS') {
            self.tplSubdata.document.documentType = null;
          } else {
            var attr = {
              subjectId: null,
              subjectContextItemNumber: null,
              archiveDocumentClass: null,
              otherSubjectContextItemId: null
            };

            angular.forEach(['subjectContextItemNumber', 'archiveDocumentClass', 'subjectId'], function(name) {
              if (self.tplSubdata.thread.customFields[name] && angular.isDefined(self.tplSubdata.thread.customFields[name].value)) {
                attr[name] = self.tplSubdata.thread.customFields[name].value;
              }
            });

            if (!angular.isString(attr.archiveDocumentClass)) {
              return;
            }

            //jeśli element wpisany z palca to różne od null 
            //atrybuty z palca nie sa wysylane w typie dokumentu
            if (attr.otherSubjectContextItemId === null) {
              var data = {
                subjectId: attr.subjectId,
                subjectContextItemNumber: attr.subjectContextItemNumber
              };
              ihestiaIkomunikatorDocumentTypes.setDocumentTypeByCode(self.tplSubdata.document, attr.archiveDocumentClass, data);
            } else {
              ihestiaIkomunikatorDocumentTypes.setDocumentTypeByCode(self.tplSubdata.document, attr.archiveDocumentClass);
            }
          }
        };

        this.setValidatonErrors = function(errors) {
          angular.forEach(errors, function(error) {
            if (['content'].indexOf(error.validationObjectId) !== -1) {
              self.tplSubdata.errors[error.validationObjectId] = {
                type: error.messageType,
                text: error.messageText
              };
            }
          });
        };

        this.clearValidatonErrors = function() {
          self.tplSubdata.errors = {};
        };

      };

      return new DetailsMessageThreadHelper();
    }
  ]);