angular.module('lsnBase.models')
  .factory('LsnPolicyDiscountModelV1', ['LsnAbstractModel', 'LsnPolicyDiscountModelConstants',
    function(LsnAbstractModel, LsnPolicyDiscountModelConstants)
    {

      var LsnPolicyDiscountModelV1 = function()
      {
        this.objectName = 'PolicyDiscount';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          key:
          {
            type: 'string',
            dictionary: LsnPolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY
          },
          enabled:
          {
            type: 'bool'
          },
          value:
          {
            type: 'int'
          },
          unit:
          {
            type: 'string',
            dictionary: LsnPolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_UNIT
          }
        };

        this.key = null; // DICTIONARY_POLICY_DISCOUNT_KEY
        this.enabled = null; // bool, czy dostępny
        this.value = null; // int, wartość
        this.unit = null; // DICTIONARY_POLICY_DISCOUNT_UNIT, jednostka wysłnanej .
      };

      LsnPolicyDiscountModelV1.prototype = LsnAbstractModel.prototype;

      return LsnPolicyDiscountModelV1;
    }
  ]);