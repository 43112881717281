angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminSystemMessageListSvc', ['ihestiaCommonAdminMessageAbstract',
    function(ihestiaCommonAdminMessageAbstract) {

      var AdminSystemMessageListSvc = function() {
        ihestiaCommonAdminMessageAbstract.apply(this, arguments);

        var self = this;
        this.businessArea = 'message-manager';
        this.resource = 'system-message/list';

        /**
         * Pobieranie administracyjnej listy wiadomości
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.getList = function(callback) {
          self.get('', {}, null, function(res) {
            var messages = self.getParsedMessages(res);
            // zwracamy dane
            callback({
              maxMessages: res.data.maxActiveMessages,
              messages: messages
            }, res);
          });
        };

      };
      return new AdminSystemMessageListSvc();
    }
  ]);