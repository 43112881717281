angular.module('salesPath2')
  .controller('liquidationModalCtrl', ['$scope', 'sp2SelectionHelper', 'CONFIG', 'CONSTANTS', 'sp2CommonHelper', 'applicationHelper', 'sp2SelectionPopups', 'actionHelper',
    function($scope, sp2SelectionHelper, CONFIG, CONSTANTS, sp2CommonHelper, applicationHelper, sp2SelectionPopups, actionHelper) {
      $scope.tplData = {
        liquidation: null,
        liquidationNetLink: CONFIG.BEHAVIOR.liquidationLink,
        liquidationVariant: sp2SelectionPopups.modals.liquidationModal.variant,
        readonly: sp2SelectionPopups.modals.liquidationModal.readonly
      };

      $scope.visibility = {
        PRICE: false,
        NET: false,
        SERVICE: false
      };

      $scope.init = function() {
        $scope.tplData.liquidation = sp2SelectionHelper.getLiquidation($scope.tplData.liquidationVariant);

        angular.forEach(CONFIG.BEHAVIOR.liquidationVariantOption[$scope.tplData.liquidationVariant], function(option) {
          $scope.visibility[option] = true;
        });
      };

      // anulij z modala (też taryfikujemy)
      $scope.$on('iHestiaCommonModalCancelBtnClicked.liquidationModal', function() {
        lsnNg.noop(); // jeśli coś mialoby sie zadziac na kliku "Anuluj" w modalu likwidacji szkod, to tutaj...
      });

      // 'ok' lub 'zamknij' z modala
      $scope.$on('iHestiaCommonModalOkBtnClicked.liquidationModal', function() {
        if ($scope.tplData.readonly) {
          return false;
        }
        sp2SelectionHelper.setLiquidation($scope.tplData.liquidation);
        actionHelper.runAction('tarifficate');
        return true;
      });

      $scope.init();
    }
  ]);