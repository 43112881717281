angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaBeneficiaryAdditionalDataModalCtrl', ['$scope', 'ihestiaBeneficiaryAdditionalDataModalHelper',
    function($scope, ihestiaBeneficiaryAdditionalDataModalHelper) {

      $scope.init = function(){
        ihestiaBeneficiaryAdditionalDataModalHelper.initData();
        $scope.tplData = ihestiaBeneficiaryAdditionalDataModalHelper.tplData;
      };

      $scope.init();
    }
  ]);
