angular.module('ihestiaRestServicesBase')

/**
 * wnioski ubezp.
 */
  .service('applicationsSvc', ['IHestiaRestServiceAbstractDef', 'ApplicationModel', 'lsnModelFactory', function(IHestiaRestServiceAbstractDef, ApplicationModel, lsnModelFactory) {

    /**
     * Główny konstruktor
     */
    var Applications = function() {
      var self = this;
      this.resource = 'applications';
      this.businessArea = 'insurance';
      this.port = '8201';
      this.path = 'RestApplications';

      /**
       * aktualizuje wniosek i zwraca staryfikowane warianty ryzyk (tryb symulacji)
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putSimulation = function(id, data, callback, errorCallback, httpParams) {
        return self.put(id, data, 'simulation', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * taryfikacja wniosku z symulacją bez zapisu do bazy danych
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.postSimulation = function(id, data, callback, errorCallback, httpParams) {
        return self.post(id, data, 'simulation', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * taryfikacja wniosku bez zapisu do bazy danych
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.postCalculation = function(id, data, callback, errorCallback, httpParams) {
        return self.post(id, data, 'calculation', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * podzial wniosku na polisy
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getPolicies = function(id, callback, errorCallback, httpParams) {
        return self.get(id, null, 'policies', callback, errorCallback, httpParams);
      };
      /**
       * zamiana wniosku na oferte
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putOffer = function(id, data, callback, errorCallback, httpParams) {
        return self.put(id, data, 'offer', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * zamiana wniosku na oferte
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putOfferReservationNumber = function(id, data, callback, errorCallback, httpParams) {
        return self.put(id, data, 'offer/reservationnumber', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * zamiana wniosku na kalkulacje
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putCalculation = function(id, data, callback, errorCallback, httpParams) {
        return self.put(id, data, 'calculation', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };
      /**
       * przeksztalca wniosek na polisy
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.postPolicy = function(id, data, callback, errorCallback, params) {
        return self.post(id, data, 'policy', callback, errorCallback, params);
      };

      /**
       * Usługa dla wskazanego wniosku ubezpieczeniowego nadaje status iOferty (przesyła wniosek do iKonta)
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putIOffer = function(id, data, callback, errorCallback, httpParams, sendByEmail) {
        return self.put(id, data, sendByEmail ? 'sendbyemail' : 'ioffer', function(result) {
          self.callCallback(result, callback);
        }, errorCallback, httpParams);
      };

      /**
       * zapisuje (tworzy i aktualizuje) wniosek ubezpieczeniowy
       * @param {object} application wniosek (odpowiednik klasy Application)
       * @param {boolean} simulation symulacja
       * @param {boolean} save zapis do bazy (w systemie zewn.)
       * @param {function} response callback
       */
      this.sendApplication = function(application, simulation, save, response, errorCallback, httpParams) { //eslint-disable-line consistent-return
        if (typeof application.metaData.id === 'undefined' || application.metaData.id === '') { //tworzymy
          return self.post(null, application, null, response, errorCallback, httpParams);
        } else { //aktualizaujemy
          var id = application.metaData.id;
          if (simulation) {
            if (save) {
              return self.putSimulation(id, application, response, errorCallback, httpParams);
            } else {
              return self.postSimulation(null, application, response, errorCallback, httpParams);
            }
          } else {
            if (save) {
              return self.put(id, application, null, response, errorCallback, httpParams);
            } else {
              return self.postCalculation(null, application, response, errorCallback, httpParams);
            }
          }
        }
      };

      this.callCallback = function(responseObject, callback) {
        var res = responseObject.data;
        if (!angular.isObject(res) || angular.equals(res, {})) {
          callback({});
        } else {
          callback(self.parseApplication(responseObject));
        }
      };

      /**
       * Usługa do pobrania transformowanego wniosku.
       * @param  {String}   id                id wniosku
       * @param  {String}   applicationSymbol symbol docelowego wniosku
       * @param  {Function} callback
       * @param  {Function}   errorCallback
       * @param  {Object}   httpParams dodatkowe parametry
       * @return {*}
       */
      this.getTransform = function(id, applicationSymbol, callback, errorCallback, httpParams) {
        var url = self._getServiceUrl(id, 'transform') + '/' + applicationSymbol;
        self.call(url, 'GET', null, callback, errorCallback, httpParams);
      };

      /**
       * pobiera dozwolone operacje dla wniosku
       * UWAGA
       * obecnie ma to zastosowanie tylko przy wznowieniach 2.0
       *
       * @param {String} id id wniosku ubezp.
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getAvailableOperations = function(id, callback, errorCallback) {
        return self.get(id, null, 'availableOperations', callback, errorCallback);
      };

      this.callCallback = function(responseObject, callback) {
        var res = responseObject.data;
        if (!angular.isObject(res) || angular.equals(res, {})) {
          callback({});
        } else {
          callback(self.parseApplication(responseObject));
        }
      };

    };

    /**
     * Konstruktor v2
     */
    var ApplicationsV2 = function() {
      Applications.apply(this, arguments);
      this.apiVersion = 'v2';
      var self = this;
      self.parseApplication = function(result) {
        result.data = lsnModelFactory.getObject('Application', {
          version: 'v2',
          data: result.data
        });
        return result;
      };
    };

    /**
     * Konstruktor v1
     */
    var ApplicationsV1 = function() {
      Applications.apply(this, arguments);
      var self = this;

      self.parseApplication = function(result) {
        result.data = new ApplicationModel().setData(result.data);
        return result;
      };
    };

    return IHestiaRestServiceAbstractDef.setApiConstructors({
      v1: ApplicationsV1,
      v2: ApplicationsV2
    });
  }]);