//DEKORATOR TempDataContainerModel
angular.module('salesPath2')
  .factory('TempDataContainerModel', ['mainDataContainer', 'CONSTANTS', 'RESOURCES', 'PolicyModel', 'CONFIG', 'PolicyDiscountModel', 'resourceHelper',
    function(mainDataContainer, CONSTANTS, RESOURCES, PolicyModel, CONFIG, PolicyDiscountModel, resourceHelper) {

      var TempDataContainerModel = function() {
        var self = this;
        this.application = null;
        this.localizations = {};
        this.vehicles = {};
        this.persons = {};
        this.organizations = {};
        this.groups = {};
        this.insurerId = null;
        this.mainInsuredId = null;
        this.suList = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.suList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premiumList = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premiumList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        //koszyk2 składka przed zniżką
        this.premBeforeDiscList = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        //koszyk2 składka po zniżce
        this.premAfterDiscList = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        // koszyk2 surowa składka przed zniżką
        this.premPureBeforeDiscList = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premPureBeforeDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};
        // koszyk2 surowa składka po zniżce
        this.premPureAfterDiscList = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_VEHICLE] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PACKAGE] = {};
        this.premPureAfterDiscList[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = {};

        this.tariffPremiumList = angular.copy(this.premiumList);
        this.finalPremiumList = angular.copy(this.premiumList);
        this.summedPremiumList = angular.copy(this.premiumList);
        this.renewedList = angular.copy(this.premiumList);
        this.protectionPeriodCode = (CONFIG.BEHAVIOR.globalProtectionPeriod !== null) ? RESOURCES.PROTECTION_PERIOD_OPTIONS[0].CODE : null; //okres ubezpieczenia - 30/90/180 - domyslnie pierwszy z brzegu
        this.protectionStartTime = RESOURCES.CURRENT_TIME;

        this.oczpProtectionDates = {
          start: null,
          end: null
        };
        this.nnwProtectionDates = {
          start: null,
          end: null
        };
        this.propertyProtectionDates = {
          start: null,
          end: null
        };
        this.nnwIncognito = null;
        this.liquidation = null;
        this.selectedVariants = {};
        this.selectedAdditions = {};
        this.selectedExtensions = {};
        this.nextObjectId = 1;
        this.nextSubjectId = 1;
        this.selectedLocalization = CONSTANTS.NO_OBJECT;
        this.selectedVehicle = CONSTANTS.NO_OBJECT;
        this.selectedSubject = CONSTANTS.NO_OBJECT;
        this._subjectIdMap = {}; //mapping id do clientId
        this._estateIdMap = {}; //mapping id do clientId
        this._vehicleIdMap = {}; //mapping id do clientId
        this.bonusMalus = angular.copy(mainDataContainer.bonusMalus);
        this.previousRisks = [];
        this.previousRisksSelection = {
          additions: {}
        };
        this.selectedRisksRefs = [];
        this.previouslySelectedRisksRefs = [];
        this.allowedChanges = null;
        this.allowedRisksVariants = null;
        this.allowedProducts = {};

        //mappowanie podwójne? policiesDiscounts
        this.policiesDiscounts = angular.copy(mainDataContainer.policiesDiscounts);
        for (var policyNumber in mainDataContainer.policiesDiscounts) {
          if (mainDataContainer.policiesDiscounts.hasOwnProperty(policyNumber) && typeof mainDataContainer.policiesDiscounts[policyNumber].discountOnRisk !== 'undefined') {
            this.policiesDiscounts[policyNumber].discountOnRisk = [];
            for (var idx in mainDataContainer.policiesDiscounts[policyNumber].discountOnRisk) {
              if (mainDataContainer.policiesDiscounts[policyNumber].discountOnRisk.hasOwnProperty(idx)) {
                var tmpDiscount = new PolicyDiscountModel();
                tmpDiscount.setData(mainDataContainer.policiesDiscounts[policyNumber].discountOnRisk[idx].getData());
                this.policiesDiscounts[policyNumber].discountOnRisk.push(tmpDiscount);
              }
            }
          }
        }

        this.policies = [];
        for (var i = 0; i < mainDataContainer.policies.length; i += 1) {
          var tmpPolicy = new PolicyModel();
          tmpPolicy.setData(mainDataContainer.policies[i].getData());
          this.policies.push(tmpPolicy);
        }
        this.greenCardNumber = null;
        this.renewedApplication = null;
        this.individualDiscountInputValue = '';
        this.productClauses = {};
        this.destinations = {};

        /**
         * inicjalizacja dataContainera
         */
        this.init = function() {
          //dostpenosc produktów - domyslnie wszystkie dostepne
          angular.forEach(RESOURCES.PRODUCTLIST, function(def) {
            self.allowedProducts[def.CODE] = true;
          });
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant) {
            self.allowedProducts[CONSTANTS.PRODUCT_OGI_KRA] = mainDataContainer.allowedProducts[CONSTANTS.PRODUCT_OGI_KRA];
          }
          self._initProtectionDates();
          //DODATKI
          if (CONFIG.MODULES.additions) {
            self.selectedAdditions = angular.copy(RESOURCES.SELECTED_ADDITIONS);
          }
          //ROZSZERZENIA
          if (CONFIG.MODULES.extensions) {
            self.selectedExtensions = angular.copy(RESOURCES.SELECTED_EXTENSIONS);
          }
        };

        //inicjalizacja okresów ubezpieczeńi niektórych dat
        this._initProtectionDates = function() {
          var oczpDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_OCZP);
          if (!angular.equals(oczpDef, {}) && CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1) {
            self.oczpProtectionDates = {};
            angular.forEach(oczpDef.VARIANTLIST, function(variant) {
              self.oczpProtectionDates[variant.CODE] = {
                'start': null,
                'end': null
              };
            });
          }
        };

        this.init();
      };

      return TempDataContainerModel;
    }
  ]);