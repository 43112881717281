angular.module('ihestiaWidgets.policyOperations.common')
  .factory('policyOperationsHelper', ['$location', 'ihestiaPlatformHelper', '$state', 'policyOperationsSvc', 'policyOperationsV2Svc', 'PolicyTerminationConstants',
    function($location, ihestiaPlatformHelper, $state, policyOperationsSvc, policyOperationsV2Svc, PolicyTerminationConstants) {

      var PolicyOperationsHelper = function() {

        var self = this;
        this.isLife = function() {
          return ihestiaPlatformHelper.isLifeContext();
        };

        /**
         *
         * @param {string} policyNumber
         * @param {string | [string, object]} state
         */
        this.end = function(policyNumber, state) {
          var policyNumberString = _.trim(policyNumber + '');
          if (ihestiaPlatformHelper.isDirect() && policyNumber) {
            //dla polisa online idziemy do podglądu polisy, bo nie każdy ma dostęp do strony głównej
            $state.go('main.abstractPolicy.policyDetails', {
              policyNumber: policyNumberString
            });
          } else if (state) {
            // handle custom end state
            if (_.isString(state)) {
              $state.go(state);
            } else if (_.isArray(state)) {
              $state.go(state[0], state[1]);
            }
          } else {
            $location.url('/');
          }
        };

        /**
         *
         * @param {string} operCauseCode
         * @param {boolean} ignoreValidation
         * @param {{comment: string?, fileModels: object[]?, operationDate: date?}} formData
         * @returns {{comment: (string), ignoreValidation: boolean, operCauseCode: string, uploadedDocumentsIds: string[]}}
         */
        this.mapTerminationRequestEhl = function(operCauseCode, ignoreValidation, formData) {
          var data = {
            comment: !_.isEmpty(formData.comment) ? formData.comment : null,
            operCauseCode: operCauseCode
          };
          // passing ignoreValidation only for PolicyCancellation, because service ignores this attribute in other termination types
          if (operCauseCode === PolicyTerminationConstants.CAUSE_CODES.CANCELLATION) {
            data.ignoreValidation = ignoreValidation;
          }
          var files = _.filter(_.map(formData.fileModels, 'fileServerId'), _.isString);
          data.uploadedDocumentsIds = !_.isEmpty(files) ? files : null;
          // date in ISO format
          data.validFrom = formData.operationDate;
          return self._minifyRequestObject(data);
        };

        this.getPolicyOperationsSvc = function() {
          return ihestiaPlatformHelper.isLifeContext() ? policyOperationsV2Svc : policyOperationsSvc;
        };

        /**
         * Removes attributes with falsy value
         * @param {object} requestData
         * @returns {object}
         * @private
         */
        this._minifyRequestObject = function(requestData) {
          return _.pickBy(requestData, function(value) {
            return _.isBoolean(value) ? true : !!value;
          });
        };

        /**
         *
         * @param {string} terminationType
         * @param {string?} terminationCause - only when terminationType = Termination, it is a cause chosen by user
         * @returns {string}
         */
        this.getOperCauseCodeFromTerminationType = function(terminationType, terminationCause) {
          var operCauseCode;
          switch (terminationType) {
            case PolicyTerminationConstants.TYPES.WITHDRAWAL:
              operCauseCode = PolicyTerminationConstants.CAUSE_CODES.WITHDRAWAL; // Odstąpienie
              break;
            case PolicyTerminationConstants.TYPES.NOTICE: // Wypowiedzenie
              operCauseCode = PolicyTerminationConstants.CAUSE_CODES.NOTICE;
              break;
            default:
              operCauseCode = terminationCause || null;
              break;
          }
          return operCauseCode;
        };
        /**
         *
         * @param {string} operCauseCode
         * @param {string} policyNumber
         */
        this.getTerminationValidFrom = function(operCauseCode, policyNumber) {
          return policyOperationsV2Svc.get(policyNumber + '/terminations/calculateValidFrom', null, null, null, null, {
            params: {
              operCauseCode: operCauseCode
            }
          });
        };

      };


      return new PolicyOperationsHelper();
    }]);
