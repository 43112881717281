angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseRecordsBoxCtrl', ['$scope', 'iHestiaCommonModalHelper', 'ihestiaCommonConfirmModalHelper', '$timeout', '$filter',
    function($scope, iHestiaCommonModalHelper, ihestiaCommonConfirmModalHelper, $timeout, $filter) {



      $scope.showAddRecordModal = function(recordNumber)
      {
        if(!recordNumber && $scope.verificationData.vehicleOwnerChangeRecords && $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1].buyerWithdrawal)
        {
          //nie można dodać kolejnej sprzedaży jak mamy wypowiedzenie
          $scope.showNextSaleBuyerWithdrawalConflictModal();
          return;
        }

        if(!$scope.verificationDataForm.$valid)
        {
          $scope.setFormSubmitted(true);
          var textLabel;
          var isSale = true;
          var titleLabel = 'fillFirstSale';
          if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
          {
            isSale = false;
            titleLabel = 'fillFirstDonation';
          }
          if(recordNumber)
          {
            if(isSale)
            {
              textLabel = 'fillSaleDataToModifyAnother';
            }
            else
            {
              textLabel = 'fillDonationDataToModifyAnother';
            }
          }
          else
          {
            if(isSale)
            {
              textLabel = 'fillSaleDataToAddAnother';
            }
            else
            {
              textLabel = 'fillDonationDataToAddAnother';
            }
          }

          ihestiaCommonConfirmModalHelper.showConfirmModal({
            title: $filter('translate')(titleLabel, {componentCode: 'PolicyOperations'}),
            okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
            cancelBtnName: '',
            text: $filter('translate')(textLabel, {componentCode: 'PolicyOperations'})
          });
        }
        else
        {
          if(recordNumber)
          {
            $scope.vehiclePurchaseRecordEditModal.newRecord = false;
            $scope.vehiclePurchaseRecordEditModal.settings.cancelBtnName = '';
          }
          else
          {
            $scope.vehiclePurchaseRecordEditModal.newRecord = true;
            $scope.vehiclePurchaseRecordEditModal.settings.cancelBtnName = $filter('translate')('Public.cancel', {componentCode: 'Public'});
          }
          $scope.setFormSubmitted(false);
          $scope.saveClients(); //zapisujemy osoby z aplikacji
          if(recordNumber)
          {
            $scope.setCurrentRecord(recordNumber);
            $scope.refreshCurrentRecord();
          }
          else
          {
            $scope.newRecordForModal(); //tworzymy nowy rekord
          }

          $scope.applicationInit(); //każemy aplikacji odświeżyć dane
          $scope.$emit('showBlockUi');
          $scope.vehiclePurchaseRecordEditModal.modalActive = true;
          $timeout(function() {
            iHestiaCommonModalHelper.showModal('vehiclePurchaseRecordEditModal');
          }, 0);

        }
      };

      /**
       * kliknięcie w przycisk usuń
       */
      $scope.deleteLastRecordClick = function()
      {
        var operationTitleLabel = 'nextSaleDeletion';
        var operationConfirmLabel = 'nextSaleDeletionConfirm';
        if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
        {
          operationTitleLabel = 'nextDonationDeletion';
          operationConfirmLabel = 'nextDonationDeletionConfirm';
        }
        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: $filter('translate')(operationTitleLabel, {componentCode: 'PolicyOperations'}),
          okBtnName: $filter('translate')('Public.delete', {componentCode: 'Public'}),
          cancelBtnName: $filter('translate')('Public.cancel', {componentCode: 'Public'}),
          text: $filter('translate')(operationConfirmLabel, {componentCode: 'PolicyOperations'}),
          size: 'sm',
          okBtnCallback: function()
          {
            $scope.deleteLastRecord();
            $scope.refreshBoxData();
          }
        });
      };

      $scope.init = function() {
        if($scope.otherData.goToStepAfterLoad && $scope.otherData.goToStepAfterLoad === 'saleModal')
        {
          if($scope.otherData.boxRecord)
          {
            //wejście z linkiem do otwarcia jednej z już zarejestrowanych sprzedaży
            $scope.showAddRecordModal($scope.otherData.boxRecord);
          }
          else
          {
            //wejście z linkiem do otwarcia kolejnej sprzedaży
            $scope.showAddRecordModal();
          }
          $scope.otherData.goToStepAfterLoad = null;
        }
      };
    }
  ]);