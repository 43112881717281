angular.module('ihestiaCommonDirectives.binaryUpload')
	.factory('BinaryChunkedUploadTransport', ['$http', '$q', 'binaryUploadMessage',
		function($http, $q, binaryUploadMessage) {
			var BinaryChunkedUploadTransport = function() {
				var self = this;

				this.sendingDeffer = null;
				this.promiseToCancel = $q.defer();
				this.actualPart = null;
				this.url = null;
				this.chunkSize = null;
				this.headers = null;
				this.fileData = null;

				/**
				 * [sendFile sluzy do fkatycznego wysylania pliku]
				 * @param  {[FileModelForBinary]} dataFile 
				 * @return {[promise]}      [zwraca obietnice, jesli udalo sie plik wyslac to resolve, jesli nie to reject]
				 */
				this.sendFile = function(dataFile, url, chunkSize, headers) {
					self.sendingDeffer = $q.defer();

					self.url = url;
					self.headers = headers;
					self.chunkSize = chunkSize;
					self.dataFile = dataFile;
					self.actualPart = 0;

					self.processSendingFile(true);

					return self.sendingDeffer.promise;
				};

				this.processSendingFile = function(startSending) {
					if (startSending || self.end < self.dataFile.size) {
						if (!startSending) {
							self.actualPart++;
						}

						self.start = self.actualPart * self.chunkSize;
						self.end = (self.actualPart + 1) * self.chunkSize;
						var headers = angular.copy(self.headers),
						//konczy sie na wielkosci pliku badz na end, bo jesli end dalej to tam nic juz nie ma
						end = self.end > self.dataFile.size ? self.dataFile.size : self.end;

						headers['Content-Range'] = 'bytes ' + self.start + '-' + (end-1) + '/' + self.dataFile.size;

						$http({
							method: 'POST',
							url: self.url,
							headers: headers,
							timeout: self.promiseToCancel.promise.then(angular.noop, angular.noop),
							data: self.dataFile.getPartDataFileForSendingRequest(self.start, self.end),
							transformRequest: angular.identity
						}).then(function(res) {
							if(self.uploadCanceled){
								self.sendingDeffer.reject('requestCancel');
							}else if (res.status === 200) {
								if(self.end < self.dataFile.size){
									//jesli nie byla to ostatnia czesc to wysylamy dalej
									self.processSendingFile();
								}else {
									if (res.data.fileServerId) {
										self.sendingDeffer.resolve(res.data.fileServerId);
									} else {
										self.sendingDeffer.reject({
											data: res.data,
											text: binaryUploadMessage.tokenNotReturnedByService
										});
									}
								}
							} else {
								if(self.uploadCanceled){
									self.sendingDeffer.reject('requestCancel');
								} else {
									self.sendingDeffer.reject(res);
								}
							}
						}, function(res) {
							self.sendingDeffer.reject(res);
						});
					}
				};

				/**
				 * [cancel metoda wywolywana na anulowanie wysylania]
				 * @return {[type]} [description]
				 */
				this.cancel = function(){
					//@todo tutaj nalezy jeszcze sprawdzic czy czasem po wyslaniu nie jest czyms innym
					if(angular.isObject(self.sendingDeffer)){
						self.uploadCanceled = true;
						self.promiseToCancel.resolve('requestCancel');
					}
					
				};

				this.clearCancel = function(){
					self.uploadCanceled = false;
					self.promiseToCancel = $q.defer();
				};

			};

			return BinaryChunkedUploadTransport;
		}
	]);