angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardClientConstants', [function() {
    return {
      TYPE_PERSON: 'PERSON',
      TYPE_COMPANY: 'COMPANY'
    };
  }])
  .factory('IhestiaDashboardClientModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var ClientModel = function() {
      this.objectName = 'IhestiaDashboardClientModel';

      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        version: {
          type: 'int'
        },
        name: {
          type: 'string'
        },
        surname: {
          type: 'string'
        },
        ikontoId: {
          type: 'string'
        },
        phoneNumber: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        nip: {
          type: 'string'
        },
        regon: {
          type: 'string'
        },
        type: {
          type: 'string'
        },
        nationality: {
          type: 'string'
        },
        documentType: {
          type: 'string'
        },
        documentNumber: {
          type: 'string'
        },
        companyName: {
          type: 'string'
        }
      };

      this.id = null;
      this.version = null;
      this.name = null;
      this.surname = null;
      this.ikontoId = null;
      this.phoneNumber = null;
      this.email = null;
      this.pesel = null;
      this.nip = null;
      this.regon = null;
      this.type = null;
      this.nationality = null;
      this.documentType = null;
      this.documentNumber = null;
      this.companyName = null;
    };


    ClientModel.prototype = IHestiaAbstractModel.prototype;

    return ClientModel;
  }]);
