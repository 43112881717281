angular.module('salesPath2')
  .controller('verifyInfoModalCtrl', ['$scope', 'verifyInfoModalHelper', '$rootScope', 'iHestiaCommonModalHelper', 'bonusMalusHelper',
    function($scope, verifyInfoModalHelper, $rootScope, iHestiaCommonModalHelper, bonusMalusHelper) {
      var personFields = ['firstName', 'lastName', 'maidenName', 'pesel'];
      var vehicleFields = ['registration'];
      $scope.modalData = verifyInfoModalHelper.modalData;
      $scope.person = $scope.dataContainer.persons[$scope.modalData.personId];
      $scope.vehicle = $scope.dataContainer.vehicles[$scope.modalData.vehicleId];

      /**
       * Init
       */
      $scope.init = function() {
        if ($scope.modalData.dataCleared) {
          $scope.loadData();
        }
        $scope.fireListeners();
      };

      /**
       * Ładujemy dane osoby/pojazdu, które edytujemy
       * @return {[type]} [description]
       */
      $scope.loadData = function() {
        // zbieramy dane osoby
        angular.forEach(personFields, function(fieldName) {
          $scope.modalData.personData[fieldName] = $scope.person.get(fieldName);
        });
        // zbieramy dane pojazdu
        angular.forEach(vehicleFields, function(fieldName) {
          $scope.modalData.vehicleData[fieldName] = $scope.vehicle.get(fieldName);
        });
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // zapis danych
        $scope.$on('iHestiaCommonModalOkBtnClicked.verifyInfoModal', function() {
          var dataChanged = $scope.save();
          verifyInfoModalHelper.clearModalData();
          if(!dataChanged){
            bonusMalusHelper.showPopup($scope.person.metaData.get('clientId'), $scope.vehicle.metaData.get('clientId'));
          }else{
            iHestiaCommonModalHelper.showTemporaryHiddenPopups();
          }
          $rootScope.$broadcast('bonusMalusDataVerified');
        });

        // anulowanie danych
        $scope.$on('iHestiaCommonModalCancelBtnClicked.verifyInfoModal', function() {
          verifyInfoModalHelper.clearModalData();
          bonusMalusHelper.showPopup($scope.person.metaData.get('clientId'), $scope.vehicle.metaData.get('clientId'));
          $rootScope.$broadcast('bonusMalusDataVerified');
        });
      };

      /**
       * Zapis danych do obiektów
       * @return {[type]} [description]
       */
      $scope.save = function() {
        // zapisujemy dane osoby
        var dataChanged = false;
        angular.forEach(personFields, function(fieldName) {
          if ($scope.modalData.personData[fieldName] !== $scope.person.get(fieldName)) {
            $scope.person.set(fieldName, $scope.modalData.personData[fieldName]);
            dataChanged = true;
          }
        });
        $scope.dataContainer.bonusMalus[$scope.person.metaData.get('clientId')][$scope.vehicle.metaData.get('clientId')].clientDataVerified = true;
        // zapisujemy dane pojazdu
        angular.forEach(vehicleFields, function(fieldName) {
          if ($scope.modalData.vehicleData[fieldName] !== $scope.vehicle.get(fieldName)) {
            var oldData = $scope.vehicle.getData();
            $scope.vehicle.set(fieldName, $scope.modalData.vehicleData[fieldName]);
            $rootScope.$broadcast('vehicleSaved', $scope.modalData.vehicleId, oldData);
            dataChanged = true;
          }
        });

        return dataChanged;
      };

      $scope.init();
    }
  ]);