angular.module('ihestiaWidgets.policyDetails')
  .controller('owuModalCtrl', ['$scope', 'owuModalHelper',
    function($scope, owuModalHelper) {

      $scope.owus = owuModalHelper.owus;

      /**
       * Init
       * @return {unefined}
       */
      $scope.init = function() {
        angular.forEach($scope.owus, function(owu){
          // Bardzo, bardzo, ale to bardzo brzydkie, ale na razie tak musi być -
          // - w przyszłości usługi zrobią odpowiednie grupowanie.
          // 
          // potrzebujemy ikonki jak w IHESTIACL-1019 (dla OC), 
          // a z drugiej strony mamy kody owu takie (IHESTIADA-1656):
          // 
          // Rozp. Min. Fin. Dz. U. 2003.220.2174
          // Rozp. Min. Fin. Dz. U. 2011.293.1729
          // Rozp. Min. Fin. Dz. U. 2013.1616; Rozp. Min. Fin. Dz. U. 2013.1620; Rozp. Min. Fin. Dz. U. 2013.1626;
          // Ustawa Dz.U. z 2003 r., nr 124, poz. 1152, z późn. zm.
          // Ustawa Dz.U.2003.124.1152
          // Ustawa t.j. Dz.U.2013.392 z późn. zm
          // 
          // z czego tylko te wynikające z ustawy są do OC
          owu.hasOcContext = owu.code.toLowerCase().indexOf('ustawa') > -1;
        });
      };
    }
  ]);
