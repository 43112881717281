/**
*  Moduł do zebrania podstawowych zależności
*  
*/
angular.module('ihestiaBase', [
  'ihestiaBasePublic',
  'ihestiaCommonDirectives.uploaderV2',
  'ihestiaWidgets.updateContactData',
  'ihestiaAg.security'
]);
