/**
* Dyrektywa do wrzucenia szablonu navbara
* @param  {[type]}  [description]
* @return {[type]}  [description]
*/
angular.module('ihestiaMenuBase')
    .directive('chatBot', [
        function() {
            return {
                restrict: 'E',
                templateUrl: 'ihestia-menu-template-app/chatBot/chatBot.tpl.html'
            };
        }
    ]);
