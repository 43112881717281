angular.module('iSkanerAztecs')
  .controller('iSkanerAztecsCtrl', ['$scope', '$timeout', '$interval', '$q', '$window', '$filter', 'iSkanerPairedDevicesSvc', 'iSkanerTokenSvc', 'iSkanerEvents', 'iSkanerAztecsListSvc', 'iSkanerConstants', '$alert',
    function($scope, $timeout, $interval, $q, $window, $filter, iSkanerPairedDevicesSvc, iSkanerTokenSvc, iSkanerEvents, iSkanerAztecsListSvc, iSkanerConstants, $alert) {

      $scope.constants = iSkanerConstants;
      
      $scope.init = function() {
        $scope.currentState = $scope.constants.STATE_START;
        iSkanerTokenSvc.fetch($scope.config) //If the user has a pairing token...
          .then(function(currentToken) {
            iSkanerTokenSvc.reset($scope.config.componentId);
            $scope.currentToken = currentToken;
            $scope.openSseForUserWithToken();
            //jesli jest dodane urzadzanie to odrazu startujemy nasluch
          }, function() {
            $scope.openSseForUserWithoutToken();
          });
      };

      $scope.getPairing = function() {
        var deferred = $q.defer();

        $scope.qrData = '';

        iSkanerPairedDevicesSvc
          .getQrCode($scope.config)
          .then(function(response) {
            $scope.qrData = response.data;
            $scope.currentState = $scope.constants.STATE_SHOW_QR;
            deferred.resolve();
          }, function() {
            $scope.qrData = '';
            deferred.reject('Pairing error');
          });

        return deferred.promise;
      };

      $scope.handleEvents = function(typeOfEvent, data) {
        if (typeOfEvent === iSkanerConstants.FINISH_PROCESSING_EVENT) {
          reinitializeIskanerAztecs();
        } else if (typeOfEvent === iSkanerConstants.START_PROCESSING_EVENT) {
          $scope.$emit('iSkanerAztecsProcessingStarted', data);
          //tutaj odrazu powinnismy rozpoczac nasluch
        } else if (typeOfEvent === iSkanerConstants.NEW_DEVICE_EVENT) {
          prepareToReceiveData();
        }

        function prepareToReceiveData() {
          iSkanerTokenSvc.reset($scope.config.componentId);

          //If user pairs up, check if their device is listed as paired...
          iSkanerPairedDevicesSvc.fetch($scope.config)
            .then(function(pairedDevices) {
              $scope.showPairedDevices(pairedDevices);
              //If there is a connected device, the directive can get a new token for datatype 'POLICY' and open SSE.
              iSkanerTokenSvc.fetch($scope.config)
                .then(function(token) {
                  $scope.currentToken = token;
                  iSkanerEvents.close($scope.config.dataType)
                    .finally(function() {
                      $scope.openSse();
                    });
                }, angular.noop);
            }, function() {
              $scope.pairAndOpenSse();
            });
        }

        function reinitializeIskanerAztecs() {
          passAztecToCallback()
            .then(function() {
              return iSkanerEvents.close($scope.config.dataType);
            }, angular.noop);
        }
      };

      $scope.unpairDevices = function() {
        $scope.currentState = $scope.constants.STATE_UNPAIRING_DEVICE;
        angular.forEach($scope.pairedDevicesList, function(device) {
          iSkanerPairedDevicesSvc.unpair(device.deviceID, $scope.config)
            .then(function() {

              iSkanerPairedDevicesSvc.fetch($scope.config)
                .then(function(response) {
                  $scope.pairedDevicesList = response.data;
                }, function() {

                  iSkanerEvents.close($scope.config.dataType)
                    .then(function() {
                      $scope.currentToken = null;
                      iSkanerTokenSvc.reset($scope.config.componentId);
                    }, angular.noop)
                    .finally(function() {
                      $timeout(function(){
                        $scope.resetIskaner();
                      }, 3000);//musi byc to opuznienie inaczej sie usluga sie wysypie
                    });
                });
            }, angular.noop);
        });
      };

      $scope.openSse = function(isNewDeviceEvent) {

        iSkanerEvents.open($scope.currentToken, $scope.config, $scope.handleEvents, isNewDeviceEvent)
          .then(function() {
            if (!isNewDeviceEvent) {
              $scope.$emit('iSkanerAztecsTokenReceived', $scope.currentToken, $scope.config.componentId);
              $scope.currentState = iSkanerConstants.STATE_PROCESSING;
            }
          }, angular.noop);
      };

      $scope.openSseForUserWithoutToken = function() {
        $scope.pairAndOpenSse();
      };

      $scope.openSseForUserWithToken = function() {
        iSkanerPairedDevicesSvc.fetch($scope.config)
          .then(function(pairedDevices) {
            $scope.showPairedDevices(pairedDevices);
            $scope.openSse();
          }, function() {
            $scope.pairAndOpenSse();
          });
      };

      $scope.pairAndOpenSse = function() {
        var newDeviceConfig = JSON.parse(JSON.stringify($scope.config));
        newDeviceConfig.dataType = 'NEWDEVICE';

        $scope.getPairing() //...generate the QR code and...
          .then(function() {
            return iSkanerTokenSvc.fetch(newDeviceConfig); //...get a token for the New Device Event
          }, angular.noop)
          .then(function(token) {
            $scope.currentToken = token;
            return $scope.openSse('NEWDEVICE');
          }, function(){
            $alert({
              content: 'Nasłuch nie został otwarty',
              type: 'warning'
            });
          });
      };

      function passAztecToCallback() {
        var deferred = $q.defer();
        iSkanerAztecsListSvc.getAztecList($scope.currentToken, $scope.config)
          .then(function(response) {
            if (!angular.isArray(response.data)) {
              deferred.resolve();
            } else {
              var aztecId = response.data[0].id;

              iSkanerAztecsListSvc.downloadAztec(aztecId, $scope.currentToken, $scope.config)
                .then(function(response) {
                  $scope.config.callback(response.data.aztecData);
                  cleanUpAztecFromList(aztecId);
                }, function(error) {
                  $scope.config.callback(error);
                });
            }

          }, function(error) {
            $scope.config.callback(error);
          });

        function cleanUpAztecFromList(aztecId) {
          iSkanerAztecsListSvc.deleteAztec(aztecId, $scope.currentToken, $scope.config)
            .finally(function() {
              deferred.resolve();
            });
        }

        return deferred.promise;
      }

      $scope.resetIskaner = function() {
        $scope.pairedDevicesList = [];
        $scope.pairAndOpenSse();
      };

      $scope.showPairedDevices = function(devices) {
        $scope.currentState = $scope.constants.STATE_DEVICE_PAIRED;
        $scope.pairedDevicesList = devices;
      };

      $scope.turnOffISkanerAztecs = function() {

        iSkanerEvents.close($scope.config.dataType)
          .then(function() {
            iSkanerTokenSvc.reset($scope.config.componentId);
          }, angular.noop)
          .finally(function() {
            $scope.resetIskaner();
          });
      };

      $scope.onClickCancel = function(){

        if(angular.isFunction($scope.config.callbackCancel)){
          $scope.config.callbackCancel();
        }

        iSkanerTokenSvc.reset($scope.config.componentId);
      };

      $scope.$on('$destroy', function() {
        iSkanerEvents.close($scope.config.dataType);
      });

      $scope.init();
    }
  ]);