angular.module('lsnBase.validators')
  .directive('clearInvalidDateOnBlur', ['$timeout', function($timeout) { // dziala tylko onBlur. Ustawia poprawny adres url dla linka zewntrznego lub wewnetrznego
      return {
        restrict: 'A',
        require: 'ngModel',
        priority: 1000,
        link: function(scope, element, attrs, modelCtrl) {
          /**
           * czyści date gdy wartość nie jest poprawna
           * @param {String} inputValue
           * @returns {String}
           */
          var clearIfInvalid = function(inputValue) {
            if (modelCtrl.$invalid) {
              inputValue = '';
            }

            element.val(inputValue);
            modelCtrl.$setViewValue(inputValue);
          };

          element.on('blur', function() {
            $timeout(function() {
              clearIfInvalid(element.val());
            });
          });

          scope.$on('$destroy', function(){
            element.off('blur');
          });

          clearIfInvalid(scope[attrs.ngModel]);
        }
      };
    }]);