angular.module('ihestiaWidgets.policyOperations')
.factory('VehicleOwnerChangeRecordModel', [ 'IHestiaAbstractModel', 'IHestiaMetaDataModel',
 function(IHestiaAbstractModel, IHestiaMetaDataModel) {

    var VehicleOwnerChangeRecordModel = function()
    {
      this.objectName = 'VehicleOwnerChangeRecord';

      this.fieldsProperties = {
        metaData: {
          type: 'IHestiaMetaData'
        },
        buyerWithdrawal: {
          type: 'bool',
          defaultValue: false
        },
        currentRegistrationNumber: {
          type: 'string'
        },
        registrationNumberChange: {
          type: 'bool',
          defaultValue: false
        },
        operationDate: {
          type: 'dateTime'
        },
        partnerRoles: {
          type: 'mixed'
        },
        shortages: {
          type: 'array',
          elementsType: 'string'
        }
      };

      this.metaData = new IHestiaMetaDataModel(this.objectName); //MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
      this.buyerWithdrawal = null;
      this.currentRegistrationNumber = null; //numer rej
      this.registrationNumberChange = null; //czy zmienił się nr rej
      this.operationDate = null; //Data operacji
      this.partnerRoles = null; //role osób/organizacji
      this.shortages = null;  //braki
    };

    VehicleOwnerChangeRecordModel.prototype = IHestiaAbstractModel.prototype;

    return VehicleOwnerChangeRecordModel;
  }]);
