angular.module('ihestiaMenuBase', [
  'ihestia-menu-templates-app',
  'ihestia-menu-templates-common',
  'ihestiaSsoBase',
  'ihestiaCommonComponents',
  'ihestiaAg',
  'ihestiaCommonDirectives',
  'menuLeadingColor',
  'ihestiaIkomunikator.common'
])

/**
 * Ustawienia
 * @return {[type]} [description]
 */
.service('iHestiaMenuSettings', function() {
  var settingObj = {
    settings: {}
  };
  return settingObj;
});