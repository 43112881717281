/**
 * Dyrektyka przeskakuje do pierwszego diva z błędem
 * (po klasie has-error) i ustawia focus na inpucie/textarea w środku.
 * Uaktywnia się na event z atrybutu scroll-event
 */
angular.module('ihestiaCommonDirectives')
  .directive('ihestiaScrollToFirstError', ['$anchorScroll', '$location', '$timeout',
    function($anchorScroll, $location, $timeout) {
      // Runs during compile
      return {
        link: function($scope, iElm, iAttrs) {
          $anchorScroll.yOffset = 100;
          $scope.$on(iAttrs.scrollEvent, function() {
            $timeout(function(){
              var firstError = iElm.find('.has-error').first();
              var oldId = firstError.attr('id');
              var errorId = oldId ? oldId : Date.now();
              if (!oldId) {
                firstError.attr('id', errorId);
              }

              if(firstError && firstError[0] && (angular.isUndefined( firstError[0].id ) || firstError[0].id === '') )
              {
                //wcześniej był firstError.context.id, ale to powodowało nadanie takiego id również do elementu iElm
                firstError[0].id = errorId;
                if ($location.hash() !== errorId)
                {
                    $location.hash( errorId );
                }
                else
                {
                    $anchorScroll();
                }
              }
              else if(firstError && firstError[0] && !angular.isUndefined( firstError[0].id ) && angular.isUndefined( oldId ) )
              {
                oldId = firstError[0].id;
                if ($location.hash() !== oldId)
                {
                    $location.hash( oldId );
                }
                else
                {
                    $anchorScroll();
                }
              }
              else
              {
                $anchorScroll(errorId);
                var old = $location.hash();
                $location.hash(errorId);
                $anchorScroll();
                $location.hash(old);
              }
              iElm.find('#' + errorId + ' input,#' + errorId + ' textarea').first().focus();
            });
          });
        }
      };
    }]);
