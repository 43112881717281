angular.module('ihestiaCommonDirectives')
  .factory('ihestiaSaleNavigationConstants', [function() {
    return {
      OFFER_TYPE_IOFFER: 'IOffer',
      OFFER_STATUS_IOFFER: 'IOffer',
      OFFER_TYPE_OFFER: 'Offer',
      OFFER_TYPE_CENTRAL_OFFER: 'CentralOffer',
      OFFER_TYPE_CALCULATION: 'Calculation',
      OFFER_TYPE_APPLICATION: 'Application',
      POLICY_SOURCE_IPEGAZ: 'IPEGAZ',
      POLICY_SOURCE_IHESTIA: 'IHESTIA',
      CLIENT_PAGE_IKONTO: 'IKONTO',
      CLIENT_PAGE_IKONTO_DIRECT: 'IKONTO_DIRECT',
      CLIENT_PAGE_IKONTO_LIFE: 'IKONTO_ZYCIE',
      POLICY_SOURCE_TERMINUS: 'TERMINUS',
      POLICY_SOURCE_BOS: 'BOS',
      POLICY_SOURCE_PARTENON: 'PARTENON',
      IKONTO_DIRECT_BRAND: 'Sprzedaż bezpośrednia', // nie usuwam tego ze względ na kompatybilność wstecz, ale należy używać IKONTO_DIRECT_BRAND_CODE
      IKONTO_DIRECT_BRAND_CODE: 'DIRECT'
    };
  }])
  .factory('ihestiaSaleNavigationHelper', ['$window', 'ihestiaConfigHelper', 'ihestiaCommonErrorHandler', 'ihestiaSaleNavigationConstants', '$filter', 'ihestiaPlatformHelper', 'ihestiaAgHelper', 'ihestiaReportsOfferConstants', 'ihestiaAgConstants', 'lsnUtils',
    function($window, ihestiaConfigHelper, ihestiaCommonErrorHandler, ihestiaSaleNavigationConstants, $filter, ihestiaPlatformHelper, ihestiaAgHelper, ihestiaReportsOfferConstants, ihestiaAgConstants, lsnUtils) {
      var SaleNavigationHelper = function() {
        var self = this;

        /**
         * Prefix do wejścia na ścieżkę do oferty/wniosku
         * @type {Object}
         */
        this.postfixes = {
          application: {},
          policy: 'action=viewPolicy&policyId={policyId}',
          policyByNumber: 'action=viewPolicy&policyNumber={policyNumber}'
        };
        this.postfixes.application[ihestiaSaleNavigationConstants.OFFER_TYPE_APPLICATION] = 'action=viewApplication&applicationId={applicationId}';
        this.postfixes.application[ihestiaSaleNavigationConstants.OFFER_TYPE_CALCULATION] = 'action=viewApplication&applicationId={applicationId}';
        this.postfixes.application[ihestiaSaleNavigationConstants.OFFER_TYPE_IOFFER] = 'action=viewOffer&offerId={applicationId}';
        this.postfixes.application[ihestiaSaleNavigationConstants.OFFER_TYPE_OFFER] = 'action=viewOffer&offerId={applicationId}';
        this.postfixes.application[ihestiaSaleNavigationConstants.OFFER_TYPE_CENTRAL_OFFER] = 'action=viewOffer&offerId={applicationId}';

        /**
         * getter dla postfixow dla aplikacji poza lsn
         * (ycd/mtu/ocn)
         * @param  {Object} application [description]
         * @return {String}
         */
        this.defaultOuterPostFixGetter = function(application) {
          var base = '?';
          if ((application.typeCode + '').toLowerCase().indexOf('offer') > -1) {
            base += 'offerVer=' + application.metaData.id;
          } else {
            base += 'applicationVer=' + application.metaData.id;
          }
          return base;
        };

        /**
         * Mapa kodów produktu na konfigurację do budowania bezpośredniego
         * urla do podglądu kalkulacji/oferty
         * @type {Object}
         */
        this.saleUrlConfig = {
          H1H7V: {
            code: 'VINTAGE_URL',
            postfix: 'external/init?'
          },
          H1V: {
            code: 'VINTAGE_URL',
            postfix: 'external/init?'
          },
          H7V: {
            code: 'VINTAGE_URL',
            postfix: 'external/init?'
          },
          E7: {
            code: 'E7_URL',
            postfix: 'external/init?'
          },
          E4: {
            code: 'E4_URL',
            postfix: 'external/init?',
            usePolicyByNumber: true
          },
          ERGO4: {
            code: 'E4_URL',
            postfix: 'external/init?'
          },
          E1: {
            code: 'E1_URL',
            postfix: 'external/init?'
          },
          EP: {
            code: 'EP_URL',
            postfix: 'external/init?'
          },
          ES: {
            code: 'ES_URL',
            postfix: 'external/init?'
          },
          NMF: {
            code: 'MF_URL',
            postfix: 'external/init?'
          },
          MD: {
            code: 'MD_URL',
            postfix: 'external/init?'
          },
          SMD: {
            code: 'MD_URL',
            postfix: 'external/init?'
          },
          SOCT: {
            code: 'OCK_URL',
            postfix: 'external/init?'
          },
          SOCZ: {
            code: 'OCZ_URL',
            postfix: 'external/init?'
          },
          JUPITER: {
            code: 'JUPITER_URL',
            postfix: 'external/salesPath/init?'
          },
          ERGOBIZNES: {
            code: 'CORPO_URL',
            postfix: 'init?',
            usePolicyByNumber: true
          },
          OUTER: {
            getPostfix: function(application, prefix) {
              return prefix + self.defaultOuterPostFixGetter(application);
            }
          }
        };

        /**
         * Mapa kodów symboli w polisie na saleUrlConfig
         * @type {Object}
         */
        this.policyProductCodeToSaleUrlConfig = {
          'NE7': 'E7',
          'NE7T': 'E7',
          'E1': 'E1',
          'E1T': 'E1',
          'E1KOM': 'E1',
          'E1MIENIE': 'E1',
          'E7KOM': 'E7',
          'NE7MIENIE': 'E7',
          'NE7KOM': 'E7',
          'E7MIENIE': 'E7',
          'NMF': 'NMF',
          'NMFT': 'NMF',
          'NMFKOM': 'NMF',
          'NMFMIENIE': 'NMF',
          'SMD': 'SMD',
          'SOCT': 'SOCT',
          'SOCZ': 'SOCZ',
          'H7': 'H1H7V',
          'H7T': 'H1H7V',
          'H1': 'H1H7V',
          'H1T': 'H1H7V',
          'KOMH1V': 'H1H7V',
          'KOMH7V': 'H1H7V',
          'KOMH1T': 'H1H7V',
          'H1V': 'H1H7V',
          'H7V': 'H1H7V',
          'EP': 'EP',
          'ES': 'ES'
        };

        /**
         * Mapa dodatkowa do aplikacji poza lsn
         * @type {Object}
         */
        this.outerMap = {
          'I/KDIR/MTU24.PL': {
            urlCode: 'MTU24_URL',
            prefix: 'motor/ikdirmtu24pl/vehicle'
          },
          'I/KDIR/YCD': {
            urlCode: 'YCD_URL',
            prefix: 'motor/ikdirycd/vehicle'
          },
          'I/PDIR/MTU24.PL': {
            urlCode: 'MTU24_URL',
            prefix: 'travel/ipdirmtu24pl/travel'
          },
          'I/PDIR/YCD': {
            urlCode: 'YCD_URL',
            prefix: 'travel/ipdirycd/travel'
          },
          'I/MDIR/MTU24.PL': {
            urlCode: 'MTU24_URL',
            prefix: 'house/imdirmtu24pl/property'
          },
          'I/MDIR/YCD': {
            urlCode: 'YCD_URL',
            prefix: 'house/imdirycd/property'
          },
          'I/KDIR/OCN': {
            urlCode: 'MTU24_URL',
            prefix: 'motor/ikdirocn/vehicle'
          }
        };

        /**
         * Produkty direct/polisaonline/jupiter/etc...
         * @type {Array}
         */
        this.directProducts = [
          '6/2007/BUI',
          'JR/KDIR/EH',
          'JR/KDIR/MTU',
          'JR/MDIR/EH',
          'JR/MDIR/MTU',
          'JR/PDIR/EH',
          'KDIR',
          'MILLENIUM/KDir/EH',
          'MILLENIUM/KDir/YCD',
          'MILLENNIUM/KDir/EH',
          'MILLENNIUM-KDIR',
          'MILLENNIUM-PDIR',
          'MTU24',
          'MTU24.PL',
          'YCD'
        ];

        /**
         * Znane produkty, dla których nie ma linku.
         * @type {Array}
         */
        this.notClickableProducts = [
          'MM/PDIR/MTU24.PL',
          'JR/KDIR/ASF',
          'I/KDIR/EHLITE',
          'JR/KDIR/EHLITE'
        ];

        this.allowedMillenniumProducts = [
          'MM/KDIR/EH',
          'MM/KDIR/MTU24.PL',
          'MM/KDIR/YCD',
          'JR/KDIR/YK'
        ];

        // Jupiter - only internal products - general agreement number list
        this.jupiterProducts = [
          'JR/KDIR/EH',
          'JR/KDIR/MTU',
          'JR/MDIR/EH',
          'JR/MDIR/MTU',
          'JR/PDIR/EH'
        ];

        this.notClickableOfferStatuses = [
          ihestiaReportsOfferConstants.OFFER_STATUS_CALCULATION_DELETED,
          ihestiaReportsOfferConstants.OFFER_STATUS_IOFFER_CANCELLED,
          ihestiaReportsOfferConstants.OFFER_STATUS_OFFER_DELETED
        ];

        /**
         * Zwraca saleUrlConfig na podstawie produktu kalkulacji
         * @param  {Object} productCode kod produktu
         * @return {Object}
         */
        this.getSaleUrlCfgForAppProduct = function(productCode) {
          var resolvedProductCode = (productCode + '').toUpperCase();
          if (self.directProducts.indexOf(resolvedProductCode) > -1) {
            resolvedProductCode = 'JUPITER';
          }
          var saleUrlConfig = self.saleUrlConfig[resolvedProductCode];
          if (!saleUrlConfig && self.outerMap[resolvedProductCode]) {
            var outerConfig = self.outerMap[resolvedProductCode];
            saleUrlConfig = {
              code: outerConfig.urlCode
            };
            saleUrlConfig.getPostfix = function(application) {
              return self.saleUrlConfig.OUTER.getPostfix(application, outerConfig.prefix);
            };
          }
          if (!saleUrlConfig || !saleUrlConfig.code) {
            ihestiaCommonErrorHandler.throwException('No config for product ' + productCode);
          }

          return saleUrlConfig;
        };

        /**
         * Zwraca saleUrlConfig na podstawie produktu polisy
         * @param  {Object} productCode kod produktu
         * @return {Object}
         */
        this.getSaleUrlCfgForPolProduct = function(productCode) {
          var resolvedProductCode = (productCode + '').toUpperCase();
          if (self.directProducts.indexOf(resolvedProductCode) > -1) {
            resolvedProductCode = 'JUPITER';
          } else if (self.policyProductCodeToSaleUrlConfig[resolvedProductCode]) {
            resolvedProductCode = self.policyProductCodeToSaleUrlConfig[resolvedProductCode];
          } else if (self.getSaleUrlCfgForAppProduct(productCode)) {
            return self.getSaleUrlCfgForAppProduct(productCode);
          } else {
            ihestiaCommonErrorHandler.throwException('No such policy map: ' + productCode);
          }

          return self.getSaleUrlCfgForAppProduct(resolvedProductCode);
        };

        /**
         * Zwraca url do ścieżki do kalkulacji/oferty
         * @param  {Object} application obiekt wniosku
         * @return {String}               bezpośredni url do ścieżki
         */
        this.getSaleApplicationUrl = function(application) {
          if (!self.postfixes.application[application.typeCode]) {
            ihestiaCommonErrorHandler.throwException('No such mode: ' + application.typeCode);
          }
          var saleUrlConfig = self.getSaleUrlCfgForAppProduct(application.product);
          var postfix = ''; // NOSONAR
          if (angular.isFunction(saleUrlConfig.getPostfix)) {
            postfix = saleUrlConfig.getPostfix(application);
          } else {
            postfix = saleUrlConfig.postfix ? saleUrlConfig.postfix : '';
            postfix = postfix + self.postfixes.application[application.typeCode].replace('{applicationId}', application.metaData.id);
          }
          //https://dev-mf-trunk.atena.pl/#/init?action=viewApplication&applicationId=245766
          var url = ihestiaConfigHelper.getUrl(saleUrlConfig.code, postfix);
          if (!url) {
            ihestiaCommonErrorHandler.throwException('No external url for code ' + saleUrlConfig.code);
          }
          //w przekierowaniach na sprzedażówkę dodajemy parametr z datą podpisania umowy
          if (angular.isString(application.salesDate)) {
            url = url + '&version=' + $filter('date')(application.salesDate, 'yyyyMMdd');
          }

          return url;
        };

        /**
         * Zwraca url do ścieżki do polisy
         * @param  {Object} policy obiekt polisy
         * @return {String}               bezpośredni url do ścieżki
         */
        this.getSalePolicyUrl = function(policy) {
          var saleUrlConfig = null;
          var policyPostfix = self.postfixes.policy;
          if (policy.platform === 'Life') {
            // todo this should be temporary (IHESTLIFE-987)
            saleUrlConfig = self.saleUrlConfig.E4;
          } else {
            saleUrlConfig = self.getSaleUrlCfgForPolProduct(policy.product);
          }

          if (!saleUrlConfig || !saleUrlConfig.code) {
            ihestiaCommonErrorHandler.throwException('Na config for product ' + policy.product);
          }

          if (saleUrlConfig && saleUrlConfig.usePolicyByNumber) {
            policyPostfix = self.postfixes.policyByNumber;
          }

          var postfix = saleUrlConfig.postfix ? saleUrlConfig.postfix : '';
          var urlCode = saleUrlConfig.code;


          postfix = postfix + policyPostfix.replace('{policyId}', self._getPolicyId(policy));
          postfix = postfix.replace('{policyNumber}', self._getPolicyNumber(policy));
          var url = ihestiaConfigHelper.getUrl(urlCode, postfix);
          if (!url) {
            ihestiaCommonErrorHandler.throwException('No external url for code ' + urlCode);
          }

          return url;
        };

        /**
         *
         * @param policy
         * @returns {*}
         * @private
         */
        this._getPolicyNumber = function(policy) {
          if (policy.policyNumber) {
            return policy.policyNumber;
          } else if (policy.number) {
            return policy.number;
          }

          return '';
        };

        /**
         * Czy to polisa, która powinna trafić do polisaonline (direct)
         * @return {Boolean} [description]
         */
        this.isPoPolicy = function(policy) {
          var isDirectBrand = policy.brand === ihestiaSaleNavigationConstants.IKONTO_DIRECT_BRAND || policy.brandCode === ihestiaSaleNavigationConstants.IKONTO_DIRECT_BRAND_CODE;
          var isJupiterPlatform = ihestiaPlatformHelper.isAnyJupiterPlatform(policy.platform);
          return isDirectBrand || isJupiterPlatform;
        };

        /**
         * Zwraca url do ścieżki na podstawie pakietu polisy
         * @param  {String|Object} policyProduct
         * @return {String} bezpośredni url do ścieżki
         */
        this.getSalePathUrl = function(policyProduct) {
          var productCode = angular.isString(policyProduct) ? policyProduct : policyProduct.product;
          productCode = (productCode + '').toUpperCase();

          var saleUrlConfig = self.getSaleUrlCfgForPolProduct(productCode);
          if (!saleUrlConfig || !saleUrlConfig.code) {
            ihestiaCommonErrorHandler.throwException('Na config for product ' + productCode);
          }
          var postfix = saleUrlConfig.postfix ? saleUrlConfig.postfix : '';
          var url = ihestiaConfigHelper.getUrl(saleUrlConfig.code, postfix);
          if (!url) {
            ihestiaCommonErrorHandler.throwException('No external url for code ' + saleUrlConfig.code);
          }

          return url;
        };

        /**
         * Get url to salespath - for Jupiter/Jupiter+ products
         * @param  {String} generalAgreementNumber [description]
         * @return {String} url to salespath
         */
        this.getSalePathUrlByGeneralAgreementNum = function(generalAgreementNumber) {
          var generalAgreement = (generalAgreementNumber).toUpperCase();
          var saleUrlConfig = '';

          if (self.jupiterProducts.indexOf(generalAgreement) > -1) {
            // internal Jupiter products
            saleUrlConfig = self.getSaleUrlCfgForAppProduct('JUPITER');
          } else if (self.outerMap[generalAgreement]) {
            saleUrlConfig = {
              code: self.outerMap[generalAgreement].urlCode,
              postfix: self.outerMap[generalAgreement].prefix + '?'
            };
          } else {
            ihestiaCommonErrorHandler.throwException(
              'No salespath url defined for generalAgreementNumber ' + generalAgreementNumber
            );
          }

          var postfix = saleUrlConfig.postfix ? saleUrlConfig.postfix : '';
          var url = ihestiaConfigHelper.getUrl(saleUrlConfig.code, postfix);

          if (!url) {
            ihestiaCommonErrorHandler.throwException('No salespath url defined for generalAgreementNumber ' + generalAgreementNumber);
          }

          return url;
        };


        /**
         * Otwiera polisę, w której wczytany zostanie dany wniosek
         * ipegaz/ikonto/ikonto direct/ścieżki sprzedażowe
         *
         * @param  {ApplicationModel} policy   polisa
         * @param  {string|null} target        domyślnie nowe okno
         * @param  {Boolean|null} forceSalePath jeśli true, to przechodzimy do ścieżki sprzedażowej
         * @return {undefined}
         */
        this.openPolicy = function(policy, target, forceSalePath) {
          var url = self.getPolicyUrl(policy, target, forceSalePath);

          self.openWindow(url, target);
        };

        /**
         * Zwraca url do aplikacji, w której wczytany zostanie dany wniosek
         * ipegaz/ikonto/ikonto direct/ścieżki sprzedażowe
         *
         * @param  {ApplicationModel} policy   polisa
         * @param  {string|null} target        domyślnie nowe okno
         * @param  {Boolean|null} forceSalePath jeśli true, to przechodzimy do ścieżki sprzedażowej
         * @return {String} url
         */
        this.getPolicyUrl = function(policy, target, forceSalePath) {
          var url = ''; // NOSONAR
          target = target ? target : '_blank';
          // najpierw sprawdzenie, czy mamy do czynienia z jupiterem
          // jeśli wejdziemy w ify dalej, to będziemy mieć problem z jupiterowymi mapowaniami
          if (!forceSalePath && self.isPoPolicy(policy) && policy.customerAccountId) {
            // ikonto direct
            url = ihestiaConfigHelper.getUrl('IKONTO_DIRECT_URL', 'external/policy/' + (policy.number ? policy.number : policy.policyNumber) + '/?customerContextId=' + policy.customerAccountId);
          } else if (!forceSalePath && policy.customerAccountId && self.isLife()) {
            url = ihestiaConfigHelper.getUrl('IKONTO_ZYCIE_URL', 'external/policy/' + (policy.number ? policy.number : policy.policyNumber) + '/?customerContextId=' + policy.customerAccountId);
          } else {
            if (policy.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_TERMINUS) {
              url = ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'OfferOcPreview.aspx?terminusID=' + policy.externalId + '&isFromPlatform=true');
            } else if (policy.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_IPEGAZ ||
              policy.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_BOS ||
              policy.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_PARTENON
            ) {
              // ipegaz
              url = self.getPolicyInfoUrl(policy);
            } else if (policy.customerAccountId && !forceSalePath) {
              // ikonto
              url = ihestiaConfigHelper.getUrl('IKONTO_URL', 'external/policy/' + (policy.number ? policy.number : policy.policyNumber) + '/?customerContextId=' + policy.customerAccountId);
            } else {
              // ścieżka sprzedażowa
              url = self.getSalePolicyUrl(policy);
            }

          }

          return url;
        };

        /**
         * Otwiera ścieżkę sprzedażową z zadaną polisą
         * zakładamy, że trafia tu polisa ihestyjna z usługi:
         * /api/insurance/v2/policies/
         *
         * @param  {PolicyModal} policyObject obiekt polisy
         * @return {undefined}
         */
        this.openFullPolicyInSalesPath = function(policyObject, target) {
          target = target ? target : '_blank';
          if (!policyObject || !policyObject.symbol) {
            ihestiaCommonErrorHandler.throwException('No policy.symbol or wrong policyModel given!');
          }
          var policySymbol = policyObject.symbol.toUpperCase();
          if (policyObject.product && policyObject.product.dynamicValues && policyObject.product.dynamicValues.application === 'ebiznes') {
            // polisa corpo w ikoncie, dla niej symbol dostajemy Niepakietowe, więc po nim nie możemy rozpoznać
            policySymbol = 'ERGOBIZNES';
          }
          var saleUrlConfig = self.getSaleUrlCfgForPolProduct(policySymbol);

          var postfix = saleUrlConfig.postfix ? saleUrlConfig.postfix : '';
          if (saleUrlConfig && saleUrlConfig.usePolicyByNumber) {
            postfix = postfix + self.postfixes.policyByNumber.replace('{policyNumber}', self._getPolicyNumber(policyObject));
          } else {
            postfix = postfix + self.postfixes.policy.replace('{policyId}', self._getPolicyId(policyObject));
          }
          if (policyObject.salesDate) {
            // https://ebok.atena.pl/browse/IHESTIAOA-1741
            postfix += '&version=' + (new XDate(policyObject.salesDate)).toString('yyyyMMdd');
          }
          var url = ihestiaConfigHelper.getUrl(saleUrlConfig.code, postfix);
          if (!url) {
            ihestiaCommonErrorHandler.throwException('No external url for code ' + saleUrlConfig.code);
          }
          self.openWindow(url, target);
        };

        /**
         * Wygenerowanie adresu url do iPegazowego PolicyInfo.
         * @param  {Object} policy
         */
        this.getPolicyInfoUrl = function(policy) {
          return ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'PolicyInfo.aspx?PolicyNo=' + policy.policyNumber + '&isFromPlatform=true');
        };

        /**
         * Otwiera iPegazowe PolicyInfo.
         * @param  {Object} policy
         * @param  {String} target
         */
        this.openPolicyInfo = function(policy, target) {
          var url = self.getPolicyInfoUrl(policy);
          target = target ? target : '_blank';
          if (url) {
            self.openWindow(url, target);
          }
        };

        /**
         * Odpalamy ikonto lub ikonto direct z kontekstem zadanego klienta
         * @param  {String} customerAccountId clientId
         * @param  {String} type typ konta - ikonto, ipegaz etc.
         * @param  {Sring|null} target            domyślnie nowe okno
         * @return {undefined}
         */
        this.openCustomerPage = function(customerAccountId, type, target) {
          target = target ? target : '_blank';
          var url = self.getCustomerPageUrl(customerAccountId, type);
          if (url) {
            self.openWindow(url, target);
          }
        };

        /**
         * Pobiera adres do ikonto lub ikonto direct z kontekstem zadanego klienta
         * @param  {String} customerAccountId clientId
         * @param  {String} type typ konta - ikonto, ipegaz etc.
         * @return {String} adres do ikonto lub ikonto direct z kontekstem zadanego klienta
         */
        this.getCustomerPageUrl = function(customerAccountId, type) {
          var url = '';
          if (type === ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO) {
            url = ihestiaConfigHelper.getUrl('IKONTO_URL', 'external/client?customerContextId=' + customerAccountId);
          }

          if (type === ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO_DIRECT) {
            url = ihestiaConfigHelper.getUrl('IKONTO_DIRECT_URL', 'external/client?customerContextId=' + customerAccountId);
          }

          if (type === ihestiaSaleNavigationConstants.CLIENT_PAGE_IKONTO_LIFE) {
            url = ihestiaConfigHelper.getUrl('IKONTO_ZYCIE_URL', 'external/client?customerContextId=' + customerAccountId);
          }

          return url;
        };

        /**
         * Otwiera aplikację, w której wczytany zostanie dany wniosek
         * ipegaz/ikonto/ścieżki sprzedażowe
         *
         * @param  {ApplicationModel} application   wniosek/oferta/ioferta
         * @param  {string|null} target        domyślnie nowe okno
         * @param  {Boolean|null} forceSalePath jeśli true, to przechodzimy do ścieżki sprzedażowej
         * @return {undefined}
         */
        this.openApplication = function(application, target, forceSalePath) {
          var url = ''; // NOSONAR
          target = target ? target : '_blank';
          // czy otwierać link do iknto/polisaonline
          var openInClientOffer = application.typeCode === ihestiaSaleNavigationConstants.OFFER_TYPE_IOFFER &&
            application.statusCode === ihestiaSaleNavigationConstants.OFFER_STATUS_IOFFER &&
            application.platform !== 'Corpo';
          if (application.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_TERMINUS) {
            url = ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'OfferOcPreview.aspx?terminusID=' + application.externalId + '&isFromPlatform=true');
          } else if (application.source === ihestiaSaleNavigationConstants.POLICY_SOURCE_IPEGAZ) {
            url = ihestiaConfigHelper.getUrl('IPEGAZ_URL', 'SalePathEntryPoint.aspx?ver=' + application.externalId + '&isFromPlatform=true');
          } else if (openInClientOffer && application.customerAccountId && !forceSalePath) {
            // ikonto - przejście tylko dla iOfert aktualnych IHESTIASIA-455
            var ikontoUrlCode = 'IKONTO_URL';
            if (ihestiaPlatformHelper.isAnyJupiterPlatform(application.platform)) {
              ikontoUrlCode = 'IKONTO_DIRECT_URL';
            } else if (self.isLife()) {
              ikontoUrlCode = 'IKONTO_ZYCIE_URL';
            }
            url = ihestiaConfigHelper.getUrl(ikontoUrlCode, 'external/offer/' + application.metaData.id + '/?customerContextId=' + application.customerAccountId);
          } else {
            // ścieżka sprzedażowa
            url = self.getSaleApplicationUrl(application);
          }
          self.openWindow(url, target);
        };

        /**
         * Zwraca id polisy
         * @param  {Object} policy mixed policy
         * @return {int} id polisy
         */
        this._getPolicyId = function(policy) {
          var policyId = null;
          if (angular.isObject(policy) && policy !== null) {
            if (angular.isObject(policy.metaData) && policy.metaData !== null) {
              policyId = policy.metaData.id;
            } else if (angular.isObject(policy.metadata) && policy.metadata !== null) {
              policyId = policy.metadata.id;
            } else if (policy.policyId) {
              return policy.policyId;
            }
          }

          return policyId;
        };

        /**
         * Czy mamy gdzie podlinkować ofertę
         * @param  {Object} offer oferta/kalkulacja
         * @return {bool}
         */
        this.canLinkToOffer = function(offer) {
          var acceptedStatus = self.notClickableOfferStatuses.indexOf(offer.statusCode) === -1;
          var acceptedProduct = self.notClickableProducts.indexOf(offer.product) === -1 && self.allowedMillenniumProducts.indexOf(offer.product) === -1;

          // for life ANNIVERSARies only ioffer with customerAccountId can link (IHESTLIFE-5278)
          if (offer.subTypeCode === ihestiaReportsOfferConstants.OFFER_SUB_TYPE_CODE_ANNIVERSARY) {
            if (offer.statusCode === ihestiaReportsOfferConstants.OFFER_STATUS_IOFFER && offer.customerAccountId) {
              return true;
            } else {
              return false;
            }
          }

          // for now we only enable link for millennium offer in status 'iOffer' (iOferta aktualna)
          if (self.allowedMillenniumProducts.indexOf(offer.product) !== -1 && offer.statusCode === ihestiaReportsOfferConstants.OFFER_STATUS_IOFFER) {
            return true;
          } else if (!acceptedStatus || !acceptedProduct) {
            return false;
          }
          return true;
        };

        /**
         * Sprawdzamy, czy zalogowany użytkownik to centrala
         * @return {Boolean} [description]
         */
        this.isCentral = function() {
          return ihestiaAgHelper.getUserRole() === ihestiaAgConstants.USER_TYPE_CENTRAL;
        };

        this.isLife = function() {
          return ihestiaConfigHelper.get('ic').activeContext === 'EHL';
        };

        /**
         * Czy wyświetlamy link do polisy
         * @param  {Object} policy obiekt polisy z grida lub PolicyModel
         * @return {Boolean}
         */
        this.canLinkToPolicy = function(policy) {
          var showPolicyLink = false;
          if (ihestiaPlatformHelper.isJupiter()) {
            // w jupiterze stos warunków
            var isJupiterPolicy = ihestiaPlatformHelper.isAnyJupiterPlatform(policy.platform);
            if (policy.customerAccountId && isJupiterPolicy) {
              // dla polis z Jupitera/ Jupitera + , które mają iKonto - link do  polisaonline.ergohestia.pl -
              showPolicyLink = true;
            } else if (!isJupiterPolicy && policy.source !== ihestiaSaleNavigationConstants.POLICY_SOURCE_IHESTIA) {
              // dla polis z iPegaz/xPegaz link do systemu iPegaz/xPegaz (podgląd szczegółów polisy)
              showPolicyLink = true;
            }
            // dla polis z Jupitera/ Jupitera + , które nie mają iKonta - brak linka
            // dla polis z iHestii - brak linka
          } else {
            // w ihestii "po staremu"
            showPolicyLink = true;
          }

          if (policy.source === 'MTU') {
            showPolicyLink = false;
          }

          return showPolicyLink;
        };

        /**
         * Czy pokazujemy link do klienta
         * @param  {Object} application poliska lub oferta lub kalkulacja
         * @return {Boolean}
         */
        this.canLinkToCustomer = function(application) {
          var showCustomerLink = false;
          if (application.customerAccountId) {
            // ikonto lub polisaonline
            if (ihestiaPlatformHelper.isAnyJupiterPlatform(application.platform)) {
              // uprawnienie do polisaonline i strony głównej polisaonline
              showCustomerLink = ihestiaAgHelper.hasPermissionFor('Public', 'MainPageiKontoJupiter') && ihestiaAgHelper.hasPermissionFor('Public', 'iKontoDirect');
            } else if (ihestiaPlatformHelper.isLifeContext()) {
              showCustomerLink = ihestiaAgHelper.hasPermissionFor('Public', 'iKontoLife');
            } else {
              // w ihestii tylko uprawnienie do ikonta
              showCustomerLink = ihestiaAgHelper.hasPermissionFor('Public', 'iKontoiHestia');
            }
          } else if (application.source !== ihestiaSaleNavigationConstants.POLICY_SOURCE_IHESTIA) {
            // dla polis z iPegaz/xPegaz link do systemu iPegaz/xPegaz (podgląd szczegółów polisy)
            showCustomerLink = true;
          }

          return showCustomerLink;
        };

        /**
         *
         * @param url
         * @param target
         */
        this.openWindow = function(url, target) {
          lsnUtils.openWindow(url, target);
        };

      };

      return new SaleNavigationHelper();
    }
  ]);
