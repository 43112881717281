angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardOfferObjectConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardOfferObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {

      var OfferObjectModel = function() {
        this.objectName = 'IhestiaDashboardOfferObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          },
          segment: {
            type: 'string'
          },
          client: {
            type: 'IhestiaDashboardClient'
          },
          offerNumber: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_OFFER;
        this.segment = null;
        this.client = null;
        this.offerNumber = null;

      };


      OfferObjectModel.prototype = IHestiaAbstractModel.prototype;

      return OfferObjectModel;
    }]);