  angular.module('ihestiaCommonDirectives.starRating')
    .directive('lsnStarRating', [function () {
        var directive = {
            restrict: 'EA',
            replace: true,
            templateUrl: 'ihestia-commondirectives-template-app/directives/starRating/starRating.tpl.html',
            require: 'ngModel',
            scope: {
                ngModel: '='
            },
            link: function(scope, element, attrs, ngModelCtrl) {
            var computed = {
                get readonly() {
                    return attrs.readonly !== 'false' && (attrs.readonly || attrs.readonly === '');
                },
                get fullIcon() {
                    return attrs.iconFull || 'icon-sl-fill icon-star-1';
                },
                get emptyIcon() {
                    return attrs.iconEmpty || 'icon-sl-outline icon-star-1';
                },
                get iconBase() {
                    return attrs.iconBase || 'icon-sl-outline icon-star-1';
                },
                get iconHover() {
                    return attrs.iconHover || 'icon-sl-hover icon-star-hover-1';
                }
            };

            scope.itemsNumber = attrs.max;
            scope.listClass = attrs.listClass || 'lsn-star-rating';

            ngModelCtrl.$render = function () {
                scope.lastValue = ngModelCtrl.$viewValue || 0;
            };

            scope.getClass = function (index) {
                var icon = index >= scope.lastValue ? computed.iconBase + ' ' + computed.emptyIcon : computed.iconBase + ' ' + computed.fullIcon + ' active ';
                return computed.readonly ? icon + ' readonly' : icon;
            };

            scope.unpaintStars = function ($index, hover) {
                scope.paintStars(scope.lastValue - 1, hover);
            };

            scope.paintStars = function ($index, hover) {
                // ignore painting if readonly
                if (computed.readonly) {
                    return;
                }

                var items = element.find('li').find('i');

                for (var index = 0; index < items.length; index++) {
                    var $star = angular.element(items[index]);

                    if ($index >= index) {
                        $star.removeClass(computed.emptyIcon);
                        $star.addClass(computed.fullIcon);
                        $star.addClass('active');
                        $star.addClass(computed.iconHover);
                    } else {
                        $star.removeClass(computed.fullIcon);
                        $star.removeClass('active');
                        $star.removeClass(computed.iconHover);
                        $star.addClass(computed.emptyIcon);

                    }
                }

                if (! hover) {
                    items.removeClass(computed.iconHover);
                }
            };

            scope.setValue = function (index) {
                // ignore setting value if readonly
                if (computed.readonly) {
                    return;
                }

                var newValue = index + 1;

                // sets to 0 if the user clicks twice on the first "star"
                // the user should be allowed to give a 0 score
                if (newValue === scope.lastValue && newValue === 1) {
                    newValue = 0;
                }

                scope.lastValue = newValue;


                ngModelCtrl.$setViewValue(newValue);
                ngModelCtrl.$render();

                //Execute custom trigger function if there is one
                if(attrs.onStarClick){
                    scope.$eval(attrs.onStarClick);
                }

            };
        }
        };

        return directive;
    }]);
