angular.module('salesPath2')
  .service('localizationModalHelper', ['mainDataContainer', 'RESOURCES', 'CONSTANTS', 'sp2CommonHelper', 'EstateModel', 'AddressModel', 'localizationHelper', 'appVariables', 'allowedChangesHelper', 'dataContainerHelper', 'errorHelper', 'MessageModelConstants',
    function(mainDataContainer, RESOURCES, CONSTANTS, sp2CommonHelper, EstateModel, AddressModel, localizationHelper, appVariables, allowedChangesHelper, dataContainerHelper, errorHelper, MessageModelConstants) {
      var LocalizationModalHelper = function() {
        var self = this;
        //ustawienia modali
        this.modals = {};

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.localizationAddModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.localizationAddModal.title',
            cancelBtnName: 'sp2.modal.cancel',
            okBtnName: '',
            size: 'sm',
            keyboard: false
          }
        };

        /**
         * edycja osoby
         * @type {Object}
         */
        this.modals.localizationEditModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.localizationEditModal.title',
            cancelBtnName: 'sp2.modal.cancel',
            okBtnName: 'sp2.modal.okBtnName',
            headerList: [],
            headerListModel: 'modals.localizationEditModal.type',
            size: 'md',
            keyboard: false
          },
          type: null, //dodanie nowej
          id: null //edycja istniejącej
        };

        var DEFAULT_EDIT_MODAL_PARAMS = {
          type: null,
          editedId: null,
          localization: null,
          localizationData: null,
          attributeVisibility: {}, // { [attr_code as string]: false|true, ... }
          allowedChanges: {}, //obiekt przechowujący konfigurację dozwolonych zmian na formularzu
          messages: {
            warning: [],
            error: []
          }
        };


        this.currentlyEditedLocalizationData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);

        /**
         * Ustawiamy domyślne wartości parametrów edycji lokalizacji
         * @return {localizationModalHelper}
         */
        this.clearEditModalData = function() {
          self.currentlyEditedLocalizationData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);
          return self;
        };

        this.showAddLocalizationModal = function() {
          sp2CommonHelper.showModal('localizationAddModal');
        };

        /**
         * okno edycji lokalizacji
         */
        this.showEditLocalizationModal = function(clientId) {
          self.modals.localizationEditModal.type = (parseInt(clientId, 10) > 0) ? mainDataContainer.localizations[clientId].get('type') : null;

          var selectedVariant = dataContainerHelper.getVariantsForProduct(CONSTANTS.PRODUCT_OGI);
          self.modals.localizationEditModal.settings.headerList = [];

          angular.forEach(RESOURCES.LOCALIZATION_TYPES.LIST, function(typeDef) { //eslint-disable-line consistent-return
            if (((!appVariables.isSupplement && !appVariables.readOnly) || typeDef.TYPE === self.modals.localizationEditModal.type) && (!typeDef.restrictProductsVariants || angular.equals({}, selectedVariant) || typeDef.restrictProductsVariants.indexOf(Object.keys(selectedVariant)[0]) !== -1)) {
              self.modals.localizationEditModal.settings.headerList.push({
                name: typeDef.NAME,
                value: typeDef.TYPE
              });
              return true;
            }
          });

          if (!clientId) {
            self.currentlyEditedLocalizationData.localization = new EstateModel();
          } else {
            self.currentlyEditedLocalizationData.editedId = clientId;
            self.currentlyEditedLocalizationData.localization = mainDataContainer.localizations[clientId];
            self.setAllMessages();
          }
          self.currentlyEditedLocalizationData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(self.currentlyEditedLocalizationData.localization);

          self.modals.localizationEditModal.settings.cancelBtnName = 'sp2.modal.cancel';
          self.modals.localizationEditModal.settings.okBtnName = 'sp2.modal.okBtnName';
          if (self.currentlyEditedLocalizationData.allowedChanges.allDisabled) {
            self.modals.localizationEditModal.settings.cancelBtnName = '';
            self.modals.localizationEditModal.settings.okBtnName = 'sp2.modal.close';
          }
          sp2CommonHelper.showModal('localizationEditModal');
        };

        /**
         * wybrano typ lokalizacji
         */
        this.onLocalizationTypeChoosen = function(localizationType) {
          self.modals.localizationEditModal.type = localizationType;
          self.currentlyEditedLocalizationData.editedId = null;
          self.currentlyEditedLocalizationData.localization = new EstateModel();
          self.currentlyEditedLocalizationData.localization.address = new AddressModel();
          //jesli dodajemy 1 - glowna, lokalizacje to uzupelniamy danymi z ubezpieczonego
          if (!angular.isDefined(mainDataContainer.localizations[mainDataContainer.selectedLocalization])) {
            self.currentlyEditedLocalizationData.localization.address.setData(localizationHelper.loadDataFromMainInsuredOnce());
          }

          sp2CommonHelper.showModal('localizationEditModal');
        };

        /**
         * set all messages (errors and warnings) for edited estate
         */
        this.setAllMessages = function() {
          self.currentlyEditedLocalizationData.errors = errorHelper.getErrorFieldsForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, self.currentlyEditedLocalizationData.editedId);
          self.currentlyEditedLocalizationData.messages.warning = [];
          self.currentlyEditedLocalizationData.messages.error = [];
          // generate and deduplicated messages
          angular.forEach(self.currentlyEditedLocalizationData.errors, function(objs1, group) {
            if (group === 'localization') {
              angular.forEach(objs1, function(msg) {
                self._pushMessage(msg);
                msg.message = null;
              });
            } else {
              angular.forEach(objs1, function(objs2) {
                angular.forEach(objs2, function(msg) {
                  self._pushMessage(msg);
                  msg.message = null;
                });
              });
            }
          });
        };

        /**
         * add message of certain type to self.currentlyEditedLocalizationData.messages
         * @param {LsnAbstractModel} msg message
         */
        this._pushMessage = function(msg) {
          if (msg.type === MessageModelConstants.MESSAGE_TYPE_WARN && self.currentlyEditedLocalizationData.messages.warning.indexOf(msg.message) === -1) {
            self.currentlyEditedLocalizationData.messages.warning.push(msg.message);
          } else if (msg.type === MessageModelConstants.MESSAGE_TYPE_ERROR && self.currentlyEditedLocalizationData.messages.error.indexOf(msg.message) === -1) {
            self.currentlyEditedLocalizationData.messages.error.push(msg.message);
          }
        };

      };

      return new LocalizationModalHelper();
    }
  ]);