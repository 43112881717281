angular.module('salesPath2')
  .controller('specializationModalCtrl', ['$scope', '$filter', 'specializationModalHelper',
    function($scope, $filter, specializationModalHelper) {

      $scope.activeSorter = '';
      $scope.reverseSort = false;
      $scope.modalData = specializationModalHelper.modalData;

      /**
       * [init description]
       * @return {[type]} [description]
       */
      $scope.init = function() {
        $scope.fireListeners();
      };

      /**
      * Odpalenie watchy
      */
      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.specializationModal', function($event, modal) {
          if(modal.validForm) {
            specializationModalHelper.saveSpecializations();
          } else {
            $event.preventDefault();
          }

          specializationModalHelper.clearModalData();
        });

        // anulowanie danych
        $scope.$on('iHestiaCommonModalCancelBtnClicked.discountsModal', function() {
          specializationModalHelper.clearModalData();
        });
      };

      /**
       * Sortowanie
       */
      $scope.sortBy = function() {
        $scope.modalData.specializations = $filter('orderBy')($scope.modalData.specializations, 'DESC', $scope.reverseSort);
        $scope.activeSorter = 'typeCode';
        $scope.reverseSort = !$scope.reverseSort;
      };

      $scope.init();
    }
  ]);