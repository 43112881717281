angular.module('salesPath2')
  /**
   * model encji errora
   */
  .factory('ErrorCollectionModel', ['ErrorEntityModel', 'sp2CommonHelper',
    function(ErrorEntityModel, sp2CommonHelper)
  {
    /**
     * kolekcja errorow - dodawanie, kasowanie, przewijanie, pobieranie
     *
     * @constructor
     */
    var ErrorCollectionModel = function ()
    {
      var self = this;
      this.errors = [];
      this.index = 0;

      this.add = function(error)
      {
        if (!(error instanceof ErrorEntityModel))
        {
          sp2CommonHelper.throwException('W kolejci bledow moze znalezc sie tylko obiekt typu Error');
        }

        self.errors.push(error);
      };

      /**
       * kasowanie rekordu po indeksie
       *
       * @param index
       */
      this.remove = function(index)
      {
        self.errors.splice(index, 1);
      };

      /**
       * kasowanie elementu na podstawie wlasciwosci
       *
       * @param property
       * @param value
       */
      this.removeBy = function(property, value)
      {
        var error; // uzupelniany w petli ponizej
        for (var i = 0; i < self.errors.length; i++)
        {
          error = self.errors[i];
          if (error[property] === value)
          {
            self.remove(i);
          }
        }
      };

      /**
       *
       * @return {Number}
       */
      this.count = function ()
      {
        return self.errors.length;
      };

      /**
       *
       * @return {*}
       */
      this.current = function ()
      {
        return self.errors[self.index];
      };

      this.next = function ()
      {
        self.index += 1;
      };

      this.reset = function ()
      {
        self.index = 0;
      };

      /**
       *
       * @return {*}
       */
      this.all = function ()
      {
        return self.errors;
      };

      this.get = function (index)
      {
        return self.errors[index];
      };
    };

    return ErrorCollectionModel;
  }]);