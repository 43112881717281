angular.module('ihestiaWidgets.ikonfigurator')
  .controller('ihestiaIkonfiguratorCtrl', ['$scope', 'iHestiaCommonModalHelper', 'ikonfiguratorHelper', 'ikonfiguratorOfferModalHelper', 'IKONFIGURATOR_RESOURCES', 'ikonfiguratorReadOfferModalHelper', '$filter', '$stateParams', 'ikonfiguratorConstants', '$alert', '$anchorScroll',
    function($scope, iHestiaCommonModalHelper, ikonfiguratorHelper, ikonfiguratorOfferModalHelper, IKONFIGURATOR_RESOURCES, ikonfiguratorReadOfferModalHelper, $filter, $stateParams, ikonfiguratorConstants, $alert, $anchorScroll) {

      $scope.ikonfiguratorOfferModalSettings = {
        treatLabelAsI18nCode: true,
        size: ikonfiguratorHelper.accessMode === ikonfiguratorConstants.ACCESS_MODE_GUEST ? 'md' : 'sm',
        okBtnName: ['Public.confirm', {componentCode: 'Public'}],
        title: ''
      };

      $scope.ikonfiguratorReadOfferModalSettings = {
        treatLabelAsI18nCode: true,
        size: 'sm',
        okBtnName: ['Public.confirm', {componentCode: 'Public'}],
        title: ['main.enterLastConfiguration', {componentCode: 'iKonfigurator'}]
      };

      $scope.resources = IKONFIGURATOR_RESOURCES;

      /**
       * Info o pomyślnym stworzeniu zadania dla agenta
       * @type {String}
       */
      $scope.successMessage = '';

      /**
       * zakres ochrony (szczegółowy)
       * @type {Object}
       */
      $scope.dataSet = {};

      /**
       * Pomocnicze checkboxy (rozwijają szczegółowe)
       * te checkboxy nie biorą udziału w sprawdzaniu oferty
       * @type {Object}
       */
      $scope.tmpSelections = {};

      /**
       * Mapa pomocniczych modeli na modele z podstawowego setu danych (dataSet)
       * @type {Object}
       */
      var TMP_SELECTIONS_TO_DATASET = {
        acKom: {
          radio: 'acKomRadio',
          codes: ['acKomNamedRisks', 'acKomTotalLoss', 'acKomAllRisk']
        },
        nnwkip: {
          radio: 'nnwkipRadio',
          codes: ['nnwKip5', 'nnwKip15', 'nnwKip30', 'nnwKip60']
        },
        activity: {
          codes: ['activityVehicle', 'activityEstate']
        },
        additionsKom: {
          codes: ['additionGlasses', 'additionOuzKom']
        },
        assistanceKom: {
          codes: ['assistanceTowingAfterClaim', 'assistanceReplacementCarAfterClaim', 'assistanceTowingAfterBreak', 'assistanceReplacementCarAfterBreak']
        },
        assistanceProperty: {
          codes: ['sosAssistance', 'homeAssistance']
        },
        fire: {
          codes: ['houseFromFire', 'outbuildingsFromFire', 'equipmentFromFire', 'electricalEquipmentFromSurge', 'houseFromFrost', 'glassFromBreak', 'estateArchitectureFromFire', 'antiqueFromFire']
        },
        nnw: {
          codes: ['nnwDeath', 'nnwStroke', 'nnwHospitalCosts', 'nnwInabilityToWork', 'nnwRehabilitation']
        },
        ocz: {
          codes: ['ocz', 'ocActivity']
        },
        oczp: {
          codes: ['oczpPropertyOwner', 'oczpKids', 'oczpAnimals', 'oczpHigherSum200', 'oczpHigherSum500']
        },
        steal: {
          codes: ['houseFromSteal', 'equipmentFromSteal', 'houseFromVadalism', 'estateArchitectureFromNormalSteal', 'antiqueFromSteal']
        }
      };

      $scope.init = function() {
        $scope.fireListeners();

        // jeśli mamy podany na starcie id konfiguracji, to łądujemy ją
        if (ikonfiguratorHelper.configurationId) {
          $scope.loadOffer(ikonfiguratorHelper.configurationId);
        }

        /**
         * Niszczymy dane konfiguracji, jesli wychodzimy z tego miejsca
         */
        $scope.$on('$destroy', function() {
          ikonfiguratorHelper.clearData();
        });

        /**
         * Klient - udało się stworzyć zadania dla agenta
         */
        $scope.$on('ikonfiguratorTaskCreated', function() {
          $scope.successMessage = $filter('translate')('configurationSentToAgent', {componentCode: 'iKonfigurator'});
          $anchorScroll('ihestiaIkonfiguratorSuccessMsgAnchor');
        });

      };

      /**
       * [fireListeners description]
       * @return {undefined}
       */
      $scope.fireListeners = function() {
        $scope.$watch('tmpSelections.acKomRadio', $scope.acKomChanged, true);
        $scope.$watch('tmpSelections.nnwkipRadio', $scope.nnwkipChanged, true);
        $scope.$on('iHestiaCommonModalOkBtnClicked.ikonfiguratorReadOfferModal', function() {
          $scope.loadOffer(ikonfiguratorReadOfferModalHelper.readOfferData.dataSetNumber);
          ikonfiguratorReadOfferModalHelper.readOfferData.dataSetNumber = '';
        });

        // wartości zależne (do zaznaczenia czegoś musi być zaznaczone coś innego)
        $scope.$watch('dataSet', $scope.setDependencyDataSetValues, true);
      };

      /**
       * Ustawiamy zależne wartości w dataSet
       */
      $scope.setDependencyDataSetValues = function(newDataSet, oldDataSet) {
        var changingKeys = [];
        // ustalamy, które klucze się zmieniły
        var fullKeyTmpSet = angular.extend({}, oldDataSet, newDataSet);
        angular.forEach(fullKeyTmpSet, function(ignore, dataSetKey) {
          if (!oldDataSet || oldDataSet[dataSetKey] !== newDataSet[dataSetKey]) {
            changingKeys.push(dataSetKey);
          }
        });

        // sprawdzamy, czy można odznaczyć daną wartość
        angular.forEach(changingKeys, function(changedKey) {
          if (!newDataSet[changedKey]) {
            var dataSetValueCanChange = false;
            angular.forEach(ikonfiguratorHelper.requiredProductsMap, function(requiredProductMap, requiredProductMapKey) {
              if (newDataSet[requiredProductMapKey] && requiredProductMap.requiredAtLeastOneFrom.indexOf(changedKey) !== -1) {
                dataSetValueCanChange = false;
                angular.forEach(requiredProductMap.requiredAtLeastOneFrom, function(requiredDataSetKey) {
                  if (newDataSet[requiredDataSetKey]) {
                    dataSetValueCanChange = true;
                  }
                });
              } else {
                dataSetValueCanChange = true;
              }

              // nic poza wyłączanym kluczem nie jest włączone, więc nie pozwalamy na jego wyłączenie :)
              if (!dataSetValueCanChange) {
                newDataSet[changedKey] = true;
                $alert({
                  content: requiredProductMap.lockedUnselectMessage,
                  type: 'warning'
                });
              }
            });
          }
        });

        // sprawdzamy, czy nie trzeba czegoś zaznaczyć
        angular.forEach(ikonfiguratorHelper.requiredProductsMap, function(requiredProductsMap, dataSetKey) {
          if (newDataSet[dataSetKey]) {
            // sprawdzenie czy zostało zaznaczone przynajmniej jedno wymagane ryzyko
            var atLeastOneChecked = false;
            angular.forEach(requiredProductsMap.requiredAtLeastOneFrom, function(requiredDataSetKey) {
              if (newDataSet[requiredDataSetKey]) {
                atLeastOneChecked = true;
              }
            });

            // jeśli chcemy zaznaczyć dany dodatek i nie ma żadnego wymaganego ryzyka
            if (!atLeastOneChecked) {
              $scope.dataSet[requiredProductsMap.requiredAtLeastOneFrom[0]] = true;
              $alert({
                content: requiredProductsMap.forcedSelectMessage,
                type: 'warning'
              });
            }
          }
        });

        // jeśli coś się zmieni w dataset, to musimy sprawdzić pomocnicze wartości
        $scope.setTemplateValues();
      };

      /**
       * Zwijanie/rozwijanie sekcji (klikanie w duży checkbox)
       * @param  {string} sectionCode kod sekcji
       * @return {undefined}
       */
      $scope.toggleSection = function(sectionCode) {
        $scope.tmpSelections[sectionCode] = !$scope.tmpSelections[sectionCode];
      };

      /**
       * Ładowanie oferty z magicznego numeru
       * @param  {int|string} dataSetNumber numer oferty z zakodowanymi danymi
       * @return {undefined}
       */
      $scope.loadOffer = function(dataSetNumber) {
        // podstawowy dataSet
        $scope.dataSet = ikonfiguratorHelper.convertDataSetNumberToObject(dataSetNumber);
        // ustawienie modeli pomocniczych (ukrywanie sekcji itd.)
        $scope.clearTemplateValues();
        $scope.setTemplateValues();
      };

      /**
       * Czyścimy pomocnicze wartości
       * @return {undefined}
       */
      $scope.clearTemplateValues = function() {
        angular.forEach($scope.tmpSelections, function(tmpSelectionValue, tmpSelectionCode){
          $scope.tmpSelections[tmpSelectionCode] = false;
        });
      };

      /**
       * ustawienie modeli pomocniczych (ukrywanie sekcji itd.)
       */
      $scope.setTemplateValues = function() {
        angular.forEach($scope.dataSet, function(isSelected, dataSetCode) {
          if (isSelected) {
            angular.forEach(TMP_SELECTIONS_TO_DATASET, function(tmpSelectionConfig, tmpSelectionCode) {
              if (tmpSelectionConfig.codes.indexOf(dataSetCode) > -1) {
                $scope.tmpSelections[tmpSelectionCode] = true;
                if (tmpSelectionConfig.radio) {
                  $scope.tmpSelections[tmpSelectionConfig.radio] = dataSetCode;
                }
              }
            });
          }
        });
      };

      /**
       * Zmieniło się zaznaczenie elementu z zakresowych checkboxow
       * @param  {string} changedCode [description]
       * @return {undefined}
       */
      $scope.rangeElementChanged = function(changedCode) {
        ikonfiguratorHelper.correctRangeData($scope.dataSet, changedCode);
      };

      /**
       * Pokazujemy modal oferty
       * @return {undefined}
       */
      $scope.showOffer = function() {
        ikonfiguratorOfferModalHelper.dataSetNumber = ikonfiguratorHelper.convertDataSetToNumber($scope.dataSet);
        if (ikonfiguratorOfferModalHelper.dataSetNumber > 0) {
          ikonfiguratorOfferModalHelper.proposedProducts = ikonfiguratorHelper.getProductsForDataSet($scope.dataSet);
          var productNames = [];
          angular.forEach(ikonfiguratorOfferModalHelper.proposedProducts, function(proposedProduct) {
            productNames.push($filter('translate')('productName.' + proposedProduct.productCode, {
              componentCode: 'iKonfigurator'
            }));
          });

          $scope.ikonfiguratorOfferModalSettings.title = $filter('translate')('preparedOffer.title', {
            componentCode: 'iKonfigurator',
            params: [productNames.join(', ')]
          });

          if (ikonfiguratorOfferModalHelper.proposedProducts.length > 0) {
            $scope.successMessage = '';
            iHestiaCommonModalHelper.showModal('ikonfiguratorOfferModal');
          }
        } else {
          $alert({
            content: $filter('translate')('selectInsuranceRange', {
              componentCode: 'iKonfigurator'
            }),
            type: 'warning'
          });
        }
      };


      /**
       * Pokazujemy modal oferty
       * @return {undefined}
       */
      $scope.showReadOffer = function() {
        iHestiaCommonModalHelper.showModal('ikonfiguratorReadOfferModal');
      };

      /**
       * Zmieniły się radio ac komunikacyjnego
       * @return {undefined}
       */
      $scope.acKomChanged = function() {
        angular.forEach(['acKomNamedRisks', 'acKomTotalLoss', 'acKomAllRisk'], function(risk) {
          if ($scope.tmpSelections.acKomRadio === risk) {
            $scope.dataSet[risk] = true;
          } else {
            delete $scope.dataSet[risk];
          }
        });
      };

      /**
       * Zmieniły się radio nnwkip
       * @return {undefined}
       */
      $scope.nnwkipChanged = function() {
        angular.forEach(['nnwKip5', 'nnwKip15', 'nnwKip30', 'nnwKip60'], function(risk) {
          if ($scope.tmpSelections.nnwkipRadio === risk) {
            $scope.dataSet[risk] = true;
          } else {
            delete $scope.dataSet[risk];
          }
        });
      };

    }
  ]);