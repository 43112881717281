angular.module('lsnBase.models')
  .factory('LsnPolicyModelConstants', [function()
  {
    var PolicyModelConstants = {};

    PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH = 'CASH';
    PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH_AGENT = 'CASH_AGENT';
    PolicyModelConstants.POLICY_PAYMENT_METHOD_TRANSFER = 'TRANSFER';
    PolicyModelConstants.POLICY_PAYMENT_METHOD_CARD = 'CARD';
    PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH_CARD = 'CASH_CARD';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD = {};
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD[PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH] = 'gotówka';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD[PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH_AGENT] = 'gotówka u agenta';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD[PolicyModelConstants.POLICY_PAYMENT_METHOD_TRANSFER] = 'przelew';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD[PolicyModelConstants.POLICY_PAYMENT_METHOD_CARD] = 'karta';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD[PolicyModelConstants.POLICY_PAYMENT_METHOD_CASH_CARD] = 'płatność mieszana, karta płatnicza plus gotówka';

    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_FREQUENCY = {};
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_FREQUENCY[1] = 'jednorazowa'; // (1 - roczna, 2 - półroczna, 4 - kwartalna, 12 - miesięczna, 99 - jednorazowa)
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_FREQUENCY[2] = '2 raty';
    PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_FREQUENCY[4] = '4 raty';

    return PolicyModelConstants;
  }]);