/* eslint-disable */
angular.module('ihestiaCommonDirectives')
  .directive('ihestiaSetSize', [function() {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        if(typeof attr.leaveHeight !== 'undefined'){
          $(element).css('height', $(window).height() - attr.leaveHeight);

          $(window).resize(function(){
            $(element).css('height', $(window).height() - attr.leaveHeight);
          });
        }
        if(typeof attr.leaveWidth !== 'undefined'){
          $(element).css('width', $(window).width() - attr.leaveWidth);

          $(window).resize(function(){
            $(element).css('width', $(window).width() - attr.leaveWidth);
          });
        }
      }
    };
  }]);