/**
 * dowiazuje funkcje do przekazanego obiektu ktora przesusuwa scroll do dolu
 */
angular.module('lsnBase.directives')
  .directive('scrollToBottom', [function() {
    return {
      restrict: 'A',
      scope: {
        // atrybut przekazany powinnien byc obiektem
        scrollToBottom: '='
      },
      link: function($scope, $element) {

        $scope.init = function() {
          $scope.scrollToBottom.scrollToBottom = function() {
            $($element).scrollTop($($element)[0].scrollHeight - $($element).height());
          };

          $scope.scrollToBottom.scrollToElement = function(elementId) {
            var target = $('#' + elementId);
            if(target && target.offset())
            {
              $($element).scrollTop(target.offset().top - $($element).offset().top);
            }
          };

          if($scope.scrollToBottom.scrollOnInit){
            $scope.scrollToBottom.scrollToBottom();
          }
        };

        $scope.init();
      }
    };
  }]);