angular.module('salesPath2')
  /**
   * baza resourców dla sprzedażówek
   * @return {Object}
   */
  .factory('RESOURCES_BASE', ['lsnDateTimeHelper', 'CONSTANTS',
    function(lsnDateTimeHelper, CONSTANTS) {

      var RESOURCES_BASE = {
        'APP_FULLNAME': 'ihestia',
        'TODAY': lsnDateTimeHelper.getDate(),
        'CURRENT_TIME': lsnDateTimeHelper.getTime(),
        'LOCALIZATION_ATTRIBUTES': {},
        'NNW_PERSON_ATTRIBUTES': {}, //atrybuty osób (zogdne z dynamicValues typu bol dla osoby) ustawiane na ryzykach NNW
        'EXTRA_DESCRIPTION_LIST': [], //dodatkowe opisy produktów
        'DICT_AGREEMENT_AUTHORIZATION_EXCEEDED_GRANT_BASIS': {
          'BRAK': {
            'BOSVAL': 'Brak'
          },
          'NR_ZG': {
            'BOSVAL': 'Nr zgody',
            'DESC': {__: ['dictAgreementAuthorizationExceededGrantBasis.nrzgDesc', 'sp2resource']}// Numer zgody
          },
          'ZG_TEL': {
            'BOSVAL': 'Zgoda telefoniczna/MS/DPD',
            'DESC': {__: ['dictAgreementAuthorizationExceededGrantBasis.zgtelDesc', 'sp2resource']}// Zgoda telefoniczna/MS/DPD
          }
        },
        'DICT_AGREEMENT_DEVIATED_PREMIUM_GRANT_BASIS': {
          'BRAK': {
            'BOSVAL': 'Brak'
          },
          'NR_ZG': {
            'BOSVAL': 'Nr zgody',
            'DESC': {__: ['dictAgreementDeviatedPremiumGrantBasis.nrzgDesc', 'sp2resource']}// Numer zgody
          },
          'ZG_TEL': {
            'BOSVAL': 'Zgoda telefoniczna/MS/DPD',
            'DESC': {__: ['dictAgreementDeviatedPremiumGrantBasis.zgtelDesc', 'sp2resource']}// Zgoda telefoniczna/MS/DPD
          },
          'PROMO': {
            'BOSVAL': 'Promocja',
            'DESC': {__: ['dictAgreementDeviatedPremiumGrantBasis.promoDesc', 'sp2resource']}// Promocja
          }
        },
        'DISCOUNT_EMPLOYEE_ESTATE': [], //do konfiguracji per pakiet
        'FLOOD_INTENSITY': [{
          'value': '-2',
          'name': {__: ['floodIntensity.selectName', 'sp2resource']}// liczba szkód powodziowych w ciągu 5 lat 
        }, {
          'value': '-1',
          'name': {__: ['floodIntensity.doNotKnowName', 'sp2resource']}// nie znam liczby szkód powodziowych w ciągu 5 lat 
        }, {
          'value': '0',
          'name': {__: ['floodIntensity.zeroInFiveYearName', 'sp2resource']}// 0 szkód powodziowych w ciągu 5 lat 
        }, {
          'value': '1',
          'name': {__: ['floodIntensity.oneInFiveYearName', 'sp2resource']}// 1 szkoda powodziowa w ciągu 5 lat 
        }, {
          'value': '2',
          'name': {__: ['floodIntensity.twoInFiveYearName', 'sp2resource']}// 2 lub więcej szkód powodziowych w ciągu 5 lat 
        }],
        'PROTECTION_PERIOD_OPTIONS': [], //do uzupeknienia w konfigu aplikacji
        'DISABLED_REST_SERVICES': [],
        'POLICY_TYPES': {
          'policyTypeProperty': 'property',
          'policyTypePropertyCession': 'propertyCession'
        },
        'LIQUIDATION_OPTIONS': [{
          'CODE': 'PRICE',
          'NAME': {__: ['liquidationOptions.priceName', 'sp2resource']},// kosztorys 
          'DESC': {__: ['liquidationOptions.priceDesc', 'sp2resource']}// kosztorys 
        }, {
          'CODE': 'NET',
          'NAME': {__: ['liquidationOptions.netName', 'sp2resource']},// SP 
          'DESC': {__: ['liquidationOptions.netDesc', 'sp2resource']}// sieć partnerska ( lista warsztatów dostępna) 
        }, {
          'CODE': 'SERVICE',
          'NAME': {__: ['liquidationOptions.serviceName', 'sp2resource']},// ASO 
          'DESC': {__: ['liquidationOptions.serviceDesc', 'sp2resource']}// autoryzowany serwis obsługi 
        }],
        'FREQUENCY_CONTRIBUTIONS': [{
          'value': 1,
          'name': {__: ['paymentFrequency.one', 'sp2resource']}
        }, {
          'value': 2,
          'name': {__: ['paymentFrequency.installment', 'sp2resource', {'number': 2}]}
        }, {
          'value': 4,
          'name': {__: ['paymentFrequency.installment', 'sp2resource', {'number': 4}]}
        }],
        'PAYMENT_METHOD': [{
          'code': CONSTANTS.PAYMENT_METHOD_TRANSFER, // internal code | MuST BE UNIQUE !!
          'value': 'TRANSFER', // svc/BOS value
          'name': {__: ['paymentMethod.transfer', 'sp2resource']} // 'przelew' template name
        }, {
          'code': CONSTANTS.PAYMENT_METHOD_CASH,
          'value': 'CASH',
          'name': {__: ['paymentMethod.cash', 'sp2resource']} //gotówka
        }, {
          'code': CONSTANTS.PAYMENT_METHOD_CARD,
          'value': 'CARD',
          'name': {__: ['paymentMethod.card', 'sp2resource']} //karta
        }, {
          'code': CONSTANTS.PAYMENT_METHOD_BLIK,
          'value': 'TRANSFER',
          'name': 'BLIK'
        }],
        'PAYMENT_DELAY': [{
          'value': 7,
          'name': {__: ['paymentDelay.days', 'sp2resource', {'number': 7}]},
          'attr': CONSTANTS.PROROGATION_ATTR_DAYS
        }, {
          'value': 14,
          'name': {__: ['paymentDelay.days', 'sp2resource', {'number': 14}]},
          'attr': CONSTANTS.PROROGATION_ATTR_DAYS
        }],
        'NO_CAR_CATEGORIES': ['P25', '93', '91', 'P20', '95', '92', 'P7', 'P21'], //kategorie pojazdów wg ETX, które nie wskazują na samochód
        'VEHICLE_ORIGINAL_KEY': [{
          'value': 1,
          'name': '1'
        }, {
          'value': 2,
          'name': '2'
        }, {
          'value': 3,
          'name': {__: ['vehicleOriginalKeyOptions.3orMore', 'sp2resource']}
        }],
        ESTATE_ATTR_DICTS: { // default dictionaries for estate attributes/dynamicValues
          floorNumberType: [ // dv code/name
            {
              code: 'Parter', // must match the BOS definition
              name: 'Parter' // label
            },
            {
              code: 'Pierwsze piętro',
              name: 'Pierwsze piętro'
            },
            {
              code: 'Piętro pośrednie',
              name: 'Piętro pośrednie'
            },
            {
              code: 'Ostatnie piętro',
              name: 'Ostatnie piętro'
            }
          ],
          basement: [
            {
              code: true,
              name: 'Tak'
            },
            {
              code: false,
              name: 'Nie'
            }
          ],
          constructionType: [
            {
              code: 'Niepalna',
              name: 'Niepalna'
            },
            {
              code: 'Palna',
              name: 'Palna'
            }
          ],
          roofShape: [
            {
              code: 'Dach skośny',
              name: 'Dach skośny'
            },
            {
              code: 'Dach płaski',
              name: 'Dach płaski'
            }
          ],
          roofType: [
            {
              code: 'Dachówka/Łupek dachowy',
              name: 'Dachówka/Łupek dachowy'
            },
            {
              code: 'Blachodachówka/Blacha',
              name: 'Blachodachówka/Blacha'
            },
            {
              code: 'Strzecha',
              name: 'Strzecha'
            },
            {
              code: 'Gont/Papa',
              name: 'Gont/Papa'
            },
            {
              code: 'Pozostałe',
              name: 'Pozostałe'
            }
          ],
          houseType: [
            {
              code: 'Wolnostojący',
              name: 'Wolnostojący'
            },
            {
              code: 'Bliźniak',
              name: 'Bliźniak'
            },
            {
              code: 'W zabudowie szeregowej lub grupowej',
              name: 'W zabudowie szeregowej lub grupowej'
            }
          ],
          commercialUse: [
            {
              code: 'Nie',
              name: 'Nie'
            },
            {
              code: 'Tak, działalność usługowa o charakterze biurowym',
              name: 'Tak, działalność o charakterze biurowym'
            },
            {
              code: 'Tak, pozostałe typy działalności',
              name: 'Tak, pozostałe typy działalności'
            }
          ],
          leasedLongOrShortTerm: [
            {
              code: 'Nie',
              name: 'Nie'
            },
            {
              code: 'Tak, długoterminowego',
              name: 'Tak, długoterminowego'
            },
            {
              code: 'Tak, krótkoterminowego',
              name: 'Tak, krótkoterminowego'
            }
          ],
          inhabitanceType: [
            {
              code: 'Wynajmujący',
              name: 'Wynajmujący'
            },
            {
              code: 'Najemca',
              name: 'Najemca'
            }
          ],
          roofWindow: [
            {
              code: false,
              name: 'Nie'
            },
            {
              code: true,
              name: 'Tak'
            }
          ],
          localType: [
            {
              code: 'Jednokondygnacyjny',
              name: 'Jednokondygnacyjny'
            },
            {
              code: 'Wielokondygnacyjny',
              name: 'Wielokondygnacyjny'
            }
          ]
        },
        ESTATE_ATTR_CONDITIONS: { // if conditions met, attr is shown and declared, otherwise it's hidden and set to null
          floorNumber: { // attr code
            conditions: [
              {
                attrCode: 'floorNumberType',
                attrValue: ['Parter', 'Pierwsze piętro', 'Piętro pośrednie', 'Ostatnie piętro'],
                equalType: CONSTANTS.EQUAL_TYPE_ONE_OF
              }
            ],
            conditionJoinMethod: CONSTANTS.JOIN_METHOD_OR, // if not defined, JOIN_METHOD_OR is used
            estateTypes: undefined, // if defined, condition will be applied only to given estate types
            disableConditions: [
              {
                attrCode: 'floorNumberType',
                attrValue: 'Parter',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL,
                value: 0
              },
              {
                attrCode: 'floorNumberType',
                attrValue: 'Pierwsze piętro',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL,
                value: 1
              },
              {
                attrCode: 'floorNumberType',
                attrValue: 'Ostatnie piętro',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL,
                valueFromAttr: 'totalFloorNumber' // copy value from specified attribute
              }
            ]
          },
          inhabitanceType: {
            conditions: [
              {
                attrCode: 'leasedLongOrShortTerm',
                attrValue: 'Tak, długoterminowego',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL
              },
              {
                attrCode: 'leasedLongOrShortTerm',
                attrValue: 'Tak, krótkoterminowego',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL
              }
            ]
          },
          // IHESTIABAS-4829
          // roofWindow: {
          //   conditions: [
          //     {attrCode: 'floorNumberType', attrValue: 'Ostatnie piętro', equalType: CONSTANTS.EQUAL_TYPE_EQUAL}
          //   ],
          //   estateTypes: ['flat']
          // },
          floorNumberInLocal: {
            conditions: [
              {
                attrCode: 'localType',
                attrValue: ['Jednokondygnacyjny', 'Wielokondygnacyjny'],
                equalType: CONSTANTS.EQUAL_TYPE_ONE_OF
              }
            ],
            disableConditions: [
              {
                attrCode: 'localType',
                attrValue: 'Jednokondygnacyjny',
                equalType: CONSTANTS.EQUAL_TYPE_EQUAL,
                value: 1
              }
            ]
          }
        },
        VEHICLE_VALUE_TYPES: [
          {
            code: CONSTANTS.VALUE_TYPE_BRUTTO,
            label: ['sp2.vehicle.vehicleValueGross', undefined]
          },
          {
            code: CONSTANTS.VALUE_TYPE_NETTO,
            label: ['sp2.vehicle.vehicleValueNet', undefined]
          },
          {
            code: CONSTANTS.VALUE_TYPE_NETTO_PLUS_50,
            label: ['VAT 50%', undefined]
          }
        ]
      };

      return RESOURCES_BASE;
    }
  ]);
