angular.module('lsnBase.models')
  .factory('LsnPolicyPropositionSummaryModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAmountModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnAmountModelConstants)
    {

      var LsnPolicyPropositionSummaryModel = function()
      {
        this.objectName = 'PolicyPropositionSummary';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          startDate: {
            type: 'date'
          },
          endDate: {
            type: 'date'
          },
          mainInsuredRef: {
            type: 'string'
          },
          personsRefs: {
            type: 'array',
            elementsType: 'string'
          },
          estatesRefs: {
            type: 'array',
            elementsType: 'string'
          },
          vehiclesRefs: {
            type: 'array',
            elementsType: 'string'
          },
          riskGroups: {
            type: 'array',
            elementsType: 'RiskGroup',
            version: 'v2'
          },
          premium: {
            type: 'float',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          metaData: {
            type: 'MetaData'
          }
        };

        this.startDate = null; // date Data rozpoczęcia ochrony
        this.endDate = null; // date Data zakończenia ochrony
        this.mainInsuredRef = null; // string Identyfikator ubezpieczonego
        this.personsRefs = null; // array Identyfikatory powiązanych osób
        this.estatesRefs = null; // array Identyfikatory powiązanych nieruchomości
        this.vehiclesRefs = null; // array Identyfikatory powiązanych pojazdów
        this.riskGroups = null; // lista RiskGroup, Lista grup ryzyk
        this.premium = null; // Amount, Składka na polisie - suma 
        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});
        
      };

      LsnPolicyPropositionSummaryModel.prototype = LsnAbstractModel.prototype;

      return LsnPolicyPropositionSummaryModel;
    }
  ]);