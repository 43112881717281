angular.module('lsnBase.models')
  .factory('LsnInstallmentModelV1', ['LsnAbstractModel', 'LsnAmountModelConstants',
    function(LsnAbstractModel, LsnAmountModelConstants)
    {

      var LsnInstallmentModelV1 = function()
      {
        this.objectName = 'Installment';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          date:
          {
            type: 'date'
          },
          amount:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          }
        };

        this.date = null; // string, Termin płatności raty przez klienta
        this.amount = null; // Amount, Wysokość rat
      };

      LsnInstallmentModelV1.prototype = LsnAbstractModel.prototype;

      return LsnInstallmentModelV1;
    }
  ]);