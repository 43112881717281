angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaLifePersonHeaderConstants', [function() {
    var CONSTANTS = {};
    CONSTANTS.roleMainInsured = 'mainInsured';
    CONSTANTS.roleInsurer = 'insurer';
    CONSTANTS.roleInsurerAndMainInsured = 'insurerAndMainInsured';
    return CONSTANTS;
  }])
  .controller('ihestiaLifePersonHeaderCtrl', ['$scope', 'IhestiaLifePersonHeaderConstants', 'policyDetailsContextHelper', 'ihestiaLifePersonHeaderHelper',
    function($scope, IhestiaLifePersonHeaderConstants, policyDetailsContextHelper, ihestiaLifePersonHeaderHelper) {
      /**
       * Role name for subject
       * @type {string | null}
       */
      $scope.displayRoleName = null;
      // todo pass it or read it here ?
      $scope.isForeigner = false;
      $scope.IhestiaLifePersonHeaderConstants = IhestiaLifePersonHeaderConstants;
      $scope.isClient = policyDetailsContextHelper.isClient();
      $scope.contactData = null;
      $scope.address = null;
      $scope.isPerson = null;

      $scope.init = function() {
        $scope.isPerson = $scope.subject.metaData.className === 'Person';
        $scope.displayRoleName = ihestiaLifePersonHeaderHelper.getRoleNameLabelLife($scope.role);
        $scope.address = ihestiaLifePersonHeaderHelper.getAddress($scope.subject);
        $scope.contactData = ihestiaLifePersonHeaderHelper.getContactData($scope.subject);
      };
      /**
       * Pass on edit callback as such:
       * on-edit-subject="editSubject(subject, role)"
       */
      $scope.handleEditSubject = function() {
        if ($scope.onEditSubject) {
          $scope.onEditSubject({
            subject: $scope.subject,
            role: $scope.role
          });
        }
      };

      $scope.init();
    }]);
