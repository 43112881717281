angular.module('ihestiaRestServicesBase')
  .service('currentTimeSvc', ['$http',
    function($http) {
      var CurrentTimeSvc = function () {

        /**
         *
         * @param {LsnCommonErrorModel} exc
         * @param {function} callback
         * @param {function} opcja, errorCallback
         */
        this.get = function () {
          return $http({
            method: 'GET',
            url: '/guiapi/v1/system/currentTime'
          });
        };
      };

      return new CurrentTimeSvc();
    }]);