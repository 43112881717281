angular.module('salesPath2')
  .controller('personsCtrl', ['$scope', 'personModalHelper', 'personsHelper', 'CONFIG', 'CONSTANTS', 'groupModalHelper', 'leasingModalHelper', 'sp2CommonHelper', 'mainDataContainer', 'actionHelper', '$filter', 'organizationHelper',
    function($scope, personModalHelper, personsHelper, CONFIG, CONSTANTS, groupModalHelper, leasingModalHelper, sp2CommonHelper, mainDataContainer, actionHelper, $filter, organizationHelper) {

      $scope.tplData = personsHelper.tplData;

      $scope.init = function() {
        personsHelper.refreshTplData();
      };

      /**
       * edytuj osobę
       * @param  {String} personId clientId osoby
       * @param  {String} personRole rola edytowanej osoby (CONSTANTS.PERSON_ROLE_...) - coowner, insured, insurer
       */
      $scope.editPerson = function(personId, personRole) {
        var data = {
          editHeader: $filter('translate')('insured', {componentCode: 'Public'}),
          id: personId,
          context: CONSTANTS.PERSON_CONTEXT_CLIENT
        };

        if (personRole === CONSTANTS.PERSON_ROLE_INSURER) {
          data.editHeader = $filter('translate')('sp2.matrix.persons.insurer', {componentCode: 'sp2'}); //ubezpieczający zawsze jest pierwszą osobą, ale nie zawsze jest głównym ubezpieczonym!!!
        } else if (personRole === CONSTANTS.PERSON_ROLE_COOWNER) {
          data.editHeader = $filter('translate')('coowner', {componentCode: 'Public'});
        }
        data.id = personId;
        data.personChangeRole = personRole;
        personModalHelper.openPopupEditPerson(data);
      };

      /**
       * Usunięcie osoby z wniosku
       * uwaga - na razie usuwa tylko z dataContainer!
       * @param  {Object} person z mainDataContainer.persons
       * @return {undefined}
       */
      $scope.removePerson = function(person) {
        var name = (person.firstName + ' ' + person.lastName).trim();
        if (name === '') {
          name = $filter('translate')('noData', {componentCode: 'Public'});
        }
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['modal.personDelete.title', {componentCode: 'sp2'}],
          okBtnName: ['modal.personDelete.okBtnName', {componentCode: 'sp2'}],
          text: ['modal.personDelete.text', {componentCode: 'sp2', params: {'name': name}}], //Czy na pewno usunąć osobę: {name} z wniosku?
          size: 'sm',
          okBtnCallback: function() {
            var clId = person.metaData.clientId;
            delete mainDataContainer.persons[clId];
            if (angular.isObject($scope.dataContainer.bonusMalus) && angular.isDefined($scope.dataContainer.bonusMalus[clId])) {
              delete $scope.dataContainer.bonusMalus[clId];
            }
            actionHelper.runAction('personEntirelyDeleted');
          }
        });
      };

      /**
       * modal z zapytaniem i usunięcie organizacji
       * @param  {Object} orgData dane organizacji uzupelnione w personsHelper.getUninsuredOrganizations()
       */
      $scope.removeOrganization = function(orgData) {
        var name = (orgData.name + ' ' + orgData.nip).trim();
        if (name === '') {
          name = $filter('translate')('noData', {componentCode: 'Public'});
        }
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['modal.orgDelete.title', {componentCode: 'sp2'}],
          okBtnName: ['modal.orgDelete.okBtnName', {componentCode: 'sp2'}],
          text: ['modal.orgDelete.text', {componentCode: 'sp2', params: {'name': name}}], //Czy na pewno usunąć osobę: {name} z wniosku?
          size: 'sm',
          okBtnCallback: function() {
            organizationHelper.deleteOrganization(orgData.id);
            actionHelper.runAction('cessionDeleted');
            actionHelper.runAction('saveApplication');
          }
        });
      };

      /**
       * edytuj grupę
       * @param  {String} clientId clientId grupy
       */
      $scope.editGroup = function(clientId) {
        groupModalHelper.openPopupEditGroup({
          id: clientId,
          context: CONSTANTS.PERSON_CONTEXT_CLIENT
        });
      };

      /**
       * otwiera edycje leasingodawcy
       * @param  {string} leaserId
       * @param  {string} objType typ obiektu, fo ktorego przypisano leasing
       */
      $scope.editLeaser = function(leaserId, objType) {
        leasingModalHelper.editExplicitLeaser(leaserId, objType);
      };

      $scope.init();
    }
  ]);