angular.module('lsnBase.models')
  .factory('LsnRiskVariantsAttributesOfRiskModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAmountModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnAmountModelConstants)
    {
      var RiskVariantsAttributesOfRisk = function()
      {
        this.objectName = 'RiskVariantsAttributesOfRisk';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          _extendPremium:
          {
            type: 'int'
          },
          isHistorical:
          {
            type: 'bool',
            defaultValue: true
          },
          parentRef:
          {
            type: 'string'
          },
          metaData: {
            type: 'MetaData'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this._extendPremium = null; // skladka za dokupienie
        this.isHistorical = true; // czy ryzyko z pierwotnej polisy
        this.parentRef = null; // refernecja do ryzyka pierwotnego
      };

      RiskVariantsAttributesOfRisk.prototype = LsnAbstractModel.prototype;

      return RiskVariantsAttributesOfRisk;
    }
  ]);