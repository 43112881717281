/**
 * Mapper nieruchomości
 */
angular.module('salesPath2').service('fromRestEstateMapper', ['MapperUtils', 'CONSTANTS', 'CONFIG', 'sp2CommonHelper', 'SPD', 'fromRestCompatibilityMapper', 'localizationHelper',
  function(MapperUtils, CONSTANTS, CONFIG, sp2CommonHelper, SPD, fromRestCompatibilityMapper, localizationHelper) {
    var FromRestEstateMapper = function() {
      var self = this;
      this.utils = MapperUtils;
      this.defaultOptions = { //opcje mapowania
        initialLoad: false //pierwsze wczytanie wniosku/polisy
      };

      this.options = angular.copy(this.defaultOptions);
      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };
      //mapuje nieruchomosci
      this.mapEstates = function(application, tmpDc, mapOptions) //eslint-disable-line consistent-return
      {
        self._setOptions(mapOptions);
        if (application.estates === null) {
          return {};
        }
        var estatesObj = {},
          nextId = tmpDc.nextObjectId;
        angular.forEach(application.estates, function(estate, idx) {
          nextId = Math.max(nextId, parseInt(estate.metaData.get('clientId'), 10) + 1);
          self._mapEstate(estate);
          estatesObj[estate.metaData.get('clientId')] = estate;
          tmpDc._estateIdMap[estate.metaData.get('id')] = estate.metaData.get('clientId');
          if (idx === 0) { //pierwsza iteracja
            tmpDc.selectedLocalization = estate.metaData.get('clientId');
          }
        });
        tmpDc.localizations = estatesObj;
        tmpDc.nextObjectId = nextId;
      };

      /**
       * mapuje lokalizacje
       * @param  {EstateModel} estate lokalizacja
       */
      this._mapEstate = function(estate) {
        var dynVals = estate.get('dynamicValues');
        if (typeof dynVals === 'object') {
          if (angular.isDefined(dynVals._additionalData)) {
            var addData = self.utils.unserializeValue(dynVals._additionalData);
            if (self.options.initialLoad) {
              fromRestCompatibilityMapper.mapAdditionalData(addData);
            }
            estate.setAdditionalData(addData);
            delete dynVals._additionalData;
          } else if (self.options.initialLoad) {
            localizationHelper.setDefaultLocalizationAdditionalData(estate);
          }
        }
        estate.dynamicValues = dynVals;
        self.utils.getTemporaryDynamicValuesForObject(estate);
        self.utils.removeUnusedDynamicValuesForObject(estate);
        if (!CONFIG.BEHAVIOR.multipleLocalizations) { //nazwa jest uzupwŐniana na podstawie estate.type w toRest.estateMapper
          estate.set('name', null);
        }
      };

      /**
       * mapuje additionalData z ryzyka
       * @param {Estate} estate nieruchomosc
       * @param {String} risk ryzyko
       * @param {object} riskDef
       */
      this.setAdditionalDataFromRisk = function(estate, risk, riskDef) {
        if (self.utils.propertyProducts.indexOf(riskDef.productCode) === -1) { //tylko ryzyka majatkowe
          return false;
        }
        //daty ochrony
        estate.setAdditionalData(
          {
            start: self.utils.getFormattedJSProtectionDate(risk.get('start')),
            end: self.utils.getFormattedJSProtectionDate(risk.get('end'))
          }, ['protectionDates', riskDef.productCode]);
        //wspolwlasciciele
        if (estate.getAdditionalData(['coowners']).length === 0) { //wspolwalsciciele na kazdym ryzyku powinni byc identyczni
          var coowners = [];
          angular.forEach(risk.insuredSubjectsRef, function(subRef) {
            var subjId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON, subRef.get('ref'));
            if (subjId === false) {
              sp2CommonHelper.throwException('No clientId found for object type {0} with id {1}.'.format(CONSTANTS.PRODUCT_TYPE_PERSON, subRef.get('ref')));
            }
            if (subRef.get('typeId') === SPD.subjects[CONSTANTS.SUBJECT_COINSURED] && coowners.indexOf(subjId) === -1) {
              coowners.push(subjId);
            }
          });
          estate.setAdditionalData(coowners, ['coowners']);
        }
        //powódź
        var dynVals;
        if (riskDef.riskCode === CONSTANTS.RISK_FLOOD) {
          dynVals = risk.product.get('dynamicValues');
          if (typeof dynVals.nrOfFloodings !== 'undefined') {
            estate.setAdditionalData(parseInt(dynVals.nrOfFloodings, 10), 'flood');
          }
        }
        //budynki gospodarcze
        if (riskDef.riskCode === CONSTANTS.RISK_OUTBUILDINGS) {
          var outbuildings = estate.getAdditionalData([riskDef.productCode, 'outBuildings']);
          dynVals = risk.product.get('dynamicValues');
          if (!angular.isArray(outbuildings)) {
            outbuildings = [];
          }
          outbuildings.push(
            {
              su: risk.get('sum'),
              desc: (typeof dynVals.insuredObjectDesc !== 'undefined') ? dynVals.insuredObjectDesc : ''
            });
          estate.setAdditionalData(outbuildings, [riskDef.productCode, 'outBuildings']);
        }
        return true;
      };
      /**
       * mapuje tymczasowe (te z definicją [...,....,true]) dynamicValues z ryzyka
       * @param {Estate} estate nieruchomosc
       * @param {String} risk ryzyko
       * @param {object} riskDef
       */
      this.setTempDynamicValuesFromRisk = function(estate, risk, riskDef) {
        if (self.utils.propertyProducts.indexOf(riskDef.productCode) === -1) { //tylko ryzyka majatkowe
          return;
        }
        var estateDynVals = estate.get('dynamicValues'),
          riskDynVals = risk.product.get('dynamicValues'),
          estateTmpDynValsDef = self.utils.getTemporaryDynamicValuesDefForObject(estate.objectName);

        angular.forEach(estateTmpDynValsDef, function(def, symbol) {
          if (typeof riskDynVals[symbol] !== 'undefined' && riskDynVals[symbol] !== null) {
            estateDynVals[symbol] = riskDynVals[symbol];
          }
        });
        estate.set('dynamicValues', estateDynVals);
      };

      //#pragma mark policiesMapping
      /**
       * mapuje nieruchomosci z polis do wniosku ubezpieczeniowego
       * @param {Policy[]} policies polisy
       * @param {Application} application wniosek
       * @param {number=} nextEstateClientId kolejny client id dla nieruchomosci
       * @return {object.<string,string>} obiekt mapowan id: clientId
       */
      this.mapPolicyEstatesToApplication = function(policies, application, nextEstateClientId) {
        var idToClientId = {},
          idToIndex = {}; //ids zmapowane (metaData.id => indeks w tablicy application.estates)
        nextEstateClientId = (typeof nextEstateClientId === 'number') ? nextEstateClientId : 1;
        lsnNg.forEach(policies, function(policy) {
          if (policy.estates === null) {
            return;
          }
          if (!angular.isArray(application.estates)) {
            application.set('estates', []);
          }
          angular.forEach(policy.estates, function(estate) {
            if (typeof idToClientId[estate.metaData.get('id')] === 'undefined') {
              estate.metaData.set('clientId', nextEstateClientId);
              application.estates.push(estate);
              idToIndex[estate.metaData.get('id')] = application.estates.length - 1;
              idToClientId[estate.metaData.get('id')] = nextEstateClientId;
              nextEstateClientId += 1;
            } else {
              self._mergeEstates(application.estates[idToIndex[estate.metaData.get('id')]], estate);
            }

          });
        });
        return idToClientId;
      };

      /**
       * Łączy dwie nieruchomości (pod kątem wlasnoci, dynamicValues, itd.)
       * @param {Estate} targetEstate docelowy estate
       * @param {Estate} sourceEstate zrodlowy estate
       * @return {Estate} połączony estate
       */
      this._mergeEstates = function(targetEstate, sourceEstate) {
        var dynVals = self.utils.mergeDynamicValues(targetEstate.get('dynamicValues'), sourceEstate.get('dynamicValues'));
        targetEstate.set('dynamicValues', dynVals);
      };

      /**
       * uzupelnia ostateczne dane lokalizacji
       */
      this.mapFinalData = function(tmpDc) {
        if (!angular.isObject(tmpDc.localizations)) {
          return;
        }
        angular.forEach(tmpDc.localizations, function(estate) { //uzupelniamy daty ochrony dla drugiego produktu, o ile ich nie ma
          // https://ebok.atena.pl/browse/EHNLEARN-2710
          if ((estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI]) === null || (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI, 'end']) === null && estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI, 'start']) === null))
            && (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA]) !== null && (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA, 'end']) !== null || estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA, 'start']) !== null))) {
            estate.setAdditionalData(estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA]), ['protectionDates', CONSTANTS.PRODUCT_OGI]);
          } else if ((estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA]) === null || (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA, 'end']) === null && estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_KRA, 'start']) === null))
            && (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI]) !== null && (estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI, 'end']) !== null || estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI, 'start']) !== null))) {
            estate.setAdditionalData(estate.getAdditionalData(['protectionDates', CONSTANTS.PRODUCT_OGI]), ['protectionDates', CONSTANTS.PRODUCT_KRA]);
          }
        });
      };
    };

    return new FromRestEstateMapper();
  }
]);