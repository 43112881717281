angular.module('ihestiaRestServicesBase')
  .factory('idpSessionSvc', ['IHestiaRestServiceLsnDef', 'ihestiaConfigHelper', '$http', '$timeout', '$q',
    function(IHestiaRestServiceLsnDef, ihestiaConfigHelper, $http, $timeout, $q) {
      var IdpSessionSvc = function() {

        IHestiaRestServiceLsnDef.apply(this, arguments);

        var self = this;
        this.host = ihestiaConfigHelper.get('ihsgDefines', 'SSO_REFRESH_URL');
        this.basePattern = '{host}';

        this.numberOfTries = 10;
        this.tryAfterMillisecunds = 1000;

        this.queryResults = null;
        this.counter = 0;

        /**
         * Aktualizuje sesje
         * @param  {[type]}   data     [description]
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.touch = function(host) { //eslint-disable-line
          var url = host ? host : self.host;
          return $http({
            url: url + '?_=' + (new XDate()).getTime(),
            data: '',
            type: 'GET',
            async: true,
            timeout: 30000,
            dataType: 'json',
            cache: false,
            withCredentials: true,
            _touchSession: true
          });
        };

        this.tryTouch = function(host){
          self.queryResults = $q.defer();
          self.counter = 0;

          self.tryTouchProcess(host);

          return self.queryResults.promise;
        };

        this.tryTouchProcess = function(host){
          return self.touch(host).then(function(body) {
            self.queryResults.resolve(body);
          }, function() {
            if (self.counter < self.numberOfTries) {
              $timeout(function(){
                self.counter++;
                self.tryTouchProcess();
              }, self.tryAfterMillisecunds);
            }
          });          
        };
      };

      return new IdpSessionSvc();
    }
  ]);