angular.module('ihestiaGridSettings')
  .controller('ihestiaDatePeriodSelectCtrl', ['$scope', 'ihestiaDatePeriodHelper',
    function ($scope, ihestiaDatePeriodHelper) {

      $scope.periodOptions = ihestiaDatePeriodHelper.getPeriodOptions($scope.filterOptions, true);

      // $scope.attributeCode set in parent scope
      // $scope.attributeLabel set in parent scope

      $scope.dateFromAttribute = $scope.attributeCode + 'From';
      $scope.dateToAttribute = $scope.attributeCode + 'To';

      $scope.init = function()
      {
        $scope.currentFilter.settings.filterSettings[$scope.dateFromAttribute] = null;
        $scope.currentFilter.settings.filterSettings[$scope.dateToAttribute] = null;

        if($scope.currentFilter.settings.filterSettings[$scope.attributeCode]) {
          // prawdopodobnie domyślna wartość z usługi
          var periodOption = ihestiaDatePeriodHelper.getPeriodByCode($scope.currentFilter.settings.filterSettings[$scope.attributeCode]);
          if(periodOption) {
            $scope.currentFilter.settings.filterSettings[$scope.attributeCode] = periodOption.code; // mapujemy na code który zgadza się wielkością liter
            $scope.periodChanged(true);
          } else {
            $scope.currentFilter.settings.filterSettings[$scope.attributeCode] = null;
          }
        } else {
          $scope.currentFilter.settings.filterSettings[$scope.attributeCode] = null;
        }
      };

      $scope.periodChanged = function(notSelectChange)
      {
        var periodOption;
        var periodCode = $scope.currentFilter.settings.filterSettings[$scope.attributeCode];
        if(periodCode) {
          periodOption = ihestiaDatePeriodHelper.getPeriodByCode(periodCode);
        }
        if(periodCode === null || (periodOption && periodOption.code === 'Manual')) {
          if(!notSelectChange) { // jak to zmiana przez załadowanie całego zestawu filtrów to nie chcemy zerować wartości Manual
            $scope.currentFilter.settings.filterSettings[$scope.dateFromAttribute] = null;
            $scope.currentFilter.settings.filterSettings[$scope.dateToAttribute] = null;
          }
        } else if(periodOption && periodOption.code !== 'Manual') {
          $scope.currentFilter.settings.filterSettings[$scope.dateFromAttribute] = new XDate(periodOption.dateFrom).toString('yyyy-MM-dd');
          $scope.currentFilter.settings.filterSettings[$scope.dateToAttribute] = new XDate(periodOption.dateTo).toString('yyyy-MM-dd');
        }

        // clear corresponding calendar values
        // $scope.currentFilter.settings.filterSettings[$scope.dateFromAttribute] = null;
        // $scope.currentFilter.settings.filterSettings[$scope.dateToAttribute] = null;
      };

      $scope.$watch('currentFilter.settings.filterSettings.'+$scope.attributeCode, function(){
        $scope.periodChanged(true);
      });

      $scope.init();
    }
  ]);
