angular.module('ihestiaRestServicesBase')
  .factory('customerPoliciesPropertySvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var CustomerPoliciesPropertySvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'customers';
        this.businessArea = 'insurance';
        this.searchesPostfix = 'policies/property/searches';

        this.sortBy = 'startDate';
      };

      return new CustomerPoliciesPropertySvc();
    }]);