angular.module('ihestiaWidgets.policyOperations')
  .controller('applicationDataCtrl', ['$scope', 'clientDataHelper', 'policyOperationsSvc', '$filter', 'ihestiaAgHelper', 'UserCharacterTypeConstants', 'labelActionHelper', 'ihestiaAutoUploadOneListUploadHelper', 'ihestiaPlatformHelper',
    function($scope, clientDataHelper, policyOperationsSvc, $filter, ihestiaAgHelper, UserCharacterTypeConstants, labelActionHelper, ihestiaAutoUploadOneListUploadHelper, ihestiaPlatformHelper) {

      $scope.addressInvalidTexts = {};

      $scope.withdrawalDateMessage = '';

      var titleStringLabel = 'addAnotherSale';
      if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
      {
        titleStringLabel = 'addAnotherDonation';
      }

      $scope.maskOptions = {
        clearOnBlur: false,
        allowInvalidValue: true
      };

      $scope.vehiclePurchaseRecordEditModal = {
        settings: {
          treatLabelAsI18nCode: true,
          okBtnName: ['Public.confirm', {componentCode: 'Public'}],
          title: [titleStringLabel, {componentCode: 'PolicyOperations'}],
          cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
          size: 'lg'
        },
        newRecord: false, //czy to nowy rekord czy edycja wcześniej zapisanego
        modalActive: false
      };

      $scope.boxData = [];

      $scope.formSubmitted = false;

      $scope.visible = {
        verificationData: true,
        clientData: true,
        additionalInformation: true,
        tarrificationData: true,
        organizationTarrificationData: false
      };

      $scope.showPolicyPerson = true; //czy pokazujemy wybór osoby z polisy (poprzedni właściciel)

      $scope.shared = {
        selectedVehicle: null, //wybrany pojazd
        vehicleDirty: false, //czy pojazd się zmienił
        clientData: {},
        sellerData: {},
        sellersString: '', //lista sprzedających
        coowners: [],
        ownersSelectList: [],
        selectedOwners: null, //id sprzedającego
        otherSellerData: null, //obiekt innego sprzedającego
        forms: [],
        nextSale: false,
        dataIsTruth: false,
        minOperationDate: '',
        showCheckboxTooltip: false //czy jest pokazany tooltip
      };

      $scope.verificationDataForm = {}; //z jakiegoś powodu $scope.verificationDataForm nie był łapany przez forma

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.generateLabels();
        labelActionHelper.registerAction('applicationDataCtrl', $scope.generateLabels);
        $scope.formSubmitted = false;
        $scope.shared.dataIsTruth = false;
        $scope.setInitVisiblity();
        if($scope.getPersonsByRole($scope.roles.BUYER_OWNER).length > 0)
        {
          //jest już kupujący na wniosku, więc ładujemy dane z wniosku
          $scope.loadClientsFromRecord();
        }
        else
        {
          $scope.initClientData(); //dane kupującego
          $scope.shared.coowners = [];
        }
        $scope.loadSellersData();
        $scope.refreshOperationDatePeriod();
        if($scope.centralSpecialMode)
        {
          $scope.refreshBoxData();
        }
      };

      $scope.generateLabels = function()
      {
        $scope.addressInvalidTexts.postalCode = $filter('translate')('validationMessageFillZipCode', {componentCode: 'Public'});
        $scope.addressInvalidTexts.city = $filter('translate')('validationMessageFillCity', {componentCode: 'Public'});
        $scope.addressInvalidTexts.streetPrefix = $filter('translate')('validationMessageFillStreetPrefix', {componentCode: 'Public'});
        $scope.addressInvalidTexts.street = $filter('translate')('validationMessageFillStreet', {componentCode: 'Public'});
        $scope.addressInvalidTexts.house = $filter('translate')('validationMessageFillHouseNumber', {componentCode: 'Public'});

        if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
        {
          $scope.withdrawalDateMessage = $filter('translate')('withdrawalDateCantBeEarlierThanDonationDate', {componentCode: 'PolicyOperations'});
        }
        else if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_SALE)
        {
          $scope.withdrawalDateMessage = $filter('translate')('withdrawalDateCantBeEarlierThanSaleDate', {componentCode: 'PolicyOperations'});
        }
        else
        {
          $scope.withdrawalDateMessage = $filter('translate')('withdrawalDateCantBeEarlierThanPurchaseDate', {componentCode: 'PolicyOperations'});
        }

        if($scope.shared.ownersSelectList && $scope.shared.ownersSelectList.length)
        {
          var sellerLabel = 'otherSeller';
          if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
          {
            sellerLabel = 'otherDonator';
          }
          angular.forEach($scope.shared.ownersSelectList, function(ownersSelectListElement){
            if(ownersSelectListElement.id === -1)
            {
              ownersSelectListElement.name = $filter('translate')(sellerLabel, {componentCode: 'PolicyOperations'});
            }
          });
        }
      };

      $scope.loadSellersData = function()
      {
        $scope.shared.sellersString = '';
        if($scope.getPersonsByRole($scope.roles.SELLER_OWNER).length > 0)
        {
          $scope.shared.sellerData = clientDataHelper.prepareApplicationPerson($scope.getPersonsByRole($scope.roles.SELLER_OWNER)[0]);
          $scope.shared.sellersString = $scope.shared.sellersString + clientDataHelper.getPersonName($scope.shared.sellerData);
          angular.forEach($scope.getPersonsByRole($scope.roles.SELLER_COOWNER), function(coowner){
            $scope.shared.sellersString = $scope.shared.sellersString + ', ' + clientDataHelper.getPersonName(coowner);
          });
        }
        else
        {
          $scope.shared.sellerData = {};
        }
      };

      /**
       * ładujemy dane klienta i współwłaścicieli z currentRecord
       */
      $scope.loadClientsFromRecord = function()
      {
        var owners = $scope.getPersonsByRole($scope.roles.BUYER_OWNER);
        if(owners.length === 0)//to nowy rekord jeszcze bez kupującego
        {
          $scope.initClientData();
        }
        else
        {
          angular.forEach($scope.shared.clientData, function(value, key){ //nie chcemy tracić dowiązania
            delete $scope.shared.clientData[key];
          });
          angular.forEach(clientDataHelper.prepareApplicationPerson(owners[0]), function(value, key){
            $scope.shared.clientData[key] = value;
          });

          $scope.shared.coowners = clientDataHelper.prepareApplicationPersons($scope.getPersonsByRole($scope.roles.BUYER_COOWNER));
        }
      };

      $scope.initClientData = function()
      {
        var clientData = clientDataHelper.prepareNewPerson();
        clientData.metaData.id = $scope.getNextPersonId();
        angular.forEach($scope.shared.clientData, function(value, key){ //nie chcemy tracić dowiązania
          delete $scope.shared.clientData[key];
        });
        angular.forEach(clientData, function(value, key){ //nie chcemy tracić dowiązania
          $scope.shared.clientData[key] = value;
        });
      };

      $scope.setInitVisiblity = function()
      {
        $scope.visible.verificationData = true;
        $scope.visible.clientData = true;
        $scope.visible.additionalInformation = true;
        $scope.visible.tarrificationData = false;
        $scope.visible.organizationTarrificationData = false;

        if($scope.otherData.saleLike)
        {
          //sprzedaż lub darowizna
          if($scope.currentUser.character.type !== 'Central' && $scope.hasUserContext)
          {
            //centrala może zmieniać osobę z polisy
            //tak samo w operacjach poza ikontem
            $scope.showPolicyPerson = false;
          }
        }
        else
        {
          $scope.visible.tarrificationData = true;
        }

        if($scope.outerOffice)
        {
          //dla widykowanej polisy wymagamy plików
          $scope.visible.tarrificationData = true;
        }

        //wejście z linku na 2 krok
        if($scope.otherData.goToStepAfterLoad && $scope.otherData.goToStepAfterLoad === 'tarrification')
        {
          $scope.visible.verificationData = false;
          $scope.visible.clientData = false;
          $scope.visible.additionalInformation = false;
          if($scope.shouldWeShowOrganizationTarrification())
          {
            $scope.visible.tarrificationData = false;
            $scope.visible.organizationTarrificationData = true;
          }
          else
          {
            $scope.visible.tarrificationData = true;
          }

          $scope.otherData.goToStepAfterLoad = null;
        }
      };

      /**
       * klikniecię przycisku "dalej"
       * @return {[type]}        [description]
       */
      $scope.forward = function(getTask) { //eslint-disable-line consistent-return
        if($scope.otherData.verifyInProgress)
        {
          //jesteśmy w trakcie ładowania danych z polisy bez blockUi (centralSpecialMode)
          return false;
        }

        $scope.formSubmitted = true;

        $scope.refreshCheckboxTooltip();

        // jeśli brak błędów, to odpytujemy usługę
        if ($scope.verificationDataForm.$valid) {
          $scope.otherData.saveErrors = [];
          if(getTask)
          {
            $scope.verificationData.currentUserAsBuyerPatron = true;
          }
          else
          {
            $scope.verificationData.currentUserAsBuyerPatron = false;
          }

          //darowizna częściowa
          if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION && $scope.otherData.donationType === 'Partial')
          {
            $scope.saveNewCoowner($scope.shared.clientData, $scope.shared.selectedVehicle);
            return undefined;
          }

          var anyNonWithdrawalFilesOnApplication = false;
          angular.forEach($scope.verificationData.files, function(file){
            if(file.fileType !== 'PolicyTermination')
            {
              anyNonWithdrawalFilesOnApplication = true;
            }
          });

          if(ihestiaAutoUploadOneListUploadHelper.isActive($scope.uploaderName) &&
            !ihestiaAutoUploadOneListUploadHelper.checkAllFilesSendWithoutErrors($scope.uploaderName))
          {
            $scope.otherData.saveErrors.push({
              label: 'sendingWithFileError'
            });
            $scope.$broadcast('scrollToError');
            return false;
          }
          else if($scope.visible.tarrificationData && //jak mamy już ekran z uploadem
           $scope.currentUser.character.type !== 'Central' && //centrala może i bez plików
           (($scope.currentRecord.settlement && $scope.currentRecord.settlement.isFileRequired) || $scope.outerOffice) && //jak plik wymagany ze względu na kwotę albo w kancelarii zewnętrznej
           $scope.fileData.fileModels.length === 0 && //czy jest plik do wysłania
           !anyNonWithdrawalFilesOnApplication //albo już wysłany
           )
          {
            //walidacja na załączone pliki
            var noFileLabel;
            if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_PURCHASE)
            {
              noFileLabel = 'attachPurchaseFile';
            }
            else if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
            {
              noFileLabel = 'attachDonationFile';
            }
            else
            {
              noFileLabel = 'attachSaleFile';
            }
            $scope.otherData.saveErrors.push({
              label: noFileLabel
            });
            $scope.$broadcast('scrollToError');
            return undefined;
          }

          if($scope.canChangeVehicle && $scope.shared.vehicleDirty && $scope.shared.selectedVehicle && $scope.shared.selectedVehicle.policyNumber) //zmienił się wybrany pojazd, więc i polisa
          {
            $scope.verificationData.policyNumber = $scope.shared.selectedVehicle.policyNumber;
          }

          if(!$scope.verificationData.metaData || !$scope.verificationData.metaData.id)
          {
            //nie mamy jeszcze wniosku, więc najpierw musimy go zapisać
            $scope.prepareNewApplicationData();
            $scope.verifyPolicy(true, $scope.updateApplication); //musimy też od razu zaktualizować go naszymi danymi
          }
          else
          {
            //aktualizujemy wniosek
            $scope.updateApplication();
          }
        }
        else
        {
          $scope.$broadcast('scrollToError');
        }
      };

      /**
       * uzupełniamy dane potrzebne do utworzenia wsniosku
       */
      $scope.prepareNewApplicationData = function()
      {
        if($scope.shared.selectedVehicle)
        {
          $scope.verificationData.vehicleRegistrationNumber = $scope.shared.selectedVehicle.vehicleRegistrationNumber;
        }
        $scope.verificationData.operationDate = $scope.currentRecord.operationDate;
      };

      $scope.setFormSubmitted = function(formSubmitted)
      {
        $scope.formSubmitted = formSubmitted;
      };

      /**
       * ładujemy dane polisy na podstawie wybranego pojazdu bez blokowania GUI (centralSpecialMode)
       * @return {[type]} [description]
       */
      $scope.loadPolicyData = function()
      {
        $scope.prepareNewApplicationData();
        $scope.verifyPolicy(true, $scope.policyDataLoaded, true);
      };

      /**
       * załadowaliśmy dane polisy sprzedawanej/darowanej w trybie centralSpecialMode
       * @return {[type]} [description]
       */
      $scope.policyDataLoaded = function()
      {
        if($scope.getPersonsByRole($scope.roles.SELLER_OWNER).length > 0)
        {
          //aktualizujemy dane właściciela danymi z polisy
          $scope.setOwnerSelect();
        }
      };

      $scope.isPersonFromPolicy = function(personId)
      {
        if($scope.verificationData && $scope.verificationData.partnersFromPolicy && $scope.verificationData.partnersFromPolicy.length > 0)
        {
          //nowe wnioski
          //mamy uzupełnione partnersFromPolicy
          return ($scope.verificationData.partnersFromPolicy.indexOf(personId) !== -1);
        }
        else
        {
          //partnersFromPolicy jest puste, więc próbujemy sami rozpoznać
          return (parseInt(personId, 10) >= 0);
        }
      };

      /**
       * Ustawiamy dane selecta "poprzedni właściciel"
       * Może być kilku, lub magiczna opcja "inny"
       */
      $scope.setOwnerSelect = function() {
        var ownersData = $scope.getOwnersData();
        var otherOwner = false;
        var otherOwnerId = null;

        if(ownersData.ownersIds && ownersData.ownersIds[0] && !$scope.isPersonFromPolicy(ownersData.ownersIds[0]) && $scope.currentRecordNumber === 0)
        {
          //sprzedający to 'inny' wpisany wcześniej ręcznie, więc szukamy osób z polisy czyli takich o dodatnim id
          otherOwner = true;
          otherOwnerId = ownersData.ownersIds[0];
          ownersData = $scope.getOwnersData(true);
        }

        $scope.shared.ownersSelectList = [{
          name: ownersData.ownerName,
          id: ownersData.ownersIds
        }];

        if($scope.verificationData.metaData && $scope.verificationData.metaData.id)
        {
          //jak nie mamy jeszcze wniosku to nie zezwalamy na wybór

          var sellerLabel = 'otherSeller';
          if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
          {
            sellerLabel = 'otherDonator';
          }

          $scope.shared.ownersSelectList.push({
            name: $filter('translate')(sellerLabel, {componentCode: 'PolicyOperations'}),
            id: -1
          });
        }

        if(otherOwner)
        {
          $scope.shared.selectedOwners = $scope.shared.ownersSelectList[1].id;
          $scope.shared.otherSellerData = clientDataHelper.prepareApplicationPerson($scope.getPersonById(otherOwnerId));
        }
        else
        {
          $scope.shared.selectedOwners = $scope.shared.ownersSelectList[0].id;
        }
      };

      /**
       * czy może wybrać podjęcie zadania
       * @return {bool}
       */
      $scope.canGetTask = function()
      {
        return ($scope.isItFinalStep() &&
          !ihestiaPlatformHelper.isJupiterOrDirect() && //w jupiterze i polisaonline nie ma zadań EHNLJUPIT-1937
          !$scope.doBuyerHasPatron() &&
          !$scope.isWithdrawal() &&
          !$scope.isBuyerOrganization() &&
          $scope.otherData.saleLike &&
          (!$scope.verificationData.pendingTask || $scope.verificationData.pendingTask.taskType !== 'FillBankAccountNumber') &&
          $scope.verificationData.hasLiabilityInsurance &&
          ($scope.currentUser.character.type === UserCharacterTypeConstants.CHARACTER_TYPE_WORKER || $scope.currentUser.character.type === UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER)) ? true : false;
      };

      $scope.doBuyerHasPatron = function(){
        var buyerHasPatron = false;
        if($scope.verificationData.vehicleOwnerChangeRecords)
        {
          var persons = clientDataHelper.getPersonsByRole($scope.verificationData, 'last', 'BuyerOwner');
          if(persons[0] && persons[0].dynamicValues && persons[0].dynamicValues.hasPatron)
          {
            buyerHasPatron = true;
          }
        }
        return buyerHasPatron;
      };

      $scope.isItFinalStep = function()
      {
        var finalStep = $scope.visible.tarrificationData || $scope.visible.organizationTarrificationData;
        if(
          $scope.isSir ||
          $scope.outerOffice )
        {
          finalStep = true;
        }
        if($scope.shared.nextSale) //jest kolejna sprzedaż, więc nie zatwierdzamy tylko towrzymy nowy krok
        {
          finalStep = false;
        }
        if($scope.shouldWeShowOrganizationTarrification() && !$scope.visible.organizationTarrificationData)
        {
          //jeszcze będzie ekran taryfikacji klienta biznesowego
          finalStep = false;
        }
        return finalStep;
      };

      /**
       * aktualizujemy dane na wniosku
       */
      $scope.updateApplication = function()
      {
        var settlementWillBeShowed = true;
        if($scope.visible.clientData)
        {
          $scope.saveClients();
        }

        $scope.isFinalStep = $scope.isItFinalStep();
        if($scope.shared.nextSale) //jest kolejna sprzedaż, więc nie zatwierdzamy tylko towrzymy nowy krok
        {
          $scope.prepareNextRecord();
          $scope.setCurrentRecord($scope.currentRecordNumber + 1);
          $scope.loadClientsFromRecord();
          $scope.refreshOperationDatePeriod();
          settlementWillBeShowed = false;
        }

        $scope.updateOwnerChange($scope.isFinalStep, $scope.applicationSavedSuccess, settlementWillBeShowed);
      };

      /**
       * odświeżamy dane pobierając je z obecnego rekordu
       * @return {[type]} [description]
       */
      $scope.applicationInit = function()
      {
        $scope.init();
      };

      $scope.applicationSavedSuccess = function()
      {
        if($scope.shared.nextSale)
        {
          $scope.shared.nextSale = false;
          $scope.init();
        }
        else if(!$scope.visible.tarrificationData) //było bez taryfikacji, więc pokazujemy tylko taryfikację
        {
          $scope.visible.verificationData = false;
          $scope.visible.clientData = false;
          $scope.visible.additionalInformation = false;

          $scope.visible.tarrificationData = true;

          //pokazujemy taryfikację ostatniego kroku
          $scope.setCurrentRecord($scope.verificationData.vehicleOwnerChangeRecords.length - 1);
          $scope.refreshCurrentRecord();
        }

        else if($scope.shouldWeShowOrganizationTarrification())
        {
          $scope.formSubmitted = false;

          $scope.visible.verificationData = false;
          $scope.visible.clientData = false;
          $scope.visible.additionalInformation = false;
          $scope.visible.tarrificationData = false;

          $scope.visible.organizationTarrificationData = true;
        }
      };

      $scope.goBack = function()
      {
        if(!$scope.visible.clientData) //jesteśmy na taryfikacji i cofamy się
        {
          $scope.setInitVisiblity();
          var currentRecordNumber;
          if($scope.centralSpecialMode)
          {
            currentRecordNumber = 0;
          }
          else
          {
            //ustawiamy do edycji ostatni rekord
            currentRecordNumber = $scope.verificationData.vehicleOwnerChangeRecords.length - 1;
          }
          $scope.setCurrentRecord(currentRecordNumber);
        }
        else if($scope.currentRecordNumber > 0)
        {
          $scope.setCurrentRecord($scope.currentRecordNumber - 1);
          //usuwamy ostatni rekord
          $scope.verificationData.vehicleOwnerChangeRecords.splice($scope.verificationData.vehicleOwnerChangeRecords.length - 1, 1);
          $scope.refreshCurrentRecord();
          $scope.loadClientsFromRecord();
          $scope.loadSellersData();
          $scope.refreshOperationDatePeriod();
        }
        else if($scope.verificationData.metaData && $scope.verificationData.metaData.id)
        {
          //usuwamy wniosek jeśli jest
          $scope.$emit('showBlockUi');
          policyOperationsSvc.remove($scope.verificationData.metaData.id, null, function(){
            $scope.doGoBack();
            $scope.$emit('hideBlockUi');
          }, function(){
            $scope.$emit('hideBlockUi');
          });
        }
        else
        {
          $scope.doGoBack();
        }
      };

      /**
       * zmieniamy stan
       * @return {[type]} [description]
       */
      $scope.doGoBack = function()
      {
        if($scope.hasUserContext && $scope.verificationData.operationType !== $scope.VerificationDataModelConstants.VEHICLE_PURCHASE)
        //bezkontekstowe wejście do sprzedaży/darowizny w ikoncie
        {
          //nie mamy ekrany weryfikacji, więc wyrzucamy z policyOperations
          $scope.goToParent();
        }
        else
        {
          $scope.pageData.currentPage = $scope.pages.PAGE_VERIFY_POLICY;
        }
      };

      /**
       * zapisujemy dane osób na wniosku
       */
      $scope.saveClients = function()
      {
        if($scope.currentRecordNumber === 0 && $scope.shared.selectedOwners === -1)
        {
          $scope.clearPersons('seller');
          $scope.addPerson($scope.shared.otherSellerData, $scope.roles.SELLER_OWNER);
        }
        else if($scope.currentRecordNumber === 0 && angular.isArray($scope.shared.selectedOwners))
        {
          //chcemy mieć sellerów z polisy, ale możliwe że ktoś wcześniej na wniosku zapisał 'innego' ręcznie
          $scope.clearPersons('seller', true);
          var first = true;
          angular.forEach($scope.shared.selectedOwners, function(ownerId){
            if(first)
            {
              $scope.currentRecord.partnerRoles[ownerId] = $scope.roles.SELLER_OWNER;
            }
            else
            {
              $scope.currentRecord.partnerRoles[ownerId] = $scope.roles.SELLER_COOWNER;
            }
            first = false;
          });
        }

        $scope.clearPersons('buyer');
        $scope.addPerson($scope.shared.clientData, $scope.roles.BUYER_OWNER);
        angular.forEach($scope.shared.coowners, function(coowner){
          $scope.addPerson(coowner, $scope.roles.BUYER_COOWNER);
        });
      };


      /**
       * odświeżamy mnimalną datę operacji, na podstawie wcześniejszej
       * @return {[type]} [description]
       */
      $scope.refreshOperationDatePeriod = function()
      {
        if($scope.currentRecordNumber === 0)
        {
          $scope.shared.minOperationDate = '';
        }
        else
        {
          var previousRecord = $scope.verificationData.vehicleOwnerChangeRecords[$scope.currentRecordNumber - 1];
          $scope.shared.minOperationDate = $filter('date')(previousRecord.operationDate,'yyyy-MM-dd');
        }
      };

      $scope.shortagesChanged = function()
      {
        $scope.mapShortages();
        $scope.refreshShortageStatus();
      };

      /**
       * anulowanie nowego rekordu z modala, więc musimy po sobie posprzątać
       */
      $scope.newRecordCancel = function()
      {
        $scope.deleteLastRecord();
        $scope.init();
        $scope.vehiclePurchaseRecordEditModal.modalActive = false;
      };

      /**
       * zapis modala kolejnej sprzedaży
       * @return {[type]} [description]
       */
      $scope.saveRecord = function()
      {
        $scope.saveClients();//zapisujemy klientów na obecnym rekordzie
        $scope.setCurrentRecord(0);
        $scope.init();
        $scope.vehiclePurchaseRecordEditModal.modalActive = false;
      };

      /**
       * odświeżamy listę w boxie
       */
      $scope.refreshBoxData = function()
      {
        $scope.boxData = [];
        angular.forEach($scope.verificationData.vehicleOwnerChangeRecords, function(record, recordNumber){
          if(recordNumber > 0) //pierwszego nie pokazujemy
          {
            $scope.boxData.push({
              buyer: $scope.getPersonsByRole($scope.roles.BUYER_OWNER, recordNumber)[0],
              recordNumber: recordNumber
            });
          }
        });
      };

      /**
       * dane dla selecta 'Poprzedni właściciel'
       * @return {Object}
       */
      $scope.getOwnersData = function(getByPositiveIds)
      {
        var ownersData = {
          ownerNames: [],
          ownerName: '',
          ownersIds: []
        };
        var owners;

        if(getByPositiveIds)
        {
          owners = [];
          //bierzmy tych co mają zapisane (dodatnie) id
          angular.forEach($scope.verificationData.persons, function(person){
            if($scope.isPersonFromPolicy(person.metaData.id))
            {
              owners.push(person);
            }
          });
          angular.forEach($scope.verificationData.organizations, function(organization){
            if($scope.isPersonFromPolicy(organization.metaData.id))
            {
              owners.push(organization);
            }
          });
        }
        else
        {
          //bierzmy po roli na rekordzie
          owners = $scope.getPersonsByRole($scope.roles.SELLER_OWNER);
          owners = owners.concat($scope.getPersonsByRole($scope.roles.SELLER_COOWNER));
        }



        angular.forEach(owners, function(person) {
          if(person.name)
          {
            ownersData.ownerNames.push(person.name);
          }
          else
          {
            ownersData.ownerNames.push(person.firstName + ' ' + person.lastName);
          }
          ownersData.ownersIds.push(person.metaData.id);
        });

        //nazwa dla selecta
        ownersData.ownerName = ownersData.ownerNames.join(', ');

        return ownersData;
      };

      $scope.refreshCheckboxTooltip = function()
      {
        if($scope.shared.dataIsTruth || !$scope.formSubmitted)
        {
          $scope.shared.showCheckboxTooltip = false;
        }
        else
        {
          $scope.shared.showCheckboxTooltip = true;
        }
      };

      $scope.$watch('shared.dataIsTruth', function() {
        $scope.refreshCheckboxTooltip();
      }, true);
    }
  ]);