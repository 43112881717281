/**
 * Odpala funkcję przekazaną w atrybucie po załadowaniu obrazka
 * potrzebne przykładowo gdy załadowanie obrazka powiększa diva i chcemy się zescrollować
 */
angular.module('ihestiaCommonDirectives')
  .directive('lsnImgOnLoad', ['$compile', '$parse', '$timeout',
    function($compile, $parse, $timeout) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.on('load', function() {
          var callback = $parse(attrs.lsnImgOnLoad)(scope);
          if(angular.isFunction(callback))
          {
            $timeout(callback, 0);
          }
        });
        scope.$on('$destroy', function() {
          element.off('load');
        });
      }
    };
  }]);
