angular.module('salesPath2')
	.controller('matrixLocaliationBoxCtrl', ['$scope', 'CONSTANTS', 'matrixLocaliationBoxHelper', 'sp2CommonHelper', 'actionHelper', 'applicationHelper', 'localizationHelper', '$timeout', 'localizationModalHelper', '$filter', 'dataContainerHelper',
		function($scope, CONSTANTS, matrixLocaliationBoxHelper, sp2CommonHelper, actionHelper, applicationHelper, localizationHelper, $timeout, localizationModalHelper, $filter, dataContainerHelper) {
			$scope.tplData = matrixLocaliationBoxHelper.tplData;

			$scope.init = function() {
				matrixLocaliationBoxHelper.refreshLocalizations();
			};

			//usuwanie osoby/grupy z listy ubezpieczonych
			$scope.deleteLocalization = function(clientId) {
				sp2CommonHelper.showConfirmModal({
					treatLabelAsI18nCode: true,
					title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
					text: $filter('translate')('sureWantToDeleteLocalization', {componentCode: 'sp2'}),
					okBtnCallback: function() {
						localizationHelper.removeLocalization(clientId);
					}
				});
			};

			$scope.selectLocalization = function(clientId) {
				if (parseInt(dataContainerHelper.getSelectedObjectId(CONSTANTS.PRODUCT_TYPE_LOCALIZATION), 10) === parseInt(clientId, 10)) {
					return; //ten podmiot jest juz wybrany
				}
				dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, clientId);
				actionHelper.runAction('localizationSelected');
			};

			//otwarcie modala edycji podmiotu
			$scope.showEditModal = function(clientId) {
				$timeout(function() {
					localizationModalHelper.showEditLocalizationModal(clientId);
				}, 0);
			};

			$scope.showAddLocalizationModal = function() {
				$timeout(function() {
					localizationModalHelper.showAddLocalizationModal();
				}, 0);
			};

			$scope.init();
		}
	]);