angular.module('ihestiaWidgets.commonOperations')
  .factory('ihestiaTaskSearchSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var IhestiaTaskSearchSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks';
        this.businessArea = 'dashboard';
        this.pageStyle = false;
        this.sortBy = 'description';
      };

      return new IhestiaTaskSearchSvc();
    }
  ]);