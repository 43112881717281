angular.module('salesPath2')
  .controller('vehicleNonEtxModalCtrl', ['$scope', 'vehicleNonEtxModalHelper', '$q', 'vehicleModalHelper', 'vehicleHelper', 'actionHelper', 'VehicleModelConstants', 'sp2CommonHelper', 'CONSTANTS', 'coownerModalHelper',
    function($scope, vehicleNonEtxModalHelper, $q, vehicleModalHelper, vehicleHelper, actionHelper, VehicleModelConstants, sp2CommonHelper, CONSTANTS, coownerModalHelper) {

      $scope.modalData = vehicleNonEtxModalHelper.modalData;
      $scope.vehicleFieldsBlackList = {};
      $scope.vehicle = vehicleNonEtxModalHelper.modalData.vehicle;
      $scope.context = 'nonEtx';
      $scope.observers = [];
      $scope.sortedSelectCodes = [];

      // domyślne ustawienia liczbowych pól, np. liczba drzwi/miejsc
      $scope.stdVehicleNumericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: false
      };

      /**
       * Init
       */
      $scope.init = function() {
        if ($scope.modalData.dataCleared) {
          $scope.loadInitialData();
          $scope.modalData.dataCleared = false;
        } else { //powrót do edycji np. po zamknieciu modala "szczegolne przeznaczenie pojazdu"
          // z danych slownikowcyh typu string robimy ['string', nll, 'INTERNAL']
          angular.forEach($scope.modalData.formData.dependencySelects, function(value, code) {
            if (angular.isString(value)) {
              $scope.modalData.formData.dependencySelects[code] = [value, null, VehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL];
            }
          });
        }

        $scope.fireListeners();
        vehicleModalHelper.disableCompareButton();
      };


      /**
       * Odpalamy nasłuchiwanie eventow, watchy itp.
       */
      $scope.fireListeners = function() {
        // anuluj
        $scope.observers.push($scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleNonEtxEditModal', function() {
          vehicleNonEtxModalHelper.clearmodalData();
          vehicleModalHelper.vehicleNonEtxEditCanceled();
        }));

        // zapisz na edycji pojazdu
        $scope.observers.push($scope.$on('iHestiaCommonModalOkBtnClicked.vehicleNonEtxEditModal', function($event, obj) {
          if (obj.validForm) {
            angular.extend($scope.modalData.formData.simpleData, $scope.modalData.formData.dependencySelects);
            $scope.modalData.formData.additionalData.source = 'nonEtx';
            if (!$scope.modalData.formData.simpleData.power) {
              $scope.modalData.formData.simpleData.power = [''];
            }
            $scope.modalData.formData.simpleData.power[1] = $scope.modalData.formData.tmp.power;
            $scope.saveVehicle();
          } else {
            $event.preventDefault();
          }
        }));

        $scope.saveVehicle = function() {
          if ($scope.modalData.replaceClientId) {
            $scope.vehicleData.metaData.clientId = $scope.modalData.replaceClientId;
          }
          if ($scope.modalData.replaceEtxVehicle && $scope.modalData.clientId) {
            $scope.vehicleData.metaData.clientId = $scope.modalData.clientId;
          }

          // dane modelu
          vehicleHelper.prepareInternalData($scope.vehicleData, Object.keys($scope.modalData.formData.dependencySelects), true);
          $scope.modalData.vehicle.setData($scope.vehicleData);
          // dodatkowe dane
          if (!$scope.vehicleAdditionalData.isLeased) {
            $scope.vehicleAdditionalData.leaserId = null;
          }
          $scope.modalData.vehicle.setAdditionalData($scope.vehicleAdditionalData);
          if ($scope.CONFIG.BEHAVIOR.vehicle.etxValuation) {
            $scope.vehicleDynamicValues.vehicleValue = $scope.vehicleAdditionalData.vehicleValue;
          }
          $scope.modalData.vehicle.set('dynamicValues', $scope.vehicleDynamicValues);
          // dodajemy do dataContainer

          vehicleHelper.applyVehicle($scope.modalData.vehicle, false);

          // zapisano pojazd
          actionHelper.runAction('saveApplication');

          // IHESTIASS-418 showing communication person modal doesnt depend on hasCoowners
          // if ($scope.vehicleDynamicValues._hasCoowners && (!angular.isArray($scope.vehicleAdditionalData.coowners) || (angular.isArray($scope.vehicleAdditionalData.coowners) && $scope.vehicleAdditionalData.coowners.length === 0))) {
          coownerModalHelper.showCoownerList(CONSTANTS.PRODUCT_TYPE_VEHICLE);
          // }

          // usuwamy przypisanie aktualnie modyfikowanego pojazdu
          vehicleHelper.clearEditModalData();
        };

        // zmiany selectów z zależnościami
        $scope.observers.push($scope.$watch('modalData.formData.dependencySelects', function(newValues, oldValues) {
          var staticCodes = vehicleNonEtxModalHelper.staticCodes;

          angular.forEach(newValues, function(newValue, code) {
            if (!oldValues || !angular.equals(oldValues[code], newValue)) {

              if (code === 'category') {
                vehicleNonEtxModalHelper.fillLastThreeSelectors();
              }

              // nastąpiła zmiana kodu
              var blockClearDown = staticCodes.indexOf(code) > -1;
              vehicleNonEtxModalHelper.dependencySelectorsChanged(code, blockClearDown);
            }
          });

          vehicleNonEtxModalHelper.setIsCar($scope.modalData.formData.dependencySelects.category);
        }, true));
      };

      /**
       * zmieniły się selecty z zależnościami
       * @param  {string} selectCode [description]
       * @param {boolean} clearData czy mamy czyścic selecty w dół, domyślnie true
       */
      $scope.dependencySelectorsChanged = function(selectCode, clearData) {
        if (typeof clearData === 'undefined') {
          clearData = true;
        }
        vehicleNonEtxModalHelper.dependencySelectorsChanged(selectCode, !clearData);
      };

      /**
       * Inicjalne dane
       * @return {[type]} [description]
       */
      $scope.loadInitialData = function() {
        $scope.modalData.formData.simpleData = $scope.vehicle.getData(false, true);

        // przepisujemy selecty z zależnościami
        angular.forEach($scope.modalData.formData.dependencySelects, function(value, code) {
          $scope.modalData.formData.dependencySelects[code] = ($scope.modalData.etxProperties.indexOf(code) !== -1 ? $scope._processDependencySelectValue($scope.modalData.formData.simpleData[code]) : $scope.modalData.formData.simpleData[code]);
          delete $scope.modalData.formData.simpleData[code];
        });
        // additionalData
        $scope.modalData.formData.additionalData = $scope.vehicle.getAdditionalData();
        $scope.modalData.vehicleDynamicValues = $scope.vehicle.get('dynamicValues');
        // moc silnika
        if ($scope.modalData.formData.simpleData.power) {
          $scope.modalData.formData.tmp.power = $scope.modalData.formData.simpleData.power[1];
        }
        // ładowanie wszystkich potrzebnych słowników w przypadku edycji
        vehicleNonEtxModalHelper.fillDictionaryForCode('power', true);
        $scope.sortedSelectCodes = Object.keys($scope.modalData.formData.dependencySelects);

        $scope.loadCodeDictWithPromise();

        vehicleNonEtxModalHelper.setIsCar($scope.modalData.formData.dependencySelects.category);
      };

      /**
       * przetwarza wartość dla selecta własności pojazdu, aby była zgodna ze słownikiem ETX
       * @param  {String[]} val wartość tablicowa atrybutu pojazdu, np fuel
       * @return {string[]} przetworzona wartość
       */
      $scope._processDependencySelectValue = function(val) {
        if (angular.isArray(val) && val[2] === VehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL) {
          val[2] = VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX;
        }
        return val;
      };

      /**
       * Ładowanie słowników do selectów
       */
      $scope.loadCodeDictWithPromise = function() {
        return $q.all($scope.sortedSelectCodes.map(
          function(code) {
            vehicleNonEtxModalHelper.fillDictionaryForCode(code, true);
          })
        );
      };

      /**
       * Wybierz inny pojazd
       */
      $scope.chooseOtherVehicle = function() {
        vehicleModalHelper.chooseOtherVehicle();
      };

      $scope.init();

    }
  ]);
