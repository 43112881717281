angular.module('lsnBase.models')
.factory('LsnPersonModelConstants', [function() {
    var PersonModelConstants = {};

    PersonModelConstants.PERSON_GENDER_MALE = 'M';
    PersonModelConstants.PERSON_GENDER_FEMALE = 'F';
    // słownik płci
    PersonModelConstants.DICTIONARY_PERSON_GENDER = {};
    PersonModelConstants.DICTIONARY_PERSON_GENDER[PersonModelConstants.PERSON_GENDER_MALE] = 'mężczyzna';
    PersonModelConstants.DICTIONARY_PERSON_GENDER[PersonModelConstants.PERSON_GENDER_FEMALE] = 'kobieta';

    return PersonModelConstants;
  }]);