angular.module('salesPath2')
  /**
   * helper do działań na GUI
   */
  .service('sp2UiHelper', ['$rootScope', '$alert',
    function($rootScope, $alert) {

      var UiHelper = function() {
        /**
         * wyświetla "kręciołka" blokującego ekran
         * @param  {String} label tekst do wyświetlenia
         */
        this.showBlockUi = function(label) {
          $rootScope.$broadcast('showBlockUi', {
            label: label
          });
        };

        /**
         * ukrywa blokadę ekranu ("kręciołka")
         */
        this.hideBlockUi = function() {
          $rootScope.$broadcast('hideBlockUi');
        };

        /**
         * wyłączenie renderowania aplikacji - brak uprawnień
         * @return {[type]} [description]
         */
        this.enableRender = function() {
          $rootScope.canRender = true;
        };

        /**
         * wyłączenie renderowania aplikacji - brak uprawnień
         * @return {[type]} [description]
         */
        this.disableRender = function() {
          $rootScope.canRender = false;
        };

        /**
         * pokazuje komunikat typu growl
         * @param  {Object} data dane do bootstrapowego $alert
         */
        this.showAlert = function(data) {
          $alert(data);
        };

      };
      return new UiHelper();
    }
  ]);