angular.module('lsnBase.models')
  .factory('LsnPolicyModelV1', ['LsnAbstractModel', 'LsnPolicyModelConstants', 'lsnModelFactory', 'SalesProductModel',
    function(LsnAbstractModel, LsnPolicyModelConstants, lsnModelFactory, SalesProductModel)
    {

      var LsnPolicyModelV1 = function()
      {
        this.objectName = 'Policy';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          metaData:
          {
            type: 'MetaData'
          },
          number:
          {
            type: 'string'
          },
          calculationDate:
          {
            type: 'date'
          },
          start:
          {
            type: 'dateTime'
          },
          end:
          {
            type: 'dateTime'
          },
          signDate:
          {
            type: 'dateTime'
          },
          frequency:
          {
            type: 'int'
          },
          paymentMethod:
          {
            type: 'string',
            dictionary: LsnPolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD
          },
          place:
          {
            type: 'string'
          },
          installments:
          {
            type: 'array',
            elementsType: 'Installment'
          },
          pkd:
          {
            type: 'string'
          },
          insuredRef:
          {
            type: 'string'
          },
          persons:
          {
            type: 'array',
            elementsType: 'Person'
          },
          organizations:
          {
            type: 'array',
            elementsType: 'Organization'
          },
          vehicles:
          {
            type: 'array',
            elementsType: 'Vehicle'
          },
          risks:
          {
            type: 'array',
            elementsType: 'Risk'
          },
          estates:
          {
            type: 'array',
            elementsType: 'Estate'
          },
          holderRef:
          {
            type: 'string'
          },
          suretyRef:
          {
            type: 'array',
            elementsType: 'string'
          },
          riskBundleRef:
          {
            type: 'string'
          },
          distributions:
          {
            type: 'array',
            elementsType: 'DistributionChannel'
          },
          segment:
          {
            type: 'string'
          },
          underwritter:
          {
            type: 'Underwriter'
          },
          product:
          {
            type: 'SalesProduct'
          },
          messages:
          {
            type: 'array',
            elementsType: 'Message'
          },
          symbol:
          {
            type: 'string'
          },
          generalAgreementNumber:
          {
            type: 'string'
          },
          isSalesSupported:
          {
            type: 'bool'
          },
          salesDate:
          {
            type: 'dateTime'
          }
        };



        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.number = null; // string, numer polisy
        this.calculationDate = null; // date, Data z jaką została wykonana taryfikacja
        this.start = null; // date, Data rozpoczęcia okresu ochrony na polisie
        this.end = null; // date, Data zakończenia okresu ochrony na polisie
        this.signDate = null; // date, Data wystawienia polisy
        this.frequency = null; // number, Częstotliwość składek (1 - roczna, 2 - półroczna, 4 - kwartalna, 12 - miesięczna, 99 - jednorazowa)
        this.place = null; // string, Miejsce podpisania umowy
        this.installments = null; // lista Installment, Kalendarz rat
        this.pkd = null; // string, Kod użyty do taryfikacji
        this.insuredRef = null; // string, Identyfikator ubezpieczonego. MetaData.Id z listy person lub organization
        this.persons = null; // lista Person, Lista osób. MetaData.Id musi być unikalne w obrębie person i organization
        this.organizations = null; // lista Organization, Lista osób prawnych. MetaData.ID musi być unikalne w obrębie person i organization
        this.vehicles = null; // Vehicle, Ubezpieczany pojazd. Maksymalnie jeden na polisie
        this.risks = null; // lista Risk, Kolekcja ubezpieczeń
        this.estates = null; // lista Estate, Lista nieruchomości do ubezpieczenia
        this.holderRef = null; // string, Idenyfikator ubezpieczającego . MetaData.Id z listy persons lub organizations
        this.suretyRef = null; // lista string, Lista pól MetaData.ID z organizations. Umieścić należy tylko te id organizacji, które udzielają cesji
        this.riskBundleRef = null; // string, Definicja wariantu pakietu w zależności od definicji produktu w BOS.PTY
        this.distributions = null; // lista DistributionChannel
        this.segment = null; // Segment klienta. Własność tylko do odczytu.
        this.underwritter = null; // Underwriter
        this.paymentMethod = null; // string, Forma płatności pierwszej raty. Dozwolone wartości:
        this.product = new SalesProductModel(); // SalesProduct SalesProduct  Pakiet ubezpieczeniowy
        this.messages = null; // lista Message     Lista komunikatów biznesowych. Własność tylko do odczytu.
        this.symbol = null; // symbol polisy H7 nMF ect. potrzebne do wczytania przez słowniki
        this.generalAgreementNumber = null;
        this.isSalesSupported = null; // czy rozszerzenie polisy jest możliwe na sprzedażówkach
        this.salesDate = null; // data sprzedaży
      };

      LsnPolicyModelV1.prototype = LsnAbstractModel.prototype;

      return LsnPolicyModelV1;
    }
  ]);