angular.module('ihestiaRestServicesBase')
  .factory('simpleOAuthHelper', ['$window', 'ihestiaRestRequestStorageHelper', 'oauthSvc', 'simpleOAuthDataHelper',
    function($window, ihestiaRestRequestStorageHelper, oauthSvc, simpleOAuthDataHelper){

    var SimpleOAuthHelper = function() {

      var self = this;

      this.refreshInProgress = false;

      this.handleTokenExpired = function(accessTokenId, provider, callArguments)
      {
        if(simpleOAuthDataHelper.getRefreshToken() !== null)
        {
          if(accessTokenId === simpleOAuthDataHelper.getAccessTokenId()) //identyfikator sesji jeszcze się nie zmienił
          {
            self.refreshTokenAndCallAgain(provider, callArguments);
          }
          else
          {
            //sesja została już wcześniej zregenerowana, więc od razu strzelamy
            ihestiaRestRequestStorageHelper.call(provider, callArguments);
          }
        }
        else
        {
          simpleOAuthDataHelper.logout();
        }
      };

      this.getAccessTokenId = function()
      {
        return simpleOAuthDataHelper.getAccessTokenId();
      };

      this.refreshTokenAndCallAgain = function(provider, callArguments)
      {
        if(!self.refreshInProgress)
        {
          self.refreshToken(); //jeśli nie leci jeszcze request regenreacji tokenu to go puszczamy
        }
        ihestiaRestRequestStorageHelper.add(provider, callArguments); //przekazujemy dalej argumenty
      };

      this.refreshToken = function()
      {
        self.refreshInProgress = true;
        oauthSvc.refreshToken().then(function(){
          self.refreshInProgress = false;
          ihestiaRestRequestStorageHelper.callAllAgain();
        }, function(res){
          self.refreshInProgress = false;
          simpleOAuthDataHelper.logout(res && res.status ? res.status : '500');
        });
      };
    };

    return new SimpleOAuthHelper();
  }]);