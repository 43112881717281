angular.module('ihestiaCommonDirectives.application')
  .factory('AbstractPolicyAndOfferHelper', ['ihestiaRolesOnPolicyConstants',
    function(ihestiaRolesOnPolicyConstants) {

      var AbstractPolicyAndOfferHelper = function(model) {

        /**
         * Model wniosku
         * @type {ApplicationModel|PolicyModel}
         */
        this.model = model;

        /**
         * Zwracamy ubezpieczającego
         * @return {PersonModel|OrganizationModel|{}} ubezpieczający
         */
        this.getInsurer = function() {
          return this.getInsured(this.model.holderRef);
        };

        /**
         * Zwracamy głównego ubezpieczonego
         * @return {PersonModel|OrganizationModel|{}} główny ubezpieczony
         */
        this.getMainInsured = function() {
          return this.getInsured(this.model.insuredRef);
        };

        /**
         * Zwracamy id wszystkich lokalizacji
         * @return {array} ['1', '323']
         */
        this.getAllEstateRefs = function() {
          var estateRefs = [];

          // zbieramy idki lokalizacji
          angular.forEach(this.model.estates, function(estate){
            estateRefs.push(estate.metaData.id);
          });

          return estateRefs;
        };

        /**
         * Zwracamy id wszystkich pojazdów
         * @return {array}
         */
        this.getAllVehicleRefs = function() {
          var vehicleRefs = [];

          // zbieramy idki lokalizacji
          angular.forEach(this.model.vehicles, function(estate){
            vehicleRefs.push(estate.metaData.id);
          });

          return vehicleRefs;
        };

        /**
         * Sprawdza, czy osoba jest na jakimkolwiek ryzyku komunikacyjnym
         * @param  {int}  personRef metadata.id osoby
         * @return {Boolean}
         */
        this.isPersonOnCommunicationRisk = function(personRef) {
          var vehicleRefs = this.getAllVehicleRefs();
          var isPersonOnCommunicationRisk = false;
          angular.forEach(this.model.risks, function(risk){
            var isPersonOnRisk = false;
            var isCommunicationRisk = false;
            // ogarniamy, czy nasza osoba jest na ryzyku
            angular.forEach(risk.insuredSubjectsRef, function(subjectRefObject){
              if(subjectRefObject.ref === personRef) {
                isPersonOnRisk = true;
              }
            });
            // ogarniamy, czy ryzyko jest komunikacyjne (posiada referencję do pojazdu)
            angular.forEach(risk.insuredObjectsRef, function(objectRefObject){
              if(vehicleRefs.indexOf(objectRefObject.ref) > -1) {
                isCommunicationRisk = true;
              }
            });
            // jeśli oba powyższe ok, to osoba jest na ryzyku komunikacyjnym
            if(isPersonOnRisk && isCommunicationRisk) {
              isPersonOnCommunicationRisk = true;
            }
          });

          return isPersonOnCommunicationRisk;
        };

        /**
         * Zwracamy referencje do współwłaścicieli na lokalizacjach
         * w szczególności nie zwracamy głównego ubezpieczonego
         *
         * @param {Boolean} onlyInCoownerRole [default - true] usuwamy wszystkich, którzy są powiązani z innymi ryzykami
         * @return {Array} [description]
         */
        this.getAllEstateCoowners = function(onlyInCoownerRole) {
          var coowners = [],
            estateRefs = this.getAllEstateRefs();

          // zbieramy osoby dla wszystkich lokalizacji
          coowners = this.getAllPersonsRefsForObject(estateRefs);

          // wywalamy z tablicy współwłaścicieli głównego ubezpieczonego
          var insuredIndex = coowners.indexOf(this.model.holderRef);
          if(insuredIndex > -1) {
            coowners.splice(insuredIndex, 1);
          }

          // usuwamy ludzi powiązanych z innymi ryzykami
          if(onlyInCoownerRole !== false) {
            angular.forEach(this.model.risks, function(risk){
              angular.forEach(risk.insuredObjectsRef, function(refObject){
                // jeśli to ryzyko nie jest ryzykiem z lokalizacji, to usuwamy osoby do niego przypisane
                if(estateRefs.indexOf(refObject.ref) === -1) {
                  angular.forEach(risk.insuredSubjectsRef, function(insuredSubject){
                    var insuredSubjectRefIndex = coowners.indexOf(insuredSubject.ref);
                    if(insuredSubjectRefIndex > -1) {
                      coowners.splice(insuredSubjectRefIndex, 1);
                    }
                  });
                }
              });
            });
          }

          return coowners;
        };


        /**
         * Zwracamy referencje do współwłaścicieli na pojazdach
         * w szczególności nie zwracamy głównego ubezpieczonego
         *
         * @param {Boolean} onlyInCoownerRole [default - true] usuwamy wszystkich, którzy są powiązani z innymi ryzykami
         * @return {Array} [description]
         */
        this.getAllVehicleCoowners = function(onlyInCoownerRole) {
          var coowners = [],
            vehicleRefs = this.getAllVehicleRefs();

          // zbieramy osoby dla wszystkich pojazdów
          coowners = this.getAllPersonsRefsForObject(vehicleRefs);

          // wywalamy z tablicy współwłaścicieli głównego ubezpieczonego
          var insuredIndex = coowners.indexOf(this.model.holderRef);
          if(insuredIndex > -1) {
            coowners.splice(insuredIndex, 1);
          }

          // usuwamy ludzi powiązanych z innymi ryzykami
          if(onlyInCoownerRole !== false) {
            angular.forEach(this.model.risks, function(risk){
              angular.forEach(risk.insuredObjectsRef, function(refObject){
                // jeśli to ryzyko nie jest ryzykiem z lokalizacji, to usuwamy osoby do niego przypisane
                if(vehicleRefs.indexOf(refObject.ref) === -1) {
                  angular.forEach(risk.insuredSubjectsRef, function(insuredSubject){
                    var insuredSubjectRefIndex = coowners.indexOf(insuredSubject.ref);
                    if(insuredSubjectRefIndex > -1) {
                      coowners.splice(insuredSubjectRefIndex, 1);
                    }
                  });
                }
              });
            });
          }

          return coowners;
        };


        /**
         * Pobieramy wszystkie ubezpieczone osoby
         * @param {boolean} withInsurer jeśli false, to nie uwzględniamy ubezpieczającego
         * @param {String|null} objectId jeśli podane, to zwracamy ubezpieczonych powiązanych z danym obiektem
         * @param {boolean} onlyPersonalRisks jeśli true, to bierzemy tylko osoby z ryzyk osobowych
         * @return {PersonModel[]}          ubezpieczeni
         */
        this.getAllInsuredPersons = function(withInsurer, objectId, onlyPersonalRisks) {
          var subjectsSource = this.model.persons;
          return this._getAllInsuredSubjects(subjectsSource, withInsurer, objectId, onlyPersonalRisks);
        };

        /**
         * Pobieramy wszystkich ubezpieczonych
         * @param {boolean} withInsurer jeśli false, to nie uwzględniamy ubezpieczającego
         * @param {String|null} objectId jeśli podane, to zwracamy ubezpieczonych powiązanych z danym obiektem
         * @param {boolean} onlyPersonalRisks jeśli true, to bierzemy tylko osoby z ryzyk osobowych
         * @return {PersonModel[]}          ubezpieczeni
         */
        this.getAllInsuredSubjects = function(withInsurer, objectId, onlyPersonalRisks) {
          var organizations = [],
            organizationRefs = [],
            allowedRoles = [
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_INSURED,
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_VEHICLE_OWNER,
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_VEHICLE_COOWNER,
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_COINSURED_OR_COOWNER,
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_ADDITIONAL_INSURED,
              ihestiaRolesOnPolicyConstants.ROLE_ON_POLICY_COINSURED
            ];
          // wydłubujemy do obróbki tylko te organizacje, które istnieją na ryzykach
          // omijamy m.in. cesję
          angular.forEach(this.model.risks, function(risk){
            angular.forEach(risk.insuredSubjectsRef, function(subjectRefObject){
              if(organizationRefs.indexOf(subjectRefObject.ref) === -1 && allowedRoles.indexOf(subjectRefObject.groupSymbol) > -1) {
                organizationRefs.push(subjectRefObject.ref);
              }
            });
          });
          angular.forEach(this.model.organizations, function(organization){
            if(organizationRefs.indexOf(organization.metaData.id) > -1) {
              organizations.push(organization);
            }
          });
          var subjectsSource = angular.isArray(this.model.persons) ? this.model.persons : [];
          subjectsSource = subjectsSource.concat(organizations);
          return this._getAllInsuredSubjects(subjectsSource, withInsurer, objectId, onlyPersonalRisks);
        };

        /**
         * Funkcja pomocnicza
         * Pobieramy wszystkich ubezpieczonych (organizacje/osoby)
         * @param {array} subjetcsSource tablica z osobami/ogranizacjami
         * @param {boolean} withInsurer jeśli false, to nie uwzględniamy ubezpieczającego
         * @param {String|null} objectId jeśli podane, to zwracamy ubezpieczonych powiązanych z danym obiektem
         * @param {boolean} onlyPersonalRisks jeśli true, to bierzemy tylko osoby z ryzyk osobowych
         * @return {PersonModel[]}          ubezpieczeni
         */
        this._getAllInsuredSubjects = function(subjectsSource, withInsurer, objectId, onlyPersonalRisks) {
          var self = this;
          var persons = [],
            personRefs = [],
            checkRefs = false;

          if(onlyPersonalRisks)
          {
            checkRefs = true;
            personRefs = self.getAllPersonsRefsFromPersonalRisks();
          }
          // zbieramy info o referencjach do osób powiązanych z obietem
          else if (angular.isDefined(objectId) && objectId !== null) {
            checkRefs = true;
            personRefs = self.getAllPersonsRefsForObject(objectId);
          }

          // właściwe wyciąganie danych osób
          angular.forEach(subjectsSource, function(person) {
            if (!checkRefs || personRefs.indexOf(person.metaData.id) > -1) {
              if (withInsurer !== false || person.metaData.id !== self.model.holderRef) {
                persons.push(person);
              }
            } else if (withInsurer !== false && person.metaData.id !== self.model.holderRef) {
              persons.push(person);
            }
          });

          return persons;
        };

        /**
         * Pobieranie wszystkich referencji do osób przypisanych do ryzyk osobowych
         * dedykowane dla mienia
         * @return {String[]}
         */
        this.getAllPersonsRefsFromPersonalRisks = function()
        {
          var personRefs = [];
          var self = this;

          var estatesIds = [];
          angular.forEach(self.model.estates, function(estate) {
            estatesIds.push(estate.metaData.id);
          });

          // @todo albo zawse return, albo nigdy (w foreachu i tak nie ma znaczenia)
          angular.forEach(self.model.risks, function(risk) {// eslint-disable-line consistent-return
            var isEstateRisk = false;
            if(risk.product.dynamicValues._groupSymbol === 'SUPPLEMENT')
            {
              //dodatków nie chcemy
              return false;
            }

            //wykluczamy ryzyka mienia
            angular.forEach(risk.insuredObjectsRef, function(insuredObjectRef) {
              if (estatesIds.indexOf(insuredObjectRef.ref) !== -1) {
                isEstateRisk = true;
                return;
              }
            });
            if(isEstateRisk)
            {
              //ryzyko z lokalizacji, więc odrzucamy
              //@todo coś trzeba zwracać, może false?
              return; // eslint-disable-line consistent-return
            }

            //mamy ryzyko osobowe i zbieramy osoby
            angular.forEach(risk.insuredSubjectsRef, function(insuredSubjectRef) {
              if(personRefs.indexOf(insuredSubjectRef.ref) === -1)
              {
                //wrzucamy jeśli jeszcze nie ma
                personRefs.push(insuredSubjectRef.ref);
              }
            });
          });

          return personRefs;
        };

        /**
         * Pobieranie wszystkich referencji do osób na podstawie id obiektu
         * @param  {String|String[]} objectId id obiektu (estate, vehicle, ...)
         * @return {String[]}
         */
        this.getAllPersonsRefsForObject = function(objectId) {
          var personRefs = [];
          var self = this;
          angular.forEach(self.model.risks, function(risk) {
            var isObjectRisk = false;
            // sprawdzamy, czy to ryzyko dotyczy wybranego obiektu
            angular.forEach(risk.insuredObjectsRef, function(insuredObjectRef) {
              if(insuredObjectRef.ref === objectId || (angular.isArray(objectId) && objectId.indexOf(insuredObjectRef.ref) > -1)) {
                isObjectRisk = true;
              }
            });
            // jeśli tak, to wrzucamy wszystkie referencje do osób z tego ryzyka jako potencjalnie dostępne
            if (isObjectRisk) {
              angular.forEach(risk.insuredSubjectsRef, function(insuredSubjectRef) {
                personRefs.push(insuredSubjectRef.ref);
              });
            }
          });

          // zapewniamy unikatowość
          var unique = [];
          angular.forEach(personRefs, function(personRef){
            if(unique.indexOf(personRef) === -1) {
              unique.push(personRef);
            }
          });
          return unique;
        };

        /**
         * Pobieranie ról osób na podstawie ryzyk.
         * @return {Object}
         */
        this.getPersonsRolesFromRisks = function() {
          var personRefs = {};
          var self = this;
          angular.forEach(self.model.risks, function(risk) {
            angular.forEach(risk.insuredSubjectsRef, function(insuredSubjectRef) {
              if (angular.isUndefined(personRefs[insuredSubjectRef.ref])) {
                personRefs[insuredSubjectRef.ref] = insuredSubjectRef.groupSymbol;
              }
              if (personRefs.length === self.model.persons.length) { // jeśli mamy rolę dla każdej osoby
                return; // to kończymy
              }
            });
          });

          return personRefs;
        };

        /**
         * Pobieranie ról osób na podstawie ryzyk - Jupiter.
         * @return {Object}
         */
        this.getJupiterPersonsRolesFromRisks = function() {
          var personRefs = {};
          var self = this;
          angular.forEach(self.model.risks, function(risk) {
            angular.forEach(risk.personsRefs, function(insuredSubjectRef) {
              if (angular.isUndefined(personRefs[insuredSubjectRef.ref])) {
                personRefs[insuredSubjectRef.ref] = insuredSubjectRef.role;
              }
              if (personRefs.length === self.model.persons.length) { // jeśli mamy rolę dla każdej osoby
                return; // to kończymy
              }
            });
          });

          return personRefs;
        };

        /**
         * Zwracamy ubezpieczonego/ubezpieczającego/współubezpieczonego
         * generalnie organizację lub osobę
         * na podstawie metaData.id
         *
         * @param  {int} insuredRef [description]
         * @return {PersonModel|OrganizationModel|{}}
         */
        this.getInsured = function(insuredRef) {
          var insured = null;
          var self = this;

          // przeszukujemy osoby
          angular.forEach(self.model.persons, function(person) {
            if (person.metaData.id === insuredRef) {
              insured = person;
            }
          });

          if (!insured) {
            angular.forEach(self.model.organizations, function(organization) {
              if (organization.metaData.id === insuredRef) {
                insured = organization;
              }
            });
          }

          return insured ? insured : {};
        };

        /**
         * Zwraca dane agenta, który wystawił iOfertę
         * @return {object} dane agenta
         */
        this.getAgentData = function() {
          var agent = {};
          angular.forEach(this.model.distributions, function(distribution) {
            if (distribution.leading) {
              agent = distribution;
            }
          });

          return agent;
        };
      };

      return AbstractPolicyAndOfferHelper;
    }]);