angular.module('salesPath2')
  .service('dcAmountHelper', ['CONSTANTS', 'resourceHelper', 'mainDataContainer', 'dcCommonHelper', 'appVariables', 'CONFIG',
    function(CONSTANTS, resourceHelper, mainDataContainer, dcCommonHelper, appVariables, CONFIG) {
      var dcAmountHelper = function() {
        var self = this;

        /**
         * ustawia sumę ubezpieczenia
         * @param {String} objectType
         * @param {String} objectId
         * @param {String} riskCode tyzyko lub produkt
         * @param {Number} value
         * @param {Number} [max] opcjonalne max SU
         * @param {String} [subRiskCode] ryzyko w przypadku produktów ogniowych lub kradzieżowych
         */
        this.setSu = function(objectType, objectId, riskCode, value, subRiskCode, max) {
          if (angular.isUndefined(mainDataContainer.suList[objectType][objectId])) {
            mainDataContainer.suList[objectType][objectId] = {};
          }
          if (angular.isUndefined(mainDataContainer.suList[objectType][objectId][riskCode])) {
            mainDataContainer.suList[objectType][objectId][riskCode] = {};
          }
          if (angular.isDefined(subRiskCode)) {
            mainDataContainer.suList[objectType][objectId][riskCode][subRiskCode] = self.roundSumInsured(riskCode, subRiskCode, value, max);
          } else {
            mainDataContainer.suList[objectType][objectId][riskCode] = angular.copy(value);
            if (angular.isObject(mainDataContainer.suList[objectType][objectId][riskCode])) {
              angular.forEach(mainDataContainer.suList[objectType][objectId][riskCode], function(val, vnt) {
                mainDataContainer.suList[objectType][objectId][riskCode][vnt] = self.roundSumInsured(riskCode, vnt, val, max);
              });
            }
          }
        };

        /**
         * zaokragla sume ubezpieczenia na podstawie definicji produktu i ewentualnie przekazanego max-a
         * @param  {String} prod kod produktu
         * @param  {String} variant kod wariantu
         * @param  {[type]} value   [description]
         * @param  {Number} [max] opcjonalny max dla SU, np. w przypadku wariantu z określaniem dochodu
         * @return {Number}
         */
        this.roundSumInsured = function(prod, variant, value, max) {
          if (angular.isUndefined(resourceHelper.sumInsuredRoundings[prod]) || (angular.isString(variant) && angular.isUndefined(resourceHelper.sumInsuredRoundings[prod][variant]))) {
            return value;
          }
          var maxSu = angular.isString(variant) ? resourceHelper.sumInsuredRoundings[prod][variant].max : resourceHelper.sumInsuredRoundings[prod].max,
            minSu = angular.isString(variant) ? resourceHelper.sumInsuredRoundings[prod][variant].min : resourceHelper.sumInsuredRoundings[prod].min;

          if (angular.isDefined(max) && max !== null) {
            maxSu = max;
          }

          if (value !== 0 && !value) {
            value = 0;
          } else if (value <= minSu) {
            value = minSu;
          } else if (value > maxSu) {
            value = maxSu;
          } else {
            var rest = value % 10000;
            var base = Math.floor(value / 10000) * 10000;
            if (rest >= 5000) {
              value = base + 10000;
            } else {
              value = base;
            }
          }

          return value;
        };

        /**
         * zwraca sumę ubezpieczenia odpowiedniego poziomu w zależności od ilości przekazanych argumentów
         * @param  {String} objectType
         * @param  {String} objectId
         * @param  {String} riskCode
         * @param  {String} [subRiskCode]
         * @return {Number|Object}
         */
        this.getSu = function(objectType, objectId, riskCode, subRiskCode) {
          if (angular.isUndefined(mainDataContainer.suList[objectType][objectId])) {
            return {};
          }
          if (angular.isUndefined(riskCode)) {
            return mainDataContainer.suList[objectType][objectId];
          }
          if (angular.isUndefined(mainDataContainer.suList[objectType][objectId][riskCode])) {
            return {};
          }
          if (angular.isUndefined(subRiskCode)) {
            return mainDataContainer.suList[objectType][objectId][riskCode];
          }
          if (angular.isUndefined(mainDataContainer.suList[objectType][objectId][riskCode][subRiskCode])) {
            return {};
          }
          return mainDataContainer.suList[objectType][objectId][riskCode][subRiskCode];
        };

        /**
         * zwraca składkę za ryzyko
         * @param  {String} objectType
         * @param  {String} objectId
         * @param  {String} riskCode
         * @param  {String} [subRiskCode]
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {Number}
         */
        this.getPremium = function(objectType, objectId, riskCode, subRiskCode, container) {
          if (!angular.isString(container)) {
            container = 'premiumList';
          }
          if (angular.isUndefined(mainDataContainer[container][objectType])) {
            return {};
          }
          if (angular.isUndefined(mainDataContainer[container][objectType][objectId])) {
            return {};
          }
          if (angular.isUndefined(riskCode)) {
            return mainDataContainer[container][objectType][objectId];
          }
          if (angular.isUndefined(mainDataContainer[container][objectType][objectId][riskCode])) {
            return {};
          }
          if (angular.isUndefined(subRiskCode)) {
            return mainDataContainer[container][objectType][objectId][riskCode];
          }
          if (angular.isUndefined(mainDataContainer[container][objectType][objectId][riskCode][subRiskCode])) {
            return {};
          }
          return mainDataContainer[container][objectType][objectId][riskCode][subRiskCode];
        };

        this.getPremiumBeforeDiscout = function(objectType, objectId, riskCode, subRiskCode, pure) {
          var premContainer = 'premBeforeDiscList';
          if (pure) { // pure premium (without attached additions)
            premContainer = 'premPureBeforeDiscList';
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType])) {
            return {};
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId])) {
            return {};
          }
          if (angular.isUndefined(riskCode)) {
            return mainDataContainer[premContainer][objectType][objectId];
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId][riskCode])) {
            return {};
          }
          if (angular.isUndefined(subRiskCode)) {
            return mainDataContainer[premContainer][objectType][objectId][riskCode];
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId][riskCode][subRiskCode])) {
            return {};
          }
          return mainDataContainer[premContainer][objectType][objectId][riskCode][subRiskCode];
        };

        this.getPremiumAfterDiscout = function(objectType, objectId, riskCode, subRiskCode, pure) {
          var premContainer = 'premAfterDiscList';
          if (pure) { // pure premium (without attached additions)
            premContainer = 'premPureAfterDiscList';
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType])) {
            return {};
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId])) {
            return {};
          }
          if (angular.isUndefined(riskCode)) {
            return mainDataContainer[premContainer][objectType][objectId];
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId][riskCode])) {
            return {};
          }
          if (angular.isUndefined(subRiskCode)) {
            return mainDataContainer[premContainer][objectType][objectId][riskCode];
          }
          if (angular.isUndefined(mainDataContainer[premContainer][objectType][objectId][riskCode][subRiskCode])) {
            return {};
          }
          return mainDataContainer[premContainer][objectType][objectId][riskCode][subRiskCode];
        };

        /**
         * wyciągnięcie pojedynczej składki za dodatek
         * @param  {string} addCode  kod dodatku.premiumList
         * @param  {string} variant  wariant
         * @param  {string|int} objectId id obiektu
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {int}          składka
         */
        this.getAddPremium = function(addCode, variant, objectId, container) {
          if (!angular.isString(container)) {
            container = 'premiumList';
          }
          if (angular.isUndefined(variant)) {
            variant = null;
          }
          if (angular.isUndefined(objectId)) {
            objectId = null;
          }

          if (objectId === CONSTANTS.NO_OBJECT) //brak lokalizacji/pojazdu więc pomijamy
          {
            return 0;
          }

          var addDef = resourceHelper.getAddDef(addCode);
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isUndefined(mainDataContainer[container][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode])) {
              return 0;
            }
            if (variant === null) {
              return mainDataContainer[container][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode];
            } else {
              return mainDataContainer[container][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode][variant];
            }
          } else {
            var premiumContainer = mainDataContainer[container][addDef.TYPE];
            return self._getTotalAddPremium(premiumContainer, addCode, objectId, variant);
          }
        };

        /**
         * zwraca sumę składek dla danego dodatku
         * @param  {*} premiumContainer kontener ze skladkmai z mainDataContainera
         * @param  {String} addCode kod dodatku
         * @param  {String} objectId clientId obiektu/podmiotu ubezpieczenia
         * @param  {String} variant wariant ubezpieczenia
         * @return {Number} łączna składka
         */
        this._getTotalAddPremium = function(premiumContainer, addCode, objectId, variant) {
          var premium = 0;
          if (objectId === CONSTANTS.ALL_OBJECTS) {
            angular.forEach(premiumContainer, function(objectRisks) {
              if (angular.isDefined(objectRisks[addCode])) {
                if (variant === null) {
                  premium += objectRisks[addCode];
                } else {
                  premium += objectRisks[addCode][variant];
                }
              }
            });
          } else {
            if (angular.isUndefined(premiumContainer[objectId])) {
              return 0;
            }
            premiumContainer = premiumContainer[objectId];
            if (angular.isDefined(premiumContainer[addCode])) {
              if (variant === null) {
                premium += premiumContainer[addCode];
              } else {
                premium += premiumContainer[addCode][variant];
              }
            }
          }
          return premium;
        };

        this.getAddPremiumBeforeDiscout = function(addCode, variant, objectId, pure) {
          var premContainer = 'premBeforeDiscList';
          if (pure) { // pure premium (without attached additions)
            premContainer = 'premPureBeforeDiscList';
          }
          if (angular.isUndefined(variant)) {
            variant = null;
          }
          if (angular.isUndefined(objectId)) {
            objectId = null;
          }

          if (objectId === CONSTANTS.NO_OBJECT) //brak lokalizacji/pojazdu więc pomijamy
          {
            return 0;
          }

          var addDef = resourceHelper.getAddDef(addCode);
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isUndefined(mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode])) {
              return 0;
            }
            if (variant === null) {
              return mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode];
            } else {
              return mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode][variant];
            }
          } else {
            var premiumContainer = mainDataContainer[premContainer][addDef.TYPE];
            return self._getTotalAddPremium(premiumContainer, addCode, objectId, variant);
          }
        };

        this.getAddPremiumAfterDiscout = function(addCode, variant, objectId, pure) {
          var premContainer = 'premAfterDiscList';
          if (pure) { // pure premium (without attached additions)
            premContainer = 'premPureAfterDiscList';
          }
          if (angular.isUndefined(variant)) {
            variant = null;
          }
          if (angular.isUndefined(objectId)) {
            objectId = null;
          }

          if (objectId === CONSTANTS.NO_OBJECT) //brak lokalizacji/pojazdu więc pomijamy
          {
            return 0;
          }

          var addDef = resourceHelper.getAddDef(addCode);
          if (addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE) {
            if (angular.isUndefined(mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode])) {
              return 0;
            }
            if (variant === null) {
              return mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode];
            } else {
              return mainDataContainer[premContainer][CONSTANTS.PRODUCT_TYPE_PACKAGE][addCode][variant];
            }
          } else {
            var premiumContainer = mainDataContainer[premContainer][addDef.TYPE];
            return self._getTotalAddPremium(premiumContainer, addCode, objectId, variant);
          }
        };

        /**
         * Zbiera dane zniżek klienckich z wszystkich polis
         * @return {object}
         */
        this.getDiscounts = function() {
          var data = {
            loyaltyDiscountAmount: 0, //lojalność
            loyaltyDiscount: false,
            countedLoyaltyDiscount: 0,
            premiumFromActivePolicesDiscountAmount: 0, //składka
            premiumFromActivePolicesDiscount: false,
            countedPremiumFromActivePolicesDiscount: 0,
            propertyInsuranceDiscountAmount: 0, //pozakomunikacyjne
            propertyInsuranceDiscount: false,
            countedPropertyInsuranceDiscount: 0,
            loyaltyBonusDiscounttAmount: 0, //zniżka kliencka suma (kwota)
            countedLoyaltyBonusDiscount: 0,
            discountPromotion: 0,
            tariffPremium: 0,
            finalPremium: 0,
            anyClientDiscount: false // if there is (loyaltyDiscount || premiumFromActivePolicesDiscount || propertyInsuranceDiscount)
          };

          lsnNg.forEach(mainDataContainer.policies, function(policy) {
            var polPremium = self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, '_premiumForDisplay', true);
            if (!polPremium) {
              return true;
            }
            if (policy.product.dynamicValues.loyaltyDiscount) //lojalność
            {
              data.loyaltyDiscount = true;
              data.loyaltyDiscountAmount += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'loyaltyDiscountAmount');
              data.countedLoyaltyDiscount = policy.product.tariffAttributes.countedLoyaltyDiscount;
            }
            if (angular.isObject(policy.product.tariffAttributes.premiumFromActivePolicesDiscountDefinition)) {
              data.premiumFromActivePolicesDiscountDefinition = dcCommonHelper.convertDefinictionTableAttributeToArray(policy.product.tariffAttributes.premiumFromActivePolicesDiscountDefinition.tableAttribute);
            } else {
              data.premiumFromActivePolicesDiscountDefinition = {};
            }
            if (policy.product.dynamicValues.premiumFromActivePolicesDiscount || policy.product.dynamicValues.premiumFromActivePolicesDiscount === 0) //składka
            {
              data.premiumFromActivePolicesDiscount = policy.product.dynamicValues.premiumFromActivePolicesDiscount;
              data.premiumFromActivePolicesDiscountAmount += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'premiumFromActivePolicesDiscountAmount');
              data.countedPremiumFromActivePolicesDiscount = policy.product.tariffAttributes.countedPremiumFromActivePolicesDiscount; //znizka %
            }
            if (policy.product.dynamicValues.propertyInsuranceDiscount) //pozakomunikacyjne
            {
              data.propertyInsuranceDiscount = true;
              data.propertyInsuranceDiscountAmount += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'propertyInsuranceDiscountAmount');
              data.countedPropertyInsuranceDiscount = policy.product.tariffAttributes.countedPropertyInsuranceDiscount;
            }
            if (policy.product.tariffAttributes.loyaltyBonusDiscounttAmount) {
              data.loyaltyBonusDiscounttAmount += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'loyaltyBonusDiscounttAmount'); //zniżka kliencka suma (kwota)
            }
            if (policy.product.tariffAttributes.countedLoyaltyBonusDiscount) {
              data.countedLoyaltyBonusDiscount = policy.product.tariffAttributes.countedLoyaltyBonusDiscount; //zniżka kliencka suma (procent)
            }

            data.discountPromotion += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'premiumAfterLoyaltyBonus') - self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, '_premiumForDisplay', true)
              + self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'adjustmentPLN', false, policy.product.dynamicValues); //zniżki i promocje
            data.tariffPremium += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, 'tariffPremium');
            data.finalPremium += self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, '_premiumForDisplay', true); //składka należna

            return true; //continue
          });

          data.anyClientDiscount = data.loyaltyDiscount || data.premiumFromActivePolicesDiscount || data.propertyInsuranceDiscount;

          return data;
        };

        /**
         * Zwraca sumę składek należnych
         * @return {int}
         */
        this.getFinalPremiumSum = function() {
          var sum = 0;
          angular.forEach(mainDataContainer.policies, function(policy) {
            var premium = self._getPolicyAttrAmount(policy.product.dynamicValues, policy.product.tariffAttributes, '_premiumForDisplay', true);
            if (premium) {
              sum += premium;
            }
          });
          return sum;
        };

        /**
         * zwraca składki dla produktow matrycy
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {Object} [description]
         */
        this.getPremiums = function(container) {
          var premiums = {};
          if (!angular.isString(container)) {
            container = 'premiumList';
          }

          //lokalizacje
          angular.forEach(resourceHelper.productType, function(productType, productCode) {
            if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
              if (productCode === CONSTANTS.PRODUCT_OGI_KRA) { //ogi_kra obliczany jest z produktów ogi i kra
                return true;
              }
              if (mainDataContainer.selectedLocalization !== CONSTANTS.ALL_OBJECTS && mainDataContainer.selectedLocalization !== CONSTANTS.NO_OBJECT) {
                premiums[productCode] = self.getPremium(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, productCode, CONSTANTS.SUM_NAME, container);
              } else {
                //wszystkie lokalizacje, sumujemy składki
                angular.forEach(mainDataContainer.localizations, function(localization, id) {
                  var localizationPremiumSums = self.getPremium(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, id, productCode, CONSTANTS.SUM_NAME, container);
                  if (angular.isUndefined(premiums[productCode])) {
                    premiums[productCode] = {};
                  }
                  angular.forEach(localizationPremiumSums, function(premium, variantCode) {
                    if (angular.isUndefined(premiums[productCode][variantCode])) {
                      premiums[productCode][variantCode] = 0;
                    }
                    if (mainDataContainer.selectedVariants.localization[id][productCode] === variantCode) {
                      premiums[productCode][variantCode] += premium;
                    }
                  });
                });
              }
              if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && angular.isDefined(premiums[productCode]) && angular.isDefined(premiums[productCode][CONSTANTS.VARIANT_III])) {
                if (angular.isUndefined(premiums[CONSTANTS.PRODUCT_OGI_KRA])) {
                  premiums[CONSTANTS.PRODUCT_OGI_KRA] = {};
                  premiums[CONSTANTS.PRODUCT_OGI_KRA][CONSTANTS.VARIANT_III] = 0;
                }
                premiums[CONSTANTS.PRODUCT_OGI_KRA][CONSTANTS.VARIANT_III] += premiums[productCode][CONSTANTS.VARIANT_III];
              }
            }
            return true;
          });

          angular.forEach(mainDataContainer[container][CONSTANTS.PRODUCT_TYPE_PERSON], function(risks) {
            angular.forEach(risks, function(variants, riskCode) {
              if (angular.isUndefined(premiums[riskCode])) {
                premiums[riskCode] = {};
              }
              angular.forEach(variants, function(premium, variantCode) {
                if (angular.isUndefined(premiums[riskCode][variantCode])) {
                  premiums[riskCode][variantCode] = 0;
                }
                premiums[riskCode][variantCode] += premium;
              });
            });
          });

          //pojazdy
          angular.forEach(resourceHelper.productType, function(productType, productCode) {
            if (productType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
              if (mainDataContainer.selectedVehicle !== CONSTANTS.ALL_OBJECTS && mainDataContainer.selectedVehicle !== CONSTANTS.NO_OBJECT) {
                premiums[productCode] = self.getPremium(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle, productCode, undefined, container);
              } else {
                //wszystkie lokalizacje, sumujemy składki
                angular.forEach(mainDataContainer.vehicles, function(vehicle, id) {
                  var vehiclePremiumSums = self.getPremium(CONSTANTS.PRODUCT_TYPE_VEHICLE, id, productCode, undefined, container);
                  if (angular.isUndefined(premiums[productCode])) {
                    premiums[productCode] = {};
                  }
                  angular.forEach(vehiclePremiumSums, function(premium, variantCode) {
                    if (angular.isUndefined(premiums[productCode][variantCode])) {
                      premiums[productCode][variantCode] = 0;
                    }
                    if (mainDataContainer.selectedVariants.vehicle[id][productCode] === variantCode) {
                      premiums[productCode][variantCode] += premium;
                    }
                  });
                });
              }
            }
          });

          //osoby-grupy
          angular.forEach(resourceHelper.productType, function(productType, productCode) {
            if (productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
              if (mainDataContainer.selectedSubject !== CONSTANTS.ALL_OBJECTS && mainDataContainer.selectedSubject !== CONSTANTS.NO_OBJECT) {
                premiums[productCode] = self.getPremium(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, mainDataContainer.selectedSubject, productCode, undefined, container);
              } else {
                //wszystkie osoby-grupy, sumujemy składki
                angular.forEach(dcCommonHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(subject, id) {
                  var vehiclePremiumSums = self.getPremium(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, id, productCode, undefined, container);
                  if (angular.isUndefined(premiums[productCode])) {
                    premiums[productCode] = {};
                  }
                  angular.forEach(vehiclePremiumSums, function(premium, variantCode) {
                    if (angular.isUndefined(premiums[productCode][variantCode])) {
                      premiums[productCode][variantCode] = 0;
                    }
                    if (mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][id][productCode] === variantCode) {
                      premiums[productCode][variantCode] += premium;
                    }
                  });
                });
              }
            }
          });
          return premiums;
        };

        /**
         * wyrównuje sumy ubezpieczeń w ryzykach osobowych pod warunkiem, że podano tylko jedną sumę a inne są równe 0
         */
        this.alignPersonalRisksSumInsured = function() {
          var getOnlySu = function(obj) {
            var only = null;
            lsnNg.forEach(obj, function(val) {
              if (angular.isDefined(val) && val !== 0) {
                if (only === null) { //znaleziono jedyną SU
                  only = val;
                  return true;
                } else { //jest uzupełniona > niż jedna SU więc brak "jedynej" - przerywamy
                  only = null;
                  return false;
                }
              }

              return true; //continue
            });
            return only;
          };
          angular.forEach(mainDataContainer.suList[CONSTANTS.PRODUCT_TYPE_PERSON], function(products) {
            if (angular.isObject(products)) {
              angular.forEach(products, function(sums, productCode) {
                var prodDef = resourceHelper.getProdDef(productCode);
                var onlySu = getOnlySu(sums);

                if (onlySu !== null) {
                  angular.forEach(prodDef.VARIANTLIST, function(variantDef) {
                    if (angular.isDefined(sums[variantDef.CODE])) {
                      if (variantDef.SUMINSUREDLIST) {
                        var valueExistOnList = false;
                        angular.forEach(variantDef.SUMINSUREDLIST, function(sumInsured) {
                          if (sumInsured.VALUE === onlySu) {
                            valueExistOnList = true;
                          }
                        });

                        sums[variantDef.CODE] = valueExistOnList ? onlySu : variantDef.SUMINSUREDLIST[0].VALUE;
                      } else {
                        sums[variantDef.CODE] = onlySu;
                      }
                    }
                  });
                }
              });
            }
          });
        };

        /**
         * zwraca kwotę ze wskazanego atrybuu
         * Bierze pod uwagę aktualny tryb wniosku (np. dokupienia)
         * @param  {Object} dynVals dynamicValues polisy
         * @param  {Object} tariffAttrs atrybuty taryfowe polisy
         * @param  {String} name oryginalna nazwa atrybutu (niezalezna od trybu wniosku)
         * @param  {Boolean} [forDisplay=false] czy wersja atrybutu "...forDisplay"
         * @param  {Object} [forceAttrContainer] jesli ustawiony, to z tego kontenera sczytujemy atrybut, jeśli nie to dobor automatyczny wg ponizszego kodu
         * @return {Number}
         */
        this._getPolicyAttrAmount = function(dynVals, tariffAttrs, name, forDisplay, forceAttrContainer) {
          var supplementMode = appVariables.isSupplement, //czy tryb dokupień
            finalName = supplementMode ? '_extend' + (forDisplay ? name.substr(1) : name).ucFirst() : name, //ostateczna nazwa atrybutu. Jeśli tryb dokupień do odczytujemy wersję atrybutów z przedrostkiem _extend....
            tariffAttrsContainer = (supplementMode ? dynVals : tariffAttrs), //miejsce w którym szukamy atrybutów taryfowych
            value = 0;
          if (forceAttrContainer) {
            tariffAttrsContainer = forceAttrContainer;
          }
          if (name === '_premiumForDisplay') {
            if (angular.isDefined(dynVals[finalName])) {
              value = dynVals[finalName];
            }
          } else if (angular.isDefined(tariffAttrsContainer[finalName])) { // atrybuty taryfowe
            value = tariffAttrsContainer[finalName];
          }

          return value;
        };

        /**
         * czy ryzyko mieniowe(ogien/kradzież) dostępne przy aktualnym zaznaczeniu i sumach dla innych ryzyk
         * @param  {String}  productCode nazwa producktu const PRODUCT_OGI lub PRODUCT_KRA
         * @param  {String}  subRiskName nazwa subryzyka, np. const RISK_ESTATE, RISK_GARDEN itd.
         * @return {Boolean}
         */
        this.isLocalizationRiskAvailable = function(productCode, subRiskName) {
          var available = false,
            selectedLocalization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
          if (angular.isUndefined(selectedLocalization)) {
            return false;
          }
          var riskList = resourceHelper.getFireBurglaryRisksListAsArray(selectedLocalization.type, productCode);
          lsnNg.forEach(riskList, function(riskDef) {
            if (riskDef.code !== subRiskName) {
              return true;
            } else {
              if (angular.isArray(riskDef.dependsOn)) {
                var sum = 0;
                angular.forEach(riskDef.dependsOn, function(depRiskCode) {
                  var su = self.getSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, mainDataContainer.selectedLocalization, productCode, depRiskCode);
                  //wyciągamy SU z ryzyk od których zależy ryzyko key
                  if (angular.isDefined(su) && parseInt(su, 10) > 0) {
                    sum = sum + parseInt(su, 10);
                  }
                });
                if (sum > 0) {
                  available = true;
                }
              } else {
                available = true;
              }
              return false;
            }
          });
          return available;
        };

      };

      return new dcAmountHelper();
    }
  ]);