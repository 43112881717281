angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseRecordEditModalCtrl', ['$scope', 'iHestiaCommonModalHelper',
    function($scope, iHestiaCommonModalHelper) {

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.$emit('hideBlockUi');
      };

      /**
       * zapis modala
       */
      $scope.$on('iHestiaCommonModalOkBtnClicked.vehiclePurchaseRecordEditModal', function($event){
        $event.preventDefault();
        if($scope.verificationDataForm.$valid)
        {
          $scope.saveRecord();
          iHestiaCommonModalHelper.hideModal('vehiclePurchaseRecordEditModal');
        }
        else
        {
          $scope.setFormSubmitted(true);
        }

      });

      $scope.$on('iHestiaCommonModalCancelBtnClicked.vehiclePurchaseRecordEditModal', function(){
        if($scope.vehiclePurchaseRecordEditModal.newRecord)
        {
          $scope.newRecordCancel();
        }
        else
        {
          //to nie powinno się wydarzyć, nie trzymami kopi rekordu, więc edycję można tylko zapisać
        }
      });


      $scope.init();
    }
  ]);