angular.module('ihestiaRestServicesBase')
  .provider('ihestiaIcContextHelper', ['ihestiaIcContextConstantsProvider', 'ihestiaConfigHelperProvider',
    function(ihestiaIcContextConstantsProvider, ihestiaConfigHelperProvider) {
    var IcContextService = function() {
      var self = this;

      self.getActiveContext = function() {
        return ihestiaConfigHelperProvider.getConfigHelper().get('ic', 'activeContext');
      };

      self.isLifeActiveContext = function() {
        return self.getActiveContext() === ihestiaIcContextConstantsProvider.getConstants().IC_CONTEXT_LIFE;
      };

      /**
       * for client type users checking activeContext is not enough because client cannot
       * change context by themselves and in multicontext app will have random (from available) context
       * @return {Boolean} if user can perform propethy operation
       */
      self.isPropertyAvailable = function() {
        var configHelper = ihestiaConfigHelperProvider.getConfigHelper();
        if(configHelper.get('sso', 'userInfo').userType === 'Client') {
           // client has available properthy context
          return configHelper.get('client', 'acceptedTermsAndConditions').ehn;
        } else {
          return !self.isLifeActiveContext();
        }
      };

    };

    var helperInstance = new IcContextService();

    /**
     * Metoda dostępna w sekcji .config
     * @return {ConfigService} [description]
     */
    this.getInstance = function() {
      return helperInstance;
    };

    /**
     * return injectora
     * @return {ConfigService} [description]
     */
    this.$get = [
      function() {
        return helperInstance;
      }];
  }]);