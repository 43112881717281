angular.module('ihestiaRestServicesBase')
  .service('offersSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

		var Offers = function() {
			IHestiaRestServiceAbstractDef.apply(this, arguments);

			this.resource = 'customers';
			this.businessArea = 'insurance';
		};

		/**
		* Konstruktor v2
		*/
		var OffersV2 = function() {
			Offers.apply(this, arguments);
			this.apiVersion = 'v2';			
		};

		/**
		* Konstruktor v1
		*/
		var OffersV1 = function() {
			Offers.apply(this, arguments);			
		};

		return IHestiaRestServiceAbstractDef.setApiConstructors({
			v1: OffersV1,
			v2: OffersV2
		});
    }]); 