/**
 * Dyrektywa do wrzucenia szablonu navbara
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaMenuTopMenu')
  .directive('ihestiaMenuTopMenu', ['$templateCache',
    function($templateCache) {
      var directive = {
        restrict: 'E',
        replace: true,
        template: $templateCache.get('ihestia-menu-template-app/topMenu/topmenu.tpl.html'),
        link: lsnNg.noop
      };

      return directive;
    }
  ]);