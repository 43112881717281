angular.module('salesPath2')
  .controller('personEditModalCtrl', ['$scope', 'actionHelper', 'CONFIG', 'AddressModelConstants', 'ClausesModelConstants', 'PersonModel', 'personModalHelper', '$timeout', 'CONSTANTS', 'IdentityDocumentModel', 'personHelper', 'applicationDictionaryHelper', 'personServiceHelper', 'dictionaryServiceHelper', 'ContactModelConstants',
    function($scope, actionHelper, CONFIG, AddressModelConstants, ClausesModelConstants, PersonModel, personModalHelper, $timeout, CONSTANTS, IdentityDocumentModel, personHelper, applicationDictionaryHelper, personServiceHelper, dictionaryServiceHelper, ContactModelConstants) {

      $scope.modalData = personModalHelper.currentlyEditedPersonData;
      $scope.listeners = {};
      $scope.k5Visible = false;
      $scope.intermName = null;
      $scope.numericSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };
      //dane do autocompletera pkd
      $scope.pkdAutocompleterData = {
        savedPkds: null,
        helper: applicationDictionaryHelper,
        pkdDataArray: applicationDictionaryHelper.loadedPkd
      };
      $scope.numericPhoneSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: true
      };
      $scope.services = {
        firstNamesSvc: personServiceHelper.getFirstNameService(),
        lastNamesSvc: personServiceHelper.getLastNameService(),
        addressSvc: dictionaryServiceHelper.getDictionaryAddressService()
      };

      $scope.init = function() {
        $scope.clauseDisabled = {
          k28: CONFIG.BEHAVIOR.optionalClauses.indexOf('k28') === -1
        };

        $scope.setInitialData();
        $scope.fireListeners();
      };

      $scope.setInitialData = function() {
        $scope.nationalities = [];

        //domyślna wartość dla listy wyboru narodowości
        $scope.nationalities[0] = {
          code: 'BRAK',
          description: 'BRAK',
          name: 'BRAK'
        };

        angular.forEach(personHelper.nationalities, function(item) {
          $scope.nationalities.push(item);
        });
        $scope.identityDocumentTypes = personHelper.identityDocumentTypes;

        //konfiguracja klauzulu K5

        var appDynVals = $scope.dataContainer.application.get('dynamicValues');
        //czy widoczna klauzula K5
        if (angular.isDefined(appDynVals._patronClauseVisible) && appDynVals._patronClauseVisible) {
          $scope.k5Visible = true;
          //pobranie intermName
          if (angular.isArray($scope.dataContainer.application.distributions)) {
            $scope.intermName = $scope.dataContainer.application.distributions[0].intermName;
          }
        } else {
          $scope.k5Visible = false;
        }

        if ($scope.modalData.personData === null) {
          $scope.modalData.personData = $scope.modalData.person.getData(true);
          $scope.fillViewData();
        }
      };

      $scope.fireListeners = function() {
        $scope.listeners.eventOnBtnClicked = $scope.$on('iHestiaCommonModalOkBtnClicked.personEditModal', $scope.modalData.allowedChanges.allDisabled ? $scope.cancelFormCallback : $scope.applyFormCallback);

        /**
         * czy formularz poprawny
         * UWAGA - sprawdzanie tylko pod kątem errorów (warningi są pomijane)
         * @return {Boolean}
         */
        $scope.isFormValid = function() {
          var isValid = true;
          angular.forEach($scope.userEdit.$error, function(fields) {
            angular.forEach(fields, function(field) {
              if (personHelper.warningEditFields.indexOf(field.$name) === -1) {
                isValid = false;
              }
            });
          });
          return isValid;
        };

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.listeners.hideEvent = $scope.$on('iHestiaCommonModalHideEvent.personEditModal', function() {
          $scope.clearListeners(); // remove listener.
        });

        // modal został zamknięty, trzeba usunąć watche itd.
        $scope.listeners.cancelEvent = $scope.$on('iHestiaCommonModalCancelBtnClicked.personEditModal', $scope.cancelFormCallback);

        $scope.listeners.eventThirdBtnClicked = $scope.$on('iHestiaCommonModalThirdBtnClicked.personEditModal', $scope.personChangeCallback);
      };

      /**
       * callback zatwierdzenia formularza
       * @param  {[type]} $event [description]
       * @return {[type]}        [description]
       */
      $scope.applyFormCallback = function($event) {
        if ($scope.isFormValid()) {
          $scope.save();
          personModalHelper.clearEditModalData();
          $scope.clearListeners(); // remove listener.
        } else {
          $event.preventDefault();
        }
      };

      /**
       * callback zamknięcia/anuowania formularza
       * @return {[type]} [description]
       */
      $scope.cancelFormCallback = function() {
        $timeout(function() {
          actionHelper.runAction('cancelEditPerson', {
            personId: $scope.modalData.editedId,
            context: $scope.modalData.context
          });
          personModalHelper.clearEditModalData();
        }, 0);
        $scope.clearListeners(); // remove listener.
      };

      /**
       * callback zamiany osoby
       * @return {[type]} [description]
       */
      $scope.personChangeCallback = function() {
        $scope.showPersonChangePopup(personModalHelper.currentlyEditedPersonData.editedId, personModalHelper.currentlyEditedPersonData.personChangeRole);
        personModalHelper.clearEditModalData();
        $scope.clearListeners(); // remove listener.
      };

      /**
       * pokazuje modla zmiany osoby
       * @param {String} clientId clientId osoby podmienianej
       * @param {String} personRole rola/kontekst osoby (CONSTANTS.PERSON_ROLE...)
       * @return {[type]} [description]
       */
      $scope.showPersonChangePopup = function(clientId, personRole) {
        $timeout(function() {
          personModalHelper.openPopupAddPerson({
            excludeIds: [clientId],
            context: CONSTANTS.PERSON_CONTEXT_CLIENT,
            personChange: true,
            sourcePersonId: clientId,
            personChangeRole: personRole
          });
        }, 0);
      };

      $scope.save = function() {
        $scope.fillPersonModel();
        personHelper.save($scope.modalData.personData, $scope.modalData.context, $scope.modalData.editedId, (($scope.modalData.personChange && angular.isString($scope.modalData.sourcePersonId)) ? $scope.modalData.sourcePersonId : null), $scope.modalData.personChangeRole);
      };

      $scope.fillPersonModel = function() {

        if ($scope.modalData.showElements.foreigner) {
          $scope.modalData.personData.documents = [];
          $scope.modalData.personData.documents[0] = $scope.modalData.foreignerDocument;
        } else {
          $scope.modalData.personData.documents = [];
          $scope.modalData.personData.citizenshipCode = 'PL';
        }

        var contacts = [];
        angular.forEach($scope.modalData.contacts, function(val, code) {
          if (val) {
            contacts.push({
              code: code,
              countryPrefix: [ContactModelConstants.CONTACT_TYPE_DOMO, ContactModelConstants.CONTACT_TYPE_KOMO, ContactModelConstants.CONTACT_TYPE_SLOZ].indexOf(code) !== -1 ? $scope.modalData.countryPrefix : null,
              value: val
            });
          }
        });

        $scope.modalData.personData.contacts = contacts;

        if ($scope.modalData.showElements.clauses) {

          if ($scope.modalData.personData.clauses === null) {
            $scope.modalData.personData.clauses = {};
          }

          //zgoda marketingowa (K4)
          if ($scope.modalData.clauses.marketing) {
            $scope.modalData.personData.clauses.mark = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          } else {
            $scope.modalData.personData.clauses.mark = ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
          }

          //informacje handlowe drogą elektroniczną (K2)
          if ($scope.modalData.clauses.email) {
            $scope.modalData.personData.clauses.elec = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          } else {
            $scope.modalData.personData.clauses.elec = ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
          }

          //informacje obsługowe drogą elektroniczną (K3)
          if ($scope.modalData.clauses.k3) {
            $scope.modalData.personData.clauses.k3 = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          } else {
            $scope.modalData.personData.clauses.k3 = ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
          }

          if(!$scope.clauseDisabled.k28){
            if ($scope.modalData.clauses.k28) {
              $scope.modalData.personData.clauses.k28 = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
            } else {
              $scope.modalData.personData.clauses.k28 = ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
            }
          }

          //pełnomocnictwo dla agenta (k5)
          if ($scope.modalData.clauses.k5) {
            $scope.modalData.personData.clauses.k5 = ClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
          } else {
            $scope.modalData.personData.clauses.k5 = ClausesModelConstants.CLAUSE_VALUE_REFUSAL;
          }
        }

        //czyścimy adres korespondencyjny
        if ($scope.modalData.hideElements.allAddresses) {
          $scope.modalData.personData.addresses = [];
        }else{
          if ($scope.modalData.hideElements.secondAddr) {
            $scope.modalData.personData.addresses.splice(1, 1);
          } else {
            $scope.modalData.personData.addresses[1].code = AddressModelConstants.ADDRESS_TYPE_KORE;
          }
        }
      };

      $scope.fillViewData = function() {
        //ustawiamy domyślnie +48, jeżeli w danych jest inaczej to wartośc zostanie nadpisana lub ustawiona na pustą.
        $scope.modalData.countryPrefix = '+48';
        angular.forEach($scope.modalData.personData.contacts, function(elem) {
          $scope.modalData.contacts[elem.code] = elem.value;
          if (elem.code === 'komo') {
            $scope.modalData.countryPrefix = elem.countryPrefix;
          }
        });
        $scope.modalData.foreignerDocument = {};
        if ($scope.modalData.personData.citizenshipCode !== 'PL') {
          $scope.modalData.showElements.foreigner = true;
          if (angular.isDefined($scope.modalData.personData.documents[0])) {
            $scope.modalData.foreignerDocument = $scope.modalData.personData.documents[0];
          }
        } else {
          $scope.modalData.foreignerDocument = (new IdentityDocumentModel()).getData();
          $scope.modalData.foreignerDocument.code = '';
        }
        if ($scope.modalData.personData.addresses && 
            $scope.modalData.personData.addresses[1] && 
            $scope.modalData.personData.addresses[1].code === AddressModelConstants.ADDRESS_TYPE_KORE) {
          $scope.modalData.hideElements.secondAddr = false;
        }
        if (angular.isUndefined($scope.modalData.personData.clauses) || $scope.modalData.personData.clauses === null) {
          $scope.modalData.personData.clauses = {};
        }
        if ($scope.modalData.personData.clauses.mark === ClausesModelConstants.CLAUSE_VALUE_AGREEMENT) {
          $scope.modalData.clauses.marketing = true;
        }
        if ($scope.modalData.personData.clauses.elec === ClausesModelConstants.CLAUSE_VALUE_AGREEMENT) {
          $scope.modalData.clauses.email = true;
        }
        if ($scope.modalData.personData.clauses.k3 === ClausesModelConstants.CLAUSE_VALUE_AGREEMENT) {
          $scope.modalData.clauses.k3 = true;
        }
        if ($scope.modalData.personData.clauses.k5 === ClausesModelConstants.CLAUSE_VALUE_AGREEMENT) {
          $scope.modalData.clauses.k5 = true;
        }        
        if ($scope.modalData.personData.clauses.k28 === ClausesModelConstants.CLAUSE_VALUE_AGREEMENT) {
          $scope.modalData.clauses.k28 = true;
        }

        if (($scope.modalData.editedId !== null && parseInt($scope.modalData.editedId, 10) === parseInt($scope.dataContainer.insurerId, 10)) || $scope.modalData.personChangeRole === CONSTANTS.PERSON_ROLE_INSURER) {
          $scope.modalData.showElements.PKD = true;
          $scope.modalData.showElements.Regon = true;
          $scope.modalData.showElements.Nip = true;
        }
        
        $scope.modalData.hideElements.allAddresses = personHelper.isAddressesUnavailable($scope.modalData.editedId, $scope.modalData.personChangeRole);
        
        if (!CONFIG.BEHAVIOR.addClientPKD) {
          $scope.modalData.showElements.PKD = false;
        } else {
          if (!angular.isArray($scope.modalData.personData.pkd)) {
            $scope.modalData.personData.pkd = [];
          }
          $scope.pkdAutocompleterData.savedPkds = $scope.modalData.personData.pkd;
        }
        if (CONFIG.BEHAVIOR.mainInsuredAdditionalFields.indexOf('NIP') === -1) {
          $scope.modalData.showElements.Nip = false;
        }
        if (CONFIG.BEHAVIOR.mainInsuredAdditionalFields.indexOf('REGON') === -1) {
          $scope.modalData.showElements.Regon = false;
        }


        if ($scope.modalData.personChangeRole !== CONSTANTS.PERSON_ROLE_INSURER && $scope.modalData.personData.metaData.clientId !== $scope.dataContainer.insurerId) {
          $scope.modalData.showElements.clauses = false;
        }

        if($scope.modalData.hideElements.allAddresses) {
          $scope.modalData.personData.addresses = [];
        }else{
          if (angular.isUndefined($scope.modalData.personData.addresses[0])) {
            $scope.modalData.personData.addresses[0] = {
              city: null,
              postalCode: null,
              streetPrefix: null,
              street: null
            };
          }
          if (angular.isUndefined($scope.modalData.personData.addresses[1])) {
            $scope.modalData.personData.addresses[1] = {
              city: null,
              postalCode: null,
              streetPrefix: null,
              street: null
            };
          }
        }
        //jeżeli prefix telefonu jest pusty to ustawiamy domyślnie +48;
      };

      $scope.onPeselChange = function() {
        if (!!$scope.modalData.personData.pesel && $scope.modalData.personData.pesel.isValidPESEL()) {
          $scope.modalData.personData.birthDate = $scope.modalData.personData.pesel.peselToBirthdate();
          $scope.modalData.personData.gender = $scope.modalData.personData.pesel.peselToSex();
        }
      };

      $scope.clearListeners = function() {
        angular.forEach($scope.listeners, function(val) {
          val();
        });
        $scope.listeners = [];
      };

      /**
       * przeladowuje text błędu
       * @param {boolean} formError - czy wystapil blad na formularzy
       * @param {string} formErrorText - text który ma się pokazać na błąd formularza
       * @param {array}  errorTree - drzewo zagnieżdzenia do błędu z usługi
       */
      $scope.changeErrorText = function(formError, formErrorText, errorTree) {
        var error; // inicjalizacja poniżej w if-ie
        //modalData.errors.person.pesel.commonMessage
        if (!$scope.modalData.errors[errorTree[0]]) {
          $scope.modalData.errors[errorTree[0]] = {};
        }
        if (!$scope.modalData.errors[errorTree[0]][errorTree[1]]) {
          $scope.modalData.errors[errorTree[0]][errorTree[1]] = {};
        }

        if (errorTree.length > 2) {
          if (!$scope.modalData.errors[errorTree[2]]) {
            $scope.modalData.errors[errorTree[0]][errorTree[1]][errorTree[2]] = {};
          }
          error = $scope.modalData.errors[errorTree[0]][errorTree[1]][errorTree[2]];
        } else {
          error = $scope.modalData.errors[errorTree[0]][errorTree[1]];
        }

        if (!!error.message && !!formError) {
          error.commonMessage = formErrorText + ' ' + error.message;
        } else if (formError) {
          error.commonMessage = formErrorText;
        } else {
          delete error.commonMessage;
        }
      };

      $scope.foreignerStateChanged = function() {
        if ($scope.modalData.showElements.foreigner && $scope.modalData.personData.citizenshipCode === 'PL' && angular.isDefined($scope.nationalities[0])) {
          //szukamy pierwszego z brzegu kodu, byle nie polskiego
          $scope.modalData.personData.citizenshipCode = $scope.nationalities[0].code;
        }
      };

      $scope.init();
    }

  ]);