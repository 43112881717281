angular.module('salesPath2')
.service('clientAddHelper', [
  function(){
    var ClientSearchHelper = function() {
      /**
       * dane dla szablonu i kontrolera
       * @type {Object}
       */
      this.tplData = {
        personData: null //dane osoby - dla formularza, jeśli null to kontroler zasili inicjalnie danymi pustej osoby
      };

    };
    return new ClientSearchHelper();
}]);