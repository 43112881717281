/**
 * Mapper wniosku z RESTow
 */
angular.module('salesPath2').service('fromRestApplicationMapper', ['MapperUtils', 'fromRestRiskMapper', 'fromRestEstateMapper', 'fromRestVehicleMapper', 'fromRestSubjectMapper', 'fromRestBonusMalusMapper', 'fromRestMessageMapper', 'fromRestVariantMapper', 'fromRestPolicyMapper', 'mainDataContainer', 'CONSTANTS', 'TempDataContainerModel', 'CONFIG', 'RESOURCES', 'fromRestCompatibilityMapper', 'resourceHelper', 'supplementHelper', 'sp2CommonHelper', 'fromRestAttributeMapper', 'lsnUtils', 'dataContainerHelper', 'dcAllowedHelper', 'appVariables', 'ApplicationModelConstants', 'SPD',
  function(MapperUtils, fromRestRiskMapper, fromRestEstateMapper, fromRestVehicleMapper, fromRestSubjectMapper, fromRestBonusMalusMapper, fromRestMessageMapper, fromRestVariantMapper, fromRestPolicyMapper, mainDataContainer, CONSTANTS, TempDataContainerModel, CONFIG, RESOURCES, fromRestCompatibilityMapper, resourceHelper, supplementHelper, sp2CommonHelper, fromRestAttributeMapper, lsnUtils, dataContainerHelper, dcAllowedHelper, appVariables, ApplicationModelConstants, SPD) {
    var FromRestApplicationMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.defaultOptions = { //opcje mapowania
        dataContainerProperties: false, //czy mapować wlasnoci dataContainera - np przy odczycie wniosku
        initialLoad: false, //pierwsze wczytanie wniosku/polisy
        policyMode: false, //wczytywanie polisy
        initSupplementData: false //czy zainicjalizować dane do dokupień
      };
      this.options = angular.copy(this.defaultOptions);
      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };
      /**
       * mapuje wniosek ubezpieczeniowy
       * @param {Application} application wniosek
       * @param {object} mapOptions opcje mapowania
       * @return {TempDataContainerModel} tymczasowy dataContainer
       */
      this.mapApplication = function(application, mapOptions) {
        self._setOptions(mapOptions);
        var tmpDc,
          riskMapperOptions = angular.copy(self.options);
        riskMapperOptions.initSupplementData = false; //w pierwszym mapowaniu ryzyk nie uwzględniamy trybu dokupień
        if (mapOptions.policyMode || mapOptions.renewalMode) {
          tmpDc = mainDataContainer;
          tmpDc._subjectIdMap = {}; //mapping id do clientId
          tmpDc._estateIdMap = {}; //mapping id do clientId
          tmpDc._vehicleIdMap = {}; //mapping id do clientId
        } else {
          tmpDc = new TempDataContainerModel();
        }
        self._setDefaultOperationProtectionDates(application, tmpDc);
        self._mapDynamicValues(application, tmpDc);
        fromRestEstateMapper.mapEstates(application, tmpDc, self.options);
        fromRestVehicleMapper.mapVehicles(application, tmpDc, self.options);
        fromRestSubjectMapper.mapSubjects(application, tmpDc, self.options);
        if (self.options.policyMode || self.options.renewalMode) {
          self._prepareMatrix(application);
        }
        fromRestRiskMapper.mapRisks(application, tmpDc, riskMapperOptions);

        if (self.options.policyMode && CONFIG.BEHAVIOR.personGroups === true) {
          self.cleanSelectedVariants(mainDataContainer.selectedVariants.personGroup);
        }

        if (application.get('variants').length > 0) { //mapujemy warianty ryzyk dla smulacji
          fromRestVariantMapper.mapVariants(application, tmpDc);
        }
        fromRestEstateMapper.mapFinalData(tmpDc);
        self._mapDataContainerProperties(application, tmpDc);
        fromRestBonusMalusMapper.mapBonusMalusData(application, tmpDc, self.options);
        fromRestMessageMapper.mapMessages(application.messages);
        fromRestPolicyMapper.mapSimplePoliciesToPolicies(application, tmpDc);
        if (CONFIG.BEHAVIOR.globalProtectionPeriod !== null && (mapOptions.policyMode || mapOptions.renewalMode) && tmpDc.policies.length !== 0) {
          //odczyt atrybutow dla datacontainera
          var dynVals = tmpDc.policies[0].product.get('dynamicValues');
          if (CONFIG.BEHAVIOR.globalProtectionPeriod === 'travel' && angular.isDefined(dynVals.typeOfAgreement)) {
            angular.forEach(RESOURCES.PROTECTION_PERIOD_OPTIONS, function(item) {
              if (item.BOS_VALUE === dynVals.typeOfAgreement) {
                tmpDc.protectionPeriodCode = item.CODE;
              }
            });
          } else if (CONFIG.BEHAVIOR.globalProtectionPeriod === 'days' && angular.isDefined(dynVals.insurancePeriod)) {
            angular.forEach(RESOURCES.PROTECTION_PERIOD_OPTIONS, function(item) {
              if (item.BOS_VALUE === dynVals.insurancePeriod) {
                tmpDc.protectionPeriodCode = item.CODE;
              }
            });
          }
        }
        self.initSupplementData(application, tmpDc);
        self.clearGreenCard(application, tmpDc);
        return tmpDc;
      };

      /**
       * https://ebok.atena.pl/browse/IHESTIAOR-90 special case for green card
       * @return {undefined}
       */
      this.clearGreenCard = function(application, dataContainer) {
        var greenCardIdpm = null;
        var objFound = lsnUtils.findObjInArray(SPD.risks, {
            productCode: CONSTANTS.ADD_GREENCARD
          });

        if(objFound && objFound.idpm) {
          greenCardIdpm = objFound.idpm;
        }

        if(greenCardIdpm) {
        var gcFound = lsnUtils.findObjInArray(application.risks, {
            product: {
              compId: greenCardIdpm
            }
          });
          if (gcFound !== null && angular.isObject(gcFound.product.dynamicValues) && !gcFound.product.dynamicValues.greenCardNumber) {
              dataContainer.greenCardNumber = '';
          }
        }
      };

      /**
       * czysci wybrane warianty przy wczytaniu wniosku
       * @param {object} varList obiekt z wybranymi ryzykami
       * @return {undefined}
       */
      this.cleanSelectedVariants = function(varList) {
        var anySelected = false;
        lsnNg.forEach(varList, function(prodSelection, personClientId) {
          if (personClientId === CONSTANTS.NO_OBJECT) {
            return true; // kontynuuj
          }
          anySelected = false;
          lsnNg.forEach(prodSelection, function(selected) {
            if (selected) {
              anySelected = true;
              return false;
            }
            return true;
          });
          if (!anySelected) {
            delete varList[personClientId];
          }
          return true;
        });
      };

      this._prepareMatrix = function(application) {
        //przygotowanie struktury dla obiektów (jesli juz istnieje to zachowujemy, jesli nie to tworzymy czyste obiekty)
        mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] || {};
        mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE] = mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE] || {};
        mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] || {};
        if (!CONFIG.BEHAVIOR.personGroups) { //przypadek matryca glowna NIE per kazda osoba i grupa osob
          angular.extend(mainDataContainer.selectedVariants, resourceHelper.getDefaultVariantsForType(CONSTANTS.PRODUCT_TYPE_PERSON));
        }

        var anyLocalization = false;
        angular.forEach(mainDataContainer.localizations, function(localization) {
          dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, localization.metaData.get('clientId'));
          if (CONFIG.BEHAVIOR.isSingleProduct && mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][localization.metaData.get('clientId')][CONSTANTS.PRODUCT_OGI] === null) {
            var availableVariant = resourceHelper.getFirstVariantForProduct(CONSTANTS.PRODUCT_OGI);
            mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][localization.metaData.get('clientId')][CONSTANTS.PRODUCT_OGI] = availableVariant.CODE;
          }
          anyLocalization = true;
        });
        if (!anyLocalization) {
          dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, 0);
        }
        var anyVehicle = false;
        angular.forEach(mainDataContainer.vehicles, function(vehicle) {
          dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicle.metaData.get('clientId'));
          anyVehicle = true;
        });
        if (!anyVehicle) {
          dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, 0);
        }
        if (CONFIG.BEHAVIOR.personGroups) { //obsluga polis typu personGroup
          var anySubject = false,
            mainInduredAnyRisk = self._hasMainInsuredAnyRisk(application),
            subjects = angular.extend({}, mainDataContainer.persons, mainDataContainer.groups);

          lsnNg.forEach(subjects, function(sub) {
            if ((application.insuredRef !== application.holderRef && application.get('holderRef') === sub.metaData.get('id')) || //jesli ub-ny !== ub-cy, to na pewno ub-cy nie jest nigdzie ubezpieczony
              (application.get('insuredRef') === sub.metaData.get('id') && !mainInduredAnyRisk)) { //jesli glowny ub-ny nie jest ubezpieczony na zadnym ryzyku, to go pomijamy
              return true;
            }
            dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, sub.metaData.get('clientId'));
            anySubject = true;
            return true; //continue
          });
          if (!anySubject) {
            dataContainerHelper.setDefaultVariantsForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, 0);
          }
        }
      };

      /**
       * czy glowny ubezpieczony z wniosku figuruje na jakims ryzyku
       * @param  {ApplicationModel}  application obiekt wniosku
       * @return {Boolean} true gdy figuruje
       */
      this._hasMainInsuredAnyRisk = function(application) {
        var mainInsured = application.get('insuredRef'),
          anyRisk = false;
        if (angular.isArray(application.risks)) {
          lsnNg.forEach(application.risks, function(risk) {
            if (risk.get('insuredSubjectsRef').length > 0) {
              lsnNg.forEach(risk.insuredSubjectsRef, function(subjRef) {
                if (mainInsured === subjRef.get('ref')) {
                  anyRisk = true;
                  return false;
                }
                return true; //continue
              });
            }
            if (anyRisk) {
              return false;
            }

            return true; //continue
          });
        }
        return anyRisk;
      };
      /**
       * mapuje dynamczne atrybuty wniosku na dane dataContainera
       * @param {Application} application wniosek
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapDynamicValues = function(application, tmpDc) {
        //kontenery zawierające idenryfikatory osób, pojazdów itd.
        var idContainers = ['selectedAdditions', 'selectedExtensions'];
        var dynVals = application.get('dynamicValues');
        var applStatus = application.get('status');
        if(dynVals._productClausesRemoved) {
          localStorage.setItem('removeProductClauses', '_productClausesRemoved');
        }
        if(dynVals._lastYearProductClausesRemoved) {
          localStorage.setItem('removeProductClauses', '_lastYearProductClausesRemoved');
        }
        if(dynVals.destinations) {
          dataContainerHelper.setDestinations(dynVals.destinations);
        }
        if (!self.options.dataContainerProperties || dynVals === null || angular.equals(dynVals, {})) {
          return;
        }
        angular.forEach(self.utils.applicationDynamicValuesMap, function(dynValName, dmPropName) {
          if (angular.isDefined(tmpDc[dmPropName]) && angular.isDefined(dynVals[dynValName])) {
            if (dmPropName === 'protectionStartTime' && applStatus === ApplicationModelConstants.APPLICATION_STATUS_CALCULATION) { //jeśli mapowanie wniosku-kalkulacji to zawsze ustawiamy aktualną godzinę
              tmpDc[dmPropName] = dataContainerHelper.getTodayProtectionStartTime();
            } else {
              tmpDc[dmPropName] = self.utils.convertToDataContainerProperty(dmPropName, self.utils.unserializeValue(dynVals[dynValName]));
            }
            if (self.options.initialLoad && idContainers.indexOf(dmPropName) !== -1) {
              fromRestCompatibilityMapper.mapIdContainer(tmpDc[dmPropName]);
              if (dmPropName === 'selectedAdditions') {
                fromRestCompatibilityMapper.removeUnsupportedAdditions(tmpDc[dmPropName]);
              }
            }
          }
        });
        self.processPzaData(application, tmpDc);
      };
      /**
       * mapuje dane wniosku na dane dataContainera (pozostałe)
       * UWAGA
       * W tmpDc powinny już być zmapowane podmioty, obiekty i ryzyka
       * @param {Application} application wniosek
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapDataContainerProperties = function(application, tmpDc) {
        if (!self.options.dataContainerProperties) {
          return;
        }
        tmpDc.insurerId = tmpDc._subjectIdMap[application.get('holderRef')];
        tmpDc.mainInsuredId = tmpDc._subjectIdMap[application.get('insuredRef')];
        tmpDc.application = application;
        if (CONFIG.BEHAVIOR.personGroups) { //jesli obsluga produktow typu personGroup
          var container = {}; //wszystkie osoby i grupy
          if (angular.isArray(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP])) {
            angular.forEach(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP], function(name) {
              angular.extend(container, tmpDc[name]);
            });
          } else {
            container = tmpDc[CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP]];
          }
          //określanie zaznaczonego podmiotu
          lsnNg.forEach(container, function(subj, clientId) {
            if (angular.isDefined(tmpDc.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][clientId])) {
              tmpDc.selectedSubject = clientId;
              return false;
            }
            return true; //continue
          });
        }
        //mapowanie typu okresu ubezpieczenia
        if (self.options.policyMode && CONFIG.BEHAVIOR.globalProtectionPeriod !== null && tmpDc.policies.length !== 0) {
          var polDynVals = tmpDc.policies[0].product.get('dynamicValues');
          if (angular.isDefined(polDynVals.typeOfAgreement)) {
            angular.forEach(RESOURCES.PROTECTION_PERIOD_OPTIONS, function(item) {
              if (item.BOS_VALUE === polDynVals.typeOfAgreement) {
                tmpDc.protectionPeriodCode = item.CODE;
              }
            });
          }
        }
      };

      /**
       * przetwarza dane dotyczące PZA
       * @param {Application} application wniosek
       * @param {TempDataContainerModel} tmpDc
       * @return {undefined}
       */
      this.processPzaData = function(application, tmpDc) {
        if (!self.options.initialLoad || !angular.isString(tmpDc.individualDiscountInputValue) || tmpDc.individualDiscountInputValue === '') {
          return;
        }
        if (application.individualDiscount === null || application.individualDiscount === '' || application.individualDiscount === '%' || application.individualDiscount === '0') {
          // jeśli np. przy powrocie do edycji z oferty usługa usunie individualDiscount, to czyścimy masz wewnetrzny atrybut
          tmpDc.individualDiscountInputValue = '';
        }
      };

      //#pragma mark policies
      /**
       * mapuje i zwraca ryzyka SimpleRisk dla ekranu podsumowania
       * @param {Policy} policy REST polisa
       * @param {DataContainer} dataContainer
       * @param {object} options opcje mapowania
       * @return {XPegaz.Mapper.FromRest.SimpleRisk[]} ryzyka do paragonów
       */
      this.getSimpleRisks = function(policy, dataContainer, options) {
        return fromRestRiskMapper.mapToSimpleRisks(policy, dataContainer, options);
      };

      /**
       * ustawia domyślne daty ochrony w tmpDc na podstawie danych z wniosku
       * Tryb operacji na wniosku
       * @param {ApplicationModel} appl
       * @param {TempDataContainerModel} tmpDc
       */
      this._setDefaultOperationProtectionDates = function(appl, tmpDc) {
        if (!(self.options.policyMode && self.options.initSupplementData)) { //poki co tylko dla pierwszego wczytania trybu dokupień
          return false;
        }
        var endDate = (new XDate(appl.policies[0].get('end'))).toString('yyyy-MM-dd'),
          startDate = (new XDate(appl.operationData.get('changeDate'))).toString('yyyy-MM-dd');

        tmpDc.propertyProtectionDates.start = startDate;
        tmpDc.propertyProtectionDates.end = endDate;
        tmpDc.communicationProtectionDates.start = startDate;
        tmpDc.communicationProtectionDates.end = endDate;
        if (CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1) {
          angular.forEach(tmpDc.oczpProtectionDates, function(dates) {
            dates.start = startDate;
            dates.end = endDate;
          });
        } else {
          tmpDc.oczpProtectionDates.start = startDate;
          tmpDc.oczpProtectionDates.end = endDate;
        }
        if (CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_NNW) !== -1) {
          angular.forEach(tmpDc.nnwProtectionDates, function(dates) {
            dates.start = startDate;
            dates.end = endDate;
          });
        } else {
          tmpDc.nnwProtectionDates.start = startDate;
          tmpDc.nnwProtectionDates.end = endDate;
        }

        if (appVariables.isSupplement) {
          tmpDc.defaultStartDate = new XDate(startDate);
          tmpDc.defaultEndDate = new XDate(endDate);
        }

        return true;
      };

      /**
       * inicjalizacja danych do dokupienia
       * @param  {ApplicationModel} application [description]
       * @param  {TempDataContainerModel} tmpDc       [description]
       * @return {Boolean} true gdy zainicjalizowano
       */
      this.initSupplementData = function(application, tmpDc) {
        if (!self.options.initSupplementData) {
          return false;
        }
        //uzupełniamy tmpDc.allowedChanges
        if (angular.isObject(application.operationData.allowedChanges)) {
          //dodajemy clientId do obiektów określających dozwolone atrybuty, parsujemy dozwolone wartości atrybutów na podstawie SPD
          var allowedChanges = application.operationData.allowedChanges.getData(false, true);
          angular.forEach(allowedChanges, function(subGroups, group) {
            if (subGroups === null) {
              return true;
            }
            if (['salesProduct', 'risks'].indexOf(group) === -1 && angular.isObject(subGroups)) { //obiekty i podmioty
              angular.forEach(subGroups, function(objs, subGroup) {
                if (angular.isArray(objs)) {
                  angular.forEach(objs, function(obj) {
                    if (obj.metaData && obj.metaData.id) { //dodajemy clientId
                      switch (subGroup) {
                        case 'persons':
                        case 'organizations':
                          obj.metaData.clientId = tmpDc._subjectIdMap[obj.metaData.id];
                          break;
                        case 'vehicles':
                          obj.metaData.clientId = tmpDc._vehicleIdMap[obj.metaData.id];
                          break;
                        case 'estates':
                          obj.metaData.clientId = tmpDc._estateIdMap[obj.metaData.id];
                          break;
                        default:
                          sp2CommonHelper.throwException('Unsupported allowedChanges object: ' + subGroup);
                      }
                      //rzutujemy dozwolone atrybuty
                      self._processAllowedObject(obj, subGroup, tmpDc);
                    }
                  });
                }
              });
            } else if (group === 'risks') { //ryzyka
              angular.forEach(subGroups, function(objs, subGroup) {
                if (angular.isArray(objs)) {
                  angular.forEach(objs, function(obj) {
                    if (obj.metaData && obj.metaData.id) { //dodajemy odniesienie do konkretnego ryzyka
                      //rzutujemy dozwolone atrybuty
                      self._processAllowedObject(obj, subGroup, tmpDc);
                    } else if (subGroup === 'newRisks' && obj.risksName.length > 0) { //dodaejmy do tmpDc.allowedRisksVariants informacje o dozwolonych nowych ryzykach (brak tu wskazania na konkretny obiekt ubezpieczenia czy atrybuty ryzyka)
                      tmpDc.allowedRisksVariants = tmpDc.allowedRisksVariants || [];
                      //rzutujemy dozwolone atrybuty
                      self._processAllowedObject(obj, subGroup, tmpDc);
                      self._setAllowedNewRisks(tmpDc.allowedRisksVariants, obj, tmpDc);
                    }
                  });
                }
              });
            } else if (group === 'salesProduct') {
              self._processAllowedObject(subGroups, 'salesProduct', tmpDc); //w przypadku polisy, nie ma podgrupy, dlatego parsowanymm obiektem jest tu subGroups a jako nazwę podgtupy dajemy salesProduct - aby rozpoznac to w wołanej metodzie
            }
            return true;
          });
          dcAllowedHelper.rebuildAllowedChanges(allowedChanges);
          tmpDc.allowedChanges = allowedChanges;
        }

        //uzupełniamy dane dotyczące ryzyk dokupywanych
        angular.forEach(tmpDc.policies, function(policy) {
          angular.forEach(supplementHelper.getPreviousPolicyRisks(policy.risks), function(risk) {
            tmpDc.previousRisks.push(risk);
          });
        });
        var prevRisksAppl = angular.copy(application),
          prevRisksOptions = angular.copy(self.options);
        //jako ryzyka do zmapowania ustawiamy ryzyka z poprzedniej polisy i uruchamiamy mapowanie ryzyk w trybie inicjalizacji dokupień
        prevRisksAppl.risks = tmpDc.previousRisks;
        prevRisksOptions.policyMode = false;
        prevRisksOptions.renewalMode = false;
        fromRestRiskMapper.mapRisks(prevRisksAppl, tmpDc, prevRisksOptions);
        //utworzenie węzła allowedProducts na podstawie informacji w allowedChanges
        angular.forEach(tmpDc.allowedProducts, function(val, code) {
          tmpDc.allowedProducts[code] = false; //domyslnie niedostepny produkt
        });
        angular.forEach(tmpDc.allowedRisksVariants, function(data) {
          if (angular.isDefined(tmpDc.allowedProducts[data.product])) {
            tmpDc.allowedProducts[data.product] = true; //jeśli produkt pojawił się w allowedRisksVariants, to jest dostępny
          }
        });
        return true;
      };

      /**
       * ustawia dostepne warianty dla nowych ryzyk
       * @param {Object} container       obiekt przechowujący informacje o dostępności waraintów ryzyk
       * @param {Object} allowedRiskData dump z ApplicationAllowedChangesObjectModel
       */
      this._setAllowedNewRisks = function(container, allowedRiskData, tmpDc) {
        angular.forEach(allowedRiskData.risksName, function(riskCode) {
          var spdDef = self.utils.findRiskDefByIdpm(riskCode),
            allowed = [], //dozwolone warianty dla tego ryzyka. Najpierw sczytujemy z definicji spd, a jesli tam nie ma, tzn ze trzeba pobrac warianty z definicji z RESOURCES
            variantAttrName = null, //atrybut przechowujacy info o wairancie
            getVariantsFromDef = true; //czy pobrac dozwolone warianty z definicji lub RESOURCES

          if (spdDef.attributes.coverageOption) {
            variantAttrName = 'coverageOption';
          } else if (spdDef.attributes.assistanceOption) {
            variantAttrName = 'assistanceOption';
          }

          if (variantAttrName !== null && angular.isArray(allowedRiskData.attributes)) { //jeśli ryzyko wariantowe (wg SPD)
            lsnNg.forEach(allowedRiskData.attributes, function(attr) {
              if (attr.name === variantAttrName) {
                allowed = attr.allowedValues;
                getVariantsFromDef = false;
                return false;
              }
              return true;
            });
            if (variantAttrName === 'assistanceOption') { //przupadek starego e7 - HCA
              angular.forEach(allowed, function(val, idx) {
                allowed[idx] = self.utils.getHCAVariantFromAssistanceOption(val);
              });
            }
          }

          //powodz nie ma wariantow
          if (spdDef.riskCode === CONSTANTS.RISK_FLOOD) {
            getVariantsFromDef = false;
          }

          if (getVariantsFromDef) {
            if (angular.isArray(spdDef.variant)) {
              allowed = spdDef.variant;
            } else {
              var isAdd = self.utils.isAddition(spdDef.productCode),
                def = (isAdd ? resourceHelper.getAddDef(self.utils.getConfigProductCode(spdDef.productCode)) : resourceHelper.getProdDef(self.utils.getConfigProductCode(spdDef.productCode))),
                variantContainer = (isAdd ? def.VARIANTS : def.VARIANTLIST);
              if (angular.isArray(variantContainer)) {
                angular.forEach(variantContainer, function(variantDef) {
                  allowed.push(variantDef.CODE);
                });
              }
            }
          }

          var clientId = null;
          if (allowedRiskData.objectClientId) {
            clientId = allowedRiskData.objectClientId;
          } else if (allowedRiskData.objectId) {
            clientId = self._getClientIdForByProdType(spdDef.productCode, allowedRiskData.objectId, tmpDc);
          }
          //jeśli produkt/ryzyko jest już na liście to uzupełniamy tylko dostępne warianty
          var allowedVariantData = lsnUtils.findObjInArray(container, {
            product: spdDef.productCode,
            risk: spdDef.riskCode,
            objId: clientId
          });
          if (allowedVariantData === null) {
            container.push({
              product: spdDef.productCode,
              risk: spdDef.riskCode,
              allowedVariants: allowed,
              objId: clientId
            });
          } else {
            allowedVariantData.allowedVariants = lsnUtils.arrayUnique(allowedVariantData.allowedVariants.concat(allowed));
          }
        });
      };

      /**
       * rzutuje wartości dla dowzolonych atrybutów na podstawie spd i uzupełnia dane w przekazanym obiekcie (np objectClientId)
       * @param  {ApplicationAllowedChangesObjectModel} obj obiekt, ktorego atrybuty beda rzutowane
       * @param  {String} subGroupName nazwa podgrupy z allowedChanges
       * @return {Boolean} false gdy nic do zrzutowania nie ma
       */
      this._processAllowedObject = function(obj, subGroupName, tmpDc) {
        if (!angular.isArray(obj.attributes)) {
          return false;
        }
        var objName = mainDataContainer.allowedSubGroupToObjectName[subGroupName],
          riskName = (angular.isArray(obj.risksName) && angular.isDefined(obj.risksName[0]) ? obj.risksName[0] : null), //jesli rozpatrujemy ryzyka, to wystarczy sprawdzic nazwe pierwszego z brzegu, bo zakladamy ze atrybuty maja taką samą definicję dla każdego ryzyka
          policyIdpm = angular.isDefined(tmpDc.policies[0]) ? tmpDc.policies[0].product.compId : null;
        angular.forEach(obj.attributes, function(attr) {
          if (angular.isArray(attr.allowedValues)) {
            var allowedValues = [];
            angular.forEach(attr.allowedValues, function(val) {
              if (riskName !== null) {
                allowedValues.push(fromRestAttributeMapper.castAttributeFor(objName, attr.name, val, riskName));
              } else if (subGroupName === 'salesProduct') { //dunamicValues polisy
                allowedValues.push(fromRestAttributeMapper.castAttributeForPolicy(attr.name, val, policyIdpm));
              } else {
                allowedValues.push(fromRestAttributeMapper.castAttributeFor(objName, attr.name, val));
              }
            });
            attr.allowedValues = allowedValues;
          }
        });
        //jeśli ryzyko i wskazanie na obiekt to uzueplaniamy clientId obiektu
        if (riskName !== null) {
          var def = self.utils.findRiskDefByIdpm(riskName);
          obj.def = def;
          if (obj.objectId) {
            obj.objectClientId = self._getClientIdForByProdType(def.productCode, obj.objectId, tmpDc);
          }

        }
        return true;
      };

      this._getClientIdForByProdType = function(prodType, id, tmpDc) {
        switch (resourceHelper.productType[prodType]) {
          case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
          case CONSTANTS.PRODUCT_TYPE_PERSON:
            return tmpDc._subjectIdMap[id];
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            return tmpDc._vehicleIdMap[id];
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            return tmpDc._estateIdMap[id];
          default:
            return null;
        }
      };

    };

    return new FromRestApplicationMapper();
  }
]);
