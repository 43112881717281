angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorAdvancedFilterHelper', ['IKomunikatorAdvancedSearchMessageModel', 'iKomunikatorMessageContextSvc', 'ihestiaIkomunikatorCommonHelper', '$rootScope', '$state', 'iKomunikatorConstants', 'ihestiaIkomunikatorNavigationHelper',
    function(IKomunikatorAdvancedSearchMessageModel, iKomunikatorMessageContextSvc, ihestiaIkomunikatorCommonHelper, $rootScope, $state, iKomunikatorConstants, ihestiaIkomunikatorNavigationHelper) {
      var AdvancedFilterHelper = function() {
        var self = this;

        this.filter = null;
        //uwaga flaga powinna sie zmienic tylko raz
        this.loaded = {dict: false};

        this.dictionaries = {
          types: [{
            id: -1,
            label: '-wybierz-'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
            label: 'Klient'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_WORKER,
            label: 'Pracownik'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_CENTRAL,
            label: 'Centrala'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE,
            label: 'Profil sprzedażowy'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_CLAIMS_ADJUSTER,
            label: 'Likwidator'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_FUNCTIONAL_MAILBOX,
            label: 'SKRZYNKA FUNKCYJNA'
          }, {
            id: iKomunikatorConstants.CONTACT_ROLE_TASK_POOL,
            label: 'PULA'
          },{
            id: iKomunikatorConstants.CONTACT_ROLE_OTHER,
            label: 'Inny odbiorca'
          }],
          subjects: [],
          channelNames: [{
            type: -1,
            label: '-wybierz-'
          }, {
            type: 'sms',
            label: 'SMS'
          }, {
            type: 'email',
            label: 'EMAIL'
          }, {
            type: 'komunikat',
            label: 'KOMUNIKAT'
          }, {
            type: 'letter',
            label: 'LIST'
          }, {
            type: 'fax',
            label: 'FAX'
          }, {
            type: 'www',
            label: 'WWW'
          }
          ]
        };

        /**
         * [init ustwia filtr oraz listę tematów]
         */
        this.init = function() {
          self.setNewFilter();
          self.setSubjects();
        };

        /**
         * [setNewFilter ustawia filtr z modelu]
         */
        this.setNewFilter = function() {
          self.filter = new IKomunikatorAdvancedSearchMessageModel();
        };

        /**
         * [setNewFilter zwraca ustawiony filtr]
         */
        this.getFilter = function() {
          return self.filter;
        };

        /**
         * [setNewFilter zwraca ustawione słowniki dla tpl zaawansowanego wyszikiwania]
         */
        this.getDictionaries = function() {
          return self.dictionaries;
        };

        /**
         * [setFilterData ustawia dane w filtrze z czystego obiektu danych]
         * @param {object} data [dane do filtru]
         * @return {[type]}     [czy zapisano jakikolwiek element na filtrze]
         */
        this.setFilterData = function(data) {
          var anyValueForModelSet = self.filter.setData(data);
          return anyValueForModelSet;
        };

        /**
         * [setSubjects ustawia tematy z usługi]
         * @param {object} data [dane do filtru]
         */
        this.setSubjects = function() {
          iKomunikatorMessageContextSvc.getDefaultFields({}, function(result) {
            if (angular.isDefined(result.data) && angular.isArray(result.data.subjectList) && result.data.subjectList.length > 0) {
              self.dictionaries.subjects = result.data.subjectList;
              self.dictionaries.subjects.unshift({
                label: '-wybierz-',
                code: -1
              });
              self.loaded.dict = true;
            } else {
              ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła dostępnych tematów');
            }
          });
        };

        /**
         * [setFilterFromFtsSearch ustawia dane w filtrze na podstawie danych z wyszukanego odbiorcy w wyszukiwaniu prostym]
         * @param {IKomunikatorFtsSearchModel} recipient [obiekt z wyszukiwania prostego]
         */
        this.setFilterFromFtsSearch = function(recipient) {
          self.filter.mapAttrFts(recipient);
        };
        
        /**
         * [initFilterFromUrl inicjacja filtru zaawamsowanego wyszukiwania poprzez parametry get]
         * @return {boolean} [czy przekazano obiekt filtru poprzez url]
         */
        this.initFilterFromUrl = function() {
          var queryParams = angular.copy($state.params),
            data = {};
          $.each(queryParams, function(name, value) {
            if (value === 'true' || value === 'false') {
              data[name] = !!value;
            } else if (angular.isString(value)) {
              data[name] = value;
            }
          });

          var anyValueForModelSet = self.setFilterData(data);

          return anyValueForModelSet;
        };

        /**
         * na zmiane stanu na inna podstrone niz historia/podglad szczegolow czyścimy filtr wyszukiwania zaawansowanego
         */
        $rootScope.$on('$stateChangeStart', function($event, toState) {
          if (angular.isObject(toState) && toState.name !== ihestiaIkomunikatorNavigationHelper.fullListPanelState && toState.name !== ihestiaIkomunikatorNavigationHelper.detailsState) {
            self.setNewFilter();
          }
        });

        // ponieważ $stateChangeSuccess nie jest odpalany przy odświeżeniu strony, to robimy $watcha na stanie
        /**
         * na zmiane stanu na inna podstrone niz historia/podglad szczegolow czyścimy filtr wyszukiwania zaawansowanego
         */
        $rootScope.$watch('$state.$current', function(toState) {
          if (angular.isObject(toState) && toState.name !== ihestiaIkomunikatorNavigationHelper.fullListPanelState && toState.name !== ihestiaIkomunikatorNavigationHelper.detailsState) {
            self.setNewFilter();
          }
        });

        self.init();
      };

      return new AdvancedFilterHelper();
    }
  ]);