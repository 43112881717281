angular.module('salesPath2')
  .controller('vehicleZkModalCtrl', ['$scope', 'CONSTANTS', 'communicationHelper',
    function($scope, CONSTANTS, communicationHelper) {
      $scope.zkData = {
        number: $scope.dataContainer.greenCardNumber
      };

      /**
       * Init
       */
      $scope.init = function() {
        $scope.fireListeners();
      };

      /**
       * Odpalamy nasłuchiwanie eventow, watchy itp.
       */
      $scope.fireListeners = function() {
        // zapisz na edycji pojazdu
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleZkModal', function() {
          $scope.saveZk();
        });
      };

      /**
       * Zapis zk
       */
      $scope.saveZk = function() {
        communicationHelper.saveZk($scope.zkData.number);
      };

      $scope.init();
    }
  ]);