angular.module('ihestiaWidgets.claims')
   .factory('claimPublicSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var ClaimPublic = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
      this.businessArea = 'iszkody';
      this.resource = 'public';
    };

    return new ClaimPublic();
  }])
;