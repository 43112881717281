angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaLifeRiskTableWrapper', [function() {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/riskTableWrapper/lifeRiskTableWrapper.tpl.html',
      transclude: true,
      restrict: 'E',
      scope: {
        hideTabs: '=',
        /**
         * type {{activeRiskTables: boolean}} - pass object as reference
         */
        showElements: '=',
        activeAvailable: '=',
        onToggle: '&'
      },
      link: function(scope) {
        scope.toggleRiskTables = function(activeClicked) {
          if (scope.onToggle) {
            scope.onToggle({
              activeClicked: activeClicked
            });
          }
        };
      }
    };
  }]);
