angular.module('ihestiaWidgets.policyOperations.common')
  .factory('policyOperationsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PolicyOperationsSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'policyOperations';
        this.businessArea = 'insurance';
      };

      return new PolicyOperationsSvc();
    }]);