angular.module('lsnBase.models')
  .factory('LsnSimplePolicyModelV1', ['LsnAbstractModel', 'LsnPolicyModelConstants', 'SalesProductModel',
    function(LsnAbstractModel, LsnPolicyModelConstants, SalesProductModel) {

      var LsnSimplePolicyModelV1 = function() {
        this.objectName = 'SimplePolicy';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          frequency: {
            type: 'int'
          },
          paymentMethod: {
            type: 'string',
            defaultValue: LsnPolicyModelConstants.POLICY_PAYMENT_METHOD_CASH,
            dictionary: LsnPolicyModelConstants.DICTIONARY_POLICY_PAYMENT_METHOD
          },
          installments: {
            type: 'array',
            elementsType: 'Installment'
          },
          suretyRef: {
            type: 'array',
            elementsType: 'string'
          },
          documents: {
            type: 'array',
            elementsType: 'string'
          },
          discounts: {
            type: 'array',
            elementsType: 'PolicyDiscount'
          },
          product: {
            type: 'SalesProduct'
          },
          vehicles: {
            type: 'array',
            elementsType: 'int'
          },
          risks: {
            type: 'array',
            elementsType: 'Risk'
          },
          estates: {
            type: 'array',
            elementsType: 'string'
          },
          insuredRef: {
            type: 'string'
          },
          symbol: {
            type: 'string'
          },
          start: {
            type: 'dateTime'
          },
          end: {
            type: 'dateTime'
          },
          number: {
            type: 'string'
          }
        };

        this.id = null; // string - Identyfikator obiektu nadawany po stronie serwera.
        this.frequency = null; // number, Częstotliwość opłacenia składek (1 - roczna, 2 - półroczna, 4 - kwartalna, 12 - miesięczna, 99 - jednorazowa).
        this.paymentMethod = null; // string, Forma płatności
        this.installments = null; // lista Installment, kalendarz rat
        this.suretyRef = null; // lista string, Lista identyfikatorów podmiotów udzielających cesję z listy organizations (pole metaData.Id).
        this.documents = null; // Lista dokumentów do polisy.
        this.discounts = null; // Lista zniżek przypisanych do polisy.
        this.product = new SalesProductModel(); // salesProduct
        this.vehicles = null; // Lista idków pojazdów powiązanych z polisą
        this.estates = null; // Lista nieruchomosci
        this.risks = null; // lista Risk, Kolekcja ubezpieczeń
        this.insuredRef = null; // string Identyfikator ubezpieczonego. MetaData.Id z listy person lub organization
        this.symbol = null; // string
        this.start = null; // string data poczatku ochrony
        this.end = null; // string data konca ochrony
        this.number = null; // string, reserved policy number
      };

      LsnSimplePolicyModelV1.prototype = LsnAbstractModel.prototype;

      return LsnSimplePolicyModelV1;
    }
  ]);