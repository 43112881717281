angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaProgramDefinitionModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var ProgramDefinitionModel = function() {
      this.objectName = 'ProgramDefinitionModel';

      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        code: {
          type: 'string'
        },
        description: {
          type: 'string'
        },
        status: {
          type: 'IhestiaPzaDictionary'
        },
        segment: {
          type: 'IhestiaPzaDictionary'
        },
        startDate: {
          type: 'date'
        },
        endDate: {
          type: 'date'
        },
        conditionList: {
          type: 'array',
          elementsType: 'IhestiaPzaConditionList'
        },
        budget: {
          type: 'float'
        },
        typeCode: {
          type: 'string'
        }
      };

      this.id = null;
      this.code = null;
      this.description = null;
      this.status = null;
      this.segment = null;
      this.startDate = null;
      this.endDate = null;
      this.budget = null;
      this.conditionList = [];
      this.typeCode = null;

    };


    ProgramDefinitionModel.prototype = IHestiaAbstractModel.prototype;

    return ProgramDefinitionModel;
  }]);