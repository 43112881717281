/**
 * helper dla ustawień grida
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaGridSettings')
  .service('ihestiaGridSettingsHelper', ['$injector', 'IHestiaGridSettingsDataModel', 'ihestiaGridSettingsContextSvc', 'ihestiaOssIcorganizationunitSvc', 'ihestiaOssSalesprofileSvc', 'ihestiaCommonErrorHandler', 'ihestiaOssCompositeSvc', 'CompositeSubjectSelectConfigModel', 'ihestiaOssWorkerSvc', '$rootScope', 'ihestiaModelHelper', '$q', 'ihestiaDictionaryEmptyStringCodeReplace', '$state', 'breadCrumbsHelper',
   function ($injector, IHestiaGridSettingsDataModel, ihestiaGridSettingsContextSvc, ihestiaOssIcorganizationunitSvc, ihestiaOssSalesprofileSvc, ihestiaCommonErrorHandler, ihestiaOssCompositeSvc, CompositeSubjectSelectConfigModel, ihestiaOssWorkerSvc, $rootScope, ihestiaModelHelper, $q, ihestiaDictionaryEmptyStringCodeReplace, $state, breadCrumbsHelper) {

      /**
       * domyslne dane globalne sidebara
       * @type object
       */
      var defaultGlobalConfig = {
          visible: false, //czy sidebar widoczny
          loading: false, //czy sidebar jest wczytywany (np. doczytywanie opcji dla selectow)
          currentData: null, //instancja IHestiaGridSettingsDataModel z konfiguracją konkretnego grida
          settingsList: null, //lista filtrów pobrana z usługi,
          salesNetworks: { //pobrany config przedstawicielstwa, agencji, pracownika
            organizationUnits: [],
            salesProfiles: [],
            workers: []
          },
          currentFilterReady: false, //czy już załadowaliśmy dane do currentFilter, póki to się nie stało nie wrzucamy selectów sieci sprzedażowcyh, bo namieszają w wartościach
          previousViewFilter: null,
          filterToLoad: null, //jak tu wrzucimy filtr to zostanie on raz załadowany i będzie najważniejszy
          gridSettingsLoaded: false
      };

      var defaultCurrentFilter = {
        settings: {
          filterSettings: {
            organizationUnits: [],
            salesProfiles: [],
            workers: []
          }
        }
      };

      /**
       * Zwracany serwis
       */
      var IHestiaGridSettingsHelper = function () {
        var self = this;

        //mapa atrybutów z usługi na nasze
        this.salesNetworksConfigFromRestMap = {
          organizationUnitUiConfig: 'organizationUnits',
          salesProfileUiConfig: 'salesProfiles',
          workerUiConfig: 'workers'
        };

        this.salesNetworksFromSubjectKeyMap = {
          subjectKeysIcOrganisationUnitFilter: 'organizationUnits',
          subjectKeysSalesProfileFilter: 'salesProfiles',
          subjectKeysWorkerFilter: 'workers'
        };


        //i mapa w drugą stronę
        this.salesNetworksConfigToRestMap = {};
        angular.forEach(this.salesNetworksConfigFromRestMap, function(ourKey, serviceKey){
          self.salesNetworksConfigToRestMap[ourKey] = serviceKey;
        });

        //i mapa w drugą stronę
        this.salesNetworksToSubjectKeyMap = {};
        angular.forEach(this.salesNetworksFromSubjectKeyMap, function(ourKey, serviceKey){
          self.salesNetworksToSubjectKeyMap[ourKey] = serviceKey;
        });

        this.requestsCancel = $q.defer(); //promise do anulowania requestów do sieci sprzedażowych

        this.globalConfig = angular.copy(defaultGlobalConfig);
        this.currentFilter = angular.copy(defaultCurrentFilter);

        this.salesNetworksLoaded = false;
        this.settingsLoaded = false;

        this.currentStateName = null;
        this.storedFilterData = {};//zachowane dane z filtrów, obecnie zachowujemy dane z rodzica i po powrocie do niego ładujemy

        this.stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function(event, toState){
          if(!event.defaultPrevented)
          {
            self.stateChange(toState);
          }
        });

        $rootScope.$on('$stateChangeStart', function(event) {
          if(!event.defaultPrevented && self.globalConfig.visible)
          {
            self.globalConfig.visible = false;
          }
        });

        this.stateChange = function(toState)
        {
          if(self.currentStateName !== null)
          {
            var toDelete = [];
            //czyścimy dane ze stanów nie będących rodzicami
            angular.forEach(self.storedFilterData, function(filterData, storedFilterStateName){
              if(storedFilterStateName !== toState.name &&
                !breadCrumbsHelper.isParentState(storedFilterStateName, toState.name) &&
                !breadCrumbsHelper.isSiblingState(storedFilterStateName, toState.name))
              {
                toDelete.push(storedFilterStateName);
              }
            });
            //usuwamy
            angular.forEach(toDelete, function(toDeleteStateName){
              delete self.storedFilterData[toDeleteStateName];
            });

            //to nie jest pierwszy nasz stan (dla pierwszego stanu nie ma czego zachować z poprzedniego)
            // i przeszliśmy ze stanu który chcemy zachować
            if(self.globalConfig.currentFilterReady && self.globalConfig.currentData &&
              (breadCrumbsHelper.isParentState(self.currentStateName, toState.name) || breadCrumbsHelper.isSiblingState(self.currentStateName, toState.name))
              )
            {
              //mamy dane i jest to przejście do dziecka, więc zapisujemy je
              self.storedFilterData[self.currentStateName] = angular.copy(self.currentFilter);
            }
          }
          self.currentStateName = toState.name;
        };

        /**
         * rozwiązujemy promise podpięty pod requesty ładujące ustawienia i config sieci sprzedażowych
         * @param  {string} reason
         */
        this.cancelRequests = function(reason)
        {
          self.requestsCancel.resolve(reason);
          self.requestsCancel = $q.defer(); //tworzymy nowy promise dla kolejnych requestów
        };

        /**
         * dane dla selectów jednostki organizacyjnej/agencji/pracownika
         * @param  {[type]} contextId [description]
         * @return {[type]}           [description]
         */
        this.loadSalesNetworks = function() //eslint-disable-line consistent-return
        {
          if(!self.globalConfig.currentData.showSalesNetworks) //nie ma w ogóle sieci sprzedażowych
          {
            self.salesNetworksLoaded = true;
            return true;
          }
          //czyścimy dane sieci sprzedażowych
          self.globalConfig.salesNetworks = angular.copy(defaultGlobalConfig.salesNetworks);

          ihestiaOssCompositeSvc.get(self.globalConfig.currentData.contextId, null, '', function(response){
            self.globalConfig.salesNetworks = new CompositeSubjectSelectConfigModel().setData(response.data).getData();
            self.salesNetworksLoaded = true;
            self.checkLoadComplete();
          }, function(reject){
            //errorCallback
            if(reject.status !== 0)
            {
              $rootScope.$emit('hideBlockUi'); //coś się wysypało to chcemy żeby modale były widoczne
            }
          }, {
            timeout: self.requestsCancel.promise.then(angular.noop, angular.noop) //abort na promise
          });

        };

        /**
         * wczytuje ustawienia z uslugi i wywoluje callbacka
         * @param {string} serviceName nazwa serwisu REST do pobierania danych ustawień
         * @param {string} id
         * @param {function} callback
         */
        this.loadSettings = function () {
          if(!self.globalConfig.currentData.contextId) {
            ihestiaCommonErrorHandler.throwException({
              message: 'No contextId in grid!'
            });
          }
          //pobieramy listę filtrów dla usera
          ihestiaGridSettingsContextSvc.get(self.globalConfig.currentData.contextId, null, null, function(result){
            var defaultFilterIndex = null;
            angular.forEach(result.data, function(filter, index){
              if(filter.isDefault)
              {
                defaultFilterIndex = index;
              }
            });
            if(defaultFilterIndex > 0)
            {
              //przesuwamy domyślny filtr na górę
              var defaultFilterTab = result.data.splice(defaultFilterIndex, 1);
              result.data.unshift(defaultFilterTab[0]);
            }


            self.globalConfig.settingsList = result.data;
            self.settingsLoaded = true;
            self.checkLoadComplete();
          }, function(reject){
            //errorCallback
            if(reject.status !== 0)
            {
              $rootScope.$emit('hideBlockUi'); //coś się wysypało to chcemy żeby modale były widoczne
            }
          },
          {
            timeout: self.requestsCancel.promise.then(angular.noop, angular.noop) //abort na promise
          });
        };

        /**
         * wczytuje ustawienia z uslugi i modyfikuje istniejącą listę
         */
        this.refreshSettings = function () {
          //pobieramy listę filtrów dla usera
          ihestiaGridSettingsContextSvc.get(self.globalConfig.currentData.contextId, null, null, function(result){
            angular.forEach(result.data, function(serviceSetting){
              var matchFound = false;
              angular.forEach(self.globalConfig.settingsList, function(localSetting){
                if(localSetting.id === serviceSetting.id)
                {
                  matchFound = true;
                }
              });
              if(!matchFound) //mamy nowy element
              {
                self.globalConfig.settingsList.push(serviceSetting);
                $rootScope.$broadcast('gridSettingsNewLoaded', serviceSetting);
              }
            });
          });
        };

        /**
         * wczytujemy domyślne dane sieci sprzedażowcyh do selectów
         */
        this.parseDefaultSalesNetworksValues = function()
        {
          self.parseDefaultSalesNetworkValues('organizationUnitUiConfig', 'organizationUnits', 'defaultOrganizationUnits');
          self.parseDefaultSalesNetworkValues('salesProfileUiConfig', 'salesProfiles', 'defaultSalesProfile');
          self.parseDefaultSalesNetworkValues('workerUiConfig', 'workers', 'defaultWorker');
        };

        this.parseDefaultSalesNetworkValues = function(configName, valueName, defaultNodeName)
        {
          if(self.globalConfig.salesNetworks[configName].renderMode === 'Hide' || self.globalConfig.salesNetworks[configName].renderMode === 'Locked') //search chyba też tu trafi
          {
            //ładujemy wartości
            if(self.globalConfig.salesNetworks[configName].loadDefault &&
              self.globalConfig.salesNetworks[defaultNodeName])
            {
              self.globalConfig.salesNetworks[valueName] = [];
              var defaultNetwork = angular.copy(self.globalConfig.salesNetworks[defaultNodeName]);
              defaultNetwork.selected = true;
              self.globalConfig.salesNetworks[valueName].push(defaultNetwork);
            }
          }
          else if(self.globalConfig.salesNetworks[configName].renderMode === 'List' &&
            self.globalConfig.salesNetworks[configName].loadDefault &&
            self.globalConfig.salesNetworks[defaultNodeName])
          {
            //zaznaczamy te które są na liście i oznaczone jako domyślne
            angular.forEach(self.globalConfig.salesNetworks[valueName], function(element){
              if(element.subjectKeyString === self.globalConfig.salesNetworks[defaultNodeName].subjectKeyString)
              {
                element.selected = true;
              }
            });
          }
        };

        /**
         * jak mamy już settingsy i sieci to puszczmy informacje do grida
         */
        this.checkLoadComplete = function(){
          if(self.settingsLoaded && self.salesNetworksLoaded)
          {
            if(self.globalConfig.currentData.showSalesNetworks)
            {
              self.parseDefaultSalesNetworksValues();
              self.globalConfig.salesNetworksCopy = angular.copy(self.globalConfig.salesNetworks); //kopia żeby dało się przywrócić bazową wersję
            }
            $rootScope.$broadcast('gridSettingsConfigLoaded', self.globalConfig.currentData.contextId);
          }
        };

        this.setOptions = function(gridOptions){

          if(!gridOptions.fields || gridOptions.fields.length === 0)
          {
            // ihestiaCommonErrorHandler.throwException({
            //   message: 'Nie przkazano fields dla grida. Prawdopodobnie źle przekazane opcje.',
            //   data: {fieldAttrs: gridOptions}
            // });
          }

          self.settingsLoaded = false;
          self.salesNetworksLoaded = false;
          self.globalConfig.filterToLoad = null;

          self.cancelRequests('configChanged');

          if(self.storedFilterData[self.currentStateName])
          {
            self.globalConfig.filterToLoad = self.storedFilterData[self.currentStateName];
            delete self.storedFilterData[self.currentStateName];
          }
          else if(self.globalConfig.currentFilterReady && self.globalConfig.currentData && self.globalConfig.currentData.showSalesNetworks)
          {
            if(gridOptions.ignorePreviousView)
            {
              self.globalConfig.previousViewFilter = null;
            }
            //to nie pierwsze załadowanie filtrów i były sieci sprzedażowe, więc kopiujemy, bo chcemy mieć dane sieci sprzedażowych
            if(!self.globalConfig.currentData.showWorkerFilter && self.globalConfig.previousViewFilter && self.globalConfig.previousViewFilter.settings.filterSettings && self.globalConfig.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter)
            {
              //na poprzednim widoku nie było pracowników, ale chcemy dziedziczyć pracowników
              if(!self.currentFilter.settings.filterSettings)
              {
                self.currentFilter.settings.filterSettings = {};
              }
              self.currentFilter.settings.filterSettings.subjectKeysWorkerFilter = angular.copy(self.globalConfig.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter);
            }

            if(!gridOptions.ignorePreviousView)
            {
              self.globalConfig.previousViewFilter = angular.copy(self.currentFilter);
            }
            if(typeof gridOptions.notInherit !== 'undefined' && typeof gridOptions.notInherit[self.globalConfig.currentData.contextId] !== 'undefined') //chcemy coś wykluczyć z dziedziczenia
            {
              angular.forEach(gridOptions.notInherit[self.globalConfig.currentData.contextId], function(itemName){
                if(typeof self.globalConfig.previousViewFilter.settings !== 'undefined' && typeof self.globalConfig.previousViewFilter.settings.filterSettings !== 'undefined' && typeof self.globalConfig.previousViewFilter.settings.filterSettings[itemName] !== 'undefined')
                {
                  delete self.globalConfig.previousViewFilter.settings.filterSettings[itemName];
                }
              });
            }
          }
          self.globalConfig.currentFilterReady = false;
          self.globalConfig.gridSettingsLoaded = false;
          self.setConfig(self.getConfigByGridOptions(gridOptions));
          self.loadSettings();
          //ładujemy config pól jednostki organizacyjnej i agecji
          self.loadSalesNetworks();
          $rootScope.$broadcast('gridConfigChanged', self.globalConfig.currentData.contextId);
        };

        /**
         * Pokazujemy menu trybika
         * @param {IHestiaGridSettingsDataModel} config instancja konfiguracji sidebara
         * @return {[type]} [description]
         */
        this.showGridSettings = function () {
          self.globalConfig.visible = true;
        };


        /**
         * Ustawiamy kontekst/konfig
         * @param {IHestiaGridSettingsDataModel} config [description]
         */
        this.setConfig = function (config) {
          self.globalConfig.currentData = config;
          //sprawdzamy czy jakies opcje trzeba pobrac zewnerzna usluga
          var servicesToRun = [];
          angular.forEach(self.globalConfig.currentData.filters, function(filter) {
            if (angular.isFunction(filter.optionsService)) {
              self.globalConfig.loading = true;
              filter.optionsLoaded = false;
            }
          });
          //uruchamiamy wszystkie uslugi pobierania opcji
          angular.forEach(servicesToRun, function(func) {
            func();
          });
        };

        this.conditionsMet = function(validator)
        {
          var allConditionsMet = true;
          if(validator && validator.conditions) {
            angular.forEach(validator.conditions, function(condition){
              if(condition === 'noAgencySelected') // for now we only have one harcoded condition
              {
                if(self.globalConfig.currentData.showSalesNetworks &&
                  self.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length > 0
                  )
                {
                  allConditionsMet = false;
                }
              }
            });
          }
          return allConditionsMet;
        };

        this.getValidData = function()
        {
          var validData = {
            valid: true,
            error: {}
          };
          if(self.globalConfig.currentData.showSalesNetworks && !self.globalConfig.currentData.allowEmptySaleProfile && self.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length === 0) //nie można zamknąć, bo brak wybranych agencji
          {
            validData.valid = false;
            validData.error.subjectKeysSalesProfileFilter = {
              'required': true
            };
          }
          if(self.globalConfig.currentData.validators)
          {
            angular.forEach(self.globalConfig.currentData.validators, function(validator){
              if(self.conditionsMet(validator))
              {
                if(validator.type === 'required' && !self.currentFilter.settings.filterSettings[validator.element])
                {
                  validData.valid = false;
                  if(angular.isUndefined(validData.error[validator.element]))
                  {
                    validData.error[validator.element] = {};
                  }

                  validData.error[validator.element].required = true;
                }
                if(validator.type === 'datesMaxRange' && self.currentFilter.settings.filterSettings[validator.dateFrom] && self.currentFilter.settings.filterSettings[validator.dateTo])
                {
                  var startDate = new XDate(self.currentFilter.settings.filterSettings[validator.dateFrom]);
                  var endDate = new XDate(self.currentFilter.settings.filterSettings[validator.dateTo]);
                  var diffDays = startDate.diffDays(endDate);
                  if(diffDays > validator.maxRangeDays || diffDays < 0)
                  {
                    validData.valid = false;
                    if(angular.isUndefined(validData.error[validator.dateFrom]))
                    {
                      validData.error[validator.dateFrom] = {};
                    }
                    if(angular.isUndefined(validData.error[validator.dateTo]))
                    {
                      validData.error[validator.dateTo] = {};
                    }
                    validData.error[validator.dateFrom].datesMaxRange = true;
                    validData.error[validator.dateTo].datesMaxRange = true;
                  }
                }
              }
            });
          }
          self.globalConfig.currentData.validData = validData;
          return validData;
        };

        /**
         * Ukrywamy menu trybika
         */
        this.hideGridSettings = function () {
          var validData = self.getValidData();
          if(!validData.valid) //nie można zamknąć
          {
            $rootScope.$broadcast('gridSettingsInvalid', self.globalConfig.currentData.contextId, validData);
            return false;
          }
          else
          {
            self.globalConfig.visible = false;
            $rootScope.$broadcast('gridSettingsClosed', self.globalConfig.currentData.contextId);
            return true;
          }
        };

        /**
         * zwraca domyslne dane dla kolumny
         * dane te wykorzystywane sa w IHestiaGridSettingsDataModel.columns
         * @returns {object}
         */
        this.getDefaultColumnData = function () {
          return {
            label: '',
            visible: true
          };
        };

        /**
         * zwraca domyslne dane dla filtra
         * dane te wykorzystywane sa w IHestiaGridSettingsDataModel.filters
         * @returns {object}
         */
        this.getDefaultFilterData = function () {
          return {
            type: 'string', //jeden z filterTypes
            value: null,
            options: [], //obiekty {code: kod, desc: opis_wyswietlony}
            optionsService: null, //opcjonalna  funkcja serwujaca opcje. Po pobraniu opcji musi zawolac metode setFilterOptions podajac kod filtra oraz tablice z opcjami w powyzszym formacie
            optionsLoaded: false //jesli optionsService !== null to ta opcja ustawia sie na true gdy zaladuja sie opcje. Nie trzeba ustawiac jej w konfiguracji/definicji filtra
          };
        };

        /**
         * ustawia opcje dla danego filtru (sczytane zewnetrzną usługą) i aktualizuje flagę .globalConfigloadeing
         * @param {string} code
         * @param {object} options obiekty {code: kod, desc: opis_wyswietlony[,...]}
         */
        this.setFilterOptions = function (code, options) {
          if (typeof self.globalConfig.currentData.filters[code] === 'undefined') {
            return;
          }
          self.globalConfig.currentData.filters[code].options = options;
          self.globalConfig.currentData.filters[code].optionsLoaded = true;
          //sprawdzanie czy wszystkie opcje ze wszystkich filtrow sa wczytane
          var loaded = true;
          angular.forEach(self.globalConfig.currentData.filters, function(filter) {
            if (angular.isFunction(filter.optionsService)) {
              loaded = loaded && filter.optionsLoaded;
            }
          });
          self.globalConfig.loading = !loaded;
        };

        /**
         * zwraca instancje konfiguracji gridSettings na podstawie opcji/konfiguracji grida (patrz commonGridConfig.$scope.options)
         * @param {object} options
         * @returns {IHestiaGridSettingsDataModel}
         */
        this.getConfigByGridOptions = function (options) {
          var config = new IHestiaGridSettingsDataModel(options.gridSettings);
          config.gridName = options.itemModelName;
          //kolumny
          if (typeof options.fields !== 'undefined') {
            angular.forEach(options.fields, function (fieldAttrs) {
              if(fieldAttrs.type !== 'checkbox' && !fieldAttrs.artificial) //checkbox i sztuczne kolumny są zawsze na początku
              {
                var disabled = true;
                if (fieldAttrs.customizable) {
                  if(typeof fieldAttrs.valueSource === 'undefined')
                  {
                    ihestiaCommonErrorHandler.throwException({
                      message: 'For column "customizable" you must set "valueSource"',
                      data: {fieldAttrs: fieldAttrs}
                    });
                  }
                  disabled = false;
                }
                var valueSource = fieldAttrs.valueSource;
                if(angular.isDefined(fieldAttrs.orderBy))
                {
                  valueSource = fieldAttrs.orderBy;
                }
                if(angular.isUndefined(fieldAttrs.tabCode))
                {
                  fieldAttrs.tabCode = 'base';
                }
                if(angular.isUndefined(config.columns[fieldAttrs.tabCode]))
                {
                  config.columns[fieldAttrs.tabCode] = [];
                }
                config.columns[fieldAttrs.tabCode].push(
                  angular.extend({}, self.getDefaultColumnData(), {
                    label: fieldAttrs.label,
                    labelCode: fieldAttrs.labelCode,
                    settingsLabel: (angular.isDefined(fieldAttrs.settingsLabel) ? fieldAttrs.settingsLabel : fieldAttrs.label),
                    settingsLabelCode: (angular.isDefined(fieldAttrs.settingsLabelCode) ? fieldAttrs.settingsLabelCode : fieldAttrs.labelCode),
                    valueSource: valueSource, code: valueSource, visible: !fieldAttrs.defaultHidden, disabled: disabled})
                  );
              }
            });
          }
          //filtry
          if (typeof options.filterConfigs !== 'undefined') {
            angular.forEach(options.filterConfigs, function (filterConfig, code) {
              config.filters[code] = angular.extend({}, self.getDefaultFilterData(), filterConfig);
            });
          }

          // później są dokładane puste opcje na początku, a chcemy bazowy config zostawić
          // więc przepisujemy elementy słownika zawchowując dowiązania
          config.dictionaries = {};
          angular.forEach(options.dictionaries, function(dict, dictCode){
            config.dictionaries[dictCode] = [];
            angular.forEach(dict, function(dictItem){
              config.dictionaries[dictCode].push(dictItem);
            });
          });

          if(options.constants){
            config.constants = options.constants;
          }
          config.requiredFilters = options.requiredFilters;
          config.multipleValues = options.multipleValues;

          //TODO zrefaktoryzować i wywalić IHestiaGridSettingsDataModel
          config.showOnlyFilterSettings = options.showOnlyFilterSettings;
          config.forcePreviousViewFilter = options.forcePreviousViewFilter;
          config.showOnlyColumnSettings = options.showOnlyColumnSettings;
          config.showSalesNetworks = options.showSalesNetworks;
          config.allowEmptySaleProfile = options.allowEmptySaleProfile;

          config.showWorkerFilter = options.showWorkerFilter;
          if(typeof options.filterRequestModel !== 'undefined')
          {
            config.filterRequestModel = options.filterRequestModel;
          }

          return config;
        };

        this.getCurrentFilterData = function(){
          var filterData = angular.copy(self.currentFilter.settings.filterSettings);

          angular.forEach(self.globalConfig.currentData.dictionaries, function(values, fieldName){
            var multipleValues = false;
            if(typeof self.globalConfig.currentData.multipleValues !== 'undefined' && self.globalConfig.currentData.multipleValues[fieldName])
            {
              multipleValues = self.globalConfig.currentData.multipleValues[fieldName];
            }

            if(multipleValues)
            {
              if(typeof filterData[fieldName] !== 'undefined')
              {
                if(filterData[fieldName].length === 0)
                {
                  delete filterData[fieldName];
                }
                else
                {
                  var simpleArray = [];
                  angular.forEach(filterData[fieldName], function(value){
                    if(angular.isDefined(value.selected) && value.selected === false)
                    {
                      return;
                    }
                    if(value.code === ihestiaDictionaryEmptyStringCodeReplace) //zmiennik pustego stringa
                    {
                      simpleArray.push('');
                    }
                    else
                    {
                      simpleArray.push(value.code);
                    }
                  });
                  if(simpleArray.length === 0)
                  {
                    delete filterData[fieldName];
                  }
                  else
                  {
                    filterData[fieldName] = simpleArray;
                  }
                }
              }
            }
            else
            {
              if(
                (typeof self.globalConfig.currentData.requiredFilters === 'undefined' ||
                  self.globalConfig.currentData.requiredFilters[fieldName]) &&
                typeof filterData[fieldName] !== 'undefined' &&
                filterData[fieldName] === ''
              )
              {
                //pole ze słownika ale nie wymagane, więc wywalamy pustą wartość
                delete filterData[fieldName];
              }
            }
          });

          if(self.globalConfig.currentData.requestParser !== null)
          {
            filterData = self.globalConfig.currentData.requestParser(filterData);
          }

          if(typeof self.globalConfig.currentData.filterRequestModel !== 'undefined')
          {
            var ItemModel = $injector.get(self.globalConfig.currentData.filterRequestModel);
            var newRequestObject = new ItemModel();

            newRequestObject.setData(filterData);
            ihestiaModelHelper.minifyRestObject(newRequestObject, true);
            filterData = newRequestObject;
          }

          //minifyRestObject zje nam puste stringi, więc przepisujemy dopiero tutaj
          angular.forEach(filterData, function(item, key){
            if(item === ihestiaDictionaryEmptyStringCodeReplace)
            {
              filterData[key] = '';
            }
          });

          return filterData;
        };
      };

      return new IHestiaGridSettingsHelper();
    }
  ]);
