/**
 * javascriptowy submit formularza, potrzebny na przykład do wysłania posta do innej domeny (płatności)
 */
angular.module('lsnBase.directives')
.directive('manualFormSubmit', ['$timeout', function($timeout) {
    return {
        // scope: {},
        require: 'form',
        link: function($scope, $element) {
            $scope.$on('manualFormSubmit', function() {
                $timeout(function() {
                    // usunięcie błędu pod FF/platform-independent
                    try {
                        var formElement = angular.element($element);
                        if(formElement && formElement[0]) // w ios mamy tu tablicę elementów
                        {
                            formElement[0].submit();
                        }
                        else
                        {
                            angular.element($element).submit();
                        }
                    } catch(e) {
                        return false;
                    }
                    return null;
                });
             });
        }
    };
}]);