angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminSystemMessageCommitSvc', ['ihestiaCommonAdminMessageAbstract',
    function(ihestiaCommonAdminMessageAbstract) {

      var AdminSystemMessageCommitSvc = function() {
        ihestiaCommonAdminMessageAbstract.apply(this, arguments);

        var self = this;
        this.businessArea = 'message-manager';
        this.resource = 'system-message';
        this.subResource = 'commit';

        /**
         * Tworzenie nowego rekordu
         *
         * @param {*} data
         * @returns {*}
         */
        this.post = function(id, data, callback) {
          var url = self._getServiceUrl(id, self.subResource);
          return self.call(url, 'POST', data, callback);
        };

      };
      return new AdminSystemMessageCommitSvc();
    }
  ]);