
/**
 * Kontroler navbara
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaSsoBase')
  .controller('ihestiaPasswordChangeCtrl', ['$scope', 'ihestiaAuthenticationMypasswordsSvc', 'ihestiaSsoBaseInfoHelper', 'ihestiaCommonConfirmModalHelper', 'iHestiaCommonModalHelper', '$window', '$filter',
    function($scope, ihestiaAuthenticationMypasswordsSvc, ihestiaSsoBaseInfoHelper, ihestiaCommonConfirmModalHelper, iHestiaCommonModalHelper, $window, $filter) {

      $scope.modalSettings = {
        title: $filter('translate')('Security.changePasswordTitle'),
        size: 'sm',
        okBtnName: $filter('translate')('Security.changePasswordTitleOkBtn'),
        cancelBtnName: $filter('translate')('Public.cancel')
      };

      $scope.data = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      };


      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();
      $scope.actionsLocked = false;

      $scope.clearPasswordChangeData = function() {
        $scope.data.oldPassword = '';
        $scope.data.newPassword = '';
        $scope.data.confirmPassword = '';
        $scope.clearErrors();
      };

      /**
       * Czyszczenie błędów
       */
      $scope.clearErrors = function() {
        $scope.serviceErrors = [];
        $scope.badInputs = {
          oldPassword: false,
          newPassword: false,
          confirmPassword: false
        };
      };

      /**
       * klikniecię przycisku "zmień hasło"
       * @return {[type]}        [description]
       */
      $scope.changePassword = function() {
        if (!$scope.actionsLocked) {
          $scope.showAllErrors = true;
          // sprawdzamy, czy warto w ogóle odpytywać usługę
          $scope.validate();
          // jeśli brak błędów, to odpytujemy usługę
          if ($scope.serviceErrors.length === 0) {
            $scope.actionsLocked = true;
            $scope.$emit('showBlockUi');
            ihestiaAuthenticationMypasswordsSvc.put('', {
              Login: $scope.currentUser.login,
              NewPassword: $scope.data.newPassword,
              OldPassword: $scope.data.oldPassword
            }).then(function(response) {
              if (response.data && response.data.successStatus) {
                $scope.clearPasswordChangeData();
                $scope.confirmChange();
              } else if (response.data && response.data.messages && response.data.messages.length > 0) {
                angular.forEach(response.data.messages, function(error) {
                  $scope.serviceErrors.push(error.text);
                  if (error.code === 'ChangePasswordErrorBadOldPassword') {
                    $scope.badInputs.oldPassword = true;
                  }
                  if (error.code === 'ChangeMyPasswordErrorBadNewPassword') {
                    $scope.badInputs.newPassword = true;
                  }
                });
              } else {
                $scope.serviceErrors.push($filter('translate')('Public.systemError'));
              }
            }, function(reject){
              $scope.serviceErrors = $scope.applyErrors(reject);          
            }).finally(function() {
              $scope.actionsLocked = false;
              $scope.$emit('hideBlockUi');
            });
          }
        }
      };

      /**
       * Walidujemy
       */
      $scope.validate = function() {
        $scope.clearErrors();
        if (!$scope.data.oldPassword) {
          if($scope.currentUser.accountInfo.showPwdExpiryInfo && !$scope.currentUser.accountInfo.passwordExpired) {
            $scope.serviceErrors.push($filter('translate')('changePasswordErrorCurrentNoEmpty', {componentCode: 'Security'}));
          }else {
            $scope.serviceErrors.push($filter('translate')('Security.changePasswordErrorOldNoEmpty'));
          }          
          $scope.badInputs.oldPassword = true;
        }
        if (!$scope.data.newPassword) {
          $scope.serviceErrors.push($filter('translate')('Security.changePasswordErrorNewNoEmpty'));
          $scope.badInputs.newPassword = true;
        }
        if (!$scope.data.confirmPassword) {
          $scope.serviceErrors.push($filter('translate')('Security.changePasswordErrorNewRepeatNoEmpty'));
          $scope.badInputs.confirmPassword = true;
        }
        if ($scope.data.confirmPassword && $scope.data.newPassword && $scope.data.newPassword !== $scope.data.confirmPassword) {
          $scope.serviceErrors.push($filter('translate')('Security.changePasswordErrorNotEqual'));
          $scope.badInputs.confirmPassword = true;
          $scope.badInputs.newPassword = true;
        }
      };

        /**
         * Zwraca tablicę z błędami z usług
         * do wyświetlania w modalach itp.
         * @param  {mixed} response response z usługi
         * @return {String[]} lista błędów
         */
        $scope.applyErrors = function(response) {
          var errors = [];
          if (response && response.data && angular.isArray(response.data.messages) && response.data.messages.length > 0) {
            angular.forEach(response.data.messages, function(messageObject) {
              if (errors.indexOf( messageObject.text ) === -1) {
                errors.push(messageObject.text );
              }
            });
          } else if (response && response.data && angular.isArray(response.data) && response.data.length > 0) {
            angular.forEach(response.data, function(messageObject) {
              if (errors.indexOf( messageObject.text ) === -1) {
                errors.push( messageObject.text );
              }
            });
          } else {
            errors.push($filter('translate')('unknownServiceErrorHasOccured', {componentCode: 'Public'}));
          }

          return errors;
        };   
        
      /**
       * Potwierdzenie zmiany hasła
       * @return {[type]} [description]
       */
      $scope.confirmChange = function() {
        iHestiaCommonModalHelper.hideModal('ihestiaPasswordChange');
        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: $filter('translate')('Security.changePasswordTitle'),
          okBtnName: $filter('translate')('Security.changePasswordSuccessOkBtn'),
          cancelBtnName: '',
          size: 'sm',
          text: $filter('translate')('Security.changePasswordSuccessText'),
          okBtnCallback: function() {
            $window.location = '/';
          }
        });
      };

      $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaPasswordChange', function() {
        $scope.clearPasswordChangeData();
      });

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaPasswordChange', function($event) {
        $event.preventDefault();
        $scope.changePassword();
      });
    }
  ]);