/**
 * filtr zamieniający pustego stringa w słowniku na stały kod, żeby uniknąć problemów z selectem i filtrowaniem pustych wartości
 */
angular.module('lsnBase.filters')
  .filter('cutLongWords', ['lsnWordHelper',
    function(lsnWordHelper){
    return function(phrase, maxWordLengthPx) {
      if(typeof maxWordLengthPx === 'undefined' || maxWordLengthPx <= 0 || !angular.isString(phrase))
      {
        return phrase;
      }

      var phraseTab = phrase.split(' ');
      var resultTab = [];
      angular.forEach(phraseTab, function(word){
        var resultWord = word;
        if(lsnWordHelper.getWordLength(resultWord) > maxWordLengthPx)
        {
          resultWord = lsnWordHelper.cutWord(resultWord, maxWordLengthPx, '...');
        }
        resultTab.push(resultWord);
      });

      return resultTab.join(' ');
    };
}]);
