angular.module('ihestiaWidgets.policyTermination')
  .controller('policyCancellationCtrl', ['$scope', '$state', '$stateParams', 'ihestiaConfigHelper', 'resolvedPolicy', 'policiesSvc', 'policyOperationsSvc', 'resolvedCancellationCauses', 'ihestiaSsoBaseInfoHelper', 'customerPoliciesSvc', 'resolvedFileAttributes', 'fileServerSvc', '$filter', '$alert', 'ihestiaAutoUploadOneListUploadHelper', 'policyTerminationHelper', 'ihestiaCommonConfirmModalHelper', 'iHestiaCommonModalHelper', 'resolvedParams', 'policyOperationsHelper', 'ihestiaPlatformHelper', 'policyOperationsV2Svc', 'PolicyTerminationConstants',
    function($scope, $state, $stateParams, ihestiaConfigHelper, resolvedPolicy, policiesSvc, policyOperationsSvc, resolvedCancellationCauses, ihestiaSsoBaseInfoHelper, customerPoliciesSvc, resolvedFileAttributes, fileServerSvc, $filter, $alert, ihestiaAutoUploadOneListUploadHelper, policyTerminationHelper, ihestiaCommonConfirmModalHelper, iHestiaCommonModalHelper, resolvedParams, policyOperationsHelper, ihestiaPlatformHelper, policyOperationsV2Svc, PolicyTerminationConstants) {

      $scope.isLife = ihestiaPlatformHelper.isLifeContext();

      $scope.policy = resolvedPolicy.data;

      $scope.holder = null;
      $scope.insured = null;

      /**
       * Ustawienia modala potwierdzenia anulowania
       * @type {Object}
       */
      $scope.confirmModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['policyCancellation.breadCrumbTitle', {componentCode: 'PolicyOperations'}],
        okBtnName: ['Public.confirm', {componentCode: 'Public'}],
        cancelBtnName: ['Public.close', {componentCode: 'Public'}],
        size: 'sm'
      };

      $scope.viewParams = resolvedParams;

      //upload plików
      if (resolvedFileAttributes) {
        $scope.documentType = resolvedFileAttributes.data;
      }

      $scope.fileModels = [];

      $scope.otherData = {
        saveErrors: [],
        onlyWarnings: false
      };

      $scope.uploaderName = 'policyCancellation';

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderName,
        hideQueueList: true
      };

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();

      $scope.causes = [];
      $scope.formData = {};

      $scope.form = {
        cancellationForm: null
      };

      $scope.otherData = {};

      $scope.formSubmitted = false;
      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.policyDescription = $stateParams.policyNumber;
      $scope.policyNumber = $stateParams.policyNumber;
      $scope.objectDescription = '';
      // data received from policyTerminationsEhl directive
      $scope.ehlFormData = {
        fileModels: null,
        isFormValid: null,
        comment: null
      };

      $scope.terminationType = PolicyTerminationConstants.TYPES.CANCELLATION;

      $scope.init = function() {
        if (!$scope.isLife) {
          if ($scope.documentType) {
            $scope.initUploader();
            ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.documentType);
          }
          $scope.loadCauses();
          $scope.tryLoadShortPolicy();
        } else {
          $scope.$emit('hideBlockUi');
        }

      };

      $scope.tryLoadShortPolicy = function() {
        if ($scope.appContextData && $scope.appContextData.customerId) {
          //jakbyśmy kiedyś mieli coś odpalać poza ikontem
          var data = {
            id: $scope.appContextData.customerId,
            dynamicFilters: {
              policyNumberFrom: $stateParams.policyNumber,
              policyNumberTo: $stateParams.policyNumber
            }
          };
          customerPoliciesSvc.findByParams(data).then(function(response) {
            if (response.data.items.length === 1 && response.data.items[0].objectName) {
              $scope.policyDescription = $stateParams.policyNumber + ', ' + response.data.items[0].objectName;
              $scope.objectDescription = response.data.items[0].objectName;
            } else if(response.data.items.length === 1 && response.data.items[0].objectCount) {
              var objectName = $filter('translate')('mainPage.policyObjectCount', {componentCode: 'iKonto'});
              objectName = objectName + ': ' + response.data.items[0].objectCount;
              $scope.policyDescription = $stateParams.policyNumber + ', ' + objectName;
              $scope.objectDescription = objectName;
            }
            $scope.$emit('hideBlockUi');
          }, function() {
            $scope.$emit('hideBlockUi');
          });
        } else {
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.loadCauses = function() {
        if (angular.isArray(resolvedCancellationCauses.data)) {
          //dane przekazane jak w rozwiązaniach (Paweł Kędzierski namieszał)
          angular.forEach(resolvedCancellationCauses.data, function(cause) {
            $scope.causes.push({
              code: cause.policyOperationCauseCode,
              description: cause.policyOperationCauseName
            });
          });
        } else {
          //dane przekazane w formacie specyficznym dla anulowań
          angular.forEach(resolvedCancellationCauses.data, function(causeDescription, causeCode) {
            $scope.causes.push({
              code: causeCode,
              description: causeDescription
            });
          });
        }
      };

      $scope.initUploader = function() {
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          null, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.back = function() {
        policyOperationsHelper.end($scope.policyNumber, $stateParams.cancelState || null);
      };


      $scope.showConfirmModal = function() {
        iHestiaCommonModalHelper.showModal('policyCancellationConfirmModal');
      };

      /**
       * klikniecię przycisku "dalej"
       * @param {boolean} force
       * @param {{isFormValid: boolean, fileModels: object[], comment: string}?} ehlData
       *  - this data object is passed by policyTerminationEhl directive (only for EHL context)
       */
      $scope.forward = function(force, ehlData) {
        $scope.formSubmitted = true;
        // jeśli brak błędów, to odpytujemy usługę
        if ($scope.form.cancellationForm && $scope.form.cancellationForm.$valid || _.get(ehlData, 'isFormValid', $scope.ehlFormData.isFormValid)) {
          $scope.otherData.saveErrors = [];

          // we have to cache ehl directive data for future retries
          if (ehlData) {
            $scope.ehlFormData = ehlData;
          }
          var data = $scope.isLife ? $scope._mapCancellationRequestEhl(force)
            : $scope._mapCancellationRequestDefault(force);

          $scope.$emit('showBlockUi');

          // pick policy termination service
          var svcObject = policyOperationsHelper.getPolicyOperationsSvc();
          // pick policy cancellation resource (URL suffix)
          var cancelationResource = $scope.getCancellationResource($stateParams.policyNumber);

          svcObject.post(cancelationResource, data, null)
            .then(function(response) {
              // for Central Life, first call is always only for validation (no cancellation, even if status is 200),
              // if no errors are returned we call service once again without validation.
              if ($scope.isLife && $scope.currentUser.character.type === 'Central' && !data.ignoreValidation) {
                var newData = _.merge({}, data, {ignoreValidation: true});
                return svcObject.post(cancelationResource, newData, null);
              } else {
                return response;
              }
            }, function(reject) {
              throw reject;
            })
            .then(function(response) {
              $scope.onCancellationSuccess(response, data);
            }, $scope.onCancellationErrors);
        }
      };

      $scope.deleteFile = function(id) {
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      $scope.onlyWarnings = function() {
        var onlyWarnings = true;
        angular.forEach($scope.otherData.saveErrors, function(error) {
          if (error.type !== 'WARN') {
            onlyWarnings = false;
          }
        });
        $scope.otherData.onlyWarnings = onlyWarnings;
        return onlyWarnings;
      };

      $scope.onCancellationSuccess = function(response, requestData) {
        policyTerminationHelper.summary = response.data;
        policyTerminationHelper.policyNumber = $scope.policyNumber;

        if ($scope.isLife) {
          policyTerminationHelper.causeCode = requestData.operCauseCode;
        }

        if ($scope.viewParams.policyExtension) {
          policyTerminationHelper.titleLabel = ['policyExtensionCancellation.breadCrumbTitle', {componentCode: 'PolicyOperations'}];
        } else {
          policyTerminationHelper.titleLabel = ['policyCancellation.breadCrumbTitle', {componentCode: 'PolicyOperations'}];
        }
        $state.go('main.policyTerminationSummary', $stateParams.successState ? {
          successState: $stateParams.successState
        } : null);
      };

      $scope.onCancellationErrors = function(reject) {
        var anyErrorsInResponse = false;
        if (reject.data && reject.data.messages) {
          anyErrorsInResponse = true;
          $scope.otherData.saveErrors = reject.data.messages;
        } else if (reject.data && angular.isArray(reject.data)) {
          anyErrorsInResponse = true;
          $scope.otherData.saveErrors = reject.data;
        }

        if (anyErrorsInResponse && $scope.currentUser.character.type === 'Central' && $scope.onlyWarnings()) {
          $scope.showConfirmModal();
        }

        $scope.$emit('hideBlockUi');
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.policyCancellationConfirmModal', function() {
        $scope.forward(true);
      });

      /**
       * Returns policy cancellation request body for default context
       * @param {boolean} ignoreValidation
       * @returns {object}
       * @private
       */
      $scope._mapCancellationRequestDefault = function(ignoreValidation) {
        var data = angular.copy($scope.formData);
        data.policyNumber = $stateParams.policyNumber;
        data.canCancelWithWarnings = ignoreValidation;

        if ($scope.currentUser.character.type === 'Central') {
          //zapisujemy pliki
          data.files = [];
          angular.forEach($scope.fileModels, function(fileObject) {
            data.files.push({
              name: fileObject.name,
              size: fileObject.size,
              fileServerId: fileObject.fileServerId
            });
          });

        }
        return data;
      };

      /**
       * Returns policy cancellation request body for EHL
       * @param {boolean} ignoreValidation
       * @returns {object}
       * @private
       */
      $scope._mapCancellationRequestEhl = function(ignoreValidation) {
        return policyOperationsHelper.mapTerminationRequestEhl(
          PolicyTerminationConstants.CAUSE_CODES.CANCELLATION,
          ignoreValidation,
          _.pick($scope.ehlFormData, ['comment', 'fileModels'])
        );
      };

      /**
       *
       * @param {string} policyNumber
       * @returns {string}
       */
      $scope.getCancellationResource = function(policyNumber) {
        if ($scope.isLife) {
          return policyNumber + '/terminations';
        } else {
          return $scope.viewParams.policyExtension ? 'policyExtensionCancellation' : 'policyCancellation';
        }
      };

      $scope.init();

    }
  ]);
