angular.module('lsnBase.md5')
  /**
   * zwraca unikalną tablicę z przekazanej
   * @param {(string|number)[]} array
   * @returns {(string|number)[]} tablica unikalnych wartości
   */
  .factory('addMd5ToFile', function() {
    var addMd5ToFile = function(file) {
      var reader = new FileReader();
      reader.onload = function(event) {
        var arrayBuffer = event.target.result;
        var rawHash = SparkMD5.ArrayBuffer.hash(arrayBuffer, true); // output is array with 4 elements of signed 32-bit type
        var bytes = new Uint8Array(new Int32Array(rawHash).buffer);
        file.md5 = btoa(String.fromCharCode.apply(null, bytes));
      };
      reader.readAsArrayBuffer(file);
    };
    return addMd5ToFile;
  });