angular.module('ihestiaWidgets.policyTermination')
  .controller('policyTerminationSummaryCtrl', ['$scope', 'policyTerminationHelper', '$filter', 'labelActionHelper', 'policyOperationsHelper', 'PolicyTerminationConstants', '$stateParams',
    function($scope, policyTerminationHelper, $filter, labelActionHelper, policyOperationsHelper, PolicyTerminationConstants, $stateParams) {

      $scope.summaryResponse = policyTerminationHelper.summary;
      $scope.causeCode = policyTerminationHelper.causeCode;

      $scope.titleLabel = '';
      $scope.message = '';

      $scope.refreshLabels = function()
      {
        var pageTitle = ''; // NOSONAR
        var translateFunc = $filter('translate');
        // umożliwiamy podawanie zarówna stringa, jak i pełnego formatu
        if(angular.isArray($scope.titleLabel)) {
          pageTitle = translateFunc.apply(this, $scope.titleLabel);
        } else {
          pageTitle = translateFunc($scope.titleLabel, {componentCode: 'PolicyOperations'});
        }
        $scope.$emit('setPageTitle', {
          pageTitle: pageTitle
        });
      };

      $scope.init = function(){
        if($scope.summaryResponse === null)
        {
          //ktoś odświeżył stronę na podsumowaniu
          $scope.end();
        }
        else
        {
          if(policyTerminationHelper.titleLabel)
          {
            $scope.titleLabel = policyTerminationHelper.titleLabel;
            $scope.refreshLabels();
            labelActionHelper.registerAction('policyTerminationSummary', $scope.refreshLabels);
            policyTerminationHelper.titleLabel = null;
          }

          if(policyTerminationHelper.title)
          {
            $scope.$emit('setPageTitle', {
              pageTitle: policyTerminationHelper.title
            });
            policyTerminationHelper.title = null;
          }

          switch($scope.causeCode){
            case PolicyTerminationConstants.CAUSE_CODES.CANCELLATION:
              $scope.message = $filter('translate')('policyCancellationSuccessText', {componentCode: 'Public', params: {'policyNumber': policyTerminationHelper.policyNumber}});
              break;
            case PolicyTerminationConstants.CAUSE_CODES.WITHDRAWAL:
              $scope.message = $filter('translate')('policyTerminationEhl.withdrawalSuccessText', {componentCode: 'PolicyOperations', params: {'policyNumber': policyTerminationHelper.policyNumber}});
              break;
            case PolicyTerminationConstants.CAUSE_CODES.NOTICE:
              $scope.message = $filter('translate')('policyTerminationEhl.noticeSuccessText', {componentCode: 'PolicyOperations', params: {'policyNumber': policyTerminationHelper.policyNumber}});
              break;
            case PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_FIRST_PAYMENT:
            case PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_DATE:
              $scope.message = $filter('translate')('policyTerminationEhl.terminationSuccessText', {componentCode: 'PolicyOperations', params: {'policyNumber': policyTerminationHelper.policyNumber}});
              break;
            default:
              break;
          }

          if($scope.summaryResponse){
            if(angular.isArray($scope.summaryResponse) && $scope.summaryResponse[0] && $scope.summaryResponse[0].text)
            {
              $scope.message = $scope.summaryResponse[0].text;
            }
            else if($scope.summaryResponse.messages && $scope.summaryResponse.messages[0] && $scope.summaryResponse.messages[0].text)
            {
              $scope.message = $scope.summaryResponse.messages[0].text;
            }
          }
          $scope.$emit('hideBlockUi');
        }
      };



      $scope.end = function(){
        policyOperationsHelper.end(policyTerminationHelper.policyNumber, $stateParams.successState || null);
      };

    }
  ]);
