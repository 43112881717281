/**
 * Mapper wniosków z wersji "nieangularowej", dzięki któremu możemy odtworzyć wniosek w sp2
 */
angular.module('salesPath2').service('fromRestCompatibilityMapper', ['CONSTANTS', 'CONFIG', 'resourceHelper',
  function(CONSTANTS, CONFIG, resourceHelper) {
    var CompatibilityMapper = function() {
      var self = this;
      var ADDITIONAL_DATA_ID_TO_MAP = [
        'coowners',
        'leaserId'
      ];

      /**
       * mapuje additionalData z pojazdów, osób, nieruchomosci i organizacji
       * @param  {Object} addData obiekt z additionalData
       * @return {Boolean} true gdy zmapowano
       */
      this.mapAdditionalData = function(addData) {
        if (!angular.isObject(addData)) {
          return false;
        }
        angular.forEach(addData, function(val, key) {
          if (ADDITIONAL_DATA_ID_TO_MAP.indexOf(key) !== -1) {
            if (angular.isArray(val)) {
              angular.forEach(val, function(subVal, subIdx) {
                val[subIdx] = self.idToString(subVal);
              });
            } else {
              addData[key] = self.idToString(val);
            }
          }
        });
        return true;
      };

      /**
       * mapuje obiekt formatu dataContainer.selectedAdditions
       * @param  {[type]} selectedAdds [description]
       * @return {[type]}              [description]
       */
      this.mapIdContainer = function(obj) {
        angular.forEach(obj, function(v, k) {
          if (angular.isArray(v) || angular.isObject(v)) {
            self.mapIdContainer(v);
          } else {
            obj[k] = self.idToString(v);
          }
        });
      };

      /**
       * usuwa z selectedAdditions dodatki któeych brak w aktualnej specyfikacji
       * @param  {Object} selectedAdds
       */
      this.removeUnsupportedAdditions = function(selectedAdds) {
        var addsToDelete = [];
        angular.forEach(selectedAdds, function(selection, addCode) {
          if (resourceHelper.getAddDef(addCode, true) === null) {
            addsToDelete.push(addCode);
          }
        });
        angular.forEach(addsToDelete, function(addCode) {
          delete selectedAdds[addCode];
        });
      };

      /**
       * zwraca stringową wartość dla identyfikatora, o ile można ją zrzutować
       * @param  {*} id
       * @return {*}
       */
      this.idToString = function(id) {
        if (angular.isNumber(id) && !isNaN(id)) {
          return id + '';
        } else {
          return id;
        }
      };

    };

    return new CompatibilityMapper();
  }
]);