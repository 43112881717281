angular.module('ihestiaCommonDirectives')
  .factory('UploadDataContainer', function() {

    var UploadDataContainer = function() {
      var self = this;
      this.files = {
        toSend: [],
        uploaded: []
      };

      this.configDefault = {
        //w zależności od tej flagi,
        //albo pliki wysyłane są po kolei
        //a jeśli na true
        //to najpierw wysyłamy informacje o plikach
        //a nastepnie jesli wszystkie pliki są poprawne wysyłamy faktyczne pliki
        firstCheckAllFiles: true,
        autoUpload: false,
        //Czy przed wysłaniem pliku wywołać usługę do sprawdzania
        checkFilesBeforeSend: true,
        verifyForDocumentsTypes : [],
        doNotAddTokenId: false, //czy wyslanie pliku wymaga pobranie tokena
        oneFile: true, //czy upload umozliwia tylko jeden plik,
        showBlockUi: false
      };

      this.config = angular.copy(self.configDefault);
      this.status = null;
      this.windowUploadClose = null;
      this.processedFileIndex = 0;
      this.processedFile = null;
      this.selectedDocumentType = null;
      this.callbacks = {
        onFileUploadDone: null,
        onAllFileSend: null,
        onErrorSendingFile: null,
        onErrorAddingFile: null,
        onFileUploadAdd: null,
        onFileDelete: null
      };

      this.areFileToSend = function() {
        return self.files.toSend.length > 0;
      };

      this.areUploadedFiles = function() {
        return self.files.uploaded.length > 0;
      };

      this.getProcessedFile = function() {
        return self.files.toSend[self.processedFileIndex];
      };

      this.clearAllFiles = function() {
        self.files.toSend.splice(0, self.files.toSend.length);
        self.files.uploaded.splice(0, self.files.uploaded.length);
      };
    };

    return UploadDataContainer;
  });