/**
 * helper do sprawdzania statusów komponentów
 */
angular.module('ihestiaCommonComponents')
  .service('ihestiaCommonComponentStatusHelper', ['ihestiaConfigHelper', '$rootScope',
   function (ihestiaConfigHelper, $rootScope) {

      /**
       * Zwracany serwis
       */
      var IHestiaCommonComponentStatusHelper = function () {
        var self = this;

        this.agToComponents = {};
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CI')] = ['InformationCenter'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_PARTNER')] = ['Commissions', 'Premiums', 'Documents'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CLIENT')] = ['iKontoiHestia'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_DASHBOARD')] = ['Dashboard', 'DashboardTaskPools'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_AGENT')] = ['iAgent'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_E1')] = ['E1'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_E7')] = ['E7'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_MF')] = ['MF'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_H7')] = ['H7Vintage'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_H1')] = ['H1Vintage'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_OCK')] = ['SoloOcKomunikacyjne'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_OCZ')] = ['SoloOcZawodu'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_MD')] = ['SoloMojDom'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_IPEGAZ')] = ['iPegaz'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_IKOMUNIKATOR')] = ['iKomunikator'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_IMONITOR')] = ['iMonitorAgentWWW'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_EP')] = ['EP'];
        this.agToComponents[ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_ES')] = ['ES'];

        this.componentEnabled = {};
        angular.forEach(ihestiaConfigHelper.get('components'), function(component){
          self.componentEnabled[component.symbol] = component.enabled;
        });

        // this.componentEnabled = {
        //   InformationCenter: true,
        //   E1: true,
        //   E7: true,
        //   MF: true,
        //   Commissions: true,
        //   H7Vintage: true,
        //   SoloOcKomunikacyjne: true,
        //   SoloOcZawodu: true,
        //   SoloMojDom: true,
        //   iPegaz: true,
        //   iKomunikator: true,
        //   iMonitorAgentWWW: true
        // };

        //pozostałe symbole
        // ["ES", "EP", "FTS", "PartnershipProgram", "Konfigurator"]

        this.getComponentStatuses = function()
        {
          return self.componentEnabled;
        };

        this.isComponentEnabledByAgSymbol = function(agSymbol)
        {
          var enabled = false;
          if(typeof self.agToComponents[agSymbol] !== 'undefined')
          {
            angular.forEach(self.agToComponents[agSymbol], function(componentSymbol){
              if(self.isComponentEnabled(componentSymbol))
              {
                enabled = true;
              }
            });
          }
          return enabled;
        };

        this.isComponentEnabled = function(componentSymbol)
        {
          if(typeof self.componentEnabled[componentSymbol] === 'undefined')
          {
            return false;
          }
          return self.componentEnabled[componentSymbol];
        };

        this.setComponentStatus = function(componentSymbol, status)
        {
          if(status === 'ENABLED')
          {
            self.componentEnabled[componentSymbol] = true;
          }
          else
          {
            self.componentEnabled[componentSymbol] = false;
          }
          $rootScope.$broadcast('componentStatusChanged', componentSymbol);
        };

      };

      return new IHestiaCommonComponentStatusHelper();
    }
  ]);
