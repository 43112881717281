angular.module('ihestiaCrossTab')
  /**
   * zestaw metod do obsługi komunikacji miedzy zakladkami
   * @return {Object} API
   */
  .service('ihestiaCrossTabHelper', ['ihestiaCrossTabSvc', 'IHestiaCrossTabMessageModel', 'ihestiaConfigHelper', '$rootScope', function (ihestiaCrossTabSvc, IHestiaCrossTabMessageModel, ihestiaConfigHelper, $rootScope) {

      var ctConfig = ihestiaConfigHelper.get('crossTab'),
        cookieReadTimerId = null,
        cookieCleanTimerId = null,
        tabOpenTime = (new Date().getTime()),
        tabId = Math.random() + ''; //wystarczy :)


      var Helper = function () {
        var self = this;
        var messagesRead = []; //odczytane wiadomosci

        /**
         * aktualizuje ciasteczko o dodatkowa wiadomosc
         * @param {type} msg wiadomosc dla ciasteczka
         */
        var updateCookie = function (msg) {
          var cookieData = ihestiaCrossTabSvc.getCookie();
          if (cookieData === null) {
            cookieData = [];
          }
          cookieData.push(msg);
          ihestiaCrossTabSvc.setCookie(cookieData);
        };

        /**
         * czy wiadomosc zostala juz odczytana w tej zakladce
         * @return {boolean}
         */
        var isMessageRead = function (msg) {
          for (var i = 0; i < messagesRead.length; i += 1) {
            if (messagesRead[i].code === msg.code && messagesRead[i].created === msg.created) {
              return true;
            }
          }
          return false;
        };

        /**
         * konwertuje wiadomosc do zminimalizowanego obiektu js
         * @param {IHestiaCrossTabMessageModel} msg wiadomosc
         * @return {object} obiekt dla ciasteczka
         */
        var messageToPlainObject = function(msg) {
          var data = {};
          angular.forEach(msg, function(val, name) {
            if (! (name === 'attrs' && (val === null || val === {}) )) {
              data[name] = val;
            }
          });
          return data;
        };
        
        /**
         * broadcastuje do rootscopa nowa wiadomosc
         */
        var broadcastMessage = function (msg) {
          $rootScope.$broadcast('ihestiaCrossTab:message', msg);
        };

        /**
         * odczytuje wiadomosci wyslane z innych zakladek
         */
        this.readMessages = function () {
          var cookieData = ihestiaCrossTabSvc.getCookie();
          if (cookieData === null) {
            return;
          }
          angular.forEach(cookieData, function (msgData) {
            if (msgData.created > tabOpenTime) {
              var msg = new IHestiaCrossTabMessageModel();
              angular.extend(msg, msgData);
              if (!isMessageRead(msg)) {
                messagesRead.push(msg);
                broadcastMessage(msg);
              }
            }
          });
        };

        /**
         * wysyla wiadomosc do innych zakladek
         * @param {*} data
         */
        this.sendMessage = function (data) {
          var msg = new IHestiaCrossTabMessageModel();
          if (typeof data === 'string') {
            msg.code = data;
          } else if (data instanceof IHestiaCrossTabMessageModel) {
            msg = data;
          } else if (angular.isObject(data) && !angular.isArray(data)) {
            angular.forEach(data, function (val, name) {
              if (typeof msg[name] !== 'undefined') {
                msg[name] = val;
              }
            });
          } else {
            return;
          }
          msg.created = (new Date().getTime());
          msg.tabId = tabId;
          messagesRead.push(msg);
          updateCookie(messageToPlainObject(msg));
        };

        /**
         * wylacza usluge odczytu wiadomosci z innych zakladek oraz czyszczenia wiadomosci
         */
        this.disableService = function () {
          clearInterval(cookieReadTimerId);
          clearInterval(cookieCleanTimerId);
        };
      };

      var helperInstance = new Helper();
      cookieReadTimerId = setInterval(helperInstance.readMessages, ctConfig.COOKIE_READ_FREQ);
      cookieCleanTimerId = setInterval(ihestiaCrossTabSvc.cleanCookie, ctConfig.COOKIE_CLEAN_FREQ);
      return helperInstance;
    }]);