angular.module('lsnBase.models')
  .factory('LsnApplicationPremiumFrequencyModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {

      var ApplicationPremiumFrequency = function() {
        this.objectName = 'ApplicationPremiumFrequency';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          generalAgreementNumber: {
            type: 'string'
          },
          frequency: {
            type: 'int'
          }
        };

        this.generalAgreementNumber = null; // nr umowy generalnej
        this.frequency = null; // int, częstotliwość składek

      };

      ApplicationPremiumFrequency.prototype = LsnAbstractModel.prototype;

      return ApplicationPremiumFrequency;
    }
  ]);