angular.module('ihestiaWidgets.claims')
  .controller('claimDetailsCtrl', ['$scope', 'ihestiaAutoUploadOneListUploadHelper', 'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaCommonErrorHandler',
    'fileServerSvc', '$alert', 'ihestiaCommonConfirmModalHelper', '$filter', 'ihestiaIkomunikatorActionHelper', 'iKomunikatorConstants', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaConfigHelper', 'iHestiaCommonModalHelper', 'claimPublicSvc', '$sce',
    function($scope, ihestiaAutoUploadOneListUploadHelper, ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaCommonErrorHandler,
      fileServerSvc, $alert, ihestiaCommonConfirmModalHelper, $filter, ihestiaIkomunikatorActionHelper, iKomunikatorConstants, ihestiaIkomunikatorUserTypeHelper, ihestiaConfigHelper, iHestiaCommonModalHelper, claimPublicSvc, $sce) {
      $scope.uploadWidowOptions = {
        hide: true,
        minimize: false
      };

      $scope.mailPreviewData = null;

      $scope.allFilesSended = false;

      $scope.surveyAddressModal = {
        settings: {
          treatLabelAsI18nCode: true,
          title: ['surveyPlace', {
            componentCode: 'Public'
          }],
          okBtnName: ['Public.close', {
            componentCode: 'Public'
          }],
          size: 'sm',
          cancelBtnName: ''
        }
      };

      $scope.issueAddressModal = {
        settings: {
          treatLabelAsI18nCode: true,
          okBtnName: ['Public.close', {
            componentCode: 'Public'
          }],
          title: ['issuePlace', {
            componentCode: 'Public'
          }],
          size: 'sm',
          cancelBtnName: ''
        }
      };

      $scope.propertyDescriptionModal = {
        settings: {
          treatLabelAsI18nCode: true,
          okBtnName: ['Public.close', {
            componentCode: 'Public'
          }],
          title: ['insurance.subject', {
            componentCode: 'Public'
          }],
          size: 'sm',
          cancelBtnName: ''
        }
      };

      $scope.mailPreviewModal = {
        settings: {
          treatLabelAsI18nCode: true,
          okBtnName: ['Public.close', {
            componentCode: 'Public'
          }],
          title: ['mailPreview', {
            componentCode: 'Public'
          }],
          size: 'lg',
          cancelBtnName: ''
        },
        mailData: null
      };


      $scope.isDirectMode = ihestiaConfigHelper.get('BRAND') === 'DIRECT';

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.initUploader();
        $scope.setDocumentTypeForUpload();
      };

      $scope.initUploader = function() {
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: $scope.onFileUploadAdd,
            onAllFileSend: $scope.onAllFileSend,
            onErrorAddingFile: $scope.onErrorAddingFile
          },
          null, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.deleteFile = function(id) {
        if (!$scope.fileModels[id].fileIsUploaded()) {
          ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
        }
      };

      $scope.onFileUploadAdd = function() {
        $scope.uploadWidowOptions.hide = false;
      };

      $scope.onAllFileSend = function() {
        $scope.allFilesSended = true;
      };

      $scope.showSurveyAddressModal = function()
      {
        iHestiaCommonModalHelper.showModal('surveyAddressModal');
      };

      $scope.showIssueAddressModal = function()
      {
        iHestiaCommonModalHelper.showModal('issueAddressModal');
      };

      $scope.showDescriptionModal = function(type) {
        var title, text;
        if (type === 'issue') {
          title = $filter('translate')('circumstancesDescription', {componentCode: 'Public'});
          text = $scope.claim.issueDescription;
        } else if (type === 'damage') {
          title = $filter('translate')('damagesDescription', {componentCode: 'Public'});
          text = $scope.claim.damageDescription;
        }
        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: title,
          okBtnName: $filter('translate')('Public.close'),
          cancelBtnName: '',
          text: text,
          showOkSpinner: false
        });
      };
      $scope.showPropertyDescriptionModal = function() {
        iHestiaCommonModalHelper.showModal('propertyDescriptionModal');
      };

      $scope.loadMailPreview = function(documentItem)
      {
        $scope.$emit('showBlockUi');
        claimPublicSvc.get('frp/getMsgFile',
          {
            frpId: documentItem.entityId,
            fileName: documentItem.originalName
          }, null, function(response){
            $scope.mailPreviewModal.mailData = response.data;
            $scope.mailPreviewModal.mailData.text = $sce.trustAsHtml($scope.mailPreviewModal.mailData.text);
            $scope.$emit('hideBlockUi');
            iHestiaCommonModalHelper.showModal('mailPreview');
          }, function(){
            $scope.$emit('hideBlockUi');
          });
      };

      $scope.downloadMailFile = function(attachment)
      {
        var params = {
          responseType: 'blob'
        };

        $scope.$emit('showBlockUi');
        claimPublicSvc.get('frp/getFile',
        {
          token: $scope.mailPreviewModal.mailData.token,
          hash: attachment.hash
        }, null, function(result){
          var headers = result.headers();
          var blob = new Blob([result.data], {
            type: headers['Content-Type']
          });
          saveAs(blob, attachment.fileName);
          $scope.$emit('hideBlockUi');
        }, function(){
          $scope.$emit('hideBlockUi');
        }, params);
      };

      $scope.downloadFile = function(documentItem) {

        if(documentItem.originalName && documentItem.originalName.substr(documentItem.originalName.length - 4).toLowerCase() === '.msg')
        {
          $scope.loadMailPreview(documentItem);
        }
        else
        {
          var params = {
            responseType: 'blob',
            allowedStatuses: [404, 403, 410]
          };
          fileServerSvc.get(documentItem.token, null, '', function(result) {
            var headers = result.headers();
            var blob = new Blob([result.data], {
              type: headers['Content-Type']
            });
            saveAs(blob, documentItem.originalName); //nazwa z rozszerzeniem
          }, function(rejection) {
            $scope.showErrorAlertModal(null, rejection.status);
          }, params);
        }
      };

      $scope.showErrorAlertModal = function(text, status) {
        if (status === 410) {
          text = $filter('translate')('passedDeadlineForStorageFilesOnFRP', {componentCode: 'Public'});
        } else if (status === 403) {
          text = $filter('translate')('Public.noPermissionsForFile');
        } else if (status === 404) {
          text = $filter('translate')('Public.fileNotFound');
        } else if (angular.isUndefined(status)) {
          return;
        }

        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: $filter('translate')('Public.errorOccurred'),
          okBtnName: $filter('translate')('Public.close'),
          cancelBtnName: '',
          text: text,
          okBtnCallback: lsnNg.noop
        });
      };

      $scope.init();
    }
  ]);