angular.module('salesPath2')
  .service('dataTransferModalHelper', ['$rootScope', 'iHestiaCommonModalHelper', 'appVariables', 'RESOURCES', 'applicationHelper', '$window', 'ihestiaConfigHelper', 'CONSTANTS', 'sp2CommonHelper',
    function($rootScope, iHestiaCommonModalHelper, appVariables, RESOURCES, applicationHelper, $window, ihestiaConfigHelper, CONSTANTS, sp2CommonHelper) {
      var dataTransferModalHelper = function() {
        var self = this;

        /**
         * data for modal template
         * @type {{items: Array}}
         */
        this.tplData = {
          items: []
        };

        $rootScope.modals = $rootScope.modals || {}; //inicjalizacja

        /**
         * wyświetlanie okienka dataTransfer
         * @type {Object}
         */
        $rootScope.modals.dataTransferModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: ['sp2.modal.dataTransferModal.title', {
              componentCode: 'sp2'
            }],
            cancelBtnName: ['sp2.modal.close', {
              componentCode: 'sp2'
            }],
            okBtnName: false,
            size: 'sm'
          }
        };

        this.init = function() {
          self.initItems();
        };

        this.initItems = function() {
          self.tplData.items = [];
          if (!RESOURCES.DATA_TRANSFER) {
            return false;
          }
          angular.forEach(RESOURCES.DATA_TRANSFER, function(dtItem) {
            if (!dtItem.generalAgreementPrefix || sp2CommonHelper.getAllowedGeneralAgreement(dtItem.generalAgreementPrefix)) {
              self.tplData.items.push(dtItem);
            }
          });
          return true;
        };

        /**
         * run service to transfer calculation/offer
         * @param  {Object} item item from RESOURCES.DATA_TRANFER
         * @return {boolean} true if service has benn called
         */
        this.transferTo = function(item) {
          if ([CONSTANTS.APP_MODE_CALCULATION, CONSTANTS.APP_MODE_OFFER].indexOf(appVariables.appMode) === -1) {
            return false;
          }
          var clientUrl = null;
          if (appVariables.appMode === CONSTANTS.APP_MODE_CALCULATION) {
            applicationHelper.saveApplication(true, function() {
              applicationHelper.transferApplicationData(item.symbol, function(applId) {
                clientUrl = ihestiaConfigHelper.getUrl(item.url, 'external/init?action=viewApplication&applicationId=' + applId);
                $window.open(clientUrl);
              }, item.generalAgreementPrefix || null);
            });
            return true;
          }
          if (appVariables.appMode === CONSTANTS.APP_MODE_OFFER) {
            applicationHelper.transferApplicationData(item.symbol, function(applId) {
              clientUrl = ihestiaConfigHelper.getUrl(item.url, 'external/init?action=viewApplication&applicationId=' + applId);
              $window.open(clientUrl);
            }, item.generalAgreementPrefix || null);
            return true;
          }
          return false;
        };

        //wyswietla modal dataTransfer
        this.showdataTransferModal = function() {
          iHestiaCommonModalHelper.showModal('dataTransferModal');
        };

      };

      return new dataTransferModalHelper();
    }
  ])
  .run(['dataTransferModalHelper', function(dataTransferModalHelper) {
    lsnNg.noop(dataTransferModalHelper);
  }]);