angular.module('ihestiaSsoBase')
  .factory('ihestiaUsersAbstractSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var UsersAbstractSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.port = '8238';
        this.path = 'users';
        this.businessArea = 'users';

      };
      return UsersAbstractSvc;
    }
  ]);