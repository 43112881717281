angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseCtrl', ['$scope', 'policyOperationsSvc', 'VerificationDataModel', 'operationType', 'ihestiaSsoBaseInfoHelper', 'VerificationDataModelConstants', 'ihestiaModelHelper', 'resolvedNationalities', 'resolvedIdentityDocumentTypes', 'ihestiaDictionaryHelper', '$filter', '$stateParams', 'ihestiaCommonErrorHandler', 'VehicleOwnerChangeRecordModel', 'ihestiaConfigHelper', '$timeout', 'ihestiaFirstInfoOneListUploaderHelper', 'resolvedFileAttributes', 'clientDataHelper', 'policiesSvc', 'ihestiaCommonConfirmModalHelper', 'vehiclePurchaseHelper', 'PersonModel', 'OrganizationModel', 'installmentsSvc', 'ContactModelConstants', 'paymentsSvc', '$sce', 'UserCharacterTypeConstants', 'ihestiaAgHelper', '$state', 'resolvedNbkCountries', 'resolvedWithdrawalAttributes', 'policyOperationsHelper',
    function($scope, policyOperationsSvc, VerificationDataModel, operationType, ihestiaSsoBaseInfoHelper, VerificationDataModelConstants, ihestiaModelHelper, resolvedNationalities, resolvedIdentityDocumentTypes, ihestiaDictionaryHelper, $filter, $stateParams, ihestiaCommonErrorHandler, VehicleOwnerChangeRecordModel, ihestiaConfigHelper, $timeout, ihestiaFirstInfoOneListUploaderHelper, resolvedFileAttributes, clientDataHelper, policiesSvc, ihestiaCommonConfirmModalHelper, vehiclePurchaseHelper, PersonModel, OrganizationModel, installmentsSvc, ContactModelConstants, paymentsSvc, $sce, UserCharacterTypeConstants, ihestiaAgHelper, $state, resolvedNbkCountries, resolvedWithdrawalAttributes, policyOperationsHelper) {

      $scope.UserCharacterTypeConstants = UserCharacterTypeConstants;

      $scope.paymentData = {};
      $scope.countries = resolvedNbkCountries.data;
      angular.forEach($scope.countries, function(country){
        country.phonePrefixDesc = country.phonePrefix + ' ' + country.name;
      });

      $scope.policyOperationsNumericSettings = {
        policyNumber: {
          decimals: 0,
          min: 0,
          allowLeadingZeros: true
        }
      };

      $scope.spinnerOptions = {
        label: '',
        size: ''
      };

      $scope.verifyErrors = [];

      $scope.confirmResponse = null;

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();
      $scope.filesSaved = false;

      $scope.tarrificationDelay = 5000; //5s, ile czekamy przed następnym strzałem sprawdzającym czy mamy składki

      $scope.isSir = false; //czy pracownik Sir
      $scope.ehNumber = null;
      $scope.outerOffice = false; //kancelaria zewnętrzna

      //uploader
      $scope.uploaderName = 'vehicleOwnerChange';
      $scope.fileAttributes = resolvedFileAttributes.data;
      $scope.fileWithdrawalAttributes = resolvedWithdrawalAttributes.data;

      $scope.fileData = {
        fileModels: [],
        fileModelsReady: false, //czy kontrolka uplodera została przygotowana. jak nie to przepisujemy pliki z wniosku
        fileWithdrawalModels: [],
        fileWithdrawalModelsReady: false //czy kontrolka uplodera została przygotowana. jak nie to przepisujemy pliki z wniosku
      };

      $scope.validationFlags = {
        personDataRequired: true
      }; //jeśli centrala zaznacza niektóre braki to pola nie będą wymagane


      $scope.verificationData = {};
      $scope.otherData = { //tu ląduje też donationType
        badRequest: false, //prawdopodobnie złe dane przekazane w linku od Sir
        boxRecord: null,
        goToStepAfterLoad: null, //czy po wczytaniu wniosku przejść do konkretnego widoku tarrification/saleModal
        isGreenCardReturned: false,
        isForward: false, //przejście z pominięciem weryfikacji
        isFileRequired: false,
        saveErrors: [],
        newPolicyId: null, //nowy id polisy po zapisie współwłaściciela
        settlementNotEmpty: false, //czy jest składka i jest różna od zera
        baseOwner: null, //zbywca, czyli sprzedawca z pierwszego rekordu, używamy do prezentacji osoby przy taryfikacji
        hasPolicyData: false, //czy mamy id polisy i pojazdu z podglądu polisy
        verifyInProgress: false,
        backgroundVerifyInProgress: false,
        loadApplicationInProgress: false,
        saleLike: true, //czy ogólnie traktujemy jako sprzedaż czy jako zakup
        //(w ikoncie darowiznę traktujemy jak sprzedaż, a poza ikontem jako zakup)
        payNow: false, // Płacę teraz
        pendingTask: null //odkładamy tu powiązane zadanie z momentu wczytania wniosku, bo po zapisie ono znika, a musimy jeszcze wiedzieć czy jakieś było
      };
      $scope.verificationData.operationType = operationType;

      $scope.VerificationDataModelConstants = VerificationDataModelConstants;

      $scope.multiselectLocalLang = {
        selectAll: $filter('translate')('Public.multiselectSelectAll'),
        selectNone: $filter('translate')('Public.multiselectSelectNone'),
        reset: $filter('translate')('Public.multiselectReset'),
        search: $filter('translate')('Public.multiselectSearch'),
        nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
        noOption: $filter('translate')('Public.multiselectNoOption')
      };

      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.hasUserContext = false; //czy mamy kontekst klienta (jesteśmy w iKoncie)
      $scope.canChangeDate = false; //czy możemy zmieniać datę sprzedaży na pierwszym rekordzie
      $scope.sendEmptyDate = false;

      if($scope.appContextData && $scope.appContextData.customerId)
      {
        $scope.hasUserContext = true;
      }

      //czy ogólnie traktujemy jako sprzedaż czy jako zakup
        //(w ikoncie darowiznę traktujemy jak sprzedaż, a poza ikontem jako zakup)
      if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_PURCHASE ||
        ($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION && !$scope.hasUserContext))
      {
        $scope.otherData.saleLike = false;
      }
      else
      {
        $scope.otherData.saleLike = true;
      }

      // /VehicleSaleShortages //'Lista braków dla sprzedaży pojazdu'

      $scope.operationTypeToShortages = {};
      $scope.operationTypeToShortages[VerificationDataModelConstants.VEHICLE_SALE] = 'VehicleSaleShortages';
      $scope.operationTypeToShortages[VerificationDataModelConstants.VEHICLE_PURCHASE] = 'VehicleSaleShortages';
      $scope.operationTypeToShortages[VerificationDataModelConstants.VEHICLE_DONATION] = 'VehicleDonationShortages';

      //słowniki dla centrali
      $scope.blockingsDict = [];
      $scope.shortagesDict = [];
      if($scope.currentUser.character.type === 'Central')
      {
        ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {
          businessArea: 'dictionaries',
          resource: 'policyOperationSuspensions',
          callback: function(dictionary){
            $scope.blockingsDict = dictionary;
            $scope.blockingsDict.unshift({
              name: '',
              code: null
            });
          }
        });
        ihestiaDictionaryHelper.getDict('policyOperationShortages', {
          businessArea: 'dictionaries',
          resource: 'policyOperationShortages',
          callback: function(dictionary){
            angular.forEach(dictionary, function(shortagesList){
              if(shortagesList.code === $scope.operationTypeToShortages[operationType])
              {
                $scope.shortagesDict = shortagesList.childDictionaries;
              }
            });
          }
        });
      }

      //słowniki dla obcokrajowca
      $scope.nationalities = {
        'PL': {
          code: 'PL',
          description: 'POLSKIE - POLSKA',
          name: 'POLSKIE'
        }
      };
      angular.forEach(resolvedNationalities.data.items, function(v) {
        $scope.nationalities[v.code] = v;
      });

      $scope.identityDocumentTypes = {};
      angular.forEach(resolvedIdentityDocumentTypes.data, function(v) {
        $scope.identityDocumentTypes[v.code] = v;
      });

      $scope.pages = {
        PAGE_VERIFY_POLICY: 'verifyPolicy', //zakup - weryfikacja/szukanie polisy
        PAGE_APPLICATION_DATA: 'applicationData', //strona z danymi kupującego, sprzedającego i załącznikami
        PAGE_DUPLICATE_DATA: 'duplicateData', //strona z danymi kupującego, sprzedającego i załącznikami

        // PAGE_VERIFICATION_DATA: 'verificationData', //zakup - dane sprzedającego
        // PAGE_CLIENT_DATA: 'clientData', //zakup - dane kupującego
        // PAGE_TARIFICATION_DATA: 'tarificationData', //zakup - taryfikacja i załącznik
        PAGE_SUMMARY: 'summary' //koniec
      };

      $scope.roles = {
        SELLER_OWNER: 'SellerOwner',
        SELLER_COOWNER: 'SellerCoowner',
        BUYER_OWNER: 'BuyerOwner',
        BUYER_COOWNER: 'BuyerCoowner'
      };

      $scope.typeToRoles = {
        buyer: [$scope.roles.BUYER_OWNER, $scope.roles.BUYER_COOWNER],
        seller: [$scope.roles.SELLER_OWNER, $scope.roles.SELLER_COOWNER]
      };

      $scope.nextPersonId = -1;

      $scope.pageData = {
        currentPage: $scope.pages.PAGE_VERIFY_POLICY
      };

      /**
       * Dane potrzebne do usługi potwierdzenia poprzedniego właściciela
       * @type {Object}
       */
      $scope.verificationData.operationDate = '';
      $scope.verificationData.policyNumber = '';
      $scope.verificationData.vehicleRegistrationNumber = '';

      $scope.savedVehicleRegistrationNumber = ''; //zapisujemy numer rejestracyjny, bo usługa go nam nie zwraca

      $scope.currentRecordNumber = 0; //iteracja którą edytujemy (dla sprzedaży wielokrotnej może być większa od 0)
      $scope.currentRecord = {};

      if($stateParams.ehNumber)
      {
        $scope.ehNumber = $stateParams.ehNumber;
        $stateParams.forward = '1'; //sir ma lądować na drugim ekranie
        $scope.canChangeDate = true;
        $scope.isSir = true;
      }

      if($stateParams.step)
      {
        $scope.otherData.goToStepAfterLoad = $stateParams.step;
        if($stateParams.record)
        {
          $scope.otherData.boxRecord = $stateParams.record;
        }
      }

      $scope.centralSpecialMode = false;
      if($scope.verificationData.operationType !== $scope.VerificationDataModelConstants.VEHICLE_PURCHASE &&
       (($scope.currentUser.character.type === 'Central' && $scope.hasUserContext) || $scope.isSir))
      {
          //sprzedaż lub darowizna dla centrali
          //tu mamy dodatkowe wrzutki, popupy i box z listą sprzedaży
          $scope.centralSpecialMode = true;
      }

      //jeśli są ładujemy dane dla SIR
      if($stateParams.policyNo)
      {
        $scope.verificationData.policyNumber = $stateParams.policyNo;
      }
      if($stateParams.sellDate)
      {
        $scope.verificationData.operationDate = $stateParams.sellDate;
      }
      else if($stateParams.forward && $stateParams.forward === '1')
      {
        $scope.otherData.isForward = true;
        //jak nie mamy daty, a ma być krok do przodu to ustawiamy datę na teraz i umożliwiamy jej edycję
        $scope.canChangeDate = true;
        if($scope.currentUser.character.type !== 'Central')
        {
          $scope.sendEmptyDate = true;
        }
      }
      if($stateParams.regNumber)
      {
        $scope.verificationData.vehicleRegistrationNumber = $stateParams.regNumber;
      }


      if($stateParams.isGreenCardReturned && $stateParams.isGreenCardReturned === '1')
      {
        $scope.otherData.isGreenCardReturned = true;
      }

      //czy wymagamy taryfikacji
      //todo odblokować jak usługa kiedyś zwróci settlement
      $scope.settlementRequired = true;
      if($scope.currentUser.character.type === 'Central')
      {
        $scope.settlementRequired = false;
      }

      $scope.duplicateData = null; //wniosek duplikatu pobrany z usługi
      $scope.duplicateObject = null; //obiekt duplikatu
      $scope.duplicateId = null; //id wniosku duplikatu

      $scope.init = function()
      {
        $scope.canChangeVehicle = false;
        if($scope.hasUserContext &&
          !$stateParams.policyNo &&
          $scope.verificationData.operationType !== $scope.VerificationDataModelConstants.VEHICLE_PURCHASE)
        //bezkontekstowe wejście do sprzedaży/darowizny w ikoncie
        {
          $scope.canChangeDate = true;
          $scope.canChangeVehicle = true;
          $scope.pageData.currentPage = $scope.pages.PAGE_APPLICATION_DATA;
        }

        if($stateParams.loadApplication)
        {
          $scope.canChangeVehicle = false;
          $scope.canChangeDate = true;
          $scope.otherData.loadApplicationInProgress = true;
          $timeout(function() { //w razie gdyby stateChangeEnd nam schował
            $scope.loadApplication($stateParams.loadApplication);
          }, 0);
        }
        else if($stateParams.forward && $stateParams.forward === '1')
        {
          $scope.otherData.isForward = true;
          $timeout(function() { //w razie gdyby stateChangeEnd nam schował
            $scope.verifyPolicy(false, null, false, true);
          }, 0);
        }
      };

      /**
       * zwraca id dla nowej osoby
       */
      $scope.getNextPersonId = function()
      {
        var id = $scope.nextPersonId;
        $scope.nextPersonId = $scope.nextPersonId - 1;
        return id;
      };

      /**
       * przepisujemy dane które chcemy zachować tworząc nowy wniosek
       */
      $scope.copyApplicationData = function(newVerificationData)
      {
        angular.forEach($scope.verificationData, function(value, key){
          if(angular.isUndefined(newVerificationData[key]))
          {
            newVerificationData[key] = value;
          }
        });

        if(newVerificationData.vehicleOwnerChangeRecords && newVerificationData.vehicleOwnerChangeRecords[0])
        {
          angular.forEach($scope.currentRecord, function(value, key){
            if(angular.isUndefined(newVerificationData.vehicleOwnerChangeRecords[0][key]))
            {
              newVerificationData.vehicleOwnerChangeRecords[0][key] = value;
            }
          });
        }
      };

      /**
       * zapis 1 kroku
       * Weryfikujemy dane poprzedniego właściciela
       * @return {undefined}
       */
      $scope.verifyPolicy = function(preserveApplicationData, successCallback, noBlockUi, showApplication) {
        if(!noBlockUi)
        {
          $scope.$emit('showBlockUi');
        }
        $scope.verifyErrors = [];
        $scope.otherData.saveErrors = [];

        var verificationData = new VerificationDataModel().setData($scope.verificationData).getData();
        if($scope.hasUserContext)
        {
           verificationData.customerId = $scope.appContextData.customerId;
        }

        if(vehiclePurchaseHelper.flags.prependOperation)
        {
          //wejście z duplikatu, wymuszamy ignorowanie duplikatów
          verificationData.prependOperation = true;
          vehiclePurchaseHelper.flags.prependOperation = false;
        }

        $scope.savedVehicleRegistrationNumber = $scope.verificationData.vehicleRegistrationNumber; //zapisujemy numer rejestracyjny, bo usługa go nam nie zwraca

        $scope.otherData.verifyInProgress = true;
        if(noBlockUi)
        {
          $scope.otherData.backgroundVerifyInProgress = true;
        }

        if(verificationData.operationDate === null)
        {
          delete verificationData.operationDate;
          if($scope.sendEmptyDate)
          {
            verificationData.operationDateRequired = false;
          }
        }
        policyOperationsSvc.post('vehicleOwnerChange', verificationData, null, function(res) {
          $scope.otherData.verifyInProgress = false;
          $scope.otherData.backgroundVerifyInProgress = false;
          $scope.$emit('hideBlockUi');
          if(res && res.data){
            var verificationData = res.data;
            if(preserveApplicationData)
            {
              $scope.copyApplicationData(verificationData);
            }
            $scope.verificationData = verificationData;
            $scope.refreshVerificationDataFlags();
            if($scope.otherData.isGreenCardReturned) //mamy z url'a isGreenCardReturned, więc inicjalnie ustawiamy na true
            {
              $scope.verificationData.isGreenCardReturned = true;
              $scope.otherData.isGreenCardReturned = false;
            }

            $scope.refreshCurrentRecord();

            if(!$scope.otherData.saleLike && $scope.settlementRequired)
            {
              //już na pierwszym kroku potrzebujemy taryfikacji żeby pokazać lub nie upload pliku
              $scope.updateOwnerChange(false, null, true);
            }
            else
            {
              $scope.pageData.currentPage = $scope.pages.PAGE_APPLICATION_DATA;
            }

            if(successCallback)
            {
              successCallback(); //leci update wniosku
            }
          }
        }, function(rej) {
          var wasForward = $scope.otherData.isForward;
          if(rej.status === 400 || rej.status === 404)
          {
            $scope.otherData.badRequest = true;
            $scope.otherData.isForward = false;
          }
          if(showApplication && !$scope.otherData.badRequest)
          {
            $scope.pageData.currentPage = $scope.pages.PAGE_APPLICATION_DATA;
          }

          $scope.otherData.verifyInProgress = false;
          $scope.otherData.backgroundVerifyInProgress = false;

          var hideBlockUiOnEnd = true;
          if(rej.status === 404 || rej.status === 400)
          {
            if(rej.data && angular.isArray(rej.data) && rej.data.length > 0)
            {
              var missingOcRisk = false;
              var ocRiskOnAnotherPolicy = false;
              var anotherPolicyNumber = '';
              angular.forEach(rej.data, function(error){
                if(error.code === 'MissingOcRisk')
                {
                  missingOcRisk = true;
                }
                else if(error.code === 'OcRiskOnAnotherPolicy')
                {
                  ocRiskOnAnotherPolicy = true;
                  anotherPolicyNumber = error.object;
                }
              });
              if(missingOcRisk && $scope.otherData.saleLike) //polisa z samym AC, więc przechodzimy na ścieżkę rozwiązania
              {
                var policyTerminationParams = {
                  policyNumber: verificationData.policyNumber
                };
                if(verificationData.operationDate && !wasForward)
                {
                  policyTerminationParams.operationDate = $filter('date')(verificationData.operationDate, 'yyyy-MM-dd');
                }

                $state.go('main.policyTermination' + $filter('ucFirst')(verificationData.operationType), policyTerminationParams);
                hideBlockUiOnEnd = false;
              }
              else if(ocRiskOnAnotherPolicy && $scope.otherData.saleLike)
              {
                var ownerChangeParams = {
                  policyNo: anotherPolicyNumber,
                  regNumber: verificationData.vehicleRegistrationNumber
                };

                if(verificationData.operationDate && !wasForward)
                {
                  ownerChangeParams.sellDate = $filter('date')(verificationData.operationDate, 'yyyy-MM-dd');
                }
                else if($scope.otherData.isForward)
                {
                  ownerChangeParams.forward = '1';
                }

                $state.go($state.current.name, ownerChangeParams, {reload: true});
                hideBlockUiOnEnd = false;
              }
              else
              {
                $scope.otherData.saveErrors = rej.data;
                $scope.verifyErrors = rej.data;
                $scope.parseVerifyErrors();
              }
            }
            else if(rej.status === 400)
            {
              $scope.otherData.saveErrors = [{text: 'Bad Request'}];
              $scope.verifyErrors = [{text: 'Bad Request'}];
            }

            if(hideBlockUiOnEnd)
            {
              $scope.$emit('hideBlockUi');
            }
          }
          else if(rej.status === 409 && rej.data.messages && rej.data.messages[0] && rej.data.messages[0].object)
          {

            //mamy już zapisany wniosek
            $scope.duplicateObject = rej.data;
            $scope.pageData.currentPage = $scope.pages.PAGE_DUPLICATE_DATA;
            $scope.$emit('hideBlockUi');
          }
          else
          {
            $scope.$emit('hideBlockUi');
          }
        },
        {
          allowedStatuses: [409, 404, 400]
        });
      };

      /**
       * mamy na wniosku już osoby, więc upewniamy się, że nextPersonId będzie unikatowe
       * @return {[type]} [description]
       */
      $scope.prepareNextPersonId = function()
      {
        angular.forEach($scope.verificationData.vehicleOwnerChangeRecords, function(record){
          angular.forEach(record.partnerRoles, function(person, personId){
            var personIdNumber = parseInt(personId, 10);
            if(isFinite(personIdNumber) && personIdNumber <= $scope.nextPersonId)
            {
              $scope.nextPersonId = personIdNumber - 1;
            }
          });
        });
      };

      $scope.loadApplication = function(applicationId)
      {
        $scope.otherData.loadApplicationInProgress = true;
        $scope.$emit('showBlockUi');
        policyOperationsSvc.get(applicationId, null, operationType, function(res){

          $scope.otherData.loadApplicationInProgress = false;
          $scope.verificationData = res.data;

          // sort addresses
          angular.forEach($scope.verificationData.persons, function(person){
            clientDataHelper.sortAddresses(person);
          });
          angular.forEach($scope.verificationData.organizations, function(organization){
            clientDataHelper.sortAddresses(organization);
          });

          if($scope.verificationData.pendingTask)
          {
            $scope.otherData.pendingTask = $scope.verificationData.pendingTask;
          }

          $scope.prepareNextPersonId();

          $scope.verificationData.operationType = operationType;
          $scope.refreshVerificationDataFlags();
          if($scope.centralSpecialMode)
          {
            $scope.currentRecordNumber = 0;
          }
          else
          {
            //ustawiamy do edycji ostatni rekord
            $scope.currentRecordNumber = $scope.verificationData.vehicleOwnerChangeRecords.length - 1;
          }

          if($scope.verificationData.pendingTask && angular.isDefined($scope.verificationData.pendingTask.salesRecordIndex) &&
            (!$scope.centralSpecialMode || ($scope.otherData.goToStepAfterLoad && $scope.otherData.goToStepAfterLoad === 'tarrification'))
            )
          {
            //jeśli to pierwszy krok standardowy albo jakikolwiek drugi i usługa wskazuje rekord to go bierzemy
            $scope.currentRecordNumber = $scope.verificationData.pendingTask.salesRecordIndex;
          }


          $scope.refreshCurrentRecord();
          $scope.pageData.currentPage = $scope.pages.PAGE_APPLICATION_DATA;
          $scope.$emit('hideBlockUi');
        }, function(){
          $scope.otherData.loadApplicationInProgress = false;
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * zapewniamy spójność danych w razie gdyby śmieci wróciły z usługi
       * @return {[type]} [description]
       */
      $scope.prepareCurrentRecord = function(currentRecord)
      {
        if(angular.isUndefined(currentRecord))
        {
          currentRecord = $scope.currentRecord;
        }
        if(currentRecord && angular.isUndefined(currentRecord.recipientsAddress))
        {
          currentRecord.recipientsAddress = {};
        }
      };

      /**
       * zmieniły się dane więc odświeżamy stan lokalny
       * parsujemy też dane z usługi
       * @return {[type]} [description]
       */
      $scope.refreshCurrentRecord = function(dataContainer, currentRecord, currentRecordNumber)
      {
        if(angular.isUndefined(dataContainer))
        {
          dataContainer = $scope.otherData;
        }
        if(angular.isUndefined(currentRecordNumber))
        {
          currentRecordNumber = $scope.currentRecordNumber;
        }

        var baseOwnerTab = $scope.getPersonsByRole($scope.roles.SELLER_OWNER, currentRecordNumber);
        if(baseOwnerTab.length > 0)
        {
          dataContainer.baseOwner = baseOwnerTab[0];
        }
        else
        {
          dataContainer.baseOwner = null;
        }

        if(angular.isUndefined(currentRecord))
        {
          $scope.currentRecord = $scope.verificationData.vehicleOwnerChangeRecords[currentRecordNumber];
          currentRecord = $scope.currentRecord;
        }
        $scope.prepareCurrentRecord(currentRecord);
        currentRecord.operationDate = $filter('date')(currentRecord.operationDate, 'yyyy-MM-dd');
        if(currentRecord.withdrawalDate)
        {
          currentRecord.withdrawalDate = $filter('date')(currentRecord.withdrawalDate,'yyyy-MM-dd');
        }

        //do zwrotu - przepisanie osoby
        var ownerTab, owner;
        if(currentRecord.settlement && currentRecord.settlement.overOrUnderPaymentAmount && currentRecord.settlement.overOrUnderPaymentAmount > 0 && !currentRecord.recipient)
        {
          ownerTab = $scope.getPersonsByRole($scope.roles.SELLER_OWNER, currentRecordNumber);
          if(ownerTab.length > 0)
          {
            owner = ownerTab[0];
            //jak nie mamy wpisanej jeszcze osoby
            currentRecord.recipient = clientDataHelper.getPersonName(owner);
          }
        }

        //do zwrotu lub brak taryfikacji - przepisanie numeru konta
        if(!currentRecord.settlement || (currentRecord.settlement.overOrUnderPaymentAmount && currentRecord.settlement.overOrUnderPaymentAmount > 0))
        {
          ownerTab = $scope.getPersonsByRole($scope.roles.SELLER_OWNER, currentRecordNumber);
          if(ownerTab.length > 0)
          {
            owner = ownerTab[0];
            //jak nie mamy wpisanej jeszcze osoby
            var clientAccount = clientDataHelper.getPersonAccount(owner);
            if(!currentRecord.recipientsBankAccountNumber && clientAccount && $scope.otherData.saleLike)
            {
              currentRecord.recipientsBankAccountNumber = clientAccount;
            }
          }
        }

        $scope.refreshShortages(currentRecord);
        $scope.refreshShortageStatus(currentRecord);

      };

      /**
       * odświeżamy wartości braków
       */
      $scope.refreshShortages = function(currentRecord)
      {
        if(angular.isUndefined(currentRecord))
        {
          currentRecord = $scope.currentRecord;
        }

        angular.forEach($scope.shortagesDict, function(value){
          if(value.selected)
          {
            delete value.selected;
          }
        });

        //jeśli są z załadowanego wniosku
        angular.forEach(currentRecord.shortages, function(shortage){
          angular.forEach($scope.shortagesDict, function(value){
            if(value.code === shortage)
            {
              value.selected = true;
            }
          });
        });

        //jeśli ich jeszcze nie zmapowaliśmy
        angular.forEach(currentRecord.selectedShortages, function(selectedShortage){
          angular.forEach($scope.shortagesDict, function(value){
            if(value.code === selectedShortage.code)
            {
              value.selected = true;
            }
          });
        });

        if(!currentRecord.suspension && currentRecord.suspensions && currentRecord.suspensions[0])
        {
          currentRecord.suspension = currentRecord.suspensions[0];
        }
        else if(!currentRecord.suspension)
        {
          currentRecord.suspension = null;
        }
      };

      $scope.newRecordForModal = function()
      {
        //w rzeczywistości ustawiamy całą stronę na nowy rekord, ale pokazdujemy go w modalu

        $scope.prepareNextRecord();

        //ustawiamy znacznik na ostatni rekord
        $scope.setCurrentRecord($scope.verificationData.vehicleOwnerChangeRecords.length - 1);
      };

      /**
       * składamy dane przed zapisem
       * przekładamy z naszych na usługę
       * @return {[type]} [description]
       */
      $scope.getApplicationData = function()
      {
        var data = angular.copy($scope.verificationData);
        if(data.settlement)
        {
          delete data.settlement;
        }

        ihestiaModelHelper.minifyRestObject(data, false, true);
        angular.forEach(data.vehicleOwnerChangeRecords, function(changeRecord){
          changeRecord.operationDate = $filter('date')(changeRecord.operationDate,'yyyy-MM-ddTHH:mm:ss');
          if(changeRecord.withdrawalDate)
          {
            changeRecord.withdrawalDate = $filter('date')(changeRecord.withdrawalDate,'yyyy-MM-ddTHH:mm:ss');
          }
          if(angular.isDefined(changeRecord.selectedShortages))
          {
            changeRecord.shortages = [];
            angular.forEach(changeRecord.selectedShortages, function(selectedShortage){
              changeRecord.shortages.push(selectedShortage.code);
            });
            delete changeRecord.selectedShortages;
          }
          changeRecord.suspensions = [];
          if(angular.isDefined(changeRecord.suspension) || angular.isDefined(changeRecord.suspensions))
          {
            if(changeRecord.suspension)
            {
              changeRecord.suspensions.push(changeRecord.suspension);
            }
            delete changeRecord.suspension;
          }
        });

        if($scope.ehNumber)
        {
          data.ehNumber = $scope.ehNumber;
        }

        var files = []; //tu wrzucamy idki plików
        //dodajemy, jeśli są, pliki
        if($scope.fileData.fileModelsReady)
        {
          angular.forEach($scope.fileData.fileModels, function(fileObject){
            files.push({
              name: fileObject.name,
              size: fileObject.size,
              fileServerId: fileObject.fileServerId,
              fileType: $scope.fileAttributes.code
            });
          });
        }
        else
        {
          //jak nie było uploadu to przepisujemy pliki z wniosku
          angular.forEach(data.files, function(file){
            if(file.fileType !== 'PolicyTermination')
            {
              files.push(angular.copy(file));
            }
          });
        }

        //dodajemy, jeśli są, pliki wypowiedzenia
        if($scope.fileData.fileWithdrawalModelsReady)
        {
          angular.forEach($scope.fileData.fileWithdrawalModels, function(fileObject){
            files.push({
              name: fileObject.name,
              size: fileObject.size,
              fileServerId: fileObject.fileServerId,
              fileType: 'PolicyTermination'
            });
          });
        }
        else
        {
          //jak nie było uploadu to przepisujemy pliki z wniosku
          angular.forEach(data.files, function(file){
            if(file.fileType === 'PolicyTermination')
            {
              files.push(angular.copy(file));
            }
          });
        }

        data.files = files;

        return data;
      };

      $scope.shouldWeWaitForSettlement = function()
      {
        return (($scope.settlementRequired || ($scope.shouldWeShowOrganizationTarrification() && !$scope.isSir)) &&
          angular.isUndefined($scope.verificationData.settlement)) ? true : false;
      };

      /**
       * czy na wniosku jest wypowiedzenie
       * @return {Boolean}
       */
      $scope.isWithdrawal = function()
      {
        var isWithdrawal = false;
        if($scope.verificationData && $scope.verificationData.vehicleOwnerChangeRecords)
        {
          isWithdrawal = $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1].buyerWithdrawal;
        }
        return isWithdrawal;
      };

      /**
       * czy powinniśmy pokazać ekran potwierdzenia przed zatwierdzeniem operacji
       * @return {bool}
       */
      $scope.shouldWeShowConfirmation = function()
      {
        return(!$scope.isWithdrawal() &&
          !$scope.isBuyerOrganization() &&
          !$scope.otherData.saleLike &&
          !$scope.otherData.pendingTask &&
          ($scope.currentUser.character.type === UserCharacterTypeConstants.CHARACTER_TYPE_WORKER || $scope.currentUser.character.type === UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER)) ? true : false;
      };

      /**
       * przechodzimy na ekran podsumowania
       */
      $scope.goToConfirmation = function()
      {
        $state.go('main.vehicleOperationConfirmation', {applicationId: $scope.verificationData.metaData.id});
      };

      /**
       * zapis 2 kroku
       * @return {[type]} [description]
       */
      $scope.updateOwnerChange = function(confirm, successCallback, settlementWillBeShowed, forceTarrification)
      {
        if(!confirm && settlementWillBeShowed && ($scope.shouldWeWaitForSettlement() || forceTarrification))
        {
          $scope.$emit('showBlockUi');
        }
        else
        {
          $scope.$emit('showBlockUi', {
            label: 'Trwa zapisywanie'
          });
        }
        var hideBlockUi = true;

        var data = $scope.getApplicationData();
        data.payNow = $scope.otherData.payNow;

        policyOperationsSvc.put('vehicleOwnerChange/' + data.metaData.id, data, null, function(res) {
          if(res && res.data)
          {
            $scope.verificationData = res.data;
            $scope.verificationData.operationType = operationType;
            $scope.refreshVerificationDataFlags();
            $scope.refreshCurrentRecord();
            if(confirm)
            {
              hideBlockUi = false;
              $scope.confirmOwnerChange(successCallback);
            }
            if(!confirm && $scope.verificationData.settlement && $scope.pageData.currentPage !== $scope.pages.PAGE_APPLICATION_DATA)
            {
              //jeśli przy weryfikacji już potrzebujemy taryfikacji to dopiero jak ją mamy przechodzimy na pierwszy krok
              $scope.pageData.currentPage = $scope.pages.PAGE_APPLICATION_DATA;
            }
          }

          if(!confirm && settlementWillBeShowed && $scope.shouldWeWaitForSettlement())
          {
            hideBlockUi = false;
            $scope.$emit('showBlockUi');
            //nie mamy taryfikacji, a nie możemy bez niej przejść dalej, więc jeszcze raz strzelamy
            $timeout(function(){
              $scope.updateOwnerChange(confirm, successCallback, settlementWillBeShowed);
            }, $scope.tarrificationDelay);
          }
          else if(successCallback)
          {
            successCallback(); //kolejny krok
          }

          if(hideBlockUi)
          {
            $scope.$emit('hideBlockUi');
          }

        }, function(rej) {
          if(rej.data && rej.data.messages)
          {
            $scope.otherData.saveErrors = rej.data.messages;
            $scope.$broadcast('scrollToError');
          }
          else if(rej.data && angular.isArray(rej.data))
          {
            $scope.otherData.saveErrors = rej.data;
            $scope.$broadcast('scrollToError');
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409, 404]
        });
      };

      /**
       * na zakończenie wysyłki plików
       */
      $scope.onAllFileSend = function(data) {
        if (data.withoutError) {
          angular.forEach(data.files, function(fileModel) {
            var attachment = {};
            attachment.name = fileModel.name;
            attachment.size = fileModel.size;
            attachment.fileServerId = fileModel.fileServerId;
            $scope.verificationData.files.push(attachment);
          });
          $scope.updateOwnerChange(true);
        }else{
          $scope.$emit('hideBlockUi');
        }
      };

      // $scope.onErrorSendingFile = function(e, data, message) {
      $scope.onErrorSendingFile = function() {
        $scope.$emit('hideBlockUi');
      };

      /**
       * zatwierdzenie wniosku OC nabywcy
       */
      $scope.confirmOwnerChange = function(successCallback)
      {
        $scope.$emit('showBlockUi', {
          label: 'Trwa zatwierdzanie'
        });

        var data = $scope.getApplicationData();
        policyOperationsSvc.post('vehicleOwnerChange', null, data.metaData.id + '/confirmation', function(response) {
          if($scope.shouldWeShowConfirmation())
          {
            $scope.goToConfirmation();
          }
          else
          {
            $scope.confirmResponse = response.data;
            if($scope.otherData.payNow && $scope.currentUser.character.type === 'Client')
            {
              $scope.paySettlement();
            }
            else
            {
              $scope.pageData.currentPage = $scope.pages.PAGE_SUMMARY;
              $scope.$emit('hideBlockUi');
            }
            if(successCallback)
            {
              successCallback();
            }
          }
        }, function(rej) {
          if(rej.status === 409 && rej.data && rej.data.messages)
          {
            $scope.otherData.saveErrors = rej.data.messages;
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409]
        });
      };

      /**
       * Czyścimy osoby z wniosku z obecnej iteracji
       * @param  {string} type buyer/owner czyścimy tylko wybranego typu
       */
      $scope.clearPersons = function(type, dontDeletePerson)
      {
        angular.forEach($scope.currentRecord.partnerRoles, function(partnerRole, partnerId){
          if($scope.typeToRoles[type].indexOf(partnerRole) !== -1)
          {
            delete $scope.currentRecord.partnerRoles[partnerId];
            if(!dontDeletePerson)
            {
              //usuwamy z listy osób
              $scope.deletePersonById(partnerId);
            }
          }
        });
      };

      /**
       * usuwamy osobę z wniosku
       */
      $scope.deletePersonById = function(partnerId, force)
      {
        // force ustawiamy z dodawania nowej osoby
        // bo był problem  z kupującym zgłaszającym z ikonta gdy duplikowaliśmy osoby
        // domyślnie usuwamy osoby z ujemnym id, których nie ma na liście partnersFromPolicy, czyli osoby dodane na GUI
        if((partnerId <= 0 && $scope.verificationData.partnersFromPolicy.indexOf(partnerId) === -1) || force)
        {
          angular.forEach($scope.verificationData.persons, function(person, personKey){
            if(person.metaData.id === partnerId)
            {
              $scope.verificationData.persons.splice(personKey, 1);
            }
          });

          angular.forEach($scope.verificationData.organizations, function(organization, organizationKey){
            if(organization.metaData.id === partnerId)
            {
              $scope.verificationData.organizations.splice(organizationKey, 1);
            }
          });
        }
      };

      /**
       * zwraca osoby z obeznego rekordu o podanej roli
       * @param  {[type]} role [description]
       * @return {array}
       */
      $scope.getPersonsByRole = function(role, recordNumber)
      {
        var record;
        if(angular.isUndefined(recordNumber))
        {
          record = $scope.currentRecord;
        }
        else
        {
          record = $scope.verificationData.vehicleOwnerChangeRecords[recordNumber];
        }

        var persons = [];
        angular.forEach(record.partnerRoles, function(personRole, personId){
          if(personRole === role)
          {
            persons.push($scope.getPersonById(personId));
          }
        });

        return persons;
      };

      /**
       * zwraca osobę z wniosku o podanym id
       * @param  {string'int} personId
       * @return {Object}
       */
      $scope.getPersonById = function(personId)
      {
        var clientFound = null;
        angular.forEach($scope.verificationData.persons, function(person){
          if(person.metaData.id == personId) //eslint-disable-line
          {
            clientFound = person;
          }
        });
        angular.forEach($scope.verificationData.organizations, function(organization){
          if(organization.metaData.id == personId) //eslint-disable-line
          {
            clientFound = organization;
          }
        });
        if(clientFound === null)
        {
          ihestiaCommonErrorHandler.throwException('Nie znaleziono osoby/orgaznizacji o podanym id ' + personId);
        }
        return clientFound;
      };

      /**
       * wrzucamy osobę na wiosek i dokładamy rolę
       * @param {object} personData [description]
       * @param {[type]} role       [description]
       */
      $scope.addPerson = function(personData, role, dontSavePerson)
      {
        if(!personData.metaData.id)
        {
          personData.metaData.id = $scope.getNextPersonId();
        }
        //jeśli już jest osoba o takim id to wywalamy i zapisujemy aktualną
        if(!dontSavePerson)
        {
          $scope.deletePersonById(personData.metaData.id, true);
        }

        personData = angular.copy(personData);
        var clientType = 'person';
        if(angular.isDefined(personData.clientType))
        {
          clientType = personData.clientType;
          delete personData.clientType;
        }

        //sprawdzamy adres korespondencyjny
        var canSavePersonAddress = false;
        if(clientType === 'person' &&
            (!$scope.otherData.saleLike ||
              // centrala na sprzedaży może podać dla kupującego
              ($scope.currentUser.character.type === 'Central' && $scope.typeToRoles.seller.indexOf(role) === -1)
            )
          )
        {
          canSavePersonAddress = true;
        }
        if(personData.noSecondAddr || !canSavePersonAddress)
        {
          //czyścimy adres korespondencyjny
          clientDataHelper.clearSecondAddress(personData);
        }
        else
        {
          clientDataHelper.clearSecondAddressIfEmpty(personData);
        }
        if(angular.isDefined(personData.noSecondAddr))
        {
          delete personData.noSecondAddr;
        }

        if(clientType === 'person')
        {
          personData = new PersonModel().setData(personData).getData();
        }
        else
        {
          personData = new OrganizationModel().setData(personData).getData();
        }

        if(!dontSavePerson)
        {
          // jeśli to osoba o id nieujemny to znaczy, że jest z wniosku i w rzeczywistości jej nie usuwaliśmy i nie edytowaliśmy
          if(clientType === 'person')
          {
            if(angular.isUndefined($scope.verificationData.persons))
            {
              $scope.verificationData.persons = [];
            }
            $scope.verificationData.persons.push(personData);
          }
          else
          {
            if(angular.isUndefined($scope.verificationData.organizations))
            {
              $scope.verificationData.organizations = [];
            }
            $scope.verificationData.organizations.push(personData);
          }


          if(angular.isUndefined($scope.currentRecord.partnerRoles))
          {
            $scope.currentRecord.partnerRoles = {};
          }
          $scope.currentRecord.partnerRoles[personData.metaData.id] = role;
        }

        return personData;
      };

      $scope.goToParent = function()
      {
        policyOperationsHelper.end($scope.verificationData.policyNumber);
      };

      /**
       * tworzymy rekord dla kolejnej sprzedaży
       */
      $scope.prepareNextRecord = function()
      {
        var lastRecord = $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1];

        var newRecord = new VehicleOwnerChangeRecordModel().getData();
        newRecord.currentRegistrationNumber = '';
        newRecord.partnerRoles = {};
        newRecord.operationDate = '';
        angular.forEach(lastRecord.partnerRoles, function(roleCode, clientId){
          if(roleCode === $scope.roles.BUYER_OWNER || roleCode === $scope.roles.BUYER_COOWNER) //kupujących z poprzedniego rekordu traktujemy jako sprzedających w następnym
          {
            var newRole;
            if(roleCode === $scope.roles.BUYER_OWNER)
            {
              newRole = $scope.roles.SELLER_OWNER;
            }
            if(roleCode === $scope.roles.BUYER_COOWNER)
            {
              newRole = $scope.roles.SELLER_COOWNER;
            }
            newRecord.partnerRoles[clientId] = newRole;
          }
        });
        $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length] = newRecord;
      };

      /**
       * ustawiamy typ dokumentu i atrybuty do plików
       */
      $scope.updateFilesAttributes = function() {
        var buyer = $scope.getBuyer();
        var buyerMainAddress = clientDataHelper.getClientMainAddress(buyer);
        var isPerson;

        if(buyer.firstName) //zakładamy, że jak ma imię to to osoba
        {
          isPerson = true;
        }
        var lastRecord = $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1];


        angular.forEach($scope.fileAttributes.attributes, function(attribute){
          switch (attribute.code) {
            case 'PolicyNumber':
              attribute.val = $scope.verificationData.policyNumber;
              break;
            case 'ReceiveDate':
              attribute.val = $filter('date')($scope.today,'yyyy-MM-ddTHH:mm:ss');
              break;
            case 'RegistrationNumber':
              attribute.val = $scope.verificationData.vehicleRegistrationNumber; //todo powinien być na wniosku
              break;
            case 'Nip':
              if(!isPerson)
              {
                attribute.val = buyer.nip;
              }
              break;
            case 'Regon':
              if(!isPerson)
              {
                attribute.val = buyer.regon;
              }
              break;
            case 'CompanyName':
              if(!isPerson)
              {
                attribute.val = buyer.name;
              }
              break;
            case 'ClientFirstName':
              if(isPerson)
              {
                attribute.val = buyer.firstName;
              }
              break;
            case 'ClientLastName':
              if(isPerson)
              {
                attribute.val = buyer.lastName;
              }
              break;
            case 'Pesel':
              if(isPerson)
              {
                attribute.val = buyer.pesel;
              }
              break;
            case 'Gender':
              if(isPerson)
              {
                if(buyer.gender === 'M')
                {
                  attribute.val = 'Man';
                }
                else if(buyer.gender === 'F')
                {
                  attribute.val = 'Woman';
                }
              }
              break;
            case 'DateSell':
              attribute.val = lastRecord.operationDate;
              break;
            case 'ZipCode':
              attribute.val = buyerMainAddress.postalCode;
              break;
            case 'City':
              attribute.val = buyerMainAddress.city;
              break;
            case 'Street':
              attribute.val = buyerMainAddress.street;
              break;
            case 'BuildingNumber':
              attribute.val = buyerMainAddress.house;
              break;
            case 'ApartmentNumber':
              attribute.val = buyerMainAddress.apartment;
              break;
            case 'AccountNumber':
              if(!$scope.otherData.saleLike &&
                  $scope.verificationData.settlement &&
                 $scope.verificationData.settlement.overOrUnderPaymentAmount < 0)
              {
                attribute.val = lastRecord.recipientsBankAccountNumber;
              }
              break;
            default:
              break;
          }
        });
      };

      /**
       * kupujący z ostatniego rekordu
       * @return {object}
       */
      $scope.getBuyer = function()
      {
        return $scope.getPersonsByRole($scope.roles.BUYER_OWNER, $scope.verificationData.vehicleOwnerChangeRecords.length - 1)[0];
      };

      /**
       * mapujemy selectedShortages na shortages
       */
      $scope.mapShortages = function(currentRecord)
      {
        if(angular.isUndefined(currentRecord))
        {
          currentRecord = $scope.currentRecord;
        }
        if(angular.isDefined(currentRecord.selectedShortages))
        {
          currentRecord.shortages = [];
          angular.forEach(currentRecord.selectedShortages, function(selectedShortage){
            currentRecord.shortages.push(selectedShortage.code);
          });
        }
      };

      /**
       * odświeżamy flagę wymagalności danych w zależności od braków
       */
      $scope.refreshShortageStatus = function(currentRecord)
      {
        if(angular.isUndefined(currentRecord))
        {
          currentRecord = $scope.currentRecord;
        }

        var personDataShortage = false;
        if(angular.isDefined(currentRecord.selectedShortages))
        {
          angular.forEach(currentRecord.selectedShortages, function(shortage){
            if(shortage.code === 'UnreadableData' || shortage.code === 'IncompleteData')
            {
              personDataShortage = true;
            }
          });
        }
        else if(currentRecord.shortages)
        {
          angular.forEach(currentRecord.shortages, function(shortage){
            if(shortage === 'UnreadableData' || shortage === 'IncompleteData')
            {
              personDataShortage = true;
            }
          });
        }
        $scope.validationFlags.personDataRequired = !personDataShortage;
      };

      /**
       * usunięcie ostatnie rekordu (zrobione dla centrali i boxu)
       */
      $scope.deleteLastRecord = function()
      {
        $scope.verificationData.vehicleOwnerChangeRecords.splice($scope.verificationData.vehicleOwnerChangeRecords.length - 1, 1);
        $scope.setCurrentRecord(0);
      };

      /**
       * ustawia obecny rekord (tak dla niepoznaki nazwa też to sugeruje ;) )
       * @param {[type]} recordNumber [description]
       */
      $scope.setCurrentRecord = function(recordNumber)
      {
        $scope.currentRecordNumber = recordNumber;
        $scope.currentRecord = $scope.verificationData.vehicleOwnerChangeRecords[recordNumber];
        $scope.prepareCurrentRecord();
        $scope.refreshShortages();
        $scope.refreshShortageStatus();
      };

      /**
       * jeśli mamy wniosek to go usuwamy
       */
      $scope.deleteApplication = function()
      {
        if($scope.verificationData.metaData && $scope.verificationData.metaData.id)
        {
          policyOperationsSvc.remove($scope.verificationData.metaData.id);
          //nie czekamy na pomyślne zakończenie operacji
        }
      };

      /**
       * darowizna częściowa czyli zapis nowego współwłaściciela
       * @param  {[type]} clientData [description]
       * @return {[type]}            [description]
       */
      $scope.saveNewCoowner = function(clientData, vehicleData)
      {
        var saveData = {}; // NOSONAR

        // w przypadku gdy darowizna jest na rzecz osoby prawnej
        if(clientData.clientType === 'organization') {
          var organizationData = angular.copy(clientData);
          organizationData.metaData.className = 'Organization';

          saveData = {
            additionType: 'PartialDonation',
            operationDate: $scope.currentRecord.operationDate,
            organization: $scope.addPerson(organizationData, $scope.roles.BUYER_OWNER, true) //przygotowujemy dane do zapisu
          };
        } else {
          //darowizna na osobę fizyczną
          saveData = {
            // note: $scope.note,
            additionType: 'PartialDonation',
            operationDate: $scope.currentRecord.operationDate,
            person: $scope.addPerson(clientData, $scope.roles.BUYER_OWNER, true) //przygotowujemy dane do zapisu
          };
        }

        $scope.$emit('showBlockUi', {
          label: 'Trwa zapisywanie'
        });
        $scope.otherData.saveErrors = [];

        var policyId, objectId;
        if(vehicleData !== null)
        {
          //dane z selecta pojazdów
          policyId = vehicleData.policyId;
          objectId = vehicleData.objectId;
        }
        else if(vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber])
        {
          //dane z przejścia z polisy
          policyId = vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber].policyId;
          objectId = vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber].vehicleId;
        }
        else
        {
          ihestiaCommonErrorHandler.throwException('Brak id polisy i pojazdu');
        }

        policiesSvc.post(policyId, saveData, 'objects/' + objectId + '/partners', function(response){
          $scope.otherData.newPolicyId = response.data; // response to sam int
          $scope.deleteApplication();
          if(vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber])
          {
            vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber].policyId = $scope.otherData.newPolicyId;
          }
          $scope.$emit('hideBlockUi');
          $scope.pageData.currentPage = $scope.pages.PAGE_SUMMARY;
        }, function(reject){
          if(reject.data && reject.data.messages)
          {
            $scope.otherData.saveErrors = reject.data.messages;
          }
          $scope.$emit('hideBlockUi');
        },
        {
          allowedStatuses: [409]
        });

      };

      $scope.getSettlementRecordNumber = function()
      {
        var settlementRecord = $scope.verificationData.vehicleOwnerChangeRecords.length - 1; //ostatni
        if($scope.verificationData.pendingTask && angular.isDefined($scope.verificationData.pendingTask.salesRecordIndex))
        {
          settlementRecord = $scope.verificationData.pendingTask.salesRecordIndex;
        }
        return settlementRecord;
      };

      /**
       * mamy nowe dane wniosku z usługi, więc wszystko ustawiamy
       * @return {[type]} [description]
       */
      $scope.refreshVerificationDataFlags = function()
      {
        if($scope.verificationData.isVerificationRequired) //flaga z usługi informująca o tym, że nie jest wymagana składka, weryfikacja będzie później
        {
          $scope.settlementRequired = false;
        }

        //ustawiamy settlement dla zachowania zgodności wstecz
        if($scope.verificationData.vehicleOwnerChangeRecords && $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1] && $scope.verificationData.vehicleOwnerChangeRecords[$scope.verificationData.vehicleOwnerChangeRecords.length - 1].settlement)
        {
          $scope.verificationData.settlement = $scope.verificationData.vehicleOwnerChangeRecords[$scope.getSettlementRecordNumber()].settlement;
        }

        if($scope.verificationData.externalDebtCollection)
        {
          $scope.outerOffice = true;
        }
        else
        {
          $scope.outerOffice = false;
        }
        if($scope.verificationData.settlement && $scope.verificationData.settlement.isFileRequired)
        {
          $scope.otherData.isFileRequired = true;
        }

        $scope.otherData.settlementNotEmpty = false;
        if($scope.verificationData.settlement &&
            ($scope.verificationData.settlement.overOrUnderPaymentAmount > 0 ||
            $scope.verificationData.settlement.overOrUnderPaymentAmount < 0)
          )
        {
          $scope.otherData.settlementNotEmpty = true;
        }
        $scope.checkPolicyData();
      };

      /**
       * sprawdzamy czy nie ma błędu z linkiem
       */
      $scope.parseVerifyErrors = function(){
        angular.forEach($scope.verifyErrors, function(error){
          if(error.text.indexOf('{') !== -1)
          {
            error.type = 'link';
            error.preText = error.text.substr(0, error.text.indexOf('{'));
            error.linkText = error.text.substr(error.text.indexOf('{') + 1, error.text.indexOf('}') - error.text.indexOf('{') - 1);
            error.postText = error.text.substr(error.text.indexOf('}') + 1);
          }
        });

      };

      /**
       * próba dodania kolejnej sprzedaży jak mamy wypowiedzenie
       */
      $scope.showNextSaleBuyerWithdrawalConflictModal = function()
      {
        var titleString = 'Kolejna sprzedaż';
        var textString = 'kolejnej sprzedaży';
        if($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION)
        {
          titleString = 'Kolejna darowizna';
          textString = 'kolejnej darowizny';
        }

        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: titleString,
          okBtnName: $filter('translate')('Public.close'),
          cancelBtnName: '',
          text: 'Zaznaczono wypowiedzenie nabywcy pojazdu – brak możliwości dodania ' + textString + '.',
          size: 'sm',
          okBtnCallback: lsnNg.noop
        });
      };

      /**
       * sprawdzamy czy mamy w helperze odłożone dane dla tej polisy
       * @return {[type]} [description]
       */
      $scope.checkPolicyData = function()
      {
        if(vehiclePurchaseHelper.policyData[$scope.verificationData.policyNumber])
        {
          $scope.otherData.hasPolicyData = true;
        }
        else
        {
          $scope.otherData.hasPolicyData = false;
        }
      };

      /**
       * Czy pokazujemy taryfikację dla klienta biznesowego
       * @return {[type]} [description]
       */
      $scope.shouldWeShowOrganizationTarrification = function()
      {
        var shouldWe = false;
        //ekran taryfikacji organizacji tylko dla zakupu i darowizny poza ikontem
        if($scope.isBuyerOrganization() &&
          $scope.verificationData.operationType !== $scope.VerificationDataModelConstants.VEHICLE_SALE &&
          !$scope.hasUserContext)
        {
          shouldWe = true;
        }

        return shouldWe;
      };

      /**
       * czy kupujący to organizacja
       * @return {Boolean}
       */
      $scope.isBuyerOrganization = function()
      {
        var isOrganization = false;
        var buyerTab = $scope.getPersonsByRole($scope.roles.BUYER_OWNER);
        if(buyerTab.length > 0 )
        {
          var buyer = buyerTab[0];
          if(buyer.name)
          {
            isOrganization = true;
          }
        }
        return isOrganization;
      };

      /**
       * Opłacenie przez klienta zaległości
       */
      $scope.paySettlement = function()
      {
        var toPay = -$scope.verificationData.settlement.overOrUnderPaymentAmount;
        //pobieramy płatności dla polisy

        var dynamicFilters = {
          policyNumberFrom: $scope.verificationData.policyNumber,
          policyNumberTo: $scope.verificationData.policyNumber
        };

        installmentsSvc.findByParams({
          id: $scope.appContextData.customerId,
          dynamicFilters: dynamicFilters,
          callback: function(response) {
            if(response.data.items.length === 0)
            {
              ihestiaCommonErrorHandler.throwException('Usługa nie zwróciła listy płatności dla polisy ' + $scope.verificationData.policyNumber);
            }
            else if(response.data.items.length > 1)
            {
              ihestiaCommonErrorHandler.throwException('Usługa zwróciła wiele płatności dla polisy ' + $scope.verificationData.policyNumber);
            }
            else
            {
              $scope.preparePayments(response.data.items, toPay);
            }
          },
          errorCallback: function() {
            $scope.$emit('hideBlockUi');
          }
        });
      };

      $scope.preparePayments = function(payments, toPay)
      {
        var payment = payments[0];

        //przygotowujemy raty
        angular.forEach(payment.installments, function(installment) {
          if (installment.paidSum[0] >= installment.paySum[0]) {
            installment.fullyPaid = true;
          }
          installment.toPay = [installment.paySum[0] - installment.paidSum[0], installment.paySum[1]];
        });

        var email = null;
        angular.forEach($scope.sharedCustomerData.contacts, function(contact) {
          if (contact.code === ContactModelConstants.CONTACT_TYPE_MAIL) {
            email = contact.value;
          }
        });

        //zbieramy raty do wymaganej kwoty
        var installmentsToPay = [];
        angular.forEach(payment.installments, function(installment) {
          if(toPay > 0)
          {
            var payByInstallment; //ile z tej raty chcemy spłacić
            if(toPay >= installment.toPay[0]) //bierzemy całą ratę
            {
              toPay = toPay - installment.toPay[0];
              payByInstallment = installment.toPay[0];
            }
            else //bierzemy tylko część z raty
            {
              payByInstallment = toPay;
              toPay = 0;
            }

            installmentsToPay.push({
              number: installment.number,
              paymentDate: installment.paymentTerm,
              premium: [payByInstallment, installment.toPay[1]]
            });
          }
        });

        //przygotowujemy płatności
        var policyPayment = {
          policy: payment.policyNumber,
          installments: installmentsToPay
        };

        //zapisujemy płatność do ateny
        var paymentData = {
          policies: [policyPayment],
          customerFirstName: $scope.sharedCustomerData.firstName,
          customerLastName: $scope.sharedCustomerData.lastName,
          customerEmail: email,
          customerId: $scope.appContextData.customerId,
          paymentType: 1,
          paymentMessages: $scope.confirmResponse[0].text
        };
        //         Wartosci dla PaymentType:
        // CustomerPolicyInstallment = 0,
        // CustomerPolicyUnderpayment = 1

        $scope.paymentData.paymentParams = {};
        $scope.paymentData.paymentUrl = '';

        paymentsSvc.post(null, paymentData, 'epayments', function(response) {
          //przekierowujemy do systemu płatności
          $scope.paymentData.paymentUrl = $sce.trustAsResourceUrl(response.data.config.paymentServiceUrl);
          angular.forEach(response.data.paymentForms, function(value, key) {
            $scope.paymentData.paymentParams[$filter('ucFirst')(key)] = value;
          });
          $scope.$broadcast('manualFormSubmit');
        }, function() {
          $scope.$emit('hideBlockUi');
        });
      };

      $scope.init();

    }]
);