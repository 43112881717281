angular.module('ihestiaRestServicesBase')
  .service('oauthSvc', ['$http', '$window', 'simpleOAuthDataHelper', 'ihestiaRestCommonHelper', 'ihestiaConfigHelper', 'simpleOAuthConfig', 'ihestiaSsoBaseInfoHelper',
    function($http, $window, simpleOAuthDataHelper, ihestiaRestCommonHelper, ihestiaConfigHelper, simpleOAuthConfig, ihestiaSsoBaseInfoHelper) {

      var self = this;

      /**
       * host do oauth, appConfigHelp jest definiowany w bootstrapie aplikacji, musimy go mieć do logowania jeszcze przed pobraniem konfiga
       * @type {String}
       */
      if(angular.isDefined(window.appConfigHelper)) //eslint-disable-line no-undef
      {
        this.oauthTokenHost = appConfigHelper.oauthTokenHost; //eslint-disable-line no-undef
      }

      /**
       * [logout description]
       * @return {undefined}
       */
      this.logout = function() {
        return simpleOAuthDataHelper.logout();
      };

      /**
       * [isLogged description]
       * @return {Boolean} [description]
       */
      this.isLogged = function() {
        return simpleOAuthDataHelper.isLogged();
      };

      /**
       * [getAccessToken description]
       * @return {String}
       */
      this.getAccessToken = function() {
        return simpleOAuthDataHelper.getAccessToken();
      };

      this.refreshToken = function()
      {
        var request = {
          grant_type: 'refresh_token', //eslint-disable-line camelcase
          client_id: simpleOAuthConfig.clientId, //eslint-disable-line camelcase
          refresh_token: simpleOAuthDataHelper.getRefreshToken() //eslint-disable-line camelcase
        };

        // odświeżanie charakteru
        var currentUser = ihestiaSsoBaseInfoHelper.getCurrentUser();
        if(currentUser && currentUser.character) {
          request.character_code = currentUser.character.code; // eslint-disable-line camelcase
        }

        var headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        if(ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') && ihestiaConfigHelper.get('sso', 'userInfo') && ihestiaConfigHelper.get('sso', 'userInfo').login && ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') === ihestiaConfigHelper.get('sso', 'userInfo').login)
        {
          headers['AP-Demo-User'] = 'true';
        }

        var data = ihestiaRestCommonHelper.objectToFormString(request);

        return $http({
          method: 'post',
          url: self.oauthTokenHost + '/connect/token',
          data: data,
          allowedStatuses: [401],
          headers: headers
        }).then(function(res){
          if(res && res.status === 200) {
            simpleOAuthDataHelper
              .setAccessToken(res.data.access_token)
              .setRefreshToken(res.data.refresh_token);
          }
          return res;
        }, lsnNg.noop);
      };

      /**
       * Nakładka na login, coby nie trzeba było podawać miliona parametrów
       * @param  {Object} params [description]
       * @return {Promise}
       */
      this.loginByParams = function(params) {
        return self.login(params.username, params.password, params.persistentToken, params);
      };

      /**
       * [login description]
       * @param  {String} username [description]
       * @param  {String} password [description]
       * @return {Promise}
       */
      this.login = function(username, password, persistentToken, otherParams) {
        otherParams = otherParams ? otherParams : {};
        var request = {
          grant_type: 'password', //eslint-disable-line camelcase
          client_id: simpleOAuthConfig.clientId, //eslint-disable-line camelcase
          // client_secret: 'secret', //eslint-disable-line camelcase
          username: username,
          password: password,
          user_type: 'Client' //eslint-disable-line camelcase
        };

        angular.extend(request, otherParams);
        delete request.persistentToken;

        var headers = {
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        if(ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') && ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') === username)
        {
          headers['AP-Demo-User'] = 'true';
        }

        var data = ihestiaRestCommonHelper.objectToFormString(request);

        return $http({
          method: 'post',
          url: self.oauthTokenHost + '/connect/token',
          data: data,
          allowedStatuses: [401],
          headers: headers
        }).then(function(res){
          if(res && res.status === 200) {
            simpleOAuthDataHelper
              .setAccessToken(res.data.access_token, persistentToken, username)
              .setRefreshToken(res.data.refresh_token, persistentToken);
          }
          return res;
        }, lsnNg.noop);
      };
    }]);