angular.module('lsnBase.tryEscapeHtml')
  /**
   * próba "eskejpowania" znaczników html ze stringa
   * @param  {String} value
   * @return {String}
   */
  .factory('lsnTryEscapeHtml', function() {
    var tryEscapeHtml = function(value) {
      if (angular.isString(value))
      {
        return value.escapeHtml();
      }
      return value;
    };
    return tryEscapeHtml;
  });