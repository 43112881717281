angular.module('salesPath2')
  .factory('BonusMalusDataObjectModel', [
    function ()
    {

      var BonusMalusDataObjectModel = function ()
      {
        this.data = {
          useGivenData: null, //true/false
          newModeData: null, //XPegaz.BonusMalus.New
          continuationModeData: null ////XPegaz.BonusMalus.Continuation
        };
        this.personRef = null; //id osoby
        this.riskName = null; //OC/AC
        this.vehicleRef = null; //id pojazdu
      };

      return BonusMalusDataObjectModel;
    }
  ]);