angular.module('ihestiaWidgets.policyDetails')
  .provider('ihestiaPolicyDetailsModalsInjectorCfg', [function() {
    var modalsInjectorConfig = this;

    this.owuModalSettings = {
      okBtnName: '',
      size: 'sm'
    };

    this.policyDocumentsModalSettings = {
      okBtnName: '',
      size: 'sm',
      title: ['chooseDocumentToDownload', {
        componentCode: 'Public'
      }]
    };

    this.policyDocumentsModalInfoSettings = {
      treatLabelAsI18nCode: true,
      okBtnName: ['Public.download', {
        componentCode: 'Public'
      }],
      size: 'sm',
      title: ['Atena.Printouts.PrintoutSymbol.Policy', {
        componentCode: 'Public'
      }]
    };

    /**
     * [description]
     * @return {[type]} [description]
     */
    this.$get = [function() {
      return modalsInjectorConfig;
    }];

  }]);
