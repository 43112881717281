angular.module('salesPath2')
  .controller('basketSecondDetailsModalCtrl', ['$scope', 'basketSecondHelper', 'CONFIG',
    function($scope, basketSecondHelper, CONFIG) {
      $scope.premiumData = {};
      $scope.tplSecondBasket = basketSecondHelper.tplData;

      $scope.init = function() {
        var purePremiumData = basketSecondHelper.getBasketContentData(true);
        var fullPremiumData = basketSecondHelper.getBasketContentData(false);
        $scope.premiumData = _.cloneDeep(fullPremiumData);
        angular.forEach($scope.premiumData.objects, function(obj, objIdx) {
          angular.forEach(obj.productsTable, function(prodData, prodIdx) {
            if (CONFIG.BEHAVIOR.discountDetailsPurePremiumProducts.indexOf(prodData.code) !== -1) {
              prodData.valueAfter = purePremiumData.objects[objIdx].productsTable[prodIdx].valueAfter;
              prodData.valueBefore = purePremiumData.objects[objIdx].productsTable[prodIdx].valueBefore;
              prodData.diff = purePremiumData.objects[objIdx].productsTable[prodIdx].diff;
            }
          });
        });
      };

      $scope.init();
    }
  ]);