/**
 * Helper do bootstrapowania aplikacji
 */
var LsnBootStrapHelper = function() {
  var self = this;

  /**
   * Po to, aby bootstrapować tylko raz
   * @type {Boolean}
   */
  this.bootstrapLocked = false;

  /**
   * indexedDb
   * @type {Dexie}
   */
  this.dexieDb = null;

  /**
   * Domyślne opcje bootstrapowania
   * @type {Object}
   */
  this.options = {
    bootstrapDomElem: document, // eslint-disable-line no-undef
    appName: 'ihestiaApp',
    devCache: false,
    configUrl: null,
    bootstrapConfig: {
      strictDi: true
    }
  };

  /**
   * [angularBootstrap description]
   * @type {[type]}
   */
  this.angularBootstrap = null;

  /**
   * [bootstrapPromise description]
   * @type {Promise}
   */
  this.bootstrapPromise = null;

  /**
   * [getBootstrap description]
   * @return {[type]} [description]
   */
  this.getBootstrap = function() {
    if (self.angularBootstrap === null) {
      self.angularBootstrap = angular.bootstrap(null, ['lsnBase.bootstrap']);
    }
    return self.angularBootstrap;
  };

  /**
   * [setBootstrapData description]
   * @param {Object} options [description]
   */
  this.setBootstrapData = function(options) {
    var $q = self.getBootstrap().get('$q');
    if(!self.bootstrapLocked) {
      self.bootstrapPromise = $q(function(resolve){
        self.bootstrapLocked = true;
        angular.extend(self.options, options);
        self.setConfig().then(function() {
          // to tylko strzał określający nam aplikację z byHeader bez pobrania pełnego configa
          if(window.APP_CONFIG.initial) // eslint-disable-line no-undef
          {
            resolve();
          }
          else
          {
            self.setLabels().then(resolve, resolve);
          }
        }, function() {
          // bootstrapujemy tak czy owak, bo jak coś pójdzie nie tak, to mamy to obsłużone w odpowiednim module
          resolve();
        });
      });
    }
    return self.bootstrapPromise;
  };

  /**
   * Inicjuje aplikację (angular bootstrap)
   * @param  {Object} options opcje
   * @return {undefined}
   */
  this.bootstrap = function(options) {
    self.setBootstrapData(options).then(function(){
      self._ngBootstrap();
    });
  };

  this.getParamsFromUrl = function()
  {
    var params = {};
    var hashString = self.getBootstrap().get('$location').url();
    if(hashString.indexOf('?') !== -1)
    {
      var paramsString = hashString.substr(hashString.indexOf('?') + 1);
      var paramsTab = paramsString.split('&');
      angular.forEach(paramsTab, function(paramString){
        var paramTab = paramString.split('=');
        params[paramTab[0]] = paramTab[1];
      });
    }
    return params;
  };

  /**
   * Ustawiamy etykiety
   *
   * @return {Promise}
   */
  this.setLabels = function() {
    var $q = self.getBootstrap().get('$q');
    return $q(function(resolve){
      var appConfig = window.APP_CONFIG; // eslint-disable-line no-undef
      if(appConfig && appConfig.serverData) {
        self._getCache('labels', appConfig.serverData.LABELS_HASH).then(function(cacheLabels){
          if(cacheLabels && angular.isObject(cacheLabels) && cacheLabels.hash === appConfig.serverData.LABELS_HASH) {
            appConfig.labels = cacheLabels.labels;
            resolve();
          } else {
            self._setLabelsFromSvc().then(resolve);
          }
        }, function(){
          self._setLabelsFromSvc().then(resolve);
        });
      } else {
        resolve();
      }
    });
  };

  /**
   * Ustawienie etykiet z danych z serwera
   */
  this._setLabelsFromSvc = function() {
    var $http = self.getBootstrap().get('$http');
    var $q = self.getBootstrap().get('$q');
    var appConfig = window.APP_CONFIG; // eslint-disable-line no-undef
    return $q(function(resolve){
      $http.get(appConfig.serverData.LABELS_JSON_URL, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(function(res){
        if(res && res.data) {
          appConfig.labels = res.data;
          self._setCache('labels', {
            hash: appConfig.serverData.LABELS_HASH,
            labels: appConfig.labels
          }).then(lsnNg.noop, lsnNg.noop);
          resolve();
        } else {
          appConfig.labels = {};
          resolve();
        }
      }, function(){
        appConfig.labels = {};
        resolve();
      });
    });
  };

  /**
   * Pobranie wartości z cache
   * @param  {String} name nazwa klucza
   * @param  {String} index index z indexedDb
   * @return {Mixed}     wartość zasobu z cache
   */
  this._getCache = function(name, index) {
    var $q = self.getBootstrap().get('$q');
    return $q(function(resolve, reject){
      if(typeof Dexie !== 'undefined') {
        self._getDb(name).then(function(db){
          db.get(index).then(function(value){
            if(value){
              resolve(value);
            } else {
              reject();
            }
          }, reject);
        }, reject);
      } else if(localStorage && angular.isDefined(localStorage[name])) {
        resolve(angular.fromJson(localStorage[name]));
      } else {
        reject();
      }
    });
  };

  /**
   * Wrzucamy coś do cache
   * @param {String} name      nazwa klucza
   * @param {Mixed} value     wartość do przechowania
   * @param {Boolean} globalCache czy cache dla wszystkich subdomen
   */
  this._setCache = function(name, value) {
    var $q = self.getBootstrap().get('$q');
    return $q(function(resolve, reject){
      try{
        if(typeof Dexie !== 'undefined') {
          self._getDb(name).then(function(db){
            db.clear().then(function(){
              db.put(value).then(resolve, reject);
            }, reject);
          }, reject);
        } else {
          localStorage[name] = angular.toJson(value);
          resolve();
        }
      } catch(e) {
        var $log = self.getBootstrap().get('$log');
        $log.log('indexedDb error', e);
        reject(e);
      }
    });
  };

  /**
   * Zwraca tabelką bazy indexedDb
   * @param  {String} name nazwa "tabelki"
   * @return {Object} db[name]
   */
  this._getDb = function(name) {
    var $q = self.getBootstrap().get('$q');
    return $q(function(resolve, reject){
      try {
        if(self.dexieDb === null) {
          self.dexieDb = new Dexie('cache');
          self.dexieDb.version(1).stores({
            labels: 'hash',
            config: '_id'
          });

          self.dexieDb.open().then(function(){
            resolve(self.dexieDb[name]);
          }, reject);
        } else {
          resolve(self.dexieDb[name]);
        }
      } catch(e){
        reject(e);
      }
    });
  };

  /**
   * Bootstrap angulara
   * @return {undefined}
   */
  this._ngBootstrap = function() {
    angular.bootstrap(self.options.bootstrapDomElem, [self.options.appName], self.options.bootstrapConfig);
  };

  /**
   * Pobieramy konfigurację i ustawiamy zmienną globalną APP_CONFIG
   *
   * return {Promise}
   */
  this.setConfig = function() {
    var $q = self.getBootstrap().get('$q');
    if (!self.options.configUrl) {
      throw (new Error('lsnBootstrapOptions.configUrl not set!'));
    }

    return $q(function(resolve){
      if(self.options.devCache) {
        self._getCache('config', 1).then(function(config){
          window.APP_CONFIG = config; // eslint-disable-line no-undef
          resolve(true);
        }, function(){
          self._setConfigFromSvc().then(resolve);
        });
      } else {
        self._setConfigFromSvc().then(resolve);
      }
    });
  };

  /**
   * Pobranie konfiguracji z serwera
   */
  this._setConfigFromSvc = function() {
    var $http = self.getBootstrap().get('$http');
    var $q = self.getBootstrap().get('$q');

    return $q(function(resolve){
      var requestUrl = self.options.configUrl;
      if(requestUrl.indexOf('?') === -1)
      {
        requestUrl = requestUrl + '?';
      }
      else
      {
        requestUrl = requestUrl + '&';
      }
      requestUrl = requestUrl + Math.random();
      $http.get(requestUrl, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(function(res) {
        if (res && res.data && !angular.equals({}, res.data)) {
            window.APP_CONFIG = res.data; // eslint-disable-line no-undef
            if(self.options.devCache) {
              res.data._id = 1;
              self._setCache('config', res.data).then(lsnNg.noop, lsnNg.noop);
            }
            resolve(true);
          } else {
            resolve(false);
          }
        }, function(){
          resolve(false);
        });
    });
  };
};

/* eslint-disable no-undef */
document.addEventListener('DOMContentLoaded', function() {
  if (window.lsnBootstrapOptions) {
    if(!window.lsnBootStrapHelper) {
      window.lsnBootStrapHelper = new LsnBootStrapHelper(); // eslint-disable-line no-undef
    }
    window.lsnBootStrapHelper.bootstrap(window.lsnBootstrapOptions); // eslint-disable-line no-undef
  }
});