angular.module('lsnBase.validators')
        .directive('validUrl', function() { // dziala tylko onBlur. Ustawia poprawny adres url dla linka zewntrznego lub wewnetrznego
          return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {

              /**
               * parsuje wartosc do teoretycznie poprawnego adresu url/uri
               * taki adres wymaga jeszcze pozniejszej walidacji
               * @param {String} inputValue
               * @returns {String}
               */
              var toValidUrl = function(inputValue) {
                if (typeof inputValue === 'undefined' || inputValue === '') {
                  return '';
                }
                inputValue = inputValue.toLowerCase();
                if (/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/.test(inputValue) && // eslint-disable-line
                        !/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/.test(inputValue)) { // eslint-disable-line
                  inputValue = 'http://' + inputValue;
                }
                return inputValue;
              };

              var formatValid = function(value){
                if (/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/.test(value)) { // eslint-disable-line
                  return true;
                }

                return false;
              };

              var setValidity = function(value) {
                if(typeof value === 'undefined' || value === ''){
                  ngModel.$setValidity('invalidFormat', true);
                }else{
                  var valid = formatValid(value);
                  ngModel.$setValidity('invalidFormat', valid);
                }

                return value;
              };

              setValidity(element.val());

              // For DOM -> model validation
              ngModel.$parsers.unshift(setValidity);

              // For model -> DOM validation
              ngModel.$formatters.unshift(setValidity);

              element.on('blur', function() {
                var newVal = toValidUrl(element.val());
                element.val(newVal);
                ngModel.$setViewValue(newVal);
              });

              scope.$on('$destroy', function(){
                element.off('blur');
              });
            }
          };
        });