angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaBeneficiaryTable', [function() {
    return {
      scope: {
        onOpenList: '&',
        mainBeneficiaries: '=',
        additionalBeneficiaries: '=',
        showElements: '='
      },
      restrict: 'E',
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/beneficiaries/beneficiaryTable/beneficiaryTable.tpl.html',
      link: function(scope) {
        scope.tplData = {
          headerSettings: [
            {width: '300px', text: 'BENEFICJENCI'},
            {width: 'auto'},
            {width: '100px', text: 'UDZIAŁ'}
          ]
        };
        scope.emitOpenList = function(){
          scope.onOpenList();
        };
      }
    };
  }
  ]);
