angular.module('lsnBase.validators')
        .directive('validNotEmpty', function() {
          return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModel) {

              var setValidity = function(length) {
                if(typeof length === 'undefined' || length === '' || length === null || length === 0){
                  ngModel.$setValidity('empty', false);
                }else{
                  ngModel.$setValidity('empty', true);
                }
                
                return length;
              };

              setValidity(element.val());

              // For DOM -> model validation
              ngModel.$parsers.unshift(setValidity);

              // For model -> DOM validation
              ngModel.$formatters.unshift(setValidity);
            }
          };
        });