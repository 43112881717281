angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaProgramConstants', [function() {
    var CONSTANTS = {
      STATUS_DRAFT: 'DRAFT',
      STAUS_SHARED: 'SHARED',
      STATUS_ACTIVE: 'ACTIVE',
      STATUS_CLOSED: 'CLOSED'
    };

    return CONSTANTS;
  }]);