angular.module('salesPath2')
  .service('personHelper', ['securityInitHelper', 'IdentityDocumentModelConstants', 'CONSTANTS', 'CONFIG', 'actionHelper', '$timeout', 'mainDataContainer', 'PersonModel', 'appVariables', 'personServiceHelper', 'sp2CommonHelper', 'sp2UiHelper', 'AddressModelConstants', 'ContactModelConstants', 'PersonModelConstants', 'ihestiaTrimInfo', 'personDataFilter', 'lsnDeepExtend', 'applicationDictionaryHelper', '$filter', 'allowedChangesHelper', 'dataContainerHelper', 'addressHelper',
    function(securityInitHelper, IdentityDocumentModelConstants, CONSTANTS, CONFIG, actionHelper, $timeout, mainDataContainer, PersonModel, appVariables, personServiceHelper, sp2CommonHelper, sp2UiHelper, AddressModelConstants, ContactModelConstants, PersonModelConstants, ihestiaTrimInfo, personDataFilter, lsnDeepExtend, applicationDictionaryHelper, $filter, allowedChangesHelper, dataContainerHelper, addressHelper) {
      var PersonHelper = function() {
        var self = this;

        this.nationalities = {
          'PL': {
            code: 'PL',
            description: 'POLSKIE - POLSKA',
            name: 'POLSKIE'
          }
        }; //w razie gdyby usługa się wysypała będziemy mieć chociaż domyślną wartość
        this.identityDocumentTypes = _.cloneDeep(IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE); //wartość przed zaciągnięciem z usługi, w razie gdyby się wysypała
        /**
         * nazwy pól na formularzu osoby, których błędne wartości traktowane będą jako warningi (nie będą blokowały zapisu osoby)
         * @type {Array}
         */
        this.warningEditFields = (angular.isArray(CONFIG.BEHAVIOR.personWarningEditFields)) ? CONFIG.BEHAVIOR.personWarningEditFields : ['pesel', 'regon', 'nip'];

        /**
         * zbiór pól additinalData mapowanych pozniej do dynamicValues
         * pole_z_add_data => klucz_w_dyn_vals
         * np. wykorzystywane w przeszłości: regon: '_regon'
         * UWAGA
         * wykorzystywane również przy pobieranu osoby z masterData - wówczas dynVals mapujemy do additionalData
         * @type {Object}
         */
        this.additinalDataToDynamicValues = {};

        /**
         * pola z modelu osoby uzupełniane tylko dla ubezpieczającego
         * @type {Object}
         */
        this.insurerOnlyData = {
          pkd: [],
          nip: null,
          regon: null,
          clauses: null,
          additionalData: { //dane dodatkowe
          }
        };

        /**
         * Init
         * @return {undefined}
         */
        this.init = function() {
          // z braku laku sprawdzamy tutaj, czy mamy uprawnienia do aplikacji
          if (securityInitHelper.hasAppPermission() && !CONFIG.doNotInitPersonDict) {
            self.setNationalities();
            self.setIdentityDocumentTypes();
          }
        };

        /**
         * zapis osoby
         * @param  {Object} formData          [description]
         * @param  {String} context           [description]
         * @param  {String} id                [description]
         * @param  {String|null} sourcePersonId clientId źródłowej osoby, której dane zostaną podmienione (null - brak podmiany)
         * @param  {String} personChangeRole rola/kontekst osoby, dla której następuje podmiana
         * @return {[type]}                   [description]
         */
        this.save = function(formData, context, id, sourcePersonId, personChangeRole) {
          var personId = self.savePerson(formData, id);

          if (angular.isString(sourcePersonId) && angular.isString(personChangeRole)) {
            self.changePerson(sourcePersonId, personId, personChangeRole);
            if(self.isAddressesUnavailable(sourcePersonId)){
              mainDataContainer.persons[sourcePersonId].addresses = [];
            }
          }
          
          if(self.isAddressesUnavailable(personId)){
            mainDataContainer.persons[personId].addresses = [];
          }
          
          if (angular.isUndefined(id) || id === null) {
            $timeout(function() {
              actionHelper.runAction('personAdded', {
                id: personId,
                context: context
              });
            }, 0);

          } else {
            $timeout(function() {
              actionHelper.runAction('personEdited', {
                id: personId,
                context: context
              });

            }, 0);
          }
        };

        /**
         * zapis osoby
         * @param {Object} personData dane osoby
         * @param {(string|int)=} personId clientId osoby
         * @param {boolean=} saveApplication czy zapisać wniosek (domyslnie = true)
         * @param {string=} filterForRole nazwa roli, dla której należy zastosować filtr zapisu osoby. Gdy brak filtrowanie nie odbywa się.
         */
        this.savePerson = function(personData, personId, saveApplication, filterForRole) {
          personData = angular.copy(personData);
          if (!angular.isString(filterForRole)) {
            filterForRole = null;
          }
          if (typeof saveApplication === 'undefined') //false przy wyborze osoby z ekranu startowego i przejściu do formularza dodawania osoby
          {
            saveApplication = true;
          }
          var newPerson = false;
          var person;
          var personPreviousData;
          if (angular.isUndefined(personId) || personId === null || angular.isUndefined(mainDataContainer.persons[personId])) {
            newPerson = true;
            if (typeof personId === 'undefined' || personId === null) {
              personId = dataContainerHelper.getNextPersonId();
            }
            person = new PersonModel();
            person.metaData.set('clientId', personId);
            mainDataContainer.persons[personId] = person;
          } else {
            person = mainDataContainer.persons[personId];
            personPreviousData = person.getData();
          }
          //blokada zapisu peselu jeśli edycja usera i user z NBK
          if (!newPerson && angular.isString(personPreviousData.metaData.masterId) && personPreviousData.metaData.masterId !== '') {
            delete personData.pesel;
          }
          person.setData(personData);
          if (newPerson) {
            if (filterForRole === null) {
              filterForRole = CONSTANTS.SUBJECT_ANY;
            }
            person = personDataFilter(person, filterForRole);
            self.fillEmptyDataFromPesel(person);
          }
          self._mapDynamicValuesToAdditionalData(personData);
          if (angular.isObject(personData._additionalData)) {
            person.setAdditionalData(lsnDeepExtend(person.getAdditionalData(), personData._additionalData));
          }
          person.metaData.set('clientId', personId);

          if (!newPerson) //sprawdzamy czy dane BM się nie zdeaktualizowały
          {
            actionHelper.runAction('personChangeCheckBM', {
              oldPerson: personPreviousData,
              newPerson: person
            });
          }
          if (saveApplication && !newPerson) //obsluga zapisu wniosku dla nowej osoby powinna byc obsluzona w delegacie (na callbacku personAdded). Niniejszy przpadek ma oblsugiwac np. edycje osoby po kliknieciu na blad z listy (automatyczny zapis wniosku)
          {
            actionHelper.runAction('saveApplication');
          }
          if (appVariables.isSupplement && newPerson) {
            allowedChangesHelper.addNewPersonToAllowed(person.metaData.get('clientId'), person.metaData.get('id'), person.objectName);
          }
          return person.metaData.get('clientId');
        };

        /**
         * zapis ubezpieczającego
         * UWAGA:
         * obecnie wykorzystywany tylko w clientAddCtrl. Jeśli zaistnieje potrzeba obsługi np. przełączania osoby lub aktualizacji należy przemyśleć i dorobić mechanizmy kopiowania/przenoszenia danych z dynamicValues i additionalData
         * @param  {Object} personData [description]
         * @param  {Boolwan} insurerIsMainInsured czy ubezpieczający jest głównym ubezpieczonym (domyślnie tak)
         * @return {String} clientId ubezpieczajacego
         */
        this.saveInsurer = function(personData, insurerIsMainInsured) {
          insurerIsMainInsured = (insurerIsMainInsured === false) ? false : true;
          var insurerId = mainDataContainer.insurerId;
          if (insurerId === null) {
            insurerId = dataContainerHelper.getNextPersonId();
          }
          var clientId = self.savePerson(personData, insurerId, false, CONSTANTS.SUBJECT_OWNER);
          mainDataContainer.insurerId = insurerId;
          if (insurerIsMainInsured) {
            mainDataContainer.mainInsuredId = insurerId;
          }
          appVariables.isInsurerChosen = true;
          if (CONFIG.BEHAVIOR.autoAddInsurerToInsured && !CONFIG.BEHAVIOR.isSingleProduct && !mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][clientId]) {
            dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
          }
          if (insurerIsMainInsured && CONFIG.BEHAVIOR.autoAddMainInsuredToInsured && !CONFIG.BEHAVIOR.isSingleProduct && !mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][clientId]) {
            dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId);
          }
          return clientId;
        };

        /**
         * mapuje dynVals do AdditionalData na dnych osobowych - do wywyolania przed zapisem osoby
         * @param  {Object} personData dane osoby
         */
        this._mapDynamicValuesToAdditionalData = function(personData) {
          if (!angular.isObject(personData.dynamicValues)) {
            return;
          }
          if (!angular.isObject(personData._additionalData)) {
            personData._additionalData = {};
          }
          angular.forEach(self.additinalDataToDynamicValues, function(dvField, adField) {
            //jesli dana z dynamicValues nie istnieje jeszcze w additinalData to ją tam uzupelniamy
            if (angular.isDefined(personData.dynamicValues[dvField]) && angular.isUndefined(personData._additionalData[adField])) {
              personData._additionalData[adField] = personData.dynamicValues[dvField];
            }
          });
        };

        /**
         * uzupełnia dane na podstawie peselu
         * @param  {PersonModel} person
         */
        this.fillEmptyDataFromPesel = function(person) { //eslint-disable-line consistent-return
          var pesel = person.get('pesel');
          if (pesel === null || pesel === '' || !pesel.isValidPESEL()) //nie da się nic wyciągnąć z peselu
          {
            return false;
          }
          if (person.get('birthDate') === null) {
            person.set('birthDate', pesel.peselToBirthdate());
          }
          if (person.get('gender') === null) {
            person.set('gender', pesel.peselToSex());
          }
        };

        /**
         * zwraca opis osoby zdatny do wyświetlena
         * @param  {PersonModel} person
         * @param  {object} params
         * @return {string}
         */
        this.getPersonInfo = function(person, params) {
          // domyslne ustawienia
          var settings = angular.extend({
            elements: [{
              field: 'firstName'
            }, {
              field: 'lastName',
              fieldSeparator: ', '
            }, {
              field: 'pesel'
            }],
            maxLength: 40,
            emptyValue: $filter('translate')('noData', {
              componentCode: 'Public'
            })
          }, params);

          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            element.text = person.get(element.field);
          });

          var text = ihestiaTrimInfo(settings);

          return text;
        };


        this.setNationalities = function() {
          personServiceHelper.getAllNationalities(function(response) {
            self.nationalities = {};
            angular.forEach(response.data.items, function(v) {
              self.nationalities[v.code] = v;
            });
          });
        };

        this.setIdentityDocumentTypes = function() {
          self.identityDocumentTypes[''] = { //opcja pusta
            code: '',
            name: ''
          };
          personServiceHelper.getAllIdentityDocumentTypes(function(response) {
            angular.forEach(response.data, function(v) {
              self.identityDocumentTypes[v.code] = v;
            });
            // delete all items that are in DICTIONARY_IDENTITY_DOCUMENT_CODE but not present in svc dictionary
            angular.forEach(Object.keys(self.identityDocumentTypes), function(docCode) {
              if (!_.some(response.data, {code: docCode})) {
                delete self.identityDocumentTypes[docCode];
              }
            });
          });
        };

        this.findPersons = function(query) {
          query = self._filterPersonsQuery(query);
          if (query === '') {
            return [];
          }
          return personServiceHelper.findPerson(query).then(function(res) {
            if (!res || !res.data || angular.isUndefined(res.data.items)) {
              return [];
            }
            return self.removeDoubles(res.data.items);
          }, angular.noop);
        };

        /**
         * nie wyświetlamy osób istniejących już na ścieżce
         */
        this.removeDoubles = function(items) {
          var usedNumber = {
            pesel: [],
            nrDok: []
          };
          angular.forEach(mainDataContainer.persons, function(person) {
            if (person.pesel) {
              usedNumber.pesel.push(person.pesel);
            }
            if (person.documents && person.documents[0]) {
              usedNumber.nrDok.push(person.documents[0]);
            }
          });

          var lengthResult = items.length - 1;
          for (var i = lengthResult; i >= 0; i--) {
            var person = items[i];
            if (usedNumber.pesel.indexOf(person.pesel) > -1 ||
              (person.documents.length && person.documents[0].number && usedNumber.nrDok.indexOf(person.documents[0].number) > -1)) {
              items.splice(i, 1);
            }
          }
          return items;
        };

        /**
         * filtrujemy query odrzucając frazy nie spełniające wymagań
         * @param  {string} query
         * @return {string}
         */
        this._filterPersonsQuery = function(query) {
          query = query.trim();
          var queryArray = query.split(' ');
          var finalQueryArray = [];
          angular.forEach(queryArray, function(string) {
            if (self._isValidPersonQueryPhrase(string)) {
              finalQueryArray.push(string);
            }
          });
          return finalQueryArray.join(' ');
        };

        /**
         * czy fraza spełnia kryteria
         * @param  {string}  string
         * @return {Boolean}
         */
        this._isValidPersonQueryPhrase = function(string) {
          if (string.length < 2) //frazy muszą mieć długość co najmniej 2
          {
            return false;
          }
          if (!isNaN(parseInt(string, 10)) && string.length < 6) { //Jeśli fraza składa się z samych cyfr to wymagane jest 6 znaków
            return false;
          }
          return true;
        };

        this.filterPersonsAdded = function(items, personsInMainContainer) {
          angular.forEach(personsInMainContainer, function(addedPerson) {
            var addedPersonId = addedPerson.metaData.get('id');

            var idsElementsToDestroy = [];
            angular.forEach(items, function(person, k) {
              var personId = person.metaData.id;

              if (addedPersonId === personId) {
                idsElementsToDestroy.push(k);
              }
            });

            while (idsElementsToDestroy.length) {
              var idELementToDestroy = idsElementsToDestroy.pop();
              items.splice(idELementToDestroy, 1);
            }
          });
        };

        this.personAddressToOneString = function(person) {
          var address = '';
          if (person.addresses.length > 0) {
            angular.forEach(person.addresses, function(adr) { //eslint-disable-line consistent-return
              if (typeof adr.code === 'undefined' || adr.code === null || adr.code === 'ADR_STALY') {
                if (adr.streetPrefix && adr.streetPrefix !== '') {
                  address += adr.streetPrefix + ' ';
                }
                if (adr.street && adr.street !== '') {
                  address += adr.street + ' ';
                }
                if (adr.house && adr.house !== '') {
                  address += adr.house;
                }
                if (adr.apartment && adr.apartment !== '') {
                  address += '/' + adr.apartment;
                }
                address += ', ';
                if (adr.postalCode && adr.postalCode !== '') {
                  address += adr.postalCode + ' ';
                }
                if (adr.city && adr.city !== '') {
                  address += adr.city + ' ';
                }
                address += ', ';
                return false;
              }
            });
          }
          address = address.replace('  ', ' ');
          address = address.replace(' ,', ',');
          if (address.length > 0) {
            address = address.substring(0, address.length - 2);
          }

          return address;
        };

        /**
         * pobiera dane klienta z uwzglednieniem blokady ekranu GUI (wersja NBK)
         * @param {int|string} id identyfikator osoby
         * @param {Object} personData dane osobowe, niezbędne do pobrania osoby z usługi
         * @param {function} callback funkcja przechwytujaca odpowiedz - dane pobranego klienta/osoby
         */
        this.loadPerson = function(id, personData, callback) {
          if (typeof id === 'undefined' || id === null) {
            sp2CommonHelper.throwException('Nie przekazano identyfikatora ososby do pobrania.');
          }
          //od pewnego momentu id zastąpiono w usłudzę masterId - id z NBK
          if (angular.isObject(personData.metaData) && angular.isString(personData.metaData.masterId)) {
            id = personData.metaData.masterId;
          } else {
            id = null;
          }
          sp2UiHelper.showBlockUi($filter('translate')('loadingPerson', {
            componentCode: 'sp2'
          }));
          personServiceHelper.getPerson(id, personData, function(res) {
            var respPersonData = res.data;
            if (!angular.isObject(respPersonData) || angular.isUndefined(respPersonData.metaData) || angular.isUndefined(respPersonData.metaData.id)) {
              sp2UiHelper.hideBlockUi();
              sp2CommonHelper.throwException('Nie udało się pobrać danych klienta o identyfikatorze: {0}.'.format(id));
            }
            var tmpPerson = new PersonModel();
            tmpPerson.setData(respPersonData);
            applicationDictionaryHelper.loadDictionariesForPerson(tmpPerson, function() {
              sp2UiHelper.hideBlockUi();
              callback(respPersonData);
            });
          }, function() { // np. przy 500-tce, odblokuje nam ekran
            sp2UiHelper.hideBlockUi();
          });
        };

        this.getFullPersonInfo = function(person) {
          var data = person.getData();
          data.mainAddress = {};
          data.hasMainAddress = false;
          data.koreAddress = {};
          data.hasKoreAddress = false;
          data.email = '';
          data.telKom = '';
          data.telStac = '';

          if (data.addresses !== null) {
            angular.forEach(data.addresses, function(address) { //eslint-disable-line consistent-return
              if (address.code === AddressModelConstants.ADDRESS_TYPE_STAL) {
                data.mainAddress = address;
                data.hasMainAddress = true;
                return true;
              }
              if (address.code === AddressModelConstants.ADDRESS_TYPE_KORE) {
                data.koreAddress = address;
                data.hasKoreAddress = true;
                return true;
              }
            });
          }
          if (data.contacts !== null) {
            angular.forEach(data.contacts, function(contact) {
              if (contact.code === ContactModelConstants.CONTACT_TYPE_DOMO) {
                data.telStac = contact.value;
              }
              if (contact.code === ContactModelConstants.CONTACT_TYPE_KOMO) {
                data.telKom = contact.value;
              }
              if (contact.code === ContactModelConstants.CONTACT_TYPE_MAIL) {
                data.email = contact.value;
              }
            });
          }
          if (data.birthDate !== null) {
            data.birthDate = new XDate(data.birthDate).toString('yyyy-MM-dd');
          }
          if (data.gender !== null) {
            data.gender = PersonModelConstants.DICTIONARY_PERSON_GENDER[data.gender];
          }
          if (data.documents !== null && data.documents.length !== 0) {
            if (data.documents[0].code === null) {
              data.documentType = '';
            } else {
              data.documentType = IdentityDocumentModelConstants.DICTIONARY_IDENTITY_DOCUMENT_CODE[data.documents[0].code];
            }
            data.documentNumber = data.documents[0].number;
          }
          return data;
        };

        //zwraca dane osoby i głównego adresu
        this.getPersonWithAddress = function(person) {
          var data = person.getData();
          addressHelper.addMainAddressData(data);
          angular.forEach(data, function(v, k) {
            if (v === null) {
              data[k] = '';
            }
          });
          return data;
        };

        /**
         * podmienia dane osoby
         * @param  {String} sourcePersonId clientId osoby, która będzie zastępowana/zamieniana
         * @param  {String} personId clientId osoby, na którą będziemy zamieniać
         * @param  {String} personChangeRole rola osoby podmienianej (CONSTANTS.PERSON_ROLE...)
         * @return {Boolean} true gdy zmieniono glownego ubezpieczonego
         */
        this.changePerson = function(sourcePersonId, personId, personChangeRole) {
          if (sourcePersonId === personId) //zmiana niepotrzebna
          {
            return false;
          }
          var sourcePerson = mainDataContainer.persons[sourcePersonId];
          var targetPerson = mainDataContainer.persons[personId];
          var rolesToChange = [personChangeRole];

          if (CONFIG.BEHAVIOR.insurerSameAsMainInsured) { //jeśli jednoczesna zmiana ub-cego i ub-nego
            if (personChangeRole === CONSTANTS.PERSON_ROLE_INSURED) {
              rolesToChange.push(CONSTANTS.PERSON_ROLE_INSURER);
            } else if (personChangeRole === CONSTANTS.PERSON_ROLE_INSURER) {
              rolesToChange.push(CONSTANTS.PERSON_ROLE_INSURED);
            }
          }

          if (rolesToChange.indexOf(CONSTANTS.PERSON_ROLE_INSURED) !== -1) {
            var rewritten = false;
            lsnNg.forEach(CONFIG.BEHAVIOR.mainInsuredOnlyRisks, function(prodCode) {
              rewritten = self._rewritePersonalRisks(sourcePerson, targetPerson, prodCode) || rewritten;
            });
            lsnNg.forEach(CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants, function(variants, prodCode) {
              rewritten = self._rewritePersonalRisks(sourcePerson, targetPerson, prodCode) || rewritten;
            });
            if (rewritten) { //jeśli przepisano jakieś dane dotyczące ryzyka osobowego, tzn. że poprzednia osoba została usunięta z ryzyka
              actionHelper.runAction('personDeleted', mainDataContainer.mainInsuredId);
            }

            //usuwamy nowego głównego ubezpieczonego z listy współwłaścicieli
            angular.forEach(mainDataContainer.localizations, function(localization) {
              self.tryDeleteCoowner(localization, personId);
            });
            angular.forEach(mainDataContainer.vehicles, function(vehicle) {
              self.tryDeleteCoowner(vehicle, personId);
            });
            mainDataContainer.mainInsuredId = personId;
            if (CONFIG.BEHAVIOR.autoAddMainInsuredToInsured && !CONFIG.BEHAVIOR.isSingleProduct && !mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][personId]) {
              dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, personId);
            }
          }

          if (rolesToChange.indexOf(CONSTANTS.PERSON_ROLE_INSURER) !== -1) {
            mainDataContainer.insurerId = personId;
            self.deleteInsurerOnlyData(sourcePerson);
          }

          actionHelper.runAction('personEdited', {
            id: personId,
            context: 'personChange'
          });
          actionHelper.runAction('saveApplication');
          return true;
        };

        /**
         * przepisywanie danych dotyczących ubezpieczenia osobowego z source do target person
         * @param  {PersonModel} sourcePerson źródłowa osoba
         * @param  {PersonModel} targetPerson docelowa osoba
         * @param  {String} prodCode kod produktu osobowego
         * @return {Boolean} czy przepisano dane
         */
        this._rewritePersonalRisks = function(sourcePerson, targetPerson, prodCode) {
          var rewritten = false;
          if (sourcePerson.getAdditionalData(prodCode) !== null) {
            var prevProdSu = dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_PERSON, sourcePerson.metaData.clientId, prodCode);
            if (!angular.equals(prevProdSu, {})) {
              //jesli ustawiona jakas SU dla tego ryzyka dla poprzedniej osoby, to kopiujemy je do nowej a ze starej ja usuwamy
              angular.forEach(prevProdSu, function(val, variant) {
                dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_PERSON, targetPerson.metaData.clientId, prodCode, val, variant);
              });
              delete mainDataContainer.suList[CONSTANTS.PRODUCT_TYPE_PERSON][mainDataContainer.mainInsuredId][prodCode];
            }
            targetPerson.setAdditionalData(sourcePerson.getAdditionalData(prodCode), prodCode); //przepisujemy dane dotyczace ubezpieczenia na osobie
            sourcePerson.deleteAdditionalData(prodCode);
            rewritten = true;
          }
          return rewritten;
        };

        /**
         * usuwa z osoby dane zarezerwowane tylko dla ubezpieczajacego
         * @param  {Person} person
         */
        this.deleteInsurerOnlyData = function(person) {
          angular.forEach(self.insurerOnlyData, function(val, name) {
            if (name === 'additionalData') {
              angular.forEach(val, function(addVal, addName) {
                if (addVal === null) {
                  person.deleteAdditionalData(addName);
                } else {
                  person.setAdditionalData(addVal, addName);
                }
              });
            } else {
              person.set(name, val);
            }
          });
        };

        this.drivingLicenseFilled = function(personId) {
          var dynamicValues = mainDataContainer.persons[personId].get('dynamicValues');
          if (dynamicValues === null) {
            return false;
          }
          return !((typeof dynamicValues.noDrivingLicence === 'undefined' || dynamicValues.noDrivingLicence === null) && (typeof dynamicValues.drivingLicenceIssueDate === 'undefined' || dynamicValues.drivingLicenceIssueDate === null));
        };

        /**
         * Usuwa osobę z roli współwłaściciela z obiektu
         * @param  {EstateModel|VehicleModel} object
         * @param  {string} personId
         * @return {bool} czy usunięto
         */
        this.tryDeleteCoowner = function(object, personId) {
          var deleted = false;
          var coowners = object.getAdditionalData('coowners');
          if (coowners.indexOf(personId) !== -1) {
            coowners.splice(coowners.indexOf(personId), 1);
            deleted = true;
          }
          return deleted;
        };

        /**
         * zwraca kontakt osoby lub null jesli nie znaleziono
         * @param  {PersonModel} person
         * @param  {String} contactCode
         * @return {ContactModel|null}
         */
        this.getPersonContact = function(person, contactCode) {
          if (person.get('contacts').length === 0) {
            return null;
          }
          var foundContact = null;
          angular.forEach(person.contacts, function(contact) {
            if (foundContact === null && contact.get('code') === contactCode) {
              foundContact = contact;
            }
          });
          return foundContact;
        };

        this.isAddressesUnavailable = function (personId, personChangeRole){
          return CONFIG.METADATA.applicationSymbol === 'EP' && !self.isInsurerOrInsured(personId, personChangeRole);
        };

        this.isInsurerOrInsured = function(personId, personChangeRole) {
          if (personId){
            return parseInt(personId, 10) === parseInt(mainDataContainer.insurerId, 10) ||
                parseInt(personId, 10) === parseInt(mainDataContainer.mainInsuredId, 10);
          }
          return personChangeRole === CONSTANTS.PERSON_ROLE_INSURER || 
              personChangeRole === CONSTANTS.PERSON_ROLE_INSURED;
        };
        
        self.init();
      };

      return new PersonHelper();
    }
  ]);