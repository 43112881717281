angular.module('lsnBase.filters')
  .filter('stringNumber', [function() {
    return function(number) {
      number += '';
      var reg = /(\d+)(\d{3})/;
      while (reg.test(number)) {
        number = number.replace(reg, '$1 $2');
      }
      return number;
    };
  }]);