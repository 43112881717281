angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryApplicationProductSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryApplicationProductSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'applicationProduct';

      };
      return new RestDictionaryApplicationProductSvc();
    }
  ]);