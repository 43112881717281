angular.module('lsnBase.models')
// TODO przeniść do REST
  .service('contactFilterModelFilter', ['ContactModelConstants', function(ContactModelConstants){
    return function(contacts)
    {
      var handledCodes = [ContactModelConstants.CONTACT_TYPE_KOMO, ContactModelConstants.CONTACT_TYPE_MAIL]; // obsługiwane typy kontaktów
      var parsedContacts = [];
      $.each(contacts, function(index, contact){
        if(handledCodes.indexOf(contact.code) !== -1)
        {
          parsedContacts.push(contact);
          handledCodes.splice(handledCodes.indexOf(contact.code), 1); // usuwamy typ z obsługiwanych
        }
      });
      return parsedContacts;
    };
  }]);