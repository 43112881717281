angular.module('ihestiaCommonDirectives')
        .factory('ihestiaCompareByPolishSign', function() {
          var sort = function(prop) {
              return function(a, b) {
                var string = ' 0123456789aąbcćdeęfghijklłmnńoópqrsśtuvwxyzźż.';
                if (typeof prop === 'string'){
                  a = a[prop]; 
                  b = b[prop];
                }
                var name1 = a.trim().toLowerCase().split(''), 
                    name2 = b.trim().toLowerCase().split('');

                for (var i = 0; i < name1.length; i++) {
                  var res = string.indexOf(name2[i]) - string.indexOf(name1[i]);
                  if (res > 0) {
                    return -1;
                  } else if (res < 0) {
                    return 1;
                  }
                }
                
                return 0;
              };
          };
          return sort;
        });