angular.module('ihestiaRestServicesBase')
.factory('clausesApplicationsSvc', [ 'IHestiaRestServiceAbstractDef',
  function(IHestiaRestServiceAbstractDef) {

  var Clauses = function()
  {
    var self = this;
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.basePattern = '{host}{port}{path}/{apiString}/{businessArea}/{apiVer}/{resource}{id}{subResource}';

    this.resource = 'applications';
    this.businessArea = 'clauses';
    this.apiVersion = 'v1';

    this.getClausesByApplicationId = function(applicationId, callback, errorCallback){
      return self.get(applicationId, null, 'clauses', callback, errorCallback);
    };
  };

  return new Clauses();
}])
;