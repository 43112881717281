angular.module('salesPath2')
  .controller('clientSearchCtrl', ['$scope', 'availabilityHelper', 'navigationHelper', 'personHelper', 'clientAddHelper', 'ceidgModalHelper',
    function($scope, availabilityHelper, navigationHelper, personHelper, clientAddHelper, ceidgModalHelper) {
      $scope.dictAvailable = availabilityHelper.isAvailable('addressSearch');
      /**
       * obiekt wykorzystywany w autocompleterze clientSearch
       * @type {Object}
       */
      $scope.temp = {
        search: ''
      };

      /**
       * przejście do ekranu dodawania nowego klienta
       */
      $scope.goToClientEdit = function() {
        clientAddHelper.tplData.personData = null; //czyscimy ewentualne dane osoby
        navigationHelper.go($scope.CONSTANTS.PAGE_START_CLIENT_EDIT);
      };

      /**
       * sprawdzanie czy można wyszukac klienta w CEIDG
       * @return {Boolean}
       */
      $scope.isCeidgSearch = function() {
        return $scope.CONFIG.BEHAVIOR.ceidgSearch;
      };

      $scope.onSelect = function($item) {
        $scope.temp.search = ''; //resetujemy, ponieważ w tym momencie w search znajduje się obiekt osoby i widać to w inpucie
        personHelper.loadPerson($item.person.metaData.id, $item.person, function(personData) {
          clientAddHelper.tplData.personData = personData; //zasilamy kolejną stronę danymi osoby
          personHelper._mapDynamicValuesToAdditionalData(clientAddHelper.tplData.personData);
          navigationHelper.go($scope.CONSTANTS.PAGE_START_CLIENT_EDIT);
        });
      };

      /**
       * wywołanie modala dodawania CEIDG
       */
      $scope.showCeidgModal = function() {
        ceidgModalHelper.openSearchCeidgClientModal();
      };
    }
  ]);