angular.module('salesPath2')
  .service('dataContainerHelper', ['dcDateHelper', 'dcCommonHelper', 'dcObjectHelper', 'dcSubjectHelper', 'dcProductHelper', 'dcSelectionHelper', 'dcAllowedHelper', 'dcAmountHelper',
    function(dcDateHelper, dcCommonHelper, dcObjectHelper, dcSubjectHelper, dcProductHelper, dcSelectionHelper, dcAllowedHelper, dcAmountHelper) {
      var DataContainerHelper = function() {
        var self = this;

        /**
         * inicjalizacja dataContainera
         */
        this.init = function() {
          dcDateHelper.initProtectionDates();
          dcDateHelper.initProtectionTime();
          dcCommonHelper.init();
        };

        /**
         * ustawia domyślną konfigurację zaznaczeń wariantów dla podanego obiektu
         * @param {String} objectType typ obiektu
         * @param {String} objectId clientId obiektu
         */
        this.setDefaultVariantsForObject = function(objectType, objectId) {
          dcCommonHelper.setDefaultVariantsForObject(objectType, objectId);
        };

        /**
         * zwraca kolejny clientId obiektu z iteratora
         * @return {Number}
         */
        this.getNextObjectId = function() {
          return dcObjectHelper.getNextObjectId();
        };

        /**
         * zwraca kolejny clientId dla lokalizacji
         * @return {Number}
         */
        this.getNextLocalizationId = function() {
          return dcObjectHelper.getNextLocalizationId();
        };

        /**
         * zwraca liczbę lokalizacji na wniosku
         * @return {Number}
         */
        this.getLocalizationCount = function() {
          return dcObjectHelper.getLocalizationCount();
        };

        /**
         * zwraca kolejny clientId dla pojazdu
         * @return {Number}
         */
        this.getNextVehicleId = function() {
          return dcObjectHelper.getNextVehicleId();
        };

        /**
         * zwraca liczbę pojazdów na wniosku
         * @return {Number}
         */
        this.getVehicleCount = function() {
          return dcObjectHelper.getVehicleCount();
        };

        /**
         * zwraca kolejny clientId dla podmiotu
         * @return {Number}
         */
        this.getNextSubjectId = function() {
          return dcSubjectHelper.getNextSubjectId();
        };

        /**
         * zwraca kolejny clientId dla osoby
         * @return {Number}
         */
        this.getNextPersonId = function() {
          return dcSubjectHelper.getNextPersonId();
        };
        /**
         * zwraca kolejny clientId dla grupy
         * @return {Number}
         */
        this.getNextGroupId = function() {
          return dcSubjectHelper.getNextGroupId();
        };

        this.getNextOrganizationId = function() {
          return dcSubjectHelper.getNextOrganizationId();
        };

        /**
         * zwraca liczbę organizacji na wniosku
         * @return {Number}
         */
        this.getOrganizationCount = function() {
          return dcSubjectHelper.getOrganizationCount();
        };

        /**
         * zwraca liczbę osób ubezpieczonych w NNW
         * @return {Number}
         */
        this.getNnwPersonsCount = function() {
          return dcProductHelper.getNnwPersonsCount();
        };

        /**
         * zwraca liczbę osób ubezpieczonych w OCZP
         * @return {Number}
         */
        this.getOczpPersonsCount = function() {
          return dcProductHelper.getOczpPersonsCount();
        };

        /**
         * zwraca liczbę osób ubezpieczonych w produkcie osobowym (NNW/OCZP)
         * @param  {String} productCode
         * @return {Number}
         */
        this.getProductPeronsCount = function(productCode) {
          return dcProductHelper.getProductPeronsCount(productCode);
        };

        /**
         * pobranie obiektu lub podmiotu, konkretnego typu po id lub lcientId
         * @param {string} objectType typ obiektu (jeden z kluczy OBJECT_TYPE_TO_CONTAINER_NAME)
         * @param {*} objectId id obiektu
         * @param {string} [idType='clientId'] typ id (id|clientId)
         * @return {*} null lub obiekt, gdy znaleziono
         */
        this.getObject = function(objectType, objectId, idType) {
          return dcCommonHelper.getObject(objectType, objectId, idType);
        };

        /**
         * zwraca obiekty z dataContainera określonego typu
         * @param  {String} objectType typ
         * @return {Object} []clientId: obiekt
         */
        this.getObjects = function(objectType) {
          return dcCommonHelper.getObjects(objectType);
        };

        /**
         * zwraca numer danego obiektu zaczynając od 1
         * @param  {string} objectType  typ obiektu
         * @param  {int} objectId       id obiektu
         * @return {int}                numer obiektu
         */
        this.getObjectNumber = function(objectType, objectId) {
          return dcCommonHelper.getObjectNumber(objectType, objectId);
        };

        /**
         * wybiera (ustawia globalny kontekst - mainDataContainer.selected...) obiekt(podmiot) określonego typu i o podanym clientId
         * @param  {String} type typ obiektu
         * @param  {String} clientId
         */
        this.selectObject = function(type, clientId) {
          dcSelectionHelper.selectObject(type, clientId);
        };

        /**
         * kopiuje zaznaczone produkty i dodatki z jedego obiektu do drugiego
         * @param  {String} productType typ produktu, ktorefo zaznaczenia maja byc skopiowane
         * @param  {String|Number} sourceObjId źródłowy obiekt clientId
         * @param  {String|Number} targetObjId docelowy obiekt clientId
         */
        this.copyObjectSelection = function(productType, sourceObjId, targetObjId) {
          dcSelectionHelper.copyObjectSelection(productType, sourceObjId, targetObjId);
        };

        /**
         * wybiera lokalizację
         * @param  {String} clientId
         */
        this.selectLocalization = function(clientId) {
          dcSelectionHelper.selectLocalization(clientId);
        };

        /**
         * wybiera lokalizację
         * @param  {String} clientId
         */
        this.selectVehicle = function(clientId) {
          dcSelectionHelper.selectVehicle(clientId);
        };

        /**
         * wybiera lokalizację
         * @param  {String} clientId
         */
        this.selectSubject = function(clientId) {
          dcSelectionHelper.selectSubject(clientId);
        };

        /**
         * ustawia numer kalkulacji/wniosku
         * @param {String} number
         */
        this.setCalculationNumber = function(number) {
          dcCommonHelper.setCalculationNumber(number);
        };

        /**
         * ustawia sumę ubezpieczenia
         * @param {String} objectType
         * @param {String} objectId
         * @param {String} riskCode tyzyko lub produkt
         * @param {Number} value
         * @param {Number} [max] opcjonalne max SU
         * @param {String} [subRiskCode] ryzyko w przypadku produktów ogniowych lub kradzieżowych
         */
        this.setSu = function(objectType, objectId, riskCode, value, subRiskCode, max) {
          dcAmountHelper.setSu(objectType, objectId, riskCode, value, subRiskCode, max);
        };

        /**
         * zaokragla sume ubezpieczenia na podstawie definicji produktu i ewentualnie przekazanego max-a
         * @param  {String} prod kod produktu
         * @param  {String} variant kod wariantu
         * @param  {[type]} value   [description]
         * @param  {Number} [max] opcjonalny max dla SU, np. w przypadku wariantu z określaniem dochodu
         * @return {Number}
         */
        this.roundSumInsured = function(prod, variant, value, max) {
          return dcAmountHelper.roundSumInsured(prod, variant, value, max);
        };

        /**
         * zwraca sumę ubezpieczenia odpowiedniego poziomu w zależności od ilości przekazanych argumentów
         * @param  {String} objectType
         * @param  {String} objectId
         * @param  {String} riskCode
         * @param  {String} [subRiskCode]
         * @return {Number|Object}
         */
        this.getSu = function(objectType, objectId, riskCode, subRiskCode) {
          return dcAmountHelper.getSu(objectType, objectId, riskCode, subRiskCode);
        };

        /**
         * zwraca składkę za ryzyko
         * @param  {String} objectType
         * @param  {String} objectId
         * @param  {String} riskCode
         * @param  {String} [subRiskCode]
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {Number}
         */
        this.getPremium = function(objectType, objectId, riskCode, subRiskCode, container) {
          return dcAmountHelper.getPremium(objectType, objectId, riskCode, subRiskCode, container);
        };

        this.getPremiumBeforeDiscout = function(objectType, objectId, riskCode, subRiskCode, pure) {
          return dcAmountHelper.getPremiumBeforeDiscout(objectType, objectId, riskCode, subRiskCode, pure);
        };

        this.getPremiumAfterDiscout = function(objectType, objectId, riskCode, subRiskCode, pure) {
          return dcAmountHelper.getPremiumAfterDiscout(objectType, objectId, riskCode, subRiskCode, pure);
        };

        /**
         * wyciągnięcie pojedynczej składki za dodatek
         * @param  {string} addCode  kod dodatku
         * @param  {string} variant  wariant
         * @param  {string|int} objectId id obiektu
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {int}          składka
         */
        this.getAddPremium = function(addCode, variant, objectId, container) {
          return dcAmountHelper.getAddPremium(addCode, variant, objectId, container);
        };

        this.getAddPremiumBeforeDiscout = function(addCode, variant, objectId, pure) {
          return dcAmountHelper.getAddPremiumBeforeDiscout(addCode, variant, objectId, pure);
        };

        this.getAddPremiumAfterDiscout = function(addCode, variant, objectId, pure) {
          return dcAmountHelper.getAddPremiumAfterDiscout(addCode, variant, objectId, pure);
        };

        /**
         * zwraca true jeśli osoba dodana do ryzyka (OCZP/NNW)) i ma zazaczony jakiś wariant
         * @param  {Person} person
         * @param  {string} riskCode
         * @return {bool}   czy soba dodana do ryzyka i ma zaznaczony dowolny wariant
         */
        this.personVariantSelected = function(person, riskCode) {
          return dcSelectionHelper.personVariantSelected(person, riskCode);
        };

        /**
         * zwraca clientId dla obiektu/podmiotu na podstawie podanego id
         * @param  {String} objectType
         * @param  {String} id
         * @return {String}
         */
        this.getClientId = function(objectType, id) {
          return dcCommonHelper.getClientId(objectType, id);
        };

        /**
         * zwraca polisę po numerze
         * @param  {String} number
         * @return {PolicyModel|null} null gdy nieznaleziono
         */
        this.getPolicyByNumber = function(number) {
          return dcCommonHelper.getPolicyByNumber(number);
        };

        /**
         * zwraca polise dla zadanego obiektu (pojazdu/lokalizacji itd.)
         * @param {string} objectType typ obiektu (jeden z kluczy OBJECT_TYPE_TO_POLICY_CONTAINER_NAME)
         * @param {int} objectId metaData.id obiektu
         * @param {string} [idType='id'] typ identyfikatora - id lub clientId (domyslnie id)
         * @param {Object} [policiesContainer=mainDataContainer.policies] kontener z polisami (domyslnie policies z DM)
         * @return {PolicyModel|null} polisa lub null gdy nieznaleziono
         */
        this.getPolicyByObjectId = function(objectType, objectId, idType, policiesContainer) {
          return dcCommonHelper.getPolicyByObjectId(objectType, objectId, idType, policiesContainer);
        };

        /**
         * get all policies with BLIK chosen as payment method
         * @return {LsnPolicyModelV1[]}
         */
        this.getBlikPaymentPolicies = function() {
          return dcCommonHelper.getBlikPaymentPolicies();
        };

        /**
         * get payment method from first policy - used for bad client were paymentMethod is one for all
         * @return {string}
         */
        this.getPaymentMethod = function() {
          return dcCommonHelper.getPaymentMethod();
        };

        /**
         * zwraca ubezpieczajacego lub null gdy nie znaleziono
         * @return {PersonModel|null}
         */
        this.getInsurer = function() {
          return dcCommonHelper.getInsurer();
        };

        /**
         * zwraca glownego ubezpieczonego
         * @return {PersonModel|null} null gdy nie znaleziono
         */
        this.getMainInsured = function() {
          return dcCommonHelper.getMainInsured();
        };

        /**
         * Zbiera dane zniżek klienckich z wszystkich polis
         * @return {object}
         */
        this.getDiscounts = function() {
          return dcAmountHelper.getDiscounts();
        };

        /**
         * Zwraca sumę składek należnych
         * @return {int}
         */
        this.getFinalPremiumSum = function() {
          return dcAmountHelper.getFinalPremiumSum();
        };

        /**
         * czy wybrano glowny obiekt ubezpieczenia - uzywane np. w produktach solo oc/md
         * @return {Boolean} gdy nie określono co jest głównym obiektem ubezpieczenia, to zwracamy true.
         */
        this.isMainObjectChosen = function() {
          return dcCommonHelper.isMainObjectChosen();
        };

        /**
         * zwraca obiekt z identyfikatorami osob oraz danymi dotyczacymi zaznaczen wariantow ryzyk osobowych dla tych osob
         * @param  {[type]} productCode [description]
         * @return {Object} {clint_id_osoby: dane_ryzyka[,...]}, gdzie dane_ryzyka to person.getAdditionalData('kod_produktu/ryzyka, np OCZP')
         */
        this.getPersonsForPersonalProduct = function(productCode) {
          return dcSubjectHelper.getPersonsForPersonalProduct(productCode);
        };

        /**
         * zwraca clientId dla wybranego podmiotu/obiektu dla określonego typu produktu
         * @param  {String} productType
         * @return {String|null} null gdy nieznaleziono
         */
        this.getSelectedObjectId = function(productType) {
          return dcCommonHelper.getSelectedObjectId(productType);
        };

        /**
         * czy produkt jest zaznaczony, jeśli nie podano objectId to sprawdza dla obecnego stanu matrycy
         * @param  {string}  productCode kod produktu
         * @param  {string|int}  objectId    id pojazdu/lokalizacji/osoby
         * @return {Boolean}
         */
        this.isProductSelected = function(productCode, objectId) {
          return dcSelectionHelper.isProductSelected(productCode, objectId);
        };

        /**
         * czy wybrano jakikolwiek produkt z danego typu
         * @param  {String}  productType
         * @return {Boolean}
         */
        this.isSelectedAnyProductOn = function(productType) {
          return dcSelectionHelper.isSelectedAnyProductOn(productType);
        };

        /**
         * zwraca listę wybranych wariantów dla produktu i obiektu (pojazdu/lokalizacji/osoby-grupy), domyślnie wybrana
         * @param  {string} productCode [description]
         * @param  {[type]} objectId    [description]
         * @return {[type]}             [description]
         */
        this.getVariantsForProduct = function(productCode, objectId) {
          return dcCommonHelper.getVariantsForProduct(productCode, objectId);
        };

        /**
         * czy obiekt jest modelem osoby
         * @param  {*}  obj coś
         * @return {Boolean}
         */
        this.isPerson = function(obj) {
          return dcSubjectHelper.isPerson(obj);
        };

        /**
         * zwraca składki dla produktow matrycy
         * @param {String} [container='premiumList'] skąd mamy sczytywać składki
         * @return {Object} [description]
         */
        this.getPremiums = function(container) {
          return dcAmountHelper.getPremiums(container);
        };

        /**
         * Czy NNW jest krótkoterminowe
         * @return {Boolean}
         */
        this.isShortTermNnw = function() {
          return dcProductHelper.isShortTermNnw();
        };

        /**
         * zwraca domyślne dane dla mainDataContainer.nnwIncognito
         * @return {Object} jak ponizej
         */
        this.getDefaultNnwIncognitoData = function() {
          return dcProductHelper.getDefaultNnwIncognitoData();
        };

        /**
         * ustawia dynamic values na wszystkich polisach/pakietach w dataContainer.policies
         * @param {Object} dynVals
         */
        this.setPoliciesDynamicValues = function(dynVals) {
          dcCommonHelper.setPoliciesDynamicValues(dynVals);
        };

        /**
         * wyrównuje sumy ubezpieczeń w ryzykach osobowych pod warunkiem, że podano tylko jedną sumę a inne są równe 0
         */
        this.alignPersonalRisksSumInsured = function() {
          dcAmountHelper.alignPersonalRisksSumInsured();
        };

        /**
         * [getPreviousVariantsForProduct description]
         * @param  {[type]} productCode [description]
         * @param  {[type]} objectId    [description]
         * @return {[type]}             [description]
         */
        this.getPreviousVariantsForProduct = function(productCode, objectId) {
          return dcCommonHelper.getPreviousVariantsForProduct(productCode, objectId);
        };

        /**
         * zwraca obiekt z informacjami o wybranych wariantach dla danego obiektu
         * @param  {Object} productVariants wycinek obiektu mainDataContainer.previousRisksSelection dla konkretnego procuktu (obiekt z wariantami i występującymi w nich obiektami)
         * @param  {String} objId           clientId obiektu, dla którego sprawdzamy zaznaczenie lub CONSTANTS.ALL_OBJECTS dla wszystkich obiektów razem
         * @return {Object}                 obiekt postaci {variant: true|false[,...]}, gdzie variant to kod wariantu a true|false oznacza czy wybrany czy nie
         */
        this._getPreviousVariantsForObject = function(productVariants, objId) {
          return dcSelectionHelper._getPreviousVariantsForObject(productVariants, objId);
        };

        /**
         * zwraca dane dotyczące dozwolonych atrybutów dla podanego obiektu oraz informacje czy obiekt jest nowym elementem na wniosku
         * @param  {String} objectType typ obiektu/produktu
         * @param  {String} clientId   clientId obiektu/podmiotu
         * @return {Object|null} znaleziony podelement/obiekt z mainDataContainer.allowedChanges z atrybutami umieszczonymi w obiekcie attributes (zmiast tablicy, kluczem w obiekcie jest nazwa atrybutu)
         */
        this.getAllowedChangesFor = function(objectType, clientId) {
          return dcAllowedHelper.getAllowedChangesFor(objectType, clientId);
        };

        /**
         * zwraca dozwolone warianty dla zadanego produktu na podstawie danych z mainDataContainer.allowedRisksVariants
         * @param  {String} product kod produktu
         * @param  {String} objId   is obiektu
         * @return {null|String[]} tablica z dozwolonymi wariantami lub null gdy brak danych
         */
        this.getAllowedProductVariants = function(product, objId) {
          return dcAllowedHelper.getAllowedProductVariants(product, objId);
        };

        /**
         * zmienia wszystkie daty ochrony danego typu w datacontainerze
         * @param {String} type  start|end
         * @param {String} value wartość - data
         */
        this.setAllProtectionDates = function(type, value) {
          dcDateHelper.setAllProtectionDates(type, value);
        };

        /**
         * czy osoba pochodzi z NBK
         * Należy podać clientId (szukanie w dataContainer) lub personData, jeśli chcemy sprawdzić przekazaną (np. jeszcze nie zapisaną) osobę
         * @param  {String}  [clientId] id osoby
         * @param  {Object}  [personData]  dane osoby
         * @return {Boolean}
         */
        this.isNbkPerson = function(clientId, personData) {
          return dcSubjectHelper.isNbkPerson(clientId, personData);
        };

        /**
         * szuka i zwraca obiekt ryzyka z poprzedniej polisy na podstawie podanych parametrów
         * UWAGA:
         * niezdefiniowane parametry lub parametry z wartością null nie będą użyte przy wyszukiwaniu ryzyka
         * @param  {String} [prodCode] kod produktu
         * @param  {String} [riskCode] kod ryzyka
         * @param  {String} [objId]    id obiektu/podmiotu ubezpieczonego
         * @param  {String} [variant]  wariant ryzyka
         * @return {null|RiskModel} ryzyko lub null gdy nieznaleziono
         */
        this.getPreviouslySelectedRiskObj = function(prodCode, riskCode, objId, variant) {
          return dcSelectionHelper.getPreviouslySelectedRiskObj(prodCode, riskCode, objId, variant);
        };

        /**
         * zwraca informacje o aktualnie zaznaczonych wariantach dla dodatku "wariantowego"
         * @param  {String} addCode kod dodatku
         * @param  {String} objId id obiektu powiązanego z dodatkiem
         * @return {Object} obiekt
         * {
         *  kod_wariantu: true|false //true gdy wybrany
         *  [,...]
         * }
         */
        this.getSelectedAddVariants = function(addCode, objId) {
          return dcSelectionHelper.getSelectedAddVariants(addCode, objId);
        };

        /**
         * przebudowuje allowedChanges (np. kopiuje dozwolone atrybuty z ryzyk do additionalData na ubezpieczonych obiektach)
         * @param  {Object} [allowedChanges] jesli nie podano, to operacja przeprowadzana jest na mainDataContainer.allowedChanges
         * @return {Boolean} true, gdy przeprowadzono operację
         */
        this.rebuildAllowedChanges = function(allowedChanges) {
          return dcAllowedHelper.rebuildAllowedChanges(allowedChanges);
        };

        /**
         * zwraca obiekt z kodami ryzyk mieniowych i informacją, czy dane ryzyko jest dostępne (na podstawie danych z allowedChanges na wniosku)
         * @param  {String} product jaki produkt sprawdzamy
         * @param  {String} objId clientId nieruchomości
         * @return {null|Object} null, gdy brak informacji (nie powinniśmy wówczas niczego blokować) lub obiekt:
         * {
         *  estate: true|false //true gdy dostępne
         *  [, kod_ryzyka: ...] //kolejne ryzyka na podstawie typu lokalizacji
         * }
         */
        this.getAllowedPropertyRisks = function(product, objId) {
          return dcAllowedHelper.getAllowedPropertyRisks(product, objId);
        };

        /**
         * zwraca informacje o dozwolonych dodatkach na podstawie dataContainer.allowedRisksVariants (czyli allowedChanges z usługi)
         * @return {null|Object} null gdy brak danych (wówczas nie blokujemy dodatków) lub obiekt formatu:
         * {
         *  kod_dodatku: true|false //true gdy dozwolony
         *  [,...]
         * }
         */
        this.getAllowedAdds = function() {
          return dcAllowedHelper.getAllowedAdds();
        };

        /**
         * zwraca czas rozpoczęcia okresu ochrony dla dnia dzisiejszego na podstawie aktualnej godziny serwerowej
         * @return {String} HH:MM
         */
        self.getTodayProtectionStartTime = function() {
          return dcDateHelper.getTodayProtectionStartTime();
        };

        /**
         * inicjalizuje czasy ochrony
         */
        this.initProtectionTime = function() {
          return dcDateHelper.initProtectionTime();
        };

        /**
         * [anyClientPolicyExist sprawdza czy na wniosku wystepuje jakakolwiek polisa klienta]
         * @return {Boolean}          [description]
         */
        this.anyClientPolicyExist = function() {
          return dcCommonHelper.anyClientPolicyExist();
        };

        /**
         * [isAllowedRisk czy ryzyko jest dostepne na rozszerzeniu]
         * @param  {string}  productCode  [kod produktu]
         * @param  {string}  riskCode  [kod ryzyka]
         * @param  {string}  objectClientId     [id obiektu powiazanego np lokalizacji]
         * @param  {array}  riskTypes [np ['newRisks', 'existingRisks'] czy ['newRisks'] ]
         * @return {Boolean}           [description]
         */
        this.isAllowedRisk = function(productCode, riskCode, objectClientId, riskTypes) {
          return dcAllowedHelper.isAllowedRisk(productCode, riskCode, objectClientId, riskTypes);
        };

        /**
         * [isNewPersonOnApp sprawdza czy osoba jest nowa na wniosku]
         * @param  {[type]}  clientId [description]
         * @return {Boolean}          [description]
         */
        this.isNewPersonOnApp = function(clientId) {
          return dcCommonHelper.isNewPersonOnApp(clientId);
        };

        /**
         * szuka i zwraca (jesli znalazl) dane dostępności dotyczące atrbutu na ryzyku
         * @param  {String} attrName nazwa atrybutu
         * @param  {String} objectId clientId obiektu/podmoitu ubezpieczonego
         * @param  {String} productCode kod produktu
         * @param  {String} [riskCode]    kod ryzyka - uzywany dla ognia/kradziezy
         * @param  {String} [estateType]  typ nieruchomości
         * @param  {String} [riskGroup]  grupa ryzyk, o ile chcemy do niej zawężać - 'existingRisks' lub 'newRisks'
         * @return {Object|null} obiekt lub null gdy nie znaleziono
         */
        this.getAllowedAttributeForRisk = function(attrName, objectId, productCode, riskCode, estateType, riskGroup) {
          return dcAllowedHelper.getAllowedAttributeForRisk(attrName, objectId, productCode, riskCode, estateType, riskGroup);
        };

        /**
         * szuka i zwraca obiekt opisujący dozwolone zmiany dla ryzyka
         * @param  {String} objectId clientId obiektu/podmoitu ubezpieczonego
         * @param  {String} productCode kod produktu
         * @param  {String} [riskCode]    kod ryzyka - uzywany dla ognia/kradziezy
         * @param  {String} [estateType]  typ nieruchomości
         * @return {Object|null} obiekt lub null gdy nie znaleziono
         */
        this.findAllowedRisk = function(objectId, productCode, riskCode, estateType) {
          return dcAllowedHelper.findAllowedRisk(objectId, productCode, riskCode, estateType);
        };

        /**
         * czy ryzyko mieniowe(ogien/kradzież) dostępne przy aktualnym zaznaczeniu i sumach dla innych ryzyk
         * @param  {String}  productCode nazwa producktu const PRODUCT_OGI lub PRODUCT_KRA
         * @param  {String}  subRiskName nazwa subryzyka, np. const RISK_ESTATE, RISK_GARDEN itd.
         * @return {Boolean}
         */
        this.isLocalizationRiskAvailable = function(productCode, subRiskName) {
          return dcAmountHelper.isLocalizationRiskAvailable(productCode, subRiskName);
        };

        /**
         * czy istnieje polisa z podanym atrybutem - dynamicValue
         * @param  {String}  name nazwa atrybutu
         * @param  {*}  val  wartość atryburu
         * @return {Boolean}
         */
        this.isPolicyWithDynamicValue = function(name, val) {
          return dcCommonHelper.isPolicyWithDynamicValue(name, val);
        };

        /**
         * usuwa wpisy dotyczące konkretnego obiektu w selectedVariants
         * @param  {String} type wspierane typy: PRODUCT_TYPE_LOCALIZATION, PRODUCT_TYPE_VEHICLE, PRODUCT_TYPE_PERSON_GROUP
         * @param  {String} clientId clientId obiektu
         * @return {Boolean} true gdy usunieto
         */
        this.deleteVariantSelection = function(type, clientId) {
          return dcSelectionHelper.deleteVariantSelection(type, clientId);
        };

        /**
         * set declared client's roles
         * @param {string[]} roles where string is one of CONSTANTS.PERSON_ROLE_...
         */
        this.setDeclaredClientRoles = function(roles) {
          return dcCommonHelper.setDeclaredClientRoles(roles);
        };

        this.getApplicationStatus = function() {
          return dcCommonHelper.getApplicationStatus();
        };

        this.getPunuNumber = function() {
          return dcCommonHelper.getPunuNumber();
        };

        this.getSelectedRisks = function() {
          return dcCommonHelper.getSelectedRisks();
        };

        this.setProductClauses = function(productClauses) {
          return dcCommonHelper.setProductClauses(productClauses);
        };

        this.getProductClauses = function() {
          return dcCommonHelper.getProductClauses();
        };

        this.setDestinations = function(destinations) {
          return dcCommonHelper.setDestinations(destinations);
        };

        this.getDestinations = function() {
          return dcCommonHelper.getDestinations();
        };

        self.init();
      };

      return new DataContainerHelper();
    }
  ]);