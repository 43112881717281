angular.module('salesPath2')
  .controller('vehicleEquipmentModalCtrl', ['$scope', '$filter', 'vehicleModalHelper', 'vehicleHelper',
    function($scope, $filter, vehicleModalHelper, vehicleHelper) {
      $scope.selectedEquipment = {};
      $scope.additionalEquipment = [];
      $scope.inProgress = false;
      $scope.vehicle = vehicleModalHelper.modals.vehicleEquipmentModal.chosenVehicle;
      $scope.vehicleData = $scope.vehicle.getData();
      $scope.equipment = vehicleModalHelper.modals.vehicleEquipmentModal.equipment;
      $scope.filters = {
        phrase: ''
      };
      $scope.readonly = $scope.appVariables.readOnly;
      $scope.allowedChanges = vehicleHelper.currentlyEditedVehicleData.allowedChanges;

      /**
       * Init
       */
      $scope.init = function() {
        $scope.loadEquipment();
        $scope.fireListeners();
      };

      /**
       * Sortowanie
       */
      $scope.sortBy = function() {
        $scope.additionalEquipment = $filter('orderBy')($scope.additionalEquipment, 'DESC', $scope.reverseSort);
        $scope.activeSorter = 'name';
        $scope.reverseSort = !$scope.reverseSort;
      };

      /**
       * [fireListeners description]
       */
      $scope.fireListeners = function(){
        // ok na modalu
        $scope.$on('iHestiaCommonModalOkBtnClicked.vehicleEquipmentModal', function() {
          vehicleModalHelper.vehicleEquipmentSelected($scope.getSelectedEquipment());
        });

        // anuluj na modalu
        $scope.$on('iHestiaCommonModalCancelBtnClicked.vehicleEquipmentModal', function() {
          vehicleModalHelper.vehicleEquipmentEditCancel();
        });

        // zmiana typu lokalizacji
        $scope.$watch('modals.vehicleEquipmentModal.type', function(type){
          $scope.type = type;
        });

        // filtrowanie wyposażenia
        $scope.$on('equipmentFilterChanged', function($event, filters){
          $scope.filters = filters;
        });
      };

      /**
       * Zwraca zaznaczone wyposażenie
       * @return array
       */
      $scope.getSelectedEquipment = function() {
        var equipment = [];
        angular.forEach($scope.additionalEquipment, function(eq){
          if($scope.selectedEquipment[eq.id]) {
            equipment.push(eq);
          }
        });

        return equipment;
      };

      /**
       * Ładowanie wyposażenia
       */
      $scope.loadEquipment = function() {

        var setEquipmentFunc = function(equipment) {
          // zaznaczone wyposazenie z pojazdu
          if(angular.isArray(vehicleModalHelper.modals.vehicleEquipmentModal.selectedEquipment)) { //eslint-disable-line no-constant-condition
            angular.forEach(vehicleModalHelper.modals.vehicleEquipmentModal.selectedEquipment, function(cEquipment){
              $scope.selectedEquipment[cEquipment[1]] = true;
            });
          }
          // nasza lista
          $scope.additionalEquipment = equipment;
          // spinner
          $scope.inProgress = false;
        };

        if(!$scope.equipment) {
          vehicleHelper.getVehicleEquipment($scope.vehicleData.firstRegistration, $scope.vehicle.type[1], function(equipment){
            $scope.equipment = equipment;
            setEquipmentFunc(equipment);
          });
        } else {
          setEquipmentFunc($scope.equipment);
        }
      };

      $scope.init();
    }
  ]);