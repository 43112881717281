angular.module('salesPath2')
  .service('coownerHelper', ['mainDataContainer', '$timeout', 'actionHelper', 'CONSTANTS', 'vehicleHelper', 'localizationHelper', 'personHelper', 'addressHelper', '$filter', 'dataContainerHelper', 'allowedChangesHelper',
    function(mainDataContainer, $timeout, actionHelper, CONSTANTS, vehicleHelper, localizationHelper, personHelper, addressHelper, $filter, dataContainerHelper, allowedChangesHelper) {
        var CoownerHelper = function() {
            var self = this;
            /**
             * NOSONAR
             * @type {[type]}
             */
            var showedCoownerId = null; // eslint-disable-line no-unused-vars

            /*
            *Czy dla wskazanego obiektu są współwłaściciele
            */
            this.isCoownerListEmpty = function(objectType, objectId) {
              var object = dataContainerHelper.getObject(objectType, objectId);
              var coowners = object.getAdditionalData('coowners');

              return coowners.length === 0;
            };
            
            /*
            *zwraca nazwę obiektu do wyświetlenia
            */
            this.getObjectName = function(objectType, objectId) {
                var object = dataContainerHelper.getObject(objectType, objectId);

                var settings = {
                  elements: [{
                    field: 'mark'
                  }, {
                    field: 'model',
                    lastInLine: true
                  }, {
                    field: 'registration'
                  }],
                  maxLength: 16,
                  lineSeparator: ' '
                };

                var objectName = '';
                if (objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
                    objectName = vehicleHelper.getVehicleInfo(object, settings);
                } else {
                  if(mainDataContainer.localizations.length > 1){//tu spradzenie czy jest jedna lokalizacja
                    objectName = localizationHelper.getLocalizationInfo(object, {
                      fieldSeparator: ' ',
                      lineSeparator: ' ',
                      maxLength: 500,
                      truncateText: '…',
                      html: false,
                      emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
                    });
                  }
                }
                return objectName;
            };

            /*
            *nzwy osób do wyświetlenia
            */
            this.getPersonsInfo = function(coowners) {
              var persons = [];
              angular.forEach(coowners, function(coownerId) {
                persons.push({
                  id: coownerId,
                  data: mainDataContainer.persons[coownerId]
                });
              });

              angular.forEach(persons, function(v, i) {
                var trimSetting = {
                  elements: [ {
                    field: 'firstName'
                  },{
                    field: 'lastName',
                    lastInLine: true
                  },{
                    field: 'pesel'
                  }],
                  maxLength: 26
                };
                persons[i].info = personHelper.getPersonInfo(v.data, trimSetting);
                persons[i].id = v.id;
              });
              return persons;
            };

            /*
            *lista właścicieli (do wykluczenia - właściciel nie może b yć współwłaścicielem)
            */
            this.getOwners = function(objectType, objectId) {
              var object = dataContainerHelper.getObject(objectType, objectId);
              var coowners = object.getAdditionalData('coowners');
              var owners = angular.fromJson(angular.toJson(coowners));
              owners.push(parseInt(mainDataContainer.mainInsuredId, 10));

              return owners;
            };

            /*
            *zwraca dane wybranej osoby
            */
            this.getSelectedPersonData = function(personId) {
              var person = mainDataContainer.persons[personId];
              var settings = {
                elements: [{
                  field: 'firstName'
                }, {
                  field: 'lastName',
                  fieldSeparator: ', '
                }, {
                  field: 'pesel'
                }],
                maxLength: 50
              };
              var personData = personHelper.getFullPersonInfo(person);
              personData.personInfo = personHelper.getPersonInfo(person, settings);
              personData.id = personId;

              settings = {
                elements: [
                  {field: 'postalCode'}, 
                  {
                    field: 'city',
                    fieldSeparator: ', ',
                    optionalLineBreak: true, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
                    forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
                  },
                  {field: 'streetPrefix'},
                  {field: 'street'},
                  {field: 'house', fieldSeparator: '', firstInRow: false},
                  {field: 'apartment', prefix: '/', lastInLine: true}            
                ], 
                maxLength: 50,
                doTruncate: false
              };

              personData.addressInfo = '';

              if (personData.addresses !== null && personData.addresses.length > 0) {
                var address = addressHelper.getAdrStal(person.addresses);
                personData.addressInfo = addressHelper.getAddressInfo(address, settings);
              }

              personData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(mainDataContainer.persons[personId]);
              return personData;
            };

            this.getSelectedObject = function(objectType) {
              var selectedObjectId;
               var object = null;
              if (objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
                selectedObjectId = mainDataContainer.selectedVehicle;
                object = dataContainerHelper.getObject(objectType, selectedObjectId, 'clientId');
              } else if (objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                selectedObjectId = mainDataContainer.selectedLocalization;
                object = dataContainerHelper.getObject(objectType, selectedObjectId, 'clientId');
              }

              return object;
            };

            this.removeCoowner = function(personId, objectType) {
              var object = self.getSelectedObject(objectType);
              var coowners = object.getAdditionalData('coowners');
              if (coowners.indexOf(personId) !== -1) {
                coowners.splice(coowners.indexOf(personId), 1);
              }

              actionHelper.runAction('coownersChanged', {
                objectType: objectType,
                objectId: personId
              });
            };

        };

        return new CoownerHelper();
    }
  ]);