angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifeInsurerChangeAdditionalDataModalHelper', ['LsnModalHelper', 'IhestiaNotesAndUploader', 'IhestiaLifeInsurerChangeFlowConstants', 'lifePolicyOperationsHelper', 'policyOperationsV2Svc', '$filter', '$q', 'ihestiaConfigHelper',
    function(LsnModalHelper, IhestiaNotesAndUploader, IhestiaLifeInsurerChangeFlowConstants, lifePolicyOperationsHelper, policyOperationsV2Svc, $filter, $q, ihestiaConfigHelper) {
      /**
       * @typedef IhestiaLifeInsurerChangeAdditionalDataModalHelper
       * @extends LsnModalHelper
       */
      var IhestiaLifeInsurerChangeAdditionalDataModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        /**
         * @type {IhestiaLifeInsurerChangeAdditionalDataModalHelper}
         */
        var self = this;
        this.modalOptions = {
          size: 'md',
          title: ['policyDetails.insurerChange.modalTitle', {componentCode: 'Public'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/insurerChange/additionalData/insurerChangeAdditionalData.tpl.html',
          okBtnName: ['Public.confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          withDefaultMargin: false
        };

        this.setOptions(this.modalOptions);

        /**
         *
         * @type {{subject: LsnPersonModelV2 | LsnOrganizationModelV2 | null, policyNumber: string | null}}
         */
        this.modalData = {
          subject: null,
          policyNumber: null,
          policyStartDate: null
        };

        this.tplData = {
          notesAndUploaderHelper: null,
          subject: null,
          showInHistory: null,
          startDate: null,
          minDate: null,
          disabledElements: {
            startDate: true
          },
          saveInProgress: false,
          errors: null,
          warnings: null,
          isPerson: null
        };

        this.cancelPromise = null;

        this.init = function() {
          self.tplData.subject = _.get(self.modalData, 'subject');
          self.tplData.isPerson = self.tplData.subject.metaData.className === 'Person';
          self.tplData.showInHistory = true;
          self.setDates();
          /**
           * @type {IhestiaNotesAndUploaderOptions}
           */
          var notesAndUploaderOptions = {
            policyNumber: self.modalData.policyNumber
          };
          self.tplData.notesAndUploaderHelper = new IhestiaNotesAndUploader(notesAndUploaderOptions);
          self.tplData.notesAndUploaderLabels = {
            uploader: $filter('translate')('policyDetails.insurerChange.documentsLabel', {componentCode: 'Public'})
          };
          self.tplData.errors = null;
          self.tplData.warnings = null;
        };

        this.onChooseOtherClient = function() {
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.CLIENT_EDIT,
            value: {
              subject: self.tplData.subject,
              checkClausesOnSubject: true
            }
          });
          return self.hideModal();
        };

        this.setDates = function() {
          var today = new XDate(ihestiaConfigHelper.get('serverData', 'TODAY'));
          var policyStartDate = new XDate(self.modalData.policyStartDate);
          self.tplData.disabledElements.startDate = false;
          self.tplData.minDate = policyStartDate.toString('yyyy-MM-dd');
          // check if insurance period started
          var policyStarted = today - policyStartDate > 0;
          self.tplData.startDate = (policyStarted ? today : policyStartDate).toString('yyyy-MM-dd');
        };

        this.confirm = function() {
          self.tplData.errors = [];
          self.tplData.warnings = [];
          // policyNumber, subjectData, addresses, clauses, contacts, operationData
          var request = lifePolicyOperationsHelper.mapPolicyholderReplacementRequest(
            self.modalData.policyNumber,
            self.tplData.subject,
            self.tplData.subject.addresses,
            self.tplData.subject.clauses,
            _.filter(self.tplData.subject.contacts, lifePolicyOperationsHelper.propertyNotEmpty('value')),
            {
              comment: self.tplData.notesAndUploaderHelper.getNotes(),
              uploadedDocumentsIds: self.tplData.notesAndUploaderHelper.getFileServerIds(),
              showInHistory: self.tplData.showInHistory,
              validFrom: self.tplData.startDate
            }
          );
          self._setSaveInProgress(true);
          if (!self.cancelPromise) {
            self.cancelPromise = $q.defer();
          }
          policyOperationsV2Svc.post(self.modalData.policyNumber, request, 'policyholderReplacement', null, null, {
            timeout: self.cancelPromise.promise.then(lsnNg.noop, lsnNg.noop)
          }).then(function() {
            self.setPromiseAsResolved({
              type: IhestiaLifeInsurerChangeFlowConstants.SUCCESS
            });
            self.hideModal();
          }, function(err) {
            _.forEach(_.get(err, 'data.messages'), function(message) {
              if (message.type === 'WARN') {
                self.tplData.warnings.push(message);
              } else if (message.type !== 'INFO') {
                self.tplData.errors.push(message);
              }
            });
          })
            .finally(function() {
              self._setSaveInProgress(false);
              self.cancelPromise = null;
            });
        };

        this.cancel = function() {
          if (self.cancelPromise) {
            self.cancelPromise.reject('cancel');
          }
          self.setPromiseAsRejected();
          return self.hideModal();
        };

        /**
         * Set modal options accordingly for saveInProgress
         * @param {boolean} saveInProgress
         * @private
         */
        this._setSaveInProgress = function(saveInProgress) {
          self.setOptions(_.merge({}, self.modalOptions, {
            okBtnDisabled: saveInProgress
          }));
          self.tplData.saveInProgress = saveInProgress;
        };

      };
      return new IhestiaLifeInsurerChangeAdditionalDataModalHelper();
    }]);
