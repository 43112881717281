angular.module('ihestiaMenuBase')
  .service('taskCounterSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var TaskCounterSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks';
        this.businessArea = 'dashboard';

        this.getTasksCount = function(data, callback, errorCallback, params){
          return self.get(null, data, 'count', callback, errorCallback, params);
        };

      };

      return new TaskCounterSvc();
    }]);