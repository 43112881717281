angular.module('ihestiaIkomunikator.fullListPanel')
  .directive('ihestiaIkomunikatorrFullListPanel', ['$templateCache', 'ihestiaIkomunikatorUserTypeHelper',
    function($templateCache, ihestiaIkomunikatorUserTypeHelper) {
      var tpl = ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged() ? $templateCache.get('ihestiaIkomunikatorTemplatesApp/views/central/fullListPanel/fullListPanelCentralMain.tpl.html'):
      $templateCache.get('ihestiaIkomunikatorTemplatesApp/views/common/fullListPanel/fullListPanelMain.tpl.html');
      return {
        // restrict: 'E',
        // replace: true,
        template: tpl
      };
    }
  ]);