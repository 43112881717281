angular.module('lsnBase.models')
  .factory('LsnRiskVariantModelV1', ['LsnAbstractModel', 'LsnAmountModelConstants',
    function(LsnAbstractModel, LsnAmountModelConstants)
    {
      var LsnRiskVariantModelV1 = function()
      {
        this.objectName = 'RiskVariant';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          compId:
          {
            type: 'string'
          },
          tariffId:
          {
            type: 'string'
          },
          premium:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          option:
          {
            type: 'string'
          },
          objectRef:
          {
            type: 'array',
            elementsType: 'string'
          },
          subjectRef:
          {
            type: 'array',
            elementsType: 'string'
          }
        };

        this.compId = null; // string, Indentyfikator ryzyka w BOS.PTY (ComponentRef  idProductModule).
        this.tariffId = null; // string, Identyfikator taryfy ryzyka zdefiniowany w BOS.PTY (TariffRef idProductModule).
        this.premium = null; // Amount, skladka na ryzykach
        this.option = null; // string, Symbol opcji/wariantu. Pole opcjonalne. Czasami sam identyfika-tor ryzyka określa jego wariant. Pole będzie przyjmowało wartości identyczne z tymi z atrybutu coverageOption na ryzyku.
        this.subjectRef = null; // string, id podmiotu
        this.objectRef = null; // string, id obiektu

      };

      LsnRiskVariantModelV1.prototype = LsnAbstractModel.prototype;

      return LsnRiskVariantModelV1;
    }
  ]);