angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaPolicyReportModalCtrl', ['$scope', 'ihestiaPzaReportsSvc', 'ihestiaPzaPoicyReportModalHelper', 'ihestiaPzaCommonHelper',
    function($scope, ihestiaPzaReportsSvc, ihestiaPzaPoicyReportModalHelper, ihestiaPzaCommonHelper) {

      /**
       * Dane pomocnicze
       * @type {Object}
       */
      $scope.screenData = {
        period: 'all',
        agency: 'all'
      };

      /**
       * Parametry raportu
       * @type {Object}
       */
      $scope.reportParams = {
        agNumber: null,
        agName: null,
        startDate: null,
        endDate: null
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        // czyszczenie po sobie
        $scope.$on('$destroy', function() {
          ihestiaPzaPoicyReportModalHelper.clearData();
        });

        // kliknięcie ok
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaPzaPolicyReport', function($event) {
          $event.preventDefault();
          $scope.getReport();
        });
      };

      /**
       * Pobieranie rapportów
       * @return {[type]}         [description]
       */
      $scope.getReport = function() {
        $scope.$emit('showBlockUi');
        // pozbywamy się nulli
        var params = ihestiaPzaCommonHelper.getListParams($scope.reportParams);
        ihestiaPzaReportsSvc.get(ihestiaPzaPoicyReportModalHelper.programId, params, 'policy').then(function(res) {
          if (res && (res.status === 200 || res.status === 204)) {
            ihestiaPzaPoicyReportModalHelper.downloadReport(res.data.fileId).then(function(res){
              if(res && (res.status === 200 || res.status === 204)){
                ihestiaPzaPoicyReportModalHelper.hidePolicyReportModal();
              }
              $scope.$emit('hideBlockUi');
            }, angular.noop);
          } else {
            $scope.$emit('hideBlockUi');
          }
        }, function(){
          $scope.$emit('hideBlockUi');
        });
      };

    }
  ]);