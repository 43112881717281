angular.module('ihestiaWidgets.commonOperations')
  .factory('ihestiaOperationsTaskOperationsHelper', ['iHestiaCommonModalHelper',
    function(iHestiaCommonModalHelper) {
      var OperationsHelper = function() {
        var self = this;

        /**
         * Modal wyszukiwania klienta (access token)
         * @return {ihestiaOperationsTaskOperationsHelper} self
         */
        this.showClientSearch = function()
        {
          iHestiaCommonModalHelper.showModal('ihestiaOperationsClientSearch');
          return self;
        };


        /**
         * Modal wyszukiwania zadania
         * @return {ihestiaOperationsTaskOperationsHelper} self
         */
        this.showTaskSearch = function()
        {
          iHestiaCommonModalHelper.showModal('ihestiaOperationsTaskSearch');
          return self;
        };


      };

      return new OperationsHelper();
    }
  ]);