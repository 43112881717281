angular.module('salesPath2')
  .service('DiscountsHelperClass', ['$rootScope', 'PolicyDiscountsModel', 'mainDataContainer', 'SPD', 'CONSTANTS', 'sp2CommonHelper', 'RESOURCES', 'appVariables',
    'PolicyDiscountModelConstants', 'PolicyDiscountModel', 'errorHelper', 'resourceHelper', 'actionHelper', 'CONFIG', 'applicationHelper', 'supplementHelper',
    function($rootScope, PolicyDiscountsModel, mainDataContainer, SPD, CONSTANTS, sp2CommonHelper, RESOURCES, appVariables, PolicyDiscountModelConstants,
             PolicyDiscountModel, errorHelper, resourceHelper, actionHelper, CONFIG, applicationHelper, supplementHelper) {
      var DiscountsHelperClass = function() {
        var self = this;
        var DEFAULT_MODAL_DATA = {
          dataCleared: true,
          selectedTab: 'employee',
          policyId: null,
          risksList: null,
          employee: {
            options: [],
            selectedCode: null
          },
          promoCodes: {
            code: null,
            applied: false, //czy zastosowany - ma zanczenie czysto interfejsowe
            error: false //czy bledny kod promocyjny
          },
          deviatedPremium: {
            dict: [],
            agreementNumber: null,
            description: null,
            decisionMakerName: null,
            minimalPremiumReduced: null,
            selectedType: {}
          },
          authorizationExceeded: {
            disabled: false,
            dict: [],
            agreementNumber: null,
            description: null,
            decisionMakerName: null,
            selectedType: {}
          },
          policyDiscounts: null,
          readonly: appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer) //czy tryb tylko do odczytu
        };

        //ustawienia modali
        this.modals = {};

        /**
         * Ustawienia modala cesji
         * @type {Object}
         */
        this.modals.discountsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.discountsModal.title',
            okBtnName: 'sp2.modal.confirm',
            cancelBtnName: 'sp2.modal.cancel',
            size: 'lg'
          }
        };

        /**
         *
         * @type {Object}
         */
        this.modals.discountsDetailsModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.discountsDetailsModal.title',
            cancelBtnName: 'sp2.modal.close',
            okBtnName: '',
            size: 'md'
          }
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          if (actionName === 'readOnlyChanged') {
            self.updateSettingsByReadOnly();
          }
        };

        /**
         * aktualizuje domyślne ustawienia dla modali na podstawie informacji o trybie readOnly
         */
        this.updateSettingsByReadOnly = function() {
          DEFAULT_MODAL_DATA.readonly = appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer);
        };

        //kody błędów związanych z kodami promocyjnymi
        this.promoCodeMessageCodes = ['U000086' /** , '200063' **/]; //zapinamy sie tylko na message z FBT zgodnie z sugestia ze zgloszenia PRODUKTYSO-182
        //dozwolone ryzyka do obnizenia skladki
        this.allowedRisks = [CONSTANTS.RISK_OC, CONSTANTS.RISK_AC, CONSTANTS.RISK_NNWKIP, CONSTANTS.PRODUCT_OGI, CONSTANTS.PRODUCT_KRA, CONSTANTS.PRODUCT_OCZP, CONSTANTS.PRODUCT_NNW, CONSTANTS.PRODUCT_REHABILITATION, CONSTANTS.PRODUCT_SPORT_ASSISTANCE, CONSTANTS.PRODUCT_BAGGAGE, CONSTANTS.PRODUCT_MEDICAL_EXPENSES];
        //dozwolone dodatki do obnizenia skladki
        this.allowedAdditions = [];

        this.init = function() {
          if (CONFIG.BEHAVIOR.greenCardAsProd) {
            self.allowedAdditions.push(CONSTANTS.ADD_GREENCARD);
          }
        };

        /**
         * Czyscimy dane modala
         * @return {CessionModalHelper} [description]
         */
        this.clearModalData = function() {
          self.modalData = angular.copy(DEFAULT_MODAL_DATA);
          self.modalData.policyDiscounts = new PolicyDiscountsModel();
          return self;
        };
        self.clearModalData();

        /**
         * wczytuje dane do self.modalData na podstawie inicjalnie przekazanych danych/parametrow
         * (otwarcie modala)
         */
        this.loadData = function() {
          // dane polisy
          var policy = self.getActivePolicy();
          var policyType = sp2CommonHelper.getPolicyType(policy);
          var policyGrantBasis;
          if (policy === null) {
            sp2CommonHelper.throwException('Nie znaleziono polisy o numerze: ' + self.modalData.policyId);
            return;
          }

          // znizki pracownicze
          self.modalData.employee.options = self._getDiscountEmployeeOptions(policyType);
          self.modalData.deviatedPremium.dict = RESOURCES.DICT_AGREEMENT_DEVIATED_PREMIUM_GRANT_BASIS;
          self.modalData.authorizationExceeded.dict = RESOURCES.DICT_AGREEMENT_AUTHORIZATION_EXCEEDED_GRANT_BASIS;

          self.populateRisksList(self.modalData.policyId);
          self.modalData.dataCleared = false;
          self.modalData.policyDiscounts = self.getPolicyDiscounts(policy);

          self.modalData.risksList = self.getSimpleRisks(policy);

          //kody promocyjne
          if (self.modalData.policyDiscounts.promoCodes.code !== null) {
            self.modalData.promoCodes.code = self.modalData.policyDiscounts.promoCodes.code;
            self.modalData.promoCodes.applied = true;
            self.modalData.promoCodes.error = false;
            angular.forEach(errorHelper.getMessagesForPolicy(policy.number), function(error) {
              if (self.promoCodeMessageCodes.indexOf(error.code) !== -1) {
                self.modalData.promoCodes.error = true;
              }
            });
          }
          //znizki pracownicze
          self.setEmployeeSelectedCode();

          // wciągamy checkboxy odstępstwa od składki
          var grantBasisTable = {};
          if (self.modalData.policyDiscounts.agreement_DeviatedPremium) {
            grantBasisTable[self.modalData.deviatedPremium.dict.NR_ZG.BOSVAL] = 'NR_ZG';
            grantBasisTable[self.modalData.deviatedPremium.dict.ZG_TEL.BOSVAL] = 'ZG_TEL';
            grantBasisTable[self.modalData.deviatedPremium.dict.PROMO.BOSVAL] = 'PROMO';
            policyGrantBasis = self.modalData.policyDiscounts.agreement_DeviatedPremium.attributes.agreement_DeviatedPremium_GrantBasis[0];
            if (grantBasisTable[policyGrantBasis]) {
              self.modalData.deviatedPremium.selectedType[grantBasisTable[policyGrantBasis]] = self.modalData.deviatedPremium.dict[grantBasisTable[policyGrantBasis]];
            }
          }

          // wciągamy checkboxy przekroczenia pełnomocnictw
          if (self.modalData.policyDiscounts.agreement_AuthorizationExceeded) {
            grantBasisTable = {};
            grantBasisTable[self.modalData.authorizationExceeded.dict.NR_ZG.BOSVAL] = 'NR_ZG';
            grantBasisTable[self.modalData.authorizationExceeded.dict.ZG_TEL.BOSVAL] = 'ZG_TEL';
            policyGrantBasis = self.modalData.policyDiscounts.agreement_AuthorizationExceeded.attributes.agreement_AuthorizationExceeded_GrantBasis[0];
            if (grantBasisTable[policyGrantBasis]) {
              self.modalData.authorizationExceeded.selectedType[grantBasisTable[policyGrantBasis]] = self.modalData.authorizationExceeded.dict[grantBasisTable[policyGrantBasis]];
            }
          }
        };

        /**
         * get appropriate DISCOUNT_EMPLOYEE_... options
         * @param policyType one of CONSTANTS.POLICY_TYPE_...
         * @private
         * @return {Object[]} data array from RESOURCES.DISCOUNT_EMPLOYEE_...
         */
        this._getDiscountEmployeeOptions = function(policyType) {
          var sourceOpts = (policyType === CONSTANTS.POLICY_TYPE_COMMUNICATION) ? RESOURCES.DISCOUNT_EMPLOYEE_COMMUNICATION : RESOURCES.DISCOUNT_EMPLOYEE_ESTATE;
          var opts = [];
          angular.forEach(sourceOpts, function(option) {
            if (!_.isArray(option.AGENCY_NUMBERS) || _.includes(option.AGENCY_NUMBERS, sp2CommonHelper.getUserAgencyNumber())) {
              opts.push(option);
            }
          });
          return opts;
        };

        /**
         * ustawia modalData.employee.selectedCode na podstawie danych policyDiscounts
         */
        this.setEmployeeSelectedCode = function() {
          angular.forEach(self.modalData.employee.options, function(opt) {
            if (
              (self.modalData.policyDiscounts.employee.attributes.discountEmployee !== null && self.modalData.policyDiscounts.employee.attributes.discountEmployee.toString() === opt.BOSCODE && opt.TYPE === 'discountEmployee') || (self.modalData.policyDiscounts.employee.attributes.discountGeneralAgreement !== null && self.modalData.policyDiscounts.employee.attributes.discountGeneralAgreement.toString() === opt.BOSCODE && opt.TYPE === 'discountGeneralAgreement')
            ) {
              self.modalData.employee.selectedCode = opt.CODE;
            }
          });
        };

        /*
         * Agregacja listy ryzyk
         */
        this.populateRisksList = function(policyNumber) {
          self.risksList = [];

          if (angular.isObject(mainDataContainer.simpleRisks) && angular.isDefined(mainDataContainer.simpleRisks[policyNumber])) {
            angular.forEach(mainDataContainer.simpleRisks[policyNumber], function(item) {
              if (angular.isDefined(item.code) && (self.allowedRisks.indexOf(item.code) >= 0 || self.allowedAdditions.indexOf(item.code) >= 0)) {
                self.risksList.push(item);
              }
            });
          }
        };


        /**
         * pobiera obiekt PolicyDiscountsModel dla modalData
         * @return {PolicyDiscountsModel}
         */
        this.getPolicyDiscounts = function(policy) {
          var policyDiscounts = angular.isUndefined(mainDataContainer.policiesDiscounts[policy.number]) ? {} : angular.copy(mainDataContainer.policiesDiscounts[policy.number]);

          //nie pokazujemy ryzyk które już zniknęły z polisy
          if (angular.isDefined(policyDiscounts.discountOnRisk)) {
            var discountOnRisk = [];
            angular.forEach(policyDiscounts.discountOnRisk, function(riskDiscount) {

              var matchFound = false;
              lsnNg.forEach(self.risksList, function(risk) {
                if (risk.code === PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY[riskDiscount.key]) {
                  matchFound = true;
                  return false;
                }
                return true; //continue
              });
              if (matchFound) {
                discountOnRisk.push(riskDiscount);
              }
            });
            policyDiscounts.discountOnRisk = discountOnRisk;
          }

          return policyDiscounts;
        };

        /**
         * Pobiera listę ryzyk podlegających promocji
         */
        this.getSimpleRisks = function() {
          var risks = [];
          var foundKeys = {};
          var discountRisks = self.modalData.policyDiscounts.discountOnRisk;

          angular.forEach(self.risksList, function(risk) {
            var key = null;

            lsnNg.forEach(PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY, function(riskCode, discountKey) {
              if (riskCode === risk.code) {
                key = discountKey;
                return false;
              }

              return true; //continue
            });

            if (key !== null && !angular.isDefined(foundKeys[key])) {
              var discountValue = null,
                riskName;
              if (discountRisks.length > 0) {
                angular.forEach(discountRisks, function(dRisk) {
                  if (dRisk.key === key) {
                    discountValue = dRisk.value;
                  }
                });
              }

              if (self.allowedAdditions.indexOf(risk.code) !== -1) {
                riskName = resourceHelper.getAdditionName(risk.code, 'DISCOUNTNAME');
              } else {
                riskName = resourceHelper.getProductName(risk.code, 'DISCOUNTNAME', risk.variant);
              }

              risks.push({
                name: riskName,
                key: key,
                value: discountValue
              });
              foundKeys[key] = true;
            }
          });

          return risks;
        };

        /**
         * zwraca obiekt aktywnej polisy na podstawie modalData.policyId
         * @return {PolicyModel} polisa
         */
        this.getActivePolicy = function() {
          var policy = null;
          angular.forEach(mainDataContainer.policies, function(pol) {
            if (pol.get('number') === self.modalData.policyId) {
              policy = pol;
            }
          });
          return policy;
        };

        //zapis danych z modala
        this.save = function() {
          self.setEmployeeOptionFromSelectedCode();

          var policy = self.getActivePolicy();
          var policyDiscounts = self.modalData.policyDiscounts;

          policyDiscounts.promoCodes.code = self.modalData.promoCodes.code;

          if (policy) {
            policyDiscounts.discountOnRisk = [];
            angular.forEach(self.modalData.risksList, function(val) {
              var policyDiscount = new PolicyDiscountModel();
              policyDiscount.set('key', val.key);
              policyDiscount.set('value', val.value);
              policyDiscounts.discountOnRisk.push(policyDiscount);
            });

            angular.forEach(policyDiscounts.discountOnRisk, function(element) {
              element.set('enabled', true);
              element.set('unit', PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_PERCENT);
            });

            // zgoda na odstępstwo od składki
            if (self.modalData.policyDiscounts.agreement_DeviatedPremium.selected) {
              policyDiscounts.agreement_DeviatedPremium = self.modalData.policyDiscounts.agreement_DeviatedPremium; //eslint-disable-line camelcase
            }

            // zgoda na przekroczenie pełnomocnictw
            if (self.modalData.policyDiscounts.agreement_AuthorizationExceeded.selected) {
              policyDiscounts.agreement_AuthorizationExceeded = self.modalData.policyDiscounts.agreement_AuthorizationExceeded; //eslint-disable-line camelcase
            }

            mainDataContainer.policiesDiscounts[policy.number] = policyDiscounts;

            actionHelper.runAction('tarifficate');
          }
        };

        /**
         * ustawia wartosci dla atrybutow bosowych w policyDiscounts.employee.attributes w zaleznosci od zaznaczonego kodu na GUI
         */
        this.setEmployeeOptionFromSelectedCode = function() {
          angular.forEach(self.modalData.employee.options, function(opt) {
            if (self.modalData.employee.selectedCode === opt.CODE) {
              angular.forEach(self.modalData.policyDiscounts.employee.attributes, function(val, attr) {
                if (attr === opt.TYPE) {
                  self.modalData.policyDiscounts.employee.attributes[attr] = opt.BOSCODE;
                } else {
                  self.modalData.policyDiscounts.employee.attributes[attr] = null;
                }
              });
            }
          });
        };

        /**
         * [showDiscountsDetailsModal otwiera popup szczgolow znizek]
         * @return {[type]} [description]
         */
        this.showDiscountsDetailsModal = function() {
          sp2CommonHelper.showModal('discountsDetailsModal');
        };

        /**
         * obsługa żądania otwarcia modala znizek
         * @return {[type]} [description]
         */
        this.showEditDiscountsModal = function(policyId, tab) {
          self.modalData.policyId = policyId;
          if (angular.isString(tab)) {
            self.modalData.selectedTab = tab;
          }

          self.justShowEditDiscountsModal();
        };

        //właściwe wyświetlenie modala znizek/odstepstw
        this.justShowEditDiscountsModal = function() {
          self.modals.discountsModal.settings.okBtnName = 'sp2.modal.confirm';
          self.modals.discountsModal.settings.cancelBtnName = 'sp2.modal.cancel';
          /**
           * used only for "discount" tabs
           * EDIT
           * - https://ebok.atena.pl/browse/IHESTIAPRO-3535 - disable for OC buyer
           * @type {boolean}
           */
          self.modalData.readonly = appVariables.isSupplement || appVariables.readOnly;
          if (appVariables.readOnly) { // since now, authorizationExceeded tab has same disable condition as discounts tabs
            self.modalData.authorizationExceeded.disabled = true;
            self.modals.discountsModal.settings.okBtnName = 'sp2.modal.close';
            self.modals.discountsModal.settings.cancelBtnName = '';
          } else if (appVariables.isSupplement) {
            self.modalData.authorizationExceeded.disabled = supplementHelper.getAllowedValuesForPolicyDynamicValues('agreement_AuthorizationExceeded') === null;
            //jesli jestesmy w trybie dokupien to albo mozemy zmieniac cos na authorizationExceeded albo nigdzie
            if (self.modalData.authorizationExceeded.disabled) {
              self.modals.discountsModal.settings.okBtnName = 'sp2.modal.close';
              self.modals.discountsModal.settings.cancelBtnName = '';
            }
          }

          sp2CommonHelper.showModal('discountsModal');
        };

        this.init();
      };

      return DiscountsHelperClass;
    }
  ]);