angular.module('ihestiaRestServicesBase')
  .factory('checkTokenSvc', [ 'IHestiaRestServiceLsnDef',
    function(IHestiaRestServiceLsnDef) {

      var CheckTokenSvc = function()
      {
        IHestiaRestServiceLsnDef.apply(this, arguments);
        var self = this;
        this.resource = 'token';
        this.isValidToken = function(data, callback) {
          return self.post('isValidToken', data, '', callback);
        };
      };

     return new CheckTokenSvc();
  }])
  ;