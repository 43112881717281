/**
* Dyrektywa do wrzucenia szablonu przyciku/boksu komunikatów do menu
* @param  {[type]}  [description]
* @return {[type]}  [description]
*/
angular.module('salesPath2')
  .directive('ihestiaSalesPathMenuMessageBox', ['$templateCache',
    function($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        template: $templateCache.get('salesPath2Template/common/messages/menuMessageBox/menuMessageBox.tpl.html')
      };
    }
  ]);