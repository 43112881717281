angular.module('lsnBase.models')
  .factory('LsnBeneficiaryModelConstants', ['$filter',
    function($filter){

      var LsnBeneficiaryConstants = {};
      LsnBeneficiaryConstants.BENEFICIARY_TYPE_MAIN = 'MAIN';
      LsnBeneficiaryConstants.BENEFICIARY_TYPE_ADDITIONAL = 'ADDITIONAL';

      LsnBeneficiaryConstants.SUBJECT_TYPE_PERSON = 'PERSON';
      LsnBeneficiaryConstants.SUBJECT_TYPE_ORGANIZATION = 'ORGANIZATION';

      LsnBeneficiaryConstants.DICTIONARY_BENEFICIARY_TYPE = {};
      LsnBeneficiaryConstants.DICTIONARY_BENEFICIARY_TYPE[LsnBeneficiaryConstants.BENEFICIARY_TYPE_MAIN] = $filter('translate')('beneficiaryModal.beneficiaryType.main', {componentCode: 'Public'});
      LsnBeneficiaryConstants.DICTIONARY_BENEFICIARY_TYPE[LsnBeneficiaryConstants.BENEFICIARY_TYPE_ADDITIONAL] = $filter('translate')('beneficiaryModal.beneficiaryType.additional', {componentCode: 'Public'});

      LsnBeneficiaryConstants.DICTIONARY_SUBJECT_TYPE = {};
      LsnBeneficiaryConstants.DICTIONARY_SUBJECT_TYPE[LsnBeneficiaryConstants.SUBJECT_TYPE_PERSON] = $filter('translate')('beneficiaryModal.naturalPerson', {componentCode: 'Public'});
      LsnBeneficiaryConstants.DICTIONARY_SUBJECT_TYPE[LsnBeneficiaryConstants.SUBJECT_TYPE_ORGANIZATION] = $filter('translate')('editSubject.legalPerson', {componentCode: 'Public'});
      return LsnBeneficiaryConstants;
    }]);