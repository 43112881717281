angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorContextHelper', ['iKomunikatorConstants',
    function(iKomunikatorConstants) {
      var ContextHelper = function() {
        var self = this;
        //nazwa formularza
        this.formName = iKomunikatorConstants.FORM_NAME_NEW_MESSAGE;
        //nazwa systemu
        this.systemContext = iKomunikatorConstants.SYSTEM_CONTEXT_PORTAL;
        //nazwa brandu
        this.brand = iKomunikatorConstants.BRAND_IHESTIA;
        //nazwa kategorii
        this.category = null;
        //nazwa podtematu
        this.categoryId = null;
        //id tematu
        this.subjectId = null;
        //id polisy
        this.policyId = null;
        //numer polisy
        this.policyNumber = null;
        //id oferty
        this.offerId = null;
        //numer oferty
        this.offerNumber = null;
        //id szkody
        this.claimId = null;
        //numer szkody
        this.claimNumber = null;
        //numer zadania w dashbordzie
        this.dashboardTaskId = null;
        //mozliwe kanaly komunikacji
        this.channels = [];
        //domyslnie wybrany kanal
        this.channelSelected = null;

        //elementy konfiguracji do nadpisania dla przypadku
        //nowy odbiorca
        this.ifNewRecipient = null;
        //wyszukany odbiorca
        this.ifClientSearched = null;
        //jakies specyficzne ustawienia dla wywolania
        //dostepne opcje
        //clientSearchAvailable - czy dostepny jest wybor dowolnego odbiorcy
        //ifClientNotFindGoToSearchPage - czy w momencie gdy nie znaleziono klienta ma przejsc do strony wyszukiwania(zamiast rzucic bledem), przy wywolaniu okno w kontekscie jakiesgos odbiorcy
        //otherRecipientInContextClient - flaga mowiaca o tym np ze nie ma byc pola email, nr telefonu - np kontekst klienta ale piszemy do likwidatora
        //selectDefaultSubjectContextItem - czy wyszukac przekazany numer, a jesli nie bedzie wystepowac to ustawic domyslnie na inny
        //subjectIsFirst - jesli true, to temat na samej gorze
        //recipientMounted - czy odbiorca jest juz ustawiony
        //subjectContextItemsAvailable - czy dostepna jest lista dotyczy
        //additionalInputAvailable - jesli dostepne to potrzbne tez dotyczy chociaz ukryte
        //clearForcedNotificationChannelName - kanal komunikacji setowany na null
        //showElements
        ////showElements.channels - czy pokazac tematy na widoku bez recipientMounted
        ////showElements.recipients - czy pokazac odbiorcow na widoku recipientMounted
        this.settings = {};

        /**
         * funkcja zwraca konteksty do wysłania w headers
         */
        this.getContextForHeaders = function() {
          var contextHeader = {};
          contextHeader['ikomunikator-context-form'] = self.formName;
          contextHeader['ikomunikator-context-system'] = self.systemContext;
          contextHeader['ikomunikator-context-brand'] = self.brand;

          return contextHeader;
        };

        /**
         * funkcja zwraca domyślne konteksty do wysłania w headers
         */
        this.getDefaultContextForHeaders = function() {
          var contextHeader = {};
          contextHeader['ikomunikator-context-form'] = iKomunikatorConstants.FORM_NAME_NEW_MESSAGE;
          contextHeader['ikomunikator-context-system'] = iKomunikatorConstants.SYSTEM_CONTEXT_PORTAL;
          contextHeader['ikomunikator-context-brand'] = iKomunikatorConstants.BRAND_IHESTIA;

          return contextHeader;
        };

        /**
         * [get zwraca atrybut obiektu]
         * @param  {[type]} nameAttr [nazwa atrybutu]
         * @return {[type]} [zwraca atrybut obiektu]
         */
        this.get = function(nameAttr) {
          return self[nameAttr];
        };

        this.getDefaultFormName = function() {
          return iKomunikatorConstants.FORM_NAME_NEW_MESSAGE;
        };

        /**
         * [clearContext czyści wywołanie kontekstowe do ustawień domyślnych]
         */
        this.clearContext = function() {
          self.formName = iKomunikatorConstants.FORM_NAME_NEW_MESSAGE;
          self.systemContext = iKomunikatorConstants.SYSTEM_CONTEXT_PORTAL;
          self.brand = iKomunikatorConstants.BRAND_IHESTIA;
          self.category = null;
          self.categoryId = null;
          self.subjectId = null;
          self.policyId = null;
          self.policyNumber = null;
          self.offerId = null;
          self.offerNumber = null;
          self.claimId = null;
          self.claimNumber = null;
          self.dashboardTaskId = null;
          self.channelSelected = null;
          self.channels = [];
          self.settings = {};
        };

        /**
         * [getContext zwraca wszystkie atrybuty w plain object ]
         * @return {[object]} [description]
         */
        this.getContext = function() {
          return {
            formName: self.formName,
            systemContext: self.systemContext,
            brand: self.brand,
            category: self.category,
            categoryId: self.categoryId,
            subjectId: self.subjectId,
            policyId: self.policyId,
            policyNumber: self.policyNumber,
            offerId: self.offerId,
            offerNumber: self.offerNumber,
            claimId: self.claimId,
            claimNumber: self.claimNumber,
            dashboardTaskId: self.dashboardTaskId,
            channelSelected: self.channelSelected,
            channels: self.channels,
            settings: self.settings,
            ifNewRecipient: self.ifNewRecipient,
            ifClientSearched: self.ifClientSearched
          };
        };

        /**
         * [contextContainSubjectContextItem sprawdza czy wywołanie kontekstowe zawiera konkretne dotyczy]
         * @return {boolean}
         */
        this.contextContainSubjectContextItem = function() {
          return self.policyNumber !== null || self.offerNumber !== null || self.claimNumber !== null;
        };

        /**
         * [getSubjectContextItem description]
         * @return {[type]} [description]
         */
        this.getSubjectContextItem = function() {
          if (self.policyId !== null || self.policyNumber !== null) {
            return {
              objectId: self.policyId,
              objectNumber: self.policyNumber,
              objectType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_POLICY,
              userNumberValue: false
            };
          } else if (self.offerId !== null) {
            return {
              objectId: self.offerId,
              objectNumber: self.offerNumber,
              objectType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_OFFER,
              userNumberValue: false
            };
          } else if (self.claimId !== null) {
            return {
              objectId: self.claimId,
              objectNumber: self.claimNumber,
              objectType: iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INJURY,
              userNumberValue: false
            };
          }

          return null;
        };

        /**
         * [setFormName pobiera numer dla subjectContextItem]
         * @param {object} context [obiekt danych kontekstu]
         */
        this.getSubjectContextNumber = function() {
          return self.policyNumber || self.offerNumber || self.claimNumber;
        };

        /**
         * [setFormName metoda setująca atrybuty na podstawie obiektu danych]
         * @param {object} context [obiekt danych kontekstu]
         */
        this.setContext = function(context) {
          self.clearContext();

          if (!angular.isObject(context)) {
            return;
          }
          if (angular.isDefined(context.formName)) {
            self.formName = context.formName;
          }
          if (angular.isDefined(context.systemContext)) {
            self.systemContext = context.systemContext;
          }
          if (angular.isDefined(context.brand)) {
            self.brand = context.brand;
          }
          if (angular.isDefined(context.subjectId)) {
            self.subjectId = context.subjectId;
          }
          if (angular.isDefined(context.category)) {
            self.category = context.category;
          }
          if (angular.isDefined(context.policyId)) {
            self.policyId = context.policyId;
          }
          if (angular.isDefined(context.policyNumber)) {
            self.policyNumber = context.policyNumber;
          }
          if (angular.isDefined(context.offerId)) {
            self.offerId = context.offerId;
          }
          if (angular.isDefined(context.offerNumber)) {
            self.offerNumber = context.offerNumber;
          }
          if (angular.isDefined(context.claimId)) {
            self.claimId = context.claimId;
          }
          if (angular.isDefined(context.claimNumber)) {
            self.claimNumber = context.claimNumber;
          }
          if (angular.isDefined(context.dashboardTaskId)) {
            self.dashboardTaskId = context.dashboardTaskId;
          }
          if (angular.isDefined(context.channels)) {
            self.channels = context.channels;
          }
          if (angular.isDefined(context.settings)) {
            self.settings = context.settings;
          }
          if (angular.isDefined(context.channelSelected)) {
            self.channelSelected = context.channelSelected;
          }
        };

        this.getSettings = function() {
          return self.settings;
        };

        this.isChannelSet = function() {
          return self.channels.length > 0;
        };

        this.getChannels = function() {
          var channels = [];
          angular.forEach(self.channels, function(type) {
            var channel = {
              type: type
            };

            if (type === iKomunikatorConstants.CHANNEL_NAME_SMS) {
              channel.label = 'SMS';
            } else if (type === iKomunikatorConstants.CHANNEL_NAME_EMAIL) {
              channel.label = 'EMAIL';
            } else if (type === iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT) {
              channel.label = 'KOMUNIKAT';
            }

            channels.push(channel);
          });

          return channels;
        };

        this.setParamsContext = function(name, value){
          self[name] = value;
        };
      };

      return new ContextHelper();
    }
  ]);