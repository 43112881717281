angular.module('lsnBase')
  .provider('parseSvcValidatorsConfig', [function(){
    var self = this;

    /**
     * Czy czyścimy błędy walidacji na blurze
     * @type {Boolean}
     */
    this.clearErrorOnBlur = true;

    /**
     * Czy czyścimy błędy walidacji na zmianie
     * @type {Boolean}
     */
    this.clearErrorOnChange = false;

    /**
     * [description]
     * @return {Object}
     */
    this.$get = [function(){
      return self;
    }];
  }])
  .directive('parseSvcValidators', ['$parse', '$timeout', 'lsnCommonErrorHandler', 'parseSvcValidatorsConfig',
    function($parse, $timeout, lsnCommonErrorHandler, parseSvcValidatorsConfig) {
      // Runs during compile
      return {
        link: function($scope, iElm, iAttrs) {
          var validationResponse = null;
          var mainForm = null;

          /**
           * * Czy czyścimy błędy walidacji na blurze
           */
          var clearErrorOnBlur = parseSvcValidatorsConfig.clearErrorOnBlur;

          if(iAttrs.clearErrorOnBlur) {
            $scope.$watch(iAttrs.clearErrorOnBlur, function(clearError){
              clearErrorOnBlur = !!clearError;
            });
          }

          /**
           * * Czy czyścimy błędy walidacji na blurze
           */
          var clearErrorOnChange = parseSvcValidatorsConfig.clearErrorOnChange;

          if(iAttrs.clearErrorOnBlur) {
            $scope.$watch(iAttrs.clearErrorOnChange, function(clearError){
              clearErrorOnChange = !!clearError;
            });
          }

          // czyścimy walidację z usługi na blur
          $timeout(function() {
            $scope.reloadEvents();
          }, 0);


          /**
           * Ustawiamy walidację dla zadanego pola
           * @param {object}  input        angular.element()
           * @param {Boolean} isValid      [description]
           * @param {undefined}
           */
          var setValidityForInput = function(input, isValid, errorMessage) {
            if (input && input.length > 0) {
              var formObject = mainForm;
              var formElement;
              var parentForms = input.parents('[save-form-ref],ng-form,[ng-form]'); // zbieramy wszystkie formy rodziców
              var parentFormsArray = [];
              angular.forEach(parentForms, function(form){
                parentFormsArray.push(form);
              });

              angular.forEach(parentFormsArray.reverse(), function(form){ // i iterujemy się po nich schodząc w głąb obiektu angularowego forma
                formElement = angular.element(form);
                if(formElement.attr('save-form-ref'))
                {
                  formObject = $parse(formElement.attr('save-form-ref'))($scope);
                }
                else if(formElement.attr('name') && formObject[formElement.attr('name')])
                {
                  formObject = formObject[formElement.attr('name')];
                }
                else if(formElement.attr('ng-form') && formObject[formElement.attr('ng-form')])
                {
                  formObject = formObject[formElement.attr('ng-form')];
                }
              });

              if (!formObject) {
                lsnCommonErrorHandler.throwException('[parseSvcValidators] - no form object!', true);
              }
              // skoro wiemy jaki to input, to możemy podziałać na obiekcie formularza
              formObject[input.attr('name')].$setValidity('service', isValid);
              // data-content dla popoovera
              formObject[input.attr('name')].errorMessage = formObject[input.attr('name')].errorMessage ? (angular.isDefined(errorMessage) ? (formObject[input.attr('name')].errorMessage + '\n' + errorMessage ) : null) : errorMessage;
              $timeout(lsnNg.noop, 0); // po to, żeby wywołać apply() na scope
            }
          };

          /**
           * Parsujemy walidację z usługi i robimy machlojki w formie
           * @return {undefined}
           */
          var parseValidation = function() {
            if (angular.isArray(validationResponse)) {
              angular.forEach(validationResponse, function(validationObject) {
                // odnajdujemy odpowiadający input
                var input = angular.element('[svc-validator-name="' + validationObject.field + '"]');
                setValidityForInput(input, false, validationObject.defaultMessage ? validationObject.defaultMessage : validationObject.default_message);
              });
            }
          };

          var clearValidationForForm = function(form)
          {
            if(form && form.$error && form.$error.service)
            {
              // zmieniam czyszczenie błędów na iterację po wszystkich polach
              // gdyż w innym wypadku mamy jakieś hazardy
              // np. https://ebok.atena.pl/browse/EHLCUMEV-141
              angular.forEach(form, function(element, elementName){
                if(elementName[0] !== '$' && angular.isFunction(element.$setValidity)) {
                  // nie jest to element wewnętrzy angulara, tylko element formularza
                  element.$setValidity('service', true);
                  if(element.errorMessage) {
                    element.errorMessage = null;
                  }
                }
              });
            }
          };

          /**
           * Przeładowanie
           * @return {[type]} [description]
           */
          var reloadFunc = function(event) {
            setValidityForInput(angular.element(event.target), true);
          };
          $scope.reloadEvents = function() {
            $timeout(function() {
              if(clearErrorOnBlur) {
                var elementsWithBlur = iElm.find('[svc-validator-name]:not([svc-validator-name=""])');
                elementsWithBlur.off('blur', reloadFunc).on('blur', reloadFunc);

                $scope.$on('$destroy', function(){
                  elementsWithBlur.off('blur', reloadFunc);
                });
              }
              if(clearErrorOnChange) {
                var elementsWithChange1 = iElm.find('[type=checkbox][svc-validator-name]:not([svc-validator-name=""])');
                elementsWithChange1.off('change', reloadFunc).on('change', reloadFunc);
                $scope.$on('$destroy', function(){
                  elementsWithChange1.off('change', reloadFunc);
                });

                var elementsWithChange2 = iElm.find('[svc-validator-name]:not([svc-validator-name=""])');
                elementsWithChange2.off('change', reloadFunc).on('change', reloadFunc);
                $scope.$on('$destroy', function(){
                  elementsWithChange2.off('change', reloadFunc);
                });
              }
            }, 0);
          };
          $scope.$on('validatorFieldsChanged', $scope.reloadEvents);

          // zwrotka z walidacji z usług
          $scope.$watch(iAttrs.parseSvcValidators, function(response) {
            validationResponse = response;
            mainForm = $parse(iElm.attr('name'))($scope);
            if (mainForm) {
              mainForm.messages = {};
              clearValidationForForm(mainForm);
            }
            parseValidation();
          }, true);

        }
      };
    }]);