/**
 * Mapper BM
 */
angular.module('salesPath2').service('toRestBonusMalusMapper', ['MapperUtils', 'BonusMalusDataObjectModel',
  function (MapperUtils, BonusMalusDataObjectModel)
  {
    var ToRestBonusMalusMapper = function ()
    {

      this.utils = MapperUtils;

      this.mapBonusMalusData = function (dataContainer)
      {
        var bonusMalusData = [];

        lsnNg.forEach(dataContainer.bonusMalus, function (vehicles, personId)
        {
          lsnNg.forEach(vehicles, function (bonusMalus, vehicleId)
          {
            lsnNg.forEach(bonusMalus.risks, function (dataSet, riskName)
            {
              if (dataSet !== null)
              {
                var bonusMalusDataObject = new BonusMalusDataObjectModel();
                bonusMalusDataObject.personRef = dataContainer.persons[personId].metaData.get('id');
                bonusMalusDataObject.vehicleRef = dataContainer.vehicles[vehicleId].metaData.get('id');
                bonusMalusDataObject.riskName = riskName;
                bonusMalusDataObject.currentClass = dataSet.currentClass;
                bonusMalusDataObject.data = angular.fromJson(angular.toJson(dataSet.data));
                lsnNg.forEach(bonusMalusDataObject.data, function (value, key)
                {
                  if (value === null)
                  {
                    delete bonusMalusDataObject.data[key];
                  }
                  else if (angular.isObject(value))
                  {
                    lsnNg.forEach(value, function (bmDataValue, bmDataKey)
                    {
                      if (bmDataValue === null)
                      {
                        delete value[bmDataKey];
                      }
                    });
                  }
                });
                bonusMalusData.push(bonusMalusDataObject);
              }
            });
          });
        });
        return bonusMalusData;
      };
    };

    return new ToRestBonusMalusMapper();
  }
]);