angular.module('salesPath2')
  .service('InsurerContactDataModalHelperClass', ['CONFIG', 'dataContainerHelper', 'LsnClausesModelConstants', 'LsnContactModelConstants', 'personHelper', 'applicationHelper',
    function(CONFIG, dataContainerHelper, LsnClausesModelConstants, LsnContactModelConstants, personHelper, applicationHelper) {
      var InsurerContactDataModalHelperClass = function() {
        var self = this;

        this.tplData = {
          mail: '', // insurer mail
          clause: false, // clause state check/unchecked
          errors: { // if there are any errors, their content/text will be here
            mail: null,
            clause: null
          },
          clauseConfig: CONFIG.OFFER_VIA_EMAIL.clause,
          loadingData: false
        };

        this.insurerData = {};

        this.initData = function() {
          self.insurerData = dataContainerHelper.getInsurer().getData();
          var mailContact = self._getEmailContact(self.insurerData);
          if (mailContact) {
            self.tplData.mail = mailContact.value;
          }
          self.tplData.clause = self.insurerData.clauses && self.insurerData.clauses[CONFIG.OFFER_VIA_EMAIL.clause.code] === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
        };

        /**
         * prepare and patch insurer data by service cll
         * NOTE
         * We don't update mainDataContainer insurer data
         * @return {*|HttpPromise}
         */
        this.patchInsurerData = function() {
          self.tplData.errors.mail = null;
          self.tplData.errors.clause = null;
          var emailContact = self._getEmailContact(self.insurerData);
          if (!emailContact) {
            emailContact = {
              code: LsnContactModelConstants.CONTACT_TYPE_MAIL,
              value: self.tplData.mail,
              countryPrefix: null
            };
            if (!_.isArray(self.insurerData.contacts)) {
              self.insurerData.contacts = [];
            }
            self.insurerData.contacts.push(emailContact);
          } else {
            emailContact.value = self.tplData.mail;
          }
          self.insurerData.clauses[CONFIG.OFFER_VIA_EMAIL.clause.code] = self.tplData.clause ? LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT : LsnClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT;
          return applicationHelper.patchInsurerContactData(self.insurerData);
        };

        /**
         * very simple error message parsing and comple self.tplData.errors data
         * We expect only contact.mail or cluases[defined_code_form_config] errors
         * @param {LsnMessageModelV1[]} messages
         */
        this.parseErrorMessages = function(messages) {
          angular.forEach(messages, function(msg) {
            if (self._isErrorForProperty('clause', msg.object)) {
              self.tplData.errors.clause = msg.text;
            } else if (self._isErrorForProperty('mail', msg.object)) {
              self.tplData.errors.mail = msg.text;
            }
          });
        };

        /**
         * is message pointing to specific person property
         * @param {'clause'|'mail'} property
         * @param {string} msgObjectPointer LsnMessageModelV1.object value
         * @return {boolean}
         * @private
         */
        this._isErrorForProperty = function(property, msgObjectPointer) {
          if (property === 'clause') {
            return msgObjectPointer.toLowerCase().indexOf('clause[{0}]'.format(CONFIG.OFFER_VIA_EMAIL.clause.code)) > -1;
          } else { // contact - mail
            return msgObjectPointer.toLowerCase().indexOf('contacts[mail]') > -1
              || msgObjectPointer.toLowerCase().indexOf('commdatalist') > -1;
          }
        };

        /**
         * update insurer data in data container
         */
        this.updateDCInsurerData = function() {
          personHelper.savePerson(self.insurerData, self.insurerData.metaData.clientId, false);
        };

        this._getEmailContact = function(personData) {
          return _.find(personData.contacts, {code: LsnContactModelConstants.CONTACT_TYPE_MAIL});
        };

        /**
         * has insurer a phone number (in contacts)
         * @return {boolean}
         */
        this.hasInsurerPhoneNumber = function() {
          if (!_.isArray(self.insurerData.contacts)) {
            return false;
          }
          return _.some(self.insurerData.contacts, function(contact) {
            return contact.code === LsnContactModelConstants.CONTACT_TYPE_KOMO
            && contact.value && contact.value.trim();
          });
        };
      };

      return InsurerContactDataModalHelperClass;
    }
  ]);