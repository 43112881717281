angular.module('salesPath2')
  .service('coownerModalHelper', ['$timeout', '$filter', 'mainDataContainer', 'CONSTANTS', 'sp2CommonHelper', 'coownerHelper', 'personModalHelper', 'actionHelper', 'vehicleHelper', 'bonusMalusModalHelper', 'appVariables', 'bonusMalusHelper', 'sp2CommonHelper', '$sce', 'dataContainerHelper',
    function($timeout, $filter, mainDataContainer, CONSTANTS, sp2CommonHelper, coownerHelper, personModalHelper, actionHelper, vehicleHelper, bonusMalusModalHelper, appVariables, bonusMalusHelper, commonHelper, $sce, dataContainerHelper) { // eslint-disable-line angular/di
      var CoownerModalHelper = function() {
        var self = this;

        var DEFAULT_EDIT_COOWNER_MODAL_DATA = {
          objectType: null,
          objectId: null,
          objectName: null,
          tplData: {
            showElements: {
              bonusMalus: false,
              emptyBmInfo: false
            },
            allDataUfgFilled: false,
            infoAboutDrivingLicence: '',
            riskBonusMalus: {
              areDataNewRisks: false,
              areDataContinuationRisks: false
            }
          }
        };

        this.editCoownerModalData = angular.copy(DEFAULT_EDIT_COOWNER_MODAL_DATA);

        this.modals = {
          coownerListModal: {
            settings: {
              treatLabelAsI18nCode: true,
              cancelBtnName: 'sp2.modal.cancelBtnName',
              okBtnName: 'sp2.modal.coownerListModal.okBtnName',
              title: 'sp2.modal.coownerListModal.title',
              okBtnDisabled: false
            }
          }
        };

        this.init = function() {
          self.editCoownerModalData.tplData = angular.copy(DEFAULT_EDIT_COOWNER_MODAL_DATA.tplData);
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
              //wykonaj akcje tylko jeśli kontekst mieniowy
              if (self.editCoownerModalData.objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION && angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PERSON_CONTEXT_COOWNER) {
                self.showCoownerListModal();
              }
              break;
            case 'personAdded':
              if (self.editCoownerModalData.objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION && angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PERSON_CONTEXT_COOWNER) {
                self.onPersonAdded(arguments[1].id);
              }
              break;
            case 'vehicleBmModalClosed':
              self.vehicleBmModalClosed();
              break;
            default:
          }
        };

        /*
         *akcja przy dodaniu nowego coownera
         */
        this.onPersonAdded = function(personId) {
          var object = dataContainerHelper.getObject(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId);
          var coowners = object.getAdditionalData('coowners');

          coowners.push(personId);

          $timeout(function() {
            actionHelper.runAction('coownersChanged', {
              objectType: self.editCoownerModalData.objectType,
              objectId: self.editCoownerModalData.objectId
            });
          }, 0);

          self.showCoownerListModal();

          actionHelper.runAction('saveApplication');
        };

        this.showCoownerList = function(objectType) {
          self.editCoownerModalData.objectType = objectType;
          if (self.editCoownerModalData.objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            sp2CommonHelper.showModal('communicationPersonsModal');
          } else {
            self.setObjectId(objectType);
            var noCoowner = coownerHelper.isCoownerListEmpty(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId);

            if (noCoowner) {
              self.editCoownerModalData.objectName = coownerHelper.getObjectName(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId);

              $timeout(function() {
                //tłumaczenia

                var title = $filter('translate')('sp2.modal.addCoowner.title', {
                  componentCode: 'sp2'
                }) + ' ' + self.editCoownerModalData.objectName;
                var titleSide = $filter('translate')('sp2.modal.addCoowner.titleSide', {
                  componentCode: 'sp2'
                });
                var searchTitle = $filter('translate')('sp2.modal.addCoowner.searchTitle', {
                  componentCode: 'sp2'
                }) + ':';

                personModalHelper.openPopupAddPerson({
                  context: CONSTANTS.PERSON_CONTEXT_COOWNER,
                  title: title,
                  titleSide: titleSide,
                  searchTitle: searchTitle,
                  searchHeader: title,
                  editHeader: title,
                  excludeIds: coownerHelper.getOwners(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId)
                });
              }, 0);
            } else {
              self.showCoownerListModal();
            }
          }
        };

        /**
         * ustawia ID aktualnie wybranego obiektu
         */
        this.setObjectId = function(objectType) {
          if (objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            self.editCoownerModalData.objectId = mainDataContainer.selectedVehicle;
          } else if (objectType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            self.editCoownerModalData.objectId = mainDataContainer.selectedLocalization;
          }
        };

        /**
         * funckja uzupelnia powiązania vehicle w personInfo
         */
        this.setVehicleInCoowners = function(personsInfo) {
          //zbieramy powiązania
          var coownersToVehicles = {};
          angular.forEach(mainDataContainer.vehicles, function(vehicle, vehicleId) {
            angular.forEach(vehicle.getAdditionalData('coowners'), function(coownerId) {
              if (typeof coownersToVehicles[coownerId] === 'undefined') {
                coownersToVehicles[coownerId] = [];
              }
              coownersToVehicles[coownerId].push(vehicleId);
            });
          });
          angular.forEach(personsInfo, function(personInfo) {
            personInfo.vehicles = [];
            angular.forEach(coownersToVehicles[personInfo.id], function(vehicleId) {
              personInfo.vehicles.push({
                id: vehicleId,
                info: vehicleHelper.getVehicleInfo(mainDataContainer.vehicles[vehicleId]),
                selected: parseInt(self.editCoownerModalData.objectId, 10) === parseInt(vehicleId, 10)
              });
            });
          });
        };

        /**
         * edycja współwłaściciela
         */
        this.editCoowner = function(personId) {
          sp2CommonHelper.hideModal('coownerListModal');
          $timeout(function() {
            personModalHelper.openPopupEditPerson({
              id: personId,
              editHeader: 'Współwłaściciel',
              context: CONSTANTS.PERSON_CONTEXT_COOWNER
            });
          }, 0);
        };

        /**
         * dodanie nowego współwłaściciela (z okna listy)
         */
        this.addCoowner = function() {
          sp2CommonHelper.hideModal('coownerListModal');
          self.editCoownerModalData.objectName = coownerHelper.getObjectName(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId);

          var title = $filter('translate')('sp2.modal.addCoowner.title', {
            componentCode: 'sp2'
          }) + ' ' + self.editCoownerModalData.objectName;
          var titleSide = $filter('translate')('sp2.modal.addCoowner.titleSide', {
            componentCode: 'sp2'
          });
          var searchTitle = $filter('translate')('sp2.modal.addCoowner.searchTitle', {
            componentCode: 'sp2'
          }) + ':';

          $timeout(function() {
            personModalHelper.openPopupAddPerson({
              context: CONSTANTS.PERSON_CONTEXT_COOWNER,
              title: title,
              titleSide: titleSide,
              searchTitle: searchTitle,
              searchHeader: title,
              editHeader: title,
              excludeIds: coownerHelper.getOwners(self.editCoownerModalData.objectType, self.editCoownerModalData.objectId)
            });
          }, 0);
        };


        /**
         * odsiweza dane BM
         * @param  {[type]} personId [description]
         * @return {boolean} true gdy odswiezono
         */
        this.refreshBMData = function(personId) { //eslint-disable-line consistent-return
          if (self.editCoownerModalData.objectType !== CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            return false;
          }
          self.editCoownerModalData.tplData.showElements.bonusMalus = true;
          var bonusMalusData = {};
          bonusMalusData.risks = mainDataContainer.bonusMalus[personId][mainDataContainer.selectedVehicle].risks;
          bonusMalusData.availableRisks = bonusMalusHelper.getAvailableRisks();

          var personData = coownerHelper.getSelectedPersonData(personId);
          if (personData.dynamicValues.noDrivingLicence) {
            self.editCoownerModalData.tplData.infoAboutDrivingLicence = $sce.trustAsHtml('brak');
          } else {
            if (typeof personData.dynamicValues.drivingLicenceIssueDate === 'undefined') {
              self.editCoownerModalData.tplData.infoAboutDrivingLicence = personData.dynamicValues.drivingLicenceIssueDate = $sce.trustAsHtml($filter('translate')('noData', {
                componentCode: 'Public'
              }));
            } else {
              self.editCoownerModalData.tplData.infoAboutDrivingLicence = $sce.trustAsHtml(commonHelper.tryEscapeHtml(personData.dynamicValues.drivingLicenceIssueDate));
            }
          }
          var riskBonusMalus = self.prepareDataBonusMalusToShow(bonusMalusData.risks, bonusMalusData.availableRisks);

          self.editCoownerModalData.tplData.showElements.emptyBmInfo = false;
          if (!riskBonusMalus.areDataNewRisks && !riskBonusMalus.areDataContinuationRisks && !riskBonusMalus.areDataNewRisks && !riskBonusMalus.areDataContinuationRisks) {
            self.editCoownerModalData.tplData.showElements.emptyBmInfo = true;
            self.editCoownerModalData.tplData.allDataUfgFilled = false;
            //wszystkie dane potrzebne do taryfikacji wypełnione powinna odbywać się taryfikacja
            if (bonusMalusHelper.dataExpected(personId, mainDataContainer.selectedVehicle)) {
              self.editCoownerModalData.tplData.allDataUfgFilled = true;
            }
            return true;
          }

          self.editCoownerModalData.tplData.riskBonusMalus = riskBonusMalus;
        };

        this.prepareDataBonusMalusToShow = function(risks, availableRisks) {
          var riskBonusMalus = {
            areDataNewRisks: false,
            areDataContinuationRisks: false
          };

          var areDataNewAC = risks.AC !== null && risks.AC.data.newModeData !== null && availableRisks.AC !== null &&
            (risks.AC.data.newModeData.history5YearsCount !== null || risks.AC.data.newModeData.last2YearsDamagesCount !== null ||
              risks.AC.data.newModeData.last5YearsDamagesCount !== null);
          var areDataNewOC = risks.OC !== null && risks.OC.data.newModeData !== null && availableRisks.OC !== null &&
            (risks.OC.data.newModeData.history5YearsCount !== null || risks.OC.data.newModeData.last2YearsDamagesCount !== null ||
              risks.OC.data.newModeData.last5YearsDamagesCount !== null);
          if (areDataNewAC || areDataNewOC) {
            riskBonusMalus = {
              areDataNewRisks: true,
              newRisksHeaders: [],
              newRisks: [{
                label: 'Ciągłość ubezpieczenia w latach',
                values: []
              }, {
                label: 'Szkody w ostatnich 2 latach',
                values: []
              }, {
                label: 'Szkody w ostatnich 5 latach',
                values: []
              }]
            };

            if (areDataNewOC) {
              self.preapareNewRisksToShow(riskBonusMalus, risks.OC.data.newModeData, 'OC');
            }
            if (areDataNewAC) {
              self.preapareNewRisksToShow(riskBonusMalus, risks.AC.data.newModeData, 'AC');
            }
          }

          var areDataContinuationAC = risks.AC !== null && risks.AC.data.continuationModeData !== null && availableRisks.AC !== null;
          var areDataContinuationOC = risks.OC !== null && risks.OC.data.continuationModeData !== null && availableRisks.OC !== null;
          if (areDataContinuationAC || areDataContinuationOC) {
            riskBonusMalus = {
              areDataContinuationRisks: true,
              continuationRisksHeaders: [],
              continuationRisks: [{
                label: 'Koniec ubezpieczenia',
                values: []
              }, {
                label: 'Dotychczasowa klasa BM',
                values: []
              }, {
                label: 'Szkody w ostatnim roku',
                values: []
              }, {
                label: 'Szkody chronione OUZ',
                values: []
              }, {
                label: 'Lata bezszkodowe w dniu zawarcia kontynuowanej polisy',
                values: []
              }, {
                label: 'Numer kontynuowanej polisy',
                values: []
              }]
            };

            if (areDataContinuationOC) {
              self.preapareContinuationRisksToShow(riskBonusMalus, risks.OC.data.continuationModeData, 'OC');
            }
            if (areDataContinuationAC) {
              self.preapareContinuationRisksToShow(riskBonusMalus, risks.AC.data.continuationModeData, 'AC');
            }
          }

          return riskBonusMalus;
        };

        this.preapareNewRisksToShow = function(riskBonusMalus, newRisks, header) {
          riskBonusMalus.newRisksHeaders.push(header);
          riskBonusMalus.newRisks[0].values.push(newRisks.history5YearsCount === null ? '' : newRisks.history5YearsCount);
          riskBonusMalus.newRisks[1].values.push(newRisks.last2YearsDamagesCount === null ? '' : newRisks.last2YearsDamagesCount);
          riskBonusMalus.newRisks[2].values.push(newRisks.last5YearsDamagesCount === null ? '' : newRisks.last5YearsDamagesCount);
        };

        this.preapareContinuationRisksToShow = function(riskBonusMalus, continuationRisks, header) {
          riskBonusMalus.continuationRisksHeaders.push(header);
          riskBonusMalus.continuationRisks[0].values.push(continuationRisks.policyEndDate === null ? '' : new XDate(continuationRisks.policyEndDate).toString('yyyy-MM-dd'));
          riskBonusMalus.continuationRisks[1].values.push(continuationRisks.bmClass === null ? '' : continuationRisks.bmClass);
          riskBonusMalus.continuationRisks[2].values.push(continuationRisks.last1YearDamagesCount === null ? '' : continuationRisks.last1YearDamagesCount);
          riskBonusMalus.continuationRisks[3].values.push(continuationRisks.last1YearOuzCoveredDamagesCount === null ? '' : continuationRisks.last1YearOuzCoveredDamagesCount);
          riskBonusMalus.continuationRisks[4].values.push(continuationRisks.noClaimContinuationYears === null ? '' : continuationRisks.noClaimContinuationYears);
          riskBonusMalus.continuationRisks[5].values.push(continuationRisks.policyNumber === null ? '' : continuationRisks.policyNumber);
        };

        /**
         * usunięcie wsĻółwłaściciela
         */
        this.removeCoowner = function(personId) {
          sp2CommonHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.info.title',
            text: 'sp2.modal.removeCoownerConfirmInfo',
            okBtnCallback: function() {
              coownerHelper.removeCoowner(personId, self.editCoownerModalData.objectType);
            }
          });

          coownerHelper.showedCoownerId = null;

          sp2CommonHelper.hideModal('coownerListModal');
        };

        /**
         * dane BonusMalus
         */
        this.showBonusMalusPopup = function(personId, openCoownerList) {
          //nie wiadomo dlaczego jest wywoływany modal, dlatego dla bezpieczeństwa dodaję flagę mówiąca o tym czy go otwierać
          if (openCoownerList) {
            self.showCoownerListModal();
          }
          bonusMalusModalHelper.showPopup(personId);
        };

        this.showCoownerListModal = function() {
          if (appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer)) {
            self.modals.coownerListModal.settings.cancelBtnName = '';
            self.modals.coownerListModal.settings.okBtnName = ['sp2.modal.close', {
              componentCode: 'sp2'
            }];
          } else {
            self.modals.coownerListModal.settings.cancelBtnName = ['sp2.modal.close', {
              componentCode: 'sp2'
            }];
            self.modals.coownerListModal.settings.okBtnName = ['sp2.modal.coownerListModal.okBtnName', {
              componentCode: 'sp2'
            }];
          }
          sp2CommonHelper.showModal('coownerListModal');
        };

        this.vehicleBmModalClosed = function() {
          //jeżeli edytowane były dane BM współwłąściciela to otweiramy ponownie okno z listą współwłaścicieli
          if (coownerHelper.showedCoownerId !== null && typeof (coownerHelper.showedCoownerId) !== 'undefined') {
            self.showCoownerList(CONSTANTS.PRODUCT_TYPE_VEHICLE);
          }
        };
      };

      return new CoownerModalHelper();
    }
  ]).run(['coownerModalHelper', 'actionHelper',
  function(coownerModalHelper, actionHelper) {
    actionHelper.registerHelper('coownerModalHelper', coownerModalHelper);
  }
]);