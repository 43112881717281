angular.module('ihestiaWidgets.policyDetails')
  .factory('PolicyHelper', ['IhestiaPolicyHelper', 'policiesPrintoutsSvc', 'owuHelper', 'policiesSvc', 'owuModalHelper', 'iHestiaCommonModalHelper', '$timeout', 'policyPaymentsSvc', 'policyDetailsContextHelper', 'policiesPrintoutsV2Svc', '$q', 'policiesV2Svc', 'ihestiaDownloadHelper', 'policyVersionsModalHelper', 'ihestiaCommonConfirmModalHelper', '$rootScope', 'ihestiaConfigHelper', 'ihestiaPlatformHelper', '$filter',
    function(IhestiaPolicyHelper, policiesPrintoutsSvc, owuHelper, policiesSvc, owuModalHelper, iHestiaCommonModalHelper, $timeout, policyPaymentsSvc, policyDetailsContextHelper, policiesPrintoutsV2Svc, $q, policiesV2Svc, ihestiaDownloadHelper, policyVersionsModalHelper, ihestiaCommonConfirmModalHelper, $rootScope, ihestiaConfigHelper, ihestiaPlatformHelper, $filter) {

      var PolicyHelper = function(policy, policyId, policyNumber) {
        var abstractHelper = new IhestiaPolicyHelper(policy, policyId, policyNumber);
        angular.extend(this, abstractHelper);

        var self = this;

        /**
         * Pdf'y z wydrukami
         * @param  {string}   documentCode [description]
         * @param  {Function} callback     [description]
         * @return {Promise}                [description]
         */
        this.printDocument = function(documentCode, callback) {
          callback = angular.isFunction(callback) ? callback : lsnNg.noop;
          if (documentCode === 'Policy') {
            return self.getPrintout('POLISA', callback);
          } else if (documentCode === 'PaymentBlank') {
            var printContext = 'PRZELEW';
            if (ihestiaPlatformHelper.isLifeContext() ||
              // ikonto ustawia platformę Corpo dla klientów Corpo
              (ihestiaConfigHelper.get('rest', 'AP_PLATFORM') && ihestiaConfigHelper.get('rest', 'AP_PLATFORM') === 'Corpo')) {
              printContext = 'WPLATA';
            }
            return self.getPrintout(printContext, callback);
          } else if (documentCode === 'InsuranceConditions' || documentCode === 'InsuranceConditionsWithProductInfo') {
            // tutaj najpierw musimy pobrać listę kodów owu...
            return policiesSvc.get(self.policyNumber, null, 'insuranceConditions').then(function(res) {
              if (res && res.data && res.status === 200) {
                // ...potem pobrać dla tych kodów obiekty z nazwami, linkiem do pliku itd....
                owuHelper.getOwus(res.data, function(owus) {
                  owuModalHelper.owus = owus;
                  iHestiaCommonModalHelper.hideModal('choosePrintoutsModal');
                  callback();
                  $timeout(function() {
                    // ...i na samym końcu wyświetlić modal z owu
                    iHestiaCommonModalHelper.showModal('ihestiaOwuModal');
                  }, 0);
                });
              } else {
                callback();
              }
              return res;
            }, function(res) {
              callback();
              return res;
            });

            // IHESTIAE-1963 - different service for EHL
          } else if (documentCode === 'ConfirmationPremiumPayment') {
            // Pobieranie wydruku potwierdzenia opłacenia składek
            return ihestiaPlatformHelper.isLifeContext() ?
              self.getPrintout('POTWIERDZENIE_OPLACENIA_POLISY')
              : self.getPremiumPaymentConfirmationFile();
          } else if (documentCode === 'Certificate') {
            return self.getPrintout('CERTYFIKAT', callback);
          } else if (documentCode === 'InfoAboutDistributor' || documentCode === 'InfoAboutInsuranceProduct') {

            var context = (documentCode === 'InfoAboutDistributor') ? 'IOD' : 'KID';

            // first check if policy has more than one version
            // if so we have to show another modal with policy versions
            return self.getPolicyVersions(self.policyNumber).then(function(res) {
              if (res) {
                if (res.length === 1) {
                  // go straight to download
                  iHestiaCommonModalHelper.hideModal('ihestiaPolicyDocuments');

                  if (context === 'IOD') {
                    $timeout(function() {
                      self.getDistributorAboutFile(self.policyId);
                    }, 0);
                  } else {
                    $timeout(function() {
                      self.getKeyInformationDocuments(self.policyId);
                    }, 0);
                  }

                } else {
                  // we have more than one policy version - show modal
                  iHestiaCommonModalHelper.hideModal('ihestiaPolicyDocuments');
                  callback();
                  $timeout(function() {
                    policyVersionsModalHelper.prepareOptions();
                    policyVersionsModalHelper.showModal({
                      policyNumber: self.policyNumber,
                      policyVersions: res,
                      context: context
                    }).then(angular.noop, angular.noop);
                  }, 0);
                }
              } else {
                callback();
                return res;
              }

              return res;

            }, function() {
              callback();
              return false;
            });
          } else if (documentCode === 'PolicyWithoutLogo') {
            return self.getPrintout('POLISA', callback);
          } else if (documentCode === 'PolicyWithLogo') {
            return self.getPrintout('POLISA-ELEKTRONICZNA');
          } else if (documentCode === 'ConfirmationCessionAgreement') {
            return self.getPrintout('POTWIERDZENIE_UMOWY_CESJI');
          } else if (documentCode === 'GreenCard') {
            return self.getPrintout('ZK', callback);
          } else if (documentCode === 'BusinessTravelCertificate') {
            return self.getPrintout('CERTYFIKAT', callback);
          } else {
            return self.getPrintout(documentCode, callback);
          }
        };

        /**
         * Pobieranie wydruku z polisy
         * @param {string} documentCode - kod wydruku z prefixem ('symbols/POLISA')
         * @param {function} [callback]
         * @return {Promise} [description]
         */
        this.getPrintout = function(documentCode, callback) {
          callback = angular.isFunction(callback) ? callback : lsnNg.noop;

          if (ihestiaPlatformHelper.isDirect()) {
            return policiesPrintoutsV2Svc.get(self.policyNumber, null, 'symbols/' + documentCode, lsnNg.noop, lsnNg.noop, {
              headers: {
                'Accept': 'application/pdf'
              },
              responseType: 'blob',
              allowedStatuses: [409]
            }).then(function(result) {
              if (result && result.status === 200) {
                var filename = $filter('translate')('fileNameDefaults.policyPrint', {
                  componentCode: 'Public',
                  params: {
                    policyNumber: self.policy.number
                  }
                }) + '.pdf'; //jak nie dostaniemy nazwy z usługi
                var contentDisposition = result.headers('Content-Disposition');

                var fileNameString = 'filename=';
                if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
                  var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
                  if (preCut.indexOf(';') !== -1) {
                    preCut.substr(0, preCut.indexOf(';'));
                  }
                  filename = decodeURIComponent(preCut);
                }

                var blob = new Blob([result.data], {
                  type: 'application/pdf'
                });
                saveAs(blob, filename);
              }
              callback();
              return result;
            }, function(res) {
              callback();
              return res;
            });
          } else {

            return policiesPrintoutsSvc.get(self.policyNumber, null, 'symbols/' + documentCode, lsnNg.noop, lsnNg.noop, {
              headers: {
                'Accept': 'application/pdf'
              },
              responseType: 'blob',
              allowedStatuses: [409]
            }).then(function(result) {
              if (result && result.status === 200) {
                var filename = $filter('translate')('fileNameDefaults.policyPrint', {
                  componentCode: 'Public',
                  params: {
                    policyNumber: self.policy.number
                  }
                }) + '.pdf'; //jak nie dostaniemy nazwy z usługi
                var contentDisposition = result.headers('Content-Disposition');

                var fileNameString = 'filename=';
                if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
                  var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
                  if (preCut.indexOf(';') !== -1) {
                    preCut.substr(0, preCut.indexOf(';'));
                  }
                  filename = decodeURIComponent(preCut);
                }

                var blob = new Blob([result.data], {
                  type: 'application/pdf'
                });
                saveAs(blob, filename);
              }
              callback();
              return result;
            }, function(res) {
              callback();
              return res;
            });

          }
        };

        // Get versions of policy
        this.getPolicyVersions = function(policyNumber) {
          return $q(function(resolve, reject) {
            policiesV2Svc.get(policyNumber, null, 'insuranceProductAboutFileParameters').then(function(res) {
              if (res && res.data) {
                resolve(res.data);
              } else {
                reject();
              }
            }, reject);
          });
        };

        // Download IOD information
        this.getDistributorAboutFile = function(policyId) {
          var noIODModalText = (policyDetailsContextHelper.isClient()) ? $filter('translate')('distributorAboutInformationModalTextClient', {componentCode: 'iKonto'}) : $filter('translate')('distributorAboutInformationModalText', {componentCode: 'iKonto'});

          $rootScope.$broadcast('showBlockUi', {
            label: $filter('translate')('downloadingDocument', {componentCode: 'Public'})
          });

          policiesSvc.get(policyId, null, 'distributorAboutFile').then(function(res) {
            if (res.status === 200) {
              ihestiaDownloadHelper.downloadFileByTokenId(res.data.token, 'iod.pdf');
            }
          }, function(res) {
            if (res.status === 409) {
              ihestiaCommonConfirmModalHelper.showConfirmModal({
                title: $filter('translate')('distributorAboutInformationModalTitle', {componentCode: 'iKonto'}),
                okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
                cancelBtnName: '',
                text: noIODModalText
              });
            }
          }).finally(function() {
            $rootScope.$broadcast('hideBlockUi');
          });
        };

        // Download KID information for iOffer
        // Key Information Documents
        this.getKeyInformationDocuments = function(policyId) {
          $rootScope.$broadcast('showBlockUi', {
            label: $filter('translate')('downloadingDocument', {componentCode: 'Public'})
          });
          policiesPrintoutsSvc.get(policyId, null, 'kid', null, null, {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'blob',
            allowedStatuses: [409]
          }).then(function(result) {

            if (result.status === 200) {
              var filename = 'KID.pdf'; // in case if service doesn't return filename
              var contentDisposition = result.headers('Content-Disposition');

              var fileNameString = 'filename=';
              if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
                var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
                if (preCut.indexOf(';') !== -1) {
                  preCut = preCut.substr(0, preCut.indexOf(';'));
                }
                filename = decodeURIComponent(preCut).replace(/\"/g, '');
              }

              var blob = new Blob([result.data], {
                type: 'application/pdf'
              });
              saveAs(blob, filename);
            }

          }, function(result) {
            if (result.status === 409) {
              var reader = new FileReader();

              reader.onloadend = function() {
                var data = JSON.parse(reader.result);
                var msg = '';

                angular.forEach(data.messages, function(message) {
                  msg += message.text;
                });

                ihestiaCommonConfirmModalHelper.showConfirmModal({
                  title: $filter('translate')('distributorAboutInformationModalTitle', {componentCode: 'iKonto'}),
                  okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
                  cancelBtnName: '',
                  text: msg
                });

              };
              reader.readAsText(result.data); // eslint-disable-line
            }
          }).finally(function() {
            $rootScope.$broadcast('hideBlockUi');
          });
        };

        this.getOrganizations = function() {
          return self.policy.organizations || [];
        };

        this.getPersons = function() {
          return self.policy.persons || [];
        };

        this.getPremiumPaymentConfirmationFile = function() {
          return policyPaymentsSvc.get(self.policyNumber + '/status/printout', null, null, function(result) {
            if (result && result.status === 200) {
              var filename = $filter('translate')('fileNameDefaults.premiumPayment', {
                componentCode: 'Public',
                params: {
                  policyNumber: self.policyNumber
                }
              }) + '.pdf';
              var blob = new Blob([result.data], {
                type: 'application/pdf'
              });
              saveAs(blob, filename);
            }
          }, lsnNg.noop, {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'blob'
          });
        };

        this.isActivePolicy = function() {
          return _.get(self.policy, 'endDate') > ihestiaConfigHelper.get('serverData').TODAY;
        };

      };

      return PolicyHelper;
    }]);
