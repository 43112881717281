angular.module('ihestiaCommonDirectives')
	.service('ihestiaSimpleUploadHelper', ['AbstractUploadOnCallbacksHelper', '$rootScope',
		function(AbstractUploadOnCallbacksHelper, $rootScope) {
			var SimpleUploadHelper = function() {
				AbstractUploadOnCallbacksHelper.apply(this, arguments);
				var self = this,
					parentMethod = {
						onFileUploadDone: this.onFileUploadDone
					};

				this.onFileUploadDone = function(e, data) {
					parentMethod.onFileUploadDone(e, data);
					var scopeName = data.scope.name;

					$rootScope.$broadcast('deleteFileInUploader', {
						scopeName: scopeName,
						file: self.data[scopeName].getProcessedFile('toSend')
					});
				};

				this.getAllSendingFiles = function(scopeName) {
					return self.data[scopeName].files.toSend;
				};
			};

			return new SimpleUploadHelper();
		}
	]);