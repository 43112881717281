/**
 * helper dla filtra selecta zakresów dat
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaGridSettings')
  .service('ihestiaDatePeriodHelper', ['ihestiaConfigHelper',
    function(ihestiaConfigHelper) {

      /**
       * Zwracany serwis
       */
      var IHestiaDatePeriodHelper = function() {
        var self = this;
        this.periodOptions = null; // generated once

        this.getPeriodByCode = function(code, ignoreCase) {
          var periods = self.getPeriodOptions();
          var periodMatch;
          angular.forEach(periods, function(period){
            if(ignoreCase && period.code.toLowerCase() === code.toLowerCase()) {
              periodMatch = period;
            } else if(!ignoreCase && period.code === code) {
              periodMatch = period;
            }
          });
          return periodMatch;
        };

        this.getPeriodOptions = function(filterOptions, withEmpty) {
          if (self.periodOptions === null) {
            var today = ihestiaConfigHelper.get('serverData').TODAY;

            // dla today = "2021-01-02" zwraca 53, bo tydzień zaczynał się w poprzednim roku
            var currentWeek = (new XDate(today)).getWeek();
            var currentMonth = (new XDate(today)).getMonth();
            var currentYear = (new XDate(today)).getFullYear();
            var weekYear = currentYear; // rok początku tygodnia
            if(currentWeek === 53 && currentMonth === 0) {
            // jeśli tydzień z przełomu roku, ale mamy już styczeń kolejnego roku
              weekYear = weekYear - 1;
            }

            var periods = [];
            periods.push({
              name: 'Dziś',
              code: 'Today',
              dateFrom: (new XDate(today)).toString('i'),
              dateTo: (new XDate(today)).toString('i')
            });
            periods.push({
              name: 'Wczoraj',
              code: 'Yesterday',
              dateFrom: (new XDate(today)).addDays(-1).toString('i'),
              dateTo: (new XDate(today)).addDays(-1).toString('i')
            });
            periods.push({
              name: 'Jutro',
              code: 'Tomorrow',
              dateFrom: (new XDate(today)).addDays(1).toString('i'),
              dateTo: (new XDate(today)).addDays(1).toString('i')
            });
            periods.push({
              name: 'Bieżący tydzień',
              code: 'CurrentWeek',
              dateFrom: (new XDate()).setWeek(currentWeek, weekYear).toString('i'),
              dateTo: (new XDate()).setWeek(currentWeek, weekYear).addDays(6).toString('i')
            });
            periods.push({
              name: 'Poprzedni tydzień',
              code: 'LastWeek',
              dateFrom: (new XDate()).setWeek(currentWeek, weekYear).addDays(-7).toString('i'),
              dateTo: (new XDate()).setWeek(currentWeek, weekYear).addDays(-1).toString('i')
            });
            periods.push({
              name: 'Następny tydzień',
              code: 'NextWeek',
              dateFrom: (new XDate()).setWeek(currentWeek, weekYear).addDays(7).toString('i'),
              dateTo: (new XDate()).setWeek(currentWeek, weekYear).addDays(6 + 7).toString('i')
            });

            var firstDayOfMonth = (new XDate()).setFullYear(currentYear, true).setMonth(currentMonth, true).setDate(1).clearTime();

            periods.push({
              name: 'Bieżący miesiąc',
              code: 'CurrentMonth',
              dateFrom: firstDayOfMonth.clone().toString('i'),
              dateTo: firstDayOfMonth.clone().addMonths(1).addDays(-1).toString('i')
            });
            periods.push({
              name: 'Poprzedni miesiąc',
              code: 'LastMonth',
              dateFrom: firstDayOfMonth.clone().addMonths(-1).toString('i'),
              dateTo: firstDayOfMonth.clone().addDays(-1).toString('i')
            });
            periods.push({
              name: 'Następny miesiąc',
              code: 'NextMonth',
              dateFrom: firstDayOfMonth.clone().addMonths(1).toString('i'),
              dateTo: firstDayOfMonth.clone().addMonths(2).addDays(-1).toString('i')
            });

            var firstDayOfYear = (new XDate()).setFullYear(currentYear, true).setMonth(0, true).setDate(1).clearTime();

            periods.push({
              name: 'Bieżący rok',
              code: 'CurrentYear',
              dateFrom: firstDayOfYear.clone().toString('i'),
              dateTo: firstDayOfYear.clone().addYears(1).addDays(-1).toString('i')
            });
            periods.push({
              name: 'Poprzedni rok',
              code: 'LastYear',
              dateFrom: firstDayOfYear.clone().addYears(-1).toString('i'),
              dateTo: firstDayOfYear.clone().addDays(-1).toString('i')
            });
            periods.push({
              name: 'Następny rok',
              code: 'NextYear',
              dateFrom: firstDayOfYear.clone().addYears(1).toString('i'),
              dateTo: firstDayOfYear.clone().addYears(2).addDays(-1).toString('i')
            });
            periods.push({
              name: 'Wybór ręczny daty',
              code: 'Manual',
              dateFrom: null,
              dateTo: null
            });

            self.periodOptions = periods;
          }

          var filteredOptions = [];

          if(withEmpty) {
            filteredOptions.push({
              name: '',
              code: null,
              dateFrom: null,
              dateTo: null
            });
          }

          var allowedCodes = ['Today', 'Yesterday', 'Tomorrow', 'CurrentWeek', 'LastWeek', 'NextWeek', 'CurrentMonth', 'LastMonth', 'NextMonth', 'CurrentYear', 'LastYear', 'NextYear'];
          // all codes
          if(typeof filterOptions === 'string')
          {
            if(filterOptions === 'noFuture') {
              allowedCodes = ['Today', 'Yesterday', 'CurrentWeek', 'LastWeek', 'CurrentMonth', 'LastMonth', 'CurrentYear', 'LastYear'];
            }
          }
          allowedCodes.push('Manual');

          angular.forEach(self.periodOptions, function(baseOption){
            if(allowedCodes.indexOf(baseOption.code) !== -1) {
              filteredOptions.push(baseOption);
            }
          });

          return filteredOptions;
        };

        // odpalany przed każdym requestem z poziomu requestParser
        this.mapFilters = function(filterData, attributeCode) {

          var dateFromAttribute = attributeCode + 'From';
          var dateToAttribute = attributeCode + 'To';

          if(filterData[attributeCode]) {
            var periodOption = self.getPeriodByCode(filterData[attributeCode]);
            if(periodOption && periodOption.code === 'Manual') {
              if(filterData[dateFromAttribute]) {
                filterData[dateFromAttribute] = (new XDate(filterData[dateFromAttribute])).toString('i');
              }
              if(filterData[dateToAttribute]) {
                filterData[dateToAttribute] = (new XDate(filterData[dateToAttribute])).toString('i');
              }
            } else if(periodOption) {
              // co prawda zazwyczaj mamy już te wartości ustawione przez komponent date period,
              // ale w razie gdyby to był kod z wartości zapisanych w settings to chcemy go zmapować na aktualne wartości
              filterData[dateFromAttribute] = periodOption.dateFrom;
              filterData[dateToAttribute] = periodOption.dateTo;
            }
          }

          if(typeof filterData[attributeCode] !== 'undefined') {
            delete filterData[attributeCode];
          }

          return filterData;
        };


      };

      return new IHestiaDatePeriodHelper();
    }
  ]);