angular.module('ihestiaSsoBase.baseInfo')

.factory('ihestiaSsoLogoutSvc', ['abstractPlatformSessionSvc',
  function(abstractPlatformSessionSvc) {
    var SsoLogoutSvc = function() {
      abstractPlatformSessionSvc.apply(this, arguments);
      var self = this;
      this.resource = 'logout';

      this.logout = function() {
        return self.get();
      };
    };

    return new SsoLogoutSvc();
  }
]);