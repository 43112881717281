angular.module('lsnBase.models')
  .factory('LsnVehicleModelConstants', [function() {
    var VehicleModelConstants = {};
    VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX = 'EUROTAX';
    VehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL = 'INTERNAL';
    // slownik dostawcy danych pojazdu
    VehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER = {};
    VehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER[VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX] = VehicleModelConstants.VEHICLE_DATA_PROVIDER_EUROTAX;
    VehicleModelConstants.DICTIONARY_VEHICLE_DATA_PROVIDER[VehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL] = VehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL;

    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_ORD = 'STANDARD';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_TEMP = 'TEMPORARY';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_TRIAL = 'TRIAL';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_FOR = 'FOREIGN';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_DIP = 'DIPLOMATIC';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_ELSE = 'OTHER';
    VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_GAP = 'GAP';
    // slownik typow rejestracji
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE = {};
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_ORD] = 'tablice zwykłe';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_TEMP] = 'tablice próbne';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_TRIAL] = 'tablice tymczasowe';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_FOR] = 'tablice zagraniczne';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_DIP] = 'tablice dyplomatyczne';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_ELSE] = 'tablice inne';
    VehicleModelConstants.DICTIONARY_VEHICLE_REGISTRATION_TYPE[VehicleModelConstants.VEHICLE_REGISTRATION_TYPE_GAP] = 'brak tablic';

    VehicleModelConstants.VEHICLE_CAPACITY_UNIT_CM = 'cm3';
    VehicleModelConstants.VEHICLE_CAPACITY_UNIT_DM = 'dm3';
    // slownik jednostek pojemnosci
    VehicleModelConstants.DICTIONARY_VEHICLE_CAPACITY_UNIT = {};
    VehicleModelConstants.DICTIONARY_VEHICLE_CAPACITY_UNIT[VehicleModelConstants.VEHICLE_CAPACITY_UNIT_CM] = VehicleModelConstants.VEHICLE_CAPACITY_UNIT_CM;
    VehicleModelConstants.DICTIONARY_VEHICLE_CAPACITY_UNIT[VehicleModelConstants.VEHICLE_CAPACITY_UNIT_DM] = VehicleModelConstants.VEHICLE_CAPACITY_UNIT_DM;

    VehicleModelConstants.VEHICLE_POWER_UNIT_KW = 'KW';
    VehicleModelConstants.VEHICLE_POWER_UNIT_KM = 'KM';
    // slownik jednostek mocy silnika
    VehicleModelConstants.DICTIONARY_VEHICLE_POWER_UNIT = {};
    VehicleModelConstants.DICTIONARY_VEHICLE_POWER_UNIT[VehicleModelConstants.VEHICLE_POWER_UNIT_KW] = VehicleModelConstants.VEHICLE_POWER_UNIT_KW;
    VehicleModelConstants.DICTIONARY_VEHICLE_POWER_UNIT[VehicleModelConstants.VEHICLE_POWER_UNIT_KM] = VehicleModelConstants.VEHICLE_POWER_UNIT_KM;

    VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_KG = 'kg';
    VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_T = 't';
    // slownik jednostek ladownosci pojazdu
    VehicleModelConstants.DICTIONARY_VEHICLE_LOAD_WEIGHT_UNIT = {};
    VehicleModelConstants.DICTIONARY_VEHICLE_LOAD_WEIGHT_UNIT[VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_KG] = VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_KG;
    VehicleModelConstants.DICTIONARY_VEHICLE_LOAD_WEIGHT_UNIT[VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_T] = VehicleModelConstants.VEHICLE_LOAD_WEIGHT_UNIT_T;


    return VehicleModelConstants;
  }]);