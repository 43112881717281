angular.module('ihestiaGridSettings')

  /**
   * serwis REST do obsługi grup ustawień
   * @return {[type]} [description]
   */
  .factory('ihestiaGridSettingsGroupSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaGridSettingsGroup = function ()
        {
          IHestiaRestServiceAbstractDef.apply(this, arguments);

          this.resource = 'gridsettings/groups';
          this.businessArea = 'settings';
        };

      return new IHestiaGridSettingsGroup();
    }])
  ;