angular.module('ihestiaRestServicesBase')

/**
 * pobieranie obrazka captcha
 */
.service('ceidgCaptchaSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var CeidgCaptcha = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'captcha';
    this.businessArea = 'ceidg';
    this.port = '8282';
    this.path = 'ceidg-reader-web';
    this.processType = 'SYNC';

  };
  return new CeidgCaptcha();
}])

/**
 * szukanie klienta w bazie
 */
.service('ceidgEntrySvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var CeidgEntry = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'entry';
    this.businessArea = 'ceidg';
    this.port = '8282';
    this.path = 'ceidg-reader-web';
  };
  return new CeidgEntry();
}])
/**
 * sprawdzenie CAPTCHA
 */
.service('ceidgSearchSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var CeidgSearch = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.resource = 'search';
    this.businessArea = 'ceidg';
    this.port = '8282';
    this.path = 'ceidg-reader-web';
  };
  return new CeidgSearch();
}])
;