angular.module('salesPath2')
  .service('extensionHelper', ['actionHelper', 'mainDataContainer', 'sp2CommonHelper', 'CONSTANTS', 'RESOURCES', '$sce', 'resourceHelper', 'dataContainerHelper',
    function(actionHelper, mainDataContainer, sp2CommonHelper, CONSTANTS, RESOURCES, $sce, resourceHelper, dataContainerHelper) {
      var ExtensionHelper = function() {
        var self = this;

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'objectSelected':
            case 'matrixProductSelected':
            case 'personVariantChanged':
            case 'personEdited':
            case 'personAdded':
            case 'personDeleted':
            case 'groupEdited':
            case 'groupAdded':
            case 'groupDeleted':
            case 'fireBurglarySumInsuredChanged':
            case 'localizationDeleted':
              self.deleteUnavailable();
              break;
            default:
          }
        };

        /**
         * obslugiwane typy produktow w rozszerzeniach
         * @type {Array}
         */
        this.availableProductTypes = [CONSTANTS.PRODUCT_TYPE_LOCALIZATION, CONSTANTS.PRODUCT_TYPE_VEHICLE, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP];


        /**
         * metoda zwraca listę rozszerzen możliwych do zaznaczenia i ewentualnie osoby-grupy
         * wybrane dla danego dodatku jeśli zaznaczone są wszystkie osoby-grupy
         * @param {String|Number} [objectId] opcja - id obiektu, dla którego sprawdzamy dostępność (np. osoba/grupa)
         * @return {object} [description]
         */
        this.getAvailableExtensions = function(objectId) {
          var availableExts = {};
          angular.forEach(mainDataContainer.selectedExtensions, function(selected, extCode) {
            if (self.extIsAvailable(extCode, objectId)) {
              availableExts[extCode] = true;
              var extDef = self.getExtDef(extCode);
              if (self.availableProductTypes.indexOf(extDef.TYPE) !== -1) {
                var selectedObject = angular.isDefined(objectId) ? objectId : dataContainerHelper.getSelectedObjectId(extDef.TYPE);
                if (selectedObject === CONSTANTS.ALL_OBJECTS) //zaznaczone wszystkie lokalizacje/pojazdy/osoby-grupy
                {
                  var numbers = {};
                  var numbersCount = 0;
                  //zbieramy numery obiektow
                  angular.forEach(selected, function(id) {
                    numbers[dataContainerHelper.getObjectNumber(extDef.TYPE, id)] = true;
                    numbersCount += 1;
                  });

                  //teraz mamy numerki w kolejności rosnącej
                  if (numbersCount) {
                    availableExts[extCode] = numbers;
                  }
                }
              }
            } else {
              availableExts[extCode] = false;
            }
          });
          return availableExts;
        };

        /**
         * czy rozszerzenie jest aktywne, możliwe do wybrania,
         * jeśli nie podano objectId jest sprawdzane pod kątem widczności na matrycy
         * @param {string} extCode
         * @param {string|int} [objectId] opcjonalne id obiektu, jak nie podano to sprawdzane dla stanu matrycy
         */
        this.extIsAvailable = function(extCode, objectId) {
          var extDef = self.getExtDef(extCode);
          var isAvailable = false;
          var isProductSelected = null;
          var checkVariants = (angular.isArray(extDef.VARIANTS) && extDef.VARIANTS.length !== 0) ? extDef.VARIANTS : false; //czy zaznaczony wariant produktu
          if (extDef.CODE === CONSTANTS.NO_EXTENSION) { //opcja "brak"
            return self.isNoExtensionAvailable(objectId);
          }
          lsnNg.forEach(extDef.PRODUCTS, function(productCode) { //lista produktów dla których możliwy jest wybór rozszerzenia
            //jeśli rozszerzenie sprawdzamy dla konkretnego obiektu i produkt może być powiązany z tą osobą/grupą/pojazdem/lokalizacja to sprawdzamy czy jest dla niej podpięty
            //w przeciwnym przypadku sprawdzamy bez względu na obiekt
            if (extDef.TYPE === resourceHelper.productType[productCode]) {
              isProductSelected = dataContainerHelper.isProductSelected(productCode, objectId);
            } else {
              //jeśli to produkt dla lokalizacji lub pojazdu lub osoby-grupy, to sprawdzamy wszystkie po kolei
              var objects = dataContainerHelper.getObjects(resourceHelper.productType[productCode]);
              if (!angular.equals(objects, {})) {
                lsnNg.forEach(objects, function(idx, objectClientId) {
                  isProductSelected = dataContainerHelper.isProductSelected(productCode, objectClientId);
                  if (isProductSelected) {
                    return false;
                  }

                  return true; //continue
                });
              } else {
                isProductSelected = dataContainerHelper.isProductSelected(productCode);
              }
            }
            if (isProductSelected) {
              if (!checkVariants) {
                isAvailable = true;
                return false;
              } else { //sprawdzamy wybrany wariant produktu
                var variantMatch = false,
                  productVariants = dataContainerHelper.getVariantsForProduct(productCode, objectId);
                lsnNg.forEach(checkVariants, function(variant) {
                  if (productVariants[variant]) {
                    variantMatch = true;
                    return false;
                  }

                  return true; //continue
                });
                if (variantMatch) {
                  isAvailable = true;
                  return false;
                }
              }
            }
          
            return true; //continue
          });
          return isAvailable;
        };

        /**
         * czy dostepna  opcja "brak" dla rozszerzeń
         * @param {String} id obiektu dla ktorego sprawdzamy
         * @return {Boolean} [description]
         */
        this.isNoExtensionAvailable = function(objectId) {
          var available = false;
          angular.forEach(RESOURCES.EXTENSIONDEF, function(extDef) { //eslint-disable-line consistent-return
            if (extDef.CODE !== CONSTANTS.NO_EXTENSION && self.extIsAvailable(extDef.CODE, objectId)) {
              available = true;
              return false;
            }
          });
          return available;
        };
        /**
         * zwraca definicje rozszerzenia z resources
         * @param  {[type]} extCode kod
         * @param {Boolean} [bosCode] opcja, jesli true, to sprawdzamy po BOS_CODE w resourcach
         * @return {Object} definicja
         */
        this.getExtDef = function(extCode, bosCode) { //eslint-disable-line consistent-return
          var ext = null;
          bosCode = angular.isDefined(bosCode) ? bosCode : false;
          angular.forEach(RESOURCES.EXTENSIONDEF, function(extData) { //eslint-disable-line consistent-return
            if ((!bosCode && extData.CODE === extCode) || (bosCode && extData.BOS_CODE === extCode)) {
              ext = extData;
              return false;
            }
          });
          if (ext !== null) {
            return ext;
          }
          sp2CommonHelper.throwException('No extension resource found for code ' + extCode);
        };

        /**
         * czy rozszerzenie wybrane
         * @param  {String}  code     [description]
         * @param  {String}  [objectId] jak nie podano to sprawdzamy dla aktualnie wybranego obiektu
         * @return {Boolean}
         */
        this.isExtSelected = function(code, objectId) {
          var currentData = self.getCurrentExtData(code, objectId);
          if (!angular.isArray(currentData.extObjectsTab) || currentData.extObjectsTab.length === 0) //nie ma niczego/nikogo z danym dodatkiem
          {
            return false;
          }

          if (currentData.objectId === CONSTANTS.ALL_OBJECTS) //wszystkie pojazdy/lokalizacje i wiemy że co najmniej jeden element przypisany do dodatku
          {
            return true;
          } else {
            var objectFound = false;
            lsnNg.forEach(currentData.extObjectsTab, function(id) {
              if (parseInt(id, 10) === parseInt(currentData.objectId, 10)) {
                objectFound = true;
                return false;
              }
              
              return true; //continue
            });
            return objectFound;
          }
        };

        /**
         * zwraca dane rozszerzenia ze względu na rodzaj dodatku i aktywne obiekty na matrycy
         * dla powiąznych z osobami wymagany jest objectId
         * @param  {[type]} code     [description]
         * @param  {[type]} objectId [description]
         * @param  {[type]} variant  [description]
         * @return {[type]}          [description]
         */
        this.getCurrentExtData = function(code, objectId) {
          var extData = self.getExtDef(code);
          var extObjectsTab = mainDataContainer.selectedExtensions[code];

          if (angular.isUndefined(objectId)) //jeśli nie podany id obiektu to bierzemy wybrany pojazd/lokalizację/osobe-grupe
          {
            objectId = dataContainerHelper.getSelectedObjectId(extData.TYPE);
          }

          return {
            extData: extData,
            extObjectsTab: extObjectsTab,
            objectId: objectId,
            allObjects: objectId === CONSTANTS.ALL_OBJECTS
          };
        };

        /**
         * Odznaczenie rozszerzenia
         * @param  {string} code     [description]
         * @param  {int} objectId    [description]
         */
        this.deleteExt = function(code, objectId) {
          var currentData = self.getCurrentExtData(code, objectId);
          angular.forEach(currentData.extObjectsTab, function(id, k) { //eslint-disable-line consistent-return
            if (parseInt(id, 10) === parseInt(currentData.objectId, 10)) //usuwamy jeśli znaleziono
            {
              currentData.extObjectsTab.splice(k, 1);
              actionHelper.runAction('extDeleted', code, objectId);
              return false;
            }
          });
        };

        /**
         * dodaje rozszerzenie do zaznaczonych
         * @param {string} code
         * @param {string|int} objectId
         */
        this.addExt = function(code, objectId) {
          var extDef = self.getExtDef(code),
            conflictExts = angular.isArray(extDef.CONFLICT_EXTENSIONS) ? extDef.CONFLICT_EXTENSIONS : [];
          if (extDef.CODE === CONSTANTS.NO_EXTENSION) //opcja "brak"
          {
            angular.forEach(RESOURCES.EXTENSIONDEF, function(extResource) { //odznaczamy wszystkie rozszerzenia oprocz opcji "brak"
              if (extResource.TYPE === extDef.TYPE && extResource.CODE !== CONSTANTS.NO_EXTENSION) {
                self.deleteExt(extResource.CODE, objectId);
              }
            });
          } else { //odznaczamy "brak" jesli jakis dodatek wybrany i odznaczamy konfliktowe rozszerzenia
            angular.forEach(conflictExts, function(conflictExtCode) {
              self.deleteExt(conflictExtCode, objectId);
            });
            self.deleteExt(CONSTANTS.NO_EXTENSION, objectId);
          }

          var objectFound = false;
          var currentData = self.getCurrentExtData(code, objectId);
          angular.forEach(currentData.extObjectsTab, function(id) { //eslint-disable-line consistent-return
            if (id === currentData.objectId) {
              objectFound = true;
              return false;
            }
          });
          if (!objectFound) //dodajemy tylko jak jeszcze go nie ma
          {
            currentData.extObjectsTab.push(currentData.objectId);
            actionHelper.runAction('extAdded', code, objectId);
          }
        };

        /**
         * usun niedostepne rozszerzenia
         * @return {[type]} [description]
         */
        this.deleteUnavailable = function() {
          //kopiujemy bo w czasie iteracji usuwwamy elementy z listy
          var selectedExtensions = angular.copy(mainDataContainer.selectedExtensions);
          angular.forEach(selectedExtensions, function(selected, extCode) {
            angular.forEach(selected, function(objectId) {
              if (!self.extIsAvailable(extCode, objectId)) {
                self.deleteExt(extCode, objectId);
              }
            });
          });
        };

        /**
         * zwraca kolekcję definicji wybranych rozszerzeń dla konkretnego obiektu
         * @param  {String} prodType typ produktu
         * @param  {Number} objId id obiektu
         * @return {Array}
         */
        this.getSelectedExtensionsForObject = function(prodType, objId) {
          var exts = [];
          angular.forEach(mainDataContainer.selectedExtensions, function(selected, extCode) {
            var extDef = self.getExtDef(extCode);
            if (extDef.TYPE === prodType && angular.isArray(selected) && selected.indexOf(objId) !== -1) {
              exts.push(extDef);
            }
          });
          return exts;
        };
      };

      return new ExtensionHelper();
    }
  ])
  .run(['extensionHelper', 'actionHelper',
    function(extensionHelper, actionHelper) {
      actionHelper.registerHelper('extensionHelper', extensionHelper);
      return true;
    }
  ]);