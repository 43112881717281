angular.module('ihestia-menu-templates-app', ['ihestia-menu-template-app/base/base.tpl.html', 'ihestia-menu-template-app/chatBot/chatBot.tpl.html', 'ihestia-menu-template-app/clipboard/clipboard.tpl.html', 'ihestia-menu-template-app/help/help.tpl.html', 'ihestia-menu-template-app/help/helpModalContent.tpl.html', 'ihestia-menu-template-app/icContext/menuIcContext.tpl.html', 'ihestia-menu-template-app/leadingColor/leadingColor.tpl.html', 'ihestia-menu-template-app/leadingColor/leadingColorUpload.tpl.html', 'ihestia-menu-template-app/leadingColor/leadingColorUploadPopup.tpl.html', 'ihestia-menu-template-app/leadingColor/leadingColorUploadPopupPrint.tpl.html', 'ihestia-menu-template-app/leftNavBar/navbar.tpl.html', 'ihestia-menu-template-app/leftNavBar/v2/menuLeftNavbar.tpl.html', 'ihestia-menu-template-app/searchBox/searchBox.tpl.html', 'ihestia-menu-template-app/taskCounter/taskCounter.tpl.html', 'ihestia-menu-template-app/topMenu/ihestiaMenuAgentReports/ihestiaMenuAgentReports.tpl.html', 'ihestia-menu-template-app/topMenu/ihestiaMenuLifeInsurance/ihestiaMenuSaleLife.tpl.html', 'ihestia-menu-template-app/topMenu/menuCIAgentCenter.tpl.html', 'ihestia-menu-template-app/topMenu/menuCILibrary.tpl.html', 'ihestia-menu-template-app/topMenu/menuCILibrary_new.tpl.html', 'ihestia-menu-template-app/topMenu/menuCINews.tpl.html', 'ihestia-menu-template-app/topMenu/menuSaleProperty.tpl.html', 'ihestia-menu-template-app/topMenu/simpleSubmenu.tpl.html', 'ihestia-menu-template-app/topMenu/topmenu.tpl.html', 'ihestia-menu-template-app/userBox/popover/userBoxAgentPopover.tpl.html', 'ihestia-menu-template-app/userBox/popover/userBoxClientPopover.tpl.html', 'ihestia-menu-template-app/userBox/popover/userBoxCustomPopover.tpl.html', 'ihestia-menu-template-app/userBox/userBox.tpl.html', 'ihestia-menu-template-app/userBox/userBoxContent.tpl.html', 'ihestia-menu-template-app/userBox/userBoxStats.tpl.html', 'ihestia-menu-template-app/userBox/userBoxStatsLoading.tpl.html']);

angular.module("ihestia-menu-template-app/base/base.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/base/base.tpl.html",
    "<div class=\"app-header\" ng-controller=\"ihestiaMenuBaseCtrl\" ng-hide=\"disableIHestiaMenu\" ng-cloak>\n" +
    "	<ihestia-menu-left-navbar ng-if=\"elementsAvailability.leftNavBar\"></ihestia-menu-left-navbar>\n" +
    "\n" +
    "	<div class=\"eh-logo pull-left\" ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "		<a class=\"eh-logo-handle\" ng-cloak>\n" +
    "			<i class=\"icon-solo icon-ergo\"></i>\n" +
    "			<i class=\"icon-solo icon-hestia\"></i>\n" +
    "		</a>\n" +
    "	</div>\n" +
    "	<div class=\"app-logo pull-left no-animate\" ng-hide=\"elementsAvailability.ihlogo === false || isDirectMode || logoData.isSirius || appVariables.contentPulledLeft\">\n" +
    "		<a class=\"app-logo-handle\" ng-click=\"goHomePage()\"><span ng-if=\"!isJupiter\">{{ menuSettings.header }}</span>\n" +
    "			<i class=\"icon-solo icon-logo-jupiter\" ng-if=\"isJupiter\">\n" +
    "	       <span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span>\n" +
    "	    </i>\n" +
    "		</a>\n" +
    "	</div>\n" +
    "\n" +
    "	<div class=\"app-logo pull-left no-animate\" ng-show=\"elementsAvailability.ihlogo !== false && isDirectMode && !appVariables.contentPulledLeft\" style=\"display:none\" ng-style=\"{ display: (isDirectMode && !appVariables.contentPulledLeft ? 'block' : 'none') }\">\n" +
    "		<a style=\"cursor: default;\" class=\"app-logo-handle app-logo-handle-direct\" ng-cloak>\n" +
    "			<div class=\"logo-polisaonline\"></div>\n" +
    "		</a>\n" +
    "	</div>\n" +
    "\n" +
    "	<div class=\"app-logo pull-left no-animate\" ng-show=\"elementsAvailability.ihlogo !== false && logoData.isSirius && !appVariables.contentPulledLeft\" style=\"display:none\" ng-style=\"{ display: (logoData.isSirius && !appVariables.contentPulledLeft ? 'block' : 'none') }\">\n" +
    "		<a style=\"cursor: default;\" class=\"app-logo-handle app-logo-handle-direct\" ng-cloak>\n" +
    "			<div class=\"logo-syriusz\"></div>\n" +
    "		</a>\n" +
    "	</div>\n" +
    "\n" +
    "	<ihestia-menu-top-menu ng-if=\"elementsAvailability.topMenu\"></ihestia-menu-top-menu>\n" +
    "\n" +
    "\n" +
    "	<div class=\"app-menu-right pull-right\">\n" +
    "		<ihestia-menu-user-box ng-if=\"elementsAvailability.userBox\"></ihestia-menu-user-box>\n" +
    "		<div ihestia-ikomunikator-notification-bar ng-if=\"elementsAvailability.ikomunikator\" class=\"pull-right\"></div>\n" +
    "		<chat-bot ng-if=\"elementsAvailability.chatBot\"></chat-bot>\n" +
    "		<task-counter ng-if=\"elementsAvailability.taskCounter\"></task-counter>\n" +
    "		<change-language ng-if=\"elementsAvailability.changeLnguage\"></change-language>\n" +
    "		<ihestia-menu-clipboard ng-if=\"elementsAvailability.clipboard\"></ihestia-menu-clipboard>\n" +
    "		<ihestia-menu-help ng-if=\"elementsAvailability.help\"></ihestia-menu-help>\n" +
    "		<ihestia-menu-search-box ng-if=\"elementsAvailability.searchBox\"></ihestia-menu-search-box>\n" +
    "		<ihestia-sales-path-menu-basket ng-if=\"elementsAvailability.basket\"></ihestia-sales-path-menu-basket>\n" +
    "		<ihestia-sales-path-menu-message-box ng-if=\"elementsAvailability.messageBox\"></ihestia-sales-path-menu-message-box>\n" +
    "		<sp-life-basket ng-if=\"elementsAvailability.basketLife\"></sp-life-basket>\n" +
    "		<sp-life-message-box ng-if=\"elementsAvailability.messageBoxLife\"></sp-life-message-box>\n" +
    "		<menu-ic-context ng-if=\"elementsAvailability.icContext\"></menu-ic-context>\n" +
    "		<div class=\"pull-right\" ng-repeat=\"element in customTopRightElements\" ng-if=\"element.visible\">\n" +
    "      <ng-include src=\"element.template\"></ng-include>\n" +
    "    </div>\n" +
    "	</div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/chatBot/chatBot.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/chatBot/chatBot.tpl.html",
    "<div class=\"chat-bot pull-right\"\n" +
    "     ng-controller=\"chatBotCtrl\"\n" +
    "     ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "\n" +
    "     <audio id=\"chatBotNotification\" ng-src=\"{{audioFilePath}}\"></audio>\n" +
    "\n" +
    "    <div class=\"chat-bot-handler\" ng-click=\"toggleChatBotVisibility()\">\n" +
    "        <i class=\"icon-sl-outline icon-bubble-conversation-5\"></i>\n" +
    "        <div class=\"chat-bot-handler-notification\" ng-class=\"{ 'show' : statusData.anyUnreadMessages, 'animate' : statusData.anyUnreadMessages && anyUnreadMessagesAnimation}\"></div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/clipboard/clipboard.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/clipboard/clipboard.tpl.html",
    "<div class=\"clipboard pull-right\" \n" +
    "     ng-controller=\"ihestiaMenuClipboardCtrl\" \n" +
    "     ng-hide=\"appVariables.contentPulledLeft\" \n" +
    "     common-click-outside=\"hideClipboard()\">\n" +
    "  <div class=\"clipboard-handle\" ng-click=\"toggleClipboard()\">\n" +
    "    <i class=\"icon-sl-outline icon-star-1\"></i>\n" +
    "  </div>\n" +
    "  <div class=\"clipboard-menu\" ng-if=\"clipboardListVisible\">\n" +
    "    <div ng-show=\"loadingListClipboard\" class=\"separator\"></div>\n" +
    "    <div ng-show=\"loadingListClipboard\" common-spinner=\"true\" common-spinner-options=\"spinnerOptions\"></div>\n" +
    "    \n" +
    "    <div class=\"alert alert-warning\" role=\"alert\" ng-if=\"emptyListClipboard && !loadingListClipboard\">\n" +
    "      <i class=\"icon-solo icon-alert\"></i>\n" +
    "      <p>{{ 'Menu.clipboardEmpty' | translate }}</p>\n" +
    "    </div>\n" +
    "    \n" +
    "    <div class=\"panel panel-no-margin\" ng-if=\"clipboardElements.articles.length && clipboardElements.documents.length\">\n" +
    "      <div class=\"panel-body panel-dark\">\n" +
    "          Artykuły\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <ul class=\"list-group list-group-white\" ng-if=\"!emptyListClipboard\">\n" +
    "      <li class=\"list-group-item no-animate\" ng-repeat=\"item in clipboardElements.articles\" ng-class=\"item.color\">        \n" +
    "        <a ng-click=\"openOrGoTo(item)\">\n" +
    "          <p class=\"xsmall text-darker\">{{ item.subtitle }}</p>\n" +
    "          <p>{{ item.title }}</p>\n" +
    "        </a>\n" +
    "        <i class=\"icon-sl-outline icon-delete-1-1\" ng-click=\"!deleteInProgress && deleteItemFromClipboard(item.metadata.delete_url)\"></i>\n" +
    "      </li>      \n" +
    "    </ul>\n" +
    "\n" +
    "    <div class=\"panel panel-no-margin\" ng-if=\"clipboardElements.articles.length && clipboardElements.documents.length\">\n" +
    "      <div class=\"panel-body panel-dark\">\n" +
    "          Dokumenty\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <ul class=\"list-group list-group-white\" ng-if=\"!emptyListClipboard\">\n" +
    "      <li class=\"list-group-item no-animate\" ng-repeat=\"item in clipboardElements.documents\" ng-class=\"item.color\">\n" +
    "        <a ng-click=\"openOrGoTo(item)\">\n" +
    "          <p class=\"xsmall text-darker\">{{ item.subtitle }}</p>\n" +
    "          <p>{{ item.title }}</p>\n" +
    "        </a>\n" +
    "        <i class=\"icon-sl-outline icon-delete-1-1\" ng-click=\"!deleteInProgress && deleteItemFromClipboard(item.metadata.delete_url)\"></i>\n" +
    "      </li>\n" +
    "    </ul>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/help/help.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/help/help.tpl.html",
    "<div class=\"help pull-right\" ng-controller=\"ihestiaMenuHelpCtrl\" ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "    <div class=\"help-handle\"  ng-click=\"toggleWindowWithHelp()\">\n" +
    "        <i class=\"icon-sl-outline icon-information\"></i>\n" +
    "    </div>\n" +
    "\n" +
    "    <ihestia-moveable-popup drv-settings=\"moveablePopupSettings\" ng-if=\"moveablePopupSettings.show\"></ihestia-moveable-popup>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/help/helpModalContent.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/help/helpModalContent.tpl.html",
    "<div class=\"panel panel-no-margin\" ng-if=\"tplData.helpDesc.description\">\n" +
    "    <div class=\"panel-body body-scroll\"\n" +
    "         ng-class=\"{ 'body-scroll-1' : tplData.helpDesc.links.length === 1, 'body-scroll-2' : tplData.helpDesc.links.length === 2 }\"\n" +
    "         ng-bind-html=\"tplData.helpDesc.description\">\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"panel panel-no-margin bg-gray-dark\" ng-if=\"tplData.helpDesc.links.length > 0\">\n" +
    "    <div class=\"panel-body\">\n" +
    "        <h6 class=\"text-white\">{{tplData.clientUserLogged?'Materiały informacyjne':'Materiały szkoleniowe'}}</h6>\n" +
    "    </div>\n" +
    "    <div class=\"list-group list-group-light\">\n" +
    "        <a class=\"list-group-item\" ng-click=\"openWebside(link.address)\" ng-repeat=\"link in tplData.helpDesc.links\">{{link.description}}</a>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"panel panel-no-margin bg-gray-dark\">\n" +
    "    <div class=\"panel-body\">\n" +
    "        <h6 class=\"text-white\">{{ tplData.clientUserLogged ? 'Twój Opiekun' : 'Obszar wsparcia Agenta' }}</h6>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"panel panel-no-margin\" ng-if=\"!tplData.clientUserLogged\">\n" +
    "    <div class=\"panel-body\">\n" +
    "        <div class=\"form-group\">\n" +
    "            <label>Masz pytanie?</label>\n" +
    "            <button type=\"button\" class=\"btn btn-default btn-block\" style=\"text-align: left;\" ng-click=\"openAgentHelp()\">\n" +
    "                <span class=\"pull-left\">Wyszukaj w dziale POMOC</span>\n" +
    "                <i class=\"icon-sl-fill icon-magnifier pull-right\" style=\"margin: 2px -5px 0 0;\"></i>\n" +
    "            </button>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"panel panel-no-margin bg-gray-lighter\">\n" +
    "    <div class=\"panel-body\">\n" +
    "        <div class=\"row middle-xs\">\n" +
    "            <div class=\"col-xs\" ng-if=\"!tplData.clientUserLogged\">Problemy techniczne?</div>\n" +
    "            <div class=\"col-xs-size\" ng-if=\"!tplData.clientUserLogged && videoChatEnabled\">\n" +
    "                <button type=\"button\" class=\"btn btn-default\" ng-click=\"openVideoChatWindow()\">Wideo</button>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-size\" ng-if=\"tplData.clientUserLogged\">\n" +
    "                <button type=\"button\" class=\"btn btn-default\" ng-click=\"contactWithAgent()\">Skontaktuj się z Opiekunem</button>\n" +
    "            </div>\n" +
    "            <div class=\"col-xs-size\" ng-if=\"!tplData.clientUserLogged && (spacechatEnabled || chatbotEnabled)\" style=\"padding-left: 0;\">\n" +
    "                <button type=\"button\" class=\"btn btn-default\" ng-click=\"openChatWindow()\">Czat</button>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/icContext/menuIcContext.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/icContext/menuIcContext.tpl.html",
    "<div class=\"context pull-right\" ng-controller=\"menuIcContextCtrl\" ng-hide=\"appVariables.contentPulledLeft\" common-click-outside=\"hidePopover();\">\n" +
    "    <div class=\"context-handle\" ng-click=\"togglePopoverVisibility();\">\n" +
    "        <i class=\"icon-sl-outline\" ng-class=\"activeContext.icon\"></i>\n" +
    "    </div>\n" +
    "    <div class=\"context-menu\" ng-if=\"popoverVisible\">\n" +
    "        <div class=\"list-group list-group-white\">\n" +
    "            <a class=\"list-group-item\" ng-class=\"{ 'active' : context.active }\" ng-click=\"selectContext(context);\" ng-repeat=\"context in availableContextList\">\n" +
    "                {{ context.name }}\n" +
    "            </a>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/leadingColor/leadingColor.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leadingColor/leadingColor.tpl.html",
    "<div class=\"row\" ng-controller=\"leadingColorCtrl\">\n" +
    "    <div class=\"col-xs-10 col-xs-offset-1\">\n" +
    "        <form class=\"form-horizontal\">\n" +
    "            <div class=\"form-group\">\n" +
    "                <label class=\"control-label col-xs-5\">{{'lc.agentNumber' | translate:{componentCode: 'Portal'} }}</label>\n" +
    "                <div class=\"col-xs-7\">\n" +
    "                    <div class=\"input-wrapper autocomplete\" ng-class=\"{'has-error': helper.tplData.errors.profileNumber}\" ng-if=\"!helper.tplData.context.profileNumber\">\n" +
    "                        <input-pending-indicator indicator-flag=\"loadingProfilesNumbers\"></input-pending-indicator>\n" +
    "                        <input \n" +
    "                            ng-model=\"helper.tplData.profileNumberInput\" \n" +
    "                            type=\"text\" \n" +
    "                            class=\"form-control text-uppercase\" \n" +
    "                            placeholder=\"{{'lsnBase.autocompliter.search' | translate:{componentCode: 'lsnBase'} }}\" \n" +
    "                            autocomplete=\"false\" \n" +
    "                            typeahead-min-length=\"1\" \n" +
    "                            typeahead-wait-ms=\"300\" \n" +
    "                            typeahead=\"item.symbol for item in getProfilesNumbers($viewValue)\" \n" +
    "                            typeahead-on-select=\"onSelectProfileNumber($item);\" model-view-value=\"true\"\n" +
    "                            input-popover data-content=\"{{helper.tplData.errors.profileNumber.text}}\" popover-invalid=\"{{helper.tplData.errors.profileNumber}}\" popover-show-on=\"focused\">\n" +
    "                    </div>\n" +
    "                    <div class=\"keywords\" ng-if=\"helper.tplData.context.profileNumber\">\n" +
    "                      <span class=\"keywords-item keywords-item-block ng-binding\">\n" +
    "                        {{helper.tplData.context.profileNumber.symbol}}\n" +
    "                        <i class=\"icon-sl-outline icon-delete-3\" ng-click=\"removeProfileNumber();\"></i>\n" +
    "                      </span>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-if=\"helper.tplData.context.profileNumber\">\n" +
    "                <label class=\"col-xs-5 control-label\">{{'leadingColor' | translate:{componentCode: 'Portal'} }}</label>\n" +
    "                <div class=\"col-xs-7\" ng-class=\"{ 'has-error': helper.tplData.errors.leadingColor}\">\n" +
    "                    <input type=\"text\" class=\"form-control\" maxlength=\"26\" name=\"leadingColor\" ng-model=\"helper.tplData.context.leadingColor\" input-popover data-content=\"{{helper.tplData.errors.leadingColor.text}}\" popover-invalid=\"{{helper.tplData.errors.leadingColor}}\" popover-show-on=\"focused\" />\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-if=\"helper.tplData.context.profileNumber\">\n" +
    "                <label class=\"col-xs-5 control-label\">\n" +
    "                    {{'appLogo' | translate:{componentCode: 'Portal'} }}                \n" +
    "                    <span bs-popover data-trigger=\"click\" \n" +
    "                    data-content-template=\"ihestia-menu-template-app/leadingColor/leadingColorUploadPopup.tpl.html\"\n" +
    "                    data-auto-close=\"true\" data-placement=\"left\" class=\"icon-solo icon-info tool-tip\"></span>\n" +
    "                </label>\n" +
    "                <div class=\"col-xs-7\" ng-if=\"!helper.tplData.context.logoPath\"> \n" +
    "                    <lsn-common-upload uploader-options=\"uploaderLOptions\" class=\"ng-isolate-scope\" attachment-list=\"helper.tplData.context.logo\" drv-template=\"ihestia-menu-template-app/leadingColor/leadingColorUpload.tpl.html\" name=\"logo\" document-context=\"'Administration'\" document-code=\"'Logo'\" tpl-options=\"tplOptions\" callbacks=\"uploadCallbacks\">\n" +
    "                    </lsn-common-upload>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-7\" ng-if=\"helper.tplData.context.logoPath\">\n" +
    "                    <div class=\"form-control-static\">\n" +
    "                        <div class=\"flex\">\n" +
    "                            <div class=\"input-flex input-size\">\n" +
    "                                <a class=\"link link-primary\" ng-click=\"downloadSrcFile('logoPath')\">{{'Public.download' | translate:{componentCode: 'Public'} }}</a>\n" +
    "                            </div>\n" +
    "                            <div class=\"input-flex\">\n" +
    "                                <a class=\"link link-primary\" ng-click=\"clearSrcFile('logo')\">{{'Public.delete' | translate:{componentCode: 'Public'} }}</a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"form-group\" ng-if=\"helper.tplData.context.profileNumber\">\n" +
    "                <label class=\"col-xs-5 control-label\">{{'printLogo' | translate:{componentCode: 'Portal'} }}\n" +
    "                    <span bs-popover data-trigger=\"click\" \n" +
    "                    data-content-template=\"ihestia-menu-template-app/leadingColor/leadingColorUploadPopupPrint.tpl.html\"\n" +
    "                    data-auto-close=\"true\" data-placement=\"left\" class=\"icon-solo icon-info tool-tip\"></span>\n" +
    "                </label>\n" +
    "                <div class=\"col-xs-7\" ng-if=\"!helper.tplData.context.logoPrintPath\"> \n" +
    "                    <lsn-common-upload uploader-options=\"uploaderLPOptions\" class=\"ng-isolate-scope\" attachment-list=\"helper.tplData.context.logoPrint\" drv-template=\"ihestia-menu-template-app/leadingColor/leadingColorUpload.tpl.html\" name=\"logoPrint\" document-context=\"'Administration'\" document-code=\"'Logo'\" tpl-options=\"tplOptions\"  callbacks=\"uploadCallbacks\">\n" +
    "                    </lsn-common-upload>\n" +
    "                </div>\n" +
    "                <div class=\"col-xs-7\" ng-if=\"helper.tplData.context.logoPrintPath\">\n" +
    "                    <div class=\"form-control-static\">\n" +
    "                        <div class=\"flex\">\n" +
    "                            <div class=\"input-flex input-size\">\n" +
    "                                <a class=\"link link-primary\" ng-click=\"downloadSrcFile('logoPrintPath')\">{{'Public.download' | translate:{componentCode: 'Public'} }}</a>\n" +
    "                            </div>\n" +
    "                            <div class=\"input-flex\">\n" +
    "                                <a class=\"link link-primary\" ng-click=\"clearSrcFile('logoPrint')\">{{'Public.delete' | translate:{componentCode: 'Public'} }}</a>\n" +
    "                            </div>\n" +
    "                        </div>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </form>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/leadingColor/leadingColorUpload.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leadingColor/leadingColorUpload.tpl.html",
    "    <ihestia-upload-form url=\"@\" uoptions=\"uploaderOptions\"></ihestia-upload-form>\n" +
    "    <ng-include ng-if=\"tplOptions.tplForFileList\" src=\"tplOptions.tplForFileList\"></ng-include>");
}]);

angular.module("ihestia-menu-template-app/leadingColor/leadingColorUploadPopup.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leadingColor/leadingColorUploadPopup.tpl.html",
    "{{'lc.uploadLogoAcceptableFormat' | translate:{componentCode: 'Menu'} }}<br/>\n" +
    "{{'lc.uploadLogoSize' | translate:{componentCode: 'Menu'} }}");
}]);

angular.module("ihestia-menu-template-app/leadingColor/leadingColorUploadPopupPrint.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leadingColor/leadingColorUploadPopupPrint.tpl.html",
    "{{'lc.uploadLogoPrintAcceptableFormat' | translate:{componentCode: 'Menu'} }} <br/>\n" +
    "{{'lc.uploadLogoPrintScale' | translate:{componentCode: 'Menu'} }}<br/>\n" +
    "{{'lc.uploadLogoPrintOtherInfo' | translate:{componentCode: 'Menu'} }}");
}]);

angular.module("ihestia-menu-template-app/leftNavBar/navbar.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leftNavBar/navbar.tpl.html",
    "<div ng-controller=\"ihestiaMenuLeftNavbarCtrl\" class=\"pull-down-menu pull-left\" ng-show=\"iHestiaMenuSettings.visible\">\n" +
    "	<a class=\"pd-menu-handle\"\n" +
    "		ng-click=\"mainMenuClicked()\"\n" +
    "		ng-class=\"{'pd-menu-selected': navBarSettings.openLvl === 'all'}\"\n" +
    "		ng-mouseenter=\"mouseenterMain()\"\n" +
    "		ng-mouseleave=\"mouseleaveMain()\">\n" +
    "		<i class=\"icon-sl-fill icon-list-1\"></i>\n" +
    "	</a>\n" +
    "	<div class=\"pd-menu-wrapper\"\n" +
    "		ng-mouseenter=\"showFullLeftBar()\"\n" +
    "		ng-class=\"{'pd-menu-open-part':navBarSettings.openLvl === 'part', 'pd-menu-open-all': navBarSettings.openLvl === 'all'}\">\n" +
    "		<div class=\"pd-menu-scroller\">\n" +
    "			<ul class=\"pd-menu\">\n" +
    "				<li menu-code=\"item.code\"\n" +
    "					ng-repeat=\"item in iHestiaMenuSettings.items\"\n" +
    "					ng-disabled=\"item.disabled\"\n" +
    "					ng-click=\"itemClicked(item, $event)\">\n" +
    "					<a ng-class=\"{'disabled': item.disabled}\" ng-if=\"!item.customTemplate\">\n" +
    "						<i ng-class=\"item.class\"></i>\n" +
    "						{{item.name}}\n" +
    "					</a>\n" +
    "					<ul class=\"pd-menu-submenu\" ng-if=\"item.items\">\n" +
    "						<li menu-code=\"subitem.code\"\n" +
    "							ng-repeat=\"subitem in item.items\"\n" +
    "							ng-disabled=\"subitem.disabled\"\n" +
    "							ng-click=\"itemClicked(subitem, $event)\">\n" +
    "							<a ng-class=\"{'disabled': subitem.disabled}\">\n" +
    "								<i class=\"icon-solo\" ng-class=\"subitem.class\"></i>\n" +
    "								{{subitem.name}}\n" +
    "							</a>\n" +
    "						</li>\n" +
    "					</ul>\n" +
    "					<ng-include src=\"item.customTemplate\" ng-if=\"item.customTemplate\"></ng-include>\n" +
    "				</li>\n" +
    "			</ul>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/leftNavBar/v2/menuLeftNavbar.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/leftNavBar/v2/menuLeftNavbar.tpl.html",
    "<div ng-init=\"init()\" class=\"pull-down-menu pull-left\">\n" +
    "	<a class=\"pd-menu-handle\"\n" +
    "		ng-click=\"mainMenuClicked()\"\n" +
    "		ng-class=\"{'pd-menu-selected': navBarSettings.openLvl === 'all'}\"\n" +
    "		ng-mouseenter=\"mouseenterMain()\"\n" +
    "		ng-mouseleave=\"mouseleaveMain()\">\n" +
    "		<i class=\"icon-sl-fill icon-list-1\"></i>\n" +
    "	</a>\n" +
    "	<div class=\"pd-menu-wrapper\"\n" +
    "		ng-mouseenter=\"showFullLeftBar()\"\n" +
    "		ng-class=\"{'pd-menu-open-part':navBarSettings.openLvl === 'part', 'pd-menu-open-all': navBarSettings.openLvl === 'all'}\">\n" +
    "		<div class=\"pd-menu-scroller\">\n" +
    "			<ul class=\"pd-menu\">\n" +
    "				<li menu-code=\"item.code\"\n" +
    "					ng-repeat=\"item in menuItems\"\n" +
    "					ng-disabled=\"item.disabled\"\n" +
    "					ng-click=\"itemClicked(item, $event)\">\n" +
    "					<a ng-class=\"{'disabled': item.disabled}\" ng-if=\"!item.customTemplate\">\n" +
    "						<i ng-class=\"item.class\"></i>\n" +
    "						{{item.name}}\n" +
    "					</a>\n" +
    "					<ul class=\"pd-menu-submenu\" ng-if=\"item.items\">\n" +
    "						<li menu-code=\"subitem.code\"\n" +
    "							ng-repeat=\"subitem in item.items\"\n" +
    "							ng-disabled=\"subitem.disabled\"\n" +
    "							ng-click=\"itemClicked(subitem, $event)\"\n" +
    "							ng-if=\"subitem.visible\">\n" +
    "							<a ng-class=\"{'disabled': subitem.disabled}\">\n" +
    "								<i class=\"icon-solo\" ng-class=\"subitem.class\"></i>\n" +
    "								{{subitem.name}}\n" +
    "							</a>\n" +
    "						</li>\n" +
    "					</ul>\n" +
    "					<ng-include src=\"item.customTemplate\" ng-if=\"item.customTemplate\"></ng-include>\n" +
    "				</li>\n" +
    "			</ul>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/searchBox/searchBox.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/searchBox/searchBox.tpl.html",
    "<div class=\"search-box pull-right\" \n" +
    "     ng-controller=\"ihestiaMenuSearchBoxCtrl\" \n" +
    "     ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "\n" +
    "	<div class=\"search-box-handler\" ng-click=\"search()\">\n" +
    "		<i class=\"icon-sl-outline icon-magnifier\"></i>\n" +
    "	</div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/taskCounter/taskCounter.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/taskCounter/taskCounter.tpl.html",
    "<div class=\"task-counter pull-right\"\n" +
    "     ng-controller=\"taskCounterCtrl\"\n" +
    "     ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "\n" +
    "    <div class=\"task-counter-handler\" ng-click=\"goToChangedTasks()\">\n" +
    "        <i class=\"icon-sl-outline icon-clipboard-checked\"></i>\n" +
    "        <span ng-if=\"tplData.tasksCount > 0\" class=\"task-counter-handler-notification\">{{tplData.tasksCount > 99 ? '99+' : tplData.tasksCount}}</span>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/topMenu/ihestiaMenuAgentReports/ihestiaMenuAgentReports.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/ihestiaMenuAgentReports/ihestiaMenuAgentReports.tpl.html",
    "<div ng-controller=\"ihestiaMenuAgentReportsCtrl\">\n" +
    "  <div class=\"row\" ng-if=\"permissions.policies || permissions.offers\">\n" +
    "    <div class=\"col-xs-6\" ng-if=\"permissions.policies\">\n" +
    "\n" +
    "      <div class=\"type-selector\">\n" +
    "        <ul class=\"type-selector-list list-unstyled\">\n" +
    "          <li>\n" +
    "            <a class=\"list-item\" ng-click=\"goToReport('policies')\">\n" +
    "              <div class=\"row middle-xs\">\n" +
    "                <div class=\"col-xs-size\"><i class=\"symbol symbol-risk icon-sl-outline icon-file-1 bg-primary-dark\"></i></div>\n" +
    "                <div ng-if=\"isLife\" class=\"col-xs\">{{'customersAndPoliciesReportLife' | translate:{componentCode: 'Reports'} }}</div>\n" +
    "                <div ng-if=\"!isLife\" class=\"col-xs\">{{'customersAndPoliciesReport' | translate:{componentCode: 'Reports'} }}</div>\n" +
    "              </div>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6\" ng-if=\"permissions.offers\">\n" +
    "\n" +
    "      <div class=\"type-selector\">\n" +
    "        <ul class=\"type-selector-list list-unstyled\">\n" +
    "          <li>\n" +
    "            <a class=\"list-item\" ng-click=\"goToReport('offers/')\">\n" +
    "              <div class=\"row middle-xs\">\n" +
    "                <div class=\"col-xs-size\"><i class=\"symbol symbol-risk icon-sl-outline icon-calculator-2 bg-primary-dark\"></i></div>\n" +
    "                <div ng-if=\"isLife\" class=\"col-xs\">{{'offersAndCalculationsReportLife' | translate:{componentCode: 'Public'} }}</div>\n" +
    "                <div ng-if=\"!isLife\" class=\"col-xs\">{{'offersAndCalculationsReport' | translate:{componentCode: 'Dashboard'} }}</div>\n" +
    "              </div>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"separator\" ng-if=\"permissions.policies || permissions.offers\"></div>\n" +
    "\n" +
    "  <div class=\"row\" ng-if=\"permissions.patrons || permissions.paymentReport\">\n" +
    "    <div ng-if=\"permissions.patrons\" class=\"col-xs-6\">\n" +
    "\n" +
    "      <div class=\"type-selector\">\n" +
    "        <ul class=\"type-selector-list list-unstyled\">\n" +
    "          <li>\n" +
    "            <a class=\"list-item\" ng-click=\"goToReport('patrons')\">\n" +
    "              <div class=\"row middle-xs\">\n" +
    "                <div class=\"col-xs-size\"><i class=\"symbol symbol-risk icon-sl-outline icon-user-checked-1 bg-primary-dark\"></i></div>\n" +
    "                <div class=\"col-xs\">{{'customerPatronsReport' | translate:{componentCode: 'Reports'} }}</div>\n" +
    "              </div>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6\" ng-if=\"permissions.paymentReport\">\n" +
    "\n" +
    "      <div class=\"type-selector\">\n" +
    "        <ul class=\"type-selector-list list-unstyled\">\n" +
    "          <li ng-if=\"hasPermissionForPaymentReportView\">\n" +
    "            <a class=\"list-item\" ng-click=\"goToReport('paymentReport')\">\n" +
    "              <div class=\"row middle-xs\">\n" +
    "                <div class=\"col-xs-size\"><i class=\"symbol symbol-risk icon-sl-outline icon-hand-coin bg-primary-dark\"></i></div>\n" +
    "                <div class=\"col-xs\">{{'paymentReport' | translate:{componentCode: 'Reports'} }}</div>\n" +
    "              </div>\n" +
    "            </a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"separator\"></div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-xs-12 text-right\">\n" +
    "      <button class=\"btn btn-default\" ng-click=\"goToUrl('AGENT_URL', 'external/', '_self')\">{{ 'Public.showMore' | translate }}</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/ihestiaMenuLifeInsurance/ihestiaMenuSaleLife.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/ihestiaMenuLifeInsurance/ihestiaMenuSaleLife.tpl.html",
    "<div class=\"row\" ng-controller=\"ihestiaMenuSaleLifeCtrl\">\n" +
    "  <div class=\"col-xs-6\">\n" +
    "    <div class=\"panel panel-prism panel-shadow panel-clickable\" ng-class=\"{'disabled': !lifePermissions.lifeSalePath}\" ng-click=\"gotoLifeSalePath()\">\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"panel-heading\"><h3>ERGO 4</h3></div>\n" +
    "        <div class=\"salepath-desc small\">\n" +
    "          Kompleksowa ochrona ubezpieczeniowa\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"prism\">\n" +
    "        <div class=\"prism-item bg-orange\"><i class=\"icon-sl-outline icon-heartpulse\"></i></div>\n" +
    "        <div class=\"prism-item bg-yellow\"><i class=\"icon-sl-outline icon-disabled\"></i></div>\n" +
    "        <div class=\"prism-item bg-green\"><i class=\"icon-sl-outline icon-first-aid\"></i></div>\n" +
    "        <div class=\"prism-item bg-lightblue\"><i class=\"icon-solo icon-children\"></i></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"col-xs-6\">\n" +
    "    <div class=\"panel panel-prism panel-shadow panel-clickable\" ng-class=\"{'disabled': !lifePermissions.sirius}\" ng-click=\"gotoSirius()\">\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"panel-heading\"><h3>Syriusz</h3></div>\n" +
    "        <div class=\"salepath-desc small\">\n" +
    "          Ubezpieczenia grupowe\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "  <!-- <div class=\"col-xs-12 text-right\">\n" +
    "    <button class=\"btn btn-default\" ng-click=\"goToUrl('PORTAL_URL', 'external/lifeInsurance', '_self')\">{{ 'Public.showMore' | translate }}</button>\n" +
    "  </div> -->\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/menuCIAgentCenter.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/menuCIAgentCenter.tpl.html",
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-4\" ng-repeat=\"(color, elem) in itemm.submenuData\">\n" +
    "		<h6 class=\"label label-lg\" ng-class=\"{ 'green' : 'bg-green', 'orange' : 'bg-warning', 'grey' : 'bg-graphite', 'red' : 'bg-danger-dark', 'darkblue' : 'bg-navy' }[color]\">{{ elem.label }}</h6>\n" +
    "		<div ng-class=\"{ 'hidden' : currentUserCharacterType !== 'SalesProfile' && currentUserCharacterType !== 'Worker' && color === 'red' }\" >\n" +
    "			<div class=\"panel panel-light panel-no-margin\">\n" +
    "				<div class=\"panel-body\">\n" +
    "					<p ng-repeat=\"link in elem.columns[0].links\">\n" +
    "						<a ng-href=\"{{ link.url }}\" class=\"link link-darker\">{{link.label}}</a>\n" +
    "					</p>\n" +
    "				</div>\n" +
    "			</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/menuCILibrary.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/menuCILibrary.tpl.html",
    "<div class=\"row\">\n" +
    "    <div ng-repeat=\"(color, elem) in itemm.submenuData\" ng-class=\"color=='green' ? 'col-xs-8' : 'col-xs-4'\">\n" +
    "        <h4>{{ elem.label }}</h4>\n" +
    "        <hr>\n" +
    "        <div ng-class=\"color === 'green' ? 'row' : ''\">\n" +
    "            <div ng-repeat=\"column in elem.columns\" ng-class=\"color === 'green' ? 'col-xs-6' : ''\">\n" +
    "                <div class=\"panel panel-light panel-no-margin\" ng-repeat=\"element in column\" >\n" +
    "                \n" +
    "                    <div class=\"panel-body\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <h5>{{ element.header }}</h5>\n" +
    "                        </div>\n" +
    "                        <p ng-repeat=\"link in element.links\">\n" +
    "                            <a ng-href=\"{{link.url}}\" class=\"link link-darker\">{{ link.label }}</a>\n" +
    "                        </p>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/menuCILibrary_new.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/menuCILibrary_new.tpl.html",
    "<div class=\"row\">\n" +
    "    <div ng-repeat=\"(color, elem) in itemm.submenuData\" ng-class=\"color=='green' ? 'col-xs-8' : 'col-xs-4'\">\n" +
    "        <h4>{{ elem.label }}</h4>\n" +
    "        <hr>\n" +
    "        <div ng-class=\"color === 'green' ? 'row' : ''\">\n" +
    "            <div ng-repeat=\"column in elem.columns\" ng-class=\"color === 'green' ? 'col-xs-6' : ''\">\n" +
    "                <div class=\"panel panel-light panel-no-margin\" ng-repeat=\"element in column\" >\n" +
    "                \n" +
    "                    <div class=\"panel-body\">\n" +
    "                        <div class=\"panel-heading\">\n" +
    "                            <h5>{{ element.header }}</h5>\n" +
    "                        </div>\n" +
    "                        <p ng-repeat=\"link in element.links\">\n" +
    "                            <a ng-href=\"{{link.url}}\" class=\"link link-darker\">{{ link.label }}</a>\n" +
    "                        </p>\n" +
    "                    </div>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/menuCINews.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/menuCINews.tpl.html",
    "<div class=\"row\">\n" +
    " <div class=\"col-xs-6\" ng-repeat=\"(color, item) in itemm.submenuData.results\">\n" +
    "   <a ng-href=\"{{ CI_URL + 'external/news/article/' + item.id }}\">\n" +
    "     <div class=\"panel panel-no-margin panel-shadow panel-clickable panel-box panel-image\">\n" +
    "       <div ng-if=\"item.layout_version==2\" class=\"image-overflow-hidden\" ng-class=\"{ 'movie-overlay' : item.has_video, 'hidden' : false }\">\n" +
    "           <img alt=\"{{item.title}}\" class=\"img-responsive\" width=\"100%\" ng-if=\"item.miniature_graphic_full_url\" ng-src=\"{{ item.miniature_graphic_full_url }}\" >\n" +
    "       </div>\n" +
    "       <div ng-if=\"!item.visited\" class=\"item-new bg-danger text-white text-uppercase text-center small\">Nowe</div>\n" +
    "         <div class=\"panel-body\">\n" +
    "               <div class=\"text-darker small\">{{ item.category_label }}</div>\n" +
    "               <div class=\"separator\"></div>\n" +
    "               <div class=\"overflow-hidden\" ng-class=\"{ 'overflow-hidden-heading-only' : item.layout_version===2 }\">\n" +
    "                 <h3>{{ item.title }}</h3>\n" +
    "                 <div class=\"separator\"></div>\n" +
    "                 <p data-ng-show=\"item.layout_version===4\">{{ item.miniature_description }}</p>\n" +
    "                 <img alt=\"{{item.title}}\" ng-if=\"item.layout_version==3 && item.miniature_graphic_full_url\" ng-src=\"{{ item.miniature_graphic_full_url }}\" />\n" +
    "               </div>\n" +
    "               <div class=\"separator\"></div>\n" +
    "               <div class=\"text-darker small\">{{ item.publication_date }}\n" +
    "           </div>                            \n" +
    "         </div>                        \n" +
    "     </div>\n" +
    "   </a>\n" +
    " </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/menuSaleProperty.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/menuSaleProperty.tpl.html",
    "<div class=\"row\">\n" +
    "  <div class=\"col-xs-6\">\n" +
    "    <div class=\"panel panel-prism panel-shadow panel-clickable\" ng-class=\"{'disabled': !availability.E1}\" ng-click=\"goToUrl('E1_URL')\">\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"panel-heading\"><h3>{{ 'Public.e1' | translate }}</h3></div>\n" +
    "        <div class=\"salepath-desc small\">\n" +
    "          {{ 'Public.e1Desc' | translate }}\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"prism\">\n" +
    "        <div class=\"prism-item bg-ogien\"><i class=\"icon-solo icon-mury\"></i></div>\n" +
    "        <div class=\"prism-item bg-oczp\"><i class=\"icon-solo icon-oczaw\"></i></div>\n" +
    "        <div class=\"prism-item bg-nnwkip\"><i class=\"icon-solo icon-nnwk\"></i></div>\n" +
    "        <div class=\"prism-item bg-oc\"><i class=\"icon-solo icon-ock\"></i></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"col-xs-6\">\n" +
    "    <div class=\"panel panel-prism panel-shadow panel-clickable\" ng-class=\"{'disabled': !availability.E7}\" ng-click=\"goToUrl('E7_URL')\">\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"panel-heading\"><h3>{{ 'Public.e7' | translate }}</h3></div>\n" +
    "        <div class=\"salepath-desc small\">\n" +
    "          {{ 'Public.e7Desc' | translate }}\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"prism\">\n" +
    "        <div class=\"prism-item bg-ogien\"><i class=\"icon-solo icon-mury\"></i></div>\n" +
    "        <div class=\"prism-item bg-kradziez\"><i class=\"icon-solo icon-kradziez\"></i></div>\n" +
    "        <div class=\"prism-item bg-oczp\"><i class=\"icon-solo icon-oczaw\"></i></div>\n" +
    "        <div class=\"prism-item bg-nnw\"><i class=\"icon-solo icon-nnw\"></i></div>\n" +
    "        <div class=\"prism-item bg-ac\"><i class=\"icon-solo icon-ac\"></i></div>\n" +
    "        <div class=\"prism-item bg-nnwkip\"><i class=\"icon-solo icon-nnwk\"></i></div>\n" +
    "        <div class=\"prism-item bg-oc\"><i class=\"icon-solo icon-ock\"></i></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"col-xs-6\">\n" +
    "    <div class=\"panel panel-prism panel-shadow panel-clickable\" ng-class=\"{'disabled': !availability.MF}\" ng-click=\"goToUrl('MF_URL')\">\n" +
    "      <div class=\"panel-body\">\n" +
    "        <div class=\"panel-heading\"><h3>{{ 'Public.mf' | translate }}</h3></div>\n" +
    "        <div class=\"salepath-desc small\">\n" +
    "          {{ 'Public.mfDesc' | translate }}\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"prism\">\n" +
    "        <div class=\"prism-item bg-ogien\"><i class=\"icon-solo icon-mury\"></i></div>\n" +
    "        <div class=\"prism-item bg-kradziez\"><i class=\"icon-solo icon-kradziez\"></i></div>\n" +
    "        <div class=\"prism-item bg-oczp\"><i class=\"icon-solo icon-oczaw\"></i></div>\n" +
    "        <div class=\"prism-item bg-nnw\"><i class=\"icon-solo icon-nnw\"></i></div>\n" +
    "        <div class=\"prism-item bg-ac\"><i class=\"icon-solo icon-ac\"></i></div>\n" +
    "        <div class=\"prism-item bg-nnwkip\"><i class=\"icon-solo icon-nnwk\"></i></div>\n" +
    "        <div class=\"prism-item bg-oc\"><i class=\"icon-solo icon-ock\"></i></div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"col-xs-12 text-right\">\n" +
    "    <button class=\"btn btn-default\" ng-click=\"goToUrl('PORTAL_URL', 'external/propertyInsurance', '_self')\">{{ 'Public.showMore' | translate }}</button>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/simpleSubmenu.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/simpleSubmenu.tpl.html",
    "<div class=\"list-group list-group-white\">\n" +
    "    <a class=\"list-group-item\" ng-repeat=\"subitem in item.items\"\n" +
    "     ng-class=\"{'disabled' : subitem.disabled }\" \n" +
    "     ng-click=\"submenuItemClick(subitem, $event)\">\n" +
    "     {{ subitem.text }}\n" +
    "    </a>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/topMenu/topmenu.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/topMenu/topmenu.tpl.html",
    "<div class=\"app-menu-dropdown pull-left\" \n" +
    "     ng-controller=\"ihestiaMenuTopMenuCtrl\" \n" +
    "     ng-hide=\"appVariables.contentPulledLeft\"\n" +
    "     common-click-outside=\"clickOutsideMenu()\">\n" +
    "  <div class=\"app-md-item\" ng-repeat=\"item in iHestiaMenuOpt.centerNavBar.items\">\n" +
    "    <div class=\"app-md-handle\" \n" +
    "         ng-click=\"menuItemClick(item)\" \n" +
    "         ng-class=\"{ 'active' : item.active }\">\n" +
    "      {{ item.name }}\n" +
    "      <i class=\"icon-sl-fill icon-arrow-65\"></i>\n" +
    "    </div>\n" +
    "    \n" +
    "    <div class=\"app-md-menu\" ng-class=\"{ 'hidden' : !item.active }\">\n" +
    "      <div class=\"list-group list-group-white\">\n" +
    "          <a class=\"list-group-item\"\n" +
    "             ng-if=\"!item.submenu\"\n" +
    "             ng-repeat-start=\"item in item.items\"\n" +
    "             ng-class=\"{ 'active' : item.submenuVisible, 'disabled' : item.disabled }\"\n" +
    "             ng-href=\"{{ item.click }}\"\n" +
    "             ng-click=\"submenuItemClick(item, $event)\">\n" +
    "            {{ item.text }}\n" +
    "          </a>\n" +
    "          <!-- nowy chrome blokuje <a> wewnątrz innego <a> dlatego jest <span> -->\n" +
    "          <span class=\"list-group-item\"\n" +
    "                ng-if=\"item.submenu\"\n" +
    "                ng-repeat-end\n" +
    "                ng-class=\"{ 'active' : item.submenuVisible, 'disabled' : item.disabled }\"\n" +
    "                ng-mouseenter=\"showSubmenu(item)\"\n" +
    "                ng-mouseleave=\"hideSubmenu(item)\"\n" +
    "                ng-href=\"{{ item.click }}\"\n" +
    "                ng-click=\"item.urlCode && submenuItemClick(item, $event)\">\n" +
    "              {{ item.text }}\n" +
    "             <i class=\"icon-sl-outline icon-arrow-68 pull-right\" ng-if=\"item.submenu\"></i>\n" +
    "\n" +
    "            <div\n" +
    "                ng-if=\"item.submenu && !item.disabled\"\n" +
    "                ng-class=\"{ 'hidden' : !item.submenuVisible}\"\n" +
    "                class=\"{{item.submenuClasses}} app-md-menu-sub no-animate\"\n" +
    "                ng-include=\"item.submenuTpl\"\n" +
    "                ng-init=\"itemm = item\"\n" +
    "                ng-controller=\"emptyCtrl\">\n" +
    "            </div>\n" +
    "          </span>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("ihestia-menu-template-app/userBox/popover/userBoxAgentPopover.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/popover/userBoxAgentPopover.tpl.html",
    "<div ng-if=\"isInReloggedMode\" class=\"alert alert-info\">\n" +
    "  <i class=\"icon-solo icon-info\"></i>\n" +
    "  <p>{{ 'Menu.reloggedUser' | translate }}</p>\n" +
    "  <p>{{currentUserShortName}}</p>\n" +
    "</div>\n" +
    "<div ng-if=\"elementsAvailability.userBoxStats !== false\" class=\"stats\" ng-include=\"userBoxStatsTpl\"></div>\n" +
    "<div class=\"identity\">\n" +
    "  <div class=\"list-group list-group-dark\">\n" +
    "    <a class=\"no-animate list-group-item\" character=\"character.code\" \n" +
    "      ng-class=\"{ \n" +
    "        'active' : character.active && !character.isBlocked,\n" +
    "        'disabled': character.isBlocked\n" +
    "      }\"\n" +
    "    ng-repeat=\"character in characters\"\n" +
    "    ng-click=\"showCharacterChangeConfirm(character)\">\n" +
    "      <div class=\"row middle-xs\">\n" +
    "        <div class=\"col-xs-size\">\n" +
    "          <i class=\"icon-sl-fill icon-user-1\"></i>\n" +
    "        </div>\n" +
    "        <div class=\"col-xs\">\n" +
    "          {{character.name}}\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </a>\n" +
    "  </div>\n" +
    "  <div class=\"pager\">\n" +
    "    <div class=\"row middle-xs\">\n" +
    "      <div class=\"col-xs-size\">\n" +
    "        <a class=\"link link-darker link-icon link-icon-sm\" ng-click=\" !( currentCharacterPage > 1 ) || prevCharactersPage()\">\n" +
    "          <i class=\"icon-sl-outline icon-arrow-67\"></i>\n" +
    "        </a>\n" +
    "      </div>\n" +
    "      <div class=\"col-xs text-center\">\n" +
    "        {{ 'Public.paginator' | translate: currentCharacterPage:charactersPagesCount }}\n" +
    "      </div>\n" +
    "      <div class=\"col-xs-size\">\n" +
    "        <a class=\"link link-darker link-icon link-icon-sm\" ng-click=\" !( currentCharacterPage < charactersPagesCount ) || nextCharactersPage()\">\n" +
    "          <i class=\"icon-sl-outline icon-arrow-68\"></i>\n" +
    "        </a>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<div class=\"operations\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-xs-6\">\n" +
    "      <button ng-if=\"elementsAvailability.profile !== false\" type=\"button\" editable-label=\"change_password\" class=\"btn btn-default\" id=\"chagePasswordBtn\" ng-click=\"isInReloggedMode ? revertRelogging() : goToProfile()\">{{ (isInReloggedMode ? 'Menu.returnFromRelogBtn' : 'Menu.profileBtn') | translate }}</button>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6 text-right\">\n" +
    "      <button type=\"button\" editable-label=\"menu_logout\" class=\"btn btn-warning\" id=\"logoutBtn\" ng-click=\"logout()\">{{ 'Public.logout' | translate }}</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/popover/userBoxClientPopover.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/popover/userBoxClientPopover.tpl.html",
    "<div ng-if=\"isInReloggedMode\" class=\"alert alert-info\">\n" +
    "  <i class=\"icon-solo icon-info\"></i>\n" +
    "  <p>{{ 'Menu.reloggedUser' | translate }}</p>\n" +
    "  <p>{{currentUserShortName}}</p>\n" +
    "</div>\n" +
    "<div class=\"identity\">\n" +
    "  <div class=\"list-group list-group-white\">\n" +
    "    <a class=\"no-animate list-group-item\"\n" +
    "      ng-click=\"openClientProfilePage('details')\">\n" +
    "      Profil\n" +
    "    </a>\n" +
    "    <a ng-if=\"!isDirectMode && isPropertyClient\" class=\"no-animate list-group-item\"\n" +
    "      ng-click=\"openClientProfilePage('patron')\">\n" +
    "      Opiekun klienta\n" +
    "    </a>\n" +
    "    <a class=\"no-animate list-group-item\"\n" +
    "      ng-click=\"openClientProfilePage('settings')\">\n" +
    "      Ustawienia\n" +
    "    </a>\n" +
    "  </div>\n" +
    "</div>\n" +
    "<div class=\"operations\">\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-xs-6\">\n" +
    "      <button ng-if=\"isInReloggedMode\" type=\"button\" class=\"btn btn-default\" ng-click=\"revertRelogging()\">{{ 'Menu.returnFromRelogBtn' | translate }}</button>\n" +
    "    </div>\n" +
    "    <div class=\"col-xs-6 text-right\">\n" +
    "      <button type=\"button\" class=\"btn btn-warning\" ng-click=\"logout()\">{{ 'Public.logout' | translate }}</button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/popover/userBoxCustomPopover.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/popover/userBoxCustomPopover.tpl.html",
    "<div class=\"panel panel-no-margin\">\n" +
    "  <div class=\"panel-body text-right\">\n" +
    "    <button type=\"button\" class=\"btn btn-warning\" ng-click=\"customLogout()\">Wyloguj</button>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/userBox.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/userBox.tpl.html",
    "<div class=\"user-box pull-right\"\n" +
    "	ng-controller=\"ihestiaMenuUserBoxCtrl\"\n" +
    "	common-click-outside=\"hideUserBoxPopover()\">\n" +
    "\n" +
    "	<div class=\"user-box-handle\" ng-class=\"{'user-relogged': isInReloggedMode}\" ng-click=\"showUserBoxPopover()\" ng-hide=\"appVariables.contentPulledLeft\">\n" +
    "		<div class=\"user-icon pull-left\"><i class=\"icon-sl-outline\" ng-class=\"{'icon-user-1': !isJupiter, 'icon-profile-1': isJupiter}\"></i></div>\n" +
    "		<div class=\"user-data\">\n" +
    "			<div class=\"user-name\">{{ isInReloggedMode ? reloggedUserShortName : currentUserShortName }}</div>\n" +
    "			<div class=\"user-location\">{{userDescription}}</div>\n" +
    "		</div>\n" +
    "	</div>\n" +
    "	<div class=\"user-box-menu\" ng-if=\"userBoxSettings.boxVisible && !customUser && currentUser.userType !== 'Client'\" ng-include=\"'ihestia-menu-template-app/userBox/popover/userBoxAgentPopover.tpl.html'\"></div>\n" +
    "	<div class=\"user-box-menu\" ng-if=\"userBoxSettings.boxVisible && !customUser && currentUser.userType === 'Client'\" ng-include=\"'ihestia-menu-template-app/userBox/popover/userBoxClientPopover.tpl.html'\"></div>\n" +
    "	<div class=\"user-box-menu user-custom\" ng-if=\"userBoxSettings.boxVisible && customUser\" ng-include=\"'ihestia-menu-template-app/userBox/popover/userBoxCustomPopover.tpl.html'\"></div>\n" +
    "\n" +
    "	<common-modal \n" +
    "		ng-if=\"modals.characterChange.settings\"\n" +
    "		modal-code=\"ihestiaCharacterChange\" \n" +
    "		modal-template=\"ihestia-menu-template-app/userBox/userBoxContent.tpl.html\" \n" +
    "		modal-settings=\"modals.characterChange.settings\">\n" +
    "	</common-modal>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/userBoxContent.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/userBoxContent.tpl.html",
    "<div>{{ 'Menu.userboxCharacterChangeQuestion' | translate }} \"{{modals.characterChange.selectedCharacter.name}}\"?</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/userBoxStats.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/userBoxStats.tpl.html",
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ 'Menu.statusDataDaily' | translate }}</small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.daily ? ( statusData.daily | number:2 ) + ' zł' : ' - ' }}\n" +
    "	</div>\n" +
    "</div>\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ ('Menu.statusData' | translate) + ' ' + ( statusData.month ? statusData.month : '' ) }} </small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.monthly ? ( statusData.monthly | number:2 ) + ' zł' : ' - ' }}\n" +
    "	</div>\n" +
    "</div>\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ 'Menu.agentStatus' | translate }}</small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.agency_status ? statusData.agency_status : ' - ' }}\n" +
    "	</div>\n" +
    "</div>\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ 'Menu.power' | translate }}</small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.powers ? statusData.powers : ' - ' }}\n" +
    "	</div>\n" +
    "</div>\n" +
    "<div class=\"row\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ 'Menu.activePoints' | translate }}</small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.points ? ( statusData.points + ' pkt' ) : ' - ' }}\n" +
    "	</div>\n" +
    "</div>\n" +
    "<div class=\"row\" ng-if=\"showInfoMyPoints\">\n" +
    "	<div class=\"col-xs-5\">\n" +
    "		<small>{{ 'Menu.masterLeague' | translate }}</small>\n" +
    "	</div>\n" +
    "	<div class=\"col-xs-7\">\n" +
    "		{{ statusData.champions_league ? ( statusData.champions_league | number ) : ' - ' }}\n" +
    "	</div>\n" +
    "</div>");
}]);

angular.module("ihestia-menu-template-app/userBox/userBoxStatsLoading.tpl.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("ihestia-menu-template-app/userBox/userBoxStatsLoading.tpl.html",
    "<div ng-class=\"userStatsLoadingClass\">\n" +
    "  <div class=\"element2BindSpinner\"></div>\n" +
    "</div>");
}]);
