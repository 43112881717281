angular.module('ihestiaRestServicesBase')

/**
 * usługi przekodownika AZTEC do ETX
 */
.service('its2etxSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {

  var Its2etxSvc = function() {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    var self = this;
    this.resource = 'vehicleDataTranscoder';
    this.businessArea = 'its2etx';
    this.port = '8248';
    this.path = 'RestIts2etx';

    /**
     * mapa poł z usługi do pól z AZTECa
     * @type {Object}
     */
    var svcToAztecMap = {
      'mark': {
        code: 'D_1',
        type: 'array'
      },
      'model': {
        code: 'D_3',
        type: 'array'
      },
      'capacity': {
        code: 'P_1',
        type: 'array'
      },
      'power': {
        code: 'P_2',
        type: 'array'
      },
      'fuel': {
        code: 'P_3',
        type: 'array'
      },
      'seatCount': {
        code: 'S_1',
        type: 'string'
      },
      'productionYear': {
        code: 'RokProdukcji',
        type: 'string'
      },
      'weight': {
        code: 'G',
        type: 'string'
      },
      'totalWeight': {
        code: 'F_2',
        type: 'string'
      },
      'category': {
        code: 'ITS_Rodzaj',
        type: 'array'
      }
    };

    /**
     * wywołane usłgi wyszukującej pojazdu wg ETX na podstawie danych z kodu AZTEC
     * @param  {Object}   aztecData     dane z AZTEC
     * @param  {function} callback      [description]
     * @param  {function}   errorCallback [description]
     * @param  {Object}   [httpParams]    dodatkowe parametry
     * @return {*}
     */
    this.transcodeAztecData = function(aztecData, callback, errorCallback, httpParams) {
      var data = {parameter: {readEquipment: true}};
      angular.forEach(svcToAztecMap, function(aztecField, svcField) {
        if(angular.isDefined(aztecData[aztecField.code])){
          data.parameter[svcField] = aztecField.type === 'array' ? [aztecData[aztecField.code]] : aztecData[aztecField.code];
        } else {
          data.parameter[svcField] = null;
        }
          
      });
      return self.post(null, data, null, callback, errorCallback, httpParams);
    };



  };

  return new Its2etxSvc();
}]);