angular.module('ihestiaCommonDirectives.application')
  .factory('OFFER_CONSTANTS', [function() {
    return {
      GROUP_TYPE_RISK: 'risk',
      GROUP_TYPE_ADD: 'add',
      GROUP_TYPE_EXTENSION: 'extension',      
      OFFER_RENEWAL_TYPE_CENTRAL_STATUTORY: 1,  //odnowienie centralne – ustawowe      
      OFFER_RENEWAL_TYPE_CENTRAL_LAST_YEAR: 2, //'RenewalCentralLastYear' odnowienie centralne – zakres ubiegłoroczny
      OFFER_RENEWAL_TYPE_CENTRAL_EXPANDED: 3,   //'RenewalCentralExpanded' odnowienie centralne – zakres rozszerzony
      OFFER_RENEWAL_TYPE_AGENCY: 4, //'RenewalAgency' wznowienie agencyjne
      OFFER_EXTEND_INSURANCE: 5 //'ExtendInsurance' oferta razszerzenia ochrony
    };
  }]);