angular.module('ihestiaIkomunikator')
  .factory('ihestiaIkomunikatorSvc', ['IHestiaRestServiceLsnDef',
    function(IHestiaRestServiceLsnDef) {


    var IHestiaIkomunikatorSvc = function() {

        IHestiaRestServiceLsnDef.apply(this, arguments);

        var self = this;
        var DICTIONARIES = null;
        this.basePattern = '{host}';
        this.dataType = 'form';

        /**
         * Pobieramy, lub zwracamy z cache słowniki
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.getDictionaries = function(callback) {
          if (DICTIONARIES === null) {
            // load
            self.post(null, {
              type: 'webserviceGetTopicsForAgent'
            }, null, function(res) {
              if (res.data.status === 'ok') {
                DICTIONARIES = res.data.data;
              }
              callback(DICTIONARIES);
            });
          } else {
            callback(DICTIONARIES);
          }
        };

        /**
         * Pobieramy listę wiadomości
         * @param  {object}   data     [description]
         * @param  {Function} callback [description]
         * @return {object}            [description]
         */
        this.getList = function(data, callback) {
          var reqData = {
            data: data,
            type: 'webserviceGetListForComponent'
          };

          return self.post(null, reqData, null, function(res) {
            callback(res);
          });
        };

        /**
         * Zapis danych
         * @param  {[type]}   data     [description]
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.save = function(data, callback) {
          var reqData = {
            data: data,
            type: 'webserviceSaveQuestion'
          };

          return self.post(null, reqData, null, callback);
        };

        /**
         * Ladowanie konkretnego zapytania
         * @param  {int}   pm       [description]
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.load = function(pm, callback){
          var reqData = {
            data: {
              PM: pm
            },
            type: 'webserviceQuestionLoad'
          };

          return self.post(null, reqData, null, callback);
        };

        /**
         * Zapis komentarza
         * @param  {[type]}   data     [description]
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.saveComment = function(data, comment, callback) {
          var reqData = {
            type: 'webserviceCommentSave',
            data: JSON.stringify({
              'ST_TYPE': 'comment',
              'FK_QUESTION': data.pm,
              'ST_TEXT': comment.st_text,
              'QUESTION': data,
              'FILES': data.files
            })
          };

          return self.post(null, reqData, null, callback);
        };


        /**
         * Zamyka zgloszenie
         * @return {Boolean}     czy nieaktualne
         */
        this.closeQuestion = function(question, callback){
          return self.openCloseQuestion(question, 'close', callback);
        };

        /**
         * Zamyka zgloszenie
         * @return {Boolean}     czy nieaktualne
         */
        this.openQuestion = function(question, callback){
          return self.openCloseQuestion(question, 'open', callback);
        };

        /**
         * Zamyka lub otwiera zgloszenie
         * @param  {Object}   question [description]
         * @param  {String}   action   [description]
         * @param  {Function} callback [description]
         * @return 
         */
        this.openCloseQuestion = function(question, action, callback){
          var reqData = {
            type: 'webserviceChangeStatus',
            data: JSON.stringify({
              question: question,
              action: action
            })
          };

          return self.post(null, reqData, null, callback);
        };

        /**
         * Zapisujemy ankietę
         * @return
         */
        this.savePoll = function(question, comment, callback){
          var reqData = {
            type: 'webserviceQuestionForm',
            data: JSON.stringify({
              'RATING': comment.rating,
              'OPINION': comment.opinion,
              'STATUSDATA': {
                action: 'close',
                question: question
              }
            })
          };

          return self.post(null, reqData, null, callback);
        };
      };

      return new IHestiaIkomunikatorSvc();
    }
  ]);