angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorSearchPersonHelper', ['$filter', 'iHestiaCommonModalHelper', '$rootScope', 'clientPersonsSvc', 'ihestiaIkomunikatorCommonHelper', '$timeout', 'ihestiaIkomunikatorLoaderMessagesHelper', 'iKomunikatorConstants', 'ihestiaDictionaryHelper', '$q', 'clientSearchSvc', 'ihestiaIkomunikatorUserTypeHelper',
    function($filter, iHestiaCommonModalHelper, $rootScope, clientPersonsSvc, ihestiaIkomunikatorCommonHelper, $timeout, ihestiaIkomunikatorLoaderMessagesHelper, iKomunikatorConstants, ihestiaDictionaryHelper, $q, clientSearchSvc, ihestiaIkomunikatorUserTypeHelper) {

      var SearchClientHelper = function() {
        var self = this;

        this.defaultData = {
          searchFilters: {
            value: ''
          },
          total: 0,
          loading: false,
          recipients: [],
          searchResultEmpty: false,
          //ustawiana na zmiane atrybutów w momencie wywoływania usługi
          //na calback usługa zostanie zawołana jeszcze jeden raz
          reloadSearchAgain: false,
          scrollVisible: false,
          reverseSort: false,
          promiseFiter: {},
          personTypes: [{
            code: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
            name: $filter('translate')('naturalPerson', {componentCode: 'Public'})
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY,
            name: $filter('translate')('company', {componentCode: 'Public'})
          }],
          subjectType: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
          isCompanySearchAvailable: ihestiaIkomunikatorUserTypeHelper.isCompanySearchAvailable(),
          countries: null,
          organizationData: {
            nip: null,
            name: null,
            regon: null,
            countryCode: 'PL',
            companyId: null
          }
        };

        this.lastFilterText = '';
        this.lastOrganizationData = null;
        this.lastSubjectType = null;
        this.newRecipientNotAvailable = false;
        this.contextForNewMessage = null;

        this.tplData = angular.copy(self.defaultData);

        this.okButtonName = 'Nowy odbiorca';

        $rootScope.ihestiaIkomunikatorSearchPersonModalSettings = {
          title: $filter('translate')('iKomunikator.newMessage'),
          okBtnName: self.okButtonName,
          okBtnDisabled: true,
          cancelBtnName: $filter('translate')('Public.cancel'),
          keyboard: false
        };

        this.showModal = function(data) {
          if (data && data.research) {
            self.tplData.searchFilters.value = self.lastFilterText;
            self.tplData.subjectType = self.lastSubjectType || self.defaultData.subjectType;
            if (self.tplData.subjectType === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
              // assign previous organization search data only for company subject type
              self.tplData.organizationData = self.lastOrganizationData || _.cloneDeep(self.defaultData.organizationData);
            } else {
              self.tplData.organizationData = _.cloneDeep(self.defaultData.organizationData);
            }
            self.reloadRecipients();
          } else {
            self.clearLastSearch();
          }

          if (data && data.contextForNewMessage) {
            self.contextForNewMessage = data.contextForNewMessage;
            if (self.contextForNewMessage.settings && self.contextForNewMessage.settings.otherRecipientInContextClient) {
              self.tplData.otherRecipientInContextClient = true;
            } else {
              self.tplData.otherRecipientInContextClient = false;
            }
          } else {
            self.contextForNewMessage = null;
            self.tplData.otherRecipientInContextClient = false;
          }

          if (data && data.context) {
            if (data.context.newRecipientNotAvailable) {
              //flaga czyszczona gdy nie wracamy na research
              self.newRecipientNotAvailable = data.context.newRecipientNotAvailable;
            }

            if (data.context.newRecipientOkButtonName) {
              self.okButtonName = data.context.newRecipientOkButtonName;
            }
          }

          self.setNewRecipientAvailable();
          if(ihestiaIkomunikatorUserTypeHelper.isCompanySearchAvailable()) {
            _.assign($rootScope.ihestiaIkomunikatorSearchPersonModalSettings, {
              size: 'md',
              headerList: [{
                value: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
                name: $filter('translate')('naturalPerson', {componentCode: 'Public'})
              }, {
                value: iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY,
                name: $filter('translate')('company', {componentCode: 'Public'})
              }],
              headerListModel: 'modalData.subjectType'
            });
          }

          if (data && data.contextForNewMessage) {
            self.tplData.contextForNewMessage = data.contextForNewMessage;
          } else {
            self.tplData.contextForNewMessage = null;
          }

          $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = true;
          iHestiaCommonModalHelper.showModal('ihestiaIkomunikatorSearchPerson');
          return self;
        };

        this.setNewRecipientAvailable = function() {
          if (self.newRecipientNotAvailable) {
            $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnName = false;
          } else {
            $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnName = self.okButtonName;
          }
        };

        this.search = function() {
          if (!self.canSearch()) {
            self.tplData.loading = false;
            return;
          }

          self.getClientSearchRequest()
            .then(function(res) {
              if (res && res.data) {
                angular.forEach(res.data.items || res.data, function(recipient) {
                  self.tplData.recipients.push(recipient);
                });
                self.tplData.searchResultEmpty = false;
              }

              self.tplData.total = res.data.total;

              if (self.tplData.total === 0 || self.tplData.recipients.length === 0) {
                self.tplData.searchResultEmpty = true;
                $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = false;
              }

              $timeout(function() {
                if (!self.tplData.scrollVisible) {
                  $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = false;
                }
              }, 0);

              self.afterSearch();
            }, function() {
              self.afterSearch();
              ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania klientów');
            });

        };

        this.afterSearch = function() {
          if (self.tplData.reloadSearchAgain) {
            self.tplData.reloadSearchAgain = false;
            self.tplData.searchFilters.pageNo = 0;
            self.tplData.recipients = [];
            self.search();
            return;
          }

          self.tplData.loading = false;
        };


        this.reloadRecipients = function() {
          if (!self.tplData.loading) {
            self.tplData.loading = true;
            self.tplData.recipients = [];
            self.search();
          }
        };

        /**
         * Ukrywamy modal nowego zapytania
         * @return {[type]} [description]
         */
        this.hideNewMessageModal = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorSearchPerson');
          return self;
        };

        this.setDisableOnOkButton = function(value) {
          $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = value;
        };

        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };

        this.sortBy = function(columnName) {
          self.tplData.loading = true;
          self.tplData.recipients = [];

          if (self.tplData.searchFilters.sort_by === columnName) {
            self.tplData.reverseSort = !self.tplData.reverseSort;
          } else {
            /*eslint-disable camelcase */
            self.tplData.searchFilters.sort_by = columnName;
            /*eslint-enable camelcase */
          }

          self.search();
        };

        this.clearLastSearch = function() {
          self.lastFilterText = '';
          self.contextForNewMessage = null;
          self.newRecipientNotAvailable = false;
        };

        this.onCountryCodeChange = function() {
          self.tplData.organizationData.nip = null;
          self.tplData.organizationData.regon = null;
          self.tplData.organizationData.name = null;
          self.tplData.organizationData.companyId = null;
          self.tplData.recipients = [];
        };

        this.onSubjectTypeChange = function() {
          if (self.tplData.subjectType === iKomunikatorConstants.CONTACT_ROLE_CLIENT) {
            self.tplData.organizationData = _.cloneDeep(self.defaultData.organizationData);
          } else {
            self.tplData.searchFilters.value = '';
          }
          self.tplData.recipients = [];
        };

        this.loadDicts = function() {
          ihestiaDictionaryHelper.getDictWithPromise('countries', {
            requestType: 'searches'
          }).then(function(countries) {
            self.tplData.countries = countries;
          });
        };

        /**
         *
         * @return {Promise}
         */
        this.getClientSearchRequest = function() {
          var sortBy = self.tplData.activeSorter ? self.tplData.activeSorter : 'rank';
          var sortDirection = self.tplData.reverseSort ? 'DESC' : 'ASC';

          $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = true;

          self.promiseToCancel = $q.defer();
          var httpParams = {
            timeout: self.promiseToCancel.promise.then(lsnNg.noop, lsnNg.noop) //abort na promise
          };
          if (self.tplData.isCompanySearchAvailable && self.tplData.subjectType === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            httpParams.headers = {
              'AP-Request-Behaviour': 'touchpoint=I-KOMUNIKATOR-READONLY;'
            };
            var body = _.omitBy(self.tplData.organizationData, _.isNil);
            return clientSearchSvc.searchOrganization(body, httpParams);
          } else {
            return clientPersonsSvc.find(self.tplData.searchFilters, null, 1000, 0, sortBy, sortDirection);
          }
        };

        this.canSearch = function() {
          if (self.tplData.isCompanySearchAvailable && self.tplData.subjectType === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            return true;
          } else {
            return self.tplData.searchFilters.value.length >= 3;
          }
        };

      };

      return new SearchClientHelper();
    }
  ]);
