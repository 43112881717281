angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorDetailsMessageThreadHelper', ['$rootScope', 'iKomunikatorMessagesThreadSvc', 'IKomunikatorMessageModel', 'IKomunikatorMessageThreadModel',
    'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorNotificationBarHelper', 'ihestiaIkomunikatorUserTypeHelper',
    function($rootScope, iKomunikatorMessagesThreadSvc, IKomunikatorMessageModel, IKomunikatorMessageThreadModel, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorNotificationBarHelper, ihestiaIkomunikatorUserTypeHelper) {
      var DetailsMessageThreadHelper = function() {
        var self = this;

        this.defaultData = {
          thread: null
        };

        this.init = function() {
          self.tplData = angular.copy(self.defaultData);
        };

        this.reload = function(threadId) {
          self.tplData.newMessage = new IKomunikatorMessageModel();
          self.setTreadById(threadId);
        };

        this.setTreadById = function(threadId) {
          $rootScope.$broadcast('showBlockUi');
          iKomunikatorMessagesThreadSvc.findLoggedUserMessageThread(threadId, function(result) {
            $rootScope.$broadcast('hideBlockUi');
            if (angular.isObject(result.data)) {
              self.tplData.thread = new IKomunikatorMessageThreadModel();
              self.tplData.thread.fromRest(result.data);

              self.tplData.additionalHeaderExist = ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged() && self.tplData.thread && self.tplData.thread.getLastMessageChannel() !== 'ALERT' && (self.tplData.thread.getLastMessageChannel() !== 'KOMUNIKAT' || self.tplData.thread.customFields.dashboardTaskId);
            } else {
              ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła wątku wiadomości');
            }

            ihestiaIkomunikatorNotificationBarHelper.reloadNumberMessage();
          }, function(){
            $rootScope.$broadcast('hideBlockUi');
            ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła wątku wiadomości');
          });
        };

        this.setClearData = function() {
          angular.forEach(self.tplData, function(value, key) {
            delete self.tplData[key];
          });

          self.tplData = angular.extend(self.tplData, angular.copy(self.defaultData));
        };

      };

      return new DetailsMessageThreadHelper();
    }
  ]);