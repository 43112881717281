/**
 * Kontroler navbara
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuUserBox')
  .controller('ihestiaMenuUserBoxCtrl', ['$scope', 'iHestiaMenuSettings', 'ihestiaSsoBaseInfoHelper',
      'IhestiaMenuUserBoxAgentCenterSvc', 'IhestiaMenuUserBoxMyPointsSvc', 'iHestiaCommonModalHelper',
      'ihestiaSsoCharactersCurrentSvc', 'ihestiaConfigHelper', '$window', 'ihestiaCrossTabHelper',
      'ihestiaUserReloggedSvc', 'ihestiaCommonConfirmModalHelper', 'ihestiaSsoReloggedUserCharactersCurrentSvc',
      '$filter', 'ihestiaAgHelper', 'ihestiaCommonComponentStatusHelper', '$urlMatcherFactory',
      'UserCharacterTypeConstants', 'iHestiaMenuBaseHelper', 'ihestiaMenuUserBoxHelper', 'lsnCookie',
      'ihestiaPlatformHelper', 'lsnConfirmModalHelper',
    function($scope, iHestiaMenuSettings, ihestiaSsoBaseInfoHelper,
             IhestiaMenuUserBoxAgentCenterSvc, IhestiaMenuUserBoxMyPointsSvc, iHestiaCommonModalHelper,
             ihestiaSsoCharactersCurrentSvc, ihestiaConfigHelper, $window, ihestiaCrossTabHelper,
             ihestiaUserReloggedSvc, ihestiaCommonConfirmModalHelper, ihestiaSsoReloggedUserCharactersCurrentSvc,
             $filter, ihestiaAgHelper, ihestiaCommonComponentStatusHelper, $urlMatcherFactory,
             UserCharacterTypeConstants, iHestiaMenuBaseHelper, ihestiaMenuUserBoxHelper, lsnCookie,
             ihestiaPlatformHelper, lsnConfirmModalHelper) {

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();
      $scope.currentUserCharacterType = $scope.currentUser.getCurrentCharacter().type;
      $scope.characterPageLimit = 5;
      $scope.currentCharacterPage = 1;
      $scope.isInReloggedMode = ihestiaConfigHelper.get('frontFlags', 'SUPPORT_RELOGGED_USER') && ihestiaSsoBaseInfoHelper.isUserRelogged();
      $scope.reloggedUser = ihestiaSsoBaseInfoHelper.getReloggedUser();
      $scope.currentUserShortName = $scope.currentUser.getShortName();
      $scope.reloggedUserShortName = $scope.reloggedUser.getShortName();
      $scope.isDirectMode = iHestiaMenuBaseHelper.isDirect();

      $scope.isPropertyClient = false;

      /**
       * Ustawienia boxu
       * @type {Object}
       */
      $scope.userBoxSettings = ihestiaMenuUserBoxHelper.settings;

      //obiekt który informuje nas czy potrzebujemy jeszcze jakiś wartości
      $scope.waitForGetResponse = {
        MyPoints: false,
        AgentCenter: false
      };
      $scope.statusData = {
        daily: null,
        month: null,
        monthly: null,
        agency_status: null, //eslint-disable-line
        powers: null,
        champions_league: null, //eslint-disable-line
        points: null
      };
      $scope.userBoxStatsTpls = {
        ready: 'ihestia-menu-template-app/userBox/userBoxStats.tpl.html',
        loading: 'ihestia-menu-template-app/userBox/userBoxStatsLoading.tpl.html'
      };
      $scope.userBoxStatsTpl = $scope.userBoxStatsTpls.loading;

      $scope.modals = {
        characterChange: {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'Menu.characterChangeTitle',
            okBtnName: 'Menu.characterChangeOkBtn',
            cancelBtnName: 'Public.cancel',
            size: 'sm'
          },
          selectedCharacter: null
        }
      };

      $scope.actionsLocked = false; //blokowanie akcji jeśli już jedna jest w trakcie

      /**
       * To, co jest wyświetlane pod imieniem i nazwiskiem
       * @type {String}
       */
      $scope.userDescription = '';
      $scope.customUser = false; //czy użytkownik spoza SSO - ocawaryjne

      /**
       * Init
       * @return {[type]} [description]
       */
      $scope.init = function() {
        var characterType = $scope.currentUser.getCurrentCharacter().type;
        if (characterType === 'Worker') {
          $scope.showInfoMyPoints = true;
          $scope.showInfoAgentCenter = true;
        } else if (characterType === 'SalesProfile') {
          $scope.showInfoMyPoints = false;
          $scope.showInfoAgentCenter = true;
        } else {
          $scope.showInfoMyPoints = false;
          $scope.showInfoAgentCenter = false;
        }

        if(characterType === 'Client') { // is client
          if(
            ihestiaConfigHelper.get('client', 'acceptedTermsAndConditions').ehn && // with accessable property data
            ihestiaConfigHelper.get('ic', 'activeContext') !== 'EHL' // not on life context
            ) {
            $scope.isPropertyClient = true;
          }
        }

        $scope.loadCharactersPage();
        $scope.setUserDescription();

        //jeśli wcześniej już mamy dane w helperze to od razu je wrzucamy
        if(iHestiaMenuBaseHelper.customUserData)
        {
          $scope.setCustomUser(iHestiaMenuBaseHelper.customUserData);
        }
        //rejestrujemy funkcję w helperze
        iHestiaMenuBaseHelper.userBoxSetCustomUser = $scope.setCustomUser;
        iHestiaMenuBaseHelper.userBoxBrandChanged = $scope.brandChanged;
      };

      $scope.brandChanged = function()
      {
        $scope.isDirectMode = iHestiaMenuBaseHelper.isDirect();
      };

      $scope.setCustomUser = function(userData)
      {
        $scope.customUser = true;
        angular.forEach(userData, function(value, key){
          $scope[key] = value;
        });
      };

      /**
       * Ustalenie wiersza opisu pod imieniem i nazwiskiem w prawym górnym boksie
       */
      $scope.setUserDescription = function() {
        var user = $scope.isInReloggedMode ? $scope.reloggedUser : $scope.currentUser;
        var character = user.getCurrentCharacter();
        if(character.type === UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT) {
          $scope.userDescription = user.pesel;
        } else {
          var agencyNumbers = [];
          angular.forEach(['AgencyNumberProperty', 'AgencyNumberLife'], function(ossAttribute){
            if(character.ossContextInfo[ossAttribute] && character.name.indexOf(character.ossContextInfo[ossAttribute]) === -1) {
              agencyNumbers.push(character.ossContextInfo[ossAttribute]);
            }
          });
          $scope.userDescription = character.name;
          if(agencyNumbers.length > 0) {
            $scope.userDescription = $scope.userDescription + ', ' + agencyNumbers.join(', ');
          }
        }
      };

      /**
       * Pokazuje dropdown menu z danymi użytkownika
       * @return {[type]} [description]
       */
      $scope.showUserBoxPopover = function() {
        $scope.userBoxSettings.boxVisible = true;
        // IHESTLIFE-1402
        if(ihestiaConfigHelper.get('ic', 'activeContext') === 'EHL') {
          return null;
        }

        if (iHestiaMenuSettings.settings.userInfo && iHestiaMenuSettings.settings.userInfo.stats && $scope.showInfoMyPoints && $scope.showInfoAgentCenter) {
          $scope.showSpinnerOnWaitForStatusData('userInfoStatsLoader6');
          $scope.getStats();
          $scope.getMyPoints();
        } else if (iHestiaMenuSettings.settings.userInfo && iHestiaMenuSettings.settings.userInfo.stats && $scope.showInfoAgentCenter) {
          $scope.showSpinnerOnWaitForStatusData('userInfoStatsLoader5');
          $scope.getStats();
        }
      };

      $scope.toggleUserBoxPopover = function(){
        if($scope.userBoxSettings.boxVisible) {
          $scope.userBoxSettings.boxVisible = false;
        } else {
          $scope.showUserBoxPopover();
        }
      };

      $scope.showSpinnerOnWaitForStatusData = function(className) {
        $scope.userStatsLoadingClass = className;
        $scope.userBoxStatsTpl = $scope.userBoxStatsTpls.loading;
      };

      /**
      * Wywołuje usługę a następnie pokazuje informacje o statusach z iMonitora
      * @return
      */
      $scope.getStats = function() {
        var attrNames = ['daily', 'month', 'monthly', 'agency_status', 'powers', 'champions_league'];



        if(!ihestiaAgHelper.hasPermissionFor('iMonitorAgentWWW', 'FullAccess') || !ihestiaCommonComponentStatusHelper.isComponentEnabled('iMonitorAgentWWW'))
        {
          //brak uprawnień do usług iMonitora lub konponent iMonitora wyłączony, więc nie strzelamy do usługi
          $scope.showStatusList();
          return;
        }

        IhestiaMenuUserBoxAgentCenterSvc.get(
          '',
          {},
          null,
          function(response) {
            angular.forEach(response.data, function(val, attrName) {
              if (attrNames.indexOf(attrName) !== -1) {
                if (typeof val !== 'undefined' && val !== null) {
                  $scope.statusData[attrName] = val;
                } else {
                  $scope.statusData[attrName] = null;
                }
              }
            });
            $scope.showStatusList();
          },
          function(res){
            if (res.status === 403) {
              angular.forEach(attrNames, function(val, attrName) {
                $scope.statusData[attrName] = null;
              });
              $scope.showStatusList();
            }
            if (res.status === 404) {
              angular.forEach(attrNames, function(val, attrName) {
                $scope.statusData[attrName] = null;
              });
              $scope.showStatusList();
            }
            if (res.status === 503) {
              angular.forEach(attrNames, function(val, attrName) {
                $scope.statusData[attrName] = null;
              });
              $scope.showStatusList();
            }
          },
          {allowedStatuses: [403, 404, 503]}
        );
      };

      /**
      * Pokazuje informacje o statusach z iMonitora
      * @return
      */
      $scope.showStatusList = function() {
        if ($scope.userBoxStatsTpl === $scope.userBoxStatsTpls.loading) {
          $scope.userBoxStatsTpl = $scope.userBoxStatsTpls.ready;
        }
      };

      /**
       * Statystyki z mypoints
       * @return
       */
      $scope.getMyPoints = function() {

        IhestiaMenuUserBoxMyPointsSvc.get(
          '',
          {},
          null,
          function(response) {
            if (response !== null && response.data && angular.isArray(response.data.attributes)) {
              angular.forEach(response.data.attributes, function(elem) {
                if (elem.code === 'Points') {
                  var points = elem.value;
                  if (typeof points !== 'undefined' && points !== null) {
                    $scope.statusData.points = points;
                  } else {
                    $scope.statusData.points = null;
                  }
                }
              });
            }
            $scope.showStatusList();
          },
          function(res){
            if (res.status === 403) {
              $scope.statusData.points = null;
              $scope.showStatusList();
            }
            if (res.status === 404) {
              $scope.statusData.points = null;
              $scope.showStatusList();
            }
          },
          {allowedStatuses: [403, 404, 503]}
          );
      };

      /**
       * Ukrywa dropdown menu z danymi użytkownika
       * @return {[type]} [description]
       */
      $scope.hideUserBoxPopover = function() {
        ihestiaMenuUserBoxHelper.hideUserBox();
      };

      /**
       * Zwracamy tozsamosci dla aktualnej strony
       * @return {Character[]}      [description]
       */
      $scope.loadCharactersPage = function() {
        var user = $scope.isInReloggedMode ? $scope.reloggedUser : $scope.currentUser,
          characters = user.getCharacters(),
          offset = ($scope.currentCharacterPage - 1) * $scope.characterPageLimit;
        $scope.characters = characters.slice(offset, offset + $scope.characterPageLimit);
        $scope.charactersPagesCount = Math.ceil(user.getCharactersCount() / $scope.characterPageLimit);
      };

      /**
       * Ustawiamy aktywna strone tozsamosci na nastepna
       * @return {[type]} [description]
       */
      $scope.nextCharactersPage = function() {
        $scope.currentCharacterPage = $scope.currentCharacterPage + 1;
        $scope.loadCharactersPage();
      };

      /**
       * Ustawiamy aktywna strone tozsamosci na poprzednia
       * @return {[type]} [description]
       */
      $scope.prevCharactersPage = function() {
        $scope.currentCharacterPage = $scope.currentCharacterPage - 1;
        $scope.loadCharactersPage();
      };

      /**
       * Wróć z przelogowania
       */
      $scope.revertRelogging = function() {
        ihestiaCommonConfirmModalHelper.showConfirmModal({
          title: $filter('translate')('Menu.relogReturnTitle'),
          text: $filter('translate')('Menu.returnFromRelogQuestion'),
          okBtnName: $filter('translate')('Menu.returnFromRelogOkBtn'),
          cancelBtnName: $filter('translate')('Public.cancel'),
          keyboard: true,
          showOkSpinner: true,
          okBtnCallback: function() {
            ihestiaCommonConfirmModalHelper.showOkSpinner();
            ihestiaUserReloggedSvc.revertRelogging().then(function() {
              ihestiaCrossTabHelper.sendMessage({
                code: 'userDereloged'
              });
              $window.location.reload();
            }, angular.noop);
          }
        });
      };

      /**
       * modal zmiany tożsamości
       * @param  {IHestiaCharacterModel} character
       */
      $scope.showCharacterChangeConfirm = function(character) {
        if ($scope.actionsLocked || character.isBlocked) {
          return false;
        }
        $scope.modals.characterChange.selectedCharacter = character;
        iHestiaCommonModalHelper.showModal('ihestiaCharacterChange');
        return true;
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaCharacterChange', function( $event ) {
        $event.defaultPrevented = true;
        $scope.changeCharacter($scope.modals.characterChange.selectedCharacter);
      });

      /**
       * Przejdź do profilu
       * @return {undefined}
       */
      $scope.goToProfile = function()
      {
        ihestiaMenuUserBoxHelper.goToProfile();
      };

      /**
       * Otwieramy stronę profilu w ikoncie
       * aktywne tylko dla klientów
       * @param  {string} tab (details|patron|setings)
       * @return {undefined}
       */
      $scope.openClientProfilePage = function(tab) {
        var $urlMatcher = $urlMatcherFactory.compile(tab);
        if($scope.isDirectMode){
          $window.location = ihestiaConfigHelper.getUrl('IKONTO_DIRECT_URL', 'external/profile/') + $urlMatcher.format({searchPhrase: $scope.searchPhrase});
        } else if (ihestiaConfigHelper.get('ic', 'activeContext') === 'EHL') {
          $window.location = ihestiaConfigHelper.getUrl('IKONTO_ZYCIE_URL', 'external/profile/') + $urlMatcher.format({searchPhrase: $scope.searchPhrase});
        } else {
          $window.location = ihestiaConfigHelper.getUrl('IKONTO_URL', 'external/profile/') + $urlMatcher.format({searchPhrase: $scope.searchPhrase});
        }
      };

      $scope.showPasswordChangeModal = function() {
        if ($scope.actionsLocked) {
          return false;
        }
        iHestiaCommonModalHelper.showModal('ihestiaPasswordChange');
        return true;
      };

      $scope.changeCharacter = function(character) //todo jak by miało być wywoływane
      {
        $scope.actionsLocked = true;
        ihestiaCrossTabHelper.sendMessage({
          code: 'characterChanged',
          attrs: {
            characterCode: character.code
          }
        });
        var changeSvc = $scope.isInReloggedMode ? ihestiaSsoReloggedUserCharactersCurrentSvc : ihestiaSsoCharactersCurrentSvc;
        changeSvc.changeCharacter(character, function() {
          $scope.hideUserBoxPopover( );
          $scope.actionsLocked = false;
          ihestiaMenuUserBoxHelper.redirectAfterCharacterChange(character);
        }).then(lsnNg.noop, function(res){
          var messages = [];
          if(res && res.data && angular.isArray(res.data.messages)) {
            angular.forEach(res.data.messages, function(message) {
              if(message.text) {
                messages.push(message.text);
              }
            });
          }
          if(messages.length === 0) {
            messages.push($filter('translate')('unknownServiceErrorHasOccured', {componentCode: 'Public'}));
          }

          $scope.hideUserBoxPopover();
          iHestiaCommonModalHelper.hideModal('ihestiaCharacterChange');
          $scope.actionsLocked = false;

          lsnConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('Public.error', {componentCode: 'Public'}),
            content: messages.join('\n'),
            cancelBtnName: null,
            okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'})
          }).then(lsnNg.noop, lsnNg.noop);
        });
      };

      $scope.logout = function() {
        $scope.changeBrandCookie();
        ihestiaCrossTabHelper.sendMessage({
          code: 'sessionExpired'
        });
        $window.location = ihestiaConfigHelper.get('sso', 'LOGOUT_URL');
      };

      /**
       * wylogowanie bez SSO - obsłużone już w samej apce
       */
      $scope.customLogout = function()
      {
        iHestiaMenuBaseHelper.customLogout();
      };

      $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaCharacterChange', function() {
        $scope.modals.characterChange.selectedCharacter = null;
      });


      // Ustawiamy ciasteczko z informacją o aktualnym brandzie
      // metoda wykorzystywana w sytuacji przełączania się pomiędzy brandami iHestia/Direct/Jupiter
      // na ciasteczko zapina się między innymi ekran wylogowania z aplikacji - pokazywane są komunikaty zgodne z brandem
      $scope.changeBrandCookie = function() {
        var cookieData = 'iHestia'; //domyślna wartość ciasteczka
        if(ihestiaPlatformHelper.isDirect()) {
          cookieData = 'Direct';
        }
        if(ihestiaPlatformHelper.isJupiter()) {
          cookieData = 'Jupiter';
        }

        var cookiesSettings = {
          'domain': ihestiaConfigHelper.get('crossTab').ROOT_DOMAIN
        };
        var cookieName = ihestiaConfigHelper.get('cookieNames', 'STARTED_BRAND');

        lsnCookie.set(cookieName, cookieData, cookiesSettings);
      };


      $scope.init();
    }
  ]);
