angular.module('ihestiaRestServicesBase')

.factory('IHestiaRestServiceInterceptor', ['$rootScope', '$q', 'ihestiaConfigHelper', 'simpleOAuthDataHelper',
  function($rootScope, $q, ihestiaConfigHelper, simpleOAuthDataHelper) {

  var errorModel = {},
   sessionNotExpired = true;
  var RestServiceInterceptor = {

    request: function(config) {
      if(ihestiaConfigHelper.get('rest', 'AUTH_METHOD') === 'oauth')
      {
        config.headers.Authorization = 'Bearer ' + simpleOAuthDataHelper.getAccessToken() //eslint-disable-line
        if(ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') && ihestiaConfigHelper.get('sso', 'userInfo') && ihestiaConfigHelper.get('sso', 'userInfo').login && ihestiaConfigHelper.get('rest', 'DEMO_LOGIN') === ihestiaConfigHelper.get('sso', 'userInfo').login)
        {
          config.headers['AP-Demo-User'] = 'true';
        }
      }
      return config;
    },

    response: function(response) {
      if (ihestiaConfigHelper.get('rest', 'AUTH_METHOD') === 'oauth' &&
       response &&
        response.status === 401 &&
         (!angular.isArray(response.config.allowedStatuses) || response.config.allowedStatuses.indexOf(401) === -1)
        ) {
        var err = {message: '401'};
        throw err;
      }
      return response;
    },

    responseError: function(rejection) {
      if(rejection.status === 401 && (!angular.isArray(rejection.config.allowedStatuses) || rejection.config.allowedStatuses.indexOf(401) === -1)) {
        sessionNotExpired = false;
      } else if(sessionNotExpired){
        if ((rejection.status === 0 || rejection.status === -1) && angular.isObject(rejection.config.timeout)) //przerwanie żadania z naszej strony
        {
          return $q.reject(rejection);
        }

        if (rejection.status === 409) {
          //konflikt tożsamości, przepuszczamy, inny interceptor obsłuży
          return $q.reject(rejection);
        }
        //allowedStatuses
        if (typeof rejection.config !== 'undefined' && typeof rejection.config.allowedStatuses !== 'undefined' && rejection.config.allowedStatuses.indexOf(rejection.status) !== -1) {
          //spodziewany status przekazany w allowedStatuses, przykładowo 404 dla pustej listy
          return $q.reject(rejection);
        }

        //jeśli nie ma configa to znaczy, że wysłanie requestu albo jego parsowanie się nie powiodło i w rejection mamy obiekt exceptiona
        if (typeof rejection.config !== 'undefined') {

          var emitInterceptorEvent = true;
          errorModel.status = rejection.status;
          errorModel.statusText = rejection.statusText;
          errorModel.url = rejection.config.url;
          errorModel.method = rejection.config.method;
          errorModel.details = rejection.data;

          if(rejection.config.headers && rejection.config.headers['AP-Unique-Request-Id'])
          {
            errorModel.guid = rejection.config.headers['AP-Unique-Request-Id'];
          }

          if(rejection.headers('AP-Infrastructure-Msg'))
          {
            errorModel.infrastructureMsg = rejection.headers('AP-Infrastructure-Msg');
          }


          // dodatkowe dane z transormatora
          if (angular.isObject(rejection.data) && rejection.data !== null && angular.isObject(rejection.data.ihestiaInterceptorSettings)) {
            var settings = rejection.data.ihestiaInterceptorSettings;
            if (settings.ignoreStatusErrors) {
              emitInterceptorEvent = false;
            }
          }

          if (emitInterceptorEvent) {
            $rootScope.$broadcast('restServiceErrorInterceptorEvent', errorModel);
          }
        }
        else if(rejection.message && rejection.stack) {
          var errorMessage = rejection.message;
          if(errorMessage.length > 1000) {
            errorMessage = errorMessage.substr(0, 1000);
          }
          errorModel.forceMessage = errorMessage;
          errorModel.details = rejection.stack;
          $rootScope.$broadcast('restServiceErrorInterceptorEvent', errorModel);
        }
      }
      return $q.reject(rejection);
    }
  };

  return RestServiceInterceptor;

}]);