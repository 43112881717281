angular.module('ihestiaRestServicesBase')
   .factory('iodsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var IodsSvc = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);
      this.resource = 'distributorPropertyFileTokens';
      this.businessArea = 'iods';
    };

    return new IodsSvc();
  }]);