/**
 * Kontroler chatbot
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
  angular.module('ihestiaMenuBase')
  .controller('chatBotCtrl', ['$scope', 'chatBotHelper', '$sce', 'ihestiaConfigHelper', '$timeout',
    function($scope, chatBotHelper, $sce, ihestiaConfigHelper, $timeout) {

      $scope.audioFilePath = $sce.trustAsResourceUrl(ihestiaConfigHelper.get('external', 'STATIC_URL') + 'ih/ihsgLsnVendor/assets/audio/chatBotNotification.mp3');

      $scope.statusData = {
        anyUnreadMessages: false
      };

      $scope.anyUnreadMessagesAnimation = false;
      $scope.anyUnreadMessagesAnimationTimeout = null;

      $scope.init = function() {
        chatBotHelper.registerAudioCallback($scope.playAudio);
        chatBotHelper.registerStatusCallback($scope.setStatusData);
      };

      // toggle chatbot visibility
      $scope.toggleChatBotVisibility = function() {
        chatBotHelper.toggleVisibility();
      };

      /**
       * Zmiana 'statusu' chatu
       * @param {string} attributeName string
       * @param {any} value         any
       */
      $scope.setStatusData = function(attributeName, value) {
        $scope.statusData[attributeName] = value;
        if(attributeName === 'anyUnreadMessages' && value) {
          $scope.runAnyUnreadMessagesAnimation();

        }
      };

      $scope.runAnyUnreadMessagesAnimation = function(){
        if($scope.anyUnreadMessagesAnimationTimeout) {
          $timeout.cancel($scope.anyUnreadMessagesAnimationTimeout);
        }
        $scope.anyUnreadMessagesAnimation = true;
        $scope.anyUnreadMessagesAnimationTimeout = $timeout(function(){
          $scope.anyUnreadMessagesAnimation = false;
        }, 2000);
      };

      $scope.playAudio = function() {
        var audio = document.getElementById('chatBotNotification');
        if (audio.paused) {
          audio.play();
        } else {
          // audio się jeszcze nie skończyło, więc przestawiamy je na początek
          audio.currentTime = 0;
        }
      };

      $scope.init();

    }
  ]);
