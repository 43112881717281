angular.module('salesPath2')
  .controller('clientAddCtrl', ['$scope', 'clientAddHelper', 'navigationHelper', 'PersonModel', 'AddressModel', 'personHelper', 'CONFIG', 'CONSTANTS', '$interval', 'applicationHelper', 'applicationDictionaryHelper', 'personServiceHelper', 'dictionaryServiceHelper', 'dataContainerHelper', 'personModalHelper', 'actionHelper', '$filter',
    function($scope, clientAddHelper, navigationHelper, PersonModel, AddressModel, personHelper, CONFIG, CONSTANTS, $interval, applicationHelper, applicationDictionaryHelper, personServiceHelper, dictionaryServiceHelper, dataContainerHelper, personModalHelper, actionHelper, $filter) {
      var self = this;

      //narazie blokujemy walidacje kodow pkd
      $scope.validPkdCode = false;
      $scope.peselFieldDisabled = false;
      $scope.tplData = clientAddHelper.tplData;
      $scope.showElements = {
        PKD: false,
        Regon: false,
        earnixData: !$scope.CONFIG.BEHAVIOR.insurerSameAsMainInsured && $scope.CONFIG.BEHAVIOR.allowMainInsuredChange
      };
      //dane do autocompletera pkd
      $scope.pkdAutocompleterData = {
        savedPkds: null,
        helper: applicationDictionaryHelper,
        pkdDataArray: applicationDictionaryHelper.loadedPkd
      };
      $scope.services = {
        firstNamesSvc: personServiceHelper.getFirstNameService(),
        lastNamesSvc: personServiceHelper.getLastNameService(),
        addressSvc: dictionaryServiceHelper.getDictionaryAddressService()
      };
      $scope.roles = {
        insured: false,
        insurer: false
      };


      /**
       * reakcja na akcje w aplikacji
       * @param  {String} actionName nazwa wykonanej akcji
       */
      this._afterAction = function(actionName) {
        switch (actionName) {
          case 'personEdited':
          case 'personAdded':
            if (angular.isObject(arguments[1]) && arguments[1].context === $scope.CONSTANTS.PERSON_CONTEXT_CLIENT) {
              navigationHelper.go($scope.appVariables.mainState);
            }
            break;
          default:
        }
      };


      $scope.init = function() {
        actionHelper.registerHelper('clientAddCtrl', self, [
          'personEdited', 'personAdded'
        ]);

        if ($scope.tplData.personData === null) {
          $scope.tplData.personData = (new PersonModel()).getData(true);
          $scope.tplData.personData.addresses = [(new AddressModel()).getData()];
        } else if (!angular.isArray($scope.tplData.personData.addresses) || $scope.tplData.personData.addresses.length === 0) {
          $scope.tplData.personData.addresses = [(new AddressModel()).getData()];
        }

        $scope.peselFieldDisabled = dataContainerHelper.isNbkPerson(null, $scope.tplData.personData);

        if (CONFIG.BEHAVIOR.addClientPKD) {
          $scope.showElements.PKD = true;
          if (!angular.isArray($scope.tplData.personData.pkd)) {
            $scope.tplData.personData.pkd = [];
          }
          $scope.pkdAutocompleterData.savedPkds = $scope.tplData.personData.pkd;
        }

        if (CONFIG.BEHAVIOR.mainInsuredAdditionalFields.indexOf('REGON') !== -1) {
          $scope.showElements.regon = true;
        }

        $scope.numericSettings = {
          decimal: '',
          separator: '',
          negative: false,
          absolute: true,
          allowBeginZero: true
        };

        // IHESTIASS-377 - for sales paths where insurer and insured cannot be divided, we set roles manually
        if (!$scope.showElements.earnixData) {
          $scope.roles.insurer = $scope.roles.insured = true;
        }

        // let's unregister this controller form actionHelper
        $scope.$on('$destroy', function() {
          actionHelper.unregisterHelper('clientAddCtrl');
        });
      };

      $scope.goToClientSearch = function() {
        navigationHelper.go(CONSTANTS.PAGE_START_CLIENT_SEARCH);
      };

      $scope.savePerson = function() {
        if ($scope.isFormValid()) {
          if (angular.isObject($scope.tplData.personData._additionalData)) {
            $scope.tplData.personData.additionalData = $scope.tplData.personData._additionalData;
          }
          var newClientId = personHelper.saveInsurer($scope.tplData.personData, $scope.roles.insured);
          if ($scope.showElements.earnixData && ($scope.roles.insured ^ $scope.roles.insurer)) {
            $scope._showAddPersonModal(newClientId);
            // dataContainerHelper.setDeclaredClientRoles($scope._getRoles());
          } else {
            navigationHelper.go($scope.appVariables.mainState);
            applicationHelper.saveApplication();
          }
        }
      };

      /**
       * czy formularz poprawny
       * UWAGA - sprawdzanie tylko pod kątem errorów (warningi są pomijane)
       * @return {Boolean}
       */
      $scope.isFormValid = function() {
        var isValid = true;
        angular.forEach($scope.newClient.$error, function(fields) {
          angular.forEach(fields, function(field) {
            if (personHelper.warningEditFields.indexOf(field.$name) === -1) {
              isValid = false;
            }
          });
        });
        return isValid;
      };

      $scope._showAddPersonModal = function(clientId) {
        personModalHelper.openPopupAddPerson({
          excludeIds: [clientId],
          context: $scope.CONSTANTS.PERSON_CONTEXT_CLIENT,
          personChange: true,
          sourcePersonId: clientId,
          personChangeRole: $scope.roles.insurer ? CONSTANTS.PERSON_ROLE_INSURED : CONSTANTS.PERSON_ROLE_INSURER,
          title: $filter('translate')($scope.roles.insurer ? 'sp2.clientAdd.addInsured' : 'sp2.clientAdd.addInsurer', {componentCode: 'sp2'})
        });
      };

      /**
       * declared roles
       * @return {string[]} CONSTANTS.PERSON_ROLE_...
       * @private
       */
      $scope._getRoles = function() {
        var roles = [];
        if ($scope.roles.insurer) {
          roles.push(CONSTANTS.PERSON_ROLE_INSURER);
        }
        if ($scope.roles.insured) {
          roles.push(CONSTANTS.PERSON_ROLE_INSURED);
        }
        return roles;
      };

      $scope.init();
    }
  ]);
