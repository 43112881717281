angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaChildHeaderCtrl', ['$scope',
    function($scope){ 
    
      $scope.handleEditChild = function (child) {
        if ($scope.onEditChild) {
          $scope.onEditChild({
            subject: child
          });
        }
      };
    }]);
