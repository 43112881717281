angular.module('ihestiaWidgets.policyOperations')
  .controller('clientDataCtrl', ['$scope', '$timeout', 'AddressModelConstants', 'ihestiaRestDictionaryFirstNamesSvc', 'clientPersonsSvc', 'clientPersonsNbkSvc', 'ContactModelConstants', '$filter', 'labelActionHelper',
    function($scope, $timeout, AddressModelConstants, ihestiaRestDictionaryFirstNamesSvc, clientPersonsSvc, clientPersonsNbkSvc, ContactModelConstants, $filter, labelActionHelper) {

      $scope.services = {
        firstNamesSvc: ihestiaRestDictionaryFirstNamesSvc
      };

      $scope.showElements = {
        Nip: false,
        Regon: false,
        foreigner: false,
        clauses: false
      };

      $scope.operationsNumericSettings = {
        number: {
          decimal: '',
          separator: '',
          negative: false,
          absolute: true,
          allowBeginZero: true
        }
      };

      $scope.contacts = {};

      $scope.clauses = {};

      $scope.foreignerDocument = {};

      $scope.clientDataForm = {};

      $scope.lastSearchString = null;

      var addressAttributes = ['postalCode', 'city', 'streetPrefix', 'street', 'house', 'apartment'];

      $scope.prepareClientTypeDictionary = function()
      {
        $scope.clientTypeDictionary = [
        {
          name: $filter('translate')('naturalPerson', {componentCode: 'Public'}),
          code: 'person'
        },
        {
          name: $filter('translate')('legalPerson', {componentCode: 'Public'}),
          code: 'organization'
        }
        ];
      };

      $scope.secondAddressPossible = function()
      {
        return ($scope.personData.clientType === 'person' &&
          ($scope.currentUser.character.type === 'Central' || $scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_PURCHASE || ($scope.verificationData.operationType === $scope.VerificationDataModelConstants.VEHICLE_DONATION && !$scope.hasUserContext)) &&
           !$scope.hideSecondAddress) ? true : false;
      };

      /**
       * Zmiana imienia, nazwiska lub peselu i blur
       */
      $scope.baseDataChanged = function()
      {
        //nie wyszukujemy dla klienta
        if($scope.currentUser && $scope.currentUser.character.type !== 'Client' && $scope.personData.firstName && $scope.personData.lastName && $scope.personData.pesel && $scope.personData.pesel.length === 11)
        {
          var searchString = $scope.personData.firstName + ' ' + $scope.personData.lastName + ' ' + $scope.personData.pesel;
          if($scope.lastSearchString !== searchString)
          {
            $scope.lastSearchString = searchString;
            $scope.searchPerson(searchString);
          }
        }
      };

      /**
       * szukamy osoby w kartotece
       * @param  {[type]} searchString [description]
       */
      $scope.searchPerson = function(searchString)
      {
        clientPersonsSvc.find(searchString).then(function(res) {
          if($scope.lastSearchString === searchString && res.data && res.data.items.length === 1)
          {
            $scope.fillPersonData(res.data.items[0]);
          }
        }, angular.noop);
      };

      /**
       * wypełniamy dane osoby danymi z usługi
       * @param  {object} personItem
       */
      $scope.fillPersonData = function(personItem)
      {
        //pobieramy resztę danych
        $scope.loadPersonNbk(personItem);
      };

      /**
       * pobranie pełnych danych, interesują nas telefon i mail
       * @param  {object} personItem
       */
      $scope.loadPersonNbk = function(personItem)
      {
        clientPersonsNbkSvc.post(personItem.metaData.id, personItem, null, function(res){
          if(res.data && res.data.metaData && res.data.metaData.id)
          {
            $scope.fillNbkPersonData(res.data);
          }
        });
      };

      /**
       * wypełniamy dane osoby danymi z usługi NBK
       * @param  {object} nbkPersonData
       */
      $scope.fillNbkPersonData = function(nbkPersonData)
      {
        //adresy
        angular.forEach(nbkPersonData.addresses, function(address){
          if($scope.secondAddressPossible() && address.code === AddressModelConstants.ADDRESS_TYPE_KORE && $scope.personData.addresses[1] && $scope.isEmptyAddress($scope.personData.addresses[1]))
          {
            $scope.personData.noSecondAddr = false;
            $scope.copyAddress(address, $scope.personData.addresses[1]);
          }
          else if(address.code === AddressModelConstants.ADDRESS_TYPE_STAL && $scope.isEmptyAddress($scope.personData.addresses[0]))
          {
            $scope.copyAddress(address, $scope.personData.addresses[0]);
          }
        });

        //kontakty
        angular.forEach(nbkPersonData.contacts, function(contact){
          if(contact.code === ContactModelConstants.CONTACT_TYPE_KOMO && $scope.personData.contacts[1] && !$scope.personData.contacts[1].value)
          {
            $scope.personData.contacts[1].value = contact.value;
            if(contact.countryPrefix)
            {
              $scope.personData.contacts[1].countryPrefix = contact.countryPrefix;
            }
          }
          else if(contact.code === ContactModelConstants.CONTACT_TYPE_MAIL && !$scope.personData.contacts[0].value)
          {
            $scope.personData.contacts[0].value = contact.value;
          }
        });


      };

      $scope.copyAddress = function(sourceAddress, destAddress)
      {
        angular.forEach(addressAttributes, function(addressAttribute){
          if(sourceAddress[addressAttribute])
          {
            destAddress[addressAttribute] = sourceAddress[addressAttribute];
          }
        });
      };

      $scope.isEmptyAddress = function(address)
      {
        var isAddressEmpty = true;
        angular.forEach(addressAttributes, function(addressAttribute){
          if(address[addressAttribute])
          {
            isAddressEmpty = false;
          }
        });
        return isAddressEmpty;
      };

      $scope.onPeselChange = function() {
        if (!!$scope.personData.pesel && $scope.personData.pesel.isValidPESEL()) {
          $scope.personData.birthDate = $scope.personData.pesel.peselToBirthdate();
          $scope.personData.gender = $scope.personData.pesel.peselToSex();
        }
        $scope.baseDataChanged();
      };

      $scope.init = function()
      {
        $scope.prepareClientTypeDictionary();
        labelActionHelper.registerAction('vehicleOwnerChangeClient', $scope.prepareClientTypeDictionary);
        $timeout(function() {
          $scope.shared.forms.push($scope.clientDataForm);
        });
        $scope.personData.noSecondAddr = true;
        angular.forEach($scope.personData.addresses, function(address){
          if(address.code === AddressModelConstants.ADDRESS_TYPE_KORE &&
            (address.city !== null || address.house !== null || address.postalCode !== null || address.street !== null || address.streetPrefix !== null))
          {
            $scope.personData.noSecondAddr = false;
          }
        });
      };


    }]
);