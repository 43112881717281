angular.module('ihestiaWidgets.updateContactData', [
  'ihestia-widgets-templates-app',
  'lsnBase.modal',
  'ihestiaWidgets.life'
])
  .run(['myCustomerContactDataHelper',
    function(myCustomerContactDataHelper) {
      // pobranie danych kontaktowych użytkownika i informacji czy należy je zaktualizowć      
      myCustomerContactDataHelper.checkContactData();
    }
  ]);
