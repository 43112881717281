angular.module('lsnBase.breadCrumbs')
  .directive('breadCrumbs', ['breadCrumbsHelper', 'breadCrumbsConfig',
    function(breadCrumbsHelper, breadCrumbsConfig){
      return {
        restrict: 'E',
        replace: true,
        controller: 'breadCrumbsCtrl',
        scope: true,
        templateUrl: breadCrumbsConfig.directiveTpl,
        link: function($scope, $element, $attrs){
          if($attrs.stateParents)
          {
            breadCrumbsHelper.setCustomStateParents($attrs.stateParents);
          }
          if($attrs.hideIhestiaLink)
          {
            breadCrumbsHelper.setMainLinkVisibility(false);
          }

          $attrs.$observe('stateParents', function(stateParents){
            breadCrumbsHelper.setCustomStateParents(stateParents);
          });

        }
      };
    }]);