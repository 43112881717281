angular.module('uiRouterFreeze')
  .controller('uiRouterFreezeCtrl', ['$scope', '$window',
    function($scope, $window) {

      /**
       * Przeładowanie strony
       * @return {undefined}
       */
      $scope.refreshPage = function() {
        $window.location.reload();
      };
    }]);