angular.module('ihestiaRestServicesBase')

/**
   * Ustawienia
   * @return {[type]} [description]
   */
  .factory('ihestiaRestMockHelper',['ihestiaConfigHelper', '$injector', 'ihestiaCommonErrorHandler',
    function(ihestiaConfigHelper, $injector, ihestiaCommonErrorHandler){

    var RestMockHelper = function() {
      var self = this;

      this.servicesList = null;
      this.parsedModules = {};

      /**
       * zwraca typ mocka (none/service/lsn)
       * @param  {[type]} serviceObject [description]
       * @return {[type]}               [description]
       */
      this.getServiceMockMode = function(serviceObject)
      {
        var mockConfig = ihestiaConfigHelper.get('rest','MOCK');

        //sprawdzamy czy jest choć jeden mock specyficzny dla usługi
        var anyServiceMock = false;
        if(typeof mockConfig.SERVICES !== 'undefined')
        {
          angular.forEach(mockConfig.SERVICES, function(){
            anyServiceMock = true;
            return false;
          });
        }

        if(anyServiceMock)
        {
          if(self.servicesList === null)
          {
            self._fillServicesList();
          }

          //porównujemy obiekty żeby znaleźć nazwę obiektu serwisu
          var serviceMatch = null;
          angular.forEach(self.servicesList, function(serviceName){ //eslint-disable-line consistent-return
            if(serviceMatch !== null)
            {
              return false;
            }
            if(self.matchServiceObject(serviceObject, serviceName))
            {
              serviceMatch = serviceName;
            }
          });

          if(serviceMatch !== null && typeof mockConfig.SERVICES[serviceMatch] !== 'undefined')
          {
            return mockConfig.SERVICES[serviceMatch];
          }
        }

        return mockConfig.DEFAULT;
      };

      /**
       * czy istnieje/jest zaladowany okreslony serwis
       * @param  {Object} serviceObject obiekt serwisu, dla którego szukamy mocka
       * @param  {String} serviceName nazwa serwisu z konfiguracji mocka
       * @return {Boolean}
       */
      this.matchServiceObject = function(serviceObject, serviceName) {
        var injObj = $injector.get(serviceName);
        if (!angular.isObject(injObj)) {
          return false;
        } else if (serviceObject.apiVersion !== 'v1' && angular.isFunction(injObj.ver)) {
          return serviceObject === injObj.ver(serviceObject.apiVersion);
        }
        return serviceObject === injObj;
      };

      /**
       * zbieramy listę nazw serwisów
       */
      this._fillServicesList = function()
      {
        self.servicesList = {};
        var appModuleName = ihestiaConfigHelper.get('appModuleName');
        if(appModuleName === null)
        {
          ihestiaCommonErrorHandler.throwException('Brak appModuleName w konfiguracji');
        }
        else
        {
          self._parseModule(ihestiaConfigHelper.get('appModuleName'));
        }
      };

      /**
       * parsuje moduł angularowy pod kątem podpięcia serwisów, następnie parsuje wszystkie podczepione moduły
       * @param  {[type]} moduleName [description]
       * @return {[type]}            [description]
       */
      this._parseModule = function(moduleName) //eslint-disable-line consistent-return
      {
        if(typeof self.parsedModules[moduleName] !== 'undefined')
        {
          //moduł już został sparsowany, więc przerywamy
          return false;
        }
        self.parsedModules[moduleName] = moduleName;
        var moduleObject = angular.module(moduleName);
        angular.forEach(moduleObject._invokeQueue, function(invokeQueue){
          self._tryToAddSvc(invokeQueue[2][0]); //komponent zarejestrowany w module
          angular.forEach(invokeQueue[2][1], function(component){ //elementy wstrzykiwane do komponentu
            self._tryToAddSvc(component);
          });
        });

        angular.forEach(moduleObject.requires, function(submoduleName){
          self._parseModule(submoduleName);
        });
      };

      /**
       * Sprawdza czy to nazwa serwisu, dodaje jeśli tak
       * @param  {[type]} component [description]
       * @return {[type]}           [description]
       */
      this._tryToAddSvc = function(component)
      {
        if(typeof component === 'string' && component.substr(-3) === 'Svc')
        {
          self.servicesList[component] = component;
        }
      };
    };

    return new RestMockHelper();

  }]);