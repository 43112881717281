angular.module('ihestiaCommonDirectives')
  .factory('AttachmentFileModel', function() {
    var AttachmentFileModel = function() {
      var self = this;
      /**
       * nazwa pliku
       * @type {string}
       */
      this.name = null;

      /**
       * wielkosc wyslanego pliku
       * @type {Number} @todo raczej powinna być data
       */
      this.size = '';

      /**
       * typ wyslanego pliku
       * @type {String}
       */
      this.fileType = null;

      /**
       * token pliku
       * @type {String}
       */
      this.fileServerId = null;
      /**
       * [setFromFile ustawia dane na podstawie modelu pliku]
       * @param {[FileModel]} fileModel [model z informacjami o pliku]
       */
      this.setDataFromFileModel = function(fileModel) {
        self.name = fileModel.file.name;
        self.size = String(fileModel.file.size);
        self.fileType = fileModel.file.documentTypeCode ? fileModel.file.documentTypeCode : 'CustomerApplication';
        self.fileServerId = fileModel.fileServerId;
      };

      /**
       * [setData ustawia dane do wysłanego pliku]
       * @param {[type]} data [obiekt]
       */
      this.setData = function(data){
        self.name = data.file.name;
        self.size = String(data.file.size);
        self.fileServerId = data.fileServerId;
      };

      /**
       * Składa json do restów (zapis/update/etc)
       * @return {Object}
       */
      this.getData = function() {
        var restObject = {};
        angular.forEach(this, function(propertyValue, propertyCode) {
          if (propertyValue !== null && !angular.isFunction(propertyValue)) {
            restObject[propertyCode] = propertyValue;
          }
        });

        return restObject;
      };
    };
    return AttachmentFileModel;
  });