angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorLoaderMessagesHelper', ['iKomunikatorMessagesThreadSvc', 'ihestiaIkomunikatorCommonHelper', 'IKomunikatorMessageThreadModel', '$rootScope',
    function(iKomunikatorMessagesThreadSvc, ihestiaIkomunikatorCommonHelper, IKomunikatorMessageThreadModel, $rootScope) {
      var LoaderMessagesHelper = function() {
        var self = this;

        /**
         * [loadFromCache funkcja wyszukuje wiadomości z cahce zgodnie z ustawionymi paramtrami wyszukiwanie]
         */
        this.loadFromCache = function(tplData) {
          tplData.loading = true;
 
          iKomunikatorMessagesThreadSvc.findThreadsFromCache(tplData, function(result) {
            if (angular.isObject(result.data)) {
              self.setAnswerFromSearch(result.data, tplData);
            }
 
            self.afterSearch(tplData);
          }, function() {
            self.afterSearch(tplData);
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania wiadomości');
          });
        };

        this.setAnswerFromSearch = function(data, tplData) {
          tplData.total = data.total;
          if (angular.isArray(data.threads)) {
            angular.forEach(data.threads, function(threadData) {
              var thread = new IKomunikatorMessageThreadModel();
              thread.fromRest(threadData);
              tplData.threads.push(thread);
            });
          }
        };
 
        this.afterSearch = function(tplData) {
          if (tplData.reloadSearchAgain) {
            tplData.reloadSearchAgain = false;
            tplData.loading = false;
 
            tplData.searchFilters.pageSpec.pageNo = 0;
            tplData.threads = [];
 
            self.loadFromCache(tplData);
            return;
          }
 
          tplData.loading = false;
          $rootScope.$broadcast('hideBlockUi');
        };

        this.loadNextResult = function(tplData) {
          if (!tplData.loading && !self.loadedAllThreads(tplData)) {
            tplData.searchFilters.pageSpec.pageNo += 1;
            self.loadFromCache(tplData);
          }
        };

        /**
         * [loadedAllThreads sprawdzamy czy załadowane zostały wszystkie wątki]
         * @return {[type]} [description]
         */
        this.loadedAllThreads = function(tplData) {
          return tplData.searchFilters.pageSpec.pageSize * (tplData.searchFilters.pageSpec.pageNo + 1) >= tplData.total;
        };

        /**
         * [setClearData czyszone sa dane na wyjscie ze scopa]
         */
        this.setClearData = function(tplData, defaultData) {
          //nie czyścimy flagi ładowania, bo zamknięcie i otwarcie okna może spowodować 
          //wczytanie dwóch wyników usług zamiast jednego
          var loading = tplData.loading;

          angular.forEach(tplData, function(value, key) {
            delete tplData[key];
          });

          tplData = angular.extend(tplData, angular.copy(defaultData));
          tplData.loading = loading;
        };
      };

      return new LoaderMessagesHelper();
    }
  ]);