angular.module('ihestiaWidgets.commonOperations')
  .controller('clientSearchListModalCtrl', ['$scope', 'clientSearchModalHelper', 'iHestiaCommonModalHelper', '$timeout',
    function($scope, clientSearchModalHelper, iHestiaCommonModalHelper, $timeout) {

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.$emit('hideBlockUi');

        $scope.customersListResponse = clientSearchModalHelper.customersListResponse;

        $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaOperationsClientSearchList', function($event) {
          $event.preventDefault();
          iHestiaCommonModalHelper.hideModal('ihestiaOperationsClientSearchList');
          $timeout(function() {
            iHestiaCommonModalHelper.showModal('ihestiaOperationsClientSearch');
          }, 0);
        });

        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaOperationsClientSearchList', function() {
          clientSearchModalHelper.clearData();
        });

      };

      $scope.goToClient = function(client)
      {
        clientSearchModalHelper.goToClient(client);
        clientSearchModalHelper.clearData();
        iHestiaCommonModalHelper.hideModal('ihestiaOperationsClientSearchList');
      };

    }
  ]);