angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardTaskHelper', ['ihestiaDashboardTaskDefinitionsSvc', '$q', 'ihestiaRestDictionaryIdentityDocumentTypesSvc', 'ihestiaDashboardDictionariesSvc', 'ihestiaGridSettingsHelper', '$rootScope', 'ihestiaModelHelper', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants',
    function(ihestiaDashboardTaskDefinitionsSvc, $q, ihestiaRestDictionaryIdentityDocumentTypesSvc, ihestiaDashboardDictionariesSvc, ihestiaGridSettingsHelper, $rootScope, ihestiaModelHelper, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants) {

      var TasksHelper = function() {
        var self = this;

        /**
         * W dashboardzie występują 3 typy użytkowników
         * @type {Object}
         */
        this.userTypes = {
          AGENT: 'agent',
          ADMIN: 'admin',
          CENTRAL: 'central'
        };

        /**
         * Stan "moje zadania"
         * @type {String}
         */
        this.taskListState = 'main.taskList.myTasks';

        /**
         * Nazwa stanu szczegółów zadania
         * @type {String}
         */
        this.taskDetailsState = 'main.dashboardTaskDetails';

        /**
         * Nazwa stanu szczegółów sprawy
         * @type {String}
         */
        this.caseDetailsState = 'main.dashboardCaseDetails';

        /**
         * Nazwa stanu szczegółów zadania, wychodząc z listy puli
         * @type {String}
         */
        this.poolTaskDetailsState = self.taskDetailsState;

        /**
         * Nazwa stanu szczegółów zadania, wychodząc z listy spraw
         * @type {String}
         */
        this.caseTaskDetailsState = self.taskDetailsState;

        /**
         * Słownik narodowości
         * @type {array}
         */
        this.nationalities = null;

        /**
         * Słownik typów tożsamości
         * @type {array}
         */
        this.identityDocumentTypes = null;

        /**
         * czy jesteśmy na liście zadań czy szczegółach sprawy
         * @type {[type]} tasks/caseTasks
         */
        this.taskListContext = null;

        /**
         * Słowniki proste typowo dashboardowe
         * @type {object}
         */
        this.dicts = {
          TaskCategoryDict: null,
          TaskStatusDict: null,
          PolicyObjectTypeDict: null,
          RenewalOfferStatus: null,
          RenewalResolutionStatus: null
        };

        /**
         * Zwraca typ użytkownika (spośród stałych w tym helperze - userTypes)
         * @return {string} userType
         */
        this.getUserType = function() {
          return self.currentUserIsCentral() ? self.userTypes.CENTRAL : self.userTypes.AGENT;
        };

        /**
         * Czy aktualnie zalogowany użytkownik jest centralą
         * @return {Boolean} [description]
         */
        this.currentUserIsCentral = function() {
          var currentCharacterType = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;
          var centralCharacters = [UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL, UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER];
          return centralCharacters.indexOf(currentCharacterType) > -1;
        };

        /**
         * Minimalizowanie obiektu przed zapisem do rest
         * @param  {object} object obiekt do minimalizacji
         * @return {undefined}
         */
        this.minifyRestObject = function(object) {
          ihestiaModelHelper.minifyRestObject(object);
          angular.forEach(object, function(attributeVal, attributeCode) {
            if (attributeVal === null) {
              delete object[attributeCode];
            } else if (angular.isObject(attributeVal)) {
              self.minifyRestObject(attributeVal);
            }
          });
        };

        /**
         * Zmiana w aktualnym filtrze
         * - klikanie/odklinanie na tablicy
         * - ustawianie wartości dla innych
         *
         * @param  {sttring} filterCode kod filtru
         * @param  {mixed} filterData dane
         * @return {ihestiaDashboardTaskHelper}            self
         */
        this.changeCurrentFilter = function(filterCode, filterData) {
          if (filterData === null) {
            // czyszczenie filtru
            ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode] = null;
          } else {
            if (angular.isArray(ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode])) {
              var outputArray = ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode],
                dictionaryArray = ihestiaGridSettingsHelper.globalConfig.currentData.dictionaries[filterCode],
                outputIndex = -1;
              angular.forEach(outputArray, function(item, itemIndex) {
                if (item.code === filterData) {
                  outputIndex = itemIndex;
                }
              });
              if (outputIndex === -1) {
                //nie było zaznaczonego elementu, więc go dokładamy
                outputArray.push({
                  code: filterData,
                  selected: true
                });
                //znajdujemy do w słowniki i oznaczamy jako selected żeby isteven sobie odświeżył
                angular.forEach(dictionaryArray, function(item) {
                  if (item.code === filterData) {
                    item.selected = true;
                  }
                });
              }
              else
              {
                //odznaczanie
                //usuwamy z wyników
                outputArray.splice(outputIndex, 1);
                //zaznaczmy w słowniku jako niezaznaczony  żeby isteven sobie odświeżył
                angular.forEach(dictionaryArray, function(item) {
                  if (item.code === filterData) {
                    item.selected = false;
                  }
                });
              }
            } else {
              ihestiaGridSettingsHelper.currentFilter.settings.filterSettings[filterCode] = filterData;
            }
          }

          $rootScope.$broadcast('gridSettingsClosed');
        };


        /**
         * Pobieranie słowników
         * @param  {string} dictCode kod słownika
         * @return {promise}
         */
        this.getDict = function(dictCode) {
          return $q(function(resolve, reject) {
            if (self.dicts[dictCode] !== null && angular.isDefined(self.dicts[dictCode])) {
              resolve(self.dicts[dictCode]);
            } else {
              ihestiaDashboardDictionariesSvc.get('content', null, dictCode).then(function(res) {
                if (res && res.data && res.status === 200) {
                  self.dicts[dictCode] = [];
                  angular.forEach(res.data, function(label, code) {
                    self.dicts[dictCode].push({
                      label: label,
                      code: code
                    });
                  });
                  resolve(self.dicts[dictCode]);
                } else {
                  reject(res);
                }
              }, function(rej) {
                reject(rej);
              });
            }
          });
        };

      };

      return new TasksHelper();
    }]);