angular.module('ihestiaCommonDirectives.application')
  .factory('simplePolicyHelper', ['IHESTIA_AMOUNT_CURRENCY_PLN', 'IHESTIA_DICTIONARY_AMOUNT_CURRENCY',
    function(IHESTIA_AMOUNT_CURRENCY_PLN, IHESTIA_DICTIONARY_AMOUNT_CURRENCY) {

      var SimplePolicyHelper = function() {

        /**
         * Zwracamy składkę dla polisy (simplePolicy i Policy)
         * @param  {object} policy [description]
         * @return {object} {currency: 'PLN', value: 100}
         */
        this.getPolicyPremium = function(policy) {
          var totalPremium = 0;
          angular.forEach(policy.risks, function(risk) {
            if(typeof risk.premium === 'number'){
              totalPremium += risk.premium;
            } else if(risk.premium[0])
            {
              totalPremium = totalPremium + risk.premium[0];
            }
          });

          var premium = {
            currencyCode: IHESTIA_AMOUNT_CURRENCY_PLN, //zakładamy, że zawsze pln
            value: totalPremium
          };
          premium.currency = IHESTIA_DICTIONARY_AMOUNT_CURRENCY[premium.currencyCode];
          return premium;
        };
      };

      return new SimplePolicyHelper();
    }]);
