angular.module('salesPath2')
  .controller('localizationEditModalCtrl', ['$scope', 'EstateModel', 'localizationHelper', 'AddressModel', 'localizationModalHelper', 'dictionaryServiceHelper', 'resourceHelper', 'CONFIG', '$state', 'CONSTANTS', 'appVariables', 'coownerModalHelper',
    function($scope, EstateModel, localizationHelper, AddressModel, localizationModalHelper, dictionaryServiceHelper, resourceHelper, CONFIG, $state, CONSTANTS, appVariables, coownerModalHelper) {

      $scope.modalData = localizationModalHelper.currentlyEditedLocalizationData;
      $scope.readonly = false;

      $scope.services = {
        addressSvc: dictionaryServiceHelper.getDictionaryAddressService()
      };

      $scope.numericSettings = {
        allowLeadingZeros: false,
        parseToString: false,
        formatting: false,
        groupSeparator: '',
        decimals: 0,
        min: 0
      };

      $scope.disabledAttrs = {};

      $scope.init = function() {
        $scope.setInitialData();
        $scope.readonly = appVariables.readOnly || (appVariables.isSupplement && $scope.modalData.allowedChanges.allDisabled);
        $scope.fireListeners();
      };

      $scope.setInitialData = function() {
        if ($scope.modalData.localizationData === null) {
          $scope.modalData.localizationData = $scope.modalData.localization.getData();
          if ($scope.modalData.localizationData.address === null) {
            $scope.modalData.localizationData.address = new AddressModel();
            $scope.modalData.localizationData.type = $scope.modals.localizationEditModal.type;
          }
        }
        $scope.handleLocalizationTypeChanged($scope.modalData.localizationData.type);
      };

      /**
       * Odpalamy nasłuchiwanie eventow, watchy itp.
       */
      $scope.fireListeners = function() {
        // zapis danych
        $scope.$on('iHestiaCommonModalOkBtnClicked.localizationEditModal', function() {
          if ($scope.readonly) {
            localizationModalHelper.clearEditModalData();
            return false;
          }
          $scope.modalData.localizationData.type = $scope.modals.localizationEditModal.type;
          angular.forEach($scope.modalData.attributeVisibility, function(isVisible, attrCode) {
            if (!isVisible) {
              $scope.modalData.localizationData.dynamicValues[attrCode] = null;
            } else { // attr visible
              // set false by default for checkbox controls
              if ((_.isUndefined($scope.modalData.localizationData.dynamicValues[attrCode]) || $scope.modalData.localizationData.dynamicValues[attrCode] === null)
                && resourceHelper.getLocalizationAttributeType(attrCode) === CONSTANTS.CONTROL_TYPE_CHECKBOX) {
                $scope.modalData.localizationData.dynamicValues[attrCode] = false;
              }
            }
          });
          localizationHelper.saveLocalization($scope.modalData.editedId, $scope.modalData.localizationData);
          localizationModalHelper.clearEditModalData();
          if ($scope.modalData.localizationData.dynamicValues._hasCoowners) {
            coownerModalHelper.showCoownerList(CONSTANTS.PRODUCT_TYPE_LOCALIZATION);
          }
          return true;
        });

        $scope.$on('iHestiaCommonModalCancelBtnClicked.localizationEditModal', function() {
          localizationModalHelper.clearEditModalData();
          if (appVariables.readOnly || appVariables.isSupplement) {
            return;
          }

          if (!localizationHelper.firstLocalizationCompletelyAdded) {
            if (CONFIG.BEHAVIOR.isSingleProduct) {
              $state.go(CONSTANTS.PAGE_START_CLIENT_EDIT);
            } else {
              $state.go(CONSTANTS.PAGE_MATRIX);
            }
          }
        });

        // zmiana typu lokalizacji
        // musi zostac gdyz, nastepuje to tez poprzez naglowek modala
        $scope.$watch('modals.localizationEditModal.type', function(type) {
          $scope.modalData.localizationData.type = type;
          $scope.handleLocalizationTypeChanged(type);
        });
      };

      /**
       * invoked on any attribute changes in template
       */
      $scope.updateAttributesVisibility = function() {
        resourceHelper.updateAttributeVisibilityWithConditions(
          $scope.modalData.localizationData.dynamicValues,
          $scope.modalData.attributeVisibility,
          $scope.modalData.localizationData.type
        );
        resourceHelper.updateDisabilityWithConditions(
          $scope.modalData.localizationData.dynamicValues,
          $scope.modalData.attributeVisibility,
          $scope.modalData.localizationData.type,
          $scope.disabledAttrs
        );
      };

      /**
       * set attributes and attribute visibility for localization type
       * @param {string} type
       */
      $scope.handleLocalizationTypeChanged = function(type) {
        $scope.localizationAttributes = resourceHelper.getLocalizationAttributesByType(type);
        $scope.modalData.attributeVisibility = {};
        $scope.allAttributes = resourceHelper.getAllLocalizationAttributes();
        _.forEach($scope.allAttributes, function(attrCode) {
          $scope.modalData.attributeVisibility[attrCode] = _.includes($scope.localizationAttributes, attrCode);
        });
        $scope.updateAttributesVisibility();
      };

      $scope.init();
    }
  ]);
