angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaPoicyReportModalHelper', ['iHestiaCommonModalHelper', 'ihestiaPzaFileSvc',
    function(iHestiaCommonModalHelper, ihestiaPzaFileSvc) {
      var PolicyReportModal = function() {
        var self = this;

        this.programId = null;

        /**
         * Czyścimy dane pomocnicze powiązane z modalem
         * zazwyczaj odpalane na $destroy modala
         * @return {PolicyReportModal} self
         */
        this.clearData = function() {
          self.programId = null;

          return self;
        };


        /**
         * Pokazujemy modal wyboru raportu
         * @param  {int} programId id programu
         * @return {PolicyReportModal} self
         */
        this.showPolicyReportModal = function(programId) {
          self.programId = programId;
          iHestiaCommonModalHelper.showModal('ihestiaPzaPolicyReport');

          return self;
        };

        /**
         * Pokazujemy modal wyboru raportu
         * @return {PolicyReportModal} self
         */
        this.hidePolicyReportModal = function() {
          self.clearData();
          iHestiaCommonModalHelper.hideModal('ihestiaPzaPolicyReport');

          return self;
        };


        /**
         * Ściąganie raportu po fileId
         * @param  {String} fileId id pliku
         * @return {Promise}
         */
        this.downloadReport = function(fileId) {
          return ihestiaPzaFileSvc.downloadFile(fileId);
        };

      };

      return new PolicyReportModal();
    }]);