angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaLifeRiskTable', ['ihestiaSumInsuredVectorModalHelper',
    function(ihestiaSumInsuredVectorModalHelper) {
      return {
        templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/riskTable/lifeRiskTable.tpl.html',
        restrict: 'E',
        scope: {
          riskTable: '=',
          hidePremium: '='
        },
        link: function(scope) {
          scope.showSumInsuredVector = function(sumInsuredVector) {
            ihestiaSumInsuredVectorModalHelper.showModal({
              sumInsuredVector: sumInsuredVector
            }).then(lsnNg.noop, lsnNg.noop);
          };
        }
      };
    }]);
