/**
* Kontoler
* @param  {[type]} ) {               }  ] [description]
* @return {[type]}   [description]
*/
angular.module('ihestiaMenuBase')
  .controller('ihestiaMenuBaseCtrl', ['$state', '$scope', 'iHestiaMenuSettings', '$timeout', 'ihestiaConfigHelper', 'ihestiaAgHelper', 'ihestiaCommonComponentStatusHelper',
    'iHestiaMenuBaseHelper', 'ihestiaMenuBaseConfig',
    function($state, $scope, iHestiaMenuSettings, $timeout, ihestiaConfigHelper, ihestiaAgHelper, ihestiaCommonComponentStatusHelper, iHestiaMenuBaseHelper, ihestiaMenuBaseConfig) {
      $scope.isDirectMode = iHestiaMenuBaseHelper.isDirect();
      $scope.logoData = iHestiaMenuBaseHelper.getLogoData();
      $scope.menuSettings = {
        header: 'iHestia'
      };

      $scope.elementsAvailability = iHestiaMenuBaseHelper.getElementsAvailability();

      $scope.customTopRightElements = ihestiaMenuBaseConfig.customTopRightElements;

      //uprawnienia do dostępu do aplikacji połączone z aktywnością komponentu (oba muszą być spełnione)
      $scope.availability = {
        'E1': false,
        'E7': false,
        'MF': false,
        'IMONITOR': false,
        'CI': false,
        'AGENT': false,
        'CLIENT': false,
        'PARTNER': false
      };

      $scope.salesPaths = ['E1', 'E7', 'MF'];

      $scope.$on('componentStatusChanged', function(){
        $scope.refreshPermissions();
      });

      $scope.$on('iHestiaMenuChangeTitle', function(e, title){
        $scope.menuSettings.header = title;
      });

      /**
      * Init
      * @return {[type]} [description]
      */
      $scope.init = function() {

        $timeout(function() {
          if (typeof iHestiaMenuSettings.settings.header !== 'undefined') {
            $scope.menuSettings.header = iHestiaMenuSettings.settings.header;
          }
        }, 0);
        $scope.refreshPermissions();
        //rejestrujemy funkcję w helperze
        iHestiaMenuBaseHelper.baseSetAvailability = $scope.setAvailability;
        iHestiaMenuBaseHelper.menuBaseBrandChanged = $scope.brandChanged;
      };

      /**
       * ręczna zmiana availability
       */
      $scope.setAvailability = function(availability)
      {
        angular.extend($scope.elementsAvailability, availability);
      };

      $scope.goHomePage = function() {
        $scope.$emit('iHestiaMenuMainLogoClicked');
      };

      $scope.refreshPermissions = function()
      {
        var symbolPublic = ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC');
        var agSymbol;

        //templatka produktów
        angular.forEach($scope.availability, function(available, permissionCode){
          if($scope.salesPaths.indexOf(permissionCode) !== -1 && !ihestiaAgHelper.canStartSalePath())
          {
            //użytkownik nie może przejść do żadnej sprzedażówki
            $scope.availability[permissionCode] = false;
          }
          else
          {
            agSymbol = ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_' + permissionCode);
            $scope.availability[permissionCode] = ihestiaAgHelper.hasPermissionFor(symbolPublic, agSymbol) && ihestiaCommonComponentStatusHelper.isComponentEnabledByAgSymbol(agSymbol);
          }
        });
      };

      $scope.brandChanged = function()
      {
        $scope.isDirectMode = iHestiaMenuBaseHelper.isDirect();
      };

      $scope.init();
    }
  ]);