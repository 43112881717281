angular.module('salesPath2.loader')
/**
 * helper loadera ścieżki
 */
  .factory('sp2LoaderHelper', ['CONFIG', 'CONSTANTS', '$state', '$location', '$window', 'sp2CommonHelper', '$rootScope', '$timeout', 'appVariables', 'applicationHelper', 'renewalHelper', 'sp2SelectionHelper', 'resourceHelper', 'dataContainerHelper',
    function(CONFIG, CONSTANTS, $state, $location, $window, sp2CommonHelper, $rootScope, $timeout, appVariables, applicationHelper, renewalHelper, selectionHelper, resourceHelper, dataContainerHelper) { // eslint-disable-line angular/di
      var LoaderHelper = function() {
        var self = this;
        this.appLoaded = false; //czy aplikcja wczytana
        /**
         * inicjalny stan aplikacji
         * @type {String}
         */
        this.initialState = angular.isDefined(CONFIG.NAVIGATION.initialState) ? CONFIG.NAVIGATION.initialState : CONSTANTS.PAGE_START_CLIENT_SEARCH;
        /**
         * dostępne akcje, ich parametry i callbacki
         * UWAGA
         * kolejność elementów tablicy params musi być taka sama jak argumentów funkcji callback
         * @type object
         */
        this.actions = {
          'new': {
            'params': ['personid', 'vehicleid', 'persondata', 'nbkid', 'risks', 'adds'],
            'callback': function(personId, vehicleId, personData, nbkId, risks, adds) {
              applicationHelper.newApplication(personId, vehicleId, personData, nbkId, risks, adds);
            }
          },
          'viewapplication': {
            'params': ['applicationid'],
            'callback': function(applicationId) {
              applicationHelper.loadApplication(applicationId);
            }
          },
          'viewoffer': {
            'params': ['offerid'],
            'callback': function(offerId) {
              applicationHelper.loadOffer(offerId);
            }
          },
          'renewal': {
            'params': ['policyid', 'policysymbol', 'insuranceoption'],
            'callback': function(policyId, policySymbol, insuranceOption) {
              renewalHelper.startRenewal(policyId, policySymbol, insuranceOption);
            }
          },
          'singlerenewal': {
            'params': ['policyid'],
            'callback': function(policyId) {
              renewalHelper.renewPolicies([policyId]);
            }
          },
          'extend': {
            'params': ['policyid', 'policynumber'],
            'callback': function(policyId, policyNumber) {
              applicationHelper.extendPolicy(policyId, policyNumber);
            }
          },
          'viewpolicy': {
            'params': ['policyid'],
            'callback': function(policyId) {
              if (!sp2CommonHelper.hasFinalPolicyPermissionFor('AG_SYMBOL_POLICY_VIEW')) {
                sp2CommonHelper.showConfirmModal({
                  title: 'Komunikat',
                  okBtnName: 'Zamknij',
                  cancelBtnName: '',
                  text: 'Brak uprawnień do podglądu polisy.',
                  okBtnCallback: function() {
                    $state.go(self.initialState);
                  }
                });
              } else {
                applicationHelper.loadPolicy(policyId);
              }
            }
          },
          'initial': { //domyślna akcja inicjalizująca ścieżkę sprzedaży
            'callback': function() {
              $state.go(self.initialState);
            }
          }
        };

        /**
         * @type object query params (z URLa) przekazane przy wywołaniu aplikacji
         */
        this.queryParams = {};

        /**
         * @type (string|null) aktualna akcja
         */
        this.currentAction = null;

        /**
         * @type object aktualne parametry akcji i ch wartości
         */
        this.currentActionParams = {};

        /**
         * przydziela akcje i jej parametry do konkretnego callbacka i wywołuje go
         */
        this.dispatchAction = function() {
          if (self.appLoaded) { //jesli aplikacja juz zaladowana, to odswiezamy strone z przekazanymi parametrami
            $timeout(function() {
              $window.location.reload(true);
            }, 0);
            return;
          }
          if (self.currentAction === null || angular.isUndefined(self.actions[self.currentAction])) {
            self.actions.initial.callback();
            self.appLoaded = true;
            return;
          }
          var params = [];
          if (angular.isDefined(self.actions[self.currentAction].params)) {
            angular.forEach(self.actions[self.currentAction].params, function(paramName) {
              var paramValue = (angular.isDefined(self.currentActionParams[paramName])) ? self.currentActionParams[paramName] : null;
              params.push(paramValue);
            });
          }
          self.actions[self.currentAction].callback.apply(this, params);
          self.appLoaded = true;
        };

        /**
         * przetwarza url i uzupelnia akcje i jej parametry
         */
        this.processUrl = function() {
          self.queryParams = angular.copy($state.params);
          lsnNg.forEach(self.queryParams, function(value, name) {
            if (!angular.isString(value) || value === 'true') {
              return;
            }
            name = name.toLowerCase();
            if (name === 'action') { //akcja
              self.currentAction = value.toLowerCase();
            } else { //parametr
              self.currentActionParams[name] = value;
            }
          });
        };

        /**
         * przeladowuje strone
         * @param {object|null} params dodatkowe parametry GET, ktore zostana skonwertowane do wueryStringa
         */
        this.reload = function(params) {
          $window.location = sp2CommonHelper.getServerConfig().get('app').URL + '/#/' + CONSTANTS.PAGE_INIT + (angular.isObject(params) ? ('?' + angular.element.param(params)) : '');
          $window.location.reload(true);
        };

        //uruchamia listenery m.in. dla eventow stanow
        this.fireListeners = function() {
          //reakcja na zmianę strony/state-u
          $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
            var toNmae = self.verifyStateTransition(toState, fromState);
            if (toNmae !== toState.name) {
              event.preventDefault();
              $state.go(toNmae);
            }
          });

          //inicjalny stan
          if (!self.appLoaded && $state.current.name !== CONSTANTS.PAGE_INIT) {
            $state.go(CONSTANTS.PAGE_INIT);
          }
        };

        //callback przed zmiana stanu (ktora za moment nastapi)
        this.stateTransitionBeforeCallback = function(stateName) {
          // lewe menu dla wyboru klienta itp.
          if (CONSTANTS.START_PAGES.indexOf(stateName) !== -1 || stateName === CONSTANTS.PAGE_INIT) {
            appVariables.disableLeftMenu = true;
          } else {
            appVariables.disableLeftMenu = false;
          }
        };

        /**
         * weryfikuje przejście do kolejnego stanu i zwraca nzawę dozwolonego stanu
         * @param  {Object} to       [description]
         * @param  {Object} from     [description]
         * @return {String}          nazwa dozwolonego stanu (moze byc to ten sam stan co to.name, jeśli weryfikacja przeszła poprawnie)
         */
        this.verifyStateTransition = function(to, from) {
          if (!self.isStateProductAllowed(to.name)) { //jesli produktu na danym stejcie nie jest obslugiwany
            return from.name;
          }
          if (to.type && to.type === 'error') {
            $rootScope.disableIHestiaMenu = true;
            self.stateTransitionBeforeCallback(to.name);
            return to.name;
          } else {
            $rootScope.disableIHestiaMenu = false;
          }
          if (from.name === '' && to.name !== CONSTANTS.PAGE_INIT) {
            self.appLoaded = true;
          }
          var insurerSet = angular.isDefined($rootScope.dataContainer) && angular.isString($rootScope.dataContainer.insurerId) && angular.isDefined(dataContainerHelper.getObjects(CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION)[$rootScope.dataContainer.insurerId]) && appVariables.isInsurerChosen,
            mainObjectChosen = angular.isDefined($rootScope.dataContainer) && dataContainerHelper.isMainObjectChosen();
          if (CONSTANTS.START_PAGES.indexOf(to.name) === -1 && to.name !== CONSTANTS.PAGE_INIT && !insurerSet) {
            self.stateTransitionBeforeCallback(self.initialState);
            return self.initialState;
          } else if (CONSTANTS.START_PAGES.indexOf(to.name) !== -1 && insurerSet && mainObjectChosen) {
            self.stateTransitionBeforeCallback(appVariables.mainState);
            return appVariables.mainState;
          }
          self.stateTransitionBeforeCallback(to.name);
          return to.name;
        };

        /**
         * czy state dostepny pod kątem obslugiwanego produktu
         * @param  {String}  state
         * @return {Boolean} false gdy niedostępny
         */
        this.isStateProductAllowed = function(state) {
          var stateProduct = null, //produkt oblsugiwany w danum state (jeli null, tzn. ze state nie odnosi się do żadnego produktu/ryzyka)
            roBlock = false; //czy blokada state'a w trybie readOnly
          lsnNg.forEach(CONSTANTS.PRODUCT_TO_STATE, function(st, pr) {
            if (state === st && angular.isDefined(resourceHelper.productType[pr])) {
              stateProduct = pr;
              if (appVariables.readOnly && !$rootScope.dataContainerHelper.isSelectedAnyProductOn(resourceHelper.productType[pr])) {
                roBlock = true;
              }
              return false;
            }
            return true;
          });
          if (stateProduct === null) {
            return true;
          }
          return $rootScope.dataContainer.allowedProducts[stateProduct] && !roBlock;
        };

      };

      return new LoaderHelper();
    }
  ]);