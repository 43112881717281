angular.module('ihestiaRestServicesBase')

  /**
   * generyczny serwis REST do obsługi OSS
   * @return {[type]} [description]
   */
  .factory('ihestiaOssSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IhestiaOssSvc = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = '';
        this.businessArea = 'oss';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new IhestiaOssSvc();
  }])
  ;
