angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryIndustriesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryIndustriesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'industries';
        this.sortBy = 'name';
        this.pageSize = 1000;

      };
      return new RestDictionaryIndustriesSvc();
    }
  ]);