angular.module('salesPath2')
  .controller('messageContainerCtrl', ['$scope', 'messagesHelper',
    function($scope, messagesHelper) {
      // -- start --
      /**
       * komunikaty do wyświetlenia
       * @type {Array}
       */
      $scope.messagesHelper = messagesHelper;
      $scope.errorTypeToClass = {};
      $scope.errorTypeToClass[$scope.CONSTANTS.ERROR_TYPE.error] = 'error';
      $scope.errorTypeToClass[$scope.CONSTANTS.ERROR_TYPE.warn] = 'warning';
      $scope.errorTypeToClass[$scope.CONSTANTS.ERROR_TYPE.info] = 'info';
      $scope.errorTypeToClass[$scope.CONSTANTS.ERROR_TYPE.unsupported] = '';

      // --end --
    }
  ]);