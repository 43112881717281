angular.module('ihestiaRestServicesBase')
  .factory('ihestiaOssCashPaymentDenySvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {
      var IhestiaOssCashPaymentDenySvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'associations/cashPaymentDeny';
        this.businessArea = 'oss';
        this.sortBy = 'symbol';
        this.pageStyle = false;
      };

      return new IhestiaOssCashPaymentDenySvc();
    }]);