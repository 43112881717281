angular.module('lsnBase.validators')
  .directive('ihestiaPattern', function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        var expr = new RegExp(attrs.ihestiaPattern);

        var setValidity = function(value) {
          if (typeof value === 'undefined' || value === '' || value === null) {
            ngModel.$setValidity('ihestiaPattern', true);
          } else {
            ngModel.$setValidity('ihestiaPattern', expr.test(value));
          }

          return value;
        };

        setValidity(element.val());

        // For DOM -> model validation
        ngModel.$parsers.unshift(setValidity);

        // For model -> DOM validation
        ngModel.$formatters.unshift(setValidity);
      }
    };
  });