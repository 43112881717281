angular.module('lsnBase.models')
.factory('LsnUploadedFileModelV1', ['LsnAbstractModel', function(LsnAbstractModel) {

    var LsnUploadedFileModelV1 = function()
    {
      this.objectName = 'UploadedFile';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        fileName: {
          type: 'string'
        },
        uploadDate: {
          type: 'date'
        },
        confirmationDate: {
          type: 'date'
        },
        qualityCode: {
          type: 'string'
        },
        qualityDescription: {
          type: 'string'
        },
        fileId: {
          type: 'string'
        },
        fileLength: {
          type: 'int'
        },
        attributes: {
          type: 'array',
          elementsType: 'DocumentAttribute'
        },
        isFileExpired: {
          type: 'bool'
        }
      };

      this.fileName = null;//  string  Nazwa pliku
      this.uploadDate = null;//  date  Data przesłania pliku
      this.confirmationDate = null;//  date  Data potwierdzenia odebrania dokumentu przez system archiwizacyjny.
      this.qualityCode = null;// string  Kod jakości dokumentu. Własność wypełniona tylko dla dokumentów, które podlegają sprawdzeniu jakości.
      this.qualityDescription = null;//  string  Opis jakości dokumentu. Własność wypełniona tylko dla dokumentów, które podlegają sprawdzeniu jakości.
      this.fileId = null;//  string  Identyfikator pliku w Frontowym Repozytorium Plików
      this.fileLength = null;//  int Wielkość pliku w bajtach
      this.isFileExpired = null;// okres przechowywania dokumentów rozliczonych w FileServer dobiegł końca
      this.attributes = null;//  List<DocumentAttribute> Lista atrybutów z wartościami uzupełnionymi przy elektronicznym przesyłaniu plików.

    };


    LsnUploadedFileModelV1.prototype = LsnAbstractModel.prototype;

    return LsnUploadedFileModelV1;
  }]);