
angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorAdvancedSearchMessagesCentralCtrl', ['$scope', 'ihestiaIkomunikatorSearchMessagesCentralHelper', '$timeout', 'ihestiaIkomunikatorAdvancedFilterHelper',
    '$rootScope', '$state', 'ihestiaIkomunikatorNavigationHelper', 'iKomunikatorConstants',
    function($scope, ihestiaIkomunikatorSearchMessagesCentralHelper, $timeout, ihestiaIkomunikatorAdvancedFilterHelper, $rootScope, $state, ihestiaIkomunikatorNavigationHelper, iKomunikatorConstants) {
      $scope.tplData = {};

      $scope.init = function() {
        $scope.constants = iKomunikatorConstants;
        $scope.tplData.filter = ihestiaIkomunikatorAdvancedFilterHelper.getFilter();
        $scope.tplData.dictionaries = ihestiaIkomunikatorAdvancedFilterHelper.getDictionaries();
        $scope.fireListeners();
      };

      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIkomunikatorSearchMessagesCentral', function() {
          if($state.current.name !== null && $state.current.name === ihestiaIkomunikatorNavigationHelper.fullListPanelState){
            $rootScope.$broadcast('iKomunikator.reloadCentralFullListPanel');
          }else{
            $rootScope.$broadcast('iKomunikator.setShowModalFilterOnInit', false);
            ihestiaIkomunikatorNavigationHelper.goTo('fullListPanel', null, ihestiaIkomunikatorAdvancedFilterHelper.getFilter().getAttributeToGet());
          }

          ihestiaIkomunikatorSearchMessagesCentralHelper.hideModal();
        });


      };

      $scope.init();
    }
  ]);