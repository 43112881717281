angular.module('salesPath2')
  .service('sp2BlikPaymentModalHelper', ['LsnModalHelper', 'dataContainerHelper', 'ihestiaBasicPaymentHelper', 'sp2UiHelper', 'personHelper', '$filter', 'ContactModelConstants',
    function(LsnModalHelper, dataContainerHelper, ihestiaBasicPaymentHelper, sp2UiHelper, personHelper, $filter, ContactModelConstants) {
    var BlikPaymentModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        this.tplData = {
            policies: null,
            insurer: null,
            formData: {
                authorizationCode: null
            },
            serviceError: null,
            formSubmitted: false,
            paymentStatus: null,
            blikPayed: false, // if sending BLIK code was successfull
            successString: '',
            statusCheckPending: false,
            applicationStatus: null
        };

        this.modalOptions = {
            size: 'sm',
            title: ['blikPayment', {
                componentCode: 'Public'
            }],
            templateUrl: 'salesPath2Template/common/blikPayment/blikPaymentModal.tpl.html',
            okBtnName: ['save', {
                componentCode: 'Public'
            }],
            cancelBtnName: ['Public.cancel', {
                componentCode: 'Public'
            }]
        };

        this.setOptions(self.modalOptions);

        this.init = function() {
            self.tplData.applicationStatus = dataContainerHelper.getApplicationStatus();
            self.tplData.policies = dataContainerHelper.getBlikPaymentPolicies();
            self.tplData.insurer = dataContainerHelper.getInsurer();
            self.tplData.formData.authorizationCode = null;
            if(self.tplData.policies.length > 1)
            {
                self.tplData.successString = $filter('translate')('thanksForPayingForPolicies', {componentCode: 'iKonto'}).replace(' {policiesString}', '');
            }
            else
            {
                self.tplData.successString = $filter('translate')('thanksForPayingForPolicy', {componentCode: 'iKonto'}).replace(' {policyString}', '');
            }
            var policicesNumbers = [];
            var paymentAmount = 0;
            angular.forEach(ihestiaBasicPaymentHelper.getPaymentsFromSpPolicies(self.tplData.policies), function(policyPaymentRecord) {
                policicesNumbers.push(policyPaymentRecord.policy);
                angular.forEach(policyPaymentRecord.installments, function(installment){
                    paymentAmount = paymentAmount + installment.premium[0];
                });
            });
            self.tplData.policicesNumbersString = policicesNumbers.join(', ');
            self.tplData.paymentAmount = paymentAmount;
        };

        this.setStatus = function(status)
        {
            self.tplData.paymentStatus = status;
            if(self.tplData.paymentStatus === 'TIMEOUT' || self.tplData.paymentStatus === 'ERROR') {
                self.setOptions({
                    okBtnName: '',
                    cancelBtnName: $filter('translate')('Public.close', {
                        componentCode: 'Public'
                    }),
                    cancelBtnSpinner: false
                });
            } else if(self.tplData.paymentStatus === 'PENDING') {
                self.setOptions({
                    okBtnName: '',
                    cancelBtnName: $filter('translate')('Public.close', {
                        componentCode: 'Public'
                    }),
                    cancelBtnSpinner: true
                });
            } else if(self.tplData.paymentStatus === 'SUCCESS')
            {
                // blik payed
                self.tplData.blikPayed = true;
                self.setOptions({
                    okBtnName: '',
                    cancelBtnName: $filter('translate')('Public.close', {
                        componentCode: 'Public'
                    }),
                    cancelBtnSpinner: false
                });
            }
        };

        this.cancel = function() {
          this.setPromiseAsRejected({
            type: 'close',
            blikPayed: self.tplData.blikPayed,
            fromOffer: self.tplData.applicationStatus === 'OFFER' // payed offer, so we need to show policies
          });
          return this.hideModal();
        };

        this.confirm = function() {
            sp2UiHelper.showBlockUi();

            self.tplData.paymentStatus = null;
            self.tplData.serviceError = null;

            var payments = ihestiaBasicPaymentHelper.getPaymentsFromSpPolicies(self.tplData.policies);
            var customerData = ihestiaBasicPaymentHelper.getCustomerDataFromSpPerson(self.tplData.insurer);
            var emailContact = personHelper.getPersonContact(self.tplData.insurer, ContactModelConstants.CONTACT_TYPE_MAIL);
            if(emailContact && emailContact.value) {
                customerData.email = emailContact.value;
            }

            var params = {
                blik: true,
                authorizationCode: self.tplData.formData.authorizationCode
            };
            self.tplData.formSubmitted = true;

            if(self.tplData.applicationStatus === 'OFFER') {
                params.isOffer = true;
            }

            ihestiaBasicPaymentHelper.savePayment(payments, customerData, params).then(function(res){
                self.setStatus(res.data.paymentStatus);
                ihestiaBasicPaymentHelper.waitForPayment(res.data.orderID).then(function(res){
                    self.setStatus(res.data.paymentStatus);
                }, function(res) {
                    if(res === 'paymentCheckTimeout') {
                        self.setStatus('TIMEOUT');
                    } else {
                        self.setStatus(res.data.paymentStatus);
                    }
                });
            }, function(reject){
                if(reject.status === 409 && reject.data && angular.isArray(reject.data))
                {
                    self.tplData.serviceError = reject.data[0];
                }
                else if(reject.data && reject.data.paymentStatus)
                {
                    self.setStatus(reject.data.paymentStatus);
                }
                else
                {
                    self.setPromiseAsRejected({
                        // message: message,
                        type: 'error'
                    });
                    self.hideModal();
                }
            }).finally(function() {
                sp2UiHelper.hideBlockUi();
            });
        };

    };
    return new BlikPaymentModalHelper();
    }]);