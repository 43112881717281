angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaAbstractPolicyDetailsBaseConstants', [function() {
    var constants = {};
    constants.OPERATION_RESTORE_VERSION = 'restoreVersion';
    constants.OPERATION_INSURER_CHANGE = 'policyholderReplacement';
    constants.OPERATION_BENEFICIARY_CHANGE = 'beneficiaryChange';
    constants.OPERATION_POLICY_TERMINATION = 'policyTermination';
    constants.OPERATION_PRINT = 'print';
    constants.OPERATION_ANNIVERSARY_DOCUMENTS = 'anniversaryDocuments'; // visibility based on product.dynamicValues._frpDocumentsIdsVector.frpDocumentId (policy details)
    constants.OPERATION_RESTORE_ANNIVERSARY = 'anniversaryChange'; // wycofaj rocznicę
    constants.OPERATION_CREATE_ANNIVERSARY = 'restoreAnniversary';
    return constants;
  }])
  .controller('ihestiaAbstractPolicyDetailsBaseCtrl', ['$scope', 'PolicyHelper', 'resolvedPolicy', 'resolvedPolicyFiles', 'ihestiaPlatformHelper', 'lsnModelFactory', 'PolicyModel', 'policiesPrintoutsSvc', 'RiskTableHelperConfigConstants', 'ihestiaRiskTableHelper', '$q', 'ihestiaSaleNavigationHelper', 'policyDetailsContextHelper', 'policiesSvc', 'IhestiaAbstractPolicyDetailsBaseConstants', 'ihestiaPolicyMessageHelper', '$filter', 'iHestiaCommonModalHelper', 'policyDocumentsModalHelper', 'ihestiaPolicyDetailsModalsInjectorCfg', 'ihestiaConfigHelper',
    function($scope, PolicyHelper, resolvedPolicy, resolvedPolicyFiles, ihestiaPlatformHelper, lsnModelFactory, PolicyModel, policiesPrintoutsSvc, RiskTableHelperConfigConstants, ihestiaRiskTableHelper, $q, ihestiaSaleNavigationHelper, policyDetailsContextHelper, policiesSvc, IhestiaAbstractPolicyDetailsBaseConstants, ihestiaPolicyMessageHelper, $filter, iHestiaCommonModalHelper, policyDocumentsModalHelper, ihestiaPolicyDetailsModalsInjectorCfg, ihestiaConfigHelper) {
      /**
       * @type {PolicyHelper}
       */
      $scope.policyHelper = null;
      /**
       * @type {PolicyModel | LsnPolicyModelV3}
       */
      $scope.policy = null;
      $scope.policyData = null;
      $scope.policyIdProperty = null;
      $scope.insurer = null;
      $scope.mainInsured = null;
      $scope.insurerIsMainInsured = null;
      $scope.insuredPersons = null;
      $scope.isClient = policyDetailsContextHelper.isClient();
      $scope.riskTables = [];
      /**
       * @type {object | null}
       */
      $scope.availableOperations = null;
      /**
       * @type {PolicyDetailsOperation[]}
       */
      $scope.availableOperationsList = [];
      /**
       * Lifecycle listener variables
       */
      $scope._riskTableLifeCycleDeferred = $q.defer();
      $scope._availableOperationsLifeCycleDeferred = $q.defer();

      $scope.loadStatuses = {
        availableOperations: false,
        riskRows: false
      };

      $scope._defaultAvailableOperations = null;

      $scope.messages = ihestiaPolicyMessageHelper.getMessages();

      $scope.policyFiles = resolvedPolicyFiles;

      $scope.baseInit = function(resolvedPolicy) {
        $scope.initPolicyData(resolvedPolicy);
        $scope.loadRiskRows();
        $scope.loadAvailableOperations();
      };
      /**
       * Initialize policy model (based on context), policy helper and policy shortcut variables
       */
      $scope.initPolicyData = function(policyResponse) {
        $scope.policyIdProperty = !ihestiaPlatformHelper.isLifeContext() ? 'id' : 'objectVersion';
        $scope.policy = ihestiaPlatformHelper.isLifeContext() ? lsnModelFactory.getObject('Policy', 'v3') : new PolicyModel();
        $scope.policy.setData(policyResponse);
        $scope.policyData = $scope.policy.getData();
        $scope.policyId = $scope.policyData.metaData[$scope.policyIdProperty];
        $scope.policyHelper = $scope.createPolicyHelper($scope.policyData);
        $scope.policyData.premium = $scope.policyHelper.getPolicyPremium();
        $scope.insurer = $scope.policyHelper.getInsurer();
        $scope.mainInsured = $scope.policyHelper.getMainInsured();
        $scope.insurerIsMainInsured = $scope.policyData.holderRef === $scope.policyData.insuredRef;
        // insured
        if ($scope.policy.estates && $scope.policy.estates.length > 1) {
          $scope.insuredPersons = $scope.policyHelper.getAllInsuredSubjects(false, null, true);
        } else {
          $scope.insuredPersons = $scope.policyHelper.getAllInsuredSubjects(false);
        }
      };

      $scope.createPolicyHelper = function(policy) {
        return new PolicyHelper(policy, policy.metaData[$scope.policyIdProperty || 'id']);
      };

      /**
       * Ładowanie tabeli ryzyk
       * @return {undefined}
       */
      $scope.loadRiskRows = function() {
        $scope.riskRows = [];
        $scope.loadStatuses.riskRows = false;
        policiesPrintoutsSvc.get($scope.policyData.number, null, 'insuranceTable').then(function(res) {
          if (res && res.data) {
            $scope.riskRows = res.data;
            $scope.mapRiskTable($scope.riskRows);
          }
        }, lsnNg.noop)
          .finally(function() {
            $scope.loadStatuses.riskRows = true;
            $scope.tryToHideBlockUi();
            $scope._riskTableLifeCycleDeferred.resolve();
          });
      };

      $scope.mapRiskTable = function(riskRows) {
        var skipEstateRisks = $scope.policy.estates && $scope.policy.estates.length > 1;
        if(ihestiaConfigHelper.get('rest', 'AP_PLATFORM') === 'Corpo') {
          // dla corpo nie mamy standardowych podekranów estateView
          skipEstateRisks = false;
        }
        $scope.riskTables = ihestiaRiskTableHelper.retrieveRiskTables(riskRows, {
          skipObjectRisks: skipEstateRisks,
          // divide risk tables between active and historical for all policies in EHL
          divideByEndDate: ihestiaPlatformHelper.isLifeContext(),
          mapMode: RiskTableHelperConfigConstants.POLICY_MODE,
          activeThresholdDate: _.get($scope.policy, 'product.dynamicValues.anniversaryTarifficationDate')
        }, $scope.policy.risks);
      };

      /**
       * sprawdzamy czy już wszystko pobraliśmy
       * @return {boolean} czy schowano blockUi
       */
      $scope.tryToHideBlockUi = function() {
        var canHideBlockUI = true;
        angular.forEach($scope.loadStatuses, function(loadStatus) {
          if (!loadStatus) {
            canHideBlockUI = false;
          }
        });
        if (canHideBlockUI) {
          $scope.$emit('hideBlockUi');
        }

        return canHideBlockUI;
      };

      /**
       * Podgląd polisy na ścieżce
       * @return {undefined}
       */
      $scope.viewPolicyOnPath = function() {
        if (ihestiaPlatformHelper.isLifeContext()) {
          $scope.policy.platform = 'Life'; // this attribute is set to correctly get sale navigation config for EHL (it is provided by backend in payment and policy reports in  Dashboard)
          ihestiaSaleNavigationHelper.openPolicy($scope.policy, undefined, true);
        } else {
          ihestiaSaleNavigationHelper.openFullPolicyInSalesPath($scope.policy);
        }
      };

      $scope.loadAvailableOperations = function() {
        $scope.availableOperations = {};
        $scope.loadStatuses.availableOperations = false;
        policiesSvc.get($scope.policyData.metaData[$scope.policyIdProperty], null, 'availableOperations').then(function(res) {
          if (res && res.data) {
            $scope.availableOperations = res.data;
          }
        }, angular.noop)
          .finally(function() {
            $scope.loadStatuses.availableOperations = true;
            $scope.tryToHideBlockUi();
            $scope._availableOperationsLifeCycleDeferred.resolve();
          });
      };

      /**
       * override this function in context (Life, Property) controller
       * @param code
       */
      $scope.handleOperation = function(code) {
        lsnNg.noop(code);
      };

      $scope.baseHandleOperation = function(code) {
        switch (code) {
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_PRINT:
            $scope.showPrintModal();
            break;
          default:
            $scope.handleOperation(code);
            break;
        }
      };

      /**
       * @return {Promise}
       */
      $scope.riskTablesLoaded = function() {
        return $scope.loadStatuses.riskRows ? $q.when() : $scope._riskTableLifeCycleDeferred.promise;
      };

      $scope.availableOperationsLoaded = function() {
        return $scope.loadStatuses.availableOperations ? $q.when() : $scope._availableOperationsLifeCycleDeferred.promise;
      };

      /**
       * Returns unfiltered list of operations. Now used only by LifeController
       * @returns {PolicyDetailsOperation[]}
       */
      $scope.defaultAvailableOperations = function() {
        if (!$scope._defaultAvailableOperations) {
          $scope._defaultAvailableOperations = [
            {
              icon: 'icon-user-edit-1',
              name: ['policyDetails.beneficiaryChange', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_BENEFICIARY_CHANGE
            },
            {
              icon: 'icon-return',
              name: ['operation.renewProtection', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_RESTORE_VERSION
            },
            {
              icon: 'icon-file-delete',
              name: ['operation.policyTermination', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_POLICY_TERMINATION
            },
            {
              icon: 'icon-file-sync',
              name: ['policyPersonSearchModal.title', {componentCode: 'Public'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_INSURER_CHANGE
            },
            {
              icon: 'icon-printer',
              name: ['operation.print', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_PRINT
            },
            {
              icon: 'icon-file-delete',
              name: ['anniversary.restoreOperation', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_RESTORE_ANNIVERSARY
            },
            {
              icon: 'icon-file-add',
              name: ['anniversary.createOperation', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_CREATE_ANNIVERSARY
            }
          ];
        }
        return $scope._defaultAvailableOperations;
      };

      /**
       * Opens modal with documents to download
       */
      $scope.showPrintModal = function() {
        policyDocumentsModalHelper.policyId = $scope.policyId;
        policyDocumentsModalHelper.policyNumber = $scope.policyData.number;
        ihestiaPolicyDetailsModalsInjectorCfg.policyDocumentsModalSettings.title = $filter('translate')('Public.policyWithNr', {
          componentCode: 'Public',
          params: [$scope.policyData.number]
        });
        iHestiaCommonModalHelper.showModal('ihestiaPolicyDocuments');
      };

      $scope.$on('$destroy', function() {
        ihestiaPolicyMessageHelper.clearMessages();
      });

      $scope.baseInit(resolvedPolicy);
    }]);
