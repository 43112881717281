/**
 * Kontroler navbara
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuTopMenu')
  .controller('ihestiaMenuTopMenuCtrl', ['$scope', '$filter', 'iHestiaJsApiSrv', '$timeout', 'ihestiaSsoBaseInfoHelper', 'ihestiaConfigHelper', '$window', '$state', 'ihestiaAgHelper', 'iHestiaMenuBaseHelper', 'ihestiaCommonErrorHandler', 'UserCharacterTypeConstants', 'labelActionHelper', 'leadingColorModalHelper', 'ihestiaIcContextHelper',
    function($scope, $filter, iHestiaJsApiSrv, $timeout, ihestiaSsoBaseInfoHelper, ihestiaConfigHelper, $window, $state, ihestiaAgHelper, iHestiaMenuBaseHelper, ihestiaCommonErrorHandler, UserCharacterTypeConstants, labelActionHelper, leadingColorModalHelper, ihestiaIcContextHelper) {

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();
      $scope.currentUserCharacterType = $scope.currentUser.getCurrentCharacter().type;
      $scope.publicContext = ihestiaConfigHelper.get('ihsgDefines', 'AG_CONTEXT_SYMBOL_PUBLIC');
      $scope.CI_URL = ihestiaConfigHelper.getUrl('CI_URL');
      $scope.hasPermissionForPaymentReportView = ihestiaAgHelper.hasPermissionFor('Payments', 'PaymentsView'); //dostęp do raportu płatności
      $scope.isLife = ihestiaConfigHelper.get('ic', 'activeContext') === 'EHL';


      // for this url's we check if we can go there in 'availability' object
      $scope.goToUrlAdditionalCheck = {
        'E1_URL': 'E1',
        'E7_URL': 'E7',
        'MF_URL': 'MF'
      };


      var saleItems = [{
        text: $filter('translate')('Public.saleProperty'),
        submenu: true,
        urlCode: 'PORTAL_URL',
        urlPostfix: 'external/propertyInsurance',
        agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_PORTAL'),
        submenuTpl: 'ihestia-menu-template-app/topMenu/menuSaleProperty.tpl.html',
        submenuVisible: false,
        disabled: false
      }, {
        text: 'Ubezpieczenie na życie',
        submenu: false,
        submenuVisible: false,
        urlCode: 'PORTAL_URL',
        urlPostfix: 'external/lifeInsurance',
        agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_PORTAL'),
        disabled: !ihestiaAgHelper.canStartAnyLifeSalePath()
      }];

      $scope.reloadMainMenuOption = function() {
        $scope.iHestiaMenuOpt = {
          centerNavBar: {
            items: [{
              code: 'sale',
              name: $filter('translate')('Public.sale'),
              active: false,
              items: saleItems
            }, {
              code: 'service',
              name: $filter('translate')('Public.menuService'),
              active: false,
              items: [{
                text: $filter('translate')('Public.menuServiceClients'),
                submenu: false,
                urlCode: 'IKONTO_URL',
                permissionCode: 'CLIENT', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CLIENT'),
                disabled: true
              }, {
                text: $filter('translate')('Public.menuServiceAgents'),
                submenu: true,
                submenuVisible: false,
                urlCode: 'AGENT_URL',
                permissionCode: 'AGENT', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_AGENT'),
                // disabledForCharacters: [UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL, UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER],
                submenuTpl: 'ihestia-menu-template-app/topMenu/ihestiaMenuAgentReports/ihestiaMenuAgentReports.tpl.html',
                disabled: true

              }, {
                text: $filter('translate')('Public.menuServicePartners'),
                urlPostfix: '',
                submenu: false,
                urlCode: 'PARTNER_URL',
                permissionCode: 'PARTNER', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_PARTNER'),
                disabled: true
              }]
            }, {
              code: 'informationCenter',
              name: $filter('translate')('Public.informationCenter'),
              active: false,
              items: [{
                text: $filter('translate')('Public.mainSite'),
                urlPostfix: 'external/main_page',
                submenu: false,
                urlCode: 'CI_URL',
                permissionCode: 'CI', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CI'),
                disabled: true
              }, {
                text: $filter('translate')('Public.news'),
                urlCode: 'CI_URL',
                urlPostfix: 'external/news',
                submenu: true,
                submenuTpl: 'ihestia-menu-template-app/topMenu/menuCINews.tpl.html',
                submenuSrvSubmenu: 'news',
                submenuData: {},
                permissionCode: 'CI', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CI'),
                disabled: true
              }, {
                text: $filter('translate')('Public.library'),
                urlCode: 'CI_URL',
                urlPostfix: 'external/library',
                submenu: true,
                submenuTpl: 'ihestia-menu-template-app/topMenu/menuCILibrary.tpl.html',
                submenuSrvSubmenu: 'library',
                submenuData: {},
                permissionCode: 'CI', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CI'),
                disabled: true
              }, {
                text: $filter('translate')('Public.agentCenter'),
                urlCode: 'CI_URL',
                urlPostfix: 'external/agent_center',
                submenu: true,
                submenuTpl: 'ihestia-menu-template-app/topMenu/menuCIAgentCenter.tpl.html',
                submenuSrvSubmenu: 'agent_center',
                submenuData: {},
                permissionCode: 'CI', // do późniejszej refaktoryzacji
                agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_CI'),
                disabled: true
              }]
            }]
          }
        };
      };


      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.reloadMainMenuOption();
        $scope.addAdminPages();
        $scope.refreshPermissions();
        $scope.$on('componentStatusChanged', function() {
          $scope.refreshPermissions();
        });

        labelActionHelper.registerAction('topMenu', $scope.onChangeLang);
      };

      $scope.onChangeLang = function() {
        $scope.reloadMainMenuOption();
        $scope.addAdminPages();
        $scope.refreshPermissions();
        $scope.$on('componentStatusChanged', function() {
          $scope.refreshPermissions();
        });
      };

      /**
       * Kliknięto element menu
       * @param  {[type]} item   [description]
       * @param  {[type]} $event [description]
       * @return {[type]}        [description]
       */
      $scope.submenuItemClick = function(item, $event) {
        if ($event.target === $event.currentTarget && !item.disabled) {
          if (angular.isUndefined(item.agSymbol) || ihestiaAgHelper.hasPermissionFor($scope.publicContext, item.agSymbol)) {
            if (item.urlCode) {
              $window.location = ihestiaConfigHelper.getUrl(item.urlCode, item.urlPostfix);
            } else if (item.leadingColor) {
              leadingColorModalHelper.showModal().then(lsnNg.noop, lsnNg.noop);
            }

            $scope.clickOutsideMenu();
          }
        }
      };

      $scope.addAdminPages = function() {
        var administration = {
          code: 'administration',
          name: $filter('translate')('Public.administration'),
          active: false,
          disabled: false,
          items: []
        };
        /** dynamiczna taryfa - to nie nasze! */
        // dla każdego z elementów submenu dynamicznej taryfy srpawdzamy osobno uprawnienia
        // gdy ich nie posiada element jest "wygaszony" [BOSJUP-487]
        if (ihestiaAgHelper.hasPermissionFor('Public', 'AppDynamicTarriff')) {
          if (ihestiaAgHelper.hasPermissionFor('Public', 'DynamicTarriffC')) {
            administration.items.push({
              text: $filter('translate')('dynamicTariffSegmentC', {
                componentCode: 'Public'
              }),
              urlCode: 'PORTAL_URL',
              urlPostfix: 'dynamictariff/#/C/',
              disabled: false
            });
          }

          if (ihestiaAgHelper.hasPermissionFor('Public', 'DynamicTarriffI')) {
            administration.items.push({
              text: $filter('translate')('dynamicTariffSegmentI', {
                componentCode: 'Public'
              }),
              urlCode: 'PORTAL_URL',
              urlPostfix: 'dynamictariff/#/I/',
              disabled: false
            });
          }

          if (ihestiaAgHelper.hasPermissionFor('Public', 'DynamicTarriffM')) {
            administration.items.push({
              text: $filter('translate')('dynamicTariffSegmentM', {
                componentCode: 'Public'
              }),
              urlCode: 'PORTAL_URL',
              urlPostfix: 'dynamictariff/#/M/',
              disabled: false
            });
          }

          if (ihestiaAgHelper.hasPermissionFor('Public', 'DynamicTarriffGeneralAgreement')) {
            administration.items.push({
              text: $filter('translate')('dynamicTariffUG', {
                componentCode: 'Public'
              }),
              urlCode: 'PORTAL_URL',
              urlPostfix: 'dynamictariff/#/generalAgreements/list',
              disabled: false
            });
          }
        }

        if (ihestiaAgHelper.hasPermissionFor('iSzkody', 'DictionariesManagement')) {

          administration.items.push({
            text: 'Zgł. szkody - słowniki',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/claimDictionaries',
            submenu: false
          });

          administration.items.push({
            text: 'Zgł. szkody - szablony emaili',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/claimEmails',
            submenu: false
          });

          administration.items.push({
            text: 'Zgł. szkody - konfiguracja emaili',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/claimEmailsConfig',
            submenu: false
          });

          administration.items.push({
            text: 'Zgł. roszczenia - słowniki',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/lifeClaimDictionaries',
            submenu: false
          });
        }

        //sprawdzamy każdy atom i wyświetlamy tylko elementy do których mamy dostęp //potwierdzone przez Arkadiusza Balcerzaka 03.08.2015
        if ($scope.currentUserCharacterType === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL || $scope.currentUserCharacterType === UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER) {
          // dashboard (zadania)
          administration.items.push({
            permissionCode: 'DASHBOARD',
            urlCode: 'DASHBOARD_URL',
            agSymbol: ihestiaConfigHelper.get('ihsgDefines', 'AG_SYMBOL_DASHBOARD'),
            text: $filter('translate')('tasks', {
              componentCode: 'Public'
            }),
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });

          administration.items.push({
            // permissionCode: 'LEADING_COLOR',
            leadingColor: true,
            text: $filter('translate')('appearanceManagementJupiter', {
              componentCode: 'Portal'
            }),
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });


          // skoro mamy dostęp do zadań, to sprawdzamy, czy możemy nim zarządzać
          if (ihestiaAgHelper.hasPermissionFor('Dashboard', 'PoolAdministration') || ihestiaAgHelper.hasPermissionFor('Dashboard', 'TaskAdministration')) {
            administration.items.push({
              permissionCode: 'DASHBOARD',
              urlCode: 'DASHBOARD_URL',
              urlPostfix: 'external/taskManagement/',
              text: $filter('translate')('taskManagement', {
                componentCode: 'Menu'
              }),
              dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
              submenu: false
            });
          }
        }

        var pzaRoles = [
          UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL,
          UserCharacterTypeConstants.CHARACTER_TYPE_PRO_CONTACT_WORKER,
          UserCharacterTypeConstants.CHARACTER_TYPE_BRANCH,
          UserCharacterTypeConstants.CHARACTER_TYPE_MANAGER
        ];
        if (pzaRoles.indexOf($scope.currentUserCharacterType) > -1 && ihestiaConfigHelper.get('external', 'PZA_URL') && ihestiaAgHelper.hasPermissionFor('PZA', 'PZAManager')) {
          administration.items.push({
            text: $filter('translate')('agencyDiscounts', {
              componentCode: 'Public'
            }),
            urlCode: 'PZA_URL',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'AppAdminPanelPwdPartner')) {
          administration.items.push({
            text: $filter('translate')('pwd', {
              componentCode: 'Menu'
            }),
            urlCode: 'PWD_URL',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'LabelsMngmt') && ihestiaAgHelper.hasPermissionFor('Administration', 'FullAccess')) {
          administration.items.push({
            text: $filter('translate')('Public.labels'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/labels',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'BannersMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.banners'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/banners',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }

        if (ihestiaAgHelper.hasPermissionFor('Portal', 'ComponentMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.componentsList'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/components',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'MessageMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.adminInfo'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/adminInfo',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'CacheMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.cacheTitle'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/cache',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'AdwordsMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.adwords'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/ftsAd',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Portal', 'PlatformStatus')) {
          administration.items.push({
            text: $filter('translate')('Public.currentInfoTitle'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/adminPanel/currentInfo',
            dataTemplate: 'ihestia-commondirectives-template-app/directives/popover/popover.tpl.html',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'Relog') || ihestiaAgHelper.hasPermissionFor('Public', 'UsersMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.users'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/users',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'OAuthManagmentService')) {
          administration.items.push({
            text: 'Dodawanie partnera',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/partner/add',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'ImportUsers')) {
          administration.items.push({
            text: $filter('translate')('Public.userImports'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/userImports',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'JobsMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.asyncExports'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/asynchronousReports',
            submenu: false
          });
        }

        if (ihestiaAgHelper.hasPermissionFor('Public', 'CustomersMngmt')) {
          administration.items.push({
            text: $filter('translate')('Public.accountsManagement'),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/accounts',
            submenu: false
          });
        }

        if (ihestiaAgHelper.hasPermissionFor('InformationCenter', 'TutorialContentEdit')) {
          administration.items.push({
            text: $filter('translate')('titleEditiHestiaHelp', {
              componentCode: 'Public'
            }),
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/helpList',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'AppNbkManagement')) {
          administration.items.push({
            text: $filter('translate')('app_nbk', {
              componentCode: 'Public'
            }),
            urlCode: 'NBK_URL',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'CommissionExceptionModuleView')) {
          administration.items.push({
            text: 'Wyjątki prowizyjne',
            urlCode: 'PORTAL_URL',
            urlPostfix: 'external/commissionExceptionModule',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'DirectAdministration')) {
          administration.items.push({
            text: $filter('translate')('contactOrder', {
              componentCode: 'Public'
            }),
            urlCode: 'CCC_URL',
            submenu: false
          });
        }
        if (ihestiaAgHelper.hasPermissionFor('Public', 'AppKid')) {
          administration.items.push({
            text: 'Idd kluczowe inf. o produkcie',
            urlCode: 'KID_URL',
            submenu: false
          });
        }

        if (ihestiaAgHelper.hasPermissionFor('Public', 'ImportQuestionnaire')) {
          administration.items.push({
            text: 'Import definicji ankiety',
            urlCode: 'QUESTIONNAIRE_URL',
            urlPostfix: 'questionnaires/import',
            submenu: false
          });
        }

        if (administration.items.length > 0) {
          $scope.iHestiaMenuOpt.centerNavBar.items.push(administration);
        }
      };


      /**
       * ustawienia widoczności elementów menu na podstawie uprawnień i statusów komponentów
       */
      $scope.refreshPermissions = function() {
        iHestiaMenuBaseHelper.refreshItemsPermissions($scope.iHestiaMenuOpt.centerNavBar.items);
      };

      $scope.showSubmenu = function(item) {
        if (!item.submenuVisible) {
          item.submenuVisible = true;

          if (typeof item.submenuSrvSubmenu !== 'undefined' && $scope.availability.CI) {
            iHestiaJsApiSrv.get('', null, item.submenuSrvSubmenu, function(response) { //strzał do CI
              $timeout(function() {
                if (response && response.data) {
                  item.submenuData = response.data;
                }
              }, 0);
            });
          }
        }
      };

      $scope.hideSubmenu = function(item) {
        item.submenuVisible = false;
      };

      $scope.menuItemClick = function(item) {
        angular.forEach($scope.iHestiaMenuOpt.centerNavBar.items, function(value) {
          if (item.code === value.code) {
            value.active = true;
          } else {
            value.active = false;
          }
        });
      };

      /**
       * Przejście do raportu w iagencies
       * @param  {String} report policies|offers|patrons
       * @return {[type]}        [description]
       */
      $scope.goToReport = function(report) {
        var url = ihestiaConfigHelper.getUrl('AGENT_URL', 'external/' + report);
        $window.open(url, '_self');
        $scope.clickOutsideMenu();
      };


      $scope.clickOutsideMenu = function() {
        angular.forEach($scope.iHestiaMenuOpt.centerNavBar.items, function(value) {
          if (!value.disabled) {
            value.active = false;
          }
        });
      };

      /**
       * Przejście pod zadany Url
       * @param  {string} urlCode    kod z external_url
       * @param  {string} urlPostfix wartość do doklejenia po prawej stronie
       * @param {string} target domyślnie _blank
       * @return {undefined}
       */
      $scope.goToUrl = function(urlCode, urlPostfix, target) {
        var canGo = true;
        if (angular.isDefined($scope.goToUrlAdditionalCheck[urlCode])) {
          canGo = $scope.availability[$scope.goToUrlAdditionalCheck[urlCode]];
        }

        if (canGo === true) {
          target = target ? target : '_blank';
          $window.open(ihestiaConfigHelper.getUrl(urlCode, urlPostfix), target);
          $scope.clickOutsideMenu();
        }
      };


      $scope.init();

    }
  ]);
