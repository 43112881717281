//pseudosłownik, bo zwrotny content jest stały, ale zależny od parametrów
//dla elektronicznych dokumentów parametry są stałe, więc dla GUI jest to słownik

angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryPolicyTypesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryPolicyTypesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.businessArea = 'documents';
        this.resource = 'policyTypes';
      };
      return new RestDictionaryPolicyTypesSvc();
    }
  ]);