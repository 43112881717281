angular.module('ihestiaCommonDirectives.baseDownloadHelper')
  .service('ihestiaBaseDownloadHelper', ['filesSvc', 'fileServerSvc',
    function(filesSvc, fileServerSvc) {
      var BaseDownloadHelper = function() {

        /**
         * Ściągnięcie pliku po id z usługi postToken 
         * @param  {String} tokenId          (np. 'd29639a0-60e2-44f4-9867-939e05037bd3')
         * @param  {String} defaultName      domyślna nazwa pliku (jesli nie będzie jej w usłudze)
         * @param  {Boolean} forceDefaultName czy wykorzystać zawsze defaultName
         * @return {Promise} promise z fileServerSvc
         */
        this.downloadFileByTokenId = function(tokenId) {
          
          var params = {
            responseType: 'blob',
            allowedStatuses: [404, 403, 410]
          };

          return fileServerSvc.get(tokenId, null, '', null, null, params);
        };
      };

      return new BaseDownloadHelper();
    }
  ]);