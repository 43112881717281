angular.module('ihestiaWidgets.policyTermination')
  .controller('policyTerminationStep2AbstractCtrl', ['$scope', 'ihestiaDictionaryHelper', 'PolicyTerminationConstants', 'fileServerSvc', '$filter', '$alert', 'resolvedApplication', 'ihestiaSsoBaseInfoHelper', 'labelActionHelper', 'resolvedParams', '$location',
    function($scope, ihestiaDictionaryHelper, PolicyTerminationConstants, fileServerSvc, $filter, $alert, resolvedApplication, ihestiaSsoBaseInfoHelper, labelActionHelper, resolvedParams, $location) {

      if(resolvedApplication === null) //odświeżenie na 2 kroku rozwiązania rozszerzenie polisy (rozszerzenie polisy nie ma zapisanego application)
      {
        $location.url('/');
        return;
      }

      $scope.terminationData = resolvedApplication.data;
      if(!$scope.terminationData.files)
      {
        $scope.terminationData.files = [];
      }
      $scope.viewParams = resolvedParams;

      //uspójniamy, bo usługa miesza w wielkościach liter
      $scope.terminationData.terminationType = $filter('lcFirst')($scope.terminationData.terminationType);
      $scope.terminationData.terminationCause = $filter('lcFirst')($scope.terminationData.terminationCause);

      $scope.TYPES = PolicyTerminationConstants.TYPES;

      $scope.shortagesMap = {};

      $scope.shortagesMap[$scope.TYPES.WITHDRAWAL] = {};
      $scope.shortagesMap[$scope.TYPES.WITHDRAWAL].withdrawalAccordingToGtc = 'AgreementTerminationAccordingToGtcShortages';
      $scope.shortagesMap[$scope.TYPES.WITHDRAWAL].distanceAgreementWithdrawal = 'DistanceAgreementTerminationShortages';

      $scope.shortagesMap[$scope.TYPES.NOTICE] = {};
      $scope.shortagesMap[$scope.TYPES.NOTICE].vehicleBuyerNotice = 'VehicleBuyerWithdrawalShortages';
      $scope.shortagesMap[$scope.TYPES.NOTICE].prolongationPolicyNotice = 'ProlongationAgreementWithdrawalShortages';
      $scope.shortagesMap[$scope.TYPES.NOTICE].endOfInsurancePeriodNotice = 'AgreementWithdrawalWithEndOfPeriodShortages';

      $scope.shortagesMap[$scope.TYPES.DAD] = {};
      $scope.shortagesMap[$scope.TYPES.DAD].vehicleDismantling = 'VehicleDeregistrationAndDismantlingShortages';
      $scope.shortagesMap[$scope.TYPES.DAD].vehicleDeregistration = 'VehicleDeregistrationAndDismantlingShortages';

      $scope.shortagesMap[$scope.TYPES.ESTATE] = 'RealEstateSaleShortages';

      $scope.currentUser = ihestiaSsoBaseInfoHelper.getUser();

      $scope.multiselectLocalLang = {
        selectAll: $filter('translate')('Public.multiselectSelectAll'),
        selectNone: $filter('translate')('Public.multiselectSelectNone'),
        reset: $filter('translate')('Public.multiselectReset'),
        search: $filter('translate')('Public.multiselectSearch'),
        nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
        noOption: $filter('translate')('Public.multiselectNoOption')
      };

      //uploader
      $scope.uploaderName = 'policyTermination';

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderName,
        hideQueueList: true
      };

      $scope.refreshLabels = function()
      {
        $scope.multiselectLocalLang = {
          selectAll: $filter('translate')('Public.multiselectSelectAll'),
          selectNone: $filter('translate')('Public.multiselectSelectNone'),
          reset: $filter('translate')('Public.multiselectReset'),
          search: $filter('translate')('Public.multiselectSearch'),
          nothingSelected: $filter('translate')('Public.multiselectNothingSelected'),
          noOption: $filter('translate')('Public.multiselectNoOption')
        };
      };

      labelActionHelper.registerAction('policyTermination', $scope.refreshLabels);

      $scope.getShortageCode = function()
      {
        if($scope.terminationData.terminationCause === 'vehicleSale')
        {
          return 'VehicleSaleShortages';
        }
        else if($scope.terminationData.terminationCause === 'vehicleDonation')
        {
          return 'VehicleDonationShortages';
        }
        else
        {
          if($scope.terminationData.terminationType === $scope.TYPES.ESTATE)
          {
            return $scope.shortagesMap[$scope.terminationData.terminationType];
          }
          else if($scope.shortagesMap[$scope.terminationData.terminationType] && $scope.shortagesMap[$scope.terminationData.terminationType][$scope.terminationData.terminationCause])
          {
            return $scope.shortagesMap[$scope.terminationData.terminationType][$scope.terminationData.terminationCause];
          }
          else
          {
            return null;
          }
        }
      };

      //słowniki dla centrali
      $scope.suspensionsDict = [];
      $scope.shortagesDict = [];
      if($scope.currentUser.character.type === 'Central')
      {
        ihestiaDictionaryHelper.getDict('policyOperationSuspensions', {
          businessArea: 'dictionaries',
          resource: 'policyOperationSuspensions',
          callback: function(dictionary){
            $scope.suspensionsDict = dictionary;
          }
        });
        ihestiaDictionaryHelper.getDict('policyOperationShortages', {
          businessArea: 'dictionaries',
          resource: 'policyOperationShortages',
          callback: function(dictionary){
            var shortageCode = $scope.getShortageCode();
            angular.forEach(dictionary, function(shortagesList){
              //po || to wymczasowe obejście póki kodu nie zmienią
              if(shortagesList.code === shortageCode || (shortageCode === 'VehicleDeregistrationAndDismantlingShortages' && shortagesList.code === 'VehicleDeregistrationShortages'))
              {
                $scope.shortagesDict = shortagesList.childDictionaries;
              }
            });
          }
        });
        if($scope.terminationData.shortages && angular.isArray($scope.terminationData.shortages))
        {
          angular.forEach($scope.shortagesDict, function(shortage){
            if($scope.terminationData.shortages.indexOf(shortage.code) !== -1)
            {
              shortage.selected = true;
            }
          });
        }
        if($scope.terminationData.suspensions && angular.isArray($scope.terminationData.suspensions))
        {
          angular.forEach($scope.suspensionsDict, function(suspension){
            if($scope.terminationData.suspensions.indexOf(suspension.code) !== -1)
            {
              suspension.selected = true;
            }
          });
        }
      }

    }
  ]);