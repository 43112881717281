/**
 * Zamienia znaki nowej linii \n\r na <br />
 */
angular.module('ihestiaCommonDirectives')
  .directive('lsnChatPopup', ['$document', '$parse', 'ihestiaCommonConfirmModalHelper', 'lsnChatPopupHelper',
    function($document, $parse, ihestiaCommonConfirmModalHelper, lsnChatPopupHelper) {
      'use strict';
      return {
        restrict: 'E',
        replace: true,
        scope: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/chatPopup/chatPopup.tpl.html',
        compile: function() {
          return function($scope, element, attr) {

            var
              container = angular.element(element[0].querySelector('[data-popup-container]')),
              header = angular.element(element[0].querySelector('[data-popup-head]')),
              anchorRight = angular.element(element[0].querySelector('[data-popup-anchor-right]')),
              anchorBottom = angular.element(element[0].querySelector('[data-popup-anchor-bottom]')),
              anchorLeft = angular.element(element[0].querySelector('[data-popup-anchor-left]')),
              startX = 0,
              startY = 0,
              x = 0,
              y = 0;

            var defaultSettings = {
              title: '',
              show: true,
              additionButton: null,
              allowResize: false,
              dynamicData: { //obiekt który modyfikujemy w trakcie działania, przy inicie trzeba go przekazać jako dowiązanie
                disableClose: false,
                additionalTitleText: null
              },
              minWidth: 320,
              minHeight: 506,
              confirmClose: true,
              onClose: null,
              searchFreeSpace: false, //czy przy pokazaniu szukać pustego miejsca dla popupu
              bodyTemplate: 'ihestia-commondirectives-template-app/directives/chatPopup/defaultChatPopupContent.tpl.html'
            };

            $scope.baseSettings = {}; //tu wrucimy dowiązanie do obiektu rodzica

            $scope.currentStateData = {
              width: null,
              height: null,
              resizeStartX: null,
              resizeStartY: null
            };

            $scope.hide = function() {
              if($scope.settings.dynamicData && $scope.settings.dynamicData.disableClose)
              {
                return false;
              }

              $scope.confirmModalOpen = true;
              if ($scope.settings.confirmClose) {
                ihestiaCommonConfirmModalHelper.showConfirmModal({
                  title: 'Komunikat',
                  okBtnName: 'Potwierdź',
                  cancelBtnName: 'Anuluj',
                  text: ($scope.settings.closeText ? $scope.settings.closeText : 'Czy na pewno chcesz zamknąć okno?'),
                  size: 'sm',
                  showOkSpinner: true,
                  okBtnCallback: function() {
                    $scope.confirmModalOpen = false;
                    $scope.baseSettings.show = false; //przestawiamy flagę w nadrzędnych settingsach żeby dało się ponownie popup pokazać
                    if (angular.isFunction($scope.settings.onClose)) {
                      $scope.settings.onClose();
                    }
                  },
                  cancelBtnCallback: function(){
                    $scope.confirmModalOpen = false;
                  }
                });
              } else {
                $scope.baseSettings.show = false; //przestawiamy flagę w nadrzędnych settingsach żeby dało się ponownie popup pokazać
                if (angular.isFunction($scope.settings.onClose)) {
                  $scope.settings.onClose();
                }
              }
              return true;
            };

            $scope.init = function() {
              $scope.setObservers();
              $scope.rebuildSettings();

              if ($scope.settings.show) {
                $scope.popupShowed();
              }

              lsnChatPopupHelper.activeChatList.push($scope.baseSettings);
            };

            $scope.$on('ihestiaCrossTab:message', function(event, data) {
              if (data.code === 'newChatWindowOpened' && $scope.confirmModalOpen) {
                ihestiaCommonConfirmModalHelper.hideConfirmModal();
              }
            });

            $scope.pushPositionToParent = function() {
              $scope.baseSettings.x = x;
              $scope.baseSettings.y = y;
            };

            /**
             * szukamy w oknie miejsca gdzie nie ma jeszcze popupu
             */
            $scope.searchFreeSpaceForPopup = function() {
              //początek jest w prawym górnym rogu

              // 320 x 358 - standardowy rozmiar popupu
              // damy 180px odstępu w pionie żeby trochę wystawało i rozdzielność w poziomie

              //max wysokość ekranu 815

              //zbieramy listę widocznych popupów
              var visibleRectangles = [];
              angular.forEach(lsnChatPopupHelper.activeChatList, function(chat) {
                if (chat.show && !chat.minimize) {
                  visibleRectangles.push({
                    x: chat.x,
                    y: chat.y
                  });
                }
              });


              var maxWidth = 1280;
              var maxHeight = 815;

              var widthStep = 330;
              var heightStep = 180;

              var newRectangle = {
                x: $scope.settings.right,
                y: $scope.settings.top
              };
              var permissibility = 0.5;

              var collide = $scope.doRectanglesCollide(newRectangle, lsnChatPopupHelper.activeChatList, permissibility);
              var maxIterations = 100; //na wszelki wypadek

              while (newRectangle.x < maxWidth && collide.permissibilityCollide && maxIterations > 0) {
                maxIterations = maxIterations - 1;
                while (newRectangle.y < maxHeight && collide.permissibilityCollide && maxIterations > 0) {
                  maxIterations = maxIterations - 1;
                  collide = $scope.doRectanglesCollide(newRectangle, lsnChatPopupHelper.activeChatList, permissibility);
                  if (collide.permissibilityCollide) {
                    newRectangle.y = newRectangle.y + heightStep; //przesuwamy okno w dół i sprawdzamy w kolejnej iteracji
                  }
                }
                newRectangle.x = newRectangle.x + widthStep; //przesuwamy krok w lewo
                newRectangle.y = $scope.settings.top; //zerujemy wysokość
              }

              if (!collide.permissibilityCollide && collide.absoluteCollide) {
                //częściowo nachodzi ale w granicach tolerancji, więc przesuwamy trochę w lewo żeby ładniej wyglądało
                newRectangle.x = newRectangle.x + 10;
              } else if (collide.permissibilityCollide) //nie udało nam się znaleźć miejsca, więc zostawiamy domyślne
              {
                newRectangle.x = $scope.settings.right;
                newRectangle.y = $scope.settings.top;
              }

              //w pozostałym przypadku mamy dobre współrzędne, więc już nic nie zmieniamy
              return newRectangle;
            };

            /**
             * [doRectanglesCollide description]
             * @param  {[type]} rectangleOne   [description]
             * @param  {[type]} rectangleTwo   [description]
             * @param  {float} permissibility dopuszczalne nachodzenie się wartość float 0-1 (1 - muszą być rozdzielne, 0.5 - mogą w połowie nachodzić na siebie)
             * @return {object}
             */
            $scope.doRectanglesCollide = function(rectangleToCheck, otherRectangles, permissibility) {
              if (!permissibility) {
                permissibility = 1;
              }
              var collide = {
                permissibilityCollide: false, //czy jest dopuszczalna rozdzielność
                absoluteCollide: false //czy jest zupełnie rozdzielne
              };

              angular.forEach(otherRectangles, function(otherRectangle) {
                if ($scope.doRectangleCollide(rectangleToCheck, otherRectangle, permissibility)) {
                  collide.permissibilityCollide = true;
                }
                if ($scope.doRectangleCollide(rectangleToCheck, otherRectangle, 1)) {
                  collide.absoluteCollide = true;
                }
              });
              return collide;
            };

            $scope.doRectangleCollide = function(rectangleToCheck, otherRectangle, permissibility) {
              var standardWidth = 320;
              var standardHeight = 358;

              var xCollide = false;
              var yCollide = false;
              if (Math.abs(otherRectangle.x - rectangleToCheck.x) <= (standardWidth * permissibility)) {
                xCollide = true;
              }
              if (Math.abs(otherRectangle.y - rectangleToCheck.y) <= (standardHeight * permissibility)) {
                yCollide = true;
              }
              return xCollide && yCollide; //jeśli obie współrzędne są kolizyjne to znaczy, że prostokąty na siebie nachodzą
            };

            /**
             * popup się pokazał
             */
            $scope.popupShowed = function() {
              if ($scope.settings.searchFreeSpace) {
                var coordinates = $scope.searchFreeSpaceForPopup();
                y = coordinates.y;
                x = coordinates.x;
              } else {
                y = $scope.settings.top;
                x = $(window).width() - $scope.settings.right - $scope.settings.minWidth;
              }

              $scope.refreshPosition();
              $scope.settings.active = true;
            };

            $scope.maximazeWindow = function() {
              if($scope.minimize)
              {
                $scope.minimize = false;
                $scope.refreshPosition();
              }
            };

            //minimalizacja
            $scope.toogleWindow = function() {
              $scope.minimize = !$scope.minimize;
              $scope.refreshPosition();
            };

            $scope.pushPositionToHelper = function() {
              $scope.baseSettings.x = x;
              $scope.baseSettings.y = y;
            };

            $scope.getLeftPositionFromRight = function(rightOffset)
            {
              var leftPosition = $(window).width() - rightOffset - container.width();
              return leftPosition;
            };

            $scope.refreshPosition = function() {
              $scope.pushPositionToHelper();
              if ($scope.minimize) {
                container.css({
                  top: '',
                  right: '',
                  left: '',
                  height: '',
                  width: '',
                  bottom: ''
                });
              } else {
                if($scope.settings.allowResize)
                {
                  container.css({
                    top: y + 'px',
                    left: x + 'px',
                    width: $scope.currentStateData.width,
                    height: $scope.currentStateData.height,
                    right: 'auto',
                    bottom: 'auto'
                  });
                }
                else
                {
                  container.css({
                    top: y + 'px',
                    left: x + 'px',
                    right: 'auto',
                    bottom: 'auto'
                  });
                }
              }
            };

            $scope.setObservers = function() {
              $scope.$watch(attr.drvSettings, function(settings, oldSettings) {
                if (settings) {
                  // todo uniezależnić od zmian x i y, bo teraz wszystko wywołuje to
                  $scope.rebuildSettings();
                  if (settings.show === true && oldSettings.show === false) {
                    $scope.popupShowed();
                  }
                }
              }, true);
            };

            /**
             * Funkcja do przebudowy ustawień
             * @param  {[type]} $attrs [description]
             * @return {[type]}        [description]
             */
            $scope.rebuildSettings = function() {
              // zmiany ustawień
              if (attr.drvSettings) {
                $scope.baseSettings = $parse(attr.drvSettings)($scope);
                $scope.settings = angular.extend({}, defaultSettings, $scope.baseSettings);
              } else {
                $scope.settings = angular.extend({}, defaultSettings);
              }
              if (angular.isUndefined($scope.settings.minimizeTitle)) {
                $scope.settings.minimizeTitle = $scope.settings.title;
              }

              if(!$scope.currentStateData.width)
              {
                $scope.currentStateData.width = $scope.settings.minWidth;
              }
              if(!$scope.currentStateData.height)
              {
                $scope.currentStateData.height = $scope.settings.minHeight;
              }
            };


            /**
             * move - START
             */

            header.on('mousedown', function(event) {
              // Prevent default dragging of selected content
              event.preventDefault();

              if ($scope.minimize) {
                return;
              }

              $scope.settings.active = true;

              // startY i startX to przesunięcie kursora względem lewego górnego rogu popupa w momencie rozpoczęcia przeciągania
              startY = event.pageY - y;
              startX = event.pageX - x;

              $document.on('mousemove', $scope.onMouseMove);
              $document.on('mouseup', $scope.onMouseUp);
            });

            $scope.onMouseMove = function(event) {
              y = event.pageY - startY;
              x = event.pageX - startX;

              $scope.pushPositionToHelper();

              container.css({
                top: y + 'px',
                left: x + 'px'
              });
            };

            $scope.onMouseUp = function() {
              $document.off('mousemove', $scope.onMouseMove);
              $document.off('mouseup', $scope.onMouseUp);
            };

            /**
             * move - END
             */


             /**
             * resize bottom - START
             */
            $scope.onMouseMoveResizeBottom = function(event) {
              var newHeight = $scope.currentStateData.resizeStartHeight + event.pageY - $scope.currentStateData.resizeStartY;
              if(newHeight < $scope.settings.minHeight)
              {
                newHeight = $scope.settings.minHeight;
              }

              container.css({
                height: newHeight + 'px'
              });
              $scope.currentStateData.height = newHeight;
            };

            $scope.onMouseUpResizeBottom = function() {
              $document.off('mousemove', $scope.onMouseMoveResizeBottom);
              $document.off('mouseup', $scope.onMouseUpResizeBottom);
            };

            anchorBottom.on('mousedown', function(event) {
              // Prevent default dragging of selected content
              event.preventDefault();

              if ($scope.minimize) {
                return;
              }

              $scope.currentStateData.resizeStartY = event.pageY;
              $scope.currentStateData.resizeStartHeight = $scope.currentStateData.height;
              $scope.currentStateData.resizeStartPopupY = y;

              $document.on('mousemove', $scope.onMouseMoveResizeBottom);
              $document.on('mouseup', $scope.onMouseUpResizeBottom);
            });

            /**
             * resize left - END
             */


            /**
             * resize left - START
             */
            $scope.onMouseMoveResizeLeft = function(event) {
              var newHeight = $scope.currentStateData.resizeStartHeight + event.pageY - $scope.currentStateData.resizeStartY;
              var newWidth = $scope.currentStateData.resizeStartWidth + $scope.currentStateData.resizeStartX - event.pageX;
              var newX = $scope.currentStateData.resizeStartPopupX - $scope.currentStateData.resizeStartX + event.pageX;
              if(newHeight < $scope.settings.minHeight)
              {
                newHeight = $scope.settings.minHeight;
              }
              if(newWidth < $scope.settings.minWidth)
              {
                newWidth = $scope.settings.minWidth;
              }
              if(newX > $scope.currentStateData.resizeMaxLeft)
              {
                newX = $scope.currentStateData.resizeMaxLeft;
              }
              x = newX;

              $scope.pushPositionToHelper();

              container.css({
                height: newHeight + 'px',
                width: newWidth + 'px',
                left: x + 'px'
              });
              $scope.currentStateData.height = newHeight;
              $scope.currentStateData.width = newWidth;
              $scope.currentStateData.left = x;
            };

            $scope.onMouseUpResizeLeft = function() {
              $document.off('mousemove', $scope.onMouseMoveResizeLeft);
              $document.off('mouseup', $scope.onMouseUpResizeLeft);
            };

            anchorLeft.on('mousedown', function(event) {
              // Prevent default dragging of selected content
              event.preventDefault();

              if ($scope.minimize) {
                return;
              }

              startX = event.pageX - x;

              // startY i startX to miejsce skąd rozpoczęliśmy resize
              $scope.currentStateData.resizeStartY = event.pageY;
              $scope.currentStateData.resizeStartX = event.pageX;
              $scope.currentStateData.resizeStartHeight = $scope.currentStateData.height;
              $scope.currentStateData.resizeStartWidth = $scope.currentStateData.width;
              $scope.currentStateData.resizeStartPopupY = y;
              $scope.currentStateData.resizeStartPopupX = x;
              $scope.currentStateData.resizeMaxLeft = $scope.currentStateData.resizeStartX + $scope.currentStateData.resizeStartWidth - $scope.settings.minWidth;

              $document.on('mousemove', $scope.onMouseMoveResizeLeft);
              $document.on('mouseup', $scope.onMouseUpResizeLeft);
            });

            /**
             * resize left - END
             */


            /**
             * resize rigth - START
             */
            $scope.onMouseMoveResizeRight = function(event) {
              var newHeight = $scope.currentStateData.resizeStartHeight + event.pageY - $scope.currentStateData.resizeStartY;
              var newWidth = $scope.currentStateData.resizeStartWidth + event.pageX - $scope.currentStateData.resizeStartX;
              if(newHeight < $scope.settings.minHeight)
              {
                newHeight = $scope.settings.minHeight;
              }
              if(newWidth < $scope.settings.minWidth)
              {
                newWidth = $scope.settings.minWidth;
              }

              container.css({
                height: newHeight + 'px',
                width: newWidth + 'px'
              });
              $scope.currentStateData.height = newHeight;
              $scope.currentStateData.width = newWidth;
            };

            $scope.onMouseUpResizeRight = function() {
              $document.off('mousemove', $scope.onMouseMoveResizeRight);
              $document.off('mouseup', $scope.onMouseUpResizeRight);
            };

            anchorRight.on('mousedown', function(event) {
              // Prevent default dragging of selected content
              event.preventDefault();

              if ($scope.minimize) {
                return;
              }

              // startY i startX to miejsce skąd rozpoczęliśmy resize
              $scope.currentStateData.resizeStartY = event.pageY;
              $scope.currentStateData.resizeStartX = event.pageX;
              $scope.currentStateData.resizeStartHeight = $scope.currentStateData.height;
              $scope.currentStateData.resizeStartWidth = $scope.currentStateData.width;

              $document.on('mousemove', $scope.onMouseMoveResizeRight);
              $document.on('mouseup', $scope.onMouseUpResizeRight);
            });

            /**
             * resize rigth - END
             */

            $scope.$on('$destroy', function() {
              var chatKey = null;
              angular.forEach(lsnChatPopupHelper.activeChatList, function(activeChat, activeChatKey) {
                if (activeChat === $scope.baseSettings) {
                  chatKey = activeChatKey;
                }
              });
              if (chatKey) {
                lsnChatPopupHelper.activeChatList.splice(chatKey, 1);
              }

              header.off('mousedown');
              $document.off('mousemove', $scope.onMouseMove);
              $document.off('mouseup', $scope.onMouseUp);

              anchorRight.off('mousedown');
              anchorLeft.off('mousedown');
              $document.off('mousemove', $scope.onMouseMoveResizeLeft);
              $document.off('mouseup', $scope.onMouseUpResizeLeft);
              $document.off('mousemove', $scope.onMouseMoveResizeRight);
              $document.off('mouseup', $scope.onMouseUpResizeRight);
            });

            $scope.init();
          };
        }
      };
    }
  ]);