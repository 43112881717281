angular.module('salesPath2')
  .factory('ToRestTempRiskModel', [
    function ()
    {


      var TempRiskModel = function (data)
      {
        data = data || {};
        this.variant = data.variant || null;
        this.sumInsured = data.sumInsured || null;
        this.code = data.code || null; //kod ryzyka
        this.prodCode = data.prodCode || null; //kod produktu
        this.estateType = data.estateType || null;
        this.profession = data.profession || null;
        this.start = data.start || null;
        this.end = data.end || null;
        this.insuredId = data.insuredId || null;
        this.assistanceOption = data.assistanceOption || null;
        this.gross = (typeof data.gross !== 'undefined') ? data.gross : null;
        this.attributes = data.attributes || {};
        this.subjectRefs = data.subjectRefs || []; //tablica tablic [rola,id] , gdze rola to stała CONSTANTS.SUBJECT_..., id to id osoby, organizacji
        this.objectRefs = data.objectRefs || []; //tablica identyfikatorów obiektow, np. pojazdu, mieszkania itp. Kazde ryzyko posiada tylko jeden typ obslugiwanego obiektu
      };

      return TempRiskModel;
    }
  ]);