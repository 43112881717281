angular.module('ihestiaGridSettings')
.factory('IHestiaGridSettingsGroupModel',[ 'IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IHestiaGridSettingsGroupModel = function()
    {
      this.objectName = 'IHestiaGridSettingsModel';

      this.fieldsProperties = {
        isDefault: {
          type: 'bool'
        },
        isSelected: {
          type: 'bool'
        },
        canDelete: {
          type: 'bool'
        },
        name: {
          type: 'string'
        },
        id: {
          type: 'int'
        },
        settings: {
          type: 'IHestiaGridSettingsSettingsModel'
        }
      };

      this.isDefault = null; //czy domyslny set ustawień
      this.canDelete = null; //czy można usunąć, jak false to znaczy też, że to filtr wytworzony przez usługę i ładujemy domyślne OSS
      this.isSelected = null; //czy aktualnie wybrany
      this.name = null; //nazwa setu ustawień
      this.id = null; //id grupt filtrow
      this.settings = null; //kontener z ustawieniami
    };

    IHestiaGridSettingsGroupModel.prototype = IHestiaAbstractModel.prototype;

    return IHestiaGridSettingsGroupModel;
  }]);