angular.module('salesPath2')
  .service('vehicleHelper', ['mainDataContainer', 'ihestiaTrimInfo', 'sp2SelectionHelper', 'CONSTANTS', 'mainDataContainer', 'ihestiaConfigHelper', 'vehicleServiceHelper', 'VehicleModel', 'actionHelper', 'addHelper', '$filter', 'dataContainerHelper', 'allowedChangesHelper', 'LsnVehicleModelConstants',
    function(mainDataContainer, ihestiaTrimInfo, selectionHelper, CONSTANTS, dataContainer, ihestiaConfigHelper, vehicleServiceHelper, VehicleModel, actionHelper, addHelper, $filter, dataContainerHelper, allowedChangesHelper, LsnVehicleModelConstants) { // eslint-disable-line angular/di
      var VehicleHelper = function() {
        var self = this;

        var DEFAULT_INFO_HEADER_SETTINGS = {
          elements: [{
            field: 'mark'
          }, {
            field: 'model',
            lastInLine: true
          }, {
            field: 'registration'
          }],
          maxLength: 16,
          lineSeparator: '\n'
        };

        this.boxVehicleInfoParams = {
          maxLength: 33,
          lineSeparator: '\n',
          emptyValue: $filter('translate')('noData', {componentCode: 'Public'})
        };

        var DEFAULT_SEARCH_MODAL_PARAMS = {
          searchParams: {
            value: '',
            productionYear: ''
          },
          searchId: null,
          currentPage: 0,
          vehicles: [],
          selectedVehicles: {},
          replaceClientId: null
        };

        this.searchModalParams = angular.copy(DEFAULT_SEARCH_MODAL_PARAMS);

        /**
         * parametry dla personInfo w boksie na matrycy
         * @type {Object}
         */

        this.searchParams = {
          pageSize: 40,
          sortBy: 'typeCode',
          sortDirection: 'ASC',
          top: 0,
          parameter: {
            value: '',
            productionYear: '',
            product: ihestiaConfigHelper.get('rest').AP_CALLER
          }
        };

        var DEFAULT_EDIT_MODAL_PARAMS = {
          errors: {},
          vehicle: null,
          replaceClientId: null,
          humanVehicleData: null,
          vehicleData: null,
          vehicleAdditionalData: null,
          vehicleDynamicValues: {}, //wszystkie dynamicValues z pojazdu
          etxData: {
            vehicleValue: 0,
            vehicleValueType: 'brutto'
          },
          mileageOption: 0,
          equipmentCount: null,
          lockCustomizeEqBox: true,
          equipment: [],
          dataCleared: true,
          equipmentchanged: false,
          tmp: {
            isCar: true //czy pojazd jest samochodem - na potrzeby zgodności z nonEtx
          },
          allowedChanges: {} //obiekt przechowujący konfigurację dozwolonych zmian na formularzu
        };

        var DEFAULT_SPECIAL_PURPOSES_MODAL_PARAMS = {
          purposes: null
        };

        /**
         * atrybuty z additionalData, które należy przepisać przy np. podmianie pojazdu
         * @type {Array}
         */
        this.additionalDataToRewrite = ['liquidation', 'protectionDates', 'coowners'];
        this.currentlyEditedVehicleData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);
        this.purposesModalData = angular.copy(DEFAULT_SPECIAL_PURPOSES_MODAL_PARAMS);

        /**
         * zwraca wszystkie pojazdy
         * @return {Object} tablica
         * obiekt:
         *   name - nazwa do wyswietlenia
         *   clientId - id elementu
         */
        this.getVehicles = function() {
          var vehicles = [];

          angular.forEach(mainDataContainer.vehicles, function(obj) {
            vehicles.push({
              name: self.getVehicleInfo(obj, self.boxVehicleInfoParams),
              clientId: obj.metaData.get('clientId'),
              editable: allowedChangesHelper.anyChangePossible(obj)
            });
          });
          return vehicles;
        };

        /*
        * zwraca wybrany pojazd (uwaga na wartość -1 i 0)
        */
        this.getSelectedVehicle = function() {
          return dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle);
        };

        /*
        * zwraca wybrany pojazd id (uwaga na wartość -1 i 0)
        */
        this.getSelectedVehicleId = function() {
          return mainDataContainer.selectedVehicle;
        };

        /**
         * [getVehicleInfo description]
         * @param  {VehicleModel} vehicle [description]
         * @param  {object} params  na bazie DEFAULT_INFO_HEADER_SETTINGS
         * @return {string}         shortInfo
         */
        this.getVehicleInfo = function(vehicle, params) {
          // domyslne ustawienia
          var settings = angular.extend({}, DEFAULT_INFO_HEADER_SETTINGS, params);

          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            var field = vehicle.get(element.field);
            element.text = angular.isString(field) ? field.toUpperCase() : '';
          });

          var textTrimed = ihestiaTrimInfo(settings);
          return textTrimed;
        };

        //usuwa pojazd
        this.deleteVehicle = function(clientId) {
          selectionHelper.deleteObjectVariants(CONSTANTS.PRODUCT_TYPE_VEHICLE, clientId);
          delete dataContainer.vehicles[clientId];
          self.deleteVehicleFromBM(clientId);

          actionHelper.runAction('vehicleDeleted', clientId);
        };

        this.deleteVehicleFromBM = function(clientId) {
          var bmToDelete = {};
          angular.forEach(dataContainer.bonusMalus, function(vehicles, personId) {
            angular.forEach(vehicles, function(bonusMalus, vehicleId) {
              if (vehicleId === clientId) {
                bmToDelete[personId] = vehicleId;
              }
            });
          });

          angular.forEach(bmToDelete, function(vehicleId, personId) {
            delete dataContainer.bonusMalus[personId][vehicleId];
          });
        };

        /**
         * Wyszukiwanie pojazdow
         * @param {object} params
         * @param {function} callback
         * @return {vehicleHelper} [description]
         */
        this.searchVehicles = function(params, callback) {
          var postParams = angular.copy(self.searchParams);
          angular.extend(postParams.parameter, params);

          vehicleServiceHelper.searchVehicles(null, postParams, 'searches', function(res) {
            callback(self.getParsedVehicleSearchResponse(res.data));
          });

          return self;
        };

        /**
         * Pobrnie zadanej strony z wyszukiwania pojazdów
         * @param  {string} id     [description]
         * @param  {int} pageNo [description]
         * @param {function} callback
         * @return {vehicleHelper}        [description]
         */
        this.getSearchVehiclesPage = function(id, pageNo, callback) {

          vehicleServiceHelper.getSearchVehiclesNextPage(null, null, 'searches/' + id + '/' + pageNo, function(res) {
            callback(self.getParsedVehicleSearchResponse(res.data));
          });

          return self;
        };

        /**
         * Ładowanie wyposażenia
         */
        this.getVehicleEquipment = function(registrationDate, etxId, callback) {
          vehicleServiceHelper.getVehicleEquipment(null, {
            pageSize: 999,
            parameter: {
              id: [etxId],
              onlyDifferences: false,
              registrationDate: registrationDate
            },
            sortBy: 'id',
            sortDirection: 'ASC',
            top: 0
          }, 'searches', function(res) {
            callback(res.data.item.items);
          });
        };

        /**
         * Pobieranie pojazu z uslugi
         * @param  {int} etxId   [description]
         * @param  {function} callback [description]
         * @return {vehicleHelper}         [description]
         */
        this.loadVehicle = function(etxId, callback, errorCallback) {
          vehicleServiceHelper.loadVehicle(etxId, function(res) {
            var vehicleModel = new VehicleModel();
            vehicleModel.setData(res.data);
            if (mainDataContainer.unsupportedVehicle && angular.isObject(mainDataContainer.tempVehicleData)) {
              vehicleModel.setData(mainDataContainer.tempVehicleData);
            }
            callback(vehicleModel);
          }, errorCallback, {
            allowedStatuses: [404]
          });

          return self;
        };

        /**
         * Zamiana obiektow z uslugi na modele
         * @param  {object} response [description]
         * @return {object}          [description]
         */
        this.getParsedVehicleSearchResponse = function(response) {
          var parsedResponse = {
            vehicles: [],
            id: response.id,
            pageNo: response.pageNo,
            nextPageAvailable: angular.isString(response.nextPageUri)
          };
          // budujemy nasze obiekty
          angular.forEach(response.items, function(vehicle) {
            var vehicleModel = new VehicleModel();
            delete vehicle.metaData;
            vehicleModel.setData(vehicle);
            parsedResponse.vehicles.push(vehicleModel);
          });

          return parsedResponse;
        };

        /**
         * Nastąpił zapis pojazdu pojazdu
         * @param {vehicleModal} vehicle [description]
         */
        this.applyVehicle = function(vehicle, saveApplication) {
          var isNew = false;
          saveApplication = (typeof saveApplication === 'boolean') ? saveApplication : true;
          var vehicleClientId = vehicle.metaData.get('clientId');
          if (!vehicleClientId) {
            vehicleClientId = dataContainerHelper.getNextVehicleId();
            vehicle.metaData.set('clientId', vehicleClientId);
            isNew = true;
          }
          if (!vehicle.getAdditionalData('protectionDates')) {
            vehicle.setAdditionalData(self.getDefaultProtectionDates(), 'protectionDates');
          }

          if (!vehicle.getAdditionalData('liquidation')) {
            //zczytujemy z ostatnio wybranego pojazdu. A jak nie ma pojazdu to z liquidation
            if (angular.isDefined(dataContainer.vehicles[vehicleClientId])) {
              //zczytanie z poprzedniego pojazdu
              var oldVehicle = dataContainer.vehicles[vehicleClientId];
              vehicle.setAdditionalData(oldVehicle.getAdditionalData('liquidation'), 'liquidation');
            } else {
              vehicle.setAdditionalData(mainDataContainer.liquidation, 'liquidation');
            }
          }

          dataContainer.vehicles[vehicleClientId] = vehicle;

          dataContainerHelper.selectVehicle(vehicleClientId);
          if (mainDataContainer.unsupportedVehicle && angular.isObject(mainDataContainer.tempVehicleData)) {
            mainDataContainer.tempVehicleData = null;
            mainDataContainer.unsupportedVehicle = false;
          }
          actionHelper.runAction(isNew ? 'vehicleAdded' : 'vehicleEdited', vehicleClientId);
        };

        /**
         * zwraca domyslne daty ochrony dla pojazdu
         * @return {Object} {start: ..., end: ...}
         */
        this.getDefaultProtectionDates = function() {
          return {
            start: null,
            end: null
          };
        };

        /*
        * czyści dane edycji pojazdu
        */
        this.clearEditModalData = function() {
          self.purposesModalData = angular.copy(DEFAULT_SPECIAL_PURPOSES_MODAL_PARAMS);
          self.currentlyEditedVehicleData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);
          return self;
        };

        /**
         * Ustawiamy domyślne wartości parametrów wyszukiwania pojazdu
         * @return {VehicleHelper}
         */
        this.clearSearchModalData = function() {
          self.searchModalParams = angular.copy(DEFAULT_SEARCH_MODAL_PARAMS);
          return self;
        };

        /**
         * Czy wybrany pojazd jest przedmiotem leasingu?
         **/
        this.isSelectedVehicleLeased = function() {
          var vehicle = dataContainerHelper.getObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, mainDataContainer.selectedVehicle);
          return (vehicle !== null) ? vehicle.getAdditionalData('isLeased') : false;
        };

        /**
         * zwraca liczbę lat eksploatacji pojazdu używaną przy sprawdzaniu dostępności ryzyk
         * @param  {Vehicle} vehicle
         * @return {int}     liczba lat eksploatacji
         */
        this.getVehicleExploatationYears = function(vehicle) {
          var firstRegistrationDate = vehicle.get('firstRegistration'); //date | null
          var productionYear = vehicle.get('productionYear'); //int
          if (firstRegistrationDate === null || productionYear === null) {
            return null;
          }

          var exploatationStart; //ustalamy datę początku eksploatacji pojazdu
          if (new XDate(firstRegistrationDate).getFullYear() === productionYear) {
            //jeśli brak roku produkcji lub są obie daty i lata są takie same
            exploatationStart = new XDate(firstRegistrationDate);
          } else {
            exploatationStart = new XDate(productionYear + '-12-31');
          }

          var exploatationEnd;
          if (vehicle.getAdditionalData(['protectionDates', 'start']) !== null) {
            exploatationEnd = new XDate(vehicle.getAdditionalData(['protectionDates', 'start']));
          } else {
            exploatationEnd = dataContainer.defaultStartDate.clone();
          }

          return parseInt(exploatationStart.diffYears(exploatationEnd), 10);
        };

        /**
         * przygotowuje dane spozaEtx - dokłada odpowiednie dane do tablicy opisującej każdy parametr pojazdu
         * @param  {Object} vehicleData dane pojazdu
         * @param  {String[]} properties tablica własności/pól, które mogą być typu INTERNAL - spoza etx
         * @param  {Boolean} nonEtx czy pojazd jest spoza etx - tylko dla takich pojazdów przeprowadzamy operacje
         * @return {Boolean} gdy przetworzno dane, false gdy nie
         */
        this.prepareInternalData = function(vehicleData, properties, nonEtx) {
          if (!nonEtx) {
            return false;
          }
          angular.forEach(properties, function(propName) {
            if (angular.isString(vehicleData[propName])) {
              vehicleData[propName] = [vehicleData[propName], null, LsnVehicleModelConstants.VEHICLE_DATA_PROVIDER_INTERNAL];
            }
          });
          return true;
        };


      };

      return new VehicleHelper();
    }
  ]);