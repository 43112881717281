angular.module('lsnBase.directives')
  .directive('commonClickOutside', ['$parse', '$document', function($parse, $document) {
    return {
      restrict: 'A',
      link: function($scope, $element, $attrs) {
        var tiggerEventsString = 'mousedown touchstart';
        if ($attrs.commonClickOutsideTrigger) {
          tiggerEventsString = $attrs.commonClickOutsideTrigger;
        }

        var outsideClickFunc = function(e) { // eslint-disable-line consistent-return
          // domyślnie kliknięcie w modal nie wywołuje tek akcji
          var modalClicked = angular.element('common-modal,lsn-modal').has(e.target).length > 0;
          // obsługujemy FF32 dla portalu e.target.closest i e.originalEvent.valueOf().path nie są obsługiwane angularowo w ff32
          if ($(e.target).closest('body').length) {
            if ((!modalClicked || $attrs.clickOutsideModalMode) && (!angular.element($element).is(e.target) && angular.element($element).has(e.target).length === 0)) {
              $parse($attrs.commonClickOutside)($scope);
            }
          } else {
            // element kliknięty nie znajduje już się w body więc nie ma kontekstu ale posiadamy jeszcze jego originalEvent.valueOf().path,
            // sprawdzamy każdego rodzica w poszukiwaniu attr.commonClickOutside.
            var commonClickOutside = '';
            if (e.originalEvent.valueOf().path) {
              angular.forEach(e.originalEvent.valueOf().path, function(elem) {
                if (angular.element(elem).attr('common-click-outside')) {
                  commonClickOutside = angular.element(elem).attr('common-click-outside');
                }
              });
            } else if ($(e.originalEvent.originalTarget)) {
              var orgObj = $(e.originalEvent.originalTarget);
              while (orgObj) {
                if (orgObj.attr('common-click-outside')) {
                  commonClickOutside = orgObj.attr('common-click-outside');
                  return false;
                }
                orgObj = orgObj.parent();
                if (orgObj.length === 0) {
                  return false;
                }
              }
            }
            if (commonClickOutside !== $attrs.commonClickOutside) {
              $parse($attrs.commonClickOutside)($scope);
            }
          }
        };
        $document.on(tiggerEventsString, outsideClickFunc);
        $scope.$on('$destroy', function(){
          $document.off(tiggerEventsString, outsideClickFunc);
        });
      }
    };
  }]);