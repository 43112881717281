angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestVehicleDictionarySvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryVehicleSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.id = '';
        this.resource = 'vehicle';
        this.searchesPostfix = 'searches/search';


      };
      return new RestDictionaryVehicleSvc();
    }
  ]);