angular.module('salesPath2')
  /**
   * helper nawigacji po ścieżce sprzedaży
   */
  .service('navigationHelper', ['$state',
    function($state) {
      var NavigationHelper = function() {
        /**
         * póki co nakładka na $state.go
         * @param  {[type]} to        [description]
         * @param  {[type]} toParams  [description]
         * @param  {[type]} toOptions [description]
         * @return {[type]}           [description]
         */
        this.go = function(to, toParams, toOptions) {
          return $state.go(to, toParams, toOptions);
        };

        /**
         * zwraca aktualny stan
         * @return {[type]} [description]
         */
        this.getCurrentState = function() {
          return $state.$current;
        };
      };

      return new NavigationHelper();
    }
  ]);