/**
 * Mapper ryzyk
 */
angular.module('salesPath2').service('fromRestRiskMapper', ['MapperUtils', 'FromRestSimpleRiskModel', 'CONSTANTS', 'CONFIG', 'sp2CommonHelper', 'fromRestVehicleMapper', 'fromRestEstateMapper', 'fromRestSubjectMapper', 'sp2SelectionHelper', 'SPD', 'RESOURCES', 'addHelper', 'extensionHelper', 'resourceHelper', 'mainDataContainer', 'appVariables', 'lsnArrayUnique', 'dataContainerHelper', 'lsnUtils',
  function(MapperUtils, FromRestSimpleRiskModel, CONSTANTS, CONFIG, sp2CommonHelper, fromRestVehicleMapper, fromRestEstateMapper, fromRestSubjectMapper, selectionHelper, SPD, RESOURCES, addHelper, extensionHelper, resourceHelper, mainDataContainer, appVariables, lsnArrayUnique, dataContainerHelper, lsnUtils) { // eslint-disable-line angular/di
    var FromRestRiskMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.defaultOptions = { //opcje mapowania
        premium: true, //mapowanie skladek
        sumInsured: true, //mapowanie sum ubezpieczen
        additionalData: false, //mapowanie additionalData osób/firm/lokalizacjimapToSimpleRisks/pojazdów
        tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
        policyMode: false, //tryb odczytu polisy
        renewalMode: false, //
        initSupplementData: false //tryb - init danych dla dokupień
      };

      this.options = angular.copy(this.defaultOptions);
      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };
      /**
       * mapuje ryzyka
       * @param {Application} application windiniosek
       * @param {TempDataContainerModel} tmpDc wniosek
       * @param {object} options opcje mapowania (dostepne opcje w self.defaultOptions)
       */
      this.mapRisks = function(application, tmpDc, options) {
        self._setOptions(options);
        if (application.risks === null) {
          return false;
        }
        self._beforeSupplementMode(application);
        var addsToMap = [], //zbieramy tu dodatki, aby zmapować je na końcu - chodzi o dostępność w zależności od innych ryzyk
          risksToOmit = self.getRisksToOmit();
        lsnNg.forEach(application.risks, function(risk, idx) {
          if (risk.product.compId === null || risk.product.compId === '') {
            sp2CommonHelper.throwException('No product.compId found for risk with index {0}'.format(idx));
          }
          if (self.options.policyMode && self.utils.isProperProtectionDate(risk.get('start'))) {
            tmpDc.protectionStartTime = new XDate(risk.get('start')).toString('HH:mm');
          }
          var def = self.utils.findRiskDefByIdpm(risk.product.compId);
          if (risksToOmit.indexOf(def.riskCode) !== -1 && def.riskCode !== CONSTANTS.RISK_FLOOD) { //pomijamy niektóre ryzyka
            return;
          }

          self._mapRisk(def, risk, tmpDc, addsToMap, options);
        });
        angular.forEach(addsToMap, function(data) {
          self._mapAdditionRisk(data[0], data[1], tmpDc, options);
        });
        return true;
      };

      /**
       * zmiany, których należy dokonać na ryzykach, zanim zmapujemy je w trybie dokupień
       * @param {ApplicationModel} application wniosek
       * @return {Boolen} false gdy poza trybem dokupień
       */
      this._beforeSupplementMode = function(application) {
        if (!appVariables.isSupplement || self.options.initSupplementData) {
          return false;
        }
        var startDate = application.operationData.get('changeDate'); //w trybie dokupień data początku ochrony przekazywana jest w tym miejscu
        // UWAGA poniższa realizacja zapobiegawczo, usługi powinny przepisywać tę datę na ryzyka samodzielnie
        angular.forEach(application.risks, function(risk) {
          risk.set('start', startDate);
        });
        return true;
      };

      /**
       * mapuje pojedyncze ryzyko
       * @param  {Object} def definicja ryzyka
       * @param  {Object} risk obiekt ryzyka
       * @param  {DataContainerModel} tmpDc (tymczasowy) dataContainer
       * @param  {Array} addsToMap w tej tablicy zapisane zostaną dodatki do zmapowania (formt [definicja, obiekt_ryzyka])
       * @param  {[type]} options opcje mapowania
       */
      this._mapRisk = function(def, risk, tmpDc, addsToMap, options) {
        if (self.utils.propertyProducts.indexOf(def.productCode) !== -1) {
          if (def.riskCode === CONSTANTS.RISK_FLOOD) {
            self._mapFloodRisk(def, risk, tmpDc, options);
          } else {
            self._mapPropertyRisk(def, risk, tmpDc, options);
          }
        } else if (self.utils.personalProducts.indexOf(def.productCode) !== -1) {
          self._mapPersonalRisk(def, risk, tmpDc, options);
        } else if (self.utils.communicationProducts.indexOf(def.productCode) !== -1) {
          self._mapCommunicationRisk(def, risk, tmpDc, options);
        } else if (self.utils.personGroupProducts.indexOf(def.productCode) !== -1) {
          self._mapPersonGroupRisk(def, risk, tmpDc, options);
        } else { //dodatki
          addsToMap.push([def, risk]);
        }
      };
      //#pragma mark additionRisks
      /**
       * mapuje ryzyko - dodatek
       * @param {object} def definicja ryzyka z SPD
       * @param {Risk} risk ryzyko
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapAdditionRisk = function(def, risk, tmpDc, options) {
        var dynVals = risk.product.get('dynamicValues');
        if (angular.isDefined(def.objectIdpm) && (!risk.insuredObjectsRef || angular.isUndefined(risk.insuredObjectsRef[0]))) {
          sp2CommonHelper.throwException('No object reference found for addition risk {0}'.format(def.riskCode));
        }
        if (angular.isUndefined(dynVals._groupId) && angular.isDefined(def.subjects) && (!risk.insuredSubjectsRef || angular.isUndefined(risk.insuredSubjectsRef[0]))) {
          sp2CommonHelper.throwException('No subject reference found for addition risk {0}'.format(def.riskCode));
        }
        var addDef = self.utils.getAdditionDefinition(def.riskCode),
          variant = null,
          objId = null;
        if (angular.isDefined(addDef.VARIANTS)) {
          if (def.attributes.coverageOption) {
            if (typeof dynVals.coverageOption === 'undefined' || dynVals.coverageOption === '' || dynVals.coverageOption === null) {
              sp2CommonHelper.throwException('No coverageOption defined for addition risk {0}'.format(def.productCode));
            }
            variant = self._getAdditionVariant(addDef.CODE, dynVals.coverageOption);
          } else if (def.attributes.assistanceOption) { //HCA
            if (typeof dynVals.assistanceOption === 'undefined' || dynVals.assistanceOption === '' || dynVals.assistanceOption === null) {
              sp2CommonHelper.throwException('No assistanceOption defined for addition risk {0}'.format(def.productCode));
            }
            variant = self.utils.getHCAVariantFromAssistanceOption(dynVals.assistanceOption);
          } else { //wariant przepisujemy z definicji lokalnej dodatku (np. u nas jedno ryzyko ma 2 warianty a w BOSie są to 2 osobne ryzyka)
            if (def.variant.length > 1) {
              sp2CommonHelper.throwException('Ambigious variant in addition risk definition {0}'.format(def.productCode));
            }
            variant = def.variant[0];
          }
        }
        switch (addDef.TYPE) {
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            objId = tmpDc._estateIdMap[risk.insuredObjectsRef[0].ref];
            if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
              self._initSupplementDataForRisk(tmpDc, risk, def, objId, variant);
              return true;
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            //przypadek dodatku per auto dla ktorego BOS nie przechowuje referncji obiektu
            if (risk.get('insuredObjectsRef').length === 0) {
              var idMapKeys = Object.keys(tmpDc._vehicleIdMap);
              if (idMapKeys.length !== 1) {
                sp2CommonHelper.throwException('Can not map vehicle for addition {0}. No vehicle or over 1 vehicles found for /policyapplication.'.format(def.riskCode));
              }
              objId = tmpDc._vehicleIdMap[idMapKeys[0]];
              if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
                self._initSupplementDataForRisk(tmpDc, risk, def, objId, variant);
                return true;
              }
              if (addDef.ACT_AS_RISK) {
                self._mapVehicleAdditionalData(tmpDc.vehicles[objId], risk, tmpDc);
              }
            } else {
              objId = tmpDc._vehicleIdMap[risk.insuredObjectsRef[0].ref];
              if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
                self._initSupplementDataForRisk(tmpDc, risk, def, objId, variant);
                return true;
              }
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
            //przypadek dodatku per auto dla ktorego BOS nie przechowuje referncji obiektu
            if (risk.get('insuredSubjectsRef').length === 0) { //ubezpieczenie grupy - szukamy id grupy na dynamicValues
              if (angular.isUndefined(dynVals._groupId)) {
                sp2CommonHelper.throwException('No group id found in addition {0} dynamicValues.'.format(def.riskCode));
              }
              objId = tmpDc._subjectIdMap[dynVals._groupId];
            } else {
              objId = tmpDc._subjectIdMap[risk.insuredSubjectsRef[0].ref];
            }
            if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
              self._initSupplementDataForRisk(tmpDc, risk, def, objId, variant);
              return true;
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON:
            //obsluga dowolnej roli (glowny ubezpieczony jesli istnieje lub dowolny z brzegu)
            if (risk.get('insuredSubjectsRef').length === 0) {
              sp2CommonHelper.throwException('No insured subject found for addition risk {0}.'.format(def.riskCode));
            }
            var mainInsuredRef = self._getAdditionMainSubjectRef(risk.insuredSubjectsRef);
            var ref = (mainInsuredRef !== null) ? mainInsuredRef : risk.insuredSubjectsRef[0].ref;
            objId = tmpDc._subjectIdMap[ref];
            if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
              self._initSupplementDataForRisk(tmpDc, risk, def, objId, variant);
              return true;
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_PACKAGE:
            if (self.options.initSupplementData) {
              self._initSupplementDataForRisk(tmpDc, risk, def, null, variant);
              return true;
            }
            break;

          default:
            break;
        }
        self._mapAdditionRiskDynamicValues(tmpDc, addDef, dynVals);
        self._setPremiums(tmpDc, addDef.TYPE, objId, def.productCode, def.riskCode, risk, variant);
        self._setRenewal(tmpDc, addDef.TYPE, objId, def.productCode, def.riskCode, risk, variant);
        if (options.policyMode || options.renewalMode) {
          self._addAdditionVariantsByParentAttributes(def.productCode, objId, variant, dynVals);
          addHelper.addAdd(def.productCode, objId, variant);
        }
        self.setSelectedRiskRef(tmpDc.selectedRisksRefs, def.productCode, def.productCode, objId, variant, risk);
        return true;
      };

      /**
       * Dodajemy warianty dodatków które są w usługach tylko atrybutem (dynamicValue) na innym dodatku - rodzicu
       * Sprawdzana jest sekcja PARENT_ATTRIBUTE na wariancie dodatku
       * @param {string} code
       * @param {string|int} objectId
       * @param {string} variant
       * @param {object} dynVals dynamicValues ryzyka
       */
      this._addAdditionVariantsByParentAttributes = function(code, objId, variant, dynVals) {
        var addDef = resourceHelper.getAddDef(code);

        if (RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE] && RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant]) {
          var dynValAttributeName = RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].attrName; //nazwa atrybutu do sprawdzenia w dynamicValues
          var variantToAdd = RESOURCES.ADDITIONS_ATTRIBUTES[addDef.CODE][variant].addVariant; //variant który ma być dodany
          if (angular.isDefined(dynVals[dynValAttributeName]) && dynVals[dynValAttributeName]) {
            addHelper.addAdd(code, objId, variantToAdd);
          }
        }
      };

      /**
       * mapuje dynamicValues z ryzyka do datamanagera
       * @param {TempDataContainerModel} tmpDc
       * @param {object} addDef definicja dodatku
       * @param {object} dynVals dynamicValues ryzyka
       */
      this._mapAdditionRiskDynamicValues = function(tmpDc, addDef, dynVals) {
        if (angular.isDefined(dynVals.greenCardNumber)) {
          tmpDc.greenCardNumber = dynVals.greenCardNumber;
        }
      };

      /**
       * zwraca premium z risk uwzględniając dodatkowy parametr na którym może być przekazane
       * @param  {Risk} risk
       * @return {boolean} [tariffPremium=false] czy po0brac skladke taryfową
       * individualDisc {string} - jeżeli funkcja ma zwrócić składkę indywidualną to przyjmuje wartości AFTER_DISC lub BEFORE_DISC
       */
      this._getRiskPremium = function(risk, tariffPremium, individualDisc) {
        tariffPremium = (typeof tariffPremium === 'boolean') ? tariffPremium : false;
        var dynamicValues = risk.product.get('dynamicValues');
        var tariffAttributes = risk.product.get('tariffAttributes');

        if (angular.isUndefined(individualDisc)) {
          return self._getPremiumType(tariffPremium ? 'tariffPremium' : 'premium', dynamicValues, tariffAttributes, risk);
        }

        if (angular.isDefined(individualDisc)) {
          if (angular.isDefined(dynamicValues.individualPremiumAdjustment) && dynamicValues.individualPremiumAdjustment) {
            if (individualDisc === 'AFTER_DISC') {
              if (angular.isDefined(dynamicValues._premiumAfterIndividualDiscountForDisplay)) {
                return dynamicValues._premiumAfterIndividualDiscountForDisplay;
              } else if (angular.isDefined(dynamicValues.premiumAfterIndividualAdjustment)) {
                return dynamicValues.premiumAfterIndividualAdjustment;
              } else if (angular.isDefined(dynamicValues._premiumForDisplay)) {
                return dynamicValues._premiumForDisplay;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'BEFORE_DISC') {
              if (angular.isDefined(dynamicValues._premiumBeforeIndividualDiscountForDisplay)) {
                return dynamicValues._premiumBeforeIndividualDiscountForDisplay;
              } else if (angular.isDefined(dynamicValues.premiumBeforeIndividualAdjustment)) {
                return dynamicValues.premiumBeforeIndividualAdjustment;
              } else if (angular.isDefined(dynamicValues._premiumForDisplay)) {
                return dynamicValues._premiumForDisplay;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'PURE_AFTER_DISC') {
              if (angular.isDefined(dynamicValues.premiumAfterIndividualDiscount)) {
                return dynamicValues.premiumAfterIndividualDiscount;
              } else if (angular.isDefined(dynamicValues.premiumAfterIndividualAdjustment)) {
                return dynamicValues.premiumAfterIndividualAdjustment;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'PURE_BEFORE_DISC') {
              if (angular.isDefined(dynamicValues.premiumBeforeIndividualDiscount)) {
                return dynamicValues.premiumBeforeIndividualDiscount;
              } else if (angular.isDefined(dynamicValues.premiumBeforeIndividualAdjustment)) {
                return dynamicValues.premiumBeforeIndividualAdjustment;
              } else {
                return risk.get('premium');
              }
            }
          } else {
            if (individualDisc === 'AFTER_DISC') {
              if (angular.isDefined(dynamicValues._premiumAfterIndividualDiscountForDisplay)) {
                return dynamicValues._premiumAfterIndividualDiscountForDisplay;
              } else if (angular.isDefined(dynamicValues._premiumForDisplay)) {
                return dynamicValues._premiumForDisplay;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'PURE_AFTER_DISC') {
              if (angular.isDefined(dynamicValues.premiumAfterIndividualDiscount)) {
                return dynamicValues.premiumAfterIndividualDiscount;
              } else if (angular.isDefined(dynamicValues.premiumAfterIndividualAdjustment)) {
                return dynamicValues.premiumAfterIndividualAdjustment;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'BEFORE_DISC') {
              if (angular.isDefined(dynamicValues._premiumBeforeIndividualDiscountForDisplay)) {
                return dynamicValues._premiumBeforeIndividualDiscountForDisplay;
              } else if (angular.isDefined(dynamicValues._premiumForDisplay)) {
                return dynamicValues._premiumForDisplay;
              } else {
                return risk.get('premium');
              }
            } else if (individualDisc === 'PURE_BEFORE_DISC') {
              if (angular.isDefined(dynamicValues.premiumBeforeIndividualDiscount)) {
                return dynamicValues.premiumBeforeIndividualDiscount;
              } else if (angular.isDefined(dynamicValues.premiumBeforeIndividualAdjustment)) {
                return dynamicValues.premiumBeforeIndividualAdjustment;
              } else {
                return risk.get('premium');
              }
            }
          }
        }

        //dla zachowania kompatybilnosci wstecz
        if (angular.isDefined(dynamicValues._premiumForDisplay)) {
          return dynamicValues._premiumForDisplay;
        } else {
          return risk.get('premium');
        }
      };

      /**
       * zwraca składkę danego typu za ryzyko
       * @param  {String} type obslugiwane typy: premium, tariffPremium
       * @param  {Object} dynVals dynamicValues ryzyka
       * @param  {Object} tariffAttributes atrybuty taryfowe ryzyka
       * @param  {RiskModel} risk ryzyko
       * @return {Number|null} skladka lub null gdy nie okreslono
       */
      this._getPremiumType = function(type, dynVals, tariffAttributes, risk) {
        var value = null,
          supplementModeMap = {
            tariffPremium: '_extendTariffPremium',
            premium: '_extendPremium'
          };
        dynVals = angular.isObject(dynVals) ? dynVals : risk.product.get('dynamicValues');
        tariffAttributes = angular.isObject(tariffAttributes) ? tariffAttributes : risk.product.get('tariffAttributes');
        if (appVariables.isSupplement && angular.isDefined(supplementModeMap[type])) { //jeśli tryb dokupień
          type = supplementModeMap[type];
        }

        switch (type) {
          case 'tariffPremium':
            if (angular.isDefined(dynVals._tariffPremiumForDisplay)) {
              value = dynVals._tariffPremiumForDisplay;
            } else if (angular.isDefined(tariffAttributes.tariffPremium)) {
              value = tariffAttributes.tariffPremium;
            }
            break;
          //w trybie dokupień sczytujemy składki tylko za dokupienie ryzyka
          case '_extendPremium':
          case '_extendPremiumAfterChange':
          case '_extendTariffPremium':
          case '_extendTariffPremiumAfterChange':
            if (angular.isDefined(dynVals[type + 'ForDisplay'])) {
              value = dynVals[type + 'ForDisplay'];
            } else if (angular.isDefined(dynVals[type])) {
              value = dynVals[type];
            }
            break;
          case 'premium':
            if (angular.isDefined(dynVals._premiumForDisplay)) {
              value = dynVals._premiumForDisplay;
            } else {
              value = risk.get('premium');
            }
            break;
          default:
        }
        return value;
      };

      //zwraca wariant dodatku na podstawie coverageOption z BOSa
      this._getAdditionVariant = function(addCode, covOption) {
        if (addCode === CONSTANTS.ADD_ASSMEDIC) {
          return (covOption === CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_BASIC) ? CONSTANTS.VARIANT_I : CONSTANTS.VARIANT_II;
        } else {
          return covOption;
        }
      };
      /**
       * zwraca ref (bosowe id) dla gĹ‚Ăłwnego podmiotu z listy ubezpieczonych
       * @param {InsuredObject[]} subjectRefs description
       * @return {string}
       */
      this._getAdditionMainSubjectRef = function(subjectRefs) {
        var ref = null;
        lsnNg.forEach(subjectRefs, function(subRef) {
          if (self.getMainSubjectsIdpms().indexOf(subRef.typeId) !== -1) {
            ref = subRef.ref;
            return false;
          }
          return true; //continue
        });
        return ref;
      };
      //#pragma mark communicationRisks
      /**
       * mapuje ryzyko komunikacyjne
       * @param {object} def definicja ryzyka z SPD
       * @param {Risk} risk ryzyko
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapCommunicationRisk = function(def, risk, tmpDc, options) {
        if (!risk.insuredObjectsRef || typeof risk.insuredObjectsRef[0] === 'undefined') {
          sp2CommonHelper.throwException('No object reference found for communication risk {0}'.format(def.productCode));
        }
        var dynVals = risk.product.get('dynamicValues');
        if (def.attributes.coverageOption && (typeof dynVals.coverageOption === 'undefined' || dynVals.coverageOption === '' || dynVals.coverageOption === null)) {
          sp2CommonHelper.throwException('No coverageOption defined for communication risk {0}'.format(def.productCode));
        }
        var vehId = tmpDc._vehicleIdMap[risk.insuredObjectsRef[0].ref],
          vehicle = fromRestVehicleMapper.getVehicleByClientId(vehId, tmpDc);

        if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
          self._initSupplementDataForRisk(tmpDc, risk, def, vehId, dynVals.coverageOption || CONSTANTS.VARIANT_I);
          return true;
        }

        self._mapVehicleAdditionalData(vehicle, risk, tmpDc);
        self._setPremiums(tmpDc, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehId, def.productCode, def.riskCode, risk, dynVals.coverageOption || CONSTANTS.VARIANT_I);
        self._setRenewal(tmpDc, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehId, def.productCode, def.riskCode, risk, dynVals.coverageOption || CONSTANTS.VARIANT_I);
        if (options.policyMode || options.renewalMode) {
          selectionHelper.setVariant(def.productCode, dynVals.coverageOption || CONSTANTS.VARIANT_I, true, vehId, true);
        }
        self.setSelectedRiskRef(tmpDc.selectedRisksRefs, def.productCode, def.riskCode, vehId, dynVals.coverageOption || CONSTANTS.VARIANT_I, risk);
        self.setExtensions(CONSTANTS.PRODUCT_TYPE_VEHICLE, dynVals, vehId);
        return true;
      };
      /**
       * mapuje dane dodatkowe dla pojazdu
       * @param {Vehicle} vehicle pojazd
       * @param {Risk} risk ryzyko
       */
      this._mapVehicleAdditionalData = function(vehicle, risk, tmpDc) {
        if (!self.options.additionalData) {
          return;
        }
        fromRestVehicleMapper.setAdditionalDataFromRisk(vehicle, risk, tmpDc);
      };
      //#pragma mark communicationRisks
      /**
       * mapuje ryzyko typu osoba-grupa
       * @param {object} def definicja ryzyka z SPD
       * @param {Risk} risk ryzyko
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapPersonGroupRisk = function(def, risk, tmpDc, options) {
        var dynVals = risk.product.get('dynamicValues');
        if (def.attributes.coverageOption && (typeof dynVals.coverageOption === 'undefined' || dynVals.coverageOption === '' || dynVals.coverageOption === null)) {
          sp2CommonHelper.throwException('No coverageOption defined for personGroup risk {0}'.format(def.productCode));
        }
        var subjectId = null;
        if (angular.isArray(risk.insuredSubjectsRef) && angular.isDefined(risk.insuredSubjectsRef[0])) { //ryzyko dla osoby
          subjectId = tmpDc._subjectIdMap[risk.insuredSubjectsRef[0].ref];
        } else if (angular.isDefined(dynVals._groupId)) { //ryzyko dla grupy
          subjectId = tmpDc._subjectIdMap[dynVals._groupId];
        } else { //nie zdefiniowano referencji do podmiotu
          sp2CommonHelper.throwException('No subject defined for personGroup risk {0}'.format(def.productCode));
        }
        if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
          self._initSupplementDataForRisk(tmpDc, risk, def, subjectId, dynVals.coverageOption || CONSTANTS.VARIANT_I);
          return true;
        }
        self._setPremiums(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subjectId, def.productCode, def.riskCode, risk, dynVals.coverageOption || CONSTANTS.VARIANT_I);
        self._setRenewal(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subjectId, def.productCode, def.riskCode, risk, dynVals.coverageOption || CONSTANTS.VARIANT_I);
        if (options.policyMode || options.renewalMode) {
          selectionHelper.setVariant(def.productCode, dynVals.coverageOption || CONSTANTS.VARIANT_I, true, subjectId, true);
          if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
            tmpDc.propertyProtectionDates.start = self.utils.getFormattedJSProtectionDate(risk.get('start'));
            tmpDc.propertyProtectionDates.end = self.utils.getFormattedJSProtectionDate(risk.get('end'));
          }
        }
        self.setSelectedRiskRef(tmpDc.selectedRisksRefs, def.productCode, def.riskCode, subjectId, dynVals.coverageOption || CONSTANTS.VARIANT_I, risk);
        self.setExtensions(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, dynVals, subjectId);
        return true;
      };

      //mapuje ryzyko powodzi
      this._mapFloodRisk = function(def, risk, tmpDc, options) {
        if (!risk.insuredObjectsRef || angular.isUndefined(risk.insuredObjectsRef[0])) {
          return;
        }
        var locId = tmpDc._estateIdMap[risk.insuredObjectsRef[0].ref],
          dynVals = risk.product.get('dynamicValues');
        if (self.options.initSupplementData) { //tryb inicjalizacji danych o dokupieniach
          self.setPreviousRiskSelection(tmpDc.previousRisksSelection, risk, def, locId, def.riskCode); //wyjątkowo dla powodzi jako wariant przekazujemy kod ryzyka
          self.setSelectedRiskRef(tmpDc.previouslySelectedRisksRefs, def.productCode, def.riskCode, locId, null, risk);
          return true; //eslint-disable-line consistent-return
        }
        if (options.renewalMode) //jeśli to zaczytanie wznowienia to wtedy ustawiamy na puste
        {
          tmpDc.localizations[locId].setAdditionalData(-2, 'flood');
        } else if (typeof dynVals.nrOfFloodings !== 'undefined') {
          var nrOfFloodings = parseInt(dynVals.nrOfFloodings, 10);
          tmpDc.localizations[locId].setAdditionalData((nrOfFloodings === 3) ? -1 : nrOfFloodings, 'flood');
        }
        self.setSelectedRiskRef(tmpDc.selectedRisksRefs, CONSTANTS.PRODUCT_OGI, CONSTANTS.RISK_FLOOD, locId, null, risk);
        return true; //eslint-disable-line consistent-return
      };
      /**
       * mapuje ryzyka majatkowe
       * @param {object} def definicja ryzyka z SPD
       * @param {Risk} risk ryzyko
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapPropertyRisk = function(def, risk, tmpDc, options) {
        if (!risk.insuredObjectsRef || typeof risk.insuredObjectsRef[0] === 'undefined') {
          if (!self.options.tarifficationMode) {
            sp2CommonHelper.throwException('No object reference found for property risk {0}'.format(def.productCode));
          } else {
            return;
          }
        }
        var dynVals = risk.product.get('dynamicValues');
        if (angular.isUndefined(dynVals.coverageOption) || dynVals.coverageOption === '' || dynVals.coverageOption === null) {
          var prodDef = self.utils.getProductDef(def.productCode);
          if (angular.isArray(prodDef.VARIANTLIST) && prodDef.VARIANTLIST.length === 1) {
            dynVals.coverageOption = prodDef.VARIANTLIST[0].CODE;
          } else {
            sp2CommonHelper.throwException('No coverageOption defined for property risk {0}'.format(def.productCode));
          }
        }
        var locId = tmpDc._estateIdMap[risk.insuredObjectsRef[0].ref];
        if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
          self._initSupplementDataForRisk(tmpDc, risk, def, locId, dynVals.coverageOption);
          return true; //eslint-disable-line consistent-return
        }
        self._mapEstateAdditionalDataFromRisk(tmpDc.localizations[locId], risk, def);
        self._setPremiums(tmpDc, CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, def.productCode, def.riskCode, risk, dynVals.coverageOption);
        self._setRenewal(tmpDc, CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, def.productCode, def.riskCode, risk, dynVals.coverageOption);
        self._setSumInsured(tmpDc, CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, def.productCode, def.riskCode, risk.get('sum'), dynVals.coverageOption);
        if (options.policyMode || options.renewalMode) {
          selectionHelper.setVariant(def.productCode, dynVals.coverageOption, true, locId, true);
          if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
            tmpDc.propertyProtectionDates.start = self.utils.getFormattedJSProtectionDate(risk.get('start'));
            tmpDc.propertyProtectionDates.end = self.utils.getFormattedJSProtectionDate(risk.get('end'));
          }
        }
        self.setSelectedRiskRef(tmpDc.selectedRisksRefs, def.productCode, def.riskCode, locId, dynVals.coverageOption, risk);
        return true; //eslint-disable-line consistent-return
      };
      /**
       * mapuje additionalData nieruchomosci z ryzyk majątkowych
       * @param {Estate} estate nieruchomosc
       * @param {String} risk ryzyko
       * @param {object} riskDef
       */
      this._mapEstateAdditionalDataFromRisk = function(estate, risk, riskDef) {
        if (!self.options.additionalData) {
          return;
        }
        fromRestEstateMapper.setAdditionalDataFromRisk(estate, risk, riskDef);
        fromRestEstateMapper.setTempDynamicValuesFromRisk(estate, risk, riskDef);
      };
      //#pragma mark personalRisks
      /**
       * mapuje ryzyko osobowe
       * @param {object} def definicja ryzyka z SPD
       * @param {Risk} risk ryzyko
       * @param {TempDataContainerModel} tmpDc
       */
      this._mapPersonalRisk = function(def, risk, tmpDc, options) { //eslint-disable-line consistent-return
        if (!risk.insuredSubjectsRef || typeof risk.insuredSubjectsRef[0] === 'undefined') {
          sp2CommonHelper.throwException('No subject reference found for personal risk {0}'.format(def.productCode));
        }
        var dynVals = risk.product.get('dynamicValues');
        if (typeof dynVals.coverageOption === 'undefined' || dynVals.coverageOption === '' || dynVals.coverageOption === null) {
          dynVals.coverageOption = CONSTANTS.VARIANT_I;
        }

        if (self.options.initSupplementData) { //init ryzyk z poprzedniej polisy w dokupieniach
          if (def.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) { //forma bezimienna
            self._initSupplementDataForRisk(tmpDc, risk, def, CONSTANTS.NNW_INCOGNITO_PERSON_ID, dynVals.coverageOption);
          } else { //zwykle oczp i nnw
            angular.forEach(risk.insuredSubjectsRef, function(subRef) {
              var subjId = tmpDc._subjectIdMap[subRef.ref];
              self._initSupplementDataForRisk(tmpDc, risk, def, subjId, dynVals.coverageOption);
            });
          }
          return true;
        }
        //tryb normalny
        //daty ochrony
        var start = self.utils.getFormattedJSProtectionDate(risk.get('start')),
          end = self.utils.getFormattedJSProtectionDate(risk.get('end'));
        if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
          tmpDc.propertyProtectionDates.start = start;
          tmpDc.propertyProtectionDates.end = end;
        } else {
          if (def.productCode === CONSTANTS.PRODUCT_NNW) {
            tmpDc.nnwProtectionDates.start = start;
            tmpDc.nnwProtectionDates.end = end;
          } else { //oczp
            if (CONFIG.BEHAVIOR.protectionDatesOnVariants.indexOf(CONSTANTS.PRODUCT_OCZP) !== -1) {
              tmpDc.oczpProtectionDates[dynVals.coverageOption].start = start;
              tmpDc.oczpProtectionDates[dynVals.coverageOption].end = end;
            } else {
              tmpDc.oczpProtectionDates.start = start;
              tmpDc.oczpProtectionDates.end = end;
            }
          }
        }

        if (def.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) { //forma bezimienna
          //mapowanie kwot
          //skladka matrycowa
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false), dynVals.coverageOption, 'premium');
          // taryfowa skladka
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, true), dynVals.coverageOption, 'tariffPremium');
          // koncowa skladka
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false), dynVals.coverageOption, 'finalPremium');
          // skladka przed zniżką indywidualną
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false, 'BEFORE_DISC'), dynVals.coverageOption, 'premBeforeDisc');
          // "surowa" skladka przed zniżką indywidualną
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false, 'PURE_BEFORE_DISC'), dynVals.coverageOption, 'premPureBeforeDisc');
          // skladka po zniżce indywidualnej
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false, 'AFTER_DISC'), dynVals.coverageOption, 'premAfterDisc');
          // "surowa" skladka po zniżce indywidualnej
          self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getRiskPremium(risk, false, 'PURE_AFTER_DISC'), dynVals.coverageOption, 'premPureAfterDisc');
          // zsumowana skladka za caly okres
          if (appVariables.isSupplement) {
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, self._getPremiumType('_extendPremiumAfterChange', null, null, risk), dynVals.coverageOption, 'summedPremium');
          }
          self._setRenewal(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, null, risk, dynVals.coverageOption);
          self._setNnwIncognitoData(risk, tmpDc);
          self.setSelectedRiskRef(tmpDc.selectedRisksRefs, CONSTANTS.PRODUCT_NNW_INCOGNITO, CONSTANTS.PRODUCT_NNW_INCOGNITO, CONSTANTS.NNW_INCOGNITO_PERSON_ID, dynVals.coverageOption, risk);
        } else { //zwykle oczp i nnw
          angular.forEach(risk.insuredSubjectsRef, function(subRef) {
            var pId = tmpDc._subjectIdMap[subRef.ref],
              subject = fromRestSubjectMapper.getSubjectByClientId(pId, tmpDc);
            //mapowanie atrybutów osób/firm
            self._mapSubjectAdditionalDataFromDynamicValues(subject, dynVals, def.productCode);
            self._mapSubjectAdditionalDataProductVariant(subject, def.productCode, dynVals.coverageOption);
            //mapowanie kwot
            //skladka matrycowa
            var premium = self.getPremiumForPersonalRisk(subject, def.productCode, false);
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, (premium !== null) ? premium : self._getRiskPremium(risk, false), dynVals.coverageOption, 'premium'); //premium z atrybutu osoby lub z ryzyka jesli brak cechy na osobie
            //taryfowa skladka
            var tariffPremium = self.getPremiumForPersonalRisk(subject, def.productCode, true);
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, (tariffPremium !== null) ? tariffPremium : self._getRiskPremium(risk, true), dynVals.coverageOption, 'tariffPremium');
            // skladka przed zniżką indywidualną
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, self._getRiskPremium(risk, false, 'BEFORE_DISC'), dynVals.coverageOption, 'premBeforeDisc');
            // "surowa" skladka przed zniżką indywidualną
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, self._getRiskPremium(risk, false, 'PURE_BEFORE_DISC'), dynVals.coverageOption, 'premPureBeforeDisc');
            //skladka po zniżce indywidualnej
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, self._getRiskPremium(risk, false, 'AFTER_DISC'), dynVals.coverageOption, 'premAfterDisc');
            // "surowa" skladka po zniżce indywidualnej
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, self._getRiskPremium(risk, false, 'PURE_AFTER_DISC'), dynVals.coverageOption, 'premPureAfterDisc');
            //koncowa skladka
            var finalPremium = self.getPremiumForPersonalRisk(subject, def.productCode, false);
            self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, (finalPremium !== null) ? finalPremium : self._getRiskPremium(risk, false), dynVals.coverageOption, 'finalPremium');
            //zsumowana skladka za caly okres
            if (appVariables.isSupplement) {
              self._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, self._getPremiumType('_extendPremiumAfterChange', null, null, risk), dynVals.coverageOption, 'summedPremium');
            }
            self._setRenewal(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, risk, dynVals.coverageOption);
            self._setSumInsured(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, risk.get('sum'), dynVals.coverageOption);
            self.setSelectedRiskRef(tmpDc.selectedRisksRefs, def.productCode, def.riskCode, pId, dynVals.coverageOption, risk);
          });
        }
        if (options.policyMode || options.renewalMode) {
          var productCode = (def.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) ? CONSTANTS.PRODUCT_NNW : def.productCode;
          selectionHelper.setVariant(productCode, dynVals.coverageOption, true, undefined, true);
        }
      };
      /**
       * ustawia w tempDatamanagerze nnwincognito
       * @param {Risk} risk ryzyko NNW incognito
       * @param {TempDataContainerModel} tmpDc
       */
      this._setNnwIncognitoData = function(risk, tmpDc) {
        var data = dataContainerHelper.getDefaultNnwIncognitoData(),
          dynVals = risk.product.get('dynamicValues'),
          variant = dynVals.coverageOption;
        data.su[variant] = risk.get('sum');
        data.insuredNo = dynVals.insuredNo;
        data.variants[variant] = true;
        tmpDc.nnwIncognito = data;
      };
      /**
       * zwraca premium dla NNW/OCZP dla konkretnej osoby
       * @param {Person|Organization} subject
       * @param {string} product NNW lub OCZP
       * @return {number|null} null gdy składka będzie miaţą być pobrana z poziomu ryzyka
       * @return {boolean} [tariffPremium=false] czy pobrac skladke taryfowa
       */
      this.getPremiumForPersonalRisk = function(subject, product, tariffPremium) {
        tariffPremium = (typeof tariffPremium === 'boolean') ? tariffPremium : false;
        if (CONFIG.BEHAVIOR.readPersonalRiskPremiumFromRisk) {
          return null;
        }
        var dynVals = subject.get('dynamicValues'),
          premium = null,
          attrName; // string

        switch (product) {
          case CONSTANTS.PRODUCT_NNW:
            attrName = (tariffPremium ? 'tariffPremiumForNNW' : 'premiumForNNW');
            break;
          case CONSTANTS.PRODUCT_OCZP:
            attrName = (tariffPremium ? 'tariffPremiumForOC' : 'premiumForOC');
            break;
          default:
            return null;
        }

        if (typeof dynVals[attrName] !== 'undefined') {
          premium = dynVals[attrName];
        }

        return premium;
      };
      /**
       * mapuje wariant ryzyka na additionalData podmiotu
       * @param {(Person|Organization)} podmiot
       * @param {string} prod typ produktu (OCZP, NNW, itd.)
       * @param {string} variant wariant
       */
      this._mapSubjectAdditionalDataProductVariant = function(subject, prod, variant) {
        if (!self.options.additionalData) {
          return;
        }
        fromRestSubjectMapper.setAdditionalDataProductVariant(subject, prod, variant);
      };
      /**
       * mapuje dynamicValues (np. ryzyka) na additionalData podmiotu (osoby/firmy)
       * @param {(Person|Organizatiuon)} subject podmiot
       * @param {object} dynamicValues atrybuty ryzyka
       */
      this._mapSubjectAdditionalDataFromDynamicValues = function(subject, dynamicValues, productCode) {
        if (!self.options.additionalData) {
          return;
        }
        fromRestSubjectMapper.setAdditionalDataFromDynamicValues(subject, dynamicValues, productCode);
      };
      //#pragma mark premiums
      /**
       * @param {TempDataContainerModel} tmpDc
       * @param {string} prodType typ produktu ryzyka (ogien, kra, nnw, oczp, oc, ac, nnwkip)
       * @param {string} prodId id produktu
       * @param {string} riskCode kod ryzyka
       * @param {(string|null)} subriskCode kod podryzyka (tylko ogien i kradziez)
       * @param {Risk} risk ryzyko
       * @param {(string|null)} variant opcjonalny wariant
       */
      this._setPremiums = function(tmpDc, prodType, prodId, riskCode, subriskCode, risk, variant) {
        //skladka matrycowa - jesli podglad polisy to skladka ostateczna, jesli wnioske, to taryfowa
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false), variant, 'premium');
        //skladka taryfowa
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, true), variant, 'tariffPremium');
        //skladka ostateczna
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false), variant, 'finalPremium');
        // skladka przed zniżką indywidualną
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false, 'BEFORE_DISC'), variant, 'premBeforeDisc');
        // skladka po zniżce indywidualnej
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false, 'AFTER_DISC'), variant, 'premAfterDisc');
        // "surowa" skladka przed zniżką indywidualną
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false, 'PURE_BEFORE_DISC'), variant, 'premPureBeforeDisc');
        // "surowa" skladka po zniżce indywidualnej
        self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getRiskPremium(risk, false, 'PURE_AFTER_DISC'), variant, 'premPureAfterDisc');

        //zsumowana skladka za caly okres
        if (appVariables.isSupplement) {
          self._setPremium(tmpDc, prodType, prodId, riskCode, subriskCode, self._getPremiumType('_extendPremiumAfterChange', null, null, risk), variant, 'summedPremium');
        }
      };
      /**
       * ustawia skladke
       * @param {TempDataContainerModel} tmpDc
       * @param {string} prodType typ produktu ryzyka (ogien, kra, nnw, oczp, oc, ac, nnwkip)
       * @param {string} prodId id produktu
       * @param {string} riskCode kod ryzyka
       * @param {(string|null)} subriskCode kod podryzyka (tylko ogien i kradziez)
       * @param {number} premium kwota
       * @param {(string|null)} variant opcjonalny wariant
       * @param {string} [type=premium] typ skladki (premium - domyslny, tariffPremium, finalPremium)
       */
      self._setPremium = function(tmpDc, prodType, prodId, riskCode, subriskCode, premium, variant, type) {
        if (!self.options.premium) {
          return;
        }
        if (!angular.isString(type)) {
          type = 'premium';
        }
        var container = type + 'List';
        self._setAmount(container, tmpDc, prodType, prodId, riskCode, subriskCode, premium, variant);
      };
      //#pragma mark
      /**
       * @param {TempDataContainerModel} tmpDc
       * @param {string} prodType typ produktu ryzyka (ogien, kra, nnw, oczp, oc, ac, nnwkip)
       * @param {string} prodId id produktu
       * @param {string} riskCode kod ryzyka
       * @param {(string|null)} subriskCode kod podryzyka (tylko ogien i kradziez)
       * @param {Risk} risk ryzyko
       * @param {(string|null)} variant opcjonalny wariant
       */
      this._setRenewal = function(tmpDc, prodType, prodId, riskCode, subriskCode, risk, variant) {
        var riskContinuation = 0;
        if (typeof risk.product.dynamicValues.riskContinuation !== 'undefined' && risk.product.dynamicValues.riskContinuation) {
          riskContinuation = 1;
        }
        self._setAmount('renewedList', tmpDc, prodType, prodId, riskCode, subriskCode, riskContinuation, variant);
      };
      //#pragma mark sumInsured
      /**
       * ustawia sume ubezpieczenia
       * @param {TempDataContainerModel} tmpDc
       * @param {string} prodType typ produktu ryzyka (ogien, kra, nnw, oczp, oc, ac, nnwkip)
       * @param {string} prodId id produktu
       * @param {string} riskCode kod ryzyka
       * @param {(string|null)} subriskCode kod podryzyka (tylko ogien i kradziez)
       * @param {number} value kwota
       * @param {(string|null)} variant opcjonalny wariant
       */
      self._setSumInsured = function(tmpDc, prodType, prodId, riskCode, subriskCode, sumInsured, variant) {
        if (!self.options.sumInsured) {
          return;
        }
        self._setAmount('suList', tmpDc, prodType, prodId, riskCode, subriskCode, sumInsured, variant);
      };
      //#pragma mark sumsPremiums
      /**
       * ustawia kwote dla danego ryzyka/obiektu/osoby w skladkach lub sumach ubezpieczen
       * używany jest również do określania czy ryzyko jest wznowieniem
       * @param {string} container suList lub premiumList
       * @param {TempDataContainerModel} tmpDc
       * @param {string} prodType typ produktu ryzyka (ogien, kra, nnw, oczp, oc, ac, nnwkip)
       * @param {string} prodId id produktu
       * @param {string} riskCode kod ryzyka
       * @param {(string|null)} subriskCode kod podryzyka (tylko ogien i kradziez)
       * @param {number} value kwota
       * @param {(string|null)} variant opcjonalny wariant
       */
      this._setAmount = function(container, tmpDc, prodType, prodId, riskCode, subriskCode, value, variant) {
        if (value === null) {
          value = 0;
        }
        var multiVariant = CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(riskCode) !== -1,
          propertyRisk = (self.utils.propertyProducts.indexOf(riskCode) !== -1) && riskCode !== CONSTANTS.RISK_FLOOD,
          communicationRisk = (self.utils.communicationProducts.indexOf(riskCode) !== -1),
          additionRisk = self.utils.isAddition(riskCode),
          premiumContext = (container !== 'suList');

        var prodDef = additionRisk ? self.utils.getAdditionDefinition(riskCode) : self.utils.getProductDef(riskCode);
        if ((multiVariant || propertyRisk) && (typeof variant === 'undefined' || variant === null)) {
          'No variant passed for multivariant risk: {0}.'.format(riskCode);
        }
        if (propertyRisk && (typeof subriskCode === 'undefined' || subriskCode === null)) {
          sp2CommonHelper.throwException('No subrisk code passed for property risk: {0}, product id: {1}.'.format(riskCode, prodId));
        }
        if (angular.isUndefined(tmpDc[container][prodType])) {
          tmpDc[container][prodType] = {};
        }
        if (prodId !== null) {
          if (angular.isUndefined(tmpDc[container][prodType][prodId])) {
            tmpDc[container][prodType][prodId] = {};
          }
          if (angular.isUndefined(tmpDc[container][prodType][prodId][riskCode])) {
            if (multiVariant || (!propertyRisk && !additionRisk && premiumContext) || (!premiumContext && !propertyRisk && angular.isDefined(prodDef.VARIANTLIST))) { //cale premium list oprocz dodatkow i ryzyk majatkowych posiada warianty, dla wszystkich ryzyk z wariantami suList powinno wyodrebniać każdy wariant
              tmpDc[container][prodType][prodId][riskCode] = {};
              angular.forEach(prodDef.VARIANTLIST, function(vnt) {
                tmpDc[container][prodType][prodId][riskCode][vnt.CODE] = 0;
              });
            } else if (propertyRisk) {
              tmpDc[container][prodType][prodId][riskCode] = {};
              if (premiumContext) {
                tmpDc[container][prodType][prodId][riskCode][CONSTANTS.SUM_NAME] = {};
                angular.forEach(prodDef.VARIANTLIST, function(vnt) {
                  tmpDc[container][prodType][prodId][riskCode][CONSTANTS.SUM_NAME][vnt.CODE] = 0;
                });
                angular.forEach(RESOURCES.FIRE_BURGLARY_RISKS_LIST, function(risksList, srName) {
                  tmpDc[container][prodType][prodId][riskCode][srName] = {};
                  angular.forEach(prodDef.VARIANTLIST, function(vnt) {
                    tmpDc[container][prodType][prodId][riskCode][srName][vnt.CODE] = 0;
                  });
                });
              }
            } else if (additionRisk && angular.isDefined(prodDef.VARIANTS)) { //dodatki z wariantami
              tmpDc[container][prodType][prodId][riskCode] = {};
              angular.forEach(prodDef.VARIANTS, function(vnt) {
                tmpDc[container][prodType][prodId][riskCode][vnt.CODE] = 0;
              });
            } else { //pozostale przypadki
              tmpDc[container][prodType][prodId][riskCode] = 0;
            }
          }
        } else if (typeof tmpDc[container][prodType][riskCode] === 'undefined') { //dodatki pakietowe
          if (typeof prodDef.VARIANTS !== 'undefined') {
            tmpDc[container][prodType][riskCode] = {};
            angular.forEach(prodDef.VARIANTS, function(vnt) {
              tmpDc[container][prodType][riskCode][vnt.CODE] = 0;
            });
          } else {
            tmpDc[container][prodType][riskCode] = 0;
          }
        }
        //właściwe uzupełnianie kwoty
        if (prodId !== null) {
          if (container === 'suList') { //ewentualne zaokraglanie
            value = dataContainerHelper.roundSumInsured(riskCode, subriskCode, value);
          }
          if (multiVariant || (!propertyRisk && !additionRisk && premiumContext) || (!premiumContext && !propertyRisk && angular.isDefined(prodDef.VARIANTLIST))) {
            tmpDc[container][prodType][prodId][riskCode][variant] += value;
          } else if (propertyRisk) {
            if (premiumContext) {
              tmpDc[container][prodType][prodId][riskCode][subriskCode][variant] += value;
              tmpDc[container][prodType][prodId][riskCode][CONSTANTS.SUM_NAME][variant] += value;
            } else {
              tmpDc[container][prodType][prodId][riskCode][subriskCode] = value;
            }
          } else if ((communicationRisk && premiumContext) || (additionRisk && variant !== null)) { //premiums dla komunikacji lub dodatki z wariantami
            tmpDc[container][prodType][prodId][riskCode][variant] += value;
          } else {
            tmpDc[container][prodType][prodId][riskCode] += value;
          }
        } else { //dodatki pakietowe
          if (variant !== null) {
            tmpDc[container][prodType][riskCode][variant] += value;
          } else {
            tmpDc[container][prodType][riskCode] += value;
          }
        }

      };

      /**
       * oblicza sumę dla dodatku
       * @param  {object} additionPremiums obiekt składek dodatku, dla variantów to obiekt danego wariantu
       */
      this._calculateAdditionSum = function(additionPremiums) {
        var sum = 0;
        angular.forEach(additionPremiums, function(premium, key) {
          if (key !== CONSTANTS.SUM_NAME) {
            sum += premium;
          }
        });
        additionPremiums[CONSTANTS.SUM_NAME] = sum;
      };
      //#pragma mark simpleRisks
      /**
       * mapuje ryzyka polisy (Risk) do SimpleRisk
       * UWAGA
       * dataManager musi miec uzupelnione clientId na obiektach osób/firm/nieruchomosci/pojazdow
       * @param {Policy} policy REST polisa
       * @param {DataContainer} dataManager
       * @param {object} options opcje mapowania
       * @return {FromRestSimpleRiskModel[]} ryzyka do paragonów
       */
      this.mapToSimpleRisks = function(policy, dataManager, options) {
        self._setOptions(options);
        var simpleRisks = {},
          simpleRisksArray = [],
          objectId = null,
          objectType = null,
          risksToOmit = self.getRisksToOmit();
        if (policy.product.get('compId') === SPD.salesProducts.Communication.idpm) {
          if (!angular.isObject(policy.vehicles) || angular.isUndefined(policy.vehicles[0])) {
            sp2CommonHelper.throwException('No vehicle found for policy.');
          }
          objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, policy.vehicles[0].metaData.get('id'));
          objectType = CONSTANTS.PRODUCT_TYPE_VEHICLE;
        }
        lsnNg.forEach(policy.risks, function(risk, idx) {
          if (risk.product.compId === null || risk.product.compId === '') {
            sp2CommonHelper.throwException('No product.compId found for risk with index {0}'.format(idx));
          }
          var def = self.utils.findRiskDefByIdpm(risk.product.compId);
          if (risksToOmit.indexOf(def.riskCode) !== -1) { //pomijamy niektóre ryzyka
            return;
          }
          self._mapSimpleRisk(risk, def, objectId, objectType, simpleRisks, dataManager, policy);
        });
        angular.forEach(simpleRisks, function(simpleRisk) {
          simpleRisksArray.push(simpleRisk);
        });
        return simpleRisksArray;
      };
      /**
       * mapuje i dodaje SimpleRisk do listy ryzyk
       * UWAGA:
       * Dla polisy komunikacyjnej objectId i objectType powinny być zawsze uzupelnione w argumentach niniejszej metody
       * @param {Risk} risk ryzyko
       * @param {object} riskDef definicja ryzyka z SPD
       * @param {(null|string)} objectId id obiektu
       * @param {(null|string)} objectType typ obiektu vehicle/localization/person
       * @param {FromRestSimpleRiskModel[]} simpleRisks kolekcja SimpleRisk
       * @param {DataContainer} dataManager
       */
      this._mapSimpleRisk = function(risk, riskDef, objectId, objectType, simpleRisks, dataManager, policy) {
        var dynVals = risk.product.get('dynamicValues'),
          variant = self.getRiskVariant(riskDef, dynVals),
          objRefs; // (array) tablica powiązań ryzyka z obiektami
        if (objectId !== null && objectType !== null) { //ryzyko komunikacyjne
          self._addSimpleRisk(simpleRisks, risk, riskDef, variant, objectId, objectType);
        } else if (self.utils.propertyProducts.indexOf(riskDef.productCode) !== -1) { //ryzyka majątkowe
          objectType = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
          if (risk.get('insuredObjectsRef').length === 0) {
            if (self.options.tarifficationMode) {
              return;
            }
            sp2CommonHelper.throwException('No object reference found for risk {0}.'.format(riskDef.riskCode));
          }
          objRefs = risk.insuredObjectsRef;
          objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, objRefs[0].get('ref'));
          self._addSimpleRisk(simpleRisks, risk, riskDef, variant, objectId, objectType, policy);
        } else if (self.utils.personalProducts.indexOf(riskDef.productCode) !== -1) { //ryzyko osobowe
          objectType = CONSTANTS.PRODUCT_TYPE_PERSON;
          if (risk.get('insuredSubjectsRef').length > 0) {
            angular.forEach(risk.insuredSubjectsRef, function(subjRef) {
              objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON, subjRef.get('ref'));
              self._addSimpleRisk(simpleRisks, risk, riskDef, variant, objectId, objectType, policy);
            });
          }
        } else if (self.utils.personGroupProducts.indexOf(riskDef.productCode) !== -1) {
          objectType = CONSTANTS.PRODUCT_TYPE_PERSON_GROUP;
          if (angular.isArray(risk.insuredSubjectsRef) && angular.isDefined(risk.insuredSubjectsRef[0])) { //eyzyko dla osoby
            objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, risk.insuredSubjectsRef[0].ref);
          } else if (angular.isDefined(dynVals._groupId)) { //ryzyko dla grupy
            objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, dynVals._groupId);
          }
          if (objectId !== null) {
            self._addSimpleRisk(simpleRisks, risk, riskDef, variant, objectId, objectType, policy);
          }
        } else if (self.utils.isAddition(riskDef.riskCode)) { //pozostałe ryzyka - dodatki
          var addDef = self.utils.getAdditionDefinition(riskDef.riskCode);
          switch (addDef.TYPE) {
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            case CONSTANTS.PRODUCT_TYPE_PACKAGE:
              objectType = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
              objRefs = risk.insuredObjectsRef;
              if (risk.get('insuredObjectsRef').length !== 0) { //obiekt podpiety pod ryzyko
                objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, objRefs[0].get('ref'));
              }
              if (risk.get('insuredObjectsRef').length === 0 || objectId === null) {
                var estate = self.utils.getFirstItemFromObject(dataManager.localizations);
                if (estate === null) { //brak lokalizacji, podpinamy pod głównego ubezpieczonego
                  if (risk.get('insuredSubjectsRef').length === 0) {
                    sp2CommonHelper.throwException('Can not assign simpleRisk (risk {0}). No estates and persons on risk.'.format(riskDef.riskCode));
                  }
                  objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON, risk.insuredSubjectsRef[0].get('ref'));
                  objectType = CONSTANTS.PRODUCT_TYPE_PERSON;
                } else //obiekt podpinamy pod pierwsza lokalizacje z brzegu
                {
                  objectId = estate.metaData.get('clientId');
                }
              }
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
              objectType = CONSTANTS.PRODUCT_TYPE_PERSON_GROUP;
              if (angular.isArray(risk.insuredSubjectsRef) && angular.isDefined(risk.insuredSubjectsRef[0])) { //eyzyko dla osoby
                objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, risk.insuredSubjectsRef[0].ref);
              } else if (angular.isDefined(dynVals._groupId)) { //ryzyko dla grupy
                objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, dynVals._groupId);
              }
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON:
              objectType = CONSTANTS.PRODUCT_TYPE_PERSON;
              objRefs = risk.insuredSubjectsRef;
              if (risk.get('insuredSubjectsRef').length !== 0) { //obiekt podpiety pod ryzyko
                objectId = self.utils.getClientIdForObject(CONSTANTS.PRODUCT_TYPE_PERSON, objRefs[0].get('ref')); //pod dodatek powinien być podpiety tylko glowny ubezpieczony, dlatego tak mozemy zalozyc
              } else { //glowny ubezpieczony
                objectId = dataManager.mainInsuredId;
              }
              break;
            default:
              return;
          }
          self._addSimpleRisk(simpleRisks, risk, riskDef, variant, objectId, objectType, policy);
        } else {
          sp2CommonHelper.throwException('Not supported risk ({0}) in simpleRisk mapping.'.format(riskDef.riskCode));
        }
      };

      this._getObjectFromPolicy = function(policy, objectType, objectClientId) {
        var containerNames = [],
          object = null;
        switch (objectType) {
          case CONSTANTS.PRODUCT_TYPE_PERSON:
            containerNames.push('persons');
            containerNames.push('organizations');
            break;
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            containerNames.push('vehicles');
            break;
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            containerNames.push('localizations');
            break;
          default:
            break;
        }
        lsnNg.forEach(containerNames, function(containerName) {
          if (typeof policy[containerName] !== 'undefined') {
            lsnNg.forEach(policy[containerName], function(obj) {
              if (obj.metaData.get('clientId') === objectClientId) {
                object = obj;
                return false;
              }
              return true; //continue
            });
          }
          if (object !== null) {
            return false;
          }
          return true; //continue
        });
        return object;
      };

      /**
       * dodaje lub aktualizuje proste ryzyko na liście zmapowanych ryzyk
       * @param {FromRestSimpleRiskModel[]} simpleRisks kolekcja SimpleRisk
       * @param {Risk} risk ryzyko
       * @param {object} riskDef definicja ryzyka z SPD
       * @param {string} variant wariant
       * @param {(null|string)} objectId id obiektu
       * @param {(null|string)} objectType typ obiektu vehicle/localization/person
       */
      this._addSimpleRisk = function(simpleRisks, risk, riskDef, variant, objectId, objectType, policy) {
        var key = riskDef.productCode + '_' + variant + '_' + objectId;

        var object;
        var premium = null;
        if (riskDef.productCode === CONSTANTS.PRODUCT_OCZP || riskDef.productCode === CONSTANTS.PRODUCT_NNW) {
          object = self._getObjectFromPolicy(policy, objectType, objectId);
          premium = self.getPremiumForPersonalRisk(object, riskDef.productCode);
        }
        if (premium === null) {
          if (appVariables.isSupplement && appVariables.appMode !== CONSTANTS.APP_MODE_POLICY) {
            premium = risk.isHistorical ? 0 : self._getPremiumType('premium', null, null, risk);
          } else {
            premium = self._getRiskPremium(risk);
          }
        }

        if (typeof simpleRisks[key] === 'undefined') {
          var riskCode = self.utils.getConfigProductCode(riskDef.productCode);

          var simpleRisk = new FromRestSimpleRiskModel({
            code: riskCode,
            premium: premium,
            salesPremium: premium, //czasami składka "do zapłaty" może różnić się od składki na ryzyku (np. oc nabywcy)
            variant: variant,
            objectId: objectId,
            objectType: objectType,
            discountRisePercentage: risk.product.dynamicValues.discountRisePercentage
          });
          self._addSimpleRiskAttributes(simpleRisk, risk);
          if (angular.isDefined(simpleRisk.attributes.sellerPremiumPart)) {
            simpleRisk.salesPremium = Number((simpleRisk.salesPremium - simpleRisk.attributes.sellerPremiumPart).toFixed(2)); //jezeli zbywca zaplacil czesc skladki, to odejmujemy ja od skladki na ryzyku - da to skladke do zaplaty
          }
          simpleRisks[key] = simpleRisk;
        } else {
          simpleRisks[key].premium += premium;
        }
      };

      /**
       * dodajemy dodatkowe atrybuty potrzebne dla niektórych ryzyk
       * @param {SimpleRisk} simpleRisk
       * @param {Risk} risk
       */
      this._addSimpleRiskAttributes = function(simpleRisk, risk) {
        if (typeof risk.product.dynamicValues.expandTurbo !== 'undefined') {
          simpleRisk.attributes.expandTurbo = risk.product.dynamicValues.expandTurbo;
        }
        //oc nabywcy - skladka przeksiegowana z polisy zbywcy
        if ((appVariables.isOcBuyer || risk.product.dynamicValues.ocBuyer) && simpleRisk.code === CONSTANTS.PRODUCT_OCKOM) {
          simpleRisk.attributes.sellerPremiumPart = risk.product.dynamicValues.vehicleSellerPremiumPart || 0;
        }
      };

      //#pragma mark risks
      /**
       * zwraqca wariant na podsatiwe SPD ryzyka i atrybutów
       * @param {object} riskDef definicja ryzyka z SPD
       * @param {object} dynamicValues atrybuty ryzyka
       * @return {string} wariant
       */
      this.getRiskVariant = function(riskDef, dynamicValues) {
        if (angular.isDefined(riskDef.variant) && riskDef.variant.length === 1) { //ryzyka z konkretnym wariantem w SPD
          return riskDef.variant[0];
        }
        if (angular.isDefined(dynamicValues.coverageOption)) { //sczytywanie wariantu z atrybutu coverageOption
          if (self.utils.isAddition(riskDef.productCode)) { //dodatek
            return self._getAdditionVariant(riskDef.productCode, dynamicValues.coverageOption);
          } else {
            return dynamicValues.coverageOption;
          }
        }
        if (typeof dynamicValues.assistanceOption !== 'undefined') { //przypadek HCA AC
          return self.utils.getHCAVariantFromAssistanceOption(dynamicValues.assistanceOption);
        }
        return '';
      };

      /**
       * ustawia rozszerzenia w dataContainerze
       * @param {String} prodType typ produktu
       * @param {Object} riskDynVals product.dynamicValues ryzyka
       * @param {String|Number} objId id obiektu, dla którego należy zaznaczyć rozszerzenie
       */
      this.setExtensions = function(prodType, riskDynVals, objId) {
        if (!CONFIG.MODULES.extensions || !(self.options.policyMode || self.options.renewalMode)) {
          return;
        }
        var extBosCodeToCode = {};
        angular.forEach(RESOURCES.EXTENSIONDEF, function(extData) {
          if (angular.isDefined(extData.BOS_CODE) && extData.TYPE === prodType) {
            extBosCodeToCode[extData.BOS_CODE] = extData.CODE;
          }
        });
        angular.forEach(riskDynVals, function(selected, attrName) {
          if (angular.isDefined(extBosCodeToCode[attrName]) && selected) {
            extensionHelper.addExt(extBosCodeToCode[attrName], objId);
          }
        });
      };

      /**
       * inicjalizuje dane dotyczące dokupień dla ryzyka z poprzedniej wersji polisy
       * @param  {TempDataContainer} tmpDc
       * @param  {RiskModel} risk ryzyko
       * @param  {Object} def definicja ryzyka  spd
       * @param  {String} [objId] clientId ubezpieczonego obiektu/podmiotu
       * @param  {String} [variant] wariant ryzyka
       */
      this._initSupplementDataForRisk = function(tmpDc, risk, def, objId, variant) {
        self.setPreviousRiskSelection(tmpDc.previousRisksSelection, risk, def, objId, variant);
        self.setSelectedRiskRef(tmpDc.previouslySelectedRisksRefs, def.productCode, def.riskCode, objId, variant, risk);
        self.setAllowedRisk(tmpDc, risk.metaData.get('id'), def, objId, variant);
      };

      /**
       * ustawia zaznaczenie dla ryzyka w przekazanym obiekcie (selection)
       * @param {Object} selection to, co w dataContainer.previousRisksSelection
       * @param {Object} riskDef   definicja ryzyka z spd
       * @param {String} objId     [description]
       * @param {String} [variant]   [description]
       */
      this.setPreviousRiskSelection = function(selection, risk, riskDef, objId, variant) {
        var prodType = resourceHelper.productType[riskDef.productCode],
          isAddition = self.utils.isAddition(riskDef.riskCode);

        if (isAddition) { //dodatki
          var addDef = self.utils.getAdditionDefinition(riskDef.riskCode),
            variantAdd = angular.isDefined(addDef.VARIANTS), //czy dodatek z wariantami
            packageAdd = addDef.TYPE === CONSTANTS.PRODUCT_TYPE_PACKAGE; //czy dodatek pakietowy
          //najpierw inicjalizujemy kontener z danymi
          if (angular.isUndefined(selection.additions[riskDef.riskCode])) {
            if (variantAdd) {
              selection.additions[riskDef.riskCode] = {};
              angular.forEach(addDef.VARIANTS, function(variantDef) {
                selection.additions[riskDef.riskCode][variantDef.CODE] = (packageAdd ? false : []);
              });
            } else {
              selection.additions[riskDef.riskCode] = (packageAdd ? false : []);
            }
          }

          //ustawiamy warianty          
          if (variantAdd) {
            if (packageAdd) {
              selection.additions[riskDef.riskCode][variant] = true;
            } else {
              selection.additions[riskDef.riskCode][variant].push(objId);

              //dodawanie wariantów dodatków które w usługach są atrybutem na dodatku - np. wariant Turbo w E7 i MF
              var dynVals = risk.product.get('dynamicValues');
              if (RESOURCES.ADDITIONS_ATTRIBUTES[riskDef.riskCode] && RESOURCES.ADDITIONS_ATTRIBUTES[riskDef.riskCode][variant]) {
                var dynValAttributeName = RESOURCES.ADDITIONS_ATTRIBUTES[riskDef.riskCode][variant].attrName; //eslint-disable-line
                var variantToAdd = RESOURCES.ADDITIONS_ATTRIBUTES[riskDef.riskCode][variant].addVariant; //eslint-disable-line
                if (angular.isDefined(dynVals[dynValAttributeName]) && dynVals[dynValAttributeName]) {
                  selection.additions[riskDef.riskCode][variantToAdd].push(objId);
                }
              }
            }
          } else {
            if (packageAdd) {
              selection.additions[riskDef.riskCode] = true;
            } else {
              selection.additions[riskDef.riskCode].push(objId);
            }
          }
        } else { //inne ryzyka
          //najpierw inicjalizujemy kontener z danymi
          if (angular.isUndefined(selection[prodType])) {
            selection[prodType] = {};
          }
          if (angular.isUndefined(selection[prodType][riskDef.productCode])) {
            selection[prodType][riskDef.productCode] = {};
          }
          //wspolny wariant ognia i kradziezy
          var sharedFBVariant = CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && variant === CONSTANTS.VARIANT_III && (riskDef.productCode === CONSTANTS.PRODUCT_OGI || riskDef.productCode === CONSTANTS.PRODUCT_KRA);
          if (sharedFBVariant && !selection[prodType][CONSTANTS.PRODUCT_OGI_KRA]) {
            selection[prodType][CONSTANTS.PRODUCT_OGI_KRA] = {};
            selection[prodType][CONSTANTS.PRODUCT_OGI_KRA][variant] = [];
          }
          if (angular.isUndefined(selection[prodType][riskDef.productCode][variant])) {
            selection[prodType][riskDef.productCode][variant] = [];
          }
          //ustawianie wariantu
          if (selection[prodType][riskDef.productCode][variant].indexOf(objId) === -1) {
            selection[prodType][riskDef.productCode][variant].push(objId);
          }
          if (sharedFBVariant && selection[prodType][CONSTANTS.PRODUCT_OGI_KRA][variant].indexOf(objId) === -1) {
            selection[prodType][CONSTANTS.PRODUCT_OGI_KRA][variant].push(objId);
          }
        }
      };

      /**
       * ustawia referencje ryzyka "interfejsowego" do metaData.id ryzyka z modelu polisy,wniosku
       * Format ustawianych danych opisany w DataContainer.selectedRisksRefs
       * @param {Array} tmpDcContainer tablica w tymczasowym dataContainerze, w której umieszczamy informacje (np. selectedRisksRefs)
       * @param {String} product kod produktu
       * @param {String} risk kod ryzyka (w dodatkach = kodowi produktu)
       * @param {String} [objId] clientId obiektu lub podmiotu ubezpieczonego
       * @param {String} [variant]
       * @param {RiskModel} risk ryzyko
       */
      this.setSelectedRiskRef = function(tmpDcContainer, productCode, riskCode, objId, variant, risk) {
        tmpDcContainer.push({
          product: productCode,
          risk: riskCode || productCode,
          variant: variant,
          objId: objId,
          riskId: risk.metaData.get('id'),
          riskIdpm: risk.product.get('compId')
        });
      };

      /**
       * ustawia dozwolone ryzyko w tmpDc.allowedRisksVariants
       * @param {TempDataContainer|DataContainer} tmpDc    [description]
       * @param {String} riskIdpm idpm ryzyka z spd
       * @param {Object} def      definicja ryzyka z SPD
       * @param {String} [objId]  id ubezpieczonego obiektu/podmiotu
       * @param {String} [variant]  aktulanie ustawiony wariant ryzyka
       * @param {String} [variant]  wariant ubezpieczenia
       */
      this.setAllowedRisk = function(tmpDc, riskIdpm, def, objId, variant) {
        var allowedVariants = [];
        if (variant) {
          allowedVariants.push(variant);
        }
        if (tmpDc.allowedRisksVariants === null) { //inicjalizujemy tablice
          tmpDc.allowedRisksVariants = [];
        }
        //szukamy dostępnych wariantów przekazanych z usługi
        if (angular.isObject(tmpDc.allowedChanges) && angular.isObject(tmpDc.allowedChanges.risks)) {
          lsnNg.forEach(tmpDc.allowedChanges.risks.existingRisks.concat(tmpDc.allowedChanges.risks.newRisks), function(riskData) { //przeszukujemy istniejace poprzednio i nowe ryzyka
            if (riskData.metaData && riskData.metaData.id === riskIdpm) {
              allowedVariants = allowedVariants.concat(self.utils._getAllowedRiskVariants(riskData.attributes, def));
              return false;
            }
            return true;
          });
        }
        tmpDc.allowedRisksVariants.push({
          product: def.productCode,
          risk: def.riskCode,
          allowedVariants: lsnArrayUnique(allowedVariants),
          objId: objId
        });
        //obsluga wspoldzielonego wariantu w ogi i kra
        var sharedFBVariant = CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && (def.productCode === CONSTANTS.PRODUCT_OGI || def.productCode === CONSTANTS.PRODUCT_KRA);
        if (sharedFBVariant && lsnUtils.findObjInArray(tmpDc.allowedRisksVariants, {
          product: CONSTANTS.PRODUCT_OGI_KRA,
          risk: def.riskCode,
          objId: objId
        }) === null) {
          tmpDc.allowedRisksVariants.push({
            product: CONSTANTS.PRODUCT_OGI_KRA,
            risk: def.riskCode,
            allowedVariants: allowedVariants,
            objId: objId
          });
        }
      };

      /**
       * zwraca idpmy dla główych ubezpieczonych wg SPD
       * @return {String[]}
       */
      this.getMainSubjectsIdpms = function() {
        return [SPD.subjects[CONSTANTS.SUBJECT_INSURED], SPD.subjects[CONSTANTS.SUBJECT_OWNER]];
      };

      /**
       * zwraca ryzyka do pominiecia w mapowaniu na podstawie aktualnego SPD
       * @return {String[]} kody ryzyk
       */
      this.getRisksToOmit = function() {
        return CONFIG.BEHAVIOR.risksToOmit;
      };
    };

    return new FromRestRiskMapper();
  }
]);