angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryApplicationTypeSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryApplicationTypeSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'applicationType';
      };
      return new RestDictionaryApplicationTypeSvc();
    }
  ]);