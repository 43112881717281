angular.module('salesPath2')
  .controller('oczpCtrl', ['$scope', 'oczpHelper', 'personModalHelper', 'sp2CommonHelper', 'specializationModalHelper', 'resourceHelper', '$filter', 'dataContainerHelper',
    function($scope, oczpHelper, personModalHelper, sp2CommonHelper, specializationModalHelper, resourceHelper, $filter, dataContainerHelper) {
      $scope.tplData = oczpHelper.tplData;
      $scope.headerSettings = {}; //ustawienia nagłówka
      $scope.oczpHelper = oczpHelper;
      $scope.specializationModalSettings = specializationModalHelper.specializationModalSettings;
      $scope.activeProductList = [$scope.CONSTANTS.PRODUCT_OCZP];
      $scope.numericSettings1 = { //m.in. do liczby osób w gospodarstwie domowym
        decimals: 0,
        min: 1,
        max: 99,
        allowLeadingZeros: false,
        parseToString: false
      };
      $scope.init = function() {
        oczpHelper.initData();
        $scope.initHeaderSettings();
        if ($scope.tplData.oczpInsured.length === 0) {
          $scope.showAddPersonModal();
        }
      };

      /**
       * inicjalizacja ustawien dla naglowka
       */
      $scope.initHeaderSettings = function() {
        if (!$scope.PAGE_CONFIG.OCZP.header.enabled) {
          return;
        }
        $scope.headerSettings = angular.copy($scope.PAGE_CONFIG.OCZP.header);
        if (oczpHelper.tplData.datesPerVariant) { //daty ochrony per wariant
          $scope.headerSettings.protectionDates.enabled = false;
        } else {
          $scope.headerSettings.protectionDates.enabled = true;
          if ($scope.CONFIG.BEHAVIOR.propertyOneProtectionPeriod) { //wspólne daty dal mienia i osobowych
            $scope.headerSettings.protectionDates.ref = 'propertyProtectionDates';
          } else {
            $scope.headerSettings.protectionDates.ref = 'oczpProtectionDates';
          }
        }
        //wszystkie ustawienia oprócz "enabled" są zgodne z sp2HeaderHelper.DEFAULT_HEADER_SETTINGS
        delete $scope.headerSettings.enabled;
      };

      //wyswietla modal wyboru specjalizacji
      $scope.showSpecializationModal = function(clientId, jobCode) {
        specializationModalHelper.showSpecializationModal(clientId, jobCode);
      };

      /**
       * modal dodawania osoby
       */
      $scope.showAddPersonModal = function() {
        personModalHelper.openPopupAddPerson({
          context: $scope.CONSTANTS.PRODUCT_OCZP,
          title: $filter('translate')('sp2.modal.personAddModal.editHeader', {componentCode: 'sp2'}),
          titleSide: 'Dodaj osoby wybierając jedną z możliwości:',
          searchTitle: 'Wyszukaj Klienta:',
          searchHeader: 'Wyszukaj osobę',
          editHeader: 'Edytuj osobę',
          excludeIds: Object.keys(dataContainerHelper.getPersonsForPersonalProduct($scope.CONSTANTS.PRODUCT_OCZP))
        });
      };

      //otwarcie modala edycji osoby
      $scope.showEditPersonModal = function(clientId) {
        personModalHelper.openPopupEditPerson({
          id: clientId,
          editHeader: $filter('translate')('insured', {componentCode: 'Public'})
        });
      };

      //usuwanie osoby z listy ubezpieczonych
      $scope.deletePerson = function(clientId) {
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
          text: ['insuredPersonDeletionConfirm', {componentCode: 'sp2'}],
          okBtnCallback: function() {
            oczpHelper.deleteInsured(clientId);
          }
        });
      };

      /**
       * po zmianie profesji/zawodu
       * @param  {String} clientId id osoby
       * @param  {String} jobCode kod profesji/zawwodu
       * @return boolean false if readonly mode
       */
      $scope.onPersonJobChange = function(clientId, jobCode) {
        if ($scope.appVariables.readOnly || !$scope.dataContainer.allowedProducts[$scope.CONSTANTS.PRODUCT_OCZP]) {
          return false;
        }
        oczpHelper.personJobHasBeenChanged(clientId, jobCode);
        var jobDef = resourceHelper.getOczpJobDef(jobCode);
        if (jobDef.SPECIALIZATIONS) {
          specializationModalHelper.showSpecializationModal(clientId, jobCode);
        }
        return true;
      };

      $scope.init();
    }
  ]);