angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseClientDataCtrl', ['$scope', 'clientDataHelper',
    function($scope, clientDataHelper) {

      $scope.additionalData = {
        dataIsTruth: false
      };

      $scope.init = lsnNg.noop;

      /**
       * dodajemy następnego kupującego/sprzedającego
       */
      $scope.addNextClient = function()
      {
        var personData = clientDataHelper.prepareNewPerson();
        personData.metaData.id = $scope.getNextPersonId();
        $scope.shared.coowners.push(personData);
      };

      /**
       * usunięcie osoby
       * @param  {[type]} personKey [description]
       */
      $scope.removePerson = function(personId)
      {
        var personToRemoveKey = null;
        angular.forEach($scope.shared.coowners, function(coowner, tabKey){
          if(coowner && coowner.metaData && coowner.metaData.id === personId)
          {
            personToRemoveKey = tabKey;
          }
        });
        if(personToRemoveKey !== null)
        {
          $scope.shared.coowners.splice(personToRemoveKey, 1);
        }
      };
    }
  ]);