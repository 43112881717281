angular.module('salesPath2')
  .service('ceidgModalHelper', ['$rootScope', 'sp2CommonHelper',
    function($rootScope, sp2CommonHelper) {
      var CeidgModalHelper = function() {
        var self = this;

        this.modals = {};

        this.modals.ceidgSearchModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.ceidgSearchModal.title',
            cancelBtnName: 'Public.cancel',
            okBtnName: 'sp2.modal.search',
            headerListModel: 'modals.ceidgSearchModal.type',
            size: 'md'
          }
        };

        this.modals.ceidgClientFoundModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.ceidgClientFoundModal.title',
            cancelBtnName: 'Public.cancel',
            okBtnName: 'sp2.modal.okBtnName.ok',
            headerListModel: 'modals.ceidgClientFoundModal.type',
            size: 'md'
          },
          data: {}
        };


        /**
        * Okno wyszukiwania klienta w CEIDG
        */
        this.openSearchCeidgClientModal = function()
        {
          sp2CommonHelper.showModal('ceidgSearchModal');
        };

        /**
        * Okno wyszukanych danych
        */
        this.showCeidgClientFoundModal = function(data)
        {
          self.modals.ceidgClientFoundModal.data = data;
          sp2CommonHelper.showModal('ceidgClientFoundModal');
        };
      };
      return new CeidgModalHelper();
    }
  ]);
