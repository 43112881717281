angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonComponentRegisterListSvc', ['ihestiaCommonAbstractComponentRegisterSvc',
    function(ihestiaCommonAbstractComponentRegisterSvc) {

      var ComponentRegisterListSvc = function() {
        ihestiaCommonAbstractComponentRegisterSvc.apply(this, arguments);

        this.resource = 'component/list';
      };
      return new ComponentRegisterListSvc();
    }
  ]);