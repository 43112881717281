angular.module('ihestiaWidgets.policyOperations')
  .factory('policyOperationsV2Svc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PolicyOperationsV2Svc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'policyOperations';
        this.businessArea = 'policies';
        this.apiVersion = 'v2';
        this.apiString = 'pr/api';
      };

      return new PolicyOperationsV2Svc();
    }]);
