angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaDictionaryModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var DictionaryModel = function() {
      this.objectName = 'DictionaryModel';

      this.fieldsProperties = {
        id: {
          type: 'int'
        },
        code: {
          type: 'string'
        },
        description: {
          type: 'string'
        }
      };

      this.id = null;
      this.code = null;
      this.description = null;

    };


    DictionaryModel.prototype = IHestiaAbstractModel.prototype;

    return DictionaryModel;
  }]);