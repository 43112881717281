angular.module('salesPath2.spd')
  .factory('spdTmpObjectsDynamicValues', [function() {

    /*
     * Dopisane "recznie" atrybuty na skutek braku SPD dla MF/E1
     * [wymiar, typ, czy_pomijac_w_uslugach_rest], czy_pomijac_w_uslugach_rest - domyslnie false, jesli true, atrybut nie będzie wysyłany
     */
    var objectsDynamicValues = {
      Person: {},
      Organization: {},
      Vehicle: {},
      Estate: {},
      Group: {}
    };
    //#pragma mark Person
    objectsDynamicValues.Person._additionalData = ['Simple', 'String']; // dane dodatkowe ze sciezki sprzedazy
    objectsDynamicValues.Person._tmpDynamicValues = ['Simple', 'String']; // zserializowane atrybuty z czy_pomijac_w_usţugach_rest = true
    //atrybuty spoza spd, wykorzystywane na sciezce sprzedazy
    objectsDynamicValues.Person.pkdCode = ['Collection', 'String', true]; // kody PKD - deprecated! używamy teraz węzła pkd bezpośrednio na osobie
    objectsDynamicValues.Person._pkdCode = ['Collection', 'String']; // pkd kod na potrzeby pakietu MF - na glownym ubezpieczonym - deprecated! używamy teraz węzła pkd bezpośrednio na osobie
    objectsDynamicValues.Person._extensionSelected = ['Simple', 'Boolean']; // czy wybrano rozszerzenie
    objectsDynamicValues.Person._OCBMmode = ['Simple', 'String']; // tryb BM dla OC
    objectsDynamicValues.Person._ACBMmode = ['Simple', 'String']; // tryb BM dla AC
    objectsDynamicValues.Person._OCPreviousPolicyNumber = ['Simple', 'String']; // nr poprzedniej polisy dla OC
    objectsDynamicValues.Person._ACPreviousPolicyNumber = ['Simple', 'String']; // nr poprzedniej polisy dla AC
    objectsDynamicValues.Person._OCNoClaimInsuranceContinuation = ['Simple', 'Integer']; // LLB dla OC
    objectsDynamicValues.Person._ACNoClaimInsuranceContinuation = ['Simple', 'Integer']; // LLB dla AC

    //#pragma mark Organization
    objectsDynamicValues.Organization._additionalData = ['Simple', 'String']; // dane dodatkowe ze sciezki sprzedazy
    objectsDynamicValues.Organization._tmpDynamicValues = ['Simple', 'String']; // zserializowane atrybuty z czy_pomijac_w_usţugach_rest = true

    //#pragma mark Vehicle
    objectsDynamicValues.Vehicle._additionalData = ['Simple', 'String']; // dane dodatkowe ze sciezki sprzedazy
    objectsDynamicValues.Vehicle._tmpDynamicValues = ['Simple', 'String']; // zserializowane atrybuty z czy_pomijac_w_usţugach_rest = true
    objectsDynamicValues.Vehicle._leased = ['Simple', 'Boolean']; // czy jest przedmiotem leasingu - atr uzywany do walidacji po stronie REST
    objectsDynamicValues.Vehicle._hasNoVin = ['Simple', 'Boolean']; // czy pojazd nie posiada numeru VIN
    objectsDynamicValues.Vehicle._hasCoowners = ['Simple', 'Boolean']; // czy pojazd posiada współwłaścicieli (zmiana pod earnix)
    objectsDynamicValues.Vehicle._vehicleCoownersIds = ['Simple', 'String']; // string z rozdzielonymi „;” (średnikiem) metaData.id współwłaścicieli (zmiana pod earnix)
    objectsDynamicValues.Vehicle.rightSideSteeringWheel = ['Simple', 'Boolean']; // czy kierownica po prawej stronie (zmiana pod earnix)
    objectsDynamicValues.Vehicle.vehicleUsedInBusiness = ['Simple', 'Boolean']; // Pojazd służy prowadzeniu działalności gospodarczej

    //#pragma mark Estate
    objectsDynamicValues.Estate._additionalData = ['Simple', 'String']; // dane dodatkowe ze sciezki sprzedazy
    objectsDynamicValues.Estate._tmpDynamicValues = ['Simple', 'String']; // zserializowane atrybuty z czy_pomijac_w_usţugach_rest = true
    //atrybuty spoza spd, wykorzystywane na sciezce sprzedazy
    objectsDynamicValues.Estate._estateCoownersIds = ['Simple', 'String']; // string z rozdzielonymi „;” (średnikiem) metaData.id współwłaścicieli
    objectsDynamicValues.Estate._hasCoowners = ['Simple', 'Boolean']; // czy nieruchomość posiada współwłaścicieli
    objectsDynamicValues.Estate.doorProtection = ['Simple', 'Boolean', true]; // Zabezpieczenie wejść drzwiami o zwiększonej odporności na włamanie
    objectsDynamicValues.Estate.localAlarmSystem = ['Simple', 'Boolean', true]; // System antywłamaniowy wywołujący alarm
    objectsDynamicValues.Estate.regularSupervision = ['Simple', 'Boolean', true]; // Stały dozór wykonywany przez agencję ochrony mienia / prywatnego strażnika, osiedle strzeżone
    objectsDynamicValues.Estate.propertyType = ['Simple', 'String']; // typ nieruchomości

    //#pragma mark Group
    objectsDynamicValues.Group = {
      '_extensionSelected': ['Simple', 'Boolean'] //czy wybrano rozszerzenie
    };


    return objectsDynamicValues;
  }])
;