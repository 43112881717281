angular.module('lsnBase.numeralString', [])
  .provider('numeralStringConfig', [function(){

    var self = this;

    /**
     * predefiniowane szablony
     * @type {Object}
     */
    this.templates = {};

    /**
     * Dodawanie szablonu odmian
     * @param {String} name   np. 'osoba'
     * @param {Array} values np. ['osób', 'osoba', 'osoby']
     */
    this.addTemplate = function(name, values){
      self.templates[name] = values;
      return self;
    };

    /**
     * Dodawanie szablonów odmian
     * @param {Array} templates szablony
     */
    this.addTemplates = function(templates) {
      angular.forEach(templates, function(template, templateName){
        templates[templateName] = template;
      });
      return self;
    };

    /**
     * [description]
     * @return {[type]} [description]
     */
    this.$get = [function(){
      return self;
    }];

  }]);