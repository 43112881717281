angular.module('ihestiaRestServicesBase')
.factory('claimsSvc', [ 'IHestiaRestServiceAbstractDef',
  function(IHestiaRestServiceAbstractDef) {

  var Claims = function()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';

    this.resource = 'secured/claim';
    this.businessArea = 'iszkody';
  };

  return new Claims();
}])
;