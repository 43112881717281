angular.module('ihestiaWidgets.policyDetails')
  .controller('editChildFormCtrl', ['$scope', 'editChildModalHelper', 'ihestiaDictionaryHelper',
    function ($scope, editChildModalHelper, ihestiaDictionaryHelper) {
  
      $scope.init = function() {
        $scope.loadDicts();
        $scope.tplData = editChildModalHelper.tplData;
        var formListener = $scope.$watch('userEdit', function(formRef) {
              $scope.api.formRef = formRef;
              formListener();
          });
      };
        
      $scope.loadDicts = function() {
        $scope.$emit('showBlockUi');
        var loadedDicts = {
            nationalities: false,
            identityDocumentTypes: false
          },
          tryToHideBlockUi = function() {
            var canHide = true;
            angular.forEach(loadedDicts, function(isLoaded) {
              if (!isLoaded) {
                canHide = false;
              }
            });
            if (canHide) {
              $scope.$emit('hideBlockUi');
            }
          };

        ihestiaDictionaryHelper.getDict('nationalities', {
          requestType: 'searches',
          callback: function(nationalities) {
            $scope.nationalities = nationalities;
            loadedDicts.nationalities = true;
            tryToHideBlockUi();
          }
        });
        ihestiaDictionaryHelper.getDict('identityDocumentTypes', {
          requestType: 'searches',
          callback: function(identityDocumentTypes) {
            $scope.identityDocumentTypes = identityDocumentTypes;
            loadedDicts.identityDocumentTypes = true;
            tryToHideBlockUi();
          }
        });
      };

    }]);
