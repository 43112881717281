angular.module('salesPath2')
  /**
   * resourcy dla aplikacji
   * @return {Object}
   */
  .service('ResourcesBuilder', ['RESOURCES_BASE', 'CONFIG', 'CONSTANTS', 'styleMap', '$sce', 'requirementMap', 'ConfigResource', 'lsnDeepExtend', 'ihestiaLabelHelper', 'lsnObjectHelper',
    function(RESOURCES_BASE, CONFIG, CONSTANTS, styleMap, $sce, requirementMap, ConfigResource, lsnDeepExtend, ihestiaLabelHelper, lsnObjectHelper) {

      var ResourcesBuilder = function() {
        var self = this;
        this.currentResources = {};

        this.refreshResources = function() {
          var currentConfig = ConfigResource.getConfig();
          if (angular.equals(currentConfig, {}) || currentConfig.METADATA.applicationSymbol === null) {
            lsnDeepExtend(self.currentResources, ihestiaLabelHelper.transObject(RESOURCES_BASE, {
              noSceSet: true
            }));
            return;
          }

          var RESOURCES = lsnDeepExtend({}, ihestiaLabelHelper.transObject(RESOURCES_BASE, {
            noSceSet: true
          }), angular.copy(ConfigResource.getResources()));

          var htmlElements = [
            ['EXTENSIONDEF', '[]', 'NAME'],
            ['EXTENSIONDEF', '[]', 'DESC'],
            ['PRODADD', '[]', 'NAME'],
            ['PRODADD', '[]', 'SUBNAME'],
            ['PRODADD', '[]', 'TAGLINE'],
            ['PRODADD', '[]', 'VARIANTS', '[]', 'NAME'],
            ['PRODADD', '[]', 'VARIANTS', '[]', 'TAGLINE'],
            ['EXTRA_DESCRIPTION_LIST', '[]', 'DESCRIPTION', '[]', 'NAME'],
            ['EXTRA_DESCRIPTION_LIST', '[]', 'DESCRIPTION', '[]', 'COMPLEX', '[]', 'TEXT'],
            ['EXTRA_DESCRIPTION_LIST', '[]', 'VARIANTLIST', '[]', 'DESCRIPTION_NAME'],
            ['EXTRA_DESCRIPTION_LIST', '[]', 'VARIANTLIST', '[]', 'DESCRIPTION_TAGLINE'],
            ['PRODUCTLIST', '[]', 'NAME'],
            ['PRODUCTLIST', '[]', 'MENU_NAME'],
            ['PRODUCTLIST', '[]', 'SUMMARY_NAME'],
            ['PRODUCTLIST', '[]', 'DESCRIPTION', '[]', 'NAME'],
            ['PRODUCTLIST', '[]', 'DESCRIPTION', '[]', 'COMPLEX', '[]', 'TEXT'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'NAME'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'SUMMARY_NAME'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'PAGEPRODUCTNAME'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'DESCRIPTION_NAME'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'DESCRIPTION_TAGLINE'],
            ['PRODUCTLIST', '[]', 'VARIANTLIST', '[]', 'TAGLINE'],
            ['PRODUCTLIST', '[]', 'DESCRIPTION_EXTRA', 'GLOBAL', '[]', 'NAME']
          ];

          //dodatki
          RESOURCES.PRODADD = RESOURCES.PRODADD || [];
          RESOURCES.SELECTED_ADDITIONS = {};
          RESOURCES.ADDITIONS_ATTRIBUTES = {}; //atrybuty dodatków wynikające z zaznaczenia innych dodatków/wariantów - sekcja PARENT_ATTRIBUTE na wariancie dodatku (UWAGA - dotyczy to tylko wariantów dodatków, nie samych dodatków)
          angular.forEach(RESOURCES.PRODADD, function(prodDef) {
            var emptyValue = false;
            if (prodDef.TYPE !== CONSTANTS.PRODUCT_TYPE_PACKAGE) {
              emptyValue = [];
            }
            RESOURCES.SELECTED_ADDITIONS[prodDef.CODE] = angular.copy(emptyValue);
            if (angular.isDefined(prodDef.VARIANTS)) {
              RESOURCES.SELECTED_ADDITIONS[prodDef.CODE] = {};
              angular.forEach(prodDef.VARIANTS, function(variant) {
                RESOURCES.SELECTED_ADDITIONS[prodDef.CODE][variant.CODE] = angular.copy(emptyValue);
                if (angular.isObject(variant.PARENT_ATTRIBUTE)) {
                  var attrDef = {
                    addCode: prodDef.CODE,
                    addVariant: variant.CODE,
                    attrName: variant.PARENT_ATTRIBUTE.ATTR_NAME
                  };
                  angular.forEach(variant.PARENT_ATTRIBUTE.PARENT_ADDS, function(parentAdd) {
                    if (angular.isUndefined(RESOURCES.ADDITIONS_ATTRIBUTES[parentAdd[0]])) {
                      RESOURCES.ADDITIONS_ATTRIBUTES[parentAdd[0]] = {};
                    }
                    RESOURCES.ADDITIONS_ATTRIBUTES[parentAdd[0]][parentAdd[1]] = attrDef;
                  });
                }
              });
            } else {
              RESOURCES.SELECTED_ADDITIONS[prodDef.CODE] = angular.copy(emptyValue);
            }
          });

          //rozszerzenia
          RESOURCES.EXTENSIONDEF = RESOURCES.EXTENSIONDEF || [];
          RESOURCES.SELECTED_EXTENSIONS = {};
          angular.forEach(RESOURCES.EXTENSIONDEF, function(extDef) {
            RESOURCES.SELECTED_EXTENSIONS[extDef.CODE] = [];
          });

          /**
           * [changeOnTrustedHtmlRecursive rekursywnie wchodzi w glab obiektu az natrafi na atrybut string i go zamieni, lub jesli nie znajdzie elementu przestanie sie wywolywac]
           * @return {[type]} [description]
           */
          var changeOnTrustedHtmlRecursive = function(elementInResources, htmlElement, index) {
            if (angular.isString(elementInResources[htmlElement[index]])) {
              //unikniecie zbieznosci nazw atrybutow
              if (index === htmlElement.length - 1) {
                elementInResources['RAW_' + htmlElement[index]] = elementInResources[htmlElement[index]]; //wersje nieeskejpowaną zostawiamy z prefiksem 'RAW_'
                elementInResources[htmlElement[index]] = $sce.trustAsHtml(elementInResources[htmlElement[index]]);
              }
            } else if (htmlElement[index] === '[]' && angular.isArray(elementInResources)) {
              angular.forEach(elementInResources, function(nestedHtmlElement, key) {
                changeOnTrustedHtmlRecursive(elementInResources[key], htmlElement, index + 1);
              });
            } else if (angular.isObject(elementInResources[htmlElement[index]])) {
              changeOnTrustedHtmlRecursive(elementInResources[htmlElement[index]], htmlElement, index + 1);
            }
          };

          /**
           * [changeOnTrustedHtml zmienia elementy z tablicy htmlElements na zaufany html]
           * @return {[type]} [description]
           */
          var changeOnTrustedHtml = function() {
            angular.forEach(htmlElements, function(htmlElement) {
              var elementInResources = RESOURCES[htmlElement[0]];
              changeOnTrustedHtmlRecursive(elementInResources, htmlElement, 1);
            });
          };

          changeOnTrustedHtml();
          styleMap.map(RESOURCES);
          requirementMap.map(RESOURCES);
          delete CONFIG.RESOURCES; //aby nie odwolywac sie do resources przez CONFIG
          lsnObjectHelper.replaceObject(self.currentResources, RESOURCES);
        };

        this.refreshResources();
      };


      return new ResourcesBuilder();
    }
  ]);