angular.module('ihestiaCommonDirectives')
  .provider('deprectedHelper', ['ihestiaConfigHelperProvider',
    function(ihestiaConfigHelperProvider) {
      var DeprectedHelper = function() {

        var self = this;

        /**
         * Czy logujemy na konsolę
         * @type {Boolean}
         */
        this.logEnabled = ihestiaConfigHelperProvider.getConfigHelper().get('frontFlags', 'DEBUG_INFO_ENABLED');

        /**
         * Ustawiamy dany helper/kontroler/etc jako przestarzały
         * @param {String} name    nazwa helpera/kontrolera/etc
         * @param {String} message dodatkowy komentarz
         */
        this.setAsDeprecated = function(name, message) {
          /*eslint-disable no-console,no-undef */
          if (self.logEnabled && console) {
            message = message ? ' (' + message + ')' : '';
            var logMsg = name + ' is deprecated' + message;
            if (angular.isFunction(console.warn)) {
              console.warn('[Depracation]', logMsg); //NOSONAR
            } else if (angular.isFunction(console.log)) {
              console.log('[Depracation]', logMsg); //NOSONAR
            }
          }
        /*eslint-enable no-console,no-undef */
        };

        /**
         * magix $get
         * @return {DeprectedHelper}
         */
        this.$get = [function() {
          return self;
        }];
      };

      return new DeprectedHelper();
    }]);