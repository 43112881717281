angular.module('ihestiaCommonDirectives')
  .directive('bigCheckbox', ['$parse',
    function($parse){
    return {
      scope: true,
      require: 'ngModel',
      restrict: 'E',
      templateUrl: 'ihestia-commondirectives-template-app/directives/bigCheckbox/bigCheckbox.tpl.html',
      replace: true,
      link: function($scope, iElm, iAttrs) {
        $scope.checked = $parse(iAttrs.ngModel)($scope);
        $scope.size = iAttrs.checkboxSize;

        if (iAttrs.ngDisabled) {
          $scope.$watch(iAttrs.ngDisabled, function(isDisabled){
            $scope.isDisabled = isDisabled;
          });
          $(iElm).find('[common-checkbox-wrap]').attr('ng-class', '{\'disabled\': ' + iAttrs.ngDisabled + '}');
        }

        $scope.$watch(iAttrs.ngModel, function(isChecked){
          $scope.checked = isChecked;
        }, true);

        /**
         * Zaznacz/odznacz
         * @return {undefined}
         */
        $scope.toggleCheck = function() {
          if(!$scope.isDisabled) {
            $parse(iAttrs.ngModel).assign($scope, !$parse(iAttrs.ngModel)($scope));
            if(iAttrs.ngChange) {
              $parse(iAttrs.ngChange)($scope);
            }
          }
        };
      }
    };
  }]);