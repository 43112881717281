angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorDetailsMessageThreadCtrl', ['$scope', 'ihestiaIkomunikatorDetailsMessageThreadHelper', 'ihestiaIkomunikatorStateParamsHelper',
    'ihestiaIkomunikatorDownloadFileHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorPermissionHelper', '$rootScope',
    function($scope, ihestiaIkomunikatorDetailsMessageThreadHelper, ihestiaIkomunikatorStateParamsHelper, ihestiaIkomunikatorDownloadFileHelper, 
      ihestiaIkomunikatorUserTypeHelper, ihestiaIkomunikatorPermissionHelper, $rootScope) {
      $scope.stateParams = ihestiaIkomunikatorStateParamsHelper.stateParams;

      /**
       * [init description]
       * @return {[type]} [description]
       */
      $scope.init = function() {
        if (!ihestiaIkomunikatorPermissionHelper.shouldShowApp()) {
          $rootScope.canRender = false;
          return;
        }

        ihestiaIkomunikatorDetailsMessageThreadHelper.init();
        $scope.tplData = ihestiaIkomunikatorDetailsMessageThreadHelper.tplData;
        $scope.reloadThread($scope.stateParams.threadId);

        $scope.centralUserLogged = ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged();
      };

      $scope.showAddingMessage = function() {
        $scope.showsAddingMessage = true;
      };

      $scope.hideAddingMessage = function() {
        $scope.showsAddingMessage = false;
      };

      $scope.reloadThread = function(threadId) {
        ihestiaIkomunikatorDetailsMessageThreadHelper.reload(threadId);
      };

      $scope.onSendedMessage = function(threadId) {
        $scope.showsAddingMessage = false;
        $scope.reloadThread(threadId);
      };

      $scope.downloadFile = function(attachment, messageId) {
        ihestiaIkomunikatorDownloadFileHelper.download(messageId, attachment.path, attachment.fileName, attachment.msgFileIndex);
      };

      /**
       * [shouldShowButtonAddMessage sprawdza czy kanal komunikatu watku pozwala na dodanie wiadomosci]
       * @return {[type]} [description]
       */
      $scope.shouldShowButtonAddMessage = function(){
        var originator = $scope.tplData.thread.lastMessage.originator;
        return ['KOMUNIKAT', 'EMAIL', 'SMS'].indexOf($scope.tplData.thread.getLastMessageChannel()) !== -1 || 
        ($scope.tplData.thread.getLastMessageChannel() === 'WWW' && (angular.isString(originator.email) && originator.email !== ''));
      };

      $scope.init();
    }
  ]);