angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaDiscountCodeModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaDiscountCodeModel = function() {
      this.objectName = 'IhestiaPzaDiscountCodeModel';

      this.fieldsProperties = {
        valuePercentage: {
          type: 'int'
        },
        valueAmount: {
          type: 'int'
        },
        code: {
          type: 'string'
        }
      };

      this.code = null;
      this.valuePercentage = null;
      this.valueAmount = null;

    };


    IhestiaPzaDiscountCodeModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaDiscountCodeModel;
  }]);