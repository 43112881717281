angular.module('salesPath2')
  .controller('navBarApplicationNumberCtrl', ['$scope', '$sce',
    function ($scope, $sce) {
      $scope.policyNumber = $sce.trustAsHtml('');

      $scope.$watchCollection('dataContainer.policies', function (policies) {
        var numbers = [];
        angular.forEach(policies, function (policy) {
          numbers.push(policy.get('number'));
        });
        $scope.policyNumber = $sce.trustAsHtml((numbers.length > 0) ? numbers[0] : '');
      });
    }
  ]);