angular.module('ihestiaWidgets.policyDetails')
  .filter('langDate', ['$filter', 'ihestiaLabelHelper',
    function($filter, ihestiaLabelHelper) {
      return function(date, format, plSuffix) {
        var isPolishLang = ihestiaLabelHelper.selectedLanguage === 'pl-PL';
        var dateString = $filter('date')(date, isPolishLang ? format : 'yyyy-MM-dd');
        if (plSuffix && isPolishLang) {
          dateString += plSuffix;
        }
        return dateString;
      };
    }
  ]);
