angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardTaskDefinitionsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var TaskDefinitionsSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/taskDefinitions';
        this.businessArea = 'dashboard';
        this.port = '8282';

      };
      return new TaskDefinitionsSvc();
    }
  ]);