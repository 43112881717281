angular.module('ihestiaCommonDirectives')
  .directive('commonTagList', ['$templateCache',
    function($templateCache){
      return {
        restrict: 'E',
        controller: 'tagListCtrl',
        replace: true,
        scope: {
          model: '=tagsModel'
        },
        template: $templateCache.get('ihestia-commondirectives-template-app/directives/tagList/tagList.tpl.html'),
        link: function($scope, $element, $attrs){

          if ($attrs.ngDisabled) {
            $scope.$watch($attrs.ngDisabled, function(isDisabled){
              $scope.isDisabled = isDisabled;
            });
          }

          // jeśli fieldset jest ustawiony na disabled, to uznajemy, że my też jesteśmy :)
          var fieldset = angular.element($element).parents('fieldset');
          if(fieldset) {
            $attrs.$observe('disabled', function(disabled){
              $scope.isDisabled = disabled ? true : false;
            });
          }
        }
      };
    }]);