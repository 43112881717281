angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorStateParamsHelper', [function(){
    var Helper = function(){
      this.stateParams = {
        threadId: null
      };
    };

    return new Helper();
  }]);
