angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzaConditionConstants', [function(){
    var CONSTANTS = {
      STATUS_ACTIVE: 'ACTIVE',
      STATUS_DRAFT: 'DRAFT'
    };

    return CONSTANTS;
  }])
  .factory('IhestiaPzaConditionDefinitionModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaConditionDefinitionModel = function() {
      this.objectName = 'IhestiaPzaConditionDefinitionModel';

      this.fieldsProperties = {
        id: { // ignorowane przy zapisie (będzie np PUT condition/{id})
          type: 'int'
        },
        code: {
          type: 'string'
        },
        description: {
          type: 'string'
        },
        status: {
          type: 'IhestiaPzaDictionary'
        },
        packageList: {
          type: 'array',
          elementsType: 'IhestiaPzaPackage'
        },
        vehicleExcludeList: {
          type: 'array',
          elementsType: 'IhestiaPzaVehicleConditionDefinition'
        },
        vehicleIncludeList: {
          type: 'array',
          elementsType: 'IhestiaPzaVehicleConditionDefinition'
        },
        postCodeIncludeList: {
          type: 'array',
          elementsType: 'IhestiaPzaPostCodeConditionDefinition'
        },
        postCodeExcludeList: {
          type: 'array',
          elementsType: 'IhestiaPzaPostCodeConditionDefinition'
        },
        bmOCMin: {
          type: 'int'
        },
        bmOCMax: {
          type: 'int'
        },
        bmACMin: {
          type: 'int'
        },
        bmACMax: {
          type: 'int'
        },
        policyType: {
          type: 'IhestiaPzaDictionary'
        },
        riskType: {
          type: 'IhestiaPzaDictionary'
        },
        customerType: {
          type: 'IhestiaPzaDictionary'
        },
        discountList: {
          type: 'array',
          elementsType: 'IhestiaPzaConditionDefinitionAdditionalPremium'
        },
        discountCodeList: {
          type: 'array',
          elementsType: 'IhestiaPzaDiscountCode'
        }
      };

      this.id = null;
      this.code = null;
      this.description = null;
      this.status = null;
      this.packageList = null;
      this.vehicleExcludeList = [];
      this.vehicleIncludeList = [];
      this.postCodeIncludeList = [];
      this.postCodeExcludeList = [];
      this.bmOCMin = null;
      this.bmOCMax = null;
      this.bmACMin = null;
      this.bmACMax = null;
      this.policyType = null;
      this.riskType = null;
      this.customerType = null;
      this.discountList = [];
      this.discountCodeList = [];


    };


    IhestiaPzaConditionDefinitionModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaConditionDefinitionModel;
  }]);