angular.module('salesPath2')
  .service('dcObjectHelper', ['mainDataContainer', 'dcCommonHelper',
    function(mainDataContainer, dcCommonHelper) {
      var DcObjectHelper = function() {
        var self = this;
        
        /**
         * zwraca kolejny clientId obiektu z iteratora
         * @return {Number}
         */
        this.getNextObjectId = function() {
          var id = mainDataContainer.nextObjectId;
          mainDataContainer.nextObjectId = mainDataContainer.nextObjectId + 1;
          return dcCommonHelper.idToString(id);
        };

         /**
         * zwraca kolejny clientId dla lokalizacji
         * @return {Number}
         */
        this.getNextLocalizationId = function() {
          return self.getNextObjectId();
        };

         /**
         * zwraca liczbę lokalizacji na wniosku
         * @return {Number}
         */
        this.getLocalizationCount = function() {
          return Object.keys(mainDataContainer.localizations).length;
        };

         /**
         * zwraca kolejny clientId dla pojazdu
         * @return {Number}
         */
        this.getNextVehicleId = function() {
          return self.getNextObjectId();
        };

         /**
         * zwraca liczbę pojazdów na wniosku
         * @return {Number}
         */
        this.getVehicleCount = function() {
          return Object.keys(mainDataContainer.vehicles).length;
        };
      };

      return new DcObjectHelper();
    }
  ]);