angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaConditionListModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var ConditionListModel = function() {
      this.objectName = 'ConditionListModel';

      this.fieldsProperties = {
        condition: {
          type: 'IhestiaPzaConditionDefinition'
        }
      };

      this.id = null;

    };


    ConditionListModel.prototype = IHestiaAbstractModel.prototype;

    return ConditionListModel;
  }]);