angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaChildHeader', function() {
    return {
      scope: {
        children: '=',
        canEdit: '=',
        onEditChild: '&'
      },
      restrict: 'E',
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/childHeader/childHeader.tpl.html'
    };
  });
