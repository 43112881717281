angular.module('salesPath2')
  .config(['$stateProvider',
    function myAppConfig($stateProvider) {
      $stateProvider
        .state('clientSearch', {
          url: '/clientSearch',
          views: {
            main: {
              templateUrl: 'salesPath2Template/pages/clientSearch/clientSearch.tpl.html',
              controller: 'clientSearchCtrl'
            }
          }
        });
    }
  ]);