/**
 * Life policy details controller - to be used as a mixin with
 * application specific implementation of policy details controller, or as a standalone controller
 *
 * To use it as mixin, in other base controller do:
 *  angular.extend(this, $controller('ihestiaLifePolicyDetailsCtrl', {$scope: $scope}));
 * All variables from this controller will be available in base controller
 * Required scope variables:
 * - insuredPersons (from PolicyHelper)
 */

angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaLifePolicyDetailsCtrl', ['$scope', 'IhestiaLifePersonHeaderConstants', 'ihestiaRiskTableHelper', 'ihestiaLifePersonEditModalHelper', '$stateParams', 'ihestiaLifeInsurerChangeFlowHelper', 'lifeControllerPolicyDetailsHelper', 'IhestiaAbstractPolicyDetailsBaseConstants', '$state', '$alert', '$filter', 'ihestiaLifePolicyTerminationModalHelper', 'LifePolicyOperationsConstants', 'ihestiaPolicyMessageHelper', '$timeout', '$rootScope', 'lsnCommonErrorHandler', 'ihestiaBeneficiaryDataHelper', 'ihestiaBeneficiaresModalFlowHelper', 'ihestiaAnniversaryDocumentsModalHelper', 'ihestiaLifeAnniversaryHelper', 'ihestiaRestoreAnniversaryModalHelper', 'blockUiHelper', 'editChildModalHelper',
    /**
     *
     * @param {IhestiaAbstractPolicyDetailsBaseScope | any} $scope
     * @param IhestiaLifePersonHeaderConstants
     * @param ihestiaRiskTableHelper
     * @param ihestiaLifePersonEditModalHelper
     * @param $stateParams
     * @param ihestiaLifeInsurerChangeFlowHelper
     * @param lifeControllerPolicyDetailsHelper
     * @param IhestiaAbstractPolicyDetailsBaseConstants
     * @param $state
     * @param $alert
     * @param $filter
     * @param ihestiaLifePolicyTerminationModalHelper
     * @param LifePolicyOperationsConstants
     * @param ihestiaPolicyMessageHelper
     * @param $timeout
     * @param $rootScope
     * @param lsnCommonErrorHandler
     * @param ihestiaBeneficiaryDataHelper
     * @param ihestiaBeneficiaresModalFlowHelper
     * @param policyDetailsContextHelper
     * @param editChildModalHelper
     */
    function($scope, IhestiaLifePersonHeaderConstants, ihestiaRiskTableHelper, ihestiaLifePersonEditModalHelper, $stateParams, ihestiaLifeInsurerChangeFlowHelper, lifeControllerPolicyDetailsHelper, IhestiaAbstractPolicyDetailsBaseConstants, $state, $alert, $filter, ihestiaLifePolicyTerminationModalHelper, LifePolicyOperationsConstants, ihestiaPolicyMessageHelper, $timeout, $rootScope, lsnCommonErrorHandler, ihestiaBeneficiaryDataHelper, ihestiaBeneficiaresModalFlowHelper, ihestiaAnniversaryDocumentsModalHelper, ihestiaLifeAnniversaryHelper, ihestiaRestoreAnniversaryModalHelper, blockUiHelper, editChildModalHelper) {
      $scope.IhestiaLifePersonHeaderConstants = IhestiaLifePersonHeaderConstants;
      /**
       * Handle person header edit action
       * @param subject
       */
      $scope.onEditSubject = function(subject) {
        ihestiaLifePersonEditModalHelper.showFormModal({
          subject: subject,
          policyNumber: $stateParams.policyNumber,
          policyStartDate: $scope.policy.startDate
        }).then(function() {
          $alert({
            content: $filter('translate')('operationSuccesfull', {componentCode: 'Public'}),
            type: 'success',
            duration: 10
          });
          $state.go($state.current, {}, {
            reload: true
          });
        }, lsnNg.noop);
      };

      $scope.onEditChild = function (subject) {
        editChildModalHelper.showModal({
          subject: subject,
          policyNumber: $stateParams.policyNumber,
          policyStartDate: $scope.policy.startDate
        }).then(function () {
          $alert({
            content: $filter('translate')('operationSuccesfull', {componentCode: 'Public'}),
            type: 'success',
            duration: 10
          });
          $state.go($state.current, {}, {
            reload: true
          });
        }, lsnNg.noop);
      };

      $scope.children = null;
      $scope.additionalBeneficiaries = [];
      $scope.mainBeneficiaries = [];

      $scope.lifeShowElements = {
        activeRiskTables: null,
        activeRiskTablesAvailable: null,
        editBeneficiaries: null,
        insuranceTableTabs: null
      };

      /**
       * this object should contain variables which should be overriden if necessary by mixed in controller
       * @type {{
       * restoreVersion: any,
       * policyTermination: {cancelEndState: null, successEndState: null},
       * insurerChange: {successEndState: string | string[]}
       * mainPageState: string
       * }}
       */
      $scope.lifeParams = {
        policyTermination: {
          successEndState: null,
          cancelEndState: null
        },
        restoreVersion: {
          confirmationState: null,
          message: $filter('translate')('renewProtection.confirmationMessage', {
            componentCode: 'iKonto',
            params: [$scope.policy.number]
          }) + '.',
          pageTitleLabel: ['renewProtection.confirmationTitle', {componentCode: 'iKonto'}],
          endState: null
        },
        insurerChange: {
          successEndState: null
        },
        mainPageState: null
      };

      $scope.lifeInit = function() {
        $scope.children = _.filter($scope.insuredPersons, ['dynamicValues._child', true]);
        $scope.riskTablesLoaded()
          .then(function() {
            $scope.lifeShowElements.activeRiskTables = $scope.lifeShowElements.activeRiskTablesAvailable = ihestiaRiskTableHelper.activeRiskTablesPresent($scope.riskTables);
            $scope.lifeShowElements.insuranceTableTabs = $scope.policyHelper.isActivePolicy();
          });
        $scope.availableOperationsLoaded()
          .then(function() {
            $scope.initAvailableOperations();
          });
        // added getPersons, because beneficaries dont have to be in insured persons
        // IHESTLIFE-5747 - omitted insuredPersons, duplicates were sent to service
        var subjects = _.concat([], $scope.policyHelper.getOrganizations(), $scope.policyHelper.getPersons());
        $scope.loadBeneficiaries(subjects);
      };

      $scope.toggleRiskTableTabs = function(activeClicked) {
        $scope.lifeShowElements.activeRiskTables = activeClicked && $scope.lifeShowElements.activeRiskTablesAvailable;
      };

      $scope.insurerChange = function() {
        return ihestiaLifeInsurerChangeFlowHelper.open($stateParams.policyNumber, $scope.policy.startDate)
          .then(function() {
            if (_.get($scope.lifeParams, 'insurerChange.successEndState')) {
              if (_.isArray($scope.lifeParams.insurerChange.successEndState)) {
                $state.go.apply($state, $scope.lifeParams.insurerChange.successEndState);
              } else {
                $state.go($scope.lifeParams.insurerChange.successEndState);
              }
            }
          }, lsnNg.noop);
      };

      $scope.initAvailableOperations = function() {
        $scope.availableOperationsList = _.chain($scope.defaultAvailableOperations())
          .filter(function(defaultOperation) {
            return $scope.availableOperations[defaultOperation.code];
          })
          .value();
        $scope.lifeShowElements.editBeneficiaries
          = $scope.availableOperations[IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_BENEFICIARY_CHANGE];
        if (!_.isEmpty(_.get($scope.policyData, 'product.dynamicValues._frpDocumentsIdsVector.frpDocumentId'))) {
          $scope.availableOperationsList.push(
            {
              icon: 'icon-folder-download',
              name: ['operation.anniversaryDocuments', {componentCode: 'iKonto'}],
              code: IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_ANNIVERSARY_DOCUMENTS
            }
          );
        }
      };

      /**
       * Restore policy
       */
      $scope.restoreVersion = function() {
        var policyNumber = _.get($scope.policy, 'number');
        lifeControllerPolicyDetailsHelper.restoreVersion(policyNumber)
          .then(function() {
            $state.go($scope.lifeParams.restoreVersion.confirmationState, $scope.lifeParams.restoreVersion);
          }, function(err) {
            var messages = _.get(err, 'data.messages');
            // handle service error
            if (messages && !_.isEmpty(messages)) {
              $scope.$emit('hideBlockUi');
              ihestiaPolicyMessageHelper.setMessages(messages);
              $timeout(function() {
                $rootScope.$broadcast('scrollToError');
              });
            }
          });
      };

      /**
       * Redirect to proper termination page,
       * termination pages are a part of policyOperations/policyTermination directory of widgets
       */
      $scope.terminatePolicy = function() {
        var policyNumber = _.get($scope.policy, 'number');
        ihestiaLifePolicyTerminationModalHelper.showModal({
          policyNumber: policyNumber
        }).then(function(result) {
          var params = {
            policyNumber: policyNumber,
            // controller can override this property for customization
            cancelState: $scope.lifeParams.policyTermination.cancelEndState,
            successState: $scope.lifeParams.policyTermination.successEndState
          };
          switch (result.terminationType) {
            case LifePolicyOperationsConstants.OPERATION_CAUSE.WITHDRAWAL:
              $state.go('main.policyTerminationWithdrawal', params);
              break;
            case LifePolicyOperationsConstants.OPERATION_CAUSE.NOTICE:
              $state.go('main.policyTerminationNotice', params);
              break;
            case LifePolicyOperationsConstants.OPERATION_CAUSE.CANCELLATION:
              $state.go('main.policyCancellation', params);
              break;
            case LifePolicyOperationsConstants.OPERATION_CAUSE.TERMINATION:
              if (result.availableTerminationTypes) {
                params.availableTerminationTypes = result.availableTerminationTypes;
              }
              $state.go('main.policyTermination', params);
              break;
            default:
              break;
          }
        }, lsnNg.noop);
      };

      /**
       * Invoked on operation click
       * @param {string} code
       */
      $scope.handleOperation = function(code) {
        switch (code) {
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_INSURER_CHANGE:
            $scope.insurerChange();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_RESTORE_VERSION:
            $scope.restoreVersion();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_POLICY_TERMINATION:
            $scope.terminatePolicy();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_BENEFICIARY_CHANGE:
            $scope.openBeneficiaryList();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_ANNIVERSARY_DOCUMENTS:
            $scope.anniversaryDocuments();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_CREATE_ANNIVERSARY:
            $scope.createAnniversary();
            break;
          case IhestiaAbstractPolicyDetailsBaseConstants.OPERATION_RESTORE_ANNIVERSARY:
            $scope.restoreAnniversary();
            break;
          default:
            // base abstract controller operation handling
            $scope.baseHandleOperation(code);
            break;
        }
      };

      /**
       * Starts beneficiary flow
       */
      $scope.openBeneficiaryList = function() {
        ihestiaBeneficiaresModalFlowHelper.open($scope.policyData.number, $scope.policyData.startDate, null, $scope.availableOperations.beneficiaryChange)
          .then(function() {
            $scope.mainBeneficiaries = ihestiaBeneficiaryDataHelper.getSavedBeneficiariesByType('MAIN');
            $scope.additionalBeneficiaries = ihestiaBeneficiaryDataHelper.getSavedBeneficiariesByType('ADDITIONAL');
          }, lsnCommonErrorHandler.throwException);
      };

      /**
       * Maps beneficiaries from policy for ihestiaBeneficiaryTable directive
       * @param {LsnPersonModelV2[]} insuredPersons
       */
      $scope.loadBeneficiaries = function(insuredPersons) {
        // IHESTLIFE-5747 - make sure subjects are not duplicated
        var uniqueSubjects = _.uniqBy(insuredPersons, 'metaData.id');
        var nonEditableSubjectIds = $scope._getNonEditableSubjectIds($scope.policy.holderRef, $scope.children);
        ihestiaBeneficiaryDataHelper.initData($scope.policy, uniqueSubjects, nonEditableSubjectIds);
        $scope.mainBeneficiaries = ihestiaBeneficiaryDataHelper.getSavedBeneficiariesByType('MAIN');
        $scope.additionalBeneficiaries = ihestiaBeneficiaryDataHelper.getSavedBeneficiariesByType('ADDITIONAL');
      };

      /**
       * Returns string array containing children and insurer ids
       * @param {string} insurerId
       * @param {object[]} children
       * @return {string[]}
       * @private
       */
      $scope._getNonEditableSubjectIds = function(insurerId, children) {
        var childrenIds = _.map(children, 'metaData.id');
        return _.compact(_.concat([insurerId], childrenIds));
      };

      /**
       * open anniversary documents modal
       */
      $scope.anniversaryDocuments = function() {
        var frpDocumentsVector = _.get($scope.policyData, 'product.dynamicValues._frpDocumentsIdsVector');
        var documents = _.chain(frpDocumentsVector.anniversaryDate)
          .zipWith(frpDocumentsVector.frpDocumentId,
            function(date, fileId) {
              return {
                name: new XDate(date).toString('yyyy-MM-dd'),
                code: fileId
              };
            })
          .sortBy(function(document) {
            return document.name;
          })
          // descending order
          .reverse()
          .value();
        ihestiaAnniversaryDocumentsModalHelper.showModal({
          documents: documents,
          policyNumber: $scope.policyData.number
        }).then(lsnNg.noop, lsnNg.noop);
      };

      $scope.createAnniversary = function() {
        blockUiHelper.showBlockUi();
        ihestiaLifeAnniversaryHelper.createAnniversary($scope.policyData.number)
          .then(function() {
            if ($scope.lifeParams.mainPageState) {
              $state.go($scope.lifeParams.mainPageState);
            }
            $alert({
              content: $filter('translate')('Atena.Validation.Orders.Success', {componentCode: 'Public'}),
              type: 'success'
            });
          }, lsnNg.noop)
          .finally(function() {
            blockUiHelper.hideBlockUi();
          });
      };

      $scope.restoreAnniversary = function() {
        ihestiaRestoreAnniversaryModalHelper.showModal({
          policyNumber: $scope.policy.number
        }).then(function() {
          if ($scope.lifeParams.mainPageState) {
            $state.go($scope.lifeParams.mainPageState);
          }
          $alert({
            content: $filter('translate')('Atena.Validation.Orders.Success', {componentCode: 'Public'}),
            type: 'success'
          });
        }, lsnNg.noop);
      };

      $scope.lifeInit();
    }]);
