angular.module('lsnBase.models')
  .factory('LsnRiskVariantModelV2', ['LsnAbstractModel', 'lsnModelFactory', 'LsnAmountModelConstants',
    function(LsnAbstractModel, lsnModelFactory, LsnAmountModelConstants)
    {
      var RiskVariantModel = function()
      {
        this.objectName = 'RiskVariant';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          sum:
          {
            type: 'int',
            additionalParams: [
            {
              type: 'string',
              defaultValue: LsnAmountModelConstants.AMOUNT_CURRENCY_PLN,
              dictionary: LsnAmountModelConstants.DICTIONARY_AMOUNT_CURRENCY
            }]
          },
          premiumList:
          {
            type: 'object'
          },
          installmentList: {
            type: 'object'
          },
          option:
          {
            type: 'string'
          },
          personsRefs:
          {
            type: 'array',
            elementsType: 'InsuredSubject'
          },
          organizationsRefs:
          {
            type: 'array',
            elementsType: 'InsuredSubject'
          },
          estatesRefs:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          vehiclesRefs:
          {
            type: 'array',
            elementsType: 'InsuredObject'
          },
          generalAgreementNumber: {
            type: 'string'
          },
          owu: {
            type: 'string'
          },
          owuName: {
            type: 'string'
          },
          metaData: {
            type: 'MetaData'
          },
          attributesOfRisk: {
            type: 'RiskVariantsAttributesOfRisk',
            version: 'v2'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
        this.sum = null; // suma ubezpieczenia
        this.premiumList = null; // lista składek w zależności od rat
        this.installmentList = null; // lista rat ze składkami i datami
        this.option = null; // string, Symbol opcji/wariantu. Pole opcjonalne. Czasami sam identyfika-tor ryzyka określa jego wariant. Pole będzie przyjmowało wartości identyczne z tymi z atrybutu coverageOption na ryzyku.
        this.personsRefs = null; // powiązania z osobami
        this.organizationsRefs = null; // powiązania z organizacjami
        this.estatesRefs = null; // powiązania z nieruchomościami
        this.vehiclesRefs = null; // powiązania z pojazdami
        this.generalAgreementNumber = null; // numer umowy generalnej
        this.owu = null; // symbol owu
        this.owuName = null; // nazwa owu
        this.attributesOfRisk = null; // dodatkowe parametry uzywane w przypdku did


      };

      RiskVariantModel.prototype = LsnAbstractModel.prototype;

      return RiskVariantModel;
    }
  ]);