/**
 * Kontroler clipboard
 * @param  {[type]}  [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuUserBox')
  .factory('IhestiaMenuUserBoxAgentCenterSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
      var IhestiaMenuUserBoxAgentCenterSvc = function() {

        IHestiaRestServiceAbstractDef.apply(this, arguments);
        this.basePattern = '{host}{port}{path}/api/{apiVer}/{resource}{id}{subResource}';
        this.resource = 'base/agent_center';
      };

      return new IhestiaMenuUserBoxAgentCenterSvc();
    }
  ]);