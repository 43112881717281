angular.module('salesPath2')
  .service('nnwHelper', ['resourceHelper', 'CONSTANTS', 'CONFIG', 'mainDataContainer', 'personHelper', 'applicationHelper', 'actionHelper', 'sp2SelectionHelper', 'allowedChangesHelper', 'dataContainerHelper', 'errorHelper', 'appVariables', 'renewalHelper',
    function(resourceHelper, CONSTANTS, CONFIG, mainDataContainer, personHelper, applicationHelper, actionHelper, selectionHelper, allowedChangesHelper, dataContainerHelper, errorHelper, appVariables, renewalHelper) { // eslint-disable-line angular/di
      var NnwHelper = function() {
        var self = this;
        var DEFAULT_NUMERIC_SETTINGS = {
          decimals: 0,
          groupSeparator: ' ',
          min: 0,
          max: 5000000,
          allowLeadingZeros: false,
          parseToString: false,
          formatting: true,
          selectOnFocus: true
        };

        /**
         * dane do szablonu i kontrolera
         * @type {Object}
         */
        this.tplData = {
          prodDef: resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW),
          showAddInsuredBtn: !appVariables.readOnly, //czy pokazywać przycisk dodawania ubezpieczonego
          nnwInsured: [], //dane ubezpieczonych w NNW
          premiums: {}, //składki
          variantSumNumericSettings: {}, //ustawienia dla common-numeric dla sum ubezpieczeń w konkretnych wariantach
          activeInsured: null

        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
              self.refreshSingleInsured(arguments[1].id);
              break;
            case 'personAdded':
              if (angular.isObject(arguments[1]) && arguments[1].context === CONSTANTS.PRODUCT_NNW) {
                self.addInsured(arguments[1].id);
              }
              break;
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.refreshRenewalData();
              self.refreshInsured();
              break;
            case 'addIncognito':
              self.addIncognito();
              break;
            case 'refreshAllInsured':
              if (arguments[1] === CONSTANTS.PRODUCT_NNW) {
                self.refreshInsured();
              }
              break;
            case 'languageChanged.afterStateReload':
              self.translate();
              break;
            default:
              break;
          }
        };

        this.initData = function() {
          //inicjalizacja ustawień common-numeric dla sum ubezpieczeń
          angular.forEach(self.tplData.prodDef.VARIANTLIST, function(variant) {
            var numSet = angular.copy(DEFAULT_NUMERIC_SETTINGS);
            if (angular.isDefined(variant.SUMINSURED_MIN)) {
              numSet.min = variant.SUMINSURED_MIN;
            }
            if (angular.isDefined(variant.SUMINSURED_MAX)) {
              numSet.max = variant.SUMINSURED_MAX;
            }
            self.tplData.variantSumNumericSettings[variant.CODE] = numSet;
          });
          self.refreshInsured();
          self.refreshPremiums();
        };

        /**
         * tlumaczenie danych dla GUI po zmianie jezyka
         */
        this.translate = function() {
          self.tplData.prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW);
        };

        /**
         * aktualizacja danych o ubezpieczonych
         */
        this.refreshInsured = function() {
          var insured = [];
          angular.forEach(self.getPersons(), function(productData, clientId) {
            insured.push(self.getInsuredData(clientId));
          });
          if (mainDataContainer.nnwIncognito !== null) {
            insured.push(self.getIncognitoData());
          }
          self.tplData.nnwInsured = insured;
          self.refreshButtonsData();
          self.refreshActiveInsured();
        };

        /**
         * odswieza aktywnego ubezpieczonego dla gui
         * @return {[type]} [description]
         */
        this.refreshActiveInsured = function() {
          if (self.tplData.nnwInsured.length === 0) {
            self.tplData.activeInsured = null;
          } else {
            var activePresent = false;
            lsnNg.forEach(self.tplData.nnwInsured, function(insured) {
              if (self.tplData.activeInsured === insured.clientId) {
                activePresent = true;
                return false;
              }
              return true;
            });
            if (!activePresent) { //zaznaczamy pierwszego z gory
              self.tplData.activeInsured = self.tplData.nnwInsured[0].clientId;
            }
          }
        };

        /**
         * aktualizuje dane konkretnego ubezpieczonego
         * @param  {String} clientId
         */
        this.refreshSingleInsured = function(clientId) {
          lsnNg.forEach(self.tplData.nnwInsured, function(insured, idx) {
            if (insured.clientId === clientId) {
              if (clientId === CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
                self.tplData.nnwInsured[idx] = self.getIncognitoData();
              } else {
                var personData = self.getInsuredData(clientId);
                if (personData === null) { //brak osoby - odswiezamy cala liste
                  self.refreshInsured();
                } else {
                  self.tplData.nnwInsured[idx] = self.getInsuredData(clientId);
                }
              }
              return false;
            }
            return true;
          });
        };

        /**
         * aktualizuje dane dotyczące przycisków
         */
        this.refreshButtonsData = function() {
          if (appVariables.readOnly || CONFIG.BEHAVIOR.mainInsuredOnlyRisks.indexOf(CONSTANTS.PRODUCT_NNW) !== -1 && self.tplData.nnwInsured.length > 0 && (!CONFIG.BEHAVIOR.nnwIncognito || mainDataContainer.nnwIncognito !== null)) { //nnw tylko dla glownego ubezpieczonego lub bezimienne
            self.tplData.showAddInsuredBtn = false;
          } else {
            self.tplData.showAddInsuredBtn = true;
          }
        };

        /**
         * zwraca zestaw danych o ubezpieczonym
         * @param  {String} clientId
         * @return {Object|null} null gdy osoba usunieta z nnw
         */
        this.getInsuredData = function(clientId) {
          var person = mainDataContainer.persons[clientId],
            personData = person.getData(),
            prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW),
            nnwData = angular.copy(person.getAdditionalData(CONSTANTS.PRODUCT_NNW)),
            allowedVariants = {},
            rejectedVariants = {}, //odrzucone warianty (np. w wyniku udzielenia odpowiedzi klienta): kod_wariantu: treść_odrzucenia
            isMutliVariant = self.isMultiVariantProduct(),
            selectedVariant = null, //wybrany wariant w przypadku gdy isMultiVariant = false
            prevSelVnts = selectionHelper.getPreviouslySelectedVariants(CONSTANTS.PRODUCT_NNW, clientId),
            riskErrors = {}; //błędy (messages) dla konkretnych wariantów ryryzka

          if (nnwData === null) {
            return null;
          }

          if (!isMutliVariant) { //przygotowujemy obiekt z wariantami, jakiego spodziewa się templatka
            selectedVariant = nnwData.variants;
            nnwData.variants = {};
          }

          lsnNg.forEach(prodDef.VARIANTLIST, function(variant) {
            allowedVariants[variant.CODE] = self.isVariantAllowed(variant.CODE, clientId);
            riskErrors[variant.CODE] = errorHelper.getErrorsFieldsForRisk({
              productType: CONSTANTS.PRODUCT_TYPE_PERSON,
              objectClientId: clientId,
              risk: {
                code: CONSTANTS.PRODUCT_NNW,
                productCode: CONSTANTS.PRODUCT_NNW,
                variant: variant.CODE
              }
            });
            if (angular.isObject(variant.INCOME_LOSS) && angular.isObject(variant.INCOME_LOSS.professionCheck) && person.getAdditionalData('highRiskJob')) {
              rejectedVariants[variant.CODE] = variant.INCOME_LOSS.professionCheck.alertContent;
            }
            if (!isMutliVariant) {
              nnwData.variants[variant.CODE] = (selectedVariant === variant.CODE);
            }
          });

          var data = {
            personData: personData,
            clientId: clientId,
            personInfo: personHelper.getPersonInfo(person, self.getPersonInfoSettings()),
            collapsedPersonInfo: personHelper.getPersonInfo(person, self.getCollapsedPersonInfoSettings()),
            sumInsured: dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_PERSON, clientId, CONSTANTS.PRODUCT_NNW),
            NNW: nnwData,
            isMainInsured: (mainDataContainer.mainInsuredId === clientId),
            allowedVariants: allowedVariants,
            allowedChanges: allowedChangesHelper.getAllowedChangesFor(person),
            previouslySelectedVariants: prevSelVnts,
            unchangeableVariants: selectionHelper.getUnchangeableVariants(selectionHelper.getHistoricalDisabledVariants(CONSTANTS.PRODUCT_NNW, clientId), prevSelVnts, nnwData.variants),
            rejectedVariants: rejectedVariants, //odrzucone warianty (np. w wyniku udzielenia odpowiedzi klienta)
            canRemove: allowedChangesHelper.canRemovePersonFromRisk(clientId, CONSTANTS.PRODUCT_NNW),
            riskErrors: riskErrors,
            renewedVariants: self.getRenewedVariantsForPerson(clientId)
          };
          return data;
        };

        /**
         * zwraca dane (do szablonu) dla NNW w formie bezimiennej
         * @return {Object} jak poniżej
         */
        this.getIncognitoData = function() {
          var allowedVariants = self.getDefaultVariantSelection(),
            prevSelVnts = selectionHelper.getPreviouslySelectedVariants(CONSTANTS.PRODUCT_NNW_INCOGNITO, CONSTANTS.NNW_INCOGNITO_PERSON_ID);
          allowedVariants[CONSTANTS.VARIANT_I] = true;
          var data = {
            incognito: true,
            isMainInsured: false,
            clientId: CONSTANTS.NNW_INCOGNITO_PERSON_ID,
            sumInsured: mainDataContainer.nnwIncognito.su,
            NNW: {
              variants: mainDataContainer.nnwIncognito.variants
            },
            allowedVariants: allowedVariants,
            previouslySelectedVariants: prevSelVnts,
            unchangeableVariants: selectionHelper.getUnchangeableVariants(selectionHelper.getHistoricalDisabledVariants(CONSTANTS.PRODUCT_NNW_INCOGNITO, CONSTANTS.NNW_INCOGNITO_PERSON_ID), prevSelVnts, mainDataContainer.nnwIncognito.variants),
            insuredNo: mainDataContainer.nnwIncognito.insuredNo,
            canRemove: allowedChangesHelper.canRemovePersonFromRisk(CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW_INCOGNITO),
            riskErrors: errorHelper.getErrorsFieldsForRisk({
              productType: CONSTANTS.PRODUCT_TYPE_PERSON,
              objectClientId: mainDataContainer.mainInsuredId,
              risk: {
                code: CONSTANTS.PRODUCT_NNW_INCOGNITO,
                productCode: CONSTANTS.PRODUCT_NNW_INCOGNITO
              }
            }),
            renewedVariants: self.getRenewedVariantsForPerson(CONSTANTS.NNW_INCOGNITO_PERSON_ID)
          };
          return data;
        };

        /**
         * zwraca ustawienia dla informacji o ubezpieczonym
         * @return {Object}
         */
        this.getPersonInfoSettings = function() {
          return {
            elements: [{
              field: 'lastName'
            }, {
              field: 'firstName'
            }],
            maxLength: 999
          };
        };

        /**
         * zwraca ustawienia dla zwiniętej informacji o ubezpieczonym
         * @return {Object}
         */
        this.getCollapsedPersonInfoSettings = function() {
          return {
            elements: [{
              field: 'lastName'
            }, {
              field: 'firstName'
            }],
            maxLength: 999
          };
        };

        /**
         * Dodaje osobę do ubezpieczenia NNW
         * @param {String} personId clientID osoby
         * @param {Boolean} tarifficate czy staryfikować po dodaniu
         */
        this.addInsured = function(personId, tarifficate) {
          self.tplData.activeInsured = personId;
          tarifficate = (typeof tarifficate === 'boolean') ? tarifficate : true;
          var person = mainDataContainer.persons[personId];
          person.setAdditionalData({
            variants: {}
          }, CONSTANTS.PRODUCT_NNW);

          //ustawiamy domyślne wartości dynamicValues dla NNW
          if (angular.isObject(CONFIG.BEHAVIOR.defaultPersonDynamicValues[CONSTANTS.PRODUCT_NNW])) {
            person.set('dynamicValues', CONFIG.BEHAVIOR.defaultPersonDynamicValues[CONSTANTS.PRODUCT_NNW]);
          }
          if ((person.get('pesel') === '' || person.get('pesel')) === null && (person.get('birthDate') === '' || person.get('birthDate') === null)) {
            person.set('dynamicValues', {
              personAbove55YearsOld: false //?? chyba jakies obejscie dla usług
            });
          }

          dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_PERSON, personId, CONSTANTS.PRODUCT_NNW, self.getPersonDefaultVarinatsSumInsured());

          angular.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_NNW], function(selected, variant) //ustawiamy puste wartości
          {
            person.setAdditionalData(false, [CONSTANTS.PRODUCT_NNW, 'variants', variant]);
          });
          if (dataContainerHelper.getNnwPersonsCount() === 1) //jeśli przed dodniem pierwszej osoby na matrycy było coś zaznaczone to próbujemy to zaznaczyć
          {
            if (self.isMultiVariantProduct()) {
              angular.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_NNW], function(selected, variant) {
                if (selected) {
                  selectionHelper.selectPersonVariant(personId, CONSTANTS.PRODUCT_NNW, variant);
                }
              });
            } else //pojedynczy wariant
            {
              if (mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_NNW] !== null) {
                selectionHelper.selectPersonVariant(personId, CONSTANTS.PRODUCT_NNW, mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_NNW]);
              }
            }
          }
          self.refreshInsured();
          if (tarifficate) {
            actionHelper.runAction('tarifficate');
          }
        };

        /**
         * czy wariant ubezpieczenia jest dostępny dla osoby
         * @param  {String}  variant
         * @param  {String}  personId
         * @return {Boolean} true gdy dostępny
         */
        this.isVariantAllowed = function(variant, personId) {
          return !(angular.isDefined(CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_NNW]) && CONFIG.BEHAVIOR.mainInsuredOnlyRisksVariants[CONSTANTS.PRODUCT_NNW].indexOf(variant) !== -1 && mainDataContainer.mainInsuredId !== personId);
        };

        //zwraca domyslny zestaw wariantow i ich sum
        this.getPersonDefaultVarinatsSumInsured = function() {
          var prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW);
          var variants = {};
          angular.forEach(prodDef.VARIANTLIST, function(variant) {
            variants[variant.CODE] = variant.SUMINSUREDLIST ? variant.SUMINSUREDLIST[0].VALUE : 0;
          });
          return variants;
        };

        /**
         * dodaje NNW w formie bezimiennej
         */
        this.addIncognito = function() {
          var firstPerson = false;
          if (mainDataContainer.nnwIncognito === null) //rzeczywste dodanie, ustawiamy domyślne dane
          {
            if (dataContainerHelper.getNnwPersonsCount() === 0) {
              firstPerson = true;
            }
            mainDataContainer.nnwIncognito = {
              su: self.getPersonDefaultVarinatsSumInsured(),
              insuredNo: 0,
              variants: self.getDefaultVariantSelection()
            };
          }

          if (firstPerson) //synchronizujemy warianty osoby z matrycą
          {
            angular.forEach(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_NNW], function(selected, variant) {
              if (selected) {
                if (variant === CONSTANTS.VARIANT_I) {
                  selectionHelper.selectPersonVariant(CONSTANTS.NNW_INCOGNITO_PERSON_ID, CONSTANTS.PRODUCT_NNW, variant);
                } else {
                  selectionHelper.setVariant(CONSTANTS.PRODUCT_NNW, variant, false);
                }
              }
            });
          }
          self.tplData.activeInsured = CONSTANTS.NNW_INCOGNITO_PERSON_ID;
          self.refreshInsured();
        };


        /**
         * ustawia sume ubezpieczenia dla wybranego wariantu dla osoby
         * @param {String} personId
         * @param {String} variant
         * @param {String|Number} sumInsured
         * @param {Number} [maxSu] opcjonalnie maksymalne SU
         */
        this.setPersonSumInsured = function(personId, variant, sumInsured, maxSu) {
          if (sumInsured === '') {
            sumInsured = 0;
          }
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
            mainDataContainer.nnwIncognito.su[variant] = parseInt(sumInsured, 10);
          } else {
            dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_PERSON, personId, CONSTANTS.PRODUCT_NNW, parseInt(sumInsured, 10), variant, maxSu);
          }
          actionHelper.runAction('tarifficate');
        };

        /**
         * usuniecie ubezpieczonej osoby
         * @param  {String} personId clientId osoby
         */
        this.deleteInsured = function(personId) {
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
            mainDataContainer.nnwIncognito = null;
          } else {
            var person = mainDataContainer.persons[personId];
            delete mainDataContainer.suList[CONSTANTS.PRODUCT_TYPE_PERSON][personId][CONSTANTS.PRODUCT_NNW];
            person.deleteAdditionalData(CONSTANTS.PRODUCT_NNW);
          }

          selectionHelper.tryUnselectPersonalProduct(CONSTANTS.PRODUCT_NNW);
          actionHelper.runAction('personDeleted', personId);
          self.refreshInsured();
          actionHelper.runAction('tarifficate');
        };

        /**
         * ustawia dynamicValue na osobie
         * @param {String} personId clientId osoby
         * @param {String} name nazwa atrybutu
         * @param {*} value wartosc
         */
        this.setPersonDynamicValue = function(personId, name, value) {
          if (personId === CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
            mainDataContainer.nnwIncognito[name] = value;
          } else {
            var person = mainDataContainer.persons[personId];
            var dynamicValues = {};
            dynamicValues[name] = value;
            person.set('dynamicValues', dynamicValues);
            if (self.hasPersonNnwSelected(person)) {
              actionHelper.runAction('tarifficate');
            }
            self.refreshSingleInsured(personId);
          }
        };

        /**
         * czy osoba ma zaznaczone ryzyko NNW
         * @param {Person} person osoba
         * @return {boolean}
         */
        this.hasPersonNnwSelected = function(person) {
          var nnwData = person.getAdditionalData(CONSTANTS.PRODUCT_NNW);
          if (nnwData === null) {
            return false;
          }
          var result = false;
          if (self.isMultiVariantProduct()) {
            lsnNg.forEach(nnwData.variants, function(value) {
              if (value === true) {
                result = true;
                return false;
              }
              return true; //continue
            });
          } else {
            if (nnwData.variants) {
              result = true;
            }
          }
          return result;
        };

        /**
         * ustawia liczbę pracowników na ubezpieczeniu bezimiennym
         * @param {String} insuredNo liczba ubezpieczonych
         */
        this.setInsuredNo = function(insuredNo) {
          mainDataContainer.nnwIncognito.insuredNo = parseInt(insuredNo, 10);
          actionHelper.runAction('tarifficate');
        };

        /**
         * ustawia dochód miesięczny dla ubezpieczonego
         * @param {String} personId clientId osoby
         * @param {String|Number} monthlyIncome dochód
         */
        this.setMonthlyIncome = function(insured, variant, monthlyIncome) {
          var person = mainDataContainer.persons[insured.clientId];
          person.setAdditionalData(parseInt(monthlyIncome, 10), [CONSTANTS.PRODUCT_NNW, 'monthlyIncome']);

          var sumInsured = monthlyIncome * 10 * 12;
          self.roundSu(insured, variant, sumInsured);
        };


        /**
         * przełączanie wariantu dla osoby
         * @param  {String} personId
         * @param  {String} variant
         */
        this.togglePersonVariant = function(personId, variant) {
          var res = selectionHelper.togglePersonVariant(personId, CONSTANTS.PRODUCT_NNW, variant);
          self.refreshSingleInsured(personId);
          return res;
        };

        /**
         * zwraca obiekt z identyfikatorami osób oraz ich danymi dla NNW
         * {id: {dane NNW}}
         * @return {Object}
         */
        this.getPersons = function() {
          return dataContainerHelper.getPersonsForPersonalProduct(CONSTANTS.PRODUCT_NNW);
        };

        /**
         * czy NNW jest produkctem, dla którego można zaznaczyć wiele wariantów ubezpieczenia
         * @return {Boolean} true gdy tak
         */
        this.isMultiVariantProduct = function() {
          return CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(CONSTANTS.PRODUCT_NNW) !== -1;
        };

        /**
         * zwraca obiekt z listą zaznaczeń wariantów na false dla produktu
         * @return {Object} {variant: false[,...]}
         */
        this.getDefaultVariantSelection = function() {
          var variantSelection = {},
            prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW);
          angular.forEach(prodDef.VARIANTLIST, function(variant) {
            variantSelection[variant.CODE] = false;
          });
          return variantSelection;
        };

        /**
         * aktualizuje składki
         */
        this.refreshPremiums = function() {
          var premiumContainer = 'premiumList';
          self.tplData.premiums = {};
          angular.forEach(mainDataContainer[premiumContainer][CONSTANTS.PRODUCT_TYPE_PERSON], function(risks, personId) {
            if (angular.isDefined(risks[CONSTANTS.PRODUCT_NNW])) {
              self.tplData.premiums[personId] = angular.fromJson(angular.toJson(risks[CONSTANTS.PRODUCT_NNW]));
            }
          });
        };

        /**
         * odświeza dane dotyczące wznawainych wariantów dla wszystkich osób
         * @return {[type]} [description]
         */
        this.refreshRenewalData = function() {
          angular.forEach(self.tplData.nnwInsured, function(data, clientId) {
            self.tplData.nnwInsured[clientId].renewedVariants = self.getRenewedVariantsForPerson(data.clientId);
          });
        };

        /**
         * zwraca dane dotyczące wznawianych wariantów dla podanej osoby
         * @param  {String} clientId clientId osoby
         * @return {Object} {kod_wariantu: true|false}, true gdy do zaznaczenia
         */
        this.getRenewedVariantsForPerson = function(clientId) {
          var prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW),
            renewedVariants = {},
            renewedVariantsArray = renewalHelper.isRenewal() ? renewalHelper.getPersonalRiskMarks(CONSTANTS.PRODUCT_NNW, clientId) : []; //znaczniki wznowionych wariantów
          angular.forEach(prodDef.VARIANTLIST, function(variantDef) {
            renewedVariants[variantDef.CODE] = renewedVariantsArray.indexOf(variantDef.CODE) !== -1;
          });
          return renewedVariants;
        };

        this.roundSu = function(insured, variant, sumInsured) {
          var maxSuPerRisk = {
            'I': 50000,
            'II': 100000,
            'III': 500000
          };
          angular.forEach(self.tplData.prodDef.VARIANTLIST, function(variant) {
            if (angular.isDefined(variant.SUMINSURED_MAX)) {
              maxSuPerRisk[variant.CODE] = variant.SUMINSURED_MAX;
            }
          });

          var maxSu = maxSuPerRisk[variant];

          var variantDef = resourceHelper.getProdVariantDef(CONSTANTS.PRODUCT_NNW, variant);
          if (angular.isDefined(variantDef.INCOME_LOSS)) {
            var monthIncome = insured.NNW.monthlyIncome;
            if (maxSu < 10000) {
              maxSu = 10000;
            }

            if (monthIncome * 10 * 12 < maxSu) {
              maxSu = monthIncome * 10 * 12;
            }
          }

          self.setPersonSumInsured(insured.clientId, variant, sumInsured, maxSu);
        };

        /**
         * aktywuje kartę/wiersz ubezpieczonego
         * @param {String} insuredId clientId ubezpieczonego
         */
        this.setActiveInsured = function(insuredId) {
          self.tplData.activeInsured = insuredId;
          self.refreshActiveInsured();
        };

      };

      return new NnwHelper();
    }
  ])
  .run(['nnwHelper', 'actionHelper',
    function(nnwHelper, actionHelper) {
      actionHelper.registerHelper('nnwHelper', nnwHelper);
    }
  ]);