angular.module('salesPath2')
	.service('dcSubjectHelper', ['mainDataContainer', 'dcCommonHelper',
		function(mainDataContainer, dcCommonHelper) {
			var DcSubjectHelper = function() {
				var self = this;

				/**
				 * zwraca kolejny clientId dla podmiotu
				 * @return {Number}
				 */
				this.getNextSubjectId = function() {
					var id = mainDataContainer.nextSubjectId;
					mainDataContainer.nextSubjectId = mainDataContainer.nextSubjectId + 1;
					return dcCommonHelper.idToString(id);
				};

				/**
				 * zwraca kolejny clientId dla osoby
				 * @return {Number}
				 */
				this.getNextPersonId = function() {
					return self.getNextSubjectId();
				};

				/**
				 * zwraca kolejny clientId dla grupy
				 * @return {Number}
				 */
				this.getNextGroupId = function() {
					return self.getNextSubjectId();
				};

				this.getNextOrganizationId = function() {
					return self.getNextSubjectId();
				};

				/**
				 * zwraca liczbę organizacji na wniosku
				 * @return {Number}
				 */
				this.getOrganizationCount = function() {
					return Object.keys(mainDataContainer.organizations).length;
				};

				/**
				 * czy osoba pochodzi z NBK
				 * Należy podać clientId (szukanie w dataContainer) lub personData, jeśli chcemy sprawdzić przekazaną (np. jeszcze nie zapisaną) osobę
				 * @param  {String}  [clientId] id osoby
				 * @param  {Object}  [personData]  dane osoby
				 * @return {Boolean}
				 */
				this.isNbkPerson = function(clientId, personData) {
					var res = false,
						personRawData = null;
					if (angular.isObject(personData)) {
						personRawData = personData;
					} else if ((angular.isString(clientId) || angular.isNumber(clientId)) && angular.isDefined(mainDataContainer.persons[clientId])) {
						personRawData = mainDataContainer.persons[clientId].getData();
					}
					if (personRawData !== null && angular.isString(personRawData.metaData.masterId) && personRawData.metaData.masterId !== '') {
						res = true;
					}
					return res;
				};

				/**
				 * zwraca obiekt z identyfikatorami osob oraz danymi dotyczacymi zaznaczen wariantow ryzyk osobowych dla tych osob
				 * @param  {[type]} productCode [description]
				 * @return {Object} {clint_id_osoby: dane_ryzyka[,...]}, gdzie dane_ryzyka to person.getAdditionalData('kod_produktu/ryzyka, np OCZP')
				 */
				this.getPersonsForPersonalProduct = function(productCode) {
					return dcCommonHelper.getPersonsForPersonalProduct(productCode);
				};

				/**
				 * czy obiekt jest modelem osoby
				 * @param  {*}  obj coś
				 * @return {Boolean}
				 */
				this.isPerson = function(obj) {
					if (angular.isObject(obj) && angular.isString(obj.objectName) && obj.objectName === 'Person') {
						return true;
					}
					return false;
				};
			};

			return new DcSubjectHelper();
		}
	]);