angular.module('salesPath2')
  .service('basketSecondDetailsModalHelper', ['sp2CommonHelper',
    function(sp2CommonHelper) {
        var BasketSecondDetailsModalHelper = function() {

            this.modals = {
                basketSecondDetailsModal: {
                    settings: {
                      treatLabelAsI18nCode: true,
                      title: 'sp2.modal.basketSecondDetails.title',
                      size: 'lg',
                      okBtnName: '',
                      cancelBtnName: 'sp2.modal.cancelBtnName'
                    }
                }
            };

            this.showBasketSecondDetailsModal = function() {
              sp2CommonHelper.showModal('basketSecondDetailsModal');
            };
		};

        return new BasketSecondDetailsModalHelper();
    }
  ]);