angular.module('ihestiaCommonAdminMessage', [])
    .run(['ihestiaCommonAdminMessageHelper', 'ihestiaCommonStateHelper', '$timeout',
    function(ihestiaCommonAdminMessageHelper, ihestiaCommonStateHelper, $timeout){
      
      //jeśli mamy ustawioną flagę willRedirect to pomijamy ładowanie modułu wiadomości administracyjnych
      //występuje to np. dla przypadku opisanego w zgłoszeniu https://ebok.atena.pl/browse/IHESTIADA-341
      $timeout(function() {
        if (!ihestiaCommonStateHelper.stateParams.willRedirect) {
          ihestiaCommonAdminMessageHelper.init();
        }
      }, 0);

    }
  ]);