angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardPoolsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var PoolsSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/pools';
        this.businessArea = 'dashboard';
        this.port = '8282';

      };
      return new PoolsSvc();
    }
  ]);