angular.module('salesPath2')
  .service('summaryPageHelper', ['RESOURCES', 'CONFIG', 'sp2SelectionHelper', 'mainDataContainer', 'lsnTryEscapeHtml', 'sp2CommonHelper', 'summaryHelper', 'summaryPaymentHelper',
    'appVariables', '$sce', '$timeout', 'applicationHelper', 'CONSTANTS', 'summaryClausesHelper', 'summaryProductsClausesHelper', 'cessionModalHelper', 'discountsHelper', 'messagesHelper', '$filter', 'dataContainerHelper', 'sp2UiHelper', 'errorHelper', 'ihestiaAgHelper', 'sp2BlikPaymentModalHelper', 'ihestiaBasicPaymentHelper', 'personHelper', 'ContactModelConstants', 'LsnContactModelConstants', 'LsnClausesModelConstants', 'clientVerificationHelper', 'lsnUtils', 
    function(RESOURCES, CONFIG, selectionHelper, mainDataContainer, lsnTryEscapeHtml, sp2CommonHelper, summaryHelper, summaryPaymentHelper, appVariables, $sce, $timeout, // eslint-disable-line angular/di
             applicationHelper, CONSTANTS, summaryClausesHelper, summaryProductsClausesHelper, cessionModalHelper, discountsHelper, messagesHelper, $filter, dataContainerHelper, sp2UiHelper, errorHelper, ihestiaAgHelper, sp2BlikPaymentModalHelper, ihestiaBasicPaymentHelper, personHelper, ContactModelConstants, LsnContactModelConstants, LsnClausesModelConstants, clientVerificationHelper, lsnUtils) {
      var SummaryPageHelper = function() {
        var self = this;

        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          options: {
            loadInProgress: false,
            acceptInProgress: false,
            requestApplicationSave: false,
            suggestLifeInsurance: false,
            permissionToSirius: false,
            permissionToErgo4: false,
            showBlikPayment: false
          },
          spinnerOptions: {
            load: {
              label: $filter('translate')('loadingPolicy', {componentCode: 'sp2'})
            },
            accept: {
              label: $filter('translate')('approvalPolicy', {componentCode: 'sp2'})
            }
          },
          data: {
            place: '',
            policyGrouping: false,
            sums: {},
            policies: {},
            insurerInfo: [],
            buttonsSettings: summaryHelper.getButtonsSettings()
          },
          modalSettings: {
            todayOfferConfirm: {
              headerDescription: null, // optional description shown in header section of modal
              destinatedObject: 'offer', // destinated object type ('policy' | 'offer')
              applyCallback: null, // (funciton) callback triggered when "ok/apply" button is pressed
              treatLabelAsI18nCode: true,
              title: ['summary.todayOfferModal.title', {componentCode: 'sp2'}],
              cancelBtnName: ['sp2.modal.cancel', {componentCode: 'sp2'}],
              okBtnName: ['Public.confirm', {componentCode: 'Public'}],
              okBtnDisabled: true,
              keyboard: false,
              size: 'md'
            },
            extraLifeModal: {
              ergo4Allowed: false,
              treatLabelAsI18nCode: true,
              title: ['summary.extraLife.title', {componentCode: 'sp2'}],
              cancelBtnName: ['Public.close', {componentCode: 'Public'}],
              okBtnName: ['summary.extraLife.btn.e4', {componentCode: 'sp2'}],
              thirdBtnName: ['summary.extraLife.btn.remindLater', {componentCode: 'sp2'}],
              keyboard: false,
              size: 'md'
            },
            insurerContactDataModal: {
              treatLabelAsI18nCode: true,
              title: ['sp2.person.contactData', {componentCode: 'sp2'}],
              cancelBtnName: ['sp2.modal.cancel', {componentCode: 'sp2'}],
              okBtnName: ['Public.confirm', {componentCode: 'Public'}],
              okBtnDisabled: true,
              keyboard: false,
              size: 'md',
              applyCallback: null, // (function(bool)) callback triggered when data patching succeed
              emailRequired: true // is email permanently required. If not, telephone is enough.
            },
            iOfferSelectionModal: {
              treatLabelAsI18nCode: true,
              title: ['modal.iOfferSelectionModal.title', {componentCode: 'sp2'}],
              cancelBtnName: 'sp2.modal.close',
              okBtnName: false,
              size: 'sm',
              applyCallback: null // (function(bool)) callback triggered when "icon/button" on modal is clicked, bool argument means "send iOffer via email"
            },
            agentClauses: {
              headerDescription: null, // optional description shown in header section of modal
              applyCallback: null, // (funciton) callback triggered when "ok/apply" button is pressed
              treatLabelAsI18nCode: true,
              title: $filter('translate')('sp2.modal.agentClausesModal.title', {componentCode: 'sp2'}),
              cancelBtnName: ['sp2.modal.cancel', {componentCode: 'sp2'}],
              cancelBtnDisabled: false,
              okBtnName: ['Public.confirm', {componentCode: 'Public'}],
              keyboard: false,
              size: 'md'
            }
          },
          placeEditable: true //czy edytowalne miejsce podpisania umowy
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'languageChanged.afterStateReload':
              self.afterLanguageChange();
              break;
            case 'loadingPolicies':
              self.tplData.options.loadInProgress = true;
              break;
            case 'applicationRequestFailed':
              self.tplData.options.loadInProgress = false;
              self.tplData.options.acceptInProgress = false;
              break;
            case 'policiesAccepted':
              self.policiesAccepted();
              break;
            case 'policyModeHasBeenSet':
              break;
            case 'tarifficationEnded':
            case 'availableOperationsLoaded':
              self.refreshButtonSettings();
              self.refreshPlaceEditability();
              self.setPolicyGrouping();
              self.refreshErrors();
              break;
            case 'policiesReceived':
              if (self.tplData.options.requestApplicationSave) {
                self.updateApplication();
              } else {
                self.showPolicies();
                if (appVariables.openPolicyModal !== null) {
                  self.showPolicyModal(appVariables.openPolicyModal.policyId, appVariables.openPolicyModal.type, appVariables.openPolicyModal.tab);
                }
                appVariables.openPolicyModal = null;
              }
              break;
            case 'readOnlyChanged':
              self.refreshPlaceEditability();
              break;
            default:
              break;
          }
        };

        this.afterLanguageChange = function() {
          self.tplData.spinnerOptions = {
            load: {
              label: $filter('translate')('loadingPolicy', {componentCode: 'sp2'})
            },
            accept: {
              label: $filter('translate')('approvalPolicy', {componentCode: 'sp2'})
            }
          };
        };

        this.refreshErrors = function() {
          self.tplData.errors = errorHelper.getMainErrorForPage(CONSTANTS.PAGE_SUMMARY);
        };

        //po zatwierdzeniu polis
        this.policiesAccepted = function() {
          this.showPolicies();
          var text; // (string) labelka uzupełniana niżej
          if (mainDataContainer.policies.length > 1) {
            text = ['summary.policyHasBeenApproved', {componentCode: 'sp2'}];
          } else {
            text = ['summary.policyWasApprovedGivenNumber', {
              componentCode: 'sp2',
              params: {policieNumber: lsnTryEscapeHtml(mainDataContainer.policies[0].number)}
            }];
          }


          var confirmModalSettings = {
            treatLabelAsI18nCode: true,
            title: ['sp2.modal.info.title', {componentCode: 'sp2'}],
            okBtnName: ['sp2.modal.close', {componentCode: 'sp2'}],
            cancelBtnName: '',
            text: text,
            size: 'sm'
          };

          var blikPaymentPolicies = dataContainerHelper.getBlikPaymentPolicies();
          if (blikPaymentPolicies.length > 0) {
            self.tplData.options.showBlikPayment = true;
            confirmModalSettings.okBtnName = ['payWithBlik', {componentCode: 'Public'}];
            confirmModalSettings.cancelBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
            confirmModalSettings.okBtnCallback = function() {
              sp2BlikPaymentModalHelper.showModal().then(angular.noop, function(event) {
                if (event && event.blikPayed) {
                  self.tplData.options.showBlikPayment = false;
                  self.checkAndShowExtraLifeModal(true);
                }
              });
            };
          } else {
            confirmModalSettings.okBtnCallback = function() {
              self.checkAndShowExtraLifeModal(true);
            };
          }

          sp2CommonHelper.showConfirmModal(confirmModalSettings);
        };

        this.showPolicies = function() {
          self.tplData.options.loadInProgress = false;
          self.tplData.options.acceptInProgress = false;
          self.tplData.data.policies = summaryHelper.getPolicies();
          self.tplData.data.insurerInfo = summaryHelper.getInsurerInfo();
          self.tplData.data.sums = summaryHelper.getSums(self.tplData.data.policies);
          self.refreshButtonSettings();
        };

        //odśwież ustawienia przycisków
        this.refreshButtonSettings = function() {
          self.tplData.data.buttonsSettings = summaryHelper.getButtonsSettings();
        };

        /**
         * odswieza edytowalnosc miejsca podpisania umowy
         */
        this.refreshPlaceEditability = function() {
          //mozże poniższe podejście jest zbyt rozpisane, ale przy najmniej dobrze widać wszystkie przypadki
          switch (appVariables.appMode) {
            case CONSTANTS.APP_MODE_CALCULATION:
              if (appVariables.isSupplement || (appVariables.readOnly && !appVariables.isOcBuyer)) {
                self.tplData.placeEditable = false;
              } else {
                self.tplData.placeEditable = true;
              }
              break;
            case CONSTANTS.APP_MODE_OFFER:
              if (appVariables.isSupplement || dataContainerHelper.isPolicyWithDynamicValue('renewalVariant', 1)) { //czy wznowienie centralne
                self.tplData.placeEditable = false;
              } else {
                self.tplData.placeEditable = true;
              }
              break;
            default: //np status polisy
              self.tplData.placeEditable = false;
          }
        };

        //otwarcie modala platnosci, to moze do helpera platnosci
        this.showPaymentModal = function(policyId) {
          var policy = dataContainerHelper.getPolicyByNumber(policyId);
          summaryPaymentHelper.showPaymentModal(policy);
        };

        //aktualizuje wniosek uwzględniając dane z formularza templatki
        this.updateApplication = function() {
          $timeout(function() {
            self.tplData.options.requestApplicationSave = false;
            mainDataContainer.application.set('place', self.tplData.data.place);
            applicationHelper.saveApplication();
          }, 0);
        };

        /**
         * showPolicyModal - wywołąnie modala do edycji pozycji na polisie
         * @param {String} policyId id polisy
         * @param {String} type typ modala
         * @param {String} [tab] kod zakladki w znizkach/odstepstwach
         */
        this.showPolicyModal = function(policyId, type, tab) {
          var policy = dataContainerHelper.getPolicyByNumber(policyId);
          switch (type) {
            case CONSTANTS.POLICY_EXTRA_PAYMENT:
              summaryPaymentHelper.showPaymentModal(policy);
              break;
            case CONSTANTS.POLICY_EXTRA_CLAUSES:
              summaryClausesHelper.showClausesModal(policy);
              break;
            case CONSTANTS.POLICY_PRODUCTS_CLAUSES:
              policy.objects = self.getObjectsFromTplPolicy(policyId);
              summaryProductsClausesHelper.getClauses(policy);
              break;
            case CONSTANTS.POLICY_EXTRA_DISCOUNT:
              discountsHelper.showEditDiscountsModal(policyId, (angular.isString(tab) ? tab : null));
              break;
            case CONSTANTS.POLICY_EXTRA_CESSION:
              cessionModalHelper.showEditCessionModal(policyId);
              break;
            default:
              break;
          }
        };

        this.getObjectsFromTplPolicy = function(policyNumber) {
          var policies = [];
          angular.forEach(self.tplData.data.policies, function(category) {
            policies = policies.concat(category);
          });

          var policy = policies.find(function(policy) {
            return policy.number === policyNumber;
          });

          return policy.objects ? policy.objects : [];
        };

        /**
         * sprawdza czy mozna utworzyc oferte wiazaca
         * @param {Boolean} checkStartDate czy sprawdzac date poczatku ochrony (czy rowna dzisiejszej dacie)
         * @param {Boolean} ignoreFraudulent ignore fraudulent client message while checking
         * @return {boolean} true gdy mozna, false w p/w
         */
        this.canMakeOffer = function(checkStartDate, ignoreFraudulent) {
          checkStartDate = (checkStartDate === true ? true : false);
          var data = {};
          data.can = true;
          if (messagesHelper.isAnyError(false, ignoreFraudulent)) {
            data.can = false;
            data.errors = true;
          }
          if (checkStartDate) {
            lsnNg.forEach(mainDataContainer.policies, function(policy) {
              var dataToCompare = (appVariables.isSupplement ? policy.product.get('dynamicValues').operationDate : policy.start);
              if ((new XDate(dataToCompare).toString('yyyy-MM-dd')) === RESOURCES.TODAY) {
                data.can = false;
                data.dateToday = true;
                return false;
              }
              return true;
            });
          }
          return data;
        };

        /**
         * check if conditions to send offer by email has been met
         * @return {boolean}
         */
        this.canEmailOffer = function() {
          var insurer = dataContainerHelper.getInsurer();
          return _.some(insurer.contacts, function(contact) {
              return contact.code === LsnContactModelConstants.CONTACT_TYPE_MAIL && contact.value;
            })
            && !!insurer.clauses && insurer.clauses[CONFIG.OFFER_VIA_EMAIL.clause.code] === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
        };

        self.setPolicyGrouping = function() {
          var appDynVals = mainDataContainer.application.get('dynamicValues');
          self.tplData.data.policyGrouping = !appDynVals._calculateEstatesSeparately || appVariables.isSupplement;
        };

        /**
         * akcja konwersji wniosku na ofertę
         */
        this.convertApplicationToOffer = function() {
          sp2UiHelper.showBlockUi($filter('translate')('makingOffer', {
            componentCode: 'sp2'
          }));
          applicationHelper.convertApplicationToOffer(function(resp) {
            sp2UiHelper.hideBlockUi();
            var parsedDate = new XDate(resp.offerValidDate).toString('yyyy-MM-dd HH:mm');
            sp2CommonHelper.showConfirmModal({
              treatLabelAsI18nCode: true,
              title: ['sp2.modal.info.title', {
                componentCode: 'sp2'
              }],
              okBtnName: ['sp2.modal.close', {
                componentCode: 'sp2'
              }],
              cancelBtnName: '',
              text: ['modal.offerConfirm.text', {
                componentCode: 'sp2',
                params: {
                  expirationDate: parsedDate
                }
              }], //'Oferta jest wiążąca i została zablokowana do edycji. Jej warunki obowiązują do dnia {expirationDate}. Aby nanieść poprawki cofnij ofertę do edycji. UWAGA - Spowoduje to ponowną taryfikację składki.'
              okBtnCallback: function() {
                self.checkAndShowExtraLifeModal();
              }
            });
          });
        };

        /**
         * send payment to BM, client should get notification by mail or sms
         * @return {[type]} [description]
         */
        this.sendOfferPayment = function() {
          var payments = ihestiaBasicPaymentHelper.getPaymentsFromSpPolicies(mainDataContainer.policies);
          var customerData = ihestiaBasicPaymentHelper.getCustomerDataFromSpPerson(dataContainerHelper.getInsurer());
          var emailContact = personHelper.getPersonContact(dataContainerHelper.getInsurer(), ContactModelConstants.CONTACT_TYPE_MAIL);
          if (emailContact && emailContact.value) {
            customerData.email = emailContact.value;
          }
          var params = {
            isOffer: true
          };
          ihestiaBasicPaymentHelper.savePayment(payments, customerData, params);
        };

        /**
         * action of reserving policy number for current calculation/application
         */
        this.reservePolicyNumber = function() {
          sp2UiHelper.showBlockUi($filter('translate')('spinner.reservingPolicyNumber', {
            componentCode: 'sp2'
          }));
          applicationHelper.reservePolicyNumber(function(resp) {
            sp2UiHelper.hideBlockUi();
            var parsedDate = new XDate(resp.offerValidDate).toString('yyyy-MM-dd HH:mm');

            var confirmModalSettings = {
              treatLabelAsI18nCode: true,
              title: ['sp2.modal.info.title', {
                componentCode: 'sp2'
              }],
              okBtnName: ['sp2.modal.close', {
                componentCode: 'sp2'
              }],
              cancelBtnName: '',
              text: ['modal.offerConfirm.text', {
                componentCode: 'sp2',
                params: {
                  expirationDate: parsedDate
                }
              }], //'Oferta jest wiążąca i została zablokowana do edycji. Jej warunki obowiązują do dnia {expirationDate}. Aby nanieść poprawki cofnij ofertę do edycji. UWAGA - Spowoduje to ponowną taryfikację składki.'
              okBtnCallback: function() {
                self.checkAndShowExtraLifeModal();
              }
            };

            var blikPaymentPolicies = dataContainerHelper.getBlikPaymentPolicies();
            if (blikPaymentPolicies.length > 0) {
              self.tplData.options.showBlikPayment = true;
              self.refreshButtonSettings();
              confirmModalSettings.cancelBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
              confirmModalSettings.okBtnName = ['payWithBlik', {componentCode: 'Public'}];
              confirmModalSettings.okBtnCallback = function() {
                sp2BlikPaymentModalHelper.showModal().then(angular.noop, function(event) {
                  if (event && event.blikPayed) {
                    self.tplData.options.showBlikPayment = false;
                    applicationHelper.setPolicyMode();
                  }
                });
              };
            } else if (dataContainerHelper.getPaymentMethod() === CONSTANTS.PAYMENT_METHOD_TRANSFER) {
              self.sendOfferPayment();
            }

            sp2CommonHelper.showConfirmModal(confirmModalSettings);
          });
        };

        /**
         * shows offer/policies confirmation modal for today's insurance starting case
         * @param  {String} destinatedObject 'offer' or 'policy'
         * @param  {String} headerDescription string wiht header description
         * @param  {Array|String} title Array with translation data or translated string (modal title)
         * @param  {Array|String} okBtnName Array with translation data or translated string ("ok" button label)
         * @return {undeifned}
         */
        this.showTodaysOfferConfirmModal = function(destinatedObject, applyCallback, headerDescription, title, okBtnName) {
          self.tplData.modalSettings.todayOfferConfirm.title = title || ['summary.todayOfferModal.title', {componentCode: 'sp2'}];
          self.tplData.modalSettings.todayOfferConfirm.destinatedObject = destinatedObject || 'offer';
          self.tplData.modalSettings.todayOfferConfirm.applyCallback = applyCallback || null;
          self.tplData.modalSettings.todayOfferConfirm.headerDescription = headerDescription || null;
          self.tplData.modalSettings.todayOfferConfirm.okBtnName = okBtnName || ['Public.confirm', {componentCode: 'Public'}];
          sp2CommonHelper.showModal('todayOfferConfirmModal');
        };

        /**
         * check conditions and if met, "Extra Life" modal is being shown
         */
        this.checkAndShowExtraLifeModal = function(noReminder) {
          if (clientVerificationHelper.isExtraLifeAllowed()) {
            self.showExtraLifeModal(noReminder);
          }
        };

        /**
         * shows "Extra Life"/ERGO 4 modal
         * @param {boolean} [noReminder=false] hide "Remind later" button
         * @return {undeifned}
         */
        this.showExtraLifeModal = function(noReminder) {
          noReminder = !!noReminder;
          var e4Allowed = this.isE4Allowed();
          self.tplData.modalSettings.extraLifeModal.ergo4Allowed = e4Allowed;
          self.tplData.modalSettings.extraLifeModal.okBtnName = e4Allowed ? ['summary.extraLife.btn.e4', {componentCode: 'sp2'}] : null;
          if (noReminder) {
            self.tplData.modalSettings.extraLifeModal.thirdBtnName = null;
          } else {
            self.tplData.modalSettings.extraLifeModal.thirdBtnName = ['summary.extraLife.btn.remindLater', {componentCode: 'sp2'}];
          }
          sp2CommonHelper.showModal('extraLifeModal');
        };

        /**
         * show "holder contact data required to send iOffer via email" modal
         * @param {function} applyCallback function(sendViaEmail: bool) if sendViaEmail===true => make ioffer and send it via email, otherwise just make ioffer
         * @return {undeifned}
         */
        this.showinsurerContactDataModal = function(applyCallback, sendViaEmail) {
          self.tplData.modalSettings.insurerContactDataModal.applyCallback = applyCallback || null;
          self.tplData.modalSettings.insurerContactDataModal.emailRequired = !!sendViaEmail;
          sp2CommonHelper.showModal('insurerContactDataModal');
        };

        /**
         * show "send iOffer" modal with 2 options (ioffer and ioffer via email)
         * @param {function} applyCallback function(sendViaEmail: bool) if sendViaEmail===true => make ioffer and send it via email, otherwise just make ioffer
         * @return {undeifned}
         */
        this.showIOfferSelectionModal = function(applyCallback) {
          self.tplData.modalSettings.iOfferSelectionModal.applyCallback = applyCallback || null;
          sp2CommonHelper.showModal('iOfferSelectionModal');
        };

        this.showAgentClausesModal = function(applyCallback) {
          self.tplData.modalSettings.agentClauses.applyCallback = applyCallback || null;
          sp2CommonHelper.showModal('agentClausesModal');
        };

        /**
         * Suggest life insuracne box
         * NOTE
         * Deprecated since https://ebok.atena.pl/browse/IHESTLIFE-5855
         */
        this.suggestLifeInsurance = function() {
          var permissionToSirius = ihestiaAgHelper.canAccessSyriusz(),
            permissionToErgo4 = ihestiaAgHelper.canAccessLifeSalePathApp() && ihestiaAgHelper.canStartSalePath();
          self.tplData.options.suggestLifeInsurance = permissionToSirius || permissionToErgo4;
          self.tplData.options.permissionToSirius = permissionToSirius;
          self.tplData.options.permissionToErgo4 = permissionToErgo4;
        };

        /**
         * are all conditions met to access Ergo 4 sales path
         * @return {boolean}
         */
        this.isE4Allowed = function() {
          var transferData = lsnUtils.findObjInArray(RESOURCES.DATA_TRANSFER, {url: 'E4_URL'}),
            gaAllowed = true;
          if (transferData && transferData.generalAgreementPrefix) {
            gaAllowed = sp2CommonHelper.getAllowedGeneralAgreement(transferData.generalAgreementPrefix);
          }
          return ihestiaAgHelper.canAccessLifeSalePathApp() && ihestiaAgHelper.canStartSalePath() && transferData && gaAllowed;
        };

        /**
         * redirect to Ergo 4
         * @return {boolean} false if redirected straightly
         */
        this.gotoErgo4 = function() {
          var transferData = lsnUtils.findObjInArray(RESOURCES.DATA_TRANSFER, {url: 'E4_URL'});
          if (!transferData) {
            self._redirectStraightToE4();
            return false;
          }

          applicationHelper.transferApplicationData(transferData.symbol, function(applId) {
            var clientUrl = sp2CommonHelper.getServerConfig().getUrl(transferData.url, 'external/init?action=viewApplication&applicationId=' + applId);
            if (clientUrl) {
              lsnUtils.openWindow(clientUrl);
            }
          }, transferData.generalAgreementPrefix || null);
          return true;
        };

        this._redirectStraightToE4 = function() {
          lsnUtils.openWindow(sp2CommonHelper.getServerConfig().get('external', 'E4_URL'));
        };
      };

      return new SummaryPageHelper();
    }
  ])
  .run(['summaryPageHelper', 'actionHelper',
    function(summaryPageHelper, actionHelper) {
      actionHelper.registerHelper('summaryPageHelper', summaryPageHelper);
    }
  ]);
