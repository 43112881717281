angular.module('salesPath2')
  .service('personModalHelper', ['$timeout', '$filter', 'personHelper', 'PersonModel', 'personGroupHelper', 'sp2CommonHelper', 'mainDataContainer', 'CONSTANTS', 'errorHelper', 'appVariables', 'CONFIG', 'allowedChangesHelper', 'dataContainerHelper', 'MessageModelConstants',
    function($timeout, $filter, personHelper, PersonModel, personGroupHelper, sp2CommonHelper, mainDataContainer, CONSTANTS, errorHelper, appVariables, CONFIG, allowedChangesHelper, dataContainerHelper, MessageModelConstants) {
      var PersonModalHelper = function() {
        var self = this;
        var DEFAULT_EDIT_MODAL_PARAMS = {
          errors: {},
          messages: {
            warning: [],
            error: []
          },
          person: null,
          personData: null,
          editedId: null,
          contacts: {},
          documents: {},
          clauses: {
            email: false,
            marketing: false
          },
          showElements: {
            PKD: false,
            Regon: false,
            Nip: false,
            clauses: true,
            foreigner: false
          },
          hideElements: {
            allAddresses: false,
            //secondAddr: czy posiada adres korespondenycjny
            secondAddr: true
          },
          context: null,
          personChange: false, //czy w trybie zmiany osoby
          sourcePersonId: null, //clientId osoby, którą będziemy zmieniać/zastępować
          personChangeRole: CONSTANTS.PERSON_ROLE_NONE, //rola osoby zmienianej
          isNbkPerson: false,
          allowedChanges: {} //obiekt przechowujący konfigurację dozwolonych zmian na formularzu
        };

        var DEFAULT_SEARCH_MODAL_PARAMS = {
          titleSide: $filter('translate')('sp2.modal.personSearchModal.titleSide', {
            componentCode: 'sp2'
          }) + ':',
          excludeIds: [],
          searchTitle: $filter('translate')('sp2.modal.personSearchModal.searchTitle', {
            componentCode: 'sp2'
          }),
          searchHeader: $filter('translate')('sp2.modal.personSearchModal.searchHeader', {
            componentCode: 'sp2'
          }),
          editHeader: $filter('translate')('sp2.modal.personSearchModal.editHeader', {
            componentCode: 'sp2'
          }),
          context: null, //kod eventu wywołanego przy zapisie
          personChange: false, //czy w trybie zmiany osoby
          sourcePersonId: null, //clientId osoby, którą będziemy zmieniać/zastępować          
          personChangeRole: CONSTANTS.PERSON_ROLE_NONE //rola osoby zmienianej
        };

        var DEFAULT_ADD_MODAL_PARAMS = {
          titleSide: $filter('translate')('sp2.modal.personAddModal.titleSide', {
            componentCode: 'sp2'
          }) + ':',
          excludeIds: [],
          searchTitle: $filter('translate')('sp2.modal.personAddModal.searchTitle', {
            componentCode: 'sp2'
          }),
          searchHeader: $filter('translate')('sp2.modal.personAddModal.searchHeader', {
            componentCode: 'sp2'
          }),
          editHeader: $filter('translate')('sp2.modal.personAddModal.editHeader', {
            componentCode: 'sp2'
          }),
          context: null, //kod eventu wywołanego przy zapisie,
          personChange: false, //czy w trybie zmiany osoby
          sourcePersonId: null, //clientId osoby, którą będziemy zmieniać/zastępować
          personChangeRole: CONSTANTS.PERSON_ROLE_NONE, //rola osoby zmienianej
          mainInsuredOnly: false //czy mozliwosc wyboru wylącznie głównego ubezpieczonego (np. przy dokupieniach)
        };

        //ustawienia modali
        this.modals = {};
        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.personSearchModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.personSearchModal.title',
            cancelBtnName: 'sp2.modal.cancel',
            okBtnName: false
          }
        };

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.personAddModal = {
          settings: {
            treatLabelAsI18nCode: true,
            cancelBtnName: 'sp2.modal.cancel',
            okBtnName: false,
            title: 'sp2.modal.personAddModal.title',
            size: 'sm'
          }
        };

        /**
         * edycja osoby
         * @type {Object}
         */
        this.modals.personEditModal = {
          settings: {
            treatLabelAsI18nCode: true,
            cancelBtnName: ['sp2.modal.cancel', {
              componentCode: 'sp2'
            }],
            okBtnName: ['sp2.modal.okBtnName', {
              componentCode: 'sp2'
            }],
            title: 'sp2.modal.personEditModal.title',
            size: 'lg'
          }
        };

        this.currentlyEditedPersonData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);
        this.searchModalParams = angular.copy(DEFAULT_SEARCH_MODAL_PARAMS);
        this.addModalParams = angular.copy(DEFAULT_ADD_MODAL_PARAMS);

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          if (actionName === 'readOnlyChanged') {
            self.updateSettingsByReadOnly();
          }
        };

        /**
         * aktualizuje domyślne ustawienia dla modali na podstawie informacji o trybie readOnly
         */
        this.updateSettingsByReadOnly = function() {
          //wczesniej bylo tu blokowanie przycisku "zapisz", ale po dokupieniach, rola tego przyciksu zmieniła się na "zamknij", dlatego już go nie blokujemy.
        };

        /**
         * Ustawiamy domyślne wartości parametrów edycji osoby
         * @return {VehicleHelper}
         */
        this.clearEditModalData = function() {
          self.currentlyEditedPersonData = angular.copy(DEFAULT_EDIT_MODAL_PARAMS);
          return self;
        };

        /**
         * Ustawiamy domyślne wartości parametrów edycji osoby
         * @return {VehicleHelper}
         */
        this.clearSearchModalData = function() {
          self.searchModalParams = angular.copy(DEFAULT_SEARCH_MODAL_PARAMS);
          return self;
        };

        /**
         * Ustawiamy domyślne wartości parametrów edycji osoby
         * @return {VehicleHelper}
         */
        this.clearAddModalData = function() {
          self.addModalParams = angular.copy(DEFAULT_ADD_MODAL_PARAMS);
          return self;
        };

        /**
         * ustawia błędy (o ile są) dla aktualnie edytowanej osoby na modalu
         */
        this.setAllErrorForCurrentlyEditedPerson = function() {
          self.currentlyEditedPersonData.errors = errorHelper.getErrorFieldsForObject(CONSTANTS.PRODUCT_TYPE_PERSON, self.currentlyEditedPersonData.editedId);
          //resetujemy message
          self.currentlyEditedPersonData.messages.warning = [];
          self.currentlyEditedPersonData.messages.error = [];
          //grupujemy i deduplikujemy errory (poczial na warn i error)
          angular.forEach(self.currentlyEditedPersonData.errors, function(objs1, group) {
            if (group === 'person') {
              angular.forEach(objs1, function(msg) {
                self._pushMessage(msg);
                msg.message = null;
              });
            } else {
              angular.forEach(objs1, function(objs2) {
                angular.forEach(objs2, function(msg) {
                  self._pushMessage(msg);
                  msg.message = null;
                });
              });
            }
          });
        };

        /**
         * dodaje message odpowiedniego typu do self.currentlyEditedPersonData.messages
         * @param  {Object} msg message
         */
        this._pushMessage = function(msg) {
          if (msg.type === MessageModelConstants.MESSAGE_TYPE_WARN && self.currentlyEditedPersonData.messages.warning.indexOf(msg.message) === -1) {
            self.currentlyEditedPersonData.messages.warning.push(msg.message);
          } else if (msg.type === MessageModelConstants.MESSAGE_TYPE_ERROR && self.currentlyEditedPersonData.messages.error.indexOf(msg.message) === -1) {
            self.currentlyEditedPersonData.messages.error.push(msg.message);
          }
        };

        /**
         * Okno edycji/dodawania osoby - formularz z danymi osoby
         * @param {Object} data dane do edycji:
         * id: clientId osoby - opcja, jesli podany, to edycja, jesli nie, to dodawanie nowej osoby
         * context: string, kontekst edycji
         * personChange: bool, czy zmiana głównego ubezpieczonego
         */
        this.openPopupEditPerson = function(data) {
          if (typeof data.id === 'undefined') {
            self.currentlyEditedPersonData.person = new PersonModel();
          } else {
            self.currentlyEditedPersonData.editedId = data.id;
            self.currentlyEditedPersonData.person = mainDataContainer.persons[data.id];
            personHelper.fillEmptyDataFromPesel(self.currentlyEditedPersonData.person);
            self.setAllErrorForCurrentlyEditedPerson();
          }
          var isInsurer = (self.currentlyEditedPersonData.person.metaData.get('clientId') === mainDataContainer.insurerId);
          self.currentlyEditedPersonData.isNbkPerson = dataContainerHelper.isNbkPerson(self.currentlyEditedPersonData.person.metaData.get('clientId'));

          if (angular.isString(data.context)) {
            self.currentlyEditedPersonData.context = data.context;
          }

          if (angular.isString(data.personChangeRole)) {
            self.currentlyEditedPersonData.personChangeRole = data.personChangeRole; //na wypadek podmiany osoby, zapisujemy kontekst/rolę osoby
          }

          if (angular.isDefined(data.personChange) && angular.isString(data.sourcePersonId)) {
            self.currentlyEditedPersonData.personChange = data.personChange;
            self.currentlyEditedPersonData.sourcePersonId = data.sourcePersonId;
          }

          var title; // string
          if (angular.isUndefined(data.editHeader)) {
            title = $filter('translate')('sp2.modal.personEditModal.title1', {
              componentCode: 'sp2'
            });
          } else {
            title = data.editHeader;
          }
          if (angular.isString(data.id)) {
            title += ' - ' + $filter('translate')('sp2.modal.personEditModal.title2', {
              componentCode: 'sp2'
            });
          } else {
            title += ' - ' + $filter('translate')('sp2.modal.personEditModal.title3', {
              componentCode: 'sp2'
            });
          }
          self.modals.personEditModal.settings.title = title;
          if (isInsurer && data.personChangeRole === CONSTANTS.PERSON_ROLE_INSURER) {
            self.modals.personEditModal.settings.thirdBtnName = $filter('translate')('sp2.modal.personEditModal.btnChangeInsurer', {
              componentCode: 'sp2'
            });
          } else if (CONFIG.BEHAVIOR.allowMainInsuredChange && self.currentlyEditedPersonData.person.metaData.get('clientId') === mainDataContainer.mainInsuredId && data.personChangeRole === CONSTANTS.PERSON_ROLE_INSURED) {
            self.modals.personEditModal.settings.thirdBtnName = $filter('translate')('sp2.modal.personEditModal.thirdBtnName', {
              componentCode: 'sp2'
            });
          } else {
            delete self.modals.personEditModal.settings.thirdBtnName;
          }
          var allowedChanges = allowedChangesHelper.getAllowedChangesFor(self.currentlyEditedPersonData.person);
          self.currentlyEditedPersonData.allowedChanges = allowedChanges;
          if (allowedChanges.allDisabled) {
            self.modals.personEditModal.settings.cancelBtnName = '';
            self.modals.personEditModal.settings.okBtnName = ['sp2.modal.close', {
              componentCode: 'sp2'
            }];
            delete self.modals.personEditModal.settings.thirdBtnName;
          } else {
            self.modals.personEditModal.settings.cancelBtnName = ['sp2.modal.cancel', {
              componentCode: 'sp2'
            }];
            self.modals.personEditModal.settings.okBtnName = ['sp2.modal.okBtnName', {
              componentCode: 'sp2'
            }];
          }
          $timeout(function() {
            sp2CommonHelper.showModal('personEditModal');
          }, 0);

        };

        /**
         * Okno dodawania osoby
         */
        this.openPopupAddPerson = function(data) {
          var translatedAddModalParams = {
            titleSide: $filter('translate')('sp2.modal.personAddModal.titleSide', {
              componentCode: 'sp2'
            }) + ':',
            excludeIds: [],
            searchTitle: $filter('translate')('sp2.modal.personAddModal.searchTitle', {
              componentCode: 'sp2'
            }),
            searchHeader: $filter('translate')('sp2.modal.personAddModal.searchHeader', {
              componentCode: 'sp2'
            }),
            editHeader: $filter('translate')('sp2.modal.personAddModal.editHeader', {
              componentCode: 'sp2'
            }),
            context: null, //kod eventu wywołanego przy zapisie,
            personChange: false, //czy w trybie zmiany osoby
            sourcePersonId: null, //clientId osoby, którą będziemy zmieniać/zastępować
            personChangeRole: CONSTANTS.PERSON_ROLE_NONE //rola osoby zmienianej
          };

          self.addModalParams = angular.extend(translatedAddModalParams, data || {});

          if (appVariables.isSupplement && CONFIG.BEHAVIOR.mainInsuredOnlyRisks.indexOf(self.addModalParams.context) !== -1) {
            self.addModalParams.mainInsuredOnly = true;
          }

          if (typeof data.title !== 'undefined') {
            self.modals.personAddModal.settings.title = data.title;
          } else {
            self.modals.personAddModal.settings.title = $filter('translate')('sp2.modal.personAddModal.title', {componentCode: 'sp2'});
          }
          $timeout(function() {
            sp2CommonHelper.showModal('personAddModal');
          }, 0);
        };

        /**
         * Okno wyszukiwania osoby
         */
        this.openPopupSearchPerson = function(data) {
          var translatedSearchModalParams = {
            titleSide: $filter('translate')('sp2.modal.personSearchModal.titleSide', {
              componentCode: 'sp2'
            }) + ':',
            excludeIds: [],
            searchTitle: $filter('translate')('sp2.modal.personSearchModal.searchTitle', {
              componentCode: 'sp2'
            }),
            searchHeader: $filter('translate')('sp2.modal.personSearchModal.searchHeader', {
              componentCode: 'sp2'
            }),
            editHeader: $filter('translate')('sp2.modal.personSearchModal.editHeader', {
              componentCode: 'sp2'
            }),
            context: null, //kod eventu wywołanego przy zapisie
            personChange: false,
            personChangeRole: CONSTANTS.PERSON_ROLE_NONE //rola osoby zmienianej
          };

          self.searchModalParams = angular.extend(translatedSearchModalParams, data || {});

          if (typeof data.title !== 'undefined') {
            self.modals.personSearchModal.settings.title = data.title;
          } else {
            self.modals.personSearchModal.settings.title = $filter('translate')('sp2.modal.personSearchModal.title', {componentCode: 'sp2'});
          }

          $timeout(function() {
            sp2CommonHelper.showModal('personSearchModal');
          }, 0);
        };

      };

      return new PersonModalHelper();
    }
  ])
  .run(['personModalHelper', 'actionHelper',
    function(personModalHelper, actionHelper) {
      actionHelper.registerHelper('personModalHelper', personModalHelper);
    }
  ]);
