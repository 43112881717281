/**
 * dokłada nbsp w wybranych miejscach żeby
 */
angular.module('lsnBase.filters')
  .filter('prettyAgencyName', [function(){
    return function(inputValue) {
      if(!angular.isString(inputValue) || inputValue === ''){
        return inputValue;
      }

      var toNbsp = ['s. c.']; // zamieniamy spacje w słowach na nbsp
      var postWords = ['sc', 's.c.', 's.\u00A0c.']; // te występują po wyrazach których dotyczą, więć będziemy je łączyć z poprzednimi
      var preWords = ['usł.']; // te występują przed wyrazami których dotyczą, więc będziemy je łączyć z następującymi po nich

      // ,[a-zA-Z] rozdzielamy spacją (przykładowo RYCHŁY-STUCKE,EDYTA KWIATKOWSKA)
      // minus zamieniamy na niełamliwy (przykładowo RYCHŁY-STUCKE,EDYTA KWIATKOWSKA)
      // - jeśli niestandardowy skrót (1-2 litery i kropka lub liczba kropek w wyrazie > 30% [P.P.U]) to łączymy z następującym wyrazem, przykład: POLFERRIES J. WOLSKI

      var regexpNbsp = new RegExp(toNbsp.join('|'), 'gi');
      inputValue = inputValue.replace(regexpNbsp, function(matched){
        return matched.replace(' ', '\u00A0'); // zmieniamy spacje na nbsp
      });

      var regexpPre = new RegExp('\\s' + preWords.join('\\s|\\s') + '\\s', 'gi');
      inputValue = inputValue.replace(regexpPre, function(matched){
        return matched.substr(0, matched.length - 1) + '\u00A0';// spacja z końca na &nbsp;
      });

      var regexpPost = new RegExp('\\s' + postWords.join('\\s|\\s') + '\\s', 'gi');
      inputValue = inputValue.replace(regexpPost, function(matched){
        return '\u00A0' + matched.substr(1);// spacja z początku na &nbsp;
      });

      // dla post words musimy jeszcze spradzić czy jakieś nie jest na końcu stringa, bo wtedy nie łapią się na powyższą regułę
      var regexpPostEnd = new RegExp('\\s' + postWords.join('$|\\s') + '$', 'gi');
      inputValue = inputValue.replace(regexpPostEnd, function(matched){
        return '\u00A0' + matched.substr(1);// spacja z początku na &nbsp;
      });

      var regexpComma = new RegExp(',[a-ząćęłńóśżź]', 'gi');
      inputValue = inputValue.replace(regexpComma, function(matched){
        return ', ' + matched.substr(1);// po przecinku wrzucamy spację
      });

      var regexpHyphen = new RegExp('[a-ząćęłńóśżź]-[a-ząćęłńóśżź]', 'gi');
      inputValue = inputValue.replace(regexpHyphen, function(matched){
        return matched.replace('-', '\u2011'); // zmieniamy hyphen na non breaking hyphen
      });

      var regexpGeneral = new RegExp('([a-ząćęłńóśżź]+\\.)+[a-ząćęłńóśżź]?\\s', 'gi');
      inputValue = inputValue.replace(regexpGeneral, function(matched){
        // liczymy liczbę kropek
        var dotsCount = 0;
        angular.forEach(matched, function(char){
          if(char === '.')
          {
            dotsCount = dotsCount + 1;
          }
        });
        if(dotsCount / (matched.length - 1) > 0.3) // na końcu jest spacja, więc długoś wyrazu to length - 1
        {
          return matched.substr(0, matched.length - 1) + '\u00A0'; // ma dużo kropek, więc traktujemy jako skrót i zamieniamy spację na końcu na nbsp
        }
        else
        {
          return matched; // uznajemy, że to nie skrót i zostawiamy bez zmian
        }
      });

      return inputValue;
    };
}]);