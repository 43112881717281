/**
 * helper komunikatow z BOSa
 */
angular.module('salesPath2').service('bosMessageHelper', ['navigationHelper', 'CONSTANTS', 'appVariables', 'salesPath2BosErrorMapper', 'actionHelper', 'groupModalHelper', 'dataContainerHelper', 'travelDestHelper',
  function(navigationHelper, CONSTANTS, appVariables, salesPath2BosErrorMapper, actionHelper, groupModalHelper, dataContainerHelper, travelDestHelper) {
    var BosMessageHelper = function() {
      var self = this;

      //lista wczytanych kodów wiadomości używana do deduplikacji
      this.loadedCodes = {};

      //kody błędów kalkulacji które wyświetlamy
      this.calculationCodes = [
        100001, 100002, 100003,
        100008, 100044, 100045, 100046,
        100027, 100085, 100086, 100087,
        100088, 100093, 100097, 100098,
        100100, 100104, 100107, 100115,
        100117, 100131, 100138, 100142,
        100159, 100161, 100170, 100171,
        100183, 100184, 100186, 100038,
        100189, 100192, 100193, 100300,
        100306, 100355, 100357,
        100302, 100347, 100366, 100503,
        100504, 100505, 100510, 100511,
        100512, 100514, 100520,
        100433, 100439, 100441, 100313,
        100314, 100315, 100316, 100317,
        100318, 100322, 100338, 100339,
        100345, 100700, 100353, 100701,
        100517, 100370, 100371, 100372,
        100374, 100385, 100605, 100392,
        100393, 100167,
        100395, 100425, 100542, 100543,
        100525, 100531, 100532, 100544,
        100545, 100560, 100342,
        120038, 100567, 100568, 100569,
        100570, 100136, 120025, 100102,
        100703, 100704, 100706, 100571,
        100110, 200028, 200037, 200029,
        200035, 200027, 200030, 200036,
        100428, 200014, 100707, 100705,
        200053, 200023, 100447, 100414,
        200017, 200043, 100368, 200066,
        100527, 200010, 200039, 200013,
        200012, 200007, 200004, 100440,
        200074, 200072, 200071, 200083,
        200084, 200063, 200065, 200096,
        200079, 200077, 200076, 200101,
        200103, 200108, 200107, 200109,
        200100, 200102, 200115, 200130,
        100127, 200119, 200111, 200105,
        200086, 200087, 200078, 200080,
        200081, 200082, 200123, 200019,
        200085, 200088, 200089, 200090,
        200091, 200092, 200093, 200094,
        200095, 200097, 200098, 200099,
        200104, 200106, 200110, 200112,
        200113, 200114, 200116, 200117,
        200118, 200120, 200121, 200122,
        200169, 200174, 200172, 200020,
        200144, 200155, 200173, 200153,
        200156, 200075, 100390, 200242,
        200175, 200255, 200256, 200070
      ];

      //kody błędów wznowień które wyświetlamy
      this.renewalCodes = [
        101077, 101078, 101081, 101082,
        101083, 101084, 101090, 101094,
        101085, 101086, 101088, 101093,
        101099, 101089, 101087, 101091,
        101095, 101100, 101092, 101105,
        200147, 200150, 200148, 200146
      ];

      this.exludedCodes = []; //kody do wykluczenia per projekt

      /**
       * czy pokazujemy o takim kodzie
       * @param  {string|int} code
       * @return {bool}
       */
      this.codeToShow = function(code) {
        if (!self.isBosCode(code)) {
          return true; //jeśli to nie jest kod bossowy to przepuszczamy
        }
        code = parseInt(code, 10);
        return (self.calculationCodes.indexOf(code) !== -1 || self.renewalCodes.indexOf(code) !== -1);
      };

      this.clearLoadedCodes = function() {
        self.loadedCodes = {};
      };

      this.isBosCode = function(code) {
        var decimalRegex = new RegExp('[0-9]');
        return decimalRegex.test(code.substring(0, 1));
      };

      /**
       * sprawdza czy istnieje duplikat dla messega
       * @return {boolean} true jesli brak duplikatow, false w przeciwnym wypadku
       */
      this.checkDuplicate = function(message) {

        var code = parseInt(message.code, 10);

        switch (code) {
          case 100161: // Nie można rozbić płatności na raty gdyż nie osiągnięto limitu składki.
            return true;
          // pojedyncze wzgledem formuly wiadomosci
          case 100395: //Opcja OUZ OC wariant A jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia OC.
          case 100425:
          case 100542:
          case 100543: //Ubezpieczenie Następstw Nieszczęśliwych Wypadków: wszystkie osoby wskazane do ubezpieczenia są w wieku powyżej 65 lat.
          case 100525:
          case 100544:
          case 100545:
          case 100393:
          case 100531: // 4 raty
          case 100532: // 2 raty
          case 100186: // %1: Zniżka dla ubezpieczenia musi być mniejsza niż 100%.
          case 100300: // %1: Dany pojazd nie może być ubezpieczony.
          case 100306: // %1: Składkę podwyższono do określonej taryfą wartości minimalnej.
          case 100355: // %1: Podaj datę pierwszej rejestracji
          case 100357: // %1: Podaj rok produkcji
          case 100347: // %1:  Pojazd eksploatowany dłużej niż 12 miesięcy nie może być traktowany jako nowy.
          case 100366: // Zostało wybrane ubezpieczenie %1, a nie podano sumy ubezpieczenia.
          case 100510: // Dla dwóch rat  składka minimalna to %1 zł.
          case 100511: // Dla czterech rat składka minimalna to %1 zł.
          case 100512: // Aby rozłożyć składkę na 2 raty, składka musi być większa od %1 zł (obecnie %2 zł)
          case 100520: // %1 jest dostępne pod warunkiem wykupienia: %2
          case 100098: // %1: Okres ochrony nie może być krótszy niż rok.
          case 100107: // %1: Umowa nie może zostać podpisana na okres krótszy niż %2 dni.
          case 100313: // Nie można rozłożyć płatności na raty gdyż wyliczona rata nie osiągnęła minimalnej wartości 150zł.
          case 100315: // %1: Nie podano wartości %2.
          case 100316: // %1: Podana wartość %2 jest błędna.
          case 100317: // W wybranym pakiecie dany pojazd nie może być ubezpieczony w ramach ryzyka %1.
          case 100322: // %1: Ilość lat bezszkodowej kontynuacji uległa wyzerowaniu ze względu na posiadane szkody.
          case 100338: // Opcja %1 jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 15% zniżką za przebieg ubezpieczenia AC.
          case 100353: // %1: Liczba szkód w ostatnich dwóch latach nie może być wyższa niż liczba szkód w ostatnich pięciu latach.
          case 100370: // %1: Nie podano wariantu ubezpieczenia.
          case 100371: // %1: Nie podano wariantu ubezpieczenia.
          case 100372: // %1: Zbyt niska wartość pojazdu dla wariantu I.
          case 100514: // Wybrano %1,  a nie wypisano żadnej osoby z tym ryzykiem.
          case 100517: // Aby rozłożyć składkę na 4 raty, składka musi być większa od %1 zł (obecnie %2 zł)
          case 100605: // %1: Nie wybrano żadnego pełnoletniego ubezpieczonego.
          case 100700: // %1: Ubezpieczenia nie można zawrzeć we wskazanym wariancie.
          case 100044: // Zawarcie ubezpieczenia %1 jest możliwe wyłącznie pod warunkiem zawarcia ubezpieczenia %2
          case 100045: // Ubezpieczenie %1 dostępne jest wyłącznie jeśli ubezpieczający jest jednocześnie ubezpieczonym
          case 100046: // Zawarcie ubezpieczenia %1 nie jest możliwe przy jednoczesnym zawarciu ubezpieczenia %2.
          case 100085: // %1: Umowa ubezpieczenia nie może zostać podpisana na okres dłuższy niż rok.
          case 100086: // %1: Rok produkcji poźniejszy niż data pierwszej rejestracji.
          case 100087: // %1: Aby ubezpieczyć pojazd starszy niż 12 lat wymagana jest zgoda MS/Centrali.
          case 100088: // %1: Wystąpiła ponad 30 dniowa przerwa w kontynuacji ubezpieczenia AC/KR. Ubezpieczenie pojazdu wymaga zgody MS/Centrali.
          case 100093: // %1: Nie można mieć równocześnie bezszkodowej kontynuacji AC i szkód zgłoszonych w ostatnim roku.
          case 100097: // %1: Osiągnięto poziom składki minimalnej.
          case 100100: // %1: Wskazanego pojazdu nie można objąć ochroną w ramach ubezpieczenia Kradzieży.
          case 100104: // Opcja %1 jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 15% zniżką za przebieg ubezpieczenia %2 oraz ukończyli 26 lat.
          case 100115: // %1: Wskazany pojazd nie może zostać objęty ochroną.
          case 100117: // %1: Wskazany rodzaj pojazdu nie może zostać objęty ochroną.
          case 100131: // %1: Niezgodność wyliczonego okresu eksploatacji i zaznaczonego atrybutu pojazd fabrycznie nowy.
          case 100138: // %1: Ubezpieczenie można zawrzeć jedynie z ubezpieczeniem OC wykupionym na okres pełnego roku.
          case 100142: // %1: Nie można ubezpieczyć pojazdu o wartości przekraczającej limit %2.
          case 100170: // %1: Podana wartość pojazdu przewyższa dopuszczalny limit %2 zł.
          case 100171: // %1: W związku z wysoką szkodowością klienta nie ma możliwości zawarcia ubezpieczenia.
          case 100167: // Podaj rok produkcji
          case 100560: // Przekroczono maksymalny okres odroczenia początku okresu ochrony pakietu.
          case 100342: // Brak możliwości zawarcia polisy, na polisie dwukrotnie wskazano klienta typu bank/leasing.
          case 100567: // %1: nie określono czy nieruchomość jest przedmiotem najmu.
          case 100569: // %1: SU nie może być większa niż %2 (10% SU domu/mieszkania).
          case 100570: // %1: nie można porównać sumy ubezpieczenia z sumą ubezpieczenia domu/mieszkania
          case 100102: // %1: Podano niepoprawną klasę Bonus-Malus.
          case 100703:
          case 100704:
          case 100571:
          case 100110:
          case 200028: //Łączna suma ubezpieczeń od ognia w ramach jednej lokalizacji ze wszystkich polis nie może przekroczyć 2000000 zł. Obecnie wynosi: %1 zł.
          case 200035: //OC w życiu prywatnym: liczba pełnoletnich osób w gospodarstwie domowym nie może być mniejsza od 1.
          case 200037: //Ubezpieczenie architektury posesji od ognia i innych zdarzeń losowych: suma ubezpieczenia nie może być większa niż 66666.6 (20% sumy ubezpieczenia budynków/lokalu).
          case 200029:
          case 200027: //Ubezpieczenie NNW od utraty dochodów: składka należna jest za niska. Powinna wynosić przynajmniej 6000.
          case 200030: //Łączna suma ubezpieczenia w ramach OC majątkowego w segmencie B i C (poza OC zawodu) ze wszystkich polis nie może przekroczyć 1000000. Obecnie wynosi: 2000000 zł
          case 200036: //AC: Zawarcie umowy w wariancie Kosztorysowym możliwe jest wyłącznie w odniesieniu do pojazdów, których okres eksploatacji nie przekracza 12 lat.
          case 100428: //Opcja OUZAC jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia AC.
          case 200014: //Wybrano zgodę na odstępstwo od składki, a nie podano wartości odstępstwa na przynajmniej jednym ubezpieczeniu.
          case 100707: //Wybrano zgodę na odstępstwo od składki lub zgodę na przekroczenie pełnomocnictw, a nie podano soby decydującej.
          case 100705: //Wybrano zgodę na odstępstwo od składki lub zgodę na przekroczenie pełnomocnictw a nie podano szczegółów.
          case 200053: //Opcja OUZOC jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia OC.
          case 200023: //Ubezpieczenie NNW forma imienna: osoba powyżej 65 roku życia nie może zostać objęta ubezpieczeniem.
          case 100527: //Ubezpieczenie NNW od utraty dochodów: suma ubezpieczenia nie może wynosić 0 zł.
          case 100447: //Ochroną na warunkach klauzuli Stała suma ubezpieczenia można objąć pojazdy nowe i używane, z tym że pojazd używany nie może przekroczyć 12 roku eksploatacji.
          case 200043: //OC najemcy: nieruchomość w lokalizacji $1 nie jest przedmiotem najmu.
          case 100414: //Wykupienie ryzyka OC możliwe jest wyłącznie dla ubezpieczeń zawieranych na pełne 12 miesięcy.
          case 200017: //OC najemcy można kupić pod warunkiem kupienia: Ubezpieczenie budynków od ognia i innych zdarzeń losowych, Ubezpieczenie lokalu od ognia i innych zdarzeń losowych
          case 200010: //$1 Jedna osoba może być związana z jednym obiektem ubezpieczenia
          case 200039: //Ubezpieczenie wartości pieniężnych / gotówki w lokalu od ognia i innych zdarzeń losowych: suma ubezpieczenia może wynosić maksymalnie 50000 zł.
          case 200013: //Klient szkodowy nie może dokupić żadnych dodatków.
          case 200012: //Klient szkodowy może być ubezpieczony tylko w I wariancie OC.
          case 200007: //Opcja OUZOC jest dostępna pod warunkiem, że wszyscy ubezpieczeni nie mieli żadnej szkody w ostatnim roku.
          case 200004: //Łączna ilośc ubezpieczonych pojazdów nie może być większa niż 15.
          case 100440: //Zgody na obniżenie składki minimalnej nie stosuje się do ubezpieczeń krótkoterminowych.
          case 200061: //Brak możliwości ubezpieczenia pojazdu spoza Eurotax
          case 200065: //Klient posiada aktywne ubezpieczenie OC rzeczoznawców majątkowych, zarządców nieruchomości i pośredników nieruchomościna innej polisie, taryfikacja nie może być przeprowadzona.
          case 200172: //"Ubezpieczenie architektury ogrodu od ognia: SU nie może być większa niż 129000 zł (30% SU domu/mieszkania)."
          case 200096:
          case 200079:
          case 200077:
          case 200076:
          case 200101:
          case 200103:
          case 200108:
          case 200107:
          case 200109:
          case 200100:
          case 200102:
          case 200115:
          case 200130:
          case 100127:
          case 200119:
          case 200111:
          case 200105:
          case 200086:
          case 200087:
          case 200078:
          case 200080:
          case 200081:
          case 200082:
          case 200085:
          case 200088:
          case 200089:
          case 200090:
          case 200091:
          case 200092:
          case 200093:
          case 200094:
          case 200095:
          case 200097:
          case 200098:
          case 200099:
          case 200104:
          case 200106:
          case 200110:
          case 200112:
          case 200113:
          case 200114:
          case 200116:
          case 200117:
          case 200118:
          case 200120:
          case 200121:
          case 200122:
          case 200123:
          case 200169: //
          case 200174: //Ubezpieczenie architektury ogrodu od ognia: suma ubezpieczenia nie może być większa niż 299999999.7 (30% sumy ubezpieczenia 
          case 200173: //Ubezpieczenie architektury ogrodu od ognia: suma ubezpieczenia nie może być większa niż 299999999.7 (30% sumy ubezpieczenia 
          case 200156: //Brak możliwości rozłożenia składki na raty ze względu na historię płatności Klienta.
          case 200242: //Proszę wskazać przynajmniej jedno ubezpieczenie do dodania lub edycji.
          case 200175: //Jeśli na polisie jest tylko NNW (oprócz dodatków), to NNW musi być roczne.
            if (angular.isDefined(self.loadedCodes[message.code])) {
              if (self.loadedCodes[message.code].indexOf(message.message) !== -1) {
                return false;
              }
            } else {
              self.loadedCodes[message.code] = [];
            }

            self.loadedCodes[message.code].push(message.message);
            return true;
          // pojedyncze wzgledem parametru object
          case 0:
          case 100302: // Nie podano sumy ubezpieczenia.
          case 100368: // Nie podano daty nabycia uprawnień.
          case 120025: // Wystąpił błąd podczas wykonania metody liczącej: Atrybut 'Liczba szkód OC w okresie ostatnich 5 lat' na obiekcie 'Właściciel pojazdu' ma ustawioną niepoprawną wartość '111'.
          case 100385: // %1: Właścicielem lub współwłaścicielem pojazdu może byc jedynie osoba fizyczna lub osoba prowadząca jednoosobową działalność gospodarczą.
          case 100701: // Nie podano sumy ubezpieczenia dla wszystkich przedmiotów.
          case 120038: // Przekroczono wyznaczoną dopuszczalną krotność wystąpień obiektu o id...
          case 200072:
          case 200071:
          case 100159: // Nie osiągnięto poziomu łącznej składki minimalnej.
          case 100318: // Nie można rozłożyć składki na dwie raty.
          case 100433: // Umowa ubezpieczenia nie może zostać podpisana na okres dłuższy niż rok
          case 100339: // Nie podano przyczyny wprowadzenia dodatkowej zniżki/zwyżki.
          case 100439: // Zniżki: Została wybrana zgoda na obniżenie składki minimalnej, a nie podano żadnej zniżki.
          case 100441: // Nie podano pochodzenia zgody na odstępstwo od składki.
          case 100706: // Wybrano niejednoznaczną podstawę udzielenia zgody na przekroczenie pełnomocnictw/odstępstwo od składki
          case 200074: // Okres ochrony polisy nie może przekraczać 36 miesięcy
          case 200083: // nie można wybrać jednocześnie rozszerzenia...
          case 200084: // nie można wybrać jednocześnie rozszerzenia...
          case 101077:
          case 101078:
          case 101081:
          case 101082:
          case 101083:
          case 101084:
          case 101088:
          case 101093:
          case 101089:
          case 101100:
          case 101092:
          case 200020: //za duza SU w wariancie NNW
          case 200144: //Car Assistance - Awaria Turbo: Ubezpieczenie dostępne wyłącznie dla pojazdów do 12 roku eksploatacji.
          case 200155: //Ubezpieczenie samochodów kempingowych, pojazdów wolnobieżnych, motocykli, motorowerów, przyczep kempingowych i przyczep o ładowności do 3
          case 200153: //Polisa może być wznowiona maksymalnie 90 dni po zakończeniu ochrony poprzedniej polisy
          case 200146: //Nie można wznowić polisy, która jeszcze się nie rozpoczęła.
          case 200075: //Przekroczono maksymalny, 90-dniowy okres odroczenia rozpoczęcia ochrony na polisie.
          case 100390: //NNWKiP: Nie wybrano ilości miejsc siedzących w pojeździe.
          case 200019: //Na polisie Klienta nie mogą znaleźć się mury od ognia.
          case 200255: //Nie podano liczby ubezpieczonych na polisie.
          case 200070: // Wystawienie polisy w czasie trwania ochrony jest możliwe tylko gdy oferta dotyczy wyłącznie odnowienia umowy ubezpieczenia OC komunikacyjnego, która została w pełni opłacona i nie została wypowiedziana.
            if (angular.isDefined(self.loadedCodes[message.code])) {
              if (self.loadedCodes[message.code].indexOf(message.object) !== -1) {
                return false;
              }
            } else {
              self.loadedCodes[message.code] = [];
            }
            self.loadedCodes[message.code].push(message.object);
            return true;
          // pojedyncze
          case 100001:   //Należy wskazać atrybut 'Kierunek podróży'.
          case 100002:   //Wstrzymujemy możliwość sprzedaży polis ERGO Podróż klientom udającym się do kraju...
          case 100003:
          case 100345: // Rok produkcji późniejszy niż data początku okresu ochrony.
          case 100374: // Nie można zawrzeć Kradzieży we wskazanym wariancie Autocasco.
          case 100392: // Dla ubezpieczeń krótkoterminowych możliwe jest rozłożenie tylko na dwie raty, jeżeli okres ubezpieczenia wynosi minimum 6 miesięcy.
          case 100503: // Nie podano liczby szkód powodziowych w ciągu ostatnich 5 lat.
          case 100504: // Nie wybrano czy mieszkanie znajduje się na ostatniej kondygnacji.
          case 100505: // Nie wybrano czy mieszkanie znajduje się na parterze.
          case 100189: // Data rozpoczęcia ochrony nie może być późniejsza niż data zakończenia ochrony.
          case 100192: // Polisa musi być zawarta na jeden rok. Od daty początku ochrony do daty końca ochrony nie mija jeden rok.
          case 100193: // Polisa może być zawarta maksymalnie na jeden rok.
          case 100008: // Data początku ochrony jest mniejsza niż data końca okresu ochrony.
          case 100027: // Składka na ubezpieczeniu jest mniejsza niż wymagana składka minimalna.
          case 100183: // Osoba pomiędzy 55 a 65 rokiem życia może zostać objęta ubezpieczeniem NNW rozszerzonym o następstwa zawałów serca i udarów mózgu, pod warunkiem nieszkodowej kontynuacji przedstawionego ubezpieczenia.
          case 100184: // Osoba powyżej 65 roku życia nie może zostać objęta ubezpieczeniem NNW rozszerzonym o następstwa zawałów serca i udarów mózgu.
          case 200066: //Osoba objęta ubezpieczeniem podróży musi mieć wykupione przynajmniej jedno inne ubezpieczenie w ramach danej polisy.
          case 100136: //HCA AC Prestiż: Ubezpieczenie dostępne wyłącznie dla pojazdów do 5 roku eksploatacji.: //
          case 100038:
          case 101085:
          case 101086:
            if (typeof self.loadedCodes[message.code] !== 'undefined') {
              return false;
            }
            self.loadedCodes[message.code] = true;
            return true;
          //walidacyjne komunikaty
          // pojedyncze wzgledem formuly wiadomosci
          case 101090: //%1: Żaden z ubezpieczonych nie kontynuuje wznawianego ubezpieczenia.
          case 101094:
          case 101099:
          case 101091:
          case 101095:
            if (typeof self.loadedCodes[message.code] !== 'undefined') {
              if (self.loadedCodes[message.code].indexOf(message.message) !== -1) {
                return false;
              }
            } else {
              self.loadedCodes[message.code] = [];
            }

            self.loadedCodes[message.code].push(message.message);
            return true;
          // pojedyncze względem rodzaju polisy, mienie i cesję traktujemy tak samo
          case 101087:
          case 101105:
            var policytype = message.policy;
            //zmieniamy cesję na mienie
            if (policytype === CONSTANTS.POLICY_TYPE_PROPERTY_CESSION) {
              policytype = CONSTANTS.POLICY_TYPE_PROPERTY;
            }

            if (typeof self.loadedCodes[message.code] !== 'undefined') {
              if (self.loadedCodes[message.code].indexOf(policytype) !== -1) {
                return false;
              }
            } else {
              self.loadedCodes[message.code] = [];
            }

            self.loadedCodes[message.code].push(policytype);
            return true;
          //pojedyncze wg parametru object
          case 200063: //Wybrana promocja nie może zostać zastosowana. Polisa nie spełnia warunków promocyjnych."
          case 200256: //Wprowadzony kod promocyjny dla polisy: nE7 jest nieprawidłowy lub promocja wygasła: S7.
          case 200147: //Wznowienie z wyprzedzeniem powyżej 30 dni wymaga Zgody Centrali.
          case 200150: //OC krajowe - OC posiadaczy pojazdów zaopatrzonych w polskie tablice rejestracyjne: Podano niepoprawną datę początku ochrony na ubezpieczeniu. Brak możliwości wznowienia OC po przerwie.
          case 200148: //Car Assistance - Awaria: Podano niepoprawną datę początku ochrony na ubezpieczeniu. Data początku ochrony na ubezpieczeniu musi być większa od daty końca ochrony na ubezpieczeniu na poprzedniej polisie oraz mniejsza bądź równa dacie końca ochrony na ubezpieczeniu na poprzedniej polisie plus 90 dni.
            if (typeof self.loadedCodes[message.code] !== 'undefined') {
              if (self.loadedCodes[message.code].indexOf(message.object) !== -1) {
                return false;
              }
            } else {
              self.loadedCodes[message.code] = [];
            }
            self.loadedCodes[message.code].push(message.object);
            return true;
          default:
            break;
        }
        // jak nie oprogramowane to nie pokazujemy
        return false;
      };

      this.deleteExludedCodes = function() {
        angular.forEach(self.exludedCodes, function(code) {
          if (self.calculationCodes.indexOf(code) !== -1) {
            self.calculationCodes.splice(self.calculationCodes.indexOf(code), 1);
          }
        });
      };

      /**
       * próba obsługi błędu z Bos po kodzie
       * @param  {Error} error
       * @param  {Object} params
       * @return {Bool} czy błąd został obsłużony
       */
      this.tryHandleByCode = function(error, params) {
        var code = parseInt(error.code, 10);
        var handled = true;

        switch (code) {
          case 200017: //OC najemcy można kupić pod warunkiem kupienia: Ubezpieczenie budynków od ognia i innych zdarzeń losowych, Ubezpieczenie lokalu od ognia i innych zdarzeń losowych
          case 200043: //OC najemcy: nieruchomość w lokalizacji $1 nie jest przedmiotem najmu.
          case 100306: //Składkę podwyższono do określonej taryfą wartości minimalnej.
          case 200028: //łączna suma ubezpieczen od ognia nie moze przekroczyc ...
          case 200019: //Na polisie Klienta nie mogą znaleźć się mury od ognia.
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OGI]);
            break;
          case 200029:
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_KRA]);
            break;
          case 200023: // Ubezpieczenie NNW forma imienna: osoba powyżej 65 roku życia nie może zostać objęta ubezpieczeniem.
          case 100543: // Ubezpieczenie Następstw Nieszczęśliwych Wypadków: wszystkie osoby wskazane do ubezpieczenia są w wieku powyżej 65 lat.
          case 100527: // Ubezpieczenie NNW od utraty dochodów: suma ubezpieczenia nie może wynosić 0 zł.
          case 200175: //Jeśli na polisie jest tylko NNW (oprócz dodatków), to NNW musi być roczne.
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_NNW]);
            break;
          case 200030: //Łączna suma ubezpieczenia w ramach OC majątkowego w segmencie B i C (poza OC zawodu) ze wszystkich polis nie może przekroczyć 2000000. Obecnie wynosi: 2000548 zł.
          case 200035: //OC w życiu prywatnym: liczba pełnoletnich osób w gospodarstwie domowym nie może być mniejsza od 1.
          case 200065: //Klient posiada aktywne ubezpieczenie OC rzeczoznawców majątkowych, zarządców nieruchomości i pośredników nieruchomościna innej polisie, taryfikacja nie może być przeprowadzona
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OCZP]);
            break;
          case 100371:
          case 100393: //Nie można ubezpieczyć podanego rodzaju pojazdu w wybranym wariancie Autocasco
          case 200053: //Opcja OUZOC jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia OC.
          case 100425: //Opcja OUZOC jest dostępna pod warunkiem, że wszyscy ubezpieczeni ukończyli 26 lat.
          case 100428: //Opcja OUZAC jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia AC.
          case 100447: //Ochroną na warunkach klauzuli Stała suma ubezpieczenia można objąć pojazdy nowe i używane, z tym że pojazd używany nie może przekroczyć 12 roku eksploatacji.
          case 100414: //Wykupienie ryzyka OC możliwe jest wyłącznie dla ubezpieczeń zawieranych na pełne 12 miesięcy.
          case 100395: //Opcja OUZ OC wariant A jest dostępna pod warunkiem, że wszyscy ubezpieczeni legitymują się co najmniej 20% zniżką za przebieg ubezpieczenia OC.
          case 200012: //Klient szkodowy może być ubezpieczony tylko w I wariancie OC.
          case 200007: //Opcja OUZOC jest dostępna pod warunkiem, że wszyscy ubezpieczeni nie mieli żadnej szkody w ostatnim roku.
          case 200004: //Łączna ilośc ubezpieczonych pojazdów nie może być większa niż 15.
          case 100300: //Szyby dla pojazdu bez szyb np. motocykli
          case 200070: // Wystawienie polisy w czasie trwania ochrony jest możliwe tylko gdy oferta dotyczy wyłącznie odnowienia umowy ubezpieczenia OC komunikacyjnego, która została w pełni opłacona i nie została wypowiedziana.
            navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
            break;
          case 1000:   //Należy wskazać atrybut 'Kierunek podróży'.
          case 1001:   //Wstrzymujemy możliwość sprzedaży polis ERGO Podróż klientom udającym się do kraju...
          case 200013:
          case 100440: //Zgody na obniżenie składki minimalnej nie stosuje się do ubezpieczeń krótkoterminowych.
          case 100159: // Nie osiągnięto poziomu łącznej składki minimalnej.
          case 100038: //Wnioskowana data rozpoczęcia ochrony jest wcześniejsza niż data podpisania polisy.
          case 100560: //Przekroczono maksymalny okres odroczenia początku okresu ochrony pakietu.
          case 100085: //ubezp. nie moze byc zawarte na okres dluzszy niz rok
          case 100313: //ubezp. nie moze byc zawarte na okres dluzszy niz rok
          case 100189: //Data rozpoczęcia ochrony nie może być późniejsza niż data zakończenia ochrony.
          case 100192:
          case 200153: //Polisa może być wznowiona maksymalnie 90 dni po zakończeniu ochrony poprzedniej polisy
          case 200242: //Proszę wskazać przynajmniej jedno ubezpieczenie do dodania lub edycji.
            navigationHelper.go(appVariables.mainState);
            break;
          case 200037: //Ubezpieczenie architektury posesji od ognia i innych zdarzeń losowych: suma ubezpieczenia nie może być większa niż 66666.6 (20% sumy ubezpieczenia budynków/lokalu).
          case 200010: //$1 Jedna osoba może być związana z jednym obiektem ubezpieczenia
          case 200039: //Ubezpieczenie wartości pieniężnych / gotówki w lokalu od ognia i innych zdarzeń losowych: suma ubezpieczenia może wynosić maksymalnie 50000 zł.
          case 100569: //Ubezpieczenie architektury ogrodu od kradzieży z włamaniem i rozboju: SU nie może być większa niż $1 zł (20% SU domu/mieszkania).
          case 100525: //Ubezpieczenie architektury ogrodu od ognia: suma ubezpieczenia musi wynosić przynajmniej 500 zł.
          case 200173: //Ubezpieczenie architektury ogrodu od ognia: suma ubezpieczenia nie może być większa niż 299999999.7 (30% sumy ubezpieczenia 
          case 200174: //Ubezpieczenie architektury ogrodu od ognia: suma ubezpieczenia nie może być większa niż 299999999.7 (30% sumy ubezpieczenia 
          case 200172: //arch ogr od ognia - SU
          case 200169: //bark mozliwosci ubezpieczenia dla kodu PKD...
          case 200020: //za duza SU w wariance nnw
          case 200144: //Car Assistance - Awaria Turbo: Ubezpieczenie dostępne wyłącznie dla pojazdów do 12 roku eksploatacji.
          case 200155: //Ubezpieczenie samochodów kempingowych, pojazdów wolnobieżnych, motocykli, motorowerów, przyczep kempingowych i przyczep o ładowności do 3
          case 100372: //%1: Zbyt niska wartość pojazdu dla wariantu I.
          case 200036: //AC: Zawarcie umowy w wariancie Kosztorysowym możliwe jest wyłącznie w odniesieniu do pojazdów, których okres eksploatacji nie przekracza 12 lat.
          case 101088: //Podano niepoprawną datę początku ochrony na polisie. Powinna być równa dacie końca ochrony na poprzedniej polisie plus 1 dzień.
          case 200148: //Car Assistance - Awaria: Podano niepoprawną datę początku ochrony na ubezpieczeniu. Data początku ochrony na ubezpieczeniu musi być większa od daty końca ochrony na ubezpieczeniu na poprzedniej polisie oraz mniejsza bądź równa dacie końca ochrony na ubezpieczeniu na poprzedniej polisie plus 90 dni.
          case 101084: //Żadne z ubezpieczeń nie jest wskazane jako ubezpieczenie kontynuowane, czyli polisa nie jest polisą wznowieniową. Może być kalkulowana, jak polisa nowa.
          case 200146: //Nie można wznowić polisy, która jeszcze się nie rozpoczęła.
          case 200075: //Przekroczono maksymalny, 90-dniowy okres odroczenia rozpoczęcia ochrony na polisie.
          case 100390: //NNWKiP: Nie wybrano ilości miejsc siedzących w pojeździe.
            var targetPage = appVariables.mainState;
            if (angular.isDefined(CONSTANTS.PRODUCT_TO_STATE[params.productCode])) {
              targetPage = CONSTANTS.PRODUCT_TO_STATE[params.productCode];
            } else if (angular.isDefined(CONSTANTS.ADD_TO_STATE[params.productCode])) {
              targetPage = CONSTANTS.ADD_TO_STATE[params.productCode];
            }
            navigationHelper.go(targetPage);
            //próba wybrania wskazanego pojazdu/lokalizacji
            //vehicleId
            if (angular.isString(params.vehicleId)) {
              dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, params.vehicleId);
              actionHelper.runAction('vehicleSelected');
            }

            if (angular.isString(params.estateId)) {
              dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, params.estateId);
              actionHelper.runAction('localizationSelected');
            }
            break;
          case 100433:
          case 200072:
          case 200071: //Umowa ubezpieczenia nie może zostać podpisana na okres dłuższy niż rok
            navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
            break;
          // case 200096:
          case 200079:
          case 200077:
          case 200076:
          case 200101:
          case 200103:
          case 200108:
          case 200107:
          case 200109:
          case 200100:
          case 200102:
          case 200115:
          // case 200130:
          case 100127://eslint-disable-line no-fallthrough
          case 200111:
          case 200105:
          case 200086:
          case 200087:
          case 200078:
          case 200080:
          case 200081:
          case 200082:
          case 200083:
          case 200084:
          case 200085:
          case 200088:
          case 200089:
          case 200090:
          case 200091:
          case 200092:
          case 200093:
          case 200094:
          case 200104:
          case 200106:
          case 200110:
          case 200112:
          case 200113:
          case 200114:
          case 200116:
          case 200117:
          case 200118:
          case 200121:
          case 200122:
          case 200123:
            self.handleSubjectMessage(error);
            break;
          case 200119:
          case 200255:
          case 200120:
            var subjectClientId = self.handleSubjectMessage(error);
            if (subjectClientId !== null) {
              groupModalHelper.openPopupEditGroup({
                id: subjectClientId
              });
            }
            break;
          case 100001:
          case 100002:
          case 100003:
          case 100314:
          case 100315:
          case 100316:
            travelDestHelper.showModal();
            break;
          default:
            handled = false;
            break;
        }
        return handled;
      };

      /**
       * oblsuga komunikatu związanego z gupą lub osobą
       * @param  {Object} error
       * @return {null|String} clientId podmiotu lub null gdy nie znaleziono
       */
      this.handleSubjectMessage = function(error) {
        var allParams = salesPath2BosErrorMapper.getRiskParams(error);
        var subjectClientId = null;
        if (angular.isObject(allParams)) { //udalo sie odczytac parametry z atrybutu object
          if (allParams.objectType === 'Persons') { //id osoby
            subjectClientId = allParams.personId;
          } else if (angular.isDefined(allParams.policyNumber)) { //poszukiwanie id grupy
            var policy = dataContainerHelper.getPolicyByNumber(allParams.policyNumber);
            if (angular.isDefined(policy.risks[0]) && angular.isDefined(policy.risks[0].product.dynamicValues._groupId)) {
              subjectClientId = dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, policy.risks[0].product.dynamicValues._groupId);
            }
          }
          if (subjectClientId !== null) {
            dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subjectClientId);
            actionHelper.runAction('personGroupSelected');
          }
        }
        navigationHelper.go(appVariables.mainState);
        return subjectClientId;
      };

    };

    return new BosMessageHelper();
  }
]);