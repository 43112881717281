angular.module('salesPath2')
  .controller('travelDestModalCtrl', ['$scope', 'dictionaryServiceHelper', 'lsnUtils', 'travelDestHelper', 'applicationHelper', 'dataContainerHelper', '$filter', 'appVariables', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants',
    function($scope, dictionaryServiceHelper, lsnUtils, travelDestHelper, applicationHelper, dataContainerHelper, $filter, appVariables, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants) {
      $scope.modalData.readOnly = appVariables.readOnly 
        || ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL;
      $scope.modalData.direction = $filter('translate')('sp2.travelDestModal.select', {componentCode: 'sp2'});
      $scope.modalData.country = $filter('translate')('sp2.travelDestModal.select', {componentCode: 'sp2'});
      $scope.modalData.selected = [];
      $scope.modalData.search = '';

      var destIdxMapper = {
          destinationsId: 0,
					travelDirectionPL: 1,
					travelDirectionEN: 2,
					destinationCountryPL: 3,
					destinationCountryEN: 4
      };

      var dest = dataContainerHelper.getDestinations();

      if (dest && dest.destinationsId 
        && dest.destinationsId.length > 0){
        var count = dest.destinationsId.length;
        for (var i = 0; i < count; i++) {
          var id = dest.destinationsId[i] + '|' + dest.travelDirectionPL[i] + '|' + dest.travelDirectionEN[i] + '|' + 
            dest.destinationCountryPL[i] + '|' + dest.destinationCountryEN[i];
          $scope.modalData.selected.push({
            id: id,
            name: dest.destinationCountryPL[i]
          });
        }
        $scope.modalData.direction = dest.travelDirectionPL[0];
        
      }

     

      /**
       * Wciągamy niezbędne słowniki
       */
      $scope.loadDictionaries = function() {
        dictionaryServiceHelper.getDictionary('directionoftravel', {
          requestType: 'get',
          callback: function(directions) {
            var baseDirections = [];
            var baseCountriesByDirection = [[]];

            baseDirections.push($filter('translate')('sp2.travelDestModal.select', {componentCode: 'sp2'}));

            angular.forEach(directions, function(element) {
              var direction = element.code.split('|')[destIdxMapper.travelDirectionPL];
              var country = element.code.split('|')[destIdxMapper.destinationCountryPL];

              if (!baseDirections.includes(direction)){
                baseDirections.push(direction);
              }

              if (baseCountriesByDirection[baseDirections.indexOf(direction)] === undefined){
                baseCountriesByDirection[baseDirections.indexOf(direction)] = [];
              }

              baseCountriesByDirection[baseDirections.indexOf(direction)].push({
                id: element.code,
                name: country
              });
            });

            $scope.modalData.baseDirections = baseDirections;
            $scope.modalData.baseCountriesByDirection = [[]];

            angular.forEach(baseDirections, function(value, index) {var sorted = $filter('orderBy')(baseCountriesByDirection[index], 'name', false, $scope.comparator);
              $scope.modalData.baseCountriesByDirection[index] = sorted;
            });
          }
        });
      };

      /**
       * porownanie 2 stringów uwzględniając polskie znaki
       * @param {*} a 
       * @param {*} b 
       * @returns 
       */
      $scope.comparator = function (a, b) {
        return a.value.localeCompare(b.value);
      };

      $scope.saveDestinationOfTravel = function(country) {
        $scope.modalData.search = '';
        if (country !== null && country.id){
          $scope.modalData.selected.push({
            id: country.id,
            name: country.id.split('|')[destIdxMapper.destinationCountryPL]
          });
        }
        $scope.setOkBtnEnabledDisabled();
      };

      $scope.filterDestinations = function(filter){
        var results = [];

        angular.forEach($scope.modalData.baseCountriesByDirection[$scope.modalData.baseDirections.indexOf($scope.modalData.direction)], function(value) {
          var found = lsnUtils.findObjInArray($scope.modalData.selected, {
            id: value.id
          });
          if (value.name.toLowerCase().startsWith(filter.toLowerCase()) 
              && found === null){
            results.push(value);
          }
        });
        return results;
      };

      /**
       * usunięcie wybranje destynacji
       * @param {*} destination 
       */
      $scope.deleteDestination = function(destination){
        if (!$scope.modalData.readOnly){
          var idxToDel = $scope.modalData.selected.indexOf(destination);
          $scope.modalData.selected.splice(idxToDel, 1);
          $scope.setOkBtnEnabledDisabled();
        }
      };

      $scope.changeDirection = function(){
        var toAdd = [];
        angular.forEach($scope.modalData.selected, function(value) {
          var exists = lsnUtils.findObjInArray($scope.modalData.baseCountriesByDirection[$scope.modalData.baseDirections.indexOf($scope.modalData.direction)], {
            name: value.name
          });
          if (exists !== null){
            toAdd.push(exists);
          }
        });
        $scope.modalData.selected = toAdd;
        $scope.setOkBtnEnabledDisabled();
      };

      $scope.setOkBtnEnabledDisabled = function(){
        travelDestHelper.modals.travelDestModal.settings.okBtnDisabled = $scope.modalData.selected.length === 0;
      };

      /**
      * init
      */
      $scope.init = function() {
        $scope.setOkBtnEnabledDisabled();
        this.loadDictionaries();
       };

      $scope.init();

      $scope.$on('iHestiaCommonModalOkBtnClicked.travelDestModal', function() {
        var destAttr = {
          'destinationsId': [],
					'travelDirectionPL': [],
					'travelDirectionEN': [],
					'destinationCountryPL': [],
					'destinationCountryEN': []
        };

        var sorted = $filter('orderBy')($scope.modalData.selected, 'name', false, $scope.comparator);
        lsnNg.forEach(sorted, function(destCountry) {
          destAttr.destinationsId.push(destCountry.id.split('|')[destIdxMapper.destinationsId]);
          destAttr.travelDirectionPL.push(destCountry.id.split('|')[destIdxMapper.travelDirectionPL]);
          destAttr.travelDirectionEN.push(destCountry.id.split('|')[destIdxMapper.travelDirectionEN]);
          destAttr.destinationCountryPL.push(destCountry.id.split('|')[destIdxMapper.destinationCountryPL]);
          destAttr.destinationCountryEN.push(destCountry.id.split('|')[destIdxMapper.destinationCountryEN]);
        });
        dataContainerHelper.setDestinations(destAttr);
        applicationHelper.saveApplication();
      });
    }
  ]);
