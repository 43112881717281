angular.module('salesPath2')
	.controller('tariffFactorsModalCtrl', ['$scope', 'tariffFactorsModalHelper',
		function($scope, tariffFactorsModalHelper) {
			$scope.factors = tariffFactorsModalHelper.modalData.tariffFactors[tariffFactorsModalHelper.modalData.vehicleId].factors;
			$scope.listeners = {};
			$scope.fireListeners = function() {
				$scope.listeners.okEvent = $scope.$on('iHestiaCommonModalOkBtnClicked.discountsModal', function() {
					tariffFactorsModalHelper.clearModalData();
					$scope.clearListeners();
				});
			};

			$scope.init = function() {
				$scope.fireListeners();
			};

			$scope.init();
			
			//czysci wszystkie listenery
			$scope.clearListeners = function() {
				angular.forEach($scope.listeners, function(val) {
					val();
				});
				$scope.listeners = [];
			};

		}
	]);