angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaModalsInjectorCtrl', ['$scope', 'ihestiaPzaCommonHelper', 'ihestiaPzaAppConstants',
    function($scope, ihestiaPzaCommonHelper, ihestiaPzaAppConstants) {

      /**
       * Kontekst aplikacji
       * @type {Object}
       */
      $scope.appContext = {
        userRole: ihestiaPzaCommonHelper.getUserRole()
      };

      $scope.ihestiaPzaAppConstants = ihestiaPzaAppConstants;

      $scope.pzaNumericSettings = ihestiaPzaCommonHelper.pzaNumericSettings;

      
      /**
       * Dowanie warunków do programu
       * @type {Object}
       */
      $scope.chooseConditionsModalSettings = {
        treatLabelAsI18nCode: true,
        
        okBtnName: ['chooseUcFirst', {componentCode:'Public'}],
        okBtnLabel: ['chooseWithCount', {componentCode:'Pza', params: {count: '{$count}'}}],
        title: ['conditionDefinition.addCondition', {componentCode:'Pza'}],
        replaceModalBody: true
      };

      /**
       * Edycja notatki
       */
      $scope.ihestiaPzaOperationsModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['operations', {componentCode:'Public'}],
        size: 'md',
        okBtnName: ['confirm', {componentCode:'Public'}],
        replaceModalBody: true
      };

      /**
       * Wybór raportu
       */
      $scope.ihestiaPzaChooseReportModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['reports', {componentCode:'Pza'}],
        size: 'sm',
        okBtnName: '',
        cancelBtnName: ['Public.close', {componentCode:'Public'}]
      };

      /**
       * Wybór raportu
       */
      $scope.ihestiaPzaPolicyReportModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['reportOnLevelPolicyAndRisk', {componentCode:'Pza'}],
        size: 'sm',
        okBtnName: ['orderReport', {componentCode:'Pza'}],
        cancelBtnName: ['cancelUcFirst', {componentCode:'Public'}]
      };

    }
  ]);