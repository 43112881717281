angular.module('salesPath2')
  .controller('discountsDetailsModalCtrl', ['$scope', 'lsnTryEscapeHtml', '$filter', 'dataContainerHelper',
    function($scope, lsnTryEscapeHtml, $filter, dataContainerHelper) {

      $scope.discounts = null;

      /**
       * Init
       */
      $scope.init = function() {
        $scope.reloadDiscountData();
      };

      /**
       * Generowanie danych do modala
       */
      $scope.reloadDiscountData = function() {
        $scope.discounts = dataContainerHelper.getDiscounts();

        //składamy warunek dla składki
        var conditionsStringTable = [];
        angular.forEach($scope.discounts.premiumFromActivePolicesDiscountDefinition, function(conditionDefinition) {
          //conditionDefinition = {Prog: 600, Stawka: 0.05, Waga: 0.4}
          conditionsStringTable.push($filter('translate')('discount.above', {componentCode: 'sp2'}) + ' ' + lsnTryEscapeHtml(conditionDefinition.Prog + ' - ' + $scope.getPercentString(100 * conditionDefinition.Stawka * conditionDefinition.Waga)));
        });
        $scope.discounts.propertyInsuranceDiscountConditions = conditionsStringTable;
      };

      $scope.getPercentString = function(value) {
        var stringValue = value+'';
        return stringValue.replace('.',',')+'%';
      };

      $scope.init();
    }
  ]);