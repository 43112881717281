angular.module('salesPath2')
/**
 * helper do wspólnych usług dla całej ścieżki
 */
.service('commonServiceHelper', ['IHestiaRestServiceProviderAtenaDef',
  function(IHestiaRestServiceProviderAtenaDef){
  var CommonServiceHelper = function() {
    var self = this;
    this.abstractProvider = new IHestiaRestServiceProviderAtenaDef();
    /**
     * zwraca unikatowy identyfikator dla requesta usługi
     * @return {String}
     */
    this.getUniqueRequestId = function() {
      return self.abstractProvider.getUniqueRequestId();
    };
  };
  return new CommonServiceHelper();
}]);