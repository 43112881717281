angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorNewMessageHelper', ['IKomunikatorMessageModel', '$rootScope', '$filter', 'ihestiaSsoBaseInfoHelper', 'iKomunikatorMessageContextSvc', 'iHestiaCommonModalHelper', 'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorContextHelper',
    'IKomunikatorRecipientModel', 'ihestiaIkomunikatorUserTypeHelper', 'iKomunikatorSubjectDictionariesSvc', 'ihestiaIkomunikatorDocumentTypes', 'iKomunikatorConstants', 'clientPersonsNbkSvc', 'ihestiaIkomunikatorSearchPersonHelper', 'ihestiaPlatformHelper', 'clientSearchSvc',
    function(IKomunikatorMessageModel, $rootScope, $filter, ihestiaSsoBaseInfoHelper, iKomunikatorMessageContextSvc, iHestiaCommonModalHelper, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorContextHelper,
             IKomunikatorRecipientModel, ihestiaIkomunikatorUserTypeHelper, iKomunikatorSubjectDictionariesSvc, ihestiaIkomunikatorDocumentTypes, iKomunikatorConstants, clientPersonsNbkSvc, ihestiaIkomunikatorSearchPersonHelper, ihestiaPlatformHelper, clientSearchSvc) {

      var NewMessageHelper = function() {
        var self = this;

        $rootScope.newMessageModalSettings = {
          title: $filter('translate')('iKomunikator.newMessage'),
          okBtnName: $filter('translate')('Public.send'),
          okBtnDisabled: ihestiaSsoBaseInfoHelper.isUserRelogged(),
          cancelBtnName: $filter('translate')('Public.cancel'),
          keyboard: false
        };

        this.defaultData = {
          contextRedisUUID: null,
          subjectList: [],
          categoryList: [],
          subjectContextItems: [],
          message: null,
          recipient: null,
          //czy nowy odbiorca
          forNewRecipient: false,
          //czy ladowac bedziemy liste dotyczy
          subjectContextItemsAvailable: true,
          channelNames: [],
          recipients: [],
          //wybrany odbiorca, bądź nowy - odbiorca nie będzie wybierany z listy
          recipientMounted: false,
          //czy modal otawrty w kontekście klienta
          //jeśli tak to nie można przejść do wyszukiwania
          clientSearchAvailable: true,
          otherRecipientInContextClient: false,
          additionalInput: {
            show: false,
            label: null,
            attrName: null
          },
          fileMessageError: false,
          document: {
            documentType: null
          },
          viewLevel: 'subjects',
          errors: {},
          active: false,
          loading: false,
          openFromSearch: false,
          showElements: {} //czy pokazac elementy wiecej szczegolow w kontekst helperze
        };


        /**
         * [relatedSubresource obiekt zależności danych używany do czyszczenia na zmianę]
         * @type {Object}
         */
        this.relatedSubresource = {
          subject: [],
          subjectContextItems: []
        };

        this.init = function() {
          self.tplData = angular.copy(self.defaultData);
          self.tplData.active = true;
          self.setRecipientMounted();
          self.setRelatedSubresource();
        };

        /**
         * [setRecipientMounted funkcja odpalana na starcie bo zalezna od typu charakteru,
         * ustawia flagę czy klient wybrany bądz nowy = brak listy wyboru odbiorców na formularzu
         */
        this.setRecipientMounted = function() {
          //tylko dla klienta wybieramy z listy
          if (ihestiaIkomunikatorUserTypeHelper.isClientUserLogged()) {
            self.tplData.recipientMounted = false;
          } else {
            self.tplData.recipientMounted = true;
          }
        };

        /**
         * [setRelatedSubresource ładuje zależności elementów formularza, w zależności od tego czyścimy pola na zmiane klucze zmieniamy
         * wszystkie atrybuty z tablicy wartości
         */
        this.setRelatedSubresource = function() {
          if (self.tplData.subjectIsFirst) {
            if (!self.tplData.recipientMounted) {
              self.relatedSubresource = {
                subjectContextItem: ['recipients', 'message', 'recipient', 'additionalInput']
              };
            } else {
              self.relatedSubresource = {
                channelName: ['subjectContextItems', 'message', 'additionalInput'],
                subjectContextItem: ['message', 'additionalInput']
              };
            }
          } else {
            if (!self.tplData.recipientMounted) {
              self.relatedSubresource = {
                subject: ['subjectContextItems', 'recipients', 'message', 'recipient', 'additionalInput', 'document'],
                subjectContextItem: ['recipients', 'message', 'recipient', 'additionalInput']
              };
            } else {
              self.relatedSubresource = {
                channelName: ['subjectContextItems', 'message', 'additionalInput', 'document'],
                subject: ['subjectContextItems', 'message', 'additionalInput', 'document'],
                subjectContextItem: ['message', 'additionalInput']
              };
            }
          }
        };

        /**
         * [reloadChannelNames Funkcja ustawia lista kanałów przez które może zostać wysłany komunikat]
         * @param  {[type]} customerStatus [status klienta - czy konto aktywne]
         */
        this.reloadChannelNames = function(customerStatus) {
          if (ihestiaIkomunikatorContextHelper.getSettings().clearForcedNotificationChannelName) {
            self.tplData.message.forcedNotificationChannelName = null;
          } else if (ihestiaIkomunikatorContextHelper.isChannelSet() && (!self.tplData.forNewRecipient || ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged())) {
            self.tplData.channelNames = ihestiaIkomunikatorContextHelper.getChannels();
          } else if (ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged()) {
            self.tplData.channelNames = [{
              type: iKomunikatorConstants.CHANNEL_NAME_SMS,
              label: 'SMS'
            }, {
              type: iKomunikatorConstants.CHANNEL_NAME_EMAIL,
              label: 'EMAIL'
            }];
          } else if (ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged()) {
            if (!self.tplData.forNewRecipient) {
              self.tplData.channelNames = [{
                type: iKomunikatorConstants.CHANNEL_NAME_EMAIL,
                label: 'EMAIL'
              }];
            } else {
              self.tplData.message.forcedNotificationChannelName = 'email';
            }
          }

          if (ihestiaIkomunikatorContextHelper.get('channelSelected') !== null) {
            self.tplData.message.forcedNotificationChannelName = ihestiaIkomunikatorContextHelper.get('channelSelected');
          } else if (angular.isString(customerStatus) && customerStatus.toLocaleLowerCase() === 'active' && !ihestiaIkomunikatorContextHelper.isChannelSet() && (ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged() || ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged())) {
            self.tplData.channelNames.unshift({
              type: iKomunikatorConstants.CHANNEL_NAME_KOMUNIKAT,
              label: 'KOMUNIKAT'
            });

            self.tplData.message.forcedNotificationChannelName = self.tplData.channelNames[0].type;
          } else if (self.tplData.channelNames.length === 1) {
            self.tplData.message.forcedNotificationChannelName = self.tplData.channelNames[0].type;
          } else if (self.tplData.channelNames.length > 1) {
            self.setSelectLabelOnSelectList('channelName');
          }
        };

        /**
         * Pokazujemy modal nowego zapytania
         * @param {object|undefined} options - dodatkowe ustawienia
         */
        this.showNewMessageModal = function(data) {
          self.tplData.active = true;
          self.tplData.message = new IKomunikatorMessageModel();

          self.setRecipientMounted();
          if (self.tplData.recipientMounted) {
            self.tplData.message.recipients[0] = new IKomunikatorRecipientModel();
          }

          self.tplData.forNewRecipient = false;
          self.tplData.subjectContextItemsAvailable = true;

          if (angular.isDefined(data.clientSearched)) {
            if (data.subjectType !== iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
              // load masterdata for person
              self.loadClientDataFromSearch(data.clientSearched);
            } else {
              self.setCompanyRecipientData(data.clientSearched);
            }
            self.tplData.openFromSearch = true;
            //osoba znaleziona poprzez wyszukiwanie osob w fts
          } else if (angular.isDefined(data.personSearched)) {

            self.setRecipientData(data.personSearched, data.personRole, data.subjectType);
            self.tplData.subjectContextItemsAvailable = false;
            self.tplData.openFromSearch = true;
            self.nextStepOnShow();
            // z ikonta
          } else if (angular.isDefined(data.client)) {
            self.setRecipientData(data.client);
            self.tplData.clientSearchAvailable = false;
            self.tplData.openFromSearch = false;
            self.nextStepOnShow(data.client.customerStatus);
            //wywolanie z dashbordu
          } else if (angular.isDefined(data.customer)) {
            self.tplData.openFromSearch = false;
            self.setRecipientData(data.customer);

            self.nextStepOnShow(data.customer.customerStatus);
            //wywolanie z dashbordu odbiorca bez ikonta
          } else if (angular.isDefined(data.otherCustomer)) {
            self.tplData.openFromSearch = false;
            self.setRecipientData(data.otherCustomer);
            self.nextStepOnShow();

          } else if (data.newRecipient) {
            self.tplData.openFromSearch = true;
            self.tplData.message.recipients[0].setForNewRecipent();
            self.tplData.forNewRecipient = true;
            self.tplData.subjectContextItemsAvailable = false;
            self.nextStepOnShow();
          } else {
            self.tplData.openFromSearch = false;
            self.nextStepOnShow();
          }

          return self;
        };

        /**
         * [loadClientDataFromSearch doczytuje dane wyszukanego klienta i setuje go jako odbiorce]
         * [ifNotFindGoToSearchPage jesli nie uda sie zalodowac klienta, przejscie na wyniki wyszukiwania]
         * @return {[type]} [description]
         */
        this.loadClientDataFromSearch = function(client) {
          $rootScope.$broadcast('showBlockUi', {
            label: 'Wczytywanie osoby'
          });

          clientPersonsNbkSvc.post(client.metaData.id,
            client, null,
            function(res) {

              $rootScope.$broadcast('hideBlockUi');
              var personData = res.data;
              if (!$.isPlainObject(personData) || typeof personData.metaData === 'undefined' || typeof personData.metaData.id === 'undefined') {
                ihestiaIkomunikatorCommonHelper.throwException('Nie udało się pobrać danych klienta o identyfikatorze: {0}.'.format(client.metaData.id));
              }

              self.setRecipientData(personData);
              self.nextStepOnShow(personData.customerStatus);

            },
            function() {
              $rootScope.$broadcast('hideBlockUi');
              var ifClientNotFindGoToSearchPage = ihestiaIkomunikatorContextHelper.getSettings().ifClientNotFindGoToSearchPage;
              if (ifClientNotFindGoToSearchPage) {
                self.hideNewMessageModal();
                ihestiaIkomunikatorSearchPersonHelper.showModal();
              } else {
                ihestiaIkomunikatorCommonHelper.throwException('Nie udało się pobrać danych klienta o identyfikatorze: {0}.'.format(client.metaData.id));
              }
            });
        };

        this.setRecipientData = function(personData, personRole, subjectType) {
          //zerujemy ustawienia
          self.tplData.contextRecipient = null;

          var contextSetting = ihestiaIkomunikatorContextHelper.getSettings();

          if (angular.isObject(contextSetting) && contextSetting.otherRecipientInContextClient) {
            self.tplData.contextRecipient = new IKomunikatorRecipientModel();
            self.tplData.contextRecipient.setRecipientData(personData, personRole);
          } else {
            self.tplData.message.recipients[0].setRecipientData(personData, personRole, subjectType);
          }
        };

        /**
         * [nextStepOnShow description]
         * @param  {[type]} customerStatus [description]
         * @return {[type]}                [description]
         */
        this.nextStepOnShow = function(customerStatus) {
          self.getFields();
          self.reloadChannelNames(customerStatus);

          var contextSetting = ihestiaIkomunikatorContextHelper.getSettings();

          self.tplData.subjectIsFirst = false;
          self.tplData.otherRecipientInContextClient = false;

          if (angular.isObject(contextSetting)) {

            if (contextSetting.clientSearchAvailable === false) {
              self.tplData.clientSearchAvailable = false;
            }

            if (contextSetting.additionalInputAvailable) {
              self.tplData.additionalInputAvailable = contextSetting.additionalInputAvailable;
            }

            if (contextSetting.clientInfoTypePesel) {
              self.tplData.clientInfoTypePesel = contextSetting.clientInfoTypePesel;
            }

            if (angular.isDefined(contextSetting.recipientMounted)) {
              self.tplData.recipientMounted = contextSetting.recipientMounted;
            }

            if (contextSetting.otherRecipientInContextClient) {
              self.tplData.otherRecipientInContextClient = true;
            }

            if (contextSetting.subjectIsFirst) {
              self.tplData.subjectIsFirst = true;
            }

            if (angular.isDefined(contextSetting.subjectContextItemsAvailable)) {
              self.tplData.subjectContextItemsAvailable = contextSetting.subjectContextItemsAvailable;
            }

            if (angular.isDefined(contextSetting.showElements)) {
              self.tplData.showElements = contextSetting.showElements;
            }
          }

          self.setRelatedSubresource();
          iHestiaCommonModalHelper.showModal('ihestiaIkomunikatorNewMessage');
        };

        /**
         * Ukrywamy modal nowego zapytania
         * @return {[type]} [description]
         */
        this.hideNewMessageModal = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorNewMessage');
          return self;
        };

        this.isLifeClient = function() {
          return ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() && ihestiaPlatformHelper.isLifeContext();
        };

        /**
         * [getDicts funkcja służy do pobierania tematów/dotyczy/odbiorców]
         * @return {[type]} [description]
         */
        this.getFields = function(selected, extraData) {
          var data = {
            contextRedisUUID: self.tplData.contextRedisUUID
          };

          //jeśli załadowaliśmy dotyczy to już nic więcej nie ładujemy, gdy ustalony odbiorca
          // chyba ze jest to inny odbiorca w kontekscie to wtedy liste odbiorow tak czy tak pobieramy
          if (self.tplData.viewLevel === 'all' && (self.tplData.recipientMounted && !self.tplData.otherRecipientInContextClient)) {
            return;
          }

          if (self.tplData.message.customFields) {

            if (self.tplData.viewLevel === 'subjects' || self.tplData.viewLevel === 'subjectContextItems' || self.tplData.viewLevel === 'all' || self.tplData.viewLevel === 'documentTypes') {
              data.selectedSubjectCode = self.tplData.message.customFields.subjectId;
            }

            if ((self.tplData.viewLevel === 'subjects' || self.tplData.viewLevel === 'subjectContextItems' || self.tplData.viewLevel === 'all' || self.tplData.viewLevel === 'documentTypes') && self.tplData.message.customFields.subjectContextItemOperationCategory) {
              data.category = self.tplData.message.customFields.subjectContextItemOperationCategory;
            }

            if (self.tplData.viewLevel === 'all') {

              if (self.tplData.message.customFields.subjectContextItemNumber === null) {
                data.selectedContextObject = {
                  objectType: self.tplData.message.customFields.subjectContextItemType,
                  objectNumber: self.tplData.message.customFields.subjectContextItemNumberFill || '',
                  userNumberValue: true
                };
              } else {
                data.selectedContextObject = {
                  objectType: self.tplData.message.customFields.subjectContextItemType,
                  objectNumber: self.tplData.message.customFields.subjectContextItemNumber,
                  userNumberValue: false
                };
              }
              if (angular.isDefined(self.tplData.message.customFields.subjectContextItemId)) {
                data.selectedContextObject.objectId = self.tplData.message.customFields.subjectContextItemId;
              }

            }

            if (self.tplData.recipientMounted && !self.tplData.forNewRecipient) {
              if (self.tplData.contextRecipient) {
                data.customerAccountNumber = self.tplData.contextRecipient.taggedValues.ikontoId;
              } else {
                data.customerAccountNumber = self.tplData.message.recipients[0].taggedValues.ikontoId;
              }

            }
          }

          if (extraData) {
            angular.forEach(extraData, function(value, name) {
              data[name] = value;
            });
          }

          self.tplData.loading = true;
          iKomunikatorMessageContextSvc.getFields(data, function(result) {
              self.tplData.loading = false;
              if (!self.tplData.active) {
                return;
              }

              //jeśli co dopiero wybrano temat, należy ustawić typ dokumentu
              if (selected === 'subject' && !self.shouldWaitForCategoryList()) {
                if (result.data.documentTypeList[0]) {
                  self.setDocumentType(result.data.documentTypeList[0].code);
                } else {
                  ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła dostępnych typów dokumentów');
                }
              }

              if (angular.isObject(result.data)) {
                if (self.tplData.viewLevel === 'subjects') {
                  self.setSubjects(result.data);
                  self.setCategories(result.data);
                } else if (self.tplData.viewLevel === 'subjectContextItems') {
                  self.setSubjectContextItems(result.data);
                } else {
                  self.setRecipients(result.data);
                }

                self.tplData.contextRedisUUID = result.data.contextRedisUUID;
              } else {
                ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie tematów/dotyczy' + (self.tplData.recipientMounted ? '' : '/odbiorcy'));
              }
            },
            function() {
              $rootScope.$broadcast('hideBlockUi');
              ihestiaIkomunikatorCommonHelper.throwException('Wystąpiły błędy przy wywołaniu usługi do pobieranie tematów/dotyczy' + (self.tplData.recipientMounted ? '' : '/odbiorcy'));
            });

        };

        /**
         * [setSubjectContextItems ustawia listę tematów]
         * @param {[type]} subjectId [description]
         */
        this.setSubjects = function(data) {
          if (angular.isObject(data) && angular.isArray(data.subjectList) && data.subjectList.length > 0) {
            self.tplData.subjectList = data.subjectList;
            if (self.tplData.subjectList.length > 1) {
              self.setSelectLabelOnSelectList('subject');
            } else {
              self.tplData.message.customFields.subjectId = self.tplData.subjectList[0].code;
              self.onSelectSubject(true, data);
              if (data.documentTypeList[0]) {
                self.setDocumentType(data.documentTypeList[0].code);
              }

              //jeśli tylko jeden temat to usług powinna zwrócić również listę dotyczy, chyba, ze ich nie posiada
              if (self.subjectContainsItems(self.tplData.message.customFields.subjectId) && !self.shouldWaitForCategoryList()) {
                self.setSubjectContextItems(data);
              }
            }
          } else {
            ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła dostępnych tematów');
          }
        };

        /**
         * [setCategories ustawia listę podtematów]
         */
        this.setCategories = function(data) {
          if (angular.isObject(data) && angular.isArray(data.categoryList) && data.categoryList.length > 0) {
            self.tplData.categoryList = data.categoryList;
            if (self.tplData.categoryList.length > 1) {
              self.setSelectLabelOnSelectList('category');
            } else {

              self.tplData.message.customFields.categoryDisplayValue = self.tplData.categoryList[0].label;
              self.tplData.message.customFields.subjectContextItemOperationCategory = self.tplData.categoryList[0].code;
              self.tplData.message.customFields.categoryObject = self.tplData.categoryList[0];
            }
          }
        };

        this.shouldWaitForCategoryList = function() {
          if (self.isLifeClient() &&
            self.tplData.message.customFields && self.tplData.message.customFields.subjectId === iKomunikatorConstants.SUBJECT_ID_POLICY &&
            !self.tplData.message.customFields.subjectContextItemOperationCategory) {
            return true;
          } else {
            return false;
          }
        };

        /**
         * [onSelectSubject wywoływane na wybór tematu]
         * @param  {[type]} byRest [czy ustawione przez użytkownika, czy przyszedł jeden element z usługi]
         * @return {[type]}        [description]
         */
        this.onSelectSubject = function(byRest, isCategory) {
          var subjectId = self.tplData.message.customFields.subjectId;

          self.clearRelatedSubresource('subject', isCategory);
          self.setSelectedSubjectOnMessage();

          if (self.shouldWaitForCategoryList()) { // tu powinnismy zostać na viewLevel subjects
            if (!byRest) {
              self.getFields('subject');
            }
          } else if (self.tplData.subjectContextItemsAvailable && self.subjectContainsItems(subjectId)) {
            self.tplData.viewLevel = 'subjectContextItems';
            if (!byRest) {
              self.getFields('subject');
            }
          } else if (!self.tplData.subjectContextItemsAvailable) {
            self.tplData.viewLevel = 'documentTypes';
            self.getFields('subject');
          } else {
            self.tplData.viewLevel = 'all';
            if (!self.tplData.recipientMounted && !byRest) {
              self.getFields('subject');
            }
          }
          self.clearValidatonErrors('subject');
        };

        /**
         * [setSubjectContextItems ustawia listę dotyczy]
         * @param {[type]} subjectId [description]
         */
        this.setSubjectContextItems = function(data) {
          if (self.tplData.subjectContextItemsAvailable || self.tplData.additionalInputAvailable) {
            if (angular.isObject(data) && angular.isArray(data.contextObjectList) && data.contextObjectList.length > 0) {
              self.tplData.subjectContextItems = [];
              var id = 0;
              angular.forEach(data.contextObjectList, function(subjectContextItem) {
                subjectContextItem.id = id++;
                self.tplData.subjectContextItems.push(subjectContextItem);
              });

              var context = ihestiaIkomunikatorContextHelper.getContext();

              //skoro nie dostepna lista to nic nie mozna wybrac dopuszczamy tylko jak jest wiecej niz jeden
              if (self.tplData.subjectContextItemsAvailable && self.tplData.subjectContextItems.length > 1) {
                var settings = ihestiaIkomunikatorContextHelper.getSettings();
                if (settings.selectDefaultSubjectContextItem) {
                  self.selectSubjectContextItemFromContext();
                  self.onSelectSubjectContextItem(true);
                  //wybralismy domyslnie, wiec wybralismy ustawiamy wiec odbiorce
                  self.setRecipients(data);
                } else {
                  self.setSelectLabelOnSelectList('subjectContextItem');
                }
              } else {
                self.tplData.message.customFields.id = self.tplData.subjectContextItems[0].id;
                self.onSelectSubjectContextItem(true);
                //mozemy otrzymac odbiorcow na podstawie kontekst - przyklad likwidator okno kontekstowe wywolane dla klienta przez agenta
                if (!self.tplData.recipientMounted || self.tplData.otherRecipientInContextClient) {
                  if ((context.formName === iKomunikatorConstants.FORM_NAME_NEW_MESSAGE || context.formName === iKomunikatorConstants.FORM_NAME_OFFERS_LIST) &&
                    self.tplData.message.customFields.subjectId === iKomunikatorConstants.SUBJECT_ID_INSURANCE && self.tplData.message.customFields.subjectContextItemNumber === null) {
                    self.getFields('subjectContextItem', {
                      category: iKomunikatorConstants.CATEGORY_NEW_OFFER
                    });
                  } else {
                    //jeśli tylko jedno dotyczy, to usługa zwróciła również odbiorców
                    self.setRecipients(data);
                  }
                }
              }

            } else if (self.tplData.subjectContextItemsAvailable) {
              ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła dostępnych dotyczy');
            }
          }

        };

        /**
         * [selectSubjectContextItemFromContext ustawia dotyczy z listy na podstawie kontekstu np numeru polisy, jesli nie znajdzie usawia inny/nowy]
         */
        this.selectSubjectContextItemFromContext = function() {
          var subjectContextItemFromContext = ihestiaIkomunikatorContextHelper.getSubjectContextItem(),
            subjectExist = false;
          angular.forEach(self.tplData.subjectContextItems, function(subjectContextItem) {
            if (subjectContextItem.objectNumber === subjectContextItemFromContext.objectNumber) {
              self.tplData.message.customFields.id = subjectContextItem.id;
              subjectExist = true;
            }
          });

          if (!subjectExist) {
            angular.forEach(self.tplData.subjectContextItems, function(subjectContextItem) {
              if (subjectContextItem.objectNumber === null) {
                self.tplData.message.customFields.id = subjectContextItem.id;
              }
            });
          }
        };

        /**
         * [onSelectSubjectContextItem wywoływane na wybór dotyczy]
         * @param  {[type]} byRest [czy ustawione przez użytkownika, czy przyszedł jeden element z usługi]
         */
        this.onSelectSubjectContextItem = function(byRest) {
          self.tplData.viewLevel = 'all';

          self.clearRelatedSubresource('subjectContextItem');
          self.setSelectedSubjectContentItemOnMessage();

          //jesli inny odbiorca to tez musimy pobrac liste odbiorcow
          if ((!self.tplData.recipientMounted || self.tplData.otherRecipientInContextClient) && !byRest) {
            self.getFields();
          }

          self.setAttributOnDocumentType();
          self.setAdditionalInput();
          self.clearValidatonErrors('subjectContextItem');
        };

        /**
         * Funkcja sprawdza czy temat posiada elementy 'dotyczy'
         * @return {boolean}
         */
        this.subjectContainsItems = function(subjectId) {
          return subjectId !== null && subjectId !== iKomunikatorConstants.SUBJECT_ID_RECLAMATION && subjectId !== iKomunikatorConstants.SUBJECT_ID_CONTACT;
        };

        /**
         * [setAdditionalInput ustawia w odpowiednim przypadku elementy dodatkowe do dotyczy typu numer polisy]
         * @param {[type]} subjectId [z dotyczy]
         */
        this.setAdditionalInput = function() {
          var type = self.getSelectedObjectSubjectContextItem().objectType,
            number = self.getSelectedObjectSubjectContextItem().objectNumber;

          if (number === null && type === iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_INJURY) {
            this.tplData.additionalInput = {
              show: true,
              label: 'Numer szkody',
              attrName: 'subjectContextItemNumberFill'
            };
          } else if (number === null && type === iKomunikatorConstants.SUBJECT_CONTEXT_ITEM_TYPE_POLICY) {
            this.tplData.additionalInput = {
              show: true,
              label: 'Numer polisy',
              attrName: 'subjectContextItemNumberFill'
            };
          } else {
            this.tplData.additionalInput = {
              show: false,
              label: null,
              attrName: null
            };
          }

          var settings = ihestiaIkomunikatorContextHelper.getSettings();
          if (number === null && settings.selectDefaultSubjectContextItem && !self.tplData.message.customFields[self.tplData.additionalInput.attrName]) {
            self.tplData.message.customFields[self.tplData.additionalInput.attrName] = ihestiaIkomunikatorContextHelper.getSubjectContextNumber();
          }
        };

        /**
         * [setRecipients ustawia odbiorców]
         */
        this.setRecipients = function(data) {
          //mozemy otrzymac odbiorcow na podstawie kontekst - przyklad likwidator okno kontekstowe wywolane dla klienta przez agenta
          if (self.tplData.recipientMounted && !self.tplData.otherRecipientInContextClient) {
            return;
          }

          self.tplData.recipients = [];
          if (angular.isObject(data) && angular.isArray(data.recipientsList) && data.recipientsList.length > 0) {
            angular.forEach(data.recipientsList, function(recipientData) {
              var recipient = new IKomunikatorRecipientModel();
              recipient.setFromList(recipientData);
              self.tplData.recipients.push(recipient);
            });
            if (self.tplData.recipients.length > 1) {
              self.setSelectLabelOnSelectList('recipient');
            } else {
              self.tplData.message.recipients[0] = self.tplData.recipients[0];
            }
          } else {
            ihestiaIkomunikatorCommonHelper.throwException('Usługa nie zwróciła żadnego odbiorcy');
          }
        };

        /**
         * [onSelectRecipient wywoływane na wybór odbiorcy]
         * @return {[type]} [description]
         */
        this.onSelectRecipient = function() {
          self.removeSelectLabelOnSelectList('recipient');
          self.clearValidatonErrors('recipient');
        };

        this.setClearData = function() {
          var recipientMounted = self.tplData.recipientMounted;

          angular.forEach(self.tplData, function(value, key) {
            delete self.tplData[key];
          });

          self.tplData = angular.copy(self.defaultData);
          self.tplData.recipientMounted = recipientMounted;
        };

        /**
         * [clearRelatedSubresource czyści listy powiązane zależące od właśnie wybranego elementu]
         * @param  {[type]} level [poziom wyboru]
         */
        this.clearRelatedSubresource = function(level, dontClearCategory) {

          if (self.checkShouldNotClearSubresource(level)) {
            return;
          }

          if (angular.isArray(self.relatedSubresource[level])) {
            angular.forEach(self.relatedSubresource[level], function(attrName) {
              if (angular.isDefined(self.tplData[attrName]) && angular.isDefined(self.defaultData[attrName])) {
                //nie czyścimy dotyczy, gdy jest tylko jedna pozycja tematow, nie zmienily sie wiec one
                //analogicznie odbiorcow gdy jest tylko jedna pozycja dotyczy
                if ((attrName === 'subjectContextItems' && level === 'channelName' && self.tplData.subjectList.length === 1) ||
                  (attrName === 'recipients' && level === 'channelName' && self.tplData.subjectContextItems.length === 1)) {
                  return;
                } else if (!angular.isArray(self.defaultData[attrName]) && angular.isObject(self.defaultData[attrName])) {
                  angular.forEach(self.defaultData[attrName], function(defaultValue, key) {
                    self.tplData[attrName][key] = angular.copy(defaultValue);
                  });
                } else if (attrName !== 'message') {
                  self.tplData[attrName] = angular.copy(self.defaultData[attrName]);
                } else {
                  var message = new IKomunikatorMessageModel();

                  if (self.tplData.recipientMounted) {
                    message.recipients = [self.tplData.message.recipients[0]];
                  }

                  message.forcedNotificationChannelName = self.tplData.message.forcedNotificationChannelName;
                  if (level === 'subjectContextItem' || (level === 'channelName' && self.tplData.subjectList.length === 1 && self.tplData.subjectContextItems.length === 1)) {
                    message.customFields = angular.copy(self.tplData.message.customFields);
                  } else if (level === 'subject' || (level === 'channelName' && self.tplData.subjectList.length === 1)) {
                    var toClear = ['subjectContextItemType', 'subjectContextItemNumber', 'subjectContextItemLabel'];
                    if (!dontClearCategory) {
                      toClear.push('subjectContextItemOperationCategory', 'categoryObject', 'categoryDisplayValue');
                    }
                    angular.forEach(self.tplData.message.customFields, function(value, key) {
                      if (toClear.indexOf(key) === -1) {
                        message.customFields[key] = value;
                      }
                    });
                  }
                  self.tplData.message = message;
                }
              }
            });
          }

          if (level === 'channelName') {
            self.removeSelectLabelOnSelectList('channelName');
            self.setSelectLabelOnSelectList('subject');
            self.setSelectLabelOnSelectList('subjectContextItem');
          } else if (level === 'subject') {
            self.removeSelectLabelOnSelectList('subject');
            self.setSelectLabelOnSelectList('subjectContextItem');
          } else if (level === 'subjectContextItem') {
            self.removeSelectLabelOnSelectList('subjectContextItem');
          }

          if (!self.tplData.recipientMounted) {
            self.setSelectLabelOnSelectList('recipient');
          }
        };

        //jeśli zmianił się kanał komunikacji
        //i jest tylko jeden temat i dotyczy
        //to nie mozemy wyczyscic zadnych zaleznosci
        this.checkShouldNotClearSubresource = function(level) {
          return (level === 'channelName' && self.tplData.subjectList.length === 1 && self.tplData.subjectContextItems.length === 1 && self.tplData.recipientMounted) ||
            (level === 'channelName' && self.tplData.subjectList.length === 1 && self.tplData.subjectContextItems.length === 1 && !self.tplData.recipientMounted && self.tplData.recipients.length === 1);
        };

        /**
         * [setSelectElementOnSelectList dodajemy pozycję wybierz na wybranym poziomie]
         * @param {[type]} level [poziom zmiany]
         */
        this.setSelectLabelOnSelectList = function(level) {
          if (level === 'channelName' && self.tplData.channelNames.length > 1) {
            if (self.tplData.channelNames[0].type !== -1) {
              self.tplData.channelNames.unshift({
                label: '-wybierz-',
                type: -1
              });
            }
            self.tplData.message.forcedNotificationChannelName = self.tplData.channelNames[0].type;

          } else if (level === 'subject' && self.tplData.subjectList.length > 1) {
            if (self.tplData.subjectList[0].code !== -1) {
              self.tplData.subjectList.unshift({
                label: '-wybierz-',
                code: -1
              });
            }
            self.tplData.message.customFields.subjectId = self.tplData.subjectList[0].code;

          } else if (level === 'category' && self.tplData.categoryList.length > 1) {
            if (self.tplData.categoryList[0].code !== -1) {
              self.tplData.categoryList.unshift({
                label: '-wybierz-',
                code: -1
              });
            }
            self.tplData.message.customFields.category = self.tplData.categoryList[0].code;
            self.tplData.message.customFields.categoryObject = self.tplData.categoryList[0];

          } else if (level === 'subjectContextItem' && self.tplData.subjectContextItems.length > 1) {
            if (self.tplData.subjectContextItems[0].id !== -1) {
              self.tplData.subjectContextItems.unshift({
                label: '-wybierz-',
                objectNumber: -1,
                id: -1
              });
            }
            self.tplData.message.customFields.id = self.tplData.subjectContextItems[0].id;

            // odbiorcy domyslnie wybrani na pierwszego
          } else if (level === 'recipient' && self.tplData.recipients.length > 1) {
            self.tplData.message.recipients[0] = self.tplData.recipients[0];
          }
        };

        /**
         * [removeSelectLabelOnSelectList usuwa pozycję wybierz z wybranego poziomu]
         * @return {[type]} [description]
         */
        this.removeSelectLabelOnSelectList = function(level) {
          if (level === 'channelName') {
            if (self.tplData.channelNames[0].type === -1) {
              self.tplData.channelNames.splice(0, 1);
            }
          } else if (level === 'subject') {
            if (self.tplData.subjectList[0].code === -1) {
              self.tplData.subjectList.splice(0, 1);
            }
            if (self.tplData.categoryList[0] && self.tplData.categoryList[0].code === -1) {
              self.tplData.categoryList.splice(0, 1);
            }
          } else if (level === 'subjectContextItem' && self.tplData.subjectContextItems[0].id === -1) {
            self.tplData.subjectContextItems.splice(0, 1);
          }
        };

        /**
         * [setDocumentType ustawia typ dokument na podstawie wybranych pozycji z tematu i dotyczy]
         * @param {[type]} subjectId                 [description]
         * @param {[type]} subjectContextItemNumber  [description]
         */
        this.setDocumentType = function(documentTypeCode) {
          if (self.tplData.message.forcedNotificationChannelName === iKomunikatorConstants.CHANNEL_NAME_SMS) {
            self.tplData.document.documentType = null;
            self.tplData.message.customFields.archiveDocumentClass = null;
          } else {
            self.tplData.message.customFields.archiveDocumentClass = documentTypeCode;
            ihestiaIkomunikatorDocumentTypes.setDocumentTypeByCode(self.tplData.document, documentTypeCode);
          }

          if (self.tplData.viewLevel === 'documentTypes') {
            self.tplData.viewLevel = 'all';
          }
        };

        /**
         * [setAttributOnDocumentType ustawiamy atybuty z wybranego dotyczy]
         */
        this.setAttributOnDocumentType = function() {
          if (self.tplData.message.forcedNotificationChannelName === iKomunikatorConstants.CHANNEL_NAME_SMS) {
            self.tplData.document.documentType = null;
          } else {
            var subjectContextItemNumber = self.tplData.message.customFields.subjectContextItemNumber,
              subjectId = self.tplData.message.customFields.subjectId;

            if (subjectContextItemNumber !== null) {
              ihestiaIkomunikatorDocumentTypes.setAttrForDocumentType(self.tplData.document, subjectId, subjectContextItemNumber);
            }
          }
        };

        /**
         * [setSelectedSubjectContentItemOnMessage ustawia informacje o docztyczy na wiadomosci po wyborze z listy]
         */
        this.setSelectedSubjectContentItemOnMessage = function() {
          var selectedObjectSubjectContextItem = self.getSelectedObjectSubjectContextItem();

          self.tplData.message.customFields.subjectContextItemLabel = selectedObjectSubjectContextItem.label;
          self.tplData.message.customFields.subjectContextItemNumber = selectedObjectSubjectContextItem.objectNumber;
          self.tplData.message.customFields.subjectContextItemType = selectedObjectSubjectContextItem.objectType;

          if (angular.isDefined(selectedObjectSubjectContextItem.objectId)) {
            self.tplData.message.customFields.subjectContextItemId = selectedObjectSubjectContextItem.objectId;
          }
        };

        /**
         * [getSelectedObjectSubjectContextItem zwraca wybrany element dotyczy]
         * @return {[type]} [description]
         */
        this.getSelectedObjectSubjectContextItem = function() {
          var selectedObjectSubjectContextItem = null;

          angular.forEach(self.tplData.subjectContextItems, function(subjectContextItem) {
            if (self.tplData.message.customFields.id === subjectContextItem.id) {
              selectedObjectSubjectContextItem = subjectContextItem;
            }
          });

          return selectedObjectSubjectContextItem;
        };

        /**
         * [setSelectedSubjectOnMessage ustawia informacje o temacie na wiadomosci po wyborze z listy]
         */
        this.setSelectedSubjectOnMessage = function() {
          angular.forEach(self.tplData.subjectList, function(subject) {
            if (self.tplData.message.customFields.subjectId === subject.code) {
              self.tplData.message.customFields.subjectLabel = subject.label;
            }
          });
        };

        /**
         * [setValidatonErrors ustawia błędy otrzymane przy zapisie wiadomosci]
         * @param {[type]} errors [odpowiedz z uslugi zapisu]
         */
        this.setValidatonErrors = function(errors) {
          var errorList = [];
          if (self.tplData.viewLevel === 'subjects' || self.tplData.viewLevel === 'documentTypes') {
            errorList = ['customFields.subjectId', 'recipient.telephonNumber', 'recipient.email'];
          } else if (self.tplData.viewLevel === 'subjectContextItems') {
            errorList = ['customFields.subjectId', 'customFields.subjectContextItemType', 'customFields.subjectContextItemNumber', 'recipient.telephonNumber', 'recipient.email'];
          } else if (self.tplData.viewLevel === 'all') {
            errorList = ['content', 'customFields.subjectId', 'receipients', 'customFields.subjectContextItemType', 'customFields.subjectContextItemNumber', 'recipient.telephonNumber', 'recipient.email', 'customFields.customerFirstName', 'customFields.customerLastName', 'customFields.customerCitizenship', 'customFields.customerDocumentNumber', 'customFields.customerDocumentType'];
          }

          self.tplData.errors = [];
          angular.forEach(errors, function(error) {
            if (errorList.indexOf(error.validationObjectId) !== -1) {
              if (error.validationObjectId.indexOf('.') !== -1) {
                var errorName = error.validationObjectId.split('.')[1];
                self.tplData.errors[errorName] = {
                  type: error.messageType,
                  text: error.messageText
                };
              } else {
                self.tplData.errors[error.validationObjectId] = {
                  type: error.messageType,
                  text: error.messageText
                };
              }
            }
          });
        };

        /**
         * [clearValidatonErrors czyści informacje o bledach przy zapisie]
         */
        this.clearValidatonErrors = function(selected) {
          if (angular.isDefined(selected)) {
            if (selected === 'subject') {
              self.tplData.errors = {};
            } else if (selected === 'subjectContextItem') {
              if (angular.isDefined(self.tplData.errors.subjectId)) {
                var tmpSubjectError = self.tplData.errors.subjectId;
                self.tplData.errors = {};
                self.tplData.errors.subjectId = tmpSubjectError;
              } else {
                self.tplData.errors = {};
              }

            } else if (selected === 'recipient') {
              delete self.tplData.errors.receipients;
            }
          } else {
            self.tplData.errors = {};
          }

        };

        this.setCompanyRecipientData = function(subjectData) {
          if (_.get(subjectData, 'countryCode') === 'PL' && _.size(_.get(subjectData, 'regon')) === 14) {
            $rootScope.$broadcast('showBlockUi', {
              label: 'Wczytywanie osoby'
            });
            var body = {
              regon: subjectData.regon
            };
            var httpParams = {
              headers: {
                'AP-Request-Behaviour': 'touchpoint=I-KOMUNIKATOR-READONLY;'
              }
            };
            clientSearchSvc.searchOrganization(body, httpParams)
              .then(function(res) {
                if (res && res.data) {
                  self.setRecipientData(_.first(res.data), undefined, iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY);
                  self.nextStepOnShow();
                }
              })
              .finally(function() {
                $rootScope.$broadcast('hideBlockUi');
              });
          } else {
            self.setRecipientData(subjectData, undefined, iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY);
            self.nextStepOnShow();
          }
        };

        self.init();

      };

      return new NewMessageHelper();
    }
  ]);
