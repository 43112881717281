angular.module('salesPath.outer')
  .directive('ihestiaSalesPathAddresses', ['ihestiaRestDictionaryAddressesSvc', 'addressHelper', 'ihestiaAvailabilityHelper', '$parse', '$q',
    function(ihestiaRestDictionaryAddressesSvc, addressHelper, ihestiaAvailabilityHelper, $parse, $q) {
      return {
        restrict: 'E',
        replace: true,
        link: function(scope, elem, attrs) {
          var address = $parse(attrs.address)(scope);
          if (attrs.drvTemplate) {
            scope._tpl = attrs.drvTemplate;
          } else if (!ihestiaAvailabilityHelper.isAvailable('addressSearch') || (address && address.countryCode && address.countryCode !== 'PL')) {
            scope._tpl = 'ihestiaSalesPathTemplate/autocompliters/address/addressesWithoutService.tpl.html';
          } else {
            scope._tpl = 'ihestiaSalesPathTemplate/autocompliters/address/addresses.tpl.html';
          }
        },
        template: '<div class="form-group" ng-form="addressForm" ng-include=_tpl></div>',
        scope: true,
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $scope.promiseToCancel = $q.defer();

          $scope.attributeList = ['postalCode', 'city', 'streetPrefix', 'street', 'house', 'apartment'];
          $scope.attributeMap = {};

          if ($attrs.attributeMap) {
            var attributeMap = $parse($attrs.attributeMap)($scope);

            angular.forEach(attributeMap, function(value, key) {
              $scope.attributeMap[key] = value;
            });
          }

          //uzupełniamy brakujące atrybuty w mapie
          angular.forEach($scope.attributeList, function(code) {
            if (angular.isUndefined($scope.attributeMap[code])) {
              $scope.attributeMap[code] = code;
            }
          });

          $scope.masks = {
            postalCode: '?99-999'
          };

          $scope.bindedInputs = {};
          $scope.bindedInputs[$scope.attributeMap.city] = null;
          $scope.bindedInputs[$scope.attributeMap.postalCode] = null;
          $scope.bindedInputs[$scope.attributeMap.streetPrefix] = null;
          $scope.bindedInputs[$scope.attributeMap.street] = null;

          $scope.addressType = 'flat';
          $scope.isDisabled = false;

          $scope.addressForm = {};

          $scope.$watch($attrs.ngDisabled, function(val) {
            $scope.isDisabled = val;
          });

          if (angular.isDefined($attrs.required)) {
            $scope.required = true;
          } else {
            $scope.required = false;
          }
          if (angular.isDefined($attrs.ngRequired)) {
            $scope.requiredFlag = $attrs.ngRequired;
          } else {
            $scope.requiredFlag = 'required';
          }

          $scope.$watch($attrs.address, function(val) {
            $scope.address = val;
          });
          $scope.$watch($attrs.dirtyStreet, function(val) {
            $scope.dirtyStreet = val;
          });

          if ($attrs.invalidTexts) {
            $scope.invalidTexts = $parse($attrs.invalidTexts)($scope);
            $scope.invalidTextPostalCode = $scope.invalidTexts[$scope.attributeMap.postalCode];
            $scope.invalidTextCity = $scope.invalidTexts[$scope.attributeMap.city];
            $scope.invalidTextStreetPrefix = $scope.invalidTexts[$scope.attributeMap.streetPrefix];
            $scope.invalidTextStreet = $scope.invalidTexts[$scope.attributeMap.street];
            $scope.invalidTextHouse = $scope.invalidTexts[$scope.attributeMap.house];
          }

          $scope.$watch($attrs.errors, function(val) {
            //todo zrefaktoryzować poniższe do forEacha
            if (!val || !val[$scope.attributeMap.postalCode]) {
              $scope.hasErrorPostalCode = false;
            } else {
              $scope.hasErrorPostalCode = !!val[$scope.attributeMap.postalCode];
              $scope.invalidTextPostalCode = val[$scope.attributeMap.postalCode].message;
            }

            if (!val || !val[$scope.attributeMap.city]) {
              $scope.hasErrorCity = false;
            } else {
              $scope.hasErrorCity = !!val[$scope.attributeMap.city];
              $scope.invalidTextCity = val[$scope.attributeMap.city].message;
            }

            if (!val || !val[$scope.attributeMap.streetPrefix]) {
              $scope.hasErrorStreetPrefix = false;
            } else {
              $scope.hasErrorStreetPrefix = !!val[$scope.attributeMap.streetPrefix];
              $scope.invalidTextStreetPrefix = val[$scope.attributeMap.streetPrefix].message;
            }

            if (!val || !val[$scope.attributeMap.street]) {
              $scope.hasErrorStreet = false;
            } else {
              $scope.hasErrorStreet = !!val[$scope.attributeMap.street];
              $scope.invalidTextStreet = val[$scope.attributeMap.street].message;
            }

            if (!val || !val[$scope.attributeMap.house]) {
              $scope.hasErrorHouse = false;
            } else {
              $scope.hasErrorHouse = !!val[$scope.attributeMap.house];
              $scope.invalidTextHouse = val[$scope.attributeMap.house].message;
            }

            if (!val || !val[$scope.attributeMap.apartment]) {
              $scope.hasErrorApartment = false;
            } else {
              $scope.hasErrorApartment = !!val[$scope.attributeMap.apartment];
              $scope.invalidTextApartment = val[$scope.attributeMap.apartment].message;
            }

          });

          $scope.init = function() {
            var excludedInputs = $parse($attrs.excludedInputs)($scope);
            angular.forEach(excludedInputs, function(name) {
              delete $scope.bindedInputs[name];
            });
          };

          // typ nieruchomości
          $attrs.$observe('addressType', function(addressType) {
            $scope.addressType = addressType === 'house' ? 'house' : 'flat';
            if ($scope.addressType === 'house') {
              $scope.address[$scope.attributeMap.apartment] = null;
            }
          });

          /**
           * jak potrzebujemy kod z mapy
           * @param  {[type]} attributeName [description]
           * @return {[type]}               [description]
           */
          $scope.getAttributeCodeFromName = function(attributeName) {
            var baseName;
            angular.forEach($scope.attributeMap, function(value, code) {
              if (attributeName === value) {
                baseName = code;
              }
            });
            return baseName;
          };

          /**
           * przygotowuje zapytanie dla usługi wyszukiwania adresu autocomletera
           *
           * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
           * @param {String} val
           */
          $scope.getQueryAutocomplete = function(focusInputName, val) {
            var query = {};

            //poprawka dla maski na kodzie pocztowym
            if (focusInputName === $scope.attributeMap.postalCode && val.indexOf('_') !== -1) {
              val = val.substring(0, val.indexOf('_'));
            }
            query[$scope.getAttributeCodeFromName(focusInputName)] = val;
            if (focusInputName !== $scope.attributeMap.postalCode) //dla zmian na kodzie pocztowym ignorowane są pozostałe pola
            {
              angular.forEach($scope.bindedInputs, function(cVal, name) {
                if (!$scope.address[name]) //pomijamy niepodpięte pola adresu, ten z którego przyszła zmiana i puste
                {
                  return true;
                }
                if (focusInputName === $scope.attributeMap.street && name === $scope.attributeMap.streetPrefix) //przy edycji ulicy ignorujemy prefix ulicy
                {
                  return true;
                }
                query[$scope.getAttributeCodeFromName(name)] = $scope.address[name];
              });
            }

            return query;
          };

          /**
           * wyszukiwanie adresu dla autocompletera
           *
           * @param {String} val
           * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
           */
          $scope.searchAddressAutocomplete = function(val, focusInputName) {
            var query = $scope.getQueryAutocomplete(focusInputName, val);
            $scope['loading' + focusInputName.ucFirst()] = true;

            if ($scope.loadingElements) {
              $scope.promiseToCancel.reject('newRequest');
              $scope.promiseToCancel = $q.defer();
            }

            $scope.loadingElements = true;
            return ihestiaRestDictionaryAddressesSvc.findByParams({
              query: query,
              pageSize: 10,
              top: 10,
              sortBy: 'id',
              sortDirection: 'DESC',
              httpParams: {
                timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
              }
            }).then(function(res) {
              $scope['loading' + focusInputName.ucFirst()] = false;
              $scope.loadingElements = false;
              var responseDataNode = addressHelper.REST_ADDRESSES_RESPONSE_RELATIONS[$scope.getAttributeCodeFromName(focusInputName)];
              if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.item)) { //jeśli pusta odpowiedź to wrzucamy pustą tablicę zgodną ze strukturą odpowiedzi
                res = {
                  data: {
                    item: {}
                  }
                };
                res.data.item[responseDataNode] = {
                  items: []
                };
              }

              return res.data.item[responseDataNode].items;
            }, angular.noop);
          };

          /**
           * przygotowuje zapytanie dla usługi wyszukiwania adresu
           *
           * @param {String} val
           * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
           */
          $scope.getQuery = function(focusInputName) {
            var query = {};
            angular.forEach($scope.bindedInputs, function(val, name) {
              if (!$scope.address[name]) //pomijamy niepodpięte pola adresu, ten z którego przyszła zmiana i puste
              {
                return true;
              }
              if (focusInputName === $scope.attributeMap.street && name === $scope.attributeMap.streetPrefix) //przy edycji ulicy ignorujemy prefix ulicy
              {
                return true;
              }
              if (focusInputName !== $scope.attributeMap.postalCode || name === $scope.attributeMap.postalCode) //jeśli zmiana na postalCode to tylko po postalCode szukamy
              {
                query[$scope.getAttributeCodeFromName(name)] = $scope.address[name];
              }
            });

            return query;
          };

          /**
           * wyszukuje adres dla podanych inputów
           * @param  {*} response     callback
           * @param  {[*} bindedInputs lista inputów
           */
          $scope.onSelectAddress = function(val, focusInputName) {
            if (!$scope.address[focusInputName]) {
              return false;
            }
            var query = $scope.getQuery(focusInputName);
            ihestiaRestDictionaryAddressesSvc.findByParams({
              query: query,
              pageSize: 10,
              top: 10,
              sortBy: 'id',
              sortDirection: 'DESC'
            }).then(function(res) {
              $scope.fillAddress(focusInputName, res.data.item);
            }, angular.noop);
          };

          $scope.onBlurAddress = function(val, focusInputName) {
            if (!$scope.address[focusInputName]) {
              return false;
            }
            var query = $scope.getQuery(focusInputName);
            ihestiaRestDictionaryAddressesSvc.findByParams({
              query: query,
              pageSize: 10,
              top: 10,
              sortBy: 'id',
              sortDirection: 'DESC'
            }).then(function(res) {
              $scope.fillAddress(focusInputName, res.data.item);
            }, angular.noop);
          };

          /**
           * wypelnia wartości inputów
           * @param  {*} focusInputName     callback
           * @param  {[*} response odpowiedź z usługi z wartościami adresów
           */
          $scope.fillAddress = function(focusInputName, response) {
            if (typeof response === 'undefined') {
              return false;
            }

            angular.forEach($scope.bindedInputs, function(val, name) {
              //jeśli pole już wypełnione to pomijamy
              if ($scope.address[name] !== '' && focusInputName !== $scope.attributeMap.postalCode && name !== $scope.attributeMap.streetPrefix) //dla zmiany kodu pocztowego zawsze nadpisujemy wartości
              {
                return true;
              }
              var responseDataNode = addressHelper.REST_ADDRESSES_RESPONSE_RELATIONS[$scope.getAttributeCodeFromName(name)];
              if (response[responseDataNode].items.length === 1) //jeśli mamy tylko jedną podpowiedź to wypełmniamy input
              {
                $scope.address[name] = response[responseDataNode].items[0];
              } else if (focusInputName === $scope.attributeMap.postalCode && name !== $scope.attributeMap.postalCode) //jeśli zmiana kodu pocztowego to inne pola czyścimy jeśli wiele możliwości
              {
                $scope.address[name] = '';
              }
            });
          };

          $scope.init();
        }]
      };
    }
  ]);