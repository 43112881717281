angular.module('ihestiaCommonDirectives')
  .directive('commonSwitch', ['$compile', '$parse',
    function($compile, $parse) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/inputs/switchWrap.tpl.html',
        require: ['^ngModel'],
        scope: true,
        compile: function(tElement, tAttrs) {
          var input = $(tElement).find('input');
          input.attr('ng-model', tAttrs.ngModel);
          input.attr('name', tAttrs.name);
          input.attr('id', tAttrs.inputId);
          if (tAttrs.ngDisabled) {
            input.attr('ng-disabled', tAttrs.ngDisabled);
            $(tElement).find('[common-switch-wrap]').attr('ng-class', '{\'disabled\': ' + tAttrs.ngDisabled + '}');
          }

          if (tAttrs.ngChange) {
            input.attr('ng-change', tAttrs.ngChange);
          }

          angular.forEach(tAttrs.$attr, function(v) {
            tElement.removeAttr(v);
          });

          return {
            pre: angular.noop,
            post: function postLink($scope, iElement, iAttrs) {
              $compile(input)($scope);
              $scope.text = iAttrs.commonSwitchText;
              if (iAttrs.commonSwitchTextLabel) {
                $scope.textLabel = $parse(iAttrs.commonSwitchTextLabel)($scope);
              }
            }
          };
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $attrs.$observe('commonSwitchText', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.text = newVal;
            }
          });
        }]
      };
    }
  ]);