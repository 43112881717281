angular.module('ihestiaCommonDirectives')
  /**
   * zwraca unikalną tablicę z przekazanej
   * @param {(string|number)[]} array
   * @returns {(string|number)[]} tablica unikalnych wartości
   */
  .factory('ihestiaArrayUnique', function () {
    var ihestiaArrayUnique = function (array) {
      var a = array.concat();
      for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
          if (a[i] === a[j]) {
            a.splice(j--, 1); // NOSONAR
          }
        }
      }

      return a;
    };
    return ihestiaArrayUnique;
  });