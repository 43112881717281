angular.module('ihestiaRestServicesBase')
  .service('clausesContextsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var ClausesContexts = function()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'contexts';
        this.businessArea = 'clauses';
        this.apiVersion = 'v1';

      };

      return new ClausesContexts();
    }])
;
