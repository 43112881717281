/**
 * Mapper wiadomosci/errorów
 */
angular.module('salesPath2').service('messagesHelper', ['CONFIG', '$state', '$rootScope', 'CONSTANTS', 'appVariables', 'mainErrorCollection', '$timeout', 'sp2CommonMessageHelper', 'sp2CommonHelper', 'sp2ApplicationValidator', 'lsnUtils', 'sp2CommonHelper', 'actionHelper',
  function(CONFIG, $state, $rootScope, CONSTANTS, appVariables, mainErrorCollection, $timeout, commonMessageHelper, commonHelper, sp2ApplicationValidator, lsnUtils, sp2CommonHelper, actionHelper) { // eslint-disable-line angular/di
    var Helper = function() {
      var self = this;
      this.messageCollection = mainErrorCollection;
      this.messagesToShow = [];
      this.messagesToShowCount = 0;
      this.isErrorToShow = false;
      this.isWarnToShow = false;
      this.isInfoToShow = false;
      this.staticMessages = []; //statyczne komunikaty, np. o braku uprawnien do zapisu wniosku/polisy
      this.isUnsupportedErrorToShow = false;

      /**
       * reakcja na akcje w aplikacji
       * @param  {String} actionName nazwa wykonanej akcji
       */
      this._afterAction = function(actionName) {
        if (actionName === 'updateMessages') {
          self.updateMessages(arguments[1]);
        }
      };

      /**
       * zwraca kolekcje komunikatow
       * @return {ErrorCollection}
       */
      this.getMessageCollection = function() {
        return self.messageCollection;
      };

      this.getMessagesToShow = function() {
        var errorsToShow = [];

        angular.forEach(self.messageCollection.all(), function(error, index) {
          error.index = index;
          error.type = error.type.toLowerCase();
          // niektóre komunikaty tylko na stronie podsumowania
          if (!(commonMessageHelper.codeToShowSummary(error.code) && $state.current.name !== 'summary')) {
            errorsToShow.push(error);
          }
        });
        return errorsToShow;
      };

      /**
       * kasowanie bledu
       *
       * @param {int} index
       */
      this.removeError = function(index) {
        self.messageCollection.remove(index);
      };

      /**
       * kasowanie bledu wg wlasciwosci
       *
       * @param {string} property
       * @param {*} value
       */
      this.removeErrorBy = function(property, value) {
        self.messageCollection.removeBy(property, value);
      };

      /**
       * usuwa wszystkie errory z kolekcji
       */
      this.removeAll = function() {
        self.messageCollection.errors = [];
        self.messageCollection.reset();
      };

      /**
       * wywołuje akcje po kliknięciu na dany komunikat
       */
      this.messageClicked = function(errorIndex) {
        if (appVariables.appMode !== CONSTANTS.APP_MODE_CALCULATION) {
          return false;
        }

        var error = self.getMessageCollection().get(errorIndex);
        var errorHandled = false;
        //obsługa podstawoa błędu
        if (angular.isDefined(error)) {
          errorHandled = commonMessageHelper.messageClicked(error);
        }

        if (errorHandled) {
          self.hideMessageContainer();
          return true;
        }

        //dodatkowa obsługa konkretnego typu obiektu
        commonMessageHelper.errorEntityAction(error);

        self.hideMessageContainer();
        return true;
      };

      /**
       * odswieza liste komunikatow oraz komunikatow do wyswietlenia
       * @param  {Object} data tablica komunikatow
       */
      this.updateMessages = function(data) {
        self.removeAll();
        commonMessageHelper.clearLoadedCodes();
        var message;
        for (var i = 0; i < data.length; i += 1) {
          message = data[i];
          if (commonMessageHelper.shouldShowMessage(message)) {
            self.addError(message.code, message.type, message.message, message.attributes, message.viewReference);
          } else if (commonMessageHelper.shouldShowMessageUnsuported(message)) {
            message.type = CONSTANTS.ERROR_TYPE.unsupported;
            self.addError(message.code, message.type, message.message, message.attributes, message.viewReference);
          }
        }
        //komunikaty statyczne i wewnetrzne walidacje wniosku
        angular.forEach(self.staticMessages.concat(sp2ApplicationValidator.validateApplication()), function(msg) {
          self.addError(msg.code, msg.type, msg.message, msg.attributes, msg.viewReference);
        });
        self.refreshHelperData();
        actionHelper.runAction('messagesUpdated', self.messagesToShow);
      };

      /**
       * odswieza dane/flagi helpera np. po dodaniu/ususnieciu komunikatu
       * @return {[type]} [description]
       */
      this.refreshHelperData = function() {
        self.messagesToShow = self.getMessagesToShow();
        self.messagesToShowCount = self.messagesToShow.length;
        self.isErrorToShow = self.isAnyError(true);
        self.isUnsupportedErrorToShow = self._isAnyMessageWithType(CONSTANTS.ERROR_TYPE.unsupported, true);
        self.isWarnToShow = self.isAnyWarn(true);
        self.isInfoToShow = self.isAnyInfo(true);
      };

      //init statycznych komunikaów
      this.initStaticMessages = function() {
        if (!commonHelper.getAgHelper().hasFinalPolicyPermissionFor(commonHelper.getServerConfig().get('ihsgDefines').AG_SYMBOL_POLICY_SALE)) {
          var msg = {
            code: 'POLSALEDENIED',
            type: CONSTANTS.ERROR_TYPE.error,
            message: 'Brak uprawnień do zapisu wniosku i polisy.',
            attributes: {},
            viewReference: null
          };
          self.staticMessages.push(msg);
        }
      };

      /**
       * Czy występują jakieś błędy taryfikacyne, czyli błędy z BOS typu error
       * @return {Boolean}
       */
      this.isanyTarifficationError = function() {
        var result = false;
        lsnNg.forEach(self.messageCollection.all(), function(error) {
          if (commonMessageHelper.isTarifficationError(error)) {
            result = true;
            return false;
          }

          return true; //continue
        });
        return result;
      };

      /**
       * Czy występują komunikaty z typem 'error'
       * @param {Boolean} [toShow=false] czy brac pod uwage tylko komunikaty do wyswietlenia
       * @param {Boolean} [ignoreFraudulent=false] ignore fraudulent client message
       * @return {Boolean}
       */
      this.isAnyError = function(toShow, ignoreFraudulent) {
        return self._isAnyMessageWithType(CONSTANTS.ERROR_TYPE.error, toShow, ignoreFraudulent);
      };

      /**
       * Czy występują komunikaty z typem 'warn'
       * @param {Boolean} [toShow=false] czy brac pod uwage tylko komunikaty do wyswietlenia
       * @return {Boolean}
       */
      this.isAnyWarn = function(toShow) {
        return self._isAnyMessageWithType(CONSTANTS.ERROR_TYPE.warn, toShow);
      };

      /**
       * Czy występują komunikaty z typem 'info'
       * @param {Boolean} [toShow=false] czy brac pod uwage tylko komunikaty do wyswietlenia
       * @return {Boolean}
       */
      this.isAnyInfo = function(toShow) {
        return self._isAnyMessageWithType(CONSTANTS.ERROR_TYPE.info, toShow);
      };

      /**
       * Czy występują komunikaty z danym typem
       * @param {Boolean} [toShow=false] czy brac pod uwage tylko komunikaty do wyswietlenia
       * @param {String} [type] typ komunikatu
       * @param {boolean} [ignoreFraudulent=false] ignore fraudulent cleint message
       * @return {Boolean}
       */
      this._isAnyMessageWithType = function(type, toShow, ignoreFraudulent) {
        ignoreFraudulent = !!ignoreFraudulent;
        var boolToShow = !!toShow;
        var container = boolToShow ? self.messagesToShow : self.messageCollection.all();
        var isMsg = false;

        angular.forEach(container, function(error) {
          if ((error.type.toLowerCase() === type) && (!ignoreFraudulent || error.code !== CONFIG.MESSAGES.fraudulentMsgCode)) {
            isMsg = true;
          }
        });

        return isMsg;
      };

      /**
       * wyswietla kontener z komunikatami
       */
      this.showMessageContainer = function() {
        $timeout(function() {
          appVariables.contentPulledLeft = true;
          appVariables.messageContainerVisible = true;
        }, 0);
      };
      /*
       * ukrywa kontener z komunikatami
       */
      this.hideMessageContainer = function() {
        appVariables.contentPulledLeft = false;
        appVariables.messageContainerVisible = false;
      };

      /**
       * dodawanie errora owrapowujace go w encje
       *
       * @param code - kod bledu
       * @param type - typ [error/warning]
       * @param message - tresc bledu
       * @param attributes - opcjonalne atrybuty
       * @param viewReference - powiazany widok
       */
      this.addError = function(code, type, message, attributes, viewReference) {
        commonMessageHelper.addError(code, type, message, attributes, viewReference);
      };

      /**
       * Checks if messages to show contains U0000102 error and any acknowledge date discrepancy modal was already shown
       * @return {object} message with U0000102 code
       * @private
       */
      this._shouldDisplayU0000102Popup = function() {
        var errorSearchParams = {
          code: 'U0000102'
        };
        var errorMessage = lsnUtils.findObjInArray(self.messagesToShow, errorSearchParams);
        return errorMessage || null;
      };

      /**
       * Checks if messages to show contains U0000103 error and any acknowledge date discrepancy modal was already shown
       * @return {object} message with U0000102 code
       * @private
       */
      this._shouldDisplayU0000103Popup = function() {
        var errorSearchParams = {
          code: 'U0000103'
        };
        var errorMessage = lsnUtils.findObjInArray(self.messagesToShow, errorSearchParams);
        return errorMessage || null;
      };

      /**
       * Shows insurance date discrepancy modal if errors regarding date exist, then executes callback
       * @param {function} callback
       */
      this.showAcknowledgeDateDiscrepancyErrorsModal = function(callback) {
        var U0000102Error = self._shouldDisplayU0000102Popup();
        var U0000103Error = self._shouldDisplayU0000103Popup();

        if (U0000102Error) {
          self._showInsuranceDatesDiscrepancyModal(U0000102Error, callback);
        } else if (U0000103Error) {
          self._showInsuranceDatesDiscrepancyModal(U0000103Error, callback);
        } else {
          callback();
        }
      };

      /**
       * Shows insurance date discrepancy modal
       * @param {object} message
       * @param {function} callback
       * @private
       */
      this._showInsuranceDatesDiscrepancyModal = function(message, callback) {
        if (message === null) {
          return;
        }
        var text = message.message;
        sp2CommonHelper.showConfirmModal({
          treatLabelAsI18nCode: true,
          title: ['sp2.modal.info.title', {
            componentCode: 'sp2'
          }],
          okBtnName: ['next', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          text: text,
          okBtnCallback: callback
        });
      };

      this.initStaticMessages();
      this.updateMessages([]);
    };

    return new Helper();
  }
])
  .run(['messagesHelper', 'actionHelper',
    function(messagesHelper, actionHelper) {
      actionHelper.registerHelper('messagesHelper', messagesHelper);
    }
  ]);
