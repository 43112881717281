angular.module('salesPath2')
	.service('dcAllowedHelper', ['mainDataContainer', 'CONSTANTS', 'resourceHelper', 'lsnUtils', 'lsnArrayUnique', 'SPD', 'ApplicationAllowedChangesObjectModel', 'IHestiaMetaDataModel', 'dcCommonHelper', 'appVariables', 'RESOURCES', 'dcProductHelper',
		function(mainDataContainer, CONSTANTS, resourceHelper, lsnUtils, lsnArrayUnique, SPD, ApplicationAllowedChangesObjectModel, IHestiaMetaDataModel, dcCommonHelper, appVariables, RESOURCES, dcProductHelper) {
			var DcAllowedHelper = function() {
				var self = this;

				/**
				 * zwraca dane dotyczące dozwolonych atrybutów dla podanego obiektu oraz informacje czy obiekt jest nowym elementem na wniosku
				 * @param  {String} objectType typ obiektu/produktu lub 'policy' dla dozwolonych atrybutów na pakiecie/polisie
				 * @param  {String} clientId   clientId obiektu/podmiotu
				 * @param  {Boolean} [plainData=false] czy zwrócić bezpośrednio niesparsowane dane z dataContainer.allowedChanges znalezionego obiektu
				 * @return {Object|null} znaleziony podelement/obiekt z mainDataContainer.allowedChanges z atrybutami umieszczonymi w obiektach zamiast tablicach (kluczem w obiekcie jest nazwa atrybutu. Zmiana dotyczy attribute, additinalData i objectProperties)
				 * Dodatkowo addtionalData zapisujemy w "_additionalData".
				 */
				this.getAllowedChangesFor = function(objectType, clientId, plainData) {
					var acData = null, //allowedChangesData
						dataPath = mainDataContainer.objTypeToAllowedContainer[objectType],
						foundObj = null; //znaleziony obiekt z danymi dotyczacymi dostepnosci
					if (angular.isObject(mainDataContainer.allowedChanges) && angular.isObject(mainDataContainer.allowedChanges[dataPath[0]]) && angular.isArray(mainDataContainer.allowedChanges[dataPath[0]][dataPath[1]])) {
						//obiekty, podmioty, ryzyka
						lsnNg.forEach(mainDataContainer.allowedChanges[dataPath[0]][dataPath[1]], function(obj) {
							if (!obj.metaData || obj.metaData.id === null) {
								foundObj = obj;
							} else if (obj.metaData && obj.metaData.clientId === clientId) {
								foundObj = obj;
								return false; //znalezlismy nasz obiekt, wiec przerywamy petle
							}

							return true; //continue
						});
					} else if (objectType === 'policy' && angular.isObject(mainDataContainer.allowedChanges) && angular.isObject(mainDataContainer.allowedChanges.salesProduct)) {
						//polisa/pakiet
						foundObj = mainDataContainer.allowedChanges.salesProduct;
					}

					if (foundObj !== null) {
						if (plainData) {
							return foundObj;
						}
						acData = angular.copy(foundObj);
						self._setAttrsArrayAsObject(acData, 'attributes');
						self._setAttrsArrayAsObject(acData, 'objectProperties');
						acData._additionalData = acData.additionalData;
						delete acData.additionalData;
						self._setAttrsArrayAsObject(acData, '_additionalData');
					}
					return acData;
				};

				/**
				 * zamienia tablicę z atrybutami na obiekt z atrybutami, w którym kluczem jest nazwa atrybutu
				 * @param {Object} obj obiekt przechowujący dane
				 * @param {String} container nazwa kontenera/tablicy z atrybutami
				 */
				this._setAttrsArrayAsObject = function(obj, container) {
					if (angular.isArray(obj[container])) {
						var newContainer = {};
						angular.forEach(obj[container], function(attr) {
							newContainer[attr.name] = attr;
						});
						obj[container] = newContainer;
					}
				};

				/**
				 * zwraca dozwolone warianty dla zadanego produktu lub dodatku na podstawie danych z mainDataContainer.allowedRisksVariants
				 * @param  {String} product kod produktu
				 * @param  {String|null} objId   is obiektu. dostępne opcje
				 * 'id_obiektu' sprawdzanie dla konkretnego obiektu/podmiotu ubezpieczonego
				 * CONSTANTS.NO_OBJECT - sprawdzanie dostępności tylko dla nowych obiektów/podmiotów
				 * null/undefined - to samo co CONSTANTS.NO_OBJECT, tylko dla ryzyk osobowych, jeśli występuje ubezpieczona tylko 1 osoba to sprawdzi dostępność ryzyka dla tej osoby (kontekst matrycy głównej)
				 * @return {null|String[]} tablica z dozwolonymi wariantami lub null gdy brak danych
				 * UWAGA
				 * jeśli w odpowiedzi pojawi się pusta tablica ([]), to oznacza to, że produkt/dodatek jest dostępny ale nie posiada wariantów
				 */
				this.getAllowedProductVariants = function(product, objId) {
					if (mainDataContainer.allowedRisksVariants === null) {
						return null;
					} else if (resourceHelper.productType[product] === CONSTANTS.PRODUCT_TYPE_PERSON) {
						if (objId === null && dcProductHelper.getProductPeronsCount(product) === 1) {
							objId = dcProductHelper.getFirstPersonOnProduct(product);
						}
						return self.getAllowedProductVariantsForProductPerson(product, objId);
					} else {
						objId = objId || null;
						if (objId === CONSTANTS.NO_OBJECT) { //brak wybranego obiektu oznacza kontekst dowolnego nowego obiektu
							objId = null;
						}
						var allowed = [], //dozwolone warianty
							allObjs = (objId === CONSTANTS.ALL_OBJECTS), //czy sprawdzanie dla wszystkich obiektów
							anyAllowedFound = false; //czy jakikolwiek zapis o dostepnosci ryzyka znaleziono
						angular.forEach(mainDataContainer.allowedRisksVariants, function(data) {
							if (data.product === product && (allObjs || data.objId === objId)) {
								allowed = allowed.concat(data.allowedVariants);
								anyAllowedFound = true;
							}
						});

						//jesli ma varianty to  tablica nie moze byc pusta
						//jeśli dla danego objId nie znaleziono informacji o dostępnych wariantach i obiekt nie był ubezpieczony na poprzedniej polisie w danym produkcie, to szukamy informacji o dostępności danego ryzyka/produktu dla dowolnego obiektu. Jeśli znajdziemy, oznaczać to będzie, że dane ryzyko jest dostępne dla dowolnego nowego obiektu ubezpieczenia
						if (allowed.length === 0 && objId !== null && parseInt(objId, 10) > 0 && angular.equals(dcCommonHelper.getPreviousVariantsForProduct(product, objId), {})) {
							return self.getAllowedProductVariants(product, CONSTANTS.NO_OBJECT);
						}
						return anyAllowedFound ? lsnArrayUnique(allowed) : null;
					}

				};


				/**
				 * zwraca dozwolone warianty dla zadanego produktu dla ryzyk osobowych
				 * UWAGA
				 * Usługi dla ryzyk osobowych zwracają w allowedChanges.risks.newRisks obiekty opisujące dostępność ryzyK w 2 postaciach:
				 * - z objectId === null - dotyczy tylko osób nowych, których nie było na poprzedniej polisie
				 * - z objectId !== null - dotyczy konkretnych osób, które istnieją już na poprzedniej polisie
				 * @param  {String} product kod produktu
				 * @param  {String|null} objId   is obiektu. dostępne opcje
				 * 'id_obiektu' sprawdzanie dla konkretnego obiektu/podmiotu ubezpieczonego
				 * CONSTANTS.ALL_OBJECTS - sprawdzanie dostępności dla dowolnego podmiotu
				 * null/undefined - to samo co dla CONSTANTS.ALL_OBJECTS
				 * ustalenia m.in. w IHESTIADID-758
				 * @return {null|String[]} tablica z dozwolonymi wariantami lub null gdy brak danych
				 * UWAGA
				 * jeśli w odpowiedzi pojawi się pusta tablica ([]), to oznacza to, że produkt/dodatek jest dostępny ale nie posiada wariantów
				 */
				this.getAllowedProductVariantsForProductPerson = function(product, objId) {
					if (mainDataContainer.allowedRisksVariants === null) {
						return null;
					}
					objId = objId || CONSTANTS.ALL_OBJECTS;

					//sprawdzamy nnwIncogntio dla NNW w przypadku gdy nie weryfikujemy dla konkrentgo obiektu lub wlasnie dla INCOGNITO
					var checkNnwIncognito = (product === CONSTANTS.PRODUCT_NNW && (objId === CONSTANTS.ALL_OBJECTS || objId === CONSTANTS.NNW_INCOGNITO_PERSON_ID)) || product === CONSTANTS.PRODUCT_NNW_INCOGNITO;
					//zero to normalny identyfikator dla NNW_INCOGNITO
					//trzeba wykluczyc PRODUCT_NNW i PRODUCT_NNW_INCOGNITO gdyz CONSTANTS.NO_OBJECT === CONSTANTS.NNW_INCOGNITO_PERSON_ID
					if (objId === CONSTANTS.NO_OBJECT && product !== CONSTANTS.PRODUCT_NNW && product !== CONSTANTS.PRODUCT_NNW_INCOGNITO) { //brak wybranego obiektu oznacza kontekst dowolnego nowego obiektu
						objId = CONSTANTS.ALL_OBJECTS;
					}

					var newPerson = false; //czy sprawdzamy dla nowych osób

					//NNW_INCOGNITO_PERSON_ID nie jest jest nowa osoba
					if (objId !== CONSTANTS.ALL_OBJECTS && objId !== CONSTANTS.NNW_INCOGNITO_PERSON_ID) {
						newPerson = dcCommonHelper.isNewPersonPerRisk(objId, product);
					}

					var allowed = [], //dozwolone warianty
						allObjs = objId === CONSTANTS.ALL_OBJECTS, //czy sprawdzanie dla wszystkich obiektów
						anyAllowedFound = false; //czy jakikolwiek zapis o dostepnosci ryzyka znaleziono

					angular.forEach(mainDataContainer.allowedRisksVariants, function(data) {
						if (data.product === product && data.product === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
							//jesli NNW_INCOGNITO istnieje na wniosku to jest dostepny 1 wariant
							if (angular.isObject(mainDataContainer.nnwIncognito)) {
								allowed.push(CONSTANTS.VARIANT_I);
								anyAllowedFound = true;
							}
						} else if (data.product === product && (allObjs || (data.objId === objId) || (newPerson && data.objId === null))) {
							allowed = allowed.concat(data.allowedVariants);
							anyAllowedFound = true;
						} else if (checkNnwIncognito && data.product === CONSTANTS.PRODUCT_NNW_INCOGNITO &&
							((data.objId === CONSTANTS.NNW_INCOGNITO_PERSON_ID && data.product === CONSTANTS.PRODUCT_NNW_INCOGNITO) ||
								(data.objId === null && angular.isObject(mainDataContainer.nnwIncognito)))
						) {
							allowed.push(CONSTANTS.VARIANT_I);
							anyAllowedFound = true;
						}
					});


					return anyAllowedFound ? lsnArrayUnique(allowed) : null;
				};

				/**
				 * zwraca informacje o dozwolonych dodatkach na podstawie dataContainer.allowedRisksVariants (czyli allowedChanges z usługi)
				 * @return {null|Object} null gdy brak danych (wówczas nie blokujemy dodatków) lub obiekt formatu:
				 * {
				 * 	kod_dodatku: true|false //true gdy dozwolony
				 * 	[,...]
				 * }
				 */
				this.getAllowedAdds = function() {
					if (!appVariables.isSupplement) { //poki co obslugujemy poprzednio wybrane warianty tylko dla dokupien/doubezpieczen
						return null;
					}
					var allowed = {};
					angular.forEach(RESOURCES.PRODADD, function(addDef) {
						var objectId = null;
						if (addDef.TYPE !== CONSTANTS.PRODUCT_TYPE_PERSON && addDef.TYPE !== CONSTANTS.PRODUCT_TYPE_PACKAGE) { //dla dodatków powiązanych z jakimś obiektem/podmiotem ubezpieczenia pobieramy jego id
							objectId = dcCommonHelper.getSelectedObjectId(addDef.TYPE);
						}
						allowed[addDef.CODE] = self.getAllowedProductVariants(addDef.CODE, objectId) !== null;
					});
					return allowed;
				};

				/**
				 * zwraca obiekt z kodami ryzyk mieniowych i informacją, czy dane ryzyko jest dostępne (na podstawie danych z allowedChanges na wniosku)
				 * @param  {String} product jaki produkt sprawdzamy
				 * @param  {String} objId clientId nieruchomości
				 * @return {null|Object} null, gdy brak informacji (nie powinniśmy wówczas niczego blokować) lub obiekt:
				 * {
				 * 	estate: true|false //true gdy dostępne
				 * 	[, kod_ryzyka: ...] //kolejne ryzyka na podstawie typu lokalizacji
				 * }
				 */
				this.getAllowedPropertyRisks = function(product, objId) {
					if (mainDataContainer.allowedRisksVariants === null || parseInt(objId, 10) < 1) {
						return null;
					}
					var allowed = {},
						riskList = resourceHelper.getFireBurglaryRisksListAsArray(mainDataContainer.localizations[objId].type, product); //tablica z definicji ryzyk do sprawdzenia

					angular.forEach(riskList, function(risk) {
						var found = lsnUtils.findObjInArray(mainDataContainer.allowedRisksVariants, {
							product: product,
							risk: risk.code,
							objId: objId
						});
						if (found === null) { //jesli brak informacji dla naszego obiektu, szukamy informacji dla dowolnego obiektu
							found = lsnUtils.findObjInArray(mainDataContainer.allowedRisksVariants, {
								product: product,
								risk: risk.code,
								objId: null
							});
						}
						allowed[risk.code] = (found !== null);
					});
					return allowed;
				};

				/**
				 * przebudowuje allowedChanges (np. kopiuje dozwolone atrybuty z ryzyk do additionalData na ubezpieczonych obiektach)
				 * @param  {Object} [allowedChanges] jesli nie podano, to operacja przeprowadzana jest na mainDataContainer.allowedChanges
				 * @return {Boolean} true, gdy przeprowadzono operację
				 */
				this.rebuildAllowedChanges = function(allowedChanges) {
					if (angular.isUndefined(allowedChanges)) {
						allowedChanges = mainDataContainer.allowedChanges;
					}
					if (!angular.isObject(allowedChanges)) {
						return false;
					}
					//przenosimy dane o dostepnosci atrybutow z ryzyk na additionalData obiektów
					if (angular.isArray(allowedChanges.risks.existingRisks)) {
						angular.forEach(allowedChanges.risks.existingRisks, function(allowedObj) {
							var prodType = null, //kod typu produktu dla ryzyka
								riskName = (angular.isArray(allowedObj.risksName) && angular.isDefined(allowedObj.risksName[0]) ? allowedObj.risksName[0] : null); //jesli rozpatrujemy ryzyka, to wystarczy sprawdzic nazwe pierwszego z brzegu, bo zakladamy ze atrybuty maja taką samą definicję dla każdego ryzyka
							if (riskName === null) {
								return true;
							}
							prodType = resourceHelper.productType[SPD.risks[riskName].productCode];
							if (angular.isUndefined(mainDataContainer.riskDynamicValuesToAdditionalData[prodType])) {
								return true; //jesli nie ma additionalData mapowanego z ryzyk dla danego typu obiektu to pomijamy to szukamy dalej
							}
							angular.forEach(allowedObj.attributes, function(attr) {
								if (angular.isDefined(mainDataContainer.riskDynamicValuesToAdditionalData[prodType][attr.name])) {
									var adAttr = angular.copy(attr);
									adAttr.name = mainDataContainer.riskDynamicValuesToAdditionalData[prodType][attr.name];
									self._addAllowedAdditionalDataToObj(allowedChanges, adAttr, prodType, allowedObj.objectClientId);
								}
							});
							return true;
						});
					}
					return true;
				};

				/**
				 * dodaje do ApplicationAllowedChangesObjectModel.additionalData nowy atrybut, o ile jeszcze nie istnieje
				 * @param {Object} allowedChanges obkekt z dumpem z ApplicationAllowedChangesModel
				 * @param {ApplicationAllowedChangesAttributeModel} attr atrybut do dodania
				 * @param {String} objType typ obiektu, do którego należy dołożyć atrybut
				 * @param {String} objId clientId obiektu, do którego należy dołożyć atrybut
				 * @return {Boolean} true, gdy dodano
				 */
				this._addAllowedAdditionalDataToObj = function(allowedChanges, attr, objType, objId) {
					var dataPath = mainDataContainer.objTypeToAllowedContainer[objType],
						allowedObjData = lsnUtils.findObjInArray(allowedChanges[dataPath[0]][dataPath[1]], {
							metaData: {
								clientId: objId
							}
						});
					//jeśli nie znalezlismy naszeog obiektu, to tworzymy nowy
					if (allowedObjData === null) {
						allowedObjData = new ApplicationAllowedChangesObjectModel();
						allowedObjData.metaData = new IHestiaMetaDataModel();
						allowedObjData.metaData.clientId = objId;
						allowedObjData.additionalData.push(attr);
						allowedChanges[dataPath[0]][dataPath[1]].push(allowedObjData);
					} else { //obiekt istnieje, dodajemy atrybut o ile go jeszcze nie ma
						if (lsnUtils.findObjInArray(allowedObjData.additionalData, {
								name: attr.name
							}) !== null) {
							return false; //taki atrybut juz istnieje w obj.additionalData, dubli nie chcemy
						}
						allowedObjData.additionalData.push(attr);
					}
					return true;
				};

				/**
				 * [isAllowedRisk czy ryzyko jest dostepne na rozszerzeniu]
				 * @param  {string}  productCode  [kod produktu]
				 * @param  {string}  riskCode  [kod ryzyka]
				 * @param  {string}  objectClientId     [id obiektu powiazanego np lokalizacji]
				 * @param  {array}  riskTypes [np ['newRisks', existingRisks] czy ['newRisks'] ]
				 * @return {Boolean}           [description]
				 */
				this.isAllowedRisk = function(productCode, riskCode, objectClientId, riskTypes) {
					var exist = false;
					if (angular.isArray(riskTypes)) {
						angular.forEach(riskTypes, function(riskTypeName) {
							angular.forEach(mainDataContainer.allowedChanges.risks[riskTypeName], function(allowedRisk) {
								var riskName = (angular.isArray(allowedRisk.risksName) && angular.isDefined(allowedRisk.risksName[0]) ? allowedRisk.risksName[0] : null); //jesli rozpatrujemy ryzyka, to wystarczy sprawdzic nazwe pierwszego z brzegu, bo zakladamy ze atrybuty maja taką samą definicję dla każdego ryzyka
								if (riskName !== null) {
									var risk = SPD.risks[riskName];
									if (risk.riskCode === riskCode && risk.productCode === productCode && allowedRisk.objectClientId === objectClientId) {
										exist = true;
									}
								}
							});
						});
					}

					return exist;
				};

				/**
				 * szuka i zwraca (jesli znalazl) dane dostępności dotyczące atrbutu na ryzyku
				 * @param  {String} attrName nazwa atrybutu
				 * @param  {String} objectId clientId obiektu/podmoitu ubezpieczonego
				 * @param  {String} productCode kod produktu
				 * @param  {String} [riskCode]    kod ryzyka - uzywany dla ognia/kradziezy
				 * @param  {String} [estateType]  typ nieruchomości
				 * @param  {String} [riskGroup]  grupa ryzyk - 'existingRisks' lub 'newRisks'
				 * @return {Object|null} obiekt lub null gdy nie znaleziono
				 */
				this.getAllowedAttributeForRisk = function(attrName, objectId, productCode, riskCode, estateType, riskGroup) {
					var riskAC = self.findAllowedRisk(objectId, productCode, riskCode, estateType, riskGroup);
					if (riskAC === null) {
						return null;
					}
					var attrACData = null;
					lsnNg.forEach(riskAC.attributes, function(attr) {
						if (attr.name === attrName) {
							attrACData = attr;
						}
					});
					return attrACData;
				};

				/**
				 * szuka i zwraca obiekt opisujący dozwolone zmiany dla ryzyka
				 * @param  {String} objectId clientId obiektu/podmoitu ubezpieczonego
				 * @param  {String} productCode kod produktu
				 * @param  {String} riskCode    kod ryzyka - uzywany dla ognia/kradziezy
				 * @param  {String} [estateType]  typ nieruchomości
				 * @return {Object|null} obiekt lub null gdy nie znaleziono
				 */
				this.findAllowedRisk = function(objectId, productCode, riskCode, estateType, riskGroup) {
					if (!angular.isObject(mainDataContainer.allowedChanges) || !angular.isObject(mainDataContainer.allowedChanges.risks)) {
						return null;
					}
					var found = null,
						match = false;
					lsnNg.forEach(mainDataContainer.allowedChanges.risks, function(risks, group) {
						if (riskGroup && group !== riskGroup) { //jesli szukamy tylko w existingRisks lub newRisks
							return true;
						}
						lsnNg.forEach(risks, function(risk) {
							if (risk.def.productCode === productCode && risk.def.riskCode === riskCode && risk.objectClientId === objectId) {
								match = true;
								if (estateType && risk.def.estateType && (angular.isArray(risk.def.estateType) ? (risk.def.estateType.indexOf(estateType) === -1) : (risk.def.estateType !== estateType))) { //estateType moze byc stringiem lub tablica
									match = false;
								}
								if (match) {
									found = risk;
									return false;
								}
							}
							return true; //continue
						});
						if (found !== null) {
							return false;
						}
						return true;
					});
					return found;
				};

			};
			return new DcAllowedHelper();
		}
	]);