angular.module('ihestiaRestServicesBase')

  /**
     * Ustawienia
     * @return {[type]} [description]
     */
  .factory('IHestiaRestServiceLsnDef', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var RestServiceLsnDef = function() {

        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.businessArea = '';
        this.host = '';
        this.apiString = 'local';
        this.version = 'v1';
      };

      return RestServiceLsnDef;
    }]);