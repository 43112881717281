angular.module('ihestiaCommonDirectives.pkdHelper')
  .factory('ihestiaPkdHelper', ['ihestiaRestDictionaryPkdsSvc', 'ihestiaConfigHelper', 'ihestiaTryEscapeHtml', 'ihestiaPolishGrama', '$q',
      function(ihestiaRestDictionaryPkdsSvc, ihestiaConfigHelper, ihestiaTryEscapeHtml, ihestiaPolishGrama, $q) {

      var PkdHelper = function() {
        var self = this;

        /**
         * promise używany do anulowania requesta
         * @type {function}
         */
        this.promiseToCancel = $q.defer();

        /**
         * czy trwa zapytanie do usługi
         * Wykorzystywane w połączneiu z promiseToCancel
         * @type {Boolean}
         */
        this.processingRequest = false;

        this.findPkds = function(query, selectedPkd) {
          var deferred = $q.defer();
          if (self.processingRequest) {
            self.promiseToCancel.reject('newPkdRequest');
            self.promiseToCancel = $q.defer();
          }
          self.processingRequest = true;
          ihestiaRestDictionaryPkdsSvc.findByParams({
            query: query,
            top: 10, //bez 0 tutaj, nie wyszukuja sie osoby
            pageSize: 10,
            httpParams: {
              timeout: self.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
            }
          }).then(function(res) {
            self.processingRequest = false;
            var data;
            if(typeof res.data === 'undefined' || typeof res.data.items === 'undefined'){
              data = {items: []};
            }else{
              data = res.data;
            }
            
            var pkds = [];
            angular.forEach(data.items, function(v) { //eslint-disable-line consistent-return
              if (selectedPkd.indexOf(v.code) !== -1)
              {
                return true;
              }
              var pkdData = self.getPkdData(v);
              var nameList = '<div class="pkd_' + pkdData.supported + '">' + pkdData.symbol + ' - ' + pkdData.desc + '</div>';
              pkds.push({code: v.code, name: pkdData.desc, symbol: pkdData.symbol, nameList: nameList, supported: pkdData.supported});
            });
            deferred.resolve(pkds);
            return pkds;
          }, function(res) {
            self.processingRequest = false;
            deferred.reject(res);
          });
          return deferred.promise;
        };

        /**
         * zwraca dane pkd używane w dataManager.loadedPkd
         * @param {Object} data sane z responsa slownika
         * @returns {RestHelper.getPkdData.pkdData}
         */
        this.getPkdData = function(data) {
          var pkdData = {
            desc: ihestiaPolishGrama(ihestiaTryEscapeHtml(data.name)),
            supported: 0,
            symbol: ihestiaTryEscapeHtml(data.symbol),
            code: data.code
          };

          if ($.inArray(ihestiaConfigHelper.get('rest', 'AP_CALLER').toUpperCase(), data.products) !== -1)
          {
            pkdData.supported = 1;
          }
          return pkdData;
        };
      };

      return new PkdHelper();

    }]);