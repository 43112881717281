angular.module('salesPath2')
  .service('specializationModalHelper', ['sp2CommonHelper', 'RESOURCES', 'oczpHelper', 'mainDataContainer', 'CONSTANTS', 'arrayLowerCaseModelFilter',
    function(sp2CommonHelper, RESOURCES, oczpHelper, mainDataContainer, CONSTANTS, arrayLowerCaseModelFilter) {

      var SpecializationModalHelper = function() {
        var self = this;
        var DEFAULT_MODAL_DATA = {
          specializations: [],
          personId: null
        };
        this.modalData = angular.copy(DEFAULT_MODAL_DATA);

        /**
         * Czyscimy dane modala
         * @return {CessionModalHelper} [description]
         */
        this.clearModalData = function () {
          self.modalData = angular.copy(DEFAULT_MODAL_DATA);
          return self;
        };

        this.specializationModalSettings = {
            treatLabelAsI18nCode: true,
            title: ['specializationModal.title', {componentCode: 'sp2'}],
            cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
            okBtnName: ['Public.confirm', {componentCode: 'Public'}],
            size: 'md'
        };

        this.getSpecializations = function(personId) {
          var person = mainDataContainer.persons[personId];
          var specializations = person.getAdditionalData([CONSTANTS.PRODUCT_OCZP, 'specializations']);

          return specializations;
        };

        /**
         * Pokazujemy modal specjalizacji lekarzy
         * @param  {String} personId clientId osoby
         * @param  {[type]} jobCode kod profesji, dla której należy pobrać specjalizacje
         */
        this.showSpecializationModal = function(personId, jobCode) {
          self.modalData.personId = personId;
          self.modalData.specializations = angular.copy(RESOURCES.OCZP_JOBS_SPEC_LIST[jobCode]);
          self.personalizeSpecializations();
          sp2CommonHelper.showModal('specializationModal');
        };

        /**
         * Ukrywamy modal specjalizacji lekarzy
         */
        this.hideSpecializationModal = function() {
          sp2CommonHelper.hideModal('specializationModal');
        };

        /**
         * Zapisuje wybrane profesje
         */
        this.saveSpecializations = function() {
          var specializations = [];
          angular.forEach(self.modalData.specializations, function(spec) {
            if (spec.selected) {
              specializations.push(spec.CODE);
            }
          });
          oczpHelper.saveSpecializations(self.modalData.personId, specializations);
        };

        /**
         * dodaje do specjalizacji dane w kontekscie obsługiwanej aktualnie osoby
         */
        this.personalizeSpecializations = function() {
          var personSpecs = mainDataContainer.persons[self.modalData.personId].getAdditionalData(['OCZP', 'specializations']);
          if (!angular.isArray(personSpecs)) {
            personSpecs = [];
          }
          personSpecs = arrayLowerCaseModelFilter(personSpecs);
          angular.forEach(self.modalData.specializations, function(val) {
            if (personSpecs.indexOf(val.CODE) !== -1) {
              val.selected = true;
            } else {
              val.selected = false;
            }
          });
        };




      };

      return new SpecializationModalHelper();
    }
  ]);