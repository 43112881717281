angular.module('ihestiaWidgets.ikonfigurator')
  .factory('ikonfiguratorConstants', [function() {
    return {
      ACCESS_MODE_CLIENT: 'Client',
      ACCESS_MODE_AGENT: 'Agent',
      ACCESS_MODE_GUEST: 'Guest'
    };
  }])
  .factory('ikonfiguratorHelper', ['ihestiaSsoBaseInfoHelper', 'ikonfiguratorConstants', 'ihestiaConfigHelper', '$filter',
    function(ihestiaSsoBaseInfoHelper, ikonfiguratorConstants, ihestiaConfigHelper, $filter) {

      var IkonfiguratorHelper = function() {

        var self = this;

        /**
         * id klienta, jeśli istnieje
         * @type {int}
         */
        this.clientId = null;

        /**
         * Czy pokazywać dane klienta (dla kontekstu agenta)
         * @type {Boolean}
         */
        this.showClientInfo = false;

        /**
         * Id nbk, jeśli istnieje
         * @type {int}
         */
        this.nbkId = null;

        /**
         * Inicjaly numer konfiguracji
         * @type {string}
         */
        this.configurationId = null;

        /**
         * Dane niezalogowanego użytkownika
         * @type {Object}
         */
        this.personData = {
          firstName: '',
          lastName: '',
          pesel: '',
          cellPhonePrefix: '+48',
          cellPhone: '',
          clauses: {
            mark: false,
            elec: false
          }
        };

        /**
         * Tryb - klient|agent
         * kiedyś do dorobienia tryb niezalogowanego (gość)
         * @type {String}
         */
        this.accessMode = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === ikonfiguratorConstants.ACCESS_MODE_CLIENT ? ikonfiguratorConstants.ACCESS_MODE_CLIENT : ikonfiguratorConstants.ACCESS_MODE_AGENT;

        /**
         * lista dostępnych produktów
         * zgodnie z priorytetem!
         * @type {Array}
         */
        var AVAILABLE_PRODUCTS = [
          'ock',
          'md',
          'ocz',
          'e1',
          'e7',
          'mf',
          'es'
        ];

        /**
         * Adresy do aplikacji sprzedażowych powiązanych z danym produktem
         * @type {Object}
         */
        this.productUrls = {
          'ock': ihestiaConfigHelper.getUrl('OCK_URL', 'external/init'),
          'md': ihestiaConfigHelper.getUrl('MD_URL', 'external/init'),
          'ocz': ihestiaConfigHelper.getUrl('OCZ_URL', 'external/init'),
          'e1': ihestiaConfigHelper.getUrl('E1_URL', 'external/init'),
          'e7': ihestiaConfigHelper.getUrl('E7_URL', 'external/init'),
          'mf': ihestiaConfigHelper.getUrl('MF_URL', 'external/init'),
          'es': ihestiaConfigHelper.getUrl('ES_URL', 'external/init')
        };

        /**
         * Czyścimy dane ostatniej konfiguracji
         * @return {ikonfiguratorHelper} self
         */
        this.clearData = function() {
          self.clientId = null;
          self.nbkId = null;
          self.configurationId = null;
          self.showClientInfo = false;
          self.personData = {
            firstName: '',
            lastName: '',
            pesel: '',
            cellPhonePrefix: '+48',
            cellPhone: '',
            clauses: {
              mark: false,
              elec: false
            }
          };
          return self;
        };

        /**
         * Mapa dla kompresji
         * @type {Array}
         */
        var BITMAP = ['ocKomBase', 'acKomNamedRisks', 'acKomTotalLoss', 'acKomAllRisk', 'assistanceTowingAfterClaim', 'assistanceReplacementCarAfterClaim', 'assistanceTowingAfterBreak', 'assistanceReplacementCarAfterBreak', 'nnwKip5', 'nnwKip15', 'nnwKip30', 'nnwKip60', 'additionGlasses', 'additionOuzKom', 'houseFromFire', 'outbuildingsFromFire', 'equipmentFromFire', 'electricalEquipmentFromSurge', 'houseFromFrost', 'glassFromBreak', 'estateArchitectureFromFire', 'antiqueFromFire', 'houseFromSteal', 'equipmentFromSteal', 'houseFromVadalism', 'estateArchitectureFromNormalSteal', 'antiqueFromSteal', 'sosAssistance', 'homeAssistance', 'oczpPropertyOwner', 'oczpKids', 'oczpAnimals', 'oczpHigherSum200', 'oczpHigherSum500', 'nnwDeath', 'nnwStroke', 'nnwHospitalCosts', 'nnwInabilityToWork', 'nnwRehabilitation', 'ocz', 'ocActivity', 'activityVehicle', 'activityEstate'];

        /**
         * Skrót do tłumaczeń
         * @param  {String} code [description]
         * @return {String}      [description]
         */
        this.translate = function(code) {
          return $filter('translate')(code, {
            componentCode: 'iKonfigurator'
          });
        };

        /**
         * Zależności dodatków od ryzyk
         * @type {Object}
         */
        this.requiredProductsMap = {
          assistanceTowingAfterClaim: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.assistanceTowingAfterBreak.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.assistanceTowingAfterBreak.lockedUnselectMessage')
          },
          assistanceReplacementCarAfterClaim: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.assistanceReplacementCarAfterBreak.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.assistanceReplacementCarAfterBreak.lockedUnselectMessage')
          },
          assistanceTowingAfterBreak: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.assistanceTowingAfterBreak.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.assistanceTowingAfterBreak.lockedUnselectMessage')
          },
          assistanceReplacementCarAfterBreak: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.assistanceReplacementCarAfterBreak.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.assistanceReplacementCarAfterBreak.lockedUnselectMessage')
          },
          additionGlasses: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.additionGlasses.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.additionGlasses.lockedUnselectMessage')
          },
          homeAssistance: {
            requiredAtLeastOneFrom: ['houseFromFire', 'antiqueFromFire', 'equipmentFromFire', 'outbuildingsFromFire', 'estateArchitectureFromFire', 'houseFromSteal', 'antiqueFromSteal', 'equipmentFromSteal', 'estateArchitectureFromNormalSteal', 'houseFromVadalism', 'electricalEquipmentFromSurge', 'houseFromFrost', 'glassFromBreak'],
            forcedSelectMessage: self.translate('growl.homeAssistance.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.homeAssistance.lockedUnselectMessage')
          },
          sosAssistance: {
            requiredAtLeastOneFrom: ['houseFromFire', 'antiqueFromFire', 'equipmentFromFire', 'outbuildingsFromFire', 'estateArchitectureFromFire', 'houseFromSteal', 'antiqueFromSteal', 'equipmentFromSteal', 'estateArchitectureFromNormalSteal', 'houseFromVadalism', 'electricalEquipmentFromSurge', 'houseFromFrost', 'glassFromBreak'],
            forcedSelectMessage: self.translate('growl.sosAssistance.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.sosAssistance.lockedUnselectMessage')
          },
          additionOuzKom: {
            requiredAtLeastOneFrom: ['ocKomBase', 'acKomAllRisk', 'acKomNamedRisks', 'acKomTotalLoss'],
            forcedSelectMessage: self.translate('growl.additionOuzKom.forcedSelectMessage'),
            lockedUnselectMessage: self.translate('growl.additionOuzKom.lockedUnselectMessage')
          }
        };

        /**
         * Dostępność pól z interfejsu w płaskiej liście
         * @type {Object}
         */
        var PRODUCT_CONFIG = {
          /**
           * komunikacja
           */
          ocKomBase: { // Obowiązkowe ubezpieczenie OC posiadaczy pojazdów mechanicznych
            ock: {
              risk: 'OC'
            },
            e1: {
              variant: 1,
              risk: 'OC'
            },
            e7: {
              variant: 1,
              risk: 'OC'
            },
            mf: {
              variant: 1,
              risk: 'OC'
            }
          },
          acKomNamedRisks: { // kompleksowa ochrona - wszystkie rodzaje uszkodzeń, kradzież auta lub jego elementów - ryzyka nazwane
            e7: {
              variant: 2,
              risk: 'AC'
            },
            mf: {
              variant: 2,
              risk: 'AC'
            }
          },
          acKomTotalLoss: { // tylko całkowita utrata pojazdu (np. kradzież auta lub szkoda całkowita)
            e7: {
              variant: 1,
              risk: 'AC'
            },
            mf: {
              variant: 1,
              risk: 'AC'
            }
          },
          acKomAllRisk: { // kompleksowa ochrona - wszystkie rodzaje uszkodzeń, kradzież auta lub jego elementów - all risk
            e7: {
              variant: 3,
              risk: 'AC'
            },
            mf: {
              variant: 3,
              risk: 'AC'
            }
          },
          assistanceTowingAfterClaim: { // holowanie pojazdu po szkodzie
            e1: {
              variant: 2,
              risk: 'OC'
            },
            e7: {
              variant: 2,
              risk: 'OC'
            },
            mf: {
              variant: 2,
              risk: 'OC'
            }
          },
          assistanceReplacementCarAfterClaim: { // pojazd zastępczy po szkodzie
            e1: {
              variant: 3,
              risk: 'OC'
            },
            e7: {
              variant: 3,
              risk: 'OC'
            },
            mf: {
              variant: 3,
              risk: 'OC'
            }
          },
          assistanceTowingAfterBreak: { // holowanie w przypadku awarii
            e7: {
              addition: {
                code: 'CAR_ASSISTANCE',
                variant: 2,
                variantCode: 'PREMIUM'
              }
            },
            mf: {
              addition: {
                code: 'CAR_ASSISTANCE',
                variantCode: 'AWARIA'
              }
            }
          },
          assistanceReplacementCarAfterBreak: { // pojazd zastępczy w przypadku awarii
            e7: {
              addition: {
                code: 'CAR_ASSISTANCE',
                variant: 3,
                variantCode: 'AWARIA'
              }
            },
            mf: {
              addition: {
                code: 'CAR_ASSISTANCE',
                variantCode: 'AWARIA'
              }
            }
          },
          nnwKip5: { // nnw kip  suma ubezpieczenia 5 000 zł
            e1: {
              risk: 'NNWKIP',
              variant: 1
            },
            e7: {
              risk: 'NNWKIP',
              variant: 1
            },
            mf: {
              risk: 'NNWKIP',
              variant: 1
            }
          },
          nnwKip15: { // nnw kip  suma ubezpieczenia 15 000 zł
            e1: {
              risk: 'NNWKIP',
              variant: 2
            },
            e7: {
              risk: 'NNWKIP',
              variant: 2
            },
            mf: {
              risk: 'NNWKIP',
              variant: 2
            }
          },
          nnwKip30: { // nnw kip  suma ubezpieczenia 30 000 zł
            e1: {
              risk: 'NNWKIP',
              variant: 3
            },
            e7: {
              risk: 'NNWKIP',
              variant: 3
            },
            mf: {
              risk: 'NNWKIP',
              variant: 3
            }
          },
          nnwKip60: { // nnw kip  suma ubezpieczenia 60 000 zł
            e7: {
              risk: 'NNWKIP',
              variant: 3
            },
            mf: {
              risk: 'NNWKIP',
              variant: 3
            }
          },
          additionGlasses: { // ubezpieczenie szyb – pokrycie kosztów naprawy bądź wymiany uszkodzonej szyby bez wpływu na poziom zniżek
            e1: {
              variant: 2,
              risk: 'OC'
            },
            e7: {
              variant: 2,
              risk: 'OC'
            },
            mf: {
              variant: 2,
              risk: 'OC'
            }
          },
          additionOuzKom: { // ochrona utraty zniżek – zapewnia zachowanie wypracowanego poziomu zniżek mimo wystąpienia szkody i wypłaty odszkodowania
            e1: {
              variant: 3,
              risk: 'OC'
            },
            e7: {
              variant: 3,
              risk: ['OC', 'AC'],
              addition: {
                code: 'OUZ',
                riskVariants: {
                  OC: 'OC',
                  AC: 'AC'
                }
              }
            },
            mf: {
              variant: 3,
              risk: ['OC', 'AC'],
              addition: {
                code: 'OUZ',
                riskVariants: {
                  OC: 'OC',
                  AC: 'AC'
                }
              }
            }
          },
          /**
           * mienie
           */
          houseFromFire: { // Dom / mieszkanie od zdarzeń losowych
            md: {
              risk: 'OGIEN'
            },
            e1: {
              risk: 'OGIEN',
              variant: 1
            },
            e7: {
              risk: 'OGIEN',
              variant: 1
            },
            mf: {
              risk: 'OGIEN',
              variant: 1
            }
          },
          outbuildingsFromFire: { // Budynki gospodarcze od zdarzeń losowych
            e1: {
              risk: 'OGIEN',
              variant: 1
            },
            e7: {
              risk: 'OGIEN',
              variant: 1
            },
            mf: {
              risk: 'OGIEN',
              variant: 1
            }
          },
          equipmentFromFire: { // Wyposażenie i rzeczy osobiste od zdarzeń losowych
            e1: {
              risk: 'OGIEN',
              variant: 3
            },
            e7: {
              risk: 'OGIEN',
              variant: 2
            },
            mf: {
              risk: 'OGIEN',
              variant: 2
            }
          },
          electricalEquipmentFromSurge: { // Urządzenia elektryczne (w tym RTV/AGD) od skutków przepięcia
            e7: {
              risk: 'OGIEN',
              variant: 2
            },
            mf: {
              risk: 'OGIEN',
              variant: 2
            }
          },
          houseFromFrost: { // Dom / mieszkanie od pękania mrozowego
            e7: {
              risk: 'OGIEN',
              variant: 3
            },
            mf: {
              risk: 'OGIEN',
              variant: 2
            }
          },
          glassFromBreak: { // Oszklenia od pęknięć i stłuczeń
            e7: {
              risk: 'OGIEN',
              variant: 2
            },
            mf: {
              risk: 'OGIEN',
              variant: 2
            }
          },
          estateArchitectureFromFire: { // Architektura posesji od zdarzeń losowych
            e7: {
              risk: 'OGIEN',
              variant: 3
            },
            mf: {
              risk: 'OGIEN',
              variant: 1
            }
          },
          antiqueFromFire: { // Antyki i kolekcje od zdarzeń losowych
            e7: {
              risk: 'OGIEN',
              variant: 3
            },
            mf: {
              risk: 'OGIEN',
              variant: 1
            }
          },
          houseFromSteal: { // Dom / mieszkanie od kradzieży (np. elementów wykończenia, instalacji elektrycznej, grzejników czy stolarki okiennej)
            e7: {
              risk: 'KRADZIEZ',
              variant: 1
            },
            mf: {
              risk: 'KRADZIEZ',
              variant: 1
            }
          },
          equipmentFromSteal: { // Wyposażenie (meble, duże AGD) i rzeczy osobiste od kradzieży z włamaniem lub rabunku
            e7: {
              risk: 'KRADZIEZ',
              variant: 2
            },
            mf: {
              risk: 'KRADZIEZ',
              variant: 2
            }
          },
          houseFromVadalism: { // Dom / mieszkanie, wyposażenie i rzeczy osobiste od aktów wandalizmu
            e7: {
              risk: 'KRADZIEZ',
              variant: 2
            },
            mf: {
              risk: 'KRADZIEZ',
              variant: 2
            }
          },
          estateArchitectureFromNormalSteal: { // Elementy architektury posesji od kradzieży zwykłej
            e7: {
              risk: 'KRADZIEZ',
              variant: 2
            },
            mf: {
              risk: 'KRADZIEZ',
              variant: 3
            }
          },
          antiqueFromSteal: { // Dzieła sztuki, antyki i kolekcje od kradzieży z włamaniem lub rabunku
            e7: {
              risk: 'KRADZIEZ',
              variant: 3
            },
            mf: {
              risk: 'KRADZIEZ',
              variant: 2
            }
          },
          sosAssistance: { // SOS Assistance – bezzwłoczne zapewnienie lokalu zastępczego i środków do życia w wyniku utraty dachu nad głową
            e7: {
              risk: ['OGIEN', 'KRADZIEZ'],
              addition: {
                code: 'SOS_ASSISTANCE'
              }
            },
            mf: {
              risk: ['OGIEN', 'KRADZIEZ'],
              addition: {
                code: 'SOS_ASSISTANCE'
              }
            }
          },
          homeAssistance: { // Home Assistance – zapewnienie pomocy specjalisty (ślusarza, hydraulika, elektryka itd.) w przypadku awarii
            e7: {
              risk: ['OGIEN', 'KRADZIEZ'],
              addition: {
                code: 'HOME_ASSISTANCE'
              }
            },
            mf: {
              risk: ['OGIEN', 'KRADZIEZ'],
              addition: {
                code: 'HOME_ASSISTANCE'
              }
            }
          },
          /**
           * ubezpieczenia osobowe
           */
          oczpPropertyOwner: { // OC właściciela / użytkownika nieruchomości (np. szkody powstałe w wyniku nieodśnieżonego chodnika czy oderwanej dachówki, zalanie sąsiada)
            e1: {
              risk: 'OCZP',
              variant: 1
            },
            e7: {
              risk: 'OCZP',
              variant: 1
            },
            mf: {
              risk: 'OCZP',
              variant: 2
            }
          },
          oczpKids: { // Szkody wyrządzone przez dzieci będące pod opieką ubezpieczonego
            e1: {
              risk: 'OCZP',
              variant: 3
            },
            e7: {
              risk: 'OCZP',
              variant: 1
            },
            mf: {
              risk: 'OCZP',
              variant: 3
            }
          },
          oczpAnimals: { // Szkody wyrządzone przez zwierzęta ubezpieczonego
            e1: {
              risk: 'OCZP',
              variant: 3
            },
            e7: {
              risk: 'OCZP',
              variant: 1
            },
            mf: {
              risk: 'OCZP',
              variant: 3
            }
          },
          oczpHigherSum200: { // Podwyższona suma gwarancyjna (200 000 zł)
            e7: {
              risk: 'OCZP',
              variant: 1
            },
            mf: {
              risk: 'OCZP',
              variant: 3
            }
          },
          oczpHigherSum500: { // Podwyższona suma gwarancyjna (500 000 zł)
            e7: {
              risk: 'OCZP',
              variant: 2
            },
            mf: {
              risk: 'OCZP',
              variant: 3
            }
          },
          nnwDeath: { // świadczenia z tytułu zgonu lub trwałego uszczerbku na zdrowiu
            e7: {
              risk: 'NNW',
              variant: 1
            },
            mf: {
              risk: 'NNW',
              variant: 1
            }
          },
          nnwStroke: { // świadczenia z tytułu wystąpienia zawału serca lub udaru mózgu
            e7: {
              risk: 'NNW',
              variant: 2
            },
            mf: {
              risk: 'NNW',
              variant: 2
            }
          },
          nnwHospitalCosts: { // pokrycie kosztów leczenia i świadczenia szpitalne
            e7: {
              risk: 'NNW',
              variant: 2
            },
            mf: {
              risk: 'NNW',
              variant: 2
            }
          },
          nnwInabilityToWork: { // świadczenia z tytułu utraty zdolności do wykonywania zawodu
            e7: {
              risk: 'NNW',
              variant: 3
            },
            mf: {
              risk: 'NNW',
              variant: 3
            }
          },
          nnwRehabilitation: { // zapewnienie finansowania rehabilitacji po nieszczęśliwym wypadku w renomowanych placówkach
            es: {
              risk: 'REHABILITATION',
              variant: 2
            }
          },
          /**
           * działalność gospodarcza
           */
          ocz: { // Obowiązkowe ubezpieczenie lekarzy, architektów i projektantów, pośredników i zarządców  nieruchomości, inżynierów budowlanych i rzeczoznawców majątkowych
            ocz: {
              risk: 'OCZP'
            },
            mf: {
              risk: 'OCZP',
              variant: 1
            }
          },
          ocActivity: { // OC prowadzonej działalności  (objęcie ochroną prowadzonej działalności - np. szkody związane z wyprodukowaniem wadliwego produktu)
            mf: {
              risk: 'OCZP',
              variant: 3
            }
          },
          activityVehicle: { // Objęcie ochroną pojazdu wykorzystywanego w związku z prowadzoną działalnością
            mf: {}
          },
          activityEstate: { // Objęcie ochroną lokalu użytkowanego w związku z prowadzoną działalnością
            mf: {}
          }
        };

        /**
         * Ogarnianie sytuacji w których zaznaczenie jednego checkboxa
         * powoduje zmianę zaznaczenia innych
         * @param {object} dataSet (obiekt będzie zmieniany dzięki referecji)
         * @param {string} changedCode kod zmienionego elementu
         * @return {undefied}
         */
        this.correctRangeData = function(dataSet, changedCode) {
          // w poniższych tablicach ważna jest kolejność
          var rangeTables = {
            assistanceKom: ['assistanceTowingAfterClaim', 'assistanceReplacementCarAfterClaim', 'assistanceTowingAfterBreak', 'assistanceReplacementCarAfterBreak'],
            oczp: ['oczpPropertyOwner', 'oczpKids', 'oczpAnimals', 'oczpHigherSum200', 'oczpHigherSum500'],
            nnw: ['nnwDeath', 'nnwStroke', 'nnwHospitalCosts', 'nnwInabilityToWork', 'nnwRehabilitation']
          };
          var hasBeenSelected = dataSet[changedCode];
          angular.forEach(rangeTables, function(rangeTable) {
            var changedIndex = rangeTable.indexOf(changedCode);
            if (changedIndex > -1) {
              angular.forEach(rangeTable, function(dataSetCode, rangeIndex) {
                if (rangeIndex < changedIndex && hasBeenSelected) {
                  dataSet[dataSetCode] = true;
                } else if (rangeIndex > changedIndex && !hasBeenSelected) {
                  dataSet[dataSetCode] = false;
                }
              });
            }
          });
        };

        /**
         * Zwracamy tablicę prouktów, które spełniają minimalne wymagania zakresu ochrony
         * zawsze 1 element + ewentualnie sport
         * @param  {[type]} dataSet zaznaczone ryzyka ({activityVehicle: true, ...})
         * @return {array}         (['mf'] | ['mf', 'es'])
         */
        this.getProductsForDataSet = function(dataSet) {
          // wstępnie określamy wszystkie produkty, jako spełniające wymagania
          var availableProducts = angular.copy(AVAILABLE_PRODUCTS),
            availableProductsTmp = angular.copy(AVAILABLE_PRODUCTS),
            productVariants = {},
            addEs = false;

          angular.forEach(AVAILABLE_PRODUCTS, function(product) {
            productVariants[product] = {
              risks: [],
              additions: {},
              productCode: product
            };
          });

          // wykluczamy produkty ze względu na zakres ochrony
          angular.forEach(dataSet, function(isSelected, dataCode) {
            if (isSelected && dataCode === 'nnwRehabilitation') {
              addEs = true;
            } else {
              angular.forEach(availableProducts, function(product) {
                var config = PRODUCT_CONFIG[dataCode][product];
                if (isSelected && !angular.isObject(config)) {
                  // wycinami produkty, które nie spełniają wymagań
                  availableProductsTmp.splice(availableProductsTmp.indexOf(product), 1);
                } else if (isSelected && angular.isObject(config)) {
                  if (config.risk) {
                    self._addRiskToArray(productVariants[product].risks, config.risk, config.variant);
                  }
                  if (config.addition) {
                    var currentAddition = productVariants[product].additions[config.addition.code];
                    if (!currentAddition || currentAddition.variant <= config.addition.variant) {
                      productVariants[product].additions[config.addition.code] = config.addition;
                    }
                    if (!productVariants[product].additions[config.addition.code]) {
                      productVariants[product].additions[config.addition.code] = config.addition;
                    }
                  }
                }
              });
              availableProducts = angular.copy(availableProductsTmp);
            }
          });

          // wykluczamy produkty względem priorytetu (na pozycji zerowej jest aplikacja o najwyższym priorytecie)
          var proposedProducts = [
            productVariants[availableProducts[0]]
          ];

          // Jeśli mamy więcej niż jedno ryzyko do wyboru, to trzeba jedno wybrać (np. risk: ['OGIEN', 'KRADZIEZ'])
          angular.forEach(proposedProducts, function(proposedProduct) {
            var simpleRisks = {};
            var arrayRisks = [];

            // dzielimy ryzyka na te, które są pewne i te, w których wystarczy jedno z kilku
            angular.forEach(proposedProduct.risks, function(riskObj) {
              if (angular.isArray(riskObj.code)) {
                arrayRisks.push(riskObj);
              } else if (riskObj.code) {
                simpleRisks[riskObj.code] = riskObj;
              }
            });
            // wybieramy ryzyka, w których mamy wybór
            angular.forEach(arrayRisks, function(arrayRisk) {
              var codeChosen = false;
              angular.forEach(arrayRisk.code, function(code) {
                if (simpleRisks[code]) {
                  codeChosen = true;
                }
              });
              if (!codeChosen) {
                simpleRisks[arrayRisk.code[0]] = {
                  code: arrayRisk.code[0]
                };
              }
            });

            // przepisujemy te ryzyka w odpowiednie miejsce
            proposedProduct.risks = [];
            angular.forEach(simpleRisks, function(risk) {
              proposedProduct.risks.push(risk);
            });

            // przepisujemy dodatki na tablicę (potem można tu dodać priorytet na potrzeby wyświetlania)
            var additions = [];
            angular.forEach(proposedProduct.additions, function(addition) {
              if (addition.riskVariants) {
                // przedrostki ryzyka, np. dla OUZ (OUZ_AC, OUZ_OC)
                angular.forEach(addition.riskVariants, function(riskCode, additionRiskCode) {
                  additions.risks = additions.risks ? additions.risks : [];
                  additions.risks.push(additionRiskCode);
                });
              }
              additions.push(addition);
            });
            proposedProduct.additions = additions;
          });

          if (addEs) {
            // dodatkowo es, jeśli jest
            proposedProducts.push(productVariants.es);
          }

          return proposedProducts;
        };

        /**
         * Dodawanie ryzyka z konfiguracji do zadanej tablicy ryzyk
         * funkcja pomocnicza do getProductsForDataSet
         * @param {Array} risks    tablica ryzyk
         * @param {String|String[]} riskCode kod lub tablica kodów ryzyk
         * @param {int|undefined} variant numer wariantu
         *
         * @return {Array} uzupełnione ryzyka z wejścia
         */
        this._addRiskToArray = function(risks, riskCode, variant) {
          var riskAdded = false;
          angular.forEach(risks, function(riskObj) {
            if (angular.equals(riskObj.code, riskCode)) {
              riskAdded = true;
            }
            if (riskAdded && variant && riskObj.variant) {
              riskObj.variant = Math.max(variant, riskObj.variant);
            } else if (riskAdded && variant) {
              riskObj.variant = variant;
            }
          });
          if (!riskAdded) {
            var newRiskObj = {
              code: riskCode
            };
            if (variant) {
              newRiskObj.variant = variant;
            }
            risks.push(newRiskObj);
          }

          return risks;
        };

        /**
         * Transponuje dane do liczby (numer konfigurcji)
         * @param  {Object} dataSet dane z konfiguratora
         * @return {int}         dataSet jako liczba
         */
        this.convertDataSetToNumber = function(dataSet) {
          var bin = '';
          angular.forEach(BITMAP, function(dataSetCode) {
            bin = bin + (dataSet[dataSetCode] ? '1' : '0');
          });

          return parseInt(bin, 2);
        };

        /**
         * Mapuje numer kalkulacji na obiekt z danymi
         * @param  {Number|String} dataSetNumber numer konfiguracji (z zaszytymi danymi)
         * @return {Object}               dataSet
         */
        this.convertDataSetNumberToObject = function(dataSetNumber) {
          var dataSet = {};
          if (angular.isString(dataSetNumber)) {
            dataSetNumber = parseInt(dataSetNumber, 10);
          }
          var bin = dataSetNumber.toString(2);
          // dla bitów zera wiodące są istotne
          bin = bin.padLeft('0', BITMAP.length);
          angular.forEach(bin, function(bit, bitIndex) {
            if (bit === '1') {
              dataSet[BITMAP[bitIndex]] = true;
            }
          });

          return dataSet;
        };

      };

      return new IkonfiguratorHelper();
    }]);