angular.module('ihestiaWidgets.policyDetails')
  .factory('LsnAddressModelV3', ['LsnAbstractModel', 'LsnAddressModelConstants',
    function(LsnAbstractModel, LsnAddressModelConstants) {

      var LsnAddressModelV3 = function() {
        this.objectName = 'Address';
        this.modelVersion = 'v3';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          addressTypeCode: {
            type: 'string',
            defaultValue: LsnAddressModelConstants.ADDRESS_TYPE_STAL,
            dictionary: LsnAddressModelConstants.DICTIONARY_ADDRESS_TYPE
          },
          postalCode: {
            type: 'string'
          },
          post: {
            type: 'string'
          },
          city: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          streetPrefix: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          street: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          houseNumber: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          apartmentNumber: {
            type: 'string',
            filters: [
              {
                name: 'strUpperCase'
              },
              {
                name: 'strTrim'
              }
            ]
          },
          region: {
            type: 'string'
          },
          district: {
            type: 'string'
          },
          commune: {
            type: 'string'
          },
          countryCode: {
            type: 'string',
            defaultValue: 'PL'
          }
        };

        this.addressTypeCode = LsnAddressModelConstants.ADDRESS_TYPE_STAL; // string, stal/kore/tymc/dzia (stały (Dla tego typu nie trzeba uzupełniać pola)/korespondencyjny/tymczasowy/adr. działalności)
        this.postalCode = null; // string, kod pocztowy
        this.post = null; // string, Poczta. Pole ma być niewypełniane jeśli jest tożsame z city
        this.city = null; // string, miejscowość
        this.streetPrefix = null; // string, przedrostek ulicy
        this.street = null; // string, ulica
        this.houseNumber = null; // string, numer domu
        this.apartmentNumber = null; // string, numer mieszkania
        this.region = null; // string, województwo
        this.district = null; // string, powiat
        this.commune = null; // string, gmina
        this.countryCode = null; // string, Kod kraju (2 znaki). Wartość domyśl-na: PL. Pole powinno być wypełniane tylko jeśli wartość jest inna.

      };

      LsnAddressModelV3.prototype = LsnAbstractModel.prototype;

      return LsnAddressModelV3;
    }]);
