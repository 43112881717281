angular.module('ihestiaSsoBase.baseInfo')

  .factory('ihestiaSsoReloggedUserCharactersSvc', [ 'abstractPlatformSessionSvc',
    function(abstractPlatformSessionSvc) {

    var ReloggedUserCharactersSvc = function()
    {
      abstractPlatformSessionSvc.apply(this, arguments);

      this.resource = 'reloggeduser/characters';

    };

    return new ReloggedUserCharactersSvc();
  }])
  ;