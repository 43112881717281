angular.module('ihestiaGridSettings')
  .controller('ihestiaGridSettingsCtrl', ['$scope', '$rootScope', 'ihestiaGridSettingsHelper', 'iHestiaCommonModalHelper', 'ihestiaGridSettingsContextSvc', '$timeout', 'ihestiaGridSettingsGroupSvc', '$q', 'ihestiaSsoBaseInfoHelper', '$filter', 'ihestiaCommonErrorHandler', 'labelActionHelper',
    function ($scope, $rootScope, ihestiaGridSettingsHelper, iHestiaCommonModalHelper, ihestiaGridSettingsContextSvc, $timeout, ihestiaGridSettingsGroupSvc, $q, ihestiaSsoBaseInfoHelper, $filter, ihestiaCommonErrorHandler, labelActionHelper) {
      /**
       * aktualny konfig sidebara
       * @type IHestiaGridSettingsDataModel
       */
      $scope.config = ihestiaGridSettingsHelper.globalConfig;
      $scope.isUserRelogged = ihestiaSsoBaseInfoHelper.isUserRelogged();

      $scope.salesNetworksCancel = $q.defer(); //promise do anulowania requestów do sieci sprzedażowych

      $scope.amountSettings = {
        decimal: '',
        separator: '',
        negative: false,
        absolute: true,
        allowBeginZero: false
      };

      $scope.modals = {
        saveSettings: {
          settings: {
            treatLabelAsI18nCode: true,
            title: ['Public.saveNewFilter', {componentCode: 'Public'}],
            okBtnName: ['Public.save', {componentCode: 'Public'}],
            cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
            size: 'sm'
          },
          name: '',
          visible: false //chcemy wiedzieć żeby nie zwijać settingsów po kliknięciu w modal
        },
        removeSettings: {
          settings: {
            treatLabelAsI18nCode: true,
            title: ['Public.deleteFilter', {componentCode: 'Public'}],
            okBtnName: ['Public.delete', {componentCode: 'Public'}],
            cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
            size: 'sm'
          },
          visible: false //chcemy wiedzieć żeby nie zwijać settingsów po kliknięciu w modal
        }
      };

      $scope.selectedFilter = null; //filtr wybrany w select
      $scope.currentFilter = ihestiaGridSettingsHelper.currentFilter; //filtr w którym trzymane są wartości z interfejsu

      //widoczność zakładek
      $scope.tabs = {
        filters: true,
        columns: false,
        other: false
      };

      $scope.recordsNumberSelection = [50, 100, 150];

      $scope.salesNetworksToSelect = {
        subjectKeysIcOrganisationUnitFilter: null,
        subjectKeysSalesProfileFilter: null,
        subjectKeysWorkerFilter: null
      };

      $scope.salesNetworksToReload = {
        organizationUnits: true,
        salesProfiles: true,
        workers: true
      };

      $scope.serviceRunning = {
        IcOrganisation: false,
        SalesProfile: false,
        Worker: false
      };

      //domyślnie sieci sprzedażowe wszystkie i do wyboru wiele elementów
      $scope.defaultSalesNetworksConfig = {
        organizationUnits: {
          disabled: false,  //wyłaczone
          singleValue: false, //tylko jedna wartość na raz
          canEmpty: false     //czy może być puste, a mimo wszystko agencje będzie można wybrać
        },
        salesProfiles: {
          disabled: false,
          singleValue: false,
          canEmpty: false
        },
        workers: {
          disabled: false,
          singleValue: false
        }
      };

      $scope.salesNetworksConfig = angular.copy($scope.defaultSalesNetworksConfig);

      $scope.$on('$stateChangeSuccess', function() {
        $scope.prepareTabs();
      });

      $scope.otherParams = {
        loadingFilter: false
      };

      /**
       * przygotowujemy listę zakłdek w filtrach
       * @return {[type]} [description]
       */
      $scope.prepareTabs = function()
      {
        $scope.tabs = {
          other: false
        };

        if($scope.config.currentData && $scope.config.currentData.multipleFilterTabs) //mamy wiele zakładek filtrów
        {
          if(angular.isUndefined($scope.config.currentData.filterTabs))
          {
            ihestiaCommonErrorHandler.throwException({
              message: 'No "filterTabs" definition. Required for "multipleFilterTabs"'
            });
          }
          var expandFilter = true;
          angular.forEach($scope.config.currentData.filterTabs, function(filterTab){
            $scope.tabs[filterTab.code] = expandFilter;
            expandFilter = false;
          });
        }
        else
        {
          $scope.tabs.filters = true;
        }
        if($scope.config.currentData)
        {
          if(angular.isUndefined($scope.config.currentData.columnTabs) || $scope.config.currentData.columnTabs.length === 0)
          {
            $scope.config.currentData.columnTabs = [{
              nameLabelCode: ['Public.columns', {componentCode: 'Public'}],
              code: 'base'
            }];
            labelActionHelper.registerAction('gridSettingsBaseTab', $scope.refreshBaseTabLabel);
          }
          angular.forEach($scope.config.currentData.columnTabs, function(columnTab){
            $scope.tabs[columnTab.code] = false;
          });
        }
        else
        {
          $scope.tabs.columns = true;
        }
      };

      $scope.refreshBaseTabLabel = function()
      {
        $scope.config.currentData.columnTabs[0].nameLabelCode = ['Public.columns', {componentCode: 'Public'}];
      };

      /**
       * rozwiązujemy promise podpięty pod wyszukiwania (multiselecty) żeby anulować requesty
       */
      $scope.cancelSalesNetworksSearch = function(reason){
        $scope.salesNetworksCancel.resolve(reason);
        $scope.salesNetworksCancel = $q.defer(); //tworzymy nowy promise dla kolejnych requestów
      };

      $scope.hideSettings = function()
      {
        if(!$scope.config.visible || $scope.modals.saveSettings.visible) //jeśli nie jest pokazany settings albo jest modal settingsów
        {
          return;
        }
        ihestiaGridSettingsHelper.hideGridSettings();
      };

      /**
       * mamy nowy filtr (zapisany) załadowny po odświeżeniu listy
       */
      $scope.$on('gridSettingsNewLoaded', function($event, serviceSetting){
        $scope.selectedFilter = serviceSetting;
      });

      /**
       * zmiana configa, przykładowo zmiana strony/stanu, więc przerywamy requesty
       */
      $scope.$on('gridConfigChanged', function(){
        $scope.cancelSalesNetworksSearch('configChanged');
      });

      /**
       * Jak już mamy config i settingsy to ładujemy wybrany filtr
       */
      $scope.$on('gridSettingsConfigLoaded', function(){ //po załadowaniu filtrów wciągamy ten zaznaczony
        var salesNetworksConfig = {};
        if($scope.config.currentData.salesNetworksConfig)
        {
          angular.extend(salesNetworksConfig, angular.copy($scope.defaultSalesNetworksConfig), angular.copy($scope.config.currentData.salesNetworksConfig));
        }
        else
        {
          angular.extend(salesNetworksConfig, angular.copy($scope.defaultSalesNetworksConfig));
        }

        $scope.salesNetworksConfig = salesNetworksConfig;
        $scope.parseSalesNetworksConfig();

        $scope.prepareTabs();

        if($scope.config.currentData.showOnlyColumnSettings)
        {
          $scope.toggleTab($scope.config.currentData.columnTabs[0].code);
        }
        
        if ($scope.config.currentData.defaultFilterConditions) {
          var matchingFilter = $scope.config.settingsList.find(function(filter) { 
            return Object.keys($scope.config.currentData.defaultFilterConditions).every(function(key) {
              return $scope.config.currentData.defaultFilterConditions[key] === filter[key];
            });
          });
          if (matchingFilter) {
            $scope.config.currentData.defaultFilterId = matchingFilter.id;
          }
        }

        $scope.selectedFilter = null;
        angular.forEach($scope.config.settingsList, function(filter){
          if($scope.config.currentData.defaultFilterId) {
            // wymuszone ustawienie konkretnego filtru
            if(filter.id === $scope.config.currentData.defaultFilterId) {
              $scope.selectedFilter = filter;
              filter.isSelected = true;
            } else {
              filter.isSelected = false;
            }
          } else if(!$scope.selectedFilter && filter.isSelected) {
            $scope.selectedFilter = filter;
          }
        });
        if(!$scope.selectedFilter)
        {//żaden z filtrów nie jest wybrany (chyba nie powinno tak się zdażyć, ale mock usługi tak zwraca)
          $scope.selectedFilter = $scope.config.settingsList[0];
        }

        $scope.selectedFilterChanged();

        $scope.config.currentFilterReady = true;
      });

      /**
       * przygotowujemy atrybuty dla multiselectów
       */
      $scope.parseSalesNetworksConfig = function()
      {
        if($scope.config.currentData.showSalesNetworks)
        {
          angular.forEach($scope.salesNetworksConfig, function(config, configKey){
            config.canSelectAll = !config.singleValue;
            if(config.singleValue)
            {
              config.outputProperties = 'subjectKeyString';
            }
            else if(typeof config.outputProperties !== 'undefined')
            {
              delete config.outputProperties;
            }

            var serviceConfigKey = ihestiaGridSettingsHelper.salesNetworksConfigToRestMap[configKey];
            if($scope.config.salesNetworks[serviceConfigKey] && $scope.config.salesNetworks[serviceConfigKey].maxNumberOfItemsToSelection)
            {
              if($scope.config.salesNetworks[serviceConfigKey].maxNumberOfItemsToSelection === 1)
              {
                config.singleValue = true;
                config.multipleOutput = true;
                config.canSelectAll = false;
              }
              else if($scope.config.salesNetworks[serviceConfigKey].maxNumberOfItemsToSelection > 1)
              {
                config.singleValue = false;
              }
            }
          });
        }
      };

      /**
       * przy zmianie wybranego z listy filtra ładujemy go do filtra edytowanego
       */
      $scope.selectedFilterChanged = function(){
        if($scope.selectedFilter !== null)
        {
          if($scope.config.currentData.showSalesNetworks)
          {
            $scope.salesNetworksToReload = {
              organizationUnits: true,
              salesProfiles: true,
              workers: true
            };
          }

          $scope.setCurrentFilter($scope.selectedFilter);
        }
      };

      /**
       * ustawia zaznaczenie w items na klucze z selection
       * @param {array} items
       * @param {array} selected
       */
      $scope.setSalesNetworkSelection = function(items, selection)
      {
        angular.forEach(items, function(item){
          if(selection.indexOf(item.subjectKeyString) !== -1)
          {
            item.selected = true;
          }
          else
          {
            item.selected = false;
          }
        });
      };

      /**
       * Ustawia podany filtr jako edytowany
       * @param {object} filter [description]
       */
      $scope.setCurrentFilter = function(filter){

        $scope.otherParams.loadingFilter = true;

        if($scope.config.currentData.showSalesNetworks)
        {
          $scope.config.salesNetworks = angular.copy($scope.config.salesNetworksCopy); //przywracamy pierwotny stan sieci sprzedaży
        }

        $scope.config.wasFilterToLoad = false; //ustawiamy jeśli jakiś kontroler w templatce będzie potrzebował takiej informacji

        if($scope.config.currentData.initialFilterExtension) { // mamy podany filtr, który ma być ustawiony i koniec tematu!
          $scope.config.filterToLoad = angular.copy(filter);
          angular.forEach($scope.config.currentData.initialFilterExtension, function(settings, settingsCode){
            $scope.config.filterToLoad.settings[settingsCode] = settings;
          });
          delete $scope.config.currentData.initialFilterExtension;
        }
        if($scope.config.filterToLoad) //mamy zachowany filtr który mamy załadować, więc ignorujemy ten z listy
        {
          $scope.config.wasFilterToLoad = true;
          $scope.currentFilter = angular.copy($scope.config.filterToLoad);
          $scope.config.previousViewFilter = null; //nie chcemy danych z poprzedniego widoku
        }
        else
        {
          $scope.currentFilter = angular.copy(filter);
        }

        ihestiaGridSettingsHelper.currentFilter = $scope.currentFilter;

        if(typeof $scope.currentFilter.settings === 'undefined')
        {
          $scope.currentFilter.settings = {};
        }
        if(typeof $scope.currentFilter.settings.filterSettings === 'undefined')
        {
          $scope.currentFilter.settings.filterSettings = {};
        }

        //sieci sprzedażowe START
        if($scope.config.currentData.showSalesNetworks)
        {
          if(typeof $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter = [];
          }
          if(typeof $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter = [];
          }
          if(typeof $scope.currentFilter.settings.filterSettings.subjectKeysWorkerFilter === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysWorkerFilter = [];
          }

          if($scope.currentFilter.isDefault || !$scope.currentFilter.canDelete) //przepisujemy domyślne dane sieci sprzedażowych
          {
            $scope.currentFilter.settings.filterSettings.organizationUnits = angular.copy($scope.config.salesNetworks.organizationUnits);
            $scope.currentFilter.settings.filterSettings.salesProfiles = angular.copy($scope.config.salesNetworks.salesProfiles);
            $scope.currentFilter.settings.filterSettings.workers = angular.copy($scope.config.salesNetworks.workers);

            if($scope.config.currentData.defaultFilterExtension && $scope.config.currentData.defaultFilterExtension.filterSettings)
            {
              angular.forEach($scope.config.currentData.defaultFilterExtension.filterSettings, function(value, key){
                $scope.currentFilter.settings.filterSettings[key] = value;
              });
            }
          }

          //jeśli na poprzednim widoku mieliśmy zaznaczenie to nadpisujemy domyślne wartości
          // ale tylko dla filtra domyślnego (IHESTIAOA-2576), w przypadku ręcznej zmiany filtra lub filtra ulubionego (gwiazdka) ignorujemy filtr z poprzedniego ekranu
          if($scope.config.previousViewFilter !== null && ($scope.currentFilter.isDefault || !$scope.currentFilter.canDelete))
          {
            //czyścimy domyślne dla tego widoku organizacje
            angular.forEach($scope.currentFilter.settings.filterSettings.organizationUnits, function(organization){
              if(angular.isDefined(organization.selected))
              {
                delete organization.selected;
              }
            });
            if($scope.config.previousViewFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter && $scope.config.previousViewFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter.length !== 0 && $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter.length === 0)
            {
              $scope.setSalesNetworkSelection($scope.currentFilter.settings.filterSettings.organizationUnits, $scope.config.previousViewFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter);
            }
            if($scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter && $scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length !== 0 && $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length === 0)
            {
              $scope.setSalesNetworkSelection($scope.currentFilter.settings.filterSettings.salesProfiles, $scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter);
            }
            if($scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter && $scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter.length !== 0 && $scope.currentFilter.settings.filterSettings.subjectKeysWorkerFilter.length === 0)
            {
              $scope.setSalesNetworkSelection($scope.currentFilter.settings.filterSettings.workers, $scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter);
            }
          }
          else if($scope.currentFilter.isDefault || !$scope.currentFilter.canDelete)
          {
            //ustawiamy dane dla niepobranych jeszcze list wartości domyślne
            if($scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'List' && $scope.config.salesNetworks.salesProfileUiConfig.loadDefault && $scope.config.salesNetworks.defaultSalesProfile && $scope.config.salesNetworks.salesProfiles.length === 0)
            {
              $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = [$scope.config.salesNetworks.defaultSalesProfile.subjectKeyString];
            }
            if($scope.config.salesNetworks.workerUiConfig.renderMode === 'List' && $scope.config.salesNetworks.workerUiConfig.loadDefault && $scope.config.salesNetworks.defaultWorker && $scope.config.salesNetworks.workers.length === 0)
            {
              $scope.salesNetworksToSelect.subjectKeysWorkerFilter = [$scope.config.salesNetworks.defaultWorker.subjectKeyString];
            }
          }

          if(typeof $scope.currentFilter.settings.filterSettings.organizationUnits === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.organizationUnits = [];
          }
          if(typeof $scope.currentFilter.settings.filterSettings.salesProfiles === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.salesProfiles = [];
          }
          if(typeof $scope.currentFilter.settings.filterSettings.workers === 'undefined')
          {
            $scope.currentFilter.settings.filterSettings.workers = [];
          }

          //dane z poprzedniego widoku dla niezaładowanych list
          //agencje
          if($scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'List' || $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Search')
          {
            //tu filtr wcześniej zachowany dla tego widoku
            if($scope.config.filterToLoad !== null &&
              $scope.config.filterToLoad.settings.filterSettings.subjectKeysSalesProfileFilter &&
              $scope.config.filterToLoad.settings.filterSettings.subjectKeysSalesProfileFilter.length !== 0)
            {
              $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = angular.copy($scope.config.filterToLoad.settings.filterSettings.subjectKeysSalesProfileFilter);
              //czyścimy stan obecny żeby watche nie zgłupiały
              $scope.currentFilter.settings.filterSettings.salesProfiles = [];
            }
            //a tu dane filtra z poprzedniego widoku
            else if($scope.config.previousViewFilter !== null &&
              ($scope.currentFilter.isDefault || !$scope.currentFilter.canDelete) &&
              $scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter &&
              $scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length !== 0)
            {
              $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = angular.copy($scope.config.previousViewFilter.settings.filterSettings.subjectKeysSalesProfileFilter);
              //czyścimy stan obecny żeby watche nie zgłupiały
              $scope.currentFilter.settings.filterSettings.salesProfiles = [];
            }
          }
          //pracownicy
          if($scope.config.salesNetworks.workerUiConfig.renderMode === 'List' || $scope.config.salesNetworks.workerUiConfig.renderMode === 'Search')
          {
            //tu filtr wcześniej zachowany dla tego widoku
            if($scope.config.filterToLoad !== null &&
              $scope.config.filterToLoad.settings.filterSettings.subjectKeysWorkerFilter &&
              $scope.config.filterToLoad.settings.filterSettings.subjectKeysWorkerFilter.length !== 0)
            {
              $scope.salesNetworksToSelect.subjectKeysWorkerFilter = angular.copy($scope.config.filterToLoad.settings.filterSettings.subjectKeysWorkerFilter);
              //czyścimy stan obecny żeby watche nie zgłupiały
              $scope.currentFilter.settings.filterSettings.workers = [];
            }
            //a tu dane filtra z poprzedniego widoku
            else if($scope.config.previousViewFilter !== null &&
              ($scope.currentFilter.isDefault || !$scope.currentFilter.canDelete) &&
              $scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter &&
              $scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter.length !== 0)
            {
              $scope.salesNetworksToSelect.subjectKeysWorkerFilter = angular.copy($scope.config.previousViewFilter.settings.filterSettings.subjectKeysWorkerFilter);
              //czyścimy stan obecny żeby watche nie zgłupiały
              $scope.currentFilter.settings.filterSettings.workers = [];
            }
          }

          //dane z settingsów dla niezaładowanych list
          if(typeof filter.settings.filterSettings !== 'undefined')
          {
            if(typeof filter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter !== 'undefined' &&
              angular.isArray(filter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter) &&
              filter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter.length > 0)
            {
              $scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter = filter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter;
              if(!$scope.salesNetworksConfig.organizationUnits.disabled)
              {
                $timeout(function(){
                  $scope.$broadcast('selectOrganizations');
                }, 0);
              }
            }
            if(typeof filter.settings.filterSettings.subjectKeysSalesProfileFilter !== 'undefined' &&
             angular.isArray(filter.settings.filterSettings.subjectKeysSalesProfileFilter) &&
              filter.settings.filterSettings.subjectKeysSalesProfileFilter.length > 0 &&
              ($scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'List' || $scope.config.salesNetworks.salesProfileUiConfig.renderMode === 'Search'))
            {
              $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = filter.settings.filterSettings.subjectKeysSalesProfileFilter;
              $scope.currentFilter.settings.filterSettings.salesProfiles = []; //czyścimy żeby nie było śmietnika póki nie załadujemy list
            }
            if(typeof filter.settings.filterSettings.subjectKeysWorkerFilter !== 'undefined' &&
             angular.isArray(filter.settings.filterSettings.subjectKeysWorkerFilter) &&
              filter.settings.filterSettings.subjectKeysWorkerFilter.length > 0 &&
              ($scope.config.salesNetworks.workerUiConfig.renderMode === 'List' || $scope.config.salesNetworks.workerUiConfig.renderMode === 'Search'))
            {
              $scope.salesNetworksToSelect.subjectKeysWorkerFilter = filter.settings.filterSettings.subjectKeysWorkerFilter;
              $scope.currentFilter.settings.filterSettings.workers = []; //czyścimy żeby nie było śmietnika póki nie załadujemy list
            }
          }


          if($scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter === null &&
           $scope.currentFilter.settings.filterSettings.organizationUnits.length === 0 &&
           $scope.config.salesNetworks.salesProfileUiConfig.renderMode !== 'Search' && !$scope.salesNetworksConfig.organizationUnits.canEmpty)
           //dla wyszukiwania agencji zakładamy, że może być agencja bez organizacji
          {
            //jeśli nie ma orgazniacji to czyścimy listę agencji i pracowników do zaznaczenia
            $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
            $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
          }

          //jak jakiś element jest wyłączony to czyścimy jego wartości
          if($scope.salesNetworksConfig.organizationUnits.disabled)
          {
            $scope.salesNetworksToReload.organizationUnits = false;
            $scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter = null;
            $scope.currentFilter.settings.filterSettings.organizationUnits = [];
          }
          if($scope.salesNetworksConfig.salesProfiles.disabled)
          {
            $scope.salesNetworksToReload.salesProfiles = false;
            $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter = null;
            $scope.currentFilter.settings.filterSettings.salesProfiles = [];
          }
          if($scope.salesNetworksConfig.workers.disabled)
          {
            $scope.salesNetworksToReload.workers = false;
            $scope.salesNetworksToSelect.subjectKeysWorkerFilter = null;
            $scope.currentFilter.settings.filterSettings.workers = [];
          }

          angular.forEach($scope.salesNetworksConfig, function(config, configKey){
            //jak mamy single value, to ucinamy wszystko poza jednym elementem
            if(config.singleValue)
            {
              var serviceConfigKey = ihestiaGridSettingsHelper.salesNetworksToSubjectKeyMap[configKey];
              if($scope.currentFilter.settings && $scope.currentFilter.settings.filterSettings &&
                angular.isArray($scope.currentFilter.settings.filterSettings[configKey]) && $scope.currentFilter.settings.filterSettings[configKey].length > 1)
              {
                //musimy coś wstawić dla selecta jako wybraną wartość
                //najpierw szukamy czy nie mam już czegoś ustawionego
                var elementToSelect = null;
                angular.forEach($scope.currentFilter.settings.filterSettings[configKey], function(element){
                  if(element.selected)
                  {
                    elementToSelect = element;
                  }
                });
                //jak nie to bierzemy pierwszą wartość
                if(elementToSelect === null)
                {
                  elementToSelect = $scope.currentFilter.settings.filterSettings[configKey][0];
                }
                $scope.currentFilter.settings.filterSettings[configKey].splice(0);
                $scope.currentFilter.settings.filterSettings[configKey].push(elementToSelect);
              }
              if($scope.salesNetworksToSelect && $scope.salesNetworksToSelect[serviceConfigKey] &&
                angular.isArray($scope.salesNetworksToSelect[serviceConfigKey]) && $scope.salesNetworksToSelect[serviceConfigKey].length > 1)
              {
                $scope.salesNetworksToSelect[serviceConfigKey].splice(1);
              }
            }
          });


        }
        //sieci sprzedażowe KONIEC
        else
        {
          if($scope.currentFilter.isDefault && $scope.config.currentData.defaultFilterExtension && $scope.config.currentData.defaultFilterExtension.filterSettings)
          {
            angular.forEach($scope.config.currentData.defaultFilterExtension.filterSettings, function(value, key){
              $scope.currentFilter.settings.filterSettings[key] = value;
            });
          }
        }

        //mamy nowy niezapisany filtr, ustawiamy mu standardowe atrybuty
        $scope.currentFilter.isDefault = false;
        $scope.currentFilter.isSelected = false;
        $scope.currentFilter.canDelete = true;
        delete $scope.currentFilter.id;

        $scope.checkEmptyValues();
        $scope.setMultiSelectsSelection();
        $scope.parseColumnSettings();
        if(typeof $scope.currentFilter.settings.otherSettings === 'undefined')
        {
          $scope.currentFilter.settings.otherSettings = {
            recordsPerPage: 50
          };
        }

        $scope.config.filterToLoad = null;
        $scope.config.previousViewFilter = null;

        $scope.checkIfFilterReady();
      };

      /**
       * dla multiselectów musimy w danych wejściowych oznaczyć wybrane
       */
      $scope.setMultiSelectsSelection = function()
      {
        angular.forEach($scope.config.currentData.dictionaries, function(dictionary, fieldName){
          if(typeof $scope.config.currentData.multipleValues !== 'undefined' && $scope.config.currentData.multipleValues[fieldName])
          {
            //multiselect
            //czyszczenie poprzedniego zaznaczenia
            angular.forEach(dictionary, function(dictionaryItem){
              if(dictionaryItem.selected)
              {
                delete dictionaryItem.selected;
              }
            });

            if($scope.currentFilter.settings.filterSettings[fieldName])
            {
              angular.forEach($scope.currentFilter.settings.filterSettings[fieldName], function(valueToSelect){
                angular.forEach(dictionary, function(dictionaryItem){
                  if(dictionaryItem.code === valueToSelect.code)
                  {
                    dictionaryItem.selected = true;
                  }
                });
              });
            }
          }
        });
      };

      /**
       * odpalane inicjalnie na ładowanie strony
       * sprawdza czy już wszystkie dane (sieci sprzedażowe) są już załadowane i filtry schowane jeśli tak to puszcza event żeby grid mógł się ładować
       */
      $scope.checkIfFilterReady = function()
      {
        //chcemy poczekać aż select ustawi też wartości w output model
        $timeout(function() {
          if($scope.ifSalesNetworksReady() &&
            !$scope.config.visible && !$scope.config.gridSettingsLoaded)
          {
            $scope.salesNetworksObjectsToSymbols();
            $scope.prepareActiveTab();
            $rootScope.$broadcast('gridSettingsLoaded', $scope.config.currentData.contextId);
            $scope.config.gridSettingsLoaded = true; //nie chcemy 2 razy puścić eventa
          }
          if( $scope.ifSalesNetworksReady( ) )
          {
            $scope.otherParams.loadingFilter = false;
          }
        }, 0);
      };

      /**
       * jeśli trzeba to ustawiamy aktywną zakładkę
       */
      $scope.prepareActiveTab = function()
      {
        if($scope.config.currentData.showSalesNetworks && !$scope.config.currentData.allowEmptySaleProfile &&
          $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.length === 0 && $scope.config.currentData.saleProfileTab) //brak agencji, więc nie możemy nic pobrać
        {
          //pokazujemy zakładkę z listą agencji
          $scope.toggleTab($scope.config.currentData.saleProfileTab);
        }
      };

      /**
       * czy sieci sprzedażowe załadowane
       * @return {bool}
       */
      $scope.ifSalesNetworksReady = function()
      {
        if(!$scope.config.currentData.showSalesNetworks)
        {
          return true;
        }
        return $scope.salesNetworksToSelect.subjectKeysIcOrganisationUnitFilter === null &&
            $scope.salesNetworksToSelect.subjectKeysSalesProfileFilter === null &&
            $scope.salesNetworksToSelect.subjectKeysWorkerFilter === null &&
            !$scope.serviceRunning.IcOrganisation &&
            !$scope.serviceRunning.SalesProfile &&
            !$scope.serviceRunning.Worker;
      };

      /**
       * parsujemy dane column z wczytanego filtra żeby zgadzał się z konfiguracją i naszą strukturą danych
       * @return {[type]} [description]
       */
      $scope.parseColumnSettings = function()
      {
        if(angular.isUndefined($scope.currentFilter.settings.columnSettings))
        {
          $scope.currentFilter.settings.columnSettings = {
            order: [],
            visibility: {}
          };
        }

        if($scope.currentFilter.settings.columnSettings.order && $scope.currentFilter.settings.columnSettings.order.length > 0)
        {
          angular.forEach($scope.config.currentData.columns, function(tabColumns){
            tabColumns.sort(function(aField, bField){
              var aFieldIndex = $scope.currentFilter.settings.columnSettings.order.indexOf(aField.valueSource);
              var bFieldIndex = $scope.currentFilter.settings.columnSettings.order.indexOf(bField.valueSource);
              // show columns that are not in grid settings at the end of the table
              var LAST_COLUMN_INDEX = 100;
              if(aFieldIndex < 0) {
                aFieldIndex = LAST_COLUMN_INDEX;
              }
              if(bFieldIndex < 0) {
                bFieldIndex = LAST_COLUMN_INDEX;
              }
              return aFieldIndex - bFieldIndex;
            });
          });
        }

        var parsedVisibility = {};
        angular.forEach($scope.config.currentData.columns, function(tabColumns){
          angular.forEach(tabColumns, function(value){
            if(typeof $scope.currentFilter.settings.columnSettings.visibility !== 'undefined' && angular.isObject($scope.currentFilter.settings.columnSettings.visibility[value.code]))
            {
              parsedVisibility[value.code] = $scope.currentFilter.settings.columnSettings.visibility[value.code];
            }
            else
            {
              parsedVisibility[value.code] = {
                selected: value.visible,
                disabled: value.disabled
              };
            }
          });
        });

        //wczelkie śmieci z usługi, nieaktualne dane chcemy wywalić, więc nadpisujemy cały obiekt
        $scope.currentFilter.settings.columnSettings.visibility = parsedVisibility;
      };

      /**
       * ustawienie liczy pobieranych rekordów
       * @param {int} number
       */
      $scope.setRecordsNumber = function(number)
      {
        $scope.currentFilter.settings.otherSettings.recordsPerPage = number;
      };

      /**
       * jak dla wartości selecta przyjdzie null albo undefined to zamieniamy na domyślną wartość
       * @return {[type]} [description]
       */
      $scope.checkEmptyValues = function()
      {
        angular.forEach($scope.config.currentData.dictionaries, function(values, fieldName){
          var defaultValue = '';

          var multipleValues = false;
          if(typeof $scope.config.currentData.multipleValues !== 'undefined' && $scope.config.currentData.multipleValues[fieldName])
          {
            multipleValues = $scope.config.currentData.multipleValues[fieldName];
          }
          if(multipleValues)
          {
            defaultValue = [];
          }

          if(!multipleValues && typeof $scope.config.currentData.requiredFilters !== 'undefined' && $scope.config.currentData.requiredFilters[fieldName])
          {
            //to pole jest wymagane, więc nie może być pustej wartości
            defaultValue = null;
            angular.forEach(values, function(item){
              if(defaultValue === null)
              {
                defaultValue = item.code;
              }
              return false;
            });
          }
          else if(!multipleValues)
          {
            //sprawdzamy czy jest już pusta wartość
            var hasEmptyValue = false;
            angular.forEach($scope.config.currentData.dictionaries[fieldName], function(dictionaryValue){
              if(dictionaryValue.code === '')
              {
                hasEmptyValue = true;
              }
            });

            //dokładamy pustego stringa na początku
            if(!hasEmptyValue)
            {
              $scope.config.currentData.dictionaries[fieldName].unshift({code: '', name: '', id: '', description: ''});
            }
          }
          if(typeof $scope.currentFilter.settings.filterSettings[fieldName] === 'undefined' || $scope.currentFilter.settings.filterSettings[fieldName] === null)
          {
            $scope.currentFilter.settings.filterSettings[fieldName] = defaultValue;
          }
        });
      };

      /**
       * rozwinięcie
       * @param  {[type]} tabKey [description]
       * @return {[type]}        [description]
       */
      $scope.toggleTab = function(tabKey)
      {
        angular.forEach($scope.tabs, function (tab, key) {
          if(key === tabKey)
          {
            $scope.tabs[key] = true;
          }
          else
          {
            $scope.tabs[key] = false;
          }
        });
      };

      /**
       * pokazuje modal zapisu nowego filtra
       */
      $scope.showSaveSettingsModal = function()
      {
        if($scope.isUserRelogged) //nie dla przelogowanych
        {
          return;
        }
        $scope.modals.saveSettings.visible = true;
        iHestiaCommonModalHelper.showModal('ihestiaGridSettingsSave');
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaGridSettingsRemove', function(){
        $scope.deleteCurrentFilter();
        $timeout(function() {
          $scope.modals.removeSettings.visible = false; //nie chcemy żeby się settingsy zamknęły od razu
        }, 0);
      });

      $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaGridSettingsSave', function($event){ //eslint-disable-line consistent-return
        $scope.modals.saveSettings.nameAlreadyExist = false;
        angular.forEach($scope.config.settingsList, function(filter){
          if(filter.name === $scope.modals.saveSettings.name)
          {
            $scope.modals.saveSettings.nameAlreadyExist = true;
          }
        });
        if($scope.modals.saveSettings.name === '' || $scope.modals.saveSettings.nameAlreadyExist) //jak brak nazwy to nie pozwalamy na zapis
        {
          $event.preventDefault();
          return false;
        }

        $scope.saveCurrentFilter($scope.modals.saveSettings.name);
        $scope.modals.saveSettings.name = '';

        $timeout(function() {
          $scope.modals.saveSettings.visible = false; //nie chcemy żeby się settingsy zamknęły od razu
        }, 0);
      });

      $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaGridSettingsSave', function(){
        $scope.modals.saveSettings.name = '';
        $timeout(function() {
          $scope.modals.saveSettings.visible = false; //nie chcemy żeby się settingsy zamknęły od razu
        }, 0);
      });

      $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaGridSettingsRemove', function(){
        $timeout(function() {
          $scope.modals.removeSettings.visible = false; //nie chcemy żeby się settingsy zamknęły od razu
        }, 0);
      });

      $scope.showRemoveSettingsModal = function()
      {
        if($scope.selectedFilter.isDefault || $scope.selectedFilter.canDelete === false || $scope.isUserRelogged) //nie można usuwać domyślnego
        {
          return;
        }
        $scope.modals.removeSettings.visible = true;
        iHestiaCommonModalHelper.showModal('ihestiaGridSettingsRemove');
      };

      /**
       * zapisujemy edytowany filtr jako nowy
       * @param  {string} name [description]
       */
      $scope.saveCurrentFilter = function(name)
      {
        $scope.currentFilter.name = name;

        var contextId = $scope.config.currentData.contextId.charAt(0).toUpperCase() + $scope.config.currentData.contextId.slice(1);

        //mapujemy kolejność kolumn na order
        $scope.currentFilter.settings.columnSettings.order = $scope.getColumnOrder();

        ihestiaGridSettingsContextSvc.post(contextId, $scope.currentFilter, null, function(){
          ihestiaGridSettingsHelper.refreshSettings();
        });
      };

      $scope.getColumnOrder = function()
      {
        var order = [];
        if($scope.config.currentData.columns)
        {
          angular.forEach($scope.config.currentData.columns, function(columnTab, columnTabCode){
            order[columnTabCode] = [];
            angular.forEach(columnTab, function(column){
              order.push(column.valueSource);
            });
          });
        }
        return order;
      };

      /**
       * usuwamy obecnie wybrany filtr
       */
      $scope.deleteCurrentFilter = function()
      {
        ihestiaGridSettingsGroupSvc.remove($scope.selectedFilter.id, null, function(){
          angular.forEach($scope.config.settingsList, function(filter, key){
            if($scope.selectedFilter.id === filter.id)
            {
              $scope.config.settingsList.splice(key, 1);
              $scope.selectedFilter = $scope.config.settingsList[0]; //zaznaczamy pierwszy z brzegu
              $scope.selectedFilterChanged();
            }
          });
        });
      };

      /**
       * obecnie wybrany filtr ustawiamy jako ulubiony/domyślny
       */
      $scope.selectedFilterToggleFavorite = function()
      {
        if($scope.selectedFilter.isDefault || !$scope.selectedFilter.canDelete || $scope.isUserRelogged) //jak jest już domyślny to olewamy
        {
          return;
        }

        if(!$scope.selectedFilter.isSelected)
        {
          //jeśli jakiś już był zaznaczony to odznaczamy, usługa zrobi to sama, ale my nie chcemy niepotrzebnie pobierać listy od nowa
          angular.forEach($scope.config.settingsList, function(filter){
            if(filter.id !== $scope.selectedFilter.id && filter.isSelected)
            {
              filter.isSelected = false;
            }
          });
        }

        $scope.selectedFilter.isSelected = !$scope.selectedFilter.isSelected;
        ihestiaGridSettingsGroupSvc.put($scope.selectedFilter.id, $scope.selectedFilter, null, angular.noop);
      };

      /**
       * generuje listę symboli z aktualnie zaznaczonych obiektów
       */
      $scope.salesNetworksObjectsToSymbols = function()
      {
        $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter = [];
        angular.forEach($scope.currentFilter.settings.filterSettings.organizationUnits, function(value){
          if(value.selected)
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysIcOrganisationUnitFilter.push(value.subjectKeyString);
          }
        });

        $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter = [];
        angular.forEach($scope.currentFilter.settings.filterSettings.salesProfiles, function(value){
          if(value.selected)
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysSalesProfileFilter.push(value.subjectKeyString);
          }
        });

        $scope.currentFilter.settings.filterSettings.subjectKeysWorkerFilter = [];
        angular.forEach($scope.currentFilter.settings.filterSettings.workers, function(value){
          if(value.selected)
          {
            $scope.currentFilter.settings.filterSettings.subjectKeysWorkerFilter.push(value.subjectKeyString);
          }
        });
      };
    }
  ]);
