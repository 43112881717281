/**
 * Mapper wariantów ryzyk (z symulacji)
 */
angular.module('salesPath2').service('fromRestVariantMapper', ['MapperUtils', 'CONSTANTS', 'ihestiaCommonErrorHandler', 'resourceHelper', 'fromRestRiskMapper',
  function(MapperUtils, CONSTANTS, ihestiaCommonErrorHandler, resourceHelper, fromRestRiskMapper) {
    var FromRestVariantMapper = function() {
      var self = this;
      this.utils = MapperUtils;

      this.defaultOptions = { //opcje mapowania
      };
      this.options = angular.copy(this.defaultOptions);
      this.mappedVariants = {};

      //mapuje warianty ryzyk - tryb symulacji
      this.mapVariants = function(application, tmpDc) {
        self.mappedVariants = {};
        var risksToOmit = fromRestRiskMapper.getRisksToOmit();
        lsnNg.forEach(application.variants, function(riskVariant) {
          var def = self.utils.findRiskDefByIdpm(riskVariant.get('compId'));
          if (risksToOmit.indexOf(def.riskCode) !== -1) { //pomijamy niektóre ryzyka
            return;
          }
          self._mapVariant(def, riskVariant, tmpDc);
        });
      };
      /**
       * mapuje wariant ryzyka
       * @param {object} def definicja ryzyka z SPD
       * @param {RiskVariant} riskVariant wariant ryzyka
       * @param {RiskVariant} tmpDc wariant ryzyka
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapVariant = function(def, riskVariant, tmpDc) {
        if (self.utils.propertyProducts.indexOf(def.productCode) !== -1) {
          self._mapPropertyRisk(def, riskVariant, tmpDc);
        } else if (self.utils.personalProducts.indexOf(def.productCode) !== -1) {
          self._mapPersonalRisk(def, riskVariant, tmpDc);
        } else if (self.utils.communicationProducts.indexOf(def.productCode) !== -1) {
          self._mapCommunicationRisk(def, riskVariant, tmpDc);
        } else if (self.utils.personGroupProducts.indexOf(def.productCode) !== -1) {
          self._mapPersonGroupRisk(def, riskVariant, tmpDc);
        }
        //
        // else
        // { //dodatki
        //   self._mapAdditionRisk(def, riskVariant, tmpDc);
        // }
      };
      //#pragma mark propertyRisks
      /**
       * mapuje ryzyka majatkowe
       * @param {object} def definicja ryzyka z SPD
       * @param {RiskVariant} riskVariant ryzyko
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapPropertyRisk = function(def, riskVariant, tmpDc) {
        var variant = self._parseVariant(riskVariant.get('option'));
        if (!riskVariant.objectRef || typeof riskVariant.objectRef[0] === 'undefined' || variant === null || variant === '') {
          return;
        }
        var locId = tmpDc._estateIdMap[riskVariant.objectRef[0]],
          mappedName = self._getMappedName(def, locId, variant);
        if (self._isVariantMapped(mappedName)) {
          return;
        }
        //dla symulacji zawsze beda skladki taryfowe
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'tariffPremium');
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_LOCALIZATION, locId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'premium');
        self._riskHasBeenMapped(mappedName);
      };
      //#pragma mark personalRisks
      /**
       * mapuje ryzyko osobowe
       * @param {object} def definicja ryzyka z SPD
       * @param {RiskVariant} riskVariant wariant ryzyka
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapPersonalRisk = function(def, riskVariant, tmpDc) {
        if (!riskVariant.subjectRef || typeof riskVariant.subjectRef[0] === 'undefined') {
          return;
        }
        var variant = self._parseVariant(riskVariant.get('option'));
        if (variant === null) {
          return;
        }
        lsnNg.forEach(riskVariant.subjectRef, function(subRef) {
          var pId = tmpDc._subjectIdMap[subRef],
            mappedName = self._getMappedName(def, pId, variant);
          if (self._isVariantMapped(mappedName)) {
            return;
          }
          //mapowanie kwot
          fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, riskVariant.get('premium'), variant, 'premium');
          fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON, pId, def.productCode, null, riskVariant.get('premium'), variant, 'tariffPremium');
          self._riskHasBeenMapped(mappedName);
        });
      };
      //#pragma mark communicationRisks
      /**
       * mapuje ryzyko komunikacyjne
       * @param {object} def definicja ryzyka z SPD
       * @param {RiskVariant} riskVariant ryzyko
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapCommunicationRisk = function(def, riskVariant, tmpDc) {
        if (!riskVariant.objectRef || typeof riskVariant.objectRef[0] === 'undefined') {
          return;
        }
        var variant = self._parseVariant(riskVariant.get('option'));
        if (variant === null) {
          return;
        }
        var vehId = tmpDc._vehicleIdMap[riskVariant.objectRef[0]],
          mappedName = self._getMappedName(def, vehId, variant);
        if (self._isVariantMapped(mappedName)) {
          return;
        }
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'premium');
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'tariffPremium');
        self._riskHasBeenMapped(mappedName);
      };
      //#pragma mark personGroupRisks
      /**
       * mapuje ryzyko typu personGroup
       * @param {object} def definicja ryzyka z SPD
       * @param {RiskVariant} riskVariant ryzyko
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapPersonGroupRisk = function(def, riskVariant, tmpDc) {
        if (!riskVariant.subjectRef || typeof riskVariant.subjectRef[0] === 'undefined') {
          return;
        }
        var variant = self._parseVariant(riskVariant.get('option'));
        if (variant === null) {
          return;
        }
        var subId = tmpDc._subjectIdMap[riskVariant.subjectRef[0]],
          mappedName = self._getMappedName(def, subId, variant);
        if (self._isVariantMapped(mappedName)) {
          return;
        }
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'premium');
        fromRestRiskMapper._setPremium(tmpDc, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'tariffPremium');
        self._riskHasBeenMapped(mappedName);
      };
      //#pragma mark additionRisks
      /**
       * mapuje ryzyko - dodatek
       * @param {object} def definicja ryzyka z SPD
       * @param {riskVariant} riskVariant wariant ryzyka
       * @param {FromRestTempDataContainerModel} tmpDc
       */
      this._mapAdditionRisk = function(def, riskVariant, tmpDc) {
        if (
          (angular.isUndefined(def.objectIdpm) || !riskVariant.objectRef || angular.isUndefined(riskVariant.objectRef[0])) &&
          (angular.isUndefined(def.subjects) || !riskVariant.subjectRef || angular.isUndefined(riskVariant.subjectRef[0]))
        ) {
          return;
        }
        var addDef = resourceHelper.getAddDef(def.riskCode),
          variant = self._parseVariant(riskVariant.get('option')),
          objId = null;
        if (angular.isDefined(addDef.VARIANTS)) {
          if (def.attributes.coverageOption) {
            if (variant === null) {
              return;
            }
            variant = fromRestRiskMapper._getAdditionVariant(addDef.CODE, variant);
          } else if (def.attributes.assistanceOption) { //HCA
            if (variant === null) {
              return;
            }
            variant = self.utils.getHCAVariantFromAssistanceOption(variant);
          } else { //wariant przepisujemy z definicji lokalnej dodatku (np. u nas jedno ryzyko ma 2 warianty a w BOSie są to 2 osobne ryzyka)
            if (def.variant.length > 1) {
              ihestiaCommonErrorHandler.throwException('Ambigious variant in addition risk definition {0}'.format(def.productCode));
            }
            variant = def.variant[0];
          }
        } else {
          variant = null;
        }
        switch (addDef.TYPE) {
          case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
            objId = tmpDc._estateIdMap[riskVariant.objectRef[0]];
            break;
          case CONSTANTS.PRODUCT_TYPE_VEHICLE:
            //przypadek dodatku per auto dla ktorego BOS nie przechowuje referncji obiektu
            if (riskVariant.get('objectRef').length === 0) {
              var idMapKeys = Object.keys(tmpDc._vehicleIdMap);
              if (idMapKeys.length !== 1) {
                ihestiaCommonErrorHandler.throwException('Can not map vehicle for addition variant {0}. No vehicle or over 1 vehicles found for /policyapplication.'.format(def.riskCode));
              }
              objId = tmpDc._vehicleIdMap[idMapKeys[0]];
            } else {
              objId = tmpDc._vehicleIdMap[riskVariant.objectRef[0]];
            }
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON:
            var ref = riskVariant.subjectRef[0];
            objId = tmpDc._subjectIdMap[ref];
            break;
          case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
            var refSub = riskVariant.subjectRef[0];
            objId = tmpDc._subjectIdMap[refSub];
            break;
          default:
            break;
        }
        var mappedName = self._getMappedName(def, objId, variant);
        if (self._isVariantMapped(mappedName)) {
          return;
        }
        fromRestRiskMapper._setPremium(tmpDc, addDef.TYPE, objId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'premium');
        fromRestRiskMapper._setPremium(tmpDc, addDef.TYPE, objId, def.productCode, def.riskCode, riskVariant.get('premium'), variant, 'tariffPremium');
        self._riskHasBeenMapped(mappedName);
      };

      /**
       * opcjonanie parsuje wariant ubezpieczenia
       */
      this._parseVariant = function(variant) {
        if (!angular.isString(variant) || variant === '') {
          return null;
        }
        return variant;
      };
      //#pragma mark mappingOptions
      /**
       * zwraca mapowaną nazwę ryzyka (uwzgledniajac obiekt i wariant)
       * @param {object} def definicja ryzyka z SPD
       * @param {string} objId id obiektu
       * @param {string} variant opcja/wariant
       * @return {string}
       */
      this._getMappedName = function(def, objId, variant) {
        var name = [def.productCode, (objId ? objId : ''), (variant ? variant : '')].join('_');
        return name;
      };
      /**
       * czy ryzyko o danej nazwie (podanej do mapowania) zostao już zmapowane
       * @param {string} mappedName nazwa zmapowanego ryzyka
       * @return {bool}
       */
      this._isVariantMapped = function() {
        return false; //zakladamy ze warianty są unikalne w sp2. Można to udekorować per apka.
      };
      /**
       * oznacza ryzyko/wariant jako zmapowane
       * @param {string} mappedName nazwa zmapowanego wariantu/ryzyka
       */
      this._riskHasBeenMapped = function(mappedName) {
        self.mappedVariants[mappedName] = true;
      };
    };

    return new FromRestVariantMapper();
  }
]);