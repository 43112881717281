angular.module('ihestiaCommonDirectives')
  .directive('ihestiaWhenScrolled', ['$parse',
    function($parse) {
    return function(scope, elm, attr) {
      var raw = elm[0],
        scrollShowed = elm[0].scrollHeight > elm[0].clientHeight;

      var checkEmitShowHideScroll = function() {
        scrollShowed = elm[0].scrollHeight > elm[0].clientHeight;

        scope.$emit(attr.onShowHideScroll, {
          scrollShowed: scrollShowed
        });
      };

      scope.init = function() {
        if (angular.isDefined(attr.onShowHideScroll)) {
          scope.$watch(
            function() {
              return elm[0].clientWidth;
            },
            function() {
              checkEmitShowHideScroll();
            }
          );

          scope.$watch(
            function() {
              return elm[0].scrollHeight;
            },
            function() {
              checkEmitShowHideScroll();
            }
          );
        }
      };

      var onScroll = function() {

        var args = {
          reachedTop: false,
          reachedBottom: false,
          percentage: Math.floor(100 * (raw.scrollTop) / (raw.scrollHeight - raw.clientHeight))
        };

        if (Math.ceil(100 * (raw.scrollTop) / (raw.scrollHeight - raw.clientHeight)) >= 100) {
          args.reachedTop = true;
          //jesteśmy na dole!
          args.reallyReachedBottom = true;
        } else if (args.percentage <= 0) {
          args.reachedBottom = true;
          //jesteśmy na górze!
          args.reallyReachedTop = true;
        }

        if(attr.ihestiaWhenScrolledCallback)
        {
          //bezpośredni callback zamiast emita, bo leci szybciej
          var callback = $parse(attr.ihestiaWhenScrolledCallback)(scope);
          if(angular.isFunction(callback))
          {
            callback(args);
          }
        }
        else
        {
          scope.$emit(attr.ihestiaWhenScrolled, args);
        }


      };

      elm.on('scroll', onScroll);

      scope.init();

      scope.$on('$destroy', function() {
        elm.off('scroll', onScroll);
      });
    };
  }]);