angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaNotesAndUploader', ['fileServerSvc', '$alert', 'ihestiaAutoUploadOneListUploadHelper', 'ihestiaRestDictionaryAllElectronicDocumentTypesSvc', 'ihestiaCommonErrorHandler', '$filter',
    function(fileServerSvc, $alert, ihestiaAutoUploadOneListUploadHelper, ihestiaRestDictionaryAllElectronicDocumentTypesSvc, ihestiaCommonErrorHandler, $filter) {
      /**
       * @typedef IhestiaNotesAndUploaderOptions
       * @property {string} [uploaderName]
       * @property {string} [documentCode]
       * @property {{[uploader]: boolean}} [disabledElements]
       * @property {string} [policyNumber]
       * @property {object[]} [fileModels]
       * @property {string} [notes]
       */
      /**
       *
       * @param {IhestiaNotesAndUploaderOptions} [options]
       * @constructor
       */
      var IhestiaNotesAndUploader = function(options) {
        var self = this;

        this.defaultOptions = {
          uploaderName: 'defaultUploaderName',
          documentCode: 'CustomerApplication',
          disabledElements: {
            uploader: false
          }
        };
        this.options = null;

        this.tplData = {
          fileModels: null,
          notes: null,
          fileUploadFailed: null
        };

        this.uploaderOptions = {
          url: fileServerSvc._getServiceUrl(),
          autoUpload: false,
          singleFileUploads: true,
          maxChunkSize: 4000000,
          messages: {
            maxNumberOfFiles: $filter('translate')('Public.maxFileCountExceeded', {
              componentCode: 'Public'
            }),
            acceptFileTypes: $filter('translate')('Public.incorrectFileType', {
              componentCode: 'Public'
            }),
            maxFileSize: $filter('translate')('Public.toLargeFile', {
              componentCode: 'Public'
            }),
            minFileSize: $filter('translate')('Public.toSmallFile', {
              componentCode: 'Public'
            })
          },
          name: null,
          hideQueueList: true,
          disabled: null,
          showMessage: function(msg) {
            $alert({
              content: msg,
              type: 'warning',
              duration: 10
            });
          }
        };

        /**
         *
         * @param {boolean} disabled
         */
        this.setUploaderDisabled = function(disabled) {
          self.uploaderOptions.disabled = disabled;
        };

        /**
         *
         * @param {string} fileId
         */
        this.deleteFile = function(fileId) {
          ihestiaAutoUploadOneListUploadHelper.deleteFile(self.uploaderOptions.name, fileId);
        };

        this.init = function() {
          self.options = self.mergeDefaultOptions(options, self.defaultOptions);
          self.uploaderOptions.name = self.options.uploaderName;
          self.uploaderOptions.disabled = self.options.disabledElements.uploader;
          self._initUploader();
          self._setDocumentTypeForUpload()
            .then(lsnNg.noop, lsnNg.noop);
          if (self.options.notes) {
            self.tplData.notes = self.options.notes;
          }
        };

        /**
         * initialize uploader directive
         * @private
         */
        this._initUploader = function() {
          ihestiaAutoUploadOneListUploadHelper.init(
            self.uploaderOptions.name, {
              onAllFileSend: self.onAllFileSend
            },
            null, {
              autoUpload: true,
              firstCheckAllFiles: false
            }
          );

          self.tplData.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles(self.uploaderOptions.name);
          if (self.options.fileModels) {
            _.forEach(self.options.fileModels, function(file) {
              self.tplData.fileModels.push(file);
            });
          }
        };

        this._setDocumentTypeForUpload = function() {
          var documentCode = self.options.documentCode || 'CustomerApplication';
          return ihestiaRestDictionaryAllElectronicDocumentTypesSvc.get(
            null,
            null,
            documentCode
          ).then(function(result) {
            if (angular.isObject(result.data) && angular.isDefined(result.data.code)) {
              var documentType = result.data;
              if (angular.isDefined(documentType.attributes) && documentType.attributes.length > 0) {
                angular.forEach(documentType.attributes, function(attribute) {
                  if (attribute.code === 'PolicyNumber') {
                    attribute.val = self.options.policyNumber;
                  }
                });
              }
              ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType(self.uploaderOptions.name, documentType);
            } else {
              ihestiaCommonErrorHandler.throwException($filter('translate')('exception.serviceDidNotReturnDocDef', {
                componentCode: 'Public',
                params: {
                  documentCode: documentCode
                }
              }));
            }
          });
        };


        /**
         *
         * @param data
         */
        this.onAllFilesSend = function(data) {
          self.tplData.fileUploadFailed = !data.withoutError;
        };

        this.getNotes = function() {
          return self.tplData.notes;
        };

        this.getFileModels = function() {
          return self.tplData.fileModels;
        };

        this.getFileServerIds = function() {
          return _.chain(self.tplData.fileModels)
            .map('fileServerId')
            .filter(_.isString)
            .value();
        };

        /**
         * Merges options passed by params and default options
         * @param {any} options
         * @param {any} defaultOptions
         * @returns {any}
         */
        this.mergeDefaultOptions = function(options, defaultOptions) {
          options = options || {};
          _.forEach(_.toPairs(defaultOptions), function(pair) {
            var key = pair[0];
            var value = pair[1];
            if (_.isPlainObject(value)) {
              options[key] = options[key] || {};
              self.mergeDefaultOptions(options[key], value);
            } else if (options[key] === undefined) {
              options[key] = value;
            }
          });
          return options;
        };

        this.init();
      };
      return IhestiaNotesAndUploader;
    }]);
