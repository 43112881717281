angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryAddressesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryAddressesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        this.resource = 'Addresses';

      };
      return new RestDictionaryAddressesSvc();
    }
  ]);