angular.module('salesPath2')
  .service('sp2HeaderHelper', ['mainDataContainer', 'CONSTANTS', 'personHelper', 'applicationHelper', 'RESOURCES', 'sp2CommonHelper', 'CONFIG', 'localizationHelper', 'vehicleHelper', 'leasingHelper', 'lsnDeepExtend', 'coownerHelper', 'actionHelper', 'sp2SelectionHelper', '$filter', 'appVariables', 'supplementHelper', 'allowedChangesHelper', 'dataContainerHelper', 'nnwHelper',
    function(mainDataContainer, CONSTANTS, personHelper, applicationHelper, RESOURCES, sp2CommonHelper, CONFIG, localizationHelper, vehicleHelper, leasingHelper, lsnDeepExtend, coownerHelper, actionHelper, selectionHelper, $filter, appVariables, supplementHelper, allowedChangesHelper, dataContainerHelper, nnwHelper) { // eslint-disable-line angular/di
      var HeaderHelper = function() {
        var self = this;

        var DEFAULT_HEADER_SETTINGS = {
          backButton: false, //czy wyswietlic przycisk "wstecz"
          ICON: null, //klasa dla ikony. Jeśli null, to nie wyswietlamy ikony
          protectionDates: { //daty ochrony
            enabled: true,
            ref: null, //referencja do obiektu przechowującego daty ochrony (start: ... i end: ...)
            endDateDisabled: false, //czy "data do" nieedytowalna
            startDateDisabled: appVariables.readOnly || appVariables.isSupplement, //czy "data od" nieedytowalna
            protectionPeriodDisabled: appVariables.readOnly || appVariables.isSupplement, //czy "okres ochrony" nieedytowalny
            periodType: null, //wybór okresu ubezpieczenia: null: brak, 'days': opcje z dniami, 'travel': opcje dla podróży
            minProtectionStartDate: null, //minimalna "data od"
            maxProtectionStartDate: null //maksymalna "data od"
          },
          insurer: { //ubezpieczający
            enabled: true,
            label: $filter('translate')('Public.insurer', {
              componentCode: 'Public'
            })
          },
          coowners: { //współwłaściciele
            enabled: false,
            ref: null //referencja do tablicy przechowującej identyfikatory współwłaścicieli
          },
          leaser: {
            enabled: false
          },
          vehicle: {
            enabled: false,
            liquidationIcon: false //czy prezentować ikonę zmiany sposobu likwidacji szkód
          },
          localization: {
            enabled: false
          },
          selectedAllObject: false
        };

        /**
         * dane do szablonu i kontrolera
         * @type {Object}
         */
        this.tplData = {
          insurerName: null,
          protectionDates: {}, //daty ochrony
          coowners: null,
          communicationPersons: null,
          changePossible: {}
        };

        /**
         * aktualne ustawienia dla headera
         * Możemy tak zrobić, ponieważ jednocześnie na GUI może być widoczny tylko jeden header, czyli helper będzie zawsze działał w kontekście jednego nagłówka/headera
         * @type {[type]}
         */
        this.currentSettings = angular.copy(DEFAULT_HEADER_SETTINGS);

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personAdded':
            case 'personGroupSelected':
            case 'coownersChanged':
            case 'tarifficationEnded':
              self.refreshInsurerName();
              self.refreshCoowner();
              break;
            case 'localizationSelected':
              if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                self.initProtectionDates();
                self.refreshLocalizationData();
                self.setSelectedAllObject();
                self.refreshLeaser();
                self.refreshCoowner();
              }
              break;
            case 'vehicleEdited':
            case 'vehicleAdded':
            case 'vehicleSelected':
              self.refreshVehicleData();
              self.refreshLeaser();
              self.refreshCoowner();
              self.initCommunicationDate();
              self.setSelectedAllObject();
              break;
            case 'localizationEdited':
              if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
                self.refreshLocalizationData();
                self.setSelectedAllObject();
                self.refreshLeaser();
                self.refreshCoowner();
              }
              break;
            case 'leaserSaved':
            case 'leaserDeleted':
              self.refreshLeaser();
              break;
            case 'matrixProductSelected':
              self.refreshVehicleData();
              break;
            case 'languageChanged.beforeStateReload':
              self.translate();
              break;
            default:
          }
        };

        /**
         * inicjalizuje ustawienia oraz dane dla szablonu
         * @param  {Object} settings
         */
        this.initData = function(settings) {
          self.currentSettings = lsnDeepExtend({}, DEFAULT_HEADER_SETTINGS, settings);
          self.initProtectionDates();
          self.refreshInsurerName();
          self.refreshLocalizationData();
          self.refreshVehicleData();
          self.setSelectedAllObject();
          self.refreshLeaser();
          self.refreshCoowner();
        };

        /**
         * inicjalizuje sekcję dotyczącą dat ochrony
         */
        this.initProtectionDates = function() { //eslint-disable-line consistent-return
          if (!self.currentSettings.protectionDates.enabled) {
            return false;
          }

          self.updateEndDateDisabled();

          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            self.initPropertyProtectionDate();
            return true;
          }

          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            self.initCommunicationDate();
            return true;
          }

          //przypadek ekranu innego niz komunikacja i ogien/kardziez
          self.currentSettings.protectionDates.minProtectionStartDate = mainDataContainer.minPropertyDate;

          //uzupełniamy referencje do dat
          if (self.currentSettings.protectionDates.ref === null) {
            sp2CommonHelper.throwException('Nie ustawiono parametru protectionDates.ref dla nagłówka.');
          }
          if (angular.isString(self.currentSettings.protectionDates.ref)) {
            self.currentSettings.protectionDates.ref = [self.currentSettings.protectionDates.ref];
          }

          if (angular.isArray(self.currentSettings.protectionDates.ref)) {
            //określamy referencję do obiektu
            var objectRef = mainDataContainer[self.currentSettings.protectionDates.ref[0]];
            lsnNg.forEach(self.currentSettings.protectionDates.ref, function(name, idx) {
              if (idx === 0) {
                return;
              }
              if (angular.isUndefined(objectRef[name])) {
                return false; //eslint-disable-line consistent-return
              }
              objectRef = objectRef[name];
              return true; //eslint-disable-line consistent-return
            });
            self.tplData.protectionDates = objectRef;
          } else if (angular.isObject(self.currentSettings.protectionDates.ref)) {
            self.tplData.protectionDates = self.currentSettings.protectionDates.ref;
          }

          if ((self.currentSettings.product === CONSTANTS.PRODUCT_NNW || self.currentSettings.product === CONSTANTS.PRODUCT_OCZP) && appVariables.isSupplement) {
            var dates = supplementHelper.getAvailablePeriodInsurance(self.currentSettings.product, nnwHelper.tplData.activeInsured);
            if (dates.maxDate.diffDays(dates.minDate) === 0) {
              self.currentSettings.protectionDates.startDateDisabled = true;
            } else {
              self.currentSettings.protectionDates.minProtectionStartDate = dates.minDate.toString('yyyy-MM-dd');
              self.currentSettings.protectionDates.maxProtectionStartDate = dates.maxDate.toString('yyyy-MM-dd');
            }
          } else {
            self.currentSettings.protectionDates.startDateDisabled = appVariables.readOnly;
          }
        };

        /**
         * odswieza informacje o nieaktywnosci pola "data do"
         */
        this.updateEndDateDisabled = function() {
          self.currentSettings.protectionDates.protectionPeriodDisabled = false;
          //aktywnosc daty do i okresu czasu
          if (appVariables.readOnly || appVariables.isSupplement) {
            self.currentSettings.protectionDates.endDateDisabled = true;
            self.currentSettings.protectionDates.protectionPeriodDisabled = true;
          } else if ((self.currentSettings.protectionDates.periodType === 'travel' && mainDataContainer.protectionPeriodCode === 'year') || self.currentSettings.protectionDates.periodType === 'days') {
            self.currentSettings.protectionDates.endDateDisabled = true;
          } else {
            self.currentSettings.protectionDates.endDateDisabled = false;
          }
        };

        /*
         * inicjalizuje sekcję dotyczącą dat ochrony dla komunikacj
         *
         */
        this.initCommunicationDate = function() {
          if (!self.currentSettings.protectionDates.enabled) {
            return true;
          }
          var vahicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
          if (angular.isDefined(vahicle)) {
            self.tplData.protectionDates = angular.copy(vahicle.getAdditionalData('protectionDates'));
          }

          self.currentSettings.protectionDates.startDateDisabled = appVariables.readOnly;
          if (!appVariables.isSupplement) {
            self.currentSettings.protectionDates.minProtectionStartDate = mainDataContainer.minCommunicationDate;
          } else {
            var product = selectionHelper.getAnyCommunicationPreviouslySelectedProductCode();
            var dates = supplementHelper.getAvailablePeriodInsurance(product, mainDataContainer.selectedVehicle);
            if (dates.maxDate.diffDays(dates.minDate) === 0) {
              self.currentSettings.protectionDates.startDateDisabled = true;
            } else {
              self.currentSettings.protectionDates.minProtectionStartDate = dates.minDate.toString('yyyy-MM-dd');
              self.currentSettings.protectionDates.maxProtectionStartDate = dates.maxDate.toString('yyyy-MM-dd');
            }

          }

          return true;
        };

        /**
         * [initPropertyProtectionDate inicjalizuje sekcję dotyczącą dat ochrony dla mienia]
         * @return {[type]} [description]
         */
        this.initPropertyProtectionDate = function() { //eslint-disable-line consistent-return

          if (!self.currentSettings.protectionDates.enabled) {
            return false;
          }

          if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) { //wspólne daty dal mienia i osobowych
            self.tplData.protectionDates = angular.copy(mainDataContainer.propertyProtectionDates);
          } else {
            var localization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
            if (angular.isDefined(localization)) {
              self.tplData.protectionDates = angular.copy(localization.getAdditionalData('protectionDates')[self.currentSettings.product]);
            }
          }

          self.currentSettings.protectionDates.startDateDisabled = appVariables.readOnly;
          if (!appVariables.isSupplement) {
            self.currentSettings.protectionDates.minProtectionStartDate = mainDataContainer.minPropertyDate;
            return true;
          } else {
            var dates = supplementHelper.getAvailablePeriodInsurance(self.currentSettings.product, mainDataContainer.selectedLocalization);
            if (dates.maxDate.diffDays(dates.minDate) === 0) {
              self.currentSettings.protectionDates.startDateDisabled = true;
            } else {
              self.currentSettings.protectionDates.minProtectionStartDate = dates.minDate.toString('yyyy-MM-dd');
              self.currentSettings.protectionDates.maxProtectionStartDate = dates.maxDate.toString('yyyy-MM-dd');
            }

          }
        };

        /**
         * [refreshLocalizationData pobiera i ustawia opis aktualnie wybranej lokalizacji]
         */
        this.refreshLocalizationData = function() {
          if (!self.currentSettings.localization.enabled || mainDataContainer.selectedLocalization === CONSTANTS.ALL_OBJECTS || mainDataContainer.selectedLocalization === CONSTANTS.NO_OBJECT) {
            return false;
          }
          var localization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
          self.tplData.selectedLocalizationName = localizationHelper.getLocalizationInfo(localization);
          self.tplData.changePossible.localization = allowedChangesHelper.anyChangePossible(localization);
          return true;
        };

        /**
         * pobiera o ustawia opis aktualnie wybranego pojazdu
         */
        this.refreshVehicleData = function() {
          if (!self.currentSettings.vehicle.enabled) {
            return;
          }
          var vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];

          if (angular.isDefined(vehicle)) {
            self.tplData.selectedVehicleName = vehicleHelper.getVehicleInfo(vehicle);
            self.tplData.changePossible.vehicle = allowedChangesHelper.anyChangePossible(vehicle);
          }
          //ikona likwidacji szkód
          self.currentSettings.vehicle.liquidationIcon = false;
          if (parseInt(mainDataContainer.selectedVehicle, 10) > 0) {
            var acVariantSelected = dataContainerHelper.getVariantsForProduct(CONSTANTS.PRODUCT_ACKOM, mainDataContainer.selectedVehicle);
            lsnNg.forEach(acVariantSelected, function(selected, variantCode) {
              if (selected && CONFIG.BEHAVIOR.liquidationVariant.indexOf(variantCode) !== -1 && CONFIG.BEHAVIOR.liquidationVariantOption[variantCode].length > 1) {
                self.currentSettings.vehicle.liquidationIcon = true;
                return false;
              }
              return true;
            });
          }
        };

        /*
         * aktualizauje dane o lisingodawcy
         */
        this.refreshLeaser = function() {
          if (!self.currentSettings.leaser.enabled) {
            return;
          }
          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            self.tplData.vehicleIsLeased = vehicleHelper.isSelectedVehicleLeased();
          }

          //domyslne ustawienie informacji o możlliwości edycji leasera
          if (appVariables.isSupplement || appVariables.readOnly) {
            self.tplData.changePossible.leaser = false;
          } else {
            self.tplData.changePossible.leaser = true;
          }

          var object = self.getSelectedObject();
          if (object !== null && angular.isDefined(object)) {
            self.tplData.leaserName = leasingHelper.getLeaserInfo(object);
            var leaserId = object.getAdditionalData('leaserId');
            var isLeased = !!object.getAdditionalData('isLeased');
            if (isLeased && leaserId !== null) {
              var leaser = mainDataContainer.organizations[leaserId];
              self.tplData.changePossible.leaser = allowedChangesHelper.anyChangePossible(leaser);
            }
          }
        };

        /*
         * aktualizauje dane o współwłaścicielach
         */
        this.refreshCoowner = function() {
          if (!self.currentSettings.coowners.enabled) {
            return;
          }

          var object = self.getSelectedObject();
          if (angular.isObject(object)) {
            var coowners = object.getAdditionalData('coowners');
            self.tplData.coowners = coownerHelper.getPersonsInfo(coowners);
          }

          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            var persons = [];
            var mainInsured = dataContainerHelper.getMainInsured();
            var insurerInfo = personHelper.getPersonInfo(mainInsured, {
              elements: [{
                field: 'firstName'
              }, {
                field: 'lastName',
                lastInLine: true
              }, {
                field: 'pesel'
              }],
              lineSeparator: '\n'
            });
            persons.push(insurerInfo);

            angular.forEach(self.tplData.coowners, function(coowner) {
              persons.push(coowner);
            });

            self.tplData.communicationPersons = persons;
          }
        };

        /**
         * aktulaizuje dane o  ubezpieczającym
         */
        this.refreshInsurerName = function() {
          if (!self.currentSettings.insurer.enabled) {
            return;
          }
          var insurer = dataContainerHelper.getInsurer();
          if (insurer === null) {
            return;
          }

          self.tplData.changePossible.insurer = allowedChangesHelper.anyChangePossible(insurer);

          self.tplData.insurerName = personHelper.getPersonInfo(insurer, {
            elements: [{
              field: 'firstName'
            }, {
              field: 'lastName',
              lastInLine: true
            }, {
              field: 'pesel'
            }],
            lineSeparator: '\n'
          });
        };

        /**
         * aktualizuje datę ochrony "do"
         * @param  {String} type 'start' lub 'end' - którą datę aktualizujemy
         */
        this.updateProtectionDate = function(type) {
          self.updateEndDateDisabled();
          var startDateAsString = angular.isString(self.tplData.protectionDates.start);

          if (appVariables.isSupplement && type === 'start' && !startDateAsString) {
            return false;
          }

          //jeśli ustawione są limity dat to sprawdzamy czy nie wykraczamy poza ich zakres - UWAGA jest to ddoatkowe sprawdzenie, ponieważ sam kalendarzyk powinien blokować takie daty
          if (type === 'start') { //przy formacie yyyy-mm-dd mozemy sobie pozwolic na bezposrednie porownywanie stringow
            if (startDateAsString &&
              (
                (angular.isString(self.currentSettings.protectionDates.minProtectionStartDate) && self.tplData.protectionDates.start < self.currentSettings.protectionDates.minProtectionStartDate) ||
                (angular.isString(self.currentSettings.protectionDates.maxProtectionStartDate) && self.tplData.protectionDates.start > self.currentSettings.protectionDates.maxProtectionStartDate)
              )
            ) {
              return false;
            }
          } else if (angular.isString(self.tplData.protectionDates.end) && angular.isString(self.tplData.protectionDates.start) && self.tplData.protectionDates.start > self.tplData.protectionDates.end) { // 'end'
            return false;
          }

          //jeśli ustawiamy datę początku na dzisiejszą, to resetujemy ustawinie godziny
          if (type === 'start' && self.tplData.protectionDates.start === RESOURCES.TODAY) {
            dataContainerHelper.initProtectionTime();
          }

          if (CONFIG.BEHAVIOR.globalProtectionPeriod === 'travel') {
            dataContainerHelper.setPoliciesDynamicValues({
              typeOfAgreement: null
            }); //niech usluga przepisze wartosc na pakiety z dynamicValues na wniosku
          }

          if (startDateAsString) {
            if (self.currentSettings.protectionDates.periodType !== null) {
              self._updateWithProtectionPeriod(type);
            } else if (type === 'start' && !appVariables.isSupplement) {
              self.tplData.protectionDates.end = (new XDate(self.tplData.protectionDates.start).addYears(1).addDays(-1)).toString('yyyy-MM-dd');
            }
          }

          if (angular.isFunction(self.currentSettings.protectionDates.callback)) {
            self.currentSettings.protectionDates.callback(self.tplData.protectionDates);
          }

          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            self.updatePropertyProtectionDate();
          } else if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            self.updateVehicleProtectionDate();
          }
          if (appVariables.isSupplement && type === 'start') {
            mainDataContainer.application.operationData.set('changeDate', self.tplData.protectionDates.start);
            dataContainerHelper.setAllProtectionDates(type, self.tplData.protectionDates.start);
          }
          actionHelper.runAction('protectionDatesChanged');
          return true;
        };

        this.updateVehicleProtectionDate = function() {
          var vehicle = mainDataContainer.vehicles[mainDataContainer.selectedVehicle];
          if (angular.isDefined(vehicle)) {
            var proDates = angular.copy(self.tplData.protectionDates);
            vehicle.setAdditionalData(proDates, 'protectionDates');
          }
        };

        this.updatePropertyProtectionDate = function() {
          if (!CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
            var localization = mainDataContainer.localizations[mainDataContainer.selectedLocalization];
            if (angular.isDefined(localization)) {
              var proDates = {};
              proDates[CONSTANTS.PRODUCT_KRA] = angular.copy(self.tplData.protectionDates);
              proDates[CONSTANTS.PRODUCT_OGI] = angular.copy(self.tplData.protectionDates);
              localization.setAdditionalData(proDates, 'protectionDates');
            }
          } else {
            angular.extend(mainDataContainer.propertyProtectionDates, angular.copy(self.tplData.protectionDates));
          }
        };

        /**
         * zwraca wybrany obiekt lokalizacji lub pojazdu w zależności od kontekstu headera
         */
        this.getSelectedObject = function() {
          var selectedObjectId; // (string) clientId danego obiektu
          var object = null;
          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            selectedObjectId = mainDataContainer.selectedVehicle;
            object = dataContainerHelper.getObject(self.currentSettings.object, selectedObjectId, 'clientId');
          } else if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            selectedObjectId = mainDataContainer.selectedLocalization;
            object = dataContainerHelper.getObject(self.currentSettings.object, selectedObjectId, 'clientId');
          }

          return object;
        };

        this.setSelectedAllObject = function() {
          var selectedId = null;
          if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            selectedId = mainDataContainer.selectedVehicle;
          } else if (self.currentSettings.object === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            selectedId = mainDataContainer.selectedLocalization;
          }
          self.currentSettings.selectedAllObject = CONSTANTS.ALL_OBJECTS === selectedId;
        };

        /**
         * aktualizacja danych związanych z opcją protectionPeriod
         * @param  {String} type 'start' lub 'end' - którą datę aktualizujemy
         **/
        this._updateWithProtectionPeriod = function(type) {
          if (mainDataContainer.protectionPeriodCode === null) {
            return;
          }
          switch (self.currentSettings.protectionDates.periodType) {
            case 'days':
              if (appVariables.isSupplement) {
                break;
              }
              var periodItem = this._getPeriodResourceItem(mainDataContainer.protectionPeriodCode);
              if (angular.isDefined(periodItem.DAYS_TO_ADD)) {
                self.tplData.protectionDates.end = (new XDate(self.tplData.protectionDates.start).addDays(periodItem.DAYS_TO_ADD)).toString('yyyy-MM-dd');
              } else if (angular.isDefined(periodItem.YEARS_TO_ADD)) {
                self.tplData.protectionDates.end = (new XDate(self.tplData.protectionDates.start).addYears(periodItem.YEARS_TO_ADD).addDays(-1)).toString('yyyy-MM-dd');
              }
              break;
            case 'travel':
              dataContainerHelper.setPoliciesDynamicValues({ //dla pakietów sport i travel (innym nie zaszkodzi)
                typeOfAgreement: null
              }); //niech usluga przepisze wartosc na pakiety z dynamicValues na wniosku
              if (!appVariables.isSupplement && mainDataContainer.protectionPeriodCode === 'year') {
                self.tplData.protectionDates.end = (new XDate(self.tplData.protectionDates.start)).addYears(1).addDays(-1).toString('yyyy-MM-dd');
              }
              //usuwamy paymentProrogation ze wszystkich polis, poniewaz po zmienie daty od, uskugi okresla domyslna wartosc tego atrybutu
              if (type === 'start' && angular.isArray(mainDataContainer.policies)) {
                dataContainerHelper.setPoliciesDynamicValues({
                  paymentProrogation: null
                });
              }
              break;
            default:
              break;
          }
        };

        this._getPeriodResourceItem = function(code) {
          var returnValue = null;
          angular.forEach(RESOURCES.PROTECTION_PERIOD_OPTIONS, function(item) {
            if (item.CODE === code) {
              returnValue = item;
            }
          });
          return returnValue;
        };

        /**
         * tłumaczy elementy dla GUI
         */
        this.translate = function() {
          DEFAULT_HEADER_SETTINGS.insurer.label = $filter('translate')('Public.insurer', {
            componentCode: 'Public'
          });
        };

      };

      return new HeaderHelper();
    }
  ])
  .run(['sp2HeaderHelper', 'actionHelper',
    function(sp2HeaderHelper, actionHelper) {
      actionHelper.registerHelper('sp2HeaderHelper', sp2HeaderHelper);
    }
  ]);
