angular.module('ihestiaSsoBase')
  .factory('ihestiaUsersSvc', ['ihestiaUsersAbstractSvc', 'ihestiaConfigHelper',
    function(ihestiaUsersAbstractSvc, ihestiaConfigHelper) {

      var UsersSvc = function() {
        ihestiaUsersAbstractSvc.apply(this, arguments);

        this.resource = 'users';

        this.grid = {
          top: 50,
          skip: 0,
          sortBy: '',
          sortDirection: 'ASC',
          count: ihestiaConfigHelper.get('rest', 'GRID_COUNT')
        };

      };

      return new UsersSvc();
    }
  ]);