angular.module('ihestiaSsoBase.baseInfo')
  .factory('abstractPlatformSessionSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var AbstractPlatformSession = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'reloggeduser/characters';
        this.businessArea = 'session';
        this.port = '8215';

      };
      return AbstractPlatformSession;
    }
  ]);