angular.module('lsnBase.modal')
  .controller('lsnModalCtrl', ['$scope', 'lsnModalGlobalHelper',
    function($scope, lsnModalGlobalHelper){

      /**
       * Nasz główny helper
       * @type {Object}
       */
      $scope.helper = lsnModalGlobalHelper.getCurrentHelper();

      $scope.modalShowed = false;

      /**
       * Init
       * @return {unefined}
       */
      $scope._init = function(){
        // pokazujemy modal dopiero jak się ustawi klasa odpowiedzialna za szerokość
        $scope.modalShowed = true;
      };
  }]);