/**
 * Mapper polis
 */
angular.module('salesPath2').service('toRestPolicyMapper', ['MapperUtils', 'SimplePolicyModel', 'PolicyDiscountModel',
  function (MapperUtils, SimplePolicyModel, PolicyDiscountModel)
  {
    var ToRestPolicyMapper = function ()
    {
      var self = this;
      this.utils = MapperUtils;

      /**
       * mapuje polisy z dataContainera do simplePolicy na wniosku
       * @param {Policy[]} policies polisy
       * @param {DataContainer} dataContainer
       */
      this.mapSimplePolicies = function (policies, dataContainer)
      {
        var simplePolicies = [];
        angular.forEach(policies, function (Policy)
        {
          var simplePolicy = new SimplePolicyModel();
          simplePolicy.setData(
          {
            id: Policy.get('number'),
            frequency: Policy.get('frequency'),
            paymentMethod: Policy.get('paymentMethod'),
            suretyRef: Policy.get('suretyRef'),
            product: Policy.get('product')
          });
          self._mapPolicyDynamicValues(simplePolicy, dataContainer);
          simplePolicies.push(simplePolicy);
        });
        return simplePolicies;
      };

      /**
       * ustawia dodatkowe dynamicValues na prostej polisie
       * @param {SimplePolicy} simplePolicy polisa
       * @param {DataContainer} dataContainer
       */
      this._mapPolicyDynamicValues = function (simplePolicy, dataContainer)
      {
        var dynVals = simplePolicy.product.get('dynamicValues');

        if (dataContainer.productClauses[simplePolicy.id]) {
          dynVals._productClausesRetail = dataContainer.productClauses[simplePolicy.id];
        } else {
          dynVals._productClausesRetail = {};
        }
        dynVals.destinations = dataContainer.destinations;
        //tu bylo uzupelnianie regonu ale przeniesiono to na dynamicValues na osobe
        simplePolicy.product.set('dynamicValues', dynVals);

        self._mapDiscounts(simplePolicy, dataContainer);
      };

      this._mapDiscounts = function (simplePolicy, dataContainer)
      {
        if (angular.isDefined(dataContainer.policiesDiscounts[simplePolicy.id]))
        {
          var discounts = dataContainer.policiesDiscounts[simplePolicy.id];
          var dynVals = simplePolicy.product.get('dynamicValues');

          lsnNg.forEach(discounts, function (element, mainAttrName) //eslint-disable-line consistent-return
          {
            if (mainAttrName === 'promoCodes') {
              if (angular.isString(element.code) && element.code !== '') {
                dynVals.specialsSymbol = element.code;
              } else {
                dynVals.specialsSymbol = null;
              }
              return true; //przerywamy daszy kod
            } else if (mainAttrName === 'discountOnRisk')
            {
              var discountsRisk = [];
              lsnNg.forEach(element, function (discountRisk)
              {
                var discount = new PolicyDiscountModel();
                discount.setData(discountRisk.getData());
                discountsRisk.push(discount);
              });
              simplePolicy.set('discounts', discountsRisk);
            }
            else if (mainAttrName !== 'employee' || discounts[mainAttrName].selected)
            {
              if (mainAttrName !== 'employee')
              {
                dynVals[mainAttrName] = discounts[mainAttrName].selected;
              }

              lsnNg.forEach(element.attributes, function (attrVal, attrName)
              {
                dynVals[attrName] = attrVal;
              });
            }
            else if (mainAttrName === 'employee' && !discounts[mainAttrName].selected)
            {
              lsnNg.forEach(element.attributes, function (attrValue, attrName)
              {
                dynVals[attrName] = null;
              });
            }
          });

          simplePolicy.product.set('dynamicValues', dynVals);
        }
      };
    };

    return new ToRestPolicyMapper();
  }
]);