angular.module('ihestiaWidgets.policyDetails')
  .controller('policyVersionsModalCtrl', ['$scope', 'ihestiaDownloadHelper', 'policiesSvc', 'ihestiaCommonConfirmModalHelper', '$filter', 'policyDetailsContextHelper', 'policiesPrintoutsSvc',
    function($scope, ihestiaDownloadHelper, policiesSvc, ihestiaCommonConfirmModalHelper, $filter, policyDetailsContextHelper, policiesPrintoutsSvc) {

      $scope.modalContext = $scope.helper.modalData.context; // the context of the modal opening: KID or IOD download

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
      };

      $scope.print = function(policyId) {
        if ($scope.modalContext === 'IOD') {
          $scope.printIOD(policyId);
        }

        if ($scope.modalContext === 'KID') {
          $scope.printKID(policyId);
        }
      };

      $scope.printIOD = function(policyId) {
        var noIODModalText = (policyDetailsContextHelper.isClient()) ? $filter('translate')('distributorAboutInformationModalTextClient', {componentCode: 'iKonto'}) : $filter('translate')('distributorAboutInformationModalText', {componentCode: 'iKonto'});
        $scope.$emit('showBlockUi');
        policiesSvc.get(policyId, 'null', 'distributorAboutFile').then(function(res) {
          if (res.status === 200) {
            ihestiaDownloadHelper.downloadFileByTokenId(res.data.token, 'iod.pdf');
          }
        }, function(res) {
          if (res.status === 409) {
            $scope.helper.hideModal();
            ihestiaCommonConfirmModalHelper.showConfirmModal({
              title: $filter('translate')('distributorAboutInformationModalTitle', {componentCode: 'iKonto'}),
              okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
              cancelBtnName: '',
              text: noIODModalText
            });
          }
        }).finally(function() {
          $scope.$emit('hideBlockUi');
        });
      };

      $scope.printKID = function(policyId) {
        $scope.$emit('showBlockUi');
        var params = {
          headers: {
            'Accept': 'application/pdf'
          },
          responseType: 'blob',
          allowedStatuses: [409]
        };

        policiesPrintoutsSvc.get(policyId, null, 'kid', null, null, params).then(function(result) {
          $scope.$emit('hideBlockUi');

          if (result.status === 200) {
            var filename = 'KID.pdf'; // in case if service doesn't return filename
            var contentDisposition = result.headers('Content-Disposition');

            var fileNameString = 'filename=';
            if (angular.isString(contentDisposition) && contentDisposition.indexOf(fileNameString) !== -1) {
              var preCut = contentDisposition.substr(contentDisposition.indexOf(fileNameString) + fileNameString.length);
              if (preCut.indexOf(';') !== -1) {
                preCut = preCut.substr(0, preCut.indexOf(';'));
              }
              filename = decodeURIComponent(preCut).replace(/\"/g, '');
            }

            var blob = new Blob([result.data], {
              type: 'application/pdf'
            });
            saveAs(blob, filename);
          }

        }, function(result) {
          if (result.status === 409) {
            var reader = new FileReader();

            reader.onloadend = function() {
              var data = JSON.parse(reader.result);
              var msg = '';

              angular.forEach(data.messages, function(message) {
                msg += message.text;
              });

              ihestiaCommonConfirmModalHelper.showConfirmModal({
                title: $filter('translate')('distributorAboutInformationModalTitle', {componentCode: 'iKonto'}),
                okBtnName: $filter('translate')('Public.close', {componentCode: 'Public'}),
                cancelBtnName: '',
                text: msg
              });

            };
            reader.readAsText(result.data); // eslint-disable-line
          }

          $scope.$emit('hideBlockUi');
        });
      };

    }
  ]);
