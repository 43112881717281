angular.module('lsnBase.addresses')
  .controller('lsnAddressCtrl', ['$scope', '$element', '$attrs', '$q', '$parse',
    function($scope, $element, $attrs, $q, $parse) {
      $scope.promiseToCancel = $q.defer();
      $scope.addressSvc = $parse($attrs.addressSvc)($scope);
      if ($attrs.visibility) {
        $scope.visibility = $parse($attrs.visibility)($scope);
      }
      $scope.attributeList = ['postalCode', 'post', 'city', 'streetPrefix', 'street', 'house', 'apartment', 'countryCode', 'region', 'district', 'commune'];
      $scope.attributeMap = {};
      var defaultMaxLength = {
        postalCode: 10,
        post: 40,
        city: 40,
        streetPrefix: 20,
        street: 80,
        house: 10,
        apartment: 10,
        countryCode: 10
      };
      $scope.__svcValidatorMap = {};
      $scope.__formName = 'addressForm';
      $scope.disabledAttributes = {}; // pola z ng-disabled
      $scope.useRegex = false; // czy mamy ograniczy znaki w inputach

      $scope.oldValues = {}; // stara wartosci po ktorych zostaly wypelnione pozostale atrybuty
      $scope.blockServices = false; // jeśli adres obcy to bez autocompleterów

      /**
       * powiązanie wyszukiwanego parametru do elementu odpowiedzi w requeście
       * (w przyszłości można przekazywać parametrem do niniejszej dyrektywy, jesli bedzie taka potrzeba)
       * @type {Object}
       */
      var REST_ADDRESSES_RESPONSE_RELATIONS = {
        postalCode: 'postalCodes',
        streetPrefix: 'streetPrefixes',
        street: 'streets',
        city: 'cities',
        region: 'regions',
        district: 'districts',
        commune: 'communes',
      };

      // domyślnie to jest wymagane
      var defaultRequiredFields = ['postalCode', 'city', 'house'];
      $scope.requiredFlags = {};

      /**
       * Przebudowujemy flagi ng-required
       * @return {[type]} [description]
       */
      var rebuildRequiredFields = function() {
        // wsteczna kompatybilność
        var globalRequiredFlag = false;
        if (angular.isDefined($attrs.ngRequired)) {
          globalRequiredFlag = $parse($attrs.ngRequired)($scope);
        } else if (angular.isDefined($attrs.required)) {
          globalRequiredFlag = $attrs.required ? true : false;
        }

        // nadpisujemy wymagalność pól
        var requiredFields = {};
        if ($attrs.overridedRequiredFields) {
          requiredFields = $parse($attrs.overridedRequiredFields)($scope);
        }
        angular.forEach($scope.attributeList, function(attribute) {
          if (angular.isDefined(requiredFields[attribute])) {
            $scope.requiredFlags[attribute] = requiredFields[attribute];
          } else if (defaultRequiredFields.indexOf(attribute) > -1) {
            $scope.requiredFlags[attribute] = globalRequiredFlag;
          } else {
            $scope.requiredFlags[attribute] = false;
          }
        });
      };

      /** ustawiamy ng-disabled, jeśli takie jest życzenie programisty */
      if (angular.isDefined($attrs.disabledAttributes)) {
        $scope.$watch($attrs.disabledAttributes, function(disabledAttributes) {
          if (disabledAttributes) {
            angular.forEach($scope.attributeList, function(attribute) {
              if (disabledAttributes[attribute]) {
                $scope.disabledAttributes[attribute] = disabledAttributes[attribute];
              } else {
                $scope.disabledAttributes[attribute] = false;
              }
            });
          }
        }, true);
      }

      /* automagiczne walidatory z usług start */
      if (angular.isDefined($attrs.svcValidatorMap)) {
        $scope.$watch($attrs.svcValidatorMap, function(svcValidatorMap) {
          if (svcValidatorMap) {
            $scope.__svcValidatorMap = svcValidatorMap;
          }
        }, true);
      }
      if (angular.isDefined($attrs.formName)) {
        $scope.__formName = $attrs.formName;
      }

      if (angular.isDefined($attrs.formSubmitted)) {
        $scope.$watch($attrs.formSubmitted, function(formSubmitted) {
          if (formSubmitted) {
            $scope.formSubmitted = formSubmitted;
          }
        }, true);
      }

      if (angular.isDefined($attrs.hideStreetPart)) {
        $scope.hideStreetPart = $parse($attrs.hideStreetPart)($scope);
      }
      var extendedMaxLength = angular.copy(defaultMaxLength);
      if (angular.isDefined($attrs.maxLength)) {
        angular.forEach($parse($attrs.maxLength)($scope), function(maxLength, property) {
          extendedMaxLength[property] = maxLength;
        });
      }
      $scope.maxLength = extendedMaxLength;

      if (angular.isDefined($attrs.hideStreetPrefixPart)) {
        $scope.hideStreetPrefixPart = $parse($attrs.hideStreetPrefixPart)($scope);
      }
      if (angular.isDefined($attrs.showPost)) {
        $scope.showPost = $parse($attrs.showPost)($scope);
      }
      if (angular.isDefined($attrs.hideApartment)) {
        $scope.hideApartment = $parse($attrs.hideApartment)($scope);
      }
      /* automagiczne walidatory z usług end*/

      if (angular.isDefined($attrs.useRegex)) {
        $scope.useRegex = $attrs.useRegex;
      }

      if ($scope.useRegex) {
        $scope.regexCity = '\'^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -]*$\'';
        $scope.regexPost = '\'^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -]*$\'';
        $scope.regexStreet = '\'^[0-9a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -]*$\'';
        $scope.regexStreetPrefix = '\'^[0-9a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -/*]*$\'';
        $scope.regexHouse = '\'^[0-9a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹÄäÖöÜüẞß -/*]*$\'';
        $scope.regexApartment = '\'^[0-9]*$\'';
      } else {
        $scope.regexCity = '';
        $scope.regexPost = '';
        $scope.regexStreet = '';
        $scope.regexStreetPrefix = '';
        $scope.regexHouse = '';
        $scope.regexApartment = '';
      }

      if ($attrs.attributeMap) {
        var attributeMap = $parse($attrs.attributeMap)($scope);

        angular.forEach(attributeMap, function(value, key) {
          $scope.attributeMap[key] = value;
        });
      }

      if ($attrs.popoverPlacement) {
        $scope.popoverPlacement = $attrs.popoverPlacement;
      }

      // uzupełniamy brakujące atrybuty w mapie
      angular.forEach($scope.attributeList, function(code) {
        if (angular.isUndefined($scope.attributeMap[code])) {
          $scope.attributeMap[code] = code;
        }
      });


      $scope.bindedInputs = {};
      $scope.bindedInputs[$scope.attributeMap.city] = null;
      $scope.bindedInputs[$scope.attributeMap.postalCode] = null;
      $scope.bindedInputs[$scope.attributeMap.streetPrefix] = null;
      $scope.bindedInputs[$scope.attributeMap.street] = null;

      $scope.addressType = 'flat';
      $scope.isDisabled = false;

      $scope.addressForm = {};

      $scope.$watch($attrs.ngDisabled, function(val) {
        $scope.isDisabled = val;
      });

      if (angular.isDefined($attrs.showRegion)) {
        $scope.showRegion = $parse($attrs.showRegion)($scope);
        if ($scope.showRegion) {
          $scope.bindedInputs[$scope.attributeMap.region] = null;
          $scope.bindedInputs[$scope.attributeMap.district] = null;
          $scope.bindedInputs[$scope.attributeMap.commune] = null;
        } else {
          delete $scope.bindedInputs[$scope.attributeMap.region];
          delete $scope.bindedInputs[$scope.attributeMap.district];
          delete $scope.bindedInputs[$scope.attributeMap.commune];
        }
      }

      /** Określanie co jest a co nie jest wymagane */
      if (angular.isDefined($attrs.overridedRequiredFields)) {
        $scope.$watch($attrs.overridedRequiredFields, rebuildRequiredFields, true);
      }
      if (angular.isDefined($attrs.ngRequired)) {
        $scope.$watch($attrs.ngRequired, rebuildRequiredFields, true);
      }
      rebuildRequiredFields();

      $scope.$watch($attrs.address, function(val) {
        $scope.address = val;
      });
      $scope.$watch($attrs.dirtyStreet, function(val) {
        $scope.dirtyStreet = val;
      });

      if (angular.isDefined($attrs.callbacks)) {
        $scope.callbacks = $parse($attrs.callbacks)($scope);
      }

      if ($attrs.invalidTexts) {
        $scope.invalidTexts = $parse($attrs.invalidTexts)($scope);
        $scope.invalidTextPostalCode = $scope.invalidTexts[$scope.attributeMap.postalCode];
        $scope.invalidTextCity = $scope.invalidTexts[$scope.attributeMap.city];
        $scope.invalidTextStreetPrefix = $scope.invalidTexts[$scope.attributeMap.streetPrefix];
        $scope.invalidTextStreet = $scope.invalidTexts[$scope.attributeMap.street];
        $scope.invalidTextHouse = $scope.invalidTexts[$scope.attributeMap.house];
        $scope.invalidTextRegion = $scope.invalidTexts[$scope.attributeMap.region];
        $scope.invalidTextDistrict = $scope.invalidTexts[$scope.attributeMap.district];
        $scope.invalidTextCommune = $scope.invalidTexts[$scope.attributeMap.commune];
      }

      $scope.$watch($attrs.errors, function(val) {
        // todo zrefaktoryzować poniższe do forEacha
        if (!val || !val[$scope.attributeMap.postalCode]) {
          $scope.hasErrorPostalCode = false;
        } else {
          $scope.hasErrorPostalCode = !!val[$scope.attributeMap.postalCode];
          $scope.invalidTextPostalCode = val[$scope.attributeMap.postalCode].message;
        }

        if (!val || !val[$scope.attributeMap.city]) {
          $scope.hasErrorCity = false;
        } else {
          $scope.hasErrorCity = !!val[$scope.attributeMap.city];
          $scope.invalidTextCity = val[$scope.attributeMap.city].message;
        }

        if (!val || !val[$scope.attributeMap.streetPrefix]) {
          $scope.hasErrorStreetPrefix = false;
        } else {
          $scope.hasErrorStreetPrefix = !!val[$scope.attributeMap.streetPrefix];
          $scope.invalidTextStreetPrefix = val[$scope.attributeMap.streetPrefix].message;
        }

        if (!val || !val[$scope.attributeMap.street]) {
          $scope.hasErrorStreet = false;
        } else {
          $scope.hasErrorStreet = !!val[$scope.attributeMap.street];
          $scope.invalidTextStreet = val[$scope.attributeMap.street].message;
        }

        if (!val || !val[$scope.attributeMap.house]) {
          $scope.hasErrorHouse = false;
        } else {
          $scope.hasErrorHouse = !!val[$scope.attributeMap.house];
          $scope.invalidTextHouse = val[$scope.attributeMap.house].message;
        }

        if (!val || !val[$scope.attributeMap.apartment]) {
          $scope.hasErrorApartment = false;
        } else {
          $scope.hasErrorApartment = !!val[$scope.attributeMap.apartment];
          $scope.invalidTextApartment = val[$scope.attributeMap.apartment].message;
        }

        if (!val || !val[$scope.attributeMap.region]) {
          $scope.hasErrorRegion = false;
        } else {
          $scope.hasErrorRegion = !!val[$scope.attributeMap.region];
          $scope.invalidTextRegion = val[$scope.attributeMap.region].message;
        }

        if (!val || !val[$scope.attributeMap.district]) {
          $scope.hasErrorDistrict = false;
        } else {
          $scope.hasErrorDistrict = !!val[$scope.attributeMap.district];
          $scope.invalidTextDistrict = val[$scope.attributeMap.district].message;
        }

        if (!val || !val[$scope.attributeMap.commune]) {
          $scope.hasErrorCommune = false;
        } else {
          $scope.hasErrorCommune = !!val[$scope.attributeMap.commune];
          $scope.invalidTextCommune = val[$scope.attributeMap.commune].message;
        }
      });

      $scope.init = function() {
        var excludedInputs = $parse($attrs.excludedInputs)($scope);
        angular.forEach(excludedInputs, function(name) {
          delete $scope.bindedInputs[name];
        });

        $scope.$watch('address.' + $scope.attributeMap.countryCode, function() {
          $scope.refreshBlockServices();
        });
      };

      // typ nieruchomości
      $attrs.$observe('addressType', function(addressType) {
        if (angular.isDefined(addressType) && angular.isString(addressType)) {
          $scope.addressType = addressType;
        }

        if ($scope.addressType === 'house') {
          $scope.address[$scope.attributeMap.apartment] = null;
        }
      });

      /**
       * jak potrzebujemy kod z mapy
       * @param  {[type]} attributeName [description]
       * @return {[type]}               [description]
       */
      $scope.getAttributeCodeFromName = function(attributeName) {
        var baseName;
        angular.forEach($scope.attributeMap, function(value, code) {
          if (attributeName === value) {
            baseName = code;
          }
        });
        return baseName;
      };

      /**
       * przygotowuje zapytanie dla usługi wyszukiwania adresu autocomletera
       *
       * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
       * @param {String} val
       */
      $scope.getQueryAutocomplete = function(focusInputName, val) {
        var query = {};

        // poprawka dla maski na kodzie pocztowym
        if (focusInputName === $scope.attributeMap.postalCode && val.indexOf('_') !== -1) {
          val = val.substring(0, val.indexOf('_'));
        }
        query[$scope.getAttributeCodeFromName(focusInputName)] = val;
        if (focusInputName !== $scope.attributeMap.postalCode) // dla zmian na kodzie pocztowym ignorowane są pozostałe pola
        {
          angular.forEach($scope.bindedInputs, function(cVal, name) {
            if (!$scope.address[name]) // pomijamy niepodpięte pola adresu, ten z którego przyszła zmiana i puste
            {
              return true;
            }
            if (focusInputName === $scope.attributeMap.street && name === $scope.attributeMap.streetPrefix) // przy edycji ulicy ignorujemy prefix ulicy
            {
              return true;
            }
            query[$scope.getAttributeCodeFromName(name)] = $scope.address[name];
          });
        }

        return query;
      };

      /**
       * wyszukiwanie adresu dla autocompletera
       *
       * @param {String} val
       * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
       */
      $scope.searchAddressAutocomplete = function(val, focusInputName) {
        if ($scope.blockServices) {
          // nie chcemy wyszukiwać
          return [];
        }

        var query = $scope.getQueryAutocomplete(focusInputName, val);
        $scope['loading' + focusInputName.ucFirst()] = true;

        if ($scope.loadingElements) {
          $scope.promiseToCancel.reject('newRequest');
          $scope.promiseToCancel = $q.defer();
        }

        $scope.loadingElements = true;
        return $scope.addressSvc.findByParams({
          query: query,
          pageSize: 10,
          top: 10,
          sortBy: 'id',
          sortDirection: 'DESC',
          httpParams: {
            timeout: $scope.promiseToCancel.promise.then(angular.noop, angular.noop) // abort na promise
          }
        }).then(function(res) {
          $scope['loading' + focusInputName.ucFirst()] = false;
          $scope.loadingElements = false;
          var responseDataNode = REST_ADDRESSES_RESPONSE_RELATIONS[$scope.getAttributeCodeFromName(focusInputName)];
          if (angular.isUndefined(res) || angular.isUndefined(res.data) || angular.isUndefined(res.data.item)) { // jeśli pusta odpowiedź to wrzucamy pustą tablicę zgodną ze strukturą odpowiedzi
            res = {
              data: {
                item: {}
              }
            };
            res.data.item[responseDataNode] = {
              items: []
            };
          }

          return res.data.item[responseDataNode].items;
        }, angular.noop);
      };

      /**
       * przygotowuje zapytanie dla usługi wyszukiwania adresu
       *
       * @param {String} val
       * @param {*} focusInputName atrybut name inputa na którym nastąpiła zmiana
       */
      $scope.getQuery = function(focusInputName) {
        var query = {};
        angular.forEach($scope.bindedInputs, function(val, name) {
          if (!$scope.address[name]) // pomijamy niepodpięte pola adresu, ten z którego przyszła zmiana i puste
          {
            return true;
          }
          if (focusInputName === $scope.attributeMap.street && name === $scope.attributeMap.streetPrefix) // przy edycji ulicy ignorujemy prefix ulicy
          {
            return true;
          }
          if (focusInputName !== $scope.attributeMap.postalCode || name === $scope.attributeMap.postalCode) // jeśli zmiana na postalCode to tylko po postalCode szukamy
          {
            query[$scope.getAttributeCodeFromName(name)] = $scope.address[name];
          }
        });

        return query;
      };

      /**
       * wyszukuje adres dla podanych inputów
       * @param  {*} response     callback
       * @param  {[*} bindedInputs lista inputów
       */
      $scope.onSelectAddress = function(val, focusInputName) {
        if (!$scope.address[focusInputName]) {
          return false;
        }
        var query = $scope.getQuery(focusInputName);
        $scope.addressSvc.findByParams({
          query: query,
          pageSize: 10,
          top: 10,
          sortBy: 'id',
          sortDirection: 'DESC'
        }).then(function(res) {
          $scope.fillAddress(focusInputName, res.data.item);
        }, angular.noop);

        $scope.oldValues[focusInputName] = val;
      };

      $scope.onBlurAddress = function(val, focusInputName) {
        val = $scope.address[focusInputName]; // przychodzi w metodzie undefined - wiec go nadpisujemy prawidlowa wartoscia. Paramertru z funkcji nie usuwam, bo nie znam wszystkich uzyc tej dyrektywy, a mozna nadpisac w niej tpl i wtedy nie bedzie dzialac prawidlowo ... 
        // jesli nic sie nie zmienilo lub blokujemy usługi
        if (!$scope.address[focusInputName] || $scope.oldValues[focusInputName] === val || $scope.blockServices) {
          return false;
        }

        var query = $scope.getQuery(focusInputName);
        $scope.addressSvc.findByParams({
          query: query,
          pageSize: 10,
          top: 10,
          sortBy: 'id',
          sortDirection: 'DESC'
        }).then(function(res) {
          $scope.fillAddress(focusInputName, res.data.item);
        }, angular.noop);

        $scope.oldValues[focusInputName] = val;

        if (angular.isDefined($scope.callbacks) && angular.isFunction($scope.callbacks['onBlur.' + focusInputName])) {
          $scope.callbacks['onBlur.' + focusInputName](val);
        }
      };

      /**
       * wypelnia wartości inputów
       * @param  {*} focusInputName     callback
       * @param  {[*} response odpowiedź z usługi z wartościami adresów
       */
      $scope.fillAddress = function(focusInputName, response) {
        if (typeof response === 'undefined') {
          return false;
        }

        angular.forEach($scope.bindedInputs, function(val, name) {
          // jeśli pole już wypełnione to pomijamy
          if (!!$scope.address[name] && focusInputName !== $scope.attributeMap.postalCode && name !== $scope.attributeMap.streetPrefix) // dla zmiany kodu pocztowego zawsze nadpisujemy wartości
          {
            return true;
          }
          var responseDataNode = REST_ADDRESSES_RESPONSE_RELATIONS[$scope.getAttributeCodeFromName(name)];
          if (response[responseDataNode].items.length === 1) // jeśli mamy tylko jedną podpowiedź to wypełmniamy input
          {
            $scope.address[name] = response[responseDataNode].items[0];
          } else if (focusInputName === $scope.attributeMap.postalCode && name !== $scope.attributeMap.postalCode) // jeśli zmiana kodu pocztowego to inne pola czyścimy jeśli wiele możliwości
          {
            $scope.address[name] = '';
          }
        });
      };

      $scope.refreshBlockServices = function() {
        if ($scope.address && $scope.address[$scope.attributeMap.countryCode] && $scope.address[$scope.attributeMap.countryCode] !== 'PL') {
          $scope.blockServices = true;
        } else {
          $scope.blockServices = false;
        }
      };

      $scope.init();
    }
  ]);