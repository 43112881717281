angular.module('ihestiaRestServicesBase', [
  'ihestiaSsoBase.baseInfo',
  'ihestiaCommonDirectives.errorHandler',
  'ihestiaCommonDirectives.configHelper',
  'ihestiaCommonDirectives.labelServiceHelper',
  'ihestiaCommonDirectives.labelActionHelper',
  'ihestiaCommonDirectives.tryEscapeHtml',
  'lsnBase.modelHelper',
  'lsnBase.models',
  'lsnBase.deprecated.models', //stare nazewnictwo modeli (bez przedrostków) uzywane w wiekszosci aplikacji iHestii
  'lsnBase.bootstrap',
  'lsnBase.detectorUserActvie',
  'lsnNg'
])

.config(['$httpProvider', 'lsnModelFactoryParamsProvider', function ($httpProvider, lsnModelFactoryParamsProvider) {
  $httpProvider.interceptors.push('IHestiaRestServiceInterceptor');
  lsnModelFactoryParamsProvider.modelPrefixes.push('IHestia');
  lsnModelFactoryParamsProvider.allowNotVersioned = true;
}]);