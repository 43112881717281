angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonAbstractComponentRegisterSvc', ['ihestiaCommonAbstractComponentManagementSvc',
    function(ihestiaCommonAbstractComponentManagementSvc) {

      var AbstractComponentRegister = function() {
        ihestiaCommonAbstractComponentManagementSvc.apply(this, arguments);

        this.businessArea = 'component-register';

      };
      return AbstractComponentRegister;
    }
  ]);