angular.module('lsnBase.models')
  .factory('LsnApplicationModelConstantsV2', [function() {
    var ApplicationModelConstants = {};

    // statusy kalkulacji
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION = 'Calculation';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_ACCEPTED = 'CalculationAccepted';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_DELETED = 'CalculationDeleted';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_EXPIRED = 'CalculationExpired';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_OFFERED = 'CalculationOffered';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_REJECT = 'CalculationRejected';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING = 'CalculationUnderwriting';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_CANCELLED = 'CalculationCancelled';
    ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING_EXPIRED = 'CalculationUnderwritingExpired';

    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS = {};
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION] = 'kalkulacja';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_ACCEPTED] = 'kalkulacja zaakceptowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_DELETED] = 'kalkulacja usunięta';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_EXPIRED] = 'kalkulacja przeterminowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_OFFERED] = 'kalkulacja zaofertowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING] = 'Wniosek';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_CANCELLED] = 'Wniosek anulowany';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING_EXPIRED] = 'Wniosek przeterminowany';
    ApplicationModelConstants.DICTIONARY_CALCULATION_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_REJECT] = 'Wniosek odrzucony';

    // statusy ofert
    ApplicationModelConstants.OFFER_STATUS_IOFFER_ACCEPTED = 'IOfferAccepted';
    ApplicationModelConstants.OFFER_STATUS_IOFFER = 'IOffer';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_CANCELLED = 'IOfferCancelled';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_EXPIRED = 'IOfferExpired';
    ApplicationModelConstants.OFFER_STATUS_IOFFER_REJECTED = 'IOfferRejected';
    ApplicationModelConstants.OFFER_STATUS_OFFER_ACCEPTED = 'OfferAccepted';
    ApplicationModelConstants.OFFER_STATUS_OFFER = 'Offer';
    ApplicationModelConstants.OFFER_STATUS_OFFER_CANCELLED = 'OfferCancelled';
    ApplicationModelConstants.OFFER_STATUS_OFFER_EXPIRED = 'OfferExpired';
    ApplicationModelConstants.OFFER_STATUS_OFFER_REJECTED = 'OfferRejected';
    ApplicationModelConstants.OFFER_STATUS_OFFER_DELETED = 'OfferDeleted';
    ApplicationModelConstants.OFFER_STATUS_DEMAND_PAYMENT = 'DemandPayment';

    ApplicationModelConstants.DICTIONARY_OFFER_STATUS = {};
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_ACCEPTED] = 'iOferta spolisowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER] = 'iOferta aktualna';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_CANCELLED] = 'iOferta anulowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_EXPIRED] = 'iOferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_REJECTED] = 'iOferta odrzucona';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_ACCEPTED] = 'Oferta spolisowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER] = 'Oferta aktualna';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_CANCELLED] = 'Oferta anulowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_EXPIRED] = 'Oferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_REJECTED] = 'Oferta odrzucona przez system';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_DELETED] = 'Oferta usunięta';
    ApplicationModelConstants.DICTIONARY_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_DEMAND_PAYMENT] = 'Żądanie płatności';

    // slownik łączony statsusów kalkulacji i ofert
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS = {};
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION] = 'kalkulacja';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_ACCEPTED] = 'kalkulacja zaakceptowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_DELETED] = 'kalkulacja usunięta';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_EXPIRED] = 'kalkulacja przeterminowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_OFFERED] = 'kalkulacja zaofertowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_REJECT] = 'kalkulacja odrzucona';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_ACCEPTED] = 'iOferta spolisowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER] = 'iOferta aktualna';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_CANCELLED] = 'iOferta anulowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_EXPIRED] = 'iOferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_IOFFER_REJECTED] = 'iOferta odrzucona';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_ACCEPTED] = 'Oferta spolisowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER] = 'Oferta aktualna';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_CANCELLED] = 'Oferta anulowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_EXPIRED] = 'Oferta przeterminowana';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_REJECTED] = 'Oferta odrzucona przez system';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.OFFER_STATUS_OFFER_DELETED] = 'Oferta usunięta';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING] = 'Wniosek';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_CANCELLED] = 'Wniosek anulowany';
    ApplicationModelConstants.DICTIONARY_CALCULATION_AND_OFFER_STATUS[ApplicationModelConstants.CALCULATION_STATUS_CALCULATION_UNDERWRITING_EXPIRED] = 'Wniosek przeterminowany';


    
    // typy operacji
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_NEW = 'New';
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW = 'Renew';
    ApplicationModelConstants.APPLICATION_OPERATION_TYPE_EXTEND_INSURANCE = 'ExtendInsurance';
    ApplicationModelConstants.DICTIONARY_CALCULATION_OPERATION_TYPE = {};
    ApplicationModelConstants.DICTIONARY_CALCULATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_NEW] = 'Tryb tworzenia';
    ApplicationModelConstants.DICTIONARY_CALCULATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_RENEW] = 'Tryb wznowienia';
    ApplicationModelConstants.DICTIONARY_CALCULATION_OPERATION_TYPE[ApplicationModelConstants.APPLICATION_OPERATION_TYPE_EXTEND_INSURANCE] = 'Tryb dokupienia';

    return ApplicationModelConstants;
  }]);
