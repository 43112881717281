angular.module('ihestiaRestServicesBase')
.service('offersPrintoutsSvc', ['IHestiaRestServiceAbstractDef',
  function(IHestiaRestServiceAbstractDef) {
    var OffersPrintouts = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'offers';
      this.businessArea = 'printouts';
      this.port = '8208';
      this.path = 'RestPrintouts';

    };

    /**
    * Konstruktor v2
    */
    var OffersPrintoutsV2 = function() {
      OffersPrintouts.apply(this, arguments);
      this.apiVersion = 'v2';     
    };

    /**
    * Konstruktor v1
    */
    var OffersPrintoutsV1 = function() {
      OffersPrintouts.apply(this, arguments);      
    };

    return IHestiaRestServiceAbstractDef.setApiConstructors({
      v1: OffersPrintoutsV1,
      v2: OffersPrintoutsV2
    });

  }
]);