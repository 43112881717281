/**
 * Directive placed on person edit form (it is required that the form has controls: 'firstName' and 'lastName')
 * Detects whether any of 'firstName' or 'lastName' change and updates editability object
 * Options passed through no-full-name-change-options attribute
 * Functionality only applied if options object is defined and 'applyIf' node of options object is truthy
 */
angular.module('ihestiaWidgets.policyDetails')
  .directive('noFullNameChange', ['$parse', function($parse) {
    return {
      restrict: 'A',
      require: 'form',
      scope: false,
      link: function(scope, elem, attrs, form) {
        scope.options = null;
        scope.initialData = {
          firstName: null,
          firstNameInitialized: false,
          lastName: null,
          lastNameInitialized: false
        };
        scope.form = null;

        /**
         * 1. Directive options are set immidiately
         * 2. Initial form values are set after form controls are instantiated (watching $modelValue is defined and of type string)
         * 3. Model value is watched and form control editability is set after initial form values are set.
         */
        scope.init = function() {
          scope.options = attrs.noFullNameChangeOptions ? $parse(attrs.noFullNameChangeOptions)(scope) : null;
          scope.options.editability = {
            firstName: true,
            lastName: true
          };

          if (scope.options && scope.options.applyIf) {
            scope.$watch('{0}.firstName.$modelValue'.format(form.$name), function(val) { // waiting for form control instantiation
              if (val && typeof val === 'string') {
                if (scope.initialData.firstNameInitialized) { // main functionality
                  scope.options.editability.lastName = scope.initialData.firstName === val;
                } else { // initialization
                  scope.initialData.firstNameInitialized = true;
                  scope.initialData.firstName = val;
                }
              }
            });
            scope.$watch('{0}.lastName.$modelValue'.format(form.$name), function(val) {
              if (val && typeof val === 'string') {
                if (scope.initialData.lastNameInitialized) {
                  scope.options.editability.firstName = scope.initialData.lastName === val;
                } else {
                  scope.initialData.lastNameInitialized = true;
                  scope.initialData.lastName = val;
                }
              }
            });
          }
        };
        scope.init();
      }
    };
  }]);
