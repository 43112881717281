angular.module('salesPath2')
  /**
   * helper do usług pobierających różne słowniki
   */
  .service('dictionaryServiceHelper', ['ihestiaRestDictionaryPkdsSvc', 'ihestiaPkdHelper', 'dictionaryBanksSvc', 
            'ihestiaRestDictionaryAddressesSvc', 'ihestiaDictionaryHelper', 'ihestiaRestVehicleDictionaryBrandsSvc', 
            'ihestiaRestVehicleDictionaryModelsSvc', 'ihestiaRestVehicleDictionaryTypesSvc', 'ihestiaRestVehicleDictionaryRegistrationTypesSvc',
            'ihestiaRestVehicleDictionaryBodyTypesSvc', 'ihestiaRestVehicleDictionaryFuelTypesSvc', 'ihestiaRestVehicleDictionaryGearsTypesSvc',
            'ihestiaRestVehicleDictionaryPowerUnitsSvc', '$q',
    function(ihestiaRestDictionaryPkdsSvc, ihestiaPkdHelper, dictionaryBanksSvc, 
              ihestiaRestDictionaryAddressesSvc, ihestiaDictionaryHelper, ihestiaRestVehicleDictionaryBrandsSvc, 
              ihestiaRestVehicleDictionaryModelsSvc, ihestiaRestVehicleDictionaryTypesSvc, ihestiaRestVehicleDictionaryRegistrationTypesSvc,
              ihestiaRestVehicleDictionaryBodyTypesSvc, ihestiaRestVehicleDictionaryFuelTypesSvc, ihestiaRestVehicleDictionaryGearsTypesSvc,
              ihestiaRestVehicleDictionaryPowerUnitsSvc, $q) {
      var DictionaryServiceHelper = function() {
        var self = this;
        /**
         * promise używany do anulowania requesta
         * Do użycia w dowolnym serwisie poniżej.
         * UWAGA
         * jeśli możliwa jest sytuacja, w której co najmniej 2 serwisy będą jednocześnie używać tego promise-a, to należy utworzyć osobny
         * @type {[type]}
         */
        this.promiseToCancel = $q.defer();

        /**
         * czy trwa zapytanie do usługi
         * Wykorzystywane w połączneiu z promiseToCancel
         * @type {Boolean}
         */
        this.processingRequest = false;

        /**
         * pobiera dane dotyczące kodu pkd z usługi
         * @param  {String} code kod/symbol pkd
         * @return {HttpPromise}
         */
        this.fetchPkd = function(code, callback, errorCallback, httpParams) {
          return ihestiaRestDictionaryPkdsSvc.get(code, null, null, callback, errorCallback, httpParams);
        };
        /**
         * szuka kodów pkd z usługi wykluczając przekazane w selectedPkd kody oraz zwraca je w sformatowanej postaci.
         * @param  {String} query fraza
         * @param  {Array} selectedPkd wykluczenia kodów
         * @return {HttpPromise}
         */
        this.findPkds = function(query, selectedPkd) {
          return ihestiaPkdHelper.findPkds(query, selectedPkd);
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania marki pojazdów
        * @return {ihestiaRestVehicleDictionaryBrandsSvc}
        */
        this.getVehicleCategoryService = function() {
          return ihestiaRestVehicleDictionaryRegistrationTypesSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania marki pojazdów
        * @return {ihestiaRestVehicleDictionaryBrandsSvc}
        */
        this.getVehicleBrandsService = function() {
          return ihestiaRestVehicleDictionaryBrandsSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania model pojazdów
        * @return {ihestiaRestVehicleDictionaryModelsSvc}
        */
        this.getVehicleModelsService = function() {
          return ihestiaRestVehicleDictionaryModelsSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania typów pojazdów
        * @return {ihestiaRestVehicleDictionaryTypesSvc}
        */
        this.getVehicleTypesService = function() {
          return ihestiaRestVehicleDictionaryTypesSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania typów nadwozi
        * @return {ihestiaRestVehicleDictionaryTypesSvc}
        */
        this.getVehicleBodyTypesService = function() {
          return ihestiaRestVehicleDictionaryBodyTypesSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania typów paliwa
        * @return {ihestiaRestVehicleDictionaryTypesSvc}
        */
        this.getVehicleFuelTypesService = function() {
          return ihestiaRestVehicleDictionaryFuelTypesSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania typów skrzyń biegów
        * @return {ihestiaRestVehicleDictionaryTypesSvc}
        */
        this.getVehicleGearsTypesService = function() {
          return ihestiaRestVehicleDictionaryGearsTypesSvc;
        };

        /**
        * zwraca klasę z serwisem do wyszukiwania rodzai jesdnostek mocy (KM/KW)
        * @return {ihestiaRestVehicleDictionaryTypesSvc}
        */
        this.getVehiclePowerUnitsService = function() {
          return ihestiaRestVehicleDictionaryPowerUnitsSvc;
        };

        /**
         * wyszukuje bank
         * @param  {String} query fraza
         * @return {HttpPromise}
         */
        this.findBank = function(query) {
          var deferred = $q.defer();
          if (self.processingRequest) {
            self.promiseToCancel.reject('newRequest');
            self.promiseToCancel = $q.defer();
          }
          self.processingRequest = true;
          dictionaryBanksSvc.findByParams({
            query: query,
            top: 0, //bez 0 tutaj, nie wyszukuja sie osoby
            pageSize: 10,
            httpParams: {
              timeout: self.promiseToCancel.promise.then(angular.noop, angular.noop) //abort na promise
            }
          }).then(function(resp) {
            self.processingRequest = false;
            deferred.resolve(resp);
          }, function(resp) {
            self.processingRequest = false;
            deferred.reject(resp);
          });
          return deferred.promise;
        };
        /**
         * zwraca sparsowane dane dotyczące kodu pkd (okd, opis)
         * @param  {Object} responseData
         * @return {Object}
         */
        this.getPkdData = function(responseData) {
          return ihestiaPkdHelper.getPkdData(responseData);
        };
        /**
         * zwraca klasę z serwisem do wyszukiwania adresu
         * @return {ihestiaRestAbstractDictionariesSvc}
         */
        this.getDictionaryAddressService = function() {
          return ihestiaRestDictionaryAddressesSvc;
        };
        /**
         * pobiera wybrany słownik (cały do list rozwijanych)
         * @param  {String} - nazwa slownika
         * @return {HttpPromise}
         */
         this.getDictionary = function(dictName, param) {
            return ihestiaDictionaryHelper.getDict(dictName, param);
         };

      };
      return new DictionaryServiceHelper();
    }
  ]);