angular.module('salesPath2')
  .service('errorHelper', ['MessageModelConstants', 'mainErrorCollection', 'dataContainerHelper', 'CONSTANTS',
    function(MessageModelConstants, mainErrorCollection, dataContainerHelper, CONSTANTS) {
      var ErrorHelper = function() {
        var self = this;

        /**
         * kody komuniaktów wyświetlanych na konkretnych stronach aplikacji
         * @type {Object}
         */
        self.pageMessages = {};
        self.pageMessages[CONSTANTS.PAGE_PRODUCT_COMMUNICATION] = ['U000096', 'U000097', 'U000098', 'U000099'];
        self.pageMessages[CONSTANTS.PAGE_SUMMARY] = ['U000092', 'U000093', 'U000094', 'U000095'];

        this.objectTypeToError = {
          'person': 'Persons',
          'organization': 'Organizations',
          'vehicle': 'Vehicles',
          'localization': 'Estates'
        };

        this.getErrorLevelForObject = function(objectType, objectClientId) {

          var errorLevel = null; //brak błędów
          lsnNg.forEach(self.getErrorsForObject(objectType, objectClientId), function(error) {
            if (error.type.toUpperCase() === MessageModelConstants.MESSAGE_TYPE_ERROR) {
              errorLevel = MessageModelConstants.MESSAGE_TYPE_ERROR;
              return false;
            }
            if (errorLevel === null && error.type.toUpperCase() === MessageModelConstants.MESSAGE_TYPE_WARN) {
              errorLevel = MessageModelConstants.MESSAGE_TYPE_WARN;
            }
            return true;
          });
          return errorLevel;
        };

        this.getErrorsForObject = function(objectType, objectClientId) {
          if (objectClientId) {
            var object = dataContainerHelper.getObject(objectType, objectClientId);
            var errors = [];
            var objectId = object.metaData.get('id');
            angular.forEach(mainErrorCollection.all(), function(error) {
              if (self.errorHasObject(error, self.objectTypeToError[objectType], objectId)) {
                errors.push(error);
              }
            });
            return errors;
          } else {
            return [];
          }
        };

        this.getErrorsForRisks = function(params) {
          var errors = {};

          if (angular.isArray(params.risks)) {
            angular.forEach(params.risks, function(risk) {
              angular.forEach(mainErrorCollection.all(), function(error) {
                if (error.riskParams.riskCode === risk.code && error.riskParams.productCode === risk.productCode &&
                  (!params.productType || !params.objectClientId || (self.objectTypeToError[params.productType] === error.riskParams.objectType && params.objectClientId === error.riskParams.objectId))) {
                  //jesli pobieramy ryzyka tylko pod konkretny obiekt to weryfikujemy rowniez pod tym kontem
                  if (angular.isUndefined(errors[risk.productCode])) {
                    errors[risk.productCode] = {};
                  }
                  //poki co uznajemy ze na jednym ryzyku moze byc jeden blad
                  errors[risk.productCode][risk.code] = error;
                }
              });
            });
          }

          return errors;
        };

        /**
         * [getErrorsFieldsForRisk pobiera obiekt ryzyka z uzupelnionymi obiektami bledow]
         * @param  {[type]} params [description]
         * params = {
            productType: CONSTANTS.PRODUCT_TYPE_PERSON,
            objectClientId: clientId,
            risk:{
              code: CONSTANTS.PRODUCT_OCZP,
              productCode: CONSTANTS.PRODUCT_OCZP
              variant: CONSTANTS.VARIANT_I
            }
          }
         * @return {[type]}        [description]
         */
        this.getErrorsFieldsForRisk = function(params) {
          var errorFields = {};

          angular.forEach(mainErrorCollection.all(), function(error) {
            //sprawdzamy zgodność kodu produktu i ryzyka i opcjonalnie wariantu ubezpieczenia (jeśli przekazano w params i ustawiono mu jakas wartosc)
            if (error.riskParams.riskCode === params.risk.code && error.riskParams.productCode === params.risk.productCode && (!params.risk.variant || error.riskParams.variant.indexOf(params.risk.variant) !== -1) &&
              (!params.productType || !params.objectClientId || (self.objectTypeToError[params.productType] === error.riskParams.objectType && params.objectClientId === error.riskParams.objectId))
            ) {
              //jesli pobieramy ryzyka tylko pod konkretny obiekt to weryfikujemy rowniez pod tym kontem
              self.getErrorRiskFields(error, errorFields);
            }
          });

          return errorFields;
        };

        /**
         * [getErrorRiskFields dodaje kolejne informacje o bledach do obudowanego erroru ryzyka]
         * @param  {ErrorEntityModel} error
         * @param  {object} errorFields - obudowany error ryzyka
         */
        this.getErrorRiskFields = function(error, errorFields) {
          var splitedErrorObject = error.attributes.objects[0].split('.');

          if (splitedErrorObject[splitedErrorObject.length - 2] === 'RiskDynamicValues') {
            if (angular.isUndefined(errorFields.dynamicValues)) {
              errorFields.dynamicValues = {};
            }

            var attrName = splitedErrorObject[splitedErrorObject.length - 1];
            errorFields.dynamicValues[attrName] = {
              message: error.message,
              type: error.type
            };
          }
        };

        this.errorHasObject = function(error, objectPrefix, objectId) {
          var hasObject = false;
          var objectString = objectPrefix + '[' + objectId + ']';
          if (angular.isUndefined(error.attributes.objects)) {
            return false;
          }
          lsnNg.forEach(error.attributes.objects, function(objectDataString) { //"Persons[45680359].PersonData.Name"
            if (objectDataString.indexOf(objectString) !== -1) {
              hasObject = true;
              return false;
            }
            return true;
          });
          return hasObject;
        };

        this.getErrorFieldsForObject = function(objectType, objectId) {
          var fieldErrors = [];
          var errors = self.getErrorsForObject(objectType, objectId);
          var deduplicatedErrors = {};
          //deduplikacja
          lsnNg.forEach(errors, function(error) {
            lsnNg.forEach(error.attributes.objects, function(allObjectString) {
              var objectString = allObjectString.substr(allObjectString.indexOf(self.objectTypeToError[objectType]));
              if (_.startsWith(error.code, 'P') && _.endsWith(objectString, ']')) {
                // workaround for PP messages without attribute indication
                objectString = objectString + '.' + error.index;
              }
              if (angular.isDefined(deduplicatedErrors[objectString]) && deduplicatedErrors[objectString] === MessageModelConstants.MESSAGE_TYPE_ERROR) {
                return true;
              }
              deduplicatedErrors[objectString] = {
                message: error.message,
                type: error.type.toUpperCase()
              };
              return true;
            });
          });

          var object = dataContainerHelper.getObject(objectType, objectId);

          lsnNg.forEach(deduplicatedErrors, function(error, objectString) {
            var objectData = objectString.split('.');
            if (objectData.length === 1) {
              fieldErrors.push({
                object: objectType,
                fieldName: '',
                type: error.type,
                message: error.message
              });
            } else if (objectData[1].toLowerCase() === objectType + 'data' && angular.isString(objectData[2])) //błąd typu Persons[1].PersonData.FirstName
            {
              fieldErrors.push({
                object: objectType,
                fieldName: self.getFieldName(objectData[2]),
                type: error.type,
                message: error.message
              });
            } else if (objectData[1].indexOf('[') !== -1 && angular.isString(objectData[2])) //podelement osoby
            {
              var subObjectData = objectData[1].split('[');
              var subObjectIndex = subObjectData[1].replace(']', '');
              if (subObjectData[0] === 'Addresses' && angular.isDefined(object.addresses[subObjectIndex])) {
                fieldErrors.push({
                  object: 'address',
                  fieldName: self.getFieldName(objectData[2]),
                  type: error.type,
                  message: error.message,
                  objectType: object.addresses[subObjectIndex].get('code')
                });
              }
              if (subObjectData[0] === 'Contacts') {
                fieldErrors.push({
                  object: 'contact',
                  fieldName: self.getFieldName(objectData[2]),
                  type: error.type,
                  message: error.message,
                  objectType: subObjectIndex
                });

              }
            } else //błąd typu Vehicles[28749780].VIN
            {
              fieldErrors.push({
                object: objectType,
                fieldName: self.getFieldName(objectData[1]),
                type: error.type,
                message: error.message
              });
            }
          });

          return self.divideErrorsByFields(fieldErrors);
        };

        this.divideErrorsByFields = function(fieldErrors) {
          var errors = {};
          angular.forEach(fieldErrors, function(fieldError) {
            if (typeof errors[fieldError.object] === 'undefined') {
              errors[fieldError.object] = {};
            }

            if (fieldError.objectType) {
              if (typeof errors[fieldError.object][fieldError.objectType] === 'undefined') {
                errors[fieldError.object][fieldError.objectType] = {};
              }
              errors[fieldError.object][fieldError.objectType][fieldError.fieldName] = {
                type: fieldError.type,
                message: fieldError.message
              };
            } else {
              errors[fieldError.object][fieldError.fieldName] = {
                type: fieldError.type,
                message: fieldError.message
              };
            }
          });

          return errors;
        };

        this.getFieldName = function(fieldNameString) {
          var fieldName;
          if (fieldNameString.match(/^[A-Z]+$/) !== null) //wszystko w upperCase
          {
            fieldName = fieldNameString.toLowerCase();
          } else {
            fieldName = fieldNameString.substr(0, 1).toLowerCase() + fieldNameString.substr(1);
          }
          return fieldName;
        };

        /**
         * zwraca komunikaty dla polisy
         * @param  {String} policyNumber
         * @return {Array} komunikaty
         */
        this.getMessagesForPolicy = function(policyNumber) {
          var errors = [];
          angular.forEach(mainErrorCollection.all(), function(error) {
            if (self.errorHasObject(error, 'Policies', policyNumber)) {
              errors.push(error);
            }
          });
          return errors;
        };

        /**
         * [getMainErrorForPage pobiera liste glownych bledow dla strony]
         * @return {[type]} [description]
         */
        this.getMainErrorForPage = function(page) {
          var errors = [];
          if (angular.isArray(self.pageMessages[page])) {
            angular.forEach(mainErrorCollection.all(), function(error) {
              if (self.pageMessages[page].indexOf(error.code) !== -1) {
                errors.push(error);
              }
            });
          }

          return errors;
        };

      };

      return new ErrorHelper();
    }
  ]);