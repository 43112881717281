angular.module('menuLeadingColor')
  .factory('leadingColorModalHelper', ['LsnModalHelper', 'ihestiaOssSalesprofileSvc', 'salesProfileSettingsSvc', '$filter', 'ihestiaFirstInfoOneListUploaderHelper', '$rootScope',
    function(LsnModalHelper, ihestiaOssSalesprofileSvc, salesProfileSettingsSvc, $filter, ihestiaFirstInfoOneListUploaderHelper, $rootScope) {
      var UfgModelHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        /**
         * Rozszerzamy opcje modala
         * @type {String}
         */
        this.setOptions({
          size: 'md',
          title: ['appearanceManagementJupiter', {
            componentCode: 'Portal'
          }],
          templateUrl: 'ihestia-menu-template-app/leadingColor/leadingColor.tpl.html',
          okBtnName: ['confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['cancelUcFirst', {
            componentCode: 'Public'
          }]
        });

        this.tplData = {};
        this.defaultData = {
          profileNumberInput: '', //id brokera
          uniqeReqId: null,
          errors: {}, //{id osoby: kolekcja komunikatów}
          context: {
            logoPath: '',
            logoPrintPath: '',
            profileFound: false,
            leadingColor: '',
            logo: [],
            logoPrint: [],
            profileNumber: null,
            logoUploadInited: true,
            printLogoUploadInited: true
          } //leadingColor, logo (ikonka), logoOnPrint (boolean), logoPrint (ikonka na wydruku)
        };

        this.initData = function(){
          this.tplData = angular.copy(self.defaultData);
          self.loadProfileNumbers();
        };

        this.loadProfileNumbers = function() {
          //tutaj tworzenie promise
          ihestiaOssSalesprofileSvc.findByParams({
            id: 'users',
            skip: 0,
            top: 100000,
            count: 0,
            dynamicFilters: {icOrganizationsUnitsKeysStrings: []}
          }).then(function(resp){
            self.tplData.uniqeReqId = resp.data.id;
          }, angular.noop);
          //tutaj zwrocenie promis
        };

        /**
         * Wybór wyposażenia
         */
        var parentshowModalFunc = this.showModal;
        this.showModal = function() {
          self.initData();
          // pokazujemy modal
          return parentshowModalFunc.apply(this);
        };

        /**
         * obslug a "zatwierdz"
         * @return {LsnModalHelper} this
         */
        this.confirm = function() {
          if(self.validate()){
            self.sendFile('jupiterLogo');
          }
        };

        this.sendFile = function(uploaderSName){
            if (self.uploadInited(uploaderSName) && ihestiaFirstInfoOneListUploaderHelper.areFileToSend(uploaderSName)) {
              $rootScope.$broadcast('showBlockUi', {
                label: $filter('translate')('Public.sending', {
                  componentCode: 'Public'
                })
              });
              ihestiaFirstInfoOneListUploaderHelper.startSendingFiles(uploaderSName);
            } else {
              if(uploaderSName === 'jupiterLogo'){
                self.sendFile('jupiterLogoPrint');
              } else {
                if (!self.uploadInited('jupiterLogo') || ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors('jupiterLogo')) {
                  self.sendContext();
                }
              }
            }
        };

        this.afterAllFileSend = function(withoutError, uploaderSName) {
          if(uploaderSName === 'jupiterLogo'){
            self.sendFile('jupiterLogoPrint');
          } else {
            if (withoutError && (!self.uploadInited('jupiterLogo') || ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors('jupiterLogo')) && 
              (!self.uploadInited('jupiterLogoPrint') || ihestiaFirstInfoOneListUploaderHelper.checkAllFilesSendWithoutErrors('jupiterLogoPrint')) ) {
              self.sendContext();
            } else {
              $rootScope.$broadcast('hideBlockUi');
            }
          }
        };

        this.uploadInited = function(uploaderSName){
          return uploaderSName === 'jupiterLogo' ? self.tplData.context.logoUploadInited : self.tplData.context.printLogoUploadInited; 
        };

        this.sendContext = function(){
          $rootScope.$broadcast('showBlockUi', {
              label: $filter('translate')('Public.sending', {
                componentCode: 'Public'
              })
            });

          if(self.tplData.context.profileFound){
            salesProfileSettingsSvc.put(self.tplData.context.profileNumber.symbol, self.getReqBodyFromParameters()).then(function(){
              self.hideModal();
              $rootScope.$broadcast('hideBlockUi');
            }, function(){
              $rootScope.$broadcast('hideBlockUi');
            });
          } else {
            salesProfileSettingsSvc.post(self.tplData.context.profileNumber.symbol, self.getReqBodyFromParameters()).then(function(){
              self.hideModal();
              $rootScope.$broadcast('hideBlockUi');
            }, function(){
              $rootScope.$broadcast('hideBlockUi');
            });
          }

        };

        this.validate = function(){
          var valid = true;
          self.tplData.errors = {};
          if(!angular.isObject(self.tplData.context.profileNumber)){
            self.tplData.errors.profileNumber = {
              text: $filter('translate')('fillField', {componentCode: 'Public'})
            };

            valid = false;
          }

          if(self.tplData.context.leadingColor){
            var re = /^(#)[0-9a-fA-Z]{6}/;
            if(!re.test(self.tplData.context.leadingColor)){
              self.tplData.errors.leadingColor = {
                text: $filter('translate')('notValidLeadingColor', {componentCode: 'Menu'})
              };

              valid = false;
            }
          }
          return valid;
        };

        this.getReqBodyFromParameters = function(){
            var reqBody = {
              Parameters: []
            };

            reqBody.Parameters.push({
              Code: 'MainColor',
              Value: self.tplData.context.leadingColor ? self.tplData.context.leadingColor : null
            });

            if(self.tplData.context.logoUploadInited){
              if(self.tplData.context.logo[0]){
                reqBody.Parameters.push({
                  code: 'MainLogo',
                  value: self.tplData.context.logo[0].fileServerId
                });
              } else {
                reqBody.Parameters.push({
                  code: 'MainLogo',
                  value: null
                });
              }
            }

            if(self.tplData.context.printLogoUploadInited){
              if(self.tplData.context.logoPrint[0]){
                reqBody.Parameters.push({
                  code: 'PrintoutLogo',
                  value: self.tplData.context.logoPrint[0].fileServerId
                });
              } else {
                reqBody.Parameters.push({
                  code: 'PrintoutLogo',
                  value: null
                });
              }
            }

            return reqBody;
        };

        this.setParametersFromResponse = function(respData){
          self.clearContextForNoData();

          angular.forEach(respData.parameters, function(parameter){
            switch(parameter.code){
              case 'MainColor':
                self.tplData.context.leadingColor = parameter.value;
                break;
              case 'MainLogoPath':
                if(parameter.value){
                  self.tplData.context.logoUploadInited = false;
                  self.tplData.context.logoPath = parameter.value;
                }

                break;
              case 'PrintoutLogoPath':
                if(parameter.value){
                  self.tplData.context.printLogoUploadInited = false;
                  self.tplData.context.logoPrintPath = parameter.value;
                }
                break;
              case 'MainLogo':
                if(parameter.value){
                  self.tplData.context.logoUploadInited = false;
                  self.tplData.context.logo = [{
                    fileServerId: parameter.value
                  }];
                }
                break;
              case 'PrintoutLogo':
                if(parameter.value){
                  self.tplData.context.printLogoUploadInited = false;
                  self.tplData.context.logoPrint = [{
                    fileServerId: parameter.value
                  }];
                }
                break;
              default:
                break;
            }
          });
        };

        this.clearContextForNoData = function(){
          self.tplData.context.leadingColor = '';
          self.tplData.context.logo = [];
          self.tplData.context.logoPrint = [];
          self.tplData.context.logoUploadInited = true;
          self.tplData.context.printLogoUploadInited = true;
          self.tplData.context.logoPath = '';
          self.tplData.context.logoPrintPath = '';
        };
      };

      return new UfgModelHelper();
    }
  ]);