angular.module('ihestiaRestServicesBase')
  .factory('insuranceConditionsSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryEurotaxSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'insuranceConditions';

      };
      return new RestDictionaryEurotaxSvc();
    }
  ]);