angular.module('salesPath2')
  .controller('personAddModalCtrl', ['$scope', 'personHelper', '$timeout', 'personModalHelper', 'sp2CommonHelper', 'actionHelper', 'groupModalHelper',
    function($scope, personHelper, $timeout, personModalHelper, sp2CommonHelper, actionHelper, groupModalHelper) {

      $scope.init = function() {

        $scope.params = personModalHelper.addModalParams;

        $scope.setExcludePersons();
      };

      $scope.setExcludePersons = function() {
        $scope.persons = [];
        angular.forEach($scope.dataContainer.persons, function(person, k) {
          var exclude = false;
          angular.forEach($scope.params.excludeIds, function(personId) {
            if (parseInt(personId, 10) === parseInt(k, 10)) {
              exclude = true;
            }
          });
          if ($scope.params.mainInsuredOnly && person.metaData.clientId !== $scope.dataContainer.mainInsuredId) {
            exclude = true;
          }
          if (!exclude) {
            var personData = {
              id: k
            };
            personData.name = personHelper.getPersonInfo(person, {
              maxLength: 35
            });
            $scope.persons.push(personData);
          }
        });
      };

      $scope.onSelectPerson = function(personId) {
        sp2CommonHelper.hideModal('personAddModal');
        if (personModalHelper.addModalParams.personChange) {
          personHelper.changePerson($scope.params.sourcePersonId, personId, $scope.params.personChangeRole);
        }
        actionHelper.runAction('personAdded', {
          id: personId,
          context: personModalHelper.addModalParams.context
        });
        personModalHelper.clearAddModalData();
      };

      $scope.addNewPerson = function() {
        sp2CommonHelper.hideModal('personAddModal');
        personModalHelper.openPopupEditPerson({
          context: personModalHelper.addModalParams.context,
          editHeader: personModalHelper.addModalParams.title,
          personChange: personModalHelper.addModalParams.personChange,
          sourcePersonId: personModalHelper.addModalParams.sourcePersonId,
          personChangeRole: personModalHelper.addModalParams.personChangeRole
        });
        personModalHelper.clearAddModalData();
      };

      $scope.addGroup = function() {
        sp2CommonHelper.hideModal('personAddModal');
        groupModalHelper.openPopupEditGroup({
          context: personModalHelper.addModalParams.context
        });
        personModalHelper.clearAddModalData();
      };

      $scope.addIncognito = function() {
        sp2CommonHelper.hideModal('personAddModal');
        actionHelper.runAction('addIncognito', {context: personModalHelper.addModalParams.context});
        personModalHelper.clearAddModalData();
      };

      $scope.personSearch = function() {
        sp2CommonHelper.hideModal('personAddModal');
        personModalHelper.openPopupSearchPerson({
          context: personModalHelper.addModalParams.context,
          personChange: personModalHelper.addModalParams.personChange,
          sourcePersonId: personModalHelper.addModalParams.sourcePersonId,
          personChangeRole: personModalHelper.addModalParams.personChangeRole,
          title: $scope.params.title || undefined
        });
        personModalHelper.clearAddModalData();
      };

      $scope.$on('iHestiaCommonModalCancelBtnClicked.personAddModal', function() {
        $timeout(function() {
          actionHelper.runAction('cancelAddPerson', {
            context: $scope.params.context
          });
          personModalHelper.clearAddModalData();
        }, 0);
      });

      $scope.init();
    }
  ]);
