/**
 * Mapper wniosku do RESTow
 */
angular.module('salesPath2').service('toRestApplicationMapper', ['MapperUtils', 'toRestRiskMapper', 'toRestEstateMapper', 'toRestVehicleMapper', 'toRestSubjectMapper', 'toRestBonusMalusMapper', 'toRestPolicyMapper', 'CONSTANTS', 'CONFIG', 'ApplicationModelConstants', 'SPD', 'ApplicationModel', 'RESOURCES',
  function(MapperUtils, toRestRiskMapper, toRestEstateMapper, toRestVehicleMapper, toRestSubjectMapper, toRestBonusMalusMapper, toRestPolicyMapper, CONSTANTS, CONFIG, ApplicationModelConstants, SPD, ApplicationModel, RESOURCES) {
    var ToRestApplicationMapper = function() {
      var self = this;
      this.utils = MapperUtils;
      /**
       * dynVals do przepisania z istniejącego wniosku (dataContainer.application)
       * @type {Array}
       */
      this.dynamicValuesToRewrite = ['_agentDiscountId', '_operationId', '_mockRenewalOperation', '_mockPricePoint'];

      /**
       * mapuje dane z dataContainera do modelu wniosku
       * @param {XPegaz.Mapper.ToRest.dataContainer} dataContainer data manager
       * @return {Object} dane do Application
       */
      this._mapBaseApplicationData = function(dataContainer) {
        var data = {};
        data.estates = toRestEstateMapper.mapEstates(dataContainer.localizations);
        data.vehicles = toRestVehicleMapper.mapVehicles(dataContainer.vehicles);
        data.risks = toRestRiskMapper.mapRisks(dataContainer);
        data.persons = toRestSubjectMapper.mapPersons(dataContainer);
        data.groups = toRestSubjectMapper.mapGroups(dataContainer);
        data.organizations = toRestSubjectMapper.mapOrganizations(dataContainer);
        data.policies = toRestPolicyMapper.mapSimplePolicies(dataContainer.policies, dataContainer);
        data.operationData = dataContainer.application.get('operationData');
        //niepotrzebne usuwamy - readonly
        if (angular.isObject(data.operationData) && angular.isObject(data.operationData.allowedChanges)) {
          delete data.operationData.allowedChanges;
        }
        return data;
      };
      /**
       * mapuje obiekt wniosku ubezpieczeniowego
       * @param  {DataContainer} dataContainer              [description]
       * @return {[type]}                            [description]
       */
      this.mapApplication = function(dataContainer) {
        var attrs = {};
        self._mapApplicationDynamicValues(attrs, dataContainer);
        var data = {
          number: dataContainer.application.get('number'),
          signDate: dataContainer.application.get('signDate'),
          place: dataContainer.application.get('place'),
          pkd: null,
          insuredRef: self.utils.getSubjectMetadataId(dataContainer.mainInsuredId),
          holderRef: self.utils.getSubjectMetadataId(dataContainer.insurerId),
          status: ApplicationModelConstants.APPLICATION_STATUS_CALCULATION,
          symbol: SPD.Application.idpm,
          dynamicValues: attrs,
          metaData: dataContainer.application.get('metaData'),
          individualDiscount: dataContainer.application.get('individualDiscount'),
          operationType: dataContainer.application.get('operationType')
        };
        angular.extend(data, self._mapBaseApplicationData(dataContainer));
        var application = new ApplicationModel();
        application.setData(data);
        self.utils.removeTemporaryDynamicValuesFromObjects(application);
        return application;
      };

      /**
       * mapuje dynamiczne atrybuty dla wniosku
       * @param  {Object} attrs         [description]
       * @param  {Object} dataContainer [description]
       */
      this._mapApplicationDynamicValues = function(attrs, dataContainer) {
        var applicationDynamicValues = dataContainer.application.get('dynamicValues');
        angular.forEach(self.utils.applicationDynamicValuesMap, function(dynAttrName, dmProp) {
          attrs[dynAttrName] = self.utils.serializeValue(self.utils.convertFromdataContainerProperty(dmProp, dataContainer[dmProp]));
        });
        if (angular.isDefined(applicationDynamicValues._calculateEstatesSeparately)) {
          attrs._calculateEstatesSeparately = applicationDynamicValues._calculateEstatesSeparately;
        } else {
          attrs._calculateEstatesSeparately = CONFIG.BEHAVIOR.defaultCalculateEstatesSeparately;
        }
        if (CONFIG.BEHAVIOR.globalProtectionPeriod !== null && dataContainer.protectionPeriodCode !== null) {
          angular.forEach(RESOURCES.PROTECTION_PERIOD_OPTIONS, function(item) {
            if (item.CODE === dataContainer.protectionPeriodCode) {
              if (CONFIG.BEHAVIOR.globalProtectionPeriod === 'travel') {
                attrs.typeOfAgreement = item.BOS_VALUE; //rodzaj umowy/okres ubezpieczenia
                attrs.destinations = dataContainer.destinations;// destynacja tylko dla 
              } else if (CONFIG.BEHAVIOR.globalProtectionPeriod === 'days') {
                attrs.insurancePeriod = item.BOS_VALUE; //okres ubezpieczenia
              }
            }
          });
        }

        //atrybuty do przepisania
        if (angular.isObject(dataContainer.application.dynamicValues)) {
          angular.forEach(self.dynamicValuesToRewrite, function(attr) {
            if (angular.isDefined(dataContainer.application.dynamicValues[attr])) {
              attrs[attr] = dataContainer.application.dynamicValues[attr];
            }
          });
        }
      };

    };

    return new ToRestApplicationMapper();
  }
]);