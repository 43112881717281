angular.module('salesPath2')
  .directive('lsnDecisionMaker', ['personServiceHelper', 'lsnTryEscapeHtml',
    function(personServiceHelper, lsnTryEscapeHtml) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          ngModel: '=',
          getDomExecutor: '&'
        },
        templateUrl: function(elem, attrs) {
          if (attrs.drvTemplate) {
            return attrs.drvTemplate;
          } else {
            return 'salesPath2Template/autocompleters/decisionMaker/decisionMaker.tpl.html';
          }
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $scope.$watch('domExecutorApi', function(domExecutorApi) {
            if (domExecutorApi) {
              $scope.getDomExecutor({
                $domExecutorApi: domExecutorApi
              });
            }
          }, true);
          $scope.isDisabled = false;

          $attrs.$observe('ngDisabled', function(val) {
            $scope.isDisabled = $scope.$eval(val);
          });

          $scope.$on('clearDecisionMaker', function() {
            $scope.decisionMaker = null;
          });

          $scope.init = function() {
            $scope.decisionMaker = $scope.ngModel;
          };

          $scope.getDecisionMakers = function(query) {
            $scope.loadingElements = true;
            var persons = [];

            return personServiceHelper.findDecisionMaker(query, null, $attrs.consentType).then(function(res) {
              $scope.loadingElements = false;
              if (!res && angular.isUndefined(res.data) || angular.isUndefined(res.data.items)) {
                res.data = {
                  items: []
                };
              }

              angular.forEach(res.data.items, function(item) {
                item.formattedInfo = $scope.getFormattedInfoDecisionMakers(item);
                persons.push(item);
              });
              return persons;
            }, angular.noop);

          };

          $scope.getFormattedInfoDecisionMakers = function(person) {
            var info = 'NUMER: ' + lsnTryEscapeHtml(person.number);
            info += ', FUNKCJA: ' + lsnTryEscapeHtml(person.type);
            info += ', PD: ' + lsnTryEscapeHtml(person.pd);
            if (person.email !== '') {
              info += ', EMAIL: ' + lsnTryEscapeHtml(person.email);
            }
            return info;
          };

          $scope.onSelectItem = function(item) {
            $scope.decisionMaker = item.firstName + ' ' + item.name;
            $scope.$emit('decisionMakerChosen', item, $attrs.consentType, $attrs.decisionType);
          };

          $scope.init();
        }]
      };
    }
  ]);