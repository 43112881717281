/**
 * Mapper BOS messages
 */
angular.module('salesPath2').service('salesPath2ErrorMapper', [function() {
  var Mapper = function() {
    var self = this;

    this.getErrorParts = function(error) {
      var errorParts = [];
      for (var i = 0; i < error.attributes.objects.length; i += 1) {
        errorParts[i] = error.attributes.objects[i].split('.');
      }
      return errorParts;
    };

    /**
     * Zwraca id encji, ktorej dotyczy error
     *
     * @returns {Integer}
     */
    this.getEntityId = function(error) {
      var errorParts = self.getErrorParts(error);
      if (errorParts.length === 0) {
        return;
      }
      
      var regexp = new RegExp('\\[.+\\]'); //cokolwiek między nawiasami
      var numbers = null;
      lsnNg.forEach(errorParts[0], function(errorPart) {
        numbers = regexp.exec(errorPart);
        if (numbers !== null) {
          return false;
        }
        return true; //continue
      }); //nie zawsze id jest w pierwszym fragmencie
      if (numbers === null) {
        return null; //eslint-disable-line consistent-return
      }
      
      //wyszukujemy [23432] i wycinamy nawiasy 
      return numbers[0].replace('[', '').replace(']', ''); //eslint-disable-line consistent-return
    };

    this.getObjectType = function(error) {
      var errorParts = self.getErrorParts(error);
      if (errorParts.length === 0) {
        return null;
      }
      var regexp = new RegExp('^[a-zA-Z]+');
      var model = regexp.exec(errorParts[0][0]);

      return model[0];
    };
  };

  return new Mapper();
}]);