angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryNationalitiesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryNationalitiesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'nationalities';
        this.sortBy = 'description';
        this.pageSize = 1000;

      };
      return new RestDictionaryNationalitiesSvc();
    }
  ]);