angular.module('ihestiaWidgets.policyDetails')
  .factory('owuHelper', ['ihestiaDictionaryHelper', 'applicationsSvc', 'blockUiHelper', 'iHestiaCommonModalHelper', 'owuModalHelper',
    function(ihestiaDictionaryHelper, applicationsSvc, blockUiHelper, iHestiaCommonModalHelper, owuModalHelper) {

      var OwuHelper = function() {
        var self = this;

        /**
         * Zwraca (w callbacku) listę obiektów owu dla zadanych kodów
         * @param  {array}   codes    string[] - kody owu
         * @param  {Function} callback dostanie jako pierwszy parametr listę obiektów owu
         * @return {Promise}            promise pochodzący z ihestiaDictionaryHelper.getDict
         */
        this.getOwus = function(codes, callback) {
          callback = angular.isFunction(callback) ? callback : lsnNg.noop;
          return ihestiaDictionaryHelper.getDict('owu', {
            callback: function(res) {
              var owus = {},
                owuDictItems = res ? res : [];

              angular.forEach(codes, function(code) {
                angular.forEach(owuDictItems, function(owuDictItem) {
                  // jeśli owu występuje w słowniku, to wybieramy jego własności
                  if (owuDictItem.code === code) {
                    // grupowanie po kodzie owu
                    owus[code] = owuDictItem;
                  }
                });
              });

              // wykluczanie ewentualnych duplikatow z tą samą nazwą owu i nazwą pliku
              var uniqueOwus = {};
              var tmpOwus = [];
              angular.forEach(owus, function(item) {
                if (!item.attributes || !item.attributes.fileUrl) {
                  uniqueOwus[item.code] = item;
                } else if (tmpOwus[item.name] !== item.attributes.fileUrl) {
                  uniqueOwus[item.code] = item;
                  tmpOwus[item.name] = item.attributes.fileUrl;
                }
              });

              callback(uniqueOwus);
            }
          });
        };
        // $scope.offerHelper.model.metaData.id
        this.showOwuModal = function(offerId) {
          // $scope.$emit('showBlockUi');
          blockUiHelper.showBlockUi();
          // pobieramy kody owu dla danej oferty
          return applicationsSvc.get(offerId, null, 'insuranceConditions').then(
            function(res) {
              if (res && res.data && res.status === 200) {
                // i przeciągamy je przez usługę słownikową
                return self.getOwus(res.data, function(owus) {
                  // dzięki temu mamy ładne obiekty z nazwami
                  owuModalHelper.owus = owus;
                  // $scope.$emit('hideBlockUi');
                  blockUiHelper.hideBlockUi();
                  iHestiaCommonModalHelper.showModal('ihestiaOwuModal');
                });
              } else {
                blockUiHelper.hideBlockUi();
                return null;
              }
            }, function() {
              blockUiHelper.hideBlockUi();
              return null;
            }).then(lsnNg.noop, lsnNg.noop);
        };
      };

      return new OwuHelper();
    }]);
