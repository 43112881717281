angular.module('ihestiaCrossTab')
  /**
   * obsługa komunikacji między zakładkami przeglądarki
   * UWAGA!
   * Wymaga parametru DOMAIN w konfiguracji systemu
   * @return {Object} API
   */
  .service('ihestiaCrossTabSvc', ['ihestiaConfigHelper', 'lsnCookie', function (ihestiaConfigHelper, lsnCookie) {
      var ctConfig = ihestiaConfigHelper.get('crossTab'),
         cookieName = ihestiaConfigHelper.get('cookieNames', 'CROSS_TAB');


      cookieName = cookieName ? cookieName  : 'ih-crosstab-data';
      var CrossTabService = function () {
        var self = this;

        this.setCookie = function (data) {
          lsnCookie.set(cookieName, data, {'domain': ctConfig.ROOT_DOMAIN});
        };

        this.getCookie = function () {
          var cookie = lsnCookie.get(cookieName);
          if (typeof cookie === 'undefined') {
            return null;
          }
          return cookie;
        };

        /**
         * czysci ciastko z przestarzalych wiadomosci
         */
        this.cleanCookie = function () {
          var currCookie = self.getCookie();
          if (currCookie === null) {
            return;
          }
          var cleanedCookie = angular.extend([], currCookie),
            i = cleanedCookie.length,
            timestamp = (new Date().getTime());
          while (i--) {
            if ((timestamp - cleanedCookie[i].created) > ctConfig.MSG_TTL) {
              cleanedCookie.splice(i, 1);
            }
          }
          if (!angular.equals(currCookie, self.getCookie())) {
            return; //w miedzyczasie ktos zmodyfikowal ciasteczko
          }
          self.setCookie(cleanedCookie);
        };

      };

      var crossTabServiceInstance = new CrossTabService();
      return crossTabServiceInstance;
    }]);