angular.module('ihestiaWidgets.policyOperationsPublic')
  .factory('vehiclePurchasePublicSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var VehiclePurchasePublicSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'vehicleOwnerChange';
        this.businessArea = 'insurance';
      };

      return new VehiclePurchasePublicSvc();
    }]);