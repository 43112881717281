angular.module('salesPath2')
  /**
   * filtruje obiekt osoby - wycina nieużywane na GUI pola
   * UWAGA - filtr operuje na przekazanym obiekcie (nie tworzy nowej instancji)
   * @param {Person} person
   * @param {string} role rola, dla której filtrujemy osobę
   * @returns {Person} referencja do obiektu przekazanego w argumencie filtru
   */
  .service('personDataFilter', ['CONSTANTS', 'CONFIG', 'documentsFilter',
    function(CONSTANTS, CONFIG, documentsFilter) {
      return function(person, role) {
        /**
         * obsługiwane role
         * SUBJECT_ANY - dodwolna osoba
         * SUBJECT_OWNER - traktowany jest jako ubezpieczający oraz jako właściciel pojazdu
         * SUBJECT_INSURED - ubezpieczony i współubezpieczony np. w NNW/OCZP
         * SUBJECT_COOWNER - współw7aściciel np. mieszkania/pojazdu
         * @type string[]
         */
        var supportedRoles = [CONSTANTS.SUBJECT_ANY, CONSTANTS.SUBJECT_INSURED, CONSTANTS.SUBJECT_OWNER, CONSTANTS.SUBJECT_COOWNER];

        if (!angular.isObject(person) || person.objectName !== 'Person' || !angular.isString(role) || supportedRoles.indexOf(role) === -1) {
          return person;
        }

        var configuration = CONFIG.FILTERS.personExtraFieldsToDelete;

        /**
         * konfiguracja filtraci dla poszczególnych ról (może być nadpisywana w CONFIGu konkretnego projektu w CONFIG.personFieldsToFilter w konwencji jak poniżej opisana)
         * rola => pola, gdzie pola to tablica z nazwamu pól osoby lub tablicą [nazwa_pola, callback], gdzie callback, to specyficzna funkcja filtrująca przyjmująca w argumencie osobę - Person (jej zwrócona wartość zostanie ustawiona dla danego pola osoby)
         * @type Object
         */
        var rolesToUnusedFields = {};

        var baseFields = ['motherName', 'fatherName', 'secondName', 'accounts', ['documents', function(personObject) {
          if (personObject.get('citizenshipCode') !== 'PL') {
            return documentsFilter(personObject.documents, {
              'leaveOne': true
            });
          } else {
            return documentsFilter(personObject.documents, {
              'removeAll': true
            });
          }
        }]];

        rolesToUnusedFields[CONSTANTS.SUBJECT_ANY] = configuration[CONSTANTS.SUBJECT_ANY].concat(baseFields);
        rolesToUnusedFields[CONSTANTS.SUBJECT_INSURED] = configuration[CONSTANTS.SUBJECT_INSURED].concat(baseFields).concat(['nip', 'clauses']);
        rolesToUnusedFields[CONSTANTS.SUBJECT_COOWNER] = configuration[CONSTANTS.SUBJECT_COOWNER].concat(baseFields).concat(['nip', 'clauses']);
        rolesToUnusedFields[CONSTANTS.SUBJECT_OWNER] = configuration[CONSTANTS.SUBJECT_OWNER].concat(baseFields);

        //filtrujemy
        angular.forEach(rolesToUnusedFields[role], function(field) {
          if (angular.isArray(field)) { //specyficzne filtrowanie
            person.set(field[0], field[1](person));
          } else {
            person.set(field, null);
          }
        });

        return person;
      };
    }
  ]);