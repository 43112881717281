angular.module('salesPath2')
  .service('matrixPersonBoxHelper', ['personGroupHelper',
    function(personGroupHelper) {
      var MatrixPersonBoxHelper = function() {
        var self = this;
        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          subjects: [] //lista podmiotów (osoby/grupy)
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personGroupDeleted':
              self.refreshSubjects();
              break;
            case 'personEdited':
            case 'groupEdited':
              self.refreshSubject(arguments[1].id);
              break;
            case 'personGroupSelected':
              if (angular.isObject(arguments[1]) && arguments[1].context === 'new') {
                self.refreshSubjects();
              }
              break;            
            default:
              break;
          }
        };

        /**
         * aktualizuje listę podmiotów
         */
        this.refreshSubjects = function() {
          self.tplData.subjects = personGroupHelper.getSubjects();
        };

        /**
         * odświeża dane pojedynczego podmiotu
         * @param  {String} clientId clientId podmiotu
         */
        this.refreshSubject = function(clientId) {
          lsnNg.forEach(self.tplData.subjects, function(subject, idx) {
            if (subject.clientId === clientId) {
              self.tplData.subjects[idx] = personGroupHelper.getSubjectData(clientId);
              return false;
            }
            return true;
          });
        };
      };

      return new MatrixPersonBoxHelper();
    }
  ])
  .run(['matrixPersonBoxHelper', 'actionHelper',
    function(matrixPersonBoxHelper, actionHelper) {
      actionHelper.registerHelper('matrixPersonBoxHelper', matrixPersonBoxHelper);
    }
  ]);