angular.module('ihestiaWidgets.life')
  .directive('ihestiaClauseList', [function() {
    return {
      restrict: 'E',
      scope: {
        helper: '=?', /** instance of with passed settings IhestiaClauseListHelper, allows control over directive.
         If not supplied, new instance is create with default options or options passed to 'settings' binding  */
        settings: '=?'
      },
      templateUrl: 'ihestia-widgets-templates-app/life/clauseList/clauseList.tpl.html',
      controller: 'ihestiaClauseListCtrl'
    };
  }]);