angular.module('ihestiaIkomunikator.fullListPanel')
  .controller('ihestiaIkomunikatorFullListPanelCtrl', ['$scope', 'ihestiaIkomunikatorActionHelper', 'ihestiaIkomunikatorNavigationHelper', 'ihestiaIkomunikatorFullListPanelHelper', 'ihestiaIkomunikatorPermissionHelper', '$rootScope', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function($scope, ihestiaIkomunikatorActionHelper, ihestiaIkomunikatorNavigationHelper, ihestiaIkomunikatorFullListPanelHelper, ihestiaIkomunikatorPermissionHelper, $rootScope, ihestiaIkomunikatorLoaderMessagesHelper) {
      $scope.spinnerOptions = {
        size: 'sm',
        label: ''
      };

      /**
       * [init description]
       * @return {[type]} [description]
       */
      $scope.init = function() {
        if (!ihestiaIkomunikatorPermissionHelper.shouldShowApp()) {
          $rootScope.canRender = false;
          return;
        }
        $scope.tplData = ihestiaIkomunikatorFullListPanelHelper.tplData;
        ihestiaIkomunikatorFullListPanelHelper.init();

        $scope.$on('messageSended', function(){
          ihestiaIkomunikatorFullListPanelHelper.setClearData();
          ihestiaIkomunikatorFullListPanelHelper.init();
        });
      };

      $scope.showDetailsMessageThread = function(threadId) {
        ihestiaIkomunikatorNavigationHelper.goTo('details', threadId);
      };

      $scope.showNewMessageModal = function() {
        ihestiaIkomunikatorActionHelper.startNewMessage();
      };

      /**
       * [filterSearch wywołane tylko na zmiane tekstu wyszukiwania]
       * @return {[type]} [description]
       */
      $scope.filterSearch = function(){
        if($scope.tplData.loading){
          $scope.tplData.reloadSearchAgain = true;
        }else{
          ihestiaIkomunikatorFullListPanelHelper.filterSearch();
        }
      };

      /**
       * Wyszukiwanie kolejnzch elementow
       * @param  {string} phrase [description]
       */
      $scope.searchNext = function() {
        if (!$scope.tplData.loading) {
          ihestiaIkomunikatorLoaderMessagesHelper.loadNextResult($scope.tplData);
        }
      };

      $scope.$on('$destroy', function() {
        ihestiaIkomunikatorFullListPanelHelper.setClearData();
      });

      $scope.init();
    }
  ]);