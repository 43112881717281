angular.module('salesPath2')
  .service('leasingHelper', ['mainDataContainer', 'actionHelper', 'organizationHelper', 'dataContainerHelper',
    function(mainDataContainer, actionHelper, organizationHelper, dataContainerHelper) {
      var LeasingHelper = function() {
        var self = this;

        /**
         * zapisuje powiązanie do leasera na obiekcie
         * @param  {int|string} leaserId
         */
        this.selectLeaser = function(leaserId, objectParams, saveApplication) {
          var updatedObject = dataContainerHelper.getObject(objectParams.objectType, objectParams.objectId);
          updatedObject.setAdditionalData(leaserId, 'leaserId');
          actionHelper.runAction('leaserSaved');

          if (saveApplication) {
            actionHelper.runAction('saveApplication');
          }

          return self;
        };

        this.getLeaserInfo = function(object) {
          var leaserId = object.getAdditionalData('leaserId');
          var leaserInfo = null;
          if (leaserId !== null && mainDataContainer.organizations[leaserId]) {
            leaserInfo = organizationHelper.getOrganizationInfo(mainDataContainer.organizations[leaserId]);
          }
          return leaserInfo;
        };

      };

      return new LeasingHelper();
    }
  ]);