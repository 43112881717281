angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorSearchPersonCentralHelper', ['$filter', 'iHestiaCommonModalHelper', '$rootScope', 'iKomunikatorFtsSearchSvc', 'ihestiaIkomunikatorCommonHelper', '$timeout', 'iKomunikatorConstants', 'clientPersonsSvc', 'PersonModel', 'IKomunikatorFtsSearchModel', 'ihestiaIkomunikatorLoaderMessagesHelper', 'ihestiaDictionaryHelper', 'clientSearchSvc', '$q', 'ihestiaIkomunikatorUserTypeHelper', 'LsnOrganizationModelV1',
    function($filter, iHestiaCommonModalHelper, $rootScope, iKomunikatorFtsSearchSvc, ihestiaIkomunikatorCommonHelper, $timeout, iKomunikatorConstants, clientPersonsSvc, PersonModel, IKomunikatorFtsSearchModel, ihestiaIkomunikatorLoaderMessagesHelper, ihestiaDictionaryHelper, clientSearchSvc, $q, ihestiaIkomunikatorUserTypeHelper, LsnOrganizationModelV1) {

      var SearchClientCentralHelper = function() {
        var self = this;

        /*eslint-disable camelcase */
        this.defaultData = {
          searchFilters: {
            size: 10,
            from: 0,
            phrase: null,
            context: 'oss-element',
            sort_by: '',
            sort_order: 'NONE',
            filters: {
              subject_class: [iKomunikatorConstants.CONTACT_ROLE_CLIENT]
            }
          },
          //do fts po zmianie styli wogole nie sortujemy
          sortOrderNone: true,
          total: 0,
          loading: false,
          recipients: [],
          searchResultEmpty: false,
          //ustawiana na zmiane atrybutów w momencie wywoływania usługi
          //na calback usługa zostanie zawołana jeszcze jeden raz
          reloadSearchAgain: false,
          scrollVisible: false,
          activeSorter: false,
          reverseSort: false,
          personTypes: [{
            code: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
            label: ihestiaIkomunikatorUserTypeHelper.isCompanySearchAvailable()
              ? $filter('translate')('contactRolePersonLong', {componentCode: 'iKomunikator'})
              : 'Klient',
            placeholder: 'IMIĘ, NAZWISKO, NUMER PESEL, NUMER DOKUMENTU'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY,
            label: $filter('translate')('contactRoleCompany', {componentCode: 'iKomunikator'})
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE,
            label: 'Profil sprzedażowy',
            placeholder: 'NAZWA, NUMER PROFILU'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_WORKER,
            label: 'Pracownik',
            placeholder: 'IMIĘ, NAZWISKO, NUMER RA, NUMER PROFILU'
          }],
          countries: null,
          isCompanySearchAvailable: ihestiaIkomunikatorUserTypeHelper.isCompanySearchAvailable(),
          organizationData: {
            countryCode: 'PL'
          }
        };


        this.lastFilterText = '';
        this.previousFilterText = '';
        this.lastSubjectClass = iKomunikatorConstants.CONTACT_ROLE_CLIENT;
        this.lastOrganizationData = null;

        this.tplData = angular.copy(self.defaultData);

        $rootScope.ikomunikatorSearchPersonCentral = {
          settings: {
            title: $filter('translate')('iKomunikator.newMessage'),
            okBtnName: 'Nowy odbiorca',
            okBtnDisabled: true,
            cancelBtnName: $filter('translate')('Public.cancel'),
            keyboard: false,
            headerList: [], // zasilony poniżej na podstawie self.tplData.personTypes
            headerListModel: 'modalData.subjectType'
          }
        };

        angular.forEach(self.tplData.personTypes, function(personType) {
          $rootScope.ikomunikatorSearchPersonCentral.settings.headerList.push({
            value: personType.code,
            name: personType.label
          });

          if (personType.code === self.tplData.searchFilters.filters.subject_class[0]) {
            self.tplData.selectedPlaceholer = personType.placeholder;
          }
        });


        this.init = function() {
          self.setPlaceolder();
        };

        this.setPlaceolder = function() {
          angular.forEach(self.tplData.personTypes, function(personType) {
            if (personType.code === self.tplData.searchFilters.filters.subject_class[0]) {
              self.tplData.selectedPlaceholer = personType.placeholder;
            }
          });
        };

        this.showModal = function(data) {
          if (data && data.research) {
            self.tplData.searchFilters.phrase = self.lastFilterText;
            self.tplData.searchFilters.filters.subject_class[0] = self.lastSubjectClass;
            self.tplData.organizationData = self.lastOrganizationData;
            self.reloadRecipients();
          } else {
            self.clearLastSearch();
          }

          $rootScope.ikomunikatorSearchPersonCentral.settings.okBtnDisabled = true;
          iHestiaCommonModalHelper.showModal('ikomunikatorSearchPersonCentral');
          return self;
        };

        this.hideModal = function() {
          iHestiaCommonModalHelper.hideModal('ikomunikatorSearchPersonCentral');
        };

        this.search = function() {
          if (!self.canSearch()) {
            self.tplData.loading = false;
            return;
          }

          //check if searched phrase changed since last search
          if (self.tplData.searchFilters.phrase !== self.previousFilterText) {
            self.tplData.searchFilters.from = 0;
          }
          self.previousFilterText = self.tplData.searchFilters.phrase;

          if (_.includes([iKomunikatorConstants.CONTACT_ROLE_CLIENT, iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY],
            self.tplData.searchFilters.filters.subject_class[0])) {
            self.searchClients();
          } else {
            self.searchOtherPerson();
          }
        };

        this.searchClients = function() {
          self.getClientSearchRequest()
            .then(function(res) {
              self.setFoundedPerson(res, false);
            }, function() {
              self.afterSearch();
              ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania klientów');
            });
        };

        this.searchOtherPerson = function() {
          if (!self.tplData.sortOrderNone) {
            self.tplData.searchFilters.sort_order = self.tplData.reverseSort ? 'DESC' : 'ASC';
          } else {
            self.tplData.searchFilters.sort_by = '';
            self.tplData.searchFilters.sort_order = 'NONE';
          }

          $rootScope.ikomunikatorSearchPersonCentral.settings.okBtnDisabled = true;
          iKomunikatorFtsSearchSvc.postSearch(self.tplData.searchFilters, function(res) {
            self.setFoundedPerson(res, true);
          }, function() {
            self.afterSearch();
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania klientów');
          });
        };

        this.setFoundedPerson = function(res, otherClient) {
          if (res && res.data) {
            if (otherClient) {
              var data = res.data.oss_element.hits;
              angular.forEach(data.hits, function(recData) {
                var recipient = new IKomunikatorFtsSearchModel();
                recipient.set(recData);
                self.tplData.recipients.push(recipient);
              });
            } else {
              var list = res.data.items || res.data;
              var isOrganization = self.tplData.searchFilters.filters.subject_class[0] === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY;
              angular.forEach(list, function(subjectData) {
                var subject = isOrganization ? new LsnOrganizationModelV1() : new PersonModel();
                subject.setData(subjectData);
                self.tplData.recipients.push(subject);
              });
            }

            self.tplData.searchResultEmpty = false;
          }

          self.tplData.total = res.data.total;

          if (self.tplData.total === 0 || self.tplData.recipients.length === 0) {
            self.tplData.searchResultEmpty = true;
            $rootScope.ikomunikatorSearchPersonCentral.settings.okBtnDisabled = false;
          }

          $timeout(function() {
            if (!self.tplData.scrollVisible) {
              $rootScope.ikomunikatorSearchPersonCentral.settings.okBtnDisabled = false;
            }
          }, 0);

          self.afterSearch();
        };

        this.afterSearch = function() {
          if (self.tplData.reloadSearchAgain) {
            self.tplData.reloadSearchAgain = false;
            self.tplData.recipients = [];
            self.search();
            return;
          }

          self.tplData.loading = false;
        };


        this.reloadRecipients = function() {
          if (!self.tplData.loading) {
            self.tplData.loading = true;
            self.tplData.recipients = [];
            self.search();
          }
        };

        this.setDisableOnOkButton = function(value) {
          $rootScope.ihestiaIkomunikatorSearchPersonModalSettings.okBtnDisabled = value;
        };

        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };

        this.sortBy = function(columnName) {
          self.tplData.loading = true;
          self.tplData.recipients = [];

          if (self.tplData.searchFilters.sort_by === columnName) {
            self.tplData.reverseSort = !self.tplData.reverseSort;
          } else {
            self.tplData.searchFilters.sort_by = columnName;
          }

          self.search();
        };
        /*eslint-enable camelcase */

        this.loadedAllMessages = function() {
          return self.tplData.searchFilters.from + self.tplData.searchFilters.size >= self.tplData.total;
        };

        this.searchNext = function() {
          if (!self.tplData.loading && !self.loadedAllMessages()) {
            self.tplData.loading = true;
            self.tplData.searchFilters.from += self.tplData.searchFilters.size;
            self.search();
          }
        };

        this.clearLastSearch = function() {
          this.lastFilterText = '';
          this.lastSubjectClass = iKomunikatorConstants.CONTACT_ROLE_CLIENT;
        };

        this.loadDicts = function() {
          ihestiaDictionaryHelper.getDictWithPromise('countries', {
            requestType: 'searches'
          }).then(function(countries) {
            self.tplData.countries = countries;
          });
        };

        /**
         *
         * @return {Promise}
         */
        this.getClientSearchRequest = function() {
          var sortBy = self.tplData.searchFilters.sort_by ? self.tplData.searchFilters.sort_by : 'rank';
          var searchFilters = {
            value: self.tplData.searchFilters.phrase
          };
          var sortDirection = self.tplData.reverseSort ? 'DESC' : 'ASC';

          $rootScope.ikomunikatorSearchPersonCentral.settings.okBtnDisabled = true;

          self.promiseToCancel = $q.defer();
          var httpParams = {
            timeout: self.promiseToCancel.promise.then(lsnNg.noop, lsnNg.noop) //abort na promise
          };
          if (self.tplData.isCompanySearchAvailable
            && self.tplData.searchFilters.filters.subject_class[0] === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            httpParams.headers = {
              'AP-Request-Behaviour': 'touchpoint=I-KOMUNIKATOR-READONLY;'
            };
            var body = _.omitBy(self.tplData.organizationData, _.isNil);
            return clientSearchSvc.searchOrganization(body, httpParams);
          } else {
            return clientPersonsSvc.find(searchFilters, null, self.tplData.size, self.tplData.from, sortBy, sortDirection);
          }
        };

        this.canSearch = function() {
          if (self.tplData.isCompanySearchAvailable
            && self.tplData.searchFilters.filters.subject_class[0] === iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            return true;
          } else {
            return self.tplData.searchFilters.phrase.length >= 3;
          }
        };

        this.onCountryCodeChange = function() {
          self.tplData.organizationData.nip = null;
          self.tplData.organizationData.regon = null;
          self.tplData.organizationData.name = null;
          self.tplData.organizationData.companyId = null;
        };

        this.onSubjectTypeChange = function() {
          if (self.tplData.subjectType !== iKomunikatorConstants.CONTACT_ROLE_CLIENT_COMPANY) {
            self.tplData.organizationData = _.cloneDeep(self.defaultData.organizationData);
          }
          self.tplData.searchFilters.phrase = '';
          self.tplData.recipients = [];
          self.tplData.sortOrderNone = true;
          self.previousFilterText = '';
          self.setPlaceolder();
        };

        self.init();
      };

      return new SearchClientCentralHelper();
    }
  ]);
