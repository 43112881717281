angular.module('ihestiaCommonDirectives')
  .directive('commonRadio', ['$compile', '$parse',
    function($compile, $parse) {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'ihestia-commondirectives-template-app/directives/inputs/radioWrap.tpl.html',
        require: ['^ngModel'],
        scope: true,
        compile: function(tElement, tAttrs) {
          var radio = $(tElement).find('input');
          radio.attr('ng-model', tAttrs.ngModel);
          radio.attr('name', tAttrs.name);
          radio.attr('id', tAttrs.inputId);
          if(tAttrs.svcValidatorName){
            radio.attr('svc-validator-name', tAttrs.svcValidatorName);
          }
          if (tAttrs.ngValue) {
            radio.attr('ng-value', tAttrs.ngValue);
          } else {
            radio.attr('value', tAttrs.value);
          }
          if (tAttrs.ngRequired) {
            radio.attr('ng-required', tAttrs.ngValue);
          } else if (tAttrs.required) {
            radio.attr('required', tAttrs.required);
          }
          if (tAttrs.ngChange) {
            radio.attr('ng-change', tAttrs.ngChange);
          }

          if (tAttrs.ngDisabled) {
            radio.attr('ng-disabled', tAttrs.ngDisabled);
            $(tElement).find('[common-radio-wrap]').attr('ng-class', '{\'disabled\': ' + tAttrs.ngDisabled + '}');
          }

          angular.forEach(tAttrs.$attr, function(v) {
            tElement.removeAttr(v);
          });

          return {
            pre: angular.noop,
            post: function($scope, iElement, iAttrs) {
              $scope.text = iAttrs.commonRadioText;
              if(iAttrs.commonRadioTextLabel){
                $scope.textLabel = $parse(iAttrs.commonRadioTextLabel)($scope);
              }
              
              $compile(radio)($scope);

              // jeśli w wyniku ng-if'a input zniknie, to wstawiamy null do modelu
              if (angular.isDefined(iAttrs.clearModel)) {
                $scope.$on('$destroy', function() {
                  $parse(iAttrs.ngModel).assign($scope, null);
                });
              }
            }
          };
        },
        controller: ['$scope', '$element', '$attrs', function($scope, $element, $attrs) {
          $attrs.$observe('commonRadioText', function(newVal, oldVal) {
            if (newVal !== oldVal) {
              $scope.text = newVal;
            }
          });
        }]
      };
    }
  ]);