angular.module('ihestiaWidgets.policyDetails')
  .directive('ihestiaLifeProductHeader', ['$filter', 'policyDetailsContextHelper',
    function($filter, policyDetailsContextHelper) {
    return {
      templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/productHeader/lifeProductHeader.tpl.html',
      restrict: 'E',
      scope: {
        productData: '=',
        number: '=?',
        premium: '=?',
        link: '&?',
        numberLabel: '=?',
        isAnniversary: '=?',
        /**
         * {{mainInsuredName: string, isAnniversaryUpdated}}
         */
        anniversaryData: '=?'
      },
      link: function(scope) {
        scope.isClient = policyDetailsContextHelper.isClient();
        scope.policySymbolDict = {
          shortTerm: {
            symbol: 'Life5A',
            label: $filter('translate')('lifeShortTermPolicy', {componentCode: 'iKonto'})
          },
          longTerm: {
            symbol: 'Life5BC',
            label: $filter('translate')('lifeLongTermPolicy', {componentCode: 'iKonto'})
          }
        };
      }
    };
  }]);
