angular.module('lsnBase.models')
  .factory('LsnMetaDataModelV1', ['LsnAbstractModel', 'LsnMetaDataModelConstants', function(LsnAbstractModel, LsnMetaDataModelConstants) {

    var MetaDataModel = function(className) {
      this.objectName = 'MetaData';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        id: {
          type: 'string'
        },
        clientId: {
          type: 'string'
        },
        created: {
          type: 'dateTime'
        },
        updated: {
          type: 'dateTime'
        },
        className: {
          type: 'string'
        },
        uri: {
          type: 'string'
        },
        version: {
          type: 'string'
        },
        masterId: {
          type: 'string'
        },
        masterVersion: {
          type: 'string'
        },
        objectVersion: {
          type: 'string'
        },
        dataSource: {
          type: 'string',
          dictionary: LsnMetaDataModelConstants.DICTIONARY_DATA_SOURCE
        }
      };

      this.id = null; // string, Pole zawiera identyfikator danego obiektu w bazie. Identyfikator serwerowy.
      this.clientId = null; // string,  Identyfikator danego obiektu nadany po stronie klienta. Własność będzie służyła do prawidłowych przemapowań obiektów po stronie klienta. Po stronie serwera wartość ta nie będzie zmieniana. Zostanie odesłana do klienta.
      this.created = null; // dataTime, Data utworzenia
      this.updated = null; // dataTime, Moment ostatniej zmiany obiektu
      this.className = className || null; // string, Unikalna nazwa typu obiektu
      this.uri = null; // string, Adres URI do zasobu reprezen-tującego obiekt
      this.version = null; // wersja obiektu
      this.modelVersion = null; // string, Numer wersji modelu obiektu zapisany w formacie zgodnym z zasadami wersjonowania usług REST (v1, v2 …)
      // na potrzeby NBK
      this.masterId = null; // string, pole zawiera identyfikator masterId osoby
      this.masterVersion = null; // string, pole zawiera numer wersji osoby wiodącej (master)
      this.dataSource = null; // string. Przyjmuje wartość LOCAL lub MASTER.
      this.objectVersion = null;
    };

    MetaDataModel.prototype = LsnAbstractModel.prototype;

    return MetaDataModel;
  }]);