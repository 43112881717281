angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardNoneObjectConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardNoneObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {

      var NoneObjectModel = function() {
        this.objectName = 'IhestiaDashboardNoneObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_NONE;

      };


      NoneObjectModel.prototype = IHestiaAbstractModel.prototype;

      return NoneObjectModel;
    }]);