angular.module('salesPath2')
  .service('SummaryPaymentHelperClass', ['$rootScope', 'summaryHelper', 'CONSTANTS', 'mainDataContainer', 'RESOURCES', 'sp2CommonHelper', 'actionHelper', 'CONFIG', 'appVariables', 'applicationHelper', 'supplementHelper', 'renewalHelper', 'lsnUtils',
    function($rootScope, summaryHelper, CONSTANTS, mainDataContainer, RESOURCES, sp2CommonHelper, actionHelper, CONFIG, appVariables, applicationHelper, supplementHelper, renewalHelper, lsnUtils) {
      var SummaryPaymentHelperClass = function() {
        var self = this;
        /**
         * podsumowanie płatności
         * @type {Object}
         */

        this.policyId = '';

        this.isFraudulentClient = false;

        //ustawienia modali
        this.modals = {};

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.summaryPaymentModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: 'sp2.modal.summaryPaymentModal.title',
            size: 'sm',
            okBtnName: 'sp2.modal.okBtnName'
          }
        };

        this.policyId = '';
        this.paymentDetails = {
          frequency: 0,
          method: '',
          delay: '',
          delayOptions: [],
          noProrogation: false,
          commonPaymentData: false // used for fraudulent client
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          if (actionName === 'readOnlyChanged') {
            // kiedyś były tu jakieś akcje (gdy ten helper był singletonem). W razie potrzeby, dodać...
            lsnNg.noop();
          }
        };

        /**
         * Pobranie danych płatności i wyświetlenie modala
         */
        this.showPaymentModal = function(policy) {
          // setting isFraudulentClient automatically before opening summary payment modal
          self.isFraudulentClient = summaryHelper.isFraudulentClient(false);
          self.setPaymentModalData(policy);

          if (appVariables.isSupplement) {
            self.modals.summaryPaymentModal.settings.title = 'sp2.modal.summaryPaymentModal.supplementTitle';
          }

          if (appVariables.readOnly && !appVariables.isOcBuyer) {
            self.modals.summaryPaymentModal.settings.okBtnName = 'sp2.modal.close';
            self.modals.summaryPaymentModal.settings.cancelBtnName = '';
          } else {
            self.modals.summaryPaymentModal.settings.okBtnName = 'sp2.modal.okBtnName';
            self.modals.summaryPaymentModal.settings.cancelBtnName = 'sp2.modal.cancel';
          }

          sp2CommonHelper.showModal('summaryPaymentModal');
        };

        /**
         * Ustawia odpowiednie inicjalne dane
         * (wycięte z showPaymentModal na potrzeby ihestii lite)
         * @param {Object} policy dane polisy
         */
        this.setPaymentModalData = function(policy) {
          policy = policy.getData();
          self.policyId = policy.number;
          angular.extend(self.paymentDetails, self._getBasicPaymentDetails(self.policyId));
          var paymentDetails = self.getPaymentDetails(self.policyId);
          angular.extend(self.paymentDetails, paymentDetails);
        };

        /**
         * set self.isFraudulentClient
         * @param {boolean} isFraudulent
         */
        this.setFraudulentClient = function(isFraudulent) {
          self.isFraudulentClient = !!isFraudulent;
        };

        /**
         * zwraca listę opcji dla odroczenia płatności
         * @param  {Object} policy policy.getData() + dodatki
         * @return {Array}
         */
        this.getPaymentDelayOptions = function(policy) {
          var paymentDelayOptions = angular.copy(RESOURCES.PAYMENT_DELAY);
          if (CONFIG.BEHAVIOR.dayDependedProrogation) {
            var signDate = new XDate(policy.signDate),
              startDate = new XDate(policy.start),
              diffDays = signDate.diffDays(startDate);

            paymentDelayOptions = [];

            self.paymentDetails.noProrogation = false;
            if (diffDays === 1 || diffDays > 90) {
              self.paymentDetails.noProrogation = true;
              self.paymentDetails.delay = 0;
              return [];
            }
            angular.forEach(RESOURCES.PAYMENT_DELAY, function(item) {
              if (item.value < diffDays) {
                paymentDelayOptions.push(item);
              }
            });
          }

          //DiD
          if (appVariables.isSupplement) {
            var allowedValues = supplementHelper.getAllowedValuesForPolicyDynamicValues('paymentProrogation');
            if (allowedValues === null) {
              allowedValues = [self.paymentDetails.delay];
            }
            var newPaymentDelayOptions = [];

            angular.forEach(paymentDelayOptions, function(item) {
              if (allowedValues.indexOf(item.value) !== -1) {
                newPaymentDelayOptions.push(item);
              }
            });

            paymentDelayOptions = angular.copy(newPaymentDelayOptions);
          }

          //dla wznowień
          if (policy.product.dynamicValues.renewal && angular.isString(policy.product.dynamicValues._renewalCalendarDate)) {
            paymentDelayOptions.push({
              'value': policy.product.dynamicValues._renewalCalendarDate,
              'name': policy.product.dynamicValues._renewalCalendarDate,
              'attr': CONSTANTS.PROROGATION_ATTR_DATE
            });
          }
          return paymentDelayOptions;
        };

        /**
         * zwraca listę opcji dla metod płatności
         * UWAGA zakładamy że uprzednio uruchomiono metodę setPaymentModalData() w celu inicjalizacji danych o polisie
         * @return {Array}
         */
        this.getPaymentMethodOptions = function() {
          var paymentMethodOptions = RESOURCES.PAYMENT_METHOD;

          if (appVariables.isSupplement) {
            var allowedValues = supplementHelper.getAllowedValuesForPolicyProperty('paymentMethod', self.policyId);
            if (allowedValues === null) {
              allowedValues = [_.find(paymentMethodOptions, {code: self.paymentDetails.method}).value];
            }
            var newPaymentMethodOptions = [];

            angular.forEach(paymentMethodOptions, function(item) {
              if (allowedValues.indexOf(item.value) !== -1) {
                newPaymentMethodOptions.push(item);
              }
            });

            paymentMethodOptions = angular.copy(newPaymentMethodOptions);
          }

          return paymentMethodOptions;
        };

        /**
         * zwraca listę opcji dla metod płatności
         * UWAGA zakładamy że uprzednio uruchomiono metodę setPaymentModalData() w celu inicjalizacji danych o polisie
         * @return {Array}
         */
        this.getPaymentFrequencyOptions = function() {
          var paymentFrequencyOptions = RESOURCES.FREQUENCY_CONTRIBUTIONS;

          if (appVariables.isSupplement) {
            var allowedValues = supplementHelper.getAllowedValuesForPolicyDynamicValues('premiumFrequency');
            if (allowedValues === null) {
              allowedValues = [self.paymentDetails.frequency];
            }
            var newPaymentFrequencyOptions = [];

            angular.forEach(paymentFrequencyOptions, function(item) {
              if (allowedValues.indexOf(item.value) !== -1) {
                newPaymentFrequencyOptions.push(item);
              }
            });

            paymentFrequencyOptions = angular.copy(newPaymentFrequencyOptions);
          }

          return paymentFrequencyOptions;
        };

        /**
         * Pobranie szczegółów płatności dla zadanej polisy
         * @param {string|null} policyId id polisy (jeśli niepodane, to domyślne zachowanie)
         * @pram {boolean} withDesc - jeśli true, to dostaniemy methodDesc, frequencyDesc, delayDesc
         */
        this.getPaymentDetails = function(policyId, withDesc) {
          if (!policyId) {
            return self.paymentDetails;
          } else {
            var policyData = null;
            var paymentDetails = _.extend({}, self._getBasicPaymentDetails(policyId));
            angular.forEach(mainDataContainer.policies, function(policy) {
              if (policy.number === policyId) {
                policyData = policy.getData();
              }
            });

            paymentDetails.frequencyOptions = self.getPaymentFrequencyOptions(policyData);
            paymentDetails.methodOptions = self.getPaymentMethodOptions(policyData, paymentDetails.method);
            paymentDetails.delayOptions = self.getPaymentDelayOptions(policyData);
            paymentDetails.commonPaymentData = self.isFraudulentClient;

            if (withDesc) {
              angular.forEach(['frequency', 'method', 'delay'], function(attr) {
                if (paymentDetails[attr]) {
                  angular.forEach(paymentDetails[attr + 'Options'], function(option) {
                    if (option.value === paymentDetails[attr]) {
                      paymentDetails[attr + 'Desc'] = option.name;
                    }
                  });
                } else {
                  paymentDetails[attr + 'Desc'] = '';
                }
              });
            }

            //przy wznowieniach odczyt odroczenia z odpowiedniego atrybutu
            if (policyData.product.dynamicValues.renewal && policyData.product.dynamicValues._chosenPaymentProrogationOrRenewalCalendarDates === CONSTANTS.PROROGATION_ATTR_DATE && angular.isArray(policyData.product.dynamicValues.renewalCalendarDates) && policyData.product.dynamicValues.renewalCalendarDates.length > 0) {
              paymentDetails.delay = policyData.product.dynamicValues.renewalCalendarDates[0];
            }

            return paymentDetails;
          }
        };

        this._getBasicPaymentDetails = function(policyId) {
          var paymentDetails = {};
          var policyData = _.find(mainDataContainer.policies, {number: policyId});
          var policyExtraData = summaryHelper.getPolicyExtraData(policyData, CONSTANTS.POLICY_EXTRA_PAYMENT);
          paymentDetails.frequency = policyExtraData.frequency;
          paymentDetails.method = self._getMethodCode(policyData);
          paymentDetails.delay = policyExtraData.paymentProrogation;
          return paymentDetails;
        };

        /**
         * get payment method code
         * @param {Object} policyData
         * @return {string} one of CONSTANTS.PAYMENT_METHOD_...
         * @private
         */
        this._getMethodCode = function(policyData) {
          var methodCode = CONSTANTS.PAYMENT_METHOD_TRANSFER; // by default
          lsnNg.forEach(RESOURCES.PAYMENT_METHOD, function(methodData) {
            if (methodData.value === policyData.paymentMethod &&
              (methodData.code === CONSTANTS.PAYMENT_METHOD_BLIK ? policyData.product.dynamicValues._isBlikPayment : !policyData.product.dynamicValues._isBlikPayment)
            ) {
              methodCode = methodData.code;
              return false;
            }
            return true;
          });
          return methodCode;
        };

        /**
         * Pobranie id polisy
         */
        this.getPolicyId = function() {
          return self.policyId;
        };


        /**
         * Zapisanie szczegółów płatności polisy
         */
        this.savePaymentDetails = function(paymentDetails, policyId) {
          var polPayDetails = null,
            frequency = null,
            method = null;
          if (paymentDetails.commonPaymentData) { // set data for rest of policies
            angular.forEach(mainDataContainer.policies, function(policy) {
              if (policy.number !== policyId) {
                polPayDetails = self.getPaymentDetails(policy.number, false);
                // trying to set same payment details
                frequency = _.find(polPayDetails.frequencyOptions, {value: paymentDetails.frequency});
                if (frequency) {
                  polPayDetails.frequency = frequency.value;
                }
                method = _.find(polPayDetails.methodOptions, {code: paymentDetails.method});
                if (method) {
                  polPayDetails.method = method.code;
                }
                self._saveData(polPayDetails, policy.number);
              }
            });
          }
          // set data for currently edited policy
          self._saveData(paymentDetails, policyId);

          actionHelper.runAction('paymentEdited');
          actionHelper.runAction('saveApplication');
        };

        /**
         * proper data saving
         * @param {Object} paymentDetails
         * @param {string} policyId
         * @private
         */
        this._saveData = function(paymentDetails, policyId) {
          var policy = {};
          angular.forEach(mainDataContainer.policies, function(val) {
            if (val.number === policyId) {
              policy = val;
            }
          });

          policy.set('frequency', paymentDetails.frequency);
          policy.set('paymentMethod', _.find(RESOURCES.PAYMENT_METHOD, {code: paymentDetails.method}).value);

          policy.product.set('dynamicValues', {
            _isBlikPayment: paymentDetails.method === CONSTANTS.PAYMENT_METHOD_BLIK
          });

          if (policy.product.dynamicValues.renewal) { //jeśli wznowienie
            if (self.getProrogationAttrByValue(paymentDetails.delay) === CONSTANTS.PROROGATION_ATTR_DATE) {
              policy.product.set('dynamicValues', {
                renewalCalendarDates: [paymentDetails.delay],
                _chosenPaymentProrogationOrRenewalCalendarDates: CONSTANTS.PROROGATION_ATTR_DATE,
                paymentProrogation: null
              });
            } else {
              policy.product.set('dynamicValues', {
                renewalCalendarDates: null,
                _chosenPaymentProrogationOrRenewalCalendarDates: CONSTANTS.PROROGATION_ATTR_DAYS,
                paymentProrogation: paymentDetails.delay
              });
            }
          } else { //przypadek standardowy
            policy.product.set('dynamicValues', {
              paymentProrogation: paymentDetails.delay
            });
          }
        };

        /**
         * zwraca nazwę atrybutu przetrzymującego informację o odroczeniu na podstawie ustawionej wartości
         * @param  {*} value wartość ustawiona / dla jakiej szukamy
         * @return {String} CONSTANTS.PROROGATION_ATTR...
         */
        this.getProrogationAttrByValue = function(value) {
          var found = lsnUtils.findObjInArray(self.paymentDetails.delayOptions, {
            value: value
          });
          return (found !== null) ? found.attr : null;
        };
      };

      return SummaryPaymentHelperClass;
    }
  ]);
