angular.module('lsnBase.models')
.factory('LsnSubjectSelectUiConfigModelV1', ['LsnAbstractModel',
  function(LsnAbstractModel) {

    var LsnSubjectSelectUiConfigModelV1 = function()
    {
      this.objectName = 'SubjectSelectUiConfig';

      this.fieldsProperties = {
        renderMode: {
          type: 'string'
        },
        initialText: {
          type: 'string'
        },
        defaultLackText: {
          type: 'string'
        },
        loadDefault: {
          type: 'bool'
        },
        maxNumberOfItemsToSelection: {
          type: 'int'
        }
      };

      this.renderMode = null; //  string  Tryb pracy kontrolki, możliwe tryby to:
                              // list – lista
                              // search – wyszukiwanie elementów
                              // locked – prezentacja bez możliwości zmiany.
                              // hide – niewidoczna
      this.initialText = null; // string  Etykieta tekstu wyświetlanego inicjalnie w kontrolce np. Wybierz.
      this.defaultLackText = null; // string  Etykieta tekstu wyświetlanego gdy brak jest elementu domyślnego.
      this.loadDefault = null; // bool  Sterowanie ładowaniem wartości domyślnej
                              // true – ładuj
                              // false – pozostaw initialText.
      this.maxNumberOfItemsToSelection = null; // ile maksymalnie można wybrać elementów z selecta
                                               // 0 - zignoruj atrubyt !!!!
                                               // 1 - pracujemy w trybie single selecta
                                               // 2+ - standardowy multiselect (na razie nie ograniczony co do liczby elementów, bo nie było takiego wymagania)
    };

    LsnSubjectSelectUiConfigModelV1.prototype = LsnAbstractModel.prototype;

    return LsnSubjectSelectUiConfigModelV1;
  }]);