angular.module('lsnBase.modal')
  .provider('lsnModalBaseConfig', [function(){

    var self = this;

    /**
     * Domyślne ustawienia modali
     * @type {Object}
     */
    this.defaultConfig = {
      mainTemplateUrl: 'lsn-base-templates-app/modal/modal.tpl.html',
      mainCtrlName: 'lsnModalCtrl',
      size: 'md',
      withDefaultMargin: true,
      removeMarginB: false,
      okBtnName: 'Ok',
      okBtnDisabled: false,
      okBtnSpinner: false,
      cancelBtnName: 'Anuluj',
      cancelBtnSpinner: false,
      thirdBtnName: '',
      thirdBtnDisabled: false,
      thirdBtnSpinner: false,
      title: 'Title',
      templateUrl: null,
      content: null,
      htmlContent: null,
      closeByXAvailable: true
    };

    /**
     * Magic get
     * @return {object} lsnModalBaseConfig
     */
    this.$get = [function(){
      return angular.copy(self.defaultConfig);
    }];

  }]);
