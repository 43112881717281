angular.module('spaceChat.common')
   .factory('spaceChatManagerSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var SpaceChatManager = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.basePattern = '{host}{port}{path}/{businessArea}/{apiString}/{resource}{id}{subResource}';
      this.businessArea = 'space-chat';
      this.resource = 'manager';
    };

    return new SpaceChatManager();
  }])
;