angular.module('ihestiaWidgets.policyDetails')
  .controller('ihestiaPersonEditFormCtrl', ['$scope', 'ihestiaPlatformHelper', 'policyDetailsContextHelper', '$filter', 'lsnModelFactory', 'ihestiaDictionaryHelper', 'LsnAddressModelConstants', 'ihestiaConfigHelper',
    function($scope, ihestiaPlatformHelper, policyDetailsContextHelper, $filter, lsnModelFactory, ihestiaDictionaryHelper, LsnAddressModelConstants, ihestiaConfigHelper) {

      $scope.isLife = ihestiaPlatformHelper.isLifeContext();

      /**
       * @callback PersonEditFormGetSubjectData
       * @returns any
       */
      /**
       * @typedef PersonEditFormApi
       * @property {PersonEditFormGetSubjectData} getSubjectData
       * @property {VoidFunction} refreshSubjectData
       * @property {any} formRef - reference to angularjs form object
       * @property {VoidFunction} validateAutoCompleters
       */

      /**
       * Show elements object - used by template, set on init
       * @typedef PersonEditFormShowElements
       * @property {boolean} [maidenName]
       * @property {boolean} [nipAndRegon]
       * @property {boolean} [citizenshipCode]
       * @property {boolean} [document]
       * @property {boolean} [drivingLicenceIssueDate]
       * @property {boolean} [mainAddressCountryCode]
       * @property {boolean} [postalCodeMask]
       * @property {boolean} [personalDataForm]
       * @property {boolean} [personalDataFormHeader]
       */
      $scope.showElements = {
        maidenName: null,
        nipAndRegon: null,
        citizenshipCode: null,
        document: null,
        drivingLicenceIssueDate: null,
        mainAddressCountryCode: null,
        postalCodeMask: null,
        personalDataForm: null,
        personalDataFormHeader: null,
        errors: null
      };
      /**
       * Default show elements object - used if no custom arg is passed
       */
      /**
       * @type{PersonEditFormShowElements}
       */
      $scope.defaultShowElements = {
        maidenName: false,
        nipAndRegon: true,
        citizenshipCode: true,
        document: true,
        drivingLicenceIssueDate: true,
        mainAddressCountryCode: true,
        postalCodeMask: true,
        personalDataForm: true,
        personalDataFormHeader: true,
        errors: false
      };

      /**
       * Disabled elements object - used by template, set on init
       */
      $scope.disabledElements = {
        drivingLicenceIssueDate: null,
        form: null,
        document: null,
        pesel: null,
        gender: null,
        citizenshipCode: null,
        birthDate: null
      };

      /**
       * Default disabled elements object - used if no custom arg is passed
       */
      $scope.defaultDisabledElements = {
        drivingLicenceIssueDate: true,
        form: false,
        document: false
      };

      $scope.requiredElements = {
        firstName: true,
        lastName: true,
        pesel: true,
        gender: true,
        citizenshipCode: true,
        documentType: true,
        documentNumber: true,
        mainAddress: true,
        correspAddress: true
      };

      $scope.labels = {
        mainAddress: null
      };

      $scope.tplData = {
        fieldDisabled: false,
        copySecondAddress: false,
        isForeigner: null,
        foreignerDocument: null,
        drivingLicenceIssueDate: null,
        document: null,
        mainAddress: null,
        correspAddress: null,
        /**
         * this object will be passed to firstname / lastname autocompleters
         */
        autoCompleteErrors: null
      };

      $scope.contacts = null;

      $scope.dicts = {
        countries: null,
        nationalities: null,
        identityDocumentTypes: null
      };

      $scope.noFullNameChangeOptions = {
        applyIf: $scope.isLife && !policyDetailsContextHelper.isCentral(),
        editability: {
          firstName: true,
          lastName: true
        }
      };

      $scope.addressInvalidTexts = {
        postalCode: $filter('translate')('validationMessageFillZipCode', {componentCode: 'Public'}),
        city: $filter('translate')('validationMessageFillCity', {componentCode: 'Public'}),
        streetPrefix: $filter('translate')('validationMessageFillStreetPrefix', {componentCode: 'Public'}),
        street: $filter('translate')('validationMessageFillStreet', {componentCode: 'Public'}),
        house: $filter('translate')('validationMessageFillHouseNumber', {componentCode: 'Public'})
      };

      $scope.peselCommonNumeric = {
        maxInputLength: 11,
        decimals: 0,
        min: 0,
        allowLeadingZeros: true
      };

      $scope.today = new Date(ihestiaConfigHelper.get('serverData', 'TODAY'));
      $scope.maxBirthDate = $scope.today;

      $scope.init = function() {
        $scope.getCountries();
        $scope.getDocuments();
        $scope.getNationalities();
        if ($scope.api) {
          $scope.api.getSubjectData = $scope.getSubjectData;
          $scope.api.refreshSubjectData = $scope.refreshSubjectData;
          $scope.api.validateAutoCompleters = $scope.validateAutoCompleters;
          // set form reference in api
          var formListener = $scope.$watch('userEdit', function(formRef) {
            if (formRef) {
              $scope.api.formRef = formRef;
              formListener();
            }
          });
        }
        if ($scope.options && $scope.options.noFullNameChange !== undefined) {
          $scope.noFullNameChangeOptions.applyIf = $scope.options.noFullNameChange;
        }
        $scope.mergeArguments();
        $scope.refreshSubjectData();
      };

      $scope.refreshSubjectData = function() {
        $scope.mapAddresses();
        $scope.mapContacts();
        if ($scope.subjectData.pesel && $scope.subjectData.pesel.isValidPESEL()) {
          $scope.subjectData.birthDate = new Date($scope.subjectData.birthDate || $scope.subjectData.pesel.peselToBirthdate());
          $scope.subjectData.gender = $scope.subjectData.gender || $scope.subjectData.pesel.peselToSex();
        }
        $scope.mapDocument();
      };

      $scope.mapAddresses = function() {
        $scope.tplData.mainAddress = null;
        $scope.tplData.correspAddress = null;

        _.forEach($scope.subjectData.addresses, function(address) {
          if (address.code === LsnAddressModelConstants.ADDRESS_TYPE_STAL) {
            $scope.tplData.mainAddress = _.clone(address);
          } else if (address.code === LsnAddressModelConstants.ADDRESS_TYPE_KORE) {
            $scope.tplData.correspAddress = _.clone(address);
          }
        });

        if ($scope.tplData.mainAddress) {
          $scope.tplData.copySecondAddress = $scope.tplData.correspAddress ? $scope.areEqualAddresses($scope.tplData.mainAddress, $scope.tplData.correspAddress) : true;
        }

        // brak adresu stałego, więc go tworzymy
        if (!$scope.tplData.mainAddress) {
          $scope.tplData.mainAddress = $scope._initAddress({
            code: LsnAddressModelConstants.ADDRESS_TYPE_STAL,
            countryCode: $scope.isLife ? 'PL' : null
          });
        }

        // brak adresu korespondecyjnego, więc go tworzymy
        if (!$scope.tplData.correspAddress) {
          $scope.tplData.correspAddress = $scope._initAddress({
            code: LsnAddressModelConstants.ADDRESS_TYPE_KORE,
            countryCode: $scope.isLife ? 'PL' : null
          });
        }

        if ($scope.tplData.copySecondAddress) {
          $scope.tplData.correspAddress = $scope._initAddress({
            code: LsnAddressModelConstants.ADDRESS_TYPE_KORE,
            countryCode: $scope.isLife ? 'PL' : null
          });
        }
      };

      $scope.mapContacts = function() {
        $scope.contacts = {};
        _.forEach($scope.subjectData.contacts, function(elem) {
          $scope.contacts[elem.code] = elem;
        });

        if (!$scope.contacts.komo) {
          $scope.contacts.komo = {
            countryPrefix: '+48'
          };
        } else if ($scope.contacts.komo.countryPrefix === '') {
          $scope.contacts.komo.countryPrefix = '+48';
        }
      };

      $scope.mapMiscData = function() {
        // foreigner
        $scope.tplData.isForeigner = $scope.subjectData.citizenshipCode !== 'PL';
        $scope.tplData.foreignerDocument = $scope.tplData.isForeigner ? $scope.subjectData.documents[0] : null;

        // driving license issue date
        var issueDate = _.get($scope.subjectData, 'dynamicValues.drivingLicenceIssueDate');
        $scope.tplData.drivingLicenceIssueDate = issueDate ? new XDate(issueDate).toString('yyyy-MM-dd') : null;
      };

      $scope.mapDocument = function() {
        // initialize document
        $scope.tplData.document = _.first($scope.subjectData.documents) || {
          code: null,
          number: null
        };
      };

      /**
       *
       * @param {LsnAddressModelV1} address1
       * @param {LsnAddressModelV1} address2
       * @return {boolean}
       */
      $scope.areEqualAddresses = function(address1, address2) {
        var propsToCheck = ['postalCode', 'city', 'streetPrefix', 'street', 'house', 'apartment', 'countryCode'];
        return _.isEqual(_.pick(address1, propsToCheck), _.pick(address2, propsToCheck));
      };

      /**
       * Zmienił się pesel, więc przeliczamy dane, które da się z niego wyciągnąć
       * @return {undefined}
       */
      $scope.onPeselChange = function() {
        $scope.userEdit.pesel.$invalid = false;
        if (!!$scope.subjectData.pesel && $scope.subjectData.pesel.isValidPESEL()) {
          $scope.subjectData.birthDate = new Date($scope.subjectData.pesel.peselToBirthdate());
          $scope.subjectData.gender = $scope.subjectData.pesel.peselToSex();
        } else {
          //komunikat o błędnej walidacji PESEL tylko gdy podano jakiś numer
          //w przeciwnym przypadku komunikat 'Uzupełnij PESEL'
          if ($scope.subjectData.pesel !== '' && $scope.subjectData.pesel !== null) {
            $scope.userEdit.invalidPeselMessage = $filter('translate')('wrongPesel', {componentCode: 'Public'});
          }
          $scope.userEdit.pesel.$invalid = true;
        }
      };

      /**
       * Clear corresp address when copy checkbox checked
       */
      $scope.onCopySecondAddressChange = function() {
        if ($scope.tplData.copySecondAddress) {
          $scope.tplData.correspAddress = $scope._initAddress({
            code: LsnAddressModelConstants.ADDRESS_TYPE_KORE,
            countryCode: $scope.isLife ? 'PL' : null
          });
        }
      };

      $scope.getNationalities = function() {
        //słowniki dla obcokrajowca
        $scope.nationalities = {
          'PL': {
            code: 'PL',
            description: 'POLSKIE - POLSKA',
            name: 'POLSKIE'
          }
        };

        ihestiaDictionaryHelper.getDictWithPromise('nationalities', {requestType: 'searches'})
          .then(function(dict) {
            $scope.dicts.nationalities = dict;
          }, lsnNg.noop);
      };

      $scope.getDocuments = function() {
        ihestiaDictionaryHelper.getDictWithPromise('identityDocumentTypes', {requestType: 'get'})
          .then(function(dict) {
            $scope.dicts.identityDocumentTypes = dict;
          }, lsnNg.noop);
      };

      $scope.getCountries = function() {
        ihestiaDictionaryHelper.getDictWithPromise('countries', {requestType: 'searches'})
          .then(function(dict) {
            $scope.dicts.countries = dict;
          }, lsnNg.noop);
      };

      /**
       *
       * @return {LsnPersonModelV2}
       */
      $scope.getSubjectData = function() {
        $scope.subjectData.addresses = [
          $scope.tplData.mainAddress
        ];
        if (!$scope.tplData.copySecondAddress) {
          $scope.subjectData.addresses.push($scope.tplData.correspAddress);
        } else { // jeśli adres koresp ma byc taki sam jak stały
          $scope.subjectData.addresses.push(_.merge(
            {},
            $scope.tplData.mainAddress,
            {code: LsnAddressModelConstants.ADDRESS_TYPE_KORE}));
        }
        // wypełniamy kontakt
        $scope.subjectData.contacts = [];

        angular.forEach($scope.contacts, function(contactValue, contactCode) {
          var newContact = {
            code: contactCode
          };
          angular.extend(newContact, contactValue);
          // upeweniamy się, że jest + w prefixie nr tel.
          if (newContact.countryPrefix && newContact.countryPrefix.charAt(0) !== '+') {
            newContact.countryPrefix = '+' + newContact.countryPrefix;
          }
          $scope.subjectData.contacts.push(newContact);
        });
        $scope.subjectData.documents = [$scope.tplData.document];
        return $scope.subjectData;
      };

      /**
       * Merge directive arguments
       */
      $scope.mergeArguments = function() {
        // set show elements
        if ($scope.customShowElements) {
          $scope.showElements = $scope.mergeObjects($scope.customShowElements || {}, $scope.defaultShowElements);
        } else {
          $scope.showElements = _.clone($scope.defaultShowElements);
        }

        if ($scope.customDisabledElements) {
          $scope.disabledElements = $scope.mergeObjects($scope.customDisabledElements || {}, $scope.defaultDisabledElements);
        } else {
          $scope.disabledElements = _.clone($scope.defaultDisabledElements);
        }

        if ($scope.customLabels) {
          $scope.labels = $scope.mergeObjects($scope.customLabels || {}, {});
        } else {
          $scope.labels = {};
        }
      };

      /**
       *
       * @param {object} mutableObject
       * @param {object} mergingObject
       * @returns {object}
       */
      $scope.mergeObjects = function(mutableObject, mergingObject) {
        _.forEach(_.toPairs(mergingObject), function(pair) {
          var key = pair[0];
          var value = pair[1];
          if (mutableObject[key] === undefined) {
            mutableObject[key] = value;
          }
        });
        return mutableObject;
      };

      /**
       *
       * @param params
       * @returns {LsnAddressModelV1}
       * @private
       */
      $scope._initAddress = function(params) {
        var address = lsnModelFactory.getObject('Address').getData();
        if (params) {
          _.assign(address, params);
        }
        return address;
      };

      /**
       *
       * @param {boolean} [hideValidation]
       */
      $scope.validateAutoCompleters = function(hideValidation) {
        $scope.tplData.autoCompleteErrors = hideValidation ? null : {
          firstName: _.get($scope.userEdit.firstName, '$invalid', false),
          lastName: _.get($scope.userEdit.lastName, '$invalid', false),
          mainAddress: $scope._getErrorsFromAddressFormGroup($scope.userEdit.mainAddress)
        };
        if (!$scope.tplData.copySecondAddress) {
          $scope.tplData.autoCompleteErrors.correspAddress = hideValidation ? false : $scope._getErrorsFromAddressFormGroup($scope.userEdit.correspAddress);
        }
      };

      $scope._getErrorsFromAddressFormGroup = function(group) {
        return {
          postalCode: _.get(group, 'pstCode.$invalid'),
          city: _.get(group, 'cty.$invalid'),
          house: _.get(group, 'house.$invalid')
        };
      };

      $scope._isAddressEmpty = function(address) {
        var isEmpty = false;
        _.forEach(['postalCode', 'city', 'streetPrefix', 'street', 'house', 'apartment', 'countryCode'], function(property) {
          isEmpty = !address[property];
          if (isEmpty) {
            return;
          }
        });
        return isEmpty && (!address.countryCode || address.countryCode === 'PL');
      };

      $scope.init();
    }]);
