angular.module('menuLeadingColor')
  .controller('leadingColorCtrl', ['$scope', 'ihestiaOssSalesprofileSvc', 'salesProfileSettingsSvc', '$window', 'leadingColorModalHelper', '$filter',
    function($scope, ihestiaOssSalesprofileSvc, salesProfileSettingsSvc, $window, leadingColorModalHelper, $filter) {

      $scope.uploaderLOptions = {
        name: 'jupiterLogo',
        oneFileDisabled: true,
        acceptFileTypes: /(\.|\/)(jpg|jpeg|png|svg)$/i,
        messages: {
          acceptFileTypes: $filter('translate')('lc.acceptFileTypesMessages', {componentCode: 'Menu'})
        }
      };      

      $scope.uploaderLPOptions = {
        name: 'jupiterLogoPrint',
        oneFileDisabled: true,
        acceptFileTypes: /(\.|\/)(jpg|jpeg|png|svg)$/i,
        messages: {
          acceptFileTypes: $filter('translate')('lc.acceptFileTypesMessages', {componentCode: 'Menu'})
        }
      };

      $scope.uploadCallbacks = {
        afterAllFileSend: leadingColorModalHelper.afterAllFileSend
      };

      $scope.tplOptions = {attachmentType: 'link'};

      $scope.init = lsnNg.noop;

      $scope.onSelectProfileNumber = function(item) {
        salesProfileSettingsSvc.get(item.symbol, null, null, null, null, {allowedStatuses: [404]}).then(function(resp){
          $scope.helper.tplData.context.profileFound = true;
          $scope.helper.setParametersFromResponse(resp.data);
          $scope.helper.tplData.context.profileNumber = item;
        }, function(resp){
          if(resp.status === 404){
            $scope.helper.tplData.context.profileFound = false;
            $scope.helper.clearContextForNoData();
            $scope.helper.tplData.context.profileNumber = item;
          }
        });
      };

      $scope.removeProfileNumber = function() {
        $scope.helper.tplData.profileNumberInput = '';
        $scope.helper.tplData.context.profileNumber = null;
      };

      $scope.getProfilesNumbers = function(query) {
        $scope.loadingProfilesNumbers = true;
        var profileNumbers = [];
        return ihestiaOssSalesprofileSvc.get('users', {
          count: 50,
          filter: query
        }, 'searches/' + $scope.helper.tplData.uniqeReqId).then(function(response) {
          $scope.loadingProfilesNumbers = false;
          if (angular.isObject(response) && angular.isObject(response.data) && angular.isArray(response.data.items)) {
            angular.forEach(response.data.items, function(item) {
              profileNumbers.push(item);
            });
          }
          return profileNumbers;

        }, lsnNg.noop);
      };

      $scope.downloadSrcFile = function(whichPath){
        $window.open($scope.helper.tplData.context[whichPath], '_blank');
      };

      $scope.clearSrcFile = function(which){
        $scope.helper.tplData.context[which] = [];
        $scope.helper.tplData.context[which + 'Path'] = '';
        if(which === 'logo'){
          $scope.helper.tplData.context.logoUploadInited = true;
        } else {
          $scope.helper.tplData.context.printLogoUploadInited = true;
        }
        
      };

      $scope.init();
    }
  ]);