angular.module('ihestiaWidgets.updateContactData')
  .factory('myCustomerContactDataModalHelper', ['LsnModalHelper',
    function(LsnModalHelper){

    var myCustomerContactDataModalHelper = function() {
      LsnModalHelper.apply(this, arguments);

      /**
       * Rozszerzamy opcje modala
       * @type {String}
       */
      this.setOptions({        
        templateUrl: 'ihestia-widgets-templates-app/myCustomerContactData/modal/myCustomerContactDataModal.tpl.html',
        size: 'lg',
        title: ['ucd.completeContactDataForClient', {
          componentCode: 'Dashboard'
        }],
        okBtnName: ['save', {
          componentCode: 'Public'
        }],
        cancelBtnName: ''
      });

      /**
       * Nadpisujemy showModal
       */
      var parentshowModalFunc = this.showModal;
      this.showModal = function(modalData) {
        // upewniamy się, że mamy dane pytania w modelu
        if(!modalData) {
          modalData = {
            workerData: {},
            agencyData: {}
          };
        }

        // pokazujemy modal
        return parentshowModalFunc.apply(this, [modalData]);
      };

    };

    return new myCustomerContactDataModalHelper();
  }]);