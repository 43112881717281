angular.module('ihestiaCommonDirectives.binaryUpload')    
    .service('BlobService', function () {
        this.createBlob = function (parts, type) {
            var BlobBuilder,
                bb;
            try {
                return new Blob(parts, {type: type});
            } catch (e) {
                BlobBuilder = window.BlobBuilder ||
                    window.WebKitBlobBuilder ||
                    window.MozBlobBuilder ||
                    window.MSBlobBuilder;
                if (BlobBuilder) {
                    bb = new BlobBuilder();
                    bb.append(parts);
                    return bb.getBlob(type);
                } else {
                    throw new Error('Unable to create blob');
                }
            }
        };

        this.dataURItoBlob = function (dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            }
            else {
                byteString = unescape(dataURI.split(',')[1]);
            }

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return this.createBlob([ia], mimeString.toString());
        };
    });