angular.module('salesPath2')
  .controller('productsClausesCleanupModalCtrl', ['$scope', '$sce', '$filter', 'productsClausesCleanupHelper',
    function($scope, $sce, $filter, productsClausesCleanupHelper) {
      var content = $filter('translate')(productsClausesCleanupHelper.modalData.content);
      $scope.message = $sce.trustAsHtml(content.replace(/(?:\r\n|\r|\n)/g, '<br />'));

      /**
      * init
      */
      $scope.init = function() {
        // empty
      };

      $scope.init();

    }
  ]);
