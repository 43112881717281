angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryBrandsSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryBrandsSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'PolicyBrand';

      };
      return new RestDictionaryBrandsSvc();
    }
  ]);