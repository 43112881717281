angular.module('ihestiaRestServicesBase')

   .factory('policyPaymentsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var PolicyPayments = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'policypayments';
      this.businessArea = 'payments';
    };

    return new PolicyPayments();
  }])
  ;