angular.module('ihestiaWidgets.policyDetails')
  .factory('IhestiaLifeAnniversaryConstants', [
    function() {
      var CONSTANTS = {};
      CONSTANTS.CHANGE_RENEWAL = 'RENEWAL';
      CONSTANTS.CHANGE_TERMINATION = 'TERMINATION';
      CONSTANTS.CHANGE_INDEXATION = 'INDEXATION';

      CONSTANTS.ATTRIBUTE_TO_BE_INDEXED = 'ToBeIndexed';
      CONSTANTS.ATTRIBUTE_TO_BE_INDEXED_IN_THIS_YEAR = 'ToBeIndexedInThisYear';
      CONSTANTS.ATTRIBUTE_TO_BE_RENEWED = 'ToBeRenewed';
      CONSTANTS.ATTRIBUTE_TO_BE_TERMINATED = 'ToBeTerminated';
      CONSTANTS.ATTRIBUTE_PREMIUM_FREQUENCY = 'InstallmentsCount';
      CONSTANTS.ATTRIBUTE_DOCUMENT = 'AttachedDocumentGuid';

      CONSTANTS.INDEXATION_CONFIRM = 'INDEXATION_CONFIRM';
      CONSTANTS.INDEXATION_NO_NEXT_YEAR = 'INDEXATION_NO_NEXT_YEAR';
      CONSTANTS.INDEXATION_NO_END_INSURNACE = 'INDEXATION_NO_END_INSURNACE';

      CONSTANTS.ANNIVERSARY_DOCUMENT_CODE = 'PISMO_ROCZNICOWE_OFR';
      CONSTANTS.ANNIVERSARY_DOCUMENT_ZAL_CODE = 'PISMO_ROCZNICOWE_OFR_ZAL';
      CONSTANTS.CONDITIONS_CHANGE_RELOAD_ERROR = 'CONDITIONS_CHANGE_RELOAD_ERROR';
      return CONSTANTS;
    }])
  .service('ihestiaLifeAnniversaryHelper', ['$filter', 'IhestiaLifeAnniversaryConstants', 'applicationsSvc', 'lsnModelHelper', '$q', 'applicationsPrintoutsSvc', 'policiesPrintoutsSvc', 'ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', 'conditionsChangeModalHelper', 'lsnConfirmModalHelper', 'blockUiHelper', 'policyOperationsV2Svc',
    function($filter, IhestiaLifeAnniversaryConstants, applicationsSvc, lsnModelHelper, $q, applicationsPrintoutsSvc, policiesPrintoutsSvc, ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants, conditionsChangeModalHelper, lsnConfirmModalHelper, blockUiHelper, policyOperationsV2Svc) {
      var AnniversaryHelper = function() {
        var self = this;

        this._availableChangesDict = null;

        /**
         * This method returns literal description of changes which will appear in top page information box.
         * The description: subject full name + a list of: change for risk + risk name,
         * @return {string}
         */
        this.getAvailableChangesDictionary = function() {
          if (!self._availableChangesDict) {
            self._availableChangesDict = {};
            self._availableChangesDict[IhestiaLifeAnniversaryConstants.CHANGE_RENEWAL] = $filter('translate')('anniversaryDetails.riskChangeExtension', {componentCode: 'iKonto'});
            self._availableChangesDict[IhestiaLifeAnniversaryConstants.CHANGE_TERMINATION] = $filter('translate')('anniversaryDetails.riskChangeExpiry', {componentCode: 'iKonto'});
            self._availableChangesDict[IhestiaLifeAnniversaryConstants.CHANGE_INDEXATION] = $filter('translate')('anniversaryDetails.riskChangeIndexation', {componentCode: 'iKonto'});
          }
          return self._availableChangesDict;
        };
        /**
         * @typedef AvailableChangesInfo
         * @property {string} subjectRef
         * @property {string} subjectName
         * @property {string[]} changes
         */
        /**
         * This method returns literal description of changes which will appear in top page information box.
         * The description: subject full name + a list of: change for risk + risk name,
         * @param {LsnOfferModelV2} offer
         * @return {AvailableChangesInfo[]}
         */
        this.mapAvailableInformationInfos = function(offer) {
          var changesData = {};
          var availableOpDict = self.getAvailableChangesDictionary();
          _.forEach(offer.risks, function(risk) {
            var personRef = self.getRelevantPersonRefFromRisk(risk);
            var riskName = _.get(risk, 'product.dynamicValues.customerPrintName', '');
            var description = '';
            if (_.get(risk, 'product.dynamicValues.isRenewable')) {
              description += '{0} {1}, '.format(availableOpDict[IhestiaLifeAnniversaryConstants.CHANGE_RENEWAL], riskName);
            }
            if (_.get(risk, 'product.dynamicValues.isIndexable')) {
              description += '{0} {1}, '.format(availableOpDict[IhestiaLifeAnniversaryConstants.CHANGE_INDEXATION], riskName);
            }
            // IHESTLIFE-5366 - additional condition for terminated risk - we check is it's ending in current year
            if (!_.get(risk, 'product.dynamicValues.isTerminated') && _.get(risk, 'product.dynamicValues._isRiskEnding')) {
              description += '{0} {1}, '.format(availableOpDict[IhestiaLifeAnniversaryConstants.CHANGE_TERMINATION], riskName);
            }
            if (!_.isEmpty(description)) {
              if (!changesData[personRef.ref]) {
                changesData[personRef.ref] = [];
              }
              changesData[personRef.ref].push(description);
            }
          });

          return _.map(_.keys(changesData), function(personRef) {
            var subject = _.find(offer.persons, ['metaData.id', personRef]);
            var description = _.replace(changesData[personRef].join('') || '', /,\s*$/, '.');
            return {
              subjectName: subject ? '{0} {1}'.format(subject.firstName, subject.lastName) : '',
              subjectRef: personRef,
              description: description
            };
          });
        };

        /**
         * Fetches available changes for anniversary policy and returns it as LsnApplicationAllowedChangesModelV2
         * @param {string} anniversaryPolicyNumber
         * @return {Promise<LsnApplicationAllowedChangesModelV2 | null>}
         */
        this.getAvailableParameters = function(anniversaryPolicyNumber) {
          return applicationsSvc.get(anniversaryPolicyNumber, null, 'parameters/available')
            .then(function(res) {
              return _.get(res, 'data.allowedChanges.allowedChanges');
            }, function(rej) {
              throw rej;
            });
        };

        /**
         * Get anniversary iOffer
         * @param {string} anniversaryPolicyNumber
         * @return {Promise<any>}
         */
        this.getAnniversaryIOffer = function(anniversaryPolicyNumber) {
          return applicationsSvc.get(anniversaryPolicyNumber, null, 'ioffer')
            .then(function(res) {
              return _.get(res, 'data', null);
            }, function() {
              return null;
            });
        };

        /**
         * Get anniversary Offer
         * @param {string} anniversaryPolicyNumber
         * @return {Promise<any>}
         */
        this.getAnniversaryOffer = function(anniversaryPolicyNumber) {
          return applicationsSvc.get(anniversaryPolicyNumber, null, 'offer')
            .then(function(res) {
              return _.get(res, 'data', null);
            }, function() {
              return null;
            });
        };

        /**
         * based solely on risk (old and new), not risk table
         * @param newOfferRisks
         * @param allowedChanges
         * @param {LsnPersonModelV2[]} offerPersons
         * @param {string} mainInsuredId
         * @return {{riskRows: *}}
         */
        this.mapAllowedChangesTableDataFromRisks = function(newOfferRisks, allowedChanges, offerPersons, mainInsuredId) {
          // risk end date for not terminated risks
          var isClient = self._isClient();
          var riskRows = _.chain(
            _.concat(_.get(allowedChanges, 'risks.existingRisks', []), _.get(allowedChanges, 'risks.newRisks')))
            .filter(_.identity)
            .map(function(allowedRiskChange) {
                // find risk on offer, if not found in new, find in original
                var offerRisk = _.find(newOfferRisks, ['metaData.id', allowedRiskChange.objectId]);
                // IHESTLIFE-5256 - filter out ZUT risk or risk entirely not editable by Client
                if ((isClient && !self._anyChangeAvailableForRisk(allowedRiskChange.attributes)) || offerRisk.symbol === 'ZUT') {
                  return null;
                }
                var riskData = {
                  value: {}
                };
                _.assign(riskData, _.pick(_.get(offerRisk, 'product.dynamicValues'),
                  ['toBeIndexed', 'toBeIndexedInThisYear', 'toBeRenewed', 'toBeTerminated']));
                riskData.riskSymbol = offerRisk.symbol;
                riskData.name = _.get(offerRisk, 'product.dynamicValues.customerPrintName', '');
                riskData.allowedAttributes = allowedRiskChange.attributes;
                // IHESTLIFE-4826 set indexationCounter risk value when _indexationCounterChanged is false
                riskData.indexationCounter = !_.get(offerRisk, 'product.dynamicValues._indexationCounterChanged', true) ? _.get(offerRisk, 'product.dynamicValues._indexationCounter', 0) : 0;
                riskData.objectId = offerRisk.metaData.id;
                riskData.personRef = _.get(self.getRelevantPersonRefFromRisk(offerRisk), 'ref');
                riskData.riskEndDate = _.get(offerRisk.product, 'dynamicValues.agreementEndDate'); // || (isClient && riskData.toBeRenewed) || (!isClient && !riskData.toBeTerminated) ? continuedRiskEndDate : offerRisk.endDate;
                return riskData;
              }
            )
            .filter(_.identity)
            .groupBy('personRef')
            .toPairs()
            .map(function(pair) {
              var subject = _.find(offerPersons, ['metaData.id', pair[0]]);
              return {
                object: self.getSubjectDescriptionForRiskTable(subject, mainInsuredId === subject.metaData.id),
                risks: pair[1]
              };
            })
            .value();
          return {
            riskRows: riskRows
          };
        };

        /**
         *
         * @param {LsnPersonModelV2} subject
         * @param {boolean} isMainInsured
         * @return {string}
         */
        this.getSubjectDescriptionForRiskTable = function(subject, isMainInsured) {
          var lines = [
            '{0} {1}'.format(subject.firstName, subject.lastName)
          ];
          lines.push(
            isMainInsured ? 'PESEL: {0}'.format(subject.pesel) : $filter('date')(subject.birthDate, 'yyyy-MM-dd')
          );
          return lines.join('\n');
        };

        this.minifyRestObject = function(object) {
          lsnModelHelper.minifyRestObject(object);
          return object;
        };

        this.putParameters = function(anniversaryPolicyNumber, request) {
          return applicationsSvc.put(anniversaryPolicyNumber, request, 'parameters');
        };

        /**
         *
         * @param {string} policyNumber
         * @param {string | undefined} offerId
         * @return {PromiseLike<any> | Promise<any>}
         */
        this.loadRiskTable = function(policyNumber, offerId) {
          var riskTableSvcCall = offerId ? applicationsPrintoutsSvc.get(offerId, null, 'insuranceTable/' + encodeURIComponent(policyNumber))
            : policiesPrintoutsSvc.get(policyNumber, null, 'insuranceTable');
          return riskTableSvcCall
            .then(function(res) {
              return res.data;
            }, function(err) {
              throw err;
            });
        };

        /**
         *
         * @param {string} offerId
         * @param {string} policyNumber
         * @return {*}
         */
        this.loadParametersAndRiskTable = function(offerId, policyNumber) {
          return $q.all([
            self.getAvailableParameters(policyNumber)
              .then(lsnNg.noop, function() {
                return IhestiaLifeAnniversaryConstants.CONDITIONS_CHANGE_RELOAD_ERROR;
              }),
            self.loadRiskTable(policyNumber, offerId)
              .then(lsnNg.noop, function() {
                return IhestiaLifeAnniversaryConstants.CONDITIONS_CHANGE_RELOAD_ERROR;
              })
          ]);
        };
        /**
         *
         * @param risk
         * @return {{role: string, ref: string}}
         */
        this.getRelevantPersonRefFromRisk = function(risk) {
          return _.size(risk.personsRefs) > 1 ? _.find(risk.personsRefs, ['role', 'CHILD']) : _.first(risk.personsRefs);
        };

        this._arrayHasTrueValue = function(array) {
          return _.includes(array, 'true') || _.includes(array, true);
        };

        this._isClient = function() {
          var currentUser = ihestiaSsoBaseInfoHelper.getUser();
          return currentUser.getCurrentCharacter().type === UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT;
        };

        this._anyChangeAvailableForRisk = function(allowedAttributes) {
          return _.chain(allowedAttributes)
            .filter(function(attr) {
              return attr.name !== IhestiaLifeAnniversaryConstants.ATTRIBUTE_TO_BE_TERMINATED;
            })
            .some(function(attr) {
              return self._arrayHasTrueValue(attr.allowedValues);
            })
            .value();
        };

        /**
         * Show change conditions modal
         * @returns {LsnApplicationModelV2 | null}
         */
        this.showChangeConditionsModal = function(modalData) {
          return conditionsChangeModalHelper.showModal(modalData)
            .then(function(data) {
              var request = data.request;
              var prevModalData = data.prevModalData;
              return lsnConfirmModalHelper.showConfirmModal({
                title: ['modal.info.title', {componentCode: 'Public'}],
                okBtnName: ['Public.confirm', {componentCode: 'Public'}],
                cancelBtnName: ['Public.cancel', {componentCode: 'Public'}],
                content: $filter('translate')('conditionsChange.confirmationContent', {componentCode: 'iKonto'})
              }).then(function() {
                blockUiHelper.showBlockUi();
                return self.putParameters(prevModalData.policyNumber, request)
                  .then(function(offerData) {
                    return offerData.data;
                  }, function(rej) {
                    blockUiHelper.hideBlockUi();
                    return self.showChangeConditionsModal(_.merge(prevModalData, {
                      messages: _.isArray(rej.data) ? rej.data : _.get(rej.data, 'messages')
                    }));
                  });
              }, function() {
                return self.showChangeConditionsModal(prevModalData);
              });
            }, function(rej) {
              throw rej;
            });
        };

        /**
         *
         * @param policyNumber
         * @return {Promise}
         */
        this.createAnniversary = function(policyNumber) {
          return applicationsSvc.post(policyNumber + '/anniversary/correct');
        };

        /**
         *
         * @param {string} policyNumber
         * @param {{
         *   comment: string,
         *   uploadedDocumentsIds: string[],
         * }} operationData
         * @param {object} [httpParams]
         * @return {Promise}
         */
        this.restoreAnniversary = function(policyNumber, operationData, httpParams) {
          return policyOperationsV2Svc.post(policyNumber + '/restore', {
            operationData: {
              type: 'WZN',
              presentInHistory: true,
              operCauseCode: 'RestoreAnniversary',
              comment: !_.isEmpty(_.get(operationData, 'comment')) ? operationData.comment : null,
              uploadedDocumentsIds: !_.isEmpty(_.get(operationData, 'uploadedDocumentsIds')) ? operationData.uploadedDocumentsIds : null
            }
          }, null, null, httpParams);
        };

        /**
         *
         * @param {string} policyNumber
         * @param {object} body
         * @param {object} httpParams
         * @return {Promise}
         */
        this.acceptAnniversary = function(policyNumber, body, httpParams) {
          return applicationsSvc.post(policyNumber + '/anniversary/accept', body || null, null, null, httpParams);
        };
      };
      return new AnniversaryHelper();
    }]);
