angular.module('ihestiaIkomunikator.notificationBar')
  .service('ihestiaIkomunikatorNotificationBarHelper', ['iKomunikatorConstants', 'iKomunikatorMessagesThreadSvc', 'IKomunikatorMessageThreadModel', '$interval',
    'detectorUserActiveHelper', 'ihestiaConfigHelper', '$timeout', 'iKomunikatorMessagesSvc', 'ihestiaIkomunikatorCommonHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorPermissionHelper',
    function(iKomunikatorConstants, iKomunikatorMessagesThreadSvc, IKomunikatorMessageThreadModel, $interval, detectorUserActiveHelper, ihestiaConfigHelper, $timeout,
      iKomunikatorMessagesSvc, ihestiaIkomunikatorCommonHelper, ihestiaIkomunikatorUserTypeHelper, ihestiaIkomunikatorPermissionHelper) {

      var NotificationBarHelper = function() {
        var self = this;

        this.defaultData = {
          searchFilters: {
            status: iKomunikatorConstants.STATUS_UNREAD,
            criteria: {},
            orderSpec: {
              direction: 'DESC',
              sortBy: 'dateFrom'
            },
            pageSpec: {
              pageNo: 0,
              pageSize: 9
            }
          },
          searchFiltersForCount: {
            pageSize: 1,
            pageNumber: 0,
            status: iKomunikatorConstants.STATUS_UNREAD
          },
          threads: [],
          loading: false,
          total: 0
        };
        this.reloadInterval = ihestiaConfigHelper.get('ikomunikator', 'NOTIFICATION_BAR_RELOAD_INTERVAL');

        this.tplData = angular.copy(self.defaultData);

        this.init = function() {
          if (!ihestiaIkomunikatorUserTypeHelper.isCentralUserLogged() && ihestiaIkomunikatorPermissionHelper.shouldShowApp()) {
            self.reloadNumberMessage();
          }
        };

        this.initSearch = function() {
          self.tplData.loading = true;

          iKomunikatorMessagesThreadSvc.findLoggedUserMessageThreads(self.defaultData.searchFilters, function(result) {
            // messageThreadRepresentersSvc.findReceivedMessages(null, function(result) {
            if (angular.isObject(result.data)) {
              self.setAnswerFromSearch(result.data);
              self.tplData.cacheId = result.data.resourceId;
            }

            self.tplData.loading = false;
          }, function() {
            self.tplData.loading = false;
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania nie odczytanych wiadomości');
          });
        };

        this.setAnswerFromSearch = function(data) {
          if (angular.isArray(data.threads)) {
            angular.forEach(data.threads, function(threadData) {
              var thread = new IKomunikatorMessageThreadModel();
              thread.fromRest(threadData);
              self.tplData.threads.push(thread);
            });
          }
        };

        /**
         * [loadFromCache funkcja wyszukuje wiadomości z cahce zgodnie z ustawionymi paramtrami wyszukiwanie]
         */
        this.loadFromCache = function() {
          self.tplData.loading = true;

          iKomunikatorMessagesThreadSvc.findThreadsFromCache(self.tplData, function(result) {
            if (angular.isObject(result.data)) {
              self.setAnswerFromSearch(result.data);
            }

            self.tplData.loading = false;
          }, function() {
            self.tplData.loading = false;
            ihestiaIkomunikatorCommonHelper.throwException('Wystąpił błąd usługi wczytywania nie odczytanych wiadomości');
          });
        };

        this.reloadNumberMessage = function() {
          var params = {allowedStatuses: [401, 402, 403, 404, 500, 501, 502, 503, 504]};
          iKomunikatorMessagesSvc.countMessageThreadWithUnreadedMessages(null, function(result) {
            $timeout(function() {
              self.tplData.total = result.data;
            }, 0);
            $timeout(function() {
              self.reloadNumberMessage();
            }, self.reloadInterval);
          }, lsnNg.noop, params);
        };

        this.reloadSearch = function() {
          if (!self.tplData.loading) {
            self.tplData.loading = true;
            self.tplData.searchFilters.pageSpec.pageNo = 0;
            self.tplData.threads = [];

            self.initSearch();
          }
        };

        this.getMessageById = function(msgId) {
          var result = null;
          if (angular.isArray(self.tplData.messages)) {
            angular.forEach(self.tplData.messages, function(message) {
              if (message.id === msgId) {
                result = message;
              }
            });
          }

          return result;
        };

        this.loadNextResult = function() {
          if (!self.tplData.loading && !self.loadedAllThreads()) {
            self.tplData.loading = true;
            self.tplData.searchFilters.pageSpec.pageNo += 1;
            self.loadFromCache();
          }
        };

        this.loadedAllThreads = function() {
          return self.tplData.searchFilters.pageSpec.pageSize * (self.tplData.searchFilters.pageSpec.pageNo + 1) >= self.tplData.total;
        };

        this.setClearData = function() {
          var total = self.tplData.total;
          var loading = self.tplData.loading;

          angular.forEach(self.tplData, function(value, key) {
            delete self.tplData[key];
          });

          self.tplData = angular.extend(self.tplData, angular.copy(self.defaultData));
          self.tplData.loading = loading;
          self.tplData.total = total;
        };

        self.init();
      };

      return new NotificationBarHelper();
    }
  ]);