angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardModalsInjectorCtrl', ['$scope', 'UserCharacterTypeConstants', 'ihestiaSsoBaseInfoHelper', 'ihestiaDashboardModalsInjectorConfig',
    function($scope, UserCharacterTypeConstants, ihestiaSsoBaseInfoHelper, ihestiaDashboardModalsInjectorConfig) {

      $scope.UserCharacterTypeConstants = UserCharacterTypeConstants;
      $scope.currentCharacter = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter();

      $scope.dashboardNumericSettings = ihestiaDashboardModalsInjectorConfig.dashboardNumericSettings;

      /**
       * EUA - sprawdzenie, czy są jakieś umowy agencyjne
       * @type {Object}
       */
      $scope.ihestiaDashboardEuaCheckerModalSettings = ihestiaDashboardModalsInjectorConfig.ihestiaDashboardEuaCheckerModalSettings;

      /**
       * Wybór tematu/podtematu/segmentu
       * @type {Object}
       */
      $scope.newTaskSubjectModalSettings = ihestiaDashboardModalsInjectorConfig.newTaskSubjectModalSettings;

      /**
       * Wybór operatora
       * @type {Object}
       */
      $scope.ihestiaDashboardNewTaskReceiverModalSettings = ihestiaDashboardModalsInjectorConfig.ihestiaDashboardNewTaskReceiverModalSettings;

      /**
       * Treść zadania
       * @type {Object}
       */
      $scope.newTaskContentModalSettings = ihestiaDashboardModalsInjectorConfig.newTaskContentModalSettings;

      /**
       * Wyszukiwanie klienta
       * @type {Object}
       */
      $scope.newTaskClientSearchModalSettings = ihestiaDashboardModalsInjectorConfig.newTaskClientSearchModalSettings;

      /**
       * Edycja klienta
       * @type {Object}
       */
      $scope.ihestiaDashboardNewTaskClientEditModalSettings = ihestiaDashboardModalsInjectorConfig.ihestiaDashboardNewTaskClientEditModalSettings;

    }
  ]);