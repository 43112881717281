/**
 * Dyrektywa do wrzucenia szablonu navbara
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaMenuUserBox')
	.directive('ihestiaMenuUserBox', ['$templateCache', 'ihestiaMenuUserBoxConfig',
		function($templateCache, ihestiaMenuUserBoxConfig) {
			return {
				restrict: 'E',
				replace: true,
				template: $templateCache.get(ihestiaMenuUserBoxConfig.defaultConfig.template),
				link: lsnNg.noop
			};
		}
	]);