angular.module('ihestiaCommonDirectives.errorHandler', [
    'lsnBase.errorHandler'
  ]).config(['lsnCommonErrorHelperProvider', 'ihestiaConfigHelperProvider',
    function(lsnCommonErrorHelperProvider, ihestiaConfigHelperProvider) {
      var configHelper = ihestiaConfigHelperProvider.getConfigHelper();

      /**
       * Opisy wszystkich opcji w lsnCommonErrorHelper (lsnBase)
       */

      lsnCommonErrorHelperProvider.displayErrorModals = configHelper.get('frontFlags', 'DISPLAY_ERROR_MODALS') !== false;
      lsnCommonErrorHelperProvider.serviceUrl = configHelper.get('errorLogger', 'ERROR_SERVICE');
      lsnCommonErrorHelperProvider.sessionExpiredEvent = 'iHestia.Session.Expired';

      lsnCommonErrorHelperProvider.frontConfig = angular.copy(APP_CONFIG); //eslint-disable-line

      var maxUiRouterStatesCount = configHelper.get('errorLogger', 'maxUiRouterStatesCount');
      if(maxUiRouterStatesCount) {
        lsnCommonErrorHelperProvider.maxUiRouterStatesCount = maxUiRouterStatesCount;
      }

      var maxLoggedSvcCount = configHelper.get('errorLogger', 'maxLoggedSvcCount');
      if(maxLoggedSvcCount) {
        lsnCommonErrorHelperProvider.maxLoggedSvcCount = maxLoggedSvcCount;
      }

      var saveErrorsOnServer = configHelper.get('errorLogger', 'saveErrorsOnServer');
      if (typeof saveErrorsOnServer === 'boolean') {
        lsnCommonErrorHelperProvider.saveErrorsOnServer = saveErrorsOnServer;
      }

      var restConfig = configHelper.get('rest');
      if(angular.isObject(restConfig) && restConfig !== null) {
        lsnCommonErrorHelperProvider.applicationCode = restConfig.AP_CALLER;
      }
    }
  ]);