/**
 * Serwis do logowania aktywności w ikoncie
 * https://ebok.atena.pl/browse/IHESTIACL-1198
 */
angular.module('ihestiaRestServicesBase')
  .service('sessionPartnerSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var SessionPartnerSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'session';
        this.businessArea = 'partners';
      };

      return new SessionPartnerSvc();
    }]);