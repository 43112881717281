angular.module('ihestiaCommonDirectives.baseUpload') 
	.service('ihestiaUploadModelHelper', ['addMd5ToFile',
		function(addMd5ToFile) {
			var UploadModelHelper = function() {
				var self = this;

				this.setStatusFile = function(fileModel, status, errorText) {
					fileModel.sendingInfo = false;
					if (angular.isDefined(errorText) && errorText !== null) {
						fileModel.errorText = errorText;
					}

					if (angular.isDefined(status) && status !== null) {
						fileModel.status = status;
					} else {
						fileModel.status = 'SUCCESS';
					}
				};

				this.convertToUploadedFile = function(fileModel, status, errorText, fileServerId) {
					self.setStatusFile(fileModel, status, errorText);

					if (angular.isDefined(fileServerId) && fileServerId !== null) {
						fileModel.fileServerId = fileServerId;
					}
				};

				this.setFileData = function(fileModel, fileData) {
					addMd5ToFile(fileData);
					fileModel.file = fileData;
					if (angular.isObject(fileData)) {
						fileModel.name = fileData.name;
						fileModel.size = parseInt(fileData.size, 10);
						fileModel.type = fileData.type;
					}
				};

				this.setDocumentType = function(file, documentType) {
					file.documentTypeName = documentType.name;
					file.documentTypeCode = documentType.code;
					file.attributes = documentType.attributes;
				};


				/**
				 * funkcja zwraca informacje o pliku do wysłania do usługi
				 * która sprawdza poprawność atrybutów wysyłąnego pliku
				 * tuż przed jego wysłaniem
				 */
				this.getInfoAboutFile = function(file) {
					var infoAboutFile = {
						dynamicValues: {}
					};
					infoAboutFile.documentCode = file.documentTypeCode;
					infoAboutFile.length = file.size;
					infoAboutFile.name = file.name;

					angular.forEach(file.attributes, function(attr) {
						if (attr.type === 'datetime') {
							var date = new XDate(attr.val);
							if (date.valid()) {
								var timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
								infoAboutFile.dynamicValues[attr.code] = (new XDate(date.getTime() - timezoneOffset)).toISOString();
							} else {
								infoAboutFile.dynamicValues[attr.code] = '';
							}
						} else {
							infoAboutFile.dynamicValues[attr.code] = attr.val;
						}
					});

					return infoAboutFile;
				};
			};

			return new UploadModelHelper();
		}
	]);
