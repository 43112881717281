/**
 * Deprecated
 */
angular.module('ihestiaCommonDirectives')
        .directive('ihestiaNumeric', ['$parse', 'deprectedHelper', function($parse, deprectedHelper) {
            return {
              restrict: 'A',
              require: 'ngModel',
              link: function($scope, $element, $attrs, ngModel)
              {

                deprectedHelper.setAsDeprecated('ihestiaNumeric', 'ihestiaNumeric jest przestarzały i powoduje wycieki pamięci! Zalecana zmiana na commonNumeric lub ngDigits');

                var settings = {};
                if ($parse($attrs.ihestiaNumeric)($scope)) {
                  settings = $parse($attrs.ihestiaNumeric)($scope);
                }
                $($element).lafNumeric(settings);

                ngModel.$parsers.unshift(function(num)// eslint-disable-line consistent-return
                {
                  if (typeof settings.separator === 'undefined')
                  {
                    var re = new RegExp(settings.separator, 'g');
                    num = num.replace(re, '');
                  }
                  num = $.trim(num.replace(',', '.'));
                  //zwracamy zawsze string, potrzebne później np przy metodach isValidPesel

                  if (typeof settings.parseTo === 'undefined') {
                    if (isNaN(num)) {
                      return '';
                    }
                    return num.toString();
                  } else if (settings.parseTo === 'float') {
                    num = parseFloat(num);
                    if (isNaN(num)) {
                      return undefined;
                    }
                    return num;
                  }
                });
              }
            };
          }]);

/*
 *funkcja do formatowania pola w inpucie wg określonego Formatu;
 *
 *  @param input - element formatowany
 *  @param events  - lista eventów podpinanych do inputa
 *
 *  @author jakub kukuryk jakubk@lsnova.pl
 */

/* eslint-disable */
// @todo eslint
(function($) {
  var methods = {
    init: function(options)
    {
      var defaults = {
        decimal: ',', //znak separatora liczb dziesiętnych
        decimalkeyCode: [188, 110], //kody klawiszy "options.decimal"  UWAGA na klawiaturę numeryczną
        separator: ' ', //separator tysięczny
        absolute: false, //brak możliwości wpisania liczb po przecinku
        decimalLength: 2, //liczba dopuszczalnych liczb po przecinku
        allowBeginZero: false, //jeżeli chcemy pozwolić wpisywać liczby z zerem wiodącym np PESEL, kod numeryczny
        negative: true, //czy liczba może być ujemna
        numericFormat: true, //formatowanie zgodne z iHestia 3994,29 || 34 223,34
        onKeyDownEvent: function() {
        }, //dodatkowa funkcja wykonywana na event KeyDown
        onBlurEvent: function() {
        }      //dodatkowa funkcja wykonywana na event blur
      };

      var settings = $.extend(defaults, options);
      $(this).data('settings', settings);
      return this.each(function()
      {
        var self = $(this);
        //nadajemy inputowi klasę laf-numeric
        self.addClass('laf-numeric');

        self.off('keydown').on('keydown', function(e) {
          settings.onKeyDownEvent(e);

          //wykonujemy przekazaną akcję
          // get decimal character and determine if negatives are allowed
          var decimal = settings.decimal;
          var negative = settings.negative;
          // get the key that was pressed
          var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
          // allow enter/return key (only when in an input box)
          if (key === 13 && this.nodeName.toLowerCase() === 'input')
          {
            return true;
          }
          else if (key === 13)
          {
            return false;
          }
          var allow = false;
          // allow Ctrl+A
          var ctrlCmd = e.ctrlKey || e.metaKey;
          if (e.shiftKey && (key === 173 || key === 189)) {
            return true;
          }

          if (ctrlCmd)
          {
            switch (key)
            {
              case 65:
              case 67:
              case 86:
              case 88:
              case 90:
              case 97:
              case 99:
              case 118:
              case 120:
              case 122:
                return true;
            }
          }

          if ((e.ctrlKey && key === 97 /* firefox */) || (e.ctrlKey && key === 65) /* opera */) {
            return true;
          }
          // allow Ctrl+X (cut)
          if ((e.ctrlKey && key === 120 /* firefox */) || (e.ctrlKey && key === 88) /* opera */) {
            return true;
          }
          // allow Ctrl+C (copy)
          if ((e.ctrlKey && key === 99 /* firefox */) || (e.ctrlKey && key === 67) /* opera */) {
            return true;
          }
          // allow Ctrl+Z (undo)
          if ((e.ctrlKey && key === 122 /* firefox */) || (e.ctrlKey && key === 90) /* opera */) {
            return true;
          }
          // allow or deny Ctrl+V (paste), Shift+Ins
          if ((key < 48 || key > 57) && (key < 96 || key > 106))
          {
            var value = $(this).val();
            /* '-' only allowed at start and if negative numbers allowed */
            if (value.indexOf('-') !== 0 && negative && (key === 173 || key === 189 || key === 109) && (value.length === 0 || parseInt($.fn.getSelectionStart(this), 10) === 0))
            {
              return true;
            }
            /* only one decimal separator allowed */

            if (decimal && $.inArray(e.keyCode, settings.decimalkeyCode) && value.indexOf(decimal) === -1)
            {
              allow = true;
            }
            // check for other keys that have special purposes
            if (
                    key !== 8 /* backspace */ &&
                    key !== 9 /* tab */ &&
                    key !== 13 /* enter */ &&
                    key !== 35 /* end */ &&
                    key !== 36 /* home */ &&
                    key !== 37 /* left */ &&
                    key !== 39 /* right */ &&
                    key !== 46 /* del */
                    )
            {
              allow = false;
            }
            else
            {
              // for detecting special keys (listed above)
              // IE does not support 'charCode' and ignores them in keypress anyway
              if (angular.isDefined(e.charCode))
              {
                // special keys have 'keyCode' and 'which' the same (e.g. backspace)
                if (e.keyCode === e.which && e.which !== 0)
                {
                  allow = true;
                  // . and delete share the same code, don't allow . (will be set to true later if it is the decimal point)
                  if (e.which === 46) {
                    allow = true;
                  }
                }
                // or keyCode != 0 and 'charCode'/'which' = 0
                else if (e.keyCode !== 0 && e.charCode === 0 && e.which === 0)
                {
                  allow = true;
                }
              }
            }
            // if key pressed is the decimal and it is not already in the field
            if (decimal && $.inArray(e.keyCode, settings.decimalkeyCode) >= 0)
            {
              if (value.indexOf(decimal) === -1)
              {
                allow = true;
              }
              else
              {
                allow = false;
              }
            }
          }
          else
          {
            allow = true;
          }
          return allow;
        });

        self.on('blur', function(e)
        {
          //wykonujemy przekazaną akcję
          settings.onBlurEvent(e);
          var num = $(this).val();
          //przetwarzamy pobraną wartość do wartości liczbowej
          num = $.trim(num.replace(/ /g, '').replace(',', '.'));
          //num = num.replace(/^-0-9/g,'');
          if (!angular.isNumber(num))
          {
            if (isNaN(parseFloat(num, 10)) || num.split(".").length > 2) //wiecej niz 1 kropka
            {
              num = '';
            } else
            {
              if (!settings.allowBeginZero)
              {
                num = parseFloat(num, 10);
              }
            }
          }

          //przetwarzamy wartość
          if (!settings.negative && parseFloat(num) < 0 && num !== '')
          {
            num = 0;
          }
          var decimalLength = settings.decimalLength;
          if (!settings.allowBeginZero)
          {
            if (settings.absolute && num !== '')
            {
              num = parseInt(num, 10);
              decimalLength = 0;
            }
            if (num !== '')
            {
              if (settings.numericFormat)
              {
                num = $.number(num, decimalLength, settings.decimal, settings.separator);
              }
            }
          }
          self.val(num);
        });

        self.on('focus', function()
        {
          var num = $(this).val();
          var re = new RegExp(settings.separator, 'g');
          num = $.trim(num.replace(re, ''));
          self.val(num);
          //fiku myku dla IE aby kursor umieszczony był na końcu tekstu w inpucie http://jsfiddle.net/pevtN/2/
          window.o = this;
          if (o.setSelectionRange) { /* DOM */
            setTimeout(function() {
              "use strict";
              o.setSelectionRange(o.value.length, o.value.length);
            }, 2);
          } else if (o.createTextRange) /* IE */
          {
            var r = o.createTextRange();
            r.moveStart('character', o.value.length);
            r.select();
          }
        });


      });
    },
    change: function()
    {
      //podłączyć zmianę jako funkcję
    },
    val: function(value)
    {
      var self = $(this);
      var settings = self.data('settings');
      if (value) {
        value = parseFloat(value, 10);
        if (!isNaN(value)) {
          var input = $(this);
          self.val(value).blur();
        }
      } else {
        var num = self.val();
        var re = new RegExp(settings.separator, 'g');
        num = parseFloat($.trim(num.replace(re, '').replace(',', '.')), 10);

        if (typeof settings.parseTo === 'undefined') {
          if (isNaN(num)) {
            return '';
          }
          return num.toString();
        } else if (settings.parseTo === 'float') {
          if (isNaN(num)) {
            return undefined;
          }
          return num;
        }
      }
    }
  };

  $.fn.lafNumeric = function(method) {
    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    } else if (typeof method === 'object' || !method) {
      return methods.init.apply(this, arguments);
    } else {
      $.error('Method ' + method + ' does not exist on lafNumeric');
    }
  };
})(jQuery);


$.fn.setSelection = function(o, p)
{
  // if p is number, start and end are the same
  if (typeof p === "number") {
    p = [p, p];
  }
  // only set if p is an array of length 2
  if (p && p.constructor === Array && p.length === 2)
  {
    if (o.createTextRange)
    {
      var r = o.createTextRange();
      r.collapse(true);
      r.moveStart('character', p[0]);
      r.moveEnd('character', p[1]);
      r.select();
    }
    else if (o.setSelectionRange)
    {
      o.focus();
      o.setSelectionRange(p[0], p[1]);
    }
  }
};




// Based on code from http://javascript.nwbox.com/cursor_position/ (Diego Perini <dperini@nwbox.com>)
$.fn.getSelectionStart = function(o)
{
  if (o.createTextRange)
  {
    var r = document.selection.createRange().duplicate();
    r.moveEnd('character', o.value.length);
    if (r.text === '') {
      return o.value.length;
    }
    return o.value.lastIndexOf(r.text);
  } else {
    return o.selectionStart;
  }
};

// Based on code from http://javascript.nwbox.com/cursor_position/ (Diego Perini <dperini@nwbox.com>)
$.fn.getSelectionEnd = function(o)
{
  if (o.createTextRange) {
    var r = document.selection.createRange().duplicate();
    r.moveStart('character', -o.value.length);
    return r.text.length;
  } else {
    return o.selectionEnd;
  }
};


