angular.module('ihestiaWidgets.policyDetails')
  .service('lifeControllerPolicyDetailsHelper', ['lsnConfirmModalHelper', 'blockUiHelper', 'lifePolicyOperationsHelper', '$filter',
    function(lsnConfirmModalHelper, blockUiHelper, lifePolicyOperationsHelper, $filter) {
      var IhestiaLifeControllerPolicyDetailsHelper = function() {

        this.restoreVersion = function(policyNumber) {
          return lsnConfirmModalHelper.showConfirmModal({
            title: $filter('translate')('announcement', {componentCode: 'Public'}),
            okBtnName: $filter('translate')('confirm', {componentCode: 'Public'}),
            cancelBtnName: $filter('translate')('Public.cancel', {componentCode: 'Public'}),
            content: $filter('translate')('renewProtection.confirmOperationContent', {
              componentCode: 'iKonto',
              params: [policyNumber]
            })
          }).then(function() {
            blockUiHelper.showBlockUi();
            return lifePolicyOperationsHelper.renewPolicy(policyNumber);
          }, function() {
            // handle cancel clicked on confirm modal
            throw new Error('CANCEL');
          });
        };
      };
      return new IhestiaLifeControllerPolicyDetailsHelper();
    }]);
