angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardRelatedTasksSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var TasksSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks';
        this.businessArea = 'dashboard';
        this.searchesPostfix = 'tasks/searches';
        this.pageStyle = false;
        this.port = '8282';

        this.sortBy = 'changeTime';
        this.grid.sortBy = 'changeTime';
        this.grid.sortDirection = 'desc';

      };
      return new TasksSvc();
    }
  ]);