angular.module('salesPath2')
  .controller('coownerListModalCtrl', ['$scope', 'coownerModalHelper', 'coownerHelper', 'CONSTANTS', 'CONFIG', '$sce', 'sp2CommonHelper', 'bonusMalusHelper', 'appVariables', 'dataContainerHelper',
    function($scope, coownerModalHelper, coownerHelper, CONSTANTS, CONFIG, $sce, commonHelper, bonusMalusHelper, appVariables, dataContainerHelper) { // eslint-disable-line angular/di

      $scope.init = function() {
        coownerModalHelper.init();
        $scope.tplData = coownerModalHelper.editCoownerModalData.tplData;
        $scope.loadedDataForCoownerId = null;

        var object = dataContainerHelper.getObject(coownerModalHelper.editCoownerModalData.objectType, coownerModalHelper.editCoownerModalData.objectId);
        var coowners = object.getAdditionalData('coowners');
        $scope.persons = coownerHelper.getPersonsInfo(coowners);
        if (coownerModalHelper.editCoownerModalData.objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE && typeof CONFIG.BEHAVIOR.multipleVehicles !== 'undefined' && CONFIG.BEHAVIOR.multipleVehicles) {
          coownerModalHelper.setVehicleInCoowners($scope.persons);
        }

        $scope.selectCoowner($scope.persons[0].id);
        $scope.fireListeners();
      };

      /**
       * eventy
       */
      $scope.fireListeners = function() {
        // Dodaj wspólwłaściciela
        $scope.$on('iHestiaCommonModalOkBtnClicked.coownerListModal', function() {
          if (!appVariables.isSupplement && !($scope.appVariables.readOnly && !$scope.appVariables.isOcBuyer)) {
            coownerModalHelper.addCoowner();
          }
          coownerHelper.showedCoownerId = null;
        });

        $scope.$on('iHestiaCommonModalCancelBtnClicked.coownerListModal', function() {
          coownerHelper.showedCoownerId = null;
        });
      };

      $scope.selectCoowner = function(personId) {
        $scope.personData = coownerHelper.getSelectedPersonData(personId);

        if (coownerModalHelper.editCoownerModalData.objectType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
          coownerModalHelper.refreshBMData(personId);
        }
        $scope.loadedDataForCoownerId = personId;
        coownerHelper.showedCoownerId = personId;
      };

      $scope.removeCoowner = function(personId) {
        coownerModalHelper.removeCoowner(personId);
      };

      $scope.editCoowner = function(personId) {
        coownerModalHelper.editCoowner(personId);
      };

      $scope.showBonusMalusPopup = function(personId) {
        //dane po edycj bonusMalus mogę się zmienić
        $scope.loadedDataForCoownerId = null;
        coownerModalHelper.showBonusMalusPopup(personId, true);
      };

      $scope.init();

  }
  ]);
