angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminSystemMessageModel', ['$injector', function($injector) {
    var AdminMessage = function() {
      var self = this;

      this.id = null;
      this.channels = ['GUI']; // w aktualnej wersji zawsze gui
      this.context = 'USER'; // w tej wersji zawsze USER
      this.created = null; // data utworzenia
      this.updated = null; // data aktualizacji
      this.endDate = ''; // koncowa data waznosci komunikatu
      this.endHour = '23:59'; // koncowa godzina waznosci komunikatu w stringu
      this.startDate = ''; // poczatkowa data waznosci komunikatu
      this.startHour = '00:00'; // poczatkowa godzina waznosci komunikatu w stringu
      this.platforms = {};
      this.recipients = [{
        allUsers: {}
      }]; // odbiorcy, w tej wersji zawsze wszyscy
      this.title = ''; // tytul
      this.body = ''; // tresc
      this.statusText = '';
      this.logicalStatus = '';
      this.statusTextMap = {
        WORKING_COPY: 'roboczy',
        ARCHIVAL: 'archiwalny',
        IN_PROGRESS: 'w toku',
        PLANNED: 'planowany'
      };

      /**
       * Format dla ws zapisu
       * @return array
       */
      this.toPlainSaveObject = function() {
        var object = {
          channels: self.channels,
          context: self.context,
          endDate: self.endDate + 'T' + self.endHour + ':00',
          startDate: self.startDate + 'T' + self.startHour + ':00',
          title: self.title,
          body: self.body,
          recipients: self.recipients,
          platforms: self.platforms,
          id: self.id
        };

        return {
          systemMessage: object
        };
      };


      /**
       * Oczekuje daty jak XDate lub string
       * zwraca date w iso do jbossow
       * @param  {String|XDate} date [description]
       * @return {String}      [description]
       */
      this.convertDateToIso = function(date) {
        if (typeof date === 'string') {
          date = new XDate(date);
        } else if (typeof date !== 'object') {
          return '';
        }

        if (date && typeof date.valid === 'function' && date.valid()) {
          return date.toISOString();
        }

        return '';
      };

      /**
       * Zapisujemy adword w bazie
       * @return {[type]} [description]
       */
      this.save = function(callback) {
        var data = self.toPlainSaveObject(),
          service = $injector.get('ihestiaCommonAdminSystemMessageSvc');
        // zapis
        service.save(data, callback);
        return self;
      };

      /**
       * Zatwierdza komunikat
       * @param  {Function} callback
       * @return {[type]} [description]
       */
      this.commit = function(callback) {
        var service = $injector.get('ihestiaCommonAdminSystemMessageCommitSvc');
        service.post(self.id, {}, callback);
      };

      /**
       * Usuwanie wiadomosci
       * @param  {Function} callback
       * @return
       */
      this.remove = function(callback) {
        var service = $injector.get('ihestiaCommonAdminSystemMessageSvc');
        service.remove(self.id, '', callback);
      };

      /**
       * Uzupelniamy nasz model w oparciu o dane z rest
       * @param  {Object} data tablica asocjacyjna z danymi
       * @return
       */
      this.setData = function(data) {
        // ogolne dane
        $.each(data, function(attr, value) {
          self[attr] = value;
        });
        // parsowanie nietrywialnych danych
        if (data.startDate !== null) {
          var start = new XDate(data.startDate);
          self.startDate = start.toString('yyyy-MM-dd');
          self.startHour = start.toString('HH:mm');
        } else {
          self.startDate = '';
          self.startHour = '00:00';
        }
        if (data.endDate !== null) {
          var end = new XDate(data.endDate);
          self.endDate = end.toString('yyyy-MM-dd');
          self.endHour = end.toString('HH:mm');
        } else {
          self.endDate = '';
          self.endHour = '23:59';
        }

        self.statusText = self.statusTextMap[data.logicalStatus];

      };
    };

    return AdminMessage;
  }]);