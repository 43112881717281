/**
 * helper dla obliczania długości słów
 */
angular.module('ihestiaCommonDirectives')
  .service('ihestiaWordHelper', ['lsnWordHelper',
    function(lsnWordHelper) {

      return lsnWordHelper;
    }
  ]);
