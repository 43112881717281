angular.module('ihestiaWidgets.policyDetails')
  .factory('RiskBeneficiaryModel', ['LsnAbstractModel',
    function(LsnAbstractModel) {
      var RiskBeneficiaryModel = function() {
        this.objectName = 'RiskBeneficiary';
        this.modelVersion = 'v1';
        this.fieldsProperties = {
          partnerRef: {
            type: 'string'
          },
          type: {
            type: 'string'
          },
          shareRatio: {
            type: 'int'
          },
          degreeOfKinship: {
            type: 'string'
          },
          cessionary: {
            type: 'bool'
          }
        };
        this.partnerRef = null; // reference to subject of this beneficiary
        this.type = null;
        this.shareRatio = null;
        this.degreeOfKinship = null;
        this.cessionary = null;
      };

      RiskBeneficiaryModel.prototype = LsnAbstractModel.prototype;
      return RiskBeneficiaryModel;
    }
  ]);
