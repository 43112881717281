angular.module('ihestiaWidgets.commonOperations')
  .controller('ihestiaOperationsModalsInjectorCtrl', ['$scope',
    function($scope) {

      $scope.commonOperationsNumericSettings = {
        stringCodes: {
          allowLeadingZeros: true,
          parseToString: true,
          formatting: false,
          groupSeparator: '',
          decimals: 0,
          min: 0,
          maxInputLength: 20
        },
        pesel: {
          allowLeadingZeros: true,
          decimals: 0,
          formatting: false,
          groupSeparator: '',
          min: 0,
          parseToString: true
        },
        policyNumber: {
          decimals: 0,
          min: 0,
          allowLeadingZeros: true
        }
      };

      /**
       * Wyszukaj klienta
       */
      $scope.ihestiaOperationsClientSearchModalSettings = {
        treatLabelAsI18nCode: true,
        okBtnName: ['Public.search', {componentCode: 'Public'}]
      };

      /**
       * Wyniki wyszukiwania klienta
       */
      $scope.ihestiaOperationsClientSearchListModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['Public.searchResults', {componentCode: 'Public'}],
        okBtnName: ['Public.close', {componentCode: 'Public'}],
        cancelBtnName: ['Public.back', {componentCode: 'Public'}]
      };


      /**
       * Wyszukaj zadanie
       */
      $scope.ihestiaOperationsTaskSearchModalSettings = {
        treatLabelAsI18nCode: true,
        okBtnName: ['Public.search', {componentCode: 'Public'}]
      };

      /**
       * Wyniki wyszukiwania zadania
       */
      $scope.ihestiaOperationsTaskSearchListModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['Public.searchResults', {componentCode: 'Public'}],
        okBtnName: ['Public.close', {componentCode: 'Public'}],
        cancelBtnName: ['Public.back', {componentCode: 'Public'}],
        size: 'lg'
      };
    }
  ]);