angular.module('ihestiaWidgets.policyOperationsPublic')
  .controller('vehiclePurchasePublicStep2Ctrl', ['$scope', '$state', '$stateParams', '$location', 'ihestiaConfigHelper', 'vehiclePurchasePublicSvc', 'operationType', 'VerificationDataModelConstants', 'resolvedNationalities', 'resolvedIdentityDocumentTypes', 'PersonModel', 'OrganizationModel', 'vehiclePurchasePublicHelper', 'vehiclePurchaseHelper', 'clientDataHelper', 'resolvedFileAttributes', 'fileServerSvc', '$filter', '$alert', 'ihestiaAutoUploadOneListUploadHelper', 'ihestiaDictionaryHelper', 'ihestiaModelHelper', 'resolvedNbkCountries', 'ihestiaCommonConfirmModalHelper', 'resolvedWithdrawalAttributes',
    function($scope, $state, $stateParams, $location, ihestiaConfigHelper, vehiclePurchasePublicSvc, operationType, VerificationDataModelConstants, resolvedNationalities, resolvedIdentityDocumentTypes, PersonModel, OrganizationModel, vehiclePurchasePublicHelper, vehiclePurchaseHelper, clientDataHelper, resolvedFileAttributes, fileServerSvc, $filter, $alert, ihestiaAutoUploadOneListUploadHelper, ihestiaDictionaryHelper, ihestiaModelHelper, resolvedNbkCountries, ihestiaCommonConfirmModalHelper, resolvedWithdrawalAttributes) {

      $scope.clauses = {
        'K3': null,
        'K17': null
      };

      //przygotujemy listę prefiksów telefonów
      $scope.countries = resolvedNbkCountries.data;
      angular.forEach($scope.countries, function(country){
        country.phonePrefixDesc = country.phonePrefix + ' ' + country.name;
      });

      ihestiaDictionaryHelper.getDict('clauses', {
        callback: function(dictionary){
          angular.forEach(dictionary, function(clause){
            if(angular.isDefined($scope.clauses[clause.code]) && clause.isActive)
            {
              $scope.clauses[clause.code] = clause;
            }
          });
        }
      });

      $scope.validationFlags = {
        personDataRequired: true,
        phoneRequired: false,
        clausesRequired: false
      };

      $scope.coownersValidationFlags = {
        personDataRequired: true,
        phoneRequired: false,
        clausesRequired: false
      };

      $scope.fileData = {
        fileModels: [],
        fileModelsReady: false, //czy kontrolka uplodera została przygotowana. jak nie to przepisujemy pliki z wniosku
        fileWithdrawalModels: [],
        fileWithdrawalModelsReady: false //czy kontrolka uplodera została przygotowana. jak nie to przepisujemy pliki z wniosku
      };

      //upload plików
      $scope.documentType = resolvedFileAttributes.data;
      $scope.fileWithdrawalAttributes = resolvedWithdrawalAttributes.data;
      $scope.fileModels = $scope.fileData.fileModels; //backward compatibility



      $scope.uploaderName = 'vehiclePurchaseSellPublic';

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderName,
        hideQueueList: true
      };

      $scope.oneListUploaderOptions = {
        attachmentType: 'static'
      };

      $scope.formData = {};

      $scope.otherData = {
        saveErrors: []
      };

      $scope.operationType = operationType;

      $scope.shared = {
        clientData: {},
        clientAdditionalData: {}, //dane obcokrajowca do prezentacji
        sellerData: {},
        sellersString: '', //lista sprzedających
        coowners: [],
        ownersSelectList: [],
        selectedOwners: null, //id sprzedającego
        otherSellerData: clientDataHelper.prepareNewPerson(), //obiekt innego sprzedającego
        forms: []
      };

      $scope.nextPersonId = -2; //-1 zajęty już na poprzenim ekranie

      $scope.vehiclePurchasePublicForm = {};

      $scope.Constants = VerificationDataModelConstants;

      $scope.formSubmitted = false;
      $scope.today = ihestiaConfigHelper.get('serverData', 'TODAY');

      $scope.policyOperationsNumericSettings = {
        policyNumber: {
          decimals: 0,
          min: 0,
          allowLeadingZeros: true
        }
      };

      $scope.addressInvalidTexts = {
        postalCode: 'Uzupełnij kod pocztowy',
        city: 'Uzupełnij miejscowość',
        streetPrefix: 'Uzupełnij prefiks ulicy',
        street: 'Uzupełnij ulicę',
        house: 'Uzupełnij numer domu'
      };

      $scope.clientTypeDictionary = [
      {
        name: 'Osoba fizyczna',
        code: 'person'
      },
      {
        name: 'Osoba prawna',
        code: 'organization'
      }
      ];

      $scope.roles = {
        SELLER_OWNER: 'SellerOwner',
        SELLER_COOWNER: 'SellerCoowner',
        BUYER_OWNER: 'BuyerOwner',
        BUYER_COOWNER: 'BuyerCoowner'
      };

      $scope.showElements = {
        foreigner: false
      };

      //słowniki dla obcokrajowca
      $scope.nationalities = {
        'PL': {
          code: 'PL',
          description: 'POLSKIE - POLSKA',
          name: 'POLSKIE'
        }
      };
      angular.forEach(resolvedNationalities.data.items, function(v) {
        $scope.nationalities[v.code] = v;
      });

      $scope.identityDocumentTypes = {};
      angular.forEach(resolvedIdentityDocumentTypes.data, function(v) {
        $scope.identityDocumentTypes[v.code] = v;
      });

      $scope.init = function(){
        $scope.initUploader();
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.documentType);
        if(vehiclePurchasePublicHelper.verificationData !== null)
        {
          $scope.verificationData = vehiclePurchasePublicHelper.verificationData;
          $scope.currentRecord = $scope.verificationData.vehicleOwnerChangeRecords[0];

          //co jest wymagane
          if($scope.verificationData.isExternalPolicy)
          {
            $scope.validationFlags.phoneRequired = false;
            $scope.validationFlags.clausesRequired = false;
          }
          else
          {
            if(operationType === $scope.Constants.VEHICLE_PURCHASE)
            {
              $scope.validationFlags.phoneRequired = true;
            }
            else
            {
              $scope.validationFlags.phoneRequired = false;
            }
            $scope.validationFlags.clausesRequired = true;
          }

          if(vehiclePurchaseHelper.getPersonsByRole($scope.roles.BUYER_OWNER, 0, $scope.verificationData).length > 0)
          {
            //jest już kupujący na wniosku, więc ładujemy dane z wniosku
            $scope.loadClientsFromRecord();
          }
          else
          {
            $scope.initClientData(); //dane kupującego
          }
          $scope.shared.sellersString = '';
          if(vehiclePurchaseHelper.getPersonsByRole($scope.roles.SELLER_OWNER, 0, $scope.verificationData).length > 0)
          {
            $scope.shared.sellerData = clientDataHelper.prepareApplicationPerson(vehiclePurchaseHelper.getPersonsByRole($scope.roles.SELLER_OWNER, 0, $scope.verificationData)[0]);
            $scope.shared.sellersString = $scope.shared.sellersString + clientDataHelper.getPersonName($scope.shared.sellerData);
            angular.forEach(vehiclePurchaseHelper.getPersonsByRole($scope.roles.SELLER_COOWNER, 0, $scope.verificationData), function(coowner){
              $scope.shared.sellersString = $scope.shared.sellersString + ', ' + clientDataHelper.getPersonName(coowner);
            });
          }
          else
          {
            $scope.shared.sellerData = {};
          }

          $scope.setOwnerSelect();
        }
        else
        {
          $scope.back();
        }
      };

      $scope.initClientData = function()
      {
        var clientData = clientDataHelper.prepareNewPerson();
        clientData.metaData.id = $scope.getNextPersonId();
        angular.forEach($scope.shared.clientData, function(value, key){ //nie chcemy tracić dowiązania
          delete $scope.shared.clientData[key];
        });
        angular.forEach(clientData, function(value, key){ //nie chcemy tracić dowiązania
          $scope.shared.clientData[key] = value;
        });
      };

      $scope.loadClientsFromRecord = function()
      {
        $scope.shared.clientData = clientDataHelper.prepareNewPerson(true);
        var owners = vehiclePurchaseHelper.getPersonsByRole($scope.roles.BUYER_OWNER, 0, $scope.verificationData);
        angular.forEach(clientDataHelper.prepareApplicationPerson(owners[0]), function(value, key){
          if((key !== 'addresses' && key !== 'contacts') || operationType !== $scope.Constants.VEHICLE_PURCHASE)
          {
            $scope.shared.clientData[key] = value;
          }
        });

        //przygotowujemy do prezentacji dane obcokrajowca
        if($scope.shared.clientData.citizenshipCode !== 'PL' && $scope.shared.clientData.documents && $scope.shared.clientData.documents[0])
        {
          $scope.shared.clientAdditionalData.documentTypeDesc = $scope.identityDocumentTypes[$scope.shared.clientData.documents[0].code].name;
          $scope.shared.clientAdditionalData.documentNumber = $scope.shared.clientData.documents[0].number;
        }
      };

      $scope.back = function() {
        if(operationType === VerificationDataModelConstants.VEHICLE_PURCHASE)
        {
          $state.go('main.vehiclePurchasePublic');
        }
        else
        {
          $state.go('main.vehicleSalePublic');
        }
      };

      /**
       * wrzucamy osobę na wiosek i dokładamy rolę
       * @param {object} personData [description]
       * @param {[type]} role       [description]
       */
      $scope.addPerson = function(personData, role, dontSavePerson, verificationData)
      {
        if(!personData.metaData.id)
        {
          personData.metaData.id = $scope.getNextPersonId();
        }
        //jeśli już jest osoba o takim id to wywalamy i zapisujemy aktualną
        if(!dontSavePerson)
        {
          vehiclePurchaseHelper.deletePersonById(personData.metaData.id, verificationData);
        }

        personData = angular.copy(personData);
        var clientType = 'person';
        if(angular.isDefined(personData.clientType))
        {
          clientType = personData.clientType;
          delete personData.clientType;
        }

        //sprawdzamy adres korespondencyjny
        var canSavePersonAddress = false;
        if(clientType === 'person' && operationType === $scope.Constants.VEHICLE_PURCHASE)
        {
          canSavePersonAddress = true;
        }
        if(personData.noSecondAddr || !canSavePersonAddress)
        {
          //czyścimy adres korespondencyjny
          clientDataHelper.clearSecondAddress(personData);
        }
        else
        {
          clientDataHelper.clearSecondAddressIfEmpty(personData);
        }
        if(angular.isDefined(personData.noSecondAddr))
        {
          delete personData.noSecondAddr;
        }

        if(clientType === 'person')
        {
          personData = new PersonModel().setData(personData).getData();
        }
        else
        {
          personData = new OrganizationModel().setData(personData).getData();
        }

        if(!dontSavePerson)
        {
          if(clientType === 'person')
          {
            if(angular.isUndefined(verificationData.persons))
            {
              verificationData.persons = [];
            }
            verificationData.persons.push(personData);
          }
          else
          {
            if(angular.isUndefined(verificationData.organizations))
            {
              verificationData.organizations = [];
            }
            verificationData.organizations.push(personData);
          }

          if(angular.isUndefined(verificationData.partnerRoles))
          {
            verificationData.partnerRoles = {};
          }

          verificationData.vehicleOwnerChangeRecords[0].partnerRoles[personData.metaData.id] = role;
        }

        return personData;
      };

      /**
       * przygotowujemy dane do zapisu
       * @return {object}
       */
      $scope.prepareData = function()
      {
        var data = angular.copy($scope.verificationData);

        if($scope.shared.selectedOwners === -1)
        {
          vehiclePurchaseHelper.clearPersons('seller', 0, data);
          $scope.addPerson($scope.shared.otherSellerData, $scope.roles.SELLER_OWNER, false, data);
        }

        vehiclePurchaseHelper.clearPersons('buyer', 0, data);
        $scope.addPerson($scope.shared.clientData, $scope.roles.BUYER_OWNER, false, data);
        angular.forEach($scope.shared.coowners, function(coowner){
          $scope.addPerson(coowner, $scope.roles.BUYER_COOWNER, false, data);
        });

        ihestiaModelHelper.minifyRestObject(data, false, true);

        data.files = [];
        angular.forEach($scope.fileData.fileModels, function(fileObject){
          data.files.push({
            name: fileObject.name,
            size: fileObject.size,
            fileServerId: fileObject.fileServerId,
            fileType: $scope.documentType.code
          });
        });

        angular.forEach($scope.fileData.fileWithdrawalModels, function(fileObject){
          data.files.push({
            name: fileObject.name,
            size: fileObject.size,
            fileServerId: fileObject.fileServerId,
            fileType: 'PolicyTermination'
          });
        });

        return data;
      };

      /**
       * klikniecię przycisku "dalej"
       * @return {[type]}        [description]
       */
      $scope.forward = function() {
        $scope.formSubmitted = true;
        // jeśli brak błędów, to odpytujemy usługę
        if ($scope.vehiclePurchasePublicForm.$valid) {
          $scope.otherData.saveErrors = [];
          if(!ihestiaAutoUploadOneListUploadHelper.checkAllFilesSendWithoutErrors($scope.uploaderName) || ($scope.currentRecord && $scope.currentRecord.buyerWithdrawal && !ihestiaAutoUploadOneListUploadHelper.checkAllFilesSendWithoutErrors('policyOperationWithdrawal')))
          {
            $scope.otherData.saveErrors.push({
              text: 'Zgłoszenie nie zostało wysłane gdyż wybrano niepoprawny plik. Szczegóły błędu wyświetlają się po kliknięciu na nazwę pliku.'
            });
            $scope.$broadcast('scrollToError');
            return false;
          }
          else if($scope.fileModels.length === 0)
          {
            var operationTypeString;
            if(operationType === $scope.Constants.VEHICLE_PURCHASE)
            {
              operationTypeString = 'zakup';
            }
            else
            {
              operationTypeString = 'sprzedaż';
            }

            //walidacja na załączone pliki
            $scope.otherData.saveErrors.push({
              text: 'Załącz dokument potwierdzający ' + operationTypeString
            });
            $scope.$broadcast('scrollToError');
            return false;
          }
          else if($scope.currentRecord && $scope.currentRecord.buyerWithdrawal && $scope.fileData.fileWithdrawalModels.length === 0)
          {
            $scope.otherData.saveErrors.push({
              text: 'Załącz dokument potwierdzający wypowiedzenie'
            });
            $scope.$broadcast('scrollToError');
            return false;
          }

          var data = $scope.prepareData();
          $scope.$emit('showBlockUi');

          vehiclePurchasePublicSvc.post(null, data, null, function(response){
            vehiclePurchasePublicHelper.summary = response.data;
            if(operationType === VerificationDataModelConstants.VEHICLE_PURCHASE)
            {
              $state.go('main.vehiclePurchasePublicSummary');
            }
            else
            {
              $state.go('main.vehicleSalePublicSummary');
            }
          }, function(reject){
            if(reject.data && reject.data.messages)
            {
              $scope.otherData.saveErrors = reject.data.messages;
              $scope.parseErrors();
              $scope.$broadcast('scrollToError');
            }
            else if(reject.data && angular.isArray(reject.data))
            {
              $scope.otherData.saveErrors = reject.data;
              $scope.parseErrors();
              $scope.$broadcast('scrollToError');
            }
            $scope.$emit('hideBlockUi');
          },
          {
            allowedStatuses: [409, 404]
          });
        }
        else
        {
          $scope.$broadcast('scrollToError');
        }
        return true;
      };

      /**
       * Ustawiamy dane selecta "poprzedni właściciel"
       * Może być kilku, lub magiczna opcja "inny"
       */
      $scope.setOwnerSelect = function() {
        var ownersData = vehiclePurchaseHelper.getOwnersData($scope.verificationData);

        $scope.shared.ownersSelectList = [{
          name: ownersData.ownerName,
          id: ownersData.ownersIds
        }];

        var sellerName = 'sprzedający';
        $scope.shared.ownersSelectList.push({
          name: 'Inny ' + sellerName,
          id: -1
        });

        $scope.shared.selectedOwners = $scope.shared.ownersSelectList[0].id;
      };

      /**
       * zwraca id dla nowej osoby
       */
      $scope.getNextPersonId = function()
      {
        var id = $scope.nextPersonId;
        $scope.nextPersonId = $scope.nextPersonId - 1;
        return id;
      };

      /**
       * dodanie współwłaściciela
       */
      $scope.addNextClient = function()
      {
        var personData = clientDataHelper.prepareNewPerson();
        personData.metaData.id = $scope.getNextPersonId();
        $scope.shared.coowners.push(personData);
      };

      /**
       * usunięcie osoby
       * @param  {[type]} personKey [description]
       */
      $scope.removePerson = function(personId)
      {
        var personToRemoveKey = null;
        angular.forEach($scope.shared.coowners, function(coowner, tabKey){
          if(coowner && coowner.metaData && coowner.metaData.id === personId)
          {
            personToRemoveKey = tabKey;
          }
        });
        if(personToRemoveKey !== null)
        {
          $scope.shared.coowners.splice(personToRemoveKey, 1);
        }
      };

      $scope.deleteFile = function(id){
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      $scope.initUploader = function() {
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          null, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileData.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
        $scope.fileModels = $scope.fileData.fileModels; // backward compatibility
      };

      $scope.showClauseDetailsInfo = function(clause, size) {
        var modalConfig = {
          title: clause.shortDescriptionPl,
          text: clause.descriptionPl,
          size: 'sm',
          isMd: true,
          okBtnName: 'Zamknij',
          cancelBtnName: '',
          keyboard: false
        };

        if(size)
        {
          modalConfig.size = size;
        }

        ihestiaCommonConfirmModalHelper.showConfirmModal(modalConfig);
      };

      $scope.parseErrors = function()
      {
        angular.forEach($scope.otherData.saveErrors, function(error){
          if(error.text.indexOf('{') !== -1)
          {
            error.type = 'link';
            error.preText = error.text.substr(0, error.text.indexOf('{'));
            error.linkText = error.text.substr(error.text.indexOf('{') + 1, error.text.indexOf('}') - error.text.indexOf('{') - 1);
            error.postText = error.text.substr(error.text.indexOf('}') + 1);
          }
        });
      };

      $scope.init();

    }
  ]);