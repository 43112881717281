angular.module('ihestiaWidgets.dashboard.euaChecker')
  .factory('euaCheckerSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var EuaCheckerSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks';
        this.businessArea = 'dashboard';
        this.port = '8282';

        this.pageStyle = false;

        this.grid.sortBy = 'dueDate';
        this.grid.sortDirection = 'desc';

      };
      return new EuaCheckerSvc();
    }
  ]);