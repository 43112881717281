angular.module('ihestiaWidgets.dashboard', [
  'ihestia-widgets-templates-app',
  'salesPath.outer',
  'ihestiaCommonDirectives',
  'ihestiaAg',
  'ihestiaCommonDirectives.upperCase'
])
  .run(['bodyInjectorHelper',
    function(bodyInjectorHelper) {
      bodyInjectorHelper.injectDirective('ihestiaDashboardModalsInjector', 'ihestia-dashboard-modals-injector');
    }
  ]);