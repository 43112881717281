angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardWorkerSubjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardSubjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardSubjectModelConstants) {

      var WorkerSubjectModel = function() {
        this.objectName = 'IhestiaDashboardWorkerSubjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          workerId: {
            type: 'string'
          },
          name: {
            type: 'string'
          },
          surname: {
            type: 'string'
          },
          type: {
            type: 'string'
          },
          saleProfileId: {
            type: 'string'
          },
          saleProfileNumber: {
            type: 'string'
          },
          representativeId: {
            type: 'string'
          },
          poolId: {
            type: 'int'
          },
          login: {
            type: 'string'
          }

        };

        this.id = null;
        this.version = null;
        this.workerId = null;
        this.name = null;
        this.surname = null;
        this.type = IhestiaDashboardSubjectModelConstants.TYPE_WORKER;
        this.saleProfileId = null;
        this.saleProfileNumber = null;
        this.representativeId = null;
        this.poolId = null;
        this.login = null;

      };


      WorkerSubjectModel.prototype = IHestiaAbstractModel.prototype;

      return WorkerSubjectModel;
    }]);
