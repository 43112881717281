angular.module('lsnBase.detectorUserActvie')
  .directive('detectorUserActive', ['detectorUserActiveHelper', '$timeout', '$document', '$window', 
    function(detectorUserActiveHelper, $timeout, $document, $window) {
    return {
      link: function() {

        $document.on('click', function() {
          $timeout(function() {
            detectorUserActiveHelper.touch();
          });

        });

        $document.on('keypress', function() {
          $timeout(function() {
            detectorUserActiveHelper.touch();
          });

        });

        $window.onfocus = function() {
          $timeout(function() {
            detectorUserActiveHelper.setActiveTab(true);
          });
        };

        $window.onblur = function() {
          $timeout(function() {
            detectorUserActiveHelper.setActiveTab(false);
          });
        };

      }
    };
  }]);