angular.module('ihestiaWidgets.policyTermination')
  .controller('policyTerminationStep2Ctrl', ['$scope', '$state', '$stateParams', 'policyTerminationHelper', 'resolvedFileAttributes', 'ihestiaAutoUploadOneListUploadHelper', 'policyOperationsSvc', 'iHestiaCommonModalHelper', 'ihestiaPlatformHelper', '$filter',
    function($scope, $state, $stateParams, policyTerminationHelper, resolvedFileAttributes, ihestiaAutoUploadOneListUploadHelper, policyOperationsSvc, iHestiaCommonModalHelper, ihestiaPlatformHelper, $filter) {

      $scope.dataReady = false;

      $scope.confirmModalSettings = {
        treatLabelAsI18nCode: true,
        title: ['Public.confirmation', {componentCode: 'Public'}],
        okBtnName: ['Public.submit', {componentCode: 'Public'}],
        cancelBtnName: ['Public.close', {componentCode: 'Public'}],
        size: 'sm'
      };

      //upload plików
      $scope.documentType = resolvedFileAttributes.data;

      $scope.fileModels = [];

      $scope.otherData = {
        selectedShortages: [],
        selectedEndOfInsurancePeriodNoticeCauses: [],
        otherPolicyStartDate: null,
        otherPolicyStartTime: null,
        otherPolicyEndDate: null,
        otherPolicyEndTime: null,
        saveErrors: [],
        saveErrorsCodes: {},
        onlyWarnings: false
      };

      $scope.fileNameLabel = '';
      $scope.fileErrorLabel = '';
      $scope.tooltipLabel = '';

      $scope.terminationStep2Form = {};
      $scope.formSubmitted = false;

      $scope.terminationType = null;

      $scope.showClientReason = false;
      $scope.endOfInsurancePeriodNoticeCauses = null;

      $scope.policyDescription = $stateParams.policyNumber;

      $scope.customPageTitle = null;
      $scope.customPageTitleLabel = null;

      $scope.prolongationDataRequired = true;
      if($scope.currentUser.character.type === 'Central')
      {
        $scope.prolongationDataRequired = false;
      }

      $scope.init = function(){

        $scope.dataReady = true;
        $scope.initUploader();
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.documentType);

        var parentStateName;
        if($scope.viewParams.policyExtension)
        {
          $scope.fileNameLabel = 'policyTermination.withdrawalUploadLabel';
          $scope.fileErrorLabel = 'policyTermination.attachWithdrawalFile';
          parentStateName = 'main.policyExtensionTerminationWithdrawal';
          $scope.tooltipLabel = 'extensionWithdrawalUploadTooltip';
        }
        else if($scope.terminationData.terminationType === $scope.TYPES.WITHDRAWAL)
        {
          $scope.fileNameLabel = 'policyTermination.withdrawalUploadLabel';
          $scope.fileErrorLabel = 'policyTermination.attachWithdrawalFile';
          parentStateName = 'main.policyTerminationWithdrawal';

          //treść tooltipa różna dla klienta i centrali/agenta
          if($scope.currentUser.character.type === 'Client') {
            $scope.tooltipLabel = 'withdrawalUploadTooltipClient';
          } else {
            $scope.tooltipLabel = 'withdrawalUploadTooltip';
          }
        }
        else if($scope.terminationData.terminationType === $scope.TYPES.NOTICE)
        {
          if($scope.terminationData.terminationCause === 'vehicleSale')
          {
            //sprzedaż i darowizna
            parentStateName = 'main.policyTerminationVehicleSale';
            $scope.fileNameLabel = 'saleUploadLabel';
            $scope.fileErrorLabel = 'attachSaleFile';
          }
          else if($scope.terminationData.terminationCause === 'vehicleDonation')
          {
            parentStateName = 'main.policyTerminationVehicleDonation';
            $scope.fileNameLabel = 'donationUploadLabel';
            $scope.fileErrorLabel = 'attachDonationFile';
          }
          else
          {
            $scope.fileNameLabel = 'policyTermination.noticeUploadLabel';
            $scope.fileErrorLabel = 'policyTermination.attachNoticeFile';
            parentStateName = 'main.policyTerminationNotice';
            if($scope.terminationData.terminationCause === 'endOfInsurancePeriodNotice') //Wypowiedzenie z końcem okresu ubezpieczenia
            {
              //treść tooltipa różna dla klienta i centrali/agenta
              if($scope.currentUser.character.type === 'Client') {
                $scope.tooltipLabel = 'endOfInsurancePeriodNoticeUploadTooltip';
              } else {
                $scope.tooltipLabel = 'endOfInsurancePeriodNoticeUploadTooltipAgentCentral';
              }

              //przyczyna wypowiedzenia przez klienta
              if($scope.currentUser.character.type !== 'Client' || ihestiaPlatformHelper.isJupiterOrDirect())
              {
                $scope.showClientReason = true;
                $scope.endOfInsurancePeriodNoticeCauses = policyTerminationHelper.endOfInsurancePeriodNoticeCauses;
                policyTerminationHelper.endOfInsurancePeriodNoticeCauses = null;
              }
            }
            else if($scope.terminationData.terminationCause === 'vehicleBuyerNotice') //Wypowiedzenie nabywcy
            {
              $scope.tooltipLabel = 'vehicleBuyerNoticeUploadTooltip';
            }
            else if($scope.terminationData.terminationCause === 'prolongationPolicyNotice') //Wypowiedzenie polisy prolongacyjnej
            {
              $scope.tooltipLabel = 'prolongationPolicyNoticeUploadTooltip';
            }
          }
        }
        else if($scope.terminationData.terminationType === $scope.TYPES.ESTATE)
        {
          $scope.fileNameLabel = 'saleUploadLabel';
          $scope.fileErrorLabel = 'attachSaleFile';
        }
        else if($scope.terminationData.terminationType === $scope.TYPES.DAD)
        {
          if($scope.terminationData.terminationCause === 'vehicleDismantling')
          {
            $scope.fileNameLabel = 'policyTermination.dismantlingUploadLabel';
            $scope.fileErrorLabel = 'policyTermination.attachDismantlingFile';
          }
          else
          {
            $scope.fileNameLabel = 'policyTermination.deregistrationUploadLabel';
            $scope.fileErrorLabel = 'policyTermination.attachDeregistrationFile';
          }
          parentStateName = 'main.policyTerminationDeregistrationAndDismantling';
        }

        if(parentStateName)
        {
          //ustawiamy parametry dla rodzica
          var stateData = {};
          stateData[parentStateName] = {
            stateParams: {policyNumber: $scope.terminationData.policyNumber}
          };
          $scope.$emit('setStatesData', stateData);
        }
      };

      $scope.back = function() {
        var params = {};
        if($scope.terminationData.terminationType !== $scope.TYPES.ESTATE)
        {
          //w nieruchomościach nie zawsze mamy na start numer polisy
          params.policyNumber = $scope.terminationData.policyNumber;
        }

        if($scope.viewParams.policyExtension)
        {
          $state.go('main.policyExtensionTerminationWithdrawal', params);
        }
        else
        {
          if($scope.terminationData.hasAwaitingTask)
          {
            params.applicationId = $scope.terminationData.metaData.id;
          }
          else
          {
            if($scope.terminationData.operationDate)
            {
              params.operationDate = $filter('date')($scope.terminationData.operationDate, 'yyyy-MM-dd');
            }
            if($scope.terminationData.terminationCause)
            {
              params.terminationCause = $filter('ucFirst')($scope.terminationData.terminationCause);
            }
          }

          if($scope.terminationData.terminationCause === 'vehicleSale')
          {
            $state.go('main.policyTerminationVehicleSale', params);
          }
          else if($scope.terminationData.terminationCause === 'vehicleDonation')
          {
            $state.go('main.policyTerminationVehicleDonation', params);
          }
          else if($scope.terminationData.terminationType === $scope.TYPES.DAD)
          {
            $state.go('main.policyTerminationDeregistrationAndDismantling', params);
          }
          else if($scope.terminationData.terminationType === $scope.TYPES.WITHDRAWAL)
          {
            $state.go('main.policyTerminationWithdrawal', params);
          }
          else if($scope.terminationData.terminationType === $scope.TYPES.NOTICE)
          {
            $state.go('main.policyTerminationNotice', params);
          }
          else if($scope.terminationData.terminationType === $scope.TYPES.ESTATE)
          {
            if($stateParams.policyNumber)
            {
              params.policyNumber = $stateParams.policyNumber;
            }
            $state.go('main.estateSale', params);
          }
        }

      };


      /**
       * klikniecię przycisku "dalej"
       * @return {[type]}        [description]
       */
      $scope.forward = function(force) {
        $scope.formSubmitted = true;
        // jeśli brak błędów, to odpytujemy usługę
        if ($scope.terminationStep2Form.$valid) {
          $scope.otherData.saveErrors = [];
          $scope.otherData.saveErrorsCodes = {};

          if(ihestiaAutoUploadOneListUploadHelper.isActive($scope.uploaderOptions.name) &&
            !ihestiaAutoUploadOneListUploadHelper.checkAllFilesSendWithoutErrors($scope.uploaderOptions.name))
          {
            $scope.otherData.saveErrors.push({
              label: 'sendingWithFileError'
            });
          }
          else if($scope.fileModels.length === 0 && $scope.currentUser.character.type !== 'Central' && $scope.terminationData.settlement && $scope.terminationData.settlement.isFileRequired)
          {
            //walidacja na załączone pliki
            $scope.otherData.saveErrors.push({
              label: $scope.fileErrorLabel
            });
          }
          else
          {
            var data = angular.copy($scope.terminationData);
            //zapisujemy pliki
            data.files = [];
            angular.forEach($scope.fileModels, function(fileObject){
              data.files.push({
                name: fileObject.name,
                size: fileObject.size,
                fileServerId: fileObject.fileServerId
              });
            });
            if($scope.currentUser.character.type === 'Central')
            {
              data.shortages = [];
              angular.forEach($scope.otherData.selectedShortages, function(selectedShortage){
                data.shortages.push(selectedShortage.code);
              });
              data.suspensions = [];
              angular.forEach($scope.otherData.selectedSuspensions, function(selectedSuspension){
                data.suspensions.push(selectedSuspension.code);
              });
            }
            if($scope.endOfInsurancePeriodNoticeCauses !== null)
            {
              data.endOfInsurancePeriodNoticeCauses = [];
              angular.forEach($scope.otherData.selectedEndOfInsurancePeriodNoticeCauses, function(selectedCause){
                data.endOfInsurancePeriodNoticeCauses.push(selectedCause.code);
              });
            }

            if(force)
            {
              data.canTerminateWithWarnings = true;
            }

            if(data.terminationCause === 'vehicleSale')
            {
              data.terminationCause = 'VehicleSale';
            }

            $scope.$emit('showBlockUi');

            data.isConfirmation = true;

            if($scope.viewParams.policyExtension)
            {
              var attributesToDrop = ['messages', 'shortages', 'suspensions', 'isConfirmation'];
              angular.forEach(attributesToDrop, function(key){
                if(angular.isDefined(data[key]))
                {
                  delete data[key];
                }
              });

              policyOperationsSvc.put('extensionTermination/1', data, null, $scope.saveSuccess, $scope.saveError, {allowedStatuses: [409, 404]});
            }
            else
            {
              policyOperationsSvc.put('termination/' + $scope.terminationData.metaData.id, data, null, $scope.saveSuccess, $scope.saveError, {allowedStatuses: [409, 404]});
            }
          }
        }
      };

      $scope.saveSuccess = function(response){
        policyTerminationHelper.summary = response.data;
        policyTerminationHelper.policyNumber = $scope.terminationData.policyNumber;

        if($scope.customPageTitleLabel)
        {
          policyTerminationHelper.titleLabel = $scope.customPageTitleLabel;
        }
        else
        {
          policyTerminationHelper.titleLabel = $state.$current.data.pageTitleLabel;
        }
        if($scope.customPageTitle)
        {
          policyTerminationHelper.title = $scope.customPageTitle;
        }
        else
        {
          policyTerminationHelper.title = $state.$current.data.pageTitle;
        }

        $state.go('main.policyTerminationSummary');
      };

      $scope.parseErrors = function()
      {
        $scope.otherData.saveErrorsCodes = {};
        angular.forEach($scope.otherData.saveErrors, function(error){
          if(error.code)
          {
            $scope.otherData.saveErrorsCodes[error.code] = true;
          }
        });
      };

      $scope.saveError = function(rej){
        if(rej.status === 409 || rej.status === 404)
        {
          if(rej.data && rej.data.messages)
          {
            $scope.otherData.saveErrors = rej.data.messages;
            $scope.parseErrors();
          }
          else if(rej.data && angular.isArray(rej.data))
          {
            $scope.otherData.saveErrors = rej.data;
            $scope.parseErrors();
          }
          if($scope.currentUser.character.type === 'Central' && $scope.onlyWarnings())
          {
            $scope.showConfirmModal();
          }
        }
        $scope.$emit('hideBlockUi');
      };

      $scope.parseErrors = function()
      {
        $scope.otherData.saveErrorsCodes = {};
        angular.forEach($scope.otherData.saveErrors, function(error){
          if(error.code)
          {
            $scope.otherData.saveErrorsCodes[error.code] = true;
          }
        });
      };

      $scope.showConfirmModal = function()
      {
        iHestiaCommonModalHelper.showModal('policyTerminationConfirmModal');
      };

      $scope.$on('iHestiaCommonModalOkBtnClicked.policyTerminationConfirmModal', function() {
        $scope.forward(true);
      });

      $scope.onlyWarnings = function()
      {
        var onlyWarnings;
        if($scope.otherData.saveErrors.length > 0)
        {
          onlyWarnings = true;
        }
        else
        {
          onlyWarnings = false; //nie ma nic
        }
        angular.forEach($scope.otherData.saveErrors, function(error){
          if(error.type !== 'WARN')
          {
            onlyWarnings = false;
          }
        });
        $scope.otherData.onlyWarnings = onlyWarnings;
        return onlyWarnings;
      };

      $scope.initUploader = function() {
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          $scope.terminationData.files, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
      };

      $scope.deleteFile = function(id){
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      /**
       * po zmianie daty albo czasu okresu ochrony innego TU osutawiamy dateTime
       */
      $scope.refreshStartDateTime = function()
      {
        if(angular.isUndefined($scope.terminationData.otherPolicyDetails))
        {
          $scope.terminationData.otherPolicyDetails = {};
        }
        if($scope.otherData.otherPolicyStartDate && $scope.otherData.otherPolicyStartTime)
        {
          $scope.terminationData.otherPolicyDetails.startDate = $scope.otherData.otherPolicyStartDate + 'T' + $scope.otherData.otherPolicyStartTime;
        }
        else
        {
          $scope.terminationData.otherPolicyDetails.startDate = null;
        }
      };

      /**
       * po zmianie daty albo czasu okresu ochrony innego TU osutawiamy dateTime
       */
      $scope.refreshEndDateTime = function()
      {
        if(angular.isUndefined($scope.terminationData.otherPolicyDetails))
        {
          $scope.terminationData.otherPolicyDetails = {};
        }
        if($scope.otherData.otherPolicyEndDate && $scope.otherData.otherPolicyEndTime)
        {
          $scope.terminationData.otherPolicyDetails.endDate = $scope.otherData.otherPolicyEndDate + 'T' + $scope.otherData.otherPolicyEndTime;
        }
        else
        {
          $scope.terminationData.otherPolicyDetails.endDate = null;
        }
      };

      if($scope.terminationData)
      {
        //jak nie ma danych to znaczy, że abstract na wyrzuci
        $scope.init();
      }


    }
  ]);