angular.module('ihestiaWidgets.commonOperations')
  .provider('findClientModalConfig', [function() {

    var findClientConfig = this;

    /**
     * Domyślne etykiety wyszukiwarki
     * @type {Object}
     */
    this.labels = {
      formModal: {
        title: ['actions.searchForClient', {
          componentCode: 'Public'
        }],
        okBtnName: ['Public.search', {
          componentCode: 'Public'
        }],
        cancelBtnName: ['Public.cancel', {
          componentCode: 'Public'
        }]
      },
      listModal: {
        title: ['Public.searchResults', {
          componentCode: 'Public'
        }],
        okBtnName: ['Public.close', {
          componentCode: 'Public'
        }],
        cancelBtnName: ['Public.back', {
          componentCode: 'Public'
        }]
      },
      policiesListModal: {
        title: ['policies', {
          componentCode: 'Public'
        }],
        okBtnName: ['Public.close', {
          componentCode: 'Public'
        }],
        cancelBtnName: ['Public.back', {
          componentCode: 'Public'
        }] 
      }
    };

    this.$get = [function() {
      return findClientConfig;
    }];
  }]);