angular.module('salesPath2')
  .service('dcProductHelper', ['mainDataContainer', 'CONSTANTS', 'CONFIG', 'resourceHelper',
    function(mainDataContainer, CONSTANTS, CONFIG, resourceHelper) {
      var DcProductHelper = function() {
        var self = this;

        /**
         * zwraca liczbę osób ubezpieczonych w NNW
         * @return {Number}
         */
        this.getNnwPersonsCount = function() {
          var count = 0;
          angular.forEach(mainDataContainer.persons, function(person) {
            if (person.getAdditionalData(CONSTANTS.PRODUCT_NNW) !== null) {
              count += 1;
            }
          });
          if (mainDataContainer.nnwIncognito !== null) {
            count += 1;
          }
          return count;
        };

        /**
         * zwraca liczbę osób ubezpieczonych w OCZP
         * @return {Number}
         */
        this.getOczpPersonsCount = function() {
          var count = 0;
          angular.forEach(mainDataContainer.persons, function(person) {
            if (person.getAdditionalData(CONSTANTS.PRODUCT_OCZP) !== null) {
              count += 1;
            }
          });
          return count;
        };

        /**
         * zwraca liczbę osób ubezpieczonych w produkcie osobowym (NNW/OCZP)
         * @param  {String} productCode
         * @return {Number}
         */
        this.getProductPeronsCount = function(productCode) {
          if (productCode === CONSTANTS.PRODUCT_OCZP) {
            return self.getOczpPersonsCount();
          } else if (productCode === CONSTANTS.PRODUCT_NNW) {
            return self.getNnwPersonsCount();
          } else {
            return 0;
          }
        };

        /**
         * zwraca clientId 1 osoby ktora posiada ubezpieczenie w produkcie osobowym (NNW/OCZP)
         * @param  {String} productCode
         * @return {Number}
         */
        this.getFirstPersonOnProduct = function(productCode){
          if (productCode === CONSTANTS.PRODUCT_OCZP) {
            return self.getFirstPersonOnOczp();
          } else if (productCode === CONSTANTS.PRODUCT_NNW) {
            return self.getFirstPersonOnNnw();
          } else {
            return null;
          }
        };

        /**
         * zwraca pierwszą osobe ubezpieczona w OCZP
         * @return {Number}
         */
        this.getFirstPersonOnOczp = function() {
          var clientId = null;
          angular.forEach(mainDataContainer.persons, function(person) {
            if (clientId === null && person.getAdditionalData(CONSTANTS.PRODUCT_OCZP) !== null) {
              clientId = person.metaData.clientId;
            }
          });
          return clientId;
        };

        /**
         * zwraca pierwszą osobe ubezpieczona w NNW
         * @return {Number}
         */
        this.getFirstPersonOnNnw = function() {
          var clientId = null;
          angular.forEach(mainDataContainer.persons, function(person) {
            if (clientId === null && person.getAdditionalData(CONSTANTS.PRODUCT_NNW) !== null) {
              clientId = person.metaData.clientId;
            }
          });

          if(mainDataContainer.nnwIncognito !== null){
            clientId = CONSTANTS.NNW_INCOGNITO_PERSON_ID;
          }
          return clientId;
        };

        /**
         * Czy NNW jest krótkoterminowe
         * @return {Boolean}
         */
        this.isShortTermNnw = function() {
          var dates = mainDataContainer.nnwProtectionDates;
          if (CONFIG.BEHAVIOR.propertyOneProtectionPeriod) {
            dates = mainDataContainer.propertyProtectionDates;
          }
          if (dates.end === null || dates.start === null) {
            return false;
          }
          var endDate = new XDate(dates.end).addDays(1); //do końca dnia
          var startDate = new XDate(dates.start);
          return startDate.diffYears(endDate) < 1;
        };

        /**
         * zwraca domyślne dane dla mainDataContainer.nnwIncognito
         * @return {Object} jak ponizej
         */
        this.getDefaultNnwIncognitoData = function() {
          var sums = {},
            variantSelection = {},
            prodDef = resourceHelper.getProdDef(CONSTANTS.PRODUCT_NNW);
          angular.forEach(prodDef.VARIANTLIST, function(variant) {
            sums[variant.CODE] = variant.SUMINSUREDLIST ? variant.SUMINSUREDLIST[0].VALUE : 0;
            variantSelection[variant.CODE] = false;
          });
          var data = {
            su: sums,
            insuredNo: 0,
            variants: variantSelection
          };
          return data;
        };
      };

      return new DcProductHelper();
    }
  ]);