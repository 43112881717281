angular.module('ihestiaCommonDirectives') //eslint-disable-line angular/controller-name
  .directive('ihestiaUploadForm', function() {
    return {
      restrict: 'E',
      templateUrl: function(elem, attrs) {
        return attrs.uploadTemplate || 'ihestia-commondirectives-template-app/directives/uploader/tpl/ihestiaUploadForm.tpl.html';
      },
      scope: {
        allowed: '@',
        url: '@',
        autoUpload: '@',
        sizeLimit: '@',
        ngModel: '=',
        name: '@',
        attachmentType: '='
      },
      controller: ['$scope', '$element', '$attrs', 'fileUpload', 'ihestiaConfigHelper', '$parse', '$filter', '$timeout', 'compressorJsConfigHelper',
       function($scope, $element, $attrs, fileUpload, ihestiaConfigHelper, $parse, $filter, $timeout, compressorJsConfigHelper) {
        $scope.fileDataId = 0;
        $scope.externalUploadFileId = 0;

        $scope.$on('fileuploadprocessdone', function(e, data) {
          var files = data.files;
          for (var k = 0; k < files.length; k++) {
            files[k].externalUploadFileId = $scope.externalUploadFileId;
            if(angular.isObject($scope.fileExtraData)){
              files[k].fileExtraData = $scope.fileExtraData;
            }
            $scope.externalUploadFileId ++;
          }

          if ($scope.options.autoUpload) {
            for (var i = 0; i < files.length; i++) {
              var file = files[i];

              data.url = $scope.options.url;
              if (typeof $scope.options.fileAttrForUrl !== 'undefined') {
                data.url += file[$scope.options.fileAttrForUrl];
              }
            }
          }

          $timeout(function() {
            data.scope.name = $scope.name;

            $scope.$emit('ihestiaOnFileUploadAdd', data);
          }, 0);
        });
        $scope.$on('fileuploadfail', function(e, data) {

          $timeout(function() {
            data.scope.name = $scope.name;
            $scope.$emit('ihestiaOnFileUploadFail', data);
          }, 100);
        });


        $scope.$on('fileuploadprocessfail', function(e, data) {
          var file = data.files[0];
          var msg = file.error;

          if($scope.options.treatMesageAsI18nCode){
            if(angular.isString(msg)){
              msg = $filter('translate')(msg);
            }else if(angular.isArray(msg)){
              msg = $filter('translate')(msg[0], msg[1]);
            }
          }
          msg = msg.replace('FILENAME', file.name);
          msg = msg.replace('MAXSIZE', $filter('formatFileSize')(data.maxFileSize));
          msg = msg.replace('SIZE', $filter('formatFileSize')(file.size));

          $timeout(function() {
            data.scope.name = $scope.name;
            $scope.$emit('ihestiaOnAddingFileFail', data);
          }, 100);
          if (typeof $scope.options.showMessage === 'function')
          {
            $scope.options.showMessage(msg);
          }

          data.scope.clear(file);
        });

        $scope.$on('fileuploaddone', function(e, data) {
          $scope.onFileUploadDone(e, data);
        });

        $scope.$on('fileuploadchunkdone', function(e, data) {
          $scope.onFileUploadDone(e, data);
        });

        $scope.onFileUploadDone = function(e, data){
         //dla srodkowego chunk nigdy nie jest spełnione
          if (data.loaded >= data.total) {
            if (angular.isString(data.result)) {
              var result = data.result;
              data.result = {
                result: result
              };
            }
            $scope.fileDataId += 1;
            data.result._id = $scope.fileDataId;
            data.scope.name = $scope.name;
            fileUpload.addFieldData($scope.name, data.result);
            $timeout(function() {
              $scope.$emit('ihestiaOnFileUploadDone', data);
            }, 100);
          }
        };

        $scope.options = $parse($attrs.uoptions)($scope.$parent);

        $scope.fileExtraData = $parse($attrs.fileExtraData)($scope.$parent);

        if ($attrs.addFileText) {
          $scope.addFileText = $attrs.addFileText;
        }

        if (angular.isUndefined($scope.options.multiple)) {
          $scope.options.multiple = true;
        }

        if ($scope.options.name) {
          $scope.name = $scope.options.name;
        }

        $scope.options.dropZone = $element;
        $scope.loadingFiles = false;

        $scope.options.compressorjs = $parse($attrs.uoptions)($scope.$parent).compressorjs === false ? false : compressorJsConfigHelper.compressorjs;
        $scope.options.compressorjsConfig = $scope.options.compressorjsConfig = $attrs.uoptions.compressorjsConfig !== undefined ? Object.assign(
          compressorJsConfigHelper.compressorjsConfig,
          $attrs.uoptions.compressorjsConfig
        ) : compressorJsConfigHelper.compressorjsConfig;

        fileUpload.registerField($scope.name);
      }]

    };
  }).controller('ihestiaUploadInsideGeneralController', ['$rootScope', '$scope', '$element', '$http', 'fileUpload', '$timeout',
  function($rootScope, $scope, $element, $http, fileUpload, $timeout) {
    $scope.$on('deleteFileInUploader', function(e, data) {
      if (data.scopeName === $scope.name) {
        //jesli fileObject nie istniej znaczy to ze fileData nie został jeszcze dodany
        if (typeof data.file.fileObject !== 'undefined') {
          fileUpload.removeFieldData($scope.name, data.file.fileObject._id);
        }

        $scope.clear(data.file);
      }

    });

    $scope.$on('addBinaryFilesToUploader', function(e, data){
      if (data.scopeName === $scope.name && angular.isArray(data.filesData)) {
        var files = [];
        angular.forEach(data.filesData, function(dataF){
          var file = new File([dataF.binary], dataF.name, {type: dataF.type, lastModified: new XDate()} );
          files.push(file);
        });

        $timeout(function() {
            $scope.add({name: $scope.name, files: files});
        }, 0);

      }
    });

    $scope.$watch('options.disabled', function(val){
      if(val){
        $element.fileupload('disable', val);
      }else{
        $element.fileupload('enable', val);
      }

    });

    $scope.$on('deleteAllFilesInScope', function(e, data) {
      if (data.scopeName === $scope.name) {
        angular.forEach($scope.queue, function(file) {

          if (typeof file.fileObject !== 'undefined') {
            fileUpload.removeFieldData($scope.name, file.fileObject._id);
          }

          $scope.clear(file);

        });
      }
    });
  }]).controller('ihestiaUploadFileController', ['$rootScope', '$scope', '$http', 'fileUpload', function($rootScope, $scope, $http, fileUpload) {

    $scope.onUploadedItemClick = function(file) {
      $scope.$emit('ihestiaOnUploadedItemClick', {
        file: file,
        scope: $scope
      });
    };

    $scope.onFileDelete = function(file) {
      $scope.$emit('ihestiaOnFileDelete', {
        file: file,
        scope: $scope
      });
    };

    var generateFileObject = function generateFileObjects(objects) {
      angular.forEach(objects, function(value, key) {
        if (typeof value !== 'undefined') {
          if (typeof $scope.queue[key] === 'undefined') {
            $scope.queue[key] = {};
          }
          $scope.queue[key].fileObject = value;
        }
      });
    };

    $scope.filequeue = fileUpload.fieldData[$scope.name];

    $scope.$watchCollection('filequeue', function(newval) {
      generateFileObject(newval);
    });
  }]);