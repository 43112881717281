angular.module('ihestiaIkomunikator.notificationBar')
	.directive('ihestiaIkomunikatorNotificationBar', ['$templateCache', 'ihestiaIkomunikatorPermissionHelper', 'ihestiaIkomunikatorUserTypeHelper', 'ihestiaIkomunikatorCommonHelper',
		function($templateCache, ihestiaIkomunikatorPermissionHelper, ihestiaIkomunikatorUserTypeHelper, ihestiaIkomunikatorCommonHelper) {//eslint-disable-line
			var tpl = $templateCache.get('ihestiaIkomunikatorTemplatesApp/views/common/notificationBar/notificationWrapper.tpl.html');
			return {
				scope: true,
				template: tpl,
				link: function($scope) {
          $scope._tpl = ihestiaIkomunikatorCommonHelper.defaultConfig.notificationBarTemplate;

					$scope.elementsAvailability.ikomunikator = $scope.elementsAvailability.ikomunikator && ihestiaIkomunikatorPermissionHelper.shouldShowAction() && (ihestiaIkomunikatorUserTypeHelper.isClientUserLogged() || ihestiaIkomunikatorUserTypeHelper.isAgentUserLogged());
				}
			};
		}
	]);