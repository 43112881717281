angular.module('salesPath.outer')
  /**
   * stałe dla ścieżek sprzedażowych
   * @return {Object}
   */
  .provider('CONSTANTS', [function() {

    var CONSTANTS = {};

    CONSTANTS.PAGE_MATRIX = 'matrix';
    CONSTANTS.PAGE_CLIENT = 'persons';
    CONSTANTS.PAGE_PRODUCT_FIRE = 'OGIEN';
    CONSTANTS.PAGE_PRODUCT_BURGLARY = 'KRA';
    CONSTANTS.PAGE_PRODUCT_OCZP = 'OCZP';
    CONSTANTS.PAGE_PRODUCT_NNW = 'NNW';
    CONSTANTS.PAGE_PRODUCT_COMMUNICATION = 'vehicleMatrix';
    CONSTANTS.PAGE_INFO = 'info';
    CONSTANTS.PAGE_SUMMARY = 'summary';
    CONSTANTS.PAGE_START_CLIENT_SEARCH = 'clientSearch';
    CONSTANTS.PAGE_START_CLIENT_EDIT = 'clientAdd';
    CONSTANTS.PAGE_INIT = 'init';
    CONSTANTS.PAGE_PRODUCT_COMPARE = 'productCompare';

    CONSTANTS.START_PAGES = [CONSTANTS.PAGE_START_CLIENT_SEARCH, CONSTANTS.PAGE_START_CLIENT_EDIT];

    CONSTANTS.POLICY_TYPE_PROPERTY = 'property';
    CONSTANTS.POLICY_TYPE_PROPERTY_CESSION = 'propertyCession';
    CONSTANTS.POLICY_TYPE_COMMUNICATION = 'communication';

    //lista elementów dodawanych do polisy
    CONSTANTS.POLICY_EXTRA_CESSION = 'CESSION';
    CONSTANTS.POLICY_EXTRA_PAYMENT = 'PAYMENT';
    CONSTANTS.POLICY_EXTRA_CLAUSES = 'CLAUSES';
    CONSTANTS.POLICY_EXTRA_ATTACH = 'ATTACH';
    CONSTANTS.POLICY_EXTRA_DISCOUNT = 'DISCOUNT';
    CONSTANTS.POLICY_EXTRA_PRINT = 'PRINT';

    CONSTANTS.CONSENT_PREMIUM = 'CONSENT_PREMIUM';
    CONSTANTS.CONSENT_EXCEED = 'CONSENT_EXCEED';

    CONSTANTS.POLICY_EXTRAS = [CONSTANTS.POLICY_EXTRA_CESSION, CONSTANTS.POLICY_EXTRA_PAYMENT, CONSTANTS.POLICY_EXTRA_CLAUSES, CONSTANTS.POLICY_EXTRA_ATTACH, CONSTANTS.POLICY_EXTRA_DISCOUNT];

    CONSTANTS.PRODUCT_TYPE_LOCALIZATION = 'localization';
    CONSTANTS.PRODUCT_TYPE_VEHICLE = 'vehicle';
    CONSTANTS.PRODUCT_TYPE_PERSON = 'person';
    CONSTANTS.PRODUCT_TYPE_PERSON_GROUP = 'personGroup'; //osoby i grupy - np. ubezpieczenia w pakietach tematycznych
    CONSTANTS.PRODUCT_TYPE_PACKAGE = 'packagetype'; //package jest zarezerwowany w js
    CONSTANTS.OBJECT_TYPE_ORGANIZATION = 'organization';
    CONSTANTS.OBJECT_TYPE_GROUP = 'group'; //obejmuje tylko grupy
    CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION = 'personOrganization'; //firmy i osoby

    CONSTANTS.FORM_OF_POLICY_PERSON = 'imienna';
    CONSTANTS.FORM_OF_POLICY_GROUP = 'grupowa';
    /**
     * @type Object mapowanie typów obiektów do nazw kontenerów (przetrzymujacych je) w dataManagerze
     */
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME = {};
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = 'localizations';
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_VEHICLE] = 'vehicles';
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON] = 'persons';
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP] = ['persons', 'groups'];
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_ORGANIZATION] = 'organizations';
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_GROUP] = 'groups';
    CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION] = ['persons', 'organizations'];

    /**
     * @type Object mapowanie typów obiektów do nazw kontenerów (przetrzymujacych je) na polisie
     */
    CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME = {};
    CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_LOCALIZATION] = 'estates';
    CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_VEHICLE] = 'vehicles';
    CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.PRODUCT_TYPE_PERSON] = 'persons';
    CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[CONSTANTS.OBJECT_TYPE_ORGANIZATION] = 'organizations';

    CONSTANTS.PRODUCT_OCZP = 'OCZP';
    CONSTANTS.PRODUCT_OGI = 'OGIEN';
    CONSTANTS.PRODUCT_KRA = 'KRA';
    CONSTANTS.PRODUCT_OGI_KRA = 'OGIEN_KRA'; //np. wspolny wariant ognia z kradzieza
    CONSTANTS.PRODUCT_NNW = 'NNW';
    CONSTANTS.PRODUCT_NNW_INCOGNITO = 'NNW_INCOGNITO';
    CONSTANTS.PRODUCT_ACKOM = 'ACKOM';
    CONSTANTS.PRODUCT_NNWKIP = 'NNWKIP';
    CONSTANTS.PRODUCT_OCKOM = 'OCKOM';
    CONSTANTS.PRODUCT_SPORT_ASSISTANCE = 'SPORT_ASSISTANCE';
    CONSTANTS.PRODUCT_REHABILITATION = 'REHABILITATION';
    CONSTANTS.PRODUCT_BAGGAGE = 'PROD_BAGGAGE';
    CONSTANTS.PRODUCT_MEDICAL_EXPENSES = 'MEDICAL_EXPENSES';

    CONSTANTS.RISK_ESTATE = 'estate';
    CONSTANTS.RISK_EQUIPMENT = 'equipment';
    CONSTANTS.RISK_PERSONAL_BELONGINGS = 'personalBelongings';
    CONSTANTS.RISK_FLOOD = 'flood';
    CONSTANTS.RISK_COLLECTIONS = 'collections';
    CONSTANTS.RISK_CURRENT_ASSETS = 'currentAssets';
    CONSTANTS.RISK_THIRD_PARTIES_PROPERTY = 'thirdPartiesProperty';
    CONSTANTS.RISK_CASH = 'cash';
    CONSTANTS.RISK_PROPERTY_ARCHITECTURE = 'propertyArchitecture';
    CONSTANTS.RISK_GARDEN = 'garden';
    CONSTANTS.RISK_GARAGE = 'garage';
    CONSTANTS.RISK_AC = 'ACKOM';
    CONSTANTS.RISK_ACKR = 'ACKOMKR';
    CONSTANTS.RISK_OC = 'OCKOM';
    CONSTANTS.RISK_NNWKIP = 'NNWKIP';
    CONSTANTS.RISK_WD = 'ADDEQ';
    CONSTANTS.RISK_OUTBUILDINGS = 'outBuildings';
    CONSTANTS.RISK_PHONE_LEGAL_ASSISTANT = 'phoneLegalAssistant';
    CONSTANTS.RISK_GARDEN_ARCHITECTURE_FENCE = 'gardenArchitectureFence';
    CONSTANTS.RISK_GARDEN_ARCHITECTURE = 'gardenArchitecture';
    CONSTANTS.RISK_FENCE = 'fence';
    CONSTANTS.RISK_MOVEABLE_PROPERTY = 'moveableProperty';
    CONSTANTS.RISK_PROPERTY_GLAZING = 'propertyGlazing';
    CONSTANTS.RISK_ELEMENTS = 'elements';
    CONSTANTS.RISK_SATELLITE = 'satelite';
    CONSTANTS.RISK_SPORT_ASSISTANCE = 'SPORT_ASSISTANCE';
    CONSTANTS.RISK_REHABILITATION = 'REHABILITATION';
    CONSTANTS.RISK_BAGGAGE = 'PROD_BAGGAGE';
    CONSTANTS.RISK_MEDICAL_EXPENSES = 'MEDICAL_EXPENSES';

    CONSTANTS.ADD_CARGO = 'CARGO';
    CONSTANTS.ADD_ASSDOM = 'ASSDOM';
    CONSTANTS.ADD_ASSPOJ = 'ASSPOJ';
    CONSTANTS.ADD_ASSMEDIC = 'ASSMEDIC';
    CONSTANTS.ADD_OCNAJ = 'OCNAJ';
    CONSTANTS.ADD_TRAVEL = 'TRAVEL';
    CONSTANTS.ADD_OUZ = 'OUZ';
    CONSTANTS.ADD_OCHPRAW = 'OCHPRAW';
    CONSTANTS.ADD_WD = 'EXTRA';
    CONSTANTS.ADD_GREENCARD = 'GREENCARD';
    CONSTANTS.ADD_SSU = 'SSU';
    CONSTANTS.ADD_BAGGAGE = 'BAGGAGE';
    CONSTANTS.ADD_GLAZING = 'GLAZING';
    CONSTANTS.ADD_HCA_TOWING_VEHICLE = 'HCA_TOWING_VEHICLE';
    CONSTANTS.ADD_HCA_THEFT = 'HCA_THEFT';
    CONSTANTS.ADD_HCA_REPLACEMENT_VEHICLE = 'HCA_REPLACEMENT_VEHICLE';
    CONSTANTS.ADD_OUZ_OC = 'ADD_OUZ_OC';
    CONSTANTS.ADD_COST_OF_CAUSE_SEARCHING = 'ADD_COST_OF_CAUSE_SEARCHING';
    CONSTANTS.ADD_TICKETS = 'TICKETS';
    CONSTANTS.ADD_SPORT_EQUIPMENT = 'SPORT_EQUIPMENT';
    CONSTANTS.ADD_TRAVEL_ACCIDENT = 'TRAVEL_ACCIDENT';

    CONSTANTS.VARIANT_I = 'I';
    CONSTANTS.VARIANT_II = 'II';
    CONSTANTS.VARIANT_III = 'III';
    CONSTANTS.VARIANT_IV = 'IV';

    CONSTANTS.VARIANTS_ORDER = [CONSTANTS.VARIANT_I, CONSTANTS.VARIANT_II, CONSTANTS.VARIANT_III, CONSTANTS.VARIANT_IV];

    CONSTANTS.SUBJECT_ANY = 'any'; //dowolna osoba. Używane np. przy filtrowaniu osob z FTSa
    CONSTANTS.SUBJECT_OWNER = 'owner';
    CONSTANTS.SUBJECT_COOWNER = 'coowner';
    CONSTANTS.SUBJECT_INSURED = 'insured';
    CONSTANTS.SUBJECT_COINSURED = 'coinsured';
    CONSTANTS.SUBJECT_LEASING = 'leasing';
    CONSTANTS.SUBJECT_BANK = 'bank';
    CONSTANTS.SUBJECT_INSURED_NNW = 'insuredNnw';
    CONSTANTS.SUBJECT_COINSURED_NNW = 'coinsuredNnw';

    CONSTANTS.ETX_CATEGORY_PASSENGER = 10;
    CONSTANTS.ETX_CATEGORY_OFFROAD = 20;

    CONSTANTS.ALL_OBJECTS = -1; //wszystkie lokalizacje/pojazdy w selectedLocalization/selectedVehicle
    CONSTANTS.NO_OBJECT = 0;
    CONSTANTS.SUM_NAME = '_sum';

    //tryby dzialania aplikacji
    CONSTANTS.APP_MODE_CALCULATION = 'calculation'; //tworzenia wniosku
    CONSTANTS.APP_MODE_OFFER = 'offer'; //oferta
    CONSTANTS.APP_MODE_POLICY = 'policy'; // podgląd polis(y)
    CONSTANTS.APP_MODE_RENEW_CHOICE = 'renew_choice'; // wybór polis do wznowienia

    CONSTANTS.LIQUIDATION_ASO = 'SERVICE';
    CONSTANTS.LIQUIDATION_PRICE = 'PRICE';
    CONSTANTS.LIQUIDATION_NET = 'NET';

    CONSTANTS.POLICY_TYPE_STANDARD = 'Standardowa';
    CONSTANTS.POLICY_TYPE_CLIENT = 'Klienta';
    CONSTANTS.POLICY_TYPE_CESSION = 'Cesyjna';

    CONSTANTS.PRINT_CODE_CALCULATION = 'KALKULACJA';
    CONSTANTS.PRINT_CODE_VIEW_CALCULATION = 'PODGLAD_KALKULACJI';
    CONSTANTS.PRINT_CODE_OFFER = 'OFERTA';
    CONSTANTS.PRINT_CODE_SUMMARY = 'PODSUMOWANIE';
    CONSTANTS.PRINT_CODE_RENOUNCEMENT = 'ODSTAPIENIE';
    CONSTANTS.PRINT_CODE_POLICY = 'POLISA';
    CONSTANTS.PRINT_GREEN_CARD = 'ZK';

    CONSTANTS.BRAK_DANYCH = 'BRAK DANYCH';
    CONSTANTS.BRAK = 'brak';

    CONSTANTS.NO_EXTENSION = 'NO_EXTENSION';

    CONSTANTS.OFFER_STATE_EXPIRED = 'EXPIRED';


    CONSTANTS.BUSINESS_PARTNER_TYPE_PERSON = 'P';
    CONSTANTS.BUSINESS_PARTNER_TYPE_ORGANIZATION = 'O';
    CONSTANTS.BUSINESS_OBJECT_TYPE_NIE = 'NIE';
    CONSTANTS.BUSINESS_OBJECT_TYPE_POJ = 'POJ';
    CONSTANTS.ORGANIZATION_TYPE_LEASING = 'leasing';
    CONSTANTS.ORGANIZATION_TYPE_BANK = 'bank';
    CONSTANTS.AMORTIZATION_TYPE = {
      PRICE: 1,
      NET: 2,
      SERVICE: 3
    };
    CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_BASIC = 'Podstawowy';
    CONSTANTS.MEDICAL_ASSISTANCE_VARIANT_EXTENDED = 'Rozszerzony';
    CONSTANTS.HCA_AC_ASSISTANCE_OPTION_MAP = {
      II: 1,
      III: 2,
      IV: 3
    };
    CONSTANTS.ESTATE_NAME_FLAT = 'Mieszkanie';
    CONSTANTS.ESTATE_NAME_HOUSE = 'Dom';
    CONSTANTS.LOCALIZATION_TYPE_FLAT = 'flat';
    CONSTANTS.LOCALIZATION_TYPE_HOUSE = 'house';

    CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_SCAN = 'Elektroniczny';
    CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_PAPER = 'Papierowy';
    CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_IOFFER = 'Zatwierdzona z iOferty';

    // @todo dodac reszte
    CONSTANTS.PRODUCT_TO_STATE = {};
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_ACKOM] = 'vehicleMatrix';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_NNWKIP] = 'vehicleMatrix';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OCKOM] = 'vehicleMatrix';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OGI] = 'fire';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_KRA] = 'buglary';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OCZP] = 'oczp';
    CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_NNW] = 'nnw';

    CONSTANTS.OCZP_JOB_DOCTOR = '1';

    CONSTANTS.COMMUNICATION_PRODUCTS = [
      CONSTANTS.PRODUCT_ACKOM,
      CONSTANTS.PRODUCT_NNWKIP,
      CONSTANTS.PRODUCT_OCKOM
    ];
    CONSTANTS.LOCALIZATION_PRODUCTS = [
      CONSTANTS.PRODUCT_OGI,
      CONSTANTS.PRODUCT_KRA
    ];

    CONSTANTS.PERSON_CONTEXT_CLIENT = 'client';
    CONSTANTS.PERSON_CONTEXT_COOWNER = 'coowner';

    //errors
    CONSTANTS.ERROR_TYPE = {
      error: 'error',
      warning: 'warning',
      warn: 'warn',
      notice: 'notice',
      bosUnsupported: 'bos'
    };

    /**
     * mapowanie kodów ryzyk z urla do kodów produktów w aplikacji
     * @type {Object}
     */
    CONSTANTS.URL_RISK_TO_PRODUCT = {
      AC: CONSTANTS.PRODUCT_ACKOM,
      OC: CONSTANTS.PRODUCT_OCKOM,
      NNWKIP: CONSTANTS.PRODUCT_NNWKIP,
      NNW: CONSTANTS.PRODUCT_NNW,
      OCZP: CONSTANTS.PRODUCT_OCZP,
      OGIEN: CONSTANTS.PRODUCT_OGI,
      KRADZIEZ: CONSTANTS.PRODUCT_KRA,
      REHABILITATION: CONSTANTS.PRODUCT_REHABILITATION
    };

    /**
     * mapowanie kodów dodatków z urla do kodów dodatków w aplikacji
     * @type {Object}
     */
    CONSTANTS.URL_ADD_TO_ADD = {
      CAR_ASSISTANCE_AWARIA: {code: CONSTANTS.ADD_ASSPOJ, variant: CONSTANTS.VARIANT_II},
      CAR_ASSISTANCE_PREMIUM: {code: CONSTANTS.ADD_ASSPOJ, variant: CONSTANTS.VARIANT_III},
      CAR_ASSISTANCE_PRESTIGE: {code: CONSTANTS.ADD_ASSPOJ, variant: CONSTANTS.VARIANT_IV},
      OUZ_OC: {code: CONSTANTS.ADD_OUZ, variant: CONSTANTS.VARIANT_I},
      OUZ_AC: {code: CONSTANTS.ADD_OUZ, variant: CONSTANTS.VARIANT_II},
      SOS_ASSISTANCE: {code: CONSTANTS.ADD_ASSDOM, variant: CONSTANTS.VARIANT_II},
      HOME_ASSISTANCE: {code: CONSTANTS.ADD_ASSDOM, variant: CONSTANTS.VARIANT_I}
    };
    /**
     * mapowanie kodów wariantów z urla do wariantów w aplikacji
     * @type {Object}
     */
    CONSTANTS.URL_VARIANT_TO_VARIANT = {
      1: CONSTANTS.VARIANT_I,
      2: CONSTANTS.VARIANT_II,
      3: CONSTANTS.VARIANT_III,
      4: CONSTANTS.VARIANT_IV
    };

    /**
     * Zwracamy stałe
     * @return {object} [description]
     */
    this.getConstants = function() {
      return angular.copy(CONSTANTS);
    };

    /**
     * get providera
     * @return {object} [description]
     */
    this.$get = function() {
      return CONSTANTS;
    };
  }]);