angular.module('ihestiaRestServicesBase')
  .service('ihestiaDictionaryHelper', ['$injector', 'ihestiaCommonErrorHandler', 'ihestiaRestAbstractDictionariesSvc', 'labelServiceHelper', 'labelActionHelper', '$filter', '$q',
    function($injector, ihestiaCommonErrorHandler, ihestiaRestAbstractDictionariesSvc, labelServiceHelper, labelActionHelper, $filter, $q) {
      var DictionaryHelper = function() {

        var self = this;

        /**
         * Cache słowników
         * @type {Object}
         */
        var dictionaries = {};

        /**
         * mapa nazw słownika na nazwę serwisu rest
         * @type {Object}
         */
        var dictToSvcMap = {
          industries: 'ihestiaRestDictionaryIndustriesSvc',
          countries: 'ihestiaRestDictionaryCountriesSvc',
          legalForms: 'ihestiaRestDictionaryLegalSvc',
          owu: 'insuranceConditionsSvc',
          policyStatus: 'ihestiaRestDictionaryPolicyStatusSvc',
          applicationStatus: 'ihestiaRestDictionaryApplicationStatusSvc',
          applicationType: 'ihestiaRestDictionaryApplicationTypeSvc',
          policySegment: 'ihestiaRestDictionaryPolicySegmentSvc',
          applicationProduct: 'ihestiaRestDictionaryApplicationProductSvc',
          policyBrand: 'ihestiaRestDictionaryBrandsSvc',
          nationalities: 'ihestiaRestDictionaryNationalitiesSvc',
          identityDocumentTypes: 'ihestiaRestDictionaryIdentityDocumentTypesSvc',
          vehicleRegistrationTypes: 'ihestiaRestVehicleDictionaryRegistrationTypesSvc',
          vehicleBodyTypes: 'ihestiaRestVehicleDictionaryBodyTypesSvc'
        };

        var defaultParams = {
          requestType: 'get', //get/searches
          callback: null,
          itemCode: null,     //podajemy jeśli chcemy wyciągnąć tylko jeden element o podanym code
          returnAssoc: false, //wynik zwraca jako obiekt code: value
          resource: null, //domyślnie dictCode
          businessArea: null, //domyślnie dictionary
          codeAttribute: 'code'
        };

        /**
         * Przelotka na getDict, która zwraca zawsze promise
         * @param  {string} dictCode kod słownika
         * @params {object}
         * @return {Promise}          [description]
         */
        this.getDictWithPromise = function(dictCode, params) {
          return $q(function(resolve, reject){
            params = params ? params : {};
            params.callback = resolve;
            var dictResponse = self.getDict(dictCode, params);
            if(angular.isFunction(dictResponse.then)){
              dictResponse.then(lsnNg.noop, reject);
            }
          });
        };

        /**
         * Usługa do pobierania słowników
         * @param  {string} dictCode kod słownika
         * @params {object}
         * @return {boolean|$promise}          [description]
         */
        this.getDict = function(dictCode, params) {//eslint-disable-line consistent-return
          params = angular.extend({}, defaultParams, params);
          // wyciąganie konkretnego elementu ze słownika lub przekazywanie całego
          var parseCallback = function(dict){
            if(angular.isString(params.itemCode)) {
              var item = null;
              angular.forEach(dict, function(dictItem){
                if(dictItem[params.codeAttribute] === params.itemCode) {
                  item = dictItem;
                }
              });
              if(angular.isFunction(params.callback))
              {
                params.callback(item);
              }
            } else {
              if(angular.isFunction(params.callback))
              {
                if(params.returnAssoc)
                {
                  var dictObject = {};
                  angular.forEach(dict, function(dictItem){
                    dictObject[dictItem[params.codeAttribute]] = dictItem.name;
                  });
                  params.callback(dictObject);
                }
                else
                {
                  params.callback(dict);
                }
              }
            }
          };
          if(angular.isObject(dictionaries[dictCode])) {
            // mamy słownik w cache
            parseCallback(dictionaries[dictCode]);
            return true;
          } else {
            // nie mamy w cache

            var standardNameDictionaryName = 'ihestiaRestDictionary' + dictCode.ucFirst() + 'Svc';
            var foundInInjector = $injector.has(standardNameDictionaryName);

            // czy w ogóle mamy taki słownik
            var service;
            if(typeof dictToSvcMap[dictCode] !== 'undefined') {
              //zdefiniowana lista
              service = $injector.get(dictToSvcMap[dictCode]);
            }
            else if(foundInInjector)
            {
              //standardowa nazwa
              service = $injector.get(standardNameDictionaryName);
            }
            else
            {
              //tworzymy sobie obiekt serwisu
              var RestDisctionaryStandardSvc = function() {
                ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

                if(params.resource !== null)
                {
                  this.resource = params.resource;
                }
                else
                {
                  this.resource = dictCode;
                }
                if(params.businessArea !== null)
                {
                  this.businessArea = params.businessArea;
                }
              };
              service = new RestDisctionaryStandardSvc();
            }

            if(params.requestType === 'searches')
            {
              return service.find('', function(res){
                dictionaries[dictCode] = res.data.items;
                /** TYMCZASOWE TŁUMACZENIA START */
                self.regenerateUglyLangData();
                /** TYMCZASOWE TŁUMACZENIA END */
                parseCallback(dictionaries[dictCode]);
              });
            }
            else if(params.requestType === 'get')
            {
              return service.get(null, {}, null, function(res){
                dictionaries[dictCode] = res.data;
                /** TYMCZASOWE TŁUMACZENIA START */
                self.regenerateUglyLangData();
                /** TYMCZASOWE TŁUMACZENIA END */
                parseCallback(dictionaries[dictCode]);
              });
            }
          }
        };

        /**
         * TYMCZASOWE TŁUMACZENIA START
         */
        this.regenerateUglyLangData = function() {
          if(labelServiceHelper.isServiceTranslationActive() && angular.isArray(dictionaries.clauses)) {
            angular.forEach(dictionaries.clauses, function(clause){
              clause.descriptionPl = $filter('translate')('tmp.clause.description' + clause.code, {componentCode: 'Temp'});
              clause.shortDescriptionPl = $filter('translate')('tmp.clause.shortDescription' + clause.code, {componentCode: 'Temp'});
            });
          }
        };
        labelActionHelper.registerAction('ihestiaDashboardTaskHelper', self.regenerateUglyLangData);
        /**
         * TYMCZASOWE TŁUMACZENIA END
         */
      };

      return new DictionaryHelper();
    }
  ]);