angular.module('ihestiaRestServicesBase')
  .service('salesProfileSettingsSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {
    var SalesProfileSettingsSvc = function() {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'salesProfile';
      this.businessArea = 'settings';

    };
    return new SalesProfileSettingsSvc();
  }]);