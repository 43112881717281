angular.module('salesPath2')
  .controller('fireBurglaryCtrl', ['$scope', 'RESOURCES', '$state', 'CONSTANTS', 'PAGE_CONFIG', '$timeout', 'localizationHelper', 'applicationHelper', 'localizationModalHelper', 'fireBurglaryHelper', 'sp2SelectionHelper', 'actionHelper', 'triggerWhenInactive', 'resourceHelper', 'dataContainerHelper',
    function($scope, RESOURCES, $state, CONSTANTS, PAGE_CONFIG, $timeout, localizationHelper, applicationHelper, localizationModalHelper, fireBurglaryHelper, selectionHelper, actionHelper, triggerWhenInactive, resourceHelper, dataContainerHelper) { // eslint-disable-line angular/di

      $scope.numericSettings = {
        decimals: 0,
        groupSeparator: ' ',
        min: 0,
        max: 999999999,
        allowLeadingZeros: false,
        parseToString: false,
        formatting: true
      };
      $scope.tempObj = {}; //na potrzeby triggerWhenInactive
      $scope.tplData = fireBurglaryHelper.tplData;

      $scope.tplData.view = $state.current.name;

      if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
        $scope.tplData.rewriteFrom = 'kradzieży';
        $scope.tplData.productCode = CONSTANTS.PRODUCT_OGI;
        $scope.tplData.otherProductCode = CONSTANTS.PRODUCT_KRA;
      } else if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_BURGLARY) {
        $scope.tplData.rewriteFrom = 'ognia';
        $scope.tplData.productCode = CONSTANTS.PRODUCT_KRA;
        $scope.tplData.otherProductCode = CONSTANTS.PRODUCT_OGI;
      }

      $scope.activeProductList = [$scope.tplData.productCode];
      /**
       * inicjacja modułu
       * @return {[type]} [description]
       */
      $scope.init = function() {
        fireBurglaryHelper.refreshRisksArray();
        $scope.tplData.flood.floodIntensity = [];
        angular.forEach(RESOURCES.FLOOD_INTENSITY, function(item) {
          $scope.tplData.flood.floodIntensity.push({
            label: item.name,
            value: item.value
          });
        });
        $scope.tplData.flood.floodIntensityOption = $scope.tplData.flood.floodIntensity[0].value;
        fireBurglaryHelper.refreshLocalizationData();
        if (angular.equals($scope.dataContainer.localizations, {})) {
          $timeout(function() {
            $scope.showAddLocalizationBox();
          }, 0);
        }
        $scope.initHeaderSettings();

        fireBurglaryHelper.refreshPremiums();
        fireBurglaryHelper.refreshDisabledElement();
        fireBurglaryHelper.refreshVariantLocalizations();
        fireBurglaryHelper.refreshAvailableVariants();
        fireBurglaryHelper.setAllErrorPageForCurrentlyLocalization();
      };

      $scope.initHeaderSettings = function() {
        if ($scope.tplData.view === null || !PAGE_CONFIG[$scope.tplData.view.toUpperCase()].header.enabled) {
          return;
        }
        $scope.pagHeaderSettings = {};
        $scope.pagHeaderSettings = angular.copy(PAGE_CONFIG[$scope.tplData.view.toUpperCase()].header);

        //wszystkie ustawienia oprócz "enabled" są zgodne z sp2HeaderHelper.DEFAULT_HEADER_SETTINGS
        delete $scope.pagHeaderSettings.enabled;
      };

      /**
       * zmiana wariantu ubezpieczenia
       * @param  {String} variant
       * @return {Boolean} false, gdy zablokowano zmianę
       */
      $scope.toggleProductVariant = function(variant) {
        selectionHelper.toggleProductVariant($scope.tplData.productCode, variant);
        fireBurglaryHelper.refreshVariantLocalizations();
        fireBurglaryHelper.refreshRenewal();
        return true;
      };

      /**
       * pokazuję modal z dodaniem lokalizacji
       */
      $scope.showAddLocalizationBox = function() {
        localizationModalHelper.showAddLocalizationModal();
      };

      /**
       * pokazuję modal z edycją aktualnej lokalizacji
       */
      $scope.editLocalization = function() {
        localizationModalHelper.showEditLocalizationModal($scope.dataContainer.selectedLocalization);
      };

      $scope.suInputBlured = function(code) {
        var minValue = $scope.getSUMinValue(code),
          prod = CONSTANTS.PRODUCT_KRA;
        if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
          prod = CONSTANTS.PRODUCT_OGI;
        }
        if (minValue !== null && (angular.isUndefined($scope.tplData.suValues[code]) || $scope.tplData.suValues[code] === '' || $scope.tplData.suValues[code] === 0)) {
          $scope.tplData.suValues[code] = minValue;
          dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, $scope.dataContainer.selectedLocalization, prod, $scope.tplData.suValues[code], code);
          fireBurglaryHelper.refreshDisabledElement(true);
          triggerWhenInactive(500, $scope.tempObj, $scope.suChanged);
        }
      };

      /**
       * zmiana wartości na sumie ubezpieczenia
       */
      $scope.suChange = function(code) {
        var riskCode = CONSTANTS.PRODUCT_KRA;
        if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
          riskCode = CONSTANTS.PRODUCT_OGI;
        }
        if (angular.isUndefined($scope.tplData.suValues[code])) { //zabezpieczenie przed undefined związanym z inicjalnym działaniem ommon-numeric
          return false;
        }
        var minValue = $scope.getSUMinValue(code);
        if (minValue !== null && ($scope.tplData.suValues[code] === '' || $scope.tplData.suValues[code] === 0)) { //nie możemy wypuścić ryzyka z nieustalonym SU w takim wypdaku
          return false;
        }
        dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, $scope.dataContainer.selectedLocalization, riskCode, $scope.tplData.suValues[code], code);
        fireBurglaryHelper.refreshDisabledElement(true);
        triggerWhenInactive(500, $scope.tempObj, $scope.suChanged);
        return true;
      };

      /**
       * zwraca minimalną dopuszczalną wartość dla SU, lub null gdy brak ograniczeń
       * @param  {String} code kod ryzyka
       * @return {null|Number} null gdy nie znaleziono ograniczenia
       */
      $scope.getSUMinValue = function(code) {
        if (angular.isDefined($scope.tplData.suLimitations[code]) && angular.isNumber($scope.tplData.suLimitations[code].min)) {
          return $scope.tplData.suLimitations[code].min;
        }
        return null;
      };

      /**
       * funkcja uruchamia się po zmianie SU i odczekaniu ustawionego wyzej interwalu czasowego
       * @return {[type]} [description]
       */
      $scope.suChanged = function() {
        actionHelper.runAction('fireBurglarySumInsuredChanged');
        $scope.tarifficate({
          delay: applicationHelper.DEFAULT_TARIFFICATE_DELAY
        });
      };

      //ustawia iloc szkod powodzi
      $scope.setFloodRisk = function(value) {
        if ($scope.dataContainer.selectedLocalization !== CONSTANTS.ALL_OBJECTS) {
          if (value === null) {
            $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].deleteAdditionalData('flood');
          } else {
            $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].setAdditionalData(parseInt(value, 10), 'flood');
          }
        }
        $scope.tarifficate();
      };

      /**
       * sprawdzamy czy można wywołać taryfikację
       */
      $scope.tarifficate = function(params) {
        if (angular.isUndefined(params) || typeof angular.isUndefined(params.delay)) {
          applicationHelper.tarifficate();
        } else {
          applicationHelper.tarifficate(null, null, params.delay);
        }
      };
      /**
       * kliknięcie strzałki powrotu
       */
      $scope.backToMatrix = function() {
        $state.go('matrix');
      };

      $scope.copySu = function() {
        var otherProduct = CONSTANTS.PRODUCT_OGI,
          currentProduct = CONSTANTS.PRODUCT_KRA;
        if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
          otherProduct = CONSTANTS.PRODUCT_KRA;
          currentProduct = CONSTANTS.PRODUCT_OGI;
        }

        var suToCopy = angular.copy(dataContainerHelper.getSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, $scope.dataContainer.selectedLocalization, otherProduct));
        angular.forEach($scope.tplData.suLimitations, function(limitData, riskCode) {
          if (!angular.isNumber(suToCopy[riskCode]) && angular.isNumber(limitData.min)) { //jesli w kopiowanych SU brak SU dla wymaganego ryzyka (z minimalną sumą ubezpieczenia), to nie nadpisujemy SU dla tego ryzyka
            suToCopy[riskCode] = $scope.tplData.suValues[riskCode];
          }
        });
        dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, $scope.dataContainer.selectedLocalization, currentProduct, suToCopy);
        fireBurglaryHelper.refreshLocalizationData();
        $scope.tarifficate();
      };

      //wlacza/wylacza powodz
      $scope.toggleFlood = function(selected) {
        var floodValue = null;
        if (selected) {
          floodValue = $scope.tplData.flood.floodIntensityOption;
        } else {
          $scope.tplData.flood.floodIntensityOption = RESOURCES.FLOOD_INTENSITY[0].value;
        }
        $scope.setFloodRisk(floodValue);
      };

      $scope.init();
    }
  ]);