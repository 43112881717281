angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi OSS salesprofile
   * @return {[type]} [description]
   */
  .factory('ihestiaOssSalesprofileSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaSalesprofile = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/salesprofile';
        this.businessArea = 'oss';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new IHestiaSalesprofile();
  }])
  ;
