angular.module('salesPath2')
  .controller('newBonusMalusModalCtrl', ['$scope', 'newBonusMalusModalHelper',
    function($scope, newBonusMalusModalHelper) {
      $scope.tplData = newBonusMalusModalHelper.tplData;
      $scope.loadSpinnerOptions = {
        label: 'Pobieranie danych' // @todo label
      };

      $scope.init = function() {
        newBonusMalusModalHelper.isActive = true;
        $scope.$on('$destroy', function() {
          newBonusMalusModalHelper.isActive = false;
        });
      };

      $scope.init();

    }
  ]);