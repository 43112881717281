angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryStreetPrefixesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryStreetPrefixesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'streetprefixes';

      };
      return new RestDictionaryStreetPrefixesSvc();
    }
  ]);