/**
 * Zarzucane
 * Przerzucamy się na ihestiaRestDictionaryEurotaxEquipmentSvc
 */
angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryEurotaxEquipmentsSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryEurotaxEquipmentsSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'eurotaxEquipment';

      };
      return new RestDictionaryEurotaxEquipmentsSvc();
    }
  ]);