angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaVehicleConditionDefinitionModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var IhestiaPzaVehicleConditionDefinitionModel = function() {
      this.objectName = 'IhestiaPzaVehicleConditionDefinitionModel';

      this.fieldsProperties = {
        type: {
          type: 'string'
        },
        make: {
          type: 'string'
        },
        usageYearMin: {
          type: 'int'
        },
        usageYearMax: {
          type: 'int'
        }
      };

      this.type = null;
      this.make = null;
      this.usageYearMin = null;
      this.usageYearMax = null;

    };


    IhestiaPzaVehicleConditionDefinitionModel.prototype = IHestiaAbstractModel.prototype;

    return IhestiaPzaVehicleConditionDefinitionModel;
  }]);