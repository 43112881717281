angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi OSS workers
   * @return {[type]} [description]
   */
  .factory('ihestiaOssWorkerSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaWorker = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/worker';
        this.businessArea = 'oss';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new IHestiaWorker();
  }])
  ;
