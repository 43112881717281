angular.module('ihestiaWidgets.policyOperationsPublic')
  .controller('vehiclePurchasePublicSummaryCtrl', ['$scope', '$state', 'vehiclePurchasePublicHelper', '$window',
    function($scope, $state, vehiclePurchasePublicHelper, $window) {

      $scope.message = '';

      $scope.init = function(){
        if(vehiclePurchasePublicHelper.summary === null)
        {
          $scope.end();
        }
        else
        {
          $scope.confirmResponse = vehiclePurchasePublicHelper.summary;
          if($scope.confirmResponse.messages && $scope.confirmResponse.messages[0])
          {
            $scope.message = $scope.confirmResponse.messages[0].text;
          }
          else if(angular.isArray($scope.confirmResponse) && $scope.confirmResponse[0])
          {
            $scope.message = $scope.confirmResponse[0].text;
          }
          vehiclePurchasePublicHelper.summary = null;
          $scope.$emit('hideBlockUi');
        }
      };

      $scope.end = function(){
        $window.location = 'http://www.ergohestia.pl/';
      };

    }
  ]);