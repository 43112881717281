angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryIdentityDocumentTypesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryIdentityDocumentTypesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'identitydocumenttypes';

      };
      return new RestDictionaryIdentityDocumentTypesSvc();
    }
  ]);