angular.module('ihestiaCommonDirectives')
  .config(['$urlMatcherFactoryProvider', function($urlMatcherFactoryProvider) {
    var valToString = function(val) {
      return val !== null ? val.toString() : val;
    };

    $urlMatcherFactoryProvider.type('urlstring', {
      encode: valToString,
      decode: valToString,
      is: function() {
        return true;
      },
      pattern: /.*/
    });

  }]);