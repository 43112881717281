angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaProgramCatalogCtrl', ['$scope', 'ihestiaPzaProgramSvc', '$state', 'ihestiaPzaDictionaryHelper', 'ihestiaCommonConfirmModalHelper', 'ihestiaPzaCommonHelper', 'ihestiaPzaAppConstants', 'ihestiaPzaProgramConstants', '$alert', 'ihestiaPzaStateConfig', '$filter',
    function($scope, ihestiaPzaProgramSvc, $state, ihestiaPzaDictionaryHelper, ihestiaCommonConfirmModalHelper, ihestiaPzaCommonHelper, ihestiaPzaAppConstants, ihestiaPzaProgramConstants, $alert, ihestiaPzaStateConfig, $filter) {

      $scope.programs = [];
      $scope.listParams = {
        sortBy: null,
        sortDirection: null,
        statusCode: null
      };

      $scope.segments = [];
      $scope.ihestiaPzaProgramConstants = ihestiaPzaProgramConstants;

      /**
       * Kontekst platformy
       * @type {String}
       */
      $scope.platformContext = ihestiaPzaCommonHelper.getPlatformContext();


      /**
       * Kontekst aplikacji
       * @type {Object}
       */
      $scope.appContext = {
        userRole: ihestiaPzaCommonHelper.getUserRole()
      };

      $scope.ihestiaPzaAppConstants = ihestiaPzaAppConstants;



      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        // filtry na platformę usunięte zgodnie z
        // https://ebok.atena.pl/browse/IHESTIADR-1743
        $scope.loadPrograms();
        $scope.loadDicts();
      };

      /**
       * Ładujemy słowniki
       * @return {undefined}
       */
      $scope.loadDicts = function() {
        ihestiaPzaDictionaryHelper.getStandardDictItems([
          // 'PROGRAM_STATUS'
          'SEGMENT'
        ]).then(function(dicts) {
          $scope.segments = dicts.SEGMENT;
        }, angular.noop);
      };


      /**
       * Sortowanie
       * @param  {strong} sortBy        kod elementu do sortowania
       * @return {undefined}
       */
      $scope.sortBy = function(sortBy) {
        ihestiaPzaCommonHelper.setSortParams(sortBy, $scope.listParams);
        $scope.loadPrograms();
      };

      /**
       * Ładujemy listę warunków
       * @return {undefined}
       */
      $scope.loadPrograms = function() {
        $scope.programs = [];
        $scope.$emit('showBlockUi');
        var params = {};
        angular.forEach($scope.listParams, function(paramValue, paramCode) {
          if (paramValue !== null) {
            params[paramCode] = paramValue;
          }
        });
        ihestiaPzaProgramSvc.get(null, params).then(function(res) {
          if (res && (res.status === 200 || res.status === 204)) {
            $scope.programs = res.data;
          }
        }, angular.noop).finally(function() {
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * Tworzenie nowego programy
       * @return {undefined}
       */
      $scope.createNew = function() {
        $state.go('main.programDefinition');
      };


      /**
       * Usuwanie warunku
       * @param  {object} program
       * @return {undefined}
       */
      $scope.removeProgram = function(program) {
        if (program.status.code === ihestiaPzaProgramConstants.STATUS_DRAFT) {
          ihestiaCommonConfirmModalHelper.showConfirmModal({
            treatLabelAsI18nCode: true,
            title: ['modal.info.title', {
              componentCode: 'Public'
            }],
            text: ['modals.areYouSureRemoveProgram', {
              componentCode: 'Pza'
            }],
            okBtnCallback: function() {
              $scope.$emit('showBlockUi');
              var responseHandler = function(res) {
                if (res && (res.status === 200 || res.status === 204)) {
                  $scope.loadPrograms();
                  $alert({
                    content: $filter('translate')('modals.removingProgramSuccessful', {
                      componentCode: 'Pza'
                    }),
                    type: 'success'
                  });
                } else {
                  $scope.$emit('hideBlockUi');
                  if (res && angular.isArray(res.data) && res.data.length > 0) {
                    angular.forEach(res.data, function(error) {
                      $alert({
                        content: error.defaultMessage ? error.defaultMessage : $filter('translate')('program.unknownErrorOccurred', {
                          componentCode: 'Pza'
                        }),
                        type: 'warning'
                      });
                    });
                  } else {
                    $alert({
                      content: $filter('translate')('program.unknownErrorOccurred', {
                        componentCode: 'Pza'
                      }),
                      type: 'warning'
                    });
                  }

                }
              };
              ihestiaPzaProgramSvc.remove(program.id, null, null, null, {
                allowedStatuses: [400]
              }).then(responseHandler, responseHandler);
            }
          });
        }
      };

      /**
       * Edycja programu
       * @param  {Object} program - obiekt programy
       * @return {undefined}
       */
      $scope.editProgram = function(program) {
        if (program.status.code !== ihestiaPzaProgramConstants.STATUS_CLOSED) {
          $state.go('main.programDefinition', {
            definitionId: program.id
          });
        }
      };

      /**
       * Podgląd programu
       * @param  {Object} program - obiekt programy
       * @return {undefined}
       */
      $scope.previewProgram = function(program) {
        // https://ebok.atena.pl/browse/IHESTIASU-559
        var stateName = ihestiaPzaStateConfig.getVirtualStateName('dashboard');
        $state.go(stateName, {
          definitionId: program.id
        });
      };

      $scope.init();
    }
  ]);