angular.module('ihestiaMenuClipboard')
    .factory('IhestiaMenuClipboardHelper', [
        function() {
            var IhestiaMenuClipboardHelper = function() {

				// aktualizacja statystyk przy zmianie zawartości schowka ma działanie tylko w aplikacji CI
				// gdzie jest na to zrobiony dekorator
                this.setCIArticleStatistics = angular.noop;

            };

            return new IhestiaMenuClipboardHelper();
        }
	]);
