angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardClaimObjectConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardClaimObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {

      var ClaimObjectModel = function() {
        this.objectName = 'IhestiaDashboardClaimObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          },
          claimNumber: {
            type: 'string'
          },
          policyNumber: {
            type: 'string'
          },
          segment: {
            type: 'string'
          },
          client: {
            type: 'IhestiaDashboardClien'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_CLAIM;
        this.claimNumber = null;
        this.policyNumber = null;
        this.segment = null;
        this.client = null;

      };


      ClaimObjectModel.prototype = IHestiaAbstractModel.prototype;

      return ClaimObjectModel;
    }]);