angular.module('salesPath2')
  .provider('pageConfigResource', [
    function() {
      var self = this;
      /**
       * aktualny pageConfig
       * @type {Object}
       */
      this.pageConfig = {};

      this.$get = ['CONFIG', 'styleMap', '$sce', 'lsnObjectHelper',
        function(CONFIG, styleMap, $sce, lsnObjectHelper) {
          var PageConfigResourceClass = function() {
            var pgrsClass = this;

            /**
             * inicjalizuje PAGE_CONFIG na podstawie CONFIGA
             * @return {Boolean} true gdy zainicjalizowano
             */
            this.initPageConfig = function() {
              if (!angular.isObject(CONFIG.PAGE_CONFIG)) {
                return false;
              }
              lsnObjectHelper.replaceObject(self.pageConfig, CONFIG.PAGE_CONFIG);

              var htmlElements = [];

              /**
               * [changeOnTrustedHtmlRecursive rekursywnie wchodzi w glab obiektu az natrafi na atrybut string i go zamieni, lub jesli nie znajdzie elementu przestanie sie wywolywac]
               * @return {[type]} [description]
               */
              var changeOnTrustedHtmlRecursive = function(elementInResources, htmlElement, index) {
                if (angular.isString(elementInResources[htmlElement[index]])) {
                  //unikniecie zbieznosci nazw atrybutow
                  if (index === htmlElement.length - 1) {
                    elementInResources[htmlElement[index]] = $sce.trustAsHtml(elementInResources[htmlElement[index]]);
                  }
                } else if (htmlElement[index] === '[]' && angular.isArray(elementInResources)) {
                  angular.forEach(elementInResources, function(nestedHtmlElement, key) {
                    changeOnTrustedHtmlRecursive(elementInResources[key], htmlElement, index + 1);
                  });
                } else if (angular.isObject(elementInResources[htmlElement[index]])) {
                  changeOnTrustedHtmlRecursive(elementInResources[htmlElement[index]], htmlElement, index + 1);
                }
              };

              /**
               * [changeOnTrustedHtml zmienia elementy z tablicy htmlElements na zaufany html]
               * @return {[type]} [description]
               */
              var changeOnTrustedHtml = function() {
                angular.forEach(htmlElements, function(htmlElement) {
                  var elementInResources = self.pageConfig[htmlElement[0]];
                  changeOnTrustedHtmlRecursive(elementInResources, htmlElement, 1);
                });
              };

              changeOnTrustedHtml();
              styleMap.map(self.pageConfig, 'PAGE_CONFIG');
              delete CONFIG.PAGE_CONFIG; //aby nie odwoływać się do PAGE_CONFIGu przez CONFIG.
              return true;
            };

            /**
             * zwraca aktualny PAGE_CONFIG
             * @return {Object}
             */
            this.getPageConfig = function() {
              if (angular.equals(self.pageConfig, {})) {
                pgrsClass.initPageConfig();
              }
              return self.pageConfig;
            };
          };
          return new PageConfigResourceClass();
        }
      ];

    }
  ])
  /**
   * PAGE_CONFIG dla aplikacji
   * (z "zaufanymi" tekstami pod ng-bind-html)
   * @return {Object}
   */
  .provider('PAGE_CONFIG', [
    function() {
      this.$get = ['pageConfigResource', function(pageConfigResource) {
        return pageConfigResource.getPageConfig();
      }];
    }
  ]);