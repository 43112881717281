/**
* Dyrektywa do wrzucenia szablonu navbara
* @param  {[type]}  [description]
* @return {[type]}  [description]
*/
angular.module('ihestiaMenuSearchBox')
	.directive('ihestiaMenuSearchBox', [
		function() {
			return {
				restrict: 'E',
				replace: true,
				templateUrl: 'ihestia-menu-template-app/searchBox/searchBox.tpl.html'
			};
		}
	]);