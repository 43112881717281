angular.module('salesPath2')
  .service('vehicleModalHelper', ['$timeout', '$state', '$filter', 'sp2CommonHelper', 'vehicleHelper', 'vehicleNonEtxModalHelper', 'mainDataContainer', 'VehicleModel',
    'CONFIG', 'allowedChangesHelper', 'dataContainerHelper', 'appVariables', 'errorHelper', 'CONSTANTS', 'vehicleCepHelper',
    function($timeout, $state, $filter, sp2CommonHelper, vehicleHelper, vehicleNonEtxModalHelper, mainDataContainer, VehicleModel,
             CONFIG, allowedChangesHelper, dataContainerHelper, appVariables, errorHelper, CONSTANTS, vehicleCepHelper) {
      var VehicleModalHelper = function() {
        var self = this;
        /**
         * label przycisku anuluj
         * @type {string}
         */
        this.CANCEL_BTN_LABEL = ['sp2.modal.cancel', {
          componentCode: 'sp2'
        }];

        this.CEP_BTN_LABEL = ['cep.searchBtn', {componentCode: 'sp2'}];

        this.vehicleSearchNonEtxBtnName = CONFIG.BEHAVIOR.vehicle.nonEtxVehicles ? ['sp2.modal.vehicleEtxSearchModal.vehicleSearchNonEtxBtnName', {
          componentCode: 'sp2'
        }] : '';

        this.modals = {
          vehicleEtxSearchModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleEtxSearchModal.title',
              okBtnName: self.CEP_BTN_LABEL,
              okBtnDisabled: true,
              cancelBtnName: 'sp2.modal.cancelBtnName',
              thirdBtnName: self.vehicleSearchNonEtxBtnName,
              fourthBtnName: ['sp2.modal.vehicleEtxSearchModal.okBtnName', {componentCode: 'sp2'}],
              fourthBtnDisabled: true,
              thirdBtnDisabled: true,
              size: 'lg',
              keyboard: false
            }
          },
          vehicleEtxEditModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleEtxEditModal.title',
              okBtnName: 'sp2.modal.okBtnName',
              size: 'lg',
              keyboard: false
            }
          },
          vehicleEquipmentModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: ['sp2.modal.vehicleEquipmentModal.title', {componentCode: 'sp2'}],
              okBtnName: ['sp2.modal.okBtnName', {componentCode: 'sp2'}],
              cancelBtnName: ['sp2.modal.cancelBtnName', {componentCode: 'sp2'}],
              headerList: [{
                name: 'sp2.modal.vehicleEquipmentModal.header.standard',
                value: 'Standard'
              }, {
                name: 'sp2.modal.vehicleEquipmentModal.header.dealers',
                value: 'Dealers'
              }, {
                name: 'sp2.modal.vehicleEquipmentModal.header.additional',
                value: 'Additional'
              }],
              headerListModel: 'modals.vehicleEquipmentModal.type',
              size: 'md'
            },
            type: 'Additional',
            chosenVehicle: null,
            selectedEquipment: null,
            equipment: null
          },
          vehicleSpecialPurposeModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleSpecialPurposeModal.title',
              okBtnName: 'sp2.modal.confirm',
              size: 'sm'
            },
            selectedPurposes: null
          },
          vehicleNonEtxEditModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleNonEtxEditModal.title',
              size: 'lg',
              okBtnName: 'sp2.modal.okBtnName'
            }
          },
          vehicleCompareModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleCompareModal.title',
              okBtnName: 'sp2.modal.back',
              cancelBtnName: '',
              size: 'lg',
              keyboard: false
            },
            vehicleIds: null
          },
          vehicleZkModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: 'sp2.modal.vehicleZkModal.title',
              okBtnName: 'sp2.modal.confirm',
              size: 'sm'
            }
          }
        };

        /**
         * Otwarcie okna wyszukiwania pojazdu Eurotax
         */
        this.showEtxSearchVehicleModal = function() {
          if (CONFIG.BEHAVIOR.isSingleProduct && !dataContainerHelper.isMainObjectChosen()) {
            self.modals.vehicleEtxSearchModal.settings.cancelBtnName = '';
          } else {
            self.modals.vehicleEtxSearchModal.settings.cancelBtnName = self.CANCEL_BTN_LABEL;
          }
          $timeout(function() {
            sp2CommonHelper.showModal('vehicleEtxSearchModal');
          }, 0);
        };

        /**
         * Edycja pojazdu Eurotax
         */
        this.editVehicle = function(clientId) {
          vehicleHelper.clearEditModalData();

          vehicleHelper.currentlyEditedVehicleData.vehicle = mainDataContainer.vehicles[clientId];
          vehicleHelper.currentlyEditedVehicleData.replaceClientId = clientId;
          vehicleHelper.currentlyEditedVehicleData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(vehicleHelper.currentlyEditedVehicleData.vehicle);

          if (vehicleHelper.currentlyEditedVehicleData.vehicle.getAdditionalData('source') === 'nonEtx') {
            vehicleNonEtxModalHelper.modalData.vehicle = mainDataContainer.vehicles[clientId];
            vehicleNonEtxModalHelper.modalData.clientId = clientId;
            vehicleNonEtxModalHelper.modalData.replaceEtxVehicle = true;
            vehicleNonEtxModalHelper.modalData.dataCleared = true;
            self.justShowVehicleNonEtxEditModal();
          } else {
            self.justShowEditEtxVehicleModal();
          }
        };

        /**
         * Wywolanie okna edycji pojazdu Eurotax
         */
        this.justShowEditEtxVehicleModal = function() {
          if (angular.isString(vehicleHelper.currentlyEditedVehicleData.replaceClientId)) {
            vehicleHelper.currentlyEditedVehicleData.errors = errorHelper.getErrorFieldsForObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleHelper.currentlyEditedVehicleData.replaceClientId);
          }

          self.modals.vehicleEtxEditModal.settings.okBtnName = 'sp2.modal.okBtnName';
          self.modals.vehicleEtxEditModal.settings.cancelBtnName = 'sp2.modal.cancel';
          if (appVariables.isSupplement) {

            var allowedChanges = allowedChangesHelper.getAllowedChangesFor(vehicleHelper.currentlyEditedVehicleData.vehicle);
            if (allowedChanges.allDisabled) {
              self.modals.vehicleEtxEditModal.settings.okBtnName = 'sp2.modal.close';
              self.modals.vehicleEtxEditModal.settings.cancelBtnName = '';
            }
          }
          //tryb RO
          if (appVariables.readOnly && !appVariables.isOcBuyer) {
            self.modals.vehicleEtxEditModal.settings.okBtnName = 'sp2.modal.close';
            self.modals.vehicleEtxEditModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('vehicleEtxEditModal');
        };

        /**
         * Anulowanie edycji pojazdu - w zależności od sytuacji albo wymuszamy ponowny wybór albo pozwalmy wyjść z edycji
         */
        this.cancelEditVehicle = function(clientId) {
          vehicleHelper.clearEditModalData();
          sp2CommonHelper.hideModal('vehicleEtxEditModal');

          if ($state.current.name === 'vehicleMatrix' && angular.equals(mainDataContainer.vehicles, {}) === true) {
            //tylko jeśli to inicjalne dodanie pojazdu
            vehicleHelper.currentlyEditedVehicleData.replaceClientId = clientId;
            sp2CommonHelper.showModal('vehicleEtxSearchModal');
          } else if (mainDataContainer.unsupportedVehicle) {
            self.chooseOtherVehicle();
          }
        };

        /**
         * Pojazd wybrany. Zamykamy okno wyszukiwania pojazdu i otwieramy okno edycji
         */
        this.vehicleChosen = function(vehicleModel, replaceClientId) {
          sp2CommonHelper.hideModal('vehicleEtxSearchModal');
          vehicleHelper.clearSearchModalData();
          vehicleCepHelper.clearSearchModalData();
          vehicleHelper.currentlyEditedVehicleData.vehicle = vehicleModel;
          vehicleHelper.currentlyEditedVehicleData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(vehicleHelper.currentlyEditedVehicleData.vehicle);
          vehicleHelper.currentlyEditedVehicleData.replaceClientId = replaceClientId;
          self.justShowEditEtxVehicleModal();
        };

        /**
         * Ustawiamy domyślne wartości parametrów edycji pojazdu i wyszukujemy na nowo
         */
        this.chooseOtherVehicle = function() {
          if (mainDataContainer.unsupportedVehicle) {
            self.modals.vehicleEtxSearchModal.settings.cancelBtnName = '';
            vehicleHelper.currentlyEditedVehicleData.replaceClientId = mainDataContainer.tempVehicleData.metaData.clientId;
          } else if (CONFIG.BEHAVIOR.isSingleProduct && !dataContainerHelper.isMainObjectChosen()) {
            self.modals.vehicleEtxSearchModal.settings.cancelBtnName = '';
          } else {
            self.modals.vehicleEtxSearchModal.settings.cancelBtnName = self.CANCEL_BTN_LABEL;
          }
          var replaceClientId = vehicleHelper.currentlyEditedVehicleData.replaceClientId;
          vehicleHelper.clearSearchModalData();
          vehicleCepHelper.clearSearchModalData();
          vehicleHelper.clearEditModalData();
          sp2CommonHelper.hideModal('vehicleEtxEditModal');
          sp2CommonHelper.hideModal('vehicleNonEtxEditModal');
          vehicleHelper.searchModalParams.replaceClientId = replaceClientId;
          sp2CommonHelper.showModal('vehicleEtxSearchModal');
          return self;
        };

        /**
         * Wybór wyposażenia
         */
        this.chooseVehicleEquipment = function(vehicleModel, selectedEquipment, equipment) {
          sp2CommonHelper.hideModal('vehicleEtxEditModal');
          self.modals.vehicleEquipmentModal.chosenVehicle = vehicleModel;
          self.modals.vehicleEquipmentModal.selectedEquipment = selectedEquipment;
          self.modals.vehicleEquipmentModal.equipment = equipment;
          self.modals.vehicleEquipmentModal.settings.okBtnName = ['sp2.modal.okBtnName', {componentCode: 'sp2'}];
          self.modals.vehicleEquipmentModal.settings.cancelBtnName = ['sp2.modal.cancelBtnName', {componentCode: 'sp2'}];
          if (appVariables.readOnly) {
            self.modals.vehicleEquipmentModal.settings.okBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
            self.modals.vehicleEquipmentModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('vehicleEquipmentModal');
        };


        /**
         * Wybór pojazdu poza ETX
         */
        this.chooseNonEurotaxVehicle = function(clientId, replaceEtxVehicle) {
          sp2CommonHelper.hideModal('vehicleEtxSearchModal');
          if (vehicleHelper.searchModalParams.replaceClientId !== null) {
            vehicleNonEtxModalHelper.modalData.vehicle = new VehicleModel();
            vehicleNonEtxModalHelper.modalData.errors = vehicleHelper.currentlyEditedVehicleData.errors;
            vehicleNonEtxModalHelper.modalData.clientId = vehicleHelper.searchModalParams.replaceClientId;
            vehicleNonEtxModalHelper.modalData.replaceClientId = vehicleHelper.searchModalParams.replaceClientId;
          } else {
            vehicleNonEtxModalHelper.modalData.vehicle = new VehicleModel();
            vehicleNonEtxModalHelper.modalData.clientId = clientId;
          }

          if (angular.isDefined(vehicleHelper.searchModalParams.searchParams.productionYear)) {
            vehicleNonEtxModalHelper.modalData.vehicle.productionYear = vehicleHelper.searchModalParams.searchParams.productionYear;
          }
          vehicleNonEtxModalHelper.modalData.dataCleared = true; //kazdorazowe wejscie w "inny pojazd - spoza etx" ma powodować reset danych IHESTIAMF-2203
          vehicleNonEtxModalHelper.modalData.replaceEtxVehicle = !!replaceEtxVehicle;
          self.justShowVehicleNonEtxEditModal();
        };

        /**
         * Wybór szczególnego przeznaczenia pojazdu
         */
        this.chooseSpecialPurpose = function(vehicleModel, specialPurposes) {
          if (!CONFIG.BEHAVIOR.vehicle.vehicleSpecialPurpose) {
            return;
          }
          sp2CommonHelper.hideModal('vehicleEtxEditModal');
          sp2CommonHelper.hideModal('vehicleNonEtxEditModal');
          self.modals.vehicleEquipmentModal.chosenVehicle = vehicleModel;
          self.modals.vehicleSpecialPurposeModal.selectedPurposes = specialPurposes;
          self.modals.vehicleSpecialPurposeModal.settings.okBtnName = ['sp2.modal.confirm', {componentCode: 'sp2'}];
          self.modals.vehicleSpecialPurposeModal.settings.cancelBtnName = self.CANCEL_BTN_LABEL;
          if (appVariables.readOnly && !appVariables.isOcBuyer) {
            self.modals.vehicleSpecialPurposeModal.settings.okBtnName = ['sp2.modal.close', {componentCode: 'sp2'}];
            self.modals.vehicleSpecialPurposeModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('vehicleSpecialPurposeModal');
        };

        /**
         * Wyposażenie wybrane (wywoływane przy naciśnięciu OK na modalu wyvory wyposażenia)
         */
        this.vehicleEquipmentSelected = function(selectedEquipment) {
          if (!CONFIG.BEHAVIOR.vehicle.vehicleEqipment) {
            return;
          }
          sp2CommonHelper.hideModal('vehicleEquipmentModal');
          var equipments = [];
          vehicleHelper.currentlyEditedVehicleData.vehicle.equipments = [];
          angular.forEach(selectedEquipment, function(eq) {
            equipments.push([eq.name, eq.id, eq.category]);
          });
          vehicleHelper.currentlyEditedVehicleData.vehicle.equipments = equipments;
          vehicleHelper.currentlyEditedVehicleData.equipmentchanged = true;
          self.justShowEditEtxVehicleModal();
        };

        /**
         * Anulowanie edycji wyposażenia
         */
        this.vehicleEquipmentEditCancel = function() {
          sp2CommonHelper.hideModal('vehicleEquipmentModal');
          self.justShowEditEtxVehicleModal();
        };

        this.purposesChosen = function(selectedPurposes) {
          vehicleHelper.purposesModalData.purposes = selectedPurposes;
          self.modals.vehicleSpecialPurposeModal.selectedPurposes = null;

          //w przypadku pojazdu nonEtx może być przypadek, że jeszcze nie został on utworzony. 
          if (vehicleHelper.currentlyEditedVehicleData.vehicle === null || vehicleHelper.currentlyEditedVehicleData.vehicle.getAdditionalData('source') === 'nonEtx') {
            self.justShowVehicleNonEtxEditModal();
          } else {
            self.justShowEditEtxVehicleModal();
          }
        };

        /**
         * [justShowVehicleNonEtxEditModal otwiera okno edycji pojazdu noEtx]
         * @return {[type]} [description]
         */
        this.justShowVehicleNonEtxEditModal = function() {
          if (angular.isString(vehicleNonEtxModalHelper.modalData.editedId)) {
            vehicleNonEtxModalHelper.modalData.errors = errorHelper.getErrorFieldsForObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicleNonEtxModalHelper.modalData.editedId);
          }

          vehicleNonEtxModalHelper.modalData.allowedChanges = allowedChangesHelper.getAllowedChangesFor(vehicleNonEtxModalHelper.modalData.vehicle);
          if (vehicleNonEtxModalHelper.modalData.allowedChanges.allDisabled) {
            self.modals.vehicleNonEtxEditModal.okBtnName = 'sp2.modal.close';
            self.modals.vehicleNonEtxEditModal.cancelBtnName = '';
          } else {
            self.modals.vehicleNonEtxEditModal.okBtnName = 'sp2.modal.okBtnName';
            self.modals.vehicleNonEtxEditModal.cancelBtnName = 'sp2.modal.cancel';
          }

          sp2CommonHelper.showModal('vehicleNonEtxEditModal');
        };

        this.purposesEditCanceled = function() {
          self.modals.vehicleSpecialPurposeModal.selectedPurposes = null;
          //w przypadku pojazdu nonEtx może być przypadek, że jeszcze nie został on utworzony. 
          if (vehicleHelper.currentlyEditedVehicleData.vehicle === null || vehicleHelper.currentlyEditedVehicleData.vehicle.getAdditionalData('source') === 'nonEtx') {
            self.justShowVehicleNonEtxEditModal();
          } else {
            self.justShowEditEtxVehicleModal();
          }
        };

        //klik w anuluj na formularzu edycji pojazdu spoza etx
        this.vehicleNonEtxEditCanceled = function() {
          sp2CommonHelper.hideModal('vehicleNonEtxEditModal');

          if ($state.current.name === 'vehicleMatrix' && angular.equals(mainDataContainer.vehicles, {}) === true) {
            //tylko jeśli to inicjalne dodanie pojazdu
            sp2CommonHelper.showModal('vehicleEtxSearchModal');
          }
        };

        /*
         * uaktywnia przycisk wyszukiwania pojazdu poza ETX
         */
        this.enableNonETXBtn = function() {
          self.modals.vehicleEtxSearchModal.settings.thirdBtnDisabled = false;
        };

        /*
         * dezaktywuje przycisk wyszukiwania poza ETX
         */
        this.disableNonETXBtn = function() {
          self.modals.vehicleEtxSearchModal.settings.thirdBtnDisabled = true;
        };

        /**
         * porównywarka pojazdów
         */
        this.compareVehicles = function(vehicleIds) {
          sp2CommonHelper.hideModal('vehicleEtxSearchModal');
          this.modals.vehicleCompareModal.vehicleIds = vehicleIds;
          sp2CommonHelper.showModal('vehicleCompareModal');
        };

        /*
         * wybór pojazdu na oknie porównywarki pojazdów
         */
        this.compareVehicleChoose = function(vehicleModel, replaceClientId) {
          sp2CommonHelper.hideModal('vehicleCompareModal');
          self.vehicleChosen(vehicleModel, replaceClientId);
        };

        /*
         * naciśnięcie 'wróć' na modalu porównywania pojazdu
         */
        this.compareVehicleCancel = function() {
          sp2CommonHelper.hideModal('vehicleCompareModal');
          sp2CommonHelper.showModal('vehicleEtxSearchModal');
        };

        /*
         * nieaktywny przycisk porównania
         */
        this.disableCompareButton = function() {
          this.modals.vehicleEtxSearchModal.settings.fourthBtnDisabled = true;
        };

        /*
         * aktywny przycisk porównania
         */
        this.enableCompareButton = function() {
          this.modals.vehicleEtxSearchModal.settings.fourthBtnDisabled = false;
        };

        /*
         * enable "search in CEP" button
         */
        this.enableCepBtn = function() {
          self.modals.vehicleEtxSearchModal.settings.okBtnDisabled = false;
        };

        /*
         * disable "search in CEP" button
         */
        this.disableCepBtn = function() {
          self.modals.vehicleEtxSearchModal.settings.okBtnDisabled = true;
        };

        /**
         * enable/disable CEP module/search
         * @param {boolean} isEnabled
         */
        this.switchCepModule = function(isEnabled) {
          if (isEnabled) {
            self.modals.vehicleEtxSearchModal.settings.okBtnName = self.CEP_BTN_LABEL;
            self.enableCepBtn();
          } else {
            self.modals.vehicleEtxSearchModal.settings.okBtnName = '';
            self.disableCepBtn();
          }
        };
      };

      return new VehicleModalHelper();
    }
  ]);
