angular.module('lsnBase.models')
  .factory('LsnBonusMalusNewModelV1', [
    function()
    {

      var LsnBonusMalusNewModelV1 = function()
      {
        this.history5YearsCount = null; // "5"
        this.last2YearsDamagesCount = null; // "0"
        this.last5YearsDamagesCount = null; // "1"
        this.noUfgData = null; // "false" //brak historii w UFG
        this.ufgConnectionProblem = null; // "false" //problem z połączeniem z UFG
      };

      return LsnBonusMalusNewModelV1;
    }
  ]);