angular.module('ihestiaRestServicesBase')
  .factory('cepSvc', ['IHestiaRestServiceAbstractDef', 'ihestiaConfigHelper', 'ihestiaSsoBaseInfoHelper',
    function(IHestiaRestServiceAbstractDef, ihestiaConfigHelper, ihestiaSsoBaseInfoHelper) {

      /**
       * API doc:
       * https://documenter.getpostman.com/view/270266/TzJsgJpP#abada9dc-de8f-405d-82c4-a574f7ce10eb
       * @constructor
       */
      var CepSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        var self = this;

        var propertyToVehiclePropMap = {
          arts: 'category',
          marks: 'mark',
          models: 'model',
          types: 'type',
          powerKw: 'power',
          engine: 'capacity',
          body: 'bodyType'
        };

        this.basePattern = '{businessArea}/{resource}{subResource}{id}';
        this.resource = 'cep';
        this.businessArea = 'cep-frontproxy-api/secured';

        /**
         * search for a vehicle in CEP
         * Suggested params: {vin: 'some_vin'} or {licensePlate: 'reg_date'}
         * NOTE
         * Service can only search by one main parameter: vin or licensePlate (both together are not allowed)
         *
         * Sample response:
         * {"matchSize":2,"matchList":[{"art":"Samochody osobowe","mark":"BMW","model":"Seria 4 Coupe [F32/F82] 13-17","subModel":"Seria 4","typ":"M4","body":"CPE","engine":2979,"powerKw":317,"powerHp":431,"idEtx":106406,"idArt":10,"idMark":356,"idModel":15845,"idTyp":106406,"typImportStart":"2014-03-01","typImportStop":"2017-04-30","typImportStartYear":2014,"typImportStopYear":2017,"modelImportStart":"2013-07-01","modelImportStop":"2017-04-30","modelImportStartYear":2013,"modelImportStopYear":2017,"fuel":"E","fuelDesc":"Benzyna","bodyDesc":"Coupe","gearbox":"M","gearboxDesc":"Manualna skrzynia biegów"},{"art":"Samochody osobowe","mark":"BMW","model":"Seria 4 Coupe [F32/F82] 13-17","subModel":"Seria 4","typ":"M4 DKG","body":"CPE","engine":2979,"powerKw":317,"powerHp":431,"idEtx":106407,"idArt":10,"idMark":356,"idModel":15845,"idTyp":106407,"typImportStart":"2014-03-01","typImportStop":"2017-04-30","typImportStartYear":2014,"typImportStopYear":2017,"modelImportStart":"2013-07-01","modelImportStop":"2017-04-30","modelImportStartYear":2013,"modelImportStopYear":2017,"fuel":"E","fuelDesc":"Benzyna","bodyDesc":"Coupe","gearbox":"A","gearboxDesc":"Automatyczna skrzynia biegów"}],"input":{"art":"SAMOCHÓD OSOBOWY","mark":"BMW","model":"M4","body":"COUPE","productionYear":2016,"engine":2979,"powerKw":431},"itsEtxData":[{"property":"arts","items":[{"code":10,"name":"Samochody osobowe"}]},{"property":"marks","items":[{"code":356,"name":"BMW"}]},{"property":"models","items":[{"code":15845,"name":"Seria 4 Coupe [F32/F82] 13-17"}]},{"property":"types","items":[{"code":106406,"name":"M4"},{"code":106407,"name":"M4 DKG"}]},{"property":"engine","items":[{"code":2979,"name":"2979"}]},{"property":"powerHp","items":[{"code":431,"name":"431"}]},{"property":"powerKw","items":[{"code":317,"name":"317"}]},{"property":"productionYear","items":[{"code":2016,"name":"2016"}]}],"responseVehicleExtendedData":{"firstRegistrationDate":"2019-07-05","mileage":10000}}
         * All available "raw" properties in itsEtxData collection: arts, marks, models, types, productionYear, powerHp, powerKW, engine
         *
         * @param {Object} params extension of default request "body" (see "var body" for details)
         * @param {Object} [httpParams] optional "standard" http parameters for IHestiaRestServiceAbstractDef
         * @return {Promise}
         */
        this.search = function(params, httpParams) {
          var currentCharacter = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter();
          var ossInfo = currentCharacter.ossContextInfo;
          var body = {
            metadata: {
              system: ihestiaConfigHelper.get('rest').AP_CALLER,
              subject: ossInfo ? ossInfo.AgencyNumberProperty : null,
              user: ossInfo ? ossInfo.WorkerSymbol : null
            },
            vin: null,
            licensePlate: null,
            queryDate: ihestiaConfigHelper.get('serverData', 'TODAY'),
            maxDaysDifference: 1,
            skipCache: false
          };
          if (params) {
            _.merge(body, params);
          }
          return self.post(null, body, null, function(resp) {
            return self._mapResponse(resp);
          }, null, angular.extend({
            allowedStatuses: [400, 404, 503]
          }, httpParams || {}));
        };

        /**
         * map POST response and try assign LsnVehicleModelV1 property names if possible
         */
        this._mapResponse = function(resp) {
          if (resp.data && _.isArray(resp.data.itsEtxData)) {
            angular.forEach(resp.data.itsEtxData, function(item) {
              if (propertyToVehiclePropMap[item.property]) {
                item.property = propertyToVehiclePropMap[item.property];
              }
            });
          }
          return resp;
        };


      };

      return new CepSvc();
    }
  ]);
