angular.module('ihestiaCommonDirectives')
  .service('ihestiaExtendHelper', ['$controller',
    function($controller) {
      var ExtendHelper = function() {
        var self = this;

        this.extendedControllers = [];

        /**
         * Metoda do rozszerzania kontrolerów
         * @param  {object} baseController         this z kontrolera
         * @param  {string} childControllerName nazwa kontrolera dziecka
         * @param  {object} params                 {$scope: $scope}
         * @return {ihestiaExtendHelper}                        self
         */
        this.extendController = function(baseController, childControllerName, params) {
          try {
            $controller(childControllerName);
          } catch (e) {
            if (!(e instanceof TypeError)) {
              angular.extend(baseController, $controller(childControllerName, params));
              self.extendedControllers.push(childControllerName);
            }
          }

          return self;
        };
      };

      return new ExtendHelper();
    }
  ]);