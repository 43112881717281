angular.module('salesPath2')
  .service('resourceTooltipModalHelper', ['RESOURCES', 'sp2CommonHelper',
    function(RESOURCES, sp2CommonHelper) {
      var ResourceTooltipModalHelper = function() {
        var self = this;
        /**
         * wyświetlanie okienka OWU
         * @type {Object}
         */
        this.tooltipModal = {
          settings: {
            treatLabelAsI18nCode: true,
            title: ['resourceInfoModal.title', {componentCode: 'sp2'}],
            cancelBtnName: ['resourceInfoModal.btnLabel', {componentCode: 'sp2'}],
            okBtnName: false,
            size: 'sm'
          },
          data: {} //dane do modala z RESOURCES
        };

        /**
         * wyswietla modal na podstawie danych z 
         * @param  {String} resourceName nazwa pozycji w resources, przechowujaca dane dla tooltipa
         */
        this.showModal = function(resourceName) {
          if (angular.isUndefined(RESOURCES[resourceName])) {
            sp2CommonHelper.throwException('Brak zasobu dla tooltipa o kodzie {0}.'.format(resourceName));
          }
          self.tooltipModal.data = RESOURCES[resourceName];
          sp2CommonHelper.showModal('resourceTooltipModal');
        };

      };

      return new ResourceTooltipModalHelper();
    }
  ]);