angular.module('salesPath2')
  .service('attachmentHelper', ['$rootScope', '$filter', 'policiesV2Svc', 'lsnTryEscapeHtml', 'filesServiceHelper',
    'ihestiaCompareByPolishSign', 'lsnDeepExtend', '$timeout', 'sp2UiHelper', 'sp2CommonHelper', 'dataContainerHelper', 'lsnDateTimeHelper',
    function($rootScope, $filter, policiesV2Svc, lsnTryEscapeHtml, filesServiceHelper,
      ihestiaCompareByPolishSign, lsnDeepExtend, $timeout, sp2UiHelper, sp2CommonHelper, dataContainerHelper, lsnDateTimeHelper) {
      var AttachmentHelper = function() {
        var self = this;
        //ustawienia modali
        this.modals = {};

        /**
         * dodawanie osoby
         * @type {Object}
         */
        this.modals.attachmentModal = {
          settings: {
            treatLabelAsI18nCode: true,
            okBtnName: 'Public.send',
            cancelBtnName: 'sp2.modal.cancelBtnName'
          }
        };
        this.policyId = '';

        var DEFAULT_MODAL_PARAMS = {
          fileModels: [],
          policy: {},
          documentType: {
            code: '',
            name: '',
            attributes: []
          },
          documentTypes: {
            all: [],
            forPolicy: []
          },
          context: ''
        };

        this.modalData = angular.copy(DEFAULT_MODAL_PARAMS);

        /**
         * Ustawiamy domyślne wartości dla modala
         * @return {attachmentHelper}
         */
        this.clearModalParams = function() {
          self.modalData = angular.copy(DEFAULT_MODAL_PARAMS);
        };

        /**
         * Pobranie danych wydruków i wyświetlenie modala
         */
        this.showAttachmentModal = function(policyNumber, context) {
          self.clearModalParams();
          self.modalData.policy = dataContainerHelper.getPolicyByNumber(policyNumber);
          self.modalData.context = context;

          self.modals.attachmentModal.settings.title = $filter('translate')('sp2.attachmentToPolicy', {componentCode: 'sp2'}) + ' ' + lsnTryEscapeHtml(policyNumber) + ' - ' + $filter('translate')('sp2.attachmentToPolicyElectronic', {componentCode: 'sp2'});

          sp2UiHelper.showBlockUi();
          self.setAllDocumentTypes();
        };

        this.setAllDocumentTypes = function() {
          if (self.modalData.documentTypes.all.length === 0) {
            filesServiceHelper.getAllElectronicDocumentTypesSvc(null, null, null, function(res) {

              if (!res || !res.data || !res.data.length) {
                sp2UiHelper.hideBlockUi();
                sp2CommonHelper.throwException($filter('translate')('sp2.attachmentExceptionNoDocTypes', {componentCode: 'sp2'})+ ' ');
              } else {
                self.modalData.documentTypes.all = angular.copy(res.data);
                self.modalData.documentTypes.all.sort(ihestiaCompareByPolishSign('name'));

                self.getDocumentTypesAttachToPolicyAndShowPopup(true);
              }

            }, function() {
              sp2UiHelper.hideBlockUi();
              sp2CommonHelper.throwException($filter('translate')('sp2.attachmentExceptionNoDocTypes', {componentCode: 'sp2'})+ ' ');
            });
          } else {
            self.getDocumentTypesAttachToPolicyAndShowPopup(true);
          }
        };

        /**
         * Pobiera listę documentTypes przypisanych do polisy, następnie wyświetla popup jeśli nie jest załadowany
         * @param {boolean} showModal flaga na otwieranie okna
         */
        this.getDocumentTypesAttachToPolicyAndShowPopup = function(showModal) {
          policiesV2Svc.getDocuments(self.modalData.policy.number).then(function(res) {
            // policiesSvc.getDocuments(31964812).then(function(res) {
            //najpierw sprawdzamy czy nie musimy powtórzyć

            var retryAfter = res.headers('Retry-After');
            if (angular.isDefined(res) && retryAfter !== null) {

              $timeout(function() {
                self.getDocumentTypesAttachToPolicyAndShowPopup(true);
              }, parseInt(retryAfter, 10) * 1000);

            } else {
              if (!res || !res.data || !res.data.length) {
                sp2UiHelper.hideBlockUi();
                sp2CommonHelper.throwException($filter('translate')('sp2.attachmentExceptionNoDocTypes', {componentCode: 'sp2'}));
              } else {
                self.applyPolicyDocumentTypesAttach(res.data);

                if (showModal) {
                  sp2CommonHelper.showModal('attachmentModal');
                }
              }

              sp2UiHelper.hideBlockUi();
            }
          }, function() {
            sp2UiHelper.hideBlockUi();
            sp2CommonHelper.throwException($filter('translate')('sp2.attachmentExceptionNoDocTypesToPolicy', {componentCode: 'sp2'}));
          });
        };

        /**
         * Uzupełniamy listę documentTypes poprzez kody dokumentów dostępnych dla polisy
         */
        this.applyPolicyDocumentTypesAttach = function(data) {
          self.modalData.documentTypes.forPolicy = [];
          angular.forEach(data, function(documentType) {
            self.modalData.documentTypes.forPolicy.push(documentType);
          });

          self.setDocumentTypesForPolicy();
        };

        /**
         * Uzupełniamy listę documentTypes poprzez kody dokumentów dostępnych dla polisy
         */
        this.setDocumentTypesForPolicy = function() {
          angular.forEach(self.modalData.documentTypes.all, function(documentTypeA) {
            angular.forEach(self.modalData.documentTypes.forPolicy, function(documentType, key) {
              if (documentType.code.toUpperCase() === documentTypeA.code.toUpperCase()) {
                self.modalData.documentTypes.forPolicy[key] = lsnDeepExtend(documentType, documentTypeA);
              }
            });
          });
        };

        /**
         * Ustawiamy atrybuty dla wybranego typu dokumentu
         * @return {UploadHelper}
         */
        this.setAttributesForSelectedDocumentType = function() {
          angular.forEach(self.modalData.documentTypes, function(types) {
            angular.forEach(types, function(documentType) {
              if (angular.isString(self.modalData.documentType.code) && angular.isString(documentType.code) &&
                documentType.code.toLowerCase() === self.modalData.documentType.code.toLowerCase()) {

                self.modalData.documentType = angular.copy(documentType);
                self.setDefaultAttributes();
              }
            });
          });
        };

        /**
         * Ustawiamy domyślne wartości atrybutom oraz przepisuje wartość w razie potrzeby
         */
        this.setDefaultAttributes = function() {
          angular.forEach(self.modalData.documentType.attributes, function(attr) {

            attr.invalidText = '';
            attr.invalid = false;

            if (attr.type === 'string' || attr.type === 'decimal' || attr.type === 'money') {
              if (angular.isDefined(attr.val)) {
                attr.val = attr.val || '';
              } else {
                attr.val = attr.value || '';
              }
            } else if (attr.type === 'datetime') {
              self.setAttributeDateTime(attr);
            }

            if (attr.code === 'PolicyNumber') {
              attr.val = self.modalData.policy.number;
              attr.disabled = true;
            }
          });
        };

        this.setAttributeDateTime = function(attr) {
          var today = lsnDateTimeHelper.getDate();
          if (attr.val !== '') {
            if (angular.isDefined(attr.val)) {
              attr.val = attr.val || today;
            } else {
              var date = new XDate(attr.value);
              if (date.valid()) {
                attr.val = date.toString('yyyy-MM-dd') || today;
              }
            }
          }

          if (attr.constraints) {
            if (attr.constraints.maxDate) {
              attr.constraints.maxDate = (new XDate(attr.constraints.maxDate)).toString('yyyy-MM-dd');
            }

            if (attr.constraints.minDate) {
              attr.constraints.minDate = (new XDate(attr.constraints.minDate)).toString('yyyy-MM-dd');
            }
          }
        };

        self.setDocumentTypeForSelectList = function() {
          angular.forEach(self.modalData.documentTypes.popular, function(popular) {
            if (self.modalData.documentType.code === popular.code) {
              self.modalData.documentTypeCodePopular = self.modalData.documentType.code;
            }
          });
          angular.forEach(self.modalData.documentTypes.other, function(other) {
            if (self.modalData.documentType.code === other.code) {
              self.modalData.documentTypeCodePopular = 'more';
              self.modalData.documentTypeCodeOther = self.modalData.documentType.code;
            }
          });
        };
      };

      return new AttachmentHelper();
    }
  ])
  .run(['attachmentHelper', function(attachmentHelper) {
    lsnNg.noop(attachmentHelper); // chodzi tylko o inicjalizacje serwisu
  }]);