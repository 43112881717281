/**
 * EHNLEARN-2121 - do not remove commune and district
 * override filter used in models
 */
angular.module('lsnBase.models')
  .service('addressFilterModelFilter', ['AddressModelConstants', 'AddressModel', function(AddressModelConstants, AddressModel) {
    return function(addresses) {
      var handledCodes = [AddressModelConstants.ADDRESS_TYPE_STAL, AddressModelConstants.ADDRESS_TYPE_KORE]; // obsługiwane typy kontaktów
      var parsedAddresses = {};

      angular.forEach(addresses, function(address) {

        if (!address.code) {
          address.code = AddressModelConstants.ADDRESS_TYPE_STAL;
        }

        var code = address.code;
        if (handledCodes.indexOf(code) !== -1 && typeof parsedAddresses[code] === 'undefined') {
          // address.commune = null;
          // address.district = null;
          parsedAddresses[code] = address;
        }
      });

      if (typeof parsedAddresses[AddressModelConstants.ADDRESS_TYPE_STAL] === 'undefined') {
        parsedAddresses[AddressModelConstants.ADDRESS_TYPE_STAL] = new AddressModel();
      }

      // chcemy zachować kolejność
      var sortedAdresses = [];
      angular.forEach(handledCodes, function(code) {
        if (typeof parsedAddresses[code] !== 'undefined') {
          sortedAdresses.push(parsedAddresses[code]);
        }
      });

      return sortedAdresses;
    };
  }]);
