angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardTasksSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var TasksSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/tasks';
        this.businessArea = 'dashboard';
        this.port = '8282';

        this.pageStyle = false;

        this.grid.sortBy = 'dueDate';
        this.grid.sortDirection = 'desc';

        this.getToken = function(taskId, commentId, attachmentId, callback, errorCallback, params) {
          var data = {}; // NOSONAR
          if (angular.isDefined(commentId)) {
            data = {objectType: 'COMMENT', commentId: commentId};
          } else {
            data = {objectType: 'TASK'};
          }

          self.get(taskId, data, 'attachment/' + attachmentId, callback, errorCallback, params);
        };

        /**
         * Request do pobierania pliku pdf ze szczegółami zadania
         * @param  {String} taskId [description]
         * @return {Promise}        [description]
         */
        this.downloadDetailsPdf = function(taskId) {
          var headers = {
            Accept: 'application/pdf'
          };
          return self.get(taskId, null, 'details', null, null, {
            headers: headers,
            responseType: 'arraybuffer' // zapobiega generowaniu pustych pdf'ów
          });
        };

      };
      return new TasksSvc();
    }
  ]);