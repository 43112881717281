angular.module('ihestiaGrid')
  .controller('ihestiaGridShortFilterCtrl', ['$scope', 'ihestiaGridHelper',
    function($scope, ihestiaGridHelper) {

      $scope.isFixed = false;
      $scope.focusOnInit = false;

      $scope.init = function()
      {
        if(ihestiaGridHelper.sharedData.baseFilterfocused)
        {
          $scope.focusOnInit = true;
        }
      };

      $scope.filterFocus = function()
      {
        //na bazowym nagłówku odkładamy informacje o focusie
        ihestiaGridHelper.sharedData.baseFilterfocused = true;
      };

      $scope.filterBlur = function()
      {
        //na bazowym nagłówku odkładamy informacje o focusie
        ihestiaGridHelper.sharedData.baseFilterfocused = false;
        $scope.filterByString(0);
      };

      $scope.init();
    }
  ]);