angular.module('lsnBase', [
  'lsnNg',
  'ngTouch',
  'mgcrea.ngStrap.popover',
  'lsnBase.typeahaed',
  'lsnBase.errorHandler',
  'lsnBase.modelHelper',
  'lsnBase.addresses',
  'lsnBase.availability',
  'lsnBase.autocompleters',
  'lsnBase.detectorUserActvie',
  'lsnBase.autocompleteOff',
  'lsnBase.models',
  'lsnBase.validators',
  'lsnBase.directives',
  'lsnBase.md5',
  'lsnBase.bootstrap',
  'lsnBase.numeralString',
  'lsn-base-templates-app',
  'lsnBase.arrayUnique',
  'lsnBase.deepExtend',
  'lsnBase.lazyLoad',
  'lsnBase.tryEscapeHtml',
  'lsnBase.utils',
  'lsnBase.constants',
  'lsnBase.dateTime',
  'lsnBase.stringPremium',
  'lsnBase.filters'
]).config(['$locationProvider',
  function($locationProvider) {
    $locationProvider.hashPrefix('');
  }]);