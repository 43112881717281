/**
 * Zamienia znaki nowej linii \n\r na <br />
 */
angular.module('ihestiaCommonDirectives')
  .directive('newlineToBreak', [function() {
    return {
      link: function(scope, element, attr) {
        scope.$watch(attr.newlineToBreak, function(value) {
          if(value) {
            value = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
            // @todo lepiej to poprawić
            element.html(value);
          }
        }, true);
      }

    };
  }]);