angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaLifeClientEditModalHelper', ['LsnModalHelper', 'lsnModelFactory', 'IhestiaClauseListHelper', 'IhestiaLifeInsurerChangeFlowConstants', 'clausesContextsSvc', 'ihestiaFromRestClauseMapper', 'ihestiaLabelHelper', '$filter', 'LsnIdentityDocumentModelConstants', '$timeout', 'LsnClausesModelConstants',
    function(LsnModalHelper, lsnModelFactory, IhestiaClauseListHelper, IhestiaLifeInsurerChangeFlowConstants, clausesContextsSvc, ihestiaFromRestClauseMapper, ihestiaLabelHelper, $filter, LsnIdentityDocumentModelConstants, $timeout, LsnClausesModelConstants) {
      var IhestiaLifePersonSearchModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;
        this.PERSON = 'Person';
        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'lg',
          title: ['policyDetails.insurerChange.modalTitle', {componentCode: 'Public'}],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/insurerChange/clientEdit/clientEditModal.tpl.html',
          okBtnName: ['Public.confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          withDefaultMargin: false
        };

        this.setOptions(this.modalOptions);

        this.modalData = {
          subject: null,
          checkClausesOnSubject: null
        };
        /**
         * @typedef ClientEditModalTplData
         * @property {'Person' | 'Organization' | null} subjectType
         * @property {LsnPersonModelV2 | LsnOrganizationModelV2} subject
         * @property {IhestiaClauseListHelper} clauseListHelper
         * @property {{api: PersonEditFormApi, showElements: PersonEditFormShowElements | null}} personEditForm
         * @property {boolean | null} clausesLoaded
         */

        /**
         *
         * @type {ClientEditModalTplData}
         */
        this.tplData = {
          subjectType: null,
          subject: null,
          clauseListHelper: null,
          personEditForm: {
            api: null,
            showElements: null,
            disabledElements: null,
            labels: {
              mainAddress: null
            }
          },
          clausesLoaded: null,
          isOrganization: null
        };

        this.init = function() {
          // on return from additional data - initialize form from previous data?
          self.tplData.subject = self.modalData.subject ? _.cloneDeep(self.modalData.subject) : lsnModelFactory.getObject('Person', 'v2');
          self.tplData.subjectType = _.get(self.modalData.subject, 'metaData.className', 'Person');
          self.tplData.isOrganization = self.tplData.subjectType === 'Organization';
          self._initPersonEditForm();
          self._initClauses(self.modalData.subject ? self.modalData.subject.clauses : undefined);
        };

        this._initPersonEditForm = function() {
          self.tplData.personEditForm.api = {};
          self.tplData.personEditForm.showElements = {
            document: true,
            citizenshipCode: true,
            maidenName: true,
            drivingLicenceIssueDate: false,
            mainAddressCountryCode: true,
            nipAndRegon: false,
            postalCodeMask: true,
            personalDataForm: self.tplData.subjectType === self.PERSON,
            personalDataFormHeader: false
          };
          var subjectFromRegistry = !!_.get(self.modalData.subject, 'metaData.id');
          self.tplData.personEditForm.disabledElements = {
            pesel: subjectFromRegistry,
            birthDate: subjectFromRegistry,
            gender: subjectFromRegistry,
            citizenshipCode: subjectFromRegistry
          };
          self._setDefaultPersonData();
        };

        /**
         *
         * @param {[Object.<string, string>]} subjectClauses
         * @private
         */
        this._initClauses = function(subjectClauses) {
          self.tplData.clausesLoaded = false;
          clausesContextsSvc.get('PolicyholderReplacement/clauses').then(function(res) {
            var language = ihestiaLabelHelper.selectedLanguage ? ihestiaLabelHelper.selectedLanguage : 'pl-PL';
            var clauseList = _.chain(_.get(res.data, 'clauses', []))
              .map(ihestiaFromRestClauseMapper.mapPartnerClause(language, false))
              // navigated from additional data, check subject clauses for selection
              .map(self.modalData.checkClausesOnSubject && _.isArray(self.tplData.subject.clauses)
                ? self._checkEditedSubjectClauses(self.tplData.subject.clauses) : self._checkRegistrySubjectClauses(subjectClauses))
              .value();
            self.tplData.clauseListHelper = new IhestiaClauseListHelper({clauseList: clauseList});
            self.tplData.clausesLoaded = true;
          }, lsnNg.noop);
        };

        /**
         * set data based on selected subject type
         */
        this.onChangeSubjectType = function() {
          var isPerson = self.tplData.subjectType === self.PERSON;
          self.tplData.personEditForm.showElements.personalDataForm = isPerson;
          self.tplData.personEditForm.showElements.errors = false;
          self.tplData.personEditForm.api.validateAutoCompleters(true);
          self.tplData.subject = lsnModelFactory.getObject(isPerson ? 'Person' : 'Organization', {
            version: 'v2',
            data: isPerson && self.modalData.subject ? _.cloneDeep(self.modalData.subject) : undefined
          }).getData();
          self.tplData.personEditForm.labels.mainAddress = isPerson
            ? null : $filter('translate')('policyData.clientEdit.organizationMainAddress', {componentCode: 'Public'});
          self.tplData.personEditForm.labels.copySecondAddress = isPerson
            ? null : $filter('translate')('policyData.clientEdit.organizationCopySecondAddress', {componentCode: 'Public'});
          if (isPerson) {
            self._setDefaultPersonData();
          }
          $timeout(function() {
            self.tplData.personEditForm.api.refreshSubjectData();
          }, 0);
        };

        this.onChooseOtherClient = function() {
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.PERSON_SEARCH,
            value: null
          });
          return self.hideModal();
        };

        this.confirm = function() {
          if (!self.validateRequiredFields()) {
            return null;
          }
          var personEditData = self.tplData.personEditForm.api.getSubjectData();
          personEditData.clauses = self.tplData.clauseListHelper.getClauses();
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.ADDITIONAL_DATA,
            value: personEditData
          });
          return self.hideModal();
        };

        this.cancel = function() {
          self.setPromiseAsResolved({
            type: IhestiaLifeInsurerChangeFlowConstants.CLOSE,
            value: null
          });
          return this.hideModal();
        };

        this._setDefaultPersonData = function() {
          // initialize citizenship
          self.tplData.subject.citizenshipCode = self.tplData.subject.citizenshipCode || 'PL';
          // default document
          if (_.isEmpty(self.tplData.subject.documents)) {
            self.tplData.subject.documents = [{
              code: LsnIdentityDocumentModelConstants.IDENTITY_DOCUMENT_CODE_DOOS,
              number: null
            }];
          }
        };

        /**
         * Read clause selection from subject clauses, which were previously edited in this modal
         * invoked when returning from additional data modal
         * @param {any[]} subjectClauses
         * @returns {function(any): any}
         * @private
         */
        this._checkEditedSubjectClauses = function(subjectClauses) {
          return function(frkClause) {
            var subjectClause = _.find(subjectClauses, ['code', frkClause.code]);
            if (subjectClause) {
              frkClause.value = subjectClause.value;
              frkClause.selected = subjectClause.selected;
            }
            return frkClause;
          };
        };

        /**
         * When selecting subject from registry, it has information about clause selection
         * if subjectClauseObject is not provided, noop function is returned
         * @param {[Object.<string, string>]} subjectClauseObject
         * @returns {function(any): any}
         * @private
         */
        this._checkRegistrySubjectClauses = function(subjectClauseObject) {
          if (!subjectClauseObject) {
            return lsnNg.noop;
          }
          var mappedClauseObject = _.chain(subjectClauseObject)
            .toPairs()
            .map(function(pair) {
              return [_.toLower(pair[0]), pair[1]];
            })
            .fromPairs()
            .value();
          return function(frkClause) {
            var registryClauseValue = frkClause.code ? mappedClauseObject[_.toLower(frkClause.code)] : null;
            if (registryClauseValue) {
              frkClause.value = registryClauseValue;
              frkClause.selected = registryClauseValue === LsnClausesModelConstants.CLAUSE_VALUE_AGREEMENT;
            }
            return frkClause;
          };
        };

        this.validateRequiredFields = function() {
          self.tplData.personEditForm.showElements.errors = true;
          var clausesValid = self.tplData.clauseListHelper.validateRequiredClauses();
          if (self.tplData.personEditForm.api.formRef.$invalid || !clausesValid) {
            self.tplData.personEditForm.api.validateAutoCompleters();
            return false;
          }
          return true;
        };

      };
      return new IhestiaLifePersonSearchModalHelper();
    }]);
