angular.module('ihestiaRestServicesBase')
/**
 * obiekt który dostajemy na liście użytkowników, przykładowo w zarządzaniu użytkownikami
 */
.factory('IHestiaUserListResponseModel', [ 'LsnAbstractModel', function(LsnAbstractModel) {

    var IHestiaUserResponseModel = function()
    {
      this.objectName = 'UserResponse';

      this.fieldsProperties = {
        canRelog: {
          type: 'bool'
        },
        characterName: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        },
        login: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        salesProfileLife: {
          type: 'string'
        },
        salesProfileProperty: {
          type: 'string'
        },
        salesProfileLifeName: {
          type: 'string'
        },
        salesProfilePropertyName: {
          type: 'string'
        },
        userType: {
          type: 'string'
        },
        userTypeDesc: {
          type: 'string'
        },
        inproperPesel: {
          type: 'bool'
        },
        phone: {
          type: 'string'
        },
        accountRetrieveMode: {
          type: 'string'
        },
        alias: {
          type: 'string'
        },
        locked: {
          type: 'string'
        },
        roles: {
          type: 'array',
          elementsType: 'mixed'
        },
        atoms: {
          type: 'array',
          elementsType: 'mixed'
        },
        lockReason: {
          type: 'string'
        },
        lockReasonDesc: {
          type: 'string'
        },
        characterType: {
          type: 'string'
        },
        characterTypeDesc: {
          type: 'string'
        },
        status: {
          type: 'string'
        },
        statusDesc: {
          type: 'string'
        },
        personName: {
          type: 'string'
        },
        blockType: {
          type: 'string'
        },
        characterStatus: {
          type: 'bool'
        },
        characterStatusDesc: {
          type: 'string'
        },
        icOrganizationUnit: {
          type: 'string'
        },
        salesProfile: {
          type: 'string'
        },
        worker: {
          type: 'string'
        },
        icDescription: {
          type: 'string'
        },
        createDate: {
          type: 'dateTime'
        },
        isCharacterDefault: {
          type: 'bool'
        },
        isCharacterDefaultDesc: {
          type: 'string'
        },
        propertyProfileDate: {
          type: 'string'
        },
        lifeProfileDate: {
          type: 'string'
        },
        propertyWorkerDate: {
          type: 'string'
        },
        lifeWorkerDate: {
          type: 'string'
        },
        characterMembers: {
          type: 'array',
          elementsType: 'string'
        },
        characterMembersDesc: {
          type: 'string'
        },
        options: {
          type: 'array',
          elementsType: 'string'
        },
        characterDefaults: {
          type: 'array',
          elementsType: 'string'
        }
      };

      this.canRelog = null; // bool
      this.characterName = null; // string
      this.email = null; // string
      this.firstName = null; // string
      this.lastName = null; // string
      this.login = null; // string
      this.pesel = null; // string
      this.salesProfileLife = null; // string
      this.salesProfileProperty = null; // string
      this.salesProfileLifeName = null; // string
      this.salesProfilePropertyName = null; // string
      this.userType = null; // string
      this.userTypeDesc = null; // string
      this.inproperPesel = null; // bool
      this.phone = null; // string
      this.accountRetrieveMode = null; // string
      this.alias = null; // string
      this.locked = null; // string
      this.roles = null; // array role
      this.atoms = null; // array uprawnienia
      this.lockReason = null; //string przyczyna zablokowania
      this.lockReasonDesc = null; //string przyczyna zablokowania - opis
      this.characterType = null; //string typ charakteru
      this.characterTypeDesc = null; //string typ charakteru
      this.status = null; //string status konta
      this.statusDesc = null; //string opisowy status konta
      this.personName = null; //string
      this.blockType = null; //string
      this.characterStatus = null; //bool
      this.characterStatusDesc = null; //opisowa wartość powyższego
      this.icOrganizationUnit = null; //string
      this.salesProfile = null; //string
      this.worker = null; //string
      this.icDescription = null; //string
      this.createDate = null; //dateTime
      this.isCharacterDefault = null; //bool
      this.isCharacterDefaultDesc = null; //string
      this.propertyProfileDate = null; //dateTime
      this.lifeProfileDate = null; //dateTime
      this.propertyWorkerDate = null; //dateTime
      this.lifeWorkerDate = null; //dateTime
      this.characterMembers = null; // array member
      this.characterMembersDesc = null; //string member

      this.options = null; //array - dostępne akcje
      this.characterDefaults = null; // array - domyślność charakteru ihestia/jupiter
    };


    IHestiaUserResponseModel.prototype = LsnAbstractModel.prototype;

    return IHestiaUserResponseModel;
  }]);