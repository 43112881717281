angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardOperatorChooserHelper', [function(){
    var OperatorChooserHelper = function(){

      /**
       * czy mamy już wczytane dane do selectów
       * @param  {Object} operatorChooserData
       * @return {bool}
       */
      this.elementsLoaded = function(operatorChooserData)
      {
        var requiredElements = [];
        var requiredElementsLoaded = true;
        if(operatorChooserData.modalData.receiverType === 'Central')
        {
          requiredElements = [];
        }
        else if(operatorChooserData.modalData.receiverType === 'Worker')
        {
          requiredElements = ['salesProfiles'];
        }
        angular.forEach(requiredElements, function(elementName){
          if(!operatorChooserData.loaded[elementName])
          {
            requiredElementsLoaded = false;
          }
        });

        return requiredElementsLoaded;
      };
    };

    return new OperatorChooserHelper();
  }]);