angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardSaleSubjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardSubjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardSubjectModelConstants) {

      var SaleSubjectModel = function() {
        this.objectName = 'IhestiaDashboardSaleSubjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          name: {
            type: 'string'
          },
          type: {
            type: 'string'
          },
          saleProfileId: {
            type: 'string'
          },
          representativeId: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.name = null;
        this.type = IhestiaDashboardSubjectModelConstants.TYPE_SALE_PROFILE;
        this.saleProfileId = null;
        this.representativeId = null;

      };


      SaleSubjectModel.prototype = IHestiaAbstractModel.prototype;

      return SaleSubjectModel;
    }]);