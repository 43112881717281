angular.module('uiRouterFreeze', [
  'ui.router'
]).config(['$stateProvider',
function($stateProvider){
    $stateProvider
    .state('uiRouterFreeze', {
      url: '/uirouterfailed',
      views: {
        'main': {
          templateUrl: 'ihestia-commondirectives-template-app/uiRouterFreeze/uiRouterFreeze.tpl.html',
          controller: 'uiRouterFreezeCtrl'
        }
      }
    }
  );
}])
.run(['uiRouterFreezeHelper',
  function(uiRouterFreezeHelper) {
    uiRouterFreezeHelper.init();
  }]);