angular.module('salesPath2')
  .service('dcCommonHelper', ['CONFIG', 'RESOURCES', 'CONSTANTS', 'sp2CommonHelper', 'resourceHelper', 'lsnDeepExtend', 'mainDataContainer', 'appVariables',
    function(CONFIG, RESOURCES, CONSTANTS, sp2CommonHelper, resourceHelper, lsnDeepExtend, mainDataContainer, appVariables) {
      var DcCommonHelper = function() {
        var self = this;

        /**
         * inicjalizacja dataContainera
         */
        this.init = function() {
          //dostpenosc produktów - domyslnie wszystkie dostepne
          angular.forEach(RESOURCES.PRODUCTLIST, function(def) {
            mainDataContainer.allowedProducts[def.CODE] = true;
          });
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant) {
            mainDataContainer.allowedProducts[CONSTANTS.PRODUCT_OGI_KRA] = mainDataContainer.allowedProducts[CONSTANTS.PRODUCT_OGI];
          }
          //DODATKI
          if (CONFIG.MODULES.additions) {
            mainDataContainer.selectedAdditions = angular.copy(RESOURCES.SELECTED_ADDITIONS);
          }
          //ROZSZERZENIA
          if (CONFIG.MODULES.extensions) {
            mainDataContainer.selectedExtensions = angular.copy(RESOURCES.SELECTED_EXTENSIONS);
          }
          //domyślne zaznaczenia dla wariantów
          mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_VEHICLE][CONSTANTS.NO_OBJECT] = resourceHelper.getDefaultVariantsForType(CONSTANTS.PRODUCT_TYPE_VEHICLE);
          mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_LOCALIZATION][CONSTANTS.NO_OBJECT] = resourceHelper.getDefaultVariantsForType(CONSTANTS.PRODUCT_TYPE_LOCALIZATION);
          if (CONFIG.BEHAVIOR.personGroups) { //przypadek matryca glowna per kazda osoba i grupa osob
            mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][CONSTANTS.NO_OBJECT] = resourceHelper.getDefaultVariantsForType(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP);
          }
          //typowe osobowe ryzyka: takie jak oczp, nnw w e1/e7 itd.
          lsnDeepExtend(mainDataContainer.selectedVariants, resourceHelper.getDefaultVariantsForType(CONSTANTS.PRODUCT_TYPE_PERSON));
        };

        /**
         * ustawia domyślną konfigurację zaznaczeń wariantów dla podanego obiektu
         * @param {String} objectType typ obiektu
         * @param {String} objectId clientId obiektu
         */
        this.setDefaultVariantsForObject = function(objectType, objectId) {
          mainDataContainer.selectedVariants[objectType][objectId] = resourceHelper.getDefaultVariantsForType(objectType);
        };

        /**
         * pobranie obiektu lub podmiotu, konkretnego typu po id lub lcientId
         * @param {string} objectType typ obiektu (jeden z kluczy OBJECT_TYPE_TO_CONTAINER_NAME)
         * @param {*} objectId id obiektu
         * @param {string} [idType='clientId'] typ id (id|clientId)
         * @return {*} null lub obiekt, gdy znaleziono
         */
        this.getObject = function(objectType, objectId, idType) {
          if (!angular.isString(idType)) {
            idType = 'clientId';
          }
          if (idType === 'clientId') {
            return self.getObjects(objectType)[objectId] || null;
          }
          var foundObj = null;
          lsnNg.forEach(self.getObjects(objectType), function(obj) {
            if (obj.metaData.get('id') === objectId) {
              foundObj = obj;
              return false;
            }
            return true; //continue
          });
          return foundObj;
        };

        /**
         * zwraca obiekty z dataContainera określonego typu
         * @param  {String} objectType typ
         * @return {Object} []clientId: obiekt
         */
        this.getObjects = function(objectType) {
          var container = {};
          if (angular.isArray(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType])) {
            angular.forEach(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType], function(name) {
              angular.extend(container, mainDataContainer[name]);
            });
          } else {
            container = mainDataContainer[CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[objectType]];
          }
          if (objectType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) { //dla typu personGroup musimy usunac obiekty niewystepujace w selectedVariants
            angular.forEach(container, function(obj, clientId) {
              if (angular.isUndefined(mainDataContainer.selectedVariants[CONSTANTS.PRODUCT_TYPE_PERSON_GROUP][clientId])) {
                delete container[clientId];
              }
            });
          }
          return container;
        };

        /**
         * zwraca numer danego obiektu zaczynając od 1
         * @param  {string} objectType  typ obiektu
         * @param  {int} objectId       id obiektu
         * @return {int}                numer obiektu
         */
        this.getObjectNumber = function(objectType, objectId) {
          var objects = self.getObjects(objectType);
          var number = 0;
          lsnNg.forEach(objects, function(object) {
            number += 1;
            if (parseInt(object.metaData.get('clientId'), 10) === parseInt(objectId, 10)) {
              return false;
            }
            return true; //continue
          });
          return number;
        };

        /**
         * ustawia numer kalkulacji/wniosku
         * @param {String} number
         */
        this.setCalculationNumber = function(number) {
          mainDataContainer.application.set('number', number);
        };

        /**
         * zwraca clientId dla obiektu/podmiotu na podstawie podanego id
         * @param  {String} objectType
         * @param  {String} id
         * @return {String}
         */
        this.getClientId = function(objectType, id) {
          var objects;
          switch (objectType) {
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
              objects = mainDataContainer.vehicles;
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON:
              objects = mainDataContainer.persons;
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
              objects = angular.extend({}, mainDataContainer.persons, mainDataContainer.groups);
              break;
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              objects = mainDataContainer.localizations;
              break;
            case CONSTANTS.OBJECT_TYPE_ORGANIZATION:
              objects = mainDataContainer.organizations;
              break;
            default:
              sp2CommonHelper.throwException('Nieobsługiwany typ obiektu: ' + objectType);
          }

          var clientId = null;
          lsnNg.forEach(objects, function(object) {
            if ((object.metaData.get('id') + '') === (id + '')) {
              clientId = object.metaData.get('clientId');
              return false;
            }
            return true; //continue
          });
          if (clientId === null) {
            sp2CommonHelper.throwException('Nie znaleziono obiektu, typ: ' + objectType + ' id: ' + id);
          }
          return clientId;
        };

        /**
         * zwraca polisę po numerze
         * @param  {String} number
         * @return {PolicyModel|null} null gdy nieznaleziono
         */
        this.getPolicyByNumber = function(number) {
          var foundPolicy = null;
          lsnNg.forEach(mainDataContainer.policies, function(policy) {
            if (policy.get('number') === number) {
              foundPolicy = policy;
              return false;
            }
            return true; //continue
          });
          return foundPolicy;
        };

        /**
         * zwraca polise dla zadanego obiektu (pojazdu/lokalizacji itd.)
         * @param {string} objectType typ obiektu (jeden z kluczy OBJECT_TYPE_TO_POLICY_CONTAINER_NAME)
         * @param {int} objectId metaData.id obiektu
         * @param {string} [idType='id'] typ identyfikatora - id lub clientId (domyslnie id)
         * @param {Object} [policiesContainer=mainDataContainer.policies] kontener z polisami (domyslnie policies z DM)
         * @return {PolicyModel|null} polisa lub null gdy nieznaleziono
         */
        this.getPolicyByObjectId = function(objectType, objectId, idType, policiesContainer) {
          var foundPolicy = null;
          if (!angular.isString(idType)) {
            idType = 'id';
          }
          if (!angular.isObject(policiesContainer)) {
            policiesContainer = mainDataContainer.policies;
          }
          lsnNg.forEach(policiesContainer, function(policy) {
            if (policy.get(CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[objectType]).length === 0) {
              return true;
            }
            lsnNg.forEach(policy[CONSTANTS.OBJECT_TYPE_TO_POLICY_CONTAINER_NAME[objectType]], function(obj) {
              if (obj.metaData.get(idType) === objectId) {
                foundPolicy = policy;
                return false;
              }
              return true; //continue
            });
            if (foundPolicy !== null) {
              return false;
            }
            return true; //continue
          });
          return foundPolicy;
        };

        /**
         * get payment method from first policy - used for bad client were paymentMethod is one for all
         * @return {string}
         */
        this.getPaymentMethod = function() {
          if(mainDataContainer.policies[0]) {
            return mainDataContainer.policies[0].get('paymentMethod');
          } else {
            return null;
          }
        };

        /**
         * get all policies with BLIK chosen as payment method
         * @return {LsnPolicyModelV1[]}
         */
        this.getBlikPaymentPolicies = function() {
          return _.filter(mainDataContainer.policies, function(pol) {
            return pol.get('paymentMethod') === CONSTANTS.PAYMENT_METHOD_TRANSFER && angular.isObject(pol.product) && angular.isObject(pol.product.dynamicValues) && pol.product.dynamicValues._isBlikPayment;
          });
        };

        /**
         * konwertuje wartość atrybutu definicyjnego typu Table na obiekt
         * @param {*} value
         * @returns {Object}
         */
        this.convertDefinictionTableAttributeToArray = function(tableAttribute) {
          if (!angular.isObject(tableAttribute)) {
            return null;
          }

          var columns = {};
          angular.forEach(tableAttribute.columnList, function(column) {
            columns[column.symbol] = column;
          });

          var defaultValues = [];
          angular.forEach(tableAttribute.defaultValue, function(oneDefaultValue) {
            //oneDefaultValue.symbol
            angular.forEach(oneDefaultValue.valueList, function(value, index2) {
              //columns[oneDefaultValue.symbol] == 'Decimal'
              var valueName = columns[oneDefaultValue.symbol].valueType.toLowerCase() + 'Value'; //decimalValue
              if (typeof defaultValues[index2] === 'undefined') {
                defaultValues[index2] = {};
              }
              defaultValues[index2][oneDefaultValue.symbol] = value[valueName];
            });
          });

          return defaultValues;
        };

        /**
         * zwraca listę wybranych wariantów dla produktu i obiektu (pojazdu/lokalizacji/osoby-grupy), domyślnie wybrana
         * @param  {string} productCode [description]
         * @param  {[type]} [objectId]    id obiektu/podmiotu. UWAGA: jesli nie podano i produkt osobowy to rozpatrujemy dla dowolnej osoby (kompatybilnosc wstecz)
         * @return {[type]}             [description]
         */
        this.getVariantsForProduct = function(productCode, objectId) {
          var productType = resourceHelper.productType[productCode];
          var selectedVariants = {};
          if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION || productType === CONSTANTS.PRODUCT_TYPE_VEHICLE || productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) //produkty powiązane z lokalizacjami lub z pojazdami lub osobami/grupami
          {
            if (angular.isUndefined(objectId)) {
              objectId = self.getSelectedObjectId(productType);
            }

            if (objectId === CONSTANTS.ALL_OBJECTS) //wszystkie lokalizacje/pojazdy
            {
              angular.forEach(mainDataContainer.selectedVariants[productType], function(products, elementId) {
                if (!self._objectExists(productType, elementId)) {
                  return;
                }
                if (products[productCode] !== null) {
                  if (angular.isUndefined(selectedVariants[products[productCode]])) {
                    selectedVariants[products[productCode]] = [];
                  }
                  selectedVariants[products[productCode]].push(self.getObjectNumber(productType, elementId));
                }
              });
            } else if (angular.isDefined(mainDataContainer.selectedVariants[productType][objectId]) && mainDataContainer.selectedVariants[productType][objectId][productCode] !== null) {
              selectedVariants[mainDataContainer.selectedVariants[productType][objectId][productCode]] = true;
            }
          } else //produkty powiązane z osobami
          {
            if (angular.isUndefined(objectId) || objectId === CONSTANTS.ALL_OBJECTS) {
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) === -1) //możliwy do wyboru tylko jeden wariant na raz
              {
                if (mainDataContainer.selectedVariants[productCode] !== null) {
                  selectedVariants[mainDataContainer.selectedVariants[productCode]] = true;
                }
              } else {
                angular.forEach(mainDataContainer.selectedVariants[productCode], function(selected, variant) {
                  if (selected) {
                    selectedVariants[variant] = selected;
                  }
                });
              }
            } else if (angular.isDefined(mainDataContainer.persons[objectId])) { //dla konkretnrj osoby
              var riskAdditionalData = mainDataContainer.persons[objectId].getAdditionalData(productCode);
              if (riskAdditionalData === null) //osoba nie została dodana do ryzyka
              {
                return {};
              }
              if (CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(productCode) !== -1) //możliwe wiele wariantów
              {
                lsnNg.forEach(riskAdditionalData.variants, function(selected, vnt) {
                  if (selected) {
                    selectedVariants[vnt] = true;
                  }
                  return true; //continue
                });
              } else if (riskAdditionalData.variants !== null) {
                selectedVariants[riskAdditionalData.variants] = true;
              }
            }
          }
          return selectedVariants;
        };

        /**
         * czy obiekt istnieje w dataContainerze
         * @param  {String} type     jeden z CONSTANTS.PRODUCT_TYPE_...
         * @param  {String} clientId clientId obiektu
         * @return {Boolean} true gdy istnieje
         */
        this._objectExists = function(type, clientId) {
          var exists = false,
            containers = angular.isArray(CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[type]) ? CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[type] : [CONSTANTS.OBJECT_TYPE_TO_CONTAINER_NAME[type]];

          lsnNg.forEach(containers, function(cnt) {
            if (mainDataContainer[cnt][clientId]) {
              exists = true;
              return false;
            }
            return true;
          });
          return exists;
        };

        /**
         * zwraca stringową wartość dla identyfikatora (np. metaData.id lub metaData.clientId)
         * @param  {*} id
         * @return {String}
         */
        this.idToString = function(id) {
          if (angular.isString(id)) {
            return id;
          } else if (id === null) {
            return '';
          } else {
            return id + '';
          }
        };

        /**
         * ustawia dynamic values na wszystkich polisach/pakietach w dataContainer.policies
         * @param {Object} dynVals
         */
        this.setPoliciesDynamicValues = function(dynVals) {
          angular.forEach(mainDataContainer.policies, function(policy) {
            policy.product.set('dynamicValues', dynVals);
          });
        };

        /**
         * zwraca ubezpieczajacego lub null gdy nie znaleziono
         * @return {PersonModel|null}
         */
        this.getInsurer = function() {
          var objs = self.getObjects(CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION);
          if (angular.isUndefined(objs[mainDataContainer.insurerId])) {
            return null;
          } else {
            return objs[mainDataContainer.insurerId];
          }
        };

        /**
         * zwraca glownego ubezpieczonego
         * @return {PersonModel|null} null gdy nie znaleziono
         */
        this.getMainInsured = function() {
          var objs = self.getObjects(CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION);
          if (angular.isUndefined(objs[mainDataContainer.mainInsuredId])) {
            return null;
          } else {
            return objs[mainDataContainer.mainInsuredId];
          }
        };

        /**
         * czy wybrano glowny obiekt ubezpieczenia - uzywane np. w produktach solo oc/md
         * @return {Boolean} gdy nie określono co jest głównym obiektem ubezpieczenia, to zwracamy true.
         */
        this.isMainObjectChosen = function() {
          var chosen;
          switch (CONFIG.BEHAVIOR.mainInsuredObject) {
            case CONSTANTS.MAIN_INSURED_OBJECT_VEHICLE:
              chosen = !angular.equals(mainDataContainer.vehicles, {});
              break;
            case CONSTANTS.MAIN_INSURED_OBJECT_ESTATE:
              chosen = !angular.equals(mainDataContainer.localizations, {});
              break;
            default: //domuslnie uznajemy ze wybrano
              chosen = true;
          }
          return chosen;
        };

        /**
         * zwraca clientId dla wybranego podmiotu/obiektu dla określonego typu produktu
         * @param  {String} productType
         * @return {String|null} null gdy nieznaleziono
         */
        this.getSelectedObjectId = function(productType) {
          if (productType === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) {
            return mainDataContainer.selectedLocalization;
          } else if (productType === CONSTANTS.PRODUCT_TYPE_VEHICLE) {
            return mainDataContainer.selectedVehicle;
          } else if (productType === CONSTANTS.PRODUCT_TYPE_PERSON_GROUP) {
            return mainDataContainer.selectedSubject;
          } else {
            return null;
          }
        };

        /**
         * zwraca obiekt z identyfikatorami osob oraz danymi dotyczacymi zaznaczen wariantow ryzyk osobowych dla tych osob
         * @param  {[type]} productCode [description]
         * @return {Object} {clint_id_osoby: dane_ryzyka[,...]}, gdzie dane_ryzyka to person.getAdditionalData('kod_produktu/ryzyka, np OCZP')
         */
        this.getPersonsForPersonalProduct = function(productCode) {
          var productPersons = {};
          if (angular.isDefined(CONFIG.BEHAVIOR.mainInsuredOnlyRisks) && CONFIG.BEHAVIOR.mainInsuredOnlyRisks.indexOf(productCode) !== -1) //ryzyko możliwe tylko na głównym ubezpieczonym
          {
            if (mainDataContainer.persons[mainDataContainer.mainInsuredId].getAdditionalData(productCode) !== null) {
              productPersons[mainDataContainer.mainInsuredId] = mainDataContainer.persons[mainDataContainer.mainInsuredId].getAdditionalData(productCode);
            }
          } else {
            angular.forEach(mainDataContainer.persons, function(person, personId) {
              if (person.getAdditionalData(productCode) !== null) {
                productPersons[personId] = person.getAdditionalData(productCode);
              }
            });
          }

          return productPersons;
        };

        /**
         * zwraca warianty dla procuktu/obiektu zaznaczone na poprzedniej polisie
         * @param  {String} productCode kod produktu
         * @param  {String} [objectId] id obiektu. Jesli nie podano, to pobieramy aktualne zaznaczenie z dataContainera
         * @return {Object} pusty ociekt ({}) jesli brak zaznaczeń, lub {kod_wariantu: true}
         */
        this.getPreviousVariantsForProduct = function(productCode, objectId) {
          objectId = objectId || null;
          var productType = resourceHelper.productType[productCode];
          var selectedVariants = {};

          if (objectId === null) {
            objectId = self.getSelectedObjectId(productType);
          }

          //sprawdzamy nnwIncogntio dla NNW w przypadku gdy nie weryfikujemy dla konkrentgo obiektu
          var checkNnwIncognito = objectId === null && productCode === CONSTANTS.PRODUCT_NNW;
          if (angular.isUndefined(mainDataContainer.previousRisksSelection[productType])) {
            return {};
          }
          if (angular.isObject(mainDataContainer.previousRisksSelection[productType][productCode])) {
            selectedVariants = self._getPreviousVariantsForObject(mainDataContainer.previousRisksSelection[productType][productCode], objectId);

            angular.forEach(selectedVariants, function(sel, variant) {
              if (!sel) { //uswamy wpisy z niewybranymi wariantmi, aby spelnic wymogi odpowiedzi z niniejszej metody
                delete selectedVariants[variant];
              }
            });
          }

          if (checkNnwIncognito) {
            var selectedVariantsNnwInco = self._getPreviousVariantsForObject(mainDataContainer.previousRisksSelection[productType][CONSTANTS.PRODUCT_NNW_INCOGNITO], objectId);
            if (selectedVariantsNnwInco[CONSTANTS.VARIANT_I]) {
              selectedVariants[CONSTANTS.VARIANT_I] = true;
            }
          }
          return selectedVariants;
        };

        /**
         * [isNewPersonPerRisk czy osoba jest nowa na wniosku per Ryzyko]
         * @param  {[type]}  personId    [description]
         * @param  {[type]}  productCode [description]
         * @return {Boolean}             [description]
         */
        this.isNewPersonPerRisk = function(personId, productCode) { //eslint-disable-line consistent-return
          if (appVariables.isSupplement) {
            //aktualnie sprawdzamy usuwalnosc osob tylko w trybie dokupien
            return angular.equals(self.getPreviousVariantsForProduct(productCode, personId), {});
          }
        };

        /**
         * zwraca obiekt z informacjami o wybranych poprzednio wariantach dla danego obiektu
         * @param  {Object} productVariants wycinek obiektu mainDataContainer.previousRisksSelection dla konkretnego procuktu (obiekt z wariantami i występującymi w nich obiektami)
         * @param  {String} objId           clientId obiektu, dla którego sprawdzamy zaznaczenie lub CONSTANTS.ALL_OBJECTS dla wszystkich obiektów razem
         * @return {Object}                 obiekt postaci {variant: true|false[,...]}, gdzie variant to kod wariantu a true|false oznacza czy wybrany czy nie
         */
        this._getPreviousVariantsForObject = function(productVariants, objId) {
          objId = objId || CONSTANTS.ALL_OBJECTS; //jesli nie okreslono id obiektu, to sprawdzamy dla dowolnego
          var selected = {};
          angular.forEach(productVariants, function(objIds, variant) {
            if ((objId === CONSTANTS.ALL_OBJECTS && objIds.length > 0) || objIds.indexOf(objId) !== -1) {
              selected[variant] = true;
            } else {
              selected[variant] = false;
            }
          });
          return selected;
        };

        /**
         * [anyClientPolicyExist sprawdza czy na wniosku wystepuje jakakolwiek polisa klienta]
         * @return {Boolean}          [description]
         */
        this.anyClientPolicyExist = function() {
          var onClientPolicy = false;
          angular.forEach(mainDataContainer.policies, function(policy) {
            var policyDynamicValues = policy.product.dynamicValues;
            if (angular.isObject(policyDynamicValues) && policyDynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT) {
              onClientPolicy = true;
            }
          });

          return onClientPolicy;
        };

        /**
         * [isNewPersonOnApp sprawdza czy osoba jest nowa na wniosku]
         * @param  {[type]}  clientId [description]
         * @return {Boolean}          [description]
         */
        this.isNewPersonOnApp = function(clientId) {
          var isNew = true;
          if (appVariables.isSupplement) {
            angular.forEach(mainDataContainer.allowedChanges.subjects.persons, function(person) {
              if (person.metaData.clientId === clientId) {
                isNew = person.isNew;
              }
            });
          }

          return isNew;
        };

        /**
         * czy istnieje polisa z podanym atrybutem - dynamicValue
         * @param  {String}  name nazwa atrybutu
         * @param  {*}  val  wartość atryburu
         * @return {Boolean}
         */
        this.isPolicyWithDynamicValue = function(name, val) {
          var found = false;
          lsnNg.forEach(mainDataContainer.policies, function(policy) {
            if (policy.product.dynamicValues && policy.product.dynamicValues[name] === val) {
              found = true;
              return false;
            }
            return true;
          });
          return found;
        };

        /**
         * set declared client's roles
         * @param {string[]} roles where string is one of CONSTANTS.PERSON_ROLE_...
         */
        this.setDeclaredClientRoles = function(roles) {
          mainDataContainer.declaredClientRoles = roles;
        };

        this.getApplicationStatus = function() {
          return mainDataContainer.application.get('status');
        };

        this.getPunuNumber = function() {
          return mainDataContainer.application.get('dynamicValues').numberPUNU;
        };

        this.getSelectedRisks = function() {
          return mainDataContainer.application.get('risks').map(function(risk) {
            return risk.metaData.id.split('__')[0];
          });
        };

        this.setProductClauses = function(productClauses) {
          mainDataContainer.productClauses = productClauses;
        };

        this.getProductClauses = function() {
          return mainDataContainer.productClauses ? mainDataContainer.productClauses : {};
        };

        this.setDestinations = function(destinations) {
          mainDataContainer.destinations = destinations;
        };

        this.getDestinations = function() {
          return mainDataContainer.destinations ? mainDataContainer.destinations : {};
        };

      };

      return new DcCommonHelper();
    }
  ]);