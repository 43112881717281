//obsługa wczytywania słowniów do podglądu wniosku/polisy
angular.module('salesPath2')
  .service('applicationDictionaryHelper', ['lsnArrayUnique', 'dictionaryServiceHelper', 'commonServiceHelper',
    function (lsnArrayUnique, dictionaryServiceHelper, commonServiceHelper) {
      var ApplicationDictionaryHelper = function () {
        var self = this;
        /**
         * wczytane na wniosku kody pkd wraz z opisami
         * @type {Object}
         */
        this.loadedPkd = {};
        /**
         * czy trwa wołanie usługi/usług (w wypadku gdy wywolano kilka jednoczesnie false ustawi sie dopiero po nadejsciu wszystkich odpowiedzi)
         * @type boolean
         */
        this.callInProgress = false;
        /**
         * tablica GUID-ów requestów które są w trakcie wołania (nie zwrocily jeszcze responsów)
         * @type Array.<string>
         */
        this.requestsInProgress = [];
        /**
         * callback, który ma być wywołany po wczytaniu wszystkich slownikow (zmianaflagi callInProgress z true na false)
         * @type null|function
         */
        this.responseCallback = null;

        /**
         * wczytuje do datamanagera wartości słownikowe niezbedne do wyswietlenia polis
         * @param {Policy[]} policies polisay
         * @param {function=} callback opcjonalny callback po wczytaniu słowników
         */
        this.loadDictionariesForPolicies = function (policies, callback) {
          //kody pkd
          var pkdsToLoad = [];
          angular.forEach(policies, function (policy) {
            var policyPkd = policy.get('pkd'),
              policyDynVals = policy.product.get('dynamicValues');

            if (policyPkd !== null && policyPkd !== '') {
              pkdsToLoad.push(policyPkd);
            }

            if (angular.isDefined(policyDynVals.insurerPKDList) && angular.isArray(policyDynVals.insurerPKDList)) {
              pkdsToLoad = pkdsToLoad.concat(policyDynVals.insurerPKDList);
            }
            pkdsToLoad = pkdsToLoad.concat(self._getPkdsFromPersonsOrOrganizations(policy.persons));
            pkdsToLoad = pkdsToLoad.concat(self._getPkdsFromPersonsOrOrganizations(policy.organizations));
          });
          //deduplikacja wartości
          pkdsToLoad = lsnArrayUnique(pkdsToLoad);
          self.loadPkds(pkdsToLoad, callback);
        };

        /**
         * wczytuje slowniki dla osob (m.in. pkd)
         * @param  {PersonModel}   person
         * @param  {Function} callback do wywolania po wczytaniu slownikow
         */
        this.loadDictionariesForPerson = function(person, callback) {
          var pkdsToLoad = self._getPkdsFromPersonsOrOrganizations([person]);
          self.loadPkds(pkdsToLoad, callback);
        };

        /**
         * wczytuje do datamanagera wartości słownikowe niezbedne do wyswietlenia wniosku
         * @param {Application} application wniosek ubezpieczeniowy
         * @param {function=} callback opcjonalny callback po wczytaniu słowników
         */
        this.loadDictionariesForApplication = function (application, callback) {
          //kody pkd
          var pkdsToLoad = [],
            appPkd = application.get('pkd');
          if (appPkd !== null && appPkd !== '') {
            pkdsToLoad.push(appPkd);
          }
          pkdsToLoad = pkdsToLoad.concat(self._getPkdsFromPersonsOrOrganizations(application.persons));
          pkdsToLoad = pkdsToLoad.concat(self._getPkdsFromPersonsOrOrganizations(application.organizations));

          //deduplikacja wartości
          pkdsToLoad = lsnArrayUnique(pkdsToLoad);
          self.loadPkds(pkdsToLoad, callback);
        };

        /**
         * zwraca tablicę kodów pkd uzyskaną z przekazanych obiektów Person
         * @param {Person[]|Organization[]} objs osoby lub firmy
         * @return {string[]} tablica kodów PKD
         */
        this._getPkdsFromPersonsOrOrganizations = function (objs) {
          var pkds = [];
          if (!angular.isArray(objs)) {
            return [];
          }
          angular.forEach(objs, function (obj) {
            var dynVals = obj.get('dynamicValues'),
              pkdsToAdd = [];
            if (angular.isArray(obj.pkd)) {
              pkdsToAdd = obj.pkd;
            } else if (angular.isArray(dynVals.pkdCode)) { //dla kompatybilności wstecz
              pkdsToAdd = dynVals.pkdCode;
            } else if (angular.isArray(dynVals._pkdCode)) { //dla kompatybilności wstecz
              pkdsToAdd = dynVals._pkdCode;
            } 
            var filteredPkds = [];
            angular.forEach(pkdsToAdd, function (pkdCode) {
              if (angular.isString(pkdCode) && pkdCode !== '' && pkdCode !== 'null') //no niestety śmieci dostajemy
              {
                filteredPkds.push(pkdCode);
              }
            });

            pkds = pkds.concat(filteredPkds);
          });
          return lsnArrayUnique(pkds);
        };

        /**
         * wczytuje do self.loadedPkd kody i opisy pkd dla podanej w argumencie listy
         * @param {string[]} pkds tablica kodów pkd
         */
        this.loadPkds = function (pkds, callback) {
          //usuwamy istnijace w datamanagerze pkd
          for (var i = pkds.length - 1; i >= 0; i -= 1) {
            if (angular.isDefined(self.loadedPkd[pkds[i]])) {
              pkds.splice(i, 1);
            }
          }
          if (pkds.length === 0) {
            if (angular.isFunction(callback)) {
              callback();
            }
            return;
          }
          if (angular.isFunction(callback)) {
            self.responseCallback = callback;
          }
          self.callInProgress = true;
          angular.forEach(pkds, function (pkd) {
            var guid = commonServiceHelper.getUniqueRequestId();
            self.requestsInProgress.push(guid);
            dictionaryServiceHelper.fetchPkd(pkd, function (resp) {
              self.loadedPkd[pkd] = dictionaryServiceHelper.getPkdData(resp.data);
              self._checkAndCallback(guid);
            }, function(){
              self.loadedPkd[pkd] = {
                desc: '',
                supported: 0,
                symbol: pkd,
                code: pkd
              };
              self._checkAndCallback(guid);
            });
          });
        };

        /**
         * uruchamia odpowiednie akcje po odpowiedzi z wywołanej usługi słownika
         * @param {string=} responseGuid GUID żądania, którego odpowedź zwrócono
         */
        this._checkAndCallback = function (responseGuid) {
          var guidIndex = angular.isString(responseGuid) ? self.requestsInProgress.indexOf(responseGuid) : -1;
          if (guidIndex !== -1) {
            self.requestsInProgress.splice(guidIndex, 1);
          }
          if (self.requestsInProgress.length === 0) {
            self.callInProgress = false;
          }
          if (!self.callInProgress && angular.isFunction(self.responseCallback)) {
            self.responseCallback();
            self.responseCallback = null;
          }
        };

        /**
         * Pobiera listę banków
         */
        this.findBanks = function(query) {
          return dictionaryServiceHelper.findBank(query).then(function (res) {
            if (!res || !res.data || typeof res.data.items === 'undefined') {
              return [];
            }
            return res.data.items;
          }, angular.noop);
        };
        /**
         * szuka kodów pkd z usługi wykluczając przekazane w selectedPkd kody oraz zwraca je w sformatowanej postaci.
         * @param  {String} query fraza
         * @param  {Array} selectedPkd wykluczenia kodów
         * @return {HttpPromise}
         */
        this.findPkds = function(query, selectedPkd) {
          return dictionaryServiceHelper.findPkds(query, selectedPkd);
        };
      };

      return new ApplicationDictionaryHelper();
    }
  ]);