angular.module('lsnBase.models')
  .factory('LsnApplicationModelV1', ['LsnAbstractModel', 'LsnApplicationModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, LsnApplicationModelConstants, lsnModelFactory) {


      var ApplicationModel = function() {
        this.objectName = 'Application';
        this.modelVersion = 'v1';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          number: {
            type: 'string'
          },
          calculationDate: {
            type: 'date'
          },
          signDate: {
            type: 'date'
          },
          place: {
            type: 'string'
          },
          pkd: {
            type: 'string'
          },
          insuredRef: {
            type: 'string'
          },
          persons: {
            type: 'array',
            elementsType: 'Person'
          },
          organizations: {
            type: 'array',
            elementsType: 'Organization'
          },
          groups: {
            type: 'array',
            elementsType: 'Group'
          },
          vehicles: {
            type: 'array',
            elementsType: 'Vehicle'
          },
          risks: {
            type: 'array',
            elementsType: 'Risk'
          },
          estates: {
            type: 'array',
            elementsType: 'Estate'
          },
          holderRef: {
            type: 'string'
          },
          distributions: {
            type: 'array',
            elementsType: 'DistributionChannel'
          },
          segment: {
            type: 'string'
          },
          messages: {
            type: 'array',
            elementsType: 'Message'
          },
          variants: {
            type: 'array',
            elementsType: 'RiskVariant'
          },
          policies: {
            type: 'array',
            elementsType: 'SimplePolicy'
          },
          status: {
            type: 'string',
            defaultValue: LsnApplicationModelConstants.APPLICATION_STATUS_CALCULATION,
            dictionary: LsnApplicationModelConstants.DICTIONARY_APPLICATION_STATUS
          },
          dynamicValues: {
            type: 'dynamicValues'
          },
          symbol: {
            type: 'string'
          },
          bonusMalusData: {
            type: 'array',
            elementsType: 'BonusMalusDataObject'
          },
          operationType: {
            type: 'string',
            defaultValue: LsnApplicationModelConstants.APPLICATION_OPERATION_TYPE_NEW,
            dictionary: LsnApplicationModelConstants.DICTIONARY_APPLICATION_OPERATION_TYPE
          },
          offerValidDate: {
            type: 'dateTime'
          },
          individualDiscount: {
            type: 'string'
          },
          salesDate: {
            type: 'date'
          },
          operationData: {
            type: 'ApplicationOperationData'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});
        this.number = null; // string, numer polisy
        this.calculationDate = null; // date, Data z jaką została wykonana taryfikacja
        this.signDate = null; // date, Data wystawienia polisy
        this.place = null; // string, Miejsce podpisania umowy
        this.pkd = null; // string, Kod użyty do taryfikacji
        this.insuredRef = null; // string, Identyfikator ubezpieczonego. MetaData.Id z listy person lub organization
        this.persons = null; // lista Person, Lista osób. MetaData.Id musi być unikalne w obrębie person i organization
        this.organizations = null; // lista Organization, Lista osób prawnych. MetaData.ID musi być unikalne w obrębie person i organization
        this.groups = null; // lista Group, Lista grup
        this.vehicles = null; // lista Vehicle, Ubezpieczany pojazd. Maksymalnie jeden na polisie
        this.risks = null; // lista Risk, Kolekcja ubezpieczeń
        this.estates = null; // lista Estate, Lista nieruchomości do ubezpieczenia
        this.holderRef = null; // string, Idenyfikator ubezpieczającego . MetaData.Id z listy persons lub organizations
        this.distributions = null; // lista DistributionChannel
        this.segment = null; // Segment klienta
        this.messages = null; // lista Message - Lista komunikatów biznesowych.
        this.variants = null; // lista RiskVariant - List ryzyk w różnych wariantach z uzupełnioną składką (tzw. symulacja).
        this.policies = null; // lista SimplePolicy - Lista prostych polis utworzona na podstawie wniosku.
        this.status = null; // Status wniosku ubezpieczeniowego. Dopuszczalne wartości:
        // • CALCULATION – wniosek w trybie kalkulacji, możliwość edycji
        // • OFFER – wniosek w trybie oferty, możliwość edycji jedynie określonych w analizie pól (np. miejsce podpisania, data podpisania)
        this.dynamicValues = null; // dynamicValues
        this.symbol = null; // symbol
        this.bonusMalusData = null; // lista danych Bonus Malus
        this.operationType = null; // Tryb procedowania wniosku ubezpieczeniowego.
        this.offerValidDate = null; // data waznosci oferty wiazacej
        this.individualDiscount = null; // kwota zniżki PZA (suwak)
        this.salesDate = null; // date, Data wystawienia oferty
        this.operationData = null; // dane dotyczące operacji na wniosku
      };

      ApplicationModel.prototype = LsnAbstractModel.prototype;

      return ApplicationModel;
    }
  ]);