/**
 * Mapper polis
 */
angular.module('salesPath2').service('fromRestPolicyMapper', ['MapperUtils', 'SimplePolicyModel', 'PolicyDiscountModel', 'VehicleModel', 'fromRestSubjectMapper', 'fromRestEstateMapper', 'fromRestVehicleMapper', 'ApplicationModel', 'appVariables', 'CONSTANTS', 'PolicyDiscountsModel', 'PolicyModel', 'PolicyDiscountModelConstants',
  function(MapperUtils, SimplePolicyModel, PolicyDiscountModel, VehicleModel, fromRestSubjectMapper, fromRestEstateMapper, fromRestVehicleMapper, ApplicationModel, appVariables, CONSTANTS, PolicyDiscountsModel, PolicyModel, PolicyDiscountModelConstants) {
    var FromRestPolicyMapper = function() {
      var self = this;
      this.utils = MapperUtils;
      this.defaultOptions = { //opcje mapowania
        premium: true, //mapowanie skladek
        sumInsured: true, //mapowanie sum ubezpieczen
        additionalData: true, //mapowanie additionalData osób/firm/lokalizacji/pojazdów
        tarifficationMode: false, //tryb taryfikacji (np. wylaczenie pewnych walidacji)
        policyMode: false //wczytywanie polisy
      };

      this.options = angular.copy(this.defaultOptions);

      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };

      /**
       * mapuje proste polisy z wniosku do polis w datamanagerze
       * @param {Application} application wniosek
       * @param {FromRestTempDataContainerModel} tmpDc tema datamanager
       */
      this.mapSimplePoliciesToPolicies = function(application, tmpDc) {
        tmpDc.policies = [];
        if (application.get('policies').length === 0) {
          return;
        }
        angular.forEach(application.policies, function(simplePolicy) {
          self._mapSimplePolicy(simplePolicy, tmpDc);
        });
        //czyścicmy policiesDiscounts dla polis które nie przyszły w response
        this._clearOldSimplePolicies(application.policies, tmpDc);
      };

      /**
       * Czyścimy pozostałości po nieaktualnych simplePolicy
       * @param  {array} newSimplePolicies
       * @param  {FromRestTempDataContainerModel} tmpDc
       */
      this._clearOldSimplePolicies = function(newSimplePolicies, tmpDc) {
        if (!angular.isArray(newSimplePolicies) || newSimplePolicies.length === 0) { //jeśli brak polis w response to nie czyścimy, bo może BOS nie zwrócił taryfikacji
          return;
        }
        //zbieramy numery polis w których discount trzeba czyścić
        var policiesDiscountsToDelete = [];
        lsnNg.forEach(tmpDc.policiesDiscounts, function(polDisc, policyNumber) {
          var isPolicyOld = true;
          lsnNg.forEach(newSimplePolicies, function(newSimplePolicy) {
            if (newSimplePolicy.get('id') === policyNumber) {
              isPolicyOld = false;
              return false;
            }
            return true; //continue
          });
          if (isPolicyOld) {
            policiesDiscountsToDelete.push(policyNumber);
          }
        });
        //czyścimy discounts
        angular.forEach(policiesDiscountsToDelete, function(policyNumber) {
          delete tmpDc.policiesDiscounts[policyNumber];
        });
      };

      /**
       * mapuje simplePolicy na Policy
       * @param {SimplePolicy} simplePolicy
       * @param {FromRestTempDataContainerModel} tmpDc tema datamanager
       */
      this._mapSimplePolicy = function(simplePolicy, tmpDc) {
        if (simplePolicy.get('id') === null) {
          return;
        }
        var data = {
          frequency: simplePolicy.get('frequency'),
          paymentMethod: simplePolicy.get('paymentMethod'),
          suretyRef: simplePolicy.get('suretyRef'),
          number: simplePolicy.get('id'),
          risks: simplePolicy.get('risks'),
          installments: simplePolicy.get('installments'),
          product: simplePolicy.product
        };
        self._mapSimplePolicyObjects(data, simplePolicy);
        self._mapPolicyDiscounts(simplePolicy.id, simplePolicy.product.get('dynamicValues'), tmpDc, simplePolicy.get('discounts'));
        //dane dodatkowe na ubezpieczającym
        if (appVariables.appMode === CONSTANTS.APP_MODE_POLICY) {
          fromRestSubjectMapper.setDynamicValuesFromSalesProduct(self.utils.getObjects(tmpDc, CONSTANTS.OBJECT_TYPE_PERSON_ORGANIZATION)[tmpDc.insurerId], simplePolicy.product);
        }
        var policy = new PolicyModel();
        policy.setData(data);

        var risksProductClauses = {
          clauseCode: [],
          clauseVer: [],
          clauseName: [],
          premium: [],
          coverageOption: [],
          dateOfLastUpdate: [],
          orderIndex: []
        };
        
        angular.forEach(data.risks, function(risk) {
          if (risk.product.dynamicValues.productClauses) {
            angular.forEach(risk.product.dynamicValues.productClauses.clauseCode, function(clauseCode) {
              risksProductClauses.clauseCode.push(clauseCode);
            });            
            angular.forEach(risk.product.dynamicValues.productClauses.clauseVer, function(clauseVer) {
              risksProductClauses.clauseVer.push(clauseVer);
            });        
            angular.forEach(risk.product.dynamicValues.productClauses.clauseName, function(clauseName) {
              risksProductClauses.clauseName.push(clauseName);
            }); 
            angular.forEach(risk.product.dynamicValues.productClauses.premium, function(premium) {
              risksProductClauses.premium.push(premium);
            });
            angular.forEach(risk.product.dynamicValues.productClauses.coverageOption, function(coverageOption) {
              risksProductClauses.coverageOption.push(coverageOption);
            });
            angular.forEach(risk.product.dynamicValues.productClauses.dateOfLastUpdate, function(dateOfLastUpdate) {
              risksProductClauses.dateOfLastUpdate.push(dateOfLastUpdate);
            });
            angular.forEach(risk.product.dynamicValues.productClauses.orderIndex, function(orderIndex) {
              risksProductClauses.orderIndex.push(orderIndex);
            });
          }
        });

        if (risksProductClauses.clauseCode.length > 0) {
          tmpDc.productClauses[policy.number] = risksProductClauses;
        }

        tmpDc.policies.push(policy);
      };

      /**
       * mapuje pojazdy
       * @param  {Object} data
       * @param  {SimplePolicy} simplePolicy
       */
      this._mapSimplePolicyObjects = function(data, simplePolicy) {
        if (angular.isDefined(simplePolicy.vehicles) && angular.isArray(simplePolicy.vehicles)) {
          angular.forEach(simplePolicy.vehicles, function(vehicleId) {
            var vehicleObject = new VehicleModel();
            vehicleObject.metaData.set('id', vehicleId);
            if (angular.isUndefined(data.vehicles)) {
              data.vehicles = [];
            }
            data.vehicles.push(vehicleObject);
          });
        }
      };

      //#pragma mark clientIds
      /**
       * uzupelnia metaData.clientId dla obiektów i podmiotów na polisach
       * @param {Policy[]} policies polisy
       */
      this.setClientIdsForPolicies = function(policies) {
        if (!angular.isArray(policies)) {
          return;
        }
        angular.forEach(policies, function(policy) {
          self.utils.setClientIdsForObjects(policy);
        });
      };

      /**
       * mapuje polisy do wniosku
       * wykorzystuje metodę pośrednią - tworzenia i mapowania wniosku
       * UWAGA
       * Wprowadza zmiany w przekazanych policies w zakresie informacji o utworzonych grupach (_groupId na ryzykach)
       * @param {Policy[]} policies polisy
       * @param {object} mapOptions parametry mapowania
       */
      this.mapPoliciesToApplication = function(policies, mapOptions) {
        self._setOptions(mapOptions);
        var application = new ApplicationModel(),
          commonApplicationDataRead = false; //czy sczytano ogolne(wspolne dla polis) dane wniosku
        application.setData({
          risks: [],
          policies: []
        });
        if (!angular.isArray(policies)) {
          return application;
        }
        //podmioty - firmy/osoby
        fromRestSubjectMapper.mapPolicySubjectsToApplication(policies, application);
        var estateidToClientId = fromRestEstateMapper.mapPolicyEstatesToApplication(policies, application),
          nextVehicleClientId = 1;
        angular.forEach(estateidToClientId, function(clientId) {
          if (nextVehicleClientId <= clientId) {
            nextVehicleClientId = clientId + 1;
          }
        });
        fromRestVehicleMapper.mapPolicyVehiclesToApplication(policies, application, nextVehicleClientId);
        angular.forEach(policies, function(policy) {
          //dane ogone wniosku
          if (!commonApplicationDataRead) {
            self._mapCommonApplicationData(policy, application);
            commonApplicationDataRead = true;
          }
          //ryzyka
          angular.forEach(policy.risks, function(risk) {
            application.risks.push(risk);
          });
          //simplePolicy
          var simplePolicy = new SimplePolicyModel();
          simplePolicy.setData(policy.getData());
          simplePolicy.set('id', policy.get('number'));
          application.policies.push(simplePolicy);
        });
        return application;
      };

      /**
       * uzupełnia ogólne parametry wniosku
       */
      this._mapCommonApplicationData = function(policy, application) {
        application.setData({
          'signDate': policy.get('signDate'),
          'place': policy.get('place'),
          'holderRef': policy.get('holderRef'),
          'insuredRef': policy.get('insuredRef')
        });
        if (policy.product && policy.product.dynamicValues) {
          // tryb "OC nabyecy", w tym miejscu, poniewaz zakładamy, ze oc nabywcy wystepuje tylko na produkcie typu solo
          if (angular.isDefined(policy.product.dynamicValues.ocBuyer)) {
            application.set('dynamicValues', {ocBuyer: policy.product.dynamicValues.ocBuyer});
          }
          // Lokalizacje razem / osobno
          if (angular.isDefined(policy.product.dynamicValues._calculateEstatesSeparately)) {
            application.set('dynamicValues', {_calculateEstatesSeparately: policy.product.dynamicValues._calculateEstatesSeparately});
          }
          if (angular.isDefined(policy.product.dynamicValues.destinations)) {
            application.set('dynamicValues', {destinations: policy.product.dynamicValues.destinations});
          }
        }
      };

      this._mapPolicyDiscounts = function(policyNumber, dynamicValues, tmpDc, discounts) {
        var isPossibleReadingEmployeeState = appVariables.appMode !== CONSTANTS.APP_MODE_POLICY;
        if (angular.isUndefined(tmpDc.policiesDiscounts[policyNumber])) {
          tmpDc.policiesDiscounts[policyNumber] = new PolicyDiscountsModel();
          isPossibleReadingEmployeeState = false;
        }
        lsnNg.forEach(tmpDc.policiesDiscounts[policyNumber], function(element, mainAttrName) {
          if (mainAttrName === 'agreement_DeviatedPremium' || mainAttrName === 'agreement_AuthorizationExceeded') {
            if (angular.isDefined(dynamicValues[mainAttrName])) {
              tmpDc.policiesDiscounts[policyNumber][mainAttrName].selected = dynamicValues[mainAttrName];
            }

            lsnNg.forEach(element.attributes, function(attrVal, attrName) {
              if (angular.isDefined(dynamicValues[attrName])) {
                tmpDc.policiesDiscounts[policyNumber][mainAttrName].attributes[attrName] = dynamicValues[attrName];
              }

            });
          } else if (mainAttrName === 'employee' && (element.selected || !isPossibleReadingEmployeeState)) {
            angular.forEach(element.attributes, function(attrVal, attrName) {
              if (angular.isDefined(dynamicValues[attrName])) {
                tmpDc.policiesDiscounts[policyNumber][mainAttrName].selected = true;
                tmpDc.policiesDiscounts[policyNumber][mainAttrName].attributes[attrName] = dynamicValues[attrName];
              }

            });
          } else if (mainAttrName === 'discountOnRisk' && angular.isDefined(discounts)) {
            var discountsRisk = [];
            angular.forEach(discounts, function(discount) {
              if (discount.value !== 0) {
                var discountRisk = new PolicyDiscountModel();
                discountRisk.set('enabled', discount.enabled);
                discountRisk.set('key', discount.key);
                discountRisk.set('unit', discount.unit);
                discountRisk.set('value', discount.value);
                discountsRisk.push(discountRisk);
              }
            });
            tmpDc.policiesDiscounts[policyNumber][mainAttrName] = discountsRisk;
          } else if (mainAttrName === 'promoCodes' && angular.isString(dynamicValues.specialsSymbol) && dynamicValues.specialsSymbol !== '') {
            tmpDc.policiesDiscounts[policyNumber][mainAttrName].code = dynamicValues.specialsSymbol;
            tmpDc.policiesDiscounts[policyNumber][mainAttrName].selected = true;
          }
        });
      };

      this.mapPolicyDiscountsFromSimpleRisk = function(policyDiscounts, simpleRisks) {
        angular.forEach(simpleRisks, function(simpleRisksPerPolicy, policyNumber) {
          angular.forEach(simpleRisksPerPolicy, function(simpleRisk) {
            if (angular.isDefined(policyDiscounts[policyNumber]) && simpleRisk.discountRisePercentage !== null) {

              angular.forEach(PolicyDiscountModelConstants.DICTIONARY_POLICY_DISCOUNT_KEY, function(code, key) {
                if (simpleRisk.code === code) {
                  var discountRisk = new PolicyDiscountModel();
                  discountRisk.set('enabled', true);
                  discountRisk.set('key', key);
                  discountRisk.set('unit', PolicyDiscountModelConstants.POLICY_DISCOUNT_UNIT_PERCENT);
                  discountRisk.set('value', simpleRisk.discountRisePercentage);
                  policyDiscounts[policyNumber].discountOnRisk.push(discountRisk);
                }
              });
            }
          });
        });
      };

      this.setPolicyDiscountsMinimalPremiumReduceAgreement = function(policyDiscounts, policies) {
        lsnNg.forEach(policies, function(policy) {
          lsnNg.forEach(policy.risks, function(risk) {
            var discountRiseMinimal = risk.product.get('dynamicValues').discountRiseMinimalPremiumValid;
            policyDiscounts[policy.get('number')].agreement_DeviatedPremium.attributes._agreementMinimalPremiumReduced = false;
            if (angular.isDefined(discountRiseMinimal) && discountRiseMinimal === true) {
              policyDiscounts[policy.get('number')].agreement_DeviatedPremium.attributes._agreementMinimalPremiumReduced = true;
              return false;
            }
            return true; //continue
          });
        });
      };
    };

    return new FromRestPolicyMapper();
  }
]);