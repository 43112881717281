angular.module('ihestiaCommonAdminMessage')
  .factory('ihestiaCommonAdminMessageUsrListSvc', ['ihestiaCommonAdminMessageAbstract',
    function(ihestiaCommonAdminMessageAbstract) {

      var AdminMessageUsrListSvc = function() {
        ihestiaCommonAdminMessageAbstract.apply(this, arguments);

        var self = this;
        this.businessArea = 'ui-channel-messages';
        this.resource = 'system-message/list';

        /**
         * Pobieranie listy wiadomości dla użytkownika
         * @param  {Function} callback [description]
         */
        this.getList = function(callback) {
          self.get('', {}, null, function(res) {
            var messages = self.getParsedMessages(res);
            // zwracamy dane
            callback(messages, res);
          });
        };

      };
      return new AdminMessageUsrListSvc();
    }
  ]);