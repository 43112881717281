angular.module('ihestiaIkomunikator')
  .service('iKomunikatorRecipientDictionarySvc', ['IHestiaRestServiceAbstractDef', 'iKomunikatorConstantsMapper', 'iKomunikatorConstants', 'ihestiaIkomunikatorContextHelper',
    function(IHestiaRestServiceAbstractDef, iKomunikatorConstantsMapper, iKomunikatorConstants, ihestiaIkomunikatorContextHelper) {

      var IKomunikatorRecipientDictionarySvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        var self = this;
        this.resource = 'resources/recipientDictionary';
        this.businessArea = 'ikomunikator';

        this.getRecipientsByCustomFields = function(customFields, callback, errorCalback) {
          var data = {
              subjectContextItemOperationCategory: customFields.subjectContextItemOperationCategory,
              subjectContextItemNumber: customFields.subjectContextItemNumber ? customFields.subjectContextItemNumber : '',
              subjectId: customFields.subjectId,
              subjectContextItemType: customFields.subjectContextItemType ? customFields.subjectContextItemType : iKomunikatorConstantsMapper.getSubjectContextItemTypeDefaultBySubjectId(customFields.subjectId)
            },
            params = {
              headers: ihestiaIkomunikatorContextHelper.getContextForHeaders()
            };

          return self.post(null, data, 'recipient', callback, errorCalback, params);
        };
      };

      return new IKomunikatorRecipientDictionarySvc();
    }
  ]);