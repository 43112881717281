angular.module('ihestiaWidgets.ikonfigurator')
  .controller('ikonfiguratorOfferModalCtrl', ['$scope', 'ikonfiguratorOfferModalHelper', 'IKONFIGURATOR_RESOURCES', 'ikonfiguratorConstants', 'ikonfiguratorHelper', '$window', 'iHestiaCommonModalHelper', 'ihestiaConfigHelper', 'base64', 'ihestiaIkonfiguratorUserSvc', '$rootScope',
    function($scope, ikonfiguratorOfferModalHelper, IKONFIGURATOR_RESOURCES, ikonfiguratorConstants, ikonfiguratorHelper, $window, iHestiaCommonModalHelper, ihestiaConfigHelper, base64, ihestiaIkonfiguratorUserSvc, $rootScope) {
      $scope.proposedProducts = angular.copy(ikonfiguratorOfferModalHelper.proposedProducts);
      $scope.dataSetNumber = ikonfiguratorOfferModalHelper.dataSetNumber;

      $scope.resources = IKONFIGURATOR_RESOURCES;
      $scope.ikonfiguratorConstants = ikonfiguratorConstants;
      $scope.accessMode = ikonfiguratorHelper.accessMode;
      $scope.formData = {
        showErrors: false
      };

      /**
       * Dane niezalogowanego użytkownika
       * @type {Object}
       */
      $scope.personData = ikonfiguratorHelper.personData;
      $scope.showClientInfo = ikonfiguratorHelper.showClientInfo;

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.parseProposedProducts();
        $scope.$on('iHestiaCommonModalOkBtnClicked.ikonfiguratorOfferModal', function($event) {
          $event.preventDefault();
          $scope.confirmConfiguration();
        });
      };

      /**
       * Dostęp do forma
       * @param {object} formObject [description]
       */
      $scope.setFormScope = function(formObject) {
        $scope.templateForms = formObject;
      };

      /**
       * Zatwierdzamy konfigurację
       * @return {undefined}
       */
      $scope.confirmConfiguration = function() { //eslint-disable-line consistent-return
        if (ikonfiguratorHelper.accessMode === ikonfiguratorConstants.ACCESS_MODE_GUEST) {
          $scope.formData.showErrors = true;
          if(!$scope.templateForms.ikonfiguratorPersonData || $scope.templateForms.ikonfiguratorPersonData.$invalid) {
            return false;
          }
        }
        if (ikonfiguratorHelper.accessMode === ikonfiguratorConstants.ACCESS_MODE_AGENT) {
          // tylko agent przechodzi na ścieżki
          var urls = ikonfiguratorOfferModalHelper.getProductsUrls();
          angular.forEach(urls, function(url) {
            $window.open(url);
          });
          iHestiaCommonModalHelper.hideModal('ikonfiguratorOfferModal');
        } else {
          // każdy inny tworzy zadanie w dashboardzie
          // wciągamy adres konfiguratora w portalu
          var konfiguratorUrl = ihestiaConfigHelper.get('external', 'PORTAL_IKONFIGURATOR_URL');
          // budujemy odowiedni link dla zadań w dashboardzie
          var urlForDashboard = konfiguratorUrl + $scope.dataSetNumber;
          if (ikonfiguratorHelper.nbkId || ikonfiguratorHelper.personData.pesel || ikonfiguratorHelper.accessMode === ikonfiguratorConstants.ACCESS_MODE_GUEST) {
            var personData = base64.encode(angular.toJson(ikonfiguratorHelper.personData)).base64UrlEncode();
            urlForDashboard = urlForDashboard + '?nbkId=' + ikonfiguratorHelper.nbkId + '&personData=' + personData;
          }
          var data = angular.copy($scope.personData);
          data.offer = {
            url: urlForDashboard
          };
          $scope.createDashboardTask($scope.dataSetNumber, data);
        }
      };

      /**
       * Stworzenie 
       * @param  {String} dataSetNumber [description]
       * @param  {Object} personData    [description]
       * @return {undefined}
       */
      $scope.createDashboardTask = function(dataSetNumber, personData) {
        $scope.$emit('showBlockUi');
        ihestiaIkonfiguratorUserSvc.post(dataSetNumber, personData).then(function(res){
          if(res && res.status === 200) {
            $rootScope.$broadcast('ikonfiguratorTaskCreated');
            iHestiaCommonModalHelper.hideModal('ikonfiguratorOfferModal');
          }
        }, angular.noop).finally(function(){
          $scope.$emit('hideBlockUi');
        });
      };

      /**
       * Modyfikujemy nieco dane o wybranych produktach aby sprostać prezentacji
       * @return {undefined}
       */
      $scope.parseProposedProducts = function() {
        var mainProduct = null,
          esProduct = null;
        angular.forEach($scope.proposedProducts, function(product) {
          if (product.productCode === 'es') {
            esProduct = product;
          } else {
            mainProduct = product;
          }
        });

        // ustalamy opisy (resources na podstawie matryc z aplikacji produktów)
        var descriptions = IKONFIGURATOR_RESOURCES.productsDescription[mainProduct.productCode];
        angular.forEach(['risks', 'additions'], function(mainCode) {
          if (angular.isArray(mainProduct[mainCode])) {
            angular.forEach(mainProduct[mainCode], function(descNode) {
              var descCode = descNode.code;
              if (descNode.variantCode) {
                descCode = descCode + '_' + descNode.variantCode;
              } else if (descNode.variant) {
                descCode = descCode + '_' + descNode.variant;
              }
              descNode.title = descriptions[mainCode][descCode].title;
              descNode.desc = descriptions[mainCode][descCode].desc;
            });
          }
        });

        // sport jako dodatek
        if (esProduct) {
          if (!angular.isArray(mainProduct.additions)) {
            mainProduct.additions = [];
          }

          mainProduct.additions.push({
            code: esProduct.productCode.toUpperCase(),
            title: IKONFIGURATOR_RESOURCES.productsDescription.es.additions.ERGO_SPORT.title
          });
        }
      };


    }
  ]);