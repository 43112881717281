angular.module('ihestiaRestServicesBase')
   .factory('installmentsSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

    var Installments = function()
    {
      IHestiaRestServiceAbstractDef.apply(this, arguments);

      this.resource = 'customers';
      this.businessArea = 'insurance';
      this.searchesPostfix = 'installments/searches';
      this.pageStyle = false;
      this.sortBy = 'startDate';
    };

    return new Installments();
  }])
  ;