/**
 * This directive contains policy termination form for all EHL termination types
 */
angular.module('ihestiaWidgets.policyTermination')
  .directive('policyTerminationEhl', [function() {
    return {
      restrict: 'E',
      templateUrl: 'ihestia-widgets-templates-app/policyOperations/policyTermination/policyTerminationEhl/policyTerminationEhl.tpl.html',
      controller: 'policyTerminationEhlCtrl',
      scope: {
        uploaderOptions: '=?', // optional, there are default options
        documentType: '<', // for uploader, response from ihestiaRestDictionaryElectronicDocumentTypesSvc with proper code
        isCentral: '<',
        forward: '&', // event emitted after confirm btn was clicked
        back: '&', // event emitted after back btn was clicked
        requestData: '<', // request context {saveErrors: object[], onlyWarnings: boolean}
        policy: '<', // policy object
        terminationType: '<',
        stateParams: '<'
      }
    };
  }]);
