angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardClientObjectConstants', [function() {
    return {
    };
  }])
  .factory('IhestiaDashboardClientObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {

      var ClientObjectModel = function() {
        this.objectName = 'IhestiaDashboardClientObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          },
          client: {
            type: 'IhestiaDashboardClient'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_CLIENT;
        this.client = null;

      };


      ClientObjectModel.prototype = IHestiaAbstractModel.prototype;

      return ClientObjectModel;
    }]);