angular.module('salesPath2')
  .service('extensionsTplHelper', ['RESOURCES', 'CONSTANTS', 'sp2SelectionHelper', 'extensionHelper', 'dataContainerHelper',
    function(RESOURCES, CONSTANTS, selectionHelper, extensionHelper, dataContainerHelper) { // eslint-disable-line angular/di
      var ExtensionsTplHelper = function() {
        var self = this;
        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          showExts: false,
          extDefs: [] //definicje rozszerzen
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'tarifficationEnded':
            case 'extDeleted':
            case 'extAdded':
            case 'matrixProductSelected':
            case 'personEdited':
            case 'personAdded':
            case 'personDeleted':
            case 'groupEdited':
            case 'groupAdded':
            case 'groupDeleted':
            case 'personVariantChanged':
            case 'fireBurglarySumInsuredChanged':
              self.refreshExtsData();
              break;
            case 'objectSelected':
            case 'localizationDeleted':
            case 'personGroupSelected':
              self.refreshExtDefs(true);
              break;
            default:
          }
        };

        /**
         * odświeżenie listy rozszerzeń
         * @param {boolean} refreshDefs czy odswiezyc definicje rozszerzen
         */
        this.refreshExtsData = function(refreshDefs) {
          if (refreshDefs) {
            self.refreshExtDefs();
          }
          if (self.tplData.extDefs.length === 0) {
            return false; //jeśli próba aktualizacji dodatków, gdy jeszcze nie zostały wyświetlone
          }
          var extensionDefs = self.getActiveExtensionDefinitions();
          angular.forEach(extensionDefs, function(ext, idx) {
            angular.forEach(ext, function(v, k) {
              self.tplData.extDefs[idx][k] = v; //getActiveExtensionDefinitions zawsze zwróci te same rozszerzenia więc można oprzeć się na idx
            });
          });
          return true;
        };

        //odsiweza definicje rozszerzeń
        this.refreshExtDefs = function() {
          self.tplData.extDefs = self.getActiveExtensionDefinitions();
          self.tplData.showExts = self.tplData.extDefs.length > 0;
        };

        /**
         * zwraca definicje rozszerzeń na potrzeby templatki
         * @return {Object} definicja z dodatkowym tplData
         */
        this.getActiveExtensionDefinitions = function() {
          var extDefs = angular.copy(RESOURCES.EXTENSIONDEF),
            extAvailable = extensionHelper.getAvailableExtensions(),
            extSelected = selectionHelper.getSelectedExts();
          angular.forEach(extDefs, function(extDef) {
            extDef.tplData = {
              isAvailable: extAvailable[extDef.CODE],
              isSelected: extSelected[extDef.CODE] === true,
              allObjects: dataContainerHelper.getSelectedObjectId(extDef.TYPE) === CONSTANTS.ALL_OBJECTS
            };
            if (angular.isObject(extAvailable[extDef.CODE])) { //numery obiektów, dla których wybrano rozszerzenie
              extDef.tplData.numbersToShow = Object.keys(extAvailable[extDef.CODE]);
            }
          });
          return extDefs;
        };


      };

      return new ExtensionsTplHelper();
    }
  ])
  .run(['CONFIG', 'extensionsTplHelper', 'actionHelper',
    function(CONFIG, extensionsTplHelper, actionHelper) {
      actionHelper.registerHelper('extensionsTplHelper', extensionsTplHelper);
    }
  ]);