angular.module('ihestiaRestServicesBase')
.factory('IHestiaUserListRequestModel', ['LsnAbstractModel', function(LsnAbstractModel) {

    var IHestiaUserRequestModel = function()
    {
      this.objectName = 'UserRequest';

      this.fieldsProperties = {
        login: {
          type: 'string'
        },
        firstName: {
          type: 'string'
        },
        lastName: {
          type: 'string'
        },
        personName: {
          type: 'string'
        },
        email: {
          type: 'string'
        },
        pesel: {
          type: 'string'
        },
        lockReason: {
          type: 'string'
        },
        roles: {
          type: 'array',
          elementsType: 'string'
        },
        atoms: {
          type: 'array',
          elementsType: 'string'
        },
        salesProfile: {
          type: 'string'
        },
        characterCode: {
          type: 'string'
        },
        userTypes: {
          type: 'array',
          elementsType: 'string'
        },
        characterName: {
          type: 'string'
        },
        isCharacterBlocked: {
          type: 'bool'
        },
        isCharacterDefault: {
          type: 'bool'
        },
        characterTypes: {
          type: 'array',
          elementsType: 'string'
        },
        organizationUnits: {
          type: 'array',
          elementsType: 'string'
        },
        salesProfiles: {
          type: 'array',
          elementsType: 'string'
        },
        workers: {
          type: 'array',
          elementsType: 'string'
        },
        icDescription: {
          type: 'string'
        },
        status: {
          type: 'string'
        },
        dateFrom: {
          type: 'dateTime'
        },
        dateTo: {
          type: 'dateTime'
        },
        profilePropertyDateFrom: {
          type: 'dateTime'
        },
        profilePropertyDateTo: {
          type: 'dateTime'
        },
        profileLifeDateFrom: {
          type: 'dateTime'
        },
        profileLifeDateTo: {
          type: 'dateTime'
        },
        workerPropertyDateFrom: {
          type: 'dateTime'
        },
        workerPropertyDateTo: {
          type: 'dateTime'
        },
        workerLifeDateFrom: {
          type: 'dateTime'
        },
        workerLifeDateTo: {
          type: 'dateTime'
        },
        characterMembers: {
          type: 'array',
          elementsType: 'string'
        },
        characterDefaults: {
          type: 'array',
          elementsType: 'string'
        },
        columns: {
          type: 'string'
        }
      };

      this.login = null; // String  Filtr loginu użytkownika.   Nie
      this.firstName = null; // String  Filtr imienia użytkownika Nie
      this.lastName = null; //  String  Filtr nazwiska użytkownika. Nie
      this.personName = null; //  String  Filtr imienia i nazwiska. Nie
      this.email = null; // String  Filtr emailu użytkownika  Nie
      this.pesel = null; // String  Filtr peselu użytkownika  Nie
      this.lockReason = null; //Przyczyna zablokowania
      this.roles = null; //  array  Filtr  roli użytkownika Nie
      this.atoms = null; // array  Filtr uprawnienia użytkownika.  Nie
      this.salesProfile = null; //  String  Filtr agencji użytkownika.  Nie
      this.characterCode = null; // String  Filtr kodu tożsamości Nie
      this.userTypes = null; // array  typy użytkowników
      this.CharacterName = null; // String  Filtr nazwy tożsamości
      this.isCharacterBlocked = null; // Bool  Filtr czy charakter zablokowany
      this.isCharacterDefault = null; // Bool  Filtr czy charakter domyślny
      this.characterTypes = null; // array  typy charakterów
      this.organizationUnits = null; // array  przedstawicielstwa
      this.salesProfiles = null; // array  agencje
      this.workers = null; // array  pracownicy
      this.icDescription = null; // string  spółka
      this.status = null; // string status konta
      this.dateFrom = null; // dateTime
      this.dateTo = null; // dateTime
      this.profilePropertyDateFrom = null; // dateTime
      this.profilePropertyDateTo = null; // dateTime
      this.profileLifeDateFrom = null; // dateTime
      this.profileLifeDateTo = null; // dateTime
      this.workerPropertyDateFrom = null; // dateTime
      this.workerPropertyDateTo = null; // dateTime
      this.workerLifeDateFrom = null; // dateTime
      this.workerLifeDateTo = null; // dateTime
      this.characterMembers = null; // array member
      this.characterDefaults = null;

      this.columns = null; // String  Filtr mówiący o zwracanych danych.
          // Mamy 3 zbiory kolumn:
          // 1.Login,FirstName,LastName,Email,Pesel,IsBlocked,
          // 2.CharacterName,SalesProfile
          // 3.RoleCode,PrivilageCode

          // Po podaniu jednej z kolumn zaczytają się wszystkie kolumny z jej grupy.
          // UWAGA!!! Nie można wstawiać spacji między kolumnami! Separatorem musi być przecinek!
          // Przykład: „Login,FirstName,CharacterName”.

          // UWAGA!!! Podanie samego filtru dla loginu bez podania kolumny „Login” też zwróci dane z pierwszej grupy.  Nie

    };

    IHestiaUserRequestModel.prototype = LsnAbstractModel.prototype;

    return IHestiaUserRequestModel;
  }]);