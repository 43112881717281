angular.module('ihestiaSsoBase', [
  'ihestiaSsoBase.constants',
  'ihestiaCommonDirectives',
  'ihestiaCommonDirectives.errorHandler',
  'ihestia-sso-templates-app',
  'ui.router',
  'ihestiaCrossTab',
  'ihestiaSsoBase.baseInfo',
  'ihestiaRestServicesBase'
]).config(['$httpProvider', '$stateProvider',
  function($httpProvider, $stateProvider) {
    $httpProvider.interceptors.push('ihestiaSsoInterceptor');
    $httpProvider.defaults.useXDomain = true;
    
    // strona hasło wygasło
    $stateProvider.state('passwordExpired', {
      type: 'error',
      url: '/passwordExpired',
      views: {
        main: {
          templateUrl: 'ihestia-commondirectives-template-app/templates/applicationError.tpl.html',
          controller: 'passwordExpiredCtrl'
        }
      },
      resolve: {
        hasPasswordExpired: ['securityInitHelper', '$q', '$rootScope', '$location', '$timeout',
          function(securityInitHelper, $q, $rootScope, $location, $timeout) {
            var d = $q.defer();
            if (!securityInitHelper.hasPasswordExpired()) {
              // jednak haslo nie wygaslo
              d.reject('appOk');
            } else {
              d.resolve(true);
            }
            // sluchamy odrzucen ui-routera
            var rejectListener = $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
              if (error === 'appOk') {
                // timeout, gdyż zmiana $location jest już w digest
                $timeout(function(){
                  $location.path('/').replace();
                }, 0);
                rejectListener();
              }
            });

            return d.promise;
          }]
      }
    }).state('appForbidden', {
      type: 'error',
      url: '/forbidden',
      views: {
        main: {
          templateUrl: 'ihestia-commondirectives-template-app/templates/applicationError.tpl.html',
          controller: 'forbiddenCtrl'
        }
      },
      resolve: {
        isForbidden: ['securityInitHelper', '$q', '$rootScope', '$location', '$timeout',
          function(securityInitHelper, $q, $rootScope, $location, $timeout) {
            var d = $q.defer();
            var promiseHandler = function(hasAppPermission){
              if (hasAppPermission) {
                // jednak mamy uprawnienia
                d.reject('appOk');
              } else {
                d.resolve(true);
              }
              // sluchamy odrzucen ui-routera
              var rejectListener = $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
                if (error === 'appOk') {
                  // timeout, gdyż zmiana $location jest już w digest
                  $timeout(function(){
                    $location.path('/').replace();
                  }, 0);
                  rejectListener();
                }
              });
            };

            $q.when(securityInitHelper.hasAppPermission(), promiseHandler, promiseHandler);

            return d.promise;
          }]
      }
    });
  }
]);
