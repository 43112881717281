angular.module('ihestiaWidgets.policyDetails')
  .factory('policyVersionsModalHelper', ['LsnModalHelper', '$filter',
    function(LsnModalHelper, $filter) {

      var PolicyVersionsModalHelper = function() {
        LsnModalHelper.apply(this, arguments);

        var self = this;

        this.prepareOptions = function()
        {
          self.restoreDefaultOptions();
          this.setOptions({
            title: $filter('translate')('choosePolicyVersion', {componentCode: 'iKonto'}),
            templateUrl: 'common/policy/policyVersionsModal/policyVersionsModal.tpl.html',
            size: 'sm',
            okBtnName: ''
          });
        };

        this.prepareOptions();
      };

      return new PolicyVersionsModalHelper();
    }]);
