angular.module('ihestiaWidgets.pza')
  .factory('IhestiaPzaWorkerOperationModel', ['IHestiaAbstractModel', function(IHestiaAbstractModel) {

    var OperationModel = function() {
      this.objectName = 'OperationModel';

      this.fieldsProperties = {
        workerList: {
          type: 'array',
          elementsType: 'string'
        },
        changeBudget: {
          type: 'bool'
        },
        budgetValue: {
          type: 'float'
        },
        operation: {
          type: 'IhestiaPzaDictionary'
        },
        groupChange: {
          type: 'bool'
        },
        groupValue: {
          type: 'string'
        },
        budgetAccess: {
          type: 'IhestiaPzaDictionary'
        },
        changeBudgetAccess: {
          type: 'bool'
        },
        blockValue: {
          type: 'bool'
        },
        blockChange: {
          type: 'bool'
        }
      };

      this.workerList = [];
      this.changeBudget = false;
      this.budgetValue = null;
      this.operation = null;
      this.groupChange = null;
      this.groupValue = null;
      this.changeBudgetAccess = null;
      this.budgetAccess = null;
      this.blockValue = null;
      this.blockChange = null;

    };


    OperationModel.prototype = IHestiaAbstractModel.prototype;

    return OperationModel;
  }]);