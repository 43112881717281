angular.module('salesPath2.spd')
  /**
   * provider SPD dla ścieżki
   * 
   */
  .provider('SPD', ['sp2LoaderProvider',
    function(sp2LoaderProvider) {
      var self = this;

      /**
       * model dla SPD
       */
      var SpdModel = function() {
        /**
         * atrybuty obiektów i podmiotów
         * @type {Object}
         */
        this.objectsDynamicValues = null;
        /**
         * definicje ryzyk
         * @type {Object}
         */
        this.risks = null;
        /**
         * mapowania kodów (CONSTANTS => kody REST) dla podmiotów
         * @type {Object}
         */
        this.subjects = null;
        /**
         * definicje pakietów (komunikacja, mienie)
         * @type {Object}
         */
        this.salesProducts = null;
        /**
         * wersja SPD - data w formacie YYYYMMDD
         * @type {String}
         */
        this.version = null;
      };

      /**
       * kolekcja SPD. Jako klucz w kolekcji(obiekcie) używana jest wersja spd.
       * @type {Object.<string, SpdModel>}
       */
      this.spds = {};

      /**
       * rejestracja nowego SPD
       * @param  {Object} spd obiekt z konfiguracją danego elementu SPD
       * @param {string} field pole w modelu SpdModel, w ktorym umieścimy przekazane dane (np. 'risks')
       */
      this.registerSPD = function(spd, field, version) {
        if (!angular.isObject(spd) || !angular.isString(field)) {
          throw new Error('Brak wymaganego parametru przy rejestracji SPD.');
        }
        if (!angular.isDefined(self.spds[version])) {
          self.spds[version] = new SpdModel();
        }
        self.spds[version][field] = spd;
      };

      /**
       * pobiera aktualne SPD
       * Bierze pod uwagę stan loaderProvidera, czyli jest w stanie załadować historyczną wersję SPD
       * @param  {string} todayVersion wersja dla dzisiejszego dnia (aktualna data w formacie YYYYMMDD)
       * @return {SpdModel}
       */
      this.getSPD = function(todayVersion) {
        if (angular.equals(self.spds, {})) {
          throw new Error('Brak SPD do pobrania.');
        }
        var version = sp2LoaderProvider.currentActionParams.version || todayVersion,
          sortedVersions = Object.keys(self.spds).sort(),
          targetVersion = null;
        for (var i = sortedVersions.length - 1; i >= 0; i -= 1) {
          if (version >= sortedVersions[i]) {
            targetVersion = sortedVersions[i];
            break;
          }
        }
        return self.spds[targetVersion];
      };

      this.$get = ['CONFIG', 'RESOURCES', 'spdAdditionalData', 'spdAllRisks', 'spdAllSalesProducts', 'spdApplication', 'spdTmpObjectsDynamicValues', 'spdTmpRisks', 'spdTmpSalesProducts', 'lsnDeepExtend', 'spdAdditionalDataAttributes',
        function(CONFIG, RESOURCES, spdAdditionalData, spdAllRisks, spdAllSalesProducts, spdApplication, spdTmpObjectsDynamicValues, spdTmpRisks, spdTmpSalesProducts, lsnDeepExtend, spdAdditionalDataAttributes) {
          var today = new XDate(RESOURCES.TODAY).toString('yyyyMMdd'),
            currentSpd = self.getSPD(today),
            SPD = {
              objectsDynamicValues: {},
              Application: {}
            };
          SPD.additionalData = spdAdditionalData;
          SPD.spdAdditionalDataAttributes = spdAdditionalDataAttributes;
          SPD.allRisks = spdAllRisks;
          SPD.allSalesProducts = spdAllSalesProducts;
          SPD.objectsDynamicValues.Application = spdApplication.objectsDynamicValues;
          SPD.Application.idpm = CONFIG.METADATA.applicationSymbol;
          lsnDeepExtend(SPD.objectsDynamicValues, currentSpd.objectsDynamicValues);
          SPD.risks = currentSpd.risks;
          SPD.salesProducts = currentSpd.salesProducts;
          SPD.subjects = currentSpd.subjects;
          lsnDeepExtend(SPD.objectsDynamicValues, spdTmpObjectsDynamicValues);
          
          // Dodatkowe sprawdzenie objectsDynamicValues pod kątem atrybutów które dla danej aplikacji nie powinny być pomijane w usługach rest
          // jeśli w spd objectsDynamicValues dla danej aplikacji mamy zdefiniowane jakieś atrybuty
          // to resetujemy dodatkowy parametr jaki mamy z spdTmpObjectsDynamicValues (mówiący o pomijaniu tych atrybutów w rest)
          // np. ma to miejsce dla atrybutów doorProtection, localAlarmSystem i regularSupervision dla Estates w E7
          angular.forEach(SPD.objectsDynamicValues, function(value, key) {
            if(angular.isDefined(currentSpd.objectsDynamicValues[key])) {
              angular.forEach(currentSpd.objectsDynamicValues[key], function(dynVal, dynKey) {
                //element tablicy o indeksie [2] to informacja o pomijaniu atrybutu
                if(angular.isDefined(SPD.objectsDynamicValues[key][dynKey]) && angular.isDefined(SPD.objectsDynamicValues[key][dynKey][2])) {
                  SPD.objectsDynamicValues[key][dynKey][2] = false;
                }
              });
            }
          });

          lsnDeepExtend(SPD.risks, spdTmpRisks);
          lsnDeepExtend(SPD.salesProducts, spdTmpSalesProducts);
          return SPD;
        }
      ];

    }
  ]);