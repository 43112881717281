angular.module('ihestiaRestServicesBase')

/**
 * oferty ubezp.
 */
.service('insuranceOffersSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {

  /**
   * Główny konstruktor
   */
  var Offers = function() {
    this.resource = 'offers';
    this.businessArea = 'insurance';
    this.port = '8201';
    this.path = 'RestOffers';

  };

  /**
   * Konstruktor v2
   */
  var OffersV2 = function() {
    Offers.apply(this, arguments);
    this.apiVersion = 'v2';
  };

  /**
   * Konstruktor v1
   */
  var OffersV1 = function() {
    Offers.apply(this, arguments);
  };

  return IHestiaRestServiceAbstractDef.setApiConstructors({
    v1: OffersV1,
    v2: OffersV2
  });
}]);