angular.module('lsnBase.models')
  /**
   * filtracja tablicy dokumentów tożsamości
   * @param  {array} documents
   * @return {array}
   */
  .service('documentsFilter', [function() {
    return function(documents, params) {
      /**
       * dostepne parametry (sprawdzane są od pierwszego z góry i mogą się wykluczać):
       * removeAll: (bool) czy usunac wszystkie dokumenty
       */
      var availableParams = {
        removeAll: false,
        leaveOne: false,
        removeIssueDate: true
      };
      var currentParams = availableParams;
      if (!angular.isArray(documents)) {
        return documents;
      }
      if (angular.isObject(params)) {
        currentParams = angular.extend(currentParams, params);
      }

      if (currentParams.removeAll) {
        return [];
      }

      if (currentParams.leaveOne) {
        if (documents.length === 0) {
          return [];
        } else {
          var docsToLeave = documents.slice(0, 1);
          delete docsToLeave[0].issueDate;
          return docsToLeave;
        }
      }

      return documents;
    };
  }]);