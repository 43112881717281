  angular.module('lsnBase.directives')

  .directive('selectEquals', ['$parse', function($parse) {
    return {
      restrict: 'A',
      require: '?ngModel',
      scope: true,
      link: function($scope, $element, $attrs, ngModel) {
        if($attrs.compareBy) {
          // porównujemy model po konkretnym atrybucie
          var comparingTo = $parse($attrs.ngModel + '.' + $attrs.compareBy)($scope);
          var selectOptions = $parse($attrs.selectEquals)($scope);
          angular.forEach(selectOptions, function(selectOption){
            if(angular.equals($parse($attrs.compareBy)(selectOption), comparingTo)) {
              $parse($attrs.ngModel).assign($scope, selectOption);
            }
          });
        } else {
          // poniższego boję się ruszać - zostaje dla wstecznej kompatybilności
          // zmiany źródła ng-options powoduję przegenerowanie referencji
          $scope.$watch($attrs.selectEquals, function(optionsArray) {
            angular.forEach(optionsArray, function(optionValue) {
              // atrybut do porównywania (np. 'someCode', jeśli mamy ng-options="item.someCode as item.name for item in ..")
              if ($attrs.selectEqualsTo) {
                angular.forEach($attrs.selectEqualsTo.split('.'), function(part){
                  optionValue = optionValue[part];
                });
              }
              if(angular.isDefined($attrs.ngCleanCompare) && angular.equals(angular.fromJson(angular.toJson(optionValue)), angular.fromJson(angular.toJson(ngModel.$modelValue)))) {
                $parse($attrs.ngModel).assign($scope, optionValue);
              } else if (angular.equals(optionValue, ngModel.$modelValue)) {
                $parse($attrs.ngModel).assign($scope, optionValue);
              }
            });
          }, true);
        }
      }
    };
  }]);