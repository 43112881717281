angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardNewTaskHelper', ['iHestiaCommonModalHelper', 'IhestiaDashboardTaskModel',
    'IhestiaDashboardTaskModelConstants', 'ihestiaCommonErrorHandler', 'ihestiaDashboardTasksSvc',
    'IhestiaDashboardObjectModelConstants', 'ihestiaModelHelper', 'ihestiaSsoBaseInfoHelper',
    'ihestiaDashboardTaskHelper', 'customerSvc', '$rootScope', 'IhestiaDashboardClientModel',
    'IhestiaDashboardClientConstants', '$q', 'ihestiaDashboardTaskDefinitionsSvc', 'labelActionHelper',
    '$filter', 'labelServiceHelper', 'IhestiaDashboardEhlTaskTopicDict', 'ihestiaConfigHelper', 'lsnDeepExtend',
    'newTaskIcrClientEditModalHelper', 'newTaskIcrDataModalHelper', '$timeout', 'ihestiaPlatformHelper', 'ihestiaDashboardModalsInjectorConfig',
    function(iHestiaCommonModalHelper, IhestiaDashboardTaskModel,
             IhestiaDashboardTaskModelConstants, ihestiaCommonErrorHandler, ihestiaDashboardTasksSvc,
             IhestiaDashboardObjectModelConstants, ihestiaModelHelper, ihestiaSsoBaseInfoHelper,
             ihestiaDashboardTaskHelper, customerSvc, $rootScope, IhestiaDashboardClientModel,
             IhestiaDashboardClientConstants, $q, ihestiaDashboardTaskDefinitionsSvc, labelActionHelper,
             $filter, labelServiceHelper, IhestiaDashboardEhlTaskTopicDict, ihestiaConfigHelper, lsnDeepExtend,
             newTaskIcrClientEditModalHelper, newTaskIcrDataModalHelper, $timeout, ihestiaPlatformHelper, ihestiaDashboardModalsInjectorConfig) {
      var NewTaskHelper = function() {
        var self = this;

        this.taskData = null;
        this.fullTaskData = null;
        this.screensToSkip = {};
        this.fieldsToSkip = {};
        this.characterType = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;
        this.context = 'DashboardMain';
        this.nationalities = null;
        this.identityDocumentTypes = null;
        this.isCentral = ihestiaDashboardTaskHelper.currentUserIsCentral();
        this.selectedClientFromList = false;
        this.ehlTaskTopicDict = IhestiaDashboardEhlTaskTopicDict;
        this.selectedClientCountryCode = null;

        /**
         * definicje zadań realizowanych ręcznie
         * @type {array}
         */
        this.manualSubjects = {};

        /**
         * Kontekstowe wywołanie dodawania zadania
         * @param  {object} contextData ({clientData: .., ikontoId: .., topicCode: .. })
         * @return {ihestiaDashboardNewTaskHelper}            self
         */
        this.showNewTaskModalWithContext = function(contextData) {
          self.clearTaskData();
          self.taskData = {};
          self.fullTaskData = {};
          // trzeba załadować temat
          if (contextData.topicCode) {
            $rootScope.$emit('showBlockUi');
            self.getManualSubjects().then(function(subjects) {
              var definition = null;
              angular.forEach(subjects, function(subject) {
                // temat
                if (subject.code === contextData.topicCode && definition === null) {
                  definition = subject;
                }

                // podtemat
                if (contextData.subtopicCode && subject.code === contextData.subtopicCode) {
                  definition = subject;
                }
              });

              if (definition) {
                self.taskData.title = definition.title;
                self.taskData.subtitle = definition.subtitle;
                self.taskData.taskDefinitionId = definition.id;
                self.taskData.taskDefinitionCode = definition.code;
                self.screensToSkip.ihestiaDashboardNewTaskSubject = true;
                self.setObjectByDefinitionType(definition.objectType);
              }
            }, angular.noop).finally(function() {
              $rootScope.$emit('hideBlockUi');
              // trzeba załadować klienta
              if (contextData.clientData) {
                var clientModel = new IhestiaDashboardClientModel();
                var clientData = {
                  pesel: contextData.clientData.pesel,
                  name: contextData.clientData.firstName,
                  surname: contextData.clientData.lastName,
                  type: IhestiaDashboardClientConstants.TYPE_PERSON
                };

                // telefon i email
                angular.forEach(contextData.clientData.contacts, function(contact) {
                  if (contact.code === 'komo') {
                    clientData.phoneNumber = contact.value;
                  } else if (contact.code === 'mail') {
                    clientData.email = contact.value;
                  }
                });

                // id ikonta
                if (contextData.ikontoId) {
                  clientData.ikontoId = contextData.ikontoId;
                }

                clientModel.setData(clientData);
                self.taskData.object.client = clientModel.getData();

                self.screensToSkip.ihestiaDashboardNewTaskClientSearch = true;
              }
              // numer polisy
              if (contextData.policyNumber) {
                self.taskData.object.policyNumber = contextData.policyNumber;
                self.fieldsToSkip.policyNumber = true;
              }
              self.showNextNewTaskModal();
            });
          }

          return self;
        };

        /**
         * Pokazuje pierwszy modal tworzenia nowego zapytania
         * @param {object|null} preLoadedData dane, które ustawiamy w modelu przed rozpoczęciem edycji
         * @param {string | undefined} context kontekst wołania
         * @param {object | undefined} screensToSkip
         * @return {undefined}
         */
        this.showNewTaskModal = function(preLoadedData, context, screensToSkip) {
          if (context) {
            self.context = context;
          } else {
            self.context = 'DashboardMain';
          }

          if (preLoadedData) {
            self.fullTaskData = preLoadedData.fullTaskData;
            delete preLoadedData.fullTaskData;
          }
          self.taskData = (new IhestiaDashboardTaskModel()).getData();

          if (angular.isObject(preLoadedData)) {
            lsnDeepExtend(self.taskData, preLoadedData);
          }
          if (angular.isObject(screensToSkip)) {
            self.screensToSkip = screensToSkip;
          }

          self.showNextNewTaskModal();
        };

        /**
         * Pokazuje kolejny modal tworzenia zapytania
         * na podstawie taskData
         * @return {undefined}
         */
        this.showNextNewTaskModal = function() {
          var clientTypes = [
            IhestiaDashboardObjectModelConstants.TYPE_CLIENT,
            IhestiaDashboardObjectModelConstants.TYPE_POLICY,
            IhestiaDashboardObjectModelConstants.TYPE_CLAIM,
            IhestiaDashboardObjectModelConstants.TYPE_OFFER,
            IhestiaDashboardObjectModelConstants.TYPE_APPLICATION
          ];

          self.screensToSkip.ihestiaDashboardNewTaskClientSearch = !!(self.isLife() && self.isCentral && !self.isUwr()
            && !_.includes([self.ehlTaskTopicDict.COMPLAINT_DASH, self.ehlTaskTopicDict.CONTACT_CLIENT_URGENT_DASH, self.ehlTaskTopicDict.APPLICATION_REPEAL_DASH], self.taskData.taskDefinitionCode));

          if (self.isUwr()) {
            self.screensToSkip.ihestiaDashboardNewTaskClientSearch = true;
            self.screensToSkip.ihestiaDashboardNewTaskReceiver = true;
          }

          if (self.taskData.taskDefinitionCode === 'z000193') {
            self.changeIcrCustomerPreLoadedData();
            newTaskIcrClientEditModalHelper.showModal(self.taskData).then(function() {
              $timeout(function() {
                newTaskIcrDataModalHelper.showModal(self.taskData).then(lsnNg.noop, lsnNg.noop);
              }).then(lsnNg.noop, lsnNg.noop);
            }, lsnNg.noop);
          } else if (!self.taskData.taskDefinitionId && !self.screensToSkip.ihestiaDashboardNewTaskSubject) {
            if (self.isSubjectSelectAvailable()) {
              _.assign(ihestiaDashboardModalsInjectorConfig.newTaskClientSearchModalSettings, {
                size: 'md',
                headerList: [{
                  value: 'person',
                  name: $filter('translate')('naturalPerson', {componentCode: 'Public'})
                }, {
                  value: 'organization',
                  name: $filter('translate')('company', {componentCode: 'Public'})
                }],
                headerListModel: 'modalData.subjectType'
              });
            } else {
              _.assign(ihestiaDashboardModalsInjectorConfig.newTaskClientSearchModalSettings, {
                size: 'sm',
                headerList: undefined,
                headerListModel: undefined
              });
            }
            iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskSubject');
          } else if (clientTypes.indexOf(self.taskData.object.type) > -1 && !self.taskData.object.client && !self.screensToSkip.ihestiaDashboardNewTaskClientSearch) {
            iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskClientSearch');
          } else if (self.isCentral && !self.taskData.operator && !self.screensToSkip.ihestiaDashboardNewTaskReceiver) {
            //zgodnie z https://ebok.atena.pl/browse/IHESTIADA-882
            if (self.taskData.taskDefinitionCode === 'NBK_INCIDENT') {
              iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskContent');
            } else {
              iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskReceiver');
            }
          } else {
            iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskContent');
          }
        };

        this.changeIcrCustomerPreLoadedData = function() {
          var dataMap = {
            email: 'object2ClientEmail',
            name: 'object2ClientName',
            documentNumber: 'object2ClientNumber',
            pesel: 'object2ClientPESEL',
            phoneNumber: 'object2ClientPhoneNumber',
            surname: 'object2ClientSurname'
          };
          if (self.fullTaskData === null || !angular.isObject(self.fullTaskData)) {
            return;
          }
          // clear model from company attributes & others
          angular.forEach(self.taskData.object.client, function(taskDataKey, modalKey) {
            delete self.taskData.object.client[modalKey];
          });
          self.taskData.object.client.type = 'PERSON';
          // map client
          angular.forEach(dataMap, function(taskDataKey, modalKey) {
            self.taskData.object.client[modalKey] = self.fullTaskData[taskDataKey];
          });
        };

        /**
         * Czyścimy dane wymieniane między modalami
         * @return {undefined}
         */
        this.clearTaskData = function() {
          self.taskData = null;
          self.context = 'DashboardMain';
          self.screensToSkip = {};
          self.fieldsToSkip = {};
        };

        /**
         * Ustawiamy odpowiedni obiekt na podstawie definicji zadania (wynika z tematu/podtematu)
         * @param {String} definitionType np. POLICY
         */
        this.setObjectByDefinitionType = function(definitionType) {
          var ObjectClass = IhestiaDashboardTaskModelConstants.OBJECT_CLASS_MAP[definitionType];
          if (!angular.isFunction(ObjectClass)) {
            ihestiaCommonErrorHandler.throwException('No class for definitionType: ' + definitionType);
          }
          var object = (new ObjectClass()).getData();
          if (self.isLife() && self.context === 'UWRTask' && self.taskData.object) {
            lsnDeepExtend(object, self.taskData.object);
          }
          self.taskData.object = object;
        };

        /**
         * Zapis zadania
         * @return {Promise}
         */
        this.saveTask = function() {
          var model = new IhestiaDashboardTaskModel();
          model.setData(self.taskData);
          ihestiaDashboardTaskHelper.minifyRestObject(model);
          var data = model.getData();
          return ihestiaDashboardTasksSvc.post(null, data, null, null, null, {
            allowedStatuses: [400]
          });
        };

        /**
         * Pobieramy definicje zadań realizowanych ręcznie
         * @return {promise}
         */
        this.getManualSubjects = function(context) {
          context = context ? context : 'DashboardMain';
          return $q(function(resolve, reject) {
            if (self.manualSubjects[context]) {
              resolve(self.manualSubjects[context]);
            } else {
              ihestiaDashboardTaskDefinitionsSvc.get('byContextName', null, context, function(res) {
                if (res && res.data) {
                  self.manualSubjects[context] = res.data;
                  /** TYMCZASOWE TŁUMACZENIA START */
                  self.regenerateUglyLangData();
                  /** TYMCZASOWE TŁUMACZENIA END */
                  resolve(self.manualSubjects[context]);
                } else {
                  reject();
                }
              }, reject);
            }
          });
        };

        this.isLife = function() {
          return ihestiaConfigHelper.get('ic').activeContext === 'EHL';
        };

        this.isUwr = function() {
          return ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().isUwr();
        };

        /**
         * TYMCZASOWE TŁUMACZENIA START
         */
        this.regenerateUglyLangData = function() {
          if (labelServiceHelper.isServiceTranslationActive()) {
            angular.forEach(self.manualSubjects.DashboardMain, function(subject) {
              subject.title = $filter('translate')('tmp.taskDefinition.title.' + subject.code, {
                componentCode: 'Temp'
              });
              if (angular.isArray(subject.subtitle)) {
                angular.forEach(subject.subtitle, function(subtitle, subtitleKey) {
                  subject.subtitle[subtitleKey] = $filter('translate')('tmp.taskDefinition.subtitle.' + subject.code + '.' + subtitleKey, {
                    componentCode: 'Temp'
                  });
                });
              }
            });
          }
        };
        labelActionHelper.registerAction('ihestiaDashboardTaskHelper', self.regenerateUglyLangData);
        /**
         * TYMCZASOWE TŁUMACZENIA END
         */

        /**
         *
         * @return {boolean}
         */
        this.isSubjectSelectAvailable = function() {
          return !ihestiaPlatformHelper.isJupiter() && !ihestiaPlatformHelper.isLifeContext();
        };

      };

      return new NewTaskHelper();
    }]);
