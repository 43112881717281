angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryComponentsSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var RestDictionaryComponentsSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.businessArea = 'permissions';
        this.resource = 'components';
      };
      return new RestDictionaryComponentsSvc();
    }
  ]);
