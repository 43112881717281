angular.module('ihestiaWidgets.policyDetails')
  .factory('editChildModalHelper', ['LsnModalHelper', 'IhestiaNotesAndUploader', 'ihestiaRestDictionaryFirstNamesSvc', 'ihestiaRestDictionaryLastNamesSvc', 'ihestiaSsoBaseInfoHelper', 'lifePolicyOperationsHelper', 'blockUiHelper', 'policyOperationsV2Svc', 'UserCharacterTypeConstants', 
    function(LsnModalHelper, IhestiaNotesAndUploader, ihestiaRestDictionaryFirstNamesSvc, ihestiaRestDictionaryLastNamesSvc, ihestiaSsoBaseInfoHelper, lifePolicyOperationsHelper, blockUiHelper, policyOperationsV2Svc, UserCharacterTypeConstants) {
      var EditChildModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        /**
         * extend modal options
         * @type {Object}
         */
        this.modalOptions = {
          size: 'md',
          title: ['Edycja danych dziecka', {
           
          }],
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/childHeader/modals/editChildModal.tpl.html',
          okBtnName: ['confirm', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }],
          withDefaultMargin: false
        };

        this.isCentral = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL;

        /**
         * sets initial modal options
         */
        this.setOptions(self.modalOptions);

        this.modalData = {
          fileModels: null,
          notes: null,
          policyNumber: null,
          policyStartDate: null,
          subject: null
        };

        this.tplData = {
          notesAndUploaderHelper: null,
          labels: {
            uploader: 'Załączniki',
            notes: 'Notatki'
          },
          showElements: {
            notes: null
          },
          canEditDocument: null,
          personData: {},
          services: {
            firstNamesSvc: ihestiaRestDictionaryFirstNamesSvc,
            lastNamesSvc: ihestiaRestDictionaryLastNamesSvc
          },
          errors: null,
          formApi: null
        };

        this.initData = function () {
          self.tplData.personData = _.cloneDeep(self.modalData.subject);
          self.tplData.showElements.notes = self.isCentral;
          self.tplData.canEditDocument = self.isCentral;
          self.tplData.errors = [];
          self.tplData.warnings = [];
          self.tplData.formApi = {};
          // override options in suitable mode
         var notesAndUploaderOptions = {
           policyNumber: self.modalData.policyNumber,
           fileModels: self.modalData.fileModels,
           notes: self.modalData.notes,
           showElements: {
            notes: false,
            uploader: true
          }
          };
          self.tplData.notesAndUploaderHelper = new IhestiaNotesAndUploader(notesAndUploaderOptions);
        };

        this.getNotesAndFilesForContext = function() {
          return {
            notes: self.isCentral ? self.tplData.notesAndUploaderHelper.getNotes() : null,
            fileIds: self.tplData.notesAndUploaderHelper.getFileServerIds()
          };
        };

        this.confirm = function () {
          if (!self.tplData.formApi.formRef.$dirty && self.tplData.formApi.formRef.$valid) {
            return null;
          }
          else if (self.tplData.formApi.formRef.$invalid) {
            self.tplData.formApi.formRef.formSubmitted = true;
            return null;
          }
          else {
            self.tplData.errors = [];
          }
          var notesAndFiles = self.getNotesAndFilesForContext();
          blockUiHelper.showBlockUi();
          self.saveChild(self.tplData.personData, notesAndFiles.notes, notesAndFiles.fileIds, self.modalData.policyNumber, self.modalData.policyStartDate)
          .then(function() {
              self.setPromiseAsResolved();
              blockUiHelper.hideBlockUi();
              return self.hideModal();
            }, function(rej) {
              blockUiHelper.hideBlockUi();
              _.forEach(_.get(rej, 'data.messages'), function(message) {
                if (message.type === 'ERROR') {
                  self.tplData.errors.push(message);
                }
              });
            });
          return null;
        };

        this.saveChild = function (subject, notes, files, policyNumber, policyStartDate) {
          var editInsuredRequest = lifePolicyOperationsHelper.mapEditChildRequest(subject, null, notes, files, policyNumber, policyStartDate);
          return policyOperationsV2Svc.post(policyNumber, editInsuredRequest, 'child');
        };

      };

      return new EditChildModalHelper();
    }
  ]);
