/**
 * Kontroler dla mocka alertu
 * @param  {[type]} $scope [description]
 * @return {[type]}        [description]
 */
angular.module('salesPath2')

  .controller('MockAlertCtrl', ['$scope', 
    function($scope) {
      $scope.mock = '';
      var appDynVals = $scope.dataContainer.application.get('dynamicValues');
      if (!appDynVals) {
        return;
      }

      if (appDynVals._mockRenewalOperation && appDynVals._mockPricePoint) {
        $scope.mock = 'mockRenewalOperation, mockPricePoint';
      } else if (appDynVals._mockRenewalOperation) {
        $scope.mock = 'mockRenewalOperation';
      } else if (appDynVals._mockPricePoint) {
        $scope.mock = 'mockPricePoint';
      } else {
        $scope.mock = '';
      }
    }
]);
