angular.module('ihestiaWidgets.policyDetails')
  .factory('LifePolicyOperationsConstants', ['PolicyTerminationConstants',
    function(PolicyTerminationConstants) {

      var CONSTANTS = {};
      CONSTANTS.PARTNER_TYPE_PERSON = 'P';
      CONSTANTS.PARTNER_TYPE_ORGANIZATION = 'O';
      CONSTANTS.OPERATION_CAUSE = {};
      CONSTANTS.OPERATION_CAUSE.EDIT_INSURED = 'InsuredDataChange';
      CONSTANTS.OPERATION_CAUSE.EDIT_CHILD = 'ChildDataChange';
      CONSTANTS.OPERATION_CAUSE.EDIT_BENEFICIARIES = 'BeneficiaryDataChange';
      CONSTANTS.OPERATION_CAUSE.POLICYHOLDER_REPLACEMENT = 'PolicyholderReplacement';
      // extending PolicyTerminationConstants cause codes with termination types for EHL
      _.assign(CONSTANTS.OPERATION_CAUSE, PolicyTerminationConstants.CAUSE_CODES);
      // this code is custom for policyTerminateTypeModal (to merge TerminationOnDate and TerminationWithFirstPaymentDate)
      CONSTANTS.OPERATION_CAUSE.TERMINATION = 'TerminationLife';

      CONSTANTS.DICTIONARY_PARTNER_TYPE = {};
      CONSTANTS.DICTIONARY_PARTNER_TYPE[CONSTANTS.PARTNER_TYPE_PERSON] = 'P';
      CONSTANTS.DICTIONARY_PARTNER_TYPE[CONSTANTS.PARTNER_TYPE_ORGANIZATION] = 'O';
      return CONSTANTS;
    }])
  .service('lifePolicyOperationsHelper', ['policyOperationsV2Svc', 'lsnModelHelper', 'lsnModelFactory', 'ihestiaConfigHelper', 'LifePolicyOperationsConstants', 'LsnContactModelConstants', 'LsnCommDataModelConstants',
    function(policyOperationsV2Svc, lsnModelHelper, lsnModelFactory, ihestiaConfigHelper, LifePolicyOperationsConstants, LsnContactModelConstants, LsnCommDataModelConstants) {
      var LifePolicyOperationsHelper = function() {
        var self = this;
        /**
         *
         * @param {object} address
         * @returns {LsnAddressModelV3}
         */
        this.mapAddress = function(address) {
          var addressV3 = lsnModelFactory.getObject('Address', 'v3');

          // copy form address to request address model
          _.assign(addressV3,
            _.pick(address, ['city', 'commune', 'street', 'region', 'district', 'streetPrefix', 'postalCode']),
            {
              houseNumber: address.house,
              apartmentNumber: address.apartment,
              countryCode: address.countryCode === null ? 'PL' : address.countryCode
            }, address.code ? {addressTypeCode: address.code} : null);
          return self.minifyRestObject(addressV3);
        };

        /**
         *
         * @param {object} person
         * @returns {InsuredPersonModel}
         */
        this.mapPerson = function(person) {
          var personModel = lsnModelFactory.getObject('InsuredPerson', {
            data: _.merge({},
              _.pick(person, ['firstName', 'lastName', 'gender', 'citizenshipCode', 'contacts']),
              {
                personId: person.pesel ? person.pesel : undefined,
                birthDate: self._dateIsoFormat(person.birthDate),
                identityDocuments: self.mapNotEmpty(person.documents, function(document) {
                  return {
                    documentTypeCode: document.code,
                    documentNumber: document.number,
                    validDate: document.validDate
                  };
                })
              })
          });
          return self.minifyRestObject(personModel);
        };

        this.mapOrganization = function(organization) {
          return {
            statisticalId: organization.regon,
            taxId: organization.nip,
            name: organization.name
          };
        };

        this.mapClause = function(clause) {
          return {
            clauseCode: clause.code.toUpperCase(),
            clauseValue: clause.value
          };
        };

        /**
         * Maps contacts to commData request model
         * @param {object} contact
         * @returns {CommDataModel}
         */
        this.mapCommData = function(contact) {
          var contactType = self._mapContactTypeToCommDataContactType(contact.code);
          if (contactType) {
            var commDataModel = lsnModelFactory.getObject('CommData', {
              data: {
                contactType: contactType,
                description: LsnCommDataModelConstants.DICTIONARY_CONTACT_TYPE[contactType],
                contactValue: contact.countryPrefix ? '({0}){1}'.format(contact.countryPrefix, contact.value || '') : contact.value,
                defaultContact: false
              }
            });
            return self.minifyRestObject(commDataModel);
          } else {
            return null;
          }
        };

        /**
         *
         * @param {object} subjectData
         * @param {string} subjectType
         * @param {object[]} addresses
         * @param {object[]} clauses
         * @param {object[]} contacts
         * @returns {BusinessPartnerModel}
         */
        this.mapBusinessPartner = function(subjectData, subjectType, addresses, clauses, contacts) {
          var businessPartnerData = lsnModelFactory.getObject('BusinessPartner', {
            data: {
              partnerType: subjectType,
              addresses: self.mapNotEmpty(addresses, self.mapAddress),
              clauses: self.mapNotEmpty(clauses, self.mapClause),
              commData: self.mapNotEmpty(contacts, self.mapCommData)
            }
          });

          if (subjectType === LifePolicyOperationsConstants.PARTNER_TYPE_PERSON) {
            businessPartnerData.set('personData', self.mapPerson(subjectData));
          } else {
            businessPartnerData.set('organizationData', self.mapOrganization(subjectData));
          }

          return self.minifyRestObject(businessPartnerData);
        };

        /**
         *
         * @param {object} personData
         * @param {object[]} clauses
         * @param {string} notes
         * @param {string[]} files
         * @param {string} policyNumber
         * @param {string} policyStartDate
         * @returns {EditInsuredRequestModel}
         */
        this.mapEditInsuredRequest = function(personData, clauses, notes, files, policyNumber, policyStartDate) {
          var validContacts = _.filter(personData.contacts, 'value');
          var editInsuredRequest = lsnModelFactory.getObject('EditInsuredRequest', {
            data: {
              operationData: {
                validFrom: self._resolveOperationStartDate(policyStartDate, self._getServerToday()),
                operCauseCode: LifePolicyOperationsConstants.OPERATION_CAUSE.EDIT_INSURED,
                policyRef: {
                  system: 'iHestia',
                  policyNumber: policyNumber
                },
                comment: !_.isEmpty(notes) ? notes : null,
                uploadedDocumentsIds: !_.isEmpty(files) ? files : null
              },
              logicalId: _.get(personData, 'metaData.id'),
              businessPartner: self.mapBusinessPartner(personData, LifePolicyOperationsConstants.PARTNER_TYPE_PERSON, personData.addresses, clauses, validContacts)
            }
          });
          return self.minifyRestObject(editInsuredRequest);
        };

        /**
         * maps beneficiaries and subjects from policy to request model for edit beneficiaries operation
         * @param {object[]} beneficiaries
         * @param {object[]} subjects
         * @param {object} additionalData - for Central, it is required to be defined, but added backup date just in case
         * @param {string} policyStartDate
         * @returns {EditBeneficiariesRequestModel}
         */
        this.mapEditBeneficiariesRequest = function(beneficiaries, subjects, additionalData, policyStartDate) {
          var editInsuredRequest = lsnModelFactory.getObject('EditBeneficiariesRequest', {
            data: {
              beneficiaries: _.map(beneficiaries, self.mapBeneficiary()),
              partners: _.map(subjects, self.mapBeneficiaryPartner),
              operCauseCode: LifePolicyOperationsConstants.OPERATION_CAUSE.EDIT_BENEFICIARIES,
              validFrom: _.has(additionalData, 'validFrom') ? self._dateIsoFormat(additionalData.validFrom)
                : self._resolveOperationStartDate(policyStartDate, self._getServerToday()),
              comment: additionalData.notes,
              uploadedDocumentsIds: !_.isEmpty(additionalData.files) ? additionalData.files : null
            }
          });
          return self.minifyRestObject(editInsuredRequest);
        };


        /**
         * Maps beneficiary to request model
         * For each risk present on policy there has to be a copy of each beneficiary!!
         * @returns {Function}
         */
        this.mapBeneficiary = function() {
          return function(beneficiary) {
            var beneficiaryModel = lsnModelFactory.getObject('RiskBeneficiary', {
              data: beneficiary
            });
            beneficiaryModel.set('partnerRef', beneficiary.subjectClientId);
            return {
              beneficiary: self.minifyRestObject(beneficiaryModel)
            };
          };
        };

        /**
         * Maps subject to request model with subject's id as sibling attribute with subject data
         * @param {object} subject
         * @returns {{businessPartner: BusinessPartnerModel, id: string}}
         */
        this.mapBeneficiaryPartner = function(subject) {
          var partnerType = self._getPartnerType(subject);
          var businessPartner = self.mapBusinessPartner(subject, partnerType, subject.addresses, null, null, null);
          return {
            businessPartner: self.minifyRestObject(businessPartner),
            id: _.get(subject, 'metaData.id')
          };
        };

        this.minifyRestObject = function(object) {
          lsnModelHelper.minifyRestObject(object);
          return object;
        };

        /**
         * Converts date string to ISO8601 format without timezone
         * @param {string} dateString
         * @returns {string | null}
         * @private
         */
        this._dateIsoFormat = function(dateString) {
          return dateString ? new XDate(dateString).toString('i') : dateString;
        };

        this._mapContactTypeToCommDataContactType = function(contactCode) {
          switch (contactCode) {
            case LsnContactModelConstants.CONTACT_TYPE_MAIL:
              return LsnCommDataModelConstants.CONTACT_TYPE_INT;
            case LsnContactModelConstants.CONTACT_TYPE_KOMO:
              return LsnCommDataModelConstants.CONTACT_TYPE_TEL;
            default:
              return null;
          }
        };

        this.mapNotEmpty = function(array, mapFn) {
          return !_.isEmpty(array) ? _.chain(array)
            .map(mapFn || lsnNg.noop)
            .filter(_.identity)
            .value() : undefined;
        };

        /**
         * Resolves which date is later and converts it to ISO format
         * @param {string} policyStartDate
         * @param {string} today
         * @returns {string} date literal in ISO format
         * @private
         */
        this._resolveOperationStartDate = function(policyStartDate, today) {
          return self._dateIsoFormat(policyStartDate > today ? policyStartDate : today);
        };

        this._getServerToday = function() {
          return ihestiaConfigHelper.get('serverData', 'TODAY');
        };

        /**
         *
         * @param {LsnOrganizationModelV2 | LsnPersonModelV2} subject
         * @return {string}
         * @private
         */
        this._getPartnerType = function(subject) {
          return _.get(subject, 'metaData.className') === 'Organization' ? LifePolicyOperationsConstants.PARTNER_TYPE_ORGANIZATION : LifePolicyOperationsConstants.PARTNER_TYPE_PERSON;
        };

        /**
         * Returns a call to renew policy protection
         * @param {string} policyNumber
         * @return {Promise<any>}
         */
        this.renewPolicy = function(policyNumber) {
          return policyOperationsV2Svc.post(policyNumber + '/restore', {
            operationData: {
              type: 'WZN',
              presentInHistory: true
            }
          });
        };

        /**
         *
         * @param {string} policyNumber
         * @param {LsnPersonModelV2 | LsnOrganizationModelV2} subjectData
         * @param {LsnAddressModelV3[]} addresses
         * @param {any[]} clauses
         * @param {any[]} contacts
         * @param {{
         *   showInHistory: boolean,
         *   comment: string,
         *   uploadedDocumentsIds: string[],
         *   validFrom: string
         * }} operationData
         * @returns {{operationData: {presentInHistory: *, uploadedDocumentsIds: (null|{elementsType: string, type: string}|string[]|{elementsType: string, type: string}), comment: (*|null), validForm: *, policyRef: {system: string, policyNumber: *}, operCauseCode: string}, policyholderData: {businessPartner: BusinessPartnerModel}}}
         */
        this.mapPolicyholderReplacementRequest = function(policyNumber, subjectData, addresses, clauses, contacts, operationData) {
          var partnerType = self._getPartnerType(subjectData);
          return {
            operationData: {
              validFrom: self._dateIsoFormat(operationData.validFrom),
              operCauseCode: LifePolicyOperationsConstants.OPERATION_CAUSE.POLICYHOLDER_REPLACEMENT,
              policyRef: {
                system: 'iHestia',
                policyNumber: policyNumber
              },
              presentInHistory: operationData.showInHistory,
              comment: !_.isEmpty(_.get(operationData, 'comment')) ? operationData.comment : null,
              uploadedDocumentsIds: !_.isEmpty(_.get(operationData, 'uploadedDocumentsIds')) ? operationData.uploadedDocumentsIds : null
            },
            policyholderData: {
              businessPartner: self.mapBusinessPartner(subjectData, partnerType, addresses, clauses, contacts)
            }
          };
        };

        /**
         * Check if property of item at path is truthy, returns function to use for example in filter
         * @param {string} propertyPath
         * @returns {function(any): boolean}
         */
        this.propertyNotEmpty = function(propertyPath) {
          return function(item) {
            return !!_.get(item, propertyPath);
          };
        };
        /**
         *
         * @param {object} personData
         * @param {object[]} clauses
         * @param {string} notes
         * @param {string[]} files
         * @param {string} policyNumber
         * @param {string} policyStartDate
         * @returns {EditInsuredRequestModel}
         */
        this.mapEditChildRequest = function(personData, clauses, notes, files, policyNumber, policyStartDate) {
          var validContacts = _.filter(personData.contacts, 'value');
          personData.documents = self.filterDocuments(personData.documents);
          var editInsuredRequest = lsnModelFactory.getObject('EditInsuredRequest', {
            data: {
              operationData: {
                validFrom: self._resolveOperationStartDate(policyStartDate, self._getServerToday()),
                operCauseCode: LifePolicyOperationsConstants.OPERATION_CAUSE.EDIT_CHILD,
                policyRef: {
                  system: 'iHestia',
                  policyNumber: policyNumber
                },
                comment: !_.isEmpty(notes) ? notes : null,
                uploadedDocumentsIds: !_.isEmpty(files) ? files : null
              },
              logicalId: _.get(personData, 'metaData.id'),
              businessPartner: self.mapBusinessPartner(personData, LifePolicyOperationsConstants.PARTNER_TYPE_PERSON, personData.addresses, clauses, validContacts)
            }
          });
          return self.minifyRestObject(editInsuredRequest);
        };

        this.filterDocuments = function (documents) {
          return documents.filter(function (doc) {
            return doc.code !== null;
          });
        };
      };

      return new LifePolicyOperationsHelper();
    }
  ]);
