angular.module('ihestiaRestServicesBase')
.service('fileServerSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef)
{
  var FileServer = function ()
  {
    IHestiaRestServiceAbstractDef.apply(this, arguments);

    var self = this;
    this.resource = 'files';
    this.businessArea = 'fileServer';
    this.port = '8220';
    this.path = 'RestFileServer';
  
    /**
    * Usługa do zapisu pliku w FileServer.
    *
    * @param {String} tokenId Token do zapisu pliku.
    * @param {Object} data
    * @param {Object} additionalData dodatkowe dane do ustawienia w opowiedzi
    * @param {Object} contentRange
    * @param {function} callback
    * @returns {*}
    */
   this.sendFile = function(tokenId, data, additionalData, callback){
     self.additionalHeaders['Content-Type'] = 'multipart/form-data; boundary=' + additionalData.boundary;
     self.additionalHeaders['Content-Range'] = 'bytes ' + additionalData.start + '-' +
             ((additionalData.end>additionalData.size)?additionalData.size-1:additionalData.end) + '/' + additionalData.size;

     self.post(tokenId, data, null, callback);

     delete self.additionalHeaders['Content-Type'];
     delete self.additionalHeaders['Content-Range'];
   };
  
    /**
     * Usługa do notyfikacji serwera o rezygnacji z przesyłania pliku
     *
     * @param {String} tokenId Token do zapisu pliku.
     * @param {function} callback
     * @returns {*}
     */
    this.putCanceled = function (tokenId, callback) {
      return self.put(tokenId, null, 'canceled', callback);
    };


  };
  return new FileServer();
}]);