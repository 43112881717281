angular.module('ihestiaMenuBase')
  .provider('ihestiaMenuBaseConfig', [
    function() {

      var provider = this;

      //tu możemy ustawić dodatkowe elementy które pojawią w górnej belce menu po prawej stronie
      this.customTopRightElements = [];

      this.setCustomTopRightElementVisibility = function(code, visible)
      {
        angular.forEach(provider.customTopRightElements, function(element){
          if(element.code === code)
          {
            element.visible = !!visible;
          }
        });
      };

      this.$get = [function(){
        return provider;
      }];
    }
  ]);