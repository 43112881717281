angular.module('salesPath2')
  .factory('PolicyDiscountsModel', [
    function () {

      /*eslint-disable camelcase */
      
      var PolicyDiscountsModel = function () {
        this.employee = {
          selected: null, //Boolean
          attributes: {
            discountEmployee: null, //Integer
            discountGeneralAgreement: null //Integer
          }
        };
        this.agreement_DeviatedPremium = {
          selected: null, //Boolean
          attributes: {
            deviatedPremiumAgreementNumber: null, //String
            deviatedPremiumDescription: null, //String
            agreement_DeviatedPremium_GrantBasis: [], //[String, ..]
            deviatedPremiumDecisionMakerName: null, //String
            _agreementMinimalPremiumReduced: null //Boolean
          }
        };
        this.agreement_AuthorizationExceeded = {
          selected: null, //Boolean
          attributes: {
            authorizationExceededAgreementNumber: null, //String
            authorizationExceededDescription: null, //String
            agreement_AuthorizationExceeded_GrantBasis: [], //[String, ..]
            authorizationExceededDecisionMakerName: null //String
          }
        };
        this.discountOnRisk = [];
        this.promoCodes = {
          code: null,
          selected: null //Boolean
        };
      };

      /*eslint-enable camelcase */

      return PolicyDiscountsModel;
    }
  ]);