angular.module('salesPath2')
  .factory('RequestApplicationsPolicyModelConstants', [function () {
    var RequestApplicationsPolicyModelConstants = {};

    RequestApplicationsPolicyModelConstants.REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS_PAPER = 'PAPER';
    RequestApplicationsPolicyModelConstants.REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS_SCAN = 'SCAN';
    RequestApplicationsPolicyModelConstants.DICTIONARY_REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS = {};
    RequestApplicationsPolicyModelConstants.DICTIONARY_REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS[RequestApplicationsPolicyModelConstants.REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS_PAPER] = 'papierowy';
    RequestApplicationsPolicyModelConstants.DICTIONARY_REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS[RequestApplicationsPolicyModelConstants.REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS_SCAN] = 'elektroniczny';
    return RequestApplicationsPolicyModelConstants;
  }])
  .factory('RequestApplicationsPolicyModel', ['LsnAbstractModel', 'RequestApplicationsPolicyModelConstants',
    function (LsnAbstractModel, RequestApplicationsPolicyModelConstants) {

      var RequestApplicationsPolicyModel = function () {
        this.objectName = 'RequestApplicationsPolicy';

        this.fieldsProperties = {
          settlementDocuments: {
            type: 'string',
            dictionary: RequestApplicationsPolicyModelConstants.DICTIONARY_REQUEST_APPLICATIONS_POLICY_SETTLEMENTS_DOCUMENTS
          },
          signDate: {
            type: 'date'
          },
          place: {
            type: 'string'
          }
        };

        this.settlementDocuments = null; //Kod metody rozliczenia dokumentów polisy. Dostępne kody:PAPER - papierowy, SCAN - elektroniczny
        this.signDate = null; //Data podpisania umowy. Pole wymagane przy ztwierdzaniu wniosku ubezpieczeniowego w statusie oferty.
        this.place = null; //Miejsce podpisa umowy. Pole wymagane przy ztwierdzaniu wniosku ubezpieczeniowego w statusie oferty.


      };

      RequestApplicationsPolicyModel.prototype = LsnAbstractModel.prototype;

      return RequestApplicationsPolicyModel;
    }
  ]);