angular.module('salesPath2')
  .factory('TariffFactorsModel', [
    function ()
    {

      var TariffFactorsModel = function ()
      {
        /**
         * @type float skladka laczna za wszystkie ryzyka
         */
        this.totalPremium = 0;
        /**
         * @type float skladka laczna do dopłaty
         */
        this.totalExtendPremium = 0;
        /**
         * @type Array nazwy ryzyk
         */
        this.names = [];
        /**
         * @type FactorRowModel[] blok 1 - nagßówki - czyli czynniki taryfikacyjne inne niż kalkulacje
         */
        this.headers = [];
        /**
         * @type FactorRowModel[] czynniki kalkulacyjne - wyliczenia/zniżki/zwyżki
         */
        this.calculations = [];
        /**
         * @type FactorRowModel[] podsumowania - łączne składki itd.
         */
        this.summaries = [];
        /**
         * @type FactorRowModel[] szczegóły - np. dla dokupień
         */
        this.details = [];
      };

      return TariffFactorsModel;
    }
  ]);