angular.module('lsnBase.models')
  .factory('LsnOfferSummaryModelV2', ['LsnAbstractModel', 'LsnOfferModelConstants', 'LsnPolicyModelConstants', 'lsnModelFactory',
    function(LsnAbstractModel, LsnOfferModelConstants, LsnPolicyModelConstants, lsnModelFactory) {

      var OfferSummaryModel = function() {
        this.objectName = 'OfferSummary';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          metaData: {
            type: 'MetaData'
          },
          number: {
            type: 'string'
          },
          persons: {
            type: 'array',
            elementsType: 'Person',
            version: 'v2'
          },
          vehicles: {
            type: 'array',
            elementsType: 'Vehicle'
          },
          estates: {
            type: 'array',
            elementsType: 'Estate',
            version: 'v2'
          },
          symbol: {
            type: 'string'
          },
          expirationDateForCustomer: {
            type: 'dateTime'
          },
          expirationDateForAgent: {
            type: 'dateTime'
          },
          previousPolicyNumber: {
            type: 'string'
          },
          previousPolicyNumbers: {
            type: 'array',
            elementsType: 'string'
          },
          renewalVariant: {
            type: 'string',
            dictionary: LsnOfferModelConstants.DICTIONARY_OFFER_RENEWAL_VARIANT
          },
          policyPropositionSummaries: {
            type: 'array',
            elementsType: 'PolicyPropositionSummary',
            version: 'v2'
          },
          isBasicRenewalVariant: {
            type: 'bool',
            defaultValue: false
          },
          isDemandPayment: {
            type: 'bool',
            defaultValue: false
          },
          generalAgreementsNumbers: {
            type: 'array',
            elementsType: 'string'
          }
        };

        this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}});
        this.number = null; // string, numer polisy
        this.persons = null; // lista Person, Lista osób. MetaData.Id musi być unikalne w obrębie person i organization
        this.vehicles = null; // lista Vehicle, Ubezpieczany pojazd. Maksymalnie jeden na polisie
        this.estates = null; // lista Estate, Lista nieruchomości do ubezpieczenia
        this.symbol = null; // symbol
        this.expirationDateForCustomer = null; // date, Data ważności oferty dla klienta
        this.expirationDateForAgent = null; // date, Data ważności oferty dla agenta
        this.previousPolicyNumber = null; // string, numer poprzedniej polisy w przypadku oferty odnowieniowej
        this.previousPolicyNumbers = null; // array, Lista numerów polis które podlegają rozszerzeniu
        this.renewalVariant = null; // string, Wariant wznowienia
        this.policyPropositionSummaries = null; // array, Lista polis składających się na ofertę - z pogrupowanymi ryzykami
        this.isBasicRenewalVariant = null; // bool Czy oferta jest wznowieniem podstawowym czy rozszerzonym
        this.isDemandPayment = null; // bool Czy oferta wymaga opłacenia do akceptacji
        this.generalAgreementsNumbers = null; // array, Lista numerów umów generalnych których dotyczy ioferta
      };

      OfferSummaryModel.prototype = LsnAbstractModel.prototype;

      return OfferSummaryModel;
    }
  ]);
