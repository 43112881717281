angular.module('salesPath2.spd')
.factory('spdApplication', [
  function() {
    var application = {};
    application.objectsDynamicValues = {
      _selectedVariants: ['Table', 'String'], //dataManager.selectedVariants
      _selectedAdditions: ['Table', 'String'], //dataManager.selectedAdditions
      _selectedExtensions: ['Table', 'String'], //dataManager.selectedExtensions
      _suList: ['Table', 'String'], //dataManager.suList
      _oczpProtectionDates: ['Table', 'String'], //dataManager.oczpProtectionDates
      _nnwProtectionDates: ['Table', 'String'], //dataManager.nnwProtectionDates
      _nnwIncognito: ['Table', 'String'], //dataManager.nnwIncognito
      _defaultStartDate: ['Table', 'String'], //dataManager.defaultStartDate
      _defaultEndDate: ['Table', 'String'], //dataManager.defaultEndDate
      _propertyProtectionDates: ['Table', 'String'],
      _greenCardNumber: ['Table', 'String'],
      _calculateEstatesSeparately: ['Simple', 'Boolean'], //czy dzielić wniosek po nieruchomosciach
      _externalData: ['Simple', 'String'], //atrybut z zewnatrz
      _renewedApplication: ['Simple', 'String'], //inicjalny wniosek wznowieniowy
      _protectionPeriodCode: ['Simple', 'String'], //okres ochrony
      typeOfAgreement: ['Simple', 'Integer'], //okres ochrony/rodzaj umowy wg BOS
      _patronClauseVisible: ['Simple', 'Boolean'], //czy prezentowac klauzule K5 - opiekun klienta
      _patronIKontoLinkVisible: ['Simple', 'Boolean'], //czy prezentowac link do ikonta do zmiany OK
      _agentDiscountId: ['Simple', 'String'], //id zniżki agencyjnej
      _operationId: ['Simple', 'Integer'], //id wniosku zakupu pojazdu
      vehicleInEtx: ['Simple', 'Boolean'], //!!tymczasowo czy pojazd z eurotax (solo ockom)
      _renewalCalendarDate: ['Simple', 'Date'], //proponowana data odroczenia płatności przy wznowieniach
      _mockRenewalOperation: ['Simple', 'Boolean'], //flaga informujaca o tym czy wniosek zostal stworzony przy uzyciu mocka renewalOperation
      _mockPricePoint: ['Simple', 'Boolean'], //flaga informujaca o tym czy wniosek zostal stworzony przy uzyciu mocka pricePoint
      _productClausesRemoved: ['Simple', 'Boolean'] //flaga informujaca czy nalezy wyczyscic klauzule produktowe,
    };

    return application;
  }])
;