angular.module('ihestiaIkomunikator')
  .controller('ihestiaIkomunikatorSearchMessagesCentralCtrl', ['$scope', 'ihestiaIkomunikatorAdvancedFilterHelper', 'ikomunikatorSearchMessagesCentralListHelper',
    function($scope, ihestiaIkomunikatorAdvancedFilterHelper, ikomunikatorSearchMessagesCentralListHelper) {
      $scope.subjects = [];

      $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaIkomunikatorSearchMessagesCentral', function() {
        ihestiaIkomunikatorAdvancedFilterHelper.getFilter().clearAllData();
        ikomunikatorSearchMessagesCentralListHelper.clearAllData();
      });

      $scope.init();
    }
  ]);