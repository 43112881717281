angular.module('ihestiaWidgets.dashboard')
  .controller('ihestiaDashboardNewTaskClientEditModalCtrl', ['$scope', 'ihestiaDashboardNewTaskHelper', 'IhestiaDashboardClientModel', '$timeout', 'iHestiaCommonModalHelper', 'IhestiaDashboardClientConstants', 'IhestiaDashboardObjectModelConstants', '$filter', 'ihestiaDictionaryHelper', 'IhestiaDashboardEhlTaskTopicDict',
    function($scope, ihestiaDashboardNewTaskHelper, IhestiaDashboardClientModel, $timeout, iHestiaCommonModalHelper, IhestiaDashboardClientConstants, IhestiaDashboardObjectModelConstants, $filter, ihestiaDictionaryHelper, IhestiaDashboardEhlTaskTopicDict) {

      $scope.taskData = ihestiaDashboardNewTaskHelper.taskData;
      $scope.objectModelConstants = IhestiaDashboardObjectModelConstants;
      $scope.clientData = {
        nationality: 'PL'
      };

      $scope.modalData = {
        clientType: 'individual',
        isForeigner: false,
        applicationSlashPolicyNumber: null
      };

      $scope.clientTypes = null;

      $scope.ehnClientTypes = [{
        name: $filter('translate')('naturalPerson', {
          componentCode: 'Public'
        }),
        code: 'individual'
      }, {
        name: $filter('translate')('oneManBusiness', {
          componentCode: 'Dashboard'
        }),
        code: 'jdg'
      }, {
        name: $filter('translate')('legalPerson', {
          componentCode: 'Public'
        }),
        code: 'organization'
      }];

      $scope.ehlClientTypes = [{
        name: $filter('translate')('naturalPerson', {
          componentCode: 'Public'
        }),
        code: 'individual'
      }, {
        name: $filter('translate')('legalPerson', {
          componentCode: 'Public'
        }),
        code: 'organization'
      }];

      $scope.nationalities = [];
      $scope.identityDocumentTypes = [];
      $scope.isLife = ihestiaDashboardNewTaskHelper.isLife();
      $scope.isCentral = ihestiaDashboardNewTaskHelper.isCentral;
      $scope.ehlTaskTopicDict = IhestiaDashboardEhlTaskTopicDict;
      $scope.showElements = {
        policyNumber: false,
        applicationSlashPolicyNumber: false
      };
      $scope.tplData = {
        policyNumberRequired: false
      };

      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        $scope.clientTypes = !$scope.isLife ? $scope.ehnClientTypes : $scope.ehlClientTypes;
        $scope.loadDicts();
        $scope.showElements.policyNumber = $scope.resolvePolicyNumber();
        $scope.showElements.applicationSlashPolicyNumber = $scope.isLife && $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.COMPLAINT_DASH && !$scope.isCentral;
        $scope.tplData.policyNumberRequired = $scope.isLife && $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.CLIENT_POLICY_DASH || $scope.taskData.taskDefinitionCode === $scope.ehlTaskTopicDict.PAYMENTS_DASH;
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaDashboardNewTaskClientEdit', function($event) {
          $event.preventDefault();
          $scope.confirmClient();
        });
      };

      /**
       * Ładowanie słowników
       * @return {undefined}
       */
      $scope.loadDicts = function() {
        $scope.$emit('showBlockUi');
        var loadedDicts = {
            nationalities: false,
            identityDocumentTypes: false
          },
          tryToHideBlockUi = function() {
            var canHide = true;
            angular.forEach(loadedDicts, function(isLoaded) {
              if (!isLoaded) {
                canHide = false;
              }
            });
            if (canHide) {
              $scope.$emit('hideBlockUi');
            }
          };

        ihestiaDictionaryHelper.getDict('nationalities', {
          requestType: 'searches',
          callback: function(nationalities) {
            $scope.nationalities = nationalities;
            loadedDicts.nationalities = true;
            tryToHideBlockUi();
          }
        });
        ihestiaDictionaryHelper.getDict('identityDocumentTypes', {
          requestType: 'searches',
          callback: function(identityDocumentTypes) {
            $scope.identityDocumentTypes = identityDocumentTypes;
            loadedDicts.identityDocumentTypes = true;
            tryToHideBlockUi();
          }
        });
      };

      /**
       * Inny klient
       * @return {undefined}
       */
      $scope.chooseAnotherClient = function() {
        iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskClientEdit');
        $timeout(function() {
          iHestiaCommonModalHelper.showModal('ihestiaDashboardNewTaskClientSearch');
        }, 0);
      };

      $scope.resolvePolicyNumber = function() {
        var ehlVisibilityCondition = true;
        if ($scope.isLife) {
          ehlVisibilityCondition = [
            $scope.ehlTaskTopicDict.PAYMENTS_DASH,
            $scope.ehlTaskTopicDict.CLIENT_POLICY_DASH].indexOf($scope.taskData.taskDefinitionCode) > -1;
        }
        return ehlVisibilityCondition && $scope.taskData.object.type === $scope.objectModelConstants.TYPE_POLICY;
      };

      /**
       * Wybór klienta
       * @return {undefined}
       */
      $scope.confirmClient = function() {
        $scope.formSubmitted = true;
        if ($scope.createTaskForm && $scope.createTaskForm.$valid) {
          // przypisanie danych
          var clientModel = $scope.mapClientModel();
          $scope.taskData.object.client = clientModel.getData();
          $scope.taskData.object.claimNumber = $scope.modalData.claimNumber;
          $scope.taskData.object.policyNumber = $scope.modalData.policyNumber;
          _.set($scope.taskData, 'attributes.complaintObjectNumber', $scope.modalData.applicationSlashPolicyNumber);

          // odpalenie kolejnego okna
          iHestiaCommonModalHelper.hideModal('ihestiaDashboardNewTaskClientEdit');
          $timeout(function() {
            ihestiaDashboardNewTaskHelper.selectedClientFromList = false;
            ihestiaDashboardNewTaskHelper.showNextNewTaskModal();
          }, 0);
        }
      };

      /**
       *
       * @return {IhestiaDashboardClientModel}
       */
      $scope.mapClientModel = function() {
        var clientModel = new IhestiaDashboardClientModel();
        var clientData = {
          pesel: $scope.modalData.clientType !== 'organization' ? $scope.clientData.pesel : null,
          name: $scope.modalData.clientType !== 'organization' ? $scope.clientData.firstName : null,
          companyName: $scope.modalData.clientType === 'organization' ? $scope.clientData.name : null,
          surname: $scope.modalData.clientType !== 'organization' ? $scope.clientData.lastName : null,
          nip: $scope.modalData.clientType !== 'individual' ? $scope.clientData.nip : null,
          regon: $scope.modalData.clientType !== 'individual' ? $scope.clientData.regon : null,
          type: $scope.modalData.clientType === 'organization' ? IhestiaDashboardClientConstants.TYPE_COMPANY : IhestiaDashboardClientConstants.TYPE_PERSON,
          nationality: $scope.modalData.isForeigner ? $scope.clientData.nationality : null,
          documentType: $scope.modalData.isForeigner ? $scope.clientData.documentType : null,
          documentNumber: $scope.modalData.isForeigner ? $scope.clientData.documentNumber : null
        };
        clientModel.setData(clientData);
        return clientModel;
      };

      $scope.init();
    }
  ]);
