angular.module('salesPath2')
  .service('bonusMalusModalHelper', ['mainDataContainer', 'CONSTANTS', 'sp2CommonHelper', 'BonusMalusDataObjectModel', 'CONFIG', 'bonusMalusHelper', '$filter', 'appVariables', 'supplementHelper',
    function(mainDataContainer, CONSTANTS, sp2CommonHelper, BonusMalusDataObjectModel, CONFIG, bonusMalusHelper, $filter, appVariables, supplementHelper) {
      var BonusMalusModalHelper = function() {
        var self = this;

        this.modals = {
          vehicleBmModal: {
            settings: {
              treatLabelAsI18nCode: true,
              okBtnDisabled: false,
              title: ['bm.modal.title.bonusMalus', {
                componentCode: 'sp2',
                params: {
                  name: $filter('translate')('noData', {
                    componentCode: 'Public'
                  })
                }
              }],
              size: 'md',
              okBtnName: ['Public.save', {
                componentCode: 'Public'
              }]
            }
          },
          verifyInfoModal: {
            settings: {
              treatLabelAsI18nCode: true,
              title: ['bm.modal.title.ownerDataVerification', {
                componentCode: 'sp2'
              }],
              okBtnName: ['Public.confirm', {
                componentCode: 'Public'
              }],
              cancelBtnName: '',
              keyboard: false,
              size: 'md'
            }
          }
        };

        var DEFAULT_BM_MODAL_DATA = {
          acPreviouslySelected: false,
          ocPreviouslySelected: false,
          risks: {
            OC: null,
            AC: null
          },
          personData: {},
          vehicleId: null,
          personId: null,
          dataCleared: true,
          readonly: false //czy w trybie RO
        };

        if (CONFIG.BEHAVIOR.bonusMalusRisks) {
          this.risks = CONFIG.BEHAVIOR.bonusMalusRisks;
          DEFAULT_BM_MODAL_DATA.acAvailable = bonusMalusHelper.risks.indexOf('AC') > -1;
          DEFAULT_BM_MODAL_DATA.ocAvailable = bonusMalusHelper.risks.indexOf('OC') > -1;
        }

        /**
         * reakcja na akcje w aplikacji
         */
        this._afterAction = function() {
          // EHNLEARN-981
          // if (actionName === 'verifyBonusMalusInfo' && angular.isObject(arguments[1])) {
          //   self.showPopup(arguments[1].personId, arguments[1].vehicleId);
          // }
        };

        this.bmModalData = {};

        this.currentPersonId = null;
        this.currentVehicleId = null;

        /**
         * Wyczyszczenie danych bm dla zadanej osoby/pojazdu
         * @param  {int} personId  [description]
         * @param  {int} vehicleId [description]
         * @return {BonusMalusHelper}           [description]
         */
        this.clearBmModalData = function(personId, vehicleId) {
          if (!self.bmModalData[personId]) {
            self.bmModalData[personId] = {};
          }
          self.bmModalData[personId][vehicleId] = angular.copy(DEFAULT_BM_MODAL_DATA);
          angular.forEach(self.risks, function(risk) {
            self.bmModalData[personId][vehicleId].risks[risk] = new BonusMalusDataObjectModel();
          });
          return self;
        };

        /*
         * wyświetla modal edycji danych BM dla wybrango pojazdu
         */
        this.showPopup = function(personId, vehicleId) {
          if (!angular.isString(vehicleId)) {
            vehicleId = self.getVehicleIdByPersonId(personId);
            if (vehicleId === null) {
              sp2CommonHelper.throwException('Nie znaleziono pojazdu powiązanego z osobą (clientId: {0}).'.format(personId));
            }
          }

          self.clearBmModalData(personId, vehicleId);
          self.currentPersonId = personId;
          self.currentVehicleId = vehicleId;

          var person = mainDataContainer.persons[personId];
          var name = '';
          if (person.firstName || person.lastName) {
            if (person.firstName) {
              name += person.firstName + ' ';
            }
            if (person.lastName) {
              name += person.lastName;
            }
          } else {
            name += $filter('translate')('noData', {
              componentCode: 'Public'
            });
          }
          self.modals.vehicleBmModal.settings.title = ['bm.modal.title.bonusMalus', {
            componentCode: 'sp2',
            params: {
              'name': name.trim()
            }
          }];
          self.modals.vehicleBmModal.settings.okBtnName = ['Public.save', {
            componentCode: 'Public'
          }];
          self.modals.vehicleBmModal.settings.cancelBtnName = ['sp2.modal.cancel', {
            componentCode: 'sp2'
          }];
          if (appVariables.isSupplement) {
            var modalData = self.bmModalData[personId][vehicleId];
            modalData.ocPreviouslySelected = supplementHelper.isRiskPreviouslySelected(CONSTANTS.RISK_OC, CONSTANTS.RISK_OC, vehicleId);
            modalData.acPreviouslySelected = supplementHelper.isRiskPreviouslySelected(CONSTANTS.RISK_AC, CONSTANTS.RISK_AC, vehicleId);
            if ((modalData.ocPreviouslySelected || !modalData.ocAvailable) && (modalData.acPreviouslySelected || !modalData.acAvailable)) {
              self.modals.vehicleBmModal.settings.okBtnName = ['Public.close', {
                componentCode: 'Public'
              }];
              self.modals.vehicleBmModal.settings.cancelBtnName = '';
            }
          }
          self.bmModalData[personId][vehicleId].readonly = appVariables.readOnly && !appVariables.isOcBuyer;
          if (self.bmModalData[personId][vehicleId].readonly) {
            self.modals.vehicleBmModal.settings.okBtnName = ['Public.close', {
              componentCode: 'Public'
            }];
            self.modals.vehicleBmModal.settings.cancelBtnName = '';
          }
          sp2CommonHelper.showModal('vehicleBmModal');
        };

        /**
         * zwraca pierwszy z brzegu clientId pojazdu powiązanego z daną osobą
         * @param  {String} personId clientId osoby
         * @return {String|null} clientId pojazdu lub null gdy nie znaleziono
         */
        this.getVehicleIdByPersonId = function(personId) {
          var vehicleId = null;
          var selectedVehicle = [CONSTANTS.ALL_OBJECTS, CONSTANTS.NO_OBJECT].indexOf(mainDataContainer.selectedVehicle) !== -1 ? null : mainDataContainer.selectedVehicle;
          /**
           * czy osoba jest wspolwlascicielem na pojezdzie
           * @param  {String}  vId clientId pojazdu
           * @param  {String}  pId clientId osoby
           * @return {Boolean}
           */
          var isCoowner = function(vId, pId) {
            var coowners = mainDataContainer.vehicles[vId].getAdditionalData('coowners');
            if (!angular.isArray(coowners)) {
              return false;
            }
            return (coowners.indexOf(pId) !== -1);
          };
          if (selectedVehicle !== null && (mainDataContainer.mainInsuredId === personId || isCoowner(selectedVehicle, personId))) //wybrany aktualnie pojazd
          {
            return selectedVehicle;
          } else //bierzemy pierwszy z brzegu
          {
            lsnNg.forEach(mainDataContainer.vehicles, function(vehicle, vId) {
              if (mainDataContainer.mainInsuredId === personId || isCoowner(vId, personId)) //właściciel bądź współwłaściciel pojazdu
              {
                vehicleId = vId;
                return false;
              }
              return true;
            });
          }
          return vehicleId;
        };

      };

      return new BonusMalusModalHelper();
    }
  ]).run(['bonusMalusModalHelper', 'actionHelper',
  function(bonusMalusModalHelper, actionHelper) {
    actionHelper.registerHelper('bonusMalusModalHelper', bonusMalusModalHelper);
  }
]);