angular.module('ihestiaRestServicesBase')

  /**
   * serwis REST do obsługi OSS intermediary (opiekun klienta czyli agencja)
   * @return {[type]} [description]
   */
  .factory('ihestiaOssIntermediarySvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
      var IHestiaIntermediary = function ()
      {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'simple/intermediary';
        this.businessArea = 'oss';
        this.sortBy = '';
        this.pageStyle = false;
      };

    return new IHestiaIntermediary();
  }])
  ;
