angular.module('ihestiaWidgets.policyDetails')
  .service('policyDetailsContextHelper', ['ihestiaSsoBaseInfoHelper', 'UserCharacterTypeConstants', function(ihestiaSsoBaseInfoHelper, UserCharacterTypeConstants) {
    var PolicyDetailsContextHelper = function() {
      this.isClient = function() {
        var curentCharacterType = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;
        return curentCharacterType === UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT;
      };

      this.isCentral = function() {
        var curentCharacterType = ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type;
        return curentCharacterType === UserCharacterTypeConstants.CHARACTER_TYPE_CENTRAL;
      };
    };
    return new PolicyDetailsContextHelper();
  }]);
