/**
 * Ukrywa element przez visibility:hidden
 */
angular.module('ihestiaCommonDirectives')
  .directive('commonIsVisible', [function(){
      return {
        restrict: 'A',
        link: function($scope, $element, $attrs){
          $scope.$watch($attrs.commonIsVisible, function(isVisible){
            if(isVisible) {
                $element.removeClass('invisible');
              } else {
                $element.addClass('invisible');
              }
          });
        }
      };
    }]);