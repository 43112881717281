angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryFirstNamesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryFirstNamesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'firstnames';
        this.sortBy = 'name';

      };
      return new RestDictionaryFirstNamesSvc();
    }
  ]);