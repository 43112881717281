/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .provider('iHestiaMenuLeftNavbarHelper', function() {
    var iHestiaMenuLeftNavbarHelper = this;

    /**
     * opcje
     * @type {Object}
     */
    this.settings = {
      openLvl: 'none', // none|part|all  
      preventClose: false,
      templateUrl: 'ihestia-menu-template-app/leftNavBar/navbar.tpl.html',
      hideCallback: lsnNg.noop
    };

    /**
     * Ukrywamy menu
     * @return {[type]} [description]
     */
    this.hide = function() {
      var settings = iHestiaMenuLeftNavbarHelper.settings;
      if (!settings.preventClose) {
        settings.openLvl = 'none';
        settings.hideCallback();
      }
      settings.preventClose = false;
    };

    /**
     * Magic get
     * @return {Object}
     */
    this.$get = function() {
      return iHestiaMenuLeftNavbarHelper;
    };
  });