//pseudosłownik, bo zwrotny content jest stały, ale zależny od parametrów
//dla elektronicznych dokumentów parametry są stałe, więc dla GUI jest to słownik
//slownik zawiera wszystkie elektroniczne typy dokumentów

angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryAllElectronicDocumentTypesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryAllElectronicDocumentTypesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        var self = this;

        this.businessArea = 'files';
        this.resource = 'documentTypes';

        this._parentMethods = {
          get: this.get
        };

        /**
         * Pobieranie rekordu
         *
         * @param {String|Integer} id
         * @param {*} filters
         * @param {String} subResource
         * @returns {*}
         */
        this.get = function(id, filters, subResource, callback, errorCallback, httpParams) {
          var internalfilters = {
            settlementMethods: 'electronic'
          };

          if(angular.isObject(filters)){
            angular.extend(internalfilters, filters);
          }
          return self._parentMethods.get(id, internalfilters, subResource, callback, errorCallback, httpParams);
        };


      };
      return new RestDictionaryAllElectronicDocumentTypesSvc();
    }
  ]);