angular.module('ihestiaRestServicesBase')
.factory('GenderConstants', [function() {
    var GenderConstants = {};

    GenderConstants.GENDER_MALE = 'M';
    GenderConstants.GENDER_FEMALE = 'F';
    //słownik płci
    GenderConstants.DICTIONARY_GENDER = {};
    GenderConstants.DICTIONARY_GENDER[GenderConstants.GENDER_MALE] = 'mężczyzna';
    GenderConstants.DICTIONARY_GENDER[GenderConstants.GENDER_FEMALE] = 'kobieta';

    return GenderConstants;
  }]);