/**
 * Mapper podmiotów (osoby, firmy)
 */
angular.module('salesPath2').service('toRestSubjectMapper', ['MapperUtils', 'SPD', 'PersonModel', 'OrganizationModel', 'CONSTANTS', 'lsnDeepExtend', 'extensionHelper', 'CONFIG', 'GroupModel',
  function (MapperUtils, SPD, PersonModel, OrganizationModel, CONSTANTS, lsnDeepExtend, extensionHelper, CONFIG, GroupModel)
  {
    var ToRestSubjectMapper = function ()
    {
      var self = this;
      this.utils = MapperUtils;
      //#pragma mark persons
      /**
       * mapuje osoby
       * @param {DataContainer} dataContainer zmapowane ryzyka
       * @return {Person[]} zmapowane osoby
       */
      this.mapPersons = function (dataContainer)
      {
        var mappedPersons = [];
        angular.forEach(dataContainer.persons, function (person, id)
        {
          var personObj = new PersonModel();
          personObj.metaData.set('clientId', id);
          self._mapPerson(personObj, person, dataContainer);
          mappedPersons.push(personObj);
        });
        return mappedPersons;
      };
      /**
       * mapuje osobe z dataContainera do osoby na polisie/wniosku
       * @param {PersonModel} person osoba z wniosku/polisy
       * @param {PersonModel} dataContainer
       * @param {DataManager} dmPerson osoba z dataContainera
       * @return {PersonModel} osoba do wniosku/polisy
       */
      this._mapPerson = function (person, dmPerson, dataContainer)
      {
        var data = {
            dynamicValues:
            {}
          };
        var personId = person.metaData.get('clientId');
        if (self._isPropertyOwner(personId, dataContainer))
        {
          data.dynamicValues.propertyOwner = true;
        } else {
          data.dynamicValues.propertyOwner = false;
        }
        var addData = dmPerson.getAdditionalData();
        if (addData !== null)
        {
          data.dynamicValues._additionalData = self.utils.serializeValue(addData);
        }
        var dmData = dmPerson.getData(false, true);
        data = lsnDeepExtend( dmData, data);
        person.setData(data);
        self.utils.setTemporaryDynamicValuesForObject(person);
        self.addExtensionsDynamicValues(person);
        //person.setData zawsze doda adres dla pustej lub null'owej kolekcji(angular.lsnBase/src/app/models/filters/addressFilter.js:25)
        if (self.isAddressesUnavailable(personId, dataContainer)){
          person.addresses = null;
        }
      };

      this.isAddressesUnavailable = function (personId, dataContainer){
        return CONFIG.METADATA.applicationSymbol === 'EP' && 
            parseInt(personId, 10) !== parseInt(dataContainer.insurerId, 10) &&
            parseInt(personId, 10) !== parseInt(dataContainer.mainInsuredId, 10);
      };
      //czy user jest (wspol)wlascicielem nieruchomosci
      this._isPropertyOwner = function (dmId, dataContainer)
      {
        var coowner = false;
        if (!angular.equals(dataContainer.localizations, {}))
        {
          if (dataContainer.mainInsuredId === dmId)
          {
            return true;
          }
          lsnNg.forEach(dataContainer.localizations, function (localization)
          {
            if (localization.getAdditionalData('coowners').indexOf(dmId) !== -1)
            {
              coowner = true;
              return false;
            }
            return true;
          });
        }
        return coowner;
      };
      //#pragma mark organizations
      /**
       * mapuje firmy/banki
       * @param {DataContainer} dataContainer zmapowane ryzyka
       * @return {Organization[]} zmapowane osoby
       */
      this.mapOrganizations = function (dataContainer)
      {
        var mappedOrganizations = [];
        lsnNg.forEach(dataContainer.organizations, function (org, id)
        {
          if (angular.isUndefined(org) || angular.isUndefined(org.objectName) || org.objectName !== 'Organization')
          { //nigdy nie powinno sie zdarzyc
            return;
          }
          var orgObj = new OrganizationModel();
          orgObj.metaData.set('clientId', id);
          self._mapOrganization(orgObj, org, dataContainer);
          mappedOrganizations.push(orgObj);
        });
        return mappedOrganizations;
      };
      /**
       * mapuje firme z dataContainera do firmy na polisie/wniosku
       * @param {OrganizationModel} organization firma z wniosku/polisy
       * @param {OrganizationModel} dmOrganization firma z dataContainera
       * @param {DataContainer} dataContainer
       * @return {OrganizationModel} firma do wniosku/polisy
       */
      this._mapOrganization = function (organization, dmOrganization, dataContainer)
      {
        var data = {
            dynamicValues:
            {}
          },
          type = self._getOrganizationType(organization.metaData.get('clientId'), dataContainer);
        if (type !== null)
        {
          data.dynamicValues.type = type;
        }
        var addData = dmOrganization.getAdditionalData();
        if (addData !== null)
        {
          data.dynamicValues._additionalData = self.utils.serializeValue(addData);
        }
        var dmData = dmOrganization.getData();
        data = lsnDeepExtend( dmData, data);
        organization.setData(data);
        self.utils.setTemporaryDynamicValuesForObject(organization);
      };

      /**
       * pobiera typ organizacji na podstawie podpięcia organizacji do obiektów ubezpieczenia
       * Czyli na chwilę obecną zwróci leasing lub null (bank - cesjonariusz obsługiwany osobno)
       * @param  {String} dmId clientId organizacji
       * @param  {Object} dataContainer
       * @return {null|String}
       */
      this._getOrganizationType = function (dmId, dataContainer)
      {
        var type = null;
        if (!angular.equals(dataContainer.vehicles, {}))
        {
          lsnNg.forEach(dataContainer.vehicles, function (vehicle)
          {
            if (vehicle.getAdditionalData('leaserId') !== null && vehicle.getAdditionalData('leaserId') === dmId)
            {
            type = CONSTANTS.ORGANIZATION_TYPE_LEASING;
              return false;
            }
            return true; //continue
          });
        }
        return type;
      };

      //#pragma mark groups
      /**
       * mapuje grupy osób
       * @param {DataContainer} dataContainer zmapowane ryzyka
       * @return {Organization[]} zmapowane grupy
       */
      this.mapGroups = function (dataContainer)
      {
        var mappedGroups = [];
        lsnNg.forEach(dataContainer.groups, function (gr, id)
        {
          if (angular.isUndefined(gr) || angular.isUndefined(gr.objectName) || gr.objectName !== 'Group')
          { //nigdy nie powinno sie zdarzyc
            return;
          }
          var groupObj = new GroupModel();
          groupObj.metaData.set('clientId', id);
          groupObj.setData(gr.getData());
          self.addExtensionsDynamicValues(groupObj);
          mappedGroups.push(groupObj);
        });
        return mappedGroups;
      };

      /**
       * dodane atrybuty dynamiczne zqiązane z rozszerzeniami
       * @param {*} subject podmiot
       */
      this.addExtensionsDynamicValues = function(subject) {
        if (!CONFIG.MODULES.extensions) {
          return;
        }
        var availableExts = extensionHelper.getAvailableExtensions(subject.metaData.get('clientId')),
          anyExtAvailable = false,
          _extensionSelected = null; //wartość dla RESTów
        lsnNg.forEach(availableExts, function(available) {
          if (available) {
            anyExtAvailable = true;
            return false;
          }
          return true; //continue
        });
        if (anyExtAvailable) { //rozszerzenie jest dostepne dla tego podmiotu
          var selectedExts = extensionHelper.getSelectedExtensionsForObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, subject.metaData.get('clientId'));
          if (selectedExts.length === 0) {
            _extensionSelected = false;
          } else {
            _extensionSelected = true;
          }
        }
        subject.set('dynamicValues', {_extensionSelected: _extensionSelected});
      };
    };

    return new ToRestSubjectMapper();
  }
]);