angular.module('lsnBase.models')
  .factory('LsnApplicationAllowedChangesRisksModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {


      var LsnApplicationAllowedChangesRisksModelV2 = function() {
        this.objectName = 'ApplicationAllowedChangesRisks';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;

        this.fieldsProperties = {
          newRisks: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesObject'
          },
          existingRisks: {
            type: 'array',
            elementsType: 'ApplicationAllowedChangesExistingRisksObject',
            version: 'v2'
          }
        };

        this.newRisks = null; // ryzyka nowe
        this.existingRisks = null; // ryzyka już istniejące
      };

      LsnApplicationAllowedChangesRisksModelV2.prototype = LsnAbstractModel.prototype;

      return LsnApplicationAllowedChangesRisksModelV2;
    }
  ]);