angular.module('ihestiaWidgets.policyTermination')
  .controller('policyTerminationEhlCtrl', ['$scope', 'ihestiaAutoUploadOneListUploadHelper', 'fileServerSvc', '$filter', '$alert', 'PolicyTerminationConstants', 'ihestiaConfigHelper', 'policyOperationsHelper', 'policiesSvc', 'policyTerminationHelper',
    function($scope, ihestiaAutoUploadOneListUploadHelper, fileServerSvc, $filter, $alert, PolicyTerminationConstants, ihestiaConfigHelper, policyOperationsHelper, policiesSvc, policyTerminationHelper) {

      $scope.formData = {
        note: null,
        declaration: false,
        operationDate: null,
        terminationCause: null
      };

      $scope.TerminationTypes = [PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_FIRST_PAYMENT, PolicyTerminationConstants.CAUSE_CODES.TERMINATION_ON_DATE];

      $scope.requiredFields = {
        files: null
      };

      $scope.disabledFields = {
        operationDate: true // served from backend, but we initially set it to false
      };

      $scope.tplData = {
        formSubmitted: null,
        terminationCauses: null,
        minDate: ihestiaConfigHelper.get('serverData', 'TODAY'),
        validFromCache: {
          'TerminationOnDate': null,
          'TerminationWithFirstPaymentDate': null
        },
        validFromErrors: null,
        showNotes: null,
        showFileUpload: null
      };

      $scope.TYPES = null;
      $scope.policyNumber = null;
      $scope.calendarDateRange = null;
      $scope.translations = null;

      $scope.init = function() {
        $scope.policyNumber = $scope.policy.number;
        // default declaration selected for Central (only visible for Cancellation)
        $scope.formData.declaration = $scope.isCentral;
        $scope.TYPES = PolicyTerminationConstants.TYPES;
        $scope._initShowElements();
        $scope.resolveTranslations();
        $scope.changePageTitleLabel();
        $scope.initPersons();
        $scope.initUploader();
        // $scope.requiredFields.files = !$scope.isCentral && $scope.terminationType === $scope.TYPES.WITHDRAWAL;

        if ($scope.terminationType !== $scope.TYPES.CANCELLATION) {
          $scope.initOperationDate()
            .then(lsnNg.noop, lsnNg.noop);
        }
        if ($scope.terminationType === $scope.TYPES.TERMINATION) {
          $scope.initAvailableTerminationTypes();
        }
      };

      /**
       * Initializes insured person and policy holder person
       */
      $scope.initPersons = function() {
        angular.forEach($scope.policy.persons, function(person) {
          if (person.metaData && person.metaData.id) {
            if ($scope.policy.holderRef === person.metaData.id) {
              $scope.holder = person;
            }
            if ($scope.policy.insuredRef === person.metaData.id) {
              $scope.insured = person;
            }
          }
        });
        // insurer can be organization
        if (!$scope.holder) {
          $scope.holder = _.find($scope.policy.organizations, ['metaData.id', $scope.policy.holderRef]);
        }
      };

      /**
       * Initializes uploader. If no uploader options are passed, creates default options.
       */
      $scope.initUploader = function() {
        if (!$scope.uploaderOptions) {
          $scope.uploaderOptions = $scope.getDefaultUploaderOptions('policyCancellation');
        }
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
            onFileUploadAdd: lsnNg.noop,
            onAllFileSend: lsnNg.noop,
            onErrorAddingFile: lsnNg.noop
          },
          null, {
            autoUpload: true,
            firstCheckAllFiles: false
          });

        $scope.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.documentType);
      };

      /**
       * Valid operation date and its editability is fetched from service
       * @param {string?} operCauseCode
       * @return Promise<{validFrom: Date, editable: boolean} | null>
       */
      $scope.initOperationDate = function(operCauseCode) {
        var requestOperCauseCode = operCauseCode || policyOperationsHelper.getOperCauseCodeFromTerminationType($scope.terminationType);
        $scope.tplData.validFromErrors = null;
        $scope.disabledFields.operationDate = true;
        $scope.formData.operationDate = null;
        return policyOperationsHelper.getTerminationValidFrom(requestOperCauseCode, $scope.policyNumber)
          .then(function(res) {
            if (_.has(res, 'data.validFrom')) {
              $scope.formData.operationDate = new XDate(res.data.validFrom).toString('yyyy-MM-dd');
              $scope.disabledFields.operationDate = !res.data.editable;
              return {
                validFrom: angular.copy($scope.formData.operationDate),
                editable: res.data.editable
              };
            } else {
              return null;
            }
          }, function(res) {
            $scope.tplData.validFromErrors = _.get(res.data, 'messages', null);
            $scope.formData.operationDate = null;
            $scope.tplData.minDate = null;
            $scope.disabledFields.operationDate = true;
            return null;
          });
      };

      /**
       * Set custom pageTitleLabels if there is a difference with default
       */
      $scope.changePageTitleLabel = function() {
        var label;
        if ($scope.terminationType === $scope.TYPES.WITHDRAWAL) {
          label = ['printoutName.ODSTAPIENIE', {componentCode: 'Public'}];
          $scope.$emit('setPageTitle', {
            pageTitleLabel: label
          });
          policyTerminationHelper.title = policyTerminationHelper.titleLabel = $filter('translate')(_.first(label), _.last(label));
        }
        if ($scope.terminationType === $scope.TYPES.NOTICE) {
          label = ['policyTerminationEhl.noticePageTitle', {componentCode: 'PolicyOperations'}];
          $scope.$emit('setPageTitle', {
            pageTitleLabel: label
          });
          policyTerminationHelper.title = policyTerminationHelper.titleLabel = $filter('translate')(_.first(label), _.last(label));
        }
        if ($scope.terminationType === $scope.TYPES.TERMINATION) {
          label = ['policyTerminationEhl.terminationPageTitle', {componentCode: 'PolicyOperations'}];
          $scope.$emit('setPageTitle', {
            pageTitleLabel: label
          });
          policyTerminationHelper.title = policyTerminationHelper.titleLabel = $filter('translate')(_.first(label), _.last(label));
        }
      };

      /**
       * Required for uploader
       * @param id
       */
      $scope.deleteFile = function(id) {
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      /**
       *
       * @param {string} uploaderName
       * @returns {{showMessage: policyTerminationEhlCtrl.showMessage, multiple: boolean, name: *, singleFileUploads: boolean, messages: {acceptFileTypes: *, maxFileSize: *}, hideQueueList: boolean, url: *, maxChunkSize: number, autoUpload: boolean}}
       */
      $scope.getDefaultUploaderOptions = function(uploaderName) {
        return {
          url: fileServerSvc._getServiceUrl(),
          autoUpload: false,
          singleFileUploads: true,
          // acceptFileTypes: /(\.|\/)(csv)$/i,
          maxChunkSize: 4000000,
          // hideProgressBar: true,
          multiple: true,
          messages: {
            acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
            maxFileSize: $filter('translate')('Public.toLargeFile')
          },
          showMessage: function(msg) {
            $alert({
              content: msg,
              type: 'warning'
            });
          },
          name: uploaderName,
          hideQueueList: true
        };
      };

      $scope.resolveTranslations = function() {
        $scope.translations = {};
        $scope.translations[$scope.TYPES.WITHDRAWAL] = {
          uploader: ['policyTermination.withdrawalUploadLabel', 'PolicyOperations'],
          operationDate: ['policyTerminationEhl.withdrawalOperationDate', 'PolicyOperations']
        };
        $scope.translations[$scope.TYPES.CANCELLATION] = {
          uploader: ['policyCancellation.uploadLabel', 'PolicyOperations'],
          operationDate: null
        };
        $scope.translations[$scope.TYPES.NOTICE] = {
          uploader: ['policyTermination.noticeUploadLabel', 'PolicyOperations'],
          operationDate: ['policyTerminationEhl.noticeOperationDate', 'PolicyOperations']
        };
        $scope.translations[$scope.TYPES.TERMINATION] = {
          uploader: ['policyTerminationEhl.terminationUploadLabel', 'PolicyOperations'],
          operationDate: ['policyTerminationEhl.noticeOperationDate', 'PolicyOperations'] // the same as notice
        };
      };

      $scope.initAvailableTerminationTypes = function() {
        if ($scope.stateParams.availableTerminationTypes) {
          $scope.tplData.terminationCauses = $scope.stateParams.availableTerminationTypes;
        } else {
          policiesSvc.get($scope.policyNumber, null, 'availableTerminationCauses/')
            .then(function(res) {
              if (_.isArray(res.data)) {
                $scope.tplData.terminationCauses = _.filter(res.data, function(cause) {
                  return $scope.TerminationTypes.indexOf(cause.policyOperationCauseCode) >= 0;
                });
              } else {
                $scope.tplData.terminationCauses = [];
              }
            });
        }
      };

      $scope.onTerminationCauseChanged = function() {
        var terminationCause = $scope.formData.terminationCause;
        $scope.tplData.validFromErrors = null;
        // check if valid from for selected termination was already fetched
        if ($scope.tplData.validFromCache[terminationCause]) {
          var editable = _.get($scope.tplData.validFromCache[terminationCause], 'editable');
          $scope.disabledFields.operationDate = !editable;
          $scope.setOperationDate(_.get($scope.tplData.validFromCache[terminationCause], 'validFrom'), editable);
        } else {
          $scope.initOperationDate(terminationCause)
            .then(function(response) {
              $scope.tplData.validFromCache[terminationCause] = angular.copy(response);
            }, lsnNg.noop);
        }
      };

      $scope.setOperationDate = function(date, editable) {
        $scope.formData.operationDate = date;
        if (editable) {
          // IHESTLIFE-4788 no min date if editable
          $scope.tplData.minDate = null;
        } else {
          $scope.tplData.minDate = date;
        }
      };

      $scope._initShowElements = function() {
        switch ($scope.terminationType) {
          case $scope.TYPES.CANCELLATION:
            $scope.tplData.showFileUpload = $scope.tplData.showNotes = $scope.isCentral;
            break;
          case $scope.TYPES.WITHDRAWAL:
          case $scope.TYPES.NOTICE:
            $scope.tplData.showFileUpload = true;
            $scope.tplData.showNotes = $scope.isCentral;
            break;
          case $scope.TYPES.TERMINATION:
            $scope.tplData.showFileUpload = true;
            $scope.tplData.showNotes = true;
            break;
          default:
            break;
        }
      };

      /**
       * Emits event on forward button clicked
       * @param {boolean} force
       */
      $scope.onForward = function(force) {
        $scope.tplData.validFromErrors = null;
        $scope.tplData.formSubmitted = true;
        $scope.forward({
          force: force,
          data: {
            isFormValid: $scope.terminationForm.$valid,
            fileModels: $scope.fileModels,
            comment: $scope.formData.comment,
            operationDate: $scope.terminationType !== $scope.TYPES.CANCELLATION && $scope.formData.operationDate ?
              new XDate($scope.formData.operationDate).toString('i') : null,
            terminationCause: $scope.terminationType === $scope.TYPES.TERMINATION ? $scope.formData.terminationCause : null
          }
        });
      };

      $scope.init();
    }]);
