/**
 * Dyrektywa - glownie do wyrzucenia html'a
 * @param  {[type]} $templateCache      [description]
 * @param  {[type]} iHestiaMenuSettings [description]
 * @param  {[type]} $parse)             {                                      var directive = {                                                                            template: $templateCache.get('ihestia-menu-template-app/base/base.tpl.html') [description]
 * @param  {[type]} link:               function($scope, $element, $attrs) {                         iHestiaMenuSettings.settings = $parse($attrs.ihestiaMenuBase)($scope);                                                                                                  $attrs.$observe('ihestiaMenuBase', function(value) {            iHestiaMenuSettings.settings = $parse(value)($scope);          });        }      };      return directive;    }  ] [description]
 * @return {[type]}                     [description]
 */
angular.module('ihestiaMenuBase')
  .directive('ihestiaMenuBase', ['$templateCache', 'iHestiaMenuSettings', '$parse',
    function($templateCache, iHestiaMenuSettings, $parse) {
      var directive = {
        template: $templateCache.get('ihestia-menu-template-app/base/base.tpl.html'),
        link: function($scope, $element, $attrs) {
          var menuSettings = $parse($attrs.ihestiaMenuBase)($scope);
          if(angular.isDefined(menuSettings))
          {
            iHestiaMenuSettings.settings = menuSettings;
          }

          $attrs.$observe('ihestiaMenuBase', function(value) {
            menuSettings = $parse(value)($scope);
            if(angular.isDefined(menuSettings))
            {
              iHestiaMenuSettings.settings = menuSettings;
            }
          });
        }
      };

      return directive;
    }
  ]);