angular.module('ihestiaWidgets.dashboard')
  .factory('IhestiaDashboardApplicationObjectModel', ['IHestiaAbstractModel', 'IhestiaDashboardObjectModelConstants',
    function(IHestiaAbstractModel, IhestiaDashboardObjectModelConstants) {
      var ApplicationObjectModel = function() {
        this.objectName = 'IhestiaDashboardApplicationObjectModel';

        this.fieldsProperties = {
          id: {
            type: 'string'
          },
          version: {
            type: 'int'
          },
          type: {
            type: 'string'
          },
          applicationId: {
            type: 'string'
          },
          applicationNumber: {
            type: 'string'
          },
          client: {
            type: 'IhestiaDashboardClient'
          },
          segment: {
            type: 'string'
          }
        };

        this.id = null;
        this.version = null;
        this.type = IhestiaDashboardObjectModelConstants.TYPE_APPLICATION;
        this.applicationNumber = null;
        this.segment = null;
        this.client = null;

      };

      ApplicationObjectModel.prototype = IHestiaAbstractModel.prototype;

      return ApplicationObjectModel;
    }]);