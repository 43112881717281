/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuLeftNavbar')
  .provider('iHestiaMenuLeftNavbarItems', ['ihestiaAgHelperProvider', 'ihestiaIcContextHelperProvider', '$windowProvider',
    function(ihestiaAgHelperProvider, ihestiaIcContextHelperProvider, $windowProvider) {
      var self = this;

      /**
       * Domyślne elementy menu
       * @type {Array}
       */
      this.items = [];
      this.items.push({
        code: 'iKomunikator',
        name: 'iKomunikator',
        class: 'icon-solo icon-osoby',
        // permissionCode: 'IKOMUNIKATOR',
        urlCode: 'IKOMUNIKATOR_URL',
        disabled: false
      });

      // IHESTLIFE-4354
      if(!ihestiaIcContextHelperProvider.getInstance().isLifeActiveContext()) {
        this.items.push({
          code: 'iMonitor',
          name: 'iMonitor',
          class: 'icon-solo icon-numkal',
          // permissionCode: 'IMONITOR',
          urlCode: ihestiaAgHelperProvider.hasPermissionFor('Public', 'AppiMonitorEhm') ? 'IMONITOR_EHM_URL' : 'IMONITOR_URL',
          disabled: false
        });
      }

      this.items.push({
        code: 'integratedSystems',
        items: [{
          code: 'iPegaz',
          name: 'iPegaz',
          // permissionCode: 'IPEGAZ',
          class: '',
          urlCode: 'IPEGAZ_URL',
          disabled: false,
          target: '_blank',
          visible: true
        }, {
          code: 'myPoints',
          name: 'Moje punkty',
          class: '',
          urlCode: 'MY_POINTS_URL',
          disabled: false,
          visible: true
        }, {
          code: 'trainingPlatform',
          name: 'Platforma szkoleniowa',
          class: '',
          urlCode: 'TRAINING_PLATFORM_URL',
          disabled: false,
          target: '_blank',
          visible: true
        }, {
          code: 'iodPlatform',
          name: 'Informacja o dystrybutorze',
          class: '',
          urlCode: 'IOD_PLATFORM_URL',
          disabled: false,
          target: '_blank',
          visible: ihestiaAgHelperProvider.hasPermissionFor('Public', 'AppIod')
        }, {
          code: 'EH24',
          name: 'EH24',
          class: '',
          disabled: false,
          target: '_blank',
          visible: true,
          onClick: function(item, $event) {
            $windowProvider.$get().open('https://eh24.ergohestia.pl', item.target ? item.target : '_self');
            $event.stopPropagation();
            return true; // hide menu
          }
        }, {
          code: 'ocean',
          name: 'Portal ERGO Hestii',
          class: '',
          urlCode: 'OCEAN_URL',
          disabled: false,
          target: '_blank',
          visible: ihestiaAgHelperProvider.hasPermissionFor('Public', 'AppOcean')
        }
        ],
        name: 'Systemy zintegrowane',
        class: 'icon-solo icon-matryca',
        disabled: true
      });

      this.items.push({
        code: 'solicitedReports',
        name: 'Zamówione raporty',
        class: 'icon-sl-fill icon-file-table',
        urlCode: 'PORTAL_URL',
        urlPostfix: 'external/solicitedReports/',
        disabled: false
      });

      /**
       * Rozszerze ustawienia wybranego elementu o odpowiednia ustawienia
       * @param  {String} itemCode np. 'iKomunikator'
       * @param  {Object} settings ustawienia (jak dla każdej pozycji w self.items)
       * @return {iHestiaMenuLeftNavbarItems}          self
       */
      this.extendItem = function(itemCode, settings) {
        angular.forEach(self.items, function(item){
          if(item.code === itemCode) {
            angular.exted(item, settings);
          }
        });

        return self;
      };

      /**
       * Magic get
       * @return {Object}
       */
      this.$get = [
        function() {
          return self;
        }];
    }]);