angular.module('ihestiaWidgets.pza')
  .factory('ihestiaPzachooseConditionsModalHelper', ['iHestiaCommonModalHelper', 'ihestiaPzaConditionSvc', 'ihestiaPzaConditionConstants',
    function(iHestiaCommonModalHelper, ihestiaPzaConditionSvc, ihestiaPzaConditionConstants) {
      var ChooseConditionsModalHelper = function() {
        var self = this;

        /**
         * Wybrane warunki
         * @type {Array}
         */
        this.selectedConditions = {};

        /**
         * Słownik warunków
         * @type {Array}
         */
        this.conditions = [];

        /**
         * Do tego callbacka zostaną przekazane wybrane warunki
         * @type {Function}
         */
        this.callbackFunc = null;

        /**
         * Parametry wyszukiwania
         * @type {Object}
         */
        this.listParams = {
          statusCode: ihestiaPzaConditionConstants.STATUS_ACTIVE
        };

        /**
         * Inicjalne ładowanie warunków - posiadamy wszystkie warunki
         * @type {Boolean}
         */
        this.firstLoad = true;

        /**
         * Pozbywamy się współdzielonych z kontrolerem danych
         * @return {[type]} [description]
         */
        this.clearData = function() {
          self.conditions = [];
          self.firstLoad = true;
          self.listParams = {
            statusCode: ihestiaPzaConditionConstants.STATUS_ACTIVE
          };
          self.selectedConditions = {};
          self.callbackFunc = null;

          return self;
        };

        this.loadConditions = function() {
          return ihestiaPzaConditionSvc.get(null, self.listParams).then(function(res) {
            if (res && (res.status === 200 || res.status === 204)) {
              if(self.firstLoad) {
                self.conditions = res.data;
                self.firstLoad = false;
              }
              return res.data;
            } else {
              return [];
            }
          }, angular.noop);
        };

        /**
         * Przekazujemy warunki
         * @return {ChooseConditionsModalHelper} self
         */
        this.passConditions = function() {
          var conditionList = [];
          angular.forEach(self.conditions, function(condition){
            if(self.selectedConditions[condition.id]) {
              conditionList.push(condition);
            }
          });
          self.callbackFunc(conditionList);

          return self;
        };

        /**
         * Pokazanie modala
         * @param {array} conditionList [{condition: conditionModel}]
         * @param {Function} callback funkcja, do której zostaną przekazane wybrane warunki
         * @return {ChooseConditionsModalHelper} self
         */
        this.showChooseConditionModal = function(conditionList, callback) {
          iHestiaCommonModalHelper.showModal('chooseConditionsModal');
          self.callbackFunc = callback;
          angular.forEach(conditionList, function(condition){
            self.selectedConditions[condition.condition.id] = true;
          });
          return self;
        };
      };

      return new ChooseConditionsModalHelper();
    }]);