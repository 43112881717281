angular.module('ihestiaSsoBase.baseInfo')

  .factory('ihestiaSsoReloggedUserCharactersCurrentSvc', [ 'abstractPlatformSessionSvc',
    function(abstractPlatformSessionSvc) {

    var ReloggedUserCharactersCurrentSvc = function()
    {
      abstractPlatformSessionSvc.apply(this, arguments);
      var self = this;
      this.resource = 'reloggeduser/characters/current';

      /**
       * zmiana tożsamości
       * @param  {[type]} character       na jaką zmieniamy
       * @param  {[type]} successCallback
       */
      this.changeCharacter = function(character, successCallback)
      {
        self.put('', {
          Name: character.name,
          Code: character.code
        }, null, successCallback);
      };

    };

    return new ReloggedUserCharactersCurrentSvc();
  }])
  ;