angular.module('lsnBase.bootstrap')
  .provider('simpleOAuthConfig', [
    function() {

      var provider = this;

      /**
       * Czy wyświetlać główny link absolutny (zazwyczaj inna główna aplikacja)
       * @type {Boolean}
       */
      this.clientId = ''; // np. MarynaMobile / StandardApplicationMobile

      this.$get = [function(){
        return provider;
      }];
    }
  ]);