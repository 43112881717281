angular.module('ihestiaWidgets.pza')
  .controller('ihestiaPzaConditionDefinitionVehicleSectionCtrl', ['$scope', 'IhestiaPzaVehicleConditionDefinitionModel', 'ihestiaRestVehicleDictionaryBrandsSvc',
    function($scope, IhestiaPzaVehicleConditionDefinitionModel, ihestiaRestVehicleDictionaryBrandsSvc) {

      /**
       * Usuwamy wiersz z pojazdem
       * @param  {int} vehicleIndex index wiersza z pojazdem
       * @return {undefined}
       */
      $scope.removeVehicle = function(vehicleIndex) {
        $scope.options.vehicles.splice(vehicleIndex, 1);
        $scope.validatorFieldsChanged();
      };

      /**
       * Dodajemy wiersz z pojazdem
       * @return {undefined}
       */
      $scope.addVehicle = function() {
        $scope.options.vehicles.push((new IhestiaPzaVehicleConditionDefinitionModel()).getData());
        $scope.validatorFieldsChanged();
      };

      /**
       * Zwracanie typu pojazdu
       * @param {String} query
       * @return {String[]}
       */
      $scope.getRegistrationTypes = function(query) {
        var types = [];
        angular.forEach($scope.dicts.registrationTypes, function(registrationType) {
          if (registrationType[0].toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1) {
            types.push(registrationType[0]);
          }
        });
        return types;
      };

      /**
       * Autocompeter marek
       * @param {String} query
       * @return {String[]}
       */
      $scope.getMarks = function(query) {
        return ihestiaRestVehicleDictionaryBrandsSvc.find({
          Value: query
        }).then(function(res){
          var marks = [];
          if(res && res.status === 201 && res.data && angular.isArray(res.data.items)) {
            angular.forEach(res.data.items, function(item){
              if(marks.indexOf(item[0]) === -1) {
                marks.push(item[0]);
              }
            });
          }

          return marks;
        }, angular.noop);
      };

      /**
       * Blur na marce - jeśli nic nie jest wpisane, to dajemy "*", czyli wszystkie
       * @param  {object} vehicleData dane wiersza z pojazdem
       * @return {undefined}
       */
      $scope.checkVehicleMake = function(vehicleData) {
        if (vehicleData.make === '') {
          vehicleData.make = '*';
        }
      };
    }
  ]);