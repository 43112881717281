angular.module('ihestiaWidgets.policyOperations')
  .controller('vehiclePurchaseTarrificationDataCtrl', ['$scope', 'customerVehiclesSvc', 'ihestiaConfigHelper', '$alert', 'fileServerSvc', '$filter', 'ihestiaAutoUploadOneListUploadHelper', 'labelActionHelper',
    function($scope, customerVehiclesSvc, ihestiaConfigHelper, $alert, fileServerSvc, $filter, ihestiaAutoUploadOneListUploadHelper, labelActionHelper) {

      $scope.uploaderOptions = {
        url: fileServerSvc._getServiceUrl(),
        autoUpload: false,
        singleFileUploads: true,
        // acceptFileTypes: /(\.|\/)(csv)$/i,
        maxChunkSize: 4000000,
        // hideProgressBar: true,
        multiple: true,
        messages: {
          acceptFileTypes: $filter('translate')('Public.incorrectFileType'),
          maxFileSize: $filter('translate')('Public.toLargeFile')
        },
        showMessage: function(msg) {
          $alert({
            content: msg,
            type: 'warning'
          });
        },
        name: $scope.uploaderName,
        hideQueueList: true
      };

      $scope.lastWithdrawalDate = null;

      $scope.currentRecordData = {
        baseOwner: null,
        settlementNotEmpty: false
      };

      $scope.init = function() {
        labelActionHelper.registerAction('vehiclePurchaseTarrificationDataCtrl', $scope.onChangeLang);
        if($scope.visible.organizationTarrificationData)
        {
          if($scope.currentRecord.withdrawalDate)
          {
            $scope.lastWithdrawalDate = $scope.currentRecord.withdrawalDate;
          }
          else
          {
            $scope.lastWithdrawalDate = null;
          }
        }

        $scope.refreshCurrentRecord($scope.currentRecordData, $scope.currentRecord, $scope.currentRecordNumber);
        if($scope.currentRecord.settlement &&
            ($scope.currentRecord.settlement.overOrUnderPaymentAmount > 0 ||
            $scope.currentRecord.settlement.overOrUnderPaymentAmount < 0)
          )
        {
          $scope.currentRecordData.settlementNotEmpty = true;
        }
        else
        {
          $scope.currentRecordData.settlementNotEmpty = false;
        }

        var files = [];
        angular.forEach($scope.verificationData.files, function(file){
          if(file.fileType !== 'PolicyTermination')
          {
            files.push(file);
          }
        });

        //inicjujemy uploader
        ihestiaAutoUploadOneListUploadHelper.init($scope.uploaderOptions.name, {
        // onFileUploadDone: $scope.onFileUploadDone,
          onErrorSendingFile: $scope.onErrorSendingFile
          // onFileUploadAdd: $scope.onFileUploadAdd,
          // onFileDelete: $scope.checkAndBlockUpload,
          // onErrorAddingFile: $scope.checkAndBlockUpload,
          // onAllFileSend: $scope.onAllFileSend
        }, files);

        $scope.fileData.fileModels = ihestiaAutoUploadOneListUploadHelper.getAllfiles($scope.uploaderOptions.name);
        $scope.fileModels = $scope.fileData.fileModels;
        $scope.fileData.fileModelsReady = true;
        $scope.setDocumentType();
      };

      $scope.onChangeLang = function()
      {
        //odświeżamy labelki
        $scope.uploaderOptions.messages.acceptFileTypes = $filter('translate')('Public.incorrectFileType');
        $scope.uploaderOptions.messages.maxFileSize = $filter('translate')('Public.toLargeFile');
      };

      /**
       * ustawiamy niewypełnione atrybuty, bo wymaga tego uploder
       * później je zaktualizujemy
       */
      $scope.setDocumentType = function() {
        ihestiaAutoUploadOneListUploadHelper.setSelectedDocumentType($scope.uploaderOptions.name, $scope.fileAttributes);
      };

      $scope.deleteFile = function(id){
        ihestiaAutoUploadOneListUploadHelper.deleteFile($scope.uploaderOptions.name, id);
      };

      $scope.nextSaleChange = function()
      {
        //jeśli mamy wypowiedzenie to nie można dodać kolejnej sprzedaży
        if($scope.currentRecord.buyerWithdrawal && $scope.shared.nextSale)
        {
          $scope.shared.nextSale = false;
          $scope.showNextSaleBuyerWithdrawalConflictModal();
        }
      };

      /**
       * zmiana checkboxa "wypowiedzenie"
       */
      $scope.buyerWithdrawalChanged = function()
      {
        if(!$scope.currentRecord.buyerWithdrawal && $scope.lastWithdrawalDate !== null)
        {
          //była data wypowiedzenia i zrezygnowano z wypowiedzenia
          $scope.refreshTarrification();
          $scope.lastWithdrawalDate = null;
          $scope.currentRecord.withdrawalDate = null;
        }
      };

      /**
       * zmiana daty wypowiedzenia
       */
      $scope.withdrawalDateChanged = function()
      {
        var isValidDate = true;
        var date = new XDate($scope.currentRecord.withdrawalDate);
        if($scope.currentRecord.withdrawalDate === '' || $scope.currentRecord.withdrawalDate === null || angular.isUndefined($scope.currentRecord.withdrawalDate) || isNaN(date.getTime()))
        {
          isValidDate = false;
        }

        var newDate = null;
        if(isValidDate)
        {
          newDate = date.toString('i');
        }
        if(newDate !== $scope.lastWithdrawalDate)
        {
          $scope.refreshTarrification();
          $scope.lastWithdrawalDate = newDate;
        }
      };

      /**
       * taryfikujemy
       */
      $scope.refreshTarrification = function()
      {
        $scope.updateOwnerChange(false, lsnNg.noop, true, true);
      };
    }
  ]);