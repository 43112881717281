angular.module('lsnBase.errorHandler')
  .factory('$exceptionHandler', ['$injector', '$log', function($injector, $log) {
      return function(exception, cause) {
        try{
          var lsnCommonErrorHandler = $injector.get('lsnCommonErrorHandler');
          lsnCommonErrorHandler.handleException(exception, cause);
        } catch(exc){
          $log.log.apply($log, [exception, exception.stack]); // eslint-disable-line no-useless-call
          $log.log.apply($log, [exc, exc.stack]); // eslint-disable-line no-useless-call
        }
      };
    }])
  ;