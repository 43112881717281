/**
 * Kontoler
 * @param  {[type]} ) {               }  ] [description]
 * @return {[type]}   [description]
 */
angular.module('ihestiaMenuHelp')
  .controller('ihestiaMenuHelpCtrl', ['$scope', 'ihestiaMenuHelpHelper', '$rootScope', '$window', 'ihestiaSsoBaseInfoHelper', 'iKomunikatorConstants', '$state', 'spaceChatHelper', 'ihestiaConfigHelper','chatBotHelper','ihestiaPlatformHelper',
    function($scope, ihestiaMenuHelpHelper, $rootScope, $window, ihestiaSsoBaseInfoHelper, iKomunikatorConstants, $state, spaceChatHelper, ihestiaConfigHelper, chatBotHelper, ihestiaPlatformHelper) {

      $scope.tplData = ihestiaMenuHelpHelper.tplData;
      $scope.moveablePopupSettings = ihestiaMenuHelpHelper.moveablePopupSettings;
      $scope.$state = $state;
      $scope.spacechatEnabled = false;
      $scope.chatbotEnabled = false;
      $scope.videoChatEnabled = false;
      $scope.videoChatUrl = false;

      $scope.init = function() {
        $scope.spacechatEnabled = ihestiaConfigHelper.get('chatbot', 'spacechatEnabled');
        $scope.chatbotEnabled = ihestiaConfigHelper.get('chatbot', 'chatbotEnabled');
        $scope.videoChatEnabled = ihestiaConfigHelper.get('videochat', 'videoChatEnabled');
        if($scope.videoChatEnabled) {
          var icContext = ihestiaConfigHelper.get('ic', 'activeContext'); // Property EHN or Life EHL context
          if(ihestiaPlatformHelper.isJupiter()){
            $scope.videoChatUrl = ihestiaConfigHelper.get('videochat', 'videoChatJupiterUrl');
          } else {
            $scope.videoChatUrl = icContext === 'EHL' ? ihestiaConfigHelper.get('videochat', 'videoChatLifeUrl') : ihestiaConfigHelper.get('videochat', 'videoChatIHestiaUrl');
          }
        }
        if($scope.tplData.clientUserLogged){
          $scope.moveablePopupSettings.title = 'Twój Opiekun';
        }
      };

      $scope.toggleWindowWithHelp = function() {
        $scope.moveablePopupSettings.show = !$scope.moveablePopupSettings.show;
      };

      $scope.openChatWindow = function() {
        if ($scope.spacechatEnabled) {
          spaceChatHelper.startAgentChat();
          $scope.moveablePopupSettings.show = false;
        }
        if ($scope.chatbotEnabled) {
          chatBotHelper.toggleVisibility();
          $scope.moveablePopupSettings.show = false;
        }
      };

      $scope.openVideoChatWindow = function() {
        if ($scope.videoChatUrl) {
          $window.open($scope.videoChatUrl+'&refURL=' + escape(document.location), 'popupwindow' + Math.round(Math.random() * 10000000000), 'toolbar=no,menubar=no,status=yes,scrollbars=yes,resizable=yes,width=500,height=500 top=80,left=120');//eslint-disable-line
        }
        return false;
      };

      $scope.$watch('moveablePopupSettings.show', function(){
        ihestiaMenuHelpHelper.reloadHelpDesc();
      });

      /**
       * [contactWithAgent otwiera okna wiadomosci ikomunikatora]
       * @return {[type]} [description]
       */
      $scope.contactWithAgent = function(){
        var context = {
          systemContext: iKomunikatorConstants.SYSTEM_CONTEXT_IKONTO,
          formName: iKomunikatorConstants.FORM_NAME_ACCOUNT_MANAGER_CONTACT
        };
        $scope.$emit('iKomunikator.startNewMessage', context);
        $scope.moveablePopupSettings.show = false;
      };

      // Przekierowanie na wyszukiwarkę pytań z zakresu pomocy dla agenta
      $scope.openAgentHelp = function() {
        var url = ihestiaConfigHelper.getUrl('CI_URL', 'external/help/search');
        $window.open(url, '_blank');
      };



      // ponieważ $stateChangeSuccess nie jest odpalany przy odświeżeniu strony, to robimy $watcha na stanie
      /**
       * na zmiane stanu na inna podstrone niz historia/podglad szczegolow czyścimy filtr wyszukiwania zaawansowanego
       */
      $scope.$watch('$state.$current', function(toState) {
        if (angular.isObject(toState)) {
          $scope.tplData.stateCode = toState.name;
          ihestiaMenuHelpHelper.reloadHelpDesc();
        }
      });

      $scope.openWebside = function(url){
        $window.open(url);
      };

      $scope.init();
    }
  ]);
