angular.module('ihestiaCommonDirectives.polishGrama')
    .factory('ihestiaPolishGrama', function() {
      var polishGrama = function(text) {
        if (typeof text === 'string')
        {
          var repl = ['i', 'a', 'o', 'z', 'w', 'lub', 'oraz', 'na', 'pod', 'za', 'nad', 'bo', 'gdy', 'do', 'od', 'dla'];
          $.each(repl, function(i, s) {
            var reg = new RegExp(' ' + s + ' ', 'gi');
            var rep = ' ' + s + '\u00A0';
            text = text.replace(reg, rep);

            reg = new RegExp('\u00A0' + s + ' ', 'gi');
            rep = '\u00A0' + s + '\u00A0';
            text = text.replace(reg, rep);

            reg = new RegExp('\\(' + s + ' ', 'gi');
            rep = '(' + s + '\u00A0';
            text = text.replace(reg, rep);
          });
        }
        return text;
      };
      return polishGrama;
    });