angular.module('lsnBase.models')
  .factory('LsnAmountModelV2', ['LsnAbstractModel',
    function(LsnAbstractModel) {
      var AmountModel = function() {
        
        this.objectName = 'RiskVariant';
        this.modelVersion = 'v2';
        this.dataVersion = this.modelVersion;
        this.fieldsProperties = {
          currencyCode: {
            type: 'string'
          },
          value: {
            type: 'int'
          }
        };

        this.currencyCode = null; // waluta
        this.value = null; // wartość

      };

      AmountModel.prototype = LsnAbstractModel.prototype;

      return AmountModel;
    }
  ]);