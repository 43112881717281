/**
 * Mapper podmiotów (osoby, firmy)
 */
angular.module('salesPath2').service('fromRestSubjectMapper', ['MapperUtils', 'sp2CommonHelper', 'SPD', 'arrayLowerCaseModelFilter', 'CONSTANTS', 'CONFIG', 'GroupModel', 'personGroupHelper', 'RESOURCES', 'fromRestCompatibilityMapper',
  function(MapperUtils, sp2CommonHelper, SPD, arrayLowerCaseModelFilter, CONSTANTS, CONFIG, GroupModel, personGroupHelper, RESOURCES, fromRestCompatibilityMapper) {
    var FromRestSubjectMapper = function() {
      var self = this;
      this.utils = MapperUtils;
      this.defaultOptions = { //opcje mapowania
        initialLoad: false //pierwsze wczytanie wniosku/polisy
      };

      this.options = angular.copy(this.defaultOptions);
      /**
       * ustawia opcje mapowania
       * @param {object} options opcje
       */
      this._setOptions = function(options) {
        self.options = angular.copy(self.defaultOptions);
        if (angular.isObject(options)) {
          angular.extend(self.options, options);
        }
      };

      /**
       * mapowanie dynamicValues (z ryzyka,osoby lub pakietu) na AdditionalData osoby
       * nazwa_dyn_val: [klucz_w_additionalData, podklucz_w_additionalData, opcjonalny_filtr_wartości]
       * @type object
       */
      this.dynamicValuesToAdditinalData = {
        profession: [CONSTANTS.PRODUCT_OCZP, 'job', function(val) {
          return val + '';
        }],
        speciality: [CONSTANTS.PRODUCT_OCZP, 'specializations', arrayLowerCaseModelFilter],
        householdNumberOfAdultPeople: [CONSTANTS.PRODUCT_OCZP, 'householdPeople', null, '1'],
        monthlyIncome: [CONSTANTS.PRODUCT_NNW, 'monthlyIncome'],
        insuredNo: [CONSTANTS.PRODUCT_NNW, 'insuredNo']
      };
      /**
       * mapuje podmioty
       * @param  {ApplicationModel} application wniosek
       * @param  {Object} tmpDataManager dataContainer
       * @param  {Object} mapOptions opcje mapowania
       */
      this.mapSubjects = function(application, tmpDataManager, mapOptions) {
        self._setOptions(mapOptions);
        angular.forEach(['persons', 'organizations', 'groups'], function(container) {
          self._mapSubjects(container, application, tmpDataManager);
        });
      };
      //mapuje podmioty danego typu
      this._mapSubjects = function(container, application, tmpDataManager) {
        if (application[container] === null) {
          return {};
        }
        var subjectsObj = {},
          nextId = tmpDataManager.nextSubjectId;
        angular.forEach(application[container], function(subject) {
          nextId = Math.max(nextId, parseInt(subject.metaData.get('clientId'), 10) + 1);
          self._mapSubject(subject, application);
          subjectsObj[subject.metaData.get('clientId')] = subject;
          tmpDataManager._subjectIdMap[subject.metaData.get('id')] = subject.metaData.get('clientId');
        });
        tmpDataManager[container] = subjectsObj;
        tmpDataManager.nextSubjectId = nextId;
        return subjectsObj;
      };
      /**
       * mapuje podmiot
       * @param {(Person|Organization)} subject podmiot
       * @param {Application} application wniosek
       */
      this._mapSubject = function(subject, application) {
        var dynVals = subject.get('dynamicValues');
        if (angular.isObject(dynVals) && angular.isDefined(dynVals._additionalData)) {
          var addData = self.utils.unserializeValue(dynVals._additionalData);
          if (self.options.initialLoad) {
            fromRestCompatibilityMapper.mapAdditionalData(addData);
          }
          subject.setAdditionalData(addData);
          delete dynVals._additionalData;
        }
        if (angular.isDefined(SPD.objectsDynamicValues[subject.objectName]._pkdCode) && angular.isDefined(SPD.objectsDynamicValues[subject.objectName].pkdCode) && angular.isDefined(dynVals._pkdCode)) {
          delete dynVals._pkdCode;
        }
        subject.dynamicValues = dynVals;
        self._setAdditionalDataFromSimplePolicies(application, subject);
        self.utils.getTemporaryDynamicValuesForObject(subject);
        self.utils.removeUnusedDynamicValuesForObject(subject);
      };

      /**
       * dodaje additionalData do podmiotu na podstawie danych z polis (prostych polis)
       * @param {Aplication} application wniosek
       * @param {Person|Organization} subject podmiot
       */
      this._setAdditionalDataFromSimplePolicies = function(application, subject) {
        if (application.get('policies').length === 0) {
          return;
        }
        angular.forEach(application.policies, function(simplePolicy) {
          var policyDynVals = simplePolicy.product.get('dynamicValues');
          //ubezpieczajacy
          if (application.get('holderRef') === subject.metaData.get('id') && angular.isDefined(policyDynVals.regon)) {
            subject.setAdditionalData(policyDynVals.regon, 'regon');
          }
        });
      };
      /**
       * zwraca Person lub Organization ze zmapowanego obiektu datamanagera
       * @param {string} subjectId id podmiotu
       * @param {FromRestTempDataContainerModel} tmpDc
       * @return {(Person|Organization)} podmiot
       */
      this.getSubjectByClientId = function(subjectId, tmpDc) {
        var subject = null;
        if (angular.isDefined(tmpDc.organizations[subjectId])) {
          subject = tmpDc.organizations[subjectId];
        } else if (angular.isDefined(tmpDc.persons[subjectId])) {
          subject = tmpDc.persons[subjectId];
        } else if (angular.isDefined(tmpDc.groups[subjectId])) {
          subject = tmpDc.groups[subjectId];
        } else {
          sp2CommonHelper.throwException('No subject mapped with clientId: {0}.'.format(subjectId));
        }
        return subject;
      };

      /**
       * ustawia dynamicValues dla podmiotu z pakietu polisy
       * @param {PersonModel|OrganizationModel} subject osoba lub firma
       * @param {SalesProduct} salesProduct pakiet polisy
       */
      this.setDynamicValuesFromSalesProduct = function(subject, salesProduct) {
        var spDynVals = salesProduct.get('dynamicValues');
        //kody pkd - tylko dla ubezpieczającego
        if (angular.isArray(spDynVals.insurerPKDList) && (!angular.isArray(subject.pkd) || subject.pkd.length === 0)) {
          subject.set('pkd', spDynVals.insurerPKDList);
        }
      };

      /**
       * ustawia additionalData dla osoby/firmy
       * @param {(Person|Organization)} subject osoba lub firma
       * @param {object} dynamicValues REST atrybuty osoby, ryzyka lub pakietu
       */
      this.setAdditionalDataFromDynamicValues = function(subject, dynamicValues, riskCode) {
        angular.forEach(dynamicValues, function(value, name) {
          if (angular.isDefined(self.dynamicValuesToAdditinalData[name])) {
            var path = [self.dynamicValuesToAdditinalData[name][0], self.dynamicValuesToAdditinalData[name][1]],
              parsedValue = angular.isFunction(self.dynamicValuesToAdditinalData[name][2]) ? self.dynamicValuesToAdditinalData[name][2](value) : value;
            subject.setAdditionalData(parsedValue, path);
          }
        });

        angular.forEach(self.dynamicValuesToAdditinalData, function(additional) {
          var path = [additional[0], additional[1]];
          if (subject.getAdditionalData(path) === null && additional[0] === riskCode && angular.isDefined(additional[3])) {
            subject.setAdditionalData(additional[3], path);
          }
        });
      };
      /**
       * ustawia wariant produktu w additionalData podmiotu
       * @param {(Person|Organization)} subject podmiot
       * @param {string} product produkt
       * @param {string} variant wariant
       */
      this.setAdditionalDataProductVariant = function(subject, product, variant) {
        var prodData = subject.getAdditionalData(product),
          multiVariant = CONFIG.BEHAVIOR.multiVariantsProducts.indexOf(product) !== -1;
        if (prodData === null) {
          prodData = {
            variants: multiVariant ? {} : variant
          };
        }
        if (angular.isUndefined(prodData.variants)) {
          prodData.variants = multiVariant ? {} : variant;
        }
        if (multiVariant) {
          var def = self.utils.getProductDef(product);
          angular.forEach(def.VARIANTLIST, function(variantData) {
            if (angular.isUndefined(prodData.variants[variantData.CODE])) {
              prodData.variants[variantData.CODE] = false;
            }
            if (variantData.CODE === variant) {
              prodData.variants[variantData.CODE] = true;
            }
          });
        }
        subject.setAdditionalData(prodData, product);
      };

      /**
       * mapuje podmioty z polis do wniosku ubezpieczeniowego
       * UWAGA
       * Wprowadza zmiany na ryzykach polis w zakresie informacji o grupac (_groupId)
       * @param {Policy[]} policies polisy
       * @param {Application} application wniosek
       * @return {object.<string,string>} obiekt mapowan id: clientId
       */
      this.mapPolicySubjectsToApplication = function(policies, application) {
        var containers = ['persons', 'organizations'],
          idToClientId = {}, //mapping id -> clientId
          nextSubjectClientId = 1,
          idToIndex = {}, //ids zmapowane (metaData.id => indeks w kontenerze)
          personGroups = CONFIG.BEHAVIOR.personGroups === true; //czxy polisy typu personGroup
        lsnNg.forEach(policies, function(policy) {
          var groupPolicy = personGroups && angular.isObject(policy.product.dynamicValues) && policy.product.dynamicValues.formOfPolicy === CONSTANTS.FORM_OF_POLICY_GROUP;
          lsnNg.forEach(containers, function(container) {
            if (policy[container] === null) {
              return;
            }
            if (!angular.isArray(application[container])) {
              application.set(container, []);
            }
            lsnNg.forEach(policy[container], function(subject) {
              if (angular.isUndefined(idToClientId[subject.metaData.get('id')])) {
                subject.metaData.set('clientId', nextSubjectClientId);
                application[container].push(subject);
                idToIndex[subject.metaData.get('id')] = application[container].length - 1;
                idToClientId[subject.metaData.get('id')] = nextSubjectClientId;
                nextSubjectClientId += 1;
              } else {
                self._mergeSubjects(application[container][idToIndex[subject.metaData.get('id')]], subject);
              }
            });
          });
          if (groupPolicy) { //mapowanie-dodawanie grup
            if (!angular.isArray(application.groups)) {
              application.set('groups', []);
            }
            var group = new GroupModel(),
              dynVals = policy.product.get('dynamicValues'),
              groupDynVals = {};
            group.set('name', personGroupHelper.generateGroupName(application.groups.length + 1));
            group.metaData.set('clientId', nextSubjectClientId);
            //atrybuty dotyczace osob w przedzialach wiekowych
            angular.forEach(RESOURCES.INSURED_AGE_INTERVALS, function(item) {
              if (angular.isNumber(dynVals[item.BOS_CODE])) {
                groupDynVals[item.BOS_CODE] = dynVals[item.BOS_CODE];
              }
            });
            group.set('dynamicValues', groupDynVals);
            application.groups.push(group);
            idToIndex[group.metaData.get('id')] = application.groups.length - 1;
            idToClientId[group.metaData.get('id')] = nextSubjectClientId;
            nextSubjectClientId += 1;
            //nadanie groupId dla wszystkich ryzyk polisy
            angular.forEach(policy.risks, function(risk) {
              risk.product.set('dynamicValues', {
                _groupId: group.metaData.get('id')
              });
            });
          }
        });
        return idToClientId;
      };

      /**
       * Łączy dwa podmioty (pod kątem wlasnoci, dynamicValues, itd.)
       * @param {Person|Organization} targetSubject docelowy podmiot
       * @param {Person|Organization} sourceSubject zrodlowy podmiot
       * @return {Person|Organization} połączony podmiot
       */
      this._mergeSubjects = function(targetSubject, sourceSubject) {
        var dynVals = self.utils.mergeDynamicValues(targetSubject.get('dynamicValues'), sourceSubject.get('dynamicValues'));
        targetSubject.set('dynamicValues', dynVals);
      };

    };

    return new FromRestSubjectMapper();
  }
]);