angular.module('salesPath2')
  .service('summaryPaymentHelper', ['SummaryPaymentHelperClass',
    function(SummaryPaymentHelperClass) {
      var summaryPaymentHelper = new SummaryPaymentHelperClass();

      return summaryPaymentHelper;
    }
  ])
  .run(['summaryPaymentHelper', 'actionHelper',
   function(summaryPaymentHelper, actionHelper) {
      actionHelper.registerHelper('summaryPaymentHelper', summaryPaymentHelper);
  }]);