angular.module('ihestiaCommonDirectives.istevenMultiSelectTranslationsHelper', [])
  .run(['istevenMultiSelectTranslationsHelper', 'labelActionHelper', '$filter',
    function(istevenMultiSelectTranslationsHelper, labelActionHelper, $filter){
      
      /**
       * Ustawiamy tłumaczenia
       */
      this.setTranslations = function() {
        istevenMultiSelectTranslationsHelper.translations = {
          selected: $filter('translate')('selected', {
            componentCode: 'Public'
          }),
          multiselectGroupNone: $filter('translate')('Public.multiselectGroupNone', {
            componentCode: 'Public'
          }),
          multiselectGroupAll: $filter('translate')('Public.multiselectGroupAll', {
            componentCode: 'Public'
          })
        };
      };

      this.setTranslations();

      labelActionHelper.registerAction('istevenMultiSelectMerged', this.setTranslations, true);
  }]);