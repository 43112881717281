angular.module('ihestiaWidgets.commonOperations')
  .controller('taskSearchModalCtrl', ['$scope', 'customerSvc', 'iHestiaCommonModalHelper', '$window', 'ihestiaConfigHelper', 'customerPoliciesSvc', 'ihestiaSsoBaseInfoHelper', 'ihestiaAgHelper', 'ihestiaTaskSearchSvc', 'taskSearchModalHelper', '$timeout', '$filter', 'ihestiaPlatformHelper',
    function($scope, customerSvc, iHestiaCommonModalHelper, $window, ihestiaConfigHelper, customerPoliciesSvc, ihestiaSsoBaseInfoHelper, ihestiaAgHelper, ihestiaTaskSearchSvc, taskSearchModalHelper, $timeout, $filter, ihestiaPlatformHelper) {

      $scope.SUBJECT_TYPE_PERSON = 'person';
      $scope.SUBJECT_TYPE_ORGANIZATION = 'organization';
      $scope.customerQuery = {
        firstName: '',
        lastName: '',
        identityNumber: '',
        policyNumber: '',
        taxId: null,
        statisticalId: null,
        companyId: null,
        registrationCountry: 'PL',
        organizationName: null,
        subjectType: $scope.SUBJECT_TYPE_PERSON
      };

      $scope.specialErrors = {
        invalidPolicyNumber: false,
        invalidCustomer: false
      };

      $scope.customerData = {};

      $scope.errorList = [];

      /**
       *
       * @type {{subjectType: undefined | 'person' | 'organization' }}
       */
      $scope.tplData = {
        subjectTypes: [{
          code: $scope.SUBJECT_TYPE_PERSON,
          name: $filter('translate')('naturalPerson', {componentCode: 'Public'})
        }, {
          code: $scope.SUBJECT_TYPE_ORGANIZATION,
          name: $filter('translate')('legalPerson', {componentCode: 'Public'})
        }],
        isSubjectSelectAvailable: false
      };
      /**
       * Init
       * @return {undefined}
       */
      $scope.init = function() {
        if (taskSearchModalHelper.searchData !== null) {
          $scope.customerQuery = angular.copy(taskSearchModalHelper.searchData);
          taskSearchModalHelper.clearData();
        }
        $scope.tplData.isSubjectSelectAvailable =
          !ihestiaPlatformHelper.isJupiter() && !ihestiaPlatformHelper.isLifeContext();

        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaOperationsTaskSearch', function($event) {
          $event.preventDefault();
          $scope.clearErrors();

          $scope.findTasks();
        });
      };


      /**
       * Wyszukiwanie zadań po zadanych parametrach
       */
      $scope.findTasks = function() {
        var dynamicFilters = {
          status: ['NEW', 'IN_PROGRESS', 'POSTPONED'], //zadania bez statusu 'zamknięte'
          renewalFilter: 'NA' //przełącznik do wyszukiwania zadań wznowieniowych - przyjmowane wartości: ONLY_RENEWALS, EXCEPT_RENEWALS, NA
        };

        if ($scope.customerQuery.subjectType === $scope.SUBJECT_TYPE_PERSON) {

          if ($scope.customerQuery.firstName || !!$scope.customerQuery.lastName) {
            dynamicFilters.objectClientFullName = $scope.customerQuery.firstName + ' ' + $scope.customerQuery.lastName;
          }

          if ($scope.customerQuery.identityNumber) {
            dynamicFilters.objectClientNumber = $scope.customerQuery.identityNumber;
          }
        } else {
          // pick truthy organization fields and join them to one string - fts search
          var objectClientNumber = _.chain($scope.customerQuery)
            .pick($scope.customerQuery.registrationCountry === 'PL'
              ? ['taxId', 'statisticalId'] : ['companyId'])
            .values()
            .filter(_.identity)
            .join(' ')
            .value();
          if (objectClientNumber) {
            dynamicFilters.objectClientNumber = objectClientNumber;
          }
          if ($scope.customerQuery.organizationName) {
            dynamicFilters.objectClientFullName = $scope.customerQuery.organizationName;
          }
        }

        if ($scope.customerQuery.policyNumber) {
          dynamicFilters.policyNumber = $scope.customerQuery.policyNumber;
        }

        // check if any search field is defined
        var canSearch = !_.isEmpty(_.pick(dynamicFilters, ['objectClientFullName', 'objectClientNumber', 'policyNumber']));
        if (canSearch) {
          $scope.$emit('showBlockUi');
          ihestiaTaskSearchSvc.findByParams({
            count: 50,
            skip: 0,
            top: 50,
            dynamicFilters: dynamicFilters
          }).then(function(response) {
            if (!response.data.items || response.data.items.length === 0) {

              $scope.errorList.push({
                label: ['operation.taskNotFoundTryAgain', {
                  componentCode: 'PolicyOperations'
                }]
              });

              $scope.$emit('hideBlockUi');
            } else if (response.data.items.length === 1) {
              taskSearchModalHelper.goToTask(response.data.items[0]);
              taskSearchModalHelper.clearData();
              $scope.$emit('hideBlockUi');
              iHestiaCommonModalHelper.hideModal('ihestiaOperationsTaskSearch');
            } else {

              angular.forEach(response.data.items, function(taskData) {
                taskData.dueDateData = $filter('ihestiaDashboardSla')(taskData.dueDate);
              });

              taskSearchModalHelper.tasksListResponse = response.data;
              taskSearchModalHelper.searchData = $scope.customerQuery;
              iHestiaCommonModalHelper.hideModal('ihestiaOperationsTaskSearch');
              $timeout(function() {
                iHestiaCommonModalHelper.showModal('ihestiaOperationsTaskSearchList');
              }, 0);
            }
          }, function() {
            $scope.errorList.push({
              label: ['operation.taskNotFoundTryAgain', {
                componentCode: 'PolicyOperations'
              }]
            });
            $scope.$emit('hideBlockUi');
          });
        } else {
          $scope.errorList.push({
            label: ['operation.taskNotFoundTryAgain', {
              componentCode: 'PolicyOperations'
            }]
          });
        }
      };


      $scope.clearErrors = function() {
        $scope.errorList = [];
        angular.forEach($scope.specialErrors, function(errorValue, errorKey) {
          $scope.specialErrors[errorKey] = false;
        });
      };

    }
  ]);
