angular.module('ihestiaRestServicesBase')
  .factory('clausesPoliciesSvc', [ 'IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var Clauses = function()
      {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.basePattern = '{host}{port}{path}/{apiString}/{businessArea}/{apiVer}/{resource}{id}{subResource}';

        this.resource = 'policies';
        this.businessArea = 'clauses';
        this.apiVersion = 'v1';

        this.getClausesByPolicyNumber = function(policyNumber, callback, errorCallback){
          return self.get(policyNumber, null, 'clauses', callback, errorCallback);
        };
      };

      return new Clauses();
    }])
;