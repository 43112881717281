angular.module('ihestiaWidgets.life')
  .controller('ihestiaBeneficiaryEditModalCtrl', ['$scope', 'ihestiaBeneficiaryEditModalHelper', 'LsnBeneficiaryModelConstants',
    function($scope, ihestiaBeneficiaryEditModalHelper, LsnBeneficiaryModelConstants) {

      $scope.tplData = ihestiaBeneficiaryEditModalHelper.tplData;
      $scope.BENEFICIARY_CONSTANTS = LsnBeneficiaryModelConstants;

      $scope.onInit = function() {
        ihestiaBeneficiaryEditModalHelper.initData();
      };

      $scope.$watch('beneficiaryForm', function() {
        ihestiaBeneficiaryEditModalHelper.tplData.formRef = $scope.beneficiaryForm;
      });

      $scope.onCessionaryCheckboxChange = function() {
        $scope.tplData.showElements.cessionaryForm = $scope.tplData.beneficiaryData.cessionary;
      };

      $scope.onInit();
    }
  ]);
