angular.module('salesPath2')
  .controller('productsClausesAutomaticUpdateModalCtrl', ['$scope', 
    function($scope) {

      /**
      * init
      */
      $scope.init = function() {
        var clauses = [];
        
        var results = $scope.dataContainer.application.get('policies')[0].product.dynamicValues._ProductClausesAutomaticUpdateResults;

        if (!results) {
          return;
        }

        for (var index = 0; index < results.operationType.length; index++) {
          clauses.push({
            operationType: results.operationType[index],
            clauseGroup: results.clauseGroup[index],
            clauseCode: results.clauseCode[index],
            clauseName: results.clauseName[index],
            indexOrder: results.indexOrder[index]
          });          
        }

        var uniqueClauses = _.uniqBy(clauses, 'clauseCode');
        $scope.deletedClauses = uniqueClauses.filter(function(clause) {
          return clause.operationType === 'Deleted';
        });        
        $scope.updatedClauses = uniqueClauses.filter(function(clause) {
          return clause.operationType === 'Updated';
        });
      };

      $scope.init();

    }
  ]);
