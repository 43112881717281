/**
 * Serwis do kontrolowania menu
 * @return {[type]} [description]
 */
angular.module('ihestiaMenuHelp')
  .service('ihestiaMenuHelpHelper', ['$rootScope', 'iHestiaCommonModalHelper', '$timeout', 'ihestiaSsoBaseInfoHelper', 'screenHelpSvc', '$sce', 'UserCharacterTypeConstants', '$window', 'ihestiaConfigHelper', 'ihestiaAgHelper', 'ihestiaMenuHelpConfig',
    function($rootScope, iHestiaCommonModalHelper, $timeout, ihestiaSsoBaseInfoHelper, screenHelpSvc, $sce, UserCharacterTypeConstants, $window, ihestiaConfigHelper, ihestiaAgHelper, ihestiaMenuHelpConfig) {

      var IHestiaMenuHelpHelper = function() {
        var self = this;
        this.tplData = {
          visible: false,
          stateCode: null,
          application: APP_CONFIG.rest.AP_CALLER,
          helpDesc: null,
          clientUserLogged: ihestiaSsoBaseInfoHelper.getUser().userType === UserCharacterTypeConstants.CHARACTER_TYPE_CLIENT
        };

        this.moveablePopupSettings = {
          top: 62,
          right: 14,
          show: false,
          title: '',
          bodyTemplate: ihestiaMenuHelpConfig.defaultConfig.moveablePopupBody
        };

        this.init = function() {
          if (!ihestiaMenuHelpConfig.defaultConfig.justVideoAndChat && ihestiaAgHelper.hasPermissionFor('InformationCenter', 'TutorialContentEdit')) {
            self.moveablePopupSettings.editButtonAvailable = true;
            self.moveablePopupSettings.onEdit = function() {
              if (angular.isObject(self.tplData.helpDesc)) {
                $window.location = ihestiaConfigHelper.getUrl('PORTAL_URL', 'external/editHelpDescripiton/' + self.tplData.helpDesc.id);
              } else {
                $window.location = ihestiaConfigHelper.getUrl('PORTAL_URL', 'external/addHelpDescripiton/?app=' + self.tplData.application + '&pageId=' + self.tplData.stateCode);
              }
            };
          }
        };

        this.openChatWindow = function() {
          LC_API.open_chat_window();
        };
        this.hideChatWindow = function() {
          LC_API.hide_chat_window();
        };

        this.showWindowWithHelp = function() {
          iHestiaCommonModalHelper.showModal('ihestiaMenuWindowWithHelp');
        };

        this.hideWindowWithHelp = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaMenuWindowWithHelp');
        };

        this.reloadHelpDesc = function() {
          if (!ihestiaMenuHelpConfig.defaultConfig.justVideoAndChat && self.moveablePopupSettings.show){
            screenHelpSvc.get(self.tplData.application, null, self.tplData.stateCode, function(result) {
              if (angular.isObject(result.data)) {
                result.data.description = $sce.trustAsHtml(result.data.description);
                self.tplData.helpDesc = result.data;
                self.moveablePopupSettings.title = self.tplData.helpDesc.name;
              }
            }, function() {
              self.tplData.helpDesc = null;
            }, {
              allowedStatuses: [404, 401]
            });
          }
        };

        $rootScope.$on('$stateChangeStart', function($event, toState) {
          if (angular.isObject(toState)) {
            self.tplData.stateCode = toState.name;
            self.reloadHelpDesc();
          }
        });



        self.init();
      };

      return new IHestiaMenuHelpHelper();
    }
  ]);