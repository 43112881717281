angular.module('salesPath2').service('sp2CommonMessageHelper', ['CONSTANTS', 'appVariables', 'bosMessageHelper', 'sp2CommonHelper', 'salesPath2ErrorMapper', 'salesPath2BosErrorMapper', 'messagesClickHelper', 'mainDataContainer', 'actionHelper', 'navigationHelper', 'localizationModalHelper', 'nnwHelper', 'dataContainerHelper', 'messageTranslator', 'ErrorEntityModel', 'CONFIG', 'labelServiceHelper', 'mainErrorCollection',
  function(CONSTANTS, appVariables, bosMessageHelper, commonHelper, salesPath2ErrorMapper, salesPath2BosErrorMapper, messagesClickHelper, mainDataContainer, actionHelper, navigationHelper, localizationModalHelper, nnwHelper, dataContainerHelper, messageTranslator, ErrorEntityModel, CONFIG, labelServiceHelper, mainErrorCollection) { // eslint-disable-line angular/di
    var CommonMessageHelper = function() {
      var self = this;

      this.errorClickHelper = messagesClickHelper;
      this.bosErrorMapper = salesPath2BosErrorMapper;
      this.errorMapper = salesPath2ErrorMapper;

      //kody które mają się pojawiać tylko na stronie podsumowania
      this.summaryCode = [
        'U000032' //walidacja na miejsce zawarcia 
      ];

      this.codeToShowSummary = function(code) {
        return (self.summaryCode.indexOf(code) !== -1);
      };

      /**
       * obsluga klika na dowolny komunikat
       * @param  {Object} error [description]
       * @return {Boolean} true gdy obsłużony klik
       */
      this.messageClicked = function(error) {
        var errorHandled;

        if (bosMessageHelper.isBosCode(error.code)) {
          //w bosowych kodach pierwszy znak jest cyfrą
          errorHandled = self.handleBosCodes(error); //obsługa błędów z BOS
          if (!errorHandled) {
            errorHandled = self.errorClickHelper.handleUnknown();
          }
          return errorHandled;
        }
        errorHandled = self.handleFbtCodes(error);

        return errorHandled; //obsługa błędów z FBT po kodach
      };


      this.errorEntityAction = function(error) {
        var entityId = self.errorMapper.getEntityId(error);
        switch (self.errorMapper.getObjectType(error)) {
          case 'Vehicles':
            if (/CoownersNotSet/g.test(_.get(error, 'attributes.objects[0]', null))) {
              self.errorClickHelper.handleCommunicationInsured(error.riskParams);
            } else {
              self.errorClickHelper.handleVehicles({
                vehicleId: dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_VEHICLE, entityId),
                originalError: error
              });
            }
            break;
          case 'Estates':
            self.errorClickHelper.handleLocalization({
              localizationId: dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, entityId),
              originalError: error
            });
            break;
          case 'Persons':
            self.errorClickHelper.handlePersons({
              personId: dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_PERSON, entityId),
              originalError: error
            });
            break;
          case 'Organizations':
            var organizationId = dataContainerHelper.getClientId(CONSTANTS.OBJECT_TYPE_ORGANIZATION, entityId);
            //sprawdzamy czy jest jako leasingodawca na wniosku
            var isLeaser = false,
              objectType = null,
              objectId = null;
            lsnNg.forEach(mainDataContainer.localizations, function(localization) {
              if (organizationId === localization.getAdditionalData('leaserId')) {
                isLeaser = true;
                objectType = CONSTANTS.PRODUCT_TYPE_LOCALIZATION;
                return false;
              }
              return true; //continue
            });
            if (!isLeaser) {
              lsnNg.forEach(mainDataContainer.vehicles, function(vehicle, vehicleId) {
                if (organizationId === vehicle.getAdditionalData('leaserId')) {
                  isLeaser = true;
                  objectId = vehicleId;
                  objectType = CONSTANTS.PRODUCT_TYPE_VEHICLE;
                  return false;
                }
                return true; //continue
              });
            }
            if (isLeaser) {
              messagesClickHelper.handleLeasing(objectType, objectId, organizationId, error);
            } else {
              lsnNg.forEach(mainDataContainer.policies, function(policy) {
                if (policy.get('suretyRef').indexOf(entityId) !== -1) {
                  messagesClickHelper.handleCession(policy.get('number'), error);
                }
              });
            }
            break;
          default:
            self.errorClickHelper.handleUnknown(error);
            break;
        }

      };

      //obsluga komunikatow generowanych po stronie FBT
      this.handleFbtCodes = function(error) {
        var errorHandled = true,
          msgParams; // object
        switch (error.code) {
          case 'U000002':
          case 'U000003':
          case 'U000033':
          case 'U000034':
          case 'U000013':
            var localizationId = self.errorMapper.getEntityId(error);

            if (angular.isString(localizationId) && localizationId !== '') {
              localizationId = dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, localizationId);
              dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, localizationId);
              actionHelper.runAction('localizationSelected', {
                id: localizationId
              });
            }

            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OGI]);
            break;
          case 'U000029':
          case 'U000035':
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_KRA]);
            break;
          case 'U000030':
          case 'U000031':
          case 'U000036':
          case 'U000039':
          case 'U000040':
          case 'U000041':
          case 'U000047':
          case 'U000072':
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OCZP]);
            break;
          case 'U000018':
          case 'U000019':
          case 'U000020':
          case 'U000042':
          case 'U000043':
          case 'U000044':
          case 'U000045':
          case 'U000069':
          case 'U000076':
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_NNW]);
            break;
          case 'U000005':
          case 'U000006':
          case 'U000021':
          case 'U000037':
          case 'U000038':
          case 'U000046':
          case 'U000048':
          case 'U000049':
          case 'U000050':
            navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[CONSTANTS.PRODUCT_OCKOM]); //ogolnie komunikacja
            break;
          case 'U000007': //brak numeru OC w klauzulach
          case 'U000008': //kod promocyjny
          case 'U000009': //odstępstwa
          case 'U000010': //odstępstwa
          case 'U000022': //odstępstwa
          case 'U000011': //odstępstwa
          case 'U000012': //odstępstwa
          case 'U000023': //odstępstwa
          case 'U000024': //data podpisania umowy
          case 'U000014': //data podpisania umowy
          case 'U000025': //data podpisania umowy
          case 'U000026': //data podpisania umowy
          case 'U000015': //data podpisania umowy
          case 'U000016': //data podpisania umowy
          case 'U000032': //miejsce podpisania umowy
            navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
            break;
          case 'U000027': //bonus malus
          case 'U000028':
          case 'T000001': //Nieprawidłowy status wniosku
          case 'T000002': //Brak uprawnień do przeprowadzenia operacji
          case 'D000006': //klient fraudowy
          case 'D000003': //klient fraudowy
          case 'POLSALEDENIED':
          case 'DID0005': //Kalkulacja jest niezgodna z najnowszą wersją polisy. Wykonaj operację ponownie.
          case 'U000062': //3 gwiazdki nic się nie dzieje.
          case '120025': //Wystąpił błąd podczas wykonania metody liczącej: Atrybut 'Data podpisania polisy' na obiekcie 'Ubezpieczenie niepakietowe OC posiadaczy pojazdów SOLO' ma ustawioną niepoprawną wartość '10103' WTF?
          case 'U000068':
          case 'U000083':
          case 'U000082':
          case 'O000111':
            //bez obsługi
            break;
          case 'U000061':
          case 'O000990': //osoba wystepuje wielokrotnie na wniosku
            navigationHelper.go(CONSTANTS.PAGE_CLIENT);
            break;
          case 'U000070':
            navigationHelper.go(CONSTANTS.PAGE_PRODUCT_NNW);
            nnwHelper.setActiveInsured(CONSTANTS.NNW_INCOGNITO_PERSON_ID);
            break;
          case 'P000990': //Pojazd o identyfikatorze ... występuje wielokrotnie na wniosku.
            navigationHelper.go(CONSTANTS.PAGE_PRODUCT_COMMUNICATION);
            break;
          case 'DID0003': //Suma ubezpieczenia nie może być niższa niż wartość pierwotna. Pierwotna wartość to {0}.
          case 'DID0004': //Rozszerzenie ochrony nie dopuszcza obniżenia liczby osób ubezpieczonych.
          case 'DID0013': //Nie można dokupić ubezpieczeń majątkowych do polisy komunikacyjnej.   
          case 'DID0014': //Nie można dokupić ubezpieczeń komunikacyjnych do polisy pozakomunikacyjnej.
          case 'DID0015': //Zmiana pakietu na inny pakiet lub produkt niedozwolona
          case 'DID0016': //Zmiana wariantu niedozwolona. Nie można obniżyć wariantu.
          case 'DID0017': //Usunięcie ryzyka jest niedozwolone.
          case 'DID0018': //Zmiana daty rozpoczęcia ubezpieczeń jest niedozwolona.
          case 'DID0019': //Zmiana daty końca ochrony ubezpieczeń jest niedozwolona.
          case 'DID0020': //podobno  nie używane?
          case 'DID0021': //Zmiana ubezpieczonego samochodu jest niedozwolona.
          case 'DID0022': //Usunięcie osoby z polisy jest niedozwolone.
          case 'DID0023': //Zmiana głównego ubezpieczonego jest niedozwolona
          case 'DID0024': //Edycja organizacji na polisie jest niedozwolona.
          case 'DID0025': //Edycja sumy ubezpieczenia na ryzyku, na którym została zarejestrowana szkoda o statusie otwartym jest niedozwolona. Pierwotna wartość to {0}.
          case 'DID0026': //Nie można dokupić ubezpieczenia OC komunikacyjnego {0}.
          case 'DID0027':
          case 'DID0028':
          case 'DID0029':
          case 'DID0030':
          case 'U000092': //Zmieniono okres ubezpieczenia dla ryzyk dobrowolnych.  
          case 'U000093': //Co najmniej jedno ubezpieczenie OC posiadacza pojazdów nie jest kontynuacją. Brak możliwości zawarcia OC z datą wsteczną.
          case 'DID0032':
          case 'U000095': //Dla ubezpieczenia OC okres ubezpieczenia posiada zachowaną ciągłość ochrony. Zmieniono okres ubezpieczenia dla ryzyk dobrowolnych.
          case 'U000094': //Dla ubezpieczenia OC okres ubezpieczenia posiada zachowaną ciągłość ochrony.
          case 'U000096': //Dla ryzyk dobrowolnych okres ubezpieczenia jest od <rrrr-mm-dd>.
          case 'U000097': //Ubezpieczenie OC dla <marka nr rej> nie jest kontynuacją. Brak możliwości zawarcia OC z datą wsteczną
          case 'U000098': //Dla ubezpieczenia OC okres ubezpieczenia jest od <rrrr-mm-dd> do <rrrr-mm-dd>  (zachowana ciągłość ochrony dla <marka nr rej>).
          case 'U000099': //Dla ubezpieczenia OC okres ubezpieczenia jest od <rrrr-mm-dd> do <rrrr-mm-dd>  (zachowana ciągłość ochrony dla <marka nr rej>). Zmieniono okres ubezpieczenia dla ryzyk dobrowolnych
          case 'P200321': // Cena za 1 m kw. lokalu mieszkalnego w podanej lokalizacji wynosi 10369 zł.\n Sugerowana Suma Ubezpieczenia wynosi 1275000 zł.\n *Szacunki własne ERGO Hestii na bazie danych z GUS i NBP
            self.goToSpecifyPageAndObjectByErrorObject(error);
            break;
          case 'U000063': //Wpisany nr zgody nie jest prawidłowy.
          case 'U000064': //Nr zgody {$numer} został już wykorzystany na innej polisie. Sprawdź jego poprawność.
          case 'U000065': //Zgoda o numerze {$numer} jest niewłaściwego typu.
          case 'U000066': //Brak wpisanego numeru zgody
          case 'U000081': //Zgoda o numerze {$numer} jest niewłaściwego typu.
          case 'U000084': //Dla agencji 000781 nie znaleziono pełnomocnictwa na datę 2015-07-10 dla pakietu, produktu: E1, H04"
            msgParams = self.bosErrorMapper.getRiskParams(error);
            if (angular.isDefined(msgParams.decisionType) && msgParams.decisionType === 'Exceed') {
              self.errorClickHelper.handleAuthorizationExceeded(msgParams);
            } else {
              self.errorClickHelper.handleDiscountDeviatedPremium(msgParams);
            }
            break;
          case 'U000086': //Wprowadzony kod promocji dla polisy: E7 jest nieprawidłwy, lub promocja wygasła
            self.errorClickHelper.handlePromoCodes(self.bosErrorMapper.getRiskParams(error));
            break;
          case 'U000067': //Dla {$vehicle} nie określono leasingodawcy.
            var leaserVehicleId = self.errorMapper.getEntityId(error);
            messagesClickHelper.handleLeasing(CONSTANTS.PRODUCT_TYPE_VEHICLE, dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_VEHICLE, leaserVehicleId), null);
            break;
          case 'U000087': //Brak uprawnień do przyjmowania gotówki
            msgParams = self.bosErrorMapper.getRiskParams(error);
            if (angular.isDefined(msgParams.policyNumber)) {
              self.errorClickHelper.handlePayment(msgParams);
            } else {
              navigationHelper.go(CONSTANTS.PAGE_SUMMARY);
            }
            break;
          case 'P000040':
            var editVehicleId = self.errorMapper.getEntityId(error);
            var clientId = 1;
            if (editVehicleId) {
              clientId = dataContainerHelper.getClientId(CONSTANTS.PRODUCT_TYPE_VEHICLE, editVehicleId);
            }
            self.errorClickHelper.handleVehicles({
              vehicleId: clientId
            });
            break;
          case 'O001000': //Dla JAN KOWALSKI (81032404415) nie wybrano rozszerzenia zakresu.
            msgParams = self.bosErrorMapper.getRiskParams(error);
            if (angular.isDefined(msgParams.objectType)) {
              navigationHelper.go(CONSTANTS.PAGE_MATRIX);
              if (msgParams.objectType === 'Persons' && angular.isDefined(msgParams.personId)) {
                dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, msgParams.personId);
                actionHelper.runAction('personGroupSelected');
              } else if (msgParams.objectType === 'Groups' && angular.isDefined(msgParams.groupId)) {
                dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, msgParams.groupId);
                actionHelper.runAction('personGroupSelected');
              }
            }
            break;
          case 'X00001':
          case 'P200300':
          case 'P200301':
          case 'P200302':
          case 'P200303':
          case 'P200304':
          case 'P200305':
          case 'P200306':
          case 'P200307':
          case 'P200308':
          case 'P200309':
          case 'P200310':
          case 'P200311':
          case 'P200312':
          case 'P200313':
          case 'P200314':
          case 'P200315':
          case 'P200316':
          case 'P200318':
          case 'P200319':
            navigationHelper.go(appVariables.mainState);
            msgParams = self.bosErrorMapper.getRiskParams(error);
            if (angular.isDefined(msgParams.objectType) && msgParams.objectType === 'Estates') {
              localizationModalHelper.showEditLocalizationModal(msgParams.estateId);
            }
            break;
          case 'PRODSELECTION': //wewnetrzne komunikaty dotyczace wyboru produktow
            if (angular.isObject(error.viewReference)) {
              navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[error.viewReference.productCode]);
            }
            //przelaczenie widoku na konkretny obiekt ubezpieczenia
            if (error.viewReference.productType !== null && error.viewReference.objectId !== null && error.viewReference.objectId !== CONSTANTS.MO_OBJECT) {
              dataContainerHelper.selectObject(error.viewReference.productType, error.viewReference.objectId);
            }
            break;
          default:
            errorHandled = false;
            break;
        }
        return errorHandled;
      };

      this.goToSpecifyPageAndObjectByErrorObject = function(error) {
        if (error.riskParams.objectType === 'Persons' && angular.isDefined(error.riskParams.personId)) {
          if (error.riskParams.productCode === CONSTANTS.PRODUCT_NNW) {
            nnwHelper.setActiveInsured(error.riskParams.personId);
          } else if (error.riskParams.productCode === CONSTANTS.PRODUCT_NNW_INCOGNITO) {
            nnwHelper.setActiveInsured(CONSTANTS.NNW_INCOGNITO_PERSON_ID);
          } else {
            dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, error.riskParams.personId);
            actionHelper.runAction('personGroupSelected');
          }

        } else if (error.riskParams.objectType === 'Groups' && angular.isDefined(error.riskParams.groupId)) {
          dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, error.riskParams.groupId);
          actionHelper.runAction('personGroupSelected');
        } else if (error.riskParams.objectType === 'Vehicles') {
          var clientId = 1;
          if (angular.isDefined(error.riskParams.objectId) && error.riskParams.objectId !== null) {
            clientId = error.riskParams.objectId;
          }
          dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_VEHICLE, error.riskParams.objectId);
          if (error.riskParams.containSubjectAttribute) {
            self.errorClickHelper.handleVehicles({
              vehicleId: clientId
            });
          }

        } else if (error.riskParams.objectType === 'Estates' && angular.isString(error.riskParams.estateId) && error.riskParams.estateId !== '') {
          dataContainerHelper.selectObject(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, error.riskParams.estateId);
        }

        if (angular.isDefined(error.riskParams.productCode)) {
          navigationHelper.go(CONSTANTS.PRODUCT_TO_STATE[error.riskParams.productCode]);
        } else {
          navigationHelper.go(appVariables.mainState);
        }
      };

      this.handleBosCodes = function(error) {
        var params = self.bosErrorMapper.getParams(error);
        var handled = bosMessageHelper.tryHandleByCode(error, params);
        if (handled) {
          return true;
        }
        if (params === false) {
          return false;
        }
        return self.errorClickHelper.handleError(params);
      };

      this.clearLoadedCodes = function() {
        bosMessageHelper.clearLoadedCodes();
      };

      /**
       * Czy błąd jest błędem taryfikacyjnym
       * @return {Boolean}
       */
      this.isTarifficationError = function(error) {
        if (bosMessageHelper.isBosCode(error.code) && error.type.toLowerCase() === 'error') {
          return true;
        }

        return false;
      };

      /**
       * Czy wiadomosc powinna zostac pokazana
       * @return {Boolean}
       */
      this.shouldShowMessage = function(message) {
        if (bosMessageHelper.codeToShow(message.code) && (!bosMessageHelper.isBosCode(message.code) || bosMessageHelper.checkDuplicate(message))) { //filtujemy nieobsługiwane kody bosowe
          return true;
        }
        return false;
      };

      /**
       * Czy wiadomosc powinna zostac pokazana mimo ze nie wspierana
       * @return {Boolean}
       */
      this.shouldShowMessageUnsuported = function(message) {
        if (commonHelper.getServerConfig().get('app').ALL_BOS_MESSAGES && bosMessageHelper.isBosCode(message.code)) {
          return true;
        }
        return false;
      };

      /**
       * dodawanie errora owrapowujace go w encje
       *
       * @param code - kod bledu
       * @param type - typ [error/warning]
       * @param message - tresc bledu
       * @param attributes - opcjonalne atrybuty
       * @param viewReference - powiazany widok
       */
      this.addError = function(code, type, message, attributes, viewReference) {
        var error = new ErrorEntityModel(),
          translated = true; //domyslnie komunikat jest traktowany jako przetlumaczony
        error.code = code;
        error.type = type;
        error.message = message;
        error.attributes = attributes;
        error.viewReference = viewReference;
        if (CONFIG.BEHAVIOR.messageTranslation && labelServiceHelper.isServiceTranslationActive() && !messageTranslator.isPolishSelected()) { //do usuniecia gdy AT wprowadzi mechanizm tlumaczenia komunikatów
          translated = messageTranslator.translateMessage(error);
        }
        if (translated) {
          mainErrorCollection.add(error);
        }

        error.riskParams = self.bosErrorMapper.getRiskParams(error);
      };

    };

    return new CommonMessageHelper();
  }
]);
