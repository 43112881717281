angular.module('lsnBase.models')
  .service('arrayLowerCaseModelFilter', ['strLowerCaseModelFilter', function(strLowerCaseModelFilter) {
    /**
     * zamienia elementy 1 poziomu w tablicy na lowerCase
     * @param {*} val
     * @returns {XPegaz.Filter.arrayLowerCase.resultArray|Array}
     */
    return function(val) {
      if (!$.isArray(val)) {
        return val;
      }

      var resultArray = [];
      angular.forEach(val, function(item) {
        resultArray.push((!angular.isString(item)) ? item : strLowerCaseModelFilter(item));
      });

      return resultArray;
    };
  }]);