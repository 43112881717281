angular.module('lsnBase.filters')
  .filter('formatFileSize1024', [
  function() {
    var units = [
      {size: 1024 * 1024 * 1024, suffix: ' GB'},
      {size: 1024 * 1024, suffix: ' MB'},
      {size: 1024, suffix: ' KB'}
    ];

    return function(bytes) {
      if (!angular.isNumber(bytes)) {
        return '';
      }
      var unit = true,
        i = 0,
        prefix,
        suffix;
      while (unit) {
        unit = units[i];
        prefix = unit.prefix || '';
        suffix = unit.suffix || '';
        if (i === units.length - 1 || bytes >= unit.size) {
          return prefix + (bytes / unit.size).toFixed(2) + suffix;
        }
        i += 1;
      }
      return ''; // wont reach here, but lint need return
    };

  }]);
