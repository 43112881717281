angular.module('lsnBase.deepExtend')
  .provider('lsnDeepExtend', [
    function() {
      var deepExtend = function(dst) {
        angular.forEach(arguments, function(obj) {
          if (obj !== dst) {
            angular.forEach(obj, function(value, key) {
              if (dst[key] && dst[key].constructor && dst[key].constructor === Object) {
                deepExtend(dst[key], value);
              } else {
                dst[key] = angular.copy(value);
              }
            });
          }
        });
        return dst;
      };

      /**
       * rekurencyjne nadpisywanie obiektu
       * @param  {Object} dst
       * @return {Object}
       */
      this.deepExtend = function() {
        return deepExtend.apply(this, Array.prototype.slice.call(arguments, 0));
      };

      /**
       * get providera
       * @return {object} [description]
       */
      this.$get = function() {
        return deepExtend;
      };
    }
  ]);