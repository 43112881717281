angular.module('ihestiaWidgets.policyDetails')
  .service('ihestiaSumInsuredVectorModalHelper', ['LsnModalHelper', '$filter',
    function(LsnModalHelper, $filter) {
      var SumInsuredVectorModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        this.modalOptions = {
          size: 'sm',
          title: $filter('translate')('riskTable.su.modalTitle', {componentCode: 'iKonto'}),
          templateUrl: 'ihestia-widgets-templates-app/policyDetails/life/sumInsuredVector/sumInsuredVectorModal.tpl.html',
          okBtnName: null,
          cancelBtnName: ['Public.close', {
            componentCode: 'Public'
          }]
        };

        this.modalData = {
          sumInsuredVector: null
        };

        this.setOptions(this.modalOptions);

        this.tplData = {
          sumInsuredVector: null
        };

        this.init = function() {
          self.tplData.sumInsuredVector = self._reduceForDisplaySumInsuredVector(self.modalData.sumInsuredVector);
        };

        this._reduceForDisplaySumInsuredVector = function(sumInsuredVector) {
          return _.chain(sumInsuredVector)
            .map(function(item) { // map to E4 format
              return {
                no: parseInt(item.year, 10) + 1,
                value: item.sumInsured
              };
            })
            .map(self._mapValuesToPeriods)
            .uniqBy('value')
            .filter(function(item) {
              return item.value !== -1;
            })
            .value();
        };

        this._mapValuesToPeriods = function(item, index, array) {
          var mappedValue = _.merge({}, item);
          // if value is eq. to -1 return without mapping, filtering out these values later in chain
          if (item.value !== -1) {
            if (index < array.length - 1 && array[index + 1].value === -1) { // checks next item in array - period format
              mappedValue = {
                no: '{0} - {1}'.format(item.no, array[index + 1].no),
                value: item.value
              };
            }
          }
          return mappedValue;
        };

        this.confirm = function() {
          self.setPromiseAsResolved();
          return this.hideModal();
        };
      };
      return new SumInsuredVectorModalHelper();
    }]);
