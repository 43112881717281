angular.module('ihestiaWidgets.life')
  .service('ihestiaBeneficiaryEditModalHelper', ['$filter', 'LsnModalHelper', 'LsnBeneficiaryModelConstants', 'ihestiaRestDictionaryFirstNamesSvc', 'ihestiaRestDictionaryLastNamesSvc', 'ihestiaRestDictionaryAddressesSvc', 'ihestiaDictionaryHelper', '$q', 'PersonModel', 'OrganizationModel', '$timeout',
    function($filter, LsnModalHelper, LsnBeneficiaryModelConstants, ihestiaRestDictionaryFirstNamesSvc, ihestiaRestDictionaryLastNamesSvc, ihestiaRestDictionaryAddressesSvc, ihestiaDictionaryHelper, $q, PersonModel, OrganizationModel, $timeout) {
      var BeneficiaryEditModalHelper = function() {
        LsnModalHelper.apply(this, arguments);
        var self = this;

        /**
         * set modal options
         */
        this.modalOptions = {
          size: 'md',
          title: ['beneficiaryModal.title', {
            componentCode: 'Public'
          }],
          templateUrl: 'ihestia-widgets-templates-app/life/beneficiaryEdit/beneficiaryEditModal.tpl.html',
          okBtnName: ['save', {
            componentCode: 'Public'
          }],
          cancelBtnName: ['Public.cancel', {
            componentCode: 'Public'
          }]
        };

        this.setOptions(self.modalOptions);

        this.modalData = {
          beneficiary: null,
          subject: null,
          editMode: null,
          personModel: null,
          organizationModel: null,
          beneficiaryModel: null,
          showCessionaryCheckbox: null,
          disableCessionaryCheckbox: null,
          mErrors: null,
          readOnly: null,
          messagesTemplateUrl: null,
          required: null, // form fields to mark as required
          invalidPartialStreetData: null, // if true street is required if streetPrefix is defined and opposite
          subjectDataNonEditable: null // if beneficiary subject is an insurer or child, its data shouldn't br editable, only beneficiary related data
        };

        this.tplData = {
          subject: null,
          personData: null,
          organizationData: null,
          beneficiaryData: null,
          formData: {
            address: null,
            subjectType: null,
            countryCode: null
          },
          dicts: {
            beneficiaryTypes: [],
            subjectTypes: LsnBeneficiaryModelConstants.DICTIONARY_SUBJECT_TYPE,
            kinshipTypes: [],
            countries: []
          },
          services: {
            firstNamesSvc: ihestiaRestDictionaryFirstNamesSvc,
            lastNamesSvc: ihestiaRestDictionaryLastNamesSvc,
            addressSvc: ihestiaRestDictionaryAddressesSvc
          },
          showElements: {
            organization: false,
            person: false,
            cessionaryCheckbox: false,
            cessionaryForm: false
          },
          disabledElements: {
            cessionaryCheckbox: false,
            subjectData: false
          },
          required: {
            organizationName: false,
            firstName: false,
            lastName: false,
            birthDate: false,
            nip: false,
            regon: false,
            kinship: false,
            countryCode: false,
            address: null // string array with address fields to mark as required - this format is accepted by lsn-address directive
          },
          addressErrors: null, // object indicating fields with error for lsn-address directive
          readOnly: null,
          maxBirthDate: new XDate().toString('yyyy-MM-dd'),
          mErrors: null,
          messages: null,
          wasSubmitted: false,
          onChangeType: function() {
            return self._onChangeType();
          },
          onChangeSubjectType: function() {
            return self._onChangeSubjectType();
          }
          // formRef -> AngularJS form object reference, set by controller in OnViewInit
        };

        this.initData = function() {
          self._initDictionaries()
            .then(function(dicts) {
              self.tplData.dicts.kinshipTypes = dicts[0];
              self.tplData.dicts.countries = dicts[1];
              // make sure default kinship type is set
              if (!self.tplData.beneficiaryData.degreeOfKinship) {
                self.tplData.beneficiaryData.degreeOfKinship = self._getDefaultKinshipCode();
              }
            });
          self.tplData.wasSubmitted = false;
          self.tplData.readOnly = self.modalData.readOnly;
          self.setOptions({
            okBtnName: self.tplData.readOnly ? '' : ['save', {
              componentCode: 'Public'
            }]
          });
          if (self.modalData.editMode) {
            self.tplData.beneficiaryData = self.modalData.beneficiary;
            if (self.tplData.beneficiaryData.subjectType === LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON) { // edit person
              self.tplData.personData = self.modalData.subject;
              self.tplData.organizationData = self._getEmptyOrganization();
              self.tplData.formData.subjectType = LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON;
              self.tplData.formData.address = _.get(self.tplData.personData, 'addresses[0]', self._initSimpleAddressData());
            } else { // edit organization
              self.tplData.personData = self._getEmptyPerson();
              self.tplData.organizationData = self.modalData.subject;
              self.tplData.formData.subjectType = LsnBeneficiaryModelConstants.SUBJECT_TYPE_ORGANIZATION;
              self.tplData.formData.address = _.get(self.tplData.organizationData, 'addresses[0]', self._initSimpleAddressData());
              self.tplData.beneficiaryData.degreeOfKinship = self._getDefaultKinshipCode();
            }
          } else { // add new beneficiary
            self.tplData.beneficiaryData = self._getEmptyBeneficiary();
            self.tplData.personData = self._getEmptyPerson();
            self.tplData.organizationData = self._getEmptyOrganization();
            self.tplData.formData.subjectType = LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON;
            self.tplData.formData.address = self._initSimpleAddressData();
            self.tplData.formData.countryCode = 'PL';
            // default degree of kinship set to lack if exists
            self.tplData.beneficiaryData.degreeOfKinship = self._getDefaultKinshipCode();
          }
          // IHESTLIFE-4377 we set postalCode requirement after confirm occurred,
          // because it is incorrectly set as dirty after focus in lsnAddress directive by Angular
          // (validation occurs before submitting)
          self.tplData.required = self.tplData.required = _.merge({}, self.modalData.required, {address: {postalCode: false}});
          self._initShowElements();
          self._initBeneficiaryTypes(self.tplData.beneficiaryData.type);
          self.tplData.mErrors = self.modalData.mErrors;
          if (self.tplData.beneficiaryData.cessionary) {
            self.tplData.formData.countryCode = self.tplData.formData.address.countryCode || 'PL';
          } else {
            self.tplData.formData.countryCode = 'PL';
          }
        };

        this._initDictionaries = function() {
          var kinshipParams = {
            resource: 'standard/Relationships',
            requestType: 'searches'
          };

          var kinshipDictPromise = ihestiaDictionaryHelper.getDictWithPromise('ApplicationRelationshipDictionary', kinshipParams);

          var countriesDictPromise = $q(function(resolve) {
            ihestiaDictionaryHelper.getDict('countries', {
              requestType: 'searches',
              callback: function(countries) {
                resolve(countries);
              }
            });
          });
          return $q.all([kinshipDictPromise, countriesDictPromise]);
        };

        this._initShowElements = function() {
          self.tplData.showElements.organization = self.tplData.beneficiaryData.subjectType === LsnBeneficiaryModelConstants.SUBJECT_TYPE_ORGANIZATION;
          self.tplData.showElements.person = self.tplData.beneficiaryData.subjectType === LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON;
          self.tplData.showElements.cessionaryCheckbox = (self.modalData.showCessionaryCheckbox && self.tplData.beneficiaryData.type === LsnBeneficiaryModelConstants.BENEFICIARY_TYPE_MAIN) || self.tplData.beneficiaryData.cessionary;
          self.tplData.disabledElements.cessionaryCheckbox = self.tplData.showElements.cessionaryCheckbox && self.modalData.disableCessionaryCheckbox;
          self.tplData.showElements.cessionaryForm = self.tplData.showElements.cessionaryCheckbox && self.tplData.beneficiaryData.cessionary;
          self.tplData.disabledElements.subjectData = self.modalData.subjectDataNonEditable;
        };

        this._onChangeType = function() {
          if (!self.tplData.beneficiaryData.type) {
            _.set(_.first(self.tplData.dicts.beneficiaryTypes), 'selected', true);
          }
          if (self.tplData.beneficiaryData.type !== LsnBeneficiaryModelConstants.BENEFICIARY_TYPE_MAIN) {
            self.tplData.beneficiaryData.cessionary = false;
          }
          self.tplData.showElements.cessionaryCheckbox = (self.modalData.showCessionaryCheckbox && self.tplData.beneficiaryData.type === LsnBeneficiaryModelConstants.BENEFICIARY_TYPE_MAIN) || self.tplData.beneficiaryData.cessionary;
          self.tplData.showElements.cessionaryForm = self.tplData.showElements.cessionaryCheckbox && self.tplData.beneficiaryData.cessionary;
        };

        this._onChangeSubjectType = function() {
          if (self.tplData.formData.subjectType === LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON) {
            self.tplData.showElements.person = true;
            self.tplData.showElements.organization = false;
          } else {
            self.tplData.showElements.person = false;
            self.tplData.showElements.organization = true;
          }
        };

        this._initBeneficiaryTypes = function(selectedBeneficiaryType) {
          self.tplData.dicts.beneficiaryTypes = _.chain(LsnBeneficiaryModelConstants.DICTIONARY_BENEFICIARY_TYPE)
            .toPairs()
            .map(function(pair) {
              return {
                code: pair[0],
                name: pair[1],
                selected: selectedBeneficiaryType === pair[0]
              };
            })
            .value();
        };

        this._getEmptyBeneficiary = function() {
          return {
            cessionary: false,
            type: LsnBeneficiaryModelConstants.BENEFICIARY_TYPE_MAIN,
            subjectType: LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON,
            subjectClientId: null,
            degreeOfKinship: null,
            shareRatio: 0,
            subjectName: ''
          };
        };

        this._getEmptyPerson = function() {
          return self.modalData.personModel ? new self.modalData.personModel() : new PersonModel();
        };

        this._getEmptyOrganization = function() {
          return self.modalData.organizationModel ? new self.modalData.organizationModel() : new OrganizationModel();
        };

        /**
         * init address data on Person object
         * @return {object} address
         */
        this._initSimpleAddressData = function() {
          return {
            city: null,
            postalCode: null,
            streetPrefix: null,
            street: null,
            countryCode: null
          };
        };

        /**
         *
         * @param formRef
         * @private
         */
        this._markRequiredFieldsAsDirty = function(formRef) {
          _.forEach(_.get(formRef.$error, 'required'), function(field) {
            field.$setDirty();
          });

          _.forEach(_.get(formRef['addressForm.addressForm'], '$error.required'), function(field) { // strange path, but set by lsn-address
            field.$setDirty();
          });
        };

        this._getDefaultKinshipCode = function() {
          return _.get(_.find(self.tplData.dicts.kinshipTypes, ['code', 'lack']), 'code', null);
        };

        /**
         * Creates address required fields object with all attributes set to false, necessary to override default
         * required fields in lsnAddress directive
         * @private
         */
        this._getEmptyAddressRequiredFields = function() {
          return {
            postalCode: false,
            city: false,
            streetPrefix: false,
            street: false,
            house: false,
            apartment: false
          };
        };

        /**
         * Validates fields that have dynamic ng-required: street, streetPrefix and postalCode
         * Separate validation for dynamic address fields must be provided, because form validity is determined asynchronously.
         * @private
         */
        this._areDynamicRequiredFieldsInvalid = function() {
          var postalCodeInvalid, streetInvalid, streetPrefixInvalid = false;
          // validate address data if cessionary form (with address fields) is visible
          if (self.tplData.showElements.cessionaryForm) {
            postalCodeInvalid = _.get(self.modalData.required, 'address.postalCode', false) && _.isEmpty(_.get(self.tplData.formData.address, 'postalCode', null));
            streetInvalid = _.get(self.modalData.required, 'address.street', false) && _.isEmpty(_.get(self.tplData.formData.address, 'street', null));
            streetPrefixInvalid = _.get(self.modalData.required, 'address.streetPrefix', false) && _.isEmpty(_.get(self.tplData.formData.address, 'streetPrefix', null));
          }
          return postalCodeInvalid || streetInvalid || streetPrefixInvalid;
        };

        /**
         * Set appropriate required fields on street and street prefix, if both of these attributes can only be defined or undefined together
         * @private
         */
        this._validatePartialStreetData = function() {
          _.set(self.tplData.required.address, 'street', !_.isEmpty(_.get(self.tplData.formData.address, 'streetPrefix', null)));
          _.set(self.tplData.required.address, 'streetPrefix', !_.isEmpty(_.get(self.tplData.formData.address, 'street', null)));
        };

        this.confirm = function() {
          // IHESTLIFE-4377 - we set full requirement after first submit
          if (!self.tplData.wasSubmitted) {
            self.tplData.required = _.merge({}, self.modalData.required);
            self.tplData.wasSubmitted = true;
          }

          if (self.modalData.invalidPartialStreetData) {
            self._validatePartialStreetData();
          }
          // IHESTLIFE-4377 we manually check required validity for fields with dynamic requirement
          if (!self.tplData.formRef.$valid || self._areDynamicRequiredFieldsInvalid()) {
            // runs after current $digest is complete - after required fields were updated in directives
            // must be run async so that lsnAddress directive sets new required fields (postalCode requirement set)
            $timeout(function() {
              self._markRequiredFieldsAsDirty(self.tplData.formRef);
            });
            return false;
          }
          var isPerson = self.tplData.formData.subjectType === LsnBeneficiaryModelConstants.SUBJECT_TYPE_PERSON;
          var subjectDataToSave = isPerson ? self.tplData.personData : self.tplData.organizationData;
          self.tplData.beneficiaryData.subjectType = self.tplData.formData.subjectType;
          if (self.tplData.beneficiaryData.cessionary && !self.tplData.disabledElements.subjectData) {
            _.set(subjectDataToSave, 'addresses[0]', self.tplData.formData.address);
            _.set(subjectDataToSave, 'addresses[0].countryCode', self.tplData.formData.countryCode === 'PL' ? null : self.tplData.formData.countryCode);
          } else {
            subjectDataToSave.addresses = undefined;
          }
          if (!isPerson) {
            self.tplData.beneficiaryData.degreeOfKinship = null;
          }

          var response = {
            beneficiary: angular.copy(self.tplData.beneficiaryData),
            subject: angular.copy(subjectDataToSave),
            editMode: self.modalData.editMode
          };

          self.setPromiseAsResolved(response);
          return self.hideModal();
        };

      };


      return new BeneficiaryEditModalHelper();
    }
  ]);
