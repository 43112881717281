angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryBanksSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryBanksSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);
        
        this.resource = 'banks';
        this.sortBy = 'name';

      };
      return new RestDictionaryBanksSvc();
    }
  ]);