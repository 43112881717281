angular.module('ihestiaRestServicesBase')

  /**
   * wnioski ubezp.
   */
  .service('policiesSvc', ['IHestiaRestServiceAbstractDef', function(IHestiaRestServiceAbstractDef) {


    /**
     * Podstawowy konstruktor serwisu
     */
    var Policies = function() {

      this.resource = 'policies';
      this.businessArea = 'insurance';
      this.port = '8206';
      this.path = 'RestPolicies';

    };

    /**
     * Konstruktor v1
     */
    var PoliciesV1 = function() {
      Policies.apply(this, arguments);
      var self = this;

      /**
       * zwraca listę dokumentów dostępnych dla wskazanej polisy wraz z informacją jaki z dokumentów został załączony
       *
       * @param {String} id
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getDocuments = function(id, callback) {
        return self.get(id, null, 'documents', callback);
      };

      /**
       * Usługa aktualizuje dokumenty załączone do polisy.
       *
       * @param {String} id polisy
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.putDocuments = function(id, data, callback) {
        return self.put(id, data, 'documents', callback);
      };

      /**
       * Usługa do pobrania informacji o przesłanych elektronicznie plikach w ramach danej polisy.
       *
       * @param {String} id polisy
       * @param {function} callback
       * @returns {*}
       */
      this.getFiles = function(id, callback) {
        return self.get(id, null, 'files', callback);
      };

      /**
       * Usługa do pobrania wznawianych polis.
       *
       * @param {String|Array} ids idki lub id polisy
       * @param {function} callback
       * @returns {*}
       */
      this.getRenew = function(ids, policySymbol, callback, errorCallback, httpParams) {
        var idsString;
        if (typeof ids === 'object') {
          idsString = ids.join(',');
        } else {
          idsString = ids + '';
        }

        var url = self._getServiceUrl(idsString, 'renew') + '/' + policySymbol;
        self.call(url, 'GET', null, callback, errorCallback, httpParams);
      };

      /**
       * zwraca listę polis, które powstały z tego samego wniosku ubezpieczeniowego
       *
       * @param {String} id
       * @param {function} callback
       * @returns {*}
       */
      this.getLinkedPolicies = function(id, data, callback, errorCallback, httpParams) {
        return self.get(id, data, 'linkedPolicies', callback, errorCallback, httpParams);
      };

      /**
       * Usługa do pobrania dokupienia polisy
       *
       * @param {String} id polisy
       * @param {function} callback
       * @returns {*}
       */
      this.getExtension = function(id, callback, errorCallback, httpParams) {
        var url = self._getServiceUrl(id, 'extend') + '/application';
        return self.call(url, 'GET', null, callback, errorCallback, httpParams);
      };

      /**
       * Register token for centrally renewed policies
       * @param  {Object} data
       * @param {boolean} [isOrganization]
       * @returns {*}
       */
      this.registerCentrallyRenewedPoliciesToken = function(data, isOrganization) {
        var customerData;
        if (isOrganization) {
          customerData = _.omit(data, ['firstName', 'lastName', 'identityNumber']);
          if (customerData.registrationCountry === 'PL') {
            customerData.registrationCountry = null;
          }
        } else {
          customerData = {
            firstName: data.firstName,
            lastName: data.lastName,
            identityNumber: data.identityNumber
          };
        }
        return self.post(data.customerAccountId, customerData, 'accessTokenToCentrallyRenewedPolicies', null, null, {allowedStatuses: [404]});
      };

    };

    /**
     * Konstruktor v2
     */
    var PoliciesV2 = function() {
      Policies.apply(this, arguments);
      var self = this;
      this.apiVersion = 'v2';

      /**
       * zwraca listę dokumentów dostępnych dla wskazanej polisy wraz z informacją jaki z dokumentów został załączony
       *
       * @param {String} numer polisy
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getDocuments = function(number, callback) {
        return self.get(number, null, 'documents', callback);
      };

      /**
       * Usługa do pobrania informacji o przesłanych elektronicznie plikach w ramach danej polisy.
       *
       * @param {String} numer polisy
       * @param {function} callback
       * @returns {*}
       */
      this.getFiles = function(number, callback) {
        return self.get(number, null, 'files', callback);
      };

    };
    /**
     * Konstruktor v3
     */
    var PoliciesV3 = function() {
      Policies.apply(this, arguments);
      var self = this;
      this.apiVersion = 'v3';

      /**
       * zwraca listę dokumentów dostępnych dla wskazanej polisy wraz z informacją jaki z dokumentów został załączony
       *
       * @param {String} numer polisy
       * @param {Object} data
       * @param {function} callback
       * @returns {*}
       */
      this.getDocuments = function(number, callback) {
        return self.get(number, null, 'documents', callback);
      };

      /**
       * Usługa do pobrania informacji o przesłanych elektronicznie plikach w ramach danej polisy.
       *
       * @param {String} numer polisy
       * @param {function} callback
       * @returns {*}
       */
      this.getFiles = function(number, callback) {
        return self.get(number, null, 'files', callback);
      };

    };

    return IHestiaRestServiceAbstractDef.setApiConstructors({
      v1: PoliciesV1,
      v2: PoliciesV2,
      v3: PoliciesV3
    });

  }]);
