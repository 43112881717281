angular.module('lsnBase.modal')
  .provider('lsnConfirmModalHelper', [function() {

    var provider = this;

    /**
     * Domyślne opcje
     * @type {Object}
     */
    this.defaultOptions = {
      title: 'Potwierdzenie',
      okBtnName: 'Potwierdź',
      cancelBtnName: 'Anuluj',
      content: ''
    };

    this.$get = ['LsnModalHelper', function(LsnModalHelper) {
      var LsnConfirmModalHelper = function() {

        /**
         * Pokazujemy modal ootwierdzenia
         * @param  {Object} options opcje do nadpisania
         * @return {Promise}
         */
        this.showConfirmModal = function(options) {
          var opts = angular.copy(provider.defaultOptions);
          angular.extend(opts, options);
          var helper = new LsnModalHelper();
          return helper.setOptions(opts).showModal();
        };

      };

      return new LsnConfirmModalHelper();
    }];
  }]);