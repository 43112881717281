angular.module('ihestiaSsoBase')

.factory('ihestiaSsoInterceptor', ['$q', 'ihestiaCharacterConflictedHelper', '$injector', '$rootScope', '$filter', 'ihestiaIcConflictedHelper',
  function($q, ihestiaCharacterConflictedHelper, $injector, $rootScope, $filter, ihestiaIcConflictedHelper) {


    var noSessionHandler = function()
    {
      var error = new Error();
      error.code = 401;
      error.message = $filter('translate')('Security.sessionExpired');
      $rootScope.$broadcast('iHestia.Session.Expired');

    };

    var ssoInterceptor = {
      response: function(resp) {
        //wyłapujemy requesty odrzucone przez f5
        if ((resp.headers('content-type') !== 'application/json' && angular.isString(resp.data) && resp.data.indexOf('SAMLRequest') !== -1) || resp.headers('AP-Session-Status') === 'Expired' || (angular.isString(resp.data) && resp.data.indexOf('d8f2bea47d1df393f0e01e72a1fc7df0') !== -1)){
          noSessionHandler();
        }
        return resp;
      },
      responseError: function(rejection) {
        if (rejection.status === 401 || (angular.isFunction(rejection.headers) && rejection.headers('AP-Session-Status') === 'Expired') || (angular.isString(rejection.data) && rejection.data.indexOf('d8f2bea47d1df393f0e01e72a1fc7df0') !== -1)) {
          noSessionHandler();
        }

        if(rejection.status === 409 && angular.isFunction(rejection.headers) && rejection.headers('AP-User-Status') === 'CHARACTER_CHANGED')
        {
          ihestiaCharacterConflictedHelper.charactersConflicted(rejection.headers('AP-User-Character'));
        }
        if(rejection.status === 409 && angular.isFunction(rejection.headers) && rejection.headers('AP-Conflict-Details') === 'IcConflict')
        {
          ihestiaIcConflictedHelper.icConflicted();
        }

        return $q.reject(rejection);
      }
    };
    return ssoInterceptor;

  }
]);