angular.module('ihestiaRestServicesBase')
  .factory('passwordResetConstants', [function() {
    var constsants = {
      RESET_MODE_TOKEN: 'Token',
      RESET_MODE_SMS: 'Sms',
      RESET_MODE_QUESTIONS: 'Qa',
      RESET_MODE_ONE_TIME_PASSWORD: 'OneTimePassword',
      RESET_METHOD_TOKEN: 'initresetbytoken',
      RESET_METHOD_SMS: 'initresetbysms',
      RESET_METHOD_ONE_TIME_PWD: 'initresetbyonetimepassword',
      RESET_METHOD_QUESTIONS: 'initresetbyquestions',
      resetMethodsData: {}
    };

    constsants.resetMethodsData[constsants.RESET_MODE_TOKEN] = {
      name: 'Adres email',
      method: constsants.RESET_METHOD_TOKEN
    };
    constsants.resetMethodsData[constsants.RESET_MODE_SMS] = {
      name: 'Kod SMS',
      method: constsants.RESET_METHOD_SMS
    };
    constsants.resetMethodsData[constsants.RESET_MODE_QUESTIONS] = {
      name: 'Pytania zabezpieczające',
      method: constsants.RESET_METHOD_QUESTIONS
    };
    constsants.resetMethodsData[constsants.RESET_MODE_ONE_TIME_PASSWORD] = {
      name: 'Hasło jednorazowe',
      method: constsants.RESET_METHOD_ONE_TIME_PWD
    };

    return constsants;
  }])
  .factory('passwordResetSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var passwordResetSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);
        var self = this;

        this.resource = 'passwords';
        this.businessArea = 'authentication';


        /**
         * Zmiana hasła przez pytnia
         * @param  {Object}   data     [description]
         * @return {Promise}
         */
        this.changePasswordByQuestions = function(data) {
          return self.post('changebyquestions', data);
        };

      };

      return new passwordResetSvc();
    }])
;