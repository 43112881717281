angular.module('ihestiaWidgets.pza', [
  'ihestia-widgets-templates-app'
])
  .config(['$alertProvider', function($alertProvider) {
    angular.extend($alertProvider.defaults, {
      duration: 5
    });
  }])
  .run(['bodyInjectorHelper',
    function(bodyInjectorHelper) {
      bodyInjectorHelper.injectDirective('ihestiaPzaModalsInjector', 'ihestia-pza-modals-injector');
    }
  ]);