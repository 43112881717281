angular.module('ihestiaRestServicesBase')
  .factory('ihestiaRestDictionaryClausesSvc', ['ihestiaRestAbstractDictionariesSvc',
    function(ihestiaRestAbstractDictionariesSvc) {

      var RestDictionaryClausesSvc = function() {
        ihestiaRestAbstractDictionariesSvc.apply(this, arguments);

        this.resource = 'clauses';

      };
      return new RestDictionaryClausesSvc();
    }
  ]);