angular.module('lsnBase.models')
.factory('LsnGroupModelV1', ['LsnAbstractModel', 'lsnModelFactory',
  function(LsnAbstractModel, lsnModelFactory) {

    var LsnGroupModelV1 = function()
    {
      this.objectName = 'Group';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        metaData: {
          type: 'MetaData'
        },
        name: {
          type: 'string'
        },
        dynamicValues: {
          type: 'dynamicValues'
        }
      };

      this.metaData = lsnModelFactory.getObject('MetaData', {data: {className: this.objectName}}); // MetaData, Podstawowe dane obiektu. W szczególności identyfikator ob.
      this.name = null; // nazwa grupy
      this.dynamicValues = null; // Lista atrybutów dotyczących osoby
    };

    LsnGroupModelV1.prototype = LsnAbstractModel.prototype;

    return LsnGroupModelV1;
  }]);