/**
 * UWAGA metoda findBanks nie powinna znajdowac sie w scope parent
 * @TODO zmienic na scope izolowany, aby zabezpieczyc sie na problem z uwagi
 * PRZYKLAD UZYCIA
 * 'html'
 * <lsn-banks find-banks-method="findBanksMethod"></lsn-banks>
 * 'html'
 * 'js' - ctrl
 * $scope.findBanksMethod = applicationDictionaryHelper.findBanks;
 *
 * $scope.onSelect = function($item) {
 *   $scope.showBankSearch = false;
 *   $scope.selectedBankName = $item.name;
 *   $scope.modalData.bankData = $item;
 *   $scope.modals.cessionModal.settings.okBtnDisabled = false;
 * };
 * 'js'
 */
angular.module('lsnBase.autocompleters')
        .directive('lsnBanks', ['availabilityHelper',
          function(availabilityHelper) {
            return {
              restrict: 'E',
              replace: true,
              scope: true,
              templateUrl: function(elem, attrs) {
                if (attrs.drvTemplate) {
                  return attrs.drvTemplate;
                } else {
                  return 'lsn-base-templates-app/autocompleters/banks/banks.tpl.html';
                }
              },
              controller: ['$scope', '$element', '$attrs', '$parse', function($scope, $element, $attrs, $parse) {
                  $scope.loadingElements = false;
                  $scope.dictAvailable = availabilityHelper.isAvailable('banks');
                  // zczytuje z atrybutu metode, ktora zwraca liste bankow
                  // metoda ta powinna zwracac obiekty w formacie iHestyjnym (patrz applicationDictionaryHelper.findBanks z modulu salespath2) 
                  $scope.findBanksMethod = $parse($attrs.findBanksMethod)($scope);

                  /** określamy wymagalność pola */
                  if(angular.isDefined($attrs.required)) {
                    $scope.requiredExp = true;
                  } else if($attrs.ngRequired) {
                    $scope.requiredExp = $attrs.ngRequired;
                  } else {
                    $scope.requiredExp = false;
                  }

                  if ($attrs.useRegex) {
                    $scope.regex = '\'^[^,:;]*$\'';
                  } else {
                    $scope.regex = '';
                  }

                  if(angular.isDefined($attrs.highlight)) {
                    $scope.urlTypeaheadMatch = 'lsn-base-templates-app/autocompleters/banks/typeaheadHighlightMatch.tpl.html';
                  } else {
                    $scope.urlTypeaheadMatch = 'lsn-base-templates-app/autocompleters/banks/typeaheadMatch.tpl.html';
                  }

                  /** określamy wymagalność pola */
                  if(angular.isDefined($attrs.disabled)) {
                    $scope.isDisabled = $attrs.disabled;
                  } else if(angular.isDefined($attrs.ngDisabled)) {
                    $scope.isDisabled = $parse($attrs.ngDisabled)($scope);
                  } else {
                    $scope.isDisabled = false;
                  }

                  if ($scope.dictAvailable) {
                    $scope.findBanks = function(val) {
                      $scope.loadingElements = true;

                      return $scope.findBanksMethod(val).then(function(items) {
                        $scope.loadingElements = false;
                        return items;
                      }, angular.noop);
                    };

                  }
                }]
            };
          }]);