angular.module('ihestiaIkomunikator')
  .factory('IKomunikatorFtsSearchModel', ['IKomunikatorAbstractModel', function(IKomunikatorAbstractModel) {

    var FtsSearchModel = function() {

      angular.extend(this, IKomunikatorAbstractModel);
      var self = this;
      /**
       * Treść komentarza
       * @type {String}
       */
      this.type = null;

      this.pesel = null;

      this.typeLabelMap = {
        Client: 'Klient'
      };

      /*eslint-disable camelcase */
      /**
       * [creatorIdentifier description]
       * @type {String}
       */
      this.firstname = null;
      this.surname = null;
      this.ra_number = null;
      this.name = null;
      this.login = null;
      this.agent_name = null;
      this.document_number = null;
      this.profile_number = null;
      this.phone = null;
      this.email = null;
      this.intermediary_name = null;
      this.agency_id = null;
      /*eslint-enable camelcase */
      
      /**
       * [set setuje dane z obiektu z usługi]
       * @param {[type]} data [description]
       */
      this.set = function(data) {
        angular.forEach(data._source, function(value, key){
          self[key] = value;
          if(key === 'type' && angular.isDefined(self.typeLabelMap[value])){
            self.typeLabel = self.typeLabelMap[value];
          }
        });
      };
    };

    return FtsSearchModel;
  }]);