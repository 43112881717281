/* eslint-disable no-extend-native */
Array.prototype.unique = function() {
  'use strict';
  var newArray = [],
    self = this;
  angular.forEach(self, function(value) {
    var isDuplicate = false;
    angular.forEach(newArray, function(currentValue) {
      if (angular.equals(currentValue, value)) {
        isDuplicate = true;
      }
    });
    if (!isDuplicate) {
      newArray.push(value);
    }
  });
  return newArray;
};

/**
 * Usuwamy zadany element z tablicy
 * @param  {mixed} element element do usunięcia
 * @return {arr}
 */
Array.prototype.removeElement = function(element) {
  if (this.length > 0) {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] === element) {
        this.splice(i, 1);
      }
    }
  }
  return this;
};

Math.uniqueId = function(prefix) {
  'use strict';
  prefix = typeof prefix !== 'undefined' ? prefix : '';
  return prefix + '_' + Math.random().toString(36).substr(2, 9);
};

Object.byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  while (a.length) {
    var n = a.shift();
    if (n in o) {
      o = o[n];
    } else {
      return false;
    }
  }
  return o;
};

/**
 * Zamienia string na pisany wielką literą
 * @return {[type]} [description]
 */
String.prototype.capitalize = function() {
  var inputValue = this,
    capitalized = inputValue.replace(/[\wżźćńółęąśŻŹĆĄŚĘŁÓŃ]*/g, function(txt) { // wcześniejszy regexp "/([^\W_]+[\s-]*) */g" nie łapał polskiich znaków
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  return capitalized;
};

Number.prototype.round = function(precision) {
  var multiplier = Math.pow(10, precision);
  return Math.round((this * multiplier)) / multiplier;
};

Number.prototype.toRate = function() {
  return 1 + (this / 100);
};

Number.prototype.ceil = function(ceiling) {
  return Math.ceil(this / ceiling) * ceiling;
};

Number.prototype.toPercentage = function(precision) {
  return ((1 - this) * 100).round(precision);
};

String.prototype.toPercentage = function(precision) {
  return parseFloat(this, 10).toPercentage(precision);
};

String.prototype.padRight = function(character, totalCount) {
  return this.pad(character, totalCount, 'right');
};

String.prototype.padLeft = function(character, totalCount) {
  return this.pad(character, totalCount, 'left');
};

String.prototype.pad = function(character, totalCount, dir) {
  var string = this;
  var toPad = totalCount - string.length;
  for (var i = 0; i < toPad; i = i + 1) {
    if (dir === 'right') {
      string = string + character;
    } else {
      string = character + string;
    }
  }

  return string;
};

Number.prototype.toRomanNumber = function() {
  // na razie wystarczy
  var romanNumbers = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV'
  };

  return romanNumbers[this];
};

String.prototype.toRomanNumber = function() {
  return parseInt(this, 10).toRomanNumber();
};

String.prototype.ucFirst = function() {
  var str = this + '',
    f = str.charAt(0)
      .toUpperCase();
  return f + str.substr(1);
};

String.prototype.base64UrlEncode = function() {
  return this.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '~~'); // eslint-disable-line no-div-regex
};

String.prototype.base64UrlDecode = function() {
  return this.replace(/\-/g, '+').replace(/\_/g, '/').replace(/\~/g, '='); // eslint-disable-line
};

Object.hasNonEmptyValues = function(obj) {
  var allFilled = true;
  angular.forEach(obj, function(value) {
    if (!value) {
      allFilled = false;
    }
  });

  return allFilled;
};

Number.prototype.toHumanFileSize = function(precision) {
  var size = this;
  if (size < 1024) {
    return size.round(precision) + ' B';
  }
  if (size < 1024 * 1024) {
    return (size / 1024).round(precision) + ' kB';
  }
  if (size < 1024 * 1024 * 1024) {
    return (size / 1024 / 1024).round(precision) + ' MB';
  }
  if (size < 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).round(precision) + ' GB';
  }

  return size.round(precision) + ' B';
};

/**
 * Zwracamy string w postaci "5 groszy", "1 grosz"
 * Jako parametr 3 elementowa tablica form (['groszy', 'grosz', 'grosze'])
 * @param  {[type]} stringArr [description]
 * @param  {bool} withNumber
 * @return {[type]}           [description]
 */
Number.prototype.toCountableString = function(stringArr, withNumber) {
  var formNoCount = 0,
    tmp = null,
    count = Math.abs(this);

  withNumber = withNumber === false ? false : true;

  // jeden
  if (count === 1) {
    formNoCount = 1;
  } else if (count % 10 === 0) {
    // podzielne przez 10
    formNoCount = 0;
  } else {
    // specjalne (11-19)
    tmp = count % 100;
    if (tmp >= 11 && tmp <= 19) {
      formNoCount = 0;
    } else {
      tmp = count % 10;
      if (tmp === 1) {
        formNoCount = 0;
      } else if (tmp <= 4) {
        formNoCount = 2;
      } else {
        formNoCount = 0;
      }
    }

  }

  if(withNumber) {
    return this + ' ' + stringArr[formNoCount];
  } else {
    return stringArr[formNoCount];
  }
};

String.prototype.trim = function() {
  return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
};
String.prototype.ltrim = function() {
  return this.replace(/^\s+/, '');
};
String.prototype.rtrim = function() {
  return this.replace(/\s+$/, '');
};
String.prototype.fulltrim = function() {
  return this.replace(/(?:(?:^|\n)\s+|\s+(?:$|\n))/g, '').replace(/\s+/g, ' ');
};

String.prototype.isValidPESEL = function() {
  var pesel = /^[0-9]{11}$/;
  var pes = this;
  var test = 1 * pes.charAt(0) +
    3 * pes.charAt(1) +
    7 * pes.charAt(2) +
    9 * pes.charAt(3) +
    1 * pes.charAt(4) +
    3 * pes.charAt(5) +
    7 * pes.charAt(6) +
    9 * pes.charAt(7) +
    1 * pes.charAt(8) +
    3 * pes.charAt(9) +
    1 * pes.charAt(10);

  if ((test % 10) !== 0 || (pesel.test(this) === false)) {
    return false;
  } else {
    return true;
  }
};

String.prototype.isValidEmail = function() {
  var email = this;
  var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return exp.test(email);
};

String.prototype.isValidNIP = function() {
  var nip = this;
  nip = nip.replace(/\-/g, ''); // eslint-disable-line
  if (nip.length !== 10) {
    return false;
  }
  // Check digits
  for (var i = 0; i < 10; i++) {
    if (isNaN(nip[i])) {
      return false;
    }
  }
  // Check checkdigit
  var sum = 6 * nip[0] +
    5 * nip[1] +
    7 * nip[2] +
    2 * nip[3] +
    3 * nip[4] +
    4 * nip[5] +
    5 * nip[6] +
    6 * nip[7] +
    7 * nip[8];
  sum %= 11;
  return parseInt(nip[9], 10) === sum;
};

String.prototype.isValidREGON = function() {
  var valid = true;
  var regon = this + '';
  if (regon && regon !== '') {
    regon = regon.replace(/[\s-]/g, '');

    if (parseInt(regon, 10) <= 0) {
      valid = false;
    } else if (regon.length === 9 || regon.length === 7 || regon.length === 14) {
      var weights;
      var max;
      if (regon.length === 9) {
        weights = [8, 9, 2, 3, 4, 5, 6, 7];
        max = 8;
      } else if (regon.length === 7) {
        weights = [2, 3, 4, 5, 6, 7];
        max = 6;
      } else {
        weights = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
        max = 13;
      }
      var sum = 0;
      for (var i = 0; i < max; i++) {
        sum += regon[i] * weights[i];
      }
      var mod = (sum % 11);
      valid = (mod === 10 ? 0 : mod) === parseInt(regon[max], 10);
    } else {
      valid = false;
    }
  }

  return valid;
};

String.prototype.isValidPostCode = function() {
  return this.match(/(\d{2})-(\d{3})/);
};

String.prototype.peselToBirthdate = function() {
  var pesel = this;
  var res = '';
  var n = 0;
  pesel = pesel.substr(0, 6);
  if (parseInt(pesel[2], 10) > 1) {
    res = '20';
    n = 2;
  } else {
    res = '19';
    n = 0;
  }
  res += pesel.charAt(0) + pesel.charAt(1) + '-';
  res += parseInt(pesel.charAt(2), 10) - n + pesel.charAt(3) + '-' + pesel.charAt(4) + pesel.charAt(5);
  if ((new XDate(res)).toString('yyyy-MM-dd') !== res) {
    return '';
  } else {
    return res;
  }
};

// dodanie funkcji formatującej string np "obiekt {0} nie istnieje.".format('Person')
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  };
}

String.prototype.peselToSex = function() {
  var pesel = this;
  if ((pesel.charAt(9) % 2) === 0) {
    return 'F';
  } else {
    return 'M';
  }
};


var __entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '\'': '&#39;',
  '/': '&#x2F;'
};

String.prototype.escapeHtml = function() {
  return String(this).replace(/[&<>"'\/]/g, function(s) { // eslint-disable-line
    return __entityMap[s];
  });
};

String.prototype.isValidDate = function() {
  try {
    if (!this.match(/(\d{4})-(\d{2})-(\d{2})/)) {
      return false;
    }
    var d = this.split(/\D+/);
    d[0] = (d[0]) * 1;
    d[1] = (d[1]) * 1 - 1;
    d[2] = (d[2]) * 1;

    var D = new Date(d[0], d[1], d[2]);
    if (D.getFullYear() === d[0] && D.getMonth() === d[1] && D.getDate() === d[2]) {
      return true;
    }
    return false;
  } catch (er) {
    return false;
  }
};


String.prototype.truncate = function(length, ending) {
  var text = this;
  ending = typeof ending === 'undefined' ? '' : ending;
  if (this.length > length) {
    text = $.trim(this.substr(0, length - ending.length)) + ending;
  }
  return text.toString();
};

String.prototype.hashCode = function() {
  var hash = 0,
    i,
    chr,
    len;
  if (this.length === 0) {
    return hash;
  }
  for (i = 0, len = this.length; i < len; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr; // NOSONAR
    // Convert to 32bit integer
    hash |= 0; // NOSONAR
  }
  return hash;
};

/**
 * Zwracanie liczby własności obiektu
 * @param  {object} countObj zliczany obiekt
 * @return {int}          liczba własności
 */
Object.countKeys = function(countObj) {
  var count = 0;
  angular.forEach(countObj, function() {
    count = count + 1;
  });
  return count;
};

/**
 * Zwracamy klucz pierwszej wartości w obiekcie
 * @param  {object} obj [description]
 * @return {string}     [description]
 */
Object.getFirstKey = function(obj) {
  var firstKey = null;
  angular.forEach(obj, function(value, key) {
    if (firstKey === null) {
      firstKey = key;
    }
  });
  return firstKey;
};

/**
 * Zwracamy pierwszą wartośc w obiekcie
 * @param  {object} obj [description]
 * @return {mixed}     [description]
 */
Object.getFirstValue = function(obj) {
  var firstValue = null;
  angular.forEach(obj, function(value) {
    if (firstValue === null) {
      firstValue = value;
    }
  });
  return firstValue;
};


String.prototype.isValidAccountNumber = function() {
  var nrb = this.replace(/[^0-9]+/g, '');
  var numWeight = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57];
  if (nrb.length === 26) {
    nrb = nrb + '2521'; // PL
    nrb = nrb.substr(2) + nrb.substr(0, 2);
    var Z = 0;
    for (var i = 0; i < 30; i++) {
      Z += nrb[29 - i] * numWeight[i];
    }
    return (Z % 97 === 1);
  } else {
    return false;
  }
};

Storage.prototype.setObject = function(key, obj) {
  return this.setItem(key, JSON.stringify(obj));
};

Storage.prototype.getObject = function(key) {
  if (!this.getItem(key)) {
    return null;
  }
  return JSON.parse(this.getItem(key));
};
