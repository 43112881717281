angular.module('ihestiaCommonDirectives')
  .directive('ihestiaServiceValidations', ['$parse', 'ihestiaServiceValidationHelper',
    function($parse, ihestiaServiceValidationHelper){
      return {
        restrict: 'E',
        templateUrl: 'ihestia-commondirectives-template-app/directives/serviceValidations/serviceValidations.tpl.html',
        scope: {
          'messages': '=' // messages list from service
        },
        link: function($scope){
          $scope.$watch('messages', function(){
            $scope.messagesByType = ihestiaServiceValidationHelper.splitByType($scope.messages);
          });

        }
      };
    }]);