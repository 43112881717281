angular.module('ihestiaCommonDirectives')
  .controller('commonSpinnerCtrl', ['$scope', '$filter',
    function($scope, $filter) {
      $scope.defaultSpinnerOptions = {
        label: $filter('translate')('Public.loading', {componentCode: 'Public'}),
        size: 'md'
      };

      $scope.staticSpinnerOptions = {
        visible: false
      };
    }
  ]);