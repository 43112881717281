angular.module('lsnBase.models')
  .factory('LsnBonusMalusContinuationModelV1', [
    function()
    {

      var LsnBonusMalusContinuationModelV1 = function()
      {
        this.policyStartDate = null; // "2013-03-13"
        this.policyEndDate = null; // "2014-03-12"
        this.policyNumber = null; // "907500001515"
        this.policySymbol = null; // "E7T",
        this.last1YearDamagesCount = null; // "1",
        this.last1YearOuzCoveredDamagesCount = null; // "1",
        this.noClaimContinuationYears = null; // "0",
        this.bmClass = null; // "-2"
        this.bmClassVersion = null; // int, 0/1
      };

      return LsnBonusMalusContinuationModelV1;
    }
  ]);