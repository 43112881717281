angular.module('ihestiaCommonDirectives')
  .controller('confirmModalCtrl', ['$scope', 'ihestiaCommonConfirmModalHelper',
    function($scope, ihestiaCommonConfirmModalHelper) {

      $scope.helper = ihestiaCommonConfirmModalHelper;

      /**
       * Init
       */
      $scope.init = function() {
        $scope.fireListeners();
      };

      /**
       * Odpalamy nasluchiwanie
       * @return {[type]} [description]
       */
      $scope.fireListeners = function() {
        $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaCommonConfirmModal', function($event) {
          $event.stopOkCallback = true;
          var callback = $scope.helper.confirmSettings.okBtnCallback;
          ihestiaCommonConfirmModalHelper.clearData();
          callback($event);
        });

        $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaCommonConfirmModal', function($event) {
          $event.stopCancelCallback = true;
          var callback = $scope.helper.confirmSettings.cancelBtnCallback;
          ihestiaCommonConfirmModalHelper.clearData();
          callback($event);
        });
      };


      $scope.init();
    }
  ]);