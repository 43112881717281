angular.module('salesPath2')
  .service('styleMap', ['STYLES',
    function(STYLES) {
      var StyleMap = function() {
        var self = this;
        //nazwy atrybutow z RESOURCES dla ktorych powinno odbyc sie mapowanie styli
        //w razie gdyby poznej okazalo sie ze nie chcemy czego mapowac, albo mapowac po jakims glebszym zagniezdzenu
        this.attrsNameMap = {
          RESOURCES: [
            ['PRODUCTLIST', '[]'], 
            ['EXTRA_DESCRIPTION_LIST', '[]'], 
            ['OWU', '[]'], 
            ['PRODADD', '[]'], 
            ['EXTENSIONDEF', '[]'], 
            ['LOCALIZATION_TYPES', 'LIST', '[]']
          ],
          PAGE_CONFIG: [
            ['FIRE', 'header'],
            ['BURGLARY', 'header'],
            ['OCZP', 'header'],
            ['COMMUNICATION', 'header'],
            ['NNW', 'header'],
            ['MATRIX', 'header']
          ]
        };

        this.parsedStyles = null;

        this.init = function() {
          self.setParsedStyles();
        };

        /**
         * [getParsedStyles parsuje i ustawia style]
         */
        this.setParsedStyles = function() {
          self.parsedStyles = angular.copy(STYLES);

          angular.forEach(self.parsedStyles, function(styleGroupDef, styleGroupCode) {
            angular.forEach(styleGroupDef, function(styleDef, styleCode) {
              //jesli nie jest tablica znaczy ze mamy styl w prost
              if (angular.isArray(styleDef)) {
                self.parsedStyles[styleGroupCode][styleCode] = self.getParsedStyle(styleCode, styleGroupDef);
              }
            });
          });
        };

        /**
         * [getParsedStyle przygotowuje styl dziedziczac po okreslonych braciach]
         * @param  {[type]} styleCode     [kod sprawdzanego stylu]
         * @param  {[type]} styleGroupDef [style z grupy rodzica]
         * @return {[type]}               [styl]
         */
        this.getParsedStyle = function(styleCode, styleGroupDef) {
          var parsedStyleArray = [];
          angular.forEach(styleGroupDef[styleCode], function(styleElement) {
            if (angular.isString(styleElement)) {
              if (styleElement.indexOf(':') === 0) {
                var styleName = styleElement.substr(1);
                parsedStyleArray.push(styleGroupDef[styleName]);
              } else {
                parsedStyleArray.push(styleElement);
              }
            }
          });

          return parsedStyleArray.join(' ');
        };

        /**
         * [map funkcja mapuje style do obiektu RESOURCES]
         * @param  {[type]} resource [RESOURCES]
         * @return {[type]}          [description]
         */
        this.map = function(RESOURCES, resourceName) {
          var attrsNameMap = self.attrsNameMap.RESOURCES; // default
          if(angular.isString(resourceName)){
            attrsNameMap = self.attrsNameMap[resourceName];
          }
          angular.forEach(attrsNameMap, function(attrNames) {
            var elementInResources = RESOURCES[attrNames[0]];
            if(angular.isDefined(elementInResources)){
              self.mapRecursive(elementInResources, attrNames, 1);
            }
          });
        };

        /**
         * [getElementsToMap szuka elementu]
         * @param  {[mix]} attrName [nazwa/tablica elementu do zmapowania]
         * @return {[type]}          [description]
         */
        this.mapRecursive = function(elementInResources, attrNames, index) {
          if (index === attrNames.length && angular.isObject(elementInResources) && angular.isString(elementInResources.STYLE)) {
              if (angular.isString(elementInResources.STYLE) && angular.isDefined(self.parsedStyles[elementInResources.STYLE])) {
                self.mapForElement(elementInResources, self.parsedStyles[elementInResources.STYLE]);
              }
          } else if (attrNames[index] === '[]' && angular.isArray(elementInResources)) {
            angular.forEach(elementInResources, function(nestedHtmlElement, key) {
              self.mapRecursive(elementInResources[key], attrNames, index + 1);
            });
          } else if (angular.isObject(elementInResources[attrNames[index]])) {
            self.mapRecursive(elementInResources[attrNames[index]], attrNames, index + 1);
          }
        };

        /**
         * [map funkcja mapuje style dla konkrenego elementu np produktu, owu]
         * @param  {[type]} element [description]
         * @param  {[type]} style [style dla elementu]
         * @return {[type]}          [description]
         */
        this.mapForElement = function(element, style) {
          angular.forEach(style, function(attrVal, attrName) {
            //jesli zostal wczesniej zdenfiniowany to znaczy ze nie ma byc nadpisany
            if (angular.isUndefined(element[attrName])) {
              element[attrName] = attrVal;
            }
          });
        };

        self.init();
      };

      return new StyleMap();
    }
  ]);