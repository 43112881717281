angular.module('lsnBase.models')
.factory('LsnClausesModelConstants', [function() {
    var ClausesModelConstants = {};

    ClausesModelConstants.CLAUSE_VALUE_AGREEMENT = 'AGREEMENT';
    ClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT = 'NO_AGREEMENT';
    ClausesModelConstants.CLAUSE_VALUE_REFUSAL = 'REFUSAL';

    // słownik wartości zgłód
    ClausesModelConstants.DICTIONARY_CLAUSE_VALUE = {};
    ClausesModelConstants.DICTIONARY_CLAUSE_VALUE[ClausesModelConstants.CLAUSE_VALUE_AGREEMENT] = 'zgoda';
    ClausesModelConstants.DICTIONARY_CLAUSE_VALUE[ClausesModelConstants.CLAUSE_VALUE_NO_AGREEMENT] = 'brak informacji';
    ClausesModelConstants.DICTIONARY_CLAUSE_VALUE[ClausesModelConstants.CLAUSE_VALUE_REFUSAL] = 'odmowa';

    return ClausesModelConstants;
  }]);