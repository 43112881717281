angular.module('ihestiaWidgets.policyOperationsPublic')
  .factory('vehiclePurchasePublicHelper', [
    function() {


      var VehiclePurchasePublicHelper = function() {

        this.verificationData = null;
        this.summary = null;

      };

      return new VehiclePurchasePublicHelper();
    }]);