angular.module('salesPath2')
  /**
   * resourcy dla aplikacji
   * @return {Object}
   */
  .factory('STYLES', [
    function() {

      var STYLES = {
        OWU_OC: {
          ICON: 'icon-solo icon-ock'
        },
        OWU_ZK: {
          ICON: 'icon-solo icon-zk'
        },
        OWU_FIRE: {
          ICON: 'icon-solo icon-mury'
        },
        OWU_HOME: {
          ICON: 'icon-sl-outline icon-house-2'
        },
        OWU_CAR: {
          ICON: 'icon-solo icon-ockom'
        },
        OWU_E1: {
          ICON: 'icon-sl-outline icon-file-search'
        },
        OWU_SPORT: {
          ICON: 'icon-solo icon-sl-fill icon-pakiet_sport'
        },
        OWU_TRAVEL: {
          ICON: 'icon-sl-fill icon-sun'
        },
        OWU_SEARCH: {
          ICON: 'icon-sl-outline icon-file-search'
        },
        //produkty
        PROD_FIRE: {
          'ICON_DEFAULT': 'icon-solo icon-mury',
          'BG_DEFAULT': 'bg-ogien',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_BURGLARY: {
          'ICON_DEFAULT': 'icon-solo icon-kradziez',
          'BG_DEFAULT': 'bg-kradziez',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_OCZP: {
          'ICON_DEFAULT': 'icon-solo icon-oczaw',
          'BG_DEFAULT': 'bg-ocprivate',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_NNW: {
          'ICON_DEFAULT': 'icon-solo icon-nnw',
          'BG_DEFAULT': 'bg-stairs',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_AC: {
          'ICON_DEFAULT': 'icon-solo icon-ac',
          'BG_DEFAULT': 'bg-ac',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_NNWKIP: {
          'ICON_DEFAULT': 'icon-solo icon-nnwk',
          'BG_DEFAULT': 'bg-nnwkip',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_OC: {
          'ICON_DEFAULT': 'icon-solo icon-ock',
          'BG_DEFAULT': 'bg-oc',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_REHABILITATION: {
          'ICON_DEFAULT': 'icon-solo icon-rehabilitacja',
          'BG_DEFAULT': 'bg-rehab',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_BENEFITS: {
          'ICON_DEFAULT': 'icon-solo icon-swiadczenia',
          'BG_DEFAULT': 'bg-provision',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_SPORT: {
          'ICON_DEFAULT': 'icon-solo icon-sportassistance',
          'BG_DEFAULT': 'bg-sport',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_MEDICAL_EXPENSES: {
          'ICON_DEFAULT': 'icon-solo icon-pmikl',
          'BG_DEFAULT': 'bg-medic',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_TRAVEL_ASSISTANCE: {
          'ICON_DEFAULT': 'icon-solo icon-podrozassistance',
          'BG_DEFAULT': 'bg-assistance',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_BAGGAGE: {
          'ICON_DEFAULT': 'icon-solo icon-ubezpieczeniebagazu',
          'BG_DEFAULT': 'bg-luggage',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT'],
          'ICON_NAVIGATION': [':ICON_DEFAULT']
        },
        PROD_GREENCARD: {
          'ICON_DEFAULT': 'icon-solo icon-zk',
          'BG_DEFAULT': 'bg-oc',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT', ':BG_DEFAULT']
        },
        //dodatki
        ADD_TRAVEL: {
          'ICON_DEFAULT': 'icon-solo icon-podroz',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_TRAVEL_ACCIDENT: {
          'ICON_DEFAULT': 'icon-solo icon-uwwp',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_OUZ: {
          'ICON_DEFAULT': 'icon-solo icon-ouz',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_OCHPRAW: {
          'ICON_DEFAULT': 'icon-solo icon-ochrona_prawna',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_OCNAJ: {
          'ICON_DEFAULT': 'icon-solo icon-oc_najemcy',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_CARGO: {
          'ICON_DEFAULT': 'icon-solo icon-oc_cargo',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_ASSPOJ: {
          'ICON_DEFAULT': 'icon-solo icon-assistance_pojazd',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_ASSDOM: {
          'ICON_DEFAULT': 'icon-solo icon-assistance_dom',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_ASSMEDIC: {
          'ICON_DEFAULT': 'icon-solo icon-assistance_medic',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_BAGGAGE: {
          'ICON_DEFAULT': 'icon-solo icon-assistance_baggage',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_GLAZING: {
          'ICON_DEFAULT': 'icon-solo icon-glass',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_EXTRA: {
          'ICON_DEFAULT': 'icon-solo icon-extra',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_SSU: {
          'ICON_DEFAULT': 'icon-solo icon-protect_amount',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_GREENCARD: {
          'ICON_DEFAULT': 'icon-solo icon-zk',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_SPORT_EQUIPMENT: {
          'ICON_DEFAULT': 'icon-solo icon-uss',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_TICKETS: {
          'ICON_DEFAULT': 'icon-solo icon-ukkik',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        ADD_BAGGAGE_2: {
          'ICON_DEFAULT': 'icon-solo icon-ubezpieczeniebagazu',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        //rozszerzenia
        EXT_RISKY_SPORTS: {
          'ICON_DEFAULT': 'icon-solo icon-dyscyplinyryzykowne',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_EXTREME_SPORTS: {
          'ICON_DEFAULT': 'icon-solo icon-dyscyplinyekstremalne',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_PROFESSIONAL_SPORT: {
          'ICON_DEFAULT': 'icon-solo icon-sportyzawodowe',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_PHYSICAL_WORK: {
          'ICON_DEFAULT': 'icon-solo icon-pracafizyczna',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_TERRORISM: {
          'ICON_DEFAULT': 'icon-solo icon-terror',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_CHRONIC_DISEASE: {
          'ICON_DEFAULT': 'icon-solo icon-chorobaprzewlekla',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_SPORT: {
          'ICON_DEFAULT': 'icon-solo icon-sport',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_COMPETITIVE_SPORT: {
          'ICON_DEFAULT': 'icon-solo icon-sportwyczynowy',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        EXT_NO_EXTENSION: {
          'ICON_DEFAULT': 'icon-sl-fill icon-delete-1-1',
          'BG_DEFAULT': '',
          'BG_SUMMARY_CLASS': [':BG_DEFAULT'],
          'ICON': [':ICON_DEFAULT']
        },
        //typy nieruchomosci
        LOCALIZATION_TYPE_LOCAL: {
          'ICON_DEFAULT': 'icon-sl-outline icon-buildings-3',
          'ICON': [':ICON_DEFAULT']
        },
        LOCALIZATION_TYPE_HOUSE: {
          'ICON_DEFAULT': 'icon-sl-outline icon-house-2',
          'ICON': [':ICON_DEFAULT']
        },
        LOCALIZATION_TYPE_SUMMER_HOUSE: {
          'ICON_DEFAULT': 'icon-solo icon-domek-letniskowy',
          'ICON': [':ICON_DEFAULT']
        },
        LOCALIZATION_TYPE_HOUSE_IN_CONSTRUCTION: {
          'ICON_DEFAULT': 'icon-solo icon-dom-w-budowie',
          'ICON': [':ICON_DEFAULT']
        },
        PAGE_HEADER_MATRIX_TRAVEL: {
          'ICON_DEFAULT': 'icon-sun',
          'ICON': [':ICON_DEFAULT', 'bg-gray-light icon-sl-fill']
        },
        PAGE_HEADER_MATRIX_SPORT: {
          'ICON_DEFAULT': 'icon-pakiet_sport',
          'ICON': [':ICON_DEFAULT', 'bg-gray-light icon-sl-fill icon-solo']
        },
        PAGE_HEADER_FIRE: {
          'ICON_DEFAULT': 'icon-mury',
          'ICON': [':ICON_DEFAULT', 'bg-ogien icon-sl-fill icon-solo']
        },
        PAGE_HEADER_BURGLARY: {
          'ICON_DEFAULT': 'icon-kradziez',
          'ICON': [':ICON_DEFAULT', 'bg-kradziez icon-sl-fill icon-solo']
        },
        PAGE_HEADER_COMMUNICATION: {
          'ICON_DEFAULT': 'icon-ockom',
          'ICON': [':ICON_DEFAULT', 'bg-oc icon-solo icon-ockom']
        },
        PAGE_HEADER_OCZP: {
          'ICON_DEFAULT': 'bg-oczp icon-solo icon-oczaw',
          'ICON': [':ICON_DEFAULT']
        },
        PAGE_HEADER_NNW: {
          'ICON_DEFAULT': 'bg-stairs icon-solo icon-nnw',
          'ICON': [':ICON_DEFAULT']
        }
      };

      return STYLES;
    }
  ]);