angular.module('ihestiaIkomunikator')
  .service('iKomunikatorMessageThreadRepresentersSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var MessageThreadRepresentersSvc = function() {
        var self = this;
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/messageThreadRepresenters';
        this.businessArea = 'ikomunikator';
        
        this.findRepresenters = function(data, callback, errorCalback) {
          var searchFilters = angular.copy(data);
          angular.forEach(searchFilters, function(value, key) {
            if (value === null) {
              delete searchFilters[key];
            }
          });
          self.addExtraHeader();
          return self.post(null, searchFilters, 'findMessageThreadRepresenters', callback, errorCalback);
        };

        this.findRepresentersByCache = function(data, callback, errorCalback) {
          var searchFilters = angular.copy(data);
          angular.forEach(searchFilters, function(value, key) {
            if (value === null) {
              delete searchFilters[key];
            }
          });
          self.addExtraHeader();
          return self.get(null, searchFilters, 'findMessageThreadRepresenters', callback, errorCalback);
        };

        this.addExtraHeader = function() {
          self.setProvider();
        };
      };

      return new MessageThreadRepresentersSvc();
    }
  ]);