/**
 * translator komunikatów
 */
angular.module('salesPath2').service('messageTranslator', ['ihestiaLabelHelper', '$filter',
  function(ihestiaLabelHelper, $filter) {
    var Translator = function() {
      var self = this;

      /**
       * lista specyficznych komunikatów, dla których sprawdzanie unikatowości na podstawie kodu nie byłoby wystarczające (więc zapinamy się na treść)
       * @type {Object}
       */
      this.msgToLabelCode = {
        'Podanie adresu e-mail ułatwi w przyszłości kontakt.': 'O000001.1',
        'Podanie numeru telefonu ułatwi w przyszłości kontakt.': 'O000001.2'
      };

      /**
       * czy wybrano jezyk polski
       * @return {Boolean}
       */
      this.isPolishSelected = function() {
        return (ihestiaLabelHelper.selectedLanguage === 'pl-PL');
      };

      /**
       * tlumaczy komunikat
       * @param  {Object} msg komunikat
       * @return {Boolean} true gdy przetlumaczono, false gdy brak tlumaczenia
       */
      this.translateMessage = function(msg) {
        var key = 'tmp.message.' + msg.code;
        if (angular.isDefined(self.msgToLabelCode[msg.message])) {
          key = 'tmp.message.' + self.msgToLabelCode[msg.message];
        }
        var value = $filter('translate')(key, {
          componentCode: 'sp2Message'
        });
        if (key !== value) {
          msg.message = value;
          return true;
        }
        return false;
      };

    };

    return new Translator();
  }
]);