angular.module('salesPath2')
  .service('summaryHelper', ['mainDataContainer', 'SPD', 'addressHelper', 'CONFIG', 'CONSTANTS', 'RESOURCES', 'matrixHelper', 'sp2CommonHelper', '$injector', 'PolicyModelConstants', 'organizationHelper', 'extensionHelper', 'ContactModelConstants', 'contactHelper', 'applicationHelper', 'ApplicationModelConstants', 'appVariables', 'ihestiaSsoBaseInfoHelper', 'resourceHelper', 'MapperUtils', '$sce', '$filter', 'dataContainerHelper', 'basketHelper', 'mainErrorCollection',
    function(mainDataContainer, SPD, addressHelper, CONFIG, CONSTANTS, RESOURCES, matrixHelper, sp2CommonHelper, $injector, PolicyModelConstants, organizationHelper, extensionHelper, ContactModelConstants, contactHelper, applicationHelper, ApplicationModelConstants, appVariables, ihestiaSsoBaseInfoHelper, resourceHelper, MapperUtils, $sce, $filter, dataContainerHelper, basketHelper, mainErrorCollection) {
      var SummaryHelper = function() {
        var self = this;

        this.getOfferState = function() {
          return mainDataContainer.application.get('dynamicValues').offerState || 'VALID';
        };

        /**
         * getInsurerInfo - configuracja i wywołanie funkcji pobrania danych klienta do prezentacji
         * @return {string} insurerInfo
         */

        this.getInsurerInfo = function() {
          var userSettings = {
            elements: [{
              field: 'lastName'
            }, {
              field: 'firstName',
              fieldSeparator: ', '
            }, {
              field: 'pesel'
            }],
            maxLength: 80
          };
          var orgSettings = {
            elements: [{
              field: 'name'
            }, {
              field: 'nip',
              fieldSeparator: ', '
            }],
            maxLength: 80
          };

          var insurer = dataContainerHelper.getInsurer();
          var insurerIsPerson = dataContainerHelper.isPerson(insurer);
          var personHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_PERSON);
          var insurerInfo = insurerIsPerson ? [personHelper.getPersonInfo(insurer, userSettings)] : [organizationHelper.getOrganizationInfo(insurer, orgSettings)];

          var address = addressHelper.getAdrStal(insurer.addresses);
          var addressSettings = {
            elements: [
              {field: 'postalCode'},
              {
                field: 'city',
                fieldSeparator: ', ',
                forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
              },
              {field: 'streetPrefix'},
              {field: 'street'},
              {field: 'house', fieldSeparator: ''},
              {field: 'apartment', prefix: '/'}
            ],
            maxLength: 80
          };
          insurerInfo.push(addressHelper.getAddressInfo(address, addressSettings));
          //telefon
          var telSettings = {
            elements: [{
              field: 'countryPrefix'
            }, {
              field: 'value'
            }],
            globalPrefix: $filter('translate')('sp2.summary.personInfo.phone', {
              componentCode: 'sp2'
            }) + ': ',
            maxLength: 40
          };
          var tel = personHelper.getPersonContact(insurer, ContactModelConstants.CONTACT_TYPE_KOMO);
          if (tel !== null) {
            tel = contactHelper.getContactInfo(tel, true, telSettings);
          }
          insurerInfo.push(tel);
          //email
          var emailSettings = {
            elements: [{
              field: 'value',
              prefix: $filter('translate')('sp2.summary.personInfo.email', {
                componentCode: 'sp2'
              }) + ': '
            }],
            maxLength: 40
          };
          var email = personHelper.getPersonContact(insurer, ContactModelConstants.CONTACT_TYPE_MAIL);
          if (email !== null) {
            email = contactHelper.getContactInfo(email, true, emailSettings);
          }
          insurerInfo.push(email);
          return insurerInfo;
        };

        /**
         * getPolicies - rozbija polisy per rodzaj
         * @return {object} policies
         */
        this.getPolicies = function() {
          var policies = {
            property: [],
            communication: [],
            other: [],
            personal: [], //imienne dla typu personGroup
            group: [] //grupowe dla typu personGroup
          };

          lsnNg.forEach(mainDataContainer.policies, function(policyObject) {
            var policy = policyObject.getData();
            // poniższe jeszcze do przemyślenia, ponieważ po zmianie w mdoelu polsiy start i end na dateTime, mozna by stamtąd bezpośrednio sczytywać czas
            policy.start = (new XDate(policy.start)).toString('yyyy-MM-dd');
            policy.end = (new XDate(policy.end)).toString('yyyy-MM-dd');
            if (appVariables.isSupplement && angular.isString(policy.product.dynamicValues.operationDate)) {
              //przy dokupieniu datą początku jest data operacji
              policy.start = policy.product.dynamicValues.operationDate;
            }
            //daty i godziny ochrony
            policy.start = policy.start + ' ' + (policy.start === RESOURCES.TODAY ? mainDataContainer.protectionStartTime : '00:00');
            policy.end = policy.end + ' 23:59';

            policy.orderId = self.getOrderId(policy);
            if (!angular.isObject(mainDataContainer.simpleRisks) || angular.isUndefined(mainDataContainer.simpleRisks[policy.number])) //podgląd polisy bez prawa do zapisu może spowodować, że nie mamy tych polis
            {
              return true;
            }
            if (!self.preparePolicy(policy)) { //nie udało się zgromadzić niezbędnych danych - nie prezentujemy takiej polisy/paragonu
              return true;
            }
            if (sp2CommonHelper.getPolicyType(policy) === CONSTANTS.POLICY_TYPE_COMMUNICATION) {
              policies.communication.push(policy);
            } else if (angular.isDefined(policy.product.dynamicValues.formOfPolicy) && policy.product.dynamicValues.formOfPolicy === CONSTANTS.FORM_OF_POLICY_PERSON) {
              policies.personal.push(policy);
            } else if (angular.isDefined(policy.product.dynamicValues.formOfPolicy) && policy.product.dynamicValues.formOfPolicy === CONSTANTS.FORM_OF_POLICY_GROUP) {
              policies.group.push(policy);
            } else {
              if (angular.isDefined(CONFIG.BEHAVIOR.separatePersonalPolices) && CONFIG.BEHAVIOR.separatePersonalPolices && self.isPersonalPolicy(policy)) {
                policies.other.push(policy);
              } else {
                policies.property.push(policy);
              }
            }

            return true; //continue
          });

          return policies;
        };

        /**
         * zwraca identyfikator dla polisy, po którym będziemy sortować polisy na GUI
         * @param  {Object} policyObj obiekt polisy (nie koniecznie w modelu PolicyModel)
         * @return {String}
         */
        this.getOrderId = function(policyObj) {
          var id = '';
          if (angular.isObject(policyObj.product) && angular.isObject(policyObj.product.dynamicValues) && angular.isString(policyObj.product.dynamicValues._sourceApplicationOfferNumber)) {
            id = policyObj.product.dynamicValues._sourceApplicationOfferNumber;
          } else if (angular.isString(policyObj.number)) {
            id = policyObj.number;
          }
          return id;
        };

        /**
         * preparePolicy - budowanie obiektu polisy (danych do widoku)
         * @return Boolean false gdy nie udało się skompletować danych na polisie
         */
        this.preparePolicy = function(policy) {
          var ocSellerPremiumPart = null,
            sum = 0,
            prepareSuccess; // (bool) czy udało się skompletować dane do polisy. Uzupwniane w warunkach poniżej.
          //wyciągmay z simpleRisks, bo tam są ryzyka przefiltrowane
          angular.forEach(mainDataContainer.simpleRisks[policy.number], function(risk) {
            if (angular.isDefined(risk.attributes.sellerPremiumPart)) {
              sum += risk.salesPremium;
            } else {
              sum += risk.premium;
            }
          });

          policy.sum = sum;
          policy.objects = [];
          if (sp2CommonHelper.getPolicyType(policy) === CONSTANTS.POLICY_TYPE_COMMUNICATION) {
            prepareSuccess = self.prepareCommunicationPolicy(policy);
          } else if (CONFIG.BEHAVIOR.personGroups && angular.isDefined(policy.product.dynamicValues.formOfPolicy)) {
            prepareSuccess = self.prepareGroupPolicy(policy, policy.product.dynamicValues.formOfPolicy);
          } else {
            prepareSuccess = self.preparePropertyPolicy(policy);
          }
          if (!prepareSuccess) {
            return false; //nie kontynuujemy, jeśli przygotowanie danych nie powiodło się
          }
          //zbieramy sumy dla obiektów
          angular.forEach(policy.objects, function(object) {
            var objectSum = 0;
            angular.forEach(object.risks, function(risk) {
              if (angular.isDefined(risk.attributes.sellerPremiumPart)) {
                objectSum += risk.salesPremium;
                ocSellerPremiumPart = risk.attributes.sellerPremiumPart;
              } else {
                objectSum += risk.premium;
              }
            });
            object.sum = objectSum;
            object.risks = self.processRisksOnPolicyObject(object.risks);
          });

          policy.extras = self.getPolicyExtrasStatus(policy);
          policy.isCession = false;
          if (policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CESSION) {
            policy.isCession = true;
          }
          policy.isCessionAvailable = self.isCessionAvailable(policy);
          policy.printoutsAllowed = applicationHelper.policyPrintoutsAllowed(policy.number);
          //oc nabywcy
          if (ocSellerPremiumPart !== null) {
            self.addOcBuyerData(policy, ocSellerPremiumPart);
          }
          //rozszerzenia
          self.addExtensions(policy);
          //dane do wznowień
          self.addRenewalData(policy);
          //klauzule produktowe
          self.addProductClauses(policy);
          return true;
        };

        this.addProductClauses = function(policy) {
          var risksProductClauses = {
            clauseCode: []
          };
          
          angular.forEach(policy.risks, function(risk) {
            if (risk.product.dynamicValues.productClauses) {
              angular.forEach(risk.product.dynamicValues.productClauses.clauseCode, function(clauseCode) {
                risksProductClauses.clauseCode.push(clauseCode);
              });
            }
          });
  
          if (risksProductClauses.clauseCode.length > 0) {
            policy.productClauses = risksProductClauses;
          } else {
            policy.productClauses = false;
          }
        };

        /**
         * przygotowuje dane dla widoku na polisie komunikacyjnej
         * @param  {Object} policy dane polisy
         * @return {Boolean} czy udało się zebrać dane
         */
        this.prepareCommunicationPolicy = function(policy) {
          var vehicleHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_VEHICLE);
          angular.forEach(policy.vehicles, function(vehicle) {
            var vehicleInfo = vehicleHelper.getVehicleInfo(mainDataContainer.vehicles[vehicle.metaData.clientId], {
              maxLength: 28
            });
            policy.objects.push({
              'clientId': vehicle.metaData.clientId,
              'productType': CONSTANTS.PRODUCT_TYPE_VEHICLE,
              'name': vehicleInfo,
              'risks': self.getRiskForObject(policy.number, CONSTANTS.PRODUCT_TYPE_VEHICLE, vehicle.metaData.clientId)
            });
          });
          return true;
        };

        /**
         * przygotowuje polise dla obiektów typu personGroup
         * @param  {PolicyModel} policy polisa
         * @param  {String} formOfPolicy osobowa|grupowa
         * @return {[type]}        [description]
         */
        this.prepareGroupPolicy = function(policy, formOfPolicy) {
          if (formOfPolicy === CONSTANTS.FORM_OF_POLICY_PERSON) { //osobowa
            var personHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_PERSON),
              personInfoSetting = {
                elements: [{
                  field: 'firstName'
                }, {
                  field: 'lastName',
                  lastInLine: true
                }],
                maxLength: 25
              };
            angular.forEach(dataContainerHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(groupPerson, clientId) {
              if (!dataContainerHelper.isPerson(groupPerson)) { //zbieramy same ubezpieczone osoby
                return;
              }
              policy.objects.push({
                clientId: clientId,
                productType: CONSTANTS.PRODUCT_TYPE_PERSON_GROUP,
                name: personHelper.getPersonInfo(groupPerson, personInfoSetting),
                showName: true,
                risks: self.getRiskForObject(policy.number, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId)
              });
            });
          } else { //grupowa
            var personGroupHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP),
              policyGroupId = self.getGroupIdForPolicy(policy),
              groupInfoSetting = {
                elements: [{
                  field: 'name'
                }],
                maxLength: 25
              };
            angular.forEach(dataContainerHelper.getObjects(CONSTANTS.PRODUCT_TYPE_PERSON_GROUP), function(groupPerson, clientId) {
              if (dataContainerHelper.isPerson(groupPerson) || groupPerson.metaData.id !== policyGroupId) { //zbieramy same ubezpieczone osoby
                return;
              }
              policy.objects.push({
                clientId: clientId,
                productType: CONSTANTS.PRODUCT_TYPE_PERSON_GROUP,
                name: personGroupHelper.getGroupInfo(groupPerson, groupInfoSetting),
                personCount: personGroupHelper.getGroupPersonCount(groupPerson),
                showName: true,
                risks: self.getRiskForObject(policy.number, CONSTANTS.PRODUCT_TYPE_PERSON_GROUP, clientId)
              });
            });
          }
          return true;
        };

        this.getGroupIdForPolicy = function(policy) {
          var groupId = null;
          if (angular.isDefined(policy.risks[0]) && angular.isDefined(policy.risks[0].product.dynamicValues._groupId)) {
            groupId = policy.risks[0].product.dynamicValues._groupId;
          }
          return groupId;
        };

        /**
         * przygotowuje dane dla polis niekomunikacyjnych (mienie, ososbwe)
         * @param  {Object} policy dane polisy
         * @return {Boolean} false, gdy nie udało się skompletować danych
         */
        this.preparePropertyPolicy = function(policy) {
          var localizationsCount = 0;
          angular.forEach(policy.estates, function() {
            localizationsCount += 1;
          });

          var objectName;
          //jeśli jedna lokalizacja na polise to bierzemy jej adres, w przeciwnym wypadku głównego ubezpieczonego na polisie
          if (localizationsCount === 1) {
            var localizationHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_LOCALIZATION);
            var localizationSettings = {
              elements: [{
                field: 'postalCode',
                prefix: ' '
              }, {
                field: 'city',
                fieldSeparator: ', ',
                optionalLineBreak: true, //jeśli adres jest zbyt długi to dopuszczamy przełamanie linii po tym elemencie
                forceShowFieldSeparator: true //wymusza pokazanie separatora nawet gdy kolejne pole jest puste
              }, {
                field: 'streetPrefix'
              }, {
                field: 'street'
              }, {
                field: 'house',
                fieldSeparator: '',
                firstInRow: false
              }, {
                field: 'apartment',
                prefix: '/',
                lastInLine: true
              }],
              maxLength: 31,
              doTruncate: false
            };


            lsnNg.forEach(policy.estates, function(estate) {
              objectName = localizationHelper.getLocalizationInfo(mainDataContainer.localizations[estate.metaData.clientId], localizationSettings);
              return false;
            });
          } else {
            var personHelper = self.getProductTypeHelper(CONSTANTS.PRODUCT_TYPE_PERSON);
            var policyMainInsured;
            lsnNg.forEach(mainDataContainer.persons, function(person) {
              if (policy.insuredRef === person.metaData.get('id')) {
                policyMainInsured = person;
                return false;
              }
              return true; //continue
            });
            var personInfoSetting = {
              elements: [{
                field: 'lastName'
              }, {
                field: 'firstName',
                lastInLine: true
              }, {
                field: 'pesel'
              }],
              maxLength: 25
            };
            if (policyMainInsured) {
              objectName = personHelper.getPersonInfo(policyMainInsured, personInfoSetting);
            }
          }

          if (objectName) {
            policy.objects.push({
              name: objectName,
              risks: self.getSummedRisksForPolicy(policy.number)
            });
          }

          return objectName ? true : false;
        };

        this.sortPropertyPolicies = function(policies) {
          var sortedPolicies = [];
          self.cutPoliciesType(policies, sortedPolicies, CONSTANTS.POLICY_TYPE_CLIENT);
          self.cutPoliciesType(policies, sortedPolicies, CONSTANTS.POLICY_TYPE_STANDARD);
          self.cutPoliciesType(policies, sortedPolicies, CONSTANTS.POLICY_TYPE_CESSION);

          //reszta (nie powinno już nic być)
          angular.forEach(policies, function(policy) {
            sortedPolicies.push(policy);
          });

          return sortedPolicies;
        };

        this.cutPoliciesType = function(policies, sortedPolicies, type) {
          var policiesToDrop = [];
          angular.forEach(policies, function(policy, index) {
            if (policy.product.dynamicValues.typeOfPolicy === type) {
              sortedPolicies.push(policy);
              policiesToDrop.push(index);
            }
          });
          angular.forEach(policiesToDrop.reverse(), function(indexToDrop) {
            policies.splice(indexToDrop, 1);
          });
        };

        /**
         * sprawdzanie statusu dodatkowym elementów polisy potrzebnych do wyświetlenia jej na podsumowaniu
         * @param  {[type]} policy [description]
         * @return {[type]}        [description]
         */
        this.getPolicyExtrasStatus = function(policy) {
          var extras = {};

          angular.forEach(CONSTANTS.POLICY_EXTRAS, function(extraType) {
            extras[extraType] = self.getPolicyExtraData(policy, extraType);
          });

          return extras;
        };

        /**
         * sortujemy ryzyka, dodajemy ryzyka sztuczne (fake), dodajemy klasy potrzene do poprawnej prezentacji ryzyk, itp.
         * @param  {Array} risks tablica ryzyk
         * @return {Array} przetworzone ryzyka
         */
        this.processRisksOnPolicyObject = function(risks) {
          var newRisks = [];
          angular.forEach(RESOURCES.PRODUCTLIST, function(product) {
            angular.forEach(risks, function(risk) {
              if (product.CODE === risk.code) {
                var newRisk = angular.copy(risk);
                newRisk.bgClass = angular.isString(product.BG_SUMMARY_CLASS) ? product.BG_SUMMARY_CLASS : '';
                if (product.FAKE) {
                  newRisk.fake = true;
                  newRisk.name = self._getRiskName(newRisk, product);
                }
                newRisks.push(newRisk);
              }
            });
          });
          angular.forEach(RESOURCES.PRODADD, function(product) {
            angular.forEach(risks, function(risk) {
              if (product.CODE === risk.code) {
                var newAdd = angular.copy(risk);
                newAdd.bgClass = angular.isString(product.BG_SUMMARY_CLASS) ? product.BG_SUMMARY_CLASS : '';
                if (product.FAKE) {
                  newAdd.fake = true;
                  newAdd.name = self._getRiskName(newAdd, product);
                }
                newRisks.push(newAdd);
              }
            });
          });

          return newRisks;
        };

        /**
         * zwraca zsumowane ryzyka (sumowanie jak ten sam wariant)
         * @param  {string} policyNumber
         * @return {Risk[]}              [description]
         */
        this.getSummedRisksForPolicy = function(policyNumber) {
          var risks = [];
          var groupedRisks = {};
          angular.forEach(mainDataContainer.simpleRisks[policyNumber], function(risk) {
            if (risk.premium !== 0) //ignorujemy ryzyka bez składki
            {
              if (angular.isUndefined(groupedRisks[risk.code])) {
                groupedRisks[risk.code] = {};
              }
              if (angular.isUndefined(groupedRisks[risk.code][risk.variant])) {
                groupedRisks[risk.code][risk.variant] = self._parseRisk(risk);
              } else {
                groupedRisks[risk.code][risk.variant].salesPremium += risk.salesPremium;
                groupedRisks[risk.code][risk.variant].premium += risk.premium;
              }
            }
          });

          angular.forEach(groupedRisks, function(variants) {
            angular.forEach(variants, function(risk) {
              risks.push(risk);
            });
          });

          return risks;
        };

        /**
         * zwraca dodatkowe dane dla polisy
         * @param  {Object} policy PlicyModel.getData()
         * @param  {String} extraType CONSTANTS.POLICY_EXTRA_...
         * @return {Object} obiekt z wartosciami dla konkretncyh ekstrasow
         */
        this.getPolicyExtraData = function(policy, extraType) {
          var data = null;
          switch (extraType) {
            case CONSTANTS.POLICY_EXTRA_CESSION:
              if (policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT && !appVariables.isSupplement) //pokazujemy cesję na polisie klienta, ale zapisywana jest na cesyjnej
              {
                var cessionPolicy = self.findRelatedClientCessionPolicy(policy);
                if (cessionPolicy === null) {
                  sp2CommonHelper.throwException($filter('translate')('exception.noCessionPolicyFoundForClientPolicy', {
                    componentCode: 'sp2'
                  }));
                }
                policy = cessionPolicy;
              }

              if (angular.isDefined(policy.suretyRef) && policy.suretyRef !== null && policy.suretyRef.length !== 0) {
                lsnNg.forEach(mainDataContainer.organizations, function(organization) {
                  if (organization.metaData.get('id') === policy.suretyRef[0]) {
                    data = organization.getData();
                    return false;
                  }
                  return true; //continue
                });
              }
              break;
            case CONSTANTS.POLICY_EXTRA_PAYMENT:
              data = {
                frequency: 1,
                paymentMethod: CONSTANTS.POLICY_PAYMENT_METHOD_TRANSFER,
                paymentProrogation: 7,
                desc: PolicyModelConstants.DICTIONARY_POLICY_PAYMENT_FREQUENCY[1]
              };

              if (policy.frequency !== null) {
                var freqObj = resourceHelper.getPaymentFrequency(policy.frequency);
                data.frequency = policy.frequency;
                data.desc = freqObj ? freqObj.name : '';
              }
              if (policy.paymentMethod !== null) {
                data.paymentMethod = policy.paymentMethod;
              }
              if (policy.product.dynamicValues !== null && angular.isDefined(policy.product.dynamicValues.paymentProrogation)) {
                data.paymentProrogation = policy.product.dynamicValues.paymentProrogation;
              }
              break;
            case CONSTANTS.POLICY_EXTRA_CLAUSES:
              if (angular.isDefined(policy.product.dynamicValues.additionalClause) && policy.product.dynamicValues.additionalClause !== '') {
                data = policy.product.dynamicValues.additionalClause;
              }
              break;
            case CONSTANTS.POLICY_EXTRA_ATTACH:
              var policyDocuments = angular.isUndefined(mainDataContainer.policyDocuments[policy.metaData.id]) ? {} : mainDataContainer.policyDocuments[policy.metaData.id];
              lsnNg.forEach(policyDocuments, function(policyDocument) {

                var dynamicValue = dataContainerHelper.getPolicyByNumber(policy.number).product.get('dynamicValues');
                if (angular.isUndefined(dynamicValue.dealingWithPolicyDocument) || dynamicValue.dealingWithPolicyDocument === CONSTANTS.DEALING_WITH_POLICY_DOCUMENTS_PAPER) {
                  if (policyDocument.modelatt.get('attached')) {
                    data = true;
                    return;
                  }
                } else {
                  if (policyDocument.files.length > 0) {
                    data = true;
                    return;
                  }
                }
              });
              break;
            case CONSTANTS.POLICY_EXTRA_DISCOUNT:
              var policyDiscounts = angular.isUndefined(mainDataContainer.policiesDiscounts[policy.number]) ? {} : mainDataContainer.policiesDiscounts[policy.number];
              lsnNg.forEach(policyDiscounts, function(val, mainAttrName) {
                if (policyDiscounts[mainAttrName].selected) {
                  data = true;
                  return;
                }
              });
              break;

            default:
              break;
          }

          return data;
        };

        /**
         * getRiskForObject - ryzyka na polisie
         * @return {array} risks
         */

        this.getRiskForObject = function(policyNumber, objectType, objectId) {
          var risks = [];
          angular.forEach(mainDataContainer.simpleRisks[policyNumber], function(risk) {
            if (risk.objectId === objectId && risk.objectType === objectType && risk.premium !== 0) //ignorujemy ryzyka bez składki
            {
              risks.push(self._parseRisk(risk));
            }
          });
          return risks;
        };

        this._parseRisk = function(risk) {
          var riskCopy = angular.fromJson(angular.toJson(risk));
          riskCopy.isAdd = resourceHelper.isRiskAdd(riskCopy.code);
          riskCopy.lowerCaseCode = riskCopy.code.toLowerCase();

          riskCopy.name = self._getRiskName(riskCopy);
          return riskCopy;
        };

        /**
         * zwraca nazwę ryzyka/dodatku
         * @param  {[type]} risk    [description]
         * @param  {Object} [riskDef] opcjonalna definicja ryzyka/dodatku z RESOURCES
         * @return {String} nazwa
         */
        this._getRiskName = function(risk, riskDef) {
          var name;
          if (risk.isAdd) {
            var addDef = riskDef || resourceHelper.getAddDef(risk.code),
              expandTurbo = risk.attributes.expandTurbo;
            if (angular.isUndefined(addDef.VARIANTS)) {
              name = expandTurbo ? addDef.RAW_NAME : addDef.NAME;
            } else {
              var variantDef = resourceHelper.getAddVariantDef(risk.code, risk.variant);
              name = expandTurbo ? variantDef.RAW_NAME : variantDef.NAME;
            }
            if (expandTurbo) { //UWAGA! pod warunkiem, że nazwa 
              name += ' - Turbo';
              name = $sce.trustAsHtml(name);
            }
          } else {
            name = resourceHelper.getProductName(risk.code, 'SUMMARY_NAME', risk.variant, riskDef);
          }

          return name;
        };

        this.isCessionAvailable = function(policy) {
          var isCessionAvailable = false;
          if (sp2CommonHelper.getPolicyType(policy) === CONSTANTS.POLICY_TYPE_COMMUNICATION) {
            //musi mieć AC
            angular.forEach(policy.risks, function(risk) { //eslint-disable-line consistent-return
              var riskSpd = SPD.risks[risk.product.compId];
              if (riskSpd.productCode === CONSTANTS.RISK_AC) {
                isCessionAvailable = true;
                return false;
              }
            });
          } else {
            //liczymy lokalizacje
            var estatesCount = 0;
            if (policy.estates !== null) {
              angular.forEach(policy.estates, function() {
                estatesCount += 1;
              });
            }
            if (estatesCount > 1) //jak wiele lokalizacji to nie może być cesji
            {
              return false;
            }
            //musi mieć OGIEN mury i nie mieć cesyjnej
            if (policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT) //ma polisę cesyjną
            {
              return true;
            }
            if (policy.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CESSION) //jeśli nie ma dla niej klienckiej
            {
              if (self.findRelatedClientCessionPolicy(policy) === null) {
                return true;
              }
              return false;
            }
            angular.forEach(policy.risks, function(risk) { //eslint-disable-line consistent-return
              var riskSpd = SPD.risks[risk.product.compId];
              var blockedVariants = []; //lista wariantów murów od ognia dla których nie można mieć cesji
              if (angular.isDefined(CONFIG.BEHAVIOR.localization.connectedFireRisk)) {
                angular.forEach(CONFIG.BEHAVIOR.localization.connectedFireRisk, function(val, variantCode) {
                  blockedVariants.push(variantCode);
                });
              }

              if (riskSpd.productCode === CONSTANTS.PRODUCT_OGI && riskSpd.riskCode === CONSTANTS.RISK_ESTATE && blockedVariants.indexOf(risk.product.dynamicValues.coverageOption) === -1) {
                isCessionAvailable = true;
                return false;
              }
            });
          }
          return isCessionAvailable;
        };

        /**
         * szuka polisy klienta/cesyjnej powiązanej (jest ta sama lokalizacja) z przekazaną polisą cesyjną/klienta, jak nie znajdzie zwraca null
         * @param  {Policy} cessionPolicy
         * @return {Policy|null}
         */
        this.findRelatedClientCessionPolicy = function(policySearchFrom) {
          var searchType = null,
            searchNumber = null,
            policySearchFromNumber = policySearchFrom.number;

          if (angular.isDefined(policySearchFrom.product.dynamicValues._sourceApplicationOfferNumber)) {
            policySearchFromNumber = policySearchFrom.product.dynamicValues._sourceApplicationOfferNumber;
          }

          if (policySearchFrom.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CLIENT) {
            searchType = CONSTANTS.POLICY_TYPE_CESSION;
            searchNumber = policySearchFromNumber + '_C';
          } else if (policySearchFrom.product.dynamicValues.typeOfPolicy === CONSTANTS.POLICY_TYPE_CESSION) {
            searchType = CONSTANTS.POLICY_TYPE_CLIENT;
            searchNumber = policySearchFromNumber.substr(0, policySearchFromNumber.length - 2); //wycinamy '_C'
          } else //zły typ polisy
          {
            return null;
          }

          var foundPolicy = null;

          lsnNg.forEach(mainDataContainer.policies, function(policy) {
            if ((angular.isDefined(policy.product.dynamicValues._sourceApplicationOfferNumber) && policy.product.dynamicValues._sourceApplicationOfferNumber === searchNumber) || policy.number === searchNumber ||
              (CONFIG.BEHAVIOR.multipleLocalizations === false && policy.product.dynamicValues.typeOfPolicy === searchType)) {
              if (policy.product.dynamicValues.typeOfPolicy !== searchType) {
                sp2CommonHelper.throwException($filter('translate')('exception.wrongPolicyTypeForPolicyWithNumber', {
                  componentCode: 'sp2',
                  params: {
                    policyNumber: policy.number,
                    searchType: searchType
                  }
                }));
              }
              foundPolicy = policy;
              return false;
            }
            if (foundPolicy !== null) {
              return false;
            }

            return true; //continue  
          });
          return foundPolicy;
        };

        /**
         * zwraca sumy skladek dla poszczegolnych typow polis
         * @param  {Object[]} policies tablica polis (po getData())
         * @return {Object} sumy dla poszczegolnych typow polis
         */
        this.getSums = function(policies) {
          var sums = {};
          angular.forEach(policies, function(typePolicies, type) {
            sums[type] = 0;
            angular.forEach(typePolicies, function(policy) {
              //wyciągmay z simpleRisks, bo tam są ryzyka przefiltrowane
              angular.forEach(mainDataContainer.simpleRisks[policy.number], function(risk) {
                if (angular.isDefined(risk.attributes.sellerPremiumPart)) {
                  sums[type] += risk.salesPremium;
                } else {
                  sums[type] += risk.premium;
                }
              });
            });
          });
          return sums;
        };

        /**
         * zwraca helpera dla danego typu produktu
         * @param  {String} type typ produktu
         * @return {Object}      helper
         */
        this.getProductTypeHelper = function(type) {
          var helper = null;
          switch (type) {
            case CONSTANTS.PRODUCT_TYPE_VEHICLE:
              helper = $injector.get('vehicleHelper');
              break;
            case CONSTANTS.PRODUCT_TYPE_LOCALIZATION:
              helper = $injector.get('localizationHelper');
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON_GROUP:
              helper = $injector.get('personGroupHelper');
              break;
            case CONSTANTS.PRODUCT_TYPE_PERSON:
              helper = $injector.get('personHelper');
              break;
            default:
              sp2CommonHelper.throwException($filter('translate')('exception.wasNotFoundHelperForProductType', {
                componentCode: 'sp2',
                params: {
                  type: type
                }
              }));
              break;
          }
          return helper;
        };

        /**
         * zwraca nazwy typów wydruków
         * @param  {String} type typ produktu
         * @return {Object}      helper
         */
        this.getPrintoutsNames = function() {
          return {
            'KALKULACJA': $filter('translate')('printoutName.KALKULACJA', {
              componentCode: 'Public'
            }),
            'OFERTA': $filter('translate')('printoutName.OFERTA', {
              componentCode: 'Public'
            }),
            'ODSTAPIENIE': $filter('translate')('printoutName.ODSTAPIENIE', {
              componentCode: 'Public'
            }),
            'PODSUMOWANIE': $filter('translate')('printoutName.PODSUMOWANIE', {
              componentCode: 'Public'
            }),
            'POLISA': $filter('translate')('printoutName.POLISA', {
              componentCode: 'Public'
            }),
            'POLISA-KOPIA': $filter('translate')('printoutName.POLISA-KOPIA', {
              componentCode: 'Public'
            }),
            'POLISA-WERYFIKACJA': $filter('translate')('printoutName.POLISA-WERYFIKACJA', {
              componentCode: 'Public'
            }),
            'PRZELEW': $filter('translate')('printoutName.PRZELEW', {
              componentCode: 'Public'
            }),
            'ZALACZNIK': $filter('translate')('printoutName.ZALACZNIK', {
              componentCode: 'Public'
            }),
            'ZK': $filter('translate')('printoutName.ZK', {
              componentCode: 'Public'
            }),
            'CERTYFIKAT': $filter('translate')('printoutName.CERTYFIKAT', {
              componentCode: 'Public'
            })
          };
        };

        /**
         * czy polisa ma tylko osobowe ryzyka
         * @param  {Policy}  policy
         * @return {Boolean}
         */
        this.isPersonalPolicy = function(policy) {
          var isPersonalPolicy = true;
          lsnNg.forEach(policy.objects, function(object) {
            lsnNg.forEach(object.risks, function(risk) {
              if (angular.isDefined(resourceHelper.productType[risk.code]) && resourceHelper.productType[risk.code] === CONSTANTS.PRODUCT_TYPE_LOCALIZATION) //ogień lub kradzież
              {
                isPersonalPolicy = false;
                return false;
              }
              return true; //continue
            });
            if (!isPersonalPolicy) {
              return false;
            }

            return true; //continue
          });
          return isPersonalPolicy;
        };

        /**
         * dodaje rozszerzenia do sczytanych polis
         * @param {Object} policy
         */
        this.addExtensions = function(policy) {
          var exts = {};
          angular.forEach(policy.objects, function(obj) {
            if (angular.isUndefined(obj.clientId) || angular.isUndefined(obj.productType)) {
              return;
            }
            var objExts = extensionHelper.getSelectedExtensionsForObject(obj.productType, obj.clientId);
            if (objExts.length > 0) { //sa wybrane rozszerzenia dla obiektu
              exts[obj.clientId] = objExts;
            }
          });
          policy.extensions = exts;
        };

        /**
         * dodaje do polisy dane dotyczące wznowień
         * @param {Object} policy zrzut z obiektu polisy z dodatkowymi polami do prezentacji w tpl
         * @return {Boolean} true gdy wznowienie
         */
        this.addRenewalData = function(policy) {
          //brak wymagalności flagi renewal na pakiecie - IHESTIAWZ-823
          var prevPolNumbers = [];
          angular.forEach(policy.risks, function(risk) {
            if (angular.isObject(risk.product.dynamicValues) && angular.isString(risk.product.dynamicValues.previousPolicyNumber) && risk.product.dynamicValues.previousPolicyNumber !== '' && prevPolNumbers.indexOf(risk.product.dynamicValues.previousPolicyNumber) === -1) {
              prevPolNumbers.push(risk.product.dynamicValues.previousPolicyNumber);
            }
          });
          if (prevPolNumbers.length === 0 && angular.isString(policy.product.dynamicValues.previousPolicyNumber) && policy.product.dynamicValues.previousPolicyNumber !== '') {
            prevPolNumbers.push(policy.product.dynamicValues.previousPolicyNumber);
          }
          if (prevPolNumbers.length > 0) {
            policy.renewPrevPolNumbers = prevPolNumbers;
          }
          return true;
        };

        /**
         * dodaje informacje o OC nabywcy do danych polisy
         * @param {Object} policy
         * @param {Number} sellerPremiumPart skladka przeksiegowana z polisy sprzedajacego
         * @return {Boolean} true gdy dodano dane dotyczące OC nabywcy
         */
        this.addOcBuyerData = function(policy, sellerPremiumPart) {
          policy.ocBuyerData = {
            sellerPremiumPart: sellerPremiumPart,
            label: CONFIG.BEHAVIOR.ocBuyerSellerPremiumPartLabel
          };
        };

        /**
         * zwraca obiekt z uprawnieniami do akcji na polisie/ofercie
         * @param {boolean} isFraudulentClient - true when client on calculation or offer is fraudulent,
         * @param {string[]} policyPaymentMethods - payment method list for policies
         * @return {Object} jak poniżej
         */
        this.getActionPermissions = function(isFraudulentClient, policyPaymentMethods) {
          var cashOrCardSelected = _.some(policyPaymentMethods, _.matches('CASH')) || _.some(policyPaymentMethods, _.matches('CARD'));
          var onlyBlikSelected = _.every(policyPaymentMethods, _.matches('BLIK'));
          // Pay and Confirm - only on calculation and when client is fraudulent and chosen BLIK as payment method
          var payAndConfirm = isFraudulentClient && onlyBlikSelected && appVariables.appMode === CONSTANTS.APP_MODE_CALCULATION;
          // Pay with BLIK - only on offer with sub status: DemandPayment (client fraudulent), and chosen BLIK as payment method
          var payWithBLIK = isFraudulentClient && onlyBlikSelected && appVariables.appMode === CONSTANTS.APP_MODE_OFFER;
          // Fraudulent client and at least one payment method is transfer (not BLIK)
          var fraudulentTransfer = isFraudulentClient && !cashOrCardSelected && !onlyBlikSelected;
          return {
            offer: !payAndConfirm && applicationHelper.hasFinalApplicationPermission('offer'),
            iOffer: applicationHelper.hasFinalApplicationPermission('iOffer'),
            backToEdit: applicationHelper.hasFinalApplicationPermission('backToEdit'),
            policyAccept: !payWithBLIK && !payAndConfirm && !fraudulentTransfer && applicationHelper.hasFinalApplicationPermission('policyAccept'),
            payWithBLIK: payWithBLIK && applicationHelper.hasFinalApplicationPermission('policyAccept'),
            reservePolicyNumber: payAndConfirm && applicationHelper.hasFinalApplicationPermission('offer'),
            viewOffer: !payAndConfirm && !appVariables.isOcBuyer && applicationHelper.hasFinalApplicationPermission('viewOffer'),
            viewIOffer: applicationHelper.hasFinalApplicationPermission('viewIOffer'),
            viewBackToEdit: applicationHelper.hasFinalApplicationPermission('viewBackToEdit'),
            viewPolicyAccept: !payWithBLIK && !payAndConfirm && !fraudulentTransfer && applicationHelper.hasFinalApplicationPermission('viewPolicyAccept'),
            terminatePolicy: !isFraudulentClient && appVariables.isOcBuyer && applicationHelper.hasFinalApplicationPermission('policyAccept'),
            viewTerminatePolicy: !isFraudulentClient && appVariables.isOcBuyer && applicationHelper.hasFinalApplicationPermission('viewPolicyAccept')
          };
        };

        /**
         * zwraca ustawienia przycisków dla templatki
         * @return {Object}
         * wartości {visible: true|false, active: true|false} //widocznosc i aktywnosc
         * dostępne klucze - przyciski:
         * backToEdit - powrót do edycji
         * policyAccept - zatwierdź polisy
         * offer - oferta wiążąca
         * iOffer - wyślij do iKonta
         */
        this.getButtonsSettings = function() {
          var isFraudulentClient = self.isFraudulentClient(false);
          var actionPermissions = self.getActionPermissions(isFraudulentClient, self.getPolicyPaymentMethods()),
            applOfferState = mainDataContainer.application.get('status') === ApplicationModelConstants.APPLICATION_STATUS_OFFER,
            offerStateValid = (angular.isUndefined(mainDataContainer.application.get('dynamicValues').offerState)) || mainDataContainer.application.get('dynamicValues').offerState === 'VALID',
            isSalesCharType = sp2CommonHelper.charTypes1.indexOf(ihestiaSsoBaseInfoHelper.getUser().getCurrentCharacter().type) !== -1,
            data = { //ustawienia przycisków
              offer: { //oferta wiążąca
                visible: actionPermissions.viewOffer, //czy widoczny
                active: false //czy aktywny
              },
              iOffer: { //wyślij do iKonta
                visible: actionPermissions.viewIOffer,
                active: false
              },
              backToEdit: { //powrót do edycji
                visible: actionPermissions.viewBackToEdit,
                active: false
              },
              policyAccept: { //zatwierdź polisy
                visible: actionPermissions.viewPolicyAccept,
                active: false
              },
              terminatePolicy: { //wypowiedz polise zbywcy
                visible: actionPermissions.viewTerminatePolicy,
                active: false
              },
              reservePolicyNumber: { // "oplac i zatwierdz"
                visible: actionPermissions.reservePolicyNumber,
                active: false
              },
              payWithBLIK: { // "oplac BLIKiem"
                visible: actionPermissions.payWithBLIK,
                active: false
              }
            };

          switch (appVariables.appMode) {
            case CONSTANTS.APP_MODE_CALCULATION:
              data.offer.active = actionPermissions.offer;
              data.reservePolicyNumber.active = actionPermissions.reservePolicyNumber;
              data.terminatePolicy.active = actionPermissions.terminatePolicy;
              data.policyAccept.active = actionPermissions.policyAccept && offerStateValid;
              break;
            case CONSTANTS.APP_MODE_OFFER:
              if (applOfferState) {
                data.iOffer.active = actionPermissions.iOffer && offerStateValid;
              }
              data.policyAccept.active = actionPermissions.policyAccept && offerStateValid;
              data.backToEdit.active = actionPermissions.backToEdit;
              data.payWithBLIK.active = actionPermissions.payWithBLIK;
              break;
            default:
              break;
          }
          //jesli charakter sprzedazowy i uprawniony do zarządzania wnioskiem, to wszystko co nieaktywne, ma byc tez niewidoczne
          if (isSalesCharType && appVariables.canManage) {
            angular.forEach(data, function(btnData) {
              if (!btnData.active) {
                btnData.visible = false;
              }
            });
          }
          return data;
        };

        /**
         * zwraca tekst komuniaktu wyświetlanego przed zatiwerdzeniem polisy
         * WARNING we've stopped using it - comment in IHESTIASS-28
         * @return {String}
         */
        this.getPolicyConfirmAlert = function() {
          var texts = [$filter('translate')('modal.verifyDataBeforePolicyAccept', {componentCode: 'sp2'})],
            pzaDiscountAdded = angular.isObject(mainDataContainer.application.dynamicValues) && angular.isDefined(mainDataContainer.application.dynamicValues.adjustmentPLN) && parseInt(mainDataContainer.application.dynamicValues.adjustmentPLN, 10) !== 0;

          // jesli przydzielona znizka PZA to dodatkowy komunikat
          if (pzaDiscountAdded) {
            var amountExp = /^[-]?[0-9,]+$/, // regexp do testu samej kwoty
              premiumDiff = basketHelper.tplData.basketSecondContent.objectsWithSums.sumAfter - basketHelper.tplData.basketSecondContent.objectsWithSums.sumBefore,
              discount = premiumDiff <= 0; // jeśli false, to była zwyżka
            texts.push($filter('translate')(discount ? 'modal.policyAcceptDiscountInfo1' : 'modal.policyAcceptBoosterInfo1', {
              componentCode: 'sp2', params: {
                discountRequested: amountExp.test(mainDataContainer.individualDiscountInputValue) ? $filter('stringPremium')(mainDataContainer.individualDiscountInputValue) : mainDataContainer.individualDiscountInputValue
              }
            }));
            texts.push($filter('translate')(discount ? 'modal.policyAcceptDiscountInfo2' : 'modal.policyAcceptBoosterInfo2', {
              componentCode: 'sp2', params: {
                discountGranted: $filter('stringPremium')(premiumDiff)
              }
            }));
          }
          return texts.join(' ');
        };

        /**
         * Returns true if client iss fraudulent - condition depends on appMode and checkByMessage flag (used only for calculations)
         * Using mainErrorCollection instead of messageHelper to avoid circular dependency injection
         * mainErrorCollection does contain current errors and is internally used by messageHelper
         * @param {boolean} [checkByMessage=true] check loss ratio only in message collection
         * @return {boolean}
         */
        this.isFraudulentClient = function(checkByMessage) {
          checkByMessage = typeof checkByMessage === 'boolean' ? checkByMessage : true;
          var isFraudulent = _.get(mainDataContainer.application, 'dynamicValues.lossRatioDebtCollection', null) === 'Zły';
          switch (appVariables.appMode) {
            case CONSTANTS.APP_MODE_CALCULATION:
              return checkByMessage ? _.some(mainErrorCollection.all(), ['code', CONFIG.MESSAGES.fraudulentMsgCode]) : isFraudulent;
            case CONSTANTS.APP_MODE_OFFER:
              return isFraudulent;
            // return _.get(mainDataContainer.application, 'dynamicValues.insuranceApplicationSubStatus', null) === 'DemandPayment'; // ApplicationModelConstants.OFFER_STATUS_DEMAND_PAYMENT;
            default:
              return false;
          }
        };

        /**
         * Returns payment method list for policies
         * @return {Array}
         */
        this.getPolicyPaymentMethods = function() {
          return _.map(mainDataContainer.policies, function(policy) {
            if (policy.paymentMethod === 'TRANSFER') {
              return _.get(policy, 'product.dynamicValues._isBlikPayment', false) ? 'BLIK' : policy.paymentMethod;
            } else {
              return policy.paymentMethod;
            }
          });
        };

        this.isPunuNumber = function() {
          return mainDataContainer.application.get('dynamicValues').numberPUNU;
        };

      };

      return new SummaryHelper();
    }
  ]);
