angular.module('ihestiaRestServicesBase')
  .factory('ihestiaSessionSvc', ['IHestiaRestServiceLsnDef', 'ihestiaConfigHelper',
    function(IHestiaRestServiceLsnDef, ihestiaConfigHelper) {

      var SessionSvc = function() {
        IHestiaRestServiceLsnDef.apply(this, arguments);

        var self = this;
        this.resource = 'session';
        this.host = '';
        this.port = '';

        /**
         * Ustalenie endpointu usługi
         */
        if(ihestiaConfigHelper.get('ihsgDefines', 'BASE_BACKEND_MODE')) {
          self.apiString = ihestiaConfigHelper.get('ihsgDefines', 'BASE_BACKEND_API_STRING');
        }


        /**
         * Wyłączamy powiadomienie o tym, że hasło wkrótce wygaśnie
         * @param  {Function} callback [description]
         * @return {[type]}            [description]
         */
        this.hidePwdExpiryInfo = function(callback) {
          return self.put('hidePwdExpiryInfo', {}, '', callback, callback);
        };


      };
      return new SessionSvc();
    }
  ]);