angular.module('lsnBase.validators')
        .directive('clearInvalidOnBlur', function() { // dziala tylko onBlur. czyści pole gdy jest invalid
          return {
            restrict: 'A',
            require: 'ngModel',
            priority: 1000,
            link: function(scope, element, attrs, modelCtrl) {

              /**
               * czyści pole gdy wartość nie jest poprawna
               * @param {String} inputValue
               * @returns {String}
               */
              var clearIfInvalid = function(inputValue) {
                if (modelCtrl.$invalid) {
                  inputValue = '';
                }

                element.val(inputValue);
                modelCtrl.$setViewValue(inputValue);
              };

              element.on('blur', function() {
                clearIfInvalid(element.val());
              });

              scope.$on('$destroy', function(){
                element.off('blur');
              });

              clearIfInvalid(scope[attrs.ngModel]);
            }
          };
        });