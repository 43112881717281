angular.module('salesPath2')
  .controller('propertySumInsuredModalCtrl', ['$scope', 'localizationModalHelper', 'CONSTANTS', '$filter', 'EstateModelConstants', 'actionHelper', 'dataContainerHelper', 'fireBurglaryHelper', '$sce', '$state',
    function($scope, localizationModalHelper, CONSTANTS, $filter, EstateModelConstants, actionHelper, dataContainerHelper, fireBurglaryHelper, $sce, $state) {
      $scope.modalData = localizationModalHelper.currentlyEditedLocalizationData;
      $scope.tplData = fireBurglaryHelper.tplData;
      var selectedLocalization = $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization];
      var risksCode = ['EFK1000GEH', '5FK1000GEH', '62P0000GE8', 'I2P0000GE8', 'ZPK1000GEH', 'QQK1000GEH', 'U200000GEB', '2300000GEB'];
      $scope.currentRisk = $scope.dataContainer.application.risks.find(function(risk) {
        return risksCode.some(function (id) {
          return risk.metaData.id.includes(id + '_' + selectedLocalization.metaData.id);
        });
      });

      var averagePriceForOneSquareMeter = $filter('stringPremium')($scope.currentRisk.product.dynamicValues.averagePriceForOneSquareMeter, '0 zł');
      var suggestedSumInsured = $filter('stringPremium')($scope.currentRisk.product.dynamicValues.suggestedSumInsured, '0 zł');
      $scope.warningMessage = selectedLocalization.type === EstateModelConstants.ESTATE_TYPE_FLAT
        ? $filter('translate')('sp2.modal.propertySumInsured.flatInsuredSumDifferenceWarning', {componentCode: 'sp2'}).replace('{R} zł', averagePriceForOneSquareMeter).replace('{Y} zł', suggestedSumInsured).replace('m2', 'm<sup>2</sup>')
        : $filter('translate')('sp2.modal.propertySumInsured.houseInsuredSumDifferenceWarning', {componentCode: 'sp2'}).replace('{R} zł', averagePriceForOneSquareMeter).replace('{Y} zł', suggestedSumInsured).replace('m2', 'm<sup>2</sup>');

      $scope.trustedWarningMessage = $sce.trustAsHtml($scope.warningMessage);

      $scope.init = function() {
        $scope.fireListeners();
      };
      
      /**
      * Odpalamy nasłuchiwanie eventow, watchy itp.
      */
     $scope.fireListeners = function() {
       // zapis danych
       $scope.$on('iHestiaCommonModalOkBtnClicked.propertySumInsuredModal', function() {
          $state.go(CONSTANTS.PAGE_PRODUCT_FIRE).then(function() {
              $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.acceptSuggestedSumInsured = true;
              var riskCode = CONSTANTS.PRODUCT_KRA;
              if ($scope.tplData.view === CONSTANTS.PAGE_PRODUCT_FIRE) {
                  riskCode = CONSTANTS.PRODUCT_OGI;
              }
              dataContainerHelper.setSu(CONSTANTS.PRODUCT_TYPE_LOCALIZATION, $scope.dataContainer.selectedLocalization, riskCode, $scope.currentRisk.product.dynamicValues.suggestedSumInsured, 'estate');
              $scope.tplData.suValues[CONSTANTS.MAIN_INSURED_OBJECT_ESTATE] = $scope.currentRisk.product.dynamicValues.suggestedSumInsured;
              $scope.setRiskDynamicValues();
              actionHelper.runAction('saveApplication');
          });
       });

       $scope.$on('iHestiaCommonModalCancelBtnClicked.propertySumInsuredModal', function() {
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.acceptSuggestedSumInsured = false;
        $scope.setRiskDynamicValues();
        actionHelper.runAction('saveApplication');
       });
     };

     $scope.init();

     $scope.setRiskDynamicValues = function() {
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.averagePriceForOneSquareMeter = $scope.currentRisk.product.dynamicValues.averagePriceForOneSquareMeter;
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.suggestedSumInsured = $scope.currentRisk.product.dynamicValues.suggestedSumInsured;
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.premiumForSuggestedSumInsured = $scope.currentRisk.product.dynamicValues.premiumForSuggestedSumInsured;
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.percentForSuggestedSumInsured = $scope.currentRisk.product.dynamicValues.percentForSuggestedSumInsured;
        $scope.dataContainer.localizations[$scope.dataContainer.selectedLocalization].dynamicValues.percentPremiumForSuggestedSumInsured = $scope.currentRisk.product.dynamicValues.percentPremiumForSuggestedSumInsured;
     };
    }
  ]);
