angular.module('lsnBase.errorHandler')
  .service('lsnCommonErrorSvc', ['lsnCommonErrorHelper', '$http', 
    function(lsnCommonErrorHelper, $http) {
      var ErrorHandlerSvc = function() {
        var serviceUrl = lsnCommonErrorHelper.serviceUrl;

        /**
         *
         * @param {LsnCommonErrorModel} exc
         * @param {function} callback
         * @param {function} opcja, errorCallback
         */
        this.saveError = function(exc, callback, errorCallback) {
          if(exc.message === 'Unexpected token <' || !serviceUrl) // prawdopodobnie wysypało się parsowanie odpowiedzi z requesta do zapisu błędu, więc nie chcemy zapętlenia
          {
            callback();
          }
          else
          {
            $http({
              method: 'POST',
              url: serviceUrl,
              data: exc.toPlainObject()
            }).then(callback, errorCallback);
          }
        };

      };

      return new ErrorHandlerSvc();
    }]);