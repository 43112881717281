angular.module('salesPath2')
  .service('matrixHelper', ['CONSTANTS', 'RESOURCES', 'CONFIG', 'sp2SelectionHelper', 'mainDataContainer', 'sp2CommonHelper', '$sce', 'renewalHelper', 'dataContainerHelper',
    function(CONSTANTS, RESOURCES, CONFIG, selectionHelper, mainDataContainer, sp2CommonHelper, $sce, renewalHelper, dataContainerHelper) { // eslint-disable-line angular/di
      var MatrixHelper = function() {
        var self = this;

        /**
         * dane dla kontrolera i szablonu
         * @type {Object}
         */
        this.tplData = {
          productList: [], //lista produktow dla sekcji kafelek
          productIconList: [], //lista produktow dla sekcji ikon
          sharedVariantData: {
            enabled: CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant, //czy aktuwna opcja współdzielenia wariantu w ogniu/kradzieży
            productList: [], //lista definicji produktów ze współdzielonym wariantem
            variantList: [], //lista definicji wariantów dla praoduktów ze współdzielonym wariantem
            name: $sce.trustAsHtml(''), //nazwa współdzielonego wariantu dl ogi,kra III
            code: CONSTANTS.VARIANT_III,
            productCode: CONSTANTS.PRODUCT_OGI_KRA,
            type: CONSTANTS.PRODUCT_TYPE_LOCALIZATION
          },
          selectedVariants: {}, //zaznaczone warianty
          previouslySelectedVariants: {}, //historycznie (np. z poprzeniej polisy w dokupieniach) wybrane warianty proukctów
          historicalDisabledVariants: {}, //historycznie (np. z poprzeniej polisy w dokupieniach) wyłączone warianty ryzyk
          numbersToShow: {}, //przechowuje numery obiektów, dla których wybrano dany wariant
          fireBurglarySharedVariant: CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant,
          prodPremiums: {},
          renewalMarks: {}, //informacje o wznowieniach
          disabledVariants: {} //productCode: {variantCode:true}
        };

        /**
         * reakcja na akcje w aplikacji
         * @param  {String} actionName nazwa wykonanej akcji
         */
        this._afterAction = function(actionName) {
          switch (actionName) {
            case 'personEdited':
            case 'personAdded':
            case 'groupAdded':
            case 'groupEdited':
            case 'personGroupSelected':
            case 'personGroupDeleted':
            case 'localizationAdded':
              self.refreshAllData();
              break;
            case 'localizationDeleted':
            case 'localizationSelected':
            case 'vehicleSelected':
            case 'vehicleDeleted':
            case 'vehicleEdited':
            case 'vehicleAdded':
              self.refreshAllData();
              self.refreshDisabledVariant();
              break;
            case 'tarifficationEnded':
              self.refreshPremiums();
              self.refreshRenewal();
              break;
            case 'languageChanged.beforeStateReload':
              self.refreshProductList();
              self.refreshAllData();
              break;
            default:
              break;
          }
        };

        //inicjalizacja helpera przy stworzeniu jego instancji
        this.initHelper = function() {
          self.refreshProductList();
        };

        /**
         * [refreshDisabledVariant odswieza nie dostepne warianty]
         * @return {[type]} [description]
         */
        this.refreshDisabledVariant = function() {
          var disabledVariants = {};
          angular.forEach(self.tplData.productList, function(productDef) {
            disabledVariants[productDef.CODE] = selectionHelper.getDisabledVariant(productDef.CODE);
          });
          angular.forEach(self.tplData.sharedVariantData.variantList, function(variantDef) {
            disabledVariants[variantDef.PRODUCT_CODE] = selectionHelper.getDisabledVariant(variantDef.PRODUCT_CODE);
          });
          disabledVariants[self.tplData.sharedVariantData.productCode] = selectionHelper.getDisabledVariant(self.tplData.sharedVariantData.productCode);

          //convertujemy do prostej w htmlu postaci
          self.tplData.disabledVariants = {};
          angular.forEach(disabledVariants, function(disabledVariants, productCode) {
            if (!angular.isObject(self.tplData.disabledVariants[productCode])) {
              self.tplData.disabledVariants[productCode] = {};
            }
            angular.forEach(disabledVariants, function(variant) {
              self.tplData.disabledVariants[productCode][variant] = true;
            });
          });
        };

        /**
         * inicjalizuje listę produktów dla matrycy
         */
        this.refreshProductList = function() {
          var sharedVariant = CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant;
          //przypadek z wariantem typu "all risk" dla III wariantu ognia i kradzieży
          self.tplData.productList = [];
          self.tplData.productIconList = [];
          if (sharedVariant) {
            self.tplData.sharedVariantData.variantList = [];
            angular.forEach(RESOURCES.PRODUCTLIST, function(prodDef) {
              if ([CONSTANTS.PRODUCT_OGI, CONSTANTS.PRODUCT_KRA].indexOf(prodDef.CODE) !== -1) {
                var sharedProdDef = angular.copy(prodDef);
                //usuwamy 3 wariant, ponieważ jest on współdzielony
                if (angular.isUndefined(sharedProdDef.VARIANTLIST[2])) {
                  sp2CommonHelper.throwException('Brak definicji trzeciego wariantu ubezpieczenia dla produktu ' + sharedProdDef.NAME);
                }
                //nazwa dla wspólnego wariantu powinna być taka sama na wariancie ognia i kradziezy, dlatego bierzemy jedną z nich - ostatnią
                self.tplData.sharedVariantData.name = prodDef.VARIANTLIST[2].NAME;
                sharedProdDef.VARIANTLIST.splice(2, 1);
                angular.forEach(sharedProdDef.VARIANTLIST, function(variant) {
                  variant.PRODUCT_CODE = prodDef.CODE;
                  variant.NAME = prodDef.NAMEFROMVARIANT ? variant.NAME : prodDef.NAME;
                  self.tplData.sharedVariantData.variantList.push(variant);
                });
                self.tplData.productIconList.push(sharedProdDef);
              } else {
                self.tplData.productList.push(prodDef);
                self.tplData.productIconList.push(prodDef);
              }
            });
          } else {
            self.tplData.productList = angular.copy(RESOURCES.PRODUCTLIST);
            self.tplData.productIconList = angular.copy(RESOURCES.PRODUCTLIST);
          }
        };

        /**
         * aktualizuje wszystkie dane (z wyjątkiem definicji produktów - productList, productIconList) z self.tplData
         */
        this.refreshAllData = function() {
          self.tplData.selectedVariants = selectionHelper.getSelectedVariants();
          self.tplData.previouslySelectedVariants = selectionHelper.getPreviouslySelectedVariants();
          self.tplData.historicalDisabledVariants = selectionHelper.getHistoricalDisabledVariants();
          //numery wybranych obiektów
          self.tplData.numbersToShow = {};
          angular.forEach(self.tplData.selectedVariants, function(variants, product) {
            self.tplData.numbersToShow[product] = {};
            if (angular.isObject(variants)) {
              angular.forEach(variants, function(selected, variant) {
                if (angular.isArray(selected)) {
                  self.tplData.numbersToShow[product][variant] = selected;
                }
              });
            }
          });
          //uzupełnienie danych dla wariantu all risk
          if (CONFIG.BEHAVIOR.localization.fireBurglarySharedVariant && angular.isDefined(self.tplData.numbersToShow[CONSTANTS.PRODUCT_OGI]) && angular.isDefined(self.tplData.numbersToShow[CONSTANTS.PRODUCT_OGI][CONSTANTS.VARIANT_III])) {
            self.tplData.numbersToShow[CONSTANTS.PRODUCT_OGI_KRA] = self.tplData.numbersToShow[CONSTANTS.PRODUCT_OGI];
          }

          self.refreshPremiums();
          self.refreshRenewal();
          self.refreshDisabledVariant();
        };

        /**
         * aktualizuje składki
         */
        this.refreshPremiums = function() {
          self.tplData.prodPremiums = dataContainerHelper.getPremiums('premiumList');
        };

        /*
         * Czyści numer zielonej karty i taryfikuje
         */
        this.clearGreenCard = function() {
          mainDataContainer.greenCardNumber = '';
        };
        /**
         * odswieża znaczniki wznowień
         */
        this.refreshRenewal = function() {
          self.tplData.renewalMarks = {};
          angular.forEach(renewalHelper.getProductsMarks(), function(variants, productCode) {
            if (angular.isObject(variants)) {
              angular.forEach(variants, function(val, variant) {
                if (angular.isUndefined(self.tplData.renewalMarks[productCode])) {
                  self.tplData.renewalMarks[productCode] = {};
                }
                self.tplData.renewalMarks[productCode][variant] = true;
              });
            } else {
              if (angular.isUndefined(self.tplData.renewalMarks[productCode])) {
                self.tplData.renewalMarks[productCode] = {};
              }
              self.tplData.renewalMarks[productCode][variants] = true;
            }
          });
        };

        this.initHelper();
      };

      return new MatrixHelper();
    }
  ])
  .run(['matrixHelper', 'actionHelper',
    function(matrixHelper, actionHelper) {
      actionHelper.registerHelper('matrixHelper', matrixHelper);
    }
  ]);