angular.module('ihestiaCommonComponents')
  .factory('ihestiaCommonComponentListSvc', ['ihestiaCommonAbstractComponentStatusSvc', 'ihestiaCommonComponenModel', 'ihestiaCommonErrorHandler',
    function(ihestiaCommonAbstractComponentStatusSvc, ihestiaCommonComponenModel, ihestiaCommonErrorHandler) {

      var ComponentListSvc = function() {
        ihestiaCommonAbstractComponentStatusSvc.apply(this, arguments);
        var self = this;
        this.resource = 'manageable-component/list';

        /**
         * Pobieranie listy  komponentow
         * @return {[type]} [description]
         */
        this.fetch = function(callback) {
          self.get(null, null, null, function(res) {
            if (res.data.Status === 'Ok') {
              var components = [];
              angular.forEach(res.data['node-list'], function(component){
                var componentModel = new ihestiaCommonComponenModel();
                componentModel.setData(component);
                components.push(componentModel);
              });
              callback(components, res);
            } else {
              ihestiaCommonErrorHandler.throwException({
                code: 500,
                message: 'Błąd ładowania listy komponentów'
              });
            }
          });
        };
      };
      return new ComponentListSvc();
    }
  ]);