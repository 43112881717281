angular.module('ihestiaCommonDirectives')
  .directive('ihestiaInLineEdit', ['$rootScope', '$timeout',
      function ($rootScope, $timeout) {
        return {
          restrict: 'E',
          replace: true,
          maxLength: null,
          scope: {
            value: '='
          },
          templateUrl: 'ihestia-commondirectives-template-app/directives/inLineEdit/inLineEditInputTemplate.tpl.html',
          link: function ($scope, element, $attrs) {

            var inputElement = null;
            //czy anulowano wprowadzanie - ESC
            $scope.editCancelled = false;
            //wartosc w polu input
            $scope.inputValue = $scope.value;
            $scope.maxLength = $attrs.maxlength;

            //szukamy elementu input
            angular.forEach(element.children(), function (childElement) {
              if (childElement.tagName === 'INPUT') {
                inputElement = angular.element(childElement);
              }
            });

            if (inputElement === null) {
              return;
            }

            //inicjalnie wyswietlamy etykiete
            $scope.inlineEditing = false;

            /**
             * edytowanie inline
             * @return {[type]} [description]
             */
            $scope.editInlineStart = function () {
              $scope.editCancelled = false;
              $scope.inlineEditing = true;
              $scope.inputValue = $scope.value;
              $timeout(function() {
                inputElement[0].focus();
              }, 0);
            };

            /**
             * edytowanie inline zakonczone
             * @return {[type]} [description]
             */
            $scope.editInlineEnd = function () {
              if ($scope.editCancelled) {
                return;
              }
              $scope.inlineEditing = false;
              var prevValue = $scope.value;
              $scope.value = $scope.inputValue;
              $timeout(function() {
                $rootScope.$broadcast('ihestiaInlineEditionEnded', $attrs.context, prevValue, $scope.inputValue);
              }, 0);
            };

            /**
             * edytowanie inline - anulowanie
             * @return {[type]} [description]
             */
            $scope.editInlineCancel = function () {
              $scope.inlineEditing = false;
              $scope.editCancelled = true;
            };

          }
        };
      }
    ]

  );