angular.module('ihestiaIkomunikator')
  .service('ikomunikatorSearchMessagesCentralListHelper', ['$filter', 'iHestiaCommonModalHelper', 'iKomunikatorFtsSearchSvc', 'IKomunikatorFtsSearchModel', 'iKomunikatorConstants', 'ihestiaIkomunikatorLoaderMessagesHelper',
    function($filter, iHestiaCommonModalHelper, iKomunikatorFtsSearchSvc, IKomunikatorFtsSearchModel, iKomunikatorConstants, ihestiaIkomunikatorLoaderMessagesHelper) {

      var SearchMessagesCentralHelper = function() {
        var self = this;
        /*eslint-disable camelcase */
        this.defaultData = {
          searchFilters: {
            size: 10,
            from: 0,
            phrase: null,
            context: 'SUBJECT',
            sort_by: null,
            sort_order: 'none',
            filters: {
              type: [iKomunikatorConstants.CONTACT_ROLE_CLIENT]
            }
          },
          total: 0,
          loading: false,
          ftsRecipients: [],
          //ustawiana na zmiane atrybutów w momencie wywoływania
          reloadSearchAgain: false,
          reverseSort: false,
          personTypes: [{
            code: iKomunikatorConstants.CONTACT_ROLE_CLIENT,
            label: 'KLIENT',
            placeholder: 'IMIĘ, NAZWISKO, NUMER PESEL'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_SALES_PROFILE,
            label: 'PROFIL SPRZEDAŻOWY',
            placeholder: 'NAZWA, NUMER PROFILU'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_WORKER,
            label: 'PRACOWNIK',
            placeholder: 'IMIĘ, NAZWISKO, NUMER RA, NUMER PROFILU'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_CENTRAL,
            label: 'CENTRALA',
            placeholder: 'IMIĘ, NAZWISKO, LOGIN'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_CLAIMS_ADJUSTER,
            label: 'LIKWIDATOR',
            placeholder: 'IMIĘ, NAZWISKO, LOGIN'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_FUNCTIONAL_MAILBOX,
            label: 'SKRZYNKA FUNKCYJNA',
            placeholder: 'NAZWA, E-MAIL'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_TASK_POOL,
            label: 'PULA',
            placeholder: 'NAZWA'
          }, {
            code: iKomunikatorConstants.CONTACT_ROLE_OTHER,
            label: 'INNY',
            placeholder: 'NAZWA, E-MAIL, TELEFON KOM.'
          }]
        };

        this.tplData = angular.copy(self.defaultData);

        this.search = function() {
          if(self.tplData.searchFilters.phrase.length < 3){
            //skoro metoda zostala wywolana to znaczy ze sie juz nie laduje loading musialo byc na false
            //chyba ze wywolane przez metode afterSearch, czyli po wyszukiwaniu nie przestawilo flagi
            self.tplData.loading = false;
            return;
          }

          self.tplData.loading = true;
          self.tplData.searchResultEmpty = false;
          if(self.tplData.searchFilters.sort_by !== null){
            self.tplData.searchFilters.sort_order = self.tplData.reverseSort ? 'DESC' : 'ASC';
          }
          
          iKomunikatorFtsSearchSvc.postSearch(self.tplData.searchFilters).then(function(res) {
            if(res && res.status === 200) {
              var data = res.data.subject.hits;
              angular.forEach(data.hits, function(recData){
                var ftsRecipient = new IKomunikatorFtsSearchModel();
                ftsRecipient.set(recData);
                self.tplData.ftsRecipients.push(ftsRecipient);  
              });

              self.tplData.total = data.total;

              if (self.tplData.total === 0 || self.tplData.ftsRecipients.length === 0) {
                self.tplData.searchResultEmpty = true;
              }
            }
          }, angular.noop).finally(function(){
            self.afterSearch();
          });
        };

        this.afterSearch = function(){
          if(self.tplData.reloadSearchAgain){
            self.tplData.reloadSearchAgain = false;

            self.tplData.searchFilters.from = 0;
            self.tplData.ftsRecipients = [];
            
            self.search();
            return;
          }

          self.tplData.loading = false;
        };

        this.searchNext = function() {
          if (!self.tplData.loading && !self.loadedAllMessages()) {
            self.tplData.loading = true;
            self.tplData.searchFilters.from += self.tplData.searchFilters.size;
            self.search();
          }
        };

        this.reloadRecipients = function() {
          if (!self.tplData.loading) {
            self.tplData.searchFilters.from = 0;
            self.tplData.ftsRecipients = [];
            self.search();
          }
        };

        this.loadedAllMessages = function() {
          return self.tplData.searchFilters.from + self.tplData.searchFilters.size >= self.tplData.total;
        };

        this.setClearData = function() {
          ihestiaIkomunikatorLoaderMessagesHelper.setClearData(self.tplData, self.defaultData);
        };

        this.setPlaceholder = function() {
          angular.forEach(self.tplData.personTypes, function(personType){
            if(personType.code === self.tplData.searchFilters.filters.type[0]){
              self.tplData.selectedPlaceholer = personType.placeholder;
            }
          });
        };

        this.sortBy = function(columnName) {
          if (!self.tplData.loading) {
            self.tplData.loading = true;
            self.tplData.ftsRecipients = [];
            if (self.tplData.searchFilters.sort_by === columnName) {
              self.tplData.reverseSort = !self.tplData.reverseSort;
            } else {
              self.tplData.searchFilters.sort_by = columnName;
            }
            
            self.search();
          }
        };
        /*eslint-enable camelcase */
        
        this.clearAllData = function(){
          self.tplData = angular.copy(self.defaultData);
        };
      };

      return new SearchMessagesCentralHelper();
    }
  ]);