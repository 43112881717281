angular.module('salesPath2.spd')
.factory('spdAdditionalData', [ function() {
    var additionalData = {
      'Person': {
        'OCZP': null,
        'NNW': null
      },
      'Organization': {

      },
      'Vehicle': {
        'protectionDates': null, //muszą być uzupełnione, ale wartości są dynamiczne
        'coowners': [],
        'isLeased': null,
        'isYoungDriver': null,
        'leaserId': null,
        'liquidation': null,
        'vehicleValueType': null,
        'source': null,
        'specialPurposes': [],
        'rightHandDrive': null
      },
      'Estate': {
        'protectionDates': null, //muszą być uzupełnione, ale wartości są dynamiczne
        'coowners': [],
        'leaserId': null,
        'flood': null,
        'outBuildings': null
      },
      Policy: {
        'discounts': {}
      }
    };

    return additionalData;
  }])
;