/**
 * Dyrektywa do wrzucenia szablonu modala konfliktu kontekstu ic
 * @param  {[type]}  [description]
 * @return {[type]}                 [description]
 */
angular.module('ihestiaSsoBase')
  .directive('ihestiaIcConflictedModal', ['$templateCache', 'iHestiaCommonModalHelper', 'ihestiaConfigHelper', '$window', 'bodyInjectorHelper', 'ihestiaIcConflictedHelper', 'ihestiaSessionCurrenticSvc', '$timeout', 'ihestiaCrossTabHelper', '$rootScope',
    function($templateCache, iHestiaCommonModalHelper, ihestiaConfigHelper, $window, bodyInjectorHelper, ihestiaIcConflictedHelper, ihestiaSessionCurrenticSvc, $timeout, ihestiaCrossTabHelper, $rootScope) {
      var directive = {
        restrict: 'E',
        template: $templateCache.get('ihestia-sso-template-app/sso/icConflicted/icConflictedModal.tpl.html'),
        link: function($scope) {
          $scope.modalSettings = {
            title: 'Konflikt towarzystwa',
            okBtnName: 'Przywróć',
            cancelBtnName: 'Potwierdź nowe towarzystwo'
          };

          $scope.serviceIc = ihestiaIcConflictedHelper.getConflictedIc();
          $scope.currentIc = ihestiaConfigHelper.get('ic', 'activeContext');

          $timeout(function() { //musimy poczekać aż on dla kodu modala załączy się na scope
            iHestiaCommonModalHelper.showModal('ihestiaIcConflicted');
          }, 0);

          $scope.$on('iHestiaCommonModalCancelBtnClicked.ihestiaIcConflicted', function(){
            //potiwerdzamy nową tożsamość przeładowując stronę
            $window.location = '/';
          });

          $scope.$on('iHestiaCommonModalOkBtnClicked.ihestiaIcConflicted', function(){
            //jesli nie zresetujemy to okna przy kolejnej zmianie tożasamości się nie pojawi
            ihestiaIcConflictedHelper.icConflictedModalActive = false;
            // informujemy resztę zakładek, że znowu zmieniono charakter
            ihestiaCrossTabHelper.sendMessage({
              code: 'icChanged',
              attrs: {
                icCode: $scope.currentIc,
                changeByRevert: true
              }
            });

            var headers = {};
            headers['AP-Ic'] = $scope.currentIc === 'EHN' ? 'EHL' : 'EHN'; // musimy się przedstawić jako ten zmieniony kontekst, bo inaczej usługa nas odrzuci
            $rootScope.$broadcast('showBlockUi');
            ihestiaSessionCurrenticSvc.put(null, JSON.stringify($scope.currentIc), null, null, null, {
              headers: headers
            }).then(function(){
              $scope.$emit('icConflictResolved');
              bodyInjectorHelper.removeDirective('icConflicted');
              $rootScope.$broadcast('hideBlockUi');
            }, function(){
              $rootScope.$broadcast('hideBlockUi');
            });
          });
        }
      };

      return directive;
    }
  ]);