/**
* Dyrektywa do wrzucenia szablonu koszyka do menu
* @param  {[type]}  [description]
* @return {[type]}  [description]
*/
angular.module('salesPath2')
  .directive('ihestiaSalesPathMenuBasket', ['$templateCache',
    function($templateCache) {
      return {
        restrict: 'E',
        replace: true,
        template: $templateCache.get('salesPath2Template/common/basket/basket.tpl.html')
      };
    }
  ]);