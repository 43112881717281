angular.module('salesPath2')
  .service('contactHelper', ['CONSTANTS', 'ContactModelConstants', 'ihestiaTrimInfo',
    function(CONSTANTS, ContactModelConstants, ihestiaTrimInfo) {
      var ContactHelper = function() {
        var self = this;
        /**
         * domyślne ustawienia trimInfo dla typów kontaktów
         * @type {Object}
         */
        this.INFO_SETTINGS = {};
        //domyslne ustawienia dla nieokreślonego kodu kontaktu
        this.INFO_SETTINGS.DEFAULT = {
          elements: [{
            field: 'value'
          }],
          maxLength: 40,
          emptyValue: ''
        };
        this.INFO_SETTINGS[ContactModelConstants.CONTACT_TYPE_KOMO] = {
          elements: [{
            field: 'countryPrefix'
          }, {
            field: 'value'
          }],
          maxLength: 40,
          emptyValue: ''
        };

        /**
         * zwraca informacje do wyświetlenia o kontakcie
         * @param  {ContactModel} contact [description]
         * @param  {Boolean} [nullIfEmpty=false] zwróci nulla, gdy dane kontaktu niewypelnione
         * @param  {Object} [params] własne parametry do ihestiaTrimInfo
         * dodatkowo obsługa po stronie helpera parametrów:
         * globalPrefix: dodaje prefix do całego generowanego tekstu niezależnie od ustawień na wezle elements
         * @return {*} null lub obiekt
         */
        this.getContactInfo = function(contact, nullIfEmpty, params) {
          nullIfEmpty = (nullIfEmpty !== true) ? false : true;
          var defaults = angular.isDefined(self.INFO_SETTINGS[contact.get('code')]) ? self.INFO_SETTINGS[contact.get('code')] : self.INFO_SETTINGS.DEFAULT,
            settings = angular.extend(defaults, params || {}),
            testTxt = '';

          // wstawiamy teksty
          angular.forEach(settings.elements, function(element) {
            element.text = contact.get(element.field) || '';
            testTxt += element.text;
          });
          //extra parametry
          if (angular.isString(params.globalPrefix)) {
            settings.elements.unshift({
              text: params.globalPrefix
            });
          }
          if (nullIfEmpty && testTxt === '') {
            return null;
          }
          var text = ihestiaTrimInfo(settings);
          return text;
        };
      };

      return new ContactHelper();
    }
  ]);