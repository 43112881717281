angular.module('lsnBase.models')
.factory('LsnContactModelV1', ['LsnAddressModelV1', 'LsnContactModelConstants',
  function(LsnAddressModelV1, LsnContactModelConstants) {

    var LsnContactModelV1 = function()
    {
      this.objectName = 'Contact';
      this.modelVersion = 'v1';
      this.dataVersion = this.modelVersion;
      this.fieldsProperties = {
        code: {
          type: 'string',
          dictionary: LsnContactModelConstants.DICTIONARY_CONTACT_TYPE
        },
        countryPrefix: {
          type: 'string'
        },
        value: {
          type: 'string',
          filters: [
            {
              name: 'strUpperCase'
            },
            {
              name: 'strTrim'
            }
          ]
        }
      };

      this.code = null; // string, Typ kontaktu wg słownika
      this.countryPrefix = null; // przedrostek kraju w przypadku kontaktu nr tel kom (+48, +49 itp.)
      this.value = null; // string, Wartość kontaktu w zależności od typu

    };

    LsnContactModelV1.prototype = LsnAddressModelV1.prototype;

    return LsnContactModelV1;
  }]);