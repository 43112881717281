angular.module('salesPath2')
  .controller('matrixCtrl', ['$scope', 'matrixHelper', 'sp2SelectionHelper', 'navigationHelper', 'productsClausesAutomaticUpdateHelper',
    function($scope, matrixHelper, selectionHelper, navigationHelper) { // eslint-disable-line angular/di
      $scope.tplData = matrixHelper.tplData;
      $scope.matrixHeaderSettings = {}; //ustawienia naglowka
      $scope.navigationHelper = navigationHelper;

      $scope.localizationBoxVisable = true;
      $scope.personBoxVisable = true;
      $scope.vehicleBoxVisable = true;

      $scope.init = function() {
        $scope.initHeaderSettings();
        matrixHelper.refreshAllData();
      };

      /**
       * inicjalizacja ustawien dla naglowka
       * @return {[type]} [description]
       */
      $scope.initHeaderSettings = function() {
        if (!$scope.PAGE_CONFIG.MATRIX.header.enabled) {
          return false;
        }
        $scope.matrixHeaderSettings = angular.copy($scope.PAGE_CONFIG.MATRIX.header);
        //wszystkie ustawienia oprócz "enabled" są zgodne z sp2HeaderHelper.DEFAULT_HEADER_SETTINGS
        delete $scope.matrixHeaderSettings.enabled;
        return true;
      };

      $scope.selectVariant = function(code, variant) {
        selectionHelper.toggleProductVariant(code, variant);
        matrixHelper.refreshAllData();
        return true;
      };

      /**
       * przejście do strony produktu
       * @param  {String} productCode kod produktu
       */
      $scope.goToProduct = function(productCode) {
        if ($scope.PAGE_CONFIG.MATRIX.clickableProductIcon && $scope.dataContainer.allowedProducts[productCode]) {
          navigationHelper.go($scope.CONSTANTS.PRODUCT_TO_STATE[productCode]);
        }
      };

      // klient chce zobaczyc jak bedzie dzialalo usuwanie klauzul. Mozliwy powrot do aktualizacji - zostawiamy w razie czego ten kod na pozniej
      // $scope.checkClausesChanges = function() {
      //   var policy = $scope.dataContainer.application.get('policies')[0];
      //   if (policy && policy.product.dynamicValues._ProductClausesAutomaticUpdateResults && policy.product.dynamicValues._ProductClausesAutomaticUpdateResults.clauseCode) {
      //     productsClausesAutomaticUpdateHelper.showModal();
      //   }
      // };

      $scope.init();
    }
  ]);
