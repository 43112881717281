angular.module('lsnBase.models')
  .factory('LsnAmountModelConstants', [
    function() {
      var AmountConstants = {};
      AmountConstants.AMOUNT_CURRENCY_PLN = 'PLN';
      AmountConstants.AMOUNT_CURRENCY_EUR = 'EUR';
      AmountConstants.DICTIONARY_AMOUNT_CURRENCY = {};
      AmountConstants.DICTIONARY_AMOUNT_CURRENCY[AmountConstants.AMOUNT_CURRENCY_PLN] = 'zł';
      AmountConstants.DICTIONARY_AMOUNT_CURRENCY[AmountConstants.AMOUNT_CURRENCY_EUR] = 'euro';

      return AmountConstants;
    }
  ]);