angular.module('ihestiaWidgets.dashboard')
  .factory('ihestiaDashboardCasesSvc', ['IHestiaRestServiceAbstractDef',
    function(IHestiaRestServiceAbstractDef) {

      var CasesSvc = function() {
        IHestiaRestServiceAbstractDef.apply(this, arguments);

        this.resource = 'resources/cases';
        this.businessArea = 'dashboard';
        this.port = '8282';

        this.grid.sortBy = 'caseId';

      };
      return new CasesSvc();
    }
  ]);