angular.module('spaceChat.client')
  .controller('chatTalkPopupCtrl', ['$scope', 'ihestiaSsoBaseInfoHelper', 'spaceChatHelper', '$timeout', 'ihestiaConfigHelper', '$sce',
    function($scope, ihestiaSsoBaseInfoHelper, spaceChatHelper, $timeout, ihestiaConfigHelper, $sce) {

      $scope.userInfo = ihestiaSsoBaseInfoHelper.getUser();

      $scope.audioFilePath = $sce.trustAsResourceUrl(ihestiaConfigHelper.get('external', 'STATIC_URL') + 'ih/ihsgLsnVendor/assets/audio/chatNotification.mp3');

      $scope.userInitials = '';
      if($scope.userInfo.firstName)
      {
        $scope.userInitials = $scope.userInitials + $scope.userInfo.firstName.substring(0, 1).toUpperCase();
      }
      if($scope.userInfo.name)
      {
        $scope.userInitials = $scope.userInitials + $scope.userInfo.name.substring(0, 1).toUpperCase();
      }

      $scope.scrollBottomObj = {
        scrollOnInit: true
      };

      $scope.rateShowed = false;

      $scope.talkData = {
        message: '',
        messageSended: false
      };

      $scope.rateData = {
        grade: null,
        description: ''
      };

      $scope.rateActive = true;

      $scope.chatData = {};
      $scope.messages = [];

      $scope.scrollToBottomOnPool = false;

      $scope.loading = {
        join: false
      };

      $scope.spinnerOptions = {
        join: {
          label: '',
          size: 'sm'
        },
        boardPrevious: {
          label: '',
          size: 'sm'
        }
      };

      $scope.boardScrolled = function(data)
      {
        if($scope.chatData && !data.reallyReachedTop)
        {
          $scope.chatData.scrolledToBottomOnce = true;
        }
        if(data.reallyReachedTop && $scope.chatData.previous)
        {
          spaceChatHelper.loadPrevious($scope.chatData).then(function(data){
            if(data.messageId && data.threadId)
            {
              $timeout(function(){
                //przesuwamy się do tej wiadomości
                if(angular.isFunction($scope.scrollBottomObj.scrollToElement))
                {
                  $scope.scrollBottomObj.scrollToElement(data.threadId + '_' + data.messageId);
                }
                // $location.hash(data.threadId + '_' + data.messageId);
              });
            }
          }, lsnNg.noop);
        }
      };

      $scope.sendMessage = function(){
        if($scope.talkData.message && !$scope.confirmModalOpen) //jak mamy podniesione okno z potwierdzeniem zamknięcia to nie pozwalamy na wysyłanie wiadomości
        {
          $scope.talkData.messageSended = true;
          spaceChatHelper.sendMessage($scope.talkData.message, $scope.chatData, true);
          $scope.talkData.message = '';
          spaceChatHelper.setCookieText('');
          $scope.scrollToBottomOnPool = true;
          $scope.refreshCloseAvailability();
        }
      };

      /**
       * zmieniła się treść w textarea, więc zapisujemy ją w razie gdyby ktoś przeszedł do innej zakładki, wteyd tam chcemy go wyświetlić
       */
      $scope.messageTextChanged = function()
      {
        spaceChatHelper.setCookieText($scope.talkData.message);
      };

      /**
       * otwieramy ekran oceniania
       * @return {[type]} [description]
       */
      $scope.openChatRateWindow = function()
      {
        $scope.rateShowed = true;
      };

      /**
       * zapis gwiazdek
       */
      $scope.saveRating = function()
      {
        if($scope.rateData.grade)
        {
          spaceChatHelper.saveRating($scope.chatData.chat.thread_id, $scope.rateData);
          $scope.rateShowed = false;
          $scope.rateActive = false;
          $scope.scrollToBottom();
        }
      };

      $scope.init = function()
      {
        spaceChatHelper.additionalEventParserMethod = $scope.parseEvent;

        $scope.loading.join = true;
        //tworzymy nowy chat
        spaceChatHelper.agentJoinRoom($scope.userInfo).then(function(chatData){
          $scope.loading.join = false;
          $scope.talkChatData.chatActive = true;
          $scope.chatData = chatData;
          if($scope.chatData.chat.is_rated)
          {
            $scope.rateActive = false;
          }
          $scope.talkChatData.threadId = $scope.chatData.chat.thread_id;
          spaceChatHelper.agentStartLongPoll($scope.chatData, true);
          $scope.checkForCookieText();
          $scope.scrollToBottom();
          $scope.refreshQueue();
        }, angular.noop);
        $scope.rateShowed = false;

        spaceChatHelper.longPollCallback = $scope.longPollCallback;

        $scope.$watch('chatData.consultant', function(){
          $scope.refreshQueue();
          $scope.refreshCloseAvailability();
        });

        $scope.$watch('chatData.messages', function(){
           $scope.refreshCloseAvailability();
        });
      };

      $scope.parseEvent = function(message)
      {
        if(message && angular.isDefined(message.queue) && message.queue !== null)
        {
          $scope.chatData.queue = message.queue;
          $scope.refreshQueue();
        }
      };

      $scope.hasAnyTextMessages = function(messages)
      {
        var hasAny = false;
        angular.forEach(messages, function(event){
          if(event.message && event.message.message_type && event.message.message_type === 'MESSAGE')
          {
            hasAny = true;
          }
        });
        return hasAny;
      };

      $scope.refreshCloseAvailability = function()
      {
        //agent nie może zamknąć chata jeśli już coś napisał, ale nie zdążył się dołączyć konsultant
        if(!$scope.chatData.consultant && ($scope.talkData.messageSended || ($scope.chatData.messages && $scope.chatData.messages.length > 0 && $scope.hasAnyTextMessages($scope.chatData.messages))))
        {
          $scope.chatTalkPopupSettings.dynamicData.disableClose = true;
        }
        else
        {
          $scope.chatTalkPopupSettings.dynamicData.disableClose = false;
        }
      };

      $scope.refreshQueue = function()
      {
        //jeśli nie ma jeszcze konsultanta i mamy informację o kolejności w kolejce to dodajemy ją do tytułu żeby była widoczna na zminimalizowanym oknie
        if($scope.chatData.queue && !$scope.chatData.consultant)
        {
          $scope.chatTalkPopupSettings.dynamicData.additionalTitleText = 'Jesteś ' + $scope.chatData.queue + ' w kolejce';
        }
        else
        {
          $scope.chatTalkPopupSettings.dynamicData.additionalTitleText = '';
        }
      };

      $scope.checkForCookieText = function()
      {
        var textFromCookie = spaceChatHelper.getCookieText();
        if(textFromCookie)
        {
          $scope.talkData.message = textFromCookie;
        }
      };

      /**
       * jak mamy coś nowego
       */
      $scope.longPollCallback = function(whatHappened){
        //for loading previous content we dont want to do anything
        if(!whatHappened || !whatHappened.params || !whatHappened.params.isHistory)
        {
          $scope.scrollToBottom();
          $scope.scrollToBottomOnPool = false;
          if(whatHappened.code === 'MESSAGE_CONSULTANT_JOIN' && whatHappened.params && whatHappened.params.source === 'agentLongPoll')  // dołączył konsultant, ale tylko jeśli z longPoll, a nie z init
          {
            // gramy badum!
            document.getElementById('chatNotification').play();
          }
          if(whatHappened.code === 'chatClosed')
          {
            spaceChatHelper.setCookieText('');
            spaceChatHelper.setCookieRoom('');
            $scope.talkChatData.chatActive = false;
            spaceChatHelper.stopPoll();
          }
          else if(whatHappened.code === 'newEvent')
          {
            $scope.maximazeWindow();
          }
        }
      };

      $scope.scrollToBottom = function()
      {
        $timeout(function() {
          if(angular.isFunction($scope.scrollBottomObj.scrollToBottom)){
            $scope.scrollBottomObj.scrollToBottom();
          }
        }, 0);
      };

    }
  ]);