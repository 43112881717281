angular.module('ihestiaIkomunikator')
  .service('ihestiaIkomunikatorSearchMessagesCentralHelper', ['$filter', 'iHestiaCommonModalHelper', '$rootScope',
    function($filter, iHestiaCommonModalHelper, $rootScope) {

      var SearchMessagesCentralHelper = function() {
        var self = this;

        this.tplData = angular.copy(self.defaultData);

        $rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings = {
          title: 'Wyszukaj wiadomość',
          okBtnName: false,
          replaceModalBody: true,
          cancelBtnName: $filter('translate')('Public.close'),
          headerList: [{
            name: 'Proste',
            value: 'simple'
          }, {
            name: 'Zaawansowane',
            value: 'advanced'
          }],
          headerListModel: 'ihestiaIkomunikatorSearchMessagesCentralModalSettings.activePage',
          activePage: 'simple'
        };

        $rootScope.$watch('ihestiaIkomunikatorSearchMessagesCentralModalSettings.activePage', function(){
          if ($rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings.activePage === 'simple') {
            $rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings.okBtnName = false;
            $rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings.replaceModalBody = true;
          } else {
            $rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings.okBtnName = $filter('translate')('Public.search');
            $rootScope.ihestiaIkomunikatorSearchMessagesCentralModalSettings.replaceModalBody = false;
          }
        });

        this.showModal = function() {
          iHestiaCommonModalHelper.showModal('ihestiaIkomunikatorSearchMessagesCentral');
          return self;
        };

        /**
         * Ukrywamy modal nowego zapytania
         * @return {[type]} [description]
         */
        this.hideModal = function() {
          iHestiaCommonModalHelper.hideModal('ihestiaIkomunikatorSearchMessagesCentral');
          return self;
        };

      };

      return new SearchMessagesCentralHelper();
    }
  ]);