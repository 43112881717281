angular.module('ihestiaGridSettings')

  /**
   * serwis REST do obsługi kontekstów ustawień
   * @return {[type]} [description]
   */
  .factory('ihestiaGridSettingsContextSvc', ['IHestiaRestServiceAbstractDef', function (IHestiaRestServiceAbstractDef) {
        var IHestiaGridSettingsContext = function ()
        {
          IHestiaRestServiceAbstractDef.apply(this, arguments);

          this.resource = 'gridsettings/contexts';
          this.businessArea = 'settings';
        };

      return new IHestiaGridSettingsContext();
    }])
  ;